<span class="p-grid">
    <ng-container *ngFor="let field of statusFieldGroup">
      <div class="p-col-4">
        <h4 class="p-col p-m-2 p-mx-lg-2 p-my-lg-0">{{ field.name }}:</h4>
        <div
          class="p-col p-m-2 p-mx-lg-2 p-my-lg-0 p-text-center"
          [ngStyle]="{
            position: 'relative',
            'border-radius': '0.5em',
            color: 'var(--primary-color-text)',
            'background-color':
              (['enabled'].includes(field.key) &&
                [false].includes(entity[field.key])) ||
              (['levelStatus'].includes(field.key) &&
                [
                  'Awaiting Batch',
                  'Assigned',
                  'Sent to Vendor',
                  'Awaiting Revision'
                ].includes(entity[field.key])) ||
              (['contentStatus'].includes(field.key) &&
                [].includes(entity[field.key]))
                ? 'var(--yellow-600)'
                : !entity[field.key] || [''].includes(entity[field.key])
                ? 'var(--gray-400)'
                : 'var(--primary-color)'
          }"
        >
          <h3 class="p-m-0 p-text-center">
            <ng-container [ngSwitch]="field.value">
              <ng-container *ngSwitchCase="''"> Not Found </ng-container>
              <ng-container *ngSwitchDefault>
                {{ field.value }}
              </ng-container>
            </ng-container>
          </h3>
        </div>
      </div>
    </ng-container>
  </span>  