<div class="p-inputgroup">
    <span class="p-float-label p-mb-4">
        <p-dropdown
            id="release-template"
            [(ngModel)]="template"
            [options]="releaseTemplate"
            [optionLabel]="'label'"
            [optionValue]="'value'"
            [autoDisplayFirst]="false"
            (onChange)="releaseTemplateDialog($event)"
        >
        </p-dropdown>
        <label for="release-template"> Select a Release Template </label>
     </span>
</div>
<div class="p-inputgroup">
    <span class="p-float-label">
        <p-dropdown
            id="misc-record"
            [(ngModel)]="selectedOption"
            [options]="options"
            [optionLabel]="'label'"
            [optionValue]="'value'"
            [autoDisplayFirst]="true"
        >
        </p-dropdown>
        <label for="misc-record"> Select Field Type </label>
     </span>
     <button
        pButton
        pRipple
        type="submit"
        label="Add Field"
        class="p-mb-4 p-ml-4"
        [style]="{ 'min-width': '18.5em' }"
        icon="pi pi-plus" iconPos="right"
        (click)="pushField();"
    ></button>
</div>
<p-orderList [value]="fields" (onReorder)="onRecordChange(fields)" [stripedRows]="true">
    <ng-template let-field pTemplate="item">
        <p-button
            ariaLabel="Remove Field"
            icon="pi pi-minus"
            styleClass="p-button-text p-button-danger"
            (click)="removeField(field); onRecordChange(fields)"
        ></p-button>
            <div><strong>Field:</strong> {{field.name}}
            </div>
            <!-- Options:
                <p-selectButton [multiple]="false" (onChange)="onRecordChange($event)" [options]="stateOptions" [(ngModel)]="field.state" optionLabel="label" optionValue="value"></p-selectButton> -->
            <div *ngIf="field.layout">
            Size:
                <p-selectButton [multiple]="false" (onChange)="onRecordChange(fields)" [options]="sizeOptions" [(ngModel)]="field.size" optionLabel="label" optionValue="value"></p-selectButton>
            </div>
            Column Layout:
            <p-selectButton [options]="columnOptions" [multiple]="false" (onChange)="onRecordChange(fields)" [(ngModel)]="field.columnOption" optionLabel="label" optionValue="value"></p-selectButton>
            <div class="p-inputgroup p-my-4" style="width: 50%;">
                <span ngClass="p-float-label">
                    <input
                        [(ngModel)]="field.fieldHeight"
                        [ngModelOptions]="{ standalone: true }"
                        pInputText
                        (ngModelChange)="onRecordChange(fields)"
                        class="field-item"
                        [disabled]="field.disabled"
                    />
                    <label for="fieldHeight"> Field Height</label>
                </span>
                <p-button
                    *ngIf="field.clearField && record[field.key]"
                    ariaLabel="Clear Field"
                    icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger"
                    (click)="field.touched = true; record[field.key] = ''"
                ></p-button>
            </div>
        
    </ng-template>

</p-orderList>

<p-dialog
  [(visible)]="showFieldTemplate"
  [dismissableMask]="true"
  [modal]="true"
  [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
>
  <ng-template pTemplate="header">
    <h3 class="p-m-0">Add Template Fields?</h3>
  </ng-template>
  <h4>Do you want to add the template fields of the selected release type? This will override your currently set fields so be careful!</h4>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="fillTemplateFields($event)" label="Ok" styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-times" [style]="{ 'margin-top': '10px' }" (click)="showFieldTemplate=false" label="Cancel" styleClass="p-button-text p-button-danger"></p-button>
  </ng-template>
</p-dialog>