<p-table [value]="buildJobs" rowGroupMode="rowspan" groupRowsBy="entityId" responsiveLayout="scroll">
    <ng-template pTemplate="caption">
        Total Jobs
        <p-chip label="{{ buildJobs ? buildJobs.length : null }}" styleClass="mr-2"></p-chip>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>Entity Id</th>
            <th>Build Type</th>
            <th>User</th>
            <th>Platform</th>
            <th>Current Status</th>
            <th>Inserted At</th>
            <th>Started At</th>
            <th>Finished At</th>
            <th>Errors</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-build let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
        <tr>
            <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                <span class="font-bold ml-2">{{build.entityId}}</span>
            </td>
            <td>{{ build.buildTypeV2 && build.buildTypeV2 == buildTypes.Items ? 'Items' : (build.buildTypeV2 && build.buildTypeV2 == buildTypes.Levels ? 'Challenges' : 'Images') }}</td>
            <td>{{ build.user ? build.user.name : null }}</td>
            <td>{{ setPlatformName(build.platforms[0]) }}</td>
            <td><button pButton pRipple label="{{ build.status.toUpperCase() }}" class="p-button-outlined p-button-{{setPlatformStatusColor(build.status)}}"></button></td>
            <td>{{ build.insertedAt | officeTime }}</td>
            <td>{{ build.startedAt | officeTime }}</td>
            <td>{{ build.finishedAt | officeTime }}</td>
            <td>
            <ng-container *ngIf="build.error_count > 0">
                <span *ngFor="let error of build.errors">
                {{ error }}
                </span>
            </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>