<div
  class="p-flex"
  style="min-width: 200px;"
>
  <div>
    <p-editor [(ngModel)]="value" [style]="{'height':'100px', 'width':'100%'}">
      <ng-template pTemplate="header">
        <span class="ql-formats">
            <button type="button" class="ql-bold" aria-label="Bold"></button>
            <button type="button" class="ql-italic" aria-label="Italic"></button>
            <button type="button" class="ql-underline" aria-label="Underline"></button>
        </span>
      </ng-template>
    </p-editor>
  </div>
  <button
    pButton
    pRipple
    ariaLabel="Revert Nested Values"
    icon="pi pi-undo"
    class="p-mx-1 p-button-text p-button-xs p-flex-shrink-0"
    tabindex="-1"
  ></button>
  <button
    pButton
    pRipple
    ariaLabel="Clear Field"
    icon="pi pi-minus"
    tabindex="-1"
    class="p-button-text p-button-danger p-button-xs p-flex-shrink-0"
  ></button>
</div>


