import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import ProgressionLevelDto from 'src/app/entities/progression-level/dtos/ProgressionLevelDto';

@Component({
  selector: 'app-progression-level-form-v3',
  templateUrl: './progression-level-form-v3.component.html',
  styleUrls: ['./progression-level-form-v3.component.sass']
})
export class ProgressionLevelFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  progressionLevelRecord: ProgressionLevelDto = new ProgressionLevelDto();

  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Progression Level Form V3 Component Initialization
   */
  async ngOnInit()
  {
    this.fields =
    [
      {
        title: "Progression Level Settings",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name'
          },
          {
            name: "Design Value",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            key: 'designValue',
            clearField: true,
            label: "Design Value",
            isRequired: true
          },
          {
            name: "Level",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            key: 'level',
            clearField: true,
            label: "Level",
            isRequired: true
          },
          {
            name: 'Tags',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            key: 'tags',
            isRequired: false,
            label: 'Tags',
            options: { fieldName: 'tags', values: ['NUF', 'Prize', 'Progression', 'Specialty'] },
            clearField: true,
            autoDisplayFirst: false,
            filter: true,
            style: { 'width': '100%' },
            showClear: true,
            multiSelectDisplayType: 'chip'
          },
          {
            name: "Internal Notes",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputTextArea],
            clearField: true,
            key: 'internal_notes',
            isRequired: false,
            label: 'Internal Notes',
            inputTextAreaCols: 40,
            inputTextAreaRows: 3
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.progressionLevelRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
          },
          {
            name: 'Reward(s)',
            inputType: DynamicInputType.Price,
            key: 'rewards_ref',
            priceExcludeResourceTypes: ['Titles']
          }
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Progression Level";
    this.viewRoute = 'progression-levels';
    this.isLoading = false;
  }
}
