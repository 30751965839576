import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';

@Component({
  selector: 'app-localized-strings-ag-grid',
  templateUrl: './localized-strings-ag-grid.component.html',
  styleUrls: ['./localized-strings-ag-grid.component.sass']
})
export class LocalizedStringsTableAgGridComponent implements OnInit {

  public entity: string = 'localization';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();


    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        width: 250,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: false,
      },
      {
        headerName: 'English',
        field: 'Source_en',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'French (France)',
        field: 'Target_frFR',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'Spanish (Mexico)',
        field: 'Target_esMX',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'German',
        field: 'Target_deDE',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'Verified',
        field: 'verified',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Verified',
          offLabel: 'Not Verified',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Verified' : 'Not Verified'
        },
        floatingFilter: true,
      },
      {
        headerName: 'Translation Request',
        field: 'translationRequest',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
      },
    ]


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);


    this.tableParams = {
      customName: "Localization Strings",
      customButtons: ['addString'],
      hideActions: true,
      hideAdd: true,
    }
  }

  async setOptions(){
  }

  

}
