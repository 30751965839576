import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import PermissionDto from '../dtos/PermissionsDto';
import DropdownDto from 'src/app/common/dtos/DropdownDto';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService
{
  private readonly apiURLprefix : string = '/api/permissions';

  constructor(private http: HttpClient) {}

  /**
   * Retrieves all CMS permissions
   */
  async getAllPermissions(): Promise<Array<PermissionDto> | undefined> {
    return await this.http.get<Array<PermissionDto>>(`${this.apiURLprefix}`).toPromise();
  }

  /**
   * Retrieves all CMS permissions
   */
  async getPermissionTypes(): Promise<any> {
    return await this.http.get<any>(`${this.apiURLprefix}/types`).toPromise();
  }

  /**
   * Creates a new Permission.
   *
   * @param permissionDTO DTO object with permission data.
   */
  async createPermission(permissionDTO: any): Promise<PermissionDto | undefined> {
    return await this.http.post<PermissionDto>(`${this.apiURLprefix}/add`, permissionDTO).toPromise();
  }
}
