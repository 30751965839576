import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

export default class CategoryClimateIconDTO extends BaseEntityDto
{
    /** Climate Icons */

    climateIcons: any = [];
    
    /** Toogle Icons for showing Climate Icons Fields */

    toggleIcons: boolean = false;
}