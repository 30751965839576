import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityTypeSettingsService 
{
  private readonly apiURLprefix : string = '/api/entity-type-settings';
  constructor(private http: HttpClient) {}

  /**
   * Retrieves all Entity Settings records.
   */
  async getAllEntityTypeSettings()
  {
    let response = await firstValueFrom(this.http.get<any>(`${this.apiURLprefix}`));

    return response;
  }

  /**
   * Retrieves all Entity Settings records given a query.
   * 
   * @param query Mongo Query.
   */
  async getAllEntityTypeSettingsWithQuery(query: any)
  {
    let response = await firstValueFrom(this.http.post<any>(`${this.apiURLprefix}`, query));

    return response;
  }
}