<ng-container *ngIf="!field.isHidden && !isFieldHidden() && !hasInputControl">
  <div class="p-d-flex p-flex-row p-ai-center">
    <strong *ngIf="isSubField" class="p-mr-2">{{ field.name }}:</strong>
    <div class="p-inputgroup">
      <ng-container [ngSwitch]="field.controlType">
        <!-- inputText -->
        <span *ngSwitchCase="'inputText'" class="p-float-label">
          <input
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Enter ' + field.name : ''
            "
            [id]="field.key"
            [style]="{ width: '100%' }"
            pInputText
            type="text"
            required
            (input)="onInput()"
            (ngModelChange)="onChange()"
            [ngClass]="{
              'p-large-inputgroup':
                field.key == 'thumbnail' ||
                field.key == 'prefab' ||
                field.key == 'name' ||
                field.key == 'cultivar' ||
                field.key == 'plantFamily' ||
                field.key == 'latinName'
            }"
            (blur)="onBlur($event)"
          />
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>
        <!-- inputChips -->
        <span *ngSwitchCase="'inputChips'" class="p-float-label">
          <p-chips
                  [(ngModel)]="value"
                  [ariaLabel]="field.name"
                  [style]="{'width':'100%'}"
                  [disabled]="(entity && entity.isLocked) || isLocked"
                  [placeholder]="
                  ['table'].includes(page) ? 'Enter ' + field.name : ''
                  "
                  [id]="field.key"
                  separator=","
                  showClear="true"
                  (ngModelChange)="onChange()"
                ></p-chips>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>
        <!-- inputTextarea -->
        <span *ngSwitchCase="'inputTextarea'" class="p-float-label">
          <textarea
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Enter ' + field.name : ''
            "
            cols="30"
            [rows]="checkRowLength(value)"
            (input)="onInput()"
            (ngModelChange)="onChange()"
          ></textarea>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>
        <!-- Editor -->
        <span *ngSwitchCase="'richTextArea'" class="p-float-label">
          <p-editor [(ngModel)]="value" [style]="{'height':'75%', 'width':'100%'}">
            <ng-template pTemplate="header">
              <span class="ql-formats">
                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                  <button type="button" class="ql-underline" aria-label="Underline"></button>
              </span>
          </ng-template>
        </p-editor>
        </span>


        <!-- Input Number -->
        <span *ngSwitchCase="'inputNumber'" class="p-float-label">
          <p-inputNumber
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Enter ' + field.name : ''
            "
            [min]="0"
            [style]="{ width: '100%' }"
            (input)="onInput()"
            (ngModelChange)="onChange()"
          ></p-inputNumber>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>

        <!-- dropdown -->
        <span *ngSwitchCase="'dropdown'" class="p-float-label">
          <p-dropdown
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Select ' + field.name : ''
            "
            [options]="options"
            [filter]="true"
            [autofocusFilter]="true"
            [autoDisplayFirst]="false"
            [style]="{ width: '100%' }"
            [virtualScroll]="true"
            [scrollHeight]="scrollHeight"
            (onChange)="
              onInput();
              onPathComponentValueChange(
                itemConstants.inputNames.year.name,
                $event
              )
            "
            (ngModelChange)="onChange()"
          ></p-dropdown>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>

        <!-- multiSelect -->
        <span *ngSwitchCase="'multiSelect'" class="p-float-label">
          <p-multiSelect
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Select ' + field.name : ''
            "
            [options]="options"
            display="chip"
            [filter]="true"
            [autofocusFilter]="true"
            [style]="{ width: '100%' }"
            (onChange)="onInput()"
            (ngModelChange)="onChange()"
          >
          </p-multiSelect>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>

        <!-- dropdown_ref -->
        <span *ngSwitchCase="'dropdown_ref'" class="p-float-label">
          <p-dropdown
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Select ' + field.name : ''
            "
            [options]="options"
            [optionValue]="field.dataKey ? field.dataKey : null"
            [dataKey]="field.apiController ? '_id' : null"
            [filter]="true"
            [filterBy]="field.filterControl && field.filterControl.keys ? field.filterControl.keys.toString() : 'name,id'"
            [autofocusFilter]="true"
            [autoDisplayFirst]="false"
            [style]="{ width: '100%' }"
            [virtualScroll]="true"
            [scrollHeight]="scrollHeight"
            (onChange)="
              onInput();
              onPathComponentValueChange(
                itemConstants.inputNames.fileType.name,
                $event
              );
              setTypeOptions()
            "
            (ngModelChange)="onChange()"
          >

            <ng-template let-option pTemplate="item">
              {{ option.name }} {{option.id ? ' ('+option.id+')' : ''}}
            </ng-template>
            <ng-template let-option pTemplate="selectedItem">
              {{ option.name }} {{option.id ? ' ('+option.id+')' : ''}}
            </ng-template>
          </p-dropdown>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>

        <!-- multiSelect_ref -->
        <!-- Circle back to this multi select element... -->
        <span *ngSwitchCase="'multiSelect_ref'" class="p-float-label">
          <p-multiSelect
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Enter ' + field.name : ''
            "
            [options]="options"
            optionLabel="name"
            dataKey="_id"
            display="chip"
            appendTo="body"
            [filter]="true"
            [filterBy]="field.filterControl && field.filterControl.keys ? field.filterControl.keys.toString() : 'name,id'"
            [autofocusFilter]="true"
            [autoDisplayFirst]="false"
            [style]="{ width: '100%' }"
            [virtualScroll]="true"
            [scrollHeight]="scrollHeight"
            [panelStyle]="{ width: '5%' }"
            (onChange)="onInput()"
            (ngModelChange)="onChange()"
          >
          </p-multiSelect>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>

        <!-- autoComplete_ref -->
        <span *ngSwitchCase="'autoComplete_ref'" class="p-float-label">
          <p-autoComplete
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Search ' + field.name : ''
            "
            [suggestions]="suggestions"
            (completeMethod)="setSuggestions($event.query)"
            field="name"
            minLength="3"
            [showEmptyMessage]="true"
            [forceSelection]="true"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            (onSelect)="onInput(); onChange()"
            (onUnselect)="onInput(); onChange()"
          >
            <ng-template let-item pTemplate="selectedItem">
              {{ item.name }} ({{ item.id }})
            </ng-template>

            <ng-template let-item pTemplate="item">
              {{ item.name }} ({{ item.id }})
            </ng-template>
          </p-autoComplete>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>

        <!-- autoComplete-multi_ref -->
        <span *ngSwitchCase="'autoComplete-multi_ref'" class="p-float-label">
          <p-autoComplete
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Search ' + field.name : ''
            "
            [suggestions]="suggestions"
            (completeMethod)="setSuggestions($event.query)"
            field="name"
            minLength="3"
            [showEmptyMessage]="true"
            [forceSelection]="true"
            [inputStyle]="{ width: '100%' }"
            [multiple]="true"
            [style]="{ width: '100%' }"
            (onSelect)="onInput(); onChange()"
            (onUnselect)="onInput(); onChange()"
          >
            <ng-template let-item pTemplate="selectedItem">
              {{ item.name || item.title }} ({{ item.id }})
            </ng-template>
            <ng-template let-item pTemplate="item">
              {{ item.name || item.title }} ({{ item.id }})
            </ng-template>
          </p-autoComplete>
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name || field.title }}
          </label>
        </span>

        <!-- date -->
        <span *ngSwitchCase="'date'" class="p-float-label">
          <p-calendar
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="
              ['table'].includes(page) ? 'Select Date/Time: ' + field.name : ''
            "
            [showTime]="true"
            [maxDate]="['start'].includes(field.key) ? conflictValue : ''"
            [minDate]="['end'].includes(field.key) ? conflictValue : ''"
            [yearNavigator]="true"
            [defaultDate]="today"
            yearRange="2020:2030"
            [monthNavigator]="true"
            [style]="{ width: '100%' }"
            (onSelect)="onInput()"
            (ngModelChange)="onChange()"
            [selectOtherMonths]="true"
            [touchUI]="true"
          >
            <!-- To show title on the calendar input for the tables -->
            <ng-template pTemplate="header">
              <div *ngIf="field.key == 'start'">
                <div class="p-d-flex p-jc-center">
                  <h3>Start Date</h3>
                </div>
              </div>
              <div *ngIf="field.key == 'end'">
                <div class="p-d-flex p-jc-center">
                  <h3>End Date</h3>
                </div>
              </div>
            </ng-template></p-calendar
          >
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>

        <!-- toggle -->
        <span *ngSwitchCase="'toggle'">
          <p-toggleButton
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [onLabel]="field.name"
            [offLabel]="'Not ' + field.name"
            [onIcon]="'pi pi-check'"
            [offIcon]="'pi pi-times'"
            [style]="{ width: '100%' }"
            (onChange)="onInput()"
            (ngModelChange)="onChange()"
          ></p-toggleButton>
        </span>

        <!-- nestedGroup -->
        <span
          *ngSwitchCase="
            ['nestedGroup', 'ref-link', 'lineItem', 'formArray'].includes(
              field.controlType
            )
              ? field.controlType
              : ''
          "
        >
          <div
            *ngFor="let group of nestedValue; let index = index"
            #nestedGroup
          >
            <p-divider *ngIf="index > 0"></p-divider>

            <span class="p-d-flex p-ai-center p-fluid">
              <p-button
                *ngIf="entity && !entity.isLocked"
                ariaLabel="Remove Group"
                styleClass="p-button-danger p-button-text p-mr-2"
                icon="pi pi-trash"
                (click)="
                  groupIndex = index;
                  deleteNestedGroup_overlay.toggle($event, nestedGroup)
                "
              ></p-button>

              <span class="p-d-flex p-flex-column">
                <div
                  *ngFor="let subField of field.subFields"
                  class="p-d-flex p-flex-row p-ai-center"
                >
                  <app-input-field
                    *ngIf="subField.isInputField"
                    #nestedInputField
                    [(value)]="group[subField.key]"
                    [field]="subField"
                    [entity]="entity[field.key][index]"
                    [entityType]="items"
                    [options]="
                      ['dropdown_ref', 'multiSelect_ref'].includes(
                        subField.controlType
                      )
                        ? options[subField.key]
                        : null
                    "
                    [page]="page"
                    [entityViewLink]="entityViewLink"
                    [lineItemType]="
                      ['lineItem'].includes(this.field.controlType)
                        ? group.lineItemType
                        : null
                    "
                    [isSubField]="true"
                    [style]="{ width: '100%' }"
                    [isLocked]="entity && entity.isLocked"
                    (nestedChange)="onNestedChange($event, index)"
                  ></app-input-field>

                  <app-data-field
                    *ngIf="!subField.isInputField"
                    [value]="group[subField.key]"
                    [field]="subField"
                    [entity]="entity[field.key][index]"
                    [page]="page"
                    [entityViewLink]="entityViewLink"
                  ></app-data-field>
                </div>
              </span>
            </span>
          </div>

          <p-divider *ngIf="nestedValue && nestedValue.length > 0"></p-divider>

          <div class="p-d-flex p-jc-evenly">
            <button
              pButton
              pRipple
              *ngIf="entity && !entity.isLocked"
              ariaLabel="Add Group"
              icon="pi pi-plus"
              class="p-mx-2 p-button-text p-button-lg"
              tabindex="-1"
              (click)="onAddNestedGroup(); onChange()"
            ></button>

            <button
              pButton
              pRipple
              *ngIf="isNestedValueChanged() && entity && !entity.isLocked"
              ariaLabel="Revert Nested Values"
              icon="pi pi-undo"
              class="p-mx-2 p-button-text p-button-lg"
              tabindex="-1"
              (click)="onRevertNestedValues(); onChange()"
            ></button>
          </div>
        </span>
      </ng-container>

      <!-- 'Revert Value' button -->
      <button
        pButton
        pRipple
        *ngIf="
          isValueChanged &&
          !['nestedGroup', 'ref-link', 'lineItem', 'formArray'].includes(
            field.controlType
          ) &&
          entity &&
          !entity.isLocked
        "
        ariaLabel="Revert Value"
        icon="pi pi-undo"
        class="p-button-text"
        tabindex="-1"
        (click)="onRevert(); onChange()"
      ></button>

      <!-- 'Clear Field' button -->
      <button
        pButton
        pRipple
        *ngIf="
          value &&
          ![
            'nestedGroup',
            'lineItem',
            'formArray',
            'ref-link',
            'toggle'
          ].includes(field.controlType) &&
          entity &&
          !entity.isLocked
        "
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        tabindex="-1"
        class="p-button-text p-button-danger"
        (click)="onClear(); onChange(true)"
      ></button>
    </div>
  </div>
</ng-container>

<!-- fields with inputControl -->
<ng-container *ngIf="!field.isHidden && !isFieldHidden() && hasInputControl">
  <div class="p-inputgroup p-d-flex p-flex-row p-ai-center">
    <ng-container [ngSwitch]="field.inputControl.type">
      <!-- text -->
      <ng-container *ngSwitchCase="'text'">
        <span class="p-float-label">
          <input
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="['table'].includes(page) ? 'Enter ' + field.name : ''"
            [id]="field.key"
            [name]="field.key"
            [style]="{ width: '100%' }"
            pInputText
            (input)="onInput()"
            (ngModelChange)="onChange()"
            [ngClass]="{
              'p-large-inputgroup':
                field.key == 'thumbnail' ||
                field.key == 'prefab' ||
                field.key == 'name' ||
                field.key == 'cultivar' ||
                field.key == 'plantFamily' ||
                field.key == 'latinName'
            }"
            (blur)="onBlur($event)"
          />
          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>
      </ng-container>

      <!-- singleRef -->
      <ng-container *ngSwitchCase="'singleRef'">
        <span class="p-float-label">
          <p-dropdown
            [(ngModel)]="value"
            [ariaLabel]="field.name"
            [disabled]="(entity && entity.isLocked) || isLocked"
            [placeholder]="['table'].includes(page) ? 'Select ' + field.name : ''"
            [options]="options"
            [optionValue]="field.dataKey ? field.dataKey : null"
            [dataKey]="field.apiController ? '_id' : null"
            [filter]="true"
            [filterBy]="field.filterControl && field.filterControl.keys ? field.filterControl.keys.toString() : 'name,id'"
            [autofocusFilter]="true"
            [autoDisplayFirst]="false"
            [style]="{ width: '100%' }"
            [virtualScroll]="true"
            [scrollHeight]="scrollHeight"
            (onChange)="
              onInput();
              onPathComponentValueChange(itemConstants.inputNames.fileType.name,$event);
              setTypeOptions()"
            (ngModelChange)="onChange()"
          >
            <ng-container *ngIf="field.isOptionsGrouped">
              <ng-template let-group pTemplate="group" class="p-d-flex p-jc-center">
                  {{ group.label }}
              </ng-template>
            </ng-container>

            <ng-container *ngFor="let template of ['item','selectedItem']">
              <ng-template let-option [pTemplate]="template">
                {{ field.viewControl && field.viewControl.props ? option[field.viewControl.props[0].key] : option.name }}
                <ng-container *ngIf="field.viewControl && field.viewControl.props && field.viewControl.props[0].caption">
                  ({{option[field.viewControl.props[0].caption]}})
                </ng-container>
              </ng-template>
            </ng-container>
          </p-dropdown>

          <label *ngIf="!['table'].includes(page)" [for]="field.key">
            {{ field.name }}
          </label>
        </span>
      </ng-container>
      

      <!-- color -->
      <ng-container *ngSwitchCase="'color'">
        <span class="p-inputgroup p-ai-center">
          <div class="color-input-preview-container">
            <div
              class="pi pi-star p-m-1"
              [ngStyle]="{ 'font-size': '1.3em', color: '#000' }"
            >
              <input
                type="button"
                [ariaLabel]="field.name"
                [disabled]="(entity && entity.isLocked) || isLocked"
                class="color-input-preview"
                [ngStyle]="{ 'background-color': value }"
                (click)="colorPicker_overlay.toggle($event)"
              />
            </div>
          </div>

          <div class="p-float-label">
            <input
              [(ngModel)]="value"
              [ariaLabel]="field.name"
              [disabled]="(entity && entity.isLocked) || isLocked"
              [placeholder]="['table'].includes(page) ? 'Enter ' + field.name : ''"
              [id]="field.key"
              [name]="field.key"
              [style]="{ width: '100%' }"
              maxlength="9"
              pInputText
              (input)="onInput()"
              (ngModelChange)="onChange()"
              (blur)="onBlur($event)"
            />
            <label *ngIf="!['table'].includes(page)" [for]="field.key">
              {{ field.name }}
            </label>
          </div>
        </span>
      </ng-container>
      <!--  -->
    </ng-container>

    <!-- Context Buttons -->
    <ng-container>
      <!-- 'Revert Value' button -->
      <button
        pButton
        pRipple
        *ngIf="isValueChanged && entity &&!entity.isLocked"
        ariaLabel="Revert Value"
        icon="pi pi-undo"
        class="p-button-text"
        tabindex="-1"
        (click)="onRevert(); onChange()"
      ></button>

      <!-- 'Clear Field' button -->
      <button
        pButton
        pRipple
        *ngIf="isClearable"
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        tabindex="-1"
        class="p-button-text p-button-danger"
        (click)="onClear(); onChange(true)"
      ></button>
    </ng-container>
  </div>
</ng-container>

<!-- 'Delete Nested Group' Confirm Overlay -->
<p-overlayPanel #deleteNestedGroup_overlay [dismissable]="true">
  <ng-template pTemplate="content">
    <div class="p-mb-2 p-d-flex p-ai-center">
      <i
        class="pi pi-exclamation-triangle p-mr-2"
        style="color: 'var(--pink-500)'; font-size: 1.5em"
      ></i>
      <h3 class="p-my-0">Confirm</h3>
    </div>

    <div class="p-p-2">Are you sure you want to delete this nested group?</div>

    <div class="p-mt-3 p-d-flex p-jc-end">
      <p-button
        label="Nope"
        class="p-mx-2"
        styleClass="p-button-outlined"
        (click)="deleteNestedGroup_overlay.hide()"
      ></p-button>
      <p-button
        label="Yup"
        icon="pi pi-check"
        class="p-mx-2"
        (click)="
          onDeleteNestedGroup(); onChange(); deleteNestedGroup_overlay.hide()
        "
      ></p-button>
    </div>
  </ng-template>
</p-overlayPanel>

<!-- color: Color Picker Overlay -->
<p-overlayPanel #colorPicker_overlay [dismissable]="true">
  <div class="p-inputgroup p-ai-center">
    <p-colorPicker
      [(ngModel)]="colorValue"
      [inline]="true"
      (onChange)="onChangeColorValue(); onChange()"
    ></p-colorPicker>
  </div>
</p-overlayPanel>
