import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';

@Component({
  selector: 'app-daily-goal-table-ag-grid',
  templateUrl: './daily-goal-table-ag-grid.component.html',
  styleUrls: ['./daily-goal-table-ag-grid.component.sass']
})
export class DailyGoalTableAgGridComponent implements OnInit {

  public entity: string = 'daily-goals';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Achievements Collection',
        field: 'achievementsCollection_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'achievements-collection',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.achievementsCollection_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        width: 400,
      },
      {
        headerName: 'Rewards Track',
        field: 'rewardsTrack_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.rewardsTrack_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        width: 400,
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images
    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'daily-goals',
    }


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions() {
    const entities = [
     
      { ref: 'achievementsCollection_ref', entity: 'achievements-collection', select: 'id name', query: { enabled: true } },
      { ref: 'rewardsTrack_ref', entity: 'rewards-track', select: 'id name', query: { enabled: true } },
    ]

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }

  }

}
