import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import RewardsTrackDTO from '../../dtos/RewardsTrackDTO';

@Component({
  selector: 'rewards-track-dynamic-field',
  templateUrl: './rewards-track-dynamic-field.component.html',
  styleUrls: ['./rewards-track-dynamic-field.component.sass']
})
export class RewardsTrackDynamicFieldComponent implements OnInit
{
  @Input() rewardsTrackRecord: RewardsTrackDTO = new RewardsTrackDTO();
  @Input() field: any = {}
  @Input() EOTrackOptions: any = [];
  @Input() EOTrackParentData: any = null
  @Output() onRecordChange = new EventEmitter<any>();
  @Output() onTrackChange = new EventEmitter<any>();
  prices7Days: Array<any> =
  [
    { key: 'dailyRewardDay1', showPrize: true, name: 'Day 1 Rewards',   priceExcludeResourceTypes: null, touched: false },
    { key: 'dailyRewardDay2', showPrize: true, name: 'Day 2 Rewards',   priceExcludeResourceTypes: null, touched: false },
    { key: 'dailyRewardDay3', showPrize: true, name: 'Day 3 Rewards',   priceExcludeResourceTypes: null, touched: false },
    { key: 'dailyRewardDay4', showPrize: true, name: 'Day 4 Rewards',   priceExcludeResourceTypes: null, touched: false },
    { key: 'dailyRewardDay5', showPrize: true, name: 'Day 5 Rewards',   priceExcludeResourceTypes: null, touched: false },
    { key: 'dailyRewardDay6', showPrize: true, name: 'Day 6 Rewards',   priceExcludeResourceTypes: null, touched: false },
    { key: 'coop',            showPrize: true, name: 'Day 7 Rewards',   priceExcludeResourceTypes: null, touched: false },
    { key: 'dailyRewardDay7', showPrize: true, name: 'Co-Op',           priceExcludeResourceTypes: null, touched: false }
  ];
  sevenDays: Array<string> = [];
  showIsLoop: boolean = false;
  constructor() { }


  /**
   * Rewards Track Dynamic Field Component Initialization
   */
  ngOnInit()
  {
    this.sevenDays = this.getDailyRewardArray();
    if(this.rewardsTrackRecord.type == 8){
      this.rewardsTrackRecord.isLoop = true;
      this.showIsLoop = true;
      this.onRecordChange.emit({ field: 'isLoop', record: this.rewardsTrackRecord, change: { field: 'isLoop', value: true } });
    }
  }

  /**
   * Handle Record Changes
   *
   * @param field Field that changed.
   * @param event Value changed.
   */
  onChange(field: string, event: any)
  {
    if(event && event.field == 'stageMilestone'){
      field = 'stageMilestone';
    }
    if(event && event.field == 'miniGamesRewards'){
      this.onRecordChange.emit({ field: "stageMilestone", record: this.rewardsTrackRecord, change: this.rewardsTrackRecord.stageMilestone });
    }
    this.onTrackChange.emit(this.rewardsTrackRecord);
    this.onRecordChange.emit({ field: field, record: this.rewardsTrackRecord, change: event });
  }

  getDailyRewardArray(): Array<any> {
    const dailyRewardArray: Array<any> = [];

    Object.keys(this.rewardsTrackRecord).forEach((key) => {
      if (key.includes("dailyRewardDay") && key !== "dailyRewardDay30") {
        dailyRewardArray.push(this.rewardsTrackRecord[key]);
      }
    });

    return dailyRewardArray;
  }

  assignDailyRewards(dailyRewards: Array<any>): void {
    if (dailyRewards.length !== 7) {
      throw new Error("The array must contain exactly 7 daily rewards.");
    }

    const dailyRewardFields = [
      "dailyRewardDay1",
      "dailyRewardDay2",
      "dailyRewardDay3",
      "dailyRewardDay4",
      "dailyRewardDay5",
      "dailyRewardDay6",
      "dailyRewardDay7",
    ];

    for (let i = 0; i < dailyRewardFields.length; i++) {
      const field = dailyRewardFields[i];
      this.rewardsTrackRecord[field] = dailyRewards[i];

      this.onChange(field, dailyRewards[i]);
    }
  }
}
