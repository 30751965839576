<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="item-sets"
    [formName]="title"
    [(record)]="ItemSetRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [rowData]="rowData"
>
</dynamic-form-v2>
