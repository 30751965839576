/**
 * Table Bulk Multi select action DTO
 */
export default class TableBulkMultiSelectAction
{
    /**
     * Entity field key
     */
    selectedField: any;
    /**
     * Old/actual field to be replaced
     */
    valueToReplace: Array<any> | any = [];
    /**
     * New values
     */
    replaceValues: Array<any> | any= [];
    /**
     * List of all Skipped rows
     */
    skippedRows: Array<any> = [];
    /**
     * Holds how many requests where successful.
     */
    successCount: number;
    /**
     * Editting Mode.
     */
    mode: string;
    /**
     * Holds how many requests where not successful.
     */
    errorCount: number;
    /**
    * Holds the value to find and to be replaced.
    */
    findValue: string;
    /**
    * Holds the value to replace with in Find And Replace Mode.
    */
    findAndReplaceValue: string;
}