import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LoggerService } from 'src/app/common/services/logger.service';
import { NotificationsService } from 'src/app/common/services/notifications.service';
import { DataService } from 'src/app/services/data.service';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'build-nodes-settings',
  templateUrl: './build-nodes-settings.component.html',
  styleUrls: ['./build-nodes-settings.component.sass']
})
export class BuildNodesSettingsComponent implements OnInit 
{
  @Input() cmsSettings: any;
  @Output() onSettingsChange = new EventEmitter<any>();

  constructor
  (
    private settingsService: SettingsService,
    private loggerService: LoggerService,
    private dataService: DataService,
    private messageService: MessageService,
    private notificationsService: NotificationsService
  ) { }

  /**
   * Build Nodes Settings Management Initialization
   */
  ngOnInit() { }

  /**
   * Handle changes on Build Nodes Settings
   * @param property 
   * @param event 
   */
  async onChange(property: string, event: any)
  {
    this.loggerService.log("Event value: ", event);
    this.onSettingsChange.emit(this.cmsSettings);
    await this.settingsService.updateSetting(this.cmsSettings.id, 
    {
      [property]: this.cmsSettings[property]
    });

    if(property == 'autoNodesScaleUp' || property == 'autoNodesScaleDown')
    {
      let payload = { type: property, value: this.cmsSettings[property] };
      await this.notificationsService.scaleSettingsNotification(payload);
    }
  }

  /**
   * Slaces up build nodes
   */
  async forceScaleUp()
  {
    let response = await this.dataService.buildNodesScaleUp();

    if(response)
    {
      this.messageService.add(
        {
        sticky: true,
        severity: 'success',
        summary: 'Scale Action Successful',
        detail: `Build nodes were successfully updated.`,
      });
    }
  }
}
