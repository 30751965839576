import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonEntityService } from '../../services/common-entity.service';
import { GettersService } from 'src/app/common/services/getters.service';


@Component({
  selector: 'app-asset-ref-input',
  templateUrl: './asset-ref-input.component.html',
  styleUrls: ['./asset-ref-input.component.sass']
})
export class AssetRefInputComponent implements OnInit {

  @Input() isEdit: boolean = false;
  @Input() nameInputEnabled: boolean = false;
  @Input() assetTypeInputEnabled: boolean = false;
  @Input() label: string;
  @Input() assetRecordData: any = null;
  @Input() parentRecordEntity: string;
  @Input() parentRecordId: string;
  @Input() parentRecordAssetKeyName: string;
  @Input() defaultEntityType: number = -1;
  @Input() defaultEntityTypeName: string;
  @Input() defaultAssetType: number = -1;

  entityType: string = "miscellaneous-build";
  isLoading: boolean = true;
  path: string = '';
  name: string = '';
  assetType: number = 0;
  imageryOptions: Array<any> = [];
  imagerySettings: Array<any> = [];

  constructor(
    private commonEntityService: CommonEntityService
  ) { }


  async ngOnInit(): Promise<void> {
    console.log('isEdit:', this.isEdit);
    console.log('assetRecordData:', this.assetRecordData);
    console.log('parentRecordEntity:', this.parentRecordEntity);
    console.log('parentRecordId:', this.parentRecordId);
    console.log('parentRecordAssetKeyName:', this.parentRecordAssetKeyName);

    if(this.isEdit && this.assetRecordData){
      this.path = this.assetRecordData.path;
      this.name = this.assetRecordData.name;
      this.assetType = this.assetRecordData.assetType
    }

    if(this.assetTypeInputEnabled){
      await this.getImagerySettings();
    }

    this.isLoading = false;
  }

  pathChange(event: any){
    console.log(event);
    // this will be used later on when we use the plastic-files-autocomplete component
    // if(event.isSelectChange){
    //   this.path = event.value.path;
    // } else {
    //   this.path = event.value.path ? event.value.path : event.value;
    // }
    // console.log(this.path)
  }

  async submitAssetRef(parentId: number = 0){
    console.log('submitAssetRef was called');
    if(this.isEdit){
      return this.editAsset();
    } else {
      return this.createAndReturnAsset(parentId);
    }
  }

  async editAsset(){
    let assetRecordDto: any = {
      path: this.path,
    }

    if (this.nameInputEnabled) {
      assetRecordDto.name = this.name;
    }
    if (this.assetTypeInputEnabled) {
      assetRecordDto.assetType = this.assetType;
    }
    return await this.commonEntityService.update(this.entityType, this.assetRecordData.id,
      { dto: assetRecordDto }, true)
  }

  async createAndReturnAsset(parentId: number){
    let assetRecordDto = {
      name: this.nameInputEnabled && this.name.length > 0 ? this.name : `${this.parentRecordEntity} ID: ${parentId} ${this.parentRecordAssetKeyName} record`,
      path: this.path,
      enabled: true,
      entityType: this.defaultEntityType,
      assetType: this.defaultAssetType,
    }
    try {
      let createResponse = await this.commonEntityService.create(this.entityType, assetRecordDto);
      return {
        key: this.parentRecordAssetKeyName,
        record: createResponse,
        success: true
      };
    } catch (error) {
      console.log(error);
      let err = "An error occurred while creating the asset for "+this.parentRecordAssetKeyName;
      return {
        key: this.parentRecordAssetKeyName,
        record: assetRecordDto,
        success: false,
        error: err
      };
    }
  }

  async getImagerySettings()
  {
      this.imagerySettings = await this.commonEntityService.findAllWithQuery
      (
        'imagerysettings', { query: { entityType: this.defaultEntityTypeName }, sort: { level: -1 }, select: 'id name value path entityType' }
      );
      console.log(this.imagerySettings)
      if(this.imagerySettings && this.imagerySettings.length > 0)
    {

      this.imagerySettings.forEach((imagerySetting: any) => {
        this.imageryOptions.push({ label: imagerySetting.name, value: imagerySetting.value });
      }
      );

    }
  }

  /**
   * Handle changes type selection
   *
   * @param event Type selected.
   */

  changePathPrefix(event: any)
  {
    if(event && event.value)
    {
      //loop through imagery settings and find the path that matches the selected type
      this.imagerySettings.forEach((imagerySetting: any) => {
        if(imagerySetting.value == event.value)
        {
          this.path = imagerySetting.path;
        }
      })
    }
  }


}
