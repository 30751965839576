import { Injectable } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { ActionsCellRendererComponent } from '../ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { TextCellRendererComponent } from '../ag-grid-table/cell-renderers/render-components/text/text.component';
import { BooleanEditorComponent } from '../ag-grid-table/cell-editors/boolean/boolean.component';

export interface TableConfig {
  entity: string;
  columnDefs: ColDef[];
  tableParams: any;
  buildParams: any;
  bulkUpdateColDefs: any[];
}

@Injectable({
  providedIn: 'root'
})
export class DynamicTableConfigService {
  private tableConfigs: { [key: string]: TableConfig } = {
    'link-destination': {
      entity: 'link-destination',
      columnDefs: [
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: ActionsCellRendererComponent,
          pinned: 'right',
          width: 120,
          resizable: true,
        },
        {
          headerName: 'ID',
          field: 'id',
          width: 125,
          headerCheckboxSelection: true,
          checkboxSelection: true,
          sortable: true,
          initialSort: 'desc',
          sortIndex: 0,
          initialSortIndex: 0,
          sort: 'desc',
          sortingOrder: ['asc','desc'],
          unSortIcon: false,
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: [
              'equals',
              'notEqual',
              'lessThan',
              'lessThanOrEqual',
              'greaterThan',
              'greaterThanOrEqual',
              'inRange'
            ]
          },
          resizable: true,
          floatingFilter: true,
          suppressSizeToFit: true,
        },
        {
          headerName: 'Link Text',
          field: 'linkText',
          cellRendererParams: {
            entity: 'link-destination'
          },
          cellRenderer: TextCellRendererComponent,
          sortable: true,
          unSortIcon: true,
          resizable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          autoHeight: true,
          cellEditor: 'agTextCellEditor',
          type: 'editableColumn',
        },
        {
          headerName: 'Link Destination',
          field: 'linkDestination',
          cellRendererParams: {
            entity: 'link-destination'
          },
          cellRenderer: TextCellRendererComponent,
          sortable: true,
          unSortIcon: true,
          resizable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          autoHeight: true,
          cellEditor: 'agTextCellEditor',
          type: 'editableColumn',
        },
        {
          headerName: 'Enabled',
          field: 'enabled',
          cellRenderer: TextCellRendererComponent,
          cellRendererParams: {
            onLabel: 'Enabled',
            offLabel: 'Not Enabled',
            isBoolean: true
          },
          sortable: true,
          unSortIcon: true,
          resizable: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            values: [true, false],
            valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
          },
          floatingFilter: true,
          cellEditor: BooleanEditorComponent,
          type: 'editableColumn',
        },
      ],
      tableParams: {
        customActionsLabel: 'Custom Bulk Actions',
        customActionButtons: ['bulkLocalize'],
        localizeCollectionName: 'link-destination',
      },
      buildParams: {},
      bulkUpdateColDefs: []
    }
    // Add more table configurations here as needed
  };

  getTableConfig(entity: string): TableConfig {
    const config = this.tableConfigs[entity];
    if (!config) {
      throw new Error(`No configuration found for entity: ${entity}`);
    }
    return config;
  }

  // Helper method to create a new table configuration
  createTableConfig(config: TableConfig): void {
    this.tableConfigs[config.entity] = config;
  }
} 