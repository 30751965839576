/**
 * Asset Types Enum
 */
export enum AssetTypes
{
    Miscellaneous = -2,
    All = -1,
    Item = 0,
    ChallengeFeed = 1,
    LoadingScreen = 2,
    SpruceImage = 3,
    ItemThumbnail = 4,
    Challenge = 5,
    LoadingLogo = 6,
    TitleIcon = 7,
    ClubIcon = 8,
    Inbox = 9,
    SpawnerIcon = 12,
    OTPImage = 13,
    NurtureAnimation = 14,
    SeedPack = 15,
    CurencyIcon = 16,
    Icon = 17,
    NurtureIcon = 18,
    NurtureVideo = 19,
    NurturePin = 20,
    NurturePinThumb = 21,
    spawnAudio = 22,
    loopAudio = 23,
    EOFeedBanner = 24,
    EOIapStore = 25,
    EOPopupHeader = 26,
    EOTileBg = 27,
    CoopBanner = 28,
    CoopCurrency = 29,
    CoopGoal = 30,
    CoopLogo = 31,
    CoopCurrencyIcon = 32,
    CoopPopupBackground = 33,
    SeedPackBox = 34,
    WelcomeGoal = 35,
    Minigames = 36,
}
