import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Seed Pack DTO
 */
export default class NurtureRarityDTO extends BaseEntityDto
{
    name: string = "";

    weight: number;
}
