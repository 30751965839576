import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import StyleDTO from '../../dtos/StyleDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-style-form-v3',
  templateUrl: './style-form-v3.component.html',
  styleUrls: ['./style-form-v3.component.sass']
})
export class StyleFormV3Component extends BaseDynamicFormComponent implements OnInit {

  styleRecord: StyleDTO = new StyleDTO();

  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }

  /**
   * Style Form V3 Initialization
   */

  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Style Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: "Start",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: true,
            label: 'Start',
            disabled: false,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: true,
            label: 'End',
            disabled: false,
            calendarMinDate: this.styleRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start && record.end){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
          },
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Style";
    this.viewRoute = 'styles';
    this.isLoading = false;
  }

}
