import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ThemeService } from 'src/app/services/theme.service'; 
import { MenuService } from 'src/app/routes/menu/menu.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.sass']
})
export class SideMenuComponent implements OnInit {
  sideMenuItems: any[] = [];
  currentTheme: string = 'arya-green'; 

  constructor(
    private menuService: MenuService,
    private authService: AuthService,
    private themeService: ThemeService
  ) {}

  async ngOnInit() {
    this.sideMenuItems = await this.menuService.fetchSideMenuItems();
    console.log('Loaded side menu items:', this.sideMenuItems);

    const cmsTheme = localStorage.getItem('cmsTheme');
    this.currentTheme = cmsTheme ? cmsTheme : 'arya-green';
    this.themeService.setTheme(this.currentTheme);
  }

  toggleMenu(index: number): void {
    this.sideMenuItems.forEach((section, i) => {
      section.items.forEach((menu: { type: string; expanded: boolean; }, j: any) => {
        if (i !== index && menu.type === 'collapsible') menu.expanded = false;
      });
    });

    this.sideMenuItems[index].items.forEach((menu: any) => {
      if (menu.type === 'collapsible') {
        menu.expanded = !menu.expanded;
      }
    });
  }

  handleAction(actionName: string): void {
    const actions: { [key: string]: () => void } = {
      logout: () => this.logout(),
      onThemeSwitched: () => this.onThemeSwitched(),
    };

    if (actions[actionName]) {
      actions[actionName]();
    } else {
      console.error(`Action "${actionName}" is not defined.`);
    }
  }

  logout(redirectToLogin: boolean = true): void {
    this.authService.logout(redirectToLogin);
    window.location.reload();
  }

  onThemeSwitched(): void {
    switch (this.currentTheme) {
      case 'arya-green':
        this.currentTheme = 'new-green';
        break;
      case 'new-green':
        this.currentTheme = 'arya-green';
        break;
    }
    this.themeService.setTheme(this.currentTheme);
    localStorage.setItem('cmsTheme', this.currentTheme);
  }
}
