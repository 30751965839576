import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ReleaseDTO from '../dtos/ReleaseDTO';

@Injectable({
  providedIn: 'root'
})
export class ReleaseService 
{
  private readonly apiURLprefix : string = '/api/releases';
  
  constructor(private http: HttpClient) {}

  /**
   * Creates a Release record
   * 
   * @param releaseData Release data
   */
  async createRelease(releaseData: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/add`, releaseData).toPromise();
  }

  /**
   * Updates a Release record.
   * 
   * @param releaseId Id of the currrency to update.
   * @param releaseData Release data to be updated.
   */
  async updateRelease(releaseId: any, releaseData: any): Promise<any>
  {
    return await this.http.patch(`${this.apiURLprefix}/update/${releaseId}`, releaseData).toPromise();
  }
}