import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AssetTypes } from '../entities/enums/AssetTypes';
import { BuildType } from '../enums/build-type';
import { firstValueFrom } from 'rxjs';
import { LoggerService } from '../common/services/logger.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class PromotionService
{
  private readonly apiURLprefix : string = '/api/promotion';

  constructor
  (
    private http: HttpClient,
    private loggerService: LoggerService,
    private messageService: MessageService,
  ) {}

  /**
   * Promotes Asset
   *
   * @param assetType Type of asset
   * @param payload Payload data
   */
  async promoteAsset(assetType: AssetTypes, payload: any): Promise<any>
  {
    return await this.http.post<any>(`${this.apiURLprefix}/asset/${assetType}`, payload).toPromise();
  }

  /**
   * Promotes Image
   *
   * @param assetType Type of asset
   * @param payload Payload data
   */
  async promoteImage(assetType: AssetTypes, payload: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/image/${assetType}`, payload).toPromise();
  }
  /**
   * Promotes a Record
   *
   * @param assetType Type of record to promote.
   * @param payload Payload data
   */
  async onPromoteRecord(buildType: BuildType, payload: any, showNotifications: boolean = true, type: string = ''): Promise<any>
  {
    if(payload)
    {
      if(payload.entity && payload.entity.path)
      {
        if(buildType == BuildType.Images && type && type.length > 0)
        {
          if(type == 'items' && !payload.entity.path.includes('.png'))
          {

            payload.entity.path = payload.entity.path + '.png';

          }
          else if(type == 'challenges' && !payload.entity.path.includes('.jpg'))
          {

            payload.entity.path = payload.entity.path + '.jpg';

          }
        } else if (payload.buildType == BuildType.Nurture && payload.entity.assetType == AssetTypes.NurturePinThumb && !payload.entity.path.endsWith('.png')) {

          payload.entity.path = payload.entity.path + '.png';

        }


        let response = await this.promoteRecord(buildType, payload);
        this.loggerService.log('Promotion Result', response);
        if(response && response.Success)
        {
          let responseMsg = '';

          if(
            response.responseData && response.responseData.s3Promotion
            && response.responseData.type && response.responseData.from
            && response.responseData.to && response.responseData.destinationPath
            )
          {
            let promotionResult = response.responseData.s3Promotion.reduce(function (a:any, b:any)
            {
              return (a.Key || a) + ", " + b.Key
            });
            responseMsg = `
            ${response.responseData.type} Id: ${response.EntityId} was promoted from
            ${response.responseData.from} to ${response.responseData.to}.
            Location: ${response.responseData.destinationPath}.
            Files Promoted: ${promotionResult}
            `;
          }
          else
          {
            responseMsg = 'Promoted to QA';
          }

          if(showNotifications)
          {
            this.messageService.add
            (
              {
                key: "AssetPromotionMsg",
                severity: 'success',
                summary: 'Promotion Sucessful',
                detail: responseMsg,
              }
            );
            this.messageService.add
            (
              {
                severity: 'success',
                summary: 'Promotion Sucessful',
                detail: "Record Promoted to QA",
              }
            );
          }

          return { Success: true };
        }
        else
        {
          this.loggerService.log(`error promoting record: `, response);

          let responseMsg = '';
          if(response && response.responseData && response.responseData.from && response.responseData.to && response.responseData.error){
            responseMsg = `
            Error promoting Record Id: ${response.EntityId} from ${response.responseData.from} to ${response.responseData.to}.
            * No successful builds found in build records.
            ${response.responseData.error}
            `;
          } else {
            responseMsg = `Asset was not promoted to QA. ${response.Message ? response.Message : ''}`;
          }

          if(showNotifications)
          {
            this.messageService.add
            (
              {
                key: "AssetPromotionMsg",
                severity: 'error',
                summary: 'Asset Promotion Error',
                detail: responseMsg,
              }
            );
            this.messageService.add
            (
              {
                severity: 'error',
                summary: 'Asset Promotion Error',
                detail: `Asset not promoted to QA. Error: ${response.Message ? response.Message : response.Error}`,
              }
            );
          }

          return { Success: false, AssetResponse: response };
        }
      }
      else
      {
        if(showNotifications)
        {
          this.messageService.add
          (
            {
              severity: 'error',
              summary: 'Promotion Error',
              detail: `No entity provided when promoting record.`,
            }
          );
        }
        this.loggerService.log('No entity provided when promoting record.');
        return { Success: false, Message: 'No entity provided when promoting record.' };
      }
    }
    else
    {
      if(showNotifications)
      {
        this.messageService.add
        (
          {
            severity: 'error',
            summary: 'Promotion Error',
            detail: `No entity provided when promoting assets`,
          }
        );
      }

      this.loggerService.log('No payload provided when promoting record.');
      return { Success: false, Message: 'No payload provided when promoting record.' };
    }
  }

  /**
   * Promotes a Record
   *
   * @param assetType Type of record to promote.
   * @param payload Payload data
   */
  async promoteRecord(assetType: BuildType, payload: any): Promise<any>
  {
    return await firstValueFrom(this.http.post<any>(`${this.apiURLprefix}/record/${assetType}`, payload));
  }
}
