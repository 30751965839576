import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-nurture-collection-groups-view-v2',
  templateUrl: './nurture-collection-groups-view-v2.component.html',
  styleUrls: ['./nurture-collection-groups-view-v2.component.sass'],
})
export class NurtureCollectionGroupsViewV2Component
  extends BaseDynamicViewComponent
  implements OnInit
{
  nurtureCollectionGroupRecord: BaseEntityDto = new BaseEntityDto();

  constructor(private agGridToolsService: AgGridToolsService) {
    super();
  }

  ngOnInit() {
    this.fields = [
      {
        title: 'Nurture Collection Group Data',
        fields: [
          {
            name: 'Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            columnWidth: 6,
          },
          {
            name: 'Start Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            columnWidth: 6,
          },
          {
            name: 'Priority',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'priority',
            label: 'priority',
            columnWidth: 6,
          },
          {
            name: 'End Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            columnWidth: 6,
          },
          {
            name: 'Unlock Condition Phrase',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: false,
            key: 'unlockConditionPhrase',
            label: 'Unlock Condition Phrase',
            columnWidth: 6,
          },
          {
            name: "Blurred Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'blurredImage_ref',
            label: 'Blurred Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'blurredImage_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card,
      },
      {
        title: 'Nurture Collections',
        type: DynamicCardType.Card,
        fields: [
          {
            name: 'Nurture Collections',
            inputType: DynamicViewType.AgGridTable,
            key: 'nurtureCollections_ref',
            label: 'Nurture Collection',
            options: {
              fieldName: '',
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  width: 125,
                  headerCheckboxSelection: true,
                  checkboxSelection: true,
                  sortable: true,
                  sort: 'desc',
                  sortingOrder: ['asc', 'desc'],
                  filter: 'agNumberColumnFilter',
                  filterParams: {
                    filterOptions: [
                      'equals',
                      'notEqual',
                      'lessThan',
                      'lessThanOrEqual',
                      'greaterThan',
                      'greaterThanOrEqual',
                      'inRange',
                    ],
                  },
                  resizable: true,
                  floatingFilter: true,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRendererParams: {
                    entity: 'nurture-collection',
                  },
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,
                },
              ],
              defaultQueryKey: 'nurtureCollections_ref',
              entity: 'nurture-collection',
            },
          },
        ],
      },
    ];

    this.nurtureCollectionGroupRecord = {
      enabled: false,
    };

    this.title = 'Nurture Collection Group';
    this.viewRoute = 'nurture-collection-groups';
    this.isLoading = false;
  }

  validateNurtureCollectionGroup(nurtureCollectionGroup: any) {
    return (
      nurtureCollectionGroup &&
      nurtureCollectionGroup.name &&
      nurtureCollectionGroup.name.length > 0
    );
  }
}