import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-achievement-collection-view-v2',
  templateUrl: './achievement-collection-view-v2.component.html',
  styleUrls: ['./achievement-collection-view-v2.component.sass']
})
export class AchievementCollectionViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
    AchievementCollectionRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Achievement Collection View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Achievement Collection Data",
        fields:
        [
          { 
            name: "Name", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text],
            clearField: true, 
            key: 'name', 
            isRequired: true, 
            label: 'Name',  
          },
          { 
            name: "Description", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text],
            clearField: true, 
            key: 'description', 
            isRequired: true, 
            label: 'Description', 
          },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time', columnWidth: 6 },
          { 
            name: "End Date/Time", 
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true, 
            key: 'end', 
            isRequired: true, 
            label: 'End Date/Time', 
            columnWidth: 6,  
          }, 
         { 
            name: "Achievements", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true, 
            key: 'achievements_ref', 
            isRequired: true, 
            label: 'Achievements',
            controller: 'achievements ',
            columnWidth: 6,  
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.AchievementCollectionRecord =
    {
      enabled: false
    };
    this.title = "AchievementCollection";
    this.viewRoute = 'achievementcollection-v2';
    this.isLoading = false;
  }
}

