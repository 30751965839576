<p-dialog
  [(visible)]="visible"
  [dismissableMask]="true"
  [modal]="true"
  [transitionOptions]="'200ms'"
  [breakpoints]="{
    '1024px': '50vw',
    '768px': '70vw',
    '480px': '90vw'
  }"
  [style]="{ width: '90vw' }"
  [contentStyle]="{ 'overflow': 'visible' }"
>
  <ng-template pTemplate="header">
    <h3 class="p-m-0">{{ title }}</h3>
  </ng-template>

  <div *ngIf="data" class="p-text-center" style="overflow: auto;">
    <p-table [value]="data" [autoLayout]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th *ngFor="let column of getColumns(data)">
            {{ column }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td style="text-align: justify;">{{ row.id }}</td>
          <td style="text-align: justify;" *ngFor="let column of getColumns(data)">
            {{ row[column] || 'N/A' }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>