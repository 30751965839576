<div class="title-edit-container">
  <div class="p-d-flex p-ai-center">
    <h1>{{ title }} ({{ recordId }})</h1>
    <a pButton pRipple label="Edit" type="button" class="edit-button" icon="pi pi-pencil"
      [routerLink]="['/store-listings-v2/edit',recordId]"></a>
  </div>
  <p-button label="Add to Cart" styleClass="p-button-sm p-mr-5" icon="fa-solid fa-cart-shopping " type="button"
    (onClick)="addToCart()">
  </p-button>
</div>

<p-tabView class="p-m-1" [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
  <ng-container *ngFor="let env of storeEnvs">
    <p-tabPanel [header]="env | uppercase" [disabled]="env !== activeEnv">
      <ng-container *ngIf="!isLoading">
        <ng-container *ngIf="CmsEnabled[selectedEnvironment.default ? 'test' : selectedEnvironment.value]; else noCmsMessage">
          <dynamic-view-v2 entityType="store-listings-v2" [record]="StoreListingV2Record" [fields]="fields"
            [sidebarFields]="sidebarFields" [isStoreEntity]="true" [storeEnv]="env" (titleEmitter)="handleTitleChange($event)"
            (idEmitter)="handleIdChange($event)" [allowDuplicate]="true" [viewRoute]="'store-listings-v2'">
          </dynamic-view-v2>
        </ng-container>
        <ng-template #noCmsMessage>
          <div class="cms-error-message"
            style="color: #f44336; padding: 1rem; background-color: #fddede; border-radius: 5px;">
            <strong>Record not available.</strong> Please ensure the record is created in CMS or imported through the Admin
            Panel.
            Use the edit form to add data, or import it via Admin Panel and try again.
          </div>
        </ng-template>
      </ng-container>
    </p-tabPanel>
  </ng-container>
</p-tabView>
