<ng-container *ngFor="let key of ['selected', 'deselected', 'disabled']">
    <div class="p-mt-5 p-inputgroup">
        <span ngClass="p-float-label">
            <p-dropdown
              [(ngModel)]="categoryIconRecord.icons[0][key]"
              [options]="options['image_ref']"
              optionLabel="name"
              optionValue="_id"
              [showClear]="true"
              [filter]="true"
              filterBy="id,name,path"
              [autoDisplayFirst]="false"
              [style]="{ 'min-width': '30em' }"
              (onChange)="onRecordChange('icons', $event)"
            >
              <ng-template let-option pTemplate="selectedItem">
                <div>
                  ({{ option.id }})
                  <strong>Name:</strong> {{ option.name }}
                  <strong>Path:</strong> {{ option.path }}
                </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <div>
                  ({{ option.id }})
                  <strong>Name:</strong> {{ option.name }}
                  <strong>Path:</strong> {{ option.path }}
                </div>
              </ng-template>
            </p-dropdown>
            <label for="image_ref">{{ key | titlecase }} Icon</label>
        </span>
    </div>
</ng-container>