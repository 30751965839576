import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChildren,
  EventEmitter,
} from '@angular/core';
import { DataService } from '../../../services/data.service';
import { ValidationsService } from '../../services/validations.service';
import { LoggerService } from '../../services/logger.service';
import FieldData from '../base-fields/field-data-dto';
import FilterData from '../dynamic-table/dtos/FilterData';
import * as _ from 'lodash';
import { FilterService, MessageService } from 'primeng/api';


@Component({
  selector: 'app-filters-sidebar',
  templateUrl: './filters-sidebar.component.html',
  styleUrls: ['./filters-sidebar.component.sass'],
})
export class FiltersSidebarComponent implements OnInit {
  @ViewChildren('filterField') filterField: any;
  @Input() visible: boolean;
  @Input() table: any;
  @Input() tableConfiguration: any;
  @Input() customFilters: Array<any>;
  @Input() userFirstName: string;
  @Input() columnSelection: Array<any> =[];
  @Output() onColumnSelectionChange = new EventEmitter();
  @Output() applyFilterAction = new EventEmitter();

  @Input() fields: Array<FieldData>;
  fieldsList: Array<FieldData>;
  @Input() options: any = {};
  optionsList: any = {};
  nestedPresetValues: any = {};

  // from userSettings
  @Input() filterSets: Array<any>;
  @Output() filterSetsChange = new EventEmitter();
  @Output() saveFilterSets = new EventEmitter();

  //from tableState
  @Input() filterValues: any;
  @Input() checkedState: any;
  @Output() filterValuesChange = new EventEmitter();
  @Output() checkedStateChange = new EventEmitter();
  @Output() saveFilterState = new EventEmitter();
  @Output() onHide = new EventEmitter<any>();

  fieldValues: any = {};
  setGroups: Array<any>;
  activeSetName: string = '';
  tempSetName: string = '';
  showAddSet: boolean = false;
  envExclusionData: any = {
    'dev': {
      exclude: false,
    },
    'qa': {
      exclude: false,
    },
    'prod': {
      exclude: false,
    },
    '[Empty]': {
      exclude: false,
    }
  };

  get checkedCount(): number {
    if (this.checkedState) {
      return Object.keys(this.checkedState).length;
    } else return 0;
  }

  displayImportFilterModal: boolean = false;
  importedSet: string;
  importedSetName: string;
  invalidImportedSetName: boolean = false;

  isLoading: boolean = true;
  dragStartIndex: number;

  constructor(
    private dataService: DataService,
    private validationsService: ValidationsService,
    private loggerService: LoggerService,
    private filterService: FilterService,
    private messageService: MessageService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;

    // register all custom filters
    this.registerCustomFilters();

    // clone all filter fields in 'fieldsList' array
    this.fieldsList = _.cloneDeep(this.fields);

    // Set all setGroups
    this.setGroups = this.getSetGroups();

    // Loop Fields
    for (const field of this.fields) {
      // Modify OPTIONS
      if (this.options[field.key]) {
        const optionsCopy = _.cloneDeep(this.options[field.key]);
        this.optionsList[field.key] = this.addEmptyOption(field, optionsCopy);
      }

      // Get/Set FIELD VALUES
      const fieldValue: any = this.getFieldValue(
        field,
        this.filterValues[field.key]
      );
      if(field.controlType == 'dropdown_ref' || field.controlType == 'multiSelect_ref')
      {
        let values: any[] = [];
        if(fieldValue)
        {
          fieldValue.forEach((value: any) =>
          {
            if(value)
            {
              values.push(value[0]);
            }
          });
          this.fieldValues[field.key] = values;
        }
      }
      else
      {
        if (!this.validationsService.isEmpty(fieldValue)) {
          this.fieldValues[field.key] = fieldValue;
        }
      }
    }

    this.isLoading = false;
  }

  // INIT FUNCTIONS
  /** Registers custom filters */
  registerCustomFilters() {
    this.customFilters.forEach((filter) => {
      switch (filter) {
        case 'empty':
          this.filterService.register('empty', (value: any) => {
            return this.validationsService.isEmpty(value);
          });
          break;

        case 'notEmpty':
          this.filterService.register('notEmpty', (value: any) => {
            return !this.validationsService.isEmpty(value);
          });
          break;

        case 'stringIn':
          this.filterService.register(
            'stringIn',
            (value: any, filter: string) => {
              let match = false;
              if (this.validationsService.isEmpty(filter)) {
                match = true;
              }

              if (this.validationsService.isEmpty(value)) {
                match = filter.includes('[Empty]');
              } else {
                match = filter.includes(value);
              }

              return match;
            }
          );
          break;

        case 'stringIn_ref':
          this.filterService.register(
            'stringIn_ref',
            (value: any, filter: string) => {
              let match = false;
              if (this.validationsService.isEmpty(filter)) {
                match = true;
              }

              if (this.validationsService.isEmpty(value)) {
                match = filter.includes('[Empty]');
              } else {
                match = filter.includes(value._id);
              }

              return match;
            }
          );
          break;

        case 'multiIn':
          this.filterService.register(
            'multiIn',
            (values: any[], filter: string) => {
              let match = false;
              if (this.validationsService.isEmpty(filter)) {
                match = true;
              }

              if (this.validationsService.isEmpty(values)) {
                match = filter.includes('[Empty]');
              } else {
                for (const v of values) {
                  if (filter.includes(v)) {
                    match = true;
                    break;
                  }
                }
              }

              return match;
            }
          );
          break;

        case 'multiIn_ref':
          this.filterService.register(
            'multiIn_ref',
            (values: any[], filter: string) => {
              if (this.validationsService.isEmpty(filter)) {
                return true;
              }

              if (this.validationsService.isEmpty(values)) {
                return filter.includes('[Empty]');
              }

              let match = false;
              for (const v of values) {
                if (filter.includes(v._id)) {
                  match = true;
                  break;
                }
              }
              return match;
            }
          );
          break;

        case 'isRule':
          this.filterService.register(
            'isRule',
            (values: any[], filter: string) => {
              this.loggerService.log('custom filter', values);
              if (
                filter === undefined ||
                filter === null ||
                filter.length === 0
              ) {
                return true;
              }

              if (values === undefined || values === null) {
                return false;
              }

              let match = false;
              for (const v of values) {
                this.loggerService.log('v', v);
                const name: string = v.filter_ref.name;
                if (name !== undefined && name !== null) {
                  const filterUpper = filter.toUpperCase();
                  const nameUpper = name.toUpperCase();
                  if (nameUpper.includes(filterUpper)) {
                    match = true;
                    break;
                  }
                }
              }
              return match;
            }
          );
          break;

        case 'isReward':
          this.filterService.register(
            'isReward',
            (values: any[], filter: string) => {
              this.loggerService.log('custom filter', values);
              if (
                filter === undefined ||
                filter === null ||
                filter.length === 0
              ) {
                return true;
              }

              if (values === undefined || values === null) {
                return false;
              }

              let match = false;
              for (const v of values) {
                this.loggerService.log('v', v);
                const name: string = v.id.name;
                if (name !== undefined && name !== null) {
                  const filterUpper = filter.toUpperCase();
                  const nameUpper = name.toUpperCase();
                  if (nameUpper.includes(filterUpper)) {
                    match = true;
                    break;
                  }
                }
              }
              return match;
            }
          );
          break;

        case 'inCollection':
          this.filterService.register(
            'inCollection',
            (values: any[], filter: any) => {
              if (
                filter === undefined ||
                filter === null ||
                filter.length === 0
              ) {
                return true;
              }

              if (values === undefined || values === null) {
                return false;
              }

              // let match = filter['items'].includes(values);

              let match = false;
              for (const f of filter) {
                if (f.includes(values)) {
                  match = true;
                  break;
                }
              }
              return match;
            }
          );
          break;

        case 'complex':
          this.filterService.register(
            'complex',
            (values: any, filters: any) => {
              if (this.validationsService.isEmpty(values)) {
                return filters.includes('[Empty]');
              } else {
                const service: any = this.filterService.filters;

                for (const f of filters) {
                  for (const v of values) {
                    let match = true;
                    for (let key of Object.keys(f)) {
                      if (this.validationsService.isEmpty(f[key])) {
                        continue;
                      }
                      const matchMode = f[key].subField.matchMode;
                      const filter = f[key].value;
                      const value = v[key];

                      if (!service[matchMode](value, filter)) {
                        match = false;
                        break;
                      }
                    }
                    if (match) {
                      return true;
                    }
                  }
                }
              }
            }
          );
          break;

        // filters for filterControl field prop
        case 'filterControl_test':
          // Use if the filter.input is either a string, an array of strings, an object, or an array of objects, and the field being checked is either a string, and array of strings, an object, or an array of objects.
          // If the entity field contains an object or array of objects, the filterControl.keys array should list the keys being searched.
          this.filterService.register(
            'filterControl_test',
            (value: any, filter: any) => {
              if (this.validationsService.isEmpty(filter.input)) {
                return true;
              }

              let match = false;
              if (this.validationsService.isEmpty(value)) {
                const keyToCheck = !this.validationsService.isEmpty(filter.control.keys) ? filter.control.keys[0] : 'name'
                const isFilteringEmptyInArray: boolean = _.isArray(filter.input) && _.map(filter.input, keyToCheck).includes('[Empty]')
                const isFilteringEmptyObject: boolean = typeof filter.input == 'object' && (filter.input[keyToCheck] == '[Empty]')
                const isFilteringEmptyValue: boolean = filter.input.includes('[Empty]')

                match = isFilteringEmptyInArray || isFilteringEmptyObject || isFilteringEmptyValue
              } else {
                // convert both values being checked into arrays
                let arrays: any[] = [];
                for (let val of [value, filter.input]) {
                  arrays.push(_.isArray(val) ? val : [val]);
                }

                // loop through both arrays
                for (let val of arrays[0]) {
                  if (!this.validationsService.isEmpty(filter.control.dataKey)) {
                    if (_.map(arrays[1], filter.control.dataKey).includes(val)) {
                      return true
                    }
                  } else {
                    for (let input of arrays[1]) {
                      if (!this.validationsService.isEmpty(filter.control.keys)) {
                        // if filterControl includes array of keys, loop through them and compare
                        for (let key of filter.control.keys) {
                          let inputCheck =
                            typeof input == 'object' &&
                            !this.validationsService.isEmpty(input[key])
                              ? this.getParsedString(input[key])
                              : this.getParsedString(input)

                          if (this.getParsedString(val[key]).includes(inputCheck)) {
                            return true;
                          }
                        }
                      } else {
                        // otherwise compare
                        if (
                          this.getParsedString(val).includes(
                            this.getParsedString(input)
                          )
                        ) {
                          return true;
                        }
                      }
                    }
                  }
                }
              }

              return match;
            }
          );
          break;

          case 'exclude':
            this.filterService.register('exclude', (values: any, filters: any) => {

              if (filters.includes('[Empty]')) {
                if (!values || values.length === 0) {
                  return true; // Return true if values are empty and [Empty] filter is present
                }
                filters = filters.filter((filter: string) => filter !== '[Empty]'); // Remove [Empty] filter from the filters array
              }

              if (!values || !filters || filters.length === 0) {
                return false;
              }

              filters.forEach((filter: string) => {
                if(this.envExclusionData[filter] && this.envExclusionData[filter].exclude){
                  filters = filters.filter((element: string) => element !== filter);
                }
              });

              const sortedValues = values.slice().sort();
              const sortedFilters = filters.slice().sort();

              if (sortedValues.length !== sortedFilters.length) {
                return false;
              }

              for (let i = 0; i < sortedValues.length; i++) {
                if (sortedValues[i] !== sortedFilters[i]) {
                  return false;
                }
              }

              return true;

            });
            break;
        default:
          break;
      }
    });
  }

  /** Modifies a value to a string in all lowercase with no spaces or special characters.
   * @param value string value being modified
   * @returns modified string
   */
  getParsedString(value: string) {
    return value
      ? value
          .toString()
          .toLowerCase()
          .replace(/[^a-z0-9]/gi, '')
      : '';
  }

  /** Gets setGroups from userSettings.filterSets */
  getSetGroups() {
    return [
      {
        key: 'userFilterSets',
        name: `${this.userFirstName}'s Sets`,
        value: this.filterSets,
        icon: 'pi pi-user',
      },
    ];
  }

  /** Adds '[Empty]' option to options arrays.
   * @param field FieldData
   * @param options options array for field being modified.
   * @returns array — options array with appropriately formatted '[Empty]' option.
   */
  addEmptyOption(field: FieldData, options: any[]) {
    const usesFilterControl: boolean = field.filterControl && field.filterControl.type
    const fieldControl = usesFilterControl ? field.filterControl.type : field.controlType

    switch (fieldControl) {
      // unmanaged lists
      case 'dropdown':
      case 'multiSelect':
        if (!this.validationsService.isEmpty(options)) {
          return ['[Empty]', ...options!];
        } else return;

      // managed lists
      case 'dropdown_ref':
      case 'multiSelect_ref':
        if (!this.validationsService.isEmpty(options)) {
          return [{ name: '[Empty]', _id: '[Empty]' }, ...options];
        } else return;

      case 'selectObjects':
        if (!this.validationsService.isEmpty(options)) {
          let emptyOption: any = {}
          if (this.validationsService.isEmpty(field.filterControl.keys)) {
            emptyOption = '[Empty]';
          } else {
            for (let key of field.filterControl.keys) {
              emptyOption[key] = '[Empty]';
            }
          }
          return [emptyOption, ...options];
        } else return;

      case 'selectStrings':
        if (!this.validationsService.isEmpty(options)) {
          return ['[Empty]', ...options];
        } else return

      default:
        return options;
    }
  }

  /** Returns value formatted for filter field input.
   * @param field FieldData
   * @param value Value parsed for filter and storage.
   * @param options Default is options array for field. Can also be passed in manually.
   * @returns fieldValue, formatted for field input.
   */
  getFieldValue(
    field: FieldData,
    value: any,
    options: any = this.optionsList[field.key]
  ) {
    if (field.subFields) {
      let presetVals: any = {};
      for (const subField of field.subFields) {
        if (subField.presetValue) {
          this.getPresetFieldValue(subField, options[subField.key]).then(
            (data) => {
              presetVals[subField.key] = data;
            }
          );
        }
      }
      if (Object.keys(presetVals).length > 0) {
        this.nestedPresetValues[field.key] = presetVals;
      }
    }

    if (this.validationsService.isEmpty(value)) {
      if (field.presetValue) {
        this.getPresetFieldValue(field).then(async (data) => {
          return await data;
        });
      } else return;
    } else {
      if (_.isArray(value) && field.matchMode !== 'between') {
        let result: any[] = [];
        value.forEach((v:any)=>{
          result.push(this.getFieldValue(field, v, options))
        })
        return result
      }

      if (!field.filterControl) {
      switch (field.controlType) {
        case 'dropdown_ref':
        case 'multiSelect_ref':
          return options.filter((o: any) => value.includes(o._id));

        case 'autoComplete_ref':
        case 'autoComplete-multi_ref':
          let opts: any;
          this.getOptionsWithModel(field.apiController!).then(
            async (data) => {
              opts = await data;
            }
          );
          return opts.filter((o: any) => value.includes(o._id));

        case 'date':
          let dates: any[] = [];
          value.forEach((value: string) => {
            const date = new Date(value);
            dates.push(date);
          });
          return dates;

        case 'inputNumber':
          return value.join('-');

        case 'toggle':
          return value == null ? null : value == true ? 'Yes' : 'No';

        case 'nestedGroup':
        case 'lineItem':
        case 'formArray':
          if (_.isArray(value)) {
            let nestedGroups: any[] = [];
            for (const group of value) {
              let nestedGroup: any = {};
              for (const key of Object.keys(group)) {
                if (group[key]) {
                  let nestedField = group[key].subField;
                  let nestedValue: any = this.getFieldValue(
                    group[key].subField,
                    group[key].value,
                    options[key]
                  );

                  nestedGroup[key] = {
                    subField: nestedField,
                    value: nestedValue,
                  };
                }
              }
              nestedGroups.push(nestedGroup);
            }
            return nestedGroups;
          }
          break;

        default:
          return value;
      }
      } else {
        // WITH FILTER CONTROL FIELD PROPERTY
        const keys = !this.validationsService.isEmpty(field.filterControl.keys)
          ? field.filterControl.keys
          : null;

        if (field.dataKey && this.optionsList) {
          let result = this.optionsList[field.key].find((o:any)=> o[field.dataKey] === value)
          if (result) {
            return result;
          } else {
            return null
          }
        } else {
        switch (field.filterControl.type) {
          case 'selectObjects':
            if (_.isArray(value)) {
              let result: any[] = [];
              for (let val of value) {
                if (['[Empty]'].includes(val)) {
                  result.push({ name: val });
                } else if (keys) {
                  for (let key of Object.keys(val)) {
                    let foundOption = options.find(
                      (o: any) => val[key] == o[key]
                    );
                    if (foundOption) {
                      result.push(foundOption);
                      break;
                    }
                  }
                } else {
                  result.push(val);
                }
              }
              return result;
            } else {
              if (['[Empty]'].includes(value)) {
                return { name: value };
              } else if (keys) {
                for (let key of Object.keys(value)) {
                  let foundOption = options.find(
                    (o: any) => value[key] == o[key]
                  );
                  if (foundOption) {
                    return foundOption;
                  }
                }
              } else {
                return value;
              }
            }
            break;

          default:
            return value;
        }
        }
      }
    }
  }

  /** Parses and returns presetValues designated in FieldData prop
   * @param field FieldData
   * @param options options for specified field
   * @returns fieldValue, formatted for field input
   */
  async getPresetFieldValue(
    field: FieldData,
    options: any = this.optionsList[field.key]
  ) {
    switch (field.controlType) {
      case 'dropdown':
      case 'dropdown_ref':
        if (!options) {
          return;
        } else {
          return options.find(
            (o: any) => o[field.presetValue.key] == field.presetValue.value
          );
        }

      case 'multiSelect_ref':
        return options.filter(
          (o: any) => o[field.presetValue.key] == field.presetValue.value
        );

      case 'autoComplete_ref':
        let autoCompResult;
        await this.getOptionsWithModel(field.apiController!).then(
          (data: any) => {
            autoCompResult = !data
              ? null
              : data.find(
                  (o: any) =>
                    o[field.presetValue.key] == field.presetValue.value
                );
          }
        );
        return autoCompResult;

      case 'autoComplete-multi_ref':
        let autoCompMultiResult;
        await this.getOptionsWithModel(field.apiController!).then(
          (data: any) => {
            autoCompMultiResult = !data
              ? null
              : data.filter(
                  (o: any) =>
                    o[field.presetValue.key] == field.presetValue.value
                );
          }
        );
        return autoCompMultiResult;

      case 'date':
        return [new Date(field.presetValue), null];

      case 'toggle':
        return field.presetValue == null
          ? null
          : field.presetValue == true
          ? 'Yes'
          : 'No';

      default:
        return field.presetValue;
    }
  }

  /** Retrieves options from managed lists, as indicated by field's 'apiController' property.
   * @param apiController string — API endpoint for retrieving options from managed list. FieldData.apiController
   * @param minimal boolean — Determines whether options should be minimal (true = ONLY 'id', 'name', & '_id' properties) or full objects from db (false). FieldData.isOptionsMin
   * @returns array — Options retrieved from API endpoint.
   */
  async getOptionsWithModel(apiController: string, minimal = false) {
    let result;
    await this.dataService
      .getAllOfTypeAsync(apiController, {
        query: {},
        autopopulate: true,
        virtuals: true,
      })
      .then((options) => {
        if (minimal) {
          let minimalOptions: any[] = [];
          for (const option of options) {
            minimalOptions.push({
              id: option.id,
              name: option.name,
              _id: option._id,
            });
          }
          result = minimalOptions;
        } else {
          result = options;
        }
      });
    return result;
  }

  /** Returns value parsed for filter and storage.
   * @param field FieldData of filterField being applied.
   * @param value Input data to be parsed.
   * @returns filterValue, parsed for filter and storage.
   */
  getFilterValue(field: FieldData, value: any) {
    // Parse input data from filter field
    if (this.validationsService.isEmpty(value)) {
      return;
    } else {
      if (!field.filterControl) {
        switch (field.controlType) {
          case 'inputText':
          case 'inputTextarea':
            return value.trim();

          case 'auto_dropdown':
          case 'dropdown':
          case 'multiSelect':
          case 'buildStatus':
            return value;

          case 'inputNumber':
            return value.includes('-')
              ? [
                  parseFloat(value.split('-')[0]),
                  parseFloat(value.split('-')[1]),
                ]
              : [parseFloat(value), parseFloat(value) + 0.00001];

          case 'toggle':
            if (_.isArray(value)) {
              let toggleValues: any = [];
              for (let val of value) {
                toggleValues.push(
                  val == 'Yes' ? true : val == 'No' ? false : '[Empty]'
                );
              }
              return toggleValues;
            }
            break;

          case 'dropdown_ref':
          case 'multiSelect_ref':
          case 'autoComplete_ref':
          case 'autoComplete-multi_ref':
            if (_.isArray(value)) {
              return _.map(value, field.filterProp ? field.filterProp : '_id');
            } else {
              return field.filterProp ? [value[field.filterProp]] : [value._id];
            }

          case 'date':
            let date0 = new Date(value[0]);
            let date1 = value[1] ? new Date(value[1]) : new Date(value[0]);
            return [
              new Date(date0.setHours(0, 0, 0, 0)).toISOString(),
              new Date(date1.setHours(23, 59, 59)).toISOString(),
            ];

          case 'nestedGroup':
          case 'lineItem':
          case 'formArray':
            if (_.isArray(value)) {
              let nestedGroups: any[] = [];
              value.forEach((group: any, index: number) => {
                let nestedGroup: any = {};
                for (const key of Object.keys(group)) {
                  if (!this.validationsService.isEmpty(group[key].value)) {
                    const nestedValue = this.getFilterValue(
                      group[key].subField,
                      group[key].value
                    );
                    nestedGroup[key] = {
                      subField: group[key].subField,
                      value: nestedValue,
                    };
                  } else {
                    nestedGroup[key] = null;
                  }
                }

                nestedGroups[index] = nestedGroup;
              });
              return nestedGroups;
            } else {
              return null;
            }
        }
      } else {
        // WITH FILTER CONTROL FIELD PROPERTY
        const keys = !this.validationsService.isEmpty(field.filterControl.keys)
          ? field.filterControl.keys
          : null;

        switch (field.filterControl.type) {
          case 'text':
            return value.trim();

          case 'selectObjects':
            if (_.isArray(value)) {
              let result: any[] = [];
              for (let val of value) {
                if (field.dataKey && this.optionsList[field.key]) {
                  result.push(val[field.dataKey])
                } else if (['[Empty]'].includes(val.name)) {
                  result.push(val.name);
                } else if (keys) {
                  let object: any = {};
                  for (let key of keys) {
                    object[key] = val[key];
                  }
                  result.push(object);
                } else {
                  result.push(val);
                }
              }
              return result;
            } else {
              if (['[Empty]'].includes(value.name)) {
                return value.name;
              } else if (keys) {
                let object: any = {};
                for (let key of keys) {
                  object[key] = value[key];
                }
                return object;
              } else {
                return value;
              }
            }

          default:
            return value;
        }
      }
    }
  }

  getFilterExlusions(field: FieldData, value: any){
    return true;
  }

  /** Formats filter data from tableState into FilterData objects for filterSet value.
   * @returns Array of FilterData objects formatted for filterSets
   */
  defineActiveFilters(): FilterData[] {
    let activeFilters: FilterData[] = [];

    Object.keys(this.checkedState).forEach((key: string) => {
      if (
        this.checkedState[key] !== null &&
        this.checkedState[key] !== undefined
      ) {
        const field: any = this.fields.find((f: FieldData) => f.key == key);

        let activeFilter: FilterData;
        if (this.validationsService.isEmpty(this.filterValues[key])) {
          activeFilter = {
            key: key,
            selectionValue: this.checkedState[key],
            value: null,
            matchMode: this.checkedState[key] ? 'empty' : 'notEmpty',
          };
        } else {
          activeFilter = {
            key: key,
            selectionValue: this.checkedState[key],
            value: this.filterValues[key],
            matchMode: field.matchMode,
          };
        }
        activeFilters.push(activeFilter);
      }
    });

    return activeFilters;
  }

  // ACTIONS
  /** Set all filter field values to null, clear all filter selections, and run filters */
  initializeAll() {
    this.fieldValues = {};
    this.filterValues = {};
    this.checkedState = {};

    // Apply all filters
    this.fields.forEach((field: FieldData) => {
      if (field.filterControl) {
        this.table.filter(null, field.key, field.filterControl.matchMode);
      } else {
        this.table.filter(null, field.filterKey, field.matchMode);
      }
    });
  }

  /** Handle 'Clear All Filters' action.
   * * Initializes all filters (setting values to null)
   * * Saves the state and clears filterSetSelection
   */
  onClearAll() {
    this.initializeAll();
    this.filterField._results.forEach((o: any) => {
      o.initValue = null;
    });
    this.saveState();
    this.activeSetName = '';

    // View All Fields
    this.onViewAllFields();
  }

  /** Handle 'Add Filter Set' action
   * @param setName Set name to be added
   */
  onAddSet(setName: string) {
    // Define ALL FILTER SETS
    let allFilterSets: any[] = [];
    this.setGroups.forEach((group: any) => {
      if (group && group.value && group.value.length > 0) {
        group.value.forEach((set: any) => {
          allFilterSets.push(set);
        });
      }
    });

    // Define ACTIVE FILTERS
    const activeFilters: FilterData[] = this.defineActiveFilters();

    // Conditions for new filter set:
    // value cannot be empty, name cannot be blank, name or value cannot match any existing set name or value
    if (
      activeFilters.length < 1 ||
      !setName ||
      allFilterSets.find((set: any) => [setName].includes(set.name)) ||
      this.activeSetName
    ) {
      return;
    }

    // Create new SET OBJECT and push to settings.filterSets
    const newSet = {
      name: setName,
      value: activeFilters,
    };
    this.filterSets.push(newSet);

    // Save filterSets to userSettings and update active set name.
    this.saveSets();
    this.activeSetName = setName;
  }
  async onRenameSet(set: any) {
    if (this.tempSetName && this.tempSetName.length > 0) {
      let setName = set.name;
      let newSetName = _.cloneDeep(this.tempSetName);

      let updateIndex = this.filterSets.findIndex(
        (set: any) => setName == set.name
      );
      let isDuplicated = this.filterSets.find(
        (set: any) => newSetName.toLowerCase() == set.name.toLowerCase()
      );

      if (!isDuplicated) {
        this.filterSets[updateIndex].name = newSetName;
        // Save User Settings and update active filter set name.
        this.saveSets();
        this.activeSetName = newSetName;
        set.isSelected = false;
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Filter Set Name Error',
          detail: 'Duplicated Filter Set Name.',
        });
      }
    } else {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Filter Set Name Error',
        detail: 'Filter set name cannot be empty.',
      });
    }
  }
  /** Handle 'Remove Filter Set' action
   * @param setName Set name to be removed.
   */
  onRemoveSet(setName: string) {
    // Find set being removed.
    const updateIndex = this.filterSets.findIndex((set: any) =>
      [set.name].includes(setName)
    );
    this.filterSets.splice(updateIndex, 1);

    // Save User Settings and update active filter set name.
    this.saveSets();
    if ([setName].includes(this.activeSetName)) {
      this.activeSetName = '';
    }
  }
  /** Renames a filter set
   * @param set Filter Set selected
   */
  /** Handle 'Select Filter Set' action */
  onSelectSet() {
    this.initializeAll();

    // Define All Filter Sets
    let allSets: any[] = [];
    this.setGroups.forEach((group: any) => {
      if (!this.validationsService.isEmpty(group.value)) {
        group.value.forEach((set: any) => {
          allSets.push(set);
        });
      }
    });

    // Define Active Filter Set
    const activeSet = allSets.find((set: any) =>
      _.isEqual(
        this.getParsedString(this.activeSetName),
        this.getParsedString(set.name)
      )
    );
    if (activeSet) {
      this.setActiveSet(activeSet.value);
      this.onViewActiveFieldsOnly();
    }
  }

  /** Loops through passed-in array of values from filterSet:
   * * Updates tableState.filterSelection & tablesState.filters
   * * Updates this.fieldValues, which in turn executes filters
   * * Updates filter sidebar view to 'Active Fields Only'
   * @param activeSetValue Array of filters, found in imported and saved filterSets.
   */
  setActiveSet(activeSetValue: any) {
    // Loop activeSet filters
    for (const filter of activeSetValue) {
      // Define Table State
      this.filterValues[filter.key] = [];
      filter.value.forEach((element: any) => this.filterValues[filter.key].push(element))
      this.checkedState[filter.key] = filter.selectionValue;

      // Get/Set FIELD VALUES
      const field: any = this.fields.find(
        (f: FieldData) => f.key == filter.key
      );
      const fieldValue: any = this.getFieldValue(
        field,
        this.filterValues[field.key]
      );
      if(field.controlType == 'dropdown_ref' || field.controlType == 'multiSelect_ref')
      {
        let values: any[] = [];
        if(fieldValue)
        {
          fieldValue.forEach((value: any) =>
          {
            if(value)
            {
              values.push(value[0]);
            }
          });
          this.fieldValues[field.key] = values;
        }
      }
      else
      {
        if (!this.validationsService.isEmpty(fieldValue)) {
          this.fieldValues[field.key] = fieldValue;
        }
      }
    }
  }

  /** Handle 'Search Filters' action
   * @param input Input value
   */
  onSearchFilters(input: string) {
    const result: FieldData[] = _.cloneDeep(this.fields).filter(
      (field: FieldData) =>
        field.name.toLowerCase().includes(input) ||
        field.key.toLowerCase().includes(input)
    );
    this.fieldsList = _.sortBy(result, 'name');
  }
  /** Handle 'View All Filters' action  */
  onViewAllFields() {
    this.fieldsList = _.cloneDeep(this.fields);
  }
  /** Handle 'View Active Filters Only' action */
  onViewActiveFieldsOnly() {
    const activeFilterFields = _.cloneDeep(this.fields).filter(
      (field: FieldData) => Object.keys(this.checkedState).includes(field.key)
    );
    this.fieldsList = _.sortBy(activeFilterFields, 'name');
  }
  /** Check whether or not this.filterFields contains *all* filter fields.
   * @returns Boolean — true: All filter fields are shown.
   */
  isAllFields(): boolean {
    return this.fieldsList.length === this.fields.length;
  }
  /** Check whether or not this.filterFields contains *only active* filter fields.
   * @returns Boolean — true: Only active filter fields are displayed.
   */
  isActiveFieldsOnly(): boolean {
    const activeFilterFields: FieldData[] = _.cloneDeep(this.fields).filter(
      (field: FieldData) =>
        !this.validationsService.isEmpty(this.checkedState[field.key])
    );

    return _.isEqual(
      _.sortBy(this.fieldsList, 'name'),
      _.sortBy(activeFilterFields, 'name')
    );
  }

  /** Checks whether a filter field uses a 3-state checkbox, based on controlType.
   * @param type controlType of field being checked.
   * @returns boolean
   */
  isTriStateCheckbox(field: FieldData): boolean {
    if (!field.filterControl) {
      return ![
        'toggle',
        'dropdown',
        'multiSelect',
        'buildStatus',
        'dropdown_ref',
        'multiSelect_ref',
        'autoComplete_ref',
        'autoComplete-multi_ref',
      ].includes(field.controlType);
    } else {
      return !['selectObjects'].includes(field.filterControl.type);
    }
  }

  /**
   * Handle filter changes.
   *
   * @param field Field data.
   */
  async beforeApplyFilter(field: FieldData)
  {
    if(this.tableConfiguration.useDynamicTableColumns)
    {
      if(field && field.key)
      {
        let missingColumn = false;
        if(!this.columnSelection.includes(field.key))
        {
          this.columnSelection.push(field.key);
          missingColumn = true;
          this.onColumnSelectionChange.emit(this.columnSelection);
        }

        if(missingColumn)
        {
          this.applyFilterAction.emit(field);
        }
        else
        {
          await this.onApplyFilter(field);
        }
      }
    }
    else
    {
      await this.onApplyFilter(field);
    }
  }

  /** Handle changes on apply filter.
   * @param field FieldData of filterField being applied.
   */
  async onApplyFilter(field: FieldData) {
    const filterValue = this.getFilterValue(field, this.fieldValues[field.key]);
    const checkedState = this.checkedState[field.key];

    if (!field.filterControl) {
      if (checkedState) {
        // checkedState == true
        if (this.isTriStateCheckbox(field)) {
          // filter for 'empty'
          this.table.filter('[Empty]', field.filterKey, 'empty');
        } else {
          // filter for 'empty' OR input
          if (this.validationsService.isEmpty(filterValue)) {
            // filter for 'empty'
            ['buildStatus'].includes(field.controlType)
              ? this.table.filter(
                  ['No build data'],
                  field.filterKey,
                  field.matchMode
                )
              : this.table.filter('[Empty]', field.filterKey, 'empty');
          } else if (field.key == 'env') {
              this.table.filter(filterValue, field.key, 'exclude');
          } else {
            // filter field has input, filter for input
            this.table.filter(filterValue, field.filterKey, field.matchMode);
          }
        }
      } else if (checkedState == false) {
        // checkedState == false
        if (!this.validationsService.isEmpty(filterValue)) {
          // filter field has input, filter for input
          this.table.filter(filterValue, field.filterKey, field.matchMode);
        } else {
          // filter field is blank, filter for 'notEmpty'
          ['buildStatus'].includes(field.controlType)
            ? this.table.filter(['No build data'], field.filterKey, 'isNot')
            : this.table.filter('[Has Value]', field.filterKey, 'notEmpty');
        }
      } else {
        // checkedState is null/undefined, clear filter
        this.table.filter(null, field.filterKey, field.matchMode);
      }

      // store filter data
      if (this.validationsService.isEmpty(filterValue)) {
        delete this.filterValues[field.key];
      }
      if (this.validationsService.isEmpty(checkedState)) {
        delete this.checkedState[field.key];
      }

      this.filterValues[field.key] = ['inCollection'].includes(field.matchMode!)
        ? _.map(this.fieldValues[field.key], '_id')
        : filterValue;
      this.saveState();
      this.checkForSet();
    } else {
      // WITH FILTER CONTROL PROP
      let filterObj = {
        input: this.fieldValues[field.key],
        control: field.filterControl,
      }
      field.dataKey ? filterObj.control.dataKey = field.dataKey : null

      if (checkedState) {
        // checkedState == true
        if (this.isTriStateCheckbox(field)) {
          // filter for 'empty'
          this.table.filter({ input: '[Empty]' }, field.key, 'empty');
        } else {
          // filter for 'empty' OR input
          if (this.validationsService.isEmpty(this.fieldValues[field.key])) {
            this.table.filter({ input: '[Empty]' }, field.key, 'empty');
          } else {
            this.table.filter(
              filterObj,
              field.key,
              'filterControl_test'
            );
          }
        }
      } else if (checkedState == false) {
        // ✅ checkedState == false
        if (!this.validationsService.isEmpty(this.fieldValues[field.key])) {
          // filter field has input, filter for input
          this.table.filter(
            filterObj,
            field.key,
            'filterControl_test'
          );
        } else {
          // filter field is blank, filter for 'notEmpty'
          this.table.filter('[Has Value]', field.key, 'notEmpty');
        }
      } else {
        // 🟩 checkedState is null/undefined, clear filter
        this.table.filter(null, field.key, 'filterControl_test');
      }

      // store filter data
      if (this.validationsService.isEmpty(filterValue)) {
        delete this.filterValues[field.key];
      }
      if (this.validationsService.isEmpty(checkedState)) {
        delete this.checkedState[field.key];
      }

      this.filterValues[field.key] = filterValue;
      this.saveState();
      this.checkForSet();
    }
  }

  /**
   *
   */
  onEnvExclusionApplied(event: any){
    // console.log('onEnvExclusionApplied');
    console.log(event);
    this.envExclusionData = event;
  }

  /** Detects active filter set.
   * * Checks all active filters against all filter sets
   */
  checkForSet() {
    // Define ALL SETS
    let allSets: any[] = [];
    this.setGroups.forEach((group: any) => {
      if (!this.validationsService.isEmpty(group.value)) {
        group.value.forEach((set: any) => {
          set.isSelected = false;
          allSets.push(set);
        });
      }
    });

    // Define ACTIVE FILTERS
    const activeFilters: FilterData[] = this.defineActiveFilters();

    // Compare
    let setName = '';
    allSets.forEach((set: any) => {
      let cloneSet = _.cloneDeep(set.value);
      let cloneActive = _.cloneDeep(activeFilters);

      if (_.isEqual(_.sortBy(cloneSet, 'key'), _.sortBy(cloneActive, 'key'))) {
        setName = set.name;
      }
    });

    this.activeSetName = setName;
  }

  /** Copies Filter Set values to clipboard so it can be shared.
   * @param setName Filter set name
   */
  copyFilterSetToClipboard(setName: string) {
    let filterSet = this.filterSets.find((x) => x.name == setName);

    let filters: any = [];

    if (filterSet) {
      filterSet.value.forEach((element: any) => {
        let filterValue: any = {
          key: element.key,
          selectionValue: element.selectionValue,
          value: element.value,
          matchMode: element.matchMode,
        };
        filters.push(filterValue);
      });

      //Copy to clipboard
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = JSON.stringify(filters);
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);

      this.messageService.add({
        sticky: true,
        severity: 'success',
        summary: 'Filter Set',
        detail: `Filter Set copied to clipboard successfully!`,
      });
    }
  }

  /** Imports a filter set */
  importFilterSet() {
    if (!this.importedSetName || this.importedSetName.length <= 0) {
      this.invalidImportedSetName = true;
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Filter Set Name',
        detail: `Filter Set Name is required.`,
      });

      return;
    } else {
      let exist = this.filterSets.find(
        (filterSet) => filterSet.name == this.importedSetName
      );
      if (exist) {
        this.invalidImportedSetName = true;
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Filter Set Name',
          detail: `Filter Set Name already exist.`,
        });

        return;
      }
    }

    if (this.importedSet && this.importedSet.length > 0) {
      // Set all filter field values to null.
      this.initializeAll();

      // Define Active Filter Set
      const importedSetValue = JSON.parse(this.importedSet);
      this.setActiveSet(importedSetValue);

      this.onAddSet(this.importedSetName);

      this.displayImportFilterModal = false;
    }
  }

  // SAVE
  /** * Emits current filters and checkedState, then emits function to save table state. */
  saveState() {
    this.filterValuesChange.emit(this.filterValues);
    this.checkedStateChange.emit(this.checkedState);
    this.saveFilterState.emit();
  }
  /** Emits changes to userSettings.filterSets, then emits function to save user settings. */
  saveSets() {
    this.filterSetsChange.emit(this.filterSets);
    this.saveFilterSets.emit();

    const setGroup = this.setGroups.find(
      (group: any) => group.key == 'userFilterSets'
    );
    setGroup.value = this.filterSets;
    this.showAddSet = false;
  }

  /**
   * Handle Drag Start events
   *
   * @param index Start index of the dragged object.
   */
  onDragStart(index: number)
  {
    this.dragStartIndex = index;
  }

  /**
   * Handle Drop events
   *
   * @param event Name of the event.
   * @param dropIndex End/drop index of the dragged object.
   * @param data Any additional data needed for the event.
   */
  async onDrop(event: string, dropIndex: number, data: any)
  {
    switch (event)
    {
      case 'Filter Sets Order':
        await this.reOrderFilterSet(dropIndex);
        break;

      default:
        break;
    }

  }

  /**
   * Handle changes on filter sets order
   *
   * @param dropIndex End/drop index of the dragged object.
   */
  async reOrderFilterSet(dropIndex: number)
  {
    if(dropIndex)
    {
      let element = this.filterSets[this.dragStartIndex]; // get element
      this.filterSets.splice(this.dragStartIndex, 1);    // delete from old position
      this.filterSets.splice(dropIndex, 0, element);    // add to new position

      this.filterSetsChange.emit(this.filterSets);
      this.saveFilterSets.emit();

      const setGroup = this.setGroups.find((group: any) => group.key == 'userFilterSets');
      setGroup.value = this.filterSets;

      this.messageService.add(
      {
        severity: 'success',
        summary: 'Update Successful',
        detail: `Filter Sets order was updated.`,
      });
    }
  }

  onHideEmitter(){
    this.onHide.emit({hide: true});
  }

  applyArchivedFilter(value: any) {
    this.fieldValues['archived'] = value;
    this.checkedState['archived'] = [true];
    this.onApplyFilter(
        {
          "key": "archived",
          "name": "Archived",
          "controlType": "toggle",
          "filterKey": "archived",
          "matchMode": "stringIn",
          "isColumn": true,
          "isInputField": true,
          "isFilterField": true
      }
      );
  }
}
