import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { BuildService } from '../../services/build.service';
import * as shape from 'd3-shape';

@Component({
  selector: 'build-history-chart',
  templateUrl: './build-history-chart.component.html',
  styleUrls: ['./build-history-chart.component.sass']
})
export class BuildHistoryChartComponent implements OnInit
{
  @Input() entity: any;
  isLoading: boolean = true;
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = false;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Build';
  yAxisLabel: string = 'Time (Seconds)';
  timeline: boolean = false;
  autoScale: boolean = true;
  chartData: any = [];
  buildHistoryData: any = [];
  colorScheme = {
    domain: [
      '#5AA454',
      '#E44D25',
      '#CFC0BB',
      '#7aa3e5',
      '#a8385d',
      '#aae3f5',
      '#925ef2',
    ],
  };
  chartCruve = shape.curveStepAfter;

  constructor(private buildService: BuildService) { }

  /**
   * Build History Chart Component Initialization
   */
  async ngOnInit()
  {

    await this.renderBuildHistoryChart(this.entity);
    this.isLoading = false;
  }

  /**
   * Render Build History Chart.
   *
   * @param entity Entity data.
   */
  async renderBuildHistoryChart(entity: any)
  {
    if (entity && entity.buildData && entity.buildData.length > 0)
    {
      if (entity.path)
      {
        this.buildHistoryData = await this.buildService.getBuildHistoryData(entity.path, entity.assetType);
      }
    }
    this.chartData = this.generateChartData(this.buildHistoryData[0]);
  }

  /**
   * Generate Chart Data.
   *
   * @param data Chart data.
   */
  generateChartData(data: any)
  {
    let x: any[] = [];
    let container =
    [
      {
        name: 'IOS',
        series: ([] = new Array<any>()),
      },
      {
        name: 'AND',
        series: ([] = new Array<any>()),
      },
      {
        name: 'WIN',
        series: ([] = new Array<any>()),
      },
      {
        name: 'LIN',
        series: ([] = new Array<any>()),
      },
      {
        name: 'MAC',
        series: ([] = new Array<any>()),
      },
      {
        name: 'MULTIPLE',
        series: ([] = new Array<any>()),
      },
    ];
    if (data)
    {
      data.forEach((buildItem: any) =>
      {

        let seriesObj: any = this.generateSeriesObject(buildItem);
        if (
          buildItem.platforms &&
          buildItem.startedAt &&
          buildItem.finishedAt &&
          parseInt(seriesObj.value) > 1 &&
          buildItem.status == 'finished'
        ) {
          if (buildItem.platforms.length == 1) {
            if (buildItem.platforms[0] == 'ios') {
              // IOS is at index 0
              container[0].series.push(seriesObj);
            }
            if (buildItem.platforms[0] == 'and') {
              // AND is at index 1
              container[1].series.push(seriesObj);
            }
            if (buildItem.platforms[0] == 'win') {
              // WIN is at index 2
              container[2].series.push(seriesObj);
            }
            if (buildItem.platforms[0] == 'lin') {
              // LIN is at index 3
              container[3].series.push(seriesObj);
            }
            if (buildItem.platforms[0] == 'mac') {
              // MAC is at index 4
              container[4].series.push(seriesObj);
            }
          } else {
            // else is at index 6
            container[5].series.push(seriesObj);
          }
        }
      });
    }
    return container;
  }

  /**
   * Generate Series Object.
   *
   * @param buildItem Build item data.
   */
  generateSeriesObject(buildItem: any)
  {
    // date difference calculation
    let seconds: any = (
      (new Date(buildItem.finishedAt).valueOf() -
        new Date(buildItem.startedAt).valueOf()) /
      1000
    ).toFixed(0);
    return {
      name: (buildItem._id).substring(0,9),
      value: seconds,
    };
  }
}
