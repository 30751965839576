<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="season-pass"
    [viewName]="title"
    [record]="seasonPassRecord"
    [fields]="fields"
    [sidebarFields]="sidebarFields"
    [isNotesPopUp]="true"
    [parentViewName]="parentViewName"
>
</dynamic-view-v2>