import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { DynamicViewV2 } from 'src/app/common/components/dynamic-view-v2/dynamic-view-v2.component';
import * as _ from 'lodash';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';

@Component({
  selector: 'app-gamedata-diff-view',
  templateUrl: './gamedata-diff-view.component.html',
  styleUrls: ['./gamedata-diff-view.component.sass']
})
export class GamedataDiffViewComponent  extends BaseDynamicViewComponent implements OnInit
{
  @ViewChild(DynamicViewV2) dynamicViewComponent: DynamicViewV2;
  record: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Trait View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Gamedata Diff Data",
        fields:
        [
          { name: "Differences:", inputType: DynamicViewType.NestedList, clearField: true, key: 'diff', isRequired: true, label: 'Differences' },
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Gamedata Diff";
    this.viewRoute = 'gamedata/diff';
    this.isLoading = false;
  }

  /**
   * Parse Record Data
   *
   * @param record Record value.
   */
  prepareRecordData(record: any)
  {

    this.record = _.cloneDeep(record);
    this.record = { ...this.record, name:  'demo name.' };

    this.dynamicViewComponent.isLoading = false;
    this.dynamicViewComponent.spinnerService.endSpinner();
  }
}
