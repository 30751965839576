import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdvancedSearchComponent } from './advanced-search/components/advanced-search/advanced-search.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { BulkBuildDataComponent } from './build-data/components/bulk-build-data/bulk-build-data.component';
import { ProdGamedataPushComponent } from './build-gamedata/prod-gamedata-push/prod-gamedata-push.component';
import { EntityHistoryComponent } from './common/components/entity-history/entity-history.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { SettingsComponent } from './entities/settings/settings.component';
import { FormCreatorComponent } from './form-creator/form-creator.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ArtComponent } from './tools/art/art.component';
import { EconTableComponent } from './entities/econ-table/econ-table.component';
import { AssetVersionTableComponent } from './entities/asset-version-table/asset-version-table.component';
import { RenderPreviewToolComponent } from './tools/art/render-preview-tool/render-preview-tool.component';
import { BuildHistoryComponent } from './common/components/build-history/build-history.component';
import { AssetBundleTableAgGridComponent } from './cms-v2/entities/asset-bundle-table-ag-grid/asset-bundle-table-ag-grid.component';
import { FlashBackChallengeTableAgGridComponent } from './cms-v2/entities/challenge/components/flashback-challenge-table-ag-grid/challenge-table-ag-grid.component';
import { NufCollectionTableAgGridComponent } from './entities/nuf-collection/nuf-collection-table-ag-grid/nuf-collection-table-ag-grid.component';
import { BundleAssetViewComponent } from './entities/bundle-asset-view/bundle-asset-view.component';
// STORE
import { StoregamedataviewComponent } from './cms-v2/store/store-game-data/storegamedataview/storegamedataview.component';
// COLORS
import { BuildGamedataV3ViewComponent } from './build-gamedata/build-gamedata-v3-view/build-gamedata-v3-view.component';
import { ClientStringsTableAgGridComponent } from './cms-v2/entities/client-strings/components/client-strings/client-strings-ag-grid.component';
import { BuildGamedataV3Component } from './build-gamedata/build-gamedata-v3/build-gamedata-v3/build-gamedata-v3.component';
import { LocalizedStringsTableAgGridComponent } from './cms-v2/entities/localized-strings/components/localized-strings/localized-strings-ag-grid.component';


// New routes scheme
import { routes as routesv2 } from './routes';
import { TestUserTableAgGridComponent } from './user/test-user-table-ag-grid-old/test-user-table-ag-grid.component';
import { ReleaseProgressionLevelsV2Component } from './entities/release/release-progression-levels-v2/release-progression-levels-v2.component';
import { ReleaseNurtureCollectionComponent } from './entities/release/release-nurture-collection/release-nurture-collection/release-nurture-collection.component';
import { DynamicEntityFormComponentComponent } from './common/components/dynamic-form-v2/components/dynamic-entity-form-component/dynamic-entity-form-component.component';
import { DynamicEntityViewComponentComponent } from './common/components/dynamic-form-v2/components/dynamic-entity-view-component/dynamic-entity-view-component.component';
import { MetadataFormComponent } from './common/components/dynamic-form-v2/components/metadata-form/metadata-form.component';
import { ReleaseGardenPassComponent } from './entities/release/release-garden-pass/release-garden-pass/release-garden-pass.component';
import { SeasonalPrizesFormV2Component } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-form-v2/seasonal-prizes-form-v2/seasonal-prizes-form-v2.component';
import { SeasonalPrizesViewV2Component } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-view-v2/seasonal-prizes-view-v2/seasonal-prizes-view-v2.component';
import { SeasonalPrizesAgGridComponent } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-ag-grid/seasonal-prizes-ag-grid/seasonal-prizes-ag-grid.component';
import { DiffToolViewComponent } from './entities/diff-tool-view/diff-tool-view.component';
import { DiffToolAgAgridComponent } from './entities/diff-tool-ag-agrid/diff-tool-ag-agrid.component';
import { SponsorViewV2Component } from './cms-v2/sponsor/sponsor-view-v2/sponsor-view-v2.component';
import { SponsorFormV2Component } from './cms-v2/sponsor/sponsor-form-v2/sponsor-form-v2.component';
import { SponsorTableAgGridComponent } from './cms-v2/sponsor/sponsor-table-ag-grid/sponsor-table-ag-grid.component';
import { LocalizationConfigFormComponent } from './cms-v2/entities/localization/form/localization-config-form-component/localization-config-form-component';
import { LocalizationConfigurationViewComponent } from './cms-v2/entities/localization/localization-config-view/localization-configuration-view/localization-configuration-view.component';
import { LocalizationConfigurationTableComponent } from './cms-v2/entities/localization/localization-config-view/localization-configuration-view/localization-configuration-table/localization-configuration-table/localization-configuration-table.component';
import { DynamicEntityHistoryComponent } from './common/components/dynamic-form-v2/components/dynamic-entity-history/dynamic-entity-history.component';
import { DynamicEntityTableComponent } from './common/components/dynamic-form-v2/components/dynamic-entity-table-component/dynamic-entity-table.component';
import { EntityTableComponent } from './common/components/dynamic-form-v2/components/entity-table-component/entity-table.component';
import { DynamicListTableComponent } from './common/components/dynamic-list-table/dynamic-list-table.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    component: HomePageComponent,
  },
  {
    path: 'form-creator',
    component: FormCreatorComponent,
  },
  {
    path: 'dynamic-form',
    component: DynamicFormComponent,
  },
  {
    path: 'flashback-challenges',
    component: FlashBackChallengeTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'build-history/:entityType/:id',
    component: BuildHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'econ-table',
    component: EconTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'build-gamedata',
    component: BuildGamedataV3Component,
    canActivate: [AuthGuard],
    data: {
      permission: {
        abilities: ['read'],
        controller: 'GamedataController'
      }
    }
  },
  {
    path: 'gamedatalogs/:id',
    component: BuildGamedataV3ViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'test-users',
    component: TestUserTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings/:type',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'art-tools',
    component: ArtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'render-preview-tool',
    component: RenderPreviewToolComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'asset-bundles',
    component: AssetBundleTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bundle-view',
    component: BundleAssetViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'history/:entityType/:id',
    component: EntityHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'prod-gamedata',
    component: ProdGamedataPushComponent,
    canActivate: [AuthGuard],
    data: {
      permission: {
        abilities: ['prod_push'],
        controller: 'GamedataController'
      }
    }
  },
  {
    path: 'advanced-search',
    component: AdvancedSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bulk-build-search',
    component: BulkBuildDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-game-data',
    component: StoregamedataviewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'asset-versions/:table/:type/:id',
    component: AssetVersionTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nuf-collection',
    component: NufCollectionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-strings',
    component: LocalizedStringsTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'client-strings',
    component: ClientStringsTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-configuration',
    component: LocalizationConfigurationTableComponent, 
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-configuration/add',
    component: LocalizationConfigFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-configuration/:id',
    component: LocalizationConfigurationViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-configuration/edit/:id',
    component: LocalizationConfigFormComponent,
    canActivate: [AuthGuard],
  },
  // SPONSORTS
  {
    path: 'sponsors',
    component: SponsorTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sponsors/add',
    component: SponsorFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'sponsors/:id',
    component: SponsorViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'sponsors/edit/:id',
    component: SponsorFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'diff-jobs',
    component: DiffToolAgAgridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'diff-jobs/:id',
    component: DiffToolViewComponent,
    canActivate: [AuthGuard],
  },
   // Dynamic List Tables
   {
    path: 'link-destination',
    component: DynamicListTableComponent,
    canActivate: [AuthGuard],
   },
   // Dynamic Entities
   {
    path: 'dynamic-entities',
    component: EntityTableComponent,
    canActivate: [AuthGuard],
   },
   {
    path: 'dynamic-entities/add',
    component: MetadataFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'dynamic-entities/edit/:id',
    component: MetadataFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'd/:entityName',
    component: DynamicEntityTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'd/:entityName/edit/:id',
    component: DynamicEntityFormComponentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'd/:entityName/add',
    component: DynamicEntityFormComponentComponent,
    canActivate: [AuthGuard],
  },
  { 
    path: 'd/:entityName/view/:id', 
    component: DynamicEntityViewComponentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'd/history/:entityType/:id',
    component: DynamicEntityHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: HomePageComponent,
    // canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot([
    ...routesv2,
    ...routes
  ])],
  exports: [RouterModule],
})
export class AppRoutingModule { }
