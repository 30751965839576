import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import CoOpDTO from '../../dtos/CoOpDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-co-op-form-v3',
  templateUrl: './co-op-form-v3.component.html',
  styleUrls: ['./co-op-form-v3.component.sass']
})
export class CoOpFormV3Component extends BaseDynamicFormComponent implements OnInit {

  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  coOpRecord: CoOpDTO = new CoOpDTO();
  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }

  ngOnInit() {
    this.fields = [

      {
        title: "Co-Op Data",
        fields: [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'internalName',
            isRequired: false,
            label: 'Internal Name',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            columnWidth: 6,
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            columnWidth: 6,
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.coOpRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: "Imagery Data",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: '',
            label: 'Imagery Data:',
          },
          {
            name: "Co-Op Logo",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'logo_ref',
            label: 'Co-Op Logo',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: {
              fieldName: 'logo_ref', apiController: 'miscellaneous-build',
              customQuery: { assetType: AssetTypes.CoopLogo }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true},
            columnWidth: 6
          },
          {
            name: "Co-Op Logo Mask",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'white_image_ref',
            label: 'Co-Op Logo Mask',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: {
              fieldName: 'white_image_ref', apiController: 'miscellaneous-build',
              customQuery: { assetType: AssetTypes.CoopLogo }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true},
            columnWidth: 6
          },
          {
            name: "Co-Op Banner Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'banner_image_ref',
            label: 'Co-Op Banner Image',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: {
              fieldName: 'banner_image_ref', apiController: 'miscellaneous-build',
              customQuery: { assetType: AssetTypes.CoopBanner }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true},
            columnWidth: 6
          },
          {
            name: "Co-Op Popup Background",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'popup_background_ref',
            label: 'Co-Op Popup Background',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: {
              fieldName: 'popup_background_ref', apiController: 'miscellaneous-build',
              customQuery: { assetType: AssetTypes.CoopPopupBackground }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true},
            columnWidth: 6
          },
          {
            name: "Slide Down Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'slide_down_image_ref',
            label: 'Slide Down Image',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: {
              fieldName: 'slide_down_image_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: { $in: [3] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true},
            columnWidth: 6
          },
          {
            name: "Popup Goal Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'popup_goalimage_ref',
            label: 'Popup Goal Image',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            options: {
              fieldName: 'popup_goalimage_ref', apiController: 'miscellaneous-build',
              customQuery: { assetType: AssetTypes.WelcomeGoal }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true,
            },
            columnWidth: 6
          },
          {
            name: "Team Colors",
            inputType: DynamicInputType.NestedInputComponent,
            key: 'team_colors',
            label: 'Team Colors',
            columnWidth: 12,
            options: {
              headerTitle: "Team Colors",
              addButtonText: "Add Color Field",
              newRecordTemplate: {
                primaryColor: '',
                secondaryColor: ''
              },
              fieldName: 'Colors',
              subFields: [
                {
                  name: "Primary Color",
                  inputType: DynamicInputType.BaseInputField,
                  inputTypeFields: [InputFieldType.ColorPicker],
                  key: 'primaryColor',
                  isRequired: true,
                  label: 'Primary Color'
                },
                {
                  name: "Secondary Color",
                  inputType: DynamicInputType.BaseInputField,
                  inputTypeFields: [InputFieldType.ColorPicker],
                  key: 'secondaryColor',
                  isRequired: true,
                  label: 'Secondary Color'
                },
              ]
            }
          },
          {
            name: "Challenge feed button bg color",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: 'ch_f_btn',
            isRequired: false,
            clearField: true,
            label: 'Challenge Feed Button BG colors',
          },
          {
            name: "Color 1",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.ColorPicker],
            key: 'feedBtnColor1',
            label: 'Color 1',
            clearField: true,
            isRequired: false,
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Color 2",
            inputType: DynamicInputType.BaseInputField,
            key: 'feedBtnColor2',
            label: 'Color 2',
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: true,
            isRequired: false,
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Logo outline and horizontal bar color",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: 'logo_o_color',
            isRequired: false,
            clearField: true,
            label: 'Logo Outline and Horizontal bar colors',
          },
          {
            name: "Logo Outline Color",
            inputType: DynamicInputType.BaseInputField,
            key: 'logoOutlineColor',
            label: 'Logo Outline Color',
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: true,
            isRequired: false,
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Horizontal Bar Color",
            inputType: DynamicInputType.BaseInputField,
            key: 'horizontalBarColor',
            label: 'Horizontal Bar Color',
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: true,
            isRequired: false,
            disabled: false,
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Goal Data",
        fields: [
          {
            name: "Goal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'goal_name',
            isRequired: false,
            label: 'Goal Name',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Goal Name Plural",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'goal_name_plural',
            isRequired: false,
            label: 'Goal Name Plural',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Goals Imagery",
            inputType: DynamicInputType.GoalImageComponentComponent,
            inputTypeFields: [InputFieldType.ListBox],
            key: 'goal_images_ref',
            label: 'Goals Imagery',
            options: {
              fieldName: 'goal_images_ref',
              createOrSelect: {
                child: true,
                parentKey: 'goal_images_ref',
                parentLabel: 'Goal Image',
              }
            },
            columnWidth: 12

          },

        ],
        type: DynamicCardType.Card
      },
      {
        title: "Currency Data",
        fields: [
          {
            name: 'Currency',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            key: 'currency_record_ref',
            label: 'Currency',
            clearField: true,
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: true,
            options: { fieldName: 'currency_record_ref', apiController: 'currencies', minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 } },
            columnWidth: 12
          },
          {
            name: "Currency Name",
            inputType: DynamicInputType.ReadonlyFieldComponent,
            clearField: false,
            key: 'currency_record_ref',
            isRequired: true,
            label: 'Currency Name',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Currency Name Plural",
            inputType: DynamicInputType.ReadonlyFieldComponent,
            clearField: false,
            key: 'currency_record_ref',
            isRequired: true,
            label: 'Currency Name Plural',
            disabled: false,
            showPlural: true,
            columnWidth: 6
          },
          {
            name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: "Sprite Index",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            isRequired: false,
            clearField: true,
            key: 'currency_sprite_index',
            label: 'Sprite Index',
            inputNumberShowButtons: true,
            columnWidth: 3
          },
          {
            name: "spriteHelp",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: '',
            label: `
              10 = Purple Flower \n
              11 = Stained Glass \n
              12 = Pink Tulip \n
              13 = Strawberry \n
              Additional icons can be added to the Sprite Sheet through CMS, and their index used instead.
            `,
            columnWidth: 9
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Co-Op Rewards",
        fields: [
          {
            name: 'Rewards Track #1',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'track_ref',
            isRequired: false,
            label: 'Rewards Track #1',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: { fieldName: 'track_ref', apiController: 'rewards-track', minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 }, customQuery: { type: 7 } }
          },
          {
            name: 'Rewards Track #2',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'track_2_ref',
            isRequired: false,
            label: 'Rewards Track #2',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: { fieldName: 'track_2_ref', apiController: 'rewards-track', minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 }, customQuery: { type: 7 } }
          },
          {
            name: 'Rewards Track #2',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'track_3_ref',
            isRequired: false,
            label: 'Rewards Track #2',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: { fieldName: 'track_3_ref', apiController: 'rewards-track', minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 }, customQuery: { type: 7 } }
          },
          {
            name: 'Grand Prize(s)',
            inputType: DynamicInputType.Price,
            options: { fieldName: '', version: 3 },
            key: 'rewards_ref',
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
    ]

    this.title = "Co-Op";
    this.viewRoute = 'co-op';
    this.isLoading = false;
  }

  /**
   * Validate Co-Op Forms
   */

  validateCoOpForm(form: any) {
    // Array of color fields to validate
    const colorFields = [
      'feedBtnColor1',
      'feedBtnColor2',
      'logoOutlineColor',
      'horizontalBarColor'
    ];

    // Store invalid fields
    let invalidFields: string[] = [];

    // Validate each color field
    colorFields.forEach((field) => {
      if (!this.validation.colorValidator(form[field])) {
        invalidFields.push(field);
      }
    });

    // Determine if validation passed
    let confirmValidationCallback = invalidFields.length === 0;

    // Create message based on validation result
    let message = confirmValidationCallback
      ? 'Valid'
      : `The following fields have invalid hex colors: ${invalidFields.join(', ')}. Please enter valid hex colors.`;

    return { confirmValidationCallback, message, invalidFields };
  }
}
