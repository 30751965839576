import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Nurture DTO
 */
export default class NurtureStageDTO extends BaseEntityDto
{
    /**
     * Name of the nurture
     */
    name: string;
    /**
     * Reference to the stages of the nurture
     */
    image_ref: any;
    /**
     * Reference to stage video assets
     */
    stageVideo_ref: any;
    /**
     * Reference to transitional video assets
     */
    transitionVideo_ref: any;
    /**
     * Id of the reward at the end
     */
    costs_ref: any = [{
        t: {
            id: 1,
            name: 'Currency',
            _id: "617b62958e2f0e4a67f86c76"
        },
        id: 
        {
            apiControllerRoute: 
            "currencies",
            id: 7,
            image: null,
            image_ref: null,
            name: "Water",
            _id: "63e61d4a20bd481c2ae19ef4"
        },
        c: 1,
        lineItemType: 'Currency',
    }];
}