import { AuthGuard } from "../auth/auth.guard";
import { AchievementTableAgGridComponent } from "../cms-v2/entities/achievement/components/achievement-table-ag-grid/achievement-table-ag-grid.component";
import { AchievementFormComponent } from "../entities/achievement/achievement-form/achievement-form.component";
import { AchievementViewV2Component } from "../entities/achievement/achievement-view-v2/achievement-view-v2.component";
import { AchievementFormV3Component } from "../entities/achievement/achievement-form-v3/achievement-form-v3.component";

export const achievementsRoutes = {
  prefix: 'achievements',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: AchievementTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: AchievementFormV3Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: AchievementViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: AchievementFormV3Component,
        canActivate: [AuthGuard],
      }
    ]
  }
};
