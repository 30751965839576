import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-audio-collection-view',
  templateUrl: './audio-collection-view.component.html',
  styleUrls: ['./audio-collection-view.component.sass'],
})
export class AudioCollectionViewComponent extends BaseDynamicViewComponent implements OnInit {
  audioCollectionRecord: BaseEntityDto = new BaseEntityDto();

  constructor()
  {
    super();
  }

  /**
   * Audio Collection V2 Initial Implementation
   */
  ngOnInit()
  {

    this.fields =
    [
      {
        title: "Color Data",
        fields:
        [
          { 
            name: "Name", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text],
            clearField: true, 
            key: 'name', 
            isRequired: true, 
            label: 'Name', 
            columnWidth: 6,  
          },
          {
            name: "Type",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.DropdownWithLabels],
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: true,
            options:
            {
              fieldName: 'type',
              values:
              [
                { name: 'Spawn Audios', value: 1 },
                { name: 'Loop Audios', value: 2 },
              ]
            }
          },
          // { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time' },
          // { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time' },
          { name: "Audio Assets",inputType: DynamicViewType.MiscImageRecordV2, viewMode: 'array',clearField: true, key: 'audios_ref', label: 'Audio Assets' },
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.audioCollectionRecord =
    {
      enabled: false
    };
    this.title = "Audio Collection";
    this.viewRoute = 'audio-collections';
    this.isLoading = false;

  }

}
