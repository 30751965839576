import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-mini-game-table-ag-grid',
  templateUrl: './mini-game-table-ag-grid.component.html',
  styleUrls: ['./mini-game-table-ag-grid.component.sass']
})
export class MiniGameTableAgGridComponent implements OnInit {

  public entity: string = 'minigames';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'minigames',
      enableDuplicateFeature: true,
    }

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService, this.tableParams);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            { label: 'Match the Plant', value: 1 },
            { label: 'Mole Hunt', value: 2 }, 
            { label: 'Sample Game', value: 3 },
            { label: 'Shuffle', value: 4 },
            { label: 'Little', value: 5 },
            { label: 'Dig', value: 6 },
            { label: 'Board', value: 7 },
            { label: 'Pachinko', value: 8 },
          ],
          valueFormatter: (params: any) => {
            return params.value.label;
          },
          keyCreator: (params: any) => {
            return params.value.value;
          }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellRenderer: (params: any) => {
          const typeMapping = [
            { label: 'Match the Plant', value: 1 },
            { label: 'Mole Hunt', value: 2 },
            { label: 'Sample Game', value: 3 },
            { label: 'Shuffle', value: 4 },
            { label: 'Little', value: 5 },
            { label: 'Dig', value: 6 },
            { label: 'Board', value: 7 },
            { label: 'Pachinko', value: 8 },
          ];
          const matchedType = typeMapping.find(type => type.value === params.value);
          return matchedType ? matchedType.label : 'Unknown';
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: [
            'Match the Plant',
            'Mole Hunt',
            'Sample Game',
            'Shuffle',
            'Little',
            'Dig',
            'Board',
            'Pachinko',
          ]
        },
        valueSetter: (params: any) => {
          const typeMapping = [
            { label: 'Match the Plant', value: 1 },
            { label: 'Mole Hunt', value: 2 },
            { label: 'Sample Game', value: 3 },
            { label: 'Shuffle', value: 4 },
            { label: 'Little', value: 5 },
            { label: 'Dig', value: 6 },
            { label: 'Board', value: 7 },
            { label: 'Pachinko', value: 8 },
          ];
          const matchedType = typeMapping.find(type => type.label === params.newValue);
          if (matchedType) {
            params.data.type = matchedType.value;
            return true;
          }
          return false;
        }
      },
      {
        headerName: 'Metadata',
        field: 'metadata',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 850,
        minWidth: 200,
        width: 400,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Banner Image',
        field: 'banner_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'banner_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.banner_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Logo Image',
        field: 'logoImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'logoImage_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.logoImage_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Bar Background',
        field: 'barBackground_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'barBackground_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.barBackground_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Bar Overlay',
        field: 'barOverlay_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'barOverlay_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.barOverlay_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Game Icon',
        field: 'gameIcon_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'gameIcon_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.gameIcon_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Welcome Popup Image',
        field: 'welcomePopupImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'welcomePopupImage_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.welcomePopupImage_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Welcome Popup Description',
        field: 'welcomePopupDescription',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        width: 500,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        type: 'editableColumn',
      },
      {
        headerName: 'Welcome Popup Background',
        field: 'welcomePopupBackground_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'welcomePopupBackground_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.welcomePopupBackground_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Welcome Popup Background',
        field: 'welcomePopupHeader_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'welcomePopupHeader_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.welcomePopupHeader_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Title Localization Key',
        field: 'titleLocalizationKey',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      // {
      //   headerName: 'Move Image',
      //   field: 'moveImage_ref',
      //   cellRenderer: MiscAssetCellRendererComponent,
      //   sortable: true,
      //   unSortIcon: true,
      //   resizable: true,
      //   filter: 'agTextColumnFilter',
      //   floatingFilter: true,
      //   filterParams: {
      //     filterKey: 'moveImage_ref',
      //     isAggregate: true,
      //     fieldKeys: ['name', 'id', 'path' ],
      //     isMiscBuild: true,
      //     values: this.options.moveImage_ref,
      //     populate: true,
      //     select: 'id name _id path'
      //   },
      //   width: 500,
      //   autoHeight: true,
      //   cellEditor: DropdownEditorComponent,
      //   type: 'editableColumn',
      //   cellEditorPopup: false,
      //   cellEditorParams: {
      //     optionValue: "_id",
      //     optionLabel: "name",
      //     objectValue: true
      //   },
      // },
      {
        headerName: 'Cost(s)',
        field: 'costs_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Reward(s)',
        field: 'rewards_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Max Mini Rewards',
        field: 'maxMiniRewards',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        width: 200,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        valueFormatter: (params: any) => {
          if (Array.isArray(params.value)) {
            return params.value.join(', ');
          }
          return params.value;
        },
      },
      {
        headerName: 'Rewards Track',
        field: 'rewardsTrack_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.rewardsTrack_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        width: 400,
      },
      {
        headerName: 'Minor Rewards Track',
        field: 'minorRewardsTrack_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.minorRewardsTrack_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        width: 400,
      },
      // {
      //   headerName: 'Move Name',
      //   field: 'moveName',
      //   cellRenderer: TextCellRendererComponent,
      //   sortable: true,
      //   unSortIcon: true,
      //   resizable: true,
      //   filter: 'agTextColumnFilter',
      //   floatingFilter: true,
      //   maxWidth: 450,
      //   minWidth: 200,
      //   width: 250,
      //   autoHeight: true,
      //   cellEditor: 'agLargeTextCellEditor',
      //   suppressKeyboardEvent: (params) => {
      //     return this.agGridToolsService.suppressEnter(params);
      //   },
      //   type: 'editableColumn',
      // },
      {
        headerName: 'Min. Level Requirement',
        field: 'minLevel',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: 'agNumberCellEditor',
        autoHeight: true,
      },
      {
        headerName: 'Min. Moves',
        field: 'minMoves',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: 'agNumberCellEditor',
        autoHeight: true,
      },
      {
        headerName: 'Mover per Round',
        field: 'movesPerRound',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: 'agNumberCellEditor',
        autoHeight: true,
      },
      {
        headerName: 'Main Background',
        field: 'mainBackground_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'mainBackground_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.mainBackground_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Mini Game Asset',
        field: 'minigame_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'minigame_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.minigame_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images
    }

    


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions() {
    const entities = [
      { ref: 'currencies_ref', entity: 'currencies' },
      { ref: 'resources_ref', entity: 'resources' },
      { ref: 'titles_ref', entity: 'titles' },
      { ref: 'banner_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'logoImage_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'moveImage_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'barOverlay_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'gameIcon_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'barBackground_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'welcomePopupImage_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'welcomePopupBackground_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'welcomePopupHeader_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'rewardsTrack_ref', entity: 'rewards-track', query: { type: 8 } },
      { ref: 'minorRewardsTrack_ref', entity: 'rewards-track', query: { type: 8 } },
      { ref: 'mainBackground_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'minigame_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: BuildType.Minigames } },
    ]

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }

  }

}
