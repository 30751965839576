<div *ngIf="!loading">
  <p-messages></p-messages>
  <p-toast></p-toast>
  <h1 *ngIf="!isEditMode" class="p-ml-4">Add New Achievement</h1>
  <h1 *ngIf="isEditMode">Editing Achievement {{ id }}</h1>
  <form [formGroup]="achievementForm" (ngSubmit)="onSubmit()">

    <div class="p-grid p-px-3">
      <div class="p-col-12">
        <button pButton pRipple type="submit" label="Submit" [disabled]="!achievementForm.valid"
          [style]="{ 'min-width': '15em' }"></button>

        <div class="p-my-3">
          <p-toggleButton formControlName="enabled" [onLabel]="'Enabled'" [offLabel]="'Not Enabled'"
            [style]="{ width: '100%' }" class="field-item"></p-toggleButton>
        </div>

        <div class="p-my-3">
          <p-card>
            <div class="p-grid p-px-3">
              <!-- title -->
              <div class="p-col-4 p-my-2 ">
                <div class="p-inputgroup">
                  <span ngClass="p-float-label ">
                    <input formControlName="title" pInputText class="field-item" />
                    <label for="title"> Title <strong style="color: crimson">*</strong></label>
                  </span>
                </div>
              </div>
              <div class="p-col-4 p-my-2">
                <div class="p-inputgroup">
                  <span ngClass="p-float-label">
                    <input formControlName="subText" pInputText class="field-item" />
                    <label for="subText">SubText</label>
                  </span>
                </div>
              </div>
              <!-- env -->
              <div class="p-col-4 p-my-2">
                <div class="p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-multiSelect formControlName="env" [options]="options['envs']" display="chip"
                      [style]="{ width: '100%' }" (onChange)="form.get('env')?.markAsTouched();"
                      (onClear)="form.get('env')?.markAsTouched();">
                    </p-multiSelect>
                    <label for="env"> Environment(s) </label>
                  </span>
                  <p-button *ngIf="achievementForm.value['env']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('env')"></p-button>
                </div>
              </div>
              <div class="p-col-3 p-my-2 ">
                <div class="p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-inputNumber formControlName="sortingOrder" [style]="{'width':'100%'}" class="field-item"
                      [min]="0" [max]="100" mode="decimal"></p-inputNumber>
                    <label for="sortingOrder"> Sorting Order </label>
                  </span>
                </div>
              </div>
              <div class="p-col-9 p-my-2"></div>
              <!-- isConsecutive -->
              <div class="p-col-3 p-my-2">
                <p-toggleButton formControlName="isConsecutive" [onLabel]="'Consecutive'" [offLabel]="'Not Consecutive'"
                  class="field-item" [style]="{'width':'100%'}"></p-toggleButton>
              </div>
              <div class="p-col-9 p-my-2"></div>
              <!-- start -->
              <div class="p-col-6 p-my-2">
                <div class=" p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-calendar formControlName="start" [showTime]="true" [yearNavigator]="true"
                      [style]="{'width':'100%'}" yearRange="2020:2030" [monthNavigator]="true" class="field-item"
                      [selectOtherMonths]="true" [defaultDate]="defaultDate"
                      (onInput)="handleDateInputChange('start',$event)"></p-calendar>
                    <label for="start"> Start Date/Time </label>
                  </span>
                  <p-button *ngIf="achievementForm.value['start']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('start')"></p-button>
                </div>
              </div>
              <!-- end -->
              <div class="p-col-6 p-my-2">
                <div class=" p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-calendar formControlName="end" [showTime]="true" [minDate]="achievementForm.get('start')!.value"
                      [style]="{'width':'100%'}" [yearNavigator]="true" yearRange="2020:2030" [monthNavigator]="true"
                      class="field-item" [selectOtherMonths]="true" [defaultDate]="defaultDate"
                      (onInput)="handleDateInputChange('end',$event)"></p-calendar>
                    <label for="end"> End Date/Time </label>
                  </span>
                  <p-button *ngIf="achievementForm.value['end']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('end')"></p-button>
                </div>

              </div>
              <!-- PROMO FIELDS -->
              <div class="p-col-12">
                <h3 class="p-mt-0">Promo Fields:</h3>
                <hr>
              </div>
              <!-- promoStartDate -->
              <div class="p-col-6 p-my-2 ">
                <div class=" p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-calendar formControlName="promoStartDate" [showTime]="true" [yearNavigator]="true"
                      [style]="{'width':'100%'}" yearRange="2020:2030" [monthNavigator]="true" class="field-item"
                      [selectOtherMonths]="true" [defaultDate]="defaultDate"
                      (onInput)="handleDateInputChange('promoStartDate',$event)"></p-calendar>
                    <label for="promoStartDate"> Promo Date/Time </label>
                  </span>
                  <p-button *ngIf="achievementForm.value['promoStartDate']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('promoStartDate')"></p-button>
                </div>
              </div>
              <!-- promoEndDate -->
              <div class="p-col-6 p-my-2">
                <div class=" p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-calendar formControlName="promoEndDate" [showTime]="true"
                      [minDate]="achievementForm.get('promoStartDate')!.value" [style]="{'width':'100%'}"
                      [yearNavigator]="true" yearRange="2020:2030" [monthNavigator]="true" class="field-item"
                      [selectOtherMonths]="true" [defaultDate]="defaultDate"
                      (onInput)="handleDateInputChange('promoEndDate',$event)"></p-calendar>
                    <label for="promoEndDate"> Promo Date/Time </label>
                  </span>
                  <p-button *ngIf="achievementForm.value['promoEndDate']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('promoEndDate')"></p-button>
                </div>

              </div>
            </div>
          </p-card>
        </div>

        <div class="p-my-3">
          <p-card>
            <div class="p-grid p-px-3">

              <label for="playerDescription" class="p-my-2"> Player Description <strong style="color: crimson">*</strong> </label> <label
                class="p-my-2 p-mx-4">Character Count: {{ characterCount }}</label>
              <p *ngIf="exceedsMaxCharacters()" [style]="{ color: 'red' }" class="p-my-2 p-mx-4">Single-line title max
                character is 35 char. Please use '&lt;br&gt;' for a linebreak.</p>
              <div class="p-col-12 p-my-2 p-inputgroup">
                <textarea formControlName="playerDescription" placeholder="Submit <target> challenges." cols="40"
                  rows="3" pInputTextarea class="field-item" (input)="updateCharacterCount($event)"></textarea>
                <p-button *ngIf="achievementForm.value['playerDescription']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('playerDescription')"></p-button>
              </div>
              <div class="p-col-6 p-my-2">
                <div class="p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-dropdown [style]="{'width':'100%'}" formControlName="interval" [options]="options['interval']"
                      optionLabel="name" optionValue="value" [autoDisplayFirst]="false" [filter]="true"></p-dropdown>
                    <label for="interval"> Interval </label>
                  </span>
                  <p-button *ngIf="achievementForm.value['interval']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('interval')"></p-button>
                </div>
              </div>

              <div class="p-col-6 p-my-2 ">
                <div class="p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-dropdown [style]="{'width':'100%'}" formControlName="achievementType"
                      [options]="options['achievementType']" optionLabel="name" optionValue="value"
                      [autoDisplayFirst]="false" [filter]="true"
                      (onChange)="checkAchievementTypeValue($event)"></p-dropdown>
                    <label for="achievementType"> Achievement Type <strong style="color: crimson">*</strong></label>
                  </span>
                  <p-button *ngIf="achievementForm.value['achievementType']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('achievementType')"></p-button>
                </div>
              </div>

              <!-- achievement type options container-->
              <div class="p-col-12" *ngIf="showOptions.showAchievementTypeOptions">
                <p-card class="p-mx-5">
                  <h3 class="p-mt-0">Achievement Type Options:</h3>

                  <div class="p-grid p-mx-3">
                    <!-- challengeType -->
                    <div class="p-col p-my-2 p-inputgroup" *ngIf="showOptions.showChallengeTypeOptions">
                      <span ngClass="p-float-label">
                        <p-dropdown [style]="{'width':'100%'}" formControlName="challengeType"
                          [options]="options['challenge-types']" optionLabel="name" optionValue="_id"
                          [autoDisplayFirst]="false" [filter]="true"
                          (onChange)="clearField('challengeClimate')"></p-dropdown>
                        <label for="challengeType"> Challenge Type </label>
                      </span>
                      <p-button *ngIf="achievementForm.value['challengeType']" ariaLabel="Clear Field"
                        icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                        (click)="clearField('challengeType')"></p-button>
                    </div>

                    <!-- challengeClimate -->
                    <div class="p-col p-my-2 p-inputgroup" *ngIf="showOptions.showChallengeClimateOptions">
                      <span ngClass="p-float-label">
                        <p-dropdown [style]="{'width':'100%'}" formControlName="challengeClimate"
                          [options]="options['climate-types']" optionLabel="name" optionValue="_id"
                          [autoDisplayFirst]="false" [filter]="true"
                          (onChange)="clearField('challengeType')"></p-dropdown>
                        <label for="challengeClimate"> Climate Type </label>
                      </span>
                      <p-button *ngIf="achievementForm.value['challengeClimate']" ariaLabel="Clear Field"
                        icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                        (click)="clearField('challengeClimate')"></p-button>
                    </div>

                    <!-- itemCategory -->
                    <div class="p-col p-my-2 p-inputgroup" *ngIf="showOptions.showItemCategoryOptions">
                      <span ngClass="p-float-label">
                        <p-dropdown [style]="{'width':'100%'}" formControlName="itemCategory"
                          [options]="options['item-categories']" optionLabel="name" optionValue="_id"
                          [autoDisplayFirst]="false" [filter]="true" (onChange)="clearField('itemType')"></p-dropdown>
                        <label for="itemCategory"> Item Category</label>
                      </span>
                      <p-button *ngIf="achievementForm.value['itemCategory']" ariaLabel="Clear Field" icon="pi pi-minus"
                        styleClass="p-button-text p-button-danger" (click)="clearField('itemCategory')"></p-button>
                    </div>

                    <!-- itemType -->
                    <div class="p-col p-my-2 p-inputgroup" *ngIf="showOptions.showItemTypeOptions">
                      <span ngClass="p-float-label">
                        <p-dropdown [style]="{'width':'100%'}" formControlName="itemType"
                          [options]="options['item-types']" optionLabel="name" optionValue="_id"
                          [autoDisplayFirst]="false" [filter]="true"
                          (onChange)="clearField('itemCategory')"></p-dropdown>
                        <label for="itemType"> Item Type</label>
                      </span>
                      <p-button *ngIf="achievementForm.value['itemType']" ariaLabel="Clear Field" icon="pi pi-minus"
                        styleClass="p-button-text p-button-danger" (click)="clearField('itemType')"></p-button>
                    </div>

                    <!-- challengeResultScore -->
                    <div class="p-col p-my-2 p-inputgroup" *ngIf="showOptions.showChallengeResultScoreInput">
                      <span ngClass="p-float-label">
                        <p-inputNumber formControlName="challengeResultScore" [style]="{'width':'100%'}"
                          class="field-item" [min]="0" [max]="300" [maxFractionDigits]="2"
                          mode="decimal"></p-inputNumber>
                        <label for="challengeResultScore"> Score </label>
                      </span>
                    </div>

                    <!-- MinimumIAPCost -->
                    <div class="p-col p-my-2 p-inputgroup" *ngIf="showOptions.showMinimumIAPCost">
                      <span ngClass="p-float-label">
                        <h3 class="p-mt-3"><strong>≥ $</strong></h3>
                        <p-inputNumber formControlName="minimumIAPCost" [style]="{'width':'100%'}" class="field-item"
                          [min]="0" [max]="99.99" [maxFractionDigits]="2" mode="decimal"></p-inputNumber>
                        <label for="challengeResultScore"> Score </label>
                      </span>
                    </div>
                  </div>
                </p-card>
              </div>

              <!-- target -->
              <div class="p-col-12 p-my-2">
                <div class="p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-chips formControlName="target" [style]="{'width':'100%'}" separator="," [showClear]="true"
                      [addOnTab]="true" [addOnBlur]="true" (onAdd)="validateTargetInput($event.value);"></p-chips>
                    <label for="target">Target(s)<strong style="color: crimson">*</strong></label>
                  </span>
                  <p-button *ngIf="achievementForm.value['target']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('target')"></p-button>
                </div>
              </div>

              <!-- link destination -->
              <div class="p-col-12 p-my-2">
                <div class="p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-dropdown
                      [style]="{'width':'100%'}"
                      formControlName="link_ref"
                      [options]="options['link_ref']"
                      optionLabel="linkText"
                      optionValue="_id"
                      [autoDisplayFirst]="false" [filter]="true"
                      (onChange)="checkAchievementTypeValue($event)"></p-dropdown>
                    <label for="link_ref"> Link Destination</label>
                  </span>
                  <p-button *ngIf="achievementForm.value['link_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('link_ref')"></p-button>
                </div>
              </div>

              <!-- isRepetitive -->
              <div class="p-col-3">
                <div class="p-mb-3">
                  <p-toggleButton formControlName="isRepetitive" [onLabel]="'Is Repetitive/Scheduled'"
                    [offLabel]="'Not Repetitive/Scheduled'" class="field-item p-mb-4"
                    [style]="{'width':'80%'}"></p-toggleButton>
                </div>
              </div>

              <!-- Repeats type options Container -->
              <div class="p-col-7" *ngIf="achievementForm.value['isRepetitive']">
                <div class="p-grid">
                  <div class="p-col-12">
                    <h4 class="p-mb-2">Repetitive Events Options:</h4>
                    <hr>
                  </div>
                  <div class="p-col-12">
                    <span ngClass="p-float-label p-mt-1">
                      <p-inputNumber formControlName="repeatsDuration" [style]="{'width':'100%'}" class="field-item"
                        [min]="1" mode="decimal"></p-inputNumber>
                      <label for="repeatsDuration"> Duration (Hrs) </label>
                      <small class="p-ml-3">Progression resets every X hours</small>
                    </span>
                  </div>
                  <div class="p-col-12">
                    <h4 class="inline-element">Available On:</h4>
                    <small class="p-ml-3 inline-element">Determines which days it shows up on</small>
                    <div class="p-ml-2">
                      <div *ngFor="let day of weekDays" class="field-checkbox p-my-1">
                        <p-checkbox [label]="day.label" [value]="day.value" [formControlName]="day.value"
                          binary="true"></p-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="p-col-12">
                    <hr>
                  </div>
                </div>
              </div>
              <div class="p-col-12 p-px-2"></div>
              <!-- rewards -->
              <div class="p-col-6 p-px-2">
                <!-- rewards_ref -->
                <div>
                  <p-fieldset formArrayName="rewards_ref">
                    <ng-template pTemplate="header">
                      <div class="p-formgroup-inline">
                        <span class="p-my-auto p-mr-2">Rewards(s)</span>
                        <button pButton icon="pi pi-plus p-panel-header-icon"
                          class="p-button-rounded p-button-text p-button" (click)="addReward()" type="button"></button>
                      </div>
                    </ng-template>

                    <span class="p-formgroup-inline">
                      <div *ngFor="let reward of rewards_ref.controls; let pointIndex = index "
                        [formGroupName]="pointIndex" class="p-m-3">
                        <p-card class="p-formgroup p-shadow-4">
                          <ng-template pTemplate="header">
                            <button pButton type="button" icon="pi pi-times"
                              class="p-button-rounded p-button-text p-button-danger float-right"
                              (click)="deleteReward(pointIndex)"></button>
                          </ng-template>

                          <ng-template pTemplate="content">
                            <div>
                              <p-dropdown formControlName="t" [options]="options['t']" placeholder="Select a Resource"
                                optionLabel="name" [showClear]="true" [autoDisplayFirst]="false" [filter]="true"
                                [filterBy]="'name'" [style]="{ width: '100%'}" [required]="true" (onChange)="
                          updateDependentField(
                            $event,
                            rewards_ref.controls[pointIndex]
                              .controls.t,
                            'lineItemType',
                            pointIndex,
                            rewards_ref.controls[pointIndex]
                              .controls.c
                          )
                        "></p-dropdown>
                            </div>
                            <div class="p-mt-4">
                              <p-autoComplete [suggestions]="suggestions['id']" [showEmptyMessage]="true"
                                (completeMethod)="
                          getSuggestionsForRef(
                            $event.query,
                            'id',
                            resourceMap[
                              rewards_ref.controls[pointIndex]
                                .controls.t.value.name
                            ]
                          )
                        " field="name" minLength="1" [style]="{ width: '100%'}" formControlName="id"
                                placeholder="Search by Name or ID" [required]="true">
                                <ng-template let-re pTemplate="selectedRe">
                                  {{ re.name }} ({{ re.id }})
                                </ng-template>

                                <ng-template let-re pTemplate="re">
                                  {{ re.name }} ({{ re.id }})
                                </ng-template>
                              </p-autoComplete>
                            </div>
                            <div ngClass="p-mt-4 p-float-label">
                              <p-inputNumber formControlName="c" class="field-item"
                                [style]="{ width: '100%'}"></p-inputNumber>
                              <label for="c"> Count </label>
                            </div>
                            <div class="p-mt-4" hidden>
                              <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
                                <input formControlName="lineItemType" pInputText class="field-item" />
                                <label for="lineItemType">
                                  Line Item Type
                                </label>
                              </span>
                            </div>
                          </ng-template>
                        </p-card>
                      </div>
                    </span>
                  </p-fieldset>
                </div>
              </div>
              <div class="p-col-6 p-px-2">
                <!-- promoRewards_ref -->
                <div>
                  <p-fieldset formArrayName="promoRewards_ref">
                    <ng-template pTemplate="header">
                      <div class="p-formgroup-inline">
                        <span class="p-my-auto p-mr-2">Promo Rewards(s)</span>
                        <button pButton icon="pi pi-plus p-panel-header-icon"
                          class="p-button-rounded p-button-text p-button" (click)="addPromoReward()"
                          type="button"></button>
                      </div>
                    </ng-template>

                    <span class="p-formgroup-inline">
                      <div *ngFor="let reward of promoRewards_ref.controls; let pointIndex = index "
                        [formGroupName]="pointIndex" class="p-m-3">
                        <p-card class="p-formgroup p-shadow-4">
                          <ng-template pTemplate="header">
                            <button pButton type="button" icon="pi pi-times"
                              class="p-button-rounded p-button-text p-button-danger float-right"
                              (click)="deletePromoReward(pointIndex)"></button>
                          </ng-template>

                          <ng-template pTemplate="content">
                            <div>
                              <p-dropdown formControlName="t" [options]="options['t']" placeholder="Select a Resource"
                                optionLabel="name" [showClear]="true" [autoDisplayFirst]="false" [filter]="true"
                                [filterBy]="'name'" [style]="{ width: '100%'}" [required]="true" (onChange)="
                          updateDependentField(
                            $event,
                            promoRewards_ref.controls[pointIndex]
                              .controls.t,
                            'lineItemType',
                            pointIndex,
                            promoRewards_ref.controls[pointIndex]
                              .controls.c
                          )
                        "></p-dropdown>
                            </div>
                            <div class="p-mt-4">
                              <p-autoComplete [suggestions]="suggestions['id']" [showEmptyMessage]="true"
                                (completeMethod)="
                          getSuggestionsForRef(
                            $event.query,
                            'id',
                            resourceMap[
                            promoRewards_ref.controls[pointIndex]
                                .controls.t.value.name
                            ]
                          )
                        " field="name" minLength="1" [style]="{ width: '100%'}" formControlName="id"
                                placeholder="Search by Name or ID" [required]="true">
                                <ng-template let-re pTemplate="selectedRe">
                                  {{ re.name }} ({{ re.id }})
                                </ng-template>

                                <ng-template let-re pTemplate="re">
                                  {{ re.name }} ({{ re.id }})
                                </ng-template>
                              </p-autoComplete>
                            </div>
                            <div ngClass="p-mt-4 p-float-label">
                              <p-inputNumber formControlName="c" class="field-item"
                                [style]="{ width: '100%'}"></p-inputNumber>
                              <label for="c"> Count </label>
                            </div>
                            <div class="p-mt-4" hidden>
                              <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
                                <input formControlName="lineItemType" pInputText class="field-item" />
                                <label for="lineItemType">
                                  Line Item Type
                                </label>
                              </span>
                            </div>
                          </ng-template>
                        </p-card>
                      </div>
                    </span>
                  </p-fieldset>
                </div>
              </div>
            </div>
          </p-card>
        </div>
        <!-- image -->
        <div class="p-my-3">
          <!-- File Uplaoder -->
          <file-uplaoder title="Upload Image" [entity]="existingDoc" [uploadURL]="fileUploaderUrl" [entityType]="key"
            (onFileUpload)="onFileUpload($event)">
          </file-uplaoder>
        </div>
      </div>

      <div class="p-col-12 p-m-3">
        <button pButton pRipple type="submit" label="Submit" [disabled]="!achievementForm.valid"
          [style]="{ 'min-width': '15em' }"></button>
      </div>
    </div>
  </form>
</div>
