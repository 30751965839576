import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { DateTime, IANAZone } from 'luxon';
import { InterceptorService } from 'src/app/services/interceptor.service';

@Injectable({
  providedIn: 'root',
})
export class TimezoneInterceptor implements HttpInterceptor {

  constructor(
    private interceptorService: InterceptorService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method === 'POST' || request.method === 'PATCH') {
      const requestWithTimezone = this.addTimezoneToRequestBody(request);
      return next.handle(requestWithTimezone);
    } else {
      // If the request method is not POST or PATCH, pass it through without modification
      return next.handle(request);
    }
  }

  private addTimezoneToRequestBody(request: HttpRequest<any>): HttpRequest<any> {
    const modifiedBody = this.modifyRequestBody(request.body);
    return request.clone({ body: modifiedBody });
  }

  private modifyRequestBody(body: any): any {
    if (body) {
      const modified = { ...body };
      var env: any = null;
      if(modified.env && modified.env.length > 0) {
        env = Object.keys(modified.env)[0];
      }

      // Check if 'start' or 'dto.start' or 'dto.storeStart' is present in the request body
      if (modified.start) {
        modified.start = this.interceptorService.adjustToPacificTime(modified.start);
      } else if (modified.dto && modified.dto.start) {
        modified.dto.start = this.interceptorService.adjustToPacificTime(modified.dto.start);
      } else if (modified.dto && modified.dto.storeStart) {
        modified.dto.storeStart[env] = this.interceptorService.adjustToPacificTime(modified.dto.storeStart[env]);
      } else if (modified.storeStart) {
        modified.storeStart[env] = this.interceptorService.adjustToPacificTime(modified.storeStart[env]);
      }

      // Similarly, adjust the 'end' or 'dto.end' or 'dto.storeEnd' if present
      if (modified.end) {
        modified.end = this.interceptorService.adjustToPacificTime(modified.end);
      } else if (modified.dto && modified.dto.end) {
        modified.dto.end = this.interceptorService.adjustToPacificTime(modified.dto.end);
      } else if (modified.dto && modified.dto.storeEnd) {
        modified.dto.storeEnd[env] = this.interceptorService.adjustToPacificTime(modified.dto.storeEnd[env]);
      } else if (modified.storeEnd) {
        modified.storeEnd[env] = this.interceptorService.adjustToPacificTime(modified.storeEnd[env]);
      }

      return modified;
    }
    return body;
  }
}


export const TimezoneInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TimezoneInterceptor,
  multi: true,
};
