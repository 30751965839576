<p-card class="p-shadow-4">
    <ng-template pTemplate="content">
      <div class="p-d-flex">
        <div class="p-mr-2" >
          <ul class="climateIconList">
            <li *ngIf="this.icon_deselected[0]?.areValuesNull == false">
                Names: <a href="/miscellaneous-build/{{this.icon_deselected[0].id ? this.icon_deselected[0].id : null}}" [ngStyle]="{ color: 'var(--primary-color)' }">{{this.icon_deselected[0].name}}</a>
            </li>
            <li *ngIf="this.icon_deselected[0]?.areValuesNull == false">
                Path: {{this.icon_deselected[0].path}}</li>
            <li *ngIf="this.icon_deselected[0]?.areValuesNull == false">
                <img
                [src]="this.icon_deselected[0].url"
                alt="Sorry No Deselected Preview is Available"
                class="climateIconPreviewImg"
                />
            </li>
            <li *ngIf="this.icon_deselected[0]?.areValuesNull == true">
                No Icons Available for Preview
            </li>
          </ul>
        </div>
        <div class="p-mr-2" >
          <ul class="climateIconList">
            <li *ngIf="this.icon_selected[0]?.areValuesNull == false">
              Name: <a href="/miscellaneous-build/{{this.icon_selected[0].id ? this.icon_selected[0].id : null}}" [ngStyle]="{ color: 'var(--primary-color)' }">{{this.icon_selected[0].name}}</a>
            </li>
            <li *ngIf="this.icon_selected[0]?.areValuesNull == false">
              Path: {{this.icon_selected[0].path}}
            </li>
            <li *ngIf="this.icon_selected[0]?.areValuesNull == false">
              <img
              [src]="this.icon_selected[0].url"
              alt="Sorry No Deselected Preview is Available"
              class="climateIconPreviewImg"
              />
            </li>
            <li *ngIf="this.icon_selected[0]?.areValuesNull == true">
              No Icons Available for Preview
            </li>
          </ul>
        </div>
        <div class="p-mr-2" >
          <ul class="climateIconList">
            <li *ngIf="this.icon_disabled[0]?.areValuesNull == false">
              Name: <a href="/miscellaneous-build/{{this.icon_disabled[0].id ? this.icon_disabled[0].id : null}}" [ngStyle]="{ color: 'var(--primary-color)' }">{{this.icon_disabled[0].name}}</a>
            </li>
            <li *ngIf="this.icon_disabled[0]?.areValuesNull == false">
              Path: {{this.icon_disabled[0].path}}
            </li>
            <li *ngIf="this.icon_disabled[0]?.areValuesNull == false">
              <img
              [src]="this.icon_disabled[0].url"
              alt="Sorry No Deselected Preview is Available"
              class="climateIconPreviewImg"
              />
            </li>
            <li *ngIf="this.icon_disabled[0]?.areValuesNull == true">
              No Icons Available for Preview
            </li>
          </ul>
        </div>
      </div>

      <h2>
        Climate Icons
      </h2>

      <div class="p-d-flex">
        <div class="p-mr-2" *ngFor="let deselected_climateIcon of this.climateIcon_deselected">
          <ul class="climateIconList">
            <li *ngIf="deselected_climateIcon?.areValuesNull == false">
              Names: <a href="/miscellaneous-build/{{deselected_climateIcon.id ? deselected_climateIcon.id : null}}" [ngStyle]="{ color: 'var(--primary-color)' }">{{deselected_climateIcon.name}}</a>
            </li>
            <li *ngIf="deselected_climateIcon?.areValuesNull == false">
              Path: {{deselected_climateIcon.path}}</li>
            <li *ngIf="deselected_climateIcon?.areValuesNull == false">
              <img
              [src]="deselected_climateIcon.url"
              alt="Sorry No Deselected Preview is Available"
              class="climateIconPreviewImg"
              />
            </li>
            <li *ngIf="deselected_climateIcon?.areValuesNull == true">
              No Deselected Climate Icons Available for Preview
            </li>
          </ul>
        </div>
        </div>
        <div class="p-d-flex">
          <div class="p-mr-2" *ngFor="let selected_climateIcon of this.climateIcon_selected">
            <ul class="climateIconList">
              <li *ngIf="selected_climateIcon?.areValuesNull == false">
                Names: <a href="/miscellaneous-build/{{selected_climateIcon.id ? selected_climateIcon.id : null}}" [ngStyle]="{ color: 'var(--primary-color)' }">{{selected_climateIcon.name}}</a>
              </li>
              <li *ngIf="selected_climateIcon?.areValuesNull == false">
                Path: {{selected_climateIcon.path}}</li>
              <li *ngIf="selected_climateIcon?.areValuesNull == false">
                <img
                [src]="selected_climateIcon.url"
                alt="Sorry No Deselected Preview is Available"
                class="climateIconPreviewImg"
                />
              </li>
              <li *ngIf="selected_climateIcon?.areValuesNull == true">
                No Selected Climate Icons Available for Preview
              </li>
            </ul>
          </div>
        </div>
        <div class="p-d-flex">
          <div class="p-mr-2" *ngFor="let climateIcon_disabled of this.climateIcon_disabled">
            <ul class="climateIconList">
              <li *ngIf="climateIcon_disabled?.areValuesNull == false">
                Names: <a href="/miscellaneous-build/{{climateIcon_disabled.id ? climateIcon_disabled.id : null}}" [ngStyle]="{ color: 'var(--primary-color)' }">{{climateIcon_disabled.name}}</a>
              </li>
              <li *ngIf="climateIcon_disabled?.areValuesNull == false">
                Path: {{climateIcon_disabled.path}}</li>
              <li *ngIf="climateIcon_disabled?.areValuesNull == false">
                <img
                [src]="climateIcon_disabled.url"
                alt="Sorry No Deselected Preview is Available"
                class="climateIconPreviewImg"
                />
              </li>
              <li *ngIf="climateIcon_disabled?.areValuesNull == true">
                No Disabled Climate Icons Available for Preview
              </li>
            </ul>
          </div>
        </div>

    </ng-template>
</p-card>

<!-- <p-card class="p-m-2 p-shadow-4">
    <ng-template pTemplate="header">
        <span class="p-d-flex">
          <span class="p-card-title">
            Climate Icons
          </span>
        </span>
    </ng-template>
    <ng-template pTemplate="content">

      </ng-template>
</p-card> -->
