import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';

@Component({
  selector: 'app-schedule-name-cell-renderer',
  templateUrl: './schedule-name.component.html',
  styleUrls: ['./schedule-name.component.sass']
})
export class ScheduleRefNameCellRendererComponent implements ICellRendererAngularComp {
  constructor(
    private tableCommunicationService: TableCommunicationService
  ) {}

  public params: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  handleClick(event: Event) {
    event.preventDefault();
    const scheduleRecord = this.params.data;
    
    // Emit event to open schedule dialog in edit mode
    this.tableCommunicationService.emitEvent({
      type: 'editSchedule',
      data: scheduleRecord
    });
  }
} 