import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonEntityService } from '../../services/common-entity.service';
import { ENTITY_FIELD_CONFIGURATIONS } from './constants';
import { MessageService } from 'primeng/api';
import { AgGridToolsService } from '../../ag-grid-table/services/ag-grid-tools.service';

interface CustomValue {
  [key: string]: any;
}

interface DuplicationPayload {
  parentId: string;
  fieldsToDuplicate: string[];
  customValues: CustomValue[];
}

@Component({
  selector: 'app-dup-dialog',
  templateUrl: './dup-dialog.component.html',
  styleUrls: ['./dup-dialog.component.css']
})
export class DupDialogComponent implements OnInit {

  @Input() entity: string;
  @Input() viewRoute: string;
  @Input() record: any;
  @Output() duplicationSuccess = new EventEmitter<any>();


  constructor(
    private commonEntityService: CommonEntityService,
    private messageService: MessageService,
    private agGridToolsService: AgGridToolsService
  ) { }

  public display: boolean = false;
  public payload: any = {};
  public tableData: any[] = [];
  public allSelected: boolean = false;
  public isStoreEntity: boolean = false;
  private storeEnv: 'dev' | 'test' | 'qa' | 'prod' = 'dev'


  async ngOnInit(): Promise<void> {
    // console.log('Dup dialog: ', this.entity, this.viewRoute);
    if (['price-points', 'store-listings-v2'].includes(this.entity)) {
      const selectedEnvironment = this.agGridToolsService.getSelectedEnvironment();
      this.storeEnv = selectedEnvironment.value;
      this.isStoreEntity = true;
    }
  }

  async show(payload: any): Promise<void> {
    this.payload = payload;

    await this.getRecordData();
    await this.buildDataForDisplay();

    this.display = true;

  }

  hide(): void {
    this.payload = {}
    this.display = false;
  }

  async getRecordData() {
    // Always fetch the record based on the payload's ID regardless of existing data
    this.record = await this.commonEntityService.findOneWithQuery(this.entity, { query: { _id: this.payload._id } });
  }



  async buildDataForDisplay() {
    const allowedFieldsForDup = ENTITY_FIELD_CONFIGURATIONS[this.entity] || [];

    this.tableData = allowedFieldsForDup.map(field => {
      let fieldValue = this.record[field.key];

      // Check if it's a store entity and the field is marked as store specific
      if (this.isStoreEntity && field.isStoreValue) {
        // Extract the value using the storeEnv key
        fieldValue = fieldValue ? fieldValue[this.storeEnv] : undefined;
      }

      let displayValue;
      switch (field.type) {
        case 'text':
          displayValue = Array.isArray(fieldValue) ? fieldValue.join(', ') : fieldValue;
          break;
        case 'multiselect':
          displayValue = fieldValue ? fieldValue.join(', ') : '';
          break;
        case 'ref':
          displayValue = fieldValue ? `${fieldValue.name} (${fieldValue.id || fieldValue._id})` : '';
          break;
        case 'date':
          displayValue = fieldValue;
          break;
        case 'boolean':
          displayValue = fieldValue ? 'Yes' : 'No';
          break;
        default:
          displayValue = fieldValue;
      }

      return {
        key: field.key,
        controller: field.controller || null,
        label: field.label,
        type: field.type,
        value: displayValue,
        duplicate: field.required ? true : false,
        required: field.required ? true : false,
      };
    });
  }


  // Call this method initially and after any change in selection to update the `allSelected` status
  updateAllSelected() {
    this.allSelected = this.tableData.every(item => item.duplicate);
  }

  toggleAllSelection() {
    this.allSelected = !this.allSelected;
    // Create a new array with updated objects to change the reference
    this.tableData = this.tableData.map(item => ({ ...item, duplicate: this.allSelected }));
  }

  onItemSelected() {
    this.updateAllSelected();
  }


  async onDuplicate() {
    // Step 1: Gather selected field keys and custom values for nameInput fields
    const selectedFields = this.tableData
      .filter(item => item.duplicate)
      .map(item => ({
        key: item.key,
        value: item.value,
        type: item.type
      }));

    // Check if any fields are selected
    if (selectedFields.length === 0) {
      // No fields selected, display error message
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Duplication Error',
        detail: 'No fields selected for duplication. Please select fields to duplicate and try again.'
      });
      return; // Exit the function if no fields are selected
    }

    // Construct the payload
    const payload: DuplicationPayload = {
      parentId: this.record._id,  // Assuming _id is available and correct
      fieldsToDuplicate: selectedFields.map(field => field.key),
      customValues: []
    };

    // Include customValues only if isStoreEntity is true
    if (this.isStoreEntity || this.entity === 'challenges') {
      payload.customValues = selectedFields.filter(field => field.type === 'nameInput').map(field => ({ [field.key]: field.value }));
    }

    // Call the duplicate service method
    try {
      const result = await this.commonEntityService.duplicate(this.entity, payload);
      console.log('Duplicate operation successful', result);
      // Successful duplication message including the ID of the new record
      this.messageService.add({
        severity: 'success',
        summary: 'Duplication Successful',
        detail: `New Record ID: ${result.id}`
      });
      this.duplicationSuccess.emit(result);

      setTimeout(() => {
        window.open(`/${this.viewRoute}/${result.id}`, '_blank');
      }, 500);

      this.hide();
    } catch (error: any) {
      console.error('Error duplicating record:', error);
      // Handle the error, e.g., show an error message to the user
      this.messageService.add({
        severity: 'error',
        summary: 'Duplication Failed',
        detail: error?.error?.message || 'Failed to duplicate record. Please try again.'
      });
    }
  }


}
