<div style="
  display: flex;
  flex-direction: column;  /* Stack children vertically */
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically */
  min-height: 35vh;">
  <div class="p-grid" style="width: 100%;">
    <div class="p-col-12">
      <label class="p-mb-3">Field: {{inputName}}</label>
    </div>
    <div *ngIf="editType?.value !== 'clear'" class="p-grid" style="width: 100%;">
      <!-- find and replace | find input  -->
      <div *ngIf="editType?.value === 'find-and-replace'" class="p-col-6">
        <div class="p-col-12">
          <p>Value to Replace</p>
          <input pInputText style="width: 100%;" [(ngModel)]="findValue" placeholder="Find">
        </div>
      </div>
      <!-- main input components -->
      <div [class]="editType?.value === 'find-and-replace' ? 'p-col-6' : 'p-col-12' ">
        <div class="p-col-12">
          <div *ngIf="editType?.value === 'find-and-replace'">
            <p>New Value</p>
          </div>

          <!-- Text Input -->
          <input *ngIf="inputType === 'text'" pInputText style="width: 100%;" [(ngModel)]="value" />

          <!-- Long Text Input (using Textarea) -->
          <textarea *ngIf="inputType === 'long-text'" pInputTextarea [(ngModel)]="value" rows="10"
            style="width: 100%;"></textarea>

          <!-- Rich Text Input -->
          <p-editor *ngIf="inputType === 'rich-text'" [(ngModel)]="value"></p-editor>

          <!-- Dropdown -->
          <p-dropdown *ngIf="inputType === 'dropdown'" [(ngModel)]="value" appendTo="body" [options]="inputOptions"
            [placeholder]="inputName" [optionLabel]="optionLabel" [filter]="true" [autofocusFilter]="true"
            [autoDisplayFirst]="false" [virtualScroll]="true"></p-dropdown>

          <!-- MultiSelect -->
          <p-multiSelect *ngIf="inputType === 'multiselect'" [options]="inputOptions" [optionLabel]="optionLabel"
            [(ngModel)]="value" appendTo="body">
          </p-multiSelect>

          <!-- Calendar -->
          <p-calendar *ngIf="inputType === 'date'" appendTo="body" [(ngModel)]="value" [showTime]="true"
            [monthNavigator]="true" [yearNavigator]="true" yearRange="2019:2035"
            (onSelect)="onDateSelect($event)"></p-calendar>

          <!-- Boolean -->
          <p-toggleButton *ngIf="inputType === 'boolean'" [(ngModel)]="value" [onLabel]="inputColumn.onLabel"
            [offLabel]="inputColumn.offLabel"></p-toggleButton>

          <!-- line-items -->
          <div *ngIf="inputType === 'line-items'">
            <prizes-card-form-group *ngIf="editType.value === 'overwrite'" [(prize)]="empty" [showPrize]="true"
              [fieldsetName]="inputName" [excludeResourceTypes]="['Titles']" (onPrizeChange)="onPrizeChange($event)">
            </prizes-card-form-group>
            <div class="p-grid" *ngIf="editType.value === 'line-item-resource' && loadedResourceTypeOptions">
              <div class="p-col-12">
                <p-dropdown [(ngModel)]="value.t" appendTo="body" [options]="inputOptions['t']"
                  [placeholder]="inputName+' Resource Type'" optionLabel="name" [filter]="true" [autofocusFilter]="true"
                  [autoDisplayFirst]="false" [virtualScroll]="true"
                  (onChange)="updateDependentFieldOptions($event)"></p-dropdown>
              </div>
              <div class="p-col-12">
                <p-dropdown *ngIf="loadedResourceOptions" appendTo="body" [style]="{ 'min-width': '15em' }"
                  [(ngModel)]="value.id" [options]="inputOptions['id']" placeholder="Select a Resource"
                  optionLabel="name" [filter]="true" [filterBy]="'name'" [showClear]="true" [autoDisplayFirst]="false"
                  [required]="true"></p-dropdown>
              </div>
            </div>
            <p-inputNumber *ngIf="editType.value === 'line-item-count'" [(ngModel)]="value"></p-inputNumber>
          </div>

        </div>
        <div class="p-col-12">
          <small class="p-mt-2">Enter the value for bulk editing.</small>
        </div>
      </div>
    </div>
    <div *ngIf="editType?.value === 'clear'">
      <p-tag icon="pi pi-arrow-circle-right" value="Clear edit type has no input, continue to validation"></p-tag>
    </div>
    <div *ngIf="editType?.value === 'percentageChange'" style="width: 100%;">
      <div class="p-grid">
        <div class="p-col-6">
          <h5 class="p-mb-1">Percentage Change Direction:</h5>
          <p-selectButton [options]="percentageChangeOptions" [(ngModel)]="percentageChangeDirection"
            optionLabel="label" optionValue="value">
            <ng-template let-item pTemplate>
              <i class="{{item.icon}}"></i>
              <small class="p-ml-2">{{item.label}}</small>
            </ng-template>
          </p-selectButton>
        </div>
        <div class="p-col-6">
          <h5 class="p-mb-1">Percentage Change</h5>
          <p-inputNumber [(ngModel)]="percentageChangeValue" [showButtons]="true" inputId="minmax" inputId="minmax"
            mode="decimal" [min]="0" [max]="100"></p-inputNumber>
        </div>
      </div>
    </div>
  </div>
</div>
