import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertToReadable'
})
export class ConvertToReadablePipe implements PipeTransform {
  transform(value: any): string {
    if (Array.isArray(value) || typeof value === 'object') {
      // Convert arrays and objects to a JSON string
      return JSON.stringify(value, null, 2); // Beautify the JSON output
    } else {
      // Return the value directly if it's a string or number
      return String(value);
    }
  }
}
