<h4>{{ field.label }}</h4>
<div class="p-grid">
  <div class="p-col-6" *ngFor="let reward of record[field.key]">
    <ul>
      <ng-container *ngIf="reward.lineItemType !== 'Item'; else itemBlock">
        <li *ngIf="reward.lineItemType !== 'SeedPackBox'; else seedPackBoxBlock">
          <img *ngIf="reward.id?.image_ref" [src]="reward.id.image_ref.thumbnail_url_32 + '?ts=' + timestamp" class="reward-image" alt="">
          <span class="reward-count">{{ reward.c | number }}</span>
          {{ reward.id.name }} ({{ reward.id.id }})
        </li>
      </ng-container>

      <ng-template #itemBlock>
        <li>
          <span class="reward-count">{{ reward.c | number }}</span>
          <a href="/items/{{ reward.id.id }}" target="_blank" class="item-link">{{ reward.id.name }} ({{ reward.id.id }})</a>
        </li>
      </ng-template>

      <ng-template #seedPackBoxBlock>
        <li>
          <span class="reward-count">{{ reward.c | number }}</span>
          <img *ngIf="reward.id?.box_image_ref" [src]="reward.id.box_image_ref.thumbnail_url_32 + '?ts=' + timestamp" class="reward-image" alt="">
          <a href="/seed-pack-box/{{ reward.id.id }}" target="_blank" class="item-link">{{ reward.id.name }} ({{ reward.id.id }})</a>
        </li>
      </ng-template>
    </ul>
  </div>
</div>
