<div *ngIf="record.buildStatus">
    <h4>Build Status:</h4>
    <span class="p-grid p-text-center p-d-none p-d-lg-flex">
      <div
        class="p-col p-mx-2"
        [ngStyle]="{
          position: 'relative',
          color: 'var(--primary-color-text)',
          'background-color': record.buildStatus.color,
          'border-radius': '0.5em'
        }"
      >
        <ng-container [ngSwitch]="record.buildStatus.text">
          <ng-container *ngSwitchCase="'n/a'">
            <h3 class="p-m-0 p-text-center">{{ field.name }} Not Found</h3>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <h3 class="p-d-inline p-m-0 p-text-center">
              {{ record.buildStatus.text }}
            </h3>
            <p class="p-d-inline">
              {{ record.buildStatus.date }}
            </p>

            <p-badge
              [value]="record.buildStatus.buildCount"
              [style]="{
                'background-color': 'var(--text-color)',
                position: 'absolute',
                right: '20%',
                top: '-1em'
              }"
            ></p-badge>
          </ng-container>
        </ng-container>
      </div>
    </span>
  </div>
