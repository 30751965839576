<p-messages *ngIf="buildType == buildTypes.Images" key="ImagePromotionMsg"></p-messages>
<p-messages *ngIf="buildType == buildTypes.Items || buildType == buildTypes.Levels"
  key="AssetPromotionMsg"></p-messages>
<div class="p-grid nested-grid p-fluid p-justify-center p-mt-2 p-mb-2" *ngIf="!isLoading">
  <div class="p-grid" style="width: 100%">
    <div class="p-col-8">
      <div *ngIf="isPromotionDisabled()" class="p-mb-2">
        <p-chip>
          <strong class="p-mr-1">i</strong>
          <small> {{promotionDisabledMessage}} </small>
        </p-chip>
      </div>
      <div class="p-grid" style="width: 100%">
        <div class="p-col buttonContainer">

          <button pButton pRipple icon="pi pi-cloud-upload" iconPos="left" type="button" (click)="onPromote()"
            style="width: auto !important;" class="p-mx-1 p-mb-1 inline "
            label="Promote {{ buildType == buildTypes.Images ? 'Image' : 'Asset' }}" [pTooltip]="promotionDisabledMessage ? 
              promotionDisabledMessage : 
              ('Promotes ' + (buildType == buildTypes.Images ? 'Image' : 'Asset'))" tooltipPosition="top"
            *ngIf="type == 'miscellaneous-build' || buildType == buildTypes.Levels || buildType == buildTypes.Images || buildType == buildTypes.Items"
            [disabled]="isPromotionDisabled()"></button>
          <button pButton pRipple icon="pi pi-cloud-upload" iconPos="left" type="button"
            (click)="onPromote(false, true)" style="width: auto !important;"
            class="p-mx-1 p-mb-1 inline forceHashButton"
            label="Promote {{ buildType == buildTypes.Images ? 'Image' : 'Asset' }} force new hash"
            [pTooltip]="promotionDisabledMessage ? 
              promotionDisabledMessage : 
              ('Promotes ' + (buildType == buildTypes.Images ? 'Image' : 'Asset') + ' and generates a new hash for gamedata')" tooltipPosition="top"
            *ngIf="type == 'miscellaneous-build' || buildType == buildTypes.Levels || buildType == buildTypes.Images || buildType == buildTypes.Items"
            [disabled]="isPromotionDisabled()"></button>
        </div>
      </div>
      <div class="p-grid" style="width: 100%">
        <div class="p-col buttonContainer">
          <button pButton pRipple icon="pi pi-cloud-upload" iconPos="left" type="button" class="p-mx-1 p-my-0 inline"
            style="width: auto !important;" label="Promote Asset & Image"
            *ngIf="buildType == buildTypes.Levels || buildType == buildTypes.Items" (click)="onPromote(true)"
            [disabled]="isPromotionDisabled()" tooltipPosition="top" tooltipPositionStyle="absolute"
            tooltipEvent="hover"
            [pTooltip]="promotionDisabledMessage ? promotionDisabledMessage : 'Promotes asset & image'"></button>
          <button pButton pRipple icon="pi pi-cloud-upload" iconPos="left" type="button"
            class="p-mx-1 p-my-0 inline forceHashButton" style="width: auto !important;" (click)="onPromote(true, true)"
            label="Promote Asset & Image force new hash"
            [pTooltip]="promotionDisabledMessage ? promotionDisabledMessage : 'Promotes asset & image and generates a new hash for gamedata'"
            tooltipPosition="top" *ngIf="buildType == buildTypes.Levels || buildType == buildTypes.Items"
            [disabled]="isPromotionDisabled()"></button>
        </div>
      </div>
    </div>
    <div class="p-col-4" *ngIf="entity">
      <div class="p-grid" style="width: 100%">
        <div class="p-col p-d-flex p-jc-end">
          <a routerLink="/asset-versions/{{ this.type }}/{{ buildType == buildTypes.Images ? 'image' : 'asset' }}/{{ this.entity.id }}"
            target="_blank">
            <button pButton pRipple class="p-m-0" icon="pi pi-list" type="button" iconPos="right"
              label="Versions History" style="width: auto !important;"
              *ngIf="entity.asset_versions || entity.image_versions"></button>
          </a>
        </div>
      </div>
      <div class="p-grid" style="width: 100%">
        <div class="p-col p-d-flex p-jc-end">
          <!-- Detailed Data Button -->
          <button *ngIf="buildData && buildData.length > 0" pButton pRipple class="p-m-0"
            style="width: auto !important;" icon="pi pi-external-link" type="button" iconPos="right"
            label="Detailed data" (click)="displayDataModal = true"></button>
        </div>
      </div>
    </div>
  </div>

  <!-- <h3 class="p-col-12">Build Data</h3> -->
  <ng-container *ngIf="buildData && buildData.length > 0">
    <div class="p-grid p-mt-0" style="width: 100%;">
      <div class="p-col">
        <div class="p-grid p-d-flex p-jc-center">
          <div *ngFor="let build of buildData" class="p-col-3" style="max-width: 165px">
            <button pButton class="p-button" [ngStyle]="{
                backgroundColor: getColorFromBuildStatus(build.status)
              }" (click)="buildDetails.toggle($event)">
              <span class="p-d-block">
                <span class="p-text-center">
                  <img src="{{
                      '../../assets/selected-icons/os-logos-wht/' +
                        build.platforms[0] +
                        '-logo.png'
                    }}" alt="{{ build.platforms[0] }}"
                    style="width: 45%; min-width: 30px; height: 100%; margin: 0 auto" />
                </span>
                <br>
                <span class="p-text-left" style="font-size: 13px;">
                  <strong>{{ build.status == 'queued' ? 'Queued at:' : 'Started at:' }}</strong>
                  {{ build.status == 'queued' ? (build.insertedAt | officeTime) : (build.startedAt | officeTime) }}
                </span>
                <br />
                <span class="p-text-left" style="font-size: 13px;" *ngIf="build.status == 'finished'">
                  <strong>Finished at:</strong> {{ build.finishedAt | officeTime }}
                </span>
                <br>
                <span class="p-text-left" style="font-size: 13px;" *ngIf="build.user">
                  <strong>By:</strong> {{ build.user.name }}
                </span>
                <br>
                <span class="p-text-left" *ngIf="build.files">
                  <strong>Size:</strong>
                  <div [ngStyle]="{
                    background: getColorFromFileSize(build)
                  }" [style]="{
                    'border-radius': '10px',
                    'padding': '3px',
                    'text-align': 'center'
                  }">
                    <span [style]="{
                    'mix-blend-mode': 'difference',
                    'color': 'white'
                    }">
                      {{getSize(build.files)}}
                    </span>
                  </div>
                </span>
              </span>
            </button>

            <p-overlayPanel #buildDetails [dismissable]="true" [showCloseIcon]="false">
              <div style="max-width: 800px">
                <h2>{{ build.platforms[0] }}</h2>

                <h3>Status: {{ build.status }}</h3>

                <strong>Started:</strong>
                {{ build.startedAt | officeTime }} <br />

                <strong>Finished:</strong>
                {{ build.finishedAt | officeTime }} <br />

                <strong>Errors:</strong>
                <ng-container *ngIf="build.error_count > 0">
                  <span *ngFor="let error of build.errors">
                    {{ error }}
                  </span>
                </ng-container>
              </div>
            </p-overlayPanel>
          </div>
        </div>
        <div class="p-grid">
          <div class="p-col-3">
            <p-button label="Refresh" icon="pi pi-refresh" iconPos="right" [loading]="isLoading"
              (onClick)="getBuildData()" class="p-button-info"></p-button>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Build Data Information Modal -->
  <p-dialog header="Build Data Information" [(visible)]="displayDataModal" [modal]="true" [style]="{width: '50vw'}"
    [maximizable]="true" [draggable]="false" [resizable]="false">
    <build-history-chart *ngIf="!hideBuildTimeHistory" [entity]="entity"></build-history-chart>
    <p-table [value]="buildData" responsiveLayout="scroll" [scrollable]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>Platform</th>
          <th>Current Status</th>
          <th>Started At</th>
          <th>Finished At</th>
          <th>Errors</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-build>
        <tr>
          <td>{{ setPlatformName(build.platforms[0]) }}</td>
          <td><button pButton pRipple label="{{ build.status.toUpperCase() }}"
              class="p-button-outlined p-button-{{setPlatformStatusColor(build.status)}}"></button></td>
          <td>{{ build.startedAt | officeTime }}</td>
          <td>{{ build.finishedAt | officeTime }}</td>
          <td>
            <ng-container *ngIf="build.error_count > 0">
              <span *ngFor="let error of build.errors">
                {{ error }}
              </span>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-dialog>
</div>