<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="audio-collections"
    [formName]="title"
    [(record)]="audioCollectionRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    (onFieldChange)="UpdateDependentOptions($event)"
    (onPreLoad)="preLoad($event)"
>
</dynamic-form-v2>
