<div style="overflow-x: scroll; overflow-y: scroll; max-height: 88vh">
    <h1>
        {{ title }}

        <span class="p-d-inline">
      <p-badge
        [value]="table.totalRecords + ' / ' + rows.length"
        size="xlarge"
        class="p-mx-2"
      >
      </p-badge>
      <p-badge
        [value]="selectedRows.length + ' rows selected'"
        size="xlarge"
        class="p-mx-2"
      >
      </p-badge>

      <p-button
        *ngIf="selectedRows.length > 0"
        label="Clear Row Selection"
        styleClass="p-mx-2 p-my-auto p-button-text"
        (click)="onResetRows()"
      ></p-button>
    </span>
    </h1>

    <!-- start of the table.  -->
    <p-table #table [columns]="selectedColumns" [value]="rows" [autoLayout]="true" [reorderableColumns]="true" sortMode="single" [(sortField)]="tableState.sortField" [(sortOrder)]="tableState.sortOrder" (sortFunction)="customSort($event)" [customSort]="true"
        [globalFilterFields]="globalFilters" (onFilter)="onFilter($event)" selectionMode="multiple" [selection]="selectedRows" (onRowSelect)="onRowCheckboxToggle($event.data, true)" (onRowUnselect)="onRowCheckboxToggle($event.data, false)" [paginator]="true"
        [(rows)]="tableState.rows" [(first)]="tableState.first" [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [rowsPerPageOptions]="[25, 50, 100, 250]" editMode="row" dataKey="id" [loading]="isLoading">
        <ng-template pTemplate="caption">
            <!-- Caption Controls -->
            <div class="p-d-flex" *ngIf="!isLoading">
                <!-- Columns Button -->
                <p-button label="Columns" icon="pi pi-list" [badge]="selectedColumns.length" badgeClass="danger" class="p-mx-2" [styleClass]="
            selectedColumns.length > 0 ? 'p-button' : 'p-button-outlined'
          " (click)="showColumns = !showColumns"></p-button>

                <span class="p-ml-auto" [ngStyle]="{ float: 'right' }">
          <!-- Debug Buttons -->
          <!-- <p-button
          label="Debug"
          styleClass="p-mx-2 p-button-help"
          (click)="debug()"
        ></p-button>
        <p-button
          label="Delete LocalStorage"
          styleClass="p-mx-2 p-button-help"
          (click)="deleteState()"
        ></p-button> -->

          <!-- Actions Button -->
          <p-button
            label="Actions"
            class="p-mx-2"
            [styleClass]="showActions ? 'p-button' : 'p-button-outlined'"
            (click)="showActions = !showActions"
          ></p-button>

          <!-- Filters Button -->
          <p-button
            label="Filters"
            icon="pi pi-list"
            [badge]="tableState.filterSelection.length"
            badgeClass="danger"
            class="p-mx-2"
            [styleClass]="
              tableState.filterSelection.length > 0
                ? 'p-button'
                : 'p-button-outlined'
            "
            (click)="showFilters = !showFilters"
          >
          </p-button>

          <!-- Global Search -->
          <span class="p-input-icon-left p-mx-2">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="table.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search"
            />
          </span>
                </span>
            </div>

            <!-- Modals, Overlays, and Sidebars -->
            <!-- COLUMNS Sidebar -->
            <p-sidebar #columnsSidebar *ngIf="!isLoading" [(visible)]="showColumns" styleClass="column-sidebar p-sidebar-sm" [style]="{ 'min-width': '27em' }" [blockScroll]="false">
                <div class="p-d-flex p-flex-row p-ai-center">
                    <h3 class="p-m-0">Columns</h3>
                    <p-badge *ngIf="selectedColumns.length > 0" [value]="selectedColumns.length + ' / ' + columns.length" class="p-ml-auto p-my-auto">
                    </p-badge>
                </div>

                <!-- Column Sets -->
                <div class="p-my-3">
                    <div class="p-d-flex p-jc-center">
                        <h5 class="p-my-1 p-mx-auto">Column Sets</h5>
                    </div>

                    <p-listbox [(ngModel)]="activeColumnSetName" [options]="columnSetGroups" [group]="true" optionValue="name" optionGroupLabel="name" optionGroupChildren="value" emptyMessage="No custom Column Sets found" (onChange)="onSelectColumnSet()">
                        <ng-template pTemplate="group" let-group>
                            <div class="p-d-flex p-fluid p-ai-center p-jc-between">
                                <span>
                  <i [class]="group.icon + ' p-mr-3'"></i>

                  {{ group.name }}
                </span>

                                <p-button *ngIf="
                    !showAddColumnSet &&
                    !['globalColumnSets'].includes(group.key)
                  " #showAddColumnSet_button ariaLabel="Add Column Set" icon="pi pi-plus" class="p-p-0" styleClass="p-button-rounded p-button-text p-button-sm" (click)="showAddColumnSet = !showAddColumnSet"></p-button>
                            </div>

                            <div *ngIf="
                  showAddColumnSet && !['globalColumnSets'].includes(group.key)
                " class="p-mt-2 p-inputgroup" pTooltip="Name and Value of new set must be unique and cannot be blank." tooltipPosition="right" showDelay="2000">
                                <input #columnSetName_input type="text" pInputText ariaLabel="Name of Column Set" placeholder="Name of Column Set" (keyup.enter)="
                    checkForColumnSet();
                    onAddColumnSet(columnSetName_input.value)
                  " />
                                <span class="p-buttonset">
                  <p-button
                    icon="pi pi-times"
                    styleClass="p-button-outlined"
                    (click)="showAddColumnSet = !showAddColumnSet"
                  >
                  </p-button>
                  <p-button
                    icon="pi pi-check"
                    (click)="
                      checkForColumnSet();
                      onAddColumnSet(columnSetName_input.value)
                    "
                  ></p-button>
                </span>
                            </div>
                        </ng-template>

                        <ng-template pTemplate="item" let-set>
                            <div #columnSetItem style="width: 100%">
                                <span class="p-d-flex p-jc-between p-ai-center">
                  {{ set.name }}

                  <p-button
                    *ngIf="!set.isGlobal"
                    ariaLabel="Remove Column Set"
                    icon="pi pi-trash"
                    class="p-p-0"
                    styleClass="p-button-rounded p-button-text p-button-sm p-button-danger"
                    (click)="
                      removeColumnSet_overlay.toggle($event, columnSetItem)
                    "
                  ></p-button>
                </span>
                            </div>
                        </ng-template>
                    </p-listbox>
                </div>

                <!-- Column Selection -->
                <div class="p-my-3 p-fluid">
                    <div class="p-d-flex p-jc-center">
                        <h5 class="p-my-1 p-mx-auto">Column Selection</h5>
                    </div>

                    <p-listbox [(ngModel)]="tableState.columnSelection" id="colSelect" [options]="columns" optionLabel="name" optionValue="key" [multiple]="true" [checkbox]="true" [filter]="true" [listStyle]="{ width: '100%', height: '100%' }" (onChange)="onSelectColumn()">
                        <ng-template pTemplate="item" let-item>
                            <div style="width: 100%">
                                <span class="p-d-flex p-fluid p-jc-between">
                  <span class="p-text-bold">
                    {{ item.name }}
                  </span>
                                <span class="p-text-right">{{ item.key }}</span>
                                </span>
                            </div>
                        </ng-template>
                    </p-listbox>
                </div>
            </p-sidebar>

            <!-- COLUMNS 'Confirm Remove Set' Overlay -->
            <p-overlayPanel #removeColumnSet_overlay [dismissable]="true">
                <ng-template pTemplate="content">
                    <div class="p-mb-2 p-d-flex p-ai-center">
                        <i class="pi pi-exclamation-triangle p-mr-2" style="color: 'var(--pink-500)'; font-size: 1.5em"></i>
                        <h3 class="p-my-0">Confirm</h3>
                    </div>

                    <div class="p-p-2">
                        Are you sure you want to delete the set,
                        <strong>{{ activeColumnSetName }}</strong
            >?
          </div>

          <div class="p-mt-3 p-d-flex p-jc-end">
            <p-button
              label="Nope"
              class="p-mx-2"
              styleClass="p-button-outlined"
              (click)="removeColumnSet_overlay.hide()"
            ></p-button>
            <p-button
              label="Yup"
              icon="pi pi-check"
              class="p-mx-2"
              (click)="
                onRemoveColumnSet(activeColumnSetName);
                removeColumnSet_overlay.hide()
              "
            ></p-button>
          </div>
        </ng-template>
      </p-overlayPanel>

      <!-- FILTERS Sidebar -->
      <p-sidebar
        #filtersSidebar
        *ngIf="!isLoading"
        [(visible)]="showFilters"
        position="right"
        [modal]="false"
        id="filter-sidebar"
        styleClass="filter-sidebar"
        [style]="{ 'min-width': '20em' }"
      >
        <div class="p-d-flex p-flex-row p-ai-center">
          <h3 class="p-m-0">Filters</h3>
          <p-button
            *ngIf="tableState.filterSelection.length > 0"
            ariaLabel="Clear Filters"
            label="Clear Filters"
            class="p-ml-auto p-my-auto"
            styleClass="p-button-outlined p-button-sm"
            (click)="onClearAllFilters()"
          ></p-button>
        </div>

        <!-- Filter Sets -->
        <div class="p-my-3">
          <div class="p-d-flex p-jc-center">
            <h5 class="p-my-1 p-mx-auto">Filter Sets</h5>
          </div>

          <p-listbox
            [(ngModel)]="activeFilterSetName"
            [options]="filterSetGroups"
            [group]="true"
            optionValue="name"
            optionGroupLabel="name"
            optionGroupChildren="value"
            emptyMessage="No custom Filter Sets found"
            (onChange)="onSelectFilterSet(); onViewActiveFiltersOnly()"
          >
            <ng-template pTemplate="group" let-group>
              <div class="p-d-flex p-fluid p-ai-center p-jc-between">
                <span>
                  <i [class]="group.icon + ' p-mr-3'"></i>

                  {{ group.name }}
                </span>

                <p-button
                  *ngIf="
                    !showAddFilterSet &&
                    !['globalFilterSets'].includes(group.key)
                  "
                  #showAddFilterSet_button
                  ariaLabel="Add Filter Set"
                  icon="pi pi-plus"
                  class="p-p-0"
                  styleClass="p-button-rounded p-button-text p-button-sm"
                  (click)="showAddFilterSet = !showAddFilterSet"
                ></p-button>
              </div>

              <div
                *ngIf="
                  showAddFilterSet && !['globalFilterSets'].includes(group.key)
                "
                class="p-mt-2 p-inputgroup"
                pTooltip="Name and Value of new set must be unique and cannot be blank."
                tooltipPosition="left"
                showDelay="2000"
              >
                <input
                  #filterSetName_input
                  type="text"
                  pInputText
                  ariaLabel="Name of Filter Set"
                  placeholder="Name of Filter Set"
                  (keyup.enter)="
                    checkForFilterSet();
                    onAddFilterSet(filterSetName_input.value)
                  "
                />
                <span class="p-buttonset">
                  <p-button
                    icon="pi pi-times"
                    styleClass="p-button-outlined"
                    (click)="showAddFilterSet = !showAddFilterSet"
                  >
                  </p-button>
                  <p-button
                    icon="pi pi-check"
                    (click)="
                      checkForFilterSet();
                      onAddFilterSet(filterSetName_input.value)
                    "
                  ></p-button>
                </span>
              </div>
            </ng-template>

            <ng-template pTemplate="item" let-set>
              <div #filterSetItem style="width: 100%">
                <span class="p-d-flex p-jc-between p-ai-center">
                  {{ set.name }}

                  <p-button
                    *ngIf="!set.isGlobal"
                    ariaLabel="Remove Column Set"
                    icon="pi pi-trash"
                    class="p-p-0"
                    styleClass="p-button-rounded p-button-text p-button-sm p-button-danger"
                    (click)="
                      removeFilterSet_overlay.toggle($event, filterSetItem)
                    "
                  ></p-button>
                </span>
              </div>
            </ng-template>
          </p-listbox>
        </div>

        <!-- Filter Field Selection -->
        <div class="p-my-3">
          <div class="p-d-flex p-jc-center">
            <h5 class="p-my-1 p-mx-auto">Filter Fields</h5>
          </div>

          <!-- Search for filter field -->
          <div class="p-mb-3">
            <span class="p-input-icon-left" [style]="{ width: '100%' }">
              <i class="pi pi-search"></i>
              <input
                #filterSearch_input
                pInputText
                [style]="{ width: '100%' }"
                placeholder="Search"
                class="p-inputtext-lg p-fluid"
                (input)="onSearchFilters($event.target.value)"
              />
            </span>

            <div class="p-buttonset">
              <p-button
                ariaLabel="View All Filters"
                icon="pi pi-eye"
                label="All"
                [style]="{ width: '50%' }"
                [styleClass]="
                  isAllFilterFields()
                    ? 'p-button p-button-sm'
                    : 'p-button-outlined p-button-sm'
                "
                (click)="onSearchFilters('')"
              ></p-button>

              <p-button
                ariaLabel="View Active Filters"
                icon="pi pi-eye"
                label="Active"
                [style]="{ width: '50%' }"
                [styleClass]="
                  isActiveFilterFields()
                    ? 'p-button p-button-sm'
                    : 'p-button-outlined p-button-sm'
                "
                (click)="onViewActiveFiltersOnly()"
              ></p-button>
            </div>
          </div>

          <ng-container
            *ngFor="let field of filterFields"
            [ngSwitch]="field.controlType"
          >
            <div *ngIf="field.filterKey" class="p-inputgroup">
              <p-checkbox
                [(ngModel)]="tableState.filterSelection"
                name="filterFields"
                [value]="field.filterKey"
                (onChange)="onApplyFilter(field.filterKey)"
              >
              </p-checkbox>

              <div
                *ngSwitchCase="
                  ['inputText', 'inputTextarea'].includes(field.controlType)
                    ? field.controlType
                    : ''
                "
                class="filter-container p-float-label"
              >
                <input
                  [(ngModel)]="filterValues[field.filterKey]"
                  [id]="field.key"
                  [name]="field.filterKey"
                  pInputText
                  [style]="{ width: '100%' }"
                  (input)="
                    autoSelectFilter(field.filterKey);
                    onApplyFilter(field.filterKey)
                  "
                />
                <label [for]="field.filterKey"> {{ field.name }} </label>
              </div>

              <div
                *ngSwitchCase="
                  ['dropdown_ref', 'multiSelect_ref'].includes(
                    field.controlType
                  )
                    ? field.controlType
                    : ''
                "
                class="filter-container p-float-label"
              >
                <p-multiSelect
                  [(ngModel)]="filterValues[field.filterKey]"
                  [id]="field.key"
                  [options]="options[field.key]"
                  optionLabel="name"
                  [showClear]="true"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  [filterBy]="'name'"
                  display="chip"
                  [style]="{ width: '100%' }"
                  scrollHeight="350px"
                  (onChange)="
                    autoSelectFilter(field.filterKey);
                    onApplyFilter(field.filterKey)
                  "
                >
                </p-multiSelect>
                <label [for]="field.key"> {{ field.name }} </label>
              </div>

              <div
                *ngSwitchCase="
                  ['dropdown', 'multiSelect', 'auto_dropdown'].includes(
                    field.controlType
                  )
                    ? field.controlType
                    : ''
                "
                class="filter-container p-float-label"
              >
                <p-multiSelect
                  [(ngModel)]="filterValues[field.filterKey]"
                  [id]="field.key"
                  [options]="options[field.key]"
                  [showClear]="true"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  display="chip"
                  [style]="{ width: '100%' }"
                  scrollHeight="350px"
                  (onChange)="
                    autoSelectFilter(field.filterKey);
                    onApplyFilter(field.filterKey)
                  "
                >
                </p-multiSelect>
                <label [for]="field.key"> {{ field.name }} </label>
              </div>

              <div
                *ngSwitchCase="
                  ['autoComplete_ref', 'autoComplete-multi_ref'].includes(
                    field.controlType
                  )
                    ? field.controlType
                    : ''
                "
                class="filter-container p-float-label"
              >
                <p-autoComplete
                  [(ngModel)]="filterValues[field.filterKey]"
                  [id]="field.key"
                  [suggestions]="suggestions[field.key]"
                  (completeMethod)="
                    getSuggestionsForRef($event.query, field.key, field.model)
                  "
                  field="name"
                  minLength="3"
                  [forceSelection]="true"
                  [multiple]="true"
                  [style]="{ width: '100%' }"
                  [inputStyle]="{ width: '100%' }"
                  (onSelect)="
                    autoSelectFilter(field.filterKey);
                    onApplyFilter(field.filterKey)
                  "
                  (onUnselect)="onApplyFilter(field.filterKey)"
                >
                  <ng-template pTemplate="item" let-item>
                    {{ item.name }} ({{ item.id }})
                  </ng-template>

                  <ng-template pTemplate="selectedItem" let-item>
                    {{ item.name }} ({{ item.id }})
                  </ng-template>
                </p-autoComplete>
                <label [for]="field.key"> {{ field.name }}</label>
              </div>

              <div
                *ngSwitchCase="
                  ['calendar-start', 'calendar-end', 'date'].includes(
                    field.controlType
                  )
                    ? field.controlType
                    : ''
                "
                class="filter-container p-float-label"
              >
                <p-calendar
                  [(ngModel)]="filterValues[field.filterKey]"
                  [id]="field.key"
                  [readonlyInput]="true"
                  [style]="{ width: '100%' }"
                  selectionMode="range"
                  (onSelect)="
                    autoSelectFilter(field.filterKey);
                    onApplyFilter(field.filterKey)
                  "
                ></p-calendar>
                <label [for]="field.key">{{ field.name }} Range</label>
              </div>

              <div
                *ngSwitchCase="'toggleButton'"
                class="filter-container p-float-label"
              >
                <p-dropdown
                  [(ngModel)]="filterValues[field.filterKey]"
                  [id]="field.key"
                  [autoDisplayFirst]="false"
                  [options]="['Yes', 'No', 'Empty']"
                  [style]="{ width: '100%' }"
                  (onChange)="
                    autoSelectFilter(field.filterKey);
                    onApplyFilter(field.filterKey)
                  "
                >
                </p-dropdown>
                <label [for]="field.key">{{ field.name }}</label>
              </div>

              <!-- 'clear filter' button -->
              <div class="p-my-auto p-mx-0">
                <p-button
                  ariaLabel="Clear Filter"
                  icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger"
                  (click)="onClearFilter(field.filterKey)"
                ></p-button>
              </div>
            </div>
          </ng-container>
        </div>
      </p-sidebar>

      <!-- FILTERS 'Confirm Remove Set' Overlay -->
      <p-overlayPanel #removeFilterSet_overlay [dismissable]="true">
        <ng-template pTemplate="content">
          <div class="p-mb-2 p-d-flex p-ai-center">
            <i
              class="pi pi-exclamation-triangle p-mr-2"
              style="color: 'var(--pink-500)'; font-size: 1.5em"
            ></i>
            <h3 class="p-my-0">Confirm</h3>
          </div>

          <div class="p-p-2">
            Are you sure you want to delete the set,
            <strong>{{ activeFilterSetName }}</strong
            >?
          </div>

          <div class="p-mt-3 p-d-flex p-jc-end">
            <p-button
              label="Nope"
              class="p-mx-2"
              styleClass="p-button-outlined"
              (click)="removeFilterSet_overlay.hide()"
            ></p-button>
            <p-button
              label="Yup"
              icon="pi pi-check"
              class="p-mx-2"
              (click)="
                onRemoveFilterSet(activeFilterSetName);
                removeFilterSet_overlay.hide()
              "
            ></p-button>
          </div>
        </ng-template>
      </p-overlayPanel>

      <!-- ACTIONS: Dialog -->
      <p-dialog
        header="Actions"
        [(visible)]="showActions"
        [modal]="true"
        [dismissableMask]="true"
      >
        <p-panel header="Export Options (CSV)">
          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-download"
            class="p-mx-2"
            pTooltip="{{
              showExportOptions
                ? getPropArray(columns, 'name').join('<br>')
                : ''
            }}"
            tooltipPosition="bottom"
            label="Export All Columns"
            (click)="exportCSV(columns)"
          ></button>

          <button
            type="button"
            pButton
            pRipple
            icon="pi pi-download"
            class="p-mx-2"
            pTooltip="{{
              showExportOptions
                ? getPropArray(selectedColumns, 'name').join('<br>')
                : ''
            }}"
            tooltipPosition="bottom"
            label="Export Custom Set"
            (click)="exportCSV(selectedColumns)"
          ></button>
        </p-panel>
        <!-- ACTIONS: 'Edit Options' panel -->
        <p-panel
          *ngIf="selectedRows.length > 0"
          header="Editing Options"
          class="p-mt-2"
        >
          <button
            *ngIf="selectedRows.length > 0"
            type="button"
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-mx-2"
            pTooltip="{{ showActions ? selectedRows.length + ' Rows' : '' }}"
            tooltipPosition="bottom"
            label="Edit Selected Items"
            (click)="onStartBulkEdit()"
          ></button>

          <!-- <button
                              *ngIf="
                                bulkEdit.failedPayloads && bulkEdit.failedPayloads.length > 0
                              "
                              type="button"
                              pButton
                              pRipple
                              icon="pi pi-eye"
                              class="p-button-warning p-mx-2"
                              pTooltip="{{ bulkEdit.failedPayloads.length + ' Rows' }}"
                              tooltipPosition="bottom"
                              label="Failed Updates"
                              (click)="onShowFailedUpdates()"
                            ></button> -->

          <button
            *ngIf="bulkEdit.conflict && bulkEdit.conflict.rows.length > 0"
            type="button"
            pButton
            pRipple
            icon="pi pi-eye"
            class="p-button-warning p-mx-2"
            pTooltip="{{ bulkEdit.conflict.rows.length + ' Rows' }}"
            tooltipPosition="bottom"
            label="Conflicts"
            (click)="onShowConflicts()"
          ></button>
        </p-panel>
      </p-dialog>

      <!-- BULK EDIT Dialog -->
      <p-dialog
        [(visible)]="showBulkEdit"
        [modal]="true"
        [dismissableMask]="true"
        [style]="
          ['reviewAndSubmit'].includes(bulkEdit.stage)
            ? { width: 'auto' }
            : { width: 'auto', 'max-width': '600px' }
        "
      >
        <!-- Bulk Edit header -->
        <ng-template pTemplate="header" [ngSwitch]="bulkEdit.stage">
          <h3 *ngSwitchCase="'selectField'" class="p-dialog-title">
            Select Field to Edit
          </h3>

          <h3 *ngSwitchCase="'editField'" class="p-dialog-title">
            Edit Field: {{ bulkEdit.field.name }}
          </h3>

          <h3 *ngSwitchCase="'reviewAndSubmit'">Review and Submit</h3>
        </ng-template>

        <!-- Bulk Edit stage: Select Field -->
        <ng-container *ngIf="['selectField'].includes(bulkEdit.stage)">
          <p-listbox
            [(ngModel)]="bulkEdit.field"
            [filter]="true"
            filterPlaceHolder="Search by Field Name"
            [options]="editableFields"
            optionLabel="name"
            [listStyle]="{ 'max-height': '50vh', 'min-width': '400px' }"
            (onClick)="onFieldSelectedForEdit()"
          >
            <ng-template pTemplate="item" let-field>
              <div style="width: 100%">
                <span class="p-grid p-fluid p-jc-between">
                  <span class="p-col p-text-bold">
                    {{ field.name }}
                  </span>
                  <span class="p-col p-text-right">{{ field.key }}</span>
                </span>
              </div>
            </ng-template>
          </p-listbox>
        </ng-container>

        <!-- Bulk Edit stage: Edit Field -->
        <ng-container *ngIf="['editField'].includes(bulkEdit.stage)">
          <!-- Conflict Messages -->
          <div
            *ngIf="bulkEdit.conflict"
            class="p-grid p-ai-center vertical-container p-m-1 conflict-msg-container"
          >
            <p-badge
              class="p-col-fixed"
              [ngStyle]="{ width: '1.5em' }"
              styleClass="p-badge-dot"
              [severity]="'danger'"
            ></p-badge>
            <p
              class="p-col p-m-0"
              [ngStyle]="{
                color: 'var(--text-secondary-color)',
                fontSize: '0.90em'
              }"
            >
              Values {{ bulkEdit.conflict.detail }} will conflict with existing
              '{{ bulkEdit.conflict.field.name }}' values in some selected rows.
            </p>
          </div>

          <!-- Form Fields -->
          <form *ngIf="bulkEdit.form" [formGroup]="bulkEdit.form">
            <ng-container [ngSwitch]="bulkEdit.field.controlType">
              <input
                *ngSwitchCase="'inputText'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [placeholder]="'Enter New ' + bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                type="text"
                pInputText
                class="bulkEdit-field"
              />

              <textarea
                *ngSwitchCase="'inputTextarea'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [placeholder]="'Enter New ' + bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                cols="40"
                rows="3"
                pInputTextarea
                class="bulkEdit-field"
              ></textarea>

              <p-calendar
                *ngSwitchCase="'calendar-start'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                [inline]="true"
                [readonlyInput]="true"
                [defaultDate]="bulkEdit.today"
                [showTime]="true"
                [stepMinute]="10"
                [monthNavigator]="true"
                [yearNavigator]="true"
                yearRange="2020:2030"
                class="bulkEdit-field"
              >
              </p-calendar>

              <p-calendar
                *ngSwitchCase="'calendar-end'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                [inline]="true"
                [readonlyInput]="true"
                [defaultDate]="bulkEdit.today"
                [showTime]="true"
                [stepMinute]="10"
                [monthNavigator]="true"
                [yearNavigator]="true"
                yearRange="2020:2030"
                class="bulkEdit-field"
              ></p-calendar>

              <p-selectButton
                *ngSwitchCase="'toggleButton'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                [options]="[
                  { label: bulkEdit.field.name, value: true },
                  { label: 'Not ' + bulkEdit.field.name, value: false }
                ]"
                optionLabel="label"
                optionValue="value"
                class="bulkEdit-field"
              ></p-selectButton>

              <p-listbox
                *ngSwitchCase="'dropdown'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                [filter]="true"
                [filterPlaceHolder]="'Search ' + bulkEdit.field.name"
                [options]="options[bulkEdit.field.key]"
                class="bulkEdit-field"
                [listStyle]="{ 'max-height': '50vh' }"
                (onClick)="onFieldEdited()"
              ></p-listbox>

              <p-listbox
                *ngSwitchCase="'dropdown_ref'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                [filter]="true"
                [filterPlaceHolder]="'Search ' + bulkEdit.field.name"
                [options]="options[bulkEdit.field.key]"
                optionLabel="name"
                class="bulkEdit-field"
                [listStyle]="{ 'max-height': '50vh' }"
                (onClick)="onFieldEdited()"
              >
                <ng-template pTemplate="item" let-option>
                  {{ option.name }}
                  <p-badge
                    *ngIf="
                      bulkEdit.conflict && bulkEdit.conflict.options[option.id]
                    "
                    class="p-mx-2"
                    styleClass="p-badge-dot"
                    severity="danger"
                  ></p-badge>
                </ng-template>
              </p-listbox>

              <p-listbox
                *ngSwitchCase="'multiSelect'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                [filter]="true"
                [filterPlaceHolder]="'Search ' + bulkEdit.field.name"
                [options]="options[bulkEdit.field.key]"
                [multiple]="true"
                [checkbox]="true"
                class="bulkEdit-field"
                [listStyle]="{ 'max-height': '50vh' }"
              ></p-listbox>

              <p-listbox
                *ngSwitchCase="'multiSelect_ref'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                [filter]="true"
                [filterPlaceHolder]="'Search ' + bulkEdit.field.name"
                [options]="options[bulkEdit.field.key]"
                optionLabel="name"
                [multiple]="true"
                [checkbox]="true"
                class="bulkEdit-field"
                [listStyle]="{ 'max-height': '50vh' }"
              ></p-listbox>

              <p-listbox
                *ngSwitchCase="'autoComplete_ref'"
                [formControlName]="bulkEdit.field.key"
                [aria-label]="bulkEdit.field.name"
                [id]="bulkEdit.field.key"
                [options]="suggestions[bulkEdit.field.key]"
                class="bulkEdit-field"
                [listStyle]="{ 'max-height': '50vh' }"
                (onClick)="onFieldEdited()"
              >
                <ng-template pTemplate="header">
                  <span class="p-input-icon-right" [style]="{ width: '100%' }">
                    <i class="pi pi-search"></i>
                    <input
                      type="text"
                      pInputText
                      placeholder="Search for {{ bulkEdit.field.name }}"
                      (input)="
                        getSuggestionsForRef(
                          $event.target.value,
                          bulkEdit.field.key,
                          bulkEdit.field.model
                        )
                      "
                      [style]="{ width: '100%' }"
                    />
                  </span>
                </ng-template>
                <ng-template pTemplate="item" let-item>
                  <span style="width: 100%">
                    <span class="p-grid p-fluid p-jc-between">
                      <span class="p-col p-text-bold">
                        {{ item.name }}
                      </span>
                      <span class="p-col p-text-right">{{ item.id }}</span>
                    </span>
                  </span>
                </ng-template>
              </p-listbox>

              <ng-container *ngSwitchCase="'formArray'">
                <div
                  [formArrayName]="bulkEdit.field.key"
                  *ngFor="
                    let group of bulkEdit.form.get(bulkEdit.field.key)[
                      'controls'
                    ];
                    let i = index
                  "
                  class="p-my-4"
                >
                  <span
                    [formGroupName]="i"
                    *ngFor="let f of bulkEdit.group"
                    [ngSwitch]="f.controlType"
                  >
                    <ng-container *ngIf="!f.hidden">
                      <p-dropdown
                        *ngSwitchCase="'dropdown_ref'"
                        [formControlName]="f.key"
                        [aria-label]="f.name"
                        [placeholder]="f.name"
                        [id]="f.key"
                        [options]="options[f.key]"
                        optionLabel="name"
                        class="p-d-inline p-mx-2"
                        [style]="{ 'min-width': '12em' }"
                      ></p-dropdown>

                      <p-inputNumber
                        *ngSwitchCase="'inputNumber-buttons'"
                        [formControlName]="f.key"
                        [aria-label]="f.name"
                        [placeholder]="f.name"
                        [id]="f.key"
                        class="p-d-inline p-mx-2"
                        [min]="0"
                        [showButtons]="true"
                      >
                      </p-inputNumber>
                    </ng-container>
                  </span>

                  <button
                    *ngIf="i > 0"
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-minus"
                    class="p-button-outlined p-d-inline p-mx-2"
                    (click)="removeGroup(i)"
                  ></button>
                </div>

                <div class="p-m-2">
                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-plus"
                    class="p-button-rounded p-button-outlined"
                    [disabled]="!isFormArrayValid()"
                    (click)="addGroup()"
                  ></button>
                </div>
              </ng-container>
            </ng-container>
          </form>
          <div class="p-d-flex p-jc-center">
            <p-button
              *ngIf="
                ['editField'].includes(bulkEdit.stage) &&
                bulkEdit.form.value[bulkEdit.field.key]
              "
              ariaLabel="Clear Field"
              label="Clear Field"
              styleClass="p-button-outlined p-button-danger p-button-sm p-mt-3"
              (click)="clearField(bulkEdit.form, bulkEdit.field.key)"
            ></p-button>
          </div>
        </ng-container>

        <!-- Bulk Edit stage: 'Review and Submit' -->
        <ng-container *ngIf="['reviewAndSubmit'].includes(bulkEdit.stage)">
          <!-- Conflict Messages -->
          <div
            *ngIf="bulkEdit.conflict"
            class="p-grid p-ai-center vertical-container p-m-1 conflict-msg-container"
            [ngStyle]="{ color: '#F48FB1' }"
          >
            <span
              class="p-col-fixed p-pb-0 p-mr-2"
              [ngStyle]="{ width: '1.5em' }"
            >
              <i
                class="pi pi-exclamation-triangle"
                style="font-size: 1.5em"
              ></i>
            </span>

            <span class="p-col p-m-0">
              <p class="p-m-0">
                Conflicts Detected. {{ bulkEdit.conflict.rows.length }} of
                {{ bulkEdit.rows.length }} selected rows have an existing
                {{ bulkEdit.conflict.field.name }} value that is incompatible
                with the new {{ bulkEdit.field.name }} value.<br />Please modify
                these rows or Submit without them.
              </p>
            </span>
          </div>

          <p-tabView>
            <p-tabPanel *ngIf="bulkEdit.conflict">
              <ng-template pTemplate="header">
                CONFLICTS ONLY - {{ bulkEdit.conflict.rows.length }}
              </ng-template>
              <p-table
                [value]="bulkEdit.conflict.rows"
                styleClass="p-datatable-sm"
              >
                <ng-template pTemplate="header">
                  <tr class="p-text-uppercase">
                    <th *ngFor="let header of ['id', 'filename', 'name']">
                      <span>{{ header }}</span>
                    </th>

                    <th [ngStyle]="{ color: 'var(--gray-400)' }">
                      {{ bulkEdit.field.name }}
                    </th>
                    <th [ngStyle]="{ color: 'var(--primary-color)' }">
                      {{ bulkEdit.field.name }}
                      <p-tag
                        styleClass="p-mx-2"
                        value="NEW"
                        [rounded]="true"
                      ></p-tag>
                    </th>

                    <th
                      *ngIf="bulkEdit.conflict"
                      [ngStyle]="{ color: '#F48FB1' }"
                    >
                      {{ bulkEdit.conflict.field.name }}
                      <p-tag
                        styleClass="p-mx-2"
                        value="CONFLICT"
                        severity="danger"
                        [rounded]="true"
                      ></p-tag>
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td
                      *ngFor="
                        let value of [
                          item.id,
                          parseFilenameFromPath(item['prefab']),
                          item.name
                        ]
                      "
                    >
                      <span>{{ value }}</span>
                    </td>

                    <td
                      *ngFor="
                        let value of [
                          parseValueForEditReview(
                            item[bulkEdit.field.key],
                            bulkEdit.field
                          ),
                          parseValueForEditReview(
                            bulkEdit.form.value[bulkEdit.field.key],
                            bulkEdit.field
                          )
                        ]
                      "
                      [ngStyle]="
                        [value].includes(
                          parseValueForEditReview(
                            item[bulkEdit.field.key],
                            bulkEdit.field
                          )
                        )
                          ? { color: 'var(--gray-400)' }
                          : { color: 'var(--primary-color)' }
                      "
                    >
                      <span
                        *ngIf="
                          !['calendar-start', 'calendar-end', 'date'].includes(
                            bulkEdit.field.controlType
                          )
                        "
                      >
                        {{ value }}
                      </span>

                      <span
                        *ngIf="
                          ['calendar-start', 'calendar-end', 'date'].includes(
                            bulkEdit.field.controlType
                          )
                        "
                      >
                        {{ value | officeTime }}
                      </span>

                      <span *ngIf="value == ''">—</span>
                    </td>

                    <td
                      *ngIf="bulkEdit.conflict"
                      [ngStyle]="{ color: '#F48FB1' }"
                    >
                      <span
                        *ngIf="
                          !['calendar-start', 'calendar-end', 'date'].includes(
                            bulkEdit.field.controlType
                          )
                        "
                      >
                        {{
                          parseValueForEditReview(
                            item[bulkEdit.conflict.field.key],
                            bulkEdit.conflict.field
                          )
                        }}
                      </span>

                      <span
                        *ngIf="
                          ['calendar-start', 'calendar-end', 'date'].includes(
                            bulkEdit.field.controlType
                          )
                        "
                      >
                        {{
                          parseValueForEditReview(
                            item[bulkEdit.conflict.field.key],
                            bulkEdit.conflict.field
                          ) | officeTime
                        }}
                      </span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </p-tabPanel>

            <p-tabPanel>
              <ng-template pTemplate="header">
                ALL SELECTED ROWS - {{ bulkEdit.rows.length }}
              </ng-template>
              <p-table [value]="bulkEdit.rows" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                  <tr class="p-text-uppercase">
                    <th *ngFor="let header of ['id', 'filename', 'name']">
                      <span>{{ header }}</span>
                    </th>

                    <th [ngStyle]="{ color: 'var(--gray-400)' }">
                      {{ bulkEdit.field.name }}
                    </th>

                    <th [ngStyle]="{ color: 'var(--primary-color)' }">
                      {{ bulkEdit.field.name }}
                      <p-tag
                        styleClass="p-mx-2"
                        value="NEW"
                        [rounded]="true"
                      ></p-tag>
                    </th>

                    <!-- header in conflict -->
                    <th
                      *ngIf="bulkEdit.conflict"
                      [ngStyle]="{ color: '#F48FB1' }"
                    >
                      {{ bulkEdit.conflict.field.name }}
                      <p-tag
                        styleClass="p-mx-2"
                        value="CONFLICT"
                        severity="danger"
                        [rounded]="true"
                      ></p-tag>
                    </th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-item>
                  <tr>
                    <td
                      *ngFor="
                        let value of [
                          item.id,
                          parseFilenameFromPath(item['prefab']),
                          item.name
                        ]
                      "
                    >
                      <span>{{ value }}</span>
                    </td>

                    <td
                      *ngFor="
                        let value of [
                          parseValueForEditReview(
                            item[bulkEdit.field.key],
                            bulkEdit.field
                          ),
                          parseValueForEditReview(
                            bulkEdit.form.value[bulkEdit.field.key],
                            bulkEdit.field
                          )
                        ]
                      "
                      [ngStyle]="
                        [value].includes(
                          parseValueForEditReview(
                            item[bulkEdit.field.key],
                            bulkEdit.field
                          )
                        )
                          ? { color: 'var(--gray-400)' }
                          : { color: 'var(--primary-color)' }
                      "
                    >
                      <span
                        *ngIf="
                          !['calendar-start', 'calendar-end', 'date'].includes(
                            bulkEdit.field.controlType
                          )
                        "
                      >
                        {{ value }}
                      </span>

                      <span
                        *ngIf="
                          ['calendar-start', 'calendar-end', 'date'].includes(
                            bulkEdit.field.controlType
                          )
                        "
                      >
                        {{ value | officeTime }}
                      </span>

                      <span *ngIf="value == ''">—</span>
                    </td>

                    <!-- column in conflict -->
                    <td
                      *ngIf="bulkEdit.conflict"
                      [ngStyle]="{
                        color: bulkEdit.conflict.indicator(
                          bulkEdit.form.value[bulkEdit.field.key],
                          item
                        )
                          ? '#F48FB1'
                          : 'var(--gray-400)'
                      }"
                    >
                      <span
                        *ngIf="
                          !['calendar-start', 'calendar-end', 'date'].includes(
                            bulkEdit.conflict.field.controlType
                          )
                        "
                      >
                        {{
                          parseValueForEditReview(
                            item[bulkEdit.conflict.field.key],
                            bulkEdit.conflict.field
                          )
                        }}
                      </span>

                      <span
                        *ngIf="
                          ['calendar-start', 'calendar-end', 'date'].includes(
                            bulkEdit.conflict.field.controlType
                          )
                        "
                      >
                        {{
                          parseValueForEditReview(
                            item[bulkEdit.conflict.field.key],
                            bulkEdit.conflict.field
                          ) | officeTime
                        }}
                      </span>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </p-tabPanel>
          </p-tabView>
        </ng-container>

        <!-- Bulk Edit dialog: Footer -->
        <ng-template pTemplate="footer">
          <div class="p-d-flex p-jc-center">
            <button
              *ngIf="!['selectField'].includes(bulkEdit.stage)"
              pButton
              pRipple
              type="button"
              label="Back"
              icon="pi pi-arrow-left"
              class="p-button-outlined p-m-2"
              (click)="goBack()"
            ></button>

            <button
              *ngIf="
                (['selectField'].includes(bulkEdit.stage) &&
                  bulkEdit.field.key) ||
                (['editField'].includes(bulkEdit.stage) &&
                  (bulkEdit.form.value[bulkEdit.field.key] ||
                    !['dropdown', 'dropdown_ref', 'autoComplete_ref'].includes(
                      bulkEdit.field.controlType
                    )))
              "
              pButton
              pRipple
              type="button"
              label="Next"
              icon="pi pi-arrow-right"
              iconPos="right"
              class="p-m-2"
              [disabled]="
                (['editField'].includes(bulkEdit.stage) &&
                  !['formArray'].includes(bulkEdit.field.controlType) &&
                  !bulkEdit.form.valid) ||
                (['formArray'].includes(bulkEdit.field.controlType) &&
                  !isFormArrayValid())
              "
              (click)="goNext()"
            ></button>

            <button
              *ngIf="['reviewAndSubmit'].includes(bulkEdit.stage)"
              pButton
              pRipple
              type="button"
              [label]="
                bulkEdit.conflict ? 'Submit Without Conflicts' : 'Submit'
              "
              icon="pi pi-check"
              class="p-m-2"
              (click)="onSubmitBulkEdit()"
            ></button>
          </div>
        </ng-template>
      </p-dialog>

      <!-- BULK EDIT: Conflicts Dialog -->
      <p-dialog
        header="Bulk Edit Conflicts"
        [(visible)]="showBulkEditConflicts"
        [modal]="true"
        [dismissableMask]="true"
      >
        <p-table
          *ngIf="bulkEdit.conflict"
          [value]="bulkEdit.conflict.rows"
          styleClass="p-datatable-sm"
        >
          <ng-template pTemplate="header">
            <tr class="p-text-uppercase">
              <th *ngFor="let header of ['id', 'filename', 'name']">
                <span>{{ header }}</span>
              </th>

              <th [ngStyle]="{ color: 'var(--gray-400)' }">
                {{ bulkEdit.field.name }}
              </th>
              <th [ngStyle]="{ color: 'var(--primary-color)' }">
                {{ bulkEdit.field.name }}
                <p-tag styleClass="p-mx-2" value="NEW" [rounded]="true"></p-tag>
              </th>

              <th *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
                {{ bulkEdit.conflict.field.name }}
                <p-tag
                  styleClass="p-mx-2"
                  value="CONFLICT"
                  severity="danger"
                  [rounded]="true"
                ></p-tag>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-item>
            <tr>
              <td
                *ngFor="
                  let value of [
                    item.id,
                    parseFilenameFromPath(item['scene']),
                    item.name
                  ]
                "
              >
                <span>{{ value }}</span>
              </td>

              <td
                *ngFor="
                  let value of [
                    parseValueForEditReview(
                      item[bulkEdit.field.key],
                      bulkEdit.field
                    ),
                    parseValueForEditReview(
                      bulkEdit.form.value[bulkEdit.field.key],
                      bulkEdit.field
                    )
                  ]
                "
                [ngStyle]="
                  [value].includes(
                    parseValueForEditReview(
                      item[bulkEdit.field.key],
                      bulkEdit.field
                    )
                  )
                    ? { color: 'var(--gray-400)' }
                    : { color: 'var(--primary-color)' }
                "
              >
                <span
                  *ngIf="
                    !['calendar-start', 'calendar-end', 'date'].includes(
                      bulkEdit.field.controlType
                    )
                  "
                >
                  {{ value }}
                </span>

                <span
                  *ngIf="
                    ['calendar-start', 'calendar-end', 'date'].includes(
                      bulkEdit.field.controlType
                    )
                  "
                >
                  {{ value | officeTime }}
                </span>

                <span *ngIf="value == ''">—</span>
              </td>

              <td *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
                <span
                  *ngIf="
                    !['calendar-start', 'calendar-end', 'date'].includes(
                      bulkEdit.field.controlType
                    )
                  "
                >
                  {{
                    parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                    )
                  }}
                </span>

                <span
                  *ngIf="
                    ['calendar-start', 'calendar-end', 'date'].includes(
                      bulkEdit.field.controlType
                    )
                  "
                >
                  {{
                    parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                    ) | officeTime
                  }}
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-dialog>
    </ng-template>

    <!-- TABLE HEADER -->
    <ng-template pTemplate="header" #tableheader let-columns>
      <tr style="width: 100%">
        <th>
          <p-checkbox
            [(ngModel)]="isHeaderChecked"
            [binary]="true"
            (onChange)="onHeaderCheckboxToggle($event.checked)"
            pTooltip="Select or De-select all filtered rows"
            tooltipPosition="right"
            showDelay="2000"
          >
          </p-checkbox>
        </th>
        <th>&nbsp;</th>
        <th
          *ngFor="let col of columns"
          class="p-text-left"
          pSortableColumn="{{ col.key }}"
          pReorderableColumn
          [ngSwitch]="col.controlType"
        >
          {{ col.name }}

          <p-sortIcon field="{{ col.key }}"></p-sortIcon>
        </th>
        <th style="width: 100px">Edit Row</th>
      </tr>
    </ng-template>

    <!-- TABLE BODY -->
    <ng-template
      pTemplate="body"
      #tablebody
      let-row
      let-columns="columns"
      let-editing="editing"
      let-rowIndex="rowIndex"
    >
      <tr [pEditableRow]="row">
        <td>
          <p-tableCheckbox [value]="row" [index]="rowIndex"></p-tableCheckbox>
        </td>
       <td>
          <a
            pButton
            pRipple
            type="button"
            class="p-button-rounded p-button-text"
            icon="pi pi-pencil"
            iconPos="left"
            href="/{{ type }}/edit/{{ row['id'] }}"
          ></a>
        </td>
        <td
          *ngFor="let col of columns"
          class="p-text-left"
          [ngSwitch]="col.key"
        >
          <!-- Calendar SwitchCase. Here we are controlling how the dates are being displayed. We will be including how calendar forms will appear in edit mode -->

          <!-- Start Date  (Date)   -->
          <span *ngSwitchCase="['start'].includes(col.key) ? col.key : ''">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-calendar
                  [(ngModel)]="row[col.key]"
                  [showTime]="true"
                  [maxDate]="row['end']"
                  [yearNavigator]="true"
                  yearRange="2020:2030"
                  [monthNavigator]="true"
                ></p-calendar>
              </ng-template>
              <ng-template pTemplate="output">
                {{ row[col.key] | officeTime }}
              </ng-template>
            </p-cellEditor>
          </span>

          <!-- End Date (Date)   -->
          <span *ngSwitchCase="['end'].includes(col.key) ? col.key : ''">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-calendar
                  [(ngModel)]="row[col.key]"
                  [showTime]="true"
                  [minDate]="row['start']"
                  [yearNavigator]="true"
                  yearRange="2020:2030"
                  [monthNavigator]="true"
                ></p-calendar>
              </ng-template>
              <ng-template pTemplate="output">
                {{ row[col.key] | officeTime }}
              </ng-template>
            </p-cellEditor>
          </span>

          <!-- Switch case Enabled (toggle) -->
          <span *ngSwitchCase="'enabled'">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-toggleButton
                  onLabel="Enabled"
                  offLabel="Not Enabled"
                  onIcon="pi-check"
                  offIcon="pi-times"
                  [(ngModel)]="row[col.key]"
                ></p-toggleButton>
              </ng-template>
              <ng-template pTemplate="output">
                {{ row[col.key] | yesNo }}
              </ng-template>
            </p-cellEditor>
          </span>

          <!-- Switch Case for Name (InputText) -->
          <span *ngSwitchCase="['name'].includes(col.key) ? col.key : ''">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input
                  pInputText
                  type="text"
                  [(ngModel)]="row[col.key]"
                  required
                />
              </ng-template>
              <ng-template pTemplate="output">
                <a
                  pButton
                  aria-label="{{ row[col.key] }}"
                  [label]="row[col.key]"
                  type="button"
                  class="p-button-link p-text-left"
                  href="/{{ type }}/{{ row['id'] }}"
                ></a>
              </ng-template>
            </p-cellEditor>
          </span>

          <span *ngSwitchDefault>
            {{ row[col.key] }}
          </span>
        </td>

        <td style="text-align: left">
          <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-cog" class="p-button-raised" (click)="onRowEditInit(row)"></button>

          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            class="p-button-rounded p-button-text p-button-success p-mr-2"
            (click)="onRowEditSave(row)"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            (click)="onRowEditCancel(row, rowIndex)"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>