import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import AudioCollectionsDto from '../dtos/AudioCollectionDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-audio-collection-form-v3',
  templateUrl: './audio-collection-form-v3.component.html',
  styleUrls: ['./audio-collection-form-v3.component.sass']
})
export class AudioCollectionFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  audioCollectionRecord: AudioCollectionsDto = new AudioCollectionsDto();

  allOptions: any[] = [];

  constructor()
  {
    super();
  }

  /**
   * Audio Collection Form V3 Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Audio Collection Data",
        fields:
        [
          { 
            name: "Name", 
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],   
            clearField: true, 
            key: 'name', 
            isRequired: true, 
            label: 'Name', 
            disabled: false 
          },
          {
            name: "Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],  
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            filterBy: "id,name",
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: true,
            options:
            {
              fieldName: 'type',
              values:
              [
                { name: 'Spawn Audio', value: 1 },
                { name: 'Loop Audio', value: 2 },
              ]
            }
          },
          {
            name: "Collection Audio",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'audios_ref',
            isRequired: false,
            label: 'Collection Audio',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'audios_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: { $in: [7] }}, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id entityType assetType',
              isArrayOfStringIds: true
            },
          },
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Audio Collection";
    this.viewRoute = 'audio-collections';
    this.isLoading = false;



  }

  preLoad(record: any){
    this.allOptions = this.dynamicFormComponent.options['audios_ref'];
    this.UpdateDependentOptions({field: "type", value: record.type});
  }

  /**
   * UpdateDependentOptions function is used to handle changes to a specific field and
   * update the 'enabled' property of dependent options based on the changed value.
   * Specifically, it handles changes to the 'type' field and modifies the 'enabled'
   * property of the options in the 'audios_ref' array according to the mapping:
   * - eventValue 1 corresponds to assetType 22
   * - eventValue 2 corresponds to assetType 23
   *
   * @param {any} event - An object representing the change event, containing field and value properties.
   */
  UpdateDependentOptions(event: any) {
    switch (event.field) {
      case 'type':
        // Logging for debug purposes
        console.log('type changed:', event.value);

        // Retrieve the 'audios_ref' options
        let options = [...this.allOptions]

        // Check if options are present
        if (options) {
          // Filter options based on the assetType and event value
          if (event.value == 1) {
            options = options.filter(option => option.assetType === 22);
          } else if (event.value == 2) {
            options = options.filter(option => option.assetType === 23);
          }
        }

        // Reassign the options to trigger a change detection in your view
        this.dynamicFormComponent.options['audios_ref'] = [...options];
        console.log('check audio ref', this.dynamicFormComponent.options['audios_ref']);
        break;

      default:
        // Other cases can be handled here if needed
        break;
    }
  }

}


