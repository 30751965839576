import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { DataService } from 'src/app/services/data.service';

export class OptionsParams {
  entity: string; // The entity's controller route name/identifier
  query: any = {}; // The MongoDB query object.
  populate: string[] = []; // An array of fields to populate.
  populateMinimal: boolean = true; // A flag populate only minimal fields from populated fields: (_id, id, name)
  autopopulate: boolean = false;  // A flag to automatically populate all fields
  smartPopulate: { [key: string]: string } = {}; // An object specifying which fields to smartly populate and with what sub-fields.
  virtuals: any = false; // A flag to include virtual fields
  select: string = 'id name _id'; // fields to select
  sort: any = { name: 1 }; //  The sort criteria. by default name asc. a-z for dropdown options

  constructor(init?: Partial<OptionsParams>) {
    Object.assign(this, init);
  }
}
@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {
  constructor
    (
      private router: Router,
      private dataService: DataService,
      private http: HttpClient
    ) { }

  /**
   * Refresh the component view/data, without reloading
   * the page.
   *
   * @param componentRoute The route of the component to refresh.
   */
  refreshComponent(componentRoute: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([componentRoute]);
    });
  }

  /**
   * Retrieves current date at midnight time
   */
  getCurrentDateAtMidnight() {
    var d = new Date();
    d.setHours(0, 0, 0, 0);
    return d;
  }

  /**
   * Retrieves current date at midnight time
   */
  getCustomDateAtMidnight(dateISOString: string) {
    var d = new Date(dateISOString);
    d.setHours(0, 0, 0, 0);
    return d;
  }

  async gameConfig() {
    return firstValueFrom(this.http.post<any>(`/api/cms-settings/set-config`, ''));
  }

  /**
   *
   * @param options Options variable ref
   * @param fieldName Field name to populate
   * @param model API Controller route
   * @param minimal Flag that sets wether or not to set minimal values
   * @param autopopulate Flag that sets wether or not to autopopulate fields
   * @param select Fields to retrieve from the entity.
   * @param virtuals Flag that sets wether or not to retrieve virtual fields.
   * @param sort Sort expression.
   * @param customQuery Custom Query object.
   */
  async getOptionsFromRef
    (
      options: any,
      fieldName: string,
      model: string,
      minimal: boolean = false,
      autopopulate: boolean = true,
      select: string = '',
      virtuals: boolean = true,
      sort: any = null,
      customQuery: any = null,
      isStoreEntity: boolean = false,
      storeEnv: 'dev' | 'qa' | 'prod' = 'dev'
    ) {
    const optionValues = await this.dataService.getAllOfTypeAsync(model,
      {
        query: customQuery ? customQuery : {},
        autopopulate: autopopulate,
        virtuals: virtuals,
        select: select,
        sort: sort ? sort : { createdAt: 1 },
      });

    if (minimal && !(select.length > 1)) {
      let o: any[] = [];
      for (const option of optionValues) {
        if (isStoreEntity && option.name && typeof option.name === 'object') {
          // Extract name based on storeEnv
          o.push({ id: option.id, name: option.name[storeEnv], _id: option._id });
        } else {
          o.push({ id: option.id, name: option.name, _id: option._id });
        }
      }
      options[fieldName] = o;
    } else {
      if (isStoreEntity) {
        options[fieldName] = optionValues.map((option: { name: { [x: string]: any; }; }) => ({
          ...option,
          name: option.name
        }));
      } else {
        options[fieldName] = optionValues;
      }
    }

    return options[fieldName];
  }





  async getOptions(options: any, fieldName: string, params: OptionsParams) {
    options[fieldName] = await this.dataService.getAllOfTypeAsync(params.entity, params);
    return options[fieldName];
  }

  async getAndReturnOptions(params: OptionsParams) {
    return await this.dataService.getAllOfTypeAsync(params.entity, params);
  }

  /**
   * Returns a Promise that resolves after "ms" Milliseconds
   *
   * @param ms Miliseconds value.
   */
  async sleep(ms: number) {
    const timer = (ms: any) => new Promise(res => setTimeout(res, ms));
    // Wait x msec
    await timer(ms);
  }

  /**
   * Markdown parser to convert md to HTML with JavaScript using Regular Expressions.
   * It doesn't cover everything that markdown supports, but it should work for most cases.
   *
   * @param markdownText Markdown text value
   */
  parseMarkdown(markdownText: string) {
    const htmlText = markdownText
      .replace(/^### (.*$)/gim, '<h3>$1</h3>')
      .replace(/^## (.*$)/gim, '<h2>$1</h2>')
      .replace(/^# (.*$)/gim, '<h1>$1</h1>')
      .replace(/^\> (.*$)/gim, '<blockquote>$1</blockquote>')
      .replace(/\*\*(.*)\*\*/gim, '<b>$1</b>')
      .replace(/\*(.*)\*/gim, '<i>$1</i>')
      .replace(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
      .replace(/\[(.*?)\]\((.*?)\)/gim, "<a href='$2'>$1</a>")
      .replace(/\n$/gim, '<br />')

    return htmlText.trim()
  }

  /**
   * Creates a new GUID
   */
  createGuid() {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  }

  /**
   * Retrieves the most recent date from an array of dates.
   *
   * @param dates Array of dates.
   */
  getMostRecentDate(dates: any[]) {
    let allDates: any[] = [];
    dates.forEach((d) => {
      allDates.push(Date.parse(d));
    });

    return new Date(Math.max(...allDates)).toLocaleDateString();
  }

  /**
   * Determines if the user's local time zone is PST.
   *
   * @returns {boolean} True if the user's local time zone is PST, otherwise false.
   */
  isUserLocalTimeZonePST(): boolean {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const pstTimeZones = [
      'America/Los_Angeles',
      'America/Vancouver',
      'America/Tijuana',
      'America/Dawson',
      'America/Ensenada',
      'America/Santa_Isabel'
    ];

    return pstTimeZones.includes(userTimeZone);
  }

  /**
   * New Game Config function to retrieve game configs from backend
   * Uses a const key variable to provide the keys api call. If you need to acquire new keys please update constant file.
  */
 
  async getGameConfigFromBackend() {

    return firstValueFrom(this.http.post<any>(`/api/cms-settings/get-game-config`, ''));

  }

}

