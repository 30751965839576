import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Relase DTO
 */
export default class ReleaseDTO extends BaseEntityDto
{
    /**
     * Name of the release
     */
    name: string = "";
    /**
     * Layout selected
     */
    layout: string = "";
    /**
     * List of related entities
     */
    refs: Array<any> = [];
    /**
     * List of Loading Screens
     */
    loadingScreen_ref: Array<any> = [];
    /**
     * List of Inbox Messages
     */
    inboxMessages_ref: Array<any> = [];
    /**
     * Series reference Mongo Id
     */
    series_ref: any;
    /**
     * Import dates for the release
     */
    release_dates: Array<any> = [];

    /**
     * Timeline Structure
     */
    timeline_dates: Array<any>;
}