import { Component, OnInit, Input, Output, EventEmitter, ViewChildren, QueryList } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DataService } from 'src/app/services/data.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { MultiSelect } from 'primeng/multiselect';

type FormMode = 'create' | 'edit' | 'view';

@Component({
  selector: 'app-content-schedule-view-form',
  templateUrl: './content-schedule-view-form.component.html',
  styleUrls: ['./content-schedule-view-form.component.css']
})
export class ContentScheduleViewFormComponent implements OnInit {
  @Input() initialStartDate: Date | null = null;
  @Input() initialEndDate: Date | null = null;
  @Input() mode: FormMode = 'create';
  @Input() scheduleId?: number;
  @Input() initialScheduleData: any = null;
  @Output() cancel = new EventEmitter<void>();
  @Output() submitSuccess = new EventEmitter<void>();
  @Output() modeChange = new EventEmitter<'view' | 'edit' | 'create'>();
  
  @ViewChildren('multiSelect') multiSelects!: QueryList<MultiSelect>;
  
  form: FormGroup;
  availableEntities: { label: string; value: string; api: string; url: string }[] = [];
  filteredItemsMap: { [key: number]: any[] } = {};
  referenceIdSuggestions: any[] = [];
  currentIndex: number = 0;
  loading: boolean = false;
  scheduleData: any = null;
  isUserLocalTimeZonePST: boolean = false;
  scheduleTypeOptions: any[] = [];
  draggedItemIndex: number | null = null;
  perks: any[] = [];
  
  envOptions = [
    { label: 'dev', value: 'dev' },
    { label: 'qa', value: 'qa' },
    { label: 'prod', value: 'prod' }
  ];

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private dataService: DataService,
    private commonEntityService: CommonEntityService,
    private messageService: MessageService,
    private router: Router,
    private utilitiesService: UtilitiesService
  ) {
    this.initForm();
  }

  async ngOnInit() {
    await Promise.all([
      this.loadAvailableEntities(),
      this.loadScheduleTypes()
    ]);

    await this.loadAvailableEntities();
    await this.loadPerks();
    
    if (this.mode === 'create' && this.initialScheduleData) {
      this.populateForm(this.initialScheduleData);
    }

    if (this.mode !== 'create' && this.scheduleId) {
      await this.loadScheduleData();
    }
    else if (this.mode !== 'create' && this.scheduleId) {
      this.loadScheduleData();
    }
    else if (this.mode === 'create') {
      this.scheduleRefs.push(
        this.fb.group({
          referenceEntity: ['', Validators.required],
          referenceId: [[], Validators.required]
        })
      );
    }

    this.isUserLocalTimeZonePST = this.utilitiesService.isUserLocalTimeZonePST();
  }

  private async loadScheduleData() {
    try {
      this.loading = true;
      this.scheduleData = await this.commonEntityService.findOne('content-schedule', this.scheduleId!);
      console.log('scheduleData', this.scheduleData);
      if (this.mode === 'edit') {
        this.populateForm(this.scheduleData);
      }
    } catch (error) {
      console.error('Error loading schedule data:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to load schedule data'
      });
    } finally {
      this.loading = false;
    }
  }

  private populateForm(data: any) {
    // Clear existing schedule refs
    while (this.scheduleRefs.length) {
      this.scheduleRefs.removeAt(0);
    }

    // Add schedule refs
    data.scheduleRefs?.forEach((ref: any) => {
      // Find matching entity from available entities
      const matchingEntity = this.availableEntities.find(
        entity => entity.value.toLowerCase() === ref.referenceEntity.toLowerCase()
      );
      
      // Create the form group with the correct entity value
      const scheduleRef = this.fb.group({
        referenceEntity: [matchingEntity?.value || ref.referenceEntity, Validators.required],
        referenceId: [ref.referenceId, Validators.required]
      });

      // Add to form array
      this.scheduleRefs.push(scheduleRef);
    });

    // Update form values
    this.form.patchValue({
      description: data.description,
      scheduleType: data.scheduleType,
      start: data.start ? new Date(data.start) : null,
      end: data.end ? new Date(data.end) : null,
      env: data.env || ['dev']
    });
  }

  private dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const start = control.get('start')?.value;
    const end = control.get('end')?.value;

    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      return startDate <= endDate ? null : { dateRange: true };
    }
    return null;
  }

  private initForm() {
    this.form = this.fb.group({
      description: ['', Validators.required],
      scheduleType: ['', Validators.required],
      scheduleRefs: this.fb.array([]),
      start: [this.initialStartDate, Validators.required],
      end: [this.initialEndDate, Validators.required],
      env: [[], Validators.required]
    }, { validators: this.dateRangeValidator });

    if (this.mode === 'view') {
      this.form.disable();
    }
    // Get all schedule types to populate the schedule type dropdown, use description as the label and value
    this.loadScheduleTypes();
  }

  get scheduleRefs() {
    return this.form.get('scheduleRefs') as FormArray;
  }

  addScheduleRef() {
    const scheduleRef = this.fb.group({
      referenceEntity: ['', Validators.required],
      referenceId: [[], Validators.required]
    });
    const newIndex = this.scheduleRefs.length;
    this.filteredItemsMap[newIndex] = [];
    this.scheduleRefs.push(scheduleRef);
  }

  removeScheduleRef(index: number) {
    this.scheduleRefs.removeAt(index);
    delete this.filteredItemsMap[index];
  }

  getFilteredItems(index: number): any[] {
    const entityType = this.getSelectedEntityType(index);
    if (entityType === 'Perk') {
      return this.perks;
    }
    return this.filteredItemsMap[index] || [];
  }

  private async loadAvailableEntities() {
    try {
      const response = await this.http.get<any[]>('/api/content-schedule/available-entities').toPromise() || [];
      console.log('Raw API response:', response);
      this.availableEntities = response.map(entity => ({
        label: entity.label,
        value: entity.value || entity.label.toLowerCase(), // Fallback to lowercase label if no value
        api: entity.api,
        url: entity.url
      }));
      console.log('Mapped available entities:', this.availableEntities);
    } catch (error) {
      console.error('Error loading available entities:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to load available entities'
      });
    }
  }

  async loadPerks() {
    try {
      this.perks = await this.dataService.getAllOfTypeAsync('perks', {
        select: '_id name id',
        virtuals: false,
        autopopulate: false,
        sort: { name: 1 }
      });
    } catch (error) {
      console.error('Error fetching perks:', error);
      this.perks = [];
    }
  }

  async onReferenceEntityChange(index: number) {
    const scheduleRef = this.scheduleRefs.at(index);
    const entityControl = scheduleRef?.get('referenceEntity');
    const entity = entityControl?.value;
    
    if (entity) {
      scheduleRef.patchValue({ referenceId: [] });
    }
  }

  async onSubmit() {
    if (!this.form.valid || this.mode === 'view') return;

    try {
      const formValue = this.form.value;
      
      const payload = {
        description: formValue.description,
        scheduleType: formValue.scheduleType,
        scheduleRefs: formValue.scheduleRefs,
        start: formValue.start,
        end: formValue.end,
        env: formValue.env
      };

      let response;
      if (this.mode === 'edit' && this.scheduleId) {
        response = await this.commonEntityService.update('content-schedule', this.scheduleId, payload);
      } else {
        response = await this.commonEntityService.create('content-schedule', payload);
      }
      
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: `Schedule ${this.mode === 'edit' ? 'updated' : 'created'} successfully`
      });

      this.form.reset();
      this.submitSuccess.emit();
      this.cancel.emit();

    } catch (error: any) {
      console.error('Error saving schedule:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error?.error?.message || `Failed to ${this.mode} schedule`
      });
    }
  }

  isFieldInvalid(fieldName: string): boolean {
    const field = this.form.get(fieldName);
    return field ? (field.invalid && (field.dirty || field.touched)) : false;
  }

  getErrorMessage(fieldName: string): string {
    const control = this.form.get(fieldName);
    if (control?.errors) {
      if (control.errors['required']) {
        return `${fieldName.charAt(0).toUpperCase() + fieldName.slice(1)} is required`;
      }
    }
    if (fieldName === 'end' && this.form.errors?.['dateRange']) {
      return 'End date must be after or equal to start date';
    }
    return '';
  }

  async filterItems(event: any, index: number) {
    const selectedEntity = this.form.get(['scheduleRefs', index, 'referenceEntity'])?.value;
    if (!selectedEntity) {
      this.filteredItemsMap[index] = [];
      return;
    }

    const input = event.query || event.filter || '';
    const selectedEntityOption = this.availableEntities.find(e => e.value === selectedEntity);
    
    if (!selectedEntityOption?.api) {
      this.filteredItemsMap[index] = [];
      return;
    }

    try {
      if (selectedEntity === 'Perk') {
        // Filter from the existing perks list
        if (input) {
          this.filteredItemsMap[index] = this.perks.filter(perk => 
            perk.name.toLowerCase().includes(input.toLowerCase()) ||
            perk.id.toString().includes(input)
          );
        } else {
          this.filteredItemsMap[index] = this.perks;
        }
      } else {
        this.filteredItemsMap[index] = await this.dataService.getAllOfTypeAsync(selectedEntityOption.api, {
          query: isNaN(input as any) 
            ? { name: { $regex: input, $options: 'i' } }
            : { id: input },
          select: '_id name id',
          virtuals: false,
          autopopulate: false,
          sort: { name: 1 }
        });
      }
    } catch (error) {
      console.error('Error fetching items:', error);
      this.filteredItemsMap[index] = [];
    }
  }

  ngOnChanges() {
    if (this.form) {
      if (this.mode === 'create') {
        this.form.patchValue({
          start: this.initialStartDate,
          end: this.initialEndDate
        });
      }
    }
  }

  onCancel() {
    this.form.reset();
    this.cancel.emit();
  }

  toggleEditMode() {
    if (this.mode === 'view') {
      this.mode = 'edit';
      this.modeChange.emit('edit');
      this.form.enable();
      this.populateForm(this.scheduleData);
    } else if (this.mode === 'edit') {
      this.mode = 'view';
      this.modeChange.emit('view');
      this.form.disable();
    }
  }

  openEntityView(entityType: string, entityId: string | number) {
    // Map entity types to their respective routes
    const routeMap: { [key: string]: string } = {
      'item': 'items',
      'collection': 'collections',
      'challenge': 'challenges',
      // Add other entity types and their routes as needed
    };

    const route = routeMap[entityType];
    if (route) {
      this.router.navigate([`/${route}`, entityId]);
    } else {
      console.warn(`No route mapping found for entity type: ${entityType}`);
    }
  }

  getEntityUrl(entityType: string): string {
    const entity = this.availableEntities.find(e => e.value === entityType);
    return entity?.url || '';
  }

  onCalendarShow(field: 'start' | 'end'): void {
    const currentValue = this.form.get(field)?.value;
    if (!currentValue) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.form.get(field)?.setValue(today);
    }
  }

  resetTimeToMidnight(field: 'start' | 'end'): void {
    const currentValue = this.form.get(field)?.value;
    if (currentValue) {
      const date = new Date(currentValue);
      date.setHours(0, 0, 0, 0);
      this.form.get(field)?.setValue(date);
    }
  }

  setToToday(field: 'start' | 'end'): void {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.form.get(field)?.setValue(today);
  }

  clearDate(field: 'start' | 'end'): void {
    this.form.get(field)?.setValue(null);
  }

  private async loadScheduleTypes() {
    const response = await this.dataService.findManyDynamic('schedule-type', {
      autopopulate: true,
      virtuals: true
    });

    console.log('scheduleTypes', response);
    
    this.scheduleTypeOptions = response.records
      .filter((scheduleType: any) => scheduleType.enabled)
      .map((scheduleType: any) => ({
        label: scheduleType.description,
        value: scheduleType._id,
        color: scheduleType.color
      }));

    console.log('scheduleTypeOptions', this.scheduleTypeOptions);

    if (this.scheduleTypeOptions.length > 0) {
      this.form.patchValue({
        scheduleType: this.scheduleTypeOptions[0].value
      });
    }

    console.log('form', this.form.value);
  }

  getSelectedEntityType(index: number): string {
    const scheduleRef = this.scheduleRefs.at(index);
    return scheduleRef?.get('referenceEntity')?.value || '';
  }

  onPerkOrderChange(event: any, index: number) {
    if (event && event.value) {
      const control = this.scheduleRefs.at(index).get('referenceId');
      if (control) {
        // Create a new array to trigger change detection
        const newValue = [...event.value];
        control.setValue(newValue, { emitEvent: true });
        control.markAsDirty();
      }
    }
  }

  onPerkSelect(event: any, index: number) {
    if (event.value) {
      const currentRefs = this.scheduleRefs.at(index).get('referenceId')?.value || [];
      if (!currentRefs.find((ref: any) => ref._id === event.value._id)) {
        this.scheduleRefs.at(index).get('referenceId')?.setValue([...currentRefs, event.value]);
      }
    }
  }

  removePerk(index: number, perk: any) {
    const currentRefs = this.scheduleRefs.at(index).get('referenceId')?.value || [];
    const newRefs = currentRefs.filter((ref: any) => ref._id !== perk._id);
    this.scheduleRefs.at(index).get('referenceId')?.setValue(newRefs);
    
    // No need to manually update the multiselect as it will reflect the form control value
    // through the [model] binding
  }

  onDragStart(index: number) {
    this.draggedItemIndex = index;
  }

  onDragEnd() {
    this.draggedItemIndex = null;
  }

  onDrop(scheduleRefIndex: number, dropIndex: number) {
    if (this.draggedItemIndex === null || this.draggedItemIndex === dropIndex) {
      return;
    }

    const control = this.scheduleRefs.at(scheduleRefIndex).get('referenceId');
    if (control) {
      const value = control.value || [];
      const draggedItem = value[this.draggedItemIndex];
      
      // Remove the dragged item from its original position
      value.splice(this.draggedItemIndex, 1);
      
      // Insert the dragged item at the drop position
      value.splice(dropIndex, 0, draggedItem);
      
      // Create a new array to trigger change detection
      const newValue = [...value];
      control.setValue(newValue, { emitEvent: true });
      control.markAsDirty();
    }
    
    this.draggedItemIndex = null;
  }

  onPerksMultiSelect(event: any, index: number) {
    const currentRefs = this.scheduleRefs.at(index).get('referenceId')?.value || [];
    const selectedPerks = event.value || [];
    
    // Add only new perks that aren't already in the list
    const newPerks = selectedPerks.filter((perk: any) => 
      !currentRefs.some((ref: any) => ref._id === perk._id)
    );
    
    if (newPerks.length > 0) {
      const updatedRefs = [...currentRefs, ...newPerks];
      this.scheduleRefs.at(index).get('referenceId')?.setValue(updatedRefs);
    }
    
    // Reset the multiselect value after adding perks
    setTimeout(() => {
      const multiSelect = this.multiSelects.toArray()[index];
      if (multiSelect) {
        multiSelect.value = [];
      }
    });
  }
} 