<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="challenge-keywords"
    [formName]="title"
    [(record)]="keywordRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [autopopulateEntity]="true"
    [hasAssetRefComponent]="false"
    [validationCallbackFunction]="validateKeywordForm.bind(this)"
>
</dynamic-form-v2>
