import { LoggerService } from './../../../../../common/services/logger.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import DailyRewardsDTO from '../../dtos/DailyRewardsDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-daily-rewards-form-v3',
  templateUrl: './daily-rewards-form-v3.component.html',
  styleUrls: ['./daily-rewards-form-v3.component.sass']
})
export class DailyRewardsFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  dailyRewardsRecord: DailyRewardsDTO = new DailyRewardsDTO();
  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Daily Rewards Form V3 Component Initialization
   */
ngOnInit()
  {
    this.fields =
    [
      {
        title: "Login Rewards Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'internalName',
            isRequired: true,
            label: 'Internal Name',
            disabled: false
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.dailyRewardsRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
          },
          {
            name: "Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: false,
            options: {
              fieldName: 'type',
              values: [
                { name: 'Login Rewards - 7 days', value: 1 },
                { name: 'Login Rewards - 30 days', value: 2 },
              ],
            },
            filterBy: 'name',
            displayControlType: "showHide",
            displayControls: {
              ['7Day']: (value: any) => value == 1,
              ['30Day']: (value: any) => value == 2,
            },
          },
          
          // {
          //   name: "Banner Image",
          //   inputType: DynamicInputType.MiscImageRecord,
          //   clearField: true,
          //   key: 'bannerImage',
          //   label: 'Banner Image',
          //   optionValue:'_id',
          //   filter: true,
          //   filterBy:'name,id,path',
          //   autoDisplayFirst: false,
          //   setMongoId: true,
          //   options: { fieldName: 'bannerImage', apiController: 'miscellaneous-build', customQuery: { enabled: true }, autopopulate: true, virtuals: true, sort: { name: 1 } }
          // },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Login Rewards Track",
        fields:
        [
          {
            name: "Login Rewards",
            inputType: DynamicInputType.DailyRewardsDynamicFieldComponent,
            key: 'rewardsTracks',
            label: 'Rewards Track',
            optionLabel: 'name',
            optionValue: '_id',
            
            options: { fieldName: 'rewardsTracks', apiController: 'rewards-track', useSetOptionsFromRef: false, isArrayOfStringIds: true, select: 'name id _id' },
            style: {'width': '100%'}
          },
          {
            name: '7 Days Login Rewards',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: '7Day',
            label: '7 Days Login Rewards',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            autoDisplayFirst: false,
            setMongoId: true,
            style: { 'min-width': '30em' },
            hideField: true, // Keep this to hide by default
            options: {
              fieldName: '7DayRewards',
              apiController: 'rewards-track',
              minimal: false,
              select: 'name id _id',
              autopopulate: true,
              virtuals: false,
              sort: { name: 1 },
              customQuery: { type: 1 },
            },
          },
          {
            name: '30 Days Login Rewards',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: '30Day',
            label: '30 Days Login Rewards',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            autoDisplayFirst: false,
            setMongoId: true,
            style: { 'min-width': '30em' },
            hideField: true, // Keep this to hide by default
            options: {
              fieldName: '30DayRewards',
              apiController: 'rewards-track',
              minimal: false,
              autopopulate: true,
              select: 'name id _id',
              virtuals: false,
              sort: { name: 1 },
              customQuery: { type: 2 },
            },
          },
        ],
        type: DynamicCardType.Card
      }
    ]

    let type = this.dailyRewardsRecord.type
    console.log('type value', type)

    this.title = "Login Reward";
    this.viewRoute = 'daily-rewards';
    this.isLoading = false;
  }
}
