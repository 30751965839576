import { Component, Input, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TableService } from 'src/app/services/table.service';

@Component({
  selector: 'bulk-render-assets',
  templateUrl: './bulk-render-assets.component.html',
  styleUrls: ['./bulk-render-assets.component.sass']
})
export class BulkRenderAssetsComponent implements OnInit {
  @Input() selectedRecords: Array<any> = [];
  @Input() entityType: any;

  constructor (
    private tableService: TableService,
    private messageService: MessageService
  ) { }

  /**
   * Bulk Render Assets Component Initialization
   */
  ngOnInit() {}

  /**
   * Triggers the render assets API.
   */
  async renderAssets() {
    try {
      await this.tableService.renderAssets({ entityType: this.entityType, selectedAssets: this.selectedRecords });
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Assets rendered successfully.' });
    } catch (error: any) {
      const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : 'An error occurred while inserting render diff job.';
      this.messageService.add({
        severity: 'error',
        summary: 'Error Queuing Render Diff Job',
        detail: errorMessage,
        life: 1400
      });
    }
  }
}
