import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SocialUser } from '@abacritt/angularx-social-login';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import SeriesDto from 'src/app/cms-v2/entities/series/dtos/SeriesDTO';
import { SeriesService } from 'src/app/cms-v2/entities/series/services/series.service';
import DropdownDto from 'src/app/common/dtos/DropdownDto';
import { LoggerService } from 'src/app/common/services/logger.service';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import ReleaseDTO from '../dtos/ReleaseDTO';
import { ReleaseService } from '../services/release.service';
import * as _ from 'lodash';
import { create } from 'lodash';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { SetDefaultTimeService } from 'src/app/common/services/set-default-time.service';

@Component({
  selector: 'app-release-form',
  templateUrl: './release-form.component.html',
  styleUrls: ['./release-form.component.sass']
})
export class ReleaseFormOriginalComponent implements OnInit
{
  type: string = "releases";
  releaseId: number;
  isEditMode: boolean = false;
  isLoading: boolean = true;
  releaseRecord: ReleaseDTO = new ReleaseDTO();
  seriesRecord: SeriesDto = new SeriesDto();
  originalSeriesRecord: SeriesDto = new SeriesDto();
  user: SocialUser;
  releaseDates: Array<Object> = [];
  layouts: Array<DropdownDto> =
  [
    { name: "Series", value: "Series" }
  ];
  dateObjectDefault: any = {
    description: null,
    date: new Date(this.setDefaultTimeV1()),
  }

  constructor
  (
    private logger: LoggerService,
    private entityViewService: EntityViewService,
    private messageService: MessageService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private releaseService: ReleaseService,
    private seriesService: SeriesService,
    private spinnerService: SpinnerService,
    private utilitiesService: UtilitiesService,
    private setDefaultTimeService: SetDefaultTimeService,
  ) { }

  /**
   * Release Form Component Initialization
   */
  async ngOnInit()
  {
    // Get current user
    const userResult = this.authService.getSocialUser();
    this.user = userResult.currentUser;
    const routeParams = this.route.snapshot.paramMap;
    this.releaseId = Number(routeParams.get('id'));
    if (this.releaseId)
    {
      this.isEditMode = true;
      let response = await this.entityViewService.getEntity(this.type, { query: { id: this.releaseId }, autopopulate: true });

      if(response)
      {
        this.releaseRecord = response;
        this.releaseRecord.start = this.releaseRecord.start ? new Date(this.releaseRecord.start) : null;
        this.releaseRecord.end = this.releaseRecord.end ? new Date(this.releaseRecord.end): this.releaseRecord.end;
        this.releaseRecord.release_dates ? this.parseDates() : this.releaseRecord.release_dates = []
        switch (this.releaseRecord.layout)
        {
          case 'Series':
            this.seriesRecord = this.releaseRecord.series_ref && this.releaseRecord.series_ref._id ? this.releaseRecord.series_ref : new SeriesDto();
            this.seriesRecord.start = this.seriesRecord.start ? new Date(this.seriesRecord.start) : null;
            this.seriesRecord.end =  this.seriesRecord.end ? new Date(this.seriesRecord.end) : null;
            this.originalSeriesRecord = _.cloneDeep(this.seriesRecord);
            break;

          default:
            break;
        }
      }
    }

    this.logger.log("Release: ", this.releaseRecord);
    this.isLoading = false;
  }
  /**
   * Handle Loading Screen ref changes
   *
   * @param event List of selected loading screens
   */
  onRefsChange(refName: string, event: any)
  {
    switch (refName)
    {
      case 'loading-screens':
        this.releaseRecord.loadingScreen_ref = event;
        break;
      case 'inbox-messages':
        this.releaseRecord.inboxMessages_ref = event;
        break;
    }
  }
  /**
   * Parse dates from incoming entity
   */
  parseDates() {
    if(this.releaseRecord.release_dates && this.releaseRecord.release_dates.length > 0) {
      this.releaseRecord.release_dates.forEach((element) => {
        element.date = new Date(element.date)
      })
    }
  }
  setDefaultTimeV1() {
    return this.utilitiesService.getCurrentDateAtMidnight();
  }

  setDefaultTimeDynamicDates(date: any, index: number){
    if(date.date){
      this.releaseRecord.release_dates[index].date = this.utilitiesService.getCustomDateAtMidnight(date.date.toISOString());
    } else {
      this.releaseRecord.release_dates[index].date = this.utilitiesService.getCurrentDateAtMidnight();

    }
  }

  /**
   * push default date for new dates
   */
  pushDate() {
    this.releaseRecord.release_dates.push(_.cloneDeep(this.dateObjectDefault))
  }
  /**
   * Build Date Timeline for the UI
   * @param props properties of the object
   * @param record the record to be updated
   */
  buildReleaseDateTimeline(props: Array<any>, record: any) {
    let releaseDates: any = _.cloneDeep(record.release_dates);
    let this_ = this;
    props.forEach((prop) => {
      var date = {description: '', date: new Date()}
      switch (prop.type) {
        case 'array':
          if(record[prop.key]) {
            record[prop.key].forEach((value: any) => {
              this_.pushDates(value.name + ' - Start', new Date(value.start), releaseDates)
              this_.pushDates(value.name + ' - End', new Date(value.end), releaseDates)
            });
          }
          break;
        case 'object':
          if(record[prop.key]) {
            this_.pushDates(record[prop.key].name + ' - Start', record[prop.key].start, releaseDates)
            this_.pushDates(record[prop.key].name + ' - End', record[prop.key].end, releaseDates)
          }
          break;
      }
    })
    return releaseDates;
  }
  /**
   *  Pushes the dates to the array, most likely timeline_dates
   * @param description description to add to the date
   * @param dateToPush  date to push
   * @param array array to push the date to
   */
  pushDates(description: string, dateToPush: any, array: any) {
    var date = {description: '', date: new Date()};
    date.description = description;
    date.date = dateToPush;
    if (dateToPush =! null) {
      array.push(date);
    }
  }
  /**
   * Submits Release Record
   */
  async onSubmit()
  {
    if(this.releaseRecord.layout && this.releaseRecord.layout == 'Series')
    {
      await this.submitSeriesRecord();
      if(this.seriesRecord._id)
      {
        this.releaseRecord.series_ref = this.seriesRecord._id;
      }
      else
      {
        this.messageService.add(
        {
          severity: 'error',
          summary: 'Series Submit Error',
          detail: 'There was an error creating/updating the Series.',
        });
        return;
      }
    }
    this.releaseRecord.userData =
    {
      name: this.user.name,
      email: this.user.email,
      id: this.user.id,
    };
    this.releaseRecord.start = _.cloneDeep(this.seriesRecord.start);
    this.releaseRecord.end = _.cloneDeep(this.seriesRecord.end);
    if(this.isEditMode)
    {
      let updateResponse = await this.releaseService.updateRelease(this.releaseId, this.releaseRecord);
      updateResponse.timeline_dates = this.buildReleaseDateTimeline([
        {type: 'array', key: 'loadingScreen_ref'},
        {type: 'array', key: 'inboxMessages_ref'},
        {type: 'object', key: 'series_ref'},
        {type: 'prop', key: 'start'},
        {type: 'prop', key: 'end'}],
        updateResponse);
      updateResponse = await this.releaseService.updateRelease(this.releaseId, updateResponse);
      if(updateResponse)
      {
        this.messageService.add(
        {
          sticky: true,
          severity: 'success',
          summary: 'Release Update Successful',
          detail: 'Release Updated Successfully',
        });
        this.router.navigate([`${this.type}/${updateResponse.id}`]);
      }
      else
      {
        this.messageService.add(
        {
          sticky: true,
          severity: 'error',
          summary: 'Release Submit Error',
          detail: 'There was an error updating the Release.',
        });
      }
    }
    else
    {
      let createResponse = await this.releaseService.createRelease(this.releaseRecord);
      createResponse.timeline_dates = this.buildReleaseDateTimeline([
          {type: 'array', key: 'loadingScreen_ref'},
          {type: 'array', key: 'inboxMessages_ref'},
          {type: 'object', key: 'series_ref'},
          {type: 'prop', key: 'start'},
          {type: 'prop', key: 'end'}],
          createResponse);
      createResponse = await this.releaseService.updateRelease(createResponse.id, createResponse);
      if(createResponse)
      {
        this.messageService.add(
        {
          sticky: true,
          severity: 'success',
          summary: 'Release Create Successful',
          detail: 'Release Created Successfully',
        });
        this.router.navigate([`${this.type}/${createResponse.id}`]);
      }
      else
      {
        this.messageService.add(
        {
          sticky: true,
          severity: 'error',
          summary: 'Release Submit Error',
          detail: 'There was an error creating the Release.',
        });
      }
    }
  }

  /**
   * Handles Updates or Creations of Series
   */
  async submitSeriesRecord()
  {
    this.seriesRecord.userData =
    {
      name: this.user.name,
      email: this.user.email,
      id: this.user.id,
    };

    if(this.isEditMode && this.seriesRecord.id)
    {
      let isEqual = _.isEqual(this.seriesRecord, this.originalSeriesRecord)
      if(!isEqual)
      {
        this.spinnerService.loadSpecificSpinner('release-form-spinner');
        let updateResponse = await this.seriesService.updateSeries(this.seriesRecord.id, this.seriesRecord);
        this.spinnerService.endSpecificSpinner('release-form-spinner');
        if(updateResponse)
        {
          this.seriesRecord = updateResponse;
          this.messageService.add(
          {
            sticky: true,
            severity: 'info',
            summary: 'Series Update Successful',
            detail: 'Series Updated Successfully',
          });
        }
        else
        {
          this.messageService.add(
          {
            sticky: true,
            severity: 'error',
            summary: 'Series Submit Error',
            detail: 'There was an error updating the Series.',
          });
        }
      }
    }
    else
    {
      if(this.seriesRecord.name)
      {
        this.spinnerService.loadSpecificSpinner('release-form-spinner');
        let createResponse = await this.seriesService.createSeries(this.seriesRecord);
        this.spinnerService.endSpecificSpinner('release-form-spinner');
        if(createResponse)
        {
          this.seriesRecord._id = createResponse._id;
          this.messageService.add(
          {
            sticky: true,
            severity: 'info',
            summary: 'Series Create Successful',
            detail: 'Series Created Successfully.',
          });
        }
        else
        {
          this.messageService.add(
          {
            sticky: true,
            severity: 'error',
            summary: 'Series Submit Error',
            detail: 'There was an error creating the Series.',
          });
        }
      }
    }
  }

  /**
   * Handle Release Enable changes
   */
  onEnabledChange()
  {
    this.seriesRecord.enabled = this.releaseRecord.enabled;
  }

    /**
   * Sets current date at midnight
   * @param patchValue checks if the value needs to be patched or not. This will be determined via if the form is using FormGroups or not.
   */
 setDefaultTime(prop: any) {
  this.setDefaultTimeService.setDefaultTime(false,this.releaseRecord,prop);
  }
}
