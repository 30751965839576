import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import ItemFileTypeDTO from '../dtos/itemFileTypeDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-item-file-type-form',
  templateUrl: './item-file-type-form.component.html',
  styleUrls: ['./item-file-type-form.component.sass']
})
export class ItemFileTypeFormV2Component extends BaseDynamicFormComponent implements OnInit
{
  itemFileTypeRecord: ItemFileTypeDTO = new ItemFileTypeDTO();
  constructor() 
  {
    super();
  }

  /**
   * Item File Type Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Item File Type Data",
        fields: 
        [
          { 
          name: "Name", 
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.TextInput],
          clearField: true, 
          key: 'name', 
          isRequired: true, 
          label: 'Name', 
          disabled: false 
        },
        { 
          name: "Code", 
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.TextInput],
          clearField: true, 
          key: 'code', 
          isRequired: true, 
          label: 'Code', 
          disabled: false 
        },
        { 
          name: "Path Name", 
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.TextInput],
          clearField: true, 
          key: 'pathName', 
          isRequired: true, 
          label: 'Path Name', 
          disabled: false 
        },
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Item File Type";
    this.viewRoute = 'item-file-types';
    this.isLoading = false;
  } 
}
    
  

