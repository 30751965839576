import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SourcingService 
{
  private readonly itemApiURLprefix : string = '/api/items-sourcing';
  private readonly challengeApiURLprefix : string = '/api/challenges-sourcing';
  constructor(private http: HttpClient) {}

  /**
   * Updates a Iten Source record.
   * 
   * @param itemSourceId Id of the item source to update.
   * @param itemSourceData Item Source data to be updated.
   */
  async updateItemSourcing(itemSourceId: any, itemSourceData: any): Promise<any>
  {
    return await this.http.patch(`${this.itemApiURLprefix}/update/${itemSourceId}`, itemSourceData).toPromise();
  }

  /**
   * Updates a Iten Source record.
   * 
   * @param challengeSourceId Id of the item source to update.
   * @param challengeSourceData Item Source data to be updated.
   */
   async updateChallengeSourcing(challengeSourceId: any, challengeSourceData: any): Promise<any>
   {
     return await this.http.patch(`${this.challengeApiURLprefix}/update/${challengeSourceId}`, challengeSourceData).toPromise();
   }
}