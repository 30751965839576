import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Batch DTO
 */
export default class ItemFileTypeDTO extends BaseEntityDto
{
    /**
     * Code for the item file type
     */
    code: String;
    /**
     * item file type path
     */
    pathName: String
}