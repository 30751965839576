/**
 * Release Field Type Enum
 */
export enum ReleaseFieldType
{
   // PrimeNG Types/Components
   Prizes = 1,
   Challenges,
   Colors,
   Collections,
   LoadingScreen,
   Timeline,
   AnnouncementImage,
   Copy,
   Name,
   Inbox

}