import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import NurtureCardDto from '../dtos/NurtureCardDTO';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-nurture-card-form',
  templateUrl: './nurture-card-form.component.html',
  styleUrls: ['./nurture-card-form.component.sass']
})
export class NurtureCardFormComponent extends BaseDynamicFormComponent implements OnInit {

  nurtureCardRecord: NurtureCardDto = new NurtureCardDto();
  constructor(
    private validation: BaseInputValidationServiceService
  ) {
    super();
  }


  /**
   * Nurture Card Form V2 Component Initialization
  */

  ngOnInit(){

    this.fields =
    [
      {
        title: "Nurture Card Data",
        fields:
        [
          { name: "Nurture Card Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Nurture Card Name'
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.nurtureCardRecord.start,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: "Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'image_ref',
            label: 'Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            setMongoId: true,
            autoDisplayFirst: false,
            scrollHeight: '200px',
            options: { fieldName: 'image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: { $in: [3, 4] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true }
          },
          {
            name: "Video",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'video_ref',
            label: 'Video',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            setMongoId: true,
            autoDisplayFirst: false,
            scrollHeight: '200px',
            options: { fieldName: 'video_ref', apiController: 'miscellaneous-build', customQuery: { entityType: { $in: [6] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true }
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Nurture Card Form";
    this.viewRoute = 'nurture-card';
    this.isLoading = false;
  }


}
