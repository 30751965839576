<div class="p-inputgroup">
    <span class="p-float-label">
        <!-- dropdown -->
        <p-dropdown [options]="environments" [(ngModel)]="selectedEnvironment" [style]="{ width: '120px'}"
            [autoWidth]="false" [filter]="true" [optionLabel]="optionLabel" class="p-inputtext-lg"
            (onChange)="onEnvironmentChange($event)">

            <!-- Custom template for the selected item -->
            <ng-template pTemplate="selectedItem" let-selectedEnvironment>
                <p-tag class="custom-dropdown-tag" [rounded]="true"
                    [severity]="getSeverity(selectedEnvironment.value)" [value]="selectedEnvironment.value | uppercase">
                </p-tag>
                <div class="flex align-items-center gap-2" *ngIf="selectedEnvironment">
                    <!-- Custom content for selected item, e.g., display the environment name -->
                    <div>{{selectedEnvironment.name}}</div>
                </div>
            </ng-template>

            <!-- Custom template for each item in the dropdown -->
            <ng-template let-environment pTemplate="item">
                <div class="flex align-items-center gap-2">
                    <p-tag class="p-ml-3 custom-dropdown-tag" [rounded]="true"
                        [severity]="getSeverity(environment.value)" [value]="environment.value | uppercase">
                    </p-tag>
                    <!-- Custom content for each item, e.g., display the environment name -->
                    <div>{{environment.name}}</div>
                </div>
            </ng-template>
        </p-dropdown>
    </span>
</div>

<p-confirmDialog></p-confirmDialog>
