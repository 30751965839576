import { ChangeDetectorRef, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart } from '@angular/router';
import * as _ from 'lodash';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { BuildType } from 'src/app/enums/build-type';
import { DataService } from '../../../services/data.service';
import { Title } from '@angular/platform-browser';
import { AssetTypes } from '../../enums/AssetTypes';
import { AuthService } from 'src/app/auth/auth.service';
import { secondaryCard } from '../data/constants';
import { ChallengeService } from '../services/challenge.service';
import { BuildService } from 'src/app/build-data/services/build.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { ArtToolsService } from 'src/app/tools/art/services/art-tools.service';
import { primaryCard } from '../data/constants';
import { Router } from '@angular/router';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { FormService } from 'src/app/services/form.service';
import { UserService } from 'src/app/user/service/user.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { EnvironmentService } from 'src/app/common/services/environment.service';
import { InitialsDialogComponent } from '../initials-dialog/initials-dialog.component';
import { ChallengeImagePopupComponent } from '../challenge-image-popup/challenge-image-popup.component';

@Component({
  selector: 'challenge-view',
  templateUrl: './challenge-view.component.html',
  styleUrls: ['./challenge-view.component.sass'],
})

export class ChallengeViewV2Component implements OnInit {

  @ViewChild(InitialsDialogComponent) initialsDialogComponent: InitialsDialogComponent;
  @ViewChild(ChallengeImagePopupComponent) challengeImagePopupComponent!: ChallengeImagePopupComponent;
  @Input() id: any;
  environmentName: string;

  entity: any = {};
  spawners: any = [];
  requiredSpawner: any;
  optionalSpawner: any;
  type: string = 'challenges';
  page: string = 'view';
  isClaimed: boolean = false;
  isLoading: boolean = true;
  categories: any;
  totals: any = [];
  areEnvValuesOk: string;
  buildAndRender: boolean = true;
  flaggedDoNotUse: boolean = false;

  editURL: string = '';

  currentUserData = this.authService.getSocialUser();

  view: any[] = [900, 400];

  //
  gameConfig: any;
  entryPayoutWater: any;
  entryPayoutCoins: any;
  showCoinConfig: boolean= false;
  showWaterConfig: boolean= false;
  //

  imgData: any = {};
  showImgDialog: boolean = false;

  displayDuplicationModal: boolean = false;

  headerFieldGroup: any[] = [
    {
      name: 'ID',
      key: 'id',
      value: '',
      controlType: 'inputNumber',
    },
  ];
  statusFieldGroup: any[] =
  [
    {
      name: 'Enabled',
      key: 'enabled',
      value: '',
      controlType: 'toggleButton',
    },
    {
      name: 'Infinite Gardens Excluded',
      key: 'isInfiniteChallengeExcluded',
      value: '',
      controlType: 'toggleButton',
    },
  ];
  cards: any[] = primaryCard;
  secondaryCard: any = secondaryCard;
  buildStatusFieldGroup: any[] =
  [
    {
      name: 'Build Status',
      key: 'scene_ref',
      controlType: 'buildStatus',
    },
  ];
  imageBuildStatusFieldGroup: Array<any> = [
    {
      name: 'Feed Image Build Status',
      key: 'image_ref',
      controlType: 'buildStatus',
    },
  ];

  restrictions: any;
  feedImageID: any;
  fileName: any;
  voteStart: any;
  voteEnd: any;
  sceneSplit: any;
  currencyIcon: any;

  localizedValues: any;
  displayLocalizedValues: boolean = false;

  checkTimezone: boolean = false;
  get buildType(): typeof BuildType {
    return BuildType;
  }
  get assetType(): typeof AssetTypes {
    return AssetTypes;
  }

  displayInitialsDialog: boolean = false;
  userInitials: string = '';
  existingUserInitials: any;

  s3Cdn: string = 'https://d3tfb94dc03jqa.cloudfront.net/';

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private titleService: Title,
    private authService: AuthService,
    public challengeService: ChallengeService,
    private commonEntityService: CommonEntityService,
    private buildService: BuildService,
    private validationService: ValidationsService,
    private artToolsService: ArtToolsService,
    private router: Router,
    private formService: FormService,
    private userService: UserService,
    private utilitiesService: UtilitiesService,
    private spinnerService: SpinnerService,
    private changeDetector: ChangeDetectorRef,

  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Check if the navigation is triggered by a popstate event,
        // indicating that the page is being restored from bfcache
        if (event.navigationTrigger === 'popstate') {
          // Force a reload or perform any other necessary actions
          location.reload();
        }
      }
    });
    this.environmentName = EnvironmentService.getEnvironmentName();
  }
  openDialog() {
    this.initialsDialogComponent.showInitialsDialog();
  }

  async ngOnInit() {
    let result = localStorage.getItem('gameConfig')
    this.gameConfig = JSON.parse(result ? result : '');
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    this.categories = await this.dataService.getAllOfTypeAsync('categories', {
      query: {},
      autopopulate: false,
      virtuals: false,
    });
    this.categories = await this.challengeService.buildCategories(this.categories);

    if(this.id)
    {
      this.challengeService.id = this.id;
      let response = await this.commonEntityService.findOneWithQuery(this.type, { query: { id: this.id } });
      if(response.rewards_ref && !(response.rewards_ref.length > 0)) {
        this.setDefaultEntryPayout(response.type_ref ? response.type_ref.name : '')
      }
      this.loggerService.log('object', response)
      if(response && response.id)
      {
        this.entity = response;
        this.challengeService.entity = response;
        this.editURL =  `challenges/update/${this.entity.id}`;
        // assign page title
        this.titleService.setTitle(this.entity.name);
        this.spawners = _.cloneDeep(response.spawners);
        this.feedImageID = this.entity.image ? this.entity.image.split('/')[this.entity.image.split('/').length-1] : ''
        this.restrictions = await this.challengeService.viewRestrictions(this.entity.restrictions_ref);
        let spawners = await this.challengeService.setupSpawners(this.entity, this.spawners, this.categories, this.optionalSpawner, this.requiredSpawner);
        await this.challengeService.setBuildData(this.entity.scene_ref, this.entity.image_ref);
        this.requiredSpawner = spawners ? spawners[1] : null;
        this.optionalSpawner = spawners ? spawners[0] : null;
        this.entity.thumbnail = this.entity.thumbnails && this.entity.thumbnails.length > 0 ? this.entity.thumbnails[1].path : this.entity.feed_image_url;

        if(this.entity.fileName && this.entity.fileName.includes("xx_") && this.entity.scene && this.entity.scene.includes(this.entity.fileName))
        {
          // this means the challenge has not being claimed.
          this.loggerService.log('has not being claimed');
        }
        else
        {
          this.isClaimed = true;
        }

        let envCheck = await this.challengeService.checkEnvFlagsRequirements(this.entity);
        if(this.environmentName == 'test'){
          this.areEnvValuesOk = 'gray-status'
        } else if ((envCheck.devReady && envCheck.devAdded) && (envCheck.qaReady && envCheck.qaAdded) && (envCheck.prodReady && envCheck.prodAdded)) {
          this.areEnvValuesOk = 'green-status'
        } else if ((!envCheck.devReady || !envCheck.devAdded) || (!envCheck.qaReady || !envCheck.qaAdded)) {
          this.areEnvValuesOk = 'red-status'
        } else if (((envCheck.devReady && envCheck.devAdded)  || (!envCheck.qaReady || !envCheck.qaAdded)) && (!envCheck.prodReady || !envCheck.prodAdded)) {
          this.areEnvValuesOk = 'yellow-status'
        } else {
          this.areEnvValuesOk = 'grey-status'
        }

        // Asset Challenge Promoted Version props
        this.entity.IsAssetUpToDate = this.buildService.getPromotionStatus(this.entity.scene_ref, BuildType.Levels);
        this.entity.IsImageUpToDate = this.buildService.getPromotionStatus(this.entity.image_ref, BuildType.Images);
        this.entity.asset_versions = this.buildService.getLatestPromotedVersion(this.entity.scene_ref, 'asset');
        this.entity.image_versions = this.buildService.getLatestPromotedVersion(this.entity.image_ref, 'image');
        this.entity.LatestAssetBuildVersion = this.buildService.getLatestBuiltVersion(this.entity.scene_ref);
        this.entity.LatestImageBuildVersion = this.buildService.getLatestBuiltVersion(this.entity.image_ref);

        // generate renderPreview image
        this.entity.renderPreview = this.artToolsService.generateImagePath(this.entity.id,"challenges");

        this.secondaryCard.fields.forEach((f: any) => {
          this.challengeService.populateImgField(f, this.entity);
        });

        // Setting the vote start and vote end dates through the challenge end date

        // The following lines set the voteStart and voteEnd dates based on the entity's end date.
        // voteStart is set to the same date as the end date.
        // voteEnd is set to the day after voteStart.
        if (this.entity.end)
        {
          this.entity.voteStart = new Date(this.entity.end);
          this.entity.voteEnd = new Date(this.entity.voteStart);
          //this.entity.voteStart.setDate(this.entity.voteStart.getDate());
          this.entity.voteEnd.setDate(this.entity.voteEnd.getDate() + 1);
        }

        this.statusFieldGroup.forEach((field: any) => {
          field.value = this.challengeService.parseValueForView(this.entity[field.key], field);
        });

        this.cards.forEach((card) => {
          card.fieldGroups.forEach((group: any) => {
            group.forEach((field: any) => {
              field.value = this.challengeService.parseValueForView(
                this.entity[field.key],
                field
              );
            });
          });
        });
      }
    };

    for(let card of this.cards)
    {
      card.fieldGroups = this.challengeService.chunkArray(card.fieldGroups);
    }

    this.checkTimezone = this.utilitiesService.isUserLocalTimeZonePST();
    await this.checkForInitials();

    this.validationService.validateEnvOrder(this.entity);
    this.isLoading = false;
  }

@HostListener('window:popstate', ['$event'])
onPopState(event: any){
  location.reload()
}

/**
   * Updates the build data for the entity.
   */
async updateBuildData()
{
  setTimeout(async () =>
  {
    let record = await this.commonEntityService.findOneWithQuery(this.type, { query: { id: this.id } });

    if(record)
    {
      this.entity.scene_ref = record.scene_ref;
      this.entity.image_ref = record.image_ref;
    }
    // get build data.
    await this.getBuildData();
  }, 500);
}

 /**
 * Retrieves the build data for the entity.
 */
 async getBuildData()
 {
   if(this.entity.scene_ref && this.entity.scene_ref.buildData)
   {
     let response = await this.buildService.getBuildData(this.entity.scene_ref.buildData);
     this.entity.scene_ref.buildData = response.buildData;
     this.entity.scene_ref.buildStatus = response.buildStatus;
   }
   else
   {
    this.entity.scene_ref.buildData = [];
    this.entity.scene_ref.buildStatus = {
       text: 'Build Status Not Found',
       date: '',
       color: 'var(--gray-400)',
       buildCount: 0,
     };
   }
   if(this.entity.image_ref && this.entity.image_ref.buildData)
   {
     let response = await this.buildService.getBuildData(this.entity.image_ref.buildData);
     this.entity.image_ref.buildData = response.buildData;
     this.entity.image_ref.buildStatus = response.buildStatus;
   }
   else
   {
    this.entity.image_ref.buildData = [];
    this.entity.image_ref.buildStatus = {
       text: 'Build Status Not Found',
       date: '',
       color: 'var(--gray-400)',
       buildCount: 0,
     };
   }
 }

 /** Compiles and sets imgData object for Image Dialog, then sets showImgDialog to true.
   * @param field FieldData
   */
 displayImageModal(field: any) {
  if (field) {
    let keyPrefix = field.key.substring(0, field.key.lastIndexOf('_'));

    this.imgData = {
      title: field.name,
      path: ''
    };

    if (this.entity[field.key]) {
      if (field.key.endsWith('_img')) {
        this.imgData.name = this.entity[field.key].replace('_128', '_1024');
        this.imgData.path = this.entity[field.key].replace('_128', '_1024');
      } else if (field.key === 'thumbnail') {
        this.imgData.name = this.entity[field.key].replace('_256', '_1024');
        this.imgData.path = this.entity[field.key].replace('_256', '_1024');
      } else {
        for (let keySuffix of [ '_url_32','_url_64','_url_128','_url_256','_url_1024' ]) {
          if (field.key.endsWith(keySuffix)) {
            this.imgData.path = this.entity[keyPrefix + '_1024'];
            break;
          }
        }
      }
    }

    this.imgData = this.imgData;
    if (this.imgData.path) {
      this.showImgDialog = false;
      this.changeDetector.detectChanges();
      this.showImgDialog = true;

    }
  }
}

containsDoNotUse() {
  if (this.entity.contentHold_ref) {
    let currentContentValue = this.entity.contentHold_ref;
    this.flaggedDoNotUse = currentContentValue.some((obj: any) => obj.name.toLowerCase() === 'do not use');
  }

}

async renameAndClaim() {
  const initials = this.existingUserInitials || this.generateInitials();
  let filename = this.entity.fileName.replace('xx', initials);
  let scene = this.entity.scene.replaceAll(this.entity.fileName, filename);
  let value = {
    id: this.entity.id,
    fileName: filename,
    scene: scene
  };

  await this.autoSetArtist(this.entity, this.currentUserData);
  this.formService.submitForm(value, this.editURL, true).subscribe(
    (val) => {
      this.loggerService.log('updated:', val);
      location.reload();
      // this.utilitiesService.refreshComponent(`challenges/${this.entity.id}`)
    }
  );
}


generateInitials(): string {
  return this.userInitials ||
    this.currentUserData.currentUser.firstName.substr(0, 1).toLocaleLowerCase() +
    this.currentUserData.currentUser.lastName.substr(0, 1).toLocaleLowerCase();
}

/**
 * Automaticaly sets the artist name to be the current user who claimed the challenge.
 */
async autoSetArtist(entity: any, currentUserData: any)
{
  // If artist is empty, set current user as artist
  if
  (
    entity &&
    (!entity.artist_ref ||
    !entity.artist_ref.name ||
    entity.artist_ref.name.length == 0)
  )
  {
    entity.artist_ref = { };
    let artistName = currentUserData.currentUser.name;
    let user = await this.userService.findUser({ query: { name: artistName }});
    if(user)
    {
      let response = await this.challengeService.updateChallenge(entity.id, { artist_ref: { _id: user._id, id: user.id, name: user.name }});
      entity.artist_ref = response ? response.artist_ref : null;
    }
  }
}

async duplicationConfirmation(entity: any, service: any) {
  this.spinnerService.loadSpinner();
  await this.challengeService.duplicateChallenge(entity, service);
  this.spinnerService.endSpinner();
  window.location.reload();
}

async setDefaultEntryPayout(type: any) {
  await this.utilitiesService.getGameConfigFromBackend();
  if(type == 'Daily') {

    let daily = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'submit_challenge_rewards_daily').Value)
    let daily_water = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'nurture_challenge_reward_settings').Value)
    this.entryPayoutWater = daily_water['DailyWaterReward']
    this.entryPayoutCoins = daily[Object.keys(daily)[0]]
    this.showCoinConfig = true;
    this.showWaterConfig = true;
  } else if (type == 'Classic') {
    let classic = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'submit_challenge_rewards_classic').Value)
    let classic_water = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'nurture_challenge_reward_settings').Value)
    this.entryPayoutWater = classic_water['ClassicWaterReward']
    this.entryPayoutCoins = classic[Object.keys(classic)[0]]
    this.showCoinConfig = true;
    this.showWaterConfig = true;
  } else if (type == 'Series') {
    let series_water = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'nurture_challenge_reward_settings').Value)
    this.entryPayoutWater = series_water['SeriesWaterReward']
    this.showWaterConfig = true;
  } else if (type == 'Grand') {
    let grand_water = JSON.parse(this.gameConfig.find((element: any) => element.Key == 'nurture_challenge_reward_settings').Value)
    this.entryPayoutWater = grand_water['EliteWaterReward']
    this.showWaterConfig = true;
  }
}

async fetchLocalized() {
  const data = await this.dataService.fetchGridlyRecord(this.id, 'challenges');
  this.localizedValues = this.formatData(data);
  this.displayLocalizedValues = true;
}

formatData(data: any[]): any[] {
  const excludedColumns = ['update_trigger', 'glossaryRes', 'start', 'translationRequest', 'verified', 'isLive'];

  return data.map(record => {
    const readableId = record.id.split('_')[1] || record.id;
    const formattedRecord: any = { id: readableId.charAt(0).toUpperCase() + readableId.slice(1) };
    record.cells.forEach((cell: any) => {
      if (!excludedColumns.includes(cell.columnId)) {
        formattedRecord[cell.columnId] = cell.value;
      }
    });
    return formattedRecord;
  });
}

  getColumns(data: any[]): string[] {
    if (data.length === 0) {
      return [];
    }
    return Object.keys(data[0]).filter(key => key !== 'id');
  }

  // Check for initials when the component initializes
  async checkForInitials() {
    await this.initialsDialogComponent.fetchInitials();
  }

}
