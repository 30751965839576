<ngx-spinner bdColor="rgba(36,34,36,0.8)" size="custom" style="width: 80px; height: 80px;" color="#81c784" type="timer">
  <p style="font-size: 20px; color: white; z-index: 9999999;">Duplicating...</p>
</ngx-spinner>
<div *ngIf="entity && !isLoading">
  <div>
    <app-entity-header [entity]="entity" [type]="type" (onToggleDuplicationModal)="displayDuplicationModal = !displayDuplicationModal; containsDoNotUse();"(onShowDupDialog)="dupDialog.show({ id: entity.id, _id: entity._id })" (onFetchLocalizedValues)="fetchLocalized()"></app-entity-header>
  </div>
  <!-- Overall Container for the Both Left and Right Cards -->
  <div class="p-grid nested-grid p-mt-3">
    <!-- Main Column (left) -->
    <div class="p-col-12 p-lg-8">
      <!-- Status Indicators -->
      <app-status-field-group [statusFieldGroup]="statusFieldGroup" [entity]="entity"></app-status-field-group>
      <!-- Master Build Status (sm scrn) -->
      <ng-container *ngFor="let field of buildStatusFieldGroup">
        <span class="p-grid p-text-center p-d-lg-none" *ngIf="entity[field.key] && entity[field.key].buildStatus">
          <div class="p-col p-mx-2 p-mt-3" [ngStyle]="{
              position: 'relative',
              color: 'var(--primary-color-text)',
              'background-color': entity[field.key].buildStatus.color,
              'border-radius': '0.5em'
            }">
            <ng-container [ngSwitch]="entity[field.key].buildStatus.text">
              <ng-container *ngSwitchCase="'No build data'">
                <h3 class="p-m-0 p-text-center">No Build Data</h3>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <h3 class="p-d-inline p-m-0 p-text-center">
                  {{ entity[field.key].buildStatus.text }}
                </h3>
                <p class="p-d-inline">
                  {{ entity[field.key].buildStatus.date }}
                </p>

                <p-badge [value]="entity[field.key].buildStatus.buildCount" [style]="{
                    'background-color': 'var(--text-color)',
                    position: 'absolute',
                    right: '20%',
                    top: '-1em'
                  }"></p-badge>
              </ng-container>
            </ng-container>
          </div>
        </span>
      </ng-container>
      <!-- Main Body Cards -->
      <p-card *ngFor="let card of cards" class="p-mx-2 p-shadow-4">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-p-3">
            <span class="p-card-title">{{ card.name }}</span>
          </div>
          <ng-container *ngIf="card.name == 'Build Data' && isClaimed">
            <!-- Build and Render -->
            <div style="margin: 15px;">
              <div>
                <h5 class="p-d-inline p-mr-2">Render Asset? <i
                    pTooltip="Renders the asset once it has been successfully built, and a slack notification is sent to preview the asset."
                    class="pi pi-info-circle" style="font-size: 12px"></i></h5>
                <p-inputSwitch class="p-d-inline" [(ngModel)]="buildAndRender"></p-inputSwitch>
              </div>
            </div>
            <!-- Build Actions -->
            <div class="p-d-flex p-pl-3 p-pr-3 p-pb-3">
              <build-asset class="p-mr-auto" [entity]="entity" [path_ref]="entity.scene_ref" [type]="type"
                (triggerCompleteEvent)="updateBuildData($event)" label="Build Scene" [buildType]="buildType.Levels"
                [renderAsset]="buildAndRender" assetType="asset"></build-asset>
              <build-asset class="p-ml-2" [entity]="entity" [path_ref]="entity.image_ref" type="challenge-feed"
                (triggerCompleteEvent)="updateBuildData($event)" label="Build Feed Image" [buildType]="buildType.Images"
                assetType="image"></build-asset>
            </div>
            <div class="p-d-flex p-pl-3 p-pr-3 p-pb-3">
              <build-asset class="p-mr-auto" [entity]="entity" [path_ref]="entity.scene_ref" [type]="type"
                (triggerCompleteEvent)="updateBuildData($event)" label="Build Scene as High Priority"
                [buildType]="buildType.Levels" [isHighPriority]="true" [renderAsset]="buildAndRender"
                assetType="asset"></build-asset>
              <build-asset class="p-ml-2" [entity]="entity" [path_ref]="entity.image_ref" type="challenge-feed"
                (triggerCompleteEvent)="updateBuildData($event)" label="Build Feed Image as High Priority"
                [buildType]="buildType.Images" assetType="image" [isHighPriority]="true"></build-asset>
            </div>
          </ng-container>
          <ng-container *ngIf="card.name == 'Build Data' && !isClaimed">
            <div class="p-m-2">
              <p-chip
              label="Claiming a challenge shell will replace the filename's `xx` placeholder with your initials: {{ existingUserInitials || generateInitials() }}."
              icon="pi pi-info-circle">
            </p-chip>
            </div>
            <div class="p-mx-5">
              <!-- <p-button label="Rename and Claim Shell" (click)="renameAndClaim()"></p-button> -->
              <p-button label="Rename and Claim Shell" (click)="openDialog()"></p-button>
            </div>
          </ng-container>
          <ng-container *ngIf="card.name == 'History'">
            <app-challenge-history [entity]="entity"></app-challenge-history>
          </ng-container>          
          <ng-container *ngIf="card.name == 'Spawners'">
            <div class="p-mx-5">
              <p-accordion>
                <p-accordionTab
                  [header]="'Required Spawners - ' + (entity.spawner_count ? entity.spawner_count.required : '0')">
                  <nested-econ-table [tableData]="requiredSpawner" (emitterData)="totals.push($event)">
                  </nested-econ-table>
                </p-accordionTab>
                <p-accordionTab
                  [header]="'Optional Spawners - ' + (entity.spawner_count ? entity.spawner_count.optional : '0')">
                  <nested-econ-table [tableData]="optionalSpawner" (emitterData)="totals.push($event)"
                    [challengeID]="id">
                  </nested-econ-table>
                </p-accordionTab>
              </p-accordion>
              <div class="card">
                <p-table class="p-mt-2" [value]="challengeService.getTotals(totals.flat(1))" styleClass="p-datatable-sm"
                  responsiveLayout="scroll">
                  <ng-template pTemplate="header">
                    <tr>
                      <th>Total Expected Coin Spend</th>
                      <th>Total Expected Diamond Spend</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-total>
                    <tr>
                      <td>{{total.totalExpectedCoin}}</td>
                      <td>{{total.totalExpectedDiamond}}</td>
                    </tr>
                  </ng-template>
                </p-table>
                <br>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="p-grid p-formgrid p-fluid">
            <span *ngFor="let column of card.fieldGroups" class="p-col-12 p-sm-6 card-col">
              <ul *ngFor="let group of column" style="list-style-type: none">
                <li *ngFor="let field of group">
                  <span *ngIf="group.length > 0 && field.key != 'internalNotes'" class="p-text-bold">
                    {{ field.name }}:
                  </span>
                  <ng-container [ngSwitch]="field.key">
                    <ng-container *ngSwitchCase="'rewards_ref'">
                      <p-tag *ngIf="showCoinConfig || showWaterConfig" class="p-mt-4" icon="pi pi-info-circle"
                        value="Entry Payout info has been loaded from Game Config"></p-tag>
                      <ng-container *ngIf="field.value && field.value.length > 0">
                        <div *ngFor="let prize of field.value">
                          <img *ngIf="prize.lineItemType != 'Item' && prize.lineItemType != 'SeedPackBox'"
                            src="{{prize.id.image_ref ? prize.id.image_ref.thumbnail_url_32 : null}}" style="
                            height: 40px;
                            vertical-align: middle;" alt="">
                          {{ prize.c }}
                          {{ prize.id.name }}
                        </div>
                      </ng-container>
                      <ng-container *ngIf="field.value && !field.value.length > 0 && showCoinConfig">
                        <img src="https://d3tfb94dc03jqa.cloudfront.net/images/currency/coin_small/coin_small_32.png"
                          style="
                            height: 20%;
                            width: 20%;
                            vertical-align: middle;" alt="">
                        {{ entryPayoutCoins }}
                        {{ 'Coins' }}
                      </ng-container>
                      <ng-container *ngIf="field.value && !field.value.length > 0 && showWaterConfig">
                        <img
                          src="https://d3tfb94dc03jqa.cloudfront.net/images/currency/waterdrop_large/waterdrop_large_32.png"
                          style="
                          height: 20%;
                          width: 20%;
                          vertical-align: middle;" alt="">
                        {{ entryPayoutWater}}
                        {{ 'Water' }}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'prizes_ref'">
                      <div class="p-grid">
                        <div class="p-col-3">
                          <div class="p-mt-4">
                            <div class="p-mb-4"><strong>4 star</strong></div>

                            <span class="p-text-normal" *ngIf="field.value.length > 0">
                              <!-- Commented out per CMS-2056 -->
                              <!-- <img
                              *ngIf="this.entity.lineItemType != 'Item' && entity.prizes_ref && entity.prizes_ref.length > 0"
                              src="{{this.entity.prizes_ref && this.entity.prizes_ref[1] && this.entity.prizes_ref[1].id && this.entity.prizes_ref[1].id.image_ref ? this.entity.prizes_ref[1].id.image_ref.thumbnail_url_32 : null}}"
                              style="
                                height: 20%;
                                width: 20%;
                                vertical-align: middle;" alt=""> -->
                              {{ field.value[0].c }}
                              <a href="/items/{{ field.value[0].id.id }}" target="_blank" class="p-text-normal"
                                style="color: #81c784">{{ field.value[0].id.name }} ({{ field.value[0].id.id }})
                              </a></span>
                          </div>
                        </div>
                        <div class="p-col-3">
                          <div class="p-mt-4">
                            <div class="p-mb-4"><strong>5 star</strong></div>
                            <span class="p-text-normal" *ngIf="field.value.length > 1" style="vertical-align: middle;">
                              <img *ngIf="this.entity.lineItemType != 'Item'"
                                src="{{ this.entity.prizes_ref && this.entity.prizes_ref[1] && this.entity.prizes_ref[1].id.image_ref ? this.entity.prizes_ref[1].id.image_ref.thumbnail_url_32 : null}}"
                                style="
                                height: 20%;
                                width: 20%;
                                vertical-align: middle;" alt="">
                              {{ field.value[1].c }}
                              {{ field.value[1].id.name }}</span>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'keywords_ref'">
                      <ul>
                        <li *ngFor="let keyword of entity.keywords_ref">
                          <a 
                            [routerLink]="'/challenge-keywords/' + keyword.id" 
                            style="color: var(--primary-color)"
                            target="_blank"
                            rel="noopener noreferrer">
                            {{ keyword.name }}
                          </a>
                        </li>
                      </ul>
                    </ng-container>                                                                                                                                                                   
                    <ng-container *ngSwitchCase="'color'">
                      <ng-container *ngIf="entity.type_ref">
                        <span class="p-ai-center">
                          {{ field.value }}
                          <p-colorPicker [(ngModel)]="entity.type_ref.color" [disabled]="true"></p-colorPicker>
                        </span>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'restrictions_ref'">
                      <div *ngFor="let restriction of restrictions">
                        <a [ngStyle]="{ color: 'var(--primary-color)' }" target="_blank"
                          [routerLink]="'/restrictions/' + restriction.id">{{ restriction.name }}</a>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="'progressionLevel_ref'">
                      <span class="p-ai-center">
                      {{ entity.progressionLevel_ref?.name }}
                      </span>
                    </ng-container>
                    <ng-container *ngSwitchCase="'associated_challenge'">
                      <div *ngIf="entity.parent_challenge">
                        <a [ngStyle]="{ color: 'var(--primary-color)' }" target="_blank"
                          [routerLink]="'/challenges/' + entity.parent_challenge">Parent({{ entity.parent_challenge
                          }})</a>
                      </div>
                      <div *ngIf="entity.child_challenge">
                        <a [ngStyle]="{ color: 'var(--primary-color)' }" target="_blank"
                          [routerLink]="'/challenges/' + entity.child_challenge">Child({{ entity.child_challenge }})</a>
                      </div>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      <!--  -->
                      <ng-container
                        *ngIf="['multiSelect_ref', 'MiscImageRecordV2' ].includes(field.controlType) else elseAppDataFieldBlock">
                        <app-data-field [field]="field" [value]="entity[field.key]" [page]="'view'" [entityType]="type"
                          [entity]="entity"></app-data-field>
                      </ng-container>
                      <!--  -->
                      <ng-template #elseAppDataFieldBlock>
                        <span *ngIf="['calendar-end', 'calendar-start' ].includes(field.controlType)">
                          {{ field.value | officeTime }}
                          <div style="margin-bottom: 10px; text-align: right; margin-right: 50px" *ngIf="!checkTimezone">
                            <h5 class="p-d-inline">Local Time: </h5><small>{{ field.value | date: 'medium'  }}</small>
                          </div>
                        </span>
                        <span
                          *ngIf="field.key != 'artist_ref' && !['calendar-end', 'calendar-start' ].includes(field.controlType)">
                          {{ field.value }}
                        </span>
                        <ng-container *ngIf="['richTextArea'].includes(field.controlType)">
                          <span *ngIf="entity.internalNotes || entity.productionNotes"
                            [class]="['table'].includes(page) ? 'long-text' : ''"
                            [innerHTML]="(field.key == 'productionNotes' ? entity.productionNotes : entity.internalNotes) | safeHtml"></span>
                        </ng-container>
                        <span *ngIf="field.key == 'artist_ref' && entity.artist_ref && entity.artist_ref.name">
                          {{ entity.artist_ref.name }}
                        </span>
                      </ng-template>
                    </ng-container>
                  </ng-container>
                </li>
              </ul>
            </span>
          </div>
          <ng-container *ngIf="card.name == 'Build Data' && isClaimed">
            <p-accordion [multiple]="true">
              <p-accordionTab header="Asset Build Data" [selected]="true">
                <build-data [parentEntity]="entity" [type]="type" [entity]="entity.scene_ref"
                  [buildData]="entity.scene_ref && entity.scene_ref.buildData ? entity.scene_ref.buildData : null"
                  [buildType]="buildType.Levels" (triggerRefreshEvent)="updateBuildData($event)"></build-data>
                  <app-misc-asset-build-error-logs [recordID]="entity.scene_ref.id" [field]="entity.scene_ref"></app-misc-asset-build-error-logs>
              </p-accordionTab>
              <p-accordionTab header="Image Build Data" [selected]="true">
                <build-data [parentEntity]="entity" [entity]="entity.image_ref"
                  [buildData]="entity.image_ref && entity.image_ref.buildData ? entity.image_ref.buildData : null"
                  [type]="type" [buildType]="buildType.Images"
                  (triggerRefreshEvent)="updateBuildData($event)"></build-data>
                  <app-misc-asset-build-error-logs [recordID]="entity.image_ref.id" [field]="entity.image_ref"></app-misc-asset-build-error-logs>
              </p-accordionTab>
            </p-accordion>
          </ng-container>
        </ng-template>
      </p-card>
    </div>
    <!-- Secondary Column (right) -->
    <div class="p-col-12 p-lg-4">
      <!-- Status Right Cards -->
      <app-environment-build-status [entity]="entity" [areEnvValuesOk]="areEnvValuesOk" [buildStatusFieldGroup]="buildStatusFieldGroup"[imageBuildStatusFieldGroup]="imageBuildStatusFieldGroup"></app-environment-build-status>
      <!-- Secondary Body Card (Images) -->
      <p-card class="p-m-2 p-shadow-4">
        <ng-template pTemplate="header">
          <span class="p-d-flex p-pt-3 p-px-3">
            <span class="p-card-title">
              {{ secondaryCard.name }}
            </span>
          </span>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="p-grid">
            <span *ngFor="let field of secondaryCard.fields" class="p-col-12 p-sm-6 p-lg-12 p-px-1"
              style="overflow-wrap: anywhere;">
              <div *ngIf="field.controlType == 'renderGallery' else elseBlock ">
                <app-render-gallery [entityId]="entity.id" [assetRecord]="entity.scene_ref"
                  [type]="type"></app-render-gallery>
              </div>
              <ng-template #elseBlock>
                <p class="p-mb-1">{{ field.name }}</p>
                <div style="
                    min-width: 225px;
                    max-width: 450px;
                    min-height: 300px;
                    margin-top: 7px;
                  " class="p-d-block p-mx-auto">
                  <img *ngIf="entity[field.key]" [lazyLoad]="entity[field.key]" class="p-mx-auto" width="100%"
                    (click)="displayImageModal(field)" />
                  <p-skeleton *ngIf="!entity[field.key]" width="100%" height="100%"></p-skeleton>
                </div>
              </ng-template>
            </span>
          </div>
        </ng-template>
      </p-card>
    </div>
  </div>
</div>
<div *ngIf="isLoading">
  <p class="p-m-5">Loading...</p>
</div>
<!-- 'View Image' dialog -->
<app-challenge-image-popup [(visible)]="showImgDialog" [imgData]="imgData"></app-challenge-image-popup>
<!-- 'Duplicate (Legacy)' dialog -->
<app-duplication-dialog [(visible)]="displayDuplicationModal" [flaggedDoNotUse]="flaggedDoNotUse" (confirmAction)="duplicationConfirmation(entity, this)"></app-duplication-dialog>
<!-- 'dup' dialog -->
<app-dup-dialog #dupDialog [entity]="'challenges'" [viewRoute]="'challenges'"></app-dup-dialog>
<!-- 'Rename & Claim Shell ( Set Your Initials )' dialog -->
<app-initials-dialog
  [displayInitialsDialog]="displayInitialsDialog"
  [existingUserInitials]="existingUserInitials"
  [currentUserData]="currentUserData"
  [entity]="entity"
  [editURL]="editURL"
  (onClose)="renameAndClaim()">
  
</app-initials-dialog>
<!-- 'Localized Values' dialog -->
<app-localized-table [visible]="displayLocalizedValues" [title]="'Localized Values'"[data]="localizedValues"></app-localized-table>
