import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { ValueGetterParams } from 'ag-grid-community';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { ColorTextComponent } from 'src/app/common/ag-grid-table/cell-editors/color-text/color-text.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';

@Component({
  selector: 'app-release-series-view-v2',
  templateUrl: './release-series-view-v2.component.html',
  styleUrls: ['./release-series-view-v2.component.sass'],
})
export class ReleaseSeriesViewV2Component extends BaseDynamicViewComponent implements OnInit {

  seriesRecord: BaseEntityDto = new BaseEntityDto();
  entity: any;
  options: any = {};
  prizeCounts: { [key: string]: number } = {};
  parentViewName: string = 'releaseSeries'

  constructor(
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.fields = [
      {
        title: 'Series Data',
        fields: [
          {
            name: 'Internal Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'displayName',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 6,
          },
          {
            name: 'Display Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Display Name',
            columnWidth: 6,
          },
          {
            name: "Type",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.DropdownWithLabels],
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: true,
            options: {
                fieldName: 'type',
                values: [
                    { name: 'Mini Series', value: 'Mini Series' },
                    { name: 'Flashback', value: 'Flashback' },
                ]
            },
            columnWidth: 12
          },              
          {
            name: 'Start Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            columnWidth: 6,
          },
          {
            name: 'End Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            columnWidth: 6,
          },
          {
            name: 'Series Colors',
            inputType: DynamicViewType.NestedInputComponent,
            clearField: true,
            key: 'colors',
            isRequired: true,
            label: 'Series Colors',
          },
          {
            name: 'Internal Notes',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.RichText],
            clearField: false,
            key: 'copy',
            label: 'Internal Notes',
            columnWidth: 12,
          },
          {
            name: "Is Infinite Challenges Enabled",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            clearField: true,
            key: 'isInfiniteChallengeIncluding',
            label: 'Is Infinite Challenges Enabled',
            columnWidth: 12,
            booleanoptions: {
              true: "Including",
              false: "Excluding"
            }
          },
        ],
        type: DynamicCardType.Card,
      },
      {
        title: 'Challenges',
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Challenges",
            inputType: DynamicViewType.AgGridTable,
            key: 'challenges_ref',
            label: 'Challenges',
            options: {
              identifier: `${this.parentViewName}-challenges`,
              fieldName: '',
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  sortable: true,
                  filter: 'agNumberColumnFilter',
                  resizable: true,
                  checkboxSelection: true,
                  headerCheckboxSelection: true,
                  width: 125,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  filter: 'agTextColumnFilter',
                  resizable: true,
                  cellEditor: 'agTextCellEditor',
                  cellRendererParams: {
                    entity: 'challenges',
                  }
                },
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'Feed Image',
                  field: 'image_ref',
                  sortable: false,
                  resizable: true,
                  width: 200,
                  autoHeight: true,
                  filterParams: {
                    filterKey: 'image_ref',
                    isAggregate: true,
                    fieldKeys: ['name', 'id', 'path' ],
                    isMiscBuild: true,
                    populate: true,
                    select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
                  },
                  cellRenderer: ThumbnailCellRendererComponent,
                  cellRendererParams: {
                    tableIdentifier: `${this.parentViewName}-challenges`,
                  },
                  valueGetter: (params: { data: { image_ref: { thumbnails: { path: any; }[]; }; }; }) => {
                    if (params.data.image_ref && params.data.image_ref.thumbnails && params.data.image_ref.thumbnails[1]) {
                      return params.data.image_ref.thumbnails[1].path;
                    } else {
                      return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                    }
                  },          
                },
                {
                  headerName: 'Room Render Image',
                  field: 'scene_ref',
                  sortable: false,
                  resizable: true,
                  width: 170,
                  autoHeight: true,
                  cellRenderer: ThumbnailCellRendererComponent,
                  cellRendererParams: {
                    tableIdentifier: `${this.parentViewName}-challenges`,
                  },
                  filterParams: {
                    filterKey: 'scene_ref',
                    isMiscBuild: true,
                    populate: true,
                    select: '_id id name bg_images thumbnails'
                  },
                  valueGetter: (params: any) => {
                    if (params.data.room_render_url_256) {
                      return params.data.room_render_url_256;
                    } else {
                      return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';

                    }
                  }
                },
                {
                  headerName: 'File Name',
                  field: 'fileName',
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,

                  autoHeight: true,
                  cellEditor: 'agTextCellEditor',
                  type: 'editableColumn',
                },
                {
                  headerName: 'Environment(s)',
                  field: 'env',
                  cellRendererParams: {
                    entity: 'challenges',
                    isEnv: true,
                  },
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['dev', 'qa', 'prod'],
                    valueFormatter: (params: any) => {
                      return params.value ? params.value.join(', ') : '';
                    }
                  },
                  floatingFilter: true,
                  cellEditor: MultiselectEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: true,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    valueObjects: true,
                    isFlatList: true,
                  },
                },
              ],
              defaultQueryKey: 'seriesChallenges_ref',
              entity: 'challenges',
              customHeight: '60vh',
              title: 'Series Challenges',
            },
            columnWidth: 12
          },
          {
            name: "Challenge Collections",
            inputType: DynamicViewType.AgGridTable,
            key: 'seriesChallengeCollecions',
            label: 'Challenge Collections',
            options: {
              fieldName: '',
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  sortable: true,
                  filter: 'agNumberColumnFilter',
                  resizable: true,
                  checkboxSelection: true,
                  headerCheckboxSelection: true,
                  width: 125,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  filter: 'agTextColumnFilter',
                  resizable: true,
                  cellEditor: 'agTextCellEditor',
                  cellRendererParams: {
                    entity: 'challenge-collections',
                  }
                },
                {
                  headerName: 'Enabled',
                  field: 'enabled',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    onLabel: 'Enabled',
                    offLabel: 'Not Enabled',
                    isBoolean: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: [true, false],
                    valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                  },
                  floatingFilter: true,

                  cellEditor: BooleanEditorComponent,
                  type: 'editableColumn',
                },
                {
                  headerName: 'Environment(s)',
                  field: 'env',
                  cellRendererParams: {
                    entity: 'challenges',
                    isEnv: true,
                  },
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['dev', 'qa', 'prod'],
                    valueFormatter: (params: any) => {
                      return params.value ? params.value.join(', ') : '';
                    }
                  },
                  floatingFilter: true,
                  cellEditor: MultiselectEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: true,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    valueObjects: true,
                    isFlatList: true,
                  },
                },
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'Challenge Count',
                  field: 'challenges_ref',
                  sortable: true,
                  unSortIcon: true,
                  suppressSizeToFit: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    entity: this.entity,
                    isPill: true
                  },
                  valueGetter: (params: any) => {
                    return params.data.challenges_ref ? params.data.challenges_ref.length : 0
                  },
                  resizable: true,

                  width: 125,
                },
              ],
              defaultQueryKey: 'seriesChallengeCollecions',
              entity: 'challenge-collections',
              customHeight: '60vh',
              title: 'Challenge Collections',
            },
            columnWidth: 12
          },
        ]
      },
      {
        title: 'Items',
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Item Collection",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'collection_ref',
            label: 'Item Collection',
            controller: 'collections',
            columnWidth: 4
          },
          {
            name: "Item Collections",
            inputType: DynamicViewType.AgGridTable,
            key: 'collection_ref',
            label: 'Item Collections',
            options: {
              fieldName: '',
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  sortable: true,
                  filter: 'agNumberColumnFilter',
                  resizable: true,
                  checkboxSelection: true,
                  headerCheckboxSelection: true,
                  width: 125,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  filter: 'agTextColumnFilter',
                  resizable: true,
                  cellEditor: 'agTextCellEditor',
                  cellRendererParams: {
                    entity: 'collections',
                  }
                },
                {
                  headerName: 'Enabled',
                  field: 'enabled',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    onLabel: 'Enabled',
                    offLabel: 'Not Enabled',
                    isBoolean: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: [true, false],
                    valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                  },
                  floatingFilter: true,

                  cellEditor: BooleanEditorComponent,
                  type: 'editableColumn',
                },
                {
                  headerName: 'Environment(s)',
                  field: 'env',
                  cellRendererParams: {
                    entity: 'challenges',
                    isEnv: true,
                  },
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['dev', 'qa', 'prod'],
                    valueFormatter: (params: any) => {
                      return params.value ? params.value.join(', ') : '';
                    }
                  },
                  floatingFilter: true,
                  cellEditor: MultiselectEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: true,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    valueObjects: true,
                    isFlatList: true,
                  },
                },
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'Item Count',
                  field: 'items_ref',
                  sortable: true,
                  unSortIcon: true,
                  suppressSizeToFit: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    entity: this.entity,
                    isPill: true
                  },
                  valueGetter: (params: any) => {
                    return params.data.items_ref ? params.data.items_ref.length : 0
                  },
                  resizable: true,

                  width: 125,
                },
              ],
              defaultQueryKey: 'collections_ref',
              entity: 'collections',
              customHeight: '60vh',
              title: 'Item Collections V2',
            },
            columnWidth: 12,
          },
          // PRIZES
          {
            name: "Prizes(s)", 
            inputType: DynamicViewType.AgGridTable,
            key: 'rewards_ref', 
            label: 'Prizes(s)',
            options: {
              title: 'Prizes(s)',
              fieldName: '',
              isPrize: true,
              identifier: `${this.parentViewName}-prizes`,
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  width: 125,
                  headerCheckboxSelection: true,
                  checkboxSelection: true,
                  sortable: true,
                  initialSort: 'desc',
                  sortIndex: 0,
                  initialSortIndex: 0,
                  sort: 'desc',
                  sortingOrder: ['asc', 'desc'],
                  unSortIcon: false,
                  filter: 'agNumberColumnFilter',
                  filterParams: {
                    filterOptions: [
                      'equals',
                      'notEqual',
                      'lessThan',
                      'lessThanOrEqual',
                      'greaterThan',
                      'greaterThanOrEqual',
                      'inRange'
                    ]
                  },
                  resizable: true,
                  floatingFilter: true,
                  suppressSizeToFit: true,
                },
                {
                  headerName: 'Type',
                  field: 'type_ref',
                  cellRenderer: RefsCellRendererComponent,
                  cellRendererParams: {
                    entity: 'item-types'
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: this.options.type_ref,
                    valueFormatter: (params: any) => {
                      return `${params.value.name} (${params.value.id})`;
                    },
                    keyCreator: (params: any) => {
                      return params.value._id;
                    },
                    comparator: this.agGridToolsService.nameASC
                  },
                  floatingFilter: true,

                  cellEditor: DropdownEditorComponent,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    objectValue: true
                  },
                  type: 'editableColumn',
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRendererParams: {
                    entity: this.entity,
                    isMultiple: true,
                    entities: ['items', 'currencies']
                  },
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,
                  cellEditor: 'agTextCellEditor',
                  type: 'editableColumn',
                },  
                {
                  headerName: 'Quantity',
                  field: 'prizeCounts',
                  sortable: true,
                  unSortIcon: true,
                  suppressSizeToFit: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    entity: this.entity,
                    isPill: true
                  },
                  valueGetter: (params: any) => {
                    const id = params.data?._id;
                    const prizeCounts = (params.context?.prizeCounts || {});
                    if (id && prizeCounts[id] !== undefined) {
                      return prizeCounts[id];
                    }
                    return 'Not Available';
                  },
                  resizable: true,
                  width: 150 
                },
                {
                  headerName: 'Enabled',
                  field: 'enabled',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    onLabel: 'Enabled',
                    offLabel: 'Not Enabled',
                    isBoolean: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: [true, false],
                    valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                  },
                  floatingFilter: true,
                  cellClass: 'center-cell-content',
                },
                {
                  headerName: 'Thumbnail Record',
                  field: 'thumbnail_ref',
                  cellRenderer: MiscAssetCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,
                  filterParams: {
                    filterKey: 'thumbnail_ref',
                    isAggregate: true,
                    fieldKeys: ['name', 'id', 'path'],
                    isMiscBuild: true,
                    populate: true,
                    select: '_id id name path thumbnails'
                  },
                  width: 500,
                  autoHeight: true,
                },
                {
                  headerName: 'Thumbnail',
                  field: 'thumbnail_img',
                  sortable: false,
                  resizable: true,
                  width: 135,
                  autoHeight: true,
                  cellRenderer: ThumbnailCellRendererComponent,
                  cellRendererParams: {
                    tableIdentifier: `${this.parentViewName}-prizes`,
                  },
                  valueGetter: (params: any) => {
                    let thumbnailPath = '';
                    if (params.data.thumbnail_ref && params.data.thumbnail_ref.thumbnails) {
                      thumbnailPath = params.data.thumbnail_ref.thumbnails.find((th: { size: number; }) => th.size === 256)?.path;
                    }
                    else if (params.data.thumbnails) {
                      thumbnailPath = params.data.thumbnails.find((th: { size: number; }) => th.size === 256)?.path;
                    }
                    else if (params.data.image_ref && params.data.image_ref.thumbnails) {
                      thumbnailPath = params.data.image_ref.thumbnails.find((th: { size: number; }) => th.size === 256)?.path;
                    }
                    else if (params.data.flyUpAnimation_ref && params.data.flyUpAnimation_ref.thumbnails) {
                      thumbnailPath = params.data.flyUpAnimation_ref.thumbnails.find((th: { size: number; }) => th.size === 256)?.path;
                    }
                    else if (params.data.thumbnail_ref && params.data.thumbnail_ref.thumbnails) {
                      thumbnailPath = params.data.thumbnail_ref.thumbnails.find((th: { size: number; }) => th.size === 128)?.path;
                    }
                    else if (params.data.imagePreview) {
                      thumbnailPath = params.data.imagePreview;
                    }
                    return thumbnailPath || 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                  },
                },                                            
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  floatingFilter: true,
                  suppressSizeToFit: true,
                  width: 300,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  valueGetter: this.agGridToolsService.dateValueGetter('start'),
                  resizable: true,
                  cellClass: 'center-cell-content',
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  floatingFilter: true,
                  suppressSizeToFit: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                    timePill: true
                  },
                  width: 300,
                  valueGetter: this.agGridToolsService.dateValueGetter('end'),
                  resizable: true,
                  cellClass: 'center-cell-content',
                },
                {
                  headerName: 'Content Status',
                  field: 'itemStatus',
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['Approved', 'Awaiting Revision', 'QA Ready'],
                    addEmptyFilters: true,
                  },
                  floatingFilter: true,

                  cellEditor: DropdownEditorComponent,
                  cellEditorParams: {
                    isFlatList: true
                  },
                  type: 'editableColumn',
                },
                {
                  headerName: 'Art Status',
                  field: 'vendorStatus',
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['Assigned', 'In Revision', 'Approved'],
                    addEmptyFilters: true,
                  },
                  floatingFilter: true,

                  cellEditor: DropdownEditorComponent,
                  cellEditorParams: {
                    isFlatList: true
                  },
                  type: 'editableColumn',
                },
                {
                  headerName: 'Promotion Status',
                  field: 'isAssetUpToDate',
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  valueGetter: (params: any) => {
                    const lastHash = params.data.prefab_ref?.lastHash;
                    const versions = params.data.prefab_ref?.asset_versions;
                    const lastVersion = versions ? versions[versions.length - 1] : null;

                    let response = 'No data';

                    if (!lastHash && versions && versions.length > 0) {
                      return 'Outdated';
                    }

                    if (lastHash && versions && versions.length > 0) {
                      response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
                    }
                    return response;
                  },
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: [
                      'No data', 'Outdated', 'Up to date'
                    ],
                    addEmptyFilters: true,
                    isAggregate: true,
                    filterKey: 'prefab_ref',
                    isAssetUpToDate: true,
                  },
                  floatingFilter: true,

                },
              ],
              isMultiple: true,
              entity: 'releases-v2',
              defaultQueryKey: 'rewards_ref',
              entities: ['items', 'currencies'],
              customHeight: '60vh',
              select: '_id id name start end enabled isAssetUpToDate vendorStatus itemStatus thumbnail_ref thumbnail_img',
            }
          },
        ]
      },
      {
        title: 'Inbox',
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Inbox Messages",
            inputType: DynamicViewType.AgGridTable,
            key: 'inboxMessages_ref',
            label: 'Inbox Messages',
            options: {
              fieldName: '',
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  sortable: true,
                  filter: 'agNumberColumnFilter',
                  resizable: true,
                  checkboxSelection: true,
                  headerCheckboxSelection: true,
                  width: 125,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  filter: 'agTextColumnFilter',
                  resizable: true,
                  cellEditor: 'agTextCellEditor',
                  cellRendererParams: {
                    entity: 'inbox-messages',
                  }
                },
                {
                  headerName: 'Enabled',
                  field: 'enabled',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    onLabel: 'Enabled',
                    offLabel: 'Not Enabled',
                    isBoolean: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: [true, false],
                    valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                  },
                  floatingFilter: true,

                  cellEditor: BooleanEditorComponent,
                  type: 'editableColumn',
                },
                {
                  headerName: 'Environment(s)',
                  field: 'env',
                  cellRendererParams: {
                    entity: 'challenges',
                    isEnv: true,
                  },
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['dev', 'qa', 'prod'],
                    valueFormatter: (params: any) => {
                      return params.value ? params.value.join(', ') : '';
                    }
                  },
                  floatingFilter: true,
                  cellEditor: MultiselectEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: true,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    valueObjects: true,
                    isFlatList: true,
                  },
                },
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'Misc. Image Record',
                  field: 'image_ref',
                  cellRenderer: MiscAssetCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,
                  filterParams: {
                    filterKey: 'image_ref',
                    isAggregate: true,
                    fieldKeys: ['name', 'id', 'path' ],
                    isMiscBuild: true,
                    populate: true,
                    select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
                  },
                  width: 500,
                  autoHeight: true,
                  cellEditor: DropdownEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: false,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    objectValue: true
                  },
                },
                {
                  headerName: 'Reward(s)',
                  field: 'rewards_ref',
                  cellRenderer: LineItemCellRendererComponent,
                  cellRendererParams: {
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agMultiColumnFilter',
                  filterParams: {
                    filters: [
                      {
                        filter: 'agSetColumnFilter',
                        display: 'accordion',
                        title: 'Currency',
                        filterParams: {
                          buttons: ['clear'],
                          title: 'Currency',
                          values: this.options.currencies_ref,
                          valueFormatter: (params: any) => {
                            return `${params.value.name}`;
                          },
                          keyCreator: (params: any) => {
                            return params.value._id;
                          }
                        },
                      },
                      {
                        filter: 'agNumberColumnFilter',
                        display: 'accordion',
                        title: 'Cost',
                        filterParams: {
                          buttons: ['clear'],
                        }
                      },
                    ],
                    populate: true,
                    nestedPop: true,
                    select: {
                      path: 'id t',
                      select: '_id id name'
                    }
                  },
                  floatingFilter: true,

                  width: 800,
                  autoHeight: true,
                  type: 'editableColumn',
                  cellEditor: LineItemEditorComponent,
                  cellEditorPopup: true,
                  cellEditorParams: {
                    resources: this.options.resources_ref,
                    currencies: this.options.currencies_ref,
                    titles: this.options.titles_ref,
                  },
                },
              ],
              defaultQueryKey: 'seriesInboxMessages_ref',
              entity: 'inbox-messages',
              customHeight: '60vh',
              title: 'Inbox Messages',
            },
            columnWidth: 12,
          },
        ]
      },
      {
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Store Listings",
            inputType: DynamicViewType.AgGridTable,
            key: 'storeListings_ref',
            label: 'Store Listings',
            options: {
              identifier: `${this.parentViewName}-storelistings`,
              fieldName: '',
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  sortable: true,
                  filter: 'agNumberColumnFilter',
                  resizable: true,
                  checkboxSelection: true,
                  headerCheckboxSelection: true,
                  width: 125,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  filter: 'agTextColumnFilter',
                  resizable: true,
                  cellEditor: 'agTextCellEditor',
                  cellRendererParams: {
                    entity: 'store-listings-v2',
                  }
                },
                {
                  headerName: 'Background HEX',
                  field: 'backgroundHex',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isColorPicker: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  autoHeight: true,
                  valueGetter: (params: any) => {
                    if (params.data && params.data.backgroundHex) {
                      const envs = ['prod', 'qa', 'dev', 'test'];
                      for (const env of envs) {
                        if (params.data.backgroundHex[env]) {
                          return params.data.backgroundHex[env];
                        }
                      }
                    }
                    return '';
                  },
                  cellEditor: ColorTextComponent,
                },
                {
                  headerName: 'Start',
                  field: 'storeStart',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  autoHeight: true,
                  valueGetter: (params: any) => {
                    if (params.data && params.data.storeStart) {
                      const envs = ['prod', 'qa', 'dev', 'test'];
                      for (const env of envs) {
                        if (params.data.storeStart[env]) {
                          return params.data.storeStart[env];
                        }
                      }
                    }
                    return '';
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'End',
                  field: 'storeEnd',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  autoHeight: true,
                  valueGetter: (params: any) => {
                    if (params.data && params.data.storeEnd) {
                      const envs = ['prod', 'qa', 'dev', 'test'];
                      for (const env of envs) {
                        if (params.data.storeEnd[env]) {
                          return params.data.storeEnd[env];
                        }
                      }
                    }
                    return '';
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'Thumbnail',
                  field: 'image_ref',
                  sortable: false,
                  resizable: true,
                  width: 135,
                  autoHeight: true,
                  cellRenderer: ThumbnailCellRendererComponent,
                  cellRendererParams: {
                    tableIdentifier: `${this.parentViewName}-storelistings`,
                  },
                  filterParams: {
                    filterKey: 'image_ref',
                    isAggregate: true,
                    fieldKeys: ['name', 'id', 'path'], 
                    isMiscBuild: true,
                    populate: true, 
                    select: '_id name id path thumbnails'
                  },
                  valueGetter: (params: any) => {
                    const envs = ['prod', 'qa', 'dev', 'test'];
                    for (const env of envs) {
                      if (params.data.image_ref && params.data.image_ref[env]) {
                        const thumbnails = params.data.image_ref[env].thumbnails;
                        
                        const thumbnail256 = thumbnails.find((thumbnail: { size: number; }) => thumbnail.size === 256);
                        if (thumbnail256) {
                          return thumbnail256.path;
                        }
                      }
                    }
                    return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                  }
                },                                             
              ],
              entity: 'store-listings-v2',
              defaultQueryKey: 'seriesStoreListings_ref',
              customHeight: '60vh',
              title: 'Store Listings',
            },
            columnWidth: 12,
          },
        ]
      },
    ];
    this.sidebarFields = [
      {
        title: "Images Preview",
        fields:
        [
          { name: "Loading Screen Images",
            inputType: DynamicViewType.deepMultiSideThumbnail,
            key: 'loadingScreenImages',
            label: 'Loading Screen Images',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "Inbox Messages Images",
            inputType: DynamicViewType.multiSideThumbnail,
            key: 'inboxImages',
            label: 'Inbox Messages Images',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
    ]
    this.seriesRecord = {
      enabled: false,
    };

    this.title = 'Releases Series';
    this.viewRoute = 'releases-series';
    this.isLoading = false;
  }
}
