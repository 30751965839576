<div
  class="p-p-1"
>
  <p-toggleButton
    #myToggleButton
    [(ngModel)]="value"
    [ariaLabel]="fieldName"
    [onLabel]="onLabel"
    [offLabel]="offLabel"
    [onIcon]="'pi pi-check'"
    [offIcon]="'pi pi-times'"
    [style]="{ height: '30px' }"
  ></p-toggleButton>
  <button
    pButton
    pRipple
    ariaLabel="Revert Nested Values"
    icon="pi pi-undo"
    class="p-mx-2 p-button-text p-button-xs"
    tabindex="-1"
    (click)="setOriginalValue()"
  ></button>
</div>


