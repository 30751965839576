import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import CoOpDTO from '../../dtos/CoOpDTO';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-co-op-view',
  templateUrl: './co-op-view.component.html',
  styleUrls: ['./co-op-view.component.sass']
})
export class CoOpViewComponent extends BaseDynamicViewComponent implements OnInit {
  coOpRecord: CoOpDTO = new CoOpDTO();

  constructor()
  {
    super();
   }

  ngOnInit() {
    if (!this.coOpRecord.team_colors) {
      this.coOpRecord.team_colors = [];
    }
    this.fields = [
      {
        title: "Co-Op Data",
        fields: [
          {
            name: "Name",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            columnWidth: 6
          },
          {
            name: "Internal Name",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'internalName',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 6
          },
          {
            name: "Start Date/Time",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            columnWidth: 6
          },
          {
            name: "End Date/Time",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            columnWidth: 6
          },
          {
            name: 'Internal Notes',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.RichText],
            clearField: false,
            key: 'copy',
            label: 'Internal Notes',
            columnWidth: 12,
          },
          {
            name: "Imagery Data",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Title],
            key: '',
            label: 'Imagery Data',
            columnWidth: 12,
            tagType: 'h2' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
          },
          {
            name: "Co-Op Logo",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'logo_ref',
            label: 'Co-Op Logo',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: { fieldName: 'logo_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.CoopLogo }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
            columnWidth: 6
          },
          {
            name: "Co-Op Logo Mask",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'white_image_ref',
            label: 'Co-Op Logo Mask',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: { fieldName: 'white_image_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.CoopLogo }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
            columnWidth: 6
          },
          {
            name: "Co-Op Banner Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'banner_image_ref',
            label: 'Co-Op Banner Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: { fieldName: 'banner_image_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.CoopBanner }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
            columnWidth: 6
          },
          {
            name: "Co-Op Popup Background",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'popup_background_ref',
            label: 'Co-Op Popup Background',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: { fieldName: 'popup_background_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.CoopPopupBackground }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
            columnWidth: 6
          },
          {
            name: "Slide Down Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'slide_down_image_ref',
            label: 'Slide Down Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: { fieldName: 'slide_down_image_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.CoopLogo }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
            columnWidth: 6
          },
          {
            name: "Popup Goal Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'popup_goalimage_ref',
            label: 'Popup Goal Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: { fieldName: 'popup_goalimage_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.WelcomeGoal }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
            columnWidth: 6
          },
          {
            name: "Team Colors",
            inputType: DynamicViewType.NestedInputComponent,
            clearField: true,
            key: 'team_colors',
            isRequired: true,
            label: 'Team Colors'
          },
          {
            name: "Challenge Feed Button BG colors",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Title],
            key: '',
            label: 'Challenge Feed Button BG colors',
            columnWidth: 12,
            tagType: 'h3' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
          },
          {
            name: "Color 1",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.ColorDisplay],
            clearField: false,
            key: 'feedBtnColor1',
            label: 'Color 1',
            columnWidth: 4
          },
          {
            name: "Color 2",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.ColorDisplay],
            clearField: false,
            key: 'feedBtnColor2',
            label: 'Color 2',
            columnWidth: 4
          },
          {
            name: "Logo Outline and Horizontal bar colors",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Title],
            key: '',
            label: 'Logo Outline and Horizontal bar colors',
            columnWidth: 12,
            tagType: 'h3' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
          },
          {
            name: "Logo Outline Color",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.ColorDisplay],
            clearField: false,
            key: 'logoOutlineColor',
            label: 'Logo Outline Color',
            columnWidth: 6
          },
          {
            name: "Horizontal Bar Color",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.ColorDisplay],
            clearField: false,
            key: 'horizontalBarColor',
            label: 'Horizontal Bar Color',
            columnWidth: 6
          },

        ],
        type: DynamicCardType.Card
      },
      {
        title: "Goal Data",
        fields: [
          {
            name: "Goal Name",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'goal_name',
            isRequired: true,
            label: 'Goal Name',
            columnWidth: 6
          },
          {
            name: "Goal Name Plural",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'goal_name_plural',
            isRequired: true,
            label: 'Goal Name Plural',
            columnWidth: 6
          },
          {
            name: "Goals Imagery",
            inputType: DynamicViewType.GoalImageComponentComponent,
            clearField: true,
            key: 'goal_images_ref',
            isRequired: true,
            label: 'Goals Imagery',
          },

        ],
        type: DynamicCardType.Card
      },
      {
        title: "Currency Data",
        fields: [
          {
            name: "Currency",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'currency_record_ref',
            label: 'Currency',
            controller: 'rewards-track',
          },
          // {
          //   name: "Currency Name",
          //   inputType: DynamicInputType.TextInput,
          //   clearField: true,
          //   key: 'currency_name',
          //   isRequired: true,
          //   label: 'Currency Name',
          //   columnWidth: 6
          // },
          // {
          //   name: "Currency Name Plural",
          //   inputType: DynamicInputType.TextInput,
          //   clearField: true,
          //   key: 'currency_name_plural',
          //   isRequired: true,
          //   label: 'Currency Name Plural',
          //   columnWidth: 6
          // },
          // {
          //   name: "Currency Icon",
          //   inputType: DynamicViewType.MiscImageRecordV2,
          //   viewMode: 'record',
          //   clearField: true,
          //   key: 'currency_ref',
          //   label: 'Currency Icon',
          //   optionValue:'_id',
          //   filter: true,
          //   filterBy:'name,id,path',
          //   autoDisplayFirst: false,
          //   options: { fieldName: 'currency_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.CoopCurrencyIcon }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
          // },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Co-Op Rewards",
        fields:
        [
          {
            name: "Rewards Track #1",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'track_ref',
            label: 'Rewards Track #1',
            controller: 'rewards-track',
            columnWidth: 6
          },
          {
            name: "Rewards Track #2",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'track_2_ref',
            label: 'Rewards Track #2',
            controller: 'rewards-track',
            columnWidth: 6
          },
          {
            name: "Rewards Track #3",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'track_3_ref',
            label: 'Rewards Track #2',
            controller: 'rewards-track',
            columnWidth: 6
          },
          {
            name: 'Grand Prize(s)',
            label: 'Grand Prize(s)',
            inputType: DynamicViewType.Price,
            key: 'rewards_ref',
          },
        ],
        type: DynamicCardType.Card
      },
    ],

    this.sidebarFields = [
     {
      title: "Imagery Build Status",
      fields:
      [
        {
          name: "Imagery Data",
          inputType: DynamicViewType.BaseViewField ,
          viewTypes: [BaseViewFieldType.Text],
          key: '',
          label: '',
          columnWidth: 12,
          tagType: 'h3' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
        },
        { name: "Co-Op Logo",
          inputType: DynamicViewType.sideThumbnail,
          key: 'logo_ref',
          label: 'Co-Op Logo',
          options: {gridWidth: 6, fieldName: '' },
          columnWidth: 12
        },
        { name: "Co-Op Logo Mask",
          inputType: DynamicViewType.sideThumbnail,
          key: 'white_image_ref',
          label: 'Co-Op Logo Mask',
          options: {gridWidth: 6, fieldName: '' },
          columnWidth: 12
        },
        { name: "Co-Op Banner Image",
          inputType: DynamicViewType.sideThumbnail,
          key: 'banner_image_ref',
          label: 'Co-Op Banner Image',
          options: {gridWidth: 6, fieldName: '' },
          columnWidth: 12
        },
        { name: "",
          inputType: DynamicViewType.SpaceCol,
          key: '',
          label: '',
          columnWidth: 6
        },
        {
          name: "Goal Images",
          inputType: DynamicViewType.BaseViewField ,
          viewTypes: [BaseViewFieldType.Text],
          key: '',
          label: '',
          columnWidth: 12,
          tagType: 'h3' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
        },
        { name: "",
          inputType: DynamicViewType.goalImageSideThumbnail,
          key: 'goal_images_ref',
          label: 'Goal Images',
          options: {gridWidth: 6, fieldName: '' },
          columnWidth: 6
        },
        { name: "Slide Down Image",
          inputType: DynamicViewType.sideThumbnail,
          key: 'slide_down_image_ref',
          label: 'Slide Down Image',
          options: {gridWidth: 6, fieldName: '' },
          columnWidth: 12
        },
        { name: "Popup Goal Image",
          inputType: DynamicViewType.sideThumbnail,
          key: 'popup_goalimage_ref',
          label: 'Popup Goal Image',
          options: {gridWidth: 6, fieldName: '' },
          columnWidth: 12
        },
      ],
      type: DynamicCardType.Card
     }
    ]

    this.coOpRecord =
    {
      enabled: false
    };
    this.title = "Co-Op";
    this.viewRoute = 'co-op';
    this.isLoading = false;
  }

}
