import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter-rules',
  templateUrl: './filter-rules.component.html',
})
export class FilterRulesComponent {
  @Input() record: any;
  @Input() field: any;
}

