<div  *ngIf="this.collection">
  <div class="p-ml-3 p-d-flex p-ai-center">
    <h2>{{this.collection.name}}</h2>
    <div
      class="p-col p-mx-3 p-text-center"
      [ngStyle]="{'max-width': '150px'}"
      [ngClass]="this.collection.enabled ? 'green-status' : 'gray-status'"
    >
      <h3 class="p-d-inline p-m-0 p-text-center">
        {{ this.collection.enabled ? 'Enabled' : 'Not Enabled' }}
      </h3>
    </div>
    <p-button label="Edit" styleClass="p-button-sm p-ml-4" icon="pi pi-pencil" type="button" (onClick)="editCollection()" >
    </p-button>
    <app-customactionbutton [record]="collection" [type]="'syncCollections'" [entity]="'challenges'"></app-customactionbutton>
    <div class="p-ml-auto">
      <p-button
        class="p-mx-2"
        label="See latest History Changes"
        [routerLink]="['/history/challenge-collections', collection.id]"
      ></p-button>
      <button
        pButton
        pRipple
        label="Localized Values"
        type="button"
        icon="fa fa-language"
        (click)="fetchLocalized()"
        class="p-ml-auto p-mr-2"
      ></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-8">
      <h4 class="p-my-0">Internal Notes:</h4>
      <div class="p-ml-2" [innerHTML]="getSafeHtml(this.collection.internal_notes)"></div>
    </div>
  </div>
  <p-divider></p-divider>
</div>
<app-ag-grid-table #agGridTable
  *ngIf="columnSelection"
  [entity]="entity"
  [columnDefs]="columnDefs"
  [autopopulateSelect]="autopopulateSelect"
  [columnSelection]="columnSelection"
  [bulkUpdateColDefs]="bulkUpdateColDefs"
  [tableParams]="tableParams"
  [buildParams]="buildParams"
  [parentViewName]="parentViewName"
  [isViewEmbedded]="true"
>
</app-ag-grid-table>

<p-dialog
[(visible)]="displayLocalizedValues"
[dismissableMask]="true"
[modal]="true"
[transitionOptions]="'200ms'"
[breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
[style]="{ width: 'auto', minWidth: '50vw' }"
[contentStyle]="{ 'overflow': 'visible' }"
>
<ng-template pTemplate="header">
  <h3 class="p-m-0">Localized Values</h3>
</ng-template>

<div *ngIf="this.localizedValues" class="p-text-center" style="overflow: auto;">
  <p-table [value]="localizedValues" [autoLayout]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th *ngFor="let column of getColumns(localizedValues)">
          {{ column }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td style="text-align: justify;">{{ row.id }}</td>
        <td style="text-align: justify;" *ngFor="let column of getColumns(localizedValues)">
          {{ row[column] || 'N/A' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
</p-dialog>
