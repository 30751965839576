import { Component, AfterViewInit, ElementRef, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';


@Component({
  selector: 'app-line-item-cell-editor',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.sass']
})
export class LineItemEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  public params: any;
  public value: any;
  public originalValue: any;
  public fieldName: string = '';
  public options: any = {};
  public optionLabel: string = 'label';
  public optionValue: string = 'value';
  public suggestions: any = [];

  lineItemForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private agGridToolsService: AgGridToolsService,
    private el: ElementRef, private renderer: Renderer2
  ){
    this.lineItemForm = this.fb.group({
      lineItems: this.fb.array([])
    });
  }

  ngOnInit(): void {}

  async agInit(params: any): Promise<void> {
    this.params = params;
    console.log('line-item params:' ,this.params);

    this.fieldName = this.params.colDef.headerName;
    this.value = this.params.value;
    this.originalValue = this.params.value;
    this.options['t'] = this.params.resources;
    this.options['currencies'] = this.params.currencies;
    this.options['titles'] = this.params.titles;

    // Pre-fill the form with existing line items
    if (this.value && this.value.length > 0 && Array.isArray(this.value)) {
      for (let item of this.value) {
          const lineItemGroup = this.fb.group({
              type: {
                  _id: item.t._id,
                  id: item.t.id,
                  name: item.t.name
              },
              id:  {
                _id: item.id._id,
                id: item.id.id,
                name: item.id.name
              },
              count: item.c
          });
          this.lineItems.push(lineItemGroup);
      }
    } else {
      // Add two items to the lineItems array based on the condition
      if (this.params.isChallengePrize) {
        this.addLineItem();
        this.addLineItem();
    } else {
        this.addLineItem();
    }
    }


    console.log('options: ', this.options);
  }

  get lineItems() {
    return (this.lineItemForm.get('lineItems') as FormArray);
  }

  addLineItem() {
    let defaultValues = {
        type: '',
        id: '',
        count: 0
    };

    // if (this.params && this.params.defaultType === 'currencies') {
    //     const currencyTypeOption = this.options['t'].find((opt: any) => opt._id === "617b62958e2f0e4a67f86c76");
    //     if (currencyTypeOption) {
    //         defaultValues.type = currencyTypeOption;
    //     }
    // }
    if (this.params && this.params.defaultType === 'currencies') {
      if (this.options['t'] && Array.isArray(this.options['t'])) {
          const currencyTypeOption = this.options['t'].find((opt: any) => opt._id === "617b62958e2f0e4a67f86c76");
          if (currencyTypeOption) {
              defaultValues.type = currencyTypeOption;
          }
      }
    }

    const lineItemGroup = this.fb.group(defaultValues);
    this.lineItems.push(lineItemGroup);
  }

  removeLineItem(index: number) {
    this.lineItems.removeAt(index);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const formGroupElement = this.el.nativeElement.querySelector('.line-item-fields');
      if (formGroupElement) {
        const firstInput = formGroupElement.querySelector('input, select');
        if (firstInput) {
          this.renderer.selectRootElement(firstInput).focus();
        }
      }
    }, 0);
  }

  getValue(): any {
    const formattedValues = [];

    // Iterate over lineItems FormArray
    for (let lineItem of this.lineItems.controls) {
      const lineItemValue = lineItem.value;

      // Construct the desired object structure
      const formattedValue = {
        lineItemType: lineItemValue.type?.name,
        t: lineItemValue.type,
        id: lineItemValue.id,
        c: lineItemValue.count
      };

      formattedValues.push(formattedValue);
    }

    // Wrap the result in a parent object with "costs_ref" key
    return formattedValues;
  }


  async getCurrencyOptions(event: any, index: Number){
    console.log(event, index);
    if(event.value.name){
      switch (event.value.name) {
        case 'Currency':
          break;
        default:
          break;
      }
    }
  }

  async getSuggestedItems(query: any,i: Number){
    this.suggestions = await this.agGridToolsService.getSuggestionsForRef(query, 'items');
  }
}
