<div class="p-grid" *ngIf="!isLoading">
  <div class="p-col-12 p-d-flex p-mb-3" *ngIf="nameInputEnabled">
    <span ngClass="p-float-label">
      <input
        [(ngModel)]="name"
        [ngModelOptions]="{ standalone: true }"
        pInputText
        class="field-item"
      />
      <label for="name">{{ label+' name' }}</label>
    </span>
    <p-button
      *ngIf="path && path.length>0"
      ariaLabel="Clear Field"
      icon="pi pi-minus"
      styleClass="p-button-text p-button-danger"
      (click)="name = null"
    ></p-button>
  </div>
  <div class="p-col-12 p-d-flex p-mb-3" *ngIf="assetTypeInputEnabled">
    <span ngClass="p-float-label">
      <p-dropdown
        [style]="{'width':'100%'}"
        [(ngModel)]="assetType"
        [options]="imageryOptions"
        optionLabel="label"
        optionValue="value"
        [autoDisplayFirst]="false"
        [required]="true"
        [filter]="true"
        (onChange)="changePathPrefix($event)"
      ></p-dropdown>
      <label for="dropdown">{{label+' Bundle Type'}}<strong style="color: crimson">*</strong></label>
    </span>
  </div>
  <div class="p-col-12 p-d-flex">
    <span ngClass="p-float-label">
      <!-- <plastic-files-autocomplete
      [(path)]="path"
      [(label)]="label"
      (onChange)="pathChange($event)"
      style="flex-grow: 1;"
      ></plastic-files-autocomplete> -->
      <input
        [(ngModel)]="path"
        [ngModelOptions]="{ standalone: true }"
        pInputText
        (ngModelChange)="pathChange($event)"
        class="field-item"
      />
      <label for="path"> {{ label+' path' }}</label>
  </span>
  <p-button
      *ngIf="path && path.length>0"
      ariaLabel="Clear Field"
      icon="pi pi-minus"
      styleClass="p-button-text p-button-danger"
      (click)="path = null"
    ></p-button>
  </div>
</div>
