<div class="reward-card p-shadow-4">
    <h4 class="day-title">{{ title }}</h4>
    <div *ngFor="let reward of rewards" class="internal-card">
      <div class="reward-layout">
        <img *ngIf="getRewardImage(reward)"
            [src]="getRewardImage(reward)"
            alt="Reward Thumbnail"
            class="item-thumbnail"
            [style]="{ width: '80px', height: '80px' }">
        <div class="reward-info">
          <p class="reward-detail">
            <span class="p-text-bold">{{ reward.lineItemType }}:</span> {{ reward.id?.name }}
          </p>
          <a [href]="getLink(reward)" target="_blank" class="item-id" [ngStyle]="{ color: 'var(--primary-color)' }">
            {{ reward.id?.id }}
          </a>
          <div class="item-quantity pill">
            <span class="p-text-bold">Quantity:</span> {{ reward.c }}
          </div>
        </div>
      </div>
    </div>
  </div>  