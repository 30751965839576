import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, IServerSideDatasource, GridOptions, ColumnApi } from 'ag-grid-community';
import { LicenseManager } from 'ag-grid-enterprise';
import { ThemeService } from '../../../services/theme.service';
import { TableService } from '../../../services/table.service';
import { TableCommunicationService } from '../services/table-communication.service';
import { Subscription } from 'rxjs';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AgGridUserSettings } from '../services/ag-grid-user-settings.service';
import { TextCellRendererComponent } from '../cell-renderers/render-components/text/text.component';
import { MultiselectEditorComponent } from '../cell-editors/multiselect/multiselect.component';
import { BooleanEditorComponent } from '../cell-editors/boolean/boolean.component';
import { LinkCellRendererComponent } from '../cell-renderers/render-components/link/link.component';
import { AgGridToolsService } from '../services/ag-grid-tools.service';
import { CalendarEditorComponent } from '../cell-editors/calendar/calendar.component';
import { ScheduleDetailTemplateComponent } from './schedule-detail-template/schedule-detail-template.component';
import { ScheduleRefNameCellRendererComponent } from '../cell-renderers/render-components/schedule-name/schedule-name.component';
import { HttpClient } from '@angular/common/http';
import { ScheduleTypeRenderComponent } from '../cell-renderers/render-components/schedule-type/schedule-type.component';
import { DataService } from '../../../services/data.service';

import { GlobalSearchService } from '../../../common/services/global-search.service';
type FormMode = 'create' | 'edit' | 'view';

interface EntityConfig {
  label: string;
  value: string;
  api: string;
  select: string;
  collectionName: string;
  url: string;
}

type Environment = 'dev' | 'qa' | 'prod' | 'test';

const STORAGE_KEY = 'schedule-view-preference';

// Add these interfaces at the top of the file
interface DefaultPerkSettings {
  _id?: string;
  id?: string;
  start: string;
  end: string;
  enabled: boolean;
}

interface CMSSettings {
  defaultPerk?: DefaultPerkSettings;
  // ... other settings
}

@Component({
  selector: 'app-ag-grid-schedule-table',
  templateUrl: './ag-grid-schedule-table.component.html',
  styleUrls: ['./ag-grid-schedule-table.component.css']
})
export class AgGridScheduleTableComponent implements OnInit, OnDestroy {
  // AGGRID License manager:
  licence: any = LicenseManager.setLicenseKey(environment.agGridLicense);

  private scheduleData: any[] = [];

  private gridApi!: GridApi;
  private themeSubscription!: Subscription;
  private routeSubscription!: Subscription;
  gridTheme = 'ag-theme-alpine';
  isGridView = true;
  loadingGrid: boolean = false;
  currentPage: number = 0;
  loadedRowCount: number = 0;
  totalRowCount: number = 0;
  tableTotalRecords: number = 0;
  currentSortModel: any = {};
  currentFilterModelVars: any = {};
  private columnApi!: ColumnApi;
  private saveColumnStateTimeout: any;
  private columnState: any;
  entity: string = 'content-schedule';
  displayAddDialog: boolean = false;
  selectedDates: { start: Date | null, end: Date | null } = { start: null, end: null };
  activeFiltersCount = 0;
  activeSortsCount = 0;
  isEditMode: boolean = false;
  selectedSchedule: any = null;
  private tableCommunicationSubscription!: Subscription;
  availableEntities: EntityConfig[] = [];
  mode: FormMode = 'create';
  public isSidebarOpen = false;

  // Add private property for schedule types
  private scheduleTypes: any[] = [];
  private eventColors = {
    enabled: {
      dev: '#4CAF50',  // Green for enabled dev
      qa: '#2196F3',   // Blue for enabled qa
      prod: '#9C27B0', // Purple for enabled prod
      test: '#FF9800'  // Orange for enabled test
    },
    disabled: {
      dev: '#81C784',  // Light green for disabled dev
      qa: '#64B5F6',   // Light blue for disabled qa
      prod: '#BA68C8', // Light purple for disabled prod
      test: '#FFB74D'  // Light orange for disabled test
    }
  };

  // Modify the background events property
  private backgroundEvents: EventInput[] = [];  // Initialize as empty array

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,dayGridWeek,dayGridDay'
    },
    height: '100%',
    events: [], // Start with empty events
    selectable: true,
    editable: true,
    eventResizableFromStart: true,
    eventDurationEditable: true,
    handleWindowResize: true,
    dayMaxEvents: true,
    expandRows: true,
    views: {
      dayGrid: {
        dayMaxEvents: true,
        fixedWeekCount: false,
      },
      timeGrid: {
        dayMaxEvents: true,
        slotMinTime: '00:00:00',
        slotMaxTime: '24:00:00'
      }
    },
    slotMinTime: '00:00:00',
    slotMaxTime: '24:00:00',
    contentHeight: 'auto',
    stickyHeaderDates: true,
    windowResizeDelay: 200,
    select: (selectInfo) => {
      this.selectedDates = {
        start: selectInfo.start,
        end: selectInfo.end
      };
      this.showAddDialog();
    },
    eventDidMount: (info) => {
      const event = info.event;
      const environments = event.extendedProps?.environments || [];
      const status = event.extendedProps?.enabled ? 'Enabled' : 'Disabled';
      
      let tooltipContent = `${event.title}\n\nStatus: ${status}\nEnvironments: ${environments.join(', ')}`;
      
      if (event.extendedProps?.scheduleRefs) {
        const refs = event.extendedProps.scheduleRefs
          .map((ref: any) => `${ref.referenceEntity}: ${ref.referenceId?.name || ref.referenceId}`)
          .join('\n');
        tooltipContent += `\n\nReferences:\n${refs}`;
      }
      
      info.el.title = tooltipContent;
    },
    eventClick: (info) => {
      // Find the original schedule data using the event ID
      const scheduleData = this.findScheduleById(info.event.id);
      if (scheduleData) {
        this.selectedSchedule = scheduleData;
        this.mode = 'view';
        this.displayAddDialog = true;
      }
    }
  };

  columnDefs!: ColDef[];
  defaultColDef!: ColDef;
  gridOptions!: GridOptions;
  detailCellRendererParams: any;

  // Add property to store default perk settings
  private defaultPerkSettings: any = null;

  constructor(
    private themeService: ThemeService,
    private tableService: TableService,
    private route: ActivatedRoute,
    private router: Router,
    private agGridUserSettings: AgGridUserSettings,
    private agGridToolsService: AgGridToolsService,
    private tableCommunicationService: TableCommunicationService,
    private http: HttpClient,
    private dataService: DataService,
    private globalSearchService: GlobalSearchService
  ) {
    this.loadDefaultPerkSettings();
    this.loadAvailableEntities();
    this.initializeTableDefs();
   
  }

  private loadAvailableEntities() {
    this.http.get<EntityConfig[]>('/api/content-schedule/available-entities')
      .subscribe(entities => {
        this.availableEntities = entities;
        // Refresh detail grid if it exists
        if (this.gridApi) {
          this.gridApi.refreshCells({ force: true });
        }
      });
  }

  initializeTableDefs() {
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        width: 100,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        cellRenderer: 'agGroupCellRenderer',
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Schedule Type',
        field: 'scheduleType',
        width: 150,
        cellRenderer: ScheduleTypeRenderComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          autopopulate: true,
          select: '_id id description color enabled',
          virtuals: true
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: ScheduleRefNameCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Start',
        field: 'start',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        width: 300,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('start'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn'
      },
      {
        headerName: 'End',
        field: 'end',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        width: 300,
        valueGetter: this.agGridToolsService.dateValueGetter('end'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRendererParams: {
          entity: 'content-schedule',
          isEnv: true
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['dev', 'qa', 'prod', 'test'],
        },
        floatingFilter: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          valueObjects: true,
          isFlatList: true
        },
      }
    ];

    this.defaultColDef = {
      flex: 1,
      resizable: true,
      suppressSizeToFit: true,
      cellClass: 'center-cell-content'
    };

    this.gridOptions = {
      rowModelType: 'serverSide',
      pagination: true,
      paginationPageSize: 50,
      cacheBlockSize: 50,
      maxBlocksInCache: 2,
      columnDefs: this.columnDefs,
      defaultColDef: this.defaultColDef,
      onColumnVisible: this.onColumnVisible.bind(this),
      onColumnMoved: this.onColumnMoved.bind(this),
      onColumnResized: this.onColumnResized.bind(this),
      onFilterChanged: this.onFilterChanged.bind(this),
      onSortChanged: this.onSortChanged.bind(this),
      masterDetail: true,
      detailRowHeight: 400,
      // detailCellRenderer: ScheduleDetailTemplateComponent,
      isRowMaster: (dataItem: any) => {
        return dataItem && dataItem.scheduleRefs && dataItem.scheduleRefs.length > 0;
      }
    };

    this.detailCellRendererParams = {
      detailGridOptions: {
        suppressHorizontalScroll: false,
        rowHeight: 35,
        groupDisplayType: 'groupRows',
        columnDefs: [
          { 
            headerName: 'Reference Entity',
            field: 'referenceEntity',
            rowGroup: true,
            hide: true
          },
          { 
            field: 'id',
            headerName: 'ID',
            cellRenderer: LinkCellRendererComponent,
            cellRendererParams: (params: any) => {
              const entityConfig = this.availableEntities.find(e => e.value === params.data.referenceEntity);
              return {
                entity: entityConfig?.url || '',
                isLink: true
              };
            },
            width: 70,
            suppressSizeToFit: true
          },
          { 
            field: 'name',
            headerName: 'Name',
            flex: 2
          },
          { 
            field: 'env',
            headerName: 'Environment(s)',
            cellRenderer: TextCellRendererComponent,
            cellRendererParams: {
              isEnv: true
            },
            flex: 1
          }
        ],
        defaultColDef: {
          flex: 1,
          sortable: true,
          resizable: true,
          cellClass: 'center-cell-content',
          suppressSizeToFit: true,
        }
      },
      getDetailRowData: (params: any) => {
        if (!params.data.scheduleRefs) {
          params.successCallback([]);
          return;
        }

        // Transform scheduleRefs data into flat array of reference items
        const detailData = params.data.scheduleRefs.reduce((acc: any[], ref: any) => {
          if (!ref.referenceId) return acc;
          
          // Handle both array and single object cases
          const refItems = Array.isArray(ref.referenceId) ? ref.referenceId : [ref.referenceId];
          const items = refItems.map((item: any) => ({
            ...item,
            referenceEntity: ref.referenceEntity
          }));
          return [...acc, ...items];
        }, []);

        params.successCallback(detailData);
      }
    };
  }

  ngOnInit(): void {
    // Add schedule types loading
    this.loadScheduleTypes();
    
    // Subscribe to theme changes
    this.themeSubscription = this.themeService.theme$.subscribe((theme) => {
      this.toggleTheme(theme === 'arya-green');
    });

    // Subscribe to query params
    this.routeSubscription = this.route.queryParams.subscribe(params => {
      if (params['view']) {
        this.isGridView = params['view'] === 'grid';
      } else {
        // If no query param, try to get from storage
        const storedPreference = localStorage.getItem(STORAGE_KEY);
        if (storedPreference) {
          this.isGridView = storedPreference === 'grid';
          // Update URL to match stored preference
          this.updateQueryParams();
        }
      }
    });

    // Fetch initial data regardless of view mode
    this.fetchInitialData();

    // Subscribe to table communication events
    this.tableCommunicationSubscription = this.tableCommunicationService.event$.subscribe(event => {
      if (event?.type === 'editSchedule') {
        this.selectedSchedule = event.data;
        this.mode = 'view';
        this.displayAddDialog = true;
      }
    });
  }

  async fetchInitialData() {
    try {
      const response = await this.tableService.getTableRowsV3({
        type: 'content-schedule',
        page: 0,
        pageSize: 50,
        autopopulate: true,
        virtuals: true
      });

      if (response.records) {
        this.updateCalendarEvents(response.records);
      }
    } catch (error) {
      console.error('Error fetching initial schedule data:', error);
    }
  }

  ngOnDestroy(): void {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
    if (this.tableCommunicationSubscription) {
      this.tableCommunicationSubscription.unsubscribe();
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.applyState();
    this.gridApi.setServerSideDatasource(this.createServerSideDatasource());
    
    // Initialize counters
    this.onFilterChanged();
    this.activeSortsCount = this.columnApi.getColumnState().filter(col => col.sort).length;
  }

  createServerSideDatasource(): IServerSideDatasource {
    return {
      getRows: async (params) => {
        try {
          const { pageSize, currentPage, sortModel, filterModelVars, populate, tableGlobalSearchQuery } = await this.setMeta(params);

          let response = await this.tableService.getTableRowsV3({
            type: 'content-schedule',
            populate: populate,
            page: currentPage,
            pageSize: pageSize,
            sortModel: sortModel || null,
            filterModel: filterModelVars || null,
            defaultQuery: null,
            tableGlobalSearchQuery: tableGlobalSearchQuery || null,
            autopopulate: true,
            virtuals: true
          });

          this.loadingGrid = false;

          if (response.records) {
            this.updateRowCounts(response);
            this.gridApi.retryServerSideLoads();

            // Update calendar events with the new data
            this.updateCalendarEvents(response.records);

            params.success({
              rowData: response.records,
              rowCount: response.paginationMeta.totalRecords
            });
          } else {
            params.fail();
          }
        } catch (error) {
          console.error('Error fetching schedule data:', error);
          params.fail();
        }
      }
    };
  }

  async setMeta(params: any) {
    const startRow = params.request.startRow ?? 0;
    const pageSize = 50; // Default page size
    const currentPage = Math.floor(startRow / pageSize);

    const sortModel = params.request.sortModel;
    const filterModel = params.request.filterModel;

    const columnKeys = Object.keys(filterModel);
    const filterParamsDict = columnKeys.reduce((acc: any, key: any) => {
      const colDef = params.columnApi.getColumn(key)?.getColDef();
      acc[key] = { ...filterModel[key], ...colDef?.filterParams }
      return acc;
    }, {});

    const populate = params.columnApi.getColumns()
      .filter((key: any) => key.colDef.filterParams?.populate)
      .map((key: any) => ({
        path: key.colId,
        [key.colDef.filterParams.nestedPop ? 'populate' : 'select']: key.colDef.filterParams.select ? key.colDef.filterParams.select : '_id id name',
      }));

    const filterModelVars = {
      filterModel: filterModel,
      filterParamsDict: filterParamsDict,
      columnKeys: columnKeys,
    }

    return {
      pageSize,
      currentPage,
      sortModel,
      filterModelVars,
      populate,
      tableGlobalSearchQuery: null
    }
  }

  updateRowCounts(response: any) {
    this.loadedRowCount += response.records.length;
    this.totalRowCount = response.paginationMeta.totalRecords;
    this.tableTotalRecords = response.paginationMeta.tableTotalRecords;
  }

  toggleTheme(isDarkMode: boolean) {
    this.gridTheme = isDarkMode ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  }

  toggleView() {
    this.isGridView = !this.isGridView;
    localStorage.setItem(STORAGE_KEY, this.isGridView ? 'grid' : 'calendar');
    this.updateQueryParams();
  }

  private updateQueryParams() {
    this.router.navigate(
      [], 
      {
        relativeTo: this.route,
        queryParams: { view: this.isGridView ? 'grid' : 'calendar' },
        queryParamsHandling: 'merge'
      }
    );
  }

  private initializeCalendarEvents() {
    const events: EventInput[] = [...this.backgroundEvents];
    this.calendarOptions = {
      ...this.calendarOptions,
      events,
    };
  }

  private findScheduleById(id: string): any {
    // This assumes you have access to the original schedule data
    // You might need to store it in a class property when updating calendar events
    return this.scheduleData?.find((schedule: any) => schedule.id.toString() === id);
  }

  updateCalendarEvents(scheduleData: any[]) {
    this.scheduleData = scheduleData;
    
    const regularEvents: EventInput[] = scheduleData.map(schedule => {
      // Get the schedule type ID (either from _id or the string itself)
      const scheduleTypeId = schedule.scheduleType?._id || schedule.scheduleType;
      const primaryEnv = (Array.isArray(schedule.env) ? schedule.env[0] : schedule.env) as Environment;

      const backgroundColor = schedule.enabled 
      ? this.eventColors.enabled[primaryEnv] 
      : this.eventColors.disabled[primaryEnv];
      
      // Find the full schedule type object
      let scheduleTypeObj = null;
      if (typeof schedule.scheduleType === 'object' && schedule.scheduleType?.color) {
        scheduleTypeObj = schedule.scheduleType;
      } else if (schedule.scheduleType) {
        // Try to find the schedule type in our loaded schedule types
        scheduleTypeObj = this.matchScheduleType(scheduleTypeId);
      }
      
      
      return {
        id: schedule.id.toString(),
        title: schedule.description,
        start: schedule.start,
        end: schedule.end,
        allDay: true,
        backgroundColor: scheduleTypeObj?.color || '#FFFFFF',
        borderColor: scheduleTypeObj?.color || '#FFFFFF',
        textColor: this.getContrastColor(scheduleTypeObj?.color),
        className: [
          schedule.enabled ? 'enabled' : 'disabled',
          ...schedule.env,
          'cursor-pointer'
        ],
        extendedProps: {
          scheduleRefs: schedule.scheduleRefs,
          scheduleType: scheduleTypeObj || schedule.scheduleType,
          environments: schedule.env,
          enabled: schedule.enabled
        }
      };
    });
    
    // Get current week's start and end
    const { start: weekStart, end: weekEnd } = this.getWeekStartAndEnd();

    // Check if there are any perk events in the current week
    const hasPerksThisWeek = this.hasPerksInWeek(regularEvents, weekStart, weekEnd);

    let allEvents = [...regularEvents];

    // If no perks this week and we have default perk settings, add default perk for this week
    if (!hasPerksThisWeek && this.defaultPerkSettings) {
      const defaultPerkEvent = this.createDefaultPerkEvent(weekStart, weekEnd, this.defaultPerkSettings);
      allEvents = [defaultPerkEvent, ...regularEvents];
    }

    // Update the calendar events
    if (this.calendarOptions) {
      this.calendarOptions.events = allEvents;
    }
  }

  private getContrastColor(color: string | undefined): string {
    if (!color) return '#000000';

    // Remove the # if present
    const hex = color.replace('#', '');
    
    // Validate hex color format
    if (!/^[0-9A-Fa-f]{6}$/.test(hex)) return '#000000';
    
    try {
      // Convert to RGB
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      
      // Calculate luminance using the WCAG formula
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      
      // Return black or white based on luminance
      return luminance > 0.5 ? '#000000' : '#FFFFFF';
    } catch (error) {
      console.error('Error calculating contrast color:', error);
      return '#000000';
    }
  }

  getGridHeight() {
    const height = window.innerHeight;
    if (height >= 1000) {
      return '85vh';
    } else if (height >= 900) {
      return '82vh';
    } else if (height >= 800) {
      return '78vh';
    } else if (height >= 700) {
      return '75vh';
    } else {
      return '70vh';
    }
  }

  clearFilters() {
    if (this.gridApi) {
      this.gridApi.setFilterModel(null);
    }
    this.activeFiltersCount = 0;
  }

  clearSort() {
    if (this.columnApi) {
      this.columnApi.applyColumnState({
        defaultState: { sort: null }
      });
    }
    this.activeSortsCount = 0;
  }

  applyState() {
    this.agGridUserSettings.getColumnState(this.entity, false, null).then((response: any) => {
      if (response[0] && response[0].settings) {
        this.columnState = response[0].settings;
        this.columnApi.applyColumnState({
          state: response[0].settings,
          applyOrder: true,
        });
      }
    }).catch((error) => {
      console.error('Error fetching column state: ', error);
    });
  }

  onColumnVisible(event: any) {
    if (event.source !== "api") {
      this.debounceSaveColumnState();
    }
  }

  onColumnMoved(event: any) {
    if (event.source !== "api") {
      this.debounceSaveColumnState();
    }
  }

  onColumnResized(event: any) {
    if (event.source !== "api") {
      this.debounceSaveColumnState();
    }
  }

  onFilterChanged() {
    if (this.gridApi) {
      const filterModel = this.gridApi.getFilterModel();
      this.activeFiltersCount = Object.keys(filterModel || {}).length;
    }
  }

  onSortChanged(event: any) {
    if (event.source !== "api") {
      const newColumnState = this.columnApi.getColumnState();
      this.columnState = newColumnState;
      this.debounceSaveColumnState();
      this.activeSortsCount = newColumnState.filter(col => col.sort).length;
    }
  }

  debounceSaveColumnState() {
    clearTimeout(this.saveColumnStateTimeout);
    this.columnState = this.columnApi.getColumnState();
    this.saveColumnStateTimeout = setTimeout(() => {
      this.agGridUserSettings.saveColumnState(this.entity, this.columnState, false, null);
    }, 800);
  }

  showAddDialog() {
    this.mode = 'create';
    this.selectedSchedule = null;
    this.displayAddDialog = true;
  }

  onSubmitSuccess() {
    this.displayAddDialog = false;
    this.mode = 'create';
    this.selectedSchedule = null;
    
    if (this.gridApi) {
      this.gridApi.refreshServerSide({ purge: true });
    }
    
    this.fetchInitialData();
  }

  toggleSidebar() {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  // Add new method to load schedule types
  private async loadScheduleTypes() {
    try {
      const response = await this.dataService.findManyDynamic('schedule-type', {
        autopopulate: true,
        virtuals: true
      });
      this.scheduleTypes = response.records;
    } catch (error) {
      console.error('Error loading schedule types:', error);
    }
  }

  onGenerateEventFromCart(cartItems: any[]) {
    // Create start date as today
    const startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    
    // Create end date as 3 days from now
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 3);
    endDate.setHours(23, 59, 59, 999);

    // Create a new schedule object with cart items
    const scheduleData = {
      description: `Event for ${cartItems.length} items`,
      start: startDate,
      end: endDate,
      env: ['dev'],
      scheduleRefs: cartItems.map(item => {
        // Find the matching entity configuration
        const entityConfig = this.availableEntities.find(
          entity => entity.label.toLowerCase() === item.entity.toLowerCase()
        );

        return {
          referenceEntity: entityConfig?.value || item.entity,
          referenceId: [{
            _id: item._id,
            id: item.id,
            name: item.name
          }]
        };
      })
    };

    this.selectedSchedule = scheduleData;
    this.mode = 'create';
    this.displayAddDialog = true;
  }

  // Add method to match schedule type
  private matchScheduleType(scheduleTypeId: any) {
    if (!scheduleTypeId || !this.scheduleTypes?.length) return null;

    // Find matching schedule type
    return this.scheduleTypes.find(type => 
      type._id === scheduleTypeId || 
      type.id === scheduleTypeId
    );
  }

  // Add these helper methods to the class
  private getWeekStartAndEnd(date: Date = new Date()): { start: Date, end: Date } {
    const start = new Date(date);
    start.setDate(date.getDate() - date.getDay()); // Start of week (Sunday)
    start.setHours(0, 0, 0, 0);

    const end = new Date(start);
    end.setDate(start.getDate() + 6); // End of week (Saturday)
    end.setHours(23, 59, 59, 999);

    return { start, end };
  }

  private hasPerksInWeek(events: EventInput[], weekStart: Date, weekEnd: Date): boolean {
    return events.some(event => {
      const eventStart = new Date(event.start as string);
      const eventEnd = new Date(event.end as string);
      
      // Check if any scheduleRef's referenceEntity contains "Perk"
      const hasPerkReference = event.extendedProps?.scheduleRefs?.some(
        (ref: any) => ref.referenceEntity?.toLowerCase().includes('perk')
      );
      
      return hasPerkReference && 
             eventStart <= weekEnd && 
             eventEnd >= weekStart;
    });
  }

  private createDefaultPerkEvent(weekStart: Date, weekEnd: Date, defaultPerk: any): EventInput {
    return {
      id: `default-perk-${weekStart.getTime()}`,
      title: `Default Perk: ${defaultPerk.name} ${defaultPerk.id}`,
      start: weekStart,
      end: weekEnd,
      allDay: true,
      backgroundColor: '#4CAF50',
      borderColor: '#4CAF50',
      classNames: ['default-perk-event'],
      textColor: '#FFFFFF',
      extendedProps: {
        isDefaultPerk: true,
        enabled: defaultPerk.enabled || true,
        scheduleType: {
          name: 'Default Perk'
        }
      }
    };
  }

  // Modify the loadDefaultPerkSettings method
  private async loadDefaultPerkSettings() {
    try {
      const response = await this.dataService.getAllOfTypeAsync('cms-settings', {
        query: {
          id: 1000
        },
        select: 'defaultPerk',
        autopopulate: true
      });

      if (response[0]?.defaultPerk) {
        this.defaultPerkSettings = response[0].defaultPerk;
        
        // If we already have schedule data, refresh the calendar
        if (this.scheduleData.length > 0) {
          this.updateCalendarEvents(this.scheduleData);
        }
      }
    } catch (error) {
      console.error('Error loading default perk settings:', error);
    }
  }
}
