<button pButton type="button" (click)="emptyCart()">
    Empty Cart
</button>
<p-card styleClass="p-card-shadow" class="p-mb-1">
    <h3>Environmental Selection</h3>
    <div style="width: 100%;" class="p-grid">
        <div class="p-col-6">
            <span class="p-float-label">
                <p-dropdown [options]="sourceOptions" autoDisplayFirst="false" (onChange)="saveEnv($event)" [(ngModel)]="sourceEnv" [style]="{ 'min-width': '30em' }"></p-dropdown>
                <label for="dropdown">Starting Environment</label>
            </span> 
        </div>
        <div class="p-col-6">
            <span class="p-float-label">
                <p-dropdown [options]="destinationOptions" autoDisplayFirst="false" (onChange)="saveEnv($event)" [(ngModel)]="destinationEnv"  [style]="{ 'min-width': '30em' }"></p-dropdown>
                <label for="dropdown">Destination Environment</label>
            </span> 
        </div>
    </div>
</p-card>
<p-card styleClass="p-card-shadow">
    <h3 style="display: inline-block;">Store Listings 
        <button type="button" style="border: none; background: none; outline: none; cursor: pointer; color: var(--primary-color);" (click)="addModal('store-listings')">
            <i class="pi pi-plus"></i>
        </button>
    </h3>
    <p-accordion *ngIf="isLoaded" [multiple]="true">
        <p-accordionTab *ngFor="let item of storeListings; let i = index">
            <ng-template pTemplate="header">
                    <span>Name: {{findFirstString(item.name)}} <span style="cursor: pointer; text-decoration: underline; color: var(--primary-color)" (click)="navigateToDetails(item.id, 'store-listings-v2')">ID: {{ item.id }}</span> <p-tag [style]="{background: '#757575'}" *ngIf="!item.isDifferent" value="No Difference" icon="pi pi-info-circle"></p-tag>
                    <p-tag class="p-ml-1" [style]="{background: '#ec942c'}" *ngIf="changeStoreListing(i)" value="Check Values" icon="pi pi-info-circle"></p-tag></span>
                    <i class="pi pi-times" style="color: red; cursor: pointer; margin-left: auto;" (click)="removeListing(item)"></i> <!-- Added margin-left: auto -->
            </ng-template>
            <p-table  [value]="item.fields" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem"></th>
                        <th pSortableColumn="name">Name <p-sortIcon field="key"></p-sortIcon></th>
                        <th pSortableColumn="source_value">Current Value<p-sortIcon field="source_value"></p-sortIcon></th>
                        <th pSortableColumn="destination_values">Destination Values<p-sortIcon field="destination_values"></p-sortIcon></th>
                        <th>Checked?</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr>
                        <td></td>
                        <td>{{item.key}}</td>
                        <td><data-component [key]="item.key" [data]="item.sourceVal"></data-component></td>
                        <td><data-component [key]="item.key" [data]="item.destVal"></data-component></td>
                        <td>
                            <p-checkbox *ngIf="!item.isValid" (onChange)="enableTransfer()" [binary]="true" [(ngModel)]="storeListingValidations[i][rowIndex]"></p-checkbox>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
    </p-accordion>

    <h3 style="display: inline-block;">Price Points 
        <button type="button" style="border: none; background: none; outline: none; cursor: pointer; color: var(--primary-color);" (click)="addModal('price-points')">
            <i class="pi pi-plus"></i>
        </button>
    </h3>
    <p-accordion [multiple]="true">
        <p-accordionTab *ngFor="let point of pricePoints; let i = index" >
            <ng-template pTemplate="header">
                <span>Name: {{findFirstString(point.name)}} <span style="cursor: pointer; text-decoration: underline; color: var(--primary-color)" (click)="navigateToDetails(point.id, 'price-points')">ID: {{ point.id }}</span> <p-tag [style]="{background: '#757575'}" *ngIf="!point.isDifferent" value="No Difference" icon="pi pi-info-circle"></p-tag>
                <p-tag class="p-ml-1" [style]="{background: '#ec942c'}" *ngIf="changePricePoint(i)" value="Check Values" icon="pi pi-info-circle"></p-tag></span>
                <i class="pi pi-times" style="color: red; cursor: pointer; margin-left: auto;" (click)="removePricePoint(point)"></i> <!-- Added margin-left: auto -->
        </ng-template>
            <p-table [value]="point.fields" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem"></th>
                        <th pSortableColumn="name">Key <p-sortIcon field="key"></p-sortIcon></th>
                        <th pSortableColumn="source_value">Current Value<p-sortIcon field="source_value"></p-sortIcon></th>
                        <th pSortableColumn="destination_values">Destination Values<p-sortIcon field="destination_values"></p-sortIcon></th>
                        <th>Checked?</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-point let-rowIndex="rowIndex">
                    <tr>
                        <td></td>
                        <td>{{point.key}}</td>
                        <td><data-component [key]="item.key"  [data]="point.sourceVal"></data-component></td>
                        <td><data-component [key]="item.key"  [data]="point.destVal"></data-component></td>
                        <td>
                            <p-checkbox *ngIf="!point.isValid" (onChange)="enableTransfer()" [binary]="true" [(ngModel)]="pricePointValidations[i][rowIndex]"></p-checkbox>
                        </td> 
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
    </p-accordion>
</p-card>

<p-dialog
  [(visible)]="addModalVisible"
  [dismissableMask]="true"
  [modal]="true"
  [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '60vw' }"
>
  <ng-template pTemplate="header">
    <div class="header-content">
      <h3>Add {{addEntity == 'store-listings' ? 'Store Listing' : 'Price Point'}}</h3>
    </div>
  </ng-template>
  <ng-template pTemplate="content"> <!-- Added style -->
    <span class="p-float-label p-fluid" style="width: 100%;"> <!-- Adjusted styles -->
    <h5 for="auto-complete" style="display: block;">Add Fields To Cart</h5>
      <p-multiSelect
        [(ngModel)]="addFields"
        [options]="suggestions"
        optionLabel="name"
        appendTo="body"
        [style]="{ width: '100%' }"
        [filterBy]="combined"
      >
      <ng-template let-item pTemplate="item">
        <div class="flex align-items-center gap-2">
            <div>{{ item.name  }} {{ item.id }}</div>
        </div>
    </ng-template>
    </p-multiSelect>
    </span>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton type="button" label="Confirm" icon="pi pi-check" (click)="confirmAction()" class="p-button-primary"></button>
    <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="addModalVisible = false" class="p-button-secondary"></button>
  </ng-template>
</p-dialog>

