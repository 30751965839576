import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import SeedPackBoxesDTO from '../dtos/SeedPackBoxesDTO';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';

@Component({
  selector: 'app-seed-pack-box-view',
  templateUrl: './seed-pack-box-view.component.html',
  styleUrls: ['./seed-pack-box-view.component.sass']
})
export class SeedPackBoxViewComponent extends BaseDynamicViewComponent implements OnInit {


  seedPackBoxRecord: SeedPackBoxesDTO = new SeedPackBoxesDTO();
  constructor() {
    super();
  }

  ngOnInit() {
    {
      this.fields =
        [
          {
            title: "Seed Pack Box Data",
            fields:
              [
                {
                  name: "Internal Name",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'name',
                  isRequired: true,
                  label: 'Internal Name',
                  columnWidth: 6
                },
                {
                  name: "Display Name",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'displayName',
                  isRequired: true,
                  label: 'Display Name',
                  columnWidth: 6
                },
                {
                  name: "Shortened Name",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'shortenedName',
                  isRequired: true,
                  label: 'Shortened Name',
                  columnWidth: 6
                },
                {
                  name: "Sprite Icon",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'spriteIcon',
                  isRequired: true,
                  label: 'Sprite Icon',
                  columnWidth: 6
                },
                {
                  name: "Start Date/Time",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Calendar],
                  clearField: true,
                  key: 'start',
                  label: 'Start Date/Time',
                  columnWidth: 6,
                },
                {
                  name: "End Date/Time",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Calendar],
                  clearField: true,
                  key: 'end',
                  label: 'End Date/Time',
                  columnWidth: 6
                },
                {
                  name: "Guaranteed Rarity",
                  inputType: DynamicViewType.MiscRef,
                  clearField: true,
                  key: 'guaranteed_rarity_ref',
                  label: 'Guaranteed Rarity',
                  controller: 'nurture-rarity',
                  columnWidth: 6
                },
                {
                  name: "Guaranteed Count",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'guaranteed_count',
                  isRequired: false,
                  label: 'Guaranteed Count',
                  columnWidth: 6
                },
                {
                  name: "Guarantee New SeedPackBox?",
                  inputType: DynamicViewType.Boolean,
                  clearField: true,
                  key: 'guarantee_new',
                  label: 'Guarantee New SeedPackBox?',
                  columnWidth: 6
                },
                {
                  name: "Backup SeedPackBox",
                  inputType: DynamicViewType.MiscRef,
                  clearField: true,
                  key: 'backupBox_ref',
                  label: 'Backup SeedPackBox',
                  controller: 'seed-pack-box',
                  columnWidth: 6
                },

                {
                  name: "Type",
                  inputType: DynamicViewType.MiscRef,
                  clearField: true,
                  key: 'type_ref',
                  label: 'Type',
                  controller: 'seed-pack-box-types',
                  columnWidth: 6
                },
                {
                  name: "Nurture Collection",
                  inputType: DynamicViewType.MiscRef,
                  clearField: true,
                  key: 'collection_ref',
                  label: 'Nurture Collection',
                  controller: 'nurture-collection',
                  columnWidth: 6
                },
                {
                  name: "Box Image",
                  inputType: DynamicViewType.MiscImageRecordV2,
                  viewMode: 'record',
                  clearField: true,
                  key: 'box_image_ref',
                  label: 'Box Image',
                  optionValue: '_id',
                  filter: true,
                  filterBy: 'name,id,path',
                  autoDisplayFirst: false,
                  columnWidth: 6,
                  options: { fieldName: 'box_image_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.SeedPack }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
                },
                {
                  name: "Box Image Empty",
                  inputType: DynamicViewType.MiscImageRecordV2,
                  viewMode: 'record',
                  clearField: true,
                  key: 'box_image_empty_ref',
                  label: 'Box Image',
                  optionValue: '_id',
                  filter: true,
                  filterBy: 'name,id,path',
                  autoDisplayFirst: false,
                  columnWidth: 6,
                  options: { fieldName: 'box_image_empty_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.SeedPack }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
                },
                {
                  name: "Seed Pack Quantity",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'seedPackQty',
                  isRequired: false,
                  label: 'Seed Pack Quantity',
                  columnWidth: 6
                },
                {
                  name: "Nurtures",
                  inputType: DynamicViewType.AutoComplete,
                  clearField: true,
                  key: 'nurtures_ref',
                  label: 'Nurtures'
                },
                {
                  name: "Allow Duplicates?",
                  inputType: DynamicViewType.Boolean,
                  clearField: true,
                  key: 'allowDups',
                  label: 'Allow Duplicates?',
                  columnWidth: 6
                },
              ],
            type: DynamicCardType.Card
          },
          {
            title: "Rarity Weight Overrides",
            fields:
              [
                {
                  name: "Common Weight",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'weight_1000',
                  isRequired: false,
                  label: 'Common Weight',
                  columnWidth: 7
                },
                {
                  name: "Uncommon Weight",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'weight_1001',
                  isRequired: false,
                  label: 'Uncommon Weight',
                  columnWidth: 7
                },
                {
                  name: "Rare Weight",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'weight_1002',
                  isRequired: false,
                  label: 'Rare Weight',
                  columnWidth: 7
                },
                {
                  name: "Epic Weight",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'weight_1003',
                  isRequired: false,
                  label: 'Epic Weight',
                  columnWidth: 7
                },
                {
                  name: "Legendary Weight",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'weight_1004',
                  isRequired: false,
                  label: 'Legendary Weight',
                  columnWidth: 7
                },
              ],
            type: DynamicCardType.Card
          }
        ]

      this.seedPackBoxRecord.enabled = false;

      this.title = "Seed Pack Box";
      this.viewRoute = 'seed-pack-box';
      this.isLoading = false;
    }
  }
}
