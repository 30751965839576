<h1 *ngIf="!isEditMode">Add New Challenge</h1>
<h1 *ngIf="isEditMode">Editing Challenge {{ id }}</h1>

<form *ngIf="fields" [formGroup]="challengeForm" (ngSubmit)="beforeSubmit()">
  <ng-container *ngIf="!loading">
  <div class="p-grid" style="
      padding-left: 5vw;
      padding-right: 5vw;
    ">
    <div class="p-col-12">
      <button
        pButton
        pRipple
        type="submit"
        label="Submit"
        [disabled]="!challengeForm.valid"
        class="p-mb-4"
        [style]="{ 'min-width': '12.5em' }"
      ></button>
      <div class="">
        <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
          <p-toggleButton
            formControlName="enabled"
            [onLabel]="'Enabled'"
            [offLabel]="'Not Enabled'"
            [style]="{ width: '100%' }"
            class="field-item"
          ></p-toggleButton>
        </span>
      </div>
      <div class="p-mt-2">
        <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
          <p-toggleButton
            formControlName="isInfiniteChallengeExcluded"
            [onLabel]="'Infinite Gardens Excluded'"
            [offLabel]="'Infinite Gardens Not Excluded'"
            [style]="{ width: '100%' }"
            class="field-item"
          ></p-toggleButton>
        </span>
      </div>
      <!-- CARD: Top Details -->
      <p-card class="p-mx-2">
        <div class="p-grid p-mx-5">
          <div class="p-col">
            <!-- name -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input
                  formControlName="name"
                  pInputText
                  class="field-item"
                  [required]="true"
                />
                <label for="name">
                  Name <strong style="color: crimson">*</strong></label
                >
              </span>
              <p-button
                *ngIf="challengeForm.value['name']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('name')"
              ></p-button>
            </div>
            <!-- 'Challenge ID' label (edit mode) -->
          </div>
        </div>
      </p-card>
      <!-- CARD: 'Production' -->
      <p-card class="p-mx-2">
        <div>
        <span class="p-card-title p-d-inline p-mx-5" id="require-fields">Production</span>
        <p-toggleButton
            [onLabel]="'Advanced'"
            [offLabel]="'Advanced'"
            [style]="{ width: '25%' }"
            class="field-item"
            [(ngModel)]="advancedPath"
            [ngModelOptions]="{standalone: true}"
          ></p-toggleButton>
        </div>
        <div class="p-grid p-mx-5">
          <div class="p-col">
            <!-- scene -->
            <div *ngIf="advancedPath" class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input
                  formControlName="scene"
                  pInputText
                  class="field-item"
                  [(ngModel)]="fields.scene"
                  (input)="challengeValidationService.handleAutomationLock('scene', fields)"
                />
                <label for="scene"> File Path </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['scene']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('scene'); challengeValidationService.handleAutomationLock('scene', fields)"
              ></p-button>
            </div>
            <div *ngIf="!advancedPath" class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input
                  pInputText
                  class="field-item"
                  [(ngModel)]="simplePathScene"
                  [ngModelOptions]="{standalone: true}"
                  (input)="updateSimplePath('scene', fields, challengeForm)"
                />
                <label for="scene"> File Path </label>
              </span>
              <p-button
                *ngIf="simplePathScene"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="simplePathScene = ''; updateSimplePath('scene', fields, challengeForm)"
              ></p-button>
            </div>
            <!-- image -->
            <div *ngIf="advancedPath" class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input
                  formControlName="image"
                  pInputText
                  class="field-item"
                  [(ngModel)]="fields.image"
                  (input)="challengeValidationService.handleAutomationLock('image', fields)"
                />
                <label for="image"> Feed Image </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['image']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('image'); challengeValidationService.handleAutomationLock('image', fields)"
              ></p-button>
            </div>
            <div *ngIf="!advancedPath" class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input
                  pInputText
                  class="field-item"
                  [(ngModel)]="simplePathImage"
                  [ngModelOptions]="{standalone: true}"
                  (input)="updateSimplePath('image', fields, challengeForm)"
                />
                <label for="scene"> Feed Image </label>
              </span>
              <p-button
                *ngIf="simplePath"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="simplePathImage = ''; updateSimplePath('image', fields, challengeForm)"
              ></p-button>
            </div>
          </div>
        </div>

        <div class="p-grid p-mx-5">
          <!-- 'Production': bottom, left column -->
          <div class="p-col-6 p-pr-4">
            <!-- Environment(s) -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect
                  formControlName="env"
                  [options]="options['envs']"
                  display="chip"
                  [style]="{ width: '100%' }"
                  (onChange)="challengeForm.get('env')?.markAsTouched();"
                  (onClear)="challengeForm.get('env')?.markAsTouched();"
                >
                </p-multiSelect>
                <label for="env"> Environment(s) </label>
              </span>
              <p-button *ngIf="challengeForm.value['env']" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="clearField('env')"></p-button>
            </div>
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input
                  #inputFileName
                  [(ngModel)]="fields.fileName"
                  formControlName="fileName"
                  pInputText
                  class="field-item"
                  (keyup)="
                    onPathComponentValueChange(
                      challengeConstants.inputNames.fileName.name,
                      { value: inputFileName.value }
                    )
                  "
                />
                <label for="fileName"> File Name </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['levelName']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('levelName')"
              ></p-button>
            </div>
            <!-- year -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown
                  formControlName="year"
                  [options]="options['year']"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  (onChange)="
                    onPathComponentValueChange(
                      challengeConstants.inputNames.year.name,
                      $event
                    )
                  "
                ></p-dropdown>
                <label for="year"> Year </label>
              </span>

              <p-button
                *ngIf="challengeForm.value['year']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('year')"
              ></p-button>
            </div>
            <!-- File Type -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown
                  formControlName="fileType"
                  [options]="options['fileType']"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                ></p-dropdown>
                <label for="fileType"> File Type </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['fileType']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('fileType')"
              ></p-button>
            </div>
            <!-- Keywords -->
            <div class="p-my-5">
              <span ngClass="p-float-label">
                <p-multiSelect
                  [(ngModel)]="keywords_ref"
                  [ngModelOptions]="{standalone: true}"
                  [options]="options['keywords_ref']"
                  optionLabel="name"
                  [filter]="true"
                  (onChange)="challengeForm.get('keywords_ref')?.markAsTouched();"
                  (onClear)="challengeForm.get('keywords_ref')?.markAsTouched();"
                ></p-multiSelect>
                <label for="keywords_ref"> Keywords </label>
              </span>
              </div>
              <div class="p-my-5">
                <span ngClass="p-float-label">
                  <p-multiSelect
                        [formControlName]="'collection_ref'"
                        [options]="options['collection_ref']"
                        optionLabel="name"
                        display="chip"
                        [style]="{ width: '100%' }"
                        (onChange)="challengeForm.get('collection_ref')?.markAsTouched(); testEvent($event)"
                        (onClear)="challengeForm.get('collection_ref')?.markAsTouched();"
                      >
                      </p-multiSelect>
                  <label for="collection_ref"> Collection </label>
                </span>
                </div>
          </div>
          <!-- 'Production': bottom, right column -->
          <div class="p-col-6">
            <div class="p-grid p-mx-5">
              <div class="p-col-3 p-pr-4">
                <h5>Bundle Asset (IOS)</h5>
                <p-inputSwitch class="p-my-5"
                formControlName="bundleAsset"></p-inputSwitch>
              </div>
              <div class="p-col-3 p-pr-4">
                <h5>Bundle Image (IOS)</h5>
                <p-inputSwitch class="p-my-5"
                formControlName="bundleImage"></p-inputSwitch>
              </div>
              <div class="p-col-3 p-pr-4">
                <h5>Bundle Asset (AND)</h5>
                <p-inputSwitch class="p-my-5"
                formControlName="bundleAssetAnd"></p-inputSwitch>
              </div>
              <div class="p-col-3 p-pr-4">
                <h5>Bundle Image (AND)</h5>
                <p-inputSwitch class="p-my-5"
                formControlName="bundleImageAnd"></p-inputSwitch>
              </div>
            </div>
            <!-- audio -->
            <div class="p-mx-2" header="Audio">
              <ng-container>
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <span ngClass="p-float-label">
                      <p-multiSelect
                        [formControlName]="'spawnAudios_ref'"
                        [options]="options['spawnAudios_ref']"
                        optionLabel="name"
                        optionValue="_id"
                        display="chip"
                        [style]="{ width: '100%' }"
                        (onChange)="challengeForm.get('spawnAudios_ref')?.markAsTouched();"
                        (onClear)="challengeForm.get('spawnAudios_ref')?.markAsTouched();"
                      >
                      </p-multiSelect>
                      <label [for]="'spawnAudios_ref'">Spawn Audio</label>
                    </span>
                    <p-button *ngIf="challengeForm.value['spawnAudios_ref']" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="clearField('spawnAudios_ref')"></p-button>
                  </span>
                </div>
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-multiSelect
                      [formControlName]="'spawnAudioCollections_ref'"
                      [options]="options['spawnAudioCollections_ref']"
                      optionLabel="name"
                      optionValue="_id"
                      display="chip"
                      [style]="{ width: '100%' }"
                      (onChange)="challengeForm.get('spawnAudioCollections_ref')?.markAsTouched();"
                      (onClear)="challengeForm.get('spawnAudioCollections_ref')?.markAsTouched();"
                    >
                    </p-multiSelect>
                    <label [for]="'spawnAudioCollections_ref'">Spawn Audio Collection</label>
                  </span>
                  <p-button *ngIf="challengeForm.value['spawnAudioCollections_ref']" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="clearField('spawnAudioCollections_ref')"></p-button>
                </div>
              </ng-container>
              <ng-container>
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <span ngClass="p-float-label">
                      <p-multiSelect
                        [formControlName]="'loopAudios_ref'"
                        [options]="options['loopAudios_ref']"
                        optionLabel="name"
                        optionValue="_id"
                        display="chip"
                        [style]="{ width: '100%' }"
                        (onChange)="challengeForm.get('loopAudios_ref')?.markAsTouched();"
                        (onClear)="challengeForm.get('loopAudios_ref')?.markAsTouched();"
                      >
                      </p-multiSelect>
                      <label [for]="'loopAudios_ref'">Loop Audio</label>
                    </span>
                    <p-button *ngIf="challengeForm.value['loopAudios_ref']" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="clearField('loopAudios_ref')"></p-button>
                  </span>
                </div>
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-multiSelect
                      [formControlName]="'loopAudioCollections_ref'"
                      [options]="options['loopAudioCollections_ref']"
                      optionLabel="name"
                      optionValue="_id"
                      display="chip"
                      [style]="{ width: '100%' }"
                      (onChange)="challengeForm.get('loopAudioCollections_ref')?.markAsTouched();"
                      (onClear)="challengeForm.get('loopAudioCollections_ref')?.markAsTouched();"
                    >
                    </p-multiSelect>
                    <label [for]="'loopAudioCollections_ref'">Loop Audio Collection</label>
                  </span>
                  <p-button *ngIf="challengeForm.value['loopAudioCollections_ref']" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="clearField('loopAudioCollections_ref')"></p-button>
                </div>
              </ng-container>
            </div>
          </div>


        </div>
      </p-card>
      <!-- CARD: 'Content' -->
      <p-card class="p-mx-2">
        <span class="p-card-title p-mx-5" id="content-fields">Content</span>
        <div class="p-grid p-mx-5">
          <!-- 'Content': left column -->
          <div class="p-col-6 p-pr-4">
            <!-- type_ref -->
            <div class="p-my-5 p-inputgroup">
              <div class="p-grid" style="width: 100%">
                <div class="p-col-9 p-d-flex">
                  <span ngClass="p-float-label">
                    <p-dropdown
                      formControlName="type_ref"
                      [options]="options['type_ref']"
                      optionLabel="name"
                      optionValue="_id"
                      [autoDisplayFirst]="false"
                      [filter]="true"
                      [filterBy]="'name'"
                      (onChange)="
                        onPathComponentValueChange(
                          challengeConstants.inputNames.challengeType.name,
                          $event
                        ); onChangeTypeRef($event)
                        "
                      ></p-dropdown>
                      <label for="type_ref">
                        Challenge Type
                        <strong style="color: crimson">*</strong></label
                      >
                    </span>
                    <p-button
                      *ngIf="challengeForm.value['type_ref']"
                      ariaLabel="Clear Field"
                      icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger"
                      (click)="clearField('type_ref')"
                    ></p-button>
                </div>
                <div class="p-col-3 p-d-flex inputgroup">
                  <p-colorPicker #foreColor class="p-pt-1" formControlName="typeColor" ></p-colorPicker>
                  <input pInputText class="p-ml-1 field-item" (change)="onColorChanged(foreColor)" [(ngModel)]="foreColor.inputBgColor"
                    [ngModelOptions]="{standalone: true}" />
                      <p-button
                        *ngIf="challengeForm.value['color']"
                        ariaLabel="Clear Field"
                        icon="pi pi-replay"
                        styleClass="p-button-text p-button-success"
                        (click)="setOriginal('typeColor'); setOriginal('type_ref')"
                      ></p-button>
                </div>
              </div>
            </div>
            <!-- sceneType -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown
                  formControlName="sceneType"
                  [options]="options['scene_type_ref']"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  optionLabel="name"
                  optionValue="_id"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  filterBy="name"
                  (onChange)="
                    onPathComponentValueChange(
                      challengeConstants.inputNames.sceneType.name,
                      $event
                    )
                  "
                ></p-dropdown>
                <label for="sceneType"> Scene Type </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['sceneType']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('sceneType')"
              ></p-button>
            </div>
            <!-- color -->
            <!-- <div class="p-my-5 p-inputgroup">Color:</div> -->
            <!-- climate_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown
                  formControlName="climate_ref"
                  [options]="options['climate_ref']"
                  optionLabel="name"
                  optionValue="_id"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  [filterBy]="'name'"
                >
                </p-dropdown>
                <label for="climate_ref">
                  Climate <strong style="color: crimson">*</strong></label
                >
              </span>
              <p-button
                *ngIf="challengeForm.value['climate_ref']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('climate_ref')"
              ></p-button>
            </div>
            <!-- location -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input
                  formControlName="location"
                  pInputText
                  class="field-item"
                />
                <label for="location"> Location </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['location']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('location')"
              ></p-button>
            </div>
            <!-- level lock -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown
                  formControlName="progressionLevel_ref"
                  [options]="options['progressionLevel_ref']"
                  optionLabel="name"
                  optionValue="_id"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  [filterBy]="'name'"
                ></p-dropdown>
                <label for="progressionLevel_ref"> Level Lock </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['progressionLevel_ref']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('progressionLevel_ref')"
              ></p-button>
            </div>
            <!-- start -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-calendar
                  formControlName="start"
                  [showTime]="true"
                  [yearNavigator]="true"
                  yearRange="2020:2030"
                  [monthNavigator]="true"
                  class="field-item"
                  [selectOtherMonths]="true"
                  [defaultDate]="defaultDate"
                  [keepInvalid]="true"
                  (onSelect)="challengeValidationService.onStartDateSelect($event, challengeForm)"
                  (onInput)="handleDateInputChange('start',$event)">
                >
                <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                  <h5 class="p-d-inline">Local Time: </h5><small>{{ challengeForm.value['start'] ? (challengeForm.value['start'] | date: 'medium') : ''   }}</small>
                </ng-template>
              </p-calendar>
                <label for="start"> Start Date/Time </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['start']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('start')"
              ></p-button>
              <p-button
                *ngIf="challengeForm.value['start'] && challengeForm.controls['start'].touched"
                ariaLabel="Undo Change"
                icon="pi pi-undo"
                styleClass="p-button-text p-button-primary"
                (click)="revertField('start')">
              </p-button>
            </div>
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-calendar
                  formControlName="end"
                  [showTime]="true"
                  [minDate]="challengeForm.get('start')!.value"
                  [yearNavigator]="true"
                  yearRange="2020:2030"
                  [monthNavigator]="true"
                  class="field-item"
                  [selectOtherMonths]="true"
                  [defaultDate]="defaultDate"
                  (onSelect)="updateVoteDates($event)"
                  (onInput)="handleDateInputChange('end',$event)">
                >
                <ng-template pTemplate="footer"*ngIf="!checkTimeZone">
                  <h5 class="p-d-inline">Local Time: </h5><small>{{ challengeForm.value['end'] ? (challengeForm.value['end'] | date: 'medium') : ''   }}</small>
                </ng-template>
              </p-calendar>
                <label for="end"> End Date/Time </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['end']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('end')"
              ></p-button>
              <p-button
                *ngIf="challengeForm.value['end'] && challengeForm.controls['end'].touched"
                ariaLabel="Undo Change"
                icon="pi pi-undo"
                styleClass="p-button-text p-button-primary"
                (click)="revertField('end')">
              </p-button>
            </div>
            <div class="p-grid p-mt-3 p-mb-4">
              <div class="p-col-5">
                <span ngClass="p-float-label">
                  <p-calendar
                    formControlName="voteStart"
                    [disabled]="true"
                    [showTime]="true"
                    [yearNavigator]="true"
                    yearRange="2020:2030"
                    [monthNavigator]="true"
                    class="field-item"
                    [selectOtherMonths]="true"
                  >
                  <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                    <h5 class="p-d-inline">Local Time: </h5><small>{{ challengeForm.value['voteStart'] ? (challengeForm.value['voteStart'] | date: 'medium') : ''   }}</small>
                  </ng-template>
                </p-calendar>
                  <label for="end"> Vote Start Date/Time </label>
                </span>
              </div>
              <div class="p-col-7">
                <small class="p-mt-2">*Auto-Generated based on Start/End Dates</small>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col-5">
                <span ngClass="p-float-label">
                  <p-calendar
                    formControlName="voteEnd"
                    [disabled]="true"
                    [showTime]="true"
                    [yearNavigator]="true"
                    yearRange="2020:2030"
                    [monthNavigator]="true"
                    class="field-item"
                    [selectOtherMonths]="true"
                  >
                  <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                    <h5 class="p-d-inline">Local Time: </h5><small>{{ challengeForm.value['voteEnd'] ? (challengeForm.value['voteEnd'] | date: 'medium') : ''   }}</small>
                  </ng-template>
                </p-calendar>
                  <label for="end"> Vote End Date/Time </label>
                </span>
              </div>
            </div>
          </div>

          <!-- 'Content': right column -->
          <div class="p-col-6 p-pl-4">
            <!-- progressionLevel_ref -->

            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <textarea
                  formControlName="description"
                  pInputTextarea
                  cols="40"
                  rows="3"
                  class="field-item"
                ></textarea>
                <label for="description"> Challenge Copy </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['description']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('description')"
              ></p-button>
            </div>
            <!-- descriptionShort -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <textarea
                  formControlName="descriptionShort"
                  pInputTextarea
                  cols="40"
                  rows="3"
                  class="field-item"
                ></textarea>
                <label for="descriptionShort"> Feed Image Copy </label>
              </span>
              <p-button
                *ngIf="challengeForm.value['descriptionShort']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('descriptionShort')"
              ></p-button>
            </div>
            <!-- Associated Challenges -->
            <h3>Associated Challenges</h3>
            <div class="p-my-5 p-inputgroup">
              <span class="">
                <p-autoComplete
                [suggestions]="suggestions['parent_challenge']"
                (completeMethod)="challengeValidationService.getSuggestionsForRef(suggestions, $event.query, 'parent_challenge', 'challenges', true)"
                [showEmptyMessage]="true"
                (onSelect)="onChallengeSelect($event, 1)"
                formControlName="parent_challenge"
                minLength="2"
                placeholder="Parent Challenge"
                [style]="{ 'min-width': '24em' }">

                <ng-template let-ca pTemplate="item">
                  {{ ca.name }} ({{ ca.id }})
                </ng-template>

                <ng-template let-ca pTemplate="selectedItem">
                  {{ ca }}
                </ng-template>

                </p-autoComplete>
            </span>
              <p-button
                *ngIf="challengeForm.value['parent_challenge']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('parent_challenge')"
              ></p-button>
            </div>
            <div class="p-my-5 p-inputgroup">
              <span class="">
                <p-autoComplete
                [suggestions]="suggestions['child_challenge']"
                (completeMethod)="challengeValidationService.getSuggestionsForRef(suggestions, $event.query, 'child_challenge', 'challenges', true)"
                [showEmptyMessage]="true"
                (onSelect)="onChallengeSelect($event, 2)"
                formControlName="child_challenge"
                minLength="2"
                placeholder="Child Challenge"
                [style]="{ 'min-width': '24em' }">

                <ng-template let-cc pTemplate="item">
                  {{ cc.name }} ({{ cc.id }})
                </ng-template>

                <ng-template let-cc pTemplate="selectedItem">
                  {{ cc }}
                </ng-template>

                </p-autoComplete>
            </span>
              <p-button
                *ngIf="challengeForm.value['child_challenge']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('child_challenge')"
              ></p-button>
            </div>
            <!-- contentHold_ref -->
            <div class="p-my-6 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect
                    formControlName="contentHold_ref"
                    [options]="options['contentHold_ref']" optionLabel="name"
                    optionValue="_id" display="chip" [style]="{ width: '100%' }"
                    (onChange)="challengeForm.get('contentHold_ref')?.markAsTouched();">
                    (onClear)="challengeForm.get('contentHold_ref')?.markAsTouched();"
                </p-multiSelect>
                <label for="contentHold_ref"> Content Hold </label>
              </span>
              <p-button *ngIf="challengeForm.value['contentHold_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('contentHold_ref')"></p-button>
            </div>
            <!-- sponsor_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown
                  formControlName="sponsor_ref"
                  [options]="options['sponsor_ref']"
                  optionLabel="name"
                  optionValue="_id"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                  [filterBy]="'name'"
                >
                </p-dropdown>
                <label for="sponsor_ref">Sponsor</label>
              </span>
              <p-button
                *ngIf="challengeForm.value['sponsor_ref']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('sponsor_ref')"
              ></p-button>
            </div>
            <!--  -->
          </div>
        </div>
        <!-- description -->

      </p-card>
      <!-- CARD: 'Internal Notes' -->
      <p-card class="p-mx-2">
        <span class="p-card-title p-mx-5">Requirement


        </span>

        <div class="p-grid">
          <div class="p-col-5"></div>
          <div class="p-col-6">
            <p-tag *ngIf="showCoinConfig || showWaterConfig" class="p-ml-4" icon="pi pi-info-circle" value="Entry Payout info has been loaded from Game Config with the following values:"></p-tag>
          <div class="p-mt-2">
            <p-tag *ngIf="showCoinConfig || showWaterConfig" [style]="{ backgroundColor: '#ffcc00', borderColor: '#ff9900' }" class="p-ml-4" icon="pi pi-info-circle" value="Changing Values will override Game Config"></p-tag>
          </div>
          </div>
          <div class="p-col-10">
            <!-- restrictions_ref -->


            <div class="p-grid">
              <div class="p-col-12 p-xl-6">
                <div class="p-m-1">
                  <span
                    [style]="{ 'min-width': '16.5em' }"
                    ngClass="p-float-label"
                  >
                  <div [ngStyle]="{'width': '100%'}">
                    <span>
                        <prizes-card-form-group
                            [(prize)]="this.prizeReward.prizes"
                            [showPrize]="true"
                            [fieldsetName]="'Prize(s)'"
                            (onPrizeChange)="this.prizeReward.prizes = $event"
                            [isChallengePrize]="true"
                            (onChallengePrizeChange)="onPrizeSelect($event)"
                        >
                        </prizes-card-form-group>
                    </span>
                </div>
                  </span>
                </div>
              </div>

              <div class="p-col-12 p-xl-6">
                <div class="p-m-1">

                  <div [ngStyle]="{'width': '100%'}">
                    <span>
                        <prizes-card-form-group
                            [(prize)]="this.prizeReward.rewards"
                            [showPrize]="true"
                            [fieldsetName]="'Entry Payout'"
                            (onPrizeChange)="onRewardsRefChange($event)"
                        >
                        </prizes-card-form-group>
                    </span>
                </div>
                </div>
              </div>
              <div class="p-ml-5 p-mt-3 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-autoComplete
                    formControlName="restrictions_ref"
                    [suggestions]="suggestions['restrictions_ref']"
                    [showEmptyMessage]="true"
                    field="name"
                    minLength="3"
                    multiple="true"
                    [style]="{ width: '100%' }"
                    (onSelect)="restrictionValidation($event)"
                    (completeMethod)="
                      challengeValidationService.getSuggestionsForRef(
                        suggestions,
                        $event.query,
                        'restrictions_ref',
                        'restrictions'
                      )
                    "
                  >
                    <ng-template let-restriction pTemplate="selectedRestriction">
                      {{ restriction.name }} ({{ restriction.id }})
                    </ng-template>

                    <ng-template let-restriction pTemplate="restriction">
                      {{ restriction.name }} ({{ restriction.id }})
                    </ng-template>
                  </p-autoComplete>
                  <label for="restrictions_ref"> Restrictions </label>
                </span>
                <p-button
                  *ngIf="challengeForm.value['restrictions_ref']"
                  ariaLabel="Clear Field"
                  icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger"
                  (click)="clearField('restrictions_ref')"
                ></p-button>
                <p-button
                  ariaLabel="Clear Field"
                  icon="pi pi-plus"
                  styleClass="p-button-text p-button-success"
                  (click)="newRestriction()"
                ></p-button>
              </div>
            </div>
          </div>
        </div>
      </p-card>
      <p-card class="p-mx-2">
        <span class="p-card-title p-mx-5"> Internal Notes</span>
        <div class="p-mx-5">
          <!-- internalNotes -->
          <div class="p-my-5 p-inputgroup">
            <span ngClass="p-float-label">
              <p-editor formControlName="internalNotes" [style]="{'height':'200px', 'width':'50vw'}">
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                      <select type="button" class="ql-header p-mr-2" aria-label="Header"></select>
                      <button type="button" class="ql-bold" aria-label="Bold"></button>
                      <button type="button" class="ql-italic" aria-label="Italic"></button>
                      <button type="button" class="ql-underline p-mr-2" aria-label="Underline"></button>
                      <select type="button" class="ql-color" aria-label="Color"></select>
                      <select type="button" class="ql-background p-mr-2" aria-label="Background Color"></select>
                      <button type="button" value="ordered" class="ql-list" aria-label="Underline"></button>
                      <button type="button" value="bullet" class="ql-list" aria-label="Underline"></button>
                      <button type="button" class="ql-link p-mr-2" aria-label="Underline"></button>
                  </span>
              </ng-template>
              </p-editor>
            </span>
            <p-button
              *ngIf="challengeForm.value['internalNotes']"
              ariaLabel="Clear Field"
              icon="pi pi-minus"
              styleClass="p-button-text p-button-danger"
              (click)="clearField('internalNotes')"
            ></p-button>
          </div>
        </div>
        <span class="p-card-title p-mx-5">Dev</span>
              <div class="p-grid p-mx-5">
                <div class="p-col-12 p-pr-4">
                  <!-- artist_ref -->
                  <div class="p-my-5 p-inputgroup">
                    <span ngClass="p-float-label">
                      <p-dropdown
                        formControlName="artist_ref"
                        [options]="options['artist_ref']"
                        optionLabel="name"
                        optionValue="_id"
                        [autoDisplayFirst]="false"
                        [filter]="true"
                        [filterBy]="'name'"
                        >

                      </p-dropdown>
                      <label for="artist_ref"> Artist </label>
                    </span>
                    <p-button *ngIf="challengeForm.value['artist_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField('artist_ref')"></p-button>
                  </div>
                </div>

                <div class="p-col-12 p-pr-4">
                  <!-- tester_ref -->
                  <div class="p-my-5 p-inputgroup">
                    <span ngClass="p-float-label">
                      <p-autoComplete
                        formControlName="tester_ref"
                        [suggestions]="suggestions['tester_ref']"
                        (completeMethod)="
                          challengeValidationService.getSuggestionsForRef(
                            suggestions,
                            $event.query,
                            'tester_ref',
                            'users'
                          )
                        "
                        [showEmptyMessage]="true"
                        field="name"
                        minLength="3"
                        [style]="{ width: '100%' }"
                      >
                        <ng-template let-tester pTemplate="selectedTester">
                          {{ tester.name }} ({{ tester.id }})
                        </ng-template>

                        <ng-template let-tester pTemplate="tester">
                          {{ tester.name }} ({{ tester.id }})
                        </ng-template>
                      </p-autoComplete>
                      <label for="tester_ref"> Tester </label>
                    </span>
                    <p-button
                      *ngIf="challengeForm.value['tester_ref']"
                      ariaLabel="Clear Field"
                      icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger"
                      (click)="clearField('tester_ref')"
                    ></p-button>
                  </div>
                </div>
              </div>
      </p-card>
      <!-- CARD: 'Requirements' -->
      <button
        pButton
        pRipple
        type="submit"
        label="Submit"
        [disabled]="!challengeForm.valid"
        class="p-m-5"
        [style]="{ 'min-width': '12.5em' }"
      ></button>
    </div>
    <!-- <div class="p-col-3">
      <p-card>

        <ng-template pTemplate="content">
          <div class="p-grid">
            <span class="p-col-12 p-sm-6 p-lg-12 p-p-3">
              <h3>Feed Image Preview</h3>
              <div
                style="
                  object-fit: cover;
                  overflow: hidden;
                  height: auto;
                  max-width: 100%;
                "
                class="p-d-block p-mx-auto"
              >
                <div class="">
                  <img
                    id="image1"
                    *ngIf="this.preview_FeedImg"
                    [src]="this.preview_FeedImg"
                    class="p-mx-auto"
                    width="100%"
                    alt="🥀 No Feed Img Path"
                    (error)="setDefaultPic(true)"
                  />
                </div>
              </div>
              <h3>Render Image Preview</h3>
              <div
                style="
                  object-fit: cover;
                  overflow: hidden;
                  height: auto;
                  max-width: 100%;
                "
                class="p-d-block p-mx-auto"
              >
                <img
                  id="image1"
                  *ngIf="this.preview_RenderImg"
                  [src]="this.preview_RenderImg"
                  class="p-mx-auto"
                  width="100%"
                  alt="🥀 No Render Img Path"
                  (error)="setDefaultPic()"
                />
              </div>

              <span class="p-card-title p-m-5">Copy</span>
              <div class="p-grid p-mx-5">
                <div class="p-col">


                </div>
              </div>


            </span>
          </div>
        </ng-template>
      </p-card>
    </div> -->
  </div>
</ng-container>
<!-- Prize Automator -->

<p-confirmDialog #cd [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <h2 *ngIf="selectedItem"><a class='p-button-link p-text-left' href="/items/{{selectedItem.id}}" target="_blank" [ngStyle]="{color:'var(--primary-color)'}"><strong>{{selectedItem.name}} ({{selectedItem.id}})</strong></a></h2>
  </ng-template>
  <ng-template pTemplate="footer">
    <div *ngIf="!hideBypass" class="p-field-checkbox">
      <p-checkbox formControlName="bypassChecked" [(ngModel)]="fields.bypassChecked" binary="true"></p-checkbox>
      <label for="binary">{{fields.bypassChecked ? 'Bypass Prize Automator Enabled' : 'Bypass Prize Automator Not Enabled'}}</label>
    </div>
    <button
      type="button"
      pButton
      icon="pi pi-times"
      label="{{showOk ? 'OK': 'No'}}"
      (click)="cd.reject()"
    ></button>
    <button
      *ngIf="(!this.prizeRefWarningDialog || fields.bypassChecked || !hideBypass) && !showOk"
      type="button"
      pButton
      icon="pi pi-check"
      label="Yes"
      (click)="cd.accept()"
    ></button>
  </ng-template>
</p-confirmDialog>
</form>
<p-dialog
  *ngIf="!loading"
  header="Start/End Date Verification"
  [(visible)]="showDatesVerificationModal" [modal]="true"
  [style]="{minWidth: '40vw'}"
  >
  <div>
    <h4 class="p-m-0">Original Start and End date values</h4>
  </div>
  <div class="p-grid p-mt-0">
    <div class="p-col">
        <h5>Start:</h5><p *ngIf="originalRecord['start']">{{challengeValidationService.formatDate(originalRecord['start'])}}</p>
    </div>
    <div class="p-col">
      <h5>End:</h5><p *ngIf="originalRecord['end']">{{challengeValidationService.formatDate(originalRecord['end'])}}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <h4 class="p-m-0">New Start and End date values</h4>
  </div>
  <div class="p-grid">
    <div class="p-col">
      <h5>Start:</h5><p *ngIf="challengeForm.value['start']">{{challengeValidationService.formatDate(challengeForm.value['start'])}}</p>
    </div>
    <div class="p-col">
      <h5>End:</h5><p *ngIf="challengeForm.value['end']">{{challengeValidationService.formatDate(challengeForm.value['end'])}}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <p-tag
      styleClass="p-mx-2"
      [value]="startDateErrorMessage"
      severity="danger"
      [rounded]="true"
    ></p-tag>
    <p-tag
    styleClass="p-mx-2"
    [value]="endDateErrorMessage"
    severity="danger"
    [rounded]="true"
  ></p-tag>
  </div>
  <ng-template pTemplate="footer">
    <button
        pButton
        pRipple
        type="submit"
        label="Cancel Submit"
        class="p-mt-2 p-button-secondary"
        (click)="showDatesVerificationModal=!showDatesVerificationModal"
      ></button>
    <button
        pButton
        pRipple
        type="submit"
        label="Confirm Submit"
        class="p-mt-2 p-button"
        (click)="submitAndSkipChecks('date')"
      ></button>
  </ng-template>
</p-dialog>
<!-- environment flags prerequisites validation dialog -->
<p-dialog
  *ngIf="!loading"
  header="Environment Flags Prerequisites Validation"
  [(visible)]="showEnvsVerificationModal" [modal]="true"
  [style]="{minWidth: '40vw'}"
  >
  <div class="p-grid p-mt-0">
    <div class="p-col">
        <span>
          <strong class="p-m-0">Environment Flag Prerequisites:</strong>
          <ul *ngFor="let env of this.options['envs']" class="p-mt-2">
            <li>
              <span class="p-m-0">
                <p-tag
                styleClass="p-mx-2"
                [value]="env"
                [severity]="this.envRules[env]?.status ? 'success' : 'danger'"
                [rounded]="true"
              ></p-tag>
              <p-chip class="p-mx-1" *ngFor="let rule of this.envRules[env]?.rules" [label]="rule"></p-chip>
              </span>
            </li>
          </ul>
        </span>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="p-grid p-mt-0">
    <div class="p-col">
        <span>
          <strong class="p-m-0">Original Env Values:</strong>
          <p *ngFor="let env of originalRecord['env']" class="p-my-0">
            <span class="p-mx-1 ">{{env}}</span>
          </p>
        </span>
    </div>
    <div class="p-col">
      <span>
        <strong class="p-m-0">New Env Values:</strong>
        <p *ngFor="let env of challengeForm.value['env']" class="p-my-0">
          <span class="p-mx-1 ">{{env}}</span>
        </p>
      </span>
  </div>
  </div>
  <p-divider></p-divider>
  <div>
    <p-tag
      styleClass="p-mx-2"
      value="Missing Environment Flags Prerequisites"
      severity="danger"
      [rounded]="true"
    ></p-tag>
  </div>
  <ng-template pTemplate="footer">
    <button
        pButton
        pRipple
        type="submit"
        label="Cancel Submit"
        class="p-mt-2 p-button-secondary"
        (click)="showEnvsVerificationModal=!showEnvsVerificationModal"
      ></button>
    <button
        pButton
        pRipple
        type="submit"
        label="Confirm Submit"
        class="p-mt-2 p-button"
        (click)="submitAndSkipChecks('envs', challengeForm, keywords_ref, isEditMode, key, id, type)"
      ></button>
  </ng-template>
</p-dialog>
<div *ngIf="loading" class="p-px-6 p-pt-3">
  <i
    class="pi pi-spin pi-spinner"
    style="font-size: 2rem"
  ></i>
</div>
