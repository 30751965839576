import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Daily Rewards DTO
 */
export default class DailyRewardsDTO extends BaseEntityDto
{
    /**
     * Misc. Record reference
     */
    bannerImage: any;
     /**
     * Each dailyRewards array is RewardsTrack_ref
     */
    rewardsTracks: any;

    type: any;
}
