import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityHistoryService } from './services/entity-history.service';
import { refMapping } from './refMapping';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'app-entity-history',
  templateUrl: './entity-history.component.html',
  styleUrls: ['./entity-history.component.sass']
})
export class EntityHistoryComponent implements OnInit
{
  entityId: any;
  entityType: any;
  entityData: any;
  rows: Array<any> = [];

  constructor
  (
    private route: ActivatedRoute,
    private entityHistoryService: EntityHistoryService,
    private utilitiesService: UtilitiesService
  ) { }

  /**
   * Entity History Component Initialization
   */
  async ngOnInit()
  {
    const routeParams = this.route.snapshot.paramMap;
    this.entityId = routeParams.get('id');
    this.entityType = routeParams.get('entityType');
    await this.getEntityHistory();
    await this.getRefsFromMapping();
  }

  /**
   * Retrieves Entity history for a given entity
   */
  async getEntityHistory() {
    if (this.entityId && this.entityType) {
      let response = await this.entityHistoryService.getEntityHistory(this.entityType, this.entityId);

      if (response) {
        const filteredData = this.filterAndConvertChanges(response);
        this.entityData = filteredData;
        this.rows = filteredData;
      }
    }
  }

  private filterAndConvertChanges(data: any[]): any[] {
    // Filter the ops for each record
    let filteredData = data.map((record: { ops: any[]; }) => {
      record.ops = record.ops.filter(op => {
        // Skip userData paths
        if (op.path.startsWith('/userData')) return false;
        
        // Skip "add" operations with null value and no originalValue
        if (op.op === 'add' && op.value === null && (!op.hasOwnProperty('originalValue') || op.originalValue === null)) {
          return false;
        }

        // Skip any operations containing Buffer data
        const hasBuffer = (value: any): boolean => {
          if (!value) return false;
          if (typeof value === 'object') {
            if (value.type === 'Buffer') return true;
            if (Array.isArray(value)) {
              return value.some(item => hasBuffer(item));
            }
            return Object.values(value).some(val => hasBuffer(val));
          }
          return false;
        };

        if (hasBuffer(op.value) || hasBuffer(op.originalValue)) {
          return false;
        }
        
        // Handle _automations paths
        if (op.path.startsWith('/_automations')) {
          if ((op.op === 'add' || op.op === 'replace') && op.value) {
            op.originalValue = null;
            op.path = '/Automation';
            return true;
          }
          return false;
        }
        
        return true;
      });
      return record;
    });

    // Remove objects with empty ops arrays
    return filteredData.filter((record: { ops: string | any[]; }) => record.ops.length > 0);
  }

  parseValue(value: any): string {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value, null, 2);  // the "2" here is for formatting with 2 spaces indentation
    }
    return value;
  }

  isDate(value: any): boolean {
    if (value instanceof Date) return true; // Checks if it's a Date object

    if (typeof value === 'string') {
      // Regex to check for a simplified ISO 8601 date format
      // This pattern checks for YYYY-MM-DD with optional time and timezone parts.
      // Adjust the regex as needed for your expected date formats.
      const isoDatePattern = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(.\d+)?(Z|[\+\-]\d{2}:\d{2})?)?$/;

      if (isoDatePattern.test(value)) {
        const date = new Date(value);
        return !isNaN(date.getTime());
      }
    }

    return false; // Not a Date object or a valid date string
  }

  async getRefsFromMapping(){
    const mapping: any = refMapping[this.entityType];
    // this.entityData
    for (const record of this.entityData) {
      for (const op of record.ops) {
        console.log(op);
        let key = op.path.split("/")[1];
        console.log(key);
        let ref = mapping[key];
  
        if (!ref) continue;
        
        if (op.value) {
          let valueRes = await this.utilitiesService.getAndReturnOptions({
            entity: ref.collection, query: { _id: op.value },
            populate: [],
            populateMinimal: true,
            autopopulate: false,
            smartPopulate: {},
            virtuals: false,
            select: 'id name',
            sort: {id: -1}
          });
  
          if(valueRes.length > 0){
            let entity = ref.collection;
            let id = valueRes[0].id;
            let link = `/${entity}/${id}`;
            let tmpVal = `<a href="${link}" target="_blank" style="color: #97C27A !important;">${valueRes[0].name} (${id})</a>`;
            op.value = tmpVal; 
          }
        }
  
        if (op.originalValue) {
          let originalValueRes = await this.utilitiesService.getAndReturnOptions({
            entity: ref.collection, query: { _id: op.originalValue },
            populate: [],
            populateMinimal: true,
            autopopulate: false,
            smartPopulate: {},
            virtuals: false,
            select: 'id name',
            sort: {id: -1}
          });
  
          if(originalValueRes.length > 0){
            let entity = ref.collection;
            let id = originalValueRes[0].id;
            let link = `/${entity}/${id}`;
            let tmpOriginalVal = `<a href="${link}" target="_blank" style="color: #97C27A !important;">${originalValueRes[0].name} (${id})</a>`;
            op.originalValue = tmpOriginalVal; 
          }
        }
      }
    }
  }


    // let keys = ["colors_ref"];
    // for (const key in mapping) {
    //   if (mapping.hasOwnProperty(key)) {
    //     const ref = mapping[key];
    //     if(keys.includes(key)){

    //     }
    //   }
    // }
  }

