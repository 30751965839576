import { Component, Input, DoCheck, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-rewards-track-input',
    templateUrl: './rewards-track-input.component.html',
    styleUrls: ['./rewards-track-input.component.css']
})
export class RewardsTrackInputComponent implements DoCheck, OnInit {
    constructor() { }

    @Input() field: any;
    @Input() record: any;
    @Output() onRecordChange = new EventEmitter<any>();
    private previousType: number | undefined;

    trackKeys: string[] = [];
    trackKeyLabels: string[] = [];
    multiKeyStages: boolean = false;
    lineItemInputVersion: number = 3;
    milestoneLabel: string = 'Target';
    touchIsLoop: boolean = false;
    showIsLoop: boolean = false;
    readonly trackKeyType: { [key: string]: string } = {
        dailyRewardDay1: 'singleStage',
        dailyRewardDay2: 'singleStage',
        dailyRewardDay3: 'singleStage',
        dailyRewardDay4: 'singleStage',
        dailyRewardDay5: 'singleStage',
        dailyRewardDay6: 'singleStage',
        dailyRewardDay7: 'singleStage',
        dailyRewardDay30: 'singleStage',
        free: 'multiStage',
        paid: 'multiStage',
        friends: 'multiStage',
        EOrewards: 'eoTrack',
        EObundles: 'eoTrack',
        coop: 'multiStage',
        miniGamesRewards: 'multiStage',
        seasonalPrizes: 'multiStage',
        dailyActivitiesRewards: 'multiStage',
        perkDailyReward: 'multiStage',
    };

    ngOnInit() {
        if (this.record?.type !== undefined) {
            this.buildTrackKeys(this.record.type);
            this.previousType = this.record.type;
        }
        if (this.field.options && this.field.options.lineItemInputVersion) {
            this.lineItemInputVersion = this.field.options.lineItemInputVersion;
        }
    }

    async ngDoCheck() {
        if (this.record?.type !== undefined && this.record.type !== this.previousType) {
            this.previousType = this.record.type;
            await this.buildTrackKeys(this.record.type);
            
            if (!this.record.stageMilestone) {
                this.record.stageMilestone = [];
            }

            const targetLength = this.multiKeyStages ? 
                this.trackKeys.length : 
                (this.record[this.trackKeys[0]]?.length || 0);

            if (this.record.stageMilestone.length !== targetLength) {
                this.record.stageMilestone = this.record.stageMilestone
                    .slice(0, targetLength)
                    .concat(Array(Math.max(0, targetLength - this.record.stageMilestone.length))
                    .fill(0));
                this.emitValue('stageMilestone');
            }
        }
    }

    private buildTrackKeys(type: number) {
        switch (type) {
            case 1: // Daily Login Rewards - 7 days
                this.trackKeys = [
                    'dailyRewardDay1', 'dailyRewardDay2', 'dailyRewardDay3',
                    'dailyRewardDay4', 'dailyRewardDay5', 'dailyRewardDay6', 'dailyRewardDay7'
                ];
                this.multiKeyStages = true;
                this.trackKeyLabels = ['Day 1 Rewards', 'Day 2 Rewards', 'Day 3 Rewards', 'Day 4 Rewards', 'Day 5 Rewards', 'Day 6 Rewards', 'Day 7 Rewards'];
                break;
            case 2: // Daily Login Rewards - Day 30
                this.trackKeys = ['dailyRewardDay30'];
                this.trackKeyLabels = ['Day 30 Rewards'];
                this.multiKeyStages = false;
                break;
            case 3: // Season Pass - Free track
                this.trackKeys = ['free'];
                this.trackKeyLabels = ['Free Stage'];
                this.milestoneLabel = 'Target';
                this.multiKeyStages = false;
                if (!this.record.free) {
                    this.record.free = [];
                }
                break;
            case 4: // Season Pass - Paid track
                this.trackKeys = ['paid'];
                this.trackKeyLabels = ['Paid Stage'];
                this.milestoneLabel = 'Target'; 
                this.multiKeyStages = false;
                if (!this.record.paid) {
                    this.record.paid = [];
                }
                break;
            case 5: // Friends Invited
                this.trackKeys = ['friends'];
                this.trackKeyLabels = ['Friends Invited'];
                this.milestoneLabel = 'Friends Invited';
                this.multiKeyStages = false;
                if (!this.record.friends) {
                    this.record.friends = [];
                }
                break;
            case 6: // Endless Offer
                this.trackKeys = ['EOrewards'];
                this.trackKeyLabels = ['Endless Offer Rewards'];
                this.touchIsLoop = true;
                this.multiKeyStages = false;
                break;
            case 7: // Co-Op
                this.trackKeys = ['coop'];
                this.trackKeyLabels = ['Co-Op Stage'];
                this.milestoneLabel = 'Reward Target';
                this.multiKeyStages = false;
                if (!this.record.coop) {
                    this.record.coop = [];
                }
                break;
            case 8: // Mini Games
                this.trackKeys = ['miniGamesRewards'];
                this.trackKeyLabels = ['Mini Games Rewards'];
                this.multiKeyStages = false;
                if (!this.record.miniGamesRewards) {
                    this.record.miniGamesRewards = [];
                }
                this.touchIsLoop = true;
                this.showIsLoop = true;
                break;
            case 9: // Seasonal Prizes
                this.trackKeys = ['seasonalPrizes'];
                this.trackKeyLabels = ['Seasonal Prizes'];
                this.multiKeyStages = false;
                if (!this.record.seasonalPrizes) {
                    this.record.seasonalPrizes = [];
                }
                break;
            case 10: // Daily Activities
                this.trackKeys = ['dailyActivitiesRewards'];
                this.trackKeyLabels = ['Daily Activities'];
                this.multiKeyStages = false;
                if (!this.record.dailyActivitiesRewards) {
                    this.record.dailyActivitiesRewards = [];
                }
                break;
            case 11: // Perk Daily Reward
                this.trackKeys = ['perkDailyReward'];
                this.trackKeyLabels = ['Perk Daily Reward'];
                this.multiKeyStages = false;
                if (!this.record.perkDailyReward) {
                    this.record.perkDailyReward = [];
                }
                break;
            default:
                this.trackKeys = ['error'];
                this.trackKeyLabels = ['Error: Could not match track type'];
        }
    }

    onChange(key: string, event: any, stageIndex: number | null = null) {

        if (key === 'stageMilestone' && stageIndex !== null) {
            this.record.stageMilestone[stageIndex] = event.value;
        } else if(key === 'EOrewards') {
            this.record.EOrewards = event.record.EOrewards;
            this.record.EObundles = event.record.EObundles;
        } else if (stageIndex !== undefined && stageIndex !== null) {
            this.record[key][stageIndex] = event;
        } else {
            this.record[key] = event;
        }

        this.emitValue(key);
    }

    addStage(key: string) {
        this.record[key] = [...this.record[key], [{ t: null, c: null, id: null, lineItemType: null }]];

        if (this.record.stageMilestone) {
            this.record.stageMilestone[this.record[key].length - 1] = 0;
        } else {
            this.record.stageMilestone = [];
        }

        this.onChange(key, this.record[key]);
        
        setTimeout(() => {
            const orderList = document.getElementById('orderList-' + key);
            if (orderList) {
                const listContent = orderList.querySelector('.p-orderlist-list');
                if (listContent) {
                    listContent.scrollTo({
                        top: listContent.scrollHeight,
                        behavior: 'smooth'
                    });
                }
            }
        }, 100);
    }

    removeStage(key: string, index: number): void {
        this.record[key].splice(index, 1);
        this.record.stageMilestone.splice(index, 1);
        this.onChange(key, this.record[key]);
    }

    emitValue(key: string) {
        this.field.customFields = [
            ...this.trackKeys.map(key => ({
                key: key,
                touched: true
            })),
            {
                key: 'stageMilestone',
                touched: true
            }
        ];

        if (this.touchIsLoop) {
            this.field.customFields.push({
                key: 'isLoop',
                touched: true
            });
        }

        if(key === 'EOrewards') {
            this.field.customFields.push({
                key: 'EObundles',
                touched: true
            });
        }
        
        this.onRecordChange.emit({
            event: { record: this.record, updateAllCustomFields: true }, 
            field: this.field, 
            updateFullRecord: true 
        });
    }
}
