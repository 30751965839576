import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.sass']
})
export class HistoryComponent implements OnInit {
  
  @Input() entityType: string;
  @Input() record: any;
  buttonConfig: any;

  constructor() { }

  ngOnInit() {

    this.buttonConfig = {
      'default': [
        { label: 'See Latest History Changes', routerLink: `/history/${this.entityType}/${ this.record.id }` },
      ]
      // Add more entityTypes here...
    };
  }

}
