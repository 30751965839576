import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators,  } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import SourcingChallengeGroupDto from '../dtos/sourcing-challenge-groupDTO';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';


@Component({
  selector: 'app-sourcing-challenge-group-form',
  templateUrl: './sourcing-challenge-group-form.component.html',
  styleUrls: ['./sourcing-challenge-group-form.component.sass'],
})
export class SourcingChallengeGroupFormComponent extends BaseDynamicFormComponent implements OnInit {
  
  key: string = 'sourcing-challenge-groups';
  id: number;
  sourcingGroup: SourcingChallengeGroupDto = new SourcingChallengeGroupDto()
  isEditMode: boolean = false;
  submitUrl: string;
  batchSubmitUrl: string;
  options: any = [];
  suggestions: any = [];
  existingDoc: any = {};
  uploadedFiles: any[] = [];
  currentFiles: any[] = [];
  isLoading: boolean = false;

  sourcingGroupForm: UntypedFormGroup;
  fields: any = {
    name: null,
    start: null,
    end: null,
    enabled: true,
  };
  display: boolean = false;
  displayShown: boolean = false;


  action: string = 'add';
  submitMessage: string = 'Submit';
  isNewBatch: boolean = true;
  updateBatch: boolean = false;
  batchError: boolean = false;
  existingBatchMessage: string = 'Create Sourcing group and use EXISTING Batch';
  newBatchMessage: string = 'Create Sourcing group and NEW Batch';

  constructor(
    private logger: LoggerService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private authService: AuthService,
    private dataService: DataService,
    private messageService: MessageService,
    private utilitiesService: UtilitiesService,
    private titleService: Title,
    private entityViewService: EntityViewService,
    private router: Router
  ) {
    super();
  }

  async ngOnInit() {
    this.fields =
    [
      {
        title: "Shape Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Sourcing Challenge Group";
    this.viewRoute = 'sourcing-challenge-groups';
    this.isLoading = false;

    // await this.setOptions()
    // const routeParams = this.route.snapshot.paramMap;
    // this.id = Number(routeParams.get('id'));
    // if (this.id) {
    //   this.isEditMode = true;
    //   let response = await this.entityViewService.getEntity(
    //     this.key,
    //     {
    //       query: { id: this.id },
    //       autopopulate: false,
    //       virtuals: false,
    //     }
    //   );

    //   if (response) {
    //     this.sourcingGroup = response;
    //   }
    // } else {
    //   this.logger.log('else');
    // }

    // if (this.isEditMode) {
    //   this.titleService.setTitle(`Edit ${this.sourcingGroup.name}(${this.id})`)
    // } else {
    //   this.titleService.setTitle(`Add New ${this.key} `)
    // }
    // await this.setOptions()
    // this.isLoading = false;
  }

  // async onSubmit() {
  //   // userData from authService
  //   let userResult = this.authService.getSocialUser();
  //   this.sourcingGroup.userData = {
  //     name: userResult.currentUser.name,
  //     email: userResult.currentUser.email,
  //     id: userResult.currentUser.id,
  //   };
  //   if (this.isEditMode) {
  //     let updateResponse = await this.dataService.updateRecord(
  //       this.id,
  //       this.key,
  //       this.sourcingGroup
  //     );
  //     if (updateResponse) {
  //       this.messageService.add({
  //         sticky: true,
  //         severity: 'success',
  //         summary: 'Update Successful',
  //         detail: 'Sourcing Group Update Successfully',
  //       });
  //       this.router.navigate([`category-collections/${updateResponse.id}`]);
  //     } else {
  //       this.messageService.add({
  //         sticky: true,
  //         severity: 'error',
  //         summary: 'Submit Error',
  //         detail: 'There was an error updating the Sourcing Group.',
  //       });
  //     }
  //   } else {
  //     let createResponse = null;
  //     try {
  //       createResponse = await this.dataService.addNewRecord(
  //         this.key,
  //         this.sourcingGroup
  //       );
  //     } catch (error) {
  //       this.messageService.add({
  //         sticky: true,
  //         severity: 'error',
  //         summary: 'Submit Error',
  //         detail:
  //           'There was an error creating the Sourcing Group. Please check the required fields',
  //       });
  //     }

  //     if (createResponse) {
  //       this.messageService.add({
  //         sticky: true,
  //         severity: 'success',
  //         summary: 'Create Successful',
  //         detail: 'Sourcing Group Created Successfully.',
  //       });
  //       this.router.navigate([`sourcing-challenge-groups/${createResponse.id}`]);
  //     }
  //   }
  // }

  // async setOptions()
  // {
  //   const entities = [
  //     { ref: 'category_ref', entity: 'categories' }

  //   ];

  //   for (const { ref, entity } of entities) {
  //     const params = new OptionsParams({
  //       entity
  //     });
  //     await this.utilitiesService.getOptions(this.options, ref, params);
  //   }
  // }

  // async getOptionsFromRef(
  //   fieldName: string,
  //   model: string,
  //   minimal: boolean = false,
  //   autopopulate: boolean = true
  // ) {
  //     const options = await this.dataService.getAllOfTypeAsync(model, {
  //       query: {},
  //       autopopulate: autopopulate,
  //       virtuals: true,
  //       sort: { name: 1 },
  //     });
  //     if (minimal) {
  //       let o: any[] = [];
  //       for (const option of options) {
  //         o.push({ id: option.id, name: option.name, _id: option._id });
  //       }
  //       this.options[fieldName] = o;
  //     } else {
  //       this.options[fieldName] = options;
  //     }
  // }
}
