import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'officeTimeForm',
})

export class OfficeTimeFormPipe implements PipeTransform {
  transform(date: any): any {
    const inputDate = new Date(date);

    // Check if the date is valid
    if (isNaN(inputDate.getTime())) {
        console.error('Invalid date string');
        return null;
    }

    // Set the target time zone to Los Angeles (Pacific Time)
    const options = { timeZone: 'America/Los_Angeles' };

    // Convert the date to a string with the specified time zone
    const transformedDate = new Date(inputDate.toLocaleString('en-US', options));

    console.log('Original Date:', date);
    console.log('Transformed Date:', transformedDate);

    return transformedDate;
  }
}

