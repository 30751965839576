import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { LoggerService } from 'src/app/common/services/logger.service';
import FieldData from '../field-data-dto';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
import { ValidationsService } from '../../../services/validations.service';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { OfficeTimePipe } from 'src/app/common/pipes/officeTime.pipe';

@Component({
  selector: 'app-data-field',
  templateUrl: './base-data-field.component.html',
  styleUrls: ['./base-data-field.component.sass'],
})
export class BaseDataFieldComponent implements OnInit {
  @Input() field: FieldData;
  @Input() value: any;
  @Input() options: Array<any> = []
  @Input() page: string;
  @Input() entityViewLink: string;
  @Input() entityType: string;
  @Input() entity: any;
  @Input() tableView: boolean;
  timestamp = new Date().getTime();
  parsedValue: string = '';

  imgData: any = {};
  showImgDialog = false;
  display: boolean = false;
  hiResImage: string = '';
  coloredCategories: string[];
  coloredClimates: Object;
  cols: any[];
  reorderedEnvValues: any;
  timestampFormatted: any = this.datePipe.transform(this.timestamp, 'MM/dd/yyyy, hh:mm a')

  checkTimezone: boolean = false;

  get isValueArray(): boolean {
    return !this.validationsService.isEmpty(this.value) && _.isArray(this.value)
  }
  get hasViewControl(): boolean {
    return this.field.viewControl && this.field.viewControl.type
  }

  get assetType(): typeof AssetTypes
  {
    return AssetTypes;
  }

  constructor(
    private loggerService: LoggerService,
    private datePipe: DatePipe,
    private officeTimePipe: OfficeTimePipe,
    private clipboard: Clipboard,
    private validationsService: ValidationsService,
    private messageService: MessageService,
    private utilitiesService: UtilitiesService,
  ) {}

  ngOnInit() {
    this.coloredCategories = [
      'Small Plant',
      'Medium Plant',
      'Large Plant',
      'Ground Cover',
      'Aquatic Plant',
      'Climbers',
    ];
    this.coloredClimates = {
      Temperate: '#ADDAE2',
      Tropical: '#4C9A9A',
      Arid: '#FBDE8E',
    };

    !this.validationsService.isEmpty(this.value) && this.field.dataKey
      ? this.value = this.mapValueToOption(this.field, this.value)
      : null

    if(this.field.useHardCodedOptions)
    {
      this.value = (this.field.options?.find((x) => x.value == this.value))?.label;
    }

    if(this.field.key == 'env'){
      // this.reorderedEnvValues = this.validationsService.validateEnvOrder(this.value);
      this.reorderedEnvValues = this.value
      let desiredOrder = ['dev', 'qa', 'prod'];

      this.reorderedEnvValues.sort(function(a:any, b:any) {
        var indexA = desiredOrder.indexOf(a);
        var indexB = desiredOrder.indexOf(b);

        if (indexA === -1) {
          return 1; // Move elements not in the desired order to the end
        }
        if (indexB === -1) {
          return -1; // Move elements not in the desired order to the end
        }

        return indexA - indexB;
      });
    }

    this.checkTimezone = this.utilitiesService.isUserLocalTimeZonePST();
  }

  /** Maps the value to a hard-coded list of options, where the value is identified by a given property, indicated by FieldData.dataKey.
   * @param field FieldData — Current field being displayed.
   * @param value Current value of field
   * @returns object from options array
   */
  mapValueToOption(field: FieldData, value: any = this.value) {
    if (_.isArray(value)) {
      let result: any[] = []
      for (let v of value) {
        result.push(this.mapValueToOption(field.dataKey, v))
      }
      return result;
    }

    if (typeof value === 'object') {
      return !this.validationsService.isEmpty(value[field.dataKey]) ? value : null
    } else {
      let allOptions: any[] = [];
      // Define ALL OPTIONS
      if (field.isOptionsGrouped) {
        this.options.forEach((group: any) => {
          if (group && group.items && group.items.length > 0) {
            group.items.forEach((option: any) => {
              allOptions.push(option);
            });
          }
        });
      } else {
        allOptions = this.options;
      }

      let foundOption = allOptions.find(
        (o: any) => o[field.dataKey].toString() === value.toString()
      );
      return foundOption ? foundOption : null
    }
  }

  /**
   * Function is a temporary measure for handling entity fields 'internalReferenceLinks' & 'referenceLinks', which have inconsistent data format (intended string value may be 'value' or 'value.link')
   * @param value The value of the entity field.
   * @returns intended string value.
   */
  parseFormArrayValue(value: any) {
    if (['internalReferenceLinks', 'referenceLinks'].includes(this.field.key)) {
      if (typeof value === 'object') {
        return value.link;
      } else if (typeof value === 'string') {
        return value;
      } else return 'Bad Format';
    } else return value;
  }

  setDefaultPic() {
    this.value =
      'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg';
  }

  /** Parse Link Path from value, by field key
   * @param value Value of field being parsed.
   * @returns Path URL parsed in function.
   */
  parseLinkPath(value: any) {
    if (this.validationsService.isEmpty(value)) {
      return '';
    } else {
      switch (this.field.key) {
        case 'internalReferenceLinks':
        case 'internalReferenceImageLinks':
        case 'referenceLinks':
          if (typeof value === 'object') {
            return value.link;
          } else {
            return value;
          }

        case 'challenges_ref':
          return `/challenges/${value.id}`;
        case 'releatedItems':
          return `/items/${value.id}`;
        case 'items_ref':
          return `/items/${value.id}`;
        case 'category_collections_ref':
          return `/category-collections/${value.id}`;
        case 'image-ref':
          return `/miscellaneous-build/${value.id}`;
        default:
          return `/${this.field.apiController}/${value.id}`;
      }
    }
  }

  /** Parse Link Label from value, by field key.
   * @param value Value of field being parsed.
   * @returns String used for Link Label.
   */
  parseLinkLabel(value: any): string {
    if (this.validationsService.isEmpty(value)) {
      return '';
    } else {
      switch (this.field.key) {
        case 'internalReferenceLinks':
        case 'referenceLinks':
          return 'LINK';
        case 'releatedItems':
        case 'challenges_ref':
          return `${value.name} (${value.id})`;
        default:
          if(value.name && value.id){
            return `${value.name} (${value.id})`;
          } else {
            return '';
          }
      }
    }
  }

  /** Parses image path from array of objects, each containing a 'size' and 'path' property. Determines necessary size by 'page'.
   * @returns Path string or null
   */
  imagesDisplaySrc(): string | null {
    const ordered = _.sortBy(this.value, 'size')
    const firstSize = this.page === 'table' ? 128 : null;
    const index = ordered.findIndex((imageObj:any)=> imageObj.size == firstSize)

    for (let imageObj of ordered.slice(index)) {
      if (!this.validationsService.isEmpty(imageObj.path)) {
        return imageObj.path
      }
    }
    return null
  }

  /** Compiles and sets imgData object for Image Dialog, then sets showImgDialog to true.
   * @param field FieldData
   */
  displayImageModal(field: any = this.field) {   
    if (field) {
      let imgData: any = {
        title: field.name,
      };

      this.loggerService.log('this.entity', this.entity)

      this.loggerService.log('displayImageModal', this.entity.thumbnail_ref);

      if (this.hasViewControl) {
        const imageObj = this.value.find((img: any) => img.size === 1024)
        if (!this.validationsService.isEmpty(imageObj)) {
          imgData.name = imageObj.path;
          imgData.path = imageObj.path;
        }
      } else {
        let keyPrefix = field.key.substring(0, field.key.lastIndexOf('_'));
        if (this.entity[field.key]) {
          if (field.key.endsWith('_img')) {
            imgData.name = this.entity[field.key].replace('_128', '_1024');
            imgData.path = this.entity[field.key].replace('_128', '_1024');
          } else if (field.key == 'thumbnail') {
            imgData.name = this.entity[field.key].replace('_256', '_1024');
            imgData.path = this.entity[field.key].replace('_256', '_1024');
          } else {
            for (let keySuffix of [
              '_url_32',
              '_url_64',
              '_url_128',
              '_url_256',
              '_url_1024',
            ]) {
              if (field.key.endsWith(keySuffix)) {
                imgData.path = this.entity[keyPrefix + '_1024'];
                break;
              }
            }
          }
        }
      }
      this.imgData = imgData;
      if (this.imgData.path) {
        this.showImgDialog = true;
      }
    }
  }

  /**
   *
   * @param copyVal // text to copy into the user's clipboard
   */
  copyToClipboard(copyVal: string) {
    this.clipboard.copy(copyVal);
  }

  getAssetType(type: number){
    return this.assetType[type]
  }

  getLabel(value: any) {

    const option = this.field.options ? this.field.options.find((option: { value: any; }) => option.value === value) : value;
    return option ? option.label : value;
  }

  getCollectionLabel(collection: any): string {
    if (!collection) return 'Undefined';
    
    if (collection.id && this.options && this.options.length > 0) {
      const option = this.options.find(opt => opt.id === collection.id);
      if (option) {
        return option.name;
      }
    }
    
    return collection.name ? collection.name : 'Undefined';
  }
}
