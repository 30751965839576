import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import GlobalSearchResults from 'src/app/common/dtos/GlobalSearchResults';
import { GlobalSearchService } from 'src/app/common/services/global-search.service';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.sass']
})
export class AdvancedSearchComponent implements OnInit {
  globalSearchQueryValue: string | null;
  fullSearchResult: any;
  currentTimeout: any;
  isLoading: boolean = true;
  views: Array<string> = ["All"];

  constructor(
    private route: ActivatedRoute,
    private globalSearchService: GlobalSearchService,
    private router: Router,
    public authService: AuthService,
  ) { }

  /**
   * Advanced Search Component Initialization
   */
  async ngOnInit() {
    this.globalSearchQueryValue = this.route.snapshot.queryParamMap.get('searchQuery');
    let response = await this.globalSearchService.globalSearch(this.globalSearchQueryValue, true);
    
    if (response) {
      this.fullSearchResult = response;
      if (this.fullSearchResult.GlobalSearchResults && this.fullSearchResult.GlobalSearchResults.GroupedEntities && this.fullSearchResult.GlobalSearchResults.GroupedEntities.length > 0) {
        this.fullSearchResult.GlobalSearchResults.GroupedEntities.forEach((entity: any) => {
          this.views.push(entity.label)
        });
      }
    }
    this.isLoading = false;
  }

 /**
   * Perform a search query on multiple entities.
   */
  async globalSearch() {
    this.updateURLQueryParams();
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    this.currentTimeout = setTimeout(async () => {
      let response = await this.globalSearchService.globalSearch(this.globalSearchQueryValue, true);
      if (response) {
        this.fullSearchResult = response;
      }
      this.currentTimeout = null;
    }, 500);
  }

  /** 
   * Update URL query params for full search. 
   * */
  updateURLQueryParams() {
    // Update Query parameter value
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { 'searchQuery' : this.globalSearchQueryValue},
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
}
