import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Dropdown } from 'primeng/dropdown';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-dropdown-cell-editor',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.sass']
})
export class DropdownEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild(Dropdown, { static: true }) dropdown: Dropdown;

  public params: any;
  public value: any;
  public originalValue: any;

  public fieldName: string = '';
  public options: any[] = [];
  private optionsMap: { [key: string]: any } = {};
  public optionLabel: string = 'label';
  public optionValue: string = 'value';

  agInit(params: any): void {
    this.params = params;
  
    console.log('Dropdown params:', this.params);
  
    // GETTING LABEL
    this.fieldName = this.params.colDef.headerName;
  
    // GETTING OPTIONS
    if (this.params.isFlatList && !this.params.isLinkDestination) {
      this.options = this.params.colDef.filterParams.values
        .filter((item: any) => item !== '(EMPTY)' && item !== '(NOT EMPTY)')
        .map((item: any) => {
          return { label: item, value: item };
        });
    } else {
      this.options = this.params.colDef.filterParams.values;
      this.optionLabel = this.params.optionLabel;
      this.optionValue = this.params.optionValue;
    }
    console.log('Options:', this.options); // Log to check the options
  
    // GETTING VALUE
    if(this.params.objectValue && this.params.optionValue && this.options){
      // Build the optionsMap for fast lookup
      if (this.options) { // Check if this.options is not undefined or null
        this.options.forEach(option => {
          this.optionsMap[option[this.optionValue]] = option;
        });
      }
      this.value = this.params.value?.[this.params.optionValue];
      this.originalValue = this.params.value?.[this.params.optionValue];
    } else {
      this.value = this.params.value;
      this.originalValue = this.params.value;
    }
  }
  

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.dropdown) {
        this.dropdown.focus();
      }
    });
  }

  getValue(): any {
    if (this.params.objectValue && this.params.optionValue) {
      // Find the object in the options array that matches the selected value
      return this.optionsMap[this.value] || null;
    } else {
        return this.value;
    }
  }

  isPopup(): boolean {
    return true;
  }

  clearValue(){
    this.value = null;
  }

  setOriginalValue(){
    this.value = this.originalValue;
  }
}
