import { Component, OnInit } from '@angular/core';
import ManagedListFieldsDTO from '../dtos/ManagedListFieldsDTO';

@Component({
  selector: 'challenge-content-hold-managed-list',
  templateUrl: './challenge-content-hold-managed-list.component.html',
  styleUrls: ['./challenge-content-hold-managed-list.component.sass']
})
export class ChallengeContentHoldManagedListComponent implements OnInit
{
  fields: Array<ManagedListFieldsDTO> =
  [
    {
      key: 'name',
      name: 'Name',
      inputType: 'inputText'
    },
    {
      key: 'enabled',
      name: 'Enabled',
      inputType: 'inputToggle',
      onLabel: 'Enabled',
      offLabel: 'Not Enabled'
    },
  ];
  properties =
  {
    name: null,
    enabled: true
  }

  constructor() { }

  ngOnInit(): void {}
}
