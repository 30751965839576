import { Component, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-link-cell-editor',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.sass']
})
export class LinkCellEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  private params: any;
  public value: any[];
  public linkForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.linkForm = this.fb.group({
      links: this.fb.array([]),
    });
  }

  ngAfterViewInit(): void {
    // Initialization code here
  }

  agInit(params: any): void {
    this.params = params;

    let initialLinks = [];
    if (params.value) {
      if (Array.isArray(params.value) && params.value.length > 0) {
        const hasString = params.value.some((item: any) => typeof item === 'string');
        const hasObjectWithLink = params.value.some(
          (item: any) => typeof item === 'object' && item && typeof item.link === 'string'
        );
    
        if (hasString) {
          // It contains strings
          initialLinks = params.value.filter((item: any) => typeof item === 'string');
        } else if (hasObjectWithLink) {
          // It contains objects with "link" property
          initialLinks = params.value
            .filter(
              (item: any) => typeof item === 'object' && item && typeof item.link === 'string'
            )
            .map((linkObj: any) => linkObj.link);
        }
      }
    }

    console.log(initialLinks)
    
    
    
    
    
    

    // Initialize form controls with the extracted links
    const linkControls = this.linkForm.get('links') as FormArray;
    initialLinks.forEach((link: any) => {
        const linkGroup = this.fb.group({ link: [link] });
        linkControls.push(linkGroup);
    });

    if (linkControls.length === 0) {
        // If there's no initial data, add an empty form control for new entries
        this.addLink();
    }
}

  getValue(): any {
    // Always return the value in ["url", ...] format
    const linkControls = this.linkForm.get('links') as FormArray;
    return linkControls.value.map((linkGroup: any) => linkGroup.link).filter((link: any) => link); // the filter is used to remove empty strings
  }

  get links() {
    return (this.linkForm.get('links') as FormArray);
  }

  addLink() {
    const linkGroup = this.fb.group({
      link: [''],
    });
    this.links.push(linkGroup);
  }

  removeLink(index: number) {
    this.links.removeAt(index);
  }
}
