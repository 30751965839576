import { Injectable } from '@angular/core';

@Injectable()
export class InterceptorService {
  private  skip: boolean = false;

  setSkipAdjust(value: any): void {
    this.skip = value;
  }

  skipAdjust(): any {
    return this.skip;
  }

  adjustToPacificTime(date: any, isCalendar: boolean = false) {
    if (!date || this.skip) return date;

    // Convert the date to a Date object if it's not already one
    const dateObj = date instanceof Date ? date : new Date(date);

    // Determine if the date is in daylight saving time for Pacific Time
    const isDaylightSavingTime = this.isPacificDaylightTime(dateObj);

    // Define the new offset based on whether it's PDT or PST
    const newOffset = isDaylightSavingTime ? '0700' : '0800';

    const stringDate = dateObj.toString();
    const regex = /GMT[+-]\d{4} \([^)]+\)/;
    console.log('Before replace: ', stringDate);

    const modifiedDateString = stringDate.replace(regex, `GMT-${newOffset}`);
    console.log('After replace: ', modifiedDateString);

    if(isCalendar) {
      this.setSkipAdjust(true);
    }
    return modifiedDateString;
  }

  isPacificDaylightTime(date: Date): boolean {
    // Function to find the nth Sunday of a given month/year and set time to 02:00
    function findNthSundayAt2AM(year: number, month: number, nth: number): Date {
      let day = new Date(year, month, 1); // Start with the first day of the month
      let count = 0; // Counter for Sundays found
      // Loop until we find the nth Sunday
      while (true) {
        if (day.getDay() === 0) { // Sunday
          count++;
          if (count === nth) {
            // Set the time to 02:00 hours
            day.setHours(2, 0, 0, 0);
            return day;
          }
        }
        day.setDate(day.getDate() + 1); // Go to the next day
      }
    }

    // PDT starts on the second Sunday of March at 02:00 hours
    const startPDT = findNthSundayAt2AM(date.getFullYear(), 2, 2); // March is month 2, zero-based
    // PDT ends on the first Sunday of November at 02:00 hours
    const endPDT = findNthSundayAt2AM(date.getFullYear(), 10, 1); // November is month 10, zero-based

    // Compare the current date to the start and end dates of PDT
    return date >= startPDT && date < endPDT;
  }
}