import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import ItemSetDto from '../dtos/ItemSetDto';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { ColDef } from 'ag-grid-enterprise';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';

@Component({
  selector: 'app-item-set-form-v2',
  templateUrl: './item-set-form-v2.component.html',
  styleUrls: ['./item-set-form-v2.component.sass']
})
export class ItemSetFormV2Component extends BaseDynamicFormComponent implements OnInit {
  ItemSetRecord: ItemSetDto = new ItemSetDto();
  options: any = {
    colors_ref: [],
    patterns_ref: [],
    materials_ref: [],
    styles_ref: []
  };

  deficolumn: ColDef[] = [
    {
      headerName: 'ID',
      field: 'id',
      width: 125,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      sortable: true,
      initialSort: 'desc',
      sortIndex: 0,
      initialSortIndex: 0,
      sort: 'desc',
      sortingOrder: ['asc','desc'],
      unSortIcon: false,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange'
        ]
      },
      resizable: true,
      floatingFilter: true,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      cellRendererParams: {
        entity: 'items'
      },
      cellRenderer: LinkCellRendererComponent,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellEditor: 'agTextCellEditor',
      type: 'editableColumn',
    },
    {
      headerName: 'Color(s)',
      field: 'colors_ref',
      cellRenderer: RefsCellRendererComponent,
      cellRendererParams: {
        entity: 'colors',
        isArray: true,
        noID: true,
      },
      sortable: true,
      unSortIcon: true,
      resizable: true,
      autoHeight: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: this.options?.colors_ref || [],
        valueFormatter: (params: any) => {
          return `${params.value.name} (${params.value.id})`;
        },
        keyCreator: (params: any) => {
          return params.value._id;
        },
        comparator: this.agGridToolsService.nameASC,
        isMultiRefFilter: true,
        populate: true,
        select: '_id id name'
      },
      floatingFilter: true,
    },
    {
      headerName: 'Patterns(s)',
      field: 'patterns_ref',
      cellRenderer: RefsCellRendererComponent,
      cellRendererParams: {
        entity: 'patterns',
        isArray: true,
        noID: true,
      },
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: this.options?.patterns_ref || [],
        valueFormatter: (params: any) => {
          return `${params.value.name} (${params.value.id})`;
        },
        keyCreator: (params: any) => {
          return params.value._id;
        },
        comparator: this.agGridToolsService.nameASC,
        isMultiRefFilter: true,
        populate: true,
        select: '_id id name'
      },
      floatingFilter: true,
    },
    {
      headerName: 'Material(s)',
      field: 'materials_ref',
      cellRenderer: RefsCellRendererComponent,
      cellRendererParams: {
        entity: 'materials',
        isArray: true,
        noID: true,
      },
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: this.options?.materials_ref || [],
        valueFormatter: (params: any) => {
          return `${params.value.name} (${params.value.id})`;
        },
        keyCreator: (params: any) => {
          return params.value._id;
        },
        comparator: this.agGridToolsService.nameASC,
        isMultiRefFilter: true,
        populate: true,
        select: '_id id name'
      },
      floatingFilter: true,
    },
    {
      headerName: 'Style(s)',
      field: 'styles_ref',
      cellRenderer: RefsCellRendererComponent,
      cellRendererParams: {
        entity: 'styles',
        isArray: true,
        noID: true,
      },
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: this.options?.styles_ref || [],
        valueFormatter: (params: any) => {
          return `${params.value.name} (${params.value.id})`;
        },
        keyCreator: (params: any) => {
          return params.value._id;
        },
        comparator: this.agGridToolsService.nameASC,
        isMultiRefFilter: true,
        addEmptyFilters: true,
        populate: true,
        select: '_id id name'
      },
      floatingFilter: true,
      autoHeight: true
    },
    {
      headerName: 'Asset Type',
      field: 'assetType',
      cellRenderer: TextCellRendererComponent,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['Hard Surface', 'HS Recolor', 'Organics', 'Organic Recolors']
      },
      floatingFilter: true,
    },
    {
      headerName: 'Thumbnail',
      field: 'thumbnail_img',
      sortable: false,
      resizable: true,
      width: 135,
      autoHeight: true,
      cellRenderer: ThumbnailCellRendererComponent,
      valueGetter: (params: { data: { thumbnail_ref: { thumbnails: { path: any; }[]; }; }; }) => {
        return params.data.thumbnail_ref.thumbnails && params.data.thumbnail_ref.thumbnails[1] ?
          params.data.thumbnail_ref.thumbnails[1].path :
          'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
      },
    },
    {
      headerName: 'Thumbnail Record',
      field: 'thumbnail_ref',
      cellRenderer: MiscAssetCellRendererComponent,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: {
        filterKey: 'thumbnail_ref',
        isAggregate: true,
        fieldKeys: ['name', 'id', 'path'],
        isMiscBuild: true,
        populate: true,
        select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
      },
      width: 500,
      autoHeight: true,
    },
  ];

  columnSelection: string;

  constructor(
    private validation: BaseInputValidationServiceService,
    private agGridToolsService: AgGridToolsService
  ) {
    super();
  }

  ngOnInit() {
    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.deficolumn);

    this.fields = [
      {
        title: "Item Set Data",
        fields: [
          {
            name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'internalName',
            isRequired: true,
            label: 'Internal Name',
            disabled: false
          },
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: "Set Items",
            inputType: DynamicInputType.BaseInputField,
            clearField: false,
            key: 'items_ref',
            inputTypeFields: [InputFieldType.TableInput],
            isRequired: true,
            label: 'Set Items',
            disabled: true,
            rowData: this.ItemSetRecord,
            entity: 'items',
            isTableInput: true,
            tableInputParams: {
              select: 'id name thumbnail_ref',
              populate: [{
                "path": "thumbnail_ref",
                "select": "_id id name path lastHash image_versions thumbnails buildOutput entityType assetType"
              }]
            },
            deficolumn: this.deficolumn,
            showthumb: true
          },
        ],
        type: DynamicCardType.Card
      },
    ];

    this.title = "Item Set";
    this.viewRoute = 'item-sets';
    this.isLoading = false;
  }
}
