import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HTTP_INTERCEPTORS,
  HttpContextToken,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { SocialUser } from '@abacritt/angularx-social-login';
import { UserContextService } from 'src/app/common/services/user-context.service';

export const SKIP_INTERCEPTOR = new HttpContextToken<boolean>(() => false);


@Injectable({
  providedIn: 'root',
})
export class AuthTokenInterceptor implements HttpInterceptor {
  currentUser: SocialUser;

  constructor
  (
    private authService: AuthService,
    private userContextService: UserContextService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add JWT to auth header.
    if (request.context.get(SKIP_INTERCEPTOR)) {
      return next.handle(request);
    }
    request = request.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getToken()}`
      },
    });
    return next.handle(request);
  }
}

export const authTokenInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthTokenInterceptor,
  multi: true,
};
