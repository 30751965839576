import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';

@Component({
  selector: 'app-climate-table-ag-grid',
  templateUrl: './climate-table-ag-grid.component.html',
  styleUrls: ['./climate-table-ag-grid.component.sass']
})
export class ClimateTableAgGridComponent implements OnInit {

  public entity: string = 'climates';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};
  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    this.tableParams = {
      enableDuplicateFeature: true,
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'climates',
    }

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService, this.tableParams);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Spawn Audios',
        field: 'spawnAudios_ref',
        cellRendererParams: {
          entity: 'miscellaneous-build',
          isArray: true,
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.spawnAudios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audios',
        field: 'loopAudios_ref',
        autoHeight: true,
        cellRendererParams: {
          isArray: true
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loopAudios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Spawn Audio Collections',
        field: 'spawnAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        autoHeight: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.spawnAudioCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audio Collections',
        field: 'loopAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loopAudioCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions() {
    const entities = [
      { ref: 'spawnAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [22] } } },
      { ref: 'loopAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [23] } } },
      { ref: 'spawnAudioCollections_ref', entity: 'audio-collections', query: { type: 1 } },
      { ref: 'loopAudioCollections_ref', entity: 'audio-collections', query: { type: 2 } },

    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }

  }

}
