import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import SeedPackBoxTypesDTO from '../dtos/SeedPackBoxTypesDTO';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-seed-pack-box-type-form',
  templateUrl: './seed-pack-box-type-form.component.html',
  styleUrls: ['./seed-pack-box-type-form.component.sass']
})
export class SeedPackBoxTypeFormComponent extends BaseDynamicFormComponent implements OnInit {

  SeedPackBoxTypesRecord: SeedPackBoxTypesDTO = new SeedPackBoxTypesDTO();
  constructor() {
    super();
  }


  /**
   * Seed Packs Forms V2 Component Initialization
  */

  ngOnInit(){

    this.fields =
    [
      {
        title: "Seed Pack Box Type",
        fields:
        [
          { name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name'
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Seed Pack Boxe Type";
    this.viewRoute = 'seed-pack-box-types';
    this.isLoading = false;
  }


}
