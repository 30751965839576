import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-color-picker-field',
  templateUrl: './color-picker-field.component.html',
  styleUrls: ['./color-picker-field.component.sass']
})
export class ColorPickerFieldComponent implements OnInit {
  @Input() record: any; 
  @Input() field: any;

  constructor() { }

  ngOnInit(): void {
  }

}
