/**
 * Table State DTO
 */
export default class TableState 
{
  /**
   * List of rows selected
   */
  rowSelection: Array<any> = [];
  /**
   * List of columns selected
   */
  columnSelection: Array<any> = [];

  /** Object containing field keys paired with _selection values_: boolean values describing whether an empty field should be filtering for 'empty (true)' or 'notEmpty (false)'. */
  filterSelection: any = {};

  /** Object pairing field keys with parsed *query values*: values that have been formatted for filtering and storage. */
  filters: any = {};

  /** The sort field value */
  sortField: string = 'id';
  /**
   * Sort order value (desc/asc)
   */
  sortOrder: number = -1;
  /**
   * Number of rows
   */
  rows: number = 50;
  /**
   * First value
   */
  first: number = 0;
  /**
   * Table Columns
   */
  columns?: any;
  /**
   * Column Order
   */
  columnOrder?: any;
  /**
   * Table Selection
   */
  selection?: any;
  /**
   * Table filter
   */
  filter?: any;
  /**
   * Custom Column Order
   */
  customColumnsOrder?: Array<any> = [];
}
