
<h1>Bulk Build Search</h1>

<div class="p-grid">
    <div class="p-col-12">
         <!-- CARD: Top Details -->
         <p-card>
            <!-- Search Input -->
            <div class="p-inputgroup">
                <span class="p-float-label p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" id="inputtext-left" pInputText [(ngModel)]="bulkBuildId" (ngModelChange)="getBulkBuilData()"> 
                    <label for="inputtext-left">Search...</label>
                </span>
            </div>
        </p-card>
        <!-- Results -->
        <p-card class="p-mx-2" *ngIf="!isLoading && buildJobs && buildJobs.length > 0">
            <div class="p-grid">
                <div class="p-col-12 p-pr-2">
                    <p-tabView>
                        <p-tabPanel header="{{view}}" *ngFor="let view of views; let i = index">
                            <div class="card" *ngIf="view == 'All'">
                                <bulk-build-data-table [buildJobs]="buildJobs"></bulk-build-data-table>
                            </div>
                            <div class="card" *ngIf="view == 'Queued'">
                                <bulk-build-data-table [buildJobs]="queueBuildJobs"></bulk-build-data-table>
                            </div>
                            <div class="card" *ngIf="view == 'Building'">
                                <bulk-build-data-table [buildJobs]="buildingBuildJobs"></bulk-build-data-table>
                            </div>
                            <div class="card" *ngIf="view == 'Finished'">
                                <bulk-build-data-table [buildJobs]="successBuildJobs"></bulk-build-data-table>
                            </div>
                            <div class="card" *ngIf="view == 'Failed'">
                                <bulk-build-data-table [buildJobs]="failedBuildJobs"></bulk-build-data-table>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </p-card>
    </div>
</div>