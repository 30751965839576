
<div class="container">
    <p-messages
      [style]="{ width: '100%', position: 'absolute', top: 0, left: 0 }"
    ></p-messages>

    <div class="p-grid p-d-flex p-jc-center">
      <i
        *ngIf="isLoading"
        class="pi pi-spin pi-spinner"
        style="font-size: 8rem"
      ></i>
      <div class="p-col-12 p-d-flex p-jc-center" style="height: 50px">
       <h2>
        Compare current QA and Production Gamedata before promoting to production.
       </h2>
      </div>
      <div class="p-col-12 p-d-flex p-jc-center">
        <h5>
         Comparing will create a summary showing important changes between current QA gamedata and PROD gamedata.
        </h5>
      </div>
      <div class="p-col p-d-flex p-jc-center ">
        <button
          icon="pi pi-clone"
          class="p-button-rounded p-button-info"
          pButton
          pRipple
          type="button"
          [disabled]="isLoading"
          (click)="confirmDiffGamedata()"
          label="Compare QA with Prod"
          style="font-size: '1.2rem'"
        >
        </button>
      </div>
      <div class="p-col-12 p-d-flex p-jc-center p-mt-6">
        <button
          icon="pi pi-cloud-upload"
          class="p-button-rounded"
          pButton
          pRipple
          type="button"
          [disabled]="isLoading || !cmsSettings.manualProdPushButtonEnabled"
          (click)="confirmProdGamedataPush()"
          label="Promote to Prod"
          style="font-size: '1.7rem'"
        >
        </button>
      </div>
    </div>
    <!-- Confirm Modal -->
    <p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
</div>
