import { Component, Input, OnInit } from '@angular/core';
import BuildrecordDTO from 'src/app/build-data/dtos/BuildRecord';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'bulk-build-data-table',
  templateUrl: './bulk-build-data-table.component.html',
  styleUrls: ['./bulk-build-data-table.component.sass']
})
export class BulkBuildDataTableComponent implements OnInit 
{
  @Input() buildJobs: Array<BuildrecordDTO> = []; 
  get buildTypes(): typeof BuildType 
  {
    return BuildType;
  }

  constructor() { }

  /**
   * Bulk Build Data Table Component Initialization
   */
  ngOnInit()
  {
  }

  /**
   * Retrieves the color from the build status value.
   *
   * @param status Build status.
   */
  getColorFromBuildStatus(status: string) 
  {
    status = status.toLowerCase();
    let output = '';
    switch (status) {
      case 'finished':
        output = 'var(--primary-color)';
        break;
      case 'failed':
        output = 'var(--pink-600)';
        break;
      case 'building':
        output = 'var(--yellow-600)';
        break;
      case 'queued':
        output = 'var(--gray-400)';
        break;
    }
    return output;
  }
 
  /**
   * Sets the plaform name to display on the detailed data
   * table.
   *
   * @param platform The current platform.
   */
  setPlatformName(platform: string)
  {
    switch (platform)
    {
      case 'and':
        return 'Android';
      case 'ios':
        return 'IOS';
      case 'lin':
        return 'Linux';
      case 'mac':
        return 'MAC';
      case 'win':
        return 'Windows';
 
      default:
        return '';
    }
  }
 
 
  /**
   * Sets the build status color.
   *
   * @param status The current status of the build.
   */
  setPlatformStatusColor(status: string)
  {
    status = status.toLowerCase();
    switch (status)
    {
      case 'finished':
        return 'success';
      case 'failed':
        return 'danger';
      case 'building':
        return 'warning';
      case 'queued':
        return 'secondary';
      default:
        return 'secondary';
    }
  }
}