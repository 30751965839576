import { Component, Input, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-bundle-view-table',
  templateUrl: './bundle-view-table.component.html',
})
export class BundleViewTableComponent implements OnInit {

  @Input() entity: string;
  @Input() name: string;
  columnDefs: ColDef[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {}
  autopopulateSelect: string = '';
  options: any = {};
  tableParams: any;
  public bulkUpdateColDefs: any[] = [];

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService
  ) { }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right', // Pin the column to the right
        width: 120, // Set the width of the column
        resizable: true,

      },
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Name',
        field: 'name',
        cellRendererParams: {
          entity: 'collections'
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
      },
      {
        headerName: 'Bundle Asset (IOS)',
        field: 'bundleAsset',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
            onLabel: 'Yes',
            offLabel: 'No',
            isBoolean: true
          },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Bundle Image (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
            onLabel: 'Yes',
            offLabel: 'No',
            isBoolean: true
          },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Bundle Image (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Bundle Asset (AND)',
        field: 'bundleAssetAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
    ];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.tableParams = {
      customName: this.name + " Bundles",
      defaultQuery: {$or: [{bundleAsset: "true"}, {bundleImage: "true"}, {bundleAssetAnd: "true"}, {bundleImageAnd: "true"},]},
      hideAdd: true,
      hideActions: true
    }
  }

  async setOptions(){
  }

}
