import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { Subscription } from 'rxjs';
import { TableCommunicationService } from 'src/app/common/ag-grid-table/services/table-communication.service';

@Component({
  selector: 'app-localized-strings-ag-grid',
  templateUrl: './localized-strings-ag-grid.component.html',
  styleUrls: ['./localized-strings-ag-grid.component.sass']
})
export class LocalizedStringsTableAgGridComponent implements OnInit {
  public entity = 'localization';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect = '';
  public buildParams = {};
  public tableParams = {};
  public displayEditDialog = false;
  public editRecord: any;

  private options = {};
  private editClientStringSubscription: Subscription;

  constructor(
    private agGridToolsService: AgGridToolsService,
    private tableCommunicationService: TableCommunicationService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setOptions();
    this.initializeColumnDefs();
    this.initializeTableParams();
    this.setupSubscriptions();
  }

  private initializeColumnDefs(): void {
    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right',
        width: 120,
        resizable: true,
        cellRendererParams: {
          enableDuplicateFeature: false
        }
      },
      {
        headerName: 'ID',
        field: 'id',
        width: 250,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: false,
      },
      {
        headerName: 'English',
        field: 'Source_en',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'French (France)', 
        field: 'Target_frFR',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'Spanish (Mexico)',
        field: 'Target_esMX', 
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'German',
        field: 'Target_deDE',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'Verified',
        field: 'verified',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Verified',
          offLabel: 'Not Verified',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Verified' : 'Not Verified'
        },
        floatingFilter: true,
      },
      {
        headerName: 'Translation Request',
        field: 'translationRequest',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
      },
    ];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }

  private initializeTableParams(): void {
    this.tableParams = {
      customName: "Localization Strings",
      customButtons: ['addString'],
      hideActions: true,
      hideAdd: true,
    };
  }

  private async setOptions(): Promise<void> {
    // Add options initialization logic here if needed
  }

  private setupSubscriptions(): void {
    this.editClientStringSubscription = this.tableCommunicationService.editClientString$.subscribe((show: any) => {
      this.displayEditDialog = show.display;
      this.editRecord = show.record;
    });
  }

  public hideStringDialog(): void {
    this.displayEditDialog = false;
  }
}
