<!-- Dialogs -->

<!-- View permissions -->
<p-dialog [(visible)]="showPermissionDetails" [style]="{ width: '60%' }" [modal]="true" styleClass="p-fluid">
  <p-messages></p-messages>
  <ng-template pTemplate="header">
    <h3>{{ role.name }} Permissions</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <p-table [value]="role.permissions" styleClass="p-datatable-gridlines" responsiveLayout="scroll" [rowHover]="true"
      dataKey="id" [scrollable]="true" scrollHeight="600px">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            Name
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="entity">
            Entity
            <p-sortIcon field="entity"></p-sortIcon>
          </th>
          <th pSortableColumn="abilities">
            Abilities
            <p-sortIcon field="abilities"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-permission>
        <tr>
          <td>{{ permission.name }}</td>
          <td>{{ entities[permission.entity] }}</td>
          <td>
            <ul style="list-style-type:none">
              <li *ngFor="let ability of permission.abilities">{{ ability }}</li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
      (click)="showPermissionDetails = false"></button>
  </ng-template>
</p-dialog>