<div class="container">
  <p-messages
    [style]="{ width: '100%', position: 'absolute', top: 0, left: 0 }"
  ></p-messages>
  <div class="p-grid">
    <div class="card p-col-12 p-mb-4">
      <div class="p-flex" style="display: flex; justify-content: center">
        <h3>
          Please make sure that there are no active or waiting jobs in:
          <a [ngStyle]="{ color: 'var(--primary-color)' }" href="https://prod.cms.flora.bgamestudios.com/api/admin/queues/queue/build?status=active" target="_blank">Bull Dashboard Queue</a>
        </h3>
      </div>
    </div>
    <div class="card p-col-12">
      <div class="p-flex" style="display: flex; justify-content: center">
        <button
          pButton
          pRipple
          type="button"
          class="p-button-rounded"
          [disabled]="!syncResourcesButtonEnabled"
          (click)="syncResources()"
          label="Sync Resources for Build"
          style="font-size: '2rem'"
        >
        </button>
      </div>
    </div>
  </div>
  <i
    *ngIf="isLoading"
    class="pi pi-spin pi-spinner"
    style="font-size: 8rem"
  ></i>
</div>
