    <p-orderList
    [value]="coOpRecord['goal_images_ref']"
    [listStyle]="{ 'max-height': '18rem' }"
    header="Team Colors"
    [dragdrop]="true"
    (onReorder)="reOrderLists($event); this.onRecordChange(this.field);">
    <ng-template pTemplate="header">
        <div class="p-clearfix">
            <span>Goal Images</span>
            <p-button label="Add Goal Image" class="p-ml-5" icon="pi pi-plus" (click)="addGoalImage(); this.onRecordChange(this.field);"></p-button>
        </div>
    </ng-template>
    <ng-template let-goal let-i="index" pTemplate="item">
        <div class="goal-item p-grid">
            <!-- Display the goal image here -->
            <!-- Multi-select for the goal -->
            <div class="p-col-10">
            <app-base-input-field 
                [(fieldValue)]="coOpRecord['goal_images_ref'][i]"
                [record]="coOpRecord" [isEdit]="true" [field]="field"
                [inputTypeFields]="field.inputTypeFields" [isRequired]="field.isRequired" [dynamicForm]="this"
                [options]="options['goal_images_ref']"
                (fieldValueChange)="field.touched = true; onRecordChange($event);"
                >
            </app-base-input-field>
            </div>
            <div class="p-col-2">
                <p-button label="Remove Goal Image(s)" [ngStyle]="{'margin-left': 'auto'}" icon="pi pi-times" styleClass="p-button-danger p-button-sm" (click)="removeGoalImage(i)"></p-button>
            </div>
            </div>
    </ng-template>
</p-orderList>
