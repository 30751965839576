import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  private readonly apiExternal : string = '/api/sourcing-groups';
  constructor(private http: HttpClient) {}

  /**
   * Upload Sourcing Items to collection
   * 
   * @param payload List of sourcing items and Collection Id
   */
  async uploadToCollection(payload: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.apiExternal}/sourcing-items/upload-to-collections`, payload));
  }
  /**
   * Upload Sourcing Items to collection
   * 
   * @param payload List of sourcing items and Collection Id
   */
  async createCollection(payload: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.apiExternal}/sourcing-items/create-collection`, payload));
  }
}
