import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AchievementService
{
  private readonly apiURLprefix : string = '/api/achievements';
  constructor(private http: HttpClient) {}

  /**
   * Updates a currency record.
   *
   * @param AchievementId Id of the currrency to update.
   * @param AchievementData Currency data to be updated.
   */
  async updateAchievement(AchievementId: any, AchievementData: any): Promise<any>
  {
    return await this.http.patch(`${this.apiURLprefix}/update/${AchievementId}`, AchievementData).toPromise();
  }
}
