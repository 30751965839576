import { AuthGuard } from "../auth/auth.guard";
import { CronScheduleTableAgGridComponent } from "../cms-v2/entities/cron-schedule/components/cron-schedule-ag-grid/cron-schedule-table-ag-grid.component";
import { CronScheduleFormV2Component } from "../cms-v2/entities/cron-schedule/components/cron-schedule-form-v2/cron-schedule-form-v2.component";
import { CronScheduleViewV2Component } from "../cms-v2/entities/cron-schedule/components/cron-schedule-view-v2/cron-schedule-view-v2.component";

export const patternsRoutes = {
  prefix: 'cron-schedules',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: CronScheduleTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: CronScheduleFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: CronScheduleViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: CronScheduleFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};