<ul *ngIf="params.key == 'stageCosts'">
  <li *ngFor="let v of params.data.costs_ref; let i = index">
 <strong>Stage {{i + 1}}: </strong> <br /> {{v[0].id.name}}: {{v[0].c}}
  </li>
</ul>
<ul *ngIf="params.key == 'stageNames'">
<li *ngFor="let v of params.data.stages_ref; let i = index">
<strong>Stage {{i + 1}}: </strong> <br /> {{v.name}}
</li>
</ul>
<ul *ngIf="params.key == 'stageImagery'">
<li *ngFor="let v of params.value; let i = index">
<strong>Stage {{i + 1}}: </strong>
<br /> <strong>Transition:</strong> {{v.transition}}
<br /> <strong>Stage:</strong> {{v.stage}}
<br /> <strong>Banner:</strong> {{v.banner}}
</li>
</ul>