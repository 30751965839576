import { LoggerService } from './common/services/logger.service';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { MenuItem, MessageService } from 'primeng/api';
import { ThemeService } from '../app/services/theme.service';
import { AuthService } from './auth/auth.service';
import { Title } from '@angular/platform-browser';
import { UtilitiesService } from './common/services/utilities.service';
import { fromEvent, debounceTime, distinctUntilChanged, map, firstValueFrom } from 'rxjs';
import { DataService } from './services/data.service';
import { environment } from 'src/environments/environment';
import { SettingsService } from './entities/settings/services/settings.service';
import { MenuService } from './routes/menu/menu.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  menuItems: MenuItem[];
  sideMenuItems: any[] = [];
  user: SocialUser | undefined = this.authService.getSocialUser()?.currentUser;
  loggedIn: Boolean = this.authService.getLoggedIn();
  GoogleLoginProvider = GoogleLoginProvider;
  isMenuFixed: boolean = false;
  isScrolling: boolean = false;
  selectedEnvironment: any

  storeTransferCount: number;

  cart: any = {
    sourceEnv: 'dev',
    destinationEnv: 'qa',
    listings: [],
    pricePoint: [],
  }
  enableTransfer: any = true;



  constructor(
    public http: HttpClient,
    public socialAuthService: SocialAuthService,
    public authService: AuthService,
    private themeService: ThemeService,
    private router: Router,
    private logger: LoggerService,
    private titleService: Title,
    private utilitiesService: UtilitiesService,
    private renderer: Renderer2,
    private messageService: MessageService,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private dataService: DataService,
    private settingsService: SettingsService,
    private menuService: MenuService  ) {
    fromEvent(window, 'scroll')
      .pipe(
        debounceTime(30),
        map(() => window.scrollY || window.pageYOffset),
        distinctUntilChanged()
      )
      .subscribe(scrollY => {
        this.handleScroll(scrollY);
      });
  }

  title = 'Flora CMS';

  setDocTitle(title: string) {
    this.logger.log('current title >> ' + this.titleService.getTitle());
    this.titleService.setTitle(title);
  }

  currentTheme: string = '';

  async ngOnInit() {
    this.menuService.mainMenu$.subscribe((updatedMenu: MenuItem[]) => {
      if (!updatedMenu || updatedMenu.length === 0) {
        return;
      }
  
  
      this.menuItems = updatedMenu.map(item => ({
        ...item,
        items: item.items?.length ? item.items.map(subItem => ({
          ...subItem,
          items: subItem.items?.length ? [...subItem.items] : undefined
        })) : undefined
      }));
  
    });
  
    await this.menuService.updateMainMenu();
      setTimeout(async () => {
        await this.menuService.updateMainMenu();
    }, 2000);

    this.sideMenuItems = await this.menuService.fetchSideMenuItems();
    console.log('Loaded side menu items:', this.sideMenuItems);
    const cmsTheme = localStorage.getItem('cmsTheme');
    await this.getGameConfig('qa')

     this.dataService.storeTransferCount$.subscribe(value => {
      this.storeTransferCount = this.getStoreLengths()
    })
    this.currentTheme = cmsTheme ? cmsTheme : 'arya-green';
    this.themeService.setTheme(this.currentTheme);

    const storedEnvironment = localStorage.getItem('selectedEnvironment');
    if (storedEnvironment) {
      this.selectedEnvironment = JSON.parse(storedEnvironment);
    } else {
      // Handle the case where there is no object stored
      this.selectedEnvironment = { label: 'Development', value: 'dev' };
    }

    // auth Service ------
    setTimeout(async () => {
      this.socialAuthService.authState.subscribe(async (user) => {
        setTimeout(async () => {
          if (user) {
            // this.logger.log('user from app', user);
            this.authService.setSocialUser(user);
            this.authService.setLoggedIn(user != null);
            this.authService.loginWithGoogle(user.idToken);
          } else if(this.user) {
            // this.logger.log('there is a session user:', this.user);
            // should validate token's age
            // this.logger.log('verifying token age');
            // await this.authService.evaluateToken();
          } else {
            // force logout if no user object is found.
            this.logger.log('user object is missing. logging out.');
            this.authService.logout();
          }
        }, 500);
      });
    }, 1000);
    // auth Service ------

    if (this.authService.getLoggedIn()) {
      this.authService.loadPermissions().subscribe((permissions) => {
        if (permissions?.length > 0) {
        }
      });
    }

    const currentURL = window.location.href;
    const faviconElement = document.getElementById('favicon') as HTMLLinkElement;

    if (currentURL.includes('prod.cms.flora.bgamestudios.com')) {
      faviconElement.href = 'prod-favicon.ico';
    } else {
      faviconElement.href = 'favicon.ico';
    }

    console.log('Loaded Environment: ', environment);
  }

  toggleMenuExpansion(menuItem: MenuItem) {
    menuItem.expanded = !menuItem.expanded;
  }
  
  onThemeSwitched() {
    switch (this.currentTheme) {
      case 'arya-green':
        this.currentTheme = 'new-green';
        break;
      case 'new-green':
        this.currentTheme = 'arya-green';
        break;
    }
    this.themeService.setTheme(this.currentTheme);
  }

  async getGameConfig(env: any) {
    try {
      // let result = await this.utilitiesService.gameConfig();
      let result = await this.utilitiesService.getGameConfigFromBackend();
      localStorage.setItem('gameConfig', JSON.stringify(result.configs));
    } catch (error) {
      console.error('Error fetching game config:', error);
    }
  }

  sidebarVisible: boolean = false;

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  transferModalVisible: boolean = false;

  toggleTransferModal() {
    this.transferModalVisible = !this.transferModalVisible
  }

  handleScroll(scrollY: number) {
  window.requestAnimationFrame(() => {
    const menuBar = this.el.nativeElement.querySelector('.p-menubar');
    const contentHeight = document.body.scrollHeight; // get the height of the page content
    const screenHeight = window.innerHeight; // get the height of the visible screen
    // console.log('contentHeight', contentHeight);
    // console.log('screenHeight', screenHeight);
    // check if the page content is at least 10% larger than the visible screen size
    if (contentHeight < screenHeight * 1.1) {
      // if it's not, do not fix the menu
      if (this.isMenuFixed) {
        this.renderer.removeClass(menuBar, 'fixed-menu');
        this.isMenuFixed = false;
      }
      return;
    }

    if (scrollY > 0) {
      if (!this.isMenuFixed) {
        this.renderer.addClass(menuBar, 'fixed-menu');
        this.isMenuFixed = true;
      }
    } else {
      if (this.isMenuFixed) {
        this.renderer.removeClass(menuBar, 'fixed-menu');
        this.isMenuFixed = false;
      }
    }
  });
}

  getStoreLengths() {
    this.reloadCart();

    if(this.cart != null || this.cart != undefined) {
       return this.cart.listings?.length + this.cart.pricePoint?.length
    } else {
      return 0
    }
  }

  async storeTransfer() {
    let cart = JSON.parse(localStorage.getItem('storeTransferCart') || 'null')

    if(cart) {
      let pricePointsIds = cart.pricePoint.length > 0 ? cart.pricePoint.map((a:any) => a.id) : []
      if (pricePointsIds.length > 0) {
        try {
          const pricePoints =  await firstValueFrom(this.http.post<any>('/api/store/transfer?entity=price-points&source='+cart.sourceEnv+'&destination='+cart.destinationEnv+'&ids='+pricePointsIds.join(), ''));

          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Price Point Records Transferred Succesfully',
            detail: pricePoints.recordsTransferred + ' Records Transferred Successfully'
          });
        } catch (error) {
          console.error('Error transferring price point records:', error);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Error Transferring Price Point Records',
            detail: 'An error occurred while transferring Price Point Records. Please try again later.'
          })
        }
      }

      let storeListingsIds = cart.listings.length > 0 ? cart.listings.map((a:any) => a.id) : []
      if (storeListingsIds.length > 0) {
        try {
          const storeListings =  await firstValueFrom(this.http.post<any>('/api/store/transfer?entity=store-listings&source='+cart.sourceEnv+'&destination='+cart.destinationEnv+'&ids='+storeListingsIds.join(), ''));
          this.messageService.add(
            {
              sticky: true,
              severity: 'success',
              summary: 'Store Listing Records Transferred Succesfully',
              detail: storeListings.recordsTransferred + ' Records Transferred Successfully'
            });
        } catch (error) {
          console.error('Error transferring store listings:', error);
            this.messageService.add({
              sticky: true,
              severity: 'error',
              summary: 'Error Transferring Store Listings',
              detail: 'An error occurred while transferring Store Listing Records. Please try again later.'
            });
        }

      }
      this.storeTransferCount = storeListingsIds?.length + pricePointsIds?.length;
    }

    const cartEmpy = {
      listings: [],
      pricePoint: [],
      sourceEnv: cart.sourceEnv,
      destinationEnv: cart.destinationEnv
    }

    localStorage.setItem('storeTransferCart', JSON.stringify(cartEmpy))

    this.transferModalVisible = false;
  }

  reloadCart() {
    this.cart = JSON.parse(localStorage.getItem('storeTransferCart') || 'null');
  }

  setEnableTransfer(event: any) {
    this.enableTransfer = event
    this.cdr.detectChanges()
  }

}
