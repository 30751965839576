import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-enterprise';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { GettersService } from 'src/app/common/services/getters.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-season-pass-table-ag-grid',
  templateUrl: './season-pass-table-ag-grid.component.html',
  styleUrls: ['./season-pass-table-ag-grid.component.sass']
})
export class SeasonPassTableAgGridComponent implements OnInit {

  entity: string = 'season-pass';
  columnDefs: ColDef[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {}
  autopopulateSelect: string = '';
  options: any = {};
  public buildParams: any = {};
  public bulkUpdateColDefs: any[] = [];
  public tableParams: any = {};
  id: any;

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) { }

  async ngOnInit(): Promise<void> {

    await this.setOptions();
    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Internal Name',
        field: 'internalName',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        type: 'editableColumn',
        cellEditor: 'agTextCellEditor',
      },
      {
        headerName: 'Free Track',
        field: 'free',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.free,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        autoHeight: true,
        width: 300,
      },
      {
        headerName: 'Paid Track',
        field: 'paid',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.paid,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        autoHeight: true,
        width: 300,
      },
      {
        headerName: 'Imagery',
        field: 'bannerImage',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          entity: 'miscellaneous-build',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          values: this.options.bannerImage,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Background Image',
        field: 'backgroundImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          entity: 'miscellaneous-build',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          values: this.options.backgroundImage_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Midground Image',
        field: 'midgroundImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          entity: 'miscellaneous-build',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          values: this.options.midgroundImage_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Foreground Image',
        field: 'foregroundImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          entity: 'miscellaneous-build',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          values: this.options.foregroundImage_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ]

    this.buildParams = {
      imageKeys: ['bannerImage', 'backgroundImage_ref', 'midgroundImage_ref', 'foregroundImage_ref'],
      imageBuildType: BuildType.Images,

    }

    this.tableParams = {
      customName: "Garden Pass",
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'seasonpasses',
    }


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];
  }

  async setOptions(){
    const entities = [
      { ref: 'bannerImage', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: BuildType.Images } },
      { ref: 'backgroundImage_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: BuildType.Images } },
      { ref: 'midgroundImage_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: BuildType.Images } },
      { ref: 'foregroundImage_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: BuildType.Images } },
      { ref: 'free', entity: 'rewards-track' },
      { ref: 'paid', entity: 'rewards-track' }

    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }
  }

}
