import { MultiSelect } from 'primeng/multiselect';
import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
@Component({
  selector: 'app-climate-form-v2',
  templateUrl: './climate-form-v2.component.html',
  styleUrls: ['./climate-form-v2.component.sass']
})
export class ClimateFormV2Component extends BaseDynamicFormComponent implements OnInit {

  climateRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  /**
   * Climate Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Climate Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name'
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Audio",
        fields:
        [
          {
            name: "Spawn Audio",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'spawnAudios_ref',
            isRequired: false,
            label: 'Spawn Audio',
            labelKeys: ['id', 'name', 'path'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'spawnAudios_ref', apiController: 'miscellaneous-build',
              customQuery: { assetType: { $in: [22] }}, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id',
              isArrayOfStringIds: true
            },
            columnWidth: 6
          },
          {
            name: "Spawn Audio Collection",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'spawnAudioCollections_ref',
            isRequired: false,
            label: 'Spawn Audio Collection',
            labelKeys: ['id', 'name', 'path'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'spawnAudioCollections_ref', apiController: 'audio-collections',
              customQuery: { type: 1 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id',
              isArrayOfStringIds: true
            },
            columnWidth: 6
          },
          {
            name: "Loop Audio",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'loopAudios_ref',
            isRequired: false,
            label: 'Loop Audio',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'loopAudios_ref', apiController: 'miscellaneous-build',
              customQuery: { assetType: { $in: [23] }}, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id _id',
              isArrayOfStringIds: true
            },
            columnWidth: 6
          },
          {
            name: "Loop Audio Collection",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'loopAudioCollections_ref',
            isRequired: false,
            label: 'Loop Audio Collection',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'loopAudioCollections_ref', apiController: 'audio-collections',
              customQuery: { type: 2 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id _id',
              isArrayOfStringIds: true
            },
            columnWidth: 6
          },

        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Climate";
    this.viewRoute = 'climates';
    this.isLoading = false;
  }
}
