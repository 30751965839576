<div [formGroup]="lineItemForm">
  <div formArrayName="lineItems">
    <div
      *ngFor="let lineItem of lineItems.controls; let i = index"
      [formGroupName]="i"
      class="p-p-1"
      style="
        display: flex;
        border-style: solid;
        border-width: 1px;
        border-radius: 6px;
        margin: 5px;
        width: 100%;
      "
    >
    <div *ngIf="this.params.isChallengePrize" style="width: 100%;">
      <h4>{{4 + i}} Star Prize</h4>
    </div>
      <div class="line-item-fields"
        style="
        display: flex;
        flex-direction: column;
        gap: 0.5rem;"
      >
        <p-dropdown
          *ngIf="!params.defaultType || params.defaultType !== 'currencies'"

          formControlName="type"
          placeholder="Resource"

          [options]="options['t']"
          optionLabel="name"
          [showClear]="true"
          [autoDisplayFirst]="false"
          [filter]="true"
          [filterBy]="'name'"

          [style]="{ 'min-width': '12.5em' }"

          (onChange)="getCurrencyOptions($event, i)"
        ></p-dropdown>

        <!-- CURRENCY RESOURCE  -->
        <p-dropdown
          *ngIf="lineItem.get('type').value?.name === 'Currency'"
          formControlName="id"
          placeholder="Currency"
          [options]="options['currencies']"
          optionLabel="name"
          [showClear]="true"
          [required]="true"
          [autoDisplayFirst]="false"
          [filter]="true"
          [filterBy]="'name'"

          [style]="{ 'min-width': '12.5em' }"
        ></p-dropdown>

        <!-- TITLES RESOURCE  -->
        <p-dropdown
          *ngIf="lineItem.get('type').value?.name === 'Titles'"

          formControlName="id"
          placeholder="Title"

          [options]="options['titles']"
          optionLabel="name"
          optionValue="_id"
          [showClear]="true"
          [required]="true"
          [autoDisplayFirst]="false"
          [filter]="true"
          [filterBy]="'name'"

          [style]="{ 'min-width': '12.5em' }"
        ></p-dropdown>

        <!-- ITEMS RESOURCE  -->
        <p-autoComplete
          *ngIf="lineItem.get('type').value?.name === 'Item'"

          [suggestions]="suggestions"
          [showEmptyMessage]="true"
          (completeMethod)="getSuggestedItems($event.query,i) "
          field="name"
          minLength="3"
          formControlName="id"
          placeholder="Search by Name or ID"
        >
          <ng-template let-re pTemplate="selectedRe">
            {{ re.name }} ({{ re.id }})
          </ng-template>

          <ng-template let-re pTemplate="re">
            {{ re.name }} ({{ re.id }})
          </ng-template>
        </p-autoComplete>

        <p-inputNumber
          formControlName="count"
          placeholder="Count"
          min="0"
        ></p-inputNumber>

      </div>
      <div class="line-item-buttons"
        style="
          display: flex;
          gap: 0.5rem;
          justify-content: flex-end;
        "
      >
        <button
          (click)="removeLineItem(i)"
          pButton
          pRipple
          ariaLabel="Delete Lineitem"
          icon="pi pi-trash"
          class="p-button-text p-button-danger p-button-xs p-flex-shrink-0"
          tabindex="-1"
        ></button>
      </div>
    </div>
  </div>
  <button
    (click)="addLineItem()"
    *ngIf="!this.params.isChallengePrize"
    pButton
    pRipple
    ariaLabel="Add LineItem"
    icon="pi pi-plus"
    tabindex="-1"
    style="width: 100%;"
    class="p-button-text p-button-success p-button-xs p-flex-shrink-0"
  ></button>
</div>
