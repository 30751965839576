import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cron-schedule-view-v2',
  templateUrl: './cron-schedule-view-v2.component.html',
  styleUrls: ['./cron-schedule-view-v2.component.sass']
})
export class CronScheduleViewV2Component  extends BaseDynamicViewComponent implements OnInit {
  gamedataScheduleRecord: BaseEntityDto = new BaseEntityDto();

  constructor(private dataService: DataService) {
    super();
  }

  /**
   * Cron Schedule View V2 Initial Implementation
   */
  async ngOnInit() {
    const cronTypes = await this.dataService.getCronTypes();

    this.fields = [{
      title: "Cron Schedule Data",
      fields: [
        {
          name: "Description",
          inputType: DynamicViewType.BaseViewField,
          viewTypes: [BaseViewFieldType.Text],
          clearField: true,
          key: 'description',
          isRequired: true,
          label: 'Description'
        },
        {
          name: "Type",
          inputType: DynamicViewType.BaseViewField,
          viewTypes: [BaseViewFieldType.DropdownWithLabels],
          clearField: true,
          key: 'type',
          label: 'Type',
          isRequired: true,
          options:
          {
            fieldName: 'type',
            values: cronTypes
          },
          columnWidth: 6
        },
        { 
          name: "Days", 
          inputType: DynamicViewType.BaseViewField, 
          viewTypes: [BaseViewFieldType.MultiSelect],
          clearField: true, 
          key: 'days', 
          isRequired: true, 
          label: 'Days',
        },
        {
          name: "Time",
          inputType: DynamicViewType.BaseViewField,
          viewTypes: [BaseViewFieldType.Text],
          clearField: true,
          key: 'time',
          label: 'Time',
          columnWidth: 6
        },
        { 
          name: "Date", 
          inputType: DynamicViewType.BaseViewField,
          viewTypes: [BaseViewFieldType.Calendar], 
          clearField: true, 
          key: 'cronDate', 
          label: 'Date',
          columnWidth: 6,
        },
        { 
          name: "Job Enabled", 
          inputType: DynamicViewType.BaseViewField, 
          viewTypes: [BaseViewFieldType.Boolean],
          clearField: true, 
          key: 'enabled', 
          isRequired: true, 
          label: 'Job Enabled',
          booleanoptions: {
            true: "Yes",
            false: "No"
          }
        },
      ],
      type: DynamicCardType.Card
    }];

    // This will help us to define fields 
    // with pre-established values.
    // This is just an example, there is no 
    // need to add it if it's not necessary.
    this.gamedataScheduleRecord = {
      enabled: false
    };
    this.title = "Cron Schedule";
    this.viewRoute = 'cron-schedules';
    this.isLoading = false;
  }
}