import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';

@Component({
  selector: 'app-misc-asset-cell-renderer',
  templateUrl: './misc-asset.component.html',
  styleUrls: ['./misc-asset.component.sass']
})
export class MiscAssetCellRendererComponent implements ICellRendererAngularComp {

  constructor(
    private tableCommunicationService: TableCommunicationService
  ) {

  }

  public params: any;
  timestamp = new Date().getTime();

  agInit(params: any): void {
    this.params = params;
    // console.log(this.params);
    if(params.isNestedArray) {
      this.params = [params];
    }
  }

  refresh(params: any): boolean {
    return false;
  }


}
