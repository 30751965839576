<p-sidebar
  #filtersSidebar
  [(visible)]="visible"
  (onHide)="onHideEmitter()"
  position="right"
  [modal]="true"
  id="filter-sidebar"
  styleClass="filter-sidebar"
  [style]="{ 'min-width': '24em' }"
>
  <!-- Sidebar Header -->
  <div class="p-d-flex p-flex-row p-ai-center p-jc-between">
    <h3 class="p-m-0">Filters</h3>

    <p-button
      *ngIf="checkedCount > 0"
      ariaLabel="Clear Filters"
      label="Clear Filters"
      class="p-my-auto"
      styleClass="p-button-outlined p-button-sm"
      (click)="onClearAll()"
    ></p-button>
  </div>

  <!-- Filter Sets -->
  <div class="p-my-3">
    <div class="p-d-flex p-jc-center">
      <h5 class="p-my-1 p-mx-auto">Filter Sets</h5>
    </div>

    <p-listbox
      [(ngModel)]="activeSetName"
      [options]="setGroups"
      [group]="true"
      optionValue="name"
      optionGroupLabel="name"
      optionGroupChildren="value"
      emptyMessage="No custom Filter Sets found"
      (onChange)="onSelectSet()"
    >
      <!-- Set Group Header -->
      <ng-template pTemplate="group" let-group>
        <div class="p-d-flex p-fluid p-ai-center p-jc-between">
          <!-- Group Name -->
          <span>
            <i [class]="group.icon + ' p-mr-3'"></i>
            {{ group.name }}
          </span>

          <!-- Group Actions -->
          <span
            *ngIf="!showAddSet && !['globalFilterSets'].includes(group.key)"
          >
            <p-button
              #showImportSet_button
              ariaLabel="Import Filter Set"
              icon="pi pi-arrow-circle-down"
              class="p-p-0"
              styleClass="p-button-rounded p-button-text p-button-info"
              pTooltip="Import Filter Set"
              tooltipPosition="bottom"
              (click)="displayImportFilterModal = !displayImportFilterModal"
            ></p-button>

            <p-button
              #showAddSet_button
              ariaLabel="Add Filter Set"
              icon="pi pi-plus"
              class="p-p-0"
              styleClass="p-button-rounded p-button-text p-button-sm"
              pTooltip="Add Filter Set"
              tooltipPosition="bottom"
              (click)="showAddSet = true"
            ></p-button>
          </span>
        </div>

        <!-- Add Set -->
        <div
          *ngIf="showAddSet && !['globalFilterSets'].includes(group.key)"
          class="p-mt-2 p-inputgroup"
          pTooltip="Name and Value of new set must be unique and cannot be blank."
          tooltipPosition="left"
          showDelay="1500"
        >
          <input
            #setName_input
            type="text"
            pInputText
            ariaLabel="Name of Filter Set"
            placeholder="Name of Filter Set"
            (keyup.enter)="checkForSet(); onAddSet(setName_input.value)"
          />
          <span class="p-buttonset">
            <p-button
              icon="pi pi-times"
              styleClass="p-button-outlined"
              (click)="showAddSet = false"
            >
            </p-button>
            <p-button
              icon="pi pi-check"
              (click)="checkForSet(); onAddSet(setName_input.value)"
            ></p-button>
          </span>
        </div>
      </ng-template>

      <!-- Set Item -->
      <ng-template pTemplate="item" let-set let-index="index">
        <div
          #filterSetItem
          *ngIf="!set.isSelected"
          class="p-d-flex p-jc-between p-ai-center"
          [style]="{ width: '100%' }"
          pDraggable="filters"
          pDroppable="filters"
          dragHandle=".filter-drag-handle"
          (onDragStart)="onDragStart(index)"
          (onDrop)="onDrop('Filter Sets Order', index)"
        >
          <i class="pi pi-bars filter-drag-handle" style="color: #A855F7;"></i>
          <!-- Set Name -->
          {{ set.name }}

          <!-- Set Actions -->
          <span *ngIf="!set.isGlobal">
            <p-button
              ariaLabel="Edit Column Set"
              icon="pi pi-pencil"
              class="p-p-0"
              styleClass="p-button-rounded p-button-text p-button-sm p-button-info"
              pTooltip="Edit Filter Set Name"
              tooltipPosition="bottom"
              (click)="set.isSelected = true; tempSetName = activeSetName"
            ></p-button>

            <p-button
              ariaLabel="Share Filter Set"
              icon="pi pi-share-alt"
              class="p-p-0"
              styleClass="p-button-rounded p-button-text p-button-sm p-button-info"
              pTooltip="Share Filter Set"
              tooltipPosition="bottom"
              (click)="copyFilterSetToClipboard(set.name)"
            ></p-button>

            <p-button
              ariaLabel="Remove Column Set"
              icon="pi pi-trash"
              class="p-p-0"
              styleClass="p-button-rounded p-button-text p-button-sm p-button-danger"
              pTooltip="Delete Filter Set"
              tooltipPosition="bottom"
              (click)="removeSet_overlay.toggle($event, filterSetItem)"
            ></p-button>
          </span>
        </div>

        <!-- Rename Set -->
        <div
          *ngIf="set.isSelected"
          class="p-mt-2 p-inputgroup"
          pTooltip="New set name must be unique and cannot be blank."
          tooltipPosition="left"
          showDelay="1500"
        >
          <input
            [(ngModel)]="tempSetName"
            type="text"
            pInputText
            ariaLabel="Name of Filter Set"
            placeholder="Name of Filter Set"
            (keyup.enter)="checkForSet(); onRenameSet(set)"
          />

          <span class="p-buttonset">
            <p-button
              icon="pi pi-times"
              styleClass="p-button-outlined"
              (click)="set.isSelected = false; tempSetName = ''"
            >
            </p-button>

            <p-button
              icon="pi pi-check"
              (click)="checkForSet(); onRenameSet(set)"
            ></p-button>
          </span>
        </div>
      </ng-template>
    </p-listbox>
  </div>

  <!-- Filter Field Selection -->
  <div class="p-my-3" *ngIf="!isLoading">
    <div class="p-d-flex p-jc-center">
      <h5 class="p-my-1 p-mx-auto">Filter Fields</h5>
    </div>

    <!-- Search for filter field -->
    <div class="p-mb-3">
      <span class="p-input-icon-left" [style]="{ width: '100%' }">
        <i class="pi pi-search"></i>
        <input
          #filterSearch_input
          pInputText
          [style]="{ width: '100%' }"
          placeholder="Search"
          class="p-inputtext-lg p-fluid"
          (input)="onSearchFilters($event.target.value.toLowerCase())"
        />
      </span>

      <div class="p-buttonset">
        <p-button
          ariaLabel="View All Filters"
          icon="pi pi-eye"
          label="All"
          [style]="{ width: '50%' }"
          [styleClass]="
            isAllFields()
              ? 'p-button p-button-sm'
              : 'p-button-outlined p-button-sm'
          "
          (click)="onViewAllFields()"
        ></p-button>

        <p-button
          ariaLabel="View Active Filters"
          icon="pi pi-eye"
          label="Active"
          [style]="{ width: '50%' }"
          [styleClass]="
            isActiveFieldsOnly()
              ? 'p-button p-button-sm'
              : 'p-button-outlined p-button-sm'
          "
          (click)="onViewActiveFieldsOnly()"
        ></p-button>
      </div>
    </div>

    <!-- Filter Field Container -->
    <ng-container *ngFor="let field of fieldsList">
      <app-filter-field
        *ngIf="!isLoading"
        #filterField
        [field]="field"
        [(value)]="fieldValues[field.key]"
        [(checkedState)]="checkedState[field.key]"
        [isTriStateCheckbox]="isTriStateCheckbox(field)"
        [options]="optionsList[field.key]"
        [nestedPresetValues]="nestedPresetValues[field.key]"
        (envExclusionEvent)="onEnvExclusionApplied($event)"
        (apply)="beforeApplyFilter(field)"
      ></app-filter-field>
    </ng-container>
  </div>
</p-sidebar>

<!-- 'Confirm Remove Set' Overlay -->
<p-overlayPanel #removeSet_overlay [dismissable]="true">
  <ng-template pTemplate="content">
    <div class="p-mb-2 p-d-flex p-ai-center">
      <i
        class="pi pi-exclamation-triangle p-mr-2"
        style="color: 'var(--pink-500)'; font-size: 1.5em"
      ></i>
      <h3 class="p-my-0">Confirm</h3>
    </div>

    <div class="p-p-2">
      Are you sure you want to delete the set,
      <strong>{{ activeSetName }}</strong
      >?
    </div>

    <div class="p-mt-3 p-d-flex p-jc-end">
      <p-button
        label="Nope"
        class="p-mx-2"
        styleClass="p-button-outlined"
        (click)="removeSet_overlay.hide()"
      ></p-button>
      <p-button
        label="Yup"
        icon="pi pi-check"
        class="p-mx-2"
        (click)="onRemoveSet(activeSetName); removeSet_overlay.hide()"
      ></p-button>
    </div>
  </ng-template>
</p-overlayPanel>

<!-- 'Import Filter' Modal -->
<p-dialog
  header="Import Filter Set"
  [(visible)]="displayImportFilterModal"
  [modal]="true"
  [style]="{ width: '25vw' }"
  [draggable]="false"
  [resizable]="false"
>
  <span class="p-float-label" style="padding-bottom: 10px">
    <input
      id="float-input"
      type="text"
      pInputText
      [(ngModel)]="importedSetName"
      class="{{ invalidImportedSetName ? 'ng-invalid ng-dirty' : null }}"
    />
    <label for="float-input">Filter Set Name</label>
  </span>
  <textarea
    rows="5"
    cols="30"
    pInputTextarea
    [(ngModel)]="importedSet"
  ></textarea>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="importFilterSet()"
      label="Import Filter Set"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
