import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import DropdownDto from 'src/app/common/dtos/DropdownDto';
import { ValidationsService } from 'src/app/common/services/validations.service';
import PermissionDto from 'src/app/permissions/dtos/PermissionsDto';
import { PermissionsService } from 'src/app/permissions/service/permissions.service';
import UserDto  from 'src/app/user/dtos/UserDto';
import { UserService } from 'src/app/user/service/user.service';
import UserPermissionDto from '../../dto/UserPermissionDto';
import { UserPermissionsService } from '../../service/user-permissions.service';

@Component({
  selector: 'user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.sass'],
  providers:[MessageService]
})
export class UserPermissionsComponent implements OnInit
{
  showAddNewUserPermission: boolean = false;
  users: Array<UserDto> |undefined = [];
  permissions: Array<PermissionDto> | undefined = [];
  userPermissions: Array<UserPermissionDto> | undefined = [];
  userList: Array<DropdownDto> = [];
  permissionsList: Array<DropdownDto> = [];
  newUserPermission =
  {
    userId: "",
    description: "",
    permissionId: ""
  }

  constructor
  (
    private userService: UserService,
    private userPermissionsService: UserPermissionsService,
    private permissionsService: PermissionsService,
    private validationService: ValidationsService,
    private messageService: MessageService,
  ) { }

  /**
   * User Permissions Component Initialization
   */
  async ngOnInit()
  {
    await this.loadUserPermissions();
    await this.loadUsers();
    await this.loadPermissions();
    this.clearMessages();
  }

  /**
   * Load all user permissions
   */
  async loadUserPermissions()
  {
    this.userPermissions = await this.userPermissionsService.findAllUserPermissions();
  }

  /**
   * Load all CMS users
   */
  async loadUsers()
  {
    this.users = await this.userService.getAllUsers();
    if(this.users && this.users.length > 0)
    {
      this.users.forEach(user =>
      {
        this.userList.push({ name: user.name, value: user._id });
      });
    }
  }

  /**
   * Load all permissions
   */
  async loadPermissions(permissionList: Array<PermissionDto> | null = null)
  {
    if(permissionList)
    {
      this.permissions = permissionList;
    }
    else
    {
      this.permissions = await this.permissionsService.getAllPermissions();
    }

    if(this.permissions && this.permissions.length > 0)
    {
      this.permissions.forEach(permission =>
      {
        this.permissionsList.push({ name: permission.name, value: permission._id });
      });
    }
  }

  /**
   * Validates if new user permission is valid.
   */
  validateUserPermission()
  {
    let isValid = true;

    if(this.validationService.isEmpty(this.newUserPermission.userId))
    {
      this.messageService.add({sticky: true, severity: "error", summary: "User Permission Error", detail: "User is required." });
      isValid = false;
    }
    if(this.validationService.isEmpty(this.newUserPermission.description))
    {
      this.messageService.add({sticky: true, severity: "error", summary: "User Permission Error", detail: "Description is required." });
      isValid = false;
    }
    if(this.validationService.isEmpty(this.newUserPermission.permissionId))
    {
      this.messageService.add({sticky: true, severity: "error", summary: "User Permission Error", detail: "Permission is required." });
      isValid = false;
    }

    return isValid;
  }

  /**
   * Create new user permission
   */
  async createNewUserPermission()
  {
    if(this.validateUserPermission())
    {
      try
      {
        let response = await this.userPermissionsService.createUserPermission(this.newUserPermission);

        if(response)
        {
          this.messageService.add({sticky: true, severity: "success", summary: "User Permission Created", detail: "User Permission created successfuly!" });
          await this.loadUserPermissions();
          this.showAddNewUserPermission = false;
          this.newUserPermission =
          {
            userId: "",
            description: "",
            permissionId: ""
          }
        }
      }
      catch (errorResponse: any)
      {
        let errorMessage = errorResponse && errorResponse.status == 403 && errorResponse.error ? errorResponse.error.error : "There was an error creating user permission.";
        this.messageService.add({sticky: true, severity: "error", summary: "User Permission Error", detail: errorMessage });
      }
    }
  }

  /**
   * Clear all messages from service
   */
  clearMessages()
  {
    this.messageService.clear();
  }
}
