import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private readonly apiURLprefix : string = '/api/cms-settings';
  constructor(private http: HttpClient) {}

  /**
   * Updates CMS Setting record by Id
   * 
   * @param settingId Id of the cms settings
   * @param settingsData Request dto with data to update cms setting record
   */
  async updateSetting(settingId: any, settingsData: any): Promise<any> {
    return await this.http.patch(`${this.apiURLprefix}/update/${settingId}`, settingsData).toPromise();
  }

  /**
   * Retrieves a CMS Setting by a given query
   * 
   * @param query Query instruction
   */
  async getSettingByQuery(query: Object) {
    return await this.http.post<any>(`${this.apiURLprefix}/find`, query).toPromise();
  }
}
