<div
  class="p-flex"
  style="min-width: 180px;"
>
  <p-multiSelect
    #multiselectComponent
    [(ngModel)]="value"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [placeholder]="fieldName"
    [options]="options"
    display="chip"
    [filter]="true"
    [autofocusFilter]="true"
    [style]="{'max-width': '400px' }"
  >
    <ng-template let-item pTemplate="item">
        <div class="p-flex align-items-center gap-2">
          {{ item[optionLabel] }} {{item.id ? ' ('+item.id+')' : ''}}
        </div>
    </ng-template>
  </p-multiSelect>

  <button
    pButton
    pRipple
    ariaLabel="Revert Nested Values"
    icon="pi pi-undo"
    class="p-mx-1 p-button-text p-button-xs p-flex-shrink-0"
    tabindex="-1"
    (click)="setOriginalValue()"
  ></button>
  <button
    pButton
    pRipple
    ariaLabel="Clear Field"
    icon="pi pi-minus"
    tabindex="-1"
    class="p-button-text p-button-danger p-button-xs p-flex-shrink-0"
    (click)="clearValue()"
  ></button>
</div>


