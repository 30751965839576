import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArtToolsService
{
  private readonly apiURLprefix : string = '/api/art-tools';
  renderServerURL: string = "https://flora-render.dev.petunia.withbuddies.com/renderserver/test"


  constructor(private http: HttpClient) {}

  /**
   * Sync Resources for Build
   */
  async syncResources(): Promise<any>
  {
    return await this.http.get<any>(`${this.apiURLprefix}/sync-resources`).toPromise();
  }

  generateImagePath(entityId: number, type: string){
    if(type=="items"){
      return `${this.renderServerURL}/2791/1024/${entityId}.jpg`
    } else {
      return `${this.renderServerURL}/${entityId}/1024/0.jpg`
    }
  }
}
