<!-- ACTIONS: 'Render Options' panel -->
<p-panel header="Render Options" *ngIf="selectedRecords.length > 0">
  <div class="p-grid p-p-1">
    <ng-container>
      <!-- Render -->
      <button *ngIf="selectedRecords.length > 0" type="button" pButton pRipple
        icon="pi pi-images" class="p-mx-2"
        pTooltip="Renders selected assets and sends a slack notification with the preview of those assets."
        tooltipPosition="bottom" label="Render Selected Assets" (click)="renderAssets()"></button>
    </ng-container>
  </div>
</p-panel>
