import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonEntityService } from '../../services/common-entity.service';
import { BuildService } from 'src/app/build-data/services/build.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'build-history',
  templateUrl: './build-history.component.html',
  styleUrls: ['./build-history.component.sass']
})
export class BuildHistoryComponent implements OnInit 
{
  @Input() id: any;
  @Input() type: string | undefined;
  entity: any;
  buildsHistory: any;
  isLoading: boolean = true;

  constructor
  (
    private commonEntityService: CommonEntityService,
    private route: ActivatedRoute,
    private buildService: BuildService,
    private dataService: DataService,
  ) { }

  /**
   * Build Histiory Component Initilization
   */
  async ngOnInit()
  {
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    this.type = routeParams.get('entityType')?.toString();

    if(this.id && this.type)
    {
      let response = await this.commonEntityService.findOneWithQuery(this.type, { query: { id: this.id } });

      console.log('response: ', response);
      console.log('this.type: ', this.entity);
      

      if(response && response.id)
      {
        if(this.type != 'miscellaneous-build')
        {
          if(this.type == 'challenges')
          {
            if(response.scene_ref)
            {
              this.entity = response.scene_ref;
            }
          }
          else if(this.type == 'items')
          {
            if(response.prefab_ref)
            {
              this.entity = response.prefab_ref;
            }
          }
        }
        else
        {
          this.entity = response;
        }

        if(this.entity.buildData)
        {
          let response = await this.buildService.getBuildData(this.entity.buildData);

          if(response && response.buildData)
          {
            this.entity.buildData = response.buildData;

            if(this.entity.buildData && this.entity.buildData[0] && this.entity.buildData[0].path)
            {
              let history = await this.dataService.getBuildHistoryData(
                this.entity.buildData[0].path,
                false
              );
              if(history && history.length > 0)
              {
                this.buildsHistory = history[0];
              }
            }
          }
        }
      }
    }
    this.isLoading = false;
  }

  /**
   * Retrieves the color from the build status value.
   *
   * @param status Build status.
   */
  getColorFromBuildStatus(status: string) 
  {
    return this.buildService.getColorFromBuildStatus(status);
  }

  /**
   * Retrieves the color from the build bundle file size based on a treshold.
   *
   * @param status Build status.
   */
  getColorFromFileSize(build: any) 
  {
    let sizeString = this.getSize(build.files);
    sizeString = sizeString.replaceAll(" ","");
    sizeString = sizeString.substring(0,sizeString.lastIndexOf("KB"));
    let size = parseFloat(sizeString);
  
    if(build.path.includes('levels')){
      if ((size > 3000) || (size < 500) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('images/challenge_feed' )){
      if ( (size > 1100) || (size < 100) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('items' )){
      if ( (size > 8000) || (size < 250)){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('images/item_thumbs' )){
      if ( (size > 1100) || (size < 100) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else {
      return 'var(--gray-100)';
    }
  }

  /**
   * returns the value of the first key (angular had trouble doing it.)
   */
  getSize(obj: any)
  {
    return obj[Object.keys(obj)[0]];
  }

  /**
   * parses json to string for angular
   */
  parse(jsonString: any)
  {
    return JSON.stringify(jsonString);
  }
}
