<div [style]="{ margin: '1em' }" *ngIf="entity && !isLoading">
  <div style="display: flex; align-items: center; justify-content: space-between;">
    <div>
      <h1 style="margin: 0 0 1em" class="p-mr-3 p-d-inline">
        {{ entity.name }} ({{ entity.id }})
      </h1>
      <a
        pButton
        pRipple
        label="Edit"
        type="button"
        class="p-d-inline"
        icon="pi pi-pencil"
        href="/{{ type }}/edit/{{ entity.id }}"
      ></a>
    </div>
    <button
      pButton
      pRipple
      label="Localized Values"
      type="button"
      icon="fa fa-language"
      (click)="fetchLocalized()"
    ></button>
  </div>


    <ng-container *ngFor="let field of headerFieldGroup">
      <div class="p-m-1 p-px-2">
        <span class="p-text-bold p-mr-2">{{ field.name }}:</span>
        <span *ngIf="entity[field.key]" class="p-text-normal">{{
          entity[field.key]
        }}</span>
      </div>
    </ng-container>

    <div class="p-grid nested-grid p-mt-3">
      <!-- Main Column (left) -->
      <div class="p-col-12 p-lg-8">
        <!-- Main Status Indicators -->
        <app-main-status-display [mainStatusGroup]="mainStatusGroup" [entity]="entity"></app-main-status-display>
        <!-- Master Build Status -->
        <ng-container *ngFor="let field of secondaryStatusGroup">
          <span
            *ngIf="field.controlType === 'buildStatus'"
            class="p-grid p-text-center p-d-lg-none"
          >
            <div
              *ngIf="entity[field.key] && entity[field.key].buildStatus"
              class="p-col p-mx-2 p-mt-3"
              [ngStyle]="{
                position: 'relative',
                color: 'var(--primary-color-text)',
                'background-color':  entity[field.key]
                ? entity[field.key].buildStatus.color
                : '',
                'border-radius': '0.5em'
              }"
            >
              <ng-container [ngSwitch]="entity[field.key].buildStatus.text">
                <ng-container *ngSwitchCase="'n/a'">
                  <h3 class="p-m-0 p-text-center">Not Found</h3>
                </ng-container>

                <ng-container *ngSwitchDefault>
                  <h3 class="p-d-inline p-m-0 p-text-center">
                    {{ entity[field.key].buildStatus.text }}
                  </h3>
                  <p class="p-d-inline">
                    {{ entity[field.key].buildStatus.date }}
                  </p>

                  <p-badge
                    [value]="entity[field.key].buildStatus.buildCount"
                    [style]="{
                      'background-color':  entity[field.key]
                      ? entity[field.key].buildStatus.color
                      : '',
                      position: 'absolute',
                      right: '20%',
                      top: '-1em'
                    }"
                  ></p-badge>
                </ng-container>
              </ng-container>
            </div>
          </span>
        </ng-container>

        <!-- Main Body Cards -->
        <p-card *ngFor="let card of cards" class="p-mx-2 p-shadow-4">
          <ng-template pTemplate="header">
            <div class="p-d-flex p-pt-3 p-pl-3">
              <span class="p-card-title">{{ card.name }} </span>
            </div>
            <ng-container *ngIf="card.name == 'Build Data'">
              <!-- Build and Render -->
              <div style="margin: 15px;">
                <div>
                    <h5 class="p-d-inline p-mr-2">Render Asset? <i pTooltip="Renders the asset once it has been successfully built, and a slack notification is sent to preview the asset." class="pi pi-info-circle" style="font-size: 12px"></i></h5>
                    <p-inputSwitch class="p-d-inline" [(ngModel)]="buildAndRender"></p-inputSwitch>
                </div>
              </div>

              <!-- Build Actions -->
              <div class="p-d-flex p-pl-3 p-pr-3 p-pb-3">
                <build-asset
                  class="p-mr-auto"
                  [entity]="entity"
                  [path_ref]="entity.prefab_ref"
                  [type]="type"
                  assetType="asset"
                  (triggerCompleteEvent)="updateBuildData($event)"
                  label="Build Item"
                  [renderAsset]="buildAndRender"
                  [buildType]="buildType.Items"
                ></build-asset>
                <build-asset
                  class="p-ml-2"
                  [entity]="entity"
                  [path_ref]="entity.thumbnail_ref"
                  type="item-thumbnail"
                  assetType="image"
                  (triggerCompleteEvent)="updateBuildData($event)"
                  label="Build Thumbnail"
                  [buildType]="buildType.Images"
                ></build-asset>
              </div>
              <div class="p-d-flex p-pl-3 p-pr-3 p-pb-3">
                <build-asset
                  class="p-mr-auto"
                  [entity]="entity"
                  [path_ref]="entity.prefab_ref"
                  [type]="type"
                  assetType="asset"
                  (triggerCompleteEvent)="updateBuildData($event)"
                  label="Build Item as High Priority"
                  [buildType]="buildType.Items"
                  [renderAsset]="buildAndRender"
                  [isHighPriority]="true"
                ></build-asset>
                <build-asset
                  class="p-ml-2"
                  [entity]="entity"
                  [path_ref]="entity.thumbnail_ref"
                  type="item-thumbnail"
                  assetType="image"
                  (triggerCompleteEvent)="updateBuildData($event)"
                  label="Build Thumbnail as High Priority"
                  [buildType]="buildType.Images"
                  [isHighPriority]="true"
                ></build-asset>
              </div>
            </ng-container>
            <ng-container *ngIf="card.name == 'History'">
              <app-history [record]="entity" [entityType]="'items'"></app-history>
            </ng-container>
          </ng-template>

          <ng-template pTemplate="content" *ngIf="!isLoading">
            <ng-container *ngFor="let row of card.rows" [ngSwitch]="row.layout">
              <div class="p-grid p-formgrid p-fluid">
                <ng-container *ngSwitchCase="'columns'">
                  <span
                    *ngFor="let column of row.fieldGroups"
                    class="p-col-12 p-sm-6 card-col"
                  >
                    <ul
                      *ngFor="let group of column"
                      style="list-style-type: none"
                    >
                      <li *ngFor="let field of group" class="p-d-flex p-mb-1">
                        <span *ngIf="group.length > 0" class="p-text-bold p-mr-2">
                          {{ field.name }}:
                        </span>
                        <app-data-field
                          [field]="field"
                          [value]="entity[field.key]"
                          [page]="'view'"
                          [entityType]="type"
                          [entity]="entity"
                          [options]="options[field.key] || []"
                        ></app-data-field>
                      </li>
                    </ul>
                  </span>
                </ng-container>

                <ng-container *ngSwitchCase="'rows'">
                  <ul
                    *ngFor="let group of row.fieldGroups"
                    style="list-style-type: none"
                  >
                    <div class="p-col-12">
                      <li *ngFor="let field of group" class="p-d-flex">
                        <span *ngIf="group.length > 0" class="p-text-bold p-mr-2">
                          {{ field.name }}:
                        </span>
                        <app-data-field
                          [field]="field"
                          [value]="entity[field.key]"
                          [page]="'view'"
                          [entityType]="type"
                          [entity]="entity"
                        ></app-data-field>
                      </li>
                    </div>
                  </ul>
                </ng-container>
              </div>
            </ng-container>

            <ng-container *ngIf="card.name == 'Build Data'">
              <p-accordion [multiple]="true">
                <p-accordionTab header="Asset Build Data" [selected]="true">
                  <build-data
                    [parentEntity]="entity"
                    [(entity)]="entity.prefab_ref"
                    [buildData]="entity.prefab_ref && entity.prefab_ref.buildData ? entity.prefab_ref.buildData : null"
                    [type]="type"
                    [buildType]="buildType.Items"
                    (triggerRefreshEvent)="updateBuildData($event)"
                  ></build-data>
                  <app-misc-asset-build-error-logs [recordID]="entity.prefab_ref.id" [field]="entity.prefab_ref"></app-misc-asset-build-error-logs>
                </p-accordionTab>
                <p-accordionTab header="Image Build Data" [selected]="true">
                  <build-data
                    [parentEntity]="entity"
                    [(entity)]="entity.thumbnail_ref"
                    [buildData]="entity.thumbnail_ref && entity.thumbnail_ref.buildData ? entity.thumbnail_ref.buildData : entity.thumbnail_ref"
                    [type]="type"
                    [buildType]="buildType.Images"
                    (triggerRefreshEvent)="updateBuildData($event)"
                  ></build-data>
                  <app-misc-asset-build-error-logs [recordID]="entity.thumbnail_ref.id" [field]="entity.thumbnail_ref"></app-misc-asset-build-error-logs>
                </p-accordionTab>
              </p-accordion>
            </ng-container>
          </ng-template>
        </p-card>
      </div>

      <!-- Secondary Column (right) -->
      <div class="p-col-12 p-lg-4">
        <!-- Secondary Indicators (lg screen) -->
        <app-secondary-status-display [secondaryStatusGroup]="secondaryStatusGroup" [entity]="entity"></app-secondary-status-display>
        <!-- Secondary Body Card (Images) -->
        <p-card class="p-m-2 p-shadow-4">
          <ng-template pTemplate="header">
            <span class="p-d-flex p-pt-3 p-px-3">
              <span class="p-card-title">
                {{ secondaryCard.name }}
              </span>
            </span>
          </ng-template>

          <ng-template pTemplate="content">
            <div class="p-grid">
              <span
                *ngFor="let field of secondaryCard.fields"
                class="p-col-12 p-sm-6 p-lg-12 p-px-1 p-pb-4"
                style="overflow-wrap: anywhere"
              >
                <div *ngIf="field.controlType == 'renderGallery' else elseBlock ">
                  <app-render-gallery
                  [entityId]="entity.id"
                  [assetRecord]="entity.prefab_ref"
                  [type]="type"
                  ></app-render-gallery>
                </div>
                <ng-template #elseBlock>
                <p class="p-mb-1">{{ field.name }}</p>
                <div
                  style="
                    min-width: 225px;
                    max-width: 450px;
                    min-height: 300px;
                    margin-top: 7px;
                  "
                  class="p-d-block p-mx-auto p-px-1"
                >
                  <img
                    *ngIf="entity[field.key]"
                    [lazyLoad]="entity[field.key]"
                    class="p-mx-auto"
                    width="100%"
                    style="border-radius: 20px;"
                    (click)="displayImage(field)"
                  />

                  <p-skeleton
                    *ngIf="!entity[field.key]"
                    width="100%"
                    height="100%"
                  ></p-skeleton>
                </div>
                </ng-template>

              </span>
              <!-- Start of Spruce Card -->
              <app-plant-card [entity]="entity" [plantPreview]="plantPreview"></app-plant-card>
            </div>
          </ng-template>
        </p-card>
      </div>
    </div>
  </div>

  <!-- 'View Image' dialog -->
  <p-dialog
    [(visible)]="showImgDialog"
    [dismissableMask]="true"
    [modal]="true"
    [transitionOptions]="'200ms'"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{ width: '50vw' }"
  >
    <ng-template pTemplate="header">
      <h3 class="p-m-0">{{ imgData.title }}</h3>
    </ng-template>

    <div class="p-text-center">
      <img [src]="imgData.path" width="90%" />
      <h4 class="p-mt-0" width="90%" style="overflow-wrap: anywhere">
        {{ imgData.name }}
        <button
          pButton
          type="button"
          icon="pi pi-copy"
          class="p-button-text p-ml-3 p-mt-1"
          (click)="itemService.copyToClipboard(imgData.path)"
        ></button>
      </h4>
    </div>
  </p-dialog>

  <p-dialog
  [(visible)]="displayLocalizedValues"
  [dismissableMask]="true"
  [modal]="true"
  [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: 'auto', minWidth: '50vw' }"
  [contentStyle]="{ 'overflow': 'visible' }"
>
  <ng-template pTemplate="header">
    <h3 class="p-m-0">Localized Values</h3>
  </ng-template>

  <div *ngIf="this.localizedValues" class="p-text-center" style="overflow: auto;">
    <p-table [value]="localizedValues" [autoLayout]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th *ngFor="let column of getColumns(localizedValues)">
            {{ column }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td style="text-align: justify;">{{ row.id }}</td>
          <td style="text-align: justify;" *ngFor="let column of getColumns(localizedValues)">
            {{ row[column] || 'N/A' }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>



