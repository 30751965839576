import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-input-price',
  templateUrl: './input-price.component.html',
  styleUrls: ['./input-price.component.css']
})
export class InputPriceComponent implements OnInit {
  @Input() record: any;
  @Input() field: any;
  @Input() timestamp = new Date().getTime();

  constructor() {}

  ngOnInit(): void {
  }
}
