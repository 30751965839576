import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../services/settings.service';
import { MessageService, ConfirmationService } from 'primeng/api';
import { DataService } from 'src/app/services/data.service';
import { TableService } from 'src/app/services/table.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { MenuService } from 'src/app/routes/menu/menu.service';

@Component({
  selector: 'app-dynamic-entities',
  templateUrl: './dynamic-entities.component.html',
  styleUrls: ['./dynamic-entities.component.sass'],
  providers: [ConfirmationService, MessageService]
})
export class DynamicEntitiesComponent implements OnInit {
  menuItems: any[] = [];
  selectedItems: any[] = [];
  showJsonDialog: boolean = false;
  selectedItemJson: any = null;
  selectedItemLabel: string = '';
  editableJson: string = '';
  toasterLife: number = 3000;
  sideMenuItems: any[] = [];
  editingIndex: number | null = null;
  isSideMenuEditing: boolean = false;
  isAddSectionModalOpen: boolean = false;
  newSectionName: string = "";
  currentMenuType: 'main' | 'side' | null = null;
  newSectionJson: string;
  

  constructor(
    private settingsService: SettingsService,
    private messageService: MessageService,
    private dataService: DataService,
    private tableService: TableService,
    private loggerService: LoggerService,
    private confirmationService: ConfirmationService,
    private menuService: MenuService
  ) {}

  async ngOnInit() {
    try {
      const menuSettings = await this.settingsService.getSettingByQuery({ query: { name: 'menu' } });

      if (!menuSettings || !menuSettings.mainMenu || !menuSettings.mainMenu.sections) {
        throw new Error("Menu settings are not structured correctly.");
      }

      this.menuItems = menuSettings.mainMenu.sections.map((section: { items: { [s: string]: unknown; } | ArrayLike<unknown>; }) => ({
        ...section,
        expanded: false,
        items: section.items ? Object.values(section.items).map((item: any) => ({
          ...item,
          expanded: false,
          items: item.items ? item.items.map((subItem: any) => ({
            ...subItem,
            expanded: false
          })) : []
        })) : []
      }));


      this.sideMenuItems = menuSettings.sideMenu?.sections?.flatMap((section: { items: any; }) => section.items || []).map((item: { subMenu: { [s: string]: unknown; } | ArrayLike<unknown>; }) => ({
        ...item,
        expanded: false,
        subMenu: item.subMenu ? Object.values(item.subMenu) : []
      }));

    } catch (error) {
    }
  }

  toggleMenuExpansion(menuItem: any) {
    menuItem.expanded = !menuItem.expanded;
    console.log(`Toggled ${menuItem.label}: ${menuItem.expanded}`);
  }


  async saveMenuOrder(menuType: 'main' | 'side') {
    try {
      const menuSettings = await this.settingsService.getSettingByQuery({
        query: { name: 'menu' }
      });

      if (!menuSettings) {
        throw new Error('Menu settings not found');
      }

      const updatedMenu = { ...menuSettings };

      if (menuType === 'main') {
        updatedMenu.mainMenu = {
            sections: this.menuItems.map(section => ({
                label: section.label,
                items: section.items ? [...section.items] : []
            }))
        };
    } else {
        updatedMenu.sideMenu = {
        sections: [{
            items: this.sideMenuItems.map(item => ({
              label: item.label,
              type: item.type,
              icon: item.icon,
              route: item.route,
              action: item.action,
              permission: item.permission,
              subMenu: item.type === 'collapsible' ? item.subMenu?.map((subItem: { label: any; route: any; action: any; permission: any; }) => ({
                label: subItem.label,
                route: subItem.route,
                action: subItem.action,
                permission: subItem.permission
            })) : []
            }))
          }]
        };
      }

      console.log('Saving menu structure:', updatedMenu);
      const response = await this.settingsService.updateSetting(menuSettings.id, updatedMenu);
      console.log(`${menuType} menu order saved successfully:`, response);

      this.messageService.add({
        severity: 'success',
        summary: 'Menu Order Saved',
        detail: `The ${menuType} menu order has been updated successfully.`,
        life: this.toasterLife
      });

    } catch (error: any) {
      console.error(`Error saving ${menuType} menu order:`, error);
      this.messageService.add({
        severity: 'error',
        summary: 'Save Error',
        detail: error.message || `Failed to save ${menuType} menu order.`,
        life: this.toasterLife
      });
    }
  }


  closeAddSectionModal() {
    this.isAddSectionModalOpen = false;
    this.newSectionName = "";
  }

  openAddSectionModal(menuType: 'main' | 'side') {
    this.currentMenuType = menuType;
    this.newSectionJson = ''; 
    this.isAddSectionModalOpen = true;
  }


  addSection() {
    try {
      if (!this.newSectionJson.trim()) {
        throw new Error("The JSON field cannot be empty.");
      }

      const newSection = JSON.parse(this.newSectionJson);

      if (!newSection.label) {
        throw new Error("Invalid JSON: 'label' is required.");
      }

      if (this.currentMenuType === 'main') {
        if (!newSection.items) {
          newSection.items = {};  
        }
        this.menuItems.push(newSection);
      } else if (this.currentMenuType === 'side') {
        if (!newSection.type) {
          throw new Error("Invalid JSON: 'type' is required for Side Menu.");
        }
        this.sideMenuItems.push(newSection);
      }

      this.messageService.add({
        severity: "success",
        summary: "Section Added",
        detail: `Added new section: ${newSection.label}`,
        life: this.toasterLife
      });

      this.saveUpdatedMenu();

      this.isAddSectionModalOpen = false;
    } catch (error: any) {
      this.messageService.add({
        severity: "error",
        summary: "Invalid JSON",
        detail: error.message || "Failed to parse JSON. Please check the format.",
        life: this.toasterLife
      });
    }
  }



  async saveUpdatedMenu() {
    try {
      const menuSettings = await this.settingsService.getSettingByQuery({ query: { name: 'menu' } });

      if (!menuSettings) {
        throw new Error("Menu settings not found.");
      }

      const updatedMenu = {
        ...menuSettings,
        mainMenu: {
          sections: this.menuItems
        },
        sideMenu: {
          sections: [{ items: this.sideMenuItems }]
        }
      };

      delete updatedMenu._id;  

      await this.settingsService.updateSetting(menuSettings.id, updatedMenu);

      this.messageService.add({
        severity: "success",
        summary: "Menu Updated",
        detail: "The new section has been saved successfully.",
        life: this.toasterLife
      });

      await this.menuService.fetchMenuItems();
      await this.menuService.fetchSideMenuItems();
      
    } catch (error: any) {
      this.messageService.add({
        severity: "error",
        summary: "Update Error",
        detail: error.message || "Failed to update the menu.",
        life: this.toasterLife
      });
    }
  }

  showMainMenuItemJson(index: number) {
    this.isSideMenuEditing = false; 
    this.editingIndex = index;
    this.selectedItemJson = { ...this.menuItems[index] };
    this.editableJson = JSON.stringify(this.selectedItemJson, null, 2);
    this.selectedItemLabel = `Edit JSON for "${this.menuItems[index].label}"`;
    this.showJsonDialog = true;
}


  onReorder(event: any) {
    if (event.items && Array.isArray(event.items)) {
      this.sideMenuItems = [...event.items];
      console.log('Updated sideMenuItems after reorder:', this.sideMenuItems);
    } else {
      console.error('Invalid event structure:', event);
    }
  }

  initializeExpandedState() {
    this.sideMenuItems = this.sideMenuItems.map(item => ({
      ...item,
      expanded: false
    }));
  }

  showItemJson(item: any) {
    if (event) {
      event.stopPropagation();
    }
    this.selectedItemJson = { ...item };
    this.selectedItemLabel = `JSON for "${item.label}"`;
    this.showJsonDialog = true;
  }

  copyJsonToClipboard() {
    const jsonStr = JSON.stringify(this.selectedItemJson, null, 2);
    navigator.clipboard.writeText(jsonStr).then(() => {
      this.messageService.add({
        severity: 'success',
        summary: 'Copied',
        detail: 'JSON copied to clipboard',
        life: this.toasterLife
      });
    });
  }




  
  
  saveJsonChanges() {
    try {
      if (this.editingIndex === null) {
        throw new Error("No item selected for editing.");
      }
  
      const updatedJson = JSON.parse(this.editableJson);
  
      if (!updatedJson || !updatedJson.label) {
        throw new Error("Invalid JSON format. Please ensure the structure is correct.");
      }
  
      this.menuItems[this.editingIndex] = updatedJson;
  
      this.settingsService.updateSetting(1, { mainMenu: { sections: this.menuItems } })
        .then(() => {
          this.messageService.add({
            severity: "success",
            summary: "Update Successful",
            detail: "The JSON has been updated successfully.",
            life: this.toasterLife,
          });
          this.showJsonDialog = false;
          this.editingIndex = null; 
        })
        .catch(error => {
          throw new Error(error.message || "Failed to update the JSON.");
        });
    } catch (error: any) {
      console.error("Error updating JSON:", error);
      this.messageService.add({
        severity: "error",
        summary: "Update Error",
        detail: error.message || "Failed to update the JSON. Please check the format.",
        life: this.toasterLife,
      });
    }
  }
  showSideMenuItemJson(item: any) {
      this.isSideMenuEditing = true;
    
      if (item.type === "collapsible") {
        this.selectedItemJson = {
          label: item.label,
          type: item.type,
          permission: item.permission || null,
          subMenu: item.subMenu || []
        };
      } else {
        this.selectedItemJson = { ...item };
      }
    
      this.editableJson = JSON.stringify(this.selectedItemJson, null, 2);
      this.selectedItemLabel = `Edit JSON for "${item.label}"`;
      this.showJsonDialog = true;
    }




  
  
  
saveSideJsonChanges() {
  try {
      if (!this.selectedItemJson) {
          throw new Error("No item selected for editing.");
      }

      const updatedJson = JSON.parse(this.editableJson);

      if (!updatedJson || !updatedJson.label) {
          throw new Error("Invalid JSON format. Please ensure the structure is correct.");
      }

      const index = this.sideMenuItems.findIndex(item => item.label === this.selectedItemJson.label);
      if (index !== -1) {
          this.sideMenuItems[index] = updatedJson;
      } else {
          console.warn("Item not found in sideMenuItems, adding it as a new item.");
          this.sideMenuItems.push(updatedJson);
      }

      this.settingsService.updateSetting(1, { sideMenu: { sections: [{ items: this.sideMenuItems }] } })
          .then(() => {
              this.messageService.add({
                  severity: "success",
                  summary: "Update Successful",
                  detail: "The JSON has been updated successfully.",
                  life: this.toasterLife,
              });
              this.showJsonDialog = false;
              this.selectedItemJson = null; 
          })
          .catch(error => {
              throw new Error(error.message || "Failed to update the JSON.");
          });

  } catch (error: any) {
      console.error("Error updating JSON:", error);
      this.messageService.add({
          severity: "error",
          summary: "Update Error",
          detail: error.message || "Failed to update the JSON. Please check the format.",
          life: this.toasterLife,
      });
  }
}


  
  
  async onReorderSideMenu(items: any[]) {
    console.log("Reorder side menu event triggered:", items);
  
    if (Array.isArray(items) && items.length > 0) {
      this.sideMenuItems = [...items];
  
      console.log("Updated sideMenuItems after reorder:", this.sideMenuItems);
  
      try {
        await this.settingsService.updateSetting(1, { sideMenu: { sections: [{ items: this.sideMenuItems }] } });
        this.messageService.add({
          severity: "success",
          summary: "Menu Order Saved",
          detail: "Side menu order updated successfully.",
          life: this.toasterLife
        });
      } catch (error) {
        console.error("Error saving side menu order:", error);
      }
    }
  }
  
  
  

  async onReorderMainMenu(items: any[]) {
    console.log('Reorder main menu event triggered:', items);
    if (Array.isArray(items) && items.length > 0) {
        this.menuItems = items.map(section => ({
            ...section,
            items: section.items ? [...section.items] : [], 
            expanded: section.expanded || false
        }));
    }
}

    
}

