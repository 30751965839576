<div [style]="{ margin: '1em' }" *ngIf="entity">
  <div class="p-grid nested-grid p-mt-3">
    <div class="p-col-12 p-lg-12">
      <p-card>
        <div [ngSwitch]="this.entity.type">
          <!-- Login Rewards Day 1-7 Type-->
          <div class="p-grid" *ngSwitchCase="1">
            <div class="grid-container">
              <div *ngFor="let dayReward of dailyRewards; let i = index" class="grid-item">
                <app-reward-card *ngIf="dayReward.day !== 7" [title]="'Day ' + dayReward.day"
                  [rewards]="dayReward.rewards"></app-reward-card>
              </div>
              <div class="grid-item-large">
                <app-reward-card [title]="'Day 7'" [rewards]="day7Rewards"></app-reward-card>
              </div>
            </div>
          </div>

          <!-- Login Rewards Day 30 Type -->
          <div class="grid-item-large" *ngSwitchCase="2">
            <app-reward-card [title]="'Day 30'" [rewards]="entity.dailyRewardDay30"></app-reward-card>
          </div>

          <!-- Free Garden Pass Type -->
          <div *ngSwitchCase="3">
            <p-timeline class="p-mt-2" [value]="parsedSeasonPass" align="alternate">
              <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-3">
                  <p-badge [value]="event[0]"></p-badge>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <app-reward-card [title]="'Event ' + event[0]" [rewards]="event[1]"></app-reward-card>
              </ng-template>
            </p-timeline>
          </div>

          <!-- Paid Garden Pass Type -->
          <div *ngSwitchCase="4">
            <p-timeline class="p-mt-2" [value]="parsedSeasonPass" align="alternate">
              <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-3">
                  <p-badge [value]="event[0]"></p-badge>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <app-reward-card [title]="'Event ' + event[0]" [rewards]="event[1]"></app-reward-card>
              </ng-template>
            </p-timeline>
          </div>

          <!-- Friends Invited Track Type -->
          <div *ngSwitchCase="5">
            <p-timeline class="p-mt-2" [value]="parsedSeasonPass" align="alternate">
              <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-3">
                  <p-badge [value]="event[0]"></p-badge>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <app-reward-card [title]="'Event ' + event[0]" [rewards]="event[1]"></app-reward-card>
              </ng-template>
            </p-timeline>
          </div>

          <!-- Endless Offer Type -->
          <div *ngSwitchCase="6">
            <!-- <app-endless-offer-track [rewardTrack]="entity" [parentEntity]="''"></app-endless-offer-track> -->
            <app-endless-offer-track-v2 [eoTrack]="entity" [parentEntity]="''"></app-endless-offer-track-v2>
          </div>

          <!-- Co-Op Rewards Track Type -->
          <div *ngSwitchCase="7">
            <p-timeline class="p-mt-2" [value]="parsedSeasonPass" align="alternate">
              <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-3">
                  <p-badge [value]="event[0]"></p-badge>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <app-reward-card [title]="'Event ' + event[0]" [rewards]="event[1]"></app-reward-card>
              </ng-template>
            </p-timeline>
          </div>

          <!-- ngSwitchCase 8 -->
          <div *ngSwitchCase="8">
            <p-timeline class="p-mt-2" [value]="parsedSeasonPass" align="alternate">
              <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-3">
                  <p-badge [value]="event[0]"></p-badge>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <app-reward-card [title]="'Event ' + event[0]" [rewards]="event[1]"></app-reward-card>
              </ng-template>
            </p-timeline>
          </div>

          <!-- Seasonal Prizes Track Type -->
          <div *ngSwitchCase="9">
            <p-timeline class="p-mt-2" [value]="parsedSeasonPass" align="alternate">
              <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-3">
                  <p-badge [value]="event[0]"></p-badge>
                </span>
              </ng-template>
              <ng-template pTemplate="content" let-event>
                <app-reward-card [title]="'Event ' + event[0]" [rewards]="event[1]"></app-reward-card>
              </ng-template>
            </p-timeline>
          </div>

        </div>
      </p-card>
    </div>
  </div>
</div>
