import { LoggerService } from './logger.service';
import { Injectable } from '@angular/core';
import { ChallengeService } from 'src/app/entities/challenge/services/challenge.service';
import { ItemValidationService } from 'src/app/entities/item/services/item-validation.service';
import { ItemService } from 'src/app/entities/item/services/item.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Validation that can be used in any controller.
 */
export class ValidationsService
{
  constructor(
  ) { }

  /**
   * Validate if a given field is empty
   *
   * @param field The field to validate.
   */
  isEmpty(field: any) {
    let value = typeof field == 'string' ? field.trim() : field;
    return value == undefined || value === null || value.length === 0;
  }

  /**
   * Check if the string is only spaces or not.
   * and returns true if does.
   * @param str the string to evaluate
   */
  stringHasOnlySpaces(str: string): boolean
  {
    return /^\s*$/.test(str);
  }

  /**
   * Checks if the string value already exists on the array.
   * This is to avoid dups.
   *
   * @param stringValue The string value to validate
   * @param collection List of strings
   */
  validateDistinctStringWithinArray(stringValue: string, collection: Array<string>)
  {
    if (stringValue.length > 0)
    {
      return (collection.some(element => element.toLowerCase() === stringValue.toLowerCase()));
    }
    else
    {
      return false;
    }
  };

  /**
   * Checks for any whitespace character including space, tab, carriage return and form feed.
   *
   * @param value String value
   */
  stringHasWhiteSpace(value: string)
  {
    return value.indexOf(' ') >= 0;
  }


  /**
   * Removes all spaces from a string
   * @param value String value
   * @returns String without spaces
  **/
  removeSpaces(str: string): string {
    // console.log('removeSpaces: ', str);
    return str.replace(/\s+/g, '');
  }

  /**
   * Checks if a string possess a / character
   *
   * @param value String value
   */
  stringHasSlash(value: any)
  {
    return value.startsWith("/");
  }


  /**
   * Checks if a value is a valid integer
   *
   * @param value Any / cause we're converting it in case it comes as string
   */
  isInteger(value: any)
  {
    return parseInt(value) ? true : false
  }

  /**
   * Checks if a value is a valid float
   *
   * @param value Any / cause we're converting it in case it comes as string
   */
  isFloat(value: any)
  {
    return parseFloat(value) ? true : false
  }

  /**
   * Checks if an array contains only valid integers for p-chip inputs
   *
   * @param value Any / cause we're converting it in case it comes as string
   */
  isArrayOfNumbers(value: [any]){
    console.log('isArrayOfNumbers: ',value);
    return value.every(element => {
      return this.isInteger(element);
    });
  }

  /**
   * Checks if an array contains only valid float numbers
   *
   * @param value Any cause we're converting it in case it comes as string
   */
  isArrayOfFloatNumbers(value: [any])
  {
    return value.every(number =>
    {
      return this.isFloat(number);
    });
  }

  /**
   * Removes all values that cannot be converted to integers
   *
   * @param value array[any] / cause we're converting it in case it comes as string
   */
  cleanArrayOfNumbers(value: [any]){
    return value.filter(function(element){return !isNaN(parseInt(element))})
  }

  /**
   * Removes all values that cannot be converted to floats
   *
   * @param value array[any] / cause we're converting it in case it comes as string
   */
  cleanArrayOfFloatNumbers(value: [any]){
    return value.filter(function(element){return !isNaN(parseFloat(element))})
  }

  /**
   * Validates Date changes between original and new row for tables
   * Tells if old dates (including re-release dates) were soon to go live or went live
   * Compares newRow with OriginalRow to tell if the values were updated.
   * @param originalRow
   * @param updatedRow
   * @param tableType
   * @returns
   */
  async validateDateChanges(originalRow: any, updatedRow: any, tableType: string){
    let isValidDate = true;

    let originalStart = new Date(originalRow.start);
    let originalEnd = new Date(originalRow.end);
    let originalReStart = new Date(originalRow.reReleaseStart);
    let originalReEnd = new Date(originalRow.reReleaseEnd);

    let newStart = new Date(updatedRow.start);
    let newEnd = new Date(updatedRow.end);
    let newReStart = new Date(updatedRow.reReleaseStart);
    let newReEnd = new Date(updatedRow.reReleaseEnd);

    let currentDate = new Date();
    let messages: Array<string> = [];

    // START DATE EVALUATION
    if((originalRow.start) && (originalStart.getTime() !== newStart.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalStart.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        messages.push(`Original Start date passed ${Math.floor(difference_In_Days)} days ago`);
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        messages.push(`Original Start date is soon to go live in ${difference_In_Hours} hours`);
        isValidDate = false;
      }
    }
    // END DATE EVALUATION
    if((originalRow.end) && (originalEnd.getTime() !== newEnd.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalEnd.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        messages.push(`Original End date passed ${Math.floor(difference_In_Days)} days ago`);
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        messages.push(`Original End date is soon to go live in ${difference_In_Hours} hours`);
        isValidDate = false;
      }
    }

    if(tableType == 'items'){
      // RE-Release START DATE EVALUATION
    if((originalRow.reReleaseStart) && (originalReStart.getTime() !== newReStart.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalReStart.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        messages.push(`Original Re-Release Start date passed ${Math.floor(difference_In_Days)} days ago`);
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        messages.push(`Original Re-Release Start date is soon to go live in ${difference_In_Hours} hours`);
        isValidDate = false;
      }
    }
    // RE-Release END DATE EVALUATION
    if((originalRow.reReleaseEnd) && (originalReEnd.getTime() !== newReEnd.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalReEnd.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        messages.push(`Original Re-Release End date passed ${Math.floor(difference_In_Days)} days ago`);
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        messages.push(`Original Re-Release  End date is soon to go live in ${difference_In_Hours} hours`);
        isValidDate = false;
      }
    }
    }

    let response = [isValidDate, messages]
    console.log(response);
    return response;
  }

  /**
   * Validates challenge type
   * if challenge type is Daily, it should not contain restrictions
   * If it contains restrictions It will throw inalid row.
   * @param row
   * @returns
   */
  async validateChallenge(row:any){
    // if challenge type is daily and contains restrictions we should throw a validation error.
    if(
      row.type_ref && row.type_ref.name && row.type_ref.name == "Daily"
      && (row.restrictions_ref && row.restrictions_ref.length > 0)
    ){
      return [
        false,
        `Challenge ID:${row.id} type is <strong>Daily</strong>, and the challenge contains <strong>${row.restrictions_ref.length}</strong> restrictions. <br>
        Do you wish to continue submitting the form?`
      ]
    } else {
      return [true,]
    }
  }

  /**
   * Validates cost values of an item
   *
   * @param item Item record
   */
  validateCost(item: any)
  {
    let isValid = true;
    if(item && item.costs_ref && item.costs_ref.length > 0)
    {
      item.costs_ref.forEach((cost: any, index: any) =>
      {
        if(cost)
        {
          if(!cost.c)
          {
            item.costs_ref.splice(index, 1);
            isValid = false;
          }
          else if(!cost.id)
          {
            item.costs_ref.splice(index, 1);
            isValid = false;
          }
          else if(!cost.id.id)
          {
            item.costs_ref.splice(index, 1);
            isValid = false;
          }
          else
          {
            isValid = true;
          }
        }
        else
        {
          item.costs_ref.splice(index, 1);
          isValid = false;
        }
      });
      return isValid;
    }
    else
    {
      return true;
    }
  }

  /**
   * validates if env variable values meet requisites based on record values.
   * If env values don't match the needed requisites it will return the error.
   * @param tableType
   * @param value
   * @returns
   */
  async validateEnvPreReqs(tableType: string, row: any){
    var challengeRules =  {
      'dev': {
        rules:['First Succesful build'],
        status: true,
      },
      'qa': {
        rules:['Start date has a value'],
        status: true,
      },
      'prod': {
        rules:['Challenge has both 4.0 and 5.0 Prizes', "Has a valid 'qa' environment flag"],
        status: true,
      },
    }
    var itemRules = {
      'dev': {
        rules:['Item is enabled', 'itemStatus is QA Ready or Approved', 'vendorStatus is Approved'],
        status: true,
      },
      'qa': {
        rules:['Item is enabled', 'Item has costs', 'itemStatus is Approved', 'vendorStatus is Approved', 'Start and End dates have values', 'item is NOT flagged on hold'],
        status: true,
      },
      'prod': {
        rules:['Item is enabled', 'Item has costs', 'itemStatus is Approved', 'vendorStatus is Approved', 'Start and End dates have values', 'item is NOT flagged on hold'],
        status: true,
      },
    }
    var rules: any, envCheck: any;

    if(tableType=="items"){
      rules = itemRules;
      envCheck = await this.checkEnvFlagsRequirementsItem(row);
    } else if(tableType=="challenges") {
      rules = challengeRules;
      envCheck = await this.checkEnvFlagsRequirementsChallenge(row);
    } else {
      return [false, 'Invalid Table Tye for env. value validations'];
    }

    rules.dev.status = envCheck.devReady;
    rules.qa.status = envCheck.qaReady;
    rules.prod.status = envCheck.prodReady;

    if(!envCheck.devReady || !envCheck.qaReady || !envCheck.prodReady)
    {
      return [false,rules];
    } else {
      return [true,];
    }
  }

  /**
   * Validate if Item meets Environment Flags Requirement
   *
   * @param item Item record.
   */
  async checkEnvFlagsRequirementsItem(item: any)
  {
    let response =
    {
      devReady: true,
      qaReady: true,
      prodReady: true,
      item:
      {
        itemStatus: item.itemStatus,
        start: item.start,
        vendorStatus: item.vendorStatus,
        enabled: item.enabled,
        flagged: item.flagged,
        costs_ref: item.costs_ref,
        env: item.env
      }
    };
    if(item.env && item.env.length > 0)
    {
      if(item.env.includes('dev'))
      {
        if(item.vendorStatus != 'Approved' || item.enabled !== true)
        {
          response.devReady = false;
        }
      }

      if(item.env.includes('qa') || item.env.includes('prod'))
      {
        if (item.vendorStatus != 'Approved' || item.itemStatus != 'Approved' || (item.start == null || item.start == undefined) || (item.flagged && item.flagged.toLowerCase() == 'hold'))
        {
          if(item.env.includes('qa')){
            response.qaReady = false;

          }
          if(item.env.includes('prod')){
            response.prodReady = false;
          }
        }
        else
        {
          if(item.enabled !== true || !item.costs_ref || (item.costs_ref && item.costs_ref.length == 0))
          {
            response.qaReady = false;
            response.prodReady = false;
          }
        }
      }
    }

    return response;
  }

  /**
   * Validate if Challenge meets Environment Flags Requirements.
   *
   * @param challenge Challenge record.
   */
  async checkEnvFlagsRequirementsChallenge(challenge: any)
    {
      let response =
      {
        devReady: true,
        qaReady: true,
        prodReady: true,
        devAdded: true,
        qaAdded: false,
        prodAdded: false,
      };

      if(challenge.env && challenge.env.length > 0)
      {
        if(challenge.env.includes('qa'))
        {
          if(!challenge.start || !challenge.end)
          {
            response.qaReady = false;
            response.prodReady = false;
          }
          response.qaAdded = true;
        }
        else
        {
          if(challenge.env.includes('prod')){
            response.prodReady = false;
          }
        }

        if(challenge.env.includes('prod'))
        {
          if(!challenge.prizes_ref || challenge.prizes_ref.length == 0)
          {
            response.prodReady = false;
            response.prodAdded = true;
          }

          if(challenge.prizes_ref.length != 2)
          {
            response.prodReady = false;
            response.prodAdded = true;
          }
          response.prodAdded = true;
        }
      }

      return response;
  }

  validateEnvOrder( entity:any){
    if(entity.env){

    let originalEnvArrays = entity.env
    let desiredOrder = ['dev', 'qa', 'prod'];

    originalEnvArrays.sort(function(a:any, b:any) {
      var indexA = desiredOrder.indexOf(a);
      var indexB = desiredOrder.indexOf(b);

      if (indexA === -1) {
        return 1; // Move elements not in the desired order to the end
      }
      if (indexB === -1) {
        return -1; // Move elements not in the desired order to the end
      }

        return indexA - indexB;
      });
    }
  }
}
