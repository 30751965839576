import { Component, OnInit } from '@angular/core';
import FieldData from 'src/app/common/components/base-fields/field-data-dto';
import Columns from 'src/app/common/components/dynamic-table/dtos/Columns';
import SetGroup from 'src/app/common/components/dynamic-table/dtos/SetGroups';
import requiredFieldsDTO from './dto/requiredFieldsDTO';
import TableConfiguration from 'src/app/common/components/dynamic-table/dtos/TableConfiguration';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-table-data',
  templateUrl: './table-data.component.html',
  styleUrls: ['./table-data.component.sass'],
})
export class TableDataComponent implements OnInit {
  // TABLE DETAILS
  title: string = '';
  type: string = '';
  tableConfig: TableConfiguration = new TableConfiguration();

  // Filters
  filtersToRegister: Array<string> = [];
  globalFilters: Array<string> = [];
  minFilters: Array<string> = [];
  filterSetGroups: Array<SetGroup> = [];

  // Fields
  fields: Array<FieldData> = [];
  options: any = {};
  globalColumnSets: Array<any> = [];
  columnSetGroups: Array<SetGroup> = [];

  defaultColumnOrder: Array<string> = [];
  customGlobalColumnSets: Array<any> = [];

  requiredFields: Array<requiredFieldsDTO> = []

  // Data
  isLoading: boolean = true;

  // Default custom filters that are registered for all entities
  defaultFilters: Array<string> = ['empty', 'notEmpty', 'stringIn', 'filterControl_test', 'exclude'];

  // Default columns that most of entities have in common
  defaultFields: Array<FieldData> = [
    {
      key: 'id',
      name: 'ID',
      controlType: 'inputText',
      filterKey: 'id',
      matchMode: 'equals',
      isColumn: true,
      isInputField: false,
      isFilterField: true,
    },
    {
      key: 'name',
      name: 'Name',
      controlType: 'inputText',
      filterKey: 'name',
      matchMode: 'contains',
      isColumn: true,
      isInputField: true,
      isFilterField: true,
    },
    {
      key: 'start',
      name: 'Start',
      controlType: 'date',
      filterKey: 'start',
      matchMode: 'between',
      isColumn: true,
      isInputField: true,
      isFilterField: true,
    },
    {
      key: 'end',
      name: 'End',
      controlType: 'date',
      filterKey: 'end',
      matchMode: 'between',
      isColumn: true,
      isInputField: true,
      isFilterField: true,
    },
    {
      key: 'enabled',
      name: 'Enabled',
      controlType: 'toggle',
      filterKey: 'enabled',
      matchMode: 'stringIn',
      isColumn: true,
      isInputField: true,
      isFilterField: true,
    },
  ];

  get assetTypes(): typeof AssetTypes
  {
    return AssetTypes;
  }
  get buildTypes(): typeof BuildType
  {
    return BuildType;
  }

  constructor() {}

  /**
   * Table Data Component Initialization
   */
  ngOnInit() {}
}
