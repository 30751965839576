<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="nurture"
    [formName]="title"
    [(record)]="nurtureRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [showBottonSubmit]="true"
    [hasSubRecord]="true"
    [hasAssetRefComponent]="true"
    (onSubmitSubRecord)="beforeSubmitNurture($event)"
    [autopopulateEntity]="true"
    (onRecordChange)="getId($event)"
    (onFieldChange)="checkNurtureCollection($event)"
    [isSubChildComponent]="isChildComponent"
>
</dynamic-form-v2>
<p-confirmDialog header="Confirmation" width="425"></p-confirmDialog>