import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SourcingItemGroupService
{
  private readonly apiURLprefix : string = '/api/sourcing-groups';
  constructor(private http: HttpClient) {}

  /**
   * Promotes all items tied to a sourcing group.
   *
   * @param sourceGroupId Id of the sourcing group
   */
  async promoteItemSourcingGroup(sourceGroupId: any, payload: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/${sourceGroupId}/promote/items`, payload).toPromise();
  }

  /**
   * Promotes selected sourcing items tied to a sourcing group.
   *
   * @param sourceGroupId Id of the sourcing group
   * @param payload Payload data
   */
  async promoteSelectedSourcingItems(sourceGroupId: any, payload: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/${sourceGroupId}/promote/selected/items`, payload).toPromise();
  }

  /**
   * Retrieve all sourcing groups records
   */
  async getAllSourcingGroupsItems(): Promise<any[] | undefined>
  {
    return await this.http.get<Array<any>>(`${this.apiURLprefix}`).toPromise();
  }

  /**
   * Validate sourcing items
   *
   * @param sourceGroupId Id of the sourcing group
   * @param payload Payload data
   */
  async validateSourcingItems(sourceGroupId: any, payload: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/${sourceGroupId}/validate/sourcing-items`, payload).toPromise();
  }

  /**
   * Validates if Sourcing Item is valid for Filename Generation Script
   * 
   * @param sourceGroupId Id of the Sourcing Group
   * @param paylaod List of sourcing items
   */
  async validateSourcingItemsFileName(sourceGroupId: any, payload: any): Promise<any>
  {
    return await firstValueFrom(this.http.post(`${this.apiURLprefix}/${sourceGroupId}/validate/sourcing-items/fileName`, payload));
  }

  /**
   * Paths Generation Script
   * 
   * @param sourceGroupId Id of the Sourcing Group
   * @param paylaod List of sourcing items
   */
  async createPathsForSourcingItems(sourceGroupId: any, payload: any): Promise<any>
  {
    return await firstValueFrom(this.http.post(`${this.apiURLprefix}/${sourceGroupId}/sourcing-items/path-creation`, payload));
  }

  /**
   * Upload Sourcing Items to collection
   * 
   * @param payload List of sourcing items and Collection Id
   */
  async uploadToCollection(payload: any): Promise<any>
  {
    return await firstValueFrom(this.http.post(`${this.apiURLprefix}/sourcing-items/upload-to-collections`, payload));
  }
  /**
   * Upload Sourcing Items to collection
   * 
   * @param payload List of sourcing items and Collection Id
   */
  async createCollection(payload: any): Promise<any>
  {
    return await firstValueFrom(this.http.post(`${this.apiURLprefix}/sourcing-items/create-collection`, payload));
  }
}
