import { environment } from 'src/environments/environment';
import { EnvironmentService } from 'src/app/common/services/environment.service';

/**
 * Here we can define the common constants within the project.
 * We will be able to use them in any component, and make the change
 * in only one place in case we have to change the value of these.
 */
var env: string;
var isProd: boolean;

export const environments = setEnv();

/**
 * Sets the list of environments by url.
 */
function setEnv()
{

    let envs: Array<string> = [];
    let env = EnvironmentService.getEnvironmentName();

    switch (env)
    {
        case 'local':
            envs = ['dev', 'test', 'qa', 'prod'];
            break;
        case 'development':
            envs = ['dev', 'qa', 'prod'];
            break;
        case 'test':
            envs = ['test'];
            break;
        case 'production':
            envs = ['dev', 'qa', 'prod'];
            break;
    }
    return envs;
}
