import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
@Component({
  selector: 'app-item-history',
  templateUrl: './item-history.component.html',
  styleUrls: ['./item-history.component.sass'],
})
export class ItemHistoryComponent implements OnInit {
  @Input() id: any;
  entity: any;
  type: string = 'items';
  data: any = {};
  rows: any = [];
  columns: any = ([] = []);
  href: any;
  date_title: any;
  value: null;
  action: string;

  _selectedCols: any[];

  constructor(
    private dataService: DataService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  ngOnInit() {
    this.href = this.router.url;
    // console.log(this.router.url);

    this.dataService.getHistory(this.href).subscribe(async (data) => {
      console.log('History', data);
      this.entity = data;
      // console.log('Checking Entity', data);
      return (this.rows = data);
      console.log(data);
    });
  }

  actionTagColor() {
    console.log(this.data);

    //   if (this.action === 'add') {
    //     return console.log('green button');
    //   } else {
    //     return console.log('nothing to see here');
    //   }
  }
}
