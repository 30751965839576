import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Series DTO
 */
export default class SeriesDto extends BaseEntityDto
{
    /**
     * Array of challenges tied to the serie
     */
    challenges_ref: Array<any> = [];
    /**
     * Array of list of prizes
     */
    prizes_ref: Array<Array<any>> = [];

    /**
     * Announcement Image
     */
    announcement_image: any;
    /**
     * Copy: message that client will use to present the serie to users
     */
    copy: string;
    /**
     * List of colors
     */
    colors: Array<string> = [];

    /**
     * collection Field
     */
     collection_ref: any;
}