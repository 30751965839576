import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-color-form-v2',
  templateUrl: './color-form-v2.component.html',
  styleUrls: ['./color-form-v2.component.sass']
})
export class ColorFormV2Component  extends BaseDynamicFormComponent implements OnInit
{
  colorRecord: BaseEntityDto = new BaseEntityDto();
  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Color Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Color Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            // displayControlType: 'disable',
            // displayControls: {
            //   ['end']: (name: string) => name.length > 5 ? true : false,
            //   ['start']: (name: string) => name.length > 5 ? true : false
            // },
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            isRequired: false,
            clearField: true,
            showField: true,
            key: 'start',
            label: 'Start Date/Time',
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            isRequired: false,
            clearField: true,
            showField: true,
            key: 'end',
            label: 'End Date/Time',
            calendarMinDate: this.colorRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
        }
      ],
      type: DynamicCardType.Card
      },
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.colorRecord =
    {
      enabled: false
    };
    this.title = "Color";
    this.viewRoute = 'colors';
    this.isLoading = false;
  }

  /**
   * Validate Color required field.
   *
   * @param color Color record
   */
  validateColor(color: any)
  {
    let confirmValidationCallback = color && color.name && color.name.length > 0;
    let message = confirmValidationCallback ? 'Valid' : 'Color Name must have a value';

    return {confirmValidationCallback, message}
  }
}
