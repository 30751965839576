<dynamic-view-v2
  *ngIf="!isLoading"
  entityType="store-sync"
  [viewName]="title"
  [record]="StoresyncRecord"
  [fields]="fields"
  [sidebarFields]="sidebarFields"
  [isStoreEntity]="false"
  [showChangesHistory]="false"
></dynamic-view-v2>
