import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-challenge-history',
  templateUrl: './challenge-history.component.html',
  styleUrls: ['./challenge-history.component.sass']
})
export class ChallengeHistoryComponent {
  @Input() entity: any;

  constructor() { }
}
