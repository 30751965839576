import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-restriction-view-v2',
  templateUrl: './restriction-view-v2.component.html',
  styleUrls: ['./restriction-view-v2.component.sass']
})
export class RestrictionViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  RestrictionRecord: BaseEntityDto = new BaseEntityDto();
  record: any;
  constructor()
  {
    super();
  }

  /**
   * Restriction View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Restriction",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "Count", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'count', label: 'Count' },
          { name: "Rule(s)", inputType: DynamicViewType.FilterRules, clearField: true, key: 'rules_ref', label: 'Rule(s)',
            options: { fieldName: 'Rule(s)', }
        },
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.RestrictionRecord =
    {
      enabled: false
    };
    this.title = "Restriction";
    this.viewRoute = 'restriction-v2';
    this.isLoading = false;
  }
}