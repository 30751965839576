<!-- Dialogs -->

<!-- Show roles -->
<p-dialog [(visible)]="showRoleDetails" [style]="{ width: '60%' }" [modal]="true" styleClass="p-fluid">
  <p-messages></p-messages>
  <ng-template pTemplate="header">
    <h3>Roles - {{ user.name }}</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <p-table [value]="user.roles" styleClass="p-datatable-gridlines" responsiveLayout="scroll" [rowHover]="true"
      dataKey="id" [scrollable]="true" scrollHeight="600px">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="name">
            Name
            <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="entity">
            Permissions
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-role>
        <tr>
          <td>{{ role.name }}</td>
          <td>
            <ul>
              <li *ngFor="let permission of role.permissions" class="spaces-li">
                <b>{{ permission.name }}</b>:
                <ul style="list-style-type:none">
                  <li *ngFor="let ability of permission.abilities">{{ ability }}</li>
                </ul>
              </li>
            </ul>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
      (click)="showRoleDetails = false"></button>
  </ng-template>
</p-dialog>