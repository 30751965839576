<p-tabView>
    <p-tabPanel header="Users" *ngIf="authService.hasPermission(['create', 'update'], 'UserController')">
      <app-users></app-users>
    </p-tabPanel>
    <p-tabPanel header="Roles" *ngIf="authService.hasPermission(['create', 'update'], 'RoleController')">
      <app-roles></app-roles>
    </p-tabPanel>
    <p-tabPanel header="Permissions" *ngIf="authService.hasPermission(['create', 'update'], 'PermissionController')">
      <app-permissions></app-permissions>
    </p-tabPanel>
  </p-tabView>