export const ENTITY_FIELD_CONFIGURATIONS: { 
  [key: string]: Array<{ 
    key: string, type: string, 
    label: string,
    controller?: string,
    isStoreValue?: boolean,
    required?: boolean,
    newValue?: boolean,
    isInput?: boolean,
    showDateInputs?: boolean,
    showNameInput?: boolean,
    showTTLInput?: boolean,
    showEnvInput?: boolean,
  duplicateMessage?: string,
    defaultValue?: string[] | boolean,
    envDefaultValues?: string[],
    options?: any[],
    showProductId?: boolean,
    showLinkRef?: boolean,
    onLabel?: string,
    offLabel?: string
  }> } = {
  challenges: [
    { key: "name", type: "nameInput", label: "Name", required: true, isInput: true },
    { key: "scene", type: "text", label: "Scene Path", required: true },
    { key: "image", type: "text", label: "Image Path", required: true },
    { key: "fileName", type: "text", label: "File Name", required: true },
    { key: "climate_ref", type: "single_ref", label: "Climate", controller: "climates" },
    { key: "year", type: "text", label: "Year" },
    { key: "keywords_ref", type: "multiselect_ref", label: "Keywords", controller: "challenges-keywords" },
    { key: "type_ref", type: "single_ref", label: "Challenge Type", controller: "challenge-types" },
    { key: "description", type: "text", label: "Description" },
    { key: "descriptionShort", type: "text", label: "Short Description" },
    { key: "internalNotes", type: "text", label: "Internal Notes" },
    { key: "location", type: "text", label: "Location" },
    { key: "progressionLevel_ref", type: "single_ref", label: "Level Lock", controller: "progression-levels" },
    { key: "sceneType", type: "single_ref", label: "Scene Type", controller: "settings" },
    { key: "tester_ref", type: "single_ref", label: "Tester", controller: "users" },
    { key: "artist_ref", type: "single_ref", label: "Artist", controller: "users" },
    { key: "restrictions_ref", type: "multiselect_ref", label: "Restrictions", controller: "restrictions" },
    { key: "contentHold_ref", type: "multiselect_ref", label: "Content Hold", controller: "settings" },
    { key: "bundleAsset", type: "boolean", label: "Bundle Asset (IOS)" },
    { key: "bundleImage", type: "boolean", label: "Bundle Image (IOS)" },
    { key: "bundleAssetAnd", type: "boolean", label: "Bundle Asset (AND)" },
    { key: "bundleImageAnd", type: "boolean", label: "Bundle Image (AND)" },
    { key: "spawnAudios_ref", type: "multiselect_ref", label: "Spawn Audios", controller: "miscellaneous-build" },
    { key: "loopAudios_ref", type: "multiselect_ref", label: "Loop Audios", controller: "miscellaneous-build" },
    { key: "spawnAudioCollections_ref", type: "multiselect_ref", label: "Spawn Audio Collection", controller: "audio-collections" },
    { key: "loopAudioCollections_ref", type: "multiselect_ref", label: "Loop Audio Collection", controller: "audio-collections" },
  ],
  climates: [
    { key: "name", type: "text", label: "Name" },
    { key: "spawnAudios_ref", type: "multiselect_ref", label: "Spawn Audio", controller: "miscellaneous-build" },
    { key: "spawnAudioCollections_ref", type: "multiselect_ref", label: "Spawn Audio Collection", controller: "audio-collections" },
    { key: "loopAudios_ref", type: "multiselect_ref", label: "Loop Audios", controller: "miscellaneous-build" },
    { key: "loopAudioCollections_ref", type: "multiselect_ref", label: "Loop Audio Collection", controller: "audio-collections" },
    { key: "enabled", type: "boolean", label: "Enabled" },
    { key: "env", type: "multiselect", label: "Env(s)" },
    { key: "start", type: "date", label: "Start" },
    { key: "end", type: "date", label: "End" },
  ],
  'price-points': [
    { key: "name", type: "nameInput", label: "Name", isInput: true },
    { key: "nonConsumable", type: "boolean", label: "Non Consumable", isStoreValue: true },
    { key: "costs_ref", type: "costs", label: "Cost(s)", isStoreValue: true },
    { key: "tags", type: "text", label: "Tag(s)", isStoreValue: true },
    { key: "GoogleCheckoutPetunia", type: "text", label: "Google Checkout Playgami", isStoreValue: true },
    { key: "AppleAppStorePetunia", type: "text", label: "Apple App Store Playgami", isStoreValue: true },
    { key: "PlaygamiPetunia", type: "text", label: "Petunia Playgami", isStoreValue: true },
  ],
  'store-listings-v2': [
    { key: "name", type: "nameInput", label: "Name", isInput: true },
    { key: "displayName", type: "text", label: "Display Name", isStoreValue: true },
    { key: "storeStart", type: "date", label: "Start", isStoreValue: true },
    { key: "storeEnd", type: "date", label: "End", isStoreValue: true },
    { key: "credits_ref", type: "costs", label: "Credit(s)", isStoreValue: true },
    { key: "image_ref", isStoreValue: true, type: "multiselect_ref", label: "Image", controller: "miscellaneous-build" },
    { key: "saleLabel", type: "text", label: "Display Name", isStoreValue: true },
    { key: "description", type: "text", label: "Display Name", isStoreValue: true },
    { key: "pricePoint_ref", type: "single_ref", label: "Price Point", isStoreValue: true, controller: "price-points" },
    { key: "priceLabel", type: "text", label: "Display Name", isStoreValue: true },
    { key: "pricePointText", type: "text", label: "Display Name", isStoreValue: true },
    { key: "useStoreFrontEndTime", type: "boolean", label: "Enabled", isStoreValue: true },
    { key: "useTileUI", type: "boolean", label: "Enabled", isStoreValue: true },
    { key: "displaySubtitle", type: "text", label: "Display Name", isStoreValue: true },
    { key: "backgroundHex", type: "text", label: "Display Name", isStoreValue: true },
    { key: "minimumLevel", type: "text", label: "Display Name", isStoreValue: true },
    { key: "analyticsBucket", type: "text", label: "Display Name", isStoreValue: true },
    { key: "originalStoreListingName", type: "text", label: "Display Name", isStoreValue: true },
    { key: "fallbackStoreListingName", type: "text", label: "Display Name", isStoreValue: true },
    { key: "secondaryStoreListingName", type: "text", label: "Display Name", isStoreValue: true },
    { key: "customerLimit", type: "text", label: "Display Name", isStoreValue: true },
    { key: "tags", type: "text", label: "Tag(s)", isStoreValue: true },
    { key: "CustomerLimitResetFixed", type: "boolean", label: "Enabled", isStoreValue: true },
    { key: "CustomerLimitResetHours", type: "text", label: "Display Name", isStoreValue: true },
  ],
  minigames: [
    { key: "name", type: "nameInput", label: "Name", required: true, isInput: true },
    { key: "costs_ref", type: "costs", label: "Costs" },
    { key: "rewards_ref", type: "costs", label: "Rewards" },
    { key: "banner_ref", type: "single_ref", label: "Banner", controller: "miscellaneous-build" },
    { key: "welcomePopupImage_ref", type: "single_ref", label: "Welcome Popup Image", controller: "miscellaneous-build" },
    { key: "welcomePopupBackground_ref", type: "single_ref", label: "Welcome Popup Background", controller: "miscellaneous-build" },
    { key: "welcomePopupHeader_ref", type: "single_ref", label: "Welcome Popup Header", controller: "miscellaneous-build" },
    { key: "logoImage_ref", type: "single_ref", label: "Logo Image", controller: "miscellaneous-build" },
    { key: "barBackground_ref", type: "single_ref", label: "Bar Background", controller: "miscellaneous-build" },
    { key: "barOverlay_ref", type: "single_ref", label: "Bar Overlay", controller: "miscellaneous-build" },
    { key: "gameIcon_ref", type: "single_ref", label: "Game Icon", controller: "miscellaneous-build" },
    { key: "metadata", type: "text", label: "Metadata" },
    { key: "downloadUrl", type: "text", label: "Download URL" },
    { key: "welcomePopupDescription", type: "text", label: "Welcome Popup Description" },
    { key: "titleLocalizationKey", type: "text", label: "Title Localization Key" },
    { key: "rewardsTrack_ref", type: "single_ref", label: "Rewards Track", controller: "rewards-tracks" },
    { key: "minLevel", type: "number", label: "Minimum Level" },
    { key: "minMoves", type: "number", label: "Minimum Moves" },
    { key: "movesPerRound", type: "array", label: "Moves Per Round" },
    { key: "rewardLoopMultiplier", type: "number", label: "Reward Loop Multiplier" },
    { key: "rewardLoopCap", type: "number", label: "Reward Loop Cap" },
    { key: "type", type: "number", label: "Type" },
    { key: "mainBackground_ref", type: "single_ref", label: "Main Background", controller: "miscellaneous-build" }
  ],
  series: [
    { key: "displayName", type: "nameInput", label: "Internal Name", required: true, isInput: true },
    { key: "name", type: "nameInput", label: "Display Name", required: true, isInput: true },
    { key: "enabled", isInput: true, type: "booleanInput", label: "Enabled", onLabel: "Enabled", offLabel: "Disabled", defaultValue: false },
    { key: "type", label: "Type", type: "simpleDropdown", isInput: true, options: ["Mini Series"]  },
    { key: "start", type: "dateInput", label: "Start", newValue: true, isInput: true },
    { key: "end", type: "dateInput", label: "End", newValue: true, isInput: true },
    { key: "colors", type: "colors", label: "Colors" },
    { key: "copy", type: "text", label: "Internal Notes"  },
    // { key: "isInfiniteChallengeIncluding", type: "boolean", label: "Is Infinite Challenges Enabled" },
    { 
      key: "announcementInbox_ref", 
      type: "singleDup",
      showDateInputs: true, 
      showNameInput: true, 
      showTTLInput: true,
      isInput: true,
      showProductId: true,
      showLinkRef: true,
      label: "Announcement Inbox", 
      controller: "inbox-messages" 
    },
    { 
      key: "inboxMessages_ref", 
      type: "multiselectDups", 
      label: "Inbox Messages", 
      controller: "inbox-messages", 
      isInput: true, 
      showDateInputs: true,
      showNameInput: true,
      showTTLInput: true,
      showProductId: true,
      showLinkRef: true,
      duplicateMessage: "Will create new inbox messages for the new series and ask for new Start and End dates."
    },
    { key: "collection_ref", type: "single_ref", label: "Items Collection", controller: "collections" },
    { key: "collections_ref",
      type: "multiselectDups", 
      isInput: true, 
      showDateInputs: false, 
      label: "Item Collections (V2)", 
      controller: "collections",
      duplicateMessage: "Will create new item collections for the new series and ask for new Start and End dates."
    },
    { key: "rewards_ref", type: "costs", label: "Rewards" },
    { 
      key: "challenges_ref", 
      type: "multiselectDups", 
      label: "Challenges", 
      controller: "challenges", 
      isInput: true, 
      showDateInputs: true,
      showEnvInput: true,
      envDefaultValues: ["dev", "qa"],
      duplicateMessage: "Will create new Challenges for the new series and ask for new Start and End dates."
    },
    { key: "challengeCollection_ref", type: "single_ref", label: "Challenge Collection", controller: "challenge-collections" },
    { key: "secondaryChallengeCollection_ref", type: "single_ref", label: "Secondary Challenge Collection", controller: "challenge-collections" },
    { 
      key: "loadingScreen_ref", 
      type: "multiselect_ref", 
      label: "Loading Screens", 
      controller: "loading-screens", 
      isInput: true,
      duplicateMessage: "Will use the same loading screens and RE-DATE based on the new Series."
    },
    { 
      key: "collectionStoreListings_ref", 
      type: "multiselectDups", 
      label: "Collection Store Listings", 
      controller: "store-listings-v2", 
      isInput: true, 
      showDateInputs: false,
      duplicateMessage: "Will use the same store listings for the new Series."
    },
    { 
      key: "storeListings_ref", 
      type: "multiselectDups", 
      label: "Store Listings", 
      controller: "store-listings-v2", 
      isInput: true, 
      showDateInputs: false,
      duplicateMessage: "Will use the same store listings for the new Series."
    },
    { key: "env", 
      type: "multiselectEnv", 
      label: "Env(s)",
      isInput: true,
      defaultValue: ["dev", "qa"], 
      duplicateMessage: "Default value is dev and qa but can be changed before duplicating." 
    },
  ],
  achievements: [
    { key: "enabled", type: "boolean", label: "Enabled" },
    { key: "env", type: "multiselect", label: "Env(s)" },

    { key: "title", type: "nameInput", label: "Title", isInput: true },
    { key: "subText", type: "nameInput", label: "Sub Text", isInput: true },
    
    { key: "start", type: "date", label: "Start" },
    { key: "end", type: "date", label: "End" },
    { key: "sortingOrder", type: "number", label: "Sorting Order" },
    { key: "isConsecutive", type: "boolean", label: "Is Consecutive" },
    { key: "promoStartDate", type: "date", label: "Promo Start Date" },
    { key: "promoEndDate", type: "date", label: "Promo End Date" },
    { key: "playerDescription", type: "text", label: "Player Description" },
    { key: "interval", type: "number", label: "Interval" },
    { key: "achievementType", type: "number", label: "Achievement Type" },
    { key: "challengeType", type: "single_ref", label: "Challenge Type", controller: "challenge-types" },
    { key: "challengeClimate", type: "single_ref", label: "Challenge Climate", controller: "climates" },
    { key: "challengeResultScore", type: "number", label: "Challenge Result Score" },
    { key: "isSpawnerType", type: "boolean", label: "Is Spawner Type" },
    { key: "isRequired", type: "boolean", label: "Is Required" },
    { key: "minimumIAPCost", type: "number", label: "Minimum IAP Cost" },
    { key: "itemType", type: "single_ref", label: "Item Type", controller: "item-types" },
    { key: "userHasBalance", type: "boolean", label: "User Has Balance" },
    { key: "isDailyGoal", type: "boolean", label: "Is Daily Goal" },
    { key: "goalType", type: "number", label: "Goal Type" },
    { key: "expectedDiamondCost", type: "number", label: "Expected Diamond Cost" },
    { key: "target", type: "array", label: "Target" },
    { key: "link_ref", type: "single_ref", label: "Link", controller: "link-destinations" },
    { key: "isRepetitive", type: "boolean", label: "Is Repetitive" },
    { key: "repeatsDuration", type: "number", label: "Repeats Duration" },
    { key: "repeats", type: "array", label: "Repeats" },
    { key: "rewards_ref", type: "costs", label: "Rewards" },
    { key: "promoRewards_ref", type: "costs", label: "Promo Rewards" },    
  ],
  'endless-offer': [
    { key: "name", type: "nameInput", label: "Name", required: true, isInput: true },
    { key: "internalName", type: "nameInput", label: "Internal Name", required: true, isInput: true },
    { key: "start", type: "dateInput", label: "Start", newValue: true, isInput: true },
    { key: "end", type: "dateInput", label: "End", newValue: true, isInput: true },
    { key: "env", 
      type: "multiselectEnv", 
      label: "Env(s)",
      isInput: true,
      defaultValue: ["dev", "qa"], 
      duplicateMessage: "Default value is dev and qa but can be changed before duplicating." 
    },
    { key: "feedBannerImage_ref", type: "single_ref", label: "Banner Feed Image", controller: "miscellaneous-build" },
    { key: "IAPStoreImage_ref", type: "single_ref", label: "IAP Store Image", controller: "miscellaneous-build" },
    { key: "popupBannerImage_ref", type: "single_ref", label: "Offer Popup Header Banner Image", controller: "miscellaneous-build" },
    { key: "bundleImagery_ref", type: "single_ref", label: "Tile Backgrounds", controller: "miscellaneous-build" },
    { key: "track_v2_ref", type: "single_ref", label: "Reward Track", controller: "rewards-track" },
  ],
  // Add other entities as needed
};