<p-tabView class="p-m-1" [(activeIndex)]="activeTabIndex" (onChange)="onTabChange($event)">
  <ng-container *ngFor="let env of storeEnvs">
    <p-tabPanel [header]="env | uppercase" [disabled]="env !== activeEnv">
      <dynamic-form-v2
        *ngIf="!isLoading"
        entityType="price-points"
        [formName]="title"
        [(record)]="PricePointRecord"
        [fields]="fields"
        [viewRoute]="viewRoute"
        [autopopulateEntity]="true"
        [isStoreEntity]="true"
        [storeEnv]="env"
    >
      </dynamic-form-v2>
    </p-tabPanel>
  </ng-container>
</p-tabView>
