import { Component, Input, OnInit } from '@angular/core';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';

@Component({
  selector: 'app-ag-grid-table-view',
  templateUrl: './ag-grid-table-view.component.html',
  styleUrls: ['./ag-grid-table-view.component.sass']
})
export class AgGridTableViewComponent implements OnInit {
  @Input() fieldOptions: any;
  @Input() fieldKey: string;
  @Input() record: any;
  @Input() isMultiple: boolean = false;
  @Input() isPrize: boolean = false; 
  public columnSelection: string = '';
  public tableParams: any = {};
  @Input() parentViewName: any;
  @Input() customHeight: string | null = null;
  title: string;
  showPopup: boolean = false;
  prizeCounts: { [key: string]: number } = {};

  constructor(private agGridToolsService: AgGridToolsService) { }

  ngOnInit(): void {
    this.title = this.fieldOptions?.title || 'Default Table Title';
    console.log('isMultiple: ', this.isMultiple);
    this.initializeTable();

    if (this.isPrize && Array.isArray(this.record[this.fieldKey])) {
        this.prizeCounts = this.extractCount(this.record[this.fieldKey]);
    }
  }
  private initializeTable(): void {
    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.fieldOptions.columnDefs);
    this.tableParams = {
      defaultQuery: this.getDefaultQuery(),
      hideHeader: this.fieldOptions.hideHeader || true,
      isMultiple: this.isMultiple,
      idEntity: this.isMultiple ? this.transformData(this.record[this.fieldKey]) : null,
    };
    console.log('tableParams: ', this.tableParams);
  }
  private extractCount(records: any[]): { [key: string]: number } {
    if (!records || !Array.isArray(records)) {
      return {};
    }
  
    const countDict: { [key: string]: number } = {};
  
    records.forEach((record: any) => {
      const id = record?.id?._id; 
      const count = record?.c; 
  
      if (id && typeof count === 'number') {
        countDict[id] = count;
      }
    });
    return countDict;
  }
  

  

  private getDefaultQuery(): any {
    const defaultQueryKey = this.fieldOptions.defaultQueryKey;
    if (!defaultQueryKey || !this.record) {
      return null;
    }

    const mapQuery = (key: string) => {
      const data = this.record[key];
      if (!data) return [null];

      if (Array.isArray(data)) {
        return data.map((obj: any) => typeof obj === 'object' && obj._id ? obj._id : obj);
      }

      return [null];
    };

    const queryMappings: { [key: string]: () => any } = {
      'nurtures_ref': () => ({ _id: { $in: mapQuery('nurtures_ref') } }),
      'items_ref': () => ({ _id: { $in: mapQuery('items_ref') } }),
      'itemSet_ref': () => ({ itemSet_ref: this.record._id }),
      'seriesChallenges_ref': () => ({ _id: { $in: mapQuery('challenges_ref') } }),
      'seriesLoadingScreen_ref': () => ({ _id: { $in: mapQuery('loadingScreen_ref') } }),
      'serieStoreListings_ref': () => {
        let ids = [
          ...mapQuery('storeListings_ref'),
          ...mapQuery('collectionStoreListings_ref')
        ];
        return { _id: { $in: ids } };
      },
      'challengeCollection_ref': () => ({ _id: this.record.challengeCollection_ref?._id }),
      'seriesChallengeCollecions': () => {
        let ids:any[] = [];
        if(this.record.challengeCollection_ref && this.record.challengeCollection_ref?._id){
          ids.push(this.record.challengeCollection_ref._id)
        }
        if(this.record.secondaryChallengeCollection_ref && this.record.secondaryChallengeCollection_ref?._id){
          ids.push(this.record.secondaryChallengeCollection_ref._id)
        }
        return { _id: { $in: ids } }
      },
      'collections_ref': () => ({ _id: { $in: this.record.collections_ref || [] } }),
      'seriesInboxMessages_ref': () => {
        let ids: any[] = mapQuery('inboxMessages_ref'); 
        if (this.record.announcementInbox_ref?._id) {
          ids.push(this.record.announcementInbox_ref._id); 
        }
        return { _id: { $in: ids } };
      },
      'seriesRewards_ref': () => ({ _id: { $in: mapQuery('rewards_ref') } }),
    };
    
    let query = queryMappings[defaultQueryKey]?.() || { _id: { $in: mapQuery(defaultQueryKey) } };
    return query;
  }    


  transformData(inputData: any) {
    const identity: any = {};

    inputData.forEach((item: any) => {
      let type;
    
      if (item.t.name === 'Currency') {
        type = 'currencies';
      } else if (item.t.name === 'SeedPackBox') {
        type = 'seed-pack-box';
      } else {
        type = item.t.name.toLowerCase() + 's'; // Default lowercase plural form
      }
    
      const id = item.id.id; // Extract id
    
      if (!identity[type]) {
        identity[type] = [];
      }
    
      identity[type].push(id);
    });
    
    console.log(identity);
    return identity;
    
  }

  openPopup(): void {
    this.showPopup = true;
  }

  closePopup(): void {
    this.showPopup = false;
  }

}
