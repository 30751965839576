import { Component, OnInit } from '@angular/core';
import { TableDataComponent } from '../../../table-data/components/table-data/table-data.component';

@Component({
  selector: 'app-collection-table-v2',
  templateUrl: './collection-table-v2.component.html',
  styleUrls: ['./collection-table-v2.component.sass'],
})
export class CollectionTableV2Component
  extends TableDataComponent
  implements OnInit
{
  constructor() {
    super();
  }

  customQuery: any

  /**
   * Collection Table V2 Component Initialization
   */
  ngOnInit() {
    this.isLoading = true;
    this.title = 'Collections';
    this.type = 'collections';

    this.customQuery = {  "tags": { $nin: 'NUF' }  };

    this.globalFilters = ['id', 'name'];
    this.filtersToRegister = [
      ...this.defaultFilters,
      'stringIn_ref',
      'multiIn_ref',
    ];

    this.fields = [
      ...this.defaultFields,
      {
        key: 'items_ref',
        name: 'Items',
        controlType: 'autoComplete-multi_ref',
        apiController: 'items',
        filterKey: 'items_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'bundleAsset',
        name: 'Bundle Asset (IOS)',
        controlType: 'toggle',
        filterKey: 'bundleAsset',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'bundleImage',
        name: 'Bundle Image (IOS)',
        controlType: 'toggle',
        filterKey: 'bundleImage',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'bundleAssetAnd',
        name: 'Bundle Asset (AND)',
        controlType: 'toggle',
        filterKey: 'bundleAssetAnd',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'bundleImageAnd',
        name: 'Bundle Image (AND)',
        controlType: 'toggle',
        filterKey: 'bundleImageAnd',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'archived',
        name: 'Archived',
        controlType: 'toggle',
        filterKey: 'archived',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: false,
      },
    ];
    this.defaultColumnOrder = [
      'id',
      'name',
      'start',
      'end',
      'enabled',
      'items_ref',
      'bundleAsset',
      'bundleImage',
      'bundleAssetAnd',
      'bundleImageAnd',
      'archived',
    ];
    this.customGlobalColumnSets = [
      {
        name: 'Default',
        value: ['id', 'name', 'start', 'end', 'enabled'],
      },
    ];

    this.tableConfig.customFilterToRegister = this.filtersToRegister;
    this.isLoading = false;
  }
}
