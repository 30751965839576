import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { EntityViewService } from 'src/app/services/entity-view.service'; 
import { LoggerService } from 'src/app/common/services/logger.service';

@Component({
  selector: 'app-gamedatabuildshistory',
  templateUrl: './gamedata-buildshistory.component.html',
  styleUrls: ['./gamedata-buildshistory.component.sass'],
})
export class GamedataBuildsHistoryComponent implements OnInit {
  @Input() id: any;
  buildsHistory: any;
  isLoading: boolean = true;
  history: any;
  params: string;
  gamedataDiff: any;


  constructor(
    private dataService: DataService,
    private logger: LoggerService,
    private router: ActivatedRoute,
  ) {}

  async ngOnInit() {
    let response: any;
    /** Fetch parameters in the URL to validate if the user wants to see the modal */
    this.router.queryParams
    .subscribe(params => {
      if (params.id) {
        this.params = params.id
      }
      
    })

    /** Fetch all the gamedata logs and fetch the individual gamedata log is the parameters exist */
    this.history = await this.dataService.getAllOfTypeAsync('gamedatalogs', {});
    // Map the dates to objects for sorting
    let sortedHistory = this.history.map((obj:any) => { return {...obj, date: new Date(obj.createdAt)}
  });
    // Sort the Dates for most recent
    this.history = sortedHistory.sort((objA: any, objB: any) => objB.date.getTime() - objA.date.getTime(), )
  

    if (this.params) {
      response = await this.dataService.getDocumentAsync('gamedatalogs', { query: {id: this.params}, autopopulate: true, virtuals: true })
    }
    
    this.gamedataDiff = response && response.qaProdDiffRecords ? response.qaProdDiffRecords : null; 
    this.logger.log('Logs:', this.gamedataDiff);
    this.logger.log('Logs:', this.history);
    this.isLoading = false;
  }

  async openModal(id: any) {
    this.params = id;
    this.gamedataDiff = await this.dataService.getDocumentAsync('gamedatalogs', { query: {id: this.params}, autopopulate: true, virtuals: true })
    this.gamedataDiff = this.gamedataDiff.qaProdDiffRecords ? this.gamedataDiff.qaProdDiffRecords : null; 
  }
}