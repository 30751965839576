import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BaseDynamicFormComponent } from '../base-dynamic-form/base-dynamic-form.component';
import { DynamicInputType } from '../../enums/DynamicInputType';
import { InputFieldType } from '../../enums/InputFieldType';
import { DynamicCardType } from '../../enums/DynamicCardType';
import { FieldTransformerService } from './services/field-transformer.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'dynamic-entity-form-component',
  templateUrl: './dynamic-entity-form-component.component.html',
  styleUrls: ['./dynamic-entity-form-component.component.sass'],
  providers: [MessageService]
})
export class DynamicEntityFormComponentComponent extends BaseDynamicFormComponent implements OnInit {
  dynamicEntityRecord: any = {};
  type: string;
  isEditMode: boolean = false;
  isLoading: boolean = true;
  options: any = [];
  fields: any = [];
  hasError: boolean = false;
  errorMessage: string = '';

  constructor(
    private http: HttpClient,
    private fieldTransformer: FieldTransformerService,
    private messageService: MessageService,
    private router: Router
  ) {
    super();
  }

  async ngOnInit() {
    // grab testeventtwo from the url /d/testeventtwo/add
    const url = window.location.pathname;
    const urlParts = url.split('/');
    this.type = urlParts[2];

    console.log('this.type', this.type);

    try {
      const metadataFields = await this.http
        .get<any[]>(`/api/metadata/${this.type}/fields`)
        .toPromise();

      if (metadataFields) {
        this.fields = this.fieldTransformer.transformFields(metadataFields);
        console.log('this.fields', this.fields);
        this.title = 'My Entities';
        this.viewRoute = this.type;
        this.isLoading = false;
      } else {
        this.handleError('No metadata fields found');
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  private handleError(error: any) {
    console.error('Error fetching metadata fields:', error);
    this.hasError = true;
    this.isLoading = false;

    if (error instanceof HttpErrorResponse && error.status === 404) {
      this.errorMessage = `The entity type "${this.type}" has not been configured yet. Please create its metadata first.`;
      this.messageService.add({
        severity: 'error',
        summary: 'Entity Not Found',
        detail: this.errorMessage,
        sticky: true
      });

      // Add a button to redirect to metadata creation
      this.messageService.add({
        severity: 'info',
        summary: 'Create Metadata',
        detail: 'Click here to create metadata for this entity type',
        sticky: true,
        closable: true,
        data: 'create-metadata',
        life: 10000,
        styleClass: 'cursor-pointer'
      });
    } else {
      this.errorMessage = 'An error occurred while loading the entity configuration.';
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: this.errorMessage
      });
    }
  }

  onToastClose(event: any) {
    if (event.message.data === 'create-metadata') {
      this.router.navigate(['/dynamic-entities']);
    }
  }
}