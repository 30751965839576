<h2>{{ field.label }}</h2>
<div class="tables-container table-border">
    <div class="table-wrapper">
        <h2>Current {{ field.label }} </h2>
        <div class="header-container">
            <h4>Items to be added upon form submission.</h4>
            <div *ngIf="entityType === 'item-sets'" class="generate-set-container">
                <app-item-set-generator-popup (itemsGenerated)="handleItemsGenerated($event)"></app-item-set-generator-popup>
              </div>
        </div>
        <div class="toolbar">
            <input type="text" pInputText placeholder="Search..." (input)="onSearch($event)" />
            <button type="button" pButton label="Delete Selected" icon="pi pi-trash" (click)="deleteSelectedItems()" [disabled]="!selectedItems.length"></button>
        </div>

        <p>Total items: {{ filteredRowData.length }}</p>

        <p-table [value]="filteredRowData" selectionMode="multiple" [(selection)]="selectedItems" dataKey="id" [scrollable]="true" scrollHeight="800px" [virtualScroll]="true" [rows]="10">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th pSortableColumn="id">ID <p-sortIcon field="id"></p-sortIcon></th>
                    <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
                    <th *ngIf="field.showthumb">Thumbnail</th>
                    <th style="width: 100px;">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr [ngClass]="{'generated-item': rowData.isGenerated}">
                    <td>
                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                    </td>
                    <td>{{ rowData.id }}</td>
                    <td>{{ rowData.name }}</td>
                    <td *ngIf="field.showthumb">
                        <img *ngIf="getThumbnailUrl(rowData)" [src]="getThumbnailUrl(rowData)" alt="Thumbnail" style="width: 50px; height: auto;" />
                    </td>
                    <td>
                        <button type="button" pButton icon="pi pi-trash" (click)="removeRow(rowIndex)"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="table-wrapper">
        <h2>Add {{ field.label }}</h2>
        <h4>Select items to add to the list.</h4>
        <app-ag-grid-table
            *ngIf="columnSelection"
            [entity]="entity"
            [columnDefs]="columnDefs"
            [bulkUpdateColDefs]="bulkUpdateColDefs"
            [autopopulateSelect]="autopopulateSelect"
            [columnSelection]="columnSelection"
            [buildParams]="buildParams"
            [tableParams]="tableParams"
            [isTableInput]="isTableInput"
            [tableInputParams]="tableInputParams"
            [itemsRef]="this.record[this.field.key]"
            (selectionChange)="handleSelectionChange($event)"
        >
        </app-ag-grid-table>
    </div>
</div>
<p-toast></p-toast>
