import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EntityHistoryService {
  constructor(private http: HttpClient) {}

  /**
   * Retrieves all history of changes for a given entity
   * 
   * @param entityType The type of the entity
   * @param entityId The Id of the entity
   */
  async getEntityHistory(entityType: string, entityId: string): Promise<any> {
    const result = await firstValueFrom(this.http.get<any>(`/api/${entityType}/history/${entityId}`));
    return result;
  }

  /**
   * Retrieves all history of changes for a given  dynamic entity
   * 
   * @param entityType The type of the entity
   * @param entityId The Id of the entity
   */
  async getDynamicEntityHistory(entityType: string, entityId: string): Promise<any> {
    const result = await firstValueFrom(this.http.get<any>(`/api/dynamic-entities/manager/${entityType}/history/${entityId}`));
    return result;
  }
}