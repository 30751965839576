<div class="card button-spaces" *ngIf="authService.hasPermission(['create'], 'PermissionController')">
  <p-toast></p-toast>
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button pButton pRipple label="Add new permission" icon="pi pi-plus" class="p-button-success"
        styleClass="p-mr-2" (click)="showAddNewPermission = true"></button>
    </ng-template>
    <ng-template pTemplate="right"> </ng-template>
  </p-toolbar>
</div>

<!-- Dialogs -->

<!-- Add new permission -->
<p-dialog [(visible)]="showAddNewPermission" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
  <p-messages></p-messages>
  <ng-template pTemplate="header">
    <h3>Add new Permission</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form>
      <div>
        <div class="p-field">
          <span>
            <ng-container>
              <div class="p-mb-5">
                <span class="p-float-label">
                  <input id="name" type="text" pInputText name="permission-name" [(ngModel)]="permission.name"
                    appendTo="body" (ngModelChange)="clearMessages()">
                  <label for="name">Name</label>
                </span>
              </div>
            </ng-container>
            <br />
            <ng-container>
              <div class="p-mb-5">
                <p-dropdown appendTo="body" [options]="entities" [required]="true" [(ngModel)]="permission.entity"
                placeholder="Select an Entity" optionLabel="name" [showClear]="true" name="entity" optionValue="value"
                (onChange)="clearMessages()"></p-dropdown>
              </div>
            </ng-container>
            <br />
            <ng-container>
              <div class="p-mb-5">
                <p-multiSelect appendTo="body" [options]="abilities" [(ngModel)]="permission.abilities"
                defaultLabel="Select abilities" optionLabel="name" optionValue="value"
                selectedItemsLabel="{0} abilities selected" name="abilities"
                (onChange)="clearMessages()"></p-multiSelect>
              </div>

            </ng-container>
          </span>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
      (click)="showAddNewPermission = false; clearMessages();"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
      (click)="createNewPermission()"></button>
  </ng-template>
</p-dialog>
