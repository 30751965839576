<div class="p-grid">
    <div class="p-col-12">
        <p-card class="p-mx-2">
            <div class="p-grid p-mx-5">
                <!-- Left column -->
                <div class="p-col-6 p-pr-4">
                    <!-- Auto Build Nodes Scaling up -->
                    <div class="p-my-5">
                        <h5>Auto Build Nodes Scaling up</h5>
                        <p-inputSwitch [(ngModel)]="cmsSettings.autoNodesScaleUp" (onChange)="onChange('autoNodesScaleUp', $event)">
                        </p-inputSwitch>
                    </div>
                    <!-- Item Scale Up Value -->
                    <div class="p-my-5 p-inputgroup">
                        <span ngClass="p-float-label">
                            <p-inputNumber
                                [(ngModel)]="cmsSettings.itemsNodesScaleUpValue"
                                [showButtons]="true"
                                inputId="itemSU"
                                [min]="0"
                                [max]="30"
                                [ngModelOptions]="{ standalone: true }"
                                (onInput)="onChange('itemsNodesScaleUpValue', $event)"
                            >
                            </p-inputNumber>
                            <label for="itemSU">Items Queue Build Nodes to be scaled up</label>
                        </span>
                    </div>
                    <!-- Levels Scale Up Value -->
                    <div class="p-my-5 p-inputgroup">
                        <span ngClass="p-float-label">
                            <p-inputNumber
                                [(ngModel)]="cmsSettings.levelsNodesScaleUpValue"
                                [showButtons]="true"
                                inputId="levelsSU"
                                [min]="0"
                                [max]="30"
                                [ngModelOptions]="{ standalone: true }"
                                (onInput)="onChange('levelsNodesScaleUpValue', $event)"
                            >
                            </p-inputNumber>
                            <label for="levelsSU">Levels Queue Build Nodes to be scaled up</label>
                        </span>
                    </div>
                    <!-- Images Scale Up Value -->
                    <div class="p-my-5 p-inputgroup">
                        <span ngClass="p-float-label">
                            <p-inputNumber
                                [(ngModel)]="cmsSettings.imagesNodesScaleUpValue"
                                [showButtons]="true"
                                inputId="itemSU"
                                [min]="0"
                                [max]="30"
                                [ngModelOptions]="{ standalone: true }"
                                (onInput)="onChange('imagesNodesScaleUpValue', $event)"
                            >
                            </p-inputNumber>
                            <label for="itemSU">Images Queue Build Nodes to be scaled up</label>
                        </span>
                    </div>
                    <div class="p-my-5 p-inputgroup">
                        <span>
                            <button
                                pButton
                                pRipple
                                type="submit"
                                label="Scale Now"
                                class="p-mb-4"
                                [style]="{ 'min-width': '12.5em' }"
                                (click)="forceScaleUp()"
                            ></button>
                        </span>
                    </div>
                </div>
                <!-- Right column -->
                <div class="p-col-6 p-pl-4" id="required-prod">
                    <!-- Auto Build Nodes Scaling down -->
                    <div class="p-my-5">
                        <h5>Auto Build Nodes Scaling down</h5>
                        <p-inputSwitch [(ngModel)]="cmsSettings.autoNodesScaleDown" (onChange)="onChange('autoNodesScaleDown', $event)">
                        </p-inputSwitch>
                    </div>
                    <!-- Item Scale Down Value -->
                    <div class="p-my-5 p-inputgroup">
                        <span ngClass="p-float-label">
                            <p-inputNumber
                                [(ngModel)]="cmsSettings.itemsNodesScaleDownValue"
                                [showButtons]="true"
                                inputId="itemSD"
                                [min]="0"
                                [max]="10"
                                [ngModelOptions]="{ standalone: true }"
                                (onInput)="onChange('itemsNodesScaleDownValue', $event)"
                            >
                            </p-inputNumber>
                            <label for="itemSU">Items Queue Build Nodes to be scaled down</label>
                        </span>
                    </div>
                    <!-- Levels Scale Down Value -->
                    <div class="p-my-5 p-inputgroup">
                        <span ngClass="p-float-label">
                            <p-inputNumber
                                [(ngModel)]="cmsSettings.levelsNodesScaleDownValue"
                                [showButtons]="true"
                                inputId="levelsSD"
                                [min]="0"
                                [max]="10"
                                [ngModelOptions]="{ standalone: true }"
                                (onInput)="onChange('levelsNodesScaleDownValue', $event)"
                            >
                            </p-inputNumber>
                            <label for="levelsSU">Levels Queue Build Nodes to be scaled down</label>
                        </span>
                    </div>
                    <!-- Images Scale Down Value -->
                    <div class="p-my-5 p-inputgroup">
                        <span ngClass="p-float-label">
                            <p-inputNumber
                                [(ngModel)]="cmsSettings.imagesNodesScaleDownValue"
                                [showButtons]="true"
                                inputId="itemSD"
                                [min]="0"
                                [max]="10"
                                [ngModelOptions]="{ standalone: true }"
                                (onInput)="onChange('imagesNodesScaleDownValue', $event)"
                            >
                            </p-inputNumber>
                            <label for="itemSU">Images Queue Build Nodes to be scaled down</label>
                        </span>
                    </div>
                </div>
            </div>
        </p-card>
    </div>
</div>