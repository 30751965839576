import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import PricePointDTO from '../dtos/pricePointDTO';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-price-point-form',
  templateUrl: './price-point-form.component.html',
  styleUrls: ['./price-point-form.component.sass']
})
export class PricePointFormComponent extends BaseDynamicFormComponent implements OnInit {

  activeTabIndex: number = 0; // Default to the first tab
  environments = ['dev', 'qa', 'prod']; // The order of these values should match the tab order
  storeEnvs: string[] = [];

  PricePointRecord: PricePointDTO = new PricePointDTO();
  constructor() {
    super();
  }


  /**
   * Price Point Forms V2 Component Initialization
  */

  ngOnInit(){
    this.initializeStoreEnvironments();
    this.loadSelectedEnvironment();

    this.fields =
    [
      {
        title: "Price Point",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: true,
            columnWidth: 10
          },
          {
            name: "Non Consumable?",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'nonConsumable',
            isRequired: false,
            label: 'Non Consumable?',
            inputId: 'nonConsumable',
            checkboxBinary: true,
            columnWidth: 12
          },
          // {
          //   name: "Start Date/Time",
          //   // inputType: DynamicInputType.Calendar,
          //   inputType: DynamicInputType.BaseInputField,
          //   inputTypeFields: [InputFieldType.Calendar],
          //   clearField: true,
          //   key: 'storeStart',
          //   label: 'Start Date/Time',
          //   calendarShowTime: true,
          //   calendarYearNavigatior: true,
          //   calendarYearRange: '2020:2030',
          //   calendarMonthNavigator: true,
          //   calendarSelectOtherMonths: true,
          //   columnWidth: 6
          // },
          // {
          //   name: "End Date/Time",
          //   // inputType: DynamicInputType.Calendar,
          //   inputType: DynamicInputType.BaseInputField,
          //   inputTypeFields: [InputFieldType.Calendar],
          //   clearField: true,
          //   key: 'storeEnd',
          //   label: 'End Date/Time',
          //   calendarShowTime: true,
          //   calendarYearNavigatior: true,
          //   calendarYearRange: '2020:2030',
          //   calendarMonthNavigator: true,
          //   calendarSelectOtherMonths: true,
          //   columnWidth: 6
          // },
          {
            name: 'Cost(s)',
            inputType: DynamicInputType.Price,
            options: {fieldName:'', version: 3},
            key: 'costs_ref',
            columnWidth: 12
          },
          {
            name: "Tags",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: '',
            label: 'Tags:',
          },
          {
            name: "Tags",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Chip],
            clearField: true,
            key: 'tags',
            isRequired: false,
            label: 'Tags',
            disabled: false,
            columnWidth: 6
          },
          {
            name: '',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            key: '',
            columnWidth: 6
          },
          {
            name: "Google Checkout Playgami",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'GoogleCheckoutPetunia',
            isRequired: false,
            label: 'Google Checkout Playgami',
            disabled: false,
            columnWidth: 8
          },
          {
            name: "Apple App Store Playgami",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'AppleAppStorePetunia',
            isRequired: false,
            label: 'Apple App Store Playgami',
            disabled: false,
            columnWidth: 8
          },
          {
            name: "Petunia Playgami",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'PlaygamiPetunia',
            isRequired: false,
            label: 'Petunia Playgami',
            disabled: false,
            columnWidth: 8
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Price Point";
    this.viewRoute = 'price-points';
    this.isLoading = false;
  }

  initializeStoreEnvironments(): void {
    const currentUrl = window.location.href;

    if (currentUrl.includes('prod.cms')) {
      // Prod CMS: DEV, QA, PROD
      this.storeEnvs = ['dev', 'qa', 'prod'];

    } else if (currentUrl.includes('test.cms')) {
      // Test CMS: TEST
      this.storeEnvs = ['test'];
    } else {
      // local/Dev CMS: TEST, DEV, QA, PROD
      this.storeEnvs = ['test', 'dev', 'qa', 'prod'];
    }
    this.environments = this.storeEnvs;
  }

  loadSelectedEnvironment(): void {
    const savedEnvironment = localStorage.getItem('selectedEnvironment');
    if (savedEnvironment) {
      const environmentObject = JSON.parse(savedEnvironment);
      this.activeTabIndex = this.environments.indexOf(environmentObject.value);
    }
  }

  onTabChange(event: { index: number; }): void {
    this.activeTabIndex = event.index;
    const environmentToSave = {
      label: this.environments[this.activeTabIndex].charAt(0).toUpperCase() + this.environments[this.activeTabIndex].slice(1),
      value: this.environments[this.activeTabIndex]
    };
    localStorage.setItem('selectedEnvironment', JSON.stringify(environmentToSave));
  }


}
