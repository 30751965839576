import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

interface Image {
  previewImageSrc: string;
  thumbnailImageSrc: string;
  alt: string;
  title: string;
  devDiff?: any;
  qaDiff?: any;
  prodDiff?: any;
  lastAssetVersion?: any;
  style?: { [key: string]: string };
}

@Component({
  selector: 'app-render-gallery',
  templateUrl: './render-gallery.component.html',
  styleUrls: ['./render-gallery.component.sass']
})
export class RenderGalleryComponent implements OnInit {
  images: Image[] = [];
  display: boolean = false;
  selectedImage: Image | null = null;
  @Input() assetRecord: any;
  @Input() entityId: number;
  @Input() type: 'challenges' | 'items';
  diffImage: string | null = null; // Add this property
  showDiffDialog = false;
  prePromotionImage: any = {};


  private readonly BASE_URL = 'https://flora-render.{env}.petunia.withbuddies.com/renderserver/test';

  constructor(private dataService: DataService) {}

  async ngOnInit() {
    this.prePromotionImage = {};
    let challengeString = this.type === 'challenges' ? this.entityId.toString() : '2791';
    let itemsString = this.type === 'challenges' ? '0' : this.entityId.toString();
    let prePromotionQuery = this.type === 'challenges' ?  'challengePath' : 'assetpath';

    this.images = this.initializeImages(challengeString, itemsString);

    this.addPrePromotion(challengeString, itemsString, prePromotionQuery);


    if (this.type === 'challenges') {
      await this.addTestCollectionImage(challengeString);
    }

    await this.comparePrePromotionWithOthers();
    await this.applyStylesBasedOnComparison();

  }

  private initializeImages(challengeString: string, itemsString: string): Image[] {
    const environments = ['dev', 'qa', 'prod'];
    return environments.map(env => ({
      previewImageSrc: `${this.BASE_URL.replace('{env}', env)}/${challengeString}/512/${itemsString}.jpg`,
      thumbnailImageSrc: `${this.BASE_URL.replace('{env}', env)}/${challengeString}/512/${itemsString}.jpg`,
      alt: `${env.toUpperCase()} Render`,
      title: `${env.toUpperCase()}`
    }));
  }

  private async addTestCollectionImage(challengeString: string) {
    let testCollectionItems = '0000';
    let testCollection = await this.dataService.getAllOfTypeAsync('collections', { query: { id: 1053 }, select: 'id name item' });
    if (testCollection[0]?.items?.length > 0) {
      testCollectionItems = testCollection[0].items.join('-');
    }
    this.images.push({
      previewImageSrc: `${this.BASE_URL.replace('{env}', 'qa')}/${challengeString}/512/${testCollectionItems}.jpg`,
      thumbnailImageSrc: `${this.BASE_URL.replace('{env}', 'qa')}/${challengeString}/512/${testCollectionItems}.jpg`,
      alt: 'Scene Render',
      title: 'Items Preview (QA)'
    });
  }

  private addPrePromotion(challengeString: string, itemsString: string, prePromotionQuery: string) {
    // Assuming this.assetRecord.asset_versions contains your array of asset version objects
    let assetVersions = this.assetRecord.asset_versions || [];
    let lastAssetVersion = assetVersions[assetVersions.length - 1] || null;
    let destinationPath = lastAssetVersion ? lastAssetVersion.destinationPath : '';

    const prePromotionImage: Image = {
      previewImageSrc: `${this.BASE_URL.replace('{env}', 'qa')}/${challengeString}/512/${itemsString}.jpg?${prePromotionQuery}=${destinationPath}`,
      thumbnailImageSrc: `${this.BASE_URL.replace('{env}', 'qa')}/${challengeString}/512/${itemsString}.jpg?${prePromotionQuery}=${destinationPath}`,
      alt: 'Pre Promotion Render',
      title: 'Pre Promotion',
      lastAssetVersion: lastAssetVersion
    };

    // Insert the prePromotionImage at index 1
    this.images.splice(1, 0, prePromotionImage);
  }

  showDialog(image: Image) {
    this.selectedImage = image;
    this.display = true;
  }

  onImgError(event: any) {
    event.target.src = 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg';
  }

  async fetchAndDisplayDiffImage(imageUrl1: string, imageUrl2: string) {
    this.dataService.getDiffImage(imageUrl1, imageUrl2)
      .then(response => {
        // Assuming response.diffImage contains the Base64 string
        this.diffImage = response.diffImage;
      })
      .catch(error => {
        console.error(error);
        // Handle any errors, potentially resetting diffImage or showing a default image
        this.diffImage = null; // or set to a default image
      });
  }

  async comparePrePromotionWithOthers() {
    const prePromotionImage = this.images.find(image => image.title === 'Pre Promotion');
    if (!prePromotionImage) return; // Exit if there's no Pre Promotion image

    const compareWith = ['DEV', 'QA', 'PROD'];
    const comparisonPromises = []; // Array to hold all comparison promises

    for (const title of compareWith) {
      const targetImage = this.images.find(image => image.title === title);
      if (targetImage) {
        // Add the comparison promise to the array
        const comparisonPromise = this.dataService.getDiffImage(prePromotionImage.previewImageSrc, targetImage.previewImageSrc)
          .then(response => {
            // Assuming response contains a property 'diffImage' with the Base64 string
            const diffKey = `${title.toLowerCase()}Diff`;
            (prePromotionImage as any)[diffKey] = response;
          })
          .catch(error => console.error(`Error comparing Pre Promotion with ${title}:`, error));

        comparisonPromises.push(comparisonPromise);
      }
    }

    // Wait for all comparisons to complete
    await Promise.all(comparisonPromises);
  }


  async applyStylesBasedOnComparison() {
    const prePromotionImage = this.images.find(image => image.title === 'Pre Promotion');
    this.prePromotionImage = prePromotionImage;
    if (!prePromotionImage) return; // Exit early if no Pre Promotion image

    // Initialize images for comparison
    const devImage = this.images.find(image => image.title === 'DEV');
    const qaImage = this.images.find(image => image.title === 'QA');
    const prodImage = this.images.find(image => image.title === 'PROD');

    // Define common border styles
    const borderStyles = {
      diffExists: '3px solid #ff6550', // Red for differences found
      qaNotExported: '3px solid #ffe306', // Yellow for QA not exported
      prodDiff: '3px solid #FB00BF' // Pink for differences found in QA and PROD when QA not exported
    };

    // Apply styles based on conditions
    if (prePromotionImage.devDiff && prePromotionImage.devDiff.diffPercentage > 0) {
      prePromotionImage.style = { border: borderStyles.diffExists };
      if (devImage) devImage.style = { border: borderStyles.diffExists };
    } else if (prePromotionImage?.lastAssetVersion?.QA_Exported && prePromotionImage?.lastAssetVersion?.PROD_Exported){
      // Remove prePromotionImage from the images array
      this.images = this.images.filter(image => image.title !== 'Pre Promotion');
    } else if (prePromotionImage.qaDiff && prePromotionImage.qaDiff.diffPercentage <= 0 && !prePromotionImage?.lastAssetVersion?.QA_Exported) {
      prePromotionImage.style = { border: borderStyles.qaNotExported };
    } else if (prePromotionImage.qaDiff && prePromotionImage.qaDiff.diffPercentage > 0 && !prePromotionImage?.lastAssetVersion?.QA_Exported) {
      prePromotionImage.style = { border: borderStyles.prodDiff };
      if (qaImage) qaImage.style = { border: borderStyles.prodDiff };
      if (prodImage) prodImage.style = { border: borderStyles.prodDiff };
    } else if (prePromotionImage.prodDiff && prePromotionImage.prodDiff.diffPercentage <= 0 && prePromotionImage?.lastAssetVersion?.QA_Exported) {
      // prePromotionImage.style = { border: borderStyles.qaNotExported };
      if (qaImage) qaImage.style = { border: borderStyles.qaNotExported };
    }

    // Trigger a refresh of the images array to update the view
    this.images = [...this.images];
  }


}
