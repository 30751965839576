import { AuthGuard } from "../auth/auth.guard";
import { PermissionsComponent } from "../permissions/permissions.component";
import { RoleComponent } from "../role/role.component";
import { UserComponent } from "../user/user.component";

export const authRoutes = {
  prefix: 'auth',
  routes: function () {
    return [
      {
        path: `${this.prefix}/roles`,
        component: RoleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/users`,
        component: UserComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/permissions`,
        component: PermissionsComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};
