import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import RewardsTrackDTO from 'src/app/cms-v2/entities/rewards-track/dtos/RewardsTrackDTO';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { EndlessOfferService } from 'src/app/common/services/endless-offer.service';
@Component({
  selector: 'app-endless-offer-track',
  templateUrl: './endless-offer-track.component.html',
  styleUrls: ['./endless-offer-track.component.css']
})
export class EndlessOfferTrackComponent implements OnInit {

  @Input() rewardTrack: RewardsTrackDTO;
  @Input() parentEntity: string;
  public isLoading: boolean = true;

  constructor(
    private http: HttpClient,
    private commonEntityService: CommonEntityService,
    private endlessOfferService: EndlessOfferService
    ) { }  // Injecting HttpClient

  async ngOnInit(): Promise<void> { // make the method async
    this.isLoading = true; // set loading to true at the start

    if (this.parentEntity && this.parentEntity === 'endless-offer') {
      if (this.rewardTrack.track_v2_ref) {
        this.rewardTrack = this.rewardTrack.track_v2_ref;

        if (!this.rewardTrack.EOrewards) {
          this.isLoading = false
          return;
        }

        // await this.endlessOfferService.getRewardsData(this.rewardTrack, this.setLoadingState.bind(this)); // await completion
        await this.endlessOfferService.buildDisplayData(this.rewardTrack); // await completion

        this.isLoading = false; // set loading to false when done
      } else {
        this.rewardTrack = new RewardsTrackDTO();
      }
    } else {
        if (!this.rewardTrack.EOrewards) {
          this.isLoading = false
          return;
        }
        // await this.endlessOfferService.getRewardsData(this.rewardTrack, this.setLoadingState.bind(this)); // await completion
        await this.endlessOfferService.buildDisplayData(this.rewardTrack); // await completion

        this.isLoading = false
    }

    // Initialize arrays if they don't exist
    if (!this.rewardTrack.bundles) {
      this.rewardTrack.bundles = [];
    }
    if (!this.rewardTrack.rewards) {
      this.rewardTrack.rewards = [];
    }

    if (this.isLoading) { // In case no async operations were performed
      this.isLoading = false;
    }
  }


  getCreditId(key: string): string {
    if(key){
      return key.split('_')[1];
    } else {
      return 'undefined'
    }
  }

  getCreditCount(credit: any): any {
    return Object.values(credit)[0];
  }

  setLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
  }


}
