import { Component, OnInit } from '@angular/core';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-achievement-view-v2',
  templateUrl: './achievement-view-v2.component.html',
  styleUrls: ['./achievement-view-v2.component.sass']
})
export class AchievementViewV2Component extends BaseDynamicViewComponent implements OnInit {
  rewardRecord: BaseEntityDto = new BaseEntityDto();

  achievementRecord: BaseEntityDto = new BaseEntityDto();
  constructor(private dataService: DataService) {
    super();
  }

  achievementTypeDisplayControls(value: string, fieldName: string): boolean {
    const numValue = parseInt(value, 10);
    // Define which fields should be shown for each achievement type
    switch (numValue) {
      case 0: // Challenges submitted
        return ['challengeClimate', 'challengeType'].includes(fieldName);

      case 2: // Challenge Results w/ Score of X or above
        return fieldName === 'challengeResultScore';

      case 3: // Items submitted
        return ['itemCategory', 'itemType', 'itemCategories', 'isSpawnerType'].includes(fieldName);

      case 8: // IAPs completed
        return fieldName === 'minimumIAPCost';

      case 18: // Water used
      case 19: // Gifts sent
        return fieldName === 'userHasBalance';

      default:
        return false;
    }
  }

  repetitiveDisplayControls(value: string, fieldName: string): boolean {
    return value === 'true';
  }

  async ngOnInit() {
    const achievementTypes = await this.dataService.getAchievementTypes();
    this.fields =
      [
        {
          title: "Achievement Data",
          fields:
            [
              { name: "Title", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'title', isRequired: true, label: 'Title', columnWidth: 6 },
              { name: "SubText", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'subText', isRequired: true, label: 'SubText', columnWidth: 6 },
              { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time', columnWidth: 6 },
              { name: "End Date/Time", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time', columnWidth: 6 },
              { name: "Sorting Order", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'sortingOrder', isRequired: true, label: 'Sorting Order', columnWidth: 6 },
              { name: "Consecutive", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'isConsecutive', isRequired: true, label: 'Consecutive', columnWidth: 6 },
              {
                name: "Promo Fields:",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Title],
                key: '',
                label: 'Promo Fields:',
                columnWidth: 12,
                tagType: 'h3'
              },
              { name: "Promo Start Date/Time", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'promoStartDate', label: 'Promo Start Date/Time', columnWidth: 6 },
              { name: "Promo End Date/Time", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'promoEndDate', label: 'Promo End Date/Time', columnWidth: 6 },
              {
                name: "Achievement Details:",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Title],
                key: '',
                label: 'Achievement Details',
                columnWidth: 12,
                tagType: 'h3'
              },
              {
                name: "Player Description",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true, key: 'playerDescription',
                isRequired: true, label: 'Player Description',
                columnWidth: 12
              },
              {
                name: "Interval",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.DropdownWithLabels],
                clearField: true,
                key: 'interval',
                label: 'Interval',
                isRequired: true,
                options:{
                  fieldName: 'interval',
                  values:
                    [
                      { name: 'Continuous', value: 0 },
                      { name: 'Single / One and Done', value: 1 },

                    ]
                },
                columnWidth: 6
              },
              {
                name: "Achievement Type",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.DropdownWithLabels],
                clearField: true,
                key: 'achievementType',
                label: 'Achievement Type',
                isRequired: true,
                options: {
                  fieldName: 'interval',
                  values: achievementTypes
                },
                columnWidth: 6,
                displayControlType: "showHide",
                emitOnInit: true,
                displayControls: {
                  ['challengeClimate']: (value: string) => this.achievementTypeDisplayControls(value, 'challengeClimate'),
                  ['challengeType']: (value: string) => this.achievementTypeDisplayControls(value, 'challengeType'),
                  ['challengeResultScore']: (value: string) => this.achievementTypeDisplayControls(value, 'challengeResultScore'),
                  ['itemCategory']: (value: string) => this.achievementTypeDisplayControls(value, 'itemCategory'),
                  ['isSpawnerType']: (value: string) => this.achievementTypeDisplayControls(value, 'isSpawnerType'),
                  ['itemType']: (value: string) => this.achievementTypeDisplayControls(value, 'itemType'),
                  ['minimumIAPCost']: (value: string) => this.achievementTypeDisplayControls(value, 'minimumIAPCost'),
                  ['userHasBalance']: (value: string) => this.achievementTypeDisplayControls(value, 'userHasBalance'),
                }
              },
              {
                name: "Achievement Type Options:",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Title],
                key: '',
                label: 'Achievement Type Options:',
                columnWidth: 12,
                tagType: 'h4'
              },
              // Challenges Submitted Type:
              {
                name: "Challenge Type",
                inputType: DynamicViewType.MiscRef,
                clearField: true,
                key: 'challengeType',
                label: 'Challenge Type',
                controller: 'challenge-types',
                columnWidth: 6
              },
              {
                name: "Challenge Climate",
                inputType: DynamicViewType.MiscRef,
                clearField: true,
                key: 'challengeClimate',
                label: 'Challenge Climate',
                controller: 'climates',
                columnWidth: 6
              },
              // Challenge Results w/ Score of X or above
              { name: "Score", 
                inputType: DynamicViewType.BaseViewField, 
                viewTypes: [BaseViewFieldType.Text], 
                clearField: true, 
                key: 'challengeResultScore', 
                isRequired: true, 
                label: 'Score', 
                columnWidth: 6 
              },
              { name: "",
                inputType: DynamicViewType.SpaceCol,
                key: '',
                label: '',
                columnWidth: 6
              },
              // Items submitted
              // {
              //   name: "Item Categories",
              //   inputType: DynamicViewType.BaseViewField , 
              //   viewTypes: [BaseViewFieldType.MultiSelectObj],
              //   clearField: true,
              //   key: 'itemCategories',
              //   label: 'Item Categories',
              //   controller: 'categories',
              //   columnWidth: 6
              // },
              {
                name: "Is Spawner Type?",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Boolean],
                clearField: true,
                key: 'isSpawnerType',
                label: 'Is Spawner Type?',
                isRequired: true,
                columnWidth: 6,
                booleanoptions: {
                  true: "Yes",
                  false: "No"
                },
                displayControlType: "showHide",
                emitOnInit: true,
                displayControls: {
                  ['isRequired']: (value: string) => value === 'true'
                }
              },
              {
                name: "Spawner Type?",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Boolean],
                clearField: true,
                key: 'isRequired',
                label: 'Spawner Type',
                isRequired: true,
                columnWidth: 6,
                booleanoptions: {
                  true: "Required",
                  false: "Optional"
                },
                displayType: 'isSpawnerType',
                displayControls: {
                  'isSpawnerType': (value: any) => value === true
                },
              },
              {
                name: "Item Type",
                inputType: DynamicViewType.MiscRef,
                clearField: true,
                key: 'itemType',
                label: 'Item Type',
                controller: 'item-types',
                columnWidth: 6
              },
              // IAPs Completed
              { name: "Minimum IAP Cost", 
                inputType: DynamicViewType.BaseViewField, 
                viewTypes: [BaseViewFieldType.Text], 
                clearField: true, 
                key: 'minimumIAPCost', 
                isRequired: true, 
                label: 'Minimum IAP Cost', 
                columnWidth: 6 
              },
              { name: "",
                inputType: DynamicViewType.SpaceCol,
                key: '',
                label: '',
                columnWidth: 6
              },
              // Daily Goals/Activities
              {
                name: "Is Daily Goal?",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Boolean],
                key: 'isDailyGoal',
                label: 'Is Daily Goal?',
                columnWidth: 3,
                displayControlType: "showHide",
                emitOnInit: true,
                booleanoptions: {
                  true: "Yes",
                  false: "No"
                },
                displayControls: {
                  ['expectedDiamondCost']: (value: string) => value === 'true',
                  ['goalType']: (value: string) => value === 'true',
                }
              },
              {
                name: "Goal Type",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.DropdownWithLabels],
                clearField: true,
                key: 'goalType',
                label: 'Goal Type',
                filter: true,
                showField: false,
                options: { 
                  fieldName: 'goalType', 
                  values: [
                    { name: 'Evergreen', value: 0 },
                    { name: 'Live Ops', value: 1 },
                    { name: 'Segmented', value: 2 },
                  ] 
                },
                columnWidth: 4
              },
              {
                name: "Expected Diamond Cost",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'expectedDiamondCost',
                isRequired: false,
                label: 'Expected Diamond Cost',
                showField: false,
                columnWidth: 4
              },
              {
                name: "Toggle Button",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Boolean],
                clearField: true,
                key: 'isRepetitive',
                isRequired: false,
                label: 'Toggle Button',
                showField: true,
                columnWidth: 3,
                displayControlType: "showHide",
                emitOnInit: true,
                booleanoptions: {
                  true: "Is Repetitive/Scheduled",
                  false: "Not Repetitive/Scheduled"
                },
                displayControls: {
                  ['repeatsDuration']: (value: string) => this.repetitiveDisplayControls(value, 'repeatsDuration'),
                  ['repeats']: (value: string) => this.repetitiveDisplayControls(value, 'repeats'),
                  ['repEventsOptions']: (value: string) => this.repetitiveDisplayControls(value, 'repEventsOptions'),
                }
              },
              {
                name: "Duration (Hrs)",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                isRequired: false,
                key: 'repeatsDuration',
                label: 'Duration (Hrs)',
                columnWidth: 2
              },
              {
                name: "Available On",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.MultiSelect],
                key: 'repeats',
                label: 'Available On',
                showField: true,
                columnWidth: 6,
                options: {
                  fieldName: 'repeats',
                  values: [
                    { label: 'Monday', value: 'monday' },
                    { label: 'Tuesday', value: 'tuesday' },
                    { label: 'Wednesday', value: 'wednesday' },
                    { label: 'Thursday', value: 'thursday' },
                    { label: 'Friday', value: 'friday' },
                    { label: 'Saturday', value: 'saturday' },
                    { label: 'Sunday', value: 'sunday' }
                  ]
                }
              },
              // Water user // Gifts Sent
              {
                name: "User Has Balance?",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Boolean],
                clearField: true,
                key: 'userHasBalance',
                label: 'User Has Balance?',
                isRequired: true,
                columnWidth: 6,
                booleanoptions: {
                  true: "Yes",
                  false: "No"
                },
                showField: false,
                displayType: 'achievementType',
                displayControls: {
                  'achievementType': (value: any) => {
                    const numValue = parseInt(value, 10);
                    return numValue === 18 || numValue === 19; // Water used (18) or Gifts sent (19)
                  }
                }
              },
              // 
              { 
                name: "Target(s)", 
                inputType: DynamicViewType.BaseViewField, 
                viewTypes: [BaseViewFieldType.MultiSelect], 
                clearField: true, 
                key: 'target', 
                isRequired: true, 
                label: 'Target(s)', 
                columnWidth: 6 
              },
              {
                name: "Is Repetitive/Scheduled",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Boolean],
                clearField: true,
                key: 'isRepetitive',
                label: 'Is Repetitive/Scheduled',
                isRequired: true,
                columnWidth: 6,
                booleanoptions: {
                  true: "True",
                  false: "False"
                }
              },
              { 
                name: "Scheduled / Repetitive Event Duration (Hrs)", 
                inputType: DynamicViewType.BaseViewField, 
                viewTypes: [BaseViewFieldType.Text], 
                clearField: true,
                key: 'repeatsDuration', 
                isRequired: true, 
                label: 'Scheduled / Repetitive Event Duration (Hrs)', 
                columnWidth: 6,
                displayType: 'isRepetitive',
                displayControls: {
                  'repeatsDuration': (value: any) => value === true
                }
              },
              { 
                name: "Scheduled / Repetitive Repeats On", 
                inputType: DynamicViewType.BaseViewField, 
                viewTypes: [BaseViewFieldType.MultiSelect], 
                clearField: true,
                key: 'repeats', 
                isRequired: true, 
                label: 'Scheduled / Repetitive Repeats On', 
                columnWidth: 6,
                displayType: 'isRepetitive',
                displayControls: {
                  'repeats': (value: any) => value === true
                }
              },
              {
                name: "Link Destination", 
                inputType: DynamicViewType.NestedList, 
                clearField: true, key: 'link_ref', 
                label: 'Link Destination',
                options: { fieldName: 'Link Destination', keys: ['linkText', 'linkDestination'] },
                columnWidth: 6
              },
              // Earn X Currency
              {
                name: "Challenge Type",
                inputType: DynamicViewType.MiscRef,
                clearField: true,
                key: 'currency_ref',
                label: 'Currency',
                controller: 'currencies',
                columnWidth: 6
              },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Rewards Data",
          fields:
            [
              { name: "Rewards", inputType: DynamicViewType.Price, clearField: true, key: 'rewards_ref', isRequired: true, label: 'Rewards' },
              { name: "Promo Rewards", inputType: DynamicViewType.Price, clearField: true, key: 'promoRewards_ref', isRequired: true, label: 'Promo Rewards' },
            ],
          type: DynamicCardType.Card
        }
      ]
    this.rewardRecord =
    {
      enabled: false
    };
    this.title = "Reward";
    this.viewRoute = 'rewards';
    this.isLoading = false;
  }

}
