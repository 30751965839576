import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isArray } from 'lodash';
import { take } from 'rxjs/operators';
import { DataService } from './../services/data.service';
import { FieldBase } from './field-base';
import { PrimeNGConfig } from 'primeng/api';
import * as moment from 'moment';
import { FieldControlService } from './../services/field-control.service';
import { FieldService } from 'src/app/services/field.service';
import { LoggerService } from '../common/services/logger.service';
import { SetDefaultTimeService } from '../common/services/set-default-time.service';
import { UtilitiesService } from '../common/services/utilities.service';

@Component({
  selector: 'field',
  templateUrl: './dynamic-field.component.html',
  styleUrls: ['./dynamic-field.component.sass'],
})
export class DynamicFieldComponent implements OnInit, OnDestroy {
  @Input() form!: UntypedFormGroup;
  @Input() formGroup!: UntypedFormGroup;
  @Input() field!: FieldBase<string>;

  @Input() editMode: boolean;
  @Input() editDocument: any;

  @Output() addField = new EventEmitter();
  @Output() replaceField = new EventEmitter();
  @Output() removeField = new EventEmitter();

  currentConditionalFieldRefs: any[] = [];
  defaultDate: any;

  get isValid() {
    return this.formGroup.controls[this.field.key].valid;
  }
  get isDirty() {
    return this.formGroup.controls[this.field.key].dirty;
  }
  get isTouched() {
    return this.formGroup.controls[this.field.key].touched;
  }
  get fieldKey() {
    return this.formGroup.controls[this.field.key];
  }

  constructor(
    private dataService: DataService,
    private primengConfig: PrimeNGConfig,
    private fieldService: FieldService,
    private fcs: FieldControlService,
    private loggerService: LoggerService,
    private utilitiesService: UtilitiesService,
    private setDefaultTimeService: SetDefaultTimeService,
  ) {}

  async ngOnInit() {
    this.primengConfig.ripple = true;
    this.defaultDate = this.utilitiesService.getCurrentDateAtMidnight()

    // populate field options.
    await this.getOptions();

    // check field conditions.
    await this.checkConditions();

    // stuff for an edit form.
    if (this.editMode) {
      // populate field if in edit mode.
      await this.populateFieldForEdit();
    }
  }

  async getOptions() {
    // make sure we can access "this" from inside another loop.
    const _this = this;

    // get field options
    await new Promise(async function (resolve, reject) {
      if (_this.field.optionsModel && !_this.field.options) {
        // console.log('getting options for ' + _this.field.key);
        const data = await _this.dataService.getAllOfTypeAsync(
          _this.field.optionsModel,
          {
            query: {},
          }
        );
        _this.field.options = data;
      }
      resolve(true);
    });

  }

  /**
   * TODO consider moving the condition from the "child" to the "parent" to prevent subscribe recursion.
   */
  async checkConditions() {
    // make sure we can access "this" from inside another loop.
    const _this = this;

    /**
     * check conditions.
     *
     * TODO: change to check all params before continuing with action. currently performing action on every param check.
     */
    if (this.field.conditions) {
      // console.log('conditions', this.field.conditions);
      // loop through all conditions (will most likely only have one)
      this.field.conditions.forEach(function (condition: any) {
        // loop through all parameters of the condition.
        condition.parameters.forEach(async function (param: any) {
          // check condition parameters (booleans)
          // console.log('param', param);

          // wait for other fields to load completely.
          await new Promise((f) => setTimeout(f, 250));

          // get current value of field we are comparing against.
          const fieldValue = _this.formGroup.controls[param.field].value;
          console.log('field value', fieldValue);

          // subscribe to any changes on the dependent field.
          // take(1) allows it to only subscribe once within the loops
          _this.formGroup
            .get(param.field)
            ?.valueChanges.pipe(take(1))
            .subscribe({
              next: (value) => {
                console.log('changed value', value);
                // clear the field if the dependent field value changes.
                // TODO: this is only useful for option swapping. may be conflicting for other actions.
                _this.clearValue();
                // run through conditions check again.
                _this.checkConditions();
              },
            });

          if (fieldValue) {
            // define operator functions for comparing. (this should get moved to it's own function probably)
            const ops: any = {
              '!==': function (val1: any, val2: any) {
                // make sure we use actual null instead of string "null".
                // TODO: can we make this a nullable field so we don't need to to this?
                val2 = val2 == 'null' ? null : val2;
                return val1 !== val2;
              },
            };

            // console.log(
            //   'paramResult',
            //   ops[param.op](fieldValue, param.value)
            // );

            // if param result is true, continue with condition.
            if (ops[param.op](fieldValue, param.value)) {
              const action = condition.action;
              // console.log('condition action', action);
              switch (action) {
                case 'setOptionsFromRef':
                  const options = condition.options;
                  // console.log('options', options);
                  try {
                    if (options.collection !== '') {
                      // get document we are referencing for new options
                      const document = await _this.dataService.getDocumentAsync(
                        options.collection,
                        {
                          query: { _id: fieldValue },
                        }
                      );
                      // console.log('document', document);
                      // if we retrieved a document from the DB, we can continue.
                      if (document && document[options.prop]) {
                        _this.field.options = document[options.prop];
                      } else {
                        // no reference document found or no property matching on the document...
                        // reset field and options.
                        _this.formGroup.get(_this.field.key)?.reset();
                        _this.formGroup.get(_this.field.key)?.markAsTouched();
                        _this.field.options = [];
                      }
                    } else {
                      // no collection provided in options. reset field and options.
                      // console.log('no collection provided');
                      _this.formGroup.get(_this.field.key)?.reset();
                      _this.formGroup.get(_this.field.key)?.markAsTouched();
                      _this.field.options = [];
                    }
                  } catch (err) {}
                  break;
                case 'setOptions':
                  break;
              }
            }
          } else {
            // no field value found in dependent field. reset this field and options.
            // console.log('no field value found or is empty');
            _this.formGroup.get(_this.field.key)?.reset();
            _this.formGroup.get(_this.field.key)?.markAsTouched();
            _this.field.options = [];
          }
        });
      });
    }
    console.log('END OF STEP 2 ' + `[${this.field.key}]`);
  }

  ngOnDestroy() {}

  async getSuggestionsForRef(e: any) {
    const _this = this;
    console.log('source', _this.field.suggestionsSource);
    let query: any = {};
    let select: string;
    switch (_this.field.suggestionsSource) {
      case 'items':
        select = '_id name id path';
        query = {
          $or: [
            { name: { $regex: e, $options: 'i' } },
            { id: { $regex: e, $options: 'i' } },
          ],
        };
        break;

      default:
        select = '_id name id';
        query = { name: { $regex: e, $options: 'i' } };
        break;
    }

    await new Promise(async function (resolve, reject) {
      if (e.length >= 3) {
        const data = await _this.dataService.getAllOfTypeAsync(
          _this.field.suggestionsSource,
          {
            query: isNaN(e) ? query : { id: e },
            select: select,
            virtuals: false,
            autopopulate: false,
          }
        );

        _this.field.suggestions = data;
      }
    });
  }

  async removeConditionalFields() {
    console.log('removeConditionalFields()');
    if (this.currentConditionalFieldRefs) {
      this.currentConditionalFieldRefs.forEach((ref: string) => {
        this.formGroup.removeControl(ref);
        this.removeField.emit(ref);
      });
      this.currentConditionalFieldRefs = [];
    }
  }

  async handleInputEvents(e: any) {
    console.log(`handleInputEvents(${e})`);
    const _this = this;

    // remove existing conditionals, if present
    this.removeConditionalFields();

    if (this.field.inputEvents) {
      this.field.inputEvents.forEach(async (f) => {
        // if conditional field matches selection, add to FormGroup
        if (f.inputRef === e) {
          f.fieldRefs.forEach(async (id: string, index: number) => {
            await new Promise(async function (resolve, reject) {
              let field: FieldBase<string>;

              await _this.fieldService
                .getFieldByIdFromJSON(id)
                .then(async (data) => {
                  field = data;

                  _this.formGroup.addControl(
                    field!.key,
                    _this.fcs.toFormControl(field!)
                  );
                  _this.addField.emit({
                    key: _this.field.key,
                    field: field,
                    i: index + 1,
                  });
                  _this.currentConditionalFieldRefs.push(field!.key);
                });
            });
          });
        }
      });
    }
  }

  /**
   * TODO: move individual cases to handler functions.
   */
  async populateFieldForEdit(): Promise<any> {
    const _this = this;

    console.log('START OF STEP 3 ' + `[${this.field.key}]`);
    await new Promise((resolve, reject) => {
      console.log('populating field ' + this.field.key + ' for edit');
      // const f = this.formGroup;
      const document = this.editDocument;
      const key = this.field.key;

      console.log('key', key);

      // // make sure the document has a value in the first place.
      console.log('document value', document[key]);
      if (document[key] !== null && document[key] !== undefined) {
        if (
          key != 'year' &&
          moment(document[key], moment.ISO_8601, true).isValid()
        ) {
          // handle date field
          console.log(key + ' is a date field ');
          this.formGroup.get(key)!.patchValue(new Date(document[key]));
          this.formGroup.get(key)!.markAsDirty();
          resolve(true);
        } else {
          if (key.indexOf('_ref') > -1) {
            // if field is a "_ref" field, we need to treat it a little different.
            console.log('ref value ' + key, document[key]);
            console.log('control type first', _this.field.controlType);
            // if the ref value is an array...
            if (isArray(document[key])) {
              console.log(
                JSON.stringify(document[key]) + ' is an array ',
                JSON.stringify(_this.field)
              );
              // handle autocomplete fields.
              if (_this.field.controlType == 'autoComplete-multi_ref') {
                // get documents
                let vals: any[] = [];
                document[key].forEach(function (element: any) {
                  vals.push({
                    _id: element._id,
                    id: element.id,
                    name: element.name,
                  });
                });
                this.form.get(key)!.patchValue(vals);
                this.form.get(key)!.markAsDirty();
                resolve(true);
              } else {
                // set form value to array of _id's.
                let vals: any[] = [];
                document[key].forEach(function (element: any) {
                  vals.push(element._id);
                });
                this.form.get(key)!.patchValue(vals);
                this.form.get(key)!.markAsDirty();
                resolve(true);
              }
            } else {
              // ref is not an array. set form value to the single _id.
              // handle autocomplete fields.
              console.log('control type', _this.field.controlType);
              if (_this.field.controlType == 'autoComplete_ref') {
                console.log(document[key] + ' is not an array');
                console.log('value to patch', document[key]['_id']);
                this.form.get(key)!.patchValue({
                  _id: document[key]['_id'],
                  id: document[key]['id'],
                  name: document[key]['name'],
                });
                this.form.get(key)!.markAsDirty();
                resolve(true);
              } else {
                console.log(document[key] + ' is not an array');
                console.log('value to patch', document[key]['_id']);
                this.form.get(key)!.patchValue(document[key]['_id']);
                this.form.get(key)!.markAsDirty();
                resolve(true);
              }
            }
          } else {
            // field is not a "_ref" field. just set the value.
            console.log('patch value', document[key]);
            this.formGroup.get(key)!.patchValue(document[key]);
            this.formGroup.get(key)!.markAsDirty();
            resolve(true);
          }
        }
      } else {
        console.log('key ' + key + ' is null or empty');
        resolve(true);
      }
    });
    console.log('END OF STEP 3 ' + `[${this.field.key}]`);
  }

  clearValue() {
    this.removeConditionalFields();
    this.formGroup.get(this.field.key)!.reset();
  }

  /**
   * TODO: fix issue with key being replaced by color hex value.
   * @param key
   * @param data
   */
  public onColorChange(key: string, data: any): void {
    this.form.get(key)?.setValue(data.value)!;
    console.log('field', this.field);
  }

  
  /**
   * Sets current date at midnight
   * @param patchValue checks if the value needs to be patched or not. This will be determined via if the form is using FormGroups or not.
   */
   setDefaultTime(prop: any) {
    this.setDefaultTimeService.setDefaultTime(true,this.form,prop);
  }
}
