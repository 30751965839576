<!-- Environment(s) -->
<div class="p-mr-2">
    <h4>Environment(s):</h4>
  </div>
  <span class="p-grid p-text-center p-d-none p-d-lg-flex">
    <div class="p-col p-m-2" [ngClass]="areEnvValuesOk">
      <h3 class="p-d-inline p-m-0 p-text-center">
        {{ entity.env && entity.env.length > 0 ? entity.env.join(', ') : 'No Environment Found' }}
      </h3>
    </div>
  </span>
  
  <!-- Master Build Status (lg screen) -->
  <ng-container *ngFor="let field of buildStatusFieldGroup">
    <div class="p-mr-2">
      <h4>{{ field.name }}:</h4>
    </div>
    <span class="p-grid p-text-center p-d-none p-d-lg-flex">
      <div class="p-col p-mx-2" [ngStyle]="{
          position: 'relative',
          color: 'var(--primary-color-text)',
          'background-color': entity[field.key] && entity[field.key].buildStatus
            ? entity[field.key].buildStatus.color
            : '',
          'border-radius': '0.5em'
        }">
        <ng-container *ngIf="entity[field.key] && entity[field.key].buildStatus"
          [ngSwitch]="entity[field.key].buildStatus.text">
          <ng-container *ngSwitchCase="'No build data'">
            <h3 class="p-m-0 p-text-center">Not Build Data</h3>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <h3 class="p-d-inline p-m-0 p-text-center">
              {{ entity[field.key].buildStatus.text }}
            </h3>
            <p class="p-d-inline">
              {{ entity[field.key].buildStatus.date }}
            </p>
            <p-badge [value]="entity[field.key].buildStatus.buildCount" [style]="{
                'background-color': 'var(--text-color)',
                position: 'absolute',
                right: '20%',
                top: '-1em'
              }"></p-badge>
          </ng-container>
        </ng-container>
      </div>
    </span>
  </ng-container>
  
  <!-- Image Build Status Field Group -->
  <ng-container *ngFor="let field of imageBuildStatusFieldGroup">
    <h4>{{ field.name }}:</h4>
    <span class="p-grid p-text-center p-d-none p-d-lg-flex">
      <div *ngIf="entity[field.key] && entity[field.key].buildStatus" class="p-col p-mx-2" [ngStyle]="{
          position: 'relative',
          color: 'var(--primary-color-text)',
          'background-color': entity[field.key].buildStatus.color,
          'border-radius': '0.5em'
        }">
        <ng-container [ngSwitch]="entity[field.key].buildStatus.text">
          <ng-container *ngSwitchCase="'No build data'">
            <h3 class="p-m-0 p-text-center">No Build Data</h3>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <h3 class="p-d-inline p-m-0 p-text-center">
              {{ entity[field.key].buildStatus.text }}
            </h3>
            <p class="p-d-inline">
              {{ entity[field.key].buildStatus.date }}
            </p>
            <p-badge [value]="entity[field.key].buildStatus.buildCount" [style]="{
                'background-color': 'var(--text-color)',
                position: 'absolute',
                right: '20%',
                top: '-1em'
              }"></p-badge>
          </ng-container>
        </ng-container>
      </div>
    </span>
  </ng-container>  