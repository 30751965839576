import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'dynamic-dialog-form',
  templateUrl: './dynamic-dialog-form.component.html',
  styleUrls: ['./dynamic-dialog-form.component.sass'],
  providers: [MessageService]
})
export class DynamicDialogFormComponent implements OnInit, OnChanges {
  @Input() visible: boolean = false;
  @Input() header: string = '';
  @Input() fields: any[] = [];
  @Input() width: string = '450px';
  @Input() entity: string = '';
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onCancel = new EventEmitter<void>();

  form: FormGroup;
  submitting: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dataService: DataService,
    private messageService: MessageService
  ) {
    this.form = this.fb.group({});
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fields'] && !changes['fields'].firstChange) {
      this.initializeForm();
    }
  }

  private initializeForm(): void {
    const group: any = {};
    
    this.fields.forEach(field => {
      const validators = [];
      
      // Add required validator if specified
      if (field.required) {
        validators.push(Validators.required);
      }

      // Set default value based on field type
      let defaultValue: any = '';
      switch (field.type) {
        case 'checkbox':
          defaultValue = field.defaultValue !== undefined ? field.defaultValue : false;
          break;
        case 'number':
          defaultValue = 0;
          break;
        default:
          defaultValue = '';
      }

      group[field.key] = [defaultValue, validators];
    });

    this.form = this.fb.group(group);
  }

  async handleSubmit(): Promise<void> {
    if (this.form.valid && !this.submitting) {
      this.submitting = true;
      try {
        const formData = this.form.value;
        
        // Make API call to create new record using dataService
        await this.dataService.addNewRecord(this.entity, formData);
        
        // Show success message
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: `${formData.name || 'Item'} added successfully`
        });
        
        // Emit the form data for parent component handling
        this.onSubmit.emit(formData);
        
        // Close the dialog
        this.visibleChange.emit(false);
      } catch (error: any) {
        // Show error message
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.message || 'Failed to create item'
        });
      } finally {
        this.submitting = false;
      }
    }
  }

  handleCancel(): void {
    this.visibleChange.emit(false);
    this.onCancel.emit();
  }

  getFieldType(field: any): string {
    return field.type || 'text';
  }
}
