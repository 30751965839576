import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PlasticService
{
  private readonly assetBuilderUrl: string ="https://worker.flora-cms.bgamestudios.com";
  constructor(private http: HttpClient) {}

  async plasticListFiles(path: any)
  {
    let files = [];
    if(path && !_.isString(path)){
          path = path.path;
    }

     // Remove trailing '/' if it exists
    // if (path && path.endsWith('/')) {
    //   path = path.slice(0, -1);
    // } else {
    //   path = path+"*";
    // }
    path = path+"*";

    if(path)
    {
      let response = await firstValueFrom
      (
        this.http.post<any>(`${this.assetBuilderUrl}/plastic/listfiles`,
        {
          path: 'approved_assets/' + path
        })
      );

      if(response && response.files)
      {
        files = response.files;
      }
    }

    return files;
  }
}
