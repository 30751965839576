<div class="p-grid p-mx-5">
  <p-orderList [value]="this.record[this.field.key]" [listStyle]="{ 'max-height': '50rem', 'min-width': '50vw' }" header="{{headerTitle}}"
    [dragdrop]="true" (onReorder)="reOrderLists($event); onRecordChange(this.record[this.field.key])">
    <ng-template pTemplate="header">
      <div class="p-clearfix">
        <span>{{ headerTitle }}</span>
        <p-button [label]="addButtonText" class="p-ml-5" icon="pi pi-plus" (click)="addNestedField()"></p-button>
      </div>
    </ng-template>
    <ng-template pTemplate="item" let-individual_record let-i="index">
      <div class="flex flex-wrap justify-items-center text-center w-full">
        <div *ngFor="let subField of field.options.subFields;" class="mr-2 p-pt-4">
          <app-base-input-field
          [(fieldValue)]="individual_record[subField.key]"
          [record]="individual_record"
          [field]="subField"
          [isRequired]="subField.isRequired"
          [inputTypeFields]="subField.inputTypeFields"
          (fieldValueChange)="onFieldValueChange(subField.key, i, $event)"
          (isBaseInputValid)="handleBaseInputValidEvent($event)">
        </app-base-input-field>
        </div>
        <p-button styleClass="p-button-danger p-button-rounded" class="p-pt-4" (click)="removeField(i)">
          <i class="pi pi-trash" style="color: white;"></i>
        </p-button>
      </div>
    </ng-template>
  </p-orderList>
</div>
