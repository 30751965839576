<div>
    <h1 style="margin: 0 0 1em" class="p-mr-3 p-d-inline">
      {{ entity.name }} ({{ entity.id }})
    </h1>
    <a
      pButton
      pRipple
      label="Edit"
      type="button"
      class="p-d-inline"
      icon="pi pi-pencil"
      [routerLink]="['/', type, 'edit', entity.id]"
    ></a>
  </div>
  
  