<ng-container *ngIf="isRecord(); else assetArray">
  <div class="p-grid">
    <div class="p-col-12">
      <div class="image-label">{{ field.label }}</div>
      <div class="image-details">
        <strong>Name:</strong> {{ record[field.key]?.name || 'Not Available' }}
        <a href="/miscellaneous-build/{{ record[field.key]?.id || '' }}" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">
          ({{ record[field.key]?.id || 'Not Available' }})
        </a>
      </div>
      <div class="image-details">
        <strong>Path:</strong> {{ record[field.key]?.path || 'Not Available' }}
      </div>
    </div>
  </div>
</ng-container>

<ng-template #assetArray>
  <div *ngIf="isArray()" class="array-container">
    <div *ngFor="let asset of record[field.key]; let i = index" class="array-item">
      <div class="image-label">{{ field.label }}</div>
      <div class="image-details">
        <strong>Name:</strong> 
        <a href="/miscellaneous-build/{{ asset?.id }}" target="_blank">
          {{ asset?.name || 'Not Available' }}
        </a>
      </div>
      <div class="image-details">
        <strong>Path:</strong> {{ asset?.path || 'Not Available' }}
      </div>
      <hr *ngIf="i < record[field.key].length - 1">
    </div>
  </div>
</ng-template>