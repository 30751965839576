import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import UserPermissionDto from '../dto/UserPermissionDto';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService
{
  private readonly apiURLprefix : string = '/api/user-permissions';

  constructor(private http: HttpClient) {}

  /**
   * Retrieves all user permissions
   */
  async findAllUserPermissions(): Promise<Array<UserPermissionDto> | undefined>
  {
    return await this.http.get<Array<UserPermissionDto>>(`${this.apiURLprefix}`).toPromise();
  }

  /**
    * Retrieves all permissions tied to a user
    */
  async findAllPermissionsOfUser(userId: any): Promise<Array<UserPermissionDto> | undefined>
  {
    return await this.http.post<Array<UserPermissionDto>>(`${this.apiURLprefix}/findAll`, { query: { userId: userId }, autopopulate: true}).toPromise();
  }

  /**
   * Assign a permission to a given user.
   *
   * @param userPermission User Permission DTO object
   */
  async createUserPermission(userPermission: any): Promise<UserPermissionDto | undefined>
  {
    return await this.http.post<UserPermissionDto>(`${this.apiURLprefix}/add`, userPermission).toPromise();
  }
}
