import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';


@Component({
  selector: 'app-category-view',
  templateUrl: './category-view.component.html',
  styleUrls: ['./category-view.component.sass'],
})
export class CategoryViewComponent extends BaseDynamicViewComponent implements OnInit {
  categoryRecord: BaseEntityDto = new BaseEntityDto();
  constructor() {
    super();
  }

  /**
   * Category Form V2 Initial Implementation
   */
  ngOnInit() {
    this.fields =
      [
        {
          title: "Color Data",
          fields:
            [
              { name: "Name", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name', columnWidth: 6 },
              { name: "Required", 
                inputType: DynamicViewType.BaseViewField, 
                viewTypes: [BaseViewFieldType.Boolean], 
                clearField: true, key: 'required', 
                label: 'Required', 
                columnWidth: 6,
                booleanoptions: {
                  true: "Is Required",
                  false: "Not Required"
                }
              },
              { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time', columnWidth: 6 },
              { name: "End Date/Time", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time', columnWidth: 6 },
              { name: "Type(s)", inputType: DynamicViewType.AutoComplete, clearField: true, key: 'types_ref', label: 'Type(s)', columnWidth: 6 },
              { name: "Short Description", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'descriptionShort', label: " Short Description", columnWidth: 6 },
              { name: "Color", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.ColorDisplay], clearField: false, key: 'color', isRequired: true, label: 'Color', disabled: false, columnWidth: 6 },
              { name: "Average Coin Cost", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'averageCoinCost', label: "Average Coin Cost" },
              { name: "Average Diamond Cost", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'averageDiamondCost', label: "Average Diamond Cost" },
              { name: "Expected Coin Spend", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'expectedCoinSpend', label: "Expected Coin Spend" },
              { name: "Expected Diamond Spend", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'expectedDiamondSpend', label: "Expected Diamond Spend" },
              { name: "Organic", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'organic', label: 'Organic', columnWidth: 6 },
              { name: "Icon Size", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'iconSize', label: 'Icon Size', columnWidth: 6 },
              { name: "Bubble Size", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'bubbleSize', label: 'Bubble Size', columnWidth: 6 },
              { name: "Border Width", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'borderWidth', label: 'Border Width', columnWidth: 6 },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Audio",
          fields:
            [
              { name: "Spawn Audios", inputType: DynamicViewType.MiscImageRecordV2, viewMode: 'array', clearField: true, key: 'spawnAudios_ref', label: 'Spawn Audios', columnWidth: 6 },
              {
                name: "Spawn Audio Collections",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.MultiSelectObj],
                clearField: true,
                key: 'spawnAudioCollections_ref',
                label: 'Spawn Audio Collections',
                controller: 'audio-collections',
                columnWidth: 6
              },
              { name: "Loop Audios", inputType: DynamicViewType.MiscImageRecordV2, viewMode: 'array', clearField: true, key: 'loopAudios_ref', label: 'Loop Audios', columnWidth: 6 },
              {
                name: "Loop Audio Collections",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.MultiSelectObj],
                clearField: true,
                key: 'loopAudioCollections_ref',
                label: 'Loop Audio Collections',
                controller: 'audio-collections',
                columnWidth: 6
              },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Icons",
          fields:
            [
              { name: "Icons", inputType: DynamicViewType.CategoryIcons, clearField: true, key: 'categoryIcons', isRequired: true, label: 'Icons' },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Bundle Assets",
          fields: [
            { name: "Bundle Image (IOS)", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'bundleImage', label: 'Bundle Image (IOS)', columnWidth: 6 },
            { name: "Bundle Image (AND)", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'bundleImageAnd', label: 'Bundle Image (AND)', columnWidth: 6 },
          ],
          type: DynamicCardType.Card
        },
      ]
    this.categoryRecord =
    {
      enabled: false
    };
    this.title = "Category";
    this.viewRoute = 'categories';
    this.isLoading = false;

  }
}
