<div class="schedule-container">


    <div class="schedule-content">
        <app-schedule-sidebar [(isOpen)]="isSidebarOpen" (generateEvent)="onGenerateEventFromCart($event)">
        </app-schedule-sidebar>

        <!-- Grid View -->
        <div class="main-content">
            <!-- Header -->
            <div class="schedule-header">
                <h2 class="schedule-title">Content Schedule</h2>
                <div class="schedule-actions">
                    <div class="button-container">
                        <button pButton icon="pi pi-bars" pTooltip="Toggle Sidebar" tooltipPosition="top"
                            (click)="toggleSidebar()" class="p-button-sm p-button-rounded p-button-secondary">
                        </button>
                    </div>
                    <div class="button-container">
                        <button pButton icon="pi pi-filter-slash" pTooltip="Clear Filters" tooltipPosition="top"
                            (click)="clearFilters()"
                            [class]="'p-button-sm p-button-rounded ' + (activeFiltersCount > 0 ? 'p-button-success' : 'p-button-secondary')">
                        </button>
                        <span *ngIf="activeFiltersCount > 0" class="p-badge">{{activeFiltersCount}}</span>
                    </div>
                    <div class="button-container">
                        <button pButton icon="pi pi-sort-alt-slash" pTooltip="Clear Sorting" tooltipPosition="top"
                            (click)="clearSort()"
                            [class]="'p-button-sm p-button-rounded ' + (activeSortsCount > 0 ? 'p-button-success' : 'p-button-secondary')">
                        </button>
                        <span *ngIf="activeSortsCount > 0" class="p-badge">{{activeSortsCount}}</span>
                    </div>
                    <button pButton [label]="isGridView ? 'Calendar' : 'Table'"
                        [icon]="isGridView ? 'pi pi-calendar' : 'pi pi-table'" (click)="toggleView()"
                        class="p-button-sm p-button-success">
                    </button>
                    <button pButton label="Add Schedule Record" icon="pi pi-plus" (click)="showAddDialog()"
                        class="p-button-sm p-ml-3">
                    </button>
                </div>
            </div>

            <!-- Calendar View -->
            <div class="calendar-view" *ngIf="!isGridView">
                <full-calendar [options]="calendarOptions"></full-calendar>
            </div>

            <!-- Grid View -->
            <div class="ag-grid-schedule-table" *ngIf="isGridView">
                <ag-grid-angular #agGrid [class]="gridTheme" [gridOptions]="gridOptions"
                    [detailCellRendererParams]="detailCellRendererParams" (gridReady)="onGridReady($event)" [ngStyle]="{
                        'width': '100%',
                        'height': getGridHeight()
                    }">
                </ag-grid-angular>
            </div>
        </div>
    </div>

    <!-- Schedule Dialog -->
    <p-dialog [(visible)]="displayAddDialog" [modal]="true"
        [style]="{width: '95vw', maxWidth: '800px', minWidth: '300px'}" [draggable]="false" [resizable]="false"
        [closeOnEscape]="true" [dismissableMask]="true" [responsive]="true" styleClass="custom-schedule-dialog">
        <app-content-schedule-view-form *ngIf="displayAddDialog" [mode]="mode" [scheduleId]="selectedSchedule?.id"
            [initialScheduleData]="selectedSchedule" (cancel)="displayAddDialog = false" (submitSuccess)="onSubmitSuccess()"
            (modeChange)="mode = $event"></app-content-schedule-view-form>
    </p-dialog>

</div>