import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { OptionsParams } from 'src/app/common/services/utilities.service';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';


@Component({
  selector: 'pp-seasonal-prizes-ag-grid',
  templateUrl: './seasonal-prizes-ag-grid.component.html',
  styleUrls: ['./seasonal-prizes-ag-grid.component.sass']
})
export class SeasonalPrizesAgGridComponent implements OnInit {

  entity: string = 'seasonal-prizes';
  columnDefs: ColDef[] = [];
  columnSelection: string;
  autopopulateSelect: string = '';
  public bulkUpdateColDefs: any[] = [];
  private options: any = {};

  constructor(
    private agGridToolsService: AgGridToolsService
  ) { }

  async ngOnInit(): Promise<void> {    
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        cellRendererParams: {
          entity: this.entity
        },
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Display Name',
        field: 'name',
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        cellRenderer: TextCellRendererComponent,
        floatingFilter: true,
      },   
      {
        headerName: 'Internal Name',
        field: 'internalName',
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          entity: this.entity
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      }, 
      {
        headerName: 'Start',
        field: 'start',
        sortable: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: this.agGridToolsService.dateValueGetter('start'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'End',
        field: 'end',
        sortable: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: this.agGridToolsService.dateValueGetter('end'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Popup Banner',
        field: 'popupBanner_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'Popup Banner',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.popupBanner_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Popup Icon',
        field: 'popupIcon_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'Popup Icon',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.popupIcon_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Slide Down Image',
        field: 'slideDownImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'Slide Down Image',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.slideDownImage_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Bundle 1',
        field: 'bundle1_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'Bundle 1',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.bundle1_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Bundle 2',
        field: 'bundle2_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'Bundle 2',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.bundle2_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Bundle 3',
        field: 'bundle3_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'Bundle 3',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.bundle3_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Rewards Track',
        field: 'rewardsTrack_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-tracks',
          isGuaranteedRarity: false
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.rewardsTrack_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        },
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Inboxes',
        field: 'inboxes_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'inbox-messages',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.inboxes_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name start end'
        },
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Collections',
        field: 'collections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.collections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        },
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
    ];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      { name: 'Internal Name', id: 'internalName', type: 'text' },
      { name: 'Popup Banner', id: 'popupBanner_ref', type: 'text' },
      { name: 'Popup Icon', id: 'popupIcon_ref', type: 'text' },
      { name: 'Slide Down Image', id: 'slideDownImage_ref', type: 'text' },
      { name: 'Bundle 1', id: 'bundle1_ref', type: 'text' },
      { name: 'Bundle 2', id: 'bundle2_ref', type: 'text' },
      { name: 'Bundle 3', id: 'bundle3_ref', type: 'text' },
      { name: 'Rewards Track', id: 'rewardsTrack_ref', type: 'text' },
      { name: 'Inboxes', id: 'inboxes_ref', type: 'multiselect' },
      { name: 'Collections', id: 'collections_ref', type: 'multiselect' },
    ];
  }
}