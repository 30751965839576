<div class="p-my-5 p-inputgroup" *ngIf="!isLoading">
  <div class="p-m-3">
    <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
      <div>
        <p-fieldset class="p-formgroup-inline">
          <ng-template pTemplate="header">
            <div class="p-formgroup-inline">
              <span class="p-my-auto p-mr-2">Images(s)</span>
              <button
                pButton
                icon="pi pi-plus p-panel-header-icon"
                class="p-button-rounded p-button-text p-button"
                (click)="addImage()"
                type="button"
              ></button>
            </div>
          </ng-template>
          <span class="p-formgroup-inline">
            <div
              *ngFor="let image of images; let pointIndex = index"
            >
              <p-card class="p-formgroup p-m-2 p-shadow-4">
                <ng-template pTemplate="header">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-times"
                    class="p-button-rounded p-button-text p-button-danger float-right"
                    (click)="deleteImage(pointIndex)"
                  ></button>
                </ng-template>

                <ng-template pTemplate="content">
                  <div class="p-m-3">
                    <span
                      [style]="{ 'min-width': '16.5em' }"
                      ngClass="p-float-label"
                    >
                      <input
                        [(ngModel)]="image.n"
                        pInputText
                        class="field-item"
                        (ngModelChange)="onImagesChange.emit(images)"
                      />
                      <label for="n"> Name </label>
                    </span>
                  </div>
                  <div class="p-m-3">
                    <span
                      [style]="{ 'min-width': '16.5em' }"
                      ngClass="p-float-label p-my-5"
                    >
                      <p-dropdown
                        [(ngModel)]="image.t"
                        class="field-item"
                        [options]="['null', 'Filled', 'Empty']"
                        (onChange)="onImagesChange.emit(images)"
                      ></p-dropdown>
                      <label for="t"> Loading Screen State </label>
                    </span>
                  </div>
                  <div class="p-my-5 p-mx-3">
                    <span
                      [style]="{ 'min-width': '16.5em' }"
                      ngClass="p-float-label"
                    >
                      <input
                        [(ngModel)]="image.p"
                        pInputText
                        class="field-item"
                        (ngModelChange)="onImagesChange.emit(images)"
                      />
                      <label for="p"> Path </label>
                    </span>
                  </div>
                </ng-template>
              </p-card>
            </div>
          </span>
        </p-fieldset>
      </div>
    </span>
  </div>
</div>
