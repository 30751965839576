import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { GamedataService } from 'src/app/build-gamedata/services/gamedata.service';
import JSConfetti from 'js-confetti'; 
import { SpinnerService } from 'src/app/common/services/spinner.service';

@Component({
  selector: 'app-storegamedataview',
  templateUrl: './storegamedataview.component.html',
  styleUrls: ['./storegamedataview.component.sass'],
  providers: [ConfirmationService],
})
export class StoregamedataviewComponent implements OnInit {
  isLoading: boolean = false;
  exportAll: boolean = false;
  isInit: boolean = true;
  environmentName: 'development' | 'production' | 'test';
  @ViewChild('opPartial') opPartial!: OverlayPanel;
  @ViewChild('opFull') opFull!: OverlayPanel;

  successConfetti: Array<any> = [
    '🌸', '🌷', '🌺', '💮', '💐', '🌹', '🌼', '🌻', '🌱', '🌴', '🌲', '🌳', '🌿',
  ];
  failedConfetti: Array<any> = ['💩'];
  jsConfetti = new JSConfetti();

  constructor(private confirmationService: ConfirmationService, private gamedataservice: GamedataService, private spinnerService: SpinnerService) { }

  ngOnInit() {
    this.environmentName = this.getEnvironmentName();
    this.isInit = false;
  }
  
  getEnvironmentName(): 'development' | 'production' | 'test' {
    if (window.location.href.includes('prod.cms')) {
      return 'production';
    } else if (window.location.href.includes('test.cms')) {
      return 'test';
    } else {
      return 'development';
    }
  }

  onExportAllChange(event: any): void {
    this.exportAll = event.checked;
    console.log('Export All switch changed:', this.exportAll);
  }

  displayConfirmModal(env: 'dev' | 'qa' | 'prod'): void {
    const messages: { [key: string]: string } = {
      'dev': 'Development',
      'qa': 'QA',
      'prod': 'Production',
    };

    this.confirmationService.confirm({
      message: `Are you sure you want to export store data for ${messages[env]}?`,
      header: `Export Store Data - ${messages[env]}`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onExportData(env);
      },
    });
  }

  onExportData(env: 'dev' | 'qa' | 'prod' | 'test'): void {
    console.log(`Exporting data for ${env}`);
    this.isLoading = true;
    this.spinnerService.loadSpinner(); 
    const exportType = this.exportAll ? "full" : "partial";
    console.log(`Starting a ${exportType} export process.`);

    this.gamedataservice.buildStoreData(env, this.exportAll)
      .then(response => {
        console.log(`Data export for ${env} (${exportType} export) completed:`, response);
        this.jsConfetti.addConfetti({
          emojis: this.successConfetti,
          confettiNumber: 100,
        });
        this.isLoading = false;
        this.spinnerService.endSpinner(); 
      })
      .catch(error => {
        console.error(`Error exporting data for ${env}:`, error);
        this.jsConfetti.addConfetti({
          emojis: this.failedConfetti,
          confettiNumber: 30,
        });
        this.isLoading = false;
        this.spinnerService.endSpinner(); 
      });
  }


}

