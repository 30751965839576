<ng-container *ngSwitchCase="'n/a'">
    <h3 class="p-m-0 p-text-center">Not Found</h3>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <h3 class="p-d-inline p-m-0 p-text-center">
      {{ record[field.key].buildStatus.text }}
    </h3>
    <p class="p-d-inline">
      {{ record[field.key].buildStatus.date }}
    </p>

    <p-badge
      [value]="record[field.key].buildStatus.buildCount"
      [style]="{
        'background-color':  record[field.key]
        ? record[field.key].buildStatus.color
        : '',
        position: 'absolute',
        right: '20%',
        top: '-1em'
      }"
    ></p-badge>
  </ng-container>
