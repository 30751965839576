import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import _ from 'lodash';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';


@Component({
  selector: 'app-sourcing-group-form',
  templateUrl: './sourcing-group-form.component.html',
  styleUrls: ['./sourcing-group-form.component.sass'],
})
export class SourcingGroupFormComponent implements OnInit {
  key: string = 'sourcing-groups';
  id: number;
  isEditMode: boolean = false;
  submitUrl: string;
  batchSubmitUrl: string;
  options: any = [];
  suggestions: any = [];
  existingDoc: any = {};
  uploadedFiles: any[] = [];
  currentFiles: any[] = [];
  loading: boolean = false;

  sourcingGroupForm: UntypedFormGroup;
  fields: any = {
    name: null,
    batch_ref: null,
    start: null,
    end: null,
    enabled: true,
    assetType: null,
    year: null,
    contentStatus: null,
    vendorStatus: null,
  };
  display: boolean = false;
  displayShown: boolean = false;
  createNewBatch: boolean = false;
  displayCreateNewBatchModal: boolean = false;

  // batchForm
  batchForm: UntypedFormGroup;
  batchFormFields: any = {
    id: null,
    _id: null,
    name: null,
    batchNumber: null,
    vendor_ref: null,
    enabled: true,
    year: null,
  };

  action: string = 'add';
  submitMessage: string = 'Submit';
  isNewBatch: boolean = true;
  updateBatch: boolean = false;
  batchError: boolean = false;
  existingBatchMessage: string = 'Create Sourcing group and use EXISTING Batch';
  newBatchMessage: string = 'Create Sourcing group and NEW Batch';
  updateBatchFormTouched: boolean = false;

  constructor(
    private logger: LoggerService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private authService: AuthService,
    private dataService: DataService,
    private messageService: MessageService,
    private utilitiesService: UtilitiesService,
    private titleService: Title,
    private commonEntityService: CommonEntityService,
    private validationService: ValidationsService,
    private router: Router
  ) {}

  async ngOnInit() {
    this.loading = true;
    this.logger.log('init');

    // Url to submit to the form to.
    this.submitUrl = `${this.key}/${this.action}`;
    this.batchSubmitUrl = `batches/add`;

    // check for ID for edit mode.
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    if (this.id) {
      this.isEditMode = true;
      this.updateBatch = true;
      this.isNewBatch = false;
      // change submit url for updating existing entity.
      this.submitUrl = `${this.key}/update/${this.id}`;

      // get existing document.
      console.log('get existing doc');
      await this.getExistingDoc().then(async (result) => {
        this.existingDoc = result;
        // set existing values from doc.
        console.log('set existing values');
        await this.setExistingValues().then(async () => {
          // init form.
          console.log('init form');
          await this.initForm();
        });
        if(this.existingDoc.batch_ref){
          this.batchSubmitUrl = `batches/update/${this.existingDoc.batch_ref.id}`;
          await this.setBatchExistingValues().then(async () => {
            await this.initBatchForm();
          });
        }
        await this.setOptions();
      });
    } else {
      // initialize form
      await this.initForm();
      await this.initBatchForm();
      await this.setOptions();
    }

    if (this.isEditMode) {
      this.titleService.setTitle(`Edit ${this.fields.name}(${this.id})`)
    } else {
      this.titleService.setTitle(`Add New Sourcing Group `)
    }
    this.loading = false;
    console.log('end of init');
  }

  async getExistingDoc() {
    const doc = await this.dataService.getDocumentAsync(this.key, {
      query: { id: this.id },
      autopopulate: true,
      virtuals: true,
    });
    this.logger.log('Existing doc', doc);
    return doc;
  }

  /**
   * Set batch existing values
   */
  async setBatchExistingValues() {
    if (this.existingDoc.batch_ref.name) {
      this.batchFormFields.name = this.existingDoc.batch_ref.name;
    }
    if (this.existingDoc.batch_ref.batchNumber) {
      this.batchFormFields.batchNumber = this.existingDoc.batch_ref.batchNumber;
    }
    if (this.existingDoc.batch_ref.vendor_ref) {
      this.batchFormFields.vendor_ref =
        this.existingDoc.batch_ref.vendor_ref._id;
    }
    if (this.existingDoc.batch_ref.enabled) {
      this.batchFormFields.enabled = this.existingDoc.batch_ref.enabled;
    }
    if (this.existingDoc.batch_ref.year) {
      this.batchFormFields.year = this.existingDoc.batch_ref.year;
    }
    if (this.existingDoc.batch_ref._id) {
      this.batchFormFields._id = this.existingDoc.batch_ref._id;
    }
    if (this.existingDoc.batch_ref.id) {
      this.batchFormFields.id = this.existingDoc.batch_ref.id;
    }
  }

  async setExistingValues() {
    if (this.existingDoc.name) {
      this.fields.name = this.existingDoc.name;
    }
    if (this.existingDoc.batch_ref) {
      this.fields.batch_ref = this.existingDoc.batch_ref._id;
    }
    if (this.existingDoc.start) {
      this.fields.start = new Date(this.existingDoc.start);
    }
    if (this.existingDoc.end) {
      this.fields.end = new Date(this.existingDoc.end);
    }
    if (this.existingDoc.enabled) {
      this.fields.enabled = this.existingDoc.enabled;
    }
    if (this.existingDoc.assetType) {
      this.fields.assetType = this.existingDoc.assetType;
    }
    if (this.existingDoc.year) {
      this.fields.year = this.existingDoc.year;
    }
    if (this.existingDoc.contentStatus) {
      this.fields.contentStatus = this.existingDoc.contentStatus;
    }
    if (this.existingDoc.vendorStatus) {
      this.fields.vendorStatus = this.existingDoc.vendorStatus;
    }
  }

  /**
   * initialize form with original values.
   */
  async initForm() {
    this.sourcingGroupForm = this.fb.group({
      name: [this.fields.name, Validators.required],
      batch_ref: [this.fields.batch_ref, Validators.required],
      start: [this.fields.start],
      end: [this.fields.end],
      enabled: [this.fields.enabled, Validators.required],
      assetType: [this.fields.assetType, Validators.required],
      contentStatus: [this.fields.contentStatus],
      vendorStatus: [this.fields.vendorStatus],
      year: [this.fields.year, Validators.required],
    });
  }

  /**
   * Unitialize batch form with values.
   */
  async initBatchForm() {
    this.batchForm = this.fb.group({
      name: [this.batchFormFields.name, Validators.required],
      batchNumber: [this.batchFormFields.batchNumber, Validators.required],
      vendor_ref: [this.batchFormFields.vendor_ref, Validators.required],
      enabled: [this.batchFormFields.enabled, Validators.required],
    });
  }

  /**
   * set all field options for the form.
   */
  async setOptions() {
    // get batches
    await this.getOptionsFromRef('batches', 'batches', true, false);
    // get options if users decide to create a new batch
    await this.getOptionsFromRef('vendor_ref', 'vendors', true, true);
    this.options['contentStatus'] = [
      'Approved',
      'In Production',
      'In Testing',
      'On Hold',
      'Scheduled',
      'Test Ready',
    ];
    this.options['year'] = [
      '2021',
      '2022',
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
      '2030',
    ];
    this.options['vendorStatus'] = [
      'Approved',
      'Assigned',
      'Awaiting Batch',
      'Awaiting Revision',
      'Sent to Vendor',
    ];
    this.options['assetType'] = [
      'Hard Surface',
      'HS Recolor',
      'Organics',
      'Organic Recolors',
    ];
    this.options['batchStatus'] = [
      'Approved',
      'In Production',
      'In Testing',
      'On Hold',
      'Scheduled',
      'Test Ready',
    ];
  }

  /**
   * Get/set options from a reference entity type.
   *
   * @param fieldName
   * @param model
   * @param minimal
   */
  async getOptionsFromRef(
    fieldName: string,
    model: string,
    minimal: boolean = false,
    autopopulate: boolean = true
  ) {
    const options = await this.dataService.getAllOfTypeAsync(model, {
      query: {},
      autopopulate: autopopulate,
      virtuals: true,
      sort: { name: 1 },
    });
    if (minimal) {
      let o: any[] = [];
      for (const option of options) {
        o.push({ id: option.id, name: option.name, _id: option._id });
      }
      this.options[fieldName] = o;
    } else {
      this.options[fieldName] = options;
    }
  }

  /**
   * Clears SG form's field value
   */
  clearSGField(fieldKey: string) {
    this.sourcingGroupForm.get(fieldKey)!.reset();
  }
  /**
   * clears a form's field value
   */
  clearField(fieldKey: string) {
    this.batchForm.get(fieldKey)!.reset();
  }

  /**
   * Resets the batch form.
   * Changes the submit message.
   */
  handleCreateBatchToggleChange()
  {
    this.displayCreateNewBatchModal = !this.displayCreateNewBatchModal;
    this.batchForm.reset();
    this.batchForm.patchValue({ enabled: true });
    this.submitMessage = this.newBatchMessage;
  }

  /** clears sourcingGroupForm field's values  */
  clearSourcingGroupFormField(fieldKey: string) {
    this.sourcingGroupForm.get(fieldKey)!.reset();
    this.batchForm.reset();
    this.isNewBatch = true;
  }

  /**
   * Sets default date and time value if start/end date value is empty
   *
   * @param isStartDate Flag that sets whether or not is start date field
   */
  setDefaultTime(isStartDate: boolean) {
    if (isStartDate) {
      if (
        !this.sourcingGroupForm.value.start ||
        this.sourcingGroupForm.value.start.length <= 0
      ) {
        this.sourcingGroupForm.patchValue({
          start: this.utilitiesService.getCurrentDateAtMidnight(),
        });
      }
    } else {
      if (
        !this.sourcingGroupForm.value.end ||
        this.sourcingGroupForm.value.end.length <= 0
      ) {
        this.sourcingGroupForm.patchValue({
          end: this.utilitiesService.getCurrentDateAtMidnight(),
        });
      }
    }
  }

  /**
   * Function that generates the batch name out of batchNumber and vendor selections
   * @param type
   * @param event
   */
  async onNameComponentChange(type: string, event: any) {
    switch (type) {
      case 'batchNumber':
        if (this.batchForm.get('vendor_ref')!.value) {
          this.batchFormFields.name = `${event} - ${
            this.options['vendor_ref'].find((vendor: any) => {
              return vendor._id === this.batchForm.get('vendor_ref')!.value;
            }).name
          }`;
        } else {
          this.batchFormFields.name = `${event} - [vendor]`;
        }
        this.batchForm.get('name')?.markAsTouched();
        this.isNewBatch = true;
        this.submitMessage = this.newBatchMessage;
        break;
      case 'vendor':
        if (this.batchForm.get('batchNumber')!.value) {
          this.batchFormFields.name = `${
            this.batchForm.get('batchNumber')!.value
          } - ${
            this.options['vendor_ref'].find((vendor: any) => {
              return vendor._id === event;
            }).name
          }`;
        } else if (
          event &&
          (typeof event === 'string' || event instanceof String)
        ) {
          this.batchFormFields.name = `[batchNumber] - ${
            this.options['vendor_ref'].find((vendor: any) => {
              return vendor._id === event;
            }).name
          }`;
        }
        this.batchForm.get('name')?.markAsTouched();
        this.isNewBatch = true;
        this.submitMessage = this.newBatchMessage;
        break;
      case 'existingBatch':
        console.log('existingBatch', event);
        this.isNewBatch = false;
        this.submitMessage = this.existingBatchMessage;
        if (this.updateBatch) {
          await this.getExistingBatchDoc(event).then(async (result) => {
            this.existingDoc.batch_ref = result;
            this.batchSubmitUrl = `batches/update/${result.id}`;
            let batch = {
              _id: result._id,
              id: result.id,
              name: result.name,
              batchNumber: result.batchNumber
                ? result.batchNumber
                : result.name && result.name.includes('-')
                ? result.name.substring(0, result.name.indexOf('-'))
                : result.name,
              vendor_ref: result.vendor_ref ? result.vendor_ref._id : null,
              start: result.start,
              year: result.year,
              contentStatus: result.contentStatus,
              vendorStatus: result.vendorStatus,
              assetType: result.assetType,
              batchStatus: result.batchStatus,
              end: result.end,
              enabled: result.enabled,
            };
            this.batchForm.reset();
            this.batchFormFields = batch;
            this.logger.log('Result: ', result);
            this.batchForm.patchValue(batch);
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * Get existing batch document by Id
   *
   * @param id Id of the batch document
   */
  async getExistingBatchDoc(id: any) {
    const doc = await this.dataService.getDocumentAsync('batches', {
      query: { _id: id },
      autopopulate: true,
      virtuals: true,
    });
    return doc;
  }

  /**
   * Submits Sourcing Group record.
   */
  async onSubmitSourcingGroup()
  {
    this.messageService.add({
      sticky: true,
      severity: 'info',
      summary: 'Loading',
      detail: `Loading...`,
    });

    if(this.updateBatchFormTouched && this.batchFormFields._id)
    {
      await this.onUpdateBatch();
    }
    // sourcing group data
    var sourcingGroupValues = this.sourcingGroupForm.value;
    // userData from authService
    let userResult = this.authService.getSocialUser();
    sourcingGroupValues.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };
    sourcingGroupValues.batch_refIdString = this.sourcingGroupForm.value.batch_ref;

    if(!this.isEditMode)
    {
      let createResponse = await this.commonEntityService.create(this.key, sourcingGroupValues);

      if(createResponse && createResponse.id)
      {
        this.messageService.add({
          sticky: true,
          severity: 'success',
          summary: 'Submit Successful',
          detail: `"${sourcingGroupValues.name}" was successfully created`,
        });
        this.router.navigate([`/sourcing-groups/${createResponse.id}`]);
      }
      else
      {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Batch Submit Error',
          detail: 'There was an error submitting.',
        });
      }
    }
    else
    {
      let updateResponse = await this.commonEntityService.update(this.key, this.id, sourcingGroupValues);

      if(updateResponse && updateResponse.id)
      {
        this.messageService.add({
          sticky: true,
          severity: 'success',
          summary: 'Update Successful',
          detail: `"${sourcingGroupValues.name}" was successfully updated`,
        });
        this.router.navigate([`/sourcing-groups/${updateResponse.id}`]);
      }
      else
      {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Batch Submit Error',
          detail: 'There was an error submitting.',
        });
      }
    }
  }

  /**
   * Updates batch record.
   */
  async onUpdateBatch()
  {
    let batchRecord = _.cloneDeep(this.batchForm.value);
    let isValid = this.validateBatch(batchRecord);

    let request: any = {};
    let touchedFields = [];

    let properties = Object.keys(this.batchForm.controls);
    properties.forEach(prop =>
    {
      if(this.batchForm.controls[prop].touched)
      {
        touchedFields.push(prop);
        request[prop] = this.batchForm.controls[prop].value;
      }
    });

    if(isValid && touchedFields.length > 0)
    {
      // UserData from authService
      let userResult = this.authService.getSocialUser();
      request.userData = {
        name: userResult.currentUser.name,
        email: userResult.currentUser.email,
        id: userResult.currentUser.id,
      };
      let response = await this.commonEntityService.update('batches', this.batchFormFields.id, request);

      if(response && response.id)
      {
        this.existingDoc.batch_ref = response;
        let batch =
        {
          id: response.id,
          _id: response._id,
          name: response.name,
          batchNumber: response.batchNumber
            ? response.batchNumber
            : response.name && response.name.includes('-')
            ? response.name.substring(0, response.name.indexOf('-'))
            : response.name,
          vendor_ref: response.vendor_ref ? response.vendor_ref._id : null,
          start: response.start,
          year: response.year,
          contentStatus: response.contentStatus,
          vendorStatus: response.vendorStatus,
          assetType: response.assetType,
          batchStatus: response.batchStatus,
          end: response.end,
          enabled: response.enabled,
        };
        // get batches
        await this.getOptionsFromRef('batches', 'batches', true, false);
        this.batchFormFields = batch;
        this.batchForm.patchValue(batch);
        this.sourcingGroupForm.patchValue({ batch_ref: batch._id });

        this.displayCreateNewBatchModal = false;
        this.messageService.add({
          sticky: true,
          severity: 'success',
          summary: 'Submit Successful',
          detail: `Batch was successfully updated.`,
        });
      }
      else
      {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Batch Submit Error',
          detail: 'There was an error submitting.',
        });
      }
    }
  }

  /**
   * Create Batch Record
   */
  async onCreateBatch()
  {
    let batchRecord = _.cloneDeep(this.batchForm.value);
    let isValid = this.validateBatch(batchRecord);

    if(isValid)
    {
      // UserData from authService
      let userResult = this.authService.getSocialUser();
      batchRecord.userData = {
        name: userResult.currentUser.name,
        email: userResult.currentUser.email,
        id: userResult.currentUser.id,
      };
      let response = await this.commonEntityService.create('batches', batchRecord);

      if(response && response.id)
      {
        this.existingDoc.batch_ref = response;
        let batch =
        {
          _id: response._id,
          id: response.id,
          name: response.name,
          batchNumber: response.batchNumber
            ? response.batchNumber
            : response.name && response.name.includes('-')
            ? response.name.substring(0, response.name.indexOf('-'))
            : response.name,
          vendor_ref: response.vendor_ref ? response.vendor_ref._id : null,
          start: response.start,
          year: response.year,
          contentStatus: response.contentStatus,
          vendorStatus: response.vendorStatus,
          assetType: response.assetType,
          batchStatus: response.batchStatus,
          end: response.end,
          enabled: response.enabled,
        };
        this.batchForm.reset();
        // get batches
        await this.getOptionsFromRef('batches', 'batches', true, false);
        this.batchFormFields = batch;
        this.batchForm.patchValue(batch);
        this.sourcingGroupForm.patchValue({ batch_ref: batch._id });

        this.displayCreateNewBatchModal = false;
        this.messageService.add({
          sticky: true,
          severity: 'success',
          summary: 'Submit Successful',
          detail: `Batch was successfully created.`,
        });
      }
      else
      {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Batch Submit Error',
          detail: 'There was an error submitting.',
        });
      }
    }
  }

  /**
   * Validates Batch record.
   *
   * @param batch Batch record to validate.
   */
  validateBatch(batch: any)
  {
    if (this.validationService.isEmpty(batch.batchNumber))
    {
      this.alertMessage(false, 'Submit Error', 'Batch number is required.');
      return false;
    }
    if (this.validationService.isEmpty(batch.vendor_ref))
    {
      this.alertMessage(false, 'Submit Error', 'Vendor is required.');
      return false;
    }
    return true;
  }

  /**
   * Display alter message
   *
   * @param success Flag that sets whether or not is a succes alert
   * @param message Message to display.
   * @param detail Additional details.
   */
  alertMessage(success: boolean = true, message: string, detail: string)
  {
    this.messageService.add({
      sticky: true,
      severity: success ? 'success' : 'error',
      summary: message,
      detail: detail,
    });
  }
}
