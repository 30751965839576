import { LoggerService } from 'src/app/common/services/logger.service';
import { Injectable } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient } from '@angular/common/http';
import { CommonEntityService } from '../common/services/common-entity.service';
import * as _ from 'lodash';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { BuildService } from 'src/app/build-data/services/build.service'

const defaultBuildStatus: any =  {
  text: 'No build data',
  date: '',
  color: 'var(--gray-400)',
  buildCount: 0,
}
@Injectable({
  providedIn: 'root',
})
export class TableService {
  constructor
  (
    private dataService: DataService,
    private buildService: BuildService,
    private http: HttpClient,
    private commonEntityService: CommonEntityService
  ) {}

  /**
   * Returns an array of entities that contain the data for the table rows.
   *
   * @param {string} type - string - The type of entity you want to get the rows for.
   * @param selectedColumns - An array of columns to be selected from the database.
   * @param {Array<string> | null} [customBuilDataKeys=null] - This is an array of strings that are
   * used to build the data object.
   * @param {any} [customQuery=null] - This is a custom query that you can pass in to filter the data.
   * @param {boolean} [getVirtuals=false] - boolean = false,
   * @param {boolean} [populateEntity=false] - boolean = false,
   * @param {number} sortOrder - 1 for ascending, -1 for descending
   * @returns An array of objects.
   */
  async getTableRowsV2
  (
    type: string,
    selectedColumns: Array<string> = [],
    customBuilDataKeys: Array<string> | null = null,
    customQuery: any = null,
    customSort: any = null,
    getVirtuals: boolean = false,
    populateEntity: boolean = false,
    defaultColumns: Array<string> = [],
    isPaginated: boolean = false,
    page: number = 0,
    pageSize: number = 100
  )
  {
    let rows = [];
    let select = '_id ';
    let columns = _.cloneDeep(selectedColumns);

    if(defaultColumns && defaultColumns.length > 0)
    {
      defaultColumns.forEach((column) =>
      {
        if(!columns.includes(column))
        {
          columns.push(column);
        }
      });
    }
    if(columns && columns.length > 0)
    {
      if(!columns.includes('id'))
      {
        columns.push('id');
      }
      if(!columns.includes('name'))
      {
        columns.push('name');
      }
      if(!columns.includes('enabled'))
      {
        columns.push('enabled');
      }

      select += columns.join(' ');
    }

    select = this.handleCustomColumns(select, columns, type);

    getVirtuals = this.handleVirtuals(getVirtuals, columns);

    let response = await this.dataService.getAllOfTypeAsync(
      type,
      {
        query : customQuery ? customQuery : { },
        sort : customSort ? customSort : { },
        select: select, virtuals: getVirtuals,
        autopopulate: populateEntity
      },
      isPaginated,
      page,
      pageSize
      );

    rows = isPaginated && response.records ? response.records : response;
    rows = await this.transformRows(rows, type, columns, customBuilDataKeys);

    return {
      paginationMeta: isPaginated ? response.paginationMeta : null,
      rows: rows
    };
  }

  async getTableRowsV3(props: any){
    let response = await this.dataService.getAllOfTypeAsync(
      props.type,
      {
        query : props.query || { },
        sort : props.sort || { },
        select: props.select || null,
        virtuals: props.virtuals || false,
        autopopulate: props.autopopulate || {},
        populate: props.populate || null,
        nestedPopulate: props.nestedPopulate,
        storeEnv: props.storeEnv || null,
        sortModel: props.sortModel || null,
        filterModel: props.filterModel || null,
        defaultQuery: props.defaultQuery || null,
        selectedRows: props.selectedRows || null,
        tableGlobalSearchQuery: props.tableGlobalSearchQuery || null,
        entities: props.entities || null,
      },
      true,
      props.page,
      props.pageSize
      );
    return response;
  }

  /**
   * Asynchronously fetches a bulk set of rows from the backend service for a specific type, based on various query parameters.
   *
   * This function internally calls `dataService.getAllOfTypeAsync` and can be customized with different query options such as sorting,
   * field selection, and more.
   *
   * @param {Object} props - An object containing various properties to configure the data fetching:
   *  - `type` (String): Specifies the type of entity to fetch.
   *  - `query` (Object): MongoDB-style query object. Defaults to an empty object.
   *  - `sort` (Object): Specifies the sorting order. Defaults to { id: 1 }.
   *  - `select` (String): Specifies which fields to include in the results. Defaults to '_id id name'.
   *  - `virtuals` (Boolean): Whether to include virtuals. Defaults to false.
   *  - `autopopulate` (Boolean): Automatically populate related fields. Defaults to false.
   *  - `populate` (String): Manually specify which fields to populate. Defaults to null.
   *  - `aggregatePipeline` (Array): Aggregation pipeline array. Defaults to null.
   *  - `smartPopulate` (any): Advanced options for populating related fields.
   *
   * @returns {Promise<any>} A Promise that resolves with the response from the data service.
   *
   */
  async getBulkActionRows(props: any) {
    let response = await this.dataService.getAllOfTypeAsync(
      props.type,
      {
        query: props.query || {},
        sort: props.sort || { id: 1 },
        select: props.select || '_id id name',
        virtuals: props.virtuals || false,
        autopopulate: props.autopopulate || false,
        populate: props.populate || null,
        populateMinimal: false,
        smartPopulate: props.smartPopulate
      },
      true,
      0,
      30000
    );
    return response;
  }


  /**
   * Handles rows transformation
   *
   * @param rows Table rows.
   * @param type Table type.
   * @param columns Table Columns.
   * @param customBuilDataKeys Custom Build Data keys.
   */
  async transformRows
  (
    rows: any,
    type: string,
    columns: Array<string> = [],
    customBuilDataKeys: Array<string> | null = null
  )
  {
    let isCustomDataTransformationNeeded = this.isCustomDataTransformationNeeded(type, columns, customBuilDataKeys);

    if(isCustomDataTransformationNeeded)
    {
      for(let row of rows)
      {
        await this.customDataTransformation(type, row, columns);
      }
    }

    return rows;
  }

  /**
   * Handle virtual columns
   *
   * @param fetchVirtuals
   * @param columns
   */
  handleVirtuals(fetchVirtuals: boolean, columns: Array<string>)
  {
    if(columns.includes('thumbnail_url_128') || columns.includes('thumbnail_url_64') || columns.includes('thumbnail_url_32') || columns.includes('thumbnail_url_256'))
    {
      return true;
    }

    return fetchVirtuals;
  }

  /**
   * Hanldes custom columns select logic.
   *
   * Since some of the columns we have on
   * CMS tables depends on some other properties,
   * we should retrieve those in order to set the value
   * for that column.
   *
   * @param selectQuery Current select query.
   * @param columns Table columns.
   */
  handleCustomColumns(selectQuery: string, columns: Array<string>, tableType: string)
  {
    if(columns.includes('buildStatus') || columns.includes('masterBuildDate'))
    {
      // assetBuildOutput buildData
      if(tableType == 'items'){
        selectQuery += ' prefab_ref';
      } else if(tableType == 'challenges'){
        selectQuery += ' scene_ref';
      } else if(tableType == 'miscellaneous-build'){
        selectQuery += ' buildOutput buildData';
      }

    }

    if(columns.includes('imageBuildStatus'))
    {
      // selectQuery += ' imageBuildOutput imageBuildData';
      if(tableType == 'items'){
        selectQuery += ' thumbnail_ref';
      } else if(tableType == 'challenges'){
        selectQuery += ' image_ref';
      } else if(tableType == 'miscellaneous-build'){
        selectQuery += ' buildOutput buildData entityType assetType';
      }
    }
    if(columns.includes('thumbnail_url_128') || columns.includes('thumbnail_url_64') || columns.includes('thumbnail_url_32') || columns.includes('thumbnail_url_256'))
    {
      selectQuery += ' thumbnail thumbnails';
    }

    if(columns.includes('isAssetUpToDate'))
    {
      // selectQuery += ' assetLastHash asset_versions';
      if(tableType == 'items'){
        selectQuery += ' prefab_ref';
      } else if(tableType == ' challenges'){
        selectQuery += ' scene_ref';
      } else if(tableType == ' miscellaneous-build'){
        selectQuery += ' asset_versions image_versions lastHash entityType assetType';
      }
    }

    if(columns.includes('isImageUpToDate'))
    {
      // selectQuery += ' imageLastHash image_versions lastHash';
      if(tableType == 'items'){
        selectQuery += ' image_ref';
      } else if(tableType == 'challenges'){
        selectQuery += ' image_ref';
      } else if(tableType == 'miscellaneous-build'){
        selectQuery += ' asset_versions image_versions lastHash entityType assetType';
      }
    }

    if(columns.includes('scene_img'))
    {
      selectQuery += ' scene';
    }

    if(columns.includes('thumbnailPreview'))
    {
      selectQuery += ' image_ref';
    }

    if(columns.includes('env'))
    {
      //                                      items                                    challenges
      if(tableType == 'items'){
        selectQuery += ' vendorStatus enabled itemStatus start flagged costs_ref';
      } else if(tableType == 'challenges'){
        selectQuery += ' end prizes_ref';
      }
    }

    return selectQuery;
  }

  /**
   * Returns if custom data transformation is needed
   * for the selected columns.
   *
   * @param type Table type.
   * @param columns Table columns.
   * @param customBuilDataKeys Custom Build Data keys.
   */
  isCustomDataTransformationNeeded(type: string, columns: Array<string>, customBuilDataKeys: Array<any> | null = null)
  {
    return columns.includes('thumbnailPreview') || columns.includes('scene_img') || columns.includes('isAssetUpToDate') || columns.includes('isImageUpToDate') || columns.includes('imageBuildStatus') || columns.includes('buildStatus') || columns.includes('masterBuildDate') ||  ['loading-screens'].includes(type) || (customBuilDataKeys && customBuilDataKeys.length > 0);
  }

  /**
   * Handles record data transformation.
   *
   * @param type Table type.
   * @param row Record.
   * @param columns Table columns.
   * @param customBuilDataKeys Custom Build Data keys.
   */
  async customDataTransformation(type: string, row: any, columns: Array<any>, customBuilDataKeys: Array<any> | null = null)
  {
    switch (type)
    {
      case 'challenges':
        if(columns.includes('scene_img'))
        {
          // Render Room Image (For Challenges)
          if (row.scene && row.scene.length > 0) {
            const s3Cdn = 'https://d3tfb94dc03jqa.cloudfront.net/';
            let removeLevel = row.scene.lastIndexOf('s/');
            let removeLevelString = row.scene.substring(removeLevel + 1);

            const fileN =
              'images/bg_renders' + removeLevelString + '_bg_1024.png';

            let addBg = fileN.split('/');
            addBg[3] = `${addBg[3]}_bg`;
            let joinAddBg = addBg.join('/');

            if (joinAddBg) {
              let finalUrl = s3Cdn + joinAddBg;
              row.scene_img = finalUrl;
            }
          }
        }
        break;
      case 'currencies':
        if(columns.includes('thumbnailPreview'))
        {
          row.thumbnailPreview = row.image_ref && row.image_ref.thumbnails && row.image_ref.thumbnails.length > 0 ? row.image_ref.thumbnails[0].path : null;
        }
        break;
      case 'loading-screens':
        if(row.images && row.images.length > 0)
        {
          row.images.forEach((loadingScreen: any, index: number) =>
          {
            let prop = 'isImageUpToDate' + index.toString();

            if(columns.includes(prop))
            {
              if
              (
                loadingScreen.imageLastHash &&
                loadingScreen.image_versions &&
                loadingScreen.image_versions.length > 0
              )
              {
                row[prop] = loadingScreen.image_versions[
                  loadingScreen.image_versions.length - 1
                ].destinationPath.includes(loadingScreen.imageLastHash)
                  ? 'Up to date'
                  : 'Outdated';
              }
              else
              {
                row[prop] = 'No data';
              }
            }
          });
        }
        break;
    }

    // Is Asset Up to Date Column
    if(columns.includes('isAssetUpToDate'))
    {
      // determine which is the asset ref key name depending on the entity
      if(!(type=='miscellaneous-build')){
        let assetKeyName = this.buildService.getAssetKeyName('isAssetUpToDate', type);
        if(row[assetKeyName] && row[assetKeyName].entityType) {
          row.isAssetUpToDate = this.buildService.getPromotionStatus(row[assetKeyName], row[assetKeyName].entityType);
        } else {
          row.isAssetUpToDate = 'No data';
        }
      } else {
        if(row && row.entityType){
          row.isAssetUpToDate = this.buildService.getPromotionStatus(row, row.entityType);
        } else {
          row.isAssetUpToDate = 'No data';
        }
      }
    }

    // Is Image Up to Date Column
    if(columns.includes('isImageUpToDate'))
    {
      // determine which is the asset ref key name depending on the entity
      if(!(type=='miscellaneous-build')){
        let assetKeyName = this.buildService.getAssetKeyName('isImageUpToDate', type);
        if(row[assetKeyName] && row[assetKeyName].entityType) {
          row.isImageUpToDate = this.buildService.getPromotionStatus(row[assetKeyName], row[assetKeyName].entityType);
        } else {
          row.isImageUpToDate = 'No data';
        }
      } else {
        if(row && row.entityType){
          row.isImageUpToDate = this.buildService.getPromotionStatus(row, row.entityType);
        } else {
          row.isImageUpToDate = 'No data';
        }
      }
    }

    // Image Build Status
    if(columns.includes('imageBuildStatus'))
    {
      if(!(type=='miscellaneous-build')){
        let assetKeyName = this.buildService.getAssetKeyName('imageBuildStatus', type)
        if(row[assetKeyName] && row[assetKeyName].buildOutput){
          row.imageBuildStatus = this.getBuildStatusData(
            row[assetKeyName].buildOutput
          );
        } else if(row[assetKeyName] && row[assetKeyName].buildData){
          row[assetKeyName].buildData = await this.dataService.getBuildData(
            row[assetKeyName].buildData
          );
          row.imageBuildStatus = this.getBuildStatus(row[assetKeyName].buildData);
        } else {
          row.imageBuildStatus = defaultBuildStatus;
        }
      } else {
        if(row.buildOutput){
          row.imageBuildStatus = this.getBuildStatusData(row.buildOutput);
        } else if (row.buildOutput){
          row.imageBuildStatus = this.getBuildStatus(row.buildData);
        }
      }
    }

    // Build Status
    if(columns.includes('buildStatus') || columns.includes('masterBuildDate'))
    {
      let record: any;
      if(!(type=='miscellaneous-build')){
        let assetKeyName = this.buildService.getAssetKeyName('buildStatus', type);
        record = row[assetKeyName];
      }
      else {
        record = row;
      }

      if(record && record.buildOutput){
        row.buildStatus = this.getBuildStatusData(
          record.buildOutput
        );
        row.masterBuildDate = this.setMasterBuildDate(
          record.buildOutput
        );
      } else if(record && record.buildData){
        record.buildData = await this.dataService.getBuildData(
          record.buildData
        );
        row.buildStatus = this.getBuildStatus(record.buildData);
      } else {
        row.buildStatus = defaultBuildStatus;
      }
    }

    // Custom Build Data
    if (customBuilDataKeys && customBuilDataKeys.length > 0)
    {
      for (let key of customBuilDataKeys)
      {
        if(columns.includes('key'))
        {
          if (row[key])
          {
            row[key] = await this.dataService.getBuildData(row[key]);
            let status = key.replace('Data', 'Status');
            row[status] = this.getBuildStatus(row[key]);
          }
          else
          {
            row[key] = [];
            let status = key.replace('Data', 'Status');
            row[status] = {
              text: 'No build data',
              date: '',
              color: 'var(--gray-400)',
              buildCount: 0,
            };
          }
        }
      }
    }

    return row;
  }

  /**
   * Retrieves all rows for a given table type
   *
   * @param type Table type.
   * @param customBuilDataKeys Custom Build data keys
   */
  async getTableRows(
    type: string,
    customBuilDataKeys: Array<string> | null = null,
    customQuery: any = null,
    isPaginated: boolean = false,
    page: number = 0,
    pageSize: number = 50,
  ) {
    let rows = [];
    let paginatedResponse: any = {};
    if(!isPaginated){
      rows = await this.dataService.getAllOfTypeAsync(type, customQuery ? customQuery : { query: {} });
    } else {
      // todo enable custom queries on paginated tables...
      paginatedResponse = await this.dataService.getAllOfTypeAsync(type, { query: {} }, true, page, pageSize);
      rows = paginatedResponse.records;
    }

    if (rows) {
      for (let element of rows) {
        if(type == 'currencies')
        {
          element.thumbnailPreview = element.image_ref && element.image_ref.thumbnails ? element.image_ref.thumbnails : null;
        }
        element.isAssetUpToDate = element.isImageUpToDate = 'No data';

        if (
          element.assetLastHash &&
          element.asset_versions &&
          element.asset_versions.length > 0
        ) {
          element.isAssetUpToDate = element.asset_versions[
            element.asset_versions.length - 1
          ].destinationPath.includes(element.assetLastHash)
            ? 'Up to date'
            : 'Outdated';
        }

        if (
          element.imageLastHash &&
          element.image_versions &&
          element.image_versions.length > 0
        ) {
          element.isImageUpToDate = element.image_versions[
            element.image_versions.length - 1
          ].destinationPath.includes(element.imageLastHash)
            ? 'Up to date'
            : 'Outdated';
        }

        if (
          element.lastHash &&
          element.image_versions &&
          element.image_versions.length > 0
        ) {
          element.isImageUpToDate = element.image_versions[
            element.image_versions.length - 1
          ].destinationPath.includes(element.lastHash)
            ? 'Up to date'
            : 'Outdated';
        }

        if(type == 'loading-screens')
        {
          if(element.images && element.images.length > 0)
          {
            element.images.forEach((loadingScreen: any, index: number) =>
            {
              let prop = 'isImageUpToDate' + index.toString();

              if (
                loadingScreen.imageLastHash &&
                loadingScreen.image_versions &&
                loadingScreen.image_versions.length > 0
              ) {
                element[prop] = loadingScreen.image_versions[
                  loadingScreen.image_versions.length - 1
                ].destinationPath.includes(loadingScreen.imageLastHash)
                  ? 'Up to date'
                  : 'Outdated';
              }
              else
              {
                element[prop] = 'No data';
              }
            });
          }
        }

        if (customBuilDataKeys && customBuilDataKeys.length > 0) {
          for (let key of customBuilDataKeys) {
            if (element[key]) {
              element[key] = await this.dataService.getBuildData(element[key]);
              let status = key.replace('Data', 'Status');
              element[status] = this.getBuildStatus(element[key]);
            } else {
              element[key] = [];
              let status = key.replace('Data', 'Status');
              element[status] = {
                text: 'No build data',
                date: '',
                color: 'var(--gray-400)',
                buildCount: 0,
              };
            }
          }
        } else {
          // Asset Build Outputs
          if (element.assetBuildOutput) {
            element.buildStatus = this.getBuildStatusData(
              element.assetBuildOutput
            );
            element.masterBuildDate = this.setMasterBuildDate(
              element.assetBuildOutput
            );
          } else {
            // build data/status
            if (element.buildData && type != 'items' && type != 'challenges' && type != 'miscellaneous-build' && type != 'external-plant-data') {
              element.buildData = await this.dataService.getBuildData(
                element.buildData
              );
              element.buildStatus = this.getBuildStatus(element.buildData);
            } else {
              element.buildData = [];
              element.buildStatus = {
                text: 'No build data',
                date: '',
                color: 'var(--gray-400)',
                buildCount: 0,
              };
            }
          }

          // Image Build outputs
          if (element.imageBuildOutput) {
            if(element.imageBuildOutput['lin'])
            {
              delete element.imageBuildOutput['lin']
            }
            element.imageBuildStatus = this.getBuildStatusData(
              element.imageBuildOutput
            );
          } else {
            // image build data/status
            if (
              element.imageBuildData &&
              type != 'items' &&
              type != 'challenges' &&
              type != 'miscellaneous-build' &&
              type != 'external-plant-data'
            ) {
              element.imageBuildData = await this.dataService.getBuildData(
                element.imageBuildData
              );
              element.imageBuildStatus = this.getBuildStatus(
                element.imageBuildData
              );
            } else {
              element.imageBuildData = [];
              element.imageBuildStatus = {
                text: 'No build data',
                date: '',
                color: 'var(--gray-400)',
                buildCount: 0,
              };
            }
          }

          // build status for other entities (misc-images for now) - TODO unify all this shit
          if (element.buildOutput && type == 'miscellaneous-build') {
            if(element.buildOutput['lin'])
            {
              delete element.buildOutput['lin']
            }
            element.imageBuildStatus = this.getBuildStatusData(
              element.buildOutput
            );
          }

          // Render Room Image (For Challenges)
          if (element.scene && element.scene.length > 0) {
            const s3Cdn = 'https://d3tfb94dc03jqa.cloudfront.net/';
            let removeLevel = element.scene.lastIndexOf('s/');
            let removeLevelString = element.scene.substring(removeLevel + 1);

            const fileN =
              'images/bg_renders' + removeLevelString + '_bg_1024.png';

            let addBg = fileN.split('/');
            addBg[3] = `${addBg[3]}_bg`;
            let joinAddBg = addBg.join('/');

            if (joinAddBg) {
              let finalUrl = s3Cdn + joinAddBg;
              element.scene_img = finalUrl;
            }
          }
        }
      }
    }

    if(!isPaginated){
      return rows;
    } else if(isPaginated && paginatedResponse && paginatedResponse.paginationMeta){
      return {
        records: rows,
        paginationMeta: paginatedResponse.paginationMeta
      }
    }

  }

  async getRows(type: string, customBuilDataKeys: Array<string> = []) {
    let rows = await this.dataService
      .getAllOfTypeAsync(type, { query: {} })
      .then(async (data) => {
        await data.forEach(async (row: any) => {
          row.isAssetUpToDate = row.isImageUpToDate = 'No data';

          if (
            row.assetLastHash &&
            row.asset_versions &&
            row.asset_versions.length > 0
          ) {
            row.isAssetUpToDate =
              row.assetLastHash ==
              row.asset_versions[row.asset_versions.length - 1].destinationPath
                ? 'Up to date'
                : 'Outdated';
          }

          if (
            row.imageLastHash &&
            row.image_versions &&
            row.image_versions.length > 0
          ) {
            row.isImageUpToDate =
              row.imageLastHash ==
              row.image_versions[row.image_versions.length - 1].destinationPath
                ? 'Up to date'
                : 'Outdated';
          }

          if (
            row.lastHash &&
            row.image_versions &&
            row.image_versions.length > 0
          ) {
            row.isImageUpToDate =
              row.lastHash ==
              row.image_versions[row.image_versions.length - 1].destinationPath
                ? 'Up to date'
                : 'Outdated';
          }
          if (customBuilDataKeys && customBuilDataKeys.length > 0) {
            for (let key of customBuilDataKeys) {
              if (row[key]) {
                row[key] = await this.dataService.getBuildData(row[key]);
                let status = key.replace('Data', 'Status');
                row[status] = this.getBuildStatus(row[key]);
              } else {
                row[key] = [];
                let status = key.replace('Data', 'Status');
                row[status] = {
                  text: 'No build data',
                  date: '',
                  color: 'var(--gray-400)',
                  buildCount: 0,
                };
              }
            }
          } else {
            // build data/status
            if (row.buildData) {
              row.buildData = await this.dataService.getBuildData(
                row.buildData
              );
              row.buildStatus = this.getBuildStatus(row.buildData);
            } else {
              row.buildData = [];
              row.buildStatus = {
                text: 'No build data',
                date: '',
                color: 'var(--gray-400)',
                buildCount: 0,
              };
            }
            // image build data/status
            if (row.imageBuildData) {
              row.imageBuildData = await this.dataService.getBuildData(
                row.imageBuildData
              );
              row.imageBuildStatus = this.getBuildStatus(row.imageBuildData);
            } else {
              row.imageBuildData = [];
              row.imageBuildStatus = {
                text: 'No build data',
                date: '',
                color: 'var(--gray-400)',
                buildCount: 0,
              };
            }
          }
        });
        return data;
      });

    return rows;
  }
  async getSourceRows(type: string, sourceGroup_ref: string) {
    let rows = await this.dataService.getAllOfTypeAsync(type, {
      query: { sourceGroup_ref: { _id: sourceGroup_ref } },
    });

    // if (rows) {
    //   for (let element of rows) {
    //     element.isLocked = element.promoted || element.isLocked ? true : false;
    //   }
    // }

    return rows;
  }

  getHeader(query: Object) {
    return this.dataService.getAllOfType('forms/fields/find', query);
  }

  async getUserPageSettings(pageRef: string, userRef: string) {
    return await this.dataService
      .getDocumentAsync(`user-settings`, {
        query: { pageRef, userRef },
      })
      .then(async (doc) => {
        let result: any;

        if (!doc) {
          result = null;
        } else {
          let settings = { ...doc.settings };
          settings.id = doc._id;
          result = settings;
        }

        return result;
      });
  }

  /**
   * Get Build Status Data for table display
   *
   * @param buildData Build data
   */
  getBuildStatusData(buildData: any) {
    let result;
    if (!buildData) {
      return {
        text: 'No build data',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }

    let statusCounts: any = {
      finished: [],
      failed: [],
      building: [],
      queued: [],
    };

    for (const key in buildData) {
      if (buildData.hasOwnProperty(key)) {
        if (buildData[key].status.toLowerCase() == 'queued') {
          statusCounts[buildData[key].status].push([buildData[key].insertedAt]);
        } else if (
          buildData[key].status.toLowerCase() == 'finished' ||
          buildData[key].status.toLowerCase() == 'failed'
        ) {
          statusCounts[buildData[key].status].push([buildData[key].finishedAt]);
        } else {
          statusCounts[buildData[key].status].push([buildData[key].startedAt]);
        }
      }
    }

    switch (Object.keys(buildData).length) {
      case statusCounts.finished.length:
        result = {
          text: 'Success',
          date: this.getMostRecent(statusCounts.finished),
          color: 'var(--primary-color)',
          buildCount: Object.keys(buildData).length,
        };
        break;

      case statusCounts.failed.length:
        result = {
          text: 'Full Fail',
          date: this.getMostRecent(statusCounts.failed),
          color: 'var(--pink-600)',
          buildCount: Object.keys(buildData).length,
        };
        break;

      case statusCounts.building.length:
        result = {
          text: 'Building',
          date: this.getMostRecent(statusCounts.building),
          color: 'var(--text-color)',
          buildCount: Object.keys(buildData).length,
        };
        break;

      case statusCounts.queued.length:
        result = {
          text: 'Queued',
          date: this.getMostRecent(statusCounts.queued),
          color: 'var(--text-color)',
          buildCount: Object.keys(buildData).length,
        };
        break;

      default:
        if (
          statusCounts.building.length > 0 &&
          statusCounts.building.length < Object.keys(buildData).length &&
          statusCounts.failed.length == 0
        ) {
          result = {
            text: 'Partial Building',
            date: this.getMostRecent(statusCounts.building),
            color: 'var(--yellow-600)',
            buildCount: statusCounts.building.length,
          };
        } else if (
          statusCounts.failed.length > 0 &&
          statusCounts.failed.length < Object.keys(buildData).length
        ) {
          result = {
            text: 'Partial Fail',
            date: this.getMostRecent(statusCounts.failed),
            color: 'var(--pink-600)',
            buildCount: statusCounts.failed.length,
          };
        } else if (
          statusCounts.queued.length > statusCounts.finished.length &&
          statusCounts.finished.length > 0
        ) {
          result = {
            text: 'Queued',
            date: this.getMostRecent(statusCounts.queued),
            color: 'var(--text-color)',
            buildCount: statusCounts.queued.length,
          };
        } else {
          result = {
            text: 'No build data',
            date: '',
            color: 'var(--gray-400)',
            buildCount: 0,
          };
        }
        break;
    }

    return result;
  }

  getBuildStatus(buildData: any) {
    if (!buildData || buildData.length < 1) {
      return { text: 'No build data', date: '', color: 'var(--gray-400)', buildCount: 0 };
    }

    let statusCounts: any = {
      successful: [],
      failed: [],
      building: [],
      queued: [],
    };

    buildData.forEach((build: any) => {
      switch (build.status.toLowerCase()) {
        case 'finished':
          statusCounts.successful.push(build.updatedAt);
          break;
        case 'failed':
          statusCounts.failed.push(build.updatedAt);
          break;
        case 'building':
          statusCounts.building.push(build.updatedAt);
          break;
        case 'queued':
          statusCounts.queued.push(build.insertedAt);
          break;
      }
    });

    let result;
    switch (buildData.length) {
      case statusCounts.successful.length:
        result = {
          text: 'Success',
          date: this.getMostRecent(statusCounts.successful),
          color: 'var(--primary-color)',
          buildCount: buildData.length,
        };
        break;

      case statusCounts.failed.length:
        result = {
          text: 'Full Fail',
          date: this.getMostRecent(statusCounts.failed),
          color: 'var(--pink-600)',
          buildCount: buildData.length,
        };
        break;

      case statusCounts.building.length:
        result = {
          text: 'Building',
          date: this.getMostRecent(statusCounts.building),
          color: 'var(--text-color)',
          buildCount: buildData.length,
        };
        break;

      case statusCounts.queued.length:
        result = {
          text: 'Queued',
          date: this.getMostRecent(statusCounts.queued),
          color: 'var(--text-color)',
          buildCount: buildData.length,
        };
        break;

      default:
        if (
          statusCounts.building.length > 0 &&
          statusCounts.building.length < buildData.length
        ) {
          result = {
            text: 'Building',
            date: this.getMostRecent(statusCounts.building),
            color: 'var(--text-color)',
            buildCount: statusCounts.building.length,
          };
        } else if (
          statusCounts.failed.length > 0 &&
          statusCounts.failed.length < buildData.length
        ) {
          result = {
            text: 'Partial Fail',
            date: this.getMostRecent(statusCounts.failed),
            color: 'var(--yellow-600)',
            buildCount: statusCounts.failed.length,
          };
        } else {
          result = {
            text: 'No build data',
            date: '',
            color: 'var(--gray-400)',
            buildCount: 0,
          };
        }
        break;
    }

    return result;
  }

  getMostRecent(dates: any[]) {
    let allDates: any[] = [];
    dates.forEach((d) => {
      allDates.push(Date.parse(d));
    });

    return new Date(Math.max(...allDates)).toLocaleDateString();
  }

  /**
   * Updates a entity object
   *
   * @param entityType Entity type value
   * @param entityId Id of the entity
   * @param payload Entity payload value
   */
  async updateEntity(entityType: string, entityId: any, payload: any) {
    return await this.http
      .patch<any>(`/api/${entityType}/update/${entityId}`, payload)
      .toPromise();
  }

  /**
   * Sets the Master Build Date value
   *
   * @param buildData Build data
   */
  setMasterBuildDate(buildData: any) {
    let dates = [];
    for (const key in buildData) {
      if (buildData.hasOwnProperty(key)) {
        if (buildData[key].status.toLowerCase() == 'queued') {
          dates.push(buildData[key].insertedAt);
        } else if (
          buildData[key].status.toLowerCase() == 'finished' ||
          buildData[key].status.toLowerCase() == 'failed'
        ) {
          dates.push(buildData[key].finishedAt);
        } else {
          dates.push(buildData[key].startedAt);
        }
      }
    }

    var maxDate = dates.reduce((a, b) => {
      return a > b ? a : b;
    });

    return maxDate;
  }

  /**
   * Renders assets.
   * Sends a slack notification with the preview link.
   *
   * @param payload Assets selected.
   */
  async renderAssets(payload: any): Promise<any>
  {
    return firstValueFrom(this.http.post<any>(`/api/build/bulk/render-assets`, payload));
  }

  async validateDeletion(payload: any): Promise<any> {   
    return firstValueFrom(this.http.post<any>(`/api/${payload.entityType}/delete-validate/${payload.id}`, payload))
  }

  async executeDeletion(payload: any) {
    const env = localStorage.getItem('selectedEnvironment');
    const envObject = env ? JSON.parse(env) : 'test';
    const envPayload = {...payload, env: envObject.value}
    return firstValueFrom(this.http.post<any>(`/api/${envPayload.entityType}/delete/${envPayload.id}`, envPayload))
  }
}
