import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import PricePointDTO from '../dtos/pricePointDTO';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { ActivatedRoute } from '@angular/router';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';

@Component({
  selector: 'app-price-point-view',
  templateUrl: './price-point-view.component.html',
  styleUrls: ['./price-point-view.component.sass']
})
export class PricePointViewComponent extends BaseDynamicViewComponent implements OnInit {
  activeTabIndex: number = 0; // Default to the first tab
  environments = ['dev', 'qa', 'prod']; // The order of these values should match the tab order
  recordId: number;
  storeEnvs: string[] = [];
  CmsEnabled: Object = {};
  selectedEnvironment: Object;


  handleTitleChange(newTitle: string) {
    this.title = newTitle;
  }
  handleIdChange(newId: number) {
    this.recordId = newId;
  }



  PricePointRecord: PricePointDTO = new PricePointDTO();
  constructor(
    private route: ActivatedRoute,
    private agGridToolsService: AgGridToolsService,
    private commonEntityService: CommonEntityService,
  ) {
    super();
  }

  async ngOnInit() {
    {
      this.initializeStoreEnvironments();
      this.loadSelectedEnvironment();
      this.selectedEnvironment = this.agGridToolsService.getSelectedEnvironment();
      this.recordId = Number(this.route.snapshot.paramMap.get('id'));
      this.CmsEnabled = (await this.commonEntityService.findOneWithQuery('price-points', {query: {id: this.recordId}, select: 'CmsEnabled'}))?.CmsEnabled
      if (!this.recordId || this.recordId <= 0) {
        console.error('Invalid recordId');
        return;
      }
      this.fields =
        [
          {
            title: "Price Point Data",
            fields:
              [
                {
                  name: "Name",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'name',
                  isRequired: true,
                  label: 'Name',
                  columnWidth: 12
                },
                {
                  name: "Non Consumable",
                  inputType: DynamicViewType.Boolean,
                  clearField: true,
                  key: 'nonConsumable',
                  label: 'Non Consumable',
                  columnWidth: 12
                },
                // {
                //   name: "Start Date/Time",
                //   inputType: DynamicViewType.BaseViewField,
                //   viewTypes: [BaseViewFieldType.Calendar],
                //   clearField: true,
                //   key: 'storeStart',
                //   label: 'Start Date/Time',
                //   columnWidth: 6
                // },
                // {
                //   name: "End Date/Time",
                //   inputType: DynamicViewType.BaseViewField,
                //   viewTypes: [BaseViewFieldType.Calendar],
                //   clearField: true,
                //   key: 'storeEnd',
                //   isRequired: true,
                //   label: 'End Date/Time',
                //   columnWidth: 6,
                // },
                {
                  name: 'Cost(s)',
                  label: 'Cost(s)',
                  inputType: DynamicViewType.Price,
                  key: 'costs_ref',
                },
                {
                  name: "Tags",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'tags',
                  isRequired: true,
                  label: 'Tags',
                  columnWidth: 12
                },
                {
                  name: "Google Checkout Petunia",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'GoogleCheckoutPetunia',
                  isRequired: true,
                  label: 'Google Checkout Petunia',
                  columnWidth: 6
                },
                {
                  name: "Apple App Store Petunia",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'AppleAppStorePetunia',
                  isRequired: true,
                  label: 'Apple App Store Petunia',
                  columnWidth: 6
                },
                {
                  name: "Playgami Petunia",
                  inputType: DynamicViewType.BaseViewField,
                  viewTypes: [BaseViewFieldType.Text],
                  clearField: true,
                  key: 'PlaygamiPetunia',
                  isRequired: true,
                  label: 'PlaygamiPetunia',
                  columnWidth: 6
                },
              ],
            type: DynamicCardType.Card
          }
        ]

      this.PricePointRecord.enabled = false;

      this.title = "Price Point";
      this.viewRoute = 'price-points';
      this.isLoading = false;

    }
  }

  initializeStoreEnvironments(): void {
    const currentUrl = window.location.href;

    if (currentUrl.includes('prod.cms')) {
      // Prod CMS: DEV, QA, PROD
      this.storeEnvs = ['dev', 'qa', 'prod'];

    } else if (currentUrl.includes('test.cms')) {
      // Test CMS: TEST
      this.storeEnvs = ['test'];
    } else {
      // local/Dev CMS: TEST, DEV, QA, PROD
      this.storeEnvs = ['test', 'dev', 'qa', 'prod'];
    }
    this.environments = this.storeEnvs;
  }

  loadSelectedEnvironment(): void {
    const savedEnvironment = localStorage.getItem('selectedEnvironment');
    if (savedEnvironment) {
      const environmentObject = JSON.parse(savedEnvironment);
      this.activeTabIndex = this.environments.indexOf(environmentObject.value);
    }
  }

  onTabChange(event: { index: number; }): void {

    this.activeTabIndex = event.index;
    const environmentToSave = {
      label: this.environments[this.activeTabIndex].charAt(0).toUpperCase() + this.environments[this.activeTabIndex].slice(1),
      value: this.environments[this.activeTabIndex]
    };
    localStorage.setItem('selectedEnvironment', JSON.stringify(environmentToSave));
  }

  // STORE RELATED FUNCTIONS:
  async addToCart() {
    this.agGridToolsService.addToCart('price-points', [this.recordId])
  }
}
