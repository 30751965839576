import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import SeedPackBoxTypesDTO from '../dtos/SeedPackBoxTypesDTO';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';

@Component({
  selector: 'app-seed-pack-box-type-view',
  templateUrl: './seed-pack-box-type-view.component.html',
  styleUrls: ['./seed-pack-box-type-view.component.sass']
})
export class SeedPackBoxTypeViewComponent extends BaseDynamicViewComponent implements OnInit {


  SeedPackBoxTypeRecord: SeedPackBoxTypesDTO = new SeedPackBoxTypesDTO();
  constructor() {
    super();
  }

  ngOnInit() {
    {
      this.fields =
      [
        {
          title: "Seed Pack Box Data",
          fields:
          [
            {
              name: "Name",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'name',
              isRequired: true,
              label: 'Name'
            },
          ],
          type: DynamicCardType.Card
        }
      ]

      this.SeedPackBoxTypeRecord.enabled = false;

      this.title = "Seed Pack Box Type";
      this.viewRoute = 'seed-pack-box-types';
      this.isLoading = false;
    }
  }
}
