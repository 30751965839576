<div class="custom-status-panel p-d-flex p-jc-between"
  style="
  display: flex;
  justify-content: space-between;
  align-items: center;  /* This will vertically center the items if your status bar has a height */
  width: 100%;"
>

  <!-- Page Size Dropdown using p-dropdown -->
  <p-dropdown
  [options]="pageSizes" [(ngModel)]="selectedPageSize"
  (onChange)="onPageSizeChange($event)" optionLabel="label" optionValue="value"
  appendTo="body"
  ></p-dropdown>

  <!-- Record Count -->
  <span class="record-count"
    style="
    flex-grow: 1;  /* This will make sure the record count takes the available space */
    text-align: center;  /* This centers the text */"
  >
    {{startRow}} to {{endRow}} from {{totalCount}}
  </span>

  <div class="page-controls"
    style="
    display: flex;
    align-items: center;"
  >
    <!-- Page Controls using p-button -->
    <p-button icon="pi pi-step-backward" (click)="goToFirstPage()" class="p-button-xs" styleClass="p-button-rounded p-button-text"></p-button>
    <p-button icon="pi pi-caret-left" (click)="goToPrevPage()" class="p-button-xs" styleClass="p-button-rounded p-button-text"></p-button>
    <span class="page-number">{{params.api.paginationGetCurrentPage() + 1}}/{{params.api.paginationGetTotalPages()}}</span>
    <p-button icon="pi pi-caret-right" (click)="goToNextPageDebounced()" class="p-button-xs" styleClass="p-button-rounded p-button-text"></p-button>
    <p-button icon="pi pi-step-forward" (click)="goToLastPage()" class="p-button-xs" styleClass="p-button-rounded p-button-text"></p-button>
  </div>


</div>
