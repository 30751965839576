<div style="width: auto;">
  <h4 class="p-ml-2">CMS Default Presets</h4>

  <!-- Preset listbox -->
  <p-listbox [options]="entityPresets" [(ngModel)]="selectedPreset" optionLabel="name" (onChange)="applyPreset()">
    <ng-template let-option pTemplate="item">
      <div class="list-item">{{option.name}}</div>
    </ng-template>
  </p-listbox>

  <!-- Custom Presets listbox -->
  <div class="custom-presets">
    <div style="display: flex; align-items: center;">
      <h4 class="p-mx-2">
        Custom Presets
      </h4>
      <p-button icon="pi pi-plus" (click)="startAddingPreset()" styleClass="p-button-rounded p-button-text"></p-button>
    </div>

    <!-- Input for adding custom preset -->
    <div *ngIf="isAddingPreset">
      <input type="text" pInputText [(ngModel)]="newPresetName" placeholder="Preset name" class="p-m-1" style="width: 60%;">
      <p-button
        icon="pi pi-save" styleClass="p-button-rounded p-button-text p-button-success p-button-sm"
        (click)="saveCustomPreset()"
      >
      </p-button>
      <p-button
        icon="pi pi-times" styleClass="p-button-rounded p-button-text p-button-danger p-button-sm"
        (click)="cancelAddingPreset()"
      >
      </p-button>
    </div>

    <p-listbox [options]="customPresets" [(ngModel)]="selectedPreset" optionLabel="name" (onChange)="applyPreset()" [filter]="true">
      <ng-template let-option pTemplate="item">
        <div class="list-item">
          <small class="p-mr-1">{{option.name}}</small>
          <!-- Info Button -->
          <button
            pButton type="button" icon="fa-solid fa-info" class="p-button-xs p-button-text p-mr-1"
            (click)="toggleExpand(option); $event.stopPropagation();"
          >
          </button>
          <!-- Trash Button -->
          <button
            pButton type="button" icon="pi pi-trash" class="p-button-xs p-button-danger p-button-text"
            (click)="deleteCustomPreset(option); $event.stopPropagation();"
          >
          </button>
          <!-- Collapsible Section -->
          <div *ngIf="expandedPreset === option">
            <hr>
            <p [ngStyle]="{'font-size': '13px'}">
              Columns:
            </p>
            <ul [ngStyle]="{'font-size': '12px'}">
              <li *ngFor="let column of option.columns">{{ column }}</li>
            </ul>
          </div>
        </div>
      </ng-template>
    </p-listbox>

  </div>

  <!-- CMS Users Presets listbox -->
  <div class="cms-presets">
    <div style="display: flex; align-items: center;">
      <h4 class="p-mx-2">
        CMS Users Presets
      </h4>
      <p-button icon="pi pi-sync" (click)="syncCmsPresets()" styleClass="p-button-rounded p-button-text"></p-button>
    </div>
    <p-listbox [options]="communityPresets" [(ngModel)]="selectedPreset" optionLabel="name" (onChange)="applyPreset()" [filter]="true">
      <ng-template let-option pTemplate="item">
        <div class="list-item">
          <small class="p-mr-1">{{option.name}}</small>
          <button
            pButton
            [icon]="isItemToggled(option) ? 'fa-solid fa-circle-info' : 'fa-solid fa-info'"
            (click)="toggleItemDetails(option, $event)"
            class="p-button-xs custom-toggle-button p-button-text">
          </button>
          <div *ngIf="isItemToggled(option)" class="expandable-details">
            <hr>
            <small>{{option.userRef}}</small>
            <hr>
            <p [ngStyle]="{'font-size': '13px'}">
              Columns:
            </p>
            <ul [ngStyle]="{'font-size': '12px'}">
              <li *ngFor="let column of option.columns">{{ column }}</li>
            </ul>
          </div>

        </div>
      </ng-template>
    </p-listbox>
  </div>

</div>
