<dynamic-view-v2
  *ngIf="!isLoading"
  entityType="diff-jobs"
  [viewName]="title"
  [record]="diffToolRecord"
  [fields]="fields"
  [jobRecordsArray]="jobRecordsArray" 
  [isFullWidth]="true"
  [hideEditButton]="true"
  [hideBuildHistoryButton]="true"

>
</dynamic-view-v2>
