import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private messageService: MessageService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return firstValueFrom(this.authService.loadPermissions()).then((permissions) => {
      const user = this.authService.getSocialUser();

      if (user.currentUser && user.loggedIn) {
        if (route.data?.permission) {
          const flattenedPermissions = permissions.flat();
          const abilities = route.data?.permission.abilities;
          const controller = route.data?.permission.controller;
          const relevantPermissions = flattenedPermissions.filter((permission: { subject: string; }) => permission.subject === controller);

          const hasPermission = abilities.every((action: string) => relevantPermissions.some((permission: { action: string; }) => permission.action === action));

          if (!hasPermission) {
            this.messageService.add({
              severity: 'error',
              summary: 'Routing Failed',
              detail: 'You don\'t have permission to resource',
            });
            this.router.navigate(['/home']);
            return false;
          }
      
          return true;
        }
        return true;
      }

      this.router.navigate(['/login']); 
      return false;
    });
  }
}
