import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import NurtureCardDto from '../dtos/NurtureCardDTO';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BuildType } from 'src/app/enums/build-type';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-nurture-card-view',
  templateUrl: './nurture-card-view.component.html',
  styleUrls: ['./nurture-card-view.component.sass']
})
export class NurtureCardViewComponent extends BaseDynamicViewComponent implements OnInit {


  nurtureCardRecord: NurtureCardDto = new NurtureCardDto();
  constructor() {
    super();
  }

  ngOnInit() {
    {
      this.fields =
      [
        {
          title: "Nurture Card Data",
          fields:
          [
            {
              name: "Nurture Card Name",
              inputType: DynamicViewType.BaseViewField , 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'name',
              isRequired: true,
              label: 'Nurture Card Name'
            },
            {
              name: "Start Date/Time",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'start',
              label: 'Start Date/Time',
              columnWidth: 6
            },
            {
              name: "End Date/Time",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'end',
              label: 'End Date/Time',
              columnWidth: 6
            },
            {
              name: "Image",
              inputType: DynamicViewType.MiscImageRecordV2,
              viewMode: 'record',
              clearField: true,
              key: 'image_ref',
              label: 'Image',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: { fieldName: 'image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Nurture }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
            },
            {
              name: "Video",
              inputType: DynamicViewType.MiscImageRecordV2,
              viewMode: 'record',
              clearField: true,
              key: 'video_ref',
              label: 'Video',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: { fieldName: 'video_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Nurture }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
            },
          ],
          type: DynamicCardType.Card
        }
      ]

      this.title = "Nurture Card";
      this.viewRoute = 'nurture-card';
      this.isLoading = false;
    }
  }
}
