import { Component, OnInit } from '@angular/core';
import { TableDataComponent } from 'src/app/cms-v2/entities/table-data/components/table-data/table-data.component';

@Component({
  selector: 'app-release-table',
  templateUrl: './release-table.component.html',
  styleUrls: ['./release-table.component.sass']
})
export class ReleaseTableComponent extends TableDataComponent implements OnInit
{
  constructor() 
  {
    super();
  }

  /**
   * Releases Table Component Initialization
   */
  ngOnInit() 
  {
    this.isLoading = true;
    this.title = 'Releases';
    this.type = 'releases';

    this.globalFilters = ['id', 'name'];
    this.filtersToRegister = [...this.defaultFilters, 'stringIn'];

    this.fields = [
      ...this.defaultFields,
      {
        key: 'releaseType_ref',
        name: 'Release Type',
        controlType: 'dropdown_ref',
        filterKey: 'releaseType_ref',
        matchMode: 'contains',
        isColumn: true,
        isInputField: false,
        isFilterField: true,
      },
    ];
    this.defaultColumnOrder = [
      'id',
      'name',
      'start',
      'end',
      'enabled',
      'layout',
      'releaseType_ref'
    ];
    this.customGlobalColumnSets = [
      {
        name: 'Default',
        value: [
          'id',
          'name',
          'start',
          'end',
          'enabled',
          'layout'
        ],
      },
    ];

    this.tableConfig.customFilterToRegister = this.filtersToRegister;
    this.isLoading = false;
  }
}

