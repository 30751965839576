<p-toast></p-toast>

<div *ngIf="isLoading" class="loading-container">
    <p-progressSpinner></p-progressSpinner>
    <span>Loading...</span>
</div>

<div *ngIf="hasError" class="error-container">
    <p-card>
        <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-ai-center p-p-4">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem; color: var(--red-500);"></i>
                <h2>Error Loading Data</h2>
            </div>
        </ng-template>
        <p>There was an error loading the entity data. Please try again later.</p>
        <ng-template pTemplate="footer">
            <button pButton label="Retry" icon="pi pi-refresh" 
                    class="p-button-primary" 
                    (click)="loadEntityData()">
            </button>
        </ng-template>
    </p-card>
</div>

<dynamic-view-v2
    [isDynamicEntity]="true"
    *ngIf="!isLoading && !hasError"
    [entityType]="type"
    [viewName]="title"
    [(record)]="dynamicEntityRecord"
    [fields]="fields"
    [viewRoute]="viewRoute">
</dynamic-view-v2>
