import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DynamicFormComponent } from 'src/app/dynamic-form/dynamic-form.component';
import { FieldService } from 'src/app/services/field.service';
import { FormService } from 'src/app/services/form.service';
import { CurrencyService } from '../services/currency.service';

@Component({
  selector: 'app-currency-form',
  templateUrl: './currency-form.component.html',
  styleUrls: ['./currency-form.component.sass'],
  providers: [FormService, FieldService],
})
export class CurrencyFormComponent implements OnInit 
{
  @ViewChild(DynamicFormComponent) dynamicFormComponent: DynamicFormComponent;
  id: number;
  isEditMode: boolean = false;

  constructor
  (
    private route: ActivatedRoute, 
    private currencyService: CurrencyService
  ) {}

  /**
   * Currency Form Component Initialization
   */
  ngOnInit()
  {
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    if (this.id) 
    {
      this.isEditMode = true;
    }
  }

  /**
   * Handle events comming from upload completed 
   * action comming from file uploader.
   * 
   * @param response API response with cdn URL value.
   */
  async onUploadCompleted(response: any)
  {
    if(response && response.originalEvent && response.originalEvent.body && response.originalEvent.body.result)
    {
      let result = response.originalEvent.body.result;

      if(this.id || this.isEditMode)
      {
        await this.currencyService.updateCurrency(this.id, { imagePreview: result.cdnUrl });
        // This is to prevent the value from being overwritten when a user submits the form after updating the image preview.
        this.dynamicFormComponent.setValueToForm('imagePreview', result.cdnUrl);
      }
      else
      {
        this.dynamicFormComponent.setValueToForm('imagePreview', result.cdnUrl);
      }
    }
  }
}
