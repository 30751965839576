import { LoggerService } from './../../../../../common/services/logger.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { ActivatedRoute, Router } from '@angular/router';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import ChallengeCollectionDto from '../../dtos/ChallengeCollectionDTO';

@Component({
  selector: 'app-challenge-collection-form-v3',
  templateUrl: './challenge-collection-form-v3.component.html',
  styleUrls: ['./challenge-collection-form-v3.component.sass']
})
export class ChallengeCollectionFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  collectionRecord: ChallengeCollectionDto = new ChallengeCollectionDto();
  isNuf:boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loggerService: LoggerService
  )
  {
    super();
  }

  /**
   * Collection Form V3 Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Collection Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true, key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: 'Internal (Content) Notes',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Editor],
            key: 'internal_notes',
            style: {'height':'320px', 'width':'100%'},
            label: 'Internal (Content) Notes',
            isRequired: false,
            clearField: true
          }
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Challenges",
        fields: [
          {
            name: 'Challenges',
            inputType: DynamicInputType.ChallengeCollectionsCustomField,
            key: 'challenges_ref',
          }
        ],

        type: DynamicCardType.Card
      }
    ]

    this.title = "Challenge Collection";
    this.viewRoute = 'challenge-collections';
    this.loggerService.log('url', this.router.url);
    this.route.queryParams.subscribe(params => {
      const isNUF = params['isNUF'];
      this.loggerService.log('isNUF', this.isNuf);
      this.loggerService.log('isNUF', typeof(this.isNuf));
      isNUF === 'true' ? this.isNuf = true : this.isNuf = false;
      if (this.isNuf) {
        this.collectionRecord.tags.push('NUF');
      }
    });
    this.isLoading = false;
  }
}
