<div style="max-width: 100%; overflow: hidden; position: relative;">
  <ng-container *ngIf="!isImageLoaded[activeIndex]">
    <!-- Skeleton loading -->
    <p-skeleton width="100%" height="240px"></p-skeleton>
  </ng-container>

  <!-- Actual image content -->
  <img
    *ngIf="entity != 'miscellaneous-build'"
    [src]="images[activeIndex].previewImageSrc"
    (load)="onImageLoad(activeIndex)"
    (error)="onImgError($event)"
    [alt]="images[activeIndex].alt"
    [style]="'display: '+imageDisplay+'; max-width: 100%;'" />

    <div *ngIf="entity == 'miscellaneous-build'" style="width: 240px; height: 240px; overflow: hidden;">
      <img
        
        [src]="images[activeIndex]?.path"
        (load)="onImageLoad(activeIndex)"
        (error)="onImgError($event)"
        style="max-width: 100%; height: auto;"
      />
    </div>
    
    
  <!-- Caption for the image -->
  <div *ngIf="entity != 'miscellaneous-build'"
       style="position: absolute; bottom: 8px; left: 50%; transform: translateX(-50%); text-align: center;">
    <span>{{images[activeIndex].alt}}</span>
  </div>

  <div *ngIf="entity == 'miscellaneous-build' && images && images.length > 1"
       style="position: absolute; bottom: 8px; left: 50%; transform: translateX(-50%); text-align: center;">
    <span>Size: {{images[activeIndex]?.size}}</span>
  </div>
  

  <!-- Navigation buttons -->
  <div class="p-grid p-mt-2">
    <button class="p-col-4 p-ml-2 p-button p-button-text p-button-rounded p-button-outlined p-button-sm" (click)="prevImage()">
      <span class="pi pi-chevron-left"></span>
    </button>
    <span class="p-col-3 p-mr-1" style="display: flex; justify-content: flex-end;"></span>
    <button class="p-col-4 p-button p-button-text p-button-rounded p-button-outlined p-button-sm" (click)="nextImage()">
      <span style="margin-left: auto;" class="pi pi-chevron-right"></span>
    </button>
  </div>
</div>
