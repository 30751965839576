

<app-ag-grid-table
  *ngIf="!isLoading && columnDefs"
  [entity]="entity"
  [columnDefs]="columnDefs"
  [columnSelection]="columnSelection"
>
</app-ag-grid-table>

<div *ngIf="isLoading">Loading...</div>