import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import  ReleaseTypeDTO  from '../dtos/ReleaseTypeDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-release-type-form-v2',
  templateUrl: './release-type-form-v2.component.html',
  styleUrls: ['./release-type-form-v2.component.sass']
})
export class ReleaseTypeFormV2Component  extends BaseDynamicFormComponent implements OnInit
{
  releaseTypeRecord: ReleaseTypeDTO = new ReleaseTypeDTO();
  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Release Type Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Release Type Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name'
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            calendarMinDate: this.releaseTypeRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
          },
          {
            name: "Release Type Custom Component",
            inputType: DynamicInputType.ReleaseTypeOrderList,
            keyDependent: 'releaseTemplate',
            key: 'releaseFields',
            label: 'Release Type Custom Component'
          }
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.title = "Release Type";
    this.viewRoute = 'release-type';
    this.isLoading = false;
  }

}
