import { Component, OnInit } from '@angular/core';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-storesyncv2view',
  templateUrl: './storesyncv2view.component.html',
})
export class Storesyncv2viewComponent extends BaseDynamicViewComponent implements OnInit 
{
  recordId: number;
  handleIdChange(newId: number) {
    this.recordId = newId; 
  }
  StoresyncRecord: BaseEntityDto = new BaseEntityDto();
    constructor(
      private route: ActivatedRoute,
    ) 
    {
    super();
    } 
    ngOnInit() {
      this.recordId = Number(this.route.snapshot.paramMap.get('id'));
      if (!this.recordId || this.recordId <= 0) {
        console.error('Invalid recordId');
        return;
      }
          this.fields = 
          [
            {
          title: "Store Sync Details",
          fields: [
            {
              name: "Entity",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Text],
              key: 'entity',
              label: 'Entity',
              columnWidth: 6,
            },
            { name: "Triggered At", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'triggeredAt', label: 'Triggered At', columnWidth: 6 },
            { 
              name: "Triggered By", 
              inputType: DynamicViewType.BaseViewField, 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true, 
              key: 'meta.user.name', 
              isRequired: true, 
              label: 'Triggered By',  
              columnWidth: 6,
            },      
            { 
              name: "Environment", 
              inputType: DynamicViewType.BaseViewField, 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true, 
              key: 'meta.environment', 
              isRequired: true, 
              label: 'Environment',
              columnWidth: 6,  
            },       
            { 
              name: "Created", 
              inputType: DynamicViewType.BaseViewField, 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true, 
              key: 'meta.created', 
              isRequired: true, 
              label: 'Created',  
              columnWidth: 6,
            },  
            { 
              name: "Updated", 
              inputType: DynamicViewType.BaseViewField, 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true, 
              key: 'meta.updated', 
              isRequired: true, 
              label: 'Updated',  
              columnWidth: 6,
            },            
         ],
         type: DynamicCardType.Card,
        },
      ]
      this.title = "Store-Sync";
      this.viewRoute = 'store-sync-v2';
      this.isLoading = false;

    }
  }

