import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Restriction DTO
 */
export default class RestrictionDTO extends BaseEntityDto
{
    /**
     * Amount of the item needed for the rule
     */
    public count?: Number;
    /**
     * Reference to rule set
     */
    public rules_ref?: any = [];
  }
