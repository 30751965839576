import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import SeasonalPrizesDto from '../../dto/seasonal-prizesDTO';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
  

@Component({
  selector: 'app-seasonal-prizes-form-v2',
  templateUrl: './seasonal-prizes-form-v2.component.html',
  styleUrls: ['./seasonal-prizes-form-v2.component.sass']
})
export class SeasonalPrizesFormV2Component extends BaseDynamicFormComponent implements OnInit {
  seasonalPrizesRecord: SeasonalPrizesDto = new SeasonalPrizesDto();
  title: string = 'seasonal-prizes';
  isLoading: boolean = false;
  options: any = {};

  constructor(
    private validation: BaseInputValidationServiceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.fields = [
      {
        title: "Seasonal Prizes Data",
        fields: [
          {
            name: "Display Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'name',
            isRequired: true,
            label: 'Display Name',
            columnWidth: 6,
          },
          {
            name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'internalName',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 6,
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            columnWidth: 6,
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            columnWidth: 6,
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.seasonalPrizesRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: "Bundle Names",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Chip],
            clearField: true,
            key: 'bundleNames',
            isRequired: false,
            label: 'Bundle Name',
            disabled: false,
            columnWidth: 6
          },
          {
            name: 'Internal Notes',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Editor],
            key: 'copy',
            label: 'Internal Notes',
            columnWidth: 12,
            clearField: true,
          },
          {
            name: "Imagery Data",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: '',
            label: 'Imagery Data:',
          },
          {
            name: "Pop-up Banner",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'popupBanner_ref',
            label: 'Pop-up Banner',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'popupBanner_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: 'name id path _id',             
              customQuery: { entityType: 3 },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              sort: { name: 1 },
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Popup Icon",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'popupIcon_ref',
            label: 'Popup Icon',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'popupIcon_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Slide Down Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'slideDownImage_ref',
            label: 'Slide Down Image',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'slideDownImage_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: 3 },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: "Bundle 1(color)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'bundle1_ref',
            label: 'Bundle 1(color)',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'bundle1_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: 3 },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Bundle 2(color)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'bundle2_ref',
            label: 'Bundle 2(color)',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'bundle2_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: 3 },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Bundle 3(color)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'bundle3_ref',
            label: 'Bundle 3(color)',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'bundle3_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: 3 },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: "Logo Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'logoImage_ref',
            label: 'Logo Image',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'logoImage_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: 3 },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },          
          {
            name: "Hex Code Color",
            inputType: DynamicInputType.BaseInputField,
            key: 'hexCodeColor',
            label: 'Hex Code Color',
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: true,
            isRequired: false,
            disabled: false,
            columnWidth: 6
          },
          {
            name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: 'Rewards Track',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'rewardsTrack_ref',
            isRequired: false,
            label: 'Rewards Track',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: false,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: {
              fieldName: 'rewardsTrack_ref',
              apiController: 'rewards-track',
              minimal: true,
              autopopulate: false,
              virtuals: false,
              select: '_id name id',
              sort: { createdAt: 1 },
              limit: 10,
              customQuery: { type: 9 },
            },
            scrollHeight: '200px',
            columnWidth: 6
          },
          {
            name: "Inbox Messages",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'inboxes_ref',
            label: 'Inbox Messages',
            labelKeys: ['id', 'name'],
            optionValue: '_id',
            optionLabel: 'name',
            optionDisabled: 'disabled',
            filter: true,
            options: {
              fieldName: 'inboxes_ref',
              apiController: 'inbox-messages',
              select: 'id name start end',
            },
            columnWidth: 12
          },
          {
            name: "Collections",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'collections_ref',
            label: 'Collections',
            labelKeys: ['id', 'name'],
            optionValue: '_id',
            optionLabel: 'name',
            optionDisabled: 'disabled',
            filter: true,
            options: {
              fieldName: 'collections_ref',
              apiController: 'collections',
              select: 'id name',
              autopopulate: false,
            },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      }
    ];

    this.viewRoute = 'seasonal-prizes';
    this.isLoading = false;
  }
}
