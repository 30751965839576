import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonEntityService } from './common-entity.service'; // Import your CommonEntityService

@Injectable({
  providedIn: 'root'
})
export class EndlessOfferService {

  constructor(private http: HttpClient, private commonEntityService: CommonEntityService) { }

  // async getRewardsData(rewardTrack: any, setLoadingState: (isLoading: boolean) => void) {
  //   if (!rewardTrack.EOrewards) {
  //     setLoadingState(false);
  //     return;
  //   }

  // Loop through the outer array
  // for (const [index, rewardsGroup] of rewardTrack.EOrewards.entries()) {
  //   // Check if rewardsGroup is indeed an array before trying to iterate over it
  //   if (Array.isArray(rewardsGroup)) {
  //     // Loop through each inner array
  //     for (const [innerIndex, reward] of rewardsGroup.entries()) {
  //       if(reward.lineItemType == "StoreListingV2" && reward.id.name){
  //         try {
  //           let query = reward.id.name;
  //           // const result = await this.http.get<any>(`/api/endless-offer-track/store/listing?searchBy=${query}`).toPromise();

  //           // console.log('Query:', query);
  //           // console.log('Result:', result);
  //           // console.log('Result: rewardTrack.EOrewards[index][0].id', rewardTrack.EOrewards[index][0].id);


  //           let creditsRef = rewardTrack.EOrewards[index][0].id.credits_ref.qa[0];

  //           // console.log('CreditsRef:', creditsRef);
  //           // console.log('CreditsRef:', creditsRef.c);

  //           // if (result.storeListings && result.storeListings.length > 0) {
  //           //   const firstMatch = result.storeListings[0];
  //           //   if (firstMatch.Name === query) {
  //           //     reward.storeListingData = firstMatch;
  //           //   }
  //           // }
  //         } catch (err) {
  //           console.error('Error fetching data', err);
  //         }
  //       }
  //     }
  //   } else {
  //     console.warn(`Expected an array of rewards, got:`, rewardsGroup);
  //   }
  // }
  //   setLoadingState(false);
  // }


  async getBundlesData(rewardTrack: any, setLoadingState: (isLoading: boolean) => void) {
    if (!rewardTrack.EObundles) {
      return;
    }

    for (const [index, bundle] of rewardTrack.EObundles.entries()) {
      if (bundle.type === 'paid' && bundle.listing && bundle.listing.Name) {
        const query = bundle.listing.Name;
        try {
          const result = await this.http.get<any>(`/api/endless-offer-track/store/listing?searchBy=${query}`).toPromise();

          if (result.storeListings && result.storeListings.length > 0) {
            const firstMatch = result.storeListings[0];
            if (firstMatch.Name === query) {
              bundle.listing = firstMatch;
            }
          }
        } catch (err) {
          console.error('Error fetching data', err);
        }
      }
    }
    setLoadingState(false);
  }


  // async buildDisplayData(rewardTrack: any) {
  //   if (!rewardTrack.EOrewards) {
  //     return;
  //   }

  //   for (const [index, reward] of rewardTrack.EOrewards.entries()) {
  //     if (
  //       reward[0].lineItemType === "StoreListingV2"
  //     ) {
  //       for (const credit of reward[0].id.credits_ref.qa) {
  //         console.log('from buildDisplayData credit:', credit);

  //         let entity = '';
  //         let entityNumber = 0;

  //         if(credit.t.name === 'Currency') {
  //           entity = 'currencies';
  //           entityNumber = 1
  //         } else if(credit.t.name === 'Item') {
  //           entity = 'items';
  //           entityNumber = 2
  //         } else if(credit.t.name === 'SeedPackBox') {
  //           entity = 'seed-pack-box';
  //           entityNumber = 3
  //         }

  //         try {
  //           const resourceData = await this.commonEntityService.findOneWithQuery(entity, {
  //             query: { id: credit.id.id },
  //             smartPopulate: { "thumbnail_ref": "_id id name thumbnails", "image_ref": "_id id name thumbnails", "box_image_ref": "_id id name thumbnails"}
  //           });

  //           console.log('from buildDisplayData resourceData:', resourceData);

  //           credit.entity = entity;
  //           credit.link = `${entity}/${credit.id.id}`;
  //           credit.displayName = resourceData.name || 'undefined';
  //           credit.thumbnail = 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'; // default thumbnail

  //           if (
  //             entityNumber === 1 &&
  //             resourceData.image_ref &&
  //             resourceData.image_ref.thumbnails &&
  //             resourceData.image_ref.thumbnails[1]
  //           ) {
  //             credit.thumbnail = resourceData.image_ref.thumbnails[1].path;
  //           } else if (
  //             entityNumber === 2 &&
  //             resourceData.thumbnail_ref &&
  //             resourceData.thumbnail_ref.thumbnails &&
  //             resourceData.thumbnail_ref.thumbnails[1]
  //           ) {
  //             credit.thumbnail = resourceData.thumbnail_ref.thumbnails[1].path;

  //           } else if (
  //             entityNumber === 3 &&
  //             resourceData.box_image_ref &&
  //             resourceData.box_image_ref.thumbnails &&
  //             resourceData.box_image_ref.thumbnails[1]
  //           ) {
  //             credit.thumbnail = resourceData.box_image_ref.thumbnails[1].path;
  //           }
  //           console.log('from buildDisplayData credit:', credit);
  //         } catch (err) {
  //           console.error('Error fetching entity data:', err);
  //         }

  //       }
  //     } else {
  //       // console.log('Free reward:');
  //       // console.log(rewardTrack?.rewards[index]);
  //     }
  //   }
  // }

  async buildDisplayData(rewardTrack: any) {
    if (!rewardTrack.EOrewards) {
      return;
    }

    for (const [index, reward] of rewardTrack.EOrewards.entries()) {
      // console.log('from buildDisplayData reward:', reward);

      // pricePoint_ref
      if (reward[0].lineItemType === "StoreListingV2") {
        if (reward[0].id.pricePoint_ref && reward[0].id.pricePoint_ref.qa) {
          try {
            const pricePoint = await this.commonEntityService.findOneWithQuery('price-points', {
              query: { _id: reward[0].id.pricePoint_ref.qa },
              smartPopulate: { "thumbnail_ref": "_id id name thumbnails", "image_ref": "_id id name thumbnails", "box_image_ref": "_id id name thumbnails" }
            });
            reward[0].pricePoint = pricePoint;
            // console.log('from buildDisplayData pricePoint:', reward[0].pricePoint);
          } catch (err) {
            console.error('Error fetching price point data:', err);
          }
        }
        if (reward[0]?.id?.credits_ref && reward[0]?.id?.credits_ref?.qa) {
          for (const credit of reward[0]?.id?.credits_ref?.qa) {
            // console.log('from buildDisplayData credit:', credit);

            let { entity, entityNumber } = this.getEntityDetails(credit.t.name);

            try {
              const resourceData = await this.fetchResourceData(this.commonEntityService, entity, credit.id.id);


              // console.log('from buildDisplayData resourceData:', resourceData);

              credit.entity = entity;
              credit.link = `${entity}/${credit.id.id}`;
              credit.displayName = resourceData.name || 'undefined';
              credit.thumbnail = this.getThumbnail(resourceData, entityNumber);

              // console.log('from buildDisplayData credit:', credit);
            } catch (err) {
              console.error('Error fetching entity data:', err);
            }
          }
        }
      } else {
        console.log('Free reward:');
        // console.log(rewardTrack?.rewards[index]);
      }
    }
    return rewardTrack.EOrewards;
  }

  getEntityDetails(name: any) {
    switch (name) {
      case 'Currency':
        return { entity: 'currencies', entityNumber: 1 };
      case 'Item':
        return { entity: 'items', entityNumber: 2 };
      case 'SeedPackBox':
        return { entity: 'seed-pack-box', entityNumber: 3 };
      default:
        return { entity: '', entityNumber: 0 };
    }
  }

  async fetchResourceData(service: any, entity: any, id: any) {
    return service.findOneWithQuery(entity, {
      query: { id },
      smartPopulate: { "thumbnail_ref": "_id id name thumbnails", "image_ref": "_id id name thumbnails", "box_image_ref": "_id id name thumbnails" }
    });
  }

  getThumbnail(resourceData: any, entityNumber: any) {
    let thumbnail = 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'; // default thumbnail

    if (entityNumber === 1 && resourceData.image_ref && resourceData.image_ref.thumbnails && resourceData.image_ref.thumbnails[1]) {
      thumbnail = resourceData.image_ref.thumbnails[1].path;
    } else if (entityNumber === 2 && resourceData.thumbnail_ref && resourceData.thumbnail_ref.thumbnails && resourceData.thumbnail_ref.thumbnails[1]) {
      thumbnail = resourceData.thumbnail_ref.thumbnails[1].path;
    } else if (entityNumber === 3 && resourceData.box_image_ref && resourceData.box_image_ref.thumbnails && resourceData.box_image_ref.thumbnails[1]) {
      thumbnail = resourceData.box_image_ref.thumbnails[1].path;
    }

    return thumbnail;
  }

}
