import { Component, Input, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';
import NurtureRarityDTO from '../dtos/NurtureRarityDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-nurture-rarity-form',
  templateUrl: './nurture-rarity-form.component.html',
  styleUrls: ['./nurture-rarity-form.component.sass']
})
export class NurtureRarityFormComponent extends BaseDynamicFormComponent implements OnInit {

  nurtureRarityRecord: NurtureRarityDTO = new NurtureRarityDTO();
  minEndDate: Date;
  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }


  /**
   * Nurture Rarity Forms V2 Component Initialization
  */

  ngOnInit(){

    this.fields =
    [
      {
        title: "Nurture Rarity Data",
        fields:
        [
          {
            name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 12
          },
          {
            name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            clearField: true,
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: "Display Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'displayName',
            isRequired: true,
            label: 'Display Name',
            columnWidth: 6
          },
          {
            name: "Start Date",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date',
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "Weight",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'weight',
            isRequired: true,
            label: 'Weight',
            columnWidth: 6
          },
          {
            name: "End Date",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date',
            columnWidth: 6,
            calendarMinDate: this.minEndDate,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: "Color",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: true,
            key: 'color',
            isRequired: true,
            label: 'Color',
            disabled: false,
            columnWidth: 6,
          },
          {
            name: "SeedPack Background",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'background_ref',
            label: 'SeedPack Background',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'background_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "SeedPack Tint",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'seedpackTint_ref',
            label: 'SeedPack Tint',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'seedpackTint_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "SeedPack Pattern",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'seedpackPattern_ref',
            label: 'SeedPack Pattern',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'seedpackPattern_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          }           
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Nurture Rarity Form";
    this.viewRoute = 'nurture-rarity';
    this.isLoading = false;
  }

  validateNurtureRarityForm(form: any)
  {
    let confirmValidationCallback = this.validation.colorValidator(form.color);
    let message = confirmValidationCallback ? 'Valid' : 'The color entered is invalid. Please enter a valid hex color.';

    return { confirmValidationCallback, message }
  }
}
