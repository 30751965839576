import { LoggerService } from './../../services/logger.service';
import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef, ValueParserParams } from 'ag-grid-enterprise';
import { DataService } from 'src/app/services/data.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, BehaviorSubject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { TABLE_ACTIONS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class AgGridToolsService {
  constructor(
    private dataService: DataService,
    private loggerService: LoggerService,
    private http: HttpClient,
    private messageService: MessageService
  ) { }

  private disabledTableActionsSubject = new BehaviorSubject<Array<TABLE_ACTIONS>>([]);

  disabledTableActions$ = this.disabledTableActionsSubject.asObservable();

  getColumnFieldNamesFromCols(columnDefs: ColDef<any, any>[], isStoreEntity: boolean = false): string {
    const fieldNames = new Set<string>();

    columnDefs
      .filter((colDef): colDef is ColDef => !(colDef as ColGroupDef).children)
      .forEach((columnDef) => {
        let field = (columnDef as ColDef).field;
        if (isStoreEntity && field) {
          // Strip off the environment suffix if it exists
          field = field.replace(/-(dev|qa|prod)$/, '');
        }
        if (field) {
          fieldNames.add(field);
        }
      });

    // Convert the set into an array and join the elements with a space
    return Array.from(fieldNames).join(' ');
  }

  /**
   * Get Build Status Data for table display
   *
   * @param buildData Build data
   */
  getBuildStatusData(buildData: any) {
    let result;
    if (!buildData) {
      return {
        text: 'No build data',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }

    let statusCounts: any = {
      finished: [],
      failed: [],
      building: [],
      queued: [],
    };

    for (const key in buildData) {
      if (buildData.hasOwnProperty(key)) {
        if (buildData[key].status.toLowerCase() == 'queued') {
          statusCounts[buildData[key].status].push([buildData[key].insertedAt]);
        } else if (
          buildData[key].status.toLowerCase() == 'finished' ||
          buildData[key].status.toLowerCase() == 'failed'
        ) {
          statusCounts[buildData[key].status].push([buildData[key].finishedAt]);
        } else {
          statusCounts[buildData[key].status].push([buildData[key].startedAt]);
        }
      }
    }

    switch (Object.keys(buildData).length) {
      case statusCounts.finished.length:
        result = {
          text: 'Success',
          date: this.getMostRecent(statusCounts.finished),
          color: 'var(--primary-color)',
          buildCount: Object.keys(buildData).length,
        };
        break;

      case statusCounts.failed.length:
        result = {
          text: 'Full Fail',
          date: this.getMostRecent(statusCounts.failed),
          color: 'var(--pink-600)',
          buildCount: Object.keys(buildData).length,
        };
        break;

      case statusCounts.building.length:
        result = {
          text: 'Building',
          date: this.getMostRecent(statusCounts.building),
          color: 'var(--text-color)',
          buildCount: Object.keys(buildData).length,
        };
        break;

      case statusCounts.queued.length:
        result = {
          text: 'Queued',
          date: this.getMostRecent(statusCounts.queued),
          color: 'var(--text-color)',
          buildCount: Object.keys(buildData).length,
        };
        break;

      default:
        if (
          statusCounts.building.length > 0 &&
          statusCounts.building.length < Object.keys(buildData).length &&
          statusCounts.failed.length == 0
        ) {
          result = {
            text: 'Partial Building',
            date: this.getMostRecent(statusCounts.building),
            color: 'var(--yellow-600)',
            buildCount: statusCounts.building.length,
          };
        } else if (
          statusCounts.failed.length > 0 &&
          statusCounts.failed.length < Object.keys(buildData).length
        ) {
          result = {
            text: 'Partial Fail',
            date: this.getMostRecent(statusCounts.failed),
            color: 'var(--pink-600)',
            buildCount: statusCounts.failed.length,
          };
        } else if (
          statusCounts.queued.length > statusCounts.finished.length &&
          statusCounts.finished.length > 0
        ) {
          result = {
            text: 'Queued',
            date: this.getMostRecent(statusCounts.queued),
            color: 'var(--text-color)',
            buildCount: statusCounts.queued.length,
          };
        } else {
          result = {
            text: 'No build data',
            date: '',
            color: 'var(--gray-400)',
            buildCount: 0,
          };
        }
        break;
    }

    return result;
  }

  getMostRecent(dates: any[]) {
    let allDates: any[] = [];
    dates.forEach((d) => {
      allDates.push(Date.parse(d));
    });

    return new Date(Math.max(...allDates)).toLocaleDateString();
  }
  // utils.js

  dateValueGetter(fieldName: string) {
    return (params: any) => {
      // Split the fieldName into parts and use reduce to traverse the object
      const fieldPath = fieldName.split('.');
      const fieldValue = fieldPath.reduce((obj, key) => obj && obj[key] ? obj[key] : null, params.data);
      // console.log('fieldValue: ', fieldValue)
      // Check if the date exists and is valid
      if (fieldValue && !isNaN(new Date(fieldValue).getTime())) {
        // Create a new Date object from the value
        const date = new Date(fieldValue);

        // Use the Intl.DateTimeFormat object to format the date
        const options: Intl.DateTimeFormatOptions = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        };
        return new Intl.DateTimeFormat('en-US', options).format(date);
      }
      return null; // Return null if the date is not valid
    };
  }

  dynamicValueGetter(fieldName: string) {
    return (params: any) => {
      // Check if the date exists and is valid
      if (
        params.data &&
        params.data[fieldName]
      ) {
        switch (fieldName) {
          case 'EOrewards':
            return 'rewards...';
            break;
          case 'EObundles':
            const lenght = params.data[fieldName].length;
            const countFree = params.data[fieldName].filter((item: any) => item.type === 'free').length;
            const countPaid = params.data[fieldName].filter((item: any) => item.type === 'paid').length;
            return `${lenght} Bundles: ${countFree} free | ${countPaid} paid`;
            break;

          default:
            params.data[fieldName]
            break;
        }
      }
      return null; // Return null if the date is not valid
    };
  }

  getMinOptions(params: any) {
    // Extract the 'id', '_id', and 'name' properties from params.value
    const value = params.data[params.colDef.field];
    if (value && value.id && value._id && value.name) {
      return {
        id: value.id,
        _id: value._id,
        name: value.name,
      };
    }
    return null; // Return null if the object does not contain these fields
  }

  suppressEnter(params: any) {
    var KEY_ENTER = 'Enter';

    var event = params.event;
    var key = event.key;
    var suppress = key === KEY_ENTER;

    return suppress;
  }

  /**
   * Autocomplete searching
   *
   * @param query
   * @param model
   */
  async getSuggestionsForRef(
    query: any,
    model: string
  ): Promise<any[] | undefined> {
    return this.dataService
      .getAllOfType(model, {
        query: isNaN(query)
          ? { name: { $regex: query, $options: 'i' } }
          : { id: query },
        select: '_id name id',
        virtuals: false,
        autopopulate: false,
        sort: { name: 1 },
      })
      .toPromise();
  }

  // Assuming this is in agGridToolsService.ts

  getBuildDate(source: any): string {
    if (source) {
      const buildOutput = source;
      let mostRecentDate: Date | null = null;
      let useInsertedAtAsFallback = true;

      Object.keys(buildOutput).forEach(platform => {
        if (buildOutput[platform].finishedAt) {
          const finishedAt = new Date(buildOutput[platform].finishedAt);
          useInsertedAtAsFallback = false;
          if (!mostRecentDate || finishedAt > mostRecentDate) {
            mostRecentDate = finishedAt;
          }
        } else if (useInsertedAtAsFallback && buildOutput[platform].insertedAt) {
          const insertedAt = new Date(buildOutput[platform].insertedAt);
          if (!mostRecentDate || insertedAt > mostRecentDate) {
            mostRecentDate = insertedAt;
          }
        }
      });

      function formatDate(date: Date): string {
        const options: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
      }

      return mostRecentDate ? formatDate(mostRecentDate) : 'Not found';
    } else {
      return 'Not found';
    }
  }

  nameASC(a: any, b: any): number {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  async syncStore(): Promise<void> {
    console.log('sync store!');
    try {
      const query = 'eo_';
      // Perform the HTTP GET request and await its Promise
      let response = await this.http.get<any>(`/api/endless-offer-track/store/listing?searchBy=${query}`).toPromise();

      // Check if the storeListings array exists in the response
      if (response && Array.isArray(response.storeListings)) {
        // Extract the Name and Credits.LineItems from each listing
        const storeListings = response.storeListings.map((listing: any) => ({
          name: listing.Name,
          lineItems: listing.Credits ? listing.Credits.LineItems : []
        }));

        // Now, make the POST request with the storeListings array
        await this.http.post('/api/store-listing/sync', { storeListings }).toPromise();

        console.log('Stores have been synced successfully.');
      } else {
        // Handle the case where storeListings is not as expected
        console.error('No storeListings found in the response');
      }
    } catch (error) {
      console.error('Error syncing store:', error);
      // Depending on how you want to handle errors, you might throw an error here
      throw error;
    }
  }

  preprocessColumnDefs(columnDefs: ColDef[]): ColDef[] {
    // Clone the array to avoid mutating the original input
    return columnDefs.map(columnDef => {
      // Check if the column uses 'agSetColumnFilter' and is configured to add empty filters
      if (columnDef.filter === 'agSetColumnFilter' && columnDef.filterParams?.addEmptyFilters) {
        // Ensure filterParams exists
        const filterParams = columnDef.filterParams || {};
        // Check if it's a multi-reference filter or not and adjust values accordingly
        if (columnDef.filterParams.isMultiRefFilter || columnDef.filterParams.isSingleRefFilter) {
          // Handle the case for multi-reference filters by adding objects for '(EMPTY)' and '(NOT EMPTY)'
          const values = filterParams.values as any[] || [];
          if (!values.some(value => value.name === '(EMPTY)')) {
            values.push({ name: '(EMPTY)', id: -1, _id: -1 });
          }
          if (!values.some(value => value.name === '(NOT EMPTY)')) {
            values.push({ name: '(NOT EMPTY)', id: -2, _id: -2 });
          }
          filterParams.values = values;
        } else {
          // Handle the case for simple filters by ensuring '(EMPTY)' and '(NOT EMPTY)' are included
          const values = filterParams.values as string[] || [];
          if (!values.includes('(EMPTY)')) values.push('(EMPTY)');
          if (!values.includes('(NOT EMPTY)')) values.push('(NOT EMPTY)');
          filterParams.values = values;
        }
        // Reassign the potentially modified filterParams back to the columnDef
        columnDef.filterParams = filterParams;
      }
      return columnDef;
    });
  }

  async addToCart(selected: any, rowIds: any[]) {
    // Assume default values that could be overwritten by the cart's content
    let {
      listings: storeListings = [],
      pricePoint: pricePoints = [],
      destinationEnv = 'qa',
      sourceEnv = 'dev'
    } = JSON.parse(localStorage.getItem('storeTransferCart') || '{}');

    const res = await firstValueFrom(this.http.get<any>(`/api/store/diff?entity=${selected}&source=${sourceEnv}&destination=${destinationEnv}&ids=${rowIds.join()}`));

    // Use a single line of logic for updating storeListings or pricePoints
    const updatedRecords = selected === 'store-listings' ? storeListings : pricePoints;
    const newRecords = res.records.filter((item: any) => !updatedRecords.some((existingItem: any) => existingItem.id === item.id));

    if (selected === 'store-listings') {
      storeListings = [...storeListings, ...newRecords];
    } else {
      pricePoints = [...pricePoints, ...newRecords];
    }

    // Construct the newCart object directly without unnecessary variables
    const newCart = {
      listings: storeListings,
      pricePoint: pricePoints,
      sourceEnv,
      destinationEnv
    };

    this.localStore('storeTransferCart', newCart);

    // Simplify detail message since both branches of the conditional returned the same result
    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Records Added',
      detail: `${rowIds.length} Records Added to Store Transfer Cart`
    });

    this.dataService.updateStoreTransferCount(newCart.listings?.length + newCart.pricePoint?.length)
  }

  getSelectedEnvironment() {
    // Parse the selectedEnvironment string from localStorage
    const selectedEnvString = localStorage.getItem('selectedEnvironment');
    if (selectedEnvString) {
      return JSON.parse(selectedEnvString);
    } else {
      return { label: 'Development', value: 'dev', default: true };
    }
  }

  async localStore(key: any, obj: any) {
    localStorage.setItem(key, JSON.stringify(obj))
  }

  filterColumnsByEnvironment(columns: ColDef[], env: string): ColDef[] {
    // Define all possible environment suffixes
    const envSuffixes = ['dev', 'qa', 'prod', 'test'];
    // Remove the current environment from the list of suffixes to exclude
    const excludeSuffixes = envSuffixes.filter(suffix => suffix !== env);

    return columns.filter(column => {
      // Check if the column is explicitly tied to a different environment
      const isColumnOfDifferentEnv = excludeSuffixes.some(suffix => column.field?.endsWith(`-${suffix}`));
      // Include the column if it is not tied to a different environment
      // or if it matches the current environment
      return !isColumnOfDifferentEnv || column.field?.endsWith(`-${env}`);
    });
  }

  disableTableActions(actions: Array<TABLE_ACTIONS>) {
    this.disabledTableActionsSubject.next(actions);
  }

}
