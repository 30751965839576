import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams, TextCellEditor } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';

@Component({
  selector: 'app-batches-table-ag-grid',
  templateUrl: './batches-table-ag-grid.component.html',
  styleUrls: ['./batches-table-ag-grid.component.sass']
})
export class BatchesAgGridComponent implements OnInit {

  public entity: string = 'batches';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = 'vendor_ref';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Batch Number',
        field: 'batchNumber',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
        },
        floatingFilter: true,

        cellEditor: TextCellEditor,
        cellEditorParams: {
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Content Status',
        field: 'contentStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Approved', 'Awaiting Revision', 'QA Ready', 'In Production', 'In Testing', 'On Hold', 'Scheduled', 'Test Ready'],
            addEmptyFilters: true
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Art Status',
        field: 'vendorStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Approved', 'Awaiting Revision', 'QA Ready'],
            addEmptyFilters: true
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Asset Type',
        field: 'assetType',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            'Hard Surface', 'HS Recolor', 'Organics', 'Organic Recolors',
          ],
          addEmptyFilters: true
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true,
        },
        cellEditorPopupPosition: 'under',
        type: 'editableColumn',
      },
      {
        headerName: 'Batch Status',
        field: 'batchStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Approved', 'Awaiting Revision', 'QA Ready'],
            addEmptyFilters: true
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Year',
        field: 'year',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            '2021', '2022', '2023','2024','2025'
          ],
          addEmptyFilters: true
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Vendor',
        field: 'vendor_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'vendors'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.vendor_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions(){
    const entities = [
      { ref: 'vendor_ref', entity: 'vendors'},
    ];

    for (const { ref, entity } of entities) {
      const params = new OptionsParams({
        entity
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }
  }

}
