<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="endless-offer"
    [viewName]="title"
    [record]="endlessOfferRecord"
    [fields]="fields"
    [sidebarFields]="sidebarFields"
    [showLocalizedValuesButton]="true"
>
</dynamic-view-v2>
