import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api'; // Adjust the import path as necessary

@Component({
  selector: 'app-display-thumbnail',
  templateUrl: './display-thumbnail.component.html'
})
export class DisplayThumbnailComponent implements OnInit {
  @Input() record: any; // Adjust the type as per your data structure
  @Input() field: any; // Adjust the type as per your data structure
  @Input() displayThumbnail: boolean = false;
  @Input() timestamp = new Date().getTime();

  imgData: any; // Define the structure as per your requirements
  showImgDialog: boolean = false; // Assuming this is a boolean flag used in your template

  constructor(private clipboard: Clipboard, private messageService: MessageService) { }

  ngOnInit(): void {
    // Initialization logic if needed
  }

  displayImage(url: string): void {
    this.imgData = {
      title: this.record.name,
      name: url.replace('_128.', '_1024.'),
      path: url.replace('_128.', '_1024.'),
    };
    this.showImgDialog = true;
  }

  copyToClipboard(copyVal: string): void {
    this.clipboard.copy(copyVal);
    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Thumbnail Copied',
      detail: `URL copied to clipboard successfully!`,
    });
  }
}

