<div style="position: relative;">
<ngx-spinner bdColor="rgba(36,34,36,0.8)" size="medium" color="#81c784" type="line-scale" [fullScreen]="false">
</ngx-spinner>
<div class="p-mt-2" *ngFor="let field of fields">
    <ng-container *ngIf="!isLoading" [ngSwitch]="field.value">
        <ng-container *ngSwitchCase="fieldType.AnnouncementImage">
            <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                    <p-dropdown
                    [(ngModel)]="record.announcement_image"
                    [ngModelOptions]="{ standalone: true }"
                    [options]="options['image_ref']"
                    optionLabel="name"
                    optionValue="_id"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    filterBy="id,name,path"
                    id="announcement_image"
                    >
                        <ng-template let-option pTemplate="selectedItem">
                            <div>
                            ({{ option.id }})
                            <strong>Name:</strong> {{ option.name }}
                            <strong>Path:</strong>{{ option.path }}
                            </div>
                        </ng-template>
                            <ng-template let-option pTemplate="item">
                            <div>
                                ({{ option.id }})
                                <strong>Name:</strong> {{ option.name }}
                                <strong>Path:</strong>{{ option.path }}
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <label for="announcement_image">Announcement Image </label>
                </span>
                <p-button *ngIf="record.announcement_image" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="record.series_ref.announcement_image = null"></p-button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Prizes">
            <div class="p-grid p-mx-5" *ngIf="!isLoading">
                <!-- 'Prizes' - Left column -->
                <div class="p-col-12 p-pr-4">
                    <!-- 3/5 Prizes -->
                    <prizes-card-form-group [version]="1" [(prize)]="prizes35" [showPrize]="selectedPrizes.includes('3/5')" fieldsetName="Prize(s) [3/5]" (onPrizeChange)="onPrizeChange('3/5')"></prizes-card-form-group>
                </div>
                <div class="p-col-12 p-pr-4">
                    <!-- 4/5 Prizes -->
                    <prizes-card-form-group [version]="1" [(prize)]="prizes45" [showPrize]="selectedPrizes.includes('4/5')" fieldsetName="Rewards" (onPrizeChange)="onPrizeChange($event)"></prizes-card-form-group>
                </div>
                <div class="p-col-12 p-pr-4">
                    <!-- 5/5 Prizes -->
                    <prizes-card-form-group [version]="1" [(prize)]="prizes55" [showPrize]="selectedPrizes.includes('5/5')" fieldsetName="Prize(s) [5/5]" (onPrizeChange)="onPrizeChange('5/5')"></prizes-card-form-group>
                </div>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Challenges">
            <div class="p-m-9 p-inputgroup">
                <span class="p-float-label">
                    <p-autoComplete
                        [(ngModel)]="record.challenges_ref"
                        [ngModelOptions]="{ standalone: true }"
                        [multiple]="true"
                        [suggestions]="suggestions['challenges_ref']"
                        (completeMethod)="
                        getSuggestionsForRef($event.query, 'challenges_ref', 'challenges')"
                        [showEmptyMessage]="true"
                        field="_id"
                        minLength="2"
                    >
                        <ng-template let-item pTemplate="selectedItem">
                            {{ item.name }} ({{ item.id }})
                        </ng-template>

                        <ng-template let-item pTemplate="item">
                            {{ item.name }} ({{ item.id }})
                        </ng-template>
                    </p-autoComplete>
                    <label for="challenges_ref"> Challenges </label>
                </span>
                <!-- <p-button *ngIf="seriesRecord.challenges_ref && seriesRecord.challenges_ref.length > 0" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="seriesRecord.challenges_ref = []"></p-button> -->
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Collections">
            <div class="p-my-5 p-inputgroup" style="max-width:90vw">
                <span ngClass="p-float-label">
                    <p-dropdown
                    [(ngModel)]="record.collection_ref"
                    [ngModelOptions]="{ standalone: true }"
                    [options]="options['collection_ref']"
                    optionLabel="name"
                    optionValue="_id"
                    [autoDisplayFirst]="false"
                    [filter]="true"
                    filterBy="name"
                    id="collections"
                    >
                    </p-dropdown>
                    <label for="collections"> Collection </label>
                </span>
                <p-button *ngIf="record.collection_ref" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="record.collection_ref = null"></p-button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.LoadingScreen">
        <div class="p-my-5 p-inputgroup">
            <dropdown-multiselect
                [(selectedOptions)]="record.loadingScreen_ref"
                optionLabel="name"
                optionValue="_id"
                labelName="Loading Screens"
                apiControllerRoute="loading-screens"
                [queryLimitValue]="15"
                [useSetOptionsFromRef]="false"
                style="width: 100%;"
                [isArrayOfStringIds]="false"
                (onSelectedOptionsChange)="onRefsChange('loading-screens', $event)"
            >
            </dropdown-multiselect>
        </div>
    </ng-container>
        <ng-container *ngSwitchCase="fieldType.Colors">
                    <div class="p-grid p-mx-5" *ngIf="!isLoading">
                        <!-- 'Colors' - Left column -->
                        <div class="p-col-12 p-pr-4">
                            <!-- Colors Component -->
                            <select-multiple-colors [(colors)]="record.colors"></select-multiple-colors>
                        </div>
                    </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Copy">
            <div class="p-my-5 p-inputgroup">
                <span class="p-float-label">
                    <textarea [(ngModel)]="record.copy" [ngModelOptions]="{ standalone: true }" id="float-input" rows="5" cols="30" pInputTextarea></textarea>
                    <label for="float-input">Copy</label>
                </span>
                <p-button *ngIf="record.copy" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="record.copy = ''"></p-button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Name">
            <div class="p-my-5 p-inputgroup">
                <span class="p-float-label">
                    <input  *ngIf="!record['series_ref']"  type="text" pInputText [(ngModel)]="record.seriesName" [ngModelOptions]="{ standalone: true }">
                    <input  *ngIf="record['series_ref']"  type="text" pInputText [(ngModel)]="record['series_ref'].name" [ngModelOptions]="{ standalone: true }">
                    <label for="float-input">Name</label>
                </span>
                <p-button *ngIf="record.name" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="record.Name = ''"></p-button>
            </div>
        </ng-container>
        <ng-container *ngSwitchCase="fieldType.Inbox">
            <div class="p-my-5 p-inputgroup" style="max-width:90vw">
                <dropdown-multiselect
                    [(selectedOptions)]="record.inboxMessages_ref"
                    optionLabel="name"
                    optionValue="_id"
                    labelName="Inbox Messages"
                    apiControllerRoute="inbox-messages"
                    [queryLimitValue]="45"
                    [useSetOptionsFromRef]="false"
                    style="width: 100%;"
                    [isArrayOfStringIds]="false"
                    (onSelectedOptionsChange)="onRefsChange('inbox-messages', $event)"
                >
                </dropdown-multiselect>
            </div>
        </ng-container>
    </ng-container>

</div>
</div>
