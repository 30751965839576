import { Component, Input, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { DataService } from 'src/app/services/data.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'data-component',
  templateUrl: './data-component.component.html',
  styleUrls: ['./data-component.component.sass']
})
export class DataComponentComponent implements OnInit
{
  @Input() data: any;
  @Input() key: any = null;
  constructor(
    private readonly dataService: DataService,
    private readonly http: HttpClient,
    private readonly utilitiesService: UtilitiesService
  )
  {
  }

  /**
   * Store Transfer Initial Implementation
   */
  async ngOnInit()
  {
  }

  getType(data: any): string {
    if (data === null) {
      return 'null';
    } else if (Array.isArray(data)) {
      // Check if all items in the array are objects
      if (data.every(item => typeof item === 'object')) {
        // console.log(data)
        return 'arrayOfObjects';
      } else {
        return 'array';
      }
    } else if (typeof data != 'number' &&  this.isDate(data)) {
      return 'date';
    } else if (this.key == 'pricePoint_ref') {
      return 'link'
    } 
    else {
      return typeof data;
    }
  }

  isDate(value: any): boolean {
    // Use a regex to check if the value is in a valid date format
    return !isNaN(Date.parse(value));
  }

}
