import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'src/app/common/services/logger.service';
import { EntityViewService } from 'src/app/services/entity-view.service';

@Component({
  selector: 'app-icons-view',
  templateUrl: './icons-view.component.html',
  styleUrls: ['./icons-view.component.sass']
})
export class IconsViewComponent implements OnInit {

  entity: any;
  type: string = 'categories'
  @Input() id: any;

  
  icon_deselected: any[] = [];
  icon_selected: any[] = [];
  icon_disabled: any[] = [];
  
  climateIcon_deselected: any[] = [];
  climateIcon_selected: any[] = [];
  climateIcon_disabled: any[] = [];

  icons: []
  climateIcons: any

  isLoading: boolean;

  constructor(
    private entityViewService: EntityViewService,
    private loggerService: LoggerService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(){
    this.loggerService.log('check the id', this.id)
    await this.entityViewService
      .getEntity(this.type, { query: { id: this.id } })
      .then(async (data: any) => {
        this.loggerService.log('doc',data);
        this.entity = data;

        if(this.entity.icons){
          for(let icons of this.entity.icons){
            if(icons.deselected != undefined){
              this.icon_deselected.push(
                {
                  areValuesNull: false,
                  id: icons.deselected.id,
                  name: icons.deselected.name,
                  path: icons.deselected.path,
                  url: icons.deselected.thumbnail_url_256
                })
            } else{
              this.icon_deselected.push(
                {
                  areValuesNull: true,
                  name: null,
                  path: null,
                  url: null,
                }
              )
            }
            if(icons.selected != undefined){
              this.icon_selected.push(
                {
                  areValuesNull: false,
                  id: icons.selected.id,
                  name: icons.selected.name,
                  path: icons.selected.path,
                  url: icons.selected.thumbnail_url_256
                })
            } else{
              this.icon_selected.push(
                {
                  areValuesNull: true,
                  id: null,
                  name: null,
                  path: null,
                  url: null,
                }
              )
            }
            if(icons.disabled != undefined){
              this.icon_disabled.push(
                {
                  areValuesNull: false,
                  id: icons.disabled.id,
                  name: icons.disabled.name,
                  path: icons.disabled.path,
                  url: icons.disabled.thumbnail_url_256
                })
            } else{
              this.icon_disabled.push(
                {
                  areValuesNull: true,
                  id: null,
                  name: null,
                  path: null,
                  url: null,
                }
              )
            }
          }
        }
      
        if(this.entity.climateIcons){
          for(let climateIcons of this.entity.climateIcons){
            if(climateIcons.deselected != undefined){
              this.climateIcon_deselected.push(
                {
                  areValuesNull: false,
                  id: climateIcons.deselected.id,
                  name: climateIcons.deselected.name,
                  path: climateIcons.deselected.path,
                  url: climateIcons.deselected.thumbnail_url_256
                })
            } else{
              this.climateIcon_deselected.push(
                {
                  areValuesNull: true,
                  id: null,
                  name: null,
                  path: null,
                  url: null,
                }
              )
            }
            if(climateIcons.selected != undefined){
              this.climateIcon_selected.push(
                {
                  areValuesNull: false,
                  id: climateIcons.selected.id,
                  name: climateIcons.selected.name,
                  path: climateIcons.selected.path,
                  url: climateIcons.selected.thumbnail_url_256
                })
            } else{
              this.climateIcon_selected.push(
                {
                  areValuesNull: true,
                  id: null,
                  name: null,
                  path: null,
                  url: null,
                }
              )
            }
            if(climateIcons.disabled != undefined){
              this.climateIcon_disabled.push(
                {
                  areValuesNull: false,
                  id: climateIcons.disabled.id,
                  name: climateIcons.disabled.name,
                  path: climateIcons.disabled.path,
                  url: climateIcons.disabled.thumbnail_url_256
                })
            } else{
              this.climateIcon_disabled.push(
                {
                  areValuesNull: true,
                  id: null,
                  name: null,
                  path: null,
                  url: null,
                }
              )
            }
          }
        }
      })
      .catch((error) => {
        console.log('error getting entity', error);
      });

      this.loggerService.log('this.icon_deselected', this.icon_deselected);
      this.loggerService.log('this.icon_selected', this.icon_selected);
      this.loggerService.log('this.icon_disabled', this.icon_disabled);
      this.loggerService.log('this.icon_disabled available?', this.icon_disabled != undefined);
      this.loggerService.log('this.climateIcon_deselected', this.climateIcon_deselected);
      this.loggerService.log('this.climateIcon_selected', this.climateIcon_selected);
      this.loggerService.log('this.climateIcon_disabled', this.climateIcon_disabled);
  }

}
