import { Component } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { IRowNode, IToolPanelParams } from 'ag-grid-enterprise';
import { DataService } from 'src/app/services/data.service';
import { TableCommunicationService } from '../../services/table-communication.service';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';

@Component({
  selector: 'app-grid-view-settings',
  templateUrl: './grid-view-settings.component.html',
  styleUrls: ['./grid-view-settings.component.sass']
})
export class GridViewSettingsComponent implements IToolPanelAngularComp {
  private params!: IToolPanelParams;
  public entity: string;
  variables: any = {
    headerField: {
      id: 'Thumbnail',
    },
    bodyFields: []
  };

  optionsThumbnail: any = [
    'Render',
    'Thumbnail'
  ];
  selectedThumbnail: any;
  suggestions: any;
  filteredSuggestions: any[] = [];
  selectedItem: any;

  constructor(
    private dataService: DataService,
    private tableCommunicationsService: TableCommunicationService,
  ) {}

  async agInit(params: any) {
    this.params = params;
    this.entity = params.entity;
    this.suggestions = params.fields;
    await this.syncGridViewSettings();
  }

  refresh(): void {}

  searchStrings(event: any) {
    const query = event.query.toLowerCase();
    this.filteredSuggestions = this.suggestions
      .filter((str: any) =>
        str.headerName.toLowerCase().includes(query) &&
        !this.arrayContainsString(this.variables.bodyFields, str.headerName)
      );
  }

  arrayContainsString(arr: any[], searchString: string): boolean {
    return arr.some(item => item.name.headerName.toLowerCase() === searchString.toLowerCase());
  }

  addField(event: any) {
    console.log(event);

    const isNameEvent = event.headerName === 'ID';
    const linkValue = isNameEvent ? true : false;

    const viewTypeMapping: { [key: string]: BaseViewFieldType[] } = {
      name: [BaseViewFieldType.NameLink],
      ID: [BaseViewFieldType.Text],
      // Add more mappings as needed
    };

    const viewTypes = viewTypeMapping[event.key as string] || [BaseViewFieldType.Text]; // Default to Text if not found

    this.variables.bodyFields = [
      ...this.variables.bodyFields,
      {
        name: event,
        enabled: false,
        size: 0,
        link: linkValue,
        type: event.key == 'enabled' ? 'boolean' : 'default',
        viewTypes: viewTypes,
        label: event.headerName
      }
    ];

    this.selectedItem = null;
    this.filteredSuggestions = [];
    this.saveCustomPreset();
  }

  async saveCustomPreset() {
    if (this.variables) {
      console.log('VARIABLES', this.variables);
      let response: any = await this.dataService.updateUserSettings('ag-grid-' + this.entity, 'gridViewSettings', this.variables);
    }
  }

  async syncGridViewSettings() {
    try {
      let pageRef = 'ag-grid-' + this.entity;
      let settings = await this.dataService.getUserSettings(pageRef, `gridViewSettings`);
      if (settings && settings[0]) {
        this.variables = settings[0].settings;
        this.mapFieldsForDynamicView();
      } else {
        this.variables = {
          headerField: {
            id: "Thumbnail"
          },
          bodyFields: [
            {
              name: {
                headerName: "Name",
                key: "name"
              },
              enabled: true,
              size: 12,
              link: false,
              type: "default",
              viewTypes: [BaseViewFieldType.Text],
              label: "Name"
            }
          ]
        };
        this.saveCustomPreset();
      }
    } catch (error) {
      console.error("Failed to update user settings", error);
    }
  }

  async refreshComponent() {
    await this.saveCustomPreset();
    this.tableCommunicationsService.emitEvent('refreshGridViewSettings');
  }

  deleteItem(index: number) {
    this.variables.bodyFields.splice(index, 1);
    this.saveCustomPreset();
  }

  // Function to map field definitions based on type
  mapFieldsForDynamicView() {
    if (this.variables.bodyFields) {
      this.variables.bodyFields = this.variables.bodyFields.map((fieldConfig: any) => {
        const fieldDefinition: any = this.getFieldDefinition(fieldConfig.type, fieldConfig.name);
        return { ...fieldConfig, ...fieldDefinition };
      });
    }
  }

  getFieldDefinition(type: string, name: any) {
    switch (type) {
      case 'text':
        return {
          inputType: DynamicViewType.BaseViewField,
          viewTypes: [BaseViewFieldType.Text],
          clearField: true,
          key: name.key,
          isRequired: true,
          label: name.headerName
        };
      case 'boolean':
        return {
          inputType: DynamicViewType.BaseViewField,
          viewTypes: [BaseViewFieldType.Boolean],
          clearField: true,
          key: name.key,
          isRequired: true,
          label: name.headerName
        };
      // Add more cases for different types
      default:
        return {
          inputType: DynamicViewType.BaseViewField,
          viewTypes: [BaseViewFieldType.Text],
          clearField: true,
          key: name.key,
          isRequired: true,
          label: name.headerName
        };
    }
  }
}
