
<div *ngIf="showIsLoop" class="flex-container p-ml-2 p-mb-2">
  <p class="label">Is Loop</p>
  <p-inputSwitch class="field-item" [(ngModel)]="rewardsTrackRecord.isLoop"
    [ngModelOptions]="{ standalone: true }" (ngModelChange)="onChange('isLoop', $event);"></p-inputSwitch>
</div>
<div [ngSwitch]="rewardsTrackRecord.type">
    <!-- Daily Login Rewards - 7 days -->
    <div *ngSwitchCase="1">
        <p-orderList [value]="sevenDays" (onReorder)="assignDailyRewards(sevenDays)" [listStyle]="{ 'max-height': '50rem' }" header="Reward Track" [dragdrop]="true">
            <ng-template let-day let-i="index" pTemplate="item">
                <div class="flex justify-items-center p-2 w-full flex-wrap">
                    <div class="w-full text-center">
                        <prizes-card-form-group
                            [(prize)]="rewardsTrackRecord['dailyRewardDay'+(i+1)]"
                            [showPrize]="true"
                            [fieldsetName]="'Day '+(i+1)+' Rewards'"
                            [excludeResourceTypes]="null"
                            (onPrizeChange)="onChange('dailyRewardDay'+(i+1), $event); rewardsTrackRecord['dailyRewardDay'+(i+1)] = $event;"
                            >
                        </prizes-card-form-group>
                    </div>
                </div>
            </ng-template>
        </p-orderList>
    </div>
    <!-- Daily Login Rewards - Day 30 -->
    <div *ngSwitchCase="2">
        <prizes-card-form-group
            [(prize)]="rewardsTrackRecord['dailyRewardDay30']"
            [showPrize]="true"
            [fieldsetName]="'Day 30 Rewards'"
            [excludeResourceTypes]="null"
            (onPrizeChange)="onChange('dailyRewardDay30', $event); rewardsTrackRecord['dailyRewardDay30'] = $event;"
        >
        </prizes-card-form-group>
    </div>
    <!-- Season Pass - Free track -->
    <div *ngSwitchCase="3">
        <season-pass-tracks-field [(seasonPassRecord)]="rewardsTrackRecord" [isFreeTrack]="true" (onChange)="onChange('free', $event)"></season-pass-tracks-field>
    </div>
    <!-- Season Pass - Paid track -->
    <div *ngSwitchCase="4">
        <season-pass-tracks-field [(seasonPassRecord)]="rewardsTrackRecord" [isFreeTrack]="false" (onChange)="onChange('paid', $event)"></season-pass-tracks-field>
    </div>
    <!-- Friends Invited -->
    <div *ngSwitchCase="5">
      <season-pass-tracks-field [(seasonPassRecord)]="rewardsTrackRecord" [isFriendsTrack]="true" (onChange)="onChange('friends', $event)"></season-pass-tracks-field>
    </div>
    <!-- Endless OFfer -->
    <div *ngSwitchCase="6">
      <eo-rewards-track-dynamic-field
        [(endlessOfferTrackRecord)]="rewardsTrackRecord"
        [columnCount]="rewardsTrackRecord?.column_count"
        (onRecordChange)="onChange('EOrewards',$event); onChange('EObundles',$event)"
        [field]="field"
        [options]="EOTrackOptions"
        [parentFieldData]="EOTrackParentData"
      ></eo-rewards-track-dynamic-field>
    </div>

    <!-- Co-Op -->
    <div *ngSwitchCase="7">
      <season-pass-tracks-field [(seasonPassRecord)]="rewardsTrackRecord" [isCoopTrack]="true" (onChange)="onChange('coop', $event)"></season-pass-tracks-field>
    </div>

    <!-- Mini Games -->
    <div *ngSwitchCase="8">
      <season-pass-tracks-field [(seasonPassRecord)]="rewardsTrackRecord" [isMiniGameTrack]="true" (onChange)="onChange('miniGamesRewards', $event)"></season-pass-tracks-field>
    </div>
    <!-- Seasonal Prizes -->
    <div *ngSwitchCase="9">
        <season-pass-tracks-field [(seasonPassRecord)]="rewardsTrackRecord" [isSeasonalPrizesTrack]="true" (onChange)="onChange('seasonalPrizes', $event)"></season-pass-tracks-field>
      </div>
    <!-- No type selected message -->
    <div *ngSwitchDefault>
        <span>
            <h3> Please select a type. </h3>
        </span>
    </div>
</div>
