import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { DataService } from 'src/app/services/data.service';
import * as _ from 'lodash';
import { TableService } from 'src/app/services/table.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { ChallengeService } from 'src/app/entities/challenge/services/challenge.service';
import { SpinnerService } from 'src/app/common/services/spinner.service';

@Component({
  selector: 'duplicate-from-record',
  templateUrl: './duplicate-from-record.component.html',
  styleUrls: ['./duplicate-from-record.component.sass'],
  
})
export class DuplicateFromRecordComponent implements OnInit 
{
  @Input() row: any;
  @Input() tableType: string;
  @Input() entityId: any;
  @Input() isEnabled: boolean;
  @Input() disableButton: boolean;
  @Output() onRowChange = new EventEmitter<any>();
  @Output() onPathValueChange = new EventEmitter<any>();

  constructor
  (
    private utilitiesService: UtilitiesService,
    private dataService: DataService,
    private tableService: TableService,
    private messageService: MessageService,
    private validationService: ValidationsService,
    private loggerService: LoggerService,
    private challengeService: ChallengeService,
    private spinnerService: SpinnerService,
  ) { }

  /**
   * Duplicate From Record Component Initialization
   */
  ngOnInit() { }

  /**
   * Duplicates the row and adds _copy to unique values or sets them to null
   */
  async duplicateRow() 
  {
    this.spinnerService.loadSpecificSpinner('duplicate-from-record-spinner');
    //makes a deep copy of the row
    const originalRow = _.cloneDeep(this.row);
    let duplicatedRow = this.row;
    //changes file name to recognize a copy and comply with unique filenames
    duplicatedRow.isLocked = false;
    duplicatedRow.env = [];

    let fieldsToDelete = ['id', 'imageBuildData', 'imageBuildStatus', 'buildStatus', 'asset_versions', 'assetBuildOutput',
    'buildData', 'imageBuildOutput', 'imageLastHash', 'imageLastVersion', 'image_versions', 'assetLastHash', 'scene_ref', 'image_ref', 'prefab_ref', 'thumbnail_ref'];

    for (let field of fieldsToDelete) 
    {
      if (duplicatedRow[field]) 
      {
        delete duplicatedRow[field];
      }
    }

    switch (this.tableType) 
    {
      case 'challenges':
        duplicatedRow = this.duplicateChallenge(originalRow, duplicatedRow);
        break;
      case 'items':
        duplicatedRow = this.duplicateItems(originalRow, duplicatedRow);
        break;
      case 'items-sourcing':
        duplicatedRow = this.duplicateSourcingItems(originalRow, duplicatedRow);
        break;
      case 'challenges-sourcing':
        duplicatedRow = this.duplicateSourcingChallenges(originalRow, duplicatedRow);
        break;
    
      default:
        break;
    }

    //check row that's being duplicated
    this.loggerService.log('Duplicated Row', duplicatedRow);
    if(duplicatedRow)
    {
      //call api to add the new record
      let newRecord = await this.dataService.addNewRecord(this.tableType, duplicatedRow);
      await this.afterRowDuplication(originalRow, duplicatedRow, newRecord);
      this.spinnerService.endSpecificSpinner('duplicate-from-record-spinner');
    }
    else
    {
      this.spinnerService.endSpecificSpinner('duplicate-from-record-spinner');
      return;
    }
  }

  /**
   * Handles challenge record duplication
   * 
   * @param originalRow Original record.
   * @param duplicatedRow Duplicated record.
   */
  duplicateChallenge(originalRow: any, duplicatedRow: any)
  {
    if(!this.validateFileName(duplicatedRow))
    {
      return null;
    }

    duplicatedRow.fileName = this.challengeService.constructLevelName(duplicatedRow.sceneType ? duplicatedRow.sceneType : null, duplicatedRow.type_ref.name == 'Grand');
    //sets parent challenge
    duplicatedRow.parent_challenge = this.tableType == 'challenges' ? originalRow.id : null;

    return duplicatedRow;
  }

  /**
   * Handles items record duplication
   * 
   * @param originalRow Original record.
   * @param duplicatedRow Duplicated record.
   */
  duplicateItems(originalRow: any, duplicatedRow: any)
  {
    if(!this.validateFileName(duplicatedRow))
    {
      return null;
    }
    duplicatedRow.fileName = originalRow.fileName + '_duplicated';
    if(duplicatedRow.internalReferenceLinks && duplicatedRow.internalReferenceLinks.length > 0)
    {
      duplicatedRow.internalReferenceLinks.forEach((element: any, index: number) =>
      {
        duplicatedRow.internalReferenceLinks[index] = { link: element }
      });
    }

    if(duplicatedRow.referenceLinks && duplicatedRow.referenceLinks.length > 0)
    {
      duplicatedRow.referenceLinks.forEach((element: any, index: number) =>
      {
        duplicatedRow.referenceLinks[index] = { link: element }
      });
    }

    return duplicatedRow;
  }

  /**
   * Handles Sourcing Items record duplication
   * 
   * @param originalRow Original record.
   * @param duplicatedRow Duplicated record.
   */
  duplicateSourcingItems(originalRow: any, duplicatedRow: any)
  {
    duplicatedRow.fileName = null;
    if (duplicatedRow.item_ref) 
    {
      duplicatedRow.item_ref = null;
      duplicatedRow.sourcing_logs = null;
      duplicatedRow.promoted = false;
    }

    return duplicatedRow;
  }

  /**
   * Handles sourcing challenge record duplication
   * 
   * @param originalRow Original record.
   * @param duplicatedRow Duplicated record.
   */
  duplicateSourcingChallenges(originalRow: any, duplicatedRow: any)
  {
    // duplicatedRow.fileName = 'placeholder_' + Math.floor(Math.random() * 1000);

    // duplicatedRow.promoted = false;

    // return duplicatedRow;

    duplicatedRow.fileName = null;
    if (duplicatedRow.challenge_ref) 
    {
      duplicatedRow.challenge_ref = null;
      duplicatedRow.sourcing_logs = null;
      duplicatedRow.promoted = false;
    }

    return duplicatedRow;
  }

  /**
   * Validates FileName value.
   * 
   * @param duplicatedRow Duplicated record.
   */
  validateFileName(duplicatedRow: any)
  {
    if (this.validationService.isEmpty(duplicatedRow.fileName)) 
    {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Failed to Duplicate Row',
        detail: `The filename for the row can not be emtpy or null`,
      });
      return false;
    }
    else
    {
      return true;
    }
  }

  /**
   * Handles data after record duplication.
   * 
   * @param originalRow Original record.
   * @param duplicatedRow Duplicated record.
   * @param newRecord New record.
   */
  async afterRowDuplication(originalRow: any, duplicatedRow: any, newRecord: any)
  {
    switch (this.tableType) 
    {
      case 'challenges':
        await this.afterChallengeDuplication(newRecord);
        break;
      case 'items-sourcing':
        await this.afterSourcingItemDuplication(newRecord);
        break;
      case 'challenges-sourcing':
        await this.afterSourcingChallengeDuplication(newRecord);
        break;
    
      default:
        this.messageService.add(
        {
          sticky: true,
          severity: 'success',
          summary: 'Row Duplicated Successfully',
          detail: `Row Duplication was succesful!`,
        });
        let rows = await this.dataService.getAllOfTypeAsync(this.tableType, {
          query: {},
        });
        this.onRowChange.emit(rows);
        break;
    }
  }

  /**
   * Handles challenge data after record duplication.
   * 
   * @param newRecord New record.
   */
  async afterChallengeDuplication(newRecord: any)
  {
    newRecord.fileName = newRecord.fileName.replace('challengeID', newRecord.id);
    await this.dataService.updateRecord(newRecord.id, 'challenges', newRecord);

    this.messageService.add(
    {
      sticky: true,
      severity: 'success',
      summary: 'Challenge Duplicated Successfully',
      detail: `Challenge Duplication was succesful! Redirecting to new challenge`,
    });

    let rows = await this.dataService.getAllOfTypeAsync(this.tableType, {
      query: {},
    });

    this.onRowChange.emit(rows);
  }

  /**
   * Handles sourcing item data after record duplication.
   * 
   * @param newRecord New record.
   */
  async afterSourcingItemDuplication(newRecord: any)
  {
    // if items-sourcing we should generate new filename based on new recor values.
    if (newRecord.itemFileType_ref && newRecord.itemFileType_ref._id) 
    {
      // generating fake event
      let copyEvent = {
        event: newRecord.itemFileType_ref._id,
        inputName: 'fileType',
        entity: newRecord,
      };
      // emmiting pathComponent change event
      this.onPathValueChange.emit(copyEvent);
    } else {
      newRecord.fileName = 'placeholder_' + Math.floor(Math.random() * 1000)
    }
    if(newRecord.sourceGroup_ref)
    {
      await this.tableService.getSourceRows(this.tableType, newRecord.sourceGroup_ref._id).then(async (data: any) => 
      {
        this.onRowChange.emit(data);
      });
    }
  }

  /**
   * Handles sourcing challenge data after record duplication.
   * 
   * @param newRecord New record.
   */
  async afterSourcingChallengeDuplication(newRecord: any)
  {
    newRecord.fileName = 'placeholder_' + Math.floor(Math.random() * 1000);
    await this.tableService.getSourceRows(this.tableType, this.entityId).then(async (data: any) => 
    {
      this.onRowChange.emit(data);
    });
  }
}
