import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Season Pass DTO
 */
export default class EndlessOfferDTO extends BaseEntityDto
{ 
  /**
   * internal name of the record
   */
  internalName?: string;

  /**
   * EO. Rewards Track reference
   */
  track_ref?: any;
  
  /**
   * Misc. Record reference
   */
  feedBannerImage_ref?: any;
  
  /**
   * Misc. Record reference
   */
  popupBannerImage_ref?: any;

  /**
   * Misc. Record reference
   */
  bundleImagery_ref?: any[];

  [key: string]: any; // Index signature
}
