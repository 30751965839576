import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { OptionsParams } from 'src/app/common/services/utilities.service';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';


@Component({
  selector: 'app-series-ag-grid',
  templateUrl: './series-ag-grid.component.html',
  styleUrls: ['./series-ag-grid.component.sass']
})
export class SeriesAgGridComponent implements OnInit {

  public entity: string = 'series';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService,
  )
  {
    this.autopopulateSelect = 'announcement_image';
  }

  async ngOnInit(): Promise<void> {
    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        cellRendererParams: {
          entity: this.entity
        },
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Internal Name',
        field: 'displayName',
        cellRendererParams: {
          entity: 'series'
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Display Name',
        field: 'name',
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        cellRenderer: TextCellRendererComponent,
        floatingFilter: true,
      },
      {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Mini Series', 'Flashback'],
            valueFormatter: (params: any) => {
                console.log('params', params);
                return `${params.value}`;
            },
            keyCreator: (params: any) => {
                return params.value;
            }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellRenderer: (params: any) => {
            const typeMapping = [
                { label: 'Mini Series', value: 'Mini Series' },
                { label: 'Flashback', value: 'Flashback' },
            ];
            const matchedType = typeMapping.find(type => type.value === params.value);
            return matchedType ? matchedType.label : ''; 
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: [
                'Mini Series',
                'Flashback',
            ]
        },
        valueSetter: (params: any) => {
            const typeMapping = [
                { label: 'Mini Series', value: 'Mini Series' },
                { label: 'Flashback', value: 'Flashback' },
            ];
            const matchedType = typeMapping.find(type => type.label === params.newValue);
            if (matchedType) {
                params.data.type = matchedType.value;
                return true;
            }
            return false;
        }
      },    
      {
        headerName: 'Start',
        field: 'start',
        sortable: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: this.agGridToolsService.dateValueGetter('start'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'End',
        field: 'end',
        sortable: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: this.agGridToolsService.dateValueGetter('end'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Challenges',
        field: 'challenges_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenges',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.challenges_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        width: 450,
        autoHeight: true,
      },
      {
        headerName: 'Reward(s)',
        field: 'rewards_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Announcement Image',
        field: 'announcement_image',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'Announcement Image',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.announcement_image,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Colors',
        field: 'colors',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorsInput: true
        },
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
      },
      {
        headerName: 'Copy',
        field: 'copy',
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        cellRenderer: TextCellRendererComponent,
        floatingFilter: true,
      },
      {
        headerName: 'Item Collections',
        field: 'collection_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'collections',
          isGuaranteedRarity: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.collection_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        },
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Challenge Collection',
        field: 'challengeCollection_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenge-collections',
          isGuaranteedRarity: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.challengeCollection_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        },
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Secondary Challenge Collection (NON I.G Logic)',
        field: 'secondaryChallengeCollection_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenge-collections',
          isGuaranteedRarity: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.challengeCollection_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        },
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Inbox Messages',
        field: 'inboxMessages_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'inbox-messages',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.inboxMessages_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        width: 450,
        autoHeight: true,
      },
      {
        headerName: 'Loading Screens',
        field: 'loadingScreen_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'loading-screens',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loadingScreen_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        width: 450,
        autoHeight: true,
      },
      {
        headerName: 'Store Listings',
        field: 'storeListings_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'store-listings-v2',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.storeListings_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        width: 450,
        autoHeight: true,
      },
      {
        headerName: 'Infinite Challenge Including',
        field: 'isInfiniteChallengeIncluding',
        cellRenderer: (params: any) => {
          if (params.value === true) {
            return 'Including';
          } else {
            return 'Excluding';
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => {
            if (params.value === true) {
              return 'Including';
            } else {
              return 'Excluding';
            }
          }
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Announcement Inbox Record',
        field: 'announcementInbox_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'inbox-messages',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.announcementInbox_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name'
        },
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right', // Pin the column to the right
        width: 120, // Set the width of the column
        resizable: true,
        cellRendererParams: {
          enableDuplicateFeature: true
        }
      },
    ];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
    this.tableParams = {
      enableDuplicateFeature: true,
    }
  }

  async setOptions() {
    const entities = [
      { ref: 'currencies_ref', entity: 'currencies', select: 'id _id name' },
      { ref: 'resources_ref', entity: 'resources', select: 'id _id name' },
      { ref: 'titles_ref', entity: 'titles', select: 'id _id name' },
      { ref: 'announcement_image', entity: 'miscellaneous-build', select: 'id _id name' },
      { ref: 'collection_ref', entity: 'collections', select: 'id _id name' },
      { ref: 'challengeCollection_ref', entity: 'challenge-collections', select: 'id _id name' },
      { ref: 'challenges_ref', entity: 'challenges', select: 'id _id name' },
      { ref: 'announcementInbox_ref', entity: 'inbox-messages', select: 'id _id name' },
    ];

    await Promise.all(entities.map(async ({ ref, entity, select }) => {
      const params = new OptionsParams({
      entity, select
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }));

  }
}
