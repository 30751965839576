import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import DailyRewardsDTO from '../dtos/DailyRewardsDTO';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'daily-rewards-dynamic-field',
  templateUrl: './daily-rewards-dynamic-field.component.html',
  styleUrls: ['./daily-rewards-dynamic-field.component.sass']
})
export class DailyRewardsDynamicFieldComponent implements OnInit {

  @Input() dailyRewardsRecord: DailyRewardsDTO = new DailyRewardsDTO();
  @Input() options: any;
  @Input() field: any;
  @Output() onChange = new EventEmitter<any>();

  type: string = "";
  value: Array<string> = [];

  constructor(
    private utilitiesService : UtilitiesService
  ) { }

  ngOnInit() {
    this.value = this.dailyRewardsRecord.rewardsTracks ? this.dailyRewardsRecord.rewardsTracks.map((a:any) => a._id) : [];
  }

  /**
   * Handle Record Changes
   *
   * @param field Field that changed.
   * @param event Value changed.
   */
  onRecordChange()
  {
    this.dailyRewardsRecord.rewardsTracks = this.value;
    this.onChange.emit({ field: 'rewardsTracks', record: this.dailyRewardsRecord});
  }


}
