<!-- Spinner -->
<ngx-spinner bdColor="rgba(36,34,36,0.8)" size="custom" style="width: 80px; height: 80px;" color="#81c784"
  [type]="tableSpinnerIcon">
  <p *ngIf="showSpinnerProgress" style="font-size: 20px; color: white">Loading... {{ showBulkPromote ?
    bulkPromoteProgress : bulkBuildProgress}}%</p>
  <p *ngIf="!showSpinnerProgress" style="font-size: 20px; color: white">Saving changes...</p>
</ngx-spinner>
<!-- Spinner -->
<ngx-spinner bdColor="rgba(36,34,36,0.8)" size="custom" style="width: 80px; height: 80px;" color="#81c784" type="timer"
  name="duplicate-from-record-spinner">
  <p style="font-size: 20px; color: white; z-index: 9999999;">Loading...</p>
</ngx-spinner>

<!-- Toaster Alert -->
<p-toast></p-toast>

<!-- Page Header -->
<ng-container>
  <div class="p-mx-3 p-d-flex p-jc-between">
    <!-- Left Side (Page Header) -->
    <div class="p-d-flex p-ai-center">
      <ng-container *ngIf="!['items-sourcing', 'challenges-sourcing'].includes(this.tableType) && !this.tableView">
        <h1>{{ tableName }} Table</h1>
        <span class="p-px-2">
          <p-badge [value]="table.totalRecords + ' / ' + rows.length" size="xlarge" class="p-mx-2">
          </p-badge>
          <ng-container *ngIf="nonPromotedSelectedRowsCount < 1; else rowsAreSelected">
            <p-badge [value]="selectedRows.length + ' Rows Selected'" size="xlarge" class="p-mx-2">
            </p-badge>
          </ng-container>

          <ng-template #rowsAreSelected>
            <p-button styleClass="p-button-lg p-mx-2 p-button-warning" (click)="onResetRows()">
              <h3 class="p-m-0" style="font-size: 1rem">{{ nonPromotedSelectedRowsCount }}
                Row{{nonPromotedSelectedRowsCount==1 ? '' : 's'}} (Clear)</h3>
            </p-button>
          </ng-template>
        </span>
      </ng-container>
      <ng-container *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)">
        <h2>
          {{ tableName }}
        </h2>
        <ng-container *ngIf="nonPromotedSelectedRowsCount < 1; else rowsAreSelected">
          <p-badge [value]="selectedRows.length + ' Rows Selected'" size="xlarge" class="p-mx-2">
          </p-badge>
        </ng-container>
        <ng-template #rowsAreSelected>
          <p-button styleClass="p-button-lg p-mx-2 p-button-warning" (click)="onResetRows()">
            <h3 class="p-m-0" style="font-size: 1rem">{{ nonPromotedSelectedRowsCount }}
              Row{{nonPromotedSelectedRowsCount==1 ? '' : 's'}} (Clear)</h3>
          </p-button>
        </ng-template>
      </ng-container>

      <div *ngIf="tableView">
        <h2>
          {{ tableName }}
          <p-tag *ngIf="tableViewType == 'progression-levels'" class="p-pb-1" rounded="true">{{ tableViewEntity.enabled
            ? "Enabled" : "Disabled" }}</p-tag>
        </h2>
      </div>
      <!-- Edit Mode Toggle Switch -->
      <ng-container *ngIf="!isLoading">
        <div class="p-mx-2 p-d-flex p-ai-center" *ngIf="!isArchived && !this.customRows">
          <i *ngIf="!this.isTableEditLocked" class="pi pi-eye" [style]="{
              'font-size': '1.2em',
              color: tableConfig.isEditMode ? '' : 'var(--primary-color)'
            }" (onClick)="toggleFullEditMode(false)" [disabled]="isPaginated && paginationIsLoading"></i>
          <p-inputSwitch [(ngModel)]="tableConfig.isEditMode"
            [disabled]="(this.isTableEditLocked) || (this.isPaginated && this.paginationIsLoading) || (this.columnsLoading)"
            class="p-mx-2" (onChange)="handleFullEditToggleChange($event)"></p-inputSwitch>
          <i *ngIf="!this.isTableEditLocked" class="pi pi-pencil" [style]="{
              'font-size': '1.2em',
              color: tableConfig.isEditMode ? 'var(--primary-color)' : ''
            }" (onClick)="tableConfig.isEditMode = true" [disabled]="isPaginated && paginationIsLoading"></i>
          <div *ngIf="this.isTableEditLocked" class="p-mx-2">
            <strong> Table Editing Disabled. Row Editing is Active</strong>
          </div>
          <p-button *ngIf="tableConfig.isEditMode" #submitEditMode_button label="Save Changes" class="p-mx-4"
            (click)="submitEditMode_overlay.toggle($event)"></p-button>
        </div>
        <p-button *ngIf="tableView" icon="pi pi-pencil" label="Edit" class="p-mx-4"
          (click)="editTableView()"></p-button>
      </ng-container>
    </div>


    <!-- Right Side (Page Header) -->
    <div class="p-d-flex p-ai-center">
      <ng-container *ngIf="isPaginated && paginationIsLoading">
        <i class="pi pi-cloud-download"></i>
        <div class="p-ml-1 p-mr-3" style="width: 100px;">
          <p-progressBar [value]="paginationProgress"></p-progressBar>
        </div>
      </ng-container>
      <ng-container *ngIf="!['items-sourcing', 'challenges-sourcing','miscellaneous-build'].includes(this.tableType)">
        <!-- 'Add New' button -->
        <a [href]="urlNewForm" target="_blank" style="text-decoration: none">
          <p-button label="New" icon="pi pi-fw pi-plus" class="p-button-sm" type="button"></p-button>
        </a>
      </ng-container>
      <ng-container *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)">
        <!-- 'Add New' button -->
        <a (click)="this.navigateToEdit()" target="_blank" style="text-decoration: none;  margin-right: 5px;">
          <p-button
            [label]="this.tableType == 'items-sourcing' ? 'Edit Sourcing Group' : 'Edit Sourcing Challenge Group'"
            icon="pi pi-pencil" class="p-button-sm" type="button">
          </p-button>
        </a>
        <a (click)="this.selectExistingItemForDuplication = true" target="_blank" style="text-decoration: none">
          <p-button
            [label]="this.tableType == 'items-sourcing' ? 'Copy Item into Sourcing Group' : 'Copy Challenge into Sourcing Group'"
            icon="pi pi-fw pi-plus" class="p-button-sm" type="button">
          </p-button>
        </a>
      </ng-container>
    </div>
  </div>
</ng-container>

<span *ngIf="this.tableView && tableViewType == 'progression-levels'">
  <h4>Internal Notes: {{ this.tableViewEntity.internal_notes ? this.tableViewEntity.internal_notes : 'N/A' }}</h4>
</span>
<span *ngIf="this.tableView && tableViewType == 'progression-levels'">
  <h4>Start: {{ (this.tableViewEntity.start ? (this.tableViewEntity.start | officeTime: 'medium') : 'N/A')
    }}&nbsp;&nbsp;&nbsp;&nbsp; End: {{ this.tableViewEntity.end ? (this.tableViewEntity.end | officeTime: 'medium') :
    'N/A' }}</h4>
  <h4 class="p-ml-1"> </h4>
</span>
<span *ngIf="this.tableView && tableViewType == 'progression-levels'">

</span>
<div *ngIf="this.tableView">
  <div class="p-ml-2" [innerHTML]="this.tableViewEntity.internal_notes"></div>
  <ng-container *ngFor="let item of this.tableViewEntity.items_ref">
    <div class="tags">
      <p-tag class="tags p-p-2" [value]="item.name + '(' + item.id + ')'"></p-tag>
      <span>&nbsp;</span>
    </div>

  </ng-container>
</div>
<span class="p-mx-3 p-mb-4 p-d-flex p-ai-center" *ngIf="this.tableType == 'items-sourcing'">
  <p-button label="HS Batch" icon="pi pi-inbox" class="p-mx-2" (click)="setBatchColumns(false)"></p-button>
  <p-button label="HS CSV Batch Export" icon="pi pi-inbox" class="p-mx-2"
    (click)="setCSVBatchedColumns(false)"></p-button>
  <p-button label="Organic Batch" icon="pi pi-sun" class="p-mx-2" (click)="setBatchColumns(true)"></p-button>
  <p-button label="Organic CSV Batch Export" icon="pi pi-sun" class="p-mx-2"
    (click)="setCSVBatchedColumns(true)"></p-button>
</span>

<!-- start of the table.  -->
<p-table #table [columns]="selectedColumns" [value]="rows" [autoLayout]="true" [reorderableColumns]="true"
  [sortMode]="tableConfig.isEditMode ? 'none' : 'single'" [(sortField)]="urlQueryParams.sortBy"
  [(sortOrder)]="urlQueryParams.sortOrder" (sortFunction)="customSort($event)" [customSort]="true"
  [globalFilterFields]="fullSearch ? globalFilters : minFilters " (onFilter)="onFilter($event)"
  [selection]="selectedRows" selectionMode="" [metaKeySelection]="false" [selectionPageOnly]="true"
  (onRowSelect)="onRowCheckboxToggle($event.data, true)" (onRowUnselect)="onRowCheckboxToggle($event.data, false)"
  (onPage)="scrollToTop()" [paginator]="true" [(rows)]="tableState.rows" [(first)]="tableState.first"
  [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
  [rowsPerPageOptions]="[25, 50, 100, 250, 500]" editMode="row" dataKey="id" (onColReorder)="onColumnsReorder($event)"
  [loading]="isLoading" styleClass="p-datatable-scrollable">
  <ng-template pTemplate="caption">
    <!-- Caption Controls -->
    <ng-container *ngIf="!isLoading">
      <div class="p-d-flex">
        <!-- Left Side -->
        <span>
          <!-- Columns Button -->
          <p-button label="Columns" icon="pi pi-list" [badge]="selectedColumns.length" badgeClass="danger"
            class="p-mx-2" [styleClass]="
            selectedColumns.length > 0 ? 'p-button' : 'p-button-outlined'
          " (click)="showColumns = !showColumns"></p-button>
          <p-button *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)" class="p-p-0"
            styleClass="p-button-rounded p-button-text p-button-sm p-button-success"
            label="Rows Created: {{rows.length}}"></p-button>
          <!-- Auto save icon -->
          <p-button *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)"
            icon="pi {{ isSaved ? 'pi-check-circle' : 'pi-spin pi-spinner' }}" class="p-p-0" styleClass="p-button-rounded p-button-text p-button-sm p-button-{{
            isSaved ? 'success' : 'info'
          }}" label="{{ isSaved ? 'Saved' : 'Saving...' }}"></p-button>
          <p-toggleButton [(ngModel)]="pageSelect" [onLabel]="'Page Select Mode'"
            offLabel="Select All Mode"></p-toggleButton>
        </span>

        <!-- Right Side -->
        <span class="p-ml-auto" [ngStyle]="{ float: 'right' }">
          <p-inputNumber *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)"
            [(ngModel)]="addRowValue" [min]="1" [max]="10" buttonLayout="vertical"
            [disabled]="isArchived"></p-inputNumber>
          <p-button *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)" label="Add Row"
            icon="pi pi-plus" class="p-mx-2" (click)="newRow()" [disabled]="isArchived"></p-button>
          <!-- Actions Button -->
          <p-button label="Actions" class="p-mx-2" [styleClass]="showActions ? 'p-button' : 'p-button-outlined'"
            (onClick)="clearMessages(); showActions = !showActions"
            [disabled]="(isArchived &&['items-sourcing', 'challenges-sourcing'].includes(this.tableType) )|| (isPaginated && paginationIsLoading) "></p-button>

          <!-- Filters Button -->
          <p-button label="Filters" icon="pi pi-list" [badge]="activeFilterCount" badgeClass="danger" class="p-mx-2"
            [styleClass]="
            activeFilterCount > 0 ? 'p-button' : 'p-button-outlined'
          " (click)="showFilters = true">
          </p-button>
          <!-- Show/Hide Archived -->
          <p-button *ngIf="tableType == 'sourcing-groups' || tableType == 'collections'" type="button"
            icon="{{ showArchivedRows ? 'pi pi-folder' : 'pi pi-inbox' }}"
            label="{{ showArchivedRows ? 'Hide Archived' : 'Show Archived' }}"
            [styleClass]="showArchivedRows ? 'p-button-outlined' : 'p-button'" (click)="onShowHideArchived()">
          </p-button>
          <p-checkbox *ngIf="this.tableType == 'challenges'" class="p-pb-2" label="Full Search" [binary]="true"
            [(ngModel)]="fullSearch"></p-checkbox>
          <!-- Global Search -->
          <span class="p-input-icon-left p-mx-2">
            <i class="pi pi-search"></i>
            <input [(ngModel)]="urlQueryParams.global" pInputText type="text"
              (input)="table.filterGlobal($event.target.value, 'regex-search')" placeholder="Table Search" />
          </span>
        </span>
      </div>

    </ng-container>

    <!-- Modals, Overlays, and Sidebars -->
    <!-- COLUMNS Sidebar -->
    <p-sidebar #columnsSidebar *ngIf="!isLoading" [(visible)]="showColumns" styleClass="column-sidebar p-sidebar-sm"
      [style]="{ 'min-width': '27em' }" [blockScroll]="false">
      <div class="p-d-flex p-flex-row p-ai-center">
        <h3 class="p-m-0">Columns</h3>
        <p-badge *ngIf="selectedColumns.length > 0" [value]="selectedColumns.length + ' / ' + columns.length"
          class="p-ml-auto p-my-auto">
        </p-badge>
      </div>

      <!-- Column Sets -->
      <div class="p-my-3">
        <div class="p-d-flex p-jc-center">
          <h5 class="p-my-1">Column Sets</h5>
        </div>

        <p-listbox [(ngModel)]="activeColumnSetName" [options]="columnSetGroups" [group]="true" optionValue="name"
          optionGroupLabel="name" optionGroupChildren="value" emptyMessage="No custom Column Sets found"
          (onChange)="onSelectColumnSet()">
          <ng-template pTemplate="group" let-group>
            <div class="p-d-flex p-fluid p-ai-center p-jc-between">
              <span>
                <i [class]="group.icon + ' p-mr-3'"></i>

                {{ group.name }}
              </span>

              <p-button *ngIf="
                  !showAddColumnSet && !['globalColumnSets'].includes(group.key)
                " #showAddColumnSet_button ariaLabel="Add Column Set" icon="pi pi-plus" class="p-p-0"
                styleClass="p-button-rounded p-button-text p-button-sm"
                (click)="showAddColumnSet = !showAddColumnSet"></p-button>
            </div>

            <div *ngIf="
                showAddColumnSet && !['globalColumnSets'].includes(group.key)
              " class="p-mt-2 p-inputgroup" pTooltip="Name and Value of new set must be unique and cannot be blank."
              tooltipPosition="right" showDelay="2000">
              <input #columnSetName_input type="text" pInputText ariaLabel="Name of Column Set"
                placeholder="Name of Column Set" (keyup.enter)="
                  checkForColumnSet(); onAddColumnSet(columnSetName_input.value)
                " />
              <span class="p-buttonset">
                <p-button icon="pi pi-times" styleClass="p-button-outlined"
                  (click)="showAddColumnSet = !showAddColumnSet">
                </p-button>
                <p-button icon="pi pi-check" (click)="
                    checkForColumnSet();
                    onAddColumnSet(columnSetName_input.value)
                  "></p-button>
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="item" let-set let-index="index">
            <div #columnSetItem style="width: 100%" pDraggable="items" pDroppable="items" dragHandle=".drag-handle"
              (onDragStart)="onDragStart(index)" (onDrop)="onDrop('Column Sets Order', index, set.isGlobalSet)">
              <!-- Global Set Name -->
              <span class="p-d-flex p-jc-between p-ai-center" *ngIf="set.isGlobalSet">
                {{ set.name }}
              </span>
              <!-- Custom Global Sets -->
              <div class="p-d-flex p-jc-between p-ai-center" *ngIf="!set.isGlobalSet">
                <i class="pi pi-bars drag-handle" style="color: #A855F7;"></i>
                <span *ngIf="!set.isSelected">
                  {{ set.name }}
                </span>

                <!-- Update Column Set Name-->
                <input *ngIf="set.isSelected" type="text" pInputText ariaLabel="Name of Column Set"
                  placeholder="Name of Column Set" (keyup.enter)="checkForColumnSet(); renameColumnSet(set.name)"
                  [(ngModel)]="tempColumnSetName" />

                <span class="p-buttonset" *ngIf="set.isSelected">
                  <!-- Cancel Edit -->
                  <p-button icon="pi pi-times" styleClass="p-button-outlined" (click)="
                      set.isSelected = !set.isSelected; tempColumnSetName = ''
                    ">
                  </p-button>
                  <!-- Confirm Edit -->
                  <p-button icon="pi pi-check" (click)="checkForColumnSet(); renameColumnSet(set.name)"></p-button>
                </span>

                <!-- Edit Column Set Name -->
                <span class="p-buttonset" *ngIf="!set.isSelected">
                  <p-button *ngIf="!set.isSelected" ariaLabel="Edit Column Set" icon="pi pi-pencil" class="p-p-0"
                    styleClass="p-button-rounded p-button-text p-button-sm p-button-info"
                    (click)="set.isSelected = true; onEditSetName(set.name)"></p-button>
                  <!-- Remove Column Set -->
                  <p-button *ngIf="!set.isSelected" ariaLabel="Remove Column Set" icon="pi pi-trash" class="p-p-0"
                    styleClass="p-button-rounded p-button-text p-button-sm p-button-danger" (click)="
                      removeColumnSet_overlay.toggle($event, columnSetItem)
                    "></p-button>
                </span>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>

      <!-- Column Selection -->
      <div class="p-my-3 p-fluid">
        <div class="p-d-flex p-jc-center">
          <h5 class="p-my-1">Column Selection</h5>
        </div>

        <p-listbox [(ngModel)]="tableState.columnSelection" id="colSelect" [options]="columnsList" optionLabel="name"
          optionValue="key" [multiple]="true" [checkbox]="true" [filter]="true"
          [listStyle]="{ width: '100%', height: '100%' }" (onChange)="onSelectColumn()">
          <ng-template pTemplate="item" let-field>
            <div style="width: 100%">
              <span class="p-d-flex p-fluid p-jc-between">
                <span class="p-text-bold">
                  {{ field.name }}
                </span>
                <span class="p-text-right">{{ field.key }}</span>
              </span>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    </p-sidebar>

    <!-- COLUMNS 'Confirm Remove Set' Overlay -->
    <p-overlayPanel #removeColumnSet_overlay [dismissable]="true">
      <ng-template pTemplate="content">
        <div class="p-mb-2 p-d-flex p-ai-center">
          <i class="pi pi-exclamation-triangle p-mr-2" style="color: 'var(--pink-500)'; font-size: 1.5em"></i>
          <h3 class="p-my-0">Confirm</h3>
        </div>

        <div class="p-p-2">
          Are you sure you want to delete the set,
          <strong>{{ activeColumnSetName }}</strong>?
        </div>

        <div class="p-mt-3 p-d-flex p-jc-end">
          <p-button label="Nope" class="p-mx-2" styleClass="p-button-outlined"
            (click)="removeColumnSet_overlay.hide()"></p-button>
          <p-button label="Yup" icon="pi pi-check" class="p-mx-2" (click)="
              onRemoveColumnSet(activeColumnSetName);
              removeColumnSet_overlay.hide()
            "></p-button>
        </div>
      </ng-template>
    </p-overlayPanel>

    <!-- FILTERS Sidebar -->
    <app-filters-sidebar *ngIf="!isLoading" [(visible)]="showFilters" [userFirstName]="user.firstName" [table]="table"
      [fields]="filterFields" [options]="options" [customFilters]="tableConfig.customFilterToRegister"
      [(filterSets)]="userSettings.filterSets" (saveFilterSets)="saveUserSettings(userSettings)"
      [(filterValues)]="tableState.filters" [(checkedState)]="tableState.filterSelection"
      [tableConfiguration]="tableConfig" [(columnSelection)]="tableState.columnSelection"
      (saveFilterState)="saveTableState()" (onApply)="this.onApplyFilter($event)" (onHide)="showFilters = false"
      (applyFilterAction)="onSelectColumn($event, true)"></app-filters-sidebar>

    <!-- ACTIONS: Dialog -->
    <p-dialog header="Actions" [(visible)]="showActions" [modal]="true" [dismissableMask]="true">
      <p-messages [style]="{ width: '100%', position: 'absolute', top: 0, left: 0 }">
      </p-messages>

      <!-- Export for general tables -->
      <p-panel header="Export Options (CSV)" *ngIf="!exportSets || exportSets.length <= 0">
        <button type="button" pButton pRipple icon="pi pi-download" class="p-mx-2" pTooltip="{{
            showExportOptions ? getPropArray(columns, 'name').join('<br>') : ''
          }}" tooltipPosition="bottom" label="Export All Columns" (click)="exportCSV(columns)"></button>

        <button type="button" pButton pRipple icon="pi pi-download" class="p-mx-2" pTooltip="{{
            showExportOptions
              ? getPropArray(selectedColumns, 'name').join('<br>')
              : ''
          }}" tooltipPosition="bottom" label="Export Custom Set" (click)="exportCSV(selectedColumns)"></button>
      </p-panel>

      <!-- Actions: 'Export Options' panel -->
      <p-panel header="Export Options (CSV)" *ngIf="exportSets && exportSets.length > 0">
        <button *ngFor="let set of exportSets" type="button" pButton pRipple icon="pi pi-download" class="p-mx-2"
          [pTooltip]="
            showActions ? getPropArray(set.value, 'name').join('<br>') : ''
          " tooltipPosition="bottom" showDelay="1500" [label]="'Export ' + set.name + ' Set'"
          (click)="exportCSV(set.value)"></button>

        <button type="button" pButton pRipple icon="pi pi-download" class="p-mx-2" pTooltip="{{
            showActions
              ? getPropArray(selectedColumns, 'name').join('<br>')
              : ''
          }}" tooltipPosition="bottom" showDelay="1500" label="Export Selected Set"
          (click)="exportCSV(selectedColumns)"></button>
      </p-panel>

      <!-- ACTIONS: 'Edit Options' panel -->
      <p-panel *ngIf="selectedRows.length > 0" header="Editing Options" class="p-mt-2">
        <button *ngIf="selectedRows.length > 0" type="button" pButton pRipple icon="pi pi-pencil" class="p-mx-2"
          pTooltip="{{ showActions ? selectedRows.length + ' Rows' : '' }}" tooltipPosition="bottom" showDelay="1500"
          label="Bulk Edit Selected {{ tableName }}" (click)="onStartBulkEdit()"></button>

        <button *ngIf="bulkEdit.conflict && bulkEdit.conflict.rows.length > 0" type="button" pButton pRipple
          icon="pi pi-eye" class="p-button-warning p-mx-2" pTooltip="{{ bulkEdit.conflict.rows.length + ' Rows' }}"
          tooltipPosition="bottom" label="Conflicts" (click)="onShowConflicts()"></button>
        <button *ngIf="selectedRows.length > 0" type="button" pButton pRipple icon="pi pi-search" class="p-mx-2"
          pTooltip="" tooltipPosition="bottom" label="Find And Replace" (click)="onSelectFindAndReplace()"></button>
        <button *ngIf="selectedRows.length > 0 && !['items-sourcing', 'challenges-sourcing'].includes(this.tableType)"
          type="button" pButton pRipple icon="pi pi-plus" class="p-mx-2" pTooltip="" tooltipPosition="bottom"
          label="Add Selected Rows to Collection"
          (click)="onCustomAction({ name: 'Add Selected Items' }, null, false, true)"></button>
        <button *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType) && selectedRows.length > 0"
          type="button" pButton pRipple icon="pi pi-trash" class="p-mx-2 p-button-danger" tooltipPosition="bottom"
          label="Delete ({{selectedRows.length}}) Selected Records" (click)="deleteMultipleConfirm(selectedRows)">
        </button>
      </p-panel>

      <!-- Upload Sourcing Items Action -->
      <p-panel *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)"
        [header]="this.tableType == 'items-sourcing' ? 'Upload Sourcing Items' : 'Upload Sourcing Challenges'">
        <button type="button" pButton pRipple icon="pi pi-upload" class="p-mx-2 p-button-info" tooltipPosition="bottom"
          [label]="
            this.tableType == 'items-sourcing' ?
              (!selectedRows || !selectedRows.length > 0 )? 'Upload All Sourcing Items' : 'Upload Selected Sourcing Items':
              (!selectedRows || !selectedRows.length > 0 )? 'Upload All Sourcing Challenges' : 'Upload Selected Sourcing Challenges'
            "
          (click)="(!selectedRows || !selectedRows.length > 0 )? onCustomAction($event, null ,true) : displayConfirmItemSourcingUploadModal = true"></button>
      </p-panel>

      <!-- Validate Sourcing Items Action -->
      <p-panel *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)"
        [header]="this.tableType == 'items-sourcing' ? 'Validate Sourcing Items' : 'Validate Sourcing Challenges'">
        <button type="button" pButton pRipple icon="pi pi-check-circle" class="p-mx-2" tooltipPosition="bottom" [label]="
            this.tableType == 'items-sourcing' ?
              (!selectedRows || !selectedRows.length > 0 )? 'Validate All Sourcing Items' : 'Validate Selected Sourcing Items' :
              (!selectedRows || !selectedRows.length > 0 )? 'Validate All Sourcing Challenges' : 'Validate Selected Sourcing Challenges'
            " (click)="(!selectedRows || !selectedRows.length > 0 )? onCustomAction({ name: 'Validate Selected Sourcing Items' }, null) :
            onCustomAction({ name: 'Validate Selected Sourcing Items' }, selectedRows)"></button>
      </p-panel>

      <!-- Action: Validate and Generate Paths -->
      <p-panel *ngIf="this.tableType == 'items-sourcing'" header="Validate Sourcing Items for Filename">
        <button type="button" pButton pRipple icon="pi pi-check-square" class="p-mx-2" tooltipPosition="bottom"
          label="{{(!selectedRows || !selectedRows.length > 0 )? 'Validate All Sourcing Items for Filename' : 'Validate Selected Sourcing Items for Filename' }}"
          (click)="(!selectedRows || !selectedRows.length > 0 )? onCustomAction({ name: 'Validate Sourcing Items for Filename' }) :
          showFilenameValidationPreviewTable = true"></button>
      </p-panel>

      <!-- Action: Validate and Generate Filename -->
      <p-panel *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)"
        header="Validate and Generate Filename">
        <ng-container *ngIf="this.tableType == 'items-sourcing'">
          <button type="button" pButton pRipple icon="pi pi-file" class="p-mx-2" tooltipPosition="bottom" label="{{(!selectedRows || !selectedRows.length > 0 ) ? 
          'Validate and Generate Filename for all sourcing items' :
          'Validate and Generate Filename for Selected Sourcing Items' }}" (click)="(!selectedRows || !selectedRows.length > 0 ) ? 
           onCustomAction({ name: 'Validate and Generate Filename for sourcing items' }) :
          showItFilenameGenValidationModal = true"></button>
        </ng-container>
        <ng-container *ngIf="this.tableType == 'challenges-sourcing'">
          <button type="button" pButton pRipple icon="pi pi-file" class="p-mx-2" tooltipPosition="bottom" label="{{(!selectedRows || !selectedRows.length > 0 ) ? 
          'Validate and Generate Filename for all sourcing challenges' :
          'Validate and Generate Filename for Selected Sourcing Challenges' }}" (click)="(!selectedRows || !selectedRows.length > 0 ) ? 
           onCustomAction({ name: 'validateAndGenerateFilenameForChallenges', isAll: true }) :
          showChFilenameGenValidationModal = true"></button>
        </ng-container>
      </p-panel>

      <!-- Action: Validate and Generate Paths -->
      <p-panel *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)"
        header="Validate and Generate Paths">
        <ng-container *ngIf="this.tableType == 'items-sourcing'">
          <button type="button" pButton pRipple icon="pi pi-folder" class="p-mx-2" tooltipPosition="bottom" label="{{(!selectedRows || !selectedRows.length > 0 ) ? 'Validate and Generate Paths for all Sourcing Items' : 
            'Validate and Generate Paths for Selected Sourcing Items' }}" (click)="(!selectedRows || !selectedRows.length > 0 ) ? onCustomAction({ name: 'Validate and Create Paths' }) :
            showPathGenerationPreviewTable = true"></button>
        </ng-container>
        <ng-container *ngIf="this.tableType == 'challenges-sourcing'">
          <button type="button" pButton pRipple icon="pi pi-folder" class="p-mx-2" tooltipPosition="bottom" label="{{(!selectedRows || !selectedRows.length > 0 ) ? 'Validate and Generate Paths for all Sourcing Challenges' :
            'Validate and Generate Paths for Selected Sourcing Challenges' }}" (click)="(!selectedRows || !selectedRows.length > 0 ) ? onCustomAction({ name: 'validateAndGeneratePathsForChallenges', isAll: true }) :
            showChPathGenerationModal = true"></button>
        </ng-container>
      </p-panel>

      <!-- Move Sourcing Items Action -->
      <p-panel *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType)"
        header="Move Sourcing Records">
        <button type="button" pButton pRipple icon="pi pi-clone" class="p-mx-2" tooltipPosition="bottom" [label]="
          this.tableType == 'items-sourcing' ?
          (!selectedRows || !selectedRows.length > 0 ) ? 'Move All Sourcing Items' : 'Move Selected Sourcing Items' :
            (!selectedRows || !selectedRows.length > 0 )? 'Move All Sourcing Challenges' : 'Move Selected Sourcing Challenges'
          "
          (click)="(!selectedRows || !selectedRows.length > 0 ) ? displayMove(true,true) : displayMove(true,false)"></button>
      </p-panel>

      <!-- ACTIONS: 'Render Options' panel -->
      <bulk-render-assets *ngIf="tableConfig.isBuildable && selectedRows.length > 0" [selectedRecords]="selectedRows"
        [entityType]="tableType"></bulk-render-assets>

      <!-- ACTIONS: 'Build Options' panel -->
      <p-panel header="Build Options" *ngIf="tableConfig.isBuildable && selectedRows.length > 0">
        <div class="p-grid p-p-2">
          <ng-container>
            <!-- Build and Render -->
            <div class="p-col">
              <h5 class="p-d-inline p-mr-2">Render Asset? <i
                  pTooltip="Renders the asset once it has been successfully built, and a slack notification is sent to preview the asset."
                  class="pi pi-info-circle" style="font-size: 12px"></i></h5>
              <p-inputSwitch class="p-d-inline p-mt-2 p-mr-3" [(ngModel)]="buildAndRender"></p-inputSwitch>
            </div>
          </ng-container>
          <ng-container id="build-items">
            <div class="p-col">
              <button *ngIf="selectedRows.length > 0" [disabled]="disableBuildButton" type="button" pButton pRipple
                icon="pi pi-box" class="p-mx-2" pTooltip="{{ showActions ? selectedRows.length + ' Rows' : '' }}"
                tooltipPosition="bottom" label="Build Selected {{ tableName }} Assets"
                (click)="onStartBulkBuild(selectedRows, true)"></button>
              <button *ngIf="bulkBuild.failedPayloads.length > 0" pButton pRipple type="button"
                icon="pi pi-exclamation-circle" label="Failed Payloads" class="p-button-warning p-mx-2"
                (click)="onShowFailedPayloads()"></button>
            </div>
          </ng-container>
          <ng-container id="build-thumbs">
            <div class="p-col">
              <button *ngIf="selectedRows.length > 0 && tableType != 'miscellaneous-build'"
                [disabled]="disableBuildButton" type="button" pButton pRipple icon="pi pi-box" class="p-mx-2"
                pTooltip="{{ showActions ? selectedRows.length + ' Rows' : '' }}" tooltipPosition="bottom" label="Build Selected {{
                  tableConfig.assetType == assetTypeValue.Item || tableConfig.assetType == assetTypeValue.NurturePin
                    ? 'Thumbs'
                    : 'Images'
                }}" (click)="
                  onStartBulkBuild(
                    selectedRows,
                    false
                  )
                "></button>
            </div>
          </ng-container>
        </div>
      </p-panel>

      <!-- ACTIONS: 'Bulk Promote' panel -->
      <p-panel header="Promotion Options"
        *ngIf="tableType == 'items' || tableType == 'challenges' || tableType == 'miscellaneous-build' || tableType == 'loading-screens' ">
        <div class="p-grid p-p-2">
          <div class="p-col-6">
            <ng-container id="build-items">
              <button *ngIf="(selectedRows.length > 0) && (tableType == 'items' || tableType == 'challenges')"
                [disabled]="disablePromoteButton" type="button" pButton pRipple icon="pi pi-cloud-upload"
                class="p-mx-2 p-my-1" pTooltip="{{ showActions ? selectedRows.length + ' Rows' : '' }}"
                tooltipPosition="bottom" label="Promote Selected Assets"
                (click)="startBulkPromotion(selectedRows, true)"></button>
            </ng-container>
          </div>
          <div class="p-col-6">
            <ng-container id="build-thumbs">
              <div class="p-col-6">
                <button *ngIf="selectedRows.length > 0" [disabled]="disablePromoteButton" type="button" pButton pRipple
                  icon="pi pi-cloud-upload" class="p-mx-2 p-my-1"
                  pTooltip="{{ showActions ? selectedRows.length + ' Rows' : '' }}" tooltipPosition="bottom"
                  label="Promote Selected Images" (click)="startBulkPromotion(selectedRows, false)"></button>
                <br>
              </div>
            </ng-container>
          </div>
          <div class="p-col-6">
            <button *ngIf="bulkPromote.failedPayloads && bulkPromote.failedPayloads.length > 0" pButton pRipple
              type="button" icon="pi pi-exclamation-circle" label="Failed Promotions"
              class="p-button-warning p-mx-2 p-my-1" (click)="onShowFailedPromotions()"></button>
          </div>
        </div>

      </p-panel>

      <div *ngIf="selectedRows.length < 1" class="p-pt-3 p-px-2">
        Select rows for additional options.
      </div>

      <br />
      <!-- Builds sent to queue progress... -->
      <p-progressBar *ngIf="showBuildProgress" [value]="bulkBuildProgress"></p-progressBar>
    </p-dialog>

    <!-- BULK BUILD Dialog -->
    <p-dialog header="Send {{
        bulkBuild.rows && bulkBuild.rows.length == rows.length ? 'ALL ' : ''
      }}
      {{ bulkBuild.rows.length }} {{ tableType }} to Build Queue?" [(visible)]="showBulkBuild" [modal]="true"
      [dismissableMask]="true">
      <p-table [value]="bulkBuild.rows" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr class="p-text-uppercase">
            <th *ngFor="let header of ['id', 'filename', 'name']">
              <span>{{ header }}</span>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
          <tr>
            <td *ngFor="
                let value of [
                  item.id,
                  parseFilenameFromPath(item['prefab']),
                  item.name
                ]
              ">
              <span>{{ value }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="No" icon="pi pi-times" class="p-button-outlined"
          (click)="showBulkBuild = false; showBuildProgress = false"></button>
        <button pButton pRipple type="button" label="Yes" icon="pi pi-check"
          (click)="buildEntities(bulkBuild.rows)"></button>
        <button pButton pRipple type="button" label="Build with new hash" icon="pi pi-check"
          (click)="buildEntities(bulkBuild.rows, true)"></button>
      </ng-template>
    </p-dialog>

    <!-- BULK BUILD Failed Payloads Dialog -->
    <p-dialog *ngIf="bulkBuild.failedPayloads && bulkBuild.failedPayloads.length > 0" header="Failed Payloads"
      [(visible)]="showBulkBuildFailed" [modal]="true" [dismissableMask]="true">
      <span class="p-grid nested-grid p-ai-center vertical-container p-m-1">
        <span class="p-col-fixed" [ngStyle]="{ width: '3em' }">
          USER DATA
        </span>

        <p-divider layout="vertical"></p-divider>

        <span class="p-col">
          <div *ngFor="let label of ['Name', 'Email', 'ID']" class="p-text-bold">
            {{ label }}:
          </div>
        </span>

        <span class="p-col">
          <div *ngFor="
              let value of [
                bulkBuild.failedPayloads[0].user.name,
                bulkBuild.failedPayloads[0].user.email,
                bulkBuild.failedPayloads[0].user.id
              ]
            " [ngStyle]="value ? '' : { color: 'red', fontWeight: 'bold' }">
            {{ value ? value : "empty" }}
          </div>
        </span>
      </span>

      <p-table [value]="bulkBuild.failedPayloads" styleClass="p-datatable-sm">
        <ng-template pTemplate="caption"></ng-template>
        <ng-template pTemplate="header">
          <tr class="p-text-uppercase">
            <th *ngFor="let header of ['id', 'build type', 'path', 'recursive']">
              <span>{{ header }}</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-payload>
          <tr>
            <td *ngFor="
                let value of [
                  payload.entityId,
                  payload.buildType,
                  payload.path,
                  payload.recursive
                ]
              " [ngStyle]="value ? '' : { color: 'red', fontWeight: 'bold' }">
              {{ value ? value : "empty" }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-dialog>

    <!-- MULTI SELECT EDIT Dialog -->
    <p-dialog [(visible)]="showEditOptions" [modal]="true" [dismissableMask]="true" [style]="
        ['reviewAndSubmitReplaced'].includes(bulkMultiSelect.stage)
          ? { width: 'auto' }
          : { width: 'auto' }
      ">
      <!-- Bulk Edit Header -->
      <ng-template pTemplate="header" [ngSwitch]="bulkMultiSelect.stage">
        <h3 *ngSwitchCase="'selectEditMode'" class="p-dialog-title">
          Select Edit Mode
        </h3>
        <h3 *ngSwitchCase="'selectInputEditMode'" class="p-dialog-title">
          Select Edit Mode
        </h3>

        <h3 *ngSwitchCase="'selectField'" class="p-dialog-title">
          Select Field to Edit
        </h3>

        <h3 *ngSwitchCase="'editFieldReplace'" class="p-dialog-title">
          Edit Field: {{ bulkMultiSelect.field.name }}
        </h3>
        <div *ngSwitchCase="'reviewAndSubmitReplaced'">
          <h3>Review and Submit</h3>
          <p-message severity="info" text="Rows that do not contain the value to be replaced will be skipped"
            *ngIf="bulkMultiSelectAction.mode == 'replace'"></p-message>
          <p-message severity="info" text="Values will be added to the existing ones"
            *ngIf="bulkMultiSelectAction.mode == 'add'"></p-message>
          <p-message severity="info" text="Rows that do not contain the value to be removed will be skipped"
            *ngIf="bulkMultiSelectAction.mode == 'remove'"></p-message>
        </div>
      </ng-template>

      <!-- Bulk Edit stage: Select Edit Mode-->
      <ng-container *ngIf="bulkMultiSelect.stage =='selectEditMode'">
        <p-panel header="Edit Options">
          <button type="button" pButton pRipple icon="pi pi-sort-alt" class="p-mx-2" [pTooltip]=""
            tooltipPosition="bottom" showDelay="1500" [label]="'Replace'"
            (click)="selectEditOption('replace')"></button>

          <button type="button" pButton pRipple icon="pi pi-plus-circle" class="p-mx-2" pTooltip=""
            tooltipPosition="bottom" showDelay="1500" label="Add" (click)="selectEditOption('add')"></button>

          <button type="button" pButton pRipple icon="pi pi-minus-circle" class="p-mx-2" pTooltip=""
            tooltipPosition="bottom" showDelay="1500" label="Remove" (click)="selectEditOption('remove')"></button>
        </p-panel>
      </ng-container>

      <ng-container *ngIf="bulkMultiSelect.stage =='selectInputEditMode'">
        <p-panel header="Edit Options">
          <button type="button" pButton pRipple icon="pi pi-sort-alt" class="p-mx-2" [pTooltip]=""
            tooltipPosition="bottom" showDelay="1500" [label]="'Replace'"
            (click)="selectInputEditOption('replace')"></button>

          <button type="button" pButton pRipple icon="pi pi-plus-circle" class="p-mx-2" pTooltip=""
            tooltipPosition="bottom" showDelay="1500" label="Add" (click)="selectInputEditOption('append')"></button>
        </p-panel>
      </ng-container>

      <!-- Bulk Edit stage: Select Field -->
      <ng-container *ngIf="bulkMultiSelect.stage == 'selectField'">
        <p-listbox [(ngModel)]="bulkMultiSelect.field" [filter]="true" filterPlaceHolder="Search by Field Name"
          [options]="multiSelectFields" optionLabel="name" [listStyle]="{ 'max-height': '50vh', 'min-width': '400px' }"
          (onClick)="onFieldMultiselectEdit()">
          <ng-template pTemplate="item" let-field>
            <div style="width: 100%">
              <span class="p-grid p-fluid p-jc-between">
                <span class="p-col p-text-bold">
                  {{ field.name }}
                </span>
                <span class="p-col p-text-right">{{ field.key }}</span>
              </span>
            </div>
          </ng-template>
        </p-listbox>
      </ng-container>

      <!-- Bulk Edit stage: Edit Field -->
      <!-- Form Fields -->
      <ng-container *ngIf="bulkMultiSelect.stage == 'editFieldReplace'">
        <!-- Form Fields -->
        <ng-container [ngSwitch]="bulkMultiSelect.field.controlType">
          <div style="display: flex; flex-direction: row">
            <div class="p-mr-4 p-ml-4">
              <h4>Select value to {{ bulkMultiSelectAction.mode }}</h4>
              <p-listbox *ngSwitchCase="'multiSelect_ref'" [aria-label]="bulkMultiSelect.field.name"
                [id]="bulkMultiSelect.field.key" [filter]="true"
                [filterPlaceHolder]="'Search ' + bulkMultiSelect.field.name"
                [options]="options[bulkMultiSelect.field.key]" optionLabel="name" [multiple]="multipleOptions"
                [checkbox]="true" class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }"
                (onChange)="onSelectorMultiselect($event)"></p-listbox>
              <p-listbox *ngSwitchCase="'multiSelect'" [aria-label]="bulkMultiSelect.field.name"
                [id]="bulkMultiSelect.field.key" [filter]="true"
                [filterPlaceHolder]="'Search ' + bulkMultiSelect.field.name"
                [options]="options[bulkMultiSelect.field.key]" [multiple]="true" [checkbox]="true"
                class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }"
                (onChange)="onSelectorMultiselect($event)"></p-listbox>
            </div>
            <div *ngIf="bulkMultiSelectAction.mode == 'replace'">
              <h4>Select value(s) to {{ bulkMultiSelectAction.mode }} with</h4>
              <p-listbox *ngSwitchCase="'multiSelect_ref'" [aria-label]="bulkMultiSelect.field.name"
                [id]="bulkMultiSelect.field.key" [(ngModel)]="bulkMultiSelectAction.replaceValues" [filter]="true"
                [filterPlaceHolder]="'Search ' + bulkMultiSelect.field.name"
                [options]="options[bulkMultiSelect.field.key]" optionLabel="name" [multiple]="true" [checkbox]="true"
                class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }" [disabled]="
                  !bulkMultiSelectAction.valueToReplace ||
                  bulkMultiSelectAction.valueToReplace.length <= 0
                "></p-listbox>
              <p-listbox *ngSwitchCase="'multiSelect'" [aria-label]="bulkMultiSelect.field.name"
                [id]="bulkMultiSelect.field.key" [(ngModel)]="bulkMultiSelectAction.replaceValues" [filter]="true"
                [filterPlaceHolder]="'Search ' + bulkMultiSelect.field.name"
                [options]="options[bulkMultiSelect.field.key]" [multiple]="true" [checkbox]="true"
                class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }" [disabled]="
                  !bulkMultiSelectAction.valueToReplace ||
                  bulkMultiSelectAction.valueToReplace.length <= 0
                "></p-listbox>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- Bulk Edit stage: 'Review and Submit' -->
      <ng-container *ngIf="bulkMultiSelect.stage == 'reviewAndSubmitReplaced'">
        <p-tabView>
          <p-tabPanel>
            <ng-template pTemplate="header">
              ALL SELECTED ROWS - {{ bulkMultiSelect.rows.length }}
            </ng-template>
            <p-table [value]="bulkMultiSelect.rows" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr class="p-text-uppercase">
                  <th *ngFor="let header of bulkEditReviewHeaders">
                    <span>{{ header }}</span>
                  </th>

                  <th [ngStyle]="{ color: 'var(--gray-400)' }">
                    {{ bulkMultiSelect.field.name }}
                  </th>

                  <th [ngStyle]="{ color: 'var(--primary-color)' }">
                    {{ bulkMultiSelect.field.name }}
                    <p-tag styleClass="p-mx-2" value="NEW" [rounded]="true"></p-tag>
                  </th>
                  <th [ngStyle]="{ color: 'var(--primary-color)' }">
                    Skipped?
                  </th>
                </tr>
              </ng-template>

              <ng-template *ngIf="bulkMultiSelectAction.mode == 'replace'" pTemplate="body" let-item>
                <tr *ngIf="tableType == 'items' else elseItems">
                  <td *ngFor="
                      let value of [
                        item.id,
                        parseFilenameFromPath(item['prefab']),
                        item.name
                      ]
                    ">
                    <span>{{ value }}</span>
                  </td>
                  <td *ngFor="
                      let value of [
                        bulkMultiSelectAction.valueToReplace[0].name,
                        parseValueForEditReview(
                          bulkMultiSelectAction.replaceValues,
                          bulkMultiSelect.field
                        )
                      ]
                    " [ngStyle]="
                      value == bulkMultiSelectAction.valueToReplace[0].name
                        ? { color: 'var(--gray-400)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    <span>{{ value }}</span>
                  </td>
                  <td>
                    <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                        !checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        )
                      ">Yes</span>
                    <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                        checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        )
                      ">No</span>
                  </td>
                </tr>
                <ng-template #elseItems>
                  <tr>
                    <td *ngFor="let value of [item.id,item.name]">
                      <span>{{ value }}</span>
                    </td>
                    <td *ngFor="
                        let value of [
                          bulkMultiSelectAction.valueToReplace[0].name,
                          parseValueForEditReview(
                            bulkMultiSelectAction.replaceValues,
                            bulkMultiSelect.field
                          )
                        ]
                      " [ngStyle]="
                        value == bulkMultiSelectAction.valueToReplace[0].name
                          ? { color: 'var(--gray-400)' }
                          : { color: 'var(--primary-color)' }
                      ">
                      <span>{{ value }}</span>
                    </td>
                    <td>
                      <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                          !checkSkippedMulti(
                            item[bulkMultiSelect.field.key],
                            bulkMultiSelectAction.valueToReplace[0].name
                          )
                        ">Yes</span>
                      <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                          checkSkippedMulti(
                            item[bulkMultiSelect.field.key],
                            bulkMultiSelectAction.valueToReplace[0].name
                          )
                        ">No</span>
                    </td>
                  </tr>
                </ng-template>
              </ng-template>

              <ng-template *ngIf="
                  bulkMultiSelectAction.mode == 'add' ||
                  bulkMultiSelectAction.mode == 'remove'
                " pTemplate="body" let-item>
                <tr *ngIf="tableType == 'items' else elseItems">
                  <td *ngFor="
                      let value of [
                        item.id,
                        parseFilenameFromPath(item['prefab']),
                        item.name
                      ]
                    ">
                    <span>{{ value }}</span>
                  </td>

                  <td *ngFor="
                      let value of [
                        parseValueForEditReview(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelect.field
                        ),
                        parseValueForEditReview(
                          bulkMultiSelectAction.valueToReplace,
                          bulkMultiSelect.field
                        )
                      ]
                    " [ngStyle]="
                      [value].includes(
                        parseValueForEditReview(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelect.field
                        )
                      )
                        ? { color: 'var(--gray-400)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    <span>{{ value }}</span>
                  </td>
                  <td>
                    <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                        checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        ) && bulkMultiSelectAction.mode == 'add'
                      ">Yes</span>
                    <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                        !checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        ) && bulkMultiSelectAction.mode == 'add'
                      ">No</span>
                    <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                        !checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        ) && bulkMultiSelectAction.mode == 'remove'
                      ">Yes</span>
                    <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                        checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        ) && bulkMultiSelectAction.mode == 'remove'
                      ">No</span>
                  </td>
                </tr>
                <ng-template #elseItems>
                  <tr>
                    <td *ngFor="let value of [item.id,item.name]">
                      <span>{{ value }}</span>
                    </td>

                    <td *ngFor="
                        let value of [
                          parseValueForEditReview(
                            item[bulkMultiSelect.field.key],
                            bulkMultiSelect.field
                          ),
                          parseValueForEditReview(
                            bulkMultiSelectAction.valueToReplace,
                            bulkMultiSelect.field
                          )
                        ]
                      " [ngStyle]="
                        [value].includes(
                          parseValueForEditReview(
                            item[bulkMultiSelect.field.key],
                            bulkMultiSelect.field
                          )
                        )
                          ? { color: 'var(--gray-400)' }
                          : { color: 'var(--primary-color)' }
                      ">
                      <span>{{ value }}</span>
                    </td>
                    <td>
                      <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                          checkSkippedMulti(
                            item[bulkMultiSelect.field.key],
                            bulkMultiSelectAction.valueToReplace[0].name
                          ) && bulkMultiSelectAction.mode == 'add'
                        ">Yes</span>
                      <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                          !checkSkippedMulti(
                            item[bulkMultiSelect.field.key],
                            bulkMultiSelectAction.valueToReplace[0].name
                          ) && bulkMultiSelectAction.mode == 'add'
                        ">No</span>
                      <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                          !checkSkippedMulti(
                            item[bulkMultiSelect.field.key],
                            bulkMultiSelectAction.valueToReplace[0].name
                          ) && bulkMultiSelectAction.mode == 'remove'
                        ">Yes</span>
                      <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                          checkSkippedMulti(
                            item[bulkMultiSelect.field.key],
                            bulkMultiSelectAction.valueToReplace[0].name
                          ) && bulkMultiSelectAction.mode == 'remove'
                        ">No</span>
                    </td>
                  </tr>
                </ng-template>

              </ng-template>
            </p-table>
          </p-tabPanel>
        </p-tabView>
      </ng-container>

      <!-- Bulk Edit dialog: Footer -->
      <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-center">
          <button pButton pRipple type="button" label="Back" icon="pi pi-arrow-left" class="p-button-outlined p-m-2"
            (click)="goBackMulti()"></button>

          <button *ngIf="
              bulkMultiSelect.stage == 'editFieldReplace' &&
              bulkMultiSelect.field.key
            " pButton pRipple type="button" label="Next" icon="pi pi-arrow-right" iconPos="right" class="p-m-2"
            [disabled]="
              (bulkMultiSelectAction.mode != 'replace' &&
                !bulkMultiSelectAction.valueToReplace) ||
              bulkMultiSelectAction.valueToReplace.length <= 0 ||
              (bulkMultiSelectAction.mode == 'replace' &&
                (!bulkMultiSelectAction.valueToReplace ||
                  bulkMultiSelectAction.valueToReplace.length <= 0 ||
                  !bulkMultiSelectAction.replaceValues ||
                  bulkMultiSelectAction.replaceValues.length <= 0))
            " (click)="goNextMulti()"></button>

          <button *ngIf="['reviewAndSubmitReplaced'].includes(bulkMultiSelect.stage)" pButton pRipple type="button"
            label="Submit" icon="pi pi-check" class="p-m-2"
            (click)="onBulkMultiSelectAction(bulkMultiSelectAction.mode)"
            [disabled]="disableBultiSelectedSubmitButton"></button>
        </div>
        <br />
        <!-- Bulk MultiSelect progress... -->
        <p-progressBar *ngIf="['reviewAndSubmitReplaced'].includes(bulkMultiSelect.stage)"
          [value]="bulkMultiSelectProgress"></p-progressBar>
      </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="showFindAndReplace" [modal]="true" [dismissableMask]="true" [style]="{ width: 'auto' }">
      <!-- Bulk Edit header -->
      <ng-template pTemplate="header" [ngSwitch]="bulkMultiSelect.stage">
        <h3 *ngSwitchCase="'selectFindAndReplaceFields'" class="p-dialog-title">
          Select Field to Edit
        </h3>

        <h3 *ngSwitchCase="'editFieldFindAndReplace'" class="p-dialog-title">
          Edit Field: {{ bulkMultiSelect.field.name }}
        </h3>
        <div *ngSwitchCase="'reviewAndSubmitFindAndReplace'">
          <h3>Review and Submit</h3>
          <p-message severity="info"
            text="Rows that do not contain the text to be replaced will be skipped"></p-message>
        </div>
      </ng-template>
      <!-- Bulk Edit stage: Select Field -->
      <ng-container *ngIf="bulkMultiSelect.stage == 'selectFindAndReplaceFields'">
        <p-listbox [(ngModel)]="bulkMultiSelect.field" [filter]="true" filterPlaceHolder="Search by Field Name"
          [options]="findAndReplaceFields" optionLabel="name"
          [listStyle]="{ 'max-height': '50vh', 'min-width': '400px' }" (onClick)="onFieldSelectFindandReplace()">
          <ng-template pTemplate="item" let-field>
            <div style="width: 100%">
              <span class="p-grid p-fluid p-jc-between">
                <span class="p-col p-text-bold">
                  {{ field.name }}
                </span>
                <span class="p-col p-text-right">{{ field.key }}</span>
              </span>
            </div>
          </ng-template>
        </p-listbox>
      </ng-container>

      <!-- Bulk Edit stage: Edit Field -->
      <ng-container *ngIf="bulkMultiSelect.stage == 'editFieldFindAndReplace'">
        <!-- Form Fields -->
        <ng-container [ngSwitch]="bulkMultiSelect.field.controlType">
          <div style="display: flex; flex-direction: column">
            <div class="p-mr-2 p-ml-2 p-col-12">
              <h4>Type the text to find and replace</h4>
              <input [aria-label]="bulkMultiSelect.field.name" [placeholder]=""
                [(ngModel)]="bulkMultiSelectAction.findValue" [id]="bulkMultiSelect.field.key" type="text" pInputText
                class="bulkEdit-field" />
            </div>
            <div class="p-mr-2 p-ml-2 p-col-12">
              <h4>Type the text to replace with</h4>
              <input [aria-label]="bulkMultiSelect.field.name" [placeholder]=""
                [(ngModel)]="bulkMultiSelectAction.findAndReplaceValue" [id]="bulkMultiSelect.field.key" type="text"
                pInputText class="bulkEdit-field" />
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- Bulk Edit stage: 'Review and Submit' -->
      <ng-container *ngIf="bulkMultiSelect.stage == 'reviewAndSubmitFindAndReplace'">
        <p-tabView>
          <p-tabPanel>
            <ng-template pTemplate="header">
              SELECTED ROWS - {{ bulkMultiSelect.rows.length }}
            </ng-template>
            <span>
              <h3 class="p-d-inline">Field:</h3>
              <p class="p-d-inline"> {{bulkMultiSelect.field.name}}</p>
            </span>
            <p-table [value]="bulkMultiSelect.rows" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr class="p-text-uppercase">
                  <th *ngFor="let header of ['id', 'filename', 'name']">
                    <span>{{ header }}</span>
                  </th>
                  <th>Replace</th>
                  <th>With</th>
                  <th [ngStyle]="{ color: 'var(--gray-400)' }">
                    Old Value
                  </th>

                  <th [ngStyle]="{ color: 'var(--primary-color)' }">
                    New Value
                  </th>
                  <th [ngStyle]="{ color: 'var(--primary-color)' }">
                    Skipped?
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-item>
                <tr>
                  <td *ngFor="
                      let value of [
                        item.id,
                        parseFilenameFromPath(item['prefab']),
                        item.name
                      ]
                    ">
                    <span>{{ value }}</span>
                  </td>
                  <td>
                    {{ bulkMultiSelectAction.findValue }}
                  </td>
                  <td>
                    {{ bulkMultiSelectAction.findAndReplaceValue }}
                  </td>
                  <td [ngStyle]="
                      value == item[bulkMultiSelectAction.selectedField]
                        ? { color: 'var(--gray-400)' }
                        : !item[bulkMultiSelectAction.selectedField]
                        ? { color: 'var(--red-100)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    {{ item[bulkMultiSelectAction.selectedField] ? item[bulkMultiSelectAction.selectedField] : 'Missing
                    Value' }}
                  </td>
                  <td [ngStyle]="
                      value == item[bulkMultiSelectAction.selectedField]
                        ? { color: 'var(--gray-400)' }
                        : !item[bulkMultiSelectAction.selectedField]
                        ? { color: 'var(--red-100)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    {{ item[bulkMultiSelectAction.selectedField] && bulkMultiSelectAction.findValue &&
                    bulkMultiSelectAction.findAndReplaceValue ?
                    ReplaceValue(item[bulkMultiSelectAction.selectedField], bulkMultiSelectAction.findValue,
                    bulkMultiSelectAction.findAndReplaceValue) : 'Text not found' }}
                  </td>
                  <td>
                    <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                        !item[bulkMultiSelectAction.selectedField] ||
                        !item[bulkMultiSelectAction.selectedField].includes(
                          bulkMultiSelectAction.findValue
                        )
                      ">Yes</span>
                    <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                        item[bulkMultiSelectAction.selectedField] &&
                        item[bulkMultiSelectAction.selectedField].includes(
                          bulkMultiSelectAction.findValue
                        )
                      ">No</span>
                  </td>
                </tr>
              </ng-template>
              <ng-template *ngIf="
                  bulkMultiSelectAction.mode == 'add' ||
                  bulkMultiSelectAction.mode == 'remove'
                " pTemplate="body" let-item>
                <tr>
                  <td *ngFor="
                      let value of [
                        item.id,
                        parseFilenameFromPath(item['prefab']),
                        item.name
                      ]
                    ">
                    <span>{{ value }}</span>
                  </td>

                  <td *ngFor="
                      let value of [
                        parseValueForEditReview(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelect.field
                        ),
                        parseValueForEditReview(
                          bulkMultiSelectAction.valueToReplace,
                          bulkMultiSelect.field
                        )
                      ]
                    " [ngStyle]="
                      [value].includes(
                        parseValueForEditReview(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelect.field
                        )
                      )
                        ? { color: 'var(--gray-400)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    <span>{{ value }}</span>
                  </td>
                  <td>
                    <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                        checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        ) && bulkMultiSelectAction.mode == 'add'
                      ">Yes</span>
                    <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                        !checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        ) && bulkMultiSelectAction.mode == 'add'
                      ">No</span>
                    <span [ngStyle]="{ color: 'var(--red-100)' }" *ngIf="
                        !checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        ) && bulkMultiSelectAction.mode == 'remove'
                      ">Yes</span>
                    <span [ngStyle]="{ color: 'var(--primary-color)' }" *ngIf="
                        checkSkippedMulti(
                          item[bulkMultiSelect.field.key],
                          bulkMultiSelectAction.valueToReplace[0].name
                        ) && bulkMultiSelectAction.mode == 'remove'
                      ">No</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>
        </p-tabView>
      </ng-container>

      <!-- Bulk Edit dialog: Footer -->
      <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-center">
          <button pButton pRipple type="button" label="Back" icon="pi pi-arrow-left" class="p-button-outlined p-m-2"
            (click)="goBackMulti()"></button>

          <button *ngIf="bulkMultiSelect.stage == 'editFieldFindAndReplace'" pButton pRipple type="button" label="Next"
            icon="pi pi-arrow-right" iconPos="right" class="p-m-2" [disabled]="
              bulkMultiSelectAction.findValue == null ||
              bulkMultiSelectAction.findAndReplaceValue == null
            " (click)="goNextMulti()"></button>
          <button *ngIf="bulkMultiSelect.stage == 'reviewAndSubmitFindAndReplace'" pButton pRipple type="button"
            label="Submit" icon="pi pi-check" class="p-m-2" (click)="onFindAndReplaceAction()"
            [disabled]="disableBultiSelectedSubmitButton"></button>
        </div>
        <br />
        <!-- Bulk MultiSelect progress... -->
        <p-progressBar *ngIf="['reviewAndSubmitReplaced'].includes(bulkMultiSelect.stage)"
          [value]="bulkMultiSelectProgress"></p-progressBar>
      </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="showBulkEdit" [modal]="true" [dismissableMask]="true" [style]="
        ['reviewAndSubmit'].includes(bulkEdit.stage)
          ? { width: 'auto' }
          : { width: 'auto', 'max-width': '600px' }
      ">
      <!-- Bulk Edit header -->
      <ng-template pTemplate="header" [ngSwitch]="bulkEdit.stage">
        <h3 *ngSwitchCase="'selectField'" class="p-dialog-title">
          Select Field to Edit
        </h3>

        <h3 *ngSwitchCase="'editField'" class="p-dialog-title">
          Edit Field: {{ bulkEdit.field.name }}
        </h3>

        <h3 *ngSwitchCase="'reviewAndSubmit'">Review and Submit</h3>
      </ng-template>

      <!-- Bulk Edit stage: Select Field -->
      <ng-container *ngIf="bulkEdit.stage == 'selectField'">
        <p-listbox [(ngModel)]="bulkEdit.field" [filter]="true" filterPlaceHolder="Search by Field Name"
          [options]="inputFields" optionLabel="name" [listStyle]="{ 'max-height': '50vh', 'min-width': '400px' }"
          (onClick)="onFieldSelectedForEdit($event)">
          <ng-template pTemplate="item" let-field>
            <div style="width: 100%">
              <span class="p-grid p-fluid p-jc-between">
                <span class="p-col p-text-bold">
                  {{ field.name }}
                </span>
                <span class="p-col p-text-right">{{ field.key }}</span>
              </span>
            </div>
          </ng-template>
        </p-listbox>
      </ng-container>

      <!-- Bulk Edit stage: Edit Field -->
      <ng-container *ngIf="bulkEdit.stage == 'editField'">
        <!-- Conflict Messages -->
        <div *ngIf="bulkEdit.conflict" class="p-grid p-ai-center vertical-container p-m-1 conflict-msg-container">
          <p-badge class="p-col-fixed" [ngStyle]="{ width: '1.5em' }" styleClass="p-badge-dot"
            [severity]="'danger'"></p-badge>
          <p class="p-col p-m-0" [ngStyle]="{
              color: 'var(--text-secondary-color)',
              fontSize: '0.90em'
            }">
            Values {{ bulkEdit.conflict.detail }} will conflict with existing
            '{{ bulkEdit.conflict.field.name }}' values in some selected rows.
          </p>
        </div>

        <!-- Form Fields -->
        <form *ngIf="bulkEdit.form" [formGroup]="bulkEdit.form">
          <ng-container [ngSwitch]="bulkEdit.field.controlType">
            <input *ngSwitchCase="'inputText'" [formControlName]="bulkEdit.field.key" [aria-label]="bulkEdit.field.name"
              [placeholder]="'Enter New ' + bulkEdit.field.name" [id]="bulkEdit.field.key" type="text" pInputText
              class="bulkEdit-field" />

            <textarea *ngSwitchCase="'inputTextarea'" [formControlName]="bulkEdit.field.key"
              [aria-label]="bulkEdit.field.name" [placeholder]="'Enter New ' + bulkEdit.field.name"
              [id]="bulkEdit.field.key" cols="40" rows="3" pInputTextarea class="bulkEdit-field"></textarea>

            <p-editor *ngSwitchCase="'richTextArea'" [formControlName]="bulkEdit.field.key" [id]="bulkEdit.field.key"
              [style]="{ height: '320px' }">
            </p-editor>

            <p-inputNumber *ngSwitchCase="'inputNumber'" [formControlName]="bulkEdit.field.key" [aria-label]="f.name"
              [placeholder]="f.name" [id]="bulkEdit.field.key" [min]="0" [showButtons]="true" class="bulkEdit-field">
            </p-inputNumber>

            <p-calendar *ngSwitchCase="'date'" [formControlName]="bulkEdit.field.key" [aria-label]="bulkEdit.field.name"
              [id]="bulkEdit.field.key" [inline]="true" [readonlyInput]="true" [defaultDate]="bulkEdit.today"
              [showTime]="true" [stepMinute]="1" [monthNavigator]="true" [yearNavigator]="true" yearRange="2020:2030"
              class="bulkEdit-field" [selectOtherMonths]="true">
            </p-calendar>

            <p-selectButton *ngSwitchCase="'toggle'" [formControlName]="bulkEdit.field.key"
              [aria-label]="bulkEdit.field.name" [id]="bulkEdit.field.key" [options]="[
                { label: bulkEdit.field.name, value: true },
                { label: 'Not ' + bulkEdit.field.name, value: false }
              ]" optionLabel="label" optionValue="value" class="bulkEdit-field"></p-selectButton>

            <p-listbox *ngSwitchCase="'dropdown'" [formControlName]="bulkEdit.field.key"
              [aria-label]="bulkEdit.field.name" [id]="bulkEdit.field.key" [filter]="true"
              [filterPlaceHolder]="'Search ' + bulkEdit.field.name" [options]="options[bulkEdit.field.key]"
              class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }" (onClick)="onFieldEdited()"></p-listbox>

            <p-listbox *ngSwitchCase="'dropdown_ref'" [formControlName]="bulkEdit.field.key"
              [aria-label]="bulkEdit.field.name" [id]="bulkEdit.field.key" [filter]="true"
              [filterPlaceHolder]="'Search ' + bulkEdit.field.name" [options]="options[bulkEdit.field.key]"
              optionLabel="name" class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }"
              (onClick)="onFieldEdited()">
              <ng-template pTemplate="item" let-option>
                {{ option.name }}
                <p-badge *ngIf="
                    bulkEdit.conflict && bulkEdit.conflict.options[option.id]
                  " class="p-mx-2" styleClass="p-badge-dot" severity="danger"></p-badge>
              </ng-template>
            </p-listbox>

            <p-listbox *ngSwitchCase="'multiSelect'" [formControlName]="bulkEdit.field.key"
              [aria-label]="bulkEdit.field.name" [id]="bulkEdit.field.key" [filter]="true"
              [filterPlaceHolder]="'Search ' + bulkEdit.field.name" [options]="options[bulkEdit.field.key]"
              [multiple]="true" [checkbox]="true" class="bulkEdit-field"
              [listStyle]="{ 'max-height': '50vh' }"></p-listbox>

            <p-listbox *ngSwitchCase="'multiSelect_ref'" [formControlName]="bulkEdit.field.key"
              [aria-label]="bulkEdit.field.name" [id]="bulkEdit.field.key" [filter]="true"
              [filterPlaceHolder]="'Search ' + bulkEdit.field.name" [options]="options[bulkEdit.field.key]"
              optionLabel="name" [multiple]="true" [checkbox]="true" class="bulkEdit-field"
              [listStyle]="{ 'max-height': '50vh' }"></p-listbox>

            <p-listbox *ngSwitchCase="'autoComplete_ref'" [formControlName]="bulkEdit.field.key"
              [aria-label]="bulkEdit.field.name" [id]="bulkEdit.field.key" [options]="suggestions[bulkEdit.field.key]"
              class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }" (onClick)="onFieldEdited()">
              <ng-template pTemplate="header">
                <span class="p-input-icon-right" [style]="{ width: '100%' }">
                  <i class="pi pi-search"></i>
                  <input type="text" pInputText placeholder="Search for {{ bulkEdit.field.name }}" (input)="
                      getSuggestionsForRef(
                        $event.target.value,
                        bulkEdit.field.key,
                        bulkEdit.field.apiController
                      )
                    " [style]="{ width: '100%' }" />
                </span>
              </ng-template>
              <ng-template pTemplate="item" let-item>
                <span style="width: 100%">
                  <span class="p-grid p-fluid p-jc-between">
                    <span class="p-col p-text-bold">
                      {{ item.name }}
                    </span>
                    <span class="p-col p-text-right">{{ item.id }}</span>
                  </span>
                </span>
              </ng-template>
            </p-listbox>

            <ng-container *ngSwitchCase="
                ['formArray', 'nestedGroup', 'lineItem'].includes(
                  bulkEdit.field.controlType
                )
                  ? bulkEdit.field.controlType
                  : ''
              ">
              <div [formArrayName]="bulkEdit.field.key" *ngFor="
                  let group of bulkEdit.form.get(bulkEdit.field.key)[
                    'controls'
                  ];
                  let i = index
                " class="p-my-4">
                <span [formGroupName]="i" *ngFor="let f of bulkEdit.group" [ngSwitch]="f.controlType">
                  <ng-container *ngIf="!f.hidden">
                    <p-dropdown *ngSwitchCase="'dropdown_ref'" [formControlName]="f.key" [aria-label]="f.name"
                      [placeholder]="f.name" [id]="f.key" [options]="options[f.key]" optionLabel="name"
                      class="p-d-inline p-mx-2" [style]="{ 'min-width': '12em' }"></p-dropdown>

                    <p-inputNumber *ngSwitchCase="'inputNumber'" [formControlName]="f.key" [aria-label]="f.name"
                      [placeholder]="f.name" [id]="f.key" class="p-d-inline p-mx-2" [min]="0" [showButtons]="true">
                    </p-inputNumber>
                  </ng-container>
                </span>

                <button *ngIf="i > 0" pButton pRipple type="button" icon="pi pi-minus"
                  class="p-button-outlined p-d-inline p-mx-2" (click)="removeGroup(i)"></button>
              </div>

              <div class="p-m-2">
                <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-outlined"
                  [disabled]="!isFormArrayValid()" (click)="addGroup()"></button>
              </div>
            </ng-container>
            <div>
              <p-button *ngIf="bulkEdit.stage == 'editField'" ariaLabel="Clear Values" label="Clear Values"
                styleClass="p-button-outlined p-button-danger p-button-sm p-mt-3"
                (click)="clearConfirmation = true"></p-button>
            </div>
          </ng-container>
        </form>
        <div class="p-d-flex p-jc-center">
          <p-button *ngIf="
              ['editField'].includes(bulkEdit.stage) &&
              bulkEdit.form.value[bulkEdit.field.key]
            " ariaLabel="Clear Field" label="Clear Field"
            styleClass="p-button-outlined p-button-danger p-button-sm p-mt-3"
            (click)="clearField(bulkEdit.form, bulkEdit.field.key)"></p-button>
        </div>
      </ng-container>

      <!-- Bulk Edit stage: 'Review and Submit' -->
      <ng-container *ngIf="['reviewAndSubmit'].includes(bulkEdit.stage)">
        <!-- Conflict Messages -->
        <div *ngIf="bulkEdit.conflict" class="p-grid p-ai-center vertical-container p-m-1 conflict-msg-container"
          [ngStyle]="{ color: '#F48FB1' }">
          <span class="p-col-fixed p-pb-0 p-mr-2" [ngStyle]="{ width: '1.5em' }">
            <i class="pi pi-exclamation-triangle" style="font-size: 1.5em"></i>
          </span>

          <span class="p-col p-m-0">
            <p class="p-m-0">
              Conflicts Detected. {{ bulkEdit.conflict.rows.length }} of
              {{ bulkEdit.rows.length }} selected rows have an existing
              {{ bulkEdit.conflict.field.name }} value that is incompatible with
              the new {{ bulkEdit.field.name }} value.<br />Please modify these
              rows or Submit without them.
            </p>
          </span>
        </div>

        <p-tabView>
          <p-tabPanel *ngIf="bulkEdit.conflict">
            <ng-template pTemplate="header">
              CONFLICTS ONLY - {{ bulkEdit.conflict.rows.length }}
            </ng-template>
            <p-table [value]="bulkEdit.conflict.rows" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr class="p-text-uppercase">
                  <th *ngFor="let header of ['id', 'filename', 'name']">
                    <span>{{ header }}</span>
                  </th>

                  <th [ngStyle]="{ color: 'var(--gray-400)' }">
                    {{ bulkEdit.field.name }}
                  </th>
                  <th [ngStyle]="{ color: 'var(--primary-color)' }">
                    {{ bulkEdit.field.name }}
                    <p-tag styleClass="p-mx-2" value="NEW" [rounded]="true"></p-tag>
                  </th>

                  <th *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
                    {{ bulkEdit.conflict.field.name }}
                    <p-tag styleClass="p-mx-2" value="CONFLICT" severity="danger" [rounded]="true"></p-tag>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-item>
                <tr>
                  <td *ngFor="
                      let value of [
                        item.id,
                        parseFilenameFromPath(item['prefab']),
                        item.name
                      ]
                    ">
                    <a style="color: white" routerLink="/{{ tableType }}/{{ item.id }}">{{ value }}</a>
                  </td>

                  <td *ngFor="
                      let value of [
                        parseValueForEditReview(
                          item[bulkEdit.field.key],
                          bulkEdit.field
                        ),
                        parseValueForEditReview(
                          bulkEdit.form.value[bulkEdit.field.key],
                          bulkEdit.field
                        )
                      ]
                    " [ngStyle]="
                      [value].includes(
                        parseValueForEditReview(
                          item[bulkEdit.field.key],
                          bulkEdit.field
                        )
                      )
                        ? { color: 'var(--gray-400)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    <span *ngIf="
                        !['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{ value }}
                    </span>

                    <span *ngIf="
                        ['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{ value | officeTime }}
                    </span>
                    <span *ngIf="value == ''">—</span>
                  </td>

                  <td *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
                    <span *ngIf="
                        !['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{
                      parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                      )
                      }}
                    </span>

                    <span *ngIf="
                        ['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{
                      parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                      ) | officeTime
                      }}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>

          <p-tabPanel>
            <ng-template pTemplate="header">
              ALL SELECTED ROWS - {{ bulkEdit.rows.length }}
            </ng-template>
            <p-table [value]="bulkEdit.rows" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr class="p-text-uppercase">
                  <th *ngFor="let header of ['id', 'filename', 'name']">
                    <span>{{ header }}</span>
                  </th>

                  <th [ngStyle]="{ color: 'var(--gray-400)' }">
                    {{ bulkEdit.field.name }}
                  </th>

                  <th [ngStyle]="{ color: 'var(--primary-color)' }">
                    {{ bulkEdit.field.name }}
                    <p-tag styleClass="p-mx-2" value="NEW" [rounded]="true"></p-tag>
                  </th>

                  <!-- header in conflict -->
                  <th *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
                    {{ bulkEdit.conflict.field.name }}
                    <p-tag styleClass="p-mx-2" value="CONFLICT" severity="danger" [rounded]="true"></p-tag>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-item>
                <tr>
                  <td *ngFor="
                      let value of [
                        item.id,
                        parseFilenameFromPath(item['prefab']),
                        item.name
                      ]
                    ">
                    <span>{{ value }}</span>
                  </td>

                  <td *ngFor="
                      let value of [
                        parseValueForEditReview(
                          item[bulkEdit.field.key],
                          bulkEdit.field
                        ),
                        parseValueForEditReview(
                          bulkEdit.form.value[bulkEdit.field.key],
                          bulkEdit.field
                        )
                      ]
                    " [ngStyle]="
                      [value].includes(
                        parseValueForEditReview(
                          item[bulkEdit.field.key],
                          bulkEdit.field
                        )
                      )
                        ? { color: 'var(--gray-400)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    <span *ngIf="
                        !['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{ value }}
                    </span>

                    <span *ngIf="
                        ['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{ value | officeTime }}
                    </span>

                    <span *ngIf="value == ''">—</span>
                  </td>

                  <!-- column in conflict -->
                  <td *ngIf="bulkEdit.conflict" [ngStyle]="{
                      color: bulkEdit.conflict.indicator(
                        bulkEdit.form.value[bulkEdit.field.key],
                        item
                      )
                        ? '#F48FB1'
                        : 'var(--gray-400)'
                    }">
                    <span *ngIf="
                        !['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.conflict.field.controlType
                        )
                      ">
                      {{
                      parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                      )
                      }}
                    </span>

                    <span *ngIf="
                        ['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.conflict.field.controlType
                        )
                      ">
                      {{
                      parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                      ) | officeTime
                      }}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>
        </p-tabView>
      </ng-container>

      <!-- Bulk Edit dialog: Footer -->
      <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-center">
          <button *ngIf="!['selectField'].includes(bulkEdit.stage)" pButton pRipple type="button" label="Back"
            icon="pi pi-arrow-left" class="p-button-outlined p-m-2" (click)="goBack()"></button>

          <button *ngIf="
              (
                bulkEdit.field.key) ||
              (['editField'].includes(bulkEdit.stage) &&
                (bulkEdit.form.value[bulkEdit.field.key] ||
                  !['dropdown', 'dropdown_ref', 'autoComplete_ref'].includes(
                    bulkEdit.field.controlType
                  )))
            " pButton pRipple type="button" label="Next" icon="pi pi-arrow-right" iconPos="right" class="p-m-2"
            [disabled]="
              (['editField'].includes(bulkEdit.stage) &&
                !['nestedGroup', 'formArray', 'lineItem'].includes(
                  bulkEdit.field.controlType
                ) &&
                !bulkEdit.form.valid) ||
              (['nestedGroup', 'formArray', 'lineItem'].includes(
                bulkEdit.field.controlType
              ) &&
                !isFormArrayValid())
            " (click)="goNext()"></button>

          <button *ngIf="['reviewAndSubmit'].includes(bulkEdit.stage)" pButton pRipple type="button"
            [label]="bulkEdit.conflict ? 'Submit Without Conflicts' : 'Submit'" icon="pi pi-check" class="p-m-2"
            (click)="onSubmitBulkEdit()" [disabled]="loading"></button>
        </div>
        <br />
        <!-- Bulk Edit progress... -->
        <p-progressBar *ngIf="['reviewAndSubmit'].includes(bulkEdit.stage) " [value]="bulkEditProgress"></p-progressBar>
      </ng-template>
    </p-dialog>

    <!-- Editing options -->
    <p-dialog [(visible)]="clearConfirmation" header="Are you sure you want to clear the values of these fields?">
      <p-messages severity="info">
        <ng-template pTemplate>
          <i class="pi pi-info-circle p-mr-2"></i>
          <div class="ml-2">
            This action will clear all the values of the fields selected. This
            will leave them as null values. <br />
            <br />Do you wish to continue?
          </div>
        </ng-template>
      </p-messages>
      <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-center">
          <button pButton pRipple type="button" label="Confirm" icon="pi pi-check" class="p-button-outlined p-m-2"
            (click)="clearValues()"></button>
        </div>
      </ng-template>
    </p-dialog>

    <!-- BULK EDIT: Conflicts Dialog -->
    <p-dialog header="Bulk Edit Conflicts" [(visible)]="showBulkEditConflicts" [modal]="true" [dismissableMask]="true">
      <p-table *ngIf="bulkEdit.conflict" [value]="bulkEdit.conflict.rows" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr class="p-text-uppercase">
            <th *ngFor="let header of ['id', 'filename', 'name']">
              <span>{{ header }}</span>
            </th>

            <th [ngStyle]="{ color: 'var(--gray-400)' }">
              {{ bulkEdit.field.name }}
            </th>
            <th [ngStyle]="{ color: 'var(--primary-color)' }">
              {{ bulkEdit.field.name }}
              <p-tag styleClass="p-mx-2" value="NEW" [rounded]="true"></p-tag>
            </th>

            <th *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
              {{ bulkEdit.conflict.field.name }}
              <p-tag styleClass="p-mx-2" value="CONFLICT" severity="danger" [rounded]="true"></p-tag>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
          <tr>
            <td *ngFor="
                let value of [
                  item.id,
                  parseFilenameFromPath(item['scene']),
                  item.name
                ]
              ">
              <a style="color: white" routerLink="/{{ tableType }}/{{ item.id }}">{{ value }}</a>
            </td>

            <td *ngFor="
                let value of [
                  parseValueForEditReview(
                    item[bulkEdit.field.key],
                    bulkEdit.field
                  ),
                  parseValueForEditReview(
                    bulkEdit.form.value[bulkEdit.field.key],
                    bulkEdit.field
                  )
                ]
              " [ngStyle]="
                [value].includes(
                  parseValueForEditReview(
                    item[bulkEdit.field.key],
                    bulkEdit.field
                  )
                )
                  ? { color: 'var(--gray-400)' }
                  : { color: 'var(--primary-color)' }
              ">
              <span *ngIf="
                  !['calendar-start', 'calendar-end', 'date'].includes(
                    bulkEdit.field.controlType
                  )
                ">
                {{ value }}
              </span>

              <span *ngIf="
                  ['calendar-start', 'calendar-end', 'date'].includes(
                    bulkEdit.field.controlType
                  )
                ">
                {{ value | officeTime }}
              </span>

              <span *ngIf="value == ''">—</span>
            </td>

            <td *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
              <span *ngIf="
                  !['calendar-start', 'calendar-end', 'date'].includes(
                    bulkEdit.field.controlType
                  )
                ">
                {{
                parseValueForEditReview(
                item[bulkEdit.conflict.field.key],
                bulkEdit.conflict.field
                )
                }}
              </span>

              <span *ngIf="
                  ['calendar-start', 'calendar-end', 'date'].includes(
                    bulkEdit.field.controlType
                  )
                ">
                {{
                parseValueForEditReview(
                item[bulkEdit.conflict.field.key],
                bulkEdit.conflict.field
                ) | officeTime
                }}
              </span>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-dialog>

    <!-- BULK Promote Dialog -->
    <p-dialog
      header="Promote {{ bulkPromote.rows && bulkPromote.rows.length == rows.length ? 'ALL ' : '' }} {{ bulkPromote.rows.length }} {{ isAssetPromotion ? 'assets' : 'images' }}?"
      [(visible)]="showBulkPromote" [modal]="true" [dismissableMask]="true">
      <p-table [value]="bulkPromote.rows" styleClass="p-datatable-sm" appendTo="body">
        <ng-template pTemplate="header">
          <tr class="p-text-uppercase">
            <th *ngFor="let header of ['id', 'name']">
              <span>{{ header }}</span>
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
          <tr>
            <td *ngFor="
                  let value of [
                    item.id,
                    item.name
                  ]
                ">
              <span>{{ value }}</span>
            </td>
          </tr>
        </ng-template>
      </p-table>

      <ng-template pTemplate="footer">
        <button pButton pRipple type="button" label="No" icon="pi pi-times" class="p-button-outlined"
          (click)="showBulkPromote = false;"></button>
        <button pButton pRipple type="button" label="Promote" class="p-mx-4" icon="pi pi-cloud-upload"
          (click)="onBulkPromotion(bulkPromote.rows, isAssetPromotion)"></button>
        <button pButton pRipple type="button" label="Promote with new hash"
          pTooltip="Promotes and generates a new hash for gamedata" tooltipPosition="top" icon="pi pi-cloud-upload"
          (click)="onBulkPromotion(bulkPromote.rows, isAssetPromotion, true)"></button>
      </ng-template>
    </p-dialog>

    <!-- BULK Promote Failed Promotions Dialog -->
    <p-dialog *ngIf="bulkPromote.failedPayloads && bulkPromote.failedPayloads.length > 0" header="Failed Promotions"
      [(visible)]="showBulkPromoteFailed" [modal]="true" [dismissableMask]="true">
      <p-table [value]="bulkPromote.failedPayloads" styleClass="p-datatable-sm">
        <ng-template pTemplate="caption"></ng-template>
        <ng-template pTemplate="header">
          <tr class="p-text-uppercase">
            <th *ngFor="let header of ['id', 'name', 'error']">
              <span>{{ header }}</span>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-payload>
          <tr>
            <td *ngFor="
              let value of [
                payload.id,
                payload.name,
                payload.promotionError
              ]
            " [ngStyle]="value ? '' : { color: 'red', fontWeight: 'bold' }">
              {{ value ? value : "empty" }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-dialog>

    <!-- Dialog: Validate Sourcing Items for Filenames -->
    <p-dialog header="Validate Sourcing Items for Filenames" [(visible)]="showFilenameValidationPreviewTable"
      [modal]="true" [style]="{ width: '50vw' }" [maximizable]="true" [draggable]="false" [resizable]="false"
      [closeOnEscape]="true" [responsive]="true">
      <ng-template pTemplate="header">
        <h2>Are you sure you want to validate the following sourcing items for filenames?</h2>
      </ng-template>
      <p-table [value]="selectedRows" responsiveLayout="scroll" [scrollable]="true"
        *ngIf="selectedRows && selectedRows.length > 0">
        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>File Name</th>
            <th>File Type</th>
            <th>Category</th>
            <th>Year</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr *ngIf="item">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.fileName}}</td>
            <td>{{ item.itemFileType_ref ? item.itemFileType_ref.name : "" }}</td>
            <td>{{ item.category_ref ? item.category_ref.name : "" }}</td>
            <td>{{ item.year }}</td>
            <td><button pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="removeRowFromSelectedRows(rowIndex)"></button></td>
          </tr>
        </ng-template>
      </p-table>
      <ng-template pTemplate="footer">
        <div class="p-mt-3 p-d-flex p-jc-end">
          <p-button label="Confirm" class="p-mx-2" styleClass="p-button-outlined"
            (click)="onCustomAction({ name: 'Validate Sourcing Items for Filename' }, selectedRows)"></p-button>
        </div>
      </ng-template>
    </p-dialog>

    <!-- Dialog: Validate and Generate filename for Sourcing Items -->
    <p-dialog header="Validate and Generate Filename for Selected Sourcing Items"
      [(visible)]="showItFilenameGenValidationModal" [modal]="true" [style]="{ width: '50vw' }" [maximizable]="true"
      [draggable]="false" [resizable]="false" [closeOnEscape]="true" [responsive]="true">
      <ng-template pTemplate="header">
        <h2>Are you sure you want to validate and generate filenames for the following sourcing items?</h2>
      </ng-template>
      <p-table [value]="selectedRows" responsiveLayout="scroll" [scrollable]="true"
        *ngIf="selectedRows && selectedRows.length > 0">
        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>File Name</th>
            <th>File Type</th>
            <th>Category</th>
            <th>Year</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr *ngIf="item">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>
              <span *ngIf="item.fileName && item.fileName.length > 0; else elseFileName"
                style="color: 'var(--yellow-600)';">
                {{item.fileName}}<small> *Skipped</small>
              </span>
              <ng-template #elseFileName>
                <span>{{item.fileName}}</span>
              </ng-template>
            </td>
            <td>{{ item.itemFileType_ref ? item.itemFileType_ref.name : "" }}</td>
            <td>{{ item.category_ref ? item.category_ref.name : "" }}</td>
            <td>{{ item.year }}</td>
            <td><button pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="removeRowFromSelectedRows(rowIndex)"></button></td>
          </tr>
        </ng-template>
      </p-table>
      <ng-template pTemplate="footer">
        <div class="p-mt-3 p-d-flex p-jc-end">
          <p-button label="Confirm" class="p-mx-2" styleClass="p-button-outlined"
            (click)="onCustomAction({ name: 'Validate and Generate Filename for sourcing items' }, selectedRows)"></p-button>
        </div>
      </ng-template>
    </p-dialog>

    <!-- Dialog: Validate and Generate Paths for Sourcing Items -->
    <p-dialog header="Validate and Generate Paths for Sourcing Items" [(visible)]="showPathGenerationPreviewTable"
      [modal]="true" [style]="{ width: '50vw' }" [maximizable]="true" [draggable]="false" [resizable]="false"
      [closeOnEscape]="true" [responsive]="true">
      <p-table [value]="selectedRows" responsiveLayout="scroll" [scrollable]="true"
        *ngIf="selectedRows && selectedRows.length > 0">
        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>File Name</th>
            <th>File Type</th>
            <th>Year</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr *ngIf="item">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.fileName }}</td>
            <td>{{ item.itemFileType_ref ? item.itemFileType_ref.name : "" }}</td>
            <td>{{ item.year }}</td>
            <td><button pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="removeRowFromSelectedRows(rowIndex)"></button></td>
          </tr>
        </ng-template>
      </p-table>
      <ng-template pTemplate="footer">
        <div class="p-mt-3 p-d-flex p-jc-end">
          <p-button label="Confirm" class="p-mx-2" styleClass="p-button-outlined"
            (click)="onCustomAction({ name: 'Validate and Create Paths' }, selectedRows)"></p-button>
        </div>
      </ng-template>
    </p-dialog>

    <!-- Dialog: Validate and Generate filename for Sourcing Challenges -->
    <p-dialog header="Validate and Generate Filename for Selected Sourcing Challenges"
      [(visible)]="showChFilenameGenValidationModal" [modal]="true" [style]="{ width: '80vw' }" [maximizable]="true"
      [draggable]="false" [resizable]="false" [closeOnEscape]="true" [responsive]="true">
      <ng-template pTemplate="header">
        <h2>Confirm Sourcing Challenges Records to validate and generate filenames?</h2>
      </ng-template>
      <p-table [value]="selectedRows" responsiveLayout="scroll" [scrollable]="true"
        *ngIf="selectedRows && selectedRows.length > 0">
        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Challenge Type</th>
            <th>Climate</th>
            <th>Scene Type</th>
            <th>Year</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr *ngIf="item">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type_ref ? item.type_ref.name : "" }}</td>
            <td>{{ item.climate_ref ? item.climate_ref.name : "" }}</td>
            <td>{{ item.sceneType ? item.sceneType.name : "" }}</td>
            <td>{{ item.year }}</td>
            <td><button pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="removeRowFromSelectedRows(rowIndex)"></button></td>
          </tr>
        </ng-template>
      </p-table>
      <ng-template pTemplate="footer">
        <div class="p-mt-3 p-d-flex p-jc-end">
          <p-button label="Confirm" class="p-mx-2" styleClass="p-button-outlined"
            (click)="onCustomAction({ name: 'validateAndGenerateFilenameForChallenges' }, selectedRows)"></p-button>
        </div>
      </ng-template>
    </p-dialog>

    <!-- Dialog: Validate and Generate paths for Sourcing Challenges -->
    <p-dialog header="Validate and Generate paths for Selected Sourcing Challenges"
      [(visible)]="showChPathGenerationModal" [modal]="true" [style]="{ width: '80vw' }" [maximizable]="true"
      [draggable]="false" [resizable]="false" [closeOnEscape]="true" [responsive]="true">
      <ng-template pTemplate="header">
        <h2>Confirm Sourcing Challenges Records to validate and generate paths?</h2>
      </ng-template>
      <p-table [value]="selectedRows" responsiveLayout="scroll" [scrollable]="true"
        *ngIf="selectedRows && selectedRows.length > 0">
        <ng-template pTemplate="header">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Challenge Type</th>
            <th>Climate</th>
            <th>Scene Type</th>
            <th>Year</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr *ngIf="item">
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>{{ item.type_ref ? item.type_ref.name : "" }}</td>
            <td>{{ item.climate_ref ? item.climate_ref.name : "" }}</td>
            <td>{{ item.sceneType ? item.sceneType.name : "" }}</td>
            <td>{{ item.year }}</td>
            <td><button pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text"
                (click)="removeRowFromSelectedRows(rowIndex)"></button></td>
          </tr>
        </ng-template>
      </p-table>
      <ng-template pTemplate="footer">
        <div class="p-mt-3 p-d-flex p-jc-end">
          <p-button label="Confirm" class="p-mx-2" styleClass="p-button-outlined"
            (click)="onCustomAction({ name: 'validateAndGeneratePathsForChallenges' }, selectedRows)"></p-button>
        </div>
      </ng-template>
    </p-dialog>

    <!-- Dialog: Input Bulk Edit -->
    <p-dialog [(visible)]="showBulkEditForInput" [modal]="true" [dismissableMask]="true" [style]="{ width: 'auto' }">

      <!-- Dialog Header -->
      <ng-template pTemplate="header">
        <h3 class="p-dialog-title">
          Edit Field: {{ bulkMultiSelect.field.name }}
        </h3>
      </ng-template>

      <!-- Dialog Body -->

      <ng-container *ngIf="bulkMultiSelectAction.mode == 'append-input'">
        <!-- Form Fields -->
        <ng-container>
          <div style="display: flex; flex-direction: row">
            <div class="p-mr-4 p-ml-4">
              <h4>Append value to existing</h4>
              <form *ngIf="bulkEdit.form" [formGroup]="bulkEdit.form">
                <p-editor [(ngModel)]="bulkMultiSelectAction.value" [formControlName]="bulkEdit.field.key"
                  [id]="bulkEdit.field.key" [style]="{ height: '320px' }"></p-editor>
              </form>
            </div>
          </div>
        </ng-container>


      </ng-container>
      <ng-container *ngIf="bulkMultiSelectAction.mode == 'replace-input'">
        <!-- Form Fields -->
        <ng-container>
          <div style="display: flex; flex-direction: row">
            <div class="p-mr-4 p-ml-4">
              <h4>Replace Existing Values</h4>
              <form *ngIf="bulkEdit.form" [formGroup]="bulkEdit.form">
                <p-editor [(ngModel)]="bulkMultiSelectAction.value" [formControlName]="bulkEdit.field.key"
                  [id]="bulkEdit.field.key" [style]="{ height: '320px' }"></p-editor>
              </form>
            </div>
          </div>
        </ng-container>


      </ng-container>

      <!-- Dialog Footer -->
      <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-center">
          <button
            *ngIf="!['selectInputEditMode'].includes(bulkEdit.stage) && (bulkMultiSelectAction.mode == 'append-input' || bulkMultiSelectAction.mode == 'replace-input')"
            pButton pRipple type="button" label="Back" icon="pi pi-arrow-left" class="p-button-outlined p-m-2"
            (click)="goBackBulkEditInput()"></button>
          <button *ngIf="bulkMultiSelectAction.mode == 'append-input'" pButton pRipple type="button" label="Next"
            icon="pi pi-arrow-right" iconPos="right" class="p-m-2"
            [disabled]="!bulkMultiSelectAction.value || bulkMultiSelectAction.value.length == 0"
            (click)="showBulkEditForNoteReview = true"></button>

          <button *ngIf="bulkMultiSelectAction.mode == 'replace-input'" pButton pRipple type="button" label="Next"
            icon="pi pi-arrow-right" iconPos="right" class="p-m-2"
            [disabled]="!bulkMultiSelectAction.value || bulkMultiSelectAction.value.length == 0"
            (click)="showBulkEditForNoteReview = true"></button>

        </div>
        <br />
      </ng-template>
    </p-dialog>

    <p-dialog header="Review" [(visible)]="showBulkEditForNoteReview" [modal]="true" [style]="{ width: '80vw' }"
      [maximizable]="true" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
      <ng-template pTemplate="header">
        <h3>Review and Submit</h3>
        <p-message *ngIf="bulkMultiSelectAction.mode == 'replace-input'" severity="info"
          text="Rows that do not contain the value to be replaced will be skipped"></p-message>
        <p-message *ngIf="bulkMultiSelectAction.mode == 'append-input'" severity="info"
          text="Values will be added to the existing ones"></p-message>
        <br />
      </ng-template>
      <ng-template pTemplate="body">
        <p-tabView>
          <p-tabPanel *ngIf="bulkEdit.conflict">
            <ng-template pTemplate="header">
              CONFLICTS ONLY - {{ bulkEdit.conflict.rows.length }}
            </ng-template>
            <p-table [value]="bulkEdit.conflict.rows" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr class="p-text-uppercase">
                  <th *ngFor="let header of ['id', 'filename', 'name']">
                    <span>{{ header }}</span>
                  </th>

                  <th [ngStyle]="{ color: 'var(--gray-400)' }">
                    {{ bulkEdit.field.name }}
                  </th>
                  <th [ngStyle]="{ color: 'var(--primary-color)' }">
                    {{ bulkEdit.field.name }}
                    <p-tag styleClass="p-mx-2" value="NEW" [rounded]="true"></p-tag>
                  </th>

                  <th *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
                    {{ bulkEdit.conflict.field.name }}
                    <p-tag styleClass="p-mx-2" value="CONFLICT" severity="danger" [rounded]="true"></p-tag>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-item>
                <tr>
                  <td *ngFor="
                      let value of [
                        item.id,
                        item.name
                      ]
                    ">
                    <a style="color: white" routerLink="/{{ tableType }}/{{ item.id }}">{{ value }}</a>
                  </td>

                  <td *ngFor="
                      let value of [
                        parseValueForEditReview(
                          item[bulkEdit.field.key],
                          bulkEdit.field
                        ),
                        parseValueForEditReview(
                          bulkEdit.form.value[bulkEdit.field.key],
                          bulkEdit.field
                        )
                      ]
                    " [ngStyle]="
                      [value].includes(
                        parseValueForEditReview(
                          item[bulkEdit.field.key],
                          bulkEdit.field
                        )
                      )
                        ? { color: 'var(--gray-400)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    <span *ngIf="
                        !['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{ value }}
                    </span>

                    <span *ngIf="
                        ['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{ value | officeTime }}
                    </span>
                    <span *ngIf="value == ''">—</span>
                  </td>

                  <td *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
                    <span *ngIf="
                        !['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{
                      parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                      )
                      }}
                    </span>

                    <span *ngIf="
                        ['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{
                      parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                      ) | officeTime
                      }}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>

          <p-tabPanel>
            <ng-template pTemplate="header">
              ALL SELECTED ROWS - {{ bulkEdit.rows.length }}
            </ng-template>
            <p-table [value]="bulkEdit.rows" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr class="p-text-uppercase">
                  <th *ngFor="let header of ['id', 'filename', 'name']">
                    <span>{{ header }}</span>
                  </th>

                  <th [ngStyle]="{ color: 'var(--gray-400)' }">
                    {{ bulkEdit.field.name }}
                  </th>

                  <th [ngStyle]="{ color: 'var(--primary-color)' }">
                    {{ bulkEdit.field.name }}
                    <p-tag styleClass="p-mx-2" value="NEW" [rounded]="true"></p-tag>
                  </th>

                  <!-- header in conflict -->
                  <th *ngIf="bulkEdit.conflict" [ngStyle]="{ color: '#F48FB1' }">
                    {{ bulkEdit.conflict.field.name }}
                    <p-tag styleClass="p-mx-2" value="CONFLICT" severity="danger" [rounded]="true"></p-tag>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-item>
                <tr>
                  <td *ngFor="
                      let value of [
                        item.id,
                        parseFilenameFromPath(item['prefab']),
                        item.name
                      ]
                    ">
                    <span>{{ value }}</span>
                  </td>

                  <td *ngFor="
                      let value of [
                        parseValueForEditReview(
                          item[bulkEdit.field.key],
                          bulkEdit.field
                        ),
                        parseValueForEditReview(
                          bulkEdit.form.value[bulkEdit.field.key],
                          bulkEdit.field
                        )
                      ]
                    " [ngStyle]="
                      [value].includes(
                        parseValueForEditReview(
                          item[bulkEdit.field.key],
                          bulkEdit.field
                        )
                      )
                        ? { color: 'var(--gray-400)' }
                        : { color: 'var(--primary-color)' }
                    ">
                    <span *ngIf="
                        !['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{ value }}
                    </span>

                    <span *ngIf="
                        ['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.field.controlType
                        )
                      ">
                      {{ value | officeTime }}
                    </span>

                    <span *ngIf="value == ''">—</span>
                  </td>

                  <!-- column in conflict -->
                  <td *ngIf="bulkEdit.conflict" [ngStyle]="{
                      color: bulkEdit.conflict.indicator(
                        bulkEdit.form.value[bulkEdit.field.key],
                        item
                      )
                        ? '#F48FB1'
                        : 'var(--gray-400)'
                    }">
                    <span *ngIf="
                        !['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.conflict.field.controlType
                        )
                      ">
                      {{
                      parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                      )
                      }}
                    </span>

                    <span *ngIf="
                        ['calendar-start', 'calendar-end', 'date'].includes(
                          bulkEdit.conflict.field.controlType
                        )
                      ">
                      {{
                      parseValueForEditReview(
                      item[bulkEdit.conflict.field.key],
                      bulkEdit.conflict.field
                      ) | officeTime
                      }}
                    </span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </p-tabPanel>
        </p-tabView>
      </ng-template>

      <ng-template pTemplate="footer">
        <button *ngIf="bulkMultiSelectAction.mode == 'replace-input'" pButton pRipple type="button"
          [label]="bulkEdit.conflict ? 'Submit Without Conflicts' : 'Submit'" icon="pi pi-check" class="p-m-2"
          (click)="onSubmitBulkEdit()" [disabled]="loading"></button>
        <button *ngIf="bulkMultiSelectAction.mode == 'append-input'" pButton pRipple type="button"
          [label]="bulkEdit.conflict ? 'Submit Without Conflicts' : 'Submit'" icon="pi pi-check" class="p-m-2"
          (click)="onSubmitBulkAppendNote()" [disabled]="loading"></button>
        <br />
        <!-- Bulk Edit progress... -->
        <p-progressBar *ngIf="['showBulkEditForNoteReview'].includes(bulkEdit.stage)"
          [value]="bulkEditProgress"></p-progressBar>

      </ng-template>

    </p-dialog>

    <!-- Dialog: Cost Bulk Edit -->
    <p-dialog [(visible)]="showBulkEditForCost" [modal]="true" [dismissableMask]="true" [style]="{ width: 'auto' }">
      <!-- Cost - Bulk Edit header -->
      <ng-template pTemplate="header">
        <h3 class="p-dialog-title">
          Edit Field: {{ bulkMultiSelect.field.name }}
        </h3>
      </ng-template>

      <!-- Dialog Body -->

      <!-- Bulk Edit Mode: Replace -->
      <ng-container *ngIf="bulkMultiSelectAction.mode == 'replace-cost'">
        <!-- Form Fields -->
        <ng-container>
          <div style="display: flex; flex-direction: row">
            <div class="p-mr-4 p-ml-4">
              <h4>Select value to replace</h4>
              <p-listbox [(ngModel)]="bulkMultiSelectAction.valueToReplace" [aria-label]="bulkMultiSelect.field.name"
                [id]="bulkMultiSelect.field.key" [filter]="true" [filterPlaceHolder]="'Search a Currency'"
                [options]="options['currencies']" optionLabel="name" optionValue="_id" [multiple]="false"
                [checkbox]="true" class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }"
                (onChange)="onCostTypeReplaceClick($event, true)"></p-listbox>
            </div>
            <div>
              <h4>Select value to replace with</h4>
              <p-listbox [aria-label]="bulkMultiSelect.field.name" [id]="bulkMultiSelect.field.key"
                [(ngModel)]="bulkMultiSelectAction.replaceValues" [filter]="true"
                [filterPlaceHolder]="'Search a Currency'" [options]="options['currencies']" optionLabel="name"
                optionValue="_id" [multiple]="false" [checkbox]="true" class="bulkEdit-field"
                [listStyle]="{ 'max-height': '50vh' }" [disabled]="false"
                (onChange)="onCostTypeReplaceClick($event, false)"></p-listbox>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="bulkMultiSelectAction.mode == 'add-cost'">
        <ng-container>
          <div style="display: flex; flex-direction: row;">
            <div class="p-mr-4 p-ml-4">
              <div class="p-col-12 p-pr-4">
                <prizes-card-form-group [(prize)]="bulkMultiSelectAction.replaceValues" [showPrize]="true"
                  [isCostField]="true" fieldsetName="Cost(s)"></prizes-card-form-group>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="bulkMultiSelectAction.mode == 'remove-cost'">
        <!-- Form Fields -->
        <ng-container>
          <div style="display: flex; flex-direction: row">
            <div class="p-mr-8 p-ml-8">
              <h4>Select Currency</h4>
              <p-listbox [(ngModel)]="bulkMultiSelectAction.replaceValues" [aria-label]="bulkMultiSelect.field.name"
                [id]="bulkMultiSelect.field.key" [filter]="true" [filterPlaceHolder]="'Search a Currency'"
                [options]="options['currencies']" optionLabel="name" optionValue="_id" [multiple]="false"
                [checkbox]="true" class="bulkEdit-field" [listStyle]="{ 'max-height': '50vh' }"
                (onChange)="onCostTypeReplaceClick($event, true)"></p-listbox>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <!-- Dialog Footer -->
      <!-- Bulk Edit dialog: Footer -->
      <ng-template pTemplate="footer">
        <div class="p-d-flex p-jc-center">
          <button pButton pRipple type="button" label="Back" icon="pi pi-arrow-left" class="p-button-outlined p-m-2"
            (click)="goBackBulkEditCost()"></button>
          <button *ngIf="bulkMultiSelect.field.key" pButton pRipple type="button" label="Next" icon="pi pi-arrow-right"
            iconPos="right" class="p-m-2"
            [disabled]="!bulkMultiSelectAction.replaceValues || bulkMultiSelectAction.replaceValues.length == 0"
            (click)="showBulkEditForCostReview = true"></button>
        </div>
        <br />
      </ng-template>
    </p-dialog>

    <!-- Cost Bulk Edit (replace) - Review and Submit Dialog -->
    <p-dialog header="Review" [(visible)]="showBulkEditForCostReview" [modal]="true" [style]="{ width: '80vw' }"
      [maximizable]="true" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
      <ng-template pTemplate="header">
        <h2>Review and Submit</h2>
        <br />
        <p-message *ngIf="bulkMultiSelectAction.mode != 'add-cost'"
          severity="{{bulkMultiSelectAction.mode == 'remove-cost' ? 'warn' : 'info'}}"
          text="{{bulkMultiSelectAction.mode == 'remove-cost' ? 'Are you sure you want to remove these costs for these selected items?' : 'This action will only apply to costs that match the value to be replaced.'}}"></p-message>
      </ng-template>
      <p-table *ngIf="showBulkEditForCostReview" [value]="selectedRows" responsiveLayout="scroll" [scrollable]="true">
        <ng-template pTemplate="header">
          <tr>
            <th>Id</th>
            <th>Name</th>
            <th>Current Cost(s) Value</th>
            <th>{{ bulkMultiSelectAction.mode == 'replace-cost' ? 'Change' : 'New Cost(s) Value' }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.id }}</td>
            <td>{{ item.name }}</td>
            <td>
              <app-data-field [value]="item.costs_ref" [field]="costField" [entity]="item"
                [options]="options['costs_ref']" [page]="'table'" [tableView]="tableView"
                [entityType]="tableType"></app-data-field>
            </td>
            <td *ngIf="bulkMultiSelectAction.mode == 'replace-cost'">
              {{ parseCostType(bulkMultiSelectAction.valueToReplace) }}
              <i class="pi pi-arrow-right" style="margin: 10px;"></i>
              {{ parseCostType(bulkMultiSelectAction.replaceValues) }}
            </td>
            <td *ngIf="bulkMultiSelectAction.mode == 'add-cost'">
              <app-data-field
                [value]="parseNewCostValueForTableView(item.costs_ref, bulkMultiSelectAction.replaceValues)"
                [field]="costField" [entity]="item" [options]="options['costs_ref']" [page]="'table'"
                [tableView]="tableView" [entityType]="tableType"></app-data-field>
            </td>
            <td *ngIf="bulkMultiSelectAction.mode == 'remove-cost'">
              <app-data-field
                [value]="parseNewCostValueForTableView(item.costs_ref, bulkMultiSelectAction.replaceValues, false)"
                [field]="costField" [entity]="item" [options]="options['costs_ref']" [page]="'table'"
                [tableView]="tableView" [entityType]="tableType"></app-data-field>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="p-d-flex p-jc-center">
            <button pButton pRipple type="button" label="Back" icon="pi pi-arrow-left" class="p-button-outlined p-m-2"
              (click)="showBulkEditForCostReview = !showBulkEditForCostReview"></button>
            <button *ngIf="bulkMultiSelect.field.key" pButton pRipple type="button" label="Next"
              icon="pi pi-arrow-right" iconPos="right" class="p-m-2"
              (click)="confirmBulkEditForCost(bulkMultiSelectAction.mode)"></button>
          </div>
        </ng-template>
      </p-table>
    </p-dialog>
  </ng-template>

  <!-- TABLE HEADER -->
  <ng-template pTemplate="header" #tableheader let-columns>
    <tr style="width: 100%">
      <th>
        <p-checkbox [(ngModel)]="isHeaderChecked" [binary]="true" (onChange)="onHeaderCheckboxToggle($event.checked)"
          pTooltip="Select or De-select all filtered rows" tooltipPosition="right" showDelay="2000">
        </p-checkbox>
      </th>
      <th>&nbsp;</th>

      <th *ngFor="let col of columns" class="p-text-left" [pSortableColumn]="!tableConfig.isEditMode ? col.key : null"
        pReorderableColumn>
        {{ col.name }}
        <i *ngIf="col.tooltip" class="pi pi-info-circle tooltip-info-icon" pTooltip="{{ col.tooltip }}"
          tooltipPosition="top" [tooltipStyleClass]="'tooltip-custom'"></i>
        <p-sortIcon *ngIf="!tableConfig.isEditMode" field="{{ col.key }}"></p-sortIcon>
      </th>

      <th *ngIf="!tableConfig.hideEditRow && !tableConfig.isEditMode" style="width: 100px">
        Edit Row
      </th>
      <th *ngIf="!tableConfig.hideEditRow && tableConfig.isEditMode
        &&
          (['items-sourcing', 'challenges-sourcing'].includes(this.tableType))" style="width: 100px">
        Lock Row
      </th>
      <th *ngIf="
          !tableConfig.hideEditRow &&
          tableConfig.isEditMode &&
          (['items-sourcing', 'challenges-sourcing'].includes(this.tableType) ||
            this.tableType == 'items' ||
            this.tableType == 'challenges')
        " style="width: 100px">
        Duplicate Row
      </th>
    </tr>
  </ng-template>

  <!-- TABLE BODY -->

  <ng-template pTemplate="body" #tablebody let-row let-columns="columns" let-editing="editing" let-rowIndex="rowIndex">
    <tr [pSelectableRow]="row" [pSelectableRowIndex]="rowIndex" *ngIf="!isLoading && (!row.archived || showArchived)"
      [pEditableRow]="row" [ngClass]="{
        'promoted-row': row.promoted && !row.isLocked,
        'locked-row': row.isLocked && !row.promoted && ['items-sourcing', 'challenges-sourcing'].includes(this.tableType),
        'selected-row': tableState.rowSelection.includes(row.id),
        'promoted-row-view': row.promoted
      }">
      <td>
        <p-tableCheckbox [value]="row" #checkboxTableP [index]="rowIndex"
          (click)="checkboxTable(checkboxTableP, $event)" *ngIf="row && !row.promoted"></p-tableCheckbox>
      </td>
      <td *ngIf="row.promoted"></td>
      <td *ngIf="!row.promoted">
        <div *ngIf="['items-sourcing', 'challenges-sourcing'].includes(this.tableType); else elseBlock">
          <a pButton pRipple type="button" class="p-button-rounded p-button-text" icon="pi pi-trash" iconPos="left"
            (click)="deleteConfirm(row)"></a>
        </div>

        <ng-template #elseBlock>
          <a *ngIf="(tableType == 'progression-levels' && tableView) else elseTableTypes" pButton pRipple type="button"
            class="p-button-rounded p-button-text" icon="pi pi-pencil" iconPos="left"
            routerLink="/items/edit/{{ row['id'] }}"></a>
        </ng-template>
        <ng-template #elseTableTypes>
          <a pButton pRipple type="button" class="p-button-rounded p-button-text" icon="pi pi-pencil" iconPos="left"
            routerLink="/{{ tableType }}/edit/{{ row['id'] }}"></a>
        </ng-template>
      </td>

      <!-- EDIT MODE -->
      <ng-container *ngIf="tableConfig.isEditMode">
        <td *ngFor="let col of columns" class="p-text-left">
          <app-input-field *ngIf="col.isInputField && !row.promoted &&!row.isLocked; else noInput" #inputField
            [(value)]="row[col.key]" [field]="col" [entity]="row" [options]="options[col.key]" [page]="'table'"
            [entityViewLink]="'/' + tableType + '/' + row['id']" [tableView]="tableView" [style]="{ width: '100%' }"
            [entityType]="tableType" (modelChange)="onModelChange($event)"
            (pathComponentValueChange)="onPathEntityValueChange($event)" (categoryChange)="onCategoryChange($event)"
            (componentChange)="onComponentChange($event)"></app-input-field>

          <ng-template #noInput>
            <app-data-field *ngIf="!col.isInputField || row.promoted || row.isLocked" [tableView]="tableView"
              [value]="row[col.key]" [field]="col" [entity]="row" [options]="options[col.key]" [page]="'table'"
              [tableView]="tableView" [entityViewLink]="'/' + tableType + '/' + row['id']"
              [entityType]="tableType"></app-data-field>
          </ng-template>

        </td>
      </ng-container>

      <!-- EDIT ROW / NOT EDIT MODE -->
      <ng-container *ngIf="!tableConfig.isEditMode">
        <td *ngFor="let col of columns" class="p-text-left">
          <p-cellEditor *ngIf="col.isInputField">
            <ng-template pTemplate="input">
              <app-input-field #inputField [(value)]="row[col.key]" [field]="col" [entity]="row"
                [options]="options[col.key]" [page]="'table'" [tableView]="tableView"
                [entityViewLink]="'/' + tableType + '/' + row['id']" [style]="{ width: '100%' }"
                [entityType]="tableType" (modelChange)="onModelChange($event)"
                (pathComponentValueChange)="onPathEntityValueChange($event)"
                (categoryChange)="onCategoryChange($event)"></app-input-field>
            </ng-template>

            <ng-template pTemplate="output">
              <app-data-field [value]="row[col.key]" [field]="col" [entity]="row" [options]="options[col.key]"
                [page]="'table'" [tableView]="tableView" [entityViewLink]="'/' + tableType + '/' + row['id']"
                [entityType]="tableType" [options]="options[col.key]"></app-data-field>
            </ng-template>
          </p-cellEditor>

          <app-data-field *ngIf="!col.isInputField" [value]="row[col.key]" [field]="col" [entity]="row"
            [options]="options[col.key]" [page]="'table'" [tableView]="tableView"
            [entityViewLink]="'/' + tableType + '/' + row['id']" [entityType]="tableType"
            [options]="options[col.key]"></app-data-field>
        </td>
      </ng-container>

      <td *ngIf="!tableConfig.hideEditRow && !tableConfig.isEditMode" style="text-align: left">
        <button *ngIf="!editing" [disabled]="this.columnsLoading" pButton pRipple type="button" pInitEditableRow
          icon="pi pi-pencil" class="p-button-raised" (click)="onRowEditInit(row)" [disabled]="row.promoted"></button>

        <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-save"
          class="p-button-rounded p-button-text p-button-success p-mr-2" (click)="onSubmitEditMode(row)"></button>
        <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
          (click)="onRowEditCancel(row, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
      </td>
      <td *ngIf="tableConfig.isEditMode && (['items-sourcing', 'challenges-sourcing'].includes(this.tableType))"
        style="text-align: left">
        <button *ngIf="tableConfig.isEditMode && !row.isLocked" [disabled]="row && row.promoted" pButton pRipple
          type="button" icon="pi pi-lock-open" class="p-button-raised" (click)="onRowLock(row)"></button>

        <button *ngIf="tableConfig.isEditMode && row.isLocked" [disabled]="row && row.promoted" pButton pRipple
          type="button" icon="pi pi-lock" class="p-button-raised" (click)="onRowLock(row)"></button>
      </td>
      <td *ngIf="tableConfig.isEditMode" style="overflow-x: auto">
        <duplicate-from-record [tableType]="tableType" [isEnabled]="tableConfig.duplicateRowEnabled" [entityId]="row.id"
          [row]="row" (onRowChange)="this.rows = $event" (onPathValueChange)="onPathEntityValueChange($event)">
        </duplicate-from-record>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- EDIT MODE: 'Confirm Submit' Overlay -->
<p-overlayPanel #submitEditMode_overlay [dismissable]="true">
  <ng-template pTemplate="content">
    <div class="p-mb-2 p-d-flex p-ai-center">
      <i class="pi pi-exclamation-triangle p-mr-2" style="color: 'var(--pink-500)'; font-size: 1.5em"></i>
      <h3 class="p-my-0">Confirm</h3>
    </div>

    <div class="p-p-2">Are you sure you want to submit these changes?</div>

    <div class="p-mt-3 p-d-flex p-jc-end">
      <p-button label="Nope" class="p-mx-2" styleClass="p-button-outlined"
        (click)="submitEditMode_overlay.hide()"></p-button>
      <p-button label="Yes" icon="pi pi-check" class="p-mx-2"
        (click)="onSubmitEditMode(); submitEditMode_overlay.hide()"></p-button>
    </div>
  </ng-template>
</p-overlayPanel>

<p-confirmDialog header="Validations Triggered" icon="pi pi-exclamation-triangle" *ngFor="let row of rows"
  key="{{row && row.id ? row.id : null}}"></p-confirmDialog>

<!-- Upload Selected Sourcing Items Confirmation Modal -->
<p-dialog
  [header]="'Are you sure you want to upload the following sourcing ' + (this.tableType === 'items-sourcing' ? 'items' : 'challenges') + '?'"
  [(visible)]="displayConfirmItemSourcingUploadModal" [modal]="true" [style]="{ width: '50vw' }" [maximizable]="true"
  [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <h3>Are you sure you want to upload the following sourcing {{ this.tableType === 'items-sourcing' ? 'items' :
      'challenges' }}?</h3>
    <br />
    <p-message severity="info"
      text="Records that have already been uploaded previously will be omitted in this action.">
    </p-message>
  </ng-template>
  <p-table [value]="selectedRows" responsiveLayout="scroll" [scrollable]="true"
    *ngIf="selectedRows && selectedRows.length > 0">
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>File Name</th>
        <th>Category</th>
        <th>Uploaded</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr *ngIf="item">
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.fileName }}</td>
        <td>{{ item.category_ref ? item.category_ref.name : "" }}</td>
        <td>
          <button pButton pRipple label="{{ item.promoted ? 'Yes' : 'No' }}" class="p-button-outlined p-button-{{
              item.promoted ? 'success' : 'secondary'
            }}"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template pTemplate="footer">
    <div class="p-mt-3 p-d-flex p-jc-end">
      <p-button label="Confirm" class="p-mx-2" styleClass="p-button-outlined"
        (click)="onCustomAction($event, selectedRows, true)"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!-- Move Sourcing Items Confirmation Modal -->
<p-dialog
  [header]="this.tableType == 'items-sourcing' ? 'Move Sourcing Items to Sourcing Group' : 'Move Sourcing Challenges to Sourcing Group'"
  [(visible)]="displayMoveSourcingModal" [modal]="true" [style]="{ width: '20vw' }" [resizable]="false">
  <p-dropdown appendTo="body" [options]="sourcingGroups" [(ngModel)]="selectedSourcingGroup"
    placeholder="Select a group" optionLabel="name" optionValue="value" [showClear]="true"></p-dropdown>
  <ng-template pTemplate="footer">
    <div class="p-mt-3 p-d-flex p-jc-end">
      <p-button label="Confirm" class="p-mx-2" styleClass="p-button-outlined" [disabled]="!selectedSourcingGroup"
        (click)="moveItemsSourcingToGroup(this.tableType != 'items-sourcing')"></p-button>
    </div>
  </ng-template>
</p-dialog>

<!-- Duplicate existing item modal  -->

<p-dialog class="copyExistingItemDialog" [(visible)]="selectExistingItemForDuplication">
  <ng-template pTemplate="header">
    Copy Existing {{this.tableType == 'items-sourcing' ? 'Item' : 'Challenge'}} into Sourcing Group
  </ng-template>
  <ng-template pTemplate="content">
    <span class="p-fluid" [style]="{ width: '100%' }">
      <h5>Search for an {{this.tableType == 'items-sourcing' ? 'Item' : 'Challenge'}} to Import</h5>
      <p-autoComplete *ngIf="['items-sourcing'].includes(this.tableType)" class="copyExistingItemDialogAutoComplete"
        [suggestions]="suggestions['items_ref']" (completeMethod)="
        getSuggestionsForItemFieldRef($event.query, 'items_ref', 'items')" [showEmptyMessage]="true" field="id"
        minLength="3" (onSelect)="onSelection($event)">
        >
        <ng-template let-item pTemplate="selectedItem">
          ({{ item.id }}) - {{ item.fileName }} - {{ item.name }} - <span *ngFor="let color of item.colors_ref">{{color
            && color.name ? color.name : 'No Colors Added'}} </span>
        </ng-template>

        <ng-template let-item pTemplate="item">
          ({{ item.id }}) - {{ item.fileName }} - {{ item.name }} - <span *ngFor="let color of item.colors_ref">{{color
            && color.name ? color.name : 'No Colors Added'}} </span>
        </ng-template>
      </p-autoComplete>
      <p-autoComplete *ngIf="['challenges-sourcing'].includes(this.tableType)"
        class="copyExistingItemDialogAutoComplete" [suggestions]="suggestions['challenges']" (completeMethod)="
        getSuggestionsForChallengeFieldRef($event.query, 'challenges', 'challenges')" [showEmptyMessage]="true"
        field="id" minLength="3" (onSelect)="onSelection($event)">
        >
        <ng-template let-challenge pTemplate="selectedItem">
          <!-- ({{ item.id }}) - {{ item.fileName }} - {{ item.name }} -  <span *ngFor="let color of item.colors_ref">{{color && color.name ? color.name : 'No Colors Added'}} </span> -->
          ({{ challenge.id }}) - {{ challenge.name }}
        </ng-template>

        <ng-template let-challenge pTemplate="item">
          ({{ challenge.id }}) - {{ challenge.name }}
        </ng-template>
      </p-autoComplete>
    </span>
  </ng-template>

</p-dialog>

<p-dialog class="copyExistingItemFieldDialog" [(visible)]="selectFieldsForDuplication">
  <ng-template pTemplate="header">
    <button pButton pRipple type="button" icon="pi pi-arrow-left" class="p-button-rounded p-button-text"
      (click)="goBackExistingItemDialog()"></button>
    Select Fields from {{itemToCopy.name}}({{itemToCopy.id}}) to duplicate.
  </ng-template>
  <ng-template pTemplate="content">
    <span *ngIf="['items-sourcing'].includes(this.tableType)">
      <h5>Inorganic</h5>
      <div class="py-3">
        <p-button [icon]="activeState[0] ? 'pi pi-minus' : 'pi pi-plus'" (click)="toggleInorganic(0)"
          styleClass="p-button-text"
          [label]="activeState[0] ? 'Deselect All Inorganic Options' : 'Select All Inorganic Options'"></p-button>
        <p-button [icon]="activeState[1] ? 'pi pi-minus' : 'pi pi-plus'" (click)="toggleOrganic(1)"
          styleClass="p-button-text ml-2"
          [label]="activeState[0] ? 'Deselect All Organic Options' : 'Select All Organic Options'"></p-button>
      </div>
    </span>
    <div *ngIf="['challenges-sourcing'].includes(this.tableType)" class="p-pt-3">
      <span>
        <p-button [icon]="challengeActiveState[0] ? 'pi pi-minus' : 'pi pi-plus'" (click)="toggleChallengeFields(0)"
          styleClass="p-button-text"
          [label]="challengeActiveState[0] ? 'Deselect All Challenge Fields' : 'Select All Challenge Fields'"></p-button>
      </span>
    </div>
    <p-accordion>
      <span *ngIf="['items-sourcing'].includes(this.tableType)">
        <p-accordionTab [(selected)]="activeState[0]">
          <ng-template pTemplate="header">
            Inorganic
            <!-- <button pButton pRipple type="button" label="Select All" class="p-button-rounded" (onClick)="toogleSelection()"></button>  -->
          </ng-template>
          <ng-template pTemplate="content">
            <div *ngFor="let field of itemInorganicFields" class="p-field-checkbox">
              <p-checkbox [value]="field" [(ngModel)]="selectedItemInOrganicFields">
              </p-checkbox>
              <label [for]="field.key">{{field.name}}</label>
            </div>
          </ng-template>
        </p-accordionTab>
      </span>

      <span *ngIf="['items-sourcing'].includes(this.tableType)">
        <p-accordionTab [(selected)]="activeState[1]">
          <ng-template pTemplate="header">
            Organic
            <!-- <button pButton pRipple type="button" label="Select All" class="p-button-rounded"></button> -->
          </ng-template>
          <ng-template pTemplate="content">
            <div *ngFor="let field of itemOrganicFields" class="p-field-checkbox">
              <p-checkbox [value]="field" [(ngModel)]="selectedItemOrganicFields">
              </p-checkbox>
              <label [for]="field.key">{{field.name}}</label>
            </div>
          </ng-template>
        </p-accordionTab>
      </span>

      <span *ngIf="['challenges-sourcing'].includes(this.tableType)">
        <p-card [(selected)]="challengeActiveState[0]">
          <ng-template pTemplate="header">
          </ng-template>
          <ng-template pTemplate="content">
            <div *ngFor="let field of challengeFields" class="p-field-checkbox">
              <p-checkbox [value]="field" [(ngModel)]="selectedChallengeFields">
              </p-checkbox>
              <label [for]="field.key">{{field.name}}</label>
            </div>
          </ng-template>
        </p-card>
      </span>
    </p-accordion>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple
      [label]="'Duplicate ' + (tableType === 'challenges-sourcing' ? 'Challenge' : 'Item') + ' into Sourcing Group'"
      icon="pi pi-check" class="p-button-text" (click)="duplicateItemForNewRow()"
      [disabled]="(tableType === 'challenges-sourcing' ? !selectedChallengeFields?.length : !selectedItemOrganicFields?.length)"></button>
  </ng-template>

</p-dialog>

<p-confirmDialog key="deleteMultiple" header="Delete Multiple Records"
  icon="pi pi-exclamation-triangle"></p-confirmDialog>