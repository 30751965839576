import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import ItemTypeDTO from '../../dtos/ItemTypeDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-item-type-form-v3',
  templateUrl: './item-type-form-v3.component.html',
  styleUrls: ['./item-type-form-v3.component.sass']
})
export class ItemTypeFormV3Component extends BaseDynamicFormComponent implements OnInit {

  itemTypeRecord: ItemTypeDTO = new ItemTypeDTO();

  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }

  /**
   * Item Type Form V3 Initial Implementation
   */

  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Item Type Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: "Start",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: true,
            label: 'Start',
            disabled: false,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: true,
            label: 'End',
            disabled: false,
            calendarMinDate: this.itemTypeRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Item Types";
    this.viewRoute = 'item-types';
    this.isLoading = false;
  }

}
