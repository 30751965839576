import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-users-and-security',
  templateUrl: './users-and-security.component.html',
  styleUrls: ['./users-and-security.component.sass']
})
export class UsersAndSecurityComponent implements OnInit {

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    if (!this.authService.loadedPermissions()) {
      this.authService.loadPermissions().subscribe();
    }
  }
}
