/**
 * Table Bulk Edit DTO
 */
export default class TableBulkEdit
{
    /**
     * List of rows to edit
     */
    rows: Array<any> = [];
    /**
     * Bulk stage
     */
    stage: string = "";
    /**
     * Field object
     */
    field: any = {};
    /**
     * Conflict value
     */
    conflict: any;
    /**
     * Form value
     */
    form: any;
    /**
     * Bulk group
     */
    group: any;
    /**
     * Date value
     */
    today: Date | string;
       /**
    * Final Payloads
    */
    failedPayloads: any;
    /**
     * Success counts
     */
    successCount: number;
    /**
     * List of options
     */
    options: Array<any> = [];
}