<div>
  <h1>Bundle Assets Table</h1>
</div>
<p-tabView>
  <p-tabPanel header="Collections">
    <app-bundle-view-table [entity]="'collections'" name="Collections"></app-bundle-view-table>
  </p-tabPanel>
  <p-tabPanel header="Progression Levels">
    <app-bundle-view-table [entity]="'progression-levels'" name="Progression Levels"></app-bundle-view-table>
  </p-tabPanel>
  <p-tabPanel header="Items">
    <app-bundle-view-table [entity]="'items'" name="Items"></app-bundle-view-table>
  </p-tabPanel>
  <p-tabPanel header="Categories">
    <app-bundle-view-table [entity]="'categories'" name="Categories"></app-bundle-view-table>
  </p-tabPanel>
  <p-tabPanel header="Climates">
    <app-bundle-view-table [entity]="'climates'" name="Climates"></app-bundle-view-table>
  </p-tabPanel>
  <p-tabPanel header="Challenges">
    <app-bundle-view-table [entity]="'challenges'" name="Challenges"></app-bundle-view-table>
  </p-tabPanel>
  <p-tabPanel header="Loading screens">
    <app-bundle-view-table [entity]="'loading-screens'" name="Loading screens"></app-bundle-view-table>
  </p-tabPanel>
  <p-tabPanel header="Currencies">
    <app-bundle-view-table [entity]="'currencies'" name="Currencies"></app-bundle-view-table>
  </p-tabPanel>
  <p-tabPanel header="Titles">
    <app-bundle-view-table [entity]="'titles'" name="Titles"></app-bundle-view-table>
  </p-tabPanel>
</p-tabView>