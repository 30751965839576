import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';

@Component({
  selector: 'app-perk-table-ag-grid',
  templateUrl: './perk-table-ag-grid.component.html',
  styleUrls: ['./perk-table-ag-grid.component.sass']
})
export class PerkTableAgGridComponent implements OnInit {
  public entity: string = 'perks';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};
  public options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {
    this.columnDefs = [
        {
            headerName: 'Actions',
            field: 'actions',
            cellRenderer: ActionsCellRendererComponent,
            pinned: 'right', // Pin the column to the right
            width: 120, // Set the width of the column
            resizable: true,
            cellRendererParams: {
              enableDuplicateFeature: this.tableParams?.enableDuplicateFeature || false
            }
          },
          {
            headerName: 'ID',
            field: 'id',
            width: 125,
            headerCheckboxSelection: true,
            checkboxSelection: true,
            sortable: true,
            initialSort: 'desc',
            sortIndex: 0,
            initialSortIndex: 0,
            sort: 'desc',
            sortingOrder: ['asc','desc'],
            unSortIcon: false,
            filter: 'agNumberColumnFilter',
            filterParams: {
              filterOptions: [
                'equals',
                'notEqual',
                'lessThan',
                'lessThanOrEqual',
                'greaterThan',
                'greaterThanOrEqual',
                'inRange'
              ]
            },
            resizable: true,
            floatingFilter: true,
            suppressSizeToFit: true,
          },
          {
            headerName: 'Name',
            field: 'name',
            cellRendererParams: {
              entity: this.entity
            },
            cellRenderer: LinkCellRendererComponent,
            sortable: true,
            unSortIcon: true,
            resizable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            cellEditor: 'agTextCellEditor',
            type: 'editableColumn',
          },
          {
            headerName: 'Enabled',
            field: 'enabled',
            cellRenderer: TextCellRendererComponent,
            cellRendererParams: {
              onLabel: 'Enabled',
              offLabel: 'Not Enabled',
              isBoolean: true
            },
            sortable: true,
            unSortIcon: true,
            resizable: true,
            filter: 'agSetColumnFilter',
            filterParams: {
              values: [true, false],
              valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
            },
            floatingFilter: true,
            cellEditor: BooleanEditorComponent,
            type: 'editableColumn',
          },
      {
        field: 'perkType',
        headerName: 'Perk Type',
        filter: 'agSetColumnFilter',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        floatingFilter: true,
        width: 200,
        cellRenderer: TextCellRendererComponent,
        valueFormatter: (params: any) => {
          const typeMapping = [
            { label: 'Free Use', value: 'FREE_USE' },
            { label: 'Gifting Tree Increase', value: 'GIFTING_TREE_INCREASE' }
          ];
          const matchedType = typeMapping.find(type => type.value === params.value);
          return matchedType ? matchedType.label : 'Unknown';
        }
      },
      {
        field: 'clubPointRequirement',
        headerName: 'Club Points Required',
        filter: 'agNumberColumnFilter',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        floatingFilter: true,
        width: 200,
        cellRenderer: TextCellRendererComponent
      },
      {
        field: 'multiplier',
        headerName: 'Multiplier',
        filter: 'agNumberColumnFilter',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        floatingFilter: true,
        width: 200,
        cellRenderer: TextCellRendererComponent
      },
      {
        field: 'rewards_ref',
        headerName: 'Weekly Reward',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true
      },
      {
        headerName: 'Is Daily Reward',
        field: 'isDailyReward',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        field: 'env',
        headerName: 'Environment(s)',
        filter: 'agSetColumnFilter',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        floatingFilter: true,
        width: 200,
        cellRenderer: TextCellRendererComponent
      },
      {
        field: 'perkDailyReward',
        headerName: 'Perk Daily Reward',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        floatingFilter: true,
        width: 300,
        autoHeight: true
      }
    ];

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Perk Type', id: 'perkType', type: 'dropdown',
        options: [
          { label: 'Free Use', value: 'FREE_USE' },
          { label: 'Gifting Tree Increase', value: 'GIFTING_TREE_INCREASE' }
        ]
      },
      {
        name: 'Value', id: 'value', type: 'number'
      },
      {
        name: 'Club Point Requirement', id: 'clubPointRequirement', type: 'number'
      },
      {
        name: 'Is Daily Reward', id: 'isDailyReward', type: 'boolean'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'perks',
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }
} 