import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DynamicTableService
{


  async isAttributeUpdated(
    original: any,
    updated: any,
    key: string
  ): Promise<boolean> {
    if (!original.hasOwnProperty(key)) {
      return true;
    }

    const originalValue = original[key];
    const updatedValue = updated[key];

    if (
      typeof originalValue === "object" &&
      originalValue !== null &&
      typeof updatedValue === "object" &&
      updatedValue !== null
    ) {
      if (
        originalValue.hasOwnProperty("_id") &&
        updatedValue.hasOwnProperty("_id")
      ) {
        return originalValue._id !== updatedValue._id;
      }

      const nestedResult = await this.extractUpdatedAttributes(originalValue, updatedValue);
      return Object.keys(nestedResult).length > 0;
    }

    return originalValue !== updatedValue;
  }

  async extractUpdatedAttributes(
    original: any,
    updated: any
  ): Promise<any> {
    const result: any = {};

    // Copy the id and _id attributes if they exist
    if (original.hasOwnProperty("id") && updated.hasOwnProperty("id")) {
      result.id = updated.id;
    }
    if (original.hasOwnProperty("_id") && updated.hasOwnProperty("_id")) {
      result._id = updated._id;
    }

    // Loop through the updated object's properties
    for (const key in updated) {
      if (updated.hasOwnProperty(key)) {
        if (await this.isAttributeUpdated(original, updated, key)) {
          result[key] = updated[key];
        }
      }
    }

    return result;
  }

}
