import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-field-group',
  templateUrl: './status-field-group.component.html',
  styleUrls: ['./status-field-group.component.sass']
})
export class StatusFieldGroupComponent {
  @Input() statusFieldGroup: any[] = [];
  @Input() entity: any;
}

