import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-entity-history',
  templateUrl: './dynamic-entity-history.component.html',
  styleUrls: ['./dynamic-entity-history.component.sass']
})
export class DynamicEntityHistoryComponent implements OnInit {
  constructor () {}

  /**
   * Dynamic Entity History Component Initialization
   */
  async ngOnInit() {}
}

