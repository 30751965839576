<ng-container *ngFor="let key of keys">
  <ng-container *ngIf="isObject(record[key])">
    <strong>{{camelCaseToWords(key)}}:</strong>
    <ul>
      <li>
        <app-dynamic-list [record]="record[key]"></app-dynamic-list>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="isArray(record[key])">
    <strong>{{camelCaseToWords(key)}}:</strong> <!-- Move the key outside the <ul> tag -->
    <ng-container *ngFor="let item of record[key]">
      <ng-container *ngIf="isObject(item)">
        <ul> <!-- Move the <ul> tag inside the *ngIf="isObject(item)" -->
          <li>
            <app-dynamic-list [record]="item"></app-dynamic-list>
          </li>
        </ul>
        <hr *ngIf="!isArray(item)">
      </ng-container>
      <ng-container *ngIf="isArray(item) || !isObject(item)">
        <ul>
          <li>
            <ng-container *ngIf="isArray(item)">
              <app-dynamic-list [record]="item"></app-dynamic-list>
            </ng-container>
            <ng-container *ngIf="!isArray(item) && !isObject(item)">
              <strong>{{camelCaseToWords(key)}}:</strong>
              <ng-container *ngIf="isLink(item)">
                <a href="{{item}}" [ngStyle]="{ color: 'var(--primary-color)' }" target="_blank">{{item}}</a>
              </ng-container>
              <ng-container *ngIf="!isLink(item)">
                {{item}}
              </ng-container>
            </ng-container>
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!isObject(record[key]) && !isArray(record[key])">
    <li>
      <strong>{{camelCaseToWords(key)}}:</strong>
      <ng-container *ngIf="isLink(record[key])">
        <a href="{{record[key]}}" [ngStyle]="{ color: 'var(--primary-color)' }" target="_blank">{{record[key]}}</a>
      </ng-container>
      <ng-container *ngIf="!isLink(record[key])">
        {{record[key]}}
      </ng-container>
    </li>
  </ng-container>
</ng-container>
