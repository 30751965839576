<div *ngFor="let value of record[field.key]">
    <ul>
      <li class="p-mb-2">
        <strong>Filter Model:</strong> {{value.filterModel}} <br>
        <strong>Filter Table Name:</strong> 
        <a href="/{{value.filterTableName}}" [ngStyle]="{ color: 'var(--primary-color)' }">
          {{value.filterTableName}}
        </a><br>
        <span *ngIf="value.filter_ref && value.filter_ref.id">
          <strong>Filter Details:</strong>({{value.filter_ref.id}}) {{value.filter_ref.name}}<br>
        </span>
      </li>
    </ul>
  </div>
