import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import BaseQueryPayloadDTO from '../dtos/BaseQueryPayloadDTO';

@Injectable({
  providedIn: 'root'
})
export class CommonEntityService
{
  private readonly apiURLprefix : string = '/api';

  constructor(private http: HttpClient) {}

  /**
   * Retrieves all records of a given entity.
   *
   * @param controller Controller API route
   */
  async findAll(controller: string): Promise<any>
  {
    return firstValueFrom(this.http.get<any>(`${this.apiURLprefix}/${controller}`));
  }

  /**
   * Retrieves all records of an entity with a given query.
   *
   * @param controller Controller API route
   * @param payload Query data
   */
  async findAllWithQuery(controller: string, payload: BaseQueryPayloadDTO): Promise<any>
  {
    return firstValueFrom(this.http.post<any>(`${this.apiURLprefix}/${controller}`, payload));
  }

  /**
   * Retrieves a record by id.
   *
   * @param controller The name of the controller you want to call.
   * @param id The id of the record to be retrieved
   */
  async findOne(controller: string, id: number): Promise<any>
  {
    return firstValueFrom(this.http.get<any>(`${this.apiURLprefix}/${controller}/${id}`));
  }

  /**
   * Retrieves a record by a given query.
   *
   * @param controller The name of the controller you want to call.
   * @param payload Query data
   */
  async findOneWithQuery(controller: string, payload: BaseQueryPayloadDTO): Promise<any>
  {
    return firstValueFrom(this.http.post<any>(`${this.apiURLprefix}/${controller}/find`, payload));
  }

  /**
   * Creates a new record
   *
   * @param controller Controller API route
   * @param payload Record data
   */
  async create(controller: string, payload: any): Promise<any>
  {
    return firstValueFrom(this.http.post<any>(`${this.apiURLprefix}/${controller}/add`, payload));
  }

  /**
   * Updates an existing record
   *
   * @param controller Controller API route
   * @param recordId Id of the record
   * @param payload Record data
   */
  async update(controller: string, recordId: number, payload: any, isV2: boolean = false): Promise<any>
  {
    let updatePath = isV2 ? 'update/v2' : 'update'
    return firstValueFrom(this.http.patch<any>(`${this.apiURLprefix}/${controller}/${updatePath}/${recordId}`, payload));
  }

  async duplicate(controller: string, payload: any): Promise<any>
  {
    return firstValueFrom(this.http.post<any>(`${this.apiURLprefix}/${controller}/duplicate`, payload));
  }
  /**
   * Cleans the relationship between items and collections for the specified collection ID.
   *
   * @param controller Controller API route
   * @param collectionId The ID of the collection to clean the relationship with items.
   */
  async cleanItemCollectionRelationship(controller: string, collectionId: number): Promise<any> {
    return firstValueFrom(this.http.get<any>(`${this.apiURLprefix}/${controller}/${collectionId}`));
  }

  async cleanChallengeCollectionRelationship(controller: string, collectionId: number): Promise<any> {
    return firstValueFrom(this.http.get<any>(`${this.apiURLprefix}/${controller}/${collectionId}`));
  }

  async createDynamicEntity(entityName: string, data: any): Promise<any> {
    const url = `api/dynamic-entities/manager/${entityName}/add`;
    return this.http.post(url, data).toPromise();
  }

  async updateDynamicEntity(entityName: string, id: number, data: any, checkConflicts: boolean = true, fetchedAt?: Date): Promise<any> {
    const url = `api/dynamic-entities/manager/update/v2/${entityName}/${id}`;
    const payload = {
      dto: data,
      fetchedAt: fetchedAt,
      checkConflicts: checkConflicts
    };
    return this.http.patch(url, payload).toPromise();
  }

  async getDynamicEntity(entityName: string, id: number): Promise<any> {
    const url = `api/dynamic-entities/manager/${entityName}/${id}`;
    return this.http.get(url).toPromise();
  }

  /**
   * Retrieves a dynamic entity record by a given query.
   *
   * @param entityName The name of the dynamic entity
   * @param id The id of the record
   * @param query Additional query parameters
   */
  async findOneWithQueryDynamicEntities(entityName: string, id: number, query?: any): Promise<any> {
    const baseUrl = `api/dynamic-entities/manager/${entityName}/${id}`;
    const url = query ? `${baseUrl}?${new URLSearchParams(query).toString()}` : baseUrl;
    return this.http.get(url).toPromise();
  }

}
