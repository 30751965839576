import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import SeasonPassDTO from '../../dtos/SeasonPassDTO';
import * as _ from 'lodash';
import RewardsTrackDTO from '../../../rewards-track/dtos/RewardsTrackDTO';
import DefaultTracks from '../../dtos/defaultTracks';

@Component({
  selector: 'season-pass-form-tracks-custom-field',
  templateUrl: './season-pass-form-tracks-custom-field.component.html',
  styleUrls: ['./season-pass-form-tracks-custom-field.component.sass']
})
export class SeasonPassFormTracksCustomFieldComponent implements OnInit 
{
  @Input() seasonPassFreeTrack: any;
  @Input() seasonPassPaidTrack: any;
  @Input() seasonPassRecord: SeasonPassDTO = new SeasonPassDTO();
  @Input() freeOptions: Array<any> = [];
  @Input() paidOptions: Array<any> = [];
  @Input() isEdit: boolean = false;
  @Output() onChange = new EventEmitter<any>();
  @Output() onCreateRewardsTrackRecord = new EventEmitter<any>();
  @Output() onCancelRewardsTrackRecord = new EventEmitter<any>();
  freeTrack: RewardsTrackDTO = new RewardsTrackDTO();
  paidTrack: RewardsTrackDTO = new RewardsTrackDTO();
  defaultTracks: DefaultTracks = new DefaultTracks();
  isLoading: boolean = true;
  createNewFreeTrack: boolean = false;
  createNewPaidTrack: boolean = false;

  constructor() { }

  /**
   * Season Pass Form Tracks Custom Field Component Initialization
   */
  ngOnInit() 
  {
    if(this.seasonPassFreeTrack)
    {
      let freeTrack = this.freeOptions.find((x) => x._id == this.seasonPassFreeTrack);
      this.freeTrack = _.cloneDeep(freeTrack);
    }
    if(this.seasonPassPaidTrack)
    {
      let paidTrack = this.paidOptions.find((x) => x._id == this.seasonPassPaidTrack);
      this.paidTrack = _.cloneDeep(paidTrack);
    }

    this.isLoading = false;
  }

  /**
   * Handle Create Rewards Track Action
   * 
   * @param isFreeTrack Flag that sets whether or not is a new free track record.
   */
  onCreateRewardsTrack(isFreeTrack: boolean)
  {
    if(isFreeTrack)
    {
      this.seasonPassRecord.free = null;
      this.freeTrack = new RewardsTrackDTO();
      this.freeTrack.free = this.defaultTracks.FreeDefaultTrack;
      this.freeTrack.stageMilestone = this.defaultTracks.FreeDefaultStageMilestones;
      this.freeTrack.type = 3;
      this.createNewFreeTrack = true;
    }
    else
    {
      this.seasonPassRecord.paid = null;
      this.paidTrack = new RewardsTrackDTO();
      this.paidTrack.paid = this.defaultTracks.PaidDefaultTrack;
      this.paidTrack.stageMilestone = this.defaultTracks.PaidDefaultStageMilestones;
      this.paidTrack.type = 4;
      this.createNewPaidTrack = true;
    }

    this.onCreateRewardsTrackRecord.emit(this.seasonPassRecord);
  }

  /**
   * Handle Cancel Rewards Track Action
   * 
   * @param isFreeTrack Flag that sets whether or not is a free track record.
   */
  onCancelRewardsTrack(isFreeTrack: boolean)
  {
    if(isFreeTrack)
    {
      this.freeTrack = new RewardsTrackDTO();
      this.createNewFreeTrack = false;
    }
    else
    {
      this.paidTrack = new RewardsTrackDTO();
      this.createNewPaidTrack = false;
    }
    this.onCancelRewardsTrackRecord.emit(this.seasonPassRecord);
  }

  /**
   * Handle Changes on Season Pass Record Input variable
   * 
   * @param changes Record changes.
   */
  ngOnChanges(changes: SimpleChanges) 
  {
    if(changes.seasonPassFreeTrack && changes.seasonPassFreeTrack.previousValue != changes.seasonPassFreeTrack.currentValue)
    {
      this.handleTrackData(true, changes.seasonPassFreeTrack.currentValue);
    } 
    
    if(changes.seasonPassPaidTrack && changes.seasonPassPaidTrack.previousValue != changes.seasonPassPaidTrack.currentValue)
    {
      this.handleTrackData(false, changes.seasonPassPaidTrack.currentValue);
    } 
  }

  /**
   * Handles Track Data object
   * 
   * @param isFreeTrack Flag that sets whether or not is a free track
   * @param value Track value.
   */
  handleTrackData(isFreeTrack: boolean, value: any)
  {
    this.isLoading = true;
    if(isFreeTrack)
    {
      if(value)
      {
        let freeTrack = this.freeOptions.find((x) => x._id == value);
        this.freeTrack = _.cloneDeep(freeTrack);
        
        if(this.freeTrack && this.freeTrack.id)
        {
          this.createNewFreeTrack = false;
        }
      }
    }
    else
    {
      if(value)
      {
        let paidTrack = this.paidOptions.find((x) => x._id == value);
        this.paidTrack = _.cloneDeep(paidTrack);

        if(this.paidTrack && this.paidTrack.id)
        {
          this.createNewPaidTrack = false;
        }
      }
    }
    this.isLoading = false;
  }

  /**
   * Handle Record Changes from child component.
   *
   * @param field Field that changed.
   * @param event Event value.
   */
  onRecordChange(field: any, event: any)
  {
    this.onChange.emit({ field: field, value: field == 'freeObject' ? this.freeTrack : this.paidTrack });
  }
}