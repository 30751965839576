import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nurture-stages-field',
  templateUrl: './nurture-stages-field.component.html',
  styleUrls: ['./nurture-stages-field.component.sass']
})
export class NurtureStagesFieldComponent implements OnInit {
  @Input() record: any;
  @Input() field: any;

  constructor() { }

  ngOnInit(): void {
  }

}
