import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FieldTransformerService } from '../dynamic-entity-form-component/services/field-transformer.service';
import { ActivatedRoute } from '@angular/router';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { ColDef } from 'ag-grid-community';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';

@Component({
  selector: 'app-dynamic-entity-table',
  templateUrl: './dynamic-entity-table.component.html',
  styleUrls: ['./dynamic-entity-table.component.sass']
})
export class DynamicEntityTableComponent implements OnInit {
  
  public columnDefs: ColDef[] = [];
  public entity: string;
  public title: string;
  public viewRoute: string;
  isLoading: boolean = true;
  public columnSelection: string;
  public modelEntity: string;
  
  constructor(
    private http: HttpClient,
    private fieldTransformer: FieldTransformerService,
    private route: ActivatedRoute,
    private agGridToolsService: AgGridToolsService,
  ) {
    
  }

  async ngOnInit() {
    console.log('Initializing DynamicEntityTableComponent');
    
    this.route.params.subscribe(params => {
      this.entity = params['entityName'];
      console.log('Entity from URL:', this.entity);
    });


    try {
      console.log('Fetching metadata fields for entity:', this.entity);
      const metadataFields = await this.http
        .get<any[]>(`http://localhost:3000/api/metadata/${this.entity}/fields`)
        .toPromise();

      console.log('Received metadata fields:', metadataFields);

      if (metadataFields) {
        const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);
        console.log('Default columns:', defaultColumns);
        
        const dynamicColumns = metadataFields.map(field => ({
          headerName: field.name.charAt(0).toUpperCase() + field.name.slice(1).replace(/_/g, ' '),
          field: field.name,
          sortable: true,
          unSortIcon: true,
          resizable: true,
          filter: 'agTextColumnFilter',
          floatingFilter: true,
          autoHeight: true,
          cellEditor: 'agTextCellEditor',
          type: 'editableColumn'
        }));
        console.log('Dynamic columns:', dynamicColumns);

        const uniqueColumns = [...defaultColumns];
        dynamicColumns.forEach(dynCol => {
          if (!uniqueColumns.some(col => col.field === dynCol.field)) {
            uniqueColumns.push(dynCol);
          }
        });

        this.columnDefs = uniqueColumns;
        console.log('Final unique column definitions:', this.columnDefs);

        this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
        console.log('Column selection:', this.columnSelection);
      }

      this.title = `${this.entity} Table`;
      this.viewRoute = this.entity;
      this.isLoading = false;
    } catch (error) {
      console.error('Error in DynamicEntityTableComponent:', error);
      this.isLoading = false;
    }
  }
}
