<div [style]="{ margin: '1em' }" *ngIf="entity">
  <div class="p-grid nested-grid p-mt-3">
    <div class="p-col-12 p-lg-12">
      <p-card>
        <ng-container [ngSwitch]="getLayoutType(entity.type)">

          <!-- Login Rewards Day 1-7 -->
          <ng-container *ngSwitchCase="'7day'">
            <div class="grid-container">
              <div *ngFor="let dayReward of dailyRewards" class="grid-item">
                <app-reward-card *ngIf="dayReward.day !== 7" [title]="'Day ' + dayReward.day"
                  [rewards]="dayReward.rewards">
                </app-reward-card>
              </div>
              <div class="grid-item-large">
                <app-reward-card [title]="'Day 7'" [rewards]="day7Rewards"></app-reward-card>
              </div>
            </div>
          </ng-container>

          <!-- Login Rewards Day 30 -->
          <ng-container *ngSwitchCase="'30d'">
            <div class="grid-item-large">
              <app-reward-card [title]="'Day 30'" [rewards]="entity.dailyRewardDay30"></app-reward-card>
            </div>
          </ng-container>

          <!-- Generic Rewards Track (p-timeline) -->
          <ng-container *ngSwitchCase="'timeline'">
            <app-reward-timeline [parsedSeasonPass]="parsedSeasonPass"></app-reward-timeline>
          </ng-container>

          <!-- Endless Offer -->
          <ng-container *ngSwitchCase="'endless-offer'">
            <app-endless-offer-track-v2 [eoTrack]="entity" [parentEntity]="''"></app-endless-offer-track-v2>
          </ng-container>

          <!-- Perk Daily Rewards -->
          <ng-container *ngSwitchCase="'perk-daily'">
            <div class="grid-container">
              <div *ngFor="let dayReward of dailyRewards" class="grid-item">
                <app-reward-card [title]="'Day ' + dayReward.day" [rewards]="dayReward.rewards">
                </app-reward-card>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </p-card>
    </div>
  </div>
</div>