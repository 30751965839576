import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Achievements Collection DTO
 */
export default class AssetDTO extends BaseEntityDto
{
  /**
   * Type of entity that the asset belongs to
   */
  entityType: string;
  /**
   * Type of image that the asset belongs to
   */
  imageType: string;
  /**
   * Path of the image (Plastic)
   */
  path: string;
  lastBuilt: string;
  image_versions: any;
  asset_versions: any;
  isAssetUpToDate: boolean;
  lastHash: string;
  assetType: string;
  entityTypeText: string;
  assetTypeText: string;
  buildData: any;
  buildStatus: any;

}
