<div class="p-grid p-mt-3" *ngIf="entity">
    <div class="p-col-12">
      <p-card>
        <div class="p-grid">
          <h2 class="p-ml-5 p-d-inline p-col-5">Free Track</h2>
          <h2 class="p-ml-5 p-d-inline p-col-6">Paid Track</h2>
        </div>
        <p-timeline [value]="parsedSeasonPass">
          <!-- Free Rewards -->
          <ng-template pTemplate="marker" let-event>
            <span class="custom-marker shadow-3">
              <p-badge [value]="event.stage"></p-badge>
            </span>
          </ng-template>
          <ng-template pTemplate="opposite" let-event>
            <p-card *ngIf="event.free && event.free.length > 0" styleClass="p-my-2 p-shadow-7">
              <div class="p-my-1" *ngFor="let reward of event.free">
                <img class="item-thumbnail" [src]="getRewardImage(reward)" alt="Reward Image" />
                <div class="reward-info">
                  <strong>Reward: </strong>{{ reward.lineItemType }}
                  <br />
                  <strong>Name: </strong>{{ reward.id.name }}
                  <br />
                  <strong>Quantity: </strong>{{ reward.c }}
                </div>
                <p-divider></p-divider>
              </div>
            </p-card>
          </ng-template>
          <!-- Paid Rewards -->
          <ng-template pTemplate="content" let-event>
            <p-card *ngIf="event.paid && event.paid.length > 0" styleClass="p-my-2 p-shadow-7">
              <div class="p-my-1" *ngFor="let reward of event.paid">
                <img class="item-thumbnail" [src]="getRewardImage(reward)" alt="Reward Image" />
                <div class="reward-info">
                  <strong>Reward: </strong>{{ reward.lineItemType }}
                  <br />
                  <strong>Name: </strong>{{ reward.id.name }}
                  <br />
                  <strong>Quantity: </strong>{{ reward.c }}
                </div>
                <p-divider></p-divider>
              </div>
            </p-card>
          </ng-template>
        </p-timeline>
      </p-card>
    </div>
  </div>
  