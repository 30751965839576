import { Component, Input, OnInit } from '@angular/core';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';

@Component({
    selector: 'app-misc-image-array-build', 
    templateUrl: './misc-image-array-build.component.html',
  })
export class MiscImageArrayBuild implements OnInit {
  @Input() field: any; 
  @Input() assetRecords: any[]; 

  assetRecord: any;


  get buildType(): typeof BuildType {
    return BuildType;
  }

  get assetType(): typeof AssetTypes {
    return AssetTypes;
  }

  ngOnInit(): void {
    if (!this.field || !this.assetRecords) {
      console.error('The field or asset records are not defined');
      return;
    }

  }

  getAssetType(assetType: string): string {
    return assetType || "Processed Asset Type";
  }

  isUptoDate(entity: any): boolean {
    return true; 
  }

  getLastPromotedHash(entity: any): string {
    return entity?.lastPromotedHash || '';
  }

  getDynamicURL(id: string): string {
    return `/miscellaneous-build/${id}`;
  }

  openInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  getUpdatedBuildData(event: any): void {
    console.log('Build event received:', event);
  }
}
