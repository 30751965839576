import { Component, OnInit } from '@angular/core';
import DynamicFormDTO from '../../../dynamic-form-v2/dtos/DynamicFormDTO';

@Component({
  selector: 'app-base-dynamic-view',
  templateUrl: './base-dynamic-view.component.html'
})
/**
 * Base Dynamic View Component
 */
export class BaseDynamicViewComponent implements OnInit
{
  title: string = "";
  isLoading: boolean = true;
  fields:  Array<DynamicFormDTO> = [];
  sidebarFields:  Array<DynamicFormDTO> = [];
  layout: string = 'full-screen';
  entityType: string;
  hideEnabledField: boolean = false;
  viewRoute: string;

  constructor() { }

  /**
   * Base Dynamic View Component Initialization
   */
  ngOnInit()
  {
  }
}
