/**
 * Build Types Enum
 */
export enum BuildType
{
    Items = 1,
    Levels = 2,
    Images = 3,
    Nurture = 4,
    Icons = 5,
    Videos = 6,
    Audio = 7,
    Coop = 8,
    Minigames = 9,
}
