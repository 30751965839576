import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { SettingsService } from '../../entities/settings/services/settings.service';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private mainMenuSubject = new BehaviorSubject<MenuItem[]>([]);
  mainMenu$ = this.mainMenuSubject.asObservable();
  constructor(private settingsService: SettingsService) {}

  private hasPermissions(userRoles: any[], visibleConfig: any): boolean {
    if (!visibleConfig) return true; 
    const { permissions, entity } = visibleConfig;
  
    if (!permissions || !entity) return true; 
  
    return userRoles.some((role: any) => permissions.includes(role.name));
  }
  
  private processMenuVisibility(items: any[], userRoles: any[]): any[] {
    return items.map((item: any) => {
      if (item.visible && typeof item.visible === 'object') {
        item.visible = this.hasPermissions(userRoles, item.visible);
      } else {
        item.visible = true; 
      }
  
      if (item.items) {
        item.items = this.processMenuVisibility(item.items, userRoles);
      }
  
      return item;
    });
  }
  async fetchMenuItems(): Promise<MenuItem[]> {
    try {
      const settingsResult = await this.settingsService.getSettingByQuery({ query: { name: 'menu' } });
      const mainMenuData = settingsResult?.mainMenu?.sections || [];
  
      let formattedMenu: MenuItem[] = mainMenuData.map((section: any) => {
        let sectionItems = section.items
          ? Object.values(section.items).map((submenu: any) => {
              if (submenu.separator) {
                return { separator: true};
              } else {
                return {
                  label: submenu.label,
                  icon: submenu.icon || null,
                  url: submenu.url || null,
                  items: submenu.items
                    ? submenu.items.map((item: any) => ({
                        label: item.label,
                        icon: item.icon || null,
                        url: item.url || null,
                        target: item.target || null,
                        visible: item.visible !== undefined ? item.visible : true,  
                      }))
                    : [], 
                  visible: submenu.visible !== undefined ? submenu.visible : true,  
                };
              }
            })
          : [];
  
        return {
          label: section.label,
          icon: section.icon || null,
          items: sectionItems,
        };
      });
  
      this.mainMenuSubject.next(formattedMenu);
      return formattedMenu;
    } catch (error) {
      return [];
    }
  }

  async fetchSideMenuItems(): Promise<any[]> {
    try {
      const settingsResult = await this.settingsService.getSettingByQuery({ query: { name: 'menu' } });
      const sideMenuData = settingsResult?.sideMenu?.sections || [];
      const userRoles = settingsResult?.userData?.roles || [];

      return sideMenuData.map((section: any) => {
        const items = Object.values(section.items).map((item: any) => {
          if (item.type === 'collapsible') {
            return {
              label: item.label,
              type: item.type,
              permission: item.permission,
              expanded: item.expanded || false,
              subMenu: item.subMenu.map((sub: any) => ({
                label: sub.label,
                route: sub.route,
              })),
              visible: item.visible,
            };
          } else {
            return {
              label: item.label,
              type: item.type,
              icon: item.icon,
              route: item.route,
              action: item.action,
              permission: item.permission,
              visible: item.visible,
            };
          }
        });

        const processedMenu = this.processMenuVisibility(items, userRoles);

        return {
          label: section.label,
          items: processedMenu.filter((menuItem: any) => menuItem.visible),
        };
      });
    } catch (error) {
      console.error('Error fetching side menu items:', error);
      return [];
    }
  }

  async updateSideMenuItems(newItems: any[]): Promise<void> {
    try {
      const menuSettings = await this.settingsService.getSettingByQuery({ query: { name: 'menu' } });

      if (menuSettings) {
        const updatedMenu = {
          ...menuSettings,
          sideMenu: {
            sections: [
              {
                items: newItems,
              },
            ],
          },
        };

        delete updatedMenu._id;
        await this.settingsService.updateSetting(menuSettings.id, updatedMenu);
      }
    } catch (error) {
      console.error('Error updating side menu items:', error);
    }
  }
  async updateMainMenu() {
    const newMenu = await this.fetchMenuItems();
    this.mainMenuSubject.next(newMenu);
  }
}
