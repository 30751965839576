import { Component, Input, OnInit } from '@angular/core';
import { BaseViewFieldType } from '../dynamic-view-v2/enums/BaseViewFieldType';
import * as moment from 'moment-timezone';
import { UtilitiesService } from '../../services/utilities.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-base-view-field',
  templateUrl: './base-view-field.component.html',
  styleUrls: ['./base-view-field.component.sass']
})
export class BaseViewFieldComponent implements OnInit {
  @Input() viewTypes: BaseViewFieldType[];
  @Input() field: any;
  @Input() record: any;
  @Input() options: any;
  @Input() keysToRender: Array<string> = [];

  checkTimezone: boolean = false;

  BaseViewFieldType = BaseViewFieldType;

  formatTimeTaken(value: any): string {
    if (value !== null && value !== undefined) {
      const timeTaken = parseFloat(value);
      const minutes = Math.floor(timeTaken);
      const seconds = Math.round((timeTaken - minutes) * 60);
      return `${minutes}m ${seconds}s`;
    }
    return '';
  }
  
  getLabelForValue(value: number): string {
    const option = this.field.options?.values?.find((o: any) => o.value === value);
    return option ? option.name : 'Unknown';
  }

  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
  
  get keys() {
    if (this.record) {
      // If keysToRender has elements, filter the keys; otherwise, return all keys
      return this.keysToRender?.length > 0
        ? Object.keys(this.record).filter(key => this.keysToRender.includes(key))
        : Object.keys(this.record);
    } else {
      return [];
    }
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
  }
  // ...
isArray(value: any): boolean {
  return Array.isArray(value);
}
isLink(value: any): boolean {
  if (typeof value !== 'string') {
    return false;
  }

  const regex = /^https?:\/\/[^ "]+$/;
  return regex.test(value);
}

  camelCaseToWords(key: string): string {
    return key
      .replace(/([A-Z])/g, ' $1') // Insert a space before each capital letter
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  }

  /**
  * Returns the label for a dropdown field.
  * @param record The record containing the field.
  * @param field The field to get the label for.
  * @returns The label for the dropdown field.
 **/
  
  getDropdownLabel(record: any, field:any) {
    if (record && record[field]) {
        return `${record[field].name} (${record[field].id})`;
    }
    return null;
  }

  constructor(
    private utilitiesService: UtilitiesService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
  this.checkTimezone = this.utilitiesService.isUserLocalTimeZonePST();
  }

  localTime(date: any) {
    return (moment(date).local().format('MMM D, YYYY, hh:mm A z'))
  }

  getBooleanLabel(value: boolean, options: { true: string; false: string }): string {
    return value ? options.true : options.false;
  }

  /**
   * Converts URLs in text to clickable links that open in a new tab
   * @param text The text containing URLs to convert
   * @returns The text with URLs converted to clickable links
   */
  convertUrlsToLinks(text: string): string {
    if (!text) return '';
    
    // This is a more robust approach that works with complex HTML
    // We'll use a regex-based solution that can handle HTML tags
    
    // Split the HTML into tokens (tags and text)
    const tokenRegex = /(<\/?[^>]+>)|([^<]+)/g;
    const tokens = text.match(tokenRegex) || [];
    
    // Process each token
    const processedTokens = tokens.map(token => {
      // If this is an HTML tag, leave it as is
      if (token.startsWith('<') && token.endsWith('>')) {
        // Skip processing if it's an anchor tag or contains href/src attributes
        if (token.match(/<a\s|<\/a>|href=|src=/i)) {
          return token;
        }
        return token;
      }
      
      // Otherwise, convert URLs to links
      // This regex is simpler but still effective for most URLs
      const urlRegex = /(https?:\/\/[^\s<>"']+\.[^\s<>"']+)/g;
      return token.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: var(--primary-color);">${url}</a>`;
      });
    });
    
    // Join the tokens back together
    return processedTokens.join('');
  }
  
  getSafeHtml(html: string): SafeHtml {
    // First convert any URLs in the HTML to clickable links
    const htmlWithLinks = this.convertUrlsToLinks(html);
    // Then sanitize the HTML
    return this.sanitizer.bypassSecurityTrustHtml(htmlWithLinks);
  }

  isValidRichText(value: any): boolean {
    return value !== null && value !== undefined && value.trim() !== '';
  }
}



