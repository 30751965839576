import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from 'primeng/api';
import { SettingsService } from '../../services/settings.service';
import { DataManagementService } from 'src/app/common/services/data-management.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/data.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-data-management',
  templateUrl: './data-management.component.html',
  styleUrls: ['./data-management.component.sass']
})
export class DataManagementComponent implements OnInit, OnDestroy {
  @Input() cmsSettings: any; 
  get autoProdPushEnabled(): boolean {
    return this.cmsSettings?.autoProdPushEnabled;
  }
  set autoProdPushEnabled(value: boolean) {
    if (this.cmsSettings) {
      this.cmsSettings.autoProdPushEnabled = value;
    }
  }

  get manualProdPushButtonEnabled(): boolean {
    return this.cmsSettings?.manualProdPushButtonEnabled;
  }
  set manualProdPushButtonEnabled(value: boolean) {
    if (this.cmsSettings) {
      this.cmsSettings.manualProdPushButtonEnabled = value;
    }
  }

  get autoDevQAPushEnabled(): boolean {
    return this.cmsSettings?.autoDevQAPushEnabled;
  }
  set autoDevQAPushEnabled(value: boolean) {
    if (this.cmsSettings) {
      this.cmsSettings.autoDevQAPushEnabled = value;
    }
  }

  get manualDevQAGamedataPushEnabled(): boolean {
    return this.cmsSettings?.manualDevQAGamedataPushEnabled;
  }
  set manualDevQAGamedataPushEnabled(value: boolean) {
    if (this.cmsSettings) {
      this.cmsSettings.manualDevQAGamedataPushEnabled = value;
    }
  }

  get manualDevGamedataPushEnabled(): boolean {
    return this.cmsSettings?.manualDevGamedataPushEnabled;
  }
  set manualDevGamedataPushEnabled(value: boolean) {
    if (this.cmsSettings) {
      this.cmsSettings.manualDevGamedataPushEnabled = value;
    }
  }

  get manualQAGamedataPushEnabled(): boolean {
    return this.cmsSettings?.manualQAGamedataPushEnabled;
  }
  set manualQAGamedataPushEnabled(value: boolean) {
    if (this.cmsSettings) {
      this.cmsSettings.manualQAGamedataPushEnabled = value;
    }
  }

  get selectedDefaultPerk(): any {
    return this.cmsSettings?.defaultPerk;
  }
  
  set selectedDefaultPerk(value: any) {
    if (this.cmsSettings) {
      this.cmsSettings.defaultPerk = value;
    }
  }

  isProd: boolean = false;
  env: string;
  perks: any[] = [];
  selectedDefaultPerks: any[] = [];
  private previousDefaultPerks: any[] = [];
  private defaultPerksSubject = new Subject<void>();
  private destroy$ = new Subject<void>();

  constructor(
    private messageService: MessageService,
    private settingsService: SettingsService,
    private dataManagementService: DataManagementService,
    private dataService: DataService
  ) {
    this.defaultPerksSubject
      .pipe(
        debounceTime(500),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.handleDefaultPerksUpdate();
      });
  }

  ngOnInit(): void {
    this.setEnv();
    this.loadPerks();
    this.loadDefaultPerks();
  }

  setEnv() {
    this.env = window.location.href.includes('dev.cms')
      ? 'dev'
      : window.location.href.includes('localhost')
      ? 'local'
      : window.location.href.includes('test.cms')
      ? 'test'
      : 'prod';

    this.isProd = environment.production && this.env === 'prod';
  }

  async handleProdPushChange(event: any, isAutoPromoteSetting: boolean = false) {
    if (isAutoPromoteSetting) {
      await this.settingsService.updateSetting(this.cmsSettings.id, {
        autoProdPushEnabled: this.autoProdPushEnabled,
      });
    } else {
      await this.settingsService.updateSetting(this.cmsSettings.id, {
        manualProdPushButtonEnabled: this.manualProdPushButtonEnabled,
      });
    }
  }

  async handleAutoDevQAPushEnabledChange(event: any) {
    await this.settingsService.updateSetting(this.cmsSettings.id, {
      autoDevQAPushEnabled: this.autoDevQAPushEnabled,
    });
  }

  async handleManualDevQAGamedataPushEnabledChange(event: any) {
    await this.settingsService.updateSetting(this.cmsSettings.id, {
      manualDevQAGamedataPushEnabled: this.manualDevQAGamedataPushEnabled,
    });
  }

  async handleManualDevGamedataPushEnabledChange(event: any) {
    await this.settingsService.updateSetting(this.cmsSettings.id, {
      manualDevGamedataPushEnabled: this.manualDevGamedataPushEnabled,
    });
  }

  async handleManualQAGamedataPushEnabledChange(event: any) {
    await this.settingsService.updateSetting(this.cmsSettings.id, {
      manualQAGamedataPushEnabled: this.manualQAGamedataPushEnabled,
    });
  }

  async syncDB() {
    this.dataManagementService.syncDatabase(this.env);
    this.messageService.add({
      severity: 'success',
      summary: 'DB Sync Started',
      detail: `DB Sync result will be notified through Slack.`,
    });
  }

  loadPerks() {
    this.dataService.getAllOfType('perks', {
      query: { defaultPerk: true }, 
      select: '_id id name'
    }).subscribe(
      (perks) => {
        this.perks = perks;
      }
    );
  }

  async loadDefaultPerks() {
    try {
      const response = await this.dataService.getAllOfTypeAsync('cms-settings', {
        query: { id: 1000 },
        select: 'defaultPerk',
        autopopulate: true
      });

      if (response[0]?.defaultPerk) {
        this.selectedDefaultPerks = response[0].defaultPerk;
        this.previousDefaultPerks = [...response[0].defaultPerk];
      }
    } catch (error) {
      console.error('Error loading default perks:', error);
    }
  }

  onDefaultPerksChange(event: any) {
    this.defaultPerksSubject.next();
  }

  private async handleDefaultPerksUpdate() {
    console.log(this.selectedDefaultPerks);
    const settings = {
      defaultPerk: this.selectedDefaultPerks
    };

    const newIds = this.selectedDefaultPerks.map((perk: any) => perk.id);
    const previousIds = this.previousDefaultPerks.map((perk: any) => perk.id);
    
    console.log('New IDs:', newIds, 'Previous IDs:', previousIds);
    
    // Find removed and added perk ids
    const removedIds = previousIds.filter(id => !newIds.includes(id));
    const addedIds = newIds.filter(id => !previousIds.includes(id));

    try {
      await this.dataService.updateRecord(1000, 'cms-settings', settings);
      
      // Update only newly added perks to true
      for (const id of addedIds) {
        await this.dataService.updateRecord(id, 'perks', { isSelectedDefaultPerk: true });
      }
      
      // Update only removed perks to false
      for (const id of removedIds) {
        await this.dataService.updateRecord(id, 'perks', { isSelectedDefaultPerk: false });
      }

      // Update previous selection
      this.previousDefaultPerks = [...this.selectedDefaultPerks];
    
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Default perks updated successfully'
      });
    } catch (error) {
      console.error('Error updating default perks:', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to update default perks'
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
