import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemSetService {
  private readonly apiUrl: string = '/api/item-sets'; 
  constructor(private http: HttpClient) {}

  async addToItemSet(payload: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.apiUrl}/add-items-to-set`, payload));
  }

  async createItemSet(payload: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.apiUrl}/create-item-set`, payload));
  }
  
  async findSimilarItems(payload: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.apiUrl}/find-similar-items`, payload));
  }
}
