<img
*ngIf="record[field.key] && record[field.key].thumbnails[0]"
[src]="record[field.key].thumbnails[0].path+'?ts=' + timestamp"
class="p-mx-auto"
width="100%"
(click)="displayImage(record[field.key].thumbnails[0].path)"
/>
<div class="p-grid">
<div class="p-col-fixed" style="width:100px">
  <button pButton
  type="button" icon="pi pi-copy"
  class="p-button-text p-ml-3 p-mt-1"
  (click)="copyToClipboard(record[field.key].thumbnails[0].path)"
  ></button>
</div>
<div class="p-col" style="min-width:200px">
  <small style="overflow-wrap: anywhere">
    {{record[field.key].thumbnails[0].path}}
  </small>
</div>
</div>


