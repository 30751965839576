<dynamic-table
  *ngIf="!isLoading"
  [tableName]="title"
  [tableType]="type"
  [tableConfig]="tableConfig"
  [fields]="fields"
  [options]="options"
  [defaultColumnOrder]="defaultColumnOrder"
  [globalFilters]="globalFilters"
  [customGlobalColumnSets]="customGlobalColumnSets"
>
</dynamic-table>
