import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap, throwError } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private url = '/api';

  constructor(private http: HttpClient, private dataService: DataService) {}

  getAllForms() {
    return this.dataService.getAllOfType('forms', {});
  }

  submitForm(form: any, formUrl: string, editMode: boolean): Observable<any> {
    if(editMode) {
      return this.http.patch(`${this.url}/${formUrl}`, form);
    } else {
      return this.http.post(`${this.url}/${formUrl}`, form);
    }
  }
}
