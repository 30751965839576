import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  private dialogStateSource = new BehaviorSubject<{visible: boolean, imageUrl: string | null, title: string | null}>({visible: false, imageUrl: '', title: ''});
  dialogState = this.dialogStateSource.asObservable();

  showDialog(imageUrl: string | null, title: string | null) {
    this.dialogStateSource.next({visible: true, imageUrl, title});
  }

  hideDialog() {
    this.dialogStateSource.next({visible: false, imageUrl: '', title: ''});
  }
}
