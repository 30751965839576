import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-daily-goal-view',
  templateUrl: './daily-goal-view.component.html',
  styleUrls: ['./daily-goal-view.component.sass']
})
export class DailyGoalViewComponent  extends BaseDynamicViewComponent implements OnInit
{
  dailyGoalRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Daily Goal View Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Daily Goal Data",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time',columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time',columnWidth: 6 },
          {
            name: "Achievements Collection",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'achievementsCollection_ref',
            label: 'Achievements Collection',
            controller: 'achievements-collection',
            columnWidth: 6
          },
          {
            name: "Rewards Track",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'rewardsTrack_ref',
            label: 'Rewards Track',
            controller: 'rewards-track',
            columnWidth: 6
          },
          { name: "Days to Loop", 
            inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],
            key: 'daysToLoop', label: 'Days to Loop', columnWidth: 6
          },

        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.dailyGoalRecord =
    {
      enabled: false
    };
    this.title = "Daily Goal";
    this.viewRoute = 'daily-goals';
    this.isLoading = false;
  }

  /**
   * Validate Daily Goal required field.
   *
   * @param dailyGoal Daily Goal record
   */
  validateDailyGoal(dailyGoal: any)
  {
    return dailyGoal && dailyGoal.name && dailyGoal.name.length > 0;
  }
}
