<div *ngIf="!isLoading"  style="position: relative;">
    <p-messages></p-messages>
    <!-- Spinner -->

    <!-- Header -->
    <div class="p-my-5">
    <h1 class="p-mr-3 p-d-inline"> Release {{entity.name}} ({{entity.id}})</h1>
    <a
      pButton
      pRipple
      label="Edit"
      type="button"
      style="position: absolute;"
      class="p-d-inline"
      icon="pi pi-pencil"
      href="/releases/edit/{{entity.id}}"
    ></a>
    </div>
    <ng-container>

<p-card *ngIf="!isLoading">
<div class="p-grid">
<div [class]="'p-grid p-col-' + (rightFields.length > 0 ? '8' : '12')">
    <ng-container *ngFor="let field of leftFields">
        <div [class]="'p-col-'+ field.size">
                <ng-container [ngSwitch]="field.value">
                <ng-container *ngSwitchCase="fieldType.Challenges">
                    <p-panel  header="Challenges">
                        <p-table [value]="challenges" [scrollable]="true" [scrollHeight]="field.fieldHeight+'px'" [virtualScroll]="true" [virtualScrollItemSize]="40">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="id" [style]="{'width': '2vw'}">ID<p-sortIcon field="id"></p-sortIcon></th>
                                    <th pSortableColumn="room_render_url_256" [style]="{'width': '1vw'}">Thumbnail<p-sortIcon field="room_render_url"></p-sortIcon></th>
                                    <th pSortableColumn="feed_image_url_256" [style]="{'width': '10vw'}">Feed Image<p-sortIcon field="feed_image_url"></p-sortIcon></th>
                                    <th pSortableColumn="name" [style]="{'width': '10vw'}">Name<p-sortIcon field="name"></p-sortIcon></th>
                                    <th pSortableColumn="sceneType" [style]="{'width': '35vw'}">Scene Type <p-sortIcon field="sceneType"></p-sortIcon></th>
                                    <th pSortableColumn="enabled" [style]="{'width': '10vw'}">Enabled<p-sortIcon field="enabled"></p-sortIcon></th>
                                    <th pSortableColumn="start" [style]="{'width': '10vw'}">Start <p-sortIcon field="start"></p-sortIcon></th>
                                    <th pSortableColumn="end" [style]="{'width': '10vw'}">End <p-sortIcon field="end"></p-sortIcon></th>
                                    <th pSortableColumn="isAssetUpToDate" [style]="{'width': '10vw'}">Promotion Status <p-sortIcon field="isAssetUpToDate"></p-sortIcon></th>
                                    <th pSortableColumn="climate" [style]="{'width': '10vw'}">Climate<p-sortIcon field="climate"></p-sortIcon></th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-challenge>
                                <tr>
                                    <td>{{challenge.id}}</td>
                                    <td> <img [src]="challenge.room_render_url_256" width="72" height="72"/></td>
                                    <td> <img [src]="challenge.feed_image_url_128" width="72" height="72"/></td>
                                    <td><a style="color: #81c784;" routerLink="/challenges/{{ challenge.id }}">{{challenge.name}}</a></td>
                                    <td>{{challenge.sceneType ? challenge.sceneType.name : ''}}</td>
                                    <td>{{challenge.enabled?'yes':'no'}}</td>
                                    <td>{{challenge.start | officeTime: 'short'}}</td>
                                    <td>{{challenge.end | officeTime: 'short'}}</td>
                                    <td>{{challenge.isAssetUpToDate}}</td>
                                    <td >{{challenge.climate}}</td>
                                  </tr>
                            </ng-template>
                        </p-table>
                    </p-panel>
                    </ng-container>
                    <ng-container *ngSwitchCase="fieldType.Prizes">
                      <p-panel header="Prizes">
                      <div class="p-mt-3 p-ml-1" [style]="'min-height:'+ field.fieldHeight + 'px' " *ngIf="prizes">
                        <p-table [value]="prizes" [scrollable]="true" scrollHeight="300px" [virtualScroll]="true" [virtualScrollItemSize]="40">
                          <ng-template pTemplate="header">
                              <tr>
                                  <th [style]="{'width': '2vw'}" pSortableColumn="id.id">ID<p-sortIcon field="id.id"></p-sortIcon></th>
                                  <th [style]="{'width': '10px'}" pSortableColumn="thumbnail" >Thumbnail<p-sortIcon field="id"></p-sortIcon></th>
                                  <th [style]="{'width': '10vw'}" pSortableColumn="id.name">Name<p-sortIcon field="id.name"></p-sortIcon></th>
                                  <th [style]="{'width': '10vw'}" pSortableColumn="id.start">Start<p-sortIcon field="id.start"></p-sortIcon></th>
                                  <th [style]="{'width': '10vw'}" pSortableColumn="id.end">End<p-sortIcon field="id.end"></p-sortIcon></th>
                                  <th [style]="{'width': '35vw'}" pSortableColumn="c">Quantity<p-sortIcon field="c"></p-sortIcon></th>
                                  <th [style]="{'width': '10vw'}" pSortableColumn="id.enabled">Enabled<p-sortIcon field="id.enabled"></p-sortIcon></th>
                                  <th [style]="{'width': '10px'}" pSortableColumn="itemStatus" >Content Status<p-sortIcon field="itemStatus"></p-sortIcon></th>
                                  <th [style]="{'width': '10px'}" pSortableColumn="vendorStatus" >Art Status<p-sortIcon field="vendorStatus"></p-sortIcon></th>
                                  <th [style]="{'width': '10vw'}" pSortableColumn="isImageUpToDate">Promotion Status <p-sortIcon field="isImageUpToDate"></p-sortIcon></th>
                                  <th [style]="{'width': '10vw'}" pSortableColumn="lineItemType">Type<p-sortIcon field="lineItemType"></p-sortIcon></th>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-prize>
                              <tr>
                                  <td>{{prize.id.id}}</td>
                                  <td> <img [src]="prize.lineItemType == 'Currency' ? prize.id.imageSmall_ref.thumbnail_url_64 : prize.id.thumbnail_url_64" /></td>
                                  <td><a style="color: #81c784;" routerLink="/{{ prizeLinks[prize.lineItemType] }}/{{ prize.id.id }}">{{prize.id.name}}</a></td>
                                  <td>{{prize.id.start | officeTime: 'short'}}</td>
                                  <td>{{prize.id.end | officeTime: 'short'}}</td>
                                  <td>{{prize.c}}</td>
                                  <td>{{prize.id.enabled ? 'Yes':'No'}}</td>
                                  <td>{{prize.itemStatus}}</td>
                                  <td>{{prize.vendorStatus}}</td>
                                  <td>{{prize.isImageUpToDate}}</td>
                                  <td>{{prize.lineItemType}}</td>
                              </tr>
                          </ng-template>
                      </p-table>
                      </div>
                    </p-panel>
                    </ng-container>
                    <ng-container *ngSwitchCase="fieldType.Collections">
                        <p-panel  header="Collections">
                                <p-table [value]="collections" [scrollable]="true" [scrollHeight]="field.fieldHeight+'px'" [virtualScroll]="true" [virtualScrollItemSize]="40">
                                  <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="id" [style]="{'width': '10px'}">ID<p-sortIcon field="id"></p-sortIcon></th>
                                        <th pSortableColumn="thumbnail" [style]="{'width': '10px'}">Thumbnail<p-sortIcon field="id"></p-sortIcon></th>
                                        <th pSortableColumn="name" [style]="{'width': '10px'}">Name<p-sortIcon field="name"></p-sortIcon></th>
                                        <th pSortableColumn="start" [style]="{'width': '10px'}">Start Date<p-sortIcon field="sceneType"></p-sortIcon></th>
                                        <th pSortableColumn="end" [style]="{'width': '10px'}">End Date<p-sortIcon field="sceneType"></p-sortIcon></th>
                                        <th pSortableColumn="enabled" [style]="{'width': '10px'}">Enabled<p-sortIcon field="sceneType"></p-sortIcon></th>
                                        <th pSortableColumn="itemStatus" [style]="{'width': '10px'}">Content Status<p-sortIcon field="itemStatus"></p-sortIcon></th>
                                        <th pSortableColumn="vendorStatus" [style]="{'width': '10px'}">Art Status<p-sortIcon field="vendorStatus"></p-sortIcon></th>
                                        <th pSortableColumn="isAssetUpToDate" [style]="{'width': '10px'}">Promotion Status<p-sortIcon field="isAssetUpToDate"></p-sortIcon></th>
                                        <th pSortableColumn="type" [style]="{'width': '10px'}">Type<p-sortIcon field="type"></p-sortIcon></th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-collection>
                                    <tr>
                                        <td>{{collection.id}}</td>
                                        <td> <img [src]="collection.thumbnail_url_64" width="72" height="72"/></td>
                                        <td><a style="color: #81c784;" routerLink="/items/{{ collection.id }}">{{collection.name}}</a></td>
                                        <td>{{collection.start | officeTime: 'short'}}</td>
                                        <td>{{collection.end | officeTime: 'short'}}</td>
                                        <td>{{collection.enabled ? "Yes" : "No"}}</td>
                                        <td>{{collection.itemStatus}}</td>
                                        <td>{{collection.vendorStatus}}</td>
                                        <td>{{collection.isAssetUpToDate}}</td>
                                        <td>{{collection.type_ref ? collection.type_ref.name : ''}}</td>
                                    </tr>
                                </ng-template>
                              </p-table>
                        </p-panel>
                        </ng-container>
                        <ng-container *ngSwitchCase="fieldType.LoadingScreen">
                        <p-panel header="Loading Screen">
                            <div [style]="'min-height:'+ field.fieldHeight + 'px'">
                                <h4 class="p-m-0 headerStyle">Loading Screens:</h4>
                                <p-tabView>
                                  <p-tabPanel *ngFor="let loadingScreen of entity.loadingScreen_ref; let i = index" [header]="loadingScreen ? loadingScreen.name : 'Loading Screen'">
                                    <div class="p-grid">
                                      <span class="p-col-12 p-sm-6 p-lg-12 p-p-3 p-my-3">
                                        <div class="p-d-block p-mx-auto loadingScreenStyle">
                                            <div class="thumb-container">
                                              <img
                                                *ngIf="loadingScreen && loadingScreen.thumbnails"
                                                [src]="loadingScreen.thumbnails[2].path"
                                                class="p-mx-auto"
                                                width="80%"
                                                height="80%"
                                              />
                                            </div>
                                            <p-skeleton
                                              *ngIf="!loadingScreen.thumbnails"
                                              width="100%"
                                              height="100%"
                                            ></p-skeleton>
                                            <div class="p-mt-1 p-ml-1" *ngIf="entity.loadingScreen_ref">
                                              <ul
                                                class="p-my-5 p-ml-1 loadingScreenListStyle">
                                                <li class="p-mx-2 p-mb-2" style="float: left;">
                                                  <b class="p-ml-0">Start: {{ loadingScreen.start ? loadingScreen.start : 'N/A' }}</b>
                                                </li>
                                                <li class="p-mx-2 p-mb-2" style="float: left;">
                                                  <b class="p-ml-0">End: {{ loadingScreen.end ? loadingScreen.end : 'N/A' }}</b>
                                                </li>
                                                <li class="p-mx-2 p-mb-2" style="float: left;">
                                                  <b class="p-ml-0">Weight: {{ loadingScreen.weight ? loadingScreen.weight : 'N/A' }}</b>
                                                </li>
                                                <li class="p-mx-2 p-mb-2" style="float: left;">
                                                  <b class="p-ml-0">Enabled: {{ loadingScreen.enabled ? 'Yes' : 'No' }}</b>
                                                </li>
                                              </ul>
                                            </div>
                                        </div>
                                      </span>
                                    </div>
                                  </p-tabPanel>
                              </p-tabView>

                              </div>
                        </p-panel>
                        </ng-container>
                        <ng-container *ngSwitchCase="fieldType.Timeline">
                                <p-panel header="Timeline">
                                  <div [style]="'min-height:'+ field.fieldHeight + 'px'" >
                                  <p-scrollPanel>
                                  <p-timeline align="alternate" [value]="this.entity.timeline_dates">
                                    <ng-template pTemplate="content" let-event>
                                      {{event.date | officeTime: 'short'}}
                                      {{event.description}}
                                    </ng-template>
                                  </p-timeline>

                                  <div class="flex justify-content-end">
                                    <p-button
                                    icon="pi pi-external-link"
                                    styleClass=" p-button-text p-button-rounded p-button-sm"
                                    (onClick)="showFullTimeline = !showFullTimeline"
                                  ></p-button>
                                  </div>
                                </p-scrollPanel>
                                </div>
                                </p-panel>

                        </ng-container>
                        <ng-container *ngSwitchCase="fieldType.Colors">
                            <p-panel  header="Colors">
                                <div class="p-d-flex">
                                    <div class="p-mr-2">
                                    </div>
                                  </div>
                                  <div [style]="'min-height:'+ field.fieldHeight + 'px' " class="p-mr-2">
                                        <div>
                                          <ul>
                                            <li *ngFor="let color of entity.colors" class="p-m-3">
                                              <!-- <p-badge class="circle" value=" " size="xlarge" [style]="{'background-color': color}"></p-badge>
                                              <a class="p-ml-2">{{color}}</a> -->
                                              <div class="circle" [style]="{'background-color': color}"></div>
                                              <a class="">{{color}}</a>
                                            </li>
                                          </ul>
                                        </div>
                                  </div>
                            </p-panel>
                            </ng-container>
                            <ng-container *ngSwitchCase="fieldType.Copy">
                              <p-panel *ngIf="entity.releaseType_ref && entity.releaseType_ref.length > 0; else notSeries" [header]="'Series Data'">
                                <div [style]="'min-height:'+ field.fieldHeight + 'px'" class="p-mt-1 p-ml-1">
                                  <div class="status-container">
                                    <div class="status-column">
                                      <h4 class="status-label">Enabled:</h4>
                                      <div class="status-button" [ngClass]="entity.enabled ? 'enabled' : 'disabled'">
                                        {{ entity.enabled ? 'Enabled' : 'Not Enabled' }}
                                      </div>
                                    </div>
                                    <div class="status-column">
                                      <h4 class="status-label">Environment(s):</h4>
                                      <div class="status-button" [ngClass]="getEnvironmentClass(entity?.env)">
                                        {{ getEnvironmentText(entity?.env) }}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="date-container">
                                    <h4 class="date-title">Start Date:</h4>
                                    <p class="date-value">{{ entity.start | date: 'MMM d, y, h:mm a' }}</p>
                                    <h4 class="date-title">End Date:</h4>
                                    <p class="date-value">{{ entity.end | date: 'MMM d, y, h:mm a' }}</p>
                                  </div>
                                  <p class="p-m-0">Copy: {{ entity.copy }}</p>
                                </div>
                              </p-panel>
                              
                              <ng-template #notSeries>
                                <p-panel header="Copy">
                                  <div [style]="'min-height:'+ field.fieldHeight + 'px'" class="p-mt-1 p-ml-1">
                                    <p *ngIf="entity && entity.copy" class="p-m-0">{{ entity.copy }}</p>
                                  </div>
                                </p-panel>
                              </ng-template>                              
                            </ng-container>
                            <ng-container *ngSwitchCase="fieldType.Inbox">
                              <p-panel header="Inboxes">
                              <div [style]="'min-height:'+ field.fieldHeight + 'px'">
                                <div *ngFor="let inbox of entity.inboxMessages_ref" class="p-grid p-ml-2 p-m-0">
                                  <div class="p-col">
                                    <img class="p-mt-3" [src]="inbox.image_ref && inbox.image_ref.thumbnails  ? inbox.image_ref.thumbnails[0].path : 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'" alt="placeholder" style="max-width: 100px; max-height: 100px; object-fit: cover;">
                                  </div>
                                  <div class="p-col">
                                    <div class="p-grid p-pt-2">
                                      <div class="p-col">
                                      {{ inbox.name }}
                                      </div>
                                    </div>
                                    <div class="p-grid">
                                      <div class="p-col">
                                        <b>Envs:</b> {{ inbox.sendReady && inbox.sendReady.length > 0 ? inbox.sendReady.join(', ') : 'N/A'}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-col">
                                    <div class="p-grid p-pt-3">
                                      <div class="p-col">
                                        <b>Start:</b> {{ inbox.start ? (inbox.start | officeTime: 'short') : 'N/A'}}
                                      </div>
                                    </div>
                                    <div class="p-grid">
                                      <div class="p-col">
                                        <b>End:</b> {{ inbox.end ? (inbox.end | officeTime: 'short') : 'N/A'}}
                                      </div>
                                    </div>
                                  </div>
                                  <p-divider></p-divider>
                                </div>
                              </div>
                            </p-panel>
                            </ng-container>
                </ng-container>
            </div>
    </ng-container>
</div>
<div *ngIf="rightFields.length > 0" class="p-col-4">
    <ng-container *ngFor="let field of rightFields">
        <div [class]="'p-col-'+ field.size">
                <ng-container [ngSwitch]="field.value">
                <ng-container *ngSwitchCase="fieldType.Challenges">
                    <p-panel  header="Challenges">
                        <p-table [value]="challenges" [scrollable]="true" [scrollHeight]="field.fieldHeight+'px'" [virtualScroll]="true" [virtualScrollItemSize]="40">
                          <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="id" [style]="{'width': '2vw'}">ID<p-sortIcon field="id"></p-sortIcon></th>
                                <th pSortableColumn="room_render_url_256" [style]="{'width': '1vw'}">Thumbnail<p-sortIcon field="room_render_url"></p-sortIcon></th>
                                <th pSortableColumn="feed_image_url_256" [style]="{'width': '10vw'}">Feed Image<p-sortIcon field="feed_image_url"></p-sortIcon></th>
                                <th pSortableColumn="name" [style]="{'width': '10vw'}">Name<p-sortIcon field="name"></p-sortIcon></th>
                                <th pSortableColumn="sceneType" [style]="{'width': '35vw'}">Scene Type <p-sortIcon field="sceneType"></p-sortIcon></th>
                                <th pSortableColumn="enabled" [style]="{'width': '10vw'}">Enabled<p-sortIcon field="enabled"></p-sortIcon></th>
                                <th pSortableColumn="start" [style]="{'width': '10vw'}">Start <p-sortIcon field="start"></p-sortIcon></th>
                                <th pSortableColumn="end" [style]="{'width': '10vw'}">End <p-sortIcon field="end"></p-sortIcon></th>
                                <th pSortableColumn="isAssetUpToDate" [style]="{'width': '10vw'}">Promotion Status <p-sortIcon field="isAssetUpToDate"></p-sortIcon></th>
                                <th pSortableColumn="climate" [style]="{'width': '10vw'}">Climate<p-sortIcon field="climate"></p-sortIcon></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-challenge>
                            <tr>
                                <td>{{challenge.id}}</td>
                                <td> <img [src]="challenge.room_render_url_256" width="72" height="72"/></td>
                                <td> <img [src]="challenge.feed_image_url_256" width="72" height="72"/></td>
                                <td><a style="color: #81c784;" routerLink="/challenges/{{ challenge.id }}">{{challenge.name}}</a></td>
                                <td>{{challenge.sceneType ? challenge.sceneType.name : ''}}</td>
                                <td>{{challenge.enabled?'yes':'no'}}</td>
                                <td>{{challenge.start | officeTime: 'short'}}</td>
                                <td>{{challenge.end | officeTime: 'short'}}</td>
                                <td>{{challenge.isAssetUpToDate}}</td>
                                <td >{{challenge.climate}}</td>
                              </tr>
                        </ng-template>
                        </p-table>
                    </p-panel>
                    </ng-container>
                    <ng-container *ngSwitchCase="fieldType.Collections">
                        <p-panel  header="Collections">
                                <p-table [value]="collections" [scrollable]="true" [scrollHeight]="field.fieldHeight+'px'" [virtualScroll]="true" [virtualScrollItemSize]="40">
                                  <ng-template pTemplate="header">
                                      <tr>
                                          <th pSortableColumn="id" [style]="{'width': '10px'}">ID<p-sortIcon field="id"></p-sortIcon></th>
                                          <th pSortableColumn="thumbnail" [style]="{'width': '10px'}">Thumbnail<p-sortIcon field="id"></p-sortIcon></th>
                                          <th pSortableColumn="name" [style]="{'width': '10px'}">Name<p-sortIcon field="name"></p-sortIcon></th>
                                          <th pSortableColumn="start" [style]="{'width': '10px'}">Start Date<p-sortIcon field="sceneType"></p-sortIcon></th>
                                          <th pSortableColumn="end" [style]="{'width': '10px'}">End Date<p-sortIcon field="sceneType"></p-sortIcon></th>
                                          <th pSortableColumn="enabled" [style]="{'width': '10px'}">Enabled<p-sortIcon field="sceneType"></p-sortIcon></th>
                                          <th pSortableColumn="itemStatus" [style]="{'width': '10px'}">Content Status<p-sortIcon field="itemStatus"></p-sortIcon></th>
                                          <th pSortableColumn="vendorStatus" [style]="{'width': '10px'}">Art Status<p-sortIcon field="vendorStatus"></p-sortIcon></th>
                                          <th pSortableColumn="isAssetUpToDate" [style]="{'width': '10px'}">Promotion Status<p-sortIcon field="isAssetUpToDate"></p-sortIcon></th>
                                          <th pSortableColumn="type" [style]="{'width': '10px'}">Type<p-sortIcon field="type"></p-sortIcon></th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-collection>
                                      <tr>
                                          <td>{{collection.id}}</td>
                                          <td> <img [src]="collection.thumbnail_url_64"/></td>
                                          <td><a style="color: #81c784;" routerLink="/items/{{ collection.id }}">{{collection.name}}</a></td>
                                          <td>{{collection.start | officeTime: 'short'}}</td>
                                          <td>{{collection.end | officeTime: 'short'}}</td>
                                          <td>{{collection.enabled ? "Yes" : "No"}}</td>
                                          <td>{{collection.itemStatus}}</td>
                                          <td>{{collection.vendorStatus}}</td>
                                          <td>{{collection.isAssetUpToDate}}</td>
                                          <td>{{collection.type_ref ? collection.type_ref.name : ''}}</td>
                                      </tr>
                                  </ng-template>
                              </p-table>
                        </p-panel>
                        </ng-container>
                        <ng-container *ngSwitchCase="fieldType.Prizes">
                          <p-panel header="Prizes">
                          <div class="p-mt-3 p-ml-1" [style]="'min-height:'+ field.fieldHeight + 'px' " *ngIf="prizes">
                            <p-table [value]="prizes" [scrollable]="true" scrollHeight="300px" [virtualScroll]="true" [virtualScrollItemSize]="40">
                              <ng-template pTemplate="header">
                                  <tr>
                                      <th [style]="{'width': '2vw'}" pSortableColumn="id.id">ID<p-sortIcon field="id.id"></p-sortIcon></th>
                                      <th [style]="{'width': '10px'}" pSortableColumn="thumbnail" >Thumbnail<p-sortIcon field="id"></p-sortIcon></th>
                                      <th [style]="{'width': '10vw'}" pSortableColumn="id.name">Name<p-sortIcon field="id.name"></p-sortIcon></th>
                                      <th [style]="{'width': '10vw'}" pSortableColumn="id.start">Start<p-sortIcon field="id.start"></p-sortIcon></th>
                                      <th [style]="{'width': '10vw'}" pSortableColumn="id.end">End<p-sortIcon field="id.end"></p-sortIcon></th>
                                      <th [style]="{'width': '35vw'}" pSortableColumn="c">Quantity<p-sortIcon field="c"></p-sortIcon></th>
                                      <th [style]="{'width': '10vw'}" pSortableColumn="id.enabled">Enabled<p-sortIcon field="id.enabled"></p-sortIcon></th>
                                      <th [style]="{'width': '10px'}" pSortableColumn="itemStatus" >Content Status<p-sortIcon field="itemStatus"></p-sortIcon></th>
                                      <th [style]="{'width': '10px'}" pSortableColumn="vendorStatus" >Art Status<p-sortIcon field="vendorStatus"></p-sortIcon></th>
                                      <th [style]="{'width': '10vw'}" pSortableColumn="isImageUpToDate">Promotion Status <p-sortIcon field="isImageUpToDate"></p-sortIcon></th>
                                      <th [style]="{'width': '10vw'}" pSortableColumn="lineItemType">Type<p-sortIcon field="lineItemType"></p-sortIcon></th>
                                  </tr>
                              </ng-template>
                              <ng-template pTemplate="body" let-prize>
                              <tr>
                                  <td>{{prize.id.id}}</td>
                                  <td> <img [src]="prize.lineItemType == 'Currency' ? prize.id.imageMedium_ref.thumbnail_url_32 : prize.id.thumbnail_url_32"/></td>

                                  <td><a style="color: #81c784;" routerLink="/{{ prizeLinks[prize.lineItemType] }}/{{ prize.id.id }}">{{prize.id.name}}</a></td>
                                  <td>{{prize.id.start | officeTime: 'short'}}</td>
                                  <td>{{prize.id.end | officeTime: 'short'}}</td>
                                  <td>{{prize.c}}</td>
                                  <td>{{prize.id.enabled ? 'Yes':'No'}}</td>
                                  <td>{{prize.itemStatus}}</td>
                                  <td>{{prize.vendorStatus}}</td>
                                  <td>{{prize.isImageUpToDate}}</td>
                                  <td>{{prize.lineItemType}}</td>
                              </tr>
                          </ng-template>
                          </p-table>
                          </div>
                        </p-panel>
                        </ng-container>
                        <ng-container *ngSwitchCase="fieldType.LoadingScreen">
                            <p-panel header="Loading Screens">
                                <div [style]="'min-height:'+ field.fieldHeight + 'px' ">
                                    <p-tabView>
                                      <p-tabPanel *ngFor="let loadingScreen of entity.loadingScreen_ref; let i = index" [header]="loadingScreen ? loadingScreen.name : 'Loading Screen'">
                                        <div class="p-grid">
                                          <span class="p-col-12 p-sm-6 p-lg-12 p-p-3 p-my-3">
                                            <div class="p-d-block p-mx-auto loadingScreenStyle">
                                                <div class="thumb-container">
                                                  <img
                                                    *ngIf="loadingScreen && loadingScreen.thumbnails"
                                                    [src]="loadingScreen.thumbnails[2].path"
                                                    class="p-mx-auto"
                                                    width="80%"
                                                    height="80%"
                                                  />
                                                </div>
                                                <p-skeleton
                                                  *ngIf="!loadingScreen.thumbnails"
                                                  width="100%"
                                                  height="100%"
                                                ></p-skeleton>
                                            </div>
                                          </span>
                                        </div>
                                      </p-tabPanel>
                                  </p-tabView>

                                  </div>
                            </p-panel>
                            </ng-container>
                            <ng-container *ngSwitchCase="fieldType.Timeline">
                                    <p-panel header="Timeline: ">
                                      <p-timeline align="alternate" [value]="this.entity.timeline_dates">
                                        <ng-template pTemplate="content" let-event>
                                          {{event.date | officeTime: 'short'}}
                                          {{event.description}}
                                        </ng-template>
                                      </p-timeline>

                                    </p-panel>
                                    <div class="flex justify-content-end">
                                      <p-button
                                      icon="pi pi-external-link"
                                      styleClass=" p-button-text p-button-rounded p-button-sm"
                                      (onClick)="showFullTimeline = !showFullTimeline"
                                    ></p-button>
                                    </div>
                            </ng-container>
                        <ng-container *ngSwitchCase="fieldType.Colors">
                            <p-panel  header="Colors">
                                <div class="p-d-flex">
                                    <div class="p-mr-2">
                                    </div>
                                  </div>
                                  <div [style]="'min-height:'+ field.fieldHeight + 'px' " class="p-mr-2">
                                        <div>
                                          <ul>
                                            <li *ngFor="let color of entity.colors" class="p-m-3">
                                              <!-- <p-badge class="circle" value=" " size="xlarge" [style]="{'background-color': color}"></p-badge>
                                              <a class="p-ml-2">{{color}}</a> -->
                                              <div class="circle" [style]="{'background-color': color}"></div>
                                              <a class="">{{color}}</a>
                                            </li>
                                          </ul>
                                        </div>
                                  </div>
                            </p-panel>
                            </ng-container>
                            <ng-container *ngSwitchCase="fieldType.Copy">
                                <p-panel  header="Copy">
                                    <div class="p-mt-1 p-ml-1" [style]="'min-height:'+ field.fieldHeight + 'px' " *ngIf="entity && entity.copy">
                                        <p class="p-m-0">{{ entity.copy }}</p>
                                      </div>
                                </p-panel>
                            </ng-container>
                            <ng-container *ngSwitchCase="fieldType.Inbox">
                              <div [style]="'min-height:'+ field.fieldHeight + 'px'">
                                <h4 class="p-m-0 p-ml-1 p-mt-1 p-mb-2 headerStyle">Inboxes:</h4>
                                <div *ngFor="let inbox of entity.inboxMessages_ref" class="p-grid p-ml-2 p-m-0">
                                  <div class="p-col">
                                    <img class="p-mt-3" [src]="inbox.image_ref && inbox.image_ref.thumbnails  ? inbox.image_ref.thumbnails[0].path : 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'" alt="placeholder" style="max-width: 100px; max-height: 100px; object-fit: cover;">
                                  </div>
                                  <div class="p-col">
                                    <div class="p-grid p-pt-2">
                                      <div class="p-col">
                                      {{ inbox.name }}
                                      </div>
                                    </div>
                                    <div class="p-grid">
                                      <div class="p-col">
                                        <b>Envs:</b> {{ inbox.sendReady && inbox.sendReady.length > 0 ? inbox.sendReady.join(', ') : 'N/A'}}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="p-col">
                                    <div class="p-grid p-pt-3">
                                      <div class="p-col">
                                        <b>Start:</b> {{ inbox.start ? (inbox.start | officeTime: 'short') : 'N/A'}}
                                      </div>
                                    </div>
                                    <div class="p-grid">
                                      <div class="p-col">
                                        <b>End:</b> {{ inbox.end ? (inbox.end | officeTime: 'short') : 'N/A'}}
                                      </div>
                                    </div>
                                  </div>
                                  <p-divider></p-divider>
                                </div>
                              </div>
                            </ng-container>
                </ng-container>
            </div>
    </ng-container>
</div>
</div>
</p-card>
