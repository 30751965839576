/**
 * Guest User DTO
 */
export default class GuestUser
{
    /**
     * Username/email of the guest user
     */
    email: string = '';
    /**
     * Password of the guest user
     */
    password: string = '';
    /**
     * If it's a test user
     */
    isTest?: boolean;
}