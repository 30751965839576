import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-schedule-type',
  templateUrl: './schedule-type.component.html',
  styleUrls: ['./schedule-type.component.sass']
})
export class ScheduleTypeRenderComponent implements ICellRendererAngularComp, OnInit {
  params: any;
  scheduleTypes: any[] = [];
  currentScheduleType: any = null;

  constructor(private dataService: DataService) {}

  async ngOnInit() {
    await this.loadScheduleTypes();
  }

  private async loadScheduleTypes() {
    try {
      const response = await this.dataService.findManyDynamic('schedule-type', {
        autopopulate: true,
        virtuals: true
      });
      this.scheduleTypes = response.records;
      
      // If we already have params, try to match the schedule type
      if (this.params?.value) {
        this.matchScheduleType();
      }
    } catch (error) {
      console.error('Error loading schedule types:', error);
    }
  }

  private matchScheduleType() {
    if (!this.params?.value || !this.scheduleTypes?.length) return;

    // Get the ID we're looking for (either from _id or the string itself)
    const searchId = this.params.value._id || this.params.value;

    // Find matching schedule type
    const matchedType = this.scheduleTypes.find(type => 
      type._id === searchId || type.id === searchId
    );

    if (matchedType) {
      this.currentScheduleType = matchedType;
      this.params = { value: matchedType };
    }
  }

  agInit(params: any): void {
    this.params = params;
    
    // If we have schedule types loaded, try to match
    if (this.scheduleTypes?.length) {
      this.matchScheduleType();
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    this.matchScheduleType();
    return true;
  }

  getBackgroundColor(): string {
    return this.currentScheduleType?.color || this.params?.value?.color || '#FFFFFF';
  }

  getContrastColor(): string {
    const color = this.currentScheduleType?.color || this.params?.value?.color;
    if (!color) return '#000000';

    // Remove the # if present
    const hex = color.replace('#', '');
    
    // Validate hex color format
    if (!/^[0-9A-Fa-f]{6}$/.test(hex)) return '#000000';
    
    try {
      // Convert to RGB
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);
      
      // Calculate luminance using the WCAG formula
      const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
      
      // Return black or white based on luminance
      return luminance > 0.5 ? '#000000' : '#FFFFFF';
    } catch (error) {
      console.error('Error calculating contrast color:', error);
      return '#000000';
    }
  }
}
