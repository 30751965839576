import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-entity-header',
  templateUrl: './entity-header.component.html',
})
export class EntityHeaderComponent {
  @Input() entity: any;
  @Input() type: string;

  @Output() onToggleDuplicationModal = new EventEmitter<void>();
  @Output() onShowDupDialog = new EventEmitter<void>();
  @Output() onFetchLocalizedValues = new EventEmitter<void>();

  get editUrl(): string {
    return `/${this.type}/edit/${this.entity.id}`;
  }

  toggleDuplicationModal(): void {
    this.onToggleDuplicationModal.emit();
  }

  showDupDialog(): void {
    this.onShowDupDialog.emit();
  }

  fetchLocalizedValues(): void {
    this.onFetchLocalizedValues.emit();
  }
}