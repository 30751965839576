import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import DropdownDto from 'src/app/common/dtos/DropdownDto';
import { PermissionsService } from "src/app/permissions/service/permissions.service";
import { MessageService } from "primeng/api";
import { ValidationsService } from "src/app/common/services/validations.service";
import PermissionDto from "src/app/permissions/dtos/PermissionsDto";
import { TableCommunicationService } from "src/app/common/ag-grid-table/services/table-communication.service";
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-permission-form-dialog',
  templateUrl: './permission-form-dialog.component.html',
  styleUrls: ['./permission-form-dialog.component.sass'],
})
export class PermissionFormDialogComponent implements OnInit {
  constructor(
    private permissionsService: PermissionsService,
    private messageService: MessageService,
    private validationService: ValidationsService,
    private tableCommunicationService: TableCommunicationService,
    public authService: AuthService,
  ) {}

  @Output() onPermissionChange = new EventEmitter();
  showAddEditNewRole: boolean = false;
  permissionsDto: Array<PermissionDto> = [];
  permissions: Array<PermissionDto> | undefined = [];
  permission: PermissionDto = new PermissionDto();
  showAddNewPermission: boolean = false;
  entities: Array<DropdownDto> = [];
  abilities: Array<DropdownDto> = [];

  async ngOnInit(): Promise<void> {
    await this.loadPermissions();
    this.clearMessages();
  }

  /**
   * Validates if new permission is valid.
   */
  validatePermission() {
    let isValid = true;

    if (this.validationService.isEmpty(this.permission.name)) {
      this.messageService.add({sticky: true, severity: "error", summary: "Permission Error", detail: "Name is required." });
      isValid = false;
    }
    if (this.validationService.isEmpty(this.permission.entity)) {
      this.messageService.add({sticky: true, severity: "error", summary: "Permission Error", detail: "Entity is required." });
      isValid = false;
    }
    if (this.validationService.isEmpty(this.permission.abilities)) {
      this.messageService.add({sticky: true, severity: "error", summary: "Permission Error", detail: "Permission should have at least 1 ability." });
      isValid = false;
    }

    return isValid;
  }

  /**
   * Load all permissions
   */
  async loadPermissions() {
    this.permissions = await this.permissionsService.getAllPermissions();
    const permissionTypes = await this.permissionsService.getPermissionTypes();
    
    this.entities = Object.keys(permissionTypes.entities)
      .map((key) => {
        return { name: permissionTypes.entities[key], value: key };
      });
    this.abilities = Object.keys(permissionTypes.abilities)
      .map((key) => {
        return { name: permissionTypes.abilities[key], value: permissionTypes.abilities[key] };
      });
  }

  /**
   * Create new permission
   */
  async createNewPermission() {
    if (this.validatePermission()) {
      try {
        let response = await this.permissionsService.createPermission(this.permission);

        if (response) {
          this.messageService.add({sticky: true, severity: "success", summary: "Permission Created", detail: "Permission created successfuly!" });
          await this.loadPermissions();
          this.onPermissionChange.emit(this.permissions);
          this.showAddNewPermission = false;
          this.permission = new PermissionDto();
          this.tableCommunicationService.emitEvent('reloadDataSource');
        }
      } catch (errorResponse: any) {
        let errorMessage = errorResponse && errorResponse.status == 403 && errorResponse.error ? errorResponse.error.error : "There was an error creating permission.";
        this.messageService.add({sticky: true, severity: "error", summary: "Permission Error", detail: errorMessage });
      }
    }
  }

  /**
   * Clear all messages from service
   */
  clearMessages() {
    this.messageService.clear();
  }
}