import { Component, OnInit } from '@angular/core';
import { BaseviewComponent } from 'src/app/common/components/baseview/baseview.component';

@Component({
  selector: 'app-collection-view',
  templateUrl: '../../../common/components/baseview/baseview.component.html',
  styleUrls: ['../../../common/components/baseview/baseview.component.sass'],
})
export class CollectionViewComponent
  extends BaseviewComponent
  implements OnInit
{
  type = 'collections';
  cards = [
    {
      name: '',
      fieldGroups: [
        [
          {
            name: 'Name',
            key: 'name',
            controlType: 'inputText',
            value: '',
          },
          {
            name: 'ID',
            key: 'id',
            controlType: 'inputText',
            value: '',
          },
          {
            name: 'Enabled',
            key: 'enabled',
            controlType: 'toggleButton',
            value: '',
          },
          {
            name: 'Start',
            key: 'start',
            controlType: 'calendar-start',
            value: '',
          },
          {
            name: 'End',
            key: 'end',
            controlType: 'calendar-end',
            value: '',
          },
          {
            name: 'Items',
            key: 'items_ref',
            controlType: 'autoComplete-multi_ref',
            value: '',
          },
        ],
      ],
    },
  ];
}
