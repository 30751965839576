import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-boolean-view',
  templateUrl: './boolean.component.html', // Asegúrate de que esta ruta es correcta
  styleUrls: ['./boolean.component.sass'] // Asegúrate de que esta ruta es correcta
})
export class BooleanViewComponent {
  @Input() value?: boolean | null;
  @Input() label?: string;
}