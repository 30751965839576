import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import TitlesDTO from '../../dtos/TitlesDTO';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-titles-form-v2',
  templateUrl: './titles-form-v2.component.html',
  styleUrls: ['./titles-form-v2.component.sass']
})
export class TitlesFormV2Component extends BaseDynamicFormComponent implements OnInit
{
  batchRecord: TitlesDTO = new TitlesDTO();
  constructor()
  {
    super();
  }

  /**
   * Titles Forms V2 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Titles Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name'
          },
          {
            name: "Misc. Image Record Ref.",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'image_ref',
            label: 'Misc. Image Record Ref.',
            columnWidth: 12,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'image_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              sort: { name: 1 },
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },          
          {
            name: "Description",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputTextArea],
            clearField: true,
            key: 'description',
            isRequired: false,
            label: 'Description'
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Bundle Images and Assets",
        fields:
        [
          {
            name: "Bundle Images (IOS)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            clearField: false,
            key: 'bundleImage',
            isRequired: false,
          },
          {
            name: "Bundle Images (AND)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            clearField: false,
            key: 'bundleImageAnd',
            isRequired: false,
          },
        ],
        type: DynamicCardType.Card,
        columns: 2
      }
    ]

    this.title = "Titles";
    this.viewRoute = 'titles';
    this.isLoading = false;
  }
}
