import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
/**
 * Service to be able to write to the console in development environments
 * and avoid logs within the production environment.
 */
export class LoggerService
{
  // Check if we are in production environment.
  isProd: boolean = environment.production;
  // Check for dev env
  isDev: boolean = false;

  constructor(private router: Router)
  {
    // Validates if we are on dev/localhost cms
    this.isDev = window.location.href.includes('dev.cms') || window.location.href.includes('localhost');
    // this.log('Logger service works. Is Prod?:', this.isProd, 'Logger Service');
  }

  /**
   * Write to console a given message
   *
   * @param message The message to log in console.
   * @param data Any additional data to display
   */
  log(message: string, data: any = null, from: any = '', functionFrom: any = '')
  {
    this.isProd && !this.isDev ? null: console.log(message, data, `From: ${from} - Function: ${functionFrom}`);
  }

  /**
   * Writes to console a given error message
   *
   * @param message The message to display in the error.
   * @param data Any additional data to display
   */
  error(message: string, data: any = null)
  {
    this.isProd && !this.isDev ? null : console.error(message, data);
  }

  /**
   * Write to console a given object as table form
   *
   * @param message The message to display in the error.
   * @param object The object to display
   */
  logObjectAsTable(message: string, object: any)
  {
    this.isProd && !this.isDev ? null: console.table(message, object);
  }
}
