import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import FieldData from '../field-data-dto';
import { DataService } from 'src/app/services/data.service';
import * as _ from 'lodash';
import { ValidationsService } from 'src/app/common/services/validations.service';

@Component({
  selector: 'app-filter-field',
  templateUrl: './base-filter-field.component.html',
  styleUrls: ['./base-filter-field.component.sass'],
})
export class BaseFilterFieldComponent implements OnInit {
  @ViewChild('nestedFilter_overlay') nestedOverlay: any;
  @Input() field: FieldData;
  @Input() isSubField: boolean = false;
  @Input() isTriStateCheckbox: boolean;

  @Input() value: any;
  @Output() valueChange = new EventEmitter();

  @Input() checkedState: boolean;
  @Output() checkedStateChange = new EventEmitter();

  @Input() options: any;
  suggestions: Array<any>;

  initValue: any;
  get valueCount(): number {
    if (_.isArray(this.value)) {
      return this.value.length;
    } else return 0;
  }

  @Input() nestedPresetValues: any;
  @Input() lineItemData: any;
  currentNestedGroup: any;

  get isComplex(): boolean {
    return ['nestedGroup', 'lineItem', 'formArray'].includes(
      this.field.controlType
    );
  }
  get isHidden(): boolean {
    return (
      this.lineItemData &&
      this.lineItemData.isLineItem &&
      ((['t'].includes(this.field.key) && this.lineItemData.type) ||
        (['id'].includes(this.field.key) &&
          this.lineItemData.type !== this.field.name) ||
        (['c'].includes(this.field.key) && !this.lineItemData.type))
    );
  }
  get hasValue(): boolean {
    return !this.validationsService.isEmpty(this.value);
  }

  @Output() apply = new EventEmitter();

  @Output() envExclusionEvent = new EventEmitter<any>();

  EnvValues: any = {
    'dev': {
      exclude: false,
    },
    'qa': {
      exclude: false,
    },
    'prod': {
      exclude: false,
    },
  };

  constructor(
    private dataService: DataService,
    private validationsService: ValidationsService
  ) {}

  ngOnInit() {
    // console.log(this.options);
    // set initValue and apply checked filters
    this.initValue = _.cloneDeep(this.value);
    this.applyIfChecked();
  }

  /** Applies filter if the checkedState is valid. */
  applyIfChecked() {
    if (this.checkedState == true || this.checkedState == false) {
      if (this.isComplex && this.value) {
        for (let i = 0; i < this.value.length; i++) {
          let nestedGroup = {
            index: i,
            values: _.cloneDeep(this.value[i]),
          };
          this.onApplyNested(nestedGroup);
        }
      } else {
        this.valueChange.emit(this.value);
        this.apply.emit();
      }
    }
  }

  /** Detects field value change and applies/executes filter.
   * * If value before change is null/undefined and field is un-checked, field will be auto-selected.
   */
  onApply() {
    if (
      (this.checkedState == null || this.checkedState == undefined) &&
      this.validationsService.isEmpty(this.initValue) &&
      !this.isSubField
    ) {
      this.checkedStateChange.emit(!this.isTriStateCheckbox);
    }

    this.initValue = _.cloneDeep(this.value);
    this.valueChange.emit(this.value);
    this.apply.emit();
  }

  /** Clears field value and applies/executes filter. */
  onClear() {
    this.initValue = null;
    this.value = null;

    this.checkedStateChange.emit(null);
    this.valueChange.emit(this.value);
    this.apply.emit();
  }

  /** Detects change in checkedState and applies/executes filter. */
  onSelect() {
    this.checkedStateChange.emit(this.checkedState);
    this.apply.emit();
  }

  envExclusionChange(event: any){
    this.envExclusionEvent.emit(this.EnvValues);
    this.apply.emit();
  }

  envStopPropagation(value: any, option: any, event: any){
    if(value.includes(option)){
      event.stopPropagation();
    }
  }

  /** Calls `this.getSuggestions()` function and assigns value (array) to global 'this.suggestions' variable. Used with controlTypes 'autoComplete_ref' & 'autoComplete-multi_ref'.
   * @param input Input for query.
   */
  async setSuggestions(input: any) {
    this.suggestions = await this.getSuggestions(input);
  }
  /** Retrieves suggestions in real-time, for controlTypes 'autoComplete_ref' & 'autoComplete-multi_ref'. Returns array of suggestions through api call to field's 'apiController' api endpoint.
   * @param input User input for query.
   * @returns Array of objects retrieved with '[Empty]' option appended when necessary.
   */
  async getSuggestions(input: any): Promise<any[]> {
    let output: any[] = await this.dataService
      .getAllOfTypeAsync(this.field.apiController!, {
        query: isNaN(input)
          ? { name: { $regex: input, $options: 'i' } }
          : { id: input },
        select: '_id name id',
        virtuals: this.field.isOptionsMin! ? false : true,
        autopopulate: this.field.isOptionsMin! ? false : true,
        sort: { name: 1 },
      })
      .then((result: any[]) => {
        return result;
      });

    return ['inCollection'].includes(this.field.matchMode!)
      ? output
      : [...output, { name: '[Empty]', _id: '[Empty]' }];
  }

  // FOR NESTED GROUPS

  /** Detects values in 'Nested Filter' overlay (currentNestedGroup), handles formatting discrepencies, and assigns them to appropriate group in this.value, then runs 'onApply()' function.
   * @param nestedGroup Default is value in variable 'this.currentNestedGroup', which is an object containing the *index* (number), the *values* (object), and (if controlType is 'lineItem') the *lineItemData* (object) for the nested group.
   * 'nestedGroup' parameter can also be passed in.
   */
  onApplyNested(nestedGroup: any = this.currentNestedGroup) {
    // define lineItemData
    nestedGroup.lineItemData = this.getLineItemData(this.field, nestedGroup);

    // modify values
    let groups: any[] = this.value ? this.value : [];
    if (this.groupHasValue(nestedGroup.values)) {
      for (const key of Object.keys(nestedGroup.values)) {
        // define field
        nestedGroup.values[key].subField =
          ['lineItem'].includes(this.field.controlType) && key == 'id'
            ? this.field.subFields?.find(
                (f: FieldData) =>
                  f.key === key && f.name == nestedGroup.lineItemData.type
              )
            : this.field.subFields?.find((f: FieldData) => f.key == key);

        if (_.isArray(nestedGroup.values[key].value)) {
          const array = _.cloneDeep(nestedGroup.values[key].value);
          nestedGroup.values[key].value = array[0];
        }
      }
      groups[nestedGroup.index] = nestedGroup.values;
    } else {
      groups.splice(nestedGroup.index, 1);
    }

    this.value = groups;
    this.onApply();
  }

  /** Opens a new or existing nested group in the 'Nested Filter' overlay.
   * @param e Browser event.
   * @param appendTo Browser element on which to append overlay.
   * @param index Index of group being opened.
   */
  onOpenNestedGroup(e: any, appendTo: any, index: number) {
    let isNewGroup: boolean = index === this.valueCount;

    let groups: any[] = this.value ? this.value : [];

    let nestedGroup: any = {
      index: index,
      values: groups[index] ? groups[index] : {},
      lineItemData: {},
    };

    if (isNewGroup) {
      // new nested group
      for (const subField of this.field.subFields!) {
        if (subField.isFilterField) {
          nestedGroup.values[subField.key] = {
            value:
              this.nestedPresetValues && this.nestedPresetValues[subField.key]
                ? this.nestedPresetValues[subField.key]
                : null,
          };

          this.nestedPresetValues && this.nestedPresetValues[subField.key]
            ? this.onApplyNested()
            : '';
        }
      }
    } else {
      // existing nested group
      for (const subField of this.field.subFields!) {
        if (
          subField.isFilterField &&
          !Object.keys(nestedGroup.values).includes(subField.key)
        ) {
          nestedGroup.values[subField.key] = { value: null };
        }
      }
    }
    nestedGroup.lineItemData = this.getLineItemData(this.field, nestedGroup);

    this.currentNestedGroup = nestedGroup;
    this.nestedOverlay.show(e, appendTo);
  }

  /** Removes group from value array.
   * @param index Index of group being removed.
   */
  onRemoveNestedGroup(index: number) {
    this.value.splice(index, 1);
    this.onApply();
  }

  /** Checks for value in all subFields nested in group.
   * @param group Group being checked.
   * @returns boolean — *true* if any subField in group has value. *false* if all subFields are empty.
   */
  groupHasValue(group: any) {
    let valCount: number = 0;
    for (let key of Object.keys(group)) {
      if (!this.validationsService.isEmpty(group[key].value)) {
        valCount++;
      }
    }
    return valCount > 0;
  }

  /** Parses nested value for chip representing nested group.
   * @param value Actual subField value.
   * @param controlType 'controlType' prop in FieldData
   * @returns string — Parsed subField value.
   */
  getNestedChipValue(value: any, controlType: string) {
    switch (controlType) {
      case 'dropdown_ref':
      case 'multiSelect_ref':
      case 'autoComplete_ref':
      case 'autoComplete-multi_ref':
        return value.name;

      case 'dropdown':
        return value.label;

      default:
        return value;
    }
  }

  /** * Creates and returns lineItemData object, based on field controlType and values in nestedGroup. Called in parent field.
   * @param field FieldData
   * @param nestedGroup Object containing properties *index* (index of group in field value), *values* (values of fields in group) and *lineItemData* (if group already exists).
   * Default value for parameter is this.currentNestedGroup.
   * @returns New lineItemData object, with properties *isLineItem* (boolean) and *type* (string representing lineItemType).
   */
  getLineItemData(field: any, nestedGroup: any = this.currentNestedGroup) {
    if (['lineItem'].includes(this.field.controlType)) {
      let lineItemData = {
        isLineItem: true,
        type: undefined,
      };

      if (
        Object.keys(nestedGroup.values).includes('t') &&
        !this.validationsService.isEmpty(nestedGroup.values.t.value)
      ) {
        lineItemData.type = _.isArray(nestedGroup.values.t.value)
          ? nestedGroup.values.t.value[0].name
          : nestedGroup.values.t.value.name;
      } else {
        const lineItemSub: any = field.subFields.find((f: FieldData) =>
          ['lineItemType'].includes(f.key)
        );
        lineItemData.type =
          lineItemSub && lineItemSub.presetValue
            ? lineItemSub.presetValue
            : null;
      }
      return lineItemData;
    } else {
      return {
        isLineItem: false,
        type: null,
      };
    }
  }
}
