import { Component, OnInit } from '@angular/core';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';


@Component({
  selector: 'app-achievement-view-v2',
  templateUrl: './achievement-view-v2.component.html',
  styleUrls: ['./achievement-view-v2.component.sass']
})
export class AchievementViewV2Component extends BaseDynamicViewComponent implements OnInit {
  rewardRecord: BaseEntityDto = new BaseEntityDto();

  achievementRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
   }

  ngOnInit(): void {
    this.fields =
    [
      {
        title: "Achievement Data",
        fields:
        [
          { name: "Title", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'title', isRequired: true, label: 'Title',columnWidth: 6 },
          { name: "SubText", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'subText', isRequired: true, label: 'SubText',columnWidth: 6 },
          { name: "Sorting Order", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'sortingOrder', isRequired: true, label: 'Sorting Order',columnWidth: 6 },
          { name: "Player Description", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'playerDescription', isRequired: true, label: 'Player Description',columnWidth: 6 },
          { name: "Interval",
           inputType: DynamicViewType.BaseViewField ,
           viewTypes: [BaseViewFieldType.DropdownWithLabels],
           clearField: true,
           key: 'interval',
           label: 'Interval',
           isRequired: true,
          options:
          {
            fieldName: 'interval',
            values:
            [
              {name: 'Continuous', value: 0},
              {name: 'Single / One and Done', value: 1},

            ]
          },columnWidth: 6 },

          { name: "Achievement Type",
           inputType: DynamicViewType.BaseViewField ,
           viewTypes: [BaseViewFieldType.DropdownWithLabels],
           clearField: true,
           key: 'achievementType',
           label: 'Achievement Type',
           isRequired: true,
          options:
          {
            fieldName: 'interval',
            values:
            [
              {name: 'Challenges submitted', value: 0},
              {name: 'Daily Gifts collected', value: 1},
              {name: 'Challenge Results w/ Score of X or above', value: 2},
              {name: 'Items submitted', value: 3},
              {name: 'Notifications', value: 4},
              {name: 'Facebook Sign In', value: 5},
              {name: 'Voting Rounds completed', value: 6},
              {name: 'Star Score Reached', value: 7},
              {name: 'IAPs completed', value: 8},
              {name: 'Contacts Integration', value: 9},
              {name: 'Plants Grown', value: 10},
              {name: 'Friend Invite', value: 11},
              {name: 'Friends Added', value: 12},
              {name: 'Watch Video', value: 13},
              {name: 'Minigames Rounds Completed', value: 14},
              {name: 'Infinite Challenges Submitted', value: 15},
            ]
          },
          columnWidth: 6 },
          { name: "Target", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.MultiSelect],  clearField: true, key: 'target', isRequired: true, label: 'Target',columnWidth: 6 },
          {
            name: "Is Repetitive/Scheduled",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            clearField: true,
            key: 'isRepetitive',
            label: 'Is Repetitive/Scheduled',
            isRequired: true,
            columnWidth: 6,
            booleanoptions: {
              true: "Achieved",
              false: "Not Achieved"
            }
          },
          { name: "Scheduled / Repetitive Event Duration (Hrs)", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text] , clearField: true, key: 'repeatsDuration', isRequired: true, label: 'Scheduled / Repetitive Event Duration (Hrs)',columnWidth: 6 },
          { name: "Scheduled / Repetitive Repeats On", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.MultiSelect] , clearField: true, key: 'repeats', isRequired: true, label: 'Scheduled / Repetitive Repeats On',columnWidth: 6 },
          { name: "Image", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'image', isRequired: true, label: 'Image' ,columnWidth: 6},
          { name: "Consecutive",  inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'isConsecutive', isRequired: true, label: 'Consecutive',columnWidth: 6 },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time',columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time',columnWidth: 6 },
          { name: "Promo Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'promoStartDate', label: 'Promo Start Date/Time',columnWidth: 6 },
          { name: "Promo End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'promoEndDate', label: 'Promo End Date/Time',columnWidth: 6 },
          {
            name: "Link Destination", inputType: DynamicViewType.NestedList, clearField: true, key: 'link_ref', label: 'Link Destination',
            options: { fieldName: 'Link Destination', keys: ['linkText', 'linkDestination'] },
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Rewards Data",
        fields:
        [
          { name: "Rewards", inputType: DynamicViewType.Price,  clearField: true, key: 'rewards_ref', isRequired: true, label: 'Rewards'},
          { name: "Promo Rewards", inputType: DynamicViewType.Price,  clearField: true, key: 'promoRewards_ref', isRequired: true, label: 'Promo Rewards'},
        ],
        type: DynamicCardType.Card
      }
    ]
    this.rewardRecord =
    {
      enabled: false
    };
    this.title = "Reward";
    this.viewRoute = 'rewards';
    this.isLoading = false;
  }

}
