import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import _, { result } from 'lodash';
import { Router } from '@angular/router';
import { LoggerService } from '../common/services/logger.service';
import { UserContextService } from '../common/services/user-context.service';
import { AssetTypes } from '../entities/enums/AssetTypes';
import { DataService } from '../services/data.service';
import { EntityViewService } from '../services/entity-view.service';
import { BuildService } from './services/build.service';
import { BuildType } from '../enums/build-type';
import { PromotionService } from '../services/promotion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'build-data',
  templateUrl: './build-data.component.html',
  styleUrls: ['./build-data.component.sass'],
})
export class BuildDataComponent implements OnInit
{
  @Input() parentEntity: any;
  @Input() buildData: any;
  @Input() buildStatus: any;
  @Input() buildType: any;
  @Input() entity: any;
  @Input() type: any;
  @Input() hideBuildTimeHistory: boolean = false;
  @Input() isCustomBuildData: boolean = false;
  @Input() customPath: string | null = null;
  @Output() triggerRefreshEvent = new EventEmitter<any>();

  private refreshInterval: any;
  private maxRefreshAttempts: number = 5;
  private refreshAttempts: number = 0;
  get buildTypes(): typeof BuildType
  {
    return BuildType;
  }
  isLoading: boolean = true;
  displayDataModal: boolean = false;
  promotionDisabledMessage: string = "Promotion Blocked: Item Content and Art status must be approved, and have a start date.";


  constructor(
    public entityViewService: EntityViewService,
    private dataService: DataService,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private router: Router,
    private buildService: BuildService,
    private promotionService: PromotionService
  ) {}

  /**
   * Build Data Component Initialization
   */
  async ngOnInit() {

    this.isLoading = false;
    this.startAutoRefresh();
  }

  ngOnDestroy() {
    this.stopAutoRefresh();
  }

  /**
   * Retrieves the color from the build status value.
   *
   * @param status Build status.
   */
  getColorFromBuildStatus(status: string) {
    status = status.toLowerCase();
    let output = '';
    switch (status) {
      case 'finished':
        output = 'var(--primary-color)';
        break;
      case 'failed':
        output = 'var(--pink-600)';
        break;
      case 'building':
        output = 'var(--yellow-600)';
        break;
      case 'queued':
        output = 'var(--gray-400)';
        break;
    }
    return output;
  }

  /**
   * Retrieves the color from the build bundle file size based on a treshold.
   *
   * @param status Build status.
   */
  getColorFromFileSize(build: any) {
    let sizeString = this.getSize(build.files);
    sizeString = sizeString.replaceAll(" ","");
    sizeString = sizeString.substring(0,sizeString.lastIndexOf("KB"));
    let size = parseFloat(sizeString);

    if(build.path.includes('levels')){
      if ((size > 3000) || (size < 500) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('images/challenge_feed' )){
      if ( (size > 1100) || (size < 100) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('items' )){
      if ( (size > 8000) || (size < 250)){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('images/item_thumbs' )){
      if ( (size > 1100) || (size < 100) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else {
      return 'var(--gray-100)';
    }

  }

  /**
   * returns the value of the first key (angular had trouble doing it.)
   */
  getSize(obj: any) {
    return obj[Object.keys(obj)[0]];
  }


 /**
   * Starts the auto-refresh process.
   */
 private startAutoRefresh() {
  this.refreshAttempts = 0; // Reset attempts
  this.refreshInterval = setInterval(() => {
    if (this.refreshAttempts < this.maxRefreshAttempts) {
      // console.log(`Refresh attempt #${this.refreshAttempts + 1}`);
      this.getBuildData();
      this.refreshAttempts++;
    } else {
      this.stopAutoRefresh();
    }
  }, 15000);
}

/**
 * Stops the auto-refresh process.
 */
private stopAutoRefresh() {
  if (this.refreshInterval) {
    clearInterval(this.refreshInterval);
    this.refreshInterval = null;
  }
}

/**
 * Check for new build data
 */
async getBuildData() {
  this.isLoading = true;
  if (!this.isCustomBuildData) {
    let result = await this.buildService.getBuildData(this.buildData);
    if (result) {
      this.entity.buildData = result.buildData;
      this.entity.buildStatus = result.buildStatus;
    }
  }

  if (this.type == 'items' || this.type == 'challenges') {
    await this.dataService.getFileSizeData(this.entity.id, this.type).then(async (results: any) => {
      return results
    })
  }
  this.triggerRefreshEvent.emit(this.entity);
  this.isLoading = false;
}


  /**
   * Sets the plaform name to display on the detailed data
   * table.
   *
   * @param platform The current platform.
   */
  setPlatformName(platform: string) {
    switch (platform) {
      case 'and':
        return 'Android';
      case 'ios':
        return 'IOS';
      case 'lin':
        return 'Linux';
      case 'mac':
        return 'MAC';
      case 'win':
        return 'Windows';

      default:
        return '';
    }
  }

  /**
   * Sets the build status color.
   *
   * @param status The current status of the build.
   */
  setPlatformStatusColor(status: string) {
    status = status.toLowerCase();
    switch (status) {
      case 'finished':
        return 'success';
      case 'failed':
        return 'danger';
      case 'building':
        return 'warning';
      case 'queued':
        return 'secondary';
      default:
        return 'secondary';
    }
  }

  /**
   * Handle Promotion of Assets and Images
   */
  async onPromote(promoteBoth: boolean = false, newHash: boolean = false) {
    let recordPayload = {
      from: 'dev',
      to: 'prod',
      entity: this.entity,
      buildType: this.buildType,
      newHash: newHash
    };

    if (promoteBoth) {
      if (this.type == 'items' || this.type == 'challenges') {
        let asset;
        let image;

        if (this.type == 'items' && this.parentEntity) {
          asset = this.parentEntity.prefab_ref;
          image = this.parentEntity.thumbnail_ref;
        } else if (this.type == 'challenges' && this.parentEntity) {
          asset = this.parentEntity.scene_ref;
          image = this.parentEntity.image_ref;
        }

        // Promote Asset
        if (asset && asset.id && asset.path) {
          await this.promotionService.onPromoteRecord(
            this.type == 'items' ? BuildType.Items : BuildType.Levels,
            {
              from: 'dev', to: 'prod',
              entity: asset, buildType: this.type == 'items' ? BuildType.Items : BuildType.Levels,
              newHash: newHash
            },
            true,
            this.type
          );
        }
        // Promote Image
        if (image && image.id && image.path) {
          await this.promotionService.onPromoteRecord(
            BuildType.Images,
            {
              from: 'dev', to: 'prod',
              entity: image, buildType: BuildType.Images,
              newHash: newHash
            },
            true,
            this.type
          );
        }
      } else {
        await this.promotionService.onPromoteRecord(this.buildType, recordPayload, true, this.type);
      }
    } else {
      await this.promotionService.onPromoteRecord(this.buildType, recordPayload, true, this.type);
    }
  }

  isPromotionDisabled() {
    if (environment.env == "test") {
      this.promotionDisabledMessage = "Promotion disabled in test environment";
      return true;
    }
    if (this.buildType == this.buildTypes.Items) {
      if (this.parentEntity && this.parentEntity.itemStatus == 'Approved' && this.parentEntity.vendorStatus == 'Approved' && this.parentEntity.start != null) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}
