<div class="p-card p-m-3 p-p-4 p-grid">
  <div class="p-col-12">
    <h2>
      Scene
    </h2>
  </div>
  <div class="p-col-12">
    <span class="p-float-label p-fluid">
      <p-autoComplete
          [style]="{'width': '100%'}"
          id="challenge-search"
          dropdownIcon="pi pi-search"
          inputId="autocomplete"
          [(ngModel)]="globalSearchQueryValue"
          [suggestions]="options"
          (completeMethod)="globalSearch($event)"
          field="name"
          appendTo="body"
          [autoHighlight]="true"
          scrollHeight="250px"
          class="p-inputtext-lg"
          (onSelect)="onSelectedEntity($event)"
          (onKeyUp)="onEnterPress($event)"
          >
          <ng-template let-entity pTemplate="item">
            <div>
                <div>{{entity.name}} - ({{entity.id}})</div>
            </div>
        </ng-template>
      </p-autoComplete>
      <label for="challenge-search">Scene Search</label>
    </span>
  </div>
  <div class="p-col-12">
    <h3 class="p-d-inline">
      Spawners Selection
    </h3>
    <p-button pButton [ngStyle]="{'height': '35px'}" class="p-ml-2" label="Preview Scene" icon="pi pi-arrow-down" iconPos="left" (click)="scroll('down')"></p-button>
  </div>
  <div class="p-col-12" *ngIf="!challengeFetched || !challenge">
    <p class="p-ml-2">Please select a scene with spawners</p>
  </div>
  <div class="p-col-12" *ngIf="challengeFetched && challenge">
    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-grid">
          <div *ngFor="let spawner of challenge.spawners" class="p-col-4 p-p-1"
          [ngStyle]="{ 'border-style': 'solid', 'border-radius': '10px', 'border-width': '1px', 'border-color': 'var(--surface-400)'}"
          >
            <div class="p-grid">
              <div class="p-col-7">
                <div>
                  <p class="p-mr-2" style="margin:3px;">Spawner <strong>ID:</strong> {{spawner.id}}</p>
                  <div style="margin:3px;">
                    <p class="p-d-inline p-mr-2"><strong>Name:</strong> {{spawner.name}}</p>
                  </div>
                  <div style="margin:3px;">
                    <p class="p-d-inline p-mr-2"><strong>Categories:</strong> {{spawner.categories}}</p>
                    <p class="p-d-inline p-mr-2"><strong>Required:</strong> {{spawner.isRequired}}</p>
                  </div>
                </div>
              </div>
              <div class="p-col-5">
                <span class="p-float-label" [style]="{ width: 'auto' }">
                  <p-autoComplete
                    [multiple]="false"
                    [suggestions]="suggestions['items_ref']"
                    (completeMethod)="getSuggestionsForRef($event.query, 'items_ref', 'items')"
                    [showEmptyMessage]="true"
                    minLength="3"
                    optionValue="_id"
                    optionLabel="name"
                    field="name"
                    [style]="{ width: '100%' }"
                    showClear="true"
                    [(ngModel)]="spawners[spawner.id].item"
                    (onSelect)="spawnerChange($event, spawner.id)"
                  >
                    <ng-template let-item pTemplate="item">
                      {{ item.name }} ({{ item.id }})
                    </ng-template>
                  </p-autoComplete>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid" *ngIf="challengeFetched && challenge">
    <div class="p-col-8">
      <h4 class="p-d-inline">
        Scene: {{challenge.name}} -
        <a href="/challenges/{{ challenge.id }}" [ngStyle]="{ color: 'var(--primary-color)' }">({{challenge.id}})</a>
      </h4>
      <h4 *ngIf="challenge.spawners" class="p-d-inline">
        Spawners Count: {{challenge.spawners.length}}
      </h4>
    </div>
  </div>
  <div class="p-col-12 p-d-flex p-flex-column p-ai-center ">
    <p-image [src]="sceneRenderURL" alt="Image" width="100%" preview="true"></p-image>
  </div>
</div>
<!-- Spinner -->
<ngx-spinner
bdColor="rgba(36,34,36,0.8)"
size="medium"
color="#81c784"
type="ball-spin-clockwise"
>
<p style="font-size: 20px; color: white">Loading Challenge Spawners...</p>
</ngx-spinner>
