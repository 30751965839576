<div
  class="p-d-flex"
  style="
    white-space: normal;
    word-wrap: break-word;
    overflow: auto;
    text-align: left;
    line-height: 1.2;
    height: auto;
    object-fit: contain;
    word-break: break-word;
  "
>
  <p>
    hello
  </p>
</div>


