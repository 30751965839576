import { DataService } from 'src/app/services/data.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { settingsConstants } from 'src/app/entities/settings/constants';
import { TableService } from 'src/app/services/table.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FormService } from 'src/app/services/form.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ViewportScroller } from '@angular/common';
@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.sass'],
  providers: [ConfirmationService],
})
export class RulesComponent implements OnInit {
  settingsConstants = settingsConstants;
  label: string = 'Add New Rule';

  type: string = 'rules';

  submitUrl: string;
  action = 'add';
  id: number;
  isEditMode: boolean = false;
  existingDoc: any = {};

  cols: any;
  rows: any;
  display: boolean = false;
  rule: any;
  submitted: boolean;
  checked: boolean;
  options: any = [];

  fields: any = {};
  selectedRows: any[] = [];

  rulesForm: UntypedFormGroup;
  newRulesData: any;
  editRulesDialog: any;
  rulesDialog: any;
  newRulesDialog: any;

  constructor(
    private messageService: MessageService,
    private tableService: TableService,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private dataService: DataService,
    private authService: AuthService,
    private loggerService: LoggerService,
    private confirmationService: ConfirmationService,
    private scroller: ViewportScroller
  ) {}

  async ngOnInit() {
    this.submitUrl = `${this.type}/${this.action}`;

    this.fields = {
      name: null,
      desc: null,
      enabled: false,
      entity: null,
      type: null,
      column: null,
      ref: null,
      min: null,
      max: null,
    };

    await this.initForm();

    // Get rules data
    await this.tableService.getRows(this.type).then(async (data: any) => {
      this.rows = data;
    });
  }

  /**
   * initForm sets the values of the form to be the object listed above in this.fields
   */

  async initForm() {
    this.rulesForm = this.fb.group({
      name: [this.fields.name],
      desc: [this.fields.desc],
      entity: [this.fields.entity],
      type: [this.fields.entity],
      ref: [this.fields.ref],
      column: [this.fields.column],
      min: [this.fields.min],
      max: [this.fields.max],
      enabled: [this.fields.enabled],
    });

    await this.setOptions();
  }

  /**
   * openNew and hideDialog both functions that used along with primeng dialogs to open and close the dialogs
   */

  openNew() {
    this.fields = {};
    this.submitted = false;
    this.rulesDialog = true;
  }
  hideDialog() {
    this.rulesDialog = false;
    this.editRulesDialog = false;
  }
  /**
   * updateRulesData function collects values of the edit form and updates the existing rules record.
   */
  async updateRulesData() {
    let id = this.rulesForm.get('id')!.value;
    let name = this.rulesForm.get('name')!.value;
    let desc = this.rulesForm.get('desc')!.value;
    let entity = this.rulesForm.get('entity')!.value;
    let type = this.rulesForm.get('type')!.value;
    let ref = this.rulesForm.get('ref')!.value;
    let min = this.rulesForm.get('min')!.value;
    let max = this.rulesForm.get('max')!.value;
    let enabled = this.rulesForm.get('enabled')!.value;

    this.loggerService.log('id', id);
    this.loggerService.log('name', name);
    this.loggerService.log('Description', desc);
    this.loggerService.log('ref', ref);
    this.loggerService.log('min', min);
    this.loggerService.log('max', max);
    this.loggerService.log('enabled', enabled);
    this.loggerService.log('entity', entity);
    this.loggerService.log('type', type);

    let isValid = this.validateRuleForm();

    if (isValid) {
      await this.dataService.updateRecord(id, this.type, {
        name: name,
        desc: desc,
        entity: entity,
        ref: ref,
        // column: column,
        type: type,
        min: min,
        max: max,
        enabled: enabled,
      });
      await this.tableService.getRows(this.type).then(async (data: any) => {
        this.rows = data;
      });
      this.rulesForm.reset();
      this.editRulesDialog = false;
    }
  }

  /**
   * This function is used on the table to provide a confirmation before deleting a rule
   * Param @row is used to collect that particular table row data
   * After delete is completed, .getRows retrieves data in an effort to refresh table
   */

  async deleteConfirm(row: any) {
    this.type = 'rules';
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete ${row.name}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.deleteRecord(row.id, this.type);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.rows = data;
        });
      },
    });
  }

  /**
   * Submit New Rule
   *
   * This is the main function used to save new rules.
   */

  submitNewRule() {
    this.loggerService.log('onSubmitNewRecord');
    this.submitted = true;
    this.newRulesDialog = false;
    this.submitUrl = 'rules/add';
    this.type = 'rules';
    var { value } = this.rulesForm;

    this.loggerService.log('submitUrl', this.submitUrl);
    this.loggerService.log('value', value);
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    let isValid = this.validateRuleForm();

    if (isValid) {
      this.formService.submitForm(value, this.submitUrl, false).subscribe(
        async (val) => {
          console.log('POST call successful value returned in body', val);
          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Submit Successful',
            detail: `"${value.name}" was successfully created`,
          });
          window.scrollTo(0, 0);
          this.rulesDialog = false;
          await this.tableService.getRows(this.type).then(async (data: any) => {
            this.rows = data;
          });
          this.rulesForm.reset();
        },
        async (response) => {
          console.log('POST call in error', response);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Submit Error',
            detail: 'There was an error submitting.',
          });
          window.scrollTo(0, 0);
          await this.tableService.getRows(this.type).then(async (data: any) => {
            this.rows = data;
          });
        },
        () => {
          console.log('The POST observable is now completed.');
        }
      );
    }
  }

  /**
   * setOptions Function
   * Purpose:
   * - sets all options for entity, type and ref
   */

  async setOptions() {
    this.options['entity'] = ['challenges', 'items'];
    this.options['type'] = ['threshold'];
    this.options['ref'] = [];
  }

  /**
   * Controls the rules editing dialog
   * @param record this is providing data to the edit form
   */
  editRulesModal(record: any) {
    this.isEditMode = true;
    this.editRulesDialog = true;

    this.rulesForm = this.fb.group({
      id: [record.id],
      name: [record.name],
      desc: [record.desc],
      entity: [record.entity],
      type: [record.type],
      ref: [record.ref],
      columns: [record.column],
      min: [record.min],
      max: [record.max],
      enabled: [record.enabled],
    });
  }

  /**
   *
   * @returns isValid which determines whether or not to display alerts
   * Purpose:
   * Valids the form if it meets all requirements
   * Make sure the min is never greater than the max value
   */

  validateRuleForm() {
    let isValid = true;

    if (
      this.rulesForm.value.min &&
      this.rulesForm.value.max &&
      Number(this.rulesForm.value.min) > Number(this.rulesForm.value.max)
    ) {
      this.alertMessage(false, 'Submit Error', 'Min is greater than Max.');
      isValid = false;

      this.scroller.scrollToAnchor('require-fields');
    }
    return isValid;
  }

  /**
   * Display alter message
   *
   * @param success Flag that sets whether or not is a succes alert
   * @param message Message to display.
   * @param detail Additional details.
   */
  alertMessage(success: boolean = true, message: string, detail: string) {
    this.messageService.add({
      sticky: true,
      severity: success ? 'success' : 'error',
      summary: message,
      detail: detail,
    });
  }

  /**
   *
   * @param entity grabs the entity value from the rules form
   * Purpose: sets the options for ref options based on the which entity is chosen from the dropdown options
   */

  setRefOptions(entity: any) {
    this.loggerService.log('checking entity', entity.value);

    if (entity.value === 'challenges') {
      this.options['ref'] = ['prizes_ref', 'rewards_ref'];
    }

    if (entity.value === 'items') {
      this.options['ref'] = ['cost_ref'];
    }
  }
}
