import { Injectable, ViewChild } from '@angular/core';
import { DynamicFormV2Component } from '../components/dynamic-form-v2/dynamic-form-v2.component';
import { AbstractControl, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BaseInputValidationServiceService {

  constructor() { }

  colorValidator(value: string): boolean {
    const hexColorPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
    return hexColorPattern.test(value);
  }


  strictNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;
      const isStrictlyNumber = !isNaN(parseFloat(value)) && isFinite(value);
      return isStrictlyNumber ? null : { 'notStrictlyNumber': { value } };
    };
  }

  validateName(inputValue: string): boolean {
    return /[A-Z].*[A-Z].*[A-Z]/.test(inputValue); // Example validation logic
  }

  validateBlankSpace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputValue = control.value || '';
      const isBlankOrSpaces = inputValue.trim().length === 0;
      return isBlankOrSpaces ? { 'blankOrSpaces': { value: control.value } } : null;
    };
  }

  validateDateRange(startDate: any, endDate: any) {
    // If either startDate or endDate is null or empty, return true
    if (!startDate || !endDate) {
      return true;
    }

    // Convert startDate to Date object if it's a string
    if (typeof startDate === 'string') {
      startDate = new Date(startDate);
    }

    // Convert endDate to Date object if it's a string
    if (typeof endDate === 'string') {
      endDate = new Date(endDate);
    }

    // Check if both dates are valid Date objects
    if (!(startDate instanceof Date) || isNaN(startDate.getTime())) {
      return false;
    }

    if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
      return false;
    }

    // Check if startDate is before endDate
    return startDate.getTime() < endDate.getTime();
  }

}



