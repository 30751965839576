import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'string-popup-form',
  templateUrl: './string-popup-form.component.html',
  styleUrls: ['./string-popup-form.component.css'],
  providers: [ConfirmationService]
})
export class StringPopupFormComponent implements OnInit
{
  @Input() isVisible: boolean = false;
  @Input() type: string;
  @Input() isEdit: boolean = false;
  @Input() record: any;
  @Output() formSubmitted = new EventEmitter<boolean>();
  @Output() onHide = new EventEmitter<any>();

  entity: any = {
    _recordId: null,
    Source_en: null,
    Target_frFR: null,
    Target_esMX: null,
    Target_deDE: null,
    verified: null,
    translationRequest: null,
  }
  values: any ={ id: null , cells: [
     {columnId: 'Source_en', value: null},{columnId: 'Target_frFR' , value: null}, {columnId: 'Target_esMX' , value: null},{columnId: 'Target_deDE' , value: null},{columnId: 'verified' , value: null},{columnId: 'translationRequest', value: null}
  ]}

  editValues: any = {
    id: null, cells: [ { columnId: 'Source_en', value: null} ]
  }
  //


  constructor(
    private readonly http: HttpClient,
    private readonly messageService: MessageService,
  ) {}

  /**
   * Build Asset Component Initialization
   */
  ngOnInit(): void
  {
    
  }


  formSubmittedTrigger(event:any){
    this.formSubmitted.emit(true);
  }

  onShow() {
    console.log(this.record)
    this.entity._recordId = this.record ? this.record.id : null;
    this.entity.Source_en = this.record ? this.record.Source_en : null;
  }

  onHideEmitter(){
    console.log('onHideEmitter');
    this.onHide.emit({hide: true});
  }

  hideDialog() {
    this.isVisible = false;
    this.onHideEmitter()
  }

  submitForm() {
    if(this.isEdit) {
      this.values.id = this.entity._recordId ? this.entity._recordId : null;
      this.values.cells[0].value = this.entity.Source_en ? this.entity.Source_en : null;


      this.http.post(this.type == 'localization/client-strings' ? '/api/localization/client-strings/update' : '/api/localization/update', this.values)
      .subscribe(
        (response) => {
          console.log('Post request successful:', response);
          // Handle success response if needed

          this.messageService.add(
            {
              sticky: true,
              severity: 'success',
              summary: 'Successfuly Updated a Record',
            });
        },
        (error) => {
          console.error('Error in post request:', error);
          // Handle error response if needed

          this.messageService.add(
            {
              sticky: true,
              severity: 'warning',
              summary: error,
            });
        }
      );
    } else {
      this.values.id = this.entity._recordId ? this.entity._recordId : null;
      this.values.cells.forEach((item: any) => {
        if (this.entity.hasOwnProperty(item.columnId)) {
          item.value = this.entity[item.columnId];
        }
      });

      console.log(this.values); // Optional: Log values before sending

      this.http.post(this.type == 'localization/client-strings' ? '/api/localization/client-strings/create' : '/api/localization/create', this.values)
        .subscribe(
          (response) => {
            console.log('Post request successful:', response);
            // Handle success response if needed

            this.messageService.add(
              {
                sticky: true,
                severity: 'success',
                summary: 'Successfuly Added a Record',
              });
          },
          (error) => {
            console.error('Error in post request:', error);
            // Handle error response if needed

            this.messageService.add(
              {
                sticky: true,
                severity: 'warning',
                summary: error,
              });
          }
        );
    }

      this.hideDialog();
  }




}
