import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Item DTO
 */
export default class itemDTO extends BaseEntityDto {
    name: any
    blurb: any
    blurbStatus: any
    cultivar: any
    latinName: any
    fileName: any
    plantFamily: any
    start: any
    end: any
    reReleaseStart: any
    bundleAsset: any
    bundleImage: any
    bundleAssetAnd: any
    bundleImageAnd: any
    reReleaseEnd: any
    category_ref: any
    nurture_ref: any
    type_ref: any
    climates_ref: any
    itemSet_ref: any
    colors_ref: any
    styles_ref: any
    traits_ref: any
    materials_ref: any
    patterns_ref: any
    shape_ref: any
    batch_ref: any
    enabled: any
    year: any
    spruceDataStatus: any
    thumbnail: any
    prefab: any
    vendor_ref: any
    vendorStatus: any
    vendorNotes: any
    vendorDimensions: any
    itemStatus: any
    contentHold_ref: any
    // recolorSource: any
    internalNotes: any
    height: any
    spread: any
    dimensionX: 0
    dimensionY: 0
    dimensionZ: 0
    radius: 0
    progressionLevel_ref: any
    itemFileType_ref: any
    externalPlantData_ref: any
    assetType: any
    costs_ref: any = []
    referenceLinks: any = []
    referenceImages: any = []
    internalReferenceLinks: any = []
    internalReferenceImageLinks: any = []
    vendorHeight: any
    vendorLength: any
    isLocked: any
    releatedItems: any = []
    env: any = []
    tags_ref: any = []
    flagged: any
    spruce_img: any
    newThumbnail_img: any
    thumbnail_img: any
    // referenceImageLinks: []
    keywords_ref: any = [];
    // audio fields
    spawnAudios_ref: any;
    loopAudios_ref: any;
    spawnAudioCollections_ref: any;
    loopAudioCollections_ref: any;
    dominantColors: any[];
}
