import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataService } from 'src/app/services/data.service';
import { TableCheckbox } from 'primeng/table';
import * as _ from 'lodash';
import ChallengeCollectionDto from '../../dtos/ChallengeCollectionDTO';

interface DataChallenge {
  id: number;
  name: string;
  selected?: boolean;
}
@Component({
  selector: 'challenge-collection-custom-field',
  templateUrl: './challenge-collection-custom-field.component.html',
  styleUrls: ['./challenge-collection-custom-field.component.sass'],
  providers: [ConfirmationService]
})
export class ChallengeCollectionCustomFieldComponent implements OnInit
{
  @Input() collection: ChallengeCollectionDto = new ChallengeCollectionDto();  
  @ViewChild('dt') table: any
  @Output() onChange = new EventEmitter<any>();
  challengesRefTableValue: Array<any> = [];
  suggestions: any = [];
  isLoading: boolean = true;
  // bulk edit variables
  isBulkDelete: boolean = false;
  selectedChallenges: Array<any> = [];
  lastClickedIndex: number;
  selectedChallenge: any = null;

  constructor
  (
    private validationService: ValidationsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private dataService: DataService,
  ) { }

  ngOnInit() {
    if (!this.collection.challenges_ref) {
      this.collection.challenges_ref = [];
    }
    this.challengesRefTableValue = this.collection.challenges_ref.length > 0
      ? this.collection.challenges_ref.map(challenge => ({ id: challenge.id, name: challenge.name, _id: challenge._id }))
      : [];
    this.isLoading = false;
  }  

    /**
   * Get suggestions for auto-complete
   *
   * @param event Event comming from the component
   * @param fieldName Name of the field to set suggestions
   * @param model API endpoint route
   */

  async getSuggestionsForRef(event: any, fieldName: string | any, model: string) {
    this.dataService
      .getAllOfType(model, {
        query: isNaN(event)
          ? { name: { $regex: event, $options: 'i' } }
          : { id: event },
        select: '_id name id',
        virtuals: false,
        autopopulate: false,
        sort: { name: 1 },
      })
      .subscribe((result) => {
        // Exclude challenges already in the table
        this.suggestions[fieldName] = result.filter(
          (challenge: any) =>
            !this.challengesRefTableValue.some(
              (item: any) => item.id === challenge.id
            )
        );
      });
  }

  onSelection(challenge: any) {
    if (this.challengesRefTableValue) {
      // Check if the challenge already exists
      const exists = this.challengesRefTableValue.some(
        (item) => item.id === challenge.id
      );
  
      if (!exists) {
        this.challengesRefTableValue.push(challenge);
        this.collection.challenges_ref = [...this.challengesRefTableValue];
        this.messageService.add({
          severity: 'success',
          summary: 'Challenge Added',
          detail: `${challenge.name} (${challenge.id}) has been added to the table. Click Submit to apply changes.`,
          life: 2000
        });
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: 'Challenge Exists',
          detail: `${challenge.name} (${challenge.id}) is already in the table.`,
          life: 2000
        });
      }
    }
    this.selectedChallenge = null; // Reset the autocomplete input
    this.onChange.emit(this.collection.challenges_ref);
  }

  confirmRemoveChallengeRef(row:any)
  {
    this.confirmationService.confirm(
    {
      message: `Are you sure that you want to remove ${row.name} (${row.id}) ?`,
      accept: async () =>
      {
        // removing item from table and collection's items_ref
        this.challengesRefTableValue = this.challengesRefTableValue.filter(function( challenge ) {
          return challenge.id !== row.id;
        });
        this.collection.challenges_ref = this.collection.challenges_ref.filter(function( challenge ) {
          return challenge.id !== row.id;
        });
        this.onChange.emit(this.collection.challenges_ref);
        this.messageService.add({
          severity: 'warn',
          summary: 'Challenge Removed',
          detail: ` ${row.name} (${row.id}) has been removed from the table. Click Submit to apply changes.`,
          life: 2000
        });
      },
    });
  }

  // Toggle bulk delete mode
  toggleBulkDelete(event: any) {
    this.isBulkDelete = event.checked;
    if (!this.isBulkDelete) {
      this.selectedChallenges = [];
      this.challengesRefTableValue.forEach(item => item.selected = false);
    }
  }

  // Confirm and remove selected items
  confirmRemoveSelectedChallenges() {
    this.selectedChallenges = [...new Set(this.selectedChallenges)]
    this.selectedChallenges = this.selectedChallenges.filter( value => typeof value == 'object' )
    const challengesList = this.selectedChallenges.map(challenge => `${challenge.name} (${challenge.id})`).join(', ');
  
    this.confirmationService.confirm({
      message: `Are you sure you want to remove the following challenges? ${challengesList}`,
      accept: () => {
        const removedChallengeCount = this.selectedChallenges.length;  // Store the count of items to be removed
  
        this.selectedChallenges.forEach(selectedChallenge => {
          this.challengesRefTableValue = this.challengesRefTableValue.filter(challenge => challenge.id !== selectedChallenge.id);
          this.collection.challenges_ref = this.collection.challenges_ref.filter(challenge => challenge.id !== selectedChallenge.id);
        });
        
        this.onChange.emit(this.collection.challenges_ref);
        this.selectedChallenges = [];
  
        // Add a message confirming the number of items removed
        this.messageService.add({
          severity: 'warn',
          summary: 'Challenges Removed',
          detail: `${removedChallengeCount} challenges(s) have been removed successfully. Submit form to save changes.`,
          life: 2000
        });
      }
    });
  }
  

  updateSelectedChallenges(challenge: any) {
    if (challenge.selected) {
      this.selectedChallenges.push(challenge);
    } else {
      const index = this.selectedChallenges.findIndex(selectedChallenge => selectedChallenge.id === challenge.id);
      if (index > -1) {
        this.selectedChallenges.splice(index, 1);
      }
    }
  }
  checkboxTable(tableCheckBox: TableCheckbox, event: any) {
    console.log(event);
    const isChecked = !!event.target.ariaChecked;
    if (event.button === 0 && event.shiftKey) {
      if (isChecked) {
        this.table.selectRange(event, tableCheckBox.index);
      } else {
        this.table.rangeRowIndex = tableCheckBox.index;
        this.table.clearSelectionRange(event);
        this.table.tableService.onSelectionChange();
      }
    }
    this.table.anchorRowIndex = tableCheckBox.index;
  }
  /** Handle checkbox toggle envents on a table row
   * @param row Row that has the checkbox toggle
   * @param isChecked Flag that sets whether or not is checked
   */
  onRowCheckboxToggle(row: any, isChecked: boolean) {
    let rowSelection: any[] = [...this.selectedChallenges];
    if(row) {
      console.log(row, isChecked)
      if (Array.isArray(row)) {
        rowSelection = [...rowSelection, row.reduce((acc, val) => acc.concat(val.id), [])]
        rowSelection = rowSelection.reduce((acc, val) => acc.concat(val), [])
      } else {
        if (isChecked) {
          rowSelection.push(row);
          // this.messageService.add({
          //   severity: 'success',
          //   summary: `Row Selected`,
          //   detail: 'Row selected successfully',
          //   life: this.toasterLife
          // });
        } else {
          let rowIndex: number = rowSelection.findIndex(
            (id: number) => id == row.id
          );
          rowSelection.splice(rowIndex, 1);
        }
    }

    }
    this.selectedChallenges = _.sortedUniq(_.sortBy(rowSelection));
  }
}
