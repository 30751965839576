import { BaseViewFieldType } from "../../dynamic-view-v2/enums/BaseViewFieldType";
import DynamicFormFieldDTO from "./DynamicFormFieldDTO";

/**
 * Dynamic Options Object
 */
export default class DynamicOptionsObject
{
    /**
     * Name of the field
     */
    fieldName: string = "";
    /**
     * Flag that sets whether or not to autopopulate data.
     */
    autopopulate?: boolean = false;
    /**
     * API Controller
     */
    apiController?: string;
    /**
     * Flag that sets whether or not to return minimal object.
     */
    minimal?: boolean = false;
    /**
     * Custom Query
     */
    customQuery?: any = null;
    /**
     * Sort expression
     */
    sort?: any = { createdAt: 1 };
    /**
     * Flag that sets whether or not to retrieve virtuals
     */
    virtuals?: boolean = false;
    customHeight?: string;  
    /**
     * Select query
     */
    select?: string = '';
    /**
     * List of pre-defined values.
     * This will be used for options that don't API call
     * to retrive values.
     */
    values?: Array<any> = [];
    /**
     * Limit query results
     */
    limit?: number;
    /**
     * Flag that sets whether or not to set options from reference
     * function.
     */
    useSetOptionsFromRef?: boolean = false;
    /**
     * Flag that sets whether or not values are array of string
     * Ids.
     */
    isArrayOfStringIds?: boolean = false;
    /**
     * Custom Query to filter by
     */
    customQueryField?: string;

    //
    buildAndRender?: boolean = false;
    //
    showEnv?: boolean = false;
    showEnabled?: boolean = false;
    showBuildStatus?: boolean = false;
    thumbnailStyle?: string = "single";
    //
    createOrSelect?: any = {};

    version?: number = 2;
    gridWidth?: number = 12;
    viewRoute?: string;
    formName?: string;
    title?: string;
    keys?: Array<string>;
    columnDefs?: any[] = [];
    autopopulateSelect?: string = '';
    defaultQueryKey?: string;
    entity?: string = '';
    entities?: string[] = [];
    collapsed?: boolean;
    images?: { path: string; title: string; }[];
    staticTitles?: string[]
    type?: 'ref' | 'self';
    headerTitle?: string;
    addButtonText?: string;
    newRecordTemplate?: any;
    subFields?: DynamicFormFieldDTO[];
    componentSelector?: string;
    isMultiple?: boolean;
    useStringArrayFormat?: boolean; 
    isdisplayNameIDPath?: boolean = false;
    isPrize?: boolean = false; 
    identifier?: string;
}
