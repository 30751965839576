<dynamic-form-v2
  *ngIf="!isLoading"
  entityType="nurture-collection-groups"
  [formName]="title"
  [(record)]="nurtureCollectionGroupRecord"
  [fields]="fields"
  [viewRoute]="viewRoute"
  [rowData]="rowData"
>
</dynamic-form-v2>
