import { Component, OnInit, Input } from '@angular/core';
import { CommonEntityService } from '../../services/common-entity.service';

@Component({
  selector: 'app-customactionbutton',
  templateUrl: './customactionbutton.component.html',
  styleUrls: ['./customactionbutton.component.sass']
})
export class CustomactionbuttonComponent implements OnInit {
  @Input() record: any; // Object record containing necessary information
  @Input() type: string; // Type of action to perform
  @Input() entity: string = 'items';
  message: string = '';
  isError: boolean = false;

  constructor(private commonEntityService: CommonEntityService) { }

  ngOnInit(): void {
    console.log('Object record in child component:', this.record);
  }

  performAction() {
    switch (this.type) {
      case 'syncCollections':
        this.syncCollection();
        break;
      default:
        console.error('Invalid action type:', this.type);
        break;
    }
  }

  syncCollection() {
    const collectionId = this.record && this.record.id;
    if (collectionId === undefined) {
      this.message = 'Collection ID not defined in record object.';
      this.isError = true;
      return;
    }


    if(this.entity == 'items') {
      this.commonEntityService.cleanItemCollectionRelationship('scripts/item-collection/collection', collectionId)
      .then(response => {
        console.log('API response:', response);
        const itemsSynced = response.itemsInCollection || 0; // Handle undefined case
        this.setMessage(`Synced a total of ${itemsSynced} items successfully!`, false);
      })
      .catch(error => {
        console.error('Error syncing collection:', error);
        this.setMessage(`Sync failed: ${error.message}`, true);
      });
    } else {
      this.commonEntityService.cleanChallengeCollectionRelationship('scripts/challenge-collection/collection', collectionId)
      .then(response => {
        console.log('API response:', response);
        const challengesSynced = response.challengesInCollection || 0; // Handle undefined case
        this.setMessage(`Synced a total of ${challengesSynced} challenges successfully!`, false);
      })
      .catch(error => {
        console.error('Error syncing collection:', error);
        this.setMessage(`Sync failed: ${error.message}`, true);
      });
    }
    
  }
  private setMessage(msg: string, isError: boolean) {
    this.message = msg;
    this.isError = isError;
    setTimeout(() => this.message = '', 5000); // Clear message after 5 seconds
  }
}
