import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  static getEnvironmentName(): string {
    if (window.location.href.includes('prod.cms')) {
      return 'production';
    } else if (window.location.href.includes('test.cms')) {
      return 'test';
    } else if(window.location.href.includes('dev.cms')){
      return 'development';
    } else {
      return 'local'
    }
  }
}
