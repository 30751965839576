<div class="p-d-flex p-ai-center p-jc-center" style="
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: hidden;
    text-align: left;
  ">
  <p-button
    *ngIf="!isEditing && !parentComponent.isFullEditMode && this.parentComponent.entity != 'localization/client-strings' && this.parentComponent.entity != 'localization'"
    icon="pi pi-pencil" class="m1" styleClass="p-button-xs" (click)="onEditClick()">
  </p-button>

  <p-button
    *ngIf="!isEditing && !parentComponent.isFullEditMode && (this.parentComponent.entity == 'localization/client-strings' || this.parentComponent.entity == 'localization')"
    icon="pi pi-pencil" class="m1" styleClass="p-button-xs" (click)="onEditClient()">
  </p-button>

  <!-- Metadata Edit button -->
  <p-button
    *ngIf="!isEditing && !parentComponent.isFullEditMode && params?.isMetadata"
    icon="pi pi-cog" class="m1" styleClass="p-button-xs p-button-info" pTooltip="Edit Metadata Configuration" (click)="onMetadataEditClick()">
  </p-button>
  
  <!-- Duplicate button -->
  <p-button *ngIf="!isEditing && !parentComponent.isFullEditMode && this.params.enableDuplicateFeature"
    icon="pi pi-copy" class="m1" styleClass="p-button-xs" (click)="onDuplicateClick()">
  </p-button>

  <!-- DELETE BUTTON -->
  <p-button *ngIf="!isEditing && !parentComponent.isFullEditMode && this.params.enableDeleteFeature"
    icon="pi pi-trash" class="m1" styleClass="p-button-xs p-button-danger" (click)="confirmDelete()">
  </p-button>

  <div *ngIf="isEditing && !parentComponent.isFullEditMode">
    <p-button icon="pi pi-times" class="m1" styleClass="p-button-warning p-button-xs" (click)="onCancelClick()">
    </p-button>
    <p-button icon="pi pi-save" class="m1" styleClass="p-button-xs" (click)="onSaveClick()">
    </p-button>
  </div>
</div>
