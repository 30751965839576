import { Component, Inject, forwardRef } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';
import { AgGridTableComponent } from '../../../ag-grid-table.component';
import { AgGridEditService } from '../../../services/ag-grid-edit.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'app-actions-cell-renderer',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.sass']
})
export class ActionsCellRendererComponent implements ICellRendererAngularComp {

  constructor(
    @Inject(forwardRef(() => AgGridTableComponent)) public parentComponent: AgGridTableComponent,
    private tableCommunicationService: TableCommunicationService,
    private agGridEditService: AgGridEditService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {

  }

  public params: any;
  public isEditing: boolean = false;
  public env: string;

  agInit(params: any): void {
    this.params = params;
    this.isEditing = this.parentComponent.editingRows.has(params.rowIndex);
  }

  refresh(params: any): boolean {
    return false;
  }

  onEditClick() {
    this.isEditing = true;
    // Notify the service to edit the row
    this.tableCommunicationService.editRow(this.params.rowIndex);
  }

  onEditClient() {
    this.tableCommunicationService.editClientString({
      display: true,
      record: this.params.data
    })
  }

  onDuplicateClick(){
    let payload = {
      id: this.params.data.id,
      _id: this.params.data._id,
    }
    this.tableCommunicationService.duplicateRow(payload);
  }

  onCancelClick() {
    this.isEditing = false;
    this.tableCommunicationService.cancelEditRow(this.params.rowIndex);
    this.agGridEditService.revertChanges(this.parentComponent.api, this.parentComponent.changedRows, this.parentComponent.originalData, [this.params.rowIndex]);
  }

  async onSaveClick() {
    this.isEditing = false;
    try {
      await this.agGridEditService.submitRow(
        this.params.rowIndex,
        this.parentComponent.changedRows,
        this.parentComponent.api,
        this.parentComponent.entity,
        null,
        null,
        this.params.storeEnv || null
      );
      // Row has been submitted successfully
    } catch (error) {
      // Handle error
      console.error("An error occurred while submitting the row:", error);
    }
  }

  // Method to confirm the deletion
  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this record?',
      accept: () => {
        this.deleteRecord();
      },
      reject: () => {
        // Do nothing
      }
    });
  }

  // Method that performs the actual deletion
  deleteRecord() {
    // Implement your deletion logic here
    let payload = {
      id: this.params.data.id,
      _id: this.params.data._id,
    };
    console.log('Deleting record:', payload);
    this.tableCommunicationService.deleteRow(payload);
  }

  onMetadataEditClick() {
    this.router.navigate(['/dynamic-entities/edit', this.params.data.id]);
  }
}
  