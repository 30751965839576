import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-keywords-view-v2',
  templateUrl: './keywords-view-v2.component.html',
  styleUrls: ['./keywords-view-v2.component.sass']
})
export class KeywordsViewV2Component extends BaseDynamicViewComponent implements OnInit {
  keywordRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.fields = [
      {
        title: "Keyword Data",
        fields: [
          {
            name: "Name",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            columnWidth: 4
          },
          {
            name: "Description",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'description',
            label: 'Description',
            columnWidth: 8
          },
          {
            name: "Infinite Garden Active",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            clearField: true,
            key: 'isInfiniteGardeningActive',
            label: 'Infinite Garden Active',
            isRequired: true,
            columnWidth: 4,
            booleanoptions: {
              true: "Active",
              false: "Disabled"
            }
          },
          {
            name: "Infinite Garden Exclusion",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            clearField: true,
            key: 'isInfiniteGardeningExluded',
            label: 'Infinite Garden Exclusion',
            isRequired: true,
            columnWidth: 4,
            booleanoptions: {
              true: "Include",
              false: "Exclude"
            }
          },
        ],
        type: DynamicCardType.Card
      },
    ];
    this.title = "Keyword Details";
    this.viewRoute = 'keyword';
    this.isLoading = false;
  }
}
