import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityViewService } from 'src/app/services/entity-view.service';
import _, { union } from 'lodash';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-rewardtimeline',
  templateUrl: './rewardtimeline.component.html',
  styleUrls: ['./rewardtimeline.component.sass']
})
export class RewardtimelineComponent implements OnInit {
  @Input() record: any; 
  @Input() field: any;  
  rewards: any; 

  entity: any;
  type = 'season-pass';
  parsedSeasonPass: any = [];
  isLoading: boolean;
  id: any;
  constructor(
    private entityViewService: EntityViewService,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    const routeParams = this.route.snapshot.paramMap;

    this.id = Number(routeParams.get('id'));

    await this.entityViewService
      .getEntity(this.type, { query: { id: this.id }, autopopulate: true })
      .then((result: any) => {
        if (result) {
          this.entity = result;
          if (this.entity.name) {
            this.titleService.setTitle(this.entity.name);
          }
        }
      });

    this.parseSeasonPass();
    console.log('Parsed Season Pass: ', this.parsedSeasonPass);
    this.isLoading = false;
  }


  parseSeasonPass() {
    this.parsedSeasonPass = [];
    if (
      this.entity &&
      ((this.entity.paid && this.entity.paid.stageMilestone) ||
        (this.entity.free && this.entity.free.stageMilestone)) &&
      (this.entity.paid.stageMilestone.length > 0 ||
        this.entity.free.stageMilestone.length > 0)
    ) {
      let mergedStageMilestones: Array<number> = union(
        this.entity.paid.stageMilestone,
        this.entity.free.stageMilestone
      );
      mergedStageMilestones =
        mergedStageMilestones && mergedStageMilestones.length > 0
          ? mergedStageMilestones.sort((a, b) => a - b)
          : [];

      for (let stage of mergedStageMilestones) {
        if (
          this.entity.free &&
          this.entity.free.stageMilestone &&
          this.entity.free.stageMilestone.includes(stage) &&
          this.entity.paid &&
          this.entity.paid.stageMilestone &&
          this.entity.paid.stageMilestone.includes(stage)
        ) {
          this.parsedSeasonPass.push({
            stage: stage,
            paid: this.entity.paid.paid[
              this.entity.paid.stageMilestone.indexOf(stage)
            ],
            free: this.entity.free.free[
              this.entity.free.stageMilestone.indexOf(stage)
            ],
          });
        }
        else if (
          this.entity.paid &&
          this.entity.paid.stageMilestone &&
          this.entity.paid.stageMilestone.includes(stage)
        ) {
          this.parsedSeasonPass.push({
            stage: stage,
            paid: this.entity.paid.paid[
              this.entity.paid.stageMilestone.indexOf(stage)
            ],
          });
        }
        else if (
          this.entity.free &&
          this.entity.free.stageMilestone &&
          this.entity.free.stageMilestone.includes(stage)
        ) {
          this.parsedSeasonPass.push({
            stage: stage,
            free: this.entity.free.free[
              this.entity.free.stageMilestone.indexOf(stage)
            ],
          });
        }
      }
    }
  }
  getRewardImage(reward: any): string {
    let imagePath = '';
  
    if (!reward || !reward.id) {
      return 'https://placeholder.com/100';
    } else {
      switch (reward.lineItemType) {
        case 'Item':
          imagePath = reward.id?.thumbnail_ref?.thumbnails?.[0]?.path || 'https://placeholder.com/100';
          break;
        case 'Currency':
          imagePath = reward.id?.thumbnail_ref?.thumbnails?.[0]?.path || reward.id?.thumbnails?.[2]?.path || 'https://placeholder.com/100';
          break;
        case 'Titles':
          imagePath = 'https://d3tfb94dc03jqa.cloudfront.net/images/titles/title_default/title_default_128.png';
          break;
        case 'Nurture':
          imagePath = reward.id?.coinThumbnail_ref?.thumbnails?.[0]?.path || reward.id?.sproutImage_ref?.thumbnails?.[0]?.path || 'https://placeholder.com/100';
          break;
        case 'SeedPackBox':
          imagePath = reward.id?.box_image_empty_ref?.thumbnails?.[0]?.path || 'https://placeholder.com/100';
          break;
        default:
          imagePath = 'https://placeholder.com/100';
          break;
      }
    }
  
    return imagePath;
  }
  
}
