import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

interface EnvironmentValue<T> {
  dev?: T;
  qa?: T;
  prod?: T;
  // Add more environments here as needed
}

/**
 * Price Point DTO
 */
export default class PricePointDTO extends BaseEntityDto
{
  name: EnvironmentValue<string>;
  storeEnabled: EnvironmentValue<boolean>;
  storeStart: EnvironmentValue<Date>;
  storeEnd: EnvironmentValue<Date>;
  nonConsumable: EnvironmentValue<boolean>;
  costUnit: EnvironmentValue<string>;
  costQuantity: EnvironmentValue<number>;
  tags: EnvironmentValue<string[]>;
  GoogleCheckoutPetunia: EnvironmentValue<string>;
  AppleAppStorePetunia: EnvironmentValue<string>;
  PlaygamiPetunia: EnvironmentValue<string>;
}
