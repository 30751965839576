import { Component } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { IRowNode, IToolPanelParams } from 'ag-grid-enterprise';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cms-filters',
  templateUrl: './cms-filters.component.html',
  styleUrls: ['./cms-filters.component.sass']
})
export class CmsFiltersComponent implements IToolPanelAngularComp {
  private params!: IToolPanelParams;
  public entity: string;

  filters: any = {
    'items':
    [
      {
        name: 'Default',
        columns: [
          'id', 'thumbnail', 'fileName', 'name', 'batch_ref', 'category_ref', 'start',
          'vendorStatus', 'itemStatus', 'costs_ref', 'enabled', 'buildStatus',
        ],
      },
      {
        name: 'Organic',
        columns: [
          'id', 'thumbnail', 'fileName', 'name', 'cultivar', 'batch_ref', 'climates_ref',
          'category_ref', 'start', 'vendorStatus', 'itemStatus', 'costs_ref',
          'enabled', 'buildStatus', 'imageBuildStatus',
        ],
      },
      {
        name: 'Hard Surface',
        columns: [
          'id',  'thumbnail', 'fileName', 'name', 'category_ref', 'start', 'vendorStatus', 'itemStatus', 'costs_ref',
          'enabled', 'buildStatus', 'imageBuildStatus', 'contentHold_ref', 'styles_ref', 'colors_ref',
        ],
      },
    ],
  }

  communityFilters: any[] = [];
  customFilters:any[] = [];

  isAddingFilter = false;
  newFilterName = '';

  selectedFilter: any;
  selectedCustomFilter: any;
  expandedFilter: any = null;

  get entityFilters(): any[] {
    return this.filters[this.entity] || [];
  }

  constructor(
    private dataService: DataService
  ){

  }

  agInit(params: any): void {
    this.params = params;
    this.entity = params.entity;
    this.syncCmsFilters()
  }

  refresh(): void {}

  // apply Filter
  applyFilter() {
    if (this.selectedFilter) {
      const filters = this.selectedFilter.filters;
      this.params.api.setFilterModel(filters)
    }
  }

  // Filters management
  startAddingFilter() {
    this.isAddingFilter = true;
  }

  cancelAddingFilter() {
    this.isAddingFilter = false;
    this.newFilterName = '';
  }

  async saveCustomFilter() {
    // Logic to capture the current column state and save it
    const currentFilterState = this.getCurrentFilterState();
    if (this.newFilterName) {
      console.log('currentFilterState:', currentFilterState);
      let newFilter = { name: this.newFilterName, filters: currentFilterState };
      this.customFilters = [...this.customFilters, newFilter];
      let response: any = await this.dataService.updateUserSettings('ag-grid-'+this.entity, 'tableFilter', newFilter); // Use the actual response type
      this.newFilterName = '';
      this.isAddingFilter = false;
    }
  }

  async deleteCustomFilter(option: any){
    console.log(option);
    await this.dataService.deleteRecord(option._id, 'user-settings');
    await this.syncCmsFilters();
  }

  getCurrentFilterState() {
    return this.params.api.getFilterModel();
  }

  toggleExpand(filter: any) {
    if (this.expandedFilter === filter) {
      this.expandedFilter = null; // Collapse the currently expanded item
    } else {
      this.expandedFilter = filter; // Expand the clicked item
    }
  }

  clearFilters(){
    this.params.api.setFilterModel(null);
  }

  async syncCmsFilters(){
    try {
      let pageRef = 'ag-grid-'+this.entity;
      // Code to save the current column state to the user's profile
      let userFilters = await this.dataService.getUserSettings(pageRef, `tableFilter`);
      // Extract 'settings' attribute and store them in 'this.customFilters'
      this.customFilters = userFilters.map((filter: any) => ({ ...filter.settings, _id: filter._id, id: filter.id }));

      let communityFilters = await this.dataService.getUserSettings(pageRef, `tableFilter?community=true`);
      // Extract 'settings' attribute and store them in 'this.communityFilters'
      this.communityFilters = communityFilters.map((filter: any) => ({ ...filter.settings, _id: filter._id, id: filter.id }));

      // console.log('user filters: ', userFilters);
      // console.log('community filters: ', communityFilters);
    } catch (error) {
      console.error("Failed to update user settings", error);
    }
  }

}
