import { AfterViewInit, Component, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-color-text',
  templateUrl: './color-text.component.html',
  styleUrls: ['./color-text.component.sass']
})
export class ColorTextComponent implements ICellEditorAngularComp, AfterViewInit {
  
  @ViewChild('colorInput', { static: false }) colorInput: ElementRef;

  
  private params: any;
  public value: string = '';
  public fieldName: string = '';
  public fieldKey: string = '';
  
  constructor(private renderer: Renderer2) {}
  
  agInit(params: any): void {
    this.params = params;

    this.value = this.params.data[this.params.colDef.field];
    this.fieldName = this.params.colDef.headerName;
    this.fieldKey = this.params.colDef.field;
  }

  ngAfterViewInit(): void {
  }

  afterGuiAttached?(): void {
    this.renderer.selectRootElement(this.colorInput.nativeElement).focus();
}


  getValue(): any {
    return this.value;
  }
  
}
