<p-fieldset [legend]="legend" [toggleable]="true" [collapsed]="collapsed">
  <div class="card">
    <p-toast></p-toast>
    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <button pButton pRipple [label]="label" icon="pi pi-plus" class="p-button-success" styleClass="p-mr-2"
          (click)="openNew()"></button>
      </ng-template>
      <ng-template pTemplate="right"> </ng-template>
    </p-toolbar>
    <!-- add managed list record dialog -->
    <p-dialog [(visible)]="showDialog" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="header">
        <h3>{{label}}</h3>
      </ng-template>
      <ng-template pTemplate="content">
        <form *ngIf="fields" [formGroup]="newRecordForm" (ngSubmit)="submitNewRecord()">
          <div *ngFor="let field of fields">
            <div class="p-field">
              <span class="p-float-label" [ngSwitch]="field.inputType">
                <ng-container *ngSwitchCase="'inputText'">
                  <div class="p-mb-5">
                    <input [formControlName]="field.key" [id]="field.key" [type]="'inputText'" pInputText />
                    <label [for]="field.key">{{field.name}}</label>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'inputToggle'">
                  <div class="p-mb-5">
                    <p-toggleButton [formControlName]="field.key" [onLabel]="field.onLabel" [offLabel]="field.offLabel"
                    [style]="{ width: '100%' }" class="field-item"></p-toggleButton>
                  </div>
                </ng-container>
              </span>
            </div>
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
          (click)="showDialog = false;"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
          (click)="submitNewRecord()"></button>
      </ng-template>
    </p-dialog>
    <p-dialog [(visible)]="editRecordDialog" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
      <ng-template pTemplate="header">
        <h3>Editing {{submitURL}} with ID:{{ this.newRecordForm.get("id")!.value }}</h3>
      </ng-template>
      <ng-template pTemplate="content">
        <form *ngIf="fields" [formGroup]="newRecordForm" (ngSubmit)="submitNewRecord()">
          <div *ngFor="let field of fields">
            <div class="p-field">
              <span class="p-float-label" [ngSwitch]="field.inputType">
                <ng-container *ngSwitchCase="'inputText'">
                  <div class="p-mb-5">
                    <input [formControlName]="field.key" [id]="field.key" [type]="'inputText'" pInputText />
                  <label [for]="field.key">{{field.name}}</label>
                  </div>

                </ng-container>
                <ng-container *ngSwitchCase="'inputToggle'">
                  <div class="p-mb-5">
                    <p-toggleButton [formControlName]="field.key" [onLabel]="field.onLabel" [offLabel]="field.offLabel"
                    [style]="{ width: '100%' }" class="field-item"></p-toggleButton>
                  </div>
                </ng-container>
              </span>
            </div>
          </div>
        </form>
      </ng-template>
      <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
          (click)="editRecordDialog = false;"></button>
        <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="updateRecord()"></button>
      </ng-template>
    </p-dialog>
  </div>
  <p-table [value]="rows" styleClass="p-datatable-gridlines" responsiveLayout="scroll" [rowHover]="true" dataKey="id">
    <ng-template pTemplate="header">
      <tr>
        <th *ngFor="let field of fields" [pSortableColumn]="field.key">
          {{field.name}}
          <p-sortIcon field="field.key"></p-sortIcon>
        </th>
        <th pSortableColumn="createdBy">
          Created By <p-sortIcon field="createdBy"></p-sortIcon>
        </th>
        <th pSortableColumn="createdAt">
          Created At <p-sortIcon field="createdAt"></p-sortIcon>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-record>
      <tr>
        <td *ngFor="let field of fields">{{ record[field.key] }}</td>
        <td>{{ record.createdBy ? record.createdBy.name : "" }}</td>
        <td>{{ record.createdAt | officeTime }}</td>
        <td>
          <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info p-mr-2 p-mb-1"
            (click)="editNewRecordModal(record)" label="Edit"></button>
          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-2"
            (click)="confirmDeleteRecordModal(record)" label="Delete"></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-fieldset>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
