import { Component, OnInit } from '@angular/core';
import ManagedListFieldsDTO from '../dtos/ManagedListFieldsDTO';

@Component({
  selector: 'tags-managed-list',
  templateUrl: './tags-managed-list.component.html',
  styleUrls: ['./tags-managed-list.component.sass']
})
export class TagsManagedListComponent implements OnInit 
{
  fields: Array<ManagedListFieldsDTO> =
  [
    {
      key: 'name',
      name: 'Tag Name',
      inputType: 'inputText'
    },
    {
      key: 'enabled',
      name: 'Enabled',
      inputType: 'inputToggle',
      onLabel: 'Enabled',
      offLabel: 'Not Enabled'
    },
  ];
  properties = 
  {
    name: null,
    enabled: true
  }

  constructor() { }

  ngOnInit(): void {}
}
