<p-dialog header="Set Your Initials" [(visible)]="displayInitialsDialog" [modal]="true" [closable]="true">
    <ng-template pTemplate="header">
      <div class="p-d-flex p-jc-between">
        <span>Set Your Initials</span>
      </div>
    </ng-template>
    <div class="p-grid">
      <div class="p-col-12">
        <label for="initials">Initials</label>
        <input id="initials" type="text" pInputText [(ngModel)]="userInitials" maxlength="4" />
      </div>
    </div>
    <p-footer>
      <button type="button" pButton label="Submit" icon="pi pi-check" (click)="onSubmitInitials()"></button>
    </p-footer>
 </p-dialog>  