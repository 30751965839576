import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bundle-asset-view',
  templateUrl: './bundle-asset-view.component.html',
  styleUrls: ['./bundle-asset-view.component.sass']
})
export class BundleAssetViewComponent implements OnInit {

  constructor(
  ) { }

  async ngOnInit(): Promise<void> {

  }

}
