<dynamic-form-v2 
    *ngIf="!isLoading" 
    entityType="sourcing-challenge-groups" 
    [formName]="title" 
    [(record)]="sourcingGroup"
    [fields]="fields"
    [viewRoute]="viewRoute"
>
</dynamic-form-v2>

<!-- <p-messages></p-messages>
<p-toast></p-toast> -->

<!-- <h1 *ngIf="!isEditMode" class="p-ml-3">Add New Sourcing Group</h1>
<h1 *ngIf="isEditMode" class="p-ml-3">Editing Sourcing Group {{ id }}</h1> -->

<!-- <div class="p-grid" *ngIf="!isLoading">
  <div class="p-col-12">
      <form (ngSubmit)="onSubmit()">
          <button pButton pRipple type="submit" label="Submit" class="p-mb-4" [style]="{ 'min-width': '12.5em' }"></button>
          <div class="">
              <span ngClass="p-float-label">
              <p-toggleButton
                  [(ngModel)]="sourcingGroup.enabled"
                  [onLabel]="'Enabled'"
                  [offLabel]="'Not Enabled'"
                  class="field-item"
                  [style]="{ width: '100%' }"
                  [ngModelOptions]="{standalone: true}"
              ></p-toggleButton>
              </span>
          </div> -->
          <!-- CARD: Top Details -->
          <!-- <p-card class="p-mx-2">
              <div class="p-grid p-mx-5">
                  <div class="p-col"> -->
                      <!-- name -->
                      <!-- <div class="p-my-5 p-inputgroup">
                          <span ngClass="p-float-label">
                              <input
                                  [(ngModel)]="sourcingGroup.name"
                                  [ngModelOptions]="{ standalone: true }"
                                  pInputText
                                  class="field-item" />
                              <label for="name"> Name <strong style="color: crimson">*</strong></label>
                          </span>
                      </div>
                  </div>
              </div>
          </p-card>

      </form>
  </div>
</div> -->
