<div class="p-grid p-align-center">
  <div class="p-col p-p-5">
    <p-card header="{{ pageTitle }}">
      <p-table (onFilter)="onFilter($event)" (onSort)="onSort($event)" [sortField]="urlQueryParams['sortBy']" [sortOrder]="urlQueryParams['sortOrder']" #spawnerTable [globalFilterFields]="['name','id','spawners']" [loading]="isLoading" [value]="challenges" [paginator]="true" [rows]="15" [showCurrentPageReport]="false" [rowsPerPageOptions]="[15,25,100]" dataKey="id" responsiveLayout="scroll">
        <ng-template pTemplate="caption">
          <div class="grid">
              <button pButton label="Filter Challenges Without Spawners" [class]="filtered ? 'p-mx-2' : 'p-button-outlined p-mx-2 col'" icon="pi pi-filter" (click)="callZeroFilter(spawnerTable)"></button>
              <button pButton label="Clear Filters" [class]="'p-button-outlined p-mr-5 col'" icon="pi pi-filter-slash" (click)="clear(spawnerTable)"></button>
              <span class="p-input-icon-left ml-5">
                  <i class="pi pi-search"></i>
                  <input pInputText type="text" (input)="spawnerTable.filterGlobal($event.target.value, 'contains')" placeholder="Search keyword" />
              </span>
          </div>
      </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem"></th>
                <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
                <th pSortableColumn="id">ID<p-sortIcon field="id"></p-sortIcon></th>
                <th>Spawner Count</th>
                <th pSortableColumn="expectedCoinSpend">Average Expected Coin Spend<p-sortIcon field="expectedCoinSpend"></p-sortIcon></th>
                <th pSortableColumn="expectedDiamondSpend">Average Expected Diamond Spend<p-sortIcon field="expectedDiamondSpend"></p-sortIcon></th>
                <th pSortableColumn="averageCoinCost">Average Coin Cost<p-sortIcon field="averageCoinCost"></p-sortIcon></th>
                <th pSortableColumn="averageDiamondCost">Average Diamond Cost<p-sortIcon field="averageDiamondCost"></p-sortIcon></th>
                <th><p-columnFilter type="date" field="start" display="menu"></p-columnFilter>Start</th>
                <th><p-columnFilter type="date" field="end" display="menu"></p-columnFilter>End</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-challenge let-expanded="expanded">
            <tr>
                <td>
                    <button type="button" pButton *ngIf="challenge.spawners && challenge.spawners.length > 0" pRipple [pRowToggler]="challenge" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                </td>
                <td><a [routerLink]="'/challenges/' + challenge.id" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">{{ challenge.name }}</a></td>
                <td>{{ challenge.id }}</td>
                <td>
                  <div>
                  <span>Optional: {{ challenge.spawner_count ? challenge.spawner_count.optional : 0 }}</span>
                </div>
                  <span>Required: {{ challenge.spawner_count ? challenge.spawner_count.required : 0 }}</span>
                </td>
                <td>{{ challenge.expectedCoinSpend }}</td>
                <td>{{ challenge.expectedDiamondSpend }}</td>
                <td>{{ challenge.averageCoinCost }}</td>
                <td>{{ challenge.averageDiamondCost }}</td>
                <td>{{ challenge.start | officeTime: 'short'}}</td>
                <td>{{ challenge.end | officeTime: 'short'}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-challenge>
          <tr>
            <td colspan="10">
              <div class="p-3">
            <nested-econ-table [tableData]="challenge.spawners" [styleClass]="'p-datatable-sm p-datatable-striped p-datatable-gridlines'"></nested-econ-table>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-card>
  </div>
</div>
