<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="nurture-stage"
    [formName]="title"
    [(record)]="nurtureStageRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [autopopulateEntity]="true"
    [startDate]="nurtureStageRecord.start"
>
</dynamic-form-v2>
