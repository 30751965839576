// For Items Related Assets
// This is the default bulk update column definitions for the ag-grid table.
// It is used to populate the bulk update modal when the user clicks the "Bulk Update" button.
// It is also used to populate the bulk update modal when the user clicks the "Bulk Update" button in the entity detail view.
export const BULK_UPDATE_COL_DEFS = [
  {
    name: 'Name', id: 'name', type: 'text'
  },
  {
    name: 'Start', id: 'start', type: 'date'
  },
  {
    name: 'End', id: 'end', type: 'date'
  },
  {
    name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
  },
  {
    name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
  },
  {
    name: 'Tag(s)', id: 'tags_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Content Status', id: 'itemStatus', type: 'dropdown', isFlatList: true
  },
  {
    name: 'Re Release Start', id: 'reReleaseStart', type: 'date'
  },
  {
    name: 'Re Release End', id: 'reReleaseEnd', type: 'date'
  },
  {
    name: 'Latin Name', id: 'latinName', type: 'text'
  },
  {
    name: 'Plant Family', id: 'plantFamily', type: 'text'
  },
  {
    name: 'Art Status', id: 'vendorStatus', type: 'dropdown', isFlatList: true
  },
  {
    name: 'Asset Type', id: 'assetType', type: 'dropdown', isFlatList: true
  },
  {
    name: 'Batch', id: 'batch_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Blurb', id: 'blurb', type: 'long-text'
  },
  {
    name: 'Blurb Status', id: 'blurbStatus', type: 'dropdown', isFlatList: true
  },
  {
    name: 'Category', id: 'category_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Shape', id: 'shape_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Vendor', id: 'vendor_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Type', id: 'type_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Climate(s)', id: 'climates_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Collection(s)', id: 'collection_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Color(s)', id: 'colors_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Content Hold', id: 'contentHold_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Costs', id: 'costs_ref', type: 'line-items', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Cultivar', id: 'cultivar', type: 'text'
  },
  {
    name: 'Internal Notes', id: 'internalNotes', type: 'rich-text'
  },
  {
    name: 'Year', id: 'year', type: 'dropdown', isFlatList: true
  },
  {
    name: 'Bundle Image (IOS)', id: 'bundleImage', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
  },
  {
    name: 'Bundle Asset (IOS)', id: 'bundleAsset', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
  },
  {
    name: 'Bundle Image (AND)', id: 'bundleImageAnd', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
  },
  {
    name: 'Bundle Asset (And)', id: 'bundleAssetAnd', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
  },
  {
    name: 'Loop Audios', id: 'loopAudios_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Spawn Audios', id: 'spawnAudios_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Loop Audio Collections', id: 'loopAudioCollections_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Keywords', id: 'keywords_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Patterns', id: 'patterns_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Materials', id: 'materials_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Styles', id: 'styles_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Traits', id: 'traits_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Spawn Audio Collections', id: 'spawnAudioCollections_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Set(s)', id: 'itemSet_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
];

export const CHALLENGE_BULK_UPDATE_COL_DEFS = [
  {
    name: 'Name', id: 'name', type: 'text'
  },
  {
    name: 'Start', id: 'start', type: 'date'
  },
  {
    name: 'End', id: 'end', type: 'date'
  },
  {
    name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
  },
  {
    name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
  },
  {
    name: 'Is Infinite Gardens Excluded', id: 'isInfiniteChallengeExcluded', type: 'boolean', onLabel: 'True', offLabel: 'False'
  },
  {
    name: 'Artist', id: 'artist_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Bundle Image (IOS)', id: 'bundleImage', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
  },
  {
    name: 'Bundle Asset (IOS)', id: 'bundleAsset', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
  },
  {
    name: 'Bundle Image (AND)', id: 'bundleImageAnd', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
  },
  {
    name: 'Bundle Asset (And)', id: 'bundleAssetAnd', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
  },
  {
    name: 'Challenge Type', id: 'type_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Climate', id: 'climate_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Content Hold', id: 'contentHold_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Description', id: 'description', type: 'long-text'
  },
  {
    name: 'Short Description', id: 'descriptionShort', type: 'text'
  },
  {
    name: 'File Name', id: 'fileName', type: 'text'
  },
  {
    name: 'Scene Path', id: 'scene', type: 'text'
  },
  {
    name: 'Feed Image Path', id: 'image', type: 'text'
  },
  {
    name: 'Internal Notes', id: 'internalNotes', type: 'rich-text'
  },
  {
    name: 'File Type', id: 'fileType', type: 'dropdown', isFlatList: true
  },
  {
    name: 'Keywords(s)', id: 'keywords_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Location', id: 'location', type: 'text'
  },
  {
    name: 'Loop Audios', id: 'loopAudios_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Spawn Audios', id: 'spawnAudios_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Loop Audio Collections', id: 'loopAudioCollections_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Spawn Audio Collections', id: 'spawnAudioCollections_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Prizes', id: 'prizes_ref', type: 'line-items', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Progression Level', id: 'progressionLevel_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Restrictions', id: 'restrictions_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Sourced By', id: 'sourced_by', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Tester', id: 'tester_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Year', id: 'year', type: 'dropdown', isFlatList: true
  },
  {
    name: 'Sponsor', id: 'sponsor_ref', type: 'dropdown', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
  {
    name: 'Collection(s)', id: 'collection_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
  },
];
