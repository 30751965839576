import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'officeTime',
})

export class OfficeTimePipe implements PipeTransform {
  transform(value: any): string {
    if(!value) return ''
    // Initialize a variable to hold the date
    let date;

    // Check if the value is a string and not a valid date object
    if (typeof value === 'string' && !isNaN(Date.parse(value))) {
      // Try to convert the string to a date object
      const parsedDate = new Date(value);
      date = moment.tz(parsedDate, 'America/Los_Angeles');
    } else {
      // Create a moment object with the time zone set
      date = moment.tz(value, 'America/Los_Angeles');
    }

    // Check for valid date
    if (!date.isValid()) {
      return ''; // Return an empty string or handle invalid date
    }

    // Format the date with the time zone
    const sfoDateTime = date.format('MMM D, YYYY, hh:mm A z');

    return sfoDateTime;
  }
}

