import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoggerService } from '../../services/logger.service';
@Component({
  selector: 'nested-econ-table',
  templateUrl: './nested-econ-table.component.html',
  styleUrls: ['./nested-econ-table.component.sass']
})
export class NestedEconTableComponent implements OnInit 
{
  @Input() tableData: Array<any> = [];
  @Input() styleClass: String;
  @Input() challengeID: Number;
  @Output() onSelectedOptionsChange = new EventEmitter<any>();
  @Output() emitterData = new EventEmitter<any>();
  options: Array<any> = [];
  isLoading: boolean = true;
  currentTimeout: any;
  sortedTableData: any[] = [];

  constructor
  (
    private loggerService: LoggerService,
  ) { }

  /**
   * Nested Econ Table Initialization
   */
  async ngOnInit()
  {
    await this.setupDataforSorting()
    this.loggerService.log('Data', this.sortedTableData);
    this.emitterData.emit(this.sortedTableData);
  }

  /**
   * Creates a new list of items fit for sorting
   */
  async setupDataforSorting() 
  {
    if(this.tableData && this.tableData.length > 0)
    {
      this.tableData.forEach((data: any) => {
        let newObject: any = {};
        newObject['name'] = data.economyObject && data.economyObject.length > 0 ? data.economyObject.map((id: any) => id ? id.categoryName : null) : data.categories;
        newObject['expectedCoinSpend'] = this.reduceEconomy(data, 'expected', 'Coin', data.economyObject.length)
        newObject['averageCoinCost'] = this.reduceEconomy(data, 'average', 'Coin', data.economyObject.length);
        newObject['expectedDiamondSpend'] = this.reduceEconomy(data, 'expected', 'Diamond', data.economyObject.length)
        newObject['averageDiamondCost'] = this.reduceEconomy(data, 'average', 'Diamond', data.economyObject.length)
        newObject['originalEconObj'] = data.economyObject;
        newObject['id'] = data.economyObject && data.economyObject.length > 0 ? data.economyObject.map((id: any) => id ? id.id : null) : [];
  
        this.sortedTableData.push(newObject);
      })
    }
  }
  /**
   * sums or averages the economy object
   * @param spawner spawner obj
   * @param mode how to reduce the obj
   * @param currency the type of currency to reduce
   * @param length the amount of objects for the average
   * @returns the reduce result
   */
   reduceEconomy(spawner: any, mode: String, currency: String, length: number = 1) {
    const result =
    spawner.economyObject.reduce((accumulator: any, obj: any) => {  
      if (currency == 'Coin') {
        return mode == 'expected' ?  accumulator + obj ? obj.expectedCoinSpend : 0 : accumulator + obj ? obj.averageCoinCost : 0
      } else {
        return mode == 'expected' ?  accumulator + obj ? obj.expectedDiamondSpend : 0 : accumulator + obj ? obj.averageDiamondCost : 0
      }
    }, 0);

    return result / length;
  } 

}
