  <ng-template pTemplate="header">
    <div class="p-d-flex p-p-3">
      <span class="p-card-title">History</span>
    </div>
  </ng-template>
  <ng-container>
    <div class="p-mx-5">
      <ng-container *ngFor="let button of (buttonConfig[entityType] || buttonConfig['default'])">
        <p-button
          class="p-mx-2"
          [label]="button.label"
          [routerLink]="button.routerLink + (button.routerLink.endsWith('/') ? record.id : '')"
        ></p-button>
      </ng-container>
    </div>
  </ng-container>