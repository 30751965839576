// import { Logger } from '@nestjs/common';
import { ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { forwardRef, Inject, Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { ValidationsService } from 'src/app/common/services/validations.service';


@Injectable({
  providedIn: 'root'
})
export class ItemValidationService {

  private readonly apiURLprefix : string = '/api/items';
  dateVerificationConfirmed: boolean = false;
  showDatesVerificationModal: boolean = false;
  envsVerificationConfirmed: boolean = false;
  showEnvsVerificationModal: boolean = false;

  startDateErrorMessage: string = "";
  endDateErrorMessage: string = "";
  reStartDateErrorMessage: string = "";
  reEndDateErrorMessage: string = "";

  constructor(
    private http: HttpClient,
    @Inject(forwardRef(() => ValidationsService)) private readonly validationService: ValidationsService,
    private scroller: ViewportScroller,
    private messageService: MessageService
  ) { }

   /**
   * Validate if Item meets Environment Flags Requirement
   *
   * @param item Item record.
   */
   checkEnvFlagsRequirements(item: any)
   {
     let response =
     {
       devReady: true,
       qaReady: true,
       prodReady: true,
       item:
       {
         itemStatus: item.itemStatus,
         start: item.start,
         vendorStatus: item.vendorStatus,
         enabled: item.enabled,
         flagged: item.flagged,
         costs_ref: item.costs_ref,
         env: item.env
       }
     };
     if(item.env && item.env.length > 0)
     {
       if(item.env.includes('dev'))
       {
         if(item.vendorStatus != 'Approved' || item.enabled !== true)
         {
           response.devReady = false;
         }
       }
 
       if(item.env.includes('qa') || item.env.includes('prod'))
       {
         if (item.vendorStatus != 'Approved' || item.itemStatus != 'Approved' || (item.start == null || item.start == undefined) || (item.flagged && item.flagged.toLowerCase() == 'hold'))
         {
           if(item.env.includes('qa')){
             response.qaReady = false;
 
           }
           if(item.env.includes('prod')){
             response.prodReady = false;
           }
         }
         else
         {
           if(item.enabled !== true || !item.costs_ref || (item.costs_ref && item.costs_ref.length == 0))
           {
             response.qaReady = false;
             response.prodReady = false;
           }
         }
       }
     }
 
     return response;
   }

   /**
   * Validate if Item meets Environment Flags Requirement
   *
   * @param itemId Id of the item.
   */
  async checkEnvFlagsRequirementsAPICall(itemId: any)
  {
    return firstValueFrom(this.http.get<any>(`${this.apiURLprefix}/${itemId}/environment-flags/check`));
  }

  /**
   * this function evaluates date changes, and returns if a change is valid based on:
   * if current start/end dates were live in the past (older than current date)
   * if current start/end dates are soon to go live -48hrs
   * @returns boolean true/false if date changes are valid
   */
  async validateDateChanges(originalRecord: any, itemForm: any, ){

    console.log('itemForm', itemForm)
    console.log('originalRecord', originalRecord)
    let isValidDate = true;
    // re-setting error message before entering function
    this.startDateErrorMessage = '';
    this.endDateErrorMessage = '';
    this.reStartDateErrorMessage = '';
    this.reEndDateErrorMessage = '';
    // setting original dates
    let originalStart = new Date(originalRecord.start);
    let originalEnd = new Date(originalRecord.end);
    let originalReStart = new Date(originalRecord.reReleaseStart);
    let originalReEnd = new Date(originalRecord.reReleaseEnd);
    // setting new dates
    // let newStart = new Date(itemForm.value['start']);
    let newStart = new Date(itemForm.start);
    // let newEnd = new Date(itemForm.value['end']);
    let newEnd = new Date(itemForm.end);
    // let newReStart = new Date(itemForm.value['reReleaseStart']);
    let newReStart = new Date(itemForm.reReleaseStart);
    // let newReEnd = new Date(itemForm.value['reReleaseEnd']);
    let newReEnd = new Date(itemForm.reReleaseEnd);
    let currentDate = new Date();

    // START DATE EVALUATION
    if((originalRecord.start) && (originalStart.getTime() !== newStart.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalStart.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        this.startDateErrorMessage = `Original Start date passed ${Math.floor(difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        this.startDateErrorMessage = `Original Start date is soon to go live in ${difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    // END DATE EVALUATION
    if((originalRecord.end) && (originalEnd.getTime() !== newEnd.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalEnd.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        this.endDateErrorMessage = `Original END date passed ${Math.floor(difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        this.endDateErrorMessage = `Original END date is soon to go live in ${difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    // RE-Release Start Date Evaluation
    if((originalRecord.reReleaseStart) && (originalReStart.getTime() !== newReStart.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalReStart.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        this.reStartDateErrorMessage = `Original Re-Release START date passed ${Math.floor(difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        this.reStartDateErrorMessage = `Original Re-Release START date is soon to go live in ${difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    // RE-Release End Date Evaluation
    if((originalRecord.reReleaseEnd) && (originalReEnd.getTime() !== newReEnd.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalReEnd.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        this.reEndDateErrorMessage = `Original Re-Release END date passed ${Math.floor(difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        this.reEndDateErrorMessage = `Original Re-Release END  date is soon to go live in ${difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    return isValidDate;
  }

  /**
   * function used to wrap multiple validations if needed
   */
  async isValidForm(skip: string, originalRecord: any, itemForm: any, envRules: any){
    let shouldSubmit = true;
    if(skip !== 'date' && !this.dateVerificationConfirmed){
      let result = await this.validateDateChanges(originalRecord, itemForm);
      console.log('result from item validation dateVerificationConfirmed',result);
      this.showDatesVerificationModal = !result;
      if(!result){
        shouldSubmit = false;
      }
    }
    if(skip !== 'envs' && !this.dateVerificationConfirmed){
      let result = await this.validateEnvPreReqs(itemForm, envRules);
      console.log('result from item validationdateVerificationConfirmed',result);
      this.showEnvsVerificationModal = !result;
      if(!result){
        shouldSubmit = false;
      }
    }
    return shouldSubmit;
  }

  async validateEnvPreReqs(itemForm: any, envRules: any){
    let {value} = itemForm.value;
    let envCheck = await this.checkEnvFlagsRequirements(value);
    envRules.dev.status = envCheck.devReady;
    envRules.qa.status = envCheck.qaReady;
    envRules.prod.status = envCheck.prodReady;

    if(!envCheck.devReady || !envCheck.qaReady || !envCheck.prodReady)
    {
      return false;
    } else {
      return true;
    }

  }

  /**
   * Validates item form values before form submission.
   */
  validateItemForm(itemForm: any) {
    let isValid = true;
    if(itemForm.value.reReleaseEnd && !itemForm.value.reReleaseStart){
      this.alertMessage(false, 'Submit Error', 'reReleaseStart date is empty and reReleasEnd is not.');
      isValid = false;

      this.scroller.scrollToAnchor('require-fields');

      return false;
    }

    if (
      itemForm.value.prefab &&
      this.validationService.stringHasWhiteSpace(itemForm.value.prefab)
    ) {
      this.alertMessage(false, 'Submit Error', 'Prefab contains white space.');
      isValid = false;

      this.scroller.scrollToAnchor('require-fields');

      return false;
    }
    if (
      itemForm.value.prefab &&
      this.validationService.stringHasSlash(itemForm.value.prefab)
    ) {
      this.alertMessage(
        false,
        'Submit Error',
        'Prefab contains a \'/ at the beginning. Please Remove the slash and try again.');
      isValid = false;

      this.scroller.scrollToAnchor('require-fields');

      return false;
    }

    if (
      itemForm.value.thumbnail &&
      this.validationService.stringHasWhiteSpace(itemForm.value.thumbnail)
    ) {
      this.alertMessage(
        false,
        'Submit Error',
        'Thumbnail contains a \'/ at the beginning. Please Remove the slash and try again.'
      );
      isValid = false;
      this.scroller.scrollToAnchor('require-fields');

      return false;
    }
    if (
      itemForm.value.thumbnail &&
      this.validationService.stringHasSlash(itemForm.value.thumbnail)
    ) {
      this.alertMessage(
        false,
        'Submit Error',
        'Thumbnail contains white space.'
      );
      isValid = false;
      this.scroller.scrollToAnchor('require-fields');

      return false;
    }
    if (
      itemForm.value.fileName &&
      this.validationService.stringHasSlash(itemForm.value.fileName)
    ) {
      this.alertMessage(
        false,
        'Submit Error',
        'File Name contains white space.'
      );
      isValid = false;
      this.scroller.scrollToAnchor('require-fields');

      return false;
    }

    if (this.validationService.isEmpty(itemForm.value.name)) {
      this.alertMessage(false, 'Submit Error', 'Name is required.');
      isValid = false;
      this.scroller.scrollToAnchor('item-details');

      return false;
    }

    if (this.validationService.isEmpty(itemForm.value.category_ref)) {
      this.alertMessage(false, 'Submit Error', 'Category is required.');
      isValid = false;
      this.scroller.scrollToAnchor('item-details');

      return false;
    }

    if (
      itemForm.value.costs_ref &&
      itemForm.value.costs_ref.length > 0
    ) {
      for (let cost of itemForm.value.costs_ref)
      {
        if (cost && cost.t == undefined) {
          this.alertMessage(
            false,
            'Submit Error',
            'Cost Resource Type cannot be null.'
          );
          isValid = false;
          this.scroller.scrollToAnchor('cost-section');
          break;
        }
        if (cost && cost.id == undefined) {
          this.alertMessage(
            false,
            'Submit Error',
            'Cost Currency Type cannot be null.'
          );
          isValid = false;
          this.scroller.scrollToAnchor('cost-section');
          break;
        }
        if (cost && cost.c == undefined) {
          this.alertMessage(
            false,
            'Submit Error',
            'Cost count cannot be null.'
          );
          isValid = false;
          this.scroller.scrollToAnchor('cost-section');
          break;
        }
      }
    }

    if (this.validationService.isEmpty(itemForm.value.fileName)) {
      this.alertMessage(false, 'Submit Error', 'File Name is required.');
      isValid = false;
      this.scroller.scrollToAnchor('required-prod');

      return false;
    }

    return isValid;
  }
  /**
   * Display alter message
   *
   * @param success Flag that sets whether or not is a succes alert
   * @param message Message to display.
   * @param detail Additional details.
   */
  alertMessage(success: boolean = true, message: string, detail: string) {
    this.messageService.add({
      severity: success ? 'success' : 'error',
      summary: message,
      detail: detail,
      life: 3000
    });
  }

}
