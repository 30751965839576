import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-sponsor-form-v2',
  templateUrl: './sponsor-form-v2.component.html',
  styleUrls: ['./sponsor-form-v2.component.sass']
})
export class SponsorFormV2Component  extends BaseDynamicFormComponent implements OnInit
{
  sponsorRecord: BaseEntityDto = new BaseEntityDto();
  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Sponsor Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Sponsor Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
          },
          {
            name: "Art Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'image_ref',
            label: 'Art Image',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            scrollHeight: '200px',
            options: {
              fieldName: 'image_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: 3 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
            },
            columnWidth: 12
          },
          {
            name: "Feed Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'feed_image_ref',
            label: 'Feed  Image',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            scrollHeight: '200px',
            options: {
              fieldName: 'feed_image_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: 3 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
            },
            columnWidth: 12
          },
          {
            name: "URL",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'url',
            isRequired: false,
            label: 'URL',
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            isRequired: false,
            clearField: true,
            showField: true,
            key: 'start',
            label: 'Start Date/Time',
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            isRequired: false,
            clearField: true,
            showField: true,
            key: 'end',
            label: 'End Date/Time',
            calendarMinDate: this.sponsorRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
            columnWidth: 6,
        }
      ],
      type: DynamicCardType.Card
      },
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.sponsorRecord =
    {
      enabled: false
    };
    this.title = "Sponsor";
    this.viewRoute = 'sponsors';
    this.isLoading = false;
  }

  /**
   * Validate Sponsor required field.
   *
   * @param sponsor Sponsor record
   */
  validateSponsor(sponsor: any)
  {
    let confirmValidationCallback = sponsor && sponsor.name && sponsor.name.length > 0;
    let message = confirmValidationCallback ? 'Valid' : 'Sponsor Name must have a value';

    return {confirmValidationCallback, message}
  }
}
