import { Injectable } from '@angular/core';
import { constant } from 'lodash';
import { FormService } from 'src/app/services/form.service';
import { MessageService } from 'primeng/api';
import { TableCommunicationService } from './table-communication.service';
import { GridApi } from 'ag-grid-enterprise';

interface RowChange {
  original: any; // Consider specifying a more precise type
  new: any; // Consider specifying a more precise type
  selectedColumn: string;
}

interface ChangedDataType {
  [key: string]: any; // Ideally, replace 'any' with a more specific type based on your data
}

type ChangesPreview = { [key: string]: RowChange };

@Injectable({
  providedIn: 'root'
})
export class AgGridEditService {

  constructor(
    private formService: FormService,
    private messageService: MessageService,
    private tableCommunicationService: TableCommunicationService,

  ) {

  }

  convertToMongoDate(dateString: string) {
    // Convert the date string to a JavaScript Date object
    const jsDate = new Date(Date.parse(dateString));

    // If the date is invalid, return null or handle accordingly
    if (isNaN(jsDate.getTime())) {
      return null;
    }

    return jsDate;
  }

  convertValueForDB(field: any, newValue: any, colDef: any) {
    if (!newValue) {
      return null;
    }
    switch (field) {
      case 'start':
      case 'end':
      case 'reReleaseStart':
      case 'reReleaseEnd':
        return this.convertToMongoDate(newValue);
        break;
      case 'collection_ref':
        if (Array.isArray(newValue)) {
          return newValue.map((obj: any) => { return { id: obj.id, name: obj.name, } });
        } else {
          // console.error('newValue is not an array:', newValue);
          // handle the case where newValue is not an array as needed
          return newValue;
        }
        break;
      // case 'collection_ref':
      // return newValue.map((obj: any) => { return { id: obj.id, name: obj.name, } } );
      // break;
      default:
        if (colDef.cellEditorParams && colDef.cellEditorParams.objectValue && colDef.cellEditorParams.optionValue) {
          if (colDef.cellRendererParams && colDef.cellRendererParams.isArray) {
            return newValue.map((obj: any) => obj[colDef.cellEditorParams.optionValue]);
          } else {
            return newValue[colDef.cellEditorParams.optionValue];
          }
        } else {
          return newValue;
        }
        break;
    }
  }

  submitEditRow(value: any, entity: string, id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const formURL: string = `${entity}/update/${id}`;
      this.formService.submitForm(value, formURL, true).subscribe({
        next: (val) => {
          console.log('submitEditRow successful: ', val);
          resolve(val);
        },
        error: (err) => {
          console.log('err', err);
          this.messageService.add({
            sticky: false,
            severity: 'error',
            summary: `ERROR updating ${entity} ID: ${id}`,
            detail: err.status === 403 ? (err.error?.error || err.statusText) : JSON.stringify(err),
          });
          reject(err);
        }
      });
    });
  }


  /**
 * Submits a row change to the server, with support for environment-specific modifications.
 *
 * @param rowIndex The index of the row in the grid.
 * @param changedRowsMap A map of row indices to their changed data.
 * @param api An API object for interacting with the grid.
 * @param entity The entity type associated with the row.
 * @param changesPreview An optional map of changes for preview purposes.
 * @param rowID The ID of the row, if applicable.
 * @param storeEnv The store environment, if environment-specific changes are needed.
 * @returns A promise that resolves to true on success, or rejects with an error.
 */
  submitRow(
    rowIndex: number | undefined,
    changedRowsMap: Map<number, ChangedDataType> | undefined,
    api: any, // Consider defining a more precise type for the API
    entity: string,
    changesPreview: ChangesPreview | null = null,
    rowID: number | null = null,
    storeEnv: string | null = null
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        if (changedRowsMap && rowIndex !== undefined) {
          var changedData = changedRowsMap.get(rowIndex);
          const rowData = api.getDisplayedRowAtIndex(rowIndex)?.data;
          const recordId = rowData?.id;
          if (changedData && recordId) {
            if (storeEnv) {
              const exceptionKeys = ['id', '_id', 'userData', 'name'];
              const modifiedData = Object.keys(changedData).reduce<ChangedDataType>((acc, key) => {
                if (exceptionKeys.includes(key)) {
                  acc[key] = changedData ? changedData[key] : null; // Directly copy the value for exception keys
                } else {
                  // For other keys, nest under storeEnv
                  acc[key] = { [storeEnv]: changedData ? changedData[key] : null };
                }
                return acc;
              }, {}); // The initial value of the accumulator is an empty object with type ChangedDataType
              changedData = modifiedData;
            }

            await this.submitEditRow(changedData, entity, recordId);
            changedRowsMap.delete(rowIndex);
            this.tableCommunicationService.submitSaveRow(rowIndex);
          } else {
            api.refreshServerSide();
          }
          resolve(true);
        } else if (changesPreview && rowID) {
          const changesData = changesPreview[rowID];
          if (changesData) {
            const updateData = { [changesData.selectedColumn]: changesData.new };
            await this.submitEditRow(updateData, entity, rowID);
            resolve(true);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  revertChanges(
    api: GridApi,
    changedRows: Map<number, any>,
    originalData: Map<string, any>,
    changedRowIndices: number[]
  ) {
    for (const rowIndex of changedRowIndices) {
      const rowChanges = changedRows.get(rowIndex);
      const currentRowNode = api.getDisplayedRowAtIndex(rowIndex);

      if (!currentRowNode) return;

      for (const field in rowChanges) {
        const originalCellValue = originalData.get(`${rowIndex}-${field}`);
        currentRowNode.data[field] = originalCellValue;
      }
      changedRows.delete(rowIndex);
      // Clean up originalData as well
      for (const field in rowChanges) {
        originalData.delete(`${rowIndex}-${field}`);
      }
    }
    // Redraw rows to reflect the changes in the UI
    api.redrawRows();
  }


  async submitBulkEdit(
    api: GridApi,
    changesPreview: { [key: string]: { original: any, new: any, selectedColumn: string } },
    selectedColumn: { id: string, name: string, type: string },
    changedRowsMap: Map<number, any>,
    entity: string
  ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // Step 1: Update all cell values first
        for (const [rowID, change] of Object.entries(changesPreview)) {
          const rowNode = api.getRowNode(rowID);
          if (rowNode) {
            rowNode.setDataValue(selectedColumn.id, change.new);
          }
        }

        // Step 2: Wait for next tick or slight delay
        await new Promise(resolve => setTimeout(resolve, 50));

        // Step 3: Now submit rows to the database
        const promises = [];

        for (const [rowID] of Object.entries(changesPreview)) {
          const rowNode = api.getRowNode(rowID);
          const rowIndex = rowNode?.rowIndex;
          if (rowNode && rowIndex !== undefined) {
            const submitPromise = this.submitRow(Number(rowIndex), changedRowsMap, api, entity);
            promises.push(submitPromise);
          } else {
            // if the row is being filtered, rowNode will not exist.
            const submitPromise = this.submitRow(undefined, undefined, api, entity, changesPreview, Number(rowID));
            promises.push(submitPromise);
          }
        }

        // Wait for all submitRow promises to resolve
        await Promise.all(promises);

        // Refresh the rows to reflect the changes
        api.redrawRows();
        api.refreshCells({ force: true });
        resolve();

      } catch (error) {
        reject(error);
      }
    });
  }

}
