<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="nurture-collection"
    [viewName]="title"
    [record]="nurtureCollectionRecord"
    [fields]="fields"
    [sidebarFields]="sidebarFields"
    [miscAssetKeys]="miscAssetKeys"
    [parentViewName]="parentViewName"
    [showLocalizedValuesButton]="true"
>
</dynamic-view-v2>
