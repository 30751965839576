import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams } from 'ag-grid-community';
import moment from 'moment';

@Component({
  selector: 'app-time-cell-filter',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeCellFilterComponent implements IFilterAngularComp, AfterViewInit {
  private params!: IFilterParams;
  filterValue: string | null = null;
  filterType: string;

  @ViewChild('timeInput', { static: true }) timeInput!: ElementRef;

  agInit(params: IFilterParams): void {
    this.params = params;
  }

  onTimeChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.filterValue = inputElement.value;
    this.params.filterChangedCallback();
  }

  doesFilterPass(params: any): boolean {
    if (!this.filterValue) return true;
    return params.data.time.startsWith(this.filterValue);
  }

  isFilterActive(): boolean {
    return this.filterValue !== null && this.filterValue !== '';
  }

  getModel() {
    return this.filterValue
      ? { 
          filterType: 'text',
          type: 'contains',
          filter: this.filterValue 
        }
      : null;
  }

  setModel(model: any): void {
    if (model) {
      this.filterValue = model.filter;
      this.filterType = model.filterType || "text";
      if (this.timeInput) {
        this.timeInput.nativeElement.value = this.filterValue || '';
      }
    } else {
      this.filterValue = null;
    }
  }
  
  getModelAsString(): string {
    return this.filterValue ? moment(this.filterValue, "HH:mm").format("hh:mm A") : '';
  }

  ngAfterViewInit(): void {}
}
