import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlasticService } from 'src/app/common/services/plastic.service';
import * as pako from 'pako';
import { firstValueFrom } from 'rxjs';
import { HttpContext } from '@angular/common/http';
import { SKIP_INTERCEPTOR } from 'src/app/auth/interceptor/auth.token.interceptor';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
@Component({
  selector: 'app-misc-asset-build-error-logs',
  templateUrl: './misc-asset-build-error-logs.component.html',
  styleUrls: ['./misc-asset-build-error-logs.component.sass']
})
export class MiscAssetBuildErrorLogsComponent {
  @Input() recordID: number;
  @Input() field: any;

  buildErrorLogs: any[] = [];
  isLoading: boolean = false;
  showPreviewDialog: boolean = false;
  previewContent: string = '';
  sanitizedPreviewContent: SafeHtml = '';

  @ViewChild('logContent') logContent: ElementRef;
  @ViewChild('logContainer') logContainer: ElementRef;


  constructor(private plasticService: PlasticService, private http: HttpClient, private sanitizer: DomSanitizer) { }

  async loadBuildErrorLogs(): Promise<void> {
    console.log('loadBuildErrorLogs');
    if (this.buildErrorLogs.length === 0) {
      try {
        this.isLoading = true;
        this.buildErrorLogs = await this.plasticService.fetchBuildErrorLogs(this.recordID);
        console.log('buildErrorLogs: ', this.buildErrorLogs);
      } catch (error) {
        console.error('Error fetching build error logs:', error);
        this.buildErrorLogs = []; // Reset on error
      } finally {
        this.isLoading = false;
      }
    }
  }

  downloadLog(key: string): void {
    const url = `https://build-nodes-logs.s3.us-east-2.amazonaws.com/${key}`;
    window.open(url, '_blank');
  }

  async previewLog(key: string): Promise<void> {
    try {
      const url = `https://build-nodes-logs.s3.us-east-2.amazonaws.com/${key}`;
      const response = await firstValueFrom(this.http.get(url, { 
        responseType: 'arraybuffer',
        headers: {},
        context: new HttpContext().set(SKIP_INTERCEPTOR, true)
      }));
      
      const arrayBuffer = response as ArrayBuffer;
      let content = pako.ungzip(new Uint8Array(arrayBuffer), { to: 'string' });
      
      // Highlight all UNITY ERROR occurrences
      const lines = content.split('\n');
      for (let i = 0; i < lines.length; i++) {
        if (lines[i].includes('UNITY ERROR')) {
          lines[i] = `<span style="background-color: #b51919;">${lines[i]}</span>`;
        }
      }
      this.previewContent = lines.join('\n');
      this.sanitizedPreviewContent = this.sanitizer.bypassSecurityTrustHtml(this.previewContent);
      this.showPreviewDialog = true;
    } catch (error) {
      console.error('Error loading log file:', error);
    }
  }

} 