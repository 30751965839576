import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable({
  providedIn: 'root'
})

/**
 * Spinner Services that handles spinner component logic and
 * properties.
 * 
 * This is a Library extracted from npm and built by ngx, with more than 50 different 
 * loading spinners. (https://www.npmjs.com/package/ngx-spinner)
 * 
 * How to use type?
 * 
 * 1. Go to the Load Awesome (https://labs.danielcardoso.net/load-awesome/animations.html)
 * 2. Select any animation, copy name of animation, replace all spaces with hyphen(-) and all letters should be lowercase.
 *  -. Let's say if I select "Ball 8bits" animation then type will be ball-8bits.
 * . 
 */
export class SpinnerService 
{
  constructor(private spinner: NgxSpinnerService) { }

  /**
   * Start the spinner and set the
   * timeout for the spinner to end.
   * 
   * @param endsAfter The spinner ends after x seconds
   * @param spinnerEndsAfterTimeout Flag that sets whether or not to set timeout to end current spinner
   */
  loadSpinner(endsAfter: number = 0, setTimeoutToEndSpinner: boolean = false)
  {
    this.spinner.show();

    if(setTimeoutToEndSpinner)
    {
      setTimeout(() => {
        /** spinner ends after x seconds */
        this.spinner.hide();
      }, endsAfter);
    }
  }

  /**
   * Hides the spinner component
   */
  endSpinner()
  {
    this.spinner.hide();
  }

  /**
   * Loads an specific spinner by name
   * 
   * @param name The name of the spinner
   */
  loadSpecificSpinner(name: string)
  {
    this.spinner.show(name);
  }

  /**
   * Ends an specific spinner by name
   * 
   * @param name The name of the spinner
   */
  endSpecificSpinner(name: string)
  {
    this.spinner.hide(name);
  }
}