import { Component, OnInit, Input } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { UtilitiesService } from '../../services/utilities.service';
import { AgGridToolsService } from '../../ag-grid-table/services/ag-grid-tools.service';
import { CellRendererService } from '../../ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from '../../ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { TextCellRendererComponent } from '../../ag-grid-table/cell-renderers/render-components/text/text.component';
import { BooleanEditorComponent } from '../../ag-grid-table/cell-editors/boolean/boolean.component';
import { DynamicTableConfigService } from '../../services/dynamic-table-config.service';

@Component({
  selector: 'app-dynamic-list-table',
  templateUrl: './dynamic-list-table.component.html',
  styleUrls: ['./dynamic-list-table.component.sass']
})
export class DynamicListTableComponent implements OnInit {
  @Input() entity: string = 'link-destination';
  
  columnDefs: ColDef[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {};
  autopopulateSelect: string = '';
  options: any = {};
  public bulkUpdateColDefs: any[] = [];
  public buildParams: any = {};
  public tableParams: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService,
    private tableConfigService: DynamicTableConfigService
  ) { }

  async ngOnInit(): Promise<void> {
    // Get table configuration based on entity
    const config = this.tableConfigService.getTableConfig(this.entity);
    
    // Apply configuration
    this.columnDefs = config.columnDefs;
    this.tableParams = config.tableParams;
    this.buildParams = config.buildParams;
    this.bulkUpdateColDefs = config.bulkUpdateColDefs;

    // Get column selection
    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }
}
