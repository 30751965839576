<div class="background">
  <p-card class="container">
    <ng-template pTemplate="header">
      <div class="header-content">
        <img src="../../assets/images/bgs-logo.png" alt="Logo" class="logo-img" />
        <!-- Custom class for styled title -->
        <h1 class="custom-title p-m-1">Garden Joy</h1>
        <!-- Custom class for styled subtitle -->
        <h2 class="custom-subtitle p-m-1">Flora CMS</h2>
        <small class="p-m-1"><strong>Environment:</strong> {{domainEnv}}</small>
      </div>
    </ng-template>
    <div class="p-fluid">
      <div>
        <asl-google-signin-button *ngIf="!loggedIn && !isGuestUser" type='standard' size='large' shape='pill' theme='filled_blue'
          logo_alignment='center'>
        </asl-google-signin-button>
        <!--  -->
        <p-card *ngIf="loggedIn && !isGuestUser" header="{{'Welcome!'}}" subheader="{{user.name}}" [style]="{width: '250px'}"
          styleClass="p-card-shadow">
          <ng-template pTemplate="header" *ngIf="user && loggedIn">
            <img *ngIf="loggedIn" src="{{ user.photoUrl.replace('s96-c','s500-c') }}" referrerpolicy="no-referrer"
              [style]="{objectFit: 'cover', 'border-radius': '10px', height: '200px'}">
          </ng-template>
          <small *ngIf="user && loggedIn">{{ user.email }}</small>
        </p-card>
        <!--  -->
        <p-button *ngIf="loggedIn && !isGuestUser" label="Logout from Google" icon="pi pi-sign-out" (click)="logout()"
          styleClass="p-button-secondary" [style]="{'margin-left': '.5em'}"></p-button>
          <p-button *ngIf="loggedIn && isGuestUser" label="Logout from Test CMS" icon="pi pi-sign-out" (click)="logout()"
          styleClass="p-button-secondary" [style]="{'margin-left': '.5em'}"></p-button>
        <!--  -->
      </div>

      <div *ngIf="!loggedIn">
        <p-divider align="center">
          <span>OR</span>
        </p-divider>
        <div >
          <p-divider align="center" class="p-mt-3">
            <span class="p-tag p-tag-info" style="font-size: 15px;">External Users</span>
          </p-divider>
          <div class="p-mb-2">
            <p-button label="Login" icon="fa-solid fa-right-to-bracket"  styleClass="p-button-secondary"
            (click)="displayExternalLogin = true"></p-button>
          </div>
          <div>
            <p-button label="Register" icon="fa-solid fa-user-plus" styleClass="p-button-secondary"
            (click)="displayExternalRegister = true"></p-button>
          </div>
        </div>
      </div>
    </div>
  </p-card>

  <!-- LOGIN External User -->
  <p-dialog header="Login as External User" [(visible)]="displayExternalLogin" [breakpoints]="{'960px': '75vw'}"
    [style]="{width: '25vw'}" [draggable]="false" [resizable]="false">
    <div class="grid p-fluid">
      <div class="col-12">
        <span class="p-float-label">
          <input id="guest-username" type="text" pInputText [(ngModel)]="guestUser.email">
          <label for="guest-username">Email</label>
        </span>
      </div>
      <div class="col-12" style="margin-top: 20px;">
        <span class="p-float-label">
          <p-password id="guest-password" [(ngModel)]="guestUser.password" [toggleMask]="true"
            [feedback]="false"></p-password>
          <label for="guest-password">Password</label>
        </span>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple type="button" label="Login" class="p-button-outlined" (click)="externalLogin()"></button>
      <button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-secondary"
        (click)="displayExternalLogin=false" [style]="{'margin-left': '.5em'}"></button>
    </ng-template>
  </p-dialog>

  <!-- Register Test User -->
  <p-dialog header="Register to as External User" [(visible)]="displayExternalRegister" [breakpoints]="{'960px': '75vw'}"
    [style]="{width: '35vw'}" [draggable]="false" [resizable]="false">
    <div class="grid p-fluid">
      <div class="col-12">
        <span class="p-float-label">
          <input id="guestUser-email" type="text" style="width: 300px;" pInputText [(ngModel)]="guestUser.email">
          <label for="guestUser-email">Email</label>
        </span>
      </div>
      <div class="col-12" style="margin-top: 30px;">
        <span class="p-float-label">
          <p-password id="guest-password" [(ngModel)]="guestUser.password" [toggleMask]="true"
            [feedback]="false"></p-password>
          <label for="guest-password">Password</label>
        </span>
      </div>

      <div class="col-12" style="margin-top: 30px;">
        <p-checkbox inputId="guestUser-test" [binary]="true" [(ngModel)]="guestUser.isTest"></p-checkbox>
        <label for="guestUser-test" for="simple" class="p-ml-2">Is a test user?</label>
      </div>
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple type="button" label="Register" class="p-button-outlined"
        (click)="externalRegister()"></button>
      <button pButton pRipple type="button" label="Cancel" class="p-button-outlined p-button-secondary"
        (click)="displayExternalRegister=false" [style]="{'margin-left': '.5em'}"></button>
    </ng-template>
  </p-dialog>
</div>
