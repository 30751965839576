import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nested-input-view',
  templateUrl: './nested-input-view.component.html',
  styleUrls: ['./nested-input-view.component.css']
})
export class NestedInputViewComponent implements OnInit {

  @Input() record: any;
  @Input() field: any;
  @Input() layoutMode: 'horizontal'; 

  constructor() { }

  ngOnInit(): void {
  }

}
