<p-messages></p-messages>

<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!loading">
  <ng-container *ngFor="let field of fields">
    <div class="p-m-5">
      <field
        [field]="field"
        [form]="form"
        [formGroup]="form"
        [editMode]="editMode"
        [editDocument]="documentForEdit"
        (addField)="onFieldAdded($event)"
        (replaceField)="onFieldReplaced($event)"
        (removeField)="onFieldRemoved($event)"
      ></field>
    </div>
  </ng-container>
  <file-uplaoder *ngIf="showFileUploader" title="Upload Image" [entity]="entity" [uploadURL]="fileUploaderUrl" [entityType]="key" (onFileUpload)="onFileUpload($event)"></file-uplaoder>

  <!-- File Uplaoder -->

  <button
    pButton
    pRipple
    type="submit"
    label="Submit"
    [disabled]="!form.valid"
    class="p-m-5"
    [style]="{ 'min-width': '12.5em' }"
  ></button>
</form>
