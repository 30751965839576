import { Component } from '@angular/core';
import { IToolPanelAngularComp } from 'ag-grid-angular';
import { IRowNode, IToolPanelParams } from 'ag-grid-enterprise';
import { DataService } from 'src/app/services/data.service';
import { AgGridUserSettings } from '../../services/ag-grid-user-settings.service';
@Component({
  selector: 'app-column-presets',
  templateUrl: './column-presets.component.html',
  styleUrls: ['./column-presets.component.sass']
})
export class ColumnPresetsComponent implements IToolPanelAngularComp {
  private params!: IToolPanelParams;
  public entity: string;

  presets: any = {
    'items':
    [
      {
        name: 'Default',
        columns: [
          'id',
          'assetType',
          'fileName',
          'thumbnail_img',
          'name',
          'start',
          'end',
          'tags_ref',
          'itemStatus',
          'contentHold_ref',
          'collection_ref',
          'batch_ref',
          'env',
          'buildStatus',
          'imageBuildStatus',
          'isAssetUpToDate',
          'isImageUpToDate',
        ],
      },
      {
        name: 'Organic',
        columns: [
          'id',
          'category_ref',
          'type_ref',
          'fileName',
          'thumbnail_img',
          'name',
          'cultivar',
          'latinName',
          'plantFamily',
          'vendorStatus',
          'itemStatus',
          'costs_ref',
          'colors_ref',
          'traits_ref',
          'climates_ref',
          'tags_ref',
          'collection_ref',
          'batch_ref',
          'contentHold_ref',
          'start',
          'end',
          'buildStatus',
          'imageBuildStatus',
        ],
      },
      {
        name: 'Hard Surface',
        columns: [
          'id',
          'category_ref',
          'fileName',
          'thumbnail_img',
          'name',
          'itemStatus',
          'vendorStatus',
          'costs_ref',
          'colors_ref',
          'styles_ref',
          'materials_ref',
          'patterns_ref',
          'shape_ref',
          'tags_ref',
          'collection_ref',
          'batch_ref',
          'contentHold_ref',
          'internalReferenceLinks',
          'start',
          'end',
          'buildStatus',
          'imageBuildStatus',
        ],
      },
    ],
  }

  communityPresets: any[] = [];
  customPresets:any[] = [];

  isAddingPreset = false;
  newPresetName = '';

  selectedPreset: any;
  selectedCustomPreset: any;
  expandedPreset: any = null;

  get entityPresets(): any[] {
    return this.presets[this.entity] || [];
  }

  constructor(
    private dataService: DataService,
    private agGridUserSettings: AgGridUserSettings
  ){

  }

  agInit(params: any): void {
    this.params = params;
    this.entity = params.entity;
    this.syncCmsPresets()
  }

  refresh(): void {}

  // apply preset
  // applyPreset() {
  //   if (this.selectedPreset) {
  //     const columnsToShow = this.selectedPreset.columns;
  //     const allColumns = this.params.columnApi.getColumnState() || [];

  //     allColumns.forEach((column: any) => {
  //       column.hide = !columnsToShow.includes(column.colId);
  //     });

  //     this.params.columnApi.applyColumnState({
  //       state: allColumns,
  //       applyOrder: true,
  //     });
  //   }
  // }

  applyPreset() {
    if (this.selectedPreset) {
      const columnsToShow = this.selectedPreset.columns;
      const allColumns = this.params.columnApi.getAllColumns();

      // Map the preset columns to ensure the order and visibility
      const orderedColumnState = columnsToShow.map((colId: string) => {
        const column = allColumns?.find(c => c.getColId() === colId);
        if (column) {
          return { colId: colId, hide: false };
        }
        return null;
      }).filter((colState: any) => colState !== null);

      // Include columns not in the preset but set them as hidden
      allColumns?.forEach(column => {
        if (!columnsToShow.includes(column.getColId())) {
          orderedColumnState.push({ colId: column.getColId(), hide: true });
        }
      });

      // Apply the new state to the grid
      this.params.columnApi.applyColumnState({
        state: orderedColumnState,
        applyOrder: true,
      });
      this.agGridUserSettings.saveColumnState(this.entity, orderedColumnState, false, null);
    }
  }


  // presets management
  startAddingPreset() {
    this.isAddingPreset = true;
  }

  cancelAddingPreset() {
    this.isAddingPreset = false;
    this.newPresetName = '';
  }

  async saveCustomPreset() {
    // Logic to capture the current column state and save it
    const currentColumnState = this.getCurrentColumnState();
    if (this.newPresetName) {
      let newPreset = { name: this.newPresetName, columns: currentColumnState };
      this.customPresets = [...this.customPresets, newPreset];
      let response: any = await this.dataService.updateUserSettings('ag-grid-'+this.entity, 'columnPreset', newPreset); // Use the actual response type
      this.newPresetName = '';
      this.isAddingPreset = false;
    }
  }

  async deleteCustomPreset(option: any){
    console.log(option);
    await this.dataService.deleteRecord(option._id, 'user-settings');
    await this.syncCmsPresets();
  }

  getCurrentColumnState() {
    // Replace this with your logic to get the current column state
    let state = this.params.columnApi.getColumnState();
    return state.filter(obj => obj.hide === false).map((obj: any) => obj.colId);
  }

  toggleExpand(preset: any) {
    if (this.expandedPreset === preset) {
      this.expandedPreset = null; // Collapse the currently expanded item
    } else {
      this.expandedPreset = preset; // Expand the clicked item
    }
  }

  async syncCmsPresets(){
    try {
      let pageRef = 'ag-grid-'+this.entity;
      // Code to save the current column state to the user's profile
      let userPresets = await this.dataService.getUserSettings(pageRef, `columnPreset`);
      // Extract 'settings' attribute and store them in 'this.customPresets'
      this.customPresets = userPresets.map((preset: any) => ({ ...preset.settings, _id: preset._id, id: preset.id }));
      let communityPresets = await this.dataService.getUserSettings(pageRef, `columnPreset?community=true`);
      // Extract 'settings' attribute and store them in 'this.communityPresets'
      this.communityPresets = communityPresets.map((preset: any) => ({ ...preset.settings, _id: preset._id, id: preset.id, userRef: preset.userRef || 'undefined' }));
    } catch (error) {
      console.error("Failed to update user settings", error);
    }
  }

  toggledItem: any = null;

  toggleItemDetails(item: any, event: Event) {
    event.stopPropagation();
    if (this.toggledItem && this.toggledItem._id === item._id) {
      this.toggledItem = null; // Close if already open
    } else {
      this.toggledItem = item; // Open the clicked item
    }
  }

  isItemToggled(item: any): boolean {
    return this.toggledItem && this.toggledItem._id === item._id;
  }

}
