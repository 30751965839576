import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-field-display',
  templateUrl: './field-display.component.html',
  styleUrls: ['./field-display.component.sass']
})
export class FieldDisplayComponent {
  @Input() headerFieldGroup: { name: string, key: string }[];
  @Input() entity: { [key: string]: any };
}

