import { AuthGuard } from "../auth/auth.guard";
import { PerkTableAgGridComponent } from "../cms-v2/entities/perk/components/perk-table-ag-grid/perk-table-ag-grid.component";
import { PerkViewV2Component } from "../cms-v2/entities/perk/components/perk-view-v2/perk-view-v2.component";
import { PerkFormV2Component } from "../cms-v2/entities/perk/components/perk-form/perk-form.component";

export const perksRoutes = {
  prefix: 'perks',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: PerkTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: PerkFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: PerkViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: PerkFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
}; 