<dynamic-form-v2 
    *ngIf="!isLoading" 
    entityType="progression-levels" 
    [formName]="title" 
    [(record)]="progressionLevelRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [showBottonSubmit]="true"
    [autopopulateEntity]="true"
>
</dynamic-form-v2>