<h2>Form Creator</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <span class="p-formgroup">
    <div class="p-formgroup-inline">
      <span class="p-float-label p-field">
        <input
          [formControlName]="'name'"
          id="name"
          type="text"
          pInputText
          (change)="formatInput('name')"
        />
        <label for="name">Form Name</label>
      </span>

      <span class="p-float-label p-field">
        <input
          [formControlName]="'key'"
          id="key"
          type="text"
          pInputText
          pTooltip="camelCase"
          (change)="formatInput('key')"
        />
        <label for="key">formKey</label>
      </span>
    </div>

    <div class="p-formgroup-inline">
      <p-listbox
        class="p-field"
        [formControlName]="'fields'"
        [metaKeySelection]="false"
        [filter]="true"
        [filterPlaceHolder]="'Filter by field name'"
        [multiple]="true"
        [options]="sourceFields"
        optionLabel="name"
        optionValue="_id"
        display="chip"
        [listStyle]="{ 'max-height': '350px', 'min-width': '600px' }"
        (onChange)="updateTargetFields($event.value)"
      >
        <ng-template pTemplate="header">
          <span style="width: 90%">Select Fields</span>
          <button
            pButton
            type="button"
            label="Clear"
            class="p-button-text"
            (click)="onListCleared()"
          ></button>
        </ng-template>

        <ng-template let-field pTemplate="item">
          <b style="width: 35%">{{ field.name }}</b>

          <span style="width: 25%">{{ field.key }}</span>

          <span style="width: 25%">{{ field.controlType }}</span>

          <p-badge
            *ngIf="field.required"
            value="Req"
            severity="warning"
            [rounded]="true"
          ></p-badge>
        </ng-template>
      </p-listbox>

      <p-listbox
        class="p-field"
        *ngIf="targetFields.length > 0"
        [id]="'display-fields'"
        [options]="targetFields"
        optionValue="_id"
        [listStyle]="{ 'max-height': '457px' }"
        (onDblClick)="onListItemCleared($event.value)"
      >
        <ng-template let-field let-index="index" pTemplate="item">
          <span style="width: 1.5em">{{ index + 1 }}:</span>
          {{ field.name }}
        </ng-template>
      </p-listbox>
    </div>

    <p-button
      class="p-field"
      type="submit"
      label="Submit"
      [disabled]="!form.valid"
    ></p-button>
  </span>
</form>
