import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-infinite-series-form',
  templateUrl: './infinite-series-form.component.html',
  styleUrls: ['./infinite-series-form.component.sass']
})
export class InfiniteSeriesFormComponent extends BaseDynamicFormComponent implements OnInit {
  infiniteSeriesRecord: BaseEntityDto = new BaseEntityDto();
  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }

  /**
   * InfiniteSeries Form Initial Implementation
   */
  ngOnInit() {
    this.fields =
      [
        {
          title: "Infinite Series Data",
          fields:
            [
              {
                name: "Name",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'name',
                isRequired: true,
                label: 'Name',
              },
              {
                name: "Start Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'start',
                isRequired: false,
                label: 'Start Date/Time',
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                columnWidth: 6,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.end) {
                    return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
                  } else {
                    return true;
                  }
                },
              },
              {
                name: "End Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'end',
                isRequired: false,
                label: 'End Date/Time',
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                calendarMinDate: this.infiniteSeriesRecord.start,
                columnWidth: 6,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (record.start && incomingValue) {
                    return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
                  } else {
                    return true;
                  }
                }
              },
            ],
          type: DynamicCardType.Card
        },
      ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.infiniteSeriesRecord =
    {
      enabled: false
    };
    this.title = "Infinite Series";
    this.viewRoute = 'infinite-series';
    this.isLoading = false;
  }
}
