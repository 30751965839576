import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-automation-log-view-v2',
  templateUrl: './automation-log-view-v2.component.html',
  styleUrls: ['./automation-log-view-v2.component.sass']
})
export class AutomationLogViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  automationLogRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Automation Log Data",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'name', isRequired: true, label: 'Name' },
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.automationLogRecord =
    {
      enabled: false
    };
    this.title = "Automation Log";
    this.viewRoute = 'automation-log';
    this.isLoading = false;
  }

}
