// src/app/services/field-transformer.service.ts
import { Injectable } from '@angular/core';
import { DynamicInputType } from '../../../enums/DynamicInputType';
import { DynamicCardType } from '../../../enums/DynamicCardType';
import { InputFieldType } from '../../../enums/InputFieldType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Injectable({
  providedIn: 'root',
})
export class FieldTransformerService {
    transformFields(metadataFields: any[]): any[] {
        const transformedFields = metadataFields.map(field => {
          const inputType = DynamicInputType.BaseInputField;
          const inputFieldType = this.mapInputFieldType(field);
      
          // Spread field properties first
          const transformedField: any = {
            name: field.name,
            inputType: inputType,
            inputTypeFields: [inputFieldType],
            clearField: field.clearField || false,
            key: field.key || field.name,
            isRequired: field.required,
            label: field.label || field.name,
            disabled: field.disabled || false,
            columnWidth: field.columnWidth || 6,
            ...field,
          };
      
          // Handle specific field types and additional properties
          switch (field.type) {
            case 'Boolean':
              transformedField.columnWidth = 12;
              if (inputFieldType === InputFieldType.ToggleButton) {
                transformedField.onLabel = field.onLabel || 'Yes';
                transformedField.offLabel = field.offLabel || 'No';
              }
              break;
            case 'Date':
              Object.assign(transformedField, {
                calendarShowTime: true,
                calendarYearNavigator: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
              });
              break;
            case 'Number':
              // Pass through all number configuration settings
              transformedField.inputNumberShowButtons = field.inputNumberShowButtons;
              if (field.useInputNumberMin) {
                transformedField.useInputNumberMin = true;
                transformedField.inputNumberMin = field.inputNumberMin;
              } else {
                transformedField.useInputNumberMin = false;
                transformedField.inputNumberMin = null;
              }
              if (field.useInputNumberMax) {
                transformedField.useInputNumberMax = true;
                transformedField.inputNumberMax = field.inputNumberMax;
              } else {
                transformedField.useInputNumberMax = false;
                transformedField.inputNumberMax = null;
              }
              if (field.useMinFractionDigits) {
                transformedField.useMinFractionDigits = true;
                transformedField.minFractionDigits = field.minFractionDigits;
              } else {
                transformedField.useMinFractionDigits = false;
                transformedField.minFractionDigits = null;
              }
              if (field.useMaxFractionDigits) {
                transformedField.useMaxFractionDigits = true;
                transformedField.maxFractionDigits = field.maxFractionDigits;
              } else {
                transformedField.useMaxFractionDigits = false;
                transformedField.maxFractionDigits = null;
              }
              break;
            case 'ObjectId':
            case 'Array':
              // Base configuration for both Dropdown and AutoComplete
              Object.assign(transformedField, {
                labelKeys: ['id', 'name'],
                optionValue: field.optionValue || '_id',
                optionLabel: field.optionLabel || 'name',
                optionDisabled: 'disabled',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                setMongoId: false,
                relationshipEntity: field.relationshipEntity || field.apiController,
                customQueryField: field.customQueryField || 'name',
                minimal: field.minimal !== undefined ? field.minimal : true,
                autopopulate: field.autopopulate || false,
                virtuals: field.virtuals || false,
                select: field.select || null,
                sort: field.sort || { name: 1 }
              });

              if (!field.relationshipEntity && !field.apiController) {
                console.error(`No relationshipEntity or apiController provided for ObjectId/Array field: ${field.name}`);
                transformedField.relationshipEntity = 'unknown';
              }

              if (inputFieldType === InputFieldType.MultiSelect) {
                // Handle MultiSelect configuration
                transformedField.multiSelectType = field.multiSelectType || 'api';
                transformedField.multiSelectDisplayType = field.multiSelectDisplayType || 'chip';
                transformedField.showClear = field.showClear !== undefined ? field.showClear : true;
                transformedField.scrollHeight = field.scrollHeight || '200px';
                transformedField.style = field.style || {};

                if (field.multiSelectType === 'static') {
                  // Handle static values
                  transformedField.options = {
                    values: field.options?.values || [],
                    fieldName: field.name.toLowerCase()
                  };
                } else {
                  // Handle API-based values
                  const apiController = field.apiController || 'categories';
                  transformedField.options = {
                    fieldName: field.name.toLowerCase(),
                    apiController: apiController,
                    useSetOptionsFromRef: true,
                    isArrayOfStringIds: true,
                    minimal: false,
                    autopopulate: true,
                    virtuals: false,
                    sort: { name: 1 },
                    select: 'name id path _id'
                  };
                  transformedField.apiController = apiController;
                  transformedField.select = field.select || 'name id path _id';
                  transformedField.filter = field.filter !== undefined ? field.filter : true;
                  transformedField.filterBy = field.filterBy || 'name,id,path';
                }
              } else if (inputFieldType === InputFieldType.Dropdown) {
                Object.assign(transformedField, {
                  showClear: field.showClear !== undefined ? field.showClear : true,
                  scrollHeight: field.scrollHeight || '200px',
                  dropdownGroup: field.dropdownGroup || false,
                  apiController: field.apiController,
                  select: field.select || ['name', 'id', 'path', '_id'],
                  dropdownType: field.dropdownType || 'api',
                  filter: field.filter !== undefined ? field.filter : true,
                  filterBy: field.filterBy || 'id,name',
                  autoDisplayFirst: false,
                  setMongoId: true,
                  options: {
                    fieldName: field.name.toLowerCase(),
                    apiController: field.apiController,
                    minimal: false,
                    autopopulate: true,
                    virtuals: false,
                    sort: { name: 1 },
                    select: field.select ? field.select : ['name', 'id', 'path', '_id']
                  }
                });
              } else if (inputFieldType === InputFieldType.AutoComplete) {
                Object.assign(transformedField, {
                  dropdown: field.dropdown !== undefined ? field.dropdown : true,
                  multiple: field.multiple || false,
                  forceSelection: field.forceSelection !== undefined ? field.forceSelection : true,
                  minLength: field.minLength || 1,
                  delay: field.delay || 300,
                  scrollHeight: field.scrollHeight || '200px',
                  showClear: field.showClear !== undefined ? field.showClear : true,
                  emptyMessage: field.emptyMessage || 'No results found',
                  apiController: field.apiController,
                  select: field.select || '-createdAt -updatedAt -createdBy',
                  filter: field.filter !== undefined ? field.filter : true,
                  filterBy: field.filterBy || 'id,name',
                  autoDisplayFirst: false,
                  setMongoId: true,
                  options: {
                    fieldName: field.name.toLowerCase(),
                    apiController: field.apiController,
                    minimal: false,
                    autopopulate: true,
                    virtuals: false,
                    sort: { name: 1 },
                    select: field.select || '-createdAt -updatedAt -createdBy'
                  }
                });
              }
              break;
            // Handle other specific field types if necessary
          }
      
          // Include any custom properties
          if (field.customProperties) {
            Object.assign(transformedField, field.customProperties);
          }
      
          // Handle Dropdown specific properties
          if (inputFieldType === InputFieldType.Dropdown) {
            transformedField.optionLabel = field.optionLabel || 'name';
            transformedField.optionValue = field.optionValue || '_id';
      
            if (field.options) {
              transformedField.options = field.options;
            }
      
            // Include other dropdown properties
            transformedField.filter = field.filter || false;
            transformedField.filterBy = field.filterBy || '';
            transformedField.showClear = field.showClear || false;
            transformedField.autoDisplayFirst = field.autoDisplayFirst || false;
            transformedField.setMongoId = field.setMongoId || false;
            transformedField.style = field.style || {};
          }
      
          if (inputFieldType === InputFieldType.AutoComplete) {
            transformedField.autocompleteMultipleValues = field.autocompleteMultipleValues || false;
            transformedField.autocompleteShowEmptyMessage = field.autocompleteShowEmptyMessage || false;
            transformedField.autocompleteField = field.autocompleteField || 'name';
            transformedField.autocompleteMinLength = field.autocompleteMinLength || 1;
            transformedField.suggestions = field.suggestions;
            transformedField.style = field.style || {};
            transformedField.showClear = field.showClear || false;
          }
      
          if (field.options?.customQuery) {
            field.options.customQuery = this.transformCustomQuery(field.options.customQuery);
          }
      
          return transformedField;
        });
      
        return [
          {
            title: 'Dynamic Entities Data',
            fields: transformedFields,
            type: DynamicCardType.Card,
          },
        ];
      }

      private mapInputFieldType(field: any): InputFieldType {

        if (field.inputWidget === 'Dropdown') {
            return InputFieldType.Dropdown;
          }

        if (field.inputWidget === 'AutoComplete') {
            return InputFieldType.AutoComplete;
          }
        if (field.inputWidget === 'MultiSelect') {
            return InputFieldType.MultiSelect;
          }

        if (field.type === 'String') {
          switch (field.inputWidget) {
            case 'Editor':
              return InputFieldType.Editor;
            case 'InputTextArea':
              return InputFieldType.InputTextArea;
            case 'ColorPicker':
              return InputFieldType.ColorPicker;
            default:
              return InputFieldType.TextInput;
          }
        }
    
        if (field.type === 'Boolean') {
          switch (field.inputWidget) {
            case 'ToggleButton':
              return InputFieldType.ToggleButton;
            default:
              return InputFieldType.Checkboxv2;
          }
        }
    
        switch (field.type) {
          case 'Number':
            return InputFieldType.Number;
          case 'Date':
            return InputFieldType.Calendar;
          case 'ObjectId':
            return InputFieldType.Dropdown;
          case 'Array':
            return InputFieldType.MultiSelect;
          default:
            throw new Error(`Unsupported field type: ${field.type}`);
        }
      }

      private transformCustomQuery(customQuery: any): any {
        if (!customQuery) return null;
        
        const { field, operator, value } = customQuery;
        
        switch (operator) {
          case 'in':
            return { [field]: { $in: value } };
          case 'eq':
            return { [field]: value };
          // Add other operators as needed
          default:
            return null;
        }
      }

      transformDynamicEntityViewFields(metadataFields: any[]): any[] {
        const transformedFields = metadataFields.map(field => {
          // Base field configuration
          const baseField = {
            name: field.name,
            inputType: DynamicViewType.BaseViewField,
            clearField: true,
            key: field.name,
            isRequired: field.required || false,
            label: field.name,
            columnWidth: 6
          };

          // Handle different field types
          switch (field.type) {
            case 'String':
              return {
                ...baseField,
                viewTypes: [BaseViewFieldType.Text]
              };

            case 'Date':
              return {
                ...baseField,
                viewTypes: [BaseViewFieldType.Calendar],
                dateFormat: 'MM/dd/yyyy hh:mm a'
              };

            case 'Boolean':
              return {
                ...baseField,
                viewTypes: [BaseViewFieldType.Boolean],
                columnWidth: 4,
                booleanoptions: {
                  true: "True",
                  false: "False"
                }
              };

            case 'ObjectId':
              return {
                ...baseField,
                viewTypes: [BaseViewFieldType.Text]
              };

            case 'Array':
              if (field.inputWidget === 'MultiSelect' || field.inputWidget === 'AutoComplete') {
                // Check if this should be a MultiSelectObj
                const isReferenceField = field.suggestions?.apiController || 
                                       field.options?.apiController ||
                                       field.name.includes('_ref');
                
                if (isReferenceField) {
                  return {
                    ...baseField,
                    viewTypes: [BaseViewFieldType.MultiSelectObj],
                    controller: field.suggestions?.apiController || field.options?.apiController || field.name.replace('_ref', ''),
                    optionValue: field.optionValue || '_id',
                    optionLabel: field.optionLabel || 'name',
                    options: {
                      fieldName: field.name,
                      apiController: field.suggestions?.apiController || field.options?.apiController || field.name.replace('_ref', ''),
                      autopopulate: true,
                      select: field.suggestions?.select || 'name id path _id'
                    }
                  };
                }
                
                return {
                  ...baseField,
                  viewTypes: [BaseViewFieldType.MultiSelect],
                  optionValue: field.optionValue || 'value',
                  optionLabel: field.optionLabel || 'label',
                  options: {
                    values: field.options?.values || [],
                    fieldName: field.name
                  }
                };
              }
              return {
                ...baseField,
                viewTypes: [BaseViewFieldType.Text]
              };

            default:
              return {
                ...baseField,
                viewTypes: [BaseViewFieldType.Text]
              };
          }
        });

        return [{
          title: 'Dynamic Entity Data',
          fields: transformedFields,
          type: DynamicCardType.Card
        }];
      }
    }
