/**
 * Table Bulk Promotion DTO
 */
 export default class TableBulkPromotion
 {
    /**
     * List of rows to promote
     */
    rows: Array<any> = [];

    /**
    * Final Payloads
    */
    failedPayloads: Array<any> = [];
    /**
     * Success counts
     */
    successCount: number;
 }