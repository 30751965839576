<p-dialog [(visible)]="display" header="Table Actions" modal="true" [draggable]="true" [resizable]="true"
  [closable]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'width': '60vw' }">
  <div *ngIf="!areRowsSelected" class="p-mb-1">
    <small>Select rows to enable bulk actions</small>
  </div>

  <!-- Custom Actions Tools Panel -->
  <ng-container *ngIf="tableParams.customActionsLabel">
    <p-panel [header]="tableParams.customActionsLabel">
      <ng-container *ngIf="tableParams?.customActionButtons?.includes('syncPricePoints')">
        <div *ngFor="let env of environments" class="responsive-button-container">
          <p-button (onClick)="syncStoreV2AndPricePoints(env)" styleClass="p-button-responsive">
            <i class="fa-solid fa-cloud-arrow-down p-mr-2"></i>
            Sync Records from Admin Panel <strong class="p-ml-2">{{(env | uppercase)}}</strong>
          </p-button>
        </div>
      </ng-container>
      <ng-container *ngIf="tableParams?.customActionButtons?.includes('syncStoreListings')">
        <div *ngFor="let env of environments" class="responsive-button-container">
          <p-button (onClick)="syncStoreV2AndPricePoints(env)" styleClass="p-button-responsive">
            <i class="fa-solid fa-cloud-arrow-down p-mr-2"></i>
            Sync Records from Admin Panel <strong class="p-ml-2">{{(env | uppercase)}}</strong>
          </p-button>
        </div>
      </ng-container>
      <ng-container *ngIf="tableParams?.customActionButtons?.includes('addToCart') &&
      tableParams?.customActionButtons?.includes('syncPricePoints')">
        <div class="responsive-button-container">
          <p-button (onClick)="addToCart('price-points')" styleClass="p-button-responsive">
            <i class="fa-solid fa-cart-shopping p-mr-2"></i>
            Add Selected PricePoints to Cart
          </p-button>
        </div>
      </ng-container>
      <ng-container *ngIf="tableParams?.customActionButtons?.includes('addToCart') &&
      tableParams?.customActionButtons?.includes('syncStoreListings')">
        <div class="responsive-button-container">
          <p-button (onClick)="addToCart('store-listings')" styleClass="p-button-responsive">
            <i class="fa-solid fa-cart-shopping p-mr-2"></i>
            Add Selected Store Listings to Cart
          </p-button>
        </div>
      </ng-container>
      <ng-container *ngIf="tableParams?.customActionButtons?.includes('viewDifferencesStore')">
        <div class="responsive-button-container">
          <p-button [disabled]="selectedRows.length < 1" (onClick)="viewDifferences('store-listings')"
            styleClass="p-button-responsive">
            <i class="fa-solid fa-search p-mr-2"></i>
            View Differences
          </p-button>
        </div>
      </ng-container>
      <ng-container *ngIf="tableParams.customActionButtons?.includes('viewDifferencesPrice')">
        <div class="responsive-button-container">
          <p-button [disabled]="selectedRows.length < 1" (onClick)="viewDifferences('price-points')"
            styleClass="p-button-responsive">
            <i class="fa-solid fa-search p-mr-2"></i>
            View Differences
          </p-button>
        </div>
      </ng-container>
      <ng-container
        *ngIf="tableParams.customActionButtons?.includes('bulkLocalize') && tableParams?.localizeCollectionName">
        <div class="responsive-button-container">
          <p-button [disabled]="selectedRows.length < 1" (onClick)="bulkLocalize(tableParams?.localizeCollectionName)"
            styleClass="p-button-responsive">
            <i class="fa-solid fa-language p-mr-2"></i>
            Localize with Gridy
          </p-button>
        </div>
      </ng-container>
    </p-panel>
  </ng-container>

  <!-- Export Tools Panel -->
  <p-panel header="Export Tools (CSV)">
    <div class="p-grid responsive-button-grid">
      <div class="responsive-button-container">
        <p-button label="Current Columns" icon="pi pi-file-excel" class="p-mr-2 p-button-responsive"
          (onClick)="exportToCSV('current')"></p-button>
      </div>
      <div class="responsive-button-container" *ngIf="entity=='items'">
        <p-button label="Organic Set" icon="pi pi-file-excel" class="p-mr-2 p-button-responsive"
          (onClick)="exportToCSV('organic')"></p-button>
      </div>
      <div class="responsive-button-container" *ngIf="entity=='items'">
        <p-button label="Hard Surface Set" icon="pi pi-file-excel" class="p-mr-2 p-button-responsive"
          (onClick)="exportToCSV('hard-surface')"></p-button>
      </div>
    </div>
  </p-panel>

  <!-- Editing Tools Panel -->
  <p-panel header="Bulk Actions" *ngIf="!authService.hasRole('ROLE_READ_ONLY')">
    <div class="p-grid responsive-button-grid">
      <div class="responsive-button-container">
        <p-button [disabled]="!areRowsSelected" [label]="'Edit Selected '+entity" icon="pi pi-pencil"
          class="p-mr-2 p-button-responsive" (onClick)="toggleBulkEdit()"></p-button>
      </div>
      <div class="responsive-button-container" *ngIf="entity=='items'">
        <p-button [disabled]="!areRowsSelected" [label]="'Add Selected '+entity+' to Collection'"
          icon="pi pi-plus" class="p-button-responsive" (onClick)="toggleAddItemsToCollection('items')"></p-button>
      </div>
      <div class="responsive-button-container" *ngIf="entity=='challenges'">
        <p-button [disabled]="!areRowsSelected" [label]="'Add Selected '+entity+' to Collection'"
          icon="pi pi-plus" class="p-button-responsive" (onClick)="toggleAddItemsToCollection('challenges')"></p-button>
      </div>
      <div class="responsive-button-container" *ngIf="entity=='items'">
        <p-button [disabled]="!areRowsSelected" [label]="'Add Selected '+entity+' to Item Set'" icon="pi pi-plus"
          class="p-ml-2 p-button-responsive" (onClick)="toggleAddItemsToItemSet()"></p-button>
      </div>
    </div>
  </p-panel>

  <!-- Build Tools Panel -->
  <p-panel header="Build Tools">
    <div class="p-grid responsive-button-grid">
      <div class="responsive-button-container" *ngIf="buildParams.assetKeys">
        <small class="p-d-inline p-mr-2">
          Render Asset?
          <i pTooltip="Renders the asset once it has been successfully built, and a slack notification is sent to preview the asset."
            class="pi pi-info-circle" style="font-size: 12px"></i>
        </small>
        <p-inputSwitch class="p-d-inline p-mt-2 p-mr-3" [(ngModel)]="buildAndRender"></p-inputSwitch>
      </div>
      <div class="responsive-button-container">
        <p-button *ngIf="buildParams.assetKeys" [disabled]="!areRowsSelected" class="p-mx-2 p-button-responsive"
          (onClick)="onStartBulkBuild(true)">
          <i class="fab fa-unity p-mr-1"></i>{{'Build Selected '+entity+' Assets'}}
        </p-button>
      </div>
      <div class="responsive-button-container">
        <p-button *ngIf="buildParams.imageKeys" [disabled]="!areRowsSelected" class="p-mx-2 p-button-responsive"
          (onClick)="onStartBulkBuild(false)">
          <i class="fab fa-unity p-mr-1"></i>{{'Build Selected '+entity+' Images'}}
        </p-button>
      </div>
      <div class="responsive-button-container" *ngIf="buildParams.assetKeys">
        <p-button [disabled]="!areRowsSelected" class="p-mx-2 p-button-responsive" (onClick)="showRenderDiffDialog()">
          <i class="fa-solid fa-plus-minus p-mr-2"></i>{{'Render Diff Check for selected assets'}}
        </p-button>
      </div>
      <div class="responsive-button-container" *ngIf="buildParams.assetKeys">
        <p-button [disabled]="!areRowsSelected" class="p-mx-2 p-button-responsive"
          (onClick)="requestBulkAssetStatusCheck('promotion-status')">
          <i class="fa-solid fa-folder-tree p-mr-2"></i>{{'Check Promotion Status for selected assets'}}
        </p-button>
      </div>
      <div class="responsive-button-container" *ngIf="bulkBuild.failedPayloads.length > 0">
        <p-button pRipple icon="pi pi-exclamation-circle" label="Failed Payloads"
          styleClass="p-button-danger p-mx-5 p-button-responsive" (click)="onShowFailedPayloads()">
        </p-button>
      </div>
    </div>
  </p-panel>

  <!-- Promotion Tools Panel -->
  <p-panel header="Promotion Tools">
    <div class="p-grid responsive-button-grid">
      <div class="responsive-button-container">
        <p-button *ngIf="buildParams.assetKeys" [disabled]="!areRowsSelected" class="p-mr-2 p-button-responsive"
          (onClick)="startBulkPromotion(true)">
          <i class="fa-solid fa-circle-up p-mr-2"></i>{{'Promote Selected '+entity+' Assets'}}
        </p-button>
      </div>
      <div class="responsive-button-container">
        <p-button *ngIf="buildParams.imageKeys" [disabled]="!areRowsSelected" class="p-mr-2 p-button-responsive"
          (onClick)="startBulkPromotion(false)">
          <i class="fa-regular fa-circle-up p-mr-2"></i>{{'Promote Selected '+entity+' Images'}}
        </p-button>
      </div>
    </div>
  </p-panel>

  <!-- ACTIONS: 'Render Options' panel -->
  <bulk-render-assets *ngIf="buildParams.renderBuildType" [selectedRecords]="selectedRows"
    [entityType]="buildParams.renderBuildType">
  </bulk-render-assets>
</p-dialog>

<!-- BULK EDIT DIALOG -->
<p-dialog [(visible)]="bulkEditDialogVisible" header="Bulk Edit" modal="true" [draggable]="true" [resizable]="true"
  [closable]="true" (onHide)="cancelBulkEdit()" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '55vw', 'min-height': '60vh'}">
  <div *ngIf="bulkEditState === 'select-column'">
    <div>
      <h5>Select a column to bulk edit</h5>
    </div>

    <p-listbox [options]="bulkUpdateColDefs" [(ngModel)]="selectedBulkEditColumns" [multiple]="true" [filter]="true"
      optionLabel="name" filterPlaceHolder="Search by Field Name">
      <ng-template let-option pTemplate="item">
        <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
          <!-- Column 1: Name -->
          <div style="flex: 1;">
            <strong>{{option.name}}</strong>
          </div>

          <!-- Column 2: Identifier wrapped in a tag -->
          <!-- <div style="flex: 1; text-align: center;">
            <span class="p-tag">{{option.id}}</span>
          </div> -->

          <!-- Column 3: Type wrapped in a tag -->
          <div style="flex: 1; text-align: right;">
            <small> <strong>Input Type:</strong> {{option.type}}</small>
          </div>
        </div>
      </ng-template>
    </p-listbox>
  </div>

  <div *ngIf="bulkEditState === 'select-edit-type'">
    <h5>Select an edit type</h5>

    <p-listbox [options]="editTypes" [(ngModel)]="editType" optionLabel="label" dataKey="value">
      <ng-template let-option pTemplate="item">
        <div>
          <!-- Display the Font Awesome icon -->
          <i class="fa {{option.icon}}" aria-hidden="true"></i>
          <strong class="p-ml-2">{{option.label}}</strong>
          <p class="p-mt-2">{{option.description}}</p>
        </div>
      </ng-template>
    </p-listbox>
  </div>



  <div *ngIf="bulkEditState === 'edit-values'">
    <div class="p-ml-2">
      <p>Edit Type: {{editType.label}}</p>
      <small class="p-ml-1">{{editType.description}}</small>
    </div>

    <hr>
    <app-bulk-edit-input #bulkEditInput [editType]="editType" [inputColumn]="getSelectedColumn()"
      [inputColumnDef]="getSelectedColumnDef()" [parentComponent]="parentAgGridTable"></app-bulk-edit-input>
  </div>

  <div *ngIf="bulkEditState === 'verify-changes'">
    <div class="p-mb-2 p-ml-1" style="line-height: 0px;">
      <h3>Bulk Edit Preview:</h3>
      <h4>Field: {{selectedBulkEditColumns[0].name}}</h4>
      <h4>Type: {{editType.label}}</h4>
      <p-progressBar *ngIf="submitInProgress" [value]="progress"></p-progressBar>
      <div *ngIf="this.editType.value == 'percentageChange' && tmpInputValue?.direction && tmpInputValue?.value"
        class="p-pl-2">
        <span>
          <h5>Direction: <small>{{tmpInputValue.direction}}</small></h5>
        </span>
        <span>
          <h5>Percentage Difference Value: <small>{{tmpInputValue.value}}%</small></h5>
        </span>
      </div>
      <hr>
    </div>
    <p-table class="p-mb-3" styleClass="p-datatable-sm p-datatable-striped" [value]="changeKeys" [paginator]="true"
      [rows]="10" [rowsPerPageOptions]="[10, 25, 50, 100]" paginatorPosition="both" paginatorDropdownAppendTo="body">
      <ng-template pTemplate="header">
        <tr>
          <th>Selected Rows</th>
          <th>ID</th>
          <th><p-tag value="Original Value" severity="warning"></p-tag></th>
          <th><p-tag value="New Value" severity="success"></p-tag></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-id>
        <tr>
          <td>
            <input type="checkbox" [(ngModel)]="changesPreview[id].selected" (change)="updateSelectedRows()">
          </td>
          <td>{{ id }}</td>
          <ng-container *ngIf="selectedBulkEditColumns[0].type === 'rich-text'; else notRichText">
            <td [innerHTML]="getSafeHtml(changesPreview[id].original)"></td>
            <td [innerHTML]="getSafeHtml(changesPreview[id].new)"></td>
          </ng-container>
          <ng-template #notRichText>
            <ng-container *ngIf="selectedBulkEditColumns[0].type === 'line-items'; else notLineItem">
              <!-- ORIGINAL LINE ITEM COUNT PILL and TYPE LABEL RENREDING -->
              <td>
                <div *ngFor="let v of changesPreview[id].original; let index = index" [ngStyle]="{ minWidth: '200px' }"
                  class="p-d-flex p-flex-column">
                  <div
                    class="p-ai-center cost-container {{ changesPreview[id].original.length > index + 1 ? 'p-pb-1' : '' }}">
                    <span class="p-ml-2"
                      style="background-color: var(--text-color); border-radius: 14%; padding: 1px 5px; font-weight: 700; line-height: 21.25px; font-size: 12px; color: var(--primary-color-text); text-align: center; margin-right: 6px; vertical-align: middle; ">
                      <strong class="p-m-auto"> {{ v.c | number }} </strong>
                    </span>
                    <div *ngIf="v.lineItemType == 'Item'; else nonItem">
                      <a pButton aria-label="{{ v.id.name }}" [label]="v.id.name+' ('+v.id.id+')'" target="_blank"
                        type="button" class="p-button-link p-text-left" routerLink="/items/{{ v.id.id }}"></a>
                    </div>
                    <ng-template #nonItem> {{ v && v.id && v.id.name ? v.id.name : null }} </ng-template>
                  </div>
                </div>
              </td>
              <td>
                <div *ngFor="let v of changesPreview[id].new; let index = index" [ngStyle]="{ minWidth: '200px' }"
                  class="p-d-flex p-flex-column">
                  <div
                    class="p-ai-center cost-container {{ changesPreview[id].new.length > index + 1 ? 'p-pb-1' : '' }}">
                    <span class="p-ml-2"
                      style="background-color: var(--text-color); border-radius: 14%; padding: 1px 5px; font-weight: 700; line-height: 21.25px; font-size: 12px; color: var(--primary-color-text); text-align: center; margin-right: 6px; vertical-align: middle; ">
                      <strong class="p-m-auto"> {{ v.c | number }} </strong>
                    </span>
                    <div *ngIf="v.lineItemType == 'Item'; else nonItem">
                      <a pButton aria-label="{{ v.id.name }}" [label]="v.id.name+' ('+v.id.id+')'" target="_blank"
                        type="button" class="p-button-link p-text-left" routerLink="/items/{{ v.id.id }}"></a>
                    </div>
                    <ng-template #nonItem> {{ v && v.id && v.id.name ? v.id.name : null }} </ng-template>
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-template #notLineItem>
              <ng-container *ngIf="selectedBulkEditColumns[0].type === 'multiselect'  else notMultiselect">
                <ng-container *ngIf="selectedBulkEditColumns[0].isFlatList  else notFlat">
                  <ng-container *ngIf="changesPreview[id].original">
                    <!-- Display for original -->
                    <td>
                      <ng-container *ngFor="let flatVal of changesPreview[id].original; let index = index">
                        {{flatVal}},
                      </ng-container>
                    </td>
                  </ng-container>
                  <ng-container *ngIf="changesPreview[id].new">
                    <!-- Display for new -->
                    <td>
                      <ng-container *ngFor="let flatVal of changesPreview[id].new; let index = index">
                        {{flatVal}},
                      </ng-container>
                    </td>
                  </ng-container>
                </ng-container>
                <ng-template #notFlat>
                  <!-- Display for original -->
                  <td>
                    <ng-container *ngIf="changesPreview[id]?.original">
                      <ng-container *ngFor="let objVal of changesPreview[id].original; let index = index">
                        {{objVal.name}} ({{objVal.id}}),<br>
                      </ng-container>
                    </ng-container>
                  </td>
                  <!-- Display for new -->
                  <td>
                    <ng-container *ngIf="changesPreview[id]?.new">
                      <ng-container *ngFor="let objVal of changesPreview[id].new; let index = index">
                        {{objVal.name}} ({{objVal.id}}), <br>
                      </ng-container>
                    </ng-container>
                  </td>
                </ng-template>
              </ng-container>
              <ng-template #notMultiselect>
                <td>{{ formatValue(changesPreview[id].original) }}</td>
                <td>{{ formatValue(changesPreview[id].new) }}</td>
              </ng-template>
            </ng-template>

          </ng-template>
        </tr>
      </ng-template>
    </p-table>
    <p-button label="Remove Selected Rows" (click)="removeSelectedRows()"></p-button>
  </div>

  <p-footer>
    <p-button *ngIf="bulkEditState !== 'select-column'" [disabled]="submitInProgress" label="Back"
      icon="pi pi-arrow-left" styleClass="p-button-warning" (onClick)="goBack()">
    </p-button>

    <p-button *ngIf="bulkEditState !== 'verify-changes'" [disabled]="submitInProgress" label="Next"
      icon="pi pi-arrow-right" styleClass="p-button-success" (onClick)="goNext()">
    </p-button>

    <p-button *ngIf="bulkEditState === 'verify-changes'" [disabled]="submitInProgress" label="Submit Changes"
      icon="pi pi-arrow-right" styleClass="p-button-success" (onClick)="submitBulkEdit()">
    </p-button>

    <p-button label="Cancel" [disabled]="submitInProgress" icon="pi pi-times" styleClass="p-button-danger"
      class="p-ml-3" (onClick)="cancelBulkEdit()">
    </p-button>
  </p-footer>
</p-dialog>

<!-- ADD TO COLLECTION DIALOG -->
<p-dialog header="Add to Collection" [(visible)]="addToCollectionDialogVisible" [modal]="true"
  [style]="{ width: 'auto' }" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <h3> Do you wish to add these items to a collection?</h3>
  <p-dropdown *ngIf="collections && collections.length>0" [options]="collections" [(ngModel)]="selectedCollection"
    optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
    placeholder="Select a Collection" appendTo="body" [style]="{ width: '100%' }">
    <ng-template let-collection pTemplate="item">
      <div>{{collection.name}} - ({{collection.id}})</div>
    </ng-template>
  </p-dropdown>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="addToCollectionDialogVisible = false" label="Cancel"
      styleClass="p-button-sm p-button-danger" class="p-mr-5"></p-button>
    <p-button icon="pi pi-plus" (click)="createCollectionDialogVisible = true" label="Create New Collection and Add"
      styleClass="p-button-sm p-button-warning" class="p-mr-2"></p-button>
    <p-button icon="pi pi-arrow-right" [disabled]="!selectedCollection" label="Add to Collection"
      (click)="addToCollection()" styleClass="p-button-sm"></p-button>
  </ng-template>
</p-dialog>

<!-- CREATE COLLECTION & ADD TO COLLECTION DIALOG -->
<p-dialog header="Create a Collection and upload to Collection" [(visible)]="createCollectionDialogVisible"
  [modal]="true" [style]="{ width: 'auto' }" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <h3>Collection Name</h3>
  <input [(ngModel)]="createCollection.name" [ngModelOptions]="{ standalone: true }" pInputText class="field-item"
    [style]="{ width: '100%'}" />

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="createCollectionDialogVisible = false" label="Cancel"
      styleClass="p-button-sm p-button-danger"></p-button>
    <p-button icon="pi pi-plus" (click)="createAndAddToCollection()" label="Create and Add Collection"
      styleClass="p-button-sm"></p-button>
  </ng-template>
</p-dialog>

<!-- ADD TO ITEM SET DIALOG -->
<p-dialog header="Add to Item Set" [(visible)]="addToItemSetDialogVisible" [modal]="true" [style]="{ width: 'auto' }"
  [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <h3> Do you wish to add these items to an Item Set?</h3>
  <p-dropdown *ngIf="itemSets && itemSets.length>0" [options]="itemSets" [(ngModel)]="selectedItemSet"
    optionLabel="name" optionValue="id" [filter]="true" filterBy="name" [showClear]="true"
    placeholder="Select an Item Set" appendTo="body" [style]="{ width: '100%' }">
    <ng-template let-itemSet pTemplate="item">
      <div>{{itemSet.name}} - ({{itemSet.id}})</div>
    </ng-template>
  </p-dropdown>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="addToItemSetDialogVisible = false" label="Cancel"
      styleClass="p-button-sm p-button-danger" class="p-mr-5"></p-button>
    <p-button icon="pi pi-plus" (click)="createItemSetDialogVisible = true" label="Create New Item Set and Add"
      styleClass="p-button-sm p-button-warning" class="p-mr-2"></p-button>
    <p-button icon="pi pi-arrow-right" [disabled]="!selectedItemSet" label="Add to Item Set" (click)="addToItemSet()"
      styleClass="p-button-sm"></p-button>
  </ng-template>
</p-dialog>

<!-- CREATE ITEM SET & ADD TO ITEM SET DIALOG -->
<p-dialog header="Create a new Item Set and add items" [(visible)]="createItemSetDialogVisible" [modal]="true"
  [style]="{ width: 'auto' }" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <h3>Item Set Name</h3>
  <input [(ngModel)]="createItemSet.name" [ngModelOptions]="{ standalone: true }" pInputText class="field-item"
    [style]="{ width: '100%'}" />

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="createItemSetDialogVisible = false" label="Cancel"
      styleClass="p-button-sm p-button-danger"></p-button>
    <p-button icon="pi pi-plus" (click)="createAndAddToItemSet()" label="Create and Add Item Set"
      styleClass="p-button-sm"></p-button>
  </ng-template>
</p-dialog>

<!-- BULK BUILD DIALOG -->
<p-dialog header="Send {{ bulkBuild.rows.length }} {{ entity }} to Build Queue?" [(visible)]="showBulkBuild"
  [modal]="true" [dismissableMask]="true">
  <p-table [value]="bulkBuild.rows" styleClass="p-datatable-sm">
    <ng-template pTemplate="header">
      <tr class="p-text-uppercase">
        <th *ngFor="let header of ['id', 'name', 'path(s)']">
          <span>{{ header }}</span>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>
          <ul>
            <li *ngFor="let jobData of item.buildJobData">
              {{ jobData.path }}
            </li>
          </ul>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <!--  -->

  <ng-template pTemplate="footer">
    <div class="p-my-2">
      <p-progressBar *ngIf="showBuildProgress" [value]="bulkBuildProgress"></p-progressBar>
    </div>
    <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" class="p-button-outlined"
      (click)="showBulkBuild = false; showBuildProgress = false"></button>
    <button pButton pRipple type="button" label="Build" icon="pi pi-check" [disabled]="disableBuildButton"
      (click)="buildMiscAsset()"></button>
    <button pButton pRipple type="button" label="Build with new hash" icon="pi pi-check" [disabled]="disableBuildButton"
      (click)="buildMiscAsset(true)"></button>
  </ng-template>
</p-dialog>

<!-- BULK BUILD DIALOG FAILED PAYLOADS -->
<p-dialog *ngIf="bulkBuild.failedPayloads && bulkBuild.failedPayloads.length > 0" header="Failed Payloads"
  [(visible)]="showBulkBuildFailed" [modal]="true" [dismissableMask]="true">
  <span class="p-grid nested-grid p-ai-center vertical-container p-m-1">
    <span class="p-col-fixed" [ngStyle]="{ width: '3em' }">
      USER DATA
    </span>

    <p-divider layout="vertical"></p-divider>

    <span class="p-col">
      <div *ngFor="let label of ['Name', 'Email', 'ID']" class="p-text-bold">
        {{ label }}:
      </div>
    </span>

    <span class="p-col">
      <div *ngFor="
        let value of [
          bulkBuild.failedPayloads[0].user.name,
          bulkBuild.failedPayloads[0].user.email,
          bulkBuild.failedPayloads[0].user.id
        ]
      " [ngStyle]="value ? '' : { color: 'red', fontWeight: 'bold' }">
        {{ value ? value : "empty" }}
      </div>
    </span>
  </span>

  <p-table [value]="bulkBuild.failedPayloads" styleClass="p-datatable-sm">
    <ng-template pTemplate="caption"></ng-template>
    <ng-template pTemplate="header">
      <tr class="p-text-uppercase">
        <th *ngFor="let header of ['id', 'build type', 'path', 'recursive']">
          <span>{{ header }}</span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payload>
      <tr>
        <td *ngFor="
          let value of [
            payload.entityId,
            payload.buildType,
            payload.path,
            payload.recursive
          ]
        " [ngStyle]="value ? '' : { color: 'red', fontWeight: 'bold' }">
          {{ value ? value : "empty" }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- BULK Promote Dialog -->
<p-dialog header="Promote {{ bulkPromote.rows.length }} {{ isAssetPromotion ? 'assets' : 'images' }}?"
  [(visible)]="showBulkPromote" [modal]="true" [dismissableMask]="true">
  <p-table [value]="bulkPromote.rows" styleClass="p-datatable-sm" appendTo="body">
    <ng-template pTemplate="header">
      <tr class="p-text-uppercase">
        <th *ngFor="let header of ['id', 'name', 'path(s)']">
          <span>{{ header }}</span>
        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>
          <ul>
            <li *ngFor="let jobData of item.buildJobData">
              {{ jobData.path }}
            </li>
          </ul>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <ng-template pTemplate="footer">
    <div class="p-my-2">
      <p-progressBar *ngIf="showPromoteProgress" [value]="bulkPromoteProgress"></p-progressBar>
    </div>
    <button pButton pRipple type="button" label="Cancel" icon="pi pi-times"
      class="p-button-outlined p-button-danger p-mr-5" (click)="showBulkPromote = false;"></button>
    <button pButton pRipple type="button" label="Promote" class="p-mr-3" icon="pi pi-cloud-upload"
      [disabled]="disablePromoteButton" (click)="onBulkPromotion(isAssetPromotion)"></button>
    <button pButton pRipple type="button" label="Promote with new hash"
      pTooltip="Promotes and generates a new hash for gamedata" tooltipPosition="top" icon="pi pi-cloud-upload"
      [disabled]="disablePromoteButton" (click)="onBulkPromotion(isAssetPromotion, true)"></button>
  </ng-template>
</p-dialog>

<!-- BULK Promote Failed Promotions Dialog -->
<p-dialog *ngIf="bulkPromote.failedPayloads && bulkPromote.failedPayloads.length > 0" header="Failed Promotions"
  [(visible)]="showBulkPromoteFailed" [modal]="true" [dismissableMask]="true">
  <p-table [value]="bulkPromote.failedPayloads" styleClass="p-datatable-sm">
    <ng-template pTemplate="caption"></ng-template>
    <ng-template pTemplate="header">
      <tr class="p-text-uppercase">
        <th *ngFor="let header of ['id', 'name', 'error']">
          <span>{{ header }}</span>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-payload>
      <tr>
        <td *ngFor="
        let value of [
          payload.id,
          payload.name,
          payload.promotionError
        ]
      " [ngStyle]="value ? '' : { color: 'red', fontWeight: 'bold' }">
          {{ value ? value : "empty" }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- ENV Diff -->
<p-dialog [(visible)]="differencesModal" [dismissableMask]="true" [modal]="true" [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '90vw' }" maximizable="true">
  <ng-template pTemplate="header">
    <div class="header-content">
      <h3>Environment Differences</h3>
    </div>
  </ng-template>
  <div>
    <p-accordion *ngIf="differencesLoaded" [multiple]="true">
      <p-accordionTab *ngFor="let item of differences.records">
        <ng-template pTemplate="header">
          <span>Name: {{findFirstString(item.name)}} <span
              style="cursor: pointer; text-decoration: underline; color: var(--primary-color)"
              (click)="navigateToDetails(item.id, differences.entity == 'store-listings' ? 'store-listings-v2' : 'price-points')">ID:
              {{ item.id }}</span> <p-tag [style]="{background: '#757575'}" *ngIf="!item.isDifferent"
              value="No Difference" icon="pi pi-info-circle"></p-tag></span>
        </ng-template>
        <p-table [value]="item.fields" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 3rem"></th>
              <th pSortableColumn="name">Name <p-sortIcon field="key"></p-sortIcon></th>
              <th pSortableColumn="dev">Dev Value<p-sortIcon field="source_value"></p-sortIcon></th>
              <th pSortableColumn="qa">Qa Value<p-sortIcon field="destination_values"></p-sortIcon></th>
              <th pSortableColumn="prod">Prod Value<p-sortIcon field="destination_values"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td></td>
              <td>{{item.key}}</td>
              <td><data-component [data]="item.dev"></data-component></td>
              <td><data-component [data]="item.qa"></data-component></td>
              <td><data-component [data]="item.prod"></data-component></td>
            </tr>
          </ng-template>
        </p-table>
      </p-accordionTab>
    </p-accordion>
  </div>
</p-dialog>

<!-- DIFF Check Options Dialog -->
<p-dialog header="Render Diff Check Options" [(visible)]="displayRenderDiffDialog" [style]="{width: '50vw'}">
  <div class="p-grid">
    <div class="p-col-12">
      <h4>Number of records selected: {{ selectedRowsSet.size }}</h4>
      <div class="p-mb-1 p-ml-2">
        <p-radioButton name="simple" value="simple" [(ngModel)]="diffCheckType"></p-radioButton>
        <label for="simple" class="p-ml-1">Simple Diff Check (Report sent Via Slack)</label>
      </div>
      <div class="p-mb-1 p-ml-2">
        <p-radioButton name="user-submission" value="user-submission" [(ngModel)]="diffCheckType"></p-radioButton>
        <label for="user-submission" class="p-ml-1">User Submission Diff Check (Infinite Gardening validity
          Check)</label>
        <span class="p-float-label p-mt-4 p-ml-4" *ngIf="diffCheckType=='user-submission'">
          <textarea id="diff-ref" [rows]="2" [cols]="50" pInputTextarea [(ngModel)]="diffCheckRef"></textarea>
          <label for="diff-ref">Reference text to identify a Diff Job (Optional)</label>
        </span>
      </div>
    </div>
    <div class="p-col-12 p-d-flex p-justify-center">
      <p-button [disabled]="!areRowsSelected" class="p-mx-2 p-button-responsive"
        (onClick)="requestBulkAssetStatusCheck('render-diff')">
        <i class="fa-solid fa-plus-minus p-mr-2"></i>{{'Trigger Render Diff Check for selected assets'}}
      </p-button>
    </div>
  </div>

</p-dialog>


<ngx-spinner bdColor="rgba(36,34,36,0.8)" size="medium" color="#81c784" type="line-scale" [fullScreen]="false">
  <h2>Loading...</h2>
</ngx-spinner>




<p-toast></p-toast>
