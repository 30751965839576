<div [style]="{ margin: '1em' }" *ngIf="entity">
  <div>
    <h1 style="margin: 0 0 1em" class="p-mr-3 p-d-inline">
      {{ entity.name }}
    </h1>
    <a
      pButton
      pRipple
      label="Edit"
      type="button"
      class="p-d-inline p-mb-2"
      icon="pi pi-pencil"
      href="/{{ type }}/edit/{{ entity.id }}"
    ></a>
  </div>
  <div class="p-grid nested-grid p-mt-3">
    <!-- Main Column (left) -->
    <div class="p-col-12 p-lg-8">
      <!-- Main Card -->
      <p-card class="p-mx-2 p-shadow-4">
        <div class="p-my-1">
          <strong>ID: </strong><span class="p-text-normal">{{ entity.id }}</span>
        </div>
        <div class="p-my-1">
          <strong>Name: </strong><span class="p-text-normal">{{ entity.name }}</span>
        </div>
        <div class="p-my-1">
          <strong>Enabled: </strong><span class="p-text-normal">{{ entity.enabled ? 'Yes' : 'No' }}</span>
        </div>
        <div class="p-my-1">
          <strong>Type: </strong><span class="p-text-normal">{{ entity.type == 1 ? '7 Day Login Rewards' : '30 Day Login Rewards' }}</span>
        </div>
        <div class="p-my-1">
          <strong>Rewards Tracks: </strong>
          <ul *ngFor="let reward of entity.rewardsTracks">
            <li>
              <a  [ngStyle]="{ color: 'var(--primary-color)' }" [routerLink]="'/rewards-track/'+reward.id" class="p-text-normal">{{ reward.name }} </a>
            </li>
          </ul>
        </div>
</p-card>
<p-card class="p-mx-2 p-shadow-4">
  <ng-template pTemplate="header">
    <span class="p-card-title">History</span>
  </ng-template>
  <div class="p-mx-5">
    <p-button
      class="p-mx-2"
      label="See latest History Changes"
      routerLink="/history/daily-rewards/{{ entity.id }}"
    ></p-button>
    <p-button
      class="p-mx-2"
      label="See latest Builds history"
      routerLink="/build-history/daily-rewards/{{ entity.id }}"
    ></p-button>
  </div>
</p-card>

</div>
<div class="p-col-3 p-lg-4">

  <ng-container *ngIf="true">
    <h4 class="p-m-2 p-d-none p-d-lg-flex">Environment(s):</h4>
    <span class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex">
       <!-- Environment(s) -->
       <ng-container>
        <div
          class="p-col p-m-2"
          [ngStyle]="{
            position: 'relative',
            color: 'var(--primary-color-text)',
            'background-color': 'green',
            'border-radius': '0.5em'
          }"
        >
          <h3 class="p-d-inline p-m-0 p-text-center">
            {{ entity.env && entity.env.length > 0 ? entity.env.join(', ') : 'No Environment Found' }}
          </h3>
        </div>
      </ng-container>
    </span>
  </ng-container>
</div>
