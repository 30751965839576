import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';
import NurtureCollectionDTO from '../dtos/NurtureCollectionDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-nurture-collection-form',
  templateUrl: './nurture-collection-form.component.html',
  styleUrls: ['./nurture-collection-form.component.sass']
})
export class NurtureCollectionFormComponent extends BaseDynamicFormComponent implements OnInit {

  nurtureCollectionRecord: NurtureCollectionDTO = new NurtureCollectionDTO();
  customQuery =
  {
    autopopulate: true,
    virtuals: true,
    populate: ['image_ref'],
    populateMinimal: false
  }

  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }


  /**
   * Seed Packs Forms V2 Component Initialization
  */

  ngOnInit(){

    this.fields =
    [
      {
        title: "Nurture Collection Data",
        fields:
        [
          { name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 12
          },
          {
            name: "Display Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'displayName',
            isRequired: false,
            label: 'Display Name',
            disabled: false,
            columnWidth: 6
          },
          {
            name: 'Nurture Collection Type',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            key: 'type',
            label: 'Nurture Collection Type',
            optionLabel: 'label',
            optionValue: 'value',
            options: { fieldName: 'type', values:
              [
                {
                  label: 'Type',
                  value: 'value',
                  items: [
                    {
                      label: 'Permanent',
                      value: 0,
                    },
                    {
                      label: 'Seasonal',
                      value: 1,
                    },
                  ],
                },
              ]
            },
            clearField: false,
            autoDisplayFirst: false,
            filter: false,
            showClear: true,
            isRequired: true,
            dropdownGroup: true,
            scrollHeight: '350px',
            columnWidth: 6
          },
          {
            name: "Region",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'region',
            isRequired: false,
            label: 'Region',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Priority",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'priority',
            label: 'Priority',
            inputNumberShowButtons: true,
            columnWidth: 6
          },
          {
            name: 'Nurture Group',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'nurtureGroup',
            label: 'Nurture Group',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            scrollHeight: '150px',
            style: { 'min-width': '30em' },
            options: {
              fieldName: 'nurtureGroup',
              apiController: 'nurture-collection-groups',
              minimal: false,
              autopopulate: true,
              virtuals: false,
              sort: { name: 1 }
            },
            columnWidth: 6
          },
          { name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            clearField: true,
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "Target Progress",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'targetProgress',
            label: 'Target Progress',
            inputNumberShowButtons: true,
            columnWidth: 6
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.nurtureCollectionRecord.start,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: 'Target Collection',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'collection_ref',
            isRequired: false,
            label: 'Target Collection',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: { fieldName: 'collection_ref', apiController: 'nurture-collection', minimal: false, autopopulate: true, virtuals: false, sort: { createdAt: 1 } },
            columnWidth: 6
          },
          { name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            clearField: true,
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: "Min Pulls 5/6",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'minPulls5',
            isRequired: false,
            label: 'Minimum Pulls 5/6',
            inputNumberMode: 'decimal',
            inputNumberShowButtons: true,
            inputNumberInputId: 'minPulls5',
            inputNumberMin: 0,
            inputNumberMax: 500,
            columnWidth: 6
          },
          {
            name: "Max Pulls 5/6",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'maxPulls5',
            isRequired: false,
            label: 'Maximum Pulls 5/6',
            inputNumberMode: 'decimal',
            inputNumberShowButtons: true,
            inputNumberInputId: 'maxPulls5',
            inputNumberMin: 0,
            inputNumberMax: 500,
            columnWidth: 6
          },
          {
            name: "Min Pulls 6/6",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'minPulls6',
            isRequired: false,
            label: 'Minimum Pulls 6/6',
            inputNumberMode: 'decimal',
            inputNumberShowButtons: true,
            inputNumberInputId: 'minPulls6',
            inputNumberMin: 0,
            inputNumberMax: 500,
            columnWidth: 6
          },
          {
            name: "Max Pulls 6/6",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'maxPulls6',
            isRequired: false,
            label: 'Maximum Pulls 6/6',
            inputNumberMode: 'decimal',
            inputNumberShowButtons: true,
            inputNumberInputId: 'maxPulls6',
            inputNumberMin: 0,
            inputNumberMax: 500,
            columnWidth: 6
          },
          {
            name: "Coming soon?",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'comingSoon',
            isRequired: false,
            label: 'Coming soon?',
            inputId: 'comingSoon',
            checkboxBinary: true,
            columnWidth: 6
          },
          {
            name: "Collection Color",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.ColorPicker],
            key: 'color',
            label: 'Collection Color',
            clearField: true,
            isRequired: false,
            disabled: false,
            columnWidth:6
          },

        ],
        type: DynamicCardType.Card
      },
      {
        title: "Images",
        fields: [
          {
            name: "Banner Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'image_ref',
            label: 'Banner Image',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'image_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 4] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Pin Background",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'pinBackground_ref',
            label: 'Pin Background',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'pinBackground_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 4] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Seed Pack",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'seedPack_ref',
            label: 'Seed Pack',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'seedPack_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 4] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          }          
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Nurture Items",
        fields: [
          {
            name: "Nurtures",
            inputType: DynamicInputType.nurtureItemsComponent,
            clearField: true,
            key: 'nurtures_ref',
            label: 'Nurtures',
            autocompleteMultipleValues: true,
            autocompleteShowEmptyMessage: true,
            autocompleteField: '_id',
            autocompleteMinLength: 3,
            suggestions: { fieldName: 'nurtures_ref', apiController: 'nurture', autopopulate: true, minimal: false, virtuals: false },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Rewards",
        fields: [
          {
            name: 'Reward(s)',
            inputType: DynamicInputType.Price,
            key: 'rewards_ref',
            priceExcludeResourceTypes: ['Titles'],
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Nurture Collection";
    this.viewRoute = 'nurture-collection';
    this.isLoading = false;
  }

  // validateLiveNurtureCollection(nurture_collection: any) {
  //   let { start, end, env } = nurture_collection;
  //   start = new Date(start);
  //   end = new Date(end);
  //   const currentDate = new Date();

  //   const isEnvAllowed = !env.includes('qa') && !env.includes('prod');
  //   const isOutsideLivePeriod = !(currentDate >= start && currentDate <= end);
  //   const confirmValidationCallback = isEnvAllowed && isOutsideLivePeriod;
  //   let message;

  //   // Emit specific messages based on validation failure
  //   if (!isEnvAllowed) {
  //     message = "This collection has an environment flag of QA or Prod."
  //   } else if (!isOutsideLivePeriod) {
  //     message = "This collection is in its live period."
  //   }
  //   return {confirmValidationCallback, message};
  // }
  validateLiveNurtureCollection(nurture_collection: any) {
    let { start, end, env, color } = nurture_collection;
    start = new Date(start);
    end = new Date(end);
    const currentDate = new Date();

    // Environment and date validation
    const isEnvAllowed = !env.includes('qa') && !env.includes('prod');
    const isOutsideLivePeriod = !(currentDate >= start && currentDate <= end);

    // Color validation
    const isColorValid = this.validation.colorValidator(color);

    // Initialize an array to hold all failure messages
    let messages: string[] = [];

    // Add specific failure messages
    if (!isEnvAllowed) {
      messages.push("This collection has an environment flag of QA or Prod.");
    }

    if (!isOutsideLivePeriod) {
      messages.push("This collection is in its live period.");
    }

    if (!isColorValid) {
      messages.push("The color entered is invalid. Please enter a valid hex color.");
    }

    // Final validation result
    const confirmValidationCallback = isEnvAllowed && isOutsideLivePeriod && isColorValid;

    // Join the messages if there are multiple failures
    const message = messages.length > 0 ? messages.join(' ') : "Validation passed.";

    return { confirmValidationCallback, message };
  }
}
