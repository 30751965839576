import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-series-view-v2',
  templateUrl: './series-view-v2.component.html',
  styleUrls: ['./series-view-v2.component.sass'],
})
export class SeriesViewV2Component
  extends BaseDynamicViewComponent
  implements OnInit
{
  seriesRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  ngOnInit() {
    this.fields = [
      {
        title: 'Series Data',
        fields: [
          {
            name: 'Display Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'displayName',
            isRequired: true,
            label: 'Display Name',
            columnWidth: 6,
          },
          {
            name: 'Internal Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 6,
          },
          {
            name: "Type",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.DropdownWithLabels],
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: true,
            options: {
                fieldName: 'type',
                values: [
                    { name: 'Mini Series', value: 'Mini Series' }
                ]
            },
            columnWidth: 12
          },         
          {
            name: 'Start Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            columnWidth: 6,
          },
          {
            name: 'End Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            columnWidth: 6,
          },
          {
            name: "Challenges",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'challenges_ref',
            isRequired: true,
            label: 'Challenges',
            controller: 'challenges',
            columnWidth: 6,
          },
          {
            name: 'Reward(s)',
            label: 'Reward(s)',
            inputType: DynamicViewType.Price,
            key: 'rewards_ref',
          },
          {
            name: "Announcement image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'announcementInbox_ref',
            label: 'Announcement image',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'announcementInbox_ref' }
          },
          {
            name: "Is Infinite Challenges Enabled",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            clearField: true,
            key: 'isInfiniteChallengeIncluding',
            label: 'Is Infinite Challenges Enabled',
            columnWidth: 6,
            booleanoptions: {
              true: "Including",
              false: "Excluding"
            }
          },
          {
            name: "Item Collection",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'collection_ref',
            label: 'Item Collection',
            controller: 'collections',
            columnWidth: 4
          },
          {
            name: "Item Collections (v2)",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'collections_ref',
            isRequired: true,
            label: 'Item Collections (v2)',
            controller: 'collections',
            columnWidth: 8,
          },
          {
            name: "Challenge Collection",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'challengeCollection_ref',
            label: 'Challenge Collections',
            controller: 'challenge-collections',
            columnWidth: 6
          },
          {
            name: "Secondary Challenge Collection",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'secondaryChallengeCollection_ref',
            label: 'Secondary Challenge Collection (NON I.G Logic)',
            controller: 'challenge-collections',
            columnWidth: 6
          },
          {
            name: "inbox Messages",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'inboxMessages_ref',
            isRequired: true,
            label: 'inbox Messages',
            controller: 'inbox-messages',
            columnWidth: 6,
          },
          {
            name: "Loading Screens",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'loadingScreen_ref',
            isRequired: true,
            label: 'loading Screens',
            controller: 'loading-screens',
            columnWidth: 6,
          },
          {
            name: "Store Listings",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'storeListings_ref',
            isRequired: true,
            label: 'Store Listings',
            controller: 'store-listings-v2',
            columnWidth: 6,
          },
          {
            name: "Collection Store Listing",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'collectionStoreListings_ref',
            isRequired: true,
            label: 'Collection Store Listing',
            controller: 'store-listings-v2',
            columnWidth: 6,
          },
          {
            name: "Colors",
            inputType: DynamicViewType.NestedInputComponent,
            clearField: true,
            key: 'colors',
            isRequired: true,
            label: 'Colors',
            columnWidth: 6,
          },
        ],
        type: DynamicCardType.Card,
      },
    ];

    this.seriesRecord = {
      enabled: false,
    };

    this.title = 'Series';
    this.viewRoute = 'series';
    this.isLoading = false;
  }
}
