import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-challenge-image-popup',
  templateUrl: './challenge-image-popup.component.html',
  styleUrls: ['./challenge-image-popup.component.sass']
})
export class ChallengeImagePopupComponent {

  @Input() visible: boolean = false;
  @Input() imgData: any = {};

  @Output() visibleChange = new EventEmitter<boolean>();

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  hideDialog() {
    this.visible = false;
    this.visibleChange.emit(this.visible); 
  }
}
