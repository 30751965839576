<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="achievements"
    [formName]="title"
    [(record)]="achievementRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [autopopulateEntity]="true"
    [hideEnvFlagsField]="false"
    (onPreLoad)="preLoad($event)"
    (onFieldChange)="triggerCustomValues($event)"
>
</dynamic-form-v2>