import { Component, Input, OnInit } from '@angular/core';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { BuildService } from 'src/app/build-data/services/build.service';

@Component({
  selector: 'app-misc-image-build-component',
  templateUrl: './misc-image-build-component.component.html',
  styleUrls: ['./misc-image-build-component.component.sass']
})
export class MiscImageBuildComponentComponent implements OnInit {
  constructor(
    private commonEntityService: CommonEntityService,
    private buildService: BuildService,
  ) {
    
  }
  @Input() field: any;
  @Input() record: any;
  @Input() type: 'ref' | 'self';

  assetRecord: any;

  get buildType(): typeof BuildType {
    return BuildType;
  }

  get assetType(): typeof AssetTypes {
    return AssetTypes;
  }

  ngOnInit(): void {
    if (!this.field || !this.record) {
      console.error('El campo o el registro no están definidos.');
      return;
    }

    if (this.type === 'self') {
      this.assetRecord = this.record;
    } else if (this.type === 'ref' && this.field.key && this.record.hasOwnProperty(this.field.key)) {
      this.assetRecord = this.record[this.field.key];
    } else {
      console.error(`The provided type(${this.type}) is neither "self" nor "ref", or the provided key (${this.field.key}) does not exist in the record.`);
    }

    console.log('Asset Registration:', this.assetRecord);
  }

  getAssetType(assetType: string): string {
    return assetType || "Processed Asset Type";
  }

  isUptoDate(entity: any): boolean {
    return true; 
  }

  getLastPromotedHash(entity: any): string {
    return entity?.lastPromotedHash || '';
  }

  getDynamicURL(id: string): string {
    return `/miscellaneous-build/${id}`;
  }

  openInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  getUpdatedBuildData(): void {
    setTimeout(async () => {
      let record = await this.commonEntityService.findOneWithQuery('miscellaneous-build', { query: { id: this.record.id } });

      if (record) {
        this.record.buildData = record.buildData;
      }
      // get build data.
      if(this.record.buildData)
        {
          let response = await this.buildService.getBuildData(this.record.buildData);
          this.record.buildData = response.buildData;
        }
        else
        {
          this.record.buildData = [];
        }
    }, 500);
  }
}
