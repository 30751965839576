<div *ngIf="data">
    <ng-container [ngSwitch]="getType(data)">
      <ng-container *ngSwitchCase="'object'">
        <p>{{data.name}}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'string'">
        <p>{{ data }}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'number'">
        <p>{{ data }}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'date'">
        <p>{{ data | officeTime }}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'boolean'">
        <p>{{ data ? 'True' : 'False' }}</p>
      </ng-container>
      <ng-container *ngSwitchCase="'array'">
        <ul>
          <li *ngFor="let item of data">{{ item }}</li>
        </ul>
      </ng-container>
      <ng-container *ngSwitchCase="'link'">
        <a style="color: #81c784;" routerLink="/price-points//{{ data.id }}" target="_blank">
          {{data.name}} ({{data.id}})
        </a>
      </ng-container>
      <ng-container *ngSwitchCase="'arrayOfObjects'">
        <ul *ngFor="let item of data" >
          <li>{{ item?.lineItemType}}</li>
          <li>{{item?.id?.name}}</li>
          <li>{{item?.c}}</li>
        </ul>
      </ng-container>
      <ng-container *ngSwitchCase="'null'">
        <p></p>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <p>/p>
      </ng-container>
    </ng-container>
  </div>