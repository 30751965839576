import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'refSuffixRemoval',
})
export class RefSuffixRemovalPipe implements PipeTransform {
  transform(value: String): string {
    return value.replace('_ref', '');
  }
}
