<div *ngIf="!isLoading; else isLoadingMsg">
  <ng-container *ngIf="eoTrack?.EOrewards?.length > 0">
    <div class="p-grid" *ngFor="let bundle of eoTrack?.EOrewards; let i = index">
      <div class="p-col-12 p-grid eoBundle">
        <div class="p-col-12 p-mb-2">
          <span>
            <h5 class="inline">Type: </h5>
            <p class="inline"> {{ bundle?.type === 'paid' ? 'Store Listing' : (bundle?.type | titlecase) }} </p>
            <h5 class="inline p-ml-3"> Step #{{i+1}}</h5>
          </span>
        </div>
        <div *ngFor="let reward of eoTrack.EOrewards[i]" class="p-col-12 p-grid rewardContainer">
          <ng-container *ngIf="reward.lineItemType == 'StoreListingV2'">
            <div class="p-col-4">
              <span class="inline">
                <strong class="inline">ID: </strong>
                <a style="display: inline; color: var(--primary-color)" target="_blank"
                  [href]="'/store-listings-v2/'+reward?.id?.id">
                  {{reward?.id?.name}}
                </a>
              </span>
            </div>
            <div class="p-col-8 p-grid" *ngIf="reward.lineItemType == 'StoreListingV2'">
              <span class="inline p-col-3">
                <strong class="inline">Start: </strong>
                <p class="inline">{{reward?.id?.storeStart[envKey]}}</p>
              </span>
              <span class="inline p-col-3">
                <strong class="inline">End: </strong>
                <p class="inline">{{reward?.id?.storeEnd[envKey]}}</p>
              </span>
              <span class="inline p-col-6">
                <strong class="inline">Price Point: </strong>
                <a style="color: var(--primary-color)" class="inline" target="_blank"
                  [href]="'/price-points/'+reward?.id?.pricePoint?.id">
                  {{reward?.id?.pricePoint?.name}}
                </a>
              </span>
            </div>
            <div class="p-col-12">
              <p style="font-size: 18px; font-weight: 500; margin: 0px; padding: 0px;">Credits:</p>
            </div>
            <div class="p-col-12 p-grid">
              <div class="p-col-4" style="list-style-type: none; padding: 0; font-size: 14px;"
                *ngFor="let credit of reward?.id?.credits_ref[envKey]">
                <span *ngIf="credit">
                  <img *ngIf="credit?.id?.imageSrc" [src]="credit?.id?.imageSrc"
                    style="height: 40px; width: 40px; vertical-align: middle;" alt="d">
                  <span class="reward-count">{{ credit?.c | number }}</span>
                  <strong class="inline">{{credit?.id?.name}}</strong>
                  <a class="inline" [ngStyle]="{ color: 'var(--primary-color)' }"
                    [href]="getLink(credit.lineItemType, credit?.id?.id)" target="_blank">
                    ({{ credit?.id?.id}})
                  </a>
                </span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="reward.lineItemType !== 'StoreListingV2'">
            <span class="reward-count">{{ reward?.c | number }}</span>
            <strong class="inline">{{reward?.id?.name}} </strong>
            <a class="inline" [ngStyle]="{ color: 'var(--primary-color)' }"
              [href]="getLink(reward.lineItemType, reward?.id?.id)" target="_blank">
              ({{ reward?.id?.id}})
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<ng-template #isLoadingMsg>
  Loading Steps Data...
</ng-template>