import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { NurtureCellRenderer } from 'src/app/common/ag-grid-table/cell-renderers/render-components/nurture/nurture.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { BuildStatusCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/build-status/build-status.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';

@Component({
  selector: 'app-nurture-table-ag-grid',
  templateUrl: './nurture-table-ag-grid.component.html',
  styleUrls: ['./nurture-table-ag-grid.component.sass']
})
export class NurtureTableAgGridComponent implements OnInit {

  public entity: string = 'nurture';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = 'costs_ref thumbnail_ref';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Region',
        field: 'region',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Nurture Collection',
        field: 'collectionId',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isLink: true
        },
        // sortable: true,
        // unSortIcon: true,
        resizable: true,
        // filter: 'agTextColumnFilter',
        // floatingFilter: true,
        autoHeight: true,
        // cellEditor: 'agTextCellEditor',
        // type: 'editableColumn',
        valueGetter: (params: any) => {
          if (params.data.collectionId){
            return `${params.data.collectionId.name} (${params.data.collectionId.id})`;
          } else {
            return null;
          }
        },
      },
      {
        headerName: 'Cost(s)',
        field: 'costs_ref',
        cellRenderer: NurtureCellRenderer,
        cellRendererParams: {
          key: 'stageCosts'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,

        width: 300,
        autoHeight: true,
      },
      {
        headerName: 'Stage Names',
        field: 'stageNames',
        cellRenderer: NurtureCellRenderer,
        cellRendererParams: {
          key: 'stageNames'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        width: 500,
        // filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        // filterParams: {
        //   addEmptyFilters: true,
        //   populate: true,
        //   select: '_id id name'
        // }
      },
      {
        headerName: 'Item ID',
        field: 'itemId',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'items',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.itemId,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Pin Asset',
        field: 'coinAsset_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'coinAsset_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.coinAsset_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Pin Asset Build Status',
        field: 'buildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'coinAsset_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
            addEmptyFilters: true,
            isAggregate: true,
            filterKey: 'coinAsset_ref',
            isBuildStatus: true
        },
        floatingFilter: true,

      },
      {
        headerName: 'Pin Thumbnail',
        field: 'coinThumbnail_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'coinThumbnail_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.coinThumbnail_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Pin Thumbnail Build Status',
        field: 'imageBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'coinThumbnail_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
            addEmptyFilters: true,
            isAggregate: true,
            filterKey: 'coinThumbnail_ref',
            isBuildStatus: true
        },
        floatingFilter: true,
      },
      {
        headerName: 'Icon Image',
        field: 'sproutImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'sproutImage_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.sproutImage_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Nurture Video',
        field: 'nurtureVideo_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'nurtureVideo_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.nurtureVideo_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Nurture Loop Video',
        field: 'nurtureLoopVideo_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'nurtureVideo_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.nurtureLoopVideo_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Rarity',
        field: 'rarity_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'nurture-rarity',
          isArray: false
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.rarity_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Seed Pack Open Image',
        field: 'seed_pack_open_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          entity: 'miscellaneous-build',

        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          values: this.options.seed_pack_open_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Seed Pack Closed Image',
        field: 'seed_pack_closed_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          entity: 'miscellaneous-build',

        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          values: this.options.seed_pack_closed_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      imageKeys: ['coinAsset_ref', 'coinThumbnail_ref', 'coinThumbnail_ref'],
      imageBuildType: BuildType.Images,
    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'nurtures',
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions(){
    const entities = [
      { ref: 'itemId', entity: 'items', select: 'name id _id', },
      { ref: 'rarity_ref', entity: 'nurture-rarity', select: 'name id _id', },
      { ref: 'coinAsset_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [AssetTypes.NurturePin] } } },
      { ref: 'coinThumbnail_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [AssetTypes.NurturePinThumb] } } },
      { ref: 'sproutImage_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [AssetTypes.NurtureIcon] } } },
      { ref: 'nurtureVideo_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [AssetTypes.NurtureVideo] } } },
      { ref: 'nurtureLoopVideo_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [AssetTypes.NurtureVideo] } } },
      { ref: 'seed_pack_open_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: AssetTypes.SeedPack } },
      { ref: 'seed_pack_closed_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: AssetTypes.SeedPack } },
    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }

  }

}
