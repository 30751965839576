<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="miscellaneous-build"
    [viewName]="title"
    [record]="assetRecord"
    [fields]="fields"
    [sidebarFields]="sidebarFields"
    [customBuildDataKeys]="customBuildDataKeys"
    [displayThumbnail]="true"
    [parseRecordData]="true"
    [displayBuildStatus]="true"
    (onParseRecordData)="prepareRecordData($event)"
    [miscAssetKeys]="miscAssetKeys"
>
</dynamic-view-v2>
