<div *ngIf="!isLoading; else isLoadingMsg">
  <div *ngIf="parentEntity == 'endless-offer' && rewardTrack">
    <span style="display: inline-block; vertical-align: middle;">
      Endless Offer Rewards Track Record:
    </span>
    <a href="/rewards-track/{{ rewardTrack.id }}" target="_blank"
      class="p-text-normal p-ml-2" style="color: #81c784; display: inline-block; vertical-align: middle;">
      <h4 style="display: inline; margin: 0;">{{ rewardTrack.name }} ({{rewardTrack.id}})</h4>
    </a>
  </div>
  <div>
    <span class="p-my-2">
      Is Loop: {{rewardTrack.isLoop ? 'Yes' : 'No'}}
    </span>
  </div>

  <p-table [value]="rewardTrack.EObundles" [tableStyle]="{'width': '100%'}" styleClass="p-datatable-striped p-mt-2">
    <ng-template pTemplate="header">
        <tr>
            <th style="padding: 10px 0 !important;">
              <span>Endless Offer Track Steps:</span>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-bundle let-index="rowIndex">
        <tr>
            <td>
              <div class="p-grid">
                <div class="p-col-12">
                  <p style="margin: 0;"><strong>Type: </strong>{{bundle.type == 'paid' ? 'Store Listing' : 'Rewards' }}</p>
                </div>
                <div class="p-col-12" *ngIf="bundle.type == 'paid'">
                  <div *ngIf="rewardTrack.EOrewards[index][0] && rewardTrack.EOrewards[index][0].id"
                      style="border-radius: 8px; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); background-color: #8c8c8c99;"
                    >
                      <div style="display: flex; justify-content: space-between; align-items: center; padding: 4px 8px; border-top-left-radius: 8px; border-top-right-radius: 8px;">
                        <span style="font-size: 16px; margin: 0;">
                          <!-- <strong>Store Listing ID:</strong> {{ rewardTrack.EOrewards[index][0]?.storeListingData?.Name }} -->
                          <strong>Store Listing ID:</strong> {{ rewardTrack.EOrewards[index][0]?.id.name }}
                        </span>
                      </div>
                      <div class="p-grid" class="p-px-1 p-py-1">
                        <div class="p-col-12 p-grid">
                          <div class="p-col-5">
                            <div>
                              <p style="display: block;" class="p-my-1">
                                <!-- <strong>Start:</strong> {{ rewardTrack.EOrewards[index][0]?.storeListingData?.DisplayDetails?.start }} -->
                                <strong>Start:</strong> {{ rewardTrack.EOrewards[index][0]?.id.storeStart.test }}
                              </p>
                            </div>
                            <div>
                              <p style="display: block;" class="p-my-1">
                                <!-- <strong>End:</strong> {{ rewardTrack.EOrewards[index][0]?.storeListingData?.DisplayDetails?.end }} -->
                                <strong>End:</strong> {{ rewardTrack.EOrewards[index][0]?.id.storeEnd.test }}
                              </p>
                            </div>
                            <div *ngIf="rewardTrack.EOrewards[index][0]?.id.priceLabel">
                              <p style="display: block;" class="p-my-1">
                                <strong>Price Point:</strong>
                                <a [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank"
                                href="/price-points"
                              > {{ rewardTrack.EOrewards[index][0]?.id.priceLabel.test }}</a>
                              </p>
                            </div>
                            <!-- These may be removed if they are not being used. -->
                            <!-- <div *ngIf="rewardTrack.EOrewards[index][0]?.storeListingData?.OriginalStoreListingName">
                              <p style="display: block;" class="p-my-1">
                                <strong>Original Store Listing ID:</strong>
                                <a [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank"
                                  href="https://admin.dev.petunia.withbuddies.com/Economy/StoreListing/?name={{ rewardTrack.EOrewards[index][0]?.storeListingData?.OriginalStoreListingName }}"
                                > {{ rewardTrack.EOrewards[index][0]?.storeListingData?.OriginalStoreListingName }}</a>
                              </p>
                            </div>
                            <div *ngIf="rewardTrack.EOrewards[index][0]?.storeListingData?.DisplayDetails?.backgroundHex">
                              <p style="display: block;" class="p-my-1">
                                <strong>Background Hex:</strong> {{rewardTrack.EOrewards[index][0].storeListingData.DisplayDetails.backgroundHex}}
                                <span
                                  [ngStyle]="{'background-color': rewardTrack.EOrewards[index][0]?.storeListingData?.DisplayDetails.backgroundHex, 'border-radius': '50%', display: 'inline-block', width: '15px', height: '15px'}"
                                >
                                </span>
                              </p>
                            </div> -->
                            <!-- <div  *ngIf="rewardTrack.EOrewards[index][0]?.storeListingData?.DisplayDetails?.referencePricePointText">
                              <p style="display: block;" class="p-my-1">
                                <strong>Reference Price Point:</strong> {{ rewardTrack.EOrewards[index][0]?.storeListingData?.DisplayDetails.referencePricePointText }}
                              </p>
                            </div> -->
                          </div>
                          <div class="p-col-7">
                            <div style="display: block;" *ngIf="rewardTrack.EOrewards[index][0]?.id && rewardTrack.EOrewards[index][0]?.id?.credits_ref">
                              <strong class="p-my-1">Credits:</strong>
                              <ul style="list-style-type: none; padding: 0; font-size: 14px;"
                              >
                                <li *ngFor="let credit of rewardTrack.EOrewards[index][0]?.id.credits_ref.qa" style="margin-bottom: 5px;">
                                  <img
                                    *ngIf="credit.thumbnail"
                                    src="{{credit.thumbnail}}"
                                    style="height: 40px; width: 40px; vertical-align: middle;"
                                    alt="d"
                                  >
                                  <span
                                  style="
                                    background-color: var(--text-color); border-radius: 14%; padding: 1px 5px; font-weight: 700; line-height: 21.25px; font-size: 12px;
                                    color: var(--primary-color-text); text-align: center; margin-left: 5px; margin-right: 3px;  "
                                  >
                                    <!-- <strong class="p-m-auto">{{credit.Quantity ? credit.Quantity: 0 | number}}</strong> -->
                                    <strong class="p-m-auto">{{credit.c}}</strong>
                                  </span>
                                  <!-- <span *ngIf="credit.displayName">
                                    {{credit.displayName}}
                                    <a
                                      href="{{credit.link}}"
                                      target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }"
                                    >
                                      ({{getCreditId(credit.CommodityKey)}})
                                    </a>
                                  </span> -->
                                  <span *ngIf="credit.lineItemType === 'SeedPackBox'">
                                    {{credit.id.name}}
                                    <a
                                      href="seed-box-pack/{{credit.id.id}}"
                                      target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }"
                                    >
                                      ({{ credit.id.id}})
                                    </a>
                                  </span>
                                  <span *ngIf="credit.displayName && credit.lineItemType !== 'SeedPackBox'">
                                    {{credit.displayName}}
                                    <a
                                      href="{{credit.link}}"
                                      target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }"
                                    >
                                      ({{ credit.link.split('/')[1] }})
                                    </a>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="p-col-12" *ngIf="bundle.type == 'free'">
                  <div class="p-grid" style="border-radius: 8px; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); background-color: #8c8c8c99;">
                    <div class="p-col-12">
                      <strong class="p-my-1 p-ml-2">Credits:</strong>
                    </div>
                    <div class="p-col-12 p-mb-1 p-pb-1">
                      <div  *ngFor="let reward of rewardTrack.EOrewards[bundle.index]">
                        <ul style="list-style-type: none; padding: 0; font-size: 14px;">
                          <li *ngIf="reward.lineItemType !=='Item'">
                            <span
                              class="p-ml-2"
                              style=" background-color: var(--text-color); border-radius: 14%; padding: 1px 5px; font-weight: 700;
                              line-height: 21.25px; font-size: 12px; color: var(--primary-color-text); text-align: center; margin-right: 6px;"
                            >
                              <strong class="p-m-auto">
                              {{reward.c | number}}</strong>
                            </span>
                            {{reward.id.name}}
                          </li>
                          <li *ngIf="reward.lineItemType=='Item'">
                            <span
                            class="p-ml-2"
                            style="
                              background-color: var(--text-color); border-radius: 14%; padding: 1px 5px; font-weight: 700; line-height: 21.25px; font-size: 12px;
                              color: var(--primary-color-text); text-align: center; margin-right: 6px; "
                            >
                              <strong class="p-m-auto">{{reward.c | number}}</strong>
                            </span>
                            <a href="/items/{{ reward.id.id }}" target="_blank"
                              class="p-text-normal" style="color: #81c784">{{ reward.id.name }}
                            ({{reward.id.id}})</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </td>
        </tr>
    </ng-template>
  </p-table>
</div>
<ng-template #isLoadingMsg>
  Loading Steps Data...
</ng-template>
