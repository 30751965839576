import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import BatchDTO from '../../dtos/BatchDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-batch-form-v3',
  templateUrl: './batch-form-v3.component.html',
  styleUrls: ['./batch-form-v3.component.sass']
})
export class BatchFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  batchRecord: BatchDTO = new BatchDTO();
  customQuery =
  {
    autopopulate: false,
    virtuals: false,
    populate: ['items_ref', 'vendor_ref'],
    populateMinimal: true
  }
  constructor()
  {
    super();
  }

  /**
   * Batch Form V3 Initial Implementation
   */
  ngOnInit()
  {
    console.log('BatchFormV3Component ngOnInit', this.batchRecord.items_ref);
    this.fields =
    [
      {
        title: "Batch Data",
        fields:
        [

          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: true
          },
          {
            name: "Batch Numbers",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'batchNumber',
            label: 'Batch Number',
            isRequired: true,
          },
          {
            name: "Vendor",
            inputType: DynamicInputType.EmbeddedForm,
            key: 'vendor_ref',
            label: 'Vendor',
            optionLabel: 'name',
            optionValue: '_id',
            filter: true,
            filterBy: 'name',
            options: {
              fieldName: 'vendor_ref',
              apiController: 'vendors',
              minimal: false,
              autopopulate: true,
              virtuals: false,
              sort: { name: 1 },
              select: '-createdAt -updatedAt -createdBy',
              viewRoute: 'vendors',
              formName: 'Vendor'
            },
            embeddedFormFields: [
              {
                title: "Vendor Data",
                fields:
                [
                  { 
                    name: "Name", 
                    inputType: DynamicInputType.BaseInputField,
                    inputTypeFields: [InputFieldType.TextInput], 
                    clearField: true, 
                    key: 'name', 
                    isRequired: true, 
                    label: 'Name', 
                    disabled: false 
                  },
                ],
                type: DynamicCardType.Card
              },
            ]
          },
          {
            name: "Vendor",
            inputType: DynamicInputType.EmbeddedForm,
            key: 'vendor_ref',
            label: 'Vendor',
            optionLabel: 'name',
            optionValue: '_id',
            filter: true,
            filterBy: 'name',
            options: {
              fieldName: 'vendor_ref',
              apiController: 'vendors',
              minimal: false,
              autopopulate: true,
              virtuals: false,
              sort: { name: 1 },
              select: '-createdAt -updatedAt -createdBy',
              viewRoute: 'vendors',
              formName: 'Vendor'
            },
            embeddedFormFields: [
              {
                title: "Vendor Data",
                fields:
                [
                  { 
                    name: "Name", 
                    inputType: DynamicInputType.BaseInputField,
                    inputTypeFields: [InputFieldType.TextInput], 
                    clearField: true, 
                    key: 'name', 
                    isRequired: true, 
                    label: 'Name', 
                    disabled: false 
                  },
                ],
                type: DynamicCardType.Card
              },
            ]
          },
          {
            name: "Year",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'year',
            label: 'Year',
            filter: true,
            autoDisplayFirst: false,
            options: {
              fieldName: 'year', values: [ {value:'2021'}, {value:'2022'}, {value:'2023'}, {value:'2024'}, {value:'2025'}, {value:'2026'}, {value:'2027'}, {value:'2028'}, {value:'2030'}]
             },
            optionLabel:'value',
            optionValue:'value',
          },
          {
            name: "Content Status",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'contentStatus',
            label: 'Content Status',
            filter: true,
            autoDisplayFirst: false,
            options: { fieldName: 'contentStatus', values: [{name:'Approved'}, {name:'In Production'}, {name:'In Testing'}, {name:'On Hold'}, {name:'Scheduled'}, {name:'Test Ready'}] },
            optionLabel:'name',
            optionValue:'name',
          },
          {
            name: "Art Status",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'vendorStatus',
            label: 'Art Status',
            filter: true,
            autoDisplayFirst: false,
            options: { fieldName: 'vendorStatus', values: [{name:'Approved'}, {name:'Assigned'}, {name:'Awaiting Batch'}, {name:'Awaiting Revision'}, {name:'Sent to Vendor'}] },
            optionLabel:'name',
            optionValue:'name',
          },
          {
            name: "Asset Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'assetType',
            label: 'Asset Type',
            filter: true,
            autoDisplayFirst: false,
            options: { fieldName: 'assetType', values: [{name:'Hard Surface'}, {name:'HS Recolor'}, {name:'Organics'}, {name:'Organic Recolors'}] },
            optionLabel:'name',
            optionValue:'name',
          },
          {
            name: "Batch Status",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'batchStatus',
            label: 'Batch Status',
            filter: true,
            autoDisplayFirst: false,
            options: { fieldName: 'batchStatus', values: [{name:'Approved'}, {name:'In Production'}, {name:'In Testing'}, {name:'On Hold'}, {name:'Scheduled'}, {name:'Test Ready'}] },
            optionLabel:'name',
            optionValue:'name',
          },
          {
            name: "Items",
            inputType: DynamicInputType.BaseInputField,
            clearField: false,
            key: 'items_ref',
            inputTypeFields: [InputFieldType.TableInput],
            isRequired: true,
            label: 'Items',
            disabled: true,
            rowData: this.batchRecord.items_ref,
            entity: 'items',
            isTableInput: true,
            tableInputParams: {
              select: 'id name',
            }
          },
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Batch";
    this.viewRoute = 'batches';
    this.isLoading = false;
  }

  /**
   * Handles batch name changes
   *
   * @param event Batch data
   */
  async onNameComponentChange(event: any)
  {
    if(event.field)
    {
      if (event.field == 'batchNumber')
      {
        if (this.dynamicFormComponent.record.vendor_ref)
        {
          this.dynamicFormComponent.record.name = `${event.value} - ${
            event.options['vendor_ref'].find((vendor: any) =>
            {
              return vendor._id === this.dynamicFormComponent.record.vendor_ref._id;
            }).name
          }`;
        }
        else
        {
          this.dynamicFormComponent.record.name = `${event.value} - [vendor]`;
        }
      }
      else if (event.field == 'vendor_ref')
      {
        if (this.dynamicFormComponent.record.batchNumber)
        {
          this.dynamicFormComponent.record.name = `${this.dynamicFormComponent.record.batchNumber} - ${
            event.options['vendor_ref'].find((vendor: any) =>
            {
              return vendor?._id === event.value?._id;
            })?.name
          }`;
        }
        else
        {
          this.dynamicFormComponent.record.name = `[batchNumber] - ${
            event.options['vendor_ref'].find((vendor: any) =>
            {
              return vendor?._id === event.value?._id;
            })?.name
          }`;
        }
      }
      this.dynamicFormComponent.fields[0].fields[0].touched = true;
    }
  }
}
