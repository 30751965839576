<div
  class="p-d-flex"
  style="
    white-space: normal;
    word-wrap: break-word;
    overflow: auto;
    text-align: left;
    line-height: 1.2; /* Increased line height for more vertical spacing */
    height: auto;
    object-fit: contain;
    word-break: break-word;
    margin: 20px 2.5px; /* Added margin: 20px top/bottom, 5px left/right */
    padding: 10px 2.5px; /* Added padding: 10px top/bottom, 5px left/right */
  "
>

  <span *ngIf="params.isHtml; else notHtml" class='long-text' [innerHTML]="params.value ? params.value : '' | safeHtml"></span>
  <ng-template #notHtml>
    <div *ngIf="params.isArray">
      <ul style="list-style-type: none">
        <li *ngFor="let text of params.value">{{ text }}</li>
      </ul>
    </div>
    <div *ngIf="(params.isColorsInput || params.isColorDisplay) && params.value && params.value.length > 0" class="color-display-container" style="margin-bottom: 10px;">
      <div *ngFor="let color of params.value" class="color-info" style="margin-bottom: 5px;">
        <div class="color-circle" [ngStyle]="{'background-color': color}"></div>
        <div class="color-code">{{ color }}</div>
      </div>
    </div>

    <div *ngIf="!params.isArray && !params.isColorsInput && !params.isColorDisplay">
      <div *ngIf="!params.isPill && !params.isColorPicker && !params.isTeamColor && !params.isReference">
        {{ transformText() }}
        <div *ngIf="params.isDate && params.value" style="text-align: right;">
          <span *ngIf="!checkTimezone">
            <h5 class="p-d-inline">Local Time: </h5>
            <small>{{ params.value | date: 'medium' }}</small>
          </span>
        </div>
      </div>
      <div *ngIf="params.isTeamColor" style="margin-bottom: 10px;">
        <div *ngFor="let colorSet of params.value; let i = index" class="p-col-12" style="margin-bottom: 10px;">
          <div>
            <strong>Team {{ i + 1 }} Colors:</strong>
          </div>
          <div class="color-list p-col-12">
            <span *ngFor="let color of colorSet">
              <div class="color-display" [ngStyle]="{'background-color': color.startsWith('#') ? color : '#' + color}" style="margin-right: 5px;"></div>
              {{ color }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="params.isColorPicker && params.value" style="margin-bottom: 10px;">
        <div class="p-d-flex p-ai-center">
          <div class="color-preview-container p-mr-2">
            <div class="pi pi-star" [ngStyle]="{'font-size': '1.3em', color: '#000'}">
              <div [ngStyle]="{ 'background-color': params.value ? params.value : params.data.color}" class="color-preview"></div>
            </div>
          </div>
          {{ transformText() }}
        </div>
      </div>
      <div *ngIf="params.isColorArray && params.value" class="color-display-container" style="margin-bottom: 10px;">
        <div *ngFor="let color of params.value" class="color-info" style="margin-bottom: 5px;">
          <div class="color-circle" [ngStyle]="{'background-color': color}"></div>
          <div class="color-code">{{ color }}</div>
        </div>
      </div>
      <div *ngIf="params.isReference" style="margin-bottom: 10px;">
        <div class="block  font-bold text-center p-4  mb-3">
          <strong>Currency Name: </strong>{{ params.data.currency_record_ref ? params.data.currency_record_ref.name : 'N/A' }}
        </div>
        <div class="block  font-bold text-center p-4  mb-3">
          <strong>Currency Name Plural:</strong> {{ (params.data.currency_record_ref ? getPluralizedName(params.data.currency_record_ref.name) : "N/A")}}
        </div>
      </div>
      <div *ngIf="params.isPill" style="margin-bottom: 10px;">
        <div *ngIf="params.isSpawner" class="pill-container" style="margin-bottom: 10px;">
          {{ transformText() }}
          <ng-container>
            <div class="block  font-bold text-center p-4  mb-3">
              Required: <div class="pill">{{ required }}</div>
            </div>
            <div class="block  font-bold text-center p-4  mb-3">
              Optional: <div class="pill">{{ optional }}</div>
            </div>
            <div class="block  font-bold text-center p-4  mb-3">
              Total: <div class="pill">{{ total }}</div>
            </div>
          </ng-container>
        </div>
        <div *ngIf="!params.isSpawner && !params.isColorPicker && !params.isReference && params.isPill" class="pill">
          {{ transformText()}}
        </div>
      </div>
    </div>

  </ng-template>
</div>
