import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import LoadingScreenDTO from '../dtos/LoadingScreenDTO';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-loading-screen-form-v3',
  templateUrl: './loading-screen-form-v3.component.html',
  styleUrls: ['./loading-screen-form-v3.component.sass']
})
export class LoadingScreenFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  loadingScreenRecord: LoadingScreenDTO = new LoadingScreenDTO();
  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Loading Screen Form V3 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Loading Screen Form",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.loadingScreenRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
          },
          {
            name: "Year",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'year',
            isRequired: false,
            label: 'Year',
            filter: true,
            autoDisplayFirst: false,
            options: { fieldName: 'year', values: [{value:'2021'}, {value:'2022'}, {value:'2023'}, {value:'2024'}, {value:'2025'}, {value:'2026'}, {value:'2027'}, {value:'2028'}, {value:'2030'}] },
            optionLabel:'value',
            optionValue: 'value',
            filterBy:'value'
          },
          { 
            name: "Weight", 
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true, 
            key: 'weight', 
            isRequired: false, 
            label: 'Weight', 
            disabled: false 
          },
          {
            name: "Logo",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'logo_ref',
            label: 'Logo',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            options: { fieldName: 'logo_ref', apiController: 'miscellaneous-build', customQuery: { entityType: 3 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true },
          },
          {
            name: "Images",
            inputType: DynamicInputType.LoadingScreenImages,
            clearField: false,
            key: 'images',
            label: 'Images',
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Bundle Images and Assets",
        fields:
        [
          {
            name: "Bundle Images (IOS)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            clearField: false,
            key: 'bundleImage',
            isRequired: false,
          },
          {
            name: "Bundle Images (AND)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            clearField: false,
            key: 'bundleImageAnd',
            isRequired: false,
          },
        ],
        type: DynamicCardType.Card,
        columns: 2
      }
    ]

    this.title = "Loading Screen";
    this.viewRoute = 'loading-screens';
    this.isLoading = false;
  }
}
