import { LoggerService } from 'src/app/common/services/logger.service';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';
import { OfficeTimePipe } from 'src/app/common/pipes/officeTime.pipe';
import { EnvironmentService } from 'src/app/common/services/environment.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-text-cell-renderer',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.sass']
})
export class TextCellRendererComponent implements ICellRendererAngularComp {

  private environmentName: string;

  constructor(
    private tableCommunicationService: TableCommunicationService,
    private officeTimePipe: OfficeTimePipe,
    private utilitiesService: UtilitiesService,

  ) {
    this.environmentName = EnvironmentService.getEnvironmentName();
  }

  public colors: string[] = [];
  public params: any;
  timestamp = new Date().getTime();
  required: string = '';
  optional: string = '';
  total: string = '';

  checkTimezone: boolean = false;

  agInit(params: any): void {
    this.params = params;

    if (this.params.isColorsInput && this.params.value) {
      if (!Array.isArray(this.params.value)) {
        this.params.value = [];
      }
    } else if (this.params.value && typeof this.params.value === 'object' && (this.params.value.value || this.params.name)) {
      this.params.value = this.params.value.value || this.params.name;
    }

    if (typeof this.params.value === 'string' && this.params.isColorArray) {
      this.params.value = this.params.value.split(',').map((color: string) => color.trim()).filter((v: any, i: any, a: string[]) => a.indexOf(v) === i);
    }

    this.checkTimezone = this.utilitiesService.isUserLocalTimeZonePST();
  }

  refresh(params: any): boolean {
    return false;
  }

  transformText() {
    if (this.params.isColorsInput && Array.isArray(this.params.value)) {
      return this.params.value.join(', ');
    } else if (this.params.isBoolean) {
      if (this.params.value !== undefined && this.params.value !== null) {
        return this.params.value ? this.params.onLabel : this.params.offLabel;
      } else {
        return 'undefined';
      }
    } else if (this.params.isEnv) {
      const order = ['dev', 'qa', 'prod'];

      if(this.environmentName == 'test' || this.environmentName == 'local'){
        order.push('test');
      }

      if (!this.params.data.env) {
        return '';
      }

      let envValues: string | string[];
      if (typeof this.params.data.env === 'string') {
        envValues = this.params.data.env.split(',').map((str: string) => str.trim());
      } else if (Array.isArray(this.params.data.env)) {
        envValues = this.params.data.env;
      } else {
        return '';
      }

      return order.filter(env => envValues.includes(env)).join(',').toUpperCase();
    } else if (this.params.isSpawner && this.params.data.scene_ref && this.params.data.scene_ref.spawners){
      this.required = (this.params.data.scene_ref.spawners ? this.params.data.scene_ref.spawners.filter((obj: any) => obj.isRequired).length : null).toString()
      this.optional = (this.params.data.scene_ref.spawners ? this.params.data.scene_ref.spawners.filter((obj: any) => !obj.isRequired).length : null).toString()
      this.total = (this.params.data.scene_ref.spawners ? this.params.data.scene_ref.spawners.length : null).toString()
    } else if (this.params.isAssetType) {
      if (this.params.options && this.params.options.length > 0) {
        const assetType = this.params.options.find((item: any) => item.value === this.params.value);
        return assetType ? assetType.name : 'No data';
      }
      return 'No data';
    } else if (this.params.isObject) {
      return this.params.value.name;
    } else if (this.params.isDate) {
      return this.officeTimePipe.transform(this.params.value);
    } else {
      return this.params.value;
    }
  }

  getPluralizedName(name: string): string {
    if (!name) return 'N/A';

    // Basic pluralization rules
    if (name.endsWith('y')) {
      return name.slice(0, -1) + 'ies';
    } else {
      return name + 's';
    }
  }
}
