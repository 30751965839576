import { query } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import ChallengeTypeDTO from '../dtos/ChallengeTypeDTO';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-challenge-type-form-v3',
  templateUrl: './challenge-type-form-v3.component.html',
  styleUrls: ['./challenge-type-form-v3.component.sass']
})
export class ChallengeTypeFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  challengeTypeRecord: ChallengeTypeDTO = new ChallengeTypeDTO();
  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Challenge Type Form V3 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Challenge Type Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: "Color",
            inputType: DynamicInputType.BaseInputField,
            key: 'color',
            label: 'Color',
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: true,
            isRequired: false,
            disabled: false
          },
          {
            name: "Misc. Image Record",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'image_ref',
            label: 'Misc. Image Record',
            optionValue:'_id',
            filter: true,
            scrollHeight: '200px',
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            options: { fieldName: 'image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: 3 },isdisplayNameIDPath: true, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
          },
        ],
        type: DynamicCardType.Card
      },
    ]

    this.title = "Challenge Type";
    this.viewRoute = 'challenge-types';
    this.isLoading = false;
  }

  /**
   * Validate Challenge Type Forms
   */
  validateChallengeTypeForm(form: any)
  {
    let confirmValidationCallback = this.validation.colorValidator(form.color);
    let message = confirmValidationCallback ? 'Valid' : 'The color entered is invalid. Please enter a valid hex color.';

    return { confirmValidationCallback, message }
  }
}
