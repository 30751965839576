import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Challenge Type DTO DTO
 */
export default class ChallengeTypeDTO extends BaseEntityDto
{
    /**
     * Misc Image Record
     */
    public image_ref?: any;

    /**
     * Color Code
     */
    public color?: any;
  }
