import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/user/service/user.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { FormService } from 'src/app/services/form.service';
import { ChallengeService } from '../services/challenge.service';

@Component({
  selector: 'app-initials-dialog',
  templateUrl: './initials-dialog.component.html',
  styleUrls: ['./initials-dialog.component.sass'],
})
export class InitialsDialogComponent implements OnInit {
  @Input() currentUserData: any;
  @Input() entity: any;
  @Input() editURL: string;

  @Output() onClose = new EventEmitter<void>();

  displayInitialsDialog: boolean = false;
  userInitials: string = '';
  @Input() existingUserInitials: string | null = null;  // This should be an input, not an output

  constructor(
    private userService: UserService,
    private loggerService: LoggerService,
    private formService: FormService,
    public challengeService: ChallengeService,
  ) {}

  async ngOnInit(): Promise<void> {
    // await this.fetchInitials();  // Fetch initials on initialization
  }

  generateInitials(): string {
    return this.userInitials ||
      this.currentUserData.currentUser.firstName.substr(0, 1).toLocaleLowerCase() +
      this.currentUserData.currentUser.lastName.substr(0, 1).toLocaleLowerCase();
  }

  async autoSetArtist(entity: any, currentUserData: any) {
    if (entity && (!entity.artist_ref || !entity.artist_ref.name || entity.artist_ref.name.length === 0)) {
      entity.artist_ref = { };
      let artistName = currentUserData.currentUser.name;
      let user = await this.userService.findUser({ query: { name: artistName } });
      if (user) {
        let response = await this.challengeService.updateChallenge(entity.id, {
          artist_ref: { _id: user._id, id: user.id, name: user.name }
        });
        entity.artist_ref = response ? response.artist_ref : null;
      }
    }
  }

  async showInitialsDialog() {
    if (this.existingUserInitials) {
      // If initials exist, rename and claim immediately
      await this.renameAndClaim();
    } else {
      // Otherwise, show the dialog to set initials
      this.userInitials = this.generateInitials();
      this.displayInitialsDialog = true;
    }
  }

  async onSubmitInitials() {
    this.displayInitialsDialog = false;
    await this.updateUserInitials();
    await this.renameAndClaim();
  }

  async renameAndClaim() {
    const initials = this.existingUserInitials || this.generateInitials();
    let filename = this.entity.fileName.replace('xx', initials);
    let scene = this.entity.scene.replaceAll(this.entity.fileName, filename);
    let value = {
      id: this.entity.id,
      fileName: filename,
      scene: scene,
    };

    await this.autoSetArtist(this.entity, this.currentUserData);
    this.formService.submitForm(value, this.editURL, true).subscribe(
      (val) => {
        this.loggerService.log('updated:', val);
        location.reload();
      }
    );
  }

  async updateUserInitials() {
    const userId = this.currentUserData.currentUser.id;
    const dto = { "dto": { initials: this.userInitials } };
    await this.userService.updateUser(userId, dto);
  }

  async fetchInitials() {
    try {
      const response = await this.userService.getInitials();
      this.existingUserInitials = response && response.initials ? response.initials : null;
    } catch (error) {
      this.loggerService.error('Failed to fetch user initials', error);
      this.existingUserInitials = null;
    }
  }

  onCloseDialog() {
    this.displayInitialsDialog = false;
  }
}
