import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import SeasonPassDTO from '../../dtos/SeasonPassDTO';
import * as _ from 'lodash';

@Component({
  selector: 'season-pass-tracks-field',
  templateUrl: './season-pass-tracks-field.component.html',
  styleUrls: ['./season-pass-tracks-field.component.sass']
})
export class SeasonPassTracksFieldComponent implements OnInit {
  @Input() seasonPassRecord: SeasonPassDTO = new SeasonPassDTO();
  @Input() isFreeTrack: boolean;
  @Input() isMiniGameTrack: boolean;
  @Input() isFriendsTrack: boolean = false;
  @Input() isCoopTrack: boolean = false;
  @Output() onChange = new EventEmitter<any>();
  type = '';
  stages: number[] = [];

  constructor() {}

  ngOnInit() {
    this.stages = _.cloneDeep(this.seasonPassRecord.stageMilestone);

    if (this.isFriendsTrack) {
      this.type = 'Friends';
      if (!this.seasonPassRecord.friends) {
        this.seasonPassRecord.friends = [];
      }
    } else if (this.isCoopTrack) {
      this.type = 'Coop';
      if (!this.seasonPassRecord.coop) {
        this.seasonPassRecord.coop = [];
      }
    } else if (this.isMiniGameTrack){
      this.type = 'Mini Game';
      if (!this.seasonPassRecord.miniGamesRewards) {
        this.seasonPassRecord.miniGamesRewards = [];
      }
    }else {
      this.type = this.isFreeTrack ? 'Free' : 'Paid';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.seasonPassRecord &&
      changes.seasonPassRecord.previousValue !== changes.seasonPassRecord.currentValue
    ) {
      this.stages = _.cloneDeep(changes.seasonPassRecord.currentValue.stageMilestone);
    }
  }

  /**
   * Adds a stage to the stage milestones list.
   */
  addStage(): void {
    if (!this.seasonPassRecord.stageMilestone) {
      this.stages = [];
      this.seasonPassRecord.stageMilestone = [];
    }

    this.stages.push(1);
    this.seasonPassRecord.stageMilestone.push(1);
    if(this.isFreeTrack && !this.isFriendsTrack) {
      this.seasonPassRecord.free = [...this.seasonPassRecord.free, [{t: null, c: null, id: null, lineItemType: null}]]
    } else if (!this.isFreeTrack && !this.isFriendsTrack && !this.isCoopTrack && !this.isMiniGameTrack) {
      this.seasonPassRecord.paid = [...this.seasonPassRecord.paid, [{t: null, c: null, id: null, lineItemType: null}]]
    } else if (!this.isFreeTrack && this.isFriendsTrack && !this.isCoopTrack && !this.isMiniGameTrack) {
      this.seasonPassRecord.friends = [...this.seasonPassRecord.friends, [{t: null, c: null, id: null, lineItemType: null}]]
    } else if (this.isCoopTrack && !this.isFriendsTrack && !this.isFreeTrack && !this.isMiniGameTrack) {
      this.seasonPassRecord.coop = [...this.seasonPassRecord.coop, [{t: null, c: null, id: null, lineItemType: null}]]
    } else if (this.isMiniGameTrack) {
      this.seasonPassRecord.miniGamesRewards = [...this.seasonPassRecord.miniGamesRewards, [{t: null, c: null, id: null, lineItemType: null}]]
    }
  }

  /**
   * Removes a stage from the stage milestones list.
   *
   * @param index The index of the stage to be removed.
   */
  removeStage(index: number): void {
    this.stages.splice(index, 1);
    this.seasonPassRecord.stageMilestone.splice(index, 1);

    if (this.isFriendsTrack) {
      this.seasonPassRecord.friends.splice(index, 1);
    } else if (this.isCoopTrack) {
      this.seasonPassRecord.coop.splice(index, 1);
    } else if (this.isMiniGameTrack) {
      this.seasonPassRecord.miniGamesRewards.splice(index, 1);
    } else {
      const trackArray = this.isFreeTrack ? this.seasonPassRecord.free : this.seasonPassRecord.paid;
      trackArray.splice(index, 1);
    }
  }

  /**
   * Handles the change of a record field.
   *
   * @param field The field that changed.
   */
  onRecordChange(field: string) {
    if(this.seasonPassRecord.type == 8){
      this.seasonPassRecord.stageMilestone = Array.from({ length: this.seasonPassRecord.miniGamesRewards.length }, (_, i) => i + 1);
    }
    this.onChange.emit({ field, record: this.seasonPassRecord });
  }

  /**
   * Moves an item within an array to a new index.
   *
   * @param array The array in which the item is moved.
   * @param currentIndex The current index of the item.
   * @param newIndex The new index to which the item is moved.
   * @param type The type of the item being moved.
   */
  moveItemInArray(array: any[], currentIndex: number, newIndex: number, type: string): void {
    if (currentIndex < 0 || currentIndex >= array.length || newIndex < 0 || newIndex >= array.length) {
      throw new Error('Invalid index provided.');
    }

    const item = array[currentIndex];
    array.splice(currentIndex, 1); // Remove the item from the current index
    array.splice(newIndex, 0, item); // Insert the item at the new index

    this.onRecordChange(type);
  }

  /**
   * Creates an array of numbers within the specified range.
   *
   * @param start The starting number of the range.
   * @param end The ending number of the range.
   * @returns An array of numbers within the specified range.
   */
  createNumberRange(start: number, end: number): number[] {
    const result: number[] = [];

    for (let i = start; i <= end; i++) {
      result.push(i);
    }

    return result;
  }

  /**
   * Assigns stages to the season pass record.
   *
   * @param stages The stages to be assigned.
   */
  assignStages(stages: any): void {
    this.onChange.emit({ field: 'free', record: this.seasonPassRecord });
  }
}
