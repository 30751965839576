<div class="p-grid p-mx-5" style="margin: -20px auto" *ngIf="!isLoading">
    <!-- 'New Paid Track' - Right column -->

    <div class="p-col-6 p-pl-4">
        <div style="text-align: center;">
            <div style="text-align: center;">
                <button
                    pButton
                    pRipple
                    type="submit"
                    label="Add Stage"
                    class="p-mb-4"
                    [style]="{ 'min-width': '18.5em' }"
                    icon="pi pi-plus" iconPos="right"
                    (click)="pushNewStage($event)"
                ></button>
            </div>
        </div>
    </div>
<div class="p-col-12" *ngFor="let stage of stages; let i = index">
    <div class="p-inputgroup">
    <h3>Stage {{i+1}}</h3>
    <p-button
            ariaLabel="Clear Field"
            icon="pi pi-minus"
            [ngStyle]="{'margin-top': '12px'}"
            styleClass="p-button-text p-button-danger"
            (click)="onRecordChange($event); stages.splice(i, 1)"
    ></p-button>
    </div>
    <p-divider class="p-mb-2"></p-divider>
    <div class="p-my-4 p-inputgroup" style="width: 100%;">
        <span ngClass="p-float-label">
            <input
                [(ngModel)]="stage.name"
                [ngModelOptions]="{ standalone: true }"
                pInputText
                (change)="onRecordChange($event)"
                class="field-item"
            />
            <label for="name"> Name <strong style="color: crimson">*</strong></label>
        </span>
        <p-button
            *ngIf="stage.name"
            ariaLabel="Clear Field"
            icon="pi pi-minus"
            styleClass="p-button-text p-button-danger"
            (click)="onRecordChange($event); stage.name = ''"
        ></p-button>
    </div>
    <div class="p-my-4 p-inputgroup" style="width: 100%;">
        <span ngClass="p-float-label">
            <input
                [(ngModel)]="stage.transitionVideo"
                [ngModelOptions]="{ standalone: true }"
                pInputText
                (change)="onRecordChange($event)"
                class="field-item"
            />
            <label for="name"> Transition Video <strong style="color: crimson">*</strong></label>
        </span>
        <p-button
            *ngIf="stage.transitionVideo"
            ariaLabel="Clear Field"
            icon="pi pi-minus"
            styleClass="p-button-text p-button-danger"
            (click)="onRecordChange($event); stage.transitionVideo = ''"
        ></p-button>
    </div>
    <div class="p-my-4 p-inputgroup" style="width: 100%;">
        <span ngClass="p-float-label">
            <input
                [(ngModel)]="stage.stageVideo"
                [ngModelOptions]="{ standalone: true }"
                pInputText
                (change)="onRecordChange($event)"
                class="field-item"
            />
            <label for="name"> Stage Video <strong style="color: crimson">*</strong></label>
        </span>
        <p-button
            *ngIf="stage.stageVideo"
            ariaLabel="Clear Field"
            icon="pi pi-minus"
            styleClass="p-button-text p-button-danger"
            (click)="onRecordChange($event); stage.stageVideo = ''"
        ></p-button>
    </div>
    <div class="p-my-4 p-inputgroup" style="width: 100%;">
        <span ngClass="p-float-label">
            <p-dropdown
                id="misc-record"
                [(ngModel)]="stage.image_ref"
                [options]="miscImageOptions"
                optionValue="_id"
                [filter]="true"
                [filterBy]="'name,id,path'"
                (onChange)="onRecordChange($event)"
                [autoDisplayFirst]="false"
                >
                    <ng-container *ngFor="let template of ['item','selectedItem']">
                        <ng-template [pTemplate]="template" let-option>
                            ({{option.id}}) <strong>Name:</strong> {{option.name}} | <strong>Path: </strong> {{option.path}}
                        </ng-template>
                    </ng-container>
            </p-dropdown>
            <label for="misc-record"> Banner Image </label>
        </span>
        <p-button
            *ngIf="stage.image_ref"
            ariaLabel="Clear Field"
            icon="pi pi-minus"
            styleClass="p-button-text p-button-danger"
            (click)="onRecordChange($event); stage.image_ref = null"
        ></p-button>
    </div>
    <div style="width: 100%;">
        <span>
            <prizes-card-form-group
                [(prize)]="stage.costs_ref"
                [showPrize]="true"
                [fieldsetName]="'Costs'"
                (onPrizeChange)="onRecordChange($event); stage.costs_ref = $event;"
            >
            </prizes-card-form-group>
        </span>
    </div>
    <p-divider></p-divider>
</div>
</div>