<div class="p-col-12">
    <h3>Toggle Climate Icons</h3>
    <p-inputSwitch class="p-my-5" [(ngModel)]="categoryClimateIconRecord.toggleIcons"></p-inputSwitch>
  </div>
  <ng-container *ngFor="let icon of categoryClimateIconRecord.climateIcons">
      <div class="p-col-12 p-pr-4" *ngIf="categoryClimateIconRecord.toggleIcons">
          <h5>{{icon.name}}</h5>
          <!-- Start -->
          <div class="p-mt-5 p-inputgroup">
              <span ngClass="p-float-label">
          <p-dropdown
            [(ngModel)]="icon.selected"
            (onChange)="onRecordChange('climateIcons', $event)"
            [options]="options['image_ref']"
            optionValue="_id"
            [showClear]="true"
            [filter]="true"
            filterBy="id,name,path"
            [autoDisplayFirst]="false"
            [style]="{ 'min-width': '30em' }"
          >
            <ng-template let-option pTemplate="selectedItem">
              <div>
                ({{ option.id }})
                <strong>Name:</strong> {{ option.name }}
                <strong>Path:</strong> {{ option.path }}
              </div>
            </ng-template>
            <ng-template let-option pTemplate="item">
              <div>
                ({{ option.id }})
                <strong>Name:</strong> {{ option.name }}
                <strong>Path:</strong> {{ option.path }}
              </div>
            </ng-template>
          </p-dropdown>
          <label for="image_ref">Selected Icon</label>
        </span>
      </div>
              <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                      <p-dropdown
                        [(ngModel)]="icon.deselected"
                        (onChange)="onRecordChange('climateIcons', $event)"
                        [options]="options['image_ref']"
                        optionLabel="name"
                        optionValue="_id"
                        [showClear]="true"
                        [filter]="true"
                        filterBy="id,name,path"
                        [autoDisplayFirst]="false"
                        [style]="{ 'min-width': '30em' }"
                      >
                        <ng-template let-option pTemplate="selectedItem">
                          <div>
                            ({{ option.id }})
                            <strong>Name:</strong> {{ option.name }}
                            <strong>Path:</strong> {{ option.path }}
                          </div>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                          <div>
                            ({{ option.id }})
                            <strong>Name:</strong> {{ option.name }}
                            <strong>Path:</strong> {{ option.path }}
                          </div>
                        </ng-template>
                      </p-dropdown>
                      <label for="image_ref">Deselected Icon</label>
                    </span>
              </div>
              <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                      <p-dropdown
                        [(ngModel)]="icon.disabled"
                        (onChange)="onRecordChange('climateIcons', $event)"
                        [options]="options['image_ref']"
                        optionLabel="name"
                        optionValue="_id"
                        [showClear]="true"
                        [filter]="true"
                        filterBy="id,name,path"
                        [autoDisplayFirst]="false"
                        [style]="{ 'min-width': '30em' }"
                      >
                        <ng-template let-option pTemplate="selectedItem">
                          <div>
                            ({{ option.id }})
                            <strong>Name:</strong> {{ option.name }}
                            <strong>Path:</strong> {{ option.path }}
                          </div>
                        </ng-template>
                        <ng-template let-option pTemplate="item">
                          <div>
                            ({{ option.id }})
                            <strong>Name:</strong> {{ option.name }}
                            <strong>Path:</strong> {{ option.path }}
                          </div>
                        </ng-template>
                      </p-dropdown>
                      <label for="image_ref">Disabled Icon</label>
                    </span>
              </div>
      </div>