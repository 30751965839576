<p-dialog
  [(visible)]="showImgDialog"
  [dismissableMask]="true"
  [modal]="true"
  [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
>
  <ng-template pTemplate="header">
    <h3 class="p-m-0">{{ imgData.title }}</h3>
  </ng-template>

  <div class="p-text-center">
    <img [src]="imgData.path" width="90%" />
    <h4 class="p-mt-0" width="90%" style="overflow-wrap: anywhere">
      {{ imgData.name }}
      <button
        pButton
        type="button"
        icon="pi pi-copy"
        class="p-button-text p-ml-3 p-mt-1"
        (click)="itemService.copyToClipboard(imgData.path)"
      ></button>
    </h4>
  </div>
</p-dialog>
