import { ToggleButtonModule } from 'primeng/togglebutton';
import { Dropdown } from 'primeng/dropdown';
export enum InputFieldType {
  SpaceCol = 'SpaceCol',
  Text = 'Text',
  TextInput = 'TextInput',
  MultiSelect = 'MultiSelect',
  Dropdown = 'Dropdown',
  InputTextArea = 'InputTextArea',
  Editor = 'Editor',
  Calendar = 'Calendar',
  ColorPicker = 'ColorPicker',
  ClearField = 'ClearField',
  Number = 'Number',
  Divider = 'Divider',
  Button = 'Button',
  BatchForm = 'BatchForm',
  Chip = 'Chip',
  CheckBox = 'CheckBox',
  TableInput = 'TableInput',
  Checkboxv2 = "Checkboxv2",
  AutoComplete = "AutoComplete",
  ToggleButton = "ToggleButton",
  ListBox = "ListBox",
  queryDropdown = "queryDropdown",
  InputSwitch = "InputSwitch",
}
