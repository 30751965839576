import { AuthGuard } from "../auth/auth.guard";
import { AutomationLogTableAgGridComponent } from "../cms-v2/entities/automation-log/components/automation-log-table-ag-grid/automation-log-table-ag-grid.component";
import { AutomationLogViewV2Component } from "../cms-v2/entities/automation-log/components/automation-log-view-v2/automation-log-view-v2.component";

export const automationLogRoutes = {
  prefix: 'automations-logs',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: AutomationLogTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: AutomationLogViewV2Component,
        canActivate: [AuthGuard],
      }
    ]
  }
};
