/**
 * Permissions DTO
 */
export default class PermissionDto 
{
    /**
     * Name of the permission
     */
    name: string;
    /**
     * Entity Tied to permission
     */
    entity: string;
    /**
     * List of abilities a user can have
     */
    abilities: Array<string> = [];
    /**
     * Mongo object Id
     */
    _id?: any;
    /**
      * Is of the permission
      */
    id?: any;
}