import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { GettersService } from 'src/app/common/services/getters.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-loading-screen-table-ag-grid',
  templateUrl: './loading-screen-table-ag-grid.component.html',
  styleUrls: ['./loading-screen-table-ag-grid.component.sass']
})
export class LoadingScreenTableAgGridComponent implements OnInit {

  entity: string = 'loading-screens';
  columnDefs: ColDef[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {}
  autopopulateSelect: string = '';
  options: any = {};
  assetTypeSettings: any[] = [];
  entityTypeSettings: any[] = [];
  public bulkUpdateColDefs: any[] = [];

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
    private gettersService: GettersService
  ) { }

  async ngOnInit(): Promise<void> {

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [

      ...defaultColumns,
      {
        headerName: 'Logo',
        field: 'logo_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'logo_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,
        autoHeight: true,
      },
      {
        headerName: 'Images',
        field: 'images_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          isLoadingScreen: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'images_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          populate: true,
          select: 'id name _id path'
        },
        width: 800,

        autoHeight: true,
      },
      {
        headerName: 'Weight',
        field: 'weight',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Year',
        field: 'year',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            '2021', '2022', '2023','2024','2025',
          ],
          addEmptyFilters: true
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Bundle Asset (IOS)',
        field: 'bundleAsset',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        cellEditorPopup: true,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        cellEditorPopup: true,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Asset (AND)',
        field: 'bundleAssetAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        cellEditorPopup: true,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        cellEditorPopup: true,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
    ];
    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

  }

}
