import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import NurtureStageDTO from '../dtos/NurtureStageDTO';
import { BuildType } from 'src/app/enums/build-type';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-nurture-stage-view',
  templateUrl: './nurture-stage-view.component.html',
  styleUrls: ['./nurture-stage-view.component.sass']
})
export class NurtureStageViewComponent extends BaseDynamicViewComponent implements OnInit {

  nurtureStageRecord: NurtureStageDTO = new NurtureStageDTO();
  constructor() {
    super();
  }

  miscAssetKeys: Array<any> = ['image_ref', 'stageVideo_ref', 'transitionVideo_ref']

  ngOnInit(){
    {
      this.fields =
      [
        {
          title: "Nurture Stage Data",
          fields:
          [
            {
              name: "Display Name",
              inputType: DynamicViewType.BaseViewField , 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'displayName',
              isRequired: true,
              label: 'Display Name',
              columnWidth: 6,
            },
            {
              name: "Start Date",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'start',
              label: 'Start Date',
              columnWidth: 6
            },
            {
              name: "Type",
              inputType: DynamicViewType.BaseViewField , 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'type',
              isRequired: false,
              label: 'Type',
              columnWidth: 6,
            },
            {
              name: "End Date",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'end',
              label: 'End Date',
              columnWidth: 6
            },
          ],
          type: DynamicCardType.Card
        },
        {
          title: "Stage Videos",
          fields:
          [
            {
              name: "Stage Video Build Data",
              inputType: DynamicViewType.MiscImageBuildComponent,
              clearField: true,
              key: 'stageVideo_ref',
              label: 'Stage Video Build Data',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: true,
              options: {
                buildAndRender: false,
                fieldName: 'stageVideo_ref', apiController: 'miscellaneous-build',
                collapsed: true,
                type: 'ref',
              },
              columnWidth: 12
            },
            {
              name: "Transition Video Build Data",
              inputType: DynamicViewType.MiscImageBuildComponent,
              clearField: true,
              key: 'transitionVideo_ref',
              label: 'Transition Video Build Data',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: true,
              options: {
                buildAndRender: false,
                fieldName: 'transitionVideo_ref', apiController: 'miscellaneous-build',
                collapsed: true,
                type: 'ref',
              },
              columnWidth: 12
            },
          ],
          type: DynamicCardType.Card
        },
        {
          title: 'Image Build Data',
          type: DynamicCardType.Card,
          fields: [
            {
              name: "Banner Image",
              inputType: DynamicViewType.MiscImageBuildComponent,
              clearField: true,
              key: 'image_ref',
              label: 'Banner Image',
              optionValue:'_id',
              filter: false,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: {
                buildAndRender: false,
                fieldName: 'image_ref', apiController: 'miscellaneous-build',
                collapsed: true,
                type: 'ref',
              },
              columnWidth: 12
            },
          ]
        },
      ]

      this.sidebarFields = [
        {
          title: "Video Preview",
          fields:
          [
            { name: "Transition Video Preview",
            inputType: DynamicViewType.VideoPreview,
            key: 'transitionVideo_ref',
            label: 'Transition Video Preview',
            columnWidth: 12
            },
            { name: "Stage Video Preview",
              inputType: DynamicViewType.VideoPreview,
              key: 'stageVideo_ref',
              label: 'Stage Video Preview',
              columnWidth: 12
            },
            { name: "Banner Image Preview",
              inputType: DynamicViewType.sideThumbnail,
              key: 'image_ref',
              label: 'Banner Image Preview',
              columnWidth: 12
            },
          ],
          type: DynamicCardType.Card
        },
      ]

      this.title = "Nurture Stage";
      this.viewRoute = 'nurture-stage';
      this.isLoading = false;
      
    }
  }
}
