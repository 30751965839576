import { Component, Input, OnInit, ViewChild, ElementRef, Renderer2, ComponentFactoryResolver, ViewContainerRef, NgZone, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LicenseManager, ColDef, GridApi, ColumnApi, GridOptions, IsRowSelectable, IServerSideDatasource, StatusPanelDef, ColGroupDef, EditableCallbackParams, CellClassParams } from 'ag-grid-enterprise';
import { TableService } from 'src/app/services/table.service';
import { ThemeService } from 'src/app/services/theme.service';
import { TableCommunicationService } from './services/table-communication.service';
import { AgGridUserSettings } from './services/ag-grid-user-settings.service';
import { AgGridFiltersService } from './services/ag-grid-filters.service';
import { AgGridToolsService } from './services/ag-grid-tools.service';
import { AgGridEditService } from './services/ag-grid-edit.service';
import { Subscription, Subject, from } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { ColumnPresetsComponent } from './sidebar-components/column-presets/column-presets.component';
import { MainStatusPanelComponent } from './ag-grid-status-panels/main-panel/main-status-panel.component';
import { CmsFiltersComponent } from './sidebar-components/cms-filters/cms-filters.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserContextService } from '../services/user-context.service';
import { UtilitiesService } from '../services/utilities.service';
import { GettersService } from 'src/app/common/services/getters.service';
import * as LZString from 'lz-string';
import { Title } from '@angular/platform-browser';
import { GridViewComponent } from '../components/grid-view/grid-view.component';
import { environment } from 'src/environments/environment';
import { DialogService } from './services/ag-grid-dialog.service';
import { GridViewSettingsComponent } from './sidebar-components/grid-view-settings/grid-view-settings.component';
import { DupDialogComponent } from '../components/dup-dialog/dup-dialog.component';
import { TABLE_ACTIONS } from './constants';
const _ = require('lodash');

@Component({
  selector: 'app-ag-grid-table',
  templateUrl: './ag-grid-table.component.html',
  styleUrls: ['./ag-grid-table.component.css'],
  providers: [ConfirmationService, MessageService],
})
export class AgGridTableComponent implements OnInit {


  // AGGRID License manager:
  licence: any = LicenseManager.setLicenseKey(environment.agGridLicense)

  @Input() entity: string;
  tableHeight: string = 'auto';
  @Input() customHeight: string | null = null;
  @Input() viewRoute: string;
  @Input() columnDefs: ColDef[] = [];
  public defaultColDef: ColDef = {
    filterParams: {
      suppressAndOrCondition: true
    },
    cellClass: 'center-cell-content'
  }
  @Input() bulkUpdateColDefs: any[] = [];
  @Input() columnSelection: string;
  @Input() rowSelection: string = 'multiple';
  @Input() autopopulateSelect: string = '';
  @Input() buildParams: any = {};
  @Input() tableParams: any = {};
  @Input() isStoreEntity: boolean = false;
  @Input() isViewEmbedded: boolean = false;
  @Input() nestedPopulate: boolean = false;
  @Input() isTableInput: boolean = false;
  @Input() tableInputParams: any = null;
  @Input() itemsRef: any[];
  populateFields: any;
  rowData: any[];
  loadingGrid: boolean = false;
  @Input() parentViewName: any;

  dialogVisible: boolean = false;
  dialogData: { header: string; content: string; footerLabel: string, ids?: any } | null = null;


  @Output() selectionChange = new EventEmitter<any[]>();
  displayDialog: boolean = false;
  selectedRows: any[];

  TABLE_ACTIONS = TABLE_ACTIONS
  filterCounter: any;
  sortCounter: any;

  // USER
  public userParams: any = {
    user: {}
  };

  // EDIT MODE VARIABLES
  @Input() fullEditModeEnabled: boolean = true;
  public isFullEditMode: boolean = false;
  public activeRowEdit: boolean = false;
  public isLoading: boolean = true;

  public rows: any[] = [];
  public gridOptions: GridOptions;
  public changedRows: Map<number, any> = new Map();  // Keeps track of changed rows
  public originalData: Map<string, any> = new Map(); // Keeps track of original vlaues for changes rows


  public api: GridApi;
  public columnApi: ColumnApi;

  private saveColumnStateTimeout: any;
  private userData: any = {};

  public gridTheme: string; // Default to the light theme
  public themeSubscription: Subscription;
  private editRowSubscription: Subscription;
  private cancelEditRowSubscription: Subscription;
  private submitSaveRowSubscription: Subscription;
  private fullEditModeSubscription: Subscription;
  private pageDataSubscription: Subscription;

  public timestamp = new Date().getTime();
  public dialogImageUrl: string | null = null;
  public dialogImageTitle: string | null = null;

  public areRowsSelected: boolean = false;
  public rowsSelected: any[] = [];
  public loadedRowCount: number = 0;
  public totalRowCount: number = 0;
  public tableTotalRecords: number = 0;
  private previousFilterModel: any = null;
  public isSelectAll: boolean = false;
  public mongoQuery: any = {};
  public mongoSort: any = {};
  // column selection
  public lastQueryMatchingIds: Set<number> = new Set<number>();
  public selectedRowsSet: Set<number> = new Set<number>();

  public globalSearchTerm: string = '';
  private searchSubject = new Subject<string>();
  public globalSearchFilter: string = '';

  // custom query buttons
  public showArchived: boolean = false; // false means don't show, true means show
  public hideActions: boolean = false

  // MISC Asset Dialog
  public displayAssetDialog: boolean = false;
  public displayStringDialog: boolean = false;
  public entityTypeSettings: Array<any> = [];
  public entityTypeOptions: Array<any> = [];
  public imagerySettings: Array<any> = [];
  public entityTypesLoaded: boolean = false;
  public imagerySettingsLoaded: boolean = false;
  public miscAssetEntity: any = {
    id: null,
    name: '',
    entityType: null,
    assetType: null,
    path: '',
  };

  // grid view
  private isGridViewVisible: boolean = true;
  private componentRef: any; // Holds reference to the dummy element
  private dialogSubscription: Subscription;
  private currentPage: number = 0;

  get urlNewForm(): string {
    return `/${this.entity}/add`
  }

  // To disable table's actions
  public disabledTableActions: Array<TABLE_ACTIONS> = [];

  // EDIT
  public editingRows: Set<number> = new Set();
  public columnTypes: {
    [key: string]: ColDef;
  } = {
      editableColumn: {
        editable: (params: EditableCallbackParams<any>) => {
          return this.isCellEditable(params);
        },
        cellClass: (params: any) => {
          if (this.isFullEditMode || this.editingRows.has(params.node.rowIndex)) {
            return 'center-cell-content editing-cell';
          } else {
            return 'center-cell-content';
          }
        }
      },
    };

  // tool panels management
  isColumnsPanelVisible = false;
  isColumnPresetsPanelVisible = false;
  isFiltersPanelVisible = false;
  isSavedFiltersPanelVisible = false;
  isGridViewSettingsPanelVisible = false;

  columnState: any;

  currentSortModel: any = {};
  currentFilterModelVars: any = {};

  @Input() isPrizeTable: boolean = false;
  @Input() prizeCounts: { [key: string]: number } = {};

  @ViewChild(DupDialogComponent) dupDialogComponent!: DupDialogComponent;


  constructor(
    private tableService: TableService,
    private tableCommunicationService: TableCommunicationService,
    private themeService: ThemeService,
    private agGridUserSettings: AgGridUserSettings,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private agGridFiltersService: AgGridFiltersService,
    private agGridToolsService: AgGridToolsService,
    private agGridEditService: AgGridEditService,
    private confirmationService: ConfirmationService,
    private userContextService: UserContextService,
    private utilitiesService: UtilitiesService,
    private gettersService: GettersService,
    private titleService: Title,
    private renderer: Renderer2,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private zone: NgZone,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private messageService: MessageService,
  ) {
    //
    this.tableCommunicationService.event$.subscribe(event => {
      if (event?.type === 'showImage') {
        this.dialogImageUrl = event.imageUrl;
        this.dialogImageTitle = event.imageTitle
      }
      // Handle other event types as needed in the future
    });
    this.dialogSubscription = this.dialogService.dialogState.subscribe(state => {
      this.dialogImageUrl = state.imageUrl;
      this.dialogImageTitle = state.title;
      // Logic to show/hide dialog based on state.visible
    });
    this.pageDataSubscription = this.tableCommunicationService.requestCurrentPageData$.subscribe(() => {
      this.updateCurrentPageData();
    });
    this.tableCommunicationService.duplicateRow$.subscribe(event => {
      this.showDuplicateDialog(event);
    });

    this.tableCommunicationService.deleteRow$.subscribe(event => {
      this.handleRowDeletion(event);
    });
  }

  async ngOnInit(): Promise<void> {
    console.log(`Custom height input received in app-ag-grid-table: ${this.customHeight}`);
    this.tableHeight = this.customHeight && this.customHeight !== 'null' ? this.customHeight : '70vh';
    console.log(`Final table height used: ${this.tableHeight}`);
    this.isLoading = true;
    if (this.isViewEmbedded) {
      this.isColumnsPanelVisible = true;
    }
    this.columnDefs = this.agGridToolsService.preprocessColumnDefs(this.columnDefs);
    this.selectedRowsSet.clear();

    if (!this.isViewEmbedded && !this.isTableInput) {
      let title = this.parse(this.entity) + ' Table';
      this.titleService.setTitle(title);
    }

    this.getUserData();

    this.gridOptions = {
      rowSelection: 'multiple',
      rowModelType: 'serverSide',
      // debug: true,
      serverSideDatasource: this.createServerSideDatasource(this.tableParams.isMultiple),
      // pagination
      pagination: true,
      paginationPageSize: 50,
      cacheBlockSize: 50,
      maxBlocksInCache: 2,
      suppressPaginationPanel: true,
      blockLoadDebounceMillis: 100,
      // sorting
      alwaysMultiSort: true,
      suppressContextMenu: true,
      ensureDomOrder: true,
      enableCellTextSelection: true,
      sideBar: {
        hiddenByDefault: !this.isColumnsPanelVisible,
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivotMode: true
            }
          },
          {
            id: 'columnPresets',
            labelDefault: 'Column Presets',
            labelKey: 'columnPresets',
            iconKey: 'columns',
            toolPanel: ColumnPresetsComponent,
            toolPanelParams: {
              entity: this.entity,
            }
          },
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
          },
          {
            id: 'cmsFilters',
            labelDefault: 'Saved Filters',
            labelKey: 'cmsFilters',
            iconKey: 'filter',
            toolPanel: CmsFiltersComponent,
            toolPanelParams: {
              entity: this.entity,
            }
          },
          {
            id: 'gridViewSettings',
            labelDefault: 'Grid View Settings',
            labelKey: 'gridViewSettings',
            iconKey: 'table',
            toolPanel: GridViewSettingsComponent,
            width: 300,
            toolPanelParams: {
              entity: this.entity,
              fields: this.columnDefs.map((obj: any) => ({ headerName: obj.headerName, key: obj.field }))
            }
          },
        ]
      },
      columnTypes: this.columnTypes,
      rowGroupPanelShow: "never",
      suppressRowClickSelection: true,
      singleClickEdit: false,
      stopEditingWhenCellsLoseFocus: true,
      // editType: 'fullRow',
      statusBar: {
        statusPanels: [
          {
            statusPanel: MainStatusPanelComponent, align: 'center',
            statusPanelParams: {
              entity: this.entity
            }
          }
        ],
      },
      // onFirstDataRendered: event => {
      //   console.log('onFirstDataRendered');
      // },
      onColumnVisible: this.onColumnVisible.bind(this),
      onColumnMoved: this.onColumnMoved.bind(this),
      onColumnResized: this.onColumnResized.bind(this),
      onFilterChanged: this.updateURLWithFilter.bind(this),
      onSortChanged: this.onSortChanged.bind(this),
      onCellValueChanged: this.onCellValueChanged.bind(this),
      getRowId: (params) => {
        return params.data.id;
      },
      // domLayout: 'autoHeight', // this breaks tables embedded in views
    };
    //
    this.userData = await this.authService.getSocialUser();

    this.themeSubscription = this.themeService.theme$.subscribe((theme) => {
      this.toggleTheme(theme === 'arya-green');
    });
    //
    this.searchSubject.pipe(
      debounceTime(350) // ms delay
    ).subscribe(searchTerm => {
      this.onGlobalSearch(searchTerm);
    });

    // Subscribe to the editRow$ Observable
    this.editRowSubscription = this.tableCommunicationService.editRow$.subscribe((rowIndex: number) => {
      this.editingRows.add(rowIndex);
      this.api.redrawRows();
    });

    this.cancelEditRowSubscription = this.tableCommunicationService.cancelEditRow$.subscribe((rowIndex: number) => {
      this.editingRows.delete(rowIndex);
      this.api.redrawRows();
    });

    this.submitSaveRowSubscription = this.tableCommunicationService.submitSaveRow$.subscribe((rowIndex: number) => {
      this.editingRows.delete(rowIndex);
      this.api.redrawRows();
    });

    this.fullEditModeSubscription = this.tableCommunicationService.fullEditMode$.subscribe(() => {
      this.toggleFullEditMode();
    });

    // MISC. asset popup form
    if (this.tableParams.customButtons && this.tableParams.customButtons.includes('addAsset')) {
      this.getEntityTypeSettings();
      this.getImagerySettings();
    }

    this.tableCommunicationService.event$.subscribe((event: any) => {
      if (event === 'refreshGridViewSettings') {
        this.fetchRowsAndRenderGrid();
      } else if (event === 'reloadDataSource') {
        this.reloadDataSource(null);
      }
    });
    this.isLoading = false;

    this.agGridToolsService.disabledTableActions$.subscribe(value => {
      this.disabledTableActions = value;
    });
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    this.editRowSubscription.unsubscribe();
    this.cancelEditRowSubscription.unsubscribe();
    this.submitSaveRowSubscription.unsubscribe();
    this.fullEditModeSubscription.unsubscribe();
    this.dialogSubscription.unsubscribe();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onGridReady(params: { api: GridApi; columnApi: ColumnApi }) {
    console.log('Grid Ready')
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.applyState();
    // Apply any filter from the URL when the grid is ready
    this.applyFilterFromURL();
    // After the grid is ready, set the custom filter layout
    this.setCustomFilterLayout();
  }

  async onPaginationChanged(event: any) {
    if (!this.api) return;

    let newPage = this.api.paginationGetCurrentPage();

    if (newPage < this.currentPage && !this.isGridViewVisible) {
      this.fetchRowsAndRenderGrid();
    }
    this.currentPage = newPage;
  }

  onColumnVisible(event: any) {
    // Capture and save the new column visibility state
    if (event.source !== "api") {
      this.debounceSaveColumnState();
    }
  }

  onColumnMoved(event: any) {
    // Capture and save the new column order
    if (event.source !== "api") {
      this.debounceSaveColumnState();
      this.setCustomFilterLayout();
    }
  }

  onColumnResized(event: any) {
    // Capture and save the new column order
    if (event.source !== "api") {
      this.debounceSaveColumnState();
    }
  }

  onSortChanged(event: any) {
    // Capture and save the new column order
    if (event.source !== "api") {
      const newColumnState = this.columnApi.getColumnState();
      const sortedColumns = newColumnState.filter(column => column.sort !== null && column.colId !== 'id');
      console.log('sortedColumns: ', sortedColumns);
      this.sortCounter = sortedColumns ? sortedColumns.length : null;
      if (sortedColumns.length > 3) {
        // Find the last sorted column
        const lastSortedColumn = sortedColumns[sortedColumns.length - 1];
        // Set the column's sort state to 'null'
        const originalColumn = this.columnState.find((column: { colId: string; }) => column.colId === lastSortedColumn.colId);
        if (originalColumn) {
          originalColumn.sort = null;
          this.columnApi.applyColumnState({
            state: this.columnState,
            applyOrder: true,
          });
          this.messageService.add(
            {
              severity: 'warn',
              summary: 'Maximum (3) Sort Columns Reached',
              detail: 'Column cannot be sorted because the maximum has been reached.',
              life: 1000
            });
        }
      } else {
        // Save the new column state
        this.columnState = newColumnState;
        this.debounceSaveColumnState();
      }
    }
  }

  onCellValueChanged(event: any) {
    console.log('onCellValueChanged: ', event);
    const rowIndex = event.rowIndex;
    const field = event.column.getColId();
    const newValue = event.newValue;
    const colDef = event.column.getUserProvidedColDef();

    // Store the original cell value if this is the first change for the cell
    const originalCellKey = `${rowIndex}-${field}`;
    if (!this.originalData.has(originalCellKey)) {
      this.originalData.set(originalCellKey, event.oldValue);
    }

    // Initialize the map for the row if not already present
    if (!this.changedRows.has(rowIndex)) {
      this.changedRows.set(rowIndex, {});
    }

    // Update the changed value for the field
    const rowChanges = this.changedRows.get(rowIndex);
    rowChanges[field] = this.agGridEditService.convertValueForDB(field, newValue, colDef);
    this.changedRows.set(rowIndex, rowChanges);
  }

  applyState() {
    this.agGridUserSettings.getColumnState(this.entity, this.isViewEmbedded, this.parentViewName).then((response: any) => {
      // Apply the state to the grid.
      // Assuming state is an array of column states
      if (response[0] && response[0].settings) {
        this.columnState = response[0].settings;
        this.columnApi.applyColumnState({
          state: response[0].settings,
          applyOrder: true,
        });
      }
      this.setCustomFilterLayout();
    }).catch((error) => {
      console.error('Error fetching column state: ', error);
    });
  }

  debounceSaveColumnState() {
    // Clear any existing timeout to reset the waiting time
    clearTimeout(this.saveColumnStateTimeout);
    this.columnState = this.columnApi.getColumnState();
    // Set a new timeout to save the column state after 500 milliseconds (or your preferred time)
    this.saveColumnStateTimeout = setTimeout(() => {
      this.agGridUserSettings.saveColumnState(this.entity, this.columnState, this.isViewEmbedded, this.parentViewName);
    }, 800);
  }

  async onSelectionChanged(event: any) {
    console.log('onSelectionChanged');

    if (this.api) {
      const filterModel = this.api.getFilterModel();
      console.log('filterModel: ', filterModel);
      this.filterCounter = filterModel ? Object.keys(filterModel).length : null;
    }

    const source = event.source;
    const type = event.type;

    if (source == 'api') return;
    if (source == 'uiSelectAll' && type == 'selectionChanged') {
      this.isSelectAll = !this.isSelectAll;
      if (this.isSelectAll) {
        this.lastQueryMatchingIds.forEach((id) => {
          this.selectedRowsSet.add(id);
        });
        this.api.forEachNode((node) => {
          node.setSelected(true);
        });
        this.rowsSelected = this.api.getSelectedNodes();
      } else {
        this.selectedRowsSet.clear();
        this.api.forEachNode((node) => {
          node.setSelected(false);
        });
        this.rowsSelected = [];
      }

      this.areRowsSelected = this.isSelectAll && this.selectedRowsSet.size > 0;

    }
    console.log('selectedRowsSet: ', this.selectedRowsSet);
    return;
  }

  onRowSelected(event: any) {

    const source = event.source;
    const type = event.type;
    const rowData = event.data;
    const nodeData = event.node;

    if (source == 'uiSelectAll') {
      return;
    }

    console.log('onRowSelected', source, type, nodeData, this.selectedRowsSet);

    if (nodeData.selected) {
      this.selectedRowsSet.add(rowData.id);
    } else {
      this.isSelectAll = false;
      this.selectedRowsSet.delete(rowData.id);
    }

    this.areRowsSelected = this.selectedRowsSet.size > 0;
    this.rowsSelected = this.api.getSelectedNodes();
  }


  // When receiving new data (e.g., after filtering or loading), update the loaded and total row counts:
  updateRowCounts(response: any) {
    this.loadedRowCount += response.records.length; // Increment the loaded row count
    this.totalRowCount = response.paginationMeta.totalRecords; // Update the total row count
    this.tableTotalRecords = response.paginationMeta.tableTotalRecords;
    this.lastQueryMatchingIds = new Set(response.matchingIds);
    this.tableCommunicationService.setRowCount(this.totalRowCount);
  }

  resetLoadedRowCount() {
    this.loadedRowCount = 0;
  }

  onResetRows(isClear: boolean = false) {
    // Deselect all rows
    this.api.deselectAll();
    this.isSelectAll = false;
    // Update the selected row count
    if (isClear) {
      this.selectedRowsSet.clear();
    }

    this.areRowsSelected = false;
    this.rowsSelected = [];
  }


  onSearchInputChange() {
    this.searchSubject.next(this.globalSearchTerm);
  }

  onGlobalSearch(searchTerm: string) {
    // Handle the global search here
    this.globalSearchFilter = searchTerm;
    this.api.onFilterChanged(); // This will trigger the getRows method with the new filter
  }

  async setMeta(params: any) {
    // -------- PAGINATION --------
    // Set default values
    const startRow = params.request.startRow ?? 0;
    const pageSize = this.gridOptions.cacheBlockSize ?? 100;
    // Calculate the current page number based on the startRow and pageSize
    const currentPage = Math.floor(startRow / pageSize);

    // -------- SORTING --------
    // Step 1: EXTRACT SORT MODEL FROM TABLE
    const sortModel = params.request.sortModel;

    // -------- FILTERING --------
    // Step 2: EXTRACT FILTER MODEL FROM TABLE
    const filterModel = params.request.filterModel;

    // -------- POPULATE --------
    // Step 3: EXTRACT POPULATE CONFIGURATION
    // Extract all column keys from the filter model
    const columnKeys = Object.keys(filterModel);
    const filterParamsDict = columnKeys.reduce((acc: any, key: any) => {
      const colDef = params.columnApi.getColumn(key)?.getColDef();
      acc[key] = { ...filterModel[key], ...colDef?.filterParams }
      return acc;
    }, {});

    // Fetch all column definitions based on the keys
    const populate = params.columnApi.getColumns()
      .filter((key: any) => key.colDef.filterParams.populate)
      .map((key: any) => ({
        path: key.colId,
        [key.colDef.filterParams.nestedPop ? 'populate' : 'select']: key.colDef.filterParams.select ? key.colDef.filterParams.select : '_id id name',
      }));

    this.populateFields = populate;
    let tableGlobalSearchQuery = null;

    if (this.globalSearchFilter.length > 0) {
      tableGlobalSearchQuery = this.getGlobalSearchQuery(this.globalSearchFilter);
    }

    const filterModelVars = {
      filterModel: filterModel,
      filterParamsDict: filterParamsDict,
      columnKeys: columnKeys,
    }

    return {
      pageSize,
      currentPage,
      sortModel,
      filterModelVars,
      populate,
      tableGlobalSearchQuery
    }
  }


  createServerSideDatasource(isMultiple: boolean): IServerSideDatasource {
    return {
      getRows: async (params) => {
        if (isMultiple) {
          await this.handleMultipleEntities(params);
        } else {
          await this.handleSingleEntity(params);
        }
        this.api.forEachNode((node, index) => {
          if (this.selectedRowsSet.has(node.data?.id)) {
            node.setSelected(true);
          }
        });
      }
    }
  }

  async handleSingleEntity(params: any) {
    const { pageSize, currentPage, sortModel, filterModelVars, populate, tableGlobalSearchQuery } = await this.setMeta(params)

    // Check if the query has changed
    if (!_.isEqual(filterModelVars.filterModel, this.previousFilterModel)) {
      this.resetLoadedRowCount(); // Reset the loaded row count
      this.previousFilterModel = filterModelVars.filterModel; // Update the previous query
    }

    if (this.tableParams && this.tableParams.hideActions) {
      this.hideActions = this.tableParams.hideActions
    }

    let payloadStoreEnv: string | null = null;
    if (this.isStoreEntity) {
      let storeEnv: any = await this.agGridToolsService.getSelectedEnvironment()
      payloadStoreEnv = storeEnv.value
    }

    this.currentSortModel = sortModel;
    this.currentFilterModelVars = filterModelVars;

    let response = await this.tableService.getTableRowsV3({
      type: this.entity,
      // TODO What to do with autopopulateSelect ?
      autopopulate: { select: this.autopopulateSelect },
      populate: populate,
      page: currentPage,
      pageSize: pageSize,
      // TODO What to do with nestedPopulate ?
      nestedPopulate: this.nestedPopulate,
      storeEnv: payloadStoreEnv,
      sortModel: sortModel || null,
      filterModel: filterModelVars || null,
      defaultQuery: this.tableParams.defaultQuery || null,
      tableGlobalSearchQuery: tableGlobalSearchQuery || null,
    });

    this.loadingGrid = false;
    this.mongoQuery = response.query;
    this.mongoSort = response.sort;

    if (response.records) {

      // Update the loaded and total row counts
      if (!this.isGridViewVisible) {
        this.fetchRowsAndRenderGrid(response.records);
      }

      this.updateRowCounts(response);
      this.api.retryServerSideLoads();

      // Notify the grid of the results
      params.success({
        rowData: response.records, // Assuming the response contains the row data under 'records' property
        rowCount: response.paginationMeta.totalRecords // Total number of records across all pages
      });

    } else {
      // inform grid request failed
      console.error('IServerSideDatasource failed');
      params.fail();
    }
  }

  async handleMultipleEntities(params: any) {
    const { pageSize, currentPage, sortModel, filterModelVars, populate, tableGlobalSearchQuery } = await this.setMeta(params)

    // Check if the query has changed
    if (!_.isEqual(filterModelVars.filterModel, this.previousFilterModel)) {
      this.resetLoadedRowCount(); // Reset the loaded row count
      this.previousFilterModel = filterModelVars.filterModel; // Update the previous query
    }

    if (this.tableParams && this.tableParams.hideActions) {
      this.hideActions = this.tableParams.hideActions
    }

    let payloadStoreEnv: string | null = null;
    if (this.isStoreEntity) {
      let storeEnv: any = await this.agGridToolsService.getSelectedEnvironment()
      payloadStoreEnv = storeEnv.value
    }

    const entityPrefixes: { [entity: string]: string } = {};
    for (const entity of Object.keys(this.tableParams.idEntity)) {
      if (entity == 'co-op') {
        entityPrefixes[entity] = 'cp';
      } else if (entity == 'season-pass') {
        entityPrefixes[entity] = 'sp';
      }
      else {
        entityPrefixes[entity] = entity.substring(0, 2);
      }

    }

    console.log(this.tableParams)
    // Fetch rows from multiple entities based on the ID-Entity mapping
    const entityFetchPromises = Object.keys(this.tableParams.idEntity).map(async (entity) => {
      const ids = this.tableParams.idEntity[entity];

      // If ids array is empty, modify the query to fetch all records
      const query = ids.length > 0
        ? { id: { $in: ids } }
        : {}; // Empty query object to fetch all records if 'ids' is empty

      const response = await this.tableService.getTableRowsV3({
        type: entity,
        query: query,
        populate: populate,
        page: currentPage,
        pageSize: pageSize,
        sortModel: sortModel || null,
        filterModel: filterModelVars || null,
        tableGlobalSearchQuery: tableGlobalSearchQuery || null,
      });

      // Add entity prefix to each record's id
      response.records = response.records.map((record: any) => {
        const entityName = entity.replace(/[-_]/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
        const prizeCountValue = this.isPrizeTable ? (this.prizeCounts[record._id] || 'Not Available') : undefined;
        return {
          ...record,
          id: `${entityPrefixes[entity]}-${record.id}`,
          entityName: entityName,
            ...(this.isPrizeTable && { prizeCounts: prizeCountValue }), // JUST IF ITS PRIZE COUNTS 
        };
      });
      response.records.forEach((record: any) => {
      });
      
      return {
        records: response.records,
        paginationMeta: response.paginationMeta,
        response
      };
    });


    // Await all fetch promises
    const entityResponses = await Promise.all(entityFetchPromises);


    // Combine the rows from all entities
    const combinedRows = entityResponses.reduce((acc, res) => acc.concat(res.records), []);

    // Combine pagination meta information
    const combinedPaginationMeta = entityResponses.reduce((acc, res) => {
      acc.totalRecords += res.paginationMeta.totalRecords;
      acc.pageRecordsCount += res.paginationMeta.pageRecordsCount;
      acc.tableTotalRecords += res.paginationMeta.tableTotalRecords;
      // Update other pagination meta fields if necessary
      return acc;
    }, {
      totalRecords: 0,
      pageRecordsCount: 0,
      tableTotalRecords: 0,
      nextPage: null,
      prevPage: null,
      page: currentPage,
      pageSize: pageSize,
      pageCount: 0,
    });

    this.loadingGrid = false;
    if (combinedRows.length > 0) {
      const totalRecords = combinedPaginationMeta.totalRecords;
      combinedPaginationMeta.pageCount = Math.ceil(totalRecords / pageSize);

      this.updateRowCounts({ records: combinedRows, paginationMeta: { totalRecords } });
      params.success({
        rowData: combinedRows,
        rowCount: totalRecords,
        paginationMeta: combinedPaginationMeta,
      });
    } else {
      console.error('IServerSideDatasource failed');
      params.fail();
    }
  }


  /**
   * Asynchronously fetches all rows from the backend service, with options for field selection and smart population.
   *
   * This function utilizes the `tableService.getBulkActionRows` method to get all records from the backend.
   * Pagination might be necessary if the dataset is large.
   *
   * @param {string} select - Specifies which fields to include in the results, defaults to '_id id'.
   *                          It is a string with space-separated field names.
   *
   * @param {any} smartPopulate - An object containing advanced options for populating related fields,
   *                              defaults to an empty object.
   *
   * @returns {Promise<any[]>} A Promise that resolves with an array of all fetched records.
   *
   * @async
   * @example
   *
   * const data = await fetchAllRowsFromBackend('_id name', { populate: 'friends' });
   */
  async fetchAllRowsFromBackend(select: string = '_id id', selectedRowsSet: Set<number> | null = null, props: any = {}, isSelectAll: boolean = false): Promise<any[]> {
    let allData = await this.tableService.getTableRowsV3({
      type: this.entity,
      select: select,
      autopopulate: props.autopopulate || false,
      populate: props.populate || null,
      sortModel: this.currentSortModel || null,
      filterModel: this.currentFilterModelVars || null,
      defaultQuery: this.tableParams.defaultQuery || null,
      selectedRows: selectedRowsSet ? Array.from(selectedRowsSet) : null,
      page: 0,
      pageSize: isSelectAll ? 30000 : (selectedRowsSet?.size || 50),
    });

    return allData.records;
  }

  getGlobalSearchQuery(searchFilter: string): any {
    if (!searchFilter) return {};

    // Initialize search conditions with the default 'name' field
    const searchConditions: any = [{ name: { $regex: searchFilter, $options: 'i' } }];

    // Distinguish between numeric and non-numeric filters more cleanly
    if (isNaN(Number(searchFilter))) {
      // Directly map tableSearchColumns to search conditions if applicable
      if (this.tableParams?.tableSearchColumns) {
        searchConditions.push(...this.tableParams.tableSearchColumns.map((field: any) => ({
          [field]: { $regex: searchFilter, $options: 'i' }
        })));
      }
    } else {
      // Handle numeric search filter, applying it to the 'id' field
      searchConditions.push({ id: Number(searchFilter) });
    }

    return { $or: searchConditions };
  }

  parse(value: string): string {

    if (this.tableParams && this.tableParams.customName) {
      return this.tableParams.customName;
    }

    // Replace underscores with spaces
    value = value.replace(/_/g, ' ');

    // Split the string into words based on spaces and hyphens
    const words = value.split(/[-\s]/);

    // Capitalize the first letter of each word and join them back together with a space
    return words.map(word => {
      // Capitalize the first letter and concatenate with the rest of the word
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join(' ');

  }

  toggleTheme(isDarkMode: boolean) {
    this.gridTheme = isDarkMode ? 'ag-theme-alpine-dark' : 'ag-theme-alpine';
  }

  // URL filters management
  // Function to fetch table filter state, generate URL param, and apply to URL
  updateURLWithFilter() {
    const currentFilterModel = this.api.getFilterModel();
    const filterString = JSON.stringify(currentFilterModel);
    const compressedFilter = LZString.compressToEncodedURIComponent(filterString);
    this.onResetRows();
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: { filter: compressedFilter },
        queryParamsHandling: 'merge'
      });
  }

  // Function to decompress URL param and apply to table state when loading
  applyFilterFromURL() {
    this.route.queryParams.subscribe(params => {
      const compressedFilter = params['filter'];
      if (compressedFilter) {
        const decompressedFilterString = LZString.decompressFromEncodedURIComponent(compressedFilter);
        const filterModel = JSON.parse(decompressedFilterString);
        this.api.setFilterModel(filterModel);
      }
    });
  }

  // EDIT
  isCellEditable(params: any) {
    // If full edit mode is on, return true
    if (this.isFullEditMode) {
      return true;
    }
    const rowIndex = params.node.rowIndex;
    return this.editingRows.has(rowIndex);
  }


  async submitFullEdit() {
    // Get all row indices of changed rows
    const changedRowIndices = Array.from(this.changedRows.keys());

    console.log('submitFullEdit - rows:', changedRowIndices);

    // Initialize an array to store the promises
    const promises = [];
    // Loop through each row index and call submitRow
    for (const rowIndex of changedRowIndices) {
      let storeEnv: any = { value: null };
      if (this.isStoreEntity) {
        storeEnv = this.agGridToolsService.getSelectedEnvironment();
      }
      const promise = this.agGridEditService.submitRow(rowIndex, this.changedRows, this.api, this.entity, null, null, storeEnv?.value);
      promises.push(promise);
    }
    // Wait for all promises to resolve
    try {
      await Promise.all(promises);
      // All rows have been submitted successfully
      this.isFullEditMode = false;
      this.toggleFullEditMode();
    } catch (error) {
      // Handle error
      console.error("An error occurred while submitting rows:", error);
    }
  }

  revertChanges(changedRowIndices: number[]) {
    this.agGridEditService.revertChanges(this.api, this.changedRows, this.originalData, changedRowIndices);
  }

  toggleFullEditMode() {
    // If full edit mode is turned on, add all visible rows to the editingRows set
    if (this.isFullEditMode) {
      const visibleRows = this.api.getModel().getRowCount();
      for (let i = 0; i < visibleRows; i++) {
        this.editingRows.add(i);
      }
      this.api.redrawRows();
      return; // exit the function
    }

    // If toggled off and there are changed rows not submitted
    const changedRowIndices = Array.from(this.changedRows.keys());
    if (changedRowIndices.length > 0) {
      this.confirmationService.confirm({
        message: 'There are unsaved changes. Do you want to submit them?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          // If user chooses to submit
          this.submitFullEdit();
          this.editingRows.clear();
        },
        reject: () => {
          // If user chooses to discard
          // Here you can revert the changes if necessary
          this.revertChanges(changedRowIndices);
          this.editingRows.clear();
          this.api.redrawRows();
        }
      });
    } else {
      this.editingRows.clear();
      this.api.redrawRows();
    }
  }

  /**
  * Retrieves data related to current user
  */
  getUserData() {
    // get current user
    const userResult = this.authService.getSocialUser();
    this.userParams.user = userResult.currentUser;
  }

  //  Custom filters
  applyCustomFilter(filterType: string) {
    switch (filterType) {
      case 'archived':
        // Toggle the current state
        this.showArchived = !this.showArchived;

        // Prepare the filter model for the "archived" column
        const filterModel = this.showArchived ? null : {
          values: ['false'],
          filterType: 'set'
        };

        // Apply the filter to the "archived" column
        this.api.setFilterModel({
          archived: filterModel
        });

        // Refresh the rows based on the new filter
        this.api.onFilterChanged();
        break;

      // Handle other custom filters here...
      default:
        break;
    }
  }

  showAssetDialog() {
    this.displayAssetDialog = true;
  }

  showStringDialog() {
    this.displayStringDialog = true;
  }

  hideAssetDialog() {
    console.log('hideAssetDialog');
    this.displayAssetDialog = false;
  }

  hideStringDialog() {
    console.log('hideAssetDialog');
    this.displayStringDialog = false;
    this.api.redrawRows();
  }

  /**
   * Retrieves all Entity Type Settings records.
   */
  async getEntityTypeSettings() {
    let entityTypes = null;
    if (this.tableParams && this.tableParams.entityTypes) {
      entityTypes = this.tableParams.entityTypes;
    } else {
      entityTypes = null;
    }
    let response = await this.gettersService.getEntityTypeSettings(true, false, entityTypes);

    if (response && response.length > 0) {
      this.entityTypeSettings = response;
      this.entityTypeSettings.forEach((entityTypeSetting: any) => {
        // this.AllEntityTypeOptions.push({ label: entityTypeSetting.name, value: entityTypeSetting.value });
        if (!entityTypeSetting.showOnAssetBundleOnly || (this.tableParams && this.tableParams.allEntityTypes)) {
          this.entityTypeOptions.push({ label: entityTypeSetting.name, value: entityTypeSetting.value });
        }
      });

      this.entityTypeOptions.sort((a, b) => a.value - b.value);
    }
    this.entityTypesLoaded = true;
  }

  /**
   * Retrieves all Imagery Settings records.
   */

  async getImagerySettings() {
    let response = await this.gettersService.getBundleTypeSettings(true);
    if (response && response.length > 0) {
      this.imagerySettings = response;

      this.imagerySettings.forEach((imagerySetting: any) => {
        // this.AllImageryOptions.push({ label: imagerySetting.name, value: imagerySetting.value });
      }
      );
      // this.AllImageryOptions.sort((a, b) => a.value - b.value);
    }
    this.imagerySettingsLoaded = true;
  }

  reloadDataSource(event: any) {
    // Refresh the entire dataset from the server
    this.api.refreshServerSide();

    // Reset miscAssetEntity to default values
    this.resetMiscAssetEntity();

    this.hideAssetDialog();
  }

  resetMiscAssetEntity() {
    this.miscAssetEntity = {
      id: null,
      name: '',
      entityType: null,
      assetType: null,
      path: '',
    };
  }

  async syncStore() {
    let response = await this.agGridToolsService.syncStore();
    console.log(response);
    this.api.refreshServerSide();
  }

  clearFilters() {
    this.api.setFilterModel(null);
  }

  clearSorting() {
    this.columnApi.applyColumnState({
      defaultState: { sort: null }
    });
    this.sortCounter = null;
  }


  toggleGrid() {
    this.zone.run(() => {
      const agGridElement = this.renderer.selectRootElement('.ag-root.ag-unselectable', true);

      if (this.isGridViewVisible) {
        // Hide the ag-grid view
        this.renderer.setStyle(agGridElement, 'visibility', 'hidden');
        this.renderer.setStyle(agGridElement, 'opacity', '0');

        this.fetchRowsAndRenderGrid();

      } else {
        // Show the ag-grid view
        this.renderer.setStyle(agGridElement, 'visibility', 'visible');
        this.renderer.setStyle(agGridElement, 'opacity', '1');

        this.removeGridViewComponent();
      }

      this.isGridViewVisible = !this.isGridViewVisible;
    });
  }


  getCurrentPageData() {
    console.log('getCurrentPageData');

    // Get current page and page size
    let currentPage = this.api.paginationGetCurrentPage();
    let pageSize = this.api.paginationGetPageSize();

    // Calculate the starting and ending index for the current page
    let startRow = currentPage * pageSize;
    let endRow = Math.min(startRow + pageSize, this.api.paginationGetRowCount());

    // Get all the rows in the current page
    let pageData: any[] = [];
    this.api.forEachNode((node, index) => {
      if (index >= startRow && index < endRow) {
        pageData.push(node.data);
      }
    });

    console.log('currentPage', currentPage, 'pageSize', pageSize);
    console.log('pageData', pageData);

    return pageData;
  }


  async updateCurrentPageData() {
    await new Promise(resolve => setTimeout(resolve, 500));
    const data = this.getCurrentPageData();
    this.tableCommunicationService.setCurrentPageData(data);
  }

  showGridViewComponent(currentPageData: any[], agGridElement: { parentNode: { appendChild: (arg0: any) => void; }; }) {
    const factory = this.componentFactoryResolver.resolveComponentFactory(GridViewComponent);
    this.componentRef = factory.create(this.viewContainerRef.injector);
    this.componentRef.instance.rowData = currentPageData;
    this.componentRef.instance.entity = this.entity;

    // Manually trigger change detection
    this.cdr.detectChanges();

    // Append the component to the DOM
    const gridViewElement = this.componentRef.location.nativeElement;
    agGridElement.parentNode.appendChild(gridViewElement);

    if (this.componentRef.instance.ngOnInit) {
      this.componentRef.instance.ngOnInit();
    }
  }

  async removeGridViewComponent() {
    if (this.componentRef) {
      const gridViewElement = this.componentRef.location.nativeElement;

      // Check if the element is still part of the DOM and remove it
      if (gridViewElement.parentNode) {
        gridViewElement.parentNode.removeChild(gridViewElement);
      }

      if (this.componentRef.instance.ngOnDestroy) {
        this.componentRef.instance.ngOnDestroy();
      }

      this.componentRef.destroy();
      this.componentRef = null;
    }
  }

  updateGridViewComponentData(newData: any[]) {
    if (this.componentRef) {
      // Update the rowData property of the GridViewComponent
      this.componentRef.instance.rowData = newData;

      // Manually trigger change detection in the GridViewComponent
      this.componentRef.changeDetectorRef.detectChanges();
    }
  }

  // tool panels management functions
  togglePanel(panel: string) {
    let isVisible;
    switch (panel) {
      case 'columns':
        this.isColumnsPanelVisible = !this.isColumnsPanelVisible;
        isVisible = this.isColumnsPanelVisible;
        break;
      case 'columnPresets':
        this.isColumnPresetsPanelVisible = !this.isColumnPresetsPanelVisible;
        isVisible = this.isColumnPresetsPanelVisible;
        break;
      case 'filters':
        this.isFiltersPanelVisible = !this.isFiltersPanelVisible;
        isVisible = this.isFiltersPanelVisible;
        break;
      case 'cmsFilters':
        this.isSavedFiltersPanelVisible = !this.isSavedFiltersPanelVisible;
        isVisible = this.isSavedFiltersPanelVisible;
        break;
      case 'gridViewSettings':
        this.isGridViewSettingsPanelVisible = !this.isGridViewSettingsPanelVisible
        isVisible = this.isGridViewSettingsPanelVisible
    }

    if (isVisible) {
      this.api.setSideBarVisible(true);
      this.api.openToolPanel(panel);
    } else {
      this.api.closeToolPanel();
      this.api.setSideBarVisible(false);

    }
  }

  setCustomFilterLayout() {
    // Fetch the original column definitions
    const originalColDefs = this.columnApi.getAllGridColumns().map(col => col.getColDef());

    // Sort the column definitions alphabetically based on headerName
    const sortedColDefs = [...originalColDefs].sort((a: any, b: any) => {
      return a.headerName.localeCompare(b.headerName);
    });

    // Obtain the Filters Tool Panel instance
    const filtersToolPanel = this.api.getToolPanelInstance('filters');

    // Apply the custom filter layout
    if (filtersToolPanel) {
      filtersToolPanel.setFilterLayout(sortedColDefs as ColDef[]);
    }
  }

  // STORE RELATED FUNCTIONS:
  async addToCart(type: string) {
    this.tableCommunicationService.addToCart(type)
  }

  async addSelectedData() {
    if (this.isTableInput) {
      let extraProps = {};
      if (this.tableInputParams?.populate) {
        extraProps = { populate: this.tableInputParams.populate }
      }
      const data = await this.fetchAllRowsFromBackend(this.tableInputParams?.select || 'id name', this.selectedRowsSet, extraProps);
      this.selectionChange.emit(data);
      console.log('Selected data', data);
      this.selectedRows = data;

      // Show a toast message
      this.messageService.add({
        severity: 'success',
        summary: 'Items Added',
        detail: `Added ${this.selectedRowsSet.size} items.`,
      });

      this.api.deselectAll();

    }
  }

  showDuplicateDialog(payload: any) {
    this.dupDialogComponent.show(payload)
  }

  handleDuplicationSuccess(event: any) {
    this.reloadDataSource(event);
  }

  getGridHeight() {
    if (this.customHeight) {
      return this.customHeight;
    } else {
      const height = window.innerHeight;
      if (height >= 1000) {
        return '85vh';
      } else if (height >= 900) {
        return '82vh';
      } else if (height >= 800) {
        return '78vh';
      } else if (height >= 700) {
        return '75vh';
      } else {
        return '70vh';
      }
    }
  }

  async fetchRowsAndRenderGrid(rows: any[] | null = null) {
    if (!rows) {
      rows = this.getCurrentPageData();
    }
    this.loadingGrid = true;
    this.tableCommunicationService.emitRefreshGrid(rows);
    await this.removeGridViewComponent();
    this.showGridViewComponent(rows, this.renderer.selectRootElement('.ag-root.ag-unselectable', true));
    this.loadingGrid = false;
  }

  async validateDeletion(payload: any, env: any): Promise<any> {
    return await this.tableService.validateDeletion({...payload, entityType: this.entity, env: env ? env.value : null})
  }

  async handleRowDeletion(event: any) {
    let env;
    if(this.entity == 'price-points') {
      env = this.agGridToolsService.getSelectedEnvironment();
    }
    const valid = await this.validateDeletion(event, env);

    if (valid.ids && valid.ids.length === 0) {
      const result = await this.tableService.executeDeletion({ ...event, entityType: this.entity });

      if (result.success) {
        this.dialogData = {
          header: 'Deletion Successful',
          content: 'The record has been successfully deleted from the current environment.',
          footerLabel: 'OK'
        };
      } else {
        this.dialogData = {
          header: 'Deletion Error',
          content: `There was an error while deleting the record: ${result.error}`,
          footerLabel: 'Close'
        };
      }
    } else {
      this.dialogData = {
        header: 'Cannot Delete Record',
        content: `This record is being referenced in the following entity: ${valid.entity}.`,
        ids: valid.ids,
        footerLabel: 'Understood'
      };
      
    }

  this.dialogVisible = true; // Show the dialog
  }

  closeDialog() {
    this.dialogVisible = false;  // Hide the modal by setting dialogData to null
  }
}
