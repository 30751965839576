export const challengeConstants = {
  filePathTooltip: `Level path is generated using the format: levels/[year]/[levelname]/[levelname]. 
  The path is generated based on: Scene Type, Challenge Type, Year, Sourced By, and can be manually edited if needed.`,
  imagePathTooltip: `Level feed image path is generated using the format: images/challenge_feed/[year]/[feedImageName]. 
  The path is generated based on the year and challenge id.`
};

export const pathconfigValues = {
  year: "2025",
  type: "levels",
  itemsThumbnailPathPrefix: "images/challenge_feed",
  grand: 'Grand',
  challengeIdPlaceholder: 'challengeid'
}

export const primaryCard = [
  {
    name: 'Production',
    fieldGroups: [
      [
        // { name: 'ID', key: 'id', controlType: 'inputText', value: '' },
        {
          name: 'File Path',
          key: 'scene',
          controlType: 'inputText',
          value: '',
        },
        {
          name: 'Feed Image',
          key: 'image',
          controlType: 'inputText',
          value: '',
        },
        {
          name: 'File Name',
          key: 'fileName',
          controlType: 'auto',
          value: '',
        },
      ],
      [
        {
          name: 'Year',
          key: 'year',
          controlType: 'dropdown',
          value: '',
        },
        {
          name: 'File Type',
          key: 'fileType',
          controlType: 'dropdown',
          value: '',
        },
        {
          name: 'Keywords',
          key: 'keywords_ref',
          controlType: 'multiSelect_ref',
          value: '',
        },
        {
          name: 'Collection(s)',
          key: 'collection_ref',
          controlType: 'multiSelect_ref',
          apiController: 'challenge-collections',
          value: '',
        },
      ],
      [
        // Hiding this field per CMS-1911
        // {
        //   name: 'Trello Link',
        //   key: 'trelloLink',
        //   controlType: 'inputText',
        //   value: '',
        // }, //
        // {
        //   name: 'Feed Image Ref',
        //   key: 'feedImageLink',
        //   controlType: 'inputText',
        //   value: '',
        // },
      ],
      [
        // Hiding this field per CMS-1911
        // {
        //   name: 'Level Status',
        //   key: 'levelStatus',
        //   controlType: 'dropdown',
        //   value: '',
        // }, ////
        // Hiding this field per CMS-1911
        // {
        //   name: 'Content Status',
        //   key: 'contentStatus',
        //   controlType: 'dropdown',
        //   value: '',
        // },
      ],
      [
        {
          name: 'Bundle Asset (IOS)',
          key: 'bundleAsset',
          controlType: 'toggleButton',
          value: '',
        },
        {
          name: 'Bundle Image (IOS)',
          key: 'bundleImage',
          controlType: 'toggleButton',
          value: '',
        },
        {
          name: 'Bundle Asset (AND)',
          key: 'bundleAssetAnd',
          controlType: 'toggleButton',
          value: '',
        },
        {
          name: 'Bundle Image (AND)',
          key: 'bundleImageAnd',
          controlType: 'toggleButton',
          value: '',
        },
       ],
       [
        {
          name: 'Spawn Audios',
          key: 'spawnAudios_ref',
          controlType: 'multiSelect_ref',
          apiController: 'miscellaneous-build',
          value: '',
        },
        {
          name: 'Spawn Audio Collections',
          key: 'spawnAudioCollections_ref',
          controlType: 'multiSelect_ref',
          apiController: 'audio-collections',
          value: '',
        },
      ],
      [
        {
          name: 'Loop Audios',
          key: 'loopAudios_ref',
          controlType: 'multiSelect_ref',
          apiController: 'miscellaneous-build',
          value: '',
        },
        {
          name: 'Loop Audio Collections',
          key: 'loopAudioCollections_ref',
          controlType: 'multiSelect_ref',
          apiController: 'audio-collections',
          value: '',
        },
       ],
    ],
  },
  {
    name: 'Content',
    fieldGroups: [
      [
        {
          name: 'Challenge Type',
          key: 'type_ref',
          controlType: 'dropdown_ref',
          value: '',
        },
        {
          name: 'Color',
          key: 'color',
          controlType: 'inputText',
          value: '',
        },
        {
          name: 'Close Up',
          key: 'isCloseUp',
          controlType: 'toggleButton',
          value: '',
        },
        {
          name: 'Scene Type',
          key: 'sceneType',
          controlType: 'dropdown_ref',
          value: '',
        },
        {
          name: 'Climate',
          key: 'climate_ref',
          controlType: 'dropdown_ref',
          value: '',
        },
        {
          name: 'Location',
          key: 'location',
          controlType: 'inputText',
          value: '',
        },
        {
          name: 'Level Lock',
          key: 'progressionLevel_ref',
          controlType: 'dropdown',
          value: '',
        },
      ],
      [
        {
          name: 'Challenge Start',
          key: 'start',
          controlType: 'calendar-start',
          value: '',
        },
        {
          name: 'Challenge End',
          key: 'end',
          controlType: 'calendar-end',
          value: '',
        },
      ],
      [
        {
          name: 'Vote Start',
          key: 'voteStart',
          controlType: 'calendar-start',
          value: '',
        },
        {
          name: 'Vote End',
          key: 'voteEnd',
          controlType: 'calendar-end',
          value: '',
        },
      ],
      [
      ],
      [
        {
          name: 'Challenge Copy',
          key: 'description',
          controlType: 'inputText',
          value: '',
        },
      ],
      [
        {
          name: 'Feed Image Copy',
          key: 'descriptionShort',
          controlType: 'inputText',
          value: '',
        },
      ],
      [
        {
          name: 'Associated Challenges',
          key: 'associated_challenge',
          controlType: 'toggleButton',
          value: '',
        },
        {
          name: 'Content Hold',
          key: 'contentHold_ref',
          controlType: 'multiSelect_ref',
          value: '',
        },
        {
          name: 'Sponsor',
          key: 'sponsor_ref',
          controlType: 'dropdown_ref',
          value: '',
        },

      ],
    ],
  },
  {
    name: 'Requirements',
    fieldGroups: [
      [
        {
          name: 'Entry Payout',
          key: 'rewards_ref',
          controlType: 'formArray',
          value: '',
        },

      ],
      [
        {
          name: 'Restrictions',
          key: 'restrictions_ref',
          controlType: 'autoComplete-multi_ref',
          value: '',
        },
      ],
      [
        {
          name: 'Prizes',
          key: 'prizes_ref',
          controlType: 'formArray',
          value: '',
        },
      ],
    ],
  },
  {
    name: 'Internal Notes',
    fieldGroups: [
      [
        {
          name: 'Internal Notes',
          key: 'internalNotes',
          controlType: 'richTextArea',
          value: '',
        },
      ],
    ],
  },
  {
    name: 'Spawners',
    fieldGroups: [
      [
      ],
    ],
  },
  {
    name: 'Build Data',
    fieldGroups: [
      [
        {
          name: 'Created By',
          key: 'createdBy',
          controlType: 'auto_ref',
          value: '',
        },
        {
          name: 'Created On',
          key: 'createdAt',
          controlType: 'date',
          value: '',
        },
        {
          name: 'Updated On',
          key: 'updatedAt',
          controlType: 'date',
          value: '',
        },
      ],
      [
        {
          name: 'Artist',
          key: 'artist_ref',
          controlType: 'autoComplete_ref',
          value: '',
        },
        {
          name: 'Tester',
          key: 'tester_ref',
          controlType: 'autoComplete_ref',
          value: '',
        },
        {
          name: 'Plastic Revision No',
          key: '',
          controlType: '',
          value: '',
        },
      ],
      [
        {
          name: 'Current Challenge Version',
          key: 'asset_versions',
          controlType: 'inputText',
        },
        {
          name: 'Current Image Version',
          key: 'image_versions',
          controlType: 'inputText',
        },
      ],
      [
        {
          name: 'Challenge Promoted Version',
          key: 'IsAssetUpToDate',
          controlType: 'auto',
          version: '',
        },
        {
          name: 'Latest Challenge Build Version',
          key: 'LatestAssetBuildVersion',
          controlType: 'auto',
          version: '',
        },
        {
          name: 'Image Promoted Version',
          key: 'IsImageUpToDate',
          controlType: 'auto',
          version: '',
        },
        {
          name: 'Latest Image Build Version',
          key: 'LatestImageBuildVersion',
          controlType: 'auto',
          version: '',
        },
      ],
    ],
  },
  {
    name: 'History',
    fieldGroups: [[]],
  },
];

export const secondaryCard = {
  name: 'Images',
  fields: [
    {
      name: 'Linux Render',
      key: 'renderPreview',
      controlType: 'renderGallery',
    },
    {
      name: 'Maya Render',
      key: 'room_render_url_1024',
      controlType: 'image',
    },
    {
      name: 'Feed Image',
      key: 'feed_image_url_1024',
      controlType: 'image',
    },

  ],
};

