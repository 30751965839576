<span ngClass="p-float-label">
    <p-multiSelect
        *ngIf="dailyRewardsRecord != undefined && dailyRewardsRecord.type != undefined"
        (onChange)="onRecordChange()"
        [options]="options[dailyRewardsRecord.type == 1 ? '7DayRewards' : '30DayRewards']"
        [(ngModel)]="value"
        optionLabel="name"
        optionValue="_id"
        [filter]="true"
        filterBy="name"
        [style]="field.style"
        display="chip"
      >
    </p-multiSelect>
    <label for="dropdown"> {{ field.label }} <strong *ngIf="field.isRequired" style="color: crimson">*</strong></label>
</span>
