import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GamedataService {
  constructor(private http: HttpClient) { }

  /**
   * Build Game data to a desired environment
   *
   * @param env Environmet to push game data
   * @param exportAll
   */
  async buildStoreData(env: string, exportAll: boolean): Promise<any> {
    const payload = { exportAll }; return await this.http.post<any>(
      `/api/store/build/store?env=${env}&exportAll=${exportAll}`,
      payload
    ).toPromise();
  }

  async buildGamedata(env: string, payload: any): Promise<any> {

    // return await this.http.post<any>(`/api/gamedata/build/${env}`, payload).toPromise();
    return await this.http.post<any>(`/api/gamedata/worker/build/${env}`, payload).toPromise();

  }

  async buildGamedataV3(env: string, payload: any): Promise<any> {
    return await this.http.post<any>(`/api/gamedata/v3/worker/build/${env}`, payload).toPromise();
  }

  /**
   * Promoted Gamedata to Production environment
   */
  async promoteToProd(user: any): Promise<any> {
    return await firstValueFrom(this.http.post<any>(`/api/gamedata/prod-push`, user));
  }

  /**
   * gamedataDiff
   */
  async gamedataDiff(user: any): Promise<any> {
    return await this.http.post<any>(`/api/gamedata/diff`, user).toPromise();
  }

  /**
   * gamedataDiff
   */
  async stopPush(): Promise<any> {
    return await this.http.delete<any>(`/api/gamedata/v3/worker/build/stop`).toPromise();
  }
}
