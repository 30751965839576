<p-card style="width: 100%;" class="p-d-block p-mx-auto p-px-3 p-mt-2"
*ngIf="record[field.key] && record[field.key][0] && record[field.key][0].id"
[header]="field.label"
>
<span class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex">
<h4 *ngIf="record[field.key] && record[field.key].buildStatus" class="p-col p-m-2 p-mx-lg-2 p-my-lg-0">{{ field.name }} Build Status:</h4>
<div
*ngIf="record[field.key] && record[field.key].buildStatus && field.options.showBuildStatus"
class="p-col p-mx-2 p-mb-3"
[ngStyle]="{
position: 'relative',
color: 'var(--primary-color-text)',
'background-color':  record[field.key]
? record[field.key].buildStatus.color
: '',
'border-radius': '0.5em'
}"
>
<ng-container [ngSwitch]="record[field.key].buildStatus.text">
<ng-container *ngSwitchCase="'n/a'">
<h3 class="p-m-0 p-text-center">Not Found</h3>
</ng-container>

<ng-container *ngSwitchDefault>
<h3 class="p-d-inline p-m-0 p-text-center">
  {{ record[field.key].buildStatus.text }}
</h3>
<p class="p-d-inline">
  {{ record[field.key].buildStatus.date }}
</p>

<p-badge
  [value]="record[field.key].buildStatus.buildCount"
  [style]="{
    'background-color':  record[field.key]
    ? record[field.key].buildStatus.color
    : '',
    position: 'absolute',
    right: '20%',
    top: '-1em'
  }"
></p-badge>
</ng-container>
</ng-container>
</div>