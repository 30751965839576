import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/entities/settings/services/settings.service';
import { ArtToolsService } from '../services/art-tools.service';
import { Title } from '@angular/platform-browser';
import GlobalSearchResults from 'src/app/common/dtos/GlobalSearchResults';
import { GlobalSearchService } from 'src/app/common/services/global-search.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { SpinnerService } from '../../../common/services/spinner.service';
import { map } from 'lodash';

@Component({
  selector: 'render-preview-tool',
  templateUrl: './render-preview-tool.component.html',
  styleUrls: ['./render-preview-tool.component.sass'],

})
export class RenderPreviewToolComponent implements OnInit {

  globalSearchQueryValue: string;
  options: Array<any> = [];
  currentTimeout: any;
  globalSearchSearchValue: string;
  challenge: any = {};
  suggestions: any = [];
  spawners: any = {};
  sceneRenderURL: string = "https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg";
  urlPrefix: string = "https://flora-render.dev.petunia.withbuddies.com/renderserver/test"

  constructor(
    private dataService: DataService,
    private messageService: MessageService,
    private settingsService: SettingsService,
    private artToolsService: ArtToolsService,
    private titleService: Title,
    private globalSearchService: GlobalSearchService,
    private entityViewService: EntityViewService,
    private spinnerService: SpinnerService
  ) {}

  isLoading: boolean;
  challengeFetched: boolean = false;

  /**
   * Component Initialiation
   */
  async ngOnInit()
  {
    this.titleService.setTitle('Render Preview');
    this.isLoading = false;
  }

  /**
   * Perform a search query on multiple entities.
   *
   * @param event The search query value
   */
  async globalSearch(event: any)
  {
    this.globalSearchSearchValue = event.query;
    if (this.currentTimeout)
    {
      clearTimeout(this.currentTimeout);
    }

    this.currentTimeout = setTimeout(async () =>
    {
      let response = await this.globalSearchService.challengeSearch(event.query);
      if(response && response.challenges)
      {
        console.log(response);
        this.options = response.challenges;
      }
      this.currentTimeout = null;
    }, 500);
  }

  /**
   * Handle Slected option from dropdownd
   *
   * @param selectedValue Selected entity
   */
  async onSelectedEntity(selectedValue: any)
  {
    this.spinnerService.loadSpinner();
    if(selectedValue)
    {
      console.log(selectedValue);
      // once user selects a challenge we need to get the whole challenge
      await this.entityViewService.getEntity('challenges', { query: { id: selectedValue.id } }).then(async (data: any) => {
        this.challenge = data;
        this.buildURL('challenge');
        if(this.challenge.spawners){
         for await (const spawner of this.challenge.spawners) {
          this.spawners[spawner.id] = {id: spawner.id, item: null}
         }
        }
        setTimeout(() => {
          console.log(this.spawners);
          this.spinnerService.endSpinner();
          this.challengeFetched = true;
        }, 100);

      });
    }
  }

  buildURL(type:string){
    if(type == "items"){
      console.log(this.spawners);
      let itemsArr: number[] = [];
      Object.keys(this.spawners).map((k) => {
        if(this.spawners[k] &&  this.spawners[k].item){
          itemsArr.push(this.spawners[k].item.id);
        } else {
          itemsArr.push(0);
        }
      });
      console.log(itemsArr.join('-'));
      this.sceneRenderURL = `${this.urlPrefix}/${this.challenge.id}/1024/${itemsArr.join('-')}.jpg`;
    } else {
      this.sceneRenderURL = `${this.urlPrefix}/${this.challenge.id}/1024/0.jpg`;
    }
    console.log(this.sceneRenderURL);
  }

  /**
   * Handle Enter key press.
   * Redirects to advance search page.
   *
   * @param event Browser event
   */
  onEnterPress(event: any)
  {
    if(event.key == 'Enter')
    {
      console.log(event);
    }
  }

  /**
   * Autocomplete searching
   *
   * @param e
   * @param fieldName
   * @param model
   * @param minimal
   */
  async getSuggestionsForRef(
    e: any,
    fieldName: string,
    model: string,
    minimal: boolean = false
  ) {
    this.dataService
      .getAllOfType(model, {
        query: isNaN(e) ? { name: { $regex: e, $options: 'i' } } : { id: e },
        select: '_id name id',
        virtuals: false,
        autopopulate: false,
        sort: { name: 1 },
      })
      .subscribe((result) => {
        if (minimal) {
          let o: any[] = [];
          for (const option of result) {
            o.push({ id: option.id, name: option.name, _id: option._id });
          }
          this.suggestions[fieldName] = o;
        } else {
          this.suggestions[fieldName] = result;
        }
      });
  }

  async spawnerChange(event: any, spawnerId: number ){
    this.buildURL('items');
    console.log('spawnerChange: ', event, spawnerId);
  }

  scroll(where: string){
    console.log('scrolling');
    window.scroll({
      top: document.body.scrollHeight,
      left: 100,
      behavior: 'smooth'
    });
  }
}
