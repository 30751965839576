<app-ag-grid-table
  [entity]="entity"
  [columnDefs]="columnDefs"
  [bulkUpdateColDefs]="bulkUpdateColDefs"
  [autopopulateSelect]="autopopulateSelect"
  [columnSelection]="columnSelection"
  [buildParams]="buildParams"
  [tableParams]="tableParams"
>
</app-ag-grid-table>
