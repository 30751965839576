import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Achievements Collection DTO
 */
export default class AchievementsCollectionDTO extends BaseEntityDto
{
    /**
     * Description of the Achievements Collection
     */
    description: string;
    /**
     * List of Achievements reference
     */
    achievements_ref: Array<any> = [];
    /**
     * Entity reference
     */
    entity: string;
}