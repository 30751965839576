import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DynamicCardType } from '../dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from '../dynamic-form-v2/enums/DynamicInputType';
import DynamicFormDTO from '../dynamic-form-v2/dtos/DynamicFormDTO';
import { CommonEntityService } from '../../services/common-entity.service';
import { UtilitiesService } from '../../services/utilities.service';
import { Title } from '@angular/platform-browser';
import { SpinnerService } from '../../services/spinner.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoggerService } from '../../services/logger.service';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { BuildService } from 'src/app/build-data/services/build.service';
import { DynamicViewType } from './enums/DynamicViewType';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { StoreManagementService } from '../../services/store-management.service';
import { OfficeTimePipe } from '../../pipes/officeTime.pipe';
import { DataService } from 'src/app/services/data.service';

@Component({
    selector: 'dynamic-view-v2',
    templateUrl: './dynamic-view-v2.component.html',
    styleUrls: ['./dynamic-view-v2.component.sass'],

  })
/**
 * Base Dynamic View Component
 */
export class DynamicViewV2 implements OnInit
{
    @Input() entityType: string;
    @Input() recordId: number;
    @Input() viewName: string;
    @Input() viewRoute: string = '';
    @Input() fields: Array<DynamicFormDTO> = [];
    @Input() sidebarFields: Array<DynamicFormDTO> = [];
    @Input() layout: string = 'full-screen';
    @Input() customBuildDataKeys: any;
    @Input() displayThumbnail: boolean = false;
    @Input() displayBuildStatus: boolean = false;
    @Input() miscAssetKeys: Array<any> = [];
    @Input() parseRecordData: boolean = false;
    @Input() record: any;
    @Input() showChangesHistory: boolean = true;
    @Input() isStoreEntity: boolean = false;
    @Input() storeEnv: 'dev' | 'qa' | 'prod';
    @Input() parentViewName: 'string';
    @Input() jobRecordsArray: any[] = [];
    @Input() nestedPopulate: boolean = false;
    @Input() allowDuplicate: boolean = false;
    @Input() hideBuildHistoryButton: boolean = false;
    @Input() showLocalizedValuesButton: boolean = false;
    @Input() hideEditButton: boolean = false;
    @Input() isFullWidth: boolean = false;
    @Input() isFullWidthButtons: boolean = false;
    @Input() isNotesPopUp: boolean = false;
    // 
    @Input() isDynamicEntity: boolean = false;
    // 
    @Output() onChange = new EventEmitter<any>();
    @Output() onParseRecordData = new EventEmitter<any>();
    @Output() titleEmitter: EventEmitter<string> = new EventEmitter<string>();
    @Output() idEmitter: EventEmitter<number> = new EventEmitter<number>();
    @ViewChild('videoPreviewCanvas', { static: false }) videoPreviewCanvasRef: ElementRef;
    isLoading: boolean = true;
    spinnerIcon: string = "line-scale";
    spinnerSize: string = "medium";
    spinnerColor: string = "#81c784";
    imgData: any = {};
    showImgDialog: boolean = false;
    showVideoDialog: boolean = false;
    field: any;
    prefix: string = "https://flora-assets-dev.s3.amazonaws.com/2021/";
    timestamp = new Date().getTime();
    imageName: any;
    imagePath: any;
    imageID: any;

    localizedValues: any;
    displayLocalizedValues: boolean = false;

    checkTimezone: boolean = false;

    costsPerStage: any;
    get inputType(): typeof DynamicInputType
    {
        return DynamicInputType;
    }
    get ViewType(): typeof DynamicViewType
    {
        return DynamicViewType;
    }
    get cardType(): typeof DynamicCardType
    {
        return DynamicCardType;
    }
    get buildType(): typeof BuildType
    {
      return BuildType;
    }
    get assetType(): typeof AssetTypes
    {
      return AssetTypes;
    }

  constructor(
    private commonEntityService: CommonEntityService,
    private utilitiesService: UtilitiesService,
    private titleService: Title,
    public spinnerService: SpinnerService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private messageService: MessageService,
    private loggerService: LoggerService,
    private dataService: DataService,
    private entityViewService: EntityViewService,
    private clipboard: Clipboard,
    private buildService: BuildService,
    private storeManagementService: StoreManagementService,
    private validationService: ValidationsService,
    private officeTimePipe: OfficeTimePipe
  ) { }

  /**
   * Dynamic View Component Initialization
   */
  async ngOnInit() {

    this.spinnerService.loadSpinner();
    const routeParams = this.route.snapshot.paramMap;
    this.recordId = Number(routeParams.get('id'));

    if (!this.recordId) {
      this.handleNotFound();
      return;
    }

    let response;
    if (this.isDynamicEntity) {
      response = await this.commonEntityService.getDynamicEntity(this.entityType, this.recordId);
      console.log('response', response);
      if (!response || !response.id) {
        this.handleNotFound();
        return;
      }
    } else {
      response = await this.commonEntityService.findOneWithQuery(this.entityType, { query: { id: this.recordId }, autopopulate: true, nestedPopulate: this.nestedPopulate});
      if (!response || !response.id) {
        this.handleNotFound();
        return;
      }
    }
    

    this.record = response;

    this.processCards();
    await this.processMiscAssets();

    this.titleService.setTitle(`View ${this.entityType} ${this.recordId}`);
    this.onChange.emit(this.record);

    if (this.parseRecordData) {
      this.onParseRecordData.emit(this.record);
      return;
    }

    this.validationService.validateEnvOrder(this.record);
    this.loggerService.log("record.buildStatus: ", this.record.buildStatus);

    console.log('utilitiesService', this.utilitiesService.isUserLocalTimeZonePST());
    this.checkTimezone = this.utilitiesService.isUserLocalTimeZonePST();

    this.isLoading = false;
    this.spinnerService.endSpinner();
  }

  private handleNotFound() {
    this.messageService.add({
      sticky: true,
      severity: 'info',
      summary: 'Not Found',
      detail: `404 Not Found.`
    });
    this.router.navigate([`home`]);
  }

  private async processCards() {
    this.fields.forEach(card => {
      if (!card.fields || card.fields.length === 0) return;

      card.fields.forEach(field => {
        this.processField(field);
        this.processRecordKey(field);
      });
    });
  }

  private async processField(field: any) {
    if (!field) return;

    if (this.isStoreEntity && this.storeEnv && !['id', 'enabled', 'name'].includes(field.key)) {
      this.record[field.key] = this.record[field.key]?.[this.storeEnv] ?? null;
    } else {
      if (field.inputType == DynamicInputType.Calendar) {
        this.parseDateField(this.record, field.key);
      }

      if (field.inputType == DynamicInputType.MiscImageRecord) {
        this.imageName = this.record[field.key]?.name || '';
        this.imagePath = this.record[field.key]?.path || '';
        this.imageID = this.record[field.key]?.id || null;
      }

      if (field.setMongoId) {
        this.record[field.key] = this.record[field.key]?._id || null;
      }
    }
  }


  private async processRecordKey(field: any) {
    if (!field || !this.record.hasOwnProperty(field.key)) return;

    if (field.inputType == DynamicInputType.MultiList) {
      this.imgData = this.record[field.key];
      // Additional logic for MultiList if needed
    }
  }

  private async processMiscAssets() {
    if (!this.miscAssetKeys || this.miscAssetKeys.length === 0) return;

    const promises = this.miscAssetKeys.map(key => this.getBuildData(key));
    try {
      const results = await Promise.all(promises);
      console.log(results);
    } catch (err) {
      console.error(err);
    }
  }


  /**
   * Parse Date Fields comming from the API
   *
   * @param existingRecord Existing record
   * @param field Date field property
   */
  parseDateField(existingRecord: any, field: any)
  {
    this.record[field] = existingRecord[field] ? this.officeTimePipe.transform(new Date(existingRecord[field]))  : null;
  }

  getUpdatedDocument(e: any)
  {
    setTimeout(async () =>
    {
      this.entityViewService
        .getEntity(this.entityType, { query: { id: this.recordId } })
        .then(async (data: any) =>
        {
          this.loggerService.log('doc', data);
          // get build data.
          let tempEntity = await this.entityViewService.getCustomBuildData(data, this.customBuildDataKeys);
          this.record = tempEntity;
          if(this.record.name){
            this.titleService.setTitle(this.record.name);
          }

          this.loggerService.log('got updated entity', this.record);
        });
    }, 1000);
  }

  async getCustomBuildData()
  {
    await this.entityViewService.getCustomBuildData(this.record, this.customBuildDataKeys);
  }

  async getUpdatedBuildData(e: any, key: string){


      if(this.miscAssetKeys && this.miscAssetKeys.length>0){

        let tmpRecord = await this.commonEntityService.findOneWithQuery(this.entityType, { query: { id: this.record.id }, select: `id _id ${this.miscAssetKeys.join(" ")}` });

        for (const key of this.miscAssetKeys) {
          this.record[key] = tmpRecord[key];
        }

        const promises = this.miscAssetKeys.map(key => this.getBuildData(key));
        Promise.all(promises)
        .then(results => {
            // results is an array of the results of each call to getBuildData
            console.log(results);
        })
        .catch(err => {
            // handle errors here
            console.error(err);
        });
      }
  }


  /**
   * Retrieves the build data for the entity.
   */
  async getBuildData(key: string)
  {
    // console.log('getBuildData: ', this.record[key])
    if(this.record[key] && this.record[key].buildData)
    {
      let response = await this.buildService.getBuildData(this.record[key].buildData);
      console.log('build data response:', response);
      this.record[key].buildData = response.buildData;
      this.record[key].buildStatus = response.buildStatus;
    }
    else if(this.record[key])
    {
      this.record[key].buildData = [];
      this.record[key].buildStatus = {
        text: 'Build Status Not Found',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }
  }

  /** Compiles and sets global imgData, then sets showImgDialog to true.
   * @param field FieldData
   */
  displayImage(url: string) {
    this.imgData = {
      title: this.record.name,
      name: url.replace('_256.', '_1024.'),
      path: url.replace('_256.', '_1024.'),
    };
    this.showImgDialog = true;
  }

   /**
   *
   * @param copyVal // text to copy into the user's clipboard
   */
   copyToClipboard(copyVal: string) {
    this.clipboard.copy(copyVal);
    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Thumbnail Copied',
      detail: `URL copied to clipboard successfully!`,
    });
  }

  parseObject(obj: any){
    return JSON.stringify(obj);
  }

  getValueLabel(value: any, field: any){
    if(field && field.options && field.options.values[0] && field.options.values[0].items){
      const obj = field.options.values[0].items.find((item: any) => item.value === value);
      if (obj && obj.label) {
        return obj.label
      } else {
        return undefined;
      }
    } else if (field && field.options && field.options.values){
      const item = field.options.values.find((item: any) => item.value === value);
      if (item && item.name) {
        return item.name
      } else {
        return value;
      }
    }else {
      return value;
    }
  }

  getNestedProperty(rowData: any, col: any): any {
    return col.field.split(/[\.\[\]]+/).reduce((obj: any, key: string) => {
      if (key !== '') {
        if (Number.isNaN(Number(key))) {
          // obj is an object
          return (obj !== null && obj !== undefined) ? obj[key] : null;
        } else {
          // obj is an array
          return (obj !== null && obj !== undefined) ? obj[Number(key)] : null;
        }
      } else {
        return obj;
      }
    }, rowData);
  }

  getAssetType(type: number){
    return this.assetType[type]
  }

  shouldDisplayField(field: any, record: any): boolean {
    // If field has displayControls and displayType, check them
    if (field.displayControls && field.displayType) {
      // Get the value that controls this field's visibility
      const controlValue = record[field.displayType];
      
      // Check each display control
      for (const [key, control] of Object.entries(field.displayControls)) {
        if (typeof control === 'function') {
          // If any control returns false, hide the field
          if (!control(controlValue, key)) {
            return false;
          }
        }
      }
    }
    
    // If no display controls or all controls pass, show the field
    return true;
  }

  openInNewTab(url: string): void {
    window.open(url, '_blank');
  }

  getDynamicURL(value: any): string {
    // Formulate the dynamic URL based on the value (record[field.key])
    // For example:
    return `/build-history/miscellaneous-build/${value}`;
  }

  isUptoDate(buildRecord: any){
    if(buildRecord.lastHash){
      if(buildRecord.asset_versions){
        return buildRecord.asset_versions[
          buildRecord.asset_versions.length - 1
        ].destinationPath.includes(buildRecord.lastHash)
          ? 'Up to date'
          : 'Outdated';
      } else if(buildRecord.image_versions){
        return buildRecord.image_versions[
          buildRecord.image_versions.length - 1
        ].destinationPath.includes(buildRecord.lastHash)
          ? 'Up to date'
          : 'Outdated';
      } else {
        return 'No Data'
      }
    } else {
      return 'No Data'
    }
  }

  getLastPromotedHash(buildRecord: any){
    let path = "";
    if(buildRecord.asset_versions){
      path = buildRecord.asset_versions[
        buildRecord.asset_versions.length - 1
      ].destinationPath;
    } else if(buildRecord.image_versions){
      path = buildRecord.image_versions[
        buildRecord.image_versions.length - 1
      ].destinationPath;
    } else {
      return 'No Data'
    }
    return path.replace("prod/", "");
  }

  /*
  * This function is used to construct the URL for the thumbnail image.
  * It takes in an object with a "path" property, or a string.
  * If the input is an object, it will extract the "path" property and use that.
  * If the input is a string, it will use that as the path.
  * It will then construct the URL using the prefix and the path.
  * The prefix is set in the constructor.
  * The path is the path to the thumbnail image, without the filename or extension.
  * The filename and extension are extracted from the "path" property.
  * The URL is constructed as follows:
  *  prefix + path + filename + "/" + filename + "." + extension
  * Example:
  * prefix = "https://s3.amazonaws.com/asset-dev.roblox.com/"
  * path = "images/AvatarEditorThumbnails/"
  * filename = "avatar"
  * extension = "png"
  * URL = "https://s3.amazonaws.com/asset-dev.roblox.com/images/AvatarEditorThumbnails/avatar/avatar.png"
  * @param input
  * @returns
  */

  constructURL(input: any) {
    let path: string;
    // Check if we're dealing with an object (video_ref) or a string (path)
    if (typeof input === 'object' && input.path) {
        path = input.path;
    } else if (typeof input === 'string') {
        path = input;
    } else {
        // console.log("Invalid input object");
        return '';
    }

    // Extracting the filename and extension from the "path" property
    const filenameWithExtension = path.split("/").pop();
    if (!filenameWithExtension) {
        // console.log("Invalid path in input object");
        return '';
    }
    const [filename, extension] = filenameWithExtension.split(".");

    // Constructing the URL
    const pathWithoutFilename = path.replace(`/${filenameWithExtension}`, '');
    const url = `${this.prefix}${pathWithoutFilename}/${filename}/${filenameWithExtension}`;

    return url;
  }

  navigateToEdit() {
    let url: string;
    if(this.isDynamicEntity) {
      url = `/d/${this.entityType}/edit/${this.recordId}`;
    } else {
      url = `/${this.entityType}/edit/${this.recordId}`;
    }
    window.open(url, '_blank');
  }

  // STORE ENTITY SPECIFIC FUNCTIONS
  getIcon(env: string): string {
    return this.storeManagementService.getIcon(env);
  }

  getSeverity(env: string): string {
    return this.storeManagementService.getSeverity(env);
  }
  // END

  async fetchLocalized() {
    const data = await this.dataService.fetchGridlyRecord(this.recordId, this.entityType);
    this.localizedValues = this.formatData(data);
    this.displayLocalizedValues = true;
  }

  formatData(data: any[]): any[] {
    const excludedColumns = ['update_trigger', 'glossaryRes', 'start', 'translationRequest', 'verified', 'isLive'];

    return data.map(record => {
      const readableId = record.id.split('_')[1] || record.id;
      const formattedRecord: any = { id: readableId.charAt(0).toUpperCase() + readableId.slice(1) };
      record.cells.forEach((cell: any) => {
        if (!excludedColumns.includes(cell.columnId)) {
          formattedRecord[cell.columnId] = cell.value;
        }
      });
      return formattedRecord;
    });
  }

  getColumns(data: any[]): string[] {
    if (data.length === 0) {
      return [];
    }
    return Object.keys(data[0]).filter(key => key !== 'id');
  }
}
