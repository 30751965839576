// card.component.ts
import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from '../../../ag-grid-table/services/ag-grid-dialog.service';
import { DynamicViewType } from '../../dynamic-view-v2/enums/DynamicViewType';
import { BaseViewFieldType } from '../../dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-card',
  templateUrl: "./card-component.html",
  // styleUrls: ['./card-component.sass'],
  styles: [`
    .card 
      border: 1px solid #ddd
      padding: 10px
      margin: 10px
      border-radius: 5px
      min-height: 250px
      display: flex
      flex-direction: column
      width: 18vw

    .thumbnail-wrapper, .name-wrapper 
      display: flex
      justify-content: center
      align-items: center

    .thumbnail-wrapper 
      margin-bottom: 10px

    .name-wrapper 
      font-size: 1.2em
      margin-top: 10px

    .field-label 
      font-weight: bold

      .p-grid > .p-col-12 
        width: 100%

      .p-grid > .p-col-6 
        width: 50%

  `]
})
export class CardComponent implements OnInit {
  @Input() cardConfig: any;
  @Input() record: any;
  @Input() entity: any;
  @Input() type: any;
  dialogImageUrl: string | null = null;
  dialogImageTitle: string | null = null;
  timestamp = new Date().getTime();
  isDialogVisible = false; // New boolean property

  field: any;


  constructor(
    private dialogService: DialogService) 
    {}


  ngOnInit(): void {
    this.buildFields();
  }

  // This method will get the image source for the thumbnail
  getImageSource(): string {
    if (this.entity === 'items' && this.record.thumbnail_ref.thumbnails && this.record.thumbnail_ref.thumbnails[1]) {
      return this.record.thumbnail_ref.thumbnails[1].path;
    } else if (this.entity === 'challenges' && this.record.scene_ref && this.record.scene_ref.bg_images && this.record.scene_ref.bg_images[2]) {
      return this.record.scene_ref.bg_images[2].path;
    } else if (this.entity === 'miscellaneous-build' && this.record.thumbnails && this.record.thumbnails[1]) {
      return this.record.thumbnails[1].path;
    } else {
      return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
    }
  }
  
  // This method will handle the click event on the thumbnail

  onThumbnailClick() {
    let imageUrl = 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';

    if (this.entity === 'items' && this.record.thumbnail_ref && this.record.thumbnail_ref.thumbnails && this.record.thumbnail_ref.thumbnails[1]) {
      imageUrl = this.record.thumbnail_ref.thumbnails[1].path.replace('_256', '_1024');
    } else if (this.entity === 'challenges' && this.record.scene_ref && this.record.scene_ref.bg_images && this.record.scene_ref.bg_images[2]) {
      imageUrl = this.record.scene_ref.bg_images[2].path.replace('_256', '_1024');
    } else if (this.entity === 'miscellaneous-build' && this.record.thumbnails && this.record.thumbnails[1]) {
      imageUrl = this.record.thumbnails[1].path.replace('_256', '_1024');
    }

    this.dialogImageUrl = imageUrl;
    this.dialogImageTitle = this.record.name ? this.record.name : 'Thumbnail';
    this.dialogService.showDialog(this.dialogImageUrl, this.dialogImageTitle);
  }

  // This method will build the fields for the card

  // buildFields() {
  //   console.log('cardConfig: ', this.cardConfig);
  //   console.log('record: ', this.record);
  //   // Build the fields based on the bodyFields configuration
  
  //   this.field = this.cardConfig.bodyFields.map((fieldConfig: any) => {
  //     let viewType: BaseViewFieldType[] = [];
  
  //     // Determine the viewType based on the field name key
  //     switch (fieldConfig.name.key) {
  //       case 'id':
  //         viewType = [BaseViewFieldType.Text];
  //         break;
  //       case 'name':
  //         viewType = [BaseViewFieldType.GridLink];
  //         break;
  //       case 'category_ref':
  //         viewType = [BaseViewFieldType.Dropdown];
  //         break;
  //       case 'climates_ref':
  //         viewType = [BaseViewFieldType.MultiSelectObj];
  //         break;
  //       case 'start':
  //         viewType = [BaseViewFieldType.Calendar];
  //         break;
  //       case 'end':
  //         viewType = [BaseViewFieldType.Calendar];
  //         break;
  //       // Add more cases as needed
  //       default:
  //         viewType = [BaseViewFieldType.Text]; // Default viewType if no specific case matches
  //     }
  
  //     return {
  //       ...fieldConfig,
  //       name: fieldConfig.name.headerName,
  //       inputType: DynamicViewType.BaseViewField, // Assuming you have a DynamicViewType enum
  //       key: fieldConfig.name.key,
  //       label: fieldConfig.label || fieldConfig.name.headerName,
  //       viewTypes: viewType,
  //       controller: this.entity,
  //       record: this.record,
  //     };
  //   });
  // }

  buildFields() {
    console.log('cardConfig: ', this.cardConfig);
    console.log('record: ', this.record);
    // Build the fields based on the bodyFields configuration
  
    this.field = this.cardConfig.bodyFields.map((fieldConfig: any) => {
      let viewType: BaseViewFieldType[] = [];
  
      const recordValue = this.getNestedValue(this.record, fieldConfig.name.key);
  
      if (recordValue !== undefined) {
        if (Array.isArray(recordValue)) {
          if (recordValue.length > 0 && typeof recordValue[0] === 'object') {
            viewType = [BaseViewFieldType.MultiSelectObj];
          } else {
            viewType = [BaseViewFieldType.MultiSelect]; // Assuming another view type for simple arrays
          }
        } else if (typeof recordValue === 'object' && recordValue !== null) {
          viewType = [BaseViewFieldType.Dropdown];
        } else if(fieldConfig.name.key === 'name'){
          viewType = [BaseViewFieldType.GridLink];
        } else if (fieldConfig.name.key === 'start' || fieldConfig.name.key === 'end'){
          viewType = [BaseViewFieldType.Calendar];
        } else {
          viewType = [BaseViewFieldType.Text]; // Default viewType if it's a primitive value
        }
      }
  
      return {
        ...fieldConfig,
        name: fieldConfig.name.headerName,
        inputType: DynamicViewType.BaseViewField, // Assuming you have a DynamicViewType enum
        key: fieldConfig.name.key,
        label: fieldConfig.label || fieldConfig.name.headerName,
        viewTypes: viewType,
        controller: this.entity,
        record: this.record,
      };
    });
  }
  
  // This method will get the nested value from the record  
  getNestedValue(obj: any, key: string): any {
    return key.split('.').reduce((acc, part) => acc && acc[part], obj);
  }

  
  
}
