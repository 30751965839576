import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { UtilitiesService, OptionsParams } from 'src/app/common/services/utilities.service';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildStatusCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/build-status/build-status.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { RichTextEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/rich-text/rich-text.component';
import { LinkCellEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/link/link.component';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { cardConfig } from '../../config/item-grid-view-config';
import { BULK_UPDATE_COL_DEFS } from 'src/app/common/ag-grid-table/constants/constants';
import { TableCommunicationService } from 'src/app/common/ag-grid-table/services/table-communication.service';

@Component({
  selector: 'app-item-table-ag-grid',
  templateUrl: './item-table-ag-grid.component.html',
  styleUrls: ['./item-table-ag-grid.component.sass']
})
export class ItemTableAgGridComponent implements OnInit {

  public entity: string = 'items';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = BULK_UPDATE_COL_DEFS;
  public columnSelection: string;
  public currenciesInclusion: number[] = [2, 1, 11]; // Add specific currency IDs here

  public autopopulateSelect: string = '';
  public buildParams: any = {};

  private options: any = {};
  public cardConfig: any = new cardConfig().cardConfig
  public tableParams: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
    private changeDetectionRef: ChangeDetectorRef,
    private tableCommunicationService: TableCommunicationService
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> { 
    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Thumbnail',
        field: 'thumbnail_img',
        sortable: false,
        resizable: true,
        width: 135,
        autoHeight: true,
        cellRenderer: ThumbnailCellRendererComponent,
        valueGetter: params => {
          return params.data.thumbnail_ref.thumbnails && params.data.thumbnail_ref.thumbnails[1] ?
            params.data.thumbnail_ref.thumbnails[1].path :
            'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
        },
      },
      {
        headerName: 'Re Release Start',
        field: 'reReleaseStart',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear'],
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('reReleaseStart'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Re Release End',
        field: 'reReleaseEnd',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('reReleaseEnd'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Build Status Date',
        field: 'masterBuildDate',
        sortable: false,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        filterParams: {
          buttons: ['clear'],
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'greaterThan',
            'inRange'
          ],
          filterKey: 'prefab_ref',
          isMasterBuildDate: true,
          isAggregate: true,

        },
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          entity: this.entity
          // key: 'prefab_ref'
        },
        valueGetter: (params: any) => {
          const source = params.data.prefab_ref && params.data.prefab_ref.buildOutput ? params.data.prefab_ref.buildOutput : null;
          return this.agGridToolsService.getBuildDate(source);
        },
        resizable: true,
      },
      {
        headerName: 'Art Status',
        field: 'vendorStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Assigned', 'In Revision', 'Approved'],

        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Content Status',
        field: 'itemStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Approved','In Production', 'Awaiting Revision', 'QA Ready'],

        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Asset Type',
        field: 'assetType',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Hard Surface', 'HS Recolor', 'Organics', 'Organic Recolors'],

        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true,
        },
        cellEditorPopupPosition: 'under',
        type: 'editableColumn',
      },
      {
        headerName: 'Batch',
        field: 'batch_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'batches',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Blurb',
        field: 'blurb',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 350,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Blurb Status',
        field: 'blurbStatus',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Approved', 'Needs Blurb', 'Ready for Review', 'Revision Needed'],
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Build Status',
        field: 'buildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'prefab_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'prefab_ref',
          isBuildStatus: true,
        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'Category',
        field: 'category_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'categories',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true,
        },
      },
      {
        headerName: 'Linked Challenge(s)',
        field: 'challenges_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenges',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: [
            'contains',
            'notContains',
            'equals',
            'notEqual',
            'blank',
            'notBlank'
          ]
        },
        floatingFilter: true,
        autoHeight: true,
        width: 250
      },
      {
        headerName: 'Climate(s)',
        field: 'climates_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'climates',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Collection(s)',
        field: 'collection_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'collections',
          isArray: true,
          optionsName: 'collection_ref',
          extractNameFromOptions: true,
          getLatestOptions: () => this.options.collection_ref
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value.id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true,
      },
      {
        headerName: 'Dominant Colors',
        field: 'dominantColors',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterOptions: [
            'contains',
            'notContains',
            'blank',
            'notBlank'
          ]
        },
        autoHeight: true,
        valueGetter: (params) => {
          return params.data.dominantColors ? params.data.dominantColors.join(', ') : 'N/A';
        },
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorArray: true,
          isColorDisplay: true,
        }
      },
      {
        headerName: 'Color(s)',
        field: 'colors_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'colors',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Content Hold',
        field: 'contentHold_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'content-hold',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name',
          addEmptyFilters: true
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true
      },
      {
        headerName: 'Cost(s)',
        field: 'costs_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                },
                comparator: this.agGridToolsService.nameASC
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
                maxNumConditions: 1
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          defaultType: 'currencies'
        },
      },
      {
        headerName: 'Cultivar',
        field: 'cultivar',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Dim X (Width)',
        field: 'dimensionX',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          if (params.data.dimensionX) {
            return params.data.dimensionX.toFixed(4)
          } else {
            return null
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          // isAggregate: true,
          // filterKey: 'prefab_ref',
          // isDimension: true
        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'Dim Y (Height)',
        field: 'dimensionY',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          if (params.data.dimensionY) {
            return params.data.dimensionY.toFixed(4)
          } else {
            return null
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          // isAggregate: true,
          // filterKey: 'prefab_ref',
          // isDimension: true
        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'Dim Z (Depth)',
        field: 'dimensionZ',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          if (params.data.dimensionZ) {
            return params.data.dimensionZ.toFixed(4)
          } else {
            return null
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          // isAggregate: true,
          // filterKey: 'prefab_ref',
          // isDimension: true
        },
        floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'Radius',
        field: 'radius',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          if (params.data.radius) {
            return params.data.radius.toFixed(4)
          } else {
            return null
          }
        },
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          // isAggregate: true,
          // filterKey: 'prefab_ref',
          // isDimension: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'External Plant Data',
        field: 'externalPlantData_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'external-plant-data'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'File Name',
        field: 'fileName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Art Hold',
        field: 'flagged',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['In QA', 'Hold', 'Marked for Deletion'],
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Height',
        field: 'height',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Image Build Status',
        field: 'imageBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'thumbnail_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'thumbnail_ref',
          isBuildStatus: true,
        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'F.Size - IOS',
        field: 'ios_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'ios');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,


        autoHeight: true,
      },
      {
        headerName: 'F.Size - AND',
        field: 'and_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'and');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - MAC',
        field: 'mac_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'mac');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - LIN',
        field: 'lin_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'lin');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - WIN',
        field: 'win_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'win');
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'Internal Notes',
        field: 'internalNotes',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isHtml: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: RichTextEditorComponent,
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
        cellEditorPopup: true,
      },
      {
        headerName: 'Internal Reference Links',
        field: 'internalReferenceLinks',
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          isRefLink: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agTextColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
        cellEditor: LinkCellEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'File Type',
        field: 'itemFileType_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-file-types',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Latin Name',
        field: 'latinName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Material(s)',
        field: 'materials_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'materials',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Patterns(s)',
        field: 'patterns_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'patterns',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Plant Family',
        field: 'plantFamily',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Prefab Path',
        field: 'prefab',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Progression Level',
        field: 'progressionLevel_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'progression-levels'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Vendor Reference Links',
        field: 'referenceLinks',
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          isRefLink: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agTextColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
        cellEditor: LinkCellEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Related Item(s)',
        field: 'releatedItems',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'items',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Shape',
        field: 'shape_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'shapes',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Spread',
        field: 'spread',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Spruce Data Status',
        field: 'spruceDataStatus',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Linked',
          offLabel: 'Not Linked',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Linked' : 'Not Linked'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Sourcing Item Ref',
        field: 'sourcingItem_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'sourcing-groups',
          isSourcingItem: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
      },
      {
        headerName: 'Sourcing Group',
        field: 'sourcingGroup_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'sourcing-groups',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
      },
      {
        headerName: 'Style(s)',
        field: 'styles_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'styles',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true
      },
      // not adding subType
      {
        headerName: 'Tag(s)',
        field: 'tags_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'tags',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true
      },
      {
        headerName: 'Thumbnail Path',
        field: 'thumbnail',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Trait(s)',
        field: 'traits_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'traits',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Type',
        field: 'type_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-types'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Vendor',
        field: 'vendor_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'vendors',
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Vendor Dimensions',
        field: 'vendorDimensions',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Vendor Notes',
        field: 'vendorNotes',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isHtml: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: RichTextEditorComponent,
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Year',
        field: 'year',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['2021', '2022', '2023', '2024', '2025'],
          addEmptyFilters: true
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Item Promoted Status',
        field: 'isAssetUpToDate',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        valueGetter: (params: any) => {
          const lastHash = params.data.prefab_ref?.lastHash;
          const versions = params.data.prefab_ref?.asset_versions;
          const lastVersion = versions ? versions[versions.length - 1] : null;

          let response = 'No data';

          if (!lastHash && versions && versions.length > 0) {
            return 'Outdated';
          }

          if (lastHash && versions && versions.length > 0) {
            response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
          }
          return response;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            'No data', 'Outdated', 'Up to date'
          ],
          isAggregate: true,
          filterKey: 'prefab_ref',
        },
        floatingFilter: true,

      },
      {
        headerName: 'Thumbnail Promoted Status',
        field: 'isImageUpToDate',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        valueGetter: (params: any) => {
          const lastHash = params.data.thumbnail_ref?.lastHash;
          const versions = params.data.thumbnail_ref?.image_versions;
          const lastVersion = versions ? versions[versions.length - 1] : null;

          let response = 'No data';
          if (lastHash && versions && versions.length > 0) {
            response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
          }

          return response;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            'No data', 'Outdated', 'Up to date'
          ],
          isAggregate: true,
          filterKey: 'thumbnail_ref',
        },
        floatingFilter: true,

      },
      {
        headerName: 'Vendor Height',
        field: 'vendorHeight',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Vendor Width',
        field: 'vendorWidth',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Vendor Length',
        field: 'vendorLength',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Bundle Asset (IOS)',
        field: 'bundleAsset',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Asset (AND)',
        field: 'bundleAssetAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle',
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Thumbnail Record',
        field: 'thumbnail_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'thumbnail_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          populate: true,
          select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
        },
        width: 500,
        autoHeight: true,
      },
      {
        headerName: 'Prefab Record',
        field: 'prefab_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'prefab_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          populate: true,
          select: '_id id name path files buildOutput lastHash asset_versions thumbnails entityType assetType'
        },
        width: 500,
        autoHeight: true,

      },
      {
        headerName: 'Nurture',
        field: 'nurture_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'nurture'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Keyword(s)',
        field: 'keywords_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-keywords',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Set(s)',
        field: 'itemSet_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-sets',
          isArray: true,
          noID: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true,
      },
      {
        headerName: 'Spawn Audios',
        field: 'spawnAudios_ref',
        cellRendererParams: {
          entity: 'miscellaneous-build',
          isArray: true,
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audios',
        field: 'loopAudios_ref',
        cellRendererParams: {
          isArray: true
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Spawn Audio Collections',
        field: 'spawnAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audio Collections',
        field: 'loopAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [], // It'll be populated with the function updateFilterColumnDefs and even it could be removed from here
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ];

    this.buildParams = {
      assetKeys: ['prefab_ref'],
      assetBuildType: BuildType.Items,
      renderBuildType: BuildType.Items,
      imageKeys: ['thumbnail_ref'],
      imageBuildType: BuildType.Images,
      assetPopulate: { path: 'prefab_ref', select: 'id _id path entityId entityType assetType buildData assetBuildOutput' },
      imagePopulate: { path: 'thumbnail_ref', select: 'id _id path entityId entityType assetType buildData assetBuildOutput' }
    }

    this.tableParams = {
      tableSearchColumns: ['fileName', 'cultivar', 'latinName'],
      customActionsLabel: 'Items Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'items'
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.setOptions();
  }

  async setOptions() {
    const entities = [
      { ref: 'batch_ref', entity: 'batches', select: 'name id _id' },
      { ref: 'category_ref', entity: 'categories', select: 'name id _id' },
      { ref: 'climates_ref', entity: 'climates', select: 'name id _id' },
      { ref: 'collection_ref', entity: 'collections', select: 'name id _id' },
      { ref: 'colors_ref', entity: 'colors', select: 'name id _id' },
      { ref: 'contentHold_ref', entity: 'content-hold', select: 'name id _id' },
      { ref: 'externalPlantData_ref', entity: 'external-plant-data', select: 'name id _id' },
      { ref: 'itemFileType_ref', entity: 'item-file-types', select: 'name id _id' },
      { ref: 'materials_ref', entity: 'materials', select: 'name id _id' },
      { ref: 'patterns_ref', entity: 'patterns', select: 'name id _id' },
      { ref: 'progressionLevel_ref', entity: 'progression-levels', select: 'name id _id' },
      { ref: 'shape_ref', entity: 'shapes', select: 'name id _id' },
      { ref: 'sourcingGroup_ref', entity: 'sourcing-groups', select: 'name id _id' },
      { ref: 'styles_ref', entity: 'styles', select: 'name id _id' },
      { ref: 'tags_ref', entity: 'tags', select: 'name id _id' },
      { ref: 'traits_ref', entity: 'traits', select: 'name id _id' },
      { ref: 'type_ref', entity: 'item-types', select: 'name id _id' },
      { ref: 'vendor_ref', entity: 'vendors', select: 'name id _id' },
      { ref: 'nurture_ref', entity: 'nurture', select: 'name id _id' },
      { ref: 'keywords_ref', entity: 'item-keywords', select: 'name id _id' },
      { ref: 'items_ref', entity: 'items', select: 'name id _id' },
      { ref: 'currencies_ref', entity: 'currencies', select: 'name id _id', query: this.currenciesInclusion ? { id: { $in: this.currenciesInclusion } } : null },
      { ref: 'resources_ref', entity: 'resources', select: 'name id _id'  },
      { ref: 'titles_ref', entity: 'titles', select: 'name id _id'},
      { ref: 'spawnAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [22] } } },
      { ref: 'loopAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [23] } } },
      { ref: 'spawnAudioCollections_ref', entity: 'audio-collections', query: { type: 1 }, select: 'name id _id' },
      { ref: 'loopAudioCollections_ref', entity: 'audio-collections', query: { type: 2 }, select: 'name id _id ' },
      { ref: 'itemSet_ref', entity: 'item-sets', select: 'name id _id' },
    ];

    Promise.all(entities.map(async ({ ref, entity, select, query }: any, index) => {
      const params = new OptionsParams({
        entity, select, query
      });
      return await this.utilitiesService.getOptions(this.options, ref, params);
    })).then(() => {
      this.updateFilterColumnDefs(entities);
      
      // Share the line item options via the communication service
      const lineItemOptions = {
        resources: this.options.resources_ref,
        currencies: this.options.currencies_ref,
        titles: this.options.titles_ref
      };
      this.tableCommunicationService.setLineItemOptions(lineItemOptions);
      // Emit the options update to notify cell renderers
      this.tableCommunicationService.emitOptionsUpdate(this.options);
    });
  }

  updateFilterColumnDefs(entities: any[]) {
    this.columnDefs = this.columnDefs.map((col) => {
      const exists = !!entities.find((entity) => entity.ref === col.field);
      if (exists && col.field) {
        const filterParams = {
          ...col.filterParams,
          values: this.options[col.field] || [],
        };

        // Add empty/non-empty options similar to preprocessColumnDefs
        if (col.filter === 'agSetColumnFilter' && col.filterParams?.addEmptyFilters) {
          if (col.filterParams.isMultiRefFilter || col.filterParams.isSingleRefFilter) {
            // Handle multi-reference filters by adding objects
            const values = filterParams.values as any[] || [];
            if (!values.some(value => value.name === '(EMPTY)')) {
              values.push({ name: '(EMPTY)', id: -1, _id: -1 });
            }
            if (!values.some(value => value.name === '(NOT EMPTY)')) {
              values.push({ name: '(NOT EMPTY)', id: -2, _id: -2 });
            }
            filterParams.values = values;
          } else {
            // Handle simple filters by adding string values
            const values = filterParams.values as string[] || [];
            if (!values.includes('(EMPTY)')) values.push('(EMPTY)');
            if (!values.includes('(NOT EMPTY)')) values.push('(NOT EMPTY)');
            filterParams.values = values;
          }
        }
        
        return {
          ...col,
          filterParams,
        };
      }
      return col;
    });

    this.changeDetectionRef.detectChanges();
    console.log('====== Item filters updated =====');
  }

  fileSizeGetter(params: any, platform: string, getNumber: boolean = false) {
    if (params.data.prefab_ref && params.data.prefab_ref.path && params.data.prefab_ref.files) {
      let fileSizeName = '';
      fileSizeName = `${params.data.prefab_ref.path.slice(
        params.data.prefab_ref.path.lastIndexOf('/') + 1,
      )}_${platform}`;
      return params.data.prefab_ref.files[`${fileSizeName}`] || null
    } else {
      return null
    }
  }
}
