import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Progression Level DTO
 */
export default class ProgressionLevelDto extends BaseEntityDto 
{
  /**
   * Design Value
   */
  designValue?: string;
  /**
   * Internal Notes
   */
  internal_notes?: string;
  /**
   * List of Rewards
   */
  rewards_ref: Array<any> = [];
  /**
   * Level to be awarded at
   */
  level: Number;
}