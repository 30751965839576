import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-rewards-track-view-v2',
  templateUrl: './rewards-track-view-v2.component.html',
  styleUrls: ['./rewards-track-view-v2.component.sass']
})
export class RewardsTrackViewV2Component extends BaseDynamicViewComponent implements OnInit {
  rewardsTrackRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  /**
   * Rewards Track View V2 Initialization
   */
  ngOnInit() {
    this.fields = [
      {
        title: 'Rewards Track Data',
        fields: [
          {
            name: 'Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'name',
            label: 'Name',
            columnWidth: 6
          },
          {
            name: 'type',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.DropdownWithLabels],
            clearField: true,
            key: 'type',
            label: 'Rewards Track Type',
            isRequired: true,
            options: {
              fieldName: 'type',
              values: [
                { name: 'Login Rewards - 7 days', value: 1 },
                { name: 'Login Rewards - Day 30', value: 2 },
                { name: 'Garden Pass - Free', value: 3 },
                { name: 'Garden Pass - Paid', value: 4 },
                { name: 'Friends Invited', value: 5 },
                { name: 'Endless Offer', value: 6 },
                { name: 'Co-Op', value: 7 },
                { name: 'Mini Games', value: 8 }
              ]
            },
            columnWidth: 6
          },
          {
            name: 'Targets / Stage Milestones',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'stageMilestone',
            label: 'Targets / Stage Milestones',
            columnWidth: 6
          },
          {
            name: "isLoop",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            clearField: true,
            key: 'isLoop',
            label: 'Is Loop',
            isRequired: true,
            columnWidth: 4,
            booleanoptions: {
              true: "True",
              false: "False"
            }
          },

        ],
        type: DynamicCardType.Card
      },
      {
        title: 'Rewards Tracks',
        fields: [
          {
            name: 'Rewards Tracks',
            inputType: DynamicViewType.RewardTrack,
            key: 'RewardTrack',
            label: 'Reward Tracks'
          }
        ],
        type: DynamicCardType.Card
      }
    ];

    this.title = "Rewards Track";
    this.viewRoute = 'rewards-tracks';
    this.isLoading = false;
  }
}
