import { ColDef } from 'ag-grid-enterprise';
import { LoggerService } from './../../../../services/logger.service';
import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';

@Component({
  selector: 'app-line-item-cell-renderer',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.sass']
})
export class LineItemCellRendererComponent implements ICellRendererAngularComp {

  constructor(
    private tableCommunicationService: TableCommunicationService,
    private loggerService: LoggerService,
  ) {

  }

  public params: any;

  agInit(params: any): void {
    this.params = params;
    // console.log(this.params.value);
  }

  refresh(params: any): boolean {
    return false;
  }

  getBadgeStyle(v: any): object {
    if (v.lineItemType === 'Currency') {
      if (v?.id?.name === 'Coin') {
        return {'background-color': '#ffcd5d'};
      } else if (v?.id?.name === 'Diamond') {
        return {'background-color': '#A6DDFD'};
      } else if (v?.id?.name === 'USP') {
        return {'background-color': '#92ABAD'}
      }
    }
    return {'background-color': 'white'}; // Default color
  }

}
