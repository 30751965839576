<form [formGroup]="formGroup">
  <ng-container [ngSwitch]="field.controlType">
    <span
      [style]="{ 'min-width': '16.5em' }"
      [ngClass]="{
        'p-float-label':
          field.controlType !==
          ('inputSwitch' ||
            'toggleButton' ||
            'formArray' ||
            'formGroup' ||
            'colorPicker')
      }"
    >
      <form-array
        *ngSwitchCase="'formArray'"
        [field]="field"
        [form]="form"
        [formGroup]="formGroup"
      ></form-array>

      <ng-container *ngSwitchCase="'inputSwitch'">
        <label for="{{ field.key }}">
          {{ field.name }}
          <p-inputSwitch
            [formControl]="formGroup.controls[field.key]"
            [id]="field.key"
          >
          </p-inputSwitch>
        </label>
      </ng-container>

      <p-toggleButton
        *ngSwitchCase="'toggleButton'"
        [formControl]="formGroup.controls[field.key]"
        [id]="field.key"
        [onLabel]="field.name"
        [offLabel]="'Not ' + field.name"
        [style]="{ width: '12.5em' }"
        class="field-item"
      ></p-toggleButton>

      <p-autoComplete
        *ngSwitchCase="'autoComplete-multi_ref'"
        [formControl]="formGroup.controls[field.key]"
        [suggestions]="field.suggestions"
        [multiple]="true"
        [showEmptyMessage]="true"
        (completeMethod)="getSuggestionsForRef($event.query)"
        field="name"
      >
        <ng-template let-item pTemplate="selectedItem">
          {{ item.name }} ({{ item.id }})
        </ng-template>

        <ng-template let-item pTemplate="item">
          <!-- <span pTooltip="{{ item.category }}, Item #{{ item.id }}">{{
            item.name
          }}</span> -->
          {{ item.name }} ({{ item.id }})
        </ng-template>
      </p-autoComplete>

      <p-autoComplete
        *ngSwitchCase="'autoComplete_ref'"
        [formControl]="formGroup.controls[field.key]"
        [suggestions]="field.suggestions"
        [showEmptyMessage]="true"
        (completeMethod)="getSuggestionsForRef($event.query)"
        field="name"
      >
        <ng-template let-item pTemplate="selectedItem">
          {{ item.name }} ({{ item.id }})
        </ng-template>

        <ng-template let-item pTemplate="item">
          {{ item.name }} ({{ item.id }})
        </ng-template>
      </p-autoComplete>

      <!-- <p-autoComplete
        *ngSwitchCase="'autoComplete_ref'"
        [formControlName]="field.key"
        [suggestions]="field.suggestions"
        [showEmptyMessage]="true"
        (completeMethod)="getSuggestionsForRef($event.query)"
        field="name"
      >
        <ng-template let-item pTemplate="item">
          {{ item.name }} ({{ item.id }})
        </ng-template>
      </p-autoComplete> -->

      <input
        *ngSwitchCase="'inputText'"
        [formControl]="formGroup.controls[field.key]"
        [id]="field.key"
        [type]="field.type"
        pInputText
        class="field-item"
      />

      <textarea
        *ngSwitchCase="'inputTextarea'"
        [formControl]="formGroup.controls[field.key]"
        [id]="field.key"
        cols="40"
        rows="3"
        pInputTextarea
        class="field-item"
      ></textarea>

      <p-inputNumber
        *ngSwitchCase="'inputNumber-buttons'"
        [formControl]="formGroup.controls[field.key]"
        [showButtons]="true"
        [inputId]="field.key"
        [min]="1"
        mode="decimal"
      ></p-inputNumber>

      <p-calendar
        *ngSwitchCase="'calendar-start'"
        [formControl]="formGroup.controls[field.key]"
        [inputId]="field.key"
        [showTime]="true"
        [yearNavigator]="true"
        yearRange="2020:2030"
        [monthNavigator]="true"
        class="field-item"
        [selectOtherMonths]="true"
        [defaultDate]="defaultDate"
      ></p-calendar>

      <p-calendar
        *ngSwitchCase="'calendar-end'"
        [formControl]="formGroup.controls[field.key]"
        [inputId]="field.key"
        [showTime]="true"
        [minDate]="form.get('start')!.value"
        [yearNavigator]="true"
        yearRange="2020:2030"
        [monthNavigator]="true"
        class="field-item"
        [selectOtherMonths]="true"
        [defaultDate]="defaultDate"
      ></p-calendar>

      <p-multiSelect
        *ngSwitchCase="'multiSelect_ref'"
        [formControl]="formGroup.controls[field.key]"
        [id]="field.key"
        [options]="field.options"
        optionLabel="name"
        optionValue="_id"
        display="chip"
        [style]="{ 'min-width': '12.5em' }"
      >
      </p-multiSelect>

      <p-multiSelect
        *ngSwitchCase="'multiSelect'"
        [formControl]="formGroup.controls[field.key]"
        [id]="field.key"
        [options]="field.options"
        optionLabel="name"
        optionValue="name"
        display="chip"
        [style]="{ 'min-width': '12.5em' }"
      >
      </p-multiSelect>

      <p-dropdown
        *ngSwitchCase="'dropdown_ref'"
        [formControl]="formGroup.controls[field.key]"
        [id]="field.key"
        [options]="field.options"
        optionLabel="name"
        optionValue="_id"
        [autoDisplayFirst]="false"
        [filter]="true"
        [filterBy]="'name'"
        [style]="{ 'min-width': '12.5em' }"
        (onChange)="handleInputEvents($event.value)"
      ></p-dropdown>

      <p-dropdown
        *ngSwitchCase="'dropdown'"
        [formControl]="formGroup.controls[field.key]"
        [id]="field.key"
        [options]="field.options"
        optionLabel="name"
        optionValue="name"
        [autoDisplayFirst]="false"
        [filter]="true"
        [filterBy]="'name'"
        [style]="{ 'min-width': '12.5em' }"
        (onChange)="handleInputEvents($event.value)"
      ></p-dropdown>

      <p-dropdown
        *ngSwitchCase="'dropdown-add'"
        [formControlName]="field.key"
        [id]="field.key"
        [options]="field.options"
        optionLabel="name"
        optionValue="_id"
        [autoDisplayFirst]="false"
        class="field-item"
        [style]="{ 'min-width': '12.5em' }"
      ></p-dropdown>

      <ng-container *ngSwitchCase="'colorPicker'">
          <div class="p-mt-2">
              <p-colorPicker 
                  [formControlName]="field.key"
                  (onChange)="onColorChange('color', $event)"
              >
              </p-colorPicker>
          </div>
          <div class="p-mt-4">
              <span
                [style]="{ 'min-width': '12.0em' }"
                ngClass="p-float-label"
              >
                <input 
                    type="text" 
                    pInputText 
                    [formControlName]="field.key"
                    class="field-item"
                    id="colorText"
                > 
                <label for="colorText"> Color </label>
              </span>
          </div>
      </ng-container>

      <ng-container
        *ngIf="
          field.controlType !== 'inputSwitch' &&
          field.controlType !== 'toggleButton' &&
          field.controlType !== 'formArray' &&
          field.controlType !== 'formGroup' &&
          field.controlType !== 'colorPicker'
        "
      >
        <label for="{{ field.key }}">
          {{ field.name }}
        </label>

        <button
          *ngIf="isDirty"
          pButton
          type="button"
          icon="pi pi-times"
          class="p-button-outlined p-d-inline"
          (click)="clearValue()"
        ></button>
      </ng-container>
    </span>
  </ng-container>
</form>
