<p-messages></p-messages>
<p-toast></p-toast>

<h1 *ngIf="!isEditMode" class="p-ml-3">Add New Sourcing Group</h1>
<h1 *ngIf="isEditMode" class="p-ml-3">Editing Sourcing Group {{ id }}</h1>

<form [formGroup]="sourcingGroupForm" (ngSubmit)="onSubmitSourcingGroup()" *ngIf="!loading">
  <div class="p-m-5">
    <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
      <input formControlName="name" [style]="{ 'min-width': '13em' }" pInputText class="field-item" />
      <label for="name"> Sourcing group name <strong style="color: crimson">*</strong></label>
    </span>
  </div>
  <!-- Content Status -->
  <div class="p-m-5 p-inputgroup">
    <span ngClass="p-float-label" [style]="{'width': 'auto'}">
      <p-dropdown
        [style]="{ 'min-width': '13em' }"
        formControlName="contentStatus"
        [options]="options['contentStatus']"
        [autoDisplayFirst]="false"
        [filter]="true"
      ></p-dropdown>
      <label for="contentStatus"> Content Status </label>
      <p-button
        *ngIf="sourcingGroupForm.value['contentStatus']"
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        styleClass="p-button-text p-button-danger"
        (click)="clearField('contentStatus')"
      ></p-button>
    </span>
  </div> 
  <!-- Art Status -->
  <div class="p-m-5 p-inputgroup">
    <span ngClass="p-float-label" [style]="{'width': 'auto'}">
      <p-dropdown
        [style]="{ 'min-width': '13em' }"
        formControlName="vendorStatus"
        [options]="options['vendorStatus']"
        [autoDisplayFirst]="false"
        [filter]="true"
      ></p-dropdown>
      <label for="vendorStatus"> Art Status </label>
      <p-button
        *ngIf="sourcingGroupForm.value['vendorStatus']"
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        styleClass="p-button-text p-button-danger"
        (click)="clearField('vendorStatus')"
      ></p-button>
    </span>
  </div> 
  <!-- Asset type -->
  <div class="p-m-5 p-inputgroup">
    <span ngClass="p-float-label" [style]="{'width': 'auto'}">
      <p-dropdown
        [style]="{ 'min-width': '13em' }"
        formControlName="assetType"
        [options]="options['assetType']"
        [autoDisplayFirst]="false"
        [filter]="true"
      ></p-dropdown>
      <label for="assetType"> Asset Type <strong style="color: crimson">*</strong></label>
      <p-button
        *ngIf="sourcingGroupForm.value['assetType']"
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        styleClass="p-button-text p-button-danger"
        (click)="clearField('assetType')"
      ></p-button>
    </span>
  </div>
  <!-- YEAR -->
  <div class="p-m-5 p-inputgroup">
    <span ngClass="p-float-label" [style]="{'width': 'auto'}">
      <p-dropdown
        formControlName="year"
        [filter]="true"
        [style]="{ 'min-width': '13em' }"
        [options]="options['year']"
        [autoDisplayFirst]="false"
      ></p-dropdown>
      <label for="year"> Year <strong style="color: crimson">*</strong> </label>
      <p-button
        *ngIf="sourcingGroupForm.value['year']"
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        styleClass="p-button-text p-button-danger"
        (click)="clearField('year');"
      ></p-button>
    </span>
  </div>
  <div class="p-m-5">
    <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
      <p-toggleButton
        formControlName="enabled"
        [onLabel]="'Enabled'"
        [offLabel]="'Not Enabled'"
        [style]="{ width: '12.5em' }"
        class="field-item"
      ></p-toggleButton>
    </span>
  </div>

  <!-- Batch Section -->
  <div class="p-m-5" *ngIf="!loading">
    <p-card>
      <ng-template pTemplate="header">
        <div class="p-pt-3 p-pl-3 p-pb-0">
          <h2 class="p-mt-0">Batch</h2>
          <small
            class="p-ml-2 p-mt-0"
          >
            All items within this sourcing group will belong to the selected Batch.
          </small>
        </div>
      </ng-template>
      <hr class="p-m-0 p-p-0">
      <!-- Create new batch button -->
      <div class="p-mt-4" style="text-align: center;">
        <button 
          [style]="{'width':'80%', 'maxWidth':'500px'}"
          pButton 
          pRipple 
          type="button" 
          label="Create New Batch" 
          class="p-button-outlined p-button-info"
          (click)="handleCreateBatchToggleChange()"
        >
        </button> 
      </div>

      <p-divider align="center">Or</p-divider>

      <!-- Use existing Batch -->
      <h3>{{ isEditMode ? 'Change to': 'Use an'}} existing Batch</h3>
      <span ngClass="p-float-label" class="p-mt-5">
        <p-dropdown
          [style]="{'width':'80%', 'maxWidth':'500px'}"
          formControlName="batch_ref"
          [options]="options['batches']"
          optionLabel="name"
          optionValue="_id"
          [autoDisplayFirst]="false"
          [filter]="true"
          (ngModelChange)="onNameComponentChange('existingBatch', $event)"
        ></p-dropdown>
        <label for="challengeClimate"> Select Existing Batch <strong style="color: crimson">*</strong></label>
        <p-button
          *ngIf="sourcingGroupForm.value['batch_ref']"
          ariaLabel="Clear Field"
          icon="pi pi-minus"
          styleClass="p-button-text p-button-danger"
          (click)="clearSourcingGroupFormField('batch_ref')"
        ></p-button>
      </span>

      <h3 *ngIf="batchFormFields._id">Update selected Batch</h3>
      <small class="p-ml-2 p-mt-0" *ngIf="batchFormFields._id">Batch details</small>

      <!-- BATCH FORM -->
      <div *ngIf="batchFormFields._id">
        <form [formGroup]="batchForm">
          <!-- NAME -->
          <div class="p-m-5">
            <span>
              <small>Auto generated by form</small>
            </span>
            <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label p-mt-4">
              <input
                formControlName="name" [(ngModel)]="batchFormFields.name"
                pInputText class="field-item"
                (ngModelChange)="updateBatchFormTouched = true;"
                />
              <label for="name"> Name </label>
            </span>
          </div>

          <!-- batch number -->
          <div class="p-m-5">
            <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
              <input
                (ngModelChange)="onNameComponentChange('batchNumber', $event); updateBatchFormTouched = true;"
                formControlName="batchNumber"
                pInputText class="field-item" />
              <label for="batchNumber"> Batch Number <strong style="color: crimson">*</strong></label>
            </span>
          </div>

          <!-- VENDOR -->
          <div class="p-m-5 p-inputgroup">
            <span ngClass="p-float-label" [style]="{'width': 'auto'}">
              <p-dropdown
                (ngModelChange)="onNameComponentChange('vendor', $event)"
                [style]="{ 'min-width': '13em' }"
                formControlName="vendor_ref"
                [options]="options['vendor_ref']"
                optionLabel="name"
                optionValue="_id"
                [autoDisplayFirst]="false"
                [filter]="true"
                [filterBy]="'name'"
                (onChange)="updateBatchFormTouched = true;"
                (onClear)="updateBatchFormTouched = true;"
              ></p-dropdown>
              <label for="vendor_ref"> Vendor <strong style="color: crimson">*</strong></label>
              <p-button
                *ngIf="batchForm.value['vendor_ref']"
                ariaLabel="Clear Field"
                icon="pi pi-minus"
                styleClass="p-button-text p-button-danger"
                (click)="clearField('vendor_ref'); updateBatchFormTouched = true;"
              ></p-button>
            </span>
          </div>
        </form>
      </div>
      <!-- BATCH FORM - END -->
    </p-card>
  </div>

  <!-- Submit Button -->
  <button
    #submitBtn
    pButton
    pRipple
    type="submit"
    [label]="isEditMode ? 'Update Sourcing Group' : submitMessage"
    [disabled]="!sourcingGroupForm.valid"
    class="p-mx-6 p-mt-3"
    [style]="{ 'min-width': '12.5em' }"
  ></button>
</form>

<!-- Create new batch modal -->
<p-dialog header="Create New Batch" [(visible)]="displayCreateNewBatchModal" [modal]="true" [style]="{width: '25em'}" [draggable]="false" [resizable]="false">
  <form *ngIf="!loading" [formGroup]="batchForm" appendTo="body">

    <!-- Name -->
    <div class="p-m-5">
      <span ngClass="p-float-label p-my-4">
        <input
          appendTo="body"
          formControlName="name" 
          [(ngModel)]="batchFormFields.name"
          pInputText 
          [style]="{ width: '18em' }"
          class="field-item"
          />
        <label for="name"> Name </label>
      </span>
    </div>

    <!-- Batch Number -->
    <div class="p-m-5">
      <span ngClass="p-float-label">
        <input
          appendTo="body"
          (ngModelChange)="onNameComponentChange('batchNumber', $event)"
          formControlName="batchNumber"
          [style]="{ width: '18em' }"
          pInputText class="field-item" />
        <label for="batchNumber"> Batch Number <strong style="color: crimson">*</strong></label>
      </span>
    </div>

    <!-- Vendor -->
    <div class="p-m-5 p-inputgroup">
      <span ngClass="p-float-label">
        <p-dropdown
          appendTo="body"
          (ngModelChange)="onNameComponentChange('vendor', $event)"
          [style]="{ width: '18em' }"
          formControlName="vendor_ref"
          [options]="options['vendor_ref']"
          optionLabel="name"
          optionValue="_id"
          [autoDisplayFirst]="false"
          [filter]="true"
          [filterBy]="'name'"
          [showClear]="true"
        ></p-dropdown>
        <label for="vendor_ref"> Vendor <strong style="color: crimson">*</strong></label>
      </span>
    </div>

    <!-- Batch - Enabled -->
    <div class="p-m-5">
      <span ngClass="p-float-label">
        <p-toggleButton
          appendTo="body"
          formControlName="enabled"
          [onLabel]="'Batch - Enabled'"
          [offLabel]="'Batch - Not Enabled'"
          [style]="{ width: '18em' }"
          class="field-item"
        ></p-toggleButton>
      </span>
    </div>
  </form>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" [style]="{ 'margin-top': '10px' }" (click)="displayCreateNewBatchModal=false" label="Cancel" styleClass="p-button-text p-button-danger"></p-button>
    <p-button icon="pi pi-check" (click)="onCreateBatch()" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>