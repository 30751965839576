import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'expandObject'
})
export class ExpandObjectPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): string {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value, null, 2);
    }
    return value; 
  }

}

