<!-- Toaster Alert -->
<p-messages [style]="{ width: '100%', position: 'absolute', top: 0, left: 0 }"></p-messages>

<!-- Confirm Modal -->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>

<!-- Spinner -->
<ngx-spinner *ngIf="isLoading" bdColor="rgba(36,34,36,0.8)" size="medium" color="#81c784" type="pacman">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>


<!-- Horizontal Buttons (Show only if not in test environment and initialized) -->
<div class="export-buttons-container" style="display: flex; justify-content: center; align-items: center; height: 100vh; margin: 0 5vw;" *ngIf="!isInit && environmentName !=='test'">
    <!-- Button for DEV -->
    <button pButton pRipple type="button" class="p-button-rounded p-button-success p-button-lg" style="margin-right: 2vw; padding: 2vw 4vw;" [disabled]="isLoading" (click)="onExportData('dev')">
      <div style="text-align: center; font-weight: bold;">
        <div style="font-size: 2rem;">EXPORT STORE DATA</div>
        <div style="font-size: 3.5rem;">DEV</div>
      </div>
    </button>
    <!-- Button for QA -->
    <button pButton pRipple type="button" class="p-button-rounded p-button-warning p-button-lg" style="margin: 0 2vw; padding: 2vw 4vw;" [disabled]="isLoading" (click)="displayConfirmModal('qa')">
      <div style="text-align: center; font-weight: bold;">
        <div style="font-size: 2rem;">EXPORT STORE DATA</div>
        <div style="font-size: 3.5rem;">QA</div>
      </div>
    </button>
    <!-- Button for PROD -->
    <button pButton pRipple type="button" class="p-button-rounded p-button-danger p-button-lg" style="margin-left: 2vw; padding: 2vw 4vw;" [disabled]="isLoading" (click)="displayConfirmModal('prod')">
      <div style="text-align: center; font-weight: bold;">
        <div style="font-size: 2rem;">EXPORT STORE DATA</div>
        <div style="font-size: 3.5em;">PROD</div>
      </div>
    </button>
  </div>

<!-- Button to build the test game data (Show only if in test environment and initialized) -->
<div style="display: flex; justify-content: center; align-items: center; height: 100vh;" *ngIf="!isInit && environmentName =='test'">
  <button pButton pRipple [ngStyle]="{backgroundColor:'var(--indigo-400)'}" type="button" class="p-button-rounded p-button-lg p-mr-3 p-ml-3" [disabled]="isLoading" (click)="onExportData('test')">
    <div style="text-align: center; font-weight: bold;">
      <h2 style="margin: 1.5vw 3.5vw; font-size: '3.5rem'">EXPORT TEST GAMEDATA</h2>
    </div>
  </button>
</div>

<!-- Upper Right Corner Elements (Always visible) -->
<div style="position: fixed; top: 100px; right: 60px; display: flex; align-items: center; gap: 10px;">
<!-- Container for Partial with Info Button -->
<div style="position: relative; display: flex; align-items: center;">
    <label style="color: white; margin-right: 30px;">Partial</label>
    <button pButton type="button" (click)="opPartial.toggle($event)" icon="pi pi-info" class="p-button-rounded p-button-text p-button-plain" style="position: absolute; top: 0; right: 22px; transform: translate(100%, -50%); padding: 11px; width: 20px; height: 22px; font-size: 14px; line-height: 18px; color: #83cb84; background-color: transparent; border: 1px solid #83cb84; border-radius: 50%;">
    </button>
    <p-overlayPanel #opPartial>
        <ng-template pTemplate="content">
          <p>Exports only updated or new records since last export time.</p>
        </ng-template>
      </p-overlayPanel>
  </div>
    <!-- Container for the switch -->
    <p-inputSwitch [(ngModel)]="exportAll" (onChange)="onExportAllChange($event)"></p-inputSwitch>
    <!-- Container for Full with Info Button -->
    <div style="position: relative; display: flex; align-items: center;">
      <label style="color: white; margin-left: 6px;">Full</label>
      <button pButton type="button" (click)="opFull.toggle($event)" icon="pi pi-info" class="p-button-rounded p-button-text p-button-plain" style="position: absolute; top: 0; right: -8px; transform: translate(100%, -50%); padding: 11px; width: 20px; height: 22px; font-size: 14px; line-height: 18px; color: #83cb84; background-color: transparent; border: 1px solid #83cb84; border-radius: 50%;">
      </button>
      <p-overlayPanel #opFull>
        <ng-template pTemplate="content">
          <p>Exports all records.</p>
        </ng-template>
      </p-overlayPanel>
    </div>
  </div>
