<div class="p-inputgroup" *ngIf="authService.hasPermission(['read'], 'GlobalSearchController') && (authService.hasPermission(['read'], 'ItemController') || authService.hasPermission(['read'], 'ChallengeController'))">
    <span class="p-float-label">
        <p-autoComplete
            dropdownIcon="pi pi-search"
            inputId="autocomplete"
            [(ngModel)]="globalSearchQueryValue"
            [group]="true"
            [suggestions]="options.GroupedEntities"
            (completeMethod)="globalSearch($event)"
            field="name"
            placeholder="Global Search"
            appendTo="body"
            [autoHighlight]="true"
            scrollHeight="250px"
            class="p-inputtext-lg"
            (onSelect)="onSelectedEntity($event)"
            (onKeyUp)="onEnterPress($event)"
            [style]="{'max-width': '125px'}"
        >
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span style="display: flex; justify-content: center">{{group.label}}</span>
                </div>
            </ng-template>
            <ng-template let-entity pTemplate="item">
                <div>
                    <div>{{entity.name}} - ({{entity.id}})</div>
                </div>
            </ng-template>
        </p-autoComplete>
    </span>
    <button pButton pRipple icon="pi pi-search" class="p-inputgroup-addon p-button" (click)="goToAdvanceSearch()"></button>
</div>
