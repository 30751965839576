import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-embedded-form',
  templateUrl: './embedded-form.component.html',
  styleUrls: ['./embedded-form.component.sass'],
  animations: [
    trigger('expandCollapse', [
      state('void', style({
        height: '0',
        overflow: 'hidden',
        opacity: '0'
      })),
      state('*', style({
        height: '*',
        opacity: '1'
      })),
      transition('void <=> *', animate('500ms ease-in-out'))
    ])
  ]
})
export class EmbeddedFormComponent implements OnInit {
  @Output() submitEmbeddedFormEvent = new EventEmitter<void>();
  private formSubmitSubject = new Subject<any>();

  @Output() entitySelected = new EventEmitter<any>();

  @Input() field: any;
  @Input() record: any;
  @Input() options: any[];


  selectedValue: any;
  embeddedType: 'new' | 'edit';
  showForm: boolean = false;
  dropdownPlaceholder: string = 'Select a record';


  constructor() {
    // Entities should be provided by the parent component
  }

  ngOnInit(): void {
    console.log('Embedded Form field: ', this.field);
    console.log('value: ', this.record[this.field.key]);
    this.assignCurrentValue();
    this.dropdownPlaceholder = 'Select a '+ this.field.label + ' record';
  }

  // Method to be called by the parent dynamic form
  submitInnerForm(): Promise<any> {
    this.submitEmbeddedFormEvent.emit();

    return new Promise(resolve => {
      const subscription = this.formSubmitSubject.subscribe({
        next: response => {
          resolve({
            data: response,
            field: this.field
          });
          subscription.unsubscribe(); // Unsubscribe to avoid memory leaks
        },
      });
    });
  }

  // Call this method when the inner form submission is completed
  onChildFormSubmitted(result: any) {
    this.formSubmitSubject.next(result);
  }

  assignCurrentValue() {
    if (this.record && this.field && this.options) {
      // Find the option that has the same _id as the record's field value
      const matchingOption = this.options.find(option => option[this.field.optionValue] === this.record[this.field.key][this.field.optionValue]);
      if (matchingOption) {
        this.selectedValue = matchingOption;
      } else {
        // If there's no matching option, the selected value should be null
        this.selectedValue = null;
      }
    }
  }

  onEntityChange(event: any) {
    console.log('onEntityChange: >> ', event.value);
    this.selectedValue = event.value;
    this.entitySelected.emit(event.value);
  }

  clearSelection() {
    this.selectedValue = null;
    this.entitySelected.emit(this.selectedValue);
  }

  createNewEntity() {
    this.embeddedType = 'new';
    this.dropdownPlaceholder = `New ${this.field.label} record will be automatically assigned.`;
    this.showForm = true;
  }

  editSelectedEntity() {
    this.embeddedType = 'edit';
    this.dropdownPlaceholder = 'Select a '+ this.field.label + ' record';
    this.showForm = true;
  }

  embeddedFormFieldChange(event: any){
    // console.log('embeddedFormFieldChange: ', event);
  }
}

