import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { clone, cloneDeep } from 'lodash';
import { firstValueFrom } from 'rxjs';


@Component({
  selector: 'app-nurture-stages-input',
  templateUrl: './nurture-stages-input.component.html',
  styleUrls: ['./nurture-stages-input.component.sass']
})
export class NurtureStagesInputComponent implements OnInit {
  @Input() record: any;
  @Input() stages: any;
  @Input() costs: any;
  @Input() nurtureItem: any;
  @Input() options: any;
  @Input() category: any;
  @Input() climate: any;
  @Output() onChange: any = new EventEmitter<any>();
  @Output() onGenerateStages: any = new EventEmitter<any>();
  originalStages: any;
  originalCosts: any;
  autofillStagesModalView: boolean = false;

  constructor(
    private httpService: HttpClient,
  ) { }

  ngOnInit(): void {
    this.originalStages = cloneDeep(this.stages)
    this.originalCosts = cloneDeep(this.costs)
    console.log(this.nurtureItem)
    if(this.stages.length > 0 && !this.costs) {
      this.costs = [];
      for(let i = 0; i < this.stages.length; i++) {
        this.costs.push([])
      }
    }
  }

  pushNewStage(event: any) {
    this.costs.push([])
    this.stages = [...this.stages, {stage: null}]
    console.log(this.stages, this.costs)
    this.onRecordChange('stages_ref');
    this.onRecordChange('costs_ref');
  }

  onRecordChange(field: any) {
    console.log(field, this.stages,this.costs)
    console.log({ field: field, value: field == 'stages_ref' ? this.stages : this.costs })
    this.onChange.emit({ field: field, value: field == 'stages_ref' ? this.stages : this.costs });
  }

  onReorder(event: any) {
    const index: number = this.originalStages.indexOf(event[0]);
    const elementToMove: string = this.originalCosts[index];
    this.costs.splice(index, 1);

    const newPosition: number = this.stages.indexOf(event[0]);
    this.costs.splice(newPosition, 0, elementToMove);


    this.originalStages = cloneDeep(this.stages);
    this.originalCosts = cloneDeep(this.stages);

    this.onRecordChange('stages_ref');
    this.onRecordChange('costs_ref');
  }

  // add remove stage
  removeStage(index: number) {
    this.stages.splice(index, 1);
    this.costs.splice(index, 1);
    this.onRecordChange('stages_ref');
    this.onRecordChange('costs_ref');
  }

  autofillStagesModal() {
    this.autofillStagesModalView = true;
  }

  async autofillStages() {
    const climate = this.climate?.[0]?.name || 'Arid';
    const category = this.category?.name || 'Small Plant';

    console.log(' Climate: ', climate, ' Category: ', category);

    const waterCost = {
        t: {_id: '617b62958e2f0e4a67f86c76', name: 'Currency', id: 1},
        id: {_id: '63e61d4a20bd481c2ae19ef4', name: "Water"},
        lineItemType: 'Currency',
    };

    const createWaterCost = (amount: number) => [{
        ...waterCost,
        c: amount
    }];

    // Base stages with their costs
    const stages = [
        '63f47b1adc85d4802de05cdf', // Seed
        '63f47b1adc85d4df69e05cf2'  // Seedling
    ];

    const seedlingCost = category === 'Large Plant' ? 3 : (category === 'Medium Plant' ? 2 : 1);
    const costs = [
        createWaterCost(1), // Seed always costs 1
        createWaterCost(seedlingCost)
    ];

    // Climate-specific sprout stages
    const climateStages: Record<string, string[]> = {
        'Arid': ['63f47b1bdc85d47cf8e05d12', '66fc5aeea3af8f207e72c3c5'],
        'Temperate': ['63f47b1adc85d42c40e05d02', '66fc5ad2c01f14528eb53db0'],
        'Tropical': ['63f47b1bdc85d42c3ae05d22', '66fc5b0526dd850a630e07eb']
    };

    const normalizedClimate = climate.replace(' Climate', '') as keyof typeof climateStages;
    if (climateStages[normalizedClimate]) {
        stages.push(...climateStages[normalizedClimate]);
        costs.push(createWaterCost(category === 'Small Plant' ? 2 : 3));
    } else {
        console.log('Invalid climate.');
    }

    // Add grown stage
    stages.push('63f47b1bdc85d47cf8e05d12');
    costs.push(createWaterCost(category === 'Large Plant' ? 4 : 3));

    this.stages = stages;
    this.costs = costs;

    this.onRecordChange('stages_ref');
    this.onRecordChange('costs_ref');

    this.onGenerateStages.emit();
    this.autofillStagesModalView = false;
  }
}
