import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
@Component({
  selector: 'app-localization-config-form',
  templateUrl: './localization-config-form-component.html',
})
export class LocalizationConfigFormComponent extends BaseDynamicFormComponent implements OnInit {
  localizationConfigRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.fields = [
      {
        title: "Localization Configuration Data",
        fields: [
          {
            name: "Grid View Id",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'gridViewId',
            isRequired: true,
            label: 'Grid View Id',
            disabled: false
          },
          {
            name: "Model Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'modelName',
            isRequired: true,
            label: 'Model Name',
            disabled: false
          },
          {
            name: "localizable Fields",
            inputType: DynamicInputType.NestedInputComponent,
            key: 'localizableFields',
            label: 'Localizable Fields',
            columnWidth: 12,
            options: {
                headerTitle : "Localizable Fields",
                addButtonText: "Add Localizable Field",
                newRecordTemplate: { migrated: false, name: '' },
                fieldName: 'desiredFieldName',
                subFields: [
                  {
                    name: "Migrated",
                    inputType: DynamicInputType.BaseInputField,
                    inputTypeFields: [InputFieldType.Checkboxv2],
                    key: 'migrated',
                    isRequired: false,
                    label: 'Migrated'
                  },
                  {
                    name: "Name",
                    inputType: DynamicInputType.BaseInputField,
                    inputTypeFields: [InputFieldType.TextInput],
                    key: 'name',
                    isRequired: true,
                    label: 'Name'
                  },
                ]
              }
            },
            {
              name: "Migrated",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Checkboxv2],
              key: 'migrated',
              isRequired: false,
              label: 'Migrated',
              disabled: false
            },
      ],
        type: DynamicCardType.Card
      }
    ];

    this.localizationConfigRecord = {
      enabled: false
    };

    this.title = "localization configuration";
    this.viewRoute = 'localization-configuration';
    this.isLoading = false;
  }
}
