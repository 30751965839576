import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import RewardsTrackDTO from '../../dtos/RewardsTrackDTO';
import DefaultTracks from '../../../season-pass/dtos/defaultTracks';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'rewards-track-form-v3',
  templateUrl: './rewards-track-form-v3.component.html',
  styleUrls: ['./rewards-track-form-v3.component.sass']
})
export class RewardsTrackFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  @Input() rewardsTrackRecord: RewardsTrackDTO = new RewardsTrackDTO();
  @Input() isChildComponent: boolean = false;
  @Input() disableTypeField: boolean = false;
  @Output() onRewardsTrackRecordChange = new EventEmitter<any>();
  defaultTracks: DefaultTracks = new DefaultTracks();
  constructor()
  {
    super();
  }

  /**
   * Rewards Track Form V3 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Rewards Track Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: "Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: true,
            disabled: this.disableTypeField,
            options:
            {
              fieldName: 'type',
              values:
              [
                { name: 'Login Rewards - 7 days', value: 1 },
                { name: 'Login Rewards - Day 30', value: 2 },
                { name: 'Garden Pass - Free', value: 3 },
                { name: 'Garden Pass - Paid', value: 4 },
                { name: 'Friends Invited', value: 5 },
                { name: 'Endless Offer', value: 6 },
                { name: 'Co-Op', value: 7 },
                { name: 'Mini Games', value: 8 },
              ]
            }
          }
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Track Data",
        fields:
        [
          {
            name: "Track",
            inputType: DynamicInputType.RewardsTrackComponent,
            key: '',
            customFields:
            [
              {
                key: 'stageMilestone',
                touched: false
              },
              {
                key: 'free',
                touched: false
              },
              {
                key: 'paid',
                touched: false
              },
              {
                key: 'friends',
                touched: false
              },
              {
                key: 'dailyRewardDay1',
                touched: false
              },
              {
                key: 'dailyRewardDay2',
                touched: false
              },
              {
                key: 'dailyRewardDay3',
                touched: false
              },
              {
                key: 'dailyRewardDay4',
                touched: false
              },
              {
                key: 'dailyRewardDay5',
                touched: false
              },
              {
                key: 'dailyRewardDay6',
                touched: false
              },
              {
                key: 'dailyRewardDay7',
                touched: false
              },
              {
                key: 'dailyRewardDay30',
                touched: false
              },
              {
                key: 'coop',
                touched: false
              },
              {
                key: 'EOrewards',
                touched: false
              },
              {
                key: 'EObundles',
                touched: false
              },
              {
                key: 'miniGamesRewards',
                touched: false
              },
              {
                key: 'isLoop',
                touched: false
              }
            ]
          },
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Rewards Track";
    this.viewRoute = 'rewards-track';
    this.isLoading = false;
  }

  /**
   * Emits changes comming from child component
   * to parent component.
   *
   * @param event Envent comming from child component.
   */
  onChange(event: any)
  {
    if(event.field == 'type') {
      switch (event.value) {
        case 3:
          this.rewardsTrackRecord.free = this.defaultTracks.FreeDefaultTrack
          this.rewardsTrackRecord.stageMilestone = this.defaultTracks.FreeDefaultStageMilestones
          break;
        case 4:
          this.rewardsTrackRecord.paid = this.defaultTracks.PaidDefaultTrack
          this.rewardsTrackRecord.stageMilestone = this.defaultTracks.PaidDefaultStageMilestones
          break;
        default:
            break;
      }
    }
    this.onRewardsTrackRecordChange.emit(this.rewardsTrackRecord);
  }
}
