import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SeriesService 
{
  private readonly apiURLprefix : string = '/api/series';
  constructor(private http: HttpClient) {}

  /**
   * Creates a Series record
   * 
   * @param seriesData Series data
   */
  async createSeries(seriesData: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/add`, seriesData).toPromise();
  }

  /**
   * Updates a Series record.
   * 
   * @param seriesId Id of the currrency to update.
   * @param seriesData Series data to be updated.
   */
  async updateSeries(seriesId: any, seriesData: any): Promise<any>
  {
    return await this.http.patch(`${this.apiURLprefix}/update/${seriesId}`, seriesData).toPromise();
  }
}