import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import NurtureRarityDTO from '../dtos/NurtureRarityDTO';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-nurture-rarity-view',
  templateUrl: './nurture-rarity-view.component.html',
  styleUrls: ['./nurture-rarity-view.component.sass']
})
export class NurtureRarityViewComponent extends BaseDynamicViewComponent implements OnInit {


  nurtureRarityRecord: NurtureRarityDTO = new NurtureRarityDTO();
  constructor() {
    super();
  }

  ngOnInit() {
    {
      this.fields =
      [
        {
          title: "Nurture Rarity Data",
          fields:
          [
            {
              name: "Display Name",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'displayName',
              isRequired: true,
              label: 'Display Name',
              columnWidth: 6
            },
            {
              name: "Start Date",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'start',
              label: 'Start Date',
              columnWidth: 6

            },
            {
              name: "Weight",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'weight',
              isRequired: true,
              label: 'Weight',
              columnWidth: 6
            },

            {
              name: "End Date",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'end',
              label: 'End Date',
              columnWidth: 6
            },
            {
              name: "Color",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.ColorDisplay],
              clearField: true,
              key: 'color',
              label: 'Color',
              columnWidth: 12
            },
            {
              name: "SeedPack Background",
              inputType: DynamicViewType.MiscImageRecordV2,
              viewMode: 'record',
              clearField: true,
              key: 'background_ref',
              label: 'SeedPack Background',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: { fieldName: 'background_ref', apiController: 'miscellaneous-build',customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
              columnWidth: 6
            },
            {
              name: "SeedPack Tint",
              inputType: DynamicViewType.MiscImageRecordV2,
              viewMode: 'record',
              clearField: true,
              key: 'seedpackTint_ref',
              label: 'SeedPack Tint',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: { fieldName: 'seedpackTint_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
              columnWidth: 6
            },
            {
              name: "SeedPack Pattern",
              inputType: DynamicViewType.MiscImageRecordV2,
              viewMode: 'record',
              clearField: true,
              key: 'seedpackPattern_ref',
              label: 'SeedPack Pattern',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: { fieldName: 'seedpackPattern_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
              columnWidth: 6
            },
          ],
          type: DynamicCardType.Card
        },
      ]

      this.title = "Nurture Rarity";
      this.viewRoute = 'nurture-rarity';
      this.isLoading = false;
    }
  }
}
