import { Component, EventEmitter, Input, OnInit, Output, ViewChild, Injector } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import NurtureDTO from '../../dtos/NurtureDTO';
import NurtureStageDTO from '../../dtos/NurtureStageDTO';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { ConfirmationService } from 'primeng/api';
import { NurtureCollectionService } from '../../nurture-collection/services/nurture-collection.service';
import { DataService } from 'src/app/services/data.service';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'nurture-form-v3',
  templateUrl: './nurture-form-v3.component.html',
  styleUrls: ['./nurture-form-v3.component.sass'],
  providers: [ConfirmationService]
})
export class NurtureFormV3Component extends BaseDynamicFormComponent implements OnInit {
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  @Input() nurtureRecord: NurtureDTO = new NurtureDTO();
  @Input() isChildComponent: boolean = false;
  @Input() disableTypeField: boolean = false;
  hasNurtureCollection: any = false;
  collectionId: any;
  record_id: any;

  constructor(
    private commonEntityService: CommonEntityService,
    private validation: BaseInputValidationServiceService,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private http: HttpClient,
    injector: Injector
  ) {
    super(injector);

  }

  /**
   * Nurture Form V3 Component Initialization
   */
  ngOnInit() {
    console.log(this.nurtureRecord)
    this.fields =
      [
        {
          title: "Nurture Item Data",
          fields: [
              {
                name: "Internal Name",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'name',
                isRequired: true,
                label: 'Internal Name',
                disabled: false,
                columnWidth: 6
              },
              {
                name: "Display Name",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'displayName',
                isRequired: true,
                label: 'Display Name',
                disabled: false,
                columnWidth: 6
              },
              {
                name: "Collection",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.queryDropdown],
                clearField: true,
                key: 'collectionId',
                optionLabel: 'name',
                optionValue: "_id",
                label: 'Collection',
                setMongoId: true,
                filter: true,
                filterBy: "id,name",
                autoDisplayFirst: false,
                options: { fieldName: 'collectionId', apiController: 'nurture-collection', minimal: true, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'id name', isdisplayNameIDPath: false },
                columnWidth: 6
              },
              {
                name: 'Item',
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.AutoComplete],
                key: 'itemId',
                label: 'Item',
                clearField: true,
                optionValue: '_id',
                autocompleteMultipleValues: true,
                autocompleteShowEmptyMessage: true,
                autocompleteField: 'id',
                autocompleteMinLength: 2,
                style: { width: '100%' },
                showClear: true,
                suggestions: { fieldName: 'itemId', apiController: 'items', minimal: false, autopopulate: false, virtuals: false, sort: { name: 1 }, select: '-createdAt -updatedAt -createdBy' },
                columnWidth: 6
              },
              {
                name: "Start Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'start',
                isRequired: false,
                label: 'Start Date/Time',
                columnWidth: 6,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.end) {
                    return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
                  } else {
                    return true;
                  }
                },
              },
              {
                name: "End Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'end',
                isRequired: false,
                label: 'End Date/Time',
                columnWidth: 6,
                validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
                  if(incomingValue && record.start){
                    return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
                  } else{
                    return true;
                  }
                },
              },
              {
                name: "Nurture Rarity",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Dropdown],
                clearField: true,
                key: 'rarity_ref',
                optionLabel: 'name',
                optionValue: "_id",
                label: 'Rarity',
                filter: true,
                setMongoId: true,
                filterBy: "id,name",
                autoDisplayFirst: false,
                options: { fieldName: 'rarity_ref', apiController: 'nurture-rarity', minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 }, select: '-createdAt -updatedAt -createdBy' },
                columnWidth: 6
              },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Sprout Share",
          fields:[
            {
              name: "Icon Image",

              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.queryDropdown],
              clearField: true,
              key: 'sproutImage_ref',
              label: 'Icon Image',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              setMongoId: true,
              scrollHeight: '200px',
              options: { fieldName: 'sproutImage_ref', apiController: 'miscellaneous-build', customQuery: { entityType: { $in: [5] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true, },
              columnWidth: 6
            },
            {
              name: "",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.SpaceCol],
              key: '',
              label: '',
              columnWidth: 12
            },
            {
              name: "Share Popup",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.queryDropdown],
              clearField: true,
              key: 'nurtureVideo_ref',
              label: 'Share Popup',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              setMongoId: true,
              scrollHeight: '200px',
              options: { fieldName: 'nurtureVideo_ref', apiController: 'miscellaneous-build', customQuery:{ entityType: { $in: [6] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true, },
              columnWidth: 6
            },
            {
              name: "Share Loop",

              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.queryDropdown],
              clearField: true,
              key: 'nurtureLoopVideo_ref',
              label: 'Share Loop',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              setMongoId: true,
              scrollHeight: '200px',
              options: { fieldName: 'nurtureLoopVideo_ref', apiController: 'miscellaneous-build', customQuery:{ entityType: { $in: [6] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true, },
              columnWidth: 6
            },
          ],
          type: DynamicCardType.Card
        },
        {
          title: "Assets",
          fields:[
            {
              name: "Pin Asset",
              inputType: DynamicInputType.assetInputComponent,
              defaultEntityType: BuildType.Nurture,
              defaultEntityTypeName: 'Nurture',
              defaultAssetType: AssetTypes.NurturePin,
              nameInputEnabled: true,
              assetTypeInputEnabled: false,
              key: 'coinAsset_ref',
              label: 'Pin Asset',
              parentEntity: 'nurture',
              options: { fieldName: 'coinAsset_ref', apiController: 'miscellaneous-build', customQuery: { assetType: { $in: [20] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
              columnWidth: 6
            },
            {
              name: "Pin Thumbnail",
              inputType: DynamicInputType.assetInputComponent,
              defaultEntityType: BuildType.Nurture,
              defaultEntityTypeName: 'Nurture',
              defaultAssetType: AssetTypes.NurturePinThumb,
              nameInputEnabled: true,
              assetTypeInputEnabled: false,
              key: 'coinThumbnail_ref',
              label: 'Pin Thumbnail',
              parentEntity: 'nurture',
              options: { fieldName: 'coinThumbnail_ref', apiController: 'miscellaneous-build', customQuery: { assetType: AssetTypes.Miscellaneous }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
              columnWidth: 6
            },
          ],
          type: DynamicCardType.Card
        },
        {
          title: "Seed Pack Imagery",
          fields:[
            {
              name: "Seed Pack Open Image",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.AutoComplete],
              clearField: true,
              key: 'seed_pack_open_ref',
              label: 'Seed Pack Open Image',
              columnWidth: 6,
              autocompleteMultipleValues: false, 
              autocompleteShowEmptyMessage: true,
              autocompleteField: 'name',
              autocompleteMinLength: 3,
              suggestions: {
                fieldName: 'seed_pack_open_ref',
                apiController: 'miscellaneous-build',
                autopopulate: false,
                minimal: false,
                virtuals: false,
                customQueryField: 'name',
                select: '_id name id path',
                customQuery: { assetType: AssetTypes.SeedPack },
                isdisplayNameIDPath: true,
                isMiscAsset: true 
              },
              style: { width: '100%' },
              showClear: true
            },
            {
              name: "Seed Pack Closed Image",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.AutoComplete],
              clearField: true,
              key: 'seed_pack_closed_ref',
              label: 'Seed Pack Closed Image',
              columnWidth: 6,
              autocompleteMultipleValues: false, 
              autocompleteShowEmptyMessage: true,
              autocompleteField: 'name',
              autocompleteMinLength: 3,
              suggestions: {
                fieldName: 'seed_pack_closed_ref',
                apiController: 'miscellaneous-build',
                autopopulate: false,
                minimal: false,
                virtuals: false,
                customQueryField: 'name',
                select: '_id name id path',
                customQuery: { assetType: AssetTypes.SeedPack },
                isdisplayNameIDPath: true,
                isMiscAsset: true 
              },
              style: { width: '100%' },
              showClear: true
            }
            
          ],
          type: DynamicCardType.Card
        },
        {
          title: "Stages",
          fields: [
            {
              name: 'Nurture Stage',
              inputType: DynamicInputType.NurtureStagesField,
              key: 'stages_ref',
              keyDependent: 'costs_ref',
              setMongoId: true,
              options: {
                fieldName: 'stages_ref',
                apiController: 'nurture-stage',
                minimal: false,
                autopopulate: true,
              }
            }
          ],
          type: DynamicCardType.Card
        },
      ]

    this.title = "Nurture";
    this.viewRoute = 'nurture';
    this.isLoading = false;
  }


  async beforeSubmitNurture(event: any) {
    if(this.hasNurtureCollection && this.record_id) {
      const confirmed = await this.showConfirmationDialog('Are you sure you want to proceed? The nurture collection will be modified');

      if (confirmed) {
        let response = await firstValueFrom(this.http.post<any>('/api/nurture/update-collection', {_id: this.record_id, collection: this.collectionId}))

        this.dynamicFormComponent.beforeSubmit();
      }
    } else {
      this.dynamicFormComponent.beforeSubmit();
    }

  }

  private async showConfirmationDialog(message: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.confirmationService.confirm({
        message: message,
        accept: () => resolve(true),
        reject: () => resolve(false)
      });
    });
  }

  checkNurtureCollection(event: any) {
    if(event.field == 'collectionId') {
      this.hasNurtureCollection = true
      this.collectionId = event.value
    }

  }

  getId(event: any) {
    if(event && event._id){
      this.record_id = event._id
    }

  }

  private async findCollection(id: string, array: any) {
    return array.find((obj: any) => obj.id === id);
  }
}
