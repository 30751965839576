<div *ngIf="!isLoading">
<p-card [header]="this.type == 'asset' ? 'Asset version history for ' + this.tableType + ' ' + this.entity.id : 'Image version history for ' + this.tableType + ' ID: ' + this.entity.id">
<p-table  [value]="filtered">

  <ng-template pTemplate="header">
      <tr>
          <th>Stage</th>
          <th>Destination Path</th>
          <th>Promoted At</th>
          <th></th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-version >
      <tr [ngClass]="{'active-row': version.promotedAt === (this.type == 'asset' ? entity.asset_versions.slice(-1)[0].promotedAt : entity.image_versions.slice(-1)[0].promotedAt)}">
          <td>{{version.stage}}</td>
          <td>{{version.destinationPath}}</td>
          <td>{{version.promotedAt | officeTime : 'short'}}</td>
          <td><button
            pButton
            pRipple
            type="button"
            *ngIf="((version.stage == 'prod') && !(version.promotedAt === (this.type == 'asset' ? entity.asset_versions.slice(-1)[0].promotedAt : entity.image_versions.slice(-1)[0].promotedAt))) && permission"
            label="Update to this version"
            class="p-ml-5"
            (click)="displayConfirmationModal(version)"
            >
          </button></td>
      </tr>
  </ng-template>
</p-table>
</p-card>
</div>
<p-dialog [transitionOptions]="'0ms'" header="Update Version" [(visible)]="displayUpdateModal" [modal]="true">
  <p-message severity="warn" text="Please beware that going through with this action will override the current version of the asset"></p-message>
  <div *ngIf="displayUpdateModal" class="p-grid">
  <div class="p-col-6">
  <div>
  <h4>Current Version:</h4>
</div>
<div>
  <h5>Stage: {{this.type == 'asset' ? entity.asset_versions.slice(-1)[0].stage : entity.image_versions.slice(-1)[0].stage}}</h5>
</div>
<div>
  <h5>Promoted At: {{this.type == 'asset' ? (entity.asset_versions.slice(-1)[0].promotedAt | officeTime: 'short') : (entity.image_versions.slice(-1)[0].promotedAt | officeTime: 'short')}}</h5>
</div>
<div>
  <h5>Destination Path: {{this.type == 'asset' ? entity.asset_versions.slice(-1)[0].destinationPath : entity.image_versions.slice(-1)[0].destinationPath}}</h5>
</div>
</div>
<div class="p-col-6">
  <div>
  <h4>Version to Update:</h4>
</div>
<div>
  <h5>Stage: {{rowToUpdate.stage}}</h5>
</div>
<div>
  <h5>Promoted At: {{rowToUpdate.promotedAt | officeTime: 'short'}}</h5>
</div>
<div>
  <h5>Destination Path: {{rowToUpdate.destinationPath}}</h5>
</div>
</div>
</div>
<ng-template pTemplate="footer">
   <button type="button" pButton icon="pi pi-times" label="Confirm" (click)="updateMidVersion()"></button>
   <button type="button" pButton icon="pi pi-check" label="Cancel" (click)="displayUpdateModal = false"></button>
</ng-template>
</p-dialog>
