<div class="rewards-track-input">
    <div *ngIf="!record?.type; else rewardTrackForm">
        Please select a Reward Track Type
    </div>
    <ng-template #rewardTrackForm>
        <div *ngFor="let key of trackKeys; let i = index">
            <ng-container [ngSwitch]="trackKeyType[key]">
                <ng-container *ngSwitchCase="'singleStage'">
                    <prizes-card-form-group [(prize)]="record[key]" [showPrize]="true" [version]="lineItemInputVersion"
                        [fieldsetName]="trackKeyLabels[i]"
                        (onPrizeChange)="onChange(key, $event);"></prizes-card-form-group>
                </ng-container>
                <ng-container *ngSwitchCase="'multiStage'">
                    <div *ngIf="showIsLoop">
                        <p class="label">Is Loop</p>
                        <p-inputSwitch class="field-item" [(ngModel)]="record.isLoop"
                            [ngModelOptions]="{ standalone: true }"
                            (ngModelChange)="onChange('isLoop', $event);"></p-inputSwitch>
                    </div>
                    <div [style]="{'max-width': '80rem', 'margin': '0 auto'}">
                        <div class="flex w-full">
                            <div style="margin: 0 auto;">
                                <button pButton pRipple type="button" label="Add Stage +"
                                    class="p-button-raised p-button-success p-button-text p-mb-3"
                                    (click)="addStage(key);">
                                </button>
                            </div>
                        </div>
                        <p-orderList [value]="record[key]" (onReorder)="assignStages(record[key])"
                            [listStyle]="{ 'max-height': record[key].length > 2 ? '200rem' : '50rem' }"
                            header="Reward Track" [dragdrop]="true" [id]="'orderList-' + key">
                            <ng-template let-stage let-i2="index" pTemplate="item">
                                <div class="flex justify-items-center p-2 w-full flex-wrap p-grid">
                                    <div style="float: left;" class="p-col-9">
                                        <prizes-card-form-group [(prize)]="record[key][i2]" [showPrize]="true"
                                            [version]="lineItemInputVersion"
                                            fieldsetName="{{ trackKeyLabels[i] }} {{ i2 + 1 }} Rewards"
                                            (onPrizeChange)="onChange(key, $event, i2);">
                                        </prizes-card-form-group>
                                    </div>
                                    <div class="p-col-3">
                                        <div class="flex justify-content-end" style="float: right;">
                                            <button pButton type="button" icon="pi pi-trash"
                                                class="p-button-rounded p-button-danger p-button-sm p-button-text"
                                                (click)="removeStage(key, i2);"></button>
                                        </div>
                                        <div class="p-mt-3 justify-content-center"
                                            style="text-align: left; height: 100%;">
                                            <h4 class="p-mt-6 p-mb-1">{{milestoneLabel}}:</h4>
                                            <p-inputNumber [(ngModel)]="record.stageMilestone[i2]" mode="decimal"
                                                inputId="minmax-buttons" [min]="0"
                                                (onInput)="onChange('stageMilestone', $event, i2)">
                                            </p-inputNumber>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </p-orderList>
                        <div class="flex w-full p-mt-3">
                            <div style="margin: 0 auto;">
                                <button pButton pRipple type="button" label="Add Stage +"
                                    class="p-button-raised p-button-success p-button-text" (click)="addStage(key);"
                                    id="bottom-add-stage-{{key}}">
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'eoTrack'">
                    <eo-rewards-track-dynamic-field [(endlessOfferTrackRecord)]="record"
                        (onRecordChange)="onChange('EOrewards',$event);">
                    </eo-rewards-track-dynamic-field>
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                    <p>{{ trackKeyLabels[i] }}</p>
                </ng-container>
            </ng-container>
        </div>
    </ng-template>
</div>