import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Release Type DTO
 */
export default class ReleaseTypeDTO extends BaseEntityDto
{
    /** 
     * Release fields
    */
    releaseFields?: any = [];

    releaseTemplate?: any;
}