import { Component, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-rich-text-cell-editor',
  templateUrl: './rich-text.component.html',
  styleUrls: ['./rich-text.component.sass']
})
export class RichTextEditorComponent implements ICellEditorAngularComp, AfterViewInit {

  private params: any;
  public value: string;
  public fieldName: string = '';

  agInit(params: any): void {
    this.params = params;
    console.log('richtext params:' ,this.params);
    this.value = this.params.data[this.params.colDef.field];
    this.fieldName = this.params.colDef.headerName;
  }

  ngAfterViewInit() {

  }

  getValue(): any {
    return this.value;
  }

}
