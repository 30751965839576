<p-fieldset legend="Rules" [toggleable]="true" [collapsed]="true">
  <p-toast></p-toast>
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        [label]="label"
        icon="pi pi-plus"
        class="p-button-success"
        styleClass="mr-2"
        (click)="openNew(settingsConstants.entities.readOnlyUser)"
      ></button>
    </ng-template>
    <ng-template pTemplate="right"> </ng-template>
  </p-toolbar>
  <p-dialog
    [(visible)]="rulesDialog"
    [style]="{ width: '450px' }"
    [modal]="true"
    styleClass="p-fluid"
  >
    <ng-template pTemplate="header">
      <h3>Add New Rule</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form *ngIf="fields" [formGroup]="rulesForm" (ngSubmit)="submitNewRule()">
        <div class="p-field">
          <span class="p-float-label">
            <input
              formControlName="name"
              id="float-input"
              type="text"
              pInputText
            />
            <label for="float-input">Name</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <input
              formControlName="desc"
              id="float-input"
              type="text"
              pInputText
            />
            <label for="float-input">Description</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown
              formControlName="entity"
              id="float-input"
              [options]="options['entity']"
              [autoDisplayFirst]="false"
              [filter]="true"
              (onChange)="setRefOptions($event)"
            ></p-dropdown>
            <label for="float-input">Entity</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown
              formControlName="type"
              id="float-input"
              [options]="options['type']"
              [autoDisplayFirst]="false"
              [filter]="true"
            ></p-dropdown>
            <label for="float-input">Rule Type</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown
              formControlName="ref"
              id="float-input"
              [options]="options['ref']"
              [autoDisplayFirst]="false"
              [filter]="true"
            ></p-dropdown>
            <label for="float-input">Ref</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <input
              formControlName="min"
              id="float-input"
              type="text"
              pInputText
            />
            <label for="float-input">Min</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <input
              formControlName="max"
              id="float-input"
              type="text"
              pInputText
            />
            <label for="float-input">Max</label>
          </span>
        </div>
        <div class="p-field">
          <p-toggleButton
            formControlName="enabled"
            [onLabel]="'Enabled'"
            [offLabel]="'Not Enabled'"
            [style]="{ width: '100%' }"
            class="field-item"
          ></p-toggleButton>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        pButton
        pRipple
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        (click)="hideDialog()"
      ></button>
      <button
        pButton
        pRipple
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        (click)="submitNewRule()"
      ></button>
    </ng-template>
  </p-dialog>
  <p-dialog
    [(visible)]="editRulesDialog"
    [style]="{ width: '450px' }"
    [modal]="true"
    styleClass="p-fluid"
  >
    <ng-template pTemplate="header">
      <h3>Editing {{ this.rulesForm.get("name")!.value }}</h3>
    </ng-template>
    <ng-template pTemplate="content">
      <form
        *ngIf="fields"
        [formGroup]="rulesForm"
        (ngSubmit)="updateRulesData()"
      >
        <div class="p-field">
          <span class="p-float-label">
            <input
              formControlName="name"
              id="float-input"
              type="text"
              pInputText
            />
            <label for="float-input">Name</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <input
              formControlName="desc"
              id="float-input"
              type="text"
              pInputText
            />
            <label for="float-input">Description</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown
              formControlName="entity"
              id="float-input"
              [options]="options['entity']"
              [autoDisplayFirst]="false"
              [filter]="true"
              (onChange)="setRefOptions($event)"
            ></p-dropdown>
            <label for="float-input">Entity Type</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown
              formControlName="type"
              id="float-input"
              [options]="options['type']"
              [autoDisplayFirst]="false"
              [filter]="true"
            ></p-dropdown>
            <label for="float-input">Rule Type</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <p-dropdown
              formControlName="ref"
              id="float-input"
              [options]="options['ref']"
              [autoDisplayFirst]="false"
              [filter]="true"
            ></p-dropdown>
            <label for="float-input">Ref</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <input
              formControlName="min"
              id="float-input"
              type="text"
              pInputText
            />
            <label for="float-input">Min</label>
          </span>
        </div>
        <div class="p-field">
          <span class="p-float-label">
            <input
              formControlName="max"
              id="float-input"
              type="text"
              pInputText
            />
            <label for="float-input">Max</label>
          </span>
        </div>
        <div class="p-field">
          <p-toggleButton
            formControlName="enabled"
            [onLabel]="'Enabled'"
            [offLabel]="'Not Enabled'"
            [style]="{ width: '100%' }"
            class="field-item"
          ></p-toggleButton>
        </div>
      </form>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        pButton
        pRipple
        label="Cancel"
        icon="pi pi-times"
        class="p-button-text"
        (click)="hideDialog()"
      ></button>
      <button
        pButton
        pRipple
        label="Save"
        icon="pi pi-check"
        class="p-button-text"
        (click)="updateRulesData()"
      ></button>
    </ng-template>
  </p-dialog>
  <p-table
    [value]="rows"
    styleClass="p-datatable-gridlines"
    responsiveLayout="scroll"
    [(selection)]="selectedRows"
    [rowHover]="true"
    dataKey="id"
  >
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="name">
          Name <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="desc">
          Description <p-sortIcon field="desc"></p-sortIcon>
        </th>
        <th pSortableColumn="entity">
          Entity <p-sortIcon field="entity"></p-sortIcon>
        </th>
        <th pSortableColumn="type">
          Rule Type <p-sortIcon field="type"></p-sortIcon>
        </th>
        <th pSortableColumn="ref">Ref <p-sortIcon field="ref"></p-sortIcon></th>
        <th pSortableColumn="min">Min <p-sortIcon field="min"></p-sortIcon></th>
        <th pSortableColumn="max">Max <p-sortIcon field="max"></p-sortIcon></th>
        <th pSortableColumn="enabled">
          Enabled <p-sortIcon field="enabled"></p-sortIcon>
        </th>
        <th pSortableColumn="createdBy">
          Created By <p-sortIcon field="createdBy"></p-sortIcon>
        </th>
        <th pSortableColumn="createdAt">
          Created At <p-sortIcon field="createdAt"></p-sortIcon>
        </th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rules>
      <tr>
        <td>{{ rules.name }}</td>
        <td>{{ rules.desc }}</td>
        <td>{{ rules.entity }}</td>
        <td>{{ rules.type }}</td>
        <td>{{ rules.ref }}</td>
        <td>{{ rules.min }}</td>
        <td>{{ rules.max }}</td>
        <td>{{ rules.enabled }}</td>
        <td>{{ rules.createdBy ? rules.createdBy.name : "" }}</td>
        <td>{{ rules.createdAt | officeTime }}</td>
        <td>
          <button
            pButton
            pRipple
            icon="pi pi-pencil"
            class="p-button-rounded p-button-info p-mr-2"
            (click)="editRulesModal(rules)"
            label="Edit"
          ></button>
          <button
            pButton
            pRipple
            (click)="deleteConfirm(rules)"
            icon="pi pi-trash"
            class="p-button-rounded p-button-warning p-mr-2"
            label="Delete"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-fieldset>

<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>
