<app-ag-grid-table
  [entity]="entity"
  [columnDefs]="columnDefs"
  [bulkUpdateColDefs]="bulkUpdateColDefs"
  [autopopulateSelect]="autopopulateSelect"
  [columnSelection]="columnSelection"
  [buildParams]="buildParams"
  [tableParams]="tableParams"
>
</app-ag-grid-table>

<string-popup-form (onHide)="hideStringDialog()" [type]="entity" [isVisible]="displayEditDialog" [isEdit]="true" [record]="editRecord">

</string-popup-form>