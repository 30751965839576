import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import ReleaseTypeDTO from '../dtos/ReleaseTypeDTO';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { ReleaseFieldType } from '../dtos/ReleaseTypeField';

@Component({
  selector: 'release-type-custom-field',
  templateUrl: './release-type-custom-field.component.html',
  styleUrls: ['./release-type-custom-field.component.sass']
})
export class ReleaseTypeCustomFieldComponent implements OnInit 
{
  @Input() fields: any;
  @Input() isEdit: boolean = false;
  @Input() miscImageOptions: Array<any>;
  @Input() stages: any;
  @Input() isLoading: boolean;
  @Input() template: any;
  @Output() onChange = new EventEmitter<any>();
  @Output() onChangeTemplate = new EventEmitter<any>();
  options: any = [];
  releaseTemplate: any = "";
  selectedOption: any = null;
  stateOptions: any = [];
  sizeOptions: any = [];
  columnOptions: any = [];
  showFieldTemplate: boolean = false;

  constructor(
    private messageSerivce: MessageService
  ) {}

  /**
   * Release Type Custom Field Component Initialization
   */
  ngOnInit() 
  {
    console.log(this.fields)
    this.options = [
      {label: 'Prizes', value: {name: 'Prizes', controller: 'items', value: ReleaseFieldType.Prizes, layout: true, columnOption: 'leftColumn'}},
      {label: 'Collections', value: {name: 'Collections', controller: 'collections', value: ReleaseFieldType.Collections, layout: true, columnOption: 'leftColumn'}},
      {label: 'Challenges', value: {name: 'Challenges', controller: 'challenges', value:  ReleaseFieldType.Challenges, layout: true, columnOption: 'leftColumn'}},
      {label: 'Colors', value: {name: 'Colors', controller: 'colors', value:  ReleaseFieldType.Colors, layout: true, columnOption: 'leftColumn'}},
      {label: 'Loading Screens', value: {name: 'Loading Screen', value:  ReleaseFieldType.LoadingScreen, layout: true, columnOption: 'leftColumn'}},
      {label: 'Timeline', value: {name: 'Timeline', value:  ReleaseFieldType.Timeline, layout: true, columnOption: 'leftColumn'}},
      {label: 'Announcement Image', value: {name: 'Announcement Image', value:  ReleaseFieldType.AnnouncementImage, layout: true, columnOption: 'leftColumn'}},
      {label: 'Copy', value: {name: 'Copy', value:  ReleaseFieldType.Copy, layout: true, columnOption: 'leftColumn'}},
      {label: 'Name', value: {name: 'Name', value:  ReleaseFieldType.Name, layout: true, columnOption: 'leftColumn'}},
      {label: 'Inboxes', value: {name: 'Inboxes', value:  ReleaseFieldType.Inbox, layout: true, columnOption: 'leftColumn'}},
    ]
    this.releaseTemplate = [
      {label: 'Series', value: 'series'},
      {label: 'Default (None)', value: 'default'},
    ]

    this.stateOptions = [{label: 'Table View', value: {name: 'Table View', value: 'table-view', table: true}}, 
                         {label: 'Thumbnails', value: {name: 'Thumbnails', value: 'thumbnails', table: true}},
                         {label: 'Divider', value: {name: 'Divider', value: 'divider', table: true}}];
    this.sizeOptions = [{label: '25%', value: 3},
                        {label: '33%', value: 4}, 
                        {label: '50%', value: 6},
                        {label: '66%', value: 8},
                        {label: '75%', value: 9},
                        {label: '100%', value: 12}];
    this.columnOptions = [{label: 'Left', value: 'leftColumn'},
                        {label: 'Right', value: 'rightColumn'}];
  }

  /**
   * Handle Record Changes from child component.
   *
   * @param field Field that changed.
   * @param event Event value.
   */
  onRecordChange(event: any)
  {
    this.onChange.emit(event);
  }
  onTemplateChange(event: any) {
    this.onChangeTemplate.emit(this.template);
  }
  /**
   * push field to releaseTypeRecord.releaseFields
   */
  pushField() {
    if (!this.fields.includes(this.selectedOption)) {
      this.fields = [...this.fields, this.selectedOption]
      this.onRecordChange(this.fields)
    } else {
      this.messageSerivce.add({severity: 'error', summary: 'Error', detail: 'Field already added', sticky: true})
    }
   
  }

  removeField(field: any) {
    this.fields.splice(this.fields.indexOf(field), 1);
    this.onRecordChange(this.fields)
  }
  releaseTemplateDialog(event: any) {
    if(event.value == 'series') {
      this.showFieldTemplate = true;
    } else {
      this.onTemplateChange(event.value)
    }
  }

  fillTemplateFields(event: any) {
    switch (this.template) {
      case 'series':
        this.fields = [
          {
            name: "Colors",
            controller: "colors",
            value: 3,
            layout: true,
            columnOption: "leftColumn",
            fieldHeight: "100",
            size: 6
        },
          {
              name: "Challenges",
              controller: "challenges",
              value: 2,
              layout: true,
              columnOption: "leftColumn",
              fieldHeight: "500",
              size: 12
          },
          {
            name: "Prizes",
            controller: "items",
            value: 1,
            layout: true,
            columnOption: "leftColumn",
            fieldHeight: "500",
            size: 12
        },
        {
          name: "Loading Screen",
          value: 5,
          layout: true,
          columnOption: "rightColumn",
          fieldHeight: "500",
          size: 12
      },
        {
          name: "Announcement Image",
          value: 7,
          layout: true,
          columnOption: "rightColumn",
          fieldHeight: "500",
          size: 12
      },
      ]
      this.onRecordChange(this.fields);
      this.onTemplateChange(this.template);
      this.showFieldTemplate = false;
        break;
    
    }
  }

}