import { Component, AfterViewInit, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { TableCommunicationService } from 'src/app/common/ag-grid-table/services/table-communication.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-line-item-cell-editor',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.sass']
})
export class LineItemEditorComponent implements ICellEditorAngularComp, AfterViewInit, OnDestroy {
  public params: any;
  public value: any;
  public originalValue: any;
  public fieldName: string = '';
  public options: any = {};
  public optionLabel: string = 'label';
  public optionValue: string = 'value';
  public suggestions: any = [];
  private subscription: Subscription = new Subscription();

  lineItemForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private agGridToolsService: AgGridToolsService,
    private el: ElementRef, 
    private renderer: Renderer2,
    private tableCommunicationService: TableCommunicationService
  ){
    this.lineItemForm = this.fb.group({
      lineItems: this.fb.array([])
    });

        // Subscribe to line item options from the service
        this.subscription.add(
          this.tableCommunicationService.lineItemOptions$.subscribe(options => {
            if (options) {
              // Initialize options object if not already done
              if (!this.options['t']) this.options['t'] = [];
              if (!this.options['currencies']) this.options['currencies'] = [];
              if (!this.options['titles']) this.options['titles'] = [];
              
              // Only update if not already set from params
              if (!this.params?.resources) this.options['t'] = options.resources;
              if (!this.params?.currencies) this.options['currencies'] = options.currencies;
              
              if (!this.params?.titles) this.options['titles'] = options.titles;
              
              console.log('Received options from service:', this.options);
            }
          })
        );
  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    // Clean up subscriptions
    this.subscription.unsubscribe();
  }

  async agInit(params: any): Promise<void> {
    this.params = params;
    this.fieldName = this.params.colDef.headerName;
    this.value = this.params.value;
    this.originalValue = this.params.value;
    
    // Initialize options object if not already done
    if (!this.options['t']) this.options['t'] = [];
    if (!this.options['currencies']) this.options['currencies'] = [];
    if (!this.options['titles']) this.options['titles'] = [];
    
    // Use params if available (for backward compatibility)
    if (this.params.resources) {
      this.options['t'] = this.params.resources;
      console.log('Resources initialized:', this.options['t']);
      // Log if we have a Currency type in resources
      const currencyType = this.options['t'].find((opt: any) => opt.name === 'Currency');
      console.log('Found Currency type in resources:', currencyType);
    }
    if (this.params.currencies) {
      this.options['currencies'] = this.params.currencies;
      console.log('Currencies initialized:', this.options['currencies']);
    }
    if (this.params.titles) {
      this.options['titles'] = this.params.titles;
      console.log('Titles initialized:', this.options['titles']);
    }

    // Pre-fill the form with existing line items
    if (this.value && this.value.length > 0 && Array.isArray(this.value)) {
      console.log('Pre-filling with existing values:', this.value);
      for (let item of this.value) {
          const lineItemGroup = this.fb.group({
              type: {
                  _id: item.t._id,
                  id: item.t.id,
                  name: item.t.name
              },
              id:  {
                _id: item.id._id,
                id: item.id.id,
                name: item.id.name
              },
              count: item.c
          });
          this.lineItems.push(lineItemGroup);
          console.log('Added line item group:', lineItemGroup.value);
      }
    } else {
      // Add two items to the lineItems array based on the condition
      if (this.params.isChallengePrize) {
        this.addLineItem();
        this.addLineItem();
      } else {
        this.addLineItem();
      }
      console.log('Added default line items. Form state:', this.lineItemForm.value);
    }

    console.log('Final options state:', this.options);
    // Log the condition that controls currency dropdown visibility
    if (this.lineItems.length > 0) {
      const firstItem = this.lineItems.at(0);
      const typeValue = firstItem?.get('type')?.value;
      console.log('First line item type:', typeValue?.name);
      console.log('Would show currency dropdown?', typeValue?.name === 'Currency');
    }
  }

  get lineItems() {
    return (this.lineItemForm.get('lineItems') as FormArray);
  }

  addLineItem() {
    let defaultValues = {
        type: {
          _id: '',
          id: '',
          name: 'Currency'  // Set default type to Currency
        },
        id: {
          _id: '',
          id: '',
          name: ''
        },
        count: 0
    };

    console.log('Initial defaultValues:', defaultValues);

    // Try to find the Currency type in resources and set it if available
    if (this.options['t'] && Array.isArray(this.options['t']) && this.options['t'].length > 0) {
        let currencyTypeOption = this.options['t'].find((opt: any) => opt.name === 'Currency');
        console.log('Found currency type option:', currencyTypeOption);
        if (currencyTypeOption) {
            defaultValues.type = currencyTypeOption;
        }
    }

    const lineItemGroup = this.fb.group(defaultValues);
    console.log('Created line item group with values:', lineItemGroup.value);
    this.lineItems.push(lineItemGroup);
  }

  removeLineItem(index: number) {
    this.lineItems.removeAt(index);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const formGroupElement = this.el.nativeElement.querySelector('.line-item-fields');
      if (formGroupElement) {
        const firstInput = formGroupElement.querySelector('input, select');
        if (firstInput) {
          this.renderer.selectRootElement(firstInput).focus();
        }
      }
    }, 0);
  }

  getValue(): any {
    const formattedValues = [];

    // Iterate over lineItems FormArray
    for (let lineItem of this.lineItems.controls) {
      const lineItemValue = lineItem.value;

      // Construct the desired object structure
      const formattedValue = {
        lineItemType: lineItemValue.type?.name,
        t: lineItemValue.type,
        id: lineItemValue.id,
        c: lineItemValue.count
      };

      formattedValues.push(formattedValue);
    }

    // Wrap the result in a parent object with "costs_ref" key
    return formattedValues;
  }


  async getCurrencyOptions(event: any, index: Number){
    console.log(event, index);
    if(event.value.name){
      switch (event.value.name) {
        case 'Currency':
          break;
        default:
          break;
      }
    }
  }

  async getSuggestedItems(query: any,i: Number){
    this.suggestions = await this.agGridToolsService.getSuggestionsForRef(query, 'items');
  }
}
