import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { StoreManagementService } from '../../services/store-management.service';

@Component({
  selector: 'environment-toggler',
  templateUrl: './environment-toggler.component.html',
  styleUrls: ['./environment-toggler.component.sass'],
  providers: [ConfirmationService]
})
export class EnvironmentTogglerComponent implements OnInit {
  environments: SelectItem[];
  selectedEnvironment: any;
  optionLabel = 'label';
  originalEnvironment: any;

  constructor(
    private storeManagementService: StoreManagementService,
    private confirmationService: ConfirmationService, // Inject the confirmation service
  ) { }

  ngOnInit(): void {
    this.initializeEnvironments();
    this.loadSelectedEnvironment();
    this.originalEnvironment = this.selectedEnvironment;
  }

  initializeEnvironments(): void {
    const currentUrl = window.location.href;

    if (currentUrl.includes('prod.cms')) {
      // Prod CMS: DEV, QA, PROD
      this.environments = [
        { label: 'Development', value: 'dev' },
        { label: 'QA', value: 'qa' },
        { label: 'Production', value: 'prod' }
      ];
    } else if (currentUrl.includes('test.cms')) {
      // Test CMS: TEST
      this.environments = [
        { label: 'Test', value: 'test' }
      ];
    } else {
      // Dev CMS: TEST, DEV, QA, PROD
      this.environments = [
        { label: 'Test', value: 'test' },
        { label: 'Development', value: 'dev' },
        { label: 'QA', value: 'qa' },
        { label: 'Production', value: 'prod' }
      ];
    }
  }

  private loadSelectedEnvironment(): void {
    const savedEnvironment = localStorage.getItem('selectedEnvironment');
    if (savedEnvironment) {
      this.selectedEnvironment = JSON.parse(savedEnvironment);
    }
  }

  // onEnvironmentChange(event: any): void {
  //   localStorage.setItem('selectedEnvironment', JSON.stringify(event.value));
  //   console.log('Environment changed to:', event.value);
  // }

  // onEnvironmentChange(event: any): void {
  //   const confirmRefresh = window.confirm('Changing the environment may require the page to be refreshed. Do you want to continue and refresh the page?');
    
  //   if (confirmRefresh) {
  //     localStorage.setItem('selectedEnvironment', JSON.stringify(event.value));
  //     console.log('Environment changed to:', event.value);
  //     window.location.reload(); // This will refresh the page
  //   } else {
  //     // Handle the case where the user does not want to refresh the page
  //     // You might want to reset the dropdown to the previous value or take some other action
  //       localStorage.setItem('selectedEnvironment', JSON.stringify(event.value));
  //   }
  // }

  onEnvironmentChange(event: any): void {

    // const previousValue = this.selectedEnvironment;

    this.confirmationService.confirm({
      message: 'Changing the store environment may require the page to be refreshed. Do you want to continue and refresh the page?',
      header: 'Confirm Store Environment Change',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        localStorage.setItem('selectedEnvironment', JSON.stringify(event.value));
        console.log('Environment changed to:', event.value);
        window.location.reload(); // This will refresh the page
      },
      reject: () => {
        // Handle the case where the user does not want to refresh the page
        // This might involve resetting the dropdown or taking some other action
        // localStorage.setItem('selectedEnvironment', JSON.stringify(event.value));

        // Reset the dropdown to the previous value
          // ...
        this.selectedEnvironment = this.originalEnvironment;
      }
    });
  }
  
  

  saveSelectedEnvironment(env: string): void {
    // Save the selected environment to localStorage
    localStorage.setItem('selectedEnvironment', env);
  }

  getSeverity(env: string): string {
    return this.storeManagementService.getSeverity(env);
  }

  getIcon(env: string): string {
    return this.storeManagementService.getIcon(env);
  }
}
