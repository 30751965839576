import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildStatusCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/build-status/build-status.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { BuildType } from 'src/app/enums/build-type';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { RichTextEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/rich-text/rich-text.component';
import { parse } from 'path';
import { IncludeExcludeFilterComponent } from 'src/app/common/ag-grid-table/filters/include-exclude-filter.component';

@Component({
  selector: 'app-challenge-table-ag-grid',
  templateUrl: './challenge-table-ag-grid.component.html',
  styleUrls: ['./challenge-table-ag-grid.component.sass']
})
export class ChallengeTableAgGridComponent implements OnInit {

  public entity: string = 'challenges';
  public columnDefs: ColDef[] = [];
  public columnSelection: string;
  public rows: any[] = [];
  public columnsPreProcessing: any = {}
  public autopopulateSelect: string = '';
  public options: any = {};
  public buildParams: any = {};
  public bulkUpdateColDefs: any[] = [];
  public tableParams: any = {};



  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService
  ) {
    this.autopopulateSelect = 'scene_ref image_ref'
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    this.tableParams = {
      tableSearchColumns: ['fileName' ,'location'],
      enableDuplicateFeature: true,
      customActionsLabel: 'Challenges Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'challenges',
      defaultQuery: { 'id': { '$lt': 10000 } },
    }

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService, this.tableParams);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Scene Record',
        field: 'scene_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'scene_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          populate: true,
          select: '_id id name path files buildOutput lastHash asset_versions dimensionX dimensionY dimensionZ radius bg_images thumbnails entityType assetType spawners'
        },
        width: 500,
        autoHeight: true,

      },
      {
        headerName: 'Feed Image Record',
        field: 'image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'image_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          populate: true,
          select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
        },
        floatingFilter: true,
        width: 500,
        autoHeight: true,

      },
      {
        headerName: 'Feed Image',
        field: 'image_ref',
        sortable: false,
        resizable: true,
        width: 200,
        autoHeight: true,
        cellRenderer: ThumbnailCellRendererComponent,
        valueGetter: params => {
          if (params.data.image_ref && params.data.image_ref.thumbnails && params.data.image_ref.thumbnails[1]) {
            return params.data.image_ref.thumbnails[1].path;
          } else {
            return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
          }
        },

      },
      {
        headerName: 'Room Render Image',
        field: 'scene_ref',
        sortable: false,
        resizable: true,
        width: 170,
        autoHeight: true,
        cellRenderer: ThumbnailCellRendererComponent,
        valueGetter: params => {
          if (params.data.scene_ref && params.data.scene_ref.bg_images && params.data.scene_ref.bg_images[1]) {
            return params.data.scene_ref.bg_images[1].path;
          } else {
            return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
          }
        },

      },
      {
        headerName: 'Challenge Type',
        field: 'type_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenge-types',
          hideId: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.type_ref,
          valueFormatter: (params: any) => {

            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Build Status',
        field: 'buildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'scene_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
            addEmptyFilters: true,
            isAggregate: true,
            filterKey: 'scene_ref',
            isBuildStatus: true
        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'Image Build Status',
        field: 'imageBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'image_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
            addEmptyFilters: true,
            isAggregate: true,
            filterKey: 'image_ref',
            isBuildStatus: true
        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'Climate',
        field: 'climate_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'climates',
          hideId: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.climate_ref,
          valueFormatter: (params: any) => {

            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Scene Type',
        field: 'sceneType',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'scene-types',
          hideId: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.scene_type_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Tester',
        field: 'tester_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'users_ref',
          hideId: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.users_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Artist',
        field: 'artist_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'users_ref',
          hideId: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.users_ref,
          valueFormatter: (params: any) => {

            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Scene Path',
        field: 'scene',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Feed Image Path',
        field: 'image',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Restrictions',
        field: 'restrictions_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'restrictions',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.restrictions_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Keywords',
        field: 'keywords_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenge-keywords',
          isArray: true,
          hideId: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        filter: IncludeExcludeFilterComponent,
        // filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.keywords_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          applyButton: true, 
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Scene Promoted Status',
        field: 'isAssetUpToDate',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        valueGetter: (params: any) => {
          const lastHash = params.data.scene_ref?.lastHash;
          const versions = params.data.scene_ref?.asset_versions;
          const lastVersion = versions ? versions[versions.length - 1] : null;
          let response = 'No data';

          if (!lastHash && versions && versions.length > 0){
            return 'Outdated';
          }

          if (lastHash && versions && versions.length > 0) {
            response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
          }
          return response;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            'No data', 'Outdated', 'Up to date'
          ],
          isAggregate: true,
          filterKey: 'scene_ref',
          isAssetUpToDate: true,
        },
        floatingFilter: true,

      },
      {
        headerName: 'Feed Image Promoted Status',
        field: 'isImageUpToDate',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        valueGetter: (params: any) => {
          const lastHash = params.data.image_ref?.lastHash;
          const versions = params.data.image_ref?.image_versions;
          const lastVersion = versions ? versions[versions.length - 1] : null;

          let response = 'No data';
          if (lastHash && versions && versions.length > 0) {
            response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
          }

          return response;
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [ 'No data', 'Outdated', 'Up to date' ],
          isAggregate: true,
          filterKey: 'image_ref',
          isImageUpToDate: true,
        },
        floatingFilter: true,

      },
      {
        headerName: 'Series',
        field: 'series_ref',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {

        },
        floatingFilter: true,

      },
      {
        headerName: 'Bundle Asset (IOS)',
        field: 'bundleAsset',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Asset (AND)',
        field: 'bundleAssetAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle',
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Spawners',
        field: 'spawners',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          entity: this.entity,
          isSpawner: true,
          isPill: true
        },
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
      },
      // not adding content hold
      // not adding sourcing challenge ref
      {
        headerName: 'Spawn Audios',
        field: 'spawnAudios_ref',
        cellRendererParams: {
          entity: 'miscellaneous-build',
          isArray: true,
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.spawnAudios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true
        },
        floatingFilter: true,
        width: 400,
        autoHeight: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audios',
        field: 'loopAudios_ref',
        cellRendererParams: {
          isArray: true
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loopAudios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true
        },
        floatingFilter: true,
        width: 400,
        autoHeight: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Spawn Audio Collections',
        field: 'spawnAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.spawnAudioCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true
        },
        floatingFilter: true,
        autoHeight: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audio Collections',
        field: 'loopAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loopAudioCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true
        },
        floatingFilter: true,
        autoHeight: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Reward(s)',
        field: 'rewards_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
                maxNumConditions: 1
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t',
            select: '_id id name'
          }
        },
        floatingFilter: true,
        width: 500,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
          defaultType: 'currencies',
        },
      },
      {
        headerName: 'Prizes(s)',
        field: 'prizes_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
                maxNumConditions: 1
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t',
            select: '_id id name'
          }
        },
        floatingFilter: true,
        width: 500,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
          defaultType: 'currencies',
          isChallengePrize: true
        },
      },
      {
        headerName: 'F.Size - IOS',
        field: 'ios_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'ios');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - AND',
        field: 'and_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'and');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - MAC',
        field: 'mac_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'mac');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - LIN',
        field: 'lin_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'lin');  // For display
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'F.Size - WIN',
        field: 'win_file',
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return this.fileSizeGetter(params, 'win');
        },
        // sortable: true,
        unSortIcon: true,
        resizable: true,
        // filter: 'agNumberColumnFilter',
        // floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'File Name',
        field: 'fileName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Build Status Date',
        field: 'masterBuildDate',
        sortable: false,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        filterParams: {
          buttons: ['clear'],
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'greaterThan',
            'inRange'
          ],
          filterKey: 'scene_ref',
          isMasterBuildDate: true,
          isAggregate: true,
        },
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          entity: this.entity
          // key: 'scene_ref'
        },
        valueGetter: (params: any) => {
          const source = params.data.scene_ref && params.data.scene_ref.buildOutput ? params.data.scene_ref.buildOutput : null;
          return this.agGridToolsService.getBuildDate(source);
        },
        resizable: true,

      },
      {
        headerName: 'File Type',
        field: 'fileType',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['Original', 'Recolor', 'Duplicate'],
            addEmptyFilters: true,
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Sourced By',
        field: 'sourced_by',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'users'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.users_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          populate: true
        } ,
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Year',
        field: 'year',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            '2021', '2022', '2023','2024','2025',
          ],
          addEmptyFilters: true,
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Description',
        field: 'description',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Short Description',
        field: 'descriptionShort',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Internal Notes',
        field: 'internalNotes',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isHtml: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: RichTextEditorComponent,
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
        cellEditorPopup: true,
      },
      // production notes not added
      {
        headerName: 'Content Hold',
        field: 'contentHold_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenge-content-hold',
          isArray: true,
          notLink: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.contentHold_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          }
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Location',
        field: 'location',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Progression Level',
        field: 'progressionLevel_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'progression-levels'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.progressionLevel_ref,
          valueFormatter: (params: any) => {

            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true
        } ,
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Sponsor',
        field: 'sponsor_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenge-types',
          hideId: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.sponsor_ref,
          valueFormatter: (params: any) => {

            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true
        } ,
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Is Infinite Gardens Excluded',
        field: 'isInfiniteChallengeExcluded',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'True',
          offLabel: 'False',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => {return params.value ? 'True' : 'False'}
        },
        valueGetter: params => {
          if (params.data.isInfiniteChallengeExcluded == null) {
            return false;
          } else {
            return params.data.isInfiniteChallengeExcluded
          }

        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Parent Challenge',
        field: 'parent_challenge',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          valueFormatter: (params: any) => {
            return params.value && !isNaN(params.value) ? parseInt(params.value) : null;
          }
        },
        valueGetter: params => {
          const value = parseInt(params.data.parent_challenge);
          return !isNaN(value) ? value : null;
        },
        floatingFilter: true,
      },
      {
        headerName: 'Collection(s)',
        field: 'collection_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenge-collections',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.collection_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value.id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "id",
          optionLabel: "name",
          objectValue: true
        },
        autoHeight: true,
      },


    ];

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
      {
        name: 'Is Infinite Gardens Excluded', id: 'isInfiniteChallengeExcluded', type: 'boolean', onLabel: 'True', offLabel: 'False'
      },
      {
        name: 'Artist', id: 'artist_ref', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Bundle Image (IOS)', id: 'bundleImage', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
      },
      {
        name: 'Bundle Asset (IOS)', id: 'bundleAsset', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
      },
      {
        name: 'Bundle Image (AND)', id: 'bundleImageAnd', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
      },
      {
        name: 'Bundle Asset (And)', id: 'bundleAssetAnd', type: 'boolean', onLabel: 'Bundle', offLabel: 'Not Bundle'
      },
      {
        name: 'Challenge Type', id: 'type_ref', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Climate', id: 'climate_ref', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Content Hold', id: 'contentHold_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Description', id: 'description', type: 'long-text'
      },
      {
        name: 'Short Description', id: 'descriptionShort', type: 'text'
      },
      {
        name: 'File Name', id: 'fileName', type: 'text'
      },
      {
        name: 'Scene Path', id: 'scene', type: 'text'
      },
      {
        name: 'Feed Image Path', id: 'image', type: 'text'
      },
      {
        name: 'Internal Notes', id: 'internalNotes', type: 'rich-text'
      },
      {
        name: 'File Type', id: 'fileType', type: 'dropdown', isFlatList: true
      },
      {
        name: 'Keywords(s)', id: 'keywords_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Location', id: 'location', type: 'text'
      },
      {
        name: 'Loop Audios', id: 'loopAudios_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Spawn Audios', id: 'spawnAudios_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Loop Audio Collections', id: 'loopAudioCollections_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Spawn Audio Collections', id: 'spawnAudioCollections_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Prizes', id: 'prizes_ref', type: 'line-items', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Progression Level', id: 'progressionLevel_ref', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Restrictions', id: 'restrictions_ref', type: 'multiselect', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Sourced By', id: 'sourced_by', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Tester', id: 'tester_ref', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Year', id: 'year', type: 'dropdown', isFlatList: true
      },
      {
        name: 'Sponsor', id: 'sponsor_ref', type: 'dropdown', optionLabel: 'name',   optionValue: '_id', smartPopulate: true
      },
      {
        name: 'Collection(s)', id: 'collection_ref', type: 'multiselect', optionLabel: 'name', optionValue: '_id', smartPopulate: true
      },
    ];

    this.buildParams = {
      assetKeys: ['scene_ref'],
      assetBuildType: BuildType.Levels,
      renderBuildType: BuildType.Levels,
      imageKeys: ['image_ref'],
      imageBuildType: BuildType.Images,
      assetPopulate: {path: 'scene_ref', select: 'id _id path entityId entityType assetType buildData assetBuildOutput'},
      imagePopulate: {path: 'image_ref', select: 'id _id path entityId entityType assetType buildData assetBuildOutput'}
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }

  async setOptions(){
    const entities = [
      { ref: 'type_ref', entity: 'challenge-types', select: 'name id _id'},
      { ref: 'climate_ref', entity: 'climates', select: 'name id _id' },
      { ref: 'users_ref', entity: 'users',  select: 'name id _id' },
      { ref: 'currencies_ref', entity: 'currencies', select: 'name id _id' },
      { ref: 'resources_ref', entity: 'resources',  select: 'name id _id' },
      { ref: 'titles_ref', entity: 'titles',  select: 'name id _id' },
      { ref: 'collection_ref', entity: 'challenge-collections', select: 'name id _id' },
      { ref: 'contentHold_ref', entity: 'challenge-content-hold', select: 'name id _id' },
      { ref: 'scene_type_ref', entity: 'scene-types', select: 'name id _id' },
      { ref: 'restrictions_ref', entity: 'restrictions', select: 'name id _id' },
      { ref: 'keywords_ref', entity: 'challenge-keywords',  select: 'name id _id' },
      { ref: 'spawnAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [22] }} },
      { ref: 'loopAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [23] }} },
      { ref: 'spawnAudioCollections_ref', entity: 'audio-collections', query: { type: 1 }, select: 'name id _id' },
      { ref: 'loopAudioCollections_ref', entity: 'audio-collections', query:  { type: 2 }, select: 'name id _id' },
      { ref: 'sponsor_ref', entity: 'sponsors', select: 'name id _id' },
    ];

    for (const { ref, entity, select, query, } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }
  }

  fileSizeGetter(params: any, platform: string, getNumber: boolean = false){
    if(params.data.scene_ref && params.data.scene_ref.path && params.data.scene_ref.files ) {
      let fileSizeName = '';
      fileSizeName = `${params.data.scene_ref.path.slice(
        params.data.scene_ref.path.lastIndexOf('/') + 1,
      )}_${platform}`;
      return params.data.scene_ref.files[`${fileSizeName}`] || null
    } else {
      return null
    }
  }
  CustomPathFilter(params: any) {
    const filterText = params.filterText.toLowerCase();
    const value = params.data.Path.toLowerCase();
    return value.includes(filterText);
  }
}
