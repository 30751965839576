import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';
import { LoggerService } from 'src/app/common/services/logger.service';

@Component({
  selector: 'app-link-cell-renderer',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.sass']
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {

  constructor(
    private tableCommunicationService: TableCommunicationService,
    private loggerService: LoggerService
  ) {

  }

  public params: any;
  public refLinkValue: string | null = null;

  timestamp = new Date().getTime();

  agInit(params: any): void {
    this.params = params;
    // console.log('LinkCellRendererComponent params: ', this.params);

    if (!params.onClick && params.isRefLink && this.params.data[this.params.colDef.field]) {
      this.refLinkValue = this.params.data[this.params.colDef.field]
        .filter((linkItem: any) => {
          // if it's a string, include it
          if (typeof linkItem === 'string') return true;

          // if it's an object and has a link property that's not empty/null, include it
          return linkItem && typeof linkItem === 'object' && linkItem.link;
        });
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  getLink() {
    const prefixToEntityMap = {
      'se': 'series',
      'eo': 'endless-offer',
      'pl': 'progression-levels',
      'co': 'collections',
      'cp': 'co-op',
      'mg': 'minigames',
      'sp': 'season-pass',
      'nc': 'nurture-collection',
      'sz': 'seasonal-prizes',
      'cu': 'currencies',
      'it': 'items'
    } as const;

    if (this.params.isReleases) {
      const idPrefixMatch = this.params.data.id.match(/^[a-z]{2}/);
      const idPrefix = idPrefixMatch ? idPrefixMatch[0] as keyof typeof prefixToEntityMap : null;
      if (!idPrefix || !prefixToEntityMap[idPrefix]) {
        this.loggerService.error(`Unknown prefix: ${idPrefix}`);
        return '';
      }


      // All other entities go under releases/[entity-path]/id
      return `releases/${prefixToEntityMap[idPrefix]}/${this.params.data.id.slice(3)}`;
    } else {
      return `${this.params.entity}/${this.params.data.id}`;
    }
  }  


  getHrefValue(linkItem: any): string {
    // Check if linkItem is an object and has a .link property
    if (linkItem && typeof linkItem === 'object' && linkItem.link) {
      return linkItem.link;
    }
    // Otherwise, return the linkItem as is (assuming it's a string)
    return linkItem;
  }

  handleClick(event: Event, value: any) {
    event.preventDefault();
    this.params.onClick(value);
  }
}
