import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EntityHistoryService 
{
  constructor(private http: HttpClient) { }

  /**
   * Retrieves all history of changes for a given entity
   * 
   * @param entityType The type of the entity
   * @param entityId The Id of the entity
   */
  async getEntityHistory(entityType: string, entityId: string): Promise<any> 
  {
    return this.http.get<any>(`/api/${entityType}/history/${entityId}`).toPromise();
  }
}