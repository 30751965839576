import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-sponsor-view-v2',
  templateUrl: './sponsor-view-v2.component.html',
  styleUrls: ['./sponsor-view-v2.component.sass']
})
export class SponsorViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  sponsorRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Sponsor View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Sponsor Data",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "URL", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'url', isRequired: true, label: 'URL' },
          {
            name: "Art Image",
            inputType: DynamicViewType.MiscImageRecordV2, viewMode: 'record', clearField: true, key: 'image_ref', label: 'Art Image',
            optionValue:'_id', filter: true, filterBy:'name,id,path', autoDisplayFirst: false, columnWidth: 12,
            options: { fieldName: 'image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: 3 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
          },
          {
            name: "Feed Image",
            inputType: DynamicViewType.MiscImageRecordV2, viewMode: 'record', clearField: true, key: 'feed_image_ref', label: 'Feed  Image',
            optionValue:'_id', filter: true, filterBy:'name,id,path', autoDisplayFirst: false, columnWidth: 12,
            options: { fieldName: 'feed_image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: 3 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
          },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time',columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time',columnWidth: 6 }
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.sponsorRecord =
    {
      enabled: false
    };
    this.title = "Sponsor";
    this.viewRoute = 'sponsors';
    this.isLoading = false;
  }

  /**
   * Validate Sponsor required field.
   *
   * @param sponsor Sponsor record
   */
  validateSponsor(sponsor: any)
  {
    return sponsor && sponsor.name && sponsor.name.length > 0;
  }
}
