 <!--  Scene Types -->
    <p-fieldset legend="Scene Types Management" [toggleable]="true" [collapsed]="true">
      <div class="card">
        <p-toast></p-toast>
        <p-toolbar styleClass="p-mb-4">
          <ng-template pTemplate="left">
            <button pButton pRipple label="Add Scene Type" icon="pi pi-plus" class="p-button-success"
              styleClass="p-mr-2" (click)="newRecordDialog = true"></button>
          </ng-template>
          <ng-template pTemplate="right"> </ng-template>
        </p-toolbar>
        <!-- add managed list record dialog -->
        <p-dialog [(visible)]="newRecordDialog" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
          <ng-template pTemplate="header">
            <h3>Add Scene Type</h3>
          </ng-template>
          <ng-template pTemplate="content">
            <form *ngIf="newRecordFields" [formGroup]="newRecordForm" (ngSubmit)="submitNewRecord()">
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="name" id="float-input" type="text" pInputText />
                  <label for="float-input">Name</label>
                </span>
              </div>
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="pathName" id="float-input" type="text" pInputText />
                  <label for="float-input">Path Name</label>
                </span>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="enabled" [onLabel]="'Enabled'" [offLabel]="'Not Enabled'"
                  [style]="{ width: '100%' }" class="field-item"></p-toggleButton>
              </div>
            </form>
          </ng-template>
          <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
              (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="submitNewRecord()"></button>
          </ng-template>
        </p-dialog>
        <p-dialog [(visible)]="editRecordDialog" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
          <ng-template pTemplate="header">
            <h3>Editing {{ this.newRecordForm.get("name")!.value }}</h3>
          </ng-template>
          <ng-template pTemplate="content">
            <form *ngIf="fields" [formGroup]="newRecordForm" (ngSubmit)="updateNewRecord()">
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="name" id="float-input" type="text" pInputText />
                  <label for="float-input">Name</label>
                </span>
              </div>
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="pathName" id="float-input" type="text" pInputText />
                  <label for="float-input">Path Name</label>
                </span>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="enabled" [onLabel]="'Enabled'" [offLabel]="'Not Enabled'"
                  [style]="{ width: '100%' }" class="field-item"></p-toggleButton>
              </div>
            </form>
          </ng-template>
          <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
              (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="updateNewRecord()"></button>
          </ng-template>
        </p-dialog>
      </div>
      <p-table [value]="sceneTypesRows" styleClass="p-datatable-gridlines" responsiveLayout="scroll"
        [(selection)]="selectedRows" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              Name <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="pathName">
              Path Name <p-sortIcon field="pathName"></p-sortIcon>
            </th>
            <th pSortableColumn="enabled">
              Enabled <p-sortIcon field="enabled"></p-sortIcon>
            </th>
            <th pSortableColumn="createdBy">
              Created By <p-sortIcon field="createdBy"></p-sortIcon>
            </th>
            <th pSortableColumn="createdAt">
              Created At <p-sortIcon field="createdAt"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>{{ record.name }}</td>
            <td>{{ record.pathName }}</td>
            <td>{{ record.enabled }}</td>
            <td>{{ record.createdBy ? record.createdBy.name : "" }}</td>
            <td>{{ record.createdAt | officeTime }}</td>
            <td>
              <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info p-mr-2"
                (click)="editNewRecordModal(record)" label="Edit"></button>
              <button pButton pRipple (click)="confirmDeleteNewRecordModal(record)" icon="pi pi-trash"
                class="p-button-rounded p-button-warning p-mr-2" label="Delete"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-fieldset>
  
    <!-- Entity Settings -->
    <p-fieldset legend="Entity Types" [toggleable]="true" styleClass="p-my-3" [collapsed]="true">
      <div class="card">
        <p-toast></p-toast>
        <p-toolbar styleClass="p-mb-4">
          <ng-template pTemplate="left">
            <button pButton pRipple label="Add Entity Type" icon="pi pi-plus" class="p-button-success"
              styleClass="p-mr-2" (click)="openNew(settingsConstants.entities.entityType)"></button>
          </ng-template>
          <ng-template pTemplate="right"> </ng-template>
        </p-toolbar>
        <!-- ADD Entity Type Settings Record (dialog) -->
        <p-dialog [(visible)]="newRecordDialog_Entity" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
          <ng-template pTemplate="header">
            <h3>Add Entity Type Settings</h3>
          </ng-template>
          <ng-template pTemplate="content">
            <form [formGroup]="newRecordForm_Entity" (ngSubmit)="submitNewRecord_Entity()">
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="name" id="float-input" type="text" pInputText />
                  <label for="float-input">Name</label>
                </span>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="showOnAssetBundleOnly"
                  [onLabel]="'Show on Asset Bundle Table - Enabled'"
                  [offLabel]="'Show on Asset Bundle Table - Not Enabled'" [style]="{ width: '100%' }"
                  class="field-item"></p-toggleButton>
              </div>
            </form>
          </ng-template>
          <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
              (click)="hideDialog('entity')"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="submitNewRecord_Entity()"></button>
          </ng-template>
        </p-dialog>
        <!-- EDIT Imagery Settings Record (dialog) -->
        <p-dialog [(visible)]="editRecordDialog_Entity" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
          <ng-template pTemplate="header">
            <h3>Editing {{ newRecordForm_Entity.get('name')?.value }}</h3>
          </ng-template>
          <ng-template pTemplate="content">
            <form [formGroup]="newRecordForm_Entity" (ngSubmit)="updateNewRecord_Entity()">
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="name" id="float-input" type="text" pInputText />
                  <label for="float-input">Name</label>
                </span>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="showOnAssetBundleOnly"
                  [onLabel]="'Show on Asset Bundle Table - Enabled'"
                  [offLabel]="'Show on Asset Bundle Table - Not Enabled'" [style]="{ width: '100%' }"
                  class="field-item"></p-toggleButton>
              </div>
            </form>
          </ng-template>
          <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
              (click)="hideDialog('entity')"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="updateNewRecord_Entity()"></button>
          </ng-template>
        </p-dialog>
      </div>
      <p-table [value]="entityTypeSettingsRows" styleClass="p-datatable-gridlines" responsiveLayout="scroll"
        [(selection)]="selectedRows_Entity" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              Name <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="enabled">
              Enabled <p-sortIcon field="enabled"></p-sortIcon>
            </th>
            <th pSortableColumn="showOnAssetBundleOnly">
              Show on Asset Bundle Table <p-sortIcon field="showOnAssetBundleOnly"></p-sortIcon>
            </th>
            <th pSortableColumn="createdBy">
              Created By <p-sortIcon field="createdBy"></p-sortIcon>
            </th>
            <th pSortableColumn="createdAt">
              Created At <p-sortIcon field="createdAt"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>{{ record.name }}</td>
            <td>{{ record.enabled }}</td>
            <td>{{ record.showOnAssetBundleOnly }}</td>
            <td>{{ record.createdBy ? record.createdBy.name : "" }}</td>
            <td>{{ record.createdAt | officeTime }}</td>
            <td>
              <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info p-mr-2"
                (click)="editNewRecordModal_Entity(record)" label="Edit"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-fieldset>
    <!-- Imagery Settings  -->
    <p-fieldset legend="Asset Types" [toggleable]="true" styleClass="p-my-3" [collapsed]="true">
      <div class="card">
        <p-toast></p-toast>
        <p-toolbar styleClass="p-mb-4">
          <ng-template pTemplate="left">
            <button pButton pRipple label="Add Imagery/Bundle Type Settings" icon="pi pi-plus" class="p-button-success"
              styleClass="p-mr-2" (click)="openNew(settingsConstants.entities.imagery)"></button>
          </ng-template>
          <ng-template pTemplate="right"> </ng-template>
        </p-toolbar>
        <!-- ADD Imagery Settings Record (dialog) -->
        <p-dialog [(visible)]="newRecordDialog_Imagery" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
          <ng-template pTemplate="header">
            <h3>Add Imagery/Bundle Type Settings</h3>
          </ng-template>
          <ng-template pTemplate="content">
            <form [formGroup]="newRecordForm_Imagery" (ngSubmit)="submitNewRecord_Imagery()">
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="name" id="float-input" type="text" pInputText />
                  <label for="float-input">Name</label>
                </span>
              </div>
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="path" id="float-input" type="text" pInputText />
                  <label for="float-input">Path</label>
                </span>
              </div>
              <div class="p-field">
                <span class="p-float-label">
                  <p-dropdown formControlName="entityType" id="float-input" [options]="entityTypeSettings"
                    optionLabel="name" optionValue="name"></p-dropdown>
                  <label for="float-input">Entity Type</label>
                </span>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="enabled" [onLabel]="'Enabled'" [offLabel]="'Not Enabled'"
                  [style]="{ width: '100%' }" class="field-item"></p-toggleButton>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="showOnImageTypeList" [onLabel]="'Show on Image Bundle Table - Enabled'"
                  [offLabel]="'Show on Image Bundle Table - Not Enabled'" [style]="{ width: '100%' }"
                  class="field-item"></p-toggleButton>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="showOnAssetBundleOnly"
                  [onLabel]="'Show on Asset Bundle Table - Enabled'"
                  [offLabel]="'Show on Asset Bundle Table - Not Enabled'" [style]="{ width: '100%' }"
                  class="field-item"></p-toggleButton>
              </div>
            </form>
          </ng-template>
          <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
              (click)="hideDialog('imagery')"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="submitNewRecord_Imagery()"></button>
          </ng-template>
        </p-dialog>
        <!-- EDIT Imagery Settings Record (dialog) -->
        <p-dialog [(visible)]="editRecordDialog_Imagery" [style]="{ width: '450px' }" [modal]="true"
          styleClass="p-fluid">
          <ng-template pTemplate="header">
            <h3>Editing {{ this.newRecordForm_Imagery.get("name")!.value }}</h3>
          </ng-template>
          <ng-template pTemplate="content">
            <form *ngIf="fields" [formGroup]="newRecordForm_Imagery" (ngSubmit)="updateNewRecord_Imagery()">
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="name" id="float-input" type="text" pInputText />
                  <label for="float-input">Name</label>
                </span>
              </div>
              <div class="p-field">
                <span class="p-float-label">
                  <input formControlName="path" id="float-input" type="text" pInputText />
                  <label for="float-input">Path Name</label>
                </span>
              </div>
              <div class="p-field">
                <span class="p-float-label">
                  <p-dropdown formControlName="entityType" id="float-input" [options]="entityTypeSettings"
                    optionLabel="name" optionValue="name"></p-dropdown>
                  <label for="float-input">Entity Type</label>
                </span>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="enabled" [onLabel]="'Enabled'" [offLabel]="'Not Enabled'"
                  [style]="{ width: '100%' }" class="field-item"></p-toggleButton>
              </div>
              <div class="p-field">
                <p-toggleButton formControlName="showOnImageTypeList" [onLabel]="'Show on Image Bundle Table - Enabled'"
                  [offLabel]="'Show on Image Bundle Table - Not Enabled'" [style]="{ width: '100%' }"
                  class="field-item"></p-toggleButton>
              </div>
              <div class="p-field">
                <div class="p-field">
                  <p-toggleButton formControlName="showOnAssetBundleOnly"
                    [onLabel]="'Show on Asset Bundle Table - Enabled'"
                    [offLabel]="'Show on Asset Bundle Table - Not Enabled'" [style]="{ width: '100%' }"
                    class="field-item"></p-toggleButton>
                </div>
              </div>
            </form>
          </ng-template>
          <ng-template pTemplate="footer">
            <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
              (click)="hideDialog()"></button>
            <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
              (click)="updateNewRecord_Imagery()"></button>
          </ng-template>
        </p-dialog>
      </div>
      <p-table [value]="imagerySettingsRows" styleClass="p-datatable-gridlines" responsiveLayout="scroll"
        [(selection)]="selectedRows_Imagery" [rowHover]="true" dataKey="id">
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="name">
              Name <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th pSortableColumn="path">
              Path <p-sortIcon field="path"></p-sortIcon>
            </th>
            <th pSortableColumn="entityType">
              Entity Type <p-sortIcon field="entityType"></p-sortIcon>
            </th>
            <th pSortableColumn="enabled">
              Enabled <p-sortIcon field="enabled"></p-sortIcon>
            </th>
            <th pSortableColumn="showOnImageTypeList">
              Show on Image Bundle Table <p-sortIcon field="showOnImageTypeList"></p-sortIcon>
            </th>
            <th pSortableColumn="showOnAssetBundleOnly">
              Show on Asset Bundle Table <p-sortIcon field="showOnAssetBundleOnly"></p-sortIcon>
            </th>
            <th pSortableColumn="createdBy">
              Created By <p-sortIcon field="createdBy"></p-sortIcon>
            </th>
            <th pSortableColumn="createdAt">
              Created At <p-sortIcon field="createdAt"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-record>
          <tr>
            <td>{{ record.name }}</td>
            <td>{{ record.path }}</td>
            <td>{{ record.entityType }}</td>
            <td>{{ record.enabled }}</td>
            <td>{{ record.showOnImageTypeList }}</td>
            <td>{{ record.showOnAssetBundleOnly }}</td>
            <td>{{ record.createdBy ? record.createdBy.name : "" }}</td>
            <td>{{ record.createdAt | officeTime }}</td>
            <td>
              <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-info p-mr-2"
                (click)="editNewRecordModal_Imagery(record)" label="Edit"></button>
              <button pButton pRipple (click)="confirmDeleteNewRecordModal_Imagery(record)" icon="pi pi-trash"
                class="p-button-rounded p-button-warning p-mr-2" label="Delete"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-fieldset>
    <!-- Links & Destinations -->
    <p-fieldset legend="Links & Destinations" [toggleable]="true" styleClass="p-my-3" [collapsed]="true">
      <div class="card">
        <p-toolbar styleClass="p-mb-4">
          <ng-template pTemplate="left">
            <button pButton pRipple 
              label="Manage Links & Destinations" 
              icon="pi pi-external-link" 
              class="p-button-outlined"
              (click)="navigateToLinkDestination()">
            </button>
          </ng-template>
        </p-toolbar>
      </div>
    </p-fieldset>
    <!-- Tags -->
    <br />
    <tags-managed-list></tags-managed-list>
    <br />
    <!-- Content Hold -->
    <content-hold-managed-list></content-hold-managed-list>
    <br />
    <!-- Challenges Content Hold -->
    <challenge-content-hold-managed-list></challenge-content-hold-managed-list>
