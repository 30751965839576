import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-duplication-dialog',
  templateUrl: './duplication-dialog.component.html',
  styleUrls: ['./duplication-dialog.component.sass']
})
export class DuplicationDialogComponent {
  @Input() visible: boolean = false;
  @Input() header: string = 'Are you sure you want to duplicate ?';
  @Input() message: string = 'This action will duplicate the current challenge';
  @Input() subMessage: string = 'Do you wish to continue?';
  @Input() flaggedDoNotUse: boolean = false;

  @Output() confirmAction = new EventEmitter<void>();

  confirm() {
    this.confirmAction.emit();
  }
}

