import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MultiSelect } from 'primeng/multiselect';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-multiselect-cell-editor',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.sass']
})
export class MultiselectEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  @ViewChild('multiselectComponent') multiSelect: MultiSelect;

  private params: any;
  public value: any[] = [];
  public originalValue: any[] = [];
  public fieldName: string = '';
  public options: any[] = [];
  private optionsMap: { [key: string]: any } = {};
  public optionLabel: string = 'label';
  public optionValue: string = 'value';

  agInit(params: any): void {
    this.params = params;
    // console.log('multiselect params:' ,this.params);

    // GETTING LABEL
    this.fieldName = this.params.colDef.headerName;

    // GETTING OPTIONS
    if (this.params.isFlatList) {
      this.options = this.params.colDef.filterParams.values
        .filter((item: any) => item !== '(EMPTY)' && item !== '(NOT EMPTY)')
        .map((item: any) => {
          return { label: item, value: item };
        });
    } else {
      this.options = this.params.colDef.filterParams.values.sort((item: any) => (Number.isInteger(item.id) && item.id > 0) || item);
      this.optionLabel = this.params.optionLabel;
      this.optionValue = this.params.optionValue;
    }

    // GETTING VALUE
    if (this.params.objectValue && this.params.optionValue && this.options) {
      this.options.forEach(option => {
        this.optionsMap[option[this.optionValue]] = option;
      });
      if (this.params.value) {
        this.value = this.params.value.map((obj: any) => obj[this.optionValue]);
        this.originalValue = this.params.value.map((obj: any) => obj[this.optionValue]);
      }
    } else {
      this.value = this.params.value;
      this.originalValue = this.params.value;
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.multiSelect) {
        this.multiSelect.containerViewChild.nativeElement.click();
        this.multiSelect.el.nativeElement.addEventListener('keydown', this.handleKeyDown.bind(this), true);
      }
    }, 0);
  }

  getValue(): any {
    if (this.params.objectValue && this.params.optionValue) {
      // If value is an array, map through it and retrieve each object from the optionsMap
      if (Array.isArray(this.value)) {
        return this.value.map(val => this.optionsMap[val]).filter(Boolean);
        // The filter(Boolean) is to remove any null/undefined values in case an ID doesn't match any option
      } else {
        // For single select, return the object
        return this.optionsMap[this.value] || null;
      }
    } else {
      return this.value;
    }
  }

  isPopup(): boolean {
    return false;
  }

  clearValue() {
    this.value = [];
  }

  setOriginalValue() {
    this.value = this.originalValue;
  }

  handleKeyDown(event: KeyboardEvent): void {
    console.log('Key pressed:', event.key);
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      if (this.multiSelect) {
        this.multiSelect.hide();
      }
    }
  }
}
