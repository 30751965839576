import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';

@Component({
  selector: 'app-localization-configuration-view',
  templateUrl: './localization-configuration-view.component.html',
  styleUrls: ['./localization-configuration-view.component.sass']
})
export class LocalizationConfigurationViewComponent extends BaseDynamicViewComponent implements OnInit
{
  localizationConfigRecord: BaseEntityDto = new BaseEntityDto();

  constructor()
  {
    super();
  }

  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Localization Configuration  Data",
        fields:
        [
          { name: "gridViewId", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'gridViewId', isRequired: true, label: 'Grid View ID' },
          { name: "modelName", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'modelName', label: 'Model Name',columnWidth: 6 },
          { name: "localizableFields", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.NestedList], clearField: true, key: 'localizableFields', label: 'Localizable Fields',columnWidth: 6 },
          { name: "migrated", inputType: DynamicViewType.Boolean, clearField: true, key: 'migrated', label: 'Migrated',columnWidth: 6 }
        ],
        type: DynamicCardType.Card
      }
    ]
    this.localizationConfigRecord =
    {
      enabled: false
    };
    this.title = "localization configuration";
    this.viewRoute = 'localization-configuration';
    this.isLoading = false;
  }
}

