import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CellRendererService {

  constructor() { }

  public thumbnail(params: any): string {
    return '<img src="' + params.value + '" style="height: 100px; width: auto;" />';
  }

  public nameLink(params: any): string {
    const id = params.data.id;
    const name = params.value; // The value of the 'name' field
    const entity = params.entity; // Access the extra parameter
    const url = `/${entity}/${id}/${name}`;
    return `<a href="${url}" target="_blank">${name}</a>`;
  }

  // ... more cell renderer functions as needed

}
