import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Season Pass DTO
 */
export default class SeasonPassDTO extends BaseEntityDto
{
    /**
     * List of challenge submission values.
     *
     * Each stage corresponds to the track’s rewards.
     */
    stageMilestone: Array<number> = [];
    /**
     * Rewards Track reference
     */
    free: any;
    /**
     * Rewards Track reference
     */
    paid: any;
    /**
     * Rewards Track reference
     */
    friends: any;
    /**
     * Misc. Record reference
     */
    bannerImage: any;
    /**
     * Free object
     */
    freeObject: any;
    /**
     * Paid Object
     */
    paidObject: any;

    [key: string]: any; // Index signature
}
