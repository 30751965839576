<div class="p-flex" style="min-width: 200px;">
  <div class="card flex justify-content-center align-items-center p-p-3">
    <!-- PrimeNG Color Picker -->
    <p-colorPicker [(ngModel)]="this.value"></p-colorPicker>

    <!-- Input Field with PrimeNG and Custom Styles -->
    <input
        #colorInput
        [(ngModel)]="this.value"
        [placeholder]="this.fieldName"
        [id]="this.fieldKey"
        [name]="this.fieldKey"
        maxlength="9"
        class="p-inputtext p-fluid"
        style="width: 55%; margin-left: 3px;"
    />
  </div>
</div>
