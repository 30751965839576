import { Component } from '@angular/core';
import { IStatusPanel, IStatusPanelParams } from 'ag-grid-community';
import { TableCommunicationService } from '../../services/table-communication.service';
import { ChangeDetectorRef } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-main-status-panel',
    templateUrl: './main-status-panel.component.html',
    styleUrls: ['./main-status-panel.component.css']
})
export class MainStatusPanelComponent implements IStatusPanel {
   private debounceTimer: any;

    constructor(
      private tableCommunicationService: TableCommunicationService,
      private cdRef: ChangeDetectorRef,
      private dataService: DataService
    ){

    }

    public params: IStatusPanelParams;
    public entity: string|null = null;
    public startRow: number = 0;
    public endRow: number = 0;
    public totalCount: number = 0;
    private settingName: string = 'defaultPageSize'
    private currentPageDataSubscription: Subscription;


    public pageSizes = [
      { label: '50 rows', value: 50 },
      { label: '100 rows', value: 100 },
      { label: '500 rows', value: 500 },
      { label: '1000 rows', value: 1000 }
    ];
    public selectedPageSize: number = 50;  // Default page size

    ngOnInit() {
    this.tableCommunicationService.rowCount$.subscribe((count: number) => {
      this.totalCount = count;
      // Trigger change detection if needed
      this.cdRef.detectChanges();
    });

    this.currentPageDataSubscription = this.tableCommunicationService.currentPageData$.subscribe(data => {
      // Handle the received current page data
      // console.log(data);
    });
    this.setDefaultPageSize();
  }


  agInit(params: IStatusPanelParams): void {
    this.params = params;
    // console.log('status panel params: ', this.params);

    // Adding event listener to capture changes in pagination
    this.params.api.addEventListener('paginationChanged', this.updatePageDetails.bind(this));

    // Initial update
    this.updatePageDetails();
  }

  updatePageDetails(): void {
    this.startRow = this.params.api.paginationGetCurrentPage() * this.params.api.paginationGetPageSize() + 1;
    this.endRow = Math.min(this.startRow + this.params.api.paginationGetPageSize() - 1, this.totalCount);
  }

  onPageSizeChange(event: any): void {
    const newSize = Number(event.value);
    this.params.api.paginationSetPageSize(newSize);
    this.saveDefaultPageSize(newSize);
  }

  debounce(func: Function, delay: number): Function {
    let debounceTimer: any;
    return (...args: any) => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(this, args), delay);
    };
  }


  // Pagination controls
  goToFirstPage(): void {
    this.params.api.paginationGoToFirstPage();
  }

  goToPrevPage(): void {
    this.params.api.paginationGoToPreviousPage();
  }

  goToNextPage(): void {
    this.params.api.paginationGoToNextPage();
    this.tableCommunicationService.triggerCurrentPageDataUpdate();
  }

  goToNextPageDebounced(): void {
    clearTimeout(this.debounceTimer);
    this.debounceTimer = setTimeout(() => {
      this.goToNextPage();
    }, 300); // Adjust the delay as needed
  }

  goToLastPage(): void {
    this.params.api.paginationGoToLastPage();
  }

  async saveDefaultPageSize(newSize: number) {
    const entity = (this.params as any).entity as string;
    const tableName = 'ag-grid-'+entity;
    try {
      let response: any = await this.dataService.updateUserSettings(tableName, this.settingName, newSize);
    } catch (error) {
      console.error("Failed save default page size for "+tableName, error);
    }
  }

  async setDefaultPageSize() {

    const entity = (this.params as any).entity as string;
    const tableName = 'ag-grid-'+entity;
    try {

      let defaultPageSize = await this.dataService.getUserSettings( tableName, this.settingName);
      if(defaultPageSize.length>0 ){
        this.selectedPageSize = defaultPageSize[0]?.settings;
        this.params.api.paginationSetPageSize(this.selectedPageSize);
      }
    } catch (error) {
      console.error("Failed fetch default page size for "+tableName, error);
    }
  }
}
