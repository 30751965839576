import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import GlobalSearchResults from '../../dtos/GlobalSearchResults';
import { GlobalSearchService } from '../../services/global-search.service';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.sass']
})
export class GlobalSearchComponent implements OnInit {
  globalSearchQueryValue: string;
  options: GlobalSearchResults = new GlobalSearchResults();
  currentTimeout: any;
  globalSearchSearchValue: string;

  constructor(
    private globalSearchService: GlobalSearchService,
    private router: Router,
    private messageService: MessageService,
    public authService: AuthService,
  ) { }

  /**
   * Global Search Component Initialization
   */
  async ngOnInit() {
    this.options.GroupedEntities = [];
  }

  /**
   * Perform a search query on multiple entities.
   * 
   * @param event The search query value
   */
  async globalSearch(event: any) {
    this.globalSearchSearchValue = event.query;

    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    this.currentTimeout = setTimeout(async () => {
      try {
        let response = await this.globalSearchService.globalSearch(event.query);
        if (response && response.GroupedEntities) {
          this.options.GroupedEntities = response.GroupedEntities;
        }
      } catch (error: any) {
        const errorMessage = error.status === 403 ? (error.error?.error || error.statusText) : 'An error occurred Searching.';
        this.messageService.add({
          severity: 'error',
          summary: 'Error Searching',
          detail: errorMessage,
        });
      }
      this.currentTimeout = null;
    }, 500);
  }

  /**
   * Handle Slected option from dropdownd
   * 
   * @param selectedValue Selected entity
   */
  onSelectedEntity(selectedValue: any) {
    if (selectedValue && selectedValue.apiControllerRoute && selectedValue.id) {
      window.open(`${selectedValue.apiControllerRoute}/${selectedValue.id}`, "_blank");
    }
  }

  /**
   * Handle Enter key press.
   * Redirects to advance search page.
   * 
   * @param event Browser event
   */
  onEnterPress(event: any) {
    if (event.key == 'Enter') {
      this.goToAdvanceSearch();
    }
  }

  /**
   * Handle clicks on search button.
   * Redirects to advance search page.
   */
  goToAdvanceSearch() {
    // Redirect to advance search page.
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(
        ['/advanced-search'],
        { queryParams: { searchQuery: this.globalSearchSearchValue } }
      );
    });
  }
}
