<div class="p-ml-1">
  <h2 style="display: flex; justify-content: space-between; align-items: center;">
    Grid View Settings
    <button type="button" class="p-button p-button-sm p-mr-2" (click)="refreshComponent()">Apply</button>
  </h2>

  <span class="p-float-label p-mt-5 p-mb-3">
    <p-dropdown [options]="optionsThumbnail" [(ngModel)]="variables.headerField.id" (onSelect)="saveCustomPreset()" [showClear]="true"
      placeholder="Select a Thumbnail"></p-dropdown>
  </span>
  <span class="p-float-label p-mt-5 p-mb-3">
    <p-autoComplete
      [multiple]="true"
      [(ngModel)]="selectedItem"
      [suggestions]="filteredSuggestions"
      field="headerName"
      (completeMethod)="searchStrings($event)"
      (onSelect)="addField($event)"
    ></p-autoComplete>
    <label for="float-label">Add Fields To Grid View</label>
  </span>

  <p-orderList
    [value]="variables.bodyFields"
    [dragdrop]="true"
    class="p-mb-3"
    [listStyle]="{'height':'300px', 'min-width': '290px'}"
    header="Field Name | Width | Enabled"
    (onReorder)="saveCustomPreset()"
  >
    <ng-template let-variable let-i="index" pTemplate="item">
      <div class="p-grid">
        <span class="p-col-3">{{ variable.name.headerName }}</span>
        <div class="p-col-4">
          <p-dropdown
            [(ngModel)]="variable.size"
            class="field-item"
            [options]="[3, 6, 9, 12]"
            appendTo="body"
            (onChange)="saveCustomPreset()"
          ></p-dropdown>
        </div>
        <div class="p-col-3">
          <p-inputSwitch
            [(ngModel)]="variable.enabled"
            (onChange)="saveCustomPreset()"
          ></p-inputSwitch>
        </div>
        <div class="p-col-2">
          <p-button icon="pi pi-times" [rounded]="true" [text]="true" severity="danger" (click)="deleteItem(i)"></p-button>
        </div>
      </div>
    </ng-template>
  </p-orderList>
</div>
