import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Currency DTO
 */
export default class CurrencyDto extends BaseEntityDto
{
    /**
     * Name of the currency
     */
    public name: string;
    /**
     * Plural name of currency
     */
    public namePlural?: string;
    /**
     * Miscellaneous Image Reference
     */
    public image_ref?: any;
    /**
     * Icon path
     */
    public icon?: string;
    /**
     * Image Small path
     */
    public imageSmall_ref?: any;
    /**
     * Image Medium path
     */
    public imageMedium_ref?: any;
    /**
     * Image Large path
     */
    public imageLarge_ref?: any;
    /**
     * Image Challenge Priza button path
     */
    public imageChallengePrizeButton_ref?: any;
    /**
     * Thumbnail path
     */
    public thumbnail_ref?: any;
    /**
     * Children ref list
     */
    public children_ref?: Array<any> = [];
    /**
     * Children value
     */
    public children?: number;
    /**
     * List of Currency Images objects. [{ name: '', path: '', buildStatus: '' }]
     */
    public currencyImages?: Array<any> = [];
    /**
     * Image Build data object that will hold build record ids for each platform
     */
    public imageBuildData?: any;
    /**
     * Image Small Build data object that will hold build record ids for each platform
     */
    public imageSmallBuildData?: any;
    /**
     * Image Medium Build data object that will hold build record ids for each platform
     */
    public imageMediumBuildData?: any;
    /**
     * Image Large Build data object that will hold build record ids for each platform
     */
    public imageLargeBuildData?: any;
    /**
     * Image Challenge Prize Button Build data object that will hold build record ids for each platform
     */
    public imageChallengePrizeButtonBuildData?: any;
    /**
     * Image Thumbnail Build data object that will hold build record ids for each platform
     */
    public imageThumbnailBuildData?: any;
    /**
     * Currency year value
     */
    public year?: string | null
    /**
     * Image Small Amount value
     */
    public imageSmallAmount: number;
    /**
     * Image Medium Amount value
     */
    public imageMediumAmount: number;
    /**
     * Image Large Amount value
     */
    public imageLargeAmount: number;
    /**
     * Current user data
     */
    public userData: any;

    public cap: number;
    public softCapped: boolean;
    public hardCapped: boolean;
  }
