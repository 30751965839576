import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';

@Component({
  selector: 'app-inbox-message-table-ag-grid',
  templateUrl: './inbox-message-table-ag-grid.component.html',
  styleUrls: ['./inbox-message-table-ag-grid.component.sass']
})
export class InboxMessageTableAgGridComponent implements OnInit {

  public entity: string = 'inbox-messages';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};
  private ttvOptions: any = {};


  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = 'image_ref';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Type',
        field: 'type',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['General', 'Gift', 'Sale'],
            addEmptyFilters: true,
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Layout',
        field: 'layout',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['MessageLayout'],
            addEmptyFilters: true,
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Product Id',
        field: 'productId',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Body',
        field: 'body',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Headline',
        field: 'headline',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Misc. Image Record',
        field: 'image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'image_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.image_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Link - Destination',
        field: 'link_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'link-destination',
          isLinkDestination: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        width: 500,
        autoHeight: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.link_ref,
          valueFormatter: (params: any) => {
            console.log('link params',params.value)
            return `${params.value.linkText} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          }
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "linkText",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Title',
        field: 'title',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'TTL',
        field: 'timeToLive',
        cellRenderer: TextCellRendererComponent,
        valueGetter: params => {
          const timeToLiveOptions = [
            {
              label: 'Hours',
              items: [
                { label: '1 hour', value: 1 },
                { label: '2 hours', value: 2 },
                { label: '3 hours', value: 3 },
                { label: '4 hours', value: 4 },
                { label: '5 hours', value: 5 },
                { label: '6 hours', value: 6 },
                { label: '7 hours', value: 7 },
                { label: '8 hours', value: 8 },
                { label: '9 hours', value: 9 },
                { label: '10 hours', value: 10 },
                { label: '11 hours', value: 11 },
                { label: '12 hours', value: 12 },
                { label: '13 hours', value: 13 },
                { label: '14 hours', value: 14 },
                { label: '15 hours', value: 15 },
                { label: '16 hours', value: 16 },
                { label: '17 hours', value: 17 },
                { label: '18 hours', value: 18 },
                { label: '19 hours', value: 19 },
                { label: '20 hours', value: 20 },
                { label: '21 hours', value: 21 },
                { label: '22 hours', value: 22 },
                { label: '23 hours', value: 23 },
              ]
            },
            {
              label: 'Days',
              items: [
                { label: '1 day / 24 hours', value: 24 },
                { label: '2 days / 48 hours', value: 48 },
                { label: '3 days / 72 hours', value: 72 },
                { label: '4 days / 96 hours', value: 96 },
                { label: '5 days / 120 hours', value: 120 },
                { label: '6 days / 144 hours', value: 144 },
                { label: '7 days / 168 hours', value: 168 },
                { label: '8 days / 192 hours', value: 192 },
                { label: '9 days / 216 hours', value: 216 },
                { label: '10 days / 240 hours', value: 240 },
                { label: '11 days / 264 hours', value: 264 },
                { label: '12 days / 288 hours', value: 288 },
                { label: '13 days / 312 hours', value: 312 },
                { label: '14 days / 336 hours', value: 336 },
                { label: '15 days / 360 hours', value: 360 },
                { label: '16 days / 384 hours', value: 384 },
                { label: '17 days / 408 hours', value: 408 },
                { label: '18 days / 432 hours', value: 432 },
                { label: '19 days / 456 hours', value: 456 },
                { label: '20 days / 480 hours', value: 480 },
                { label: '21 days / 504 hours', value: 504 },
              ]
            }
          ];

          for (const option of timeToLiveOptions) {
            for (const item of option.items) {
              if (item.value === params.data.timeToLive) {
                return `${item.label}`;
              }
            }
          }
          return params.data.timeToLive; // return the value as is if no label is found
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'Reward(s)',
        field: 'rewards_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        width: 800,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Use Segment',
        field: 'useSegment',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Buy CTA',
        field: 'showBuyCta',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Minimum User Level',
        field: 'minimumUserLevel',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          filterKey: 'priority',
        },
        floatingFilter: true,

        autoHeight: true,
        type: 'editableColumn',
        cellEditor: "agNumberCellEditor",
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      imageKeys: ['image_ref'],
      imageBuildType: BuildType.Images,
    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'inboxmessages',
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions(){

    const entities = [
      {
        ref: 'currencies_ref', entity: 'currencies',
      },
      {
        ref: 'challengeType', entity: 'challenge-types',
      },
      {
        ref: 'challengeClimate', entity: 'climates',
      },
      {
        ref: 'itemCategory', entity: 'categories',
      },
      {
        ref: 'itemType', entity: 'item-types',
      },
      {
        ref: 'resources_ref', entity: 'resources',
      },
      {
        ref: 'titles_ref', entity: 'titles',
      },
      {
        ref: 'link_ref', entity: 'link-destination', select: 'id _id linkText',
      },
      {
        ref: 'image_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { assetType: AssetTypes.Inbox }
      }
    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }

    this.ttvOptions = {
      timeToLive: [
        {
          label: 'Hours',
          value: 'Hours',
          items: [
            {
              label: '1 hour',
              value: 1,
            },
            {
              label: '2 hours',
              value: 2,
            },
            {
              label: '3 hours',
              value: 3,
            },
            {
              label: '4 hours',
              value: 4,
            },
            {
              label: '5 hours',
              value: 5,
            },
            {
              label: '6 hours',
              value: 6,
            },
            {
              label: '7 hours',
              value: 7,
            },
            {
              label: '8 hours',
              value: 8,
            },
            {
              label: '9 hours',
              value: 9,
            },
            {
              label: '10 hours',
              value: 10,
            },
            {
              label: '11 hours',
              value: 11,
            },
            {
              label: '12 hours',
              value: 12,
            },
            {
              label: '13 hours',
              value: 13,
            },
            {
              label: '14 hours',
              value: 14,
            },
            {
              label: '15 hours',
              value: 15,
            },
            {
              label: '16 hours',
              value: 16,
            },
            {
              label: '17 hours',
              value: 17,
            },
            {
              label: '18 hours',
              value: 18,
            },
            {
              label: '19 hours',
              value: 19,
            },
            {
              label: '20 hours',
              value: 20,
            },
            {
              label: '21 hours',
              value: 21,
            },
            {
              label: '22 hours',
              value: 22,
            },
            {
              label: '23 hours',
              value: 23,
            },
          ],
        },
        {
          label: 'Days',
          value: 'Days',
          items: [
            {
              label: '1 day',
              value: 24,
            },
            {
              label: '2 days',
              value: 48,
            },
            {
              label: '3 days',
              value: 72,
            },
            {
              label: '4 days',
              value: 96,
            },
            {
              label: '5 days',
              value: 120,
            },
            {
              label: '6 days',
              value: 144,
            },
            {
              label: '7 days',
              value: 168,
            },
            {
              label: '8 days',
              value: 192,
            },
            {
              label: '9 days',
              value: 216,
            },
            {
              label: '10 days',
              value: 240,
            },
            {
              label: '11 days',
              value: 264,
            },
            {
              label: '12 days',
              value: 288,
            },
            {
              label: '13 days',
              value: 312,
            },
            {
              label: '14 days',
              value: 336,
            },
            {
              label: '15 days',
              value: 360,
            },
            {
              label: '16 days',
              value: 384,
            },
            {
              label: '17 days',
              value: 408,
            },
            {
              label: '18 days',
              value: 432,
            },
            {
              label: '19 days',
              value: 456,
            },
            {
              label: '20 days',
              value: 480,
            },
            {
              label: '21 days',
              value: 504,
            },
          ],
        },
      ],
    }
  }

  findLabel(value: number): string {
    for (const option of this.options.timeToLive) {
      for (const item of option.items) {
        if (item.value === value) {
          return `${item.label} (${option.label})`;
        }
      }
    }
    return value.toString(); // return value as string if no label is found
  }


}
