<div *ngIf="fieldOptions">
  <div class="header" style="display: flex; justify-content: space-between; align-items: center;">
    <h2 *ngIf="fieldOptions?.title">{{ fieldOptions?.title }}</h2>
    <!-- Expand Popup Button -->
    <button pButton type="button" label="Expand" icon="pi pi-external-link" (click)="openPopup()"></button>
  </div>

  <!-- Embedded Table -->
  <app-ag-grid-table
    *ngIf="columnSelection"
    [entity]="fieldOptions?.entity"
    [columnDefs]="fieldOptions?.columnDefs"
    [autopopulateSelect]="fieldOptions?.autopopulateSelect"
    [columnSelection]="columnSelection"
    [isViewEmbedded]="true"
    [tableParams]="tableParams"
    [parentViewName]="parentViewName"
    [customHeight]="fieldOptions?.customHeight"
    [isMultiple]="tableParams.isMultiple || false"
    [prizeCounts]="prizeCounts || null"
    [isPrizeTable]="isPrize"

  ></app-ag-grid-table>

  <!-- Popup Dialog -->
  <p-dialog [(visible)]="showPopup" [modal]="true" [style]="{ width: '90vw', height: '80vh' }" [closable]="true" [draggable]="false" header="Expanded Table" (onHide)="closePopup()">
    <ng-container *ngIf="showPopup">
      <div style="height: auto;">
        <app-ag-grid-table
        [entity]="fieldOptions?.entity"
        [columnDefs]="fieldOptions?.columnDefs"
        [autopopulateSelect]="fieldOptions?.autopopulateSelect"
        [columnSelection]="columnSelection"
        [isViewEmbedded]="false"
        [tableParams]="tableParams"
        [parentViewName]="parentViewName"
        [customHeight]="'60vh'"
        [isMultiple]="tableParams.isMultiple || false"
        [prizeCounts]="prizeCounts || null"
        [isPrizeTable]="isPrize"
      ></app-ag-grid-table>
      </div>
    </ng-container>
  </p-dialog>
</div>
