<!-- Spinner -->
<ngx-spinner bdColor="rgba(36,34,36,0.8)" size="medium" color="#81c784" type="line-scale"
  name="sourcing-group-view-spinner">
  <p style="font-size: 20px; color: white">Uploading...</p>
</ngx-spinner>

<dynamic-table *ngIf="!isLoading" [tableName]="
    entity.name + ' || ' + (entity.batch_ref ? entity.batch_ref.id: 'No Batch') + ' || ' + (entity.batch_ref ? entity.batch_ref.name: 'No Batch')
  " [tableType]="type" [tableConfig]="tableConfig" [fields]="fields" [sourcingKey]="key"
  [defaultColumnOrder]="defaultColumnOrder" [globalFilters]="globalFilters"
  [customGlobalColumnSets]="customGlobalColumnSets" [sourceGroupID]="this.entity._id" [sourceGroupEditID]="this.entity.id"  [sourcingDoc]="doc"
  [isArchived]="entity.archived" (tableModelChange)="onChange($event)" (onAction)="onCustomAction($event)"
  class="p-mt-5">
</dynamic-table>
<!-- Invalid Items Information Modal -->
<p-dialog header="Invalid Items" [(visible)]="displayModal" [modal]="true" [style]="{ width: '50vw' }"
  [maximizable]="true" [draggable]="false" [resizable]="false">
  <p-table *ngIf="displayModal" [value]="invalidItems" responsiveLayout="scroll" [scrollable]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>Item Id</th>
        <th>Field</th>
        <th>Error</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.Entity.id }}</td>
        <td>{{ item.Field }}</td>
        <td>{{ item.ErrorMessage }}</td>
        <td><button pButton pRipple type="button" icon="pi pi-copy" class="p-button-raised"
            (click)="copyMessage(item)"></button></td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- Validation Report Modal -->
<p-dialog header="Validation Report" [(visible)]="displayValidationModal" [modal]="true" [style]="{ width: '80vw' }"
  [maximizable]="true" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <ng-template pTemplate="header">
    <h2>Validation Report</h2>
    <br />
    <p-message *ngIf="displayValidationModal && severityType && severityType.length > 0" severity="{{severityType}}"
      text="{{ severityType == 'warn' ? 'Sourcing Items are valid, but there are some duplicate fields.' : 'One or more sourcing items are invalid.' }}"></p-message>
  </ng-template>
  <p-table *ngIf="displayValidationModal" [value]="invalidItems" responsiveLayout="scroll" [scrollable]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>Id</th>
        <th>Name</th>
        <th>Message</th>
        <th>Severity</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.id }}</td>
        <td>{{ item.name }}</td>
        <td>{{ item.message }}</td>
        <td> {{ item.icon == 'warning' ? 'Warning ': (item.icon == 'info' ? 'Information ' : 'Error ') }}<button pButton
            pRipple type="button"
            icon="pi pi-{{item.icon == 'warning' ? 'exclamation-triangle': (item.icon == 'info' ? 'info' :'ban') }}"
            class="p-button-rounded p-button-{{item.icon}} p-button-text"></button></td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<!-- Upload to Collection -->
<p-dialog header="Upload to Collection" [(visible)]="displayUploadToCollectionModal" [modal]="true"
  [style]="{ width: 'auto' }" [maximizable]="true" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <h3> Do you wish to add these items to a collection?</h3>
  <p-dropdown [options]="collections" [(ngModel)]="selectedCollection" optionLabel="name" optionValue="id"
    [filter]="true" filterBy="name" [showClear]="true" placeholder="Select a Collection" appendTo="body"
    [style]="{ width: '100%' }">
    <ng-template let-collection pTemplate="item">
      <div>{{collection.name}} - ({{collection.id}})</div>
    </ng-template>
  </p-dropdown>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-plus" (click)="displayCreateCollection = true" label="Create New Collection and Add"
      styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="displayUploadToCollectionModal = false" label="No"
      styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-check" (click)="uploadToCollection()" label="Yes" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<p-dialog header="Add to Collection" [(visible)]="displayAddToCollectionModal" [modal]="true"
  [style]="{ width: 'auto' }" [maximizable]="true" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <h3> Do you wish to add these items to a collection?</h3>
  <p-dropdown [options]="collections" [(ngModel)]="selectedCollection" optionLabel="name" optionValue="id"
    [filter]="true" filterBy="name" [showClear]="true" placeholder="Select a Collection" appendTo="body"
    [style]="{ width: '100%' }">
    <ng-template let-collection pTemplate="item">
      <div>{{collection.name}} - ({{collection.id}})</div>
    </ng-template>
  </p-dropdown>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-plus" (click)="displayCreateCollection = true" label="Create New Collection and Add"
      styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="displayAddToCollectionModal = false" label="No"
      styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-check" (click)="addToCollection()" label="Yes" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>


<p-dialog header="Upload to Collection" [(visible)]="displayAddCollection" [modal]="true" [style]="{ width: 'auto' }"
  [maximizable]="true" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <h3> Do you wish to add the already uploaded items to a collection?</h3>
  <p-dropdown [options]="collections" [(ngModel)]="selectedCollection" optionLabel="name" optionValue="id"
    [filter]="true" filterBy="name" [showClear]="true" placeholder="Select a Collection" appendTo="body"
    [style]="{ width: '100%' }">
    <ng-template let-collection pTemplate="item">
      <div>{{collection.name}} - ({{collection.id}})</div>
    </ng-template>
  </p-dropdown>

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-plus" (click)="displayCreateCollection = true" label="Create New Collection and Add"
      styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-times" (click)="displayAddCollection = false" label="No"
      styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-check" (click)="addToCollection()" label="Yes" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-dialog header="Create a Collection and upload to Collection" [(visible)]="displayCreateCollection" [modal]="true"
  [style]="{ width: 'auto' }" [maximizable]="true" [draggable]="true" [resizable]="true" [closeOnEscape]="true">
  <h3>Collection Name</h3>
  <input [(ngModel)]="createCollection.name" [ngModelOptions]="{ standalone: true }" pInputText
     class="field-item" [style]="{ width: '100%'}" />

  <ng-template pTemplate="footer">
    <p-button icon="pi pi-times" (click)="displayCreateCollection = false" label="No"
      styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-check" (click)="createAndAddToCollection()" label="Yes"
      styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
