import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "thumbPath"
})
export class ThumbPathPipe implements PipeTransform {
  transform(value: string): string {
      if (value) {
        value = value.substr(0, value.lastIndexOf('/'));
      }
      return value;
  }
}