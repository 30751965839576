import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import ItemSetDto from '../dtos/ItemSetDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';

@Component({
  selector: 'app-item-set-view-v2',
  templateUrl: './item-set-view-v2.component.html',
  styleUrls: ['./item-set-view-v2.component.css']
})
export class ItemSetViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  ItemSetRecord: ItemSetDto = new ItemSetDto();
  options: any;
  parentViewName: string = 'itemSet'
  constructor(
    private agGridToolsService: AgGridToolsService,
  )
  {
    super();
  }

  /**
   * ItemSet View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Item Set Data",
        fields:
        [
          { name: "Internal Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'internalName', isRequired: true, label: 'Internal Name' },
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name' },
        ],
        type: DynamicCardType.Card
      },
      {
        title: 'Items',
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Items", 
            inputType: DynamicViewType.AgGridTable,
            key: 'itemSet_ref',
            label: 'Items',
            options: {
              fieldName: '',
              columnDefs:[
                {
                  headerName: 'ID',
                  field: 'id',
                  width: 125,
                  headerCheckboxSelection: true,
                  checkboxSelection: true,
                  sortable: true,
                  initialSort: 'desc',
                  sortIndex: 0,
                  initialSortIndex: 0,
                  sort: 'desc',
                  sortingOrder: ['asc','desc'],
                  unSortIcon: false,
                  filter: 'agNumberColumnFilter',
                  filterParams: {
                    filterOptions: [
                      'equals',
                      'notEqual',
                      'lessThan',
                      'lessThanOrEqual',
                      'greaterThan',
                      'greaterThanOrEqual',
                      'inRange'
                    ]
                  },
                  resizable: true,
                  floatingFilter: true,
                  suppressSizeToFit: true,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRendererParams: {
                    entity: 'items'
                  },
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,
                  cellEditor: 'agTextCellEditor',
                  type: 'editableColumn',
                },
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  filterParams: {
                    buttons: ['clear']
                  },
                  floatingFilter: true,
                  suppressSizeToFit: true,
                  width: 300,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  valueGetter: this.agGridToolsService.dateValueGetter('start'),
                  resizable: true,
                  cellEditor: CalendarEditorComponent,
                  type: 'editableColumn'
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  filterParams: {
                    buttons: ['clear']
                  },
                  floatingFilter: true,
                  suppressSizeToFit: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                    timePill: true
                  },
                  width: 300,
                  valueGetter: this.agGridToolsService.dateValueGetter('end'),
                  resizable: true,
                  type: 'editableColumn',
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'Thumbnail',
                  field: 'thumbnail_img',
                  sortable: false,
                  resizable: true,
                  width: 135,
                  autoHeight: true,
                  cellRenderer: ThumbnailCellRendererComponent,
                  valueGetter: (params: { data: { thumbnail_ref: { thumbnails: { path: any; }[]; }; }; }) => {
                    return params.data.thumbnail_ref.thumbnails && params.data.thumbnail_ref.thumbnails[1] ?
                      params.data.thumbnail_ref.thumbnails[1].path :
                      'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                  },
                },
                {
                  headerName: 'Thumbnail Record',
                  field: 'thumbnail_ref',
                  cellRenderer: MiscAssetCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,
                  filterParams: {
                    filterKey: 'thumbnail_ref',
                    isAggregate: true,
                    fieldKeys: ['name', 'id', 'path'],
                    isMiscBuild: true,
                    populate: true,
                    select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
                  },
                  width: 500,
                  autoHeight: true,
                },
                {
                  headerName: 'Re Release Start',
                  field: 'reReleaseStart',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  filterParams: {
                    buttons: ['clear'],
                  },
                  floatingFilter: true,
                  suppressSizeToFit: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  valueGetter: this.agGridToolsService.dateValueGetter('reReleaseStart'),
                  resizable: true,
                  type: 'editableColumn',
                },
                {
                  headerName: 'Re Release End',
                  field: 'reReleaseEnd',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  filterParams: {
                    buttons: ['clear']
                  },
                  floatingFilter: true,
                  suppressSizeToFit: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  valueGetter: this.agGridToolsService.dateValueGetter('reReleaseEnd'),
                  resizable: true,
                  type: 'editableColumn',
                },
                {
                  headerName: 'Color(s)',
                  field: 'colors_ref',
                  cellRenderer: RefsCellRendererComponent,
                  cellRendererParams: {
                    entity: 'colors',
                    isArray: true,
                    noID: true,
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  autoHeight: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: this.options?.colors_ref || [],
                    valueFormatter: (params: any) => {
                      return `${params.value.name} (${params.value.id})`;
                    },
                    keyCreator: (params: any) => {
                      return params.value._id;
                    },
                    comparator: this.agGridToolsService.nameASC,
                    isMultiRefFilter: true,
                    populate: true,
                    select: '_id id name'
                  },
                  floatingFilter: true,
                },
                {
                  headerName: 'Patterns(s)',
                  field: 'patterns_ref',
                  cellRenderer: RefsCellRendererComponent,
                  cellRendererParams: {
                    entity: 'patterns',
                    isArray: true,
                    noID: true,
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: this.options?.patterns_ref || [],
                    valueFormatter: (params: any) => {
                      return `${params.value.name} (${params.value.id})`;
                    },
                    keyCreator: (params: any) => {
                      return params.value._id;
                    },
                    comparator: this.agGridToolsService.nameASC,
                    isMultiRefFilter: true,
                    populate: true,
                    select: '_id id name'
                  },
                  floatingFilter: true,
                },
                {
                  headerName: 'Material(s)',
                  field: 'materials_ref',
                  cellRenderer: RefsCellRendererComponent,
                  cellRendererParams: {
                    entity: 'materials',
                    isArray: true,
                    noID: true,
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: this.options?.materials_ref || [],
                    valueFormatter: (params: any) => {
                      return `${params.value.name} (${params.value.id})`;
                    },
                    keyCreator: (params: any) => {
                      return params.value._id;
                    },
                    comparator: this.agGridToolsService.nameASC,
                    isMultiRefFilter: true,
                    populate: true,
                    select: '_id id name'
                  },
                  floatingFilter: true,
                },  
                {
                  headerName: 'Style(s)',
                  field: 'styles_ref',
                  cellRenderer: RefsCellRendererComponent,
                  cellRendererParams: {
                    entity: 'styles',
                    isArray: true,
                    noID: true,
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: this.options?.styles_ref || [],
                    valueFormatter: (params: any) => {
                      return `${params.value.name} (${params.value.id})`;
                    },
                    keyCreator: (params: any) => {
                      return params.value._id;
                    },
                    comparator: this.agGridToolsService.nameASC,
                    isMultiRefFilter: true,
                    addEmptyFilters: true,
                    populate: true,
                    select: '_id id name'
                  },
                  floatingFilter: true,
                  autoHeight: true
                },
                {
                  headerName: 'Asset Type',
                  field: 'assetType',
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['Hard Surface', 'HS Recolor', 'Organics', 'Organic Recolors'] || [],
                  },
                  floatingFilter: true,
                },                                  
              ],
              defaultQueryKey: 'itemSet_ref',
              entity: 'items'
            }
          },
        ]
      },
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.ItemSetRecord =
    {
      enabled: false,
      items_ref: [],
    };
    this.title = "Item Set";
    this.viewRoute = 'item-sets';
    this.isLoading = false;
  }
}
