import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service'; // Adjust path as necessary

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.sass']
})
export class SideMenuComponent {
  menus = [
    {
      label: 'Users Management',
      subMenu: [
        { 
          label: 'Test Users Table',
          route: '/test-users'
        }
      ],
      expanded: false,
      permission: 'UserController'
    },
    {
      label: 'Automations Logs',
      subMenu: [
        { 
          label: 'Table',
          route: '/automations-logs'
        }
      ],
      expanded: false,
      // permission: ''
    },
    {
      label: 'Localization Management',
      subMenu: [
        { 
          label: 'Localization Config Table',
          route: '/localization-configuration' 
        }
      ],
      expanded: false,
      permission: 'LocalizationConfigController' 
    }
  ];

  constructor(private router: Router, public authService: AuthService) {}

  toggleMenu(index: number): void {
    this.menus.forEach((menu, i) => {
      if (i !== index) menu.expanded = false;
    });
    this.menus[index].expanded = !this.menus[index].expanded;
  }
}
