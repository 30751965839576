<p-toast></p-toast>

<div class="card" style="margin: 2rem;">
  <div class="grid" style="display: flex; flex-direction: row; gap: 2rem;">
    <!-- Left side - Form -->
    <div class="col-12 md:col-8" style="flex: 1.5;">
      <p-card header="Form Configuration" [style]="{ padding: '1.5rem' }">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="grid p-mb-4">
            <!-- All three controls in one row with equal size -->
            <div style="display: flex; gap: 2rem; width: 100%; align-items: stretch; margin-bottom: 2rem;">
              <div style="flex: 1; display: flex;">
                <span class="p-float-label" style="width: 100%;">
                  <input pInputText id="modelName" formControlName="modelName" 
                         style="width: 100%; height: 48px; font-size: 1.2em; padding: 1rem 0.75rem;" />
                  <label for="modelName" style="margin-left: 0.75rem;">Model Name</label>
                </span>
              </div>
              
              <div style="flex: 1; display: flex;">
                <span class="p-float-label" style="width: 100%;">
                  <input pInputText id="modelLabel" formControlName="modelLabel"
                         style="width: 100%; height: 48px; font-size: 1.2em; padding: 1rem 0.75rem;" />
                  <label for="modelLabel" style="margin-left: 0.75rem;">Model Label</label>
                </span>
              </div>

              <div style="flex: 1; padding: 0 0.5rem;">
                <p-toggleButton
                  formControlName="enabled"
                  onLabel="Enabled"
                  offLabel="Disabled"
                  [style]="{ width: '100%', height: '48px', fontSize: '1.2em' }">
                </p-toggleButton>
              </div>

              <div style="flex: 1; padding: 0 0.5rem;">
                <p-toggleButton
                  formControlName="gamedataExportEnabled"
                  onLabel="Include in Gamedata"
                  offLabel="Skip Gamedata"
                  [style]="{ width: '100%', height: '48px', fontSize: '1.2em' }">
                </p-toggleButton>
              </div>
            </div>
          </div>

          <div formArrayName="fields">
            <div class="field-array-container" style="margin: 2rem 0 1rem 0;">
              <div style="display: flex; gap: 1rem; align-items: center; margin-bottom: 1rem;">
                <button 
                  pButton 
                  type="button" 
                  label="Add Field" 
                  icon="pi pi-plus" 
                  (click)="addField()"
                  class="p-button-secondary"
                ></button>

                <p-toggleButton
                  [(ngModel)]="showDefaultFields"
                  [ngModelOptions]="{standalone: true}"
                  (onChange)="toggleDefaultFields($event)"
                  onLabel="Include Default Fields"
                  offLabel="Exclude Default Fields"
                  [style]="{ width: '250px' }">
                </p-toggleButton>
              </div>
              <h3 style="margin: 0.75rem 0 0.5rem 0;">Fields</h3>
              <hr style="margin: 0.5rem 0; border: 0; border-top: 1px solid var(--surface-border); width: 100%;">
            </div>

            <div class="field-cards-container" style="max-height: 70vh; overflow-y: auto; padding: 0.25rem;">
              <div *ngFor="let field of fields.controls; let i=index" [formGroupName]="i" class="p-fluid field-container" style="margin-bottom: 1rem;">
                <p-card [style]="{ padding: '1rem' }">
                  <div class="p-grid">
                    <!-- Add reorder buttons -->
                    <div class="p-col-12" style="display: flex; justify-content: flex-end; gap: 0.5rem; margin-bottom: 1rem;">
                      <button pButton type="button" 
                              icon="pi pi-chevron-up" 
                              class="p-button-rounded p-button-text"
                              [disabled]="!canMoveUp(i)"
                              (click)="moveFieldUp(i)"
                              pTooltip="Move Up"></button>
                      <button pButton type="button" 
                              icon="pi pi-chevron-down" 
                              class="p-button-rounded p-button-text"
                              [disabled]="!canMoveDown(i)"
                              (click)="moveFieldDown(i)"
                              pTooltip="Move Down"></button>
                    </div>

                    <!-- Add a visual indicator and toggle for readonly fields -->
                    <div class="p-col-12" *ngIf="isFieldReadonly(field)" 
                         style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
                      <div style="display: flex; align-items: center; gap: 1rem;">
                        <p-tag severity="info" value="Default Field"></p-tag>
                        <p-inputSwitch
                          formControlName="fieldEnabled"
                          (onChange)="toggleField(field, $event.checked)">
                        </p-inputSwitch>
                        <label>Include Field</label>
                      </div>
                    </div>

                    <!-- Left Column -->
                    <div class="p-col-12 p-md-6" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}">
                      <div class="field">
                        <label>Field Name</label>
                        <input type="text" pInputText formControlName="name" [readonly]="isFieldReadonly(field)">
                        <small class="p-error" *ngIf="hasFieldError(field, 'name')">
                          {{ getFieldError(field, 'name') }}
                        </small>
                      </div>
                    </div>

                    <!-- Right Column -->
                    <div class="p-col-12 p-md-6" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}">
                      <div class="field">
                        <label>Label</label>
                        <input type="text" pInputText formControlName="label">
                      </div>
                    </div>

                    <!-- Left Column -->
                    <div class="p-col-12 p-md-6" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}">
                      <div class="field">
                        <label>Type</label>
                        <p-dropdown 
                          [options]="fieldTypes" 
                          formControlName="type"
                          optionLabel="label"
                          optionValue="value"
                          [showClear]="true"
                          [disabled]="isFieldReadonly(field)"
                        ></p-dropdown>
                        <small class="p-error" *ngIf="hasFieldError(field, 'type')">
                          {{ getFieldError(field, 'type') }}
                        </small>
                      </div>
                    </div>

                    <!-- Right Column -->
                    <div class="p-col-12 p-md-6" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}">
                      <div class="field">
                        <label>Input Widget</label>
                        <p-dropdown 
                          [options]="getAvailableInputWidgets(field.get('type').value)" 
                          formControlName="inputWidget"
                          optionLabel="label"
                          optionValue="value"
                          [showClear]="true"
                          (onChange)="onInputWidgetChange(field)"
                        ></p-dropdown>
                        <small class="p-error" *ngIf="hasFieldError(field, 'inputWidget')">
                          {{ getFieldError(field, 'inputWidget') }}
                        </small>
                      </div>
                    </div>

                    <!-- Number Configuration -->
                    <ng-container *ngIf="field.get('type').value === 'Number'">
                      <div class="p-col-12">
                        <p-fieldset legend="Number Configuration">
                          <div class="p-grid">
                            <!-- Show Buttons -->
                            <div class="p-col-12" style="display: flex; align-items: center; gap: 1rem; margin-bottom: 1rem;">
                              <label>Show Buttons</label>
                              <div class="field-checkbox" style="margin-left: auto;">
                                <p-checkbox formControlName="inputNumberShowButtons" [binary]="true"></p-checkbox>
                              </div>
                            </div>

                            <!-- Min Value Configuration -->
                            <div class="p-col-12" style="display: flex; align-items: center; gap: 1rem; margin-bottom: 1rem;">
                              <div class="field" style="flex: 1;">
                                <label>Minimum Value</label>
                                <p-inputNumber formControlName="inputNumberMin" [disabled]="!field.get('useInputNumberMin').value"
                                              [style]="{ width: '100%' }">
                                </p-inputNumber>
                              </div>
                              <div class="field-checkbox">
                                <p-checkbox formControlName="useInputNumberMin" [binary]="true"></p-checkbox>
                              </div>
                            </div>

                            <!-- Max Value Configuration -->
                            <div class="p-col-12" style="display: flex; align-items: center; gap: 1rem; margin-bottom: 1rem;">
                              <div class="field" style="flex: 1;">
                                <label>Maximum Value</label>
                                <p-inputNumber formControlName="inputNumberMax" [disabled]="!field.get('useInputNumberMax').value"
                                              [style]="{ width: '100%' }">
                                </p-inputNumber>
                              </div>
                              <div class="field-checkbox">
                                <p-checkbox formControlName="useInputNumberMax" [binary]="true"></p-checkbox>
                              </div>
                            </div>

                            <!-- Min Fraction Digits Configuration -->
                            <div class="p-col-12" style="display: flex; align-items: center; gap: 1rem; margin-bottom: 1rem;">
                              <div class="field" style="flex: 1;">
                                <label>Min Fraction Digits</label>
                                <p-inputNumber formControlName="minFractionDigits" 
                                              [min]="0" [max]="20" 
                                              [showButtons]="true"
                                              [disabled]="!field.get('useMinFractionDigits').value"
                                              [style]="{ width: '100%' }">
                                </p-inputNumber>
                              </div>
                              <div class="field-checkbox">
                                <p-checkbox formControlName="useMinFractionDigits" [binary]="true"></p-checkbox>
                              </div>
                            </div>

                            <!-- Max Fraction Digits Configuration -->
                            <div class="p-col-12" style="display: flex; align-items: center; gap: 1rem; margin-bottom: 1rem;">
                              <div class="field" style="flex: 1;">
                                <label>Max Fraction Digits</label>
                                <p-inputNumber formControlName="maxFractionDigits" 
                                              [min]="0" [max]="20" 
                                              [showButtons]="true"
                                              [disabled]="!field.get('useMaxFractionDigits').value"
                                              [style]="{ width: '100%' }">
                                </p-inputNumber>
                              </div>
                              <div class="field-checkbox">
                                <p-checkbox formControlName="useMaxFractionDigits" [binary]="true"></p-checkbox>
                              </div>
                            </div>
                          </div>
                        </p-fieldset>
                      </div>
                    </ng-container>

                    <!-- Toggle Button Configuration -->
                    <ng-container *ngIf="field.get('inputWidget').value === 'ToggleButton'">
                      <div class="p-col-12">
                        <p-fieldset legend="Toggle Button Configuration">
                          <div class="p-grid">
                            <!-- On Label -->
                            <div class="p-col-12 p-md-6">
                              <div class="field">
                                <label>On Label</label>
                                <input type="text" pInputText formControlName="onLabel">
                              </div>
                            </div>

                            <!-- Off Label -->
                            <div class="p-col-12 p-md-6">
                              <div class="field">
                                <label>Off Label</label>
                                <input type="text" pInputText formControlName="offLabel">
                              </div>
                            </div>
                          </div>
                        </p-fieldset>
                      </div>
                    </ng-container>

                    <!-- Left Column -->
                    <div class="p-col-12 p-md-6" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}">
                      <div class="field">
                        <label>Column Width</label>
                        <p-inputNumber 
                          formControlName="columnWidth"
                          [min]="1" 
                          [max]="12"
                          [showButtons]="true"
                          [style]="{ width: '100%' }"
                        ></p-inputNumber>
                        <small class="p-error" *ngIf="hasFieldError(field, 'columnWidth')">
                          {{ getFieldError(field, 'columnWidth') }}
                        </small>
                      </div>
                    </div>

                    <!-- Right Column -->
                    <div class="p-col-12 p-md-6" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}">
                      <div class="field">
                        <label>Position</label>
                        <p-inputNumber 
                          formControlName="position"
                          [min]="1"
                          [showButtons]="true"
                          [style]="{ width: '100%' }"
                        ></p-inputNumber>
                        <small class="p-error" *ngIf="hasFieldError(field, 'position')">
                          {{ getFieldError(field, 'position') }}
                        </small>
                      </div>
                    </div>

                    <!-- Left Column -->
                    <div class="p-col-12 p-md-6" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}">
                      <div class="field-checkbox">
                        <p-checkbox formControlName="required" [binary]="true"></p-checkbox>
                        <label>Required</label>
                      </div>
                    </div>

                    <!-- Right Column -->
                    <div class="p-col-12 p-md-6" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}">
                      <div class="field-checkbox">
                        <p-toggleButton
                          formControlName="gamedataExportEnabled"
                          onLabel="Include in Gamedata"
                          offLabel="Skip Gamedata"
                          [style]="{ width: '100%' }">
                        </p-toggleButton>
                      </div>
                    </div>

                    <!-- Gamedata Export Label (only shown when gamedataExportEnabled is true) -->
                    <div class="p-col-12" [ngStyle]="{'opacity': field.get('fieldEnabled')?.value ? '1' : '0.5'}" *ngIf="field.get('gamedataExportEnabled').value">
                      <div class="field">
                        <label>Gamedata Field Name</label>
                        <input type="text" pInputText formControlName="gamedataExportEnabledLabel">
                        <small class="p-error" *ngIf="hasFieldError(field, 'gamedataExportEnabledLabel')">
                          {{ getFieldError(field, 'gamedataExportEnabledLabel') }}
                        </small>
                      </div>
                    </div>

                    <!-- Add Dropdown specific configuration -->
                    <ng-container *ngIf="field.get('inputWidget').value === 'Dropdown'">
                      <div class="p-col-12">
                        <p-fieldset legend="Dropdown Configuration">
                          <div class="p-grid">
                            <!-- Dropdown Type Selection -->
                            <div class="p-col-12 mb-3">
                              <div class="field">
                                <label>Dropdown Type</label>
                                <p-selectButton
                                  [options]="dropdownTypes"
                                  formControlName="dropdownType"
                                  optionLabel="label"
                                  optionValue="value">
                                </p-selectButton>
                              </div>
                            </div>

                            <!-- API-based Configuration -->
                            <ng-container *ngIf="field.get('dropdownType').value === 'api'">
                              <div class="p-col-12">
                                <div class="field">
                                  <label>API Controller</label>
                                  <p-dropdown
                                    [formControlName]="'apiController'"
                                    [options]="apiControllers"
                                    [showClear]="true"
                                    placeholder="Select a controller"
                                    [filter]="true"
                                    filterBy="label"
                                  ></p-dropdown>
                                </div>
                              </div>

                              <div class="p-col-12">
                                <div class="field">
                                  <label>Select Fields</label>
                                  <p-multiSelect
                                    [formControlName]="'select'"
                                    [options]="selectFieldOptions"
                                    [showToggleAll]="true"
                                    [filter]="true"
                                    placeholder="Choose fields to select"
                                    [showHeader]="true"
                                    display="chip"
                                    [style]="{ width: '100%' }"
                                  ></p-multiSelect>
                                </div>
                              </div>

                              <div class="p-col-12">
                                <div class="field-checkbox">
                                  <p-checkbox [formControlName]="'filter'" [binary]="true"></p-checkbox>
                                  <label>Enable Filtering</label>
                                </div>
                              </div>
                            </ng-container>

                            <!-- Static Values Configuration -->
                            <ng-container *ngIf="field.get('dropdownType').value === 'static'">
                              <div class="p-col-12">
                                <div class="field">
                                  <label>Static Values</label>
                                  <div class="static-values-container">
                                    <div *ngFor="let valueControl of getStaticValues(field); let i = index" class="static-value-row p-mb-2">
                                      <div [formGroup]="valueControl">
                                        <input type="text" pInputText formControlName="value" placeholder="Enter value">
                                      </div>
                                      <button pButton type="button" icon="pi pi-trash" class="p-button-danger p-button-sm"
                                        (click)="removeStaticValue(field, i)"></button>
                                    </div>
                                    <button pButton type="button" label="Add Value" icon="pi pi-plus" 
                                      class="p-button-secondary p-button-sm"
                                      (click)="addStaticValue(field)"></button>
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <!-- Common Configuration -->
                            <div class="p-col-6">
                              <div class="field">
                                <label>Option Label</label>
                                <input 
                                  type="text" 
                                  pInputText 
                                  [formControlName]="'optionLabel'"
                                  *ngIf="field.contains('optionLabel')"
                                >
                              </div>
                            </div>
                            
                            <div class="p-col-6">
                              <div class="field">
                                <label>Option Value</label>
                                <input 
                                  type="text" 
                                  pInputText 
                                  [formControlName]="'optionValue'"
                                  *ngIf="field.contains('optionValue')"
                                >
                              </div>
                            </div>
                          </div>
                        </p-fieldset>
                      </div>
                    </ng-container>

                    <!-- AutoComplete Configuration -->
                    <ng-container *ngIf="field.get('inputWidget').value === 'AutoComplete'">
                      <div class="p-col-12">
                        <p-fieldset legend="AutoComplete Configuration">
                          <div class="p-grid">
                            <!-- API Controller -->
                            <div class="p-col-12">
                              <div class="field">
                                <label>API Controller</label>
                                <p-dropdown
                                  [formControlName]="'apiController'"
                                  [options]="apiControllers"
                                  [showClear]="true"
                                  placeholder="Select a controller"
                                  [filter]="true"
                                  filterBy="label"
                                ></p-dropdown>
                              </div>
                            </div>

                            <!-- Select Fields -->
                            <div class="p-col-12">
                              <div class="field">
                                <label>Select Fields</label>
                                <p-multiSelect
                                  [formControlName]="'select'"
                                  [options]="selectFieldOptions"
                                  [showToggleAll]="true"
                                  [filter]="true"
                                  placeholder="Choose fields to select"
                                  [showHeader]="true"
                                  display="chip"
                                  [style]="{ width: '100%' }"
                                ></p-multiSelect>
                              </div>
                            </div>

                            <!-- Option Label -->
                            <div class="p-col-6">
                              <div class="field">
                                <label>Option Label</label>
                                <input type="text" pInputText [formControlName]="'optionLabel'">
                              </div>
                            </div>

                            <!-- Option Value -->
                            <div class="p-col-6">
                              <div class="field">
                                <label>Option Value</label>
                                <input type="text" pInputText [formControlName]="'optionValue'">
                              </div>
                            </div>
                          </div>
                        </p-fieldset>
                      </div>
                    </ng-container>

                    <!-- MultiSelect Configuration -->
                    <ng-container *ngIf="field.get('inputWidget').value === 'MultiSelect'">
                      <div class="p-col-12">
                        <p-fieldset legend="MultiSelect Configuration">
                          <div class="p-grid">
                            <!-- MultiSelect Type -->
                            <div class="p-col-12">
                              <div class="field">
                                <label>MultiSelect Type</label>
                                <p-dropdown
                                  [formControlName]="'multiSelectType'"
                                  [options]="[
                                    { label: 'API Based', value: 'api' },
                                    { label: 'Static Values', value: 'static' }
                                  ]"
                                  placeholder="Select type"
                                  (onChange)="onMultiSelectTypeChange(field)"
                                ></p-dropdown>
                              </div>
                            </div>

                            <!-- API Based Configuration -->
                            <ng-container *ngIf="field.get('multiSelectType').value === 'api'">
                              <!-- API Controller -->
                              <div class="p-col-12">
                                <div class="field">
                                  <label>API Controller</label>
                                  <p-dropdown
                                    [formControlName]="'apiController'"
                                    [options]="apiControllers"
                                    [showClear]="true"
                                    placeholder="Select a controller"
                                    [filter]="true"
                                    filterBy="label"
                                  ></p-dropdown>
                                </div>
                              </div>

                              <!-- Select Fields -->
                              <div class="p-col-12">
                                <div class="field">
                                  <label>Select Fields</label>
                                  <p-multiSelect
                                    [formControlName]="'select'"
                                    [options]="selectFieldOptions"
                                    [showToggleAll]="true"
                                    [filter]="true"
                                    placeholder="Choose fields to select"
                                    [showHeader]="true"
                                  ></p-multiSelect>
                                </div>
                              </div>
                            </ng-container>

                            <!-- Static Values Configuration -->
                            <ng-container *ngIf="field.get('multiSelectType').value === 'static'">
                              <div class="p-col-12">
                                <div class="field">
                                  <label>Static Values</label>
                                  <div formGroupName="options">
                                    <div formArrayName="values">
                                      <div *ngFor="let value of getStaticValues(field); let i = index" class="p-grid p-ai-center">
                                        <div class="p-col-11" [formGroupName]="i">
                                          <input pInputText type="text" formControlName="value" placeholder="Enter value">
                                        </div>
                                        <div class="p-col-1">
                                          <button pButton type="button" icon="pi pi-trash" class="p-button-danger p-button-text" 
                                                  (click)="removeStaticValue(field, i)"></button>
                                        </div>
                                      </div>
                                    </div>
                                    <button pButton type="button" label="Add Value" icon="pi pi-plus" 
                                            class="p-button-text" (click)="addStaticValue(field)"></button>
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <!-- Display Type -->
                            <div class="p-col-12">
                              <div class="field">
                                <label>Display Type</label>
                                <p-dropdown
                                  [formControlName]="'multiSelectDisplayType'"
                                  [options]="[
                                    { label: 'Chip', value: 'chip' },
                                    { label: 'Text', value: 'text' }
                                  ]"
                                  placeholder="Select display type"
                                ></p-dropdown>
                              </div>
                            </div>
                          </div>
                        </p-fieldset>
                      </div>
                    </ng-container>

                  </div>

                  <!-- Only show remove button for non-readonly fields -->
                  <button 
                    *ngIf="canRemoveField(field)"
                    pButton 
                    type="button" 
                    icon="pi pi-trash" 
                    class="p-button-danger" 
                    (click)="removeField(i)"
                  ></button>
                </p-card>
              </div>
            </div>
            
            <div class="field-array-container" style="margin: 1.5rem 0;">
              <div style="display: flex; gap: 1rem; align-items: center;">
                <button 
                  pButton 
                  type="button" 
                  label="Add Field" 
                  icon="pi pi-plus" 
                  (click)="addField()"
                  class="p-button-secondary"
                ></button>

                <p-toggleButton
                  [(ngModel)]="showDefaultFields"
                  [ngModelOptions]="{standalone: true}"
                  (onChange)="toggleDefaultFields($event)"
                  onLabel="Include Default Fields"
                  offLabel="Exclude Default Fields"
                  [style]="{ width: '250px' }">
                </p-toggleButton>
              </div>
            </div>

            <div class="button-container" style="margin: 2rem 0 1rem 0; display: flex; justify-content: center;">
              <button 
                pButton 
                type="submit" 
                label="{{ isEdit ? 'Update' : 'Create' }}" 
                [disabled]="!form.valid"
                style="width: 300px; height: 56px; font-size: 1.2em;"
              ></button>
            </div>
          </div>
        </form>
      </p-card>
    </div>

    <!-- Right side - Preview -->
    <div class="col-12 md:col-4" style="flex: 1;">
      <p-card header="Form Preview">
        <div class="preview-grid" style="display: flex; flex-wrap: wrap; gap: 4px; padding: 0.5rem; background-color: var(--surface-a); width: 100%; min-height: 200px;">
          <ng-container *ngFor="let field of fields.controls; let i = index">
            <div [style.flex]="'0 0 calc(' + (field.get('columnWidth')?.value / 12 * 100) + '% - ' + (getUsedWidthInCurrentRow(i) === 0 ? '0px' : '4px') + ')'"
                 [style.min-height]="'36px'"
                 class="shadow-2"
                 style="background-color: var(--primary-color); border-radius: 3px; padding: 4px; display: flex; flex-direction: column; align-items: center; justify-content: center; gap: 2px; box-sizing: border-box;"
                 pTooltip="Field: {{field.get('name')?.value}}&#13;Type: {{field.get('type')?.value}}&#13;Input: {{field.get('inputWidget')?.value}}"
                 tooltipPosition="top"
                 [escape]="false">
              <span style="color: var(--primary-color-text); font-weight: bold;">Pos: {{field.get('position')?.value}}</span>
              <span style="color: var(--primary-color-text); font-weight: bold;">Width: {{field.get('columnWidth')?.value}}</span>
            </div>
          </ng-container>
        </div>
      </p-card>
    </div>
  </div>
</div>