import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Nurture DTO
 */
export default class NurtureDTO extends BaseEntityDto
{
    /**
     * Name of the nurture
     */
    name: string;
    /**
     * Reference to the stages of the nurture
     */
    stages_ref: Array<any> = [];
    /**
     * Id of the reward at the end
     */
    itemId: number;
    /**
     * Costs ref
     */
    costs_ref: Array<any> = [];

    collectionId: any;
}