<p-fieldset legend="Gamedata" [toggleable]="true" [collapsed]="true">
    <h5>Auto Prod Push</h5>
    <p-inputSwitch [(ngModel)]="autoProdPushEnabled" (onChange)="handleProdPushChange($event, true)"></p-inputSwitch>
    <h5>Manual Prod Push</h5>
    <p-inputSwitch [(ngModel)]="manualProdPushButtonEnabled"
      (onChange)="handleProdPushChange($event, false)"></p-inputSwitch>
    <p-divider></p-divider>
    <h5>Auto Dev + Auto QA Gamedata Push</h5>
    <p-inputSwitch [(ngModel)]="autoDevQAPushEnabled"
      (onChange)="handleAutoDevQAPushEnabledChange($event)"></p-inputSwitch>
    <p-divider></p-divider>
    <h5>Manual Dev + QA Gamedata Push</h5>
    <p-inputSwitch [(ngModel)]="manualDevQAGamedataPushEnabled"
      (onChange)="handleManualDevQAGamedataPushEnabledChange($event)"></p-inputSwitch>
    <p-divider></p-divider>
    <h5>Manual Dev Gamedata Push</h5>
    <p-inputSwitch [(ngModel)]="manualDevGamedataPushEnabled"
      (onChange)="handleManualDevGamedataPushEnabledChange($event)"></p-inputSwitch>
    <p-divider></p-divider>
    <h5>Manual QA Gamedata Push</h5>
    <p-inputSwitch [(ngModel)]="manualQAGamedataPushEnabled"
      (onChange)="handleManualQAGamedataPushEnabledChange($event)"></p-inputSwitch>
  </p-fieldset>
  <br />
  <p-fieldset *ngIf="!isProd" legend="DataBase" [toggleable]="true">
    <h5>Sync DB</h5>
    <button pButton pRipple icon="pi pi-database" class="p-button-success p-mr-2 p-mb-6" (click)="syncDB()"
      label="Sync DB"></button>
  </p-fieldset>
  <br />
  <p-fieldset legend="Default Records" [toggleable]="true">
    <div class="field">
      <h5>Default Perk</h5>
      <p-multiSelect 
        [options]="perks" 
        [(ngModel)]="selectedDefaultPerks" 
        optionLabel="name"
        [filter]="true"
        filterBy="name"
        [style]="{'width': '85%'}"
        placeholder="Select Default Perks"
        (onChange)="onDefaultPerksChange($event)"
        display="chip">
        <ng-template pTemplate="item" let-perk>
          {{perk.name}} ({{perk.id}})
        </ng-template>
        <ng-template pTemplate="selectedItem" let-perk>
          {{perk.name}} ({{perk.id}})
        </ng-template>
      </p-multiSelect>
    </div>
  </p-fieldset>
  <br />
  