<div *ngIf="!tableParams?.hideHeader">
  <div>
    <div class="p-mx-2 p-mt-1 p-d-flex p-jc-between p-my-0">
      <div class="p-d-flex p-ai-center">
        <h2 *ngIf="!isTableInput" class="p-mx-1 p-my-1">{{parse(entity)}} Table</h2>
        <ng-container *ngIf="totalRowCount && tableTotalRecords">
          <p-badge [value]="totalRowCount + ' / ' + tableTotalRecords" size="medium" class="p-mx-1 p-mt-1">
          </p-badge>
        </ng-container>
        <ng-container *ngIf="selectedRowsSet.size < 1; else rowsAreSelected">
          <p-badge *ngIf="!disabledTableActions.includes(TABLE_ACTIONS.ROW_SELECTED)" [value]="selectedRowsSet.size + ' Rows Selected'" size="medium" class="p-mx-1 p-mt-1">
          </p-badge>
        </ng-container>
        <ng-template #rowsAreSelected>
          <p-badge (click)="onResetRows(true)" [value]="selectedRowsSet.size + ' Rows Selected (Clear)'" size="medium"
            severity="warning" class="p-mx-1 p-mt-1">
          </p-badge>
        </ng-template>
        <div *ngIf="fullEditModeEnabled && !disabledTableActions.includes(TABLE_ACTIONS.FULL_EDIT_MODE)" class="p-pt-2">
          <div style="display: flex;
            align-items: center;">
            <i class="pi pi-eye p-ml-3" [style]="{
                'font-size': '1em',
                color: isFullEditMode ? '' : 'var(--primary-color)'
              }"></i>
            <p-inputSwitch [(ngModel)]="isFullEditMode" [disabled]="activeRowEdit" class="p-mx-2"
              (onChange)="toggleFullEditMode()"></p-inputSwitch>
            <i class="pi pi-pencil" [style]="{
                'font-size': '1em',
                color: isFullEditMode ? 'var(--primary-color)' : ''
              }"></i>
          </div>
          <div *ngIf="this.isTableEditLocked" class="p-mx-2">
            <strong> Table Editing Disabled. Row Editing is Active</strong>
          </div>
        </div>
        <div class="p-ml-3">
          <p-button class="p-button-sm" *ngIf="isFullEditMode" label="Save Changes" (click)="submitFullEdit()"></p-button>
        </div>
      </div>
      <div class="p-d-flex p-ai-center">
        <div *ngIf="tableParams && tableParams.customButtons">
          <p-button *ngIf="tableParams.customButtons.includes('archived')"
            [label]="showArchived ? 'Hide Archived' : 'Show Archived'" class="p-button-sm p-mr-2"
            [styleClass]="showArchived ? '' : 'p-button-outlined'"
            [icon]="showArchived ? 'pi pi-inbox' : 'pi pi-folder'" type="button"
            (click)="applyCustomFilter('archived')"></p-button>
        </div>

        <p-button
          *ngIf="(entity == 'items' || entity == 'challenges' || entity == 'miscellaneous-build') && !isTableInput"
          [label]="isGridViewVisible ? 'Grid' : 'Rows'"
          [icon]="isGridViewVisible ? 'fa-solid fa-grip' : 'fa-solid fa-table'"
          class="p-mr-2"
          styleClass="p-button-sm"
          (click)="toggleGrid()">
        </p-button>

        <p-button label="Clear Sorting" [badge]="sortCounter" icon="pi pi-sort-alt-slash" class="p-mr-2" styleClass="p-button-sm"
          (click)="clearSorting()">
        </p-button>

        <p-button  label="Clear Filters" [badge]="filterCounter" icon="pi pi-filter-slash" class="p-mr-2" styleClass="p-button-sm"
          (click)="clearFilters()">
        </p-button>

        <p-button
          *ngIf="!hideActions && !isTableInput && !disabledTableActions.includes(TABLE_ACTIONS.ACTIONS)"
          [label]="TABLE_ACTIONS.ACTIONS"
          styleClass="p-button-sm"
          icon="pi pi-cog"
          type="button"
          (click)="actionsDialog.show()"
        >
      </p-button>

        <span class="p-input-icon-left p-ml-4" *ngIf="!disabledTableActions.includes(TABLE_ACTIONS.TABLE_SEARCH)">
          <i class="pi pi-search"></i>
          <input pInputText type="text" [placeholder]="TABLE_ACTIONS.TABLE_SEARCH" [(ngModel)]="globalSearchTerm" (input)="onSearchInputChange()" />
        </span>      
        <a
          *ngIf="tableParams && !tableParams.hideAdd && !isLoading"
          [href]="urlNewForm" target="_blank" style="text-decoration: none" class="p-ml-3"
        >
          <p-button
            *ngIf="!isTableInput && !disabledTableActions.includes(TABLE_ACTIONS.NEW)"
            [label]="TABLE_ACTIONS.NEW"
            icon="pi pi-fw pi-plus"
            class="p-button-sm"
            type="button"
          ></p-button>
        </a>
        <p-button
          *ngIf="isTableInput"
          label="Add"
          icon="pi pi-fw pi-plus"
          class="p-button-sm"
          type="button"
          (onClick)="addSelectedData()"
        ></p-button>

        <div *ngIf="tableParams && tableParams.customButtons">
          <p-button *ngIf="tableParams.customButtons.includes('addAsset')" label="Add Asset" class="p-button-sm p-ml-2"
            icon="fa-solid fa-circle-plus" type="button" (click)="showAssetDialog()"></p-button>
          <p-button *ngIf="tableParams.customButtons.includes('syncStoreListings')"
            label="Sync Store Listings with Admin Panel" class="p-button-sm p-ml-4" icon="fa-solid fa-rotate"
            type="button" (click)="syncStore()"></p-button>
            <p-button *ngIf="tableParams.customButtons.includes('addString')" label="Add" class="p-button-sm p-ml-2"
            icon="fa-solid fa-circle-plus" type="button" (click)="showStringDialog()"></p-button>
          <!-- <p-button
            *ngIf="tableParams.customButtons.includes('syncStoreListingsV2')"
            label="Sync Store Listings with Admin Panel"
            class="p-button-sm p-ml-4"
            icon="fa-solid fa-rotate"
            type="button"
            (click)="syncStoreV2('listings')"
          ></p-button>
          <p-button
            *ngIf="tableParams.customButtons.includes('syncPricePoints')"
            label="Sync Price Points with Admin Panel"
            class="p-button-sm p-ml-4"
            icon="fa-solid fa-rotate"
            type="button"
            (click)="syncStoreV2('price-points')"
          ></p-button> -->
        </div>
      </div>
    </div>
    <div class="p-mx-2 p-pt-2 p-d-flex p-jc-end p-mb-1">

      <ng-container *ngIf="tableParams?.customActionButtons?.includes('addToCart') && entity == 'store-listings-v2'">
        <p-button label="Add to Cart" styleClass="p-button-sm p-mr-4" icon="fa-solid fa-cart-shopping " type="button"
          (click)="addToCart('store-listings')">
        </p-button>
      </ng-container>

      <ng-container *ngIf="tableParams?.customActionButtons?.includes('addToCart') && entity == 'price-points'">
        <p-button label="Add to Cart" styleClass="p-button-sm p-mr-4" icon="fa-solid fa-cart-shopping " type="button"
          (click)="addToCart('price-points')">
        </p-button>
      </ng-container>

      <p-button  label="Columns" [outlined]="true" icon="fa-solid fa-table-columns" class="p-mx-1"
        [styleClass]="isColumnsPanelVisible ? 'p-button-sm p-button-outlined' : 'p-button-sm'"
        (click)="togglePanel('columns')">
      </p-button>
      <p-button *ngIf="!disabledTableActions.includes(TABLE_ACTIONS.COLUMNS_PRESETS)" [label]="TABLE_ACTIONS.COLUMNS_PRESETS" icon="fa-solid fa-diagram-successor" class="p-mx-1"
        [styleClass]="isColumnPresetsPanelVisible ? 'p-button-sm p-button-outlined' : 'p-button-sm'"
        (click)="togglePanel('columnPresets')">
      </p-button>
      <p-button  label="Filters" icon="pi pi-filter" class="p-mx-1"
        [styleClass]="isFiltersPanelVisible ? 'p-button-sm p-button-outlined' : 'p-button-sm'"
        (click)="togglePanel('filters')">
      </p-button>
      <p-button *ngIf="!disabledTableActions.includes(TABLE_ACTIONS.SAVED_FILTERS)" [label]="TABLE_ACTIONS.SAVED_FILTERS" icon="pi pi-filter-fill" class="p-mx-1"
        [styleClass]="isSavedFiltersPanelVisible ? 'p-button-sm p-button-outlined' : 'p-button-sm'"
        (click)="togglePanel('cmsFilters')">
      </p-button>
      <p-button *ngIf="!isGridViewVisible && !disabledTableActions.includes(TABLE_ACTIONS.GRID_VIEW_SETTINGS)" [label]="TABLE_ACTIONS.GRID_VIEW_SETTINGS"
        [icon]="isGridViewSettingsVisible ? 'fa-solid fa-grip' : 'fa-solid fa-table'" class="p-mx-1"
        [styleClass]="isGridViewSettingsVisible ? 'p-button-sm p-button-outlined' : 'p-button-sm'"
        (click)="togglePanel('gridViewSettings')">
      </p-button>
    </div>
  </div>
</div>

<div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; z-index: 9999;" *ngIf="loadingGrid">
  <p-progressSpinner
    styleClass="custom-spinner"
    strokeWidth="4"
    animationDuration="1s"></p-progressSpinner>
</div>


<ag-grid-angular
  #agGrid
  [gridOptions]="gridOptions"
  [columnDefs]="columnDefs"
  [defaultColDef]="defaultColDef"
  [rowData]="rowData"
  [class]="gridTheme"
  (gridReady)="onGridReady($event)"
  (selectionChanged)="onSelectionChanged($event)"
  (paginationChanged)="onPaginationChanged($event)"
  (rowSelected)="onRowSelected($event)"
  [ngStyle]="{
    'border': isFullEditMode ? '2px solid #81C784' : 'none',
    'width': '100%',
    'height': getGridHeight()|| tableHeight || 'auto' 
  }"
>
</ag-grid-angular>

<p-dialog
  [(visible)]="dialogImageUrl"
  [dismissableMask]="true"
  [modal]="true"
  [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw', height: '80vh', overflow: 'hidden' }"
>
  <ng-template pTemplate="header">
    <h3 class="p-m-0">{{ dialogImageTitle ? dialogImageTitle : 'Thumbnail' }}</h3>
  </ng-template>

  <div class="p-text-center p-p-0" style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; overflow: hidden;">
    <img *ngIf="dialogImageUrl"
         [src]="dialogImageUrl + '?ts=' + timestamp"
         style="width: auto; max-width: 100%; height: auto; max-height: 100%; object-fit: contain;" />
  </div>
</p-dialog>


<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<app-ag-grid-actions #actionsDialog [gridApi]="api" [columnApi]="columnApi" [entity]="entity" [mongoQuery]="mongoQuery"
  [mongoSort]="mongoSort" [bulkUpdateColDefs]="bulkUpdateColDefs"
  [areRowsSelected]="areRowsSelected" [rowsSelected]="rowsSelected" [selectedRowsSet]="selectedRowsSet"
  [isSelectAll]="isSelectAll" [loadedRowCount]="loadedRowCount"
  [totalRecords]="totalRowCount" [changedRows]="changedRows" [buildParams]="buildParams" [userParams]="userParams"
  [tableParams]="tableParams" [populateFields]="populateFields" [fetchAllRowsFromBackend]="fetchAllRowsFromBackend.bind(this)"></app-ag-grid-actions>

<asset-popup-form
  *ngIf="tableParams && tableParams.customButtons && tableParams.customButtons.includes('addAsset') && imagerySettingsLoaded && entityTypesLoaded"
  [isVisible]="displayAssetDialog" [entity]="miscAssetEntity" [parentEntityTypeOptions]="entityTypeOptions"
  [imagerySettings]="imagerySettings" [type]="'miscellaneous-build'" [buildType]="3"
  (formSubmitted)="reloadDataSource($event)" [showOnAssetBundleOnly]="false"
  (onHide)="hideAssetDialog()"></asset-popup-form>

<string-popup-form (onHide)="hideStringDialog()" [type]="entity" [isVisible]="displayStringDialog">

</string-popup-form>

<p-toast></p-toast>
<app-dup-dialog #dupDialog [entity]="entity" [viewRoute]="viewRoute" (duplicationSuccess)="handleDuplicationSuccess($event)"></app-dup-dialog>

<p-dialog 
  [(visible)]="dialogVisible" 
  [modal]="true" 
  [closable]="false" 
  [header]="dialogData?.header" 
  [style]="{width: '600px'}"
>
  <div *ngIf="dialogData">
    <p>{{ dialogData.content }}</p>
    <div style="max-height: 150px; overflow-y: auto; margin-top: 10px;">
      <p *ngIf="dialogData.ids">Referenced IDs: {{ dialogData.ids.join(', ') }}</p>
    </div>
    <p-footer>
      <div style="display: flex; justify-content: flex-end; margin-top: 10px;">
        <button 
          pButton 
          label="{{ dialogData.footerLabel }}" 
          (click)="closeDialog()" 
        ></button>
      </div>
    </p-footer>
  </div>
</p-dialog>




