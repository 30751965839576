export enum BaseViewFieldType {
  Text = 'Text',
  Calendar = 'Calendar',
  Link = 'Link',
  NameLink = 'NameLink',
  MultiSelectObj = "MultiSelectObj",
  MultiSelect = "MultiSelect",
  Title = "Title",
  DropdownWithLabels = 'DropdownWithLabels',
  NestedList = 'NestedList',
  Boolean = 'Boolean',
  Dropdown = 'Dropdown',
  GridLink = 'GridLink',
  TimeTaken = "TimeTaken",
  ColorDisplay = 'ColorDisplay',
  RichText = 'RichText',
}
