import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Seed Pack DTO
 */
export default class NurtureCardDTO extends BaseEntityDto
{
    name: string = "";

    enabled: boolean;

    image_ref: any;

    video_ref: any;
}
