<ng-container *ngFor="let viewType of viewTypes">
    <ng-container [ngSwitch]="viewType">

        <!-- BaseViewFieldType.Text -->
        <div *ngSwitchCase="BaseViewFieldType.Text" class="view-field">
            <h4 *ngIf="field.label && field.label.trim()" class="p-d-inline">{{ field.label }}: </h4> {{ getNestedValue(record, field.key) }}
        </div>

        <!-- BaseViewFieldType.Calendar -->
        <div *ngSwitchCase="BaseViewFieldType.Calendar" class="view-field">
            <div>
                <h4 class="p-d-inline">{{ field.label }}: </h4> {{ getNestedValue(record, field.key) | officeTime: 'short' }}
            </div>
            <div *ngIf="record[field.key] && !checkTimezone">
                <div>
                    <h5 class="p-d-inline">Local Time: </h5><small>{{ localTime(getNestedValue(record, field.key))  }}</small>
                </div>
            </div>
        </div>

        <!-- BaseViewFieldType.Link -->
        <div *ngSwitchCase="BaseViewFieldType.Link" class="view-field">
            <h4 class="p-d-inline">{{ field.label }}: </h4>
            <a *ngIf="getNestedValue(record, field.key)" [href]="'/' + field.controller + '/' + getNestedValue(record, field.key).id" style="color: var(--primary-color)">
                {{ getNestedValue(record, field.key).name }}({{ getNestedValue(record, field.key).id }})
            </a>
        </div>
        <!-- BaseViewFieldType.GridLink -->
        <div *ngSwitchCase="BaseViewFieldType.GridLink" class="view-field">
            <h4 class="p-d-inline">{{ field.label }}: </h4>
            <a *ngIf="getNestedValue(record, field.key)" [href]="'/' + field.controller + '/' + record.id" style="color: var(--primary-color)">
                {{ getNestedValue(record, field.key) }}({{ record.id }})
            </a>
        </div>

        <!-- BaseViewFieldType.NameLink -->
        <div *ngSwitchCase="BaseViewFieldType.NameLink" class="view-field">
            <h4 class="p-d-inline">{{ field.label }}: </h4>
            <a *ngIf="getNestedValue(record, field.key)?.[0]" [href]="'/' + field.controller + '/' + getNestedValue(record, field.key)[0].id" style="color: var(--primary-color)">
                {{ getNestedValue(record, field.key)[0].name }}({{ getNestedValue(record, field.key)[0].id }})
            </a>
        </div>

        <!-- BaseViewFieldType.Dropdown -->
        <div *ngSwitchCase="BaseViewFieldType.Dropdown" class="view-field">
            <h4 class="p-d-inline">{{ field.label }}: </h4>
            <span>{{ getDropdownLabel(record, field.key) }}</span>
        </div>
        
        <!-- BaseViewFieldType.MultiSelect -->
        <div *ngSwitchCase="BaseViewFieldType.MultiSelect" class="view-field">
            <h4 class="p-d-inline">{{field.label}}: </h4>
            <ul>
                <li *ngFor="let item of getNestedValue(record, field.key)">{{item}}</li>
            </ul>
        </div>

        <!-- BaseViewFieldType.MultiSelectObj -->
        <div *ngSwitchCase="BaseViewFieldType.MultiSelectObj" class="view-field">
            <h4 class="p-d-inline">{{field.label}}: </h4>
            <ul>
                <li *ngFor="let item of getNestedValue(record, field.key)">
                    {{item.name}}
                    <a *ngIf="item.id" [href]="'/' + field.controller + '/' + item.id" style="color: var(--primary-color)">
                        ({{item.id}})
                    </a>
                </li>
            </ul>
        </div>

        <!-- BaseViewFieldType.Title -->
        <div *ngSwitchCase="BaseViewFieldType.Title" class="view-field">
            <ng-container [ngSwitch]="field.tagType">
            <h1 *ngSwitchCase="'h1'">{{ field.key ? getNestedValue(record, field.key) : field.label }}</h1>
            <h2 *ngSwitchCase="'h2'">{{ field.key ? getNestedValue(record, field.key) : field.label }}</h2>
            <h3 *ngSwitchCase="'h3'">{{ field.key ? getNestedValue(record, field.key) : field.label }}</h3>
            <h4 *ngSwitchCase="'h4'">{{ field.key ? getNestedValue(record, field.key) : field.label }}</h4>
            <h5 *ngSwitchCase="'h5'">{{ field.key ? getNestedValue(record, field.key) : field.label }}</h5>
            <p *ngSwitchCase="'p'">{{ field.key ? getNestedValue(record, field.key) : field.label }}</p>
            <small *ngSwitchCase="'small'">{{ field.key ? getNestedValue(record, field.key) : field.label }}</small>
            <!-- Default case if tagType is not specified -->
            <p *ngSwitchDefault>{{ field.key ? getNestedValue(record, field.key) : field.label }}</p>
            </ng-container>
        </div>

        <!-- BaseViewFieldType.DropdownWithLabels -->
        <div *ngSwitchCase="BaseViewFieldType.DropdownWithLabels" class="view-field">
            <h4 class="p-d-inline">{{ field.label }}: </h4>
            <span>{{ getLabelForValue(getNestedValue(record, field.key)) }}</span>
        </div>

        <!-- BaseViewFieldType.ColorDisplay -->
        <div *ngSwitchCase="BaseViewFieldType.ColorDisplay" class="view-field">
            <div class="color-display-container">
                <strong>{{ field.label }}:</strong>
                <div class="color-box" [style.background]="getNestedValue(record, field.key)"></div>
                <span class="color-code">{{ getNestedValue(record, field.key) }}</span>
            </div>
        </div>
        
        <!-- BaseViewFieldType.NestedList -->
        <div *ngSwitchCase="BaseViewFieldType.NestedList" class="view-field">
            <ng-container *ngFor="let nestedKey of getNestedValue(record, field.key)">
                <ng-container *ngIf="isObject(nestedKey)">
                    <strong>{{ camelCaseToWords(field.label) }}:</strong>
                    <ul>
                        <li>
                            <app-dynamic-list [record]="nestedKey"></app-dynamic-list>
                        </li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="isArray(nestedKey)">
                    <strong>{{ camelCaseToWords(field.label) }}:</strong>
                    <ul>
                        <ng-container *ngFor="let item of nestedKey">
                            <li *ngIf="isObject(item)">
                                <app-dynamic-list [record]="item"></app-dynamic-list>
                            </li>
                            <li *ngIf="!isObject(item)">
                                {{ item }}
                            </li>
                        </ng-container>
                    </ul>
                </ng-container>
                <ng-container *ngIf="!isObject(nestedKey) && !isArray(nestedKey)">
                    <strong>{{ camelCaseToWords(field.label) }}:</strong> {{ nestedKey }}
                </ng-container>
            </ng-container>
        </div>

        <!-- BaseViewFieldType.Boolean -->
        <div *ngSwitchCase="BaseViewFieldType.Boolean" class="view-field">
            <h4 class="p-d-inline">{{ field.label }}: </h4>
            <span>{{ getBooleanLabel(record[field.key], field.booleanoptions) }}</span>
        </div>
        <!-- BaseViewFieldType.RichText for Rich Text Rendering -->
        <div *ngSwitchCase="BaseViewFieldType.RichText" class="view-field">
            <h4 class="p-d-inline">{{ field.label }}:</h4>
            <div class="p-ml-2" [innerHTML]="getSafeHtml(getNestedValue(record, field.key))"></div>
        </div>
        <!-- BaseViewFieldType.TimeTaken -->
        <div *ngSwitchCase="BaseViewFieldType.TimeTaken" class="view-field">
            <h4 class="p-d-inline">{{ field.label }}: </h4>
            <span>
            {{ formatTimeTaken(getNestedValue(record, field.key)) }}
            </span>
        </div>
    </ng-container>
</ng-container>
