<!-- Spinner -->
<ngx-spinner
  bdColor="rgba(36,34,36,0.8)"
  size="medium"
  color="#81c784"
  type="line-scale"
  name="release-form-spinner"
>
  <p style="font-size: 20px; color: white">Saving...</p>
</ngx-spinner>

<p-messages></p-messages>
<p-toast></p-toast>

<h1>{{ !isEditMode ? 'Add New': 'Editing' }} Release {{ isEditMode ? releaseId : null }}</h1>

<div class="p-grid" *ngIf="!isLoading">
    <div class="p-col-12">
        <form (ngSubmit)="onSubmit()">
            <button
                pButton
                pRipple
                type="submit"
                label="Submit"
                class="p-mb-4"
                [style]="{ 'min-width': '12.5em' }"
            ></button>
            <div class="">
                <span ngClass="p-float-label">
                <p-toggleButton
                    [(ngModel)]="releaseRecord.enabled"
                    [onLabel]="'Enabled'"
                    [offLabel]="'Not Enabled'"
                    class="field-item"
                    [style]="{ width: '100%' }"
                    [ngModelOptions]="{standalone: true}"
                    (onChange)="onEnabledChange()"
                ></p-toggleButton>
                </span>
            </div>
             <!-- CARD: Top Details -->
            <p-card class="p-mx-2">
                <div class="p-grid p-mx-5">
                    <div class="p-col">
                        <!-- Name -->
                        <div class="p-my-5 p-inputgroup">
                            <span ngClass="p-float-label">
                                <input [(ngModel)]="releaseRecord.name" [ngModelOptions]="{ standalone: true }" pInputText class="field-item" />
                                <label for="name"> Name <strong style="color: crimson">*</strong></label>
                            </span>
                            <p-button
                                *ngIf="releaseRecord.name"
                                ariaLabel="Clear Field"
                                icon="pi pi-minus"
                                styleClass="p-button-text p-button-danger"
                                (click)="releaseRecord.name = ''"
                            ></p-button>
                        </div>
                        <!-- Layout -->
                        <div class="p-my-5 p-inputgroup">
                            <span ngClass="p-float-label">
                                <p-dropdown
                                  [(ngModel)]="releaseRecord.layout"
                                  [ngModelOptions]="{ standalone: true }"
                                  [options]="layouts"
                                  optionLabel="name"
                                  optionValue="value"
                                  [autoDisplayFirst]="false"
                                  [filter]="true"
                                  filterBy="name"
                                  id="layouts"
                                >
                                </p-dropdown>
                                <label for="layouts"> Layout </label>
                            </span>
                            <p-button
                                *ngIf="releaseRecord.layout"
                                ariaLabel="Clear Field"
                                icon="pi pi-minus"
                                styleClass="p-button-text p-button-danger"
                                (click)="releaseRecord.layout = null"
                            ></p-button>
                        </div>
                        <!-- Loading Screeens -->
                        <div class="p-my-5 p-inputgroup">
                            <dropdown-multiselect
                                [(selectedOptions)]="releaseRecord.loadingScreen_ref"
                                optionLabel="name"
                                optionValue="_id"
                                labelName="Loading Screens"
                                apiControllerRoute="loading-screens"
                                [queryLimitValue]="15"
                                [useSetOptionsFromRef]="false"
                                style="width: 100%;"
                                [isArrayOfStringIds]="false"
                                (onSelectedOptionsChange)="onRefsChange('loading-screens', $event)"
                            >
                            </dropdown-multiselect>
                        </div>
                        <!-- Inbox Messages -->
                        <div class="p-my-5 p-inputgroup" style="max-width:90vw">
                            <dropdown-multiselect
                                [(selectedOptions)]="releaseRecord.inboxMessages_ref"
                                optionLabel="name"
                                optionValue="_id"
                                labelName="Inbox Messages"
                                apiControllerRoute="inbox-messages"
                                [queryLimitValue]="45"
                                [useSetOptionsFromRef]="false"
                                style="width: 100%;"
                                [isArrayOfStringIds]="false"
                                (onSelectedOptionsChange)="onRefsChange('inbox-messages', $event)"
                            >
                            </dropdown-multiselect>
                        </div>
                        <p-divider align="left">
                            <b>Release Dates</b>
                        </p-divider>
                        <div>
                            <p-button
                                *ngIf="releaseRecord && releaseRecord.release_dates &&  releaseRecord.release_dates.length < 1"
                                ariaLabel="Clear Field"
                                icon="pi pi-plus"
                                label="Add Release Date"
                                styleClass="p-button-text p-button-success"
                                (click)="pushDate()"
                            ></p-button>
                        </div>
                        <div class="p-my-5 p-inputgroup field" *ngFor="let date of releaseRecord.release_dates; let i = index; let last = last; let first = first">
                            <span class="p-float-label">
                            <input [(ngModel)]="date.description" [ngModelOptions]="{ standalone: true }" pInputText class="p-inputgroup"/>
                            <label for="description"> Date Description</label>
                            </span>
                            <p-button
                                *ngIf="date.description"
                                ariaLabel="Clear Field"
                                icon="pi pi-minus"
                                styleClass="p-button-text p-button-danger"
                                (click)="date.description = null"
                            ></p-button>
                            <span class="p-float-label">
                            <p-calendar
                                [(ngModel)]="date.date"
                                [ngModelOptions]="{ standalone: true }"
                                [ariaLabel]="'Timeline Dates'"
                                [showTime]="true"
                                [yearNavigator]="true"
                                placeholder="Select a Date"
                                yearRange="2020:2030"
                                [monthNavigator]="true"
                                [style]="{ width: '100%' }"
                                [selectOtherMonths]="true"
                                [touchUI]="true"
                                class="p-mx-5"
                                (onFocus)="setDefaultTimeDynamicDates(date, i)"
                            >
                            </p-calendar>
                        </span>
                        <p-button
                                *ngIf="date.date"
                                ariaLabel="Clear Field"
                                icon="pi pi-minus"
                                styleClass="p-button-text p-button-danger"
                                (click)="date.date = null"
                                class="p-mr-5"
                            ></p-button>
                        <p-button
                                *ngIf="releaseRecord && last"
                                ariaLabel="Clear Field"
                                icon="pi pi-plus"
                                styleClass="p-button-text p-button-success"
                                (click)="pushDate()"
                            ></p-button>
                        <p-button
                                *ngIf="date && last && !first"
                                ariaLabel="Clear Field"
                                icon="pi pi-minus"
                                styleClass="p-button-text p-button-danger"
                                (click)="releaseRecord.release_dates.pop()"
                            ></p-button>

                            <p-button
                                *ngIf="last && first"
                                ariaLabel="Clear Field"
                                icon="pi pi-minus"
                                styleClass="p-button-text p-button-danger"
                                (click)="date.description = null; date.date = null; releaseRecord.release_dates.pop()"
                            ></p-button>
                        </div>
                    </div>
                </div>
            </p-card>
            <!-- CARD: 'Layouts' -->
            <p-card class="p-mx-2" *ngIf="!isLoading && releaseRecord.layout && releaseRecord.layout.length > 0">
                <div class="p-grid p-mx-5">
                    <div class="p-col-12 p-pr-4">
                        <p-panel header="{{releaseRecord.layout}}" [toggleable]="true">
                            <ng-template pTemplate="icons">
                                    <p-menu #menu id="config_menu" [model]="items" [popup]="true"></p-menu>
                            </ng-template>
                            <series-form *ngIf="releaseRecord.layout == 'Series'" [(seriesRecord)]="seriesRecord"></series-form>
                        </p-panel>
                    </div>
                </div>
            </p-card>
        </form>
    </div>
</div>
