import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import RoleDto from '../dto/RoleDto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private readonly apiURLprefix : string = '/api/roles';

  constructor(private http: HttpClient) {}

  private role = new BehaviorSubject<RoleDto | null>(null);

  role$ = this.role.asObservable();

  /**
   * Assign a permission to a given user.
   *
   * @param userPermission User Permission DTO object
   */
  async createRole(role: RoleDto): Promise<RoleDto | undefined> {
    return await this.http.post<RoleDto>(`${this.apiURLprefix}/add`, role).toPromise();
  }

  setRole(role: RoleDto) {
    this.role.next(role);
  }
}
