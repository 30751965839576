import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-diff-tool-view',
  templateUrl: './diff-tool-view.component.html',
  styleUrls: ['./diff-tool-view.component.sass']
})
export class DiffToolViewComponent implements OnInit {
  jobRecordsArray: any[] = []; 
  isLoading: boolean = true;
  title: string = 'Diff Tool';
  entityType: string = 'diff-jobs'; 
  recordId!: number; 
  nestedPopulate: boolean = true; 
  diffToolRecord: any = {}; 

  fields: any[] = []; 

  constructor(
    private commonEntityService: CommonEntityService, 
    private route: ActivatedRoute 
  ) {}

  ngOnInit() {
    this.fields = [
      {
        title: "Diff Tool Data",
        fields: [
          { name: "Status", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'status', label: 'Status', columnWidth: 4 },
          { name: "Diff Type", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'diffType', label: 'Diff Type', columnWidth: 4 },
          { name: "Diff Qty", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'diffQty', label: 'Diff Qty', columnWidth: 4 },
          { name: "Created By", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'createdBy', label: 'Created By', columnWidth: 4 },
          { name: "Created At", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'createdAt', label: 'Created At', columnWidth: 4 },
          { name: "Job Id", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'uuid', label: 'Job Id', columnWidth: 12 },
          { name: "Diff Ref", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'diffRef', label: 'Diff Ref', columnWidth: 12 },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Comparison Results", 
        fields: [
          { name: "Jobs Records", inputType: DynamicViewType.JobsRecordTable, clearField: true, key: 'jobRecords', label: 'Jobs Records' }
        ],
        type: DynamicCardType.Card
      }
    ];
    
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      if (id) {
        this.recordId = +id; 
        this.loadRecordData();
      } else {
        console.error("No 'id' found in route parameters");
      }
    });
  }

  async loadRecordData() {
    try {
      const response = await this.commonEntityService.findOneWithQuery(this.entityType, {
        query: { id: this.recordId },
        autopopulate: true,
        nestedPopulate: this.nestedPopulate,
      });

      if (response) {
        this.diffToolRecord = response;  
        if (response.jobRecords && typeof response.jobRecords === 'object') {
          this.jobRecordsArray = Object.values(response.jobRecords);
          this.diffToolRecord.jobRecords = this.jobRecordsArray;  
        } else if (Array.isArray(response.jobRecords)) {
          this.jobRecordsArray = response.jobRecords;
          this.diffToolRecord.jobRecords = response.jobRecords;
        } else {
          console.error("Unexpected jobRecords format:", response.jobRecords);
        }
      } else {
        console.error("Response is undefined or null", response);
      }
    } catch (error) {
      console.error("Error loading record data:", error);
    } finally {
      this.isLoading = false;
    }
  }

}