import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { GridViewComponent } from '../../components/grid-view/grid-view.component';

@Injectable({
  providedIn: 'root'
})
export class TableCommunicationService {

  private eventSource = new BehaviorSubject<any>(null);
  event$ = this.eventSource.asObservable();
  private refreshGridSource = new BehaviorSubject<any>(null);
  refreshGrid$ = this.refreshGridSource.asObservable();
  rows: any;

  // Add new BehaviorSubject for line item options
  private lineItemOptionsSource = new BehaviorSubject<any>(null);
  lineItemOptions$ = this.lineItemOptionsSource.asObservable();
  // Add a new subject for options updates
  private optionsUpdateSource = new BehaviorSubject<any>(null);
  optionsUpdate$ = this.optionsUpdateSource.asObservable();

  emitEvent(event: any) {
    this.eventSource.next(event);
  }

  // Observable string sources
  private editRowSource = new Subject<number>();
  private cancelEditRowSource = new Subject<number>();
  private submitSaveRowSource = new Subject<number>();
  private fullEditModeSource = new Subject<void>();
  private rowCountSource = new BehaviorSubject<number>(0);
  private addToCartSource = new Subject<string>();
  private duplicateRowSource = new Subject<any>();
  private deleteRowSource = new Subject<any>();

  private editClientStringSource = new Subject<any>();

  private currentPageData = new BehaviorSubject<any[]>([]);
  private requestCurrentPageData = new Subject<void>();
  private gridViewSettings = new Subject<void>();

  // Observable string streams
  editRow$ = this.editRowSource.asObservable();
  cancelEditRow$ = this.cancelEditRowSource.asObservable();
  submitSaveRow$ = this.submitSaveRowSource.asObservable();
  public fullEditMode$ = this.fullEditModeSource.asObservable();
  rowCount$ = this.rowCountSource.asObservable();
  addToCart$ = this.addToCartSource.asObservable();
  duplicateRow$ = this.duplicateRowSource.asObservable();
  deleteRow$ = this.deleteRowSource.asObservable();
  editClientString$ = this.editClientStringSource.asObservable();

  // grid mode
  currentPageData$ = this.currentPageData.asObservable();
  requestCurrentPageData$ = this.requestCurrentPageData.asObservable();
  gridViewSettings$ = this.gridViewSettings.asObservable()


  // Service message commands
  editRow(row: number) {
    this.editRowSource.next(row);
  }

  editClientString(show: any) {
    this.editClientStringSource.next(show)
  }

  cancelEditRow(row: number){
    this.cancelEditRowSource.next(row);
  }

  submitSaveRow(row: number){
    this.submitSaveRowSource.next(row);
  }

  toggleFullEditMode() {
    this.fullEditModeSource.next();
  }

  setRowCount(count: number) {
    this.rowCountSource.next(count);
  }

  setCurrentPageData(data: any[]) {
    this.currentPageData.next(data);
  }

  // Service method to trigger the request
  triggerCurrentPageDataUpdate() {
    this.requestCurrentPageData.next();
  }

  addToCart(type: string) {
    this.addToCartSource.next(type);
  }

  duplicateRow(payload: any) {
    this.duplicateRowSource.next(payload);
  }

  deleteRow(payload: any) {
    this.deleteRowSource.next(payload)
  }

  emitRefreshGrid(event: any) {
    // console.log(event)
    this.rows = event?.records ? event.records : event
    // console.log(this.rows)
    this.refreshGridSource.next(this.rows)
  }

  // Add new method to share line item options
  setLineItemOptions(options: any) {
    this.lineItemOptionsSource.next(options);
  }
  // Add a new method to emit options updates
  emitOptionsUpdate(options: any) {
    this.optionsUpdateSource.next(options);
  }

}
