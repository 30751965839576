import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import _ from 'lodash';
import { DialogService } from '../../ag-grid-table/services/ag-grid-dialog.service';
import { DataService } from 'src/app/services/data.service';
import { TableCommunicationService } from '../../ag-grid-table/services/table-communication.service';
@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class GridViewComponent implements OnInit {
  @Input() rowData: any[];
  @Input() entity: string;
  dialogImageUrl: string | null = null;
  dialogImageTitle: string | null = null;
  timestamp = new Date().getTime();
  isDialogVisible = false; // New boolean property
  variables: any;
  isVisible: any;


  constructor(
    private cdr: ChangeDetectorRef, 
    private dialogService: DialogService, 
    private dataService: DataService,
    private tableCommunicationsService: TableCommunicationService
    ) {}

  async ngOnInit() {
    this.tableCommunicationsService.refreshGrid$.subscribe(event => {
      // Call your method here
      this.isVisible = false
      this.getRows(event);
      this.isVisible = true
    });
    await this.syncGridViewSettings()
    this.cdr.detectChanges(); // Manually trigger change detection
  }

  ngOnDestroy() {

  }

  async syncGridViewSettings(){
    try {
      let pageRef = 'ag-grid-'+this.entity;
      // Code to save the current column state to the user's profile
      let settings = await this.dataService.getUserSettings(pageRef, `gridViewSettings`);
      // Extract 'settings' attribute and store them in 'this.customPresets'
      this.variables = settings[0].settings;


      // console.log('user presets: ', userPresets);
      // console.log('community presets: ', communityPresets);
    } catch (error) {
      console.error("Failed to update user settings", error);
    }
  }

  onThumbnailClick(record: any) {
    if (record.thumbnail_ref && record.thumbnail_ref.thumbnails && record.thumbnail_ref.thumbnails[1]) {
      this.dialogImageUrl = record.thumbnail_ref.thumbnails[1].path.replace('_256', '_1024');
      this.dialogImageTitle = record.name;
    } else {
      this.dialogImageUrl = 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
      this.dialogImageTitle = 'Thumbnail';
    }
    this.dialogService.showDialog(this.dialogImageUrl, this.dialogImageTitle);
  }

  async getRows(event: any) {
    if(event) {
      this.rowData = event
    }
    
    this.cdr.markForCheck()
  }

}
