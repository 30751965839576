import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseviewComponent } from 'src/app/common/components/baseview/baseview.component';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { BuildType } from 'src/app/enums/build-type';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import _ from 'lodash';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-daily-rewards-view',
  templateUrl: './daily-rewards-view.component.html',
  styleUrls: ['./daily-rewards-view.component.sass'],
})
export class DailyRewardsViewComponent implements OnInit
{
  entity: any;
  type = 'daily-rewards';
  isLoading: boolean;
  id: any;
  dailyRewards: any = [];


  constructor
  (
    private entityViewService: EntityViewService,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private datePipe: DatePipe,
    private titleService: Title
  ) {}

  /**
   * Daily Rewards view component initialization
   */
  async ngOnInit()
  {
    this.isLoading = true;
    const routeParams = this.route.snapshot.paramMap;

    this.id = Number(routeParams.get('id'));

    await this.entityViewService
      .getEntity(this.type, { query: { id: this.id }, autopopulate: true })
      .then((result: any) => {
        if (result) {
          this.entity = result;
          if (this.entity.name) {
            this.titleService.setTitle(this.entity.name);
          }

        }
      });
    await this.loadDailyRewards();
    console.log('this.entity',this.entity.rewardsTracks.name)
    this.isLoading = false;
  }


  async loadDailyRewards() {
    for(let key of Object.keys(this.entity)) {
      if(key.includes('dailyRewardDay')) {
        this.dailyRewards.push(this.entity[key][0])
      }

    }
  }

}
