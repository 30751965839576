<!-- Toaster Alert -->
<p-toast></p-toast>

<!-- Confirm Modal -->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<!-- Confirm Build Button -->
<button id="build-asset-button" 
    [disabled]="disableButton || btnDisabled ? true : false" 
    pButton pRipple type="button" (click)="confirmBuildQueue($event)" [label]="label"
    tooltipPosition="top" tooltipPositionStyle="absolute" tooltipEvent="hover"
    [pTooltip]="disabledToolTipMessage ? disabledToolTipMessage : null">
</button>
