import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import RewardsTrackDTO from 'src/app/cms-v2/entities/rewards-track/dtos/RewardsTrackDTO';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { EndlessOfferService } from 'src/app/common/services/endless-offer.service';

@Component({
  selector: 'app-endless-offer-track-v2',
  templateUrl: './endless-offer-track-v2.component.html',
  styleUrls: ['./endless-offer-track-v2.component.css']
})
export class EndlessOfferTrackComponentV2 implements OnInit {

  @Input() eoTrack: RewardsTrackDTO;
  @Input() parentEntity: string;
  public isLoading: boolean = true;
  public envKey: 'dev' | 'qa' | 'prod' | 'test' = this.loadSelectedEnvironment();

  constructor(
    private endlessOfferService: EndlessOfferService,
    private commonEntityService: CommonEntityService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.isLoading = false;
    if (this.eoTrack?.EOrewards?.length > 0) {
      await this.fetchThumbnailForCredits(this.eoTrack.EOrewards);
    }
    console.log('EndlessOfferTrackComponentV2: rewardTrack', this.eoTrack);
  }

  loadSelectedEnvironment(): 'dev' | 'qa' | 'prod' | 'test' {
    const savedEnvironment = localStorage.getItem('selectedEnvironment');
    if (savedEnvironment) {
      const environmentObject = JSON.parse(savedEnvironment);
      return environmentObject.value;
    } else {
      return 'dev'
    }
  }

  async fetchThumbnailForCredits(EOrewards: any): Promise<void> {
    // Loop through each EOrewards (each representing a collection of store listings)
    for (let i = 0; i < EOrewards.length; i++) {
      let creditsArray = EOrewards[i];

      for (let j = 0; j < creditsArray.length; j++) {
        if (creditsArray[j].lineItemType !== 'StoreListingV2') {
          continue;
        }
        console.log('Filling data for: storeListing: ', creditsArray[j].id);

        // Fetch pricePoint and credits in parallel using Promise.all
        const promises = [];

        // Fetch the pricePoint
        promises.push(
          this.commonEntityService.findOneWithQuery('price-points', {
            query: { _id: creditsArray[j].id.pricePoint_ref[this.envKey] },
            select: 'id name',
            autopopulate: false
          })
        );

        // Prepare to fetch credits in parallel
        const creditsPromises = creditsArray[j].id.credits_ref[this.envKey].map(async (credit: any) => {
          const controllerName = this.getControllerName(credit.lineItemType);
          const imageSrc = await this.getImageSrc(controllerName, credit.id);
          credit.id.imageSrc = imageSrc;
        });

        // Add the credits fetch promises to the array
        promises.push(...creditsPromises);

        // Wait for all the promises (pricePoint and credits) to resolve
        const [pricePoint] = await Promise.all(promises);

        // Assign the pricePoint to the creditsArray
        creditsArray[j].id.pricePoint = pricePoint;
      }
    }
  }

  getControllerName(type: string): string {
    let controller = 'currencies';
    switch (type) {
      case 'Item':
        controller = 'items';
        break;
      case 'Currency':
        controller = 'currencies';
        break;
      case 'Titles':
        controller = 'titles';
        break;
      case 'SeedPackBox':
        controller = 'seed-pack-box';
        break;
      case 'StoreListingV2':
        controller = 'store-listings-v2';
        break;
      case 'Nurture':
        controller = 'nurtures';
        break;
      default:
        return '#';
    }
    return controller;
  }

  async getImageSrc(controller: string, credit: any): Promise<string> {
    const creditIdData = await this.commonEntityService.findOneWithQuery(controller, {
      query: { _id: credit._id },
      smartPopulate: {
        'image_ref': 'id _id name thumbnails',
        'box_image_ref': 'id _id name thumbnails',
        'thumbnail_ref': 'id _id name thumbnails'
      },
      autopopulate: false
    });

    switch (controller) {
      case 'seed-pack-box':
        return creditIdData?.box_image_ref?.thumbnails[4]?.path;
      case 'items':
        return creditIdData?.thumbnail_ref?.thumbnails[4]?.path;
      default:
        return creditIdData?.image_ref?.thumbnails[4]?.path;
    }
  }

  getLink(type: string, id: string): string {
    let baseUrl = '';
    switch (type) {
      case 'Item':
        baseUrl = '/items';
        break;
      case 'Currency':
        baseUrl = '/currencies';
        break;
      case 'Titles':
        baseUrl = '/titles';
        break;
      case 'SeedPackBox':
        baseUrl = '/seed-pack-box';
        break;
      case 'StoreListingV2':
        baseUrl = '/store-listings-v2';
        break;
      case 'Nurture':
        baseUrl = '/nurture';
        break;
      default:
        return '#';
    }
    return `${baseUrl}/${id}`;
  }
}
