<div style="min-width: 225px; max-width: 450px; min-height: 300px; margin-top: 7px; " class="p-d-block p-mx-auto" >
    <img
      *ngIf="displayThumbnail && record.thumbnails && record.thumbnails[0]"
      [src]="record.thumbnails[0].path+'?ts=' + timestamp"
      class="p-mx-auto"
      width="100%"
      (click)="displayImage(record.thumbnails[0].path)"
    />
    <div class="p-grid" *ngIf="displayThumbnail && record.thumbnails">
      <div class="p-col-fixed" style="width:100px">
        <button pButton
        type="button" icon="pi pi-copy"
        class="p-button-text p-ml-3 p-mt-1"
        (click)="copyToClipboard(record.thumbnails[0].path)"
        ></button>
      </div>
      <div class="p-col" style="min-width:200px">
        <small *ngIf="displayThumbnail && record.thumbnails && record.thumbnails[0]" style="overflow-wrap: anywhere">
          {{record.thumbnails[0].path}}
        </small>
      </div>
    </div>
  </div>
