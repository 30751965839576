import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'select-multiple-colors',
  templateUrl: './select-multiple-colors.component.html',
  styleUrls: ['./select-multiple-colors.component.sass']
})
export class SelectMultipleColorsComponent implements OnInit 
{
  @Input() colors: Array<string> = [];
  @Output() onColorChange = new EventEmitter<any>();
  readonly defaultColor: string = "#ff0000";
  isLoading: boolean = true;
  toggle: boolean = false;
  selectedColors: Array<any> = [];

  constructor() { }

  /**
   * Select Multiple Color Component Initialization
   */
  async ngOnInit()
  {
    this.selectedColors = [];
    if(this.colors && this.colors.length > 0)
    {
      this.colors.forEach(color => 
      {
        this.selectedColors.push({ value: color });
      });
    }
    this.isLoading = false;
  }

  /**
   * Adds a new color
   */
  addColor() 
  {
    this.selectedColors.push({ value: this.defaultColor });
    this.colors.push(this.defaultColor);
    this.onColorChange.emit(this.colors);
  }
   
  /**
   * Deletes a color from array of colors
   * 
   * @param index Index of the color to remove.
   */
  deleteColor(index: number) 
  {
    this.selectedColors.splice(index, 1);
    this.colors.splice(index, 1);
    this.onColorChange.emit(this.colors);
  }

  /**
   * Handle Color Selection
   * 
   * @param colorSelected The selected color value
   * @param index Index of the color picker
   */
  colorSelected(colorSelected: any, index: number)
  {
    this.colors[index] = colorSelected.value;
    this.onColorChange.emit(this.colors);
  }
  
  
  /**
   * Handles the color inputs field
   * 
   * @param colorSelected  the color input
   * @param index  the position within the colors array where it is being updated
   * selectedColors possesses the vaule of the new color
   */
  onChange(colorSelected: any, index: number)
  {
    this.colors[index] = this.selectedColors[index].value
    this.onColorChange.emit(this.colors);
  }
}