import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { MessageService } from 'primeng/api';
import { ViewportScroller } from '@angular/common';
import * as _ from 'lodash';
import { pathconfigValues } from '../data/constants';
import { DataService } from 'src/app/services/data.service';
import { ChallengeService } from './challenge.service';
import { Router } from '@angular/router';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { AuthService } from 'src/app/auth/auth.service';
import { environments } from 'src/app/common/constants/constants';
import { firstValueFrom } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { OfficeTimePipe } from 'src/app/common/pipes/officeTime.pipe';

@Injectable({
  providedIn: 'root'
})
export class ChallengeValidationService
{

  constructor
  (
    private http: HttpClient,
    private commonEntityService: CommonEntityService,
    private loggerService: LoggerService,
    private utilitiesService: UtilitiesService,
    private messageService: MessageService,
    private dataService: DataService,
    private challengeService: ChallengeService,
    private router: Router,
    private validationService: ValidationsService,
    private scroller: ViewportScroller,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private officePipe: OfficeTimePipe
  ) {}
  automationLockedFields: string[] = [];
  dateVerificationConfirmed: boolean = false;
  showDatesVerificationModal: boolean = false;
  envsVerificationConfirmed: boolean = false;
  showEnvsVerificationModal: boolean = false;
  originalRecord: any = {};
  startDateErrorMessage: string = "";
  endDateErrorMessage: string = "";

  envRules = {
    'dev': {
      rules:['First Succesful build'],
      status: true,
    },
    'qa': {
      rules:['Start and End dates have values'],
      status: true,
    },
    'prod': {
      rules:['Challenge has both 4.0 and 5.0 Prizes', "Has a valid 'qa' environment flag"],
      status: true,
    },
  }
  /**
   * function used to wrap multiple validations if needed
   */
  async isValidForm(skip: string, form: any){
    let shouldSubmit = true;
    if(skip !== 'date' && !this.dateVerificationConfirmed){
      let result = await this.validateDateChanges(form);
      this.showDatesVerificationModal = !result;
      if(!result){
        shouldSubmit = false;
      }
    }
    if(skip !== 'envs' && !this.dateVerificationConfirmed){
      let result = await this.validateEnvPreReqs(form);
      this.showEnvsVerificationModal = !result;
      if(!result){
        shouldSubmit = false;
      }
    }
    return shouldSubmit;
  }

  async validateEnvPreReqs(challengeForm: any){
    let {value} = challengeForm;
    let envCheck = await this.challengeService.checkEnvFlagsRequirements(value);
    this.envRules.dev.status = envCheck.devReady;
    this.envRules.qa.status = envCheck.qaReady;
    this.envRules.prod.status = envCheck.prodReady;

    console.log('envCheck: ', envCheck);
    if(!envCheck.devReady || !envCheck.qaReady || !envCheck.prodReady)
    {
      return false;
    } else {
      return true;
    }

  }
  /**
   * this function evaluates date changes, and returns if a change is valid based on:
   * if current start/end dates were live in the past (older than current date)
   * if current start/end dates are soon to go live -48hrs
   * @returns boolean true/false if date changes are valid
   */
  async validateDateChanges(challengeForm: any){
    let isValidDate = true;
    // re-setting error message before entering function
    this.startDateErrorMessage = '';
    this.endDateErrorMessage = '';
    // setting original dates
    let originalStart = new Date(this.originalRecord.start);
    let originalEnd = new Date(this.originalRecord.end);
    // setting new dates
    let newStart = new Date(challengeForm.value['start']);
    let newEnd = new Date(challengeForm.value['end']);
    let currentDate = new Date();
    // time difference for START
    let s_Difference_In_Time = currentDate.getTime() - originalStart.getTime();
    // To calculate the no. of days between two dates
    let s_Difference_In_Hours = +Math.abs(s_Difference_In_Time/3600000).toFixed(1)
    let s_Difference_In_Days = s_Difference_In_Time / (1000 * 3600 * 24);
    // time difference for END
    let e_Difference_In_Time = currentDate.getTime() - originalEnd.getTime();
    // To calculate the no. of days between two dates
    let e_Difference_In_Hours = +Math.abs(e_Difference_In_Time/3600000).toFixed(1)
    let e_Difference_In_Days = e_Difference_In_Time / (1000 * 3600 * 24);
    // START DATE EVALUATION
    if((this.originalRecord.start) && (originalStart.getTime() !== newStart.getTime())){
      if(s_Difference_In_Days>0){
        this.startDateErrorMessage = `Original Start date passed ${Math.floor(s_Difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((s_Difference_In_Days<=0) && (s_Difference_In_Hours<=48)){
        this.startDateErrorMessage = `Original Start date is soon to go live in ${s_Difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    // END DATE EVALUATION
    if((this.originalRecord.end) && (originalEnd.getTime() !== newEnd.getTime())){
      if(e_Difference_In_Days>0){
        this.endDateErrorMessage = `Original END date passed ${Math.floor(e_Difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((e_Difference_In_Days<=0) && (e_Difference_In_Hours<=48)){
        this.endDateErrorMessage = `Original END date is soon to go live in ${e_Difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    return isValidDate;
  }

  /**
   * This function sets end date of the challenge depending on challenge type
   *
   * @param selectedDate is the date being collected from the form
   */

  async onStartDateSelect(selectedDate: any, form: any){


    let type = form.get('type_ref')?.value
    this.loggerService.log('selectedDate',selectedDate)
    this.loggerService.log('type',type)

    const doc = await this.dataService.getDocumentAsync('challenge-types', {
      query: { _id: type },
      autopopulate: true,
      virtuals: true,
    });

    let selectedTypeRef = doc && doc.name ? doc.name : null
    this.loggerService.log('selectedTypeRef',selectedTypeRef)

    const daysToAdd = selectedTypeRef === 'Daily' ? 1 : 
                      selectedTypeRef === 'Lifestyle' ? 4 : 3;
    
    const endDate = new Date(selectedDate);
    endDate.setDate(endDate.getDate() + daysToAdd);
    form.get('end')?.setValue(endDate);
    form.controls['end'].markAsTouched();

    this.messageService.add({
      severity: 'success', 
      summary: 'End Date Updated',
      detail: `End Date has been updated for ${selectedTypeRef} challenge type. Added ${daysToAdd} days to start date of ${this.formatDate(selectedDate)}.`,
    });
  }

  // functions needed for start/end dates validation
  formatDate(date: any){
    if(date == 'Invalid Date') return date
    return this.officePipe.transform(new Date(date));
  }

  /**
   * Creates Challenge Record.
   *
   * @param request Challenge data to create.
   */
  async onCreateChallenge(request: any, key: any, type: any)
  {
    try {
      let result = await this.commonEntityService.create(key, request);

      if(result && result.id)
      {
        this.messageService.add({
          sticky: true,
          severity: 'success',
          summary: 'Replaced placeholder',
          detail: `Replaced scene and feed image path placeholders with ID.`,
        });
        if ((result.image || result.scene) && (result.image.includes(pathconfigValues.challengeIdPlaceholder) || result.scene.includes(pathconfigValues.challengeIdPlaceholder)))
        {
          this.messageService.add(
          {
            severity: 'info',
            summary: 'Replacing placeholder',
            detail: `"Replacing ChallengeId placeholder with: ${result.id}`,
          });
          let newScenePath = result.scene.replaceAll(
            pathconfigValues.challengeIdPlaceholder,
            result.id
          );
          let newImagePath = result.image.replace(
            pathconfigValues.challengeIdPlaceholder,
            result.id
          );
          let newfileName = result.fileName.replace(
            pathconfigValues.challengeIdPlaceholder,
            result.id
          );
          let form = {
            scene: newScenePath,
            image: newImagePath,
            fileName: newfileName,
            userData: request.userData
          };

          let updateResult = await this.commonEntityService.update(key, result.id, form);

          if(updateResult && updateResult._doc.id)
          {
            this.messageService.add(
            {
              severity: 'success',
              summary: 'Replaced placeholder',
              detail: `Replaced scene and feed image path placeholders with ID.`,
            });

            setTimeout(() =>
            {
              this.router.navigate([`${type}/${updateResult._doc.id}`]);
            }, 500);
          }
        }
        else
        {
          this.router.navigate([`${type}/${result.id}`]);
        }
      }
      else
      {
        this.messageService.add
        (
          {
            severity: 'error',
            summary: 'Submit Error',
            detail: `${result.error.message}`,
          }
        );
      }
    } catch (error: any) {
      if(error.error){
        error = error.error;
      }
      console.log('POST call in error', error);
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Submit Error',
        detail: error.message ? error.message : 'There was an error submitting.',
      });
      window.scrollTo(0, 0);
    }
  }
  /**
   * Handle Challenge Update after submission.
   *
   * @param challengeResult Challenge result.
   */
  afterChallengeUpdate(challengeResult: any, type: any)
  {
    if(challengeResult && challengeResult._doc)
    {
      this.messageService.add(
      {
        severity: 'success',
        summary: 'Update Successful',
        detail: `"${challengeResult._doc.name}" was successfully updated`,
      });
      if(challengeResult.automations && challengeResult.automations.length > 0)
      {
        challengeResult.automations.forEach((automation: any) =>
        {
          this.messageService.add(
          {
            severity: 'info',
            summary: 'Automation Executed',
            detail: `${automation}`,
            life: 3000
          });
        });
      }
      window.scrollTo(0, 0);
      this.router.navigate([`${type}/${challengeResult._doc.id}`]);
    }
  }

  /**
   * Updates a challenge record.
   *
   * @param request Challenge data to update.
   * @param skipChecksFor Flag that sets whether to skip environment checks.
   */
  async onEditChallenge(request: any, skipChecksFor: string = '', form: any, key: any, id: any, type: any)
  {
    let validationsMet = await this.isValidForm(skipChecksFor, form);
    if(!validationsMet)
    {
      return;
    }

    try {
      let result = await this.commonEntityService.update(key, id, request);
      if(result && result._doc)
      {
        this.afterChallengeUpdate(result, type);
      }
      else
      {
        this.messageService.add
        (
          {
            severity: 'error',
            summary: 'Submit Error',
            detail: `${result.error.message}`,
          }
        );
      }
    } catch (error: any) {
      if(error.error){
        error = error.error;
      }
      console.log('POST call in error', error);
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Submit Error',
        detail: error.message ? error.message : 'There was an error submitting.',
      });
      window.scrollTo(0, 0);
    }

  }

  /** On input or clear, adds or removed field key in global 'automationLockedFields' array. This array is checked before field receives automated value. Triggered on input.
   * @param key field key
   */
  handleAutomationLock(key: string, fields: any) {
    // If field is NOT already automation-locked and has value provided via input, add key to 'automationLockedFields'
    if (
      !this.automationLockedFields.includes(key) &&
      !this.validationService.isEmpty(fields[key])
    ) {
      this.automationLockedFields.push(key);
    }

    // If field IS already automation-locked and has no value, remove key from 'automationLockedFields'
    if (
      this.automationLockedFields.includes(key) &&
      this.validationService.isEmpty(fields[key])
    ) {
      let index = this.automationLockedFields.findIndex(
        (fieldKey) => fieldKey == key
      );
      this.automationLockedFields.splice(index, 1);
    }
  }

  /**
   * Validates challenge form values before form submission.
   */
  async validateChallengeForm(challengeForm: any) {
    let isValid = true;
    if (
      challengeForm.value.scene &&
      this.validationService.stringHasWhiteSpace(challengeForm.value.scene)
    ) {
      this.alertMessage(false, 'Submit Error', 'Scene contains white space.');
      isValid = false;

      this.scroller.scrollToAnchor('require-fields');
    }
    if (
      challengeForm.value.fileName &&
      this.validationService.stringHasWhiteSpace(challengeForm.value.fileName)
    ) {
      await this.validationService.removeSpaces(challengeForm.value.fileName)
      // this.alertMessage(false, 'Submit Error', 'File Name contains white space.');
      // isValid = false;

      this.scroller.scrollToAnchor('require-fields');
    }

    if (
      challengeForm.value.scene &&
      this.validationService.stringHasSlash(challengeForm.value.scene)
    ) {
      this.alertMessage(
        false,
        'Submit Error',
        'scene contains a \'/ at the beginning. Please Remove the slash and try again.');
      isValid = false;

      this.scroller.scrollToAnchor('require-fields');
    }

    if (
      challengeForm.value.image &&
      this.validationService.stringHasWhiteSpace(challengeForm.value.image)
    ) {
      this.alertMessage(
        false,
        'Submit Error',
        'Feed Image contains white space.'
      );
      isValid = false;
      this.scroller.scrollToAnchor('require-fields');
    }

    if (this.validationService.isEmpty(challengeForm.value.type_ref)) {
      this.alertMessage(false, 'Submit Error', 'Challenge Type is required.');
      isValid = false;
      this.scroller.scrollToAnchor('content-fields');
    }

    if (
      challengeForm.value.prizes_ref &&
      challengeForm.value.prizes_ref.length > 0
    ) {
      for (let prize of challengeForm.value.prizes_ref) {
        if (prize && this.validationService.isEmpty(prize.id)) {
          this.alertMessage(
            false,
            'Submit Error',
            'Prize item cannot be null.'
          );
          isValid = false;
          // this.scroller.scrollToAnchor("prize-section");
          break;
        }
      }
    }
    if (
      challengeForm.value.rewards_ref &&
      challengeForm.value.rewards_ref.length > 0
    ) {
      for (let reward of challengeForm.value.rewards_ref) {
        if (reward && this.validationService.isEmpty(reward.id)) {
          this.alertMessage(
            false,
            'Submit Error',
            'Reward item cannot be null.'
          );
          isValid = false;
          // this.scroller.scrollToAnchor("prize-section");
          break;
        }
      }
    }

    if (
      !challengeForm.value.climate_ref ||
      challengeForm.value.climate_ref.length == 0
    ) {
      this.alertMessage(
        false,
        'Submit Error',
        'Challenge Climate is required.'
      );
      isValid = false;
      this.scroller.scrollToAnchor('content-fields');
    }

    return isValid;
  }

  /**
   * Display alter message
   *
   * @param success Flag that sets whether or not is a succes alert
   * @param message Message to display.
   * @param detail Additional details.
   */
  alertMessage(success: boolean = true, message: string, detail: string) {
    this.messageService.add({
      sticky: true,
      severity: success ? 'success' : 'error',
      summary: message,
      detail: detail,
      life: 3000,
    });
  }

  /**
   * Submits form with overwrite flag to bypass conflict checks
  */
  async submitAndSkipChecks(skip: string, challengeForm: any, keywords_ref: any, isEditMode: any, key: any, id: any, type: any){
    switch (skip) {
      case 'date':
        this.dateVerificationConfirmed = true;
        this.showDatesVerificationModal = false;
        break;
      case 'envs':
        this.envsVerificationConfirmed = true;
        this.showEnvsVerificationModal = false;
        break;

      default:
        break;
    }
    this.onSubmitV2(skip, challengeForm, keywords_ref, isEditMode, key, id, type);
  }


  async updateDependentField(dataArray: any, field: any, original: any, form: any) {
    // Check if dataArray has changed
  if (!this.areArraysEqual(original, dataArray)) {
    // Create a new FormArray based on dataArray
    const newFormArray = this.fb.array(
      dataArray.map((event: any) => this.fb.group(event))
    );

    // Set the costs_ref FormArray to the newFormArray
    form.controls[field].controls = newFormArray.controls;
    form.controls[field].setValue(newFormArray.value);

    // Mark the entire costs_ref FormArray as touched
    form.controls[field].markAllAsTouched();

  } else {
    console.log('No changes in dataArray');
  }
}
private areArraysEqual(array1: any[], array2: any[]): boolean {
  // Implement your own logic to compare arrays
  // This example assumes a simple comparison for illustration purposes
  return JSON.stringify(array1) === JSON.stringify(array2);
}

  /**
   * Form submission.
   *
   * @param skipChecksFor Flag that sets whether to skip environment checks.
   */
  async onSubmitV2(skipChecksFor: string = '', challengeForm: any, keywords_ref: any, isEditMode: any, key: any, id: any, type: any, spawners: any = null, prizeRewards: any = null)
  {
    challengeForm.controls['keywords_ref'].setValue(keywords_ref);
    challengeForm.controls['keywords_ref'].markAsTouched();

    if (prizeRewards) {
      this.updateDependentField(prizeRewards.prizes, 'prizes_ref', prizeRewards.originalPrizes, challengeForm);
      this.updateDependentField(prizeRewards.rewards, 'rewards_ref', prizeRewards.originalRewards, challengeForm)
    }
    let request: any = {};
    let touchedFields: Array<any> = [];

    if(isEditMode)
    {
      let properties = Object.keys(challengeForm.controls);
      properties.forEach(prop =>
      {
        if(challengeForm.controls[prop].touched)
        {
          touchedFields.push(prop);
          request[prop] = challengeForm.controls[prop].value;
        }
      });
    }
    else
    {
      request = challengeForm.value;
    }

    request.skipEnvChecks = this.envsVerificationConfirmed;

    // userData from authService
    let userResult = this.authService.getSocialUser();
    request.userData =
    {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    request.spawnersName = spawners
    request.restrictions_ref = challengeForm.controls['restrictions_ref'].value

    let isValid = this.validateChallengeForm(challengeForm);
    if(await isValid)
    {
      if(isEditMode)
      {
        this.onEditChallenge(request, skipChecksFor, challengeForm, key, id, type);
      }
      else
      {
        this.onCreateChallenge(request, key, type);
      }
    }
  }

  /** constructLevelName auto generates a challenge's filename based on it's form fields & existing data
   *
   * @param sceneType : scene type to attach to the name
   * @param isGrand  : if is grand type, naming convention changes
   */
  async constructLevelName(sceneType: string, isGrand: boolean, fields: any, bonus: boolean = false) {
    console.log('sourced_by: ', fields.sourced_by);

    let initials: string = 'xx';

    return [
      bonus ? `${isGrand ? pathconfigValues.grand : initials}_${
        pathconfigValues.challengeIdPlaceholder
      }_bonus` : `${isGrand ? pathconfigValues.grand : initials}_${
        pathconfigValues.challengeIdPlaceholder
      }_${sceneType}`,
      `${pathconfigValues.challengeIdPlaceholder}`,
    ];
  }

  /**
   * Set all field options for the form.
   */
  async setOptions(options: any) {
    // from refs (managed lists)
    await this.utilitiesService.getOptionsFromRef(options, 'climate_ref', 'climates', true);
    await this.utilitiesService.getOptionsFromRef(options, 'type_ref', 'challenge-types', false, true);
    await this.utilitiesService.getOptionsFromRef(options, 't', 'resources', true, true);
    await this.utilitiesService.getOptionsFromRef(options, 'progressionLevel_ref', 'progression-levels',true,false);
    await this.utilitiesService.getOptionsFromRef(options, 'scene_type_ref', 'scene-types', false, false);
    await this.utilitiesService.getOptionsFromRef(options, 'prizes_ref', 'currencies', false, true);
    await this.utilitiesService.getOptionsFromRef(options, 'rewards_ref', 'currencies', false, true);
    await this.utilitiesService.getOptionsFromRef(options,'keywords_ref','challenge-keywords',true,true);
    await this.utilitiesService.getOptionsFromRef(options, 'contentHold_ref', 'challenge-content-hold', true, true);
    await this.utilitiesService.getOptionsFromRef(options, 'artist_ref', 'users', true, true);
    await this.utilitiesService.getOptionsFromRef(options, 'tester_ref', 'users', true, true);
    await this.utilitiesService.getOptionsFromRef(options, 'collection_ref', 'challenge-collections', true, false, 'id name -_id', false);
    await this.utilitiesService.getOptionsFromRef(options, 'spawnAudios_ref', 'miscellaneous-build', false, false, 'name id _id path', false, { name: 1 }, { assetType: { $in: [22] }});
    await this.utilitiesService.getOptionsFromRef(options, 'loopAudios_ref', 'miscellaneous-build', false, false, 'name id _id path', false, { name: 1 }, { assetType: { $in: [23] }});
    await this.utilitiesService.getOptionsFromRef(options, 'spawnAudioCollections_ref', 'audio-collections', false, false, 'name id _id', false, { name: 1 }, { type: 1 });
    await this.utilitiesService.getOptionsFromRef(options, 'loopAudioCollections_ref', 'audio-collections', false, false, 'name id _id', false, { name: 1 }, { type: 2 });
    await this.utilitiesService.getOptionsFromRef(options, 'sponsor_ref', 'sponsors', false, false, 'name id _id', false, { name: 1 }, {enabled: true});


    // un-managed lists
    options['sceneType'] = [
      'Aquatic',
      'Backyard',
      'Courtyard',
      'Dining',
      'Fire Pit',
      'Front Yard',
    ];

    options['fileType'] = [
      'Original',
      'Recolor',
      'Duplicate'
    ]

    options['feedImageStatus'] = [
      'Approved',
      'Need Review',
      'Ready',
      'Revision',
      'Showing',
    ];

    options['year'] = [
      '2021',
      '2022',
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
      '2030',
    ];

    options['envs'] = environments;
  }

  hasColorChanged(type_ref: string, types: any[], color: string): boolean {
    // Find the original type record using type_ref
    const originalType = types.find((type) => type._id === type_ref);

    // If the original type record is found and its color is different from the current color
    if (originalType && originalType.color !== color) {
      return true; // Color has changed
    }

    return false; // Color has not changed
  }

  validateSpawners(dto: any, spawnerNames: any) {
    return firstValueFrom(this.http.post<any>(`api/challenges/verify-spawners`, {spawnerNames: spawnerNames, dto: dto}));
  }




}
