import { ColDef } from 'ag-grid-community';
import { ActionsCellRendererComponent } from './cell-renderers/render-components/actions/actions.component';
import { TextCellRendererComponent } from './cell-renderers/render-components/text/text.component';
import { LinkCellRendererComponent } from './cell-renderers/render-components/link/link.component';
import { BooleanEditorComponent } from './cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from './cell-editors/calendar/calendar.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { MultiselectEditorComponent } from './cell-editors/multiselect/multiselect.component';
import { OfficeTimePipe } from '../pipes/officeTime.pipe';

interface CustomColDef extends ColDef {
  cellClass?: string;
}

export function getDefaultTableColumns(entity: string, agGridToolsService: AgGridToolsService, tableParams: any = null): ColDef[] {
  return [
    {
      headerName: 'Actions',
      field: 'actions',
      cellRenderer: ActionsCellRendererComponent,
      pinned: 'right', // Pin the column to the right
      width: 120, // Set the width of the column
      resizable: true,
      cellRendererParams: {
        enableDuplicateFeature: tableParams?.enableDuplicateFeature || false
      }
    },
    {
      headerName: 'ID',
      field: 'id',
      width: 125,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      sortable: true,
      initialSort: 'desc',
      sortIndex: 0,
      initialSortIndex: 0,
      sort: 'desc',
      sortingOrder: ['asc','desc'],
      unSortIcon: false,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: [
          'equals',
          'notEqual',
          'lessThan',
          'lessThanOrEqual',
          'greaterThan',
          'greaterThanOrEqual',
          'inRange'
        ]
      },
      resizable: true,
      floatingFilter: true,
      suppressSizeToFit: true,
    },
    {
      headerName: 'Name',
      field: 'name',
      cellRendererParams: {
        entity: entity
      },
      cellRenderer: LinkCellRendererComponent,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      cellEditor: 'agTextCellEditor',
      type: 'editableColumn',
    },
    {
      headerName: 'Enabled',
      field: 'enabled',
      cellRenderer: TextCellRendererComponent,
      cellRendererParams: {
        onLabel: 'Enabled',
        offLabel: 'Not Enabled',
        isBoolean: true
      },
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: [true, false],
        valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
      },
      floatingFilter: true,
      cellEditor: BooleanEditorComponent,
      type: 'editableColumn',
    },
    {
      headerName: 'Start',
      field: 'start',
      sortable: true,
      unSortIcon: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['clear']
      },
      floatingFilter: true,
      suppressSizeToFit: true,
      width: 300,
      cellRenderer: TextCellRendererComponent,
      cellRendererParams: {
        isDate: true,
      },
      valueGetter: agGridToolsService.dateValueGetter('start'),
      resizable: true,
      cellEditor: CalendarEditorComponent,
      type: 'editableColumn'
    },
    {
      headerName: 'End',
      field: 'end',
      sortable: true,
      unSortIcon: true,
      filter: 'agDateColumnFilter',
      filterParams: {
        buttons: ['clear']
      },
      floatingFilter: true,
      suppressSizeToFit: true,
      cellRenderer: TextCellRendererComponent,
      cellRendererParams: {
        isDate: true,
        timePill: true
      },
      width: 300,
      valueGetter: agGridToolsService.dateValueGetter('end'),
      resizable: true,

      type: 'editableColumn',
      cellEditor: CalendarEditorComponent,
    },
    {
      headerName: 'Environment(s)',
      field: 'env',
      cellRendererParams: {
        entity: entity,
        isEnv: true
      },
      cellRenderer: TextCellRendererComponent,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      filter: 'agSetColumnFilter',
      filterParams: {
          values: ['dev', 'qa', 'prod', 'test'],
      },
      floatingFilter: true,
      cellEditor: MultiselectEditorComponent,
      type: 'editableColumn',
      cellEditorPopup: true,
      cellEditorParams: {
        optionValue: "_id",
        optionLabel: "name",
        valueObjects: true,
        isFlatList: true
      },
    },
  ];

}
