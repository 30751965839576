import {
  Component, EventEmitter, Input, OnInit,
  Output, SimpleChanges, ElementRef, QueryList, ViewChildren
} from '@angular/core';
import EndlessOfferTrackDTO from '../../dtos/EndlessOfferTrackDTO';
import DynamicFormFieldDTO from 'src/app/common/components/dynamic-form-v2/dtos/DynamicFormFieldDTO';
import { HttpClient } from '@angular/common/http';
import { EndlessOfferService } from 'src/app/common/services/endless-offer.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { ChangeDetectorRef } from '@angular/core';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'eo-rewards-track-dynamic-field',
  templateUrl: './eo-rewards-track-dynamic-field.component.html',
  styleUrls: ['./eo-rewards-track-dynamic-field.component.css']
})
export class EORewardsTrackDynamicFieldComponent implements OnInit {
  @ViewChildren('bundleItem') bundleItems: QueryList<ElementRef>;


  @Input() endlessOfferTrackRecord: EndlessOfferTrackDTO = new EndlessOfferTrackDTO();
  @Input() field: DynamicFormFieldDTO = new DynamicFormFieldDTO();
  @Input() options: any[] = [];
  @Input() columnCount: number = 2;
  @Input() dropdownOptions: any = {};
  @Input() parentFieldData: any;
  @Output() onRecordChange = new EventEmitter<any>();
  @Output() onTrackChange = new EventEmitter<any>();

  public isLoading: boolean = true;
  public types: any[] = [
    { label: 'Rewards', value: 'free' },
    { label: 'Store Listings', value: 'paid' },
  ];
  public rewards_credits: any[] = [];

  filteredPaidOptions: any[];

  constructor(
    private http: HttpClient,
    private endlessOfferService: EndlessOfferService,
    private utilitiesService: UtilitiesService,
  ) { }

  /**
   * Rewards Track Dynamic Field Component Initialization
   */
  async ngOnInit() {
    const getOptionsParams = new OptionsParams({
      entity: 'store-listings-v2',
      select: 'id name pricePoint_ref credits_ref'
    });
    await this.utilitiesService.getOptions(this.dropdownOptions, 'storeListing_ref', getOptionsParams);
    await this.initializeComponent();
    // await this.endlessOfferService.getRewardsData(
    //   this.endlessOfferTrackRecord,
    //   this.setLoadingState.bind(this)
    // );
    await this.endlessOfferService.buildDisplayData(this.endlessOfferTrackRecord);

    console.log(this.endlessOfferTrackRecord);

    this.extractRewardsCreditsQA();

    this.isLoading = false;

  }

  private async initializeComponent() {
    if (this.field.options && this.field.options.createOrSelect && this.field.options.createOrSelect.child) {
      const matchingTrack = this.options.find(option => option._id === this.parentFieldData);
      if (matchingTrack) {
        this.endlessOfferTrackRecord = matchingTrack;
      } else {
        this.endlessOfferTrackRecord = new EndlessOfferTrackDTO();
      }
    }

    if (!this.endlessOfferTrackRecord['EObundles']) {
      this.endlessOfferTrackRecord['EObundles'] = [];
    }
    if (!this.endlessOfferTrackRecord['EOrewards']) {
      this.endlessOfferTrackRecord['EOrewards'] = [];
    }

    console.log(this.endlessOfferTrackRecord);
  }


  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    if (changes.parentFieldData && !changes.parentFieldData.firstChange) {
      // The parentFieldData input property has changed.
      const newParentFieldData = changes.parentFieldData.currentValue;
      this.initializeComponent();
    }
  }

  /**
   * Handle Record Changes
   *
   * @param field Field that changed.y
   * @param event Value changed.
   */
  onChange(field: string, event: any) {
    this.onTrackChange.emit(this.endlessOfferTrackRecord);
    this.onRecordChange.emit({ field: field, record: this.endlessOfferTrackRecord, change: event });
  }


  addBundle(): void {
    const index = this.endlessOfferTrackRecord['EObundles']?.length || 0;
    this.endlessOfferTrackRecord['EObundles']?.push({ type: null, index: index });

    if (!this.endlessOfferTrackRecord.EOrewards) {
      this.endlessOfferTrackRecord.EOrewards = [];
    }

    if (this.endlessOfferTrackRecord.EOrewards) {
      this.endlessOfferTrackRecord.EOrewards = [...this.endlessOfferTrackRecord.EOrewards, [{ t: null, c: 5, id: null, lineItemType: null }]]
    }

    // Trigger the scroll to the new item
    this.scrollToNewItem();

  }

  onTypeChange(event: any, index: number): void {
    // console.log(event, index);
    if (event.value === 'paid') {
      // Initialize EOrewards as an array if it does not exist yet
      if (!this.endlessOfferTrackRecord.EOrewards) {
        this.endlessOfferTrackRecord.EOrewards = [];
      }

      while (index >= this.endlessOfferTrackRecord.EOrewards.length) {
        this.endlessOfferTrackRecord.EOrewards.push(undefined);
      }

      // Now we can safely assign the new reward to the specific index
      const newReward = {
        t: "654c04eb527d4031196b7561",
        id: { _id: null },
        c: 1,
        lineItemType: "StoreListingV2"
      };
      this.endlessOfferTrackRecord.EOrewards[index] = [newReward];
    }
  }


  async onSearchPaidOptions(event: any) {
    let query = event.query.toLowerCase();
    let result = await this.http.get<any>(`/api/endless-offer-track/store/listing?searchBy=${query}`).toPromise();
    this.filteredPaidOptions = result.storeListings;
  }

  async onSelectPaidOption(event: any, index: number) {
    const storeListingRefClone = cloneDeep(this.dropdownOptions.storeListing_ref);

    // Find the matching option where _id equals event.value in the cloned array
    let matchingOption = storeListingRefClone.find((option: { _id: any; }) => option._id === event.value);

    // Ensure a matching option was found
    if (!matchingOption) {
      return; // Exit the function if no matching option is found
    }

    if (this.endlessOfferTrackRecord && this.endlessOfferTrackRecord.EOrewards && this.endlessOfferTrackRecord.EOrewards[index]) {

      if (!this.endlessOfferTrackRecord.EOrewards) {
        this.endlessOfferTrackRecord.EOrewards = [];
      }

      // Create the new reward object using the _id of the matching option
      const newReward = {
        t: "654c04eb527d4031196b7561",
        id: matchingOption,
        c: 1,
        lineItemType: "StoreListingV2"
      };

      // Insert the new reward at the specific index
      this.endlessOfferTrackRecord.EOrewards[index] = [newReward];

      // Explicitly wait for the state to be updated
      await this.updateRewardsData();

      await this.endlessOfferService.buildDisplayData(this.endlessOfferTrackRecord);

      // Update the rewards credits
      this.extractRewardsCreditsQA();
    }
  }


  private extractRewardsCreditsQA() {
    if (this.endlessOfferTrackRecord && this.endlessOfferTrackRecord.EOrewards) {
      this.endlessOfferTrackRecord.EOrewards.forEach((reward: any, index: number) => {
        if (reward[0]?.id?.credits_ref?.qa) {
          this.rewards_credits[index] = reward[0]?.id?.credits_ref?.qa;
        }
      });
    }
  }


  async updateRewardsData() {
    // Use a promise to wait for the next event loop tick, ensuring state is updated
    await new Promise(resolve => setTimeout(resolve, 0));
    this.isLoading = false;
  }

  clearSelectedPaidOption(index: number) {
    if (this.endlessOfferTrackRecord && this.endlessOfferTrackRecord.EObundles && this.endlessOfferTrackRecord.EObundles[index]) {
      // this.endlessOfferTrackRecord.EObundles[index].listing = null;
    }
  }

  getCreditId(key: string): string {
    if (key) {
      return key.split('_')[1];
    } else {
      return 'undefined'
    }
  }

  getCreditCount(credit: any): any {
    return Object.values(credit)[0];
  }

  reOrderLists(event: any): void {
    if (!this.endlessOfferTrackRecord.EObundles || !this.endlessOfferTrackRecord.EOrewards) {
      return;
    }

    const movedBundle = event[0];
    if (movedBundle && typeof movedBundle.index === 'number') {
      const originalIndex = movedBundle.index;
      const newIndex = this.endlessOfferTrackRecord.EObundles.findIndex((bundle: any) => bundle.index === originalIndex);

      // Move the corresponding reward to match the new bundle position
      if (originalIndex !== newIndex && originalIndex < this.endlessOfferTrackRecord.EOrewards.length && newIndex < this.endlessOfferTrackRecord.EOrewards.length) {
        const [movedReward] = this.endlessOfferTrackRecord.EOrewards.splice(originalIndex, 1);
        this.endlessOfferTrackRecord.EOrewards.splice(newIndex, 0, movedReward);
      }
    }

    // Update index values in EObundles for future moves
    this.endlessOfferTrackRecord.EObundles.forEach((bundle: { index: any; }, index: any) => {
      bundle.index = index;
    });
  }

  removeBundle(index: number): void {
    // Remove the bundle at the specified index
    this.endlessOfferTrackRecord['EObundles']?.splice(index, 1);

    // Remove the corresponding reward at the same index
    this.endlessOfferTrackRecord['EOrewards']?.splice(index, 1);

    // Call onChange or any other function you need to update your component state
    this.onChange('EObundles', {});
    this.onChange('EOrewards', {});
  }

  setLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  scrollToNewItem() {
    // Get the drop list by its ID and assert it's an HTMLElement
    const dropList = document.querySelector("#cdk-drop-list-0") as HTMLElement;

    // Check if the drop list exists and has children
    if (dropList) {
      // Set smooth scroll behavior
      dropList.style.scrollBehavior = 'smooth';

      // Use a setTimeout to give time for the scroll to finish before simulating the click
      setTimeout(() => {
        // Scroll the container to its maximum scroll height
        dropList.scrollTop = dropList.scrollHeight;
        // Access the last child of the drop list and assert it's an HTMLElement
        const lastListItem = dropList.lastElementChild as HTMLElement;

        if (lastListItem) {
          lastListItem.click();
        }
      }, 150); // Adjust the timeout duration as needed
    }
  }

}
