import { Component, Input, OnInit } from '@angular/core';
import { MessageService, ConfirmationService } from 'primeng/api'; 
import { CommonEntityService } from 'src/app/common/services/common-entity.service';

@Component({
  selector: 'app-notes-popup',
  templateUrl: './notes-popup.component.html',
  styleUrls: ['./notes-popup.component.sass'],
  providers: [ConfirmationService]
})
export class NotesPopupComponent implements OnInit {

  @Input() record: any; 
  @Input() entityType: string;  
  
  displayNotesPopup: boolean = false;
  originalCopy: string = ''; 
  tempCopy: string = ''; 

  constructor(
    private commonEntityService: CommonEntityService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {}

  closePopup(saveAndClose: boolean = false): void {
    if (saveAndClose || this.tempCopy === this.originalCopy) {
      this.displayNotesPopup = false;
    } else {
      this.confirmationService.confirm({
        message: 'You have unsaved changes. Are you sure you want to close?',
        header: 'Unsaved Changes',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.tempCopy = this.record.copy.replace(/<br\s*\/?>/gi, '\n');
          this.originalCopy = this.record.copy.replace(/<br\s*\/?>/gi, '\n'); // Restauramos el original
          this.displayNotesPopup = false;
        },
        reject: () => {
          this.displayNotesPopup = true;
        }
      });
    }
  }
  
  openNotesPopup(): void {
    if (this.record && this.record.copy) {
      this.originalCopy = this.record.copy.replace(/<br\s*\/?>/gi, '\n');
      this.tempCopy = this.record.copy.replace(/<br\s*\/?>/gi, '\n');
    } else {
      this.originalCopy = ''; 
      this.tempCopy = '';
    }
    this.displayNotesPopup = true;
  }
  

  saveNotes(): void {
    const payload = { copy: this.tempCopy };
  
    this.commonEntityService.update(this.entityType, this.record.id, payload)
      .then(() => {
        this.record.copy = this.tempCopy; 
  
        this.originalCopy = this.tempCopy;
  
        this.displayNotesPopup = false;
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Notes saved correctly' });
      })
      .catch((error: any) => {
        console.error('Error trying to save notes', error);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error trying to save notes' });
      });
  }  

  onResize(event: any): void {
    const editorElement = document.getElementById('copy');
    if (editorElement) {
      const newHeight = event.target.clientHeight - 150;
      editorElement.style.height = `${newHeight}px`;
    }
  }
}