import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SocialUser } from '@abacritt/angularx-social-login';
import { ExportToCsv } from 'export-to-csv';
import * as _ from 'lodash';
import {
  FilterService,
  MessageService,
  SelectItem,
  SortEvent,
} from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';
import { TableService } from '../../../services/table.service';

@Component({
  selector: 'app-trait-table',
  templateUrl: './trait-table.component.html',
  styleUrls: ['./trait-table.component.sass'],
  
})
export class TraitTableComponent implements OnInit {
  @ViewChild('table') table: any;

  // TABLE DETAILS
  title: string = 'Traits';
  type: string = 'traits';
  buildType: string;
  user: any;

  // ROWS
  rows: any[] = [];
  selectedRows: any[] = [];
  filteredRows: any[] = [];
  clonedRows: { [s: string]: any } = {};

  // COLUMNS
  columns: any[] = [];
  selectedColumns: any[] = [];
  columnSetGroups: any[];
  activeColumnSetName: string;

  // FILTERS
  filterValues: any = {};
  filterFields: any = [];
  filterSetGroups: any[];
  activeFilterSetName: string;
  globalFilters: string[] = [];
  currentFilter: string;

  // FIELDS
  options: any = [];
  suggestions: any = [];

  // VIEW
  showColumns: boolean;
  showAddColumnSet: boolean;
  showFilters: boolean;
  showAddFilterSet: boolean;
  showActions: boolean;
  showBulkBuild: boolean;
  showBulkBuildFailed: boolean;
  showBulkEdit: boolean;
  showBulkEditConflicts: boolean;
  showBulkEditFailed: boolean;
  isHeaderChecked: boolean;
  isLoading: boolean = false;

  // ACTIONS —
  // — Edit
  editableFields: any[];
  bulkEdit: any = {
    rows: [],
    stage: '',
    field: {},
  };
  // — Build
  bulkBuild: any = {
    rows: [],
    successCount: 0,
    failedPayloads: [],
  };
  // — Export
  exportFields: any[];

  // SETTINGS & STATE
  userSettings: any = {
    columnSets: [],
    filterSets: [],
  };
  tableState: any = {
    rowSelection: [],
    columnSelection: [],
    filterSelection: [],
    filters: [],
    sortField: 'id',
    sortOrder: -1,
    rows: 50,
    first: 0,
  };

  matchModeOptions: SelectItem[];
  event: any;

  constructor(
    private tableService: TableService,
    private formService: FormService,
    private messageService: MessageService,
    private dataService: DataService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private filterService: FilterService,
    private userContextService: UserContextService
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    this.globalFilters = ['id', 'name'];

    // get current user
    const userResult = this.authService.getSocialUser();
    this.user = userResult.currentUser;

    // Get row data
    await this.tableService.getRows(this.type).then(async (data: any) => {
      console.log('rows', data);
      this.rows = data;
      this.filteredRows = data;
    });

    // register custom filters —
    // — for multiSelect
    this.filterService.register('multiIn', (values: any[], filter: string) => {
      // console.log('custom filter values', values);
      // console.log('custom filter filter', filter);
      if (filter === undefined || filter === null || filter.length === 0) {
        return true;
      }

      if (values === undefined || values === null) {
        return false;
      }

      let match = false;
      for (const v of values) {
        // console.log('v', v);
        // console.log('filter', filter);
        if (filter.includes(v._id)) {
          match = true;
          break;
        }
      }
      return match;
    });
    // — for empty values
    this.filterService.register('empty', (value: any) => {
      return value === undefined || value === null || value.length < 1;
    });

    this.columns = [
      {
        key: 'id',
        name: 'ID',
        controlType: 'inputText',
        filterKey: 'id',
        matchMode: 'equals',
      },
      {
        key: 'name',
        name: 'Name',
        controlType: 'inputText',
        filterKey: 'name',
        matchMode: 'contains',
      },
      {
        key: 'start',
        name: 'Start',
        controlType: 'calendar-start',
        filterKey: 'start',
        matchMode: 'between',
      },
      {
        key: 'end',
        name: 'End',
        controlType: 'calendar-end',
        filterKey: 'end',
        matchMode: 'between',
      },
      {
        key: 'enabled',
        name: 'Enabled',
        controlType: 'toggleButton',
        filterKey: 'enabled',
        matchMode: 'equals',
      },
    ];

    // FIELD Key Sets
    const editableFieldKeys = ['name', 'start', 'end', 'enabled'];

    // Define COLUMN Sets
    const defaultColKeys = ['id', 'name', 'start', 'end', 'enabled'];

    // Define 'Global Column Sets' Group
    // TODO: Get list of global column sets needed.
    const globalColumnSets: any[] = [
      {
        name: 'Default',
        value: defaultColKeys,
        isGlobal: true,
      },
    ];

    // Set COLUMN SET GROUPS
    this.columnSetGroups = [
      {
        key: 'globalColumnSets',
        name: 'Global Sets',
        value: globalColumnSets,
        icon: 'pi pi-globe',
      },
      {
        key: 'userColumnSets',
        name: `${this.user.firstName}'s Sets`,
        value: [],
        icon: 'pi pi-user',
      },
    ];

    // Set FILTER SET GROUPS
    this.filterSetGroups = [
      {
        key: 'userFilterSets',
        name: `${this.user.firstName}'s Sets`,
        value: [],
        icon: 'pi pi-user',
      },
    ];

    // Get/Set settings from localStorage
    const state: any = localStorage.getItem(`test_${this.type}-table`);
    this.tableState = JSON.parse(state);
    if (!this.tableState) {
      this.tableState = {
        rowSelection: [],
        columnSelection: [],
        filterSelection: [],
        filters: [],

        sortField: 'id',
        sortOrder: -1,
        rows: 50,
        first: 0,
      };
      this.saveTableState();
    }

    // Handle localStorage data update —
    // — convert 'columnOrder' to 'columns'
    if (this.tableState.columnOrder) {
      this.tableState.columnSelection = [...this.tableState.columnOrder];

      delete this.tableState.columnOrder;
      this.saveTableState();
    }
    // — convert columns(any[]) to columnSelection(string[])
    if (this.tableState.columns) {
      let columnSelection: string[] = [];
      this.tableState.columns.forEach((col: any) => {
        columnSelection.push(col.key);
      });
      this.tableState.columnSelection = columnSelection;

      delete this.tableState.columns;
      this.saveTableState();
    }
    // — convert selection(any[]) to rowSelection(number[])
    if (this.tableState.selection) {
      let rowSelection: string[] = [];
      this.tableState.selection.forEach((row: any) => {
        rowSelection.push(row.id);
      });
      this.tableState.rowSelection = rowSelection;

      delete this.tableState.selection;
      this.saveTableState();
    }
    // — convert filters from object to array
    if (this.tableState.filters && !_.isArray(this.tableState.filters)) {
      let filtersArray: any[] = [];

      Object.keys(this.tableState.filters).forEach((filterKey: string) => {
        let value = this.tableState.filters[filterKey].value;

        const field: any = this.columns.find((c) => c.filterKey == filterKey);
        if (_.isArray(value) && field.controlType.endsWith('_ref')) {
          let newValue: any[] = [];
          value.forEach((id: any) => {
            let result = this.options[field.key].find(
              (option: any) => option._id == id
            );
            newValue.push(result);
          });

          value = newValue;
        }

        filtersArray.push({
          key: filterKey,
          value: value,
          matchMode: field.matchMode,
        });
      });
      this.tableState.filters = filtersArray;

      this.saveTableState();
    }
    //

    // set selected rows
    this.setSelectedRows();

    // set selected columns
    if (this.tableState.columnSelection.length < 1) {
      const defaultSet: any = globalColumnSets.find((set: any) =>
        ['Default'].includes(set.name)
      );
      this.tableState.columnSelection = defaultSet.value;
    }
    this.setSelectedColumns();

    // set active filters
    this.tableState.filters.forEach((filter: any) => {
      if (!['empty'].includes(filter.matchMode)) {
        // handle unique filter fields
        if (['start', 'end', 'date'].includes(filter.key)) {
          let val: any[] = [];
          filter.value.forEach((date: any) => {
            if (!date) {
              val.push(null);
            } else {
              val.push(new Date(date));
            }
          });
          filter.value = val;
        }
        //
      }

      this.filterValues[filter.key] = filter.value;
      this.onApplyFilter(filter.key);
    });

    this.checkTableState();

    // Get/Set USER settings
    await this.tableService
      .getUserPageSettings(`${this.type}-table`, this.user.email)
      .then((data: any) => {
        const settings: any = { ...data };

        // get filterSet data
        if (!settings.filterSets || settings.filterSets.length < 1) {
          settings.filterSets = [];
        } else {
          const setGroup = this.filterSetGroups.find(
            (group: any) => group.key == 'userFilterSets'
          );
          setGroup.value = settings.filterSets;
        }

        // get column data
        if (!settings.columnSets || settings.columnSets.length < 1) {
          console.log('No saved column sets found');
          settings.columnSets = [];
        } else {
          const setGroup = this.columnSetGroups.find(
            (group: any) => group.key == 'userColumnSets'
          );
          setGroup.value = settings.columnSets;
        }

        // handle change from userSettings to localStorage —
        // — activeColumns
        if (settings.activeColumns) {
          if (settings.activeColumns.length > 0) {
            let columnKeys: any = [];
            settings.activeColumns.forEach((col: any) => {
              columnKeys.push(col.key);
            });
            this.tableState.columns = columnKeys;
            this.saveTableState();
          }
          delete settings.activeColumns;
          this.saveUserSettings(settings);
        }
        // — selectedColumnSet
        if (settings.selectedColumnSet) {
          delete settings.selectedColumnSet;
          this.saveUserSettings(settings);
        }
        //

        this.userSettings = settings;

        console.log('userSettings on init', settings);
      });

    // Check for matching columnSet/filterSet
    this.checkForColumnSet();
    this.checkForFilterSet();

    // Set all EDITABLE Fields
    this.editableFields = _.sortBy(
      await this.getColumnsFromKeys(editableFieldKeys),
      [
        (col) => {
          return col.name;
        },
      ]
    );

    // Set all FILTER Fields
    this.columns.forEach((col) => {
      if (Object.keys(col).includes('filterKey')) {
        this.filterFields.push({ ...col });
      }
    });

    this.isLoading = false;
  }

  getColumnsFromKeys(keys: any[]) {
    let result: any[] = [];
    keys.forEach((key) => {
      let column = this.columns.find((col) => col.key === key);
      if (column) {
        result.push(column);
      }
    });
    return result;
  }

  // TABLE STATE
  checkTableState() {
    // isHeaderChecked
    let checkedRows: number[] = [];
    this.filteredRows.forEach((row: any) => {
      if (this.tableState.rowSelection.includes(row.id)) {
        checkedRows.push(row.id);
      }
    });
    this.isHeaderChecked = checkedRows.length === this.filteredRows.length;
  }
  saveTableState() {
    this.checkTableState();

    localStorage.setItem(
      `test_${this.type}-table`,
      JSON.stringify(this.tableState)
    );
  }

  // USER SETTINGS
  saveUserSettings(settings: any) {
    console.log('saving userSettings', settings);

    // set payload
    const payload = {
      userRef: this.user.email,
      pageRef: `${this.type}-table`,
      settings: { ...settings },
      id: settings.id,
    };

    // define action and call type
    let action: string = settings.id ? 'update' : 'add';
    let call: string = ['update'].includes(action) ? 'PATCH' : 'POST';

    // save userSettings
    this.dataService.saveUserSettings(payload, action).subscribe(
      async (val) => {
        console.log(`${call} call successful. Value returned in body`, val);
        this.userSettings = { ...settings };
        this.userSettings.id = val._id;
      },
      (response) => {
        console.log(`${call} call in error`, response);
      },
      () => {
        console.log(`The ${call} observable is now completed.`);
      }
    );
  }

  // FILTERS —
  // — userSettings.filterSets
  onAddFilterSet(setName: string) {
    const settings = { ...this.userSettings };

    let allFilterSets: any[] = [];
    this.filterSetGroups.forEach((group: any) => {
      group.value.forEach((set: any) => {
        allFilterSets.push(set);
      });
    });

    // value cannot be empty, name cannot be blank, name or value cannot match any existing set name or value
    if (
      this.tableState.filters.length < 1 ||
      !setName ||
      allFilterSets.find((set: any) => [setName].includes(set.name)) ||
      this.activeFilterSetName
    ) {
      return;
    }

    // create new filterSet object
    const newFilterSet = {
      name: setName,
      value: this.tableState.filters,
    };
    settings.filterSets.push(newFilterSet);
    this.saveUserSettings(settings);

    if (settings.filterSets) {
      const setGroup = this.filterSetGroups.find(
        (group: any) => group.key == 'userFilterSets'
      );
      setGroup.value = settings.filterSets;
    }

    this.activeFilterSetName = setName;
    this.showAddFilterSet = !this.showAddFilterSet;
  }
  onRemoveFilterSet(setName: string) {
    const settings = { ...this.userSettings };

    const updateIndex = settings.filterSets.findIndex((set: any) =>
      [set.name].includes(setName)
    );
    settings.filterSets.splice(updateIndex, 1);

    if ([setName].includes(this.activeFilterSetName)) {
      this.activeFilterSetName = '';
    }

    this.saveUserSettings(settings);
  }
  onSelectFilterSet() {
    // initialize filters
    this.onClearAllFilters();

    // get filter set
    let allFilterSets: any[] = [];
    this.filterSetGroups.forEach((group: any) => {
      group.value.forEach((set: any) => {
        allFilterSets.push(set);
      });
    });

    const activeSet = allFilterSets.find(
      (set) => set.name == this.activeFilterSetName
    );

    // run filters
    activeSet.value.forEach((filter: any) => {
      // set format for date fields
      if (['start', 'end', 'date'].includes(filter.key)) {
        let dateRangeValues: any[] = [];
        filter.value.forEach((d: any) => {
          if (d !== null) dateRangeValues.push(new Date(d));
        });
        this.filterValues[filter.key] = dateRangeValues;
      } else {
        this.filterValues[filter.key] = filter.value;
      }

      this.autoSelectFilter(filter.key);
      this.onApplyFilter(filter.key);
    });
  }
  checkForFilterSet() {
    // get all filter sets
    let allFilterSets: any[] = [];
    this.filterSetGroups.forEach((group: any) => {
      group.value.forEach((set: any) => {
        let tempSet = { ...set };

        let setValue: any[] = [];
        set.value.forEach((filter: any) => {
          setValue.push({
            key: filter.key,
            matchMode: filter.matchMode,
            value: ['empty'].includes(filter.matchMode)
              ? 'empty'
              : this.parseInputForFilter(filter.value, filter.key),
          });
        });
        tempSet.value = setValue;

        allFilterSets.push(tempSet);
      });
    });

    // get active filters
    let activeFilters: any[] = [];
    this.tableState.filters.forEach((filter: any) => {
      activeFilters.push({
        key: filter.key,
        matchMode: filter.matchMode,
        value: ['empty'].includes(filter.matchMode)
          ? 'empty'
          : this.parseInputForFilter(filter.value, filter.key),
      });
    });

    let filterSetName = '';

    allFilterSets.forEach((filterSet: any) => {
      // check lengths
      if (filterSet.value.length !== activeFilters.length) return;
      let matchCount: number = 0;

      for (let i = 0; i < activeFilters.length; i++) {
        const set = _.sortBy(filterSet.value, 'key')[i];
        const active = _.sortBy(activeFilters, 'key')[i];

        // check filterKeys, matchModes, and value types
        if (
          set.key !== active.key ||
          set.matchMode !== active.matchMode ||
          typeof set.value !== typeof active.value
        ) {
          return;
        }

        // if both values are arrays of equal length
        if (_.isArray(set.value) && _.isArray(active.value)) {
          if (set.value.length !== active.value.length) return;

          const allArrayItems = _.union(set.value, active.value);
          if (allArrayItems.length == active.value.length) {
            matchCount++;
          }
        } else {
          if (_.isEqual(set.value, active.value)) {
            matchCount++;
          }
        }
      }

      if (matchCount == activeFilters.length) {
        filterSetName = filterSet.name;
      }
    });
    this.activeFilterSetName = filterSetName;
  }
  // — filter fields
  onSearchFilters(input: string) {
    const fields: any[] = this.columns.filter(
      (col: any) =>
        Object.keys(col).includes('filterKey') &&
        (col.name.includes(input) || col.key.includes(input))
    );
    this.filterFields = fields;
  }
  onViewActiveFiltersOnly() {
    const fields: any[] = this.columns.filter(
      (col: any) =>
        Object.keys(col).includes('filterKey') &&
        this.tableState.filterSelection.includes(col.filterKey)
    );
    this.filterFields = fields;
  }
  autoSelectFilter(filterKey: string) {
    let keys = [...this.tableState.filterSelection];
    if (!keys.includes(filterKey)) {
      keys.push(filterKey);
    }
    this.tableState.filterSelection = keys;
  }
  isAllFilterFields() {
    const allFields: any[] = this.columns.filter((col: any) =>
      Object.keys(col).includes('filterKey')
    );
    return this.filterFields.length === allFields.length;
  }
  isActiveFilterFields() {
    const activeFields: any[] = this.columns.filter(
      (col: any) =>
        Object.keys(col).includes('filterKey') &&
        this.tableState.filterSelection.includes(col.filterKey)
    );
    return _.isEqual(this.filterFields, activeFields);
  }
  // — filter process
  onClearFilter(filterKey: string) {
    delete this.filterValues[filterKey];
    this.onApplyFilter(filterKey);
  }
  onClearAllFilters() {
    this.filterValues = {};
    this.tableState.filters = [];
    this.tableState.filterSelection = [];

    const fields: any[] = this.columns.filter((col: any) =>
      Object.keys(col).includes('filterKey')
    );
    this.filterFields = fields;

    this.filterFields.forEach((field: any) => {
      this.table.filter(null, field.filterKey, field.matchMode);
    });
  }
  onApplyFilter(filterKey: string) {
    const field: any = this.columns.find((c) => c.filterKey == filterKey);
    const isChecked: boolean =
      this.tableState.filterSelection.includes(filterKey);
    const input = this.filterValues[filterKey];

    let value: any;
    let matchMode: string = field.matchMode;

    if (isChecked && input) {
      value = this.parseInputForFilter(input, filterKey);
    }

    if (isChecked && (!input || input.length === 0)) {
      value = {};
      matchMode = 'empty';
    }

    if (!isChecked && input) {
      value = null;
    }

    if (!isChecked && (!input || input.length === 0)) {
      value = null;
      delete this.filterValues[filterKey];
    }

    // execute table filter
    this.table.filter(value, filterKey, matchMode);
  }
  parseInputForFilter(input: any, filterKey: string) {
    const field: any = this.columns.find((c) => c.filterKey == filterKey);

    // Parse input data from filter fields
    let value: any;
    switch (field.controlType) {
      case 'inputText':
      case 'inputTextarea':
      case 'dropdown':
      case 'auto_dropdown':
      case 'multiSelect':
        value = input;
        break;

      case 'toggleButton':
        if (input == 'Yes') value = true;
        if (input == 'No') value = false;
        if (input == 'Empty') value = '';
        break;

      case 'dropdown_ref':
      case 'multiSelect_ref':
      case 'autoComplete_ref':
      case 'autoComplete-multi_ref':
        let ids: string[] = [];
        input.forEach((val: any) => {
          ids.push(val._id);
        });
        value = ids;
        break;

      case 'calendar-start':
      case 'calendar-end':
      case 'date':
        let date0 = new Date(input[0]);
        let date1 = input[1] ? new Date(input[1]) : new Date(input[0]);

        value = [
          new Date(date0.setHours(0, 0, 0, 0)).toISOString(),
          new Date(date1.setHours(23, 59, 59)).toISOString(),
        ];
        break;
    }

    return value;
  }
  onFilter(e: any) {
    console.log('onFilter(e)', e);

    if (e.filters.global) {
      this.currentFilter = e.filters.global.value;
    } else {
      this.currentFilter = 'n/a';
    }
    this.filteredRows = e.filteredValue;

    // store values in tableState
    this.tableState.filters = [];
    let activeFilters: any[] = [];
    Object.keys(e.filters).forEach((filterKey: string) => {
      activeFilters.push({
        key: filterKey,
        value: this.filterValues[filterKey],
        matchMode: e.filters[filterKey].matchMode,
      });
    });
    this.tableState.filters = activeFilters;

    this.checkForFilterSet();

    this.saveTableState();
  }

  // COLUMNS —
  // — userSettings.columnSets
  onAddColumnSet(setName: string) {
    const settings = { ...this.userSettings };

    let allColumnSets: any[] = [];
    this.columnSetGroups.forEach((group: any) => {
      group.value.forEach((set: any) => {
        allColumnSets.push(set);
      });
    });

    // value cannot be empty, name cannot be blank, name or value cannot match any existing set name or value
    if (
      this.tableState.columnSelection.length < 1 ||
      !setName ||
      allColumnSets.find((set: any) => [setName].includes(set.name)) ||
      this.activeColumnSetName
    ) {
      return;
    }

    // create new columnSet object
    const newColumnSet = {
      name: setName,
      value: this.tableState.columnSelection,
    };
    settings.columnSets.push(newColumnSet);
    this.saveUserSettings(settings);

    if (settings.columnSets) {
      const setGroup = this.columnSetGroups.find(
        (group: any) => group.key == 'userColumnSets'
      );
      setGroup.value = settings.columnSets;
    }

    this.activeColumnSetName = setName;
    this.showAddColumnSet = !this.showAddColumnSet;
  }
  onRemoveColumnSet(setName: string) {
    const settings = { ...this.userSettings };

    const updateIndex = settings.columnSets.findIndex((set: any) =>
      [setName].includes(set.name)
    );
    settings.columnSets.splice(updateIndex, 1);

    if ([setName].includes(this.activeColumnSetName)) {
      this.activeColumnSetName = '';
    }

    this.saveUserSettings(settings);
  }
  onSelectColumnSet() {
    // find selected set
    let allColumnSets: any[] = [];
    this.columnSetGroups.forEach((group: any) => {
      group.value.forEach((set: any) => {
        allColumnSets.push(set);
      });
    });

    const selectedSet = allColumnSets.find((set: any) =>
      [this.activeColumnSetName].includes(set.name)
    );
    this.tableState.columnSelection = selectedSet.value;

    this.setSelectedColumns();
  }
  checkForColumnSet() {
    if (this.tableState.columnSelection) {
      let allColumnSets: any[] = [];
      this.columnSetGroups.forEach((group: any) => {
        group.value.forEach((set: any) => {
          allColumnSets.push(set);
        });
      });

      const match = allColumnSets.find((set: any) =>
        _.isEqual(
          _.sortBy(set.value),
          _.sortBy(this.tableState.columnSelection)
        )
      );
      this.activeColumnSetName = match ? match.name : null;
    }
  }
  // — column selection
  onSelectColumn() {
    let ordered: any[] = [];
    this.columns.forEach((col: any) => {
      if (this.tableState.columnSelection.includes(col.key)) {
        ordered.push(col.key);
      }
    });
    this.tableState.columnSelection = ordered;

    this.setSelectedColumns();
  }
  setSelectedColumns() {
    this.saveTableState();

    let selectedColumns: any[] = [];
    this.tableState.columnSelection.forEach((key: string) => {
      let selectedColumn = this.columns.find((col: any) => col.key === key);
      selectedColumns.push(selectedColumn);
    });
    this.selectedColumns = selectedColumns;

    this.checkForColumnSet();
  }

  // ROWS
  onHeaderCheckboxToggle(isChecked: boolean) {
    let rowSelection: number[] = [...this.tableState.rowSelection];

    if (isChecked) {
      console.log('CHECKED ALL');
      this.filteredRows.forEach((row: any) => {
        rowSelection.push(row.id);
      });
    } else {
      console.log('UNCHECKED ALL');
      this.filteredRows.forEach((row: any) => {
        let rowIndex: number = rowSelection.findIndex(
          (id: number) => id == row.id
        );
        rowSelection.splice(rowIndex, 1);
      });
    }

    this.tableState.rowSelection = _.sortedUniq(_.sortBy(rowSelection));
    this.setSelectedRows();
  }
  onRowCheckboxToggle(row: any, isChecked: boolean) {
    let rowSelection: number[] = [...this.tableState.rowSelection];

    if (isChecked) {
      rowSelection.push(row.id);
    } else {
      let rowIndex: number = rowSelection.findIndex(
        (id: number) => id == row.id
      );
      rowSelection.splice(rowIndex, 1);
    }

    this.tableState.rowSelection = _.sortedUniq(_.sortBy(rowSelection));
    this.saveTableState();
    this.setSelectedRows();
  }
  setSelectedRows() {
    this.saveTableState();

    let selectedRows: any[] = [];
    this.tableState.rowSelection.forEach((id: number) => {
      let selectedRow = this.rows.find((row: any) => row.id === id);
      selectedRows.push(selectedRow);
    });
    this.selectedRows = selectedRows;
  }
  onResetRows() {
    this.tableState.rowSelection = [];
    this.selectedRows = [];
    this.saveTableState();
  }

  // EXPORT
  exportCSV(exportCols: any[]) {
    console.log('exportCols', exportCols);
    let exportTable: any[] = [];
    const exportColNames = this.getPropArray(exportCols, 'name');
    const exportOptions = {
      title: this.title,
      filename:
        `${this.type}_` +
        new Date().getFullYear() +
        ((new Date().getMonth() > 9 ? '' : 0) +
          (new Date().getMonth() + 1).toString()) +
        (new Date().getDate() > 9 ? '' : 0) +
        new Date().getDate().toString(),
      showLabels: true,
      headers: exportColNames,
    };
    const csvExporter = new ExportToCsv(exportOptions);

    this.filteredRows.forEach((row: any) => {
      let exportRow: any = {};

      for (let i = 0; i < exportCols.length; i++) {
        const col = exportCols[i];

        exportRow[col.key] =
          Object.keys(row).includes(col.key) && row[col.key]
            ? this.parseValue(row, col)
            : ['image'].includes(col.controlType) &&
              row[col.key.replace(/_img/, '')]
            ? this.parseFilenameFromPath(row[col.key.replace(/_img/, '')])
            : ['filename'].includes(col.key) && !row['filename'] && row['scene']
            ? this.parseFilenameFromPath(row['scene'])
            : '';
      }

      exportTable.push(exportRow);
    });
    console.log('exportTable', exportTable);
    csvExporter.generateCsv(exportTable);
    this.showActions = false;
  }

  parseValue(row: any, col: any) {
    const data: any = row[col.key];
    const controlType: string = col.controlType;
    let result: any;

    if (
      [
        'multiSelect',
        'multiSelect_ref',
        'autoComplete-multi_ref',
        'formArray',
      ].includes(controlType)
    ) {
      let values: any[] = [];
      data.forEach((value: any) => {
        if (['referenceLinks'].includes(col.key)) {
          values.push(value.toString());
        } else if (
          ['costs_ref', 'rewards_ref', 'prizes_ref'].includes(col.key)
        ) {
          // handle if 'id' field is null for some reason.
          if (value.id) {
            values.push(`${value['c']} ${value.id['name']}`);
          } else {
            values.push(`${value['c']}`);
          }
          // shouldn't need a db call here. data should be in the field value.
          // this.dataService
          //   .getDocument(
          //     value['t'].name == 'Currency' ? 'currencies' : 'items',
          //     { _id: value['id'] }
          //   )
          //   .subscribe((resource) => {
          //     values.push(`${value['c'].toString()} ${resource.name}`);
          //   });
        } else values.push(value.name);
      });
      result = values.join();
      console.log('result', result);
    } else {
      result = ['calendar-start', 'calendar-end', 'date'].includes(controlType)
        ? new Date(data)
        : ['dropdown', 'dropdown_ref', 'autoComplete_ref'].includes(controlType)
        ? data.name
        : ['toggleButton'].includes(controlType)
        ? data.toString() == 'true'
          ? data.toString().replace(/true/, 'Yes')
          : data.toString().replace(/false/, 'No')
        : data.toString();
    }
    if (controlType === 'formArray') {
      console.log(result, data);
    }
    return result;
  }

  parseFilenameFromPath(path: string) {
    if (!path) {
      return '';
    }
    var n = path.lastIndexOf('/');
    var result = path.substring(n + 1);
    return result;
  }

  getPropArray(arr: any[], prop: string) {
    let result: any[] = [];
    if (arr) {
      arr.forEach((o: any) => result.push(o[prop]));
    }
    return result;
  }

  getImageSrc(key: string, row: any) {
    // temporarily returning null by default until images are available.
    return null;

    let srcKey = key.replace(/_img/gi, '');

    if (row[srcKey]) {
      return row[srcKey];
    } else return null;
  }

  // BULK EDIT
  onStartBulkEdit() {
    // this.getOptionsFromRef('type_ref', 'item-types');
    this.showBulkEdit = true;
    this.bulkEdit.rows = [...this.selectedRows];
    this.bulkEdit.stage = 'selectField';
  }

  onFieldSelectedForEdit() {
    let today = new Date().setHours(0, 0, 0, 0);
    this.bulkEdit.today = new Date(today);

    this.detectEditConflicts();
    this.createEditForm();

    this.bulkEdit.stage = 'editField';
  }

  onFieldEdited() {
    if (this.bulkEdit.conflict) {
      if (this.getConflictRows().length > 0) {
        this.bulkEdit.conflict.rows = this.getConflictRows();
      } else {
        this.bulkEdit.conflict = null;
      }
    }

    this.bulkEdit.stage = 'reviewAndSubmit';
  }

  clearField(form: UntypedFormGroup, fieldKey: string) {
    form.get(fieldKey)!.reset();
  }

  checkOptions(input: string) {
    if (input.length > 0) {
      this.bulkEdit.options = this.options[this.bulkEdit.field.key].filter(
        (value: string) => {
          return value.toLowerCase().includes(input.toLowerCase());
        }
      );
    } else {
      this.bulkEdit.options = [];
    }
  }

  detectEditConflicts() {
    switch (this.bulkEdit.field.key) {
      case 'start':
        this.bulkEdit.conflict = {
          field: this.columns.find((field) => field.key == 'end'),
          detail: `later than ${new Date(
            this.getConflictDate('end')
          ).toDateString()}`,
          indicator: (value: any, row: any) => {
            if (!row.end) return false;
            return new Date(value).valueOf() > new Date(row.end).valueOf();
          },
          updatedAt: new Date(),
        };
        this.bulkEdit.conflict.rows =
          this.bulkEdit.form &&
          this.bulkEdit.form.value[this.bulkEdit.field.key]
            ? this.getConflictRows()
            : [];
        break;
      case 'end':
        this.bulkEdit.conflict = {
          field: this.columns.find((field) => field.key == 'start'),
          detail: `earlier than ${new Date(
            this.getConflictDate('start')
          ).toDateString()}`,
          indicator: (value: any, row: any) => {
            if (!row.start) return false;
            return new Date(value).valueOf() < new Date(row.start).valueOf();
          },
          updatedAt: new Date(),
        };
        this.bulkEdit.conflict.rows =
          this.bulkEdit.form &&
          this.bulkEdit.form.value[this.bulkEdit.field.key]
            ? this.getConflictRows()
            : [];
        break;
      case 'category_ref':
        this.bulkEdit.conflict = {
          field: this.columns.find((field) => field.key == 'type_ref'),
          detail: 'flagged below',
          indicator: (value: any, row: any) => {
            if (!row.type) return false;
            return value.types && !value.types.includes(row.type);
          },
          updatedAt: new Date(),
        };
        this.bulkEdit.conflict.rows =
          this.bulkEdit.form &&
          this.bulkEdit.form.value[this.bulkEdit.field.key]
            ? this.getConflictRows()
            : [];
        this.bulkEdit.conflict.options = this.getConflictOptions();
        break;
      case 'type_ref':
        this.bulkEdit.conflict = {
          field: this.columns.find((field) => field.key == 'category_ref'),
          detail: 'flagged below',
          indicator: (value: any, row: any) => {
            return (
              !row.category_ref ||
              !row.category_ref.types ||
              !row.category_ref.types.includes(value.id)
            );
          },
          updatedAt: new Date(),
        };
        this.bulkEdit.conflict.rows =
          this.bulkEdit.form &&
          this.bulkEdit.form.value[this.bulkEdit.field.key]
            ? this.getConflictRows()
            : [];
        this.bulkEdit.conflict.options = this.getConflictOptions();
        break;
      default:
        this.bulkEdit.conflict = null;
    }
  }

  getConflictDate(key: string) {
    let allValues: any[] = [];

    this.bulkEdit.rows.forEach((row: any) => {
      if (row[key]) {
        let date = new Date(row[key]).valueOf();
        allValues.push(date);
      }
    });

    return ['start'].includes(key)
      ? Math.max(...allValues)
      : Math.min(...allValues);
  }

  getConflictRows() {
    let value = this.bulkEdit.form.value[this.bulkEdit.field.key];

    let conflictRows: any[] = [];
    this.bulkEdit.rows.forEach((row: any) => {
      if (this.bulkEdit.conflict.indicator(value, row)) {
        conflictRows.push(row);
      }
    });

    if (conflictRows.length > 0) {
      return conflictRows;
    } else {
      return [];
    }
  }

  getConflictOptions() {
    let conflictOptions: any = {};

    this.options[this.bulkEdit.field.key].forEach((option: any) => {
      let conflictRows: any[] = [];
      this.bulkEdit.rows.forEach((row: any) => {
        if (this.bulkEdit.conflict.indicator(option, row)) {
          conflictRows.push(row.id);
        }
      });

      if (conflictRows.length > 0) {
        conflictOptions[option.id] = {
          name: option.name,
          rows: conflictRows,
        };
      }
    });

    return conflictOptions;
  }

  createEditForm() {
    // Check for existing value
    let currentValue = this.bulkEdit.form
      ? this.bulkEdit.form.value[this.bulkEdit.field.key]
      : null;

    this.bulkEdit.form = this.fb.group({});

    // Add form control
    switch (this.bulkEdit.field.key) {
      case 'costs_ref':
        this.bulkEdit.group = [
          {
            key: 'lineItemType',
            name: 'LineItemType',
            controlType: 'inputText',
            value: 'Currency',
            hidden: true,
          },
          {
            key: 't',
            name: 'Resource',
            controlType: 'dropdown_ref',
            model: 'resources',
            value: { id: 1, name: 'Currency', _id: '617b62958e2f0e4a67f86c76' },
            hidden: true,
          },
          {
            key: 'id',
            name: 'Currency',
            controlType: 'dropdown_ref',
            model: 'currencies',
          },
          {
            key: 'c',
            name: 'Count',
            controlType: 'inputNumber-buttons',
          },
        ];

        this.bulkEdit.group.forEach((f: any) => {
          if (f.model) {
            this.getOptionsFromRef(f.key, f.model);
          }
        });

        this.bulkEdit.form.addControl(
          this.bulkEdit.field.key,
          this.fb.array([this.createGroup()], [Validators.required])
        );
        break;
      default:
        this.bulkEdit.form.addControl(
          this.bulkEdit.field.key,
          this.fb.control(currentValue, [Validators.required])
        );
    }
  }

  createGroup(): UntypedFormGroup {
    let newGroup: any = {};
    this.bulkEdit.group.forEach((f: any) => {
      newGroup[f.key] = f.value ? f.value : null;
    });

    return this.fb.group(newGroup);
  }

  addGroup() {
    (this.bulkEdit.form.get(this.bulkEdit.field.key) as UntypedFormArray).push(
      this.createGroup()
    );
    console.log(this.bulkEdit.form);
  }

  removeGroup(index: number) {
    (this.bulkEdit.form.get(this.bulkEdit.field.key) as UntypedFormArray).removeAt(
      index
    );
  }

  isFormArrayValid() {
    let nonValues = 0;

    this.bulkEdit.form.value[this.bulkEdit.field.key].forEach((group: any) => {
      Object.values(group).forEach((value) => {
        if (!value) nonValues++;
      });
    });

    return nonValues > 0 ? false : true;
  }

  parseValueForEditReview(data: any, field: any) {
    if (!data) return '';
    if (!data && !['toggleButton'].includes(field.controlType)) return '';

    let output;
    switch (field.controlType) {
      case 'inputText':
      case 'inputTextarea':
      case 'dropdown':
      case 'date':
      case 'calendar-start':
      case 'calendar-end':
        output = data;
        break;
      case 'inputNumber-buttons':
        output = data.toString();
        break;
      case 'dropdown_ref':
      case 'autoComplete_ref':
        output = data.name;
        break;
      case 'multiSelect':
        output = data.join(', ');
        break;
      case 'multiSelect_ref':
        let values: any[] = [];
        data.forEach((o: any) => {
          values.push(o.name);
        });
        output = values.join(', ');
        break;
      case 'formArray':
        let groups: any[] = [];
        data.forEach((formGroup: any) => {
          let values: any[] = [];
          this.bulkEdit.group.forEach((field: any) => {
            values.push(
              this.parseValueForEditReview(formGroup[field.key], field)
            );
          });
          groups.push(values.join(', '));
        });
        output = groups.join(`;
        `);
        break;
      case 'toggleButton':
        data == true ? (output = 'Yes') : (output = 'No');
        break;
    }

    return output;
  }

  onSubmitBulkEdit() {
    let { conflict, form, rows, successCount, failedPayloads } = this.bulkEdit;
    successCount = 0;
    failedPayloads = [];

    // remove conflicts from submission
    if (conflict) {
      conflict.rows.forEach((conflictRow: any) => {
        let conflictIndex = rows.findIndex(
          (row: any) => row.id == conflictRow.id
        );
        rows.splice(conflictIndex, 1);
      });
    }

    // userData from authService
    const userResult = this.authService.getSocialUser();

    form.value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    // loop bulkEdit rows
    rows.forEach((row: any) => {
      // set dates as date objects
      ['start', 'end', 'date'].forEach((key) => {
        if (!form.value[key] && row[key]) {
          form.value[key] = new Date(row[key]);
        }
      });

      // submit
      this.formService
        .submitForm(form.value, `${this.type}/update/${row.id}`, true)
        .subscribe(
          (val) => {
            successCount++;
            console.log('POST call successful value returned in body', val);
          },
          (response) => {
            console.log('POST call in error', response);
            console.log(failedPayloads.length);
            failedPayloads.push({
              rowId: row.id,
              value: form.value[this.bulkEdit.field.key],
            });
            // this.rows[row.id] = response;
          },
          () => {
            console.log('The POST observable is now completed.');
          }
        );
    });

    let messageAfterLoop = setInterval(() => {
      const completedSum = failedPayloads.length + successCount;

      if (completedSum == rows.length) {
        this.messageService.add(
          failedPayloads.length < 1
            ? {
                sticky: true,
                severity: 'success',
                summary: 'Updates Successful',
                detail: !conflict
                  ? `${successCount} rows successfully updated.`
                  : `${conflict.rows.length} conflict(s) removed and ${successCount} rows successfully updated.`,
              }
            : {
                sticky: true,
                severity: 'error',
                summary: 'Submit Error',
                detail: !conflict
                  ? `${successCount} rows successfully updated, and ${failedPayloads.length} errors.`
                  : `${conflict.rows.length} conflicts removed, ${successCount} rows successfully updated, and ${failedPayloads.length} errors.`,
              }
        );

        clearInterval(messageAfterLoop);
      }
    }, 500);

    this.showBulkEdit = false;
  }

  goBack() {
    if (this.bulkEdit.stage == 'reviewAndSubmit') {
      this.onFieldSelectedForEdit();
    } else if (this.bulkEdit.stage == 'editField') {
      this.onStartBulkEdit();
    }
  }

  goNext() {
    if (this.bulkEdit.stage == 'selectField') {
      this.onFieldSelectedForEdit();
    } else if (this.bulkEdit.stage == 'editField') {
      this.onFieldEdited();
    }
  }

  onShowConflicts() {
    console.log(this.bulkEdit.conflict);
    this.showBulkEditConflicts = true;
  }

  // Function to handle when Row Editing is enabled
  onRowEditInit(row: any) {
    // clonedRows is going to preexisting row is there already
    console.log('OnRowEditInit Triggered');
    this.clonedRows[row.id] = { ...row };

    // if there is a start key
    if (row.start) {
      row.start = new Date(row.start);
    }
    console.log('after row start', row);
    // if there is end key available set the value to new Date onject
    if (row.end) {
      row.end = new Date(row.end);
    }
  }

  onRowEditSave(row: any) {
    var changesets = require('diff-json');
    const diffObjects = changesets.diff(this.clonedRows[row.id], row);
    console.log('diff objs', diffObjects);
    console.log('clonedRow', this.clonedRows[row.id]);

    // userData from authService
    let userResult = this.authService.getSocialUser();
    row.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    // remove build data from update submit.
    // it is in the wrong format and causes save conflicts.
    delete row.buildData;

    console.log('row', row);

    this.formService
      .submitForm(row, `${this.type}/update/${row.id}`, true)
      .subscribe(
        (val) => {
          console.log('POST call successful value returned in body', val);
          // adjust submit message for edit/new.
          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Update Successful',
            detail: `"${row.name}" was successfully updated`,
          });
          window.scrollTo(0, 0);
        },
        (response) => {
          console.log('POST call in error', response);
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Submit Error',
            detail: 'There was an error submitting.',
          });
          window.scrollTo(0, 0);
          // this.rows[row.id] = response;
        },
        () => {
          console.log('The POST observable is now completed.');
        }
      );
  }

  onRowEditCancel(row: any, index: number) {
    this.rows[index] = this.clonedRows[row.id];
    delete this.clonedRows[row.id];
  }

  async getOptionsFromRef(
    fieldName: string,
    model: string,
    minimal: boolean = false
  ) {
    const options = await this.dataService.getAllOfTypeAsync(model, {
      query: {},
      autopopulate: true,
      virtuals: true,
    });
    if (minimal) {
      let o: any[] = [];
      for (const option of options) {
        o.push({ id: option.id, name: option.name, _id: option._id });
      }
      this.options[fieldName] = o;
    } else {
      this.options[fieldName] = options;
    }
  }

  customSort(event: SortEvent) {
    console.log('custom sort', event);
    event.data!.sort((data1, data2) => {
      let value1 = data1[event.field!];
      let value2 = data2[event.field!];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      } else if (value1 != null && value2 == null) {
        result = 1;
      } else if (value1 == null && value2 == null) {
        result = 0;
      } else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      } else if (_.isArray(value1) && _.isArray(value2)) {
        if (value1.length < 1 && value2.length > 0) {
          // value 1 is empty, so value 2 wins.
          result = -1;
        } else if (value1.length > 0 && value2.length < 1) {
          // value 2 is empty, so value 1 wins.
          result = 1;
        } else if (value1.length < 1 && value2.length < 1) {
          // both arrays are empty. so no one wins.
          result = 0;
        } else {
          result = value1[0]['name'].localeCompare(value2[0]['name']);
        }
      } else if (typeof value1 === 'object' && typeof value2 === 'object') {
        if (event.field == 'buildStatus') {
          result = value1.text.localeCompare(value2.text);
        } else {
          result = value1.name.localeCompare(value2.name);
        }
      } else result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;

      return event.order! * result;
    });
  }
}
