<div ngClass="p-inputgroup" *ngIf="!field.hideField">
  <span ngClass="p-float-label">
    <p-dropdown [(ngModel)]="record[field.key]"
      (ngModelChange)="onFieldValueChange(field.key, $event); field.touched = true;"
      [options]="options[field.options.fieldName]" optionLabel="name" optionValue="value"
      [autoDisplayFirst]="field.autoDisplayFirst" [filter]="field.filter"
      [filterBy]="field.filterBy" [group]="field.dropdownGroup"
      [scrollHeight]="field.scrollHeight" [showClear]="field.showClear" [style]="field.style"
      [disabled]="field.disabled">
    </p-dropdown>
    <label for="dropdown"> {{ field.label }} <strong *ngIf="field.isRequired"
        style="color: crimson">*</strong></label>
    <p-button *ngIf="field.clearField && record[field.key] && !field.disabled"
      ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger"
      (click)="field.touched = true; record[field.key] = null"></p-button>
  </span>
</div>
<div ngClass="p-mt-5 p-inputgroup" *ngIf="!field.hideField && record[field.key] && !field.autocomplete">
  <span ngClass="p-float-label">
    <p-dropdown [(ngModel)]="record[field.keyDependent]"
      (ngModelChange)="onFieldValueChange(field.keyDependent, $event); field.touchedDependent = true;"
      [options]="options[field.optionsDependent.fieldName]" optionLabel="name" optionValue="value"
      [autoDisplayFirst]="field.autoDisplayFirst" [filter]="field.filter" [label]="field.labelDependent"
       [filterBy]="field.filterBy"
      [group]="field.dropdownGroup" [scrollHeight]="field.scrollHeight" [showClear]="field.showClear" [style]="field.style"
      [disabled]="field.disabled">
    </p-dropdown>
    <label for="Reference"> {{ field.labelDependent }} <strong *ngIf="field.isRequired"
        style="color: crimson">*</strong></label>
    <p-button *ngIf="field.clearField && record[field.keyDependent] && !field.disabled"
      ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger"
      (click)="field.touched = true; record[field.key] = null"></p-button>
  </span>
</div>

<div ngClass="p-mt-5 p-inputgroup" *ngIf="!field.hideField && record[field.key] && field.autocomplete">
  <span ngClass="p-float-label">
            <p-autoComplete [(ngModel)]="record[field.keyDependent]"
              [suggestions]="suggestions[field.keyDependent]"
              (completeMethod)="getSuggestionsForRef($event.query, field.suggestions.fieldName, field.optionsDependent.controllerDictionary[controller], field.suggestions.minimal, field.suggestions.autopopulate,
              field.suggestions.sort,field.suggestions.virtuals, field.suggestions.select, field.suggestions.customQuery, field.suggestions.customQueryField)"
              [showEmptyMessage]="true" optionValue="id" minLength="3" (onSelect)="onFieldValueChange(field.keyDependent, $event); field.touchedDependent = true" [placeholder]="field.placeholderDependent">
              <ng-template let-item pTemplate="item">
                {{ item.name }} ({{ item.id }})
              </ng-template>
            </p-autoComplete>
    <label for="Reference"> {{ field.labelDependent }} <strong *ngIf="field.isRequired"
        style="color: crimson">*</strong></label>
    <p-button *ngIf="field.clearField && record[field.keyDependent] && !field.disabled"
      ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger"
      (click)="field.touched = true; record[field.key] = null"></p-button>
  </span>
</div>