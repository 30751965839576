import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import GuestUser from '../user-dtos/dtos/GuestUser';
import { LoggerService } from '../common/services/logger.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
})
export class HomePageComponent implements OnInit {
  loggedIn: Boolean = this.authService.getLoggedIn();
  guestUserLoggedIn: boolean = false;
  displayExternalRegister: boolean = false;
  displayExternalLogin: boolean = false;
  guestUser = new GuestUser();
  user: SocialUser = this.authService.getSocialUser()?.currentUser;
  isGuestUser: Boolean;
  @Output() onLogout = new EventEmitter<any>();
  @Output() onLogin = new EventEmitter<any>();
  domainEnv: 'development' | 'test' |'production';

  constructor (
    public socialAuthService: SocialAuthService,
    private authService: AuthService,
    private loggerService: LoggerService,
    private messageService: MessageService,
    private router: Router,
  ) {
    this.isGuestUser = localStorage.getItem('isGuestUser') == 'true' ? true : false;
  }

  /**
   * Home Page Component Initialization
   */
  async ngOnInit() {
    if(window.location.href.includes('prod.cms') ){
      this.domainEnv = 'production';
    } else if (window.location.href.includes('test.cms') ) {
      this.domainEnv = 'test';
    } else {
      this.domainEnv = 'development';
    }
  }

  /**
   * Emits event when logout requested
   */
  logout(redirectToLogin: boolean = true) {
    this.authService.logout(redirectToLogin);
    window.location.reload();
  }

  externalRegister() {
    this.authService.registerExternalUser(this.guestUser).subscribe({
      next: (response) => {
        // Handle successful registration
        this.messageService.add({
          severity: 'success',
          summary: 'Registration Successful',
          detail: 'You have been successfully registered. Please request system administrator to get your account activated',
          sticky: true,
        });
        this.displayExternalRegister = false; // Optionally close the dialog
        this.guestUser = { email: '', password: '' }; // Optionally reset form
      },
      error: (error) => {
        // Handle error during registration
        this.messageService.add({
          severity: 'error',
          summary: 'Registration Failed',
          detail: 'Registration was not successful. Please try again.',
          sticky: true,
        });
      }
    });
  }

  externalLogin() {
    this.authService.loginExternalUser(this.guestUser).subscribe({
      next: (response) => {
        // Handle successful login
        this.messageService.add({
          severity: 'success',
          summary: 'Login Successful',
          detail: 'You have been successfully logged in.',
          sticky: false,
        });
        this.displayExternalLogin = false; // Close the login dialog

        // Optionally, save the token and navigate the user
        localStorage.setItem('token', response.accessToken);
        // this.router.navigate(['/dashboard']);
      },
      error: (error) => {
        console.log(error);
        // Handle login error
        this.messageService.add({
          severity: 'error',
          summary: 'Login Failed',
          detail: error.error.message,
          sticky: false,
        });
      }
    });
  }
}
