import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';

@Component({
  selector: 'app-build-status-cell-renderer',
  templateUrl: './build-status.component.html',
  styleUrls: ['./build-status.component.sass']
})
export class BuildStatusCellRendererComponent implements ICellRendererAngularComp {

  constructor(
    private tableCommunicationService: TableCommunicationService,
    private agGridToolsService: AgGridToolsService
  ) {

  }

  public params: any;
  timestamp = new Date().getTime();
  buildStatus: any;


  agInit(params: any): void {
    this.params = params;
    if(this.params.isMiscAsset){
      this.buildStatus = this.agGridToolsService.getBuildStatusData((this.params.data ? this.params.data.buildOutput : null) || null);
    } else {
      this.buildStatus = this.agGridToolsService.getBuildStatusData((this.params.data[this.params.key] ? this.params.data[this.params.key].buildOutput : null) || null);
    }
  }

  refresh(params: any): boolean {
    return false;
  }

}
