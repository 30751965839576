import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { DataService } from 'src/app/services/data.service';
@Injectable({
  providedIn: 'root'
})
export class AgGridUserSettings {

  constructor
  (
    private dataService: DataService
  ) {}

  async saveColumnState(table: string, state: any, isViewEmbedded: boolean = false, parentViewName: any) {
    try {
      if(isViewEmbedded && parentViewName) {
        table = 'ag-grid-'+ `${parentViewName}-` + table;
      } else {
      table = 'ag-grid-'+table;
      }
      // Code to save the current column state to the user's profile
      let response: any = await this.dataService.updateUserSettings(table, 'columnState', state);
    } catch (error) {
      console.error("Failed to update user settings", error);
    }
  }

  async getColumnState(table: string, isViewEmbedded: boolean = false, parentViewName: any) {
    try {
      if(isViewEmbedded && parentViewName) {
        table = 'ag-grid-'+ `${parentViewName}-` + table;
      } else {
      table = 'ag-grid-'+table;
      }
      // Code to save the current column state to the user's profile
      return await this.dataService.getUserSettings(table, 'columnState');
    } catch (error) {
      console.error("Failed to update user settings", error);
    }
  }

  async saveGridViewState(table: string, state: any) {
    try {

      table = 'ag-grid-'+table;
      // Code to save the current column state to the user's profile
      let response: any = await this.dataService.updateUserSettings(table, 'gridViewState', state);
    } catch (error) {
      console.error("Failed to update user settings", error);
    }
  }

  async getGridViewState(table: string) {
    try {
      table = 'ag-grid-'+table;
      // Code to save the current column state to the user's profile
      return await this.dataService.getUserSettings(table, 'gridViewState');
    } catch (error) {
      console.error("Failed to update user settings", error);
    }
  }
}
