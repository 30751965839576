<dynamic-form-v2 
    *ngIf="!isLoading" 
    entityType="co-op" 
    [formName]="title" 
    [(record)]="coOpRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [showBottonSubmit]="true"
    [autopopulateEntity]="true"
    [validationCallbackFunction]="validateCoOpForm.bind(this)"
>
</dynamic-form-v2>