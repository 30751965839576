<p-fieldset legend="User Permissions" [toggleable]="true" [collapsed]="true">
  <div class="card">
    <p-toast></p-toast>
    <p-toolbar styleClass="p-mb-4">
      <ng-template pTemplate="left">
        <button pButton pRipple label="Add new user permission" icon="pi pi-plus" class="p-button-success"
          styleClass="p-mr-2" (click)="showAddNewUserPermission = true"></button>
      </ng-template>
      <ng-template pTemplate="right"> </ng-template>
    </p-toolbar>
  </div>
  <p-table [value]="userPermissions" styleClass="p-datatable-gridlines" responsiveLayout="scroll" [rowHover]="true"
    dataKey="id" [scrollable]="true" scrollHeight="400px">
    <ng-template pTemplate="header">
      <tr>
        <th>
          Name
        </th>
        <th>
          Description
        </th>
        <th>
          User
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-permission>
      <tr>
        <td>{{ permission.permissionId ? permission.permissionId.name : null }}</td>
        <td>{{ permission.description }}</td>
        <td>{{ permission.userId ? permission.userId.name: null }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-fieldset>

<!-- Dialogs -->

<!-- Add new permission -->
<p-dialog [(visible)]="showAddNewUserPermission" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
  <p-messages></p-messages>
  <ng-template pTemplate="header">
    <h3>Add new User Permission</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form>
      <div>
        <div class="p-field">
          <span>
            <ng-container>
              <div class="p-mb-5">
                <p-dropdown appendTo="body" [options]="userList" [required]="true"
                  [(ngModel)]="newUserPermission.userId" placeholder="Select a User" optionLabel="name"
                  [showClear]="true" name="user" optionValue="value" [filter]="true" filterBy="name"
                  (onChange)="clearMessages()"></p-dropdown>
              </div>
            </ng-container>
            <br />
            <ng-container>
              <div class="p-mb-5">
                <span class="p-float-label">
                  <input id="description" type="text" pInputText name="description"
                    [(ngModel)]="newUserPermission.description" appendTo="body" (ngModelChange)="clearMessages()">
                  <label for="description">Description</label>
                </span>
              </div>

            </ng-container>
            <br />
            <ng-container>
              <div class="p-mb-5">
                <p-dropdown appendTo="body" [options]="permissionsList" [required]="true"
                  [(ngModel)]="newUserPermission.permissionId" placeholder="Select a Permission" optionLabel="name"
                  [showClear]="true" name="permission" optionValue="value" [filter]="true" filterBy="name"
                  (onChange)="clearMessages()"></p-dropdown>
              </div>
            </ng-container>
          </span>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
      (click)="showAddNewUserPermission = false; clearMessages()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
      (click)="createNewUserPermission()"></button>
  </ng-template>
</p-dialog>
