import { LoginComponent } from './../../../auth/login/login.component';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { BuildType } from 'src/app/enums/build-type';
import { DataService } from '../../../services/data.service';
import { EntityViewService } from './../../../services/entity-view.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { Title, Meta } from '@angular/platform-browser';
import { AssetTypes } from '../../enums/AssetTypes';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
import { ItemService } from '../services/item.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { BuildService } from 'src/app/build-data/services/build.service';
import { ArtToolsService } from 'src/app/tools/art/services/art-tools.service';
import { ItemValidationService } from '../services/item-validation.service';

@Component({
  selector: 'item-view',
  templateUrl: './item-view.component.html',
  styleUrls: ['./item-view.component.sass'],
})
export class ItemViewComponent implements OnInit {
  @Input() id: any;

  entity: any;
  type: string = 'items';
  page: string = 'view';
  areEnvValuesOk: boolean = true;
  buildAndRender: boolean = true;
  //
  multi: any[] = [];
  view: any[] = [900, 400];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = false;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Build';
  yAxisLabel: string = 'Time (min)';
  timeline: boolean = false;
  autoScale: boolean = true;

  imgData: any = {};
  showImgDialog: boolean = false;
  contentHolds: any = [
    'Bundle Hold',
    'New Arrivals Hold',
    'Prize Hold',
    'Progression Hold',
    'Series Hold',
    'Do Not Use'
  ]
  colorScheme = {
    domain: [
      '#5AA454',
      '#E44D25',
      '#CFC0BB',
      '#7aa3e5',
      '#a8385d',
      '#aae3f5',
      '#925ef2',
    ],
  };

  headerFieldGroup: any[] =
  [
    {
      name: 'Cultivar',
      key: 'cultivar',
      value: '',
      controlType: 'inputText',
    },
    {
      name: 'Latin Name',
      key: 'latinName',
      value: '',
      controlType: 'inputText',
    },
    {
      name: 'Plant Family',
      key: 'plantFamily',
      value: '',
      controlType: 'inputText',
    },
  ];

  mainStatusGroup: any[] =
  [
    {
      name: 'Enabled',
      key: 'enabled',
      value: '',
      controlType: 'toggle',
    },
    {
      name: 'Art Status',
      key: 'vendorStatus',
      value: '',
      controlType: 'dropdown',
    },
    {
      name: 'Content Status',
      key: 'itemStatus',
      value: '',
      controlType: 'dropdown',
    },
  ];
  secondaryStatusGroup: any[] =
  [
    {
      name: 'Environment(s)',
      key: 'env',
      controlType: 'envs',
    },
    {
      name: 'Build Status',
      key: 'prefab_ref',
      controlType: 'buildStatus',
    },
    {
      name: 'Thumbnail Build Status',
      key: 'thumbnail_ref',
      controlType: 'buildStatus',
    },
    {
      name: 'Linked Prize',
      key: 'isReward',
      value: '',
      controlType: 'toggle',
    },
    {
      name: 'Spruce Data Status',
      key: 'spruceDataStatus',
      value: '',
      controlType: 'toggle',
    },
  ];

  cards: any[] =
  [
    {
      name: 'Production',
      rows: [
        {
          layout: 'columns',
          fieldGroups: [
            [
              {
                name: 'ID',
                key: 'id',
                controlType: 'inputText',
                value: '',
              },
              {
                name: 'File Name',
                key: 'fileName',
                controlType: 'auto',
                value: '',
              },
              {
                name: 'Path',
                key: 'prefab',
                controlType: 'auto',
                value: '',
              },
              {
                name: 'Thumbnail Path',
                key: 'thumbnail',
                controlType: 'auto',
                value: '',
              },
            ],
            [
              {
                name: 'File Type',
                key: 'fileType',
                controlType: 'auto',
                value: '',
              },
              {
                name: 'Asset Type',
                key: 'assetType',
                controlType: 'auto',
                value: '',
              },
              {
                name: 'Year',
                key: 'year',
                controlType: 'dropdown',
                value: '',
              },
            ],
            [
              {
                name: 'Vendor Height',
                key: 'vendorHeight',
                controlType: 'inputText',
                value: '',
              },
              {
                name: 'Vendor Spread',
                key: 'vendorLength',
                controlType: 'inputText',
                value: '',
              },
              {
                name: 'Vendor Dimensions',
                key: 'vendorDimensions',
                controlType: 'inputTextarea',
                value: '',
              },
            ],
            [
              {
                name: 'Art Status',
                key: 'vendorStatus',
                controlType: 'dropdown',
                value: '',
              },
              {
                name: 'Art Hold',
                key: 'flagged',
                controlType: 'dropdown',
                value: '',
              },
              {
                name: 'Vendor',
                key: 'vendor_ref',
                controlType: 'autoComplete_ref',
                apiController: 'vendors',
                value: '',
              },
            ],
            [
              {
                name: 'Batch',
                key: 'batch_ref',
                controlType: 'autoComplete_ref',
                apiController: 'batches',
                value: '',
              },
              {
                name: 'Batch Type',
                key: 'batchType',
                controlType: 'auto',
                value: '',
              },
              {
                name: 'Source Vendor',
                key: 'sourceVendor',
                controlType: 'auto',
                value: '',
              },
              {
                name: 'Source Batch',
                key: 'sourceBatch',
                controlType: 'auto',
                value: '',
              },
              {
                name: 'Collection',
                key: 'collection',
                controlType: 'auto',
                value: '',
              },
            ],

             [
              {
                name: 'Bundle Asset (IOS)',
                key: 'bundleAsset',
                controlType: 'toggle',
                value: '',
              },
              {
                name: 'Bundle Image (IOS)',
                key: 'bundleImage',
                controlType: 'toggle',
                value: '',
              },
              {
                name: 'Bundle Asset (AND)',
                key: 'bundleAssetAnd',
                controlType: 'toggle',
                value: '',
              },
              {
                name: 'Bundle Image (AND)',
                key: 'bundleImageAnd',
                controlType: 'toggle',
                value: '',
              },
             ],
            // [
            //   {
            //     name: 'Organic Vendor Dimensions',
            //     key: 'organicVDim',
            //     controlType: 'auto',
            //     value: '',
            //   }, //
            //   {
            //     name: 'Hard Surface Vendor Dimensions',
            //     key: 'hsVDim',
            //     controlType: 'auto',
            //     value: '',
            //   }, //
            // ],
          ],
        },
        {
          layout: 'rows',
          fieldGroups: [
            [
              {
                name: 'Vendor Reference Link',
                key: 'referenceLinks',
                controlType: 'ref-link',
                value: '',
              },
              {
                name: 'Internal Reference Link',
                key: 'internalReferenceLinks',
                controlType: 'ref-link',
                value: '',
              },
            ],
          ],
        },
      ],
    },
    {
      name: 'Internal (Content) Notes',
      rows: [
        {
          layout: 'rows',
          fieldGroups: [
            [
              {
                name: 'Content Notes',
                key: 'internalNotes',
                controlType: 'richTextArea',
                value: '',
              },
            ],
          ],
        },
      ],
    },
    {
      name: 'Content',
      rows: [
        {
          layout: 'columns',
          fieldGroups: [
            [
              {
                name: 'Climate(s)',
                key: 'climates_ref',
                controlType: 'multiSelect_ref',
                apiController: 'climates',
                value: '',
              },
              {
                name: 'Category',
                key: 'category_ref',
                apiController: 'categories',
                controlType: 'dropdown_ref',
                value: '',
              },
              {
                name: 'Type',
                key: 'type_ref',
                apiController: 'item-types',
                controlType: 'dropdown_ref',
                value: '',
              },
              {
                name: 'Trait(s)',
                key: 'traits_ref',
                apiController: 'traits',
                controlType: 'multiSelect_ref',
                value: '',
              },
              {
                name: 'Color(s)',
                key: 'colors_ref',
                apiController: 'colors',
                controlType: 'multiSelect_ref',
                value: '',
              },
              {
                name: 'Height',
                key: 'height',
                controlType: 'inputText',
                value: '',
              },
              {
                name: 'Spread',
                key: 'spread',
                controlType: 'inputText',
                value: '',
              },
            ],
            [
              {
                name: 'Style(s)',
                key: 'styles_ref',
                apiController: 'styles',
                controlType: 'multiSelect_ref',
                value: '',
              },
              {
                name: 'Material(s)',
                key: 'materials_ref',
                apiController: 'materials',
                controlType: 'multiSelect_ref',
                value: '',
              },
              {
                name: 'Pattern(s)',
                key: 'patterns_ref',
                apiController: 'patterns',
                controlType: 'multiSelect_ref',
                value: '',
              },
              {
                name: 'Shape(s)',
                key: 'shape_ref',
                apiController: 'shapes',
                controlType: 'dropdown_ref',
                value: ''
              }
            ],
            [
              {
                name: 'Cost(s)',
                key: 'costs_ref',
                controlType: 'lineItem',
                value: '',
              },
              {
                name: 'Tag(s)',
                key: 'tags_ref',
                controlType: 'multiSelect_ref',
                value: '',
              },
              {
                name: 'Level Lock',
                key: 'progressionLevel_ref',
                controlType: 'dropdown_ref',
                value: '',
              },
              {
                name: 'Content Hold',
                key: 'contentHold_ref',
                controlType: 'multiSelect_ref',
                value: '',
              },
              {
                name: 'Store Start',
                key: 'start',
                controlType: 'calendar-start',
                value: '',
              },
              {
                name: 'Store End',
                key: 'end',
                controlType: 'calendar-end',
                value: '',
              },
              {
                name: 'Re Release Start',
                key: 'reReleaseStart',
                controlType: 'calendar-start',
                value: '',
              },
              {
                name: 'Re Release End',
                key: 'reReleaseEnd',
                controlType: 'calendar-end',
                value: '',
              },
            ],
            [
              {
                name: 'Linked Challenge(s)',
                key: 'challenges_ref',
                controlType: 'ref-link',
                value: '',
              },
              {
                name: 'Associated Asset(s)',
                key: 'releatedItems',
                controlType: 'ref-link',
                value: '',
              },
              {
                name: 'Nurture',
                key: 'nurture_ref',
                apiController: 'nurture',
                controlType: 'dropdown_ref',
                value: '',
              },
            ],
          ],
        },
      ],
    },
    {
      name: 'Vendor Notes',
      rows: [
        {
          layout: 'rows',
          fieldGroups: [
            [
              {
                name: 'Vendor Notes',
                key: 'vendorNotes',
                controlType: 'richTextArea',
                value: '',
              },
            ],
          ],
        },
      ],
    },
    {
      name: 'Build Data',
      rows: [
        {
          layout: 'columns',
          fieldGroups: [
            [
              {
                name: 'Created By',
                key: 'createdBy',
                controlType: 'userName',
                value: '',
              },
              {
                name: 'Created At',
                key: 'createdAt',
                controlType: 'date',
                value: '',
              },
              {
                name: 'Updated At',
                key: 'updatedAt',
                controlType: 'date',
                value: '',
              },
            ],
            [
              {
                name: 'Dimension X',
                key: 'dimensionX',
                controlType: 'inputText',
                value: '',
              },
              {
                name: 'Dimension Y',
                key: 'dimensionY',
                controlType: 'inputText',
                value: '',
              },
              {
                name: 'Dimension Z',
                key: 'dimensionZ',
                controlType: 'inputText',
                value: '',
              },
              {
                name: 'Base Radius',
                key: 'radius',
                controlType: 'inputText',
                value: '',
              },
            ],
            [
              {
                name: 'Current Item Version',
                key: 'asset_versions',
                controlType: 'inputText',
              },
              {
                name: 'Current Image Version',
                key: 'image_versions',
                controlType: 'inputText',
              },
            ],
            [
              {
                name: 'Item Promoted Status',
                key: 'IsAssetUpToDate',
                controlType: 'auto',
                version: '',
              },
              {
                name: 'Latest Item Build Version',
                key: 'LatestAssetBuildVersion',
                controlType: 'auto',
                version: '',
              },
              {
                name: 'Image Promoted Status',
                key: 'IsImageUpToDate',
                controlType: 'auto',
                version: '',
              },
              {
                name: 'Latest Image Build Version',
                key: 'LatestImageBuildVersion',
                controlType: 'auto',
                version: '',
              },
            ],
          ],
        },
      ],
    },
    {
      name: 'Info',
      rows: [
        {
          layout: 'columns',
          fieldGroups: [
            [
              {
                name: 'Blurb Status',
                key: 'blurbStatus',
                controlType: 'dropdown',
                value: '',
              },
              {
                name: 'Blurb',
                key: 'blurb',
                controlType: 'inputTextarea',
                value: '',
              },
            ],
          ],
        },
      ],
    },
    {
      name: 'History',
      rows: [
        {
          layout: 'rows',
          fieldGroups: [],
        },
      ],
    },
  ];
  secondaryCard: any =
  {
    name: 'Images',
    fields: [
      {
        name: 'Thumbnail',
        key: 'thumbnail_url_1024',
        controlType: 'image',
      },
      {
        name: 'Linux Render',
        key: 'renderPreview',
        controlType: 'renderGallery'
      },
      // {
      //   name: 'Support Image Gallery',
      //   key: 'supportImageGallery',
      //   controlType: 'image',
      // },
    ],
  };;

  get buildType(): typeof BuildType {
    return BuildType;
  }
  get assetType(): typeof AssetTypes {
    return AssetTypes;
  }

  // spruce preview
  categoryPreviewName: any;
  options: any = [];
  plantPreview: any = {
    latinName: '',
    category: '',
    type: '',
    colors: { names: [] },
    climates: { names: [] },
    traits: { names: [] },
    hardinessZones: '',
    nativeArea: '',
    sunExposure: '',
    bloomTime: '',
    description: '',
    blurb: '',
    cost: '',
  };
  isLoading: boolean = true;

  s3Cdn: string = 'https://d3tfb94dc03jqa.cloudfront.net/';

  constructor(
    private entityViewService: EntityViewService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private titleService: Title,
    private metaService: Meta,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private itemService: ItemService,
    private itemValidationService: ItemValidationService,
    private commonEntityService: CommonEntityService,
    private buildService: BuildService,
    private artToolsService: ArtToolsService
  ) {}

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));

    if (this.id) {
      let record = await this.commonEntityService.findOneWithQuery(this.type, { query: { id: this.id } });

      if (record && record.id) {
        this.entity = record;

        // Set Page Title
        if (this.entity.name) {
          this.titleService.setTitle(this.entity.name);
        }

        await this.setBuildData();

        // Asset Image Promoted Version props
        this.entity.IsAssetUpToDate = this.buildService.getPromotionStatus(this.entity.prefab_ref, BuildType.Items);
        this.entity.IsImageUpToDate = this.buildService.getPromotionStatus(this.entity.thumbnail_ref, BuildType.Images);
        this.entity.asset_versions = this.buildService.getLatestPromotedVersion(this.entity.prefab_ref, 'asset');
        this.entity.image_versions = this.buildService.getLatestPromotedVersion(this.entity.thumbnail_ref, 'image');
        this.entity.LatestAssetBuildVersion = this.buildService.getLatestBuiltVersion(this.entity.prefab_ref);
        this.entity.LatestImageBuildVersion = this.buildService.getLatestBuiltVersion(this.entity.thumbnail_ref);

        // generate renderPreview image
        this.entity.renderPreview = this.artToolsService.generateImagePath(this.entity.id,"items");

        if (this.entity.prefab_ref && this.entity.prefab_ref.dimensionX) {
          this.entity.dimensionX = this.entity.prefab_ref.dimensionX.toFixed(3);
        }

        if (this.entity.prefab_ref && this.entity.prefab_ref.dimensionY) {
          this.entity.dimensionY = this.entity.prefab_ref.dimensionY.toFixed(3);
        }

        if (this.entity.prefab_ref && this.entity.prefab_ref.dimensionZ) {
          this.entity.dimensionZ = this.entity.prefab_ref.dimensionZ.toFixed(3);
        }

        if (this.entity.prefab_ref && this.entity.prefab_ref.radius) {
          this.entity.radius = this.entity.prefab_ref.radius.toFixed(3);
        }

        if (
          this.entity.itemFileType_ref &&
          this.entity.itemFileType_ref.name
        ) {
          this.entity.fileType = this.entity.itemFileType_ref.name;
        }

        let envCheck = await this.itemValidationService.checkEnvFlagsRequirementsAPICall(this.id);

        if (!envCheck.devReady || !envCheck.qaReady || !envCheck.prodReady) {
          this.areEnvValuesOk = false;
        }

        // Spruce Card Preview conditions. Checking for nulls.
        if (this.entity.latinName) {
          this.plantPreview.latinName = this.entity.latinName;
        }
        if (this.entity.category_ref) {
          this.plantPreview.category = this.entity.category_ref.name;
        }
        if (this.entity.type_ref) {
          this.plantPreview.type = this.entity.type_ref.name;
        }
        if (this.entity.externalPlantData_ref) {
          this.plantPreview.hardinessZones =
            this.entity.externalPlantData_ref.hardinessZone;
          this.plantPreview.nativeArea =
            this.entity.externalPlantData_ref.nativeArea;
          this.plantPreview.sunExposure =
            this.entity.externalPlantData_ref.sunExposure;
          this.plantPreview.bloomTime =
            this.entity.externalPlantData_ref.bloomTime;
        }

        if (this.entity.climates_ref) {
          for (let climate of this.entity.climates_ref) {
            this.plantPreview.climates.names.push(` ${climate.name}`);
          }
        } else {
          this.plantPreview.climates.names = '🌼 Not Filled';
        }
        if (this.entity.colors_ref) {
          for (let color of this.entity.colors_ref) {
            this.plantPreview.colors.names.push(` ${color.name}`);
          }
        } else {
          this.plantPreview.colors.names = '🌼 Not Filled';
        }
        if (this.entity.traits_ref) {
          for (let trait of this.entity.traits_ref) {
            this.plantPreview.traits.names.push(` ${trait.name}`);
          }
        } else {
          this.plantPreview.traits.names = '🌼 Not Filled';
        }

        if (this.entity.costs_ref) {
          console.log('logging coin', this.entity.costs_ref);
          for (let cost of this.entity.costs_ref) {
            console.log('currency', cost);
            console.log('cost', cost.c);
            this.plantPreview.cost = cost.c;
          }
        }

        this.secondaryCard.fields.forEach((f: any) => {
          // this.populateImgField(f);
        });

        if (this.entity.externalPlantData_ref) {
          console.log(
            'spruce image',
            this.entity.externalPlantData_ref.imagePath
          );
          const fileName = this.entity.externalPlantData_ref.imagePath + '.jpg';
          console.log('filename', fileName);

          if (this.entity.externalPlantData_ref.imagePath) {
            this.entity.spruce_img = this.s3Cdn + fileName;
            console.log('spruce image', this.entity.spruce_img);
          }
        }

        console.log('entity again', this.entity);

        // setting thumbnails value
        if (this.entity.thumbnails && this.entity.thumbnails[1]) {
          this.entity.newThumbnail_img = this.entity?.thumbnails[1].path;
        } else {
          this.entity.newThumbnail_img = this.entity?.thumbnail_img;
        }

        for (let card of this.cards) {
          for (let row of card.rows) {
            if (row.layout && row.layout == 'columns') {
              row.fieldGroups = this.chunkArray(row.fieldGroups);
            }

            row.fieldGroups.forEach((group: any) => {
              group.forEach((field: any) => {
                this.entity[field.key] = this.parseValueForView(
                  this.entity[field.key],
                  field
                );
              });
            });
          }
        }
      }
    }

    this.isLoading = false;
  }

  /**
   * Sets the build data for the entity.
   */
  async setBuildData()
  {
    let prefabBuildData = this.entity.prefab_ref ? this.entity.prefab_ref.buildData : null;
    let thumbnailBuildData = this.entity.thumbnail_ref ? this.entity.thumbnail_ref.buildData : null;
    if(prefabBuildData)
    {
      let response = await this.buildService.getBuildData(prefabBuildData);

      if(response)
      {
        this.entity.prefab_ref = this.entity.prefab_ref == undefined ? {} : this.entity.prefab_ref;
        this.entity.prefab_ref.buildData = response.buildData;
        this.entity.prefab_ref.buildStatus = response.buildStatus;
      }
    }

    if(thumbnailBuildData)
    {
      let response = await this.buildService.getBuildData(thumbnailBuildData);

      if(response)
      {
        this.entity.thumbnail_ref = this.entity.thumbnail_ref == undefined ? {} : this.entity.thumbnail_ref;
        this.entity.thumbnail_ref.buildData = response.buildData;
        this.entity.thumbnail_ref.buildStatus = response.buildStatus;
      }
    }
  }

  chunkArray(array: string | any[]) {
    if (array.length > 1) {
      return _.chunk(array, Math.round(array.length / 2));
    } else return _.chunk(array, array.length);
  }

  /**
   * Retrieves the build data for the entity.
   */
  async getBuildData()
  {
    if(this.entity.prefab_ref && this.entity.prefab_ref.buildData)
    {
      let response = await this.buildService.getBuildData(this.entity.prefab_ref.buildData);
      this.entity.prefab_ref.buildData = response.buildData;
      this.entity.prefab_ref.buildStatus = response.buildStatus;
    }
    else
    {
      this.entity.prefab_ref.buildData = [];
      this.entity.prefab_ref.buildStatus = {
        text: 'Build Status Not Found',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }
    if(this.entity.thumbnail_ref && this.entity.thumbnail_ref.buildData)
    {
      let response = await this.buildService.getBuildData(this.entity.thumbnail_ref.buildData);
      this.entity.thumbnail_ref.buildData = response.buildData;
      this.entity.thumbnail_ref.buildStatus = response.buildStatus;
    }
    else
    {
      this.entity.thumbnail_ref.buildData = [];
      this.entity.thumbnail_ref.buildStatus = {
        text: 'Build Status Not Found',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }
  }

  /**
   * Updates the build data for the entity.
   */
  async updateBuildData()
  {
    setTimeout(async () =>
    {
      let record = await this.commonEntityService.findOneWithQuery(this.type, { query: { id: this.id } });

      if(record)
      {
        this.entity.prefab_ref = record.prefab_ref;
        this.entity.thumbnail_ref = record.thumbnail_ref;
      }
      // get build data.
      await this.getBuildData();
      this.loggerService.log('got updated entity', this.entity);
    }, 500);
  }

  parseValueForView(data: any, field: any) {
    if (!data && !['toggle'].includes(field.controlType)) return '';
    let output;
    switch (field.controlType) {
      case 'date':
      case 'calendar-start':
      case 'calendar-end':
        output = this.datePipe.transform(new Date(data), 'medium');
        break;
      case 'toggle':
        if (field.key == 'spruceDataStatus') {
          output = data ? 'Linked' : 'Unlinked';
          break;
          }
        if (field.key == 'enabled') {
          output = data ? 'Enabled' : 'Not Enabled';
          break;
          }
          if (field.key == 'isReward') {
            output = data ? 'Yes' : 'No';
            break;
            }
        output = data;
        break;
      default:
        output = data;
        break;
    }
    if(field.key == 'vendorStatus' && this.entity['flagged']) {
      output = this.entity['flagged']
    } else if (field.key == 'itemStatus' && this.entity['contentHold_ref'] && this.entity['contentHold_ref'].length > 0) {
      if(this.entity['contentHold_ref'].some((obj: any) => obj.name.toLowerCase() === "do not use")){
        if(this.entity['contentHold_ref'].length > 1){
          output = `Do Not Use (+${this.entity['contentHold_ref'].length - 1})`
        } else {
          output = "Do Not Use"
        }
      } else {
        for(let hold of this.contentHolds) {
            if (this.entity['contentHold_ref'].some((obj: any) => obj.name.toLowerCase() === hold.toLowerCase())) {
              {
                if(this.entity['contentHold_ref'].length > 1){
                  output = `${hold} (+${this.entity['contentHold_ref'].length - 1})`
                  return output
                } else {
                  output = hold
                  return output
                }
              }
          }
        }
      }
    }
    return output;
  }

  getMostRecent(dates: any[]) {
    let allDates: any[] = [];
    dates.forEach((d) => {
      allDates.push(Date.parse(d));
    });

    return new Date(Math.max(...allDates)).toLocaleDateString();
  }

  /*
  Plant Preview Setup Function

  This function matches user's selected options from the itemForm and grabs the names from the options that were set from the nestedOptions functions from above
  formFieldValue = x.colors_ref
  formfield = string of the field such as 'color_ref'
   */
  setDefaultPic() {
    this.entity.thumbnail_img =
      'https://d3tfb94dc03jqa.cloudfront.net/images/asset_missing/need_thumb/need_thumb_1024.png';
  }

  /** Uses field key to dynamically parse path for image retrieval.
   * @param field FieldData
   */
  populateImgField(field: any) {
    if (field.key.endsWith('_img')) {
      // define path
      const pathKey: any = Object.keys(this.entity).find(
        (key: string) =>
          key === field.key.substring(0, field.key.indexOf('_img'))
      );
      const path = ['externalPlantData_ref'].includes(pathKey)
        ? this.entity[pathKey].imagePath
        : this.entity[pathKey];

      if (path) {
        // define default size
        const size: string = this.page === 'table' ? '_256' : '_1024';
        // define default fileExt
        const fileExt: string =
          path.endsWith('.png') || path.endsWith('.jpg')
            ? ''
            : this.type === 'challenges' && pathKey === 'image'
            ? '.jpg'
            : '.png';

        // define default fileName
        const n =
          pathKey === 'scene' ? path.lastIndexOf('s/') : path.lastIndexOf('/');
        const rString = path.substring(n + 1);

        let output;
        if (this.type === 'challenges' && pathKey === 'scene') {
          let array = rString.split('/');
          array[2] = array[2] + '_bg';
          output =
            this.s3Cdn +
            'images/bg_renders' +
            array.join('/') +
            '_bg' +
            size +
            fileExt;
        } else {
          output =
            (this.type === 'items' && pathKey === 'thumbnail') ||
            (this.type === 'challenges' && pathKey === 'image')
              ? this.s3Cdn + path + '/' + rString + size + fileExt
              : this.s3Cdn + path + fileExt;
        }

        this.entity[field.key] = output;
      } else {
        this.entity[field.key] = null;
      }
    }
  }

  /** Compiles and sets global imgData, then sets showImgDialog to true.
   * @param field FieldData
   */
  displayImage(field: any) {
    if (field.key == 'thumbnail') {
      this.imgData = {
        title: field.name,
        name: this.entity[field.key].replace('_256.', '_1024.'),
        path: this.entity[field.key].replace('_256.', '_1024.'),
      };
      this.showImgDialog = true;
    } else if (this.entity[field.key]) {
      if (['challenges', 'items'].includes(this.type)) {
        var n = this.entity[field.key].lastIndexOf('/');

        this.imgData = {
          title: field.name,
          name: this.entity[field.key].substring(n + 1),
          path: this.entity[field.key].replace('256.', '1024.'),
        };

        this.showImgDialog = true;
      }
    }
  }

  /**
   *
   * @param copyVal // text to copy into the user's clipboard
   */
  copyToClipboard(copyVal: string) {
    this.clipboard.copy(copyVal);
    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Thumbnail Copied',
      detail: `URL copied to clipboard successfully!`,
    });
  }

  /**
   *
   * @param field the field to evaluate
   * @returns the style class of the badge
   */
  statusBgColors(field: any) {
    if (field && field.key) {
      switch (field.key) {
        case 'vendorStatus':
          if (['Hold', 'In QA', 'Marked for Deletion'].includes(this.entity['flagged'])) {
            return 'red-status'
          } else if
            (['Assigned', 'In Revision']
            .includes(this.entity[field.key]))
          {
              return 'yellow-status'
          } else if (!this.entity[field.key]) {
            return 'gray-status'
          } else {
            return 'green-status'
          }
        case 'itemStatus':
          if(this.entity && this.entity.contentHold_ref && this.entity.contentHold_ref.length > 0) {
            return 'red-status'
          } else if (this.entity[field.key] == 'Approved') {
            return 'green-status'
          } else {
            return 'gray-status'
          }
        case 'enabled':
          if(this.entity.enabled){
            return 'green-status'
          } else {
            return 'red-status'
          }
        case 'isReward':
          if(!this.entity.isReward) {
            return 'gray-status'
           } else {
            return 'blue-status'
           }
        case 'spruceDataStatus':
          if(!this.entity.spruceDataStatus) {
            return 'gray-status'
           } else {
            return 'blue-status'
           }
        case 'env':
          if(!this.entity.env || this.entity.env.length == 0) {
            return 'gray-status';
          } else if (!this.areEnvValuesOk) {
            return 'red-status'
          } else if (['dev', 'qa', 'prod'].every(i => this.entity.env.includes(i))) {
            return 'green-status'
          } else if (['dev'].every(i => this.entity.env.includes(i))) {
            return 'yellow-status'
          } else if (['dev', 'qa'].every(i => this.entity.env.includes(i))) {
            return 'blue-status'
          } else {
            return 'red-status'
          }
        case 'buildStatus':
          if (this.entity.buildStatus && this.entity.buildStatus.text == 'Build Status Not Found') {
            return 'gray-status'
          } else if (this.entity.buildStatus && (this.entity.buildStatus.text == 'Full Fail' || this.entity.buildStatus.text == 'Partial Fail')) {
            return 'red-status'
          } else if (this.entity.buildStatus && this.entity.buildStatus.text == 'Success') {
            return 'green-status'
          } else {
            return 'yellow-status'
          }
        case 'imageBuildStatus':
          if (this.entity.imageBuildStatus && this.entity.imageBuildStatus.text == 'Build Status Not Found') {
            return 'gray-status'
          } else if (this.entity.imageBuildStatus && (this.entity.imageBuildStatus.text == 'Full Fail' || this.entity.imageBuildStatus.text == 'Partial Fail')) {
            return 'red-status'
          } else if (this.entity.imageBuildStatus && this.entity.imageBuildStatus.text == 'Success') {
            return 'green-status'
          } else {
            return 'yellow-status'
          }
      }
    }
    return 'gray-status'
  }
}
