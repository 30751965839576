import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FieldBase } from './../../field-base';
import { UntypedFormGroup } from '@angular/forms';
import { FieldService } from 'src/app/services/field.service';
import { FieldControlService } from './../../../services/field-control.service';
import { LoggerService } from 'src/app/common/services/logger.service';

@Component({
  selector: 'form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.sass'],
})
export class FormGroupComponent implements OnInit {
  @Input() form!: UntypedFormGroup;
  @Input() formGroup!: UntypedFormGroup;
  @Input() fields: FieldBase<string>[] = [];

  group: FieldBase<string>[] = [];

  constructor(
    private fieldService: FieldService,
    private fcs: FieldControlService,
    private loggerService: LoggerService
  ) {}

  async ngOnInit() {
    console.log('form group init');
    this.group = [...this.fields];
    this.loggerService.log('group: ',this.group);
  }

  onFieldAdded(e: any) {
    console.log(`onFieldAdded(${e.field.key})`);
    console.log(this.group);
    const { key, field, i } = e;
    let index = this.group!.findIndex((f) => f.key === key);

    this.group?.splice(index + i, 0, field);
  }

  onFieldReplaced(e: any) {
    console.log(`onFieldReplaced: ${e.field.key}`);
    const { key, field } = e;
    let index = this.group!.findIndex((f) => f.key === key);

    this.group?.splice(index, 1, field);
  }

  onFieldRemoved(e: any) {
    console.log(`onFieldRemoved: ${e}`);
    const key = e;
    let index = this.group!.findIndex((f) => f.key === key);

    this.group?.splice(index, 1);
  }
}
