<div class="p-grid p-mt-1">
  <div style="position: relative;" class="p-col-12">
    <!-- Spinner -->
    <ngx-spinner bdColor="rgba(36,34,36,0.8)" [size]="spinnerSize" [color]="spinnerColor" [type]="spinnerIcon"
      [fullScreen]="true">
    </ngx-spinner>

    <!-- Header -->
    <div *ngIf="isEmbedded else notEmbedded">
      <h2>{{ !isEdit ? 'Add New': 'Editing' }} {{ formName }} {{ isEdit ? '('+record.id+')' : null }}</h2>
    </div>
    <ng-template #notEmbedded>
      <div class="inline-container">
        <h1>{{ !isEdit ? 'Add New' : 'Editing' }} {{ formName }} {{ isEdit ? '('+record.id+')' : null }}</h1>
        <div *ngIf="isStoreEntity">
          <p-tag class="p-ml-3" [rounded]="true" [icon]="getIcon(storeEnv)" [severity]="getSeverity(storeEnv)"
            [value]="storeEnv | uppercase">
          </p-tag>
        </div>
      </div>
    </ng-template>


    <!-- Body -->
    <div class="p-grid" *ngIf="!isLoading && fields && fields.length > 0">
      <div [class]="useTwoColumnLayout ? 'p-col-8' : 'p-col-12'">
        <div>
          <!-- Submit and Generate Set Buttons -->
          <div *ngIf="!isSubChildComponent && !isEmbedded" class="p-mb-1" style="display: flex; align-items: center;">
            <button pButton pRipple class="p-ml-3 p-mb-2" type="submit" label="Submit"
              [ngStyle]="{ 'min-width': '19rem' }" (click)="beforeSubmitAction()"></button>
          </div>
          <!-- Enable Button -->
          <div *ngIf="!hideEnabledField" class="centered-toggle-button">
            <span ngClass="p-float-label">
              <p-toggleButton [(ngModel)]="record.enabled" [onLabel]="'Enabled'" [offLabel]="'Not Enabled'"
                class="field-item" [style]="{'width': '45em'}"
                (onChange)="onFieldValueChange('enabled', $event);"></p-toggleButton>
            </span>
          </div>
          <div *ngIf="this.hasbundleAsset">
            <span ngClass="p-float-label">
              <p-toggleButton [(ngModel)]="record.bundleAsset" [onLabel]="'Bundle Asset (IOS)'"
                [offLabel]="'Not Bundle Asset (IOS)'" class="field-item" [ngStyle]="{'width': '100%'}"
                (onChange)="onFieldValueChange('bundleAsset', $event);"></p-toggleButton>
            </span>
          </div>
          <div *ngIf="this.hasbundleImage">
            <span ngClass="p-float-label">
              <p-toggleButton [(ngModel)]="record.bundleImage" [onLabel]="'Bundle Image (IOS)'"
                [offLabel]="'Not Bundle Image (IOS)'" class="field-item" [ngStyle]="{'width': '100%'}"
                (onChange)="onFieldValueChange('bundleImage', $event);"></p-toggleButton>
            </span>
          </div>
          <div *ngIf="this.hasbundleAssetAnd">
            <span ngClass="p-float-label">
              <p-toggleButton [(ngModel)]="record.bundleAssetAnd" [onLabel]="'Bundle Asset (AND)'"
                [offLabel]="'Not Bundle Asset (AND)'" class="field-item" [ngStyle]="{'width': '100%'}"
                (onChange)="onFieldValueChange('bundleAssetAnd', $event);"></p-toggleButton>
            </span>
          </div>
          <div *ngIf="this.hasbundleImageAnd">
            <span ngClass="p-float-label">
              <p-toggleButton [(ngModel)]="record.bundleImageAnd" [onLabel]="'Bundle Images (AND)'"
                [offLabel]="'Not Bundle Images (AND)'" class="field-item" [ngStyle]="{'width': '100%'}"
                (onChange)="onFieldValueChange('bundleImageAnd', $event);"></p-toggleButton>
            </span>
          </div>
          <div *ngFor="let card of fields">
            <!-- Card/Section Type-->
            <ng-container [ngSwitch]="card.type">
              <!-- CARD -->
              <p-card class="p-mx-2" *ngSwitchCase="cardType.Card">
                <!-- Card Title -->
                <span *ngIf="card.title && card.title.length > 0" class="p-card-title p-mx-5 p-mb-5">{{ card.title
                  }}</span>
                <div class="p-grid p-mx-5">
                  <!-- Card Fields -->
                  <div
                    class="p-my-2 p-col-{{card.columns ? (12 /card.columns) : field.columnWidth ? field.columnWidth : 12 }}"
                    *ngFor="let field of card.fields">
                    <!-- Input Types Switch Case -->
                    <ng-container [ngSwitch]="field.inputType">
                      <!-- Base Input Type Field -->
                      <ng-container *ngSwitchCase="inputType.BaseInputField">
                        <app-base-input-field [(fieldValue)]="record[field.key]"
                          *ngIf="!field.displayType || (field.displayType && record[field.displayType])"
                          [record]="record"
                          [field]="field"
                          [isEdit]="isEdit"
                          [inputTypeFields]="field.inputTypeFields"
                          [isRequired]="field.isRequired"
                          [dynamicForm]="this"
                          [options]="options[field.key]"
                          [options]="options"
                          [validate]="field.validate"
                          [isdisplayNameIDPath]="field.displayNameIDPath"
                          [optionLabel]="optionLabel"
                          [optionValue]="optionValue"
                          [optionDisabled]="field.optionDisabled"
                          [entityType]="entityType"
                          (fieldValueChange)="onFieldValueChange(field.key, $event, false, field.displayControls||null, field.displayControlType||null)"
                          (isBaseInputValid)="handleBaseInputValidEvent($event)">
                        </app-base-input-field>
                      </ng-container>
                      <!-- ReadonlyFieldComponent -->
                      <div class="p-inputgroup p-pr-5" *ngSwitchCase="inputType.ReadonlyFieldComponent"
                        [ngStyle]="{'width': '100%'}">
                        <app-readonly-field [(isPlural)]="field.showPlural" [(record)]="record[field.key]"
                          [(options)]="options['currency_record_ref']" [(entityType)]="this.entityType" [(label)]="field.label"
                          (ngModelChange)="onFieldValueChange(field.key, $event); field.touched = true;"></app-readonly-field>
                      </div>
                      <!-- Editor -->
                      <div class="p-mx-5" *ngSwitchCase="inputType.Editor">
                        <h5>{{ field.label }}</h5>
                        <div class="p-my-5 p-inputgroup">
                          <span ngClass="p-float-label">
                            <p-editor [(ngModel)]="record[field.key]" [style]="field.style"
                              (onTextChange)="field.touched = true; onFieldValueChange(field.key, $event)"
                              (onSelectionChange)="field.touched = true; onFieldValueChange(field.key, $event)">
                              <ng-template pTemplate="header">
                                <span class="ql-formats">
                                  <select type="button" class="ql-header p-mr-2" aria-label="Header"></select>
                                  <button type="button" class="ql-bold" aria-label="Bold"></button>
                                  <button type="button" class="ql-italic" aria-label="Italic"></button>
                                  <button type="button" class="ql-underline p-mr-2" aria-label="Underline"></button>
                                  <select type="button" class="ql-color" aria-label="Color"></select>
                                  <select type="button" class="ql-background p-mr-2"
                                    aria-label="Background Color"></select>
                                  <button type="button" value="ordered" class="ql-list" aria-label="Underline"></button>
                                  <button type="button" value="bullet" class="ql-list" aria-label="Underline"></button>
                                  <button type="button" class="ql-link p-mr-2" aria-label="Underline"></button>
                                </span>
                              </ng-template>
                            </p-editor>
                          </span>
                          <p-button *ngIf="field.clearField && record[field.key]" ariaLabel="Clear Field"
                            icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                            (click)="record[field.key] = null"></p-button>
                        </div>
                      </div>
                      <!-- Price/Reward/Cost Input -->
                      <div *ngSwitchCase="inputType.Price" [ngStyle]="{'width': '100%'}">
                        <span>
                          <prizes-card-form-group [(prize)]="record[field.key]" [showPrize]="true"
                            [fieldsetName]="field.name" [excludeResourceTypes]="field.priceExcludeResourceTypes"
                            (onPrizeChange)="field.touched = true; onFieldValueChange(field.key, $event); record[field.key] = $event"
                            [version]="field.options?.version">
                          </prizes-card-form-group>
                        </span>
                      </div>
                      <!-- Multiple Price/Reward/Cost Input -->
                      <div *ngSwitchCase="inputType.MultiplePrices">
                        <div class="p-grid p-mx-5">
                          <div class="p-col-4 p-pr-4" *ngFor="let prize of field.prices">
                            <prizes-card-form-group [(prize)]="record[prize.key]" [showPrize]="prize.showPrize"
                              [fieldsetName]="prize.name" [excludeResourceTypes]="prize.priceExcludeResourceTypes"
                              (onPrizeChange)="prize.touched = true; onFieldValueChange(prize.key, $event)">
                            </prizes-card-form-group>
                          </div>
                        </div>
                      </div>
                      <!-- Season Pass Component -->
                      <div *ngSwitchCase="inputType.SeasonPassComponent">
                        <season-pass-tracks-field [(seasonPassRecord)]="record" [field]="field"
                          (onChange)="onCustomFieldChange($event, field)"></season-pass-tracks-field>
                      </div>
                      <!-- Season Pass Component -->
                      <div *ngSwitchCase="inputType.CategoryIcon">
                        <category-icon [(categoryIconRecord)]="record" [field]="field"
                          (onChange)="onCustomFieldChange($event, field)"></category-icon>
                      </div>
                      <!-- Season Pass Component -->
                      <div *ngSwitchCase="inputType.CategoryClimateIcon">
                        <category-climate-icon [(categoryClimateIconRecord)]="record" [field]="field"
                          (onChange)="onCustomFieldChange($event, field)"></category-climate-icon>
                      </div>
                      <!-- Rewards Track Component -->
                      <div *ngSwitchCase="inputType.RewardsTrackComponent" [ngStyle]="{'width': '100%'}">
                        <rewards-track-dynamic-field [(rewardsTrackRecord)]="record"
                          (onRecordChange)="onCustomFieldChange($event, field)"
                          [EOTrackOptions]="field.options && field.options.createOrSelect && field.options.createOrSelect.child ? options[field.options.createOrSelect.parentKey] : []"
                          [EOTrackParentData]="field.options && field.options.createOrSelect && field.options.createOrSelect.child ? record[field.options.createOrSelect.parentKey] : null"></rewards-track-dynamic-field>
                      </div>
                      <!-- Endless Offer Rewards Track Component -->
                      <div *ngSwitchCase="inputType.EndlessOfferRewardsTrackComponent" [ngStyle]="{'width': '100%'}">
                        <div *ngIf="record[field.options.createOrSelect.parentKey]">
                          <eo-rewards-track-dynamic-field [(endlessOfferTrackRecord)]="record"
                            [columnCount]="record?.column_count" (onRecordChange)="onCustomFieldChange($event, field)"
                            [field]="field"
                            [options]="field.options && field.options.createOrSelect && field.options.createOrSelect.child ? options[field.options.createOrSelect.parentKey] : []"
                            [parentFieldData]="field.options && field.options.createOrSelect && field.options.createOrSelect.child ? record[field.options.createOrSelect.parentKey] : null"></eo-rewards-track-dynamic-field>
                        </div>
                        <div *ngIf="!record[field.options.createOrSelect.parentKey]">
                          * Select a {{field.options.createOrSelect?.parentLabel}} Record First.
                        </div>
                      </div>
                      <!-- Nested Inputs -->
                      <div *ngSwitchCase="inputType.NestedInputComponent" [ngStyle]="{'width': '100%'}">
                        <app-nested-input [record]="record" [componentType]="field.componentType"
                          (onChange)="field.touched = true; onFieldValueChange(field.key, $event, true)"
                          [field]="field"></app-nested-input>
                      </div>
                      <!-- Goal Images -->
                      <div *ngSwitchCase="inputType.GoalImageComponentComponent" [ngStyle]="{'width': '100%'}">
                        <app-goal-image-component [(coOpRecord)]="record"
                          (onChange)="field.touched = true; onFieldValueChange(field.key, $event)"
                          [field]="field"></app-goal-image-component>
                      </div>
                      <!-- RESTRICTIONS -->
                      <div *ngSwitchCase="inputType.RestrictionComponent" [ngStyle]="{'width': '100%'}">
                        <restriction-form-field [(restrictionRecord)]="record"
                          (onRecordChange)="field.touched = true; onFieldValueChange(field.key, $event)"
                          [isEdit]="isEdit"></restriction-form-field>
                      </div>
                      <!-- CollectionsCustomField -->
                      <div *ngSwitchCase="inputType.CollectionsCustomField">
                        <collection-custom-field [(collection)]="record"
                          (onChange)="field.touched = true; record[field.key] = $event">
                        </collection-custom-field>
                      </div>
                      <div *ngSwitchCase="inputType.ChallengeCollectionsCustomField">
                        <challenge-collection-custom-field [(collection)]="record"
                          (onChange)="field.touched = true; record[field.key] = $event">
                        </challenge-collection-custom-field>
                      </div>
                      <!-- DAILY REWARDS -->
                      <div *ngSwitchCase="inputType.DailyRewardsDynamicFieldComponent">
                        <daily-rewards-dynamic-field [(dailyRewardsRecord)]="record" [options]="options" [field]="field"
                          (onChange)="field.touched = true; onFieldValueChange(field.key, $event);">
                        </daily-rewards-dynamic-field>
                      </div>
                      <!-- Season Pass Form Tracks Custom Field Component -->
                      <div *ngSwitchCase="inputType.SeasonPassFormTracksCustomField">
                        <season-pass-form-tracks-custom-field [isEdit]="isEdit" [(seasonPassFreeTrack)]="record.free"
                          [(seasonPassPaidTrack)]="record.paid" [freeOptions]="options['free']"
                          [paidOptions]="options['paid']" [seasonPassRecord]="record"
                          (onChange)="onCustomFieldChange($event, field); record[$event.field] = $event.value">
                        </season-pass-form-tracks-custom-field>
                      </div>
                      <!-- Nurture Custom Field -->
                      <div *ngSwitchCase="inputType.NurtureCustomField">
                        <nurture-stage-custom-field [miscImageOptions]="options['image_ref']"
                          [stages]="record['stages_ref']" [isLoading]="isLoading"
                          (onChange)="field.touched = true; onFieldValueChange(field.key, $event);">
                        </nurture-stage-custom-field>
                      </div>
                      <!-- Nurture Stages Input -->
                      <div *ngSwitchCase="inputType.NurtureStagesField">
                        <app-nurture-stages-input [record]="record" [stages]="record['stages_ref']"
                          [costs]="record['costs_ref']"
                          [climate]="record && record.itemId && record.itemId[0] && record.itemId[0]['climates_ref'] ? record.itemId[0]['climates_ref'] : null"
                          [nurtureItem]="record && record.itemId && record.itemId[0] ? record.itemId[0] : null"
                          [category]="record && record.itemId && record.itemId[0] && record.itemId[0]['category_ref'] ? record.itemId[0]['category_ref'] : null"
                          [options]="options['stages_ref']"
                          (onChange)="field.touched = true; field.touchedDependent = true; record[$event.field] = $event.value"
                          (onGenerateStages)="regenerateOptions(field)">
                        </app-nurture-stages-input>
                      </div>
                      <!-- loading screens images component -->
                      <div *ngSwitchCase="inputType.LoadingScreenImages">
                        <loading-screen-images [(images)]="record[field.key]"
                          (onImagesChange)="field.touched = true; onFieldValueChange(field.key, $event)"></loading-screen-images>
                      </div>
                      <!-- Release Type -->
                      <div *ngSwitchCase="inputType.ReleaseTypeOrderList">
                        <release-type-custom-field [(fields)]="record[field.key]" [template]="record['releaseTemplate']"
                          (onChange)="field.touched = true; onFieldValueChange(field.key, $event); record[field.key] = $event"
                          (onChangeTemplate)="field.touchedDependent = true; onFieldValueChange(field.keyDependent, $event); record[field.keyDependent] = $event">
                        </release-type-custom-field>
                      </div>
                      <!-- DROPDOWN DEPENDENT -->
                      <div *ngSwitchCase="inputType.DropdownDependent" [ngStyle]="{'width': '100%'}">
                        <app-dependent-input [field]="field" [record]="record" [options]="options" (onRecordChange)="onFieldValueChange($event.field, $event.value)">
                        </app-dependent-input>
                      </div>
                      <!-- plastic autocomplete component -->
                      <div *ngSwitchCase="inputType.PlasticAutoComplete" class="p-inputgroup">
                        <plastic-files-autocomplete [ngStyle]="{'width': '100%'}" *ngIf="!isLoading"
                          [(path)]="record.path"
                          (onComplete)="handlePathComplete($event); onFieldValueChange(field.key, event); field.touched = true;"
                          (onChange)="buildPath($event);"></plastic-files-autocomplete>
                      </div>
                      <!-- asset record ref input component -->
                      <div *ngSwitchCase="inputType.assetInputComponent" class="p-inputgroup p-pr-5"
                        [ngStyle]="{'width': '100%'}">
                        <app-asset-ref-input [ngStyle]="{'width': '100%'}" *ngIf="!isLoading" [isEdit]="isEdit"
                          [label]="field.label" [assetRecordData]="record[field.key]"
                          [parentRecordEntity]="field.parentEntity" [defaultEntityType]="field.defaultEntityType"
                          [defaultAssetType]="field.defaultAssetType"
                          [defaultEntityTypeName]="field.defaultEntityTypeName" [parentRecordId]="record.id"
                          [parentRecordAssetKeyName]="field.key" [nameInputEnabled]="field.nameInputEnabled"
                          [assetTypeInputEnabled]="field.assetTypeInputEnabled">
                        </app-asset-ref-input>
                      </div>
                      <div *ngSwitchCase="inputType.Enabled">
                        <span ngClass="p-float-label">
                          <p-toggleButton [(ngModel)]="record[field.key]" [onLabel]="'Export Enabled'" [offLabel]="'Export Disabled'"
                             [style]="{'width': '15em'}"
                            (onChange)="onFieldValueChange(field.key, $event); field.touched = true"></p-toggleButton>
                        </span>
                      </div>
                      <!-- nurture items component -->
                      <div *ngSwitchCase="inputType.nurtureItemsComponent" class="p-inputgroup"
                        [ngStyle]="{'width': '100%'}">
                        <app-nurture-items-input-component [nurtureItems]="record[field.key]" [field]="field"
                          (onChange)="onFieldValueChange(field.key, $event); field.touched = true;">
                        </app-nurture-items-input-component>
                      </div>
                      <!-- Embedded Form -->
                      <div *ngSwitchCase="inputType.EmbeddedForm" [ngStyle]="{'width': '100%'}">
                        <app-embedded-form [field]="field" [record]="record"
                          [options]="options[field.options.fieldName]" [submitEmbededForm]="submitEmbeddedFormEvent"
                          (entitySelected)="this.record[field.key] = $event; onFieldValueChange(field.key, $event); field.touched = true;">
                        </app-embedded-form>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </p-card>
            </ng-container>
          </div>
          <!-- Environment Flags Input -->
          <p-card class="p-mx-2" *ngIf="!(hideEnvFlagsField || isStoreEntity)">
            <span class="p-card-title p-ml-3">Environment(s)</span>
            <div class="p-grid p-mx-5">
              <div class="p-my-4 p-col-6">
                <div class="p-inputgroup" [ngStyle]="{'width': '100%'}">
                  <span ngClass="p-float-label">
                    <p-multiSelect [(ngModel)]="record['env']" [options]="options['envs']" display="chip"
                      [style]="{'width': '35em'}" (onChange)="onFieldValueChange('env', $event)">
                    </p-multiSelect>
                    <label for="env-multiselect"> Select Environments</label>
                  </span>
                  <p-button *ngIf="record['env'] && record['env'].length > 0" ariaLabel="Clear Field"
                    icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                    (click)="record['env'] = []; onFieldValueChange('env', $event)"></p-button>
                </div>
              </div>
            </div>
          </p-card>
          <!-- Footer -->
          <button *ngIf="showBottonSubmit && !isSubChildComponent && !isEmbedded" pButton pRipple type="submit"
            label="Submit" class="p-mb-5 p-ml-3 p-mt-5" [ngStyle]="{ 'min-width': '19rem' }"
            (click)="beforeSubmitAction()"></button>
        </div>
      </div>
      <div *ngIf="useTwoColumnLayout" class="p-col-4">
        <div class="p-mt-8">
          <div *ngFor="let card of sidebarFields">
            <div class="p-grid">
              <ng-container *ngFor="let field of card.fields">
                <ng-container [ngSwitch]="field.inputType">
                  <div class="p-inputgroup" *ngSwitchCase="inputType.SpaceCol" [ngStyle]="{'width': '100%'}">
                  </div>
                  <div *ngSwitchCase="inputType.InGamePreview">
                    <p-card class="p-d-block p-pt-7 p-mx-auto p-px-3 p-mt-2">
                      <in-game-preview [record]="record" [field]="field">
                      </in-game-preview>
                    </p-card>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-dialog header="Form submission conflicts" [(visible)]="showConflictsModal" [modal]="true">
    <p>Current {{entityType}} ID: {{recordId}} was updated by someone else.</p>
    <p>Record Fetched at: {{conflictFetchedAt}}</p>
    <p>Record was updated at: {{conflictUpdatedAt}}</p>
    <p-divider></p-divider>
    <p-table [value]="conflicts" [tableStyle]="{'min-width': '50rem'}">
      <ng-template pTemplate="header">
        <tr>
          <th>Column</th>
          <th>Current Value</th>
          <th>Update Value</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-conflict>
        <tr>
          <td>{{conflict.key}}</td>
          <td>{{conflict.currentValue}}</td>
          <td>{{conflict.updateValue}}</td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-d-flex p-jc-center p-mt-3">
      <p-button class="p-mx-3" styleClass="p-button-success" icon="pi pi-angle-left" label="Go Back"
        (onClick)="showConflictsModal=false">
      </p-button>
      <p-button class="p-mx-3" styleClass="p-button-danger" icon="pi pi-save" label="Save Changes and overwrite"
        (onClick)="submitAndOverwrite()">
      </p-button>
    </div>
  </p-dialog>
  <p-dialog *ngIf="!isLoading" header="Start/End Date Verification" [(visible)]="showDatesVerificationModal"
    [modal]="true" [ngStyle]="{minWidth: '40vw'}">
    <div>
      <h4 class="p-m-0">Original Start and End date values</h4>
    </div>
    <div class="p-grid p-mt-0">
      <div class="p-col">
        <h5>Start:</h5>
        <p *ngIf="originalRecord['start']">{{formatDate(originalRecord['start'])}}</p>
      </div>
      <div class="p-col">
        <h5>End:</h5>
        <p *ngIf="originalRecord['end']">{{formatDate(originalRecord['end'])}}</p>
      </div>
    </div>
    <p-divider></p-divider>
    <div>
      <h4 class="p-m-0">New Start and End date values</h4>
    </div>
    <div class="p-grid">
      <div class="p-col">
        <h5>Start:</h5>
        <p *ngIf="record['start']">{{formatDate(record['start'])}}</p>
      </div>
      <div class="p-col">
        <h5>End:</h5>
        <p *ngIf="record['end']">{{formatDate(record['end'])}}</p>
      </div>
    </div>
    <p-divider></p-divider>
    <div>
      <p-tag styleClass="p-mx-2" [value]="startDateErrorMessage" severity="danger" [rounded]="true"></p-tag>
      <p-tag styleClass="p-mx-2" [value]="endDateErrorMessage" severity="danger" [rounded]="true"></p-tag>
    </div>
    <ng-template pTemplate="footer">
      <button pButton pRipple type="submit" label="Cancel Submit" class="p-mt-2 p-button-secondary"
        (click)="showDatesVerificationModal=!showDatesVerificationModal"></button>
      <button pButton pRipple type="submit" label="Confirm Submit" class="p-mt-2 p-button"
        (click)="submitAndSkipChecks('date')"></button>
    </ng-template>
  </p-dialog>

  <p-confirmDialog></p-confirmDialog>
</div>
<!-- Side Bar -->
<!-- <div *ngIf="useTwoColumnLayout" class="p-col-4">
    <div *ngFor="let card of sidebarFields">
      <div class="p-grid">
        <ng-container *ngFor="let field of card.fields">
          <ng-container [ngSwitch]="field.inputType">
            <div class="p-inputgroup" *ngSwitchCase="inputType.SpaceCol" [ngStyle]="{'width': '100%'}">
            </div>
            <div *ngSwitchCase="inputType.InGamePreview">
              <p-card class="p-d-block p-pt-7 p-mx-auto p-px-3 p-mt-2">
                <in-game-preview
                  [record]="record"
                  [field]="field">
                </in-game-preview>
              </p-card>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div> -->
<!-- <div *ngIf="useTwoColumnLayout" class="p-col-4">
    <div *ngFor="let card of sidebarFields">
      <div *ngFor="let field of card.fields">
        <ng-container [ngSwitch]="field.inputType">

        </ng-container>

      </div>
    </div>
  </div> -->

<!-- <div *ngIf="useTwoColumnLayout" class="p-col-4">
    <div *ngFor="let card of fields"> -->
<!-- Card/Section Type-->
<!-- <ng-container [ngSwitch]="card.type"> -->
<!-- CARD -->
<!-- <p-card class="p-mx-2" *ngSwitchCase="cardType.Card"> -->
<!-- Card Title -->
<!-- <span *ngIf="card.title && card.title.length > 0" class="p-card-title p-mx-5 p-mb-5">{{ card.title
          }}</span> -->
<!-- <div class="p-grid"> -->
<!-- Card Fields -->
<!-- <div class="p-col-12"
            *ngFor="let field of card.sidebarFields"> -->
<!-- Input Types Switch Case -->
<!-- <ng-container [ngSwitch]="field.inputType"> -->
<!-- Preview -->
<!-- <div *ngSwitchCase="inputType.InGamePreview">
                <in-game-preview
                  [record]="record"
                  [field]="field">
                </in-game-preview>
              </div>
            </ng-container>
    </div>
</div> -->
