<div *ngIf="!isLoading" style="position: relative;">
  <!-- Spinner -->
  <ngx-spinner bdColor="rgba(36,34,36,0.8)" [size]="spinnerSize" [color]="spinnerColor" [type]="spinnerIcon"
    [fullScreen]="true">
  </ngx-spinner>

  <!-- Header -->
  <div>
    <div *ngIf="!isStoreEntity" class="p-my-3 p-d-flex p-jc-between">
      <span>
        <h1 class="p-mr-3 p-d-inline"> {{ record.name }} ({{ viewName }} {{record.id}})</h1>
        <button *ngIf="!hideEditButton" pButton pRipple icon="pi pi-pencil" label="Edit" type="button"
          class="p-d-inline" (click)="navigateToEdit()"></button>
      </span>
      <ng-container *ngIf="isNotesPopUp">
        <app-notes-popup [record]="record" [entityType]="entityType"></app-notes-popup>
      </ng-container>
      <span class="p-d-flex">
        <button *ngIf="showLocalizedValuesButton" pButton pRipple label="Localized Values"
        type="button" icon="fa fa-language" (click)="fetchLocalized()" class="p-ml-auto p-mr-2">
        </button>

        <!-- Conditional Blocks based on isFullWidthButtons -->
        <ng-container *ngIf="isFullWidthButtons">
          <div class="p-d-flex" style="gap: 15px;">
            <!-- Enabled Block -->
            <div style="display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                height: 33px;  /* Reduced height */
                width: 300px;  /* Fixed width */
                background-color: #81C784;
                color: black;
                border-radius: 7px;
                text-align: center;
              ">
              <h3 style="margin: 0;">
                {{ record.enabled ? 'Enabled' : 'Not Enabled' }}
              </h3>
            </div>
            <!-- Environment(s) Block -->
            <div style="
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                height: 33px;  /* Reduced height */
                width: 300px;  /* Fixed width */
                background-color: #4CAF50;
                color: black;
                border-radius: 7px;
                text-align: center;
              ">
              <h3 style="margin: 0;">
                {{ record.env && record.env.length > 0 ? record.env.join(', ') : 'No Environment Found' }}
              </h3>
            </div>
          </div>
        </ng-container>
        <p-button *ngIf="allowDuplicate" label="Duplicate"
          icon="fa-solid fa-copy" iconPos="right"
          (onClick)="dupDialog.show({id: record.id, _id: record._id})" class="p-button-secondary p-mr-4"></p-button>
      </span>
    </div>
    <div *ngIf="isStoreEntity && allowDuplicate" class="p-mb-1 p-d-flex p-jc-end">
      <p-button label="Duplicate"
        icon="fa-solid fa-copy" iconPos="right"
        (onClick)="dupDialog.show({id: record.id, _id: record._id})" class="p-button-secondary p-mr-4"></p-button>
    </div>


  <!-- Main View Body-->
  <div class="p-grid" *ngIf="!isLoading && fields && fields.length > 0">
    <div [class]="isFullWidth ? 'p-col-12' : 'p-col-8'">
      <!-- Cards -->
      <div *ngFor="let card of fields" class="">
        <ng-container [ngSwitch]="card.type">
          <div *ngIf="isStoreEntity">
            <p-tag [rounded]="true" [icon]="getIcon(storeEnv)" [severity]="getSeverity(storeEnv)"
              [value]="storeEnv | uppercase"></p-tag>
          </div>
          <p-card *ngSwitchCase="cardType.Card" [header]="card.title">
            <div class="p-grid p-mx-5">
              <!-- Fields -->
              <div class="p-col-{{card.columns ? (12 /card.columns) : field.columnWidth ? field.columnWidth : 12 }}"
                *ngFor="let field of card.fields">
                <ng-container *ngIf="shouldDisplayField(field, record)">
                  <ng-container [ngSwitch]="field.inputType">
                    <div *ngSwitchCase="ViewType.BaseViewField" style="width: 100%;">
                      <app-base-view-field [field]="field" [record]="record" [viewTypes]="field.viewTypes"
                        [options]="field.options"></app-base-view-field>
                    </div>
                    <div *ngSwitchCase="ViewType.JobsRecordTable" style="width: 100%;">
                      <app-job-records-table *ngIf="jobRecordsArray.length > 0 && record.uuid" [jobRecords]="jobRecordsArray" [diffUuid]="record.uuid"></app-job-records-table>
                    </div>
                    <div *ngSwitchCase="ViewType.AutoComplete" style="width: 100%;">
                      <app-autocomplete [record]="record" [field]="field" [entityType]="entityType"></app-autocomplete>
                    </div>
                    <div *ngSwitchCase="ViewType.MiscImageRecordV2" style="width: 100%;">
                      <app-misc-image-record-v2 [record]="record" [field]="field" [viewMode]="field.viewMode || 'record'"
                        [type]="viewType"></app-misc-image-record-v2>
                    </div>
                    <div *ngSwitchCase="ViewType.NestedInputComponent" class="field-container">
                      <app-nested-input-view [record]="record" [field]="field"[layoutMode]="field.layoutMode">  </app-nested-input-view>
                    </div>
                    <div *ngSwitchCase="ViewType.RewardTrack" style="width: 100%;">
                        <app-rewards-track-view [entity]="rewardsTrackRecord"></app-rewards-track-view>
                    </div>
                    <div *ngSwitchCase="ViewType.NestedList" style="width: 100%;">
                      <h4 class="p-d-inline">{{field.label}}: </h4> <br>
                      <app-dynamic-list [record]="record[field.key]"
                        [keysToRender]="field.options?.keys"></app-dynamic-list>
                    </div>
                    <div *ngSwitchCase="ViewType.Boolean" style="width: 100%;">
                      <app-boolean-view [value]="record[field.key]" [label]="field.label"></app-boolean-view>
                    </div>
                    <div *ngSwitchCase="ViewType.GoalImageComponentComponent" class="field-container">
                      <app-goal-imagery [record]="record" [field]="field"></app-goal-imagery>
                    </div>
                    <div *ngSwitchCase="ViewType.NurtureStagesField" style="width: 100%;">
                      <app-nurture-stages-field [record]="record" [field]="field"></app-nurture-stages-field>
                    </div>
                    <div *ngSwitchCase="ViewType.RewardTimeline" style="width: 100%;">
                      <app-rewardtimeline [record]="record" [field]="field"></app-rewardtimeline>
                    </div>                  
                    <div *ngSwitchCase="ViewType.Price" style="width: 100%;">
                      <app-input-price [record]="record" [field]="field"></app-input-price>
                    </div>
                    <div *ngSwitchCase="ViewType.MiscRef" style="width: 100%;">
                      <app-misc-ref [record]="record" [field]="field"></app-misc-ref>
                    </div>
                    <div *ngSwitchCase="ViewType.MiscImageBuildComponent" style="width: 100%;">
                      <app-misc-image-build-component [record]="record" [field]="field" [type]="field.options.type">
                      </app-misc-image-build-component>
                    </div>
                    <div *ngSwitchCase="ViewType.MiscAssetBuildErrorLogs" style="width: 100%;">
                      <app-misc-asset-build-error-logs [recordID]="record.id" [field]="field"></app-misc-asset-build-error-logs>
                    </div>
                    <div *ngSwitchCase="ViewType.MiscImageArrayBuild" style="width: 100%;">
                      <ng-container *ngIf="record[field.key] && record[field.key].length > 0">
                        <app-misc-image-array-build [assetRecords]="record[field.key]" [field]="field">
                        </app-misc-image-array-build>
                      </ng-container>
                    </div>
                    <div *ngSwitchCase="ViewType.CategoryIcons">
                      <app-icons-view [id]="record.id"></app-icons-view>
                    </div>
                    <div *ngSwitchCase="ViewType.EndlessOfferTrackViewComponent">
                      <app-endless-offer-track [rewardTrack]="record"
                        [parentEntity]="field.parentEntity || '' "></app-endless-offer-track>
                    </div>
                    <div *ngSwitchCase="ViewType.EndlessOfferTrackV2">
                      <app-endless-offer-track-v2 [eoTrack]="record[field.key]"
                        [parentEntity]="field.parentEntity || '' "></app-endless-offer-track-v2>
                    </div>
                    <div *ngSwitchCase="ViewType.FilterRules" style="width: 100%;">
                      <app-filter-rules [record]="record" [field]="field"></app-filter-rules>
                    </div>
                    <div *ngSwitchCase="ViewType.AgGridTable">
                      <app-ag-grid-table-view
                        [fieldOptions]="field.options"
                        [fieldKey]="field.key"
                        [record]="record"
                        [isMultiple]="field.options.isMultiple || false"
                        [isPrize]="field.options.isPrize || false"
                        [prizeCounts]="prizeCounts"                       
                        [parentViewName]="parentViewName"
                        [customHeight]="field.options.customHeight || null"
                      ></app-ag-grid-table-view>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </p-card>
          <br>
          <p-card *ngSwitchCase="cardType.BuildHistory" [header]="'Build History'" class="p-mx-2 p-shadow-4">
            <ng-template pTemplate="header">
              <div class="p-d-flex p-p-3">
              </div>
            </ng-template>
            <ng-container>
              <div class="p-mx-5">
                <p-button class="p-mx-2" label="See Latest Builds History"
                  routerLink="/build-history/miscellaneous-build/{{ record.id }}"></p-button>
              </div>
            </ng-container>
          </p-card>
          <p-card *ngSwitchCase="cardType.History" [header]="'Build History'" class="p-mx-2 p-shadow-4">
            <app-history [record]="record" [entityType]="entityType"></app-history>
          </p-card>
          <br>
        </ng-container>
      </div>
      <ng-container *ngIf="showChangesHistory && !hideBuildHistoryButton">
        <p-card class="p-mt-4">
          <div class="history-header">History</div>
          <div class="card-contents">
            <div class="button-container">
              <!-- d/:entityType/build-history/:id -->
              <p-button *ngIf="isDynamicEntity" label="See latest History Changes" routerLink="/d/history/{{entityType}}/{{recordId}}"></p-button>
              <p-button *ngIf="!isDynamicEntity" label="See latest History Changes" routerLink="/history/{{entityType}}/{{recordId}}"></p-button>
            </div>
            <div class="created-by">
              <span class="created-by-label">Created by:</span>
              {{record?.createdBy?.name}}
            </div>
          </div>
        </p-card>
      </ng-container>
    </div>
    <!-- right sidebar -->
    <div [class]="isFullWidth ? 'p-col-12' : 'p-col-4'" *ngIf="!isFullWidth">
      <!-- Build status -->
      <ng-container *ngIf="displayBuildStatus">
        <app-display-build-status [record]="record" [field]="field"></app-display-build-status>
      </ng-container>
      <!-- Enabled -->
      <ng-container *ngIf="!isStoreEntity">
        <h4 class="p-m-2 p-d-none p-d-lg-flex">Enabled:</h4>
        <span class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex">
          <!-- Environment(s) -->
          <ng-container>
            <div class="p-col p-m-2" [ngClass]="record.enabled ? 'green-status' : 'gray-status'">
              <h3 class="p-d-inline p-m-0 p-text-center">
                {{ record.enabled ? 'Enabled' : 'Not Enabled' }}
              </h3>
            </div>
          </ng-container>
        </span>
      </ng-container>
      <!-- Environments -->
      <ng-container *ngIf="!isStoreEntity">
        <h4 class="p-m-2 p-d-none p-d-lg-flex">Environment(s):</h4>
        <span class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex">
          <!-- Environment(s) -->
          <ng-container>
            <div class="p-col p-m-2" [ngStyle]="{
                position: 'relative',
                color: 'var(--primary-color-text)',
                'background-color': 'green',
                'border-radius': '0.5em'
              }">
              <h3 class="p-d-inline p-m-0 p-text-center">
                {{ record.env && record.env.length > 0 ? record.env.join(', ') : 'No Environment Found' }}
              </h3>
            </div>
          </ng-container>
        </span>
      </ng-container>
      <!-- thumbnail -->
      <ng-container *ngIf="displayThumbnail">
        <!-- <app-display-thumbnail [record]="record" [field]="field"  [displayThumbnail]="false"></app-display-thumbnail> -->
        <div style="min-width: 225px; max-width: 450px; min-height: 300px; margin-top: 7px; "
          class="p-d-block p-mx-auto">
          <img *ngIf="displayThumbnail && record.thumbnails && record.thumbnails[1]" [src]="record.thumbnails[1].path+'?ts=' + timestamp"
            class="p-mx-auto" width="100%" (click)="displayImage(record.thumbnails[1].path)" />
          <div class="p-grid" *ngIf="displayThumbnail && record.thumbnails">
            <div class="p-col-fixed" style="width:100px">
              <button pButton type="button" icon="pi pi-copy" class="p-button-text p-ml-3 p-mt-1"
                (click)="copyToClipboard(record.thumbnails[1].path)"></button>
            </div>
            <div class="p-col" style="min-width:200px">
              <small *ngIf="displayThumbnail && record.thumbnails && record.thumbnails[1]"
                style="overflow-wrap: anywhere">
                {{record.thumbnails[1].path}}
              </small>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- dynamic sidebar -->
      <ng-container *ngFor="let card of sidebarFields">
        <div class="p-grid">
          <ng-container *ngFor="let field of card.fields">
            <ng-container *ngIf="shouldDisplayField(field, record)">
              <ng-container [ngSwitch]="field.inputType">
                <div *ngSwitchCase="ViewType.BaseViewField" class="p-my-1">
                  <app-base-view-field [field]="field" [record]="record" [viewTypes]="field.viewTypes"
                    [options]="field.options"></app-base-view-field>
                </div>
                <ng-container *ngSwitchCase="ViewType.SpaceCol">
                  <div class="p-col-{{field.columnWidth || 12}} p-p-0">
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="ViewType.sideThumbnail">
                  <div 
                    *ngIf="record[field.key]?.thumbnails && record[field.key]?.thumbnails.length > 0" 
                    class="p-col-{{ field.options?.gridWidth || 12 }} p-p-0">
                    <app-side-thumbnail 
                      [record]="record" 
                      [imageRecord]="record[field.key]" 
                      [field]="field" 
                      [timestamp]="timestamp">
                    </app-side-thumbnail>
                  </div>
                </ng-container>              
                <ng-container *ngSwitchCase="ViewType.sideThumbnailEnhanced">
                  <div class="p-col-{{field.options?.gridWidth || 12}} p-p-0">
                    <!-- Check if the imageRecord is defined before rendering -->
                    <app-side-thumbnail
                      [record]="record"
                      [imageRecord]="record[field.key]?.image"
                      [field]="field"
                      [timestamp]="timestamp">
                    </app-side-thumbnail>
                  </div>
                </ng-container>              
                <ng-container *ngSwitchCase="ViewType.InGamePreview">
                  <div class="p-col-{{field.options?.gridWidth || 12}} p-p-0">
                    <in-game-preview
                      [record]="record"
                      [field]="field"
                    ></in-game-preview>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="ViewType.multiSideThumbnail">
                  <div *ngFor="let imageRecord of record[field.key]"
                    class="p-col-{{field.options?.gridWidth || 12}} p-p-0">
                    <app-side-thumbnail [record]="record" [imageRecord]="imageRecord" [field]="field"
                      [timestamp]="timestamp"></app-side-thumbnail>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="ViewType.deepMultiSideThumbnail">
                  <div class="p-col-12" style="margin-top: 20px;">
                    <div *ngFor="let screen of record[field.key]">
                      <div class="p-grid">
                        <h1>{{ screen.logoImage?.name}}</h1> 
                        <div *ngIf="screen.logoImage" class="p-col-12 p-p-0">
                          <app-side-thumbnail
                            [record]="record"
                            [imageRecord]="screen.logoImage"
                            [field]="field"
                            [timestamp]="timestamp">
                          </app-side-thumbnail>
                        </div>
                        <div *ngFor="let imageRecord of screen.screenImages" class="p-col-{{field.options?.gridWidth || 12}} p-p-0">
                          <app-side-thumbnail [record]="record" [imageRecord]="imageRecord" [field]="field" [timestamp]="timestamp"></app-side-thumbnail>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="ViewType.RenderThumbnails">
                  <div class="p-col-{{field.options?.gridWidth || 12}} p-p-0">
                    <p-card style="width: 100%;" class="p-d-block p-mx-auto p-px-3 p-mt-2"
                      *ngIf="record[field.key] && record[field.key][0] && record[field.key][0].id" [header]="field.label">
                      <span class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex">
                        <h4 *ngIf="record[field.key] && record[field.key].buildStatus"
                          class="p-col p-m-2 p-mx-lg-2 p-my-lg-0">{{ field.name }} Build Status:</h4>
                        <div *ngIf="record[field.key] && record[field.key].buildStatus && field.options.showBuildStatus"
                          class="p-col p-mx-2 p-mb-3" [ngStyle]="{
                    position: 'relative',
                    color: 'var(--primary-color-text)',
                    'background-color':  record[field.key]
                    ? record[field.key].buildStatus.color
                    : '',
                    'border-radius': '0.5em'
                  }">
                          <ng-container [ngSwitch]="record[field.key].buildStatus.text">
                            <app-status-display [record]="record" [field]="field"></app-status-display>
                          </ng-container>
                        </div>
                        <!-- Environment(s) -->
                        <ng-container>
                          <div *ngIf="field.options.showEnabled" class="p-col p-m-2"
                            [ngClass]="record[field.key][0].enabled ? 'green-status' : 'gray-status'">
                            <h3 class="p-d-inline p-m-0 p-text-center">
                              {{ record[field.key][0].enabled ? 'Enabled' : 'Not Enabled' }}
                            </h3>
                          </div>
                        </ng-container>
                      </span>
                      <ng-container *ngIf="field.options.showEnv">
                        <span class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex">
                          <!-- Environment(s) -->
                          <ng-container>
                            <div class="p-col p-m-2" [ngStyle]="{
                          position: 'relative',
                          color: 'var(--primary-color-text)',
                          'background-color': 'green',
                          'border-radius': '0.5em'
                        }">
                              <h3 class="p-d-inline p-m-0 p-text-center">
                                {{ record[field.key][0].env && record[field.key][0].env.length > 0 ?
                                record[field.key][0].env.join(', ') : 'No Environment Found' }}
                              </h3>
                            </div>
                          </ng-container>
                        </span>
                      </ng-container>
                      <ng-container *ngIf="field.options.thumbnailStyle == 'multiple'">
                        <app-render-gallery [entityId]="record[field.key][0].id" [type]="'items'"></app-render-gallery>
                      </ng-container>
                      <ng-container *ngIf="field.options.thumbnailStyle == 'single'">
                        <app-thumbnail-style [record]="record" [field]="field"></app-thumbnail-style>
                      </ng-container>
                    </p-card>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="ViewType.VideoPreview">
                  <div class="p-col-{{field.options?.gridWidth || 12}} p-p-0">
                    <p-card class="p-d-block p-mx-auto p-px-3 p-mt-2">
                      <div class="label-container">
                        <h1 class="video-title">{{ field.label }}</h1>
                        <span class="build-status">Build Status:</span>
                      </div>
                      <div class="video-container">
                        <video class="video-player" width="640" height="360" controls>
                          <source [src]="constructURL(record[field.key])" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </p-card>
                  </div>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- 'View Image' dialog -->
  <p-dialog [(visible)]="showImgDialog" [dismissableMask]="true" [modal]="true" [transitionOptions]="'200ms'"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
      <h3 class="p-m-0">{{ imgData?.title }}</h3>
    </ng-template>

    <div class="p-text-center">
      <img *ngIf="imgData && imgData?.path" [src]="imgData.path + '?ts=' + timestamp" width="90%" />
      <h4 class="p-mt-0" width="90%" style="overflow-wrap: anywhere">
        {{ imgData?.name }}
        <button *ngIf="imgData?.path" pButton type="button" icon="pi pi-copy" class="p-button-text p-ml-3 p-mt-1"
          (click)="copyToClipboard(imgData.path)"></button>
      </h4>
    </div>
  </p-dialog>

  <app-dup-dialog #dupDialog [entity]="entityType" [viewRoute]="viewRoute"></app-dup-dialog>

  <p-dialog
  [(visible)]="displayLocalizedValues"
  [dismissableMask]="true"
  [modal]="true"
  [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: 'auto', minWidth: '50vw' }"
  [contentStyle]="{ 'overflow': 'visible' }"
>
  <ng-template pTemplate="header">
    <h3 class="p-m-0">Localized Values</h3>
  </ng-template>

  <div *ngIf="this.localizedValues" class="p-text-center" style="overflow: auto;">
    <p-table [value]="localizedValues" [autoLayout]="true">
      <ng-template pTemplate="header">
        <tr>
          <th>ID</th>
          <th *ngFor="let column of getColumns(localizedValues)">
            {{ column }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row>
        <tr>
          <td style="text-align: justify;">{{ row.id }}</td>
          <td style="text-align: justify;" *ngFor="let column of getColumns(localizedValues)">
            {{ row[column] || 'N/A' }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-dialog>
