<div
  class="p-d-flex"
  style="
    white-space: normal;
    word-wrap: break-word;
    overflow-y: auto;
    text-align: left;
    line-height: 1.2;
    max-height: 110px;
    word-break: break-word;
  "
>
  <!-- Single Item Rendering -->
  <div *ngIf="!params.isArray && !params.isLoadingScreen && params.value && params.value.id && params.value.name && params.value.path">
    <p>
      <strong>Name: </strong>
      {{params.value.name}}
      <a href="/miscellaneous-build/{{params.value.id}}" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">({{params.value.id}})</a>
    </p>
    <p>
      <strong>Path: </strong>
      {{params.value.path}}
    </p>
  </div>

  <!-- Array Item Rendering -->
  <div *ngIf="params.isArray && params.value">
    <div *ngFor="let item of params.value">
      <p *ngIf="item && item.id && item.name">
        <strong>Name: </strong>
        {{item.name}}
        <a href="/miscellaneous-build/{{item.id}}" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">({{item.id}})</a>
      </p>
      <p *ngIf="item && item.path">
        <strong>Path: </strong>
        {{item.path}}
      </p>
      <hr>
    </div>
  </div>

  <div *ngIf="params.isLoadingScreen">
    <span *ngFor="let v of this.params.value; let index = index">
      <div class="p-grid">
        <div class="p-col-9">
          <p><strong>Name: </strong>{{v.name}}</p>
          <p><strong>Path: </strong>{{v.path}}</p>
          <a href="/miscellaneous-build/{{v.id}}" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">Asset Record: ({{v.id}})</a>
        </div>
        <div class="p-col-3">
          <img
        *ngIf="v.thumbnails && v.thumbnails.length > 0"
        src="{{v.thumbnails[2].path}}"
          style="
              border-radius: 10px;
              width: 100%;
              vertical-align: middle;"
          alt="">
        </div>
      </div>
      <p-divider *ngIf="this.params.value.length > index + 1"></p-divider>
    </span>
  </div>
</div>
