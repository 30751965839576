<dynamic-form-v2 
    *ngIf="!isLoading" 
    entityType="rewards-track" 
    [formName]="title" 
    [(record)]="rewardsTrackRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [showBottonSubmit]="true"
    [autopopulateEntity]="true"
    [isSubChildComponent]="isChildComponent"
    (onFieldChange)="onChange($event)"
>
</dynamic-form-v2>