import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import _ from 'lodash';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
import { SpinnerService } from '../../../common/services/spinner.service';
import  { ReleaseFieldType }  from 'src/app/cms-v2/entities/release-type/components/dtos/ReleaseTypeField';
import { TimelineModule } from 'primeng/timeline';

@Component({
  selector: 'app-release-viewV2',
  templateUrl: './release-viewV2.component.html',
  styleUrls: ['./release-viewV2.component.sass'],
})
export class ReleaseViewV2Component implements OnInit
{
  entity: any = {}; 
  type = 'releases';
  isLoading: boolean;
  fields: any[] = [];
  leftFields: any[] = [];
  rightFields: any[] = [];
  id: any;
  prizes: any[] = [];
  challenges: any[] = [];
  collections: any[] = [];
  events: any[];
  showFullTimeline: boolean = false;
  prizeLinks: any = {
    Currency: 'currencies',
    Item: 'items',
    Titles: 'titles'
  }
  
  cols_prizes: Array<any> = [
    { field: 'lineItemType', header: 'Type' },
    { field: 'id', subfield: 'id', header: 'ID' },
    { field: 'id', subfield: 'name', header: 'Category' },
    { field: 'c', header: 'Quantity' }
];
  get fieldType(): typeof ReleaseFieldType
    {
        return ReleaseFieldType;
    }

getEnvironmentClass(environments: string[] | undefined): string {
  if (!environments || environments.length === 0) {
    return 'no-environment'; 
  }

  return 'has-environment';
}

getEnvironmentText(environments: string[] | undefined): string {
  if (!environments || environments.length === 0) {
    return 'No Environment Found'; 
  }
  return environments.join(', '); 
}

  cards =
  [
    {
      name: 'Release Page',
      fieldGroups: [
        [
          {
            name: 'ID',
            key: 'id',
            controlType: 'inputText',
            value: '',
          },
          {
            name: 'Name',
            key: 'name',
            controlType: 'inputText',
            value: '',
          },
          {
            name: 'Enabled',
            key: 'enabled',
            controlType: 'toggleButton',
            value: '',
          },
          {
            name: 'Start',
            key: 'start',
            controlType: 'calendar-start',
            value: '',
          },
          {
            name: 'End',
            key: 'end',
            controlType: 'calendar-end',
            value: '',
          },
        ],
      ],
    }
  ];
  constructor
  (
    private entityViewService: EntityViewService,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private datePipe: DatePipe,
    private titleService: Title,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private spinnerService: SpinnerService
  ) {}

  /**
   * Currency view component initialization
   */
  async ngOnInit() {
    this.isLoading = true;
    this.spinnerService.loadSpinner();
    const routeParams = this.route.snapshot.paramMap;

    this.id = Number(routeParams.get('id'));

    let result = await this.entityViewService.getEntity('releases', { query: { id: this.id } });

    if (result) {
      this.entity = result;
      console.log("Entity: ", this.entity);
      if (this.entity.name) {
        this.titleService.setTitle(this.entity.name);
      }
      this.prizes = this.entity.prizes_ref ? this.entity.prizes_ref[0] : null;
      this.challenges = this.entity.challenges_ref ? this.entity.challenges_ref : null;
      this.collections = this.entity.collection_ref ? this.entity.collection_ref.items_ref : null;
      this.fields = this.entity.releaseType_ref[0] ? this.entity.releaseType_ref[0].releaseFields : null
      this.rightFields = this.fields 
      ? this.fields.filter((field: any) => field.columnOption == 'rightColumn')
      : [];
    
    this.leftFields = this.fields 
      ? this.fields.filter((field: any) => field.columnOption == 'leftColumn')
      : [];    


      if (this.prizes && this.prizes.length > 0)  {
        this.prizes.forEach(async (element:any) => {
          if (element.lineItemType === "Item") {
            let item = await this.entityViewService.getEntity('items', { query: { id: element.id.id } });
            console.log(item)
            element.vendorStatus = item.vendorStatus;
            element.itemStatus = item.itemStatus;
            element.start = item.start;
            element.end = item.end;

            if (item.enabled){
              item.enabled = item.enabled;
            }
            if (
              item.imageLastHash &&
              item.image_versions &&
              item.image_versions.length > 0
            ) {
              item.isImageUpToDate = item.image_versions[
                item.image_versions.length - 1
              ].destinationPath.includes(item.imageLastHash)
                ? 'Up to date'
                : 'Outdated';
                this.loggerService.log('item.isImageUpToDate', item.isImageUpToDate);
                element.isImageUpToDate = item.isImageUpToDate
                this.loggerService.log('item.isImageUpToDate', element.isImageUpToDate);
            }
          }
        })
      }

      if(this.challenges && this.challenges.length > 0)
      {
        this.challenges.forEach((element: any) => 
        {
          element.isAssetUpToDate = element.isImageUpToDate = 'No data'
        if (
          element.assetLastHash &&
          element.asset_versions &&
          element.asset_versions.length > 0
        ) {
          element.isAssetUpToDate = element.asset_versions[
            element.asset_versions.length - 1
          ].destinationPath.includes(element.assetLastHash)
            ? 'Up to date'
            : 'Outdated';
        }
        })
      }

      if(this.collections && this.collections.length > 0)
      {
        this.collections.forEach((element: any) => 
        {
          element.isAssetUpToDate = element.isImageUpToDate = 'No data'
        if (
          element.assetLastHash &&
          element.asset_versions &&
          element.asset_versions.length > 0
        ) {
          element.isAssetUpToDate = element.asset_versions[
            element.asset_versions.length - 1
          ].destinationPath.includes(element.assetLastHash)
            ? 'Up to date'
            : 'Outdated';
        }
        })
      }

      this.cards.forEach((card) =>
      {
        card.fieldGroups.forEach((group: any) =>
        {
          group.forEach((field: any) =>
          {
            field.value = this.parseValueForView(
              this.entity[field.key],
              field
            );
          });
        });
      });
    }
    this.spinnerService.endSpinner();
    this.isLoading = false;
  }

  /**
   *
   * @param copyVal // text to copy into the user's clipboard
   */
  copyToClipboard(copyVal: string){
    this.clipboard.copy(copyVal);
    this.messageService.add({
      severity: 'success',
      summary: 'Thumbnail Copied',
      detail: `URL copied to clipboard successfully!`,
    });
  }

  /**
   * Parse a given value for view
   *
   * @param data View data.
   * @param field Field to be parsed.
   */
  parseValueForView(data: any, field: any) {
    if (!data && !['toggleButton'].includes(field.controlType)) return '';

    let output;
    switch (field.controlType) {
      case 'inputText':
      case 'inputTextarea':
      case 'dropdown':
      case 'links':
      case 'auto':
        output = data;
        break;
      case 'date':
      case 'calendar-start':
      case 'calendar-end':
        output = this.datePipe.transform(new Date(data), 'medium');
        break;
      case 'toggleButton':
        data == true ? (output = field.name) : (output = `Not ${field.name}`);
        break;
      case 'inputNumber-buttons':
        output = data.toString();
        break;
      case 'dropdown_ref':
      case 'autoComplete_ref':
      case 'auto_ref':
        if (field.key == 'progressionLevel_ref') {
          output = data.level;
        } else {
          console.log('field data', data);
          output = data.name;
        }
        break;

      case 'multiSelect':
        output = data.join(', ');
        break;
      case 'multiSelect_ref':
      case 'autoComplete-multi_ref':
        let values: any[] = [];
        data.forEach((o: any) => {
          values.push(o.name);
        });
        output = values.join(', ');
        break;
      case 'formArray':
        let groups: any[] = [];
        data.forEach((group: any) => {
          if (['costs_ref'].includes(field.key)) {
            groups.push(`${group['c']} ${group['id'].name}`);
          } else {
            let values: any[] = [];
            for (const [key, value] of Object.entries(group)) {
              if (['string', 'number', 'bigint'].includes(typeof value)) {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'auto',
                  })}`
                );
              } else if (['boolean'].includes(typeof value)) {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'toggleButton',
                  })}`
                );
              } else {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'dropdown_ref',
                  })}`
                );
              }
            }
            groups.push(values.join(', '));
          }
        });
        output = groups.join(`, `);
        break;

      default:
        return '';
    }

    return output;
  }

  /**
   * Chunks array
   */
  chunkArray(array: string | any[])
  {
    if (array && array.length > 1)
    {
      return _.chunk(array, array.length / 2 + 1);
    } else return _.chunk(array, array.length);
  }
}
