<ng-container *ngIf="!field.isHidden && !hasViewControl">
  <ng-container [ngSwitch]="field.controlType">
    <!-- inputText, inputNumber, dropdown -->
    <ng-container *ngSwitchCase="
        ['inputText', 'inputNumber', 'dropdown'].includes(field.controlType)
          ? field.controlType
          : ''
      ">
      <span *ngIf="
          ['table'].includes(page) &&
          ['name'].includes(field.key) &&
          entityType == 'items-sourcing'
        ">
        {{ value }}
      </span>
      <span *ngIf="
          ['table'].includes(page) &&
          ['name'].includes(field.key) &&
          entityType == 'challenges-sourcing'
        ">
        <ng-container *ngIf="field.key == 'name' && entity?.promoted && entity?.challenge_ref; else elseBlock">
          <a target="_blank" class="p-text-normal p-d-block" [ngStyle]="{ color: 'var(--primary-color)' }"
            [routerLink]="'/' + 'challenges'  + '/' + entity.challenge_ref">{{ value }}</a>
        </ng-container>
        <ng-template #elseBlock>
          {{ value }}
        </ng-template>
      </span>
      <span *ngIf="
          ['dimensionX','dimensionY','dimensionZ'].includes(field.key) &&
          entityType == 'items'
        ">
        <span class="p-mr-1">{{ value * 39.370 | number: '1.2-2' }} inches</span>
        <span>{{ value | number: '1.3-3' }} meters</span>
      </span>

      <a *ngIf="
          !tableView &&
          ['table'].includes(page) &&
          ['name', 'title'].includes(field.key) &&
          entityType != 'items-sourcing' &&
          entityType != 'challenges-sourcing'
        " target="_blank" class="p-text-normal p-d-block" [ngStyle]="{ color: 'var(--primary-color)' }"
        [routerLink]="entityViewLink">{{ value }}</a>
      <a *ngIf="
          ['table'].includes(page) &&
          ['name', 'title'].includes(field.key) &&
          tableView
        " a target="_blank" class="p-text-normal p-d-block" [ngStyle]="{ color: 'var(--primary-color)' }"
        [routerLink]="'/' + entity.apiControllerRoute  + '/' + entity.id">{{ value }}</a>
      <p
        *ngIf="['sourcingItem_ref', 'sourcingChallenge_ref'].includes(field.key) && entityType != 'items-sourcing' && ['table'].includes(page)">
        ID: {{ value }}</p>
      <ng-container *ngIf="
          !['table'].includes(page) && !['dimensionX','dimensionY','dimensionZ'].includes(field.key) ||
          (['table'].includes(page) && !['name', 'title', 'sourcingItem_ref', 'sourcingChallenge_ref', 'dimensionX','dimensionY','dimensionZ'].includes(field.key))
        ">
        <span *ngIf=" field.controlType == 'dropdown' && field.options; else defaultNotDropdown">
          {{ getLabel(value) }}
        </span>
        <ng-template #defaultNotDropdown>
          {{ value }}
        </ng-template>
      </ng-container>
      <ng-container *ngIf="
          (['table'].includes(page) && ['entityType'].includes(field.key))
        ">
        <span *ngFor="let v of options">
          <span *ngIf="value == v.value">
            {{ v.label }}
          </span>
        </span>
      </ng-container>
      <ng-container *ngIf="
          (['table'].includes(page) && ['assetType'].includes(field.key))
        ">
        <span *ngFor="let v of options">
          <span *ngIf="value == v.value">
            {{ v.label }}
          </span>
        </span>
      </ng-container>
    </ng-container>
    <!-- userName -->
    <na-container *ngSwitchCase="'userName'">
      {{ value ? value.name : null }}
    </na-container>
    <!-- userName -->
    <na-container *ngSwitchCase="'buildReferenceId'">
      <a target="_blank" class="p-text-normal p-d-block" [ngStyle]="{ color: 'var(--primary-color)' }"
        [routerLink]="'/miscellaneous-build' + '/' + value.id">{{ value ? value.id : null }}</a>

    </na-container>
    <!-- MiscImageRecordV2 -->
    <ng-container *ngSwitchCase="'MiscImageRecordV2'">
      <span class="p-my-2">
        {{entity[field.key] ? entity[field.key].name : ''}}
        <a href="/miscellaneous-build/{{entity[field.key] ?  entity[field.key].id : ''}}" target="_blank"
          [ngStyle]="{ color: 'var(--primary-color)' }">
          (Asset ID: {{entity[field.key] ? entity[field.key].id : ''}})
        </a>
      </span>
      <!-- <div class="p-grid p-ml-2 p-mt-2" style="overflow-wrap: anywhere;">
        <strong>{{field.label}} Name:</strong>
        <strong>{{field.label}} Path:</strong>  {{entity[field.key] ? entity[field.key].path : ''}}
      </div> -->
    </ng-container>

    <!-- rewardTracks for Daily Login Rewards -->
    <na-container *ngSwitchCase="'dailyRewardType'">
      {{ value == 1 ? '7 Day Login Rewards' : '30 Day Login Rewards' }}
    </na-container>

    <!-- version -->
    <ng-container *ngSwitchCase="'version'">
      {{
      value && value.length > 0 ? value.slice(-1)[0].destinationPath : null
      }}
    </ng-container>

    <!-- inputTextarea -->
    <ng-container *ngSwitchCase="'inputTextarea'">
      <span [class]="['table'].includes(page) ? 'long-text' : ''">
        {{ value }}
      </span>
    </ng-container>

    <!--  richTextArea -->
    <ng-container *ngSwitchCase="'richTextArea'">
      <span *ngIf="entity[field.key]" [class]="['table'].includes(page) ? 'long-text' : ''"
        [innerHTML]="(entity[field.key]) | safeHtml"></span>
    </ng-container>

    <!-- dropdown_ref, autoComplete_ref -->
    <ng-container *ngSwitchCase="['dropdown_ref', 'autoComplete_ref'].includes(field.controlType)
                  ? field.controlType
                  : ''
      ">
      <!-- Item Sourcing Table - Dropdowns and Auto Complete -->
      <span *ngIf="entityType == 'items-sourcing'">
        <span *ngIf="
                  (value && field.key != 'category_ref') ||
                  (value &&
                    field.key == 'category_ref' &&
                    !coloredCategories.includes(value.name))
                ">
          {{ value.name }}
        </span>

        <p-chip *ngIf="
                  value &&
                  field.key == 'category_ref' &&
                  entityType == 'items-sourcing' &&
                  coloredCategories.includes(value.name)
                " [label]="value.name" [style]="{
                  'background-color': value.color ? value.color : '#81c784'
                }"></p-chip>

        <span *ngIf="
                ['table'].includes(page) &&
                ['item_ref'].includes(field.key)
              ">
          <a target="_blank" class="p-text-normal p-d-block" [ngStyle]="{ color: 'var(--primary-color)' }"
            [routerLink]="'/items'  + '/' + entity.item_ref">{{value}}</a>
        </span>
      </span>
      <span *ngIf="entityType == 'challenges-sourcing'">
        <span *ngIf="value" [ngSwitch]="field.key">
          <a *ngSwitchCase="'challenge_ref'" target="_blank" class="p-text-normal p-d-block"
            [ngStyle]="{ color: 'var(--primary-color)' }"
            [routerLink]="'/challenges'  + '/' + entity.challenge_ref">{{value}}</a>
          <a *ngSwitchDefault target="_blank" class="p-text-normal p-d-block"
            [ngStyle]="{ color: 'var(--primary-color)' }"
            [routerLink]="'/' + (field.urlLink || field.apiController) + '/' + value.id">{{value.name}}
            ({{value.id}})</a>
        </span>
      </span>

      <!-- Ordinary Tables - Dropdowns and Auto Complete -->
      <span>
        <span
          *ngIf="value && entityType !== 'items-sourcing' && entityType !== 'challenges-sourcing' && field.key !== 'series_ref' && field.key !== 'sourcingGroup_ref' && field.key !== 'sourcingChallengeGroup_ref' ">
          <!-- {{ value.name }} -->
          <a href="{{ parseLinkPath(value) }}" target="_blank" class="p-text-normal"
            [ngStyle]="{ color: 'var(--primary-color)' }">
            {{ value.name }} ({{value.id}})
          </a><br />
        </span>
      </span>
      <span *ngIf="['series_ref'].includes(field.key) && entityType != 'items-sourcing' && ['table'].includes(page)">
        <span *ngFor="let v of value">
          <a target="_blank" class="p-text-normal p-d-block" [ngStyle]="{ color: 'var(--primary-color)' }"
            [routerLink]="'/releases' + '/' + v.id">{{v.name}}({{ v.id }})</a>
        </span>
      </span>
      <span
        *ngIf="['sourcingGroup_ref'].includes(field.key) && entityType != 'items-sourcing' && ['table'].includes(page) && value">
        <!-- {{value.id}} -->
        <a target="_blank" class="p-text-normal p-d-block" [ngStyle]="{ color: 'var(--primary-color)' }"
          [routerLink]="'/sourcing-groups' + '/' + value.id">{{value.name}}({{ value.id }})</a>
      </span>
      <span
        *ngIf="['sourcingChallengeGroup_ref'].includes(field.key) && entityType != 'items-sourcing' && ['table'].includes(page) && value">
        <a target="_blank" class="p-text-normal p-d-block" [ngStyle]="{ color: 'var(--primary-color)' }"
          [routerLink]="'/sourcing-challenge-groups' + '/' + value.id">{{value.name}}({{ value.id }})</a>
      </span>
    </ng-container>

    <!-- multiSelect -->
    <ng-container *ngSwitchCase="'multiSelect'">
      <span *ngIf="this.reorderedEnvValues && field.key == 'env'">
        <span *ngFor="let v of this.reorderedEnvValues"> {{ v }} <br /></span>
      </span>
      <span *ngIf="value && field.key != 'env'">
        <span *ngFor="let v of value"> {{ v }} <br /></span>
      </span>
    </ng-container>

    <!-- multiSelect_ref, autoComplete-multi_ref -->
    <ng-container *ngSwitchCase="
        ['multiSelect_ref', 'autoComplete-multi_ref'].includes(
          field.controlType
        )
          ? field.controlType
          : ''
      ">
      <span *ngIf="value && field.key == 'collection_ref'">
        <span *ngFor="let v of value">
          <a target="_blank" class="p-text-normal" [ngStyle]="{ color: 'var(--primary-color)' }"
            [routerLink]="'/' + (field.urlLink || field.apiController) + '/' + v.id">
            {{getCollectionLabel(v)}} ({{v.id}})
          </a><br />
        </span>
      </span>
      <span *ngIf="value && field.key == 'achievements_ref'">
        <span *ngFor="let v of value"> {{ v.title }} <br /></span>
      </span>
      <span *ngIf="value && field.key == 'keywords_ref'">
        <span *ngFor="let v of value"> {{ v.name }} <br /></span>
      </span>

      <!-- Item Sourcing Table -->
      <span *ngIf="value && entityType == 'items-sourcing'">
        <span *ngIf="field.key == 'climates_ref'">
          <p-chip *ngFor="let v of value" [label]="v.name" [style]="{
              'background-color': v.name ? coloredClimates[v.name] : '#FFF'
            }"></p-chip>
        </span>
        <span *ngIf="field.key != 'climates_ref'">
          <span *ngFor="let v of value"> {{ v.name }} <br /></span>
        </span>
      </span>

      <!-- Handing Items Ref field` -->
      <span *ngIf="
                value &&
                entityType !== 'items-sourcing' &&
                field.key != 'achievements_ref' &&
                field.key == 'items_ref' &&
                field.key == 'keywords_ref'

              ">
        <div class="parent">
          <ul *ngFor="let v of value" class="flex-2-cols">
            <li>
              <a href="{{ parseLinkPath(v) }}" target="_blank" class="p-text-normal"
                [ngStyle]="{ color: 'var(--primary-color)' }">
                {{ v.name }} ({{ v.id }})
              </a>
            </li>
          </ul>
        </div>
      </span>

      <!-- Handing Related Items fields` -->
      <span *ngIf="
                      value &&
                      entityType !== 'items-sourcing' &&
                      field.key != 'achievements_ref' &&
                      field.key == 'releatedItems'
                    ">
        <span *ngFor="let v of value">
          <a href="{{ parseLinkPath(v) }}" target="_blank" class="p-text-normal"
            [ngStyle]="{ color: 'var(--primary-color)' }">
            {{ v.name }} ({{v.id}})
          </a><br />
        </span>
      </span>

      <!-- Category Collection Name Link -->
      <span *ngIf="
        value &&
        entityType =='categories' &&
        field.key =='category_collections_ref'
      ">
        <span *ngFor="let v of value">
          <a href="{{ parseLinkPath(v) }}" target="_blank" class="p-text-normal"
            [ngStyle]="{ color: 'var(--primary-color)' }">
            {{ v.name }} ({{v.id}})
          </a>
          <br /> </span>
      </span>

      <!-- Ordinary Tables -->
      <span *ngIf="
          value &&
          entityType !== 'items-sourcing' &&
          field.key != 'achievements_ref' &&
          field.key !== 'items_ref' &&
          field.key !=='category_collections_ref' &&
          field.key !== 'keywords_ref' &&
          field.key !=='releatedItems' &&
          field.key !== 'collection_ref'
        ">
        <span *ngFor="let v of value">
          <a href="{{ parseLinkPath(v) }}" target="_blank" class="p-text-normal"
            [ngStyle]="{ color: 'var(--primary-color)' }">
            {{ v.name }} ({{v.id}})
          </a> <br />
          <!-- {{ v.name }}<br /> -->
        </span>
      </span>

    </ng-container>

    <!-- date -->
    <ng-container *ngSwitchCase="'date'">
      {{ value | officeTime }}
    </ng-container>
    <!-- nurture info -->
    <ng-container *ngSwitchCase="'nurtureInfo'">
      <ul *ngIf="field.key == 'stageCosts'">
        <li *ngFor="let v of value; let i = index">
          <strong>Stage {{i + 1}}: </strong> <br /> {{v.currency}}: {{v.amount}}
        </li>
      </ul>
      <ul *ngIf="field.key == 'stageNames'">
        <li *ngFor="let v of value; let i = index">
          <strong>Stage {{i + 1}}: </strong> <br /> {{v}}
        </li>
      </ul>
      <ul *ngIf="field.key == 'stageImagery'">
        <li *ngFor="let v of value; let i = index">
          <strong>Stage {{i + 1}}: </strong>
          <br /> <strong>Transition:</strong> {{v.transition}}
          <br /> <strong>Stage:</strong> {{v.stage}}
          <br /> <strong>Banner:</strong> {{v.banner}}
        </li>
      </ul>
    </ng-container>

    <!-- calendar start -->
    <ng-container *ngSwitchCase="'calendar-start'">
      {{ value | officeTime }}
      <div style="margin-bottom: 10px; text-align: right;" *ngIf="!checkTimezone">
        <h5 class="p-d-inline">Local Time: </h5><small>{{ value | date: 'medium' }}</small>
      </div>
    </ng-container>
    <!--  end -->
    <ng-container *ngSwitchCase="'calendar-end'">
      {{ value | officeTime }}
      <div style="margin-bottom: 10px; text-align: right;" *ngIf="!checkTimezone">
        <h5 class="p-d-inline">Local Time: </h5><small>{{ value | date: 'medium' }}</small>
      </div>
    </ng-container>

    <!-- toggle -->
    <ng-container *ngSwitchCase="'toggle'">
      {{ value == null ? null : (value | yesNo) }}
    </ng-container>

    <!-- spawner count -->
    <ng-container *ngSwitchCase="'spawner_count'">
      <div class="block  font-bold text-center p-4  mb-3">
        Required:{{value.required > 0 ? value.required : 0 }}
      </div>
      <div class="block  font-bold text-center p-4  mb-3">
        Optional: {{value.optional > 0 ? value.optional : 0 }}
      </div>
      <div class="block  font-bold text-center p-4  mb-3">
        Total: {{value.required != 0 && value.optional != 0 ? value.required : 0 }}
      </div>
    </ng-container>

    <!-- buildStatus -->
    <ng-container *ngSwitchCase="'buildStatus'">
      <span class="p-d-flex" *ngIf="value">
        <strong [ngStyle]="{ color: value.color }">{{ value.text }}</strong>

        <div *ngIf="value.buildCount > 0" class="p-ml-2" style="
            height: 21.25px;
            width: 21.25px;
            background-color: var(--text-color);
            border-radius: 50%;
            font-weight: 700;
            line-height: 21.25px;
            font-size: 12px;
            color: var(--primary-color-text);
            text-align: center;
          ">
          <strong class="p-m-auto">
            {{ value.buildCount }}
          </strong>
        </div>
      </span>

      <ng-container *ngIf="value && value.buildCount > 0">
        {{ value.date }}
      </ng-container>
    </ng-container>

    <!-- formArray -->
    <ng-container *ngSwitchCase="'formArray'">
      <div *ngFor="let v of value">
        {{ parseFormArrayValue(value) }}
        <!-- {{ value }} -->
      </div>
    </ng-container>

    <!-- Rules  -->
    <!-- Switch Case for Rewards (Line Item) -->
    <ng-container *ngSwitchCase="'rules_ref'">
      <div class="">
        <div *ngFor="let v of value" [ngStyle]="{ minWidth: '200px' }" class="p-d-flex p-flex-column">
          <div class="p-ai-center p-mb-2 cost-container">
            {{ v && v.filter_ref ? v.filter_ref.name : null }}
          </div>
        </div>
      </div>
    </ng-container>

    <!-- nestedGroup -->
    <ng-container *ngSwitchCase="'nestedGroup'">
      <span *ngFor="let v of value; let index = index">
        <div *ngFor="let subField of field.subFields">
          <strong>{{ subField.name }}:</strong>
          <app-data-field [field]="subField" [value]="v[subField.key]" [page]="page"
            [entityViewLink]="entityViewLink"></app-data-field>
        </div>
        <p-divider *ngIf="value.length > index + 1"></p-divider>
      </span>
    </ng-container>

    <!-- nestedGroup -->
    <ng-container *ngSwitchCase="'loadingScreenImages'">
      <span *ngFor="let v of value; let index = index">
        <div class="p-grid">
          <div class="p-col-9">
            <p><strong>Name: </strong>{{v.name}}</p>
            <p><strong>Path: </strong>{{v.path}}</p>
            <a href="/miscellaneous-build/{{v.id}}" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }">Asset
              Record: ({{v.id}})</a>
          </div>
          <div class="p-col-3">
            <img *ngIf="v.thumbnails && v.thumbnails.length > 0" src="{{v.thumbnails[2].path}}" style="
                border-radius: 10px;
                width: 100%;
                vertical-align: middle;" alt="">
          </div>
        </div>
        <p-divider *ngIf="value.length > index + 1"></p-divider>
      </span>
    </ng-container>

    <!-- lineItem -->
    <ng-container *ngSwitchCase="'lineItem'">
      <div *ngFor="let v of value; let index = index" [ngStyle]="{ minWidth: '200px' }" class="p-d-flex p-flex-column">
        <div class="p-ai-center cost-container {{
            value.length > index + 1 ? 'p-pb-1' : ''
          }}">
          <!-- hide image if no v.id.image_ref"-->
          <img *ngIf="v.lineItemType != 'Item' && v.id.image_ref" src="{{v.id.image_ref.thumbnail_url_32 }}" style="
                height: 42px;
                width: 42px;
                vertical-align: middle;" alt="d">

          <span class="p-ml-2" style="
              background-color: var(--text-color);
              border-radius: 14%;
              padding: 1px 5px;
              font-weight: 700;
              line-height: 21.25px;
              font-size: 12px;
              color: var(--primary-color-text);
              text-align: center;
              margin-right: 6px;
              vertical-align: middle;
            ">
            <strong class="p-m-auto">
              {{ v.c | number }}
            </strong>
          </span>
          <div *ngIf="v.lineItemType == 'Item'; else nonItem">
            <a pButton aria-label="{{ v.id.name }}" [label]="v.id.name+' ('+v.id.id+')'" target="_blank" type="button"
              class="p-button-link p-text-left" routerLink="/items/{{ v.id.id }}"></a>
          </div>
          <ng-template #nonItem>
            {{ v && v.id && v.id.name ? v.id.name : null }}
          </ng-template>
        </div>
      </div>
    </ng-container>

    <!-- image -->
    <span *ngSwitchCase="'image'">
      <div class="thumb-container" *ngIf="field.key != 'feed_image_url_128'">
        <img *ngIf="value" [lazyLoad]="value+'?ts=' + timestamp" class="p-mx-auto" width="120px" height="120px"
          (click)="displayImageModal()" (error)="setDefaultPic()" />

        <p-skeleton *ngIf="!value" class="p-mx-auto" shape="square" width="120px" height="120px"></p-skeleton>
      </div>
      <div class="feed-image-container" *ngIf="field.key == 'feed_image_url_128'">
        <img *ngIf="value" [lazyLoad]="value+'?ts=' + timestamp" class="p-mx-auto" width="170px" height="120px"
          (click)="displayImageModal()" (error)="setDefaultPic()" />

        <p-skeleton *ngIf="!value" class="p-mx-auto" shape="square" width="170px" height="120px"></p-skeleton>
      </div>
    </span>

    <!-- Ref Links -->
    <ng-container *ngSwitchCase="'ref-link'">
      <div *ngFor="let val of value; let index = index" class="{{ value.length > index + 1 ? 'p-pb-1' : '' }}">
        <a href="{{ parseLinkPath(val) }}" target="_blank" class="p-text-normal p-ai-center" style="color: #81c784">
          {{ parseLinkLabel(val) }}
        </a>
      </div>
    </ng-container>

    <!-- Simple Links -->
    <ng-container *ngSwitchCase="'simple-link'">
      <a *ngIf="field.key != 'parent_challenge'" href="//{{ value ? value : '' }}" target="_blank"
        class="p-text-normal p-d-block" style="color: #81c784">
        {{ value != undefined ? "LINK" : "" }}
      </a>
      <a *ngIf="field.key == 'parent_challenge'" href="/challenges/{{ value ? value : '' }}" target="_blank"
        class="p-text-normal p-d-block" style="color: #81c784">
        {{ value != undefined ? "Challenge ID: " + value : "" }}
      </a>
    </ng-container>

    <!-- collection_ref -->
    <ng-container *ngSwitchCase="'collection_ref'">
      <ul style="list-style-type: none">
        <li *ngFor="let collection of entity.collection_ref">
          <a *ngIf="page == 'table' && collection != undefined" pButton [label]="getCollectionLabel(collection)"
            type="button" class="p-button-link p-text-left" [href]="
              collection && collection.id
                ? 'collections/' + collection.id.toString()
                : null
            " target="_blank"></a>
          <ng-container *ngIf="
              page != 'table' ||
              (page == 'table' && field.key != ['collection_ref'])
            ">
            {{ value }}
          </ng-container>
        </li>
      </ul>
    </ng-container>

    <!-- relatedItems -->
    <ng-container *ngSwitchCase="'relatedItems'">
      <ul style="list-style-type: none">
        <li *ngFor="let item of entity.releatedItems">
          <a pButton [label]="item && item.nameId.length > 0 ? item.nameId : null" type="button"
            class="p-button-link p-text-left" [href]="'items/' + item.id.toString()" target="_blank"></a>
        </li>
      </ul>
    </ng-container>

    <!-- Fields not Ready Yet -->
    <span *ngSwitchCase="
        ['referenceLinks', 'vendorDimensions'].includes(field.key)
          ? field.key
          : ''
      ">
      <p-cellEditor>
        <ng-template pTemplate="input">
          <img src="https://media.giphy.com/media/HoaHfvovCLSvu/giphy.gif" width="100 px" height="100 px"
            title="Not Ready Yet, Sorry ...." />
        </ng-template>
        <ng-template pTemplate="output">
          {{ value }}
        </ng-template>
      </p-cellEditor>
    </span>

    <span *ngSwitchCase="'multi_prizes_ref'">
      <div *ngFor="let items of value;" [ngStyle]="{ minWidth: '200px' }" class="p-d-flex p-mb-2">
        <div *ngFor="let item of items; let index = index" [ngStyle]="{ minWidth: '200px' }"
          class="p-d-flex p-flex-column">
          <div class="p-ai-center cost-container {{
            items.length > index + 1 ? 'p-pb-1' : ''
          }}" *ngIf="item">
            <span class="p-ml-2" style="
              background-color: var(--text-color);
              border-radius: 14%;
              padding: 1px 5px;
              font-weight: 700;
              line-height: 21.25px;
              font-size: 12px;
              color: var(--primary-color-text);
              text-align: center;
              margin-right: 6px;
            ">
              <strong class="p-m-auto">
                {{ item.c | number }}
              </strong>
            </span>
            <div *ngIf="item.lineItemType == 'Item'; else nonItem">
              <a pButton aria-label="{{ item.id ? item.id.name: null }}"
                [label]="item.id ? item.id.name+' ('+item.id.id+')' : null" target="_blank" type="button"
                class="p-button-link p-text-left" routerLink="/items/{{ item.id ? item.id.id : null }}"></a>
            </div>
            <ng-template #nonItem>
              {{ item && item.id && item.id.name ? item.id.name : null }}
            </ng-template>
          </div>
        </div>
      </div>
    </span>

    <span *ngSwitchCase="'nameIdLink'">
      <span *ngIf="value">
        <a href="{{ parseLinkPath(value) }}" target="_blank" class="p-text-normal"
          [ngStyle]="{ color: 'var(--primary-color)' }">
          {{ value.name }} ({{value.id}})
        </a><br />
      </span>
    </span>

    <span *ngSwitchDefault>
      {{ value }}
    </span>
  </ng-container>
</ng-container>

<!-- fields with viewControl -->
<ng-container *ngIf="value && !field.isHidden && hasViewControl">
  <ng-container [ngSwitch]="field.viewControl.type">
    <!-- multiProp -->
    <ng-container *ngSwitchCase="'multiProp'">
      <ng-container *ngIf="field.viewControl.detailed && field.viewControl.detailed.includes(page); else singleLine">
        <div *ngFor="let prop of field.viewControl.props" class="p-my-2">
          <ng-container *ngIf="!prop.isSibling && value[prop.key]; else notProp">
            <strong>{{prop.label}}: </strong>
            <ng-container *ngIf="prop.isEntityLink && field.apiController; else notLink">
              <a target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }"
                [href]="field.apiController + '/' + value.id">{{value[prop.key]}}
                <ng-container *ngIf="prop.caption">
                  ({{value[prop.caption]}})
                </ng-container>
              </a>
            </ng-container>
            <ng-template #notLink>
              {{value[prop.key]}}
              <ng-container *ngIf="prop.caption"> ({{value[prop.caption]}})</ng-container>
            </ng-template>
          </ng-container>

          <ng-template #notProp>
            <ng-container *ngIf="prop.isSibling && entity[prop.key]">
              <div class="p-d-flex p-ai-center">
                <strong>{{ prop.label }}: </strong>
                <i class="pi pi-info-circle p-mx-1" style="color:var(--primary-color)"
                  pTooltip="Value is populated from other property of {{entity.name}} ({{entity.id}}), '{{ prop.key }}'."
                  tooltipPosition="left"></i>
                {{ entity[prop.key] }}
              </div>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>

      <ng-template #singleLine>
        <ng-container *ngIf="field.viewControl.props[0].isEntityLink && field.apiController; else notLink">
          <a target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }"
            [href]="field.apiController + '/' + value.id">
            {{ value[field.viewControl.props[0].key]}}
            <ng-container *ngIf="field.viewControl.props[0].caption; else noCaption">
              ({{ value[field.viewControl.props[0].caption] }})
            </ng-container>

            <ng-template #noCaption>
              <ng-container *ngIf="value.id">
                ({{value.id}})
              </ng-container>
            </ng-template>
          </a>
        </ng-container>

        <ng-template #notLink>
          {{ value[field.viewControl.props[0].key] }}

          <ng-container *ngIf="field.viewControl.props[0].caption; else noCaption">
            ({{ value[field.viewControl.props[0].caption] }})
          </ng-container>

          <ng-container #noCaption *ngIf="value.id">
            ({{ value.id }})
          </ng-container>
        </ng-template>
      </ng-template>
    </ng-container>

    <!-- color -->
    <ng-container *ngSwitchCase="'color'">
      <div class="p-d-flex p-ai-center">
        <div class="color-preview-container p-mr-2">
          <div class="pi pi-star" [ngStyle]="{'font-size': '1.3em', color: '#000'}">
            <div [ngStyle]="{ 'background-color': value }" class="color-preview">
            </div>
          </div>
        </div>

        {{ value }}
      </div>
    </ng-container>

    <!-- images -->
    <ng-container *ngSwitchCase="'images'">
      <div class="thumb-container">
        <ng-container *ngIf="isValueArray">
          <img [src]="imagesDisplaySrc()" class="p-mx-auto" width="120px" height="120px" (click)="displayImageModal()"
            (error)="setDefaultPic()" />
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<!-- 'View Image' dialog -->
<p-dialog [(visible)]="showImgDialog" [dismissableMask]="true" [modal]="true" [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <div class="header-content">
      <h3>{{ imgData.title }}</h3>
    </div>
  </ng-template>

  <div class="p-text-center">
    <img *ngIf="imgData && imgData.path" [src]="imgData.path+'?ts=' + timestamp" width="90%" />
  </div>
</p-dialog>