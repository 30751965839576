import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Seasonal Prizes DTO
 */
export default class SeasonalPrizesDto extends BaseEntityDto {
    /**
     * Internal Name
     */
    internalName: string;

    /**
     * Popup Banner Reference
     */
    popupBanner_ref: any;

    /**
     * Popup Icon Reference
     */
    popupIcon_ref: any;

    /**
     * Slide Down Image Reference
     */
    slideDownImage_ref: any;

    /**
     * Bundle 1 Reference
     */
    bundle1_ref: any;

    /**
     * Bundle 2 Reference
     */
    bundle2_ref: any;

    /**
     * Bundle 3 Reference
     */
    bundle3_ref: any;

    /**
     * Rewards Track Reference
     */
    rewardsTrack_ref: any;

    /**
     * Array of Inbox Messages
     */
    inboxes_ref: Array<any> = [];

    /**
     * Array of Collections
     */
    collections_ref: Array<any> = [];
}
