import { Component, Input, OnInit } from '@angular/core';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { DynamicViewType } from '../../enums/DynamicViewType';

@Component({
  selector: 'app-misc-image-record-v2',
  templateUrl: './misc-image-record-v2.component.html',
  styleUrls: ['./misc-image-record-v2.component.sass']
})
export class MiscImageRecordV2Component implements OnInit {
  @Input() record: any;
  @Input() field: any;
  @Input() viewMode: 'record' | 'array';
  @Input() type: DynamicViewType;

  DynamicViewType = DynamicViewType;

  getAssetType(type: number): string {
    return AssetTypes[type] || 'Unknown';
  }

  isArray(): boolean {
    return this.viewMode === 'array';
  }
  
  isRecord(): boolean {
    return this.viewMode === 'record';
  }

  ngOnInit(): void {
    if (this.viewMode === 'array' && !Array.isArray(this.record[this.field.key])) {
      console.error(`Expected an array for the key ${this.field.key}`);
    }
  }
}

