<div class="p-grid nested-grid p-fluid p-justify-center p-mt-2 p-mb-2" *ngIf="!isLoading">
    <ng-container *ngIf="chartData.length > 0">
        <h3 class="p-col-12 p-mb-0">
          Build Times History in seconds
        </h3>
        <div style="width: 100%;">
          <ngx-charts-line-chart
          legendPosition="below"
          [curve]="chartCruve"
          [autoScale]="true"
          [scheme]="colorScheme"
          [legend]="legend"
          [showXAxisLabel]="showXAxisLabel"
          [showYAxisLabel]="showYAxisLabel"
          [xAxis]="xAxis"
          [yAxis]="yAxis"
          [xAxisLabel]="xAxisLabel"
          [yAxisLabel]="yAxisLabel"
          [timeline]="timeline"
          [results]="chartData"
          style="fill: #81C784"
        >
        </ngx-charts-line-chart>
        </div>

    </ng-container>
</div>
