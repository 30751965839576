import { Component, OnInit } from '@angular/core';
import { TableDataComponent } from '../../../table-data/components/table-data/table-data.component';
import { BuildType } from 'src/app/enums/build-type';


@Component({
  selector: 'app-nurture-card-table',
  templateUrl: './nurture-card-table.component.html',
  styleUrls: ['./nurture-card-table.component.sass']
})
export class NurtureCardTableComponent extends TableDataComponent implements OnInit {

  constructor() {
    super();
  }

  ngOnInit() {
    this.isLoading = true;
    this.title = 'Nurture Cards';
    this.type = 'nurture-card';

    this.globalFilters = ['id', 'name'];
    this.filtersToRegister = [...this.defaultFilters, 'multiIn_ref', 'stringIn_ref', 'filterControl_test'];

    this.fields = [
      {
        key: 'id',
        name: 'ID',
        controlType: 'inputText',
        filterKey: 'id',
        matchMode: 'equals',
        isColumn: true,
        isInputField: false,
        isFilterField: true,
      },
      {
        key: 'name',
        name: 'Nurture Card Name',
        controlType: 'inputText',
        filterKey: 'name',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'start',
        name: 'Start',
        controlType: 'date',
        filterKey: 'start',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'end',
        name: 'End',
        controlType: 'date',
        filterKey: 'end',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'enabled',
        name: 'Enabled',
        controlType: 'toggle',
        filterKey: 'enabled',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'image_ref',
        name: 'Image',
        controlType: 'dropdown_ref',
        apiController: 'miscellaneous-build',
        customQuery: { entityType: BuildType.Nurture },
        selectProps: 'name id _id path',
        viewControl: {
          type: 'multiProp',
          props: [
            { label: 'Name', key: 'name', caption: 'id', isEntityLink: true},
            { label: 'Path', key: 'path' },
          ],
          sortBy: 'name',
          detailed: ['table']
        },
        filterControl: {
          type: 'selectObjects',
          keys: ['name', 'path', 'id'],
          matchMode: 'filterControl_test',
        },
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'video_ref',
        name: 'Video',
        controlType: 'dropdown_ref',
        apiController: 'miscellaneous-build',
        customQuery: { entityType: BuildType.Nurture },
        selectProps: 'name id _id path',
        viewControl: {
          type: 'multiProp',
          props: [
            { label: 'Name', key: 'name', caption: 'id', isEntityLink: true},
            { label: 'Path', key: 'path' },
          ],
          sortBy: 'name',
          detailed: ['table']
        },
        filterControl: {
          type: 'selectObjects',
          keys: ['name', 'path', 'id'],
          matchMode: 'filterControl_test',
        },
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
    ];

    this.defaultColumnOrder = [
      'id',
      'name',
      'start',
      'end',
      'enabled',
      'image_ref',
      'video_ref',
    ];

    this.customGlobalColumnSets = [
      {
        name: 'Default',
        value: ['id', 'name', 'start', 'end', 'enabled'],
      },
    ];

    this.tableConfig.customFilterToRegister = this.filtersToRegister;
    this.isLoading = false;
  }

}
