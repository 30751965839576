import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rewards-track-view',
  templateUrl: './rewards-track-view.component.html',
  styleUrls: ['./rewards-track-view.component.css'],
})
export class RewardsTrackViewComponent implements OnInit {
  entity: any;
  type = 'rewards-track';
  isLoading: boolean;
  id: any;
  dailyRewards: any = [];
  day7Rewards: Array<any> = [];
  parsedSeasonPass: any = [];
  rewardsTrackType: any = {
    1: 'Login Rewards Day 1-7',
    2: 'Login Rewards Day 30',
    3: 'Free Garden Pass',
    4: 'Paid Garden Pass',
    5: 'Friends Invited Track',
    6: 'Endless Offer',
    7: 'Co-Op Rewards Track',
    8: 'Mini Games',
    9: 'Seasonal Prizes',
  };

  constructor(
    private entityViewService: EntityViewService,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private datePipe: DatePipe,
    private titleService: Title
  ) {}

  async ngOnInit() {
    this.isLoading = true;
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));

    await this.entityViewService
      .getEntity(this.type, { query: { id: this.id }, autopopulate: true })
      .then((result: any) => {
        if (result) {
          this.entity = result;
          if (this.entity.name) {
            this.titleService.setTitle(this.entity.name);
          }
        }
      });

    switch (this.entity.type) {
      case 1:
      case 2:
        await this.loadDailyRewards(this.entity.type);
        break;
      case 3:
      case 4:
      case 5:
      case 7:
      case 8:
      case 9:
        await this.parseSeasonPass(this.entity.type);
        break;
      default:
        break;
    }

    console.log(this.parsedSeasonPass);
    this.isLoading = false;
  }

  async loadDailyRewards(type: number) {
    this.dailyRewards = [];

    for (let key of Object.keys(this.entity)) {
      if (key.includes('dailyRewardDay') && key !== 'dailyRewardDay30') {
        let day = parseInt(key.replace('dailyRewardDay', ''), 10);
        if (day !== 7) {
          let dayIndex = this.dailyRewards.findIndex((d: { day: number }) => d.day === day);
          if (dayIndex === -1) {
            this.dailyRewards.push({ day: day, rewards: [] });
            dayIndex = this.dailyRewards.length - 1;
          }

          this.entity[key].forEach((reward: any) => {
            if (reward) {
              this.dailyRewards[dayIndex].rewards.push({ ...reward });
            }
          });
        } else {
          this.day7Rewards = this.entity[key];
        }
      }
    }
  }

  async parseSeasonPass(type: number) {
    console.log('parseSeasonPass: ', type, this.entity);

    switch (type) {
      case 3:
        if (this.entity.free && this.entity.free.length > 0) {
          for (let i = 0; i < this.entity.free.length; i++) {
            let stageArray = [];
            if (this.entity.free[i]) {
              stageArray.push(this.entity.stageMilestone[i]);
              stageArray.push(this.entity.free[i]);
              this.parsedSeasonPass.push(stageArray);
            }
          }
        }
        break;
      case 4:
        if (this.entity.paid && this.entity.paid.length > 0) {
          for (let i = 0; i < this.entity.paid.length; i++) {
            let stageArray = [];
            if (this.entity.paid[i]) {
              stageArray.push(this.entity.stageMilestone[i]);
              stageArray.push(this.entity.paid[i]);
              this.parsedSeasonPass.push(stageArray);
            }
          }
        }
        break;
      case 5:
        if (this.entity.friends && this.entity.friends.length > 0) {
          for (let i = 0; i < this.entity.friends.length; i++) {
            let stageArray = [];
            if (this.entity.friends[i]) {
              stageArray.push(this.entity.stageMilestone[i]);
              stageArray.push(this.entity.friends[i]);
              this.parsedSeasonPass.push(stageArray);
            }
          }
        }
        break;
      case 7:
        if (this.entity.coop && this.entity.coop.length > 0) {
          for (let i = 0; i < this.entity.coop.length; i++) {
            let stageArray = [];
            if (this.entity.coop[i]) {
              stageArray.push(this.entity.stageMilestone[i]);
              stageArray.push(this.entity.coop[i]);
              this.parsedSeasonPass.push(stageArray);
            }
          }
        }
        break;
      case 8:
        if (this.entity.miniGamesRewards && this.entity.miniGamesRewards.length > 0) {
          for (let i = 0; i < this.entity.miniGamesRewards.length; i++) {
            let stageArray = [];
            if (this.entity.miniGamesRewards[i]) {
              stageArray.push(this.entity.stageMilestone[i]);
              stageArray.push(this.entity.miniGamesRewards[i]);
              this.parsedSeasonPass.push(stageArray);
            }
          }
        }
        break;
      case 9:
        if (this.entity.seasonalPrizes && this.entity.seasonalPrizes.length > 0) {
          for (let i = 0; i < this.entity.seasonalPrizes.length; i++) {
            let stageArray = [];
            if (this.entity.seasonalPrizes[i]) {
              stageArray.push(this.entity.stageMilestone[i]);
              stageArray.push(this.entity.seasonalPrizes[i]);
              this.parsedSeasonPass.push(stageArray);
            }
          }
        }
        break;
      default:
        break;
    }
  }

  getRewardImage(reward: any): string {
    let imagePath = '';

    if (!reward || !reward.id) {
    } else {
      switch (reward.lineItemType) {
        case 'Item':
          imagePath = reward.id?.thumbnail_ref?.thumbnails?.[0]?.path
          break;
        case 'Currency':
          imagePath = reward.id?.thumbnail_ref?.thumbnails?.[0]?.path ||
          reward.id?.thumbnails?.[2]?.path
          break;
        case 'Titles':
          imagePath = 'https://d3tfb94dc03jqa.cloudfront.net/images/titles/title_default/title_default_128.png';
          break;
        case 'Nurture':
          imagePath = reward.id?.coinThumbnail_ref?.thumbnails?.[0]?.path ||
          reward.id?.sproutImage_ref?.thumbnails?.[0]?.path;
          break;
        case 'SeedPackBox':
          imagePath = reward.id?.box_image_empty_ref?.thumbnails?.[0]?.path
          break;
        default:
          break;
      }
    }

    return imagePath;
  }


  getLink(reward: any): string {
    if (!reward || !reward.id || reward.id.id === null || reward.id.id === undefined) {
      console.error('Reward or reward ID is null:', reward);
      return '#';
    }
    let baseUrl = '';
    switch (reward.lineItemType) {
      case 'Item':
        baseUrl = '/items';
        break;
      case 'Currency':
        baseUrl = '/currencies';
        break;
      case 'Titles':
        baseUrl = '/titles';
        break;
      case 'SeedPackBox':
        baseUrl = '/seed-pack-box';
        break;
      case 'StoreListingV2':
        baseUrl = '/store-listings-v2';
        break;
      case 'Nurture':
        baseUrl = '/nurture';
        break;
      default:
        console.error('Unknown reward type:', reward.lineItemType);
        return '#';
    }
    return `${baseUrl}/${reward.id.id}`;
  }

  getEnvironmentClass(environments: string[] | undefined): string {
    if (!environments || environments.length === 0) {
      return 'no-environment';
    }
    return 'has-environment';
  }

  getEnvironmentText(environments: string[] | undefined): string {
    if (!environments || environments.length === 0) {
      return 'No Environment Found';
    }
    return environments.join(', ');
  }
}
