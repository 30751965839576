import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';

/**
 * @deprecated It'll be removed
 */
@Component({
  selector: 'app-test-user-table-ag-grid',
  templateUrl: './test-user-table-ag-grid.component.html',
  styleUrls: ['./test-user-table-ag-grid.component.sass']
})
export class TestUserTableAgGridComponent implements OnInit {

  public entity: string = 'users';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};


  private options: any = {};

  constructor(
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();


    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right', // Pin the column to the right
        width: 120, // Set the width of the column
        resizable: true,
      },
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Email',
        field: 'email',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellEditor: 'agTextCellEditor',
      },
      {
        headerName: 'Name',
        field: 'name',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellEditor: 'agTextCellEditor',
      },
      {
        headerName: 'Is Test',
        field: 'isTest',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,
      },
      {
        headerName: 'Is Active',
        field: 'isActive',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Active',
          offLabel: 'Not Active',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Active' : 'Not Active'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Is Active', id: 'isActive', type: 'boolean', onLabel: 'Active', offLabel: 'Not Active'
      },
    ];

    this.buildParams = {
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions(){
    this.tableParams = {
      customName: "Test Users Management",
      hideAdd: true,
      defaultQuery: {
          'isTest': true
      }
    }
  }

}
