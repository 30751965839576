import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser
} from '@abacritt/angularx-social-login';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit {
  user: SocialUser;
  loggedIn: boolean;
  existingUser: any;
  errorMessage: any;

  constructor(
    private router: Router,
    public socialAuthService: SocialAuthService,
    private authService: AuthService,
    private http: HttpClient,
    private userContextService: UserContextService,
  ) {}

  ngOnInit(): void {}
}
