import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-rewards-track-view',
  templateUrl: './rewards-track-view.component.html',
  styleUrls: ['./rewards-track-view.component.css'],
})
export class RewardsTrackViewComponent implements OnInit {
  entity: any = {};  
  isLoading: boolean = false;
  id: any;
  dailyRewards: any = [];
  day7Rewards: Array<any> = [];
  parsedSeasonPass: any = [];

  layoutMapping: any = {
    1: '7day',    // Login Rewards Day 1-7
    2: '30d',     // Login Rewards Day 30
    3: 'timeline', // Free Garden Pass
    4: 'timeline', // Paid Garden Pass
    5: 'timeline', // Friends Invited Track
    6: 'endless-offer', // Endless Offer
    7: 'timeline', // Co-Op Rewards Track
    8: 'timeline', // Mini Games
    9: 'timeline', // Seasonal Prizes
    10: 'timeline',// Daily Goals/Activities
    11: 'perk-daily' // Perk Daily Rewards (nuevo)

  };

  constructor(private entityViewService: EntityViewService, private route: ActivatedRoute) {}

  async ngOnInit() {
    this.isLoading = true;
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));

    const result: any = await this.entityViewService.getEntity('rewards-track', { query: { id: this.id }, autopopulate: true });
    
    if (result) {
      this.entity = result;
    }

    const layoutType = this.getLayoutType(this.entity?.type);

    if (layoutType === 'timeline') {
      await this.parseSeasonPass(this.entity?.type);
    } else if (layoutType === '7day') {
      await this.loadDailyRewards(this.entity?.type);
    } else if (layoutType === 'perk-daily') {
      await this.loadPerkDailyRewards(this.entity?.type);
    }

    this.isLoading = false;
  }

  getLayoutType(type?: number): string {
    return this.layoutMapping[type ?? 10];  
  }

  async parseSeasonPass(type: number) {
    console.log('Parsing season pass for type:', type);
    this.parsedSeasonPass = []; 

    if (!this.entity || !this.entity.stageMilestone) return;

    const rewardKeys: { [key: number]: string } = {
      3: 'free',
      4: 'paid',
      5: 'friends',
      7: 'coop',
      8: 'miniGamesRewards',
      9: 'seasonalPrizes',
      10: 'dailyActivitiesRewards'
    };

    const key = rewardKeys[type];
    if (key && this.entity[key]?.length > 0) {
      for (let i = 0; i < this.entity[key].length; i++) {
        if (this.entity[key][i]) {
          this.parsedSeasonPass.push([this.entity.stageMilestone[i], this.entity[key][i]]);
        }
      }
    }
  }

  async loadDailyRewards(type: number) {
    console.log('Loading daily rewards for type:', type);
    this.dailyRewards = [];
    this.day7Rewards = [];

    if (!this.entity) return;

    for (let key of Object.keys(this.entity)) {
      if (key.includes('dailyRewardDay') && key !== 'dailyRewardDay30') {
        let day = parseInt(key.replace('dailyRewardDay', ''), 10);
        if (day !== 7) {
          let dayIndex = this.dailyRewards.findIndex((d: { day: number }) => d.day === day);
          if (dayIndex === -1) {
            this.dailyRewards.push({ day: day, rewards: [] });
            dayIndex = this.dailyRewards.length - 1;
          }

          this.entity[key].forEach((reward: any) => {
            if (reward) {
              this.dailyRewards[dayIndex].rewards.push({ ...reward });
            }
          });
        } else {
          this.day7Rewards = this.entity[key];
        }
      }
    }
  }
  async loadPerkDailyRewards(type: number) {
    this.dailyRewards = [];
    if (!this.entity || !Array.isArray(this.entity.perkDailyReward)) return;
    this.dailyRewards = this.entity.perkDailyReward.map((rewards: any, index: number) => ({
      day: index + 1, 
      rewards: rewards || []
    }));
  }  
}
