<div class="p-inputgroup">
    <span ngClass="p-float-label" *ngIf="!isLoading && options && options.length > 0">
        <p-multiSelect
        [(ngModel)]="selectedOptions"
        [ngModelOptions]="{ standalone: true }"
        [options]="options"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        display="chip"
        [style]="{ width: '100%' }"
        (onFilter)="getSuggestionsForRef($event)"
        (onChange)="onChange()"
        >
        <ng-template let-option pTemplate="selectedItem">
            <div>
                ({{ option.id }})
                {{ option.name }}
            </div>
        </ng-template>
        <ng-template let-option pTemplate="item">
            <div>
                ({{ option.id }})
                {{ option.name }}
            </div>
        </ng-template>
        </p-multiSelect>
        <label for="colors_ref"> {{labelName}} </label>
    </span>
    <p-button
        *ngIf="selectedOptions && selectedOptions.length > 0"
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        styleClass="p-button-text p-button-danger"
        (click)="selectedOptions = []; onChange()"
    ></p-button>
    <div *ngIf="!isLoading && (!options || options.length == 0)">
        <h3> No records found. </h3>
    </div>
</div>