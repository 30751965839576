import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'prod-push-info',
  templateUrl: './prod-push-info.component.html',
  styleUrls: ['./prod-push-info.component.sass']
})
export class ProdPushInfoComponent implements OnInit {
  manualProdPushButtonEnabled: boolean;

  constructor(
    private settingsService: SettingsService,
  ) {}

  /**
   * Build Nodes Settings Management Initialization
   */
  async ngOnInit() {
    let result = await this.settingsService.getSettingByQuery({
      query: { name: 'cms-settings' },
    });
    if (result) {
      this.manualProdPushButtonEnabled = result.manualProdPushButtonEnabled;
    }
  }
}