import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreManagementService {

  constructor() { }

  getIcon(env: string): string {
    switch(env) {
      case 'dev':
        return 'fa-solid fa-d';
      case 'qa':
        return 'fa-solid fa-q';
      case 'prod':
        return 'fa-solid fa-p';
      default:
        return ''; // default icon or an empty string if no match
    }
  }

  getSeverity(env: string): string {
    switch(env) {
      case 'dev':
        return 'success';
      case 'qa':
        return 'warning';
      case 'prod':
        return 'danger';
      default:
        return 'info'; // default severity or any other as per your requirement
    }
  }
}
