import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';

export default class CategoryCollectionsDto extends BaseEntityDto {
  /**
   * Name of the Category Collection
   */
    
    public name: string;

  /**
   * Array for category ref
   * These will tie categoreis to each collection
   */
    
    public category_ref: Array<any> = [];
  

    /**
     * Array for filters where it will host multiple different types entities such as colors
     */
    
    public filters: Array<any> = [];
}