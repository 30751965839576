import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

export default class NurtureCollectionGroupDto extends BaseEntityDto
{ 
    name: string = "";

    enabled: boolean;

    unlockThreshold: number = 0;

    order: number = 0;

    nurtureCollections_ref: any[] = [];
    
    blurredImage_ref: string = '';
  }
  