<dynamic-form-v2 
    *ngIf="!isLoading" 
    entityType="challenge-types" 
    [formName]="title" 
    [(record)]="challengeTypeRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [showBottonSubmit]="true"
    [autopopulateEntity]="true"
    [validationCallbackFunction]="validateChallengeTypeForm.bind(this)"
>
</dynamic-form-v2>