import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { BuildType } from 'src/app/enums/build-type';
import NurtureCollectionDTO from '../dtos/NurtureCollectionDTO';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';


@Component({
  selector: 'app-nurture-collection-view',
  templateUrl: './nurture-collection-view.component.html',
  styleUrls: ['./nurture-collection-view.component.sass']
})
export class NurtureCollectionViewComponent extends BaseDynamicViewComponent implements OnInit {


  nurtureCollectionRecord: NurtureCollectionDTO = new NurtureCollectionDTO();
  constructor(
      private agGridToolsService: AgGridToolsService,
    ) {
    super();
  }

  miscAssetKeys: Array<any> = ['image_ref', 'pinBackground_ref', 'seedPack_ref']
  parentViewName: string = 'nurtureCollectionView'

  async ngOnInit() {
    {
      console.log(this.nurtureCollectionRecord);
      this.fields = [
        {
          title: "Nurture Collection Data",
          fields:
          [
            {
              name: "Display Name",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'displayName',
              isRequired: false,
              label: 'Display Name',
              disabled: false,
              columnWidth: 6
            },
            {
              name: "Nurture Collection Type",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.DropdownWithLabels],
              clearField: true,
              key: 'type',
              label: 'Nurture Collection Type',
              filter: true,
              autoDisplayFirst: false,
              optionLabel: 'name',
              optionValue: 'value',
              isRequired: true,
              columnWidth: 6,
              options:
              {
                fieldName: 'type',
                values:
                [
                  { name: 'Permanent', value: 0 },
                  { name: 'Seasonal', value: 1 },
                ]
              }
            },
            // {
            //   name: "Region",
            //   inputType: DynamicViewType.BaseViewField ,
            //   viewTypes: [BaseViewFieldType.Text],
            //   clearField: true,
            //   key: 'region',
            //   isRequired: false,
            //   label: 'Region',
            //   columnWidth: 6
            // },
            {
              name: "Region Group",
              inputType: DynamicViewType.MiscRef,
              clearField: true,
              key: 'nurtureGroup',
              label: 'Region Group',
              controller: 'nurture-collection-group',
              columnWidth: 6
            },
            {
              name: "Priority",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'priority',
              label: 'Priority',
              inputNumberShowButtons: true,
              columnWidth: 6
            },
            { name: "",
              inputType: DynamicViewType.SpaceCol,
              clearField: true,
              key: '',
              label: '',
              columnWidth: 12
            },
            {
              name: "Start Date/Time",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'start',
              label: 'Start Date/Time',
              columnWidth: 6
            },
            {
              name: "Target Progress",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'targetProgress',
              label: 'Target Progress',
              inputNumberShowButtons: true,
              columnWidth: 6
            },
            {
              name: "End Date/Time",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'end',
              label: 'End Date/Time',
              columnWidth: 6
            },
            {
              name: "Target Nurture Collection",
              inputType: DynamicViewType.MiscRef,
              clearField: true,
              key: 'collection_ref',
              label: 'Target Nurture Collection',
              controller: 'nurture-collection',
              columnWidth: 6
            },
            {
              name: "Min Pulls 5/6",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'minPulls5',
              isRequired: false,
              label: 'Min Pulls 5/6',
              columnWidth: 6
            },
            {
              name: "Max Pulls 5/6",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'maxPulls5',
              isRequired: false,
              label: 'Max Pulls 5/6',
              columnWidth: 6
            },
            {
              name: "Min Pulls 6/6",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'minPulls6',
              isRequired: false,
              label: 'Min Pulls 6/6',
              columnWidth: 6
            },
            {
              name: "Max Pulls 6/6",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'maxPulls6',
              isRequired: false,
              label: 'Max Pulls 6/6',
              columnWidth: 6
            },
            {
              name: "Coming Soon?",
              inputType: DynamicViewType.Boolean,
              clearField: true,
              key: 'comingSoon',
              label: 'Coming Soon?',
              columnWidth: 6
             },
             {
              name: "Collection Color",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.ColorDisplay],             
              clearField: false,
              key: 'color',
              label: 'Collection Color',
              columnWidth: 6
            },
          ],
          type: DynamicCardType.Card
        },
        {
          title: "Images",
          fields:
          [
            {
              name: "Banner Image",
              inputType: DynamicViewType.MiscImageBuildComponent,
              clearField: true,
              key: 'image_ref',
              label: 'Banner Image',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: {
                buildAndRender: false,
                fieldName: 'image_ref', apiController: 'miscellaneous-build',
                collapsed: true,
                type: 'ref',
              },
              columnWidth: 12
            },
            {
              name: "Pin Background",
              inputType: DynamicViewType.MiscImageBuildComponent,
              clearField: true,
              key: 'pinBackground_ref',
              label: 'Pin Background',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: {
                fieldName: 'pinBackground_ref', apiController: 'miscellaneous-build',
                autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' ,
                collapsed: true,
                type: 'ref',
              },
              columnWidth: 12
            },
            {
              name: "Seed Packet",
              inputType: DynamicViewType.MiscImageBuildComponent,
              clearField: true,
              key: 'seedPack_ref',
              label: 'Seed Packet',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: {
                fieldName: 'seedPack_ref', apiController: 'miscellaneous-build',
                customQuery: { entityType: BuildType.Images },
                autopopulate: false,
                virtuals: false, sort: { name: 1 },
                select: 'name id path _id',
                collapsed: true,
                type: 'ref',
              },
              columnWidth: 12
            },
          ],
          type: DynamicCardType.Card
        },
        {
          title: 'Nurture Items',
          type: DynamicCardType.Card,
          fields: [
            {
              name: "Nurtures", inputType: DynamicViewType.AgGridTable,
              key: 'nurtures_ref', label: 'Nurtures',
              options: {
                fieldName: 'nurtures',
                columnDefs:[
                  {
                    headerName: 'ID',
                    field: 'id',
                    width: 125,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    sortable: true,
                    initialSort: 'desc',
                    sortIndex: 0,
                    initialSortIndex: 0,
                    sort: 'desc',
                    sortingOrder: ['asc','desc'],
                    unSortIcon: false,
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                      filterOptions: [
                        'equals',
                        'notEqual',
                        'lessThan',
                        'lessThanOrEqual',
                        'greaterThan',
                        'greaterThanOrEqual',
                        'inRange'
                      ]
                    },
                    resizable: true,
                    floatingFilter: true,
                    suppressSizeToFit: true,
                  },
                  {
                    headerName: 'Name',
                    field: 'name',
                    cellRendererParams: {
                      entity: 'nurture'
                    },
                    cellRenderer: LinkCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    width: 350,
                    resizable: true,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                    cellClass: 'center-cell-content',
                    cellEditor: 'agTextCellEditor',
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Item Name',
                    field: 'itemName',
                    cellRenderer: TextCellRendererComponent,
                    resizable: true,
                    cellClass: 'center-cell-content',
                    valueGetter: (params: any) => {
                      if(params.data.itemId[0]){
                        return params.data.itemId[0].name
                      } else {
                        return 'undefined';
                      }
                    },
                  },
                  {
                    headerName: 'Item Thumb',
                    field: 'thumbnail_img',
                    sortable: false,
                    resizable: true,
                    width: 135,
                    autoHeight: true,
                    cellRenderer: ThumbnailCellRendererComponent,
                    valueGetter: (params: any) => {
                      const thumbnail = params.data?.itemId?.[0]?.thumbnail_ref?.thumbnails?.[1];
                      return thumbnail?.path || 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg';
                    },
                  },
                  {
                    headerName: 'Item ID',
                    field: 'itemId',
                    cellRenderer: TextCellRendererComponent,
                    resizable: true,
                    cellClass: 'center-cell-content',
                    valueGetter: (params: any) => {
                      if(params.data.itemId[0]){
                        return params.data.itemId[0].id
                      } else {
                        return 'undefined';
                      }
                    },
                  },
                  {
                    headerName: 'Rarity',
                    field: 'rarity',
                    cellRenderer: TextCellRendererComponent,
                    resizable: true,
                    cellClass: 'center-cell-content',
                    valueGetter: (params: any) => {
                      if(params.data.itemId[0]){
                        return params.data?.rarity_ref?.name || 'undefined';
                      } else {
                        return 'undefined';
                      }
                    },
                  },
                  {
                    headerName: 'Start',
                    field: 'start',
                    sortable: true,
                    unSortIcon: true,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    suppressSizeToFit: true,
                    width: 300,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      isDate: true,
                    },
                    valueGetter: this.agGridToolsService.dateValueGetter('start'),
                    resizable: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'End',
                    field: 'end',
                    sortable: true,
                    unSortIcon: true,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    suppressSizeToFit: true,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      isDate: true,
                      timePill: true
                    },
                    width: 300,
                    valueGetter: this.agGridToolsService.dateValueGetter('end'),
                    resizable: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'Enabled',
                    field: 'enabled',
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      onLabel: 'Enabled',
                      offLabel: 'Not Enabled',
                      isBoolean: true
                    },
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: [true, false],
                      valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                    },
                    floatingFilter: true,
                    cellClass: 'center-cell-content',
                  },
                ],
                defaultQueryKey: 'nurtures_ref',
                entity: 'nurture'
              }
            },
          ]
        },
        {
          title: 'Rewards',
          type: DynamicCardType.Card,
          fields: [
            {
              name: 'Reward(s)',
              label: 'Reward(s)',
              inputType: DynamicViewType.Price,
              key: 'rewards_ref',
              priceExcludeResourceTypes: ['Titles']
            },
          ]
        },
      ]

      this.sidebarFields = [
        {
          title: "Nurture Collection Data",
          fields:
          [
            { name: "Banner Preview",
              inputType: DynamicViewType.sideThumbnail,
              key: 'image_ref',
              label: 'Banner Preview',
              columnWidth: 12
            },
          ],
          type: DynamicCardType.Card
        },
      ]

      this.title = "";
      this.viewRoute = 'nurture-collection';
      this.isLoading = false;
    }
  }

}
