<div class="filter-container">

  <!-- Clear Filters Button -->
  <button
    class="clear-filters"
    (click)="clearFilters()"
    pButton
    icon="pi pi-filter-slash"
    styleClass="p-button-rounded p-button-text p-button-sm"
    title="Clear Filters"
  ></button>

  <!-- Empty/Non Empty Filter -->
  <div class="filter-section">
    <label>Empty/Non Empty:</label>
    <br />
    <div class="radio-group">
      <p-radioButton
        name="emptyFilter"
        value="empty"
        [(ngModel)]="emptyFilter"
        (onClick)="onFilterChanged('emptyNonEmpty','empty')"
        label="Empty"
      ></p-radioButton>
      <p-radioButton
        name="emptyFilter"
        value="nonEmpty"
        [(ngModel)]="emptyFilter"
        (onClick)="onFilterChanged('emptyNonEmpty','nonEmpty')"
        label="Non Empty"
      ></p-radioButton>
    </div>
  </div>

  <!-- "or" Divider -->
  <div class="divider-with-text">
    <hr class="divider" />
    <span>or</span>
    <hr class="divider" />
  </div>

  <!-- Include Keywords ListBox -->
  <div class="filter-section" [class.disabled]="emptyFilter == 'empty'">
    <label class="p-mb-1" for="includeSet">Include:</label>
    <p-listbox
      [options]="includeOptions"
      [(ngModel)]="includeSet"
      optionLabel="label"
      optionValue="value"
      (onChange)="onFilterChanged('include', includeSet)"
      [disabled]="emptyFilter == 'empty'"
      [multiple]="true"
      [checkbox]="true"
      [filter]="true"
      filterPlaceHolder="Search"
      [ariaFilterLabel]="'Include '+entityLabel+'(s)'"
      [listStyle]="{'max-height':'200px', 'font-size':'14px'}"
    ></p-listbox>
  </div>

  <hr class="divider" />
  <br />

  <!-- Exclude Keywords ListBox -->
  <div class="filter-section" [class.disabled]="emptyFilter == 'empty'">
    <label class="p-mb-1" for="excludeSet">Exclude:</label>
    <p-listbox
      [options]="excludeOptions"
      [(ngModel)]="excludeSet"
      optionLabel="label"
      optionValue="value"
      (onChange)="onFilterChanged('exclude', excludeSet)"
      [disabled]="emptyFilter == 'empty'"
      [multiple]="true"
      [checkbox]="true"
      [filter]="true"
      filterPlaceHolder="Search"
      [ariaFilterLabel]="'Exclude '+entityLabel+'(s)'"
      [listStyle]="{'max-height':'200px', 'font-size':'14px'}"
    ></p-listbox>
  </div>
</div>
