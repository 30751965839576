<div class="p-grid">
  <div class="p-col-12 gallery-header">
    <span class="title-wrapper">
      <h4 class="p-m-0">Linux Renders Gallery</h4>
    </span>
    <button
      pButton pRipple
      type="button" class="p-button-rounded float-right p-mr-3" icon="fa-solid fa-images"
      [ngStyle]="{'width': '26px', 'height': '26px'}"
      (click)="showDiffDialog=true"
    ></button>
  </div>
  <div class="p-col-6" *ngFor="let image of images">
    <div class="image-container">
      <p class="p-m-1">{{ image.title }}</p>
      <img [src]="image.previewImageSrc" (click)="showDialog(image)" class="thumbnail render-thumb"  [ngStyle]="image.style" />
      <!-- <img *ngIf="image?.devDiff?.diffImage" [src]="image.devDiff.diffImage" (click)="showDialog(image)" class="thumbnail"  [ngStyle]="{'border-radius': '10px'}" /> -->
    </div>
  </div>
</div>

<p-dialog [(visible)]="display" modal="true" [style]="{width: '50vw'}" baseZIndex="10000">
  <p-galleria *ngIf="display" [value]="images"
      [activeIndex]="selectedImage ? images.indexOf(selectedImage) : 0"
      [showIndicators]="true" [showItemNavigatorsOnHover]="true" [circular]="true"
      [showItemNavigators]="true" [autoPlay]="false" [showThumbnails]="false" [showThumbnailNavigators]="false"
      [ngStyle]="{maxWidth: '100%'}">
    <ng-template pTemplate="item" let-item>
      <img [src]="item.previewImageSrc" (error)="onImgError($event)"
           [alt]="item.alt" style="display: block;" />
    </ng-template>
    <ng-template pTemplate="caption" let-item>
      <h4 style="color: #ffffff;">
        {{ item.title }}
        <a [style.color]="item.linkColor || 'var(--primary-color)'" target="_blank" [href]="item.thumbnailImageSrc" class="p-text-normal p-ml-1"><i class="pi pi-external-link"></i></a>
      </h4>
    </ng-template>
  </p-galleria>
</p-dialog>

<p-dialog header="Image Differences" [(visible)]="showDiffDialog" [style]="{width: '50vw'}">
  <ng-container *ngIf="prePromotionImage.devDiff">
    <div class="diff-details">
      <h4>DEV Difference</h4>
      <p>Pixels Different: {{prePromotionImage.devDiff.diffPixels}}</p>
      <p>Difference Percentage: {{prePromotionImage.devDiff.diffPercentage}}%</p>
      <div class="image-container">
        <img [src]="prePromotionImage.devDiff.diffImage" alt="DEV Difference">
      </div>
    </div>
  </ng-container>
  <!-- Repeat for qaDiff and prodDiff with corresponding adjustments -->
  <ng-container *ngIf="prePromotionImage.qaDiff">
    <div class="diff-details">
      <h4>QA Difference</h4>
      <p>Pixels Different: {{prePromotionImage.qaDiff.diffPixels}}</p>
      <p>Difference Percentage: {{prePromotionImage.qaDiff.diffPercentage}}%</p>
      <div class="image-container">
        <img [src]="prePromotionImage.qaDiff.diffImage" alt="QA Difference">
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="prePromotionImage.prodDiff">
    <div class="diff-details">
      <h4>PROD Difference</h4>
      <p>Pixels Different: {{prePromotionImage.prodDiff.diffPixels}}</p>
      <p>Difference Percentage: {{prePromotionImage.prodDiff.diffPercentage}}%</p>
      <div class="image-container">
        <img [src]="prePromotionImage.prodDiff.diffImage" alt="PROD Difference">
      </div>
    </div>
  </ng-container>
</p-dialog>

