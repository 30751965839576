import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-build-gamedata-v3-view',
  templateUrl: './build-gamedata-v3-view.component.html',
})
export class BuildGamedataV3ViewComponent extends BaseDynamicViewComponent implements OnInit {
  Gamedatav3Record: BaseEntityDto = new BaseEntityDto();
  constructor() {
    super();
  }
  /**
   * Build GameData View V3 Initial Implementation
   */
  
  ngOnInit() {

    this.fields = [
      {
        title: "Build GameData View V3 ",
        fields: [            
          { name: "id", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'id', isRequired: true, label: 'ID', columnWidth: 6 },
          { name: "Datetime", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'createdAt', isRequired: true, label: 'Date Time', columnWidth: 6 },
          { name: "Finished", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'finish', isRequired: true, label: 'Finished', columnWidth: 6 },
          { name: "isConsecutive", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'isConsecutive', isRequired: true, label: 'is Consecutive', columnWidth: 6 },
          { name: "version", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'version', isRequired: true, label: 'Version', columnWidth: 6 },
          { name: "jobId", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'jobId', isRequired: true, label: 'Job Id', columnWidth: 6 },
          { name: "timeTaken", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.TimeTaken], clearField: true, key: 'timeTaken', isRequired: true, label: 'Time Taken', columnWidth: 6 },
          { name: "previousBuildTime", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'previousBuildTime', isRequired: true, label: 'Previous Build Time',columnWidth: 6 },
          { name: "buildTime", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'buildTime', isRequired: true, label: 'Build Time', columnWidth: 6  },
          { name: "devID", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'devID', isRequired: true, label: 'Dev ID', columnWidth: 6},
          { name: "Created By", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], key: 'createdBy.name', isRequired: true, label: 'Created By', columnWidth: 6 },

        ],
        type: DynamicCardType.Card
      },
    ];
    this.Gamedatav3Record = {
      enabled: false
    };
    this.title = "Gamedatav3";
    this.viewRoute = 'build-gamedata-v3';
    this.isLoading = false;
    
  }
}
