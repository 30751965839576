<p-dialog [(visible)]="visible" [header]="header">
    <p-messages severity="info">
      <ng-template pTemplate>
        <div class="p-grid">
          <div class="p-col-12">
            <i class="pi pi-info-circle p-mr-2"></i>
            <div class="ml-2">{{ message }}
              <br /> <br />{{ subMessage }}
            </div>
          </div>
          <div class="p-col-12">
            <div *ngIf="flaggedDoNotUse">
              <strong style="color: red">Warning:</strong>
              Current challenge Content Hold Flag contains "Do not use"<br>
            </div>
          </div>
        </div>
      </ng-template>
    </p-messages>
    <ng-template pTemplate="footer">
      <div class="p-d-flex p-jc-center">
        <button pButton pRipple type="button" label="Confirm" icon="pi pi-check" class="p-button-outlined p-m-2"
          (click)="confirm()"></button>
      </div>
    </ng-template>
  </p-dialog>
  
