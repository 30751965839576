import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  private themeSubject = new BehaviorSubject<string>('arya-green'); // Default theme
  public theme$ = this.themeSubject.asObservable();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  setTheme(theme: any) {
    let themeLink = this.document.getElementById(
      'app-theme'
    ) as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = theme + '.css';
    }

    localStorage.setItem('cmsTheme', theme);
    this.themeSubject.next(theme); // Notify subscribers about the theme change
  }
}
