import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PlasticService } from '../../services/plastic.service';
import { AutoComplete } from 'primeng/autocomplete';

@Component({
  selector: 'plastic-files-autocomplete',
  templateUrl: './plastic-files-autocomplete.component.html',
  styleUrls: ['./plastic-files-autocomplete.component.sass']
})
export class PlasticFilesAutocompleteComponent implements OnInit
{
  @Input() path: any;
  @Input() label: string = "Path";
  @Output() onChange = new EventEmitter<any>();
  @Output() onComplete = new EventEmitter<any>();
  @Output() onPathChange = new EventEmitter<any>();
  files: Array<any> = [];
  currentTimeout: any;
  isLoading: boolean = true;
  previousPath: any = null;

  @ViewChild(AutoComplete) autoComplete: AutoComplete;

  constructor(
    private plasticService: PlasticService,
  ) { }

  /**
   * Plastic Files Autocomplete Component Implementation
   */
  ngOnInit()
  {
    this.path = { path: this.path };
    this.isLoading = false;
  }

  /**
   * Listen changes from parent component.
   *
   * @param changes Path changes.
   */
 async ngOnChanges(changes: SimpleChanges)
 {
   if(changes.path && changes.path.previousValue != changes.path.currentValue && !changes.path.isFirstChange())
   {
    this.path = { path: changes.path.currentValue };
   }
 }

  /**
   * List plastic files given a path.
   */
  async listPlasticFiles()
  {
    if (this.currentTimeout)
    {
      clearTimeout(this.currentTimeout);
    }

    this.currentTimeout = setTimeout(async () =>
    {
      let filesResponse = [];
      let response = await this.plasticService.plasticListFiles(this.path);

      if(response && response.length > 0)
      {
        for(let file of response)
        {
          if(file && file.length > 0)
          {
            let emailUsername = file.split('@');
            let fileData = file.split('|');
            let user = emailUsername[0];
            let fileType = fileData[2];
            let path = fileData[3];

            let fullOption = `Created By: ${user} | ${path.replace('/approved_assets/', '')}`;

            filesResponse.push({ 
              type: fileType,
              preview: fullOption,
              path: path.replace('/approved_assets/', ''),
              user: user });
          }
        }
        this.files = filesResponse;
      }
      this.currentTimeout = null;
    }, 500);
  }

  /**
   * Handle select event.
   *
   * @param isSelectChange Flag to indicate if the event is a select change.
   */
  onSelect(isSelectChange: boolean = false) {
    this.onChange.emit({ isSelectChange: isSelectChange, value: this.path });
    this.onPathComplete();
    this.onPathChange.emit(this.path);
    this.listPlasticFiles();
  
    // Trigger search only if the path has changed
    if (this.previousPath !== this.path.path) {
      this.triggerSearch();
    }
  
    // Update previousPath to the current path
    this.previousPath = this.path.path;
  }
  

  /**
   * Handle path complete event.
   *
   *
   */
  onPathComplete()
  {
    this.onComplete.emit({value : this.path});
  }

  triggerSearch() {
    if (this.autoComplete) {
      this.autoComplete.search({}, this.path.path);
    }
  }

}
