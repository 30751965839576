<h4 class="p-d-inline">{{field.label}}: </h4>
<ul style="list-style: none;">
    <ng-container *ngIf="['seed-pack', 'nurture-collection', 'seed-pack-box'].includes(entityType); else elseBlock">
        <li *ngFor="let item of record[field.key]">
            <a href="/nurture/{{item.id}}"
               target="_blank"
               [ngStyle]="{ color: 'var(--primary-color)' }">
                {{item.name}} ({{item.id}})
            </a>
        </li>
    </ng-container>

    <ng-template #elseBlock>
        <ng-container *ngIf="['categories'].includes(entityType); else elseBlock2">
            <li *ngFor="let item of record[field.key]">
                <a href="/item-types/{{item.id}}"
                   target="_blank"
                   [ngStyle]="{ color: 'var(--primary-color)' }">
                    {{item.name}} ({{item.id}})
                </a>
            </li>
        </ng-container>
    </ng-template>

    <ng-template #elseBlock2>
        <li *ngFor="let item of record[field.key]">{{item}}</li>
    </ng-template>
</ul>
