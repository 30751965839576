<ng-container *ngFor="let field of secondaryStatusGroup">
    <h4 class="p-m-2 p-d-none p-d-lg-flex">{{ field.name }}:</h4>
    <span
      class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex"
      [ngSwitch]="field.controlType"
    >
      <!-- Environment(s) -->
      <ng-container *ngSwitchCase="'envs'">
        <div
          class="p-col p-m-2"
          [ngClass]="itemService.statusBgColors(field, entity)"
        >
          <h3 class="p-d-inline p-m-0 p-text-center">
            {{ entity[field.key] && entity[field.key].length > 0 ? entity[field.key].join(', ') : 'No Environment Found' }}
          </h3>
        </div>
      </ng-container>
  
      <!-- buildStatus -->
      <ng-container *ngSwitchCase="'buildStatus'">
        <div
          class="p-col p-m-2"
          [ngStyle]="{
            position: 'relative',
            color: 'var(--primary-color-text)',
            'background-color': entity[field.key]?.buildStatus?.color,
            'border-radius': '0.5em'
          }"
          *ngIf="entity[field.key] && entity[field.key].buildStatus"
        >
          <h3 class="p-d-inline p-m-0 p-text-center">
            {{ entity[field.key].buildStatus.text }}
          </h3>
          <p class="p-d-inline">
            {{ entity[field.key].buildStatus.date }}
          </p>
  
          <p-badge
            [value]="entity[field.key].buildStatus.buildCount"
            [style]="{
              'background-color': 'var(--text-color)',
              position: 'absolute',
              right: '5%'
            }"
          ></p-badge>
        </div>
      </ng-container>
  
      <!-- toggle -->
      <ng-container *ngSwitchCase="'toggle'">
        <div
          class="p-col p-m-2"
          [ngClass]="itemService.statusBgColors(field, entity)"
        >
          <h3 class="p-m-0 p-text-center">
            {{ itemService.parseValueForView(entity[field.key], field, entity) }}
          </h3>
        </div>
      </ng-container>
  
    </span>
  </ng-container>  