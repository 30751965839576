<p-timeline class="p-mt-2" [value]="parsedSeasonPass" align="alternate">
    <ng-template pTemplate="marker" let-event>
      <span class="custom-marker shadow-3">
        <p-badge [value]="event[0]"></p-badge>
      </span>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      <app-reward-card [title]="'Event ' + event[0]" [rewards]="event[1]"></app-reward-card>
    </ng-template>
  </p-timeline>
  