<div>
  <h1>Challenges Settings</h1>
</div>
<p-tabView>
    <p-tabPanel header="Keywords">
      <!--  Scene Types -->
      <p-fieldset
        legend="Keywords"
        [toggleable]="true"
        [collapsed]="false"
      >
        <div class="card">
          <p-toast></p-toast>
          <p-toolbar styleClass="p-mb-4">
            <ng-template pTemplate="left">
              <button
                pButton
                pRipple
                label="Add Keyword"
                icon="pi pi-plus"
                class="p-button-success"
                styleClass="p-mr-2"
                (click)="openNew('challenge-keywords')"
              ></button>
            </ng-template>
            <ng-template pTemplate="right"> </ng-template>
          </p-toolbar>
          <!-- add managed list record dialog -->
          <p-dialog
            [(visible)]="newRecordDialog"
            [style]="{ width: '450px' }"
            [modal]="true"
            styleClass="p-fluid"
          >
            <ng-template pTemplate="header">
              <h3>Add Keyword</h3>
            </ng-template>
            <ng-template pTemplate="content">
              <form
                *ngIf="newRecordFields"
                [formGroup]="newRecordForm"
                (ngSubmit)="submitNewRecord()"
              >
                <div class="p-field">
                  <span class="p-float-label">
                    <input
                      formControlName="name"
                      id="float-input"
                      type="text"
                      pInputText
                    />
                    <label for="float-input">Name</label>
                  </span>
                </div>
                <div class="p-field">
                  <span class="p-float-label">
                    <input
                      formControlName="description"
                      id="float-input"
                      type="text"
                      pInputText
                    />
                    <label for="float-input">Description</label>
                  </span>
                </div>
                <div class="p-field">
                  <p-toggleButton
                    formControlName="enabled"
                    [onLabel]="'Enabled'"
                    [offLabel]="'Not Enabled'"
                    [style]="{ width: '100%' }"
                    class="field-item"
                  ></p-toggleButton>
                </div>
              </form>
            </ng-template>
            <ng-template pTemplate="footer">
              <button
                pButton
                pRipple
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                (click)="hideDialog()"
              ></button>
              <button
                pButton
                pRipple
                label="Save"
                icon="pi pi-check"
                class="p-button-text"
                (click)="submitNewRecord()"
              ></button>
            </ng-template>
          </p-dialog>
          <p-dialog
            [(visible)]="editRecordDialog"
            [style]="{ width: '450px' }"
            [modal]="true"
            styleClass="p-fluid"
          >
            <ng-template pTemplate="header">
              <h3>Editing {{ this.newRecordForm.get("name")!.value }}</h3>
            </ng-template>
            <ng-template pTemplate="content">
              <form
                *ngIf="fields"
                [formGroup]="newRecordForm"
                (ngSubmit)="updateNewRecord()"
              >
                <div class="p-field">
                  <span class="p-float-label">
                    <input
                      formControlName="name"
                      id="float-input"
                      type="text"
                      pInputText
                    />
                    <label for="float-input">Name</label>
                  </span>
                </div>
                <div class="p-field">
                  <span class="p-float-label">
                    <input
                      formControlName="description"
                      id="float-input"
                      type="text"
                      pInputText
                    />
                    <label for="float-input">Description</label>
                  </span>
                </div>
                <div class="p-field">
                  <p-toggleButton
                    formControlName="enabled"
                    [onLabel]="'Enabled'"
                    [offLabel]="'Not Enabled'"
                    [style]="{ width: '100%' }"
                    class="field-item"
                  ></p-toggleButton>
                </div>
              </form>
            </ng-template>
            <ng-template pTemplate="footer">
              <button
                pButton
                pRipple
                label="Cancel"
                icon="pi pi-times"
                class="p-button-text"
                (click)="hideDialog()"
              ></button>
              <button
                pButton
                pRipple
                label="Save"
                icon="pi pi-check"
                class="p-button-text"
                (click)="updateNewRecord()"
              ></button>
            </ng-template>
          </p-dialog>
        </div>
        <p-table
          [value]="challengeTypeRows"
          styleClass="p-datatable-gridlines"
          responsiveLayout="scroll"
          [(selection)]="selectedRows"
          [rowHover]="true"
          dataKey="id"
        >
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="name">
                Name <p-sortIcon field="name"></p-sortIcon>
              </th>
              <th pSortableColumn="description">
                Description <p-sortIcon field="description"></p-sortIcon>
              </th>
              <th pSortableColumn="enabled">
                Enabled <p-sortIcon field="enabled"></p-sortIcon>
              </th>
              <th pSortableColumn="createdBy">
                Created By <p-sortIcon field="createdBy"></p-sortIcon>
              </th>
              <th pSortableColumn="createdAt">
                Created At <p-sortIcon field="createdAt"></p-sortIcon>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-record>
            <tr>
              <td>{{ record.name }}</td>
              <td>{{ record.description }}</td>
              <td>{{ record.enabled }}</td>
              <td>{{ record.createdBy ? record.createdBy.name : "" }}</td>
              <td>{{ record.createdAt | officeTime }}</td>
              <td>
                <button
                  pButton
                  pRipple
                  icon="pi pi-pencil"
                  class="p-button-rounded p-button-info p-mr-2"
                  (click)="editNewRecordModal(record)"
                  label="Edit"
                ></button>
                <button
                  pButton
                  pRipple
                  (click)="confirmDeleteNewRecordModal(record)"
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-warning p-mr-2"
                  label="Delete"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </p-fieldset>
    </p-tabPanel>
  </p-tabView>
  
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>
  