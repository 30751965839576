import { Injectable } from '@angular/core';
import { UtilitiesService } from './utilities.service';
import { EntityTypeSettingsService } from 'src/app/entities/settings/services/entity-type-settings.service';
import { ImagerySettingsService } from 'src/app/entities/settings/services/imagery-settings.service';

@Injectable({
  providedIn: 'root'
})
export class GettersService {

  constructor(
    private utilitiesService: UtilitiesService,
    private entityTypeSettingsService: EntityTypeSettingsService,
    private imagerySettingsService: ImagerySettingsService
  ) { }

  /**
   * Retrieves all Entity Type Settings records.
   */
  async getEntityTypeSettings(getAll: boolean = false, showOnAssetBundleOnly: boolean = false, entityTypes: any = null)
  {
    let queryConditions: any[] = [];
    if(entityTypes){
      queryConditions = [
        { value: { $in: entityTypes } },
        { value: { $exists: true } },
        { value: { $not: { $eq: "" } } }
      ];
    } else {
      queryConditions = [
        { value: { $ne: null } },
        { value: { $exists: true } },
        { value: { $not: { $eq: "" } } }
      ];
    }


    if (!getAll) {
      queryConditions.push(JSON.parse('{ "value": { "$ne": -1 } }'));
    }

    if (showOnAssetBundleOnly) {
      queryConditions.push(JSON.parse('{ "showOnAssetBundleOnly": true }'));
    }

    let response = await this.entityTypeSettingsService.getAllEntityTypeSettingsWithQuery({
      query: { $and: queryConditions},
    });

    if(response && response.length > 0)
    {
      return response;
    }
  }

  /**
   * Retrieves all Entity Type Settings records.
   */
  async getBundleTypeSettings(getAll: boolean = false)
  {
    let notAllQuery = [
      { value: { $ne: -1 }},
      { value: { $ne: null } },
      { value: { $exists: true } },
      { value: { $not: { $eq: "" } } }
    ];

    let allQuery = [
      { value: { $ne: null } },
      { value: { $exists: true } },
      { value: { $not: { $eq: "" } } }
    ];

    let response = await this.imagerySettingsService.getAllImagerySettingsWithQuery({
      query: { $and: getAll ? allQuery : notAllQuery},
    });

    if(response && response.length > 0)
    {
      return response;
    }
  }
}
