import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BuildType } from 'src/app/enums/build-type';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-external-plant-data-view-v2',
  templateUrl: './external-plant-data-view-v2.component.html',
  styleUrls: ['./external-plant-data-view-v2.component.sass']
})
export class ExternalPlantDataViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  externalPlantRecord: BaseEntityDto = new BaseEntityDto();
  constructor() 
  {
    super();
  }

  /**
   * External Plant Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "External Plant Data",
        fields: 
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name', disabled: false },
          { name: "File Reference", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'fileRef', isRequired: false, label: 'File Reference', disabled: false },
          { name: "Common Name",inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'commonName', isRequired: false, label: 'Common Name', disabled: false },
          { name: "Botanical Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'botanicalName', isRequired: false, label: 'Latin Name', disabled: false },
          { name: "Plant Type",inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'plantType', isRequired: false, label: 'Plant Type', disabled: false },
          { name: "Mature Size", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'matureSize', isRequired: false, label: 'Mature Size', disabled: false },
          { name: "Growing Time",inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'growingTime', isRequired: false, label: 'Growing Time', disabled: false },
          { name: "Bloom Time", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'bloomTime', isRequired: false, label: 'Bloom Time', disabled: false },
          { name: "Flower Color", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'flowerColor', isRequired: false, label: 'Flower Color', disabled: false },
          { name: "Soil Type", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'soilType', isRequired: false, label: 'Soil Type', disabled: false },
          { name: "Soil PH", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'soilPH', isRequired: false, label: 'Soil PH', disabled: false },
          { name: "Hardiness Zone", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'hardinessZone', isRequired: false, label: 'Hardiness Zone', disabled: false },
          { name: "Native Area", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'nativeArea', isRequired: false, label: 'Native Area', disabled: false },
          { name: "Sun Exposure", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'sunExposure', isRequired: false, label: 'Sun Exposure', disabled: false },
          { name: "Toxicity", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'toxicity', isRequired: false, label: 'Toxicity', disabled: false },
          { name: "URL", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'url', isRequired: false, label: 'URL', disabled: false },
          { name: "imagePath", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'imagePath', isRequired: false, label: 'Image Path', disabled: false },
          { name: "Description", inputType: DynamicViewType.InputTextArea, clearField: true, key: 'description', isRequired: false, label: 'Description', disabled: false },
          {
            name: "Spruce Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'image_ref',
            label: 'Spruce Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            options: { fieldName: 'image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
          },

        ],
        type: DynamicCardType.Card
      },
    ]
    // This will help us to define fields 
    // with pre-established values.
    // This is just an example, there is no 
    // need to add it if it's not necessary.
    this.externalPlantRecord =
    {
      enabled: false
    };
    this.title = "External Plant Data";
    this.viewRoute = 'external-plant-data';
    this.isLoading = false;
  }

  /**
   * Validate Color required field.
   * 
   * @param color Color record
   */
  validateColor(color: any)
  {
    return color && color.name && color.name.length > 0;
  }
}