import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Titles DTO
 */
export default class TitlesDTO extends BaseEntityDto
{
    /**
     * List of items
     */
    items_ref: Array<any> = [];
    /**
     * Titles description
     */
    description: string = "";
}