<dynamic-form-v2 
    *ngIf="!isLoading" 
    entityType="batches" 
    [formName]="title" 
    [(record)]="batchRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [customQuery]="customQuery"
    (onFieldChange)="onNameComponentChange($event)"
>
</dynamic-form-v2>