import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { GoogleLoginProvider, SocialLoginModule , SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
//import { ColorPickerModule } from 'ngx-color-picker';
import { NgxSpinnerModule } from "ngx-spinner";
import { AccordionModule } from 'primeng/accordion';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { ChipsModule } from 'primeng/chips';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { GalleriaModule } from 'primeng/galleria';
import { DividerModule } from 'primeng/divider';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';
import { authTokenInterceptorProvider } from './auth/interceptor/auth.token.interceptor';
import { LoginComponent } from './auth/login/login.component';
import { BuildDataComponent } from './build-data/build-data.component';
import { BuildGamedataComponent } from './build-gamedata/build-gamedata.component';
import { GamedataBuildsHistoryComponent } from './build-gamedata/build-history/gamedata-buildshistory.component';
import { GamedataDiffComponent } from './build-gamedata/prod-gamedata-push/gamedata-diff/gamedata-diff.component';
import { GamedataDiffViewComponent } from './build-gamedata/prod-gamedata-push/gamedata-diff-view/gamedata-diff-view.component';
import { ProdGamedataPushComponent } from './build-gamedata/prod-gamedata-push/prod-gamedata-push.component';
import { AchievementCollectionViewV2Component } from './cms-v2/entities/achievements-collection/achievement-collection-view-v2/achievement-collection-view-v2.component';
import { CollectionTableV2Component } from './cms-v2/entities/collection/components/collection-table-v2/collection-table-v2.component';
import { CollectionTableViewComponent } from './cms-v2/entities/collection/components/collection-view-v2/collection-table-view.component';
import { LoadingScreenViewV2Component } from './cms-v2/entities/loading-screen/loading-screen-view-v2/loading-screen-view-v2.component';
import { SeriesTableComponent } from './cms-v2/entities/series/components/series-table/series-table.component';
import { SourcingGroupFormComponent } from './cms-v2/entities/sourcing-group/sourcing-group-form/sourcing-group-form.component';
import { SourcingGroupViewComponent } from './cms-v2/entities/sourcing-group/sourcing-group-view/sourcing-group-view.component';
import { TableDataComponent } from './cms-v2/entities/table-data/components/table-data/table-data.component';
import { TitlesViewV2Component } from './cms-v2/entities/titles/components/titles-view-v2/titles-view-v2.component';
import { BaseDataFieldComponent } from './common/components/base-fields/data-field/base-data-field.component';
import { BaseFilterFieldComponent } from './common/components/base-fields/filter-field/base-filter-field.component';
import { BaseviewComponent } from './common/components/baseview/baseview.component';
import { BuildAssetComponent } from './common/components/build-asset/build-asset.component';
import { DynamicTableComponent } from './common/components/dynamic-table/dynamic-table.component';
import { EntityHistoryComponent } from './common/components/entity-history/entity-history.component';
import { FileUplaoderComponent } from './common/components/file-uplaoder/file-uplaoder.component';
import { FiltersSidebarComponent } from './common/components/filters-sidebar/filters-sidebar.component';
import { ManagedListComponent } from './common/components/managed-list/managed-list.component';
import { PrizesCardFormGroupComponent } from './common/components/prizes-card-form-group/prizes-card-form-group.component';
import { RulesComponent } from './common/components/rules/rules.component';
import { SelectMultipleColorsComponent } from './common/components/select-multiple-colors/select-multiple-colors.component';
import { TableViewComponent } from './common/components/table-view/table-view/table-view.component';
import { RefSuffixRemovalPipe } from './common/pipes/refString.pipe';
import { ThumbPathPipe } from './common/pipes/thumbPath.pipe';
import { YesNoPipe } from './common/pipes/yes-no.pipe';
import { FormArrayComponent } from './dynamic-field/control-groups/form-array/form-array.component';
import { FormGroupComponent } from './dynamic-field/control-groups/form-group/form-group.component';
import { DynamicFieldComponent } from './dynamic-field/dynamic-field.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { AchievementFormComponent } from './entities/achievement/achievement-form/achievement-form.component';
import { BatchViewV2Component } from './cms-v2/entities/batch/components/batch-view-v2/batch-view-v2.component';
import { CategoryViewComponent } from './entities/category/category-view/category-view.component';
import { ChallengeTypeViewV2Component } from './cms-v2/entities/challenge-type/components/challenge-type-view-v2/challenge-type-view-v2.component';
import { ChallengeBuildsHistoryComponent } from './entities/challenge/challenge-buildshistory/challenge-buildshistory.component';
import { ChallengeFormComponent } from './entities/challenge/challenge-form/challenge-form.component';
import { ChallengesKeywordsComponent } from './entities/challenge/challenge-keywords/challenge-keyword.component';
import { ClimateViewComponent } from './entities/climate/climate-view/climate-view.component';
import { CollectionViewComponent } from './entities/collection/collection-view/collection-view.component';
import { CurrencyFormComponent } from './entities/currency/currency-form/currency-form.component';
import { CurrencyViewV2Component } from './cms-v2/entities/currency/components/currency-view-v2/currency-view-v2.component';
import { ExternalPlantDataFormV3Component } from './cms-v2/entities/external-plant-data/components/external-plant-data-form-v3/external-plant-data-form-v3.component';
import { InboxMessageViewV2Component } from './cms-v2/entities/inbox-message/components/inbox-message-view-v2/inbox-message-view-v2.component';
import { ItemTypeViewV2Component } from './cms-v2/entities/item-type/components/item-type-view-v2/item-type-view-v2.component';
import { ItemBuildsHistoryComponent } from './entities/item/item-buildshistory/item-buildshistory.component';
import { ItemHistoryComponent } from './entities/item/item-history/item-history.component';
import { ItemViewComponent } from './entities/item/item-view/item-view.component';
import { ItemFileTypeFormV2Component } from './cms-v2/entities/item-file-type/components/item-file-type-form/item-file-type-form.component';
import { itemfiletypeViewV2Component } from './cms-v2/entities/item-file-type/components/item-file-type-view-v2/item-file-type-view-v2.component';
import { PatternFormV2Component } from './cms-v2/entities/pattern/components/pattern-form/pattern-form.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { ResourceFormV2Component } from './cms-v2/entities/resource/components/resource-form/resource-form.component';
import { ResourceViewV2Component } from './cms-v2/entities/resource/components/resource-view-v2/resource-view-v2.component';
import { RestrictionViewV2Component } from './cms-v2/entities/restriction/components/restriction-view-v2/restriction-view-v2.component';
import { SettingsComponent } from './entities/settings/settings.component';
import { TraitTableComponent } from './entities/trait/trait-table/trait-table.component';
import { VendorViewV2Component } from './cms-v2/entities/vendor/components/vendor-view-v2/vendor-view-v2.component';
import { FormCreatorComponent } from './form-creator/form-creator.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ArtComponent } from './tools/art/art.component';
import { CategoryCollectionViewV2Component } from './cms-v2/entities/category-collection/category-collection-view-v2/category-collection-view-v2.component';
import { ReleaseFormComponent } from './entities/release/release-form/release-form.component';
import { ReleaseTableComponent } from './entities/release/release-table/release-table.component';
import { GlobalSearchComponent } from './common/components/global-search/global-search.component';
import { DropdownMultiselectComponent } from './common/components/dropdown-multiselect/dropdown-multiselect.component';
import { NestedEconTableComponent } from './common/components/nested-econ-table/nested-econ-table.component';
import { TimelineModule } from 'primeng/timeline';
import { TreeModule } from 'primeng/tree';
import { AdvancedSearchComponent } from './advanced-search/components/advanced-search/advanced-search.component';
import { BuildNodesSettingsComponent } from './entities/settings/components/build-nodes-settings/build-nodes-settings.component';
import { CategoryFormV2Component } from './cms-v2/entities/category/components/category-form-v2/category-form-v2.component';
import { EconTableComponent } from './entities/econ-table/econ-table.component';
import { BulkBuildDataComponent } from './build-data/components/bulk-build-data/bulk-build-data.component';
import { BulkBuildDataTableComponent } from './build-data/components/bulk-build-data/table/bulk-build-data-table/bulk-build-data-table.component';
import { EditorModule } from 'primeng/editor';
import { Safe } from './common/pipes/safeHtml.pipe';
import { SourcingChallengeGroupFormComponent } from './cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-form/sourcing-challenge-group-form.component';
import { SourcingChallengeGroupViewComponent } from './cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-view/sourcing-challenge-group-view.component';
import { DynamicFormV2Component } from './common/components/dynamic-form-v2/dynamic-form-v2.component';
import { ColorFormV2Component } from './cms-v2/entities/color/components/color-form-v2/color-form-v2.component';
import { BaseDynamicFormComponent } from './common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { BatchFormV3Component } from './cms-v2/entities/batch/components/batch-form-v3/batch-form-v3.component';
import { ShapeFormV2Component } from './cms-v2/entities/shape/components/shape-form-v2/shape-form-v2.component';
import { ItemTypeFormV3Component } from './cms-v2/entities/item-type/components/item-type-form-v3/item-type-form-v3.component';
import { TraitFormV3Component } from './cms-v2/entities/traits/components/trait-form-v3/trait-form-v3.component';
import { StyleFormV3Component } from './cms-v2/entities/style/components/style-form-v3/style-form-v3.component';
import { TitlesFormV2Component } from './cms-v2/entities/titles/components/titles-form-v2/titles-form-v2.component';
import { MaterialFormV2Component } from './cms-v2/entities/material/components/material-form-v2/material-form-v2.component';
import { IconsViewComponent } from './cms-v2/entities/category/components/icons-view/icons-view.component';
import { InboxMessageFormV3Component } from './cms-v2/entities/inbox-message/components/inbox-message-form-v3/inbox-message-form-v3.component';
import { SeasonPassViewComponent } from './entities/season-pass/season-pass-view.component';
import { DailyRewardsFormV3Component } from './cms-v2/entities/daily-rewards/components/daily-rewards-form-v3/daily-rewards-form-v3.component';
import { DailyRewardsViewComponent } from './cms-v2/entities/daily-rewards/components/daily-rewards-view/daily-rewards-view.component';
import { DataViewModule } from 'primeng/dataview'
import { SeasonPassFormV3Component } from './cms-v2/entities/season-pass/components/season-pass-form-v3/season-pass-form-v3.component';
import { SeasonPassTracksFieldComponent } from './cms-v2/entities/season-pass/components/season-pass-tracks-field/season-pass-tracks-field.component';
import { RewardsTrackFormV3Component } from './cms-v2/entities/rewards-track/components/rewards-track-form-v3/rewards-track-form-v3.component';
import { RewardsTrackDynamicFieldComponent } from './cms-v2/entities/rewards-track/components/rewards-track-dynamic-field/rewards-track-dynamic-field.component';
import { RewardsTrackViewComponent } from './cms-v2/entities/rewards-track/components/rewards-track-view/rewards-track-view.component';
import { VendorFormV3Component } from './cms-v2/entities/vendor/components/vendor-form-v3/vendor-form-v3.component';
import { ChallengeTypeFormV3Component } from './cms-v2/entities/challenge-type/components/challenge-type-form-v3/challenge-type-form-v3.component';
import { RestrictionFormV3Component } from './cms-v2/entities/restriction/components/restriction-form-v3/restriction-form-v3.component';
import { RestrictionFormFieldComponent } from './cms-v2/entities/restriction/components/restriction-form-fields/restriction-form-field.component';
import { DuplicateFromRecordComponent } from './common/components/dynamic-table/sub-components/duplicate-from-record/duplicate-from-record.component';
import { ClimateFormV2Component } from './cms-v2/entities/climate/components/climate-form-v2/climate-form-v2.component';
import { CategoryCollectionFormV3Component } from './cms-v2/entities/category-collection/category-collection-form-v3/category-collection-form-v3.component';
import { AchievementsCollectionFormV3Component } from './cms-v2/entities/achievements-collection/achievements-collection-form-v3/achievements-collection-form-v3.component';
import { CollectionFormV3Component } from './cms-v2/entities/collection/components/collection-form-v3/collection-form-v3.component';
import { CollectionCustomFieldComponent } from './cms-v2/entities/collection/components/collection-custom-field/collection-custom-field.component';
import { DailyRewardsDynamicFieldComponent } from './cms-v2/entities/daily-rewards/daily-rewards-dynamic-field/daily-rewards-dynamic-field.component';
import { SeasonPassFormTracksCustomFieldComponent } from './cms-v2/entities/season-pass/components/season-pass-form-tracks-custom-field/season-pass-form-tracks-custom-field.component';
import { CurrencyFormV3Component } from './cms-v2/entities/currency/components/currency-form-v3/currency-form-v3.component';
import { LoadingScreenFormV3Component } from './cms-v2/entities/loading-screen/loading-screen-form-v3/loading-screen-form-v3.component';
import { LoadingScreenImagesComponent } from './cms-v2/entities/loading-screen/components/loading-screen-images/loading-screen-images.component';
import { ProgressionLevelFormV3Component } from './cms-v2/entities/progression-level/components/progression-level-form-v3/progression-level-form-v3.component';
import { AssetVersionTableComponent } from './entities/asset-version-table/asset-version-table.component';
import { ColorViewV2Component } from './cms-v2/entities/color/components/color-view-v2/color-view-v2.component';
import { DynamicViewV2 } from './common/components/dynamic-view-v2/dynamic-view-v2.component';
import { PlasticFilesAutocompleteComponent } from './common/components/plastic-files-autocomplete/plastic-files-autocomplete.component';
import { MaterialViewV2Component } from './entities/material/material-view-v2/material-view-v2.component';
import { PatternViewV2Component } from './entities/pattern/pattern-view-v2/pattern-view-v2.component';
import { ShapeViewV2Component } from './entities/shape/shape-view-v2/shape-view-v2.component';
import { StyleViewV2Component } from './entities/style/style-view-v2/style-view-v2.component';
import { TraitViewV2Component } from './entities/trait/trait-view-v2/trait-view-v2.component';
import { AssetFormV3Component } from './common/components/assets/asset-form/asset-form-v3.component';
import { AssetViewV2Component } from './common/components/assets/asset-view/asset-view-v2.component';
import { TagsManagedListComponent } from './entities/settings/components/managed-lists/tags-managed-list/tags-managed-list.component';
import { ContentHoldManagedListComponent } from './entities/settings/components/managed-lists/content-hold-managed-list/content-hold-managed-list.component';
import { ChallengeContentHoldManagedListComponent } from './entities/settings/components/managed-lists/challenge-content-hold-managed-list/challenge-content-hold-managed-list.component';
import { NurtureFormV3Component } from './cms-v2/entities/nurture/components/nurture-form-v3/nurture-form-v3.component';
import { NurtureCustomFieldComponent } from './cms-v2/entities/nurture/components/nurture-stage-custom-field/nurture-stage-custom-field.component';
import { NurtureCardFormComponent } from './cms-v2/entities/nurture/nurture-card/nurture-card-form/nurture-card-form.component';
import { NurtureCardTableComponent } from './cms-v2/entities/nurture/nurture-card/nurture-card-table/nurture-card-table.component';
import { NurtureCardViewComponent } from './cms-v2/entities/nurture/nurture-card/nurture-card-view/nurture-card-view.component';
import { NurtureRarityFormComponent } from './cms-v2/entities/nurture/nurture-rarity/nurture-rarity-form/nurture-rarity-form.component';
import { NurtureRarityViewComponent } from './cms-v2/entities/nurture/nurture-rarity/nurture-rarity-view/nurture-rarity-view.component';
// Nurture Collection
import { NurtureCollectionFormComponent } from './cms-v2/entities/nurture/nurture-collection/nurture-collection-form/nurture-collection-form.component';
import { NurtureCollectionViewComponent } from './cms-v2/entities/nurture/nurture-collection/nurture-collection-view/nurture-collection-view.component';
// Seed Pack
import { SeedPackViewComponent } from './cms-v2/entities/nurture/seed-pack/seed-pack-view/seed-pack-view.component';
import { SeedPackFormComponent } from './cms-v2/entities/nurture/seed-pack/seed-pack-form/seed-pack-form.component';
import { SeedPackTableAgGridComponent } from './cms-v2/entities/nurture/seed-pack/seed-pack-table-ag-grid/seed-pack-table-ag-grid.component';
// Seed Pack Box
import { SeedPackBoxViewComponent } from './cms-v2/entities/nurture/seed-pack-box/seed-pack-box-view/seed-pack-box-view.component';
import { SeedPackBoxFormComponent } from './cms-v2/entities/nurture/seed-pack-box/seed-pack-box-form/seed-pack-box-form.component';
import { SeedPackBoxTableAgGridComponent } from './cms-v2/entities/nurture/seed-pack-box/seed-pack-box-table-ag-grid/seed-pack-box-table-ag-grid.component';
// Seed Pack Box Type
import { SeedPackBoxTypeViewComponent } from './cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-view/seed-pack-box-type-view.component';
import { SeedPackBoxTypeFormComponent } from './cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-type-form/seed-pack-box-type-form.component';
import { SeedPackBoxTypeTableAgGridComponent } from './cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-type-table-ag-grid/seed-pack-box-type-table-ag-grid.component';
// STORE
// Price Points
import { PricePointViewComponent } from './cms-v2/store/price-point/price-point-view/price-point-view.component';
import { PricePointFormComponent } from './cms-v2/store/price-point/price-point-form/price-point-form.component';
import { PricePointTableAgGridComponent } from './cms-v2/store/price-point/price-point-table-ag-grid/price-point-table-ag-grid.component';
// Store Listings
import { StoreListingV2ViewComponent } from './cms-v2/store/store-listing-v2/store-listing-v2-view/store-listing-v2-view.component';
import { StoreListingV2FormComponent } from './cms-v2/store/store-listing-v2/store-listing-v2-form/store-listing-v2-form.component';
import { StoreListingV2TableAgGridComponent } from './cms-v2/store/store-listing-v2/store-listing-v2-table-ag-grid/store-listing-v2-table-ag-grid.component';
//
import { RenderPreviewToolComponent } from './tools/art/render-preview-tool/render-preview-tool.component';
import { NufCollectionViewComponent } from './entities/nuf-collection/nuf-collection-view/nuf-collection-view.component';
import { CreateNewAssetComponent } from './common/components/dynamic-table/sub-components/create-new-asset/create-new-asset.component';
import { BulkRenderAssetsComponent } from './common/components/dynamic-table/sub-components/bulk-render-assets/bulk-render-assets.component';
import { BuildHistoryChartComponent } from './build-data/components/build-history-chart/build-history-chart.component';
import { ReleaseTypeFormV2Component } from './cms-v2/entities/release-type/components/release-type-form-v2/release-type-form-v2.component';
import { ReleaseTypeViewV2Component } from './cms-v2/entities/release-type/components/release-type-view-v2/release-type-view-v2.component';
import { ExternalPlantDataViewV2Component } from './cms-v2/entities/external-plant-data/components/external-plant-data-view-v2/external-plant-data-view-v2.component';
import { ReleaseTypeCustomFieldComponent } from './cms-v2/entities/release-type/components/release-type-custom-field/release-type-custom-field.component';
import { ReleaseViewV2Component } from './entities/release/release-view-v2/release-viewV2.component';
import { ReleaseCustomFieldComponent } from './entities/release/release-custom-fields/release-custom-field.component';
import { BuildHistoryComponent } from './common/components/build-history/build-history.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AssetPopupFormComponent } from './common/components/asset-popup-form/asset-popup-form.component';
import { DynamicListComponent } from './dynamic-list/dynamic-list.component';
import { ItemViewV2Component } from './entities/item/item-view-v2/item-view-v2.component';
import { ItemFormV2Component } from './entities/item/item-form-v2/item-form-v2.component';
import { ChallengeViewV2Component } from './entities/challenge/challenge-view-v2/challenge-view.component';
import { RenderGalleryComponent } from './common/components/render-gallery/render-gallery.component';
import { AssetRefInputComponent } from './common/components/asset-ref-input/asset-ref-input.component';
import { NurtureItemsInputComponentComponent } from './common/components/nurture-items-input-component/nurture-items-input-component.component';
import { NurtureViewV3Component } from './cms-v2/entities/nurture/components/nurture-view-v3/nurture-view-v3.component';
import { NurtureStagesInputComponent } from './common/components/nurture-stages-input/nurture-stages-input.component';
import { NurtureStageViewComponent } from './cms-v2/entities/nurture/nurture-stage/nurture-stage-view/nurture-stage-view.component';
import { HistoryComponent } from './common/components/history/history.component';
import { NurtureStageFormComponent } from './cms-v2/entities/nurture/nurture-stage/nurture-stage-form/nurture-stage-form.component';
import { ReleaseFormOriginalComponent } from './entities/release-original/release-form/release-form.component';
import { ItemKeywordsComponent } from './entities/item/item-keywords/item-keywords.component';
import { AudioCollectionFormV3Component } from './cms-v2/entities/audio-collection/audio-collection-form-v3/audio-collection-form-v3.component';
import { AudioCollectionViewComponent } from './cms-v2/entities/audio-collection/audio-collection-view/audio-collection-view.component';
import { AgGridModule } from 'ag-grid-angular';
import { AgGridTableComponent } from './common/ag-grid-table/ag-grid-table.component';
import { IncludeExcludeFilterComponent } from './common/ag-grid-table/filters/include-exclude-filter.component';
import { ItemTableAgGridComponent } from './cms-v2/entities/item/components/item-table-ag-grid/item-table-ag-grid.component';
import { ThumbnailCellRendererComponent } from './common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { LinkCellRendererComponent } from './common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { RefsCellRendererComponent } from './common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { BuildStatusCellRendererComponent } from './common/ag-grid-table/cell-renderers/render-components/build-status/build-status.component';
import { ChallengeTableAgGridComponent } from './cms-v2/entities/challenge/components/challenge-table-ag-grid/challenge-table-ag-grid.component';
import { FlashBackChallengeTableAgGridComponent } from './cms-v2/entities/challenge/components/flashback-challenge-table-ag-grid/challenge-table-ag-grid.component';
import { AsyncTextCellRendererComponent } from './common/ag-grid-table/cell-renderers/render-components/async-text/async-text.component';
import { LineItemCellRendererComponent } from './common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { EOTrackCellRenderer } from './common/ag-grid-table/cell-renderers/render-components/eo-track/eo-track.component';
import { AssetBundleTableAgGridComponent } from './cms-v2/entities/asset-bundle-table-ag-grid/asset-bundle-table-ag-grid.component';
import { MiscAssetCellRendererComponent } from './common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { ColumnPresetsComponent } from './common/ag-grid-table/sidebar-components/column-presets/column-presets.component';
import { CmsFiltersComponent } from './common/ag-grid-table/sidebar-components/cms-filters/cms-filters.component';
import { RewardsTrackTableAgGridComponent } from './cms-v2/entities/rewards-track/components/rewards-track-table-ag-grid/rewards-track-table-ag-grid.component';
import { CalendarEditorComponent } from './common/ag-grid-table/cell-editors/calendar/calendar.component';
import { BooleanEditorComponent } from './common/ag-grid-table/cell-editors/boolean/boolean.component';
import { DropdownEditorComponent } from './common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MultiselectEditorComponent } from './common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { SeasonPassTableAgGridComponent } from './cms-v2/entities/season-pass/components/season-pass-table-ag-grid/season-pass-table-ag-grid.component';
import { DailyRewardsTableAgGridComponent } from './cms-v2/entities/daily-rewards/daily-rewards-table-ag-grid/daily-rewards-table-ag-grid.component';
import { LineItemEditorComponent } from './common/ag-grid-table/cell-editors/line-item/line-item.component';
import { RichTextEditorComponent } from './common/ag-grid-table/cell-editors/rich-text/rich-text.component';
import { LinkCellEditorComponent } from './common/ag-grid-table/cell-editors/link/link.component';
import { AgTableActionsComponent } from './common/ag-grid-table/ag-grid-actions/ag-grid-actions.component';
import { MainStatusPanelComponent } from './common/ag-grid-table/ag-grid-status-panels/main-panel/main-status-panel.component';
import { BulkEditInputComponent } from './common/ag-grid-table/ag-grid-actions/bulk-edit-input/bulk-edit-input.component';
import { CollectionTableAgGridComponent } from './cms-v2/entities/collection/components/collection-table-ag-grid/collection-table-ag-grid.component';
import { EndlessOfferTableAgGridComponent } from './cms-v2/entities/endless-offer/components/table/endless-offer-table-ag-grid.component';
import { EndlessOfferFormComponent } from './cms-v2/entities/endless-offer/components/form/endless-offer-form-v3.component';
import { EndlessOfferViewComponent } from './cms-v2/entities/endless-offer/components/view/endless-offer-view.component';
import { EORewardsTrackDynamicFieldComponent } from './cms-v2/entities/endless-offer-track/components/eo-rewards-track-dynamic-field/eo-rewards-track-dynamic-field.component';
import { EndlessOfferTrackComponent } from './common/components/dynamic-view-v2/components/endless-offer-track/endless-offer-track/endless-offer-track.component';
import { EndlessOfferTrackComponentV2 } from './common/components/dynamic-view-v2/components/endless-offer-track/endless-offer-track-v2/endless-offer-track-v2.component';
import { LoadingScreenTableAgGridComponent } from './cms-v2/entities/loading-screen/loading-screen-table-ag-grid/loading-screen-table-ag-grid.component';
import { VendorTableAgGridComponent } from './cms-v2/entities/vendor/components/vendor-table-ag-grid/vendor-table-ag-grid.component';
import { ColorTableAgGridComponent } from './cms-v2/entities/color/components/color-table-ag-grid/color-table-ag-grid.component';
import { MaterialTableAgGridComponent } from './cms-v2/entities/material/components/material-table-ag-grid/material-table-ag-grid.component';
import { PatternTableAgGridComponent } from './cms-v2/entities/pattern/components/pattern-table-ag-grid/pattern-table-ag-grid.component';
import { ShapeTableAgGridComponent } from './cms-v2/entities/shape/components/shape-table-ag-grid/shape-table-ag-grid.component';
import { StyleTableAgGridComponent } from './cms-v2/entities/style/components/style-table-ag-grid/style-table-ag-grid.component';
import { TraitsTableAgGridComponent } from './cms-v2/entities/traits/components/traits-table-ag-grid/traits-table-ag-grid.component';
import { ExternalPlantDataTableAgGridComponent } from './cms-v2/entities/external-plant-data/components/external-plant-data-table-ag-grid/external-plant-data-table-ag-grid.component';
import { AchievementTableAgGridComponent } from './cms-v2/entities/achievement/components/achievement-table-ag-grid/achievement-table-ag-grid.component';
import { ItemFileTypeTableAgGridComponent } from './cms-v2/entities/item-file-type/components/item-file-type-table-ag-grid/item-file-type-table-ag-grid.component';
import { ColorTextComponent } from './common/ag-grid-table/cell-editors/color-text/color-text.component';
import { CategoryTableAgGridComponent } from './cms-v2/entities/category/components/category-table-ag-grid/category-table-ag-grid.component';
import { CurrencyTableAgGridComponent } from './cms-v2/entities/currency/components/entity-table-ag-grid/currency-table-ag-grid.component';
import { ReleasesAgGridComponent } from './entities/release/releases-ag-grid/releases-ag-grid.component';
import { NurtureCollectionTableAgGridComponent } from './cms-v2/entities/nurture/nurture-collection/nurture-collection-table-ag-grid/nurture-collection-table-ag-grid.component';
import { TitlesAgGridComponent } from './cms-v2/entities/titles/components/titles-ag-grid/titles-table-ag-grid.component';
import { CategoryCollectionTableAgGridComponent } from './cms-v2/entities/category-collection/category-collection-table-ag-grid/category-collection-table-ag-grid.component';
import { ReleaseTypeAgGridComponent } from './cms-v2/entities/release-type/components/release-type-ag-grid/release-type-ag-grid.component';
import { SourcingGroupTableAgGridComponent } from './cms-v2/entities/sourcing-group/sourcing-group-table-ag-grid/sourcing-group-table-ag-grid.component';
import { MiscImageTableAgGridComponent } from './entities/misc-image/misc-image-table-ag-grid/misc-image-table-ag-grid.component';
import { InboxMessageTableAgGridComponent } from './cms-v2/entities/inbox-message/components/inbox-message-table-ag-grid/inbox-message-table-ag-grid.component';
import { NurtureRarityTableAgGridComponent } from './cms-v2/entities/nurture/nurture-rarity/nurture-rarity-table-ag-grid/nurture-rarity-table-ag-grid.component';
import { BatchesAgGridComponent } from './cms-v2/entities/batch/components/batches-table-ag-grid/batches-table-ag-grid.component';
import { ItemTypeAgGridComponent } from './cms-v2/entities/item-type/components/item-type-table-ag-grid/item-type-table-ag-grid.component';
import { ProgressionLevelTableAgGridComponent } from './entities/progression-level/progression-level-table-ag-grid/progression-level-table-ag-grid.component';
import { NurtureStageTableAgGridComponent } from './cms-v2/entities/nurture/nurture-stage/nurture-stage-table-ag-grid/nurture-stage-table-ag-grid.component';
import { ChallengeTypeTableAgGridComponent } from './cms-v2/entities/challenge-type/components/challenge-type-table-ag-grid/challenge-type-table-ag-grid.component';
import { AudioCollectionTableAgGridComponent } from './cms-v2/entities/audio-collection/audio-collection-table-ag-grid/audio-collection-table-ag-grid.component';
import { AchievementCollectionTableAgGridComponent } from './cms-v2/entities/achievements-collection/achievement-collection-table-ag-grid/achievement-collection-table-ag-grid.component';
import { ClimateTableAgGridComponent } from './cms-v2/entities/climate/components/climate-table-v2/climate-table-ag-grid/climate-table-ag-grid.component';
import { RestrictionTableAgGridComponent } from './cms-v2/entities/restriction/components/entity-table-ag-grid/restriction-table-ag-grid.component';
import { NurtureTableAgGridComponent } from './cms-v2/entities/nurture/components/nurture-table-ag-grid/nurture-table-ag-grid.component';
import { NurtureCellRenderer } from './common/ag-grid-table/cell-renderers/render-components/nurture/nurture.component';
import { ResourceTableAgGridComponent } from './cms-v2/entities/resource/components/resource-table-ag-grid/resource-table-ag-grid.component';
import { CoOpFormV3Component } from './cms-v2/entities/co-op/components/co-op-form-v3/co-op-form-v3.component';
import { SourcingChallengeGroupTableAgGridComponent } from './cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-table-ag-grid/sourcing-challenge-group-table-ag-grid.component';
import { TimezoneInterceptorProvider } from './auth/interceptor/timezone.interceptor';
import { OfficeTimePipe } from './common/pipes/officeTime.pipe';
import { ConvertToReadablePipe } from './common/pipes/convert-to-readable';
import { InterceptorService } from './services/interceptor.service';
import { MiscImageRecordV2Component } from './common/components/dynamic-view-v2/components/misc-image-record-v2/misc-image-record-v2.component';
import { ColorPickerFieldComponent } from './common/components/dynamic-view-v2/components/color-picker-field/color-picker-field.component';
import { NurtureStagesFieldComponent } from './common/components/dynamic-view-v2/components/nurture-stages-field/nurture-stages-field.component';
import { InputPriceComponent } from './common/components/dynamic-view-v2/components/input-price/input-price.component';
import { MiscRefComponent } from './common/components/dynamic-view-v2/components/misc-ref/misc-ref.component';
import { ThumbnailStyleComponent } from './common/components/dynamic-view-v2/components/thumbnail-style/thumbnail-style.component';
import { DisplayThumbnailComponent } from './common/components/dynamic-view-v2/components/display-thumbnail/display-thumbnail.component';
import { DisplayBuildStatusComponent } from './common/components/dynamic-view-v2/components/display-build-status/display-build-status.component';
import { AutocompleteComponent } from './common/components/dynamic-view-v2/components/autocomplete/autocomplete.component';
import { RenderThumbnailsComponent } from './common/components/dynamic-view-v2/components/render-thumbnails/render-thumbnails.component';
import { BuildStatusComponent } from './common/components/dynamic-view-v2/components/build-status/build-status.component';
import { MiscImageBuildComponentComponent } from './common/components/dynamic-view-v2/components/misc-image-build-component/misc-image-build-component.component';
import { CollectionTableViewAgGridComponent } from './cms-v2/entities/collection/components/collection-view-ag-grid/collection-table-view-ag-grid.component';
import { NestedInputComponent } from './common/components/nested-input/nested-input.component';
import { GoalImageComponentComponent } from './common/components/goal-image-component/goal-image-component.component';
import { CoOpTableAgGridComponent } from './cms-v2/entities/co-op/components/co-op-table-ag-grid/co-op-table-ag-grid.component';
import { CoOpViewComponent } from './cms-v2/entities/co-op/components/co-op-view/co-op-view.component';
import { SideThumbnailComponent } from './common/components/dynamic-view-v2/components/side-thumbnail/side-thumbnail.component';
// import { StoreListingTableAgGridComponent } from './cms-v2/entities/store-listing/components/store-listing-table-ag-grid/store-listing-table-ag-grid.component';
import { StoreListingFormV2Component } from './cms-v2/entities/store-listing/components/store-listing-form-v2/store-listing-form-v2.component';
import { StoreListingViewV2Component } from './cms-v2/entities/store-listing/components/store-listing-view-v2/store-listing-view-v2.component';
import { OfficeTimeFormPipe } from './common/pipes/officeTimeForm.pipe';
import { GoalImageryComponent } from './common/components/dynamic-view-v2/components/goal-imagery/goal-imagery.component';
import { NestedInputViewComponent } from './common/components/dynamic-view-v2/components/nested-input-view/nested-input-view.component';
import { BundleAssetViewComponent } from './entities/bundle-asset-view/bundle-asset-view.component';
import { BundleViewTableComponent } from './entities/bundle-asset-view/bundle-view-table/bundle-view-table.component';
import { NufCollectionTableAgGridComponent } from './entities/nuf-collection/nuf-collection-table-ag-grid/nuf-collection-table-ag-grid.component';
import { ReadonlyFieldComponent } from './common/components/readonly-field/readonly-field.component';
import { BaseInputFieldComponent2 } from './common/components/dynamic-form-v2/components/base-input-field/base-input-field.component';
import { BaseInputFieldComponent } from './common/components/base-fields/input-field/base-input-field.component';
import { EmbeddedFormComponent } from './common/components/dynamic-form-v2/components/embedded-form/embedded-form.component';
import { BooleanViewComponent } from './common/components/dynamic-view-v2/components/Boolean/boolean.component';
import { FilterRulesComponent } from './common/components/dynamic-view-v2/components/filter-rules/filter-rules.component';
import { ProgressionLevelTableViewAgGridComponent } from './cms-v2/entities/progression-level/components/progression-level-table-v2/progression-level-view-v2/progression-level-table-view-ag-grid.component';
import { AgGridTableViewComponent } from './common/components/dynamic-view-v2/components/ag-grid-table-view/ag-grid-table-view.component';
import { GridViewComponent } from './common/components/grid-view/grid-view.component';
import { BaseViewFieldComponent } from './common/components/base-view-field/base-view-field.component';
import { GridViewSettingsComponent } from './common/ag-grid-table/sidebar-components/grid-view-settings/grid-view-settings.component';
import { CardComponent } from './common/components/grid-view/card-component/card-component';
import { CategoryIconComponent } from './cms-v2/entities/category/components/category-icon/category-icon.component';
import { CategoryClimateIconComponent } from './cms-v2/entities/category/components/category-climate-icon/category-climate-icon.component';
import { MiscImageArrayBuild } from './common/components/dynamic-view-v2/components/misc-image-array-build/misc-image-array-build.component';
import { StoreTransferComponent } from './cms-v2/entities/store-listing/components/store-transfer/store-transfer.component';
import { DataComponentComponent } from './cms-v2/entities/store-listing/components/store-transfer/data-component/data-component.component';
import { StoreSyncTableAgGridComponent } from './cms-v2/store/store-sync/store-sync-table-ag-grid/store-sync-table-ag-grid.component';
import { Storesyncv2viewComponent } from './cms-v2/store/storesyncv2view/storesyncv2view/storesyncv2view.component';
import { EnvironmentTogglerComponent } from './common/components/environment-toggler/environment-toggler.component';
import { StoregamedataviewComponent } from './cms-v2/store/store-game-data/storegamedataview/storegamedataview.component';
import { StoreTransferTableAgGridComponent } from './cms-v2/store/store-transfer/store-transfer-table-ag-grid/store-transfer-table-ag-grid.component';
import { StoreTransferV2ViewComponent } from './cms-v2/store/store-transfer-v2-view/store-transfer-v2-view.component';
import { ThumbnailComponent } from './common/components/thumbnail-component/thumbnail.component';
import { AchievementViewV2Component } from './entities/achievement/achievement-view-v2/achievement-view-v2.component';
import { TableInputComponent } from './common/components/dynamic-form-v2/components/table-input/table-input.component';
import { LocalizationConfigFormComponent } from './cms-v2/entities/localization/form/localization-config-form-component/localization-config-form-component';
import { LineBreakPipe } from './line-break.pipe';
import { LocalizationConfigurationViewComponent } from './cms-v2/entities/localization/localization-config-view/localization-configuration-view/localization-configuration-view.component';
import { LocalizationConfigurationTableComponent } from './cms-v2/entities/localization/localization-config-view/localization-configuration-view/localization-configuration-table/localization-configuration-table/localization-configuration-table.component';
import { DupDialogComponent } from './common/components/dup-dialog/dup-dialog.component';
import { SideMenuComponent } from './common/ag-grid-table/sidebar-components/side-menu/side-menu.component';
// ITEM SETS
import { ItemSetTableAgGridComponent } from './cms-v2/entities/item-set/item-set-table-ag-grid/item-set-table-ag-grid.component';
import { ItemSetFormV2Component } from './cms-v2/entities/item-set/item-set-form-v2/item-set-form-v2.component';
import { ItemSetViewV2Component } from './cms-v2/entities/item-set/item-set-view-v2/item-set-view-v2.component';
import { CustomactionbuttonComponent } from './common/components/customactionbutton/customactionbutton.component';
// import { AchievementFormV3Component } from './entities/achievement/achievement-form-v3/achievement-form-v3.component';
// SPONSORS
import { SponsorTableAgGridComponent } from './cms-v2/sponsor/sponsor-table-ag-grid/sponsor-table-ag-grid.component';
import { SponsorFormV2Component } from './cms-v2/sponsor/sponsor-form-v2/sponsor-form-v2.component';
import { SponsorViewV2Component } from './cms-v2/sponsor/sponsor-view-v2/sponsor-view-v2.component';
import { ItemSetGeneratorPopupComponent } from './common/components/dynamic-form-v2/components/item-set-generator-popup/item-set-generator-popup.component';
import { LocalizedStringsTableAgGridComponent } from './cms-v2/entities/localized-strings/components/localized-strings/localized-strings-ag-grid.component';
import { StringPopupFormComponent } from './common/components/string-popup-form/string-popup-form.component';
import { InGamePreviewComponent } from './common/components/dynamic-form-v2/components/in-game-preview/in-game-preview.component';
import { InsertImagePipe } from './common/pipes/insert-image.pipe';
import { ReplaceNewlinesPipe } from './common/pipes/replace-newlines.pipe';
import { BuildGamedataV3Component } from './build-gamedata/build-gamedata-v3/build-gamedata-v3/build-gamedata-v3.component';
import { GameDataLogsPopupComponent } from './build-data/gamedata-logs-popup/game-data-logs-popup/game-data-logs-popup.component';
import { BuildGamedataV3ViewComponent } from './build-gamedata/build-gamedata-v3-view/build-gamedata-v3-view.component';
import { EntityDisplayComponent } from './entities/item/entity-display/entity-display.component';
import { FieldDisplayComponent } from './entities/item/field-display/field-display.component';
import { MainStatusDisplayComponent } from './entities/item/main-status-display/main-status-display.component';
import { SecondaryStatusDisplayComponent } from './entities/item/secondary-status-display/secondary-status-display.component';
import { ImageDialogComponent } from './entities/item/image-dialog/image-dialog.component';
import { PlantCardComponent } from './entities/item/plant-card/plant-card.component';
import { ClientStringsTableAgGridComponent } from './cms-v2/entities/client-strings/components/client-strings/client-strings-ag-grid.component';
import { EnvironmentBuildStatusComponent } from './entities/challenge/environment-build-status/environment-build-status.component';
import { ChallengeHistoryComponent } from './entities/challenge/challenge-history/challenge-history.component';
import { LocalizedTableComponent } from './entities/challenge/localized-table/localized-table.component';
import { DuplicationDialogComponent } from './entities/challenge/duplication-dialog/duplication-dialog.component';
import { InitialsDialogComponent } from './entities/challenge/initials-dialog/initials-dialog.component';
import { StatusFieldGroupComponent } from './entities/challenge/status-field-group/status-field-group.component';
import { EntityHeaderComponent } from './entities/challenge/entity-header/entity-header.component';
import { ChallengeImagePopupComponent } from './entities/challenge/challenge-image-popup/challenge-image-popup.component';
// mini games
import { MiniGameFormV2Component } from './cms-v2/entities/mini-game/components/mini-game-form-v2/mini-game-form-v2.component';
import { MiniGameTableAgGridComponent } from './cms-v2/entities/mini-game/components/mini-game-table-ag-grid/mini-game-table-ag-grid.component';
import { MiniGameViewV2Component } from './cms-v2/entities/mini-game/components/mini-game-view-v2/mini-game-view-v2.component';
import { ExpandObjectPipe } from './common/pipes/expand-object.pipe';
import { ChallengeCollectionTableAgGridComponent } from './cms-v2/entities/challenge-collection/components/challenge-collection-table-ag-grid/challenge-collection-table-ag-grid.component';
import { ChallengeCollectionFormV3Component } from './cms-v2/entities/challenge-collection/components/challenge-collection-form-v3/challenge-collection-form-v3.component';
import { ChallengeCollectionCustomFieldComponent } from './cms-v2/entities/challenge-collection/components/challenge-collection-custom-field/challenge-collection-custom-field.component';
import { ChallengeCollectionTableViewAgGridComponent } from './cms-v2/entities/challenge-collection/components/challenge-collection-view-ag-grid/challenge-collection-table-view-ag-grid.component';
import { KeywordsViewV2Component } from './cms-v2/entities/keywords/keywords-view-v2/keywords-view-v2.component';
import { KeywordsFormV2Component } from './cms-v2/entities/keywords/keywords-form-v2/keywords-form-v2.component';
import { KeywordsTableAgGridComponent } from './cms-v2/entities/keywords/keywords-table-ag-grid/keywords-table-ag-grid.component';
import { DiffToolViewComponent } from './entities/diff-tool-view/diff-tool-view.component';
import { JobRecordsTableComponent } from './common/components/job-records-table/job-records-table.component';
import { DiffToolAgAgridComponent } from './entities/diff-tool-ag-agrid/diff-tool-ag-agrid.component';
import { RewardsTrackViewV2Component } from './cms-v2/entities/rewards-track/components/rewards-track-view-v2/rewards-track-view-v2.component';
import { RewardCardComponent } from './cms-v2/entities/rewards-track/components/reward-card/reward-card.component';
import { NurtureCollectionGroupsViewV2Component } from './cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-view-v2/nurture-collection-groups-view-v2.component';
import { NurtureCollectionGroupsAgGridComponent } from './cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-ag-grid/nurture-collection-groups-ag-grid/nurture-collection-groups-ag-grid.component';
import { NurtureCollectionGroupsFormComponent } from './cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-form/nurture-collection-groups-form/nurture-collection-groups-form.component';
import { ReleasesV2AgGridComponent } from './entities/release/releases-v2-ag-grid/releases-v2-ag-grid.component';
import { SeriesViewV2Component } from './cms-v2/entities/series/components/series-view-v2/series-view-v2.component';
import { SeriesFormV2Component } from './cms-v2/entities/series/components/series-form-v2/series-form-v2/series-form-v2.component';
import { SeriesAgGridComponent } from './cms-v2/entities/series/components/series-ag-grid/series-ag-grid/series-ag-grid.component';
import { ReleaseSeriesViewV2Component } from './entities/release/release-series-view-v2/release-series-view-v2/release-series-view-v2.component';
// infinite series
import { InfiniteSeriesFormComponent } from './cms-v2/entities/series/infinite-series/components/infinite-series-form/infinite-series-form.component';
import { InfiniteSeriesViewComponent } from './cms-v2/entities/series/infinite-series/components/infinite-series-view/infinite-series-view.component';
import { InfiniteSeriesTableAgGridComponent } from './cms-v2/entities/series/infinite-series/components/infinite-series-table-ag-grid/infinite-series-table-ag-grid.component';
import { RoleComponent } from './role/role.component';
import { RoleFormDialogComponent } from './role/role-form-dialog/role-form-dialog.component';
import { PermissionViewDialogComponent } from './role/permission-view-dialog/permission-view-dialog.component';
import { DependentInputComponent } from './common/components/dependent-input/dependent-input.component';
import { UserComponent } from './user/user.component';
import { RoleViewDialogComponent } from './user/role-view-dialog/role-view-dialog.component';
import { PermissionFormDialogComponent } from './permissions/permission-form-dialog/permission-form-dialog.component';
import { TestUserTableAgGridComponent } from './user/test-user-table-ag-grid-old/test-user-table-ag-grid.component';
import { NotesPopupComponent } from './common/components/dynamic-view-v2/components/notes-popup-component/notes-popup.component';
import { ReleaseEndlessOfferViewV2Component } from './entities/release/release-endless-offer/release-endless-offer-view-v2/release-endless-offer-view-v2.component';
import { ReleaseProgressionLevelsV2Component } from './entities/release/release-progression-levels-v2/release-progression-levels-v2.component';
import { ReleaseNurtureCollectionComponent } from './entities/release/release-nurture-collection/release-nurture-collection/release-nurture-collection.component';
import { ReleaseGardenPassComponent } from './entities/release/release-garden-pass/release-garden-pass/release-garden-pass.component';
import { RewardtimelineComponent } from './cms-v2/entities/rewards-track/components/rewardtimeline/rewardtimeline.component';
// Automation Logs
import { AutomationLogTableAgGridComponent } from './cms-v2/entities/automation-log/components/automation-log-table-ag-grid/automation-log-table-ag-grid.component';
import { AutomationLogViewV2Component } from './cms-v2/entities/automation-log/components/automation-log-view-v2/automation-log-view-v2.component';
import { SeasonalPrizesViewV2Component } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-view-v2/seasonal-prizes-view-v2/seasonal-prizes-view-v2.component';
import { SeasonalPrizesFormV2Component } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-form-v2/seasonal-prizes-form-v2/seasonal-prizes-form-v2.component';
import { SeasonalPrizesAgGridComponent } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-ag-grid/seasonal-prizes-ag-grid/seasonal-prizes-ag-grid.component';
import { ProdPushInfoComponent } from './entities/settings/components/prod-push-info/prod-push-info.component';
import { MiscAssetBuildErrorLogsComponent } from './common/components/dynamic-view-v2/components/misc-asset-build-error-logs/misc-asset-build-error-logs.component';
@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LoginComponent,
    HomePageComponent,
    BaseDataFieldComponent,
    BaseFilterFieldComponent,
    BaseInputFieldComponent2,
    BaseInputFieldComponent,
    BaseviewComponent,
    YesNoPipe,
    ThumbPathPipe,
    OfficeTimePipe,
    ConvertToReadablePipe,
    OfficeTimeFormPipe,
    Safe,
    BuildAssetComponent,
    ManagedListComponent,
    DynamicFormComponent,
    DynamicFieldComponent,
    DependentInputComponent,
    FormArrayComponent,
    FormGroupComponent,
    ChallengeFormComponent,
    ItemViewComponent,
    ItemHistoryComponent,
    ItemBuildsHistoryComponent,
    ChallengeBuildsHistoryComponent,
    FormCreatorComponent,
    ChallengeFormComponent,
    ItemViewComponent,
    RefSuffixRemovalPipe,
    FormArrayComponent,
    ResourceFormV2Component,
    CurrencyFormComponent,
    ExternalPlantDataFormV3Component,
    BuildGamedataComponent,
    TraitTableComponent,
    PatternFormV2Component,
    ItemFileTypeFormV2Component,
    InboxMessageViewV2Component,
    ArtComponent,
    ItemTypeViewV2Component,
    itemfiletypeViewV2Component,
    ClimateViewComponent,
    ResourceViewV2Component,
    CurrencyViewV2Component,
    ChallengeTypeViewV2Component,
    VendorViewV2Component,
    BatchViewV2Component,
    CollectionViewComponent,
    RestrictionViewV2Component,
    CategoryViewComponent,
    BuildDataComponent,
    SettingsComponent,
    DynamicTableComponent,
    TableViewComponent,
    TableDataComponent,
    LoadingScreenViewV2Component,
    CollectionTableV2Component,
    FileUplaoderComponent,
    SourcingGroupFormComponent,
    SourcingGroupViewComponent,
    AchievementFormComponent,
    EntityHistoryComponent,
    PermissionsComponent,
    FiltersSidebarComponent,
    RulesComponent,
    GamedataBuildsHistoryComponent,
    GamedataDiffComponent,
    GamedataDiffViewComponent,
    ProdGamedataPushComponent,
    AchievementCollectionViewV2Component,
    TitlesViewV2Component,
    ChallengesKeywordsComponent,
    CollectionTableViewComponent,
    SeriesTableComponent,
    PrizesCardFormGroupComponent,
    SelectMultipleColorsComponent,
    ReleaseFormComponent,
    ProgressionLevelTableViewAgGridComponent,
    ReleaseTableComponent,
    CategoryCollectionViewV2Component,
    GlobalSearchComponent,
    DropdownMultiselectComponent,
    AdvancedSearchComponent,
    BuildNodesSettingsComponent,
    CategoryFormV2Component,
    NestedEconTableComponent,
    EconTableComponent,
    BulkBuildDataComponent,
    BulkBuildDataTableComponent,
    SourcingChallengeGroupFormComponent,
    SourcingChallengeGroupViewComponent,
    DynamicFormV2Component,
    ColorFormV2Component,
    BaseDynamicFormComponent,
    BatchFormV3Component,
    ShapeFormV2Component,
    ItemTypeFormV3Component,
    TraitFormV3Component,
    StyleFormV3Component,
    TitlesFormV2Component,
    MaterialFormV2Component,
    IconsViewComponent,
    InboxMessageFormV3Component,
    DailyRewardsFormV3Component,
    DailyRewardsViewComponent,
    SeasonPassViewComponent,
    DailyRewardsFormV3Component,
    SeasonPassFormV3Component,
    SeasonPassTracksFieldComponent,
    RewardsTrackFormV3Component,
    RewardsTrackDynamicFieldComponent,
    RewardsTrackViewComponent,
    RestrictionFormV3Component,
    RestrictionFormFieldComponent,
    VendorFormV3Component,
    DuplicateFromRecordComponent,
    CategoryCollectionFormV3Component,
    CollectionFormV3Component,
    CollectionCustomFieldComponent,
    ChallengeTypeFormV3Component,
    DailyRewardsDynamicFieldComponent,
    SeasonPassFormTracksCustomFieldComponent,
    CurrencyFormV3Component,
    ChallengeTypeFormV3Component,
    LoadingScreenFormV3Component,
    LoadingScreenImagesComponent,
    ClimateFormV2Component,
    AchievementsCollectionFormV3Component,
    ProgressionLevelFormV3Component,
    AssetVersionTableComponent,
    DynamicViewV2,
    ColorViewV2Component,
    PlasticFilesAutocompleteComponent,
    MaterialViewV2Component,
    PatternViewV2Component,
    ShapeViewV2Component,
    StyleViewV2Component,
    TraitViewV2Component,
    AssetFormV3Component,
    AssetViewV2Component,
    TagsManagedListComponent,
    ContentHoldManagedListComponent,
    ChallengeContentHoldManagedListComponent,
    NurtureFormV3Component,
    NurtureCustomFieldComponent,
    NurtureCardFormComponent,
    NurtureCardTableComponent,
    NurtureCardViewComponent,
    NurtureRarityFormComponent,
    NurtureRarityViewComponent,
    NurtureCollectionFormComponent,
    NurtureCollectionViewComponent,

    SeedPackViewComponent,
    SeedPackFormComponent,
    SeedPackTableAgGridComponent,

    SeedPackBoxViewComponent,
    SeedPackBoxFormComponent,
    SeedPackBoxTableAgGridComponent,

    SeedPackBoxTypeViewComponent,
    SeedPackBoxTypeFormComponent,
    SeedPackBoxTypeTableAgGridComponent,

    PricePointViewComponent,
    PricePointFormComponent,
    PricePointTableAgGridComponent,

    StoreListingV2ViewComponent,
    StoreListingV2FormComponent,
    StoreListingV2TableAgGridComponent,

    RenderPreviewToolComponent,
    NufCollectionViewComponent,
    CreateNewAssetComponent,
    BulkRenderAssetsComponent,
    BuildHistoryChartComponent,
    ReleaseTypeFormV2Component,
    ReleaseTypeViewV2Component,
    ExternalPlantDataViewV2Component,
    ReleaseTypeCustomFieldComponent,
    ReleaseViewV2Component,
    ReleaseCustomFieldComponent,
    BuildHistoryComponent,
    AssetPopupFormComponent,
    DynamicListComponent,
    RenderGalleryComponent,
    AssetRefInputComponent,
    NurtureItemsInputComponentComponent,
    NurtureViewV3Component,
    NurtureStagesInputComponent,
    NurtureStageViewComponent,
    HistoryComponent,
    NurtureStageFormComponent,
    ItemViewV2Component,
    ItemFormV2Component,
    ChallengeViewV2Component,
    RenderGalleryComponent,
    ReleaseFormOriginalComponent,
    ItemKeywordsComponent,
    AudioCollectionFormV3Component,
    AudioCollectionViewComponent,
    EndlessOfferFormComponent,
    EndlessOfferViewComponent,
    EORewardsTrackDynamicFieldComponent,
    StoreListingFormV2Component,
    StoreListingViewV2Component,
    BundleAssetViewComponent,
    BundleViewTableComponent,
    // >> >> AG-GRID SPECIFIC << <<
    AgGridTableComponent,
    // AG-GRID TABLES
    ItemTableAgGridComponent,
    ChallengeTableAgGridComponent,
    FlashBackChallengeTableAgGridComponent,
    DailyRewardsTableAgGridComponent,
    EndlessOfferTableAgGridComponent,
    AchievementTableAgGridComponent,
    CurrencyTableAgGridComponent,
    ReleasesAgGridComponent,
    ReleasesV2AgGridComponent,
    TitlesAgGridComponent,
    ReleaseTypeAgGridComponent,
    SourcingGroupTableAgGridComponent,
    NufCollectionTableAgGridComponent,
    MiscImageTableAgGridComponent,
    AssetBundleTableAgGridComponent,
    BatchesAgGridComponent,
    ItemTypeAgGridComponent,
    ProgressionLevelTableAgGridComponent,
    NurtureStageTableAgGridComponent,
    ChallengeTypeTableAgGridComponent,
    AudioCollectionTableAgGridComponent,
    AchievementCollectionTableAgGridComponent,
    ClimateTableAgGridComponent,
    RestrictionTableAgGridComponent,
    NurtureTableAgGridComponent,
    SourcingChallengeGroupTableAgGridComponent,
    CollectionTableViewAgGridComponent,
    LocalizedStringsTableAgGridComponent,
    ClientStringsTableAgGridComponent,
    // AG-GRID CELL RENDERERS
    ThumbnailCellRendererComponent,
    TextCellRendererComponent,
    AsyncTextCellRendererComponent,
    ActionsCellRendererComponent,
    RefsCellRendererComponent,
    BuildStatusCellRendererComponent,
    LineItemCellRendererComponent,
    MiscAssetCellRendererComponent,
    EOTrackCellRenderer,
    NurtureCellRenderer,
    LinkCellRendererComponent,
    // AG-GRID CUSTOM FILTERS
    IncludeExcludeFilterComponent,
    // AG-GRID CUSTOM SIDEBAR components
    ColumnPresetsComponent,
    CmsFiltersComponent,
    RewardsTrackTableAgGridComponent,
    // AG-GRID CUSTOM CELL EDITORS
    CalendarEditorComponent,
    BooleanEditorComponent,
    DropdownEditorComponent,
    MultiselectEditorComponent,
    SeasonPassTableAgGridComponent,
    DailyRewardsTableAgGridComponent,
    LineItemEditorComponent,
    RichTextEditorComponent,
    LinkCellEditorComponent,
    ColorTextComponent,
    // AG-GRID TABLE ACTIONS
    AgTableActionsComponent,
    // AG-GRID PANEL COMPONENT
    MainStatusPanelComponent,
    BulkEditInputComponent,
    LoadingScreenTableAgGridComponent,
    CollectionTableAgGridComponent,
    EndlessOfferTrackComponent,
    EndlessOfferTrackComponentV2,
    VendorTableAgGridComponent,
    ColorTableAgGridComponent,
    MaterialTableAgGridComponent,
    PatternTableAgGridComponent,
    ShapeTableAgGridComponent,
    StyleTableAgGridComponent,
    TraitsTableAgGridComponent,
    ExternalPlantDataTableAgGridComponent,
    ItemFileTypeTableAgGridComponent,
    CategoryTableAgGridComponent,
    NurtureCollectionTableAgGridComponent,
    InboxMessageTableAgGridComponent,
    CategoryCollectionTableAgGridComponent,
    NurtureRarityTableAgGridComponent,
    CategoryCollectionTableAgGridComponent,
    ResourceTableAgGridComponent,
    MiscImageRecordV2Component,
    ColorPickerFieldComponent,
    NurtureStagesFieldComponent,
    InputPriceComponent,
    MiscRefComponent,
    ThumbnailStyleComponent,
    DisplayThumbnailComponent,
    DisplayBuildStatusComponent,
    AutocompleteComponent,
    RenderThumbnailsComponent,
    BuildStatusComponent,
    MiscImageBuildComponentComponent,
    MiscImageArrayBuild,
    CoOpFormV3Component,
    NestedInputComponent,
    GoalImageComponentComponent,
    CoOpTableAgGridComponent,
    CoOpViewComponent,
    SideThumbnailComponent,
    GoalImageryComponent,
    NestedInputViewComponent,
    ReadonlyFieldComponent,
    EmbeddedFormComponent,
    BooleanViewComponent,
    FilterRulesComponent,
    AgGridTableViewComponent,
    GridViewComponent,
    BaseViewFieldComponent,
    GridViewSettingsComponent,
    CardComponent,
    BaseViewFieldComponent,
    CategoryIconComponent,
    CategoryClimateIconComponent,
    StoreTransferComponent,
    DataComponentComponent,
    StoreSyncTableAgGridComponent,
    Storesyncv2viewComponent,
    EnvironmentTogglerComponent,
    StoregamedataviewComponent,
    StoreTransferTableAgGridComponent,
    StoreTransferV2ViewComponent,
    ThumbnailComponent,
    AchievementViewV2Component,
    TableInputComponent,
    LineBreakPipe,
    LocalizationConfigurationViewComponent,
    LocalizationConfigurationTableComponent,
    DupDialogComponent,
    LocalizationConfigFormComponent,
    LineBreakPipe,
    SideMenuComponent,
    StringPopupFormComponent,
    // ITEM SET
    ItemSetViewV2Component,
    ItemSetFormV2Component,
    ItemSetTableAgGridComponent,
    CustomactionbuttonComponent,
    // AchievementFormV3Component,
    // SPONSORS
    SponsorViewV2Component,
    SponsorFormV2Component,
    SponsorTableAgGridComponent,
    ItemSetGeneratorPopupComponent,
    InGamePreviewComponent,
    InsertImagePipe,
    BuildGamedataV3Component,
    GameDataLogsPopupComponent,
    BuildGamedataV3ViewComponent,
    EntityDisplayComponent,
    FieldDisplayComponent,
    SecondaryStatusDisplayComponent,
    ImageDialogComponent,
    PlantCardComponent,
    EnvironmentBuildStatusComponent,
    ChallengeHistoryComponent,
    LocalizedTableComponent,
    DuplicationDialogComponent,
    InitialsDialogComponent,
    StatusFieldGroupComponent,
    EntityHeaderComponent,
    ChallengeImagePopupComponent,
    MainStatusDisplayComponent,
    // mini games
    MiniGameFormV2Component,
    MiniGameTableAgGridComponent,
    MiniGameViewV2Component,
    ExpandObjectPipe,
    // challenge collection
    ChallengeCollectionTableAgGridComponent,
    ChallengeCollectionFormV3Component,
    ChallengeCollectionCustomFieldComponent,
    ChallengeCollectionTableViewAgGridComponent,
    KeywordsViewV2Component,
    KeywordsFormV2Component,
    KeywordsTableAgGridComponent,
    DiffToolViewComponent,
    JobRecordsTableComponent,
    DiffToolAgAgridComponent,
    RewardsTrackViewV2Component,
    RewardCardComponent,
    NurtureCollectionGroupsViewV2Component,
    NurtureCollectionGroupsAgGridComponent,
    NurtureCollectionGroupsFormComponent,
    // infinite series
    InfiniteSeriesFormComponent,
    InfiniteSeriesViewComponent,
    InfiniteSeriesTableAgGridComponent,
    SeriesViewV2Component,
    SeriesFormV2Component,
    SeriesAgGridComponent,
    ReleaseSeriesViewV2Component,
    RoleComponent,
    RoleFormDialogComponent,
    PermissionViewDialogComponent,
    UserComponent,
    NotesPopupComponent,
    RoleViewDialogComponent,
    PermissionFormDialogComponent,
    TestUserTableAgGridComponent,
    ReleaseEndlessOfferViewV2Component,
    ReleaseProgressionLevelsV2Component,
    ReleaseNurtureCollectionComponent,
    AutomationLogTableAgGridComponent,
    AutomationLogViewV2Component,
    ReleaseGardenPassComponent,
    RewardtimelineComponent,
    SeasonalPrizesViewV2Component,
    SeasonalPrizesFormV2Component,
    SeasonalPrizesAgGridComponent,
    ProdPushInfoComponent,
    MiscAssetBuildErrorLogsComponent,
  ],
  imports: [
    AppRoutingModule,
    AutoCompleteModule,
    AccordionModule,
    BadgeModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CommonModule,
    CalendarModule,
    CardModule,
    ProgressSpinnerModule,
    CheckboxModule,
    ChipModule,
    ColorPickerModule,
    DialogModule,
    GalleriaModule,
    DividerModule,
    DropdownModule,
    FieldsetModule,
    FormsModule,
    HttpClientModule,
    InplaceModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    NgxChartsModule,
    ScrollPanelModule,
    OverlayPanelModule,
    PanelModule,
    PickListModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RippleModule,
    SelectButtonModule,
    SidebarModule,
    SkeletonModule,
    SocialLoginModule,
    TableModule,
    TabViewModule,
    TagModule,
    TriStateCheckboxModule,
    ImageModule,
    ToolbarModule,
    OrderListModule,
    ToastModule,
    NgxChartsModule,
    ToggleButtonModule,
    TooltipModule,
    ChipsModule,
    FileUploadModule,
    RouterModule,
    SocialLoginModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    PasswordModule,
    ProgressBarModule,
    SplitterModule,
    DragDropModule,
    NgxSpinnerModule,
    TimelineModule,
    TreeModule,
    EditorModule,
    DataViewModule,
    LazyLoadImageModule,
    AgGridModule,
    PanelMenuModule
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true, //keeps the user signed in
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '643251371111-jeinp9e8sj1nth7hn38gjqleu6vneqh8.apps.googleusercontent.com', // your client id
              {
                scopes: [
                  'openid',
                  'email',
                  'profile'
                ],
                oneTapEnabled: false
              }
            ),
          },
        ],
        onError: (err) => {
          console.error('SocialAuthServiceConfig ', err);
        }
      } as SocialAuthServiceConfig,
    },
    authTokenInterceptorProvider,
    TimezoneInterceptorProvider,
    AuthGuard,
    DatePipe,
    OfficeTimePipe,
    OfficeTimeFormPipe,
    MessageService,
    InterceptorService,
    Title,
    ConfirmationService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
