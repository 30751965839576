<p-card class="p-d-block p-mx-auto p-px-1 p-mt-1">
  <ng-template pTemplate="content">
    <div class="custom-card-body">
      <div class="p-mr-2 p-margin-top-high">
        <div id="overallCard">
          <div class="header-row">
            <i class="fa fa-envelope icon-style"></i>
            <div class="header-content">
              <div class="header-title">{{ record.headline ? record.headline : "Earn New Rewards!" }}</div>
              <div class="header-subtitle">{{ record.title ? record.title : "Invite Friends For New Rewards!" }}</div>
            </div>
            <!-- Format the date as "Month Day" -->
            <div class="date-text">
              {{ record.start | date: 'MMMM d' }}<br>
              {{ record.start | date: 'h:mma' }}
            </div>
          </div>
          <div id="contentBody">
            <div class="body-details" [innerHTML]="formattedBody"></div>
          </div>
        </div>
        <div id="preview-footer">
          <div class="action-button">
            <button id="preview-footer-button">{{record.linkText ? record.linkText : 'Link Here'}}</button>
          </div>
      </div>
    </div>
  </div>
  </ng-template>
</p-card>
