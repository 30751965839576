import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { DataService } from 'src/app/services/data.service';
import { LoggerService } from '../common/services/logger.service';
import { AssetTypes } from '../entities/enums/AssetTypes';
import { PromotionService } from './promotion.service';

@Injectable({
  providedIn: 'root',
})
export class EntityViewService {
  constructor(
    private dataService: DataService,
    private messageService: MessageService,
    private promotionService: PromotionService,
    private loggerService: LoggerService
  ) {}

  async getEntity(type: string, query: object) {
    const entity = await this.dataService
      .getDocumentAsync(type, query)
      .then(async (doc) => {
        if(doc)
        {
          if (doc.buildData)
          {
            doc.buildData = await this.dataService.getBuildData(doc.buildData);
            doc.buildStatus = this.getBuildStatus(doc.buildData);
          }
          else
          {
            doc.buildData = [];
            doc.buildStatus = {
              text: 'No build data',
              date: '',
              color: 'var(--gray-400)',
              buildCount: 0,
            };
          }
          if (doc.imageBuildData)
          {
            doc.imageBuildData = await this.dataService.getBuildData(
              doc.imageBuildData
            );
            doc.imageBuildStatus = this.getBuildStatus(doc.imageBuildData);
          }
          else
          {
            doc.imageBuildData = [];
            doc.imageBuildStatus = {
              text: 'No build data',
              date: '',
              color: 'var(--gray-400)',
              buildCount: 0,
            };
          }
        }
        return doc ? doc : null;
      });
    console.log('getEntity', entity);
    return entity;
  }

  getBuildStatus(buildData: any) {
    if (!buildData || buildData.length < 1)
      return {
        text: 'No build data',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };

    let successful: any[] = [];
    let failed: any[] = [];
    let building: any[] = [];
    let queued: any[] = [];

    buildData.forEach((build: any) => {
      switch (build.status.toLowerCase()) {
        case 'finished':
          successful.push(build.finishedAt);
          break;
        case 'failed':
          failed.push(build.finishedAt);
          break;
        case 'building':
          building.push(build.startedAt);
          break;
        case 'queued':
          queued.push(build.insertedAt);
          break;
      }
    });

    let result = {};

    switch (buildData.length)
    {
      case successful.length:
        result = {
          text: 'Success',
          date: this.getMostRecent(successful),
          color: 'var(--primary-color)',
          buildCount: buildData.length,
        };
        break;

      case failed.length:
        result = {
          text: 'Full Fail',
          date: this.getMostRecent(failed),
          color: 'var(--pink-600)',
          buildCount: buildData.length,
        };
        break;

      case building.length:
        result = {
          text: 'Building',
          date: this.getMostRecent(building),
          color: 'var(--text-color)',
          buildCount: buildData.length,
        };
        break;

      case queued.length:
        result = {
          text: 'Queued',
          date: this.getMostRecent(queued),
          color: 'var(--text-color)',
          buildCount: buildData.length,
        };
        break;

      default:
        if (
          building.length > 0 &&
          building.length < buildData.length &&
          failed.length == 0
        ) {
          result = {
            text: 'Partial Building',
            date: this.getMostRecent(building),
            color: 'var(--yellow-600)',
            buildCount: building.length,
          };
        } else if (
          failed.length > 0 &&
          failed.length < buildData.length
        ) {
          result = {
            text: 'Partial Fail',
            date: this.getMostRecent(failed),
            color: 'var(--pink-600)',
            buildCount: failed.length,
          };
        } else if (
          queued.length > successful.length &&
          successful.length > 0
        ) {
          result = {
            text: 'Queued',
            date: this.getMostRecent(queued),
            color: 'var(--text-color)',
            buildCount: queued.length,
          };
        } else {
          result = {
            text: 'No build data',
            date: '',
            color: 'var(--gray-400)',
            buildCount: 0,
          };
        }
        break;
    }

    return result;
  }

  getMostRecent(dates: any[]) {
    let allDates: any[] = [];
    dates.forEach((d) => {
      allDates.push(Date.parse(d));
    });

    return new Date(Math.max(...allDates)).toLocaleDateString();
  }

  /**
   * Makes the request to AB to promote asset.
   *
   * @param entity Entity data
   * @param type Type of asset
   * @param from Promote source
   * @param to Promote to
   * @param displayMessageNotification Flag that sets wether or not to display the message notification.
   */
  async promoteAsset
  (
    entity: any,
    type: AssetTypes,
    from: string,
    to: string,
    displayMessageNotification: boolean = true
  )
  {
    this.loggerService.log('Promoting Asset....');
    if(entity)
    {
      var payload =
      {
        entity: entity,
        from: from,
        to: to,
        isManualPromotion: true,
      };

      let assetResponse = await this.promotionService.promoteAsset(type, payload);

      if(assetResponse && assetResponse.Success)
      {
        this.loggerService.log('assetResult', assetResponse);

        if(displayMessageNotification)
        {
          let responseMsg = '';
          if(
            assetResponse.responseData && assetResponse.responseData.s3Promotion
            && assetResponse.responseData.type && assetResponse.responseData.from
            && assetResponse.responseData.to && assetResponse.responseData.destinationPath
            ){
            let promotionResult = assetResponse.responseData.s3Promotion.reduce(function (a:any, b:any){
              return (a.Key || a) + ", " + b.Key}
          )
            responseMsg = `
            ${assetResponse.responseData.type} Id: ${assetResponse.EntityId} was promoted from
            ${assetResponse.responseData.from} to ${assetResponse.responseData.to}.
            Location: ${assetResponse.responseData.destinationPath}.
            Files Promoted: ${promotionResult}
            `;
          } else {
            responseMsg = 'Promoted to QA';
          }

          this.messageService.add
          (
            {
              key: "AssetPromotionMsg",
              severity: 'success',
              summary: 'Promotion Sucessful',
              detail: responseMsg,
            }
          );
          this.messageService.add
          (
            {
              severity: 'success',
              summary: 'Promotion Sucessful',
              detail: "Asset Promoted to QA",
            }
          );
        }
        return { Success: true };
      }
      else
      {
        this.loggerService.log(`error promoting asset for ${type} ${entity.id}`, assetResponse);

        if(displayMessageNotification)
        {
          let responseMsg = '';
          if(assetResponse && assetResponse.responseData && assetResponse.responseData.from && assetResponse.responseData.to && assetResponse.responseData.error){
            responseMsg = `
            Error promoting Asset Id: ${entity.id}
            from ${assetResponse.responseData.from} to ${assetResponse.responseData.to}.
            * No successful builds found in build records.
            ${assetResponse.responseData.error}
            `;
          } else {
            responseMsg = `Asset was not promoted to QA. ${assetResponse.Message ? assetResponse.Message : ''}`;
          }

          this.messageService.add
          (
            {
              key: "AssetPromotionMsg",
              severity: 'error',
              summary: 'Asset Promotion Error',
              detail: responseMsg,
            }
          );
          this.messageService.add
          (
            {
              severity: 'error',
              summary: 'Asset Promotion Error',
              detail: `Asset not promoted to QA. Error: ${assetResponse.Message ? assetResponse.Message : assetResponse.Error}`,
            }
          );
        }
        return { Success: false, AssetResponse: assetResponse };
      }
    }
    else
    {
      this.loggerService.log('No entity provided when promoting assets');
      return { Success: false, Message: 'No entity provided when promoting assets' };
    }
  }

  /**
   * Makes the request to AB to promote image.
   *
   * @param entity Entity data
   * @param type Type of asset
   * @param from Promote source
   * @param to Promote to
   * @param customPath Custom path to promote
   * @param displayMessageNotification Flag that sets wether or not to display the message notification.
   */
  async promoteImage
  (
    entity: any,
    type: AssetTypes,
    from: string,
    to: string,
    customPath: string | null = null,
    displayMessageNotification: boolean = true
  )
  {
    this.loggerService.log('Promoting Image....');
    if(entity)
    {
      var payload =
      {
        entity: entity,
        from: from,
        to: to,
        isManualPromotion: true,
        customPath: customPath,
      };

      let imageResponse = await this.promotionService.promoteImage(type, payload);

      if(imageResponse && imageResponse.Success)
      {
        this.loggerService.log('imageResponse', imageResponse);

        if(displayMessageNotification)
        {
          let responseMsg = '';
          if(imageResponse.responseData){
            let promotionResult = imageResponse.responseData.s3Promotion.reduce(function (a:any, b:any){
              return (a.Key || a) + ", " + b.Key}
          )
            responseMsg = `
            ${imageResponse.responseData.type} Id: ${imageResponse.EntityId} was promoted from
            ${imageResponse.responseData.from} to ${imageResponse.responseData.to}.
            Location: ${imageResponse.responseData.destinationPath}.
            Files Promoted: ${promotionResult}
            `;
          } else {
            responseMsg = 'Promoted to QA';
          }

          this.messageService.add
          (
            {
              key: "ImagePromotionMsg",
              severity: 'success',
              summary: 'Promotion Sucessful',
              detail: responseMsg,
            }
          );
          this.messageService.add
          (
            {
              severity: 'success',
              summary: 'Promotion Sucessful',
              detail: "Image Promoted to QA",
            }
          );
        }

        return { Success: true };
      }
      else
      {
        this.loggerService.log(`error promoting image for ${type} ${entity.id}`, imageResponse);

        if(displayMessageNotification)
        {
          let responseMsg = '';
          if(imageResponse && imageResponse.responseData && imageResponse.responseData.from && imageResponse.responseData.to && imageResponse.responseData.error){
            responseMsg = `
            Error promoting Image Id: ${entity.id}
            from ${imageResponse.responseData.from} to ${imageResponse.responseData.to}.
            * No successful builds found in build records.
            ${imageResponse.responseData.error}
            `;
          } else {
            responseMsg = `Image was not promoted to QA. ${imageResponse.Message ? imageResponse.Message : ''}`;
          }
          this.messageService.add
          (
            {
              key: "ImagePromotionMsg",
              severity: 'error',
              summary: 'Image Promotion Error',
              detail: responseMsg,
            }
          );
          this.messageService.add
          (
            {
              severity: 'error',
              summary: 'Image Promotion Error',
              detail: `Image was not promoted to QA. Error: ${imageResponse.Message}`,
            }
          );
        }

        return { Success: false, ImageResponse: imageResponse };
      }
    }
    else
    {
      this.loggerService.log('No entity provided when promoting image');
      return { Success: false, Message: 'No entity provided when promoting image.' };
    }
  }

  async renderBuildHistoryChart(entity: any) {
    if (entity.prefab) {
      entity.buildHistoryData = await this.dataService.getBuildHistoryData(
        entity.prefab,
        true
      );
      // console.log(this.entity.buildHistoryData);
      return this.generateChartData(entity.buildHistoryData[0]);
    }
    return null;
  }

  generateSeriesObject(buildItem: any) {
    // date difference calculation
    let minutes: any = (
      (new Date(buildItem.finishedAt).valueOf() -
        new Date(buildItem.startedAt).valueOf()) /
      1000 /
      60
    ).toFixed(0);
    return {
      name: buildItem._id,
      value: minutes,
    };
  }

  generateChartData(data: any) {
    let x: any[] = [];
    let dummyContainer = [
      {
        name: 'IOS',
        series: ([] = new Array<any>()),
      },
      {
        name: 'AND',
        series: ([] = new Array<any>()),
      },
      {
        name: 'WIN',
        series: ([] = new Array<any>()),
      },
      {
        name: 'LIN',
        series: ([] = new Array<any>()),
      },
      {
        name: 'MAC',
        series: ([] = new Array<any>()),
      },
      // {
      //   name: 'WEB',
      //   series: ([] = new Array<any>()),
      // },
      {
        name: 'MULTIPLE',
        series: ([] = new Array<any>()),
      },
    ];
    data.forEach((buildItem: any) => {
      // console.log(buildItem);
      let seriesObj: any = this.generateSeriesObject(buildItem);
      if (
        buildItem.platforms &&
        buildItem.startedAt &&
        buildItem.finishedAt &&
        parseInt(seriesObj.value) > 1 &&
        buildItem.status == 'finished'
      ) {
        if (buildItem.platforms.length == 1) {
          if (buildItem.platforms[0] == 'ios') {
            // IOS is at index 0
            dummyContainer[0].series.push(seriesObj);
          }
          if (buildItem.platforms[0] == 'and') {
            // AND is at index 1
            dummyContainer[1].series.push(seriesObj);
          }
          if (buildItem.platforms[0] == 'win') {
            // WIN is at index 2
            dummyContainer[2].series.push(seriesObj);
          }
          if (buildItem.platforms[0] == 'lin') {
            // LIN is at index 3
            dummyContainer[3].series.push(seriesObj);
          }
          if (buildItem.platforms[0] == 'mac') {
            // MAC is at index 4
            dummyContainer[4].series.push(seriesObj);
          }
          // if (buildItem.platforms[0] == 'web') {
          //   // WEB is at index 5
          //   dummyContainer[5].series.push(seriesObj);
          // }
        } else {
          // else is at index 6
          dummyContainer[5].series.push(seriesObj);
        }
      }
    });
    console.log('dummyContainer: ', dummyContainer);
    return dummyContainer;
  }

  /**
   * Check for new build data
   */
  async getBuildData(entity: any) {
    let output: any = {};
    if (entity.buildData.length > 0) {
      output.buildData = await this.dataService.getBuildData(entity.buildData);
      output.buildStatus = this.getBuildStatus(entity.buildData);
    } else {
      output.buildData = [];
      output.buildStatus = {
        text: 'Build Status Not Found',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }
    if (entity.imageBuildData.length > 0) {
      output.imageBuildData = await this.dataService.getBuildData(
        entity.imageBuildData
      );
      output.imageBuildStatus = this.getBuildStatus(entity.imageBuildData);
    } else {
      output.imageBuildData = [];
      output.imageBuildStatus = {
        text: 'Build Status Not Found',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }
    return output;
  }

  /**
   * Retrieves Custom Build Data by a given entity and a set of custom build data keys
   *
   * @param entity Entity object
   * @param customBuildDataKeys List of custom build data keys.
   */
  async getCustomBuildData(entity: any, customBuildDataKeys: Array<string>)
  {
    for(let buildData of customBuildDataKeys)
    {
      if(entity[buildData])
      {
        entity[buildData] = await this.dataService.getBuildData(entity[buildData]);

        let status = buildData.replace('Data', 'Status');

        entity[status] = this.getBuildStatus(entity[buildData]);
      }
    }

    return entity;
  }
}
