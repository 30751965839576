/**
 * Table Bulk Build DTO
 */
export default class TableBulkBuild
{
    /**
     * List of rows to edit
     */
    rows: Array<any> = [];
    /**
     * Bulk stage
     */
    successCount: number;
    /**
     * Field object
     */
    failedPayloads: Array<any> = [];
}