import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { GlobalSearchService } from '../../../../common/services/global-search.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';

interface CartItem {
  id: string;
  _id: string;
  name: string;
  type: string;
  entity: string;
  enabled: boolean;
  start: Date | null;
  end: Date | null;
}

interface SearchResults {
  GroupedEntities: Array<{
    label: string;
    items: Array<{
      id: string;
      name: string;
      type: string;
      entity: string;
    }>;
  }>;
}

@Component({
  selector: 'app-schedule-sidebar',
  templateUrl: './schedule-sidebar.component.html',
  styleUrls: ['./schedule-sidebar.component.css'],
  animations: [
    trigger('sidebarState', [
      state('closed', style({
        width: '0',
        opacity: 0
      })),
      state('open', style({
        width: '250px',
        opacity: 1
      })),
      transition('closed <=> open', [
        animate('0.3s ease-in-out')
      ])
    ])
  ]
})
export class ScheduleSidebarComponent implements OnInit {
  @Input() isOpen: boolean = false;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() generateEvent = new EventEmitter<CartItem[]>();

  searchResults: SearchResults = { GroupedEntities: [] };
  searchQuery: string = '';
  private searchSubject = new Subject<string>();
  cartItems: CartItem[] = [];
  
  constructor(private globalSearchService: GlobalSearchService) {
    // Initialize cart from localStorage
    const savedCart = localStorage.getItem('scheduleCart');
    if (savedCart) {
      this.cartItems = JSON.parse(savedCart);
    }

    // Setup search with debounce
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(async (query) => {
      if (query.length >= 2) {
        const results = await this.globalSearchService.seriesOnlySearch(query, false);
        
        // Filter out items that are already in cart
        const filteredGroups = results.GroupedEntities.map((group: any) => {
          return {
            ...group,
            items: group.items.map((item: any) => {
              const mappedItem = {
                ...item,
                entity: group.value
              };
              return mappedItem;
            }).filter((item: any) => 
              !this.cartItems.some(cartItem => 
                cartItem.id === item.id && cartItem.entity === item.entity
              )
            )
          };
        }).filter((group: any) => group.items.length > 0);

        this.searchResults = { GroupedEntities: filteredGroups };
      } else {
        this.searchResults = { GroupedEntities: [] };
      }
    });
  }

  ngOnInit() {}

  get sidebarState() {
    return this.isOpen ? 'open' : 'closed';
  }

  toggleSidebar() {
    this.isOpen = !this.isOpen;
    this.isOpenChange.emit(this.isOpen);
  }

  onSearch(event: any) {
    const query = event.target.value;
    if (query.length >= 2) {
      this.searchSubject.next(query);
    } else {
      this.searchResults = { GroupedEntities: [] };
    }
  }

  addToCart(item: any) {
    console.log('addToCart item', item);
    const cartItem: CartItem = {
      id: item.id,
      _id: item._id,
      name: item.name,
      type: item.type,
      entity: item.entity.toLowerCase(),
      enabled: item.enabled || true,  // Default to true if not provided
      start: item.start || null,
      end: item.end || null
    };

    if (!this.cartItems.some(i => i.id === cartItem.id && i.entity === item.entity)) {
      this.cartItems.push(cartItem);
      this.updateLocalStorage();

      // Update search results to remove the added item
      this.searchResults = {
        GroupedEntities: this.searchResults.GroupedEntities.map((group: any) => ({
          ...group,
          items: group.items.filter((resultItem: any) => 
            !(resultItem.id === item.id && resultItem.entity === item.entity)
          )
        })).filter((group: any) => group.items.length > 0)
      };
    }
  }

  removeFromCart(index: number) {
    const removedItem = this.cartItems[index];
    this.cartItems.splice(index, 1);
    this.updateLocalStorage();

    // If there's an active search, refresh the results
    if (this.searchQuery.length >= 2) {
      this.searchSubject.next(this.searchQuery);
    }
  }

  clearCart() {
    this.cartItems = [];
    this.updateLocalStorage();

    // If there's an active search, refresh the results
    if (this.searchQuery.length >= 2) {
      this.searchSubject.next(this.searchQuery);
    }
  }

  private updateLocalStorage() {
    localStorage.setItem('scheduleCart', JSON.stringify(this.cartItems));
  }

  onGenerateEvent() {
    this.generateEvent.emit(this.cartItems);
    this.clearCart();
  }

  get groupedCartItems() {
    const groups = this.cartItems.reduce((acc, item) => {
      if (!acc[item.entity]) {
        acc[item.entity] = [];
      }
      acc[item.entity].push(item);
      return acc;
    }, {} as { [key: string]: CartItem[] });

    return Object.entries(groups).map(([entity, items]) => ({
      entity,
      items
    })).sort((a, b) => a.entity.localeCompare(b.entity));
  }
} 