import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { DynamicViewV2 } from 'src/app/common/components/dynamic-view-v2/dynamic-view-v2.component';
import * as _ from 'lodash';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-trait-view-v2',
  templateUrl: './trait-view-v2.component.html',
  styleUrls: ['./trait-view-v2.component.sass']
})
export class TraitViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  @ViewChild(DynamicViewV2) dynamicViewComponent: DynamicViewV2;
  traitRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Trait View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Trait Data",
        fields:
        [
          { name: "Name",inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time',columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time',columnWidth: 6 }
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Trait";
    this.viewRoute = 'traits';
    this.isLoading = false;
  }

  /**
   * Parse Record Data
   *
   * @param record Record value.
   */
  prepareRecordData(record: any)
  {
    // This is just an example of how to use the prepareRecordData event method.
    // In this case, we are cloning the record and appending a period to the name.
    // This is just a simple change/data transformation that can be done to the record,
    // but we should be able to do more complex data transformations here.
    this.traitRecord = _.cloneDeep(record);
    this.traitRecord = { ...this.traitRecord, name: this.traitRecord.name + '.' };

    this.dynamicViewComponent.isLoading = false;
    this.dynamicViewComponent.spinnerService.endSpinner();
  }
}
