import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { IDetailCellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-schedule-detail-template',
  templateUrl: './schedule-detail-template.component.html',
  styleUrls: ['./schedule-detail-template.component.css']
})
export class ScheduleDetailTemplateComponent implements ICellRendererAngularComp {
  params!: IDetailCellRendererParams;

  agInit(params: IDetailCellRendererParams): void {
    this.params = params;
  }

  refresh(params: IDetailCellRendererParams): boolean {
    this.params = params;
    return true;
  }
} 