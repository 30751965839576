import { Component, OnInit } from "@angular/core";
import DropdownDto from 'src/app/common/dtos/DropdownDto';
import { PermissionsService } from "src/app/permissions/service/permissions.service";
import { MessageService } from "primeng/api";
import { ValidationsService } from "src/app/common/services/validations.service";
import { RoleService } from "../service/role.service";
import PermissionDto from "src/app/permissions/dtos/PermissionsDto";
import { TableCommunicationService } from "src/app/common/ag-grid-table/services/table-communication.service";
import RoleDto from "../dto/RoleDto";
import { AuthService } from "src/app/auth/auth.service";

@Component({
  selector: 'app-role-form-dialog',
  templateUrl: './role-form-dialog.component.html',
  styleUrls: ['./role-form-dialog.component.sass'],
})
export class RoleFormDialogComponent implements OnInit {
  constructor(
    private permissionsService: PermissionsService,
    private messageService: MessageService,
    private validationService: ValidationsService,
    private roleService: RoleService,
    private tableCommunicationService: TableCommunicationService,
    public authService: AuthService,
  ) {}

  rolPrefix = 'ROLE_';
  isLoading: boolean = true;
  showAddEditNewRole: boolean = false;
  permissions: Array<DropdownDto> = [];
  permissionsDto: Array<PermissionDto> = [];
  role: RoleDto = {
    name: "",
    description: "",
    permissions: []
  }

  async ngOnInit(): Promise<void> {
    await this.loadPermissions();
    this.clearMessages();
  }

  transformInput(event: any, type: keyof RoleDto) {
    const input = event.target.value;
    this.role[type] = input.replace(/\s/g, '').toUpperCase();
  }

  /**
   * Validates if new user permission is valid.
   */
  validateUserPermission() {
    let isValid = true;

    if (this.validationService.isEmpty(this.role.name)) {
      this.messageService.add({ sticky: true, severity: "error", summary: "Role Error", detail: "Name is required." });
      isValid = false;
    } else if (this.role.name.replace(/^[A-Za-z_]+$/, '')) {
      this.messageService.add({ sticky: true, severity: "error", summary: "Role Error", detail: "Name must have only letters and underscores." });
      isValid = false;
    }

    if (this.role.permissions.length === 0) {
      this.messageService.add({ sticky: true, severity: "error", summary: "Role Error", detail: "At least one permission is required." });
      isValid = false;
    }

    return isValid;
  }

  /**
   * Load all permissions
   */
  async loadPermissions() {
    this.permissionsDto = await this.permissionsService.getAllPermissions() || [];

    if (this.permissionsDto && this.permissionsDto.length > 0) {
      this.permissionsDto.forEach(permission => {
        this.permissions.push({ name: permission.name, value: permission._id });
      });
    }
  }

  /**
   * Clear all messages from service
   */
  clearMessages() {
    this.messageService.clear();
  }

  /**
   * Save a role
   */
  async saveRole() {
    if (this.validateUserPermission()) {
      try {
        const completeDataPermissions = this.permissionsDto.filter((permission) => this.role.permissions.includes(permission._id));
        this.role.permissions = completeDataPermissions;
        await this.roleService.createRole({
          ...this.role,
          name: this.rolPrefix + this.role.name
        });
        this.messageService.clear();
        this.showAddEditNewRole = false;
        this.tableCommunicationService.emitEvent('reloadDataSource');
      } catch (err: any) {
        this.messageService.add({ sticky: true, severity: "error", summary: err.statusText || "Error", detail: err?.error?.error || err.message });
      }
    }
  }
}