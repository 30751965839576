import { AuthGuard } from "../auth/auth.guard";
import { DailyGoalTableAgGridComponent } from "../cms-v2/entities/daily-goal/components/daily-goal-table-ag-grid/daily-goal-table-ag-grid.component";
import { DailyGoalFormComponent } from "../cms-v2/entities/daily-goal/components/daily-goal-form/daily-goal-form.component";
import { DailyGoalViewComponent } from "../cms-v2/entities/daily-goal/components/daily-goal-view/daily-goal-view.component";

export const dailyGoalRoutes = {
  prefix: 'daily-goals',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: DailyGoalTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: DailyGoalFormComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: DailyGoalViewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: DailyGoalFormComponent,
        canActivate: [AuthGuard],
      },
    ]
  }
};