import { Component, OnInit } from '@angular/core';
import { TableDataComponent } from '../../../table-data/components/table-data/table-data.component';

@Component({
  selector: 'app-series-table',
  templateUrl: './series-table.component.html',
  styleUrls: ['./series-table.component.sass']
})
export class SeriesTableComponent extends TableDataComponent implements OnInit 
{
  constructor() 
  {
    super();
  }

  /**
   * Series Table Component Initialization
   */
  ngOnInit() 
  {
    this.isLoading = true;
    this.title = 'Series';
    this.type = 'series';

    this.globalFilters = ['id', 'name'];
    this.filtersToRegister = 
    [
      ...this.defaultFilters,
      'stringIn_ref',
      'multiIn_ref',
    ];

    this.fields = 
    [
      ...this.defaultFields,
      {
        key: 'challenges_ref',
        name: 'Challenge(s)',
        controlType: 'autoComplete-multi_ref',
        apiController: 'challenges',
        filterKey: 'challenges_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,        
      },
      {
        key: 'prizes_ref',
        name: 'Prize(s)',
        controlType: 'multi_prizes_ref',
        filterKey: 'prizes_ref',
        matchMode: 'complex',
        isColumn: true,
        isInputField: false,
        isFilterField: false, // filter field disabled until field is developed.
        viewControl: {
          sortBy: 'c',
        },
        subFields: [
          {
            key: 'lineItemType',
            name: 'Line Item Type',
            controlType: 'inputText',
            isHidden: true,
          },
          {
            key: 't',
            name: 'Resource',
            controlType: 'dropdown_ref',
            apiController: 'resources',
            filterKey: 't',
            matchMode: 'stringIn_ref',
            isInputField: true,
            isFilterField: true,
          },
          {
            key: 'id',
            name: 'Currency',
            controlType: 'dropdown_ref',
            apiController: 'currencies',
            filterKey: 'id',
            matchMode: 'stringIn_ref',
            isInputField: true,
            isFilterField: true,
          },
          {
            key: 'id',
            name: 'Item',
            controlType: 'autoComplete_ref',
            apiController: 'items',
            filterKey: 'id',
            matchMode: 'stringIn_ref',
            isInputField: true,
            isFilterField: true,
          },
          {
            key: 'c',
            name: 'Count',
            controlType: 'inputNumber',
            filterKey: 'c',
            matchMode: 'between',
            isInputField: true,
            isFilterField: true,
          },
        ],
      },
    ];
    this.defaultColumnOrder = 
    [
      'id', 
      'name', 
      'start', 
      'end', 
      'enabled',
      'challenges_ref',
      'prizes_ref'
    ];
    this.customGlobalColumnSets = [
      {
        name: 'Default',
        value: [...this.defaultColumnOrder],
      },
    ];

    this.tableConfig.customFilterToRegister = this.filtersToRegister;
    this.isLoading = false;
  }
}
