<div *ngIf="isLoading else elseBlock">
  <p-card class="p-m-5">
    <span>
      <p-progressSpinner
      [style]="{width: '70px', height: '70px'}" strokeWidth="8"
      fill="var(--primary-color)"  animationDuration="2s">
    </p-progressSpinner>
    </span>
  </p-card>
</div>
<div #elseBlock>
  <p-card>
    <h1>Gamedata Build History</h1>
    <p-table [value]="history" responsiveLayout="scroll" [resizableColumns]="true">
      <ng-template pTemplate="header">
          <tr>
              <th>ID</th>
              <th>Version</th>
              <th>Method</th>
              <th>Built By</th>
              <th>Date</th>
              <th>Environment and Status</th>
              <th>QA-Prod Differences</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-build let-rowIndex="rowIndex">
          <tr>
              <td>{{build.id}}</td>
              <td>{{build.s3id}}</td>
              <td>
                {{build.method}}
              </td>
              <td>
                {{build.createdBy ? build.createdBy.firstName + ' ' + build.createdBy.lastName : 'CMS Bot'}}
              </td>
              <td>{{build.createdAt | officeTime: 'short'}}</td>
              <td  style="
              width: 45vw;
              min-width: 20vw;
              max-width: 75vw;
              ">
                <p-accordion *ngIf="(build.devGamedata && build.devGamedata.status == 'Failed') ||
                (build.qaGamedata && build.qaGamedata.status == 'Failed') ||
                (build.prodGamedata && build.prodGamedata.status == 'Failed')">
                  <p-accordionTab *ngIf="build.devGamedata">
                    <ng-template pTemplate="header">
                      Dev Gamedata
                    </ng-template>
                    <ng-template pTemplate="content">
                      <span>
                        <small class="failed-build">
                          Status: {{build.devGamedata.status}}
                        </small>
                        <small class="failed-build">
                          <br>
                          Message: {{build.devGamedata.message}}
                          <br>
                          {{build.devGamedata.error}}
                        </small>
                      </span>
                    </ng-template>
                  </p-accordionTab>
                  <p-accordionTab *ngIf="build.qaGamedata">
                    <ng-template pTemplate="header" >
                      QA Gamedata
                    </ng-template>
                    <ng-template pTemplate="content">
                      <span>
                        <small class="failed-build">
                          Status: {{build.qaGamedata.status}}
                        </small>
                        <small class="failed-build">
                          <br>
                          Message: {{build.qaGamedata.message}}
                          <br>
                          {{build.qaGamedata.error}}
                        </small>
                      </span>
                    </ng-template>
                  </p-accordionTab>
                  <p-accordionTab *ngIf="build.prodGamedata">
                    <ng-template pTemplate="header">
                      Prod Gamedata
                    </ng-template>
                    <ng-template pTemplate="content">
                      <span>
                        <small class="failed-build">
                          Status: {{build.prodGamedata.status}}
                        </small>
                        <small class="failed-build">
                          <br>
                          Message: {{build.prodGamedata.message}}
                          <br>
                          {{build.prodGamedata.error}}
                        </small>
                      </span>
                    </ng-template>
                  </p-accordionTab>
                </p-accordion>
                <p-accordion *ngIf="
                (build.devGamedata && build.devGamedata.status == 'Built and Uploaded') ||
                (build.devGamedata && build.devGamedata.status == 'Success') ||
                (build.qaGamedata && build.qaGamedata.status == 'Success') ||
                (build.qaGamedata && build.qaGamedata.status == 'Built and Uploaded') ||
                (build.prodGamedata && build.prodGamedata.status == 'Success')">
                  <p-accordionTab *ngIf="build.devGamedata">
                    <ng-template pTemplate="header">
                      Dev Gamedata
                    </ng-template>
                    <ng-template pTemplate="content">
                      <span>
                        <small class="success-build">
                          Status: {{build.devGamedata.status}}
                        </small>
                        <small class="success-build">
                          <br>
                          Message: {{build.devGamedata.message.s3Response}}
                          <br>
                          {{build.devGamedata.message.verS3Response}}
                        </small>
                      </span>
                    </ng-template>
                  </p-accordionTab>
                  <p-accordionTab *ngIf="build.qaGamedata">
                    <ng-template pTemplate="header" >
                      QA Gamedata
                    </ng-template>
                    <ng-template pTemplate="content">
                      <span>
                        <small class="success-build">
                          Status: {{build.qaGamedata.status}}
                        </small>
                        <small class="success-build">
                          <br>
                          Message: {{build.qaGamedata.message.s3Response}}
                          <br>
                          {{build.qaGamedata.message.verS3Response}}
                        </small>
                      </span>
                    </ng-template>
                  </p-accordionTab>
                  <p-accordionTab *ngIf="build.prodGamedata">
                    <ng-template pTemplate="header">
                      Prod Gamedata
                    </ng-template>
                    <ng-template pTemplate="content">
                      <span>
                        <small class="success-build">
                          Status: {{build.prodGamedata.status}}
                        </small>
                        <small class="success-build">
                          <br>
                          Message: {{build.prodGamedata.message.s3Response}}
                          <br>
                          {{build.prodGamedata.message.verS3Response}}
                        </small>
                      </span>
                    </ng-template>
                  </p-accordionTab>
                </p-accordion>
              </td>
              <td>
                <button
            pButton
            pRipple
            type="button"
            icon="pi pi-bars"
            class="p-button-raised"
            (click)="openModal(build.id)"
          ></button>
              </td>
          </tr>
      </ng-template>
  </p-table>

  </p-card>
</div>
<p-dialog
  [header]="gamedataDiff ? 'QA and Prod Gamedata differences for ID: ' + params : 'QA - Prod Differences unavailable for ID: ' + params"
  [(visible)]="params"
  [modal]="true"
  [style]="{ width: '95vw' }"
  [resizable]="false"
>
<p-message
      *ngIf="!gamedataDiff"
      severity="info"
      text="QA and Prod differences is not available for this Gamedata log"
></p-message>
<p-table *ngIf="gamedataDiff" [value]="gamedataDiff" responsiveLayout="stack" [scrollable]="false" styleClass="p-datatable-sm">
  <ng-template pTemplate="header">
      <tr>
          <th style="width:10px">Key</th>
          <th>QA</th>
          <th>Production</th>
      </tr>
  </ng-template>
  <ng-template pTemplate="body" let-product>
      <tr>
          <td>{{product.Key}}</td>
          <td>{{product.Records.qa.Value ? product.Records.qa.Value : 'null'}}</td>
          <td>{{product.Records.prod.Value ? product.Records.prod.Value : 'null'}}</td>
      </tr>
  </ng-template>
</p-table>
</p-dialog>
