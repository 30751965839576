import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService 
{
  private readonly apiURLprefix : string = '/api/currencies';
  constructor(private http: HttpClient) {}

  /**
   * Creates a currency record
   * 
   * @param currencyData Currency data
   */
  async createCurrency(currencyData: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/add`, currencyData).toPromise();
  }

  /**
   * Updates a currency record.
   * 
   * @param currencyId Id of the currrency to update.
   * @param currencyData Currency data to be updated.
   */
  async updateCurrency(currencyId: any, currencyData: any): Promise<any>
  {
    return await this.http.patch(`${this.apiURLprefix}/update/${currencyId}`, currencyData).toPromise();
  }
}