<div *ngIf="field.key != 'currency_record_ref'">
  <h4 class="p-d-inline">{{field.label}}: </h4>
  <a href="/{{field.controller}}/{{record[field.key] ?  record[field.key].id : ''}}"
     target="_blank"
     [ngStyle]="{ color: 'var(--primary-color)' }">
    {{record[field.key] ? record[field.key].name : ''}} ({{record[field.key] ?  record[field.key].id : ''}})
  </a>
</div>

<div *ngIf="field.key == 'currency_record_ref'" class="currency-record-ref">
  <h4 class="p-d-inline">{{field.label}}: </h4>
  <a href="/currencies/{{record[field.key] ?  record[field.key].id : ''}}"
     target="_blank"
     [ngStyle]="{ color: 'var(--primary-color)' }">
    {{record[field.key] ? record[field.key].name : ''}} ({{record[field.key] ?  record[field.key].id : ''}})
  </a>

  <div class="currency-details">
    <div class="top-row">
      <div *ngIf="isCoOpView()" class="sprite-index"><strong>Sprite Index:</strong> {{ record['currency_sprite_index'] || 'N/A' }}</div>
    </div>
    <div class="bottom-row">
      <div>Currency Name: {{ record[field.key] ? record[field.key].name : "N/A" }}</div>
      <div>Currency Name Plural: {{ record[field.key].namePlural ? record[field.key].namePlural : 'N/A' }}</div>
    </div>
  </div>
</div>