import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import StoreListingV2DTO from '../dtos/storeListingV2DTO';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-store-listing-v2-form',
  templateUrl: './store-listing-v2-form.component.html',
  styleUrls: ['./store-listing-v2-form.component.sass']
})
export class StoreListingV2FormComponent extends BaseDynamicFormComponent implements OnInit {
  activeTabIndex: number = 0; // Default to the first tab
  environments = ['dev', 'qa', 'prod']; // The order of these values should match the tab order
  storeEnvs: string[] = [];
  currentEnv: string;

  StoreListingV2Record: StoreListingV2DTO = new StoreListingV2DTO();
  constructor(
    private validation: BaseInputValidationServiceService
  ) {
    super();
  }


  /**
   * Store Listing Forms V2 Component Initialization
  */

  ngOnInit(){
    this.initializeStoreEnvironments();
    this.loadSelectedEnvironment();

    this.fields =
    [
      {
        title: "Store Listing",
        fields:
        [
          {
            name: "Enabled",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.ToggleButton],
            onLabel: 'Export Enabled',
            offLabel: 'Export Disabled',
            key: 'CmsEnabled',
            label: 'Enabled',
            columnWidth: 12
          },
          {
            name: "Listing ID",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Listing ID',
            disabled: true,
            columnWidth: 12
          },
          {
            name: 'Price Point',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'pricePoint_ref',
            label: 'Price Point',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            scrollHeight: '150px',
            style: { 'min-width': '30em' },
            options: {
              fieldName: 'pricePoint_ref',
              apiController: 'price-points', minimal: false,
              autopopulate: true, virtuals: false, sort: { name: 1 }
            },
            columnWidth: 12
          },
          {
            name: 'Credit(s)',
            inputType: DynamicInputType.Price,
            options: {fieldName:'', version: 3},
            key: 'credits_ref',
            columnWidth: 12
          },
          {
            name: "Display Details",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: '',
            label: 'Display Details:',
          },
          {
            name: "Sale Label",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'saleLabel',
            isRequired: false,
            label: 'Sale Label',
            disabled: false,
            columnWidth: 12
          },
          {
            name: "Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'image_ref',
            label: 'Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: {
              fieldName: 'image_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: 3 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
            },
            columnWidth: 12
          },
          {
            name: "Display Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'displayName',
            isRequired: false,
            label: 'Display Name',
            disabled: false,
            columnWidth: 12
          },
          {
            name: "Description",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'description',
            isRequired: false,
            label: 'Description',
            disabled: false,
            columnWidth: 12
          },
          {
            name: "Price Label",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'priceLabel',
            isRequired: false,
            label: 'Price Label',
            disabled: false,
            columnWidth: 12
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'storeStart',
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            columnWidth: 6
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'storeEnd',
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            columnWidth: 6
          },
          {
            name: "Use StoreFront End Time?",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'useStoreFrontEndTime',
            isRequired: false,
            label: 'Use StoreFront End Time?',
            inputId: 'useStoreFrontEndTime',
            checkboxBinary: true,
            columnWidth: 12
          },
          {
            name: "Use Tile UI?",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'useTileUI',
            isRequired: false,
            label: 'Use Tile UI?',
            inputId: 'useTileUI',
            checkboxBinary: true,
            columnWidth: 12
          },
          {
            name: "Display Subtitle",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'displaySubtitle',
            isRequired: false,
            label: 'Display Subtitle?',
            inputId: 'displaySubtitle',
            checkboxBinary: true,
            columnWidth: 12
          },
          {
            name: "Background Hex",
            inputType: DynamicInputType.BaseInputField,
            key: 'backgroundHex',
            label: 'Background Hex',
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: false,
            isRequired: false,
            disabled: false,
            columnWidth: 12
          },
          {
            name: "Price Point Text",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'pricePointText',
            isRequired: false,
            label: 'Price Point Text',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Minimum Level",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'minimumLevel',
            label: 'Minimum Level',
            inputNumberShowButtons: true,
            columnWidth: 6
          },
          {
            name: "Analytics Bucket",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'analyticsBucket',
            isRequired: false,
            label: 'Analytics Bucket',
            inputId: 'analyticsBucket',
            checkboxBinary: true,
            columnWidth: 6
          },
          {
            name: "Optional Data",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: '',
            label: 'Optional Data:',
          },
          {
            name: "Original StoreListing Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: false,
            key: 'originalStoreListingName',
            isRequired: false,
            label: 'Original StoreListing Name',
            inputId: 'originalStoreListingName',
            optionLabel: 'name',
            optionValue: 'name',
            filter: true,
            options: { fieldName: 'originalStoreListingName', apiController: 'store-listings-v2', autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'  },
            scrollHeight: '200px',
            columnWidth: 12
          },
          {
            name: "Fallback StoreListing Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: false,
            key: 'fallbackStoreListingName',
            isRequired: false,
            label: 'Fallback StoreListing Name',
            inputId: 'fallbackStoreListingName',
            optionLabel: 'name',
            optionValue: 'name',
            filter: true,
            options: { fieldName: 'fallbackStoreListingName', apiController: 'store-listings-v2', autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
            scrollHeight: '200px',
            columnWidth: 12
          },
          {
            name: "Secondary StoreListing Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: false,
            key: 'secondaryStoreListingName',
            isRequired: false,
            label: 'Secondary StoreListing Name',
            inputId: 'secondaryStoreListingName',
            optionLabel: 'name',
            optionValue: 'name',
            filter: true,
            options: { fieldName: 'secondaryStoreListingName', apiController: 'store-listings-v2', autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' },
            scrollHeight: '200px',
            columnWidth: 12
          },
          {
            name: "Customer Limit",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'customerLimit',
            label: 'Customer Limit',
            inputNumberShowButtons: true,
            columnWidth: 12,
            inputNumberMin: 0
          },
          {
            name: 'Customer Limit Reset Fixed',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'CustomerLimitResetFixed',
            isRequired: false,
            label: 'Customer Limit Reset Fixed',
            inputId: 'CustomerLimitResetFixed',
            checkboxBinary: true,
            columnWidth: 6,
            displayType: 'customerLimit'
          },
          {
            name: "Customer Limit Reset Hours",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'CustomerLimitResetHours"',
            isRequired: false,
            label: 'Customer Limit Reset Hours',
            disabled: false,
            columnWidth: 6,
            displayType: 'customerLimit',
            inputNumberMin: 0
          },
          {
            name: "Tags",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: '',
            label: 'Tags:',
          },
          {
            name: "Tags",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Chip],
            clearField: true,
            key: 'tags',
            isRequired: false,
            label: 'Tags',
            disabled: false,
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Store Listing";
    this.viewRoute = 'store-listings-v2';
    this.isLoading = false;


  }

  initializeStoreEnvironments(): void {
    const currentUrl = window.location.href;

    if (currentUrl.includes('prod.cms')) {
      // Prod CMS: DEV, QA, PROD
      this.storeEnvs = ['dev', 'qa', 'prod'];

    } else if (currentUrl.includes('test.cms')) {
      // Test CMS: TEST
      this.storeEnvs = ['test'];
    } else {
      // local/Dev CMS: TEST, DEV, QA, PROD
      this.storeEnvs = ['test', 'dev', 'qa', 'prod'];
    }
    this.environments = this.storeEnvs;
  }

  loadSelectedEnvironment(): void {
    const savedEnvironment = localStorage.getItem('selectedEnvironment');
    if (savedEnvironment) {
      const environmentObject = JSON.parse(savedEnvironment);
      this.currentEnv = environmentObject.value;
      this.activeTabIndex = this.environments.indexOf(environmentObject.value);
    }
  }

  onTabChange(event: { index: number; }): void {

    this.activeTabIndex = event.index;
    const environmentToSave = {
      label: this.environments[this.activeTabIndex].charAt(0).toUpperCase() + this.environments[this.activeTabIndex].slice(1),
      value: this.environments[this.activeTabIndex]
    };
    localStorage.setItem('selectedEnvironment', JSON.stringify(environmentToSave));
  }
  validateStoreListingV2Form(form: any)
  {
    let confirmValidationCallback = true;
    if(form.color) {
      confirmValidationCallback = this.validation.colorValidator(form.color);
    }
    
    let message = confirmValidationCallback ? 'Valid' : 'The color entered is invalid. Please enter a valid hex color.';

    return { confirmValidationCallback, message }
  }
}
