import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Challenge Type DTO DTO
 */
export default class LoadingScreenDTO extends BaseEntityDto
{
    /**
     * Misc Image Record
     */
    public logo_ref?: any;

    public images?: Array<any> = [];

    public weight?: string;

    public year?: number;

  }
