import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Daily Rewards DTO
 */
export default class ExternalPlantDataDTO extends BaseEntityDto
{
    public name: string;

    public fileRef?: string;

    public description: String;

    public commonName: any;

    public botanicalName: any;
    
    public plantType: any;

    public matureSize: String;

    public growingTime: String;

    public bloomTime: String;

    public flowerColor: String;

    public soilType: String;

    public soilPH: String;

    public hardinessZone: String;

    public nativeArea: any;

    public sunExposure: String;

    public toxicity: String;

    public url: String;

    public imagePath: String;
}
