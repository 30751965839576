import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import BuildrecordDTO from '../../dtos/BuildRecord';
import { BuildService } from '../../services/build.service';

@Component({
  selector: 'app-bulk-build-data',
  templateUrl: './bulk-build-data.component.html',
  styleUrls: ['./bulk-build-data.component.sass']
})
export class BulkBuildDataComponent implements OnInit 
{
  bulkBuildId: any;
  buildJobs: Array<BuildrecordDTO> = []; 
  queueBuildJobs: Array<BuildrecordDTO> = []; 
  buildingBuildJobs: Array<BuildrecordDTO> = []; 
  successBuildJobs: Array<BuildrecordDTO> = []; 
  failedBuildJobs: Array<BuildrecordDTO> = []; 
  isLoading: boolean = true;
  views: Array<string> = ["All", "Queued", "Building", "Finished", "Failed"];
  currentTimeout: any;

  constructor
  (
    private route: ActivatedRoute,
    private router: Router,
    private buildService: BuildService
  ) { }

  /**
   * Bulk Build Data Component Initialization
   */
  async ngOnInit()
  {
    this.bulkBuildId = this.route.snapshot.queryParamMap.get('bulkBuildId');
    if(this.bulkBuildId)
    {
      await this.getBulkBuilData();
    }
 
    this.isLoading = false;
  }

  /**
   * Retrieves all build records tied to the bulk build.
   */
  async getBulkBuilData()
  {
    this.isLoading = true;
    this.updateURLQueryParams();
    if (this.currentTimeout) 
    {
      clearTimeout(this.currentTimeout);
    }

    this.currentTimeout = setTimeout(async () => 
    {
      let response = await this.buildService.getBulkBuildData(this.bulkBuildId);

      if(response && response.length > 0)
      {
        this.buildJobs = response;
        this.queueBuildJobs = this.buildJobs.filter((x) => x.status == 'queued');
        this.buildingBuildJobs = this.buildJobs.filter((x) => x.status == 'building');
        this.successBuildJobs = this.buildJobs.filter((x) => x.status == 'finished');
        this.failedBuildJobs = this.buildJobs.filter((x) => x.status == 'failed');
      }
      this.currentTimeout = null;
    }, 500);
    this.isLoading = false;
  } 

  /** 
   * Update URL query params for bulk build search. 
   * */
  updateURLQueryParams() 
  {
    // Update Query parameter value
    this.router.navigate([], 
      {
        relativeTo: this.route,
        queryParams: { 'bulkBuildId' : this.bulkBuildId},
        queryParamsHandling: 'merge',
        replaceUrl: true,
      }
    );
  }
}