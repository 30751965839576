import { Component, OnInit } from '@angular/core';
import NurtureStageDTO from '../../dtos/NurtureStageDTO';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-nurture-stage-form',
  templateUrl: './nurture-stage-form.component.html',
  styleUrls: ['./nurture-stage-form.component.sass']
})
export class NurtureStageFormComponent extends BaseDynamicFormComponent implements OnInit {

  nurtureStageRecord: NurtureStageDTO = new NurtureStageDTO();

  constructor(
    private validation: BaseInputValidationServiceService
  ) {
    super();
  }

  ngOnInit() {
    this.fields =
    [
      {
        title: "Nurture Stage Data",
        fields:
        [
          {
            name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 12,
          },
          {
            name: "Display Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'displayName',
            isRequired: true,
            label: 'Display Name',
            columnWidth: 6,
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            autoDisplayFirst: false,
            options: { fieldName: 'type', values: [{name:'Grown'}, {name:'Sapling'}, {name:'Seed'}, {name:'Seedling'}] },
            optionLabel:'name',
            optionValue:'name',
            columnWidth: 6
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.nurtureStageRecord.start,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            clearField: true,
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: "Transition Video",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'transitionVideo_ref',
            label: 'Transition Video',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: { fieldName: 'transitionVideo_ref', apiController: 'miscellaneous-build', customQuery:{ entityType: { $in: [6] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true },
            columnWidth: 6
          },
          {
            name: "Banner Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'image_ref',
            label: 'Banner Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: {
              fieldName: 'image_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: { $in: [3, 4] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true
            },
            columnWidth: 6
          },
          {
            name: "Stage Video",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'stageVideo_ref',
            label: 'Stage Video',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: { fieldName: 'stageVideo_ref', apiController: 'miscellaneous-build', customQuery:{ entityType: { $in: [6] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true },
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Nurture Stage Form";
    this.viewRoute = 'nurture-stage';
    this.isLoading = false;

  }

}
