import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-cron-schedule-form',
  templateUrl: './cron-schedule-form-v2.component.html',
  styleUrls: ['./cron-schedule-form-v2.component.sass']
})
export class CronScheduleFormV2Component extends BaseDynamicFormComponent implements OnInit {
  gamedataScheduleRecord: BaseEntityDto = new BaseEntityDto();
  
  constructor(
    private validation: BaseInputValidationServiceService,
    private dataService: DataService,
  ) {
    super();
  }

  /**
   * Cron Schedule Form V2 Initial Implementation
   */
  async ngOnInit() {
    const cronTypes = await this.dataService.getCronTypes();

    this.fields = [{
      title: "Cron Schedule Data",
      fields: [
        {
          name: "Description",
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.TextInput],
          clearField: true,
          key: 'description',
          isRequired: true,
          label: 'Description',
          disabled: false
        },
        {
          name: "Type",
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.Dropdown],
          clearField: true,
          key: 'type',
          showField: true,
          isRequired: true,
          label: 'Type',
          filter: true,
          autoDisplayFirst: false,
          options: {
            fieldName: 'type', 
            values: cronTypes
          },
          optionLabel: 'name',
          optionValue: 'value',
        },
        {
          name: "Is Repetitive/Scheduled",
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.ToggleButton],
          clearField: true,
          key: 'isRepetitive',
          isRequired: false,
          showField: true,
          label: 'Is Repetitive/Scheduled',
          onLabel: 'Yes',
          offLabel: 'No',
          displayControlType: "showHide",
          disabled: false,
          columnWidth: 3,
          emitOnInit: true,
          displayControls: {
            ['days']: (value: any) => value == true ? true : false,
            ['time']: (value: any) => value == true ? true : false,
            ['cronDate']: (value: any) => value == true ? false : true,
          },
        },
        {
          name: "Date",
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.Calendar],
          clearField: true,
          key: 'cronDate',
          isRequired: false,
          label: 'Date',
          disabled: false,
          calendarShowTime: true,
          calendarYearNavigatior: true,
          calendarMonthNavigator: true,
          calendarSelectOtherMonths: true,
        },
        {
          name: 'Days',
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.MultiSelect],
          key: 'days',
          isRequired: false, 
          label: 'Days',
          options: { fieldName: 'days', values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] },
          clearField: true,
          filter: true,
          style: { 'width': '100%' },
          showClear: true,
          multiSelectDisplayType: 'chip',
          showField: false,
        },
        {
          name: "Time",
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.Time],
          clearField: true,
          key: 'time',
          isRequired: false,
          label: 'Time',
          disabled: false,
          showField: false,
        },
      ],
      type: DynamicCardType.Card
    }];

    this.title = "Cron Schedule";
    this.viewRoute = 'cron-schedules';
    this.isLoading = false;
  }

  /**
   * Handles batch name changes
   *
   * @param event Batch data
   */
  async onNameComponentChange(event: any) {}
}



