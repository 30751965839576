import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChildren,
} from '@angular/core';
import _, { groupBy, isEqual } from 'lodash';
import FieldData from '../field-data-dto';
import { DataService } from 'src/app/services/data.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { itemConstants } from 'src/app/entities/item/data/constants';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { ValidationsService } from 'src/app/common/services/validations.service';

@Component({
  selector: 'app-input-field',
  templateUrl: './base-input-field.component.html',
  styleUrls: ['./base-input-field.component.sass'],
})
export class BaseInputFieldComponent implements OnInit {
  @ViewChildren('nestedInputField') nestedInputField: any;
  @Input('value') value?: any = null;
  @Input() field: FieldData;
  @Input() entity: any;
  @Input() entityType: any;
  @Input() options: any = [];
  @Input() page: string;
  @Input() entityViewLink?: string;
  @Input() isSubField?: boolean = false;
  @Input() lineItemType?: string;
  @Input() isLocked: boolean = false;
  @Output() modelChange = new EventEmitter<any>();

  initValue: any = null;
  nestedValue: any[] = [];
  nestedValueCopy: Array<any> = [];
  itemConstants = itemConstants;
  today = this.setDefaultTime();

  presetValue: any = null;
  nestedPresetValues: any = {};

  conflictValue: any = null;
  groupIndex: number;
  suggestions: Array<any>;

  disabledSubFields: any[] = [];

  colorValue: string = '';

  @Output() valueChange = new EventEmitter();
  @Output() nestedChange = new EventEmitter();
  @Output() pathComponentValueChange = new EventEmitter();
  @Output() categoryChange = new EventEmitter();
  @Output() componentChange = new EventEmitter()

  get hasValue(): boolean {
    return !this.validationsService.isEmpty(this.value)
  }
  get hasInputControl(): boolean {
    return this.field.inputControl && this.field.inputControl.type
  }
  get isClearable(): boolean {
    return !this.validationsService.isEmpty(this.value) && this.entity && !this.entity.isLocked
  }
  get isValueChanged(): boolean {
    return !_.isEqual(this.initValue, this.value);
  }
  get scrollHeight(): string {
    return this.options.length > 8 ? '350px' : `${this.options.length * 42}px`
  }

  constructor(
    private dataService: DataService,
    private loggerService: LoggerService,
    private utilitiesService: UtilitiesService,
    private validationsService: ValidationsService
  ) {}

  /** Base Input Field Initialization */
  async ngOnInit() {
    if (
      this.hasValue &&
      this.hasInputControl &&
      this.field.inputControl.type == 'color' &&
      !_.isArray(this.value)
    ) {
      this.colorValue = this.value.substring(0, 7);
    }
    // complex fields
    if (this.field.key == 'type_ref') {
      this.options =
        this.entity['category_ref'] && this.entity.category_ref.types_ref
          ? this.entity.category_ref.types_ref
          : this.options;
    }
    if (
      ['nestedGroup', 'ref-link', 'formArray', 'lineItem'].includes(
        this.field.controlType
      )
    ) {
      if (this.value && this.field.controlType == 'ref-link') {
        let value: any[] = [];
        for (let i = 0; i < this.value.length; i++) {
          let val: any = {};
          val['link'] =
            this.value[i] && typeof this.value[i] === 'object'
              ? this.value[i].link
              : this.value[i];
          value.push(val);
        }
        this.value = value;
        this.nestedValue = _.cloneDeep(this.value);
        this.nestedValueCopy = _.cloneDeep(this.value);
      } else {
        this.value = await this.setPresetValue(this.field);
        this.nestedValue = _.cloneDeep(this.value);
        this.nestedValueCopy = _.cloneDeep(this.value);
      }
    } else if (!this.hasValue && this.field.presetValue) {
      // other fields
      this.value = await this.setPresetValue(this.field);
    } else if (this.field.controlType == 'date') {
      if (this.hasValue) {
        this.value = new Date(this.value);
      }

      if (['start'].includes(this.field.key) && this.entity['end']) {
        this.conflictValue = new Date(this.entity['end']);
      } else if (['end'].includes(this.field.key) && this.entity['start']) {
        this.conflictValue = new Date(this.entity['start']);
      }
    } else {
      this.value = this.hasValue ? this.value : null;
    }
    this.initValue = _.cloneDeep(this.value);
  }

  /** Function for checking conditions and emitting value changes in field */
  onInput() {
    if (!['table'].includes(this.page)) {
      this.valueChange.emit(this.value);
    }
  }
  /** Function for reverting field value, and emitting changes (pending condition) */
  onRevert() {
    this.value = this.initValue;
    if (!['table'].includes(this.page)) {
      this.valueChange.emit(this.value);
    }
  }
  /** Function for clearing field value and emitting changes (pending condition) */
  onClear() {
    this.value = null;
    if (!['table'].includes(this.page)) {
      this.valueChange.emit(this.value);
    }
  }
  /** checks for the length of the Rows on the input text area fields */
  checkRowLength(value: any) {
    return value != null && value.length > 30 ? 4 : 2;
  }

  /** Function for checking conditions for hidden fields in lineItem fields */
  isFieldHidden() {
    return (
      (['id'].includes(this.field.key) &&
      (!this.lineItemType || this.lineItemType !== this.field.name)) && !this.field.autoShow
    );
  }

  /**
   * Sets default date and time value if start/end date value is empty
   */
   setDefaultTime() {
    return new Date(this.utilitiesService.getCurrentDateAtMidnight());
  }

  // Nested Groups / Complex Fields
  /** Function for adding a nested group to field with 'nestedGroup' controlType */
  onAddNestedGroup() {
    let newGroup: any = {};
    this.field.subFields?.forEach((subField: FieldData) => {
      newGroup[subField.key] = this.nestedPresetValues[subField.key]
        ? this.nestedPresetValues[subField.key]
        : null;
    });
    if(!this.nestedValue || this.nestedValue.length < 0)
    {
      this.nestedValue = [];
    }
    if(!this.nestedValueCopy || this.nestedValueCopy.length < 0)
    {
      this.nestedValueCopy = [];
    }

    if(!this.entity[this.field.key] || !this.entity[this.field.key].length)
    {
      this.entity[this.field.key] = [];
    }
    if(this.entityType == 'items') {
      newGroup = {
        lineItemType: 'Currency',
        t: {name: 'Currency', _id: '617b62958e2f0e4a67f86c76', id: 1},
        c: null,
        id: null,
      }
    }  
    this.nestedValue.push(newGroup);
    this.nestedValueCopy.push(newGroup);

    if (!['table'].includes(this.page)) {
      this.valueChange.emit(this.nestedValue);
      this.valueChange.emit(this.nestedValueCopy);
    }
  }
  /** Function for deleting a nested group from field with 'nestedGroup' controlType */
  onDeleteNestedGroup() {
    this.nestedValue.splice(this.groupIndex, 1);
    this.nestedValueCopy.splice(this.groupIndex, 1);

    this.value = _.cloneDeep(this.nestedValueCopy);

    if (!['table'].includes(this.page)) {
      this.valueChange.emit(this.nestedValue);
      this.valueChange.emit(this.value);
    }
  }
  /** Function for reverting field with 'nestedGroup' controlType to it's original value */
  onRevertNestedValues() {
    this.nestedValue = _.cloneDeep(this.value);
    this.nestedValueCopy = _.cloneDeep(this.value);

    this.nestedInputField._results.forEach((o: any) => {
      o.value = o.initValue;

      if (!['table'].includes(this.page)) {
        o.valueChange.emit(this.value);
      }
    });
  }
  /** Function for detecting changes to nested values */
  isNestedValueChanged() {
    let changeCount = 0;
    if (this.nestedInputField) {
      this.nestedInputField._results.forEach((o: any) => {
        if (!_.isEqual(o.value, o.initValue)) {
          changeCount++;
        }
      });
    }

    if (changeCount > 0) {
      return true;
    } else return !_.isEqual(this.value, this.nestedValue);
  }

  /**
   * Function for calling `this.getSuggestions()` function and assigning value (array) to global 'this.suggestions' variable. Used with controlTypes 'autoComplete_ref' & 'autoComplete-multi_ref'.
   * @param input Input for query.
   */
  async setSuggestions(input: any) {
    this.suggestions = await this.getSuggestions(input);
  }

  /**
   * Function for retrieving suggestions in real-time, for controlTypes 'autoComplete_ref' & 'autoComplete-multi_ref'. Returns array of suggestions through api call to field's 'apiController' api endpoint.
   * @param input User input for query.
   * @returns Array of objects retrieved.
   */
  async getSuggestions(input: any) {
    // use this.filterModels to map the correct api endpoint so we don't have to store it on the entity.

    if (this.field.key === 'achievements_ref') {
      let output: any[] = await this.dataService
        .getAllOfTypeAsync(this.field.apiController!, {
          query: isNaN(input)
            ? { title: { $regex: input, $options: 'i' } }
            : { id: input },
          select: '_id title id',
          virtuals: this.field.isOptionsMin! ? false : true,
          autopopulate: this.field.isOptionsMin! ? false : true,
          sort: { name: 1 },
        })
        .then((result: any[]) => {
          return result;
        });
      this.loggerService.log('hey its achievement_ref', output);
      return output;
    } else {
      let output: any[] = await this.dataService
        .getAllOfTypeAsync(this.field.apiController!, {
          query: isNaN(input)
            ? { name: { $regex: input, $options: 'i' } }
            : { id: input },
          select: '_id name id',
          virtuals: this.field.isOptionsMin! ? false : true,
          autopopulate: this.field.isOptionsMin! ? false : true,
          sort: { name: 1 },
        })
        .then((result: any[]) => {
          return result;
        });
      return output;
    }
  }

  // Function for retrieving and parsing presetValue as determined by FieldData 'presetValue' property.

  /**
   * Function for retrieving and parsing presetValue as determined by FieldData 'presetValue' property.
   * @param field FieldData — field that the value is being retrieved for.
   * @param isNested boolean — Default is set to 'false', but if a field has a 'subFields' property (array), it loops through subFields and recursively calls this fuction with this parameter set to true.
   * @returns presetValue data for each subField, recursively.
   */
  async setPresetValue(field: FieldData, isNested: boolean = false) {
    let options: any;
    if (['dropdown_ref', 'multiSelect_ref'].includes(field.controlType)) {
      options = isNested ? this.options[field.key] : this.options;
    }
    let presetValue: any = null;

    switch (field.controlType) {
      case 'inputText':
      case 'inputTextarea':
      case 'inputNumber':

      case 'multiSelect':
        presetValue = field.presetValue ? field.presetValue : null;
        break;

      case 'date':
        presetValue = field.presetValue ? new Date(field.presetValue) : null;
        break;

      case 'dropdown':
      case 'dropdown_ref':
        presetValue = field.presetValue
          ? options.find(
              (o: any) => o[field.presetValue.key] === field.presetValue.value
            )
          : null;
        break;

      case 'multiSelect_ref':
        if (field.presetValue) {
          let multiSelect_values: any[] = [];

          for (let i = 0; i < field.presetValue.value.length; i++) {
            let multiSelect_value = this.options.find(
              (option: any) =>
                option[field.presetValue.key] === field.presetValue.value[i]
            );
            multiSelect_values.push(multiSelect_value);
          }
          presetValue = multiSelect_values;
        }
        break;

      case 'autoComplete_ref':
        if (field.presetValue) {
          let suggestions: any[] = await this.getSuggestions(
            field.presetValue.value
          );
          presetValue = suggestions.find(
            (suggestion: any) =>
              suggestion[field.presetValue.key] === field.presetValue.value
          );
        }
        break;

      case 'autoComplete-multi_ref':
        if (field.presetValue) {
          let autoComplete_values: any[] = [];
          for (let i = 0; i < field.presetValue.value.length; i++) {
            let suggestions: any[] = await this.getSuggestions(
              field.presetValue.value
            );
            let autoComplete_value = suggestions.find(
              (suggestion: any) =>
                suggestion[field.presetValue.key] === field.presetValue.value
            );
            autoComplete_values.push(autoComplete_value);
          }
          presetValue = autoComplete_values;
        }
        break;

      case 'nestedGroup':
      case 'lineItem':
      case 'formArray':
      case 'ref-link':
        let value = _.cloneDeep(this.value);
        let presetValues: any = {};

        // get presetValues for each subField
        field.subFields?.forEach(async (subField: FieldData) => {
          presetValues[subField.key] = subField.presetValue
            ? await this.setPresetValue(subField, true)
            : null;

          if (value && presetValues[subField.key]) {
            value.forEach((group: any) => {
              if (!group[subField.key]) {
                group[subField.key] = presetValues[subField.key];
              }
            });
          }
        });

        this.nestedPresetValues = presetValues;

        presetValue = value;
        break;
    }

    return presetValue;
  }

  /**
   * Emits event with value changed on a given field.
   * Returns an object with the new value of the field, the entity and the field data.
   */
  onChange(isClearAction: boolean = false) {
    this.modifyInputValue();

    let change = {
      value: this.value,
      entity: this.entity,
      field: this.field,
      isClearAction: isClearAction,
    };

    if (this.isSubField || this.field.subFields) {
      this.nestedChange.emit(change);
    } else {
      if (this.field.inputControl && this.field.inputControl.effects) {
        this.field.inputControl.effects.forEach((effect:any, index: number) => {
          switch (effect.type) {
            case 'componentChange':
              this.componentChange.emit({change: change, index: index});
              break;
          }
        })
      }

      this.modelChange.emit(change);
    }
  }
  /**
   * set options for type fields
   */
  async setTypeOptions() {
    let change = { value: this.value, entity: this.entity, field: this.field };
    this.categoryChange.emit(change);
  }

  /**
   * Updates nestedValue and emits event with value changed on given subField.
   * @param e Event received from subField. Object containing new value of the subField, entity (group of subFields), and field data.
   * @param index Index of the fieldGroup that the subField is in.
   */
  onNestedChange(e: any, index: number) {
    let nestedVal = _.cloneDeep(this.nestedValueCopy);
    nestedVal[index][e.field.key] = e.value;

    if (
      ['lineItem'].includes(this.field.controlType) &&
      ['t'].includes(e.field.key)
    ) {
      nestedVal[index].id = null;
      nestedVal[index].lineItemType = e.value ? e.value.name : null;
      this.nestedValue = nestedVal;
    }
    if((e.field.key == 'id' || e.field.key == 'c') && !nestedVal[index].lineItemType) {
      nestedVal[index].lineItemType = nestedVal[index].t ? nestedVal[index].t.name : null;
    }

    this.nestedValueCopy = _.cloneDeep(nestedVal);
    let change = {
      value: nestedVal,
      entity: this.entity,
      field: this.field,
    };
    this.modelChange.emit(change);
  }

  /**
   * Handles Path component value change
   *
   * @param inputNameValue Input name value
   * @param event Item value selected
   */
  onPathComponentValueChange(inputNameValue: any, event: any) {
    if (
      this.entityType === 'items-sourcing' &&
      (this.field.key == 'itemFileType_ref' || this.field.name == 'Year')
    ) {
      let value = inputNameValue == 'year' ? event.value : event.value._id;
      this.pathComponentValueChange.emit({
        inputName: inputNameValue,
        event: value,
        entity: this.entity,
      });
    }
  }

  /**
   * Emits event when blur is activated
   *
   * @param event
   */
  onBlur(event: any) {
    // Just emit pathComponentValueChange event on item sourcing - file name field
    if (
      this.entityType === 'items-sourcing' &&
      this.field.key == 'fileName' &&
      this.value &&
      this.value.length > 0
    ) {
      setTimeout(() => {
        this.pathComponentValueChange.emit({
          inputName: 'fileName',
          event: this.value,
          entity: this.entity,
        });
      }, 500);
    }
  }

  /** Extracts the alpha value (opacity) and concatenates it to colorValue selected in color-picker for value */
  onChangeColorValue() {
    const alphaValue = this.hasValue && !this.validationsService.isEmpty(this.value.substring(7)) ? this.value.substring(7) : ''
    this.value = (this.colorValue + alphaValue).toUpperCase();
  }

  /** Modifies input value based on formatting requirements of inputControl type. */
  modifyInputValue() {
    if (!this.hasValue) {
      this.value = null;
    } else if (this.hasInputControl) {
      switch (this.field.inputControl.type) {
        case 'color':
          !this.value.startsWith('#') ? this.value = `#${this.value}` : null
          break;
      }
    }
  }

}
