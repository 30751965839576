import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, AbstractControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Metadata, Field, MetadataService } from './metadata.service';

interface ValidationMessages {
  [key: string]: {
    [key: string]: string;
  };
}

@Component({
  selector: 'app-metadata-form',
  templateUrl: './metadata-form.component.html',
  providers: [MessageService]
})
export class MetadataFormComponent implements OnInit {
  form: FormGroup;
  isEdit = false;
  id: string;
  apiControllers: any[] = [];
  @Input() showDefaultFields: boolean = true;
  toasterLife: number = 3000;
  
  fieldTypes = [
    { label: 'String', value: 'String' },
    { label: 'Number', value: 'Number' },
    { label: 'Boolean', value: 'Boolean' },
    { label: 'Date', value: 'Date' },
    { label: 'ObjectId', value: 'ObjectId' },
    { label: 'Array', value: 'Array' }
  ];

  inputWidgets = {
    String: [
      { label: 'Text Input', value: 'TextInput' },
      { label: 'Text Area', value: 'InputTextArea' },
      { label: 'Editor', value: 'Editor' },
      { label: 'Color Picker', value: 'ColorPicker' },
      { label: 'Dropdown', value: 'Dropdown' },
      { label: 'AutoComplete', value: 'AutoComplete' },
      { label: 'MultiSelect', value: 'MultiSelect' }
    ],
    Number: [
      { label: 'Text Input', value: 'TextInput' },
    ],
    Boolean: [
      { label: 'Checkbox', value: 'Checkboxv2' },
      { label: 'Toggle Button', value: 'ToggleButton' }
    ],
    ObjectId: [
      { label: 'Dropdown', value: 'Dropdown' },
      { label: 'AutoComplete', value: 'AutoComplete' }
    ],
    Array: [
      { label: 'MultiSelect', value: 'MultiSelect' },
      { label: 'AutoComplete', value: 'AutoComplete' }
    ]
  };

  dropdownTypes = [
    { label: 'API Based', value: 'api' },
    { label: 'Static Values', value: 'static' }
  ];

  selectFieldOptions = [
    { label: '_id', value: '_id' },
    { label: 'id', value: 'id' },
    { label: 'name', value: 'name' },
    { label: 'title', value: 'title' },
    { label: 'description', value: 'description' },
    { label: 'path', value: 'path' },
    { label: 'enabled', value: 'enabled' },
    { label: 'type', value: 'type' },
    { label: 'status', value: 'status' },
    { label: 'createdAt', value: 'createdAt' },
    { label: 'updatedAt', value: 'updatedAt' }
  ];

  defaultFields = [
    {
      name: "name",
      type: "String",
      required: true,
      label: "Name",
      inputWidget: "TextInput",
      columnWidth: 6,
      enabled: true,
      gamedataExportEnabled: true,
      gamedataExportEnabledLabel: 'name',
      readonly: true,
      fieldEnabled: true,
      position: 1,
      filter: true,
      filterBy: "name,id"
    },
    {
      name: "start",
      type: "Date",
      required: false,
      label: "Start Date/Time",
      inputWidget: "Date",
      columnWidth: 6,
      enabled: true,
      gamedataExportEnabled: true,
      gamedataExportEnabledLabel: 'start',
      readonly: true,
      fieldEnabled: true,
      position: 2,
      filter: true,
      filterBy: "name,id"
    },
    {
      name: "end", 
      type: "Date",
      required: false,
      label: "End Date/Time",
      inputWidget: "Date",
      columnWidth: 6,
      enabled: true,
      gamedataExportEnabled: true,
      gamedataExportEnabledLabel: 'end',
      readonly: true,
      fieldEnabled: true,
      position: 3,
      filter: true,
      filterBy: "name,id"
    }
  ];

  // Validation messages mapping
  private validationMessages: ValidationMessages = {
    name: {
      required: 'Field name is required',
      pattern: 'Field name must contain only letters and underscores (A-Z, a-z, _) without spaces or special characters'
    },
    modelName: {
      required: 'Model name is required',
      pattern: 'Model name must start with a letter and contain only letters and hyphens (A-Z, a-z, -)'
    },
    type: {
      required: 'Field type is required'
    },
    columnWidth: {
      min: 'Column width must be at least 1',
      max: 'Column width must be at most 12'
    },
    position: {
      min: 'Position must be at least 1'
    },
    gamedataExportEnabledLabel: {
      required: 'Field name is required when Gamedata Export is enabled',
      pattern: 'Field name must contain only letters and underscores (A-Z, a-z, _) without spaces or special characters'
    },
    inputWidget: {
      required: 'Input widget is required for this field type'
    },
    dropdownType: {
      required: 'Dropdown type is required when using dropdown input'
    },
    apiController: {
      required: 'API Controller is required for API-based dropdowns'
    }
  };

  constructor(
    private fb: FormBuilder,
    private metadataService: MetadataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.params['id'];
    if (this.id) {
      this.isEdit = true;
    }
    
    // Initialize form with current showDefaultFields value
    this.initForm();
    
    if (this.isEdit) {
      this.loadMetadata();
    }
    
    // Load API controllers
    this.loadApiControllers();
  }

  private initForm() {
    this.form = this.fb.group({
      modelName: ['', [Validators.required, Validators.pattern('^[A-Za-z][A-Za-z-]*$')]],
      modelLabel: ['', [Validators.required]],
      enabled: [true],
      gamedataExportEnabled: [false],
      fields: this.fb.array([])
    });

    // Add default fields only if explicitly enabled and it's a new form
    if (!this.isEdit && this.showDefaultFields) {
      this.defaultFields.forEach(field => {
        const fieldWithReadonly = {
          ...field,
          readonly: true  // Mark default fields as readonly
        };
        this.addField(fieldWithReadonly);
      });
    }

    // Add a blank field for new forms if no default fields
    if (!this.isEdit && !this.showDefaultFields) {
      this.addField({
        name: '',
        type: '',
        required: false,
        gamedataExportEnabled: false,
        enabled: true
      });
    }

    // Subscribe to gamedataExportEnabled changes
    this.form.get('gamedataExportEnabled')?.valueChanges.subscribe((enabled: boolean) => {
      if (enabled && this.showDefaultFields && !this.isEdit) {
        const fieldsArray = this.fields;
        const hasDefaultFields = fieldsArray.controls.some(field => field.get('readonly')?.value);
        if (!hasDefaultFields) {
          this.addDefaultFields();
        }
      }
    });
  }

  private addDefaultFields() {
    this.defaultFields.forEach(field => this.addField(field));
  }

  private removeDefaultFields() {
    const fieldsArray = this.fields;
    for (let i = fieldsArray.length - 1; i >= 0; i--) {
      if (fieldsArray.at(i).get('readonly')?.value) {
        fieldsArray.removeAt(i);
      }
    }
  }

  toggleDefaultFields(event: any) {
    console.log('Toggle default fields:', event.checked);
    // Update the showDefaultFields value
    this.showDefaultFields = event.checked;
    const fieldsArray = this.fields;
    
    // Store non-readonly fields (custom fields)
    const customFields = fieldsArray.controls.filter(field => !field.get('readonly')?.value);
    console.log('Custom fields:', customFields.length);
    
    // Clear all fields
    fieldsArray.clear();
    
    // Re-add custom fields
    customFields.forEach(field => {
      fieldsArray.push(field);
    });
    
    // Add default fields if enabled
    if (this.showDefaultFields) {
      console.log('Adding default fields');
      this.defaultFields.forEach(field => {
        // Only add default field if it doesn't already exist
        if (!customFields.some(f => f.get('name')?.value === field.name)) {
          console.log('Adding default field:', field.name);
          const defaultField = { ...field, readonly: true };
          const addedField = this.addField(defaultField);
          
          // Ensure the field is properly disabled
          Object.keys(addedField.controls).forEach(key => {
            if (key !== 'fieldEnabled' && key !== 'readonly') {
              addedField.get(key)?.disable();
            }
          });
        }
      });
    }
    
    // If no fields are present after toggle, add a blank field
    if (fieldsArray.length === 0) {
      this.addField({
        name: '',
        type: '',
        required: false,
        gamedataExportEnabled: false,
        enabled: true
      });
    }
    
    console.log('Final fields count:', fieldsArray.length);
  }

  private loadMetadata() {
    if (!this.id) {
      return;
    }

    this.metadataService.getMetadataById(this.id).subscribe({
      next: (metadata) => {
        // Clear existing fields first
        while (this.fields.length !== 0) {
          this.fields.removeAt(0);
        }
        
        this.form.patchValue({ 
          modelName: metadata.modelName,
          modelLabel: metadata.modelLabel,
          enabled: metadata.enabled,
          gamedataExportEnabled: metadata.gamedataExportEnabled
        });
        
        // Update showDefaultFields based on metadata
        this.showDefaultFields = metadata.fields.some(field => 
          this.defaultFields.some(df => df.name === field.name)
        );
        
        if (metadata.fields && metadata.fields.length > 0) {
          metadata.fields.forEach(field => {
            // Check if this is a default field
            const defaultField = this.defaultFields.find(df => df.name === field.name);
            if (defaultField) {
              // If it's a default field, merge with default field properties and ensure readonly
              const mergedField = {
                ...field,
                ...defaultField,
                readonly: true,
                fieldEnabled: field.enabled !== false
              };
              const addedField = this.addField(mergedField);
              
              // Ensure the field is properly disabled
              Object.keys(addedField.controls).forEach(key => {
                if (key !== 'fieldEnabled' && key !== 'readonly') {
                  addedField.get(key)?.disable();
                }
              });
            } else {
              // If it's a custom field, add as is
              this.addField(field);
            }
          });
        }
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to load metadata'
        });
        console.error('Error loading metadata:', error);
      }
    });
  }

  private loadApiControllers() {
    // This is a static list for now - we can make it dynamic later
    this.apiControllers = [
      { label: 'Achievements', value: 'achievements' },
      { label: 'Categories', value: 'categories' },
      { label: 'Colors', value: 'colors' },
      { label: 'Currencies', value: 'currencies' },
      { label: 'Challenges', value: 'challenges' },
      { label: 'Item Types', value: 'item-types' },
      { label: 'Items', value: 'items' },
      { label: 'Materials', value: 'materials' },
      { label: 'Miscellaneous Build', value: 'miscellaneous-build' },
      { label: 'Nurture', value: 'nurture' },
      { label: 'Patterns', value: 'patterns' },
      { label: 'Progression Levels', value: 'progression-levels' },
      { label: 'Settings', value: 'settings' },
      { label: 'Shapes', value: 'shapes' },
      { label: 'Styles', value: 'styles' },
      { label: 'Traits', value: 'traits' },
      { label: 'Types', value: 'types' }
    ];
  }

  get fields() {
    return this.form.get('fields') as FormArray;
  }

  addField(fieldData: any = {}) {
    // Ensure default values are set for required properties
    const defaultValues = {
      name: '',
      type: 'String',  // Default type
      required: false,
      label: '',
      inputWidget: 'TextInput',  // Default input widget
      columnWidth: 6,
      position: this.fields.length + 1,
      enabled: true,
      gamedataExportEnabled: false,
      gamedataExportEnabledLabel: '',
      readonly: false,
      fieldEnabled: true,
      filter: true,
      filterBy: 'name,id'
    };

    // Merge fieldData with default values, ensuring all required properties are set
    const mergedFieldData = { ...defaultValues, ...fieldData };

    // Ensure type is one of the allowed values
    if (!this.fieldTypes.some(ft => ft.value === mergedFieldData.type)) {
      mergedFieldData.type = 'String';  // Default to String if invalid type
    }

    const field = this.fb.group({
      name: [mergedFieldData.name, [Validators.required, Validators.pattern('^[A-Za-z_][A-Za-z0-9_]*$')]],
      type: [mergedFieldData.type, [Validators.required]],
      required: [mergedFieldData.required],
      label: [mergedFieldData.label || mergedFieldData.name],  // Default label to name if not provided
      inputWidget: [mergedFieldData.inputWidget],
      columnWidth: [mergedFieldData.columnWidth, [Validators.min(1), Validators.max(12)]],
      position: [mergedFieldData.position, [Validators.min(1)]],
      enabled: [mergedFieldData.enabled],
      gamedataExportEnabled: [mergedFieldData.gamedataExportEnabled],
      gamedataExportEnabledLabel: [mergedFieldData.gamedataExportEnabledLabel],
      readonly: [mergedFieldData.readonly],
      fieldEnabled: [mergedFieldData.fieldEnabled],
      filter: [mergedFieldData.filter],
      filterBy: [mergedFieldData.filterBy],
      // Common controls for Dropdown, AutoComplete, and MultiSelect
      apiController: [fieldData.apiController || ''],
      select: [fieldData.select || []],
      optionLabel: [fieldData.optionLabel || 'name'],
      optionValue: [fieldData.optionValue || '_id'],
      // Dropdown specific controls
      dropdownType: [fieldData.dropdownType || 'api'],
      // MultiSelect specific controls
      multiSelectType: [fieldData.multiSelectType || 'api'],
      multiSelectDisplayType: [fieldData.multiSelectDisplayType || 'chip'],
      options: this.fb.group({
        values: this.fb.array([]),
        fieldName: [fieldData.options?.fieldName || ''],
        apiController: [fieldData.options?.apiController || ''],
        select: [fieldData.options?.select || ''],
        sort: [fieldData.options?.sort || { name: 1 }]
      }),
      // Toggle Button specific controls
      onLabel: [fieldData.onLabel || 'Yes'],
      offLabel: [fieldData.offLabel || 'No'],
      // Number specific controls
      inputNumberShowButtons: [fieldData.inputNumberShowButtons || false],
      useInputNumberMin: [fieldData.useInputNumberMin || false],
      inputNumberMin: [fieldData.inputNumberMin || null],
      useInputNumberMax: [fieldData.useInputNumberMax || false],
      inputNumberMax: [fieldData.inputNumberMax || null],
      useMinFractionDigits: [fieldData.useMinFractionDigits || false],
      minFractionDigits: [fieldData.minFractionDigits || null],
      useMaxFractionDigits: [fieldData.useMaxFractionDigits || false],
      maxFractionDigits: [fieldData.maxFractionDigits || null]
    });

    // If the field is readonly, disable all controls except fieldEnabled
    if (mergedFieldData.readonly) {
      Object.keys(field.controls).forEach(key => {
        if (key !== 'fieldEnabled' && key !== 'readonly') {
          field.get(key)?.disable();
        }
      });
    }

    // Add validation for gamedataExportEnabledLabel when gamedataExportEnabled is true
    if (mergedFieldData.gamedataExportEnabled) {
      field.get('gamedataExportEnabledLabel')?.setValidators([
        Validators.required,
        Validators.pattern('^[A-Za-z_][A-Za-z0-9_]*$')
      ]);
    }

    // Add static values if provided
    if (fieldData.options?.values && Array.isArray(fieldData.options.values)) {
      const valuesArray = field.get('options.values') as FormArray;
      fieldData.options.values.forEach((value: any) => {
        valuesArray.push(this.fb.group({
          value: [value.label || value.value || '']
        }));
      });
    }

    this.fields.push(field);

    // Set up validation based on input widget
    if (mergedFieldData.inputWidget) {
      this.onInputWidgetChange(field);
    }

    return field;
  }

  removeField(index: number) {
    this.fields.removeAt(index);
    this.updatePositions();
  }

  onSubmit() {
    if (this.form.valid) {
      const formValue = this.form.value;
      
      // Format fields data before submission
      const formattedFields = this.formatSelectFields(formValue);
      
      // Filter out default fields if showDefaultFields is false
      if (!this.showDefaultFields) {
        formattedFields.fields = formattedFields.fields.filter((field: any) => !field.readonly);
      }

      const payload = {
        ...formattedFields,
        fields: formattedFields.fields,
        includeDefaultFields: this.showDefaultFields
      };

      if (this.isEdit) {
        this.metadataService.updateMetadata(this.id, payload).subscribe ({
          next: (response) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Metadata updated successfully',
              life: this.toasterLife
            });
            this.router.navigate(['/d', formValue.modelName.toLowerCase()]);
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.error?.message || 'Failed to update metadata',
              life: this.toasterLife
            });
          }
        });
      } else {
        this.metadataService.createMetadata(payload).subscribe({
          next: (response) => {
            this.messageService.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Metadata created successfully',
              life: this.toasterLife
            });
            this.router.navigate(['/d', formValue.modelName.toLowerCase()]);
          },
          error: (error) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error',
              detail: error.error?.message || 'Failed to create metadata',
              life: this.toasterLife
            });
          }
        });
      }
    } else {
      Object.keys(this.form.controls).forEach(key => {
        const control = this.form.get(key);
        if (control?.invalid) {
          control.markAsTouched();
        }
      });
    }
  }

  getAvailableInputWidgets(type: string) {
    return this.inputWidgets[type as keyof typeof this.inputWidgets] || [];
  }

  getFieldConfigControl(fieldName: string): AbstractControl | null {
    const fieldConfig = this.form.get('gamedataExportConfig.fieldConfig') as FormControl;
    const currentValue = fieldConfig.value || {};
    
    if (!(fieldName in currentValue)) {
      currentValue[fieldName] = false;
      fieldConfig.setValue(currentValue);
    }
    
    return fieldConfig;
  }

  onInputWidgetChange(field: AbstractControl) {
    const inputWidget = field.get('inputWidget')?.value;
    const type = field.get('type')?.value;

    // Reset all widget-specific controls
    field.get('apiController')?.clearValidators();
    field.get('select')?.clearValidators();
    field.get('optionLabel')?.clearValidators();
    field.get('optionValue')?.clearValidators();
    field.get('dropdownType')?.clearValidators();
    field.get('multiSelectType')?.clearValidators();

    switch (inputWidget) {
      case 'Dropdown':
        // Set up dropdown-specific validation
        field.get('dropdownType')?.setValidators([Validators.required]);
        
        // Set up API-specific validation if dropdownType is 'api'
        if (field.get('dropdownType')?.value === 'api') {
          field.get('apiController')?.setValidators([Validators.required]);
          field.get('select')?.setValidators([Validators.required]);
          field.get('optionLabel')?.setValidators([Validators.required]);
          field.get('optionValue')?.setValidators([Validators.required]);
        }
        break;

      case 'MultiSelect':
        // Set up multiselect-specific validation
        field.get('multiSelectType')?.setValidators([Validators.required]);
        
        // Set up API-specific validation if multiSelectType is 'api'
        if (field.get('multiSelectType')?.value === 'api') {
          field.get('apiController')?.setValidators([Validators.required]);
          field.get('select')?.setValidators([Validators.required]);
          field.get('optionLabel')?.setValidators([Validators.required]);
          field.get('optionValue')?.setValidators([Validators.required]);
        }
        break;

      case 'AutoComplete':
        field.get('apiController')?.setValidators([Validators.required]);
        field.get('select')?.setValidators([Validators.required]);
        field.get('optionLabel')?.setValidators([Validators.required]);
        field.get('optionValue')?.setValidators([Validators.required]);
        break;

      case 'ToggleButton':
        field.get('onLabel')?.setValidators([Validators.required]);
        field.get('offLabel')?.setValidators([Validators.required]);
        break;
    }

    // Update validation status
    field.get('apiController')?.updateValueAndValidity();
    field.get('select')?.updateValueAndValidity();
    field.get('optionLabel')?.updateValueAndValidity();
    field.get('optionValue')?.updateValueAndValidity();
    field.get('dropdownType')?.updateValueAndValidity();
    field.get('multiSelectType')?.updateValueAndValidity();
  }

  getStaticValues(field: AbstractControl): any[] {
    const values = field.get('options.values') as FormArray;
    return values ? values.controls : [];
  }

  addStaticValue(field: AbstractControl) {
    let values = field.get('options.values') as FormArray;
    
    if (!values) {
      const optionsGroup = field.get('options') as FormGroup;
      if (!optionsGroup) {
        (field as FormGroup).addControl('options', this.fb.group({
          values: this.fb.array([])
        }));
      } else {
        optionsGroup.addControl('values', this.fb.array([]));
      }
      values = field.get('options.values') as FormArray;
    }

    // Create a form group with a value control
    values.push(this.fb.group({
      value: ['']  // Initialize with empty string
    }));
  }

  removeStaticValue(field: AbstractControl, index: number) {
    const values = field.get('options.values') as FormArray;
    if (values) {
      values.removeAt(index);
    }
  }

  private formatSelectFields(formValue: any) {
    const formattedValue = { ...formValue };
    
    // Explicitly set includeDefaultFields based on the current state
    formattedValue.includeDefaultFields = this.showDefaultFields;
    
    // Get all fields from the form
    let fields = formValue.fields
      .filter((field: any) => field.name?.trim() && field.type) // Only include fields that have name and type
      .map((field: any, index: number) => {
        // Base field properties that are always required
        const formattedField: any = {
          name: field.name?.trim(),
          type: field.type,
          required: field.required || false,
          label: field.label || field.name,
          gamedataExportEnabled: field.gamedataExportEnabled || false,
          columnWidth: field.columnWidth || 6,
          inputWidget: field.inputWidget || 'TextInput',
          position: field.position || (index + 1),  // Use existing position or fallback to index + 1
          enabled: field.enabled !== false,
          readonly: field.readonly || false,
          fieldEnabled: field.fieldEnabled !== false
        };

        // Add optional properties only if they exist and are valid
        if (field.gamedataExportEnabledLabel) {
          formattedField.gamedataExportEnabledLabel = field.gamedataExportEnabledLabel;
        }

        // Handle MultiSelect specific properties
        if (field.inputWidget === 'MultiSelect') {
          formattedField.multiSelectType = field.multiSelectType || 'api';
          formattedField.multiSelectDisplayType = field.multiSelectDisplayType || 'chip';
          
          if (field.multiSelectType === 'static' && field.options?.values) {
            formattedField.options = {
              values: field.options.values
            };
          } else {
            // API-based MultiSelect properties
            if (field.apiController) formattedField.apiController = field.apiController;
            if (field.select) formattedField.select = field.select;
            if (field.filter !== undefined) formattedField.filter = field.filter;
            if (field.filterBy) formattedField.filterBy = field.filterBy;
          }
        }

        // Handle Dropdown specific properties
        if (field.inputWidget === 'Dropdown') {
          if (field.apiController) formattedField.apiController = field.apiController;
          if (field.select) formattedField.select = field.select;
          if (field.filter !== undefined) formattedField.filter = field.filter;
          if (field.filterBy) formattedField.filterBy = field.filterBy;
        }

        // Handle number configuration
        if (field.type === 'Number') {
          if (field.useInputNumberMin && field.inputNumberMin !== null) {
            formattedField.inputNumberMin = field.inputNumberMin;
          }
          if (field.useInputNumberMax && field.inputNumberMax !== null) {
            formattedField.inputNumberMax = field.inputNumberMax;
          }
          if (field.useMinFractionDigits && field.minFractionDigits !== null) {
            formattedField.minFractionDigits = field.minFractionDigits;
          }
          if (field.useMaxFractionDigits && field.maxFractionDigits !== null) {
            formattedField.maxFractionDigits = field.maxFractionDigits;
          }
          if (field.inputNumberShowButtons !== undefined) {
            formattedField.inputNumberShowButtons = field.inputNumberShowButtons;
          }
        }

        return formattedField;
      });

    // If showDefaultFields is true and this is not an edit operation, ensure default fields are included
    if (this.showDefaultFields && !this.isEdit) {
      // Get the names of fields already in the form
      const existingFieldNames = new Set(fields.map((f: any) => f.name));
      
      // Add any default fields that aren't already present
      const defaultFieldsToAdd = this.defaultFields
        .filter(df => !existingFieldNames.has(df.name))
        .map(df => ({
          ...df,
          enabled: true,
          readonly: true,
          fieldEnabled: true
        }));
      
      fields = [...fields, ...defaultFieldsToAdd];
    }

    // Sort all fields by position
    formattedValue.fields = fields.sort((a: any, b: any) => (a.position || 0) - (b.position || 0));

    return formattedValue;
  }

  isFieldReadonly(field: AbstractControl): boolean {
    return field.get('readonly')?.value === true;
  }

  canRemoveField(field: AbstractControl): boolean {
    return !field.get('readonly')?.value;
  }

  toggleField(field: AbstractControl, enabled: boolean) {
    if (enabled) {
      const controls = (field as FormGroup).controls;
      Object.keys(controls).forEach(key => {
        if (key !== 'fieldEnabled' && key !== 'readonly') {
          field.get(key)?.enable();
        }
      });
    } else {
      const controls = (field as FormGroup).controls;
      Object.keys(controls).forEach(key => {
        if (key !== 'fieldEnabled' && key !== 'readonly') {
          controls[key]?.disable();
        }
      });
    }
    // Set the fieldEnabled value
    field.get('fieldEnabled')?.setValue(enabled);
  }

  // Add method to validate field name format
  private isValidFieldName(name: string): boolean {
    return /^[A-Za-z_]+$/.test(name);
  }

  moveFieldUp(index: number): void {
    if (index <= 0) return;
    
    const fieldsArray = this.fields;
    const field = fieldsArray.at(index);
    fieldsArray.removeAt(index);
    fieldsArray.insert(index - 1, field);
    this.updatePositions();
  }

  moveFieldDown(index: number): void {
    const fieldsArray = this.fields;
    if (index >= fieldsArray.length - 1) return;
    
    const field = fieldsArray.at(index);
    fieldsArray.removeAt(index);
    fieldsArray.insert(index + 1, field);
    this.updatePositions();
  }

  private updatePositions(): void {
    const fieldsArray = this.fields;
    fieldsArray.controls.forEach((field, index) => {
      field.get('position')?.setValue(index + 1);
    });
  }

  canMoveUp(index: number): boolean {
    return index > 0;
  }

  canMoveDown(index: number): boolean {
    return index < this.fields.length - 1;
  }

  getUsedWidthInCurrentRow(currentIndex: number): number {
    let usedWidth = 0;
    let currentRowWidth = 0;
    
    // Loop through fields before the current one
    for (let i = 0; i < currentIndex; i++) {
      const field = this.fields.at(i);
      const fieldWidth = field.get('columnWidth')?.value || 0;
      
      // If adding this field would exceed 12, it's on a new row
      if (currentRowWidth + fieldWidth > 12) {
        currentRowWidth = fieldWidth;
      } else {
        currentRowWidth += fieldWidth;
      }
    }
    
    return currentRowWidth;
  }

  /**
   * Get validation error message for a field control
   * @param field The form group containing the field
   * @param controlName The name of the control to check
   * @returns The error message if any, empty string otherwise
   */
  getFieldError(field: AbstractControl, controlName: string): string {
    const control = field.get(controlName);
    if (control && control.errors && control.touched) {
      const messages = this.validationMessages[controlName];
      if (messages) {
        const errorType = Object.keys(control.errors)[0];
        return messages[errorType] || '';
      }
    }
    return '';
  }

  /**
   * Check if a field has any errors
   * @param field The form group containing the field
   * @param controlName The name of the control to check
   * @returns boolean indicating if the field has errors and has been touched
   */
  hasFieldError(field: AbstractControl, controlName: string): boolean {
    const control = field.get(controlName);
    return !!(control && control.errors && control.touched);
  }

  // Helper method to handle number configuration toggle subscriptions
  private setupNumberConfigToggleSubscription(fieldGroup: FormGroup, toggleControl: string, targetControl: string) {
    fieldGroup.get(toggleControl)?.valueChanges.subscribe(enabled => {
      this.handleNumberConfigToggle(fieldGroup, toggleControl, targetControl);
    });
  }

  // Helper method to handle enabling/disabling number configuration controls
  private handleNumberConfigToggle(fieldGroup: FormGroup, toggleControl: string, targetControl: string) {
    const isEnabled = fieldGroup.get(toggleControl)?.value;
    const control = fieldGroup.get(targetControl);
    
    if (isEnabled) {
      control?.enable();
    } else {
      control?.disable();
      control?.setValue(null);
    }
  }

  onMultiSelectTypeChange(field: AbstractControl) {
    const multiSelectType = field.get('multiSelectType')?.value;
    
    // Clear API-specific fields if switching to static
    if (multiSelectType === 'static') {
      field.patchValue({
        apiController: '',
        select: [],
        optionLabel: 'label',
        optionValue: 'value'
      });
    }
    
    // Clear static values if switching to API
    if (multiSelectType === 'api') {
      const valuesArray = field.get('options.values') as FormArray;
      while (valuesArray.length !== 0) {
        valuesArray.removeAt(0);
      }
    }
    
    // Update validation
    this.onInputWidgetChange(field);
  }
}