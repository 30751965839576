<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="endless-offer"
    [viewName]="title"
    [record]="endlessOfferRecord"
    [fields]="fields"
    [sidebarFields]="sidebarFields"
    [sidebarFields]="sidebarFields"
    [isNotesPopUp]="true"
    [parentViewName]="parentViewName"
>
</dynamic-view-v2>

