import { Component, AfterViewInit } from '@angular/core';
import { IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams, IDoesFilterPassParams } from 'ag-grid-community';

@Component({
  selector: 'app-include-exclude-filter',
  templateUrl: './include-exclude-filter.component.html',
  styleUrls: ['./include-exclude-filter.component.css']
})
export class IncludeExcludeFilterComponent implements IFilterAngularComp, AfterViewInit {
  includeSet: string[] = [];
  excludeSet: string[] = [];
  options: any[] = [];
  includeOptions: any[] = [];
  excludeOptions: any[] = [];
  entityLabel: string = "Keyword";
  includePlaceholder: string = "Include";
  excludePlaceholder: string = "Exclude";
  emptyFilter: string | null = null;
  private previousEmptyFilter: string | null = null;
  private params: any;

  agInit(params: any): void {
    this.params = params;
    // Initialize options from params
    this.options = params.values?.map((item: { name: any; id: any; _id: any; }) => ({
      label: `${item.name} (${item.id})`,
      value: item._id,
    })) || [];
    
    // Create deep copies of options for includeOptions and excludeOptions
    this.includeOptions = this.options.map(option => ({ ...option }));
    this.excludeOptions = this.options.map(option => ({ ...option }));
    
    this.includePlaceholder = `Include ${this.entityLabel}(s)`;
    this.excludePlaceholder = `Exclude ${this.entityLabel}(s)`;
  }

  ngAfterViewInit(): void {}

  isFilterActive(): boolean {
    return this.includeSet.length > 0 || this.excludeSet.length > 0 || this.emptyFilter !== null;
  }

  doesFilterPass(params: IDoesFilterPassParams): boolean {
    const cellValue = params.data[this.params.colDef.field];
    const includesPass = this.includeSet.every(keywordId => cellValue.some((item: { _id: string; }) => item._id === keywordId));
    const excludesPass = this.excludeSet.every(keywordId => !cellValue.some((item: { _id: string; }) => item._id === keywordId));
    return includesPass && excludesPass;
  }

  getModel() {
    // Return null if all filter values are empty
    if (this.includeSet.length === 0 && this.excludeSet.length === 0 && this.emptyFilter === null) {
      return null;
    }
  
    // Otherwise, return the filter model
    return {
      include: this.includeSet,
      exclude: this.excludeSet,
      emptyFilter: this.emptyFilter,
      filterType: "include-exclude",
    };
  }
  

  setModel(model: any): void {
    this.includeSet = model?.include || [];
    this.excludeSet = model?.exclude || [];
    this.emptyFilter = model?.emptyFilter || null;
    this.updateDisabledStates(); // Update disabled states on model set
  }

  onFilterChanged(field: string, value: any = null) {
    if (field === "emptyNonEmpty") {
      this.emptyFilter = this.previousEmptyFilter === value ? null : value;
      this.previousEmptyFilter = this.emptyFilter;
    }
    this.applyFilterChanges();
  }

  applyFilterChanges() {
    this.updateDisabledStates(); // Update the disabled states before applying filter
    this.params.filterChangedCallback(); // Apply filter to the grid
  }

  clearFilters() {
    this.includeSet = [];
    this.excludeSet = [];
    this.emptyFilter = null;
    this.previousEmptyFilter = null;
    this.applyFilterChanges(); // Apply reset to the grid
  }

  updateDisabledStates() {
    // Update `includeOptions` based on `excludeSet`
    this.includeOptions = this.options.map(option => ({
      ...option,
      disabled: this.excludeSet.includes(option.value), // Disable if selected in excludeSet
    }));

    // Update `excludeOptions` based on `includeSet`
    this.excludeOptions = this.options.map(option => ({
      ...option,
      disabled: this.includeSet.includes(option.value), // Disable if selected in includeSet
    }));
  }
}
