import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp, IFilterAngularComp } from 'ag-grid-angular';
import { IFilterParams } from 'ag-grid-community';
import moment from 'moment';

@Component({
  selector: 'app-time-cell-filter',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeCellEditorComponent implements ICellRendererAngularComp {
  private params: any;
  value: string | null = null;

  @ViewChild('timeInput', { static: true }) timeInput!: ElementRef;

  agInit(params: any): void {
    this.params = params;
    this.value = params.value; // Inicializar el valor con el de la celda
  }

  onTimeChange(): void {
    if (this.params && this.value !== null) {
      this.params.node.setDataValue(this.params.column.colId, this.value);
      this.params.api.refreshCells({ rowNodes: [this.params.node] });
    }
  }

  refresh(params: any): boolean {
    this.value = params.value;
    return true;
  }

  afterGuiAttached(): void {
    // Enfocar el input automáticamente cuando se abre la celda en edición
    setTimeout(() => this.timeInput.nativeElement.focus());
  }

  getValue(): string {
    return this.value || '';
  }
}
