<div
  *ngIf="!params.isLinkDestination"
  class="p-flex dropdown-container"
>
  <p-dropdown
    [(ngModel)]="value"
    [placeholder]="fieldName"
    [options]="options"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [filter]="true"
    [autofocusFilter]="true"
    [autoDisplayFirst]="false"
    [virtualScroll]="true"
    class="dropdown-field"
  >
    <ng-template let-option pTemplate="item">
      {{ option[optionLabel] }} {{option.id ? ' ('+option.id+')' : ''}}
    </ng-template>
    <ng-template let-option pTemplate="selectedItem">
      {{ option[optionLabel] }} {{option.id ? ' ('+option.id+')' : ''}}
    </ng-template>
  </p-dropdown>
  <div class="dropdown-actions">
    <button
      pButton
      pRipple
      ariaLabel="Revert Nested Values"
      icon="pi pi-undo"
      class="p-button-text p-button-xs"
      tabindex="-1"
      (click)="setOriginalValue()"
    ></button>
    <button
      pButton
      pRipple
      ariaLabel="Clear Field"
      icon="pi pi-minus"
      tabindex="-1"
      class="p-button-text p-button-danger p-button-xs"
      (click)="clearValue()"
    ></button>
  </div>
</div>
<div
  *ngIf="params.isLinkDestination"
  class="p-flex dropdown-container"
>
  <p-dropdown
    [(ngModel)]="value"
    [placeholder]="fieldName"
    [options]="options"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [filter]="true"
    [autofocusFilter]="true"
    [autoDisplayFirst]="false"
    [virtualScroll]="true"
    class="dropdown-field"
  >
    <ng-template let-option pTemplate="item">
      {{ option[optionLabel] }} {{option.id ? ' ('+option.id+')' : ''}}
    </ng-template>
    <ng-template let-option pTemplate="selectedItem">
      {{ option[optionLabel] }} {{option.id ? ' ('+option.id+')' : ''}}
    </ng-template>
  </p-dropdown>
  <!-- <button
    pButton
    pRipple
    ariaLabel="Revert Nested Values"
    icon="pi pi-undo"
    class="p-mx-1 p-button-text p-button-xs p-flex-shrink-0"
    tabindex="-1"
    (click)="setOriginalValue()"
  ></button>
  <button
    pButton
    pRipple
    ariaLabel="Clear Field"
    icon="pi pi-minus"
    tabindex="-1"
    class="p-button-text p-button-danger p-button-xs p-flex-shrink-0"
    (click)="clearValue()"
  ></button> -->
</div>


