import { Component, OnInit } from '@angular/core';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-release-garden-pass',
  templateUrl: './release-garden-pass.component.html',
  styleUrls: ['./release-garden-pass.component.sass']
})
export class ReleaseGardenPassComponent extends BaseDynamicViewComponent implements OnInit {
  seasonPassRecord: BaseEntityDto = new BaseEntityDto();
  entity: any;
  options: any = {};
  prizeCounts: { [key: string]: number } = {};
  parentViewName: string = 'releaseSeries'

  constructor(
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService
  ) {
    super();
  }
  async ngOnInit(): Promise<void> {
    this.fields = [
      {
        title: 'Garden Pass Data',
        fields: [
          {
            name: 'Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            columnWidth: 6,
          },
          {
            name: 'Internal Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'internalName',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 6,
          },           
          {
            name: 'Start Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            columnWidth: 6,
          },
          {
            name: 'End Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            columnWidth: 6,
          },
          {
            name: 'Internal Notes',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.RichText],
            clearField: false,
            key: 'copy',
            label: 'Internal Notes',
            columnWidth: 12,
          },
          {
            name: 'Free Stage Milestone',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'free.stageMilestone',
            label: 'Free Stage Milestone',
            columnWidth: 6,
          },
          {
            name: 'Paid Stage Milestone',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'paid.stageMilestone',
            label: 'Paid Stage Milestone',
            columnWidth: 6,
          },
          {
            name: 'Free Rewards Tracks',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Link],
            key: 'free',
            label: 'Free Rewards Tracks',
            controller: 'rewards-track',
            columnWidth: 6,
          },
          {
            name: 'Paid Rewards Tracks',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Link],
            key: 'paid',
            label: 'Paid Rewards Tracks',
            controller: 'rewards-track',
            columnWidth: 6,
          },
          
        ],
        type: DynamicCardType.Card,
      },
      {
        title: 'Rewards Tracks',
        fields: [
          {
            name: 'Reward Timeline',
            inputType: DynamicViewType.RewardTimeline,
            key: 'rewardTimeline', 
            label: 'Reward Timeline',
            columnWidth: 12,
          },
        ],
        type: DynamicCardType.Card,
      },
    ]
    this.sidebarFields = [
      {
        title: "Images Preview",
        fields:
        [
          { name: "Banner Image",
            inputType: DynamicViewType.sideThumbnail,
            key: 'bannerImage',
            label: 'bannerImage',
            options: {gridWidth: 12, fieldName: '' },
            columnWidth: 12
          },
          { name: "Background Image",
            inputType: DynamicViewType.sideThumbnail,
            key: 'backgroundImage_ref',
            label: 'Background Image',
            options: {gridWidth: 12, fieldName: '' },
            columnWidth: 12
          },
          { name: "Midground Image",
            inputType: DynamicViewType.sideThumbnail,
            key: 'midgroundImage_ref',
            label: 'Midground Image',
            options: {gridWidth: 12, fieldName: '' },
            columnWidth: 12
          },
          { name: "Foreground Image",
            inputType: DynamicViewType.sideThumbnail,
            key: 'foregroundImage_ref',
            label: 'Foreground Image',
            options: {gridWidth: 12, fieldName: '' },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
    ]
    this.seasonPassRecord = {
      enabled: false,
    };

    this.title = 'Releases Garden Pass';
    this.viewRoute = 'releases-season-pass';
    this.isLoading = false;
  }
}
