<div class="p-d-flex-column p-ai-center p-jc-center responsive-cell-padding">
  <!-- Add isSourcingItem condition at the top level, if it affects the entire block -->
  <ng-container *ngIf="params.isSourcingItem && this.params.data.sourcingGroup_ref ; else notSourcingItem">
    <!-- Custom rendering for when isSourcingItem is true -->
    <a
      *ngIf="params.value && params.data.sourcingGroup_ref"
      target="_blank"
      class="p-text-normal p-d-block p-text-reduced-line-height"
      [ngStyle]="{ color: 'var(--primary-color)' }" href="{{ getLink(params.value) }}">
      <span *ngIf="!params.noID">({{params.value}})</span>
    </a>
  </ng-container>
  <ng-template #notSourcingItem>
    <!-- Original structure for when isSourcingItem is not true or not applicable -->
    <ng-container *ngIf="params.isGuaranteedRarity">
      <a *ngIf="params.value" target="_blank"  class="p-text-normal p-d-block p-text-reduced-line-height"
      [ngStyle]="{ color: 'var(--primary-color)' }" href="{{ getLink(params.value.id) }}">
     {{ params.value.name }} <span *ngIf="!params.noID">({{params.value.id}})</span>
   </a>
    </ng-container>
    <ng-container *ngIf="params.isArray; else singleValue">
      <div *ngIf="params.isLinkDestination; else arrayLinks">
        <span *ngFor="let value of params.value"  class="p-text-normal p-d-block p-text-reduced-line-height"
              [ngStyle]="{ color: 'var(--primary-color)' }">
          {{ value.name }} <span *ngIf="!params.noID">({{value.id}})</span>
        </span>
      </div>
      <!-- problem is here -->
      <ng-template #arrayLinks>
        <div *ngIf="!params.onClick" class="scrollable-cell">
          <a *ngFor="let value of params.value" target="_blank" class="p-text-normal p-d-block p-text-reduced-line-height"
             [ngStyle]="{ color: 'var(--primary-color)' }" href="{{ getLink(value.id) }}">
             <ng-container *ngIf="params.extractNameFromOptions; else normalRef">
              {{ extractNameFromOptions(value.id) }} <span *ngIf="!params.noID">({{value.id}})</span>
             </ng-container>
             <ng-template #normalRef>
              {{ value.name }} <span *ngIf="!params.noID">({{value.id}})</span>
             </ng-template> 
          </a>
        </div>
        <div *ngIf="params.onClick" class="scrollable-cell">
          <a *ngFor="let value of params.value" target="_blank" class="p-text-normal p-d-block p-text-reduced-line-height"
             [ngStyle]="{ color: 'var(--primary-color)' }" href="#" (click)="handleClick($event, params.data)">
            {{ value.name }} <span *ngIf="!params.noID">({{value.id}})</span>
          </a>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #singleValue>
      <ng-container *ngIf="params.isLinkDestination; else singleLink">
        <span *ngIf="params.value" class="p-text-normal p-d-block">
          {{ params.value.linkText }} <span *ngIf="!params.noID">({{params.value.id}})</span>
        </span>
      </ng-container>
      <ng-template #singleLink>
        <a *ngIf="params.value" target="_blank"  class="p-text-normal p-d-block p-text-reduced-line-height"
           [ngStyle]="{ color: 'var(--primary-color)' }" href="{{ getLink(params.value?.id) }}">
          {{ params.value?.name }} <span *ngIf="!params.noID">({{params.value?.id}})</span>
        </a>
      </ng-template>
    </ng-template>
  </ng-template>
</div>
