<h1>{{ isEditMode ? 'Editing Challenge ' + id : 'Add New Challenge' }}</h1>
<form *ngIf="fields && !loading" [formGroup]="challengeForm" (ngSubmit)="beforeSubmit()">
  <ng-container>
    <div class="p-grid form-container">
      <div class="p-col-12">
        <button pButton pRipple type="submit" label="Submit" [disabled]="!challengeForm.valid" class="p-mb-4"
          [style]="{ width: '20%', 'min-width': '12.5em' }"></button>
        <div class="p-d-flex p-flex-column p-ai-center">
          <div class="p-mb-4" style="width: 60%">
            <p-toggleButton formControlName="enabled" [onLabel]="'Enabled'" [offLabel]="'Not Enabled'"
              [style]="{ width: '100%' }" class="field-item"></p-toggleButton>
          </div>
          <div style="width: 60%">
            <p-toggleButton formControlName="isInfiniteChallengeExcluded" [onLabel]="'Infinite Gardens Excluded'"
              [offLabel]="'Not Excluded from Infinite Gardens'" [style]="{ width: '100%' }"
              class="field-item"></p-toggleButton>
          </div>
        </div>
        <!-- CARD: Top Details -->
        <p-card class="p-mx-2">
          <div class="p-grid p-mx-5">
            <div class="p-col">
              <!-- name -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <input formControlName="name" pInputText class="field-item" [required]="true" />
                  <label for="name">
                    Name <strong style="color: crimson">*</strong></label>
                </span>
                <p-button *ngIf="challengeForm.value['name']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('name')"></p-button>
              </div>
            </div>
          </div>
        </p-card>
        <!-- CARD: 'Production' -->
        <p-card class="p-mx-2">
          <div>
            <span class="p-card-title p-d-inline p-mx-5" id="require-fields">Production</span>
            <p-toggleButton [onLabel]="'Advanced'" [offLabel]="'Advanced'" [style]="{ width: '17%' }" class="field-item"
              [(ngModel)]="advancedPath" [ngModelOptions]="{standalone: true}"></p-toggleButton>
          </div>
          <div class="p-grid p-mx-5">
            <div class="p-col">
              <!-- scene -->
              <div *ngIf="advancedPath" class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <input formControlName="scene" pInputText class="field-item" [(ngModel)]="fields.scene"
                    (input)="challengeValidationService.handleAutomationLock('scene', fields)" 
                    [pTooltip]="challengeConstants.filePathTooltip" tooltipPosition="top" />
                  <label for="scene"> File Path </label>
                </span>
                <p-button *ngIf="challengeForm.value['scene']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger"
                  (click)="clearField('scene'); challengeValidationService.handleAutomationLock('scene', fields)"></p-button>
              </div>
              <div *ngIf="!advancedPath" class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <input pInputText class="field-item" [(ngModel)]="simplePathScene"
                    [ngModelOptions]="{standalone: true}" (input)="updateSimplePath('scene', fields, challengeForm)" 
                    [pTooltip]="challengeConstants.filePathTooltip" tooltipPosition="top" />
                  <label for="scene"> File Path </label>
                </span>
                <p-button *ngIf="simplePathScene" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger"
                  (click)="simplePathScene = ''; updateSimplePath('scene', fields, challengeForm)"></p-button>
              </div>
              <!-- image -->
              <div *ngIf="advancedPath" class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <input formControlName="image" pInputText class="field-item" [(ngModel)]="fields.image"
                    (input)="challengeValidationService.handleAutomationLock('image', fields)" />
                  <label for="image"> Feed Image </label>
                </span>
                <p-button *ngIf="challengeForm.value['image']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger"
                  (click)="clearField('image'); challengeValidationService.handleAutomationLock('image', fields)"></p-button>
              </div>
              <div *ngIf="!advancedPath" class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <input pInputText class="field-item" [(ngModel)]="simplePathImage"
                    [ngModelOptions]="{standalone: true}" (input)="updateSimplePath('image', fields, challengeForm)" />
                  <label for="scene"> Feed Image </label>
                </span>
                <p-button *ngIf="simplePath" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger"
                  (click)="simplePathImage = ''; updateSimplePath('image', fields, challengeForm)"></p-button>
              </div>
            </div>
          </div>

          <div class="p-grid p-mx-5">
            <!-- 'Production': bottom, left column -->
            <div class="p-col-6 p-pr-4">
              <!-- Environment(s) -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-multiSelect formControlName="env" [options]="options['envs']" display="chip"
                    [style]="{ width: '100%' }"
                    (onChange)="markFieldAsTouched('env')"
                    (onClear)="markFieldAsTouched('env')"
                  >
                  </p-multiSelect>
                  <label for="env"> Environment(s) </label>
                </span>
                <p-button *ngIf="challengeForm.value['env']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('env')"></p-button>
              </div>
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <input #inputFileName [(ngModel)]="fields.fileName" formControlName="fileName" pInputText
                    class="field-item" (keyup)="
                    onPathComponentValueChange(
                      'fileName',
                      { value: inputFileName.value }
                    )
                  " />
                  <label for="fileName"> File Name </label>
                </span>
                <p-button *ngIf="challengeForm.value['fileName']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('fileName')"></p-button>
              </div>
              <!-- year -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-dropdown #yearInput formControlName="year" [options]="options['year']" [autoDisplayFirst]="false"
                    [filter]="true" (onChange)="
                    onPathComponentValueChange(
                      'year',
                      {value: yearInput.value}
                    )
                  "></p-dropdown>
                  <label for="year"> Year </label>
                </span>

                <p-button *ngIf="challengeForm.value['year']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('year')"></p-button>
              </div>
              <!-- File Type -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-dropdown formControlName="fileType" [options]="options['fileType']" [autoDisplayFirst]="false"
                    [filter]="true"></p-dropdown>
                  <label for="fileType"> File Type </label>
                </span>
                <p-button *ngIf="challengeForm.value['fileType']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('fileType')"></p-button>
              </div>
              <!-- Keywords -->
              <div class="p-my-5">
                <span ngClass="p-float-label">
                  <p-multiSelect [(ngModel)]="keywords_ref" [ngModelOptions]="{standalone: true}"
                    [options]="options['keywords_ref']" optionLabel="name" [filter]="true"
                    (onChange)="markFieldAsTouched('keywords_ref')"
                    (onClear)="markFieldAsTouched('keywords_ref')"></p-multiSelect>
                  <label for="keywords_ref"> Keywords </label>
                </span>
              </div>
              <div class="p-my-5">
                <span ngClass="p-float-label">
                  <p-multiSelect [formControlName]="'collection_ref'" [options]="options['collection_ref']"
                    optionLabel="name" display="chip" [style]="{ width: '100%' }"
                    (onChange)="markFieldAsTouched('collection_ref')"
                    (onClear)="markFieldAsTouched('collection_ref')"></p-multiSelect>
                  <label for="collection_ref"> Collection </label>
                </span>
              </div>
            </div>
            <!-- 'Production': bottom, right column -->
            <div class="p-col-6">
              <!-- audio -->
              <div class="p-mx-2" header="Audio">
                <ng-container>
                  <div class="p-mt-5 p-inputgroup">
                    <span ngClass="p-float-label">
                      <span ngClass="p-float-label">
                        <p-multiSelect [formControlName]="'spawnAudios_ref'" [options]="options['spawnAudios_ref']"
                          optionLabel="name" optionValue="_id" display="chip" [style]="{ width: '100%' }"
                          (onChange)="markFieldAsTouched('spawnAudios_ref')"
                          (onClear)="markFieldAsTouched('spawnAudios_ref')"></p-multiSelect>
                        <label [for]="'spawnAudios_ref'">Spawn Audio</label>
                      </span>
                      <p-button *ngIf="challengeForm.value['spawnAudios_ref']" ariaLabel="Clear Field"
                        icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                        (click)="clearField('spawnAudios_ref')"></p-button>
                    </span>
                  </div>
                  <div class="p-mt-5 p-inputgroup">
                    <span ngClass="p-float-label">
                      <p-multiSelect [formControlName]="'spawnAudioCollections_ref'"
                        [options]="options['spawnAudioCollections_ref']" optionLabel="name" optionValue="_id"
                        display="chip" [style]="{ width: '100%' }"
                        (onChange)="markFieldAsTouched('spawnAudioCollections_ref')"
                        (onClear)="markFieldAsTouched('spawnAudioCollections_ref')"></p-multiSelect>
                      <label [for]="'spawnAudioCollections_ref'">Spawn Audio Collection</label>
                    </span>
                    <p-button *ngIf="challengeForm.value['spawnAudioCollections_ref']" ariaLabel="Clear Field"
                      icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                      (click)="clearField('spawnAudioCollections_ref')"></p-button>
                  </div>
                </ng-container>
                <ng-container>
                  <div class="p-mt-5 p-inputgroup">
                    <span ngClass="p-float-label">
                      <span ngClass="p-float-label">
                        <p-multiSelect [formControlName]="'loopAudios_ref'" [options]="options['loopAudios_ref']"
                          optionLabel="name" optionValue="_id" display="chip" [style]="{ width: '100%' }"
                          (onChange)="markFieldAsTouched('loopAudios_ref')"
                          (onClear)="markFieldAsTouched('loopAudios_ref')"></p-multiSelect>
                        <label [for]="'loopAudios_ref'">Loop Audio</label>
                      </span>
                      <p-button *ngIf="challengeForm.value['loopAudios_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                        styleClass="p-button-text p-button-danger" (click)="clearField('loopAudios_ref')"></p-button>
                    </span>
                  </div>
                  <div class="p-mt-5 p-inputgroup">
                    <span ngClass="p-float-label">
                      <p-multiSelect [formControlName]="'loopAudioCollections_ref'"
                        [options]="options['loopAudioCollections_ref']" optionLabel="name" optionValue="_id"
                        display="chip" [style]="{ width: '100%' }"
                        (onChange)="markFieldAsTouched('loopAudioCollections_ref')"
                        (onClear)="markFieldAsTouched('loopAudioCollections_ref')"></p-multiSelect>
                      <label [for]="'loopAudioCollections_ref'">Loop Audio Collection</label>
                    </span>
                    <p-button *ngIf="challengeForm.value['loopAudioCollections_ref']" ariaLabel="Clear Field"
                      icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                      (click)="clearField('loopAudioCollections_ref')"></p-button>
                  </div>
                  <div class="p-grid p-mt-3">
                    <ng-container *ngFor="let bundle of bundleControls">
                      <div class="p-col-3">
                        <p style="font-size: 14px; text-align: center" class="p-mb-2">{{bundle.name}}</p>
                        <div class="p-d-flex p-jc-center">
                          <p-inputSwitch class="" [formControlName]="bundle.control"></p-inputSwitch>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>


          </div>
        </p-card>
        <!-- CARD: 'Content' -->
        <p-card class="p-mx-2">
          <span class="p-card-title p-mx-5" id="content-fields">Content</span>
          <div class="p-grid p-mx-5">
            <!-- 'Content': left column -->
            <div class="p-col-6 p-pr-4">
              <!-- type_ref -->
              <div class="p-my-5 p-inputgroup">
                <div class="p-grid" style="width: 100%">
                  <div class="p-col-9 p-d-flex">
                    <span ngClass="p-float-label">
                      <p-dropdown #challengeType formControlName="type_ref" [options]="options['type_ref']" optionLabel="name"
                        optionValue="_id" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'" (onChange)="
                        onPathComponentValueChange(
                          'challengeType',
                          { value: challengeType.value }
                        ); onChangeTypeRef($event)
                        "></p-dropdown>
                      <label for="type_ref">
                        Challenge Type
                        <strong style="color: crimson">*</strong></label>
                    </span>
                    <p-button *ngIf="challengeForm.value['type_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField('type_ref')"></p-button>
                  </div>
                  <div class="p-col-3 p-d-flex inputgroup">
                    <p-colorPicker #foreColor class="p-pt-1" formControlName="typeColor"></p-colorPicker>
                    <input pInputText class="p-ml-1 field-item" (change)="onColorChanged(foreColor)"
                      [(ngModel)]="foreColor.inputBgColor" [ngModelOptions]="{standalone: true}" />
                    <p-button *ngIf="challengeForm.value['color']" ariaLabel="Clear Field" icon="pi pi-replay"
                      styleClass="p-button-text p-button-success"
                      (click)="setOriginal('typeColor'); setOriginal('type_ref')"></p-button>
                  </div>
                </div>
              </div>
              <!-- sceneType -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-dropdown formControlName="sceneType" [options]="options['scene_type_ref']"
                    [autoDisplayFirst]="false" [filter]="true" optionLabel="name" optionValue="_id"
                    [autoDisplayFirst]="false" [filter]="true" filterBy="name" (onChange)="
                    onPathComponentValueChange(
                      'sceneType',
                      $event
                    )
                  "></p-dropdown>
                  <label for="sceneType"> Scene Type </label>
                </span>
                <p-button *ngIf="challengeForm.value['sceneType']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('sceneType')"></p-button>
              </div>
              <!-- color -->
              <!-- <div class="p-my-5 p-inputgroup">Color:</div> -->
              <!-- climate_ref -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-dropdown formControlName="climate_ref" [options]="options['climate_ref']" optionLabel="name"
                    optionValue="_id" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'">
                  </p-dropdown>
                  <label for="climate_ref">
                    Climate <strong style="color: crimson">*</strong></label>
                </span>
                <p-button *ngIf="challengeForm.value['climate_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('climate_ref')"></p-button>
              </div>
              <!-- location -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <input formControlName="location" pInputText class="field-item" />
                  <label for="location"> Location </label>
                </span>
                <p-button *ngIf="challengeForm.value['location']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('location')"></p-button>
              </div>
              <!-- level lock -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-dropdown formControlName="progressionLevel_ref" [options]="options['progressionLevel_ref']"
                    optionLabel="name" optionValue="_id" [autoDisplayFirst]="false" [filter]="true"
                    [filterBy]="'name'"></p-dropdown>
                  <label for="progressionLevel_ref"> Level Lock </label>
                </span>
                <p-button *ngIf="challengeForm.value['progressionLevel_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('progressionLevel_ref')"></p-button>
              </div>
              <!-- start -->
              <ng-container *ngFor="let dateField of dateFields">
                <div class="p-my-5 p-inputgroup">
                  <span ngClass="p-float-label" style="flex: 1;">
                    <p-calendar [formControlName]="dateField.name"
                      [showTime]="true"
                      [yearNavigator]="true"
                      yearRange="2020:2030"
                      [monthNavigator]="true"
                      class="field-item"
                      [selectOtherMonths]="true"
                      [defaultDate]="defaultDate"
                      [keepInvalid]="true"
                      [minDate]="dateField.name === 'end' ? challengeForm.get('start')!.value : null"
                      (onSelect)="handleDateSelect(dateField.name, $event)"
                      (onInput)="handleDateInputChange(dateField.name,$event)"
                      [style]="{width: '100%'}">
                      <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                        <h5 class="p-d-inline">Local Time: </h5>
                        <small>{{ challengeForm.value[dateField.name] ? 
                          (challengeForm.value[dateField.name] | date: 'medium') : '' }}</small>
                      </ng-template>
                    </p-calendar>
                    <label [for]="dateField.name">{{dateField.label}}</label>
                  </span>
                  <div style="width: 2rem;"><!-- Spacer for consistent width -->
                    <p-button *ngIf="challengeForm.value[dateField.name]"
                      ariaLabel="Clear Field"
                      icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger"
                      (click)="clearField(dateField.name)"></p-button>
                  </div>
                  <div style="width: 2rem;"><!-- Spacer for consistent width -->
                    <p-button *ngIf="challengeForm.value[dateField.name] && challengeForm.controls[dateField.name].touched"
                      ariaLabel="Undo Change"
                      icon="pi pi-undo"
                      styleClass="p-button-text p-button-primary"
                      (click)="revertField(dateField.name)">
                    </p-button>
                  </div>
                </div>
              </ng-container>
              <div class="p-grid p-mt-3 p-mb-4">
                <!-- Vote Start/End -->
                <ng-container *ngFor="let vote of [{name: 'Start', control: 'voteStart'}, {name: 'End', control: 'voteEnd'}]">
                  <div class="p-col-6">
                    <span ngClass="p-float-label">
                      <p-calendar [formControlName]="vote.control" [disabled]="true" [showTime]="true" [yearNavigator]="true"
                        yearRange="2020:2030" [monthNavigator]="true" class="field-item" [selectOtherMonths]="true"
                        pTooltip="*Auto-Generated based on Start/End Dates">
                        <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                          <h5 class="p-d-inline">Local Time: </h5><small>{{ challengeForm.value[vote.control] ?
                            (challengeForm.value[vote.control] | date: 'medium') : '' }}</small>
                        </ng-template>
                      </p-calendar>
                      <label for="end"> Vote {{vote.name}} Date/Time </label>
                    </span>
                  </div>
                </ng-container>
              </div>
            </div>

            <!-- 'Content': right column -->
            <div class="p-col-6 p-pl-4">
              <!-- progressionLevel_ref -->

              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <textarea formControlName="description" pInputTextarea cols="40" rows="3"
                    class="field-item"></textarea>
                  <label for="description"> Challenge Copy </label>
                </span>
                <p-button *ngIf="challengeForm.value['description']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('description')"></p-button>
              </div>
              <!-- descriptionShort -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <textarea formControlName="descriptionShort" pInputTextarea cols="40" rows="3"
                    class="field-item"></textarea>
                  <label for="descriptionShort"> Feed Image Copy </label>
                </span>
                <p-button *ngIf="challengeForm.value['descriptionShort']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('descriptionShort')"></p-button>
              </div>
              <!-- Associated Challenges -->
              <div class="p-grid">
                <div class="p-col-12">
                  <h3>Associated Challenges</h3>
                </div>
                <div class="p-col-12">
                  <div class="p-grid">
                    <div class="p-col-6">
                      <div class="p-inputgroup">
                        <span ngClass="p-float-label" class="p-mr-2">
                          <p-autoComplete [suggestions]="suggestions['parent_challenge']"
                            (completeMethod)="utilitiesService.getSuggestionsForRef(suggestions, $event.query, 'parent_challenge', 'challenges', true)"
                            [showEmptyMessage]="true" (onSelect)="onChallengeSelect($event, 1)"
                            formControlName="parent_challenge" minLength="2" [style]="{ 'width': '100%' }">

                            <ng-template let-ca pTemplate="item">
                              {{ ca.name }} ({{ ca.id }})
                            </ng-template>

                            <ng-template let-ca pTemplate="selectedItem">
                              {{ ca.name }} ({{ ca.id }})
                            </ng-template>

                          </p-autoComplete>
                          <label for="parent_challenge">Parent Challenge</label>
                          <p-button *ngIf="challengeForm.value['parent_challenge']" ariaLabel="Clear Field"
                          icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                          (click)="clearField('parent_challenge')"></p-button>
                        </span>
                        
                      </div>
                    </div>
                    <div class="p-col-6">
                      <div class="p-inputgroup">
                        <span ngClass="p-float-label">
                          <p-autoComplete [suggestions]="suggestions['child_challenge']"
                            (completeMethod)="utilitiesService.getSuggestionsForRef(suggestions, $event.query, 'child_challenge', 'challenges', true)"
                            [showEmptyMessage]="true" (onSelect)="onChallengeSelect($event, 2)"
                            formControlName="child_challenge" minLength="2" [style]="{ 'width': '100%' }">

                            <ng-template let-cc pTemplate="item">
                              {{ cc.name }} ({{ cc.id }})
                            </ng-template>

                            <ng-template let-cc pTemplate="selectedItem">
                              {{ cc }}
                            </ng-template>

                          </p-autoComplete>
                          <label for="child_challenge">Child Challenge</label>
                          <p-button *ngIf="challengeForm.value['child_challenge']" ariaLabel="Clear Field"
                          icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                          (click)="clearField('child_challenge')"></p-button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- contentHold_ref -->
              <div class="p-mt-3 p-mb-2 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-multiSelect formControlName="contentHold_ref" [options]="options['contentHold_ref']"
                    optionLabel="name" optionValue="_id" display="chip" [style]="{ width: '100%' }"
                    (onChange)="markFieldAsTouched('contentHold_ref')"
                    (onClear)="markFieldAsTouched('contentHold_ref')">
                  </p-multiSelect>
                  <label for="contentHold_ref"> Content Hold </label>
                </span>
                <p-button *ngIf="challengeForm.value['contentHold_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('contentHold_ref')"></p-button>
              </div>
              <!-- sponsor_ref -->
              <div class="p-my-5 p-inputgroup">
                <span ngClass="p-float-label">
                  <p-dropdown formControlName="sponsor_ref" [options]="options['sponsor_ref']" optionLabel="name"
                    optionValue="_id" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'">
                  </p-dropdown>
                  <label for="sponsor_ref">Sponsor</label>
                </span>
                <p-button *ngIf="challengeForm.value['sponsor_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('sponsor_ref')"></p-button>
              </div>
              <!--  -->
            </div>
          </div>
          <!-- description -->

        </p-card>
        <!-- CARD: 'Requirements' -->
        <p-card class="p-mx-2">
          <span class="p-card-title p-mx-5">Requirements</span>

          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-12" style="text-align: right;">
                  <p-tag *ngIf="showCoinConfig || showWaterConfig" class="p-m-1" icon="pi pi-info-circle"
                    value="Entry Payout info has been loaded from Game Config with the following values">
                  </p-tag>
                  <p-tag *ngIf="showCoinConfig || showWaterConfig" class="p-m-1"
                    [style]="{ backgroundColor: '#ffcc00', borderColor: '#ff9900' }" icon="pi pi-info-circle"
                    value="Changing Values will override Game Config">
                  </p-tag>
                </div>
                <div class="p-col-12 p-xl-6">
                  <div [ngStyle]="{'width': '100%'}" class="p-d-flex p-jc-center">
                    <span>
                      <prizes-card-form-group [(prize)]="this.prizeReward.prizes" [showPrize]="true" version="3"
                        [fieldsetName]="'Prize(s)'" [isChallenge]="true" (onPrizeChange)="this.prizeReward.prizes = $event"
                        [isChallengePrize]="true" (onChallengePrizeChange)="onPrizeSelect($event)">
                      </prizes-card-form-group>
                    </span>
                  </div>
                </div>
                <div class="p-col-12 p-xl-6">
                  <div [ngStyle]="{'width': '100%'}" class="p-d-flex p-jc-center">
                    <span>
                      <prizes-card-form-group [(prize)]="this.prizeReward.rewards" [showPrize]="true" version="3"
                        [fieldsetName]="'Entry Payout'" (onPrizeChange)="onRewardsRefChange($event)">
                      </prizes-card-form-group>
                    </span>
                  </div>
                </div>
                <div class="p-mx-5 p-mt-3 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-autoComplete formControlName="restrictions_ref" [suggestions]="suggestions['restrictions_ref']"
                      [showEmptyMessage]="true" field="name" minLength="3" multiple="true" [style]="{ width: '100%' }"
                      (completeMethod)="
                      utilitiesService.getSuggestionsForRef(
                        suggestions,
                        $event.query,
                        'restrictions_ref',
                        'restrictions'
                      )
                    ">
                      <ng-template let-restriction pTemplate="selectedRestriction">
                        {{ restriction.name }} ({{ restriction.id }})
                      </ng-template>

                      <ng-template let-restriction pTemplate="restriction">
                        {{ restriction.name }} ({{ restriction.id }})
                      </ng-template>
                    </p-autoComplete>
                    <label for="restrictions_ref"> Restrictions </label>
                  </span>
                  <p-button *ngIf="challengeForm.value['restrictions_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                    styleClass="p-button-text p-button-danger" (click)="clearField('restrictions_ref')"></p-button>
                  <p-button ariaLabel="Clear Field" icon="pi pi-plus" styleClass="p-button-text p-button-success"
                    (click)="newRestriction()"></p-button>
                </div>
              </div>
            </div>
          </div>
        </p-card>
        <p-card class="p-mx-2">
          <span class="p-card-title p-mx-5"> Internal Notes</span>
          <div class="p-mx-5">
            <!-- internalNotes -->
            <div class="p-my-5 p-inputgroup p-d-flex p-jc-center">
              <p-editor formControlName="internalNotes" [style]="{'height':'200px', 'width':'50vw'}">
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <select type="button" class="ql-header p-mr-2" aria-label="Header"></select>
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline p-mr-2" aria-label="Underline"></button>
                    <select type="button" class="ql-color" aria-label="Color"></select>
                    <select type="button" class="ql-background p-mr-2" aria-label="Background Color"></select>
                    <button type="button" value="ordered" class="ql-list" aria-label="Underline"></button>
                    <button type="button" value="bullet" class="ql-list" aria-label="Underline"></button>
                    <button type="button" class="ql-link p-mr-2" aria-label="Underline"></button>
                  </span>
                </ng-template>
              </p-editor>
              <p-button *ngIf="challengeForm.value['internalNotes']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger p-mx-1" (click)="clearField('internalNotes')"></p-button>
            </div>
          </div>
          <span class="p-card-title p-mx-4">Dev</span>
          <div class="p-grid p-mx-5">
            <div class="p-col-12 p-pr-4">
              <!-- artist_ref and tester_ref -->
              <div class="p-grid">
                <div class="p-col-6" *ngFor="let field of ['artist_ref', 'tester_ref']">
                  <div class="p-mt-5 p-inputgroup">
                    <span ngClass="p-float-label">
                      <p-dropdown [formControlName]="field" [options]="options[field]" optionLabel="name"
                        optionValue="_id" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'">
                      </p-dropdown>
                      <label [for]="field">{{field === 'artist_ref' ? 'Artist' : 'Tester'}}</label>
                    </span>
                    <p-button *ngIf="challengeForm.value[field]" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField(field)"></p-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </p-card>
        <button pButton pRipple type="submit" label="Submit" [disabled]="!challengeForm.valid" class="p-m-5"
          [style]="{ width: '20%', 'min-width': '12.5em' }"></button>
      </div>
    </div>
  </ng-container>
  <!-- Prize Automator -->
  <p-confirmDialog #cd [style]="{ width: '50vw' }">
    <ng-template pTemplate="header">
      <h2 *ngIf="selectedItem"><a class='p-button-link p-text-left' href="/items/{{selectedItem.id}}" target="_blank"
          [ngStyle]="{color:'var(--primary-color)'}"><strong>{{selectedItem.name}} ({{selectedItem.id}})</strong></a>
      </h2>
    </ng-template>
    <ng-template pTemplate="footer">
      <div *ngIf="!hideBypass" class="p-field-checkbox">
        <p-checkbox formControlName="bypassChecked" [(ngModel)]="fields.bypassChecked" binary="true"></p-checkbox>
        <label for="binary">{{fields.bypassChecked ? 'Bypass Prize Automator Enabled' : 'Bypass Prize Automator Not
          Enabled'}}</label>
      </div>
      <button type="button" pButton icon="pi pi-times" label="{{showOk ? 'OK': 'No'}}" (click)="cd.reject()"></button>
      <button *ngIf="(!this.prizeRefWarningDialog || fields.bypassChecked || !hideBypass) && !showOk" type="button"
        pButton icon="pi pi-check" label="Yes" (click)="cd.accept()"></button>
    </ng-template>
  </p-confirmDialog>
</form>
<p-dialog *ngIf="!loading" header="Start/End Date Verification" [(visible)]="showDatesVerificationModal" [modal]="true"
  [style]="{minWidth: '40vw'}">
  <div>
    <h4 class="p-m-0">Original Start and End date values</h4>
  </div>
  <div class="p-grid p-mt-0">
    <div class="p-col">
      <h5>Start:</h5>
      <p *ngIf="originalRecord['start']">{{challengeValidationService.formatDate(originalRecord['start'])}}</p>
    </div>
    <div class="p-col">
      <h5>End:</h5>
      <p *ngIf="originalRecord['end']">{{challengeValidationService.formatDate(originalRecord['end'])}}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <h4 class="p-m-0">New Start and End date values</h4>
  </div>
  <div class="p-grid">
    <div class="p-col">
      <h5>Start:</h5>
      <p *ngIf="challengeForm.value['start']">{{challengeValidationService.formatDate(challengeForm.value['start'])}}
      </p>
    </div>
    <div class="p-col">
      <h5>End:</h5>
      <p *ngIf="challengeForm.value['end']">{{challengeValidationService.formatDate(challengeForm.value['end'])}}</p>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <p-tag styleClass="p-mx-2" [value]="startDateErrorMessage" severity="danger" [rounded]="true"></p-tag>
    <p-tag styleClass="p-mx-2" [value]="endDateErrorMessage" severity="danger" [rounded]="true"></p-tag>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple type="submit" label="Cancel Submit" class="p-mt-2 p-button-secondary"
      (click)="showDatesVerificationModal=!showDatesVerificationModal"></button>
    <button pButton pRipple type="submit" label="Confirm Submit" class="p-mt-2 p-button"
      (click)="submitAndSkipChecks('date')"></button>
  </ng-template>
</p-dialog>
<!-- environment flags prerequisites validation dialog -->
<p-dialog *ngIf="!loading" header="Environment Flags Prerequisites Validation" [(visible)]="showEnvsVerificationModal"
  [modal]="true" [style]="{minWidth: '40vw'}">
  <div class="p-grid p-mt-0">
    <div class="p-col">
      <span>
        <strong class="p-m-0">Environment Flag Prerequisites:</strong>
        <ul *ngFor="let env of this.options['envs']" class="p-mt-2">
          <li>
            <span class="p-m-0">
              <p-tag styleClass="p-mx-2" [value]="env" [severity]="this.envRules[env]?.status ? 'success' : 'danger'"
                [rounded]="true"></p-tag>
              <p-chip class="p-mx-1" *ngFor="let rule of this.envRules[env]?.rules" [label]="rule"></p-chip>
            </span>
          </li>
        </ul>
      </span>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="p-grid p-mt-0">
    <div class="p-col">
      <span>
        <strong class="p-m-0">Original Env Values:</strong>
        <p *ngFor="let env of originalRecord['env']" class="p-my-0">
          <span class="p-mx-1 ">{{env}}</span>
        </p>
      </span>
    </div>
    <div class="p-col">
      <span>
        <strong class="p-m-0">New Env Values:</strong>
        <p *ngFor="let env of challengeForm.value['env']" class="p-my-0">
          <span class="p-mx-1 ">{{env}}</span>
        </p>
      </span>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <p-tag styleClass="p-mx-2" value="Missing Environment Flags Prerequisites" severity="danger"
      [rounded]="true"></p-tag>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple type="submit" label="Cancel Submit" class="p-mt-2 p-button-secondary"
      (click)="showEnvsVerificationModal=!showEnvsVerificationModal"></button>
    <button pButton pRipple type="submit" label="Confirm Submit" class="p-mt-2 p-button"
      (click)="submitAndSkipChecks('envs', challengeForm, keywords_ref, isEditMode, key, id, type)"></button>
  </ng-template>
</p-dialog>
<div *ngIf="loading" class="p-px-6 p-pt-3">
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>