import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { TimeCellFilterComponent } from 'src/app/common/ag-grid-table/cell-filters/time/time.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import moment from 'moment';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { TimeCellEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/time/time.component';
import { DataService } from 'src/app/services/data.service';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';

@Component({
  selector: 'app-cron-schedule-table-ag-grid',
  templateUrl: './cron-schedule-table-ag-grid.component.html',
  styleUrls: ['./cron-schedule-table-ag-grid.component.sass']
})
export class CronScheduleTableAgGridComponent implements OnInit {

  public entity: string = 'cron-schedules';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};
  public frameworkComponents: any;
  public cronTypesDictionary: { [key: number]: string };

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
    private dataService: DataService
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {
    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right',
        width: 120,
        resizable: true,
      },
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Description',
        field: 'description',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Type',
        field: 'type',
        cellRenderer: (params: any) => {
          return this.cronTypesDictionary[params.data.type];
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.cronTypes,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value.value;
          }
        },
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "value",
          optionLabel: "name",
          objectValue: false
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Days',
        field: 'days',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          addEmptyFilters: true,
        },        
        floatingFilter: true,
        cellEditor: MultiselectEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Date',
        field: 'cronDate',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        width: 300,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('cronDate'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn'
      },
      {
        headerName: 'Time',
        field: 'time',
        sortable: true,
        unSortIcon: true,
        filter: TimeCellFilterComponent,
        cellRenderer: (params: any) => {
          if (params.value) {
            return moment(params.value, "HH:mm").format("hh:mm A");
          }
          return params.value;
        },
        floatingFilter: true,
        width: 200,
        resizable: true,
        cellEditor: TimeCellEditorComponent,
        type: 'editableColumn'
      },
      {
        headerName: 'Job Enabled',
        field: 'enabled',
        cellRenderer: (params: any) => {
          if (params.value === true) {
            return 'Yes';
          } else {
            return 'No';
          }
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => {
            if (params.value === true) {
              return 'Yes';
            } else {
              return 'No';
            }
          }
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
    ];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }

  async setOptions() {
    this.options['cronTypes'] = await this.dataService.getCronTypes();
    this.cronTypesDictionary = this.options['cronTypes'].reduce((acc: any, type: any) => {
      acc[type.value] = type.name;
      return acc;
    }, {});
  }
}
