import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import {
  MessageService,
} from 'primeng/api';
import { TableDataComponent } from 'src/app/cms-v2/entities/table-data/components/table-data/table-data.component';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { ActivatedRoute } from '@angular/router';
import { UserPermissionsService } from '../user-permissions/service/user-permissions.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UserService } from 'src/app/user/service/user.service';

@Component({
  selector: 'app-asset-version-table',
  templateUrl: './asset-version-table.component.html',
  styleUrls: ['./asset-version-table.component.sass'],
})
export class AssetVersionTableComponent extends TableDataComponent implements OnInit {
  @ViewChild('spawnerTable') table: any;
  pageTitle: string = 'Economy Table';
  entity: any;
  id: any;
  type: any;
  tableType: any;
  filtered: any;
  sortableChallenges: any = [];
  urlQueryParams: any = {};
  displayUpdateModal: boolean = false;
  rowToUpdate: any;
  permission: boolean = false;

  constructor(
    private dataService: DataService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private entityViewService: EntityViewService,
    private userPermissionService: UserPermissionsService,
    private authService: AuthService,
    private userService: UserService,

  ) {
    super();
  }

  async ngOnInit() {
    this.isLoading = true;
    let socialUser = this.authService.getSocialUser()
    if(socialUser) {
      
      let user = await this.userService.findUser({query: {email: socialUser.currentUser.email}})
      let response = await this.userPermissionService.findAllPermissionsOfUser(user._id)  

      let filteredPermissionArray = response?.filter(obj => { 
        return obj.permissionId.entity === 'VersionController'
      })
      if (filteredPermissionArray && filteredPermissionArray.length > 0) {
        this.permission = true;
      }
    }
    
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    this.tableType = String(routeParams.get('table'));
    this.type = String(routeParams.get('type'));
    console.log(this.id, this.tableType, this.type)

    await this.entityViewService
    .getEntity('miscellaneous-build', { query: { id: this.id } })
    .then(async (data: any) => {
      this.entity = data;
    });
    
    console.log(this.entity)
    await this.filterUniques()
    this.filtered = this.filtered.reverse();
    this.isLoading = false;
  }

  /**
   * filters all the unique values for hashes and sorts by latest one
   */
  async filterUniques() {
    if(this.type == 'asset') {
      this.filtered = this.entity.asset_versions.filter((value:any, index:any, self:any) => {
        return self.findIndex((v:any) => v.destinationPath === value.destinationPath) === index;
      })
    } else if (this.type == 'image') {
      this.filtered = this.entity.image_versions.filter((value:any, index:any, self:any) => {
        return self.findIndex((v:any) => v.destinationPath === value.destinationPath) === index;
      })
    }
  }
  /**
   * Displays the confirmation modal and sets the row to be updated
   * @param row selected row
   */
  displayConfirmationModal(row: any) {
    this.displayUpdateModal = true;
    this.rowToUpdate = row;
  }

  /**
   * updates the version of the asset/image
   */
  async updateVersion() {
    if(this.type == 'asset') {
      this.rowToUpdate.promotedAt = new Date().toISOString()
      this.entity.asset_versions.push(this.rowToUpdate)
      this.dataService.updateRecord(this.id, 'miscellaneous-build', this.entity)
    } else if (this.type == 'image') {
      this.rowToUpdate.promotedAt = new Date().toISOString()
      this.entity.image_versions.push(this.rowToUpdate)
      this.dataService.updateRecord(this.id, "miscellaneous-build", this.entity)
      
    }
    
   this.displayUpdateModal = false;
  }
  /**
   * Transition update function
   */
  async updateMidVersion() {
    await this.updateVersion()
    this.messageService.add(
      {
        sticky: true,
        severity: 'success',
        summary: 'Succesfully Updated The Version',
        detail: 'Successfully updated to the selected version',
      }
    );
    this.ngOnInit();
  }
}
