<div class="card" style="min-height: 250px; overflow-wrap: break-word; word-wrap: break-word;">
  <div *ngIf="cardConfig.headerField.id === 'Thumbnail';" class="thumbnail-wrapper">
    <img [src]="getImageSource()"
         [alt]="record.name"
         style="width: 100%; max-height: 250px; object-fit: contain;"
         (click)="onThumbnailClick()">
  </div>
  <div *ngIf="cardConfig.headerField.id !== 'Thumbnail';">
    <app-thumbnail [entityId]="record.id" [entity]="entity" [assetRecord]="record"></app-thumbnail>
  </div>
  <div [id]="cardConfig.headerField.id" class="name-wrapper">
    <div class="p-grid">
      <ng-container *ngFor="let field of field">
        <div *ngIf="field.enabled" [ngClass]="'p-col-' + field.size">
          <app-base-view-field 
            [field]="field" 
            [record]="record" 
            [viewTypes]="field.viewTypes"
            [options]="field.options">
          </app-base-view-field>
        </div>
      </ng-container>
    </div>
  </div>
</div>