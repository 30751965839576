import { Component, OnInit } from '@angular/core';
import DynamicFormDTO from '../../dtos/DynamicFormDTO';

@Component({
  selector: 'app-base-dynamic-form',
  templateUrl: './base-dynamic-form.component.html'
})
/**
 * Base Dynamic Form Component
 */
export class BaseDynamicFormComponent implements OnInit 
{
  title: string = "";
  isLoading: boolean = true;
  fields:  Array<DynamicFormDTO> = [];
  sidebarFields:  Array<DynamicFormDTO> = [];
  layout: string = 'full-screen';
  entityType: string;
  hideEnabledField: boolean = false;
  viewRoute: string;
  
  constructor() { }

  /**
   * Base Dynamic Form Component Initialization
   */
  ngOnInit()
  {
  }
}