import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api';
import { BuildService } from 'src/app/build-data/services/build.service';

@Component({
  selector: 'app-side-thumbnail',
  templateUrl: './side-thumbnail.component.html',
  styleUrls: ['./side-thumbnail.component.css']
})
export class SideThumbnailComponent implements OnInit {
  @Input() record: any;
  @Input() imageRecord: any;
  @Input() field: any;
  @Input() customLabel: any = null;
  @Input() options: any;
  @Input() timestamp: number;
  public imgData: any = {};
  public showImgDialog: boolean = false;

  constructor(
    private clipboard: Clipboard,
    private messageService: MessageService,
    private buildService: BuildService
  ) { }

  ngOnInit(): void {
    this.getBuildData();
  }

  /** Compiles and sets global imgData, then sets showImgDialog to true.
   * @param field FieldData
   */
  displayImage(url: string) {
    this.imgData = {
      title: this.record.name,
      imageName: this.field.label,
      name: url.replace('_128.', '_1024.'),
      path: url.replace('_128.', '_1024.'),
    };
    setTimeout(() => {
      this.showImgDialog = true;
    }, 300);
  }

   /**
   *
   * @param copyVal // text to copy into the user's clipboard
   */
   copyToClipboard(copyVal: string) {
    this.clipboard.copy(copyVal);
    this.messageService.add({
      life: 500,
      sticky: false,
      severity: 'success',
      summary: 'Thumbnail Copied',
      detail: `URL copied to clipboard successfully!`,
    });
  }

  /**
   * Retrieves the build data for each entity in the record.
   * If the entity has build data, it makes an API call to get the detailed build data and status.
   * If not, it sets a default build status indicating that the build data was not found.
   */
  async getBuildData() {
    await this.fetchAndUpdateBuildData(this.imageRecord);
  }

  /**
   * Fetches and updates the build data for a given entity.
   * @param {Object} entity - The entity for which to fetch and update the build data.
   */
  async fetchAndUpdateBuildData(entity: any) {
    if (entity && entity.buildData) {
      // Fetch the build data from the service.
      let response = await this.buildService.getBuildData(entity.buildData);
      // console.log('build data response:', response);

      // Update the entity with the fetched build data and status.
      entity.buildData = response?.buildData;
      entity.buildStatus = response?.buildStatus;
    } else {
      if (!entity) entity = {};
      // Set a default build status if no build data is present.
      entity.buildData = [];
      entity.buildStatus = {
        text: 'Build Status Not Found',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }
  }

  /**
   * Returns style for a given record
   * @param record The record for which style needs to be computed
   */
  getStyle(record: any): any {
    if (!record || !record.buildStatus) {
      return {};
    }

    return {
      'font-size': '12px',
      position: 'relative',
      color: 'var(--primary-color-text)',
      'background-color': record.buildStatus.color || '',
      'border-radius': '0.5em'
    };
  }

  /**
   * Returns badge style for a given record
   * @param record The record for which badge style needs to be computed
   */
  getBadgeStyle(record: any): any {
    if (!record || !record.buildStatus) {
      return {};
    }

    return {
      'background-color': record.buildStatus.color || '',
      position: 'absolute',
      right: '-0.5rem',
      top: '-1em'
    };
  }




}
