<div *ngIf="params.isNestedArray && this.params.value && this.params.value.length > 0" class="container">
  <strong>Stages:</strong>
  <div *ngFor="let lineItemsArr of this.params.value; let indexParent = index">
    <div class="stage-container">
      <span> - Stage {{ indexParent + 1 }} : {{ lineItemsArr.length }} Rewards</span>
    </div>
  </div>
</div>

 <div
  *ngIf="!params.isNestedArray"
  class="container">
  <div>
    <div *ngFor="let v of this.params.value; let index = index" class="item-container">
      <span class="number-badge" [ngStyle]="getBadgeStyle(v)">
        <strong class="number-content">
          {{ v.c | number }}
        </strong>
      </span>
      <div *ngIf="v.lineItemType == 'Item'; else nonItem" class="item-link">
        <a
          class="custom-link"
          pButton
          aria-label="{{ v.id.name }}"
          [label]="v.id.name+' ('+v.id.id+')'"
          target="_blank"
          type="button"
          class="p-button-link p-text-left"
          routerLink="/items/{{ v.id.id }}"
        ></a>
      </div>
      <ng-template #nonItem>
        {{ v && v.id && v.id.name ? v.id.name : null }}
      </ng-template>
    </div>
  </div>
</div>
