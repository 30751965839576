<div [formGroup]="linkForm">
  <div formArrayName="links">
    <div
      *ngFor="let link of links.controls; let i = index"
      [formGroupName]="i"
      class="link-container"
      style="
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin: 3px;
        margin-bottom: 5px;
        "
    >
      <input
        pInputText
        formControlName="link"
        placeholder="Enter link"
        class="link-input"
        type="text"
        style=" flex: 1;"
      />
      <button
          (click)="removeLink(i)"
          pButton
          pRipple
          ariaLabel="Delete Lineitem"
          icon="pi pi-trash"
          class="p-button-text p-button-danger p-button-xs p-flex-shrink-0"
          tabindex="-1"
        ></button>
    </div>
  </div>
  <button
    (click)="addLink()"
    pButton
    pRipple
    ariaLabel="Add LineItem"
    icon="pi pi-plus"
    tabindex="-1"
    style="width: 100%; cursor: pointer;"
    class="p-button-text p-button-success p-button-xs p-flex-shrink-0"
  ></button>
</div>
