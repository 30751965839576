import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import EndlessOfferDTO from 'src/app/cms-v2/entities/endless-offer/dtos/EndlessOfferDTO';
@Component({
  selector: 'app-release-endless-offer-view-v2',
  templateUrl: './release-endless-offer-view-v2.component.html',
  styleUrls: ['./release-endless-offer-view-v2.component.sass'],
})
export class ReleaseEndlessOfferViewV2Component  extends BaseDynamicViewComponent implements OnInit {
  endlessOfferRecord: EndlessOfferDTO = new EndlessOfferDTO();

  constructor()
  {
    super();
  }
  ngOnInit()
  {

    this.fields =
    [
      {
        title: "Endless Offer Data",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  key: 'name', isRequired: true, label: 'Name',columnWidth: 6  },
          { name: "Internal Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  key: 'internalName', isRequired: true, label: 'Internal Name',columnWidth: 6  },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Calendar],  key: 'start', label: 'Start Date/Time',columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Calendar], key: 'end', label: 'End Date/Time',columnWidth: 6 },
          {
            name: 'Internal Notes',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.RichText],
            clearField: false,
            key: 'copy',
            label: 'Internal Notes',
            columnWidth: 12,
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Track Data",
        fields:
        [
          { 
            name: "Column Count", inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text], key: 'column_count', isRequired: true, 
            label: 'Column Count', columnWidth: 4
          },
          {
            name: "Reward Track Record", inputType: DynamicViewType.MiscRef, clearField: true, key: 'track_v2_ref',
            label: 'Reward Track Record', controller: 'rewards-track', columnWidth: 6
          },
          { name: "Track", inputType: DynamicViewType.EndlessOfferTrackV2, key: 'track_v2_ref', label: 'Track', parentEntity: 'endless-offer' },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.sidebarFields = [
      {
        title: "Imagery Build Status",
        fields:
        [
          {
            name: "Imagery Data",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            key: '',
            label: '',
            columnWidth: 12,
            tagType: 'h3' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
          },
          { name: "Banner Feed Image",
            inputType: DynamicViewType.sideThumbnail,
            key: 'feedBannerImage_ref',
            label: 'Banner Feed Image',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "IAP Store Image",
            inputType: DynamicViewType.sideThumbnail,
            key: 'IAPStoreImage_ref',
            label: 'IAP Store Image',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "Offer Popup Header Banner Image",
            inputType: DynamicViewType.sideThumbnail,
            key: 'popupBannerImage_ref',
            label: 'Offer Popup Header Banner Image',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "",
            inputType: DynamicViewType.SpaceCol,
            key: '',
            label: '',
            columnWidth: 6
          },
          {
            name: "Tile Backgrounds",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            key: '',
            label: '',
            columnWidth: 12,
            tagType: 'h3' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
          },
          { name: "Tile Backgrounds",
            inputType: DynamicViewType.multiSideThumbnail,
            key: 'bundleImagery_ref',
            label: 'Tile Backgrounds',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },

    ]

    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.endlessOfferRecord =
    {
      enabled: false
    };
    this.title = "Endless Offer";
    this.viewRoute = 'endless-offer';
    this.isLoading = false;

  }

}

