import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SocialUser } from '@abacritt/angularx-social-login';
import {
  ConfirmationService,
  FilterService,
  MessageService,
  SortEvent,
} from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';
import { TableService } from 'src/app/services/table.service';
import { Title } from '@angular/platform-browser';
import UserSettings from 'src/app/user/UserSettings';
import { LoggerService } from '../../services/logger.service';
import { UserContextService } from '../../services/user-context.service';
import { ValidationsService } from '../../services/validations.service';
import Columns from './dtos/Columns';
import TableBulkBuild from './dtos/TableBulkBuild';
import TableBulkEdit from './dtos/TableBulkEdit';
import TableConfiguration from './dtos/TableConfiguration';
import TableState from './dtos/TableState';
import * as _ from 'lodash';
import { Papa } from 'ngx-papaparse';
import FieldData from '../base-fields/field-data-dto';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { ActivatedRoute, Router } from '@angular/router';
import TableBulkMultiSelectAction from './dtos/TableBulkMultiSelectAction';
import { SourcingItemGroupService } from 'src/app/cms-v2/entities/sourcing-group/services/sourcing-group.service';
import { SourcingChallengeGroupService } from 'src/app/cms-v2/entities/sourcing-challenge-group/services/sourcing-group.service';
import { SourcingService } from 'src/app/cms-v2/entities/items-sourcing/services/sourcing.service';
import { SpinnerService } from '../../services/spinner.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import TableBulkPromotion from './dtos/TableBulkPromotion';
import { UtilitiesService } from '../../services/utilities.service';
import { requiredFieldValidations } from 'src/app/cms-v2/entities/table-data/components/table-data/enums/requiredFieldsEnum';
import { environments } from '../../constants/constants';
import { FiltersSidebarComponent } from '../filters-sidebar/filters-sidebar.component';
import { AesEncryptionDecryptionService } from '../../services/aes-encryption-decryption.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { DynamicTableService } from './dynamic-table.service';
import { TableCheckbox } from 'primeng/table';
const JSURL = require("jsurl"); // Moved to the top

@Component({
  selector: 'dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.sass'],
  providers: [ConfirmationService, MessageService],
})
export class DynamicTableComponent implements OnInit {
  // Table variables
  @ViewChild('table') table: any;
  @ViewChildren('inputField') inputField: any;
  @Input() tableName: string;
  @Input() tableType: string;
  @Input() tableConfig: TableConfiguration = new TableConfiguration();
  @Input() isArchived: boolean = false;
  @Input() customQuery: any = null;
  @Input() tableView: boolean = false;
  @Input() tableViewEntity: any = null;
  @Input() customRows: any = null;
  @Input() tableTitle: any = null;
  @Input() tableViewType: string;
  @Input() sourcingKey: string;
  @Input() newRowObject: any;
  @Input() requiredFields: any;
  @Input() isPaginated: boolean = false;
  @Input() pageSize: number = 50;
  @Input() hideEnvFlagsColumn: boolean = false;
  tableViewItems: any = null;
  tableState: TableState = new TableState();
  isSaved: boolean = true;
  showArchived: boolean = true;
  showArchivedRows: boolean = false;
  eventModelChange: any;
  clearConfirmation: boolean = false;
  tableSpinnerIcon: string = 'pacman';
  showSpinnerProgress: boolean = true;
  fullSearch: boolean = true;
  currentTimeout: any;
  @ViewChild(FiltersSidebarComponent) filtersSidebarComponent: FiltersSidebarComponent;

  @Output() tableModelChange = new EventEmitter<any>();
  @Output() onAction = new EventEmitter<any>();
  @Output() onPathComponentValueChange = new EventEmitter<any>();

  // Rows
  rows: Array<any> = [];
  originalRows: Array<any> = [];
  selectedRows: any[] = [];
  filteredRows: any[] = [];
  clonedRows: { [s: string]: any } = {};
  newSelectedRows: Array<string>;
  pageSelect: boolean = true

  // Computed property to get count of non-promoted selected rows
  get nonPromotedSelectedRowsCount(): number {
    // Only filter out promoted rows for sourcing tables
    if (['items-sourcing', 'challenges-sourcing'].includes(this.tableType)) {
      return this.selectedRows.filter(row => !row.promoted).length;
    }
    // For other table types, return the full selection count
    return this.selectedRows.length;
  }

  // Columns
  columns: Array<FieldData> = [];
  columnsList: Array<FieldData> = [];
  selectedColumns: any[] = [];
  columnSetGroups: any[] = [];
  activeColumnSetName: string = '';
  tempColumnSetName: string = '';
  prevColumnSelection: Array<string> = [];

  @Input() defaultColumnOrder: Array<string> = [];
  columnOrder: Array<string> = [];
  @Input() customGlobalColumnSets: Array<any> = [];
  dragStartIndex: number;

  /**
   * These variables are used to set the columns in a particular order. We will use the tableState to these columns.
   * Functions setCSVBatchedColumns and setBatchColumns use these arrays.
   */

  private readonly itemInorganicFields: Array<any> = [
    { name: 'Name', key: 'name' },
    { name: 'Category', key: 'category_ref' },
    { name: 'File Type', key: 'itemFileType_ref' },
    { name: 'Style(s)', key: 'styles_ref' },
    { name: 'Material(s)', key: 'materials_ref' },
    { name: 'Pattern(s)', key: 'patterns_ref' },
    { name: 'Shape', key: 'shape_ref' },
  ];

  private readonly itemOrganicFields: Array<any> = [
    { name: 'Name', key: 'name' },
    { name: 'Latin Name', key: 'latinName' },
    { name: 'Plant Family', key: 'plantFamily' },
    { name: 'Climate', key: 'climates_ref' },
    { name: 'Category', key: 'category_ref' },
    { name: 'File Type', key: 'itemFileType_ref' },
    { name: 'Type', key: 'type_ref' },
    { name: 'Traits', key: 'traits_ref' },
    { name: 'Height', key: 'height' },
    { name: 'Spread', key: 'spread' },
    { name: 'Spruce Entry', key: 'spruceDataStatus' },
  ];

  private readonly challengeFields: Array<any> = [
    { name: 'Name', key: 'name' },
    { name: 'File Name', key: 'fileName' },
    { name: 'Scene Type', key: 'sceneType' },
    { name: 'Scene', key: 'scene' },
    { name: 'Location', key: 'location' },
    { name: 'Climate', key: 'climate_ref' },
    { name: 'Challenge Type', key: 'type_ref' },
  ];

  private readonly organicSourceColKeys: Array<any> = [
    'climates_ref',
    'category_ref',
    'type_ref',
    'itemFileType_ref',
    'fileName',
    'name',
    'cultivar',
    'latinName',
    'plantFamily',
    'colors_ref',
    'height',
    'spread',
    'traits_ref',
    'referenceLinks',
    'vendorNotes',
    'batch_ref',
    'archived',
    'vendorHeight',
    'vendorLength',
  ];
  private readonly inorganicSourceColKeys: Array<any> = [
    'category_ref',
    'itemFileType_ref',
    'name',
    'fileName',
    'colors_ref',
    'styles_ref',
    'materials_ref',
    'patterns_ref',
    'shape_ref',
    'vendorDimensions',
    'vendorNotes',
    'internalReferenceLinks',
    'referenceLinks',
    'batch_ref',
    'year',
    'archived',
  ];
  private readonly organicSourceCSVColKeys: Array<any> = [
    'batch_ref',
    'category_ref',
    'fileName',
    'plantFamily',
    'name',
    'cultivar',
    'colors_ref',
    'referenceLinks',
    'vendorHeight',
    'vendorLength',
    'vendorNotes',
  ];
  private readonly inorganicSourceCSVColKeys: Array<any> = [
    'category_ref',
    'name',
    'fileName',
    'colors_ref',
    'vendorDimensions',
    'vendorNotes',
    'referenceLinks',
    'batch_ref',
  ];

  // Fields
  @Input() fields: Array<FieldData> = [];
  inputFields: Array<FieldData> = [];
  filterFields: Array<FieldData> = [];
  multiSelectFields: Array<FieldData> = [];
  findAndReplaceFields: Array<FieldData> = [];

  //Duplicate Existing Item Dialog Variables
  itemToCopy: any;
  selectExistingItemForDuplication: boolean = false;
  selectFieldsForDuplication: boolean = false;
  selectedItemInOrganicFields: any[]
  selectedItemOrganicFields: any[]
  selectedChallengeFields: any[]
  checked: boolean = false;
  activeState: boolean[] = [true, false, false];
  challengeActiveState: boolean[] = [false];
  existingItemStyles_ref: any[];

  // New Assets to Create

  assetToCreate: any = {
    name: '',
    entityType: null,
    imageType: null,
    path: ''
  };
  displayCreateNewAsset: boolean = false;


  //Experiment of checklist
  masterSelected: boolean;
  checklist: any;
  checkedList: any;


  @Input() options: any = {};
  fullOptions: any = [];
  suggestions: Array<any> = [];

  // Filters
  @Input() globalFilters: Array<string> = [];
  @Input() minFilters: Array<string> = [];
  currentFilter: string;
  get activeFilterCount(): number {
    if (this.tableState && this.tableState.filterSelection) {
      return Object.keys(this.tableState.filterSelection).length;
    } else return 0;
  }
  urlQueryParams: any = {};
  filterParams: any;
  filterSelectionParams: any;

  encryptedFilterParams: any;
  encryptedFilterSelectionParams: any;

  secureKey: any = 'jlajdfoiajwej32j3ojr209j0adj80384u80j'

  // User variables
  user: any;
  userSettings: UserSettings = new UserSettings();

  //Actions
  bulkEdit: TableBulkEdit = new TableBulkEdit();
  bulkBuild: TableBulkBuild = new TableBulkBuild();
  bulkMultiSelect: TableBulkEdit = new TableBulkEdit();
  bulkMultiSelectAction: TableBulkMultiSelectAction =
    new TableBulkMultiSelectAction();
  disableBultiSelectedSubmitButton: boolean = false;
  bulkPromote: TableBulkPromotion = new TableBulkPromotion();
  bulkEditReviewHeaders: Array<string> = [];

  get urlNewForm(): string {
    return this.tableView ? `/${this.tableViewType}/add` : `/${this.tableType}/add`
  }

  // Export
  exportSets: any[];
  exportFields: any[];

  // View variables
  isLoading: boolean = false;
  paginationIsLoading: boolean = false;
  columnsLoading: boolean = false;
  paginationProgress: number = 0;
  isHeaderChecked: boolean = false;
  showColumns: boolean;
  showAddColumnSet: boolean;
  showFilters: boolean = false;
  showActions: boolean;
  showBulkBuild: boolean;
  showBulkBuildFailed: boolean;
  showBulkEdit: boolean;
  showBulkEditConflicts: boolean;
  showBulkEditFailed: boolean;
  showEditOptions: boolean;
  selectInputEditMode: boolean;
  showInputEditOptions: boolean;
  showFindAndReplace: boolean;
  multipleOptions: boolean;
  showBulkPromote: boolean = false;
  showBulkPromoteFailed: boolean = false;
  isAssetPromotion: boolean = false;
  disablePromoteButton: boolean = false;
  bulkPromoteProgress: number;
  showBulkEditForCost: boolean = false;
  showBulkEditForCostReview: boolean = false;
  showBulkEditForInput: boolean = false;
  showBulkEditForNoteReview: boolean = false;


  s3Cdn: string = 'https://d3tfb94dc03jqa.cloudfront.net/';
  loading: boolean = false;
  event: any;
  addRowValue: number = 1;

  // Build Data
  disableBuildButton: boolean = false;
  @Input() assetType: AssetTypes;
  @Input() buildType: BuildType;
  bulkBuildProgress: number = 0;
  bulkEditProgress: number = 0;
  bulkMultiSelectProgress: number = 0;
  showBuildProgress: boolean = false;
  buildAndRender: boolean = false;
  get assetTypeValue(): typeof AssetTypes {
    return AssetTypes;
  }

  // Sourcing tables data
  sourcingGroups: Array<any> = [];
  @Input() sourceGroupID: string;
  @Input() sourceGroupEditID: string;
  @Input() sourcingDoc?: any;
  displayConfirmItemSourcingUploadModal: boolean = false;
  displayMoveSourcingModal: boolean = false;
  selectedSourcingGroup: number | null = null;
  moveAllSourcingItems: boolean = false;
  isTableEditLocked: boolean = false;
  editModeSuccessCount: number = 0;
  editModeFailedCount: number = 0;

  // Sourcing Items - Filename Validation variables
  showFilenameValidationPreviewTable: boolean = false;
  showPathGenerationPreviewTable: boolean = false;
  showItFilenameGenValidationModal: boolean = false;
  showChFilenameGenValidationModal: boolean = false;
  showChPathGenerationModal: boolean = false;

  // Cost Field
  costField: any;

  // Toaster Life to live
  toasterLife: number = 3000;

  // Add these properties to the class
  recordsToDelete: any[] = [];

  constructor(
    private dataService: DataService,
    private authService: AuthService,
    private tableService: TableService,
    private filterService: FilterService,
    private formService: FormService,
    private loggerService: LoggerService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private fb: UntypedFormBuilder,
    private userContextService: UserContextService,
    private validationsService: ValidationsService,
    private aesEncryptionDecryptionService: AesEncryptionDecryptionService,
    private route: ActivatedRoute,
    private router: Router,
    private sourcingItemGroupService: SourcingItemGroupService,
    private sourcingChallengeGroupService: SourcingChallengeGroupService,
    private sourcingService: SourcingService,
    private titleService: Title,
    private spinnerService: SpinnerService,
    public entityViewService: EntityViewService,
    public utilitiesService: UtilitiesService,
    private promotionService: PromotionService,
    private dynamicTableService: DynamicTableService,
    private cd: ChangeDetectorRef,
    private papa: Papa
  ) { }

  /**
   * Dynamic Table Initialization
   */
  async ngOnInit() {


    this.isLoading = true;
    this.addEnvFlagsColumn();
    this.masterSelected = false;
    this.fullSearch = this.tableType == 'challenges' ? false : true;
    this.bulkEditReviewHeaders = this.tableType == 'items' ? ['id', 'filename', 'name'] : ['id', 'name'];
    let getFullCategories = this.fields ? this.fields.find((field) => field.key == 'category_ref') : null;
    if (getFullCategories) {
      await this.getfullOptionsFromRef('category_ref', 'categories');
    }

    this.options = await this.getOptions(this.fields);

    this.costField = this.fields.find((x) => x.key == 'costs_ref');

    if (this.tableType && !this.tableTitle) {
      this.titleService.setTitle(
        this.tableType.charAt(0).toUpperCase() +
        this.tableType.slice(1) +
        ' Table'
      );
    } else {
      this.titleService.setTitle(this.tableTitle)
    }
    // Get current user data
    this.getUserData();

    // Get settings from localStorage
    const state: any = localStorage.getItem(`${this.tableType}-table`);
    this.tableState = JSON.parse(state);

    if (!this.tableState) {
      this.tableState = {
        rowSelection: [],
        columnSelection: [],
        customColumnsOrder: [],
        filterSelection: {},
        filters: {},
        sortField: 'id',
        sortOrder: -1,
        rows: 50,
        first: 0,
      };
      this.saveTableState();
    } else {
      // Handle localStorage data update
      this.localStorageConversions();
    }

    // check queryParamMap for values, and apply default values as necessary.
    for (let key of ['global', 'sortBy', 'sortOrder', 'filters', 'filterSelection']) {
      let value = this.route.snapshot.queryParamMap.get(key);

      if (!this.validationsService.isEmpty(value)) {
        this.urlQueryParams[key] = value;
      } else {
        // set default values
        switch (key) {
          case 'sortBy':
            this.urlQueryParams[key] = 'id';
            break;
          case 'sortOrder':
            this.urlQueryParams[key] = -1;
            break;
        }
      }
    }

    this.decodeEncodedFilters();

    // Define and Set INPUT FIELDS, FILTER FIELDS, & COLUMNS
    this.setAllFieldData();

    // Get/Set user page settings
    this.userSettings = await this.getUserSettings();

    // Set COLUMN SET GROUPS
    this.setColumnSetGroups();

    // Set EXPORT COLUMN SETS
    this.setExportColumnSets();

    // Set selected rows
    //this.setSelectedRows();
    this.onResetRows();

    // If no selected columns, use global 'Default' set.
    if (this.tableState.columnSelection.length < 1) {
      const globalGroup = this.columnSetGroups.find((group) =>
        ['globalColumnSets'].includes(group.key)
      );
      const defaultSet: any = globalGroup
        ? globalGroup.value.find((set: any) => ['Default'].includes(set.name))
        : null;
      if (defaultSet) {
        this.tableState.columnSelection = defaultSet.value;
      }
    }
    // Set Selected Columns
    this.setSelectedColumns();

    // Get row data
    if (this.tableType === 'items-sourcing' || this.tableType == 'challenges-sourcing') {

      await this.getAllSourcingGroups();
      await this.getItemSourceRowData();
    } else if (this.customRows) {
      await this.getCustomRows();
    } else {
      await this.getRowData();
    }

    this.prevColumnSelection = _.cloneDeep(this.tableState.columnSelection);

    // Check table state
    this.checkTableState();

    // Check for matching columnSet
    this.checkForColumnSet();

    // register filter for global search
    this.registerGlobalRegexFilter();

    this.table.filterGlobal(this.urlQueryParams.global, 'regex-search');

    // read url param for show and hide archived

    //get url params for filters

    // let encodedFilterParam =  this.route.snapshot.queryParamMap.get('filters')
    // let encodedFilterSelectionParam =  this.route.snapshot.queryParamMap.get('filterSelection')

    // this.loggerService.log('encodedFilterParam ngonit', encodedFilterParam)
    // this.loggerService.log('encodedFilterSelectionParam ngonit', encodedFilterSelectionParam)


    // if (encodedFilterParam == '~(archived~(~true~false))'){
    //   this.loggerService.log('this is true', this.showArchivedRows)
    // } else {
    //   this.loggerService.log('this is false', this.showArchivedRows)
    // }

    // const storedShowArchivedRows = localStorage.getItem('showArchivedRows');
    // this.showArchivedRows = storedShowArchivedRows ? JSON.parse(storedShowArchivedRows) : false;

    // this.loggerService.log('this.showArchivedRows from NGONIT', this.showArchivedRows)

    // When initializing the showArchivedRows variable (e.g., in the component's constructor or ngOnInit)
    const storedShowArchivedRows = localStorage.getItem('showArchivedRows');
    this.showArchivedRows = storedShowArchivedRows ? JSON.parse(storedShowArchivedRows) : false;
    console.log('Retrieved showArchivedRows:', this.showArchivedRows);

    this.isLoading = false;
  }

  /**
   * Adds the Environment(s) column to CMS Entities tables.
   * Adds the ability to filter those values as well.
   */
  addEnvFlagsColumn() {
    if (!this.hideEnvFlagsColumn) {
      let envColumn: FieldData =
      {
        key: 'env',
        name: 'Environment(s)',
        controlType: 'multiSelect',
        filterKey: 'env',
        matchMode: 'multiIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true
      };

      let isAlreadyAdded = this.fields.find((column: FieldData) => column.key == 'env');
      if (!isAlreadyAdded) {
        this.fields.push(envColumn);
        this.defaultColumnOrder.push('env');
        if (!this.tableConfig.customFilterToRegister || this.tableConfig.customFilterToRegister.length == 0) {
          this.tableConfig.customFilterToRegister = ['multiIn'];
        }
        else if (!this.tableConfig.customFilterToRegister.find((filter: string) => filter == 'multiIn')) {
          this.tableConfig.customFilterToRegister.push('multiIn');
        }
      }

      this.options['env'] = environments;
    }
  }

  /**
   * Retrieves data related to current user
   */
  getUserData() {
    // get current user
    const userResult = this.authService.getSocialUser();
    this.user = userResult.currentUser;
  }

  // registers 'regex-search' filter, which is used for global search
  registerGlobalRegexFilter() {
    this.filterService.register(
      'regex-search',
      (value: any, filter: any): boolean => {
        if (filter === undefined || filter === null || filter.trim() === '') {
          return true;
        }

        if (value === undefined || value === null) {
          return false;
        }
        const replaceRegex = /\s/g;

        return new RegExp(filter.replace(replaceRegex, ''), 'i').test(
          value.toString().replace(replaceRegex, '')
        );
      }
    );
  }

  /**
   * Get Row data for table type
   */

  async getItemSourceRowData() {
    await this.tableService
      .getSourceRows(this.tableType, this.sourceGroupID)
      .then(async (data: any) => {
        this.rows = data;
        this.filteredRows = data;
        this.loggerService.log('Rows: ', data);
        // Update Selected Rows
        if (this.rows && this.selectedRows) {
          this.selectedRows.forEach((element, index) => {
            let item = this.rows.find((x) => x.id == element.id);
            if (item) {
              this.selectedRows[index] = item;
            }
          });
        }
      });
  }


  async getRowData() {
    // Get row data
    if (this.isPaginated) {
      this.paginationIsLoading = true;
    }
    if (this.tableConfig.useDynamicTableColumns) {
      console.log('calling getTableRecords');
      await this.getTableRecords();
    }
    else {
      console.log('calling getTableRows');
      let dataResponse = await this.tableService.getTableRows(
        this.tableType,
        this.tableConfig.customBuildDataKeys
          ? this.tableConfig.customBuildDataKeys
          : null,
        this.customQuery
      );
      if (dataResponse) {
        this.loggerService.log('Table Rows Data: ', dataResponse);
        this.rows = dataResponse;
        this.originalRows = JSON.parse(JSON.stringify(dataResponse))
        this.filteredRows = dataResponse;
      }
    }
  }


  /**
   * Retrieves Table records.
   *
   * @param {Array<any> | null} [customColumns=null] - This is an array of column names that you want
   * to fetch. If you want to fetch all selected columns, pass null.
   * @param {boolean} [isColumnAction=false] - Flag that sets whether or not is a column action.
   */
  async getTableRecords(customColumns: Array<any> | null = null, isColumnAction: boolean = false, page: number = 0) {
    let customSort = {};
    if (this.urlQueryParams && this.urlQueryParams.sortBy && this.urlQueryParams.sortOrder) {
      customSort = { [this.urlQueryParams.sortBy]: this.urlQueryParams.sortOrder };
    } else {
      customSort = { id: 1 };
    }
    let dataResponse = await this.tableService.getTableRowsV2(
      this.tableType,
      customColumns && customColumns.length > 0 ? customColumns : this.tableState.columnSelection,
      this.tableConfig.customBuildDataKeys ? this.tableConfig.customBuildDataKeys : null,
      this.customQuery,
      customSort,
      isColumnAction ? false : this.tableConfig.fetchVirtuals,
      this.tableConfig.autopopulateEntities,
      this.tableConfig.defaultColumns,
      this.isPaginated,
      page,
      this.pageSize
    );

    if (dataResponse.rows && dataResponse.rows.length > 0 && !isColumnAction) {
      //
      if (page == 0) {
        this.rows = [];
        this.filteredRows = [];
        this.originalRows = [];
      }
      this.rows = [...this.rows, ...dataResponse.rows];
      this.filteredRows = [...this.filteredRows, ...dataResponse.rows];
      this.originalRows = [...this.originalRows, ..._.cloneDeep(dataResponse.rows)];
    }
    else if (dataResponse && dataResponse.rows.length > 0 && isColumnAction) {
      if (dataResponse.rows) {
        dataResponse.rows.forEach((resRecord: any) => {
          const index = this.rows.findIndex((orgRecord: any) => orgRecord.id === resRecord.id);

          if (index !== -1) {
            this.newSelectedRows.forEach((column: string) => {
              this.rows[index][column] = resRecord[column];
            });

          }
        });

        this.filteredRows = _.cloneDeep(this.rows);
      }
      else {
        // error, reload the table.
        this.messageService.add(
          {
            severity: 'info',
            summary: 'Table data is outdated.',
            detail: `Table data is outdated. Please reload the page.`,
            life: this.toasterLife
          });
      }
    }

    // this.loggerService.log("Rows: ", this.rows, DynamicTableComponent.name, this.getTableRecords.name);

    if (this.isPaginated && dataResponse.paginationMeta) {
      this.paginationProgress = Math.ceil((page / dataResponse.paginationMeta.pageCount) * 100);
      if (dataResponse.paginationMeta && dataResponse.paginationMeta.nextPage) {
        this.getTableRecords(customColumns, isColumnAction, dataResponse.paginationMeta.nextPage);
      } else {
        this.paginationProgress = 100;
        this.loggerService.log(`Pagination finished, loaded a total of ${dataResponse.paginationMeta.pageCount} pages and ${dataResponse.paginationMeta.totalRecords} records`);
        this.paginationIsLoading = false;
      }
    }

  }

  /**
   *
   */
  async getPaginatedTable(page: number = 0) {
    let paginatedResponse = await this.tableService.getTableRows(
      this.tableType,
      this.tableConfig.customBuildDataKeys
        ? this.tableConfig.customBuildDataKeys
        : null,
      this.customQuery,
      true, // isPaginated parameter
      page,
      this.pageSize
    );

    if (paginatedResponse) {
      // todo: diable filters and edditing until all records are fully loaded (no next page)
      // Array.prototype.push.apply(this.rows,paginatedResponse.records);
      if (page == 0) {
        this.rows = [];
        this.filteredRows = [];
      }
      this.rows = [...this.rows, ...paginatedResponse.records];
      this.filteredRows = [...this.filteredRows, ...paginatedResponse.records];

      this.paginationProgress = Math.ceil((page / paginatedResponse.paginationMeta.pageCount) * 100);

      if (paginatedResponse.paginationMeta && paginatedResponse.paginationMeta.nextPage) {
        this.getPaginatedTable(paginatedResponse.paginationMeta.nextPage);
      } else {
        this.paginationProgress = 100;
        console.log(`Pagination finished, loaded a total of ${paginatedResponse.paginationMeta.pageCount} pages and ${paginatedResponse.paginationMeta.totalRecords} records`);
        this.paginationIsLoading = false;
      }
    }
  }

  /**
   * Assign custom row information instead of api request
   */
  async getCustomRows() {
    for (let i = 0; i < this.customRows.length; i++) {
      this.rows.push(this.customRows[i].id)
    }
  }
  /**
   * Route to the editting page
   */
  editTableView() {
    console.log('editTableView', this.route)

    if (this.tableType == 'items' && this.tableView) {
      this.router.navigate([`collections/edit/${this.tableViewEntity.id}`]);
    } else {
      this.router.navigate([`${this.tableType}/edit/${this.tableViewEntity.id}`]);
    }

  }

  /**
   * Set options for fields with 'dropdown' / 'dropdown_ref' / 'multiSelect' / 'multiSelect_ref' controlTypes.
   * @param fields FieldData[] — Each field will be checked for controlType, and options will be added to global 'options' variable.
   * @returns any[] — Options for nested subFields, if 'isNested' parameter is set to true.
   */
  async getOptions(fields: FieldData[]) {
    let options: any = _.cloneDeep(this.options);
    let apiControllers: Array<any> = [];
    let apiControllerOptions: any = {};

    for (let field of fields) {

      const isOptionsLoaded: boolean = !this.validationsService.isEmpty(
        options[field.key]
      );

      // unmanaged lists
      if (
        ['dropdown', 'multiSelect', 'buildStatus'].includes(
          field.controlType
        ) &&
        !isOptionsLoaded
      ) {
        options[field.key] = field.options;
      }

      // managed lists
      if (
        ['dropdown_ref', 'multiSelect_ref', 'collection_ref'].includes(field.controlType) &&
        field.apiController &&
        !isOptionsLoaded
      ) {
        apiControllers.push(field.apiController);
        const selectProps: string =
          field.selectProps
            ? field.selectProps
            : '-createdBy -createdAt -updatedAt';
        const sortBy: string =
          field.viewControl && field.viewControl.sortBy
            ? field.viewControl.sortBy
            : 'name';

        options[field.key] = await this.getOptionsWithModel(
          field.apiController,
          field.isOptionsMin !== undefined && field.isOptionsMin !== null ? field.isOptionsMin : false,
          false, // Temp. fix
          selectProps,
          sortBy,
          field.viewControl ? field.viewControl.virtuals : true,
          field.customQuery
        );
        apiControllerOptions[field.apiController] = _.cloneDeep(options[field.key]);
      }

      // nested subFields
      if (
        ['nestedGroup', 'lineItem', 'rewards_ref', 'formArray'].includes(
          field.controlType
        ) &&
        field.subFields &&
        field.subFields.length > 0
      ) {
        await this.getOptions(field.subFields).then((data) => {
          options[field.key] = data;
        });
      }
    }

    options['currencies'] = await this.getOptionsWithModel(
      'currencies',
      true,
      true,
      '-createdBy -createdAt -updatedAt',
      'name'
    );

    return options;
  }

  /**
   * Retrieve options for fields whose options are in managed lists, by using the field's 'apiController' property or api endpoint.
   *
   * @param apiController string — API endpoint for retrieving options from managed list.
   * - FieldData.apiController
   * @param minimal boolean — Determines whether options should be minimal (true = ONLY 'id', 'name', & '_id' properties) or full objects from db (false).
   * - FieldData.isOptionsMin
   * - Default: false
   * @param autopopulate boolean — Indicates whether API call should include autopopulate.
   * - Default: true
   * @param select string — Defines properties used in 'select' for API call.
   * - FieldData.selectProps
   * - Default: '-createdBy -createdAt -updatedAt'
   * @param sortBy string — Defines property by which options are sorted in table dropdown panel.
   * - FieldData.viewControl.sortBy
   * - Default: 'name'
   * @returns array — Options retrieved from API endpoint.
   */
  async getOptionsWithModel(
    apiController: string,
    minimal: boolean = false,
    autopopulate: boolean = true,
    select: string = '-createdBy -createdAt -updatedAt',
    sortBy: string = 'name',
    virtuals: boolean = false,
    customQuery: any = {}
  ) {
    const sort: any = {};
    apiController == 'progression-levels'
      ? (sort.level = 1)
      : (sort[sortBy] = 1);

    let options = await this.dataService.getAllOfTypeAsync(apiController, {
      query: customQuery,
      autopopulate: autopopulate,
      virtuals: virtuals,
      select: select,
      sort: sort,
    });

    if (minimal) {
      let minimalOptions: any[] = [];
      for (const option of options) {
        minimalOptions.push({
          id: option.id,
          name: option.name,
          _id: option._id,
        });
      }
      return minimalOptions;
    } else {
      return options;
    }
  }

  /**
   * Check table state
   */
  checkTableState() {
    let checkedRows: number[] = [];
    this.filteredRows.forEach((row: any) => {
      if (this.tableState.rowSelection.includes(row.id)) {
        checkedRows.push(row.id);
      }
    });
    this.isHeaderChecked =
      checkedRows.length === this.filteredRows.length && checkedRows.length > 0;
  }

  /**
   * Saves table state to local storage
   */
  saveTableState() {
    this.checkTableState();

    localStorage.setItem(
      `${this.tableType}-table`,
      JSON.stringify(this.tableState)
    );
  }

  /** Handle localStorage data update */
  localStorageConversions() {
    // Convert 'columnOrder' to 'columns'
    if (this.tableState.columnOrder) {
      this.tableState.columnSelection = [...this.tableState.columnOrder];
      delete this.tableState.columnOrder;
    }

    // Convert columns(any[]) to columnSelection(string[])
    if (this.tableState.columns) {
      let columnSelection: string[] = [];
      this.tableState.columns.forEach((col: any) => {
        columnSelection.push(col.key);
      });
      this.tableState.columnSelection = columnSelection;
      delete this.tableState.columns;
    }
    // Convert selection(any[]) to rowSelection(number[])
    if (this.tableState.selection) {
      let rowSelection: string[] = [];
      this.tableState.selection.forEach((row: any) => {
        rowSelection.push(row.id);
      });
      this.tableState.rowSelection = rowSelection;

      delete this.tableState.selection;
    }
    // Convert filters from array to object
    if (this.tableState.filters && _.isArray(this.tableState.filters)) {
      const filterArray: any[] = _.cloneDeep(this.tableState.filters);

      let filters: any = {};
      filterArray.forEach((f: any) => {
        filters[f.key] = f.value;
      });
      this.tableState.filters = filters;
      this.loggerService.log('filterssssss', filters)
    }
    // Convert filterSelection from array to object
    if (
      this.tableState.filterSelection &&
      _.isArray(this.tableState.filterSelection)
    ) {
      let filterSelectionObj: any = {};

      for (let i = 0; i < this.tableState.filterSelection.length; i++) {
        const key = this.tableState.filterSelection[i];
        const field = this.fields.find((f: FieldData) => f.key === key);

        if (field) {
          filterSelectionObj[key] = !this.tableState.filters[key];
        }
      }
      this.tableState.filterSelection = filterSelectionObj;
    }

    this.saveTableState();
  }

  /** Handle userSettings data update */
  userSettingsConversions(settings: any) {
    // update FilterData for existing filterSets
    if (!this.validationsService.isEmpty(settings.filterSets)) {
      settings.filterSets.forEach((set: any) => {
        set.value.forEach((filter: any) => {
          if (!filter.selectionValue) {
            filter.selectionValue = filter.matchMode == 'empty';
          }

          const filterConversions: any = {
            collections: {
              originalKey: '_id',
              newFieldKey: 'collection_ref',
            },
          };

          if (filterConversions[filter.key]) {
            const convObj = filterConversions[filter.key];
            const field = this.fields.find(
              (f: FieldData) => f.key === convObj.newFieldKey
            );

            if (
              !this.validationsService.isEmpty(field) &&
              field!.filterControl
            ) {
              let modifiedValue: any;

              if (_.isArray(filter.value)) {
                modifiedValue = [];
                for (let val of filter.value) {
                  if (field!.filterControl.keys) {
                    const actualValue = this.options[convObj.newFieldKey].find(
                      (o: any) => o[convObj.originalKey] === val
                    );
                    let newValueObj: any = {};
                    for (let key of field!.filterControl.keys) {
                      newValueObj[key] = actualValue[key];
                    }
                    modifiedValue.push(newValueObj);
                  } else {
                    modifiedValue.push(val);
                  }
                }
              } else {
                if (field!.filterControl.keys) {
                  const actualValue = this.options[convObj.newFieldKey].find(
                    (o: any) => o[convObj.originalKey] === filter.value
                  );
                  let newValueObj: any = {};
                  for (let key of field!.filterControl.keys) {
                    newValueObj[key] = actualValue[key];
                  }
                  modifiedValue = newValueObj;
                } else {
                  modifiedValue = filter.value;
                }
              }

              filter.key = convObj.newFieldKey;
              filter.value = modifiedValue;
              delete filter.matchMode;
            }
          }
        });
      });
    }
    return settings;
  }

  /**
   * Set selected rows
   */
  setSelectedRows() {
    this.saveTableState();

    let selectedRows: any[] = [];
    this.tableState.rowSelection.forEach((id: number) => {
      // Look for the row in both the main rows array and filtered rows array
      let selectedRow = this.rows.find((row: any) => row.id === id);
      if (!selectedRow) {
        // If not found in main rows, check filtered rows
        selectedRow = this.filteredRows.find((row: any) => row.id === id);
      }

      if (selectedRow) {
        selectedRows.push(selectedRow);
      }
    });

    this.selectedRows = selectedRows;
    console.log('Updated selectedRows count:', selectedRows.length);
  }

  /**
   * Set selected columns
   */
  setSelectedColumns() {
    this.saveTableState();

    let selectedColumns: any[] = [];
    this.tableState.columnSelection.forEach((key: string) => {
      let selectedColumn = this.columns.find((col: any) => col.key === key);
      // Only push selected column if user have column already selected on LS.
      if (selectedColumn) {
        selectedColumns.push(selectedColumn);
      }
    });
    this.selectedColumns = selectedColumns;

    this.checkForColumnSet();
  }

  /**
   * Check if columns set is valid
   */
  checkForColumnSet() {
    if (this.tableState && this.tableState.columnSelection) {
      let allColumnSets: any[] = [];
      this.columnSetGroups.forEach((group: any) => {
        group.value.forEach((set: any) => {
          set.isSelected = false;
          allColumnSets.push(set);
        });
      });

      const match = allColumnSets.find((set: any) =>
        _.isEqual(
          _.sortBy(set.value),
          _.sortBy(this.tableState.columnSelection)
        )
      );
      this.activeColumnSetName = match && match.name ? match.name : null;
    }
  }

  /**
   * Retrieves current user settings
   */
  async getUserSettings(): Promise<any> {
    let output = await this.tableService
      .getUserPageSettings(`${this.tableType}-table`, this.user.email)
      .then((data: any) => {
        const settings: any = { ...data };

        // get column data
        if (!settings.columnSets || settings.columnSets.length < 1) {
          this.loggerService.log('No saved column sets found');
          settings.columnSets = [];
        }

        // get filterSet data
        if (!settings.filterSets || settings.filterSets.length < 1) {
          this.loggerService.log('No saved filter sets found');
          settings.filterSets = [];
        }

        const updatedSettings = this.userSettingsConversions(settings);

        return updatedSettings;
      });

    this.loggerService.log('userSettings on init', output);
    return output;
  }

  /**
   * Save User Settings
   *
   * @param settings Settings to be saved/updated
   */
  async saveUserSettings(settings: any) {
    this.loggerService.log('saving userSettings', settings);

    // set payload
    const payload = {
      userRef: this.user.email,
      pageRef: `${this.tableType}-table`,
      settings: { ...settings },
      id: settings.id,
    };

    // define action and call type
    let action: string = settings.id ? 'update' : 'add';
    let call: string = ['update'].includes(action) ? 'PATCH' : 'POST';

    // save userSettings
    this.dataService.saveUserSettings(payload, action).subscribe(
      async (val) => {

        this.messageService.add({
          severity: 'success',
          summary: `${action} successful`,
          detail: `${action} was successful}`,
          life: this.toasterLife
        });

        this.loggerService.log(
          `${call} call successful. Value returned in body`,
          val
        );
        this.userSettings = _.cloneDeep(settings);
        this.userSettings.id = val._id;
      },
      (response) => {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: `${action} resulted in error`,
          detail: `${action} failed}`,
        });
        this.loggerService.log(`${call} call in error`, response);
      },
      () => {
        this.loggerService.log(`The ${call} observable is now completed.`);
      }
    );
  }

  /** Function to loop through all fields for entity and set all inputFields, filterFields, and columns */
  setAllFieldData() {
    // Define COLUMN ORDER
    this.columnOrder = !this.validationsService.isEmpty(this.tableState.customColumnsOrder)
      ? this.tableState.customColumnsOrder!
      : !this.validationsService.isEmpty(this.defaultColumnOrder)
        ? this.defaultColumnOrder
        : this.fields.map((f: any) => { return f.key })

    // Define INPUT FIELDS, FILTER FIELDS, & COLUMNS
    let inputFields: FieldData[] = [];
    let filterFields: FieldData[] = [];
    let orderedColumns: FieldData[] = [];
    let multiSelectFieldsObj: FieldData[] = [];
    let findAndReplaceFieldsObj: FieldData[] = [];

    this.fields.forEach((field: FieldData) => {
      if (field.isInputField) {
        inputFields.push({ ...field });
      }
      if (field.isFilterField) {
        filterFields.push({ ...field });
      }
      if (field.controlType == 'multiSelect_ref' && field.isInputField) {
        multiSelectFieldsObj.push({ ...field });
      }
      if (field.controlType == 'inputText' && field.isInputField) {
        findAndReplaceFieldsObj.push({ ...field });
      }
      if (this.defaultColumnOrder.includes(field.key) && !this.columnOrder.includes(field.key)) {
        let index = this.defaultColumnOrder.findIndex((key: string) => key === field.key)
        if (index < this.defaultColumnOrder.length + 1) {
          let keyAfter = this.defaultColumnOrder[index + 1]
          let newKeyIndex = this.columnOrder.findIndex((key: string) => key === keyAfter)
          this.columnOrder.splice(newKeyIndex, 0, field.key)
        } else {
          this.columnOrder.push(field.key)
        }
      }
    });

    // Set COLUMNS in custom or default order
    this.columnOrder.forEach((key: string) => {
      let column: any = this.fields.find(
        (field: FieldData) => field.isColumn && field.key === key
      );
      if (column) {
        orderedColumns.push(column);
      }
    })
    this.columns = orderedColumns

    // Set 'inputFields', 'filterFields', and 'columnsList'
    this.inputFields = _.sortBy(inputFields, 'name');
    this.filterFields = _.sortBy(filterFields, 'name');
    this.multiSelectFields = _.sortBy(multiSelectFieldsObj, 'name');
    this.findAndReplaceFields = _.sortBy(findAndReplaceFieldsObj, 'name');
    this.columnsList = _.sortBy(this.columns, 'name')
  }

  /** Function to set columnSetGroups from entity globalColumnSets and userSettings.columnSets */
  setColumnSetGroups() {
    // Define GLOBAL COLUMN SETS
    let globalColumnSets: any[] = [];
    if (this.customGlobalColumnSets && this.customGlobalColumnSets.length > 0) {
      this.customGlobalColumnSets.forEach((set: any) => {
        globalColumnSets.push({
          name: set.name,
          value: set.value,
          isGlobalSet: true,
          isExportSet: set.isExportSet,
        });
      });
    }

    // Set COLUMN SET GROUPS
    this.columnSetGroups = [
      {
        key: 'globalColumnSets',
        name: 'Global Sets',
        value: globalColumnSets,
        icon: 'pi pi-globe',
      },
      {
        key: 'userColumnSets',
        name: `${this.user.firstName}'s Sets`,
        value: this.userSettings.columnSets,
        icon: 'pi pi-user',
      },
    ];
  }

  /** Function for setting exportSets from all columnSetGroups */
  setExportColumnSets() {
    // Define ALL COLUMN SETS
    let exportSets: any[] = [];

    this.columnSetGroups.forEach((group: any) => {
      group.value.forEach((set: any) => {
        if (set.isExportSet) {
          let fields: FieldData[] = this.columns.filter((col: any) =>
            set.value.includes(col.key)
          );
          exportSets.push({
            name: set.name,
            value: fields,
          });
        }
      });
    });

    this.exportSets = exportSets;
  }

  /**
   * Handles filter actions whithin the table
   *
   * filterParams takes localStorage filters for encoding followed by setting the filters url query param to that value
   * filterSelectionParams takes localStorage filterSelection for encoding followed by setting the filterSelection url query param to that value
   */

  onFilter(e: any) {

    this.filterParams = _.cloneDeep(this.tableState.filters);
    this.filterSelectionParams = _.cloneDeep(this.tableState.filterSelection);

    // Encrypt
    this.encryptedFilterParams = this.aesEncryptionDecryptionService.encrypt(this.filterParams, this.secureKey)
    this.encryptedFilterSelectionParams = this.aesEncryptionDecryptionService.encrypt(this.filterSelectionParams, this.secureKey)

    var JSURL = require("jsurl");

    this.encryptedFilterParams = JSURL.stringify(this.filterParams);
    this.encryptedFilterSelectionParams = JSURL.stringify(this.filterSelectionParams);

    this.urlQueryParams['filters'] = this.encryptedFilterParams == '~()' ? '~()' : this.encryptedFilterParams
    this.urlQueryParams['filterSelection'] = this.encryptedFilterSelectionParams == '~()' ? '~()' : this.encryptedFilterSelectionParams

    this.updateURLQueryParams();

    this.filteredRows = e.filteredValue;

  }


  applyAllFiltersToData(data: any[]) {
    // Apply the archived filter
    let result = data.filter(row => {
      if (this.showArchivedRows) {
        return [true, false].includes(row.archived);
      } else {
        return [false].includes(row.archived);
      }
    });

    // Apply any other active filters (if applicable)

    return result;
  }

  async onShowHideArchived() {
    // When toggling the showArchivedRows variable
    this.showArchivedRows = !this.showArchivedRows;

    // Save the value to localStorage
    localStorage.setItem('showArchivedRows', JSON.stringify(this.showArchivedRows));
    this.loggerService.log('Stored showArchivedRows:', this.showArchivedRows);

    let filterValue = [];

    if (this.showArchivedRows) {
      this.filterParams = { archived: [true, false] };
      filterValue = ['Yes', 'No'];
      this.filterSelectionParams = { archived: true };
    } else {
      this.filterParams = { archived: [false] };
      filterValue = ['No'];
      this.filterSelectionParams = { archived: true };
    }

    var JSURL = require("jsurl");

    this.encryptedFilterParams = JSURL.stringify(this.filterParams);
    this.encryptedFilterSelectionParams = JSURL.stringify(this.filterSelectionParams);

    const { encryptedFilterParams, encryptedFilterSelectionParams } = this;

    this.urlQueryParams = {
      filters: encryptedFilterParams === '~()' ? '~()' : encryptedFilterParams,
      filterSelection: encryptedFilterSelectionParams === '~()' ? '~()' : encryptedFilterSelectionParams
    };

    this.updateURLQueryParams();

    this.filtersSidebarComponent.applyArchivedFilter(filterValue)
  }



  /** Update URL query params for global search, sortBy, and sortOrder. */
  updateURLQueryParams() {
    let urlQueryParams: any = {};
    for (let key of ['global', 'sortBy', 'sortOrder', 'filters', 'filterSelection']) {
      urlQueryParams[key] = !this.validationsService.isEmpty(
        this.urlQueryParams[key]
      )
        ? this.urlQueryParams[key]
        : null;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: urlQueryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  /**
   * Decode Encoded Filters grabs encoded values from filters and filterSelection and decodes them to apply filters
   *
   * encodedFilterParam contains encoded value for filters
   * encodedFilterSelectionParam contains encoded value for filterSelection
   */

  decodeEncodedFilters() {
    let encodedFilterParam = this.route.snapshot.queryParamMap.get('filters')
    let encodedFilterSelectionParam = this.route.snapshot.queryParamMap.get('filterSelection')

    if (encodedFilterParam) {
      let JSURL = require("jsurl");
      let decodedFilters = JSURL.parse(encodedFilterParam);
      let decodedFilterSelection = JSURL.parse(encodedFilterSelectionParam)

      this.loggerService.log('decodedFilterSelection', decodedFilterSelection)
      this.loggerService.log('decodedFilters', decodedFilters)

      this.tableState.filterSelection = decodedFilterSelection;
      this.tableState.filters = decodedFilters;
    }
  }

  /** Handle Custom Sort events.
   * * If the value being sorted is an object AND the object property being sorted is not 'name', the field MUST have `viewControl.sortBy <string>` to identify the correct property.
   * @param event sort event comming from table
   */
  customSort(event: SortEvent) {
    // this.loggerService.log('custom sort', event);

    // If table is in edit mode, prevent sorting
    if (this.tableConfig.isEditMode) {
      return;
    }

    this.urlQueryParams.sortBy = event.field!;
    this.urlQueryParams.sortOrder = event.order!;
    this.updateURLQueryParams();

    const field: any = this.fields.find((f: FieldData) => f.key == event.field);
    const sortBy =
      field && field.viewControl && field.viewControl.sortBy
        ? field.viewControl.sortBy
        : 'name';

    event.data!.sort((data1, data2) => {
      let values: any[] = [];

      for (let value of [data1[event.field!], data2[event.field!]]) {
        const parsedValue: string = this.parseValueToCompare(
          value,
          sortBy,
          event.order!
        );
        values.push(parsedValue);
      }
      const result = this.tableType == 'items-sourcing' ? Number(data2.promoted) - Number(data1.promoted) || values[0].localeCompare(values[1], undefined, {
        numeric: true,
      }) : values[0].localeCompare(values[1], undefined, {
        numeric: true,
      });
      return event.order! * result;
    });
  }
  /** Modifies a value to be used in sorting.
   * @param value value being modified.
   * @param sortBy string indicating the property being sorting, if the value is an object.
   * @param order number indicating the sort order
   * @returns modified string
   */
  parseValueToCompare(value: any, sortBy: string, order: number): string {
    if (this.validationsService.isEmpty(value)) {
      return '';
      // Alternative — To sort all empty values to bottom:
      // return order > 0 ? 'ZZZZZZZZZZ' : '';
    } else {
      let result;
      switch (true) {
        case _.isArray(value) && value.length > 0:
          let valueArray: any[] = [];
          for (let val of value) {
            valueArray.push(this.parseValueToCompare(val, sortBy, order));
          }

          if (typeof valueArray[0] == 'number') {
            // if values are numbers, the greatest value will be used for compare.
            result = valueArray.sort((a, b) => {
              return b - a;
            })[0];
          } else {
            result =
              order < 0 ? valueArray.sort().reverse() : valueArray.sort();
            // Alternative — To skip pre-sorting:
            // result = valueArray;
          }
          break;

        case typeof value == 'object':
          result = this.parseValueToCompare(value[sortBy], sortBy, order);
          break;

        default:
          result = value;
      }

      return result
        .toString()
        .toLowerCase()
        .replace(/[^a-z0-9]/gi, '');
    }
  }

  /** Handle checkbox toggle envents on a table row
   * @param row Row that has the checkbox toggle
   * @param isChecked Flag that sets whether or not is checked
   */
  onRowCheckboxToggle(row: any, isChecked: boolean) {
    let rowSelection: any[] = [...this.tableState.rowSelection];
    if (row) {
      console.log(row, isChecked)
      if (Array.isArray(row)) {
        rowSelection = [...rowSelection, row.reduce((acc, val) => acc.concat(val.id), [])]
        rowSelection = rowSelection.reduce((acc, val) => acc.concat(val), [])
      } else {
        if (isChecked) {
          rowSelection.push(row.id);
          // this.messageService.add({
          //   severity: 'success',
          //   summary: `Row Selected`,
          //   detail: 'Row selected successfully',
          //   life: this.toasterLife
          // });
        } else {
          let rowIndex: number = rowSelection.findIndex(
            (id: number) => id == row.id
          );
          rowSelection.splice(rowIndex, 1);
        }
      }

    }
    console.log(rowSelection)
    this.tableState.rowSelection = _.sortedUniq(_.sortBy(rowSelection));
    this.saveTableState();
    this.setSelectedRows();
  }

  checkboxTable(tableCheckBox: TableCheckbox, event: any) {
    console.log(event);
    const isChecked = !!event.target.ariaChecked;
    if (event.button === 0 && event.shiftKey) {
      if (isChecked) {
        this.table.selectRange(event, tableCheckBox.index);
      } else {
        this.table.rangeRowIndex = tableCheckBox.index;
        this.table.clearSelectionRange(event);
        this.table.tableService.onSelectionChange();
      }
    }
    this.table.anchorRowIndex = tableCheckBox.index;
  }

  /** Handle additions to column sets for a given user
   * @param setName Column set name to be added.
   */
  onAddColumnSet(setName: string) {
    const settings = { ...this.userSettings };

    // Define ALL COLUMN SETS
    let allColumnSets: any[] = [];
    this.columnSetGroups.forEach((group: any) => {
      group.value.forEach((set: any) => {
        allColumnSets.push(set);
      });
    });

    // value cannot be empty, name cannot be blank, name or value cannot match any existing set name or value
    if (
      this.tableState.columnSelection.length < 1 ||
      !setName ||
      allColumnSets.find((set: any) => [setName].includes(set.name)) ||
      this.activeColumnSetName
    ) {
      return;
    }

    // create new columnSet object
    const newColumnSet = {
      name: setName,
      value: this.tableState.columnSelection,
    };
    settings.columnSets.push(newColumnSet);

    this.saveUserSettings(settings);
    if (settings.columnSets) {
      const setGroup = this.columnSetGroups.find(
        (group: any) => group.key == 'userColumnSets'
      );
      setGroup.value = settings.columnSets;
    }

    this.activeColumnSetName = setName;
    this.showAddColumnSet = !this.showAddColumnSet;

    this.messageService.add({
      severity: 'success',
      summary: `Column Set Added`,
      detail: 'Column set added successfully',
      life: this.toasterLife
    });
  }

  /**
   * Handle column set remove action
   *
   * @param setName Column set name to be added.
   */
  onRemoveColumnSet(setName: string) {
    const settings = { ...this.userSettings };

    const updateIndex = settings.columnSets.findIndex((set: any) =>
      [setName].includes(set.name)
    );
    settings.columnSets.splice(updateIndex, 1);

    if ([setName].includes(this.activeColumnSetName)) {
      this.activeColumnSetName = '';
    }

    this.saveUserSettings(settings);
    if (settings.columnSets) {
      const setGroup = this.columnSetGroups.find(
        (group: any) => group.key == 'userColumnSets'
      );
      setGroup.value = settings.columnSets;
    }

    this.messageService.add({
      severity: 'success',
      summary: `Column Set Removed`,
      detail: 'Column set removed successfully',
      life: this.toasterLife
    });
  }

  /**
   * Handle column set selection
   */
  async onSelectColumnSet() {
    // Define ALL COLUMN SETS
    let allColumnSets: any[] = [];
    this.columnSetGroups.forEach((group: any) => {
      group.value.forEach((set: any) => {
        allColumnSets.push(set);
      });
    });
    // Define SELECTED COLUMN SET
    const selectedSet = allColumnSets.find((set: any) =>
      [this.activeColumnSetName].includes(set.name)
    );
    // Set Column Selection
    if (selectedSet && selectedSet.value.length > 0) {
      this.tableState.columnSelection = selectedSet.value;
      this.setSelectedColumns();
    }
    if (this.tableConfig.useDynamicTableColumns) {
      await this.onSelectColumn();
    }
    // Set Column Order
    this.columnOrder = this.getMergedColOrder(
      this.tableState.columnSelection,
      this.defaultColumnOrder
    );
    this.tableState.customColumnsOrder = this.columnOrder;
    this.saveTableState();

    this.loggerService.log(
      'Column Selection:',
      this.tableState.columnSelection
    );
  }

  /**
   * Handle column selection action
   */
  async onSelectColumn(field: FieldData | null = null, isFilterAction: boolean = false) {
    // We need this delay to avoid making unnecessary
    // API calls.
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }

    this.currentTimeout = setTimeout(async () => {
      this.columnsLoading = true;
      let selectedColumnKeys: any[] = [];

      this.columnOrder.forEach((key) => {
        if (this.tableState.columnSelection.includes(key)) {
          selectedColumnKeys.push(key);
        }
      });

      if (this.tableConfig.useDynamicTableColumns) {
        this.newSelectedRows = [];

        // If the user added a new column, we should fetch new data
        this.tableState.columnSelection.forEach(column => {
          if (!this.prevColumnSelection.includes(column)) {
            this.newSelectedRows.push(column);
          }
        });

        let fetchNewColumns = false;
        let entityColumns = this.rows && this.rows.length > 0 ? Object.keys(this.rows[0]) : [];

        for (let column of this.newSelectedRows) {
          if (!entityColumns.includes(column)) {
            fetchNewColumns = true;
            break;
          }
        }

        let customColumnsSelected = this.tableService.handleCustomColumns('', this.newSelectedRows, this.tableType)

        if (fetchNewColumns && this.newSelectedRows && this.newSelectedRows.length > 0) {
          await this.getTableRecords(this.newSelectedRows, true);
        }
        else if (customColumnsSelected.length > 0 && !fetchNewColumns && this.newSelectedRows && this.newSelectedRows.length > 0) {
          await this.getTableRecords(this.newSelectedRows, true);
        }
        else {
          let newRowsData = await this.tableService.transformRows(this.rows, this.tableType, this.newSelectedRows, this.tableConfig.customBuildDataKeys ? this.tableConfig.customBuildDataKeys : null);

          this.rows = _.cloneDeep(newRowsData);
        }
      }

      this.tableState.columnSelection = selectedColumnKeys;
      this.setSelectedColumns();
      this.prevColumnSelection = _.cloneDeep(this.tableState.columnSelection);
      if (isFilterAction && field) {
        await this.filtersSidebarComponent.onApplyFilter(field);
      }
      this.currentTimeout = null;
      this.columnsLoading = false;
    }, 800);
  }

  /** Handle column re-order action
   * @param event Event comming from drag-and-drop action on columns order.
   */
  async onColumnsReorder(event: any) {
    if (event && event.columns) {
      this.tableState.columnSelection = _.map(event.columns, 'key');
      this.prevColumnSelection = _.cloneDeep(this.tableState.columnSelection);

      // Set Column Order
      this.columnOrder = this.getMergedColOrder(
        this.tableState.columnSelection,
        this.columnOrder
      );
      this.tableState.customColumnsOrder = this.columnOrder;
      this.saveTableState();
    }
  }

  /** Arrange full column order by merging existing column order with selected columns.
   * @param selected ordered selected columns
   * @param order existing column order
   * @returns ordered array of all column keys
   */
  getMergedColOrder(selected: string[], order: string[]): any {
    let matchingKey = order.find((key: string) => selected.includes(key));
    if (matchingKey) {
      let orderIndex = order.indexOf(matchingKey);
      let selectedIndex = selected.indexOf(matchingKey);

      const beforeInsert = selected.slice(0, selectedIndex);
      const insertedKeys = order
        .slice(0, orderIndex)
        .filter((key: string) => !selected.includes(key));
      const afterInsert = selected.slice(selectedIndex);

      const tempOrder = [...beforeInsert, ...insertedKeys, ...afterInsert];

      let recOrder = order.filter(
        (key) => key !== matchingKey && !insertedKeys.includes(key)
      );

      return this.getMergedColOrder(tempOrder, recOrder);
    } else {
      return [...selected, ...order];
    }
  }

  /**
   *
   * @param isOrganicSource determins whether to set the columns to organic or inorganicSourceCSVColKeys
   *
   * setCSVBatchColumns function set the columns in the correct order to allow the user to export data out for vendors
   * setBatchColumns function set the columns in the order specified in the order by content
   *
   * Each function sets the columnSelection to an array that is defined in the following variables
   */

  setCSVBatchedColumns(isOrganicSource: boolean = false) {
    this.tableState.columnSelection = isOrganicSource
      ? this.organicSourceCSVColKeys
      : this.inorganicSourceCSVColKeys;
    this.prevColumnSelection = _.cloneDeep(this.tableState.columnSelection);
    this.setSelectedColumns();
  }

  setBatchColumns(isOrganicSource: boolean = false) {
    this.tableState.columnSelection = isOrganicSource
      ? this.organicSourceColKeys
      : this.inorganicSourceColKeys;
    this.prevColumnSelection = _.cloneDeep(this.tableState.columnSelection);
    this.setSelectedColumns();
  }

  /**
   * Habkde header checkbox toggle action
   *
   * @param isChecked Flag that sets whether or not toggle is checked.
   */
  onHeaderCheckboxToggle(isChecked: boolean) {
    let rowSelection: number[] = [...this.tableState.rowSelection];
    console.log('Header checkbox toggled:', isChecked);
    console.log('Current filtered rows count:', this.filteredRows.length);

    if (isChecked && this.pageSelect) {
      // Select only current page rows
      const currentPageRows = this.filteredRows.slice(this.tableState.first, this.tableState.first + this.tableState.rows);
      console.log('Current page rows to select:', currentPageRows.length);

      currentPageRows.forEach((row) => {
        if (!rowSelection.includes(row.id)) {
          rowSelection.push(row.id);
        }
      });
    } else if (isChecked && !this.pageSelect) {
      // Select all rows
      this.loggerService.log('CHECKED ALL');
      this.filteredRows.forEach((row: any) => {
        if (!rowSelection.includes(row.id)) {
          rowSelection.push(row.id);
        }
      });
    } else {
      // Uncheck all rows
      this.loggerService.log('UNCHECKED ALL');
      this.filteredRows.forEach((row: any) => {
        let rowIndex: number = rowSelection.findIndex(
          (id: number) => id == row.id
        );
        if (rowIndex >= 0) {
          rowSelection.splice(rowIndex, 1);
        }
      });
    }

    this.tableState.rowSelection = _.sortedUniq(_.sortBy(rowSelection));
    this.setSelectedRows();

    // Force UI update
    this.cd.detectChanges();
  }

  /**
   * Handles reset row action
   */
  onResetRows() {
    this.tableState.rowSelection = [];
    this.selectedRows = [];
    this.saveTableState();

    // this.messageService.add({
    //   severity: 'success',
    //   summary: `Row Reset`,
    //   detail: 'Row selection has been reset.',
    // });
  }


  exportCSV(exportCols: any[]) {
    console.log('exportCols: ', exportCols);

    // Map each row to a format suitable for CSV export
    const exportTable = this.filteredRows.map(row => {
      let exportRow: any = {};
      exportCols.forEach(col => {
        exportRow[col.name] = this.getExportValue(row[col.key]); // Note: We use col.name as the key for CSV
      });
      return exportRow;
    });

    console.log('exportTable: ', exportTable);

    // Convert the data to CSV using PapaParse
    const csv = this.papa.unparse(exportTable, {
      header: true,
    });

    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    // Create a link and trigger the download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', `${this.tableType}_${new Date().toISOString().split('T')[0]}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Hide actions and show success message
    this.showActions = false;
    this.messageService.add({
      severity: 'success',
      summary: 'CSV Exported',
      detail: 'Table data has been exported to CSV.',
      life: this.toasterLife,
    });
  }


  /**
   * Handles the conversion of a value to a string format for CSV export.
   * @param value The value to convert.
   * @returns A string representation of the value.
   */
  private getExportValue(value: any): string {
    // Handle null or undefined values
    if (value == null) {
      return '';
    }

    // Handle Date objects
    if (value instanceof Date) {
      return value.toISOString();
    }

    // Handle Array of objects or strings
    if (Array.isArray(value)) {
      // Check if the array contains strings
      if (value.every(item => typeof item === 'string')) {
        return value.join(' | ');
      } else {
        // Process array of objects
        return value.map(item => this.formatObjectForExport(item)).join(' | ');
      }
    } else if (typeof value === 'object') {
      return this.formatObjectForExport(value);
    }

    // Convert any other types directly to string
    return value.toString();
  }

  private formatObjectForExport(obj: any): string {
    // Check if object is non-null and has properties
    if (obj && typeof obj === 'object') {
      // Check if both name and id exist in the object
      if ('name' in obj && ('id' in obj || '_id' in obj)) {
        const id = obj.id || obj._id;
        return `${obj.name}`;
      } else {
        // Extract keys and values; if value is an object, call recursively
        return Object.entries(obj)
          .map(([key, val]) => {
            if (val && typeof val === 'object') {
              return `${key}: ${this.formatObjectForExport(val)}`;
            } else if (val) {
              return `${key}: ${val}`;
            } else {
              return ''
            }
          })
          .join(', ');
      }
    }
    return '';
  }



  /**
   * Parse a value from a column/row
   *
   * @param row Row value to be parsed
   * @param col Column value to be parsed
   */
  parseValue(row: any, col: any) {
    const data: any = row[col.key];
    const controlType: string = col.controlType;
    let result: any;

    // console.log('data:', data);
    // console.log('controlType:', controlType);

    if (
      [
        'multiSelect',
        'multiSelect_ref',
        'autoComplete-multi_ref',
        'formArray',
        'lineItem',
        'nestedGroup',
        'ref-link'
      ].includes(controlType)
    ) {
      let values: any[] = [];
      data.forEach((value: any) => {
        if (['referenceLinks', 'internalReferenceLinks'].includes(col.key)) {
          values.push(value.toString());
        } else if (
          ['costs_ref', 'rewards_ref', 'prizes_ref'].includes(col.key)
        ) {
          // handle if 'id' field is null for some reason.
          if (value.id) {
            values.push(`${value['c']} ${value.id['name']}`);
          } else {
            values.push(`${value['c']}`);
          }
        } else if (col.key == 'achievements_ref') {
          this.loggerService.log('checking Parse Value', value);
        } else values.push(value.name);
      });
      result = values.join();
      this.loggerService.log('result', result);
    } else {
      result = null;
      switch (controlType) {
        case 'calendar-start':
        case 'calendar-end':
        case 'date':
          result = new Date(data);
          break;
        case 'dropdown':
        case 'dropdown_ref':
        case 'autoComplete_ref':
          if (col.key == 'progressionLevel_ref') {
            result = data.level;
          } else {
            result = data.name;
          }
          break;
        case 'toggle':
          result =
            data.toString() == 'true'
              ? data.toString().replace(/true/, 'Yes')
              : data.toString().replace(/false/, 'No');
          break;
        case 'formArray':
        case 'nestedGroup':
        case 'lineItem':
          this.loggerService.log(result, data);
          break;
        default:
          if (typeof data == 'object') {
            result = JSON.stringify(this.flattenObj(data));
          } else {
            result = data.toString();
          }
          break;
      }
    }

    return result;
  }

  /**
   * Flatten object
   * recursively flattens all properties of a nested object
   */
  flattenObj(obj: any, parent: any = null, res: any = {}) {
    const skippedKeys = [
      'color', 'buildCount', 'date',
      '_id'
    ];
    for (let key in obj) {
      if (!skippedKeys.includes(key)) {
        let propName = parent ? parent + '.' + key : key;
        if (typeof obj[key] == 'object') {
          this.flattenObj(obj[key], propName, res);
        } else {
          res[propName] = obj[key];
        }
      }
    }
    return res;
  }

  /**
   * Parse Filename from path
   *
   * @param path Asset path
   */
  parseFilenameFromPath(path: string) {
    if (!path) {
      return '';
    }
    var n = path.lastIndexOf('/');
    var result = path.substring(n + 1);
    return result;
  }

  /**
   * Get prop array
   *
   * @param arr List of items
   * @param prop Prop that will be used to filter the array
   */
  getPropArray(arr: any[], prop: string) {
    let result: any[] = [];
    if (arr) {
      arr.forEach((o: any) => result.push(o[prop]));
    }
    return result;
  }

  /**
   * Retrieves image source
   *
   * @param key Image key
   * @param row Image row
   */
  getImageSrc(key: string, row: any) {
    // temporarily returning null by default until images are available.
    return null;

    let srcKey = key.replace(/_img/gi, '');

    if (row[srcKey]) {
      return row[srcKey];
    } else return null;
  }

  /**
   * Handle bulk edit start action
   */
  async onStartBulkEdit() {
    this.showBulkEdit = true;
    this.bulkEdit.rows = [...this.selectedRows];
    this.bulkEdit.stage = 'selectField';
  }
  /**
   * Handle edit options dialogue
   */
  onShowInputEditOptions() {
    /**
     * Shows the modal for multiselect edit, stores the row and changes the stage of the edition
     */
    this.showEditOptions = true;
    this.bulkMultiSelect.rows = [...this.selectedRows];
    this.bulkMultiSelect.stage = 'selectInputEditMode';
  }
  /**
   * Handle edit options dialogue
   */
  onShowEditOptions() {
    /**
     * Shows the modal for multiselect edit, stores the row and changes the stage of the edition
     */
    this.showEditOptions = true;
    this.bulkMultiSelect.rows = [...this.selectedRows];
    this.bulkMultiSelect.stage = 'selectEditMode';
  }
  /**
   * Handle edit options dialogue
   */
  onSelectFindAndReplace() {
    /**
     * Shows the modal for find and replace edit, stores the row and changes the stage of the edition
     */
    this.showFindAndReplace = true;
    this.bulkMultiSelect.rows = [...this.selectedRows];
    this.bulkMultiSelect.stage = 'selectFindAndReplaceFields';
  }

  onFieldSelectFindandReplace() {
    /**
     * Stores the current time to see when it was updated, stores the field and changes the modal
     */
    let today = new Date().setHours(0, 0, 0, 0);
    this.bulkMultiSelect.today = new Date(today);

    this.bulkMultiSelectAction.selectedField = this.bulkMultiSelect.field.key;
    this.bulkMultiSelect.stage = 'editFieldFindAndReplace';
  }

  onFindAndReplaceEdited() {
    /**
     * Changes the current modal for find and replace function
     */
    this.bulkMultiSelect.stage = 'reviewAndSubmitFindAndReplace';
  }

  onFieldMultiselectEdit() {
    /**
     * Stores the current time to see when it was updated, stores the field and changes the modal
     */
    let today = new Date().setHours(0, 0, 0, 0);
    this.bulkMultiSelect.today = new Date(today);

    this.bulkMultiSelectAction.selectedField = this.bulkMultiSelect.field.key;
    this.bulkMultiSelect.stage = 'editFieldReplace';
  }

  onSelectorMultiselect(event: any) {
    /**
     * Checks for current edit mode and stores the value/resets values as needed
     */
    if (this.bulkMultiSelectAction.mode == 'replace') {
      this.bulkMultiSelectAction.valueToReplace = [];
      this.bulkMultiSelectAction.valueToReplace.push(event.value);
    } else {
      this.bulkMultiSelectAction.valueToReplace = event.value;
    }
  }

  selectInputEditOption(mode: string) {
    this.multipleOptions = mode == 'replace' ? false : true;
    switch (mode) {
      case 'replace':
        this.bulkMultiSelectAction.valueToReplace = '';
        this.bulkMultiSelectAction.replaceValues = '';
        this.bulkMultiSelectAction.mode = 'replace-input';
        this.bulkMultiSelect.stage = 'editFieldReplaceInternalNotes';
        break;
      case 'append':
        this.bulkMultiSelectAction.replaceValues = [];
        this.bulkMultiSelectAction.mode = 'append-input';
        this.bulkMultiSelect.stage = 'editFieldAppendInternalNotes';
        break;
    }
    this.showBulkEditForInput = true;
  }

  selectEditOption(mode: string) {
    this.multipleOptions = mode == 'replace' ? false : true;

    if (this.bulkMultiSelectAction.selectedField == 'costs_ref') {
      switch (mode) {
        case 'replace':
          this.bulkMultiSelectAction.valueToReplace = '';
          this.bulkMultiSelectAction.replaceValues = '';
          this.bulkMultiSelectAction.mode = 'replace-cost';
          this.bulkMultiSelect.stage = 'editFieldReplaceCost';
          break;
        case 'add':
          this.bulkMultiSelectAction.replaceValues = [];
          this.bulkMultiSelectAction.mode = 'add-cost';
          this.bulkMultiSelect.stage = 'editFieldAddCost';
          break;
        case 'remove':
          this.bulkMultiSelectAction.valueToReplace = '';
          this.bulkMultiSelectAction.mode = 'remove-cost';
          this.bulkMultiSelect.stage = 'editFieldRemoveCost';
          break;
      }
      this.showBulkEditForCost = true;
    }
    else {
      this.bulkMultiSelectAction.valueToReplace = [];
      this.bulkMultiSelectAction.replaceValues = [];
      this.bulkMultiSelectAction.mode = mode;
      this.bulkMultiSelect.stage = 'editFieldReplace';
    }
  }

  /**
   * Hides Bulk Edit for cost dialog and
   * returns the user to the edit mode selection.
   */
  goBackBulkEditCost() {
    this.showBulkEditForCost = false;
    this.onShowEditOptions();
    this.showBulkEdit = false;
  }
  /**
   * Hides Bulk Edit for cost dialog and
   * returns the user to the edit mode selection.
   */
  goBackBulkEditInput() {
    this.showBulkEditForInput = false;
    this.onShowInputEditOptions();
    this.showBulkEdit = false;
  }

  /**
   * Sets the replaced value and the new value for cost bulk edit.
   *
   * @param event Event value.
   * @param isValueToReplace Flag that sets whether or not is vale to replace action.
   */
  onCostTypeReplaceClick(event: any, isValueToReplace: boolean) {
    if (isValueToReplace) {
      this.bulkMultiSelectAction.valueToReplace = event.value;
    }
    else {
      this.bulkMultiSelectAction.replaceValues = event.value;
    }
  }

  /**
   * Returns an array with the existing and the new cost values.
   *
   * @param currentCost Existing cost(s).
   * @param newCost New cost(s).
   */
  parseNewCostValueForTableView(currentCost: Array<any>, newCost: Array<any>, isAddAction: boolean = true) {
    let costs = [];
    if (isAddAction) {
      if (!currentCost) {
        currentCost = [];
      }
      costs.push(...currentCost, ...newCost);
    }
    else {
      if (this.bulkMultiSelectAction.replaceValues && this.bulkMultiSelectAction.replaceValues.length > 0 && currentCost && currentCost.length > 0) {
        let tempCosts = [...currentCost];
        for (let cost of tempCosts) {
          if (cost) {
            if (cost.lineItemType && cost.lineItemType == "Currency") {
              const indexOfObject = currentCost.findIndex((object: any) => {
                return (object.id && object.id._id && object.id._id == this.bulkMultiSelectAction.replaceValues) || object.id == this.bulkMultiSelectAction.replaceValues;
              });
              if (indexOfObject != -1) {
                tempCosts.splice(indexOfObject, 1);
              }
            }
          }
        }
        costs = tempCosts;
      }
    }
    return costs;
  }

  /**
   * Returns the name of the currency selected.
   *
   * @param costId Mongo Id of the currency selected
   */
  parseCostType(costId: any) {
    return (this.options['currencies'].find((x: any) => x._id == costId)).name;
  }

  /**
   * Handles Cost Bulk action confirmation
   *
   * @param mode Bulk Action mode.
   */
  async confirmBulkEditForCost(mode: string) {
    switch (mode) {
      case 'replace-cost':
        await this.replaceCostCurrencyType(this.bulkMultiSelectAction.valueToReplace, this.bulkMultiSelectAction.replaceValues);
        break;
      case 'add-cost':
        await this.addCost();
        break;
      case 'remove-cost':
        await this.removeCost();
        break;
    }
  }

  /**
   * Adds cost(s) to selected records.
   */
  async addCost() {
    this.tableSpinnerIcon = "timer";
    this.showSpinnerProgress = false;
    this.spinnerService.loadSpinner();
    let count = 0;
    let failedPayloads = 0;
    if (this.bulkMultiSelectAction.replaceValues && this.bulkMultiSelectAction.replaceValues.length > 0) {
      for (let record of this.selectedRows) {
        if (record.costs_ref && record.costs_ref.length > 0) {
          record.costs_ref.push(...this.bulkMultiSelectAction.replaceValues);
        }
        else {
          record.costs_ref = this.bulkMultiSelectAction.replaceValues;
        }
        try {
          await this.dataService.updateRecord(record.id, this.tableType, { costs_ref: record.costs_ref });
          count += 1;
        }
        catch (error) {
          failedPayloads += 1;
        }
      }
      this.showEditOptions = false;
      this.showBulkEdit = false;
      this.showBulkEditForCost = false;
      this.showActions = false;
      this.showBulkEditForCostReview = false;
      await this.getRowData();
    }


    this.bulkMultiSelectAction.valueToReplace = null;
    this.bulkMultiSelectAction.replaceValues = null;
    this.spinnerService.endSpinner();

    if (failedPayloads > 0) {
      this.messageService.add(
        {
          sticky: true,
          severity: 'error',
          summary: 'Updates Error',
          detail: `${failedPayloads} rows not updated.`
        });
    }

    if (count > 0) {
      this.messageService.add(
        {
          severity: 'success',
          summary: 'Updates Successful',
          detail: `${count} rows successfully updated.`,
          life: this.toasterLife
        });
    }
  }

  /**
   * Removes all costs for selected records.
   */
  async removeCost() {
    this.tableSpinnerIcon = "timer";
    this.showSpinnerProgress = false;
    this.spinnerService.loadSpinner();
    let count = 0;
    let failedPayloads = 0;

    if (this.selectedRows && this.selectedRows.length > 0) {
      for (let row of this.selectedRows) {
        if (row.costs_ref && row.costs_ref.length > 0) {
          for (let cost of row.costs_ref) {
            if (cost.lineItemType && cost.lineItemType == "Currency") {
              const indexOfObject = row.costs_ref.findIndex((object: any) => {
                return (object.id && object.id._id && object.id._id == this.bulkMultiSelectAction.replaceValues) || object.id == this.bulkMultiSelectAction.replaceValues;
              });
              if (indexOfObject != -1) {
                row.costs_ref.splice(indexOfObject, 1);
              }
            }
          }
          try {
            await this.dataService.updateRecord(row.id, this.tableType, { costs_ref: row.costs_ref });
            count += 1;
          }
          catch (error) {
            failedPayloads += 1;
          }
        }
      }
      this.showEditOptions = false;
      this.showBulkEdit = false;
      this.showBulkEditForCost = false;
      this.showActions = false;
      this.showBulkEditForCostReview = false;
      await this.getRowData();
    }
    this.bulkMultiSelectAction.valueToReplace = null;
    this.bulkMultiSelectAction.replaceValues = null;
    this.spinnerService.endSpinner();

    if (failedPayloads > 0) {
      this.messageService.add(
        {
          sticky: true,
          severity: 'error',
          summary: 'Updates Error',
          detail: `${failedPayloads} rows not updated.`
        });
    }

    if (count > 0) {
      this.messageService.add(
        {
          severity: 'success',
          summary: 'Updates Successful',
          detail: `${count} rows successfully updated.`,
          life: this.toasterLife
        });
    }
  }

  /**
   * Replace Cost Curency Type
   *
   * @param currencyTypeToReplaceId Id of the currency type to replace.
   * @param newCurrencyTypeId Id of the new currency type selected.
   */
  async replaceCostCurrencyType(currencyTypeToReplaceId: any, newCurrencyTypeId: any) {
    this.tableSpinnerIcon = "timer";
    this.showSpinnerProgress = false;
    this.spinnerService.loadSpinner();
    let count = 0;
    let failedPayloads = 0;
    if (this.selectedRows && this.selectedRows.length > 0) {
      for (let row of this.selectedRows) {
        if (row.costs_ref && row.costs_ref.length > 0) {
          for (let cost of row.costs_ref) {
            if (cost.lineItemType && cost.lineItemType == "Currency") {
              if ((cost.id && cost.id._id && cost.id._id == currencyTypeToReplaceId) || cost.id == currencyTypeToReplaceId) {
                cost.id = newCurrencyTypeId;
                try {
                  await this.dataService.updateRecord(row.id, this.tableType, { costs_ref: row.costs_ref });
                  count += 1;
                }
                catch (error) {
                  failedPayloads += 1;
                }
              }
            }
          }
        }
      }
      this.showEditOptions = false;
      this.showBulkEdit = false;
      this.showBulkEditForCost = false;
      this.showActions = false;
      this.showBulkEditForCostReview = false;
      await this.getRowData();
    }
    this.bulkMultiSelectAction.valueToReplace = null;
    this.bulkMultiSelectAction.replaceValues = null;
    this.spinnerService.endSpinner();

    if (failedPayloads > 0) {
      this.messageService.add(
        {
          sticky: true,
          severity: 'error',
          summary: 'Updates Error',
          detail: `${failedPayloads} rows not updated.`
        });
    }

    if (count > 0) {
      this.messageService.add(
        {
          severity: 'success',
          summary: 'Updates Successful',
          detail: `${count} rows successfully updated.`,
          life: this.toasterLife
        })
    }
  }

  /**
   * Check if field is multselect, if so go into multiselect flow
   * Handle field selected for edit
   */
  async onFieldSelectedForEdit(event?: any) {
    if (this.tableConfig.useDynamicTableColumns && !this.tableState.columnSelection.includes(event.value.key)) {
      this.tableState.columnSelection.push(event.value.key);
      await this.onSelectColumn();
    }
    if (
      ['colors_ref', 'materials_ref', 'patterns_ref', 'traits_ref', 'costs_ref', 'env', 'tags_ref'].includes(
        event.value.key
      )
    ) {
      this.loggerService.log('event', event.value)
      this.bulkMultiSelect.field = event.value;
      this.bulkMultiSelectAction.selectedField = event.value.key;
      let today = new Date().setHours(0, 0, 0, 0);
      this.bulkMultiSelect.today = new Date(today);
      this.onShowEditOptions();
      this.showBulkEdit = false;
      this.bulkMultiSelectProgress = 0;
      return;
    }

    if (
      ['vendorNotes', 'internalNotes',].includes(
        event.value.key
      )
    ) {
      this.onShowInputEditOptions();
      this.detectEditConflicts();
      this.createEditForm();
      this.showBulkEdit = false;
    } else {

      let today = new Date().setHours(0, 0, 0, 0);
      this.bulkEdit.today = new Date(today);

      this.detectEditConflicts();
      this.createEditForm();

      this.bulkEdit.stage = 'editField';

    }

  }

  /**
   * Handle field edited action
   */
  onFieldEdited() {
    if (this.bulkEdit.conflict) {
      if (this.getConflictRows().length > 0) {
        this.bulkEdit.conflict.rows = this.getConflictRows();
      } else {
        this.bulkEdit.conflict = null;
      }
    }

    this.bulkEdit.stage = 'reviewAndSubmit';
  }

  onMultipleFieldEdited() {
    /**
     * Change the modal stage
     */
    this.bulkMultiSelect.stage = 'reviewAndSubmitReplaced';
  }

  /**
   * Clear table field
   *
   * @param form Form group
   * @param fieldKey Field key.
   */
  clearField(form: UntypedFormGroup, fieldKey: string) {
    form.get(fieldKey)!.reset();
  }

  /**
   * Check options selected by the user
   *
   * @param input Input value.
   */
  checkOptions(input: string) {
    if (input.length > 0) {
      this.bulkEdit.options = this.options[this.bulkEdit.field.key].filter(
        (value: string) => {
          return value.toLowerCase().includes(input.toLowerCase());
        }
      );
    } else {
      this.bulkEdit.options = [];
    }
  }

  /**
   * Detect Edition conflicts
   */
  detectEditConflicts() {
    switch (this.bulkEdit.field.key) {
      case 'start':
        this.bulkEdit.conflict = {
          field: this.columns.find((field) => field.key == 'end'),
          detail: `later than ${new Date(
            this.getConflictDate('end')
          ).toDateString()}`,
          indicator: (value: any, row: any) => {
            if (!row.end) return false;
            return new Date(value).valueOf() > new Date(row.end).valueOf();
          },
          updatedAt: new Date(),
        };
        this.bulkEdit.conflict.rows =
          this.bulkEdit.form &&
            this.bulkEdit.form.value[this.bulkEdit.field.key]
            ? this.getConflictRows()
            : [];
        break;
      case 'end':
        this.bulkEdit.conflict = {
          field: this.columns.find((field) => field.key == 'start'),
          detail: `earlier than ${new Date(
            this.getConflictDate('start')
          ).toDateString()}`,
          indicator: (value: any, row: any) => {
            if (!row.start) return false;
            return new Date(value).valueOf() < new Date(row.start).valueOf();
          },
          updatedAt: new Date(),
        };
        this.bulkEdit.conflict.rows =
          this.bulkEdit.form &&
            this.bulkEdit.form.value[this.bulkEdit.field.key]
            ? this.getConflictRows()
            : [];
        break;
      case 'category_ref':
        this.bulkEdit.conflict = {
          field: this.columns.find((field) => field.key == 'type_ref'),
          detail: 'flagged below',
          indicator: (value: any, row: any) => {
            if (!row.type) return false;
            return value.types && !value.types.includes(row.type);
          },
          updatedAt: new Date(),
        };
        this.bulkEdit.conflict.rows =
          this.bulkEdit.form &&
            this.bulkEdit.form.value[this.bulkEdit.field.key]
            ? this.getConflictRows()
            : [];
        this.bulkEdit.conflict.options = this.getConflictOptions();
        break;
      case 'type_ref':
        this.bulkEdit.conflict = {
          field: this.columns.find((field) => field.key == 'category_ref'),
          detail: 'flagged below',
          indicator: (value: any, row: any) => {
            return (
              !row.category_ref ||
              !row.category_ref.types ||
              !row.category_ref.types.includes(value.id)
            );
          },
          updatedAt: new Date(),
        };
        this.bulkEdit.conflict.rows =
          this.bulkEdit.form &&
            this.bulkEdit.form.value[this.bulkEdit.field.key]
            ? this.getConflictRows()
            : [];
        this.bulkEdit.conflict.options = this.getConflictOptions();
        break;
      default:
        this.bulkEdit.conflict = null;
    }
  }

  /**
   * Get conflict date value
   *
   * @param key
   */
  getConflictDate(key: string) {
    let allValues: any[] = [];

    this.bulkEdit.rows.forEach((row: any) => {
      if (row[key]) {
        let date = new Date(row[key]).valueOf();
        allValues.push(date);
      }
    });

    return ['start'].includes(key)
      ? Math.max(...allValues)
      : Math.min(...allValues);
  }

  /**
   * Retrieves rows with conflict.
   */
  getConflictRows() {
    let value = this.bulkEdit.form.value[this.bulkEdit.field.key];

    let conflictRows: any[] = [];
    this.bulkEdit.rows.forEach((row: any) => {
      if (this.bulkEdit.conflict.indicator(value, row)) {
        conflictRows.push(row);
      }
    });

    return conflictRows && conflictRows.length > 0 ? conflictRows : [];
  }

  /**
   * Retrieves options with conflict.
   */
  getConflictOptions() {
    let conflictOptions: any = {};

    this.options[this.bulkEdit.field.key].forEach((option: any) => {
      let conflictRows: any[] = [];
      this.bulkEdit.rows.forEach((row: any) => {
        if (this.bulkEdit.conflict.indicator(option, row)) {
          conflictRows.push(row.id);
        }
      });

      if (conflictRows.length > 0) {
        conflictOptions[option.id] = {
          name: option.name,
          rows: conflictRows,
        };
      }
    });

    return conflictOptions;
  }


  /**
   * Create edit form
   */
  createEditForm() {
    // Check for existing value
    let currentValue = this.bulkEdit.form
      ? this.bulkEdit.form.value[this.bulkEdit.field.key]
      : null;

    this.bulkEdit.form = this.fb.group({});

    // Add form control
    switch (this.bulkEdit.field.key) {
      case 'costs_ref':
        this.bulkEdit.group = [
          {
            key: 'lineItemType',
            name: 'LineItemType',
            controlType: 'inputText',
            value: 'Currency',
            hidden: true,
          },
          {
            key: 't',
            name: 'Resource',
            controlType: 'dropdown_ref',
            apiController: 'resources',
            value: { id: 1, name: 'Currency', _id: '617b62958e2f0e4a67f86c76' },
            hidden: true,
          },
          {
            key: 'id',
            name: 'Currency',
            controlType: 'dropdown_ref',
            apiController: 'currencies',
          },
          {
            key: 'c',
            name: 'Count',
            controlType: 'inputNumber-buttons',
          },
        ];

        this.bulkEdit.group.forEach((f: any) => {
          if (f.apiController) {
            this.getOptionsFromRef(f.key, f.apiController);
          }
        });

        this.bulkEdit.form.addControl(
          this.bulkEdit.field.key,
          this.fb.array([this.createGroup()], [Validators.required])
        );
        break;
      default:
        this.bulkEdit.form.addControl(
          this.bulkEdit.field.key,
          this.fb.control(currentValue, [Validators.required])
        );
    }
  }

  /**
   * Create form group
   */
  createGroup(): UntypedFormGroup {
    let newGroup: any = {};
    this.bulkEdit.group.forEach((f: any) => {
      newGroup[f.key] = f.value ? f.value : null;
    });

    return this.fb.group(newGroup);
  }

  /**
   * Add form group
   */
  addGroup() {
    (this.bulkEdit.form.get(this.bulkEdit.field.key) as UntypedFormArray).push(
      this.createGroup()
    );
    this.loggerService.log('Bulk Edit Form: ', this.bulkEdit.form);
  }

  /**
   * Removes form group.
   *
   * @param index Index of the form group to be removed.
   */
  removeGroup(index: number) {
    (this.bulkEdit.form.get(this.bulkEdit.field.key) as UntypedFormArray).removeAt(
      index
    );
  }

  /**
   * Sets if form array is valid
   */
  isFormArrayValid() {
    let nonValues = 0;

    if (this.bulkEdit.form && this.bulkEdit.form.value && this.bulkEdit.form.value[this.bulkEdit.field.key] && this.bulkEdit.form.value[this.bulkEdit.field.key].length > 0) {
      this.bulkEdit.form.value[this.bulkEdit.field.key].forEach((group: any) => {
        Object.values(group).forEach((value) => {
          if (!value) nonValues++;
        });
      });
    }

    return nonValues > 0 ? false : true;
  }

  /**
   * Retrieves Options from reference
   *
   * @param fieldName Name of the field
   * @param apiController apiController value
   * @param minimal Minimal value
   */
  async getOptionsFromRef(
    fieldName: any,
    apiController: string,
    minimal: boolean = false
  ) {
    const options = await this.dataService.getAllOfTypeAsync(apiController, {
      query: {},
      autopopulate: true,
      virtuals: true,
    });
    if (minimal) {
      let o: any[] = [];
      for (const option of options) {
        o.push({ id: option.id, name: option.name, _id: option._id });
      }
      this.options[fieldName] = o;
    } else {
      this.options[fieldName] = options;
    }
  }
  async getfullOptionsFromRef(
    fieldName: any,
    apiController: string,
    minimal: boolean = false
  ) {
    const options = await this.dataService.getAllOfTypeAsync(apiController, {
      query: {},
      autopopulate: true,
      virtuals: false,
    });
    if (minimal) {
      let o: any[] = [];
      for (const option of options) {
        o.push({ id: option.id, name: option.name, _id: option._id });
      }
      this.fullOptions[fieldName] = o;
    } else {
      this.fullOptions[fieldName] = options;
    }
  }

  /**
   * Go to prev step
   */
  goBack() {
    if (this.bulkEdit.stage == 'reviewAndSubmit') {
      this.onFieldSelectedForEdit();
    } else if (this.bulkEdit.stage == 'selectInputEditMode') {
      this.onFieldSelectedForEdit();
    } else if (this.bulkEdit.stage == 'editField') {
      this.onStartBulkEdit();
    }
  }

  goBackInput() {
    if (this.bulkEdit.stage == 'selectInputEditMode') {
      this.onFieldSelectedForEdit();
    } else if (this.bulkEdit.stage == 'editField') {
      this.onStartBulkEdit();
    }
  }

  goNextInput() {
    if (this.bulkEdit.stage == 'selectField') {
      this.onFieldSelectedForEdit();
    } else if (this.bulkEdit.stage == 'editField') {
      this.onFieldEdited();
    }
  }

  /**
   * Go to next step.
   */
  goNext() {
    if (this.bulkEdit.stage == 'selectField') {
      this.onFieldSelectedForEdit();
    } else if (this.bulkEdit.stage == 'editField') {
      this.onFieldEdited();
    }
  }
  goBackMulti() {
    /**
     * Go to previous step in the multiselect edit mode
     */

    switch (this.bulkMultiSelect.stage) {
      case 'selectEditMode':
        this.onStartBulkEdit();
        break;
      case 'selectInputEditMode':
        this.onStartBulkEdit();
        break;
      case 'reviewAndSubmitReplaced':
        this.onFieldMultiselectEdit();
        break;
      case 'editFieldReplace':
        this.onShowEditOptions();
        //this.selectEditOption(this.bulkMultiSelectAction.mode);
        break;
      case 'selectField':
        this.onShowEditOptions();
        break;
      case 'selectFindAndReplaceFields':
        this.showFindAndReplace = false;
        break;
      case 'editFieldFindAndReplace':
        this.onSelectFindAndReplace();
        break;
      case 'reviewAndSubmitFindAndReplace':
        this.onFieldSelectFindandReplace();
    }
  }

  goNextMulti() {
    /**
     * Go to next step in the multiselect edit mode
     */
    this.loggerService.log('this.bulkMultiSelect.stage: ', this.bulkMultiSelect.stage);
    switch (this.bulkMultiSelect.stage) {
      case 'selectFieldReplace':
        this.onFieldMultiselectEdit();
        break;
      case 'editFieldReplace':
        this.onMultipleFieldEdited();
        break;
      case 'editFieldFindAndReplace':
        this.onFindAndReplaceEdited();
        break;
    }
  }

  checkSkippedMulti(row: any, value: any) {
    /**
     * Checks if the multi select value exists, then compares it to the existing values
     * @param row Row Affected
     * @param value value of the value to add/replace/remove
     * returns boolean
     */

    return (
      row &&
      ((_.isArray(row) && row.some((e: any) => e.name === value)) ||
        row.name == value)
    );
  }

  /**
   * Show conflicts dialog
   */
  onShowConflicts() {
    this.loggerService.log(this.bulkEdit.conflict);
    this.showBulkEditConflicts = true;
  }

  /**
   * Inverses the current lock state of the row
   * @param row that is affected by the lock feature
   */
  async onRowLock(row: any) {
    this.loggerService.log('Row Locked', row);
    if (row) {
      row.isLocked = !row.isLocked;
    }
    let payload = { isLocked: row.isLocked };

    await this.dataService.updateRecord(row.id, this.tableType, payload);
  }
  /**
   * Handle when row editing is enabled.
   *
   * @param row Row affected.
   *
   * isTableEditLocked is a new variable that helps disabling table edit when row editing is enabled.
   */
  onRowEditInit(row: any) {
    this.loggerService.log('OnRowEditInit Triggered');
    let typeRefBackup: any = _.cloneDeep(this.options['type_ref']);
    if (row) {
      this.copyOriginalValue(row);
      this.isTableEditLocked = true;
      // if there is a start key
      if (row.start) {
        row.start = new Date(row.start);
      }
      this.loggerService.log('after row start', row);
      // if there is end key available set the value to new Date onject
      if (row.end) {
        row.end = new Date(row.end);
      }

      if (row['category_ref']) {
        this.setOptionsFromNestedField(
          row,
          'category_ref',
          'types_ref',
          'type_ref',
          row['category_ref']._id,
          false
        );
      }
    }
    if (this.options['type_ref'] == undefined) {
      this.options['type_ref'] = typeRefBackup;
    }
    this.loggerService.log('Disable Table Edit');
  }



  /**
   * Handle row edit save action
   *
   * @param row Row to be saved.
   * this.isTableEditLocked is set to false to reenable the table edit button
   */
  async onRowEditSave(row: any, isFullEditMode: boolean = false) {
    this.showSpinnerProgress = true;
    this.spinnerService.loadSpinner();
    let responseRow = null;
    //
    console.log('clone:', this.clonedRows[row.id]);
    console.log('new:', row);
    //
    let updatedAttributes = await this.dynamicTableService.extractUpdatedAttributes(this.clonedRows[row.id], row)
    // userData from authService
    updatedAttributes.userData = {
      name: this.user.name,
      email: this.user.email,
      id: this.user.id,
    };

    // remove build data from update submit.
    // it is in the wrong format and causes save conflicts.
    delete updatedAttributes.buildData;
    delete updatedAttributes.assetBuildOutput;
    delete updatedAttributes.imageBuildOutput;

    updatedAttributes.isTableEdit = true;

    this.loggerService.log('updatedAttributes: ', updatedAttributes);
    let automations = [];

    try {
      let response = await this.dataService.updateRecord(
        updatedAttributes.id,
        this.tableType,
        updatedAttributes
      );

      if (['items', 'challenges'].includes(this.tableType)) {
        automations = _.cloneDeep(response.automations);
        response = _.cloneDeep(response._doc);
      }
      if (response && response.id) {
        if (!isFullEditMode) {
          this.messageService.add({
            severity: 'success',
            summary: 'Update Successful',
            detail: `${row.name}" was successfully updated`,
            life: this.toasterLife
          });
          window.scrollTo(0, 0);
        }
        this.handleRecordAutomations(automations);
        this.editModeSuccessCount += 1;
        responseRow = response;
      }
      else {
        this.editModeFailedCount += 1;
      }
    }
    catch (errorResponse: any) {
      if (errorResponse.status == 400) {
        if (errorResponse.error && errorResponse.error.error) {
          // Duplicate Key Error
          if (errorResponse.error.error.codeName && errorResponse.error.error.codeName == "DuplicateKey") {
            // Switch statement to handle different error messages for different tables
            switch (this.tableType) {
              case 'items':
                this.messageService.add(
                  {
                    sticky: true,
                    severity: 'error',
                    summary: 'Submit Error',
                    detail: `Filename must be unique. Item Id: ${row.id}`,
                  });
                break;

              default:
                this.messageService.add({
                  sticky: true,
                  severity: 'error',
                  summary: 'Submit Error',
                  detail: `There was an error submitting ${this.tableType} Id: ${row.id}.`,
                });
                break;
            }
          }
        }
        else {
          this.messageService.add(
            {
              sticky: true,
              severity: 'error',
              summary: 'Submit Error',
              detail: `There was an error submitting ${this.tableType} Id: ${row.id}.`,
            });
        }
      }
      else {
        this.messageService.add(
          {
            sticky: true,
            severity: 'error',
            summary: 'Server Error',
            detail: `There was an error submitting ${this.tableType} Id: ${row.id}.`,
          });
      }

      this.editModeFailedCount += 1;
    }
    //
    let index = this.rows.findIndex((x: any) => x.id == row.id);
    let originalRowsIndex = this.originalRows.findIndex((x: any) => x.id == row.id);
    // updating updated row with response data.
    this.rows[index] = responseRow ? JSON.parse(JSON.stringify(responseRow)) : JSON.parse(JSON.stringify(this.originalRows[originalRowsIndex]));
    this.spinnerService.endSpinner();
  }

  /**
   * Display automations in a toast message.
   *
   * @param automations List of automations made.
   */
  handleRecordAutomations(automations: any) {
    if (automations && automations.length > 0) {
      automations.forEach((automation: any) => {
        this.messageService.add(
          {
            severity: 'info',
            summary: 'Automation Executed',
            detail: `${automation}`,
            life: this.toasterLife
          });
      });
    }
  }

  /**
   * Handle row edit cancel action
   *
   * @param row Table row
   * @param index Index of the table row
   *
   * this.isTableEditLocked is set to false to reenable the table edit button
   */
  onRowEditCancelValidation(row: any) {
    this.isTableEditLocked = false;
    // return to original value
    this.revertToOriginalValue(row);
  }

  /**
   * Handle row edit cancel action
   *
   * @param row Table row
   * @param index Index of the table row
   *
   * this.isTableEditLocked is set to false to reenable the table edit button
   */
  async onRowEditCancel(row: any, index: number) {
    let updatedRows = await this.findUpdatedRows();
    if (updatedRows.includes(row.id)) {
      await this.confirmationService.confirm(
        {
          key: `${row.id}`,
          message: `
          ${this.tableType} ID:${row.id} has unsaved changes, do you wish to save them?
          `,
          accept: async () => {
            this.onSubmitEditMode(row)
          },
          reject: () => {

            console.log(updatedRows);
            this.isTableEditLocked = false;
            // return to original value
            this.revertToOriginalValue(row, index);

            this.messageService.add({
              severity: 'warn',
              summary: `${this.tableType} ID:${row.id} changes cancelled.`,
              life: this.toasterLife
            });
          }
        }
      );
    } else {
      this.isTableEditLocked = false;
      // return to original value
      this.revertToOriginalValue(row, index);
    }

  }

  /**
   * Find updated rows
  */
  async findUpdatedRows() {
    let updatedRows: any[] = [];
    this.inputField._results.forEach((o: any) => {
      if (
        ['nestedGroup', 'formArray', 'lineItem', 'rewards_ref'].includes(
          o.field.controlType
        )
      ) {
        o.nestedInputField._results.forEach((oo: any) => {
          if (!_.isEqual(oo.value, oo.initValue)) {

            if (!updatedRows.includes(o.entity.id)) {
              updatedRows.push(o.entity.id);
            }
          }
        });
        if (!_.isEqual(o.value, o.nestedValue)) {

          if (!updatedRows.includes(o.entity.id)) {
            updatedRows.push(o.entity.id);
          }
        }
      } else {
        if (!_.isEqual(o.value, o.initValue)) {
          o.entity[o.field.key] = o.value;

          if (!updatedRows.includes(o.entity.id)) {
            updatedRows.push(o.entity.id);
          }
        } else if (
          o.nestedValueCopy &&
          o.nestedValueCopy.length > 0 &&
          !_.isEqual(o.nestedValueCopy, o.initValue)
        ) {
          o.entity[o.field.key] = o.nestedValueCopy;

          if (!updatedRows.includes(o.entity.id)) {
            updatedRows.push(o.entity.id);
          }
        } else {
        }
      }
    });
    return updatedRows;
  }

  /**
   * sets all of the selected fields to null to remove the value
   */
  clearValues() {
    this.bulkEdit.form.patchValue({ [this.bulkEdit.field.key]: null });
    this.clearConfirmation = false;
    this.bulkEdit.stage = 'reviewAndSubmit';

    this.messageService.add({
      severity: 'success',
      summary: `Clear Value`,
      detail: 'Field value has been cleared.',
      life: this.toasterLife
    });
  }
  /**
   * Parse value for edit review
   *
   * @param data Data to be parsed
   * @param field Field to be editted
   */
  parseValueForEditReview(data: any, field: any) {
    if (data == 'null') return '';
    if (!data) return '';
    if (!data && !['toggle'].includes(field.controlType)) return '';

    let output;
    switch (field.controlType) {
      case 'inputText':
      case 'inputTextarea':
      case 'richTextArea':
      case 'dropdown':
      case 'date':
      case 'calendar-start':
      case 'calendar-end':
        output = data;
        break;
      case 'inputNumber-buttons':
        output = data.toString();
        break;
      case 'dropdown_ref':
      case 'autoComplete_ref':
        output = data.name;
        break;
      case 'multiSelect':
        output = data.join(', ');
        break;
      case 'multiSelect_ref':
        let values: any[] = [];
        data.forEach((o: any) => {
          values.push(o.name);
        });
        output = values.join(', ');
        break;
      case 'nestedGroup':
      case 'lineItem':
      case 'formArray':
        let groups: any[] = [];
        data.forEach((formGroup: any) => {
          let values: any[] = [];
          this.bulkEdit.group.forEach((field: any) => {
            values.push(
              this.parseValueForEditReview(formGroup[field.key], field)
            );
          });
          groups.push(values.join(', '));
        });
        output = groups.join(`;
        `);
        break;
      case 'toggle':
        data == true ? (output = 'Yes') : (output = 'No');
        break;
    }
    return output;
  }

  async newRow() {
    this.loggerService.log('val check', this.addRowValue);
    const sourcingGroup = await this.dataService.getDocumentAsync(
      this.sourcingKey,
      {
        query: { _id: this.sourceGroupID },
        autopopulate: true,
        virtuals: true,
      }
    );
    this.loggerService.log('Sourcing Group: ', sourcingGroup);
    const newRow = this.newRowObject ? this.newRowObject :
      {
        name: '',
        batch_ref: sourcingGroup.batch_ref ? sourcingGroup.batch_ref._id : null,
        promoted: false,
        vendor_ref:
          sourcingGroup.batch_ref && sourcingGroup.batch_ref.vendor_ref
            ? sourcingGroup.batch_ref.vendor_ref._id
            : null,
        assetType:
          sourcingGroup.assetType
            ? sourcingGroup.assetType
            : null,
        year:
          sourcingGroup.year
            ? sourcingGroup.year
            : null,
        vendorStatus:
          'Assigned',
        itemStatus:
          sourcingGroup.contentStatus
            ? sourcingGroup.contentStatus
            : null,
      };

    newRow.sourceGroup_ref = { _id: this.sourceGroupID };
    // newRow.fileName = this.tableType == 'challenges-sourcing' ? 'xx_challengeid_' + Math.floor(Math.random() * 1000) : null;
    newRow.fileName = this.tableType == 'challenges-sourcing' ? 'xx_challengeid_' : null;
    newRow.year = 2025;

    for (let i = 1; i <= this.addRowValue; i++) {
      await this.dataService.addNewRecord(this.tableType, newRow);
    }

    await this.tableService
      .getSourceRows(this.tableType, this.sourceGroupID)
      .then(async (data: any) => {
        this.rows = data;
        // Update filteredRows to match the refreshed rows
        this.filteredRows = [...this.rows];

        // If there are any filter settings, re-apply them
        if (this.tableState.filters && this.tableState.filters.length > 0) {
          this.filteredRows = this.applyAllFiltersToData(this.filteredRows);
        }

        // Update selected rows to ensure new rows are properly registered
        this.setSelectedRows();
      });

    this.messageService.add({
      severity: 'success',
      summary: `New Row Added`,
      detail: 'Row added to table successfully.',
      life: this.toasterLife
    });

    // Trigger change detection to update the UI
    this.cd.detectChanges();
  }

  async deleteRow(row: any) {
    this.loggerService.log('row deleted');
    this.loggerService.log('checking row id', row);

    this.dataService.deleteRecord(row.id, this.tableType);

    this.messageService.add({
      severity: 'warn',
      summary: `Row Deleted`,
      detail: 'Row deleted successfully',
      life: this.toasterLife
    });
  }

  ReplaceValue(original: string, substring: string, replacement: string): string {
    return original.replace(new RegExp(substring, 'g'), replacement);
  }

  async onSubmitBulkAppendNote() {
    this.loading = true;
    this.bulkEditProgress = 0;
    this.loggerService.log('bulk edit', this.bulkEdit);
    let count = 0;

    let { conflict, form, rows, successCount, failedPayloads } = this.bulkEdit;
    successCount = 0;
    failedPayloads = [];

    // remove conflicts from submission
    if (conflict) {
      conflict.rows.forEach((conflictRow: any) => {
        let conflictIndex = rows.findIndex(
          (row: any) => row.id == conflictRow.id
        );
        rows.splice(conflictIndex, 1);
      });
    }

    form.value.userData = {
      name: this.user.name,
      email: this.user.email,
      id: this.user.id,
    };

    // loop bulkEdit rows
    await new Promise(async (resolve, reject) => {
      this.loggerService.log('inside promise');
      for (const row of rows) {
        count += 1;
        this.bulkEditProgress = Number(
          ((count / rows.length) * 100).toFixed(2)
        );
        await new Promise(async (resolve, reject) => {
          // Create a deep copy of form.value
          let formDataCopy = JSON.parse(JSON.stringify(form.value));

          // set dates as date objects
          ['start', 'end', 'date'].forEach((key) => {
            if (formDataCopy[key]) {
              formDataCopy[key] = new Date(formDataCopy[key]);
            }
          });

          // get the current value from the row
          let currentValue = row[this.bulkEdit.field.key] || ''; // default to an empty string if null or undefined

          // append the new data to the existing data
          let appendedValue = currentValue + formDataCopy[this.bulkEdit.field.key];

          // set the appended value back to the form copy so it gets updated
          formDataCopy[this.bulkEdit.field.key] = appendedValue;

          // deleting large fields in update to prevent http request limit overflow
          formDataCopy.batch_ref && formDataCopy.batch_ref.items_ref
            ? delete formDataCopy.batch_ref.items_ref
            : null;

          // submit using the formDataCopy
          this.formService
            .submitForm(formDataCopy, `${this.tableType}/update/${row.id}`, true)
            .subscribe(
              (val) => {
                successCount++;
              },
              (response) => {
                this.loggerService.error('POST call in error', response);
                this.loggerService.error(
                  'Failed Payloads: ',
                  failedPayloads.length
                );
                failedPayloads.push({
                  rowId: row.id,
                  value: formDataCopy[this.bulkEdit.field.key],
                });
              },
              () => {
                this.loggerService.log('The POST observable is now completed.');
                resolve('done');
              }
            );
        });
      }
      resolve('done with loop');
    });

    let messageAfterLoop = setInterval(() => {
      const completedSum = failedPayloads.length + successCount;

      if (completedSum == rows.length) {
        this.messageService.add(
          failedPayloads.length < 1
            ? {
              severity: 'success',
              summary: 'Updates Successful',
              detail: !conflict
                ? `${successCount} rows successfully updated.`
                : `${conflict.rows.length} conflict(s) removed and ${successCount} rows successfully updated.`,
              life: this.toasterLife
            }
            : {
              sticky: true,
              severity: 'error',
              summary: 'Submit Error',
              detail: !conflict
                ? `${successCount} rows successfully updated, and ${failedPayloads.length} errors.`
                : `${conflict.rows.length} conflicts removed, ${successCount} rows successfully updated, and ${failedPayloads.length} errors.`,
            }
        );

        clearInterval(messageAfterLoop);
      }
    }, 500);

    await this.getRowData();
    this.loading = false;
    this.showBulkEdit = false;
    this.showBulkEditForInput = false;
    this.showBulkEditForNoteReview = false;
  }

  /**
   * Handle bulk edit submit action
   */
  async onSubmitBulkEdit() {
    this.loading = true;
    this.bulkEditProgress = 0;
    this.loggerService.log('bulk edit', this.bulkEdit);
    let count = 0;

    let { conflict, form, rows, successCount, failedPayloads } = this.bulkEdit;
    successCount = 0;
    failedPayloads = [];

    // remove conflicts from submission
    if (conflict) {
      conflict.rows.forEach((conflictRow: any) => {
        let conflictIndex = rows.findIndex(
          (row: any) => row.id == conflictRow.id
        );
        rows.splice(conflictIndex, 1);
      });
    }

    form.value.userData = {
      name: this.user.name,
      email: this.user.email,
      id: this.user.id,
    };

    // loop bulkEdit rows
    await new Promise(async (resolve, reject) => {
      this.loggerService.log('inside promise');
      for (const row of rows) {
        count += 1;
        this.bulkEditProgress = Number(
          ((count / rows.length) * 100).toFixed(2)
        );
        await new Promise(async (resolve, reject) => {
          // set dates as date objects
          ['start', 'end', 'date'].forEach((key) => {
            if (form.value[key]) {
              form.value[key] = new Date(form.value[key]);
            }
          });

          // deleting large fields in update to prevent http request limit overflow
          form.value.batch_ref && form.value.batch_ref.items_ref
            ? delete form.value.batch_ref.items_ref
            : null;
          form.value[this.bulkEdit.field.key] =
            form.value[this.bulkEdit.field.key] == 'null'
              ? null
              : form.value[this.bulkEdit.field.key];
          // submit
          this.formService
            .submitForm(form.value, `${this.tableType}/update/${row.id}`, true)
            .subscribe(
              (val) => {
                successCount++;
              },
              (response) => {
                this.loggerService.error('POST call in error', response);
                this.loggerService.error(
                  'Failed Payloads: ',
                  failedPayloads.length
                );
                failedPayloads.push({
                  rowId: row.id,
                  value: form.value[this.bulkEdit.field.key],
                });
              },
              () => {
                this.loggerService.log('The POST observable is now completed.');
                resolve('done');
              }
            );
        });
      }
      resolve('done with loop');
    });

    let messageAfterLoop = setInterval(() => {
      const completedSum = failedPayloads.length + successCount;

      if (completedSum == rows.length) {
        this.messageService.add(
          failedPayloads.length < 1
            ? {
              severity: 'success',
              summary: 'Updates Successful',
              detail: !conflict
                ? `${successCount} rows successfully updated.`
                : `${conflict.rows.length} conflict(s) removed and ${successCount} rows successfully updated.`,
              life: this.toasterLife
            }
            : {
              sticky: true,
              severity: 'error',
              summary: 'Submit Error',
              detail: !conflict
                ? `${successCount} rows successfully updated, and ${failedPayloads.length} errors.`
                : `${conflict.rows.length} conflicts removed, ${successCount} rows successfully updated, and ${failedPayloads.length} errors.`,
            }
        );

        clearInterval(messageAfterLoop);
      }
    }, 500);

    await this.getRowData();
    this.loading = false;
    this.showBulkEdit = false;
    this.showBulkEditForInput = false;
    this.showBulkEditForNoteReview = false;
  }
  /** THIS function does ALL the validations needed based on:
   *  - table type
   *  - fields changed
   *  - required fields
   * Validates the rows for submissions
   * @param row row to be checked
   * @param id id of the row
   * @param array array of errors
   * @returns array of errors or empty array
   */
  async validateSubmission(row: any, id: any, array: any) {
    let errorArray: Array<any> = [];
    if (this.requiredFields) {
      array.push(id);
      for (let requiredField of this.requiredFields) {

        switch (requiredField.validation) {
          case requiredFieldValidations.notEmpty:
            if (!row[requiredField.field]) {
              errorArray.push(requiredField.field)

            }
            break
          case requiredFieldValidations.nestedNotEmptyLoading:
            row.images.forEach((image: any, index: any) => {
              if (image.t != 'null' && (!image.n || !image.p)) {
                errorArray.push('Path/Name of Image ' + (index + 1))
              }
            })
        }
      }
      errorArray.length > 0 ? array.push(errorArray) : array = []
      return array;
    } else return []
  }

  /** THIS function does ALL the validations needed based on:
   *  - table type
   *  - fields changed
   *  - required fields
   * Validates the row for submission and returns errors if found.
   * @param row row to be evaluated
   * @returns array of errors or empty array
   */
  async validateRowSubmission(row: any) {
    let errorArray: Array<any> = [];
    if (this.requiredFields) {
      for (let requiredField of this.requiredFields) {

        switch (requiredField.validation) {
          case requiredFieldValidations.notEmpty:
            if (!row[requiredField.field]) {
              errorArray.push(`Field <strong>${requiredField.field}</strong> is required.`);
            }
            break
          case requiredFieldValidations.nestedNotEmptyLoading:
            row.images.forEach((image: any, index: any) => {
              if (image.t != 'null' && (!image.n || !image.p)) {
                errorArray.push(`Path/Name of Image no. ${index + 1} is required`);
              }
            })
        }
      }
    }
    if (row.start && row.end) {
      // validates that row.start < row.end
      let areDateValuesValid = await this.validateDate(row);
      if (!areDateValuesValid) {
        errorArray.push('Start date must be before End date.');
      }
      // validates that changed dates are not soon to go live or have passed
      // returns an array with: [valid:true/false, errorMessage: string]
      let areDateChangesValid = await this.validationsService.validateDateChanges(this.clonedRows[row.id], row, this.tableType);
      if (_.isArray(areDateChangesValid) && !areDateChangesValid[0]) {
        errorArray.push(areDateChangesValid[1] ? areDateChangesValid[1] : 'Invalid Date values');
      }
    }
    // ------ !! ------ ------ !! ------ ------ !! ------
    //       ADD HERE ALL table specific validations
    // ------ !! ------ ------ !! ------ ------ !! ------
    switch (this.tableType) {
      case 'items':
        let isCostValid = this.validationsService.validateCost(row);
        if (!isCostValid) {
          errorArray.push('Invalid Cost value');
        }
        // environment variables preReqs validation
        if ((this.clonedRows[row.id].env || row.env) && (this.clonedRows[row.id] !== row.env)) {
          let isEnvValid = await this.validationsService.validateEnvPreReqs('items', row);
          console.log('isEnvValid: ', isEnvValid);
          if (!isEnvValid[0]) {
            Object.keys(isEnvValid[1]).forEach(function (key, index) {
              if (!isEnvValid[1][key].status) {
                errorArray.push(`ENV value <strong>${key}</strong> does not meet pre-requisites: <br> ${isEnvValid[1][key].rules.join(' <br> ')}`);
              }
            });
          }
        }

        break;
      case 'challenges':
        let isValidChallenge = await this.validationsService.validateChallenge(row);
        if (_.isArray(isValidChallenge) && !isValidChallenge[0]) {
          errorArray.push(isValidChallenge[1] ? isValidChallenge[1] : 'Invalid Challenge Type and restriction values');
        }
        // environment variables preReqs validation
        if ((this.clonedRows[row.id].env || row.env) && (this.clonedRows[row.id] !== row.env)) {
          let isEnvValid = await this.validationsService.validateEnvPreReqs('challenges', row);
          console.log('isEnvValid: ', isEnvValid);
          if (!isEnvValid[0]) {
            Object.keys(isEnvValid[1]).forEach(function (key, index) {
              if (!isEnvValid[1][key].status) {
                errorArray.push(`ENV value <strong>${key}</strong> does not meet pre-requisites: <br> ${isEnvValid[1][key].rules.join(' <br> ')}`);
              }
            });
          }
        }
        break;
      default:
        break;
    }
    return errorArray;
  }

  /** Function for finding rows with updated values in editMode, and looping/submitting changes.
   * this.isTableEditLocked locks table edit mode when row editing is active
   */
  async onSubmitEditMode(edittedRow: any) {
    console.log('onSubmitEditMode: ', edittedRow);
    this.showSpinnerProgress = false;
    this.tableSpinnerIcon = "timer";
    this.isTableEditLocked = false;
    let updatedRows: any[] = [];
    this.editModeSuccessCount = 0;
    this.editModeFailedCount = 0;

    this.inputField._results.forEach((o: any) => {
      if (
        ['nestedGroup', 'formArray', 'lineItem', 'rewards_ref'].includes(
          o.field.controlType
        )
      ) {
        o.nestedInputField._results.forEach((oo: any) => {
          if (!_.isEqual(oo.value, oo.initValue)) {
            oo.valueChange.emit(oo.value);

            if (!updatedRows.includes(o.entity.id)) {
              updatedRows.push(o.entity.id);
            }
          }
        });
        if (!_.isEqual(o.value, o.nestedValue)) {
          o.valueChange.emit(o.nestedValue);

          if (!updatedRows.includes(o.entity.id)) {
            updatedRows.push(o.entity.id);
          }
        }
      } else {
        if (!_.isEqual(o.value, o.initValue)) {
          o.valueChange.emit(o.value);
          o.entity[o.field.key] = o.value;

          if (!updatedRows.includes(o.entity.id)) {
            updatedRows.push(o.entity.id);
          }
        } else if (
          o.nestedValueCopy &&
          o.nestedValueCopy.length > 0 &&
          !_.isEqual(o.nestedValueCopy, o.initValue)
        ) {
          o.valueChange.emit(o.nestedValueCopy);
          o.entity[o.field.key] = o.nestedValueCopy;

          if (!updatedRows.includes(o.entity.id)) {
            updatedRows.push(o.entity.id);
          }
        } else {
          o.valueChange.emit(o.initValue);
        }
      }
    });

    if (edittedRow) {
      updatedRows = [edittedRow.id];
    }

    let errorFields = [];
    this.tableConfig.isEditMode = false;

    console.log('updatedRows: ', updatedRows);

    for (let id of updatedRows) {
      let row = this.rows.find((row) => row.id == id);
      let index = this.rows.findIndex((x: any) => x.id == row.id);

      let errorArray: Array<any> = [];

      let validatedErrorArray = await this.validateSubmission(row, id, errorArray);
      console.log('validatedErrorArray: ', validatedErrorArray);
      if (validatedErrorArray && validatedErrorArray.length > 0) {
        errorFields.push(validatedErrorArray);
      }

      //  VALIDATIONS BEFORE SUBMISSION
      let rowErrors = await this.validateRowSubmission(row);
      console.log(rowErrors);
      if (rowErrors.length > 0) {
        this.invalidRowValidation(row, rowErrors)
      } else {
        await this.onRowEditSave(row, true);
      }
    }

    if (this.editModeSuccessCount > 0) {
      this.messageService.add({
        severity: 'success',
        summary: `${this.editModeSuccessCount} rows updated`,
        detail: `${this.editModeSuccessCount} records updated.`,
        life: this.toasterLife
      });
    }

    if (this.editModeFailedCount > 0) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: `${this.editModeFailedCount} rows failed`,
        detail: `${this.editModeFailedCount} records not updated.`,
      });
    }
    this.showSpinnerProgress = false;
  }

  /**
   * toggle full edit mode
   */
  async toggleFullEditMode(active: boolean) {
    if (active) {
      // Reset sorting when entering edit mode
      this.urlQueryParams.sortBy = '';
      this.urlQueryParams.sortOrder = 0;

      for (const row of this.rows) {
        this.copyOriginalValue(row);
      }
    }
    let updatedRows = await this.findUpdatedRows();
    if ((!active) && (updatedRows.length > 0)) {
      if (this.tableType != 'items-sourcing') {
        this.tableConfig.isEditMode = true;
        await this.confirmationService.confirm(
          {
            key: `${updatedRows[0]}`,
            message: `
              ${this.tableName} table records: ${updatedRows.toString()} have unsaved changes. <br>
              Do you want to discard the changes?
            `,
            accept: async () => {
              updatedRows.forEach(recordId => {
                let index = this.rows.findIndex((x: any) => x.id == recordId);
                // return to original value
                this.revertToOriginalValue(this.rows[index], index);
              });
              this.tableConfig.isEditMode = false;
              this.messageService.add({
                severity: 'info',
                summary: `Changes Discarded`,
                detail: `Discarding changes`,
                life: this.toasterLife
              });
            },
            reject: () => {
              this.tableConfig.isEditMode = true;
            }
          }
        );
      }
    } else {
      this.tableConfig.isEditMode = active;
    }
  }

  /**
   * function to handle full edit mode toggler
   * used to validate for unsaved changes
  */
  handleFullEditToggleChange(event: any) {
    this.toggleFullEditMode(event.checked);
  }

  async validateDate(row: any) {
    if (row.end > row.start) {
      return true;
    } else {
      return false;
    }
  }


  /**
   * confirm Daily challenge with restrictions
   *
   */
  async validateDailyWRes(row: any) {
    await this.confirmationService.confirm(
      {
        key: `${row.id}`,
        message: `
        Challenge ID:${row.id} type is "Daily", and the challenge contains <strong>${row.restrictions_ref.length}</strong> restrictions. <br>
        Do you wish to continue submitting the form?
        `,
        accept: async () => {
          this.messageService.add({
            severity: 'success',
            summary: `Saving challenge ${row.id} Form`,
            detail: `Saving changes... please wait`,
            life: this.toasterLife
          });
          await this.onRowEditSave(row, true);
        },
        reject: () => {
          this.onRowEditCancelValidation(row);
          this.messageService.add({
            severity: 'warn',
            summary: 'Submit Cancelled',
            detail: `changes have not been saved`,
            life: this.toasterLife
          });
        }
      }
    );
  }

  /**
   * confirm invalid Start/End Date force update
   *
   */
  async invalidRowValidation(row: any, errors: any) {
    let flatErrors = [].concat(...errors);
    var listHtml: string = ``;
    if (_.isArray(flatErrors)) {
      listHtml = `<ul>`;
      flatErrors.forEach(message => {
        listHtml += `<li>${message}</li>`;
      });
      listHtml += `</ul>`;
    }
    await this.confirmationService.confirm(
      {
        key: `${row.id}`,
        message: `
        The following validations for ${this.tableType} ID: ${row.id} have been triggered:
        <br>
        ${listHtml}
        <br>
        <p>Do you wish to submit these changes for row ${row.id}?</p>
        `,
        acceptLabel: 'Save Changes',
        rejectLabel: 'Cancel',
        acceptIcon: 'pi pi-save',
        rejectIcon: 'pi pi-undo',
        acceptButtonStyleClass: 'yellow-status',
        rejectButtonStyleClass: 'green-status',
        accept: async () => {
          await this.onRowEditSave(row, true);
          this.messageService.add({
            severity: 'success',
            summary: `Row ID:${row.id} update confirmed`,
            detail: `Saving changes...`,
            life: this.toasterLife
          });
        },
        reject: () => {
          this.onRowEditCancelValidation(row);
          this.messageService.add({
            severity: 'warn',
            summary: `Row ID:${row.id} update Cancelled`,
            detail: `Skipped row update`,
            life: this.toasterLife
          });
        }
      }
    );
  }

  /**
   * confirm invalid Start/End Date force update
   *
   */
  async confirmInvalidDateUpdate(row: any, response: any) {
    var listHtml: string = ``;
    if (_.isArray(response[1])) {
      listHtml = `<ul>`;
      response[1].forEach(message => {
        listHtml += `<li>${message}</li>`;
      });
      listHtml += `</ul>`;
    }
    await this.confirmationService.confirm(
      {
        key: `${row.id}`,
        message: `
        ${this.tableType} record ID:${row.id} date validation:
        <br>
        ${listHtml}
        <br>
        <p>Do you wish to submit changes for row ${row.id}?</p>
        `,
        accept: async () => {
          this.messageService.add({
            severity: 'success',
            summary: `Row ID:${row.id} update confirmed`,
            detail: `Saving changes...`,
            life: this.toasterLife
          });
          await this.onRowEditSave(row, true);
        },
        reject: () => {
          this.onRowEditCancelValidation(row);
          this.messageService.add({
            severity: 'warn',
            summary: `Row ID:${row.id} update Cancelled`,
            detail: `Skipped row update`,
            life: this.toasterLife
          });
        }
      }
    );
  }

  /**
   * confirm invalid Start/End Date force update
   *
   */
  async confirmInvalidEnvPreReqs(row: any, rules: any) {
    var listHtml: string = ``;
    if (_.isArray(rules[1])) {
      listHtml = `<ul>`;
      rules[1].forEach(message => {
        listHtml += `<li>${message}</li>`;
      });
      listHtml += `</ul>`;
    }
    await this.confirmationService.confirm(
      {
        key: `${row.id}`,
        message: `
        ${this.tableType} record ID:${row.id} date validation:
        <br>
        ${listHtml}
        <br>
        <p>Do you wish to submit changes for row ${row.id}?</p>
        `,
        accept: async () => {
          this.messageService.add({
            severity: 'success',
            summary: `Row ID:${row.id} update confirmed`,
            detail: `Saving changes...`,
            life: this.toasterLife
          });
          await this.onRowEditSave(row, true);
        },
        reject: () => {
          this.onRowEditCancelValidation(row);
          this.messageService.add({
            severity: 'warn',
            summary: `Row ID:${row.id} update Cancelled`,
            detail: `Skipped row update`,
            life: this.toasterLife
          });
        }
      }
    );
  }

  /**
   * Bulk Build Enities
   */
  async buildEntities(buildRows: any[], newHash: boolean = false) {
    this.disableBuildButton = true;
    this.bulkBuild.successCount = 0;
    this.bulkBuild.failedPayloads = [];
    this.showBulkBuild = false;
    this.showBuildProgress = true;

    // Handle build path for Dynamic Columns table.
    if (this.tableConfig.useDynamicTableColumns) {
      let updateColumns = false;
      switch (this.buildType) {
        case BuildType.Items:
          // if(!this.tableState.columnSelection.includes('prefab_ref'))
          // {
          //   this.tableState.columnSelection.push('prefab_ref');
          //   updateColumns = true;
          // }
          break;
        case BuildType.Levels:
        // if(!this.tableState.columnSelection.includes('scene_ref'))
        // {
        //   this.tableState.columnSelection.push('scene_ref');
        //   updateColumns = true;
        // }
        // break;
        case BuildType.Images:
          if (this.assetType == AssetTypes.Item) {
            // if(!this.tableState.columnSelection.includes('thumbnail_ref'))
            // {
            //   this.tableState.columnSelection.push('thumbnail_ref');
            //   updateColumns = true;
            // }
          }
          else if (this.assetType == AssetTypes.Challenge) {
            // if(!this.tableState.columnSelection.includes('image_ref'))
            // {
            //   this.tableState.columnSelection.push('image_ref');
            //   updateColumns = true;
            // }
          }
          else {
            if (!this.tableState.columnSelection.includes('path')) {
              this.tableState.columnSelection.push('path');
              updateColumns = true;
            }
          }
          break

        default:
          break;
      }

      if (updateColumns) {
        await this.onSelectColumn();
      }
    }

    await this.prepareBuildAndSendToQueue(buildRows, newHash);

    if (this.bulkBuild.failedPayloads.length == 0) {
      this.messageService.add({
        severity: 'success',
        summary: 'All Builds Queued',
        detail: `${this.bulkBuild.successCount} items sent to build queue.`,
        life: this.toasterLife
      });
    } else {
      this.messageService.add({
        sticky: true,
        severity: 'warn',
        summary: 'Error Queuing Builds',
        detail: `${this.bulkBuild.successCount} items sent to build queue & ${this.bulkBuild.failedPayloads.length} errors.`,
      });
    }
    await this.getRowData();
    // Reset Build Type value
    if (this.assetType == AssetTypes.Item) {
      this.buildType = BuildType.Items;
    }
    else if (this.assetType == AssetTypes.Challenge) {
      this.buildType = BuildType.Levels;
    }
    this.disableBuildButton = false;
  }

  /**
   * Prepare Build and Send to Queue
   *
   * @param builds List of builds to send to queue.
   */
  async prepareBuildAndSendToQueue(builds: any[], newHash: boolean = false) {
    let record;
    let parentRecord = null;
    let bulkBuildId = this.utilitiesService.createGuid();
    this.bulkBuildProgress = 0;
    let count = 0;
    let userPayload: any = {};
    userPayload.email = this.user.email;
    userPayload.name = this.user.name;
    userPayload.id = this.user.id;

    await this.dataService.bulkNotification(this.user.email, bulkBuildId);

    for (const entity of builds) {
      let buildType = _.cloneDeep(this.buildType);
      let path: string = '';

      switch (this.buildType) {
        case BuildType.Items:
          if (entity.prefab_ref && entity.prefab_ref.path) {
            path = entity.prefab_ref.path;
            record = entity.prefab_ref;
            parentRecord = entity;
          }
          break;
        case BuildType.Levels:
          if (entity.scene_ref && entity.scene_ref.path) {
            path = entity.scene_ref.path;
            record = entity.scene_ref;
            parentRecord = entity;
          }
          break;
        case BuildType.Nurture:
          if (entity.coinAsset_ref && entity.coinAsset_ref.path) {
            path = entity.coinAsset_ref.path;
            record = entity.coinAsset_ref;
            parentRecord = entity;
          }
          break;
        case BuildType.Images:
          if (this.assetType == AssetTypes.Item) {
            if (entity.thumbnail_ref && entity.thumbnail_ref.path) {
              path = entity.thumbnail_ref.path;
              record = entity.thumbnail_ref;
              parentRecord = entity;
            }
          } else if (this.assetType == AssetTypes.Challenge) {
            if (entity.image_ref && entity.image_ref.path) {
              path = entity.image_ref.path;
              record = entity.image_ref;
              parentRecord = entity;
            }
          } else if (this.assetType == AssetTypes.NurturePinThumb) {
            if (entity.coinThumbnail_ref && entity.coinThumbnail_ref.path) {
              path = entity.coinThumbnail_ref.path;
              record = entity.coinThumbnail_ref;
              parentRecord = entity;
            }
          } else {
            if (entity.path) {
              path = entity.path;
              record = entity;
            }
          }
          break;
      }

      if (this.tableType == 'miscellaneous-build') {
        buildType = entity.entityType;
        path = entity.path;
        record = entity;
      }

      if (record && record.id) {
        await this.sentToBuildQueue(record, path, buildType, bulkBuildId, parentRecord, newHash);
      }
      else {
        this.bulkBuild.failedPayloads.push({ entityId: entity.id, path: null, user: userPayload });
      }

      count += 1;
      this.bulkBuildProgress = Number(
        ((count / builds.length) * 100).toFixed(2)
      );
    }
  }

  /**
   * Send the build job request to build queue
   */
  async sentToBuildQueue
    (
      entity: any,
      path: string,
      buildQueueType: BuildType,
      bulkBuildId: any,
      parentRecordId: any = null,
      newHash: boolean = false,
    ) {
    interface userData {
      [key: string]: any;
    }
    // populating userPayload with current users's data
    let userPayload: userData = {};
    userPayload.email = this.user.email;
    userPayload.name = this.user.name;
    userPayload.id = this.user.id;

    var payload =
    {
      path: path,
      recursive: true,
      buildType: buildQueueType,
      entityType: entity.entityType,
      assetType: entity.assetType,
      entityId: entity.id,
      user: userPayload,
      isPriority: null,
      bulkBuildId: bulkBuildId,
      isFirstBuild: (entity.buildData && entity.buildData.length > 0) || (entity.assetBuildOutput && Object.keys(entity.assetBuildOutput).length > 0) ? false : true,
      renderAsset: this.buildAndRender,
      parentRecordId: parentRecordId ? parentRecordId.id : null,
      entity: entity,
      newHash: newHash
    };

    this.loggerService.log('payload from build button', payload);
    if (
      payload.path &&
      payload.path.length > 0 &&
      payload.user &&
      payload.entityId
    ) {
      let response = await this.dataService.sendToBuildQueue(
        buildQueueType,
        payload,
        false
      );
      if (response.Success) {
        this.bulkBuild.successCount++;
      } else {
        this.bulkBuild.failedPayloads.push(payload);
      }
    } else {
      this.loggerService.log(payload.path);
      this.bulkBuild.failedPayloads.push(payload);
    }

    return true;
  }

  /**
   * Display failed payloads
   */
  onShowFailedPayloads() {
    this.showBulkBuildFailed = true;
    this.showActions = false;

    this.loggerService.log('Failed Payloads: ', this.bulkBuild.failedPayloads);
  }

  // BULK BUILD
  /**
   * Pre load need data for bulk build
   *
   * @param array List fo entities to build
   * @param type Entity Type
   * @param assetType Asset type
   */
  onStartBulkBuild(array: any[], isAssetBuild: boolean) {
    const rows = [...array];
    this.bulkBuild.rows = rows;
    this.showBulkBuild = true;
    this.buildType = isAssetBuild ? this.buildType : BuildType.Images;
  }

  /**
   * Set Options from nested field
   *
   * @param row Entity Object
   * @param fieldWithNested Field with nested fields
   * @param nestedField Nested field value
   * @param fieldToUpdate Field to update
   * @param refId Id of the field (entity prop)
   * @param reset Flag that sets whether or not to reset field
   */
  async setOptionsFromNestedField(
    row: any,
    fieldWithNested: any,
    nestedField: string,
    fieldToUpdate: any,
    refId: string,
    reset: boolean = true
  ) {
    // clear options on field to update if ref field is empty.
    if (row[fieldWithNested] == null) {
      this.options[fieldToUpdate] = [];
    }

    // get refs from ref field.
    for (const option of this.options[fieldWithNested]) {
      // find matching option to the value selected.
      // this.loggerService.log('option', option);
      if (option._id == refId) {
        this.options[fieldToUpdate] = option[nestedField];
        break;
      }
    }
  }

  async deleteConfirm(row: any) {
    this.confirmationService.confirm({
      key: `${row.id}`,
      message: `Are you sure that you want to delete ${row.name}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.deleteRecord(row.id, this.tableType);

        const idToRemove = String(row.id);
        this.rows = this.rows.filter(row => {
          const currentRowId = String(row.id);
          return currentRowId !== idToRemove;
        });
      },
    });
  }

  async deleteMultipleConfirm(rows: any[]) {
    // Store the records to be deleted
    this.recordsToDelete = rows.filter(row => !row.promoted);
    const promotedRows = rows.filter(row => row.promoted).length;

    this.confirmationService.confirm({
      key: 'deleteMultiple',
      header: 'Delete Multiple Records',
      message: (() => {
        let message = `Are you sure that you want to delete ${this.recordsToDelete.length} records?`;
        if (promotedRows > 0) {
          message += ` (${promotedRows} promoted records will be excluded from deletion)`;
        }
        return message;
      })(),
      accept: async () => {
        if (this.recordsToDelete.length > 0) {
          // Process deletion here
          try {
            this.spinnerService.loadSpinner();

            // Create array of delete promises
            const deletePromises = this.recordsToDelete.map(row =>
              this.dataService.deleteRecord(row.id, this.tableType)
            );

            // Execute all deletes in parallel
            await Promise.all(deletePromises);

            // Remove deleted records from table
            const idsToRemove = this.recordsToDelete.map(row => String(row.id));
            this.rows = this.rows.filter(row => !idsToRemove.includes(String(row.id)));

            this.messageService.add({
              severity: 'success',
              summary: 'Delete Successful',
              detail: `Successfully deleted ${this.recordsToDelete.length} records`
            });

          } catch (error) {
            this.messageService.add({
              severity: 'error',
              summary: 'Delete Failed',
              detail: 'An error occurred while deleting records'
            });
          } finally {
            this.spinnerService.endSpinner();
            this.recordsToDelete = [];
            this.showActions = false;
          }
        } else {
          // No records to delete
          this.messageService.add({
            severity: 'info',
            summary: 'No Records',
            detail: 'No records selected for deletion.'
          });
        }
      },
      reject: () => {
        this.recordsToDelete = [];
        // Handle rejection if needed
      }
    });
  }

  confirmDeleteItemSourceModal(record: any) {
    this.confirmationService.confirm({
      key: `${record.id}`,
      message: `Are you sure that you want to delete ${record.name}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.deleteRecord(record.id, this.tableType);
        await this.tableService
          .getSourceRows(this.tableType, this.sourceGroupID)
          .then(async (data: any) => {
            this.rows = data;
          });
      },
    });
  }

  /**
   * Retrieve suggestions for ref value
   *
   * @param e Event
   * @param fieldKey Key of the field
   * @param apiController Entity property
   * @param minimal Flag that sets whether or not retrieve minimal value
   */
  async getSuggestionsForRef(
    e: any,
    fieldKey: any,
    apiController: string,
    minimal: boolean = false
  ) {
    this.loggerService.log('e', e);
    this.loggerService.log('fieldKey', fieldKey);
    this.loggerService.log('apiController', apiController);
    this.loggerService.log('minimal', minimal);

    // use this.filterModels to map the correct api endpoint so we don't have to store it on the entity.
    this.dataService
      .getAllOfType(apiController, {
        query: isNaN(e) ? { name: { $regex: e, $options: 'i' } } : { id: e },
        select: '_id name id',
        virtuals: minimal ? false : true,
        autopopulate: minimal ? false : true,
      })
      .subscribe((result) => {
        // TODO: remove "items" resource option for item form specifically.
        // items should not cost "items".
        this.suggestions[fieldKey] = result;
      });
  }
  /**
   * Retrieve suggestions for ref value
   *
   * @param e Event
   * @param fieldKey Key of the field
   * @param apiController Entity property
   * @param minimal Flag that sets whether or not retrieve minimal value
   */
  async getSuggestionsForItemFieldRef(
    e: any,
    fieldKey: any,
    apiController: string,
    minimal: boolean = false
  ) {
    // use this.filterModels to map the correct api endpoint so we don't have to store it on the entity.
    this.dataService
      .getAllOfType(apiController, {

        query: isNaN(e) ? {
          $or: [{ name: { $regex: e, $options: 'i' } },
          { fileName: { $regex: e, $options: 'i' } }]
        } : { id: e },
        select: '_id name id colors_ref latinName plantFamily fileName spruceDataStatus height spread',
        virtuals: minimal ? false : true,
        autopopulate: minimal ? false : true,
      })
      .subscribe((result) => {
        // TODO: remove "items" resource option for item form specifically.
        // items should not cost "items".
        this.suggestions[fieldKey] = result;
        this.loggerService.log('checking the results', result);

      });
  }
  async getSuggestionsForChallengeFieldRef(
    e: any,
    fieldKey: any,
    apiController: string,
    minimal: boolean = false
  ) {
    // use this.filterModels to map the correct api endpoint so we don't have to store it on the entity.
    this.dataService
      .getAllOfType(apiController, {
        query: isNaN(e) ? {
          $or: [{ name: { $regex: e, $options: 'i' } },
          { fileName: { $regex: e, $options: 'i' } }]
        } : { id: e },
        select: '_id name id fileName scene location sceneType climate_ref type_ref',
        virtuals: minimal ? false : true,
        autopopulate: minimal ? false : true,
      })
      .subscribe((result) => {
        // TODO: remove "items" resource option for item form specifically.
        // items should not cost "items".
        this.suggestions[fieldKey] = result;
        this.loggerService.log('checking the results', result);

      });
  }

  /**
   * Emits event with value changed on a given field.
   * Returns an object with the new value of the field, the entity and the field data.
   *
   * @param event Event comming from data input component.
   */
  onModelChange(event: any) {
    this.eventModelChange = _.cloneDeep(event);
    this.tableModelChange.emit(event);
  }
  /**
   * Listens to a change in the category dropdown and changes the type dropdown
   * @param event
   */
  onCategoryChange(event: any) {
    if (event.field.key == 'category_ref') {
      let option = this.fullOptions.category_ref.filter((x: { _id: any }) => x._id == event.value._id ? event.value._id : event.value);

      if (option && option[0] && option[0]['types_ref']) {
        this.options['type_ref'] = option;
      }
    }
  }

  /** Makes changes to other fields in row while in Edit Mode, based on conditions specified in FieldData.
   * @param event Contains 'change' object constructed in base-input-field.onChange() and 'effect' object (field.inputControl.effect)
   */
  onComponentChange(event: any) {
    const effect = event.change.field.inputControl.effects[event.index];
    const entity = event.change.entity
    const newValue = event.change.value;

    const originalValue: any = this.inputField._results.find(
      (o: any) =>
        o.entity._id === entity._id && o.field.key === event.change.field.key
    ).initValue;

    const targetedInputField = this.inputField._results.find(
      (o: any) => o.entity._id === entity._id && o.field.key === effect.targetKey
    )

    let otherValues: any = {}
    for (let key of effect.otherKeys) {
      const otherValue = this.inputField._results.find(
        (o: any) => o.entity._id === entity._id && o.field.key === key
      ).value;
      !this.validationsService.isEmpty(otherValue)
        ? (otherValues[key] = otherValue)
        : null;
    }

    const newTargetValue = newValue === originalValue ? entity[effect.targetKey] : effect.change(
      newValue,
      targetedInputField.value ? targetedInputField.value : '',
      otherValues
    );

    targetedInputField.value = newTargetValue
  }

  /**
   * Emits custom action event to parent component.
   * This methos/event emiter can be used on any controller and
   * any custom action.
   *
   * @param event Event comming from custom action
   */
  onCustomAction(event: any, data: any = null, isAssetPromotion: boolean = false, isAddToCollection: boolean = false) {
    this.showFilenameValidationPreviewTable = false;
    this.showPathGenerationPreviewTable = false;
    if (isAddToCollection && event.name == 'Add Selected Items') {
      data = this.selectedRows
    } else if (isAddToCollection) {
      data = this.rows.filter((row: any) => row.promoted == true)
    } else if (isAssetPromotion && !data) {
      data = this.rows.filter((row: any) => row.promoted == false)
    }
    this.onAction.emit({ event: event, data: data, user: this.user, isAssetPromotion: isAssetPromotion, isAddToCollection: isAddToCollection });
  }

  /**
   * Emits Path entity value change
   *
   * @param event Event commig from input field component
   */
  onPathEntityValueChange(event: any) {
    this.onPathComponentValueChange.emit(event);
  }

  /**
   * Handle Bulk Multi select action
   *
   * @param action Action value (Replace, remove, add);
   */
  async onBulkMultiSelectAction(action: string) {
    this.disableBultiSelectedSubmitButton = true;
    this.bulkMultiSelectProgress = 0;
    this.bulkMultiSelectAction.successCount = 0;
    this.bulkMultiSelectAction.errorCount = 0;
    this.bulkMultiSelectAction.skippedRows = [];
    let count = 0;
    // Loop through all rows selected
    for (let row of this.bulkMultiSelect.rows) {
      count += 1;
      this.bulkMultiSelectProgress = Number(
        ((count / this.bulkMultiSelect.rows.length) * 100).toFixed(2)
      );
      // Holds Entity property value to be updated
      let replaceField = row[this.bulkMultiSelectAction.selectedField];
      if (replaceField && replaceField.length > 0) {
        switch (action) {
          case 'replace':
            await this.onBulkReplaceMultiSelect(replaceField, row);
            break;
          case 'remove':
            await this.onBulkRemoveMultiSelect(replaceField, row);
            break;
          case 'add':
            await this.onBulkAddMultiSelect(replaceField, row);
            break;
        }
      } else {
        // If the entity prop is null, and it's add action, the create the object.
        if (action == 'add') {
          await this.onBulkAddMultiSelect(replaceField, row);
        } else {
          this.bulkMultiSelectAction.skippedRows.push({
            Entity: row,
            Message: `Record does not have value to be ${action}`,
          });
        }
      }
    }

    this.messageService.add({
      severity: 'success',
      summary: 'Updates Successful',
      detail: `${this.bulkMultiSelectAction.successCount} rows successfully updated.`,
      life: this.toasterLife
    });

    if (
      this.bulkMultiSelectAction.skippedRows &&
      this.bulkMultiSelectAction.skippedRows.length > 0
    ) {
      this.messageService.add({
        severity: 'info',
        summary: 'Skipped Record',
        detail: `${this.bulkMultiSelectAction.skippedRows.length} rows skipped.`,
        life: this.toasterLife
      });
    }

    if (
      this.bulkMultiSelectAction.errorCount &&
      this.bulkMultiSelectAction.errorCount > 0
    ) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Error',
        detail: `${this.bulkMultiSelectAction.errorCount} rows with error.`,
      });
    }

    this.disableBultiSelectedSubmitButton = false;
    this.showEditOptions = false;
  }

  /**
   * Handle find and replace function
   */
  async onFindAndReplaceAction() {
    this.disableBultiSelectedSubmitButton = true;
    this.bulkMultiSelectProgress = 0;
    this.bulkMultiSelectAction.successCount = 0;
    this.bulkMultiSelectAction.errorCount = 0;
    this.bulkMultiSelectAction.skippedRows = [];

    for (let row of this.bulkMultiSelect.rows) {
      let replaceField = row[this.bulkMultiSelectAction.selectedField];

      if (replaceField && replaceField.length > 0) {
        if (replaceField.includes(this.bulkMultiSelectAction.findValue)) {
          replaceField = this.ReplaceValue(replaceField, this.bulkMultiSelectAction.findValue, this.bulkMultiSelectAction.findAndReplaceValue);
          await this.updateMultiSelectEntity(row, replaceField);
          row[this.bulkMultiSelectAction.selectedField] = replaceField;
        } else {
          this.bulkMultiSelectAction.skippedRows.push({
            Entity: row,
            Message: `Record does not have value to be replaced`,
          });
        }
      }
    }

    if (this.bulkMultiSelectAction.successCount > 0) {
      this.messageService.add({
        severity: 'success',
        summary: 'Updates Successful',
        detail: `${this.bulkMultiSelectAction.successCount} rows successfully updated.`,
        life: this.toasterLife
      });
    }

    if (
      this.bulkMultiSelectAction.skippedRows &&
      this.bulkMultiSelectAction.skippedRows.length > 0
    ) {
      this.messageService.add({
        severity: 'info',
        summary: 'Skipped Record',
        detail: `${this.bulkMultiSelectAction.skippedRows.length} rows skipped.`,
        life: this.toasterLife
      });
    }

    if (
      this.bulkMultiSelectAction.errorCount &&
      this.bulkMultiSelectAction.errorCount > 0
    ) {
      this.messageService.add({
        sticky: true,
        severity: 'info',
        summary: 'Skipped Record',
        detail: `${this.bulkMultiSelectAction.skippedRows} rows skipped.`,
      });
    }

    this.selectedRows = this.bulkMultiSelect.rows;
    this.disableBultiSelectedSubmitButton = false;
    this.showFindAndReplace = false;
  }

  /**
   * Handle Bulk Replace multi select action
   *
   * @param replaceField Field to be modified
   * @param entity Entity record
   */
  async onBulkAddMultiSelect(replaceField: Array<any>, entity: any) {
    let originalFieldValue = _.cloneDeep(replaceField);
    if (!replaceField) {
      replaceField = [];
    }
    if (this.bulkMultiSelect.field && this.bulkMultiSelect.field.key == 'env') {
      // joining exising value with new values
      replaceField = replaceField.concat(this.bulkMultiSelectAction.valueToReplace);
      // removing duplicates
      replaceField = [...new Set(replaceField)];
    } else {
      for (let value of this.bulkMultiSelectAction.valueToReplace) {
        let existingValue = replaceField.find((x: any) => x.name == value.name);

        if (!existingValue) {
          let option = this.options[
            this.bulkMultiSelectAction.selectedField
          ].find((option: any) => option.name == value.name);
          if (option) {
            replaceField.push(option);
          }
        }
      }
    }

    await this.updateMultiSelectEntity(entity, replaceField);
  }

  /**
   * Handle Bulk Replace multi select action
   *
   * @param replaceField Field to be modified
   * @param entity Entity record
   */
  async onBulkReplaceMultiSelect(replaceField: Array<any>, entity: any) {
    // Find existing value to be replaced.
    // Get first value from valueToReplace, since it's 1 value x multiple values.

    if (this.bulkMultiSelect.field && this.bulkMultiSelect.field.key == 'env') {
      // find and deplave for env's col
      let existingValue = replaceField;
      console.log(`
      existingValue ${existingValue}
      to replace: ${this.bulkMultiSelectAction.valueToReplace}
      replace with: ${this.bulkMultiSelectAction.replaceValues}
      `);
      let indexesToReplace: Array<number> = [];
      this.bulkMultiSelectAction.valueToReplace[0].forEach((toReplace: string) => {
        let indexToReplace = existingValue.indexOf(toReplace);
        if (indexToReplace !== -1) {
          indexesToReplace.push(indexToReplace)
        }
      });
      // remove matching indexes
      replaceField = replaceField.filter(function (value, index) {
        return indexesToReplace.indexOf(index) == -1;
      });
      //  add new values to replace removed ones and clean duplicates
      replaceField = [...new Set(replaceField.concat(this.bulkMultiSelectAction.replaceValues))];
      if (replaceField.length > 0) {
        await this.updateMultiSelectEntity(entity, replaceField);
      } else {
        this.bulkMultiSelectAction.skippedRows.push({
          Entity: entity,
          Message: 'No remaining value to be replaced.',
        });
      }
    } else {
      let existingValue = replaceField.find(
        (x: any) => x.name == this.bulkMultiSelectAction.valueToReplace[0].name
      );

      if (existingValue) {
        var index = replaceField.indexOf(existingValue);

        if (index !== -1) {
          // Remove existing value.
          replaceField.splice(index, 1);
          replaceField.push(...this.bulkMultiSelectAction.replaceValues);
          await this.updateMultiSelectEntity(entity, replaceField);
        }
      } else {
        this.bulkMultiSelectAction.skippedRows.push({
          Entity: entity,
          Message: 'No existing value to be replaced.',
        });
      }
    }

  }

  /**
   * Handle Bulk remove multi select action
   *
   * @param replaceField Field to be modified
   * @param entity Entity record
   */
  async onBulkRemoveMultiSelect(replaceField: Array<any>, entity: any) {
    let originalFieldValue = _.cloneDeep(replaceField);
    if (this.bulkMultiSelect.field && this.bulkMultiSelect.field.key == 'env') {
      // filtering original value with values to remove
      replaceField = replaceField.filter(x => !this.bulkMultiSelectAction.valueToReplace.includes(x));
    } else {
      for (let value of this.bulkMultiSelectAction.valueToReplace) {
        let existingValue = replaceField.find((x: any) => x.name == value.name);

        if (existingValue) {
          var index = replaceField.indexOf(existingValue);

          if (index !== -1) {
            // Remove existing value.
            replaceField.splice(index, 1);
          }
        } else {
          this.bulkMultiSelectAction.skippedRows.push({
            Entity: entity,
            Message: 'No existing value to be removed.',
          });
        }
      }
    }

    // Only call API if value was modified
    if (originalFieldValue.length != replaceField.length) {
      await this.updateMultiSelectEntity(entity, replaceField);
    }
  }

  /** Updates entity Multi select field
   *
   * @param entity Entity object
   * @param entity Entity record
   */
  async updateMultiSelectEntity(entity: any, replaceField: any) {
    console.log('updateMultiSelectEntity ', entity, replaceField);
    try {
      // Update entity
      let response = await this.tableService.updateEntity(
        this.tableType,
        entity.id,
        { [this.bulkMultiSelectAction.selectedField]: replaceField }
      );

      if (response) {
        this.bulkMultiSelectAction.successCount += 1;
        // TODO - REMOVE THIS SHIT
        let index = this.rows.findIndex((x: any) => x.id == entity.id);
        this.rows[index][this.bulkMultiSelectAction.selectedField] = replaceField
      } else {
        this.bulkMultiSelectAction.errorCount += 1;
      }
    } catch (error) {
      this.loggerService.error('Error on Update Entity: ', error);
      this.bulkMultiSelectAction.errorCount += 1;
    }
  }

  /**
   * Get all sourcing groups and set the sourcing group list
   * for dropdown list.
   */
  async getAllSourcingGroups() {
    this.sourcingGroups = [];
    let response: any;
    if (this.tableType == 'items-sourcing') {
      response = await this.sourcingItemGroupService.getAllSourcingGroupsItems();
    } else {
      response = await this.sourcingChallengeGroupService.getAllSourcingGroupsChallenges();
    }


    if (response && response.length > 0) {
      response.forEach((element: any) => {
        // Skip current source group
        if ((element._id != this.sourceGroupID) && !element.archived) {
          this.sourcingGroups.push({ name: element.name, value: element._id });
        }
      });
    }
  }

  /**
   * Moves Items Sourcing to a selected Sourcing Group
   */
  async moveItemsSourcingToGroup(challenges: boolean) {
    if (this.moveAllSourcingItems) {
      await this.updateItemSourcingGroupRef(this.rows, challenges);
    } else {
      if (this.selectedRows && this.selectedRows.length > 0) {
        await this.updateItemSourcingGroupRef(this.selectedRows, challenges);
      }
    }
  }

  /**
   * Updates Item Sourcing - Sourcing Group Ref value
   *
   * @param rows List of items sourcing
   */
  async updateItemSourcingGroupRef(rows: Array<any>, challenges: boolean) {
    let successCount = 0;
    if (rows && rows.length > 0) {
      for (let row of rows) {
        let response = challenges ? await this.sourcingService.updateChallengeSourcing(
          row.id,
          {
            sourceGroup_ref: { _id: this.selectedSourcingGroup },
          }) : await this.sourcingService.updateItemSourcing(
            row.id,
            {
              sourceGroup_ref: { _id: this.selectedSourcingGroup },
            }
          );
        if (response) {
          successCount += 1;
        } else {
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Error',
            detail: `Something went wrong with row ${row.id}.`,
          });
        }
      }

      if (successCount == rows.length) {
        this.displayMoveSourcingModal = false;
        this.messageService.add({
          severity: 'success',
          summary: 'Update Successful',
          detail: `${successCount} rows successfully moved.`,
          life: this.toasterLife
        });
        this.selectedRows = [];
      }

      await this.getItemSourceRowData();
    }
  }

  /**
   * Clear all messages from Message Service.
   */
  clearMessages() {
    this.messageService.clear();
  }

  /**
   * Create a copy of the original set name
   *
   * @param setName Original set name value
   */
  onEditSetName(setName: string) {
    this.tempColumnSetName = _.cloneDeep(setName);
  }

  /**
   * Updates Column Set name
   *
   * @param setName Set name value to be replaced.
   */
  async renameColumnSet(setName: string) {
    if (this.tempColumnSetName && this.tempColumnSetName.length > 0) {
      let newSetName = _.cloneDeep(this.tempColumnSetName);
      let settings = { ...this.userSettings };
      let updateIndex = settings.columnSets.findIndex(
        (set: any) => setName == set.name
      );

      let isDuplicated = settings.columnSets.find(
        (set: any) => newSetName.toLowerCase() == set.name.toLowerCase()
      );

      if (!isDuplicated) {
        settings.columnSets[updateIndex].name = newSetName;

        this.saveUserSettings(settings);
        if (settings.columnSets) {
          const setGroup = this.columnSetGroups.find(
            (group: any) => group.key == 'userColumnSets'
          );
          setGroup.value = settings.columnSets;
        }
        this.activeColumnSetName = newSetName;
        this.tempColumnSetName = '';
      } else {
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Column Set Name Error',
          detail: 'Duplicated Column Set Name.',
        });
      }
    } else {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Column Set Name Error',
        detail: 'Column set name cannot be empty.',
      });
    }
  }

  /**
   * Handle Drag Start events
   *
   * @param index Start index of the dragged object.
   */
  onDragStart(index: number) {
    this.dragStartIndex = index;
  }

  /**
   * Handle Drop events
   *
   * @param event Name of the event.
   * @param dropIndex End/drop index of the dragged object.
   * @param data Any additional data needed for the event.
   */
  async onDrop(event: string, dropIndex: number, data: any) {
    switch (event) {
      case 'Column Sets Order':
        await this.reOrderColumnSet(dropIndex, data);
        break;

      default:
        break;
    }
  }

  /**
   * Handle changes on column sets order
   *
   * @param dropIndex End/drop index of the dragged object.
   * @param isGlobalSet Flag that sets whether or not is a global set.
   */
  async reOrderColumnSet(dropIndex: number, isGlobalSet: boolean) {
    if (!isGlobalSet) {
      let set = this.columnSetGroups.find(
        (columnSet: any) => columnSet.key == 'userColumnSets'
      );

      let element = set.value[this.dragStartIndex]; // get element
      this.columnSetGroups[1].value.splice(this.dragStartIndex, 1); // delete from old position
      this.columnSetGroups[1].value.splice(dropIndex, 0, element); // add to new position

      this.userSettings.columnSets = _.cloneDeep(this.columnSetGroups[1].value);

      await this.saveUserSettings(this.userSettings);

      // adjust submit message for edit/new.
      this.messageService.add({
        severity: 'success',
        summary: 'Update Successful',
        detail: `Column Sets order was updated.`,
        life: this.toasterLife
      });
    }
  }

  /**
   * Handle bulk Promotion of assets/images
   *
   * @param selectedRows List of selected entities to be promoted
   * @param isAssetPromotion Flag that sets whether or not is an asset promotion.
   */
  async onBulkPromotion(selectedRows: any[], isAssetPromotion: boolean = false, newHash: boolean = false) {
    this.tableSpinnerIcon = "ball-climbing-dot";
    this.bulkPromoteProgress = 0;
    this.spinnerService.loadSpinner();
    this.bulkPromote.successCount = 0;
    this.bulkPromote.failedPayloads = [];
    this.disablePromoteButton = true;
    let count = 0;
    let assetRecord: any = {};
    let imageRecord: any = {};
    let tableType = this.tableType

    if (selectedRows && selectedRows.length > 0) {
      for (let asset of selectedRows) {
        if (this.tableType == 'miscellaneous-build' && asset.entityType !== undefined && asset.entityType !== null && asset.assetType !== undefined && asset.assetType !== null) {
          this.assetType = asset.assetType;
          this.buildType = asset.entityType;
          isAssetPromotion = true;
          if ([0, 4].includes(this.assetType)) {
            tableType = 'items';
          } else if ([1, 5].includes(this.assetType)) {
            tableType = 'challenges';
          }
        }

        if (isAssetPromotion) {
          let isValid = this.validateAssetPromotion(this.tableType, asset);

          if (isValid) {
            if (this.assetType == AssetTypes.Item && this.tableType !== 'miscellaneous-build') {
              assetRecord = asset.prefab_ref;
            }
            else if (this.assetType == AssetTypes.Challenge && this.tableType !== 'miscellaneous-build') {
              assetRecord = asset.scene_ref;
            }
            else {
              assetRecord = asset;
            }

            if (assetRecord && assetRecord.id) {
              let response;
              if (this.tableType == 'miscellaneous-build') {
                response = await this.promotionService.onPromoteRecord(
                  this.buildType,
                  {
                    from: 'dev', to: 'prod',
                    entity: assetRecord, buildType: this.buildType,
                    newHash: newHash
                  },
                  false,
                  tableType
                );
              } else {
                response = await this.promotionService.onPromoteRecord(
                  this.tableType == 'items' ? BuildType.Items : BuildType.Levels,
                  {
                    from: 'dev', to: 'prod',
                    entity: assetRecord, buildType: this.tableType == 'items' ? BuildType.Items : BuildType.Levels,
                    newHash: newHash
                  },
                  false,
                  this.tableType
                );
              }

              if (response && response.Success) {
                this.bulkPromote.successCount += 1;
              }
              else {
                assetRecord.promotionError = response && response.AssetResponse && response.AssetResponse.Message ? response.AssetResponse.Message : 'Error promoting asset.';
                this.bulkPromote.failedPayloads.push(assetRecord);
              }
            }
          }
          else {
            assetRecord.promotionError = 'Safety gating validation failed.';
            this.bulkPromote.failedPayloads.push(assetRecord);
          }
        }
        else {
          let isValid = this.validateAssetPromotion(this.tableType, asset);

          if (isValid) {
            if (this.assetType == AssetTypes.Item) {
              imageRecord = asset.thumbnail_ref;
            }
            else if (this.assetType == AssetTypes.Challenge) {
              imageRecord = asset.image_ref;
            }
            else {
              imageRecord = asset;
            }
            if (imageRecord && imageRecord.id) {
              let response = await this.promotionService.onPromoteRecord(BuildType.Images, { from: 'dev', to: 'prod', entity: imageRecord, buildType: BuildType.Images }, false, this.tableType);

              if (response && response.Success) {
                this.bulkPromote.successCount += 1;
              }
              else {
                imageRecord.promotionError = response && response.ImageResponse && response.ImageResponse.Message ? response.ImageResponse.Message : 'Error promoting image.';
                this.bulkPromote.failedPayloads.push(imageRecord);
              }
            }
          }
          else {
            imageRecord.promotionError = 'Safety gating validation failed.';
            this.bulkPromote.failedPayloads.push(imageRecord);
          }
        }
        count += 1;
        this.bulkPromoteProgress = Number(
          ((count / selectedRows.length) * 100).toFixed(2)
        );
      }

      if (this.bulkPromote.successCount > 0) {
        this.messageService.add(
          {
            severity: 'success',
            summary: 'Promotion Successful',
            detail: `${this.bulkPromote.successCount} ${isAssetPromotion ? 'assets' : 'images'} successfully promoted.`,
            life: this.toasterLife
          }
        );
      }

      if (this.bulkPromote.failedPayloads && this.bulkPromote.failedPayloads.length > 0) {
        this.messageService.add(
          {
            sticky: true,
            severity: 'error',
            summary: 'Promotion Error',
            detail: `${this.bulkPromote.failedPayloads.length} ${isAssetPromotion ? 'assets' : 'images'} not promoted.`
          }
        );
      }

      this.showBulkPromote = false;
      this.showBulkPromoteFailed = this.bulkPromote.failedPayloads && this.bulkPromote.failedPayloads.length > 0;
      this.disablePromoteButton = false;
      this.spinnerService.endSpinner();
      this.tableSpinnerIcon = "pacman";
    }
  }

  /**
   * Check safety gating logic.
   *
   * @param assetType Asset Type value
   * @param entity Entity data
   */
  validateAssetPromotion(assetType: string, entity: any) {
    let isValid = true;
    switch (assetType) {
      case 'items':
        if
          (
          entity.itemStatus != 'Approved' ||
          entity.vendorStatus != 'Approved' ||
          entity.start == null ||
          entity.start == 0
        ) {
          isValid = false;
        }
        // Do not promote if "hold" flag is set
        if (entity.flagged && entity.flagged.toLowerCase() == 'hold') {
          isValid = false;
        }
        break;
    }

    return isValid;
  }

  /**
   * Show the Confirmation Bulk Promotion Dialog,
   * and sets the selected rows.
   *
   * @param selectedRows Rows selected by the user.
   * @param isAssetPromotion Flag that sets whether or not is an asset promotion.
   */
  startBulkPromotion(selectedRows: Array<any>, isAssetPromotion: boolean) {
    const rows = [...selectedRows];
    this.bulkPromote.rows = rows;
    this.showBulkPromote = true;
    this.isAssetPromotion = isAssetPromotion;
  }

  /**
   * Show Failed Promotion Dialog
   */
  onShowFailedPromotions() {
    this.showBulkPromoteFailed = true;
    this.loggerService.log('Failed Promotions: ', this.bulkPromote.failedPayloads);
  }

  async duplicateItemForNewRow() {
    // Extract reference IDs from collection references
    const extractReferenceIds = (referenceCollection: any[] | undefined): string[] => {
      if (!referenceCollection || !referenceCollection.length) return [];
      return referenceCollection.map(item => item._id);
    };

    // Collect all reference IDs in one place
    const references = {
      styles: extractReferenceIds(this.itemToCopy.styles_ref),
      materials: extractReferenceIds(this.itemToCopy.materials_ref),
      patterns: extractReferenceIds(this.itemToCopy.patterns_ref),
      climates: extractReferenceIds(this.itemToCopy.climates_ref),
      traits: extractReferenceIds(this.itemToCopy.traits_ref)
    };

    // Collect all checked fields in one array
    const checkedField: string[] = [];

    // Add fields from each selection group to checkedField array
    [
      this.selectedItemInOrganicFields,
      this.selectedItemOrganicFields,
      this.selectedChallengeFields
    ].forEach(fieldGroup => {
      if (fieldGroup && fieldGroup.length) {
        fieldGroup.forEach((element: any) => {
          checkedField.push(element.key);
        });
      }
    });

    // Helper function to conditionally include fields
    const includeIf = (fieldName: string, value: any, defaultValue: any = null) => {
      return checkedField.includes(fieldName) && value !== undefined ? value : defaultValue;
    };

    // Get the appropriate source group type based on table type
    const sourceGroupType = this.tableType === 'items-sourcing' ? 'sourcing-groups' : 'sourcing-challenge-groups';

    // Fetch source group data
    const sourcingGroup = await this.dataService.getDocumentAsync(
      sourceGroupType,
      {
        query: { _id: this.sourceGroupID },
        autopopulate: true,
        virtuals: true,
      }
    );
    this.loggerService.log('Sourcing Group: ', sourcingGroup);

    // Create new record data based on table type
    if (this.tableType === 'items-sourcing') {
      await this.createItemSourcingRecord(checkedField, references, sourcingGroup);
    } else if (this.tableType === 'challenges-sourcing') {
      await this.createChallengeSourcingRecord(checkedField);
    }

    // Refresh the rows data
    await this.tableService
      .getSourceRows(this.tableType, this.sourceGroupID)
      .then(async (data: any) => {
        this.rows = data;
      });

    // Reset selection fields and close the dialog
    this.selectedItemInOrganicFields = [];
    this.selectedItemOrganicFields = [];
    this.selectedChallengeFields = [];
    this.selectFieldsForDuplication = false;
  }

  /**
   * Creates a new record for items-sourcing table type
   * @param checkedField Array of field names that were checked for duplication
   * @param references Object containing extracted reference IDs
   * @param sourcingGroup The source group data
   */
  private async createItemSourcingRecord(checkedField: string[], references: any, sourcingGroup: any) {
    // Helper function to conditionally include fields
    const includeIf = (fieldName: string, value: any, defaultValue: any = null) => {
      return checkedField.includes(fieldName) && value !== undefined ? value : defaultValue;
    };

    await this.dataService.addNewRecord(this.tableType, {
      name: includeIf('name', this.itemToCopy.name),
      spruceDataStatus: includeIf('spruceDataStatus', this.itemToCopy.spruceDataStatus, false),
      externalPlantData_ref: includeIf('spruceDataStatus', this.itemToCopy.spruceDataStatus) ?
        `${this.itemToCopy.externalPlantData_ref._id}` : null,
      height: includeIf('height', this.itemToCopy.height),
      spread: includeIf('spread', this.itemToCopy.spread),
      category_ref: includeIf('category_ref', this.itemToCopy.category_ref) ?
        { _id: this.itemToCopy.category_ref._id } : null,
      climates_ref: includeIf('climates_ref', references.climates.length > 0) ?
        references.climates : null,
      itemFileType_ref: includeIf('itemFileType_ref', this.itemToCopy.itemFileType_ref) ?
        { _id: this.itemToCopy.itemFileType_ref._id } : null,
      latinName: includeIf('latinName', this.itemToCopy.latinName),
      plantFamily: includeIf('plantFamily', this.itemToCopy.plantFamily),
      styles_ref: includeIf('styles_ref', references.styles.length > 0) ?
        references.styles : null,
      materials_ref: includeIf('materials_ref', references.materials.length > 0) ?
        references.materials : null,
      patterns_ref: includeIf('patterns_ref', references.patterns.length > 0) ?
        references.patterns : null,
      shape_ref: includeIf('shape_ref', this.itemToCopy.shape_ref) ?
        this.itemToCopy.shape_ref._id : null,
      sourceGroup_ref: { _id: this.sourceGroupID },
      type_ref: includeIf('type_ref', this.itemToCopy.type_ref) ?
        { _id: this.itemToCopy.type_ref._id } : null,
      traits_ref: includeIf('traits_ref', references.traits.length > 0) ?
        references.traits : null,
      batch_ref: sourcingGroup.batch_ref ? sourcingGroup.batch_ref._id : null,
      promoted: false,
      vendor_ref: sourcingGroup.batch_ref?.vendor_ref ?
        sourcingGroup.batch_ref.vendor_ref._id : null,
      assetType: sourcingGroup.batch_ref?.assetType ?
        sourcingGroup.batch_ref.assetType : null,
      year: sourcingGroup.batch_ref?.year ?
        sourcingGroup.batch_ref.year : null,
      vendorStatus: sourcingGroup.batch_ref?.vendorStatus ?
        sourcingGroup.batch_ref.vendorStatus : null,
    });
  }

  /**
   * Creates a new record for challenges-sourcing table type
   * @param checkedField Array of field names that were checked for duplication
   */
  private async createChallengeSourcingRecord(checkedField: string[]) {
    // Helper function to conditionally include fields
    const includeIf = (fieldName: string, value: any, defaultValue: any = null) => {
      return checkedField.includes(fieldName) && value !== undefined ? value : defaultValue;
    };

    let fileNameToCopy = 'xx_challengeid_';
    if (this.itemToCopy.fileName) {
      const parts = this.itemToCopy.fileName.split('_');
      if (parts.length > 1 && !isNaN(parts[1])) {
        fileNameToCopy = parts[0] + '_challengeid_' + parts.slice(2).join('_');
      }
    }

    if (this.itemToCopy?.scene?.length > 0) {
      // Replace old filename with new filename in scene path
      this.itemToCopy.scene = this.itemToCopy.scene.replace(
        new RegExp(this.itemToCopy.fileName, 'g'),
        fileNameToCopy
      );
    }

    await this.dataService.addNewRecord(this.tableType, {
      sourceGroup_ref: { _id: this.sourceGroupID },
      name: includeIf('name', this.itemToCopy.name),
      fileName: fileNameToCopy,
      sceneType: includeIf('sceneType', this.itemToCopy.sceneType) ?
        this.itemToCopy.sceneType._id : null,
      env: [],
      scene: includeIf('scene', this.itemToCopy.scene),
      location: includeIf('location', this.itemToCopy.location),
      climate_ref: includeIf('climate_ref', this.itemToCopy.climate_ref) ?
        this.itemToCopy.climate_ref._id : null,
      type_ref: includeIf('type_ref', this.itemToCopy.type_ref) ?
        { _id: this.itemToCopy.type_ref._id } : null,
      parent_challenge: this.itemToCopy.id
    });
  }

  /**
   *
   * @param item item is used to grab the item select and copied into this.itemToCopy variable
   * This function is where itemToCopy is set after searching an item via autoComplete field on the template side
   */

  onSelection(item: any) {
    this.selectExistingItemForDuplication = false;
    this.selectFieldsForDuplication = true;
    this.itemToCopy = item;
    this.loggerService.log('itemToCopy', this.itemToCopy)
  }


  /**
   * This function controls going back to the search existing item dialog after selection
   */

  goBackExistingItemDialog() {
    this.selectExistingItemForDuplication = true;
    this.selectFieldsForDuplication = false;
    this.itemToCopy = {}
  }

  /**
   *
   * @param index
   * This function controils toggling the list of Inorganic options.
   * It acts as a select all button
   */
  toggleInorganic(index: number) {
    this.activeState[index] = !this.activeState[index];
    this.selectedItemInOrganicFields = this.itemInorganicFields.slice(0, this.itemInorganicFields.length);

    if (this.activeState[index] == true) {
      this.selectedItemInOrganicFields = this.itemInorganicFields.slice(0, this.itemInorganicFields.length);
    } else {
      this.selectedItemInOrganicFields = []
    }
  }


  /**
   *
   * @param index
   * This function controils toggling the list of Organic options.
   * It acts as a select all button
   */

  toggleOrganic(index: number) {
    this.activeState[index] = !this.activeState[index];

    if (this.activeState[index] == true) {
      this.selectedItemOrganicFields = this.itemOrganicFields.slice(0, this.itemOrganicFields.length);
    } else {
      this.selectedItemOrganicFields = [];
    }
  }

  /**
   *
   * @param index
   * This function controils toggling the list of Organic options.
   * It acts as a select all button
   */

  toggleChallengeFields(index: number) {
    this.challengeActiveState[index] = !this.challengeActiveState[index];

    if (this.challengeActiveState[index] == true) {
      this.selectedChallengeFields = this.challengeFields.slice(0, this.challengeFields.length);
    } else {
      this.selectedChallengeFields = [];
    }
  }

  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Removes a row from selected rows
   *
   * @param index index of the row to remove.
   */
  removeRowFromSelectedRows(index: any) {
    this.onRowCheckboxToggle(this.selectedRows[index], false);
  }

  /**
   * function to trigger display all
   * @param display
   * @param all
   */
  displayMove(display: boolean, all: boolean) {
    this.displayMoveSourcingModal = display;
    this.moveAllSourcingItems = all;
  }

  /**
   * copies original row's value into cloned rows
   */
  copyOriginalValue(row: any) {
    this.clonedRows[row.id] = JSON.parse(JSON.stringify(row));
  }

  /**
   * remove copy from cloned rows
   */
  removeCopy(row: any) {
    delete this.clonedRows[row.id];
  }

  /**
   * reverts a row's value to it's original value copied in clonedRows
  */
  revertToOriginalValue(row: any, index: number = -1) {
    if (index < 0) {
      index = this.rows.findIndex((x: any) => x.id == row.id);
    }
    this.rows[index] = JSON.parse(JSON.stringify(this.clonedRows[row.id]));
    delete this.clonedRows[row.id];
  }

  showAssetDialog() {
    this.displayCreateNewAsset = true;
  }

  navigateToEdit() {
    // Assuming you have a router service injected in your component
    console.log('this.tableViewEntity', this.sourceGroupEditID)
    console.log('this.tableType', this.tableType)

    if (this.tableType == 'items-sourcing') {
      this.router.navigate([`sourcing-groups/edit/${this.sourceGroupEditID}`]);
    } else {
      this.router.navigate([`sourcing-challenge-groups/edit/${this.sourceGroupEditID}`]);
    }
  }

  // Add this method to handle removing items from the delete list
  removeFromDeleteList(index: number) {
    if (index >= 0 && index < this.recordsToDelete.length) {
      this.recordsToDelete.splice(index, 1);

      // Update the confirmation message to reflect the new count
      const confirmMessage = document.querySelector('.p-confirm-dialog-message p');
      if (confirmMessage) {
        confirmMessage.textContent = `Are you sure that you want to delete ${this.recordsToDelete.length} records?`;
      }

      // If all records were removed, close the dialog
      if (this.recordsToDelete.length === 0) {
        this.confirmationService.close();
        this.messageService.add({
          severity: 'info',
          summary: 'No Records',
          detail: 'All records removed from deletion list.'
        });
      }
    }
  }
}
