<div p-component>
  <p-menubar *ngIf="loggedIn" [model]="menuItems" [autoDisplay]="false"
    [ngClass]="{'fixed-menu': isMenuFixed}">
    <ng-template pTemplate="end">

      <div class="p-m-1 p-grid p-jc-end p-ac-center">
        <!-- Label "Store" in front of the Environments Dropdown -->
        <div class="p-as-center" style="margin-right: 0.3em;">
          <small>Store Env:</small>
        </div>
        <div class="p-as-center">
          <environment-toggler></environment-toggler>
        </div>
        <!-- Environments Dropdown -->
        <!-- GLOBAL SEARCH -->
        <!-- Transfer Cart Icon -->
        <button pButton pRipple icon="pi pi-shopping-cart" type="button" class="p-button-rounded p-button-text-title"
          (click)="toggleTransferModal()" style="position: relative;">
        <!-- No content needed here as the icon will be set via the icon attribute -->
          <p-badge [value]="storeTransferCount" severity="primary" size="small" 
          [ngStyle]="{ 'position': 'absolute', 'top': '-1px', 'right': '1px', 'z-index': '999'}"></p-badge>
        </button>
        <!-- GLOBAL SEARCH -->
        <div class="p-as-center">
          <global-search></global-search>
        </div>

        <!-- RIGHT BUTTONS -->
        <div class="p-my-auto p-ml-2">
          <button pButton pRipple icon="pi pi-bars" type="button" class="p-button-rounded p-button-text-title"
            (click)="toggleSidebar()"></button>
        </div>

        <!-- Right Sidebar -->
        <p-sidebar [(visible)]="sidebarVisible" [showCloseIcon]="true" [closeOnEscape]="true" position="right" appendTo="body">
          <div>
            <button pButton pRipple type="button" routerLink="/home" class="p-button p-button-text p-mb-1"
              style="width: 100%; text-align: left;">
              <span class="pi pi-sign-out" style="margin-right: 8px;"></span>Sign Out
            </button>
            <button pButton pRipple type="button" class="p-button p-button-text p-mb-1"
              style="width: 100%; text-align: left;" (click)="onThemeSwitched()">
              <span [class]="themeIcon" style="margin-right: 8px;"></span>Switch Theme
            </button>
            <button pButton pRipple type="button" routerLink="/settings" class="p-button p-button-text p-mb-1"
              style="width: 100%; text-align: left;">
              <span class="pi pi-cog" style="margin-right: 8px;"></span>Settings
            </button>
            <!-- Inserted SideMenu Component -->
            <app-side-menu></app-side-menu>
          </div>
        </p-sidebar>

      </div>
    </ng-template>
  </p-menubar>
  <p-toast [life]="3000"></p-toast>
  <router-outlet></router-outlet>
</div>
<p-dialog [(visible)]="transferModalVisible" [dismissableMask]="true" [modal]="true" [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '90vw' }" (onShow)="reloadCart()"
  maximizable="true">
  <ng-template pTemplate="header">
    <div class="header-content">
      <h3>Store Transfer</h3>
    </div>
  </ng-template>
  <div>
    <store-transfer (enableButton)="setEnableTransfer($event)" [sourceEnv]="cart ? cart.sourceEnv : 'dev'" [destinationEnv]="cart ? cart.destinationEnv : 'qa'" [storeListings]="cart ? cart.listings : []"
      [pricePoints]="cart ? cart.pricePoint : []"></store-transfer>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" label="Transfer" [disabled]="enableTransfer" icon="pi pi-check" (click)="storeTransfer()"
      class="p-button-primary p-mt-3"></button>
    <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="transferModalVisible = false"
      class="p-button-secondary p-mt-3"></button>
  </ng-template>
</p-dialog>
