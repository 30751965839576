/**
 * Dynamic Card Type Enum
 */
 export enum DynamicCardType
 {
    // PrimeNG Types/Components
    Card = 1,
    Fieldset,
    Accordion,
    Divider,
    Panel,
    TabView,
    MiscellaneousBuild,
    BuildHistory,
    History
 }
