<p-fieldset [legend]="field.label" [toggleable]="true" [collapsed]="field.options.collapsed">
  <!-- Build and Render -->
  <div style="margin: 5px;">
    <div *ngIf="assetRecord">
          <div style="margin-bottom: 1%;">
            <h5 class="p-d-inline p-mr-2">Render Asset? <i pTooltip="Renders the asset once it has been successfully built, and a slack notification is sent to preview the asset." class="pi pi-info-circle" style="font-size: 12px"></i></h5>
          </div>
            <p-inputSwitch class="p-d-inline" [(ngModel)]="field.options.buildAndRender"></p-inputSwitch>

            <div class="p-grid" >
              <div class="p-col-12">
                <strong>{{field.label}} Name:</strong>
                {{assetRecord ? assetRecord.name : ''}}
                <a href="/miscellaneous-build/{{assetRecord ?  assetRecord.id : ''}}" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }"
                >
                  ({{assetRecord ?  assetRecord.id : ''}})
                </a>
              </div>
              <div class="p-col-12">
                <strong>{{field.label}} Path:</strong>  {{assetRecord ? assetRecord.path : ''}}
              </div>
              <div class="p-col-12">
                <strong>{{field.label}} Asset Type:</strong>  {{assetRecord && assetRecord.assetType ? getAssetType(assetRecord.assetType): ''}}
              </div>
              <div class="p-col-12 p-my-3 p-d-flex" >
                <build-asset
                  class="p-ml-1"
                  [entity]="assetRecord"
                  [path]="assetRecord.path"
                  [type]="'miscellaneous-build'"
                  assetType="image"
                  (triggerCompleteEvent)="getUpdatedBuildData($event)"
                  label="Build Asset"
                  [renderAsset]="field.options.buildAndRender"
                  [buildType]="buildType.Images"
                ></build-asset>
                <build-asset
                  class="p-ml-3 p-mr-5"
                  [entity]="assetRecord"
                  [path]="assetRecord.path"
                  [type]="'miscellaneous-build'"
                  assetType="asset"
                  (triggerCompleteEvent)="getUpdatedBuildData($event)"
                  label="Build Asset as High Priority"
                  [buildType]="buildType.Images"
                  [renderAsset]="field.options.buildAndRender"
                  [isHighPriority]="true"
                ></build-asset>
                  <p-button
                  label="See Latest Builds History"
                  target="_blank"
                  (click)="openInNewTab(getDynamicURL(assetRecord.id))"
                ></p-button>
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col-6">
                <span>
                  <strong>Created By:</strong>
                  {{ assetRecord.buildData && assetRecord.buildData.length ? assetRecord.buildData[0].user.name : '' }}
                </span>
                <br>
                <span>
                  <strong>Created At:</strong>
                  {{ assetRecord.buildData && assetRecord.buildData.length ? (assetRecord.buildData[0].insertedAt | officeTime:'medium') : '' }}
                </span>
                <br>
                <span>
                  <strong>Updated At:</strong>
                  {{ assetRecord.buildData && assetRecord.buildData.length ? (assetRecord.buildData[0].finishedAt | officeTime:'medium') : '' }}                                              </span>
              </div>
              <div class="p-col-6">
                <span>
                  <strong>Promotional Status:</strong>
                </span>
                <br>
                <span>
                  <strong>Asset Promoted Status:</strong>
                  {{ assetRecord ? isUptoDate(assetRecord)  : '' }}
                </span>
                <br>
                <span>
                  <strong>Current Asset Hash:</strong>
                  {{ assetRecord ? getLastPromotedHash(assetRecord)  : '' }}
                </span>
                <br>
                <span>
                  <strong>Last Build Hash:</strong>
                  {{ assetRecord.lastHash ? assetRecord.lastHash : '' }}
                </span>
              </div>
              <div class="p-col-12">
              </div>
              <div class="p-col-6">
                <span>
                  <strong>Promotional Status:</strong>
                </span>
                <br>
              </div>
            </div>
            <div>
              <p-accordion [multiple]="true">
                <p-accordionTab header="Asset Build Data" [selected]="true">
                  <build-data
                    [entity]="assetRecord"
                    [type]="'miscellaneous-build'"
                    [buildData]="assetRecord.buildData"
                    [buildType]="assetRecord.entityType"
                    (triggerRefreshEvent)="getUpdatedBuildData()"
                    [assetType]="assetRecord.assetType"
                  ></build-data>
                </p-accordionTab>
              </p-accordion>
            </div>
        </div>
      </div>
</p-fieldset>

