import { Component, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Table } from 'primeng/table';
import {
  FilterService,
  MessageService,
  SelectItem,
  SortEvent,
} from 'primeng/api';
import { TableDataComponent } from 'src/app/cms-v2/entities/table-data/components/table-data/table-data.component';
import { LoggerService } from 'src/app/common/services/logger.service';
import { DataService } from 'src/app/services/data.service';
import { TableService } from '../../services/table.service';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { update } from 'lodash';

@Component({
  selector: 'app-econ-table',
  templateUrl: './econ-table.component.html',
  styleUrls: ['./econ-table.component.sass'],
  
})
export class EconTableComponent extends TableDataComponent implements OnInit {
  @ViewChild('spawnerTable') table: any;
  pageTitle: string = 'Economy Table';
  categories: any;
  challenges: any;
  filtered: boolean = false;
  sortableChallenges: any = [];
  dateRange: any;
  urlQueryParams: any = {};

  constructor(
    private dataService: DataService,
    private loggerService: LoggerService,
    private filterService: FilterService,
    private tableService: TableService,
    private validationsService: ValidationsService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    super();
  }

  async ngOnInit() {
    this.isLoading = true;
    this.registerCustomFilter();
    this.challenges = await this.tableService.getTableRows('challenges', null);
    this.categories = await this.dataService.getAllOfTypeAsync('categories', {
      query: {},
      autopopulate: false,
      virtuals: false,
    });
    this.categories = await this.buildCategories();
    await this.setupSpawners();

    for (let key of ['global', 'sortBy', 'sortOrder', 'filterBy']) {
      let value = this.route.snapshot.queryParamMap.get(key);

      if (!this.validationsService.isEmpty(value)) {
        this.urlQueryParams[key] = value;
      } else {
        // set default values
        switch (key) {
          case 'sortBy':
            this.urlQueryParams[key] = 'id';
            break;
          case 'sortOrder':
            this.urlQueryParams[key] = -1;
            break;
          case 'filterBy':
            this.urlQueryParams[key] = 'none'
        }
      }
    }
    if(this.urlQueryParams['filterBy'] != 'none') {
      this.callZeroFilter(this.table);
    }
    this.loggerService.log('Data', this.sortableChallenges);
    
    

    this.isLoading = false;
  }

  /**
   * sums or averages the economy object
   * @param spawner spawner obj
   * @param mode how to reduce the obj
   * @param currency the type of currency to reduce
   * @param length the amount of objects for the average
   * @returns the reduce result
   */
   reduceEconomySpawner(spawner: any, mode: String, currency: String, length: number = 1) {
    const result =
    spawner.economyObject.reduce((accumulator: any, obj: any) => {
      
      if (currency == 'Coin') {
        return mode == 'expected' ?  accumulator + obj ? obj.expectedCoinSpend : 0 : accumulator + obj ? obj.averageCoinCost : 0
      } else {
        return mode == 'expected' ?  accumulator + obj ? obj.expectedDiamondSpend : 0 : accumulator + obj ? obj.averageDiamondCost : 0
      }
    }, 0);

    return result / length;
  }
  /**
   * Updates de URL query params on filter
   * @param event Filter Event
   */
  onFilter(event: any) {
   if(Object.keys(event.filters).includes('spawner_count.total')){
    this.urlQueryParams['filterBy'] = 'zeroes'
    this.updateURLQueryParams();
   }
  }
  /**
   * Updates the urlquery params on sort
   * @param event sort event
   */
  onSort(event: SortEvent) {
    this.urlQueryParams['sortBy'] = event.field
    this.urlQueryParams['sortOrder'] = event.order
    this.updateURLQueryParams();
    }
    /**
     * Updates the URL Query Params
     */
  updateURLQueryParams() {
    let urlQueryParams: any = {};
    for (let key of ['global', 'sortBy', 'sortOrder', 'filterBy']) {
      urlQueryParams[key] = !this.validationsService.isEmpty(
        this.urlQueryParams[key]
      )
        ? this.urlQueryParams[key]
        : null;
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: urlQueryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }
  /**
   * 
   * @param spawners spawners to aggregate
   * @param mode expected or average
   * @param currency coin or diamond
   * @returns 
   */
  reduceEconomyChallenge(spawners: any, mode: String, currency: String) {
    const result = spawners && spawners.length > 0 ?
    spawners.reduce((accumulator: any, obj: any) => {
      let avg = 0;
      avg = obj.economyObject.reduce((econAccumulator: any, econObj: any) => {
        if (currency == 'Coin') {
        return mode == 'expected' ?  econAccumulator + econObj ? econObj.expectedCoinSpend : 0 : econAccumulator + econObj ? econObj.averageCoinCost : 0 
      } else {
        return mode == 'expected' ?  econAccumulator + econObj ? econObj.expectedDiamondSpend : 0 : econAccumulator + econObj ? econObj.averageDiamondCost : 0
      }
      }, 0)
      return accumulator + avg
  }, 0) / spawners.length : 0
    return Math.floor(result)
  }
  /**
   * Builds the categories and its costs and spends
   * @returns the categories
   */
  async buildCategories() {
    var output =
    this.categories.map((category: any) => ({
        categoryName: category.name, 
        id: category.id,
        averageCoinCost: category.averageCoinCost ? category.averageCoinCost : null,
        averageDiamondCost: category.averageDiamondCost ? category.averageDiamondCost : null,
        expectedDiamondSpend: category.expectedDiamondSpend ? category.expectedDiamondSpend : null,
        expectedCoinSpend: category.expectedCoinSpend ? category.expectedCoinSpend : null,
        iconPath: category.iconPath ? category.iconPath : null
    }));
    return output;
  }
  /**
   * Setups the spawners' economy object
   */
   async setupSpawners() {
      this.challenges.forEach((challenge: any)=> {
        if(challenge.spawners && challenge.spawners.length > 0) {
          challenge.spawners.forEach((spawner: any) => {
            spawner['economyObject'] = [];
            spawner.categories.map((category: any) => {
              spawner['economyObject'].push(this.categories.find((x: any) => x.categoryName === category));
            });
          });
        }
            let newObject: any = {};
            newObject['name'] = challenge.name
            newObject['id'] = challenge.id
            newObject['expectedCoinSpend'] = this.reduceEconomyChallenge(challenge.spawners, 'expected', 'Coin');
            newObject['averageCoinCost'] = this.reduceEconomyChallenge(challenge.spawners, 'average', 'Coin');
            newObject['expectedDiamondSpend'] = this.reduceEconomyChallenge(challenge.spawners, 'expected', 'Diamond');
            newObject['averageDiamondCost'] = this.reduceEconomyChallenge(challenge.spawners, 'average', 'Diamond');
            newObject['spawner_count'] = challenge.spawners ? challenge.spawner_count : null;
            newObject['spawners'] = challenge.spawners;
            newObject['start'] = challenge.start ? new Date(challenge.start) : null;
            newObject['end'] = challenge.end ? new Date(challenge.end) : null;
            this.sortableChallenges.push(newObject);
      })
      this.challenges = this.sortableChallenges
    }
    /**
     * Clears the table and resets its state
     * @param table table to clear and reset
     */
    clear(table: Table) {
      if(this.filtered) {
        table.filters = {};
        table.clear();
        this.urlQueryParams['filterBy'] = 'none'
        this.updateURLQueryParams();
        this.filtered = !this.filtered;
      }
  }
  /**
   * Calls the filter to filter out the zeroes in the table
   * @param table the table to filter
   */
  callZeroFilter(table: Table) {
    table.filter(0, 'spawner_count.total', 'zeroes');
    if (this.filtered) {
      table.clear();
      table.filters = {};
      this.urlQueryParams['filterBy'] = 'none'
      this.updateURLQueryParams();
    }
    this.filtered = !this.filtered
  }
  /**
   * register custom filters
   */
  registerCustomFilter() {
    this.filterService.register('zeroes', (value: any, filter: string) => {
      if (filter === undefined || filter === null) {
        return true;
      }

      if (value === undefined || value === null || value.toString() === '0') {
        return false;
      }

      return true;
    })
  }
}
