import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { FieldBase } from '../../field-base';
import { FieldControlService } from '../../../services/field-control.service';
import { FieldService } from 'src/app/services/field.service';
import { LoggerService } from 'src/app/common/services/logger.service';

@Component({
  selector: 'form-array',
  templateUrl: './form-array.component.html',
  styleUrls: ['./form-array.component.sass'],
})
export class FormArrayComponent implements OnInit {
  @Input() form!: UntypedFormGroup; // the global form
  @Input() formGroup!: UntypedFormGroup; // parent FormGroup
  @Input() field!: FieldBase<string>; // this line item field

  fields: FieldBase<string>[] = []; // subFields

  get formArray() {
    return this.formGroup.get(this.field.key) as UntypedFormArray;
  }

  constructor(
    private dataService: DataService,
    private fieldService: FieldService,
    private fcs: FieldControlService,
    private loggerService: LoggerService
  ) {}

  async ngOnInit() {
    this.field.group.forEach(async (id) => {
      await this.fieldService.getFieldByIdFromJSON(id).then(async (data) => {
        this.fields.push(data);
      });
    });
  }

  addControl() {
    this.loggerService.log('add control:',this.fields);
    let newGroup = this.fcs.toFormGroup(this.fields as FieldBase<string>[]);
    this.formArray.push(newGroup);
  }

  removeControl(index: number) {
    this.formArray.removeAt(index);
  }

  removeLastControl() {
    if (this.formArray.length > 0) {
      this.formArray.removeAt(this.formArray.length - 1);
    }
  }
}
