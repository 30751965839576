<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="nurture-rarity"
    [formName]="title"
    [(record)]="nurtureRarityRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [autopopulateEntity]="true"
    [startDate]="nurtureRarityRecord.start"
    [validationCallbackFunction]="validateNurtureRarityForm.bind(this)"
>
</dynamic-form-v2>
