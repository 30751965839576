import { Component, OnInit } from '@angular/core';
import { ColDef } from "ag-grid-community";
import { PermissionsService } from './service/permissions.service';
import { AgGridToolsService } from '../common/ag-grid-table/services/ag-grid-tools.service';
import { OptionsParams, UtilitiesService } from "../common/services/utilities.service";
import { TABLE_ACTIONS } from '../common/ag-grid-table/constants';
import { ActionsCellRendererComponent } from '../common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { RefsCellRendererComponent } from '../common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { MultiselectEditorComponent } from '../common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { DropdownEditorComponent } from '../common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { TextCellRendererComponent } from '../common/ag-grid-table/cell-renderers/render-components/text/text.component';

@Component({
  selector: 'app-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.sass'],
})
export class PermissionsComponent implements OnInit {
  constructor(
    private permissionsService: PermissionsService,
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService,
  ) {}

  public entity: string = 'permissions';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public isLoading: boolean = true;
  private options: any = {};

  /**
    * Permissions Component Initialization
    */
  async ngOnInit() {
    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right',
        width: 120,
        resizable: true,
      },
      {
        headerName: 'ID',
        field: 'id',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Name',
        field: 'name',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Entity',
        field: 'entity',
        cellRenderer: TextCellRendererComponent,
        sortable: false,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.entities,
          valueFormatter: (params: any) => {
            console.log('params');
            return `${params.value.name} (${params.value.value})`;
          },
          keyCreator: (params: any) => {
            return params.value.value;
          },
          comparator: this.agGridToolsService.nameASC,
        },
        floatingFilter: true,
        width: 400,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "value",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Abilities',
        field: 'abilities',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isArray: true,
        },
        sortable: false,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.abilities,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value.value;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: false,
        },
        floatingFilter: true,
        width: 400,
        autoHeight: true,
        cellEditor: MultiselectEditorComponent,
        cellEditorParams: {
          optionValue: "value",
          optionLabel: "name",
          objectValue: false,
        },
        type: 'editableColumn',
      },
    ];

    this.bulkUpdateColDefs = [];

    this.agGridToolsService.disableTableActions([
      TABLE_ACTIONS.ACTIONS,
      TABLE_ACTIONS.NEW,
      TABLE_ACTIONS.TABLE_SEARCH,
      TABLE_ACTIONS.COLUMNS_PRESETS,
      TABLE_ACTIONS.SAVED_FILTERS,
      TABLE_ACTIONS.GRID_VIEW_SETTINGS,
      TABLE_ACTIONS.ROW_SELECTED,
      TABLE_ACTIONS.FULL_EDIT_MODE,
    ]);

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }

  async setOptions() {
    const permissionTypes = await this.permissionsService.getPermissionTypes();
    
    this.options.entities = Object.keys(permissionTypes.entities)
      .map((key) => {
        return { name: permissionTypes.entities[key], value: key };
      });
    this.options.abilities = Object.keys(permissionTypes.abilities)
      .map((key) => {
        return { name: permissionTypes.abilities[key], value: permissionTypes.abilities[key] };
      });
  }
}
