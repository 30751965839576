import { Component, Input } from '@angular/core';
import { ItemService } from '../services/item.service';

@Component({
  selector: 'app-plant-card',
  templateUrl: './plant-card.component.html',
  styleUrls: ['./plant-card.component.sass']
})
export class PlantCardComponent {
  @Input() entity: { [key: string]: any };
  @Input() plantPreview: { [key: string]: any };

  constructor(public itemService: ItemService) {}
}

