import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-boolean-cell-editor',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.sass']
})
export class BooleanEditorComponent implements ICellEditorAngularComp, AfterViewInit {

  constructor(private el: ElementRef) { }


  private params: any;
  public value: boolean;
  public originalValue: boolean;
  public fieldName: string = '';
  public onLabel: string = 'True';
  public offLabel: string = 'False';


  agInit(params: any): void {
    this.params = params;
    // console.log('Boolean params:' ,this.params);
    this.value = this.params.data[this.params.colDef.field];
    this.originalValue = this.params.data[this.params.colDef.field];
    this.fieldName = this.params.colDef.headerName;
      if (this.params.colDef.cellRendererParams) {
      this.onLabel = this.params.colDef.cellRendererParams.onLabel || this.onLabel;
      this.offLabel = this.params.colDef.cellRendererParams.offLabel || this.offLabel;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const toggleButton = this.el.nativeElement.querySelector('.p-togglebutton');
      if (toggleButton) {
        toggleButton.focus();
      }
    });
  }

  getValue(): any {
    return this.value;
  }

  isPopup(): boolean {
    return false;
  }

  setOriginalValue(){
    this.value = this.originalValue;
  }
}
