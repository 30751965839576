import { Table } from 'primeng/table';
import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-store-listing-form-v2',
  templateUrl: './store-listing-form-v2.component.html',
  styleUrls: ['./store-listing-form-v2.component.css']
})
export class StoreListingFormV2Component  extends BaseDynamicFormComponent implements OnInit
{
  StoreListingRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * StoreListing Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Store Listing Data",
        fields:
        [
          { 
            name: "Name", 
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true, 
            key: 'name', 
            isRequired: true, 
            label: 'Name', 
            disabled: true 
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.StoreListingRecord =
    {
      enabled: false
    };
    this.title = "Store Listing";
    this.viewRoute = 'store-listing';
    this.isLoading = false;
  }


}
