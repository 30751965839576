import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-challenge-type-view-v2',
  templateUrl: './challenge-type-view-v2.component.html',
  styleUrls: ['./challenge-type-view-v2.component.sass']
})
export class ChallengeTypeViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  ChallengeTypeRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Challenge Type View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Challenge Type",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "Color",inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.ColorDisplay], clearField: true, key: 'color', isRequired: true, label: 'Color', disabled: false },
          {
            name: "Misc. Image Record",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'image_ref',
            label: 'Misc. Image Record',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {fieldName:'image_ref'}
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.ChallengeTypeRecord =
    {
      enabled: false
    };
    this.title = "Challenge Type";
    this.viewRoute = 'challenge-types-v2';
    this.isLoading = false;
  }
}