import { Component, OnInit, } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import StoreListingV2DTO from '../dtos/storeListingV2DTO';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { ActivatedRoute } from '@angular/router';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';


@Component({
  selector: 'app-store-listing-v2-view',
  templateUrl: './store-listing-v2-view.component.html',
  styleUrls: ['./store-listing-v2-view.component.sass']
})
export class StoreListingV2ViewComponent extends BaseDynamicViewComponent implements OnInit {
  activeTabIndex: number = 0; // Default to the first tab
  environments = ['dev', 'qa', 'prod']; // The order of these values should match the tab order
  recordId: number;
  private options: any = {};
  storeEnvs: string[] = [];
  selectedEnvironment: Object = {label: null, value: null, default: false};
  CmsEnabled: Object = {};



  handleTitleChange(newTitle: string) {
    this.title = newTitle;
  }
  handleIdChange(newId: number) {
    this.recordId = newId;
  }

  StoreListingV2Record: StoreListingV2DTO = new StoreListingV2DTO();
  constructor(
    private route: ActivatedRoute,
    private agGridToolsService: AgGridToolsService,
    private commonEntityService: CommonEntityService,
  ) {
    super();
  }


  async ngOnInit() {
    this.initializeStoreEnvironments();
    this.loadSelectedEnvironment();

    const fetchEnv = this.agGridToolsService.getSelectedEnvironment()
    this.selectedEnvironment = fetchEnv
    console.log(this.selectedEnvironment)

    this.recordId = Number(this.route.snapshot.paramMap.get('id'));
    this.CmsEnabled = (await this.commonEntityService.findOneWithQuery('store-listings-v2', {query: {id: this.recordId}, select: 'CmsEnabled'}))?.CmsEnabled
    if (!this.recordId || this.recordId <= 0) {
      console.error('Invalid recordId');
      return;
    }


    this.fields =
      [
        {
          title: "Store Listing Data",
          fields:
            [
              {
                name: "Listing ID",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'name',
                isRequired: true,
                label: 'Listing ID',
                columnWidth: 12
              },
              {
                name: "Price Point",
                inputType: DynamicViewType.MiscRef,
                clearField: true,
                key: 'pricePoint_ref',
                label: 'Price Point',
                controller: 'price-points',
                columnWidth: 12
              },
              {
                name: "Display Details",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Title],
                key: '',
                label: 'Display Details',
                columnWidth: 12,
                tagType: 'h2' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
              },
              {
                name: "Sale Label",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'saleLabel',
                isRequired: true,
                label: 'Sale Label',
                columnWidth: 12
              },
              {
                name: "Image",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Title],
                key: '',
                label: 'Image',
                columnWidth: 12,
                tagType: 'h4' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
              },
              {
                name: "Image",
                inputType: DynamicViewType.MiscImageRecordV2,
                clearField: true,
                key: 'image_ref',
                label: 'Image',
                optionValue: '_id',
                filter: true,
                filterBy: 'name,id,path',
                autoDisplayFirst: false,
                options: {
                  fieldName: 'image_ref', apiController: 'miscellaneous-build',
                  buildAndRender: false,
                },
                columnWidth: 12
              },
              {
                name: "Display Name",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'displayName',
                isRequired: true,
                label: 'Display Name',
                columnWidth: 12
              },
              {
                name: "Description",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'description',
                isRequired: true,
                label: 'Description',
                columnWidth: 12
              },
              {
                name: "Price Label",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'priceLabel',
                isRequired: true,
                label: 'Price Label',
                columnWidth: 12
              },
              {
                name: "Start Date/Time",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Calendar],
                clearField: true,
                key: 'storeStart',
                label: 'Start Date/Time',
                columnWidth: 6
              },
              {
                name: "End Date/Time",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Calendar],
                clearField: true,
                key: 'storeEnd',
                isRequired: true,
                label: 'End Date/Time',
                columnWidth: 6,
              },
              {
                name: "Use StoreFront End Time?",
                inputType: DynamicViewType.Boolean,
                clearField: true,
                key: 'useStoreFrontEndTime',
                label: 'Use StoreFront End Time?',
                columnWidth: 12
              },
              {
                name: "Use Tile UI?",
                inputType: DynamicViewType.Boolean,
                clearField: true,
                key: 'useTileUI',
                label: 'Use Tile UI?',
                columnWidth: 12
              },
              {
                name: "Display Subtitle",
                inputType: DynamicViewType.Boolean,
                clearField: true,
                key: 'displaySubtitle',
                label: 'Display Subtitle',
                columnWidth: 12
              },
              {
                name: "Background Hex",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.ColorDisplay],
                clearField: false,
                key: 'backgroundHex',
                label: 'Background Hex',
                columnWidth: 12
              },
              {
                name: "Price Point Text",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'pricePointText',
                isRequired: true,
                label: 'Price Point Text',
                columnWidth: 12
              },
              {
                name: "Minimum Level",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'minimumLevel',
                isRequired: true,
                label: 'Minimum Level',
                columnWidth: 12
              },
              {
                name: "Analytics Bucket?",
                inputType: DynamicViewType.Boolean,
                clearField: true,
                key: 'analyticsBucket',
                label: 'Analytics Bucket?',
                columnWidth: 6
              },
              {
                name: "Optional Data",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Title],
                key: '',
                label: 'Optional Data',
                columnWidth: 12,
                tagType: 'h2' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
              },
              {
                name: "Original StoreListing Name?",
                inputType: DynamicViewType.Boolean,
                clearField: true,
                key: 'originalStoreListingName',
                label: 'Original StoreListing Name?',
                columnWidth: 6
              },
              {
                name: "Fallback StoreListing Name?",
                inputType: DynamicViewType.Boolean,
                clearField: true,
                key: 'fallbackStoreListingName',
                label: 'Fallback StoreListing Name?',
                columnWidth: 6
              },
              {
                name: "Secondary StoreListing Name?",
                inputType: DynamicViewType.Boolean,
                clearField: true,
                key: 'secondaryStoreListingName',
                label: 'Secondary StoreListing Name?',
                columnWidth: 6
              },
              {
                name: "Customer Limit",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'customerLimit',
                isRequired: true,
                label: 'Customer Limit',
                columnWidth: 6
              },
              {
                name: "Tags",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'tags',
                isRequired: true,
                label: 'Tags',
                columnWidth: 8
              },
              {
                name: "Customer Limit Reset Fixed",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'CustomerLimitResetFixed',
                isRequired: true,
                label: 'Customer Limit Reset Fixed',
                columnWidth: 6
              },
              {
                name: "Customer Limit Reset Hours",
                inputType: DynamicViewType.BaseViewField,
                viewTypes: [BaseViewFieldType.Text],
                clearField: true,
                key: 'CustomerLimitResetHours',
                isRequired: true,
                label: 'Customer Limit Reset Hours',
                columnWidth: 6
              },
            ],
          type: DynamicCardType.Card
        },
        {
          title: 'Credit(s)',
          type: DynamicCardType.Card,
          fields: [
            {
              name: "Credit(s)", 
              inputType: DynamicViewType.AgGridTable,
              key: 'credits_ref',
              label: 'Credit(s)',
              options: {
                fieldName: 'Credit(s)',
                columnDefs: [
                  {
                    headerName: 'ID',
                    field: 'id',
                    width: 125,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    sortable: true,
                    initialSort: 'desc',
                    sortIndex: 0,
                    initialSortIndex: 0,
                    sort: 'desc',
                    sortingOrder: ['asc', 'desc'],
                    unSortIcon: false,
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                      filterOptions: [
                        'equals',
                        'notEqual',
                        'lessThan',
                        'lessThanOrEqual',
                        'greaterThan',
                        'greaterThanOrEqual',
                        'inRange'
                      ]
                    },
                    resizable: true,
                    floatingFilter: true,
                    suppressSizeToFit: true,
                  },
                  {
                    headerName: 'Type',
                    field: 'type_ref',
                    cellRenderer: RefsCellRendererComponent,
                    cellRendererParams: {
                      entity: 'item-types'
                    },
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: this.options.type_ref,
                      valueFormatter: (params: any) => {
                        return `${params.value.name} (${params.value.id})`;
                      },
                      keyCreator: (params: any) => {
                        return params.value._id;
                      },
                      comparator: this.agGridToolsService.nameASC
                    },
                    floatingFilter: true,

                    cellEditor: DropdownEditorComponent,
                    cellEditorParams: {
                      optionValue: "_id",
                      optionLabel: "name",
                      objectValue: true
                    },
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Name',
                    field: 'name',
                    cellRendererParams: {
                      entity: 'items',
                      isMultiple: true,
                      entities: ['currencies', 'seed-pack-box', 'items']
                    },
                    cellRenderer: LinkCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    width: 350,
                    resizable: true,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                    cellClass: 'center-cell-content',
                    cellEditor: 'agTextCellEditor',
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Enabled',
                    field: 'enabled',
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      onLabel: 'Enabled',
                      offLabel: 'Not Enabled',
                      isBoolean: true
                    },
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: [true, false],
                      valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                    },
                    floatingFilter: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'Thumbnail',
                    field: 'thumbnail_img',
                    sortable: false,
                    resizable: true,
                    width: 135,
                    autoHeight: true,
                    cellRenderer: ThumbnailCellRendererComponent,
                    valueGetter: (params: any) => {
                      return params.data.thumbnail_ref.thumbnails && params.data.thumbnail_ref.thumbnails[1] ?
                        params.data.thumbnail_ref.thumbnails[1].path :
                        'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                    },

                  },
                  {
                    headerName: 'Start',
                    field: 'start',
                    sortable: true,
                    unSortIcon: true,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    suppressSizeToFit: true,
                    width: 300,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      isDate: true,
                    },
                    valueGetter: this.agGridToolsService.dateValueGetter('start'),
                    resizable: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'End',
                    field: 'end',
                    sortable: true,
                    unSortIcon: true,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    suppressSizeToFit: true,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      isDate: true,
                      timePill: true
                    },
                    width: 300,
                    valueGetter: this.agGridToolsService.dateValueGetter('end'),
                    resizable: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'Content Status',
                    field: 'itemStatus',
                    cellRenderer: TextCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: ['Approved', 'Awaiting Revision', 'QA Ready'],
                      addEmptyFilters: true,
                    },
                    floatingFilter: true,

                    cellEditor: DropdownEditorComponent,
                    cellEditorParams: {
                      isFlatList: true
                    },
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Art Status',
                    field: 'vendorStatus',
                    cellRenderer: TextCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: ['Assigned', 'In Revision', 'Approved'],
                      addEmptyFilters: true,
                    },
                    floatingFilter: true,

                    cellEditor: DropdownEditorComponent,
                    cellEditorParams: {
                      isFlatList: true
                    },
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Promotion Status',
                    field: 'isAssetUpToDate',
                    cellRenderer: TextCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    valueGetter: (params: any) => {
                      const lastHash = params.data.prefab_ref?.lastHash;
                      const versions = params.data.prefab_ref?.asset_versions;
                      const lastVersion = versions ? versions[versions.length - 1] : null;

                      let response = 'No data';

                      if (!lastHash && versions && versions.length > 0) {
                        return 'Outdated';
                      }

                      if (lastHash && versions && versions.length > 0) {
                        response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
                      }
                      return response;
                    },
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: [
                        'No data', 'Outdated', 'Up to date'
                      ],
                      addEmptyFilters: true,
                      isAggregate: true,
                      filterKey: 'prefab_ref',
                      isAssetUpToDate: true,
                    },
                    floatingFilter: true,

                  },
                ],
                isMultiple: true,
                defaultQueryKey: 'credits_ref',
                entity: 'items'
              }
            },
          ]
        },
      ]

    this.sidebarFields = [
      {
        title: "Images",
        fields:
          [
            {
              name: "Images",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Text],
              key: '',
              label: 'Images',
              columnWidth: 12,
              tagType: 'h1' // Can be 'h1', 'h2', 'h3', 'h4', 'h5', 'p', 'small', etc.
            },
            {
              name: "Image",
              inputType: DynamicViewType.sideThumbnail,
              key: 'image_ref',
              label: 'Image',
              options: { gridWidth: 12, fieldName: '' },
              columnWidth: 12
            },
          ],
        type: DynamicCardType.Card
      }
    ]

    this.StoreListingV2Record.enabled = false;


    this.title = "Store Listing";
    this.viewRoute = 'store-listings-v2';
    this.isLoading = false;

  }

  initializeStoreEnvironments(): void {
    const currentUrl = window.location.href;

    if (currentUrl.includes('prod.cms')) {
      // Prod CMS: DEV, QA, PROD
      this.storeEnvs = ['dev', 'qa', 'prod'];

    } else if (currentUrl.includes('test.cms')) {
      // Test CMS: TEST
      this.storeEnvs = ['test'];
    } else {
      // local/Dev CMS: TEST, DEV, QA, PROD
      this.storeEnvs = ['test', 'dev', 'qa', 'prod'];
    }
    this.environments = this.storeEnvs;
  }

  loadSelectedEnvironment(): void {
    const savedEnvironment = localStorage.getItem('selectedEnvironment');
    if (savedEnvironment) {
      const environmentObject = JSON.parse(savedEnvironment);
      this.activeTabIndex = this.environments.indexOf(environmentObject.value);
    }
  }

  onTabChange(event: { index: number; }): void {

    this.activeTabIndex = event.index;
    const environmentToSave = {
      label: this.environments[this.activeTabIndex].charAt(0).toUpperCase() + this.environments[this.activeTabIndex].slice(1),
      value: this.environments[this.activeTabIndex]
    };
    localStorage.setItem('selectedEnvironment', JSON.stringify(environmentToSave));
  }

  // STORE RELATED FUNCTIONS:
  async addToCart() {
    this.agGridToolsService.addToCart('store-listings', [this.recordId])
  }
}
