<div id="maincard">
    <div id="headerBody">
      <div class="p-mb-2" id="headerT">
        {{ entity.name ? entity.name : "Commune nomen" }}
      </div>
      <div class="p-mb-2" id="subheaderT">
        {{ entity.cultivar ? entity.cultivar : "Iterum conare" }}
      </div>
    </div>
    <div id="contentBody">
      <div class="p-mb-2">
        <div class="p-d-flex p-jc-center">
          <img
            id="image1"
            [src]="entity['thumbnail_url_1024']"
            (error)="itemService.setDefaultPic()"
          />
        </div>
      </div>
      <div class="p-d-flex">
        <div class="p-mr-2">
          <div class="p-d-flex p-flex-column" id="text1">
            <div class="p-mb-2" id="contentTitle">
              <div id="rowText1">Latin Name</div>
            </div>
            <div class="p-mb-2" id="contentTitle">
              <div id="rowText1">Category</div>
            </div>
            <div class="p-mb-2" id="contentTitle">
              <div id="rowText1">Type</div>
            </div>
            <div class="p-mb-2" id="contentTitle">
              <div id="rowText1">Climates</div>
            </div>
            <div class="p-mb-2" id="contentTitle">
              <div id="rowText1">Color</div>
            </div>
            <div class="p-mb-2" id="contentTitle">
              <div id="rowText1">Traits</div>
            </div>
          </div>
        </div>
        <div class="p-mr-2">
          <div class="p-d-flex p-flex-column" id="text1">
            <div class="p-mb-2" id="contentText">
              <div id="rowText1">
                {{ entity.latinName ? entity.latinName : "🌼 Not Filled" }}
              </div>
            </div>
            <div class="p-mb-2" id="contentText">
              <div id="rowText1">
                {{ plantPreview.category ? plantPreview.category : "🌼 Not Filled" }}
              </div>
            </div>
            <div class="p-mb-2" id="contentText">
              <div id="rowText1">
                {{ plantPreview.type ? plantPreview.type : "🌼 Not Filled" }}
              </div>
            </div>
            <div class="p-mb-2" id="contentText">
              <div id="rowText1">
                {{ plantPreview.climates ? plantPreview.climates.names : "🌼 Not Filled" }}
              </div>
            </div>
            <div class="p-mb-2" id="contentText">
              <div id="rowText1">
                {{ plantPreview.colors.names ? plantPreview.colors.names : "🌼 Not Filled" }}
              </div>
            </div>
            <div class="p-mb-2" id="contentText">
              <div id="rowText1">
                {{ plantPreview.traits.names ? plantPreview.traits.names : "🌼 Not Filled" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-d-flex p-flex-column">
        <div class="p-mb-2">
          <div id="AboutTitle">About this Plant</div>
        </div>
        <div class="p-mb-2">
          <div class="p-d-flex p-jc-center">
            <img
              id="image2"
              [src]="entity['spruce_img']"
              alt="🥀 Sorry No Image Found"
            />
          </div>
        </div>
        <div class="p-mb-2">
          <div class="p-d-flex">
            <div class="p-mr-2">
              <div class="p-d-flex p-flex-column" id="text1">
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText2">Typical Size</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText2">Hardiness Zone</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText2">Native Area</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText2">Sun Exposure</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText2">Bloom Time</div>
                </div>
              </div>
            </div>
            <div class="p-mr-2">
              <div class="p-d-flex p-flex-column" id="text1">
                <div class="p-mb-2" id="contentText">
                  <div id="rowText2">
                    {{ plantPreview.size ? plantPreview.size : "🌼 Not Filled" }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText2">
                    {{ plantPreview.hardinessZones ? plantPreview.hardinessZones : "🌼 Not Filled" }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText2">
                    {{ plantPreview.nativeArea ? plantPreview.nativeArea : "🌼 Not Filled" }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText2">
                    {{ plantPreview.sunExposure ? plantPreview.sunExposure : "🌼 Not Filled" }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText2">
                    {{ plantPreview.bloomTime ? plantPreview.bloomTime : "🌼 Not Filled" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-flex-column">
          <div class="p-mb-2">
            <div class="p-text-left">
              {{ entity.blurb ? entity.blurb : "Description goes here ...." }}
            </div>
          </div>
          <div class="p-mb-2">
            <button id="contentButton">Read more on the Spruce</button>
          </div>
        </div>
      </div>
    </div>
    <div id="footerBody">
      <div class="p-d-flex p-flex-column p-flex-md-row">
        <div class="p-mb-2 p-mr-2" id="costSection">
          <img
            id="gold"
            src="https://d3tfb94dc03jqa.cloudfront.net/images/currency/currency_coin_fly_up.png"
          />
          {{ plantPreview.cost ? plantPreview.cost : " Empty" }}
        </div>
        <div class="p-mb-2 p-mr-2">
          <button id="footerButton">Buy in game</button>
        </div>
      </div>
    </div>
  </div>  
