import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Inbox Message DTO
 */
export default class InboxMessageDto extends BaseEntityDto
{
    /**
     * Flag that sets whether or not to use Segment
     */
    useSegment: boolean = false;

    /**
     * Flag that sets whether or not to use Segment
     */
    showBuyCta: boolean = false;

    /**
     * Inbox Message headline
     */
    headline: string = "";
    /**
     * Inbox Message Title
     */
    title: string = "";
    /**
     * Inbox Message Type
     */
    type: string = "";
    /**
     * Inbox Message Layout
     */
    layout: string = "";
    /**
     * Id of the product
     */
    productId: string = "";
    /**
     * Inbox Time to live value
     */
    timeToLive: number;
    /**
     * Inbox Message body
     */
    body: string = "";
    /**
     * Misc. Record Image Reference
     */
    image_ref: any;
    /**
     * Links reference
     */
    link_ref: any;
    /**
     * List of environments
     */
    sendReady: Array<string> = [];
    /**
     * List of Rewards
     */
    rewards_ref: Array<any> = [];
    /**
     * Minimum user level value
     */
    minimumUserLevel: number;
}
