import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { ReleaseFieldType } from 'src/app/cms-v2/entities/release-type/components/dtos/ReleaseTypeField';
import { DataService } from 'src/app/services/data.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import RewardDTO from 'src/app/common/dtos/RewardDTO';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'release-custom-field',
  templateUrl: './release-custom-field.component.html',
  styleUrls: ['./release-custom-field.component.sass']
})
export class ReleaseCustomFieldComponent implements OnInit 
{
  @Input() fields: any;
  @Input() record: any;
  @Input() isEdit: boolean = false;
  @Input() isLoading: boolean = true;
  @Output() onChange = new EventEmitter<any>();
  @Input() options: any = [];
  @Input() seriesName: any;
  suggestions: Array<any> = [];
  prizes45: Array<RewardDTO> = [];
  prizes55: Array<RewardDTO> = [];
  prizes35: Array<RewardDTO> = [];
  selectedPrizes: Array<string> = ['4/5'];
  selectedOption: any = null;
  stateOptions: any = [];
  sizeOptions: any = [];
  columnOptions: any = [];

  get fieldType(): typeof ReleaseFieldType
    {
        return ReleaseFieldType;
    }

  constructor(
    private messageSerivce: MessageService,
    private dataService: DataService,
    private utilitiesService: UtilitiesService,
    private spinnerService: SpinnerService
  ) {}

  /**
   * Release Type Custom Field Component Initialization
   */
  async ngOnInit() {
    this.spinnerService.loadSpinner();
    if(this.isEdit) {
      this.prizes45 = this.record.prizes_ref && this.record.prizes_ref.length > 0 ? this.record.prizes_ref[0] : []
      this.record.collection_ref = this.record.collection_ref ? this.record.collection_ref._id : null
      this.record.announcement_image = this.record.announcement_image ? this.record.announcement_image._id : null
      this.record.inboxMessages_ref = this.record.inboxMessages_ref ? this.record.inboxMessages_ref.map(({ _id, id, name }: {_id: string, id: number, name: string}) => ({_id, name, id})) : null;

      console.log(this.record)
    }
    await this.setOptions();
    this.isLoading = false;
    this.spinnerService.endSpinner();
  }

  /**
   * Handle Record Changes from child component.
   *
   * @param field Field that changed.
   * @param event Event value.
   */
  onRecordChange(event: any)
  {
    this.onChange.emit(this.fields);
  }

  /**
   * Get suggestions for auto-complete
   * 
   * @param event Event comming from the component
   * @param fieldName Name of the field to set suggestions
   * @param model API endpoint route 
   */
  async getSuggestionsForRef
  (
    event: any,
    fieldName: string | any,
    model: string
  ) {
    this.dataService.getAllOfType(model, 
    {
      query: isNaN(event) ? { name: { $regex: event, $options: 'i' } } : { id: event },
      select: '_id name id',
      virtuals: false,
      autopopulate: false,
      sort: { name: 1 },
    })
    .subscribe((result) => 
    {
      this.suggestions[fieldName] = result;
    });
  }
  /**
   * Handle changes comming from prize component
   * 
   * @param prizeType The type of the prize (3/5, 4/5, 5/5)
   */
  onPrizeChange(event: any)
  {
    console.log(event)
    this.record.prizes_ref[0] = event
  }

  /**
   * Set options for dropdowns and auto-completes
   */
  async setOptions() 
  {
    await this.utilitiesService.getOptionsFromRef(this.options, 'image_ref', 'miscellaneous-build', false, false, '', false, null, { entityType: 3 });
    await this.utilitiesService.getOptionsFromRef(this.options, 'collection_ref', 'collections', false, true);
  }

  /**
   * Handle Loading Screen ref changes
   *
   * @param event List of selected loading screens
   */
  onRefsChange(refName: string, event: any)
  {
    switch (refName)
    {
      case 'loading-screens':
        this.record.loadingScreen_ref = event;
        break;
      case 'inbox-messages':
        this.record.inboxMessages_ref = event;
        break;
    }
  }
}