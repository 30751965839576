<div class="p-m-5">
    <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
      <div>
        <p-fieldset class="p-formgroup-inline">
          <ng-template pTemplate="header">
            <div class="p-formgroup-inline">
              <span class="p-my-auto p-mr-2">Rule</span>
              <button
                pButton
                icon="pi pi-plus p-panel-header-icon"
                class="p-button-rounded p-button-text p-button"
                (click)="addRule()"
                type="button"
              ></button>
            </div>
          </ng-template>
          <span class="p-formgroup-inline">
            <div
              *ngFor="let rule of restrictionRecord.rules_ref; let pointIndex = index"
            >
              <p-card class="p-formgroup p-m-2 p-shadow-4">
                <ng-template pTemplate="header">
                  <button
                    pButton
                    type="button"
                    icon="pi pi-times"
                    class="
                      p-button-rounded p-button-text p-button-danger
                      float-right
                    "
                    (click)="deleteRule(pointIndex)"
                  ></button>
                </ng-template>

                <ng-template pTemplate="content">
                  <div class="p-m-5">
                    <span
                      [style]="{ 'min-width': '16.5em' }"
                      ngClass="p-float-label"
                    >
                      <p-dropdown
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="rule.filterModel"
                        [options]="options['filterModel']"
                        optionLabel="name"
                        dataKey="model"
                        [showClear]="false"
                        [autoDisplayFirst]="false"
                        [filter]="true"
                        [filterBy]="'name'"
                        [style]="{ 'min-width': '12.5em' }"
                        (onChange)="updateDependentField(
                          $event,
                          pointIndex); rule.filter_ref = null"
                      ></p-dropdown>
                      <label for="filterModel"> Filter </label>
                    </span>
                  </div>
                  <div class="p-m-5">
                    <span
                      [style]="{ 'min-width': '16.5em' }"
                      ngClass="p-float-label"
                    >
                      <p-autoComplete
                        [suggestions]="suggestions['filter_ref']"
                        [ngModelOptions]="{ standalone: true }"
                        [(ngModel)]="rule.filter_ref"
                        (onChange)="onChange($event, pointIndex)"
                        [showEmptyMessage]="true"
                        (completeMethod)="
                          getSuggestionsForRef(
                            $event.query,
                            'filter_ref',
                            restrictionRecord.rules_ref[pointIndex].filterModel.tableName
                          )
                        "
                        field="name"
                        minLength="3"
                      >
                        <ng-template let-item pTemplate="selectedItem">
                          {{ item.name }} ({{ item.id }})
                        </ng-template>

                        <ng-template let-item pTemplate="item">
                          {{ item.name }} ({{ item.id }})
                        </ng-template>
                      </p-autoComplete>
                      <label for="filter_ref"> Search By Name or ID </label>
                    </span>
                  </div>
                </ng-template>
              </p-card>
            </div>
          </span>
        </p-fieldset>
      </div>
    </span>
  </div>