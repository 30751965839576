import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'lineBreak'
})
export class LineBreakPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeHtml {
    // Replace line breaks with <br> tags
    const replacedValue = value.replace(/\n/g, '<br>');
    // Bypass security trust for direct HTML manipulation
    return this.sanitizer.bypassSecurityTrustHtml(replacedValue);
  }
}
