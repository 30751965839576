import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-entity-display',
  templateUrl: './entity-display.component.html',
  styleUrls: ['./entity-display.component.sass']
})
export class EntityDisplayComponent {
  @Input() entity: { name: string, id: number };
  @Input() type: string;

  constructor() { }
}
