<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="achievements"
    [viewName]="title"
    [record]="achievementRecord"
    [fields]="fields"
    [sidebarFields]="sidebarFields"
    [miscAssetKeys]="miscAssetKeys"
    [showLocalizedValuesButton]="true"
    [allowDuplicate]="true"
    [viewRoute]="'achievements'"
>
</dynamic-view-v2>