export const ENTITY_FIELD_CONFIGURATIONS: { [key: string]: Array<{ key: string; type: string, label: string, controller?: string, isStoreValue?: boolean, required?: boolean }> } = {
  challenges: [

    { key: "name", type: "nameInput", label: "Name", required: true },
    { key: "scene", type: "text", label: "Scene Path", required: true },
    { key: "image", type: "text", label: "Image Path", required: true },
    { key: "fileName", type: "text", label: "File Name", required: true },
    { key: "climate_ref", type: "single_ref", label: "Climate", controller: "climates" },
    { key: "year", type: "text", label: "Year" },
    { key: "keywords_ref", type: "multiselect_ref", label: "Keywords", controller: "challenges-keywords" },
    { key: "type_ref", type: "single_ref", label: "Challenge Type", controller: "challenge-types" },
    { key: "description", type: "text", label: "Description" },
    { key: "descriptionShort", type: "text", label: "Short Description" },
    { key: "internalNotes", type: "text", label: "Internal Notes" },
    { key: "location", type: "text", label: "Location" },
    { key: "progressionLevel_ref", type: "single_ref", label: "Level Lock", controller: "progression-levels" },
    { key: "sceneType", type: "single_ref", label: "Scene Type", controller: "settings" },
    { key: "tester_ref", type: "single_ref", label: "Tester", controller: "users" },
    { key: "artist_ref", type: "single_ref", label: "Artist", controller: "users" },
    { key: "restrictions_ref", type: "multiselect_ref", label: "Restrictions", controller: "restrictions" },
    { key: "contentHold_ref", type: "multiselect_ref", label: "Content Hold", controller: "settings" },
    { key: "bundleAsset", type: "boolean", label: "Bundle Asset (IOS)" },
    { key: "bundleImage", type: "boolean", label: "Bundle Image (IOS)" },
    { key: "bundleAssetAnd", type: "boolean", label: "Bundle Asset (AND)" },
    { key: "bundleImageAnd", type: "boolean", label: "Bundle Image (AND)" },
    { key: "spawnAudios_ref", type: "multiselect_ref", label: "Spawn Audios", controller: "miscellaneous-build" },
    { key: "loopAudios_ref", type: "multiselect_ref", label: "Loop Audios", controller: "miscellaneous-build" },
    { key: "spawnAudioCollections_ref", type: "multiselect_ref", label: "Spawn Audio Collection", controller: "audio-collections" },
    { key: "loopAudioCollections_ref", type: "multiselect_ref", label: "Loop Audio Collection", controller: "audio-collections" },
  ],
  climates: [
    { key: "name", type: "text", label: "Name" },
    { key: "spawnAudios_ref", type: "multiselect_ref", label: "Spawn Audio", controller: "miscellaneous-build" },
    { key: "spawnAudioCollections_ref", type: "multiselect_ref", label: "Spawn Audio Collection", controller: "audio-collections" },
    { key: "loopAudios_ref", type: "multiselect_ref", label: "Loop Audios", controller: "miscellaneous-build" },
    { key: "loopAudioCollections_ref", type: "multiselect_ref", label: "Loop Audio Collection", controller: "audio-collections" },
    { key: "enabled", type: "boolean", label: "Enabled" },
    { key: "env", type: "multiselect", label: "Env(s)" },
    { key: "start", type: "date", label: "Start" },
    { key: "end", type: "date", label: "End" },
  ],
  'price-points': [
    { key: "name", type: "nameInput", label: "Name" },
    { key: "nonConsumable", type: "boolean", label: "Non Consumable", isStoreValue: true },
    { key: "costs_ref", type: "costs", label: "Cost(s)", isStoreValue: true },
    { key: "tags", type: "text", label: "Tag(s)", isStoreValue: true },
    { key: "GoogleCheckoutPetunia", type: "text", label: "Google Checkout Playgami", isStoreValue: true },
    { key: "AppleAppStorePetunia", type: "text", label: "Apple App Store Playgami", isStoreValue: true },
    { key: "PlaygamiPetunia", type: "text", label: "Petunia Playgami", isStoreValue: true },
  ],
  'store-listings-v2': [
    { key: "name", type: "nameInput", label: "Name" },
    { key: "displayName", type: "text", label: "Display Name", isStoreValue: true },
    { key: "storeStart", type: "date", label: "Start", isStoreValue: true },
    { key: "storeEnd", type: "date", label: "End", isStoreValue: true },
    { key: "credits_ref", type: "costs", label: "Credit(s)", isStoreValue: true },
    { key: "image_ref", isStoreValue: true, type: "multiselect_ref", label: "Image", controller: "miscellaneous-build" },
    { key: "saleLabel", type: "text", label: "Display Name", isStoreValue: true },
    { key: "description", type: "text", label: "Display Name", isStoreValue: true },
    { key: "pricePoint_ref", type: "single_ref", label: "Price Point", isStoreValue: true, controller: "price-points" },
    { key: "priceLabel", type: "text", label: "Display Name", isStoreValue: true },
    { key: "pricePointText", type: "text", label: "Display Name", isStoreValue: true },
    { key: "useStoreFrontEndTime", type: "boolean", label: "Enabled", isStoreValue: true },
    { key: "useTileUI", type: "boolean", label: "Enabled", isStoreValue: true },
    { key: "displaySubtitle", type: "text", label: "Display Name", isStoreValue: true },
    { key: "backgroundHex", type: "text", label: "Display Name", isStoreValue: true },
    { key: "minimumLevel", type: "text", label: "Display Name", isStoreValue: true },
    { key: "analyticsBucket", type: "text", label: "Display Name", isStoreValue: true },
    { key: "originalStoreListingName", type: "text", label: "Display Name", isStoreValue: true },
    { key: "fallbackStoreListingName", type: "text", label: "Display Name", isStoreValue: true },
    { key: "secondaryStoreListingName", type: "text", label: "Display Name", isStoreValue: true },
    { key: "customerLimit", type: "text", label: "Display Name", isStoreValue: true },
    { key: "tags", type: "text", label: "Tag(s)", isStoreValue: true },
    { key: "CustomerLimitResetFixed", type: "boolean", label: "Enabled", isStoreValue: true },
    { key: "CustomerLimitResetHours", type: "text", label: "Display Name", isStoreValue: true },
  ]
  // Add other entities as needed
};
