import { Component, OnInit, ViewChild } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { DataService } from 'src/app/services/data.service';
import { ActivatedRoute } from '@angular/router';
import { LoggerService } from 'src/app/common/services/logger.service';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { UtilitiesService, OptionsParams } from 'src/app/common/services/utilities.service';
import { TABLE_ACTIONS } from 'src/app/common/ag-grid-table/constants';


@Component({
  selector: 'app-build-gamedata-v3',
  templateUrl: './build-gamedata-v3.component.html',
  styleUrls: ['./build-gamedata-v3.component.sass']
})
export class BuildGamedataV3Component implements OnInit {
  @ViewChild('agGrid') agGrid: AgGridAngular;
  public entity: string = 'gamedatalogs';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public history: any;
  public isLoading: boolean = true;
  public displayModal: boolean = false;
  public development: boolean = false;
  public qa: boolean = false;
  public buildType: boolean = false;
  public localization: boolean = false;
  public english: boolean = false;
  public spanish: boolean = false;
  public french: boolean = false;
  public selectAll: boolean = false;
  public challenges: boolean = false;
  public assets: boolean = false;
  public items: boolean = false;
  public params: any;
  public gamedataDiff: any;
  private options: any = {};

  constructor(
    private agGridToolsService: AgGridToolsService,
    private router: ActivatedRoute,
    private utilitiesService: UtilitiesService,
    private dataService: DataService  
  ) { }

  async ngOnInit(): Promise<void> {
    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right',
        width: 120,
        resizable: true,
        cellRendererParams: {
          enableDuplicateFeature: false
        }
      },
      {
        headerName: 'ID',
        field: 'id',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc', 'desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: false,
      },
      {
        headerName: 'Triggered At',
        field: 'createdAt',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear'],
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('createdAt'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
        width: 300,
        autoHeight: true,
        cellStyle: {
          padding: '10px',
          whiteSpace: 'normal',
          lineHeight: '1.5',
        },
        cellClass: 'custom-cell-style',
      },
      {
        headerName: 'Built By',
        field: 'createdBy',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'users',
          isArray: false
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.users_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,
        autoHeight: true,
      },
      {
        headerName: 'DEV Version',
        field: 'buildVersion',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        valueGetter: (params) => {
          if (params.data && params.data.devGamedata) {
            return params.data.devGamedata?.buildVersion?.substring(0, 8);
          }
          return null;
        }
      },
      {
        headerName: 'DEV Status',
        field: 'buildVersion',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        valueGetter: (params) => {
          if (params.data && params.data.devGamedata) {
            return params.data.devGamedata.status;
          }
          return null;
        }
      },
      {
        headerName: 'DEV Message',
        field: 'buildVersion',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
        valueGetter: (params) => {
          if (params.data && params.data.devGamedata) {
            const message = params.data.devGamedata.message;
            return typeof message === 'string' ? message : (typeof message === 'object' ? undefined : message);
          }
          return undefined;
        }
      },
      {
        headerName: 'QA Version',
        field: 'buildVersion',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        valueGetter: (params) => {
          if (params.data && params.data.qaGamedata) {
            return params.data.qaGamedata?.buildVersion?.substring(0, 8);;
          }
          return null;
        }
      },
      {
        headerName: 'QA Status',
        field: 'buildVersion',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        valueGetter: (params) => {
          if (params.data && params.data.qaGamedata) {
            return params.data.qaGamedata.status;
          }
          return null;
        }
      },
      {
        headerName: 'QA Message',
        field: 'buildVersion',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
        valueGetter: (params) => {
          if (params.data && params.data.qaGamedata) {
            const message = params.data.qaGamedata.message;
            return typeof message === 'string' ? message : (typeof message === 'object' ? undefined : message);
          }
          return undefined;
        }
      },
      {
        headerName: 'Finished',
        field: 'finish',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Is Consecutive',
        field: 'isConsecutive',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Version',
        field: 'version',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
        filterParams: {
          filterOptions: ['equals'],
          defaultOption: 'equals',
          applyButton: true,
          clearButton: true,
          filterValue: '3'
        }
      },
      {
        headerName: 'Job Id',
        field: 'jobId',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
      },
      {
        headerName: 'Time Taken',
        field: 'timeTaken',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
        autoHeight: true,
        cellStyle: { 'white-space': 'normal' },
        valueFormatter: (params) => {
          const timeTaken = params.value;
          if (timeTaken !== null && timeTaken !== undefined) {
            const minutes = Math.floor(timeTaken);
            const seconds = Math.round((timeTaken - minutes) * 60);
            return `${minutes}m ${seconds}s`;
          }
          return '';
        },
      },
      {
        headerName: 'Previous Build Time',
        field: 'previousBuildTime',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear'],
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('createdAt'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
        width: 300,
        autoHeight: true,
        cellStyle: {
          padding: '10px',
          whiteSpace: 'normal',
          lineHeight: '1.5',
        },
        cellClass: 'custom-cell-style',
      },
      {
        headerName: 'Build Time',
        field: 'buildTime',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear'],
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('createdAt'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
        width: 300,
        autoHeight: true,
        cellStyle: {
          padding: '10px',
          whiteSpace: 'normal',
          lineHeight: '1.5',
        },
        cellClass: 'custom-cell-style',
      },
    ]

    this.bulkUpdateColDefs = [];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.agGridToolsService.disableTableActions([
      TABLE_ACTIONS.ACTIONS,
      TABLE_ACTIONS.NEW,
      TABLE_ACTIONS.TABLE_SEARCH,
      TABLE_ACTIONS.COLUMNS_PRESETS,
      TABLE_ACTIONS.SAVED_FILTERS,
      TABLE_ACTIONS.GRID_VIEW_SETTINGS,
      TABLE_ACTIONS.ROW_SELECTED,
      TABLE_ACTIONS.FULL_EDIT_MODE,
    ]);

    this.router.queryParams.subscribe(params => {
      if (params.id) {
        this.params = params.id;
      }
    });
  }

  async onDataUpdated(): Promise<void> {
    this.isLoading = true;
  
    try {
      const updatedData = await this.dataService.getAllOfTypeAsync('gamedatalogs', {});
      
      if (updatedData) {
        this.buildParams = updatedData;
        
        if (this.agGrid && this.agGrid.api) {
          setTimeout(() => {
            this.agGrid.api.refreshServerSide({ purge: true });  // Forzar la recarga de los datos del servidor
          }, 0);
        }
      }
    } catch (error) {
    } finally {
      this.isLoading = false;
    }
  }

  showModal() {
    this.displayModal = true;
  }

  setBuildType(type: boolean) {
    this.buildType = type;
  }

  async setOptions() {
    const entities = [
      { ref: 'users_ref', entity: 'users', select: 'name id _id', query: { } },
    ];

    // Using the new approach
    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }

  }
}
