import { Component, Input } from '@angular/core';
import { ItemService } from '../services/item.service';


@Component({
  selector: 'app-secondary-status-display',
  templateUrl: './secondary-status-display.component.html',
  styleUrls: ['./secondary-status-display.component.sass']
})
export class SecondaryStatusDisplayComponent {
  @Input() secondaryStatusGroup: { name: string, key: string, controlType: string }[];
  @Input() entity: { [key: string]: any };

  constructor(public itemService: ItemService) {}
}

