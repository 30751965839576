import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-build-status',
  templateUrl: './build-status.component.html',
  styleUrls: ['./build-status.component.sass']
})
export class BuildStatusComponent implements OnInit {
  @Input() record: any; 
  @Input() field: any;

  constructor() { }

  ngOnInit(): void {
  }

}
