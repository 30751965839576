import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import TitlesDTO from '../../dtos/TitlesDTO';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-titles-form-v2',
  templateUrl: './titles-form-v2.component.html',
  styleUrls: ['./titles-form-v2.component.sass']
})
export class TitlesFormV2Component extends BaseDynamicFormComponent implements OnInit
{
  batchRecord: TitlesDTO = new TitlesDTO();
  constructor()
  {
    super();
  }

  /**
   * Titles Forms V2 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Titles Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name'
          },
          {
            name: "Misc. Image Record Ref.",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'image_ref',
            label: 'Misc. Image Record Ref.',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            scrollHeight: '200px',
            options: { fieldName: 'image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: { $in: [3, 5] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true }
          },
          {
            name: "Description",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputTextArea],
            clearField: true,
            key: 'description',
            isRequired: false,
            label: 'Description'
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Bundle Images and Assets",
        fields:
        [
          {
            name: "Bundle Images (IOS)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            clearField: false,
            key: 'bundleImage',
            isRequired: false,
          },
          {
            name: "Bundle Images (AND)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            clearField: false,
            key: 'bundleImageAnd',
            isRequired: false,
          },
        ],
        type: DynamicCardType.Card,
        columns: 2
      }
    ]

    this.title = "Titles";
    this.viewRoute = 'titles';
    this.isLoading = false;
  }
}
