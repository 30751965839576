<div class="side-menu">
  <ul class="menu-list">
    <ng-container *ngFor="let section of sideMenuItems">
      <li class="menu-section">
        <span class="p-menuitem-text">{{ section.label }}</span>
        <ul>
          <ng-container *ngFor="let item of section.items">
            <!-- Collapsible Menus -->
            <li *ngIf="item.type === 'collapsible'" class="menu-item">
              <button
                type="button"
                class="p-button p-button-text p-mb-1"
                style="width: 100%; text-align: left;"
                (click)="item.expanded = !item.expanded"
              >
                <span
                  class="pi"
                  [ngClass]="item.expanded ? 'pi-chevron-down' : 'pi-chevron-right'"
                  style="margin-right: 8px;"
                ></span>
                {{ item.label }}
              </button>
              <ul *ngIf="item.expanded" class="sub-menu">
                <li *ngFor="let sub of item.subMenu" class="menu-item">
                  <a
                    [routerLink]="sub.route"
                    class="p-button p-button-text p-mb-1"
                    style="width: 100%; text-align: left;"
                  >
                    {{ sub.label }}
                  </a>
                </li>
              </ul>
            </li>

            <!-- Single Menus -->
            <li *ngIf="item.type !== 'collapsible'" class="menu-item">
              <button
                *ngIf="item.action"
                type="button"
                class="p-button p-button-text p-mb-1"
                style="width: 100%; text-align: left;"
                (click)="handleAction(item.action)"
              >
                <span [class]="item.icon" style="margin-right: 8px;"></span>
                {{ item.label }}
              </button>
              <a
                *ngIf="item.route"
                [routerLink]="item.route"
                class="p-button p-button-text p-mb-1"
                style="width: 100%; text-align: left;"
              >
                <span [class]="item.icon" style="margin-right: 8px;"></span>
                {{ item.label }}
              </a>
            </li>
          </ng-container>
        </ul>
      </li>
    </ng-container>
  </ul>
</div>
