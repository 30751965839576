import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { BuildStatusCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/build-status/build-status.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { ColorTextComponent } from 'src/app/common/ag-grid-table/cell-editors/color-text/color-text.component';


@Component({
  selector: 'app-currency-table-ag-grid',
  templateUrl: './currency-table-ag-grid.component.html',
  styleUrls: ['./currency-table-ag-grid.component.sass']
})
export class CurrencyTableAgGridComponent implements OnInit {

  public entity: string = 'currencies';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc', 'desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Internal Name',
        field: 'internalName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        pinned: 'right', // Pin the column to the right
        width: 120, // Set the width of the column
        resizable: true,
      },
      {
        headerName: 'Name Single',
        field: 'name',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Start',
        field: 'start',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        width: 300,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('start'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn'
      },
      {
        headerName: 'End',
        field: 'end',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        width: 300,
        valueGetter: this.agGridToolsService.dateValueGetter('end'),
        resizable: true,

        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRendererParams: {
          entity: this.entity,
          isEnv: true
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['dev', 'qa', 'prod', 'test'],
          addEmptyFilters: true,
        },
        floatingFilter: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          valueObjects: true,
          isFlatList: true
        },
      },
      {
        headerName: 'Thumbnail',
        field: 'thumbnails',
        sortable: false,
        resizable: true,
        width: 135,
        autoHeight: true,
        cellRenderer: ThumbnailCellRendererComponent,
        valueGetter: params => {
          const thumbnailPath = params.data.thumbnail_ref?.thumbnails?.[1]?.path;
          const imagePath = params.data.image_ref?.thumbnails?.[1]?.path;
          return thumbnailPath || imagePath || 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
        }
      },
      {
        headerName: 'Year',
        field: 'year',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            '2021', '2022', '2023', '2024', '2025'
          ],
          addEmptyFilters: true,
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          isFlatList: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Name Plural',
        field: 'namePlural',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Image Preview',
        field: 'imagePreview',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Icon',
        field: 'icon',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Sprite Icon',
        field: 'spriteIcon',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Misc. Image Record',
        field: 'image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'image_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.image_ref,
          populate: true,
          select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Image Build Status',
        field: 'imageBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'image_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'image_ref',
          isBuildStatus: true
        },
        floatingFilter: true,

      },
      {
        headerName: 'Image Small',
        field: 'imageSmall_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'imageSmall_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmall_ref,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Image Small Build Status',
        field: 'imageSmallBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'imageSmall_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'imageSmall_ref',
          isBuildStatus: true
        },
        floatingFilter: true,

      },
      {
        headerName: 'Image Small Amount',
        field: 'imageSmallAmount',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Image Medium',
        field: 'imageMedium_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'imageMedium_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmallAmount,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Image Medium Build Status',
        field: 'imageMediumBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'imageMedium_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'imageMedium_ref',
          isBuildStatus: true
        },
        floatingFilter: true,

      },
      {
        headerName: 'Image Medium Amount',
        field: 'imageMediumAmount',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Image Large',
        field: 'imageLarge_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'imageLarge_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmallAmount,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Image Large Build Status',
        field: 'imageLargeBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'imageLarge_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'imageLarge_ref',
          isBuildStatus: true
        },
        floatingFilter: true,

      },
      {
        headerName: 'Image Large Amount',
        field: 'imageLargeAmount',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Image 4',
        field: 'image_4_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'image_4_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmallAmount,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Image 4 Amount',
        field: 'image_4Amount',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Image 5',
        field: 'image_5_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'image_5_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmallAmount,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Image 5 Amount',
        field: 'image_5Amount',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Image 6',
        field: 'image_6_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'image_6_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmallAmount,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Image 6 Amount',
        field: 'image_6Amount',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Thumbnail',
        field: 'thumbnail_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'thumbnail_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmallAmount,
          populate: true,
          select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Thumbnail Build Status',
        field: 'imageThumbnailBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'thumbnail_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'thumbnail_ref',
          isBuildStatus: true
        },
        floatingFilter: true,

      },
      {
        headerName: 'Image Prize Button',
        field: 'imageChallengePrizeButton_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'imageChallengePrizeButton_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmallAmount,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Challenge Prize Button Build Status',
        field: 'imageChallengePrizeButtonBuildStatus',
        cellRenderer: BuildStatusCellRendererComponent,
        cellRendererParams: {
          key: 'prefab_ref'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Queued', 'Building', 'Finished', 'Partial Fail', 'Full Fail', '(EMPTY)'],
          addEmptyFilters: true,
          isAggregate: true,
          filterKey: 'prefab_ref',
          isBuildStatus: true
        },
        floatingFilter: true,

      },
      {
        headerName: 'Bundle Image (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Bundle Image (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Bundle',
          offLabel: 'Not Bundle',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Bundle' : 'Not Bundle'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Cap',
        field: 'cap',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Soft Capped',
        field: 'softCapped',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      },
      {
        headerName: 'Hard Capped',
        field: 'hardCapped',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        cellEditorPopupPosition: 'under'
      }, {
        headerName: 'Image Prize Button',
        field: 'imageChallengePrizeButton_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'imageChallengePrizeButton_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.imageSmallAmount,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Fly Up Animation',
        field: 'flyUpAnimation_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterKey: 'flyUpAnimation_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.flyUpAnimation_ref,
          populate: true,
          select: 'id name _id path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Fly Up Animation Color',
        field: 'flyUpColor',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorPicker: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: ColorTextComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      imageKeys: ['image_ref', 'imageSmall_ref', 'imageMedium_ref', 'imageLarge_ref', 'image_4_ref', 'image_5_ref', 'image_6_ref', 'thumbnail_ref', 'imageChallengePrizeButton_ref', 'flyUpAnimation_ref'],
      imageBuildType: BuildType.Images
    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'currencies',
    }


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions() {
    const entities = [
      {
        ref: 'image_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'imageSmall_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'imageSmallAmount',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'imageMedium_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'imageMediumAmount',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'imageLarge_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'imageLargeAmount',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'image_4_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'image_4Amount',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'image_5_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'image_5Amount',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'image_6_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'image_6Amount',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'thumbnail_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'imageChallengePrizeButton_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      },
      {
        ref: 'flyUpAnimation_ref',
        entity: 'miscellaneous-build',
        select: 'name id _id path',
        query: { assetType: { $in: [AssetTypes.Miscellaneous] } }
      }
    ];


    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }

  }

}
