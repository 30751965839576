import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicViewV2 } from 'src/app/common/components/dynamic-view-v2/dynamic-view-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { GettersService } from 'src/app/common/services/getters.service';
import AssetDTO from '../dtos/AssetDTO';
import { BuildService } from 'src/app/build-data/services/build.service';
import * as _ from 'lodash';
import { BaseViewFieldType } from '../../dynamic-view-v2/enums/BaseViewFieldType';



@Component({
  selector: 'app-asset-view-v2',
  templateUrl: './asset-view-v2.component.html',
  styleUrls: ['./asset-view-v2.component.sass']
})
export class AssetViewV2Component  extends BaseDynamicViewComponent implements OnInit

{

  @ViewChild(DynamicViewV2) dynamicViewComponent: DynamicViewV2;
  assetRecord: AssetDTO = new AssetDTO();
  customBuildDataKeys: Array<string> = ['imageBuildData'];
  entityTypes: any[];
  bundleTypes: any[];
  constructor(
    private gettersService: GettersService,
    private buildService: BuildService
  )
  {
    super();
  }
  miscAssetKeys: Array<any> = ['logo_ref', 'images_ref']

  /**
   * Asset View V2 Initial Implementation
   */
  async ngOnInit()
  {
    this.entityTypes = await this.gettersService.getEntityTypeSettings();
    this.bundleTypes = await this.gettersService.getBundleTypeSettings();
    this.fields =
    [
      {
        title: "Asset Data",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name', disabled: false },
          { name: "ID", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'id', label: 'ID', disabled: false },
          { name: "Entity Type", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'entityTypeText', isRequired: true, label: 'Entity Type', disabled: false },
          { name: "Bundle Type", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'assetTypeText', isRequired: true, label: 'Bundle Type', disabled: false },
          { name: "Is Localized", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'localized', label: 'Is Localized', disabled: false },
          { name: "Path", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'path', label: 'Path', disabled: false },
          { name: "Is Asset up to date", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'isAssetUpToDate', label: 'Is Asset up to date', disabled: false },
          { name: "Asset Current Version", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'lastHash', label: 'Asset Current Version', disabled: false },
          { name: "Asset Last Build Version", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'lastBuilt', label: 'Asset Last Build Version', disabled: false },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Build Tools",
        fields: [
          {
            name: "Build Tools",
            inputType: DynamicViewType.MiscImageBuildComponent,
            clearField: true,
            key: 'self',
            label: 'Build Tools',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: '', apiController: 'miscellaneous-build',
              buildAndRender: true,
              type: 'self',
            },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Development Data",
        fields:
        [
          { name: "S3 Path", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 's3Path', isRequired: true, label: 'S3 Path', disabled: false },
          { name: "Files", inputType: DynamicViewType.NestedList, clearField: true, key: 'files', isRequired: true, label: 'Files', disabled: false },
          { name: "Thumbnails", inputType: DynamicViewType.NestedList, clearField: true, key: 'thumbnails', isRequired: true, label: 'Thumbnails', disabled: false },
          // { name: "Last (3) Asset Versions", inputType: DynamicViewType.NestedList, clearField: true, key: 'lastAssetVersions', isRequired: true, label: 'Last (3) Asset Versions', disabled: false },
          { name: "Updated At", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'updatedAt', isRequired: true, label: 'Updated At', disabled: false },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Extra Data",
        fields:
        [
          { name: "Dim X (meters)", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'dimensionX', isRequired: true, label: 'Dim X (meters)', disabled: false },
          { name: "Dim Y (meters)", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'dimensionY', isRequired: true, label: 'Dim Y (meters)', disabled: false },
          { name: "Dim Z (meters)", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'dimensionZ', isRequired: true, label: 'Dim Z (meters)', disabled: false },
          { name: "Radius", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'radius', isRequired: true, label: 'Radius (meters)', disabled: false },

        ],
        type: DynamicCardType.Card
      },
      {
        title: "Build History",
        fields:[],
        type: DynamicCardType.BuildHistory
      },
    ]

    this.sidebarFields = [
      {
        title: "Video Preview",
        fields:
        [
          { name: "Video Preview",
          inputType: DynamicViewType.VideoPreview,
          key: 'path',
          label: 'Video/Audio Preview',
          columnWidth: 12
          }
        ],
        type: DynamicCardType.Card
      },
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.title = "Asset";
    this.viewRoute = 'asset';
    this.isLoading = false;
  }

  /**
   * Parse Record Data
   *
   * @param record Record value.
   */
  async prepareRecordData(record: any)
  {
    // console.log('prepare data:', record);
    // This is just an example of how to use the prepareRecordData event method.
    // In this case, we are cloning the record and appending a period to the name.
    // This is just a simple change/data transformation that can be done to the record,
    // but we should be able to do more complex data transformations here.
    this.assetRecord = _.cloneDeep(record);

    if(this.assetRecord.image_versions){
      this.assetRecord.lastBuilt = this.getLastElementPath(this.assetRecord.image_versions);
    } else if (this.assetRecord.asset_versions){
      this.assetRecord.lastBuilt = this.getLastElementPath(this.assetRecord.asset_versions);
    } else {
      this.assetRecord.lastBuilt = 'no data';
    }
    //
    this.assetRecord.isAssetUpToDate = this.assetRecord.lastBuilt == this.assetRecord.lastHash;
    //
    this.assetRecord.entityTypeText = await this.findObjectNameByValue(this.entityTypes, this.assetRecord.entityType);
    this.assetRecord.assetTypeText =  await this.findObjectNameByValue(this.bundleTypes, this.assetRecord.assetType);
    //
    this.getBuildData();
    //
    this.dynamicViewComponent.isLoading = false;
    this.dynamicViewComponent.spinnerService.endSpinner();
  }

  getLastElementPath(array: any[]) {
    if (!Array.isArray(array) || array.length === 0) {
      return null;
    }

    const lastElement = array[array.length - 1];
    return lastElement.destinationPath.replace("prod/", "");;
  }

  findObjectNameByValue(list: any[], targetValue: any): string {
    const foundObject = list.find(obj => obj.value == targetValue);

    return foundObject ? foundObject.name : 'undefined';
  }

  /**
   * Retrieves the build data for the entity.
   */
  async getBuildData()
  {
    if(this.assetRecord && this.assetRecord.buildData)
    {
      let response = await this.buildService.getBuildData(this.assetRecord.buildData);
      console.log('build data response:', response);
      this.assetRecord.buildData = response.buildData;
      this.assetRecord.buildStatus = response.buildStatus;
    }
    else
    {
      this.assetRecord.buildData = [];
      this.assetRecord.buildStatus = {
        text: 'Build Status Not Found',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }
  }
}
