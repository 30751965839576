import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Batch DTO
 */
export default class BatchDTO extends BaseEntityDto
{
    /**
     * Batch number
     */
    batchNumber: string;
    /**
     * Vendor reference
     */
    vendor_ref: any;
    /**
     * List of Items tied to the batch
     */
    items_ref: Array<any> = [];
    /**
     * Year of the batch
     */
    year: string;
    /**
     * Content Status
     */
    contentStatus: string;
    /**
     * Type of the asset
     */
    assetType: string;
    /**
     * Status of the batch
     */
    batchStatus: string;
    /**
     * Status of the vendor
     */
    vendorStatus: string;
}