import { Component, EventEmitter, Output } from '@angular/core';
import { ItemSetService } from 'src/app/cms-v2/entities/item-set/item-set-service/item-set.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';

type SelectionType = 'selectedColors' | 'selectedPatterns' | 'selectedMaterials' | 'selectedStyles';

@Component({
  selector: 'app-item-set-generator-popup',
  templateUrl: './item-set-generator-popup.component.html',
  styleUrls: ['./item-set-generator-popup.component.sass'],
  providers: [MessageService]
})
export class ItemSetGeneratorPopupComponent {
  @Output() itemsGenerated = new EventEmitter<any[]>();
  isModalOpen = false;
  name = '';
  selectedColors: any[] = [];
  selectedPatterns: any[] = [];
  selectedMaterials: any[] = [];
  selectedStyles: any[] = [];
  showSpinner = false;
  outputCount = 5; 
  assetTypes = {
    hsRecolor: false,
    hardSurface: false,
    organics: false,
    organicRecolors: false
  };

  colors = [
    { label: 'Bronze', value: 'Bronze' },
    { label: 'Yellow', value: 'Yellow' },
    { label: 'White', value: 'White' },
    { label: 'Turquoise', value: 'Turquoise' },
    { label: 'Silver', value: 'Silver' },
    { label: 'Red', value: 'Red' },
    { label: 'Purple', value: 'Purple' },
    { label: 'Pink', value: 'Pink' },
    { label: 'Metallic', value: 'Metallic' },
    { label: 'Orange', value: 'Orange' },
    { label: 'Grey', value: 'Grey' },
    { label: 'Green', value: 'Green' },
    { label: 'Gold', value: 'Gold' },
    { label: 'Copper', value: 'Copper' },
    { label: 'Brown', value: 'Brown' },
    { label: 'Blue', value: 'Blue' },
    { label: 'Black', value: 'Black' },
    { label: 'Beige', value: 'Beige' },
  ];

  patterns = [
    { label: 'Ombre', value: 'Ombre' },
    { label: 'Mosaic', value: 'Mosaic' },
    { label: 'Wicker', value: 'Wicker' },
    { label: 'Weathered', value: 'Weathered' },
    { label: 'Striped', value: 'Striped' },
    { label: 'Plaid', value: 'Plaid' },
    { label: 'Geometric', value: 'Geometric' },
    { label: 'Floral', value: 'Floral' },
    { label: 'Motif', value: 'Motif' },
    { label: 'Chevron', value: 'Chevron' },
    { label: 'Animal Print', value: 'Animal Print' },
    { label: 'Antique', value: 'Antique' },
  ];

  materials = [
    { label: 'Fabric', value: 'Fabric' },
    { label: 'Foliage', value: 'Foliage' },
    { label: 'Ceramic', value: 'Ceramic' },
    { label: 'Wood', value: 'Wood' },
    { label: 'Stone', value: 'Stone' },
    { label: 'Rattan', value: 'Rattan' },
    { label: 'Poly', value: 'Poly' },
    { label: 'Natural', value: 'Natural' },
    { label: 'Metal', value: 'Metal' },
    { label: 'Marble', value: 'Marble' },
    { label: 'Glass', value: 'Glass' },
    { label: 'Concrete', value: 'Concrete' },
  ];

  styles = [
    { label: 'Tropical', value: 'Tropical' },
    { label: 'Traditional', value: 'Traditional' },
    { label: 'Farmhouse', value: 'Farmhouse' },
    { label: 'Modern', value: 'Modern' },
    { label: 'Mediterranean', value: 'Mediterranean' },
    { label: 'Industrial', value: 'Industrial' },
    { label: 'Eclectic', value: 'Eclectic' },
    { label: 'Contemporary', value: 'Contemporary' },
    { label: 'Coastal', value: 'Coastal' },
  ];

  constructor(
    private itemSetService: ItemSetService,
    private spinnerService: NgxSpinnerService,
    private messageService: MessageService
  ) {}

  openModal(): void {
    this.isModalOpen = true;
  }

  closeModal(): void {
    this.isModalOpen = false;
  }

  onSelectChange(type: SelectionType): void {
    const selectedValues = this[type];

    if (selectedValues.length > 1 && selectedValues.includes(null)) {
      this[type] = selectedValues.filter(value => value !== null);
    }
  }

  async generate(): Promise<void> {
    this.showSpinner = true;
    this.spinnerService.show();

    const payload = {
      attributes: {
        Name: this.name || '',
        "Color(s)": this.selectedColors.join(', ') || '',
        "Pattern(s)": this.selectedPatterns.join(', ') || '',
        "Material(s)": this.selectedMaterials.join(', ') || '',
        "Style(s)": this.selectedStyles.join(', ') || '',
        "Asset Type": this.assetTypes.hsRecolor ? 'HS Recolor' : this.assetTypes.hardSurface ? 'Hard Surface' : this.assetTypes.organics ? 'Organics' : this.assetTypes.organicRecolors ? 'Organic Recolors' : ''
      },
      output_count: this.outputCount || 5 
    };

    console.log('Payload:', payload);

    try {
      const result = await this.itemSetService.findSimilarItems(payload);
      console.log('Result:', result);

      const generatedItems = result.items.map((item: any) => ({ ...item, isGenerated: true }));

      this.itemsGenerated.emit(generatedItems);
      this.messageService.add({
        sticky: true,
        severity: 'success',
        summary: 'Set Generated',
        detail: 'Item set generated successfully!',
      });
    } catch (error: any) {
      console.error('Error:', error);
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Generation Failed',
        detail: error instanceof Error ? error.message : 'Unable to generate a set with those matches.',
      });
    } finally {
      this.showSpinner = false;
      this.spinnerService.hide();
      this.closeModal();
    }
  }

  removeSelectedItem(type: SelectionType, item: any): void {
    this[type] = this[type].filter(selected => selected !== item);
  }

  clearSelection(type: SelectionType): void {
    this[type] = [];
  }

  markAsTouched(type: SelectionType): void {
  }
}
