<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="gamedataLogs"
    [viewName]="title"
    [record]="Gamedatav3Record"
    [fields]="fields"
    [hideBuildHistoryButton]="true"  

>
</dynamic-view-v2>
