import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';

@Component({
  selector: 'app-thumbnail-cell-renderer',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.css']
})
export class ThumbnailCellRendererComponent implements ICellRendererAngularComp {

  constructor(
    private tableCommunicationService: TableCommunicationService
  ) {

  }

  public params: any;
  timestamp = new Date().getTime();

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

  onThumbnailClick() {
    const event = {
      type: 'showImage',
      imageUrl: this.params.value.replace("_256", "_1024"),
      imageTitle: this.params.data.name,
      tableIdentifier: this.params.tableIdentifier || null,
    };
    this.tableCommunicationService.emitEvent(event);
  }

  getImageStyle(): string {
    if(this.params.width){
      return `
        width: ${this.params.width}px;
        height: 100%; max-height: 300px;
        max-width: 300px; object-fit: contain;
        border-radius: 10px`;
    } else {
      return `
        width: 100%; height: 100%;
        max-height: 300px; max-width: 300px;
        object-fit: contain; border-radius: 10px`;
    }
  }


    calculateAspectRatioHeight(): string {
      if (this.params && this.params.column && this.params.column.actualWidth) {
        const height = this.params.column.actualWidth;
        return `${height}px`;
      }
      return '100%';
    }

}
