<p-dialog class="createNewAssetDialog" [(visible)]="isVisible" [style]="{width: '65vw'}" (onHide)="onHideEmitter()" (onShow)="onShow()">
  <ng-template pTemplate="header">
    <h3>{{ isEdit ? 'Edit' :'Add New'}} Localization String</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="p-grid p-mx-5">
      <div class="p-col-12 p-inputgroup" *ngIf="this.type == 'localization/client-strings' && !isEdit">
        <span ngClass="p-float-label">
          <input [(ngModel)]="entity._recordId" pInputText class="field-item" />
          <label for="entity.Key">Key <strong style="color: crimson">*</strong></label>
        </span>
      </div>
      <div class="p-col-12 p-inputgroup">
        <span ngClass="p-float-label">
          <input [(ngModel)]="entity.Source_en" pInputText class="field-item" />
          <label for="entity.Source_en">English <strong style="color: crimson">*</strong></label>
        </span>
      </div>
      <ng-container *ngIf="!isEdit">
      <div class="p-col-12 p-inputgroup">
        <span ngClass="p-float-label">
          <input [(ngModel)]="entity.Target_frFR" pInputText class="field-item" />
          <label for="entity.Target_frFR">French</label>
        </span>
      </div>
      <div class="p-col-12 p-inputgroup">
        <span ngClass="p-float-label">
          <input [(ngModel)]="entity.Target_esMX" pInputText class="field-item" />
          <label for="entity.Target_esMX">Spanish</label>
        </span>
      </div>
      <div class="p-col-12 p-inputgroup">
        <span ngClass="p-float-label">
          <input [(ngModel)]="entity.Target_deDE" pInputText class="field-item" />
          <label for="entity.Target_deDE">German</label>
        </span>
      </div>
      <div class="p-col-12 p-inputgroup">
        <span ngClass="p-float-label">
          <p-toggleButton
            onLabel="Verified" offLabel="Not Verified" onIcon="fa-solid fa-language" offIcon="fa-solid fa-xmark" [(ngModel)]="entity.verified"
          >
          </p-toggleButton>
        </span>
      </div>
    </ng-container>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="p-grid p-justify-end">
      <button pButton type="button" label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
      <button pButton type="button" label="Submit" icon="pi pi-check" class="p-button-text" (click)="submitForm()"></button>
    </div>
  </ng-template>
</p-dialog>
