<div style="width: auto;">
  <!-- User's Filters listbox -->
  <div style="display: flex; align-items: center;" class="p-mb-2 p-p-2">
    <p-button label="Clear Filters" icon="pi pi-filter-slash" styleClass="p-button-sm" (click)="clearFilters()"></p-button>
  </div>
  <div class="custom-filters">
    <div style="display: flex; align-items: center;">
      <h4 class="p-mx-2">
        Saved Filters
      </h4>
      <p-button icon="pi pi-plus" (click)="startAddingFilter()" styleClass="p-button-rounded p-button-text"></p-button>
    </div>

    <!-- Input for saving a filter -->
    <div *ngIf="isAddingFilter">
      <input type="text" pInputText [(ngModel)]="newFilterName" placeholder="Filter name" class="p-m-1" style="width: 60%;">
      <p-button
        icon="pi pi-save" styleClass="p-button-rounded p-button-text p-button-success p-button-sm"
        (click)="saveCustomFilter()"
      >
      </p-button>
      <p-button
        icon="pi pi-times" styleClass="p-button-rounded p-button-text p-button-danger p-button-sm"
        (click)="cancelAddingFilter()"
      >
      </p-button>
    </div>

    <p-listbox [options]="customFilters" [(ngModel)]="selectedFilter" optionLabel="name" (onChange)="applyFilter()" [filter]="true">
      <ng-template let-option pTemplate="item">
        <div class="list-item">
          {{option.name}}
          <!-- Info Button -->
          <button
            pButton type="button" icon="pi pi-info-circle" class="p-mr-2 p-button-rounded p-button-info p-button-sm p-button-text"
            (click)="toggleExpand(option); $event.stopPropagation();"
          >
          </button>
          <!-- Trash Button -->
          <button
            pButton type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-sm p-button-text"
            (click)="deleteCustomFilter(option); $event.stopPropagation();"
          >
          </button>
          <!-- Collapsible Section -->
          <div *ngIf="expandedFilter === option">
            <ul>
              <li *ngFor="let filter of option.filters | keyvalue">
                <strong>
                  {{ filter.key }}
                </strong>
                {{filter.filterType ? ': '+filter.filterType : "" }}
              </li>
            </ul>
          </div>
        </div>
      </ng-template>
    </p-listbox>

  </div>

  <!-- CMS Users/community filters -->
  <div class="cms-filters">
    <div style="display: flex; align-items: center;">
      <h4 class="p-mx-2">
        CMS Users Filters
      </h4>
      <p-button icon="pi pi-sync" (click)="syncCmsFilters()" styleClass="p-button-rounded p-button-text"></p-button>
    </div>
    <p-listbox [options]="communityFilters" [(ngModel)]="selectedFilter" optionLabel="name" (onChange)="applyFilter()" [filter]="true">
      <ng-template let-option pTemplate="item">
        <div class="list-item">{{option.name}}</div>
      </ng-template>
    </p-listbox>
  </div>

</div>
