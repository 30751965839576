import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

// import RewardDTO from '../../dtos/RewardDTO';

@Component({
  selector: 'loading-screen-images',
  templateUrl: './loading-screen-images.component.html',
  styleUrls: ['./loading-screen-images.component.sass']
})
export class LoadingScreenImagesComponent implements OnInit
{
  @Input() images: Array<any> = [];
  @Output() onImagesChange = new EventEmitter<any>();
  isLoading: boolean = true;
  options: any = { };
  suggestions: Array<any> = [];
  year: string;
  pathPrefix: string;
  defaultDate: any;

  constructor
  (
    private dataService: DataService,
    private entityViewService: EntityViewService,
    private utilitiesService: UtilitiesService
  ) { }

  /**
   * Prize Card Form Group Component Initialization
   */
  async ngOnInit()
  {
    if(!this.images)
    {
      this.images = [];
      this.onImagesChange.emit(this.images);
    }
    this.defaultDate = this.utilitiesService.getCurrentDateAtMidnight()
    console.log('images: ',this.images);

    await this.setOptions();
    this.isLoading = false;
  }

  /**
   * Set options for dropdowns and auto-completes
   */
  async setOptions()
  {

  }



  async addImage()
  {
    //checks for year dropdown value if it doesn't exist assigns current year to the path
    this.pathPrefix = this.year != null ? (await this.setPathPrefix()).replace('[yyyy]', this.year) :
    (await this.setPathPrefix()).replace('[yyyy]', new Date().getFullYear());
    this.images.push(
      {
        n: '',
        t: 'Filled',
        p: this.pathPrefix,
      }
    );
    this.onImagesChange.emit(this.images);
  }

  deleteImage(index: number) {
    this.images.splice(index,1);
    this.onImagesChange.emit(this.images);
  }

  /**
   * Retrieves path prefix for loading screens
   */
  async setPathPrefix()
  {
    let response = await this.entityViewService.getEntity('ImagerySettings', { query: { entityType: 'loadingScreens'} });

    return response && response.enabled && response.path && response.path.length > 0 ? response.path : '';
  }
}
