<div *ngIf="record[field.key]" class="p-grid">
    <div *ngFor="let column of record[field.key]; let i = index" class="p-col-6">
      <div class="p-col-12">
        <strong>Stage</strong> {{ i+1 }}
      </div>
      <div class="p-col-12">
        <a
      href="/nurture-stage/{{ column.id }}"
      target="_blank"
      [ngStyle]="{ color: 'var(--primary-color)' }"
      >{{column.name}}({{column.id}})</a>
      </div>
      <div *ngIf="record['costs_ref']" class="p-col-12">
        <strong>Cost:</strong>
        <ul>
          <li *ngFor="let item of record['costs_ref'][i]"><a *ngIf="record['costs_ref'][i]" [ngStyle]="{ color: 'var(--primary-color)' }" href="/currencies/{{ item.id.id }}">{{item.id ? item.id.name : ''}}({{item.c}})</a></li>
      </ul>
      </div>
      <div class="p-col-12">
        <strong>Transition Video:</strong> {{record['stages_ref'][i].transitionVideo_ref ? record['stages_ref'][i].transitionVideo_ref.path : ''}}
      </div>
      <div class="p-col-12">
        <strong>Stage Video:</strong> {{record['stages_ref'][i].stageVideo_ref ? record['stages_ref'][i].stageVideo_ref.path : ''}}
      </div>
      <div class="p-col-12">
        <strong>Thumbnail Name:</strong> {{record['stages_ref'][i].image_name}}
      </div>
      <div class="p-col-12">
        <strong>Thumbnail Path:</strong> {{record['stages_ref'][i].image_path}}
      </div>
    </div>
</div>
