import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';

@Component({
  selector: 'app-progression-level-table-ag-grid',
  templateUrl: './progression-level-table-ag-grid.component.html',
  styleUrls: ['./progression-level-table-ag-grid.component.sass']
})
export class ProgressionLevelTableAgGridComponent implements OnInit {

  public entity: string = 'progression-levels';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Design Value',
        field: 'designValue',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Reward(s)',
        field: 'rewards_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        width: 500,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Item',
              filterParams: {
                title: 'Item',
                buttons: ['clear'],
                values: this.options.items_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name} (${params.value.id})`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                },
                comparator: this.agGridToolsService.nameASC,
                isSingleRefFilter: true,
                addEmptyFilters: true
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images,

    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'progressionlevels',
    }


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions(){
    const entities = [
      { ref: 'resources_ref', entity: 'resources' },
      { ref: 'currencies_ref', entity: 'currencies' },
      { ref: 'items_ref', entity: 'items' },
      { ref: 'titles_ref', entity: 'titles' }
    ];

    for (const { ref, entity } of entities) {
      const params = new OptionsParams({
        entity
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }
  }

}
