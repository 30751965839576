import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Challenge DTO
 */
export default class challengeDTO extends BaseEntityDto {
    name: any;
    start: any;
    end: any;
    voteStart: any;
    voteEnd: any;
    enabled: any;
    bypassChecked: any;
    bundleAsset: any;
    bundleImage: any;
    bundleAssetAnd: any;
    bundleImageAnd: any;
    scene: any;
    image: any;
    fileName: any;
    climate_ref: any;
    collection_ref: any;
    type_ref: any;
    typeColor: any;
    description: any;
    year: any;
    keywords_ref: any = [];
    feedImageStatus: any;
    internalNotes: any;
    productionNotes: any;
    descriptionShort: any;
    progressionLevel_ref: any;
    location: any;
    sceneType: any;
    tester_ref: any;
    artist_ref: any;
    sourced_by: any;
    restrictions_ref: any;
    feedImage: any;
    prizes_ref: any = [];
    rewards_ref: any = [];
    env: any = [];
    fileType: any;
    [key: string]: string | number | boolean;
    contentHold_ref: any = [];
    spawnAudio_ref: any;
    loopAudio_ref: any;
    sponsor_ref: any;
}
