import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import RewardDTO from '../../dtos/RewardDTO';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'prizes-card-form-group',
  templateUrl: './prizes-card-form-group.component.html',
  styleUrls: ['./prizes-card-form-group.component.sass']
})
export class PrizesCardFormGroupComponent implements OnInit {
  @Input() prize: Array<RewardDTO> = [];
  @Input() showPrize: boolean = true;
  @Input() fieldsetName: string = "";
  @Input() excludeResourceTypes: Array<string> = [];
  @Input() useOptionValue: boolean = false;
  @Input() isCostField: boolean = false;
  @Input() isChallengePrize: boolean = false;
  @Input() isChallenge: boolean = false;
  @Output() onPrizeChange = new EventEmitter<any>();
  @Output() DeletedIndex = new EventEmitter<any>();
  @Output() onChallengePrizeChange = new EventEmitter<any>();
  @Input() version: number = 2;
  @Input() defaultResourceType: keyof typeof this.resourceMap | null = null;

  isLoading: boolean = true;
  options: any = {};
  suggestions: Array<any> = [];

  get resourceMap() {
    return {
      Currency: 'currencies',
      Item: 'items',
      Titles: 'titles',
      SeedPackBox: 'seed-pack-box',
      StoreListing: 'store-listings-v2',
      Nurture: 'nurture',
    };
  }

  constructor
    (
      private dataService: DataService,
      private messageService: MessageService,
    ) { }

  /**
   * Prize Card Form Group Component Initialization
   */
  async ngOnInit() {
    // console.log(this.prize);
    if (this.isCostField) {
      this.excludeResourceTypes = ['Titles', 'Item'];
    }
    if (!this.prize) {
      this.prize = [];
    }
    this.version = this.version ? this.version : 2;
    await this.setOptions();
    this.isLoading = false;
  }

  /**
   * Set options for dropdowns and auto-completes
   */
  async setOptions() {
    for (const prize of this.prize) {
      console.log(prize);
      if (prize.t && prize.t._id) {
        prize.t = { name: prize.t.name, id: prize.t.id, _id: prize.t._id }
      }
      if (this.isCostField) {
        prize.id = prize.id._id;
      }
    }
    await this.getOptionsFromRef('t', 'resources', true, true, this.excludeResourceTypes && this.excludeResourceTypes.length > 0 ? { name: { $nin: this.excludeResourceTypes } } : null);
    await this.getOptionsFromRef('id', 'currencies', true, true);
    await this.getOptionsFromRef('item-types', 'item-types', true, true);
    await this.getOptionsFromRef('user_titles', 'titles', true, true);
  }

  /**
   * Get/set options from a reference entity type.
   *
   * @param fieldName Field name to populate
   * @param model API Controller route
   * @param minimal Flag that sets wether or not to set minimal values
   */
  async getOptionsFromRef
    (
      fieldName: string,
      model: string,
      minimal: boolean = false,
      autopopulate: boolean = true,
      customQuery: any = null
    ) {
    const options = await this.dataService.getAllOfTypeAsync(model,
      {
        query: customQuery ? customQuery : {},
        autopopulate: autopopulate,
        virtuals: true,
        sort: { name: 1 },
      });
    if (minimal) {
      let o: any[] = [];
      for (const option of options) {
        o.push({ name: option.name, id: option.id, _id: option._id });
      }
      this.options[fieldName] = o;
    }
    else {
      this.options[fieldName] = options;
    }
  }

  /**
   * Get suggestions for auto-complete
   *
   * @param event Event comming from the component
   * @param fieldName Name of the field to set suggestions
   * @param model API endpoint route
   */
  async getSuggestionsForRef
    (
      event: any,
      fieldName: string | any,
      model: string
    ) {
    this.dataService.getAllOfType(model,
      {
        query: isNaN(event) ? { name: { $regex: event, $options: 'i' } } : { id: event },
        select: '_id name id start end',
        virtuals: false,
        autopopulate: false,
        sort: { name: 1 },
      })
      .subscribe((result) => {
        // Using map to transform the result array more efficiently
        const transformedResult = result.map(item => ({
          ...item,
          name: `${item.name} (${item.id})`,
        }));

        // Assign the transformed result to suggestions
        this.suggestions[fieldName] = transformedResult;
      });
  }

  updateDependentField(event: any, index: number, isAutoComplete: boolean = false) {
    console.log(event);
    console.log(this.prize[index]);

    // Update the prize based on the selection
    if (event.value) {
      if (event.value.name !== this.prize[index].lineItemType) {
        this.prize[index].lineItemType = event.value.name;
        // Cleaning id since it now points to a different lineItemType
        this.prize[index].id = null;
      }

      if (event.value.name === 'Titles') {
        this.prize[index].c = 1;
      }

      if (event.value.name === 'SeedPackBox') {
        this.prize[index].c = 1;
      }

    } else {
      this.prize[index].lineItemType = '';
    }

    console.log(this.prize);

    // Emit events based on whether the change is from the autocomplete componen
    // and if it's the first prize in the array
    if (isAutoComplete && index === 0 && this.isChallengePrize) {
      this.onChallengePrizeChange.emit(this.prize);
    } else {
      this.onPrizeChange.emit(this.prize);
    }
  }



  /**
   * Adds a new reward
   */
  addReward() {
    if (this.isChallenge && this.prize.length >= 2) {
      // Using window.alert since we don't have access to a notification service
      this.messageService.add({severity: 'warn', summary: 'Warning', detail: 'Challenge prizes can only have 4.0 and 5.0 star prizes'});
      return;
    }

    let reward = {
      t: this.defaultResourceType !== null ? this.options['t'].find((option: any) => option.name === this.defaultResourceType) : {},
      id: {},
      c: 5,
      lineItemType: this.defaultResourceType !== null ? this.defaultResourceType : '',
    }
    this.prize = [...this.prize, reward];
    this.onPrizeChange.emit(this.prize);
  }


  /**
   * Deletes a reward from array of rewards
   *
   * @param index Index of the reward to remove.
   */
  deleteReward(index: number) {
    this.prize.splice(index, 1);
    this.onPrizeChange.emit(this.prize);
  }

  reorder(event: any) {
    this.onPrizeChange.emit(this.prize)
  }
}
