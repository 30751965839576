<p-table [value]="sortedTableData" [styleClass]="styleClass" dataKey="name" responsiveLayout="scroll">
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem"></th>
            <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
            <th pSortableColumn="expectedCoinSpend">Average Expected Coin Spend<p-sortIcon field="expectedCoinSpend"></p-sortIcon></th>
            <th pSortableColumn="expectedDiamondSpend">Average Expected Diamond Spend<p-sortIcon field="expectedDiamondSpend"></p-sortIcon></th>
            <th pSortableColumn="averageCoinCost">Average Coin Cost<p-sortIcon field="averageCoinCost"></p-sortIcon></th>
            <th pSortableColumn="averageDiamondCost">Average Diamond Cost<p-sortIcon field="averageDiamondCost"></p-sortIcon></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-spawner let-expanded="expanded">
        <tr>
            <td>
                <button type="button" *ngIf="spawner.originalEconObj.length > 1" pButton pRipple [pRowToggler]="spawner" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>
                <a 
                    [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank" 
                    [routerLink]="'/categories/' + id"
                    *ngFor="let id of spawner.id; let i = index; let last = last"
                >
                    {{last ? spawner.name : spawner.name + ' | '}}
                </a>
            </td>
            <td>{{spawner.expectedCoinSpend}}</td>
            <td>{{spawner.expectedDiamondSpend}}</td>
            <td>{{spawner.averageCoinCost}}</td>
            <td>{{spawner.averageDiamondCost}}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-spawner>
        <tr>
            <td colspan="7">
                <div class="p-3">
                    <p-table [value]="spawner.originalEconObj" dataKey="categoryName">
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="categoryName">Category Name <p-sortIcon field="categoryName"></p-sortIcon></th>
                                <th pSortableColumn="expectedCoinSpend">Expected Coin Spend <p-sortIcon field="expectedCoinSpend"></p-sortIcon></th>
                                <th pSortableColumn="expectedDiamondSpend">Expected Diamond Spend <p-sortIcon field="expectedDiamondSpend"></p-sortIcon></th>
                                <th pSortableColumn="averageCoinCost">Average Coin Cost <p-sortIcon field="averageCoinCost"></p-sortIcon></th>
                                <th pSortableColumn="averageDiamondCost">Average Diamond Cost <p-sortIcon field="averageDiamondCost"></p-sortIcon></th>                                
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-eco>
                            <tr>
                                <td>{{eco.categoryName}}</td>
                                <td>{{eco.expectedCoinSpend ? eco.expectedCoinSpend : 0}}</td>
                                <td>{{eco.expectedDiamondSpend ? eco.expectedDiamondSpend : 0}}</td>
                                <td>{{eco.averageCoinCost ? eco.averageCoinCost : 0}}</td>
                                <td>{{eco.averageDiamondCost ? eco.averageDiamondCost : 0}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </td>
        </tr>
    </ng-template>
  </p-table>