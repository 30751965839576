<!-- DEV, QA, DEV + QA buttons (only show when not in test environment) -->
<div class="button-group-container" *ngIf="environmentName !== 'test'">
  <div class="button-group" *ngIf="authService.hasPermission(['push'], 'GamedataController')">
    <button pButton type="button" label="DEV" class="dev-button" (click)="onGameDataBuildV3('dev')"></button>
    <button pButton type="button" label="QA" class="qa-button" (click)="onGameDataBuildV3('stage')"></button>
    <button pButton type="button" label="DEV + QA" class="devqa-button" (click)="showConfirmModal()"></button>
  </div>
  <div class="button-group-single" *ngIf="authService.hasPermission(['delete'], 'GamedataController')">
    <button pButton type="button" label="Cancel PUSH" class="cancel-push-button" (click)="cancelPush()"></button>
  </div>
</div>

<!-- TEST button (only show in test environment) -->
<div class="container test-button-container" *ngIf="environmentName === 'test' && authService.hasPermission(['push'], 'GamedataController')" style="margin-top: 20px;">
  <div class="center p-pt-1 button-group-single">
    <button pButton pRipple type="button" label="TEST" class="qa-button" [disabled]="isLoading || !cmsSettings.manualQAGamedataPushEnabled" (click)="onGameDataBuildV3('test')"></button>
    <div class="button-group-single" *ngIf="authService.hasPermission(['delete'], 'GamedataController')">
      <button pButton type="button" label="Cancel PUSH" class="cancel-push-button" (click)="cancelPush()"></button>
    </div>
  </div>
</div>

<!-- Confirmation Dialog for DEV + QA -->
<p-dialog header="Build DEV + QA Gamedata V3" [(visible)]="displayConfirmModal" [modal]="true" [closable]="true" [style]="{ width: '40vw' }">
  <div class="ui-g ui-fluid">
    <div class="ui-g-12">
      <p style="font-size: 1.1em; margin-bottom: 1em;">
        <i class="pi pi-exclamation-triangle" style="font-size: 2em; color: #FBC02D; vertical-align: middle; margin-right: 10px;"></i>
        Are you sure that you want to Build Dev and QA Gamedata V3? QA Build Gamedata will only be executed if Dev gamedata build was successful.
      </p>
    </div>
    <div class="ui-g-12 text-center button-group-modal">
      <button pButton type="button" label="No" icon="pi pi-times" class="ui-button-secondary no-button" (click)="cancelBuild()"></button>
      <button pButton type="button" label="Yes" icon="pi pi-check" class="ui-button-primary yes-button" (click)="confirmBuild()"></button>
    </div>
  </div>
</p-dialog>

<!-- Spinner -->
<ngx-spinner bdColor="rgba(36,34,36,0.8)" size="medium" color="#81c784" type="pacman">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<!-- Toast -->
<p-toast position="top-right"></p-toast>