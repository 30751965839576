import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import NurtureCollectionGroupDto from '../../NurtureCollectionGroupDTO';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';


@Component({
  selector: 'app-nurture-collection-groups-form',
  templateUrl: './nurture-collection-groups-form.component.html',
  styleUrls: ['./nurture-collection-groups-form.component.sass']
})
export class NurtureCollectionGroupsFormComponent extends BaseDynamicFormComponent implements OnInit {
  nurtureCollectionGroupRecord: NurtureCollectionGroupDto = new NurtureCollectionGroupDto();
  title: string = 'Nurture Collection Group';
  isLoading: boolean = false;
  options: any = {
    collections_ref: [],
    environments_ref: [],
    stages_ref: []
  };

  constructor(
    private validation: BaseInputValidationServiceService,
    private agGridToolsService: AgGridToolsService
  ) {
    super();
  }

  ngOnInit() {
    this.fields = [
      {
        title: "Nurture Collection Group Data",
        fields: [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'name',
            isRequired: true,
            label: 'Name',
            columnWidth: 6,
            disabled: false
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            columnWidth: 3,
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            columnWidth: 3,
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.nurtureCollectionGroupRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: 'priority',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'priority',
            isRequired: false,
            label: 'Priority',
            columnWidth: 2,
            minFractionDigits: 0,
            maxFractionDigits: 0,
            inputNumberInputId: 'rewardLoopMultiplier',
            inputNumberMin: 0,
            inputNumberMax: 999,
          },

          {
            name: "Unlock Condition",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'unlockCondition',
            label: 'Unlock Condition',
            filter: true,
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: true,
            options: {
              fieldName: 'unlockCondition',
              values: [
                { name: 'Grow [x] unique plants', value: 'Grow [x] unique plants' },
                { name: 'Grow [x] plants', value: 'Grow [x] plants' },
                { name: 'Grow 0 plants', value: 'Grow 0 plants' }
              ]
            },
            filterBy: 'name',
            columnWidth: 4,
          },
          {
            name: 'Unlock Threshold',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'unlockThreshold',
            isRequired: false,
            label: 'Unlock Threshold',
            inputNumberMode: 'decimal',
            minFractionDigits: 0,
            maxFractionDigits: 2,
            inputNumberInputId: 'rewardLoopMultiplier',
            inputNumberMin: 0,
            inputNumberMax: 99999,
            columnWidth: 3,
          },
          {
            name: "Blurred Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'blurredImage_ref',
            label: 'Blurred Image',
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'blurredImage_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: BuildType.Images },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          }          
        ],
        type: DynamicCardType.Card
      },
      {
        fields: [
          {
            name: "Regions (Nurture Collections)",
            inputType: DynamicInputType.BaseInputField,
            clearField: false,
            key: 'nurtureCollections_ref',
            inputTypeFields: [InputFieldType.TableInput],
            isRequired: true,
            label: 'Regions (Nurture Collections)',
            disabled: true,
            rowData: this.nurtureCollectionGroupRecord.nurtureCollections_ref,
            entity: 'nurture-collection',
            isTableInput: true,
            tableInputParams: {
              select: 'id name',
            }
          }
        ],
        type: DynamicCardType.Card
      }
    ];

    this.viewRoute = 'nurture-collection-groups';
    this.isLoading = false;
  }
}
