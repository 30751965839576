import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-nurture-items-input-component',
  templateUrl: './nurture-items-input-component.component.html',
  styleUrls: ['./nurture-items-input-component.component.sass']
})
export class NurtureItemsInputComponentComponent implements OnInit {
  @Input() nurtureItems: any;
  @Input() field: any;
  @Output() onChange = new EventEmitter<any>();
  options: any = [];
  newNurture: any = null;

  constructor(
    private dataService: DataService,
    private messageService: MessageService,
  ) { }

  async ngOnInit(): Promise<void> {
    console.log(this.nurtureItems);
    this.options['nurture'] = await this.dataService.getOptionsFromRef('nurture', 'nurture', false, true);
  }

  onNurtureChange(event: any){
    console.log('onNurtureChange:', event)
    this.onChange.emit({ field: this.field.key, value: this.nurtureItems});
  }

  addNurture() {
    console.log(`should add ${this.newNurture}`);

    const newNurtureItem = this.options['nurture'].find((item: any) => item._id === this.newNurture);

    if (!newNurtureItem) {
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: 'Nurture Item not found.'
      });
      return;
    }

    if (this.nurtureItems.some((item: any) => item._id === newNurtureItem._id)) {
      this.messageService.add({
        severity: 'info',
        summary: 'Info',
        detail: 'Nurture Item already in collection.'
      });
    } else {
      this.nurtureItems.push(newNurtureItem);
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Nurture item added successfully.'
      });
    }
    this.onNurtureChange(this.nurtureItems);
  }

  removeNurture(id: number) {
    const index = this.nurtureItems.findIndex((item: any) => item.id === id);

    if (index !== -1) {
      this.nurtureItems.splice(index, 1); // Remove the item from the array using splice
      this.messageService.add({
        severity: 'success',
        summary: 'Success',
        detail: 'Removed Nurture Item.'
      });
      this.onNurtureChange(this.nurtureItems); // Emit the updated nurtureItems array
    }
  }



}
