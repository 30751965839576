import { ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';
import { itemConstants, pathconfigValues } from '../data/constants';
import { ItemService } from '../services/item.service';
import { Title } from '@angular/platform-browser';
import { SetDefaultTimeService } from 'src/app/common/services/set-default-time.service';
import { environments } from 'src/app/common/constants/constants'
import { DatePipe } from '@angular/common';
import { ItemValidationService } from '../services/item-validation.service';
import * as constants from '../data/constants'
import itemDTO from '../data/itemDTO';
import { OfficeTimePipe } from 'src/app/common/pipes/officeTime.pipe';
import { OfficeTimeFormPipe } from 'src/app/common/pipes/officeTimeForm.pipe';

@Component({
  selector: 'app-restriction-form',
  templateUrl: './item-form-v2.component.html',
  styleUrls: ['./item-form-v2.component.sass'],
})
export class ItemFormV2Component implements OnInit {
  @ViewChild('nestedGroup_overlay') nestedGroup_overlay: any;
  @ViewChild('nestedGroup_input') nestedGroup_input: any;
  // variables for path generation
  fileTypePath: string = '';
  fileTypeCode: string = '';
  selectedYearPath: string = '';
  fileNamePath: string = '';
  defaultYearPath: string = pathconfigValues.year;
  externalPlantData: any;
  //
  itemConstants = itemConstants;
  thumbnailPath: string = '';
  key: string = 'items';
  id: number;
  isEditMode: boolean = false;
  submitUrl: string;
  payLoad = '';
  action = 'add';
  options: any = [];
  suggestions: any = [];
  existingDoc: any = {};
  text: any;
  getName: any;
  getLatinName: any;
  getFileName: any;
  getMergeValues: any;
  uploadedFiles: any[] = [];
  currentFiles: any[] = [];
  defaultDate: any;
  selectedExternalPlant: any = {
    botanicalName: '',
    commonName: '',
  };
  plantPreview: any = constants.plantPreview;
  commonNameResult: any;
  latinNameResult: any;
  name: any;
  itemForm: UntypedFormGroup;
  fields: itemDTO = new itemDTO;
  rows: any[];
  loading: boolean = false;
  searchToggle: boolean = false;
  commonToggle: boolean = true;
  latinToggle: boolean = true;

  currentNestedGroup: any = {};

  s3Cdn = 'https://d3tfb94dc03jqa.cloudfront.net/';
  spruceImage: any;

  dateVerificationConfirmed: boolean = false;
  showDatesVerificationModal: boolean = false;
  envsVerificationConfirmed: boolean = false;
  showEnvsVerificationModal: boolean = false;

  envsModalMessage: string = '';
  originalRecord: any = {};
  startDateErrorMessage: string = "";
  endDateErrorMessage: string = "";
  reStartDateErrorMessage: string = "";
  reEndDateErrorMessage: string = "";
  envRules = constants.envRules;

  originalCosts: any;
  costs: any;
  touchedCosts: boolean = false;

  checkTimeZone: boolean = false;



  constructor(
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private messageService: MessageService,
    private authService: AuthService,
    private dataService: DataService,
    private loggerService: LoggerService,
    private validationService: ValidationsService,
    private scroller: ViewportScroller,
    private utilitiesService: UtilitiesService,
    private itemService: ItemService,
    private itemValidationService: ItemValidationService,
    private titleService: Title,
    private officeTimeFormPipe: OfficeTimeFormPipe,
    private setDefaultTimeService: SetDefaultTimeService,
    private datePipe: DatePipe,
    private router: Router,
  ) {}

  async ngOnInit() {
    this.loggerService.log('init');
    // url to submit the form to.
    this.submitUrl = `${this.key}/${this.action}`;
    /**
     * form fields / defaults
     */
    this.defaultDate = this.utilitiesService.getCurrentDateAtMidnight();
    
    this.checkTimeZone = this.utilitiesService.isUserLocalTimeZonePST();
    
    // check for ID for edit mode.
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    if (this.id) {
      this.loading = true;
      this.isEditMode = true;
      // change submit url for updating existing entity.
      this.submitUrl = `${this.key}/update/${this.id}`;

      // get existing document.
      await this.getExistingDoc().then(async (result) => {
        this.loggerService.log('get existing doc', result);
        this.existingDoc = result;

        // Transform each cost Id prop of costs_ref array to be the MongoId instead of the whole object
        // in order to match the options from the dropdown.

        if (this.existingDoc.thumbnails && this.existingDoc.thumbnails[1]) {
          this.existingDoc.newThumbnail_img =
            this.existingDoc.thumbnails[1].path;
        } else {
          this.existingDoc.newThumbnail_img = this.existingDoc.thumbnail_img;
        }

        // set existing values from doc.
        this.loggerService.log('set existing values');
        await this.setExistingValuesV2().then(async () => {
          // init form.
          this.loggerService.log('init form');
          await this.initForm();

          // conditionals
          this.loggerService.log('conditionals');

          this.itemService.setOptionsFromNestedField(
            this.options,
            this.itemForm,
            'category_ref',
            'types_ref',
            'type_ref',
            this.existingDoc.category_ref
              ? this.existingDoc.category_ref._id
              : '',
            false
          );
        });
      });
      this.originalRecord = _.cloneDeep(this.existingDoc);
      this.loading = false;
    } else {
      // initialize form
      await this.initForm();
      this.itemForm.get('enabled')?.markAsTouched();
    }

    if (this.isEditMode) {
      this.titleService.setTitle(`Edit ${this.fields.name}(${this.id})`)
    } else {
      this.titleService.setTitle(`Add New Item`)
    }
    this.externalPlantData = await this.dataService
          .getAllOfTypeAsync('external-plant-data', {
            query: {},
            select:
            '-imageBuildData -image_versions -createdAt -updatedAt -createdBy'
          })
  }

  get costs_ref() {
    return this.itemForm.get('costs_ref') as UntypedFormArray;
  }

  get referenceLinks() {
    return this.itemForm.get('referenceLinks') as UntypedFormArray;
  }

  get internalReferenceLinks() {
    return this.itemForm.get('internalReferenceLinks') as UntypedFormArray;
  }

  // get referenceImageLinks() {
  //   return this.itemForm.get('referenceImageLinks') as FormArray;
  // }

  get resourceMap() {
    return {
      Currency: 'currencies',
      Item: 'items',
    };
  }

  addCost() {
    this.costs_ref.push(
      this.fb.group({
        t: this.options['t'][0],
        id: this.options['id'][0],
        c: 0,
        lineItemType: 'Currency',
      })
    );
    this.itemForm.get('costs_ref')?.markAsTouched();
  }

  deleteCost(index: number) {
    this.costs_ref.removeAt(index);
    this.itemForm.get('costs_ref')?.markAsTouched();
    this.messageService.add({
      severity: 'warn',
      summary: 'Cost Removed',
      detail: 'Cost removed from item.',
    });
  }

  addReferenceLink() {
    this.referenceLinks.push(
      this.fb.group({
        link: null,
      })
    );
  }

  deleteReferenceLink(index: number) {
    this.referenceLinks.removeAt(index);
  }

  addInternalReferenceLink() {
    this.internalReferenceLinks.push(
      this.fb.group({
        link: null,
      })
    );
  }

  filterFields() {
    if ((this.latinToggle && this.commonToggle)) {
      return ['botanicalName', 'commonName'];
    } else if (this.latinToggle) {
      return ['botanicalName']
    } else {
      return ['commonName']
    }
  }
  deleteInternalReferenceLink(index: number) {
    this.internalReferenceLinks.removeAt(index);
  }


  /**
   * Get existing document from the DB.
   * @returns
   */
  async getExistingDoc() {
    const doc = await this.dataService.getDocumentAsync(this.key, {
      query: { id: this.id },
      autopopulate: true,
      virtuals: true,
    });
    // console.log('existing doc', doc);
    return doc;
  }



  async setExistingValuesV2(){

    /**
     * Copies reference IDs from the specified source property of `this.existingDoc` to the corresponding target property of `this.fields`.
     * If the source property exists and is not null, the target property will be assigned an array containing the `_id` values from the source.
     *
     * @param sourceProp - The property name in `this.existingDoc` from which to copy the reference IDs.
     * @param targetProp - The property name in `this.fields` to which the reference IDs will be assigned.
     *
     * Example Usage:
     * copyRefs('spawnAudios_ref', 'spawnAudios_ref');
    */
    const copyRefs = (sourceProp: keyof typeof this.existingDoc, targetProp: keyof typeof this.fields) => {
      if (this.existingDoc[sourceProp]) {
        this.fields[targetProp] = this.existingDoc[sourceProp].map((record: { _id: any; }) => record._id);
      }
    };

    let keys = Object.keys(this.existingDoc);
    keys.forEach((key: any) => {
      if (this.existingDoc[key] &&
        ['sceneType', 'progressionLevel_ref', 'type_ref', 'climate_ref','keywords_ref'].includes(key)) {
        this.fields[key as keyof itemDTO] = this.existingDoc[key]._id;
      } else if (this.existingDoc[key]) {
        this.fields[key as keyof itemDTO] = this.existingDoc[key]
      }
    })
    if (this.existingDoc.start) {
      this.fields.start = new Date(this.officeTimeFormPipe.transform(this.existingDoc.start));
    }
    if (this.existingDoc.end) {
      this.fields.end = new Date(this.officeTimeFormPipe.transform(this.existingDoc.end));
    }
    if (this.existingDoc.reReleaseStart) {
      this.fields.reReleaseStart = new Date(this.officeTimeFormPipe.transform(this.existingDoc.reReleaseStart));
    }
    if (this.existingDoc.reReleaseEnd) {
      this.fields.reReleaseEnd = new Date(this.officeTimeFormPipe.transform(this.existingDoc.reReleaseEnd));
    }
    if (this.existingDoc.itemStatus) {
      this.fields.itemStatus = this.existingDoc.itemStatus == 'In Progress' ? 'QA Ready' : this.existingDoc.itemStatus;
    }
    if (this.existingDoc.progressionLevel_ref) {
      this.fields.progressionLevel_ref =
        this.existingDoc.progressionLevel_ref._id;
    }

    if (this.existingDoc.itemFileType_ref) {
      this.fields.itemFileType_ref = this.existingDoc.itemFileType_ref._id;
    }

    if (this.existingDoc.category_ref) {
      this.fields.category_ref = this.existingDoc.category_ref._id;
    }

    if (this.existingDoc.nurture_ref) {
      this.fields.nurture_ref = this.existingDoc.nurture_ref._id;
    }

    if (this.existingDoc.type_ref) {
      this.fields.type_ref = this.existingDoc.type_ref._id;
    }

    if (this.existingDoc.shape_ref) {
      this.fields.shape_ref = this.existingDoc.shape_ref._id;
    }

    if (this.existingDoc.costs_ref) {
      this.costs = this.existingDoc.costs_ref;
      this.originalCosts = _.cloneDeep(this.costs)
      this.fields.costs_ref = []
      for (const cost of this.existingDoc.costs_ref) {
        this.fields.costs_ref.push(
          this.fb.group({
            t: cost.t,
            id: cost.id,
            c: cost.c,
            lineItemType: cost.lineItemType,
          })
        );
      }
    }

    // handle reference links
    if (this.existingDoc.internalReferenceLinks) {
      this.fields.internalReferenceLinks = []
      for (const link of this.existingDoc.internalReferenceLinks) {
        this.fields.internalReferenceLinks.push(
          this.fb.group({
            link: link,
          })
        );
      }
    }

    // handle vendor reference links
    if (this.existingDoc.referenceLinks) {
      this.fields.referenceLinks = []
      for (const link of this.existingDoc.referenceLinks) {
        this.fields.referenceLinks.push(
          this.fb.group({
            link: link,
          })
        );
      }
    }

    if (this.existingDoc.referenceImages) {
      this.fields.referenceImages = [];
      for (const image of this.existingDoc.referenceImages) {
        this.fields.referenceImages.push(image);
      }
    }

    if (this.existingDoc.releatedItems) {
      this.fields.releatedItems = [];
      for (const item of this.existingDoc.releatedItems)
      {
        this.fields.releatedItems.push(item._id);
      }
    }

    if (this.existingDoc.batch_ref) {
      this.fields.batch_ref = this.existingDoc.batch_ref._id;
    }

    if (this.existingDoc.vendor_ref) {
      this.fields.vendor_ref = this.existingDoc.vendor_ref._id;
    }

    if (this.existingDoc.externalPlantData_ref) {
      this.fields.externalPlantData_ref =
        this.existingDoc.externalPlantData_ref._id;

      this.selectedExternalPlant = this.existingDoc.externalPlantData_ref;
      this.commonNameResult = [this.existingDoc.externalPlantData_ref];
      this.itemService.setExternalPlantData(this.plantPreview, this.selectedExternalPlant);
    }

    copyRefs('climates_ref', 'climates_ref');
    copyRefs('itemSet_ref', 'itemSet_ref');
    copyRefs('colors_ref', 'colors_ref');
    copyRefs('tags_ref', 'tags_ref');
    copyRefs('keywords_ref', 'keywords_ref');
    copyRefs('contentHold_ref', 'contentHold_ref');
    copyRefs('styles_ref', 'styles_ref');
    copyRefs('traits_ref', 'traits_ref');
    copyRefs('materials_ref', 'materials_ref');
    copyRefs('patterns_ref', 'patterns_ref');
    copyRefs('spawnAudios_ref', 'spawnAudios_ref');
    copyRefs('loopAudios_ref', 'loopAudios_ref');
    copyRefs('spawnAudioCollections_ref', 'spawnAudioCollections_ref');
    copyRefs('loopAudioCollections_ref', 'loopAudioCollections_ref');

  }

  /**
   * Clear a field.
   * @param fieldKey The field key to clear.
   * @returns void
   *
   * Resets input field to null value and marks it as touched.
   **/

  clearField(fieldKey: string) {
    this.itemForm.get(fieldKey)!.reset();
    this.itemForm.get(fieldKey)!.markAsTouched();

    this.messageService.add({
      severity: 'success',
      summary: 'Field Cleared',
      detail: `Field ${fieldKey} has been cleared.`,
    });
  }



  /**
   * Initialize the form.
   */
  async initForm() {
    // console.log('fields', this.fields);
    this.itemForm = this.fb.group({
      name: [this.fields.name, Validators.required],
      blurb: [this.fields.blurb],
      blurbStatus: [this.fields.blurbStatus],
      bundleAsset: [this.fields.bundleAsset],
      bundleImage: [this.fields.bundleImage],
      bundleAssetAnd: [this.fields.bundleAssetAnd],
      bundleImageAnd: [this.fields.bundleImageAnd],
      cultivar: [this.fields.cultivar],
      latinName: [this.fields.latinName],
      fileName: [this.fields.fileName],
      plantFamily: [this.fields.plantFamily],
      category_ref: [this.fields.category_ref, Validators.required],
      nurture_ref: [this.fields.nurture_ref],
      type_ref: [this.fields.type_ref],
      climates_ref: [this.fields.climates_ref],
      itemSet_ref: [this.fields.itemSet_ref],
      colors_ref: [this.fields.colors_ref],
      keywords_ref: [this.fields.keywords_ref],
      tags_ref: [this.fields.tags_ref],
      styles_ref: [this.fields.styles_ref],
      traits_ref: [this.fields.traits_ref],
      materials_ref: [this.fields.materials_ref],
      patterns_ref: [this.fields.patterns_ref],
      shape_ref: [this.fields.shape_ref],
      start: [this.fields.start],
      end: [this.fields.end],
      reReleaseStart: [this.fields.reReleaseStart],
      reReleaseEnd: [this.fields.reReleaseEnd],
      enabled: [this.fields.enabled, Validators.required],
      year: [this.fields.year],
      batch_ref: [this.fields.batch_ref],
      thumbnail: [this.fields.thumbnail],
      prefab: [this.fields.prefab],
      vendor_ref: [this.fields.vendor_ref],
      vendorStatus: [this.fields.vendorStatus],
      vendorNotes: [this.fields.vendorNotes],
      vendorDimensions: [this.fields.vendorDimensions],
      itemStatus: [this.fields.itemStatus],
      itemFileType_ref: [this.fields.itemFileType_ref],
      flagged: [this.fields.flagged],
      spruceDataStatus: [this.fields.spruceDataStatus],
      contentHold_ref: [this.fields.contentHold_ref],
      // recolorSource: [this.fields.recolorSource],
      internalNotes: [this.fields.internalNotes],
      height: [this.fields.height],
      spread: [this.fields.spread],
      dimensionX: [this.fields.dimensionX],
      dimensionY: [this.fields.dimensionY],
      dimensionZ: [this.fields.dimensionZ],
      radius: [this.fields.radius],
      progressionLevel_ref: [this.fields.progressionLevel_ref],
      externalPlantData_ref: [this.fields.externalPlantData_ref],
      assetType: [this.fields.assetType],
      referenceLinks: this.fb.array(this.fields.referenceLinks),
      internalReferenceLinks: this.fb.array(this.fields.internalReferenceLinks),
      costs_ref: this.fb.array(this.fields.costs_ref),
      referenceImages: [this.fields.referenceImages],
      internalReferenceImages: [this.fields.referenceImages],
      vendorHeight: [this.fields.vendorHeight],
      vendorLength: [this.fields.vendorLength],
      releatedItems: [this.fields.releatedItems],
      env: [this.fields.env],
      spawnAudios_ref: [this.fields.spawnAudios_ref],
      loopAudios_ref: [this.fields.loopAudios_ref],
      spawnAudioCollections_ref: [this.fields.spawnAudioCollections_ref],
      loopAudioCollections_ref: [this.fields.loopAudioCollections_ref],
      // referenceImageLinks: this.fb.array(this.fields.referenceImageLinks),
    });

    /**
     * set all field options for the form.
     */
    this.loggerService.log('setting options');
    await this.itemService.setOptions(this.options).then(() => {
      this.itemService.initPlantPreview(this.fields, this.plantPreview, this.options, this.existingDoc);
    });
    // Value Changes for External Plant Data

    if (this.fields.thumbnail) {
      this.loggerService.log('thumbnail', this.fields.thumbnail);
      var n = this.fields.thumbnail.lastIndexOf('/');
      this.loggerService.log('n', n);
      var result = this.fields.thumbnail.substring(n + 1);
      this.loggerService.log('result', result);
      const fileN = result + '_1024.png';
      this.loggerService.log('fileN', fileN);
      if (fileN) {
        this.fields.thumbnail_img =
          this.s3Cdn + this.fields.thumbnail + '/' + fileN;
        this.loggerService.log('thumbnail_img', this.fields.thumbnail_img);
      }
    }

    if (this.existingDoc.externalPlantData_ref) {
      this.fields.spruceDataStatus = true;
      const fileName =
        this.existingDoc.externalPlantData_ref.imagePath + '.jpg';

      if (this.existingDoc.externalPlantData_ref.imagePath) {
        this.fields.spruce_img = this.s3Cdn + fileName;
      }
    }

    this.itemForm.valueChanges.subscribe((x) => {
      // Grab the Category name
      if (x.category_ref) {
        this.options['category_ref'].find((match: any) => {
          if(match)
          {
            if (x.category_ref === match._id) {
              // console.log('inside', match);
              this.plantPreview.category = match.name;
              // console.log('match type_ref', match['types_ref']);
              // Grab the Type Name
              if(match['types_ref'])
              {
                match['types_ref'].find((type_match: any) => {
                  if (x.type_ref === type_match._id) {
                    // console.log('match type_ref', type_match.name);
                    this.plantPreview.type = type_match.name;
                  }
                });
              }
            }
          }
        });
      }
      if (x.colors_ref) {
        // Grab Colors Name for Preview Module
        this.itemService.plantPreviewSetup('colors', 'colors_ref', x.colors_ref, this.plantPreview, this.options);
      }

      // Grab Climates Name for Preview Module
      if (x.climates_ref) {
        this.itemService.plantPreviewSetup('climates', 'climates_ref', x.climates_ref, this.plantPreview, this.options);
      }

      // Grab Traits Name for Preview Module
      if (x.traits_ref) {
        this.itemService.plantPreviewSetup('traits', 'traits_ref', x.traits_ref, this.plantPreview, this.options);
      }

      // Grab Blurbs Name for Preview Module

      if (x.blurb?.length > 5) {
        this.plantPreview.blurb = x.blurb;
        // console.log('the blurb', this.plantPreview.blurb);
      } else {
        this.plantPreview.blurb = this.plantPreview.description;
      }
      if (x.height || x.spread) {
        this.plantPreview.typicalSize = `${x.height} ft tall, ${x.spread} ft wide `;
      }

      if (x.thumbnail) {
        // this.loggerService.log('thumbnail', x.thumbnail);
        var n = x.thumbnail.lastIndexOf('/');
        // this.loggerService.log('n', n);
        var result = x.thumbnail.substring(n + 1);
        // this.loggerService.log('result', result);
        const fileN = result + '_1024.png';
        // this.loggerService.log('fileN', fileN);

        if (fileN) {
          this.fields.thumbnail_img = this.s3Cdn + x.thumbnail + '/' + fileN;
          // this.loggerService.log('thumbnail_img', this.fields.thumbnail_img);
        }
      }
    });

  }





  /**
   * Cleans the table of current filter to search for the current input
   * @param table table to filter
   * @param event value to filter for
   */
  filterFunction(table: any, event: any) {
    if (this.fields.name || this.fields.latinName) {
      table.clear();
    }
    table.filterGlobal(event, 'contains')
  }




  /**
   * Update a dependent field with the value of another field.
   * TODO: - handle non arrays - accept ref field properly
   *
   * @param e contains the value of the field the event was triggered on.
   * @param dependentField the field you want to update the value of
   * @param index index in the form array.
   */
  async updateDependentField(dataArray: any) {
    // Check if dataArray has changed
  if (!this.areArraysEqual(this.originalCosts, dataArray) && this.touchedCosts) {
    // Create a new FormArray based on dataArray
    const newFormArray = this.fb.array(
      dataArray.map((event: any) => this.fb.group(event))
    );

    // Set the costs_ref FormArray to the newFormArray
    this.costs_ref.controls = newFormArray.controls;
    this.costs_ref.setValue(newFormArray.value);

    // Mark the entire costs_ref FormArray as touched
    this.costs_ref.markAllAsTouched();

    console.log(this.costs_ref);
  } else {
    console.log('No changes in dataArray');
  }
}

private areArraysEqual(array1: any[], array2: any[]): boolean {
  // Implement your own logic to compare arrays
  // This example assumes a simple comparison for illustration purposes
  return JSON.stringify(array1) === JSON.stringify(array2);
}


  // Question: is this needed?

  onImageUpload(event: any, field: string) {
    this.loggerService.log('image upload', event);
    for (let file of event.files) {
      this.uploadedFiles.push(file.name);
    }
    this.itemForm.get(field)?.setValue(this.uploadedFiles);
    this.itemForm.get(field)?.markAsTouched();
  }

  // Question: is this needed?

  onImageSelect(fieldName: string, event: any) {
    const _this = this;
    this.loggerService.log('image select', event);
    for (let file of event.currentFiles) {
      this.loggerService.log('filename', file.name);
      // _this.currentFiles[file.name].push(file.name);
    }
    this.loggerService.log('current files', this.currentFiles);
  }



  beforeSubmit(){
    // ----
    this.envsVerificationConfirmed = false;
    this.dateVerificationConfirmed = false;
    // ----
    this.onSubmit()
  }

  /**
   * form submission.
   */
  async onSubmit(skipChecksFor: string = '') {

    const _this = this;
    let request: any = {};
    let touchedFields = [];

    await this.updateDependentField(this.costs);

    let properties = Object.keys(this.itemForm.controls);
    properties.forEach(prop =>
    {
      if(this.itemForm.controls[prop].touched)
      {
        touchedFields.push(prop);
        request[prop] = this.itemForm.controls[prop].value;
      }
    });
    if(!this.isEditMode || (this.isEditMode && touchedFields.length > 0))
    {
      let isValid = this.itemValidationService.validateItemForm(this.itemForm);
      var value = request;
      // userData from authService
      let userResult = this.authService.getSocialUser();
      value.userData = {
        name: userResult.currentUser.name,
        email: userResult.currentUser.email,
        id: userResult.currentUser.id,
      };

      if (isValid)
      {
        if(this.isEditMode){
          // let validationsMet = await this.itemValidationService.isValidForm(skipChecksFor, this.originalRecord, this.itemForm, this.envRules);
          let validationsMet = await this.isValidForm(skipChecksFor);
          if(!validationsMet){
            return;
          }
        }
        value.skipEnvChecks = this.envsVerificationConfirmed;
        this.formService
          .submitForm(value, this.submitUrl, this.isEditMode)
          .subscribe(
            (val) => {
              this.loggerService.log(
                'POST call successful value returned in body',
                val
              );
              // adjust submit message for edit/new.
              if (this.isEditMode)
              {
                this.afterItemUpdate(val);
              }
              else
              {
                this.messageService.add({
                  sticky: true,
                  severity: 'success',
                  summary: 'Submit Successful',
                  detail: `${val.name} was successfully created`,
                });
                this.router.navigate([`items/${val.id}`]);
              }
            },
            (response) => {
              this.loggerService.log('POST call in error', response);
              let detail = 'There was an error submitting.';


              detail = response.error ? JSON.stringify(response.error) : JSON.stringify(response);

              this.messageService.add({
                severity: 'error',
                sticky: true,
                summary: 'Form Submission Error',
                detail: detail,
              });
              window.scrollTo(0, 0);
            },
            () => {
              this.loggerService.log('The POST observable is now completed.');
            }
          );
      }
    }
    else
    {
      if(this.isEditMode)
      {
        window.scrollTo(0, 0);
        window.location.href = `/items/${this.id}`;
      }
    }
  }

  /**
   * Handle Item Update after submission.
   *
   * @param itemResult Item result.
   */
  afterItemUpdate(itemResult: any)
  {
    if(itemResult && itemResult._doc)
    {
      this.messageService.add({
        sticky: true,
        severity: 'success',
        summary: 'Update Successful',
        detail: `${itemResult._doc.name} was successfully updated.`,
      });
      if(itemResult.automations && itemResult.automations.length > 0)
      {
        itemResult.automations.forEach((automation: any) =>
        {
          this.messageService.add(
          {
            severity: 'info',
            summary: 'Automation Executed',
            detail: `${automation}`,
            life: 3000
          });
        });
      }
      this.router.navigate([`items/${itemResult._doc.id}`]);
    }
  }

  /**
   * This function builds a suggested path for prefab and thumbnail values based on the user's input.
   * @param
   * uses this variables to store the path's state:
   *  fileTypePath: string = '';
      fileTypeCode: string = '';
      selectedYearPath: string = '';
      fileNamePath: string = '';
      defaultYearPath: string = pathconfigValues.year;
   */
  async onPathComponentValueChange(inputName: string, event: any) {
    if(this.isEditMode)
    {
      if (inputName == 'year') {
        if (this.fields.prefab && this.fields.prefab.length > 0)
        {
          let prefabValues = this.fields.prefab.split('/');
          this.fileTypePath = _.cloneDeep(prefabValues[1]);
        }

        if (
          this.fields.fileName &&
          this.fields.fileName.length > 0
        ) {
          this.fileNamePath = _.cloneDeep(this.fields.fileName);
        }
      }

      if (inputName == 'fileType') {
        if (this.fields.year && this.fields.year.length > 0)
        {
          this.defaultYearPath = _.cloneDeep(this.fields.year);
        }
      }

      if (inputName == 'fileName') {

        if (this.fields.year && this.fields.year.length > 0) {
          this.defaultYearPath = _.cloneDeep(this.fields.year);
        }

        if (this.fields.prefab && this.fields.prefab.length > 0) {
          let prefabValues = this.fields.prefab.split('/');
          this.fileTypePath = _.cloneDeep(prefabValues[1]);
        }
      }
    }
    let response = await this.itemService.onPathComponentValueChange(
      this.fields,
      inputName,
      event,
      this.options,
      this.fileNamePath,
      this.fileTypePath,
      this.fileTypeCode,
      this.defaultYearPath,
      this.isEditMode
    );

    if (response) {
      response.fields.prefab = this.validationService.removeSpaces(response.fields.prefab);
      this.fields.prefab = response.fields.prefab;
      this.itemForm.get('prefab')?.markAsTouched();
      response.fields.thumbnail = this.validationService.removeSpaces(response.fields.thumbnail);
      this.fields.thumbnail = response.fields.thumbnail;
      this.itemForm.get('thumbnail')?.markAsTouched();
      response.fields.fileName = this.validationService.removeSpaces(response.fields.fileName);
      this.fields.fileName = response.fields.fileName;
      this.itemForm.get('fileName')?.markAsTouched();
      this.fileNamePath = response.fileNamePath;
      this.fileTypePath = response.fileTypePath;
      this.fileTypeCode = response.fileTypeCode;
      this.defaultYearPath = response.defaultYearPath;

      this.messageService.add({
        severity: 'success',
        summary: 'Path Components Updated',
        detail: 'Prefab, Thumbnail, and File Name fields have been filled.',
      });
    }
  }

  /**
   * Handle the date change event when it is copy and pasted
   */
  handleDateInputChange(field: any, event: any) {
    const inputValue = event.target.value;
    const parsedDate = new Date(inputValue);

    if (inputValue && !inputValue.includes('0:00')) {
      this.itemForm.controls[field].setValue(parsedDate);
      this.itemForm.controls[field].markAsUntouched();
    }
  }

  /**
   * Handles clicking the unlink/link buttom
   */
  onLinkClick() {
    if(!this.fields.spruceDataStatus) {
      this.itemService.unLink(this.itemForm, this.fields);
    }

  }
/**
 * reverts the change of the desired field
 * @param field field to revert/und
 */
  revertField(field: any) {
    this.itemForm.controls[field]?.setValue(this.fields[field as keyof itemDTO])
    this.itemForm.controls[field].markAsUntouched();
  }

  setDefaultPic() {
    this.fields.thumbnail_img =
      'https://d3tfb94dc03jqa.cloudfront.net/images/asset_missing/need_thumb/need_thumb_1024.png';
  }



  /**
   * Sets current date at midnight
   * @param patchValue checks if the value needs to be patched or not. This will be determined via if the form is using FormGroups or not.
   */
 setDefaultTime(prop: any) {
  this.setDefaultTimeService.setDefaultTime(true,this.itemForm,prop);
}

  /** Sets this.currentNestedGroup object with value for a new or existing costs_ref control group.
   * @param field FieldData used to reference the main field key and main field name to be used for the nestedGroup header.
   * @param index Index of the group being added. If equal to the value.length, a new group is added.
   * @param e browser event used when opening the 'Nested Group' dialog.
   */
  onOpenNestedGroup(field: any, index: number, e: any = null) {
    const key: string = field.key;

    this.currentNestedGroup = {
      index: index,
      field: field,
      value:
        !this.itemForm.value[key][index] ||
        (this.itemForm.value[key] && this.itemForm.value[key].length === index)
          ? {
              lineItemType: 'Currency',
              t: this.options['t'].find((o: any) => o.name === 'Currency'),
              id: null,
              c: null,
            }
          : this.itemForm.value[key][index],
    };

    if (e) {
      this.nestedGroup_overlay.show(e);
    }
  }

  /** Clears an entire group from the main field value.
   * @param key string for referencing the value in the form.
   * @param index index of the group in the value.
   */
  onRemoveNestedGroup(key: string, index: number) {
    this.itemForm.value[key].splice(index, 1);
  }

  /** Checks value of currentNestedGroup and updates form value. */
  onNestedFieldChanged() {
    const key = this.currentNestedGroup.field.key;
    const index = this.currentNestedGroup.index;
    const groupValue = _.cloneDeep(this.currentNestedGroup.value);

    if (!groupValue['id'] && !groupValue['c']) {
      this.onRemoveNestedGroup(key, index);

      this.onOpenNestedGroup(
        this.currentNestedGroup.field,
        this.itemForm.value[key].length
      );
    } else {
      this.itemForm.value[key][index] = groupValue;
    }
  }

  /** Clears a single field in the currentNestedGroup and runs onNestedFieldChanged() to check value and update form.
   * @param fieldKey Key of the subfield being changed.
   */
  onNestedFieldCleared(fieldKey: string) {
    this.currentNestedGroup.value[fieldKey] = null;

    this.onNestedFieldChanged();
  }

  /**
   * Sets the enabled environments to null if the selected value is 'Do Not Use'.
   *
   * @param selectedValue The selected value from the contentHold_ref multiselect dropdown.
   */
  setEnabledEnvironmentsToNull(selectedValue: any)
  {
    let value = this.options['contentHold_ref'].find((x: any) => x._id === selectedValue.itemValue);

    if(value && value.name === 'Do Not Use')
    {
      this.itemForm.get('enabled')?.setValue(false);
      this.itemForm.controls['enabled']?.markAsTouched();
      this.itemForm.get('env')?.setValue(null);
      this.itemForm.controls['env']?.markAsTouched();

      this.messageService.add({
        severity: 'warn',
        summary: 'Item Not Enabled',
        detail: `Item is no longer enabled.`,
      });
    }
    this.itemForm.controls['contentHold_ref']?.markAsTouched();
  }

  /**
   * this function evaluates date changes, and returns if a change is valid based on:
   * if current start/end dates were live in the past (older than current date)
   * if current start/end dates are soon to go live -48hrs
   * @returns boolean true/false if date changes are valid
   */
  async validateDateChanges(){
    let isValidDate = true;
    // re-setting error message before entering function
    this.startDateErrorMessage = '';
    this.endDateErrorMessage = '';
    this.reStartDateErrorMessage = '';
    this.reEndDateErrorMessage = '';
    // setting original dates
    let originalStart = new Date(this.originalRecord.start);
    let originalEnd = new Date(this.originalRecord.end);
    let originalReStart = new Date(this.originalRecord.reReleaseStart);
    let originalReEnd = new Date(this.originalRecord.reReleaseEnd);
    // setting new dates
    let newStart = new Date(this.itemForm.value['start']);
    let newEnd = new Date(this.itemForm.value['end']);
    let newReStart = new Date(this.itemForm.value['reReleaseStart']);
    let newReEnd = new Date(this.itemForm.value['reReleaseEnd']);
    let currentDate = new Date();

    // START DATE EVALUATION
    if((this.originalRecord.start) && (originalStart.getTime() !== newStart.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalStart.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        this.startDateErrorMessage = `Original Start date passed ${Math.floor(difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        this.startDateErrorMessage = `Original Start date is soon to go live in ${difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    // END DATE EVALUATION
    if((this.originalRecord.end) && (originalEnd.getTime() !== newEnd.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalEnd.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        this.endDateErrorMessage = `Original END date passed ${Math.floor(difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        this.endDateErrorMessage = `Original END date is soon to go live in ${difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    // RE-Release Start Date Evaluation
    if((this.originalRecord.reReleaseStart) && (originalReStart.getTime() !== newReStart.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalReStart.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        this.reStartDateErrorMessage = `Original Re-Release START date passed ${Math.floor(difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        this.reStartDateErrorMessage = `Original Re-Release START date is soon to go live in ${difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    // RE-Release End Date Evaluation
    if((this.originalRecord.reReleaseEnd) && (originalReEnd.getTime() !== newReEnd.getTime())){
      // time difference for START
      let difference_In_Time = currentDate.getTime() - originalReEnd.getTime();
      let difference_In_Hours = +Math.abs(difference_In_Time/3600000).toFixed(1)
      let difference_In_Days = difference_In_Time / (1000 * 3600 * 24);
      if(difference_In_Days>0){
        this.reEndDateErrorMessage = `Original Re-Release END date passed ${Math.floor(difference_In_Days)} days ago`;
        isValidDate = false;
      } else if((difference_In_Days<=0) && (difference_In_Hours<=48)){
        this.reEndDateErrorMessage = `Original Re-Release END  date is soon to go live in ${difference_In_Hours} hours`;
        isValidDate = false;
      }
    }
    return isValidDate;
  }

  async validateEnvPreReqs(){
    let {value} = this.itemForm;
    let envCheck = await this.itemValidationService.checkEnvFlagsRequirements(value);
    this.envRules.dev.status = envCheck.devReady;
    this.envRules.qa.status = envCheck.qaReady;
    this.envRules.prod.status = envCheck.prodReady;

    console.log('envCheck: ', envCheck);
    if(!envCheck.devReady || !envCheck.qaReady || !envCheck.prodReady)
    {
      return false;
    } else {
      return true;
    }

  }

  async isValidForm(skip: string){
    let shouldSubmit = true;
    if(skip !== 'date' && !this.dateVerificationConfirmed){
      let result = await this.validateDateChanges();
      this.showDatesVerificationModal = !result;
      if(!result){
        shouldSubmit = false;
      }
    }
    if(skip !== 'envs' && !this.dateVerificationConfirmed){
      let result = await this.validateEnvPreReqs();
      this.showEnvsVerificationModal = !result;
      if(!result){
        shouldSubmit = false;
      }
    }
    return shouldSubmit;
  }

    /**
   * Submits form with overwrite flag to bypass conflict checks
  */
    async submitAndSkipChecks(skip: string){
      switch (skip) {
        case 'date':
          this.dateVerificationConfirmed = true;
          this.showDatesVerificationModal = false;
          break;
        case 'envs':
          this.envsVerificationConfirmed = true;
          this.showEnvsVerificationModal = false;
          break;

        default:
          break;
      }
      this.onSubmit(skip);
    }

    onToggleChange(event: any) {
      this.fields.spruceDataStatus = event;
      // Other logic related to the toggle button state change
    }


    onRowSelect(event: any) {
      this.fields.spruceDataStatus = true;
      this.loggerService.log('spruceStatus check', this.fields.spruceDataStatus);
      this.loggerService.log('selected row', this.selectedExternalPlant);

      this.itemForm.get('externalPlantData_ref')?.setValue(this.selectedExternalPlant._id);
      this.itemForm.get('spruceDataStatus')?.setValue(this.fields.spruceDataStatus);

      this.setExternalPlantData();

      this.itemForm.get('externalPlantData_ref')?.markAsTouched();
      this.itemForm.get('spruceDataStatus')?.markAsTouched();

      const fileName = this.selectedExternalPlant.imagePath + '.jpg';
      this.loggerService.log('filename', fileName);

      this.loggerService.log('this.fields.spruceDataStatus', this.fields.spruceDataStatus);

      this.onToggleChange(this.fields.spruceDataStatus);

      if (this.selectedExternalPlant.imagePath) {
        this.fields.spruce_img = this.s3Cdn + fileName;
        this.loggerService.log('spruce image', this.spruceImage);
      }
      this.messageService.add({
        severity: 'success',
        summary: 'Linking Successful',
        detail: `Spruce Data has been succesfully linked`,
      });
    }
    onRowUnselect(event: any) {
      this.fields.spruceDataStatus = false;
      this.loggerService.log('spruceStatus check', this.fields.spruceDataStatus);
      this.plantPreview.bloomTime = '🌸 🌺 🌻 🌼';
      this.plantPreview.hardinessZone = '🌸 🌺 🌻 🌼 ';
      this.plantPreview.extTypicalSize = '🌸 🌺 🌻 🌼';
      this.plantPreview.nativeArea = '🌸 🌺 🌻 🌼';
      this.plantPreview.sunExposure = '🌸 🌺 🌻 🌼';
      this.plantPreview.blurb = this.itemForm.value.blurb
        ? this.itemForm.value.blurb
        : 'Please either type in a blurb or select another description';
        this.loggerService.log('this.fields.spruceDataStatus', this.fields.spruceDataStatus);
        this.unLink();
    }

    setExternalPlantData() {
      this.plantPreview.bloomTime = this.selectedExternalPlant.bloomTime;
      this.plantPreview.hardinessZone = this.selectedExternalPlant.hardinessZone;
      this.plantPreview.extTypicalSize = this.selectedExternalPlant.matureSize;
      this.plantPreview.nativeArea = this.selectedExternalPlant.nativeArea;
      this.plantPreview.sunExposure = this.selectedExternalPlant.sunExposure;
      this.plantPreview.blurb = this.selectedExternalPlant.description;
    }

      /**
   * clear values of spruce data
   */
  unLink() {
    this.itemForm
    .get('externalPlantData_ref')
    ?.setValue(null);
    this.itemForm
    .get('spruceDataStatus')
    ?.setValue(false);
    this.fields.spruce_img = null;

    this.itemForm.get('externalPlantData_ref')?.markAsTouched();
    this.itemForm.get('spruceDataStatus')?.markAsTouched();
    this.messageService.add({
      severity: 'success',
      summary: 'Unlinking Successful',
      detail: `Spruce Data has been succesfully unlinked`,
    });
  }
}
