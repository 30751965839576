import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { LoggerService } from 'src/app/common/services/logger.service';
import CategoryDTO from '../../dtos/CategoryDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-category-form-v2',
  templateUrl: './category-form-v2.component.html',
  styleUrls: ['./category-form-v2.component.sass']
})
export class CategoryFormV2Component extends BaseDynamicFormComponent implements  OnInit
{
  categoryRecord: CategoryDTO = new CategoryDTO();
  categoryId: number;
  type: string = "categories";
  isEditMode: boolean = false;
  isLoading: boolean = true;
  options: any = [];
  showClimateIcons: boolean = false;
  climateIcons: any = []
  defaultDate: any;

  constructor
  (
    private logger: LoggerService,
    private validation: BaseInputValidationServiceService
  ) {
    super();
  }

  /**
   * Category Form V2 Component Initialization
   */
  async ngOnInit()
  {
    this.fields =
    [
      {
        title: "Category Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Required",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Checkboxv2],
            key: 'required',
            isRequired: false,
            label: 'Required',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.categoryRecord.start,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: "Types",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'types_ref',
            isRequired: false,
            label: 'Types',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'types_ref', apiController: 'item-types',
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id',
              isArrayOfStringIds: true
            },
          },
          {
            name: "Short Description",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputTextArea],
            clearField: false,
            key: 'descriptionShort',
            isRequired: false,
            label: 'Short Description',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Color",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: false,
            key: 'color',
            isRequired: true,
            label: 'Color',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Average Coin Cost",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'averageCoinCost',
            isRequired: false,
            label: 'Average Coin Cost',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Average Diamond Cost",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'averageDiamondCost',
            isRequired: false,
            label: 'Average Diamond Cost',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Expected Coin Spend",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'expectedCoinSpend',
            isRequired: false,
            label: 'Expected Coin Spend',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Expected Diamond Spend",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: false,
            key: 'expectedDiamondSpend',
            isRequired: false,
            label: 'Expected Diamond Spend',
            disabled: false,
            columnWidth: 6
          },
          {
            name: "Organic",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Checkboxv2],
            key: 'organic',
            isRequired: false,
            label: 'Organic',
            disabled: false,
            columnWidth: 12
          },
          {
            name: "Icon Size",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'iconSize',
            label: 'Icon Size',
            inputNumberShowButtons: true,
            columnWidth: 6
          },
          {
            name: "Bubble Size",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'bubbleSize',
            label: 'Bubble Size',
            inputNumberShowButtons: true,
            columnWidth: 6
          },
          {
            name: "Border Width",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'borderWidth',
            label: 'Border Width',
            inputNumberShowButtons: true,
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Audio",
        fields:
        [
          {
            name: "Spawn Audio",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'spawnAudios_ref',
            isRequired: false,
            label: 'Spawn Audio',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'spawnAudios_ref',
              apiController: 'miscellaneous-build',
              customQuery: { assetType: { $in: [22] } },
              autopopulate: false, virtuals: false,
              sort: { name: 1 },
              select: 'name id path _id',
              isArrayOfStringIds: true
            },
          },
          {
            name: "Spawn Audio Collection",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'spawnAudioCollections_ref',
            isRequired: false,
            label: 'Spawn Audio Collection',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'spawnAudioCollections_ref',
              apiController: 'audio-collections',
              customQuery: { type: 1 },
              autopopulate: false, virtuals: false,
              sort: { name: 1 },
              select: 'name id _id',
              isArrayOfStringIds: true
            },
          },
          {
            name: "Loop Audio",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'loopAudios_ref',
            isRequired: false,
            label: 'Loop Audio',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'loopAudios_ref',
              apiController: 'miscellaneous-build',
              customQuery: { assetType: { $in: [23] }},
              autopopulate: false, virtuals: false,
              sort: { name: 1 },
              select: 'name id _id',
              isArrayOfStringIds: true
            },
          },
          {
            name: "Loop Audio Collection",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'loopAudioCollections_ref',
            isRequired: false,
            label: 'Loop Audio Collection',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'loopAudioCollections_ref',
              apiController: 'audio-collections',
              customQuery: { assetType: { $in: [23] }},
              autopopulate: false, virtuals: false,
              sort: { name: 1 },
              select: 'name id _id',
              isArrayOfStringIds: true
            },
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Icons",
        fields:
        [
          {
            name: "",
            inputType: DynamicInputType.CategoryIcon,
            clearField: true,
            key: '',
            customFields:
            [
              {
                key: 'icons',
                touched: false
              },
            ],
            hideOnCreate: true
          },
          {
            name: "",
            inputType: DynamicInputType.CategoryClimateIcon,
            clearField: true,
            key: '',
            customFields:
            [
              {
                key: 'climateIcons',
                touched: false
              },
            ],
            hideOnCreate: true
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Bundle Images and Assets",
        fields:
        [
          {
            name: 'Bundle Image (IOS)',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            key: 'bundleImage',
            label: 'Bundle Image (IOS)',
            columnWidth: 6
          },
          {
            name: 'Bundle Image (AND)',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            key: 'bundleImageAnd',
            label: 'Bundle Image (AND)',
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },



    ]
    this.title = "Categories";
    this.viewRoute = 'categories';
    this.isLoading = false;
  }

  /**
   * Validate Category Forms
   */
  validateCategories(form: any)
  {
    let confirmValidationCallback = this.validation.colorValidator(form.color);
    let message = confirmValidationCallback ? 'Valid' : 'The color entered is invalid. Please enter a valid hex color.';

    return { confirmValidationCallback, message }
  }
}
