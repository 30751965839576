import { Title } from '@angular/platform-browser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';
import { TableService } from 'src/app/services/table.service';
import { ConfirmationService } from 'primeng/api';
import { LoggerService } from 'src/app/common/services/logger.service';

@Component({
  selector: 'app-challenge-keyword',
  templateUrl: './challenge-keyword.component.html',
  styleUrls: ['./challenge-keyword.component.sass'],
  providers: [ConfirmationService],
})
export class ChallengesKeywordsComponent implements OnInit 
{
  type: string = 'challenge-keywords';
  key: string = '';

  submitUrl: string = '';
  action = 'add';
  id: number;
  isEditMode: boolean = false;
  existingDoc: any = {};

  cols: any;
  rows: any;
  challengeTypeRows: any;
  display: boolean = false;
  user: any;
  submitted: boolean;
  userDialog: any;
  checked: boolean;

  fields: any = {};
  selectedRows: any[] = [];
  userForm: UntypedFormGroup;

  newRecordData: any;
  newRecordFields: any = {};
  newRecordContainer: any;
  newRecordSubmitted: any;
  newRecordDialog: any;
  newRecordForm: UntypedFormGroup;
  editRecordDialog: any;



  constructor(
    private messageService: MessageService,
    private tableService: TableService,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private dataService: DataService,
    private authService: AuthService,
    private loggerService: LoggerService,
    private confirmationService: ConfirmationService,
    private titleService: Title,
  ) {}

  async ngOnInit() {
    this.fields = {
      username: null,
      password: null,
      enabled: true,
    };

    this.newRecordFields = {
      name: null,
      description: null,
      enabled: true,
    };

    await this.initForm();

    // Get row data
    await this.tableService.getRows(this.type).then(async (data: any) => {
      this.rows = data;
    });

    // get managed lists data
    await this.tableService
      .getRows('challenge-keywords')
      .then(async (data: any) => {
        this.loggerService.log(`challengeskeywords:`, data);
        this.challengeTypeRows = data;
      });

        this.titleService.setTitle(`Challenge Keywords`)
  }

  /**
   * Initialize the Form
   */
  async initForm() {

    this.newRecordForm = this.fb.group({
      name: [this.newRecordFields.name],
      description: [this.newRecordFields.description],
      enabled: [this.newRecordFields.enabled],
    });

  }

  /**
   * sets-up variables for a new form submission
   * @param entity entity name that we are trying to open
   */
  openNew(entity: string) {
      this.user = {};
      this.submitted = false;
      this.userDialog = true;
      this.newRecordDialog = true;
    }

    /**
     * Hide currrent user dialog
     */
  hideDialog() {
    this.submitted = false;
    this.newRecordDialog = false;
    this.editRecordDialog = false;
  }
  async updateNewRecord() {
    let id = this.newRecordForm.get('id')!.value;
    let name = this.newRecordForm.get('name')!.value;
    let description = this.newRecordForm.get('description')!.value
    let enabled = this.newRecordForm.get('enabled')!.value;

    this.loggerService.log('id', id);
    this.loggerService.log('name', name);
    this.loggerService.log('description', description)
    this.loggerService.log('enabled', enabled);

    await this.dataService.updateRecord(id, this.type, {
      name: name,
      description: description,
      enabled: enabled,
    });
    await this.tableService.getRows(this.type).then(async (data: any) => {
      this.challengeTypeRows = data;
    });
    this.editRecordDialog = false;
  }

  /**
   * Sets all the necessary variables and submits the form
   */
  onSubmit() {
    this.submitted = true;
    this.userDialog = false;
    var { value } = this.userForm;
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    this.formService
      .submitForm(value, this.submitUrl, this.isEditMode)
      .subscribe(
        async (val) => {
          console.log('POST call successful value returned in body', val);
          // adjust submit message for edit/new.
          if (this.isEditMode) {
            this.messageService.add({
              sticky: true,
              severity: 'success',
              summary: 'Update Successful',
              detail: `Keyword was successfully updated`,
            });
            window.scrollTo(0, 0);
            this.userDialog = false;
          } else {
            this.messageService.add({
              sticky: true,
              severity: 'success',
              summary: 'Submit Successful',
              detail: `Keyword was successfully created`,
            });
            window.scrollTo(0, 0);
            await this.tableService
              .getRows(this.type)
              .then(async (data: any) => {
                this.rows = data;
              });
          }
        },
        async (response) => {
          console.log('POST call in error', response);

          let summary: string = "Submit Error";
          let error: string = "There was an error submitting.";

          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: summary,
            detail: error
          });
          window.scrollTo(0, 0);
          await this.tableService.getRows(this.type).then(async (data: any) => {
            this.rows = data;
          });
        },
        () => {
          console.log('The POST observable is now completed.');
        }
      );
  }

  /**
   * Submits the record to DB
   */
  submitNewRecord() {
    this.loggerService.log('onSubmitNewRecord');
    this.submitted = true;
    this.newRecordDialog = false;
    this.submitUrl = 'challenge-keywords/add';
    var { value } = this.newRecordForm;

    this.loggerService.log('submitUrl', this.submitUrl);
    this.loggerService.log('value', value);
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };
    this.formService.submitForm(value, this.submitUrl, false).subscribe(
      async (val) => {
        console.log('POST call successful value returned in body', val);
        this.messageService.add({
          sticky: true,
          severity: 'success',
          summary: 'Submit Successful',
          detail: `"${value.name}" was successfully created`,
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.challengeTypeRows = data;
        });
      },
      async (response) => {
        console.log('POST call in error', response);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Submit Error',
          detail: 'There was an error submitting.',
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.challengeTypeRows = data;
        });
      },
      () => {
        this.newRecordForm.reset();
        console.log('The POST observable is now completed.');
      }
    );
  }
  /**
   * Setups the modal to edit the record
   * @param record 
   */
  editNewRecordModal(record: any) {
    this.isEditMode = true;
    this.editRecordDialog = true;

    this.newRecordForm = this.fb.group({
      id: [record.id],
      name: [record.name],
      description: [record.description],
      enabled: [record.enabled],
    });
  }

  /**
   * Setups the confirmation to delete a record modal
   * @param record
   */
  confirmDeleteNewRecordModal(record: any) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete ${record.name}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.deleteRecord(record.id, this.type);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.challengeTypeRows = data;
        });
      },
    });
  }
}
