<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="loading-screens"
    [viewName]="title"
    [record]="LoadingScreenRecord"
    [fields]="fields"
    [sidebarFields]="sidebarFields"
    [miscAssetKeys]="miscAssetKeys"

>
</dynamic-view-v2>
