<div class="p-my-5 p-inputgroup" *ngIf="!isLoading">
    <div class="p-m-2">
        <span
            [style]="{ 'min-width': '16.5em' }"
            ngClass="p-float-label"
        >
        <div>
            <p-fieldset class="p-formgroup-inline">
                <!-- Header -->
                <ng-template pTemplate="header">
                    <div class="p-formgroup-inline">
                      <span class="p-my-auto p-mr-2">Color(s)</span>
                      <!-- Add Reward Button -->
                      <button
                        pButton
                        icon="pi pi-plus p-panel-header-icon"
                        class="p-button-rounded p-button-text p-button"
                        (click)="addColor()"
                        type="button"
                      ></button>
                    </div>
                  </ng-template>
                  <span class="p-formgroup-inline">
                    <div
                        *ngFor="let color of selectedColors; let pointIndex = index"
                    >
                    <p-card class="p-formgroup p-m-2 p-shadow-4">
                        <ng-template pTemplate="header">
                            <button
                              pButton
                              type="button"
                              icon="pi pi-times"
                              class="p-button-rounded p-button-text p-button-danger float-right"
                              (click)="deleteColor(pointIndex)"
                            ></button>
                        </ng-template>

                        <ng-template pTemplate="content">
                            <div class="p-mt-2">
                                <p-colorPicker 
                                    [(ngModel)]="color.value"
                                    (onChange)="colorSelected($event, pointIndex)"
                                    name="colorPicker" 
                                    ngDefaultControl
                                >
                                </p-colorPicker>
                            </div>
                            <div class="p-mt-4">
                                <span
                                  [style]="{ 'min-width': '12.0em' }"
                                  ngClass="p-float-label"
                                >
                                    <input 
                                        type="text" 
                                        pInputText 
                                        [(ngModel)]="color.value"
                                        [ngModelOptions]="{ standalone: true }"
                                        (ngModelChange)="onChange($event, pointIndex)"
                                        class="field-item"
                                        id="colorText"
                                    > 
                                    <label for="colorText"> Color Value </label>
                                </span>
                              </div>
                        </ng-template>
                    </p-card>
                    </div>
                  </span>
            </p-fieldset>
        </div>
        </span>
    </div>
</div>