import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import SeriesDto from '../../../dtos/SeriesDTO';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';


@Component({
  selector: 'app-series-form-v2',
  templateUrl: './series-form-v2.component.html',
  styleUrls: ['./series-form-v2.component.sass']
})
export class SeriesFormV2Component extends BaseDynamicFormComponent implements OnInit {
  seriesRecord: SeriesDto = new SeriesDto();
  title: string = 'series';
  isLoading: boolean = false;
  options: any = {
    collections_ref: [],
    environments_ref: [],
    stages_ref: []
  };

  constructor(
    private validation: BaseInputValidationServiceService,
    private agGridToolsService: AgGridToolsService
  ) {
    super();
  }

  ngOnInit() {
    this.fields = [
      {
        title: "Series Data",
        fields: [
          {
            name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'displayName',
            isRequired: true,
            label: 'Internal Name',
            columnWidth: 4,
            disabled: false
          },
          {
            name: "Display Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: false,
            key: 'name',
            isRequired: true,
            label: 'Display Name',
            columnWidth: 4,
            disabled: false,
          },
          {
            name: "Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: false,
            options: {
                fieldName: 'type',
                values: [
                    { name: 'Mini Series', value: 'Mini Series' }, 
                ]
            },
            columnWidth: 3
          },        
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            columnWidth: 6,
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            columnWidth: 6,
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.seriesRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: "Colors",
            inputType: DynamicInputType.NestedInputComponent,
            key: 'colors',
            label: 'Colors',
            options: {
              headerTitle: "Colors",
              addButtonText: "Add Color",
              useStringArrayFormat: true,
              newRecordTemplate: '',
              fieldName: 'Colors',
              subFields: [
                {
                  name: "Color",
                  inputType: DynamicInputType.BaseInputField,
                  inputTypeFields: [InputFieldType.ColorPicker],
                  key: 'color',
                  isRequired: true,
                  label: 'Color'
                }
              ]
            }
          },
          {
            name: 'Internal Notes',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Editor],
            key: 'copy',
            label: 'Internal Notes',
            columnWidth: 12,
            clearField: true,
            isRequired: false,
            disabled: false,
          },
          {
            name: 'Is Infinite Challenges Enabled',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Checkboxv2],
            key: 'isInfiniteChallengeIncluding',
            label: 'Is Infinite Challenges Enabled',
            columnWidth: 12,
            clearField: true,
            isRequired: false,
            disabled: false,
          },
          {
            name: 'Announcement Inbox Record',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'announcementInbox_ref',
            isRequired: false,
            label: 'Announcement Inbox Record',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: {
              fieldName: 'announcementInbox_ref',
              apiController: 'inbox-messages',
              minimal: true,
              autopopulate: false,
              virtuals: false,
              select: '_id name id',
              sort: { createdAt: 1 },
              limit: 10
            },
            columnWidth: 12,
            scrollHeight: '200px',
          },
          {
            name: "Inbox Messages",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'inboxMessages_ref',
            isRequired: false,
            label: 'Inbox Messages',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            options: {
              fieldName: 'inboxMessages_ref', apiController: 'inbox-messages',
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id',
              isArrayOfStringIds: true
            },
            columnWidth: 12
          },
          {
            name: 'Items Collection',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'collection_ref',
            isRequired: false,
            label: 'Items Collection',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: {
              fieldName: 'collection_ref',
              apiController: 'collections',
              minimal: true,
              autopopulate: false,
              virtuals: false,
              select: '_id name id',
              sort: { createdAt: 1 },
              limit: 10
            },
            columnWidth: 12,
            scrollHeight: '200px',
          },
          {
            name: "Item Collection (v2)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'collections_ref',
            isRequired: false,
            label: 'Item Collection (v2)',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            options: {
              fieldName: 'collections_ref', apiController: 'collections',
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id',
              isArrayOfStringIds: true
            },
            columnWidth: 12
          },
          // {
          //   name: 'Item Collection (v2)',
          //   inputType: DynamicInputType.BaseInputField,
          //   inputTypeFields: [InputFieldType.AutoComplete],
          //   key: 'collections_ref',
          //   label: 'Item Collection (v2)',
          //   columnWidth: 8,
          //   clearField: true,
          //   autocompleteMultipleValues: true,
          //   autocompleteShowEmptyMessage: true,
          //   autocompleteField: 'name',
          //   autocompleteMinLength: 3,
          //   suggestions: {
          //     fieldName: 'collections_ref',
          //     apiController: 'collections',
          //     autopopulate: false,
          //     minimal: false,
          //     virtuals: false,
          //     customQueryField: 'name',
          //     select: '_id name id',
          //   },
          //   style: { width: '100%' },
          //   showClear: true
          // },
          {
            name: 'Reward(s)',
            inputType: DynamicInputType.Price,
            options: { fieldName: '', version: 3 },
            key: 'rewards_ref',
            priceExcludeResourceTypes: ['Titles', 'Nurture', 'SeedPackBox', 'StoreListing'],
            columnWidth: 12
          },
          {
            name: 'Challenges',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            key: 'challenges_ref',
            label: 'Challenges',
            columnWidth: 12,
            clearField: true,
            autocompleteMultipleValues: true,
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'challenges_ref',
              apiController: 'challenges',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id',
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: 'Challenge Collection',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'challengeCollection_ref',
            isRequired: false,
            label: 'Challenge Collection',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: {
              fieldName: 'challengeCollection_ref',
              apiController: 'challenge-collections',
              minimal: true,
              autopopulate: false,
              virtuals: false,
              select: '_id name id',
              sort: { createdAt: 1 },
              limit: 10
            },
            scrollHeight: '200px',
            columnWidth: 6
          },
          {
            name: 'Secondary Challenge Collection (NON I.G Logic)',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'secondaryChallengeCollection_ref',
            isRequired: false,
            label: 'Secondary Challenge Collection (NON I.G Logic)',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: {
              fieldName: 'secondaryChallengeCollection_ref',
              apiController: 'challenge-collections',
              minimal: true,
              autopopulate: false,
              select: '_id name id',
              virtuals: false,
              sort: { createdAt: 1 },
              limit: 10
            },
            scrollHeight: '200px',
            columnWidth: 6
          },
          {
            name: 'loading Screens',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            key: 'loadingScreen_ref',
            label: 'Loading Screens',
            columnWidth: 12,
            clearField: true,
            autocompleteMultipleValues: true,
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'loadingScreen_ref',
              apiController: 'loading-screens',
              autopopulate: false,
              minimal: true,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id',
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Collection Store Listing",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'collectionStoreListings_ref',
            isRequired: false,
            label: 'Collection Store Listing',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            options: {
              fieldName: 'collectionStoreListings_ref', apiController: 'store-listings-v2',
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id',
              isArrayOfStringIds: true
            },
            columnWidth: 12
          },
          {
            name: "Store Listings",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'storeListings_ref',
            isRequired: false,
            label: 'Store Listings',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            options: {
              fieldName: 'storeListings_ref', apiController: 'store-listings-v2',
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id',
              isArrayOfStringIds: true
            },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },

    ];

    this.viewRoute = 'series';
    this.isLoading = false;
  }
}
