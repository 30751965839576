<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="nurture-stage"
    [viewName]="title"
    [record]="nurtureStageRecord"
    [fields]="fields"
    [sidebarFields]="sidebarFields"
    [miscAssetKeys]="miscAssetKeys"
    [showLocalizedValuesButton]="true"
>
</dynamic-view-v2>
