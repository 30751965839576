<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="minigames"
    [formName]="title"
    [record]="miniGameRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [autopopulateEntity]="true"
    [validationCallbackFunction]="validateMiniGame.bind(this)"
>
</dynamic-form-v2>
