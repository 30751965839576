import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-localized-table',
  templateUrl: './localized-table.component.html',
  styleUrls: ['./localized-table.component.sass']
})
export class LocalizedTableComponent {
  @Input() visible: boolean = false;
  @Input() title: string = 'Localized Values';
  @Input() data: any[] = [];

  getColumns(data: any[]): string[] {
    if (data.length === 0) {
      return [];
    }
    return Object.keys(data[0]).filter(key => key !== 'id');
  }
}
