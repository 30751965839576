import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/user/service/user.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { BuildService } from 'src/app/build-data/services/build.service';
import { FormService } from 'src/app/services/form.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { MessageService } from 'primeng/api';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { Clipboard } from '@angular/cdk/clipboard'
import { pathconfigValues } from '../data/constants';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class ChallengeService
{
  [x: string]: any;
  editURL: string = '';
  type: string = 'challenges';
  page: string = 'view';
  id: string;
  entity: any;

  s3Cdn: string = 'https://d3tfb94dc03jqa.cloudfront.net/';
  private readonly apiURLprefix : string = '/api/challenges';
  private readonly apiExternal : string = '/api/sourcing-challenge-groups';

  constructor
  (
    private http: HttpClient,
    private userService: UserService,
    private commonEntityService: CommonEntityService,
    private loggerService: LoggerService,
    private buildService: BuildService,
    private formService: FormService,
    private utilitiesService: UtilitiesService,
    private messageService: MessageService,
    private datePipe: DatePipe,
    private clipboard: Clipboard,
    private dataService: DataService,
    private router: Router,
  ) {}

  /**
   * Retrieves a Challenge by a given query
   *
   * @param query Query data
   */
  async findChallenge(query: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/find`, query).toPromise();
  }

  /**
   * Updates a Challenge record.
   *
   * @param challengeId Id of the challenge to update.
   * @param challengeData Challenge data to be updated.
   */
  async updateChallenge(challengeId: any, challengeData: any): Promise<any>
  {
    return await this.http.patch(`${this.apiURLprefix}/update/${challengeId}`, challengeData).toPromise();
  }

  /**
   * Updates Spawners data
   *
   * @param payload Entity Id and challenge path
   */
  async updateSpawnersDataCall(challengeId: any, path: any): Promise<any>
  {
    return await firstValueFrom(this.http.patch(`${this.apiURLprefix}/${challengeId}/spawners`, { entityId: challengeId, path: path}));
  }

  async uploadToCollection(payload: any): Promise<any>
  {
    return await firstValueFrom(this.http.post(`${this.apiExternal}/upload-to-collections`, payload));
  }

  /**
   * Upload Sourcing Items to collection
   * 
   * @param payload List of sourcing items and Collection Id
   */
  async createCollection(payload: any): Promise<any>
  {
    return await firstValueFrom(this.http.post(`${this.apiExternal}/create-collection`, payload));
  }

  /**
   * Retrurns the level name for a Challenge.
   *
   * @param sceneType Challenge Scene Type.
   * @param isGrand Flag that sets whether or not is a grand challenge.
   */
  constructLevelName(sceneType: any, isGrand: boolean)
  {
    let initials: string = 'xx';

    return `${isGrand ? 'Grand' : initials}_challengeID_${
      sceneType ? sceneType.name : 'placeholder'
    }`;
  }

  /**
   * Validate if Challenge meets Environment Flags Requirements.
   *
   * @param challenge Challenge record.
   */
  /**
* Validate if Challenge meets Environment Flags Requirements.
*
* @param challenge Challenge record.
*/
async checkEnvFlagsRequirements(challenge: any) {
  let response =
  {
    devReady: true,
    qaReady: true,
    prodReady: true,
    devAdded: true,
    qaAdded: false,
    prodAdded: false,
  };

  if(challenge.env && challenge.env.length > 0)
  {
    if(challenge.env.includes('qa'))
    {
      if(!challenge.start || !challenge.end)
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Validation error',
            detail: `The challenge is not QA or Prod ready because it lacks start and/or end date(s).`
          }
        );
        response.qaReady = false;
        response.prodReady = false;
      }
      response.qaAdded = true;
    }
    else
    {
      if(challenge.env.includes('prod')){
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Validation error',
            detail: `The challenge is not Prod ready because it is not marked for QA.`
          }
        );
        response.prodReady = false;
      }
    }

    if(challenge.env.includes('prod'))
    {
      if(!challenge.prizes_ref || challenge.prizes_ref.length == 0)
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Validation error',
            detail: `The challenge is not Prod ready because it lacks prizes references.`
          }
        );
        response.prodReady = false;
      }

      if(challenge.prizes_ref && challenge.prizes_ref.length != 2)
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Validation error',
            detail: `The challenge is not Prod ready because it requires exactly 2 prizes references.`
          }
        );
        response.prodReady = false;
      }
      response.prodAdded = true;
    }
  }

  return response;
  }

  /**
   * sums or averages the economy object
   * @param spawner spawner obj
   * @param mode how to reduce the obj
   * @param currency the type of currency to reduce
   * @param length the amount of objects for the average
   * @returns the reduce result
   */
  reduceEconomy(spawner: any, mode: String, currency: String, length: number = 1) {
    const result =
    spawner.economyObject.reduce((accumulator: any, obj: any) => {

      if (currency == 'Coin') {
        return mode == 'expected' ?  accumulator + obj.expectedCoinSpend : accumulator + obj.averageCoinCost
      } else {
        return mode == 'expected' ?  accumulator + obj.expectedDiamondSpend : accumulator + obj.averageDiamondCost
      }
    }, 0);

    return result / length;
  }


  /**
   * Updates the build data for the entity.
   */
  async updateBuildData(scene_ref: any, image_ref: any, type: any, id: any, entity: any)
  {
    setTimeout(async () =>
    {
      let record = await this.commonEntityService.findOneWithQuery(type, { query: { id: id } });

      if(record)
      {
        scene_ref = record.scene_ref;
        image_ref = record.image_ref;
      }
      // get build data.
      await this.getBuildData(scene_ref, image_ref);
      this.loggerService.log('got updated entity', entity);
    }, 500);
  }

   /**
   * Retrieves the build data for the entity.
   */
   async getBuildData(scene_ref: any, image_ref: any)
   {
     if(scene_ref && scene_ref.buildData)
     {
       let response = await this.buildService.getBuildData(scene_ref.buildData);
       scene_ref.buildData = response.buildData;
       scene_ref.buildStatus = response.buildStatus;
     }
     else
     {
       scene_ref.buildData = [];
       scene_ref.buildStatus = {
         text: 'Build Status Not Found',
         date: '',
         color: 'var(--gray-400)',
         buildCount: 0,
       };
     }
     if(image_ref && image_ref.buildData)
     {
       let response = await this.buildService.getBuildData(image_ref.buildData);
       image_ref.buildData = response.buildData;
       image_ref.buildStatus = response.buildStatus;
     }
     else
     {
       image_ref.buildData = [];
       image_ref.buildStatus = {
         text: 'Build Status Not Found',
         date: '',
         color: 'var(--gray-400)',
         buildCount: 0,
       };
     }
   }
   /**
   * Sets the build data for the entity.
   */
  async setBuildData(scene_ref: any, image_ref: any)
  {
    let sceneBuildData = scene_ref ? scene_ref.buildData : null;
    let imageBuildData = image_ref ? image_ref.buildData : null;

    let sceneResponse = await this.buildService.getBuildData(sceneBuildData);

    if(sceneResponse)
    {
      scene_ref = scene_ref == undefined ? {} : scene_ref;
      scene_ref.buildData = sceneResponse.buildData;
      scene_ref.buildStatus = sceneResponse.buildStatus;
    }

    let imageResponse = await this.buildService.getBuildData(imageBuildData);

    if(imageResponse)
    {
      image_ref = image_ref == undefined ? {} : image_ref;
      image_ref.buildData = imageResponse.buildData;
      image_ref.buildStatus = imageResponse.buildStatus;
    }
  }

  /**
   * Sums up and returns the totals for the spawners
   * @param totals total of the amount of diamond and coin
   * @returns the array of totals
   */
  getTotals(totals: any) {
    const result = [{
      totalExpectedDiamond: totals.reduce((accumulator: any, obj: any) => { return accumulator + obj.expectedDiamondSpend}, 0),
      totalExpectedCoin: totals.reduce((accumulator: any, obj: any) => { return accumulator + obj.expectedCoinSpend}, 0),
    }]
    return result;
  }

  /**
   * Updates ExtraData property for a given challenge
   */
  async updateSpawnersData(id: any, entity: any)
  {
    let path = 'approved_assets/' + entity.scene.substr(0, entity.scene.lastIndexOf('/'));
    let response = await this.updateSpawnersDataCall(id, path);

    if(response !== null && response.success)
    {
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'success',
          summary: 'Entity ExtraData updated',
          detail: response !== null ? response.message : '',
        }
      );
    }
    else
    {
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'Entity ExtraData update error',
          detail: response !== null ? response.message : '',
        }
      );
    }
  }
  parseValueForView(data: any, field: any) {
    if (!data && !['toggleButton'].includes(field.controlType)) return '';

    let output;
    switch (field.controlType) {
      case 'inputText':
      case 'inputTextarea':
      case 'dropdown':
      case 'links':
      case 'auto':
        output = data;
        break;
      case 'date':
      case 'calendar-start':
      case 'calendar-end':
        output = this.datePipe.transform(new Date(data), 'medium');
        break;
      case 'toggleButton':
        data == true ? (output = field.name) : (output = `Not ${field.name}`);
        break;
      case 'inputNumber-buttons':
        output = data.toString();
        break;
      case 'dropdown_ref':
      case 'autoComplete_ref':
      case 'auto_ref':
        if (field.key == 'progressionLevel_ref') {
          output = data.level;
          this.loggerService.log('field data level', data);
        } else {
          this.loggerService.log('field datal level', data);
          output = data.name;
        }
        break;
      case 'multiSelect':
        output = data.join(', ');
        break;

      case 'multiSelect_ref':
      case 'autoComplete-multi_ref':
        let values: any[] = [];
        data.forEach((o: any) => {
          values.push(o.name);
        });
        output = values.join(', ');
        break;
      case 'formArray':
        if (['prizes_ref', 'rewards_ref'].includes(field.key)) {
          output = data;
          this.loggerService.log('prize output', output);
        } else {
          let groups: any[] = [];
          data.forEach((group: any) => {
            let values: any[] = [];
            for (const [key, value] of Object.entries(group)) {
              if (['string', 'number', 'bigint'].includes(typeof value)) {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'auto',
                  })}`
                );
              } else if (['boolean'].includes(typeof value)) {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'toggleButton',
                  })}`
                );
              } else {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'dropdown_ref',
                  })}`
                );
              }
            }
            groups.push(values.join(', '));
          });
          output = groups.join(`, `);
        }
        break;

      default:
        return '';
    }

    return output;
  }
  getValueAsString(key: any, entity: any) {
    let value = entity[key];

    if (/_ref/.test(key)) {
      if (key == 'costs_ref') {
        return value;
      } else {
        return value ? value.name : '';
      }
    } else if (key == 'createdBy') {
      if (value && value.firstName) {
        return value.firstName + ' ' + value.lastName;
      } else {
        return 'ERROR WITH USER';
      }
    } else if (Array.isArray(value)) {
      return value.toString();
    } else if (key == 'year') {
      return value;
    } else if (this.isDate(value)) {
      return this.datePipe.transform(new Date(value), 'medium');
    } else {
      return value;
    }
  }

  isEmpty(value: any) {
    if (value === '' || value === null || value === undefined) return true;
    return false;
  }

  isDate(value: any) {
    let x = new Date(value).getTime();

    if (isNaN(x) || x < 10000000) {
      return false;
    } else return true;
  }

  chunkArray(array: string | any[]) {
    if (array.length > 1) {
      return _.chunk(array, array.length / 2 + 1);
    } else return _.chunk(array, array.length);
  }



  getMostRecent(dates: any[]) {
    let allDates: any[] = [];
    dates.forEach((d) => {
      allDates.push(Date.parse(d));
    });

    return new Date(Math.max(...allDates)).toLocaleDateString();
  }

  /** Uses field key to dynamically parse path for image retrieval.
   * @param field FieldData
   */
  populateImgField(field: any, entity: any) {
    if (field.key.endsWith('_img')) {
      // define path
      const pathKey: any = Object.keys(entity).find(
        (key: string) =>
          key === field.key.substring(0, field.key.indexOf('_img'))
      );
      const path = ['externalPlantData_ref'].includes(pathKey)
        ? entity[pathKey].imagePath
        : entity[pathKey];

      if (path) {
        // define default size & fileExt
        const size: string = this.page === 'table' ? '_256' : '_1024';
        const fileExt: string =
          path.endsWith('.png') || path.endsWith('.jpg')
            ? ''
            : this.type === 'challenges' && pathKey === 'image'
            ? '.jpg'
            : '.png';
        const n =
          pathKey === 'scene' ? path.lastIndexOf('s/') : path.lastIndexOf('/');
        const rString = path.substring(n + 1);

        let output;
        if (this.type === 'challenges' && pathKey === 'scene') {
          let array = rString.split('/');
          array[2] = array[2] + '_bg';
          output =
            this.s3Cdn +
            'images/bg_renders' +
            array.join('/') +
            '_bg' +
            size +
            fileExt;
        } else {
          output =
            (this.type === 'items' && pathKey === 'thumbnail') ||
            (this.type === 'challenges' && pathKey === 'image')
              ? this.s3Cdn + path + '/' + rString + size + fileExt
              : this.s3Cdn + path + fileExt;
        }

          entity[field.key] = output;
      } else {
          entity[field.key] = null;
      }
    }
  }

  /**
   * builds the categories with their appropiate spend and costs
   * @returns the formatted categories
   */
  async buildCategories(categories: any) {
    var output =
      categories.map((category: any) => ({
        categoryName: category.name,
        id: category.id,
        averageCoinCost: category.averageCoinCost ? category.averageCoinCost : 0,
        averageDiamondCost: category.averageDiamondCost ? category.averageDiamondCost : 0,
        expectedDiamondSpend: category.expectedDiamondSpend ? category.expectedDiamondSpend : 0,
        expectedCoinSpend: category.expectedCoinSpend ? category.expectedCoinSpend : 0,
        iconPath: category.iconPath ? category.iconPath : null
    }));
    return output;
  }

  /**
   * Setups the spawners' economy object
   */
  async setupSpawners(entity: any, spawners: any, categories: any, optionalSpawner: any, requiredSpawner: any) {
    if (entity.parsedSpawners)
    {
      if(spawners && spawners.length > 0)
      {
        spawners.forEach((spawner: any) => {
          spawner['economyObject'] = [];
          spawner.categories.map((category: any) => {
            spawner['economyObject'].push(categories.find((x: any) => (x.categoryName === category || x.id === category)))
          })
         })
        optionalSpawner = spawners.filter((obj: any) => !obj.isRequired)
        requiredSpawner = spawners.filter((obj: any) => obj.isRequired)


      }
    };
    return [optionalSpawner, requiredSpawner]
  }

  /**
   *
   * @param copyVal // text to copy into the user's clipboard
   */
  copyToClipboard(copyVal: string){
    this.clipboard.copy(copyVal);
    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Thumbnail Copied',
      detail: `URL copied to clipboard successfully!`,
    });
  }

  /**
   * Duplicates the row and adds _copy to unique values or sets them to null
   */
   async duplicateChallenge(entity: any, component: any) {
    let fieldsToDelete = ['id', 'imageBuildData', 'imageBuildStatus', 'buildStatus', 'assetBuildOutput',
    'buildData', 'imageBuildOutput', 'imageLastHash', 'imageLastVersion', 'asset_versions' , 'image_versions', 'assetLastHash'];
    //makes a deep copy of the row
    const originalChallenge = _.cloneDeep(entity);
    delete originalChallenge['image_versions']
    delete originalChallenge['asset_versions']
    const duplicatedChallenge = entity;
    this.loggerService.log('Original Entity', originalChallenge)
    //changes file name to recognize a copy and comply with unique filenames
    duplicatedChallenge.id = null;
    duplicatedChallenge.start = null;
    duplicatedChallenge.end = null;
    duplicatedChallenge.prizes = null;
    duplicatedChallenge.prizes_ref = [];
    duplicatedChallenge.rewards_ref = [];
    duplicatedChallenge.rewards = null;
    duplicatedChallenge.fileType = 'Duplicate'
    duplicatedChallenge.env = ['dev'];
    duplicatedChallenge.enabled = false;
    duplicatedChallenge.artist_ref = duplicatedChallenge.artist_ref ? duplicatedChallenge.artist_ref : null;


    // We need to set filename as null to be re-generated afterwards.
    // duplicatedChallenge.fileName = await this.constructLevelName(this.entity.sceneType, this.entity.type_ref ? this.entity.type_ref.name == 'Grand' : false)
    duplicatedChallenge.fileName = originalChallenge.fileName;
    //sets parent challenge
    duplicatedChallenge.parent_challenge = originalChallenge.id;

    for (let field of fieldsToDelete) {
      if (duplicatedChallenge[field]) {
        delete duplicatedChallenge[field];
      }
    }
    //check row that's being duplicated
    this.loggerService.log('Duplicated Row', duplicatedChallenge);
    //call api to add the new record
    let newRecord = await this.dataService.addNewRecord(
      'challenges',
      duplicatedChallenge
    );
    newRecord.fileName = newRecord.fileName ? newRecord.fileName.replace(pathconfigValues.challengeIdPlaceholder, newRecord.id) : '';

    await this.dataService.updateRecord(newRecord.id, 'challenges', {fileName: newRecord.fileName, enabled: false, isDuplicated: true, 
      scene_ref: newRecord.scene_ref._id, scene: newRecord.scene, image_ref: newRecord.image_ref, image: newRecord.image});
    await this.dataService.updateRecord(originalChallenge.id, 'challenges', {child_challenge: newRecord.id})

    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Challenge Duplicated Successfully',
      detail: `Challenge Duplication was succesful! Redirecting to new challenge`,
    });
    //this.router.navigateByUrl('challenges/'+newRecord.id);
    window.open('challenges/'+newRecord.id, '_blank')
  }

  async viewRestrictions(entity: any) {
    let restrictions: any = []
    if (entity && entity.length > 0) {
      for(let i = 0; i < entity.length; i++) {
        let restriction = {
          name: entity[i].name+ '(' + entity[i].id + ')',
          id: entity[i].id
        }
        restrictions.push(restriction)
      }
    }

    return restrictions
  }

}
