import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';

@Component({
  selector: 'app-batch-view-v2',
  templateUrl: './batch-view-v2.component.html',
  styleUrls: ['./batch-view-v2.component.sass']
})
export class BatchViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  options: any;
  batchRecord: BaseEntityDto = new BaseEntityDto();
  parentViewName: string = 'batchView'
  constructor(
    private agGridToolsService: AgGridToolsService,
  )
  {
    super();
  }

  /**
   * Batch View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Batches Data",
        fields:
        [
          { name: "Name",
          inputType: DynamicViewType.BaseViewField ,
          viewTypes: [BaseViewFieldType.Text],
          clearField: true,
          key: 'name',
          isRequired: true,
          label: 'Name'},
          {
            name: "Batch Number",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'batchNumber',
            label: 'Batch Number',
            columnWidth: 6
          },
          {
            name: "Vendor",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'vendor_ref',
            label: 'Vendor',
            controller: 'vendors',
            columnWidth: 6
          },
          { 
            name: "Year", 
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],  
            clearField: true, 
            key: 'year', 
            label: 'Year', 
            columnWidth: 6 
          },
          { 
            name: "Content Status", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text],  
            clearField: true, 
            key: 'contentStatus', 
            label: 'Content Status', 
            columnWidth: 6 
          },
          { 
            name: "Art Status", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text],  
            clearField: true, 
            key: 'vendorStatus', 
            label: 'Art Status', 
            columnWidth: 6 
          },
          { 
            name: "Asset Type", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text],  
            clearField: true, 
            key: 'assetType', 
            label: 'Asset Type', 
            columnWidth: 6 
          },
          { 
            name: "Batch Status", 
            inputType: DynamicViewType.BaseViewField, 
            viewTypes: [BaseViewFieldType.Text],  
            clearField: true, 
            key: 'batchStatus', 
            label: 'Batch Status'
          },  
        ],
        type: DynamicCardType.Card
      },
      {
        title: 'Items',
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Nurtures", inputType: DynamicViewType.AgGridTable,
            key: 'items_ref', label: 'Items',
            options: {
              fieldName: 'items_ref',
              columnDefs:[
                {
                  headerName: 'ID',
                  field: 'id',
                  width: 125,
                  headerCheckboxSelection: true,
                  checkboxSelection: true,
                  sortable: true,
                  initialSort: 'desc',
                  sortIndex: 0,
                  initialSortIndex: 0,
                  sort: 'desc',
                  sortingOrder: ['asc','desc'],
                  unSortIcon: false,
                  filter: 'agNumberColumnFilter',
                  filterParams: {
                    filterOptions: [
                      'equals',
                      'notEqual',
                      'lessThan',
                      'lessThanOrEqual',
                      'greaterThan',
                      'greaterThanOrEqual',
                      'inRange'
                    ]
                  },
                  resizable: true,
                  floatingFilter: true,
                  suppressSizeToFit: true,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRendererParams: {
                    entity: 'items'
                  },
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  width: 350,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,
                  cellClass: 'center-cell-content',
                  cellEditor: 'agTextCellEditor',
                  type: 'editableColumn',
                },
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  floatingFilter: true,
                  suppressSizeToFit: true,
                  width: 300,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  valueGetter: this.agGridToolsService.dateValueGetter('start'),
                  resizable: true,
                  cellClass: 'center-cell-content',
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  floatingFilter: true,
                  suppressSizeToFit: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                    timePill: true
                  },
                  width: 300,
                  valueGetter: this.agGridToolsService.dateValueGetter('end'),
                  resizable: true,
                  cellClass: 'center-cell-content',
                },
                {
                  headerName: 'Enabled',
                  field: 'enabled',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    onLabel: 'Enabled',
                    offLabel: 'Not Enabled',
                    isBoolean: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: [true, false],
                    valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                  },
                  floatingFilter: true,
                  cellClass: 'center-cell-content',
                },
              ],
              defaultQueryKey: 'items_ref',
              entity: 'items'
            }
          },
        ]
      },
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.batchRecord =
    {
      enabled: false
    };
    this.title = "Batches";
    this.viewRoute = 'batches-v2';
    this.isLoading = false;
  }

}
