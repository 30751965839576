import { Component, Input } from '@angular/core';
import { ItemService } from '../services/item.service';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.sass']
})
export class ImageDialogComponent {
  @Input() showImgDialog: boolean;
  @Input() imgData: { title: string, path: string, name: string };

  constructor(public itemService: ItemService) {}
}

