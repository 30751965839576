<h1 class="col-3" *ngIf="!isEditMode">Add New Item</h1>
<h1 *ngIf="isEditMode">Editing Item {{ id }}</h1>
<div class="p-grid p-mt-1" *ngIf="!loading">
  <div class="p-col-8">
    <form *ngIf="fields" [formGroup]="itemForm" (ngSubmit)="beforeSubmit()">
      <button pButton pRipple type="submit" label="Submit" [disabled]="!itemForm.valid" class="p-mb-4"
        [style]="{ 'min-width': '12.5em' }"></button>
      <div class="">
        <span ngClass="p-float-label">
          <p-toggleButton formControlName="enabled" [onLabel]="'Enabled'" [offLabel]="'Not Enabled'" class="field-item"
            [style]="{ width: '100%' }"></p-toggleButton>
        </span>
      </div>
      <!-- CARD: Top Details -->
      <p-card class="p-mx-2">
        <div class="p-grid p-mx-5" id="item-details">
          <div class="p-col">
            <!-- name -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input (input)="spruceData.filterGlobal($event.target.value, 'contains')" formControlName="name"
                  pInputText class="field-item" />
                <label for="name">
                  <strong>Common Name <span style="color: crimson">*</span></strong>
                </label>
              </span>
              <p-button *ngIf="itemForm.value['name']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('name')"></p-button>
            </div>
            <!-- cultivar -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="cultivar" pInputText class="field-item" />
                <label for="cultivar"> Cultivar </label>
              </span>
              <p-button *ngIf="itemForm.value['cultivar']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('cultivar')"></p-button>
            </div>
            <!-- latinName -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="latinName" (input)="filterFunction(spruceData, $event.target.value)" pInputText
                  class="field-item" />
                <label for="latinName"> Latin Name </label>
              </span>
              <p-button *ngIf="itemForm.value['latinName']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('latinName')"></p-button>
            </div>
            <!-- plantFamily -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="plantFamily" pInputText class="field-item" />
                <label for="plantFamily"> Plant Family </label>
              </span>
              <p-button *ngIf="itemForm.value['plantFamily']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('plantFamily')"></p-button>
            </div>
          </div>
        </div>
      </p-card>
      <!-- CARD: 'Required - Production' -->
      <p-card class="p-mx-2">
        <span class="p-card-title p-mx-5" id="require-fields">Required - Production</span>
        <div class="p-grid p-mx-5">
          <div class="p-col">
            <!-- prefab -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="prefab" [(ngModel)]="fields.prefab" pInputText class="field-item" />
                <label for="prefab"> Prefab </label>
              </span>
              <p-button *ngIf="itemForm.value['prefab']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('prefab')"></p-button>
            </div>
            <!-- thumbnail -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="thumbnail" pInputText class="field-item" [(ngModel)]="fields.thumbnail" />
                <label for="thumbnail"> Thumbnail </label>
              </span>
              <p-button *ngIf="itemForm.value['thumbnail']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('thumbnail')"></p-button>
            </div>
          </div>
        </div>

        <!-- 'Required - Production': bottom, left column -->
        <div class="p-grid p-mx-2">
          <div class="p-col-6 p-pr-2">
            <!-- FileName -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input #inputFileName formControlName="fileName" [(ngModel)]="fields.fileName" pInputText
                  class="field-item" (keyup)="
                onPathComponentValueChange(
                  itemConstants.inputNames.fileName.name,
                  { value: inputFileName.value }
                )
              " />
                <label for="fileName">
                  <strong>File Name <span style="color: crimson">*</span></strong>
                </label>
              </span>
              <p-button *ngIf="itemForm.value['fileName']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('fileName')"></p-button>
            </div>
            <!-- Environment(s) -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="env" [options]="options['envs']" display="chip"
                  [style]="{ width: '100%' }" (onChange)="itemForm.get('env')?.markAsTouched();"
                  (onClear)="itemForm.get('env')?.markAsTouched();">
                </p-multiSelect>
                <label for="env"> Environment(s) </label>
              </span>
              <p-button *ngIf="itemForm.value['env']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('env')"></p-button>
            </div>
            <!-- itemFileType_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="itemFileType_ref" [options]="options['itemFileType_ref']"
                  optionLabel="name" optionValue="_id" [autoDisplayFirst]="false" [filter]="true" filterBy="name"
                  (onChange)="onPathComponentValueChange(itemConstants.inputNames.fileType.name,$event)"></p-dropdown>
                <label for="itemFileType_ref"> File Type </label>
              </span>
              <p-button *ngIf="itemForm.value['itemFileType_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('itemFileType_ref')"></p-button>
            </div>
            <!-- assetType -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="assetType" [options]="options['assetType']" [autoDisplayFirst]="false"
                  [filter]="true"></p-dropdown>
                <label for="assetType"> Asset Type </label>
              </span>
              <p-button *ngIf="itemForm.value['assetType']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('assetType')"></p-button>
            </div>
            <!-- year -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="year" [options]="options['year']" [autoDisplayFirst]="false"
                  [filter]="true" (onChange)="
                    onPathComponentValueChange(
                      itemConstants.inputNames.year.name,
                      $event
                    )
                  "></p-dropdown>
                <label for="year"> Year </label>
              </span>
              <p-button *ngIf="itemForm.value['year']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('year')"></p-button>
            </div>



            <!-- Vendor Height -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="vendorHeight" pInputText />
                <label for="vendorHeight"> Vendor Height </label>
              </span>
              <p-button *ngIf="itemForm.value['vendorHeight']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('vendorHeight')"></p-button>
            </div>
            <!-- Vendor Length -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="vendorLength" pInputText />
                <label for="vendorLength"> Vendor Spread </label>
              </span>
              <p-button *ngIf="itemForm.value['vendorLength']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('vendorLength')"></p-button>
            </div>

            <!-- vendorDimensions -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <textarea formControlName="vendorDimensions" cols="40" rows="3" pInputTextarea
                  class="field-item"></textarea>
                <label for="vendorDimensions"> Vendor Dimensions </label>
              </span>
              <p-button *ngIf="itemForm.value['vendorDimensions']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('vendorDimensions')"></p-button>
            </div>

            <!-- Vendor Reference Links -->
            <div class="p-m-5">
              <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
                <div formArrayName="referenceLinks">
                  <p-fieldset class="p-formgroup-inline">
                    <ng-template pTemplate="header">
                      <div class="p-formgroup-inline">
                        <span class="p-my-auto p-mr-2">Vendor Reference Links</span>
                        <button pButton icon="pi pi-plus p-panel-header-icon"
                          class="p-button-rounded p-button-text p-button" (click)="addReferenceLink()"
                          type="button"></button>
                      </div>
                    </ng-template>
                    <span class="p-formgroup-inline">
                      <div *ngFor="
                              let link of referenceLinks.controls;
                              let pointIndex = index
                            " [formGroupName]="pointIndex">
                        <p-card class="p-formgroup p-m-2 p-shadow-4">
                          <ng-template pTemplate="header">
                            <button pButton type="button" icon="pi pi-times"
                              class="p-button-rounded p-button-text p-button-danger float-right"
                              (click)="deleteReferenceLink(pointIndex)"></button>
                          </ng-template>

                          <ng-template pTemplate="content">
                            <div class="p-m-5">
                              <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
                                <input formControlName="link" pInputText class="field-item" />
                                <label for="link"> Link </label>
                              </span>
                            </div>
                          </ng-template>
                        </p-card>
                      </div>
                    </span>
                  </p-fieldset>
                </div>
              </span>
            </div>

            <!-- Internal Reference Links -->

            <div class="p-m-5">
              <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
                <div formArrayName="internalReferenceLinks">
                  <p-fieldset class="p-formgroup-inline">
                    <ng-template pTemplate="header">
                      <div class="p-formgroup-inline">
                        <span class="p-my-auto p-mr-2">Internal Reference Links</span>
                        <button pButton icon="pi pi-plus p-panel-header-icon"
                          class="p-button-rounded p-button-text p-button" (click)="addInternalReferenceLink()"
                          type="button"></button>
                      </div>
                    </ng-template>
                    <span class="p-formgroup-inline">
                      <div *ngFor="
                                let link of internalReferenceLinks.controls;
                                let pointIndex = index
                              " [formGroupName]="pointIndex">
                        <p-card class="p-formgroup p-m-2 p-shadow-4">
                          <ng-template pTemplate="header">
                            <button pButton type="button" icon="pi pi-times"
                              class="p-button-rounded p-button-text p-button-danger float-right" (click)="
                                      deleteInternalReferenceLink(pointIndex)
                                    "></button>
                          </ng-template>

                          <ng-template pTemplate="content">
                            <div class="p-m-5">
                              <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
                                <input formControlName="link" pInputText class="field-item" />
                                <label for="link"> Link </label>
                              </span>
                            </div>
                          </ng-template>
                        </p-card>
                      </div>
                    </span>
                  </p-fieldset>
                </div>
              </span>
            </div>
          </div>

          <!-- 'Required - Production': bottom, right column -->
          <div class="p-col-6 p-pl-4" id="required-prod">
            <!-- vendorStatus -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="vendorStatus" [options]="options['vendorStatus']"
                  [autoDisplayFirst]="false" [filter]="true"></p-dropdown>
                <label for="vendorStatus"> Art Status </label>
              </span>
              <p-button *ngIf="itemForm.value['vendorStatus']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('vendorStatus')"></p-button>
            </div>
            <!-- flagged -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="flagged" [options]="options['flagged']" [autoDisplayFirst]="false"
                  [filter]="true"></p-dropdown>
                <label for="flagged"> Art Hold </label>
              </span>
              <p-button *ngIf="itemForm.value['flagged']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('flagged')"></p-button>
            </div>

            <!-- vendor_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="vendor_ref" [options]="options['vendor_ref']" optionLabel="name"
                  optionValue="_id" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'"></p-dropdown>
                <label for="vendor_ref"> Vendor </label>
              </span>
              <p-button *ngIf="itemForm.value['vendor_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('vendor_ref')"></p-button>
            </div>

            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="batch_ref" [options]="options['batch_ref']" optionLabel="name"
                  optionValue="_id" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'"></p-dropdown>
                <label for="batch_ref"> Vendor Batch </label>
              </span>
              <p-button *ngIf="itemForm.value['batch_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('batch_ref')"></p-button>
            </div>
            <!-- recolorSource -->
            <!-- <div class="p-my-5 p-inputgroup">
            <span ngClass="p-float-label">
                <p-dropdown
                  formControlName="recolorSource"
                  [options]="options['recolorSource']"
                  [autoDisplayFirst]="false"
                  [filter]="true"
                ></p-dropdown>
                <label for="recolorSource"> Recolor Source </label>
              </span>
            <p-button *ngIf="itemForm.value['recolorSource']" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="clearField('recolorSource')"></p-button>
        </div> -->
            <div class="p-col-12">
              <div class="p-grid">
                <!-- First Row -->
                <div class="p-col-3">
                  <p>Bundle Asset (IOS)</p>
                  <p-inputSwitch class="p-my-5" formControlName="bundleAsset"></p-inputSwitch>
                </div>
                <div class="p-col-3">
                  <p>Bundle Image (IOS)</p>
                  <p-inputSwitch class="p-my-5" formControlName="bundleImage"></p-inputSwitch>
                </div>
                <!-- Second Row -->
                <div class="p-col-3">
                  <p>Bundle Images (AND)</p>
                  <p-inputSwitch class="p-my-5" formControlName="bundleImageAnd"></p-inputSwitch>
                </div>
                <div class="p-col-3">
                  <p>Bundle Asset (AND)</p>
                  <p-inputSwitch class="p-my-5" formControlName="bundleAssetAnd"></p-inputSwitch>
                </div>
              </div>
            </div>

            <div class="p-mx-2" header="Audio">
              <ng-container>
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <span ngClass="p-float-label">
                      <p-multiSelect [formControlName]="'spawnAudios_ref'" [options]="options['spawnAudios_ref']"
                        optionLabel="name" optionValue="_id" display="chip" [style]="{ width: '100%' }"
                        (onChange)="itemForm.get('spawnAudios_ref')?.markAsTouched();"
                        (onClear)="itemForm.get('spawnAudios_ref')?.markAsTouched();">
                      </p-multiSelect>
                      <label [for]="'spawnAudios_ref'">Spawn Audio</label>
                    </span>
                    <p-button *ngIf="itemForm.value['spawnAudios_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField('spawnAudios_ref')"></p-button>
                  </span>
                </div>
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-multiSelect [formControlName]="'spawnAudioCollections_ref'"
                      [options]="options['spawnAudioCollections_ref']" optionLabel="name" optionValue="_id"
                      display="chip" [style]="{ width: '100%' }"
                      (onChange)="itemForm.get('spawnAudioCollections_ref')?.markAsTouched();"
                      (onClear)="itemForm.get('spawnAudioCollections_ref')?.markAsTouched();">
                    </p-multiSelect>
                    <label [for]="'spawnAudioCollections_ref'">Spawn Audio Collection</label>
                  </span>
                  <p-button *ngIf="itemForm.value['spawnAudioCollections_ref']" ariaLabel="Clear Field"
                    icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                    (click)="clearField('spawnAudioCollections_ref')"></p-button>
                </div>
              </ng-container>
              <ng-container>
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <span ngClass="p-float-label">
                      <p-multiSelect [formControlName]="'loopAudios_ref'" [options]="options['loopAudios_ref']"
                        optionLabel="name" optionValue="_id" display="chip" [style]="{ width: '100%' }"
                        (onChange)="itemForm.get('loopAudios_ref')?.markAsTouched();"
                        (onClear)="itemForm.get('loopAudios_ref')?.markAsTouched();">
                      </p-multiSelect>
                      <label [for]="'loopAudios_ref'">Loop Audio</label>
                    </span>
                    <p-button *ngIf="itemForm.value['loopAudios_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField('loopAudios_ref')"></p-button>
                  </span>
                </div>
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-multiSelect [formControlName]="'loopAudioCollections_ref'"
                      [options]="options['loopAudioCollections_ref']" optionLabel="name" optionValue="_id"
                      display="chip" [style]="{ width: '100%' }"
                      (onChange)="itemForm.get('loopAudioCollections_ref')?.markAsTouched();"
                      (onClear)="itemForm.get('loopAudioCollections_ref')?.markAsTouched();">
                    </p-multiSelect>
                    <label [for]="'loopAudioCollections_ref'">Loop Audio Collection</label>
                  </span>
                  <p-button *ngIf="itemForm.value['loopAudioCollections_ref']" ariaLabel="Clear Field"
                    icon="pi pi-minus" styleClass="p-button-text p-button-danger"
                    (click)="clearField('loopAudioCollections_ref')"></p-button>
                </div>
              </ng-container>
            </div>

            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="keywords_ref" [options]="options['keywords_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('keywords_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('keywords_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="keywords_ref"> Keywords </label>
              </span>
              <p-button *ngIf="itemForm.value['keywords_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('keywords_ref')"></p-button>
            </div>
          </div>
        </div>
      </p-card>
      <!-- CARD: 'Vendor Notes' -->
      <p-card class="p-mx-2">
        <span class="p-card-title p-mx-5">Vendor Notes</span>
        <div class="p-mx-5">
          <!-- vendorNotes -->
          <div class="p-my-5 p-inputgroup">
            <span ngClass="p-float-label">
              <label for="vendorNotes"> Vendor Notes </label>
              <p-editor formControlName="vendorNotes" [style]="{'height':'200px', 'width':'50vw'}">
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <select type="button" class="ql-header p-mr-2" aria-label="Header"></select>
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline p-mr-2" aria-label="Underline"></button>
                    <select type="button" class="ql-color" aria-label="Color"></select>
                    <select type="button" class="ql-background p-mr-2" aria-label="Background Color"></select>
                    <button type="button" value="ordered" class="ql-list" aria-label="Underline"></button>
                    <button type="button" value="bullet" class="ql-list" aria-label="Underline"></button>
                    <button type="button" class="ql-link p-mr-2" aria-label="Underline"></button>
                  </span>
                </ng-template>
              </p-editor>
            </span>
            <p-button *ngIf="itemForm.value['vendorNotes']" ariaLabel="Clear Field" icon="pi pi-minus"
              styleClass="p-button-text p-button-danger" (click)="clearField('vendorNotes')"></p-button>
          </div>
        </div>
      </p-card>
      <!-- CARD: 'Internal Notes' -->
      <p-card class="p-mx-2">
        <span class="p-card-title p-mx-5">Internal (Content) Notes</span>
        <div class="p-mx-5">
          <!-- internalNotes -->
          <div class="p-my-5 p-inputgroup">
            <span ngClass="p-float-label">
              <p-editor formControlName="internalNotes" [style]="{'height':'200px', 'width':'50vw'}">
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <select type="button" class="ql-header p-mr-2" aria-label="Header"></select>
                    <button type="button" class="ql-bold" aria-label="Bold"></button>
                    <button type="button" class="ql-italic" aria-label="Italic"></button>
                    <button type="button" class="ql-underline p-mr-2" aria-label="Underline"></button>
                    <select type="button" class="ql-color" aria-label="Color"></select>
                    <select type="button" class="ql-background p-mr-2" aria-label="Background Color"></select>
                    <button type="button" value="ordered" class="ql-list" aria-label="Underline"></button>
                    <button type="button" value="bullet" class="ql-list" aria-label="Underline"></button>
                    <button type="button" class="ql-link p-mr-2" aria-label="Underline"></button>
                  </span>
                </ng-template>
              </p-editor>
            </span>
            <p-button *ngIf="itemForm.value['internalNotes']" ariaLabel="Clear Field" icon="pi pi-minus"
              styleClass="p-button-text p-button-danger" (click)="clearField('internalNotes')"></p-button>
          </div>
        </div>
      </p-card>
      <!-- Vendor Dimensions -->
      <!-- CARD: 'Content' -->
      <p-card class="p-mx-2">
        <span class="p-card-title p-mx-5">Content</span>
        <div class="p-grid p-mx-1">
          <!-- 'Content': left column -->
          <div class="p-col-6 p-pr-2">
            <!-- climates_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="climates_ref" [options]="options['climates_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('climates_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('climates_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="climates_ref"> Climates </label>
              </span>
              <p-button *ngIf="itemForm.value['climates_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('climates_ref')"></p-button>
            </div>
            <!-- itemSet_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="itemSet_ref" [options]="options['itemSet_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('itemSet_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('itemSet_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="itemSet_ref"> Set(s) </label>
              </span>
              <p-button *ngIf="itemForm.value['itemSet_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('itemSet_ref')"></p-button>
            </div>
            <!-- category_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="category_ref" [options]="options.category_ref" optionLabel="name"
                  optionValue="_id" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'" (onChange)="
                    setOptionsFromNestedField(
                      'category_ref',
                      'types_ref',
                      'type_ref',
                      itemForm?.get('category_ref').value
                    )
                  "></p-dropdown>
                <label for="category_ref">
                  <strong>Category Type<span style="color: crimson">*</span></strong>
                </label>
              </span>
              <p-button *ngIf="itemForm.value['category_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('category_ref')"></p-button>
            </div>
            <!-- type_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="type_ref" [options]="options['type_ref']" optionLabel="name"
                  optionValue="_id" [autoDisplayFirst]="false" [filter]="true" [filterBy]="'name'"></p-dropdown>
                <label for="type_ref">Plant Type </label>
              </span>
              <p-button *ngIf="itemForm.value['type_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('type_ref')"></p-button>
            </div>


            <!-- height -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="height" pInputText />
                <label for="height">Height</label>
              </span>
              <p-button *ngIf="itemForm.value['height']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('height')"></p-button>
            </div>
            <!-- spread -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="spread" pInputText />
                <label for="spread">Spread</label>
              </span>
              <p-button *ngIf="itemForm.value['spread']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('spread')"></p-button>
            </div>
            <div class="p-grid p-col-12">
              <!-- start and End Dates -->
              <div class="p-col-6">
                <p-divider align="center">
                  <span class="p-tag">Dates</span>
                </p-divider>
                <div class="p-mt-2 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-calendar formControlName="start" [showTime]="true" [yearNavigator]="true" yearRange="2020:2030"
                      [monthNavigator]="true" class="field-item" [defaultDate]="defaultDate" [selectOtherMonths]="true"
                      (onInput)="handleDateInputChange('start',$event)">
                      <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                        <h5 class="p-d-inline">Local Time: </h5><small>{{ itemForm.value['start'] ?
                          (itemForm.value['start'] | date: 'medium') : '' }}</small>
                      </ng-template></p-calendar>
                    <label for="start"> Start Date/Time </label>
                  </span>
                  <div style="width: 2rem;">
                    <p-button *ngIf="itemForm.value['start']" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField('start')"></p-button>
                  </div>
                  <div style="width: 2rem;">
                    <p-button *ngIf="itemForm.value['start'] && itemForm.controls['start'].touched"
                      ariaLabel="Undo Change" icon="pi pi-undo" styleClass="p-button-text p-button-primary"
                      (click)="revertField('start')"></p-button>
                  </div>
                </div>
                <!-- end -->
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-calendar formControlName="end" [showTime]="true" [minDate]="itemForm.get('start')!.value"
                      [yearNavigator]="true" yearRange="2020:2030" [monthNavigator]="true" class="field-item"
                      [selectOtherMonths]="true" [defaultDate]="defaultDate"
                      (onInput)="handleDateInputChange('end',$event)">
                      <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                        <h5 class="p-d-inline">Local Time: </h5><small>{{ itemForm.value['end'] ? (itemForm.value['end']
                          | date: 'medium') : '' }}</small>
                      </ng-template></p-calendar>
                    <label for="end"> End Date/Time </label>
                  </span>
                  <div style="width: 2rem;">
                    <p-button *ngIf="itemForm.value['end']" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField('end')"></p-button>
                  </div>
                  <div style="width: 2rem;">
                    <p-button *ngIf="itemForm.value['end'] && itemForm.controls['end'].touched" ariaLabel="Undo Change"
                      icon="pi pi-undo" styleClass="p-button-text p-button-primary"
                      (click)="revertField('end')"></p-button>
                  </div>
                </div>
              </div>
              <!-- RE-RELEASE start and End Dates -->
              <div class="p-col-6">
                <p-divider align="center">
                  <span class="p-tag">Re-Release Dates</span>
                </p-divider>
                <div class="p-mt-2 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-calendar formControlName="reReleaseStart" [showTime]="true" [yearNavigator]="true"
                      yearRange="2020:2030" [monthNavigator]="true" class="field-item" [selectOtherMonths]="true"
                      [defaultDate]="defaultDate" (onInput)="handleDateInputChange('reReleaseStart',$event)">
                      <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                        <h5 class="p-d-inline">Local Time: </h5><small>{{ itemForm.value['reReleaseStart'] ?
                          (itemForm.value['reReleaseStart'] | date: 'medium') : '' }}</small>
                      </ng-template></p-calendar>
                    <label for="reReleaseStart"> Re-Release Start Date/Time </label>
                  </span>
                  <div style="width: 2rem;">
                    <p-button *ngIf="itemForm.value['reReleaseStart']" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField('reReleaseStart')"></p-button>
                  </div>
                  <div style="width: 2rem;">
                    <p-button *ngIf="itemForm.value['reReleaseStart'] && itemForm.controls['reReleaseStart'].touched"
                      ariaLabel="Undo Change" icon="pi pi-undo" styleClass="p-button-text p-button-primary"
                      (click)="revertField('reReleaseStart')"></p-button>
                  </div>
                </div>
                <!-- end -->
                <div class="p-mt-5 p-inputgroup">
                  <span ngClass="p-float-label">
                    <p-calendar formControlName="reReleaseEnd" [showTime]="true"
                      [minDate]="itemForm.get('reReleaseStart')!.value" [yearNavigator]="true" yearRange="2020:2030"
                      [monthNavigator]="true" class="field-item" [selectOtherMonths]="true" [defaultDate]="defaultDate"
                      (onInput)="handleDateInputChange('reReleaseEnd',$event)">
                      <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
                        <h5 class="p-d-inline">Local Time: </h5><small>{{ itemForm.value['reReleaseEnd'] ?
                          (itemForm.value['reReleaseEnd'] | date: 'medium') : '' }}</small>
                      </ng-template>
                    </p-calendar>
                    <label for="end"> Re-Release End Date/Time </label>
                  </span>
                  <div style="width: 2rem;">
                    <p-button *ngIf="itemForm.value['reReleaseEnd']" ariaLabel="Clear Field" icon="pi pi-minus"
                      styleClass="p-button-text p-button-danger" (click)="clearField('reReleaseEnd')"></p-button>
                  </div>
                  <div style="width: 2rem;">
                  <p-button *ngIf="itemForm.value['reReleaseEnd'] && itemForm.controls['reReleaseEnd'].touched"
                      ariaLabel="Undo Change" icon="pi pi-undo" styleClass="p-button-text p-button-primary"
                      (click)="revertField('reReleaseEnd')"></p-button>
                  </div>
                </div>
              </div>
            </div>
            <!-- start and End Dates -->
            <!-- <p-divider align="center">
        <span class="p-tag">Dates</span>
      </p-divider>
      <div class="p-my-5 p-inputgroup">
        <span ngClass="p-float-label">
          <p-calendar formControlName="start" [showTime]="true" [yearNavigator]="true" yearRange="2020:2030"
            [monthNavigator]="true" class="field-item" [defaultDate]="defaultDate" [selectOtherMonths]="true"></p-calendar>
          <label for="start"> Start Date/Time </label>
        </span>
        <p-button *ngIf="itemForm.value['start']" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="p-button-text p-button-danger" (click)="clearField('start')"></p-button>
        <p-button *ngIf="itemForm.value['start'] && itemForm.controls['start'].touched" ariaLabel="Undo Change"
          icon="pi pi-undo" styleClass="p-button-text p-button-primary" (click)="revertField('start')"></p-button>
      </div> -->
            <!-- end -->
            <!-- <div class="p-my-5 p-inputgroup">
        <span ngClass="p-float-label">
          <p-calendar formControlName="end" [showTime]="true" [minDate]="itemForm.get('start')!.value"
            [yearNavigator]="true" yearRange="2020:2030" [monthNavigator]="true" class="field-item"
            [selectOtherMonths]="true" [defaultDate]="defaultDate"></p-calendar>
          <label for="end"> End Date/Time </label>
        </span>
        <p-button *ngIf="itemForm.value['end']" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="p-button-text p-button-danger" (click)="clearField('end')"></p-button>
        <p-button *ngIf="itemForm.value['end'] && itemForm.controls['end'].touched" ariaLabel="Undo Change"
          icon="pi pi-undo" styleClass="p-button-text p-button-primary" (click)="revertField('end')"></p-button>
      </div>

            <br /> -->
            <!-- colors_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="colors_ref" [options]="options['colors_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('colors_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('colors_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="colors_ref"> Colors </label>
              </span>
              <p-button *ngIf="itemForm.value['colors_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('colors_ref')"></p-button>
            </div>
            <!-- traits_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="traits_ref" [options]="options['traits_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('traits_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('traits_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="traits_ref"> Traits </label>
              </span>
              <p-button *ngIf="itemForm.value['traits_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('traits_ref')"></p-button>
            </div>
            <!-- styles_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="styles_ref" [options]="options['styles_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('styles_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('styles_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="styles_ref"> Styles </label>
              </span>
              <p-button *ngIf="itemForm.value['styles_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('styles_ref')"></p-button>
            </div>
            <!-- materials_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="materials_ref" [options]="options['materials_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('materials_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('materials_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="materials_ref"> Materials </label>
              </span>
              <p-button *ngIf="itemForm.value['materials_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('materials_ref')"></p-button>
            </div>
            <!-- patterns_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="patterns_ref" [options]="options['patterns_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('patterns_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('patterns_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="patterns_ref"> Patterns </label>
              </span>
              <p-button *ngIf="itemForm.value['patterns_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('patterns_ref')"></p-button>
            </div>
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="shape_ref" [options]="options['shape_ref']" optionLabel="name"
                  optionValue="_id" [autoDisplayFirst]="false" [filter]="true"></p-dropdown>
                <label for="shape_ref"> Shape </label>
              </span>
              <p-button *ngIf="itemForm.value['shape_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('shape_ref')"></p-button>
            </div>
          </div>

          <!-- 'Content': right column -->
          <div class="p-col-6 p-pl-4">
            <!-- itemStatus -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="itemStatus" [options]="options['itemStatus']" [autoDisplayFirst]="false"
                  [filter]="true"></p-dropdown>
                <label for="itemStatus"> Content Status </label>
              </span>
              <p-button *ngIf="itemForm.value['itemStatus']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('itemStatus')"></p-button>
            </div>

            <!-- contentHold_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="contentHold_ref" [options]="options['contentHold_ref']"
                  optionLabel="name" optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="setEnabledEnvironmentsToNull($event); itemForm.get('contentHold_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('contentHold_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="contentHold_ref"> Content Hold </label>
              </span>
              <p-button *ngIf="itemForm.value['contentHold_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('contentHold_ref')"></p-button>
            </div>

            <!-- costs_ref -->
            <div [ngStyle]="{'width': '100%'}">
              <prizes-card-form-group [fieldsetName]="'Cost(s)'" [version]="3" [defaultResourceType]="'Currency'"
                [(prize)]="this.costs" [showPrize]="true" [isCostField]="true" [currenciesInclusion]="[2,1,11]"
                (onPrizeChange)="this.costs = $event; this.touchedCosts = true">
              </prizes-card-form-group>
            </div>
            <!-- RE-RELEASE start and End Dates -->

            <!-- <p-divider align="center">
          <span class="p-tag">Re-Release Dates</span>
      </p-divider>
      <div class="p-my-5 p-inputgroup">
          <span ngClass="p-float-label">
                  <p-calendar
                    formControlName="reReleaseStart"
                    [showTime]="true"
                    [yearNavigator]="true"
                    yearRange="2020:2030"
                    [monthNavigator]="true"
                    class="field-item"
                    [selectOtherMonths]="true"
                    [defaultDate]="defaultDate"
                  ></p-calendar>
                  <label for="reReleaseStart"> Re-Release Start Date/Time </label>
      </span>
          <p-button *ngIf="itemForm.value['reReleaseStart']" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="clearField('reReleaseStart')"></p-button>
          <p-button *ngIf="itemForm.value['reReleaseStart'] && itemForm.controls['reReleaseStart'].touched" ariaLabel="Undo Change" icon="pi pi-undo" styleClass="p-button-text p-button-primary" (click)="revertField('reReleaseStart')"></p-button>
      </div> -->
            <!-- end -->
            <!-- <div class="p-my-5 p-inputgroup">
          <span ngClass="p-float-label">
                  <p-calendar
                    formControlName="reReleaseEnd"
                    [showTime]="true"
                    [minDate]="itemForm.get('reReleaseStart')!.value"
                    [yearNavigator]="true"
                    yearRange="2020:2030"
                    [monthNavigator]="true"
                    class="field-item"
                    [selectOtherMonths]="true"
                    [defaultDate]="defaultDate"
                  ></p-calendar>
                  <label for="end"> Re-Release End Date/Time </label>
                </span>
          <p-button *ngIf="itemForm.value['reReleaseEnd']" ariaLabel="Clear Field" icon="pi pi-minus" styleClass="p-button-text p-button-danger" (click)="clearField('reReleaseEnd')"></p-button>
          <p-button *ngIf="itemForm.value['reReleaseEnd'] && itemForm.controls['reReleaseEnd'].touched" ariaLabel="Undo Change" icon="pi pi-undo" styleClass="p-button-text p-button-primary" (click)="revertField('reReleaseEnd')"></p-button>
      </div>

      <br /> -->
            <!-- <div class="p-my-5">
              <div class="p-mb-2 p-inputgroup">
                <p-button
                  #addNestedGroup_btn
                  ariaLabel="Add Cost Item"
                  icon="pi pi-plus"
                  styleClass="p-button-text"
                  (click)="
                    onOpenNestedGroup(
                      { name: 'Cost(s)', key: 'costs_ref' },
                      itemForm.value['costs_ref'].length,
                      $event
                    )
                  "
                ></p-button>

                <div ngClass="p-float-label">
                  <input
                    #nestedGroup_input
                    id="costs_ref"
                    pInputText
                    (keyup.enter)="
                      onAddNestedGroupByInput('costs_ref', $event.target.value)
                    "
                  />
                  <label [for]="'costs_ref'">Cost(s)</label>
                </div>

                <p-button
                  *ngIf="
                    itemForm.value['costs_ref'] &&
                    itemForm.value['costs_ref'].length > 0
                  "
                  ariaLabel="Clear Field"
                  icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger"
                  (click)="clearField('costs_ref')"
                ></p-button>
              </div>

              <div
                *ngIf="
                  itemForm.value['costs_ref'] &&
                  itemForm.value['costs_ref'].length > 0
                "
                class="p-pt-1 p-px-3"
              >
                <p-chip
                  *ngFor="
                    let item of itemForm.value['costs_ref'];
                    let index = index
                  "
                  styleClass="p-mt-1 p-mx-1 p-px-0"
                >
                  <p-button
                    ariaLabel="Update Cost Item"
                    icon="pi pi-pencil"
                    styleClass="p-button-text p-button-plain p-button-rounded p-button-sm"
                    [ngStyle]="{ 'text-color': 'var(--text-color)' }"
                    (click)="
                      onOpenNestedGroup(
                        { name: 'Cost(s)', key: 'costs_ref' },
                        index,
                        $event
                      )
                    "
                  ></p-button>
                  <span class="p-m-1">
                    {{ item.c }}
                    {{ item.id && item.id.name ? item.id.name : null }}
                  </span>
                  <p-button
                    ariaLabel="Add Filter Item"
                    icon="pi pi-times-circle"
                    styleClass="p-button-text p-button-plain p-button-rounded p-button-sm"
                    [ngStyle]="{ 'text-color': 'var(--text-color)' }"
                    (click)="onRemoveNestedGroup('costs_ref', index)"
                  ></p-button>
                </p-chip>
              </div>
            </div> -->

            <!-- tags -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect formControlName="tags_ref" [options]="options['tags_ref']" optionLabel="name"
                  optionValue="_id" display="chip" [style]="{ width: '100%' }"
                  (onChange)="itemForm.get('tags_ref')?.markAsTouched();"
                  (onClear)="itemForm.get('tags_ref')?.markAsTouched();">
                </p-multiSelect>
                <label for="tags_ref"> Select Tags </label>
              </span>
              <p-button *ngIf="itemForm.value['tags_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('tags_ref')"></p-button>
            </div>
            <!-- progressionLevel_ref -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="progressionLevel_ref" [options]="options['progressionLevel_ref']"
                  optionLabel="name" optionValue="_id" [autoDisplayFirst]="false" [filter]="true"
                  [filterBy]="'name'"></p-dropdown>
                <label for="progressionLevel_ref"> Level Lock </label>
              </span>
              <p-button *ngIf="itemForm.value['progressionLevel_ref']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('progressionLevel_ref')"></p-button>
            </div>


            <!-- Associated Assets -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-multiSelect [options]="options['releatedItems']" formControlName="releatedItems" display="chip"
                  filterBy="name,id" [autofocusFilter]="true" [style]="{ width: '100%' }" optionValue="_id"
                  optionLabel="name" (onChange)="itemForm.get('releatedItems')?.markAsTouched();"
                  (onClear)="itemForm.get('releatedItems')?.markAsTouched();">
                  <ng-template let-option pTemplate="item">
                    <div>
                      {{ option.name }} ({{ option.id }})
                    </div>
                  </ng-template>
                </p-multiSelect>
                <label for="releatedItems"> Associated Assets </label>
              </span>
              <p-button *ngIf="itemForm.value['releatedItems']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('releatedItems')"></p-button>
            </div>
            <!-- blurbStatus -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <p-dropdown formControlName="blurbStatus" [options]="options['blurbStatus']" [autoDisplayFirst]="false"
                  [filter]="true"></p-dropdown>
                <label for="blurbStatus"> Blurb Status </label>
              </span>
              <p-button *ngIf="itemForm.value['blurbStatus']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('blurbStatus')"></p-button>
            </div>

            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <textarea formControlName="blurb" cols="40" rows="3" pInputTextarea class="field-item"></textarea>
                <label for="blurb"> Blurb </label>
              </span>
              <p-button *ngIf="itemForm.value['blurb']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('blurb')"></p-button>
            </div>
          </div>

        </div>
        <div class="p-mx-1">
          <!-- blurb -->

        </div>
      </p-card>
      <!-- CARD: 'Dev' -->
      <p-card class="p-mx-2" *ngIf="false">
        <span class="p-card-title p-mx-5">Dev Dimensions</span>
        <div class="p-grid p-mx-5">
          <div class="p-col">
            <!-- dimensionX -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="dimensionX" type="number" step="0.01" min="0" value="0" pInputText required />
                <label for="dimensionX"> Dimension (X) </label>
              </span>
              <p-button *ngIf="itemForm.value['dimensionX']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('dimensionX')"></p-button>
            </div>
            <!-- dimensionY -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="dimensionY" type="number" step="0.01" min="0" value="0" pInputText required />
                <label for="dimensionY"> Dimension (Y) </label>
              </span>
              <p-button *ngIf="itemForm.value['dimensionY']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('dimensionY')"></p-button>
            </div>
            <!-- dimensionZ -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="dimensionZ" type="number" step="0.01" min="0" value="0" pInputText required />
                <label for="dimensionZ"> Dimension (Z) </label>
              </span>
              <p-button *ngIf="itemForm.value['dimensionZ']" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearField('dimensionZ')"></p-button>
            </div>
            <!-- radius -->
            <div class="p-my-5 p-inputgroup">
              <span ngClass="p-float-label">
                <input formControlName="radius" type="number" step="0.01" min="0" value="0" pInputText required />
                <label for="radius"> Radius</label>
                <p-button *ngIf="itemForm.value['radius']" ariaLabel="Clear Field" icon="pi pi-minus"
                  styleClass="p-button-text p-button-danger" (click)="clearField('radius')"></p-button>
              </span>
            </div>
          </div>
        </div>
      </p-card>
      <!-- <div class="p-m-5">
          <span [style]="{ 'min-width': '16.5em' }" ngClass="p-float-label">
            <div formArrayName="referenceImageLinks">
              <p-fieldset class="p-formgroup-inline">
                <ng-template pTemplate="header">
                  <div class="p-formgroup-inline">
                    <span class="p-my-auto p-mr-2">Vendor Reference Image Links</span>
                    <button
                      pButton
                      icon="pi pi-plus p-panel-header-icon"
                      class="p-button-rounded p-button-text p-button"
                      (click)="addReferenceImageLink()"
                      type="button"
                    ></button>
                  </div>
                </ng-template>
                <span class="p-formgroup-inline">
                  <div
                    *ngFor="
                      let link of referenceImageLinks.controls;
                      let pointIndex = index
                    "
                    [formGroupName]="pointIndex"
                  >
                    <p-card class="p-formgroup p-m-2 p-shadow-4">
                      <ng-template pTemplate="header">
                        <button
                          pButton
                          type="button"
                          icon="pi pi-times"
                          class="
                            p-button-rounded p-button-text p-button-danger
                            float-right
                          "
                          (click)="deleteReferenceImageLink(pointIndex)"
                        ></button>
                      </ng-template>

                      <ng-template pTemplate="content">
                        <div class="p-m-5">
                          <span
                            [style]="{ 'min-width': '16.5em' }"
                            ngClass="p-float-label"
                          >
                            <input
                              formControlName="link"
                              pInputText
                              class="field-item"
                            />
                            <label for="link"> Link </label>
                          </span>
                        </div>
                      </ng-template>
                    </p-card>
                  </div>
                </span>
              </p-fieldset>
            </div>
          </span>
        </div> -->

      <button pButton pRipple type="submit" label="Submit" [disabled]="!itemForm.valid" class="p-mt-4"
        [style]="{ 'min-width': '12.5em' }"></button>
    </form>
  </div>

  <!-- External Data matching table -->
  <div class="p-col-4">
    <div class="p-mr-2" id="table">
      <p-card>
        <h3>Spruce Data Match</h3>
        <p-table #spruceData [globalFilterFields]="filterFields()" [value]="externalPlantData" selectionMode="single"
          [(selection)]="selectedExternalPlant" dataKey="id" responsiveLayout="scroll" [scrollHeight]="'300px'"
          (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)">
          <ng-template pTemplate="caption">
            <div class="p-grid">
              <div class="p-col">
                <div class="flex p-my-2">
                  <p-checkbox name="latin" [binary]="true" [(ngModel)]="commonToggle"></p-checkbox>
                  <label class="p-mx-2" for="latin">Common Name</label>
                  <p-checkbox class="p-ml-2" name="common" [binary]="true" [(ngModel)]="latinToggle"></p-checkbox>
                  <label class="p-mx-2" for="common">Latin Name</label>
                  <div class="flex p-my-2">
                    <span class="p-input-icon-left ml-auto field-item">
                      <i class="pi pi-search"></i>
                      <input pInputText type="text" (input)="filterFunction(spruceData, $event.target.value)"
                        placeholder="Search keyword" />
                    </span>
                  </div>
                </div>
              </div>
              <div class="p-col-4">
                <p>Spruce Data Status:</p>
                <p-toggleButton [(ngModel)]="fields.spruceDataStatus" (ngModelChange)="onToggleChange($event)"
                  [onLabel]="'Linked'" [offLabel]="'Not Linked'" class="field-item"
                  (onChange)="onLinkClick()"></p-toggleButton>
              </div>
            </div>


            <p-divider></p-divider>
            <div class="flex" *ngIf="selectedExternalPlant">
              <h3>Matching Record</h3>
              <p-tag>
                Common Name: {{selectedExternalPlant.commonName}}
                <br />
                Latin Name: {{selectedExternalPlant.botanicalName}}
              </p-tag>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pSortableColumn="commonName">Common Name<p-sortIcon field="commonName"></p-sortIcon></th>
              <th pSortableColumn="botanicalName">Latin Name<p-sortIcon field="botanicalName"></p-sortIcon></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-externalplant of commonNameResult>
            <tr [pSelectableRow]="externalplant">
              <td>
                {{ externalplant.commonName }}
              </td>
              <td>{{ externalplant.botanicalName }}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>
    </div>
    <div class="p-mr-2 p-margin-top-high">
      <div id="maincard">
        <div id="headerBody">
          <div class="p-mb-2" id="headerT">
            {{ itemForm.get("name")!.value ? itemForm.get("name")!.value : "Commune nomen" }}
          </div>
          <div class="p-mb-2" id="subheaderT">
            {{ itemForm.get("cultivar")?.value ? itemForm.get("cultivar")?.value : "Iterum conare" }}
          </div>
        </div>
        <div id="contentBody">
          <div class="p-mb 2">
            <div class="p-d-flex p-jc-center">
              <img id="image1" src="{{ this.existingDoc.newThumbnail_img }}" alt="🥀 No Thumbnail Path"
                (error)="setDefaultPic()" />
            </div>
          </div>
          <div class="p-d-flex">
            <div class="p-mr-2">
              <div class="p-d-flex p-flex-column" id="text1">
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText1">Latin Name</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText1">Category</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText1">Type</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText1">Climates</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText1">Color</div>
                </div>
                <div class="p-mb-2" id="contentTitle">
                  <div id="rowText1">Traits</div>
                </div>
              </div>
            </div>
            <div class="p-mr-2">
              <div class="p-d-flex p-flex-column" id="text1">
                <div class="p-mb-2" id="contentText">
                  <div id="rowText1">
                    {{ itemForm.get("latinName")?.value ? itemForm.get("latinName")?.value : "🌼 Not Filled" }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText1">
                    {{ this.plantPreview.category ? this.plantPreview.category : "🌼 Not Filled" }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText1">
                    {{ this.plantPreview.type ? this.plantPreview.type : "🌼 Not Filled" }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText1">
                    {{ this.plantPreview.climates.names.length > 0 ? this.plantPreview.climates.names : "🌼 Not Filled"
                    }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText1">
                    {{ this.plantPreview.colors.names.length > 0 ? this.plantPreview.colors.names : "🌼 Not Filled" }}
                  </div>
                </div>
                <div class="p-mb-2" id="contentText">
                  <div id="rowText1">
                    {{ this.plantPreview.traits.names.length > 0 ? this.plantPreview.traits.names : "🌼 Not Filled" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="p-d-flex p-flex-column">
            <div class="p-mb-2">
              <div id="AboutTitle">About This Plant</div>
            </div>
            <div class="p-mb-2">
              <div class="p-d-flex p-jc-center">
                <img id="image2" src="{{ this.fields.spruce_img }}" alt="🥀 Sorry No Image Found" />
              </div>
            </div>
            <div class="p-mb-2">
              <div class="p-d-flex">
                <div class="p-mr-2">
                  <div class="p-d-flex p-flex-column" id="text1">
                    <div class="p-mb-2" id="contentTitle">
                      <div id="rowText2">Typical Size</div>
                    </div>
                    <div class="p-mb-2" id="contentTitle">
                      <div id="rowText2">Hardiness Zone</div>
                    </div>
                    <div class="p-mb-2" id="contentTitle">
                      <div id="rowText2">Native Area</div>
                    </div>
                    <div class="p-mb-2" id="contentTitle">
                      <div id="rowText2">Sun Exposure</div>
                    </div>
                    <div class="p-mb-2" id="contentTitle">
                      <div id="rowText2">Bloom Time</div>
                    </div>
                  </div>
                </div>
                <div class="p-mr-2">
                  <div class="p-d-flex p-flex-column" id="text1">
                    <div class="p-mb-2" id="contentText">
                      <div id="rowText2">
                        {{ this.plantPreview.typicalSize ? this.plantPreview.typicalSize : "🌼 Not Filled" }}
                      </div>
                    </div>
                    <div class="p-mb-2" id="contentText">
                      <div id="rowText2">
                        {{ this.plantPreview.hardinessZone ? this.plantPreview.hardinessZone : "🌼 Not Filled" }}
                      </div>
                    </div>
                    <div class="p-mb-2" id="contentText">
                      <div id="rowText2">
                        {{ this.plantPreview.nativeArea ? this.plantPreview.nativeArea : "🌼 Not Filled" }}
                      </div>
                    </div>
                    <div class="p-mb-2" id="contentText">
                      <div id="rowText2">
                        {{ this.plantPreview.sunExposure ? this.plantPreview.sunExposure : "🌼 Not Filled" }}
                      </div>
                    </div>
                    <div class="p-mb-2" id="contentText">
                      <div id="rowText2">
                        {{ this.plantPreview.bloomTime ? this.plantPreview.bloomTime : "🌼 Not Filled" }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-d-flex p-flex-column">
              <div class="p-mb-2">
                <div class="p-text-left">
                  {{ this.plantPreview && this.plantPreview.blurb ? this.plantPreview.blurb : " Description goes here
                  .... 🌼 Not Filled" }}
                </div>
              </div>
              <div class="p-mb-2">
                <button id="contentButton">Read more on the Spruce</button>
              </div>
            </div>
          </div>
        </div>
        <div id="footerBody">
          <div class="p-d-flex p-flex-column p-flex-md-row">
            <div class="p-mb-2 p-mr-2" id="costSection">
              <img id="gold" src="https://d3tfb94dc03jqa.cloudfront.net/images/currency/currency_coin_fly_up.png" /> {{
              this.plantPreview.cost ? this.plantPreview.cost : " 2,999" }}
            </div>
            <div class="p-mb-2 p-mr-2">
              <button id="footerButton">Buy in game</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 'Nested Group' overlay -->
<p-overlayPanel #nestedGroup_overlay [dismissable]="true" [showCloseIcon]="true" [style]="{ width: '23em' }">
  <ng-template pTemplate="content">
    <h4 class="p-my-0">
      {{ currentNestedGroup.field.name }} #{{ currentNestedGroup.index + 1 }}
    </h4>

    <div class="p-d-flex p-flex-column">
      <div class="p-inputgroup p-mt-4">
        <span ngClass="p-float-label">
          <p-dropdown [(ngModel)]="currentNestedGroup.value['id']" [ariaLabel]="'Currency'" [id]="'id'"
            [options]="options.id" optionLabel="name" [autoDisplayFirst]="false" [style]="{ width: '100%' }"
            (onChange)="onNestedFieldChanged()"></p-dropdown>
          <label for="id">Currency</label>
        </span>

        <p-button *ngIf="currentNestedGroup.value['id']" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="p-button-text p-button-danger" (click)="onNestedFieldCleared('id')"></p-button>
      </div>

      <div class="p-inputgroup p-mt-4" *ngIf="currentNestedGroup.value['id'] || currentNestedGroup.value['c']">
        <span class="p-float-label">
          <input [(ngModel)]="currentNestedGroup.value['c']" [id]="'c'" type="number" pInputText
            (input)="onNestedFieldChanged()" />
          <label for="c">Count</label>
        </span>

        <p-button *ngIf="currentNestedGroup.value['c']" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="p-button-text p-button-danger" (click)="onNestedFieldCleared('c')"></p-button>
      </div>
    </div>

    <p-button label="Another" icon="pi pi-plus" styleClass="p-button-sm p-mx-auto p-mt-3 p-d-flex p-jc-end" [autofocus]="
        currentNestedGroup.value['id'] && currentNestedGroup.value['c']
      " (click)="
        onOpenNestedGroup(
          currentNestedGroup.field,
          this.itemForm.value[currentNestedGroup.field.key].length
        )
      "></p-button>
  </ng-template>
</p-overlayPanel>
<p-dialog *ngIf="!loading" header="Start/End Date Verification" [(visible)]="showDatesVerificationModal" [modal]="true"
  [style]="{minWidth: '40vw'}">
  <!-- START END -->
  <div class="p-grid">
    <div class="p-col">
      <div>
        <h4 class="p-m-0">Original Start and End date values</h4>
      </div>
      <div class="p-grid p-mt-0">
        <div class="p-col">
          <p>Start:</p><small
            *ngIf="originalRecord['start']">{{itemService.formatDate(originalRecord['start'])}}</small>
        </div>
        <div class="p-col">
          <p>End:</p><small *ngIf="originalRecord['end']">{{itemService.formatDate(originalRecord['end'])}}</small>
        </div>
      </div>
    </div>
    <div class="p-col">
      <div>
        <h4 class="p-m-0">New Start and End date values</h4>
      </div>
      <div class="p-grid p-mt-0">
        <div class="p-col">
          <p>Start:</p><small
            *ngIf="itemForm.value['start']">{{itemService.formatDate(itemForm.value['start'])}}</small>
        </div>
        <div class="p-col">
          <p>End:</p><small *ngIf="itemForm.value['end']">{{itemService.formatDate(itemForm.value['end'])}}</small>
        </div>
      </div>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <p-tag styleClass="p-mx-2" [value]="startDateErrorMessage" severity="danger" [rounded]="true"></p-tag>
    <p-tag styleClass="p-mx-2" [value]="endDateErrorMessage" severity="danger" [rounded]="true"></p-tag>
  </div>
  <p-divider></p-divider>
  <!-- Re Release Start End -->
  <!-- START END -->
  <div class="p-grid">
    <div class="p-col">
      <div>
        <h4 class="p-m-0">Original Re-Release Start and End date values</h4>
      </div>
      <div class="p-grid p-mt-0">
        <div class="p-col">
          <p>Start:</p><small
            *ngIf="originalRecord['reReleaseStart']">{{itemService.formatDate(originalRecord['reReleaseStart'])}}</small>
        </div>
        <div class="p-col">
          <p>End:</p><small
            *ngIf="originalRecord['reReleaseEnd']">{{itemService.formatDate(originalRecord['reReleaseEnd'])}}</small>
        </div>
      </div>
    </div>
    <div class="p-col">
      <div>
        <h4 class="p-m-0">New Re-Release Start and End date values</h4>
      </div>
      <div class="p-grid p-mt-0">
        <div class="p-col">
          <p>Start:</p><small
            *ngIf="itemForm.value['reReleaseStart']">{{itemService.formatDate(itemForm.value['reReleaseStart'])}}</small>
        </div>
        <div class="p-col">
          <p>End:</p><small
            *ngIf="itemForm.value['reReleaseEnd']">{{itemService.formatDate(itemForm.value['reReleaseEnd'])}}</small>
        </div>
      </div>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <p-tag styleClass="p-mx-2" [value]="reStartDateErrorMessage" severity="danger" [rounded]="true"></p-tag>
    <p-tag styleClass="p-mx-2" [value]="reEndDateErrorMessage" severity="danger" [rounded]="true"></p-tag>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple type="submit" label="Cancel Submit" class="p-mt-2 p-button-secondary"
      (click)="showDatesVerificationModal=!showDatesVerificationModal"></button>
    <button pButton pRipple type="submit" label="Confirm Submit" class="p-mt-2 p-button"
      (click)="submitAndSkipChecks('date')"></button>
  </ng-template>
</p-dialog>
<!-- environment flags prerequisites validation dialog -->
<p-dialog *ngIf="!loading" header="Environment Flags Prerequisites Validation" [(visible)]="showEnvsVerificationModal"
  [modal]="true" [style]="{minWidth: '40vw'}">
  <div class="p-grid p-mt-0">
    <div class="p-col">
      <span>
        <strong class="p-m-0">Environment Flag Prerequisites:</strong>
        <ul *ngFor="let env of this.options['envs']" class="p-mt-2">
          <li>
            <span class="p-m-0">
              <p-tag styleClass="p-mx-2" [value]="env" [severity]="this.envRules[env]?.status ? 'success' : 'danger'"
                [rounded]="true"></p-tag>
              <p-chip class="p-mx-1" *ngFor="let rule of this.envRules[env]?.rules" [label]="rule"></p-chip>
            </span>
          </li>
        </ul>
      </span>
    </div>
  </div>
  <p-divider></p-divider>
  <div class="p-grid p-mt-0">
    <div class="p-col">
      <span>
        <strong class="p-m-0">Original Env Values:</strong>
        <p *ngFor="let env of originalRecord['env']" class="p-my-0">
          <span class="p-mx-1 ">{{env}}</span>
        </p>
      </span>
    </div>
    <div class="p-col">
      <span>
        <strong class="p-m-0">New Env Values:</strong>
        <p *ngFor="let env of itemForm.value['env']" class="p-my-0">
          <span class="p-mx-1 ">{{env}}</span>
        </p>
      </span>
    </div>
  </div>
  <p-divider></p-divider>
  <div>
    <p-tag styleClass="p-mx-2" value="Missing Environment Flags Prerequisites" severity="danger"
      [rounded]="true"></p-tag>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple type="submit" label="Cancel Submit" class="p-mt-2 p-button-secondary"
      (click)="showEnvsVerificationModal=!showEnvsVerificationModal"></button>
    <button pButton pRipple type="submit" label="Confirm Submit" class="p-mt-2 p-button"
      (click)="submitAndSkipChecks('envs')"></button>
  </ng-template>
</p-dialog>
<div *ngIf="loading" class="p-px-6 p-pt-3">
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
</div>