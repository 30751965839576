<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="gamedataDiff"
    [viewName]="title"
    [(record)]="record"
    [fields]="fields"
    [parseRecordData]="true"
    (onParseRecordData)="prepareRecordData($event)"
    [displayThumbnail]="false"
    [displayBuildStatus]="false"
>
</dynamic-view-v2>
