import { Component, Input, OnInit } from '@angular/core';
import { DataService } from '../../../services/data.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { EntityViewService } from 'src/app/services/entity-view.service';

@Component({
  selector: 'app-itembuildshistory',
  templateUrl: './item-buildshistory.component.html',
  styleUrls: ['./item-buildshistory.component.sass'],
})
export class ItemBuildsHistoryComponent implements OnInit {
  @Input() id: any;
  entity: any;
  type: string = 'items';
  data: any = {};
  buildsHistory: any;
  rows: any = [];
  columns: any = ([] = []);
  href: any;
  date_title: any;
  value: null;
  action: string;
  isLoading: boolean = true;

  _selectedCols: any[];

  constructor(
    private entityViewService: EntityViewService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private datePipe: DatePipe,
    private router: Router
  ) {}

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    await this.entityViewService
    .getEntity(this.type, { query: { id: this.id } })
    .then(async (data: any) => {
      this.entity = data;
    });
    this.buildsHistory = await this.dataService.getBuildHistoryData(
      this.entity.buildData[0].path,
      false
    );
    this.isLoading = false;
  }

  /**
   * Retrieves the color from the build status value.
   *
   * @param status Build status.
   */
  getColorFromBuildStatus(status: string) {
    status = status.toLowerCase();
    let output = '';
    switch (status) {
      case 'finished':
        output = 'var(--primary-color)';
        break;
      case 'failed':
        output = 'var(--pink-600)';
        break;
      case 'building':
        output = 'var(--yellow-600)';
        break;
      case 'queued':
        output = 'var(--gray-400)';
        break;
    }
    return output;
  }

   /**
   * Retrieves the color from the build bundle file size based on a treshold.
   *
   * @param status Build status.
   */
  getColorFromFileSize(build: any) {
    let sizeString = this.getSize(build.files);
    sizeString = sizeString.replaceAll(" ","");
    sizeString = sizeString.substring(0,sizeString.lastIndexOf("KB"));
    let size = parseFloat(sizeString);

    if(build.path.includes('levels')){
      if ((size > 3000) || (size < 500) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('images/challenge_feed' )){
      if ( (size > 1100) || (size < 100) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('items' )){
      if ( (size > 8000) || (size < 250)){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else if (build.path.includes('images/item_thumbs' )){
      if ( (size > 1100) || (size < 100) ){
        return 'var(--gray-900)';
      } else {
        return 'var(--gray-100)';
      }
    } else {
      return 'var(--gray-100)';
    }

  }

  /**
   * returns the value of the first key (angular had trouble doing it.)
   */
  getSize(obj: any){
    return obj[Object.keys(obj)[0]];
  }

  /**
   * parses json to string for angular
   */
  parse(jsonString: any){
    return JSON.stringify(jsonString);
  }

}
