import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { SourcingService } from '../../items-sourcing/services/sourcing.service';
import { TableDataComponent } from '../../table-data/components/table-data/table-data.component';
import FieldData from '../../../../common/components/base-fields/field-data-dto';
import { SourcingItemGroupService } from '../services/sourcing-group.service';
import { ItemService } from 'src/app/entities/item/services/item.service';
import { pathconfigValues } from 'src/app/entities/item/data/constants';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { DynamicTableComponent } from 'src/app/common/components/dynamic-table/dynamic-table.component';
import { SpinnerService } from 'src/app/common/services/spinner.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-sourcing-group-view',
  templateUrl: './sourcing-group-view.component.html',
  styleUrls: ['./sourcing-group-view.component.sass'],
})
export class SourcingGroupViewComponent
  extends TableDataComponent
  implements OnInit
{
  constructor(
    private entityViewService: EntityViewService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private logger: LoggerService,
    private titleService: Title,
    private sourcingService: SourcingService,
    private sourcingItemGroupService: SourcingItemGroupService,
    private messageService: MessageService,
    private itemService: ItemService,
    private utilitiesService: UtilitiesService,
    private spinnerService: SpinnerService,
    private commonEntityService: CommonEntityService,
    private authService: AuthService
  ) {
    super();
  }

  @ViewChild(DynamicTableComponent)
  dynamicTableComponent: DynamicTableComponent;
  // variables for path generation
  fileTypePath: string = '';
  fileTypeCode: string = '';
  uploadedRows: any;
  fileNamePath: string = '';
  defaultYearPath: string = pathconfigValues.year;
  options: Array<any> = [];
  tmpFields: any = { prefab: null, thumbnail: null, fileName: null };
  displayCreateCollection: boolean = false;
  displayAddToCollectionModal: boolean = false;
  createCollection: any = {
    name: '',
    items_ref: []
  }
  user: any = null
  key: string = 'sourcing-groups';
  pageTitle: string = 'Sourcing Group';
  section1Title: string = 'Source Group Details';
  section2Title: string = 'Item Sourcing Table';

  id: number;
  entity: any;
  doc: any = {
    name: null,
    start: null,
    end: null,
    enabled: false,
  };

  currentTimeout: any;
  currentTimeout2: any;
  displayModal: boolean = false;
  invalidItems: Array<any> = [];
  displayValidationModal: boolean = false;
  severityType: string;
  displayUploadToCollectionModal: boolean = false;
  displayAddCollection: boolean = false;
  collections: Array<any> = [];
  selectedCollection: any;
  promotedItems: Array<any> = [];

  /**
   * Sourcing Group Component Initialization
   */
  async ngOnInit() {
    this.isLoading = true;
    await this.setItemFileTypeOptions();
    this.title = 'Sourcing Items';
    this.type = 'items-sourcing';
    let result = this.authService.getSocialUser()
    this.user = {
      name: result.currentUser.name,
      email: result.currentUser.email,
      id: result.currentUser.id,
    };
    this.globalFilters = [
      'id',
      'name',
      'category_ref.name',
      'type_ref.name',
      'thumbnail',
      'prefab',
      'batch_ref.name',
      'fileName',
      'commonName',
      'plantFamily',
      'botanicalName',
      'latinName',
      'cultivar',
      'height',
      'spread',
      'internalNotes',
      'referenceLinks',
      'buildStatus.text',
      'progressionLevel_ref.level',
      'vendorStatus',
    ];
    this.filtersToRegister = [
      ...this.defaultFilters,
      'stringIn',
      'stringIn_ref',
      'multiIn',
      'multiIn_ref',
      'complex',
      'inCollection',
    ];

    this.tableConfig.isEditMode = false;

    const routeParams = this.route.snapshot.paramMap;

    this.id = Number(routeParams.get('id'));

    await this.getExistingDoc().then(async (result) => {
      this.entity = result;
      this.logger.log('checking existing doc', this.entity);
      if (this.entity.name) {
        this.doc.name = this.entity.name;
      }

      if (this.entity.start) {
        this.doc.start = this.entity.start;
      }

      if (this.entity.end) {
        this.doc.end = new Date(this.entity.end);
      }

      if (this.entity.enabled) {
        this.doc.enabled = this.entity.enabled;
      }
    });

    this.fields = [
      ...this.defaultFields,
      {
        key: 'vendorStatus',
        name: 'Art Status',
        controlType: 'dropdown',
        options: [
          { label: 'Awaiting Batch', value: 'Awaiting Batch' },
          { label: 'Assigned', value: 'Assigned' },
          { label: 'Sent to Vendor', value: 'Sent to Vendor' },
          { label: 'Awaiting Revision', value: 'Awaiting Revision' },
          { label: 'Approved', value: 'Approved' },
        ],
        filterKey: 'vendorStatus',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'assetType',
        name: 'Asset Type',
        controlType: 'dropdown',
        options: [
          { label: 'Organics', value: 'Organics' },
          { label: 'Organic Recolors', value: 'Organic Recolors' },
          { label: 'Hard Surface', value: 'Hard Surface' },
          { label: 'HS Recolor', value: 'HS Recolor' },
        ],
        filterKey: 'assetType',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'batch_ref',
        name: 'Batch',
        controlType: 'dropdown_ref',
        apiController: 'batches',
        isOptionsMin: true,
        filterKey: 'batch_ref._id',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'blurb',
        name: 'Blurb',
        controlType: 'inputTextarea',
        filterKey: 'blurb',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'blurbStatus',
        name: 'Blurb Status',
        controlType: 'dropdown',
        options: [
          { label: 'Needs Blurb', value: 'Needs Blurb' },
          { label: 'Ready for Review', value: 'Ready for Review' },
          { label: 'Revision Needed', value: 'Revision Needed' },
          { label: 'Approved', value: 'Approved' },
        ],
        filterKey: 'blurbStatus',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'category_ref',
        name: 'Category',
        controlType: 'dropdown_ref',
        apiController: 'categories',
        filterKey: 'category_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        isOptionsMin: true,
      },
      {
        key: 'climates_ref',
        name: 'Climate(s)',
        controlType: 'multiSelect_ref',
        apiController: 'climates',
        filterKey: 'climates_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        isOptionsMin: true,
      },
      {
        key: 'collections',
        name: 'Collection(s)',
        controlType: 'autoComplete-multi_ref',
        apiController: 'collections',
        filterKey: 'id',
        matchMode: 'inCollection',
        filterProp: 'items',
        isColumn: false,
        isHidden: true,
        isInputField: false,
        isFilterField: true,
        isOptionsMin: false,
      },
      {
        key: 'colors_ref',
        name: 'Color(s)',
        controlType: 'multiSelect_ref',
        apiController: 'colors',
        filterKey: 'colors_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        isOptionsMin: true,
      },
      {
        key: 'contentHold',
        name: 'Content Hold',
        controlType: 'multiSelect',
        options: [
          'Bundle Hold',
          'New Arrivals Hold',
          'Prize Hold',
          'Progression Hold',
          'Series Hold',
          'Do Not Use',
        ],
        filterKey: 'contentHold',
        matchMode: 'multiIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'costs_ref',
        name: 'Cost(s)',
        controlType: 'lineItem',
        viewControl: {
          sortBy: 'c',
        },
        filterKey: 'costs_ref',
        matchMode: 'complex',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        subFields: [
          {
            key: 'lineItemType',
            name: 'Line Item Type',
            controlType: 'inputText',
            isHidden: true,
            presetValue: 'Currency',
          },
          {
            key: 't',
            name: 'Resource',
            controlType: 'dropdown_ref',
            apiController: 'resources',
            filterKey: 't',
            matchMode: 'stringIn_ref',
            isOptionsMin: true,
            isInputField: false,
            isFilterField: false,
            isHidden: true,
            presetValue: {
              key: 'name',
              value: 'Currency',
            },
          },
          {
            key: 'id',
            name: 'Currency',
            controlType: 'dropdown_ref',
            apiController: 'currencies',
            filterKey: 'id',
            matchMode: 'stringIn_ref',
            isOptionsMin: true,
            isFilterField: true,
            isInputField: true,
          },
          {
            key: 'c',
            name: 'Count',
            controlType: 'inputNumber',
            filterKey: 'c',
            matchMode: 'between',
            isFilterField: true,
            isInputField: true,
          },
        ],
      },
      {
        key: 'cultivar',
        name: 'Cultivar',
        controlType: 'inputText',
        filterKey: 'cultivar',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'dimensionX',
        name: 'Dimension (X)',
        controlType: 'inputNumber',
        filterKey: 'dimensionX',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'dimensionY',
        name: 'Dimension (Y)',
        controlType: 'inputNumber',
        filterKey: 'dimensionY',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'dimensionZ',
        name: 'Dimension (Z)',
        controlType: 'inputNumber',
        filterKey: 'dimensionZ',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'fileName',
        name: 'File Name',
        controlType: 'inputText',
        filterKey: 'fileName',
        matchMode: 'contains',
        isColumn: true,
        isFilterField: true,
        isInputField: true,
      },
      {
        key: 'flagged',
        name: 'Flagged',
        controlType: 'dropdown',
        options: [
          { label: 'In QA', value: 'In QA' },
          { label: 'Hold', value: 'Hold' },
          { label: 'Marked for Deletion', value: 'Marked for Deletion' },
        ],
        filterKey: 'flagged',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'height',
        name: 'Height',
        controlType: 'inputText',
        filterKey: 'height',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'internalNotes',
        name: 'Internal Notes',
        controlType: 'inputTextarea',
        filterKey: 'internalNotes',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'internalReferenceLinks',
        name: 'Internal Reference Links',
        controlType: 'ref-link',
        filterKey: 'internalReferenceLinks',
        matchMode: 'notEmpty',
        isColumn: true,
        isFilterField: true,
        isInputField: true,
        subFields: [
          {
            key: 'link',
            name: 'Link',
            controlType: 'inputText',
            filterKey: 'link',
            matchMode: 'contains',
            isInputField: true,
            isFilterField: false,
          },
        ],
      },
      {
        key: 'itemFileType_ref',
        name: 'File Type',
        controlType: 'dropdown_ref',
        apiController: 'item-file-types',
        isOptionsMin: true,
        filterKey: 'itemFileType_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'itemStatus',
        name: 'Content Status',
        controlType: 'dropdown',
        options: [
          { label: 'QA Ready', value: 'QA Ready' },
          { label: 'Awaiting Revision', value: 'Awaiting Revision' },
          { label: 'Approved', value: 'Approved' },
        ],
        filterKey: 'itemStatus',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'item_ref',
        name: 'Item Ref',
        controlType: 'dropdown_ref',
        apiController: 'items-sourcing',
        isOptionsMin: true,
        filterKey: 'item_ref',
        matchMode: 'contains',
        isColumn: true,
        isInputField: false,
        isFilterField: true,
      },
      {
        key: 'latinName',
        name: 'Latin Name',
        controlType: 'inputText',
        filterKey: 'latinName',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'materials_ref',
        name: 'Material(s)',
        controlType: 'multiSelect_ref',
        apiController: 'materials',
        isOptionsMin: true,
        filterKey: 'materials_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'patterns_ref',
        name: 'Pattern(s)',
        controlType: 'multiSelect_ref',
        apiController: 'patterns',
        isOptionsMin: true,
        filterKey: 'patterns_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'plantFamily',
        name: 'Plant Family',
        controlType: 'inputText',
        filterKey: 'plantFamily',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'prefab',
        name: 'Prefab Path',
        controlType: 'inputText',
        filterKey: 'prefab',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'progressionLevel_ref',
        name: 'Progression Level',
        controlType: 'dropdown_ref',
        apiController: 'progression-levels',
        isOptionsMin: true,
        filterKey: 'progressionLevel_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'radius',
        name: 'Radius',
        controlType: 'inputNumber',
        filterKey: 'radius',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      // {
      //   key: 'recolorSource',
      //   name: 'Recolor Source',
      //   controlType: 'dropdown',
      //   options: [
      //     { label: 'Internal', value: 'Internal' },
      //     { label: 'External', value: 'External' },
      //   ],
      //   filterKey: 'recolorSource',
      //   matchMode: 'stringIn',
      //   isColumn: true,
      //   isInputField: true,
      //   isFilterField: true,
      // },
      {
        key: 'referenceLinks',
        name: 'Vendor Reference Links',
        controlType: 'ref-link',
        filterKey: 'referenceLinks',
        matchMode: 'notEmpty',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        subFields: [
          {
            key: 'link',
            name: 'Link',
            controlType: 'inputText',
            filterKey: 'link',
            matchMode: 'contains',
            isFilterField: false,
            isInputField: true,
          },
        ],
      },
      {
        key: 'shape_ref',
        name: 'Shape',
        controlType: 'dropdown_ref',
        apiController: 'shapes',
        isOptionsMin: true,
        filterKey: 'shape_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'spread',
        name: 'Spread',
        controlType: 'inputText',
        filterKey: 'spread',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'spruceDataStatus',
        name: 'Spruce Data Status',
        controlType: 'toggle',
        filterKey: 'spruceDataStatus',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'styles_ref',
        name: 'Style(s)',
        controlType: 'multiSelect_ref',
        apiController: 'styles',
        isOptionsMin: true,
        filterKey: 'styles_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'subType',
        name: 'Sub-Type',
        controlType: 'inputText',
        filterKey: 'subType',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'tags',
        name: 'Tags',
        controlType: 'multiSelect',
        options: ['NUF', 'Prize', 'Progression', 'Series Completion Reward', 'Specialty'],
        filterKey: 'tags',
        matchMode: 'multiIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'thumbnail',
        name: 'Thumbnail Path',
        controlType: 'inputText',
        filterKey: 'thumbnail',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'thumbnail_img',
        name: 'Thumbnail',
        controlType: 'image',
        isColumn: true,
        isInputField: false,
        isFilterField: false,
      },
      {
        key: 'traits_ref',
        name: 'Trait(s)',
        controlType: 'multiSelect_ref',
        apiController: 'traits',
        isOptionsMin: true,
        filterKey: 'traits_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'sourceGroup_ref',
        name: 'Sourcing Group',
        controlType: 'dropdown_ref',
        apiController: 'items-sourcing',
        isOptionsMin: true,
        filterKey: 'sourceGroup_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'type_ref',
        name: 'Type',
        controlType: 'dropdown_ref',
        apiController: 'item-types',
        isOptionsMin: true,
        filterKey: 'type_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendor_ref',
        name: 'Vendor',
        controlType: 'dropdown_ref',
        apiController: 'vendors',
        isOptionsMin: true,
        filterKey: 'vendor_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorDimensions',
        name: 'Vendor Dimensions',
        controlType: 'inputText',
        filterKey: 'vendorDimensions',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorNotes',
        name: 'Vendor Notes',
        controlType: 'inputTextarea',
        filterKey: 'vendorNotes',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'year',
        name: 'Year',
        controlType: 'dropdown',
        options: [
          { label: '2021', value: '2021' },
          { label: '2022', value: '2022' },
          { label: '2023', value: '2023' },
          { label: '2024', value: '2024' },
          { label: '2025', value: '2025' },
        ],
        filterKey: 'year',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorHeight',
        name: 'Vendor Height',
        controlType: 'inputText',
        filterKey: 'vendorHeight',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorWidth',
        name: 'Vendor Width',
        controlType: 'inputText',
        filterKey: 'vendorWidth',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorLength',
        name: 'Vendor Spread',
        controlType: 'inputText',
        filterKey: 'vendorLength',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'promoted',
        name: 'Uploaded',
        controlType: 'toggle',
        filterKey: 'promoted',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: false,
        isFilterField: true,
      },
      {
        key: 'archived',
        name: 'Archived',
        controlType: 'toggle',
        filterKey: 'archived',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
    ];

    this.defaultColumnOrder = [
      // not editable
      'id',
      'thumbnail_img',
      'fileName',

      // main card
      'name',
      'cultivar',
      'plantFamily',
      'latinName',

      // 'Required - Production'
      'prefab',
      'thumbnail',
      'vendorDimensions',

      'itemFileType_ref',
      'assetType',
      'year',
      'vendor_ref',
      'batch_ref',
      'collections',

      'vendorStatus',
      'flagged',
      // 'recolorSource',

      // 'Vendor Notes'
      'vendorNotes',

      // 'Content'
      'climates_ref',
      'category_ref',
      'type_ref',
      'traits_ref',
      'colors_ref',
      'start',
      'end',

      'itemStatus',
      'contentHold',
      'materials_ref',
      'patterns_ref',
      'styles_ref',
      'tags',
      'shape_ref',
      'progressionLevel_ref',
      'costs_ref',
      'sourceGroup_ref',
      'item_ref',

      // 'Internal Notes'
      'internalNotes',

      // 'Dev'
      'dimensionX',
      'dimensionY',
      'dimensionZ',
      'radius',

      // 'Info Blurb'
      'blurbStatus',
      'blurb',

      // 'Reference'
      'height',
      'spread',

      'internalReferenceLinks',
      'referenceLinks',

      // not editable
      'enabled',
      'promoted',
      'subType',
      'spruceDataStatus',
      'vendorHeight',
      'vendorWidth',
      'vendorLength',
      'archived',
    ];
    this.customGlobalColumnSets = [
      {
        name: 'Default',
        value: ['id', 'name', 'start', 'end', 'enabled', 'archived'],
      },
    ];

    this.tableConfig.duplicateRowEnabled = true;
    this.tableConfig.customFilterToRegister = this.filtersToRegister;
    await this.getExistingDoc();
    await this.getAllCollections();
    this.isLoading = false;
  }

  /**
   * Retrieves all collections
   */
  async getAllCollections()
  {
    let result = await this.commonEntityService.findAllWithQuery('collections', { query: {}, select: 'name id _id', autopopulate: false, virtuals: false });
    if(result)
    {
      this.collections = result;
    }
  }

  /**
   * Retrieves existing sourcing group reecord
   */
  async getExistingDoc() {
    const doc = await this.dataService.getDocumentAsync(this.key, {
      query: { id: this.id },
      autopopulate: true,
      virtuals: true,
    });
    return doc;
  }

  /**
   * Auto save changes on table fields
   *
   * @param event Event comming from dynamic table component
   */
  async onChange(event: any) {
    if(this.dynamicTableComponent.tableConfig.isEditMode)
    {
      this.dynamicTableComponent.isSaved = false;
      if (this.currentTimeout) {
        clearTimeout(this.currentTimeout);
      }
      let field = event.field.key;
      this.currentTimeout = setTimeout(async () => {
        let response = null;
        if (event.isClearAction && field == 'itemFileType_ref') {
          response = await this.sourcingService.updateItemSourcing(
            event.entity.id,
            {
              [field]: event.value,
              prefab: '',
              thumbnail: '',
              fileName: '',
            }
          );
        }
        else if(field == 'year')
        {
          let prefabPath = event.entity.prefab && event.entity.prefab.length > 0 ? event.entity.prefab.split('/'): [];
          let thumbPath = event.entity.thumbnail && event.entity.thumbnail.length > 0 ? event.entity.thumbnail.split('/'): [];

          if(prefabPath.length == 5)
          {
            prefabPath[2] = event.value;
          }
          else
          {
            prefabPath = [];
          }

          if(thumbPath.length == 4)
          {
            thumbPath[2] = event.value;
          }
          else
          {
            thumbPath = [];
          }

          response = await this.sourcingService.updateItemSourcing(
            event.entity.id,
            {
              [field]: event.value,
              prefab: prefabPath && prefabPath.length > 0 ? prefabPath.join('/'): null,
              thumbnail: thumbPath && thumbPath.length > 0 ? thumbPath.join('/'): null
            }
          );
          this.dynamicTableComponent.rows[entityIndex]['prefab'] = prefabPath && prefabPath.length > 0 ? prefabPath.join('/'): null;
          this.dynamicTableComponent.rows[entityIndex]['thumbnail'] = thumbPath && thumbPath.length > 0 ? thumbPath.join('/'): null;
        }
        else {
          response = await this.sourcingService.updateItemSourcing(
            event.entity.id,
            {
              [field]: event.value,
            }
          );
        }

        if (response) {
          this.dynamicTableComponent.isSaved = true;
        }
        this.currentTimeout = null;
      }, 500);
      let entityIndex = this.dynamicTableComponent.rows.findIndex(
        (element) => element.id === event.entity.id
      );
      this.dynamicTableComponent.rows[entityIndex][field] = event.value;

      if (event.isClearAction && field == 'itemFileType_ref') {
        this.dynamicTableComponent.rows[entityIndex]['prefab'] = '';
        this.dynamicTableComponent.rows[entityIndex]['thumbnail'] = '';
        this.dynamicTableComponent.rows[entityIndex]['fileName'] = '';
      }
    }
  }

  /**
   * Copies the ID, field and error message of a row in the validation report
   * @param row Row to be copied
   */
  copyMessage(row: any) {
    let stringBuild =
      'Item ID: ' +
      row.Entity.id +
      '   ' +
      'Field: ' +
      row.Field +
      '   ' +
      'Error Message: ' +
      row.ErrorMessage;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = stringBuild;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Row Copied',
      detail: `Row copied to clipboard successfully!`,
    });
  }
  /**
   * Promotes Sourcing Items
   *
   * @param event Event comming from dynamic table component.
   */
  async promoteItems(event: any) {
    event.data.forEach((element:any) => {
      element.enabled = true;
    });
    if (event) {
      this.spinnerService.loadSpecificSpinner('sourcing-group-view-spinner');
      if (event.data) {
        await this.promoteSelectedSourcingItems(event.data, event.user);
      } else {
        await this.promoteAllSourcingItems(event.user);
      }
      this.spinnerService.endSpecificSpinner('sourcing-group-view-spinner');
    }
  }

  /**
   * Promotes selected items tied to a sourcing group
   *
   * @param items List of selcetd sourcing items
   * @param user Current user logged-in
   */
  async promoteSelectedSourcingItems(items: Array<any> = [], user: any) {
    let response = await this.sourcingItemGroupService.promoteSelectedSourcingItems(
      this.entity._id,
      { sourcingItems: items, user: user }
    );

    await this.handlePromoteResponse(response);
  }

  /**
   * Promotes all items tied to a sourcing group
   *
   * @param user Current user logged-in
   */
  async promoteAllSourcingItems(user: any) {
    let response = await this.sourcingItemGroupService.promoteItemSourcingGroup(
      this.entity._id,
      { user: user }
    );

    await this.handlePromoteResponse(response);
  }

  /**
   * Handle Promote Response comming from API
   *
   * @param response Response from API
   */
  async handlePromoteResponse(response: any) {
    this.promotedItems = [];
    if (response.Success) {
      this.promotedItems = response.ItemsPromoted;
      await this.dynamicTableComponent.getItemSourceRowData();
      this.dynamicTableComponent.displayConfirmItemSourcingUploadModal = false;
      this.dynamicTableComponent.showActions = false;
      this.dynamicTableComponent.selectedRows = [];
      this.messageService.add({
        sticky: true,
        severity:
          response.ItemPromotedCount && response.ItemPromotedCount > 0
            ? 'success'
            : 'info',
        summary:
          response.ItemPromotedCount && response.ItemPromotedCount > 0
            ? 'Upload Successful'
            : 'Nothing to upload',
        detail: response.Message,
      });
      if(response.ItemPromotedCount && response.ItemPromotedCount > 0) {
        this.displayUploadToCollectionModal = true;
      } else {
        this.displayAddCollection = true
      }

    } else {
      this.dynamicTableComponent.displayConfirmItemSourcingUploadModal = false;
      this.dynamicTableComponent.showActions = false;
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Upload Error',
        detail: response.Message,
      });
      this.invalidItems = response.InvalidItems;
      this.displayModal = true;
    }
  }

  /**
   * Set Item File Type Options
   */
  async setItemFileTypeOptions() {
    await this.utilitiesService.getOptionsFromRef(
      this.options,
      'itemFileType_ref',
      'item-file-types',
      false
    );
  }

  /**
   * This function builds a suggested path for prefab and thumbnail values based on the user's input.
   * @param
   * uses this variables to store the path's state:
   *  fileTypePath: string = '';
      fileTypeCode: string = '';
      fileNamePath: string = '';
      defaultYearPath: string = pathconfigValues.year;
   */
  async onPathComponentValueChange(eventValue: any) {
    this.dynamicTableComponent.isSaved = false;
    if (eventValue.entity) {
      if (eventValue.inputName == 'year') {
        if (eventValue.entity.prefab && eventValue.entity.prefab.length > 0) {
          let prefabValues = eventValue.entity.prefab.split('/');
          this.fileTypePath = prefabValues[1];
        }

        if (
          eventValue.entity.fileName &&
          eventValue.entity.fileName.length > 0
        ) {
          this.fileNamePath = eventValue.entity.fileName;
        }
      }

      if (eventValue.inputName == 'fileType') {
        if (eventValue.entity.year && eventValue.entity.year.length > 0) {
          this.defaultYearPath = eventValue.entity.year;
        }
      }

      if (eventValue.inputName == 'fileName') {
        if (eventValue.entity.year && eventValue.entity.year.length > 0) {
          this.defaultYearPath = eventValue.entity.year;
        }

        if (eventValue.entity.prefab && eventValue.entity.prefab.length > 0) {
          let prefabValues = eventValue.entity.prefab.split('/');
          this.fileTypePath = prefabValues[1];
        }
      }
    }
    let response = await this.itemService.onPathComponentValueChange(
      this.tmpFields,
      eventValue.inputName,
      { value: eventValue.event },
      this.options,
      this.fileNamePath,
      this.fileTypePath,
      this.fileTypeCode,
      this.defaultYearPath,
      false,
      true
    );

    if (response) {
      this.tmpFields.prefab = response.fields.prefab;
      this.tmpFields.thumbnail = response.fields.thumbnail;
      this.tmpFields.fileName = response.fields.fileName;
      this.fileNamePath = response.fileNamePath;
      this.fileTypePath = response.fileTypePath;
      this.fileTypeCode = response.fileTypeCode;
      this.defaultYearPath = response.defaultYearPath;
    }

    if (this.currentTimeout2) {
      clearTimeout(this.currentTimeout2);
    }
    this.currentTimeout2 = setTimeout(async () => {
      let updateResponse = await this.sourcingService.updateItemSourcing(
        eventValue.entity.id,
        {
          prefab: this.tmpFields.prefab,
          thumbnail: this.tmpFields.thumbnail,
          fileName: this.tmpFields.fileName,
        }
      );

      if (response && updateResponse) {
        this.dynamicTableComponent.isSaved = true;
      }

      await this.dynamicTableComponent.getItemSourceRowData();
      this.currentTimeout2 = null;
    }, 500);
  }

  /**
   * Validate if Sourcing items are ok to be uploaded
   *
   * @param data List of sourcing items
   */
  async validateSourcingItems(data: any) {
    this.spinnerService.loadSpecificSpinner('sourcing-group-view-spinner');
    let response = await this.sourcingItemGroupService.validateSourcingItems(
      this.entity._id,
      { sourcingItems: data }
    );

    if (response && response.Success) {
      this.messageService.add({
        sticky: true,
        severity: 'success',
        summary: 'Validation Success',
        detail: 'Sourcing items are ready to be uploaded.',
      });
    } else {
      this.dynamicTableComponent.displayConfirmItemSourcingUploadModal = false;
      this.dynamicTableComponent.showActions = false;
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Validation Error',
        detail: 'One or more sourcing items are invalid.',
      });
      this.invalidItems = response.InvalidItems;
      this.displayModal = true;
    }
    this.spinnerService.endSpecificSpinner('sourcing-group-view-spinner');
  }

  /**
   * Validates if Sourcing Item is valid for Filename Generation Script
   *
   * @param data List of sourcing items
   */
  async validateFileName(data: any, isFileNameGeneration: boolean = false)
  {
    this.dynamicTableComponent.showActions = false;
    this.dynamicTableComponent.displayConfirmItemSourcingUploadModal = false;
    this.spinnerService.loadSpecificSpinner('sourcing-group-view-spinner');

    let response = await this.sourcingItemGroupService.validateSourcingItemsFileName
    (
      this.entity._id,
      {
        sourcingItems: data,
        isFileNameGeneration: isFileNameGeneration
      }
    );

    if (response)
    {
      if(response.isValid && response.errorValidations.length == 0 && !isFileNameGeneration)
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'success',
            summary: 'Validation Success',
            detail: 'Sourcing Items are valid.',
          }
        );
      }
      else if(response.isValid && response.errorValidations.length > 0 && !isFileNameGeneration)
      {
        this.severityType = 'warn';
        this.invalidItems = response.errorValidations;
        this.displayValidationModal = true;
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'warn',
            summary: 'Validation Success',
            detail: 'Sourcing Items are valid, but there are some duplicate fields.',
          }
        );
      } else if (response.isValid && response.fileNames.length > 0 && isFileNameGeneration){
        this.severityType = 'success';
        this.invalidItems = response.errorValidations;
        // this.displayValidationModal = true;
        let skippedMsg = response.fileNamesSkipped.length > 0 ? `Skipped ${response.fileNamesSkipped.length} records with existing file names.` : ``;
        await this.dynamicTableComponent.getItemSourceRowData();
        this.dynamicTableComponent.showItFilenameGenValidationModal = false;
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'success',
            summary: 'File Names generated',
            detail: `Generated file names for ${response.fileNames.length} records. ${skippedMsg}`,
          }
        );
      } else if (response.isValid && response.fileNames.length == 0 && response.fileNamesSkipped.length > 0 && isFileNameGeneration){
        this.severityType = 'warn';
        this.invalidItems = response.errorValidations;
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'warn',
            summary: 'File Names Skipped',
            detail: 'All Sourcing Items have file names, skipped all records.',
          }
        );
      } else
      {
        this.severityType = 'error';
        this.invalidItems = response.errorValidations;
        this.displayValidationModal = true;
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Validation Error',
            detail: 'One or more sourcing items are invalid.',
          }
        );
      }
    }
    else
    {
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'API Error',
          detail: 'There is an error in the API request.',
        }
      );
    }
    this.spinnerService.endSpecificSpinner('sourcing-group-view-spinner');
  }

  /**
   * Validates and Generates Paths for Sourcing Items
   *
   * @param data List of sourcing items
   */
  async createPathsForSourcingItems(data: any)
  {
    this.severityType = '';
    this.dynamicTableComponent.showActions = false;
    this.spinnerService.loadSpecificSpinner('sourcing-group-view-spinner');

    let response = await this.sourcingItemGroupService.createPathsForSourcingItems
    (
      this.entity._id,
      { sourcingItems: data }
    );

    if (response)
    {
      if(response.errorValidations && response.errorValidations.length > 0)
      {
        this.invalidItems = response.errorValidations;
      }
      else
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'success',
            summary: 'Success',
            detail: 'Paths were successfully generated for Sourcing Items.',
          }
        );
      }

      if(response.skippedItems && response.skippedItems.length > 0)
      {
        this.invalidItems.push(...response.skippedItems);
      }

      this.displayValidationModal = (response.skippedItems && response.skippedItems.length > 0) || (response.errorValidations && response.errorValidations.length > 0);

      await this.dynamicTableComponent.getItemSourceRowData();
    }
    else
    {
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'API Error',
          detail: 'There is an error in the API request.',
        }
      );
    }
    this.spinnerService.endSpecificSpinner('sourcing-group-view-spinner');
  }

  /**
   * Handles Custom action events comming from dynamic table component
   *
   * @param data Event data.
   */
  async onCustomAction(data: any)
  {

    console.log('onCustomAction: ', data);
    console.log('isSourcingItemsPromotion: ', data.isAssetPromotion);

    if(data && data.event && !data.isAssetPromotion)
    {
      switch (data.event.name)
      {
        case 'Validate Selected Sourcing Items':
          await this.validateSourcingItems(data.data);
          break;

        case 'Validate Sourcing Items for Filename':
          await this.validateFileName(data.data);
          break;

        case 'Validate and Generate Filename for sourcing items':
          await this.validateFileName(data.data, true);
          break;

        case 'Validate and Create Paths':
          await this.createPathsForSourcingItems(data.data);
          break;
        case 'Add Selected Sourcing Items':
          await this.collectionFlow(data.data)
          break;
        default:
          console.log('defaulting to nothing');
          break;
      }
    }
    else if(data.isAssetPromotion && !data.isAddToCollection)
    {
      await this.promoteItems(data);
      this.uploadedRows = data.data
    } else if(data.isAddToCollection) {
      this.displayAddCollection = true;
      this.uploadedRows = data.data
    }
  }


  async collectionFlow(data: any) {
    this.displayAddToCollectionModal = true
    this.uploadedRows = data
  }
  /**
   * Upload Sourcing Items to collection
   */
  async uploadToCollection()
  {
    let result = await this.sourcingItemGroupService.uploadToCollection({ collectionId: this.selectedCollection, sourcingItems: this.promotedItems });

    if(result)
    {
      if(result.Success)
      {
        this.displayUploadToCollectionModal = false;
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'success',
            summary: 'Success',
            detail: 'Uploaded Items were successfully uploaded to collection.',
          }
        );
      }
      else
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Error',
            detail: 'No Collection Selected.',
          }
        );
      }
    }
    else
    {
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'API Error',
          detail: 'There is an error in the API request.',
        }
      );
    }
  }
  /**
   * Upload Sourcing Items to collection
   */
  async addToCollection(data=null)
  {
    let result = await this.sourcingItemGroupService.uploadToCollection({ collectionId: this.selectedCollection, sourcingItems: data ? data : this.uploadedRows, existingItem: true });

    if(result)
    {
      if(result.Success)
      {
        this.displayAddCollection = false;
        this.displayAddToCollectionModal = false;
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'success',
            summary: 'Success',
            detail: 'Uploaded Items were successfully uploaded to collection.',
          }
        );
      }
      else
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Error',
            detail: 'No Collection Selected.',
          }
        );
      }
    }
    else
    {
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'API Error',
          detail: 'There is an error in the API request.',
        }
      );
    }
  }
  /**
   * Upload Sourcing Items to collection
   */
  async createAndAddToCollection()
  {
    this.createCollection.items_ref = this.promotedItems;
    let result = await this.sourcingItemGroupService.createCollection({data: this.createCollection, user: this.user})

    if(result)
    {
      if(result.success)
      {
        this.displayAddCollection = false;
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'success',
            summary: 'Success',
            detail: 'Succesfully created a collection and added the items.',
          }
        );
      }
      else
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Error',
            detail: 'No Collection Created.',
          }
        );
      }
    }
    else
    {
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'API Error',
          detail: 'There is an error in the API request.',
        }
      );
    }
    this.displayAddCollection = false;
    this.displayCreateCollection = false;
    this.displayUploadToCollectionModal = false;
  }
}
