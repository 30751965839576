import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { FormService } from '../services/form.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'form-creator',
  templateUrl: './form-creator.component.html',
  styleUrls: ['./form-creator.component.sass'],
  
})
export class FormCreatorComponent implements OnInit {
  form!: UntypedFormGroup;
  formURL: string;

  sourceFields: any[];
  targetFields: any[] = [];

  constructor(
    private dataService: DataService,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private messageService: MessageService
  ) {}

  async ngOnInit() {
    console.log('form creator init');

    this.formURL = `forms/create`;
    console.log(this.formURL);

    this.form = this.fb.group({
      name: [, Validators.required],
      key: [, Validators.required],
      fields: [, Validators.required],
    });

    this.sourceFields = await this.dataService.getAllOfTypeAsync(
      'forms/fields',
      {}
    );
    this.targetFields = [];
  }

  formatInput(key: string) {
    let input = this.form.controls[key].value;

    let value: string = '';
    if (key === 'key') {
      value = input
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: any, i: any) =>
          i === 0 ? word.toLowerCase() : word.toUpperCase()
        )
        .replace(/\s+/g, '');
    } else if (key === 'name') {
      let words = input.split(' ');
      words.forEach((word: string, i: number) => {
        words[i] = word[0].toUpperCase() + word.substr(1);
      });
      value = words.join(' ');
    }

    this.form.controls[key].setValue(value);
  }

  onListCleared() {
    this.updateTargetFields([]);
  }

  onListItemCleared(value: any) {
    let clearedIndex = this.targetFields.findIndex((f) => value === f._id);
    this.targetFields.splice(clearedIndex, 1);

    this.updateFieldsValue();
  }

  updateTargetFields(values: any) {
    let selectedFields: any[] = [];
    values.forEach((v: string) => {
      let data = this.sourceFields.find((f) => v === f._id);
      selectedFields.push(data);
    });
    this.targetFields = selectedFields;
    this.updateFieldsValue();
  }

  updateFieldsValue() {
    let selectedValues: any[] = [];
    this.targetFields.forEach((f) => {
      selectedValues.push(f._id);
    });
    this.form.controls['fields'].setValue(selectedValues);
  }

  onSubmit() {
    const { value } = this.form;

    console.log(this.formURL);
    console.log(JSON.stringify(value, null, 0));

    this.formService.submitForm(value, this.formURL, false).subscribe(
      (val) => {
        console.log('POST call successful value returned in body', val);
        this.messageService.add({
          sticky: true,
          severity: 'success',
          summary: 'Submit Successful',
          detail: `"${value.name}" was successfully created`,
        });
        window.scrollTo(0, 0);
        this.form.reset();
        this.targetFields = [];
      },
      (res) => {
        console.log('POST call in error', res);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Submit Error',
          detail: 'There was an error submitting.',
        });
        window.scrollTo(0, 0);
      },
      () => {
        console.log('The POST observable is now completed.');
      }
    );
  }
}
