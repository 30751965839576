import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { SourcingService } from '../../items-sourcing/services/sourcing.service';
import { TableDataComponent } from '../../table-data/components/table-data/table-data.component';
import FieldData from '../../../../common/components/base-fields/field-data-dto';
import { SourcingChallengeGroupService } from '../services/sourcing-group.service';
import { ItemService } from 'src/app/entities/item/services/item.service';
import { pathconfigValues } from 'src/app/entities/item/data/constants';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { DynamicTableComponent } from 'src/app/common/components/dynamic-table/dynamic-table.component';
import { SpinnerService } from 'src/app/common/services/spinner.service';

@Component({
  selector: 'app-sourcing-challenge-group-view',
  templateUrl: './sourcing-challenge-group-view.component.html',
  styleUrls: ['./sourcing-challenge-group-view.component.sass'],
})
export class SourcingChallengeGroupViewComponent
  extends TableDataComponent
  implements OnInit
{
  constructor(
    private entityViewService: EntityViewService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private logger: LoggerService,
    private titleService: Title,
    private sourcingService: SourcingService,
    private sourcingChallengeGroupService: SourcingChallengeGroupService,
    private messageService: MessageService,
    private itemService: ItemService,
    private utilitiesService: UtilitiesService,
    private spinnerService: SpinnerService
  ) {
    super();
  }

  @ViewChild(DynamicTableComponent)
  dynamicTableComponent: DynamicTableComponent;
  // variables for path generation
  fileTypePath: string = '';
  fileTypeCode: string = '';
  fileNamePath: string = '';
  defaultYearPath: string = pathconfigValues.year;
  options: Array<any> = [];
  tmpFields: any = { prefab: null, thumbnail: null, fileName: null };

  key: string = 'sourcing-challenge-groups';
  pageTitle: string = 'Sourcing Challenge Group';
  section1Title: string = 'Source Challenge Group Details';
  section2Title: string = 'Challenge Sourcing Table';

  id: number;
  entity: any;
  doc: any = {
    name: null,
    start: null,
    end: null,
    enabled: false,
  };

  newRowObject: any = {
    name: '',
    promoted: false,
    sourceGroup_ref: {_id: null},
  }

  currentTimeout: any;
  currentTimeout2: any;
  displayModal: boolean = false;
  invalidChallenges: Array<any> = [];

  /**
   * Sourcing Group Component Initialization
   */
  async ngOnInit() {
    this.isLoading = true;
    await this.setItemFileTypeOptions();
    this.title = 'Sourcing Challenges';
    this.type = 'challenges-sourcing';

    this.globalFilters = [
      'id',
      'name',
      'category_ref.name',
      'type_ref.name',
      'thumbnail',
      'prefab',
      'batch_ref.name',
      'fileName',
      'commonName',
      'plantFamily',
      'botanicalName',
      'latinName',
      'cultivar',
      'height',
      'spread',
      'internalNotes',
      'referenceLinks',
      'buildStatus.text',
      'progressionLevel_ref.level',
      'vendorStatus',
    ];
    this.filtersToRegister = [
      ...this.defaultFilters,
      'stringIn',
      'stringIn_ref',
      'multiIn',
      'multiIn_ref',
      'complex',
      'inCollection',
    ];

    this.tableConfig.isEditMode = false;

    const routeParams = this.route.snapshot.paramMap;

    this.id = Number(routeParams.get('id'));

    await this.getExistingDoc().then(async (result) => {
      this.entity = result;
      this.logger.log('checking existing doc', this.entity);
      if (this.entity.name) {
        this.doc.name = this.entity.name;
      }

      if (this.entity.start) {
        this.doc.start = this.entity.start;
      }

      if (this.entity.end) {
        this.doc.end = new Date(this.entity.end);
      }

      if (this.entity.enabled) {
        this.doc.enabled = this.entity.enabled;
      }
    });

    this.fields = [
      ...this.defaultFields,
      {
        key: 'type_ref',
        name: 'Challenge Type',
        controlType: 'dropdown_ref',
        apiController: 'challenge-types',
        isOptionsMin: true,
        filterKey: 'type_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'climate_ref',
        name: 'Climate',
        controlType: 'dropdown_ref',
        apiController: 'climates',
        isOptionsMin: true,
        filterKey: 'climate_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'scene',
        name: 'Scene',
        controlType: 'inputText',
        filterKey: 'scene',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'location',
        name: 'Location',
        controlType: 'inputText',
        filterKey: 'location',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'sceneType',
        name: 'Scene Type',
        controlType: 'dropdown_ref',
        apiController: 'scene-types',
        filterKey: 'sceneType',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'fileName',
        name: 'File Name',
        controlType: 'inputText',
        filterKey: 'fileName',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'year',
        name: 'Year',
        controlType: 'dropdown',
        options: [
          { label: '2021', value: '2021' },
          { label: '2022', value: '2022' },
          { label: '2023', value: '2023' },
          { label: '2024', value: '2024' },
          { label: '2025', value: '2025' },
        ],
        filterKey: 'year',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'challenge_ref',
        name: 'Challenge Ref',
        controlType: 'dropdown_ref',
        apiController: 'challenges-sourcing',
        isOptionsMin: true,
        filterKey: 'challenge_ref',
        matchMode: 'contains',
        isColumn: true,
        isInputField: false,
        isFilterField: true,
      },
    ];
    this.defaultColumnOrder = [
      // not editable
      'id',
      'fileName',
      'name',
      'sceneType',
      'scene',
      'location',
      'climate_ref',
      'type_ref',
      'archived',
      'year',
      'challenge_ref',
    ];
    this.customGlobalColumnSets = [
      {
        name: 'Default',
        value: ['id', 'name', 'start', 'end', 'enabled', 'archived'],
      },
    ];

    this.tableConfig.customFilterToRegister = this.filtersToRegister;
    await this.getExistingDoc();
    this.isLoading = false;
  }

  /**
   * Retrieves existing sourcing group reecord
   */
  async getExistingDoc() {
    const doc = await this.dataService.getDocumentAsync(this.key, {
      query: { id: this.id },
      autopopulate: true,
      virtuals: true,
    });
    return doc;
  }

  /**
   * Auto save changes on table fields
   *
   * @param event Event comming from dynamic table component
   */
  async onChange(event: any) {
    this.dynamicTableComponent.isSaved = false;
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }
    let field = event.field.key;
    this.currentTimeout = setTimeout(async () => {
      let response = null;
      if (event.isClearAction && field == 'itemFileType_ref') {
        response = await this.sourcingService.updateChallengeSourcing(
          event.entity.id,
          {
            [field]: event.value,
            prefab: '',
            thumbnail: '',
            fileName: '',
          }
        );
      } else {
        response = await this.sourcingService.updateChallengeSourcing(
          event.entity.id,
          {
            [field]: event.value,
          }
        );
      }

      if (response) {
        this.dynamicTableComponent.isSaved = true;
      }
      this.currentTimeout = null;
    }, 500);
    let entityIndex = this.dynamicTableComponent.rows.findIndex(
      (element) => element.id === event.entity.id
    );
    this.dynamicTableComponent.rows[entityIndex][field] = event.value;

    if (event.isClearAction && field == 'itemFileType_ref') {
      this.dynamicTableComponent.rows[entityIndex]['prefab'] = '';
      this.dynamicTableComponent.rows[entityIndex]['thumbnail'] = '';
      this.dynamicTableComponent.rows[entityIndex]['fileName'] = '';
    }
  }

  /**
   * Copies the ID, field and error message of a row in the validation report
   * @param row Row to be copied
   */
  copyMessage(row: any) {
    let stringBuild =
      'Item ID: ' +
      row.Entity.id +
      '   ' +
      'Field: ' +
      row.Field +
      '   ' +
      'Error Message: ' +
      row.ErrorMessage;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = stringBuild;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Row Copied',
      detail: `Row copied to clipboard successfully!`,
    });
  }
  /**
   * Promotes Sourcing Items
   *
   * @param event Event comming from dynamic table component.
   */
  async promoteChallenges(event: any) {
    if (event) {
      this.spinnerService.loadSpecificSpinner('sourcing-group-view-spinner');
      if (event.data) {
        await this.promoteSelectedSourcingChallenges(event.data, event.user);
      } else {
        await this.promoteAllSourcingChallenges(event.user);
      }
      this.spinnerService.endSpecificSpinner('sourcing-group-view-spinner');
    }
  }

  /**
   * Promotes selected items tied to a sourcing group
   *
   * @param items List of selcetd sourcing items
   * @param user Current user logged-in
   */
  async promoteSelectedSourcingChallenges(items: Array<any> = [], user: any) {
    let response = await this.sourcingChallengeGroupService.promoteSelectedSourcingChallenges(
      this.entity._id,
      { sourcingItems: items, user: user }
    );

    await this.handlePromoteResponse(response);
  }

  /**
   * Promotes all items tied to a sourcing group
   *
   * @param user Current user logged-in
   */
  async promoteAllSourcingChallenges(user: any) {
    let response = await this.sourcingChallengeGroupService.promoteChallengeSourcingGroup(
      this.entity._id,
      { user: user }
    );

    await this.handlePromoteResponse(response);
  }

  /**
   * Handle Promote Response comming from API
   *
   * @param response Response from API
   */
  async handlePromoteResponse(response: any) {
    if (response.Success) {
      await this.dynamicTableComponent.getItemSourceRowData();
      this.dynamicTableComponent.displayConfirmItemSourcingUploadModal = false;
      this.dynamicTableComponent.showActions = false;
      this.dynamicTableComponent.selectedRows = [];
      this.messageService.add({
        sticky: true,
        severity:
          response.ItemPromotedCount && response.ItemPromotedCount > 0
            ? 'success'
            : 'info',
        summary:
          response.ItemPromotedCount && response.ItemPromotedCount > 0
            ? 'Upload Successful'
            : 'Nothing to upload',
        detail: response.Message,
      });
    } else {
      this.dynamicTableComponent.displayConfirmItemSourcingUploadModal = false;
      this.dynamicTableComponent.showActions = false;
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Upload Error',
        detail: response.Message,
      });
      this.invalidChallenges = response.invalidChallenges;
      this.displayModal = true;
    }
  }

  /**
   * Set Item File Type Options
   */
  async setItemFileTypeOptions() {
    await this.utilitiesService.getOptionsFromRef(
      this.options,
      'itemFileType_ref',
      'item-file-types',
      false
    );
  }

  /**
   * This function builds a suggested path for prefab and thumbnail values based on the user's input.
   * @param
   * uses this variables to store the path's state:
   *  fileTypePath: string = '';
      fileTypeCode: string = '';
      fileNamePath: string = '';
      defaultYearPath: string = pathconfigValues.year;
   */
  async onPathComponentValueChange(eventValue: any) {
    this.dynamicTableComponent.isSaved = false;
    if (eventValue.entity) {
      if (eventValue.inputName == 'year') {
        if (eventValue.entity.prefab && eventValue.entity.prefab.length > 0) {
          let prefabValues = eventValue.entity.prefab.split('/');
          this.fileTypePath = prefabValues[1];
        }

        if (
          eventValue.entity.fileName &&
          eventValue.entity.fileName.length > 0
        ) {
          this.fileNamePath = eventValue.entity.fileName;
        }
      }

      if (eventValue.inputName == 'fileType') {
        if (eventValue.entity.year && eventValue.entity.year.length > 0) {
          this.defaultYearPath = eventValue.entity.year;
        }
      }

      if (eventValue.inputName == 'fileName') {
        if (eventValue.entity.year && eventValue.entity.year.length > 0) {
          this.defaultYearPath = eventValue.entity.year;
        }

        if (eventValue.entity.prefab && eventValue.entity.prefab.length > 0) {
          let prefabValues = eventValue.entity.prefab.split('/');
          this.fileTypePath = prefabValues[1];
        }
      }
    }
    let response = await this.itemService.onPathComponentValueChange(
      this.tmpFields,
      eventValue.inputName,
      { value: eventValue.event },
      this.options,
      this.fileNamePath,
      this.fileTypePath,
      this.fileTypeCode,
      this.defaultYearPath,
      false,
      true
    );

    if (response) {
      this.tmpFields.prefab = response.fields.prefab;
      this.tmpFields.thumbnail = response.fields.thumbnail;
      this.tmpFields.fileName = response.fields.fileName;
      this.fileNamePath = response.fileNamePath;
      this.fileTypePath = response.fileTypePath;
      this.fileTypeCode = response.fileTypeCode;
      this.defaultYearPath = response.defaultYearPath;
    }

    if (this.currentTimeout2) {
      clearTimeout(this.currentTimeout2);
    }
    this.currentTimeout2 = setTimeout(async () => {
      let updateResponse = await this.sourcingService.updateItemSourcing(
        eventValue.entity.id,
        {
          prefab: this.tmpFields.prefab,
          thumbnail: this.tmpFields.thumbnail,
          fileName: this.tmpFields.fileName,
        }
      );

      if (response && updateResponse) {
        this.dynamicTableComponent.isSaved = true;
      }

      await this.dynamicTableComponent.getItemSourceRowData();
      this.currentTimeout2 = null;
    }, 500);
  }

  /**
   * Validate if Sourcing items are ok to be uploaded
   *
   * @param data List of sourcing items
   */
  async validateSourcingChallenges(data: any) {
    this.spinnerService.loadSpecificSpinner('sourcing-group-view-spinner');
    let response = await this.sourcingChallengeGroupService.validateSourcingChallenges(
      this.entity._id,
      { sourcingItems: data }
    );

    if (response && response.Success) {
      this.messageService.add({
        sticky: true,
        severity: 'success',
        summary: 'Validation Success',
        detail: 'Sourcing challenges are ready to be uploaded.',
      });
    } else {
      this.dynamicTableComponent.displayConfirmItemSourcingUploadModal = false;
      this.dynamicTableComponent.showActions = false;
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Validation Error',
        detail: 'One or more sourcing challenges are invalid.',
      });
      this.invalidChallenges = response.invalidChallenges;
      this.displayModal = true;
    }
    this.spinnerService.endSpecificSpinner('sourcing-group-view-spinner');
  }

  /**
   * Handles Custom action events comming from dynamic table component
   *
   * @param data Event data.
   */
  async onCustomAction(data: any) {
    if (
      data &&
      data.event &&
      data.event.name == 'Validate Selected Sourcing Items'
    ) {
      await this.validateSourcingChallenges(data.data);
    } else {
      await this.promoteChallenges(data);
    }
  }
}
