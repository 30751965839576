import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import CoOpDTO from 'src/app/cms-v2/entities/co-op/dtos/CoOpDTO';
import DynamicFormFieldDTO from '../dynamic-form-v2/dtos/DynamicFormFieldDTO';
import { OptionsParams, UtilitiesService } from '../../services/utilities.service';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from '../dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-goal-image-component',
  templateUrl: './goal-image-component.component.html',
  styleUrls: ['./goal-image-component.component.sass']
})
export class GoalImageComponentComponent implements OnInit {
  
  @Input() coOpRecord: CoOpDTO = new CoOpDTO();
  @Input() field: DynamicFormFieldDTO = new DynamicFormFieldDTO();
  @Output() onChange = new EventEmitter<any>();
  options: any = [];
  
  constructor(
    private utilitiesService: UtilitiesService,
  ) { }
  

  async ngOnInit() {
     if (!this.coOpRecord['goal_images_ref']) {
      this.coOpRecord['goal_images_ref'] = [];
     } else {
      this.coOpRecord['goal_images_ref'] = this.coOpRecord['goal_images_ref'].map((subArray: any[]) => 
        subArray.map(item => item._id)
      );
     }

    await this.utilitiesService.getOptionsFromRef(this.options, 'goal_images_ref', 'miscellaneous-build', false, false, 'name id _id path', false, { name: 1 }, { entityType: BuildType.Coop });
  }
  
  addGoalImage() {

      this.coOpRecord.goal_images_ref.push([]);
      console.log('Added goal image', this.coOpRecord['goal_images_ref']);
}

  
  removeGoalImage(index: number): void {
    // Remove the color at the specified index
    this.coOpRecord['goal_images_ref']?.splice(index, 1);
    this.onRecordChange(this.field);
  }


  reOrderLists(event: any): void {
    if (!this.coOpRecord.goal_images) {
      return;
    }

    const movedBundle = event[0];
    if (movedBundle && typeof movedBundle.index === 'number') {
      const originalIndex = movedBundle.index;
    }

    // Update index values in bundles for future moves
    this.coOpRecord.goal_images_ref.forEach((color: { index: any; }, index: any) => {
      color.index = index;
    });
  }

  onRecordChange(event: any)
  {
    this.onChange.emit(event);
  }
  
  transform(i: any) {
    const obj = this.options['goal_images_ref'].find((item: any) => item._id === i);
    return obj ? obj.name + ' (' + obj.id + ')' : null;
  }
}
