import { Component, OnInit } from "@angular/core";
import { PermissionsService } from "src/app/permissions/service/permissions.service";
import { RoleService } from "../service/role.service";
import { MessageService } from "primeng/api";
import RoleDto from "../dto/RoleDto";

@Component({
  selector: 'app-permission-view-dialog',
  templateUrl: './permission-view-dialog.component.html',
  styleUrls: ['./permission-view-dialog.component.sass'],
})
export class PermissionViewDialogComponent implements OnInit {
  constructor(
    private permissionsService: PermissionsService,
    private roleService: RoleService,
    private messageService: MessageService,
  ) {}

  rolPrefix = 'ROLE_';
  isLoading: boolean = true;
  showPermissionDetails: boolean = false;
  entities = {};
  abilities = {};
  role: RoleDto | null;

  async ngOnInit(): Promise<void> {
    await this.loader();

    this.roleService.role$.subscribe((role) => {
      if (role) {
        this.role = role;
        this.showPermissionDetails = true;
        this.messageService.clear();
      }
    });
  }

  async loader() {
    const permissionTypes = await this.permissionsService.getPermissionTypes();
    this.entities = permissionTypes.entities;
    this.abilities = permissionTypes.abilities;
  }
}