<!-- Main Status Indicators -->
<span class="p-grid">
    <ng-container *ngFor="let field of mainStatusGroup">
      <div class="p-col-4">
        <h4 class="p-col p-m-2 p-mx-lg-2 p-my-lg-0">{{ field.name }}:</h4>
        <div
          *ngIf="field.key != 'itemStatus'"
          class="p-col p-m-2 p-mx-lg-2 p-my-lg-0 p-text-center"
          [ngClass]="itemService.statusBgColors(field, entity)"
        >
          <h3 class="p-m-0 p-text-center">
            <ng-container
              [ngSwitch]="itemService.parseValueForView(entity[field.key], field, entity)"
            >
              <ng-container *ngSwitchCase="''"> Not Found </ng-container>
              <ng-container *ngSwitchDefault>
                {{ itemService.parseValueForView(entity[field.key], field, entity) }}
              </ng-container>
            </ng-container>
          </h3>
        </div>
  
        <div
          *ngIf="field.key == 'itemStatus'"
          class="p-col p-m-2 p-mx-lg-2 p-my-lg-0 p-text-center"
          [ngClass]="itemService.statusBgColors(field, entity)"
        >
          <h3 class="p-m-0 p-text-center">
            <ng-container
              [ngSwitch]="itemService.parseValueForView(entity[field.key], field, entity)"
            >
              <ng-container *ngSwitchCase="''"> Not Found </ng-container>
              <ng-container *ngSwitchDefault>
                {{ itemService.parseValueForView(entity[field.key], field, entity) }}
              </ng-container>
            </ng-container>
          </h3>
        </div>
      </div>
    </ng-container>
  </span>  