import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-nurture-view-v3',
  templateUrl: './nurture-view-v3.component.html',
  styleUrls: ['./nurture-view-v3.component.sass']
})
export class NurtureViewV3Component  extends BaseDynamicViewComponent implements OnInit
{
  nurtureRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  miscAssetKeys: Array<any> = ['coinAsset_ref', 'coinThumbnail_ref']

  /**
   * Nurture View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Nurture Item Data",
        fields:
        [
          { name: "Display Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'displayName', isRequired: true, label: 'Display Name', columnWidth: 6 },
          {
            name: "Collection",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Link],
            clearField: true,
            key: 'collectionId',
            isRequired: true,
            label: 'Collection',
            controller: 'nurture-collection',
            columnWidth: 6,
          },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time', columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time', columnWidth: 6 },
          { name: "Item", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.NameLink], clearField: true, key: 'itemId', label: 'Item', controller: 'items', columnWidth: 6 },
          {
            name: "",
            inputType: DynamicViewType.SpaceCol,
            key: '',
            label: '',
            columnWidth: 6
          },
          {
            name: "Nurture Rarity",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'rarity_ref',
            label: 'Nurture Rarity',
            controller: 'nurture-rarity',
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Sprout Share",
        fields:
        [
          {
            name: "Icon Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'sproutImage_ref',
            label: 'Icon Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'sproutImage_ref', apiController: 'miscellaneous-build',
              buildAndRender: false,
            },
            columnWidth: 12
          },
          {
            name: "",
            inputType: DynamicViewType.SpaceCol,
            key: '',
            label: '',
            columnWidth: 12
          },
          {
            name: "Share Popup",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'nurtureVideo_ref',
            label: 'Share Popup',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'nurtureVideo_ref', apiController: 'miscellaneous-build',
              buildAndRender: false,
            },
            columnWidth: 6
          },
          {
            name: "Share Loop",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'nurtureLoopVideo_ref',
            label: 'Share Loop',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'nurtureLoopVideo_ref', apiController: 'miscellaneous-build',
              buildAndRender: false,
            },
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Assets",
        fields:
        [
          {
            name: "Pin Asset",
            inputType: DynamicViewType.MiscImageBuildComponent,
            clearField: true,
            key: 'coinAsset_ref',
            label: 'Pin Asset',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              buildAndRender: true,
              fieldName: 'coinAsset_ref', apiController: 'miscellaneous-build',
              collapsed: true,
              type: 'ref',
            },
            columnWidth: 12
          },
          {
            name: "Pin Thumbnail",
            inputType: DynamicViewType.MiscImageBuildComponent,
            clearField: true,
            key: 'coinThumbnail_ref',
            label: 'Pin Thumbnail',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              buildAndRender: true,
              fieldName: 'coinThumbnail_ref', apiController: 'miscellaneous-build',
              collapsed: true,
              type: 'ref'
            },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Seed Pack Imagery",
        fields:
        [
          {
            name: "Seed Pack Open Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            clearField: true,
            key: 'seed_pack_open_ref',
            label: 'Seed Pack Open Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: { fieldName: 'seed_pack_open_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
          },
          {
            name: "Seed Pack Closed Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            clearField: true,
            key: 'seed_pack_closed_ref',
            label: 'Seed Pack Closed Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: { fieldName: 'seed_pack_closed_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Stages",
        fields:
        [
          {
            name: "",
            inputType: DynamicViewType.NurtureStagesField,
            clearField: true,
            key: 'stages_ref',
            label: '',
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
    ]

    this.sidebarFields = [
      {
        title: "Nurture Collection Data",
        fields:
        [
          { name: "Nurture Pin Preview",
            inputType: DynamicViewType.RenderThumbnails,
            key: 'itemId',
            label: 'Nurture Pin Preview',
            columnWidth: 12,
            options: {
              fieldName: '',
              showEnv: false,
              showEnabled: false,
              showBuildStatus: true,
              thumbnailStyle: "multiple",
            }
          },
          { name: "Item Asset Preview",
            inputType: DynamicViewType.RenderThumbnails,
            key: 'itemId',
            label: 'Item Asset Preview',
            columnWidth: 12,
            options: {
              fieldName: '',
              showEnv: true,
              showEnabled: true,
              showBuildStatus: true,
              thumbnailStyle: "multiple",
            }
          },
          { name: "Nurture Pin Thumbnail Preview",
              inputType: DynamicViewType.sideThumbnail,
              key: 'coinThumbnail_ref',
              label: 'Nurture Pin Thumbnail Preview',
              columnWidth: 12
            },
        ],
        type: DynamicCardType.Card
      },

    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.nurtureRecord =
    {
      enabled: false
    };
    this.title = "Nurture";
    this.viewRoute = 'nurture';
    this.isLoading = false;
  }
}
