import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImagerySettingsService 
{
  private readonly apiURLprefix : string = '/api/ImagerySettings';
  constructor(private http: HttpClient) {}

  /**
   * Retrieves all Imagery Settings records.
   */
  async getAllImagerySettings()
  {
    let response = await firstValueFrom(this.http.get<any>(`${this.apiURLprefix}`));

    return response;
  }

  /**
   * Retrieves all Imagery Settings records given a query.
   * 
   * @param query Mongo Query.
   */
  async getAllImagerySettingsWithQuery(query: any)
  {
    let response = await firstValueFrom(this.http.post<any>(`${this.apiURLprefix}`, query));

    return response;
  }
}