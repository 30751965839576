import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-reward-card',
  templateUrl: './reward-card.component.html',
  styleUrls: ['./reward-card.component.css']
})
export class RewardCardComponent {
  @Input() title: string;
  @Input() rewards: any[];

  getRewardImage(reward: any): string {
    let imagePath = '';

    if (!reward || !reward.id) {
      return 'default_image_path_here';
    } else {
      switch (reward.lineItemType) {
        case 'Item':
          imagePath = reward.id?.thumbnail_ref?.thumbnails?.[0]?.path;
          break;
        case 'Currency':
          imagePath = reward.id?.thumbnail_ref?.thumbnails?.[0]?.path ||
                      reward.id?.thumbnails?.[2]?.path;
          break;
        case 'Titles':
          imagePath = 'https://d3tfb94dc03jqa.cloudfront.net/images/titles/title_default/title_default_128.png';
          break;
        case 'Nurture':
          imagePath = reward.id?.coinThumbnail_ref?.thumbnails?.[0]?.path ||
                      reward.id?.sproutImage_ref?.thumbnails?.[0]?.path;
          break;
        case 'SeedPackBox':
          imagePath = reward.id?.box_image_empty_ref?.thumbnails?.[0]?.path;
          break;
        default:
          break;
      }
    }

    return imagePath || 'default_image_path_here';
  }

  getLink(reward: any): string {
    if (!reward || !reward.id || reward.id.id === null || reward.id.id === undefined) {
      return '#';
    }
    let baseUrl = '';
    switch (reward.lineItemType) {
      case 'Item':
        baseUrl = '/items';
        break;
      case 'Currency':
        baseUrl = '/currencies';
        break;
      case 'Titles':
        baseUrl = '/titles';
        break;
      case 'SeedPackBox':
        baseUrl = '/seed-pack-box';
        break;
      case 'StoreListingV2':
        baseUrl = '/store-listings-v2';
        break;
      case 'Nurture':
        baseUrl = '/nurture';
        break;
      default:
        return '#';
    }
    return `${baseUrl}/${reward.id.id}`;
  }
}
