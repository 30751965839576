export const constants = {
  protectedPaths: [
    "items",
    "images",
    "images/currency",
    "images/challenge_feed",
    "images/item_thumbs",
    "resources"
  ]
};
