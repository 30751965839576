import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-keywords-form-v2',
  templateUrl: './keywords-form-v2.component.html',
  styleUrls: ['./keywords-form-v2.component.sass']
})
export class KeywordsFormV2Component extends BaseDynamicFormComponent implements OnInit {

  keywordRecord: BaseEntityDto = new BaseEntityDto();

  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }

  ngOnInit(): void {
    this.fields = [
      {
        title: "Keyword Data",
        fields: [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            columnWidth: 4
          },
          {
            name: "Description",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'description',
            label: 'Description',
            columnWidth: 8
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Infinite Garden Exclusion",
        fields: [
          {
            name: "isInfiniteGardeningActiveLabel",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: 'isInfiniteGardeningActiveLabel',
            isRequired: false,
            clearField: true,
            label: 'Is Infinite Garden Active?:',
            columnWidth: 12,
          },
          {
            name: "Infinite Garden Active",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.ToggleButton],
            clearField: true,
            key: 'isInfiniteGardeningActive',
            emitOnInit: true,
            isRequired: false,
            label: 'Infinite Garden Active',
            onLabel: 'Active',
            offLabel: 'Disabled',
            showField: true,
            disabled: false,
            columnWidth: 2,
            displayControlType: "showHide",
            displayControls: {
              ['isInfiniteGardeningExluded']: (value: any) => value == true ? true : false,
              ['isInfiniteGardeningExludedLabel']: (value: any) => value == true ? true : false,
            },
          },
          {
            name: "isInfiniteGardeningExludedLabel",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Text],
            key: 'isInfiniteGardeningExludedLabel',
            isRequired: false,
            clearField: true,
            label: 'Infinite Garden Keyword Type:',
            columnWidth: 3,
          },
          {
            name: "Infinite Garden Exclusion",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.ToggleButton],
            clearField: true,
            key: 'isInfiniteGardeningExluded',
            isRequired: false,
            label: 'Infinite Garden Exclusion',
            onLabel: 'Include',
            offLabel: 'Exclude',
            showField: true,
            disabled: false,
            columnWidth: 3,

          }
        ],
        type: DynamicCardType.Card
      },
    ];

    this.title = "Keyword Form";
    this.viewRoute = 'challenge-keywords';
    this.isLoading = false;
  }

  validateKeywordForm(keyword: any) {
    const { name, createdAt } = keyword;
    let message = '';

    if (!name) {
      message = 'Name is required.';
      return { confirmValidationCallback: false, message };
    }

    if (createdAt && new Date(createdAt) > new Date()) {
      message = 'Created At cannot be in the future.';
      return { confirmValidationCallback: false, message };
    }

    return { confirmValidationCallback: true, message: '' };
  }
}
