import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { ColorTextComponent } from 'src/app/common/ag-grid-table/cell-editors/color-text/color-text.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';

@Component({
  selector: 'app-category-table-ag-grid',
  templateUrl: './category-table-ag-grid.component.html',
  styleUrls: ['./category-table-ag-grid.component.sass']
})
export class CategoryTableAgGridComponent implements OnInit {

  entity: string = 'categories';
  columnDefs: ColDef[] = [];
  bulkUpdateColDefs: any[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {}
  autopopulateSelect: string = '';
  options: any = {};
  public tableParams: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {}

  async ngOnInit(): Promise<void> {

    await this.setOptions();
    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Type(s)',
        field: 'types_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-types',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        autoHeight: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.types_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: "id name"
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: MultiselectEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Icon Size',
        field: 'iconSize',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Bubble Size',
        field: 'bubbleSize',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Border Width',
        field: 'borderWidth',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Color',
        field: 'color',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorPicker: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: ColorTextComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Description Short',
        field: 'descriptionShort',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Organic',
        field: 'organic',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Organic',
          offLabel: 'Not Organic',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Required',
        field: 'required',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Required',
          offLabel: 'Not Required',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Average Coin Cost',
        field: 'averageCoinCost',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Average Diamond Cost',
        field: 'averageDiamondCost',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Expected Coin Spend',
        field: 'expectedCoinSpend',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agNumberCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Expected Diamond Spend',
        field: 'expectedDiamondSpend',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agNumberCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Icon Path',
        field: 'iconPath',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Bundle Icons (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Bundle Icons (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Spawn Audios',
        field: 'spawnAudios_ref',
        cellRendererParams: {
          entity: 'miscellaneous-build',
          isArray: true,
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.spawnAudios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audios',
        field: 'loopAudios_ref',
        cellRendererParams: {
          isArray: true
        },
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loopAudios_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name path'
        },
        floatingFilter: true,
        width: 500,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Spawn Audio Collections',
        field: 'spawnAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.spawnAudioCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Loop Audio Collections',
        field: 'loopAudioCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'audio-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.loopAudioCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: '_id id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRendererParams: {
          entity: this.entity,
          isEnv: true
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['dev', 'qa', 'prod'],
            addEmptyFilters: true
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          valueObjects: true,
          isFlatList: true
        },
      },
    ];
    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'categories',
    }

  }

  async setOptions(){
    const entities = [
      { ref: 'types_ref', entity: 'item-types'},
      { ref: 'spawnAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [22] }} },
      { ref: 'loopAudios_ref', entity: 'miscellaneous-build', select: 'name id _id path', query: { assetType: { $in: [23] }} },
      { ref: 'spawnAudioCollections_ref', entity: 'audio-collections', query: { type: 1 }},
      { ref: 'loopAudioCollections_ref', entity: 'audio-collections', query: { type: 2 }},
    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }
  }

}
