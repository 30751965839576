import { Component, Input, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { MessageService } from 'primeng/api'; // Adjust the import path as necessary

@Component({
  selector: 'app-render-thumbnails',
  templateUrl: './render-thumbnails.component.html',
  styleUrls: ['./render-thumbnails.component.sass']
})
export class RenderThumbnailsComponent implements OnInit {
  @Input() field: any;
  @Input() record: any;
  timestamp = new Date().getTime();
  imageName: any;
  showImgDialog: boolean = false;
  imgData: any;
  
  constructor(private clipboard: Clipboard, private messageService: MessageService) { }

  ngOnInit(): void {
    // Initialization logic if needed
  }

  displayImage(url: string): void {
    this.imgData = {
      title: this.record.name,
      name: url.replace('_128.', '_1024.'),
      path: url.replace('_128.', '_1024.'),
    };
    this.showImgDialog = true;
  }

  copyToClipboard(copyVal: string): void {
    this.clipboard.copy(copyVal);
    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Thumbnail Copied',
      detail: `URL copied to clipboard successfully!`,
    });
  } 
}
