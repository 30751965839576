<div *ngIf="isVisible" class="grid-container">
  <div *ngFor="let record of rowData" class="grid-item">
    <p-card *ngIf="record">
      
      <ng-template pTemplate="content">
        <app-card [cardConfig]="variables" [entity]="entity" [record]="record"></app-card>
      </ng-template>
      <!-- <ng-template pTemplate="content">
        <div class="card-content">
          <div class="p-grid">
            <div class="p-col-8">
              <h5 class="p-m-1">
                {{ record.name }}
                 <a [href]="entity+'/'+record.id" target="_blank" class="" [ngStyle]="{ color: 'var(--primary-color)' }">({{ record.id }})</a>
              </h5>
            </div>
            <div class="p-col-4">
              <small>
                {{ record.enabled ? 'Enabled' : 'Disabled' }}
              </small>
            </div>
          </div>

          <p style="font-size: 13px;"><strong>Start:</strong> {{ record.start | officeTime: 'medium' }}</p>
          <p style="font-size: 13px;"><strong>End:</strong> {{ record.end | officeTime: 'medium' }}</p>
        </div>
      </ng-template> -->
    </p-card>
  </div>

</div>
