import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-climate-view',
  templateUrl: './climate-view.component.html',
  styleUrls: ['./climate-view.component.sass'],
})
export class ClimateViewComponent extends BaseDynamicViewComponent implements OnInit {
  climateRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }
  miscAssetKeys: Array<any> = ['spawnAudio_ref', 'loopAudio_ref']

  /**
   * Nurture View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Climate Data",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time',columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time',columnWidth: 6 }
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Audio",
        fields:
        [
          { name: "Spawn Audios",inputType: DynamicViewType.MiscImageRecordV2,viewMode: 'array', clearField: true, key: 'spawnAudios_ref', label: 'Spawn Audios', columnWidth: 6 },
          {
            name: "Spawn Audio Collections",
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'spawnAudioCollections_ref',
            label: 'Spawn Audio Collections',
            controller: 'audio-collections',
            columnWidth: 6
          },
          {
            name: "",
            inputType: DynamicViewType.SpaceCol,
            key: '',
            label: '',
            columnWidth: 12
          },
          { name: "Loop Audios",inputType: DynamicViewType.MiscImageRecordV2, viewMode: 'array', clearField: true, key: 'loopAudios_ref', label: 'Loop Audios', columnWidth: 6 },
          {
            name: "Loop Audio Collections",
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'loopAudioCollections_ref',
            label: 'Loop Audio Collections',
            controller: 'audio-collections',
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
    ]

    this.sidebarFields = [
      {
        title: "",
        fields:
        [
        ],
        type: DynamicCardType.Card
      },
    ]

    this.climateRecord =
    {
      enabled: false
    };
    this.title = "Climate";
    this.viewRoute = 'climates';
    this.isLoading = false;
  }
}
