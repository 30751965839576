import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-external-plant-data-table-ag-grid',
  templateUrl: './external-plant-data-table-ag-grid.component.html',
  styleUrls: ['./external-plant-data-table-ag-grid.component.sass']
})
export class ExternalPlantDataTableAgGridComponent implements OnInit {

  public entity: string = 'external-plant-data';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  constructor(
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) { }

  async ngOnInit(): Promise<void> {

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'File Reference',
        field: 'fileRef',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Description',
        field: 'description',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        width: 500,
        cellEditor: 'agLargeTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Plant Common Name',
        field: 'commonName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Botanical Name',
        field: 'botanicalName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Plant Type',
        field: 'plantType',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Mature Size',
        field: 'matureSize',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Growing Time',
        field: 'growingTime',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Bloom Time',
        field: 'bloomTime',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Flower Color',
        field: 'flowerColor',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Soil Type',
        field: 'soilType',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Soil PH',
        field: 'soilPH',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Hardiness Zone',
        field: 'hardinessZone',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Native Area',
        field: 'nativeArea',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Sun Exposure',
        field: 'sunExposure',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Toxicity',
        field: 'toxicity',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Url',
        field: 'url',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Image Record',
        field: 'image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filterParams: {
          filterKey: 'image_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          populate: true,
          select: 'id name path _id'
        },
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        width: 500,
        autoHeight: true,

      },
    ]

    this.buildParams = {
      imageKeys: ['image_ref'],
      imageBuildType: BuildType.Images,
    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'externalplantdatas',
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];
  }

}
