import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.sass']
})
export class DynamicListComponent implements OnInit {
  @Input() record: any;
  @Input() keysToRender: Array<string> = [];

  constructor() { }

  ngOnInit(): void {
    // console.log('dynamic-list record:', this.record)
  }

  get keys() {
    if (this.record) {
      // If keysToRender has elements, filter the keys; otherwise, return all keys
      return this.keysToRender?.length > 0
        ? Object.keys(this.record).filter(key => this.keysToRender.includes(key))
        : Object.keys(this.record);
    } else {
      return [];
    }
  }

  isObject(value: any): boolean {
    return typeof value === 'object' && !Array.isArray(value) && value !== null;
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  isLink(value: any): boolean {
    if (typeof value !== 'string') {
      return false;
    }

    const regex = /^https?:\/\/[^ "]+$/;
    return regex.test(value);
  }

  camelCaseToWords(key: string): string {
    return key
      .replace(/([A-Z])/g, ' $1') // Insert a space before each capital letter
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  }

}
