import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';

@Component({
  selector: 'app-restriction-table-ag-grid',
  templateUrl: './restriction-table-ag-grid.component.html',
  styleUrls: ['./restriction-table-ag-grid.component.sass']
})
export class RestrictionTableAgGridComponent implements OnInit {

  public entity: string = 'restrictions';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Count',
        field: 'count',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Rules',
        field: 'rules_ref',
        valueGetter: (params: any) => {
          let names = params.data.rules_ref.map((name: any) => name?.filter_ref?.name)
          return names
        },
        sortable: true,
        unSortIcon: true,
        filterParams: {
          populate: true,
          nestedPop: true,
          select: {
            path: 'filter_ref', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        resizable: true,
        autoHeight: true,
      }
    ]

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images,

    }

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'restrictions',
    }


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

  }

  async setOptions(){
    const entities = [
      { ref: 'rules_ref', entity: 'rules' },

    ];

    for (const { ref, entity } of entities) {
      const params = new OptionsParams({
        entity
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }
  }

}
