import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';

/**
 * Season Pass DTO
 */
export default class CoOpDTO extends BaseEntityDto {
  /**
   * internal name of the record
   */
  name?: string;

  /**
   * EO. Rewards Track reference
   */
  track_ref?: any;

  /**
   * EO. Rewards Track reference
   */
  logo_id?: any;

  /**
   * Misc. Record reference
   */
  goal_images_ref?: any;

  /**
   * Banner Image Reference
   */
  banner_image_id?: any;

  /**
   * Team Colors
   */
  team_colors?: any[];

  /**
   * Currency Name
   */
  currency_name?: string;
  /**
   * Currency Name Plural
   */
  currency_name_plural?: string;
  /**
   * Goal Name
   */
  goal_name?: string;
  /**
   * Goal Name Plural
   */
  goal_name_plural?: string;
  /**
   * Invite Text
   */
  invite_text?: string;
  /**
   * Welcome Text
   */
  welcome_text?: string;
  /**
   * Currency_id Misc. Record reference
   */
  currency_id?: any;
  /**
   * Grand Prize will be saved as rewards_ref
   */
  rewards_ref?: any;
  /**
   * White Image Misc. Record reference
   */
  white_image_id?: any;

  [key: string]: any; // Index signature
}
