import { DataService } from './../../../services/data.service';
import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from '../../../auth/auth.service';
import { Output, EventEmitter } from '@angular/core';
import { BuildType } from 'src/app/enums/build-type';
import { UserContextService } from '../../services/user-context.service';
import { LoggerService } from '../../services/logger.service';
import { FormService } from 'src/app/services/form.service';
import { TableService } from 'src/app/services/table.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'managed-list',
  templateUrl: './managed-list.component.html',
  styleUrls: ['./managed-list.component.sass'],
  providers: [ConfirmationService, MessageService],
})
export class ManagedListComponent implements OnInit {
  @Input() legend: string;
  @Input() type: string;
  @Input() collapsed: boolean = true;
  @Input() fields: any[] = [];
  @Input() fbFields: any = {};
  @Input() submitURL: string;

  label: string;
  rows: any[];
  newRecordDialog: boolean;
  editRecordDialog: boolean;
  user: any;
  submitted: boolean = false;
  showDialog: boolean = false;
  isEditMode: boolean = false;

  toasterLife: number = 3000;

  newRecordForm: UntypedFormGroup;

  constructor(
    private tableService: TableService,
    private dataService: DataService,
    private messageService: MessageService,
    private authService: AuthService,
    private confirmationService: ConfirmationService,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private fb: UntypedFormBuilder,
    private formService: FormService
  ) {}

  /**
   * Build Asset Component Initialization
   */
  async ngOnInit(): Promise<void> {
    this.label = `Add new ${this.legend}`;
    this.initForm();
    await this.tableService.getRows(this.type).then(async (data: any) => {
      this.rows = data;
    });
  }

  /**
   * confirms with confirmationService if user is sure about deleting a record.
   * If user confirms, it continues with deletion, and updates rows afterwards
   * receives the record to be deleted
   * @param record: any
   */
  confirmDeleteRecordModal(record: any) {
    this.confirmationService.confirm({
      message: `Are you sure that you want to delete ${this.type} with ID: ${record.id}?`,
      accept: async () => {
        //Actual logic to perform a confirmation
        await this.dataService.deleteRecord(record.id, this.type);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.rows = data;
        });
      },
    });
  }

  openNew() {
    this.user = {};
    this.submitted = false;
    this.showDialog = true;
  }

  async initForm() {
    let newFormGroup: any = {};
    // building form group fields dinamycally
    for (const [key, value] of Object.entries(this.fbFields)) {
      newFormGroup[key] = [value];
    }
    this.newRecordForm = this.fb.group(newFormGroup);
  }

  submitNewRecord() {
    this.submitted = true;
    this.newRecordDialog = false;
    let submitUrl = `${this.type}/add`;
    let type = this.type;
    var { value } = this.newRecordForm;

    this.loggerService.log('submitUrl', submitUrl);
    this.loggerService.log('value', value);
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };

    this.formService.submitForm(value, submitUrl, false).subscribe(
      async (val) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Submit Successful',
          detail: `"${type}" was successfully created`,
          life: this.toasterLife
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.rows = data;
        });
        this.initForm();
        this.showDialog = false;
      },
      async (response) => {
        this.loggerService.log('POST call in error', response);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Submit Error',
          detail: `There was an error submitting ${type}.`,
        });
        window.scrollTo(0, 0);
        await this.tableService.getRows(this.type).then(async (data: any) => {
          this.rows = data;
        });
      },
      () => {
        this.loggerService.log('The POST observable is now completed.');
      }
    );
  }

  /**
   * Generates form group fields dinamically out of record values.
   * this method generates the form needed to render and attach formGroup fields within edit modal
   * receives record to be editted from event
   * @param record
   */
  editNewRecordModal(record: any) {
    this.isEditMode = true;
    this.editRecordDialog = true;
    let newFormGroup: any = {};
    // building form group fields dinamycally
    for (const [key, value] of Object.entries(record)) {
      newFormGroup[key] = [value];
    }
    this.newRecordForm = this.fb.group(newFormGroup);
  }

  /**
   * this method updates a record using the form's data.
   */
  async updateRecord() {
    let id = this.newRecordForm.get('id')!.value;
    let type = this.type;

    let upatePayload: any = {};
    for (const [key, value] of Object.entries(this.fbFields)) {
      upatePayload[key] = this.newRecordForm.get(key)!.value;
    }
    await this.dataService.updateRecord(id, type, upatePayload);
    await this.tableService.getRows(type).then(async (data: any) => {
      this.rows = data;
    });
    this.editRecordDialog = false;
  }
}
