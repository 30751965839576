<dynamic-view-v2
    *ngIf="!isLoading"
    entityType="climates"
    [viewName]="title"
    [record]="colorRecord"
    [fields]="fields"
    [allowDuplicate]="true"
    [viewRoute]="viewRoute"
    [showLocalizedValuesButton]="true"
>
</dynamic-view-v2>
