import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-daily-goal-form',
  templateUrl: './daily-goal-form.component.html',
  styleUrls: ['./daily-goal-form.component.sass']
})
export class DailyGoalFormComponent  extends BaseDynamicFormComponent implements OnInit
{
  dailyGoalRecord: BaseEntityDto = new BaseEntityDto();
  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Color Form V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Daily Goal Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            isRequired: false,
            clearField: true,
            showField: true,
            key: 'start',
            label: 'Start Date/Time',
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
            columnWidth: 6
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            isRequired: false,
            clearField: true,
            showField: true,
            key: 'end',
            label: 'End Date/Time',
            calendarMinDate: this.dailyGoalRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
            columnWidth: 6
          },
          {
            name: 'Achievements Collection',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'achievementsCollection_ref',
            isRequired: false,
            label: 'Achievements Collection',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: false,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: { 
                fieldName: 'achievementsCollection_ref', 
                apiController: 'achievements-collection', 
                minimal: true, autopopulate: true, virtuals: false, sort: { name: 1 } 
            },
            columnWidth: 6
          },
          {
            name: 'Rewards rewardsTrack_ref',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'rewardsTrack_ref',
            isRequired: false,
            label: 'Rewards Track',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: false,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: { 
                fieldName: 'rewardsTrack_ref', apiController: 'rewards-track', 
                minimal: false, autopopulate: true, virtuals: false, 
                sort: { name: 1 }, customQuery: { type: 10 } 
            },
            columnWidth: 6
          },
          {
            name: "Days to Loop",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'daysToLoop',
            label: 'Days to Loop',
            inputNumberShowButtons: true,
            inputNumberMin: 1,
            inputNumberMax: 7,
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.dailyGoalRecord =
    {
      enabled: false
    };
    this.title = "Daily Goal";
    this.viewRoute = 'daily-goals';
    this.isLoading = false;
  }

  /**
   * Validate Daily Goal required field.
   *
   * @param dailyGoal Daily Goal record
   */
  validateDailyGoal(dailyGoal: any)
  {
    let confirmValidationCallback = dailyGoal && dailyGoal.name && dailyGoal.name.length > 0;
    let message = confirmValidationCallback ? 'Valid' : 'Daily Goal Name must have a value';

    return {confirmValidationCallback, message}
  }
}
