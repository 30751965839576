export const settingsConstants = {
  managedLists: {
    linkDestinationFields: [
      {
        key: 'linkText',
        name: 'Button Text',
        inputType: 'inputText'
      },
      {
        key: 'linkDestination',
        name: 'Button Destination',
        inputType: 'inputText'
      },
      {
        key: 'enabled',
        name: 'Enabled',
        inputType: 'inputToggle',
        onLabel: 'Enabled',
        offLabel: 'Not Enabled'
      },
    ],
    linkDestinationFBFields: {
      linkText: null,
      linkDestination: null,
      enabled: true
    },
    linkDestinationURL: 'link-destination'
  },
  sceneTypes: 'scene-types',
  entities:{
    readOnlyUser: 'readOnlyUser',
    sceneTypes: 'sceneTypes',
    imagery: 'imagery',
    entityType: 'entityType'
  },
  sceneTypesData: {
    title: 'New Scene Type',
    fields: [{ formControlName: 'name', type: 'text', label: 'Name' }],
  },
  sceneTypesForm: {
    name: null,
  },

  imagerySettings: 'ImagerySettings',
  imagerySettingsData: {
    title: 'Imagery Settings',
    fields: [
      { formControlName: 'name', type: 'text', label: 'Name' },
      { formControlName: 'path', type: 'text', label: 'Path' },
      {
        formControlName: 'entityType',
        // type: 'dropdown',
        // options: [
        //   { label: 'Currencies', value: 'currencies' },
        //   { label: 'Loading Screens', value: 'loadingScreens' },
        //   { label: 'Misc. Images', value: 'miscImages' },
        // ],
        label: 'Entity Type',
      },
    ],
  },
  entityTypeSettings: 'entity-type-settings',
  entityTypeSettingsData: {
    title: 'Entity Type Settings',
    fields: [
      { formControlName: 'name', type: 'text', label: 'Name' },
    ],
  },
};
