import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { DataService } from './data.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FieldService {
  private url: string = '/api/forms';

  constructor(private http: HttpClient, private dataService: DataService) {}

  // get all fields for specific form
  async getFormFields(formKey: String) {
    return await this.http
      .get<any>(`${this.url}/${formKey}/fields`)
      .toPromise()
      .then((fields) => {
        return fields;
      });
  }

  // get all fields for a specific form, from JSON
  async getFormFieldsFromJSON(formKey: String) {
    return await this.dataService.getAllOfTypeAsync(
      `forms/${formKey}/jsonFields`,
      {}
    );
  }

  // get single field from JSON
  async getFieldByIdFromJSON(id: any) {
    return await this.http
      .get<any>(`${this.url}/jsonFields/find/${id}`)
      .toPromise()
      .then((field) => {
        return field;
      });
    // console.log('async get document', result);
  }

  // get a single specific field
  getField(query: Object) {
    return this.dataService.getAllOfType('forms/fields/find', query);
  }

  // get all options for a specific field
  getOptions(type: String) {
    return this.dataService.getAllOfType(type, {});
  }

  // get selected options for a specific field
  getOptionsByGameId(type: String, query: Object) {
    return this.dataService.getAllOfType(type, query);
  }
}
