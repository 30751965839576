import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'in-game-preview',
templateUrl: './in-game-preview.component.html',
  styleUrls: ['./in-game-preview.component.sass']
})
export class InGamePreviewComponent implements OnInit {
  @Input() record: any;
  @Input() field: any;
  initialBody: string = '';

  formattedBody: SafeHtml; // Use SafeHtml to store sanitized HTML

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.initialBody = this.record.body || '';
    this.updateFormattedBody();

    console.log('record', this.record);
    console.log('field', this.field);
  }

  ngOnChanges(changes: SimpleChanges) {
  if (changes.record) {
    const previousBody = changes.record.previousValue?.body || 'No previous content';
    const currentBody = changes.record.currentValue?.body || 'No current content';

    console.log('Previous Body:', previousBody);
    console.log('Current Body:', currentBody);

    // Update the formatted body if the content has changed
    if (previousBody !== currentBody) {
      this.updateFormattedBody();
    }
  }
}

  

  updateFormattedBody(): void {
    const bodyContent = this.record?.body || '';
    this.formattedBody = this.insertNewlines(bodyContent);
    console.log('Formatted Body:', this.formattedBody);
 }
 
  
  insertNewlines(text: string): SafeHtml {
    const imageUrl = this.record?.image_ref?.thumbnail_url_256;
  
    const imageTag = imageUrl
      ? `
        <div class="image-container">
          <img src="${imageUrl}" alt="Embedded Image" class="banner-image">
        </div>`
      : `
        <div class="image-container placeholder">
          <div class="placeholder-text">Please submit to see preview of banner image</div>
        </div>`;
  
    if (!text) {
      text = `
        <p>This is a preview of your content. Please note that the actual content will be displayed here once you submit the final version.</p>
        ${imageTag}
        <p>With love,<br>The Garden Joy Team</p>
      `;
    } else {
      // Replace {image} with the image tag and insert newlines where needed
      text = text.replace(/\{image\}/g, `${imageTag}<br>`);
  
      // Insert newline before "With love, The Garden Joy Team"
      text = text.replace(/With love,\s*The Garden Joy Team/g, '<br>With love,<br>The Garden Joy Team');
  
      // Convert any remaining newlines to <br>
      text = text.replace(/\n/g, '<br>');
    }
  
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }
}
