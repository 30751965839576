import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { DataService } from 'src/app/services/data.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';

@Component({
  selector: 'store-transfer',
  templateUrl: './store-transfer.component.html',
  styleUrls: ['./store-transfer.component.sass']
})
export class StoreTransferComponent implements OnInit
{
  @Input() visibleModal: boolean = false;
  addEntity: string;
  addModalVisible: boolean = false;
  addFields: Array<any> = [];
  filteredSuggestions: Array<any> = [];
  suggestions: any;
  isLoaded: boolean = false;
  sourceOptions: any = ['dev', 'qa', 'prod']
  destinationOptions: any = ['dev', 'qa', 'prod']
  @Input() sourceEnv: string;
  @Input() destinationEnv: string;
  @Input() storeListings: any = [
  ]
  @Input() pricePoints: any = [
  ]
  @Output() enableButton: any = new EventEmitter<any>();
  storeListingValidations: any = [];
  pricePointValidations: any = [];
  constructor(
    private readonly dataService: DataService,
    private readonly http: HttpClient,
    private readonly utilitiesService: UtilitiesService,
    private readonly messageService: MessageService,
    private readonly router: Router,
  )
  {
  }

  /**
   * Store Transfer Initial Implementation
   */
  async ngOnInit()
  {
    let cart = JSON.parse(localStorage.getItem('storeTransferCart') || 'null');

    if(cart) {
      this.storeListings = cart.listings
      this.pricePoints = cart.pricePoint
      this.destinationEnv = cart.destinationEnv
      this.sourceEnv = cart.sourceEnv
    }

    this.refreshValidationArrays()

    this.isLoaded = true
  }

  async addModal(entity: string) {
    if(entity == 'store-listings') {
      this.addEntity = 'store-listings';
      this.addModalVisible = true;
      this.suggestions = await this.dataService.getAllOfTypeAsync('store-listings-v2', {})

      this.suggestions = this.suggestions.map((option:any) => {
        return {
          ...option,
          combined: option.id + ' ' + option.name // Combine id and name
        };
      });
    } else {
      this.addEntity = "price-points";
      this.addModalVisible = true;
      this.suggestions = await this.dataService.getAllOfTypeAsync('price-points', {});
      this.suggestions = this.suggestions.map((option:any) => {
        return {
          ...option,
          combined: option.id + ' ' + option.name // Combine id and name
        };
      });
    }
    console.log(this.suggestions)
  }

  async confirmAction() {
    let result = this.addFields.map(a => a.id);
    const res =  await firstValueFrom(this.http.get<any>('/api/store/diff?entity='+this.addEntity+'&source='+this.sourceEnv+'&destination='+this.destinationEnv+'&ids='+result.join()));
    if(this.addEntity == 'store-listings') {
      this.storeListings = [...this.storeListings, ...res.records]
    } else {
      this.pricePoints = [...this.pricePoints, ...res.records]
    }

    this.refreshValidationArrays();
    const cart = {
      listings: this.storeListings,
      pricePoint: this.pricePoints,
      sourceEnv: this.sourceEnv,
      destinationEnv: this.destinationEnv
    }
    this.localStoreCart(cart)
    this.addFields = []
    this.addModalVisible = false;
  }
  async localStoreCart(cart: any) {
    localStorage.setItem('storeTransferCart', JSON.stringify(cart))
  }

  removeListing(item: any) {
    // Find the index of the item in the array
    const index = this.storeListings.indexOf(item);
    // If the item is found, remove it from the array
    if (index !== -1) {
      this.storeListings.splice(index, 1);
    }
    const cart = {
      listings: this.storeListings,
      pricePoint: this.pricePoints,
      sourceEnv: this.sourceEnv,
      destinationEnv: this.destinationEnv
    }
    this.localStoreCart(cart)
  }
  removePricePoint(item: any) {
    // Find the index of the item in the array
    const index = this.pricePoints.indexOf(item);
    // If the item is found, remove it from the array
    if (index !== -1) {
      this.pricePoints.splice(index, 1);
    }
    const cart = {
      listings: this.storeListings,
      pricePoint: this.pricePoints,
      sourceEnv: this.sourceEnv,
      destinationEnv: this.destinationEnv
    }
    this.localStoreCart(cart)
  }

  async saveEnv(event: any) {
    console.log(event)
    try {
        let resultListing = this.storeListings.map((a: any) => a.id);
        const resListing =  await firstValueFrom(this.http.get<any>('/api/store/diff?entity=store-listings&source='+this.sourceEnv+'&destination='+this.destinationEnv+'&ids='+resultListing.join()));
        this.storeListings = resListing.records

        let resultPrice = this.pricePoints.map((a: any) => a.id);
        const resPrice =  await firstValueFrom(this.http.get<any>('/api/store/diff?entity=price-points&source='+this.sourceEnv+'&destination='+this.destinationEnv+'&ids='+resultPrice.join()));
        this.pricePoints = resPrice.records


        const cart = {
          listings: this.storeListings,
          pricePoint: this.pricePoints,
          sourceEnv: this.sourceEnv,
          destinationEnv: this.destinationEnv
        }

      this.localStoreCart(cart)
      this.messageService.add(
        {
          sticky: false,
          severity: 'success',
          summary: 'Environment Change Succesfully',
          detail: 'Environment changed to: ' + event.value,
          life: 1500
        });
    } catch(error) {
      console.error('Error changing environment:', error);
      this.messageService.add({
        sticky: false,
        severity: 'error',
        summary: 'Error Changing Environment',
        detail: 'Error Changing Environment',
        life: 1500
      });
      this.destinationEnv = 'qa'
  }

  }

  findFirstString(obj: any): string | undefined {
    if(typeof obj === 'string') {
      return obj
    } else {
      for (const key in obj) {
        if (typeof obj[key] === 'string') {
          return obj[key];
        }
        if (typeof obj[key] === 'object') {
          const result = this.findFirstString(obj[key]);
          if (result) {
            return result;
          }
        }
      }
      return undefined;
    }

  }

  changeStoreListing(index: any) {
    try {
      return this.storeListingValidations[index].includes(false);
    } catch (error) {
      this.refreshValidationArrays();
      return this.storeListingValidations[index].includes(false);
    }
  }

  changePricePoint(index: any) {
    try {
      return this.pricePointValidations[index].includes(false);
    } catch (error) {
      this.refreshValidationArrays();
      return this.pricePointValidations[index].includes(false);
    }

  }

  navigateToDetails(id: any, entity: any) {
    window.open('/'+entity+'/'+id)
  }

  checkValidate(entity: any, index: number) {
    if(entity = 'price') {
      return this.pricePointValidations[index] ? this.pricePointValidations[index].includes(false) : false
    } else {
      return this.storeListingValidations[index] ? this.storeListingValidations[index].includes(false) : false
    }
  }

  checkFalseValidates(array: any){
    return array.some((item: any) => item === false)
  }

  enableTransfer() {
   if (!(this.pricePointValidations.some((subArray: any) => this.checkFalseValidates(subArray)) &&
    this.storeListingValidations.some((subArray: any) => this.checkFalseValidates(subArray)))) {
      this.enableButton.emit(false)
      return true
    } else {
      this.enableButton.emit(true)
      return false
    }
  }

  refreshValidationArrays() {
    this.storeListingValidations = this.storeListings.map((item: any) => item.fields.map((subitem: any) => subitem.isValid))
    this.pricePointValidations = this.pricePoints.map((item: any) => item.fields.map((subitem: any) => subitem.isValid))
    // console.log(this.storeListingValidations, this.pricePointValidations)
  }

  emptyCart() {
    const cart = {
      listings: [],
      pricePoint: [],
      sourceEnv: 'dev',
      destinationEnv: 'qa'
    }

    this.storeListings = [];
    this.pricePoints = [];

    this.localStoreCart(cart)

    this.messageService.add({
      sticky: false,
      severity: 'success',
      summary: 'Store Transfer Cart Emptied Succesfully',
      life: 1500
    });

    this.dataService.updateStoreTransferCount(this.storeListings.length + this.pricePoints.length)
  }
}
