import { Component, OnInit, ViewChild } from '@angular/core';
import { throws } from 'assert';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import ExternalPlantDTO from '../../dtos/ExternalPlantDTO';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-external-plant-data-form-v3',
  templateUrl: './external-plant-data-form-v3.component.html',
  styleUrls: ['./external-plant-data-form-v3.component.sass']
})
export class ExternalPlantDataFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  externalPlantRecord: ExternalPlantDTO = new ExternalPlantDTO();
  constructor()
  {
    super();
  }

  /**
   * Rewards Track Form V3 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "External Plant Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
        },
          {
            name: "File Reference",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'fileRef',
            isRequired: false,
            label: 'File Reference',
            disabled: false
          },
          {
            name: "Common Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'commonName',
            isRequired: false,
            label: 'Common Name',
            disabled: false
          },
          {
            name: "Botanical Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'botanicalName',
            isRequired: false,
            label: 'Latin Name',
            disabled: false
          },
          {
            name: "Plant Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'plantType',
            isRequired: false,
            label: 'Plant Type',
            disabled: false
          },
          {
            name: "Mature Size",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'matureSize',
            isRequired: false,
            label: 'Mature Size',
            disabled: false
          },
          {
            name: "Growing Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'growingTime',
            isRequired: false,
            label: 'Growing Time',
            disabled: false
          },
          {
            name: "Bloom Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'bloomTime',
            isRequired: false,
            label: 'Bloom Time',
            disabled: false
          },
          {
            name: "Flower Color",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'flowerColor',
            isRequired: false,
            label: 'Flower Color',
            disabled: false
          },
          {
            name: "Soil Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'soilType',
            isRequired: false,
            label: 'Soil Type',
            disabled: false
          },
          {
            name: "Soil PH",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'soilPH',
            isRequired: false,
            label: 'Soil PH',
            disabled: false
          },
          {
            name: "Hardiness Zone",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'hardinessZone',
            isRequired: false,
            label: 'Hardiness Zone',
            disabled: false
          },
          {
            name: "Native Area",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'nativeArea',
            isRequired: false,
            label: 'Native Area',
            disabled: false
          },
          {
            name: "Sun Exposure",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'sunExposure',
            isRequired: false,
            label: 'Sun Exposure',
            disabled: false
          },
          {
            name: "Toxicity",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'toxicity',
            isRequired: false,
            label: 'Toxicity',
            disabled: false
          },
          {
            name: "URL",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'url',
            isRequired: false,
            label: 'URL',
            disabled: false
          },
          {
            name: "imagePath",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'imagePath',
            isRequired: false,
            label: 'Image Path',
            disabled: false
          },
          {
            name: "Description",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputTextArea],
            clearField: true,
            key: 'description',
            isRequired: false,
            label: 'Description',
            disabled: false
          },
          {
            name: "Spruce Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'image_ref',
            label: 'Spruce Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: true,
            scrollHeight: '200px',
            options: { fieldName: 'image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: 3 }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true }
            // options: { fieldName: 'image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
          },
        ],
        type: DynamicCardType.Card
      },
    ]

    this.title = "External Plant Data";
    this.viewRoute = 'external-plant-data';
    this.isLoading = false;


  }

  /**
   * modifies the input text fields into array
   */
  async splitArrayFields() {
    if(typeof this.dynamicFormComponent.record.botanicalName == 'string') {
      this.dynamicFormComponent.record.botanicalName = this.dynamicFormComponent.record.botanicalName.split(",")
    }
    if(typeof this.dynamicFormComponent.record.commonName == 'string') {
      this.dynamicFormComponent.record.commonName = this.dynamicFormComponent.record.commonName.split(",")
    }
    if(typeof this.dynamicFormComponent.record.plantType == 'string') {
      this.dynamicFormComponent.record.plantType = this.dynamicFormComponent.record.plantType.split(",")
    }
    if(typeof this.dynamicFormComponent.record.nativeArea == 'string') {
      this.dynamicFormComponent.record.nativeArea = this.dynamicFormComponent.record.nativeArea.split(",")
    }
    if(this.dynamicFormComponent.record.botanicalName && this.dynamicFormComponent.record.botanicalName[0].length == 0) {
      this.dynamicFormComponent.record.botanicalName = null;
    }
    if(this.dynamicFormComponent.record.commonName && this.dynamicFormComponent.record.commonName[0].length == 0) {
      this.dynamicFormComponent.record.commonName = null;
    }
    if(this.dynamicFormComponent.record.plantType && this.dynamicFormComponent.record.plantType[0].length == 0) {
      this.dynamicFormComponent.record.plantType = null;
    }
    if(this.dynamicFormComponent.record.nativeArea && this.dynamicFormComponent.record.nativeArea[0].length == 0) {
      this.dynamicFormComponent.record.nativeArea = null;
    }
   }
}
