<p-fieldset legend="Main Menu" [toggleable]="true">
    <div class="card">
      <p-toast></p-toast>
      <p-orderList 
        [value]="menuItems" 
        [(selection)]="selectedItems" 
        [dragdrop]="true" 
        (onReorder)="onReorderMainMenu(menuItems)">
        <ng-template let-item let-i="index" pTemplate="item">
        <ng-container *ngIf="!item.separator; else separatorTemplate">
          <div class="menu-item">
            <!-- Main menu item header -->
            <div class="p-d-flex p-ai-center p-jc-between w-full">
              <div class="p-d-flex p-ai-center cursor-pointer" (click)="item.expanded = !item.expanded">
                <i class="pi" [ngClass]="item.expanded ? 'pi-chevron-down' : 'pi-chevron-right'" style="margin-right: 8px;"></i>
                <span>{{ item.label }}</span>
              </div>
              <button pButton icon="pi pi-code" 
                      class="p-button-text p-button-rounded" 
                      (click)="showMainMenuItemJson(i)">
              </button>
            </div>

            <!-- Submenu items -->
            <div *ngIf="item.expanded && item.items" class="submenu-items">
              <div *ngFor="let submenu of item.items" class="submenu-item">
              <ng-container *ngIf="!submenu.separator; else separatorTemplate">
                <div class="p-d-flex p-ai-center p-jc-between">
                  <div class="p-d-flex p-ai-center cursor-pointer" (click)="submenu.expanded = !submenu.expanded">
                    <i class="pi" [ngClass]="submenu.expanded ? 'pi-chevron-down' : 'pi-chevron-right'" style="margin-right: 8px;"></i>
                    <span>{{ submenu.label }}</span>
                  </div>
                  <button pButton icon="pi pi-code" 
                          class="p-button-text p-button-rounded" 
                          (click)="showMainMenuItemJson(submenu)">
                  </button>
                </div>

                <!-- URL or nested items -->
                <div *ngIf="submenu.expanded" class="nested-submenu">
                  <div *ngIf="submenu.url" class="url-display">
                    <i class="pi pi-link" style="margin-right: 8px;"></i>
                    <span class="url-text">{{ submenu.url }}</span>
                  </div>
                  <div *ngIf="submenu.items && submenu.items.length > 0">
                    <div *ngFor="let nestedItem of submenu.items" 
                         class="p-d-flex p-ai-center p-jc-between">
                      <div class="p-d-flex p-ai-center">
                        <i class="pi pi-angle-right" style="margin-right: 8px;"></i>
                        <span>{{ nestedItem.label }}</span>
                      </div>
                      <button pButton icon="pi pi-code" 
                              class="p-button-text p-button-rounded" 
                              (click)="showMainMenuItemJson(nestedItem)">
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #separatorTemplate></ng-template>
        </ng-template>
      </p-orderList>            
        <div class="buttons-container">
          <p-button label="Add Section" icon="pi pi-plus" class="p-button-outlined"
                    (click)="openAddSectionModal('main')"></p-button>
          <button pButton pRipple label="Save" icon="pi pi-save" 
                  class="p-button-success"
                  (click)="saveMenuOrder('main')"></button>
        </div>

    </div>
  </p-fieldset>
  <p-fieldset legend="Side Menu" [toggleable]="true">
    <div class="card">
      <p-toast></p-toast>
      <p-orderList 
        [value]="sideMenuItems" 
        [(selection)]="selectedItems" 
        [dragdrop]="true" 
        (onReorder)="onReorderSideMenu($event.items)">
        <ng-template let-item pTemplate="item">
          <div class="menu-item">
            <!-- Regular menu item -->
            <ng-container *ngIf="item.type !== 'collapsible'; else collapsibleTemplate">
            <div class="p-d-flex p-ai-center p-jc-between w-full">
              <div class="p-d-flex p-ai-center cursor-pointer">
                <i class="pi" [ngClass]="item.expanded ? 'pi-chevron-down' : 'pi-chevron-right'" style="margin-right: 8px;"></i>
                <span>{{ item.label }}</span>
              </div>
              <button pButton icon="pi pi-code" 
                      class="p-button-text p-button-rounded" 
                      (click)="showSideMenuItemJson(item)">
              </button>
            </div>

            <!-- Show URL if expanded -->
            <div *ngIf="item.expanded && item.route" class="url-display">
              <i class="pi pi-link" style="margin-right: 8px;"></i>
              <span class="url-text">{{ item.route }}</span>
            </div>
          </ng-container>

          <ng-template #collapsibleTemplate>
            <div class="collapsible-menu">
              <div class="p-d-flex p-ai-center p-jc-between w-full">
                <div class="p-d-flex p-ai-center cursor-pointer" (click)="item.expanded = !item.expanded">
                  <i class="pi" [ngClass]="item.expanded ? 'pi-chevron-down' : 'pi-chevron-right'" style="margin-right: 8px;"></i>
                  <span>{{ item.label }}</span>
                </div>
                <button pButton icon="pi pi-code" class="p-button-text p-button-rounded" (click)="showSideMenuItemJson(item)"></button>
              </div>
              <div *ngIf="item.expanded" class="submenu-items">
                <div *ngFor="let subItem of item.subMenu" class="submenu-item">
                  <div class="p-d-flex p-ai-center p-jc-between">
                    <div class="p-d-flex p-ai-center cursor-pointer">
                      <i class="pi pi-angle-right" style="margin-right: 8px;"></i>
                      <span>{{ subItem.label }}</span>
                    </div>
                    <button pButton icon="pi pi-code" class="p-button-text p-button-rounded" (click)="showSideMenuItemJson(subItem)"></button>
                  </div>
                  <div *ngIf="subItem.route" class="url-display">
                    <i class="pi pi-link" style="margin-right: 8px;"></i>
                    <span class="url-text">{{ subItem.route }}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          </div>
        </ng-template>
      </p-orderList>            
      <div class="buttons-container">
        <p-button label="Add Section" icon="pi pi-plus" class="p-button-outlined"
                  (click)="openAddSectionModal('side')"></p-button>
        <button pButton pRipple label="Save" icon="pi pi-save" 
                class="p-button-success"
                (click)="saveMenuOrder('side')"></button>
      </div>
      
  <p-dialog [(visible)]="isAddSectionModalOpen" header="Add New Section" [style]="{width: '50vw'}" [modal]="true">
    <div class="p-field">
      <label for="newSectionJson">Enter JSON</label>
      <textarea id="newSectionJson" [(ngModel)]="newSectionJson" rows="10" class="p-inputtext w-full"></textarea>
    </div>
    
    <div class="p-d-flex p-jc-end">
      <button pButton label="Cancel" class="p-button-text" (click)="isAddSectionModalOpen = false"></button>
      <button pButton label="Add" icon="pi pi-check" class="p-button-success" (click)="addSection()"></button>
    </div>
  </p-dialog>

  <p-dialog 
  [(visible)]="showJsonDialog" 
  [header]="selectedItemLabel" 
  [style]="{width: '50vw'}" 
  [modal]="true">
  <div class="json-viewer">
    <textarea [(ngModel)]="editableJson" rows="20" style="width: 100%;"></textarea>
  </div>
  <div class="p-d-flex p-jc-end p-mt-3">
    <button 
      pButton label="Save" 
      icon="pi pi-save" 
      class="p-button-secondary p-mr-2" 
      (click)="isSideMenuEditing ? saveSideJsonChanges() : saveJsonChanges()">
    </button>
    <button pButton label="Copy" icon="pi pi-copy" class="p-button-secondary p-mr-2" 
            (click)="copyJsonToClipboard()"></button>
    <button pButton label="Close" class="p-button-secondary" 
            (click)="showJsonDialog = false"></button>
  </div>
</p-dialog>
