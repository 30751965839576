<p-dialog class="createNewAssetDialog" [(visible)]="isVisible" [draggable]="false" [style]="{width: '65vw'}" (onHide)="onHideEmitter()">
  <ng-template pTemplate="header">
    <h3>Add New Asset Record</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="p-grid p-mx-5">
      <div class="p-col-12 p-inputgroup">
        <span ngClass="p-float-label">
          <input [(ngModel)]="entity.name" pInputText class="field-item" />
          <label for="entity.name">Name <strong style="color: crimson">*</strong></label>
        </span>
      </div>
      <div class="p-col-6 p-my-2">
        <span ngClass="p-float-label">
          <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="entity.entityType" [ngModelOptions]="{standalone: true}"
            [options]="entityTypeOptions" optionLabel="label" optionValue="value" [autoDisplayFirst]="false"
            [required]="true" [showClear]="true" (ngModelChange)="onEntityValueChanged($event)"></p-dropdown>
          <label for="entityTypeSelection">Entity Type <strong style="color: crimson">*</strong></label>
        </span>
      </div>
      <div class="p-col-6 p-my-2">
        <span ngClass="p-float-label">
          <p-dropdown [style]="{'width':'100%'}" [(ngModel)]="entity.assetType" [options]="imageryOptions"
            optionLabel="label" optionValue="value" [autoDisplayFirst]="false" [required]="true" [filter]="true"
            (onChange)="changePathPrefix($event)"></p-dropdown>
          <label for="dropdown">Bundle Type <strong style="color: crimson">*</strong></label>
        </span>
      </div>
      <div class="p-col-12 p-mb-3 align-right">
        <p-toggleButton
          onLabel="Localized" offLabel="Not Localized" onIcon="fa-solid fa-language" offIcon="fa-solid fa-xmark" [(ngModel)]="entity.localized"
          pTooltip="Check if image has Localized text versions (es, fr, de)" tooltipPosition="top"
        >
        </p-toggleButton>
      </div>
      <div class="p-col-12">
        <div class="p-grid p-mx-3">
          <div class="p-col-8 p-p-0">
            <div class="p-mb-1">
              <p-tag icon="pi pi-info-circle" severity="info"
                value="Add '/' at the end of the path to look for files inside a folder or directory."></p-tag>
            </div>
          </div>
          <div class="p-col-4 p-p-0 align-right">
            <div class="">
              <p-checkbox inputId="check" [(ngModel)]="validateDupPath" [binary]="true" inputId="binary"></p-checkbox>
              <label for="check" style="font-size: small; margin-left: 10px;"> Allow Duplicate Path?</label>
            </div>
          </div>
          <div class="p-col-12">
            <div class="">
              <div class="p-inputgroup" style="width: 100%;">
                <plastic-files-autocomplete *ngIf="!isLoading" [(path)]="path" (onChange)="buildPath($event)"
                  style="width: 100%;"></plastic-files-autocomplete>
              </div>
            </div>
          </div>
          <div class="p-col-12">
            <div class="p-grid p-mx-3">
              <div class="p-col-12">
                <div class="p-my-3">
                  <build-asset [skipMiscValidation]="validateDupPath" [entity]="entity" [createMiscRecord]="true"
                    [path]="entity.path"
                    [btnDisabled]="!isValidPath || entity.entityType === undefined || entity.entityType === null || entity.assetType === undefined || entity.assetType === null"
                    [type]="type" assetType="image" (triggerCompleteEvent)="formSubmittedTrigger($event)"
                    label="Submit and Build" [buildType]="buildType"></build-asset>
                  <build-asset [skipMiscValidation]="validateDupPath" class="p-mx-3" [entity]="entity"
                    [createMiscRecord]="true" [path]="entity.path"
                    [btnDisabled]="!isValidPath || entity.entityType === undefined || entity.entityType === null || entity.assetType === undefined || entity.assetType === null"
                    [type]="type" assetType="image" (triggerCompleteEvent)="formSubmittedTrigger($event)"
                    label="Submit and Build with High Priority" [buildType]="buildType"
                    [isHighPriority]="true"></build-asset>
                  <build-asset [skipMiscValidation]="validateDupPath" [entity]="entity" [createMiscRecord]="true"
                    [skipBuild]="true" [path]="entity.path"
                    [btnDisabled]="!isValidPath || entity.entityType === undefined || entity.entityType === null || entity.assetType === undefined || entity.assetType === null"
                    [type]="type" assetType="image" (triggerCompleteEvent)="formSubmittedTrigger($event)"
                    label="Submit and Skip Build" [buildType]="buildType"></build-asset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>
