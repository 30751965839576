import { LoggerService } from './../common/services/logger.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { UserContextService } from '../common/services/user-context.service';
import { UtilitiesService } from '../common/services/utilities.service';
import jwt_decode from 'jwt-decode';
import { Observable, catchError, map, of, shareReplay, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser: SocialUser;
  loggedIn: Boolean;
  permissions: any;

  constructor(
    public socialAuthService: SocialAuthService,
    private router: Router,
    private http: HttpClient,
    private userContextService: UserContextService,
    private utilitiesService: UtilitiesService,
    private logger: LoggerService,
  ) {}

  /**
   * Retrieves Soscial User Data
   */
  getSocialUser() {
    this.currentUser = JSON.parse(localStorage.getItem('socialUser') || 'null');
    this.loggedIn = JSON.parse(localStorage.getItem('loggedIn') || 'false');
    if (!this.currentUser) {
      this.logout();
    }
    return { currentUser: this.currentUser, loggedIn: this.loggedIn };
  }

  /**
   * Retrieves Soscial User Data
   */
  getLoggedUser() {
    return this.currentUser = JSON.parse(localStorage.getItem('socialUser') || 'null');
  }

  /**
   * Retrieves if the user is loggedIn
   */
  public getLoggedIn() {
    return JSON.parse(localStorage.getItem('loggedIn') || 'false');
  }

  /**
   * Retrieves if the user is loggedIn
   */
  public loadedPermissions() {
    return this.permissions?.length > 0;
  }

  /**
   * Retrieves the user token from Local Storage
   */
  public getToken() {
    return localStorage.getItem('userToken');
  }

  /**
   * Sets user token to local storage.
   *
   * @param token The user toket to set.
   */
  public setToken(token: any) {
    localStorage.setItem('userToken', token);
    localStorage.setItem('token', token);
  }

  /**
   * Sets socialUser to local storage.
   * This is temporary fix since https://github.com/abacritt/angularx-social-login/issues/522 still has an open issue on this....
   *
   * @param user socialUser
   */
  public setSocialUser(user: SocialUser) {
    localStorage.setItem('socialUser', JSON.stringify(user));
  }

  /**
   * Sets isLogged in to local storage.
   * This is temporary fix since https://github.com/abacritt/angularx-social-login/issues/522 still has an open issue on this....
   *
   * @param isLoggedIn The user toket to set.
   */
  public setLoggedIn(loggedIn: Boolean) {
    localStorage.setItem('loggedIn', JSON.stringify(loggedIn));
  }

  /**
   * User Login with Google
   */
  loginWithGoogle() {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((user: any) => {
        this.logger.log('google user', user);
        this.http.post<any>('/api/auth/login', user).subscribe({
          next: (data) => {
            this.logger.log('login response', data);
            if (data && data.accessToken) {
              this.setToken(data.accessToken);
            }
            this.utilitiesService.refreshComponent('home');
          },
          error: (error) => {
            this.logger.error('There was an error logging in.', error);
            this.utilitiesService.refreshComponent('home');
          },
        });
      })
      .then(() => {
        if (this.router.url == '/home') {
          /**
           * If the user is on home page, we need to "restart/refresh" the component.
           */
          this.utilitiesService.refreshComponent('home');
        } else {
          this.router.navigate(['home']);
        }
      });
  }

  /**
   * User Login with Google
   */
  loginWithGoogle2(token: string) {
    this.http.post<any>('/api/auth/login-google', { token })
      .pipe(
        tap((data: any) => this.handleLoginResponse(data)),
        catchError((error: any) => this.handleError(error))
      )
      .subscribe();
  }

  private handleLoginResponse(data: any) {
    this.logger.log('login response', data);
    if (data && data.token.accessToken) {
      this.setToken(data.token.accessToken);
      // Consider navigating to a specific route instead of reloading
      // this.router.navigate(['/dashboard']);
    } else {
      // Handle unexpected response structure
      this.logger.error('Unexpected login response', data);
    }
    window.location.reload(); // Consider alternatives to reloading
  }

  private handleError(error: any) {
    this.logger.error('There was an error logging in.', error);
    // Return an observable to properly complete the observable chain
    return of(error);
  }

  /**
   * Checks if token is valid based on expires in
   * @param token
   * @returns
   */
  tokenValid(token: any) {
    // subtracting 12 hours to expiration time to renew before it expires.
    if (Date.now() >= ((token.exp * 1000) - (24 * 3600000))) {
      // this.logger.log('the token will expire in LESS than 24 hours or has expired');
      return false;
    } else {
      // this.logger.log('the token will expire in MORE than 24 hours.')
      return true;
    }
  }

  /**
   * evaluateToken Token
   * verify time left to expire
   * if close to expire, renew
   */
  async evaluateToken() {
    let currentToken = this.getToken();
    if (currentToken) {
      let decoded = jwt_decode(currentToken);
      // this.logger.log('decoded: ', decoded);
      if (this.tokenValid(decoded)) {
        // this.logger.log('valid token');
      } else {
        this.http.post<any>('/api/auth/refresh-token', { userData: decoded }).subscribe({
          next: (data) => {
            this.logger.log('verifyToken response', data);
            if (data && data.token.accessToken) {
              this.setToken(data.token.accessToken);
            }
          },
          error: (error) => {
            this.logger.error('verifyToken Error: ', error);
            this.logger.log('logging out, token cannot be refreshed.');
            this.logout();
          },
        });
      }
    } else {
      this.logger.log('no token found');
      this.logout();
    }
  }


  async refreshToken() {
    this.logger.log('Refreshing social login and user token.');
    try {
        // Refresh social auth token
        await this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);

        // Get current user from social service
        const user = await this.getSocialUser(); // Assuming getSocialUser can be made async or returns a promise

        this.logger.log('Posting refresh to API');

        // Refresh application token
        const data = await this.refreshAppToken(user.currentUser);
        this.logger.log('Setting local user token');
        this.setToken(data.accessToken);

    } catch (error) {
        this.logger.error('There was an error refreshing the user token.', error);
        // Implement retry logic or further error handling here if needed
    }
  }

  /**
   * Helper function to refresh application token.
   * Abstracting this into its own method makes the refreshToken method cleaner
   * and separates concerns for better testing and readability.
   */
  private async refreshAppToken(currentUser: any): Promise<any> {
    return new Promise((resolve, reject) => {
        this.http.post<any>('/api/auth/refresh', currentUser).subscribe({
            next: resolve,
            error: reject,
        });
    });
  }

  /**
   * Remove user token from local storage and redirect
   * user to the login screen.
   */
  async logout(redirectToLogin: boolean = true) {
    this.logger.log('logging out');
    this.setLoggedIn(false);

    await localStorage.removeItem('socialUser');
    await localStorage.removeItem('isGuestUser');
    await localStorage.removeItem('userToken');
    await localStorage.removeItem('token');

    // Check if the current location is not login to prevent redirect loop
    if (redirectToLogin && window.location.pathname !== '/login') {
      this.utilitiesService.refreshComponent('home');

      this.logger.log('user token after logout', localStorage.getItem('userToken'));

      await this.socialAuthService
        .signOut()
        .then(() => {
          console.log('finished logging out');
          // Use window.location.href for redirection to avoid reloading if already at login
          window.location.href = '/login';
        });
    } else {
      // Optionally handle the case when already on the login page
      console.log('Already at the login page, no redirect performed.');
    }
  }

  /**
   * Check if read only user's token is still valid for the session.
   */
  async validateReadOnlyUserSession() {
    let isValid = false;

    let bgUser = this.getSocialUser();

    if (bgUser && bgUser.loggedIn) {
      isValid = true;
    } else {
      isValid = false;
    }

    return isValid;
  }

  /**
   * Check if user's token is still valid for the session.
   */
  async checkUserAuth() {
    let isValid = false;

    // Call Burlingame studios user method
    // Set this value to true, as we currently don't
    // have a way to verify the user's session is valid.
    // We can circle-back and integrate the new endpoint when we have it ready.
    isValid = true;

    return isValid;
  }

  loadPermissions(): Observable<any> {
    return this.http.get(`/api/permissions/user`).pipe(
      map((permissions: any) => {
        this.permissions = permissions;
        return permissions;
      }),
      shareReplay(1)
    );
  }

  hasPermission(abilities: string[], controller: string): boolean {
    if (!this.permissions) {
      return false;
    }
    
    const flattenedPermissions = this.permissions.flat();
    const relevantPermissions = flattenedPermissions.filter((permission: { subject: string; }) => permission.subject === controller);

    return abilities.every(action => relevantPermissions.some((permission: { action: string; }) => permission.action === action));
  }

  // Define the method to register a test user
  registerExternalUser(user: any): Observable<any> {
    const url = '/api/auth/guest/register';
    return this.http.post(url, user);
  }

  loginExternalUser(user: any) {
    const url = '/api/auth/guest/login';
    return this.http.post<any>(url, user).pipe(
      tap((response: { accessToken: any; user: SocialUser; }) => {
        if (response && response.accessToken && response.user) {
          this.setLoggedIn(true)
          this.setToken(response.accessToken);
          this.setSocialUser(response.user);
          this.userContextService.isGuestUser = true;
          window.location.reload();
        }
      })
    );
  }
}
