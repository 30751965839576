import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BuildType } from 'src/app/enums/build-type';
@Component({
  selector: 'app-perk-form',
  templateUrl: './perk-form.component.html',
  styleUrls: ['./perk-form.component.sass']
})
export class PerkFormV2Component extends BaseDynamicFormComponent implements OnInit {
  perkRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  ngOnInit() {
    this.fields = [
      {
        title: "Perk Data",
        fields: [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false,
            columnWidth: 5
          },
          {
            name: "Perk Type",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'perkType',
            label: 'Perk Type',
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            options: {
              fieldName: 'perkType',
              values: [
                { name: 'Free Use', value: 1},
                { name: 'Daily Rewards', value: 2},
                { name: 'Challenge Submit Rewards', value: 3},
                { name: 'Voting Rewards', value: 4},
                { name: 'Gifting Tree Rewards', value: 5},
                { name: 'Gifting Wheel More Spins', value: 6},
                { name: 'Gifting Wheel Rewards', value: 7},
                { name: 'Gifting Wheel Lower Cost', value: 8},
                { name: 'Bonus Minigame Currency', value: 9},
                { name: 'Bonus Coop Currency', value: 10},
                { name: 'Bonus Weekly Achievements', value: 11},
              ]
            },
            columnWidth: 5
          },
          {
            name: "Is Default Perk",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            key: 'defaultPerk',
            isRequired: false,
            label: 'Default Perk',
            disabled: false,
            checkboxBinary: true,
            columnWidth: 2
          },
          {
            name: "Description",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputTextArea],
            clearField: true,
            key: 'description',
            isRequired: true,
            label: 'Description',
            disabled: false,
            columnWidth: 8
          },
          {
            name: "Club Point Requirement",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'clubPointRequirement',
            isRequired: true,
            label: 'Club Point Requirement',
            disabled: false,
            columnWidth: 4
          },
          {
            name: "Multiplier",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            key: 'multiplier',
            isRequired: false,
            label: 'Multiplier',
            disabled: false,
            maxFractionDigits: 2,
            useMaxFractionDigits: true,
            columnWidth: 4
          },
          {
            name: "Currency Multiplier",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'currencyMultiplier',
            isRequired: false,
            label: 'Currency Multiplier',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'currencyMultiplier', apiController: 'currencies',
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id',
              isArrayOfStringIds: true
            },
            columnWidth: 8
          },
          {
            name: 'Weekly Reward',
            inputType: DynamicInputType.Price,
            key: 'rewards_ref',
            options: { fieldName: '', version: 3 },
            priceExcludeResourceTypes: ['Titles'],
            columnWidth: 12
          },
          {
            name: "Colors",
            inputType: DynamicInputType.NestedInputComponent,
            key: 'colorStrings',
            label: 'Colors',
            options: {
              flatValue: true,
              headerTitle: "Colors",
              addButtonText: "Add Color",
              useStringArrayFormat: true,
              newRecordTemplate: '',
              fieldName: 'colorStrings',
              subFields: [
                {
                  name: "Color",
                  inputType: DynamicInputType.BaseInputField,
                  inputTypeFields: [InputFieldType.ColorPicker],
                  key: 'color',
                  isRequired: true,
                  label: 'Color'
                }
              ]
            },
            columnWidth: 12
          },
          {
            name: "Is Daily Reward",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            key: 'isDailyReward',
            isRequired: false,
            label: 'Is Daily Reward',
            disabled: false,
            checkboxBinary: true,
            displayControlType: "showHide",
            emitOnInit: true,
            displayControls: {
              ['dailyRewardTrack']: (value: any) => value == true ? true : false,
            },
          },
          {
            name: "Daily Reward Track",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            key: 'dailyRewardTrack',
            isRequired: false,
            label: 'Daily Reward Track',
            disabled: false,
            optionLabel: 'name',
            optionValue: '_id',
            scrollHeight: '200px',
            autoDisplayFirst: false,
            showField: false,
            columnWidth: 6,
            options: {
              fieldName: 'dailyRewardTrack',
              apiController: 'rewards-track',
              autopopulate: false,
              virtuals: false,
              sort: { name: 1 },
              select: 'name id path _id',
              customQuery: { type: 11 } 

            }
          },
          {
            name: "",
            key: '',
            inputType: DynamicInputType.SpaceCol,
            columnWidth: 6
          },
          {
            name: "Icon",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'icon_ref',
            label: 'Icon',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'icon_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              customQuery: { entityType: BuildType.Images },
              select: '_id name id path',
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          }, 
          
        ],
        type: DynamicCardType.Card
      }
    ];

    this.title = "Perk";
    this.viewRoute = 'perks';
    this.isLoading = false;
  }
} 