import { Component, Input } from '@angular/core';
import { ItemService } from '../services/item.service';


@Component({
  selector: 'app-main-status-display',
  templateUrl: './main-status-display.component.html',
  styleUrls: ['./main-status-display.component.sass']
})
export class MainStatusDisplayComponent {
  @Input() mainStatusGroup: { name: string, key: string }[];
  @Input() entity: { [key: string]: any };

  constructor(public itemService: ItemService) {}
}

