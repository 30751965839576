import { Component, OnInit } from '@angular/core';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { ColDef } from 'ag-grid-community';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';

@Component({
  selector: 'app-entity-table',
  templateUrl: './entity-table.component.html',
  styleUrls: ['./entity-table.component.sass']
})
export class EntityTableComponent implements OnInit {
  
  public columnDefs: ColDef[] = [];
  public entity: string = 'metadata';
  public title: string;
  public columnSelection: string;
  public isLoading: boolean = true;
  
  constructor(
    private agGridToolsService: AgGridToolsService,
  ) {}

  async ngOnInit() {
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Model Name',
        field: 'modelName',
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          entity: this.entity,
          isDynamicEntity: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        width: 350
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        width: 200
      },
      {
        headerName: 'Locked',
        field: 'isLocked',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Locked',
          offLabel: 'Not Locked',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Locked' : 'Not Locked'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        width: 200
      },
      {
        headerName: 'Archived',
        field: 'archived',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Archived',
          offLabel: 'Not Archived',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Archived' : 'Not Archived'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
        width: 200
      },
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        cellRendererParams: {
          isMetadata: true
        },
        pinned: 'right',
        width: 120,
        resizable: true,
      }
    ];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
    this.isLoading = false;
  }
}
