<!-- Common Header -->
<div class="p-d-flex p-jc-between p-ai-center mb-4">
    <h2 class="p-m-0">
        <ng-container [ngSwitch]="mode">
            <ng-container *ngSwitchCase="'edit'">Edit Schedule Record {{scheduleData?.id ? '(' + scheduleData.id + ')' : ''}}</ng-container>
            <ng-container *ngSwitchCase="'create'">Add new Schedule Record</ng-container>
            <ng-container *ngSwitchCase="'view'">Schedule Record {{scheduleData?.id ? '(' + scheduleData.id + ')' : ''}}</ng-container>
        </ng-container>
    </h2>
    <p-button 
        *ngIf="mode !== 'create'"
        [icon]="mode === 'view' ? 'pi pi-pencil' : 'pi pi-eye'"
        (onClick)="toggleEditMode()" 
        [label]="mode === 'view' ? 'Edit' : 'View'"
        severity="secondary">
    </p-button>
</div>

<div *ngIf="loading" class="flex justify-content-center">
    <p-progressSpinner></p-progressSpinner>
</div>

<div *ngIf="!loading && mode === 'view'" class="view-mode">
    <p-card>
        <div class="p-grid">
            <div class="p-col-12">
                <div class="p-grid">
                    <div class="p-col-12">
                        <h4 class="p-mb-1">Description</h4>
                        <div class="text-900">{{scheduleData?.description}}</div>
                    </div>
                </div>
            </div>

            <div class="p-col-12">
                <div class="flex align-items-center gap-2">
                    <h4 class="p-mb-1">Schedule Period</h4>
                    <button pButton pRipple type="button" 
                            icon="pi pi-calendar" 
                            class="p-button-rounded p-button-text"
                            pTooltip="View in Calendar">
                    </button>
                </div>
                <div class="p-grid">
                    <div class="p-col-6">
                        <h5 class="p-mb-1 p-mt-1">Start Date:</h5>
                        <div class="text-900">{{scheduleData?.start | date:'medium'}}</div>
                    </div>
                    <div class="p-col-6">
                        <h5 class="p-mb-1 p-mt-1">End Date:</h5>
                        <div class="text-900">{{scheduleData?.end | date:'medium'}}</div>
                    </div>
                </div>
            </div>

            <div class="p-col-12">
                <h4 class="p-mb-1">Referenced Records</h4>
                <p-table [value]="scheduleData?.scheduleRefs" [tableStyle]="{ width: '100%' }">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 30%">Table</th>
                            <th style="width: 70%">Records</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-ref>
                        <tr>
                            <td style="width: 30%">{{ref.referenceEntity}}</td>
                            <td style="width: 70%">
                                <div class="p-0 m-0">
                                    <div *ngFor="let item of ref.referenceId" class="mb-1">
                                        {{item.name || item.title}} (
                                        <a 
                                           [href]="getEntityUrl(ref.referenceEntity) + '/' + item.id"
                                           target="_blank"
                                           [ngStyle]="{ color: 'var(--primary-color)' }"
                                           class="hover:text-primary-700 cursor-pointer no-underline">
                                            {{item.id}}
                                        </a>
                                        )
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </p-card>
</div>

<form *ngIf="!loading && (mode === 'edit' || mode === 'create')" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="p-grid">
        <!-- Description -->
        <div class="p-col-12">
            <div class="field">
                <label for="description">Description</label>
                <div class="p-grid">
                    <div class="p-col-8">
                        <div class="p-inputgroup">
                            <input id="description" type="text" pInputText formControlName="description" class="w-full">
                            <button pButton pRipple ariaLabel="Clear Field" icon="pi pi-minus" tabindex="-1"
                                class="p-button-text p-button-danger" (click)="form.get('description')?.setValue('')">
                            </button>
                        </div>
                    </div>
                    <div class="p-col-4">
                        <p-dropdown formControlName="scheduleType"
                                  placeholder="Select Schedule Type"
                                  [appendTo]="'body'"
                                  [options]="scheduleTypeOptions"
                                  class="w-full">
                        </p-dropdown>
                    </div>
                </div>
                <small class="p-error" *ngIf="isFieldInvalid('scheduleType')">
                    {{ getErrorMessage('scheduleType') }}
                </small>
            </div>
        </div>

        <!-- Schedule References -->
        <div class="p-col-12">
            <div class="field" formArrayName="scheduleRefs">
                <label>Scheduled Records</label>
                <hr class="p-mb-4">
                <div class="schedule-refs-container refsContainer">
                    <div *ngFor="let ref of scheduleRefs.controls; let i=index" [formGroupName]="i" class="mb-3">
                        <div class="p-grid">
                            <!-- Reference Entity -->
                            <div class="p-col-4">
                                <p-dropdown [options]="availableEntities" 
                                             formControlName="referenceEntity"
                                             [appendTo]="'body'" 
                                             (onChange)="onReferenceEntityChange(i)"
                                             placeholder="Select Entity" 
                                             optionLabel="label"
                                             optionValue="value"
                                             class="w-full">
                                </p-dropdown>
                            </div>

                            <!-- Reference ID -->
                            <div class="p-col-7">
                                <ng-container [ngSwitch]="getSelectedEntityType(i)">
                                    <ng-container *ngSwitchCase="'Perk'">
                                        <div class="perk-list-container">
                                            <p-multiSelect 
                                                #multiSelect
                                                [options]="getFilteredItems(i)"
                                                [optionLabel]="'name'"
                                                [filter]="true"
                                                [showClear]="true"
                                                [appendTo]="'body'"
                                                [showHeader]="false"
                                                placeholder="Search and Add Perks"
                                                (onChange)="onPerksMultiSelect($event, i)"
                                                [selectedItemsLabel]="'{0} perks selected'"
                                                [model]="scheduleRefs.at(i).get('referenceId')?.value"
                                                class="w-full mb-2">
                                                <ng-template let-item pTemplate="item">
                                                    <div class="flex align-items-center gap-2">
                                                        <span>{{ item.name }} ({{ item.id }})</span>
                                                    </div>
                                                </ng-template>
                                            </p-multiSelect>

                                            <div class="perk-list">
                                                <div *ngFor="let perk of scheduleRefs.at(i).get('referenceId')?.value || []; let perkIndex = index"
                                                     class="perk-item"
                                                     [pDraggable]="'perks'"
                                                     [dragHandle]="'.drag-handle'"
                                                     (onDragStart)="onDragStart(perkIndex)"
                                                     (onDragEnd)="onDragEnd()"
                                                     [pDroppable]="'perks'"
                                                     (onDrop)="onDrop(i, perkIndex)">
                                                    <div class="flex align-items-center justify-content-between gap-2 p-3">
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="pi pi-bars drag-handle cursor-move"></i>
                                                            <span>{{ perk.name }} (
                                                                <a [href]="'/perks/' + perk.id" 
                                                                   target="_blank"
                                                                   class="perk-link"
                                                                   (click)="$event.stopPropagation()">
                                                                    {{ perk.id }}
                                                                </a>
                                                            )</span>
                                                        </div>
                                                        <i class="pi pi-times cursor-pointer" (click)="removePerk(i, perk)"></i>
                                                    </div>
                                                    <div class="drop-indicator" *ngIf="draggedItemIndex !== perkIndex"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <p-autoComplete [suggestions]="getFilteredItems(i)"
                                            (completeMethod)="filterItems($event, i)" 
                                            [field]="'name'"
                                            [style]="{ width: '100%' }" 
                                            [inputStyle]="{ width: '100%' }" 
                                            [appendTo]="'body'"
                                            [forceSelection]="true" 
                                            formControlName="referenceId" 
                                            [multiple]="true"
                                            minLength="2" 
                                            [dataKey]="_id" 
                                            placeholder="Search items">
                                            <ng-template let-item pTemplate="item">
                                                {{ item.name }} ({{ item.id }})
                                            </ng-template>
                                            <ng-template let-item pTemplate="selectedItem">
                                                {{ item.name }} ({{ item.id }})
                                            </ng-template>
                                        </p-autoComplete>
                                    </ng-container>
                                </ng-container>
                            </div>

                            <!-- Remove Button -->
                            <div class="p-col-1">
                                <button pButton type="button" icon="pi pi-trash" class="p-button-danger p-button-sm"
                                    (click)="removeScheduleRef(i)">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <!-- Add Schedule Ref Button -->
                <button pButton type="button" label="Add Record" icon="pi pi-plus"
                    class="p-button-secondary p-button-sm mt-2" (click)="addScheduleRef()">
                </button>
            </div>
        </div>

        <!-- Date Fields -->
        <div class="p-col-6">
            <div class="field">
                <label for="start">Start Date</label>
                <div class="p-inputgroup">
                    <p-calendar formControlName="start" [showTime]="true" [yearNavigator]="true" yearRange="2020:2030"
                        [monthNavigator]="true" [selectOtherMonths]="true"
                        [appendTo]="'body'" (onShow)="onCalendarShow('start')">
                        <ng-template pTemplate="header">
                            <div class="p-d-flex p-jc-center">
                                <h3 class="p-m-0">Start Date</h3>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <div class="p-d-flex p-jc-center p-mt-2 gap-2">
                                <button pButton type="button" 
                                        label="Reset Time" 
                                        icon="pi pi-clock"
                                        class="p-button-text p-button-sm"
                                        (click)="resetTimeToMidnight('start')">
                                </button>
                                <button pButton type="button" 
                                        label="Today" 
                                        icon="pi pi-calendar"
                                        class="p-button-text p-button-sm"
                                        (click)="setToToday('start')">
                                </button>
                                <button pButton type="button" 
                                        label="Clear" 
                                        icon="pi pi-times"
                                        class="p-button-text p-button-sm p-button-danger"
                                        (click)="clearDate('start')">
                                </button>
                            </div>
                        </ng-template>
                    </p-calendar>
                    <button pButton pRipple ariaLabel="Clear Field" icon="pi pi-minus" tabindex="-1"
                        class="p-button-text p-button-danger" (click)="form.get('start')?.setValue(null)">
                    </button>
                </div>
                <small class="p-error" *ngIf="isFieldInvalid('start')">
                    {{ getErrorMessage('start') }}
                </small>
            </div>
        </div>

        <div class="p-col-6">
            <div class="field">
                <label for="end">End Date</label>
                <div class="p-inputgroup">
                    <p-calendar formControlName="end" [showTime]="true" [yearNavigator]="true" yearRange="2020:2030"
                        [monthNavigator]="true" [selectOtherMonths]="true"
                        [appendTo]="'body'" (onShow)="onCalendarShow('end')">
                        <ng-template pTemplate="header">
                            <div class="p-d-flex p-jc-center">
                                <h3 class="p-m-0">End Date</h3>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="footer">
                            <div class="p-d-flex p-jc-center p-mt-2 gap-2">
                                <button pButton type="button" 
                                        label="Reset Time" 
                                        icon="pi pi-clock"
                                        class="p-button-text p-button-sm"
                                        (click)="resetTimeToMidnight('end')">
                                </button>
                                <button pButton type="button" 
                                        label="Today" 
                                        icon="pi pi-calendar"
                                        class="p-button-text p-button-sm"
                                        (click)="setToToday('end')">
                                </button>
                                <button pButton type="button" 
                                        label="Clear" 
                                        icon="pi pi-times"
                                        class="p-button-text p-button-sm p-button-danger"
                                        (click)="clearDate('end')">
                                </button>
                            </div>
                        </ng-template>
                    </p-calendar>
                    <button pButton pRipple ariaLabel="Clear Field" icon="pi pi-minus" tabindex="-1"
                        class="p-button-text p-button-danger" (click)="form.get('end')?.setValue(null)">
                    </button>
                </div>
                <small class="p-error" *ngIf="isFieldInvalid('end') || form.errors?.['dateRange']">
                    {{ getErrorMessage('end') }}
                </small>
            </div>
        </div>

        <!-- Environment -->
        <div class="p-col-6">
            <div class="field">
                <label for="env">Environment</label>
                <p-multiSelect id="env" formControlName="env" [options]="envOptions" defaultLabel="Select Environments"
                    [appendTo]="'body'" class="w-full">
                </p-multiSelect>
            </div>
        </div>

        <!-- Submit Button -->
        <div class="p-col-12">
            <div class="flex justify-content-end gap-2">
                <button pButton type="button" label="Cancel" class="p-button-secondary" (click)="onCancel()">
                </button>
                <button pButton type="submit" [label]="mode === 'edit' ? 'Update' : 'Save'" [disabled]="!form.valid" class="p-button-primary">
                </button>
            </div>
        </div>
    </div>
</form>