import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { clone, cloneDeep } from 'lodash';
import { firstValueFrom } from 'rxjs';


@Component({
  selector: 'app-nurture-stages-input',
  templateUrl: './nurture-stages-input.component.html',
  styleUrls: ['./nurture-stages-input.component.sass']
})
export class NurtureStagesInputComponent implements OnInit {
  @Input() record: any;
  @Input() stages: any;
  @Input() costs: any;
  @Input() nurtureItem: any;
  @Input() options: any;
  @Input() category: any;
  @Input() climate: any;
  @Output() onChange: any = new EventEmitter<any>();
  @Output() onGenerateStages: any = new EventEmitter<any>();
  originalStages: any;
  originalCosts: any;
  generateStagesModalView: boolean = false;

  constructor(
    private httpService: HttpClient,
  ) { }

  ngOnInit(): void {
    this.originalStages = cloneDeep(this.stages)
    this.originalCosts = cloneDeep(this.costs)
    console.log(this.nurtureItem)
    if(this.stages.length > 0 && !this.costs) {
      this.costs = [];
      for(let i = 0; i < this.stages.length; i++) {
        this.costs.push([])
      }
    }
  }

  pushNewStage(event: any) {
    this.costs.push([])
    this.stages = [...this.stages, {stage: null}]
    console.log(this.stages, this.costs)
    this.onRecordChange('stages_ref');
    this.onRecordChange('costs_ref');
  }

  onRecordChange(field: any) {
    console.log(field, this.stages,this.costs)
    console.log({ field: field, value: field == 'stages_ref' ? this.stages : this.costs })
    this.onChange.emit({ field: field, value: field == 'stages_ref' ? this.stages : this.costs });
  }

  onReorder(event: any) {
    const index: number = this.originalStages.indexOf(event[0]);
    const elementToMove: string = this.originalCosts[index];
    this.costs.splice(index, 1);

    const newPosition: number = this.stages.indexOf(event[0]);
    this.costs.splice(newPosition, 0, elementToMove);


    this.originalStages = cloneDeep(this.stages);
    this.originalCosts = cloneDeep(this.stages);

    this.onRecordChange('stages_ref');
    this.onRecordChange('costs_ref');
  }

  // add remove stage
  removeStage(index: number) {
    this.stages.splice(index, 1);
    this.costs.splice(index, 1);
    this.onRecordChange('stages_ref');
    this.onRecordChange('costs_ref');
  }

  generateStagesModal() {
    this.generateStagesModalView = true;
  }

  async generateStages() {

    console.log('record: ', this.record);

    let climate = this.climate && this.climate[0] && this.climate[0]?.name ? this.climate[0].name : 'Arid'
    let category = this.category && this.category.name ? this.category.name : 'Small Plant'

    console.log(' Climate: ', climate, ' Category: ', category)

    let generatedStages = await firstValueFrom(this.httpService.post<any>('/api/nurture-stage/generate-stages', {name: this.nurtureItem.name, category: category}))

    let stages: string[] = [
    '63f47b1adc85d4802de05cdf',
    '63f47b1adc85d4df69e05cf2',];

    let costs: any[][] = [
      [{
        t: {_id: '617b62958e2f0e4a67f86c76', name: 'Currency', id: 1},
        id: {_id: '63e61d4a20bd481c2ae19ef4', name: "Water"},
        c: 1,
        lineItemType: 'Currency',
    }],
    [{
        t: {_id: '617b62958e2f0e4a67f86c76', name: 'Currency', id: 1},
        id: {_id: '63e61d4a20bd481c2ae19ef4', name: "Water"},
        c: category === 'Large Plant' ? 3 : (category === 'Medium Plant' ? 2 : 1),
        lineItemType: 'Currency',
    }],
    ];

    switch (climate) {
        case 'Arid':
        case 'Arid Climate':
            stages.push('63f47b1bdc85d47cf8e05d12');
            costs.push([{
                t: {_id: '617b62958e2f0e4a67f86c76', name: 'Currency', id: 1},
                id: {_id: '63e61d4a20bd481c2ae19ef4', name: "Water"},
                c: category === 'Small Plant' ? 2 : 3,
                lineItemType: 'Currency',
            }]);
            break;

        case 'Temperate':
        case 'Temperate Climate':
            stages.push('63f47b1adc85d42c40e05d02');
            costs.push([{
                t: {_id: '617b62958e2f0e4a67f86c76', name: 'Currency', id: 1},
                id: {_id: '63e61d4a20bd481c2ae19ef4', name: "Water"},
                c: category === 'Small Plant' ? 2 : 3,
                lineItemType: 'Currency',
            }]);
            break;

        case 'Tropical':
        case 'Tropical Climate':
            stages.push('63f47b1bdc85d42c3ae05d22');
            costs.push([{
                t: {_id: '617b62958e2f0e4a67f86c76', name: 'Currency', id: 1},
                id: {_id: '63e61d4a20bd481c2ae19ef4', name: "Water"},
                c: category === 'Small Plant' ? 2 : 3,
                lineItemType: 'Currency',
            }]);
            break;

        default:
            console.log('Invalid climate or category.');

    }
    if(generatedStages && generatedStages.length > 0) {
      stages = stages.concat(generatedStages);
      
      const costAmount = category === 'Large Plant' ? 4 : (category === 'Medium Plant' ? 3 : 3);
      costs = costs.concat([[{
        t: {_id: '617b62958e2f0e4a67f86c76', name: 'Currency', id: 1},
        id: {_id: '63e61d4a20bd481c2ae19ef4', name: "Water"}, 
        c: costAmount,
        lineItemType: 'Currency',
      }]]);

    }

    // Now you can use the generated stages and costs as needed
    this.stages = stages;
    this.costs = costs;

    this.onRecordChange('stages_ref');
    this.onRecordChange('costs_ref');

    this.onGenerateStages.emit();
    this.generateStagesModalView = false;
}




}
