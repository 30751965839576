import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Currency DTO
 */
export default class ChallengeCollectionDto extends BaseEntityDto 
{
    /**
     * Name of the currency
     */
    public name: string;
    /**
     * Plural name of currency
     */
    public internal_notes?: string;
    /**
     * Image path
     */
    public challenges_ref: Array<any> = [];
    /**
     * Current user data
     */
    public userData: any;
    /**
     * List of tags
     */
    public tags: Array<string> = [];
  }