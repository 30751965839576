import { Component, OnInit } from '@angular/core';
import { SocialUser } from '@abacritt/angularx-social-login';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { GamedataService } from '../services/gamedata.service';
import { SettingsService } from 'src/app/entities/settings/services/settings.service';

@Component({
  selector: 'app-prod-gamedata-push',
  templateUrl: './prod-gamedata-push.component.html',
  styleUrls: ['./prod-gamedata-push.component.sass'],
  providers: [ConfirmationService],
})
export class ProdGamedataPushComponent implements OnInit
{
  isLoading: boolean = false;
  currentUser: SocialUser;
  cmsSettings: any;

  constructor
  (
    private loggerService: LoggerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private authService: AuthService,
    private gamedataService: GamedataService,
    private settingsService: SettingsService,
  ) { }

  /**
   * Prdo Gamedata Push Component Initialization
   */
  async ngOnInit()
  {
    let userResult = this.authService.getSocialUser();
    let settingsResult = await this.settingsService.getSettingByQuery({ query: { name: 'cms-settings' }});
    if(settingsResult)
    {
      this.cmsSettings = settingsResult;
    }
    this.currentUser = userResult.currentUser;
  }

  /**
   * Confirm Prod Gamedata Push to prod
   */
  async confirmProdGamedataPush()
  {
    this.confirmationService.confirm(
    {
      message: 'Are you sure that you want to promote gamedata to prod?',
      header: 'Promote Gamedata to Prod',
      icon: 'pi pi-exclamation-triangle',
      accept: async () =>
      {
        await this.promoteToProd();
      },
      reject: () =>
      {
        return;
      }
    });
  }

  async confirmDiffGamedata(){
    this.confirmationService.confirm(
      {
        message: 'Do you want to continue to compare current QA and PROD gamedata?',
        header: 'Compare QA with Prod',
        icon: 'pi pi-database',
        accept: async () =>
        {
          await this.executeGamedataDiff();
        },
        reject: () =>
        {
          return;
        }
      });
  }

  /**
   * Promotes gamedata to prod env.
   */
  async promoteToProd()
  {
    this.isLoading = true;
    try
    {
      let result = await this.gamedataService.promoteToProd({ user: this.currentUser });
      if(result && result.Success)
      {
        this.loggerService.log('Prod push successful!');
        this.messageService.add
        (

          {
            sticky: true,
            severity: 'success',
            summary: 'Promotion Successful',
            detail: `Promotion to prod was successful!`,
          }
        );
      }
      else if(result && result.isOnHold)
      {
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'info',
            summary: 'Promotions are On Hold',
            detail: `Prod Pushes are on hold.`,
          }
        );
      }
      else
      {
        this.loggerService.log('Prod push error.');
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Promotion Error',
            detail: `Promotion to prod error.`,
          }
        );
      }
    }
    catch (errorResponse: any)
    {
      this.loggerService.error("Prod push error: ", errorResponse);
      this.loggerService.log('Prod push error.');
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'Promotion Error',
          detail: errorResponse && errorResponse.error &&  errorResponse.error.error ? errorResponse.error.error : `Promotion to prod error.`,
        }
      );
    }
    this.isLoading = false;
  }

  /**
   * executeGamedataDiff
   */
  async executeGamedataDiff()
  {
    this.isLoading = true;
    try
    {
      let result = await this.gamedataService.gamedataDiff({ user: this.currentUser });
      if(result)
      {
        this.loggerService.log('Diff generated successfully!');
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'success',
            summary: 'Diff Successful',
            detail: `Diff generation was successful!`,
          }
        );
        setTimeout(() => {
          window.open(`/gamedata/diff/${result.id}`, "_blank");
        }, 200);
      }
      else
      {
        this.loggerService.log('Diff tool error.');
        this.messageService.add
        (
          {
            sticky: true,
            severity: 'error',
            summary: 'Diff Tool Error',
            detail: `Diff tool error.`,
          }
        );
      }
    }
    catch (errorResponse: any)
    {
      this.loggerService.error("Diff tool error: ", errorResponse);
      this.loggerService.log('Diff tool error.');
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'Diff tool Error',
          detail: `Diff tool could not execute`,
        }
      );
    }
    this.isLoading = false;
  }
}
