import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseDynamicViewComponent } from '../../../dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { FieldTransformerService } from '../dynamic-entity-form-component/services/field-transformer.service';
import { DynamicCardType } from '../../enums/DynamicCardType';
import { DynamicViewType } from '../../../dynamic-view-v2/enums/DynamicViewType';
import { BaseViewFieldType } from '../../../dynamic-view-v2/enums/BaseViewFieldType';
import BaseEntityDto from '../../../../dtos/BaseEntityDto';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-dynamic-entity-view-component',
  templateUrl: './dynamic-entity-view-component.component.html',
  styleUrls: ['./dynamic-entity-view-component.component.sass'],
  providers: [MessageService]
})
export class DynamicEntityViewComponentComponent extends BaseDynamicViewComponent implements OnInit {
  
  dynamicEntityRecord: BaseEntityDto = new BaseEntityDto();
  type: string;
  isLoading: boolean = true;
  options: any = [];
  fields: any = [];
  recordId: string;
  hasError: boolean = false;

  constructor(
    private http: HttpClient,
    private fieldTransformer: FieldTransformerService,
    private route: ActivatedRoute,
    private dataService: DataService,
    private messageService: MessageService
  ) {
    super();
  }

  async ngOnInit() {
    try {
      // Get entity type and id from URL /d/testeventtwo/view/123
      this.route.params.subscribe(async params => {
        this.type = params['entityName'];
        this.recordId = params['id'];
        await this.loadEntityData();
      });
    } catch (error) {
      this.handleError('Error initializing component', error);
    }
  }

  private async loadEntityData() {
    try {
      // Fetch metadata fields
      const metadataFields = await this.http
        .get<any[]>(`/api/metadata/${this.type}/fields`)
        .toPromise();

      if (metadataFields) {
        // Transform the fields into the correct format for viewing
        const transformedFields = this.fieldTransformer.transformDynamicEntityViewFields(metadataFields);
        this.fields = transformedFields;

        // Fetch the record data using DataService
        if (this.recordId) {
          try {
            const recordData = await this.dataService.findOneDynamic(this.type, {
              query: { id: this.recordId },
              autopopulate: true,
              virtuals: true
            });

            console.log('Record data:', recordData);
              
            if (recordData) {
              this.dynamicEntityRecord = recordData;
              this.messageService.add({
                severity: 'success',
                summary: 'Data Loaded',
                detail: `Successfully loaded ${this.type} record`
              });
            }
          } catch (recordError) {
            this.handleError('Error fetching record data', recordError);
          }
        }
      }

      this.title = `View ${this.type}`;
      this.viewRoute = this.type;
      this.isLoading = false;
    } catch (error) {
      this.handleError('Error fetching metadata', error);
    }
  }

  private handleError(context: string, error: any) {
    console.error(context, error);
    this.hasError = true;
    this.isLoading = false;
    
    const errorMessage = error.status === 403 
      ? (error.error?.error || error.statusText) 
      : error.message || 'An unexpected error occurred';

    this.messageService.add({
      severity: 'error',
      summary: context,
      detail: errorMessage,
      life: 5000
    });
  }
}
