/**
 * Base DTO properties
 */
export default class BaseEntityDto
{
  /**
   * Flag that sets whther or not the entity is enabled
   */
  enabled: boolean = false;
  /**
   * Start date value
   */
  start?: Date | string | any;
  /**
   * End date value
   */
  end?: Date | string | any;
  /**
   * Moderation value
   */
  moderation?: any;
  /**
   * User that created the entity
   */
  createdBy?: any;
  /**
   * User data tied to the entity
   */
  userData?: Object | any;
  /**
   * Id of the entity
   */
  id?: any = null;
  /**
   * Mongo Entity Id
   */
  _id?: any = null;
  /**
   * Name of the record
   */
  name?: string | any;
  /**
   * List of environments
   */
  env?: Array<string> = [];
}
