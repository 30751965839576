import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-keywords-view-v2',
  templateUrl: './keywords-view-v2.component.html',
  styleUrls: ['./keywords-view-v2.component.sass']
})
export class KeywordsViewV2Component extends BaseDynamicViewComponent implements OnInit {
  keywordRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  infiniteGardenDisplayControls(value: string, fieldName: string): boolean {
    return value === 'true';
  }

  ngOnInit(): void {
    this.fields = [
      {
        title: "Keyword Data",
        fields: [
          {
            name: "Name",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            columnWidth: 4
          },
          {
            name: "Description",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'description',
            label: 'Description',
            columnWidth: 8
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Infinite Garden Exclusion",
        fields: [
          {
            name: "isInfiniteGardeningActiveLabel",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Title],
            key: 'isInfiniteGardeningActiveLabel',
            label: 'Is Infinite Garden Active?:',
            columnWidth: 12,
            tagType: 'h4'
          },
          {
            name: "Infinite Garden Active",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            clearField: true,
            key: 'isInfiniteGardeningActive',
            emitOnInit: true,
            label: 'Infinite Garden Active',
            columnWidth: 2,
            booleanoptions: {
              true: "Active",
              false: "Disabled"
            },
            displayControlType: "showHide",
            displayControls: {
              ['isInfiniteGardeningExluded']: (value: string) => this.infiniteGardenDisplayControls(value, 'isInfiniteGardeningExluded'),
              ['isInfiniteGardeningExludedLabel']: (value: string) => this.infiniteGardenDisplayControls(value, 'isInfiniteGardeningExludedLabel'),
            }
          },
          {
            name: "isInfiniteGardeningExludedLabel",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Title],
            key: 'isInfiniteGardeningExludedLabel',
            label: 'Infinite Garden Keyword Type:',
            columnWidth: 3,
            tagType: 'h4',
            showField: false
          },
          {
            name: "Infinite Garden Exclusion",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            clearField: true,
            key: 'isInfiniteGardeningExluded',
            label: 'Infinite Garden Exclusion',
            columnWidth: 3,
            showField: false,
            booleanoptions: {
              true: "Include",
              false: "Exclude"
            }
          }
        ],
        type: DynamicCardType.Card
      },
    ];
    this.title = "Keyword Details";
    this.viewRoute = 'keyword';
    this.isLoading = false;
  }
}
