<div class="p-d-flex p-ai-center p-jc-center">
  <div *ngIf="!params.onClick && params.isRefLink && refLinkValue; else notRefLink" style="line-height: 1.3;">
    <a *ngFor="let link of refLinkValue; let i = index"
        href="{{getHrefValue(link)}}"
        target="_blank" class="p-text-normal" [ngStyle]="{ color: 'var(--primary-color)' }"
    >
      Link
      <br>
    </a>
  </div>
  <ng-template #notRefLink>
    <a
      *ngIf="!params.onClick"
      target="_blank" class="p-text-normal p-d-block"
      [ngStyle]="{ color: 'var(--primary-color)' }"
      href="{{ getLink() }}" target="_blank"
    >
      {{params.value}}
    </a>
    <a
      *ngIf="params.onClick"
      target="_blank" class="p-text-normal p-d-block"
      [ngStyle]="{ color: 'var(--primary-color)' }"
      href="#" target="_blank" (click)="handleClick($event, params.data)"
    >
      {{params.value}}
    </a>
  </ng-template>
</div>
