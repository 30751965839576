<p-toast (onClose)="onToastClose($event)"></p-toast>

<dynamic-form-v2 
    *ngIf="!isLoading && !hasError" 
    [entityType]=type
    [formName]="title" 
    [(record)]="dynamicEntityRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [isDynamicEntity]="true"
>
</dynamic-form-v2>

<div *ngIf="hasError" class="error-container" style="padding: 2rem; text-align: center;">
    <p-card>
        <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-ai-center p-p-4">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem; color: var(--red-500);"></i>
                <h2>Configuration Required</h2>
            </div>
        </ng-template>
        <p>{{ errorMessage }}</p>
        <ng-template pTemplate="footer">
            <button pButton label="Create Metadata" icon="pi pi-plus" 
                    class="p-button-primary" 
                    (click)="router.navigate(['/dynamic-entities'])">
            </button>
        </ng-template>
    </p-card>
</div>