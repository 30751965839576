import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-misc-ref',
  templateUrl: './misc-ref.component.html',
  styleUrls: ['./misc-ref.component.sass']

})
export class MiscRefComponent {
  @Input() record: any; // Assuming that record is an object containing the data
  @Input() field: any; // Assuming that field is an object containing the field's configuration like label and key


  constructor() {}

  ngOnInit(): void {
    console.log('field', this.field);
  }

  getPluralizedName(name: string): string {
    if (!name) return 'N/A';

    // Basic pluralization rules
    if (name.endsWith('y')) {
      return name.slice(0, -1) + 'ies';
    } else if (name.endsWith('h')) {
      return name + 'es';
    } else {
      return name
    }
  }
  isCoOpView(): boolean {
    return window.location.pathname.includes('co-op'); 
  }
}
