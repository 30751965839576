import { Pipe, PipeTransform, Sanitizer, SecurityContext } from '@angular/core';

@Pipe({
  name: 'insertImage'
})
export class InsertImagePipe implements PipeTransform {

  constructor(private sanitizer: Sanitizer) {}

  transform(value: string, imageUrl: string): any {
    if (!value) return value;
    const imageTag = `<img src="${imageUrl}" alt="Embedded Image" style="max-width: 100%; height: auto;">`;
    const newValue = value.replace(/\{\{image\}\}/g, imageTag);
    return this.sanitizer.sanitize(SecurityContext.HTML, newValue);
  }
}
