import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Category DTO
 */
export default class CategoryDTO extends BaseEntityDto
{
    /**
     * List of conditionals 
     */
    conditionals: Array<any> = [];
 
    /**
     * List of children reference 
     */
    children_ref: Array<any> = [];

    /**
     * Bitwise value of submitted children
     */
    children: number;
  
    /**
     * List of Types
     */
    types_ref: Array<any> = [];
  
    /**
     * Icon size
     */
    iconSize: string;
  
    /**
     * Bubble size
     */
    bubbleSize: string;
  
    /**
     * Border width
     */
    borderWidth: string;
  
    /**
     * Flag that sets whether or not is required
     */
    required: boolean;
  
    /**
     * Category's color
     */
    color: string;
  
    /**
     * Category's description
     */
    descriptionShort: String;
  
    /**
     * Colors value
     */
    colors: number;
  
    /**
     * Traits value
     */
    traits: number;
  
    /**
     * Materials value
     */
    materials: number;
  
    /**
     * Styles value
     */
    styles: number;
  
    /**
     * Patterns value
     */
    patterns: number;
  
    /**
     * Shape value
     */
    shape: number;
  
    /**
     * List of Category cllection
     */
    category_collections_ref: Array<any> = [];

    /**
     * Flag that sets whether or not is an organic.
     */
    organic: boolean;
  
    /**
     * Average coin cost
     */
    averageCoinCost: number;
  
    /**
     * Average diamond cost
     */
    averageDiamondCost: number;
  
    /**
     * Expected diamond spend
     */
    expectedDiamondSpend: number;
  
    /**
     * Expected coin spend
     */
    expectedCoinSpend: number;
  
    /**
     * Path of the icon
     */
    iconPath: string;
  

}