import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
})
export class AutocompleteComponent implements OnInit {
  @Input() record: any;
  @Input() field: any;
  @Input() entityType: string;
  constructor() { }

  ngOnInit(): void {
  }
  getValueLabel(value: any, field: any){
    if(field && field.options && field.options.values[0] && field.options.values[0].items){
      const obj = field.options.values[0].items.find((item: any) => item.value === value);
      if (obj && obj.label) {
        return obj.label
      } else {
        return undefined;
      }
    } else if (field && field.options && field.options.values){
      const item = field.options.values.find((item: any) => item.value === value);
      if (item && item.name) {
        return item.name
      } else {
        return value;
      }
    }else {
      return value;
    }
  }
}
