import { Component, Input, Optional } from '@angular/core';
import { AnyARecord } from 'dns';
import { AgGridTableComponent } from '../../ag-grid-table.component';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-bulk-edit-input',
  templateUrl: './bulk-edit-input.component.html',
  styleUrls: ['./bulk-edit-input.component.css']
})
export class BulkEditInputComponent {
  @Input() editType: any;
  @Input() inputColumn: any;
  @Input() inputColumnDef: any;
  @Input() parentComponent: AgGridTableComponent;

  value: any;
  empty: [];
  private prevDate: Date;
  findValue: any;
  inputType: string;
  inputColumnIdentifier: string;
  inputName: string;
  inputOptions: any[] = [];
  optionValue: string = 'value';
  optionLabel: string = 'label';
  loadedResourceTypeOptions: boolean = false;
  loadedResourceOptions: boolean = false;
  // percentageChange
  percentageChangeOptions = [{ label: "Increase", value: "increase", icon: "fa-solid fa-plus" }, { label: "Decrease", value: "decrease", icon: "fa-solid fa-minus" }];
  percentageChangeDirection: string = 'increase';
  percentageChangeValue: number = 0;

  constructor(
    private utilitiesService: UtilitiesService
  ) { }

  async ngOnInit(): Promise<void> {

    this.inputType = this.inputColumn.type;
    this.inputColumnIdentifier = this.inputColumn.id;
    this.inputName = this.inputColumn.name;

    console.log('bulk edit input column: ',this.inputColumn);
    if(this.inputType == 'date'){
      this.value = new Date();
      this.value.setHours(0, 0, 0, 0);
    }

    if(['dropdown', 'multiselect'].includes(this.inputType)){
      this.inputOptions = this.getFilterParamsValues(this.inputColumnDef);
      if (this.inputColumn.optionValue && this.inputColumn.optionLabel) {
        this.optionValue = this.inputColumn.optionValue;
        this.optionLabel = this.inputColumn.optionLabel;
      }
      if(this.inputColumn.isFlatList){
        // this.inputOptions
        let options = this.getFilterParamsValues(this.inputColumnDef);
        this.inputOptions = options.filter((item: any) => item !== '(EMPTY)' && item !== '(NOT EMPTY)')
        .map((item: any) => {
          return { label: item, value: item };
        });
      }
    }

    if(this.inputType == 'line-items' && this.editType.value === 'line-item-resource'){
      this.value = {
        id: null,
        t: null
      }
      await this.utilitiesService.getOptionsFromRef(this.inputOptions, 't', 'resources', true, false, 'id _id name', false, 'id',{ name: { $nin : ['Titles', 'Item']} } );
      this.loadedResourceTypeOptions = true;
    }

  }

  getOptionsFromConfig(): any[] {
    if (!this.parentComponent) {
      console.warn('Parent AgGridTableComponent not found.');
      return [];
    }

    // Find the column definition by the inputColumnIdentifier
    const columnDef = this.parentComponent.columnDefs.find(col => col.field === this.inputColumnIdentifier);

    if (!columnDef || !columnDef.cellEditorParams || !columnDef.cellEditorParams.values) {
      console.warn('Options not found for column:', this.inputColumnIdentifier);
      return [];
    }

    return columnDef.cellEditorParams.values;
  }

  getValue(): any {
    if(this.editType.value == 'find-and-replace'){
      return {find: this.findValue, replace: this.value}
    } else if (this.editType.value == 'line-item-resource'){
      return {
        t: this.value.t ? this.value.t : null,
        lineItemType: this.value.t ? this.value.t.name : null,
        id: this.value.id ? this.value.id : null,
      };
    } else if (this.inputColumn.type == 'multiselect' && this.inputColumn.isFlatList) {
      return this.value.map((obj: any) => obj.value);
    } else if (this.inputColumn.isFlatList){
      return this.value['value'];
    } else if (this.editType.value == 'percentageChange') {
      return { direction: this.percentageChangeDirection, value: this.percentageChangeValue };
    } else {
      return this.value;
    }
  }

  onDateSelect(event: any): void {
    if (this.prevDate) { // Check if prevDate is initialized
      if (this.value.getDate() !== this.prevDate.getDate() ||
          this.value.getMonth() !== this.prevDate.getMonth() ||
          this.value.getFullYear() !== this.prevDate.getFullYear()) {
            // Reset time to 00:00:00
            this.value.setHours(0, 0, 0, 0);
      }
    }
    this.prevDate = new Date(this.value); // Update the previous date
  }

  getFilterParamsValues(colDef: any) {
    return colDef && colDef.filterParams && colDef.filterParams.values ? colDef.filterParams.values : [];
  }

  onPrizeChange(event: any) {
    this.value = event;
  }

  async updateDependentFieldOptions(event: any){
    this.loadedResourceOptions = false;
    console.log('updateDependentFieldOptions Event:', event);
    await this.utilitiesService.getOptionsFromRef(this.inputOptions, 'id', 'currencies', true, false);
    this.loadedResourceOptions = true;
  }

}
