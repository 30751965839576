<div class="p-grid embedded-form">
  <div class="p-col-12 p-m-0 p-py-0">
    <small class="p-ml-2">{{field.label}}</small>
  </div>
  <div class="p-col-6 p-d-flex">
    <p-dropdown
      [options]="options"
      [(ngModel)]="selectedValue"
      id="entityDropdown"
      [placeholder]="dropdownPlaceholder"
      [optionLabel]="field.optionLabel"
      [filter]="field.filter"
      [filterBy]="field.filterBy"
      (onChange)="onEntityChange($event)"
      class="p-mr-2 p-flex-grow-1"
      [ngStyle]="{'width':'90%'}"
    >
      <ng-template pTemplate="selectedItem">
          <div class="" *ngIf="selectedValue">
              <div>{{selectedValue.name}} ({{selectedValue.id}})</div>
          </div>
      </ng-template>
      <ng-template let-record pTemplate="item">
          <div class="">
              <div>{{record.name}} ({{record.id}})</div>
          </div>
      </ng-template>
    </p-dropdown>
    <p-button icon="pi pi-minus" class="ui-button-warning" (onClick)="clearSelection()" [disabled]="!selectedValue"></p-button>
  </div>
  <div class="p-col-6 p-d-flex">
    <p-divider layout="vertical" class="p-mr-3" styleClass="hidden"></p-divider>
    <p-button label="Create New"  icon="pi pi-plus" (onClick)="createNewEntity()" [disabled]="selectedValue" styleClass="p-button-primary p-mr-3"></p-button>
    <p-button label="Edit Selected" icon="pi pi-pencil" (onClick)="editSelectedEntity()" [disabled]="!selectedValue" styleClass="p-button-warning"></p-button>
  </div>
  <div *ngIf="showForm" class="embeddedFormContainer" [@expandCollapse]>
    <dynamic-form-v2
      [isEmbedded]="true"
      [embeddedType]="embeddedType"
      [entityType]="field.options.apiController"
      [formName]="field.options.formName"
      [(record)]="selectedValue"
      [fields]="field.embeddedFormFields"
      [viewRoute]="field.options.viewRoute"
      (onFieldChange)="embeddedFormFieldChange($event)"
      [submitEmbededForm]="submitEmbeddedFormEvent"
      (formSubmitted)="onChildFormSubmitted($event)">
    >
    </dynamic-form-v2>
  </div>
</div>
