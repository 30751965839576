<div class="card button-spaces" *ngIf="authService.hasPermission(['create'], 'RoleController')">
  <p-toast></p-toast>
  <p-toolbar styleClass="p-mb-4">
    <ng-template pTemplate="left">
      <button
        pButton
        pRipple
        label="Add new role"
        icon="pi pi-plus"
        class="p-button-success"
        styleClass="p-mr-2"
        (click)="showAddEditNewRole = true"
      ></button>
    </ng-template>
    <ng-template pTemplate="right"> </ng-template>
  </p-toolbar>
</div>

<!-- Dialogs -->

<!-- Add New Role -->
<p-dialog [(visible)]="showAddEditNewRole" [style]="{ width: '450px' }" [modal]="true" styleClass="p-fluid">
  <p-messages></p-messages>
  <ng-template pTemplate="header">
    <h3>Add New Role</h3>
  </ng-template>
  <ng-template pTemplate="content">
    <form>
      <div>
        <div class="p-field">
          <span>
            <ng-container>
            <div class="display-row p-mb-5">
              <div class="p-mr-2">{{ rolPrefix }}</div>
              <span class="p-float-label w-100">
                <input id="name" type="text" pInputText name="name" (input)="transformInput($event, 'name')"
                  [(ngModel)]="role.name" appendTo="body" (ngModelChange)="clearMessages()">
                <label for="description">Name</label>
              </span>
            </div>

            </ng-container>
            <br />
            <ng-container>
              <div class="p-mb-5">
                <span class="p-float-label">
                  <input id="description" type="text" pInputText name="description"
                    [(ngModel)]="role.description" appendTo="body" (ngModelChange)="clearMessages()">
                  <label for="description">Description</label>
                </span>
              </div>

            </ng-container>
            <br />
            <ng-container>
              <div class="p-mb-5">
                <p-multiSelect appendTo="body" [options]="permissions" [required]="true" multiselectable="true"
                  [(ngModel)]="role.permissions" placeholder="Select a Permission" optionLabel="name"
                  [showClear]="true" name="permission" optionValue="value" [filter]="true" filterBy="name"
                  (onChange)="clearMessages()"></p-multiSelect>
              </div>
            </ng-container>
          </span>
        </div>
      </div>
    </form>
  </ng-template>
  <ng-template pTemplate="footer">
    <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text"
      (click)="showAddEditNewRole = false; clearMessages()"></button>
    <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text"
      (click)="saveRole()"></button>
  </ng-template>
</p-dialog>