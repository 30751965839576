import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { map } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-async-text-cell-renderer',
  templateUrl: './async-text.component.html',
  styleUrls: ['./async-text.component.sass']
})
export class AsyncTextCellRendererComponent implements ICellRendererAngularComp {

  public maxDate: string;

  constructor(
    private tableCommunicationService: TableCommunicationService,
    private dataService: DataService
  ) {

  }


  public params: any;
  timestamp = new Date().getTime();

  ngOnInit() {
  }

  agInit(params: ICellRendererParams): void {
    this.refresh(params);
  }

  // This method will be responsible for re-rendering the cell whenever the grid is refreshed
  refresh(params: any): boolean {
    const prefabRef = params.data.prefab_ref;
    if (prefabRef && prefabRef.buildData) {
      this.dataService.getBuildData(prefabRef.buildData)
        .then(buildData => {
          let dates = [];
          for (const key in buildData) {
            if (buildData.hasOwnProperty(key)) {
              if (buildData[key].status.toLowerCase() === 'queued') {
                dates.push(buildData[key].insertedAt);
              } else if (
                buildData[key].status.toLowerCase() === 'finished' ||
                buildData[key].status.toLowerCase() === 'failed'
              ) {
                dates.push(buildData[key].finishedAt);
              } else {
                dates.push(buildData[key].startedAt);
              }
            }
          }
          if(dates.length>0){
            this.maxDate = dates.reduce((a, b) => a > b ? a : b);
          }
        })
        .catch(err => {
          console.error("An error occurred:", err);
        });
    }
    return true;
  }

}
