/**
 * Dynamic View Type Enum
 */
export enum DynamicViewType {
  // PrimeNG Types/Components
  Text = 1,
  Number,
  Switch,
  MultiSelect,
  MultiSelectObj,
  Dropdown,
  AutoComplete,
  Calendar,
  CalendarDual,
  ColorPicker,
  // Custom Components
  Price,
  MiscImageRecord,
  DropdownDependent,
  Link,
  NameLink,
  MultiList,
  MultiLink,
  NestedList,
  MiscRef,
  MiscImageRecordV2,
  TableView,
  MiscImageBuildComponent,
  sideThumbnail,
  multiSideThumbnail,
  NurtureStagesField,
  RenderThumbnails,
  VideoPreview,
  VideoPreviewPath,
  CategoryIcons,
  // ENDLESS OFFERS
  // INPUT
  // VIEW
  EndlessOfferTrackViewComponent,
  EndlessOfferTrackV2,
  InputTextArea,
  NestedInputComponent,
  GoalImageComponentComponent,
  SpaceCol,
  ToggleButton,
  AgGridTable,
  goalImageSideThumbnail,
  Boolean,
  FilterRules,
  BaseViewField,
  Title,
  MiscAssetType,
  MiscImageArrayBuild,
  InGamePreview,
  JobsRecordTable,
  RewardTrack,
  deepMultiSideThumbnail,
  sideThumbnailEnhanced,
  RewardTimeline,
  MiscAssetBuildErrorLogs
}
