<dynamic-form-v2 
    *ngIf="!isLoading" 
    entityType="season-pass" 
    [formName]="title" 
    [(record)]="seasonPassRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [showBottonSubmit]="true"
    [isNotesPopUp]="true"
>
</dynamic-form-v2>