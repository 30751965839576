import { Component, Input, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { BuildService } from 'src/app/build-data/services/build.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { BuildType } from 'src/app/enums/build-type';
import { DataService } from 'src/app/services/data.service';
import { ArtToolsService } from 'src/app/tools/art/services/art-tools.service';
import { AssetTypes } from '../../enums/AssetTypes';
import { ItemService } from '../services/item.service';
import { ItemValidationService } from '../services/item-validation.service';
import { primaryCard, secondaryCard } from '../data/constants';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { OptionsParams } from 'src/app/common/services/utilities.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-item-view-v2',
  templateUrl: './item-view-v2.component.html',
  styleUrls: ['./item-view-v2.component.sass']
})

export class ItemViewV2Component implements OnInit {



  @Input() id: any;
  entity: any;
  type: string = 'items';
  page: string = 'view';
  areEnvValuesOk: boolean = true;
  buildAndRender: boolean = true;
  view: any[] = [900, 400];

  // options
  legend: boolean = true;
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = false;
  yAxis: boolean = true;
  showYAxisLabel: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Build';
  yAxisLabel: string = 'Time (min)';
  timeline: boolean = false;
  autoScale: boolean = true;

  imgData: any = {};
  showImgDialog: boolean = false;
  displayLocalizedValues: boolean = false;
  localizedValues: any;
  contentHolds: any = [
    'Bundle Hold',
    'New Arrivals Hold',
    'Prize Hold',
    'Progression Hold',
    'Series Hold',
    'Do Not Use'
  ]
  colorScheme = {
    domain: [
      '#5AA454',
      '#E44D25',
      '#CFC0BB',
      '#7aa3e5',
      '#a8385d',
      '#aae3f5',
      '#925ef2',
    ],
  };

  headerFieldGroup: any[] =
  [
    {
      name: 'Cultivar',
      key: 'cultivar',
      value: '',
      controlType: 'inputText',
    },
    {
      name: 'Latin Name',
      key: 'latinName',
      value: '',
      controlType: 'inputText',
    },
    {
      name: 'Plant Family',
      key: 'plantFamily',
      value: '',
      controlType: 'inputText',
    },
  ];

  mainStatusGroup: any[] =
  [
    {
      name: 'Enabled',
      key: 'enabled',
      value: '',
      controlType: 'toggle',
    },
    {
      name: 'Art Status',
      key: 'vendorStatus',
      value: '',
      controlType: 'dropdown',
    },
    {
      name: 'Content Status',
      key: 'itemStatus',
      value: '',
      controlType: 'dropdown',
    },
  ];
  secondaryStatusGroup: any[] =
  [
    {
      name: 'Environment(s)',
      key: 'env',
      controlType: 'envs',
    },
    {
      name: 'Build Status',
      key: 'prefab_ref',
      controlType: 'buildStatus',
    },
    {
      name: 'Thumbnail Build Status',
      key: 'thumbnail_ref',
      controlType: 'buildStatus',
    },
    {
      name: 'Linked Prize',
      key: 'isReward',
      value: '',
      controlType: 'toggle',
    },
    {
      name: 'Spruce Data Status',
      key: 'spruceDataStatus',
      value: '',
      controlType: 'toggle',
    },
  ];

  // Primary and Secondary Cards. These are defined in constants.ts.

  cards: any[] = primaryCard;
  secondaryCard: any = secondaryCard

  get buildType(): typeof BuildType {
    return BuildType;
  }
  get assetType(): typeof AssetTypes {
    return AssetTypes;
  }

  // spruce preview

  categoryPreviewName: any;
  options: any = [];

  plantPreview: any = {
    latinName: '',
    category: '',
    type: '',
    colors: { names: [] },
    climates: { names: [] },
    traits: { names: [] },
    hardinessZones: '',
    nativeArea: '',
    sunExposure: '',
    bloomTime: '',
    description: '',
    blurb: '',
    cost: '',
  };
  isLoading: boolean = true;

  s3Cdn: string = 'https://d3tfb94dc03jqa.cloudfront.net/';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private loggerService: LoggerService,
    private titleService: Title,
    private dataService: DataService,
    public itemService: ItemService,
    private itemValidationService: ItemValidationService,
    private commonEntityService: CommonEntityService,
    private buildService: BuildService,
    private validationService: ValidationsService,
    private utilitiesService: UtilitiesService,
    private artToolsService: ArtToolsService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Check if the navigation is triggered by a popstate event,
        // indicating that the page is being restored from bfcache
        if (event.navigationTrigger === 'popstate') {
          // Force a reload or perform any other necessary actions
          location.reload();
        }
      }
    });
  }

  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    await this.fetchExistingData();
    await this.setOptions();
    this.validationService.validateEnvOrder(this.entity);
    this.isLoading = false;
  }


  async fetchExistingData() {
    if(this.id)
    {
      let record = await this.commonEntityService.findOneWithQuery(this.type, { query: { id: this.id } });
      let exportRecord

      if(record && record.id)
      {
        this.entity = record;
        this.loggerService.log('Entity', this.entity);



        // Set Page Title
        if (this.entity.name)
        {
          this.titleService.setTitle(this.entity.name);
        }

        await this.itemService.setBuildData(this.entity);

        // Asset Image Promoted Version props
        this.entity.IsAssetUpToDate = this.buildService.getPromotionStatus(this.entity.prefab_ref, BuildType.Items);
        this.entity.IsImageUpToDate = this.buildService.getPromotionStatus(this.entity.thumbnail_ref, BuildType.Images);
        this.entity.asset_versions = this.buildService.getLatestPromotedVersion(this.entity.prefab_ref, 'asset');
        this.entity.image_versions = this.buildService.getLatestPromotedVersion(this.entity.thumbnail_ref, 'image');
        this.entity.LatestAssetBuildVersion = this.buildService.getLatestBuiltVersion(this.entity.prefab_ref);
        this.entity.LatestImageBuildVersion = this.buildService.getLatestBuiltVersion(this.entity.thumbnail_ref);

        // generate renderPreview image
        this.entity.renderPreview = this.artToolsService.generateImagePath(this.entity.id,"items");

        const dimensions = ['dimensionX', 'dimensionY', 'dimensionZ', 'radius'] as const;

        dimensions.forEach((dim) => {
          if (this.entity.prefab_ref?.[dim]) {
            this.entity[dim] = this.entity.prefab_ref[dim].toFixed(3);
          }
        });

        this.entity.fileType = this.entity.itemFileType_ref?.name || this.entity.fileType;

        let envCheck = await this.itemValidationService.checkEnvFlagsRequirementsAPICall(this.id);

        if(!envCheck.devReady || !envCheck.qaReady || !envCheck.prodReady)
        {
          this.areEnvValuesOk = false;
        }

        // Spruce Card Preview conditions. Checking for nulls.
        this.plantPreview.latinName = this.entity.latinName || this.plantPreview.latinName;
        this.plantPreview.category = this.entity.category_ref?.name || this.plantPreview.category;
        this.plantPreview.type = this.entity.type_ref?.name || this.plantPreview.type;

        if (this.entity.externalPlantData_ref) {
          const {
            hardinessZone,
            nativeArea,
            sunExposure,
            bloomTime
          } = this.entity.externalPlantData_ref;

          Object.assign(this.plantPreview, { hardinessZones: hardinessZone, nativeArea, sunExposure, bloomTime });
        }

        const populateNames = (source: any[], target: string[], fallback: string) => {
          if (source && source.length > 0) {
            source.forEach(item => target.push(` ${item.name}`));
          } else {
            target = [fallback];
          }
          return target;
        };

        this.plantPreview.climates.names = populateNames(
          this.entity.climates_ref,
          this.plantPreview.climates.names,
          '🌼 Not Filled'
        );

        this.plantPreview.colors.names = populateNames(
          this.entity.colors_ref,
          this.plantPreview.colors.names,
          '🌼 Not Filled'
        );

        this.plantPreview.traits.names = populateNames(
          this.entity.traits_ref,
          this.plantPreview.traits.names,
          '🌼 Not Filled'
        );

        if (this.entity.costs_ref) {
          for (let cost of this.entity.costs_ref) {
            this.plantPreview.cost = cost.c;
          }
        }

        this.secondaryCard.fields.forEach((image: any) => {
          this.itemService.populateImgField(image);
        });

        if (this.entity.externalPlantData_ref) {
          const fileName = this.entity.externalPlantData_ref.imagePath + '.jpg';
          if (this.entity.externalPlantData_ref.imagePath) {
            this.entity.spruce_img = this.s3Cdn + fileName;
          }
        }

        for(let card of this.cards)
        {
          for(let row of card.rows)
          {
            if(row.layout && row.layout == 'columns')
            {
              row.fieldGroups = this.itemService.chunkArray(row.fieldGroups);
            }

            row.fieldGroups.forEach((group: any) =>
            {
              group.forEach((field: any) => {
                this.entity[field.key] = this.itemService.parseValueForView(
                  this.entity[field.key],
                  field,
                  this.entity
                );
              });
            });
          }
        }
      }
    }
  }


  /**
   * Updates the build data for the entity.
   */
  async updateBuildData() {
    setTimeout(async () => {
      let record = await this.commonEntityService.findOneWithQuery(this.type, { query: { id: this.id } });

      if (record) {
        this.entity.prefab_ref = record.prefab_ref;
        this.entity.thumbnail_ref = record.thumbnail_ref;
      }
      // get build data.
      await this.getBuildData();
    }, 500);
  }

    /**
    * Retrieves the build data for the entity.
   */
    async getBuildData()
    {
      if(this.entity.prefab_ref && this.entity.prefab_ref.buildData)
      {
        let response = await this.buildService.getBuildData(this.entity.prefab_ref.buildData);
        this.entity.prefab_ref.buildData = response.buildData;
        this.entity.prefab_ref.buildStatus = response.buildStatus;
      }
      else
      {
        this.entity.prefab_ref.buildData = [];
        this.entity.prefab_ref.buildStatus = {
          text: 'Build Status Not Found',
          date: '',
          color: 'var(--gray-400)',
          buildCount: 0,
        };
      }
      if(this.entity.thumbnail_ref && this.entity.thumbnail_ref.buildData)
      {
        let response = await this.buildService.getBuildData(this.entity.thumbnail_ref.buildData);
        this.entity.thumbnail_ref.buildData = response.buildData;
        this.entity.thumbnail_ref.buildStatus = response.buildStatus;
      }
      else
      {
        this.entity.thumbnail_ref.buildData = [];
        this.entity.thumbnail_ref.buildStatus = {
          text: 'Build Status Not Found',
          date: '',
          color: 'var(--gray-400)',
          buildCount: 0,
        };
      }
    }

    /** Compiles and sets global imgData, then sets showImgDialog to true.
     * @param field FieldData
     */
    displayImage(field: any) {
      if (field.key === 'thumbnail' || this.entity[field.key]) {
        const isThumbnail = field.key === 'thumbnail';
        const name = isThumbnail
          ? this.entity[field.key].replace('_256.', '_1024.')
          : this.entity[field.key].substring(this.entity[field.key].lastIndexOf('/') + 1);

        this.imgData = {
          title: field.name,
          name,
          path: this.entity[field.key].replace('_256.', '_1024.'),
        };

        if (isThumbnail || ['challenges', 'items'].includes(this.type)) {
          this.showImgDialog = true;
        }
      }
    }

  async fetchLocalized() {
    const data = await this.dataService.fetchGridlyRecord(this.id, 'items');
    this.localizedValues = this.formatData(data);
    this.displayLocalizedValues = true;
  }

  formatData(data: any[]): any[] {
    const excludedColumns = ['update_trigger', 'glossaryRes', 'start', 'translationRequest', 'verified', 'isLive'];

    return data.map(record => {
      const readableId = record.id.split('_')[1] || record.id;
      const formattedRecord: any = { id: readableId.charAt(0).toUpperCase() + readableId.slice(1) };
      record.cells.forEach((cell: any) => {
        if (!excludedColumns.includes(cell.columnId)) {
          formattedRecord[cell.columnId] = cell.value;
        }
      });
      return formattedRecord;
    });
  }

  getColumns(data: any[]): string[] {
    if (data.length === 0) {
      return [];
    }
    return Object.keys(data[0]).filter(key => key !== 'id');
  }
  
  async setOptions(){
    let collectionQuery: any = {id: 0}
    if(this.entity.collection_ref && this.entity.collection_ref.length > 0){
      let collectionIds = this.entity.collection_ref.map((collection: any) => collection.id);
      collectionQuery = { id: { $in: collectionIds } };
    }

    const entities = [
      { ref: 'collection_ref', entity: 'collections', select: 'id _id name', query: collectionQuery },
    ];

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query,
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }
  }

  
}
