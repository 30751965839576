import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import CategoryIconDTO from '../../dtos/CategoryIconDTO';
import { UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'category-icon',
  templateUrl: './category-icon.component.html',
  styleUrls: ['./category-icon.component.sass']
})
export class CategoryIconComponent implements OnInit {

  @Input() categoryIconRecord: CategoryIconDTO = new CategoryIconDTO();
  options: any = [];

  @Output() onChange = new EventEmitter<any>();

  constructor(
    private utilitiesService: UtilitiesService
  ) { }

  async ngOnInit() {

    if(this.categoryIconRecord){

      this.categoryIconRecord.icons = this.categoryIconRecord.icons ? this.categoryIconRecord.icons : [{selected: null, deselected: null, disabled: null}]
    }


    await this.utilitiesService.getOptionsFromRef(this.options, 'image_ref', 'miscellaneous-build', false, false, 'name id _id path', false, { name: 1 }, { assetType: { $in: [12] }});
  }

  onRecordChange(field: string) {
    this.onChange.emit({ field, record: this.categoryIconRecord });
  }

}
