<div class="card" *ngIf="rows">
  <p-accordion *ngFor="let row of rows">
    <div class="card">
      <p-accordionTab>
        <ng-template pTemplate="header">
          <span class="flex align-items-center gap-2 w-full">
            <!-- Date Display -->
            <span class="font-bold white-space-nowrap p-mr-2">{{ row.date | officeTime: 'medium' }}</span>

            <!-- User Data Display -->
            <span class="p-ml-4">
              <strong>User: </strong>{{ row.userData.name ? row.userData.name : 'Undefined' }}
            </span>

            <!-- Button with Badge -->
            <p-button label="Changes" [badge]="row.ops.length" severity="info" styleClass="p-button-warning p-ml-3"
              [style]="{'height': '22px'}"></p-button>
            <span *ngIf="row.userData.source" class="p-ml-4" [style]="{'font-size': '14px'}">
              <span>
                <strong>Source:</strong> {{row.userData.source}}
              </span>
              <span *ngIf="row.userData.challengeId" class="p-ml-2">
                <strong>Challenge Id:</strong> {{row.userData.challengeId}}
              </span>
            </span>
          </span>
        </ng-template>

        <div>
          <p-table
          [value]="[row]"
          styleClass="p-datatable-gridlines"
          [scrollHeight]="'flex'"
          [style]="{width: '100%'}"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>Date</th>
                <th>Action</th>
                <th>Field Changed</th>
                <th>Original Value</th>
                <th>New Value</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body">
              <tr *ngFor="let op of row.ops">
                <ng-container *ngIf="!op.path.includes('/userData')">
                  <td>
                    <span [style]="{'font-size': '14px'}">{{ row.date | officeTime: 'medium' }}</span>
                  </td>
                  <td>
                    <span>
                      <p-tag styleClass="p-mr-2" severity="{{ op.op == 'add' ? 'success' : (op.op == 'replace' ? 'warning' : 'danger') }}" value="{{ op.op }}"></p-tag>
                    </span>
                  </td>
                  <td>
                    <span>{{ op.path | refSuffixRemoval | slice: 1 }}</span>
                  </td>
                  <td style="word-break: break-word">
                    <ng-container *ngIf="op.hasOwnProperty('originalValue')">
                      <ng-container *ngIf="isDate(op.originalValue); then dateTemplate else notDateTemplate"></ng-container>
                      <ng-template #dateTemplate>
                        <span>{{ op.originalValue | officeTime: 'medium' }}</span>
                      </ng-template>
                      <ng-template #notDateTemplate>
                        <ng-container *ngIf="op.originalValue !== null; then notNullTemplate else nullTemplate"></ng-container>
                      </ng-template>
                      <ng-template #notNullTemplate>
                        <span [innerHTML]="op.originalValue | expandObject"></span>
                      </ng-template>
                      <ng-template #nullTemplate><span>Null</span></ng-template>
                    </ng-container>
                    <ng-container *ngIf="!op.hasOwnProperty('originalValue')">
                      <span>Null</span>
                    </ng-container>
                  </td>
                  <td style="word-break: break-word">
                    <ng-container *ngIf="isDate(op.value); then dateTemplate2 else notDateTemplate2"></ng-container>
                    <ng-template #dateTemplate2>
                      <span>{{ op.value | officeTime: 'medium' }}</span>
                    </ng-template>
                    <ng-template #notDateTemplate2>
                      <ng-container *ngIf="op.value !== null; then notNullTemplate2 else nullTemplate2"></ng-container>
                    </ng-template>
                    <ng-template #notNullTemplate2>
                      <span [innerHTML]="op.value | expandObject"></span>
                    </ng-template>
                    <ng-template #nullTemplate2>
                      <span>Null</span>
                    </ng-template>
                  </td>
                </ng-container>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </p-accordionTab>
    </div>
  </p-accordion>
</div>
