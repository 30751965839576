import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import CategoryCollectionsDto from '../dtos/CategoryCollectionDTO';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';

@Component({
  selector: 'app-category-collection-form-v3',
  templateUrl: './category-collection-form-v3.component.html',
  styleUrls: ['./category-collection-form-v3.component.sass']
})
export class CategoryCollectionFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  categoryCollectionRecord: CategoryCollectionsDto = new CategoryCollectionsDto();
  constructor() 
  {
    super();
  }

  /**
   * Category Collection Form V3 Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Category Collection Data",
        fields: 
        [
          { 
            name: "Name", 
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true, 
            key: 'name', 
            isRequired: true, 
            label: 'Name', 
            disabled: false 
          },
          {
            name: "Categories",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            clearField: true,
            key: 'category_ref',
            isRequired: false,
            label: 'Categories',
            labelKeys: ['id', 'name'],
            optionValue:'_id',
            optionLabel:'name',
            optionDisabled:'disabled',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'category_ref', apiController: 'categories', useSetOptionsFromRef: true, isArrayOfStringIds: false },
          },
          {
            name: 'Filters',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.MultiSelect],
            key: 'filters',
            isRequired: false, 
            label: 'Filters',
            options: { fieldName: 'filters', values: ['Climate' ,'Color', 'Material','Pattern', 'Style', 'Trait', 'Type', 'Shape'] },
            // optionLabel: 'name',
            clearField: false,
            autoDisplayFirst: false,
            filter: true,
            style: { 'width': '100%' },
            showClear: true,
            multiSelectDisplayType: 'chip'
          }
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Category Collection";
    this.viewRoute = 'category-collections';
    this.isLoading = false;
  }
}