import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-loading-screen-view-v2',
  templateUrl: './loading-screen-view-v2.component.html',
})
export class LoadingScreenViewV2Component extends BaseDynamicViewComponent implements OnInit {
  LoadingScreenRecord: BaseEntityDto = new BaseEntityDto();
  constructor() {
    super();
  }

  miscAssetKeys: Array<any> = [{ key: 'logo_ref', type: 'object' }, { key: 'images_ref', type: 'array' }]

  /**
   * Loading Screen View V2 Initial Implementation
   */
  ngOnInit() {

    this.fields = [
      {
        title: "Loading Screen View",
        fields: [
          { name: "Name", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time', columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time', columnWidth: 6 },
          { name: "Year", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'year', isRequired: true, label: 'Year' },
          { name: "Weight", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'weight', isRequired: true, label: 'Weight' },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Images",
        fields: [
          {
            name: "Logo",
            inputType: DynamicViewType.MiscImageBuildComponent,
            clearField: true,
            key: 'logo_ref',
            label: 'Logo',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'logo_ref', apiController: 'miscellaneous-build',
              buildAndRender: true,
              type: 'ref',
            },
            columnWidth: 12
          },
          {
            name: "Loading Screen",
            inputType: DynamicViewType.MiscImageArrayBuild,
            clearField: true,
            key: 'images_ref',
            label: 'Loading Screen',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {
              fieldName: 'images_ref', apiController: 'miscellaneous-build',
              buildAndRender: true,
            },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Bundle Images and Assets",
        fields: [
          { name: "Bundle Image (IOS)", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'bundleImage', label: 'Bundle Image (IOS)', columnWidth: 6 },
          { name: "Bundle Image (AND)", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'bundleImageAnd', label: 'Bundle Image (AND)', columnWidth: 6 },

        ],
        type: DynamicCardType.Card
      }
    ];

    this.sidebarFields = [
      {
        title: "Loading Screen Preview",
        fields:
          [
            {
              name: "Logo",
              inputType: DynamicViewType.sideThumbnail,
              key: 'logo_ref',
              label: 'Logo',
              columnWidth: 12
            },
            {
              name: "Images",
              inputType: DynamicViewType.multiSideThumbnail,
              key: 'images_ref',
              label: 'Images',
              options: { gridWidth: 6, fieldName: '' },
              columnWidth: 12
            },
          ],
        type: DynamicCardType.Card
      },

    ]
    this.LoadingScreenRecord = {
      enabled: false
    };
    this.title = "Loading Screen";
    this.viewRoute = 'loading-screen-v2';
    this.isLoading = false;
  }
}
