import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-style-view-v2',
  templateUrl: './style-view-v2.component.html',
  styleUrls: ['./style-view-v2.component.sass']
})
export class StyleViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  styleRecord: BaseEntityDto = new BaseEntityDto();
  constructor() 
  {
    super();
  }

  /**
   * Style View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Style Data",
        fields: 
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time',columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time',columnWidth: 6 }
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields 
    // with pre-established values.
    // This is just an example, there is no 
    // need to add it if it's not necessary.
    this.styleRecord =
    {
      enabled: false
    };
    this.title = "Style";
    this.viewRoute = 'styles';
    this.isLoading = false;
  }

}