import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Challenge Type DTO DTO
 */
export default class AchievementDTO extends BaseEntityDto
{
    /**
     Achievement DTO
     */
    public title?: any;
    public logo_ref?: any;
    public images?: Array<any> = [];
    public weight?: string;
    public year?: number;
    public promoStartDate?: any;
    public promoEndDate?: any;
    public repeats?: any;
    public isDailyGoal?: boolean;
    public interval?: number;
  }
