    <h3>Goal Imagery:</h3>
    <div *ngIf="record[field.key]" class="goals-grid">
      <div *ngFor="let goalImage of record[field.key]; let i = index" class="p-col-12">
        <div>
          <strong>Goal {{ i+1 }} Images:</strong>
          <div *ngFor="let image of goalImage; let i = index" class="p-col-12">
            <div><strong>Name:</strong>
            <a href="/miscellaneous-build/{{image ?  image.id : ''}}" target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }"
                                      >
                                      {{image ? image.name : ''}} ({{image ?  image.id : ''}})
                                      </a>
               </div>
              <div><strong>Path:</strong> {{ image.path }}</div>
          </div>
        </div>
      </div>
    </div>