<div class="card">
    <p-accordion *ngFor="let row of rows">
        <div class="card">
            <p-accordionTab header="{{
                (row.date | officeTime: 'medium')
                +(row.userData ? '  |  User: '+row.userData.name : '')
              }}">
                <div>
                    <p-table [value]="[row]">
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Date</th>
                                <th>Action</th>
                                <th>Field Changed</th>
                                <th>Value Changed</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body">
                            <tr *ngFor="let op of row.ops">
                                <ng-container *ngIf="!op.path.includes('/userData')">
                                    <td>
                                        <span>{{ row.date | officeTime }}</span>
                                    </td>
                                    <td>
                                        <span><p-tag
                                            styleClass="p-mr-2"
                                            severity="success"
                                            value="{{ op.op }}"
                                        ></p-tag>
                                        </span>
                                    </td>
                                    <td>
                                        <span>{{ op.path | refSuffixRemoval | slice: 1 }}</span>
                                    </td>
                                    <td *ngIf="!op.value; else elseBlock"><span>Null</span></td>
                                    <ng-template #elseBlock>
                                        <td>
                                            <span>{{ op.path !== '/userData' ? op.value : op.value.email }}</span>
                                        </td>
                                    </ng-template>
                                </ng-container>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-accordionTab>
        </div>
    </p-accordion>
</div>