import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-enterprise';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { GettersService } from 'src/app/common/services/getters.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-rewards-track-table-ag-grid',
  templateUrl: './rewards-track-table-ag-grid.component.html',
  styleUrls: ['./rewards-track-table-ag-grid.component.sass']
})
export class RewardsTrackTableAgGridComponent implements OnInit {

  entity: string = 'rewards-track';
  columnDefs: ColDef[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {}
  autopopulateSelect: string = '';
  options: any = {};
  assetTypeSettings: any[] = [];
  entityTypeSettings: any[] = [];
  public bulkUpdateColDefs: any[] = [];

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
    private gettersService: GettersService
  ) { }

  async ngOnInit(): Promise<void> {

    await this.setOptions();
    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.type,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value.value;
          }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellRenderer: (params: any) => {
            const typeMapping = [
                { label: 'Login Rewards - 7 days', value: 1 },
                { label: 'Login Rewards - Day 30', value: 2 },
                { label: 'Garden Pass - Free', value: 3 },
                { label: 'Garden Pass - Paid', value: 4 },
                { label: 'Friends Invited', value: 5 },
                { label: 'Endless Offer', value: 6 },
                { label: 'Co-Op', value: 7 },
                { label: 'Mini Games', value: 8 },
                { label: 'Seasonal Prizes', value: 9 },
            ];
            const matchedType = typeMapping.find(type => type.value === params.value);
            return matchedType ? matchedType.label : 'Unknown';
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: [
                'Login Rewards - 7 days',
                'Login Rewards - Day 30',
                'Garden Pass - Free',
                'Garden Pass - Paid',
                'Friends Invited',
                'Co-Op',
                'Mini Games',
                'Seasonal Prizes'
            ]
        },
        valueSetter: (params: any) => {
            const typeMapping = [
                { label: 'Login Rewards - 7 days', value: 1 },
                { label: 'Login Rewards - Day 30', value: 2 },
                { label: 'Garden Pass - Free', value: 3 },
                { label: 'Garden Pass - Paid', value: 4 },
                { label: 'Friends Invited', value: 5 },
                { label: 'Co-Op', value: 7 },
                { label: 'Mini Games', value: 8 },
                { label: 'Seasonal Prizes', value: 9 },
            ];
            const matchedType = typeMapping.find(type => type.label === params.newValue);
            if (matchedType) {
                params.data.type = matchedType.value;
                return true;
            }
            return false;
        }
      },
      {
        headerName: 'Free',
        field: 'free',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
        },
        floatingFilter: true,

        width: 300,
        autoHeight: true,
      },
      {
        headerName: 'Paid',
        field: 'paid',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
        },
        floatingFilter: true,

        autoHeight: true,
        width: 300
      },
      {
        headerName: 'Friends',
        field: 'friends',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
        },
        floatingFilter: true,

        autoHeight: true,
        width: 500
      },
      {
        headerName: 'Login Reward(s) Day 1',
        field: 'dailyRewardDay1',
        valueGetter: (params: any) => params.data.type === 1 ? params.data[params.colDef.field] : null,
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
        width: 500,
        autoHeight: true,
      },
      {
        headerName: 'Login Reward(s) Day 2',
        field: 'dailyRewardDay2',
        valueGetter: (params: any) => params.data.type === 1 ? params.data[params.colDef.field] : null,
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
        width: 500,
        autoHeight: true,
      },

      {
        headerName: 'Login Reward(s) Day 3',
        field: 'dailyRewardDay3',
        valueGetter: (params: any) => params.data.type === 1 ? params.data[params.colDef.field] : null,
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        width: 500,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Login Reward(s) Day 4',
        field: 'dailyRewardDay4',
        valueGetter: (params: any) => params.data.type === 1 ? params.data[params.colDef.field] : null,
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        width: 500,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Login Reward(s) Day 5',
        field: 'dailyRewardDay5',
        valueGetter: (params: any) => params.data.type === 1 ? params.data[params.colDef.field] : null,
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        width: 500,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Login Reward(s) Day 6',
        field: 'dailyRewardDay6',
        valueGetter: (params: any) => params.data.type === 1 ? params.data[params.colDef.field] : null,
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        width: 500,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Login Reward(s) Day 7',
        field: 'dailyRewardDay7',
        valueGetter: (params: any) => params.data.type === 1 ? params.data[params.colDef.field] : null,
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        width: 500,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Login Reward(s) Day 30',
        field: 'dailyRewardDay30',
        valueGetter: (params: any) => params.data.type === 2 ? params.data[params.colDef.field] : null,
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        width: 500,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
        cellStyle: {'padding-top': '10px' } // adds padding to the cell,

      },
      {
        headerName: 'Mini Games Reward(s)',
        field: 'miniGamesRewards',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
          isNestedArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                title: 'Currency',
                buttons: ['clear'],
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
      },
    ];
    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

  }

  async setOptions(){
    const entities = [
      { ref: 'currencies_ref', entity: 'currencies' },
      { ref: 'resources_ref', entity: 'resources' },
      { ref: 'titles_ref', entity: 'titles' }
    ];

    for (const { ref, entity } of entities) {
      const params = new OptionsParams({
        entity
      });
      await this.utilitiesService.getOptions(this.options, ref, params);

    }

    this.options['type'] = [
      {
        name: 'Login Rewards - 7 days',
        value: 1,
      },
      {
        name: 'Login Rewards - Day 30',
        value: 2,
      },
      {
        name: 'Garden Pass - Free',
        value: 3,
      },
      {
        name: 'Garden Pass - Paid',
        value: 4,
      },
      {
        name: 'Friends Invited',
        value: 5,
      },
      {
        name: 'Endless Offer',
        value: 6,
      },
      {
        name: 'Co-Op',
        value: 7,
      },
      {
        name: 'Mini Games',
        value: 8,
      },
      {
        name: 'Seasonal Prizes',
        value: 9,
      },
    ];
  }
}
