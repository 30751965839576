import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import BuildrecordDTO from '../dtos/BuildRecord';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import * as _ from 'lodash';
import { BuildType } from 'src/app/enums/build-type';

@Injectable({
  providedIn: 'root'
})
export class BuildService
{
  private readonly apiURLprefix : string = '/api/build';

  constructor
  (
    private http: HttpClient,
    private utilitiesService: UtilitiesService
  ) {}

  /**
   * Retrieves all build records tied to the bulk build.
   */
  async getBulkBuildData(bulkBuildId: any): Promise<Array<BuildrecordDTO>>
  {
    let response = await firstValueFrom(this.http.post<any>(`${this.apiURLprefix}/records/find/all`, { query: { bulkBuildId: bulkBuildId } }));

    return response;
  }

  /**
   * Retrieves Build Data from the Build database
   *
   * @param buildData Object containing build data.
   */
  async getBuildRecordData(buildData: any)
  {
    let ids: Array<string> = [];
    if(_.isArray(buildData))
    {
      buildData.forEach(element =>
      {
        ids.push(element._id);
      });
    }
    else if (_.isObject(buildData))
    {
      Object.keys(buildData).forEach((platform: any) =>
      {
        ids.push(buildData[platform as keyof typeof buildData]);
      });
    }

    let response = await firstValueFrom(this.http.post<any>(`/api/build/records/find/all`,
    {
      query:
      {
        $and: [{ _id: { $in: ids } }, { platforms: { $nin: 'web' } }]
      }
    }));

    return response;
  }

  /**
   * Returns build data objects ordered by platform ('ios', 'and', 'lin', 'mac', 'win').
   *
   * @param data array of Build Data objects
   * @returns ordered array of Build Data objects
   */
  getOrderedBuildData(data: any[])
  {
    let result: any = [];
    if (data && data.length > 0) {
      let platformsOrder = ['ios', 'and', 'lin', 'mac', 'win'];

      for (let key of platformsOrder) {
        let build = data.find((build: any) => build.platforms.includes(key));
        if (build) {
          result.push(build);
        }
      }
    }
    return result;
  }

  /**
   * Retrieves build data from the build Ids.
   *
   * @param buildData Object containing builds Ids.
   */
  async getBuildDataFromIDS(buildData: any)
  {
    let result = await this.getBuildRecordData(buildData);

    if(result && result.length > 0)
    {
      return this.getOrderedBuildData(result);
    }
    else
    {
      return [];
    }
  }

  /**
   * Check for new build data
   */
  async getBuildData(buildData: any)
  {
    let output: any = {};
    if (buildData && Object.keys(buildData).length > 0)
    {
      output.buildData = await this.getBuildDataFromIDS(buildData);
      output.buildStatus = this.getBuildStatus(output.buildData);
    }
    else
    {
      output.buildData = [];
      output.buildStatus =
      {
        text: 'No build data',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }

    return output;
  }


  /**
   * Retrieves the build history from a given path.
   *
   * @param path Build Path.
   * @param adjust Adjusts the path to the approved assets folder.
   */
  async getBuildHistoryData(path: any, assetType: number)
  {
    if(path && !path.includes('approved_assets/')){
      path = 'approved_assets/'+path;
    }
    if([0,1].includes(assetType)){
      path = path.substring(0, path.lastIndexOf("/"));
    }
    let output: any = [];
    let queryParams = {
      query: {
        path: path,
        $or: [{ status: "finished" }, { status: "Finished" }],
      },
      select: '_id id status path platforms startedAt finishedAt files',
      autopopulate: true,
      virtuals: false,
      sort: { startedAt: 1 },
    };

    let result = await this.getBuildHistory(queryParams);

    // console.log(result);

    output[0] = result;

    return output;
  }

  /**
   * Retrieves the build history from a given build.
   *
   * @param query Query object.
   */
  async getBuildHistory(query: Object)
  {
    let result = await firstValueFrom(this.http.post<any>(`${this.apiURLprefix}/records/find/all`, query));

    return result;
  }

  /**
   * Retrieves the build status from the build data.
   *
   * @param buildData Build Data object.
   */
  getBuildStatus(buildData: any)
  {
    if (!buildData || buildData.length < 1)
    {
      return {
        text: 'No build data',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }

    let successful: any[] = [];
    let failed: any[] = [];
    let building: any[] = [];
    let queued: any[] = [];

    buildData.forEach((build: any) =>
    {
      switch (build.status.toLowerCase())
      {
        case 'finished':
          successful.push(build.finishedAt);
          break;
        case 'failed':
          failed.push(build.finishedAt);
          break;
        case 'building':
          building.push(build.startedAt);
          break;
        case 'queued':
          queued.push(build.insertedAt);
          break;
      }
    });

    let result = {};

    switch (buildData.length)
    {
      case successful.length:
        result = {
          text: 'Success',
          date: this.utilitiesService.getMostRecentDate(successful),
          color: 'var(--primary-color)',
          buildCount: buildData.length,
        };
        break;

      case failed.length:
        result = {
          text: 'Full Fail',
          date: this.utilitiesService.getMostRecentDate(failed),
          color: 'var(--pink-600)',
          buildCount: buildData.length,
        };
        break;

      case building.length:
        result = {
          text: 'Building',
          date: this.utilitiesService.getMostRecentDate(building),
          color: 'var(--text-color)',
          buildCount: buildData.length,
        };
        break;

      case queued.length:
        result = {
          text: 'Queued',
          date: this.utilitiesService.getMostRecentDate(queued),
          color: 'var(--text-color)',
          buildCount: buildData.length,
        };
        break;

      default:
        if (
          building.length > 0 &&
          building.length < buildData.length &&
          failed.length == 0
        ) {
          result = {
            text: 'Partial Building',
            date: this.utilitiesService.getMostRecentDate(building),
            color: 'var(--yellow-600)',
            buildCount: building.length,
          };
        } else if (
          failed.length > 0 &&
          failed.length < buildData.length
        ) {
          result = {
            text: 'Partial Fail',
            date: this.utilitiesService.getMostRecentDate(failed),
            color: 'var(--pink-600)',
            buildCount: failed.length,
          };
        } else if (
          queued.length > successful.length &&
          successful.length > 0
        ) {
          result = {
            text: 'Queued',
            date: this.utilitiesService.getMostRecentDate(queued),
            color: 'var(--text-color)',
            buildCount: queued.length,
          };
        } else {
          result = {
            text: 'No build data',
            date: '',
            color: 'var(--gray-400)',
            buildCount: 0,
          };
        }
        break;
    }

    return result;
  }

  getPromotionStatus(buildRecord: any, type: BuildType){
    let response = 'No data';
    let versionsKey = type != BuildType.Images ? 'asset_versions' : 'image_versions';
    if
    (
      buildRecord &&
      buildRecord.lastHash &&
      buildRecord[versionsKey] &&
      buildRecord[versionsKey].length > 0
    )
    {
      if(
        buildRecord[versionsKey][buildRecord[versionsKey].length - 1].destinationPath.includes(buildRecord.lastHash)
      ){
        response = 'Up to date'
      } else {
        response = 'Outdated'
      }
    }
    return response;
  }

  getProdHash(assetVersions: any){
    if(assetVersions.length>0){
      let cleanHahes = assetVersions.filter((record: { destinationPath: string; }) => !record.destinationPath.startsWith("2021/"));
      if(cleanHahes.length>0){
        return cleanHahes.slice(-1)[0].destinationPath;
      }
    } else {
      return null;
    }
  }

  getLatestPromotedVersion(buildRecord: any, type: string){
    let response = 'No data';
    let versionsKey = type == 'asset' ? 'asset_versions' : 'image_versions';
    if
    (
      buildRecord &&
      buildRecord[versionsKey] &&
      buildRecord[versionsKey].length > 0
    )
    {
      response = this.getProdHash(buildRecord[versionsKey]);
    }
    return response;
  }

  getLatestBuiltVersion(buildRecord: any){
    let response = 'No data';
    if
    (
      buildRecord &&
      buildRecord.lastHash
    )
    {
      response = buildRecord.lastHash;
    }
    return response;
  }

  /**
   * returns the asset_ref key name based on the column name and table type (entity)
   * @param columnName
   * @param type
   * @returns
   */
  getAssetKeyName(columnName: string, type: string){
    let assetKeyName: string = '';
    switch (true) {

      case (type == 'items' && ( columnName == 'buildStatus' || columnName == 'isAssetUpToDate') ) :
        assetKeyName = 'prefab_ref';
        break;
      case (type == 'challenges' &&  ( columnName == 'buildStatus' || columnName == 'isAssetUpToDate') ) :
        assetKeyName = 'scene_ref';
        break;
      case (type == 'items' && ( columnName == 'imageBuildStatus' || columnName == 'isImageUpToDate') ) :
        assetKeyName = 'thumbnail_ref';
        break;
      case (type == 'challenges' && ( columnName == 'imageBuildStatus' || columnName == 'isImageUpToDate') ) :
        assetKeyName = 'image_ref';
        break;
      case (type == 'nurture' && ( columnName == 'buildStatus' || columnName == 'isAssetUpToDate') ) :
        assetKeyName = 'coinAsset_ref';
        break;
      case (type == 'nurture' && ( columnName == 'imageBuildStatus' || columnName == 'isImageUpToDate') ) :
        assetKeyName = 'coinThumbnail_ref';
        break;
      default:
        assetKeyName = ''
        break;
     }
    return assetKeyName;
  }

  /**
   * Retrieves the color from the build status value.
   *
   * @param status Build status.
   */
  getColorFromBuildStatus(status: string) {
    status = status.toLowerCase();
    let output = '';
    switch (status) {
      case 'finished':
        output = 'var(--primary-color)';
        break;
      case 'failed':
        output = 'var(--pink-600)';
        break;
      case 'building':
        output = 'var(--yellow-600)';
        break;
      case 'queued':
        output = 'var(--gray-400)';
        break;
    }
    return output;
  }
}
