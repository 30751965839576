

<div class="p-grid p-mx-5">
    <div class="p-my-5 p-inputgroup">
      <span ngClass="p-float-label">
        <input
          [(ngModel)]="entity.name"
          pInputText
          class="field-item" />
          <label for="entity.name"> Name </label>
      </span>
    </div>
  </div>

  <div class="p-grid p-mx-5">
    <div class="p-col-6">
      <div class="p-my-5">
        <span ngClass="p-float-label">
          <p-dropdown
            [style]="{'width':'100%'}"
            [(ngModel)]="entity.entityType"
            [ngModelOptions]="{standalone: true}"
            [options]="entityTypeSettings"
            optionLabel="name"
            optionValue="value"
            [autoDisplayFirst]="false"
            [showClear]="true"
          ></p-dropdown>
          <label for="entityTypeSelection"> Entity Type</label>
        </span>
      </div>
    </div>

    <div class="p-col-6">
        <div class="p-my-5">
          <span ngClass="p-float-label">
            <p-dropdown
              [style]="{'width':'100%'}"
              [(ngModel)]="entity.imageType"
              [options]="imagerySettings"
              optionLabel="name"
              optionValue="path"
              [autoDisplayFirst]="false"
              [filter]="true"
              (onChange)="changePathPrefix($event)"
            ></p-dropdown>
            <label for="dropdown">Bundle Type</label>
          </span>
        </div>
      </div>

      <!-- <div class="p-grid p-mx-5">
        <div class="p-col-12">
          <div class="p-my-5">
          <plastic-files-autocomplete
            *ngIf="!isLoading"
            [(path)]="path"
            (onChange)="buildPath($event)"
            ></plastic-files-autocomplete>
          </div>
        </div>
      </div> -->
        <div class="p-col-12">
          <div class="p-my-5">
          <plastic-files-autocomplete
            *ngIf="!isLoading"
            [(path)]="path"
            (onChange)="buildPath($event)"
            ></plastic-files-autocomplete>
          </div>
        </div>
      </div>


    <div class="p-grid p-mx-5">
        <div class="p-col-4">
          <div class="p-my-5">
            <build-asset
            [entity]="entity"
            [createMiscRecord]="true"
            [path]="entity.path"
            [btnDisabled]="!isValidPath"
            [type]="type"
            assetType="image"
            (triggerCompleteEvent)="refreshData($event)"
            label="Submit and Build"
            [buildType]="buildType.Images"
          ></build-asset>
          </div>
        </div>
        <div class="p-col-4">
          <div class="p-my-5">
            <build-asset
            [entity]="entity"
            [createMiscRecord]="true"
            [path]="entity.path"
            [btnDisabled]="!isValidPath"
            [type]="type"
            assetType="image"
            (triggerCompleteEvent)="refreshData($event)"
            label="Submit and Build with High Priority"
            [buildType]="buildType.Images"
            [isHighPriority]="true"
          ></build-asset>
          </div>
        </div>
        <div class="p-col-4">
          <div class="p-my-5">
            <button
            pButton
            pRipple
            label="Submit"
            icon="pi pi-check"
            class="p-button"
            (click)="createNewAsset()"
          ></button>
          </div>
        </div>
      </div>

