import { AuthGuard } from "../auth/auth.guard";
import { MiniGameFormV2Component } from "../cms-v2/entities/mini-game/components/mini-game-form-v2/mini-game-form-v2.component";
import { MiniGameTableAgGridComponent } from "../cms-v2/entities/mini-game/components/mini-game-table-ag-grid/mini-game-table-ag-grid.component";
import { MiniGameViewV2Component } from "../cms-v2/entities/mini-game/components/mini-game-view-v2/mini-game-view-v2.component";

export const minigameRoutes = {
  prefix: 'minigames',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: MiniGameTableAgGridComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: MiniGameFormV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: MiniGameViewV2Component,
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: MiniGameFormV2Component,
        canActivate: [AuthGuard],
      },
    ]
  }
};
