import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { OfficeTimePipe } from 'src/app/common/pipes/officeTime.pipe';
import { LoggerService } from 'src/app/common/services/logger.service';
import { DataService } from 'src/app/services/data.service';
import { itemConstants, pathconfigValues } from '../data/constants';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { BuildService } from 'src/app/build-data/services/build.service';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import _ from 'lodash';
import { MessageService } from 'primeng/api';
import { Clipboard } from '@angular/cdk/clipboard';
import { environments } from 'src/app/common/constants/constants';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { EnvironmentService } from 'src/app/common/services/environment.service';

@Injectable({
  providedIn: 'root'
})
export class ItemService
{

  // @Input() id: any;
  id: any;
  entity: any;
  type: string = 'items';
  page: string = 'view';
  s3Cdn: string = 'https://d3tfb94dc03jqa.cloudfront.net/';

  dateVerificationConfirmed: boolean = false;
  showDatesVerificationModal: boolean = false;
  envsVerificationConfirmed: boolean = false;
  showEnvsVerificationModal: boolean = false;

  commonNameResult: any;
  latinNameResult: any;

  contentHolds: any = [
    'Bundle Hold',
    'New Arrivals Hold',
    'Prize Hold',
    'Progression Hold',
    'Series Hold',
    'Do Not Use'
  ]

  areEnvValuesOk: boolean = true;

  environmentName: string;

  private readonly apiURLprefix : string = '/api/items';

  constructor
  (
    private loggerService: LoggerService,
    private dataService: DataService,
    private http: HttpClient,
    private buildService: BuildService,
    private datePipe: DatePipe,
    private officeTimePipe: OfficeTimePipe,
    private commonEntityService: CommonEntityService,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private utilitiesService: UtilitiesService
  ) {
    this.environmentName = EnvironmentService.getEnvironmentName();
  }
  /**
   * This function builds a suggested path for prefab and thumbnail values based on the user's input.
   * @param
   * uses this variables to store the path's state:
   *  fileTypePath: string = '';
      fileTypeCode: string = '';
      selectedYearPath: string = '';
      fileNamePath: string = '';
      defaultYearPath: string = pathconfigValues.year;
   */
  async onPathComponentValueChange(fields: any, inputName: string, event: any, options: any, fileNamePath: string, fileTypePath: string, fileTypeCode: any, defaultYearPath: any, isEditMode: boolean, isItemSourcing: boolean = false){
    this.loggerService.log(`onPathComponentValueChange: inputName: ${inputName}, event:`, event);
    // desired output: [asset type]/ [file type] / [year] / [filename] / [filename]
    // [filetype-suffix]YYMMDD_XXX where XXX is an incremental number from 001 to 999
    let suggestedPath: string = `${pathconfigValues.type}/`;
    let option: any;
    switch (inputName) {
      case itemConstants.inputNames.fileType.name:
        // filtered array
        option = options[
          itemConstants.inputNames.fileType.optionsName
        ].filter((obj: any) => obj._id == event.value);
        if(option && option[0])
        {
          this.loggerService.log(`Selected file type: ${option[0].name}`);
          // adding file-type pathname and code
          fileTypePath = option[0].pathName;
          fileTypeCode = option[0].code;
          let response = await firstValueFrom(this.http.get<any>(`api/items/generate/filename/${fileTypeCode}/${isItemSourcing ? 'items-sourcing' : 'items' }`));
          console.log('filename gen: ', response);
          //
          if(response.fileName){
            fileNamePath = response.fileName;
          } else {
            fileNamePath = 'undefined';
          }
          // suggestedPath+=`${option[0].pathName}/${pathconfigValues.year}`;
        }
        break;
      case itemConstants.inputNames.year.name:
        this.loggerService.log('event:',event)
        defaultYearPath = event.value;
        break;
      case itemConstants.inputNames.fileName.name:
        fileNamePath = event.value;
        break;
      default:
        break;
    }
    this.loggerService.log(options);

    fields.prefab = `${pathconfigValues.type}/${fileTypePath && fileTypePath.length > 0 ? fileTypePath : '{File Type}'}/${defaultYearPath}/${fileNamePath && fileNamePath.length > 0 ? fileNamePath : '{File Name}'}/${fileNamePath && fileNamePath.length > 0 ? fileNamePath : '{File Name}'}`;
    fields.thumbnail = `${pathconfigValues.itemsThumbnailPathPrefix}/${defaultYearPath}/${fileNamePath && fileNamePath.length > 0 ? fileNamePath : '{File Name}'}`;
    fields.fileName = fileNamePath;

    return { fields: fields, fileTypePath: fileTypePath, defaultYearPath: defaultYearPath, fileNamePath: fileNamePath, fileTypeCode: fileTypeCode, }
  }

    /**
   * Sets the build data for the entity.
   */
    async setBuildData(entity: any)
    {
      let prefabBuildData = entity.prefab_ref ? entity.prefab_ref.buildData : null;
      let thumbnailBuildData = entity.thumbnail_ref ? entity.thumbnail_ref.buildData : null;
      if(prefabBuildData)
      {
        let response = await this.buildService.getBuildData(prefabBuildData);

        if(response)
        {
          entity.prefab_ref = entity.prefab_ref == undefined ? {} : entity.prefab_ref;
          entity.prefab_ref.buildData = response.buildData;
          entity.prefab_ref.buildStatus = response.buildStatus;
        }
      }

      if(thumbnailBuildData)
      {
        let response = await this.buildService.getBuildData(thumbnailBuildData);

        if(response)
        {
          entity.thumbnail_ref = entity.thumbnail_ref == undefined ? {} : entity.thumbnail_ref;
          entity.thumbnail_ref.buildData = response.buildData;
          entity.thumbnail_ref.buildStatus = response.buildStatus;
        }
      }
    }

    /**
     * populateImgField - populate image field
     * This function is used to populate image field
     * @param field
     */

    populateImgField(field: any) {
      if (field.key.endsWith('_img')) {
        // define path
        const pathKey: any = Object.keys(this.entity).find(
          (key: string) =>
            key === field.key.substring(0, field.key.indexOf('_img'))
        );
        const path = ['externalPlantData_ref'].includes(pathKey)
          ? this.entity[pathKey].imagePath
          : this.entity[pathKey];

        if (path) {
          // define default size
          const size: string = this.page === 'table' ? '_256' : '_1024';
          // define default fileExt
          const fileExt: string =
            path.endsWith('.png') || path.endsWith('.jpg')
              ? ''
              : this.type === 'challenges' && pathKey === 'image'
              ? '.jpg'
              : '.png';

          // define default fileName
          const n =
            pathKey === 'scene' ? path.lastIndexOf('s/') : path.lastIndexOf('/');
          const rString = path.substring(n + 1);

          let output;
          if (this.type === 'challenges' && pathKey === 'scene') {
            let array = rString.split('/');
            array[2] = array[2] + '_bg';
            output =
              this.s3Cdn +
              'images/bg_renders' +
              array.join('/') +
              '_bg' +
              size +
              fileExt;
          } else {
            output =
              (this.type === 'items' && pathKey === 'thumbnail') ||
              (this.type === 'challenges' && pathKey === 'image')
                ? this.s3Cdn + path + '/' + rString + size + fileExt
                : this.s3Cdn + path + fileExt;
          }

          this.entity[field.key] = output;
        } else {
          this.entity[field.key] = null;
        }
      }
    }

    /**
     *
     * @param array
     * @returns sends back a split array of the original array for the cards
     */

    chunkArray(array: string | any[]) {
      if (array.length > 1) {
        return _.chunk(array, Math.round(array.length / 2));
      } else return _.chunk(array, array.length);
    }

    /**
     * parseValueForView - parse values for the view depending on the control type
     * @param data
     * @param field
     * @param entity
     * @returns
     */

    parseValueForView(data: any, field: any, entity:any) {
      if (!data && !['toggle'].includes(field.controlType)) return '';
      let output;
      switch (field.controlType) {
        case 'date':
        case 'calendar-start':
        case 'calendar-end':
          output = data ? this.officeTimePipe.transform(new Date(data)) : '';

          break;
        case 'toggle':
          if (field.key == 'spruceDataStatus') {
            output = data ? 'Linked' : 'Unlinked';
            break;
            }
          if (field.key == 'enabled') {
            output = data ? 'Enabled' : 'Not Enabled';
            break;
            }
            if (field.key == 'isReward') {
              output = data ? 'Yes' : 'No';
              break;
              }
          output = data;
          break;
        default:
          output = data;
          break;
      }
      if(field.key == 'vendorStatus' && entity['flagged']) {
        output = entity['flagged']
      } else if (field.key == 'itemStatus' && entity['contentHold_ref'] && entity['contentHold_ref'].length > 0) {
        if(entity['contentHold_ref'].some((obj: any) => obj.name.toLowerCase() === "do not use")){
          if(entity['contentHold_ref'].length > 1){
            output = `Do Not Use (+${entity['contentHold_ref'].length - 1})`
          } else {
            output = "Do Not Use"
          }
        } else {
          for(let hold of this.contentHolds) {
              if (entity['contentHold_ref'].some((obj: any) => obj.name.toLowerCase() === hold.toLowerCase())) {
                {
                  if(entity['contentHold_ref'].length > 1){
                    output = `${hold} (+${entity['contentHold_ref'].length - 1})`
                    return output
                  } else {
                    output = hold
                    return output
                  }
                }
            }
          }
        }
      }
      return output;
    }

  /**
   * Updates the build data for the entity.
   */
  async updateBuildData()
  {
    setTimeout(async () =>
    {
      let record = await this.commonEntityService.findOneWithQuery(this.type, { query: { id: this.id } });

      if(record)
      {
        this.entity.prefab_ref = record.prefab_ref;
        this.entity.thumbnail_ref = record.thumbnail_ref;
      }
      // get build data.
      await this.getBuildData();
    }, 500);
  }

    /**
    * Retrieves the build data for the entity.
   */
    async getBuildData()
    {
      if(this.entity.prefab_ref && this.entity.prefab_ref.buildData)
      {
        let response = await this.buildService.getBuildData(this.entity.prefab_ref.buildData);
        this.entity.prefab_ref.buildData = response.buildData;
        this.entity.prefab_ref.buildStatus = response.buildStatus;
      }
      else
      {
        this.entity.prefab_ref.buildData = [];
        this.entity.prefab_ref.buildStatus = {
          text: 'Build Status Not Found',
          date: '',
          color: 'var(--gray-400)',
          buildCount: 0,
        };
      }
      if(this.entity.thumbnail_ref && this.entity.thumbnail_ref.buildData)
      {
        let response = await this.buildService.getBuildData(this.entity.thumbnail_ref.buildData);
        this.entity.thumbnail_ref.buildData = response.buildData;
        this.entity.thumbnail_ref.buildStatus = response.buildStatus;
      }
      else
      {
        this.entity.thumbnail_ref.buildData = [];
        this.entity.thumbnail_ref.buildStatus = {
          text: 'Build Status Not Found',
          date: '',
          color: 'var(--gray-400)',
          buildCount: 0,
        };
      }
    }

    /**
     * Sets the Default picture image feed when no image is present
     */

    setDefaultPic() {
      if(this.entity){
        this.entity.thumbnail_img =
        'https://d3tfb94dc03jqa.cloudfront.net/images/asset_missing/need_thumb/need_thumb_1024.png';
      }

     }

    /**
   *
   * @param copyVal // text to copy into the user's clipboard
   */
    copyToClipboard(copyVal: string) {
      this.clipboard.copy(copyVal);
      this.messageService.add({
        sticky: true,
        severity: 'success',
        summary: 'Thumbnail Copied',
        detail: `URL copied to clipboard successfully!`,
      });
    }

    /**
    *
    * @param field the field to evaluate
    * @returns the style class of the badge
   */
  statusBgColors(field: any, entity: any) {
    if (field && field.key) {
      switch (field.key) {
        case 'vendorStatus':
          if (['Hold', 'In QA', 'Marked for Deletion'].includes(entity['flagged'])) {
            return 'red-status'
          } else if
            (['Assigned', 'In Revision']
            .includes(entity[field.key]))
          {
              return 'yellow-status'
          } else if (!entity[field.key]) {
            return 'gray-status'
          } else {
            return 'green-status'
          }
        case 'itemStatus':
          if(entity && entity.contentHold_ref && entity.contentHold_ref.length > 0) {
            return 'red-status'
          } else if (entity[field.key] == 'Approved') {
            return 'green-status'
          } else {
            return 'gray-status'
          }
        case 'enabled':
          if(entity.enabled){
            return 'green-status'
          } else {
            return 'red-status'
          }
        case 'isReward':
          if(!entity.isReward) {
            return 'gray-status'
           } else {
            return 'blue-status'
           }
        case 'spruceDataStatus':
          if(!entity.spruceDataStatus) {
            return 'gray-status'
           } else {
            return 'blue-status'
           }
        case 'env':
          if(this.environmentName == 'test'){
            return 'gray-status'
          }
          if(!entity.env || entity.env.length == 0) {
            return 'gray-status';
          } else if (!this.areEnvValuesOk) {
            return 'red-status'
          } else if (['dev', 'qa', 'prod'].every(i => entity.env.includes(i))) {
            return 'green-status'
          } else if (['dev'].every(i => entity.env.includes(i))) {
            return 'yellow-status'
          } else if (['dev', 'qa'].every(i => entity.env.includes(i))) {
            return 'blue-status'
          } else {
            return 'red-status'
          }
        case 'buildStatus':
          if (entity.buildStatus && entity.buildStatus.text == 'Build Status Not Found') {
            return 'gray-status'
          } else if (entity.buildStatus && (entity.buildStatus.text == 'Full Fail' || entity.buildStatus.text == 'Partial Fail')) {
            return 'red-status'
          } else if (entity.buildStatus && entity.buildStatus.text == 'Success') {
            return 'green-status'
          } else {
            return 'yellow-status'
          }
        case 'imageBuildStatus':
          if (entity.imageBuildStatus && entity.imageBuildStatus.text == 'Build Status Not Found') {
            return 'gray-status'
          } else if (entity.imageBuildStatus && (entity.imageBuildStatus.text == 'Full Fail' || entity.imageBuildStatus.text == 'Partial Fail')) {
            return 'red-status'
          } else if (entity.imageBuildStatus && entity.imageBuildStatus.text == 'Success') {
            return 'green-status'
          } else {
            return 'yellow-status'
          }
      }
    }
    return 'gray-status'
  }

  async findLatinNameSpruceMatch(field: any, nameValue: any) {
    let valueSplit = nameValue.split(' ');
    let e = false;
    // console.log(valueSplit);
    // const _nameValue = nameValue;

    const exactMatch = await this.dataService
      .getAllOfTypeAsync(field, {
        query: {
          // 1 to 1
          botanicalName: { $regex: `^${nameValue}$`, $options: 'i' },
        },
      })
      .then(async (result: any) => {
        // console.log('Stepping into next then results=exactMatch', result);
        if (result?.length > 0) {
          // console.log('returning first results', result);
          e = true;
          this.latinNameResult = result;
          return result;
        } else {
          const partialMatch = await this.dataService.getAllOfTypeAsync(
            'external-plant-data',
            {
              // contains
              query: {
                botanicalName: { $regex: `${nameValue}`, $options: 'i' },
              },
            }
          );
          // console.log('returning back Partial Match', partialMatch);
          this.latinNameResult = partialMatch;
          return partialMatch;
        }
      })
      .then(async (result: any) => {
        if (!e) {
          // console.log(
          //   'Stepping into next then,Results for Partial Match',
          //   result
          // );
          if (result?.length > 0) {
            // console.log(
            //   'if there is stuff in the partial match return the array',
            //   result
            // );

            return result;
          } else {
            let splitArray: any = [];
            for (const value of valueSplit) {
              // console.log('checking loop values', value);
              await this.dataService
                .getAllOfTypeAsync('external-plant-data', {
                  query: {
                    botanicalName: { $regex: `${value}`, $options: 'i' },
                    // commonName: { $in: `${value}`, $options: 'i' },
                  },
                })
                .then((result) => {
                  // console.log('after loop', result);
                  // splitArray.push.apply(splitArray, result);
                  splitArray.push(result);
                  // result.push(splitArray);
                  // splitArray.concat(result);
                });
            }
            let newSplitArray = [];
            for (const v of splitArray) {
              newSplitArray.push(...v);
            }
            const diffObjects = new Set(newSplitArray);
            // console.log('diffObjects', diffObjects);

            this.latinNameResult = [...diffObjects];
            // console.log('latinNameResultResultsMerged', this.latinNameResult);

            return diffObjects;
          }
        }
      });
    // console.log('final latinNameResult', this.latinNameResult);
  }

  async findCommonNameSpruceMatch(field: any, nameValue: any) {
    let valueSplit = nameValue.split(' ');
    let e = false;
    // console.log(valueSplit);
    // const _nameValue = nameValue;

    const exactMatch = await this.dataService
      .getAllOfTypeAsync(field, {
        query: {
          // 1 to 1
          commonName: { $regex: `^${nameValue}$`, $options: 'i' },
        },
      })
      .then(async (result: any) => {
        // console.log('Stepping into next then results=exactMatch', result);
        if (result?.length > 0) {
          this.loggerService.log('returning first results', result);
          e = true;
          this.commonNameResult = result;
          return result;
        } else {
          const partialMatch = await this.dataService.getAllOfTypeAsync(
            'external-plant-data',
            {
              // contains
              query: {
                commonName: { $regex: `${nameValue}`, $options: 'i' },
              },
            }
          );
          // console.log('returning back Partial Match', partialMatch);
          this.commonNameResult = partialMatch;
          return partialMatch;
        }
      })
      .then(async (result: any) => {
        if (!e) {
          // console.log(
          //   'Stepping into next then,Results for Partial Match',
          //   result
          // );
          if (result?.length > 0) {
            // console.log(
            //   'if there is stuff in the partial match return the array',
            //   result
            // );

            return result;
          } else {
            let splitArray: any = [];
            for (const value of valueSplit) {
              // console.log('checking loop values', value);
              await this.dataService
                .getAllOfTypeAsync('external-plant-data', {
                  query: {
                    commonName: { $regex: `${value}`, $options: 'i' },
                    // commonName: { $in: `${value}`, $options: 'i' },
                  },
                })
                .then((result) => {
                  // console.log('after loop', result);
                  // splitArray.push.apply(splitArray, result);
                  splitArray.push(result);
                  // result.push(splitArray);
                  // splitArray.concat(result);
                });
            }
            let newSplitArray = [];
            for (const v of splitArray) {
              newSplitArray.push(...v);
            }
            const diffObjects = new Set(newSplitArray);
            // console.log('diffObjects', diffObjects);

            this.commonNameResult = [...diffObjects];
            // console.log('commoneNameResultsMerged', this.commonNameResult);

            return diffObjects;
          }
        }
      });


    // console.log('final CommonNameResults', this.commonNameResult);
  }

  onRowUnselect(event:any, fields: any, plantPreview: any, itemForm: any) {
    fields.spruceDataStatus = false;
    this.loggerService.log('spruceStatus check', fields.spruceDataStatus);
    plantPreview.bloomTime = '🌸 🌺 🌻 🌼';
    plantPreview.hardinessZone = '🌸 🌺 🌻 🌼 ';
    plantPreview.extTypicalSize = '🌸 🌺 🌻 🌼';
    plantPreview.nativeArea = '🌸 🌺 🌻 🌼';
    plantPreview.sunExposure = '🌸 🌺 🌻 🌼';
    plantPreview.blurb = itemForm.value.blurb
      ? itemForm.value.blurb
      : 'Please either type in a blurb or select another description';

      this.unLink(itemForm, fields);
  }

  /**
   * clear values of spruce data
   */
  unLink(itemForm: any, fields: any) {
    itemForm
    .get('externalPlantData_ref')
    ?.setValue(null);
    itemForm
    .get('spruceDataStatus')
    ?.setValue(false);
    fields.spruce_img = null;

    itemForm.get('externalPlantData_ref')?.markAsTouched();
    itemForm.get('spruceDataStatus')?.markAsTouched();
    this.messageService.add({
      severity: 'success',
      summary: 'Unlinking Successful',
      detail: `Spruce Data has been succesfully unlinked`,
    });
  }

  setExternalPlantData(plantPreview: any, selectedExternalPlant: any) {
    plantPreview.bloomTime = selectedExternalPlant.bloomTime;
    plantPreview.hardinessZone = selectedExternalPlant.hardinessZone;
    plantPreview.extTypicalSize = selectedExternalPlant.matureSize;
    plantPreview.nativeArea = selectedExternalPlant.nativeArea;
    plantPreview.sunExposure = selectedExternalPlant.sunExposure;
    plantPreview.blurb = selectedExternalPlant.description;
  }

  /**
   * Set all field options for the form.
   */
  async setOptions(options: any) {
    // from refs (managed lists)
    await this.getAllRecordsForType(options);
    await this.getOptionsFromRef(options, 'category_ref', 'categories', false, true);
    // await this.getOptionsFromRef(options, 'nurture_ref', 'nurture', false, true);
    await this.getOptionsFromRef(options, 'climates_ref', 'climates', true, true);
    await this.getOptionsFromRef(options, 'itemSet_ref', 'item-sets', true, true);
    await this.getOptionsFromRef(options, 'colors_ref', 'colors', true, true);
    await this.getOptionsFromRef(options, 'tags_ref', 'tags', true, true);
    await this.getOptionsFromRef(options, 'keywords_ref', 'item-keywords', true, true);
    await this.getOptionsFromRef(options, 'contentHold_ref', 'content-hold', true, true);
    await this.getOptionsFromRef(options, 'styles_ref', 'styles', true, true);
    await this.getOptionsFromRef(options, 'traits_ref', 'traits', true, true);
    await this.getOptionsFromRef(options, 'materials_ref', 'materials', true);
    await this.getOptionsFromRef(options, 'patterns_ref', 'patterns', true, true);
    await this.getOptionsFromRef(options, 'shape_ref', 'shapes', true, true);
    await this.getOptionsFromRef(options, 'vendor_ref', 'vendors', true, true);
    await this.getOptionsFromRef(options, 'batch_ref', 'batches', true, false);
    await this.utilitiesService.getOptionsFromRef(options,'progressionLevel_ref','progression-levels', false, false, 'name id _id path level', false, {level: 1});
    await this.getOptionsFromRef(options, 'id', 'currencies', false, true);
    await this.getOptionsFromRef(options, 't', 'resources', true, true);
    // not minimal cause we need 'code' for path generation
    await this.getOptionsFromRef(options,
      'itemFileType_ref',
      'item-file-types',
      false,
      true
    );
    await this.utilitiesService.getOptionsFromRef(options, 'spawnAudios_ref', 'miscellaneous-build', false, false, 'name id _id path', false, { name: 1 }, { assetType: { $in: [22] }});
    await this.utilitiesService.getOptionsFromRef(options, 'loopAudios_ref', 'miscellaneous-build', false, false, 'name id _id path', false, { name: 1 }, { assetType: { $in: [23] }});
    await this.utilitiesService.getOptionsFromRef(options, 'spawnAudioCollections_ref', 'audio-collections', false, false, 'name id _id', false, { name: 1 }, { type: 1 });
    await this.utilitiesService.getOptionsFromRef(options, 'loopAudioCollections_ref', 'audio-collections', false, false, 'name id _id', false, { name: 1 }, { type: 2 });


    // Remove item from resources. Items should not cost another item.
    options['t'].splice(1, 1);

    // un-managed lists
    options['vendorStatus'] = [
      'Assigned',
      'In Revision',
      'Approved',
    ];
    options['itemStatus'] = [
      'Approved',
      'Awaiting Revision',
      'QA Ready'
    ];

    options['blurbStatus'] = [
      'Approved',
      'Needs Blurb',
      'Ready for Review',
      'Revision Needed',
    ];
    options['year'] = [
      '2021',
      '2022',
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
      '2030',
    ];
    // options['recolorSource'] = ['External', 'Internal'];
    options['flagged'] = ['Hold', 'In QA', 'Marked for Deletion'];
    options['assetType'] = [
      'Hard Surface',
      'HS Recolor',
      'Organics',
      'Organic Recolors',
    ];
    options['envs'] = environments;
  }

  /**
   * Get/set options from a reference entity type.
   *
   * @param fieldName
   * @param model
   * @param minimal
   */
  async getOptionsFromRef(
    optionsArray: any,
    fieldName: string,
    model: string,
    minimal: boolean = false,
    autopopulate: boolean = true
  ) {
    if (fieldName == 'progressionLevel_ref') {
      const options = await this.dataService.getAllOfTypeAsync(model, {
        query: {},
        autopopulate: autopopulate,
        virtuals: true,
        sort: { level: 1 },
      });
      if (minimal) {
        let o: any[] = [];
        for (const option of options) {
          o.push({ id: option.id, name: option.name, _id: option._id });
        }
        options[fieldName] = o;
      } else {
        options[fieldName] = options;
      }
    } else {
      const options = await this.dataService.getAllOfTypeAsync(model, {
        query: {},
        autopopulate: autopopulate,
        virtuals: true,
        sort: { name: 1 },
      });
      if (minimal) {
        let o: any[] = [];
        for (const option of options) {
          o.push({ id: option.id, name: option.name, _id: option._id });
        }
        optionsArray[fieldName] = o;
      } else {
        optionsArray[fieldName] = options;
      }
    }
  }

  /**
   * Retrieves all records for an entity type
   */
  async getAllRecordsForType(options: any)
  {
    this.dataService.getAllOfType('items',
    {
      query: { },
      select: '_id name id',
      virtuals: false,
      autopopulate: false,
      sort: { name: 1 }
    })
    .subscribe((result) =>
    {
      options['releatedItems'] = result;
    });
  }

  // functions needed for start/end dates validation
  formatDate(date: any){
    return  date ? this.officeTimePipe.transform(new Date(date)) : '';
  }

  /**
   * Set options of a field with the value of a nested field.
   *
   * @param fieldWithNested
   * @param nestedField
   * @param fieldToUpdate
   * @param refId
   * @param reset
   */
  async setOptionsFromNestedField(
    options: any,
    itemForm: any,
    fieldWithNested: string,
    nestedField: string,
    fieldToUpdate: string,
    refId: string,
    reset: boolean = true
  ) {
    // clear options on field to update if ref field is empty.
    if (itemForm.get(fieldWithNested)?.value == null) {
      options[fieldToUpdate] = [];
    }

    // get refs from ref field.
    for (const option of options[fieldWithNested]) {
      // find matching option to the value selected.
      if (option._id == refId) {
        options[fieldToUpdate] = option[nestedField];
        this.loggerService.log('checking options', option[nestedField]);
        break;
      }
    }

    // reset the field we are setting options for.
    if (reset) {
      itemForm.get(fieldToUpdate)?.reset();
      itemForm.get(fieldToUpdate)?.markAsTouched();
    }
  }
  /*
  Plant Preview Setup Function

  This function matches user's selected options from the itemForm and grabs the names from the options that were set from the nestedOptions functions from above
  formFieldValue = x.colors_ref
  formfield = string of the field such as 'color_ref'
   */

  plantPreviewSetup(property: any, formField: string, formFieldValue: any, plantPreview: any, options: any) {
    plantPreview[property].raw = formFieldValue;
    plantPreview[property].names = [];
    if (formFieldValue?.length > 0) {
      // console.log('checking color ref', plantPreview.color.raw);
      for (let i = 0; i < plantPreview[property].raw.length; i++) {
        options[formField].find((field_match: any) => {
          if (plantPreview[property].raw[i] === field_match._id) {
            // console.log('field_match', field_match.name);
            if (!plantPreview[property].names.includes(field_match.name)) {
              plantPreview[property].names.push(` ${field_match.name}`);
            }
          }
        });
      }
    }
    // console.log('colors ??', this.plantPreview[property].names);
  }

  /**
   * Sets the enabled environments to null if the selected value is 'Do Not Use'.
   *
   * @param selectedValue The selected value from the contentHold_ref multiselect dropdown.
   */
  setEnabledEnvironmentsToNull(selectedValue: any, options: any, itemForm: any)
  {
    let value = options['contentHold_ref'].find((x: any) => x._id === selectedValue.itemValue);

    if(value && value.name === 'Do Not Use')
    {
      itemForm.get('enabled')?.setValue(false);
      itemForm.controls['enabled']?.markAsTouched();
      itemForm.get('env')?.setValue(null);
      itemForm.controls['env']?.markAsTouched();

      this.messageService.add({
        severity: 'warn',
        summary: 'Item Not Enabled',
        detail: `Item is no longer enabled.`,
      });
    }
  }

  initPlantPreview(fields: any, plantPreview: any, options: any, existingDoc: any) {
    if (fields.height && fields.spread) {
      plantPreview.typicalSize = `${fields.height} ft tall, ${fields.spread} ft wide `;
    }
    // climates
    this.plantPreviewSetup(
      'climates',
      'climates_ref',
      fields.climates_ref,
      plantPreview,
      options,
    );
    //categories
    if (fields.category_ref) {
      if (options && options['category_ref']) {
        options['category_ref'].find((match: any) => {
          if (match) {
            if (fields.category_ref === match._id) {
              // console.log('inside', match);
              plantPreview.category = match.name;

              // Grab the Type Name
              if (match['types_ref']) {
                match['types_ref'].find((type_match: any) => {
                  if (type_match && fields.type_ref === type_match._id) {
                    plantPreview.type = type_match.name;
                  }
                });
              }
            }
          }
        });
      }
    }
    if (fields.colors_ref) {
      this.plantPreviewSetup('colors', 'colors_ref', fields.colors_ref, plantPreview, options);
    }

    // Grab Traits Name for Preview Module
    if (fields.traits_ref) {
      this.plantPreviewSetup('traits', 'traits_ref', fields.traits_ref, plantPreview, options);
    }
    if (existingDoc.costs_ref) {
      plantPreview.cost =
        existingDoc.costs_ref.length > 0
          ? existingDoc.costs_ref[0].c
          : null;
    }
  }
  /**
   * Autocomplete searching
   *
   * @param e
   * @param fieldName
   * @param model
   */
  async getSuggestionsForRef(e: any, fieldName: string, model: string, suggestions: any) {

    // use this.filterModels to map the correct endpoint model so we don't have to store it on the entity.
    this.dataService
      .getAllOfType(model, {
        query: isNaN(e) ? { name: { $regex: e, $options: 'i' } } : { id: e },
        select: '_id name id',
        virtuals: false,
        autopopulate: false,
      })
      .subscribe((result) => {
        suggestions[fieldName] = result;
      });
  }

  /**
   * Display alter message
   *
   * @param success Flag that sets whether or not is a succes alert
   * @param message Message to display.
   * @param detail Additional details.
   */
  alertMessage(success: boolean = true, message: string, detail: string) {
    this.messageService.add({
      severity: success ? 'success' : 'error',
      summary: message,
      detail: detail,
    });
  }

  onRowSelect(event: any, fields: any, selectedExternalPlant: any, itemForm: any, plantPreview: any, spruceImage: any) {
    fields.spruceDataStatus = true;
    this.loggerService.log('spruceStatus check', fields.spruceDataStatus);
    this.loggerService.log('selected row', selectedExternalPlant);
    itemForm
      .get('externalPlantData_ref')
      ?.setValue(selectedExternalPlant._id);
      itemForm
      .get('spruceDataStatus')
      ?.setValue(fields.spruceDataStatus);
    this.setExternalPlantData(plantPreview, selectedExternalPlant);

    itemForm.get('externalPlantData_ref')?.markAsTouched();
    itemForm.get('spruceDataStatus')?.markAsTouched();

    const fileName = selectedExternalPlant.imagePath + '.jpg';
    this.loggerService.log('filename', fileName);

    if (selectedExternalPlant.imagePath) {
      fields.spruce_img = this.s3Cdn + fileName;
      this.loggerService.log('spruce image', spruceImage);
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Linking Successful',
      detail: `Spruce Data has been succesfully linked`,
    });
  }
}
