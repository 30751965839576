import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';

import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import AchievementDTO from '../achievement-form/dto/AchievementDTO';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { DataService } from 'src/app/services/data.service';
@Component({
  selector: 'app-achievement-form-v3',
  templateUrl: './achievement-form-v3.component.html',
  styleUrls: ['./achievement-form-v3.component.sass']
})
export class AchievementFormV3Component extends BaseDynamicFormComponent implements OnInit {

  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  achievementRecord: AchievementDTO = new AchievementDTO();

  constructor(
    private validation: BaseInputValidationServiceService,
    injector: Injector,
    private dataService: DataService
  ) {
    super(injector);
  }

  // Repetitive Events
  weekDays = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' }
  ];

  async ngOnInit() {

    const weekDayControls: Record<string, any[]> = {};
    this.weekDays.forEach(day => {
      // Check if the day is included in the existing values and set the control accordingly
      const isDaySelected = this.achievementRecord.repeats && this.achievementRecord.repeats.includes(day.value);
      weekDayControls[day.value] = [isDaySelected]; // Initialize each day based on existing values
    });

    const achievementTypes = await this.dataService.getAchievementTypes();

    this.fields =
      [
        {
          title: "Achievement Form",
          fields:
            [
              {
                name: "Title",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'title',
                isRequired: true,
                label: 'Title',
                disabled: false,
                columnWidth: 6,
              },
              {
                name: "SubText",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'subText',
                isRequired: false,
                label: 'SubText',
                disabled: false,
                columnWidth: 6,
              },
              {
                name: "Start Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                isRequired: false,
                clearField: true,
                key: 'start',
                label: 'Start Date/Time',
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                columnWidth: 6,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.end) {
                    return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
                  } else {
                    return true;
                  }
                },
              },
              {
                name: "End Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'end',
                isRequired: false,
                label: 'End Date/Time',
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                calendarMinDate: this.achievementRecord.start,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.start) {
                    return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
                  } else {
                    return true;
                  }
                },
                columnWidth: 6
              },
              {
                name: "Sorting Order",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Number],
                clearField: true,
                key: 'sortingOrder',
                isRequired: false,
                label: 'Sorting Order',
                disabled: false,
                showField: true,
                columnWidth: 4,
              },
              {
                name: "",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.SpaceCol],
                key: '',
                label: '',
                columnWidth: 2
              },
              {
                name: "Is Consecutive",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.ToggleButton],
                clearField: true,
                key: 'isConsecutive',
                isRequired: false,
                label: 'Infinite Garden Exclusion',
                onLabel: 'Consecutive',
                offLabel: 'Not Consecutive',
                showField: true,
                disabled: false,
                columnWidth: 4,
              },
              {
                name: "",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.SpaceCol],
                key: '',
                label: '',
                columnWidth: 6
              },
              {
                name: "Promo Fields",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Text],
                key: 'promoFields',
                isRequired: false,
                clearField: true,
                label: 'Promo Fields:',
                columnWidth: 12
              },
              {
                name: "Promo Start Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                isRequired: false,
                clearField: true,
                key: 'promoStartDate',
                label: 'Promo Start Date/Time',
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                columnWidth: 6,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.promoEndDate) {
                    return [(this.validation.validateDateRange(incomingValue, record.promoEndDate)), "Start Date must be before end date."]
                  } else {
                    return true;
                  }
                },
              },
              {
                name: "Promo End Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'promoEndDate',
                isRequired: false,
                label: 'Promo End Date/Time',
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                calendarMinDate: this.achievementRecord.promoStartDate,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.promoStartDate) {
                    return [(this.validation.validateDateRange(record.promoStartDate, incomingValue)), "End date must be greater than start date."]
                  } else {
                    return true;
                  }
                },
                columnWidth: 6
              },
            ],
          type: DynamicCardType.Card
        },
        {
          title: "Achievement Details",
          fields: [
            {
              name: "Player Description",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.InputTextArea],
              clearField: true,
              options: {
                showCharacterCount: true,
                characterCountValidFunction: (value: string) => {
                  const count = value?.length || 0;
                  if (count >= 35) {
                    return {
                      valid: false,
                      message: `Single-line title max character is 35 char. Please use '<br>' for a linebreak.`
                    };
                  }
                  return {
                    valid: true,
                    message: `Character Count: ${count}`
                  };
                },
                fieldName: 'playerDescription',
              },
              key: 'playerDescription',
              isRequired: true,
              label: 'Player Description',
              disabled: false,
              columnWidth: 12,
            },
            {
              name: "Interval",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              clearField: true,
              key: 'interval',
              label: 'Interval',
              filter: true,
              showField: true,
              autoDisplayFirst: false,
              options: { fieldName: 'interval', values: [{ name: 'Continuous', value: 0 }, { name: 'Single / One and Done', value: 1 },] },
              optionLabel: 'name',
              optionValue: 'value',
              columnWidth: 6
            },
            {
              name: "Achievement Type",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              clearField: true,
              key: 'achievementType',
              showField: true,
              isRequired: true,
              label: 'Achievement Type',
              filter: true,
              autoDisplayFirst: false,
              options: {
                fieldName: 'achievementType', 
                values: achievementTypes
              },
              optionLabel: 'name',
              optionValue: 'value',
              displayControlType: "showHide",
              emitOnInit: true,
              displayControls: {
                ['challengeType']: (value: any) => this.achievementTypeDisplayControls(value, 'challengeType'),
                ['challengeClimate']: (value: any) => this.achievementTypeDisplayControls(value, 'challengeClimate'),
                ['challengeResultScore']: (value: any) => this.achievementTypeDisplayControls(value, 'challengeResultScore'),
                ['itemCategory']: (value: any) => this.achievementTypeDisplayControls(value, 'itemCategory'),
                ['isSpawnerType']: (value: any) => this.achievementTypeDisplayControls(value, 'isSpawnerType'),
                // ['itemCategories']: (value: any) => this.achievementTypeDisplayControls(value, 'itemCategories'),
                ['itemType']: (value: any) => this.achievementTypeDisplayControls(value, 'itemType'),
                ['minimumIAPCost']: (value: any) => this.achievementTypeDisplayControls(value, 'minimumIAPCost'),
                ['userHasBalance']: (value: any) => this.achievementTypeDisplayControls(value, 'userHasBalance'),
                ['currency_ref']: (value: any) => this.achievementTypeDisplayControls(value, 'currency_ref'),
              },
              columnWidth: 6
            },
            {
              name: "",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Divider],
              key: '',
              isRequired: false,
              clearField: false,
            },
            {
              name: "Achievement Type Options:",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Text],
              key: 'promoFields',
              isRequired: false,
              clearField: true,
              label: 'Achievement Type Options:',
              columnWidth: 12
            },
            // Challenges Submitted Type:
            {
              name: "Challenge Type",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              clearField: true,
              key: 'challengeType',
              label: 'Challenge Type',
              filter: true,
              autoDisplayFirst: false,
              showField: false,
              options: {
                fieldName: 'challengeType',
                apiController: 'challenge-types',
                autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id _id'
              },
              optionLabel: 'name',
              optionValue: "_id",
              columnWidth: 6
            },
            {
              name: "Challenge Climate",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              clearField: true,
              key: 'challengeClimate',
              label: 'Challenge Climate',
              filter: true,
              autoDisplayFirst: false,
              showField: false,
              options: {
                fieldName: 'challengeClimate',
                apiController: 'climates',
                autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id _id'
              },
              optionLabel: 'name',
              optionValue: "_id",
              columnWidth: 6
            },
            // Challenge Results w/ Score of X or above
            {
              name: "Score",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Number],
              clearField: true,
              key: 'challengeResultScore',
              isRequired: false,
              label: 'Score',
              showField: false,
              disabled: false,
              columnWidth: 12,
              minFractionDigits: 0,
              maxFractionDigits: 2,
              inputNumberMode: 'decimal',
            },
            // Items submitted
            // {
            //   name: "Item Categories",
            //   inputType: DynamicInputType.BaseInputField,
            //   inputTypeFields: [InputFieldType.MultiSelect],
            //   clearField: true,
            //   key: 'itemCategories',
            //   isRequired: false,
            //   label: 'Item Categories',
            //   labelKeys: ['id', 'name'],
            //   optionValue:'_id',
            //   optionLabel:'name',
            //   optionDisabled:'disabled',
            //   filter: true,
            //   filterBy:'name,id,path',
            //   autoDisplayFirst: false,
            //   setMongoId: true,
            //   options: {
            //     fieldName: 'itemCategories', apiController: 'categories',
            //     autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id _id',
            //     isArrayOfStringIds: true
            //   },
            //   columnWidth: 8
            // },
            {
              name: "is Spawner Type?",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.ToggleButton],
              clearField: true,
              key: 'isSpawnerType',
              isRequired: false,
              label: 'is Spawner Type?',
              onLabel: 'Yes',
              offLabel: 'No',
              showField: true,
              disabled: false,
              columnWidth: 3,
              displayControlType: "showHide",
              emitOnInit: true,
              displayControls: {
                ['isRequired']: (value: any) => value == true ? true : false,
              },
            },
            {
              name: "Spawner Type",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.ToggleButton],
              clearField: true,
              key: 'isRequired',
              isRequired: false,
              label: 'Spawner Type',
              onLabel: 'Required',
              offLabel: 'Optional',
              showField: false,
              disabled: false,
              columnWidth: 3,
            },
            // IAPs Completed
            {
              name: "Minimum IAP Cost",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Number],
              clearField: true,
              key: 'minimumIAPCost',
              isRequired: false,
              label: 'Minimum IAP Cost',
              showField: false,
              disabled: false,
              columnWidth: 12,
              inputNumberShowButtons: true,
              inputNumberMin: 0,
              inputNumberMax: 99.99,
              inputNumberMode: 'decimal',
              minFractionDigits: 0,
              maxFractionDigits: 2,
            },
            {
              name: "Item Category",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              clearField: true,
              key: 'itemCategory',
              label: 'Item Category',
              filter: true,
              autoDisplayFirst: false,
              showField: false,
              options: {
                fieldName: 'itemCategory',
                apiController: 'categories',
                autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id _id'
              },
              optionLabel: 'name',
              optionValue: "_id",
              columnWidth: 6
            },
            {
              name: "Item Type",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              clearField: true,
              key: 'itemType',
              label: 'Item Type',
              filter: true,
              autoDisplayFirst: false,
              showField: false,
              options: {
                fieldName: 'itemType',
                apiController: 'item-types',
                autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id _id'
              },
              optionLabel: 'name',
              optionValue: "_id",
              columnWidth: 6
            },
            // User Has Balance?
            {
              name: "User Has Balance?",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.ToggleButton],
              clearField: true,
              key: 'userHasBalance',
              isRequired: false,
              label: 'User Has Balance?',
              onLabel: 'Yes',
              offLabel: 'No',
              showField: true,
              disabled: false,
              columnWidth: 3,
              displayControlType: "showHide",
              emitOnInit: true
            },
            // Earn X Currency Field
            {
              name: 'Currency',
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              key: 'currency_ref',
              label: 'Currency',
              clearField: true,
              optionLabel: 'name',
              optionValue: "_id",
              filter: true,
              filterBy: "id,name",
              showClear: true,
              setMongoId: true,
              autoDisplayFirst: true,
              options: { 
                fieldName: 'currency_ref', apiController: 'currencies', 
                minimal: true, autopopulate: false, virtuals: false, 
                sort: { id: 1 }
              },
              columnWidth: 6,
              showField: false,
              displayControlType: "showHide",
              emitOnInit: true,
            },
            // end achievement type options
            {
              name: "",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Divider],
              key: '',
              isRequired: false,
              clearField: false,
            },
            // Daily Goals/Activities options
            {
              name: 'Is Daily Goal?',
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.InputSwitch],
              key: 'isDailyGoal',
              label: 'Is Daily Goal?',
              columnWidth: 3,
              displayControlType: "showHide",
              emitOnInit: true,
              displayControls: {
                ['expectedDiamondCost']: (value: any) => value == true ? true : false,
                ['goalType']: (value: any) => value == true ? true : false,
              },
            },
            {
              name: "Goal Type",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              clearField: true,
              key: 'goalType',
              label: 'Goal Type',
              filter: true,
              showField: false,
              autoDisplayFirst: false,
              options: { 
                fieldName: 'goalType', 
                values: [
                  { name: 'Evergreen', value: 0 },
                  { name: 'Live Ops', value: 1 },
                  { name: 'Segmented', value: 2 },
                ] 
              },
              optionLabel: 'name',
              optionValue: 'value',
              columnWidth: 4
            },
            {
              name: "Expected Diamond Cost",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Number],
              clearField: true,
              key: 'expectedDiamondCost',
              isRequired: false,
              label: 'Expected Diamond Cost',
              showField: false,
              disabled: false,
              columnWidth: 4,
              inputNumberShowButtons: true,
              inputNumberMin: 0,
              inputNumberMax: 10000,
            },
            // 
            {
              name: "Target(s)",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Chip],
              clearField: true,
              key: 'target',
              isRequired: true,
              label: 'Target(s)',
              showField: true,
              disabled: false,
              columnWidth: 12
            },
            {
              name: 'Link - Destination',
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Dropdown],
              clearField: true,
              key: 'link_ref',
              isRequired: false,
              label: 'Link - Destination',
              optionLabel: 'linkText',
              optionSubLabel: 'linkDestination',
              optionValue: '_id',
              filter: true,
              filterBy: 'id,linkText,linkDestination',
              showClear: true,
              autoDisplayFirst: false,
              setMongoId: true,
              style: { 'min-width': '30em' },
              options: {
                fieldName: 'link_ref',
                apiController: 'link-destination',
                minimal: false,
                autopopulate: true,
                virtuals: false,
                sort: { createdAt: 1 },
              },
            },
            {
              name: "Toggle Button",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.RepetitiveEvents],
              clearField: true,
              key: 'isRepetitive',
              isRequired: false,
              label: 'Toggle Button',
              onLabel: 'Is Repetitive/Scheduled',
              offLabel: 'Not Repetitive/Scheduled',
              showField: true,
              disabled: false,
              columnWidth: 3,
              displayControlType: "showHide",
              emitOnInit: true,
              displayControls: {
                ['repeatsDuration']: (value: any) => this.repetitiveDisplayControls(value, 'repeatsDuration'),
                ['repeats']: (value: any) => this.repetitiveDisplayControls(value, 'repeats'),
                ['repEventsOptions']: (value: any) => this.repetitiveDisplayControls(value, 'repEventsOptions'),
              },
            },
            {
              name: "",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Divider],
              key: '',
              isRequired: false,
              clearField: false,
            },
            {
              name: "Repetitive Events Options:",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Text],
              key: 'repEventsOptions',
              isRequired: false,
              clearField: true,
              label: 'Repetitive Events Options:',
              columnWidth: 12,
            },
            {
              name: "Duration (Hrs)",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Number],
              clearField: true,
              isRequired: false,
              key: 'repeatsDuration',
              label: 'Duration (Hrs)',
              inputNumberShowButtons: true,
              columnWidth: 2
            },
            {
              name: "",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.SpaceCol],
              key: '',
              label: '',
              columnWidth: 2
            },
            {
              name: "Available On:",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Text],
              key: 'repEventsOptions',
              isRequired: false,
              clearField: true,
              label: 'Available On:',
              columnWidth: 2,
            },            
            {
              name: "Available On",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.MultipleCheckbox],
              key: 'repeats',
              label: 'Available On',
              helperText: 'Determines which days it shows up on',
              showField: true,
              disabled: false,
              options: { fieldName: 'repeats', values: this.weekDays },
              columnWidth: 6,
            },
            {
              name: "",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.Divider],
              key: '',
              isRequired: false,
              clearField: false,
            },
            {
              name: 'Reward(s)',
              inputType: DynamicInputType.Price,
              key: 'rewards_ref',
              options: { fieldName: '', version: 3 },
              columnWidth: 6
            },
            {
              name: 'Promo Rewards(s)',
              inputType: DynamicInputType.Price,
              key: 'promoRewards_ref',
              options: { fieldName: '', version: 3 },
              columnWidth: 6
            },
          ],
          type: DynamicCardType.Card
        }
      ]

    this.title = "Achievements";
    this.viewRoute = 'achievements';
    this.isLoading = false;
  }

  achievementTypeDisplayControls(value: number, fieldName: string): boolean {
    // Define which fields should be shown for each achievement type
    switch (value) {
      case 0: // Challenges submitted
        return ['challengeClimate', 'challengeType'].includes(fieldName);

      case 2: // Challenge Results w/ Score of X or above
        return fieldName === 'challengeResultScore';

      case 3: // Items submitted
        return ['itemCategory', 'itemType', 'itemCategories', 'isSpawnerType'].includes(fieldName);

      case 8: // IAPs completed
        return fieldName === 'minimumIAPCost';

      case 18: // Water used
      case 19: // Gifts sent
        return fieldName === 'userHasBalance';

      case 20: // Earn X Currency
        return fieldName === 'currency_ref';
      default:
        return false;
    }
  }

  repetitiveDisplayControls(value: any, fieldName: string): boolean {
    return value == true ? true : false;
  }

  triggerCustomValues(event: any) {
    if(event.field == "isDailyGoal" && event.value == true) {
      this.achievementRecord.interval = 1;
    }
  }

}
