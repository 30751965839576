<p-accordion (onOpen)="loadBuildErrorLogs()">
    <p-accordionTab header="Build Error Logs">
        <ng-template pTemplate="content">
            <div *ngIf="isLoading" class="flex justify-content-center">
              Loading...
            </div>

            <ul *ngIf="!isLoading && buildErrorLogs?.length">
                <li *ngFor="let log of buildErrorLogs; let i = index" class="flex align-items-center gap-2 mb-2">
                    {{log.LastModified | officeTime}}
                    <button pButton icon="pi pi-eye" class="p-button-text p-button-sm"
                        (click)="previewLog(log.Key)" pTooltip="Preview log"></button>
                    <button pButton icon="pi pi-download" class="p-button-text p-button-sm"
                        (click)="downloadLog(log.Key)" pTooltip="Download log"></button>
                </li>
            </ul>

            <div *ngIf="!isLoading && !buildErrorLogs?.length" class="text-center">
                No build error logs found
            </div>
        </ng-template>
    </p-accordionTab>
</p-accordion>

<p-dialog [(visible)]="showPreviewDialog" [header]="'Log Preview'" [modal]="true" 
    [style]="{width: '90vw', height: '90vh'}" [maximizable]="true">
    <ng-template pTemplate="header">
        Log Preview
    </ng-template>
    <div class="log-preview" #logContainer>
        <pre style="white-space: pre-wrap; word-wrap: break-word; height: 100%; overflow: auto;" [innerHTML]="sanitizedPreviewContent"></pre>
    </div>
</p-dialog>