import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NotificationsService } from '../common/services/notifications.service';
import { UtilitiesService } from '../common/services/utilities.service';
import { Title } from '@angular/platform-browser';
import JSConfetti from 'js-confetti';
import { AuthService } from '../auth/auth.service';
import BuildGameDataSlackNotification from './dtos/BuildGameDataSlackNotification';
import { BuildGameDataEnvs } from './enums/BuildGameDataEnvs';
import { GamedataService } from './services/gamedata.service';
import { SocialUser } from '@abacritt/angularx-social-login';
import { LoggerService } from '../common/services/logger.service';
import * as _ from 'lodash';
import { SettingsService } from '../entities/settings/services/settings.service';
import { SpinnerService } from '../common/services/spinner.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-build-gamedata',
  templateUrl: './build-gamedata.component.html',
  styleUrls: ['./build-gamedata.component.sass'],
  providers: [DataService, ConfirmationService],
})
export class BuildGamedataComponent implements OnInit
{
  constructor
  (
    private dataService: DataService,
    private messageService: MessageService,
    private notificationsService: NotificationsService,
    private authService: AuthService,
    private gamedataService: GamedataService,
    private loggerService: LoggerService,
    private confirmationService: ConfirmationService,
    private utilitiesService: UtilitiesService,
    private settingsService: SettingsService,
    private titleService: Title,
    private spinnerService: SpinnerService
  ) {}

  currentUser: SocialUser = new SocialUser();
  isLoading: boolean = false;
  isInit: boolean = false;
  environmentName: 'development' | 'production' | 'test';
  gamedataLogs = {
    enabled: true,
    devGamedata: {},
    qaGamedata: {},
    testGamedata: {},
    prodGamedata: {},
    method: '',
    s3id: null,
  }
  gamedataLogsDef = {
    enabled: true,
    devGamedata: {},
    qaGamedata: {},
    testGamedata: {},
    prodGamedata: {},
    method: '',
    s3id: null,
  }
  successConfetti: Array<any> =
  [
    '🌸',
    '🌷',
    '🌺',
    '💮',
    '💐',
    '🌹',
    '🌼',
    '🌻',
    '🌱',
    '🌴',
    '🌲',
    '🌳',
    '🌿',
  ];
  failedConfetti: Array<any> = ['💩'];
  cmsSettings: any;

  jsConfetti = new JSConfetti();
  get buildEnv(): typeof BuildGameDataEnvs {
    return BuildGameDataEnvs;
  }

  /**
   * Build Gamedata Component Initialization
   */
  async ngOnInit()
  {
    if(window.location.href.includes('prod.cms') ){
      this.environmentName = 'production';
    } else if (window.location.href.includes('test.cms') ) {
      this.environmentName = 'test';
    } else {
      this.environmentName = 'development';
    }
    this.isInit = true;
    let user = this.authService.getSocialUser();
    this.currentUser = user.currentUser;
    this.titleService.setTitle('Build Gamedata')

    let result = await this.settingsService.getSettingByQuery({ query: { name: 'cms-settings' }});
    this.loggerService.log("CMS Settings: ", result);
    if(result)
    {
      this.cmsSettings = result;
    }
    this.isInit = false;
  }

  /**
   * Build Game data to a desired environment
   *
   * @param env Environmet to push game data
   */
  async onGameDataBuild(env: string, isConsecutive: boolean = false)
  {
    this.spinnerService.loadSpinner();
    this.isLoading = true;
    let request =
    {
      user: { firstName: this.currentUser.firstName, lastName: this.currentUser.lastName },
      isConsecutive: isConsecutive
    }
    let payload: BuildGameDataSlackNotification =
    {
      User: this.currentUser.name,
      Environment: env == 'dev' ? 'Development' : env == 'stage' ? 'QA' : 'Test',
      Status: 'Building',
      Message: 'Building Gamedata',
    };

    // Building Game Data
    await this.sendSlackMessage(payload);
    try
    {
      let response = await this.gamedataService.buildGamedata(env, request);

      console.log("Build Gamedata response: ", response);

      this.gamedataLogs.s3id = response && response.buildVersion ? response.buildVersion : null;

      if(response && response.success)
      {
        console.log("Build Gamedata Success response.");
        this.messageService.add(
        {
          severity: 'success',
          summary: this.environmentName == 'production' ? 'Gamedata built' : 'Gamedata built',
          detail: this.environmentName == 'production' ?
            `Gamedata was built successfully and uploaded to S3. Build Version: ${response.buildVersion}`
              : this.environmentName == 'test' ?
                `*Test* Gamedata was built successfully and uploaded to S3. Build Version: ${response.buildVersion}`
                : `*Development*  Gamedata was built successfully and skipped s3 upload. NODE_ENV: ${this.environmentName} | Build Version: ${response.buildVersion}`,
        });

        // Game data build logs storage
        switch (env)
        {
          case 'dev':
            this.gamedataLogs.devGamedata = {'status': 'Built and Uploaded', 'message': response, buildVersion: response.buildVersion };
            this.gamedataLogs.method = 'Manual'
            this.dataService.addNewRecord('gamedatalogs', this.gamedataLogs);
            this.gamedataLogs = _.cloneDeep(this.gamedataLogsDef);
            break;
          case 'test':
            this.gamedataLogs.testGamedata = {'status': 'Built and Uploaded', 'message': response, buildVersion: response.buildVersion };
            this.gamedataLogs.method = 'Manual'
            this.dataService.addNewRecord('gamedatalogs', this.gamedataLogs);
            this.gamedataLogs = _.cloneDeep(this.gamedataLogsDef);
            break;
          case 'stage':
            this.gamedataLogs.qaGamedata = {'status': 'Built and Uploaded', 'message': response, buildVersion: response.buildVersion };
            this.gamedataLogs.method = 'Manual'
            await this.dataService.addNewRecord('gamedatalogs', this.gamedataLogs);
            this.gamedataLogs = _.cloneDeep(this.gamedataLogsDef);
            break;
        }
        // Success Game Data Build
        window.scrollTo(0, 0);
        this.jsConfetti.addConfetti(
        {
          emojis: this.successConfetti,
          confettiNumber: 50,
        });
        this.isLoading = false;
        this.spinnerService.endSpinner();
        return { Success: true };
      }
      else
      {
        console.log("Build Gamedata Error response.", response);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: response.error ? response.error  : 'Build Error',
          detail: response.message ? response.message  : 'There was an error in the build.',
        });

        // Game data build logs storage
        switch (env)
        {
          case 'dev':
            this.gamedataLogs.devGamedata = {'status': 'Failed', 'message': response.message ? response.message : '', 'error': response.error ? response.error : ''};
            this.gamedataLogs.method = 'Manual'
            this.dataService.addNewRecord('gamedatalogs', this.gamedataLogs);
            this.gamedataLogs = _.cloneDeep(this.gamedataLogsDef);
            break;
          case 'dev':
            this.gamedataLogs.testGamedata = {'status': 'Failed', 'message': response.message ? response.message : '', 'error': response.error ? response.error : ''};
            this.gamedataLogs.method = 'Manual'
            this.dataService.addNewRecord('gamedatalogs', this.gamedataLogs);
            this.gamedataLogs = _.cloneDeep(this.gamedataLogsDef);
          break;
          case 'stage':
            this.gamedataLogs.qaGamedata = {'status': 'Failed', 'message': response.message ? response.message : '', 'error': response.error ? response.error : ''}
            this.gamedataLogs.method = 'Manual'
            this.dataService.addNewRecord('gamedatalogs', this.gamedataLogs);
            this.gamedataLogs = _.cloneDeep(this.gamedataLogsDef);
            break;
        }

        window.scrollTo(0, 0);
        this.jsConfetti.addConfetti({
          emojis: this.failedConfetti,
          confettiNumber: 10,
        });
        this.isLoading = false;
        this.spinnerService.endSpinner();
        let isGamedataBuildInProgress =  response.isGamedataBuildInProgress;
        if(response && response.isGamedataBuildInProgress)
        {
          payload.Status = 'Skipped';
          payload.Message = 'A Gamedata build is already in progress.';
          await this.sendSlackMessage(payload);
        }
        return { Success: false, isGamedataBuildInProgress: isGamedataBuildInProgress };
      }
    }
    catch (response: any)
    {
      console.log("Build Gamedata Catch Error response.", response);
      payload.Status = 'Failed';
      switch (response.status)
      {
        case 403:
          payload.Message = response && response.error ? response.error.error : "There was an error in the build.";
          break;
        case 400:
          payload.Message = response && response.error && response.error.error == 'Gamedata Validation' ? response.error.message :(response && response.error ? response.error.s3Response : "There was an error in the build.");
          break;

        default:
          payload.Message = "There was an error in the build.";
          break;
      }
      this.loggerService.log('error response: ', response);

      // Ignore 504, Tiemout error.
      if (response.status != 504) {
        // Error Gamedata build
        await this.sendSlackMessage(payload);
        this.messageService.add({
          severity: 'error',
          summary:  'Build Error',
          detail: 'There was an error in the build.'
        });
        this.isLoading = false;
        this.spinnerService.endSpinner();
        return { Success: false, isGamedataBuildInProgress: false };
      } else {
        this.isLoading = false;
        this.spinnerService.endSpinner();
        window.scrollTo(0, 0);
        this.jsConfetti.addConfetti(
        {
          emojis: this.successConfetti,
          confettiNumber: 50,
        });
        return { Success: true };
      }
    }
  }

  /**
   * Build Game data to a desired environment
   *
   * @param env Environmet to push game data
   */
  async onGameDataBuildV3(env: string, isConsecutive: boolean = false) {
    this.spinnerService.loadSpinner();
    this.isLoading = true;
    let request = {
      isConsecutive: isConsecutive,
      exportAll: true
    };

    try {
      const response = await this.gamedataService.buildGamedataV3(env, request);

      console.log("Build Gamedata response: ", response);
      let isGamedataBuildInProgress =  response.isGamedataBuildInProgress;

      if (response && response.success) {
        console.log("Build Gamedata Success response.");
        this.messageService.add({
          severity: 'success',
          summary: 'Building Gamedata',
          detail: 'The Gamedata is building. Please, pay attention to the notifs-gamedata channel on Slack',
        });

        this.isLoading = false;
        this.spinnerService.endSpinner();
        window.scrollTo(0, 0);
        this.jsConfetti.addConfetti({
          emojis: this.successConfetti,
          confettiNumber: 50,
        });
        return { Success: true };
      } else {
        this.jsConfetti.addConfetti({
          emojis: this.failedConfetti,
          confettiNumber: 10,
        });
        this.isLoading = false;
        this.spinnerService.endSpinner();
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: response.error ? response.error  : 'Build Error',
          detail: response.message ? response.message  : 'There was an error in the build.',
        });
        return { Success: false, isGamedataBuildInProgress };
      }

    } catch (error: any) {
      this.isLoading = false;
      this.jsConfetti.addConfetti({
        emojis: this.failedConfetti,
        confettiNumber: 10,
      });
      this.isLoading = false;
      this.spinnerService.endSpinner();

      if (error.status == 504) {
        return { Success: true };
      }
      
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Build Error',
        detail: 'There was an error in the build.',
      });
      return { Success: false };
    }
  }

  /**
   * Sends slack notification to flora-gamedata channel
   *
   * @param payload Payload with message details.
   */
  async sendSlackMessage(payload: any)
  {
    await this.notificationsService.sendGameDataBuildNotification(
      payload
    );
  }

  /**
   * Display Build Dev and QA Gamedata Modal
   */
  async displayConfirmBuildDevAndQAModal()
  {
    this.confirmationService.confirm(
    {
      message: 'Are you sure that you want to Build Dev and QA Gamedata? QA Build Gamedata will only be executed if Dev gamedata build was successful.',
      header: 'Build DEV + QA Gamedata',
      icon: 'pi pi-exclamation-triangle',
      accept: async () =>
      {
        await this.onBuildDevAndQAGamedata();
      },
      reject: () =>
      {
        return;
      }
    });
  }

  /**
   * Display Build Dev and QA Gamedata Modal
   */
  async displayConfirmBuildDevAndQAModalV3() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Build Dev and QA Gamedata V3? QA Build Gamedata will only be executed if Dev gamedata build was successful.',
      header: 'Build DEV + QA Gamedata V3',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await this.onBuildDevAndQAGamedataV3();
      },
      reject: () => {
        return;
      }
    });
  }

  /**
   * Builds Game data for Dev and QA.
   *
   * QA will trigger only if Build Dev Gamedata was successful.
   */
  async onBuildDevAndQAGamedataV3() {
    let response = await this.onGameDataBuildV3('dev', true);

    if (response && response.Success == true) {
      this.messageService.add({
        severity: 'info',
        summary:  'QA Build V3',
        detail: 'QA Build V3 will be executed in the background if dev push response callback is successful. Please see the results in the slack channel.'
      });
    }
    
    this.spinnerService.endSpinner();
  }

  /**
   * Builds Game data for Dev and QA.
   *
   * QA will trigger only if Build Dev Gamedata was successful.
   */
  async onBuildDevAndQAGamedata()
  {
    let response = await this.onGameDataBuild('dev', true);

    if(response && response.Success == true)
    {
      this.messageService.add(
      {
        severity: 'info',
        summary:  'QA Build',
        detail: 'QA Build will be executed in the background if dev push response callback is successful. Please see the results in the slack channel.'
      });
      this.spinnerService.endSpinner();
    }
    else if(response && response.Success == false && response.isGamedataBuildInProgress)
    {
      this.spinnerService.endSpinner();
    }
    else
    {
      let payload: BuildGameDataSlackNotification =
      {
        User: this.currentUser.name,
        Environment: 'Development',
        Status: 'Failed',
        Message: 'Build Dev Gamedata failed.',
      };
      await this.sendSlackMessage(payload);
      this.spinnerService.endSpinner();
    }
  }
}
