import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


export interface Field {
  name: string;
  type: string;
  required: boolean;
  label?: string;
  inputWidget?: string;
  onLabel?: string;
  offLabel?: string;
  columnWidth?: number;
  enabled: boolean;
  gamedataExportEnabled: boolean;
  gamedataExportEnabledLabel?: string;
  readonly?: boolean;
  position?: number;
}

export interface Metadata {
  id?: string;
  modelName: string;
  modelLabel: string;
  enabled: boolean;
  gamedataExportEnabled: boolean;
  fields: Array<Field>;
}

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  private apiUrl = `/api/dynamic-entities`;

  constructor(private http: HttpClient) {}

  getAllMetadata(): Observable<Metadata[]> {
    return this.http.get<Metadata[]>(this.apiUrl);
  }

  getMetadataById(id: string): Observable<Metadata> {
    return this.http.get<Metadata>(`${this.apiUrl}/${id}`);
  }

  createMetadata(metadata: Metadata): Observable<Metadata> {
    return this.http.post<Metadata>(this.apiUrl, metadata);
  }

  updateMetadata(id: string, metadata: Metadata): Observable<Metadata> {
    return this.http.patch<Metadata>(`${this.apiUrl}/${id}`, metadata);
  }

  getApiControllers(): Observable<string[]> {
    return this.http.get<string[]>('/api/controllers');  // Adjust endpoint as needed
  }
}