import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';

@Component({
  selector: 'app-vendor-form-v3',
  templateUrl: './vendor-form-v3.component.html',
  styleUrls: ['./vendor-form-v3.component.sass']
})
export class VendorFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  vendorRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Vendor Form V3 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Vendor Data",
        fields:
        [
          { 
            name: "Name", 
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],  
            clearField: true, 
            key: 'name', 
            isRequired: true, 
            label: 'Name', 
            disabled: false 
          },
        ],
        type: DynamicCardType.Card
      },
    ]

    this.title = "Vendor";
    this.viewRoute = 'vendors';
    this.isLoading = false;
  }
}