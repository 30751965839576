export default class DefaultTracks {
    FreeDefaultTrack: Array<any> = [
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 2,
                    name: "Coin",
                    _id: "6181ebcd7ce55a60f7ed6f88"
                },
                c: 3000,
                lineItemType: "Currency"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 2,
                    name: "Coin",
                    _id: "6181ebcd7ce55a60f7ed6f88"
                },
                c: 3000,
                lineItemType: "Currency"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 2,
                    name: "Coin",
                    _id: "6181ebcd7ce55a60f7ed6f88"
                },
                c: 3000,
                lineItemType: "Currency"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 1,
                    name: "Diamond",
                    _id: "6181ebd17ce55a6cf4ed6f93"
                },
                c: 3250,
                lineItemType: "Currency"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
    ]

    FreeDefaultStageMilestones: Array<number> = [2, 10, 18, 33, 46, 67, 82, 98]

    PaidDefaultTrack: Array<any> = [
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 1,
                    name: "Diamond",
                    _id: "6181ebd17ce55a6cf4ed6f93"
                },
                c: 5000,
                lineItemType: "Currency"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 1,
                    name: "Diamond",
                    _id: "6181ebd17ce55a6cf4ed6f93"
                },
                c: 5000,
                lineItemType: "Currency"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 1,
                    name: "Diamond",
                    _id: "6181ebd17ce55a6cf4ed6f93"
                },
                c: 5000,
                lineItemType: "Currency"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 1,
                    name: "Diamond",
                    _id: "6181ebd17ce55a6cf4ed6f93"
                },
                c: 5000,
                lineItemType: "Currency"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 2,
                    name: "Item",
                    start: null,
                    _id: "617b629b8e2f0ed1c7f86c82",
                },
                id: null,
                c: 5,
                lineItemType: "Item"
            }
        ],
        [
            {
                t: {
                    enabled: true,
                    end: null,
                    id: 1,
                    name: "Currency",
                    start: null,
                    _id: "617b62958e2f0e4a67f86c76",
                },
                id: {
                    apiControllerRoute: "currencies",
                    enabled: true,
                    id: 1,
                    name: "Diamond",
                    _id: "6181ebd17ce55a6cf4ed6f93"
                },
                c: 5000,
                lineItemType: "Currency"
            }
        ],
    ]

    PaidDefaultStageMilestones: Array<number> = [0, 4, 7, 14, 23, 28, 40, 53, 60, 75, 90, 106]
}