import { Component, OnDestroy, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-refs-cell-renderer',
  templateUrl: './refs.component.html',
  styleUrls: ['./refs.component.sass']
})
export class RefsCellRendererComponent implements ICellRendererAngularComp, OnDestroy {
  constructor(
    private tableCommunicationService: TableCommunicationService
  ) { }

  public params: any;
  timestamp = new Date().getTime();
  private optionsSubscription: Subscription;
  options: any;
  agInit(params: any): void {
    this.params = params;
    this.params.key = this.params.key ? this.params.key : 'name';
    if (this.params.extractNameFromOptions && this.params.entity === 'collections') {
      this.optionsSubscription = this.tableCommunicationService.optionsUpdate$.subscribe(options => {
        this.options = options;
      });
    }
  }

  ngOnDestroy(): void {
    // Clean up subscription to prevent memory leaks
    if (this.optionsSubscription) {
      this.optionsSubscription.unsubscribe();
    }
  }

  refresh(params: any): boolean {
    return false;
  }

  getLink(id: number | string) {
    // Check if this.params.data and this.params.data.sourcingGroup_ref are defined
    if (this.params?.isSourcingItem && this.params?.data && this.params?.data?.sourcingGroup_ref) {
      return `sourcing-groups/${this.params.data.sourcingGroup_ref.id}?global=${id}`;
    } else if (this.params?.isGuaranteedRarity && this.params?.data && this.params?.data?.guaranteedRarity_ref) {
      return `nurture-rarity/${this.params.value.id}`;
    } else if (typeof this.params?.linkFormatter === 'function') {
      return this.params.linkFormatter(this.params);
    }

    return `${this.params.entity}/${id}`;
  }

  handleClick(event: Event, value: any) {
    event.preventDefault();
    this.params.onClick(value);
  }

  extractNameFromOptions(id: number) {
    return this.options[this.params.optionsName].find((option: any) => option.id === id)?.name;
  }
}
