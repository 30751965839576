import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { MessageService } from 'primeng/api';
import { cardConfig } from 'src/app/cms-v2/entities/item/config/item-grid-view-config';
import { AgGridTableComponent } from 'src/app/common/ag-grid-table/ag-grid-table.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';

@Component({
  selector: 'table-input',
  templateUrl: './table-input.component.html',
  styleUrls: ['./table-input.component.sass'],
  providers: [AgGridTableComponent, MessageService],
})
export class TableInputComponent implements OnInit {
  @Input() field: any;
  @Input() record: any;
  @Input() isTableInput: boolean = false;
  @Input() deficolumn: ColDef[];
  @Input() entityType: string;

  @Output() onChange = new EventEmitter<any>();

  private options: any = {};
  public entity: string;
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;

  public autopopulateSelect: string = '';
  public buildParams: any = {};

  public cardConfig: any = new cardConfig().cardConfig;
  public tableParams: any = {};
  public rowData: any = [];
  public filteredRowData: any = [];
  public tableInputParams: string;
  selectedItems: any[] = [];

  constructor(
    private agGridToolsService: AgGridToolsService,
    private messageService: MessageService
  ) {
    this.autopopulateSelect = 'prefab_ref thumbnail_ref';
  }

  async ngOnInit(): Promise<void> {
    console.log('tic entity: ', this.entity);
    this.isTableInput = this.field.isTableInput ? this.field.isTableInput : false;

    if(this.field && this.field.tableInputParams){
      this.tableInputParams = this.field.tableInputParams;
    }

    this.rowData = Array.isArray(this.record[this.field.key]) ? this.record[this.field.key] : [];
    this.filteredRowData = [...this.rowData];

    this.rowData = this.rowData.map((item: { thumbnail_ref: { thumbnails: { path: any; }[]; }; id: any; }) => {
      const thumbnailImg = item.thumbnail_ref && item.thumbnail_ref.thumbnails && item.thumbnail_ref.thumbnails[1]
        ? item.thumbnail_ref.thumbnails[1].path
        : 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
      return {
        ...item,
        thumbnail_img: thumbnailImg
      };
    });

    this.entity = this.field.entity ? this.field.entity : 'items';

    // Usa deficolumn si está disponible, de lo contrario usa columnas predeterminadas
    this.columnDefs = this.deficolumn ? this.deficolumn : [
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc', 'desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Name',
        field: 'name',
        cellRendererParams: {
          entity: 'collections'
        },
        cellRenderer: 'agGroupCellRenderer',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
    ];

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }

  onSearch(event: Event): void {
    const value = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredRowData = this.rowData.filter((row: { name: string; id: { toString: () => string | string[]; }; }) =>
      row.name.toLowerCase().includes(value) || row.id.toString().includes(value)
    );
  }

  getThumbnailUrl(rowData: any): string {
    if (rowData.thumbnail_ref && rowData.thumbnail_ref.thumbnails && rowData.thumbnail_ref.thumbnails[1]) {
      return rowData.thumbnail_ref.thumbnails[1].path;
    } else {
      return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
    }
  }

  handleSelectionChange(selectedData: any[]) {
    if (!Array.isArray(this.record[this.field.key])) {
      this.record[this.field.key] = [];
    }

    const existingDataIds = this.record[this.field.key].map((data: { id: any; }) => data.id);
    const newSelectedData = selectedData.filter(data => !existingDataIds.includes(data.id));

    if (newSelectedData.length > 0) {
      this.record[this.field.key] = [...this.record[this.field.key], ...newSelectedData];
      this.filteredRowData = [...this.record[this.field.key]];

      this.onRecordChange(this.record[this.field.key]);
    }
  }

  onRecordChange(updatedItemsRef: any) {
    this.onChange.emit(updatedItemsRef);
  }

  removeRow(rowIndex: number): void {
    if (rowIndex >= 0 && rowIndex < this.filteredRowData.length) {
      const itemToRemove = this.filteredRowData[rowIndex];
      const idToRemove = itemToRemove.id;
      const nameToRemove = itemToRemove.name;

      this.filteredRowData.splice(rowIndex, 1);
      this.filteredRowData = [...this.filteredRowData];

      const originalRowIndex = this.rowData.findIndex((item: { id: any; }) => item.id === idToRemove);
      if (originalRowIndex !== -1) {
        this.rowData.splice(originalRowIndex, 1);
        this.rowData = [...this.rowData];
      }

      this.record[this.field.key] = this.filteredRowData;

      this.messageService.add({
        severity: 'success',
        summary: 'Item Removed',
        detail: `Item "${nameToRemove}" has been removed.`,
        life: 3000
      });

      this.onRecordChange(this.record[this.field.key]);
    } else {
      console.error(`Invalid rowIndex: ${rowIndex}`);
    }
  }

  deleteSelectedItems(): void {
    if (this.selectedItems && this.selectedItems.length > 0) {
      const itemsToRemoveCount = this.selectedItems.length;

      this.rowData = this.rowData.filter((row: any) => !this.selectedItems.includes(row));
      this.filteredRowData = this.filteredRowData.filter((row: any) => !this.selectedItems.includes(row));

      this.selectedItems = [];

      this.record[this.field.key] = this.filteredRowData;

      this.messageService.add({
        severity: 'success',
        summary: 'Items Removed',
        detail: `${itemsToRemoveCount} items have been removed.`,
        life: 3000
      });

      this.onRecordChange(this.record[this.field.key]);
    } else {
      console.error('No items selected for deletion.');
    }
  }

  handleItemsGenerated(items: any[]): void {
    if (!Array.isArray(this.record[this.field.key])) {
      this.record[this.field.key] = [];
    }

    const existingIds = this.record[this.field.key].map((item: { id: any; }) => item.id);

    const newUniqueItems = items.filter(item => !existingIds.includes(item.id));

    if (newUniqueItems.length > 0) {
      this.record[this.field.key] = [...this.record[this.field.key], ...newUniqueItems];
      this.filteredRowData = [...this.record[this.field.key]];

      this.messageService.add({
        severity: 'success',
        summary: 'Items Added',
        detail: `${newUniqueItems.length} items have been added.`,
        life: 3000
      });

      this.onRecordChange(this.record[this.field.key]);
    } else {
      this.messageService.add({
        severity: 'info',
        summary: 'No New Items',
        detail: 'No new items were added because they already exist in the list.',
        life: 3000
      });
    }
  }
}
