<div [style]="{'position': 'fixed', 'overflow': 'visible', 'z-index': '999'}">
  <p-calendar #agCalendar class="'ag-custom-component-popup'" [showButtonBar]="true" [showClear]="true"
    [(ngModel)]="selectedDate" [showTime]="true" [monthNavigator]="true" [hideOnDateTimeSelect]="true"
    [yearNavigator]="true" yearRange="2020:2030" (onSelect)="onDateSelect($event)">
    <ng-template pTemplate="footer" *ngIf="!checkTimeZone">
      <h5 class="p-d-inline">Local Time: </h5><small>{{ selectedDate ? (localDate | date: 'medium') : ''   }}</small>
    </ng-template>
  </p-calendar>
  <button pButton pRipple ariaLabel="Revert Nested Values" icon="pi pi-undo" class="p-mx-2 p-button-text p-button-xs"
    tabindex="-1" (click)="setOriginalValue()"></button>

</div>
