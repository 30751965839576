import { Component, OnInit, ViewChild } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef } from 'ag-grid-enterprise';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { GettersService } from 'src/app/common/services/getters.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-daily-rewards-table-ag-grid',
  templateUrl: './daily-rewards-table-ag-grid.component.html',
  styleUrls: ['./daily-rewards-table-ag-grid.component.sass']
})
export class DailyRewardsTableAgGridComponent implements OnInit {

  entity: string = 'daily-rewards';
  columnDefs: ColDef[] = [];
  columnSelection: string;
  rows: any[] = [];
  columnsPreProcessing: any = {}
  autopopulateSelect: string = '';
  options: any = {};
  public bulkUpdateColDefs: any[] = [];

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) { }

  async ngOnInit(): Promise<void> {

    await this.setOptions();
    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Internal Name',
        field: 'internalName',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Type',
        field: 'type',
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        type: 'editableColumn',
        cellRenderer: (params: any) => {
            const typeMapping = [
                { label: 'Login Rewards - 7 days', value: 1 },
                { label: 'Login Rewards - Day 30', value: 2 },
                { label: 'Garden Pass - Free', value: 3 },
                { label: 'Garden Pass - Paid', value: 4 }
            ];
            const matchedType = typeMapping.find(type => type.value === params.value);
            return matchedType ? matchedType.label : 'Unknown';
        },
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: [
                'Login Rewards - 7 days',
                'Login Rewards - Day 30',
                'Garden Pass - Free',
                'Garden Pass - Paid'
            ]
        },
        valueSetter: (params: any) => {
            const typeMapping = [
                { label: 'Login Rewards - 7 days', value: 1 },
                { label: 'Login Rewards - Day 30', value: 2 },
                { label: 'Garden Pass - Free', value: 3 },
                { label: 'Garden Pass - Paid', value: 4 }
            ];
            const matchedType = typeMapping.find(type => type.label === params.newValue);
            if (matchedType) {
                params.data.type = matchedType.value;
                return true;
            }
            return false;
        }
      },
      {
        headerName: 'Reward Tracks',
        field: 'rewardsTracks',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track',
          isArray: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.rewardsTracks,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
          
        },
        floatingFilter: true,

        autoHeight: true,
        width: 350, // Set the width of the column
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ]

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];
  }

  async setOptions(){
    const entities = [
      { ref: 'rewardsTracks', entity: 'rewards-track'}

    ];

    for (const { ref, entity } of entities) {
      const params = new OptionsParams({
        entity
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }
  }

}
