<div
  class="p-d-flex"
  style="
    white-space: normal;
    word-wrap: break-word;
    overflow-y: auto;
    text-align: left;
    line-height: 1.2;
    max-height: 110px;
  "
>
  <p>
    {{maxDate}}
  </p>
</div>


