import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { BuildType } from 'src/app/enums/build-type';
import NurtureCollectionDTO from 'src/app/cms-v2/entities/nurture/nurture-collection/dtos/NurtureCollectionDTO';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';


@Component({
  selector: 'app-release-nurture-collection-view',
  templateUrl: './release-nurture-collection.component.html',
  styleUrls: ['./release-nurture-collection.component.sass']
})
export class ReleaseNurtureCollectionComponent extends BaseDynamicViewComponent implements OnInit {
  entity: string = 'nurture-collection';  // Add this line
  options: any = {
    type_ref: [] // Initialize with appropriate values
  };


  nurtureCollectionRecord: NurtureCollectionDTO = new NurtureCollectionDTO();
  constructor(
      private agGridToolsService: AgGridToolsService,
    ) {
    super();
  }

  miscAssetKeys: Array<any> = ['image_ref', 'pinBackground_ref', 'seedPack_ref']
  parentViewName: string = 'nurtureCollectionView'

  async ngOnInit() {
    {
      console.log(this.nurtureCollectionRecord);
      this.fields = [
        {
          title: "Nurture Collection Data",
          fields:
          [
            {
              name: "Display Name",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'displayName',
              isRequired: false,
              label: 'Display Name',
              disabled: false,
              columnWidth: 6
            },
            {
              name: 'Nurture Collection Type',
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              key: 'type',
              label: 'Nurture Collection Type',
              options: { fieldName: 'type', values:
                [
                  {
                    label: 'Type',
                    value: 'Type',
                    items: [
                      {
                        label: 'Permanent',
                        value: 0,
                      },
                      {
                        label: 'Seasonal',
                        value: 1,
                      },
                    ],
                  },
                ]
              },
              clearField: false,
              autoDisplayFirst: false,
              filter: false,
              showClear: true,
              isRequired: true,
              dropdownGroup: true,
              scrollHeight: '350px',
              columnWidth: 6
            },
            // {
            //   name: "Region",
            //   inputType: DynamicViewType.BaseViewField ,
            //   viewTypes: [BaseViewFieldType.Text],
            //   clearField: true,
            //   key: 'region',
            //   isRequired: false,
            //   label: 'Region',
            //   columnWidth: 6
            // },
            {
              name: "Region Group",
              inputType: DynamicViewType.MiscRef,
              clearField: true,
              key: 'group',
              label: 'Region Group',
              controller: 'nurture-collection-group',
              columnWidth: 6
            },
            {
              name: "Priority",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'priority',
              label: 'Priority',
              inputNumberShowButtons: true,
              columnWidth: 6
            },
            { name: "",
              inputType: DynamicViewType.SpaceCol,
              clearField: true,
              key: '',
              label: '',
              columnWidth: 12
            },
            {
              name: "Start Date/Time",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'start',
              label: 'Start Date/Time',
              columnWidth: 6
            },
            {
              name: "Target Progress",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'targetProgress',
              label: 'Target Progress',
              inputNumberShowButtons: true,
              columnWidth: 6
            },
            {
              name: "End Date/Time",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'end',
              label: 'End Date/Time',
              columnWidth: 6
            },
            {
              name: "Target Nurture Collection",
              inputType: DynamicViewType.MiscRef,
              clearField: true,
              key: 'collection_ref',
              label: 'Target Nurture Collection',
              controller: 'nurture-collection',
              columnWidth: 6
            },
            {
              name: "Min Pulls 5/6",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'minPulls5',
              isRequired: false,
              label: 'Min Pulls 5/6',
              columnWidth: 6
            },
            {
              name: "Max Pulls 5/6",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'maxPulls5',
              isRequired: false,
              label: 'Max Pulls 5/6',
              columnWidth: 6
            },
            {
              name: "Min Pulls 6/6",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'minPulls6',
              isRequired: false,
              label: 'Min Pulls 6/6',
              columnWidth: 6
            },
            {
              name: "Max Pulls 6/6",
              inputType: DynamicViewType.BaseViewField ,
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'maxPulls6',
              isRequired: false,
              label: 'Max Pulls 6/6',
              columnWidth: 6
            },
            {
              name: "Coming Soon?",
              inputType: DynamicViewType.Boolean,
              clearField: true,
              key: 'comingSoon',
              label: 'Coming Soon?',
              columnWidth: 6
             },
             {
              name: "Collection Color",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.ColorDisplay],             
              clearField: false,
              key: 'color',
              label: 'Collection Color',
              columnWidth: 6
            },
          ],
          type: DynamicCardType.Card
        },
        {
          title: 'Nurture Items',
          type: DynamicCardType.Card,
          fields: [
            {
              name: "Nurtures", inputType: DynamicViewType.AgGridTable,
              key: 'nurtures_ref', label: 'Nurtures',
              options: {
                fieldName: 'nurtures',
                columnDefs:[
                  {
                    headerName: 'ID',
                    field: 'id',
                    width: 125,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    sortable: true,
                    initialSort: 'desc',
                    sortIndex: 0,
                    initialSortIndex: 0,
                    sort: 'desc',
                    sortingOrder: ['asc','desc'],
                    unSortIcon: false,
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                      filterOptions: [
                        'equals',
                        'notEqual',
                        'lessThan',
                        'lessThanOrEqual',
                        'greaterThan',
                        'greaterThanOrEqual',
                        'inRange'
                      ]
                    },
                    resizable: true,
                    floatingFilter: true,
                    suppressSizeToFit: true,
                  },
                  {
                    headerName: 'Name',
                    field: 'name',
                    cellRendererParams: {
                      entity: 'nurture'
                    },
                    cellRenderer: LinkCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    width: 350,
                    resizable: true,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                    cellClass: 'center-cell-content',
                    cellEditor: 'agTextCellEditor',
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Item Name',
                    field: 'itemName',
                    cellRenderer: TextCellRendererComponent,
                    resizable: true,
                    cellClass: 'center-cell-content',
                    valueGetter: (params: any) => {
                      if(params.data.itemId[0]){
                        return params.data.itemId[0].name
                      } else {
                        return 'undefined';
                      }
                    },
                  },
                  {
                    headerName: 'Item Thumb',
                    field: 'thumbnail_img',
                    sortable: false,
                    resizable: true,
                    width: 135,
                    autoHeight: true,
                    cellRenderer: ThumbnailCellRendererComponent,
                    valueGetter: (params: any) => {
                      const thumbnail = params.data?.itemId?.[0]?.thumbnail_ref?.thumbnails?.[1];
                      return thumbnail?.path || 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg';
                    },
                  },
                  {
                    headerName: 'Item ID',
                    field: 'itemId',
                    cellRenderer: TextCellRendererComponent,
                    resizable: true,
                    cellClass: 'center-cell-content',
                    valueGetter: (params: any) => {
                      if(params.data.itemId[0]){
                        return params.data.itemId[0].id
                      } else {
                        return 'undefined';
                      }
                    },
                  },
                  {
                    headerName: 'Rarity',
                    field: 'rarity',
                    cellRenderer: TextCellRendererComponent,
                    resizable: true,
                    cellClass: 'center-cell-content',
                    valueGetter: (params: any) => {
                      if(params.data.itemId[0]){
                        return params.data?.rarity_ref?.name || 'undefined';
                      } else {
                        return 'undefined';
                      }
                    },
                  },
                  {
                    headerName: 'Start',
                    field: 'start',
                    sortable: true,
                    unSortIcon: true,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    suppressSizeToFit: true,
                    width: 300,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      isDate: true,
                    },
                    valueGetter: this.agGridToolsService.dateValueGetter('start'),
                    resizable: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'End',
                    field: 'end',
                    sortable: true,
                    unSortIcon: true,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    suppressSizeToFit: true,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      isDate: true,
                      timePill: true
                    },
                    width: 300,
                    valueGetter: this.agGridToolsService.dateValueGetter('end'),
                    resizable: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'Enabled',
                    field: 'enabled',
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      onLabel: 'Enabled',
                      offLabel: 'Not Enabled',
                      isBoolean: true
                    },
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: [true, false],
                      valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                    },
                    floatingFilter: true,
                    cellClass: 'center-cell-content',
                  },
                ],
                defaultQueryKey: 'nurtures_ref',
                entity: 'nurture'
              }
            },
          ]
        },
        {
          title: 'Rewards',
          type: DynamicCardType.Card,
          fields: [
            {
              name: "Prizes(s)", 
              inputType: DynamicViewType.AgGridTable,
              key: 'rewards_ref', 
              label: 'Prizes(s)',
              options: {
                fieldName: '',
                isPrize: true,
                identifier: `${this.parentViewName}-prizes`,
                columnDefs: [
                  {
                    headerName: 'ID',
                    field: 'id',
                    width: 125,
                    headerCheckboxSelection: true,
                    checkboxSelection: true,
                    sortable: true,
                    initialSort: 'desc',
                    sortIndex: 0,
                    initialSortIndex: 0,
                    sort: 'desc',
                    sortingOrder: ['asc', 'desc'],
                    unSortIcon: false,
                    filter: 'agNumberColumnFilter',
                    filterParams: {
                      filterOptions: [
                        'equals',
                        'notEqual',
                        'lessThan',
                        'lessThanOrEqual',
                        'greaterThan',
                        'greaterThanOrEqual',
                        'inRange'
                      ]
                    },
                    resizable: true,
                    floatingFilter: true,
                    suppressSizeToFit: true,
                  },
                  {
                    headerName: 'Type',
                    field: 'type_ref',
                    cellRenderer: RefsCellRendererComponent,
                    cellRendererParams: {
                      entity: 'item-types'
                    },
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: this.options.type_ref,
                      valueFormatter: (params: any) => {
                        return `${params.value.name} (${params.value.id})`;
                      },
                      keyCreator: (params: any) => {
                        return params.value._id;
                      },
                      comparator: this.agGridToolsService.nameASC
                    },
                    floatingFilter: true,
  
                    cellEditor: DropdownEditorComponent,
                    cellEditorParams: {
                      optionValue: "_id",
                      optionLabel: "name",
                      objectValue: true
                    },
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Name',
                    field: 'name',
                    cellRendererParams: {
                      entity: this.entity,
                      isMultiple: true,
                      entities: ['items', 'currencies']
                    },
                    cellRenderer: LinkCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                    cellEditor: 'agTextCellEditor',
                    type: 'editableColumn',
                  },  
                  {
                    headerName: 'Quantity',
                    field: 'prizeCounts',
                    sortable: true,
                    unSortIcon: true,
                    suppressSizeToFit: true,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      entity: this.entity,
                      isPill: true
                    },
                    valueGetter: (params: any) => {
                      const prizeCounts = params.data.prizeCounts;
                      return prizeCounts !== undefined ? prizeCounts : 'Not Available';
                    },
                    resizable: true,
                    width: 150 
                  },
                  {
                    headerName: 'Enabled',
                    field: 'enabled',
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      onLabel: 'Enabled',
                      offLabel: 'Not Enabled',
                      isBoolean: true
                    },
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: [true, false],
                      valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                    },
                    floatingFilter: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'Thumbnail Record',
                    field: 'thumbnail_ref',
                    cellRenderer: MiscAssetCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agTextColumnFilter',
                    floatingFilter: true,
                    filterParams: {
                      filterKey: 'thumbnail_ref',
                      isAggregate: true,
                      fieldKeys: ['name', 'id', 'path'],
                      isMiscBuild: true,
                      populate: true,
                      select: '_id id name path thumbnails'
                    },
                    width: 500,
                    autoHeight: true,
                  },
                  {
                    headerName: 'Thumbnail',
                    field: 'thumbnail_img',
                    sortable: false,
                    resizable: true,
                    width: 135,
                    autoHeight: true,
                    cellRenderer: ThumbnailCellRendererComponent,
                    cellRendererParams: {
                      tableIdentifier: `${this.parentViewName}-prizes`,
                    },
                    valueGetter: (params: any) => {
                      let thumbnailPath = '';
                      if (params.data.thumbnail_ref && params.data.thumbnail_ref.thumbnails) {
                        thumbnailPath = params.data.thumbnail_ref.thumbnails.find((th: { size: number; }) => th.size === 256)?.path;
                      }
                      else if (params.data.thumbnails) {
                        thumbnailPath = params.data.thumbnails.find((th: { size: number; }) => th.size === 256)?.path;
                      }
                      else if (params.data.image_ref && params.data.image_ref.thumbnails) {
                        thumbnailPath = params.data.image_ref.thumbnails.find((th: { size: number; }) => th.size === 256)?.path;
                      }
                      else if (params.data.flyUpAnimation_ref && params.data.flyUpAnimation_ref.thumbnails) {
                        thumbnailPath = params.data.flyUpAnimation_ref.thumbnails.find((th: { size: number; }) => th.size === 256)?.path;
                      }
                      else if (params.data.thumbnail_ref && params.data.thumbnail_ref.thumbnails) {
                        thumbnailPath = params.data.thumbnail_ref.thumbnails.find((th: { size: number; }) => th.size === 128)?.path;
                      }
                      else if (params.data.imagePreview) {
                        thumbnailPath = params.data.imagePreview;
                      }
                      return thumbnailPath || 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                    },
                  },                                            
                  {
                    headerName: 'Start',
                    field: 'start',
                    sortable: true,
                    unSortIcon: true,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    suppressSizeToFit: true,
                    width: 300,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      isDate: true,
                    },
                    valueGetter: this.agGridToolsService.dateValueGetter('start'),
                    resizable: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'End',
                    field: 'end',
                    sortable: true,
                    unSortIcon: true,
                    filter: 'agDateColumnFilter',
                    floatingFilter: true,
                    suppressSizeToFit: true,
                    cellRenderer: TextCellRendererComponent,
                    cellRendererParams: {
                      isDate: true,
                      timePill: true
                    },
                    width: 300,
                    valueGetter: this.agGridToolsService.dateValueGetter('end'),
                    resizable: true,
                    cellClass: 'center-cell-content',
                  },
                  {
                    headerName: 'Content Status',
                    field: 'itemStatus',
                    cellRenderer: TextCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: ['Approved', 'Awaiting Revision', 'QA Ready'],
                      addEmptyFilters: true,
                    },
                    floatingFilter: true,
  
                    cellEditor: DropdownEditorComponent,
                    cellEditorParams: {
                      isFlatList: true
                    },
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Art Status',
                    field: 'vendorStatus',
                    cellRenderer: TextCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: ['Assigned', 'In Revision', 'Approved'],
                      addEmptyFilters: true,
                    },
                    floatingFilter: true,
  
                    cellEditor: DropdownEditorComponent,
                    cellEditorParams: {
                      isFlatList: true
                    },
                    type: 'editableColumn',
                  },
                  {
                    headerName: 'Promotion Status',
                    field: 'isAssetUpToDate',
                    cellRenderer: TextCellRendererComponent,
                    sortable: true,
                    unSortIcon: true,
                    resizable: true,
                    valueGetter: (params: any) => {
                      const lastHash = params.data.prefab_ref?.lastHash;
                      const versions = params.data.prefab_ref?.asset_versions;
                      const lastVersion = versions ? versions[versions.length - 1] : null;
  
                      let response = 'No data';
  
                      if (!lastHash && versions && versions.length > 0) {
                        return 'Outdated';
                      }
  
                      if (lastHash && versions && versions.length > 0) {
                        response = lastVersion.destinationPath.includes(lastHash) ? 'Up to date' : 'Outdated';
                      }
                      return response;
                    },
                    filter: 'agSetColumnFilter',
                    filterParams: {
                      values: [
                        'No data', 'Outdated', 'Up to date'
                      ],
                      addEmptyFilters: true,
                      isAggregate: true,
                      filterKey: 'prefab_ref',
                      isAssetUpToDate: true,
                    },
                    floatingFilter: true,
  
                  },
                ],
                isMultiple: true,
                entity: 'items',
                defaultQueryKey: 'seriesRewards_ref',
                entities: ['items', 'currencies'],
                customHeight: '30vh',
              }
            },
          ]
        },
      ];
        this.sidebarFields = [
          {
            title: "Nurture Collection Data",
            fields: [
              {
                name: "Banner Preview",
                inputType: DynamicViewType.sideThumbnail,
                key: 'image_ref',
                label: 'Banner Preview',
                columnWidth: 12
              },
              {
                name: "Banner Preview",
                inputType: DynamicViewType.sideThumbnail,
                key: 'pinBackground_ref',
                label: 'Banner Preview',
                columnWidth: 12
              },
              {
                name: "Banner Preview",
                inputType: DynamicViewType.sideThumbnail,
                key: 'seedPack_ref',
                label: 'Banner Preview',
                columnWidth: 12
              },
            ],
            type: DynamicCardType.Card
          },
        ];
  
        this.title = "";
        this.viewRoute = 'nurture-collection';
        this.isLoading = false;
      }
    }
  }  
  
  
  
  
  
  