<dynamic-view-v2 
    *ngIf="!isLoading" 
    entityType="traits" 
    [viewName]="title" 
    [(record)]="traitRecord"
    [fields]="fields"
    [parseRecordData]="true"
    (onParseRecordData)="prepareRecordData($event)"
    [showLocalizedValuesButton]="true"
>
</dynamic-view-v2>