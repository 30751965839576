<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="materials"
    [formName]="title"
    [(record)]="materialRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    (onFieldChange)="onNameComponentChange($event)"
>
</dynamic-form-v2>
