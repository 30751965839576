<dynamic-form-v2
    *ngIf="!isLoading"
    entityType="seed-pack-box"
    [formName]="title"
    [(record)]="SeedPackBoxesRecord"
    [fields]="fields"
    [viewRoute]="viewRoute"
    [autopopulateEntity]="true"
>
</dynamic-form-v2>
