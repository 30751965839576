<!-- Save Changes button at the top -->
<div class="save-button-top p-mb-2">
  <button pButton type="button" label="Retry Failed Diff Jobs" icon="pi pi-replay" class="p-mr-5" (click)="retryFailedDiffJobs()"></button>
  <button pButton type="button" label="Save Changes" icon="pi pi-save" (click)="submitChanges()"></button>
</div>

<p-table *ngIf="jobRecords && jobRecords.length > 0" [value]="jobRecords" responsiveLayout="scroll">
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="entityId">Id <p-sortIcon field="entityId"></p-sortIcon></th>
      <th pSortableColumn="entityName">Name <p-sortIcon field="entityName"></p-sortIcon></th>
      <th pSortableColumn="diffPercentage">Diff % <p-sortIcon field="diffPercentage"></p-sortIcon></th>
      <th>Base Render</th>
      <th>Compare Render</th>
      <th>Diff Image</th>
      <th>Is Infinite Garden Excluded</th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-job>
    <tr>
      <td>
        <a [href]="'/challenges/' + job.entityId" target="_blank">{{ job.entityId || 'N/A' }}</a>
      </td>
      <td>{{ job.entityName || 'N/A' }}</td>
      <td>{{ job.diffPercentage !== undefined ? job.diffPercentage + '%' : 'N/A' }}</td>
      <td>
        <div class="image-container">
          <img [src]="getImageUrl(job.baseRenderURL)" (click)="openImagePopup(job, 0, 'Base Render')" alt="Base Render" width="250" height="250"/>
        </div>
      </td>
      <td>
        <div class="image-container">
          <img [src]="getImageUrl(job.compareRenderURL)" (click)="openImagePopup(job, 1, 'Compare Render')" alt="Compare Render" width="250" height="250"/>
        </div>
      </td>
      <td>
        <div class="image-container">
          <img [src]="getImageUrl(job.diffPixelImagePath)" (click)="openImagePopup(job, 2, 'Diff Image')" alt="Diff Image" width="250" height="250"/>
        </div>
      </td>
      <td>
        <div class="centered-toggle">
          <p-toggleButton 
            [(ngModel)]="job.isInfiniteChallengeExcluded" 
            onLabel="True" 
            offLabel="False"
            (onChange)="markAsChanged(job)" 
            [class.p-button-true]="job.isInfiniteChallengeExcluded" 
            [class.p-button-false]="!job.isInfiniteChallengeExcluded">
          </p-toggleButton>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<!-- Save Changes button at the bottom -->
<div class="save-button-bottom">
  <button pButton type="button" label="Save Changes" icon="pi pi-save" class="p-mt-2" (click)="submitChanges()"></button>
</div>

<!-- Popup dialog -->
<p-dialog [(visible)]="selectedImage" [modal]="true" [closable]="true" [blockScroll]="true" [dismissableMask]="true" [header]="imagePreviewTitle" [style]="{width: '65vw', height: '65vh', overflow: 'hidden'}" *ngIf="selectedImage">
  <div style="display: flex; justify-content: space-between; align-items: center;">
    <a [href]="selectedImage" target="_blank" rel="noopener noreferrer" style="margin-left: auto;">
      <i class="pi pi-external-link" style="font-size: 24px;"></i>
    </a>
  </div>

  <p-galleria [value]="currentJobImages" [activeIndex]="selectedImageIndex" [circular]="true" [showIndicators]="true"
              [showItemNavigators]="true" [showItemNavigatorsOnHover]="false" [autoPlay]="false"
              [showThumbnails]="false" [showThumbnailNavigators]="false"
              (activeIndexChange)="onImageChange($event)"
              [style]="{'width': '100%', 'height': '100%', 'display': 'flex', 'justify-content': 'center', 'align-items': 'center'}">
    <ng-template pTemplate="item" let-item>
      <img [src]="item" alt="Selected Image" [style]="{'max-width': '100%', 'max-height': '100%', 'object-fit': 'contain'}" />
    </ng-template>
  </p-galleria>
</p-dialog>
