<form [formGroup]="form">
  <p-fieldset class="p-formgroup-inline">
    <ng-template pTemplate="header">
      <div class="p-formgroup-inline">
        <span class="p-my-auto p-mr-2">{{ field.name }}</span>
        <button
          pButton
          icon="pi pi-plus p-panel-header-icon"
          class="p-button-rounded p-button-text p-button"
          (click)="addControl()"
        ></button>

        <!-- <button
          pButton
          icon="pi pi-minus p-panel-header-icon"
          class="p-button-rounded p-button-text p-button"
          (click)="removeLastControl(index)"
        ></button> -->
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <span [formArrayName]="field.key" class="p-formgroup-inline">
        <ng-container
          *ngFor="
            let group of formGroup.get(field.key).controls;
            let index = index
          "
          [formGroupName]="index"
        >
          <p-card class="p-formgroup p-m-2 p-shadow-4">
            <ng-template pTemplate="header">
              <button
                pButton
                type="button"
                icon="pi pi-times"
                class="
                  p-button-rounded p-button-text p-button-danger
                  float-right
                "
                (click)="removeControl(index)"
              ></button>
            </ng-template>

            <ng-template pTemplate="content">
              <form-group
                [fields]="fields"
                [form]="form"
                [formGroup]="form.controls[field.key].controls[index]"
                [editMode]="editMode"
                [editDocument]="documentForEdit"
              ></form-group>
            </ng-template>
          </p-card>
        </ng-container>
      </span>
    </ng-template>
  </p-fieldset>
  <div></div>
</form>
