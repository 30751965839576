import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-community';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';

@Component({
  selector: 'app-store-transfer-table-ag-grid',
  templateUrl: './store-transfer-table-ag-grid.component.html',
  styleUrls: ['./store-transfer-table-ag-grid.component.sass']
})
export class StoreTransferTableAgGridComponent implements OnInit {
  public entity: string = 'store-transfer';
  public columnDefs: ColDef[] = [];
  public columnSelection: string;

  constructor(private agGridToolsService: AgGridToolsService) { }

  async ngOnInit(): Promise<void> {
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: LinkCellRendererComponent,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: false,
      },
      {
        headerName: 'Entity',
        field: 'entity',
        sortable: true,
        filter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          // Safely access `firstName` and return `null` (or another default value) if not found
          return params.data?.meta?.entity ?? null;
        },
      },
      {
        headerName: 'Triggered At',
        field: 'triggeredAt',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('triggeredAt'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Triggered By',
        field: 'meta',
        sortable: true,
        filter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          // Safely access `firstName` and return `null` (or another default value) if not found
          return params.data?.meta?.user?.name ?? null;
        },
      },
      {
        headerName: 'Source',
        field: 'source',
        sortable: true,
        filter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return params.data.meta?.source ?? null;
        },
      },
      {
        headerName: 'Destination',
        field: 'destination',
        sortable: true,
        filter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: (params) => {
          return params.data.meta?.destination?? null;
        },
      },
    ];


    this.columnSelection = this.columnDefs.map(col => col.field).join(' ');
  }
}

