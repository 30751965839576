import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { Subscription } from 'rxjs';
import { TableCommunicationService } from 'src/app/common/ag-grid-table/services/table-communication.service';

@Component({
  selector: 'app-client-strings-ag-grid',
  templateUrl: './client-strings-ag-grid.component.html',
  styleUrls: ['./client-strings-ag-grid.component.sass']
})
export class ClientStringsTableAgGridComponent implements OnInit {

  public entity: string = 'localization/client-strings';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};
  public displayEditDialog: boolean = false;

  private editClientStringSubscription: Subscription;
  public  editRecord: any;

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
    private tableCommunicationService: TableCommunicationService,
  ) {
    this.autopopulateSelect = '';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();


    this.columnDefs = [
        {
          headerName: 'Actions',
          field: 'actions',
          cellRenderer: ActionsCellRendererComponent,
          pinned: 'right', // Pin the column to the right
          width: 120, // Set the width of the column
          resizable: true,
          cellRendererParams: {
            enableDuplicateFeature: false
          }
        },
        {
        headerName: 'Record ID',
        field: 'id',
        width: 400,
        headerCheckboxSelection: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        checkboxSelection: true,
        sortable: true,
        unSortIcon: true,
      },
      {
        headerName: 'English',
        field: 'Source_en',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        minWidth: 300,
        autoHeight: true
      },
      {
        headerName: 'French (France)',
        field: 'Target_frFR',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
      },
      {
        headerName: 'Spanish (Mexico)',
        field: 'Target_esMX',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
      },
      {
        headerName: 'German',
        field: 'Target_deDE',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
      },
      {
        headerName: 'Verified',
        field: 'verified',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Verified',
          offLabel: 'Not Verified',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Verified' : 'Not Verified'
        },
        floatingFilter: true,
      },
      {
        headerName: 'Translation Request',
        field: 'translationRequest',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
      },
    ]


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);


    this.tableParams = {
      customName: "Client Strings",
      customButtons: ['addString'],
      hideActions: true,
      hideAdd: true,
    }


    this.editClientStringSubscription = this.tableCommunicationService.editClientString$.subscribe((show: any) => {
      this.displayEditDialog = show.display;
      this.editRecord = show.record
    });
  
  }

  hideStringDialog() {
    console.log('hideAssetDialog');
    this.displayEditDialog = false;
  }

  

  async setOptions(){
  }

  

}
