import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-titles-view-v2',
  templateUrl: './titles-view-v2.component.html',
  styleUrls: ['./titles-view-v2.component.sass']
})
export class TitlesViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  TitlesRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Titles View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Title Details",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', isRequired: true, label: 'Name' },
          { name: "Description", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'description', label: 'Description' },
          {
            name: "Misc. Image Record",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'image_ref',
            label: 'Misc. Image Record',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {fieldName:'image_ref'}
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Bundle Assets",
        fields:[
          { name: "Bundle Image (IOS)", inputType: DynamicViewType.Boolean, clearField: true, key: 'bundleImage', label: 'Bundle Image (IOS)', columnWidth: 6 },
          { name: "Bundle Image (AND)", inputType: DynamicViewType.Boolean, clearField: true, key: 'bundleImageAnd', label: 'Bundle Image (AND)', columnWidth: 6 },
        ],
        type: DynamicCardType.Card
      },
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.TitlesRecord =
    {
      enabled: false
    };
    this.title = "Titles";
    this.viewRoute = 'titles-v2';
    this.isLoading = false;
  }

}
