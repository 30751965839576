import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BuildType } from '../enums/build-type';
import { AssetTypes } from '../entities/enums/AssetTypes';
import { LoggerService } from '../common/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class DataService {

  private _storeTransferCount = new BehaviorSubject<any>(null);
  storeTransferCount$ = this._storeTransferCount.asObservable()

  private readonly promotionEndpoint: string =
    'https://worker.flora-cms.bgamestudios.com/stage/promote';

  constructor(private http: HttpClient, private loggerService: LoggerService) {}

  getAllOfType(type: String, query: Object): Observable<any[]> {
    return this.http
      .post<any>(`/api/${type}`, query)
      .pipe(catchError(this.handleError));
  }

  async updateReadOnlyUser(id: number, type: String, body: Object) {
    const result = await this.http
      .patch<any>(`/api/${type}/update/${id}`, body)
      .toPromise();
    return result;
  }
  async updateRecord(id: number, type: String, body: Object): Promise<any> {
    const result = await this.http
      .patch<any>(`/api/${type}/update/${id}`, body)
      .toPromise();
    return result;
  }
  async addNewRecord(type: String, body: Object) {
    const result = await this.http
      .post<any>(`/api/${type}/add`, body)
      .toPromise();
    return result;
  }
  async updateItemPrizeRef(id: number, type: String, body: Object) {
    const result = await this.http
      .patch<any>(`/api/${type}/update/${id}`, body)
      .toPromise();
    return result;
  }

  async deleteRecord(id: number, type: String) {
    const result = await this.http
      .delete<any>(`/api/${type}/${id}`)
      .toPromise();
    return result;
  }

  async getAllOfTypeAsync(type: String, query: Object, isPaginated: Boolean = false, page: number = 0, pageSize: number = 50) {
    if(!isPaginated){
      return await this.http.post<any>(`/api/${type}`, query).toPromise();
    } else {
      return await this.http.post<any>(`/api/${type}/paginated?page=${page}&pageSize=${pageSize}`, query).toPromise();
    }
  }

  getDocument(type: String, query: Object): Observable<any> {
    return this.http
      .post<any>(`/api/${type}/find`, query)
      .pipe(catchError(this.handleError));
  }

  getHistory(type: String): Observable<any> {
    return this.http.get(`/api${type}`).pipe(catchError(this.handleError));
  }

  async getDocumentAsync(type: String, query: Object) {
    const result = await this.http
      .post<any>(`/api/${type}/find`, query)
      .toPromise();
    // console.log('async get document', result);
    return result;
  }

  async getBuildHistoryAsync(query: Object) {
    const result = await this.http
      .post<any>(`/api/build/records/find/all`, query)
      .toPromise();
    // console.log('async get document', result);
    return result;
  }

  async getBuildData(buildData: any) {
    // console.log("Build DATA ------: ", buildData);
    let output: any = [];
    return await this.getBuildDocuments(buildData).then((result) => {
      if (result && result.length > 0) {
        // console.log('build records', result);
        return this.getOrderedBuildData(result);
      } else {
        return output;
      }
    });
  }
  /**
   * get file-size data
   */

  async getFileSizeData(id: any, type: any) {
    const result = await this.http.post<any>(`/api/file-size/${type}/${id}`, {}).toPromise();
    return result;
  }

  /** Returns build data objects ordered by platform ('ios', 'and', 'lin', 'mac', 'win')
   * @param data array of Build Data objects
   * @returns ordered array of Build Data objects
   */
  getOrderedBuildData(data: any[]) {
    if (data && data.length > 0) {
      let platformsOrder = ['ios', 'and', 'lin', 'mac', 'win'];

      let result: any = [];
      for (let key of platformsOrder) {
        let build = data.find((build: any) => build.platforms.includes(key));
        if (build) {
          result.push(build);
        }
      }
      return result;
    }
  }

  async getBuildDocuments(data: any) {
    // console.log('getBuildDocuments data: ', data );
    let ids: any = [];
    Object.keys(data).forEach(async (platform) => {
      ids.push(data[platform]);
    });

    // console.log("Build DATA ------ IDs: ", ids);
    // console.log('build ids to pull', ids);
    const docs = await this.http
      .post<any>(`/api/build/records/find/all`, {
        query: {
          $and: [{ _id: { $in: ids } }, { platforms: { $nin: 'web' } }],
        },
      })
      .toPromise();

    // console.log('!! Docs:', docs);

    return docs;
  }

  /**
   *
   * @param path
   * @param adjust this can be removed after items/challenges use base view.
   * @returns
   */
  async getBuildHistoryData(path: any, adjust: boolean) {
    let adjustedPath = '';
    if (adjust) {
      path = path.substr(0, path.lastIndexOf('/'));
      adjustedPath = 'approved_assets/' + path;
      // console.log('adjustedPath: ',adjustedPath);
    } else {
      adjustedPath = path;
    }
    let output: any = [];
    await this.getBuildHistoryAsync({
      query: { path: adjustedPath },
      autopopulate: true,
      virtuals: true,
      sort: { startedAt: 1 },
    }).then((doc) => {
      output[0] = doc;
    });

    return output;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }

  async sendToBuidQueue(payload: any): Promise<any> {
    // console.log('sendToBuild payload in DataService', payload);
    return this.http.post<any>(`/api/build/trigger`, payload).toPromise();

    // return this.http
    //   .post<any>(`/api/build/trigger`, payload)
    //   .pipe(catchError(this.handleError));
  }

  buildGamedata(env: string): Observable<any> {
    return this.http.post<any>(`/api/gamedata/build/${env}`, {});
  }

  uploadFileS3(type: string, files: any): Observable<any> {
    return this.http.post<any>(`/api/files/upload`, { files: files });
  }

  async getFileS3(type: string, path: string) {
    return this.http
      .post<any>(`/api/files/get/s3/`, { type: type, path: path })
      .toPromise();
  }

  saveUserSettings(settings: any, action: string): Observable<any> {
    settings.enabled = true;

    return ['add'].includes(action)
      ? this.http.post<any>(`api/user-settings/add`, settings)
      : this.http.patch<any>(
          `api/user-settings/update/${settings.id}`,
          settings
        );
  }

  async updateUserSettings(pageRef: string, type: string, settings: any) {
    let syncData: any = {
      pageRef: pageRef,
      settings: settings,
      type: type
    }
    const result = await firstValueFrom(this.http.post<any>(`api/user-settings/sync`, syncData));
    return result;
  }

  async getUserSettings(pageRef: string, type: string) {
    const result = await firstValueFrom(this.http.get<any>(`api/user-settings/get/${pageRef}/${type}`));
    return result;
  }

  syncArtResourcesForBuild() {
    return this.http.post<any>(
      'https://worker.flora-cms.bgamestudios.com/resources/update',
      {}
    );
  }

  /**
   * Sends a build job into a queue given a type of build and
   * payload with data for the build.
   *
   * @param type The type of build. (Items, Levels, Images)
   * @param payload Payload data
   * @param isPriority Flag that sets whether or not is high priority job build
   */
  async sendToBuildQueue(
    type: BuildType,
    payload: any,
    isPriority: boolean = false
  ): Promise<any> {
    payload.isPriority = isPriority;
    return await this.http.post<any>(`/api/build/add/${type}`, payload).toPromise();
  }

async insertRenderDiffJob(payload: any): Promise<any> {
  return await firstValueFrom(this.http.post<any>(`/api/worker/sendInsertJob`, payload));
}

async retryFailedJobs(payload: any): Promise<any> {
  return await firstValueFrom(this.http.post<any>(`/api/worker/retryFailedJobs`, payload));
}

/**
 * Scale up build nodes
 */
async buildNodesScaleUp() {
  return await firstValueFrom(this.http.get<any>(`/api/build/nodes/scale/up`));
}

  /**
   * Sends a slack notification to the user who triggered the bulk build.
   */
  async bulkNotification(email: string, bulkBuildId: any)
  {
    return await this.http.post<any>(`/api/build/bulk/notification`, { email: email, bulkBuildId: bulkBuildId }).toPromise();
  }

  /**
   * Send the entities that are going to be built
   *
   * @param payload List of entities and build data
   */
  async sendBulkBuild(payload: any): Promise<any>
  {
    return this.http.post<any>(`/api/build/bulk-build`, payload).toPromise();
  }

  async promoteAsset(entity: any, type: AssetTypes, from: string, to: string) {
    let asset: string | null = null;

    switch (type) {
      case AssetTypes.Item:
        if (entity.prefab) {
          asset = entity.prefab.substr(0, entity.prefab.lastIndexOf('/'));
          asset = 'approved_assets/' + asset;
        }
        break;
      case AssetTypes.Challenge:
        if (entity.scene) {
          asset = entity.scene.substr(0, entity.scene.lastIndexOf('/'));
          asset = 'approved_assets/' + asset;
        }
        break;

      default:
        throwError('Asset type not recognized');
        break;
    }

    let assetPayload = {
      from: from,
      to: to,
      entityId: entity.id,
      path: asset,
      assetType: type,
    };

    this.loggerService.log('Asset Payload: ', assetPayload);

    // promote asset
    if (asset) {
      return await this.http
        .post<any>(this.promotionEndpoint, assetPayload, {
          headers: { 'Content-Type': 'application/json' },
        })
        .toPromise();
    } else {
      this.loggerService.log(`no asset found for ${type} ${entity.id}`);
      return false;
    }
  }

  async promoteImage(
    entity: any,
    type: AssetTypes,
    from: string,
    to: string,
    customPath: string | null = null
  ) {
    let image: string | null = null;

    switch (type) {
      case AssetTypes.ItemThumbnail:
        if (entity.thumbnail) {
          image = 'approved_assets/' + entity.thumbnail + '.png';
        }
        break;
      case AssetTypes.ChallengeFeed:
        if (entity.image) {
          image = 'approved_assets/' + entity.image + '.jpg';
        }
        break;
      /*
      This logic was not here before, but it is on the backend...
      Ask if we will need it for manual promotion logic or just in auto-promotion.

      case AssetTypes.SpruceImage :
        if (entity.imagePath)
        {
          image = 'approved_assets/' + entity.imagePath + '.jpg';
        }
        break;
      */
      case AssetTypes.LoadingScreen:
        if (customPath) {
          image = 'approved_assets/' + customPath;
        }
        break;
      case AssetTypes.Miscellaneous:
        // Circle-back here to set the image extension...
        if (entity.path) {
          image = 'approved_assets/' + entity.path;
        }
        break;

      default:
        throwError('Asset type not recognized');
        break;
    }

    let imagePayload = {
      from: from,
      to: to,
      entityId: entity.id,
      path: image,
      assetType: type,
    };

    this.loggerService.log('Image Payload: ', imagePayload);

    // promote image
    if (image) {
      return await this.http
        .post<any>(this.promotionEndpoint, imagePayload, {
          headers: { 'Content-Type': 'application/json' },
        })
        .toPromise();
    } else {
      this.loggerService.log(`no image found for ${type} ${entity.id}`);
      return false;
    }
  }

  /**
   * Pause the desired build queue
   *
   * @param type The type of build. (Items, Levels, Images)
   */
  async pauseBuildQueue(type: BuildType): Promise<any> {
    return this.http.put<any>(`/api/build/${type}/queue/pause`, {}).toPromise();
  }

  /**
   * Resume the desired build queue
   *
   * @param type The type of build. (Items, Levels, Images)
   */
  async resumeBuildQueue(type: BuildType): Promise<any> {
    return this.http
      .put<any>(`/api/build/${type}/queue/resume`, {})
      .toPromise();
  }

  /**
   * Retrieves if the Asset/Image promoted version is latest.
   *
   * @param entityId Id of the entity
   * @param assetPath Asset path
   * @param imagePath Image path
   * @param assetType Asset Type value
   */
  async getLatestAssetImagePromotedVersion(
    entityId: number,
    assetPath: string,
    imagePath: string,
    assetType: AssetTypes
  ): Promise<any> {
    return this.http
      .get<any>(
        `/api/build/promoted-version/${entityId}?assetType=${assetType}&assetPath=${
          assetPath ? assetPath : ''
        }&imagePath=${imagePath ? imagePath : ''}`
      )
      .toPromise();
  }

  async getOptionsFromRef(
    fieldName: string,
    model: string,
    minimal: boolean = false,
    autopopulate: boolean = true
  ) {
    const options = await this.getAllOfTypeAsync(model, {
      query: {},
      autopopulate: autopopulate,
      virtuals: true,
      sort: { createdAt: 1 },
    });
    if (minimal) {
      let o: any[] = [];
      for (const option of options) {
        o.push({ id: option.id, name: option.name, _id: option._id });
      }
      return o;
    } else {
      return options;
    }
  }

  async getDiffImage(imageUrl1: string, imageUrl2: string): Promise<any> {
    const params = new HttpParams()
      .set('img1', imageUrl1)
      .set('img2', imageUrl2);
    const result = await firstValueFrom(this.http.get<any>(`api/image-tools/diff`, { params }));
    return result;
  }

  updateStoreTransferCount(value: any) {
    this._storeTransferCount.next(value)
  }

  post(endpoint: string, data: any) {
    return this.http.post(endpoint, data).toPromise();
  }

  async fetchGridlyRecord(id: number, entity: string) {

    const params = new HttpParams()
      .set('id', id)
      .set('entity', entity);
    const data = await firstValueFrom(this.http.get<any>(`api/localization/gridly-record`, { params }))

    return data
  }
}
