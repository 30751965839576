<form [formGroup]="formGroup">
  <ng-container *ngFor="let f of group; let i = index">
    <div [ngClass]="{ 'p-mt-5': i !== 0 }">
      <field
        [field]="f"
        [form]="form"
        [formGroup]="formGroup"
        [editMode]="editMode"
        [editDocument]="documentForEdit"
        (addField)="onFieldAdded($event)"
        (replaceField)="onFieldReplaced($event)"
        (removeField)="onFieldRemoved($event)"
      ></field>
    </div>
  </ng-container>
</form>
