import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SourcingChallengeGroupService
{
  private readonly apiURLprefix : string = '/api/sourcing-challenge-groups';
  constructor(private http: HttpClient) {}

  /**
   * Promotes all items tied to a sourcing group.
   *
   * @param sourceGroupId Id of the sourcing group
   */
  async promoteChallengeSourcingGroup(sourceGroupId: any, payload: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/${sourceGroupId}/promote/challenges`, payload).toPromise();
  }

  /**
   * Promotes selected sourcing items tied to a sourcing group.
   *
   * @param sourceGroupId Id of the sourcing group
   * @param payload Payload data
   */
  async promoteSelectedSourcingChallenges(sourceGroupId: any, payload: any): Promise<any>
  {
    return await this.http.post(`${this.apiURLprefix}/${sourceGroupId}/promote/selected/challenges`, payload).toPromise();
  }

  /**
   * Retrieve all sourcing groups records
   */
  async getAllSourcingGroupsChallenges(): Promise<any[] | undefined>
  {
    return await this.http.get<Array<any>>(`${this.apiURLprefix}`).toPromise();
  }

  /**
   * Validate sourcing items
   *
   * @param sourceGroupId Id of the sourcing group
   * @param payload Payload data
   */
   async validateSourcingChallenges(sourceGroupId: any, payload: any): Promise<any>
   {
     return await this.http.post(`${this.apiURLprefix}/${sourceGroupId}/validate/sourcing-challenges`, payload).toPromise();
   }
}
