import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-perk-view-v2',
  templateUrl: './perk-view-v2.component.html',
  styleUrls: ['./perk-view-v2.component.sass']
})
export class PerkViewV2Component extends BaseDynamicViewComponent implements OnInit {
  perkRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  ngOnInit() {
    this.fields = [
      {
        title: 'Perk Data',
        fields: [
          {
            name: 'Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'name',
            label: 'Name',
            columnWidth: 5
          },
          {
            name: 'perkType',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.DropdownWithLabels],
            key: 'perkType',
            label: 'Perk Type',
            options: {
              fieldName: 'perkType',
              values: [
                { name: 'Free Use', value: 1},
                { name: 'Daily Rewards', value: 2},
                { name: 'Challenge Submit Rewards', value: 3},
                { name: 'Voting Rewards', value: 4},
                { name: 'Gifting Tree Rewards', value: 5},
                { name: 'Gifting Wheel More Spins', value: 6},
                { name: 'Gifting Wheel Rewards', value: 7},
                { name: 'Gifting Wheel Lower Cost', value: 8},
                { name: 'Bonus Minigame Currency', value: 9},
                { name: 'Bonus Coop Currency', value: 10},
                { name: 'Bonus Weekly Achievements', value: 11},
              ]
            },
            columnWidth: 4
          },
          {
            name: 'Default Perk',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            key: 'defaultPerk',
            label: 'Default Perk',
            columnWidth: 3,
            booleanoptions: {
              true: "Yes",
              false: "No"
            }
          },
          {
            name: 'Description',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'description',
            label: 'Description',
            columnWidth: 12
          },
          
          {
            name: 'Multiplier',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'multiplier',
            label: 'Multiplier',
            columnWidth: 6
          },
          {
            name: 'Currency Multiplier',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'currencyMultiplier',
            label: 'Currency Multiplier',
            controller: 'currencies',
            columnWidth: 6
            
          },
          {
            name: 'Club Point Requirement',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'clubPointRequirement',
            label: 'Club Point Requirement',
            columnWidth: 6
          },  
          {
            name: 'isDailyReward',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Boolean],
            key: 'isDailyReward',
            label: 'Is Daily Reward',
            columnWidth: 6,
            booleanoptions: {
              true: "Yes",
              false: "No"
            }
          },
          {
            name: "Colors",
            inputType: DynamicViewType.NestedInputComponent,
            clearField: true,
            options: {
              flatValue: true,
              fieldName: 'colorStrings'
            },
            key: 'colorStrings',
            isRequired: true,
            label: 'Colors',
            columnWidth: 12,
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: 'Rewards',
        fields: [
          {
            name: 'Weekly Reward',
            inputType: DynamicViewType.Price,
            key: 'rewards_ref',
            label: 'Weekly Reward'
          },
          {
            name: 'Daily Reward Track',
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'dailyRewardTrack',
            label: 'Daily Reward Track',
            controller: 'rewards-tracks',
            columnWidth: 6
          }
        ],
        type: DynamicCardType.Card
      },
      {
        title: 'Imagery',
        fields: [
          {
            name: "Icon",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'icon_ref',
            label: 'Icon',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'icon_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          }
        ],
        type: DynamicCardType.Card
      }
    ];

    this.title = "Perk";
    this.viewRoute = 'perks';
    this.isLoading = false;
  }
} 