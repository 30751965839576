import BaseEntityDto from "src/app/common/dtos/BaseEntityDto";

/**
 * Seed Pack DTO
 */
export default class NurtureCollectionDTO extends BaseEntityDto
{
    name: string = "";

    enabled: boolean;

    image_ref: any;

    nurture_ref: Array<any> = [];

    nurtures_ref: Array<any> = [];

    /**
     * List of Rewards
     */
    rewards_ref: Array<any> = [];
}
