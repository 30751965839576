<div class="p-my-3 p-d-flex p-jc-between">
    <span>
      <h1 class="p-mr-3 p-d-inline">{{ entity.name }} ({{ entity.id }})</h1>
      <a pButton pRipple label="Edit" type="button" class="p-d-inline" icon="pi pi-pencil"
        [href]="editUrl"></a>
    </span>
    <a pButton pRipple label="Duplicate (Legacy)" type="button" class="p-d-inline p-ml-3"
      icon="pi pi-copy" (click)="toggleDuplicationModal()"></a>
    <p-button label="Duplicate (New)" icon="fa-solid fa-copy" iconPos="right"
      (onClick)="showDupDialog()" class="p-button-secondary p-mr-4"></p-button>
    <button pButton pRipple label="Localized Values" type="button" icon="fa fa-language"
      (click)="fetchLocalizedValues()"></button>
  </div>  