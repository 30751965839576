<div *ngIf="isLoading else elseBlock">
  <p-card class="p-m-5">
    <span>
      <p-progressSpinner
      [style]="{width: '70px', height: '70px'}" strokeWidth="8"
      fill="var(--primary-color)"  animationDuration="2s">
    </p-progressSpinner>
    </span>
  </p-card>
</div>
<div #elseBlock>
  <p-card *ngIf="entity">
    <ng-template pTemplate="header" class="p-m-5">
      <div class="p-p-3">
        <h3>Challenge Details</h3>
        <h4>Id: {{entity.id}}</h4>
        <h4>Name: {{entity.name}}</h4>
      </div>
    </ng-template>
    <div *ngIf="buildsHistory && buildsHistory[0]">
      <p-table [value]="buildsHistory[0]" responsiveLayout="scroll" [resizableColumns]="true">
        <ng-template pTemplate="header">
            <tr>
                <th>No.</th>
                <th>Build</th>
                <th>Metadata</th>
                <th>Platform and Status</th>
                <th>Logs</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-build let-rowIndex="rowIndex">
            <tr>
                <td>{{rowIndex}}</td>
                <td>
                  <p-overlayPanel #op>
                    <ng-template pTemplate>
                      {{build.id}}
                    </ng-template>
                  </p-overlayPanel>
                  <button
                  pButton type="button" class="p-button-text"
                  (click)="op.toggle($event)"
                  > {{build.id.substr(0,5)}}</button>
                </td>
                <td>
                  <p-overlayPanel #op1>
                    <ng-template pTemplate>
                      <ul>
                        <span *ngFor="let item of build | keyvalue" >
                          <li *ngIf="!['logs', 'errors'].includes(item.key)">
                            <strong>
                              {{item.key}}
                            </strong>
                            {{parse(item.value)}}
                            </li>
                        </span>
                      </ul>
                    </ng-template>
                  </p-overlayPanel>
                  <button
                  pButton type="button" class="p-button-text"
                  (click)="op1.toggle($event)"
                  > Open</button>
                </td>
                <td>
                  <button
                    pButton
                    class="p-button"
                    [ngStyle]="{
                      backgroundColor: getColorFromBuildStatus(build.status)
                    }"
                  >
                  <div class="p-grid">
                    <div class="col">
                      <span class="p-text-center">
                        <img
                          src="{{
                            '../../assets/selected-icons/os-logos-wht/' +
                              build.platforms[0] +
                              '-logo.png'
                          }}"
                          alt="{{ build.platforms[0] }}"
                          style="width: 70px; min-width: 30px; height: 100%; margin: 0 auto"
                        />
                      </span>
                    </div>
                    <div class="col p-p-3">
                      <span class="p-text-left p-mb-1">
                        <strong>{{ build.status == 'queued' ? 'Queued at:' : 'Started at:' }}</strong>
                        {{ build.status == 'queued' ? (build.insertedAt | officeTime) : (build.startedAt | officeTime) }}
                      </span>
                      <span class="p-text-left" *ngIf="build.status == 'finished'">
                        <strong>Finished:</strong>
                        {{ build.finishedAt | officeTime }}
                      </span>
                      <br>
                      <span class="p-text-left" *ngIf="build.user">
                        <strong>By:</strong>
                        {{ build.user.name }}
                      </span>
                      <br>
                      <span class="p-text-left" *ngIf="build.files" >
                        <strong>Size:</strong>
                        <div
                          [ngStyle]="{
                            background: getColorFromFileSize(build)
                          }"
                          [style]="{
                            'font-size': '10px',
                            'border-radius': '10px',
                            'padding': '3px',
                            'text-align': 'center'
                          }"
                        >
                          <small [style]="{
                            'mix-blend-mode': 'difference',
                            'color': 'white'
                            }"
                          >
                            {{getSize(build.files)}}
                          </small>
                        </div>
                      </span>
                    </div>
                  </div>
                  </button>
                </td>
                <td  style="
                width: 45vw;
                min-width: 20vw;
                max-width: 75vw;
                ">
                  <p-accordion>
                    <p-accordionTab>
                      <ng-template pTemplate="header" *ngIf="build && build.errors">
                        Errors <p-badge class="p-ml-2" [value]="build.errors.length"></p-badge>
                      </ng-template>
                      <ng-template pTemplate="content">
                        <span>
                          <small
                            *ngFor="let log of build.errors"
                            [ngStyle]="{
                              color: getColorFromBuildStatus('failed')
                            }"
                            style="white-space: initial;"
                          >
                            {{parse(log)}}
                            <br>
                          </small>
                        </span>
                      </ng-template>
                    </p-accordionTab>
                    <p-accordionTab>
                      <ng-template pTemplate="header" *ngIf="build && build.logs">
                        Logs <p-badge class="p-ml-2" [value]="build.logs.length"></p-badge>
                      </ng-template>
                      <ng-template pTemplate="content">
                        <span>
                          <small
                            *ngFor="let log of build.logs"
                            [ngStyle]="{
                              color: getColorFromBuildStatus('finished')
                            }"
                            style="white-space: initial;"
                          >
                            {{parse(log)}}
                            <br>
                          </small>
                        </span>
                      </ng-template>
                    </p-accordionTab>
                  </p-accordion>
                </td>
            </tr>
        </ng-template>
      </p-table>
    </div>
  </p-card>
</div>
