<div *ngIf="isLoading else elseBlock">
  <p-card class="p-m-5">
    <span>
      <p-progressSpinner
      [style]="{width: '70px', height: '70px'}" strokeWidth="8"
      fill="var(--primary-color)"  animationDuration="2s">
    </p-progressSpinner>
    </span>
  </p-card>
</div>
<ng-template #elseBlock>
  <p-card>
    <h1>Gamedata Diff History</h1>
    <p-table [value]="history" responsiveLayout="scroll" [resizableColumns]="true">
      <ng-template pTemplate="header">
          <tr>
              <th>ID</th>
              <th>Generated By</th>
              <th>Generated At</th>
              <th>Actions</th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-build let-rowIndex="rowIndex">
          <tr>
              <td>{{build.id}}</td>
              <td>
                {{build.createdBy ? build.createdBy.firstName + ' ' + build.createdBy.lastName : 'CMS Bot'}}
              </td>
              <td>{{build.createdAt | officeTime: 'short'}}</td>
              <td>
                <a
                  href="/gamedata/diff/{{build.id}}"
                  target="_blank"
                  ><button
                  pButton
                  pRipple
                  type="button"
                  icon="pi pi-external-link"
                  class="p-button-raised"
                ></button></a>
              </td>
          </tr>
      </ng-template>
  </p-table>
  </p-card>
</ng-template>
