
<app-ag-grid-table
*ngIf="columnSelection"
[entity]="entity"
[columnDefs]="columnDefs"
[bulkUpdateColDefs]="bulkUpdateColDefs"
[autopopulateSelect]="autopopulateSelect"
[columnSelection]="columnSelection"
[tableParams]="tableParams"
>
</app-ag-grid-table>
