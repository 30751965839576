<!-- Spinner -->
<ngx-spinner
  bdColor="rgba(36,34,36,0.8)"
  size="medium"
  color="#81c784"
  type="line-scale"
  name="sourcing-group-view-spinner"
>
  <p style="font-size: 20px; color: white">Uploading...</p>
</ngx-spinner>

<dynamic-table
  *ngIf="!isLoading"
  [tableName]="entity.name"
  [tableType]="type"
  [tableConfig]="tableConfig"
  [fields]="fields"
  [sourcingKey]="key"
  [newRowObject]="newRowObject"
  [defaultColumnOrder]="defaultColumnOrder"
  [globalFilters]="globalFilters"
  [customGlobalColumnSets]="customGlobalColumnSets"
  [sourceGroupID]="this.entity._id"
  [sourcingDoc]="doc"
  [isArchived]="entity.archived"
  (tableModelChange)="onChange($event)"
  (onAction)="onCustomAction($event)"
  (onPathComponentValueChange)="onPathComponentValueChange($event)"
  class="p-mt-5"
>
</dynamic-table>
<!-- Invalid Items Information Modal -->
<p-dialog
  header="Invalid Items"
  [(visible)]="displayModal"
  [modal]="true"
  [style]="{ width: '50vw' }"
  [maximizable]="true"
  [draggable]="false"
  [resizable]="false"
>
  <p-table [value]="invalidChallenges" responsiveLayout="scroll" [scrollable]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>Item Id</th>
        <th>Field</th>
        <th>Error</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.Entity.id }}</td>
        <td>{{ item.Field }}</td>
        <td>{{ item.ErrorMessage }}</td>
        <td><button
          pButton
          pRipple
          type="button"
          icon="pi pi-copy"
          class="p-button-raised"
          (click)="copyMessage(item)"
        ></button></td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
