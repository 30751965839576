import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { ActionsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/actions/actions.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-achievement-table-ag-grid',
  templateUrl: './achievement-table-ag-grid.component.html',
  styleUrls: ['./achievement-table-ag-grid.component.sass']
})
export class AchievementTableAgGridComponent implements OnInit {

  public entity: string = 'achievements';
  public columnDefs: ColDef[] = [];
  public columnSelection: string;
  public rows: any[] = [];
  public columnsPreProcessing: any = {}
  public autopopulateSelect: string = '';
  public assetBundleOptions: any[] = [];
  public entityTypeOptions: any[] = [];
  public options: any = {};
  public assetTypeSettings: any[] = [];
  public entityTypeSettings: any[] = [];
  public achievementTypeDictionary: { [key: number]: string };
  public bulkUpdateColDefs: any[] = [];
  public tableParams: any = {};

  constructor(
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
    private dataService: DataService
  ) {
    this.autopopulateSelect = 'itemType';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();
    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService, this.tableParams);

    this.columnDefs = [
      {
        headerName: 'Actions',
        field: 'actions',
        cellRenderer: ActionsCellRendererComponent,
        cellRendererParams: {
          enableDuplicateFeature: true,
        },
        pinned: 'right', // Pin the column to the right
        width: 120, // Set the width of the column
        resizable: true,

      },
      {
        headerName: 'ID',
        field: 'id',
        width: 125,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'Title',
        field: 'title',
        cellRendererParams: {
          entity: 'achievements'
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'SubText',
        field: 'subText',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Sorting Order',
        field: 'sortingOrder',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        type: 'editableColumn',
        cellEditor: 'agNumberCellEditor',
        autoHeight: true,
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Start',
        field: 'start',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('start'),
        resizable: true,

        cellEditor: CalendarEditorComponent,
        type: 'editableColumn'
      },
      {
        headerName: 'End',
        field: 'end',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('end'),
        resizable: true,

        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Promo Start Date',
        field: 'promoStartDate',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('promoStartDate'),
        resizable: true,

        cellEditor: CalendarEditorComponent,
        type: 'editableColumn'
      },
      {
        headerName: 'Promo End Date',
        field: 'promoEndDate',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear']
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
          timePill: true
        },
        valueGetter: this.agGridToolsService.dateValueGetter('promoEndDate'),
        resizable: true,

        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRendererParams: {
          entity: 'achievements',
          isEnv: true
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
            values: ['dev', 'qa', 'prod'],
            addEmptyFilters: true
        },
        floatingFilter: true,
        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          valueObjects: true,
          isFlatList: true
        },
      },
      {
        headerName: 'Player Description',
        field: 'playerDescription',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Interval',
        field: 'interval',
        cellRenderer: (params: any) => {
          return params.value === 0 ? 'Continous' : 'Single / One and Done'
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.interval,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value.value;
          }
        },
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "value",
          optionLabel: "name",
          objectValue: false,
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Achievement Type',
        field: 'achievementType',
        cellRenderer: (params: any) => {
          return this.achievementTypeDictionary[params.data.achievementType]
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.achievementType,
          valueFormatter: (params: any) => {
            return `${params.value.name}`;
          },
          keyCreator: (params: any) => {
            return params.value.value;
          }
        },
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "value",
          optionLabel: "name",
          objectValue: false,
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Challenge Type',
        field: 'challengeType',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'challenge-types',
          isArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.challengeType,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true,
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Climate Type',
        field: 'challengeClimate',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'climates',
          isArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.challengeClimate,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true,
        },
        type: 'editableColumn',
      },
      // {
      //   headerName: 'Item Categories',
      //   field: 'itemCategories',
      //   cellRenderer: RefsCellRendererComponent,
      //   cellRendererParams: {
      //     entity: 'categories',
      //     isArray: true
      //   },
      //   sortable: true,
      //   unSortIcon: true,
      //   resizable: true,
      //   autoHeight: true,
      //   filter: 'agSetColumnFilter',
      //   filterParams: {
      //     values: this.options.itemCategories,
      //     valueFormatter: (params: any) => {
      //       return `${params.value.name} (${params.value.id})`;
      //     },
      //     keyCreator: (params: any) => {
      //       return params.value._id;
      //     },
      //     comparator: this.agGridToolsService.nameASC,
      //     isMultiRefFilter: true,
      //     addEmptyFilters: true,
      //     populate: true
      //   },
      //   floatingFilter: true,
      //   type: 'editableColumn',
      //   cellEditor: MultiselectEditorComponent,
      //   cellEditorPopup: true,
      //   cellEditorParams: {
      //     optionValue: "_id",
      //     optionLabel: "name",
      //     objectValue: true
      //   },
      // },
      {
        headerName: 'Item Type',
        field: 'itemType',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'item-types',
          isArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.itemType,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true,
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Challenge Result Score',
        field: 'challengeResultScore',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: 'agNumberCellEditor',
        autoHeight: true,
      },
      {
        headerName: 'Minimum IAP Cost',
        field: 'minimumIAPCost',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,

        type: 'editableColumn',
        cellEditor: 'agNumberCellEditor',
        autoHeight: true,
      },
      {
        headerName: 'Target',
        field: 'target',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: 'agNumberCellEditor',
      },
      {
        headerName: 'Reward(s)',
        field: 'rewards_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Title',
              filterParams: {
                buttons: ['clear'],
                title: 'Title',
                values: this.options.titles_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currency_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
          defaultType: 'currencies'
        },
      },
      {
        headerName: 'Promo Reward(s)',
        field: 'promoRewards_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Title',
              filterParams: {
                buttons: ['clear'],
                title: 'Title',
                values: this.options.titles_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currency_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,

        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
          defaultType: 'currencies'
        },
      },
      {
        headerName: 'Is Consecutive',
        field: 'isConsecutive',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Is Repetitive',
        field: 'isRepetitive',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Bundle Image (IOS)',
        field: 'bundleImage',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Bundle Image (AND)',
        field: 'bundleImageAnd',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,

        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Link - Destination',
        field: 'link_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'link-destination',
          isLinkDestination: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        width: 500,
        autoHeight: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.link_ref,
          valueFormatter: (params: any) => {
            return `${params.value.linkText} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          }
        } ,
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "linkText",
          objectValue: true
        },
        type: 'editableColumn',
      },
      {
        headerName: 'User Has Balance?',
        field: 'userHasBalance',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Is Daily Goal',
        field: 'isDailyGoal',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Yes',
          offLabel: 'No',
          isBoolean: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Goal Type',
        field: 'goalType',
        cellRenderer: (params: any) => {
          if (!params.data.goalType) return 'N/A';
          const goalTypeMap = {
            0: 'Evergreen',
            1: 'Live Ops',
            2: 'Segmented'
          } as { [key: number]: string };
          return goalTypeMap[params.data.goalType] || 'N/A';
        },
        cellRendererParams: {
          innerHtml: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [0, 1, 2],
          valueFormatter: (params: any) => {
            const goalTypeMap = {
              0: 'Evergreen',
              1: 'Live Ops',
              2: 'Segmented'
            };
            return goalTypeMap[params.value as keyof typeof goalTypeMap] || 'N/A';
          }
        },
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
      },
      {
        headerName: 'Expected Diamond Cost',
        field: 'expectedDiamondCost',
        cellRenderer: (params: any) => {
          if (!params.data.expectedDiamondCost) return 'N/A';
          return `💎 ${params.data.expectedDiamondCost}`;
        },
        cellRendererParams: {
          innerHtml: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
      },
      {
        headerName: 'Currency',
        field: 'currency_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'currencies',
          isArray: false,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.currency_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true,
        },
        type: 'editableColumn',
      }
    ]

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);

    this.bulkUpdateColDefs = [
      {
        name: 'Title', id: 'title', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
      {
        name: 'Re Release Start', id: 'reReleaseStart', type: 'date'
      },
      {
        name: 'Re Release End', id: 'reReleaseStart', type: 'date'
      },
      {
        name: 'Player Description', id: 'playerDescription', type: 'text'
      },

    ];

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'achievements',
      enableDuplicateFeature: true,
    }

  }

  async setOptions(){
    const entities = [
      { ref: 'titles_ref', entity: 'titles' },
      { ref: 'currency_ref', entity: 'currencies' },
      { ref: 'challengeType', entity: 'challenge-types' },
      { ref: 'challengeClimate', entity: 'climates' },
      // { ref: 'itemCategories', entity: 'categories' },
      {
        ref: 'link_ref', entity: 'link-destination', select: 'id _id linkText',
      },
      { ref: 'itemType', entity: 'item-types' },
    ];

    for (const { ref, entity, select } of entities) {
      const params = new OptionsParams({
        entity, select,
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }

    this.options['interval'] = [{value: 0, name: 'Continous',},{value: 1, name: 'Single / One and Done'}]

    this.options['achievementType'] = await this.dataService.getAchievementTypes();
    this.achievementTypeDictionary = this.options['achievementType'].reduce((acc: any, type: any) => {
      acc[type.value] = type.name;
      return acc;
    }, {});

  }
}
