import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import EndlessOfferDTO from '../../dtos/EndlessOfferDTO';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-endless-offer-form-v3',
  templateUrl: './endless-offer-form-v3.component.html',
  styleUrls: ['./endless-offer-form-v3.component.sass']
})
export class EndlessOfferFormComponent extends BaseDynamicFormComponent implements OnInit {
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  endlessOfferRecord: EndlessOfferDTO = new EndlessOfferDTO();
  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }

  /**
   * Season Pass Form V3 Component Initialization
   */
  ngOnInit() {
    this.fields =
      [
        {
          title: "Endless Offer Data",
          fields:
            [
              {
                name: "Name",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'name',
                isRequired: true,
                label: 'Name',
                disabled: false,
                columnWidth: 6
              },
              {
                name: "Internal Name",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.TextInput],
                clearField: true,
                key: 'internalName',
                isRequired: true,
                label: 'Internal Name',
                disabled: false,
                columnWidth: 6
              },
              {
                name: "Start Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'start',
                isRequired: false,
                label: 'Start Date/Time',
                columnWidth: 6,
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.end) {
                    return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
                  } else {
                    return true;
                  }
                },
              },
              {
                name: "End Date/Time",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Calendar],
                clearField: true,
                key: 'end',
                isRequired: false,
                label: 'End Date/Time',
                columnWidth: 6,
                calendarShowTime: true,
                calendarYearNavigatior: true,
                calendarYearRange: '2020:2030',
                calendarMonthNavigator: true,
                calendarSelectOtherMonths: true,
                calendarMinDate: this.endlessOfferRecord.start,
                validate: (incomingValue: boolean, record: any, fieldKey: any) => {
                  if (incomingValue && record.start) {
                    return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
                  } else {
                    return true;
                  }
                },
              },
              {
                name: 'Internal Notes',
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Editor],
                key: 'copy',
                label: 'Internal Notes',
                columnWidth: 12,
                clearField: true,
                isRequired: false,
                disabled: false,
              },

            ],
          type: DynamicCardType.Card
        },
        {
          // EOFeedBanner = 24,
          // EOIapStore = 25,
          // EOPopupHeader = 26,
          // EOTileBg = 27
          title: "Imagery",
          fields: [
            {
              name: "Banner Feed Image",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.AutoComplete],
              clearField: true,
              key: 'feedBannerImage_ref',
              label: 'Banner Feed Image',
              autocompleteMultipleValues: false,
              autocompleteShowEmptyMessage: true,
              autocompleteField: 'name',
              autocompleteMinLength: 3,
              suggestions: {
                fieldName: 'feedBannerImage_ref',
                apiController: 'miscellaneous-build',
                autopopulate: false,
                minimal: false,
                virtuals: false,
                customQueryField: 'name',
                select: '_id name id path',
                customQuery: { entityType: BuildType.Images },
                isdisplayNameIDPath: true,
                isMiscAsset: true,
                initializeWith_id: true
              },
              style: { width: '100%' },
              showClear: true
            },

            {
              name: "IAP Store Image",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.AutoComplete],
              clearField: true,
              key: 'IAPStoreImage_ref',
              label: 'IAP Store Image',
              autocompleteMultipleValues: false, 
              autocompleteShowEmptyMessage: true,
              autocompleteField: 'name',
              autocompleteMinLength: 3,
              suggestions: {
                fieldName: 'IAPStoreImage_ref',
                apiController: 'miscellaneous-build',
                autopopulate: false,
                minimal: false,
                virtuals: false,
                customQueryField: 'name',
                select: '_id name id path',
                customQuery: { entityType: BuildType.Images },
                isdisplayNameIDPath: true,
                isMiscAsset: true ,
                initializeWith_id: true
              },
              style: { width: '100%' },
              showClear: true
            },
            {
              name: "Offer Popup Header Banner Image",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.AutoComplete],
              clearField: true,
              key: 'popupBannerImage_ref',
              label: 'Offer Popup Header Banner Image',
              autocompleteMultipleValues: false, 
              autocompleteShowEmptyMessage: true,
              autocompleteField: 'name',
              autocompleteMinLength: 3,
              suggestions: {
                fieldName: 'popupBannerImage_ref',
                apiController: 'miscellaneous-build',
                autopopulate: false,
                minimal: false,
                virtuals: false,
                customQueryField: 'name',
                select: '_id name id path',
                customQuery: { entityType: BuildType.Images},
                isdisplayNameIDPath: true,
                isMiscAsset: true ,
                initializeWith_id: true
              },
              style: { width: '100%' },
              showClear: true
            },  
            {
              name: "Tile Backgrounds",
              inputType: DynamicInputType.BaseInputField,
              inputTypeFields: [InputFieldType.MultiSelect],
              clearField: true,
              key: 'bundleImagery_ref',
              isRequired: false,
              label: 'Tile Backgrounds',
              optionValue: '_id',
              optionLabel: 'name',
              filter: true,
              filterBy: 'name,id,path',
              autoDisplayFirst: false,
              displayNameIDPath: true,
              labelKeys: ['id', 'name', 'path'],
              setMongoId: false,
              options: {
                fieldName: 'bundleImagery_ref', apiController: 'miscellaneous-build',
                customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 },
                select: 'name id path _id',
                isArrayOfStringIds: true
              }
            },
          ],
          type: DynamicCardType.Card
        },
        {
          title: "Endless Offer Track",
          fields:
            [
              {
                name: "Column Count",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Dropdown],
                clearField: true,
                key: 'column_count',
                label: 'Column Count',
                filter: true,
                autoDisplayFirst: false,
                optionLabel: 'name',
                optionValue: 'value',
                isRequired: true,
                options:
                {
                  fieldName: 'column_count',
                  values:
                    [
                      { name: '1 Columns', value: 1 },
                      { name: '2 Columns', value: 2 },
                    ]
                },
                filterBy: 'name'
              },
              {
                name: 'Reward Track Record',
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Dropdown],
                clearField: true,
                key: 'track_v2_ref',
                isRequired: false,
                label: 'Reward Track Record',
                optionLabel: 'name',
                optionValue: "_id",
                filter: true,
                filterBy: "id,name",
                showClear: true,
                autoDisplayFirst: false,
                style: { 'min-width': '30em' },
                options: {
                  fieldName: 'track_v2_ref', apiController: 'rewards-track',
                  minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 },
                  createOrSelect: {
                    parent: true
                  },
                  customQuery: { type: 6 }
                },
                columnWidth: 6
              },
              {
                name: "Track Label",
                inputType: DynamicInputType.BaseInputField,
                inputTypeFields: [InputFieldType.Text],
                key: 'track_ref_label',
                isRequired: false,
                clearField: true,
                label: 'Endless Offer Track Data:',
              },
              {
                name: "Track",
                inputType: DynamicInputType.EndlessOfferRewardsTrackComponent,
                key: 'track_v2_ref',
                options: {
                  fieldName: 'track_v2_ref',
                  createOrSelect: {
                    child: true,
                    parentKey: 'track_v2_ref',
                    parentLabel: 'Reward Track'
                  }
                },
                customFields:
                  [
                    {
                      key: 'track_child',
                      touched: false
                    },
                  ]
              }
            ],
          type: DynamicCardType.Card
        }
      ]

    this.title = "Endless Offer";
    this.viewRoute = 'endless-offer';
    this.isLoading = false;
  }
}
