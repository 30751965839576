<div class="card">
    <h3>{{title}}</h3>
    <p-fileUpload
        name="file" 
        [mode]="mode" 
        [url]="uploadURL" 
        [multiple]="multipleUploads" 
        [accept]="acceptFileExtension" 
        [maxFileSize]="maxFileSize" 
        [auto]="autoUpload" 
        [chooseLabel]="buttonLabel" 
        (onBeforeUpload)="onBeforeUpload($event)"
        (onSelect)="onSelect($event)"
        (onUpload)="onUpload($event)"
    >
        <ng-template pTemplate="content">
            <ul *ngIf="uploadedFiles.length">
                <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
            </ul>
        </ng-template>
    </p-fileUpload>
</div>
