import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { LoggerService } from '../../services/logger.service';
import { UserContextService } from '../../services/user-context.service';
import { Title } from '@angular/platform-browser';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-baseview',
  templateUrl: './baseview.component.html',
  styleUrls: ['./baseview.component.sass'],
})
export class BaseviewComponent implements OnInit {
  @Input() type: string;
  @Input() cards: any[] = [];
  @Input() buildable: boolean;
  @Input() buildButtons: any;
  @Input() envs: boolean = false;

  id: any;
  image_ref_id: any;
  deselected: any;
  selected: any;
  disabled: any;

  entity: any;
  

  buildStatusFieldGroup: any[] = [];
  buildChartData: any = [];

  isLoading: boolean;

  constructor(
    private entityViewService: EntityViewService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private logger: LoggerService,
    private validationService: ValidationsService,
    private titleService: Title,
    public authService: AuthService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;

    const routeParams = this.route.snapshot.paramMap;
    console.log('routeParams', routeParams);

    this.id = Number(routeParams.get('id'));

    await this.entityViewService
      .getEntity(this.type, { query: { id: this.id } })
      .then(async (data: any) => {
        this.logger.log('doc',data);
        this.entity = data;
        if (this.entity.name) {
          this.titleService.setTitle(this.entity.name);
        }
        if(this.entity.image_ref){
          this.image_ref_id = this.entity.image_ref.id
        }

        // if(this.entity.icons && this.type == 'categories'){
        //   for(let icons of this.entity.icons){
        //     this.logger.log('icons', icons)
        //     this.logger.log('icons deselected', icons.deselected.thumbnail_url_256)
        //     this.logger.log('icons selected', icons.selected.thumbnail_url_256)
        //     this.logger.log('icons disabled', icons.disabled.thumbnail_url_256)

        //     this.deselected = icons.deselected.thumbnail_url_256
        //     this.selected = icons.selected.thumbnail_url_256
        //     this.disabled = icons.disabled.thumbnail_url_256
        //   }
        // }

        this.cards.forEach((card) => {
          card.fieldGroups.forEach((group: any) => {
            group.forEach((field: any) => {
              field.value = this.parseValueForView(
                this.entity[field.key],
                field
              );
              // field.value = this.getValueAsString(field.key);
            });
          });
          card.fieldGroups = this.chunkArray(card.fieldGroups);
        });

        if (this.buildable) {
          // render build history chart.
          // this.buildChartData =
          await this.entityViewService.renderBuildHistoryChart(this.entity);

          this.buildStatusFieldGroup = [
            {
              name: 'Build Status',
              key: 'buildStatus',
              controlType: 'auto',
            },
          ];
        }
      })
      .catch((error) => {
        console.log('error getting entity', error);
      });
    this.validationService.validateEnvOrder(this.entity);
    this.isLoading = false;
  }

  parseValueForView(data: any, field: any) {
    if (!data && !['toggleButton'].includes(field.controlType)) return '';

    let output;
    switch (field.controlType) {
      case 'inputText':
      case 'inputTextarea':
      case 'dropdown':
      case 'links':
      case 'auto':
        output = data;
        if (field.key == 'images' && output.length >= 1) {
          // this.logger.log('checking output', output);
          let values: any[] = [];
          data.forEach((o: any) => {
            this.logger.log('o', o);
            values.push(`name: ${o.n}`, `path: ${o.p}`);
          });
          output = values.join(', ');
        }
        break;
      case 'date':
      case 'calendar-start':
      case 'calendar-end':
        output = this.datePipe.transform(new Date(data), 'medium');
        break;
      case 'toggleButton':
        data == true ? (output = field.name) : (output = `Not ${field.name}`);
        break;
      case 'inputNumber-buttons':
        output = data.toString();
        break;
      case 'dropdown_ref':
      case 'autoComplete_ref':
      case 'auto_ref':
        if (field.key == 'progressionLevel_ref') {
          output = data.level;
        } else {
          console.log('field data', data);
          output = data.name;
        }
        break;

      case 'multiSelect':
        output = data.join(', ');
        break;
      case 'multiSelect_ref':
      case 'autoComplete-multi_ref':
        if (field.key == 'achievements_ref') {
          let values: any[] = [];
          data.forEach((o: any) => {
            values.push(o.title);
          });
          output = values.join(', ');
        } else {
          let values: any[] = [];
          data.forEach((o: any) => {
            values.push(o.name);
          });
          output = values.join(', ');
        }
        break;
      case 'rewards_ref':
        let groups0: any[] = [];
        let values0: any[] = [];
        data.forEach((group: any) => {
          for (const [key, value] of Object.entries(group)) {
            values0.push(
              `${key}: ${this.parseValueForView(value, {
                name: _.capitalize(key),
                key: key,
                controlType: 'auto',
              })}`
            );
          }
        });
        groups0.push(values0.join(', '));
        output = groups0.join(`, `);
        break;
      case 'formArray':
        let groups: any[] = [];
        data.forEach((group: any) => {
          if (['costs_ref'].includes(field.key)) {
            groups.push(`${group['c']} ${group['id'].name}`);
          } else {
            let values: any[] = [];
            for (const [key, value] of Object.entries(group)) {
              if (['string', 'number', 'bigint'].includes(typeof value)) {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'auto',
                  })}`
                );
              } else if (['boolean'].includes(typeof value)) {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'toggleButton',
                  })}`
                );
              } else {
                values.push(
                  `${key}: ${this.parseValueForView(value, {
                    name: _.capitalize(key),
                    key: key,
                    controlType: 'dropdown_ref',
                  })}`
                );
              }
            }
            groups.push(values.join(', '));
          }
        });
        output = groups.join(`, `);
        break;
      case 'rules_ref':
        output = data;
        break;
      default:
        return '';
    }

    return output;
  }

  chunkArray(array: string | any[]) {
    if (array.length > 1) {
      return _.chunk(array, array.length / 2 + 1);
    } else return _.chunk(array, array.length);
  }

  getUpdatedDocument(e: any) {
    setTimeout(async () => {
      this.entityViewService
        .getEntity(this.type, { query: { id: this.id } })
        .then(async (data: any) => {
          console.log('doc', data);
          this.entity = data;
          if (this.entity.name) {
            this.titleService.setTitle(this.entity.name);
          }

          // get build data.
          await this.entityViewService
            .getBuildData(this.entity)
            .then((results: any) => {
              if (results) {
                this.entity.buildData = results.buildData;
                this.entity.buildStatus = results.buildStatus;
                this.entity.imageBuildData = results.imageBuildData;
                this.entity.imageBuildStatus = results.imageBuildStatus;
              }
            });
          console.log('got updated entity', this.entity);
        });
    }, 1000);
  }
}
