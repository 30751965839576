import { Component, Input, OnInit } from '@angular/core';
import CoOpDTO from 'src/app/cms-v2/entities/co-op/dtos/CoOpDTO';

@Component({
  selector: 'app-goal-imagery',
  templateUrl: './goal-imagery.component.html',
  styleUrls: ['./goal-imagery.component.sass']
})
export class GoalImageryComponent implements OnInit {

  @Input() record: any;
  @Input() field: any;

  constructor() { }

  ngOnInit(): void {
  }

}
