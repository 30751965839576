import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nuf-collection-view',
  templateUrl: './nuf-collection-view.component.html',
  styleUrls: ['./nuf-collection-view.component.sass']
})
export class NufCollectionViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
