import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-currency-view-v2',
  templateUrl: './currency-view-v2.component.html',
  styleUrls: ['./currency-view-v2.component.sass']
})
export class CurrencyViewV2Component extends BaseDynamicViewComponent implements OnInit {
  CurrencyRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  ngOnInit() {
    this.fields = [
      {
        title: "Currency Data",
        fields: [
          { name: "Internal Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'internalName', isRequired: true, label: 'Internal Name', columnWidth: 12 },
          { name: "Name Single", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'name', isRequired: true, label: 'Name Single', columnWidth: 6 },
          { name: "Name Plural", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'namePlural', isRequired: true, label: 'Name Plural', columnWidth: 6 },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time', columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time', columnWidth: 6 },
          { name: "Year", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'year', label: 'Year', columnWidth: 12 },
          { name: "Icon", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'icon', label: 'Icon', columnWidth: 6 },
          { name: "Sprite Icon", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'spriteIcon', label: 'Sprite Icon', columnWidth: 6 },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Images",
        fields: [
          {
            name: "Misc. Image Record",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'image_ref',
            label: 'Misc. Image Record',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: {fieldName:'image_ref'}
          },
          {
            name: "Image Challenge Prize Button",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'imageChallengePrizeButton_ref',
            label: 'Image Challenge Prize Button',
            optionValue:'_id',
            options: {fieldName:'imageLarge_ref'}
          },
          {
            name: "Thumbnail",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'thumbnail_ref',
            label: 'Thumbnail',
            optionValue:'_id',
            options: {fieldName:'imageLarge_ref'}
          },
          {
            name: "Fly Up Animation",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'flyUpAnimation_ref',
            label: 'Fly Up Animation',
            optionValue:'_id',
            options: {fieldName:'flyUpAnimation_ref'},
            columnWidth: 6
          },
          { name: "Fly Up Animation Color", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.ColorDisplay], clearField: true, key: 'flyUpColor', isRequired: true, label: 'Fly Up Animation Color', disabled: false, columnWidth: 6 },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Images Amounts",
        fields: [
          {
            name: "Image Small",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'imageSmall_ref',
            label: 'Image Small',
            optionValue:'_id',
            options: {fieldName:'imageSmall_ref'},
            columnWidth: 6
          },
          {
            name: "Image Small Amount",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'imageSmallAmount',
            isRequired: true,
            label: 'Image Small Amount',
            columnWidth: 6,
          },
          {
            name: "Image Medium",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'imageMedium_ref',
            label: 'Image Medium',
            optionValue:'_id',
            options: {fieldName:'imageMedium_ref'},
            columnWidth: 6
          },
          { name: "Image Medium Amount", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'imageMediumAmount', isRequired: true, label: 'Image Medium Amount', columnWidth: 6 },
          {
            name: "Image Large",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'imageLarge_ref',
            label: 'Image Large',
            optionValue:'_id',
            options: {fieldName:'imageLarge_ref'},
            columnWidth: 6
          },
          { name: "Image Large Amount", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'imageLargeAmount', isRequired: true, label: 'Image Large Amount', columnWidth: 6 },
          {
            name: "Image 4",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'image_4_ref',
            label: 'Image 4',
            optionValue:'_id',
            options: {fieldName:'image_4_ref'},
            columnWidth: 6
          },
          { name: "Image 4 Amount", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'image_4Amount', isRequired: true, label: 'Image 4 Amount', columnWidth: 6 },
          {
            name: "Image 5",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'image_5_ref',
            label: 'Image 5',
            optionValue:'_id',
            options: {fieldName:'image_5_ref'},
            columnWidth: 6
          },
          { name: "Image 5 Amount", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'image_5Amount', isRequired: true, label: 'Image 5 Amount', columnWidth: 6 },
          {
            name: "Image 6",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'image_6_ref',
            label: 'Image 6',
            optionValue:'_id',
            options: {fieldName:'image_5_ref'},
            columnWidth: 6
          },
          { name: "Image 6 Amount", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'image_6Amount', isRequired: true, label: 'Image 6 Amount', columnWidth: 6 },
          { name: "Cap", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'cap', isRequired: true, label: 'Cap', columnWidth: 3 },
          { name: "Soft Capped", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'softCapped', isRequired: true, label: 'Soft Capped', columnWidth: 3 },
          { name: "Hard Capped", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'hardCapped', isRequired: true, label: 'Hard Capped', columnWidth: 3 },

        ],
        type: DynamicCardType.Card
      },
      {
        title: "Bundle Images and Assets",
        fields: [
          { name: "Bundle Image (IOS)", inputType: DynamicViewType.Boolean, clearField: true, key: 'bundleImage', label: 'Bundle Image (IOS)', columnWidth: 6 },
          { name: "Bundle Image (AND)", inputType: DynamicViewType.Boolean, clearField: true, key: 'bundleImageAnd', label: 'Bundle Image (AND)', columnWidth: 6 },
        ],
        type: DynamicCardType.Card
      }
    ];
    this.sidebarFields = [
      {
        title: "Images Preview",
        fields: [
          { name: "Misc. Image Record",
            inputType: DynamicViewType.sideThumbnail,
            key: 'image_ref',
            label: 'Misc. Image Record',
            options: {
              gridWidth: 6, 
              fieldName: ''  
            },
            columnWidth: 12
          },
          { name: "Image Challenge Prize Button",
            inputType: DynamicViewType.sideThumbnail,
            key: 'imageChallengePrizeButton_ref',
            label: 'Image Challenge Prize Button',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
          { name: "Thumbnail",
            inputType: DynamicViewType.sideThumbnail,
            key: 'thumbnail_ref',
            label: 'Thumbnail',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
          { name: "Fly Up Animation",
            inputType: DynamicViewType.sideThumbnail,
            key: 'flyUpAnimation_ref',
            label: 'Fly Up Animation',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
          { name: "Image Small",
            inputType: DynamicViewType.sideThumbnail,
            key: 'imageSmall_ref',
            label: 'Image Small',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
          { name: "Image Medium",
            inputType: DynamicViewType.sideThumbnail,
            key: 'imageMedium_ref',
            label: 'Image Medium',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
          { name: "Image Large",
            inputType: DynamicViewType.sideThumbnail,
            key: 'imageLarge_ref',
            label: 'Image Large',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
          { name: "Image 4",
            inputType: DynamicViewType.sideThumbnail,
            key: 'image_4_ref',
            label: 'Image 4',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
          { name: "Image 5",
            inputType: DynamicViewType.sideThumbnail,
            key: 'image_5_ref',
            label: 'Image 5',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
          { name: "Image 6",
            inputType: DynamicViewType.sideThumbnail,
            key: 'image_6_ref',
            label: 'Image 6',
            options: {
              gridWidth: 6, 
              fieldName: ''
            },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
    ]

    this.CurrencyRecord = {
      enabled: false
    };
    this.title = "Currency";
    this.viewRoute = 'currency-v2';
    this.isLoading = false;
  }
}
