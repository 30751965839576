import { Component, OnInit, ViewChild } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import SeasonPassDTO from '../../dtos/SeasonPassDTO';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-season-pass-form-v3',
  templateUrl: './season-pass-form-v3.component.html',
  styleUrls: ['./season-pass-form-v3.component.sass']
})
export class SeasonPassFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  seasonPassRecord: SeasonPassDTO = new SeasonPassDTO();
  constructor(private validation: BaseInputValidationServiceService)
  {
    super();
  }

  /**
   * Season Pass Form V3 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Garden Pass Data",
        fields:
        [
          {
            name: "Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name',
            disabled: false
          },
          {
            name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'internalName',
            isRequired: true,
            label: 'Internal Name',
            disabled: false
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            isRequired: false,
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.seasonPassRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
          },
          {
            name: "Internal Notes",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Editor],
            clearField: true,
            key: 'copy',
            label: 'Internal Notes',
            columnWidth: 12,
            isRequired: false,
            disabled: false,
          },
          {
            name: "Imagery",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'bannerImage',
            label: 'Imagery',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'bannerImage',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: 3 },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Background Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'backgroundImage_ref',
            label: 'Background Image',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'backgroundImage_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: BuildType.Images },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Midground Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'midgroundImage_ref',
            label: 'Midground Image',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'midgroundImage_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: BuildType.Images },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Foreground Image",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'foregroundImage_ref',
            label: 'Foreground Image',
            columnWidth: 6,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'foregroundImage_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: BuildType.Images },
              isdisplayNameIDPath: true,
              isMiscAsset: true,
              initializeWith_id: true
            },
            style: { width: '100%' },
            showClear: true
          }          
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Tracks",
        fields:
        [
          {
            name: 'Free',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'free',
            isRequired: false,
            label: 'Free',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: { fieldName: 'free', apiController: 'rewards-track', minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 }, customQuery: { type: 3 } }
          },
          {
            name: 'Paid',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'paid',
            isRequired: false,
            label: 'Paid',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            autoDisplayFirst: false,
            style: { 'min-width': '30em' },
            options: { fieldName: 'paid', apiController: 'rewards-track', minimal: false, autopopulate: true, virtuals: false, sort: { name: 1 }, customQuery: { type: 4 } }
          },
          {
            name: 'Season Pass Tracks',
            inputType: DynamicInputType.SeasonPassFormTracksCustomField,
            key: '',
            customFields:
            [
              {
                key: 'freeObject',
                touched: false
              },
              {
                key: 'paidObject',
                touched: false
              },
            ],
            hideOnCreate: true
          }
        ],
        type: DynamicCardType.Card
      }
    ]

    this.title = "Season Reward";
    this.viewRoute = 'season-pass';
    this.isLoading = false;
  }
}
