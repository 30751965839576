<p-orderList
  [value]="nurtureItems" (onReorder)="onNurtureChange(nurtureItems)"
  [listStyle]="{ 'max-height': '30rem' }" [dragdrop]="true"
  >
  <ng-template let-product pTemplate="header">
    <!-- <h2 class="p-mb-4 p-mt-0">Nurture Items</h2> -->
    <div class="p-grid">
      <div class="p-col-10">
        <span ngClass="p-float-label">
            <p-dropdown
              style="min-width: 200px;"
              [(ngModel)]="newNurture"
              [options]="options['nurture']"
              optionLabel="name"
              optionValue="_id"
              [autoDisplayFirst]="false"
              [filter]="true"
              filterBy="name"
            ></p-dropdown>
            <label for="itemFileType_ref"> Select Nurture Item </label>
        </span>
      </div>
      <div class="p-col-2">
        <button
          pButton
          type="button"
          icon="pi pi-plus"
          class="p-button-rounded p-button-text p-button-success float-left"
          (click)="addNurture()"
        ></button>
      </div>
    </div>

  </ng-template>
  <ng-template let-item pTemplate="item">
    <div class="p-grid" style="border-style: solid; border-width: 1px; border-color: rgb(147, 147, 147); border-radius: 5px;">
      <div class="p-col-2">
        <img src="{{ item.coinThumbnail_ref && item.coinThumbnail_ref.thumbnail_url_128 ?
          item.coinThumbnail_ref.thumbnail_url_128  :
          'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg' }}"
          [alt]="item.name" class="p-mr-3"
          width="100%"
        />
      </div>
      <div class="p-col-9" style=" display: flex; align-items: center; justify-content: flex-start;">
        <span>
          <strong>Name: </strong>{{ item.name }}
          <a [routerLink]="['/nurture', item.id]" [ngStyle]="{ color: 'var(--primary-color)' }" target="_blank">
            ({{ item.id }})
          </a> <br>
          <strong>Rarity: </strong>
          <a *ngIf="item.rarity_ref" [routerLink]="['/nurture-rarity', item.rarity_ref.id]" [ngStyle]="{ color: 'var(--primary-color)' }" target="_blank">
            {{ item.rarity_ref.name }}({{ item.rarity_ref.id }})
          </a>
        </span>
      </div>
      <div class="p-col-1" style=" display: flex; align-items: start; justify-content: flex-end;">
        <button
          pButton
          type="button"
          icon="pi pi-times"
          class="p-button-rounded p-button-text p-button-danger float-left"
          (click)="removeNurture(item.id)"
        ></button>
      </div>
    </div>
  </ng-template>
</p-orderList>
