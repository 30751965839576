import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class UserContextService {
  _isGuestUser: boolean = false;
  _testUser: any;
  _testUsername: any;
  
  constructor (
    private messageService: MessageService
  ) {}

  /**
   * If the user has only read-only access.
   */
  get isGuestUser() {
    return this._isGuestUser;
  }

  set isGuestUser(value: boolean) {
    localStorage.setItem('isGuestUser', value.toString());
    this._isGuestUser = value;
  }

   /**
   * Retrieves test user
   */
   get testUser() {
     return this._testUser;
   }

  /**
   * Sets read only user
   */
  set testUser(user: any) {
    localStorage.setItem('testUsername', user.username);
    this._testUser = user;
  }

  /**
   * Retrieves read only username
   */
  get testUsername() {
    this._testUsername = localStorage.getItem('testUsername');
    return this._testUsername;
  }

  /**
   * Alert notification for no access to a given resource
   */
  noAccessToResourceAlert() {
    let message = {
      severity: 'error',
      summary: 'Access Denied',
      detail: `You do not have access to this resource.`,
    };
    this.messageService.add(message);
  }
}
