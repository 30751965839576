import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseviewComponent } from 'src/app/common/components/baseview/baseview.component';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { BuildType } from 'src/app/enums/build-type';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import _, { merge } from 'lodash';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { PrimeIcons } from 'primeng/api';

@Component({
  selector: 'app-season-pass-view',
  templateUrl: './season-pass-view.component.html',
  styleUrls: ['./season-pass-view.component.sass'],
})
export class SeasonPassViewComponent implements OnInit
{
  entity: any;
  type = 'season-pass';
  parsedSeasonPass: any = [];
  get buildType(): typeof BuildType
  {
    return BuildType;
  }
  isLoading: boolean;
  id: any;

  localizedValues: any;
  displayLocalizedValues: boolean = false;

  constructor
  (
    private entityViewService: EntityViewService,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private loggerService: LoggerService,
    private datePipe: DatePipe,
    private titleService: Title,
    private dataService: DataService
  ) {}

  /**
   * Season Pass view component initialization
   */
  async ngOnInit() {
    this.isLoading = true;
    const routeParams = this.route.snapshot.paramMap;

    this.id = Number(routeParams.get('id'));

    await this.entityViewService
      .getEntity(this.type, { query: { id: this.id }, autopopulate: true })
      .then((result: any) => {
        if (result) {
          this.entity = result;
          if (this.entity.name) {
            this.titleService.setTitle(this.entity.name);
          }


        }
      });
    this.parseSeasonPass();
    this.loggerService.log("Parsed Season Pass: ", this.parsedSeasonPass);
    this.isLoading = false;
  }

  /**
   * Parse Season Pass data.
   */
  parseSeasonPass()
  {
    this.parsedSeasonPass = [];
    if(this.entity && ((this.entity.paid && this.entity.paid.stageMilestone) || (this.entity.free && this.entity.free.stageMilestone)) && (this.entity.paid.stageMilestone.length > 0 || this.entity.free.stageMilestone.length > 0))
    {
      // Merge both paid and free stage milestones.
      // Then sort the merged array.
      let mergedStageMilestones: Array<number> = _.union(this.entity.paid.stageMilestone, this.entity.free.stageMilestone);
      mergedStageMilestones = mergedStageMilestones && mergedStageMilestones.length > 0 ? mergedStageMilestones.sort((a, b) => a - b): [];

      for(let stage of mergedStageMilestones)
      {
        // If both paid and free milestones are present for a stage, then add both.
        if(this.entity.free && this.entity.free.stageMilestone && this.entity.free.stageMilestone.includes(stage) && this.entity.paid && this.entity.paid.stageMilestone && this.entity.paid.stageMilestone.includes(stage))
        {
          this.parsedSeasonPass.push({ stage: stage, paid: this.entity.paid.paid[this.entity.paid.stageMilestone.indexOf(stage)], free: this.entity.free.free[this.entity.free.stageMilestone.indexOf(stage)]});
        }
        // If only paid milestones are present for a stage, then add only paid.
        else if(this.entity.paid && this.entity.paid.stageMilestone && this.entity.paid.stageMilestone.includes(stage))
        {
          this.parsedSeasonPass.push({ stage: stage, paid: this.entity.paid.paid[this.entity.paid.stageMilestone.indexOf(stage)]});
        }
        // If only free milestones are present for a stage, then add only free.
        else if(this.entity.free && this.entity.free.stageMilestone && this.entity.free.stageMilestone.includes(stage))
        {
          this.parsedSeasonPass.push({ stage: stage, free: this.entity.free.free[this.entity.free.stageMilestone.indexOf(stage)]});
        }
      }
    }
  }

  async fetchLocalized() {
    const data = await this.dataService.fetchGridlyRecord(this.id, this.type);
    this.localizedValues = this.formatData(data);
    this.displayLocalizedValues = true;
  }

  formatData(data: any[]): any[] {
    const excludedColumns = ['update_trigger', 'glossaryRes', 'start', 'translationRequest', 'verified', 'isLive'];

    return data.map(record => {
      const readableId = record.id.split('_')[1] || record.id;
      const formattedRecord: any = { id: readableId.charAt(0).toUpperCase() + readableId.slice(1) };
      record.cells.forEach((cell: any) => {
        if (!excludedColumns.includes(cell.columnId)) {
          formattedRecord[cell.columnId] = cell.value;
        }
      });
      return formattedRecord;
    });
  }

  getColumns(data: any[]): string[] {
    if (data.length === 0) {
      return [];
    }
    return Object.keys(data[0]).filter(key => key !== 'id');
  }
}
