import { Component, OnInit } from '@angular/core';
import { ColDef, TextCellEditor } from 'ag-grid-community';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';

@Component({
  selector: 'app-localization-configuration-table',
  templateUrl: './localization-configuration-table.component.html',
  styleUrls: ['./localization-configuration-table.component.sass']
})
export class LocalizationConfigurationTableComponent implements OnInit {

  public entity: string = 'localization-configuration';
  public columnDefs: ColDef[] = [];
  public columnSelection: string;


  constructor(
    private agGridToolsService: AgGridToolsService,
  ) {
  }

  async ngOnInit(): Promise<void> {

    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc','desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: true,
      },
      {
        headerName: 'gridViewId',
        field: 'gridViewId',
        cellRendererParams: {
          entity: 'localization-configuration'
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'modelName',
        field: 'modelName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
        },
        floatingFilter: true,

        cellEditor: TextCellEditor,
        cellEditorParams: {
        },
        type: 'editableColumn',
      },
      {
        headerName: 'migrated',
        field: 'migrated',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        filterParams: {
        },
        floatingFilter: true,

        cellEditor: TextCellEditor,
        cellEditorParams: {
        },
        type: 'editableColumn',
      },
    ]
    this.columnSelection = this.columnDefs.map(col => col.field).join(' ');

  }
  }

