<div class="settings-container">
  <!-- Sidebar -->
  <div class="schedule-sidebar" [@sidebarState]="sidebarState">
    <div class="sidebar-content">
      <div class="sidebar-header">
        <h3>Settings</h3>
        <button pButton 
                icon="pi pi-angle-left"
                class="p-button-rounded p-button-text p-button-sm"
                (click)="toggleSidebar()">
        </button>
      </div>
      <ul class="menu-items">
        <li 
          *ngFor="let tab of tabArray; let i = index"
          [class.active]="activeTabIndex === i"
          (click)="onTabChange(i)">
          <span [ngClass]="getIconForTab(tab.key)"></span>
          {{ tab.label }}
        </li>
      </ul>
    </div>
  </div>

  <!-- Floating Button -->
  <button *ngIf="!isSidebarOpen"
          pButton
          icon="pi pi-angle-right"
          class="sidebar-float-button p-button-text"
          (click)="toggleSidebar()">
  </button>

  <!-- Content -->
  <div class="settings-content">
    <ng-container *ngIf="activeTab === 'security'">
      <app-users-and-security></app-users-and-security>
    </ng-container>
    <ng-container *ngIf="activeTab === 'lists'">
      <app-managed-lists></app-managed-lists>
    </ng-container>
    <ng-container *ngIf="activeTab === 'data'">
      <app-data-management [cmsSettings]="cmsSettings"></app-data-management>
    </ng-container>
    <ng-container *ngIf="activeTab === 'rules'">
      <app-rules></app-rules>
    </ng-container>
    <ng-container *ngIf="activeTab === 'dynamicEntities'">
      <app-dynamic-entities></app-dynamic-entities>
    </ng-container>
  </div>
</div>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<p-dialog 
  [(visible)]="showJsonDialog" 
  [header]="selectedItemLabel" 
  [style]="{width: '50vw'}" 
  [modal]="true">
  <div class="json-viewer">
    <pre><code>{{ selectedItemJson | json }}</code></pre>
  </div>
  <div class="p-d-flex p-jc-end p-mt-3">
    <button pButton label="Copy" icon="pi pi-copy" class="p-button-secondary p-mr-2" 
            (click)="copyJsonToClipboard()"></button>
    <button pButton label="Close" class="p-button-secondary" 
            (click)="showJsonDialog = false"></button>
  </div>
</p-dialog>
