<div [style]="{ margin: '1em' }" *ngIf="entity">
  <div class="p-d-flex p-jc-between">
    <div>
      <h1 style="margin: 0 0 1em" class="p-mr-3 p-d-inline">
        {{ entity.internalName }}
      </h1>
      <a
        pButton
        pRipple
        label="Edit"
        type="button"
        class="p-d-inline"
        icon="pi pi-pencil"
        href="/{{ type }}/edit/{{ entity.id }}"
      ></a>
    </div>
    <div class="p-d-flex p-ai-center">
      <!-- Add the notes popup component -->
      <ng-container *ngIf="isNotesPopUp">
        <app-notes-popup [record]="entity" [entityType]="type"></app-notes-popup>
      </ng-container>
    <button
      pButton
      pRipple
      label="Localized Values"
      type="button"
      icon="fa fa-language"
      (click)="fetchLocalized()"
      class="p-ml-2"
    ></button>
    </div>
  </div>


  <div class="p-grid nested-grid p-mt-3" *ngIf="entity">
    <!-- Main Column (left) -->
    <div class="p-col-12 p-lg-8">
      <!-- Main Card -->
      <p-card class="p-mx-2 p-shadow-4">
        <div class="p-my-1">
          <strong>ID: </strong><span class="p-text-normal">{{ entity.id }}</span>
        </div>
        <div class="p-my-1">
          <strong>Name: </strong><span class="p-text-normal">{{ entity.name }}</span>
        </div>
        <div class="p-my-1">
          <strong>Enabled: </strong><span class="p-text-normal">{{ entity.enabled ? 'Yes' : 'No' }}</span>
        </div>
        <div class="p-my-1">
          <strong>Start Date: </strong><span class="p-text-normal">{{ entity.start | officeTime: 'medium' }}</span>
        </div>
        <div class="p-my-1">
          <strong>End Date: </strong><span class="p-text-normal">{{ entity.end | officeTime: 'medium' }}</span>
        </div>
        <div class="p-my-1">
          <strong>Free Stage Milestone: </strong><span class="p-text-normal">{{ entity.free ? entity.free.stageMilestone : null }}</span>
        </div>
        <div class="p-my-1">
          <strong>Paid Stage Milestone: </strong><span class="p-text-normal">{{ entity.paid ? entity.paid.stageMilestone: null }}</span>
        </div>
        <div class="p-my-1" *ngIf="entity.free">
          <strong>Free Rewards Tracks: </strong><a  [ngStyle]="{ color: 'var(--primary-color)' }" [routerLink]="'/rewards-track/'+entity.free.id" class="p-text-normal">{{ entity.free.name }} </a>
        </div>
        <div class="p-my-1" *ngIf="entity.paid">
          <strong>Paid Rewards Tracks: </strong><a  [ngStyle]="{ color: 'var(--primary-color)' }" [routerLink]="'/rewards-track/'+entity.paid.id" class="p-text-normal">{{ entity.paid.name }} </a>
        </div>
        <div class="p-my-3">
          <h4 class="p-d-inline">Internal Notes:</h4>
          <div class="p-ml-2" *ngIf="entity.copy" [innerHTML]="getSafeHtml(entity.copy)"></div>
        </div>
      </p-card>

      <p-card class="p-mx-2 p-shadow-4">
        <ng-template pTemplate="header">
          <span class="p-d-flex p-pt-1 p-px-3">
            <span class="p-card-title">
             Images
            </span>
          </span>
        </ng-template>
        <div class="p-my-1" *ngIf="entity.bannerImage">
          <strong>Imagery: </strong><a  [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank" [href]="'/miscellaneous-build/'+entity.bannerImage.id" class="p-text-normal">{{ entity.bannerImage.name }} </a>
        </div>
        <div class="p-my-1" *ngIf="entity.backgroundImage_ref">
          <strong>Background Image: </strong><a  [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank" [href]="'/miscellaneous-build/'+entity.backgroundImage_ref.id" class="p-text-normal">{{ entity.backgroundImage_ref.name }} </a>
        </div>
        <div class="p-my-1" *ngIf="entity.midgroundImage_ref">
          <strong>Midground Image: </strong><a  [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank" [href]="'/miscellaneous-build/'+entity.midgroundImage_ref.id" class="p-text-normal">{{ entity.midgroundImage_ref.name }} </a>
        </div>
        <div class="p-my-1" *ngIf="entity.foregroundImage_ref">
          <strong>Foreground Image: </strong><a  [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank" [href]="'/miscellaneous-build/'+entity.foregroundImage_ref.id" class="p-text-normal">{{ entity.foregroundImage_ref.name }} </a>
        </div>
      </p-card>

      <p-card>
        <div class="p-grid">
          <h2 class="p-ml-5 p-d-inline p-col-5">Free Track</h2>
          <h2 class="p-ml-5 p-d-inline p-col-6">Paid Track</h2>
        </div>
        <!-- Timeline - Paid - Free tracks -->
        <p-timeline [value]="parsedSeasonPass">
          <ng-template pTemplate="marker" let-event>
              <span class="custom-marker shadow-3">
                <p-badge [value]="event.stage"></p-badge>
              </span>
          </ng-template>
          <ng-template pTemplate="opposite" let-event>
            <p-card *ngIf="event.free && event.free.length > 0" styleClass="p-my-2 p-shadow-7">
              <div class="p-my-1" *ngFor="let reward of event.free">
                <strong>Reward: </strong>{{reward.lineItemType}}
                <br/>
                <strong>Name: </strong>{{reward.id.name}}
                <br/>
                <Strong>Quantity: </Strong>{{reward.c}}
                <p-divider></p-divider>
              </div>
            </p-card>
          </ng-template>
          <ng-template pTemplate="content" let-event>
            <p-card *ngIf="event.paid && event.paid.length > 0" styleClass="p-my-2 p-shadow-7">
              <div class="p-my-1" *ngFor="let reward of event.paid">
                <strong>Reward: </strong>{{reward.lineItemType}}
                <br/>
                <strong>Name: </strong>{{reward.id.name}}
                <br/>
                <Strong>Quantity: </Strong>{{reward.c}}
                <p-divider></p-divider>
              </div>
            </p-card>
          </ng-template>
        </p-timeline>
      </p-card>
      <p-card class="p-mx-2 p-shadow-4">
        <ng-template pTemplate="header">
          <span class="p-card-title">History</span>
        </ng-template>
        <div class="p-mx-5">
          <p-button
            class="p-mx-2"
            label="See latest History Changes"
            routerLink="/history/season-pass/{{ entity.id }}"
          ></p-button>
          <p-button
            class="p-mx-2"
            label="See latest Builds history"
            routerLink="/build-history/season-pass/{{ entity.id }}"
          ></p-button>
        </div>
      </p-card>
      <br />
    </div>
    <div class="p-col-12 p-lg-4">

      <ng-container *ngIf="true">
        <h4 class="p-m-2 p-d-none p-d-lg-flex">Environment(s):</h4>
        <span class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex">
           <!-- Environment(s) -->
           <ng-container>
            <div
              class="p-col p-m-2"
              [ngStyle]="{
                position: 'relative',
                color: 'var(--primary-color-text)',
                'background-color': 'green',
                'border-radius': '0.5em'
              }"
            >
              <h3 class="p-d-inline p-m-0 p-text-center">
                {{ entity.env && entity.env.length > 0 ? entity.env.join(', ') : 'No Environment Found' }}
              </h3>
            </div>
          </ng-container>
        </span>
      </ng-container>
      <div class="p-col">
        <div *ngIf="entity.bannerImage" class="p-mt-3">
          <p>Imagery: {{entity.bannerImage.name}}</p>
          <img
            [src]=" entity.bannerImage.thumbnails ?
              entity.bannerImage.thumbnails[0].path : 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'
            "
              [alt]="entity.bannerImage.name" style="width: 80%; max-width: 400px; object-fit: cover; border-radius: 10px;"
          >
        </div>
        <div *ngIf="entity.backgroundImage_ref" class="p-mt-3">
          <p>Background Image: {{entity.backgroundImage_ref.name}}</p>
          <img
            [src]=" entity.backgroundImage_ref.thumbnails ?
              entity.backgroundImage_ref.thumbnails[0].path : 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'
            "
              [alt]="entity.backgroundImage_ref.name" style="width: 80%; max-width: 400px; object-fit: cover; border-radius: 10px;"
          >
        </div>
        <div *ngIf="entity.midgroundImage_ref" class="p-mt-3">
          <p>Midground Image: {{entity.midgroundImage_ref.name}}</p>
          <img
            [src]=" entity.midgroundImage_ref.thumbnails ?
              entity.midgroundImage_ref.thumbnails[0].path : 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'
            "
              [alt]="entity.midgroundImage_ref.name" style="width: 80%; max-width: 400px; object-fit: cover; border-radius: 10px;"
          >
        </div>
        <div *ngIf="entity.foregroundImage_ref" lass="p-mt-3">
          <p>Foreground Image: {{entity.foregroundImage_ref.name}}</p>
          <img
            [src]=" entity.foregroundImage_ref.thumbnails ?
              entity.foregroundImage_ref.thumbnails[0].path : 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'
            "
              [alt]="entity.foregroundImage_ref.name" style="width: 80%; max-width: 400px; object-fit: cover; border-radius: 10px;"
          >
        </div>

      </div>
    </div>
  </div>

</div>


<p-dialog
[(visible)]="displayLocalizedValues"
[dismissableMask]="true"
[modal]="true"
[transitionOptions]="'200ms'"
[breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
[style]="{ width: 'auto', minWidth: '50vw' }"
[contentStyle]="{ 'overflow': 'visible' }"
>
<ng-template pTemplate="header">
  <h3 class="p-m-0">Localized Values</h3>
</ng-template>

<div *ngIf="this.localizedValues" class="p-text-center" style="overflow: auto;">
  <p-table [value]="localizedValues" [autoLayout]="true">
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th *ngFor="let column of getColumns(localizedValues)">
          {{ column }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row>
      <tr>
        <td style="text-align: justify;">{{ row.id }}</td>
        <td style="text-align: justify;" *ngFor="let column of getColumns(localizedValues)">
          {{ row[column] || 'N/A' }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
</p-dialog>