import { Component, OnInit, ViewChild, Input, Injector } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicFormV2Component } from 'src/app/common/components/dynamic-form-v2/dynamic-form-v2.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import CurrencyDto from '../../dtos/CurrencyDTO';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-currency-form-v3',
  templateUrl: './currency-form-v3.component.html',
  styleUrls: ['./currency-form-v3.component.sass']
})
export class CurrencyFormV3Component extends BaseDynamicFormComponent implements OnInit
{
  @ViewChild(DynamicFormV2Component) dynamicFormComponent: DynamicFormV2Component;
  currencyRecord: CurrencyDto = new CurrencyDto();
  constructor(
    private validation: BaseInputValidationServiceService,
    injector: Injector
  ) {
    super(injector);
  }

  /**
   * Currency Form V3 Component Initialization
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Currency Data",
        fields:
        [
          { name: "Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'internalName',
            isRequired: false,
            label: 'Internal Name',
            disabled: false,
            columnWidth: 4,
          },
          { name: "Name Single",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Name Single',
            disabled: false,
            columnWidth: 4,
          },
          {
            name: "Name Plural",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'namePlural',
            isRequired: false,
            label: 'Name Plural',
            disabled: false,
            columnWidth: 4
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            isRequired: false,
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            // inputType: DynamicInputType.Calendar,
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.currencyRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
            columnWidth: 6
          },
          {
            name: 'Year',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            key: 'year',
            label: 'Year',
            options: { fieldName: 'year', values: [{value:'2021'}, {value:'2022'}, {value:'2023'}, {value:'2024'}, {value:'2025'},] },
            clearField: false,
            autoDisplayFirst: false,
            filter: true,
            style: { 'min-width': '12.5em' },
            showClear: true,
            isRequired: true,
            optionLabel:'value',
            optionValue:'value',
            scrollHeight: '200px',
            columnWidth: 12
          },
          { name: "Icon",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'icon',
            isRequired: false,
            label: 'Icon',
            disabled: false,
            columnWidth: 6
          },
          { name: "Sprite Icon",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'spriteIcon',
            isRequired: false,
            label: 'Sprite Icon',
            disabled: false,
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Images",
        fields:
        [
          {
            name: "Misc. Image Record",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'image_ref',
            label: 'Misc. Image Record',
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'image_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Image Challenge Prize Button",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'imageChallengePrizeButton_ref',
            label: 'Image Challenge Prize Button',
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'imageChallengePrizeButton_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Thumbnail",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'thumbnail_ref',
            label: 'Thumbnail',
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'thumbnail_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },
          {
            name: "Fly Up Animation",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'flyUpAnimation_ref',
            label: 'Fly Up Animation',
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'flyUpAnimation_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true,
            columnWidth: 6
          },
          {
            name: "Fly Up Animation Color",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.ColorPicker],
            clearField: false,
            key: 'flyUpColor',
            isRequired: true,
            label: 'Fly Up Animation Color',
            disabled: false,
            columnWidth: 6
          },          
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Image Amounts",
        fields:
        [
          {
            name: "Image Small",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'imageSmall_ref',
            label: 'Image Small',
            columnWidth: 8,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'imageSmall_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
                isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },          
          {
            name: "Image Small Amount",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            isRequired: false,
            clearField: true,
            key: 'imageSmallAmount',
            label: 'Image Small Amount',
            inputNumberShowButtons: true,
            columnWidth: 4
          },
          {
            name: "Image Medium",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'imageMedium_ref',
            label: 'Image Medium',
            columnWidth: 8,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'imageMedium_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },          
          {
            name: "Image Medium Amount",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'imageMediumAmount',
            isRequired: false,
            label: 'Image Medium Amount',
            inputNumberShowButtons: true,
            columnWidth: 4
          },
          {
            name: "Image Large",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'imageLarge_ref',
            label: 'Image Large',
            columnWidth: 8,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'imageLarge_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },          
          {
            name: "Image Large Amount",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            isRequired: false,
            key: 'imageLargeAmount',
            label: 'Image Large Amount',
            inputNumberShowButtons: true,
            columnWidth: 4
          },
          {
            name: "Image 4",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'image_4_ref',
            label: 'Image 4',
            columnWidth: 8,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'image_4_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },          
          {
            name: "Image 4 Amount",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            isRequired: false,
            key: 'image_4Amount',
            label: 'Image 4 Amount',
            inputNumberShowButtons: true,
            columnWidth: 4
          },
          {
            name: "Image 5",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'image_5_ref',
            label: 'Image 5',
            columnWidth: 8,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'image_5_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },          
          {
            name: "Image 5 Amount",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            isRequired: false,
            key: 'image_5Amount',
            label: 'Image 5 Amount',
            inputNumberShowButtons: true,
            columnWidth: 4
          },
          {
            name: "Image 6",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.AutoComplete],
            clearField: true,
            key: 'image_6_ref',
            label: 'Image 6',
            columnWidth: 8,
            autocompleteMultipleValues: false, 
            autocompleteShowEmptyMessage: true,
            autocompleteField: 'name',
            autocompleteMinLength: 3,
            suggestions: {
              fieldName: 'image_6_ref',
              apiController: 'miscellaneous-build',
              autopopulate: false,
              minimal: false,
              virtuals: false,
              customQueryField: 'name',
              select: '_id name id path',
              customQuery: { entityType: { $in: [3, 5] } },
              isdisplayNameIDPath: true,
              isMiscAsset: true 
            },
            style: { width: '100%' },
            showClear: true
          },          
          {
            name: "Image 6 Amount",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            isRequired: false,
            key: 'image_6Amount',
            label: 'Image 6 Amount',
            inputNumberShowButtons: true,
            columnWidth: 4
          },
          {
            name: "Cap",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            isRequired: false,
            key: 'cap',
            label: 'Cap',
            inputNumberShowButtons: true,
            inputNumberMin: 0,
            columnWidth: 4
          },
          {
            name: "Soft Capped",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Checkboxv2],
            key: 'softCapped',
            isRequired: false,
            label: 'Soft Capped',
            disabled: false,
            columnWidth: 2,
            validate: (incomingValue: boolean, record: any, fieldKey: string) => {
              if(incomingValue && record.hardCapped){
                return [false, "Currency needs to be either Soft Capped or Hard Capped, not both"];
              } else {
                return true;
              }
            },
          },
          {
            name: "Hard Capped",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Checkboxv2],
            key: 'hardCapped',
            isRequired: false,
            label: 'Hard Capped',
            disabled: false,
            columnWidth: 2,
            validate: (incomingValue: boolean, record: any, fieldKey: string) => {
              if(incomingValue && record.softCapped){
                return [false, "Currency needs to be either Soft Capped or Hard Capped, not both"];
              } else {
                return true;
              }
            },
          },
        ],
        type: DynamicCardType.Card,
      },
      {
        title: "Bundle Images and Assets",
        fields:
        [
          {
            name: "Bundle Images (IOS)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            clearField: false,
            key: 'bundleImage',
          },
          {
            name: "Bundle Images (AND)",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.InputSwitch],
            clearField: false,
            key: 'bundleImageAnd',
          },
        ],
        type: DynamicCardType.Card,
        columns: 2
      }
    ]

    this.title = "Currency";
    this.viewRoute = 'currencies';
    this.isLoading = false;
  }

  validateCurrencyForm(form: any)
  {
    let confirmValidationCallback = this.validation.colorValidator(form.flyUpColor);
    let message = confirmValidationCallback ? 'Valid' : 'The color entered is invalid. Please enter a valid hex color.';

    return { confirmValidationCallback, message }
  }
}

