<p-dialog 
  [(visible)]="visible" 
  [header]="header"
  [style]="{width: width}"
  [modal]="true"
  [closable]="true"
  (onHide)="handleCancel()">
  <div class="p-fluid">
    <form [formGroup]="form" (ngSubmit)="handleSubmit()">
      <div *ngFor="let field of fields" class="p-field p-mb-3">
        <ng-container [ngSwitch]="getFieldType(field)">
          <!-- Text Input -->
          <ng-container *ngSwitchCase="'text'">
            <span class="p-float-label">
              <input 
                [id]="field.key"
                type="text"
                pInputText
                [formControlName]="field.key"
                [disabled]="submitting"
              />
              <label [for]="field.key">{{field.label}}</label>
            </span>
          </ng-container>

          <!-- Number Input -->
          <ng-container *ngSwitchCase="'number'">
            <span class="p-float-label">
              <input 
                [id]="field.key"
                type="number"
                pInputNumber
                [formControlName]="field.key"
                [disabled]="submitting"
              />
              <label [for]="field.key">{{field.label}}</label>
            </span>
          </ng-container>

          <!-- Textarea -->
          <ng-container *ngSwitchCase="'textarea'">
            <span class="p-float-label">
              <textarea 
                [id]="field.key"
                pInputTextarea
                [formControlName]="field.key"
                [rows]="field.rows || 3"
                [disabled]="submitting"
              ></textarea>
              <label [for]="field.key">{{field.label}}</label>
            </span>
          </ng-container>

          <!-- Dropdown -->
          <ng-container *ngSwitchCase="'dropdown'">
            <span class="p-float-label">
              <p-dropdown
                [id]="field.key"
                [formControlName]="field.key"
                [options]="field.options"
                [optionLabel]="field.optionLabel || 'label'"
                [optionValue]="field.optionValue || 'value'"
                [showClear]="true"
                [filter]="true"
                [style]="{width: '100%'}"
                [disabled]="submitting"
              ></p-dropdown>
              <label [for]="field.key">{{field.label}}</label>
            </span>
          </ng-container>

          <!-- Checkbox -->
          <ng-container *ngSwitchCase="'checkbox'">
            <div class="checkbox-field">
              <p-checkbox
                [id]="field.key"
                [formControlName]="field.key"
                [binary]="true"
                [label]="field.label"
                [disabled]="submitting"
                [style]="{'margin-top': '0.5rem'}"
              ></p-checkbox>
            </div>
          </ng-container>
        </ng-container>
        <small class="p-error" *ngIf="form.get(field.key)?.invalid && form.get(field.key)?.touched">
          {{field.errorMessage || 'This field is required'}}
        </small>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <button 
      pButton 
      label="Cancel" 
      icon="pi pi-times" 
      class="p-button-text" 
      (click)="handleCancel()"
      [disabled]="submitting"
    ></button>
    <button 
      pButton 
      label="Submit" 
      icon="pi pi-check" 
      (click)="handleSubmit()"
      [disabled]="!form.valid || submitting"
      [loading]="submitting"
    ></button>
  </ng-template>
</p-dialog>

<p-toast></p-toast>
