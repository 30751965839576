<ng-container *ngFor="let inputTypeField of inputTypeFields">
  <ng-container [ngSwitch]="inputTypeField">

    <!-- Space -->
    <div *ngSwitchCase="InputFieldType.SpaceCol">
    </div>


   <!-- Divider -->
   <ng-container *ngSwitchCase="InputFieldType.Divider">
    <div *ngIf="field.showField">
      <p-divider></p-divider>
    </div>
  </ng-container>

    <!-- Button -->
    <ng-container *ngSwitchCase="InputFieldType.Button">
      <div *ngIf="field.showField">
        <button [style]="{'width':'80%', 'maxWidth':'500px'}" pButton pRipple type="button" [label]="field.label"
          [class]="field.class" (click)="field.touched = true; this.onValueChange(fieldValue)">
        </button>
      </div>
    </ng-container>

    <!-- Clear Field Button -->
    <div *ngSwitchCase="InputFieldType.ClearField" class="p-inputgroup" [ngStyle]="{'width': '100%'}">
      <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
        styleClass="clear-button p-button-text p-button-danger"
        (click)="field.touched = true; fieldValue = null"></p-button>
    </div>

    <!-- Input Switch -->
    <div class="p-inputgroup " *ngSwitchCase="InputFieldType.InputSwitch">
      <div class="flex-container" *ngIf="field.showField">
        <p class="label">{{field.name}}</p>
        <p-inputSwitch class="field-item"
          [(ngModel)]="fieldValue"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="field.touched = true; this.onValueChange(this.fieldValue)">
        </p-inputSwitch>
      </div>
    </div>

    <!-- Text/Label -->
    <ng-container *ngIf="field.showField">
      <div *ngSwitchCase="InputFieldType.Text" class="p-inputgroup" [ngStyle]="{'width': '100%'}">
        <p class="p-m-0 reduced-line-height" [innerHTML]="field.label | lineBreak"></p>
      </div>
    </ng-container>

    <!-- Number -->
    <ng-container *ngIf="field.showField">
      <div class="p-inputgroup" *ngSwitchCase="InputFieldType.Number" [ngStyle]="{'width': '100%'}">
        <span class="flex-container" style="display: flex; align-items: center;">
          <span class="input-field p-float-label flex-grow">
            <p-inputNumber
              [(ngModel)]="fieldValue"
              [required]="field.isRequired"
              [inputId]="field.inputNumberInputId"
              [minFractionDigits]="field.minFractionDigits"
              [maxFractionDigits]="field.maxFractionDigits"
              [mode]="field.inputNumberMode"
              [showButtons]="field.inputNumberShowButtons"
              [min]="field.inputNumberMin"
              [max]="field.inputNumberMax"
              (ngModelChange)="field.touched = true; this.onValueChange(this.fieldValue)">
            </p-inputNumber>
            <label for="number"> {{ field.label }} <strong *ngIf="field.isRequired"
                style="color: crimson">*</strong></label>
            <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
              styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key)"
              style="margin-left: 8px;">
            </p-button>
          </span>
        </span>
      </div>
    </ng-container>

    <!-- Text  -->
    <ng-container *ngSwitchCase="InputFieldType.TextInput">
      <span class="flex-container" *ngIf="field.showField">
        <span class="input-field p-float-label flex-grow">
          <input [(ngModel)]="fieldValue" pInputText
            (ngModelChange)="field.touched = true; this.onValueChange(this.fieldValue)" class="field-item"
            [disabled]="field.disabled && this.isEdit" />
          <label for="name">{{ field.label }} <strong *ngIf="field.isRequired" style="color: crimson">*</strong></label>
        </span>
        <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="clear-button p-button-text p-button-danger" (click)="this.onValueChange(null)"></p-button>
      </span>
    </ng-container>

  <!-- Color Picker Input -->
  <ng-container *ngSwitchCase="InputFieldType.ColorPicker">
    <div *ngIf="field.showField" class="flex-container">
      <div class="color-picker-wrapper">
        <p-colorPicker appendTo="body" [(ngModel)]="fieldValue"
          (ngModelChange)="this.onValueChange(this.fieldValue)"
          (onHide)="this.onValueChange(this.fieldValue)">
        </p-colorPicker>
      </div>
      <div class="input-field-wrapper">
        <span class="input-field-label p-float-label">
          <input [(ngModel)]="fieldValue" pInputText type="text" class="field-item" id="colorText"
            (ngModelChange)="this.onValueChange(this.fieldValue)">
          <label for="colorText">{{ field.label }}</label>
        </span>
      </div>
      <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
        styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key)"></p-button>
    </div>
  </ng-container>

    <!-- Toggle Button -->
    <ng-container *ngSwitchCase="InputFieldType.ToggleButton">
      <div *ngIf="field.showField">
        <div class="toggle-button-wrapper">
          <p-toggleButton [(ngModel)]="fieldValue" (onChange)="this.onValueChange(this.fieldValue)"
            [onLabel]="field.onLabel" [offLabel]="field.offLabel" [disabled]="field.disabled"></p-toggleButton>
        </div>
      </div>
    </ng-container>


    <!-- Calendar/Date Input -->
    <ng-container *ngSwitchCase="InputFieldType.Calendar">
      <div *ngIf="field.showField" class="flex-container" [ngStyle]="{'width': '100%'}">
        <span class="calendar-input p-float-label">
          <p-calendar [(ngModel)]="fieldValue" [showTime]="field.calendarShowTime" [disabled]="field.disabled"
            [yearNavigator]="field.calendarYearNavigatior" [yearRange]="field.calendarYearRange"
            [monthNavigator]="true" [showButtonBar]="true" class="field-item" [showClear]="true"
            [selectOtherMonths]="field.calendarSelectOtherMonths" [ngModelOptions]="{ standalone: true }"
            [defaultDate]="field.calendarDefaultDate ? field.calendarDefaultDate : defaultDate"
            [minDate]="field.calendarMinDate ? minEndDate : null"
            (onSelect)="field.touched = true; this.onValueChange(fieldValue)"
            (onInput)="field.touched = true; this.onValueChange(fieldValue); debounceHandleDateInputChange(field.key, $event)"
            (onClearClick)="field.touched = true; clearField(field.key)" [showIcon]="false" [hideOnDateTimeSelect]="false">
            <ng-template pTemplate="footer">
              <button type="button" (click)="resetTime()" class="p-button p-component p-button-text">Reset
                Time</button>
            </ng-template>
          </p-calendar>
          <label for="calendar"> {{ field.label }} <strong *ngIf="field.isRequired"
              style="color: crimson">*</strong></label>
        </span>
        <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key);"></p-button>
      </div>
    </ng-container>

    <!-- Multi-Select -->
    <ng-container *ngSwitchCase="InputFieldType.MultiSelect">
      <div *ngIf="field.showField" class="flex-container">
        <div class="input-field-wrapper">
          <span class="p-float-label">
            <p-multiSelect [(ngModel)]="fieldValue" (ngModelChange)="this.onValueChange(this.fieldValue)"
              [options]="this.options[field.key]" [optionLabel]="field.optionLabel" [optionValue]="field.optionValue"
              [optionDisabled]="field.optionDisabled" display="chip" filter="true" [filterBy]="field.filterBy"
              [ngStyle]="{'width': '100%'}">
              <!-- Template for selected items -->
              <ng-template let-option pTemplate="selectedItem">
                <div *ngIf="isObject(option)">
                  <ng-container *ngFor="let key of field.labelKeys">
                    <span *ngIf="key === 'id'">({{ option[key] }})</span>
                    <span *ngIf="key === 'name'"><strong> {{ option[key] }}</strong></span>
                    <span *ngIf="key !== 'id' && key !== 'name'">{{ option[key] }}</span>
                  </ng-container>
                </div>
                <div *ngIf="!isObject(option)">
                  {{ option }} <!-- Directly display the string -->
                </div>
              </ng-template>
              <!-- Template for items in dropdown -->
              <ng-template let-option pTemplate="item">
                <div *ngIf="isObject(option)">
                  <ng-container *ngFor="let key of field.labelKeys">
                    <span *ngIf="key === 'id'">({{ option[key] }})</span>
                    <span *ngIf="key === 'name'"><strong> {{ option[key] }}</strong></span>
                    <span *ngIf="key !== 'id' && key !== 'name'"> {{ option[key] }}</span>
                  </ng-container>
                </div>
                <div *ngIf="!isObject(option)">
                  {{ option }} <!-- Directly display the string -->
                </div>
              </ng-template>
            </p-multiSelect>

            <label for="multiselect">{{ field.label }} <strong *ngIf="field.isRequired"
                style="color: crimson">*</strong></label>
          </span>
        </div>
        <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key)">
        </p-button>

        <p-button icon="pi pi-eye" (click)="showSelectedItems()" class="p-mt-2 small-eye-button"></p-button>
      </div>

      <p-dialog header="Selected Items" [(visible)]="isDialogVisible" [modal]="true" [closable]="true" [style]="{width: '500px'}">
        <ul>
          <li *ngFor="let item of selectedItems">
            <a [routerLink]="item.link" target="_blank" style="color: #91c58a; text-decoration: underline;">
              {{ item.name }} ({{ item.id }})
            </a>
          </li>
        </ul>
      </p-dialog>               
    </ng-container>


     <!-- Dropdown -->
     <ng-container *ngSwitchCase="InputFieldType.Dropdown">
      <div *ngIf="field.showField" class="p-inputgroup p-pr-5" [ngStyle]="{'width': '100%'}">
        <span ngClass="p-float-label" *ngIf="!field.hideField">
          <p-dropdown [(ngModel)]="fieldValue" (ngModelChange)="this.onValueChange(this.fieldValue);"
            [options]="this.options[field.key]" [optionLabel]="field.optionLabel" [optionValue]="field.optionValue"
            [autoDisplayFirst]="field.autoDisplayFirst" [filter]="field.filter" [filterBy]="field.filterBy"
            [group]="field.dropdownGroup" [scrollHeight]="field.scrollHeight || '200px'" [showClear]="field.showClear"
            [disabled]="field.disabled">

            <!-- Group Template -->
            <ng-template *ngIf="field.dropdownGroup" let-group pTemplate="group">
              <div class="p-d-flex p-ai-center">
                <span>{{ group.label }}</span>
              </div>
            </ng-template>

            <!-- Dynamic Custom Templates -->
            <ng-template let-option pTemplate="selectedItem" *ngIf="fieldTemplates[field.name] && !field.isdisplayNameIDPath">
              <div *ngIf="fieldTemplates[field.name].header">
                <strong>{{ fieldTemplates[field.name].header[0] }}:</strong> {{
                option[fieldTemplates[field.name].fields[0]] }}
                <strong>{{ fieldTemplates[field.name].header[1] }}:</strong> {{
                option[fieldTemplates[field.name].fields[1]] }}
              </div>
              <div *ngIf="!fieldTemplates[field.name].header">
                {{ option[fieldTemplates[field.name].fields[0]] }} ({{ option[fieldTemplates[field.name].fields[1]] }})
              </div>
            </ng-template>

            <ng-template let-option pTemplate="item" *ngIf="fieldTemplates[field.name] && !field.isdisplayNameIDPath">
              <div *ngIf="fieldTemplates[field.name].header">
                <strong>{{ fieldTemplates[field.name].header[0] }}:</strong> {{
                option[fieldTemplates[field.name].fields[0]] }}
                <strong>{{ fieldTemplates[field.name].header[1] }}:</strong> {{
                option[fieldTemplates[field.name].fields[1]] }}
              </div>
              <div *ngIf="!fieldTemplates[field.name].header">
                {{ option[fieldTemplates[field.name].fields[0]] }} ({{ option[fieldTemplates[field.name].fields[1]] }})
              </div>
            </ng-template>
            <ng-template let-option pTemplate="selectedItem" *ngIf="field.isdisplayNameIDPath">
              <div>
                ({{option.id}}) <strong>Name:</strong> {{ option.name }} | <strong>Path: </strong> {{ option.path }}
              </div>
            </ng-template>
            <ng-template let-option pTemplate="item" *ngIf="field.isdisplayNameIDPath">
              <div>
                ({{option.id}}) <strong>Name:</strong> {{ option.name }} | <strong>Path: </strong> {{ option.path }}
              </div>
            </ng-template>

          </p-dropdown>
          <label for="dropdown"> {{ field.label }} <strong *ngIf="field.isRequired"
              style="color: crimson">*</strong></label>
        </span>
        <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key)"></p-button>
      </div>
    </ng-container>

    <!-- Query Dropdown -->
    <ng-container *ngSwitchCase="InputFieldType.queryDropdown">
      <div *ngIf="field.showField" class="p-inputgroup">
        <span ngClass="p-float-label" *ngIf="!field.hideField">
          <p-dropdown
            [(ngModel)]="fieldValue"
            (ngModelChange)="this.onValueChange(this.fieldValue);"
            [options]="this.options[field.key]"
            [optionLabel]="field.optionLabel"
            [optionValue]="field.optionValue"
            [autoDisplayFirst]="field.autoDisplayFirst"
            [filter]="field.filter"
            [filterBy]="field.filterBy"
            [group]="field.dropdownGroup"
            [scrollHeight]="field.scrollHeight || '200px'"
            [showClear]="field.showClear"
            [disabled]="field.disabled">

            <!-- Conditionally render (id) Name: name | Path: path -->
            <ng-container *ngFor="let template of ['item', 'selectedItem']">
              <ng-template [pTemplate]="template" let-option>
                <ng-container *ngIf="field.options?.isdisplayNameIDPath; else defaultTemplate">
                  ({{option.id}}) <strong>Name:</strong> {{option.name}} | <strong>Path:</strong> {{option.path}}
                </ng-container>
                <ng-template #defaultTemplate>
                  {{option[field.optionLabel]}} <!-- Render default label if the condition is false -->
                </ng-template>
              </ng-template>
            </ng-container>
          </p-dropdown>

          <label for="dropdown">
            {{ field.label }}
            <strong *ngIf="field.isRequired" style="color: crimson">*</strong>
          </label>
        </span>

        <p-button *ngIf="field.clearField && fieldValue"
          ariaLabel="Clear Field"
          icon="pi pi-minus"
          styleClass="clear-button p-button-text p-button-danger"
          (click)="clearField(field.key)">
        </p-button>
      </div>
    </ng-container>

    <!-- AutoComplete -->
    <ng-container *ngSwitchCase="InputFieldType.AutoComplete">
      <div *ngIf="field.showField" class="p-inputgroup">
        <span ngClass="p-float-label" *ngIf="!field.hideField">
          <p-autoComplete #itemsInput [(ngModel)]="fieldValue" [multiple]="field.autocompleteMultipleValues"
            (ngModelChange)="this.onValueChange(this.fieldValue);"
            [suggestions]="suggestions[field.suggestions.fieldName]" (completeMethod)="getSuggestionsForRef(
              $event.query,
              field.suggestions.fieldName,
              field.suggestions.apiController,
              field.suggestions.minimal,
              field.suggestions.autopopulate,
              field.suggestions.sort,
              field.suggestions.virtuals,
              field.suggestions.select,
              field.suggestions.customQuery,
              field.suggestions.customQueryField)" [showEmptyMessage]="field.autocompleteShowEmptyMessage"
            [field]="field.autocompleteField" [minLength]="field.autocompleteMinLength" [ngStyle]="{'width': '100%'}"
            [showClear]="field.showClear" (onSelect)="this.onValueChange(this.fieldValue);"
            (onUnselect)="this.onValueChange(this.fieldValue);" (onClear)="this.onValueChange(this.fieldValue);">
            <ng-template let-item pTemplate="selectedItem">
              {{ item.name ? item.name : (item[field.suggestions.customQueryField] ?
              item[field.suggestions.customQueryField] : null) }} ({{ item.id }})
            </ng-template>

            <ng-template let-item pTemplate="item">
              {{ item.name ? item.name : (item[field.suggestions.customQueryField] ?
              item[field.suggestions.customQueryField] : null) }} ({{ item.id }})
            </ng-template>
          </p-autoComplete>
          <label for="dropdown"> {{ field.label }} <strong *ngIf="field.isRequired" style="color: crimson">*</strong>
          </label>
          <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
            styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key)">
          </p-button>
        </span>
      </div>
    </ng-container>

    <!-- Input Text Area (Long Text Input) -->
    <div *ngSwitchCase="InputFieldType.InputTextArea">
      <span ngClass="p-float-label" *ngIf="field.showField">
        <textarea id="text-area" [(ngModel)]="fieldValue" (ngModelChange)="this.onValueChange(this.fieldValue)"
          [disabled]="field.disabled" [cols]="field.inputTextAreaCols ? field.inputTextAreaCols : null"
          [rows]="field.inputTextAreaRows ? inputTextAreaRows : null" pInputTextarea class="field-item">
        </textarea>
        <label for="text-area"> {{ field.label }} <strong *ngIf="field.isRequired"
            style="color: crimson">*</strong></label>
      </span>
      <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
        styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key)"></p-button>
    </div>

    <!-- Editor -->
    <ng-container *ngSwitchCase="InputFieldType.Editor">
      <div *ngIf="field.showField" class="p-mx-5">
        <h5>{{ field.label }}</h5>
        <div class="flex-container p-my-5" style="align-items: flex-start;">
          <div class="input-field-wrapper" style="flex-grow: 1; margin-right: 0.5em;">
            <span class="p-float-label">
              <p-editor 
                [(ngModel)]="fieldValue" 
                [style]="field.style"
                (ngModelChange)="this.onValueChange(this.fieldValue)"
                (onSelectionChange)="this.onValueChange(this.fieldValue)">
                <ng-template pTemplate="header">
                  <span class="ql-formats">
                    <select class="ql-header p-mr-2" aria-label="Header"></select>
                    <button class="ql-bold" aria-label="Bold"></button>
                    <button class="ql-italic" aria-label="Italic"></button>
                    <button class="ql-underline p-mr-2" aria-label="Underline"></button>
                    <select class="ql-color" aria-label="Color"></select>
                    <select class="ql-background p-mr-2" aria-label="Background Color"></select>
                    <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
                    <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
                    <button class="ql-link p-mr-2" aria-label="Insert Link"></button>
                  </span>
                </ng-template>
              </p-editor>
            </span>
          </div>
          <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
            styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key)"
            style="margin-left: 0; padding: 0;"></p-button>
        </div>
      </div>
    </ng-container>

    <!-- Checkbox Input -->
    <ng-container *ngSwitchCase="InputFieldType.CheckBox">
      <div *ngIf="field.showField" class="p-inputgroup" [ngStyle]="{'width': '100%'}">
        <span ngClass="p-field-checkbox">
          <p-checkbox [(ngModel)]="fieldValue" [inputId]="field.inputId" [binary]="field.checkboxBinary"
            (onChange)="this.onValueChange(this.fieldValue); field.touched = true; ">
          </p-checkbox>
          <label for="checkbox"><strong> {{ field.label }} </strong><strong *ngIf="field.isRequired"
              style="color: crimson">*</strong></label>
        </span>
        <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="p-button-text p-button-danger" (click)="field.touched = true; fieldValue = null"></p-button>
      </div>
    </ng-container>

    <!-- Table Input -->
    <ng-container *ngSwitchCase="InputFieldType.TableInput">
      <div *ngIf="field.showField">
        <table-input [field]="field" [record]="record" [isTableInput]="isTableInput" [entityType]="entityType"
          [deficolumn]="field.deficolumn" (onChange)="handleItemsRefChange($event)">
        </table-input>
      </div>
    </ng-container>


    <!-- Checkboxv2 Input -->
    <ng-container *ngSwitchCase="InputFieldType.Checkboxv2">
      <div *ngIf="field.showField" class="p-field-checkbox">
        <p-checkbox [(ngModel)]="fieldValue" binary="true" [inputId]="field.inputId"
          (onChange)="onValueChange(fieldValue); field.touched = true"></p-checkbox>
        <label for="{{field.inputId}}"><strong>{{ field.label }}</strong><strong *ngIf="field.isRequired"
            style="color: crimson">*</strong></label>
      </div>
    </ng-container>



    <!-- CHIPS -->
    <ng-container *ngSwitchCase="InputFieldType.Chip">
      <div *ngIf="field.showField" class="flex-container">
        <span ngClass="p-float-label">
          <p-chips [(ngModel)]="fieldValue" (onAdd)="this.onValueChange(this.fieldValue)"
            (onRemove)="this.onValueChange(this.fieldValue)"
            [addOnTab]="true" [addOnBlur]="true" separator="," [showClear]="true"
            [ngStyle]="{'width': '100%'}">
          </p-chips>
          <label for="p-chip"> {{ field.label }} <strong *ngIf="field.isRequired" style="color: crimson">*</strong></label>
        </span>
        <p-button *ngIf="field.clearField && fieldValue" ariaLabel="Clear Field" icon="pi pi-minus"
          styleClass="clear-button p-button-text p-button-danger" (click)="clearField(field.key)"></p-button>
      </div>
    </ng-container>

    <!-- Listbox -->

    <div *ngSwitchCase="InputFieldType.ListBox">
      <div class="p-grid" [ngStyle]="{'max-width': '80vw'}" *ngIf="field.showField">
        <div class="p-col-6">
          <p-listbox [options]="options" [(ngModel)]="fieldValue" [metaKeySelection]="false" [checkbox]="true"
            [filter]="true" [multiple]="true" optionLabel="name" optionValue="_id"
            (onChange)="this.onValueChange(this.fieldValue)" [listStyle]="{'max-height':'150px'}"
            [style]="{'width':'25rem'}">
            <ng-template let-item pTemplate="item">
              <div class="item-item">
                <div>{{item.name}}</div>
              </div>
            </ng-template>
          </p-listbox>
        </div>
        <div class="p-col-6">
          <h4>Selected: </h4>
          <ul>
            <li class="p-my-1" *ngFor="let i of fieldValue">
              {{transform(i)}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </ng-container>

<p-toast></p-toast>
