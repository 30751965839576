import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { ThumbnailCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/thumbnail/thumbnail.component';

@Component({
  selector: 'app-seasonal-prizes-view-v2',
  templateUrl: './seasonal-prizes-view-v2.component.html',
  styleUrls: ['./seasonal-prizes-view-v2.component.sass'],
})
export class SeasonalPrizesViewV2Component extends BaseDynamicViewComponent implements OnInit {
  seasonalPrizesRecord: any = {};
  entity: string = 'seasonal-prizes';
  options: any = {};
  title: string = 'Seasonal Prizes';
  isLoading: boolean = false;
  parentViewName: string = 'seasonalPrizes'

  constructor(private agGridToolsService: AgGridToolsService) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.fields = [
      {
        title: 'Seasonal Prizes Data',
        type: DynamicCardType.Card,
        fields: [
          {
            name: 'Display Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'name',
            label: 'Display Name',
            isRequired: true,
            columnWidth: 6,
          },
          {
            name: 'Internal Name',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'internalName',
            label: 'Internal Name',
            isRequired: true,
            columnWidth: 6,
          },
          {
            name: 'Start Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            key: 'start',
            label: 'Start Date/Time',
            columnWidth: 6,
          },
          {
            name: 'End Date/Time',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Calendar],
            key: 'end',
            label: 'End Date/Time',
            columnWidth: 6,
          },
          {
            name: 'Internal Notes',
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.RichText],
            key: 'copy',
            label: 'Internal Notes',
            columnWidth: 12,
          },
        ],
      },
      {
        title: 'Imagery Data',
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Pop-up Banner",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'popupBanner_ref',
            label: 'Pop-up Banner',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'popupBanner_ref'},
            columnWidth: 6
          },
          {
            name: "Popup Icon",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'popupIcon_ref',
            label: 'Popup Icon',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'popupIcon_ref'},
            columnWidth: 6
          },
          {
            name: "Slide Down Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'slideDownImage_ref',
            label: 'Slide Down Image',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'slideDownImage_ref'},
            columnWidth: 6
          },
          {
            name: "Bundle 1(color)",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'bundle1_ref',
            label: 'Bundle 1(color)',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'bundle1_ref'},
            columnWidth: 6
          },
          {
            name: "Bundle 2(color)",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'bundle2_ref',
            label: 'Bundle 2(color)',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'bundle2_ref'},
            columnWidth: 6
          },
          {
            name: "Bundle 3(color)",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'bundle3_ref',
            label: 'Bundle 3(color)',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'bundle3_ref'},
            columnWidth: 6
          },
        ],
      },
      {
        title: 'Rewards',
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Rewards Track",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'rewardsTrack_ref',
            label: 'Rewards Track',
            controller: 'rewards-track',
            columnWidth: 6
          },
        ],
      },
      {
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Inbox Messages",
            inputType: DynamicViewType.AgGridTable,
            key: 'inboxes_ref',
            label: 'Inbox Messages',
            options: {
              fieldName: '',
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  sortable: true,
                  filter: 'agNumberColumnFilter',
                  resizable: true,
                  checkboxSelection: true,
                  headerCheckboxSelection: true,
                  width: 125,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  filter: 'agTextColumnFilter',
                  resizable: true,
                  cellEditor: 'agTextCellEditor',
                  cellRendererParams: {
                    entity: 'inbox-messages',
                  }
                },
                {
                  headerName: 'Enabled',
                  field: 'enabled',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    onLabel: 'Enabled',
                    offLabel: 'Not Enabled',
                    isBoolean: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: [true, false],
                    valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                  },
                  floatingFilter: true,

                  cellEditor: BooleanEditorComponent,
                  type: 'editableColumn',
                },
                {
                  headerName: 'Environment(s)',
                  field: 'env',
                  cellRendererParams: {
                    entity: 'challenges',
                    isEnv: true,
                  },
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['dev', 'qa', 'prod'],
                    valueFormatter: (params: any) => {
                      return params.value ? params.value.join(', ') : '';
                    }
                  },
                  floatingFilter: true,
                  cellEditor: MultiselectEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: true,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    valueObjects: true,
                    isFlatList: true,
                  },
                },
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'Misc. Image Record',
                  field: 'image_ref',
                  cellRenderer: MiscAssetCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agTextColumnFilter',
                  floatingFilter: true,
                  filterParams: {
                    filterKey: 'image_ref',
                    isAggregate: true,
                    fieldKeys: ['name', 'id', 'path' ],
                    isMiscBuild: true,
                    populate: true,
                    select: '_id id name path lastHash image_versions thumbnails buildOutput entityType assetType'
                  },
                  width: 500,
                  autoHeight: true,
                  cellEditor: DropdownEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: false,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    objectValue: true
                  },
                },
                {
                  headerName: 'Thumbnail',
                  field: 'image_ref',
                  sortable: false,
                  resizable: true,
                  width: 135,
                  autoHeight: true,
                  cellRenderer: ThumbnailCellRendererComponent,
                  cellRendererParams: {
                    tableIdentifier: `${this.parentViewName}-inbox-thumbnails`,
                  },
                  valueGetter: (params: any) => {
                    if (params.data.image_ref?.thumbnails) {
                      const thumbnail = params.data.image_ref.thumbnails.find((t: any) => t.size === 128);
                      return thumbnail?.path || 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                    }
                    return params.data.image_ref?.thumbnail_url_128 || 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                  },
                },               
                {
                  headerName: 'Reward(s)',
                  field: 'rewards_ref',
                  cellRenderer: LineItemCellRendererComponent,
                  cellRendererParams: {
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agMultiColumnFilter',
                  filterParams: {
                    filters: [
                      {
                        filter: 'agSetColumnFilter',
                        display: 'accordion',
                        title: 'Currency',
                        filterParams: {
                          buttons: ['clear'],
                          title: 'Currency',
                          values: this.options.currencies_ref,
                          valueFormatter: (params: any) => {
                            return `${params.value.name}`;
                          },
                          keyCreator: (params: any) => {
                            return params.value._id;
                          }
                        },
                      },
                      {
                        filter: 'agNumberColumnFilter',
                        display: 'accordion',
                        title: 'Cost',
                        filterParams: {
                          buttons: ['clear'],
                        }
                      },
                    ],
                    populate: true,
                    nestedPop: true,
                    select: {
                      path: 'id t',
                      select: '_id id name'
                    }
                  },
                  floatingFilter: true,

                  width: 800,
                  autoHeight: true,
                  type: 'editableColumn',
                  cellEditor: LineItemEditorComponent,
                  cellEditorPopup: true,
                  cellEditorParams: {
                    resources: this.options.resources_ref,
                    currencies: this.options.currencies_ref,
                    titles: this.options.titles_ref,
                  },
                },
              ],
              defaultQueryKey: 'inboxes_ref',
              entity: 'inbox-messages',
              customHeight: '30vh',
              title: 'Inbox Messages',
            },
            columnWidth: 12,
          },
        ]
      },
      {
        type: DynamicCardType.Card,
        fields: [
          {
            name: "Collections",
            inputType: DynamicViewType.AgGridTable,
            key: 'collection_ref',
            label: 'Collections',
            options: {
              fieldName: '',
              columnDefs: [
                {
                  headerName: 'ID',
                  field: 'id',
                  sortable: true,
                  filter: 'agNumberColumnFilter',
                  resizable: true,
                  checkboxSelection: true,
                  headerCheckboxSelection: true,
                  width: 125,
                },
                {
                  headerName: 'Name',
                  field: 'name',
                  cellRenderer: LinkCellRendererComponent,
                  sortable: true,
                  filter: 'agTextColumnFilter',
                  resizable: true,
                  cellEditor: 'agTextCellEditor',
                  cellRendererParams: {
                    entity: 'items',
                  },
                },
                {
                  headerName: 'Enabled',
                  field: 'enabled',
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    onLabel: 'Enabled',
                    offLabel: 'Not Enabled',
                    isBoolean: true
                  },
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: [true, false],
                    valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
                  },
                  floatingFilter: true,

                  cellEditor: BooleanEditorComponent,
                  type: 'editableColumn',
                },
                {
                  headerName: 'Environment(s)',
                  field: 'env',
                  cellRendererParams: {
                    entity: 'challenges',
                    isEnv: true,
                  },
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['dev', 'qa', 'prod'],
                    valueFormatter: (params: any) => {
                      return params.value ? params.value.join(', ') : '';
                    }
                  },
                  floatingFilter: true,
                  cellEditor: MultiselectEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: true,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    valueObjects: true,
                    isFlatList: true,
                  },
                },
                {
                  headerName: 'Start',
                  field: 'start',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'End',
                  field: 'end',
                  sortable: true,
                  unSortIcon: true,
                  filter: 'agDateColumnFilter',
                  resizable: true,
                  floatingFilter: true,
                  cellRenderer: TextCellRendererComponent,
                  cellRendererParams: {
                    isDate: true,
                  },
                  cellEditor: CalendarEditorComponent,
                },
                {
                  headerName: 'Environment(s)',
                  field: 'env',
                  cellRendererParams: {
                    entity: 'challenges',
                    isEnv: true,
                  },
                  cellRenderer: TextCellRendererComponent,
                  sortable: true,
                  unSortIcon: true,
                  resizable: true,
                  filter: 'agSetColumnFilter',
                  filterParams: {
                    values: ['dev', 'qa', 'prod'],
                    valueFormatter: (params: any) => {
                      return params.value ? params.value.join(', ') : '';
                    }
                  },
                  floatingFilter: true,
                  cellEditor: MultiselectEditorComponent,
                  type: 'editableColumn',
                  cellEditorPopup: true,
                  cellEditorParams: {
                    optionValue: "_id",
                    optionLabel: "name",
                    valueObjects: true,
                    isFlatList: true,
                  },
                },
                {
                  headerName: 'Thumbnail',
                  field: 'thumbnail',
                  sortable: false,
                  resizable: true,
                  width: 135,
                  autoHeight: true,
                  cellRenderer: ThumbnailCellRendererComponent,
                  valueGetter: (params: any) => {
                    if (params.data.thumbnail_url_1024) {
                      return params.data.thumbnail_url_1024;
                    }
                    if (params.data.thumbnail_url_256) {
                      return params.data.thumbnail_url_256;
                    }
                    if (params.data.thumbnail_url) {
                      return params.data.thumbnail_url.replace('_32', '_1024'); // Asegúrate de usar la mejor calidad disponible.
                    }
                    if (params.data.thumbnails) {
                      const thumbnail1024 = params.data.thumbnails.find(
                        (t: any) => t.size === 1024
                      );
                      if (thumbnail1024?.path) {
                        return thumbnail1024.path;
                      }
                    }
                    return 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';
                  },
                }                                                           
              ],
              defaultQueryKey: 'itemsFromCollections',
              entity: 'items',
              customHeight: '30vh',
              title: 'Collections',
            },
            columnWidth: 12,
          },
        ]
      },
    ];
    this.sidebarFields = [
      {
        title: "Images Preview",
        fields:
        [
          { name: "Pop-up Banner",
            inputType: DynamicViewType.sideThumbnail,
            key: 'popupBanner_ref',
            label: 'Pop-up Banner',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "Popup Icon",
            inputType: DynamicViewType.sideThumbnail,
            key: 'popupIcon_ref',
            label: 'Popup Icon',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "Slide Down Image",
            inputType: DynamicViewType.sideThumbnail,
            key: 'slideDownImage_ref',
            label: 'Slide Down Image',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "Bundle 1(color)",
            inputType: DynamicViewType.sideThumbnail,
            key: 'bundle1_ref',
            label: 'Bundle 1(color)',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "Bundle 2(color)",
            inputType: DynamicViewType.sideThumbnail,
            key: 'bundle2_ref',
            label: 'Bundle 2(color)',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
          { name: "Bundle 3(color)",
            inputType: DynamicViewType.sideThumbnail,
            key: 'bundle3_ref',
            label: 'Bundle 3(color)',
            options: {gridWidth: 6, fieldName: '' },
            columnWidth: 12
          },
        ],
        type: DynamicCardType.Card
      },
    ] 
    this.seasonalPrizesRecord = {
      enabled: false,
    };

    this.title = 'Seasonal Prizes';
    this.viewRoute = 'Seasonal-Prizes';
    this.isLoading = false;
  }
}
