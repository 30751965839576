import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';

export default class CategoryIconDTO extends BaseEntityDto {
  /**
   * Icons
   */
  icons: any = [
    {
      selected: null,
      deselected: null,
      disabled: null,
    },
  ];
}
