<dynamic-table
  *ngIf="!isLoading"
  [tableName]="this.entity.name + ' || ' + this.entity.id 
  + ' || ' + (this.entity.enabled ? 'Enabled' : 'Disabled') 
  + ' || ' + (this.entity.tags ? this.entity.tags.toString() : '')
  + (this.entity.items ? 'Total Items: ' + this.entity.items.length : 'Total Items:' + '0')"
  [tableType]="'items'"
  [tableView]="true"
  [tableViewType]="'collections'"
  [tableViewEntity]="entity"
  [tableConfig]="tableConfig"
  [fields]="fields"
  [defaultColumnOrder]="defaultColumnOrder"
  [globalFilters]="globalFilters"
  [customGlobalColumnSets]="customGlobalColumnSets"
  [customQuery]="customQuery"
  [tableTitle]="'Collection ' + this.entity.id"
  [isArchived]="false"
  class="p-mt-5"
  [buildType]="buildTypes.Items"
  [assetType]="assetTypes.Item"
>
</dynamic-table>