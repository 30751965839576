<p-button label="Generate Set" icon="fa fa-robot" class="p-button-success" (onClick)="openModal()"></p-button>
<p-toast></p-toast>

<p-dialog [(visible)]="isModalOpen" modal="true" [draggable]="true" [resizable]="true"
    [closable]="true" [breakpoints]="{ '960px': '75vw', '640px': '90vw' }" [style]="{ 'width': '50vw' }">
  <ng-template pTemplate="header">
    <div style="display: flex; align-items: center; margin-top: 0;">
      <h3 style="margin-top: 0;">Item Set Generator
        <i class="pi pi-info-circle" 
           pTooltip="This tool uses a machine learning model to generate item sets based on selected attributes. The text preprocessing includes normalization, stemming, and stop words removal. The model calculates a similarity score for each item, selects the top items based on the score, and ensures only one item per category is included in the final set."
           tooltipPosition="right"
           style="max-width: 600px; white-space: normal;">
        </i>
      </h3>
    </div>
  </ng-template>

  <form>
    <div class="p-field">
      <label for="name">
        Name
        <i class="pi pi-info-circle" pTooltip="First word or name of the item you want to base your suggestion on." tooltipPosition="right" style="max-width: 400px; white-space: normal;"></i>
      </label>
      <input type="text" id="name" name="name" [(ngModel)]="name" pInputText />
    </div>

    <div class="p-field">
      <label for="colors">
        Colors
        <i class="pi pi-info-circle" pTooltip="Select the colors you want to include." tooltipPosition="right" style="max-width: 400px; white-space: normal;"></i>
      </label>
      <p-multiSelect 
        [(ngModel)]="selectedColors"
        [options]="colors" 
        optionLabel="label" 
        optionValue="value"
        display="chip"
        [style]="{ width: '100%' }"
        id="colors"
        name="colors"
        (ngModelChange)="onSelectChange('selectedColors')"
        (onChange)="markAsTouched('selectedColors')"
        (onClear)="markAsTouched('selectedColors')">
      </p-multiSelect>
      <p-button *ngIf="selectedColors.length" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearSelection('selectedColors')"></p-button>
    </div>

    <div class="p-field">
      <label for="patterns">
        Patterns
        <i class="pi pi-info-circle" pTooltip="Select the patterns you want to include." tooltipPosition="right" style="max-width: 400px; white-space: normal;"></i>
      </label>
      <p-multiSelect 
        [(ngModel)]="selectedPatterns"
        [options]="patterns" 
        optionLabel="label" 
        optionValue="value"
        display="chip"
        [style]="{ width: '100%' }"
        id="patterns"
        name="patterns"
        (ngModelChange)="onSelectChange('selectedPatterns')"
        (onChange)="markAsTouched('selectedPatterns')"
        (onClear)="markAsTouched('selectedPatterns')">
      </p-multiSelect>
      <p-button *ngIf="selectedPatterns.length" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearSelection('selectedPatterns')"></p-button>
    </div>

    <div class="p-field">
      <label for="materials">
        Materials
        <i class="pi pi-info-circle" pTooltip="Select the materials you want to include." tooltipPosition="right" style="max-width: 400px; white-space: normal;"></i>
      </label>
      <p-multiSelect 
        [(ngModel)]="selectedMaterials"
        [options]="materials" 
        optionLabel="label" 
        optionValue="value"
        display="chip"
        [style]="{ width: '100%' }"
        id="materials"
        name="materials"
        (ngModelChange)="onSelectChange('selectedMaterials')"
        (onChange)="markAsTouched('selectedMaterials')"
        (onClear)="markAsTouched('selectedMaterials')">
      </p-multiSelect>
      <p-button *ngIf="selectedMaterials.length" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearSelection('selectedMaterials')"></p-button>
    </div>

    <div class="p-field">
      <label for="styles">
        Styles
        <i class="pi pi-info-circle" pTooltip="Select the styles you want to include." tooltipPosition="right" style="max-width: 400px; white-space: normal;"></i>
      </label>
      <p-multiSelect 
        [(ngModel)]="selectedStyles"
        [options]="styles" 
        optionLabel="label" 
        optionValue="value"
        display="chip"
        [style]="{ width: '100%' }"
        id="styles"
        name="styles"
        (ngModelChange)="onSelectChange('selectedStyles')"
        (onChange)="markAsTouched('selectedStyles')"
        (onClear)="markAsTouched('selectedStyles')">
      </p-multiSelect>
      <p-button *ngIf="selectedStyles.length" ariaLabel="Clear Field" icon="pi pi-minus"
                styleClass="p-button-text p-button-danger" (click)="clearSelection('selectedStyles')"></p-button>
    </div>
    
    <h3>
      Asset Type
      <i class="pi pi-info-circle" pTooltip="Select the type(s) of assets to include in the set." tooltipPosition="right" style="max-width: 400px; white-space: normal;"></i>
    </h3>
    <div class="p-field-checkbox">
      <p-checkbox [(ngModel)]="assetTypes.hsRecolor" binary="true" inputId="hs-recolor" name="assetType"></p-checkbox>
      <label for="hs-recolor">HS Recolor</label>
    </div>
    <div class="p-field-checkbox">
      <p-checkbox [(ngModel)]="assetTypes.hardSurface" binary="true" inputId="hard-surface" name="assetType"></p-checkbox>
      <label for="hard-surface">Hard Surface</label>
    </div>
    <div class="p-field-checkbox">
      <p-checkbox [(ngModel)]="assetTypes.organics" binary="true" inputId="organics" name="assetType"></p-checkbox>
      <label for="organics">Organics</label>
    </div>
    <div class="p-field-checkbox">
      <p-checkbox [(ngModel)]="assetTypes.organicRecolors" binary="true" inputId="organic-recolors" name="assetType"></p-checkbox>
      <label for="organic-recolors">Organic Recolors</label>
    </div>

    <div class="p-field">
      <label for="outputCount">
        Output Count
        <i class="pi pi-info-circle" pTooltip="Number of items to be generated in the output." tooltipPosition="right" style="max-width: 400px; white-space: normal;"></i>
      </label>
      <input type="number" id="outputCount" name="outputCount" [(ngModel)]="outputCount" pInputText min="1" />
    </div>
  
    <div class="button-container">
      <button type="button" class="generate-set" (click)="generate()">Generate Set</button>
    </div>
  </form>
</p-dialog>