<div class="schedule-sidebar" [class.open]="isOpen">
    <div class="sidebar-content">
        <!-- Add hide button -->
        <button pButton 
                icon="pi pi-angle-left" 
                class="p-button-text p-button-sm hide-sidebar-button"
                (click)="toggleSidebar()"
                pTooltip="Hide Sidebar"
                tooltipPosition="right">
        </button>

        <!-- Search Section -->
        <div class="search-section">
            <span class="p-input-icon-left" style="width: 100%;">
                <i class="pi pi-search"></i>
                <input type="text" pInputText [(ngModel)]="searchQuery" 
                       (input)="onSearch($event)" 
                       placeholder="Search entities..."
                       class="p-inputtext-sm"
                       style="width: 100%; font-size: 0.875rem; padding: 0.25rem 0.5rem;">
            </span>
        </div>

        <!-- Search Results -->
        <div class="search-results" *ngIf="searchResults.GroupedEntities.length > 0">
            <p-scrollPanel [style]="{width: '100%', height: '200px'}">
                <ng-container *ngFor="let group of searchResults.GroupedEntities">
                    <div class="group-label">{{group.label}}</div>
                    <div *ngFor="let item of group.items" 
                         class="search-result-item"
                         (click)="addToCart(item)">
                        <span class="result-title">{{item.name}} ({{item.id}})</span>
                        <button pButton icon="pi pi-plus" 
                                class="p-button-text p-button-sm">
                        </button>
                    </div>
                </ng-container>
            </p-scrollPanel>
        </div>

        <!-- Cart Section -->
        <div class="cart-section">
            <div class="cart-header">
                <div class="header-content">
                    <h3>Selected Records</h3>
                    <button pButton 
                            label="Clear" 
                            icon="pi pi-trash" 
                            class="p-button-text p-button-sm p-button-danger"
                            (click)="clearCart()"
                            *ngIf="cartItems.length > 0">
                    </button>
                </div>
            </div>
            <p-scrollPanel [style]="{width: '100%', height: '300px'}">
                <div *ngFor="let item of cartItems; let i = index" class="cart-item">
                    <span class="item-title">{{item.name}} ({{item.id}})</span>
                    <button pButton icon="pi pi-times" 
                            class="p-button-text p-button-sm p-button-danger"
                            (click)="removeFromCart(i)">
                    </button>
                </div>
            </p-scrollPanel>
        </div>

        <!-- Generate Event Button -->
        <div class="generate-section">
            <button pButton label="Generate Event" 
                    icon="pi pi-calendar-plus"
                    [disabled]="cartItems.length === 0"
                    (click)="onGenerateEvent()"
                    class="p-button-success p-button-sm"
                    style="width: 100%;">
            </button>
        </div>
    </div>
</div>

<!-- Add this right after the sidebar div -->
<div class="sidebar-overlay" *ngIf="isOpen" (click)="toggleSidebar()"></div>

<!-- Update the float button -->
<button *ngIf="!isOpen"
        pButton
        icon="pi pi-angle-right"
        class="sidebar-float-button"
        (click)="toggleSidebar()">
</button> 