import { Injectable } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';

import { FieldBase } from '../dynamic-field/field-base';

@Injectable({
  providedIn: 'root',
})
export class FieldControlService {
  constructor(private fb: UntypedFormBuilder) {}

  toFormGroup(fields: FieldBase<string>[]) {
    let group: any = {};

    fields.forEach((f) => {
      group[f.key] =
        f.controlType === 'formArray'
          ? this.fb.array([])
          : f.isRequired
          ? new UntypedFormControl(f.value || null, Validators.required)
          : new UntypedFormControl(f.value || null);
    });

    return new UntypedFormGroup(group);
  }

  toFormControl(f: FieldBase<string>) {
    let control =
      f.controlType === 'formArray'
        ? this.fb.array([])
        : f.isRequired
        ? new UntypedFormControl(f.value || null, Validators.required)
        : new UntypedFormControl(f.value || null);

    return control;
  }
}
