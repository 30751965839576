import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import SeedPacksDTO from '../dtos/SeedPacksDTO';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-seed-pack-form',
  templateUrl: './seed-pack-form.component.html',
  styleUrls: ['./seed-pack-form.component.sass']
})
export class SeedPackFormComponent extends BaseDynamicFormComponent implements OnInit {

  seedPacksRecord: SeedPacksDTO = new SeedPacksDTO();
  constructor(private validation: BaseInputValidationServiceService) {
    super();
  }


  /**
   * Seed Packs Forms V2 Component Initialization
  */

  ngOnInit(){

    this.fields =
    [
      {
        title: "Seed Packs Data",
        fields:
        [
          { name: "Seed Pack Name",
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Seed Pack Name',
            columnWidth: 6
          },
          { name: "Nurture Display Name",
          inputType: DynamicInputType.BaseInputField,
          inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'displayName',
            isRequired: true,
            label: 'Nurture Display Name',
            columnWidth: 6
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            isRequired: false,
            label: 'End Date/Time',
            calendarMinDate: this.seedPacksRecord.start,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(incomingValue && record.start){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            },
            columnWidth: 6
          },
          {
            name: "Opened Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'opened_image_ref',
            label: 'Opened Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            setMongoId: true,
            autoDisplayFirst: false,
            scrollHeight: '200px',
            options: { fieldName: 'opened_image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: { $in: [3, 4] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true },
          },
          {
            name: "Closed Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'closed_image_ref',
            label: 'Closed Image',
            optionValue:'_id',
            filter: true,
            setMongoId: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            scrollHeight: '200px',
            options: { fieldName: 'closed_image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: { $in: [3, 4] } }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id', isdisplayNameIDPath: true },
          },
          {
            name: "Nurture",
            inputType: DynamicInputType.BaseInputField,
            clearField: false,
            key: 'nurture_ref',
            inputTypeFields: [InputFieldType.TableInput],
            isRequired: true,
            label: 'Nurture',
            disabled: true,
            rowData: this.seedPacksRecord.nurture_ref,
            entity: 'nurture',
            isTableInput: true,
            tableInputParams: {
              select: 'id name thumbnail_ref'
            }
          },
        ],
        type: DynamicCardType.Card
      },
    ]
    this.title = "Seed Packs";
    this.viewRoute = 'seed-pack';
    this.isLoading = false;
  }


}
