export class cardConfig {

    cardConfig = {
        headerField: {
            id: 'thumbnail',
            label: 'Thumbnail'
        },
        bodyFields: [
            {
                id: 'name',
                label: 'Name',
                width: '9',
                textStyle: 'h3',
            },
            {
                id: 'id',
                label: 'ID',
                width: '2',
                textStyle: 'small-link',
            },
            {
                id: 'description',
                label: 'Description',
                width: '12',
                textStyle: 'p',
            },
        ]
    }
    
 }
 