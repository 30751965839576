import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { UserService } from "../service/user.service";
import UserDto from "../dtos/UserDto";

@Component({
  selector: 'app-role-view-dialog',
  templateUrl: './role-view-dialog.component.html',
  styleUrls: ['./role-view-dialog.component.sass'],
})
export class RoleViewDialogComponent implements OnInit {
  constructor(
    private userService: UserService,
    private messageService: MessageService,
  ) {}

  showRoleDetails: boolean = false;
  user: UserDto | null;

  async ngOnInit(): Promise<void> {
    this.userService.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        this.showRoleDetails = true;
        this.messageService.clear();
      }
    });
  }
}