import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-category-collection-view-v2',
  templateUrl: './category-collection-view-v2.component.html',
  styleUrls: ['./category-collection-view-v2.component.sass']
})
export class CategoryCollectionViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  categorycollectionRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * Category Collection View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "Category Collection",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'name', isRequired: true, label: 'Name' },
          {
            name: "Categories",
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.MultiSelectObj],
            clearField: true,
            key: 'category_ref',
            label: 'Categories',
            controller: 'categories',
            columnWidth: 6
          },
          { 
            name: "Filters", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.MultiSelect],
            clearField: true, 
            key: 'filters', 
            isRequired: true, 
            label: 'Filters',   
         },
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.categorycollectionRecord =
    {
      enabled: false
    };
    this.title = "Color";
    this.viewRoute = 'colors';
    this.isLoading = false;
  }
}