<ng-container
  *ngIf="!isHidden && (!field.filterControl || !field.filterControl.type)"
>
  <div
    #fieldContainer
    class="p-inputgroup p-fluid p-d-flex p-flex-row p-my-1"
    [style]="{ 'max-width': '24em' }"
  >
    <p-triStateCheckbox
      [(ngModel)]="checkedState"
      *ngIf="!isSubField && isTriStateCheckbox"
      class="p-mr-3 p-mt-2"
      checkboxTrueIcon="pi pi-times"
      checkboxFalseIcon="pi pi-check"
      (onChange)="onSelect()"
    >
    </p-triStateCheckbox>

    <p-checkbox
      [(ngModel)]="checkedState"
      *ngIf="!isSubField && !isTriStateCheckbox"
      class="p-mr-3 p-mt-2"
      [binary]="true"
      [falseValue]="null"
      (onChange)="onSelect()"
    ></p-checkbox>

    <ng-container [ngSwitch]="field.controlType">
      <!-- inputText, inputTextarea -->
      <span
        *ngSwitchCase="
          ['inputText', 'inputTextarea', 'richTextArea'].includes(field.controlType)
            ? field.controlType
            : ''
        "
        class="p-float-label"
      >
        <input
          [(ngModel)]="checkedState ? null : value"
          [ariaLabel]="field.name"
          [disabled]="checkedState"
          [id]="field.key"
          [name]="field.filterKey"
          pInputText
          (input)="onApply()"
        />
        <label [for]="field.key">
          {{
            checkedState == null || (!checkedState && value)
              ? field.name
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </span>

      <!-- inputNumber -->
      <span *ngSwitchCase="'inputNumber'" class="p-float-label">
        <input
          [(ngModel)]="checkedState ? null : value"
          [ariaLabel]="field.name"
          [disabled]="checkedState"
          [id]="field.key"
          [name]="field.filterKey"
          pInputText
          pTooltip="Use '-' to filter range."
          tooltipPosition="left"
          showDelay="1500"
          (input)="onApply()"
        />
        <label [for]="field.key">
          {{
            checkedState == null || (!checkedState && value)
              ? field.name + " Range"
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </span>

      <!-- dropdown, multiSelect, buildStatus -->
      <span
        *ngSwitchCase="
          ['dropdown', 'multiSelect', 'buildStatus'].includes(field.controlType)
            ? field.controlType
            : ''
        "
        class="p-float-label"
      >
        <p-dropdown
          *ngIf="isSubField"
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [options]="options"
          [filter]="true"
          [autoDisplayFirst]="false"
          [style]="{ width: '100%' }"
          scrollHeight="350px"
          (onChange)="onApply()"
        ></p-dropdown>

        <p-multiSelect
          *ngIf="!isSubField && field.key !== 'env'"
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [options]="options"
          [autoDisplayFirst]="false"
          [filter]="true"
          display="chip"
          [style]="{ width: '100%' }"
          scrollHeight="350px"
          (onChange)="onApply()"
        ></p-multiSelect>

        <p-multiSelect
          *ngIf="!isSubField && field.key == 'env'"
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [options]="options"
          [autoDisplayFirst]="false"
          [filter]="true"
          display="chip"
          [style]="{ width: '100%' }"
          scrollHeight="350px"
          (onChange)="onApply()"
        >
        <ng-template let-value pTemplate="selectedItems">
            <div class="flex align-items-center gap-2" *ngFor="let val of value">
              <p-tag *ngIf="EnvValues[val]" [rounded]="true" class="p-mr-1" [severity]="EnvValues[val].exclude ? 'danger':'success' ">{{val}}</p-tag>
              <p-tag *ngIf="val == '[Empty]'" [rounded]="true" class="p-mr-1" severity="info">{{val}}</p-tag>
            </div>
        </ng-template>
          <ng-template let-option pTemplate="item">
              <div class="flex align-items-center gap-2" [style]="{ width: '100%' }">
                  {{option}}
                  <span *ngIf="option !== '[Empty]'; else isEmptyOption" [style]="{ float: 'right' }" >
                    <p-checkbox (onChange)="envExclusionChange($event);" (click)="envStopPropagation(value, option, $event);" [(ngModel)]="EnvValues[option].exclude" [binary]="true" inputId="binary"></p-checkbox>
                  </span>
                  <ng-template #isEmptyOption>
                    <span [style]="{ float: 'right' }">
                      <i class="pi pi-filter-slash" [style]="{ marginRight: '0.5rem' }"></i>
                    </span>
                  </ng-template>

              </div>
          </ng-template>
      </p-multiSelect>

        <label [for]="field.key">
          {{
            checkedState == null || hasValue
              ? field.name
              : field.name +
                (["buildStatus"].includes(field.controlType)
                  ? " — [No Build Data]"
                  : " — [Empty]")
          }}
        </label>
      </span>

      <!-- dropdown_ref, multiSelect_ref -->
      <span
        *ngSwitchCase="
          ['dropdown_ref', 'multiSelect_ref'].includes(field.controlType)
            ? field.controlType
            : ''
        "
        class="p-float-label"
      >
        <p-dropdown
          *ngIf="isSubField"
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [options]="options"
          optionLabel="name"
          [autoDisplayFirst]="false"
          [filter]="true"
          [filterBy]="'name'"
          [style]="{ width: '100%' }"
          scrollHeight="350px"
          (onChange)="onApply()"
        ></p-dropdown>

        <p-multiSelect
          *ngIf="!isSubField"
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [options]="options"
          optionLabel="name"
          [autoDisplayFirst]="false"
          [filter]="true"
          [filterBy]="'name,id'"
          display="chip"
          [style]="{ width: '100%' }"
          scrollHeight="350px"
          (onChange)="onApply()"
        ></p-multiSelect>
        <label [for]="field.key">
          {{
            checkedState == null || hasValue
              ? field.name
              : field.name + " — [Empty]"
          }}
        </label>
      </span>

      <!-- autoComplete_ref, autoComplete-multi_ref -->
      <span
        *ngSwitchCase="
          ['autoComplete_ref', 'autoComplete-multi_ref'].includes(
            field.controlType
          )
            ? field.controlType
            : ''
        "
        class="p-float-label"
      >
        <p-autoComplete
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [suggestions]="suggestions"
          (completeMethod)="setSuggestions($event.query)"
          field="name"
          minLength="3"
          [showEmptyMessage]="true"
          [forceSelection]="true"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [multiple]="!isSubField"
          (onSelect)="onApply()"
          (onUnselect)="onApply()"
        >
          <ng-template pTemplate="item" let-item>
            {{ item.name }} ({{ item.id }})
          </ng-template>

          <ng-template pTemplate="selectedItem" let-item>
            {{ item.name }} ({{ item.id }})
          </ng-template>
        </p-autoComplete>
        <label [for]="field.key">
          {{
            checkedState == null || hasValue
              ? field.name
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </span>

      <!-- date -->
      <div *ngSwitchCase="'date'" class="p-float-label">
        <p-calendar
          [(ngModel)]="checkedState ? null : value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [placeholder]="
            ['table'].includes(page) ? 'Select Date/Time: ' + field.name : ''
          "
          [showTime]="false"
          [maxDate]="['start'].includes(field.key) ? conflictValue : ''"
          [minDate]="['end'].includes(field.key) ? conflictValue : ''"
          [yearNavigator]="true"
          yearRange="2020:2030"
          [monthNavigator]="true"
          [readonlyInput]="true"
          [style]="{ width: '100%' }"
          selectionMode="range"
          [selectOtherMonths]="true"
          (onSelect)="onApply()"
          (onFocus)="field.key == 'masterBuildDate' ? onApply() : null"
          [touchUI]="true"
          appendTo="body"
          id="filter-calendar"
        >
          <!-- To show title on the calendar input for the tables -->
          <ng-template pTemplate="header">
            <div class="p-d-flex p-jc-center">
              <h3>
                {{
                  field.key == "start"
                    ? "Start Date"
                    : field.key == "end"
                    ? "End Date"
                    : field.key == "masterBuildDate"
                    ? "Master Build Date Range"
                    : null
                }}
              </h3>
            </div>
          </ng-template>
        </p-calendar>
        <label [for]="field.key">
          {{
            checkedState == null || (!checkedState && value)
              ? field.name + " Range"
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </div>

      <!-- toggle -->
      <span *ngSwitchCase="'toggle'" class="p-float-label">
        <p-multiSelect
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [options]="['[Empty]', 'Yes', 'No']"
          [autoDisplayFirst]="false"
          display="chip"
          [style]="{ width: '100%' }"
          scrollHeight="350px"
          (onChange)="onApply()"
        ></p-multiSelect>

        <label [for]="field.key">
          {{
            checkedState == null || hasValue
              ? field.name
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </span>

      <!-- ref-link, simple-link -->
      <span
        *ngSwitchCase="
          ['ref-link', 'simple-link'].includes(field.controlType)
            ? field.controlType
            : ''
        "
        class="p-float-label"
      >
        <input
          [value]=""
          [ariaLabel]="field.name"
          [id]="field.key"
          [disabled]="true"
          pInputText
        />
        <label [for]="field.key">
          {{
            checkedState == null || (!checkedState && value)
              ? field.name
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </span>

      <!-- nestedGroup -->
      <span
        *ngSwitchCase="
          ['nestedGroup', 'lineItem', 'formArray'].includes(field.controlType)
            ? field.controlType
            : ''
        "
      >
        <div class="p-inputgroup p-fluid p-d-flex p-flex-row p-ai-center">
          <p-button
            ariaLabel="Add Filter Item"
            icon="pi pi-plus"
            styleClass="p-button-text"
            (click)="onOpenNestedGroup($event, fieldContainer, valueCount)"
          ></p-button>

          <span class="p-float-label">
            <input
              value=""
              [ariaLabel]="field.name"
              [id]="field.key"
              [disabled]="true"
              pInputText
            />
            <label [for]="field.key">
              {{
                checkedState == null || (!checkedState && value)
                  ? field.name
                  : checkedState
                  ? field.name + " — [Empty]"
                  : field.name + " — [Has Value]"
              }}</label
            >
          </span>
        </div>

        <div
          *ngFor="let group of value; let index = index"
          class="p-fluid p-d-flex p-mt-2"
        >
          <p-chip
            [removable]="true"
            (onRemove)="onRemoveNestedGroup(index)"
            *ngIf="groupHasValue(group)"
          >
            <p-button
              ariaLabel="Add Filter Item"
              icon="pi pi-pencil"
              styleClass="p-button-text p-button-rounded p-button-sm p-p-0"
              (click)="onOpenNestedGroup($event, fieldContainer, index)"
            ></p-button>

            <ng-container *ngFor="let subField of field.subFields">
              <ng-container *ngFor="let prop of group | keyvalue">
                <ng-container
                  *ngIf="
                    prop.value.value &&
                    prop.key === subField.key &&
                    prop.value.subField.name === subField.name
                  "
                >
                  {{ prop.value.subField.name }}:
                  {{
                    getNestedChipValue(
                      prop.value.value,
                      prop.value.subField.controlType
                    )
                  }}
                  <br />
                </ng-container>
              </ng-container>
            </ng-container>
          </p-chip>
        </div>
      </span>
    </ng-container>

    <div
      *ngIf="hasValue || checkedState == true || checkedState == false"
      class="p-d-flex p-ai-center"
    >
      <p-button
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        styleClass="p-button-text p-button-danger"
        (click)="onClear()"
      ></p-button>
    </div>
  </div>
</ng-container>

<!-- TEST NEW FILTER STRUCTURE -->
<ng-container
  *ngIf="!isHidden && field.filterControl && field.filterControl.type"
>
  <div
    #fieldContainer
    class="p-inputgroup p-fluid p-d-flex p-flex-row p-my-1"
    [style]="{ 'max-width': '24em' }"
  >
    <div *ngIf="!isSubField" class="p-d-flex p-ai-center">
      <p-triStateCheckbox
        [(ngModel)]="checkedState"
        *ngIf="isTriStateCheckbox"
        class="p-mr-3 p-mt-2"
        checkboxTrueIcon="pi pi-times"
        checkboxFalseIcon="pi pi-check"
        (onChange)="onSelect()"
      >
      </p-triStateCheckbox>

      <p-checkbox
        [(ngModel)]="checkedState"
        *ngIf="!isTriStateCheckbox"
        class="p-mr-3 p-mt-2"
        [binary]="true"
        [falseValue]="null"
        (onChange)="onSelect()"
      ></p-checkbox>
    </div>

    <ng-container [ngSwitch]="field.filterControl.type">
      <!-- text -->
      <span *ngSwitchCase="'text'" class="p-float-label">
        <input
          [(ngModel)]="checkedState ? null : value"
          [ariaLabel]="field.name"
          [disabled]="checkedState"
          [id]="field.key"
          [name]="field.key"
          pInputText
          (input)="onApply()"
        />
        <label [for]="field.key">
          {{
            checkedState == null || (!checkedState && value)
              ? field.name
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </span>

      <!-- selectObjects -->
      <span *ngSwitchCase="'selectObjects'" class="p-float-label">
        <p-multiSelect
          *ngIf="!isSubField"
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [options]="options"
          [optionLabel]="'name'"
          [autoDisplayFirst]="false"
          [filter]="true"
          [filterBy]="
            field.filterControl.keys && field.filterControl.keys.length > 0
              ? field.filterControl.keys.toString()
              : 'name,id'
          "
          display="chip"
          [style]="{ width: '100%' }"
          [dataKey]="'id'"
          scrollHeight="350px"
          (onChange)="onApply()"
        >
          <ng-template let-option pTemplate="item">
            <div
              class="option-label-container"
              [pTooltip]="
                field.viewControl && field.viewControl.props
                  ? option[field.viewControl.props[0].key]
                  : option.name
              "
              tooltipPosition="left"
              showDelay="1500"
            >
              <ng-container >
                <div class="option-label-caption">
                  <ng-container
                  *ngIf="
                    field.viewControl &&
                      field.viewControl.props &&
                      field.viewControl.props[0].caption;
                    else noCaption
                  "
                >
                  ({{ option[field.viewControl.props[0].caption] }}):
                </ng-container>

                <ng-container #noCaption *ngIf="option.id">
                  ({{ option.id }})
                </ng-container>
                </div>

              </ng-container>

              <ng-container >
                <div class="option-label-text">
                  {{
                    field.viewControl && field.viewControl.props
                      ? option[field.viewControl.props[0].key]
                      : option.name
                  }}
                </div>

              </ng-container>
            </div>
          </ng-template>
        </p-multiSelect>

        <label [for]="field.key">
          {{
            checkedState == null || hasValue
              ? field.name
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </span>

      <!-- selectStrings -->
      <span *ngSwitchCase="'selectStrings'" class="p-float-label">
        <p-multiSelect
          *ngIf="!isSubField"
          [(ngModel)]="value"
          [ariaLabel]="field.name"
          [id]="field.key"
          [options]="options"
          [autoDisplayFirst]="false"
          [filter]="true"
          display="chip"
          [style]="{ width: '100%' }"
          scrollHeight="350px"
          (onChange)="onApply()"
        >
        </p-multiSelect>

        <label [for]="field.key">
          {{
            checkedState == null || hasValue
              ? field.name
              : checkedState
              ? field.name + " — [Empty]"
              : field.name + " — [Has Value]"
          }}
        </label>
      </span>
    </ng-container>

    <!-- 'Clear Field' button -->
    <div
      *ngIf="value || checkedState == true || checkedState == false"
      class="p-d-flex p-ai-center"
    >
      <p-button
        ariaLabel="Clear Field"
        icon="pi pi-minus"
        styleClass="p-button-text p-button-danger"
        (click)="onClear()"
      ></p-button>
    </div>
  </div>
</ng-container>

<!-- 'Nested Filter' overlay -->
<p-overlayPanel
  #nestedFilter_overlay
  [dismissable]="true"
  [showCloseIcon]="true"
  [style]="{ width: '23em' }"
>
  <ng-template pTemplate="content">
    <div class="p-mb-2 p-d-flex p-ai-center p-jc-between">
      <h4 class="p-my-0">
        {{ field.name }} #{{ currentNestedGroup.index + 1 }}
      </h4>
      <h5 *ngIf="currentNestedGroup.lineItemData.type">
        Resource: {{ currentNestedGroup.lineItemData.type }}
      </h5>
    </div>

    <div
      *ngFor="let subField of field.subFields"
      class="p-d-flex p-flex-column"
    >
      <app-filter-field
        *ngIf="subField.isFilterField"
        #filterField
        [field]="subField"
        [(value)]="currentNestedGroup.values[subField.key].value"
        [options]="options[subField.key]"
        [ngStyle]="{ width: '100%' }"
        [lineItemData]="currentNestedGroup.lineItemData"
        [isSubField]="true"
        (apply)="onApplyNested()"
      ></app-filter-field>
    </div>

    <p-button
      label="Another"
      icon="pi pi-plus"
      styleClass="p-button-sm p-mx-auto p-mt-3 p-d-flex p-jc-end"
      (click)="onOpenNestedGroup($event, fieldContainer, valueCount)"
    ></p-button>
  </ng-template>
</p-overlayPanel>
