/**
 * User Settings DTO
 */
export default class UserSettings
{
    /**
     * Id of the user setting
     */
    id: any;
    /**
     * Columns sets tied to a user
     */
    columnSets: Array<any> = [];
    /**
     * Filter Sets tied to a user
     */
    filterSets: Array<any> = [];
}