import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { LoggerService } from '../../services/logger.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'file-uplaoder',
  templateUrl: './file-uplaoder.component.html',
  styleUrls: ['./file-uplaoder.component.sass']
})
export class FileUplaoderComponent implements OnInit 
{
  @Input() title: string = 'File Uploader';
  @Input() buttonLabel: string = 'Browse';
  @Input() autoUpload: boolean = false;
  @Input() multipleUploads: boolean = false;
  @Input() uploadURL: string = '';
  // File Extensions options: https://www.w3schools.com/tags/att_input_accept.asp
  @Input() acceptFileExtension: string = 'image/*';
  // Use basic for basic file uploading UI
  @Input() mode: string = 'advanced';
  @Input() showUploadButton: boolean = true;
  @Input() showCancelButton: boolean = true;
  @Input() maxFileSize: string = '8000000';
  @Input() entity: any;
  @Input() entityType: string;
  @Output() onBeforeFileUpload = new EventEmitter<any>();
  @Output() onFileSelected = new EventEmitter<any>();
  @Output() onFileUpload = new EventEmitter<any>();
  uploadedFiles: any[] = [];
  currentFile: any;

  constructor
  (
    private messageService: MessageService,
    private loggerService: LoggerService
  ) { }

  /**
   * File Uploader Component initialization
   */
  ngOnInit()
  {
  }

  /**
   * Callback to invoke before file upload is initialized.
   * 
   * @param event FormData object.
   */
  onBeforeUpload(event: any) 
  {
    let uuid = uuidv4();
    if(this.entity)
    {
      // add entity id to form data for upload.
      event.formData.append('entityId', this.entity.id);
    }
    // Add here all the properties and values ​​that you need to include in the payload when calling the API.
    event.formData.append('path', this.entityType.toLowerCase() + '/' + uuid + '.' + this.currentFile.name.split('.')[1]);
    this.onBeforeFileUpload.emit(event);
  }

  /**
   * Callback to invoke when files are selected.
   * 
   * @param event Selected files of the select event
   */
  onSelect(event: any) 
  {
    for(let file of event.currentFiles) 
    {
      this.currentFile = file;
      this.loggerService.log("File: ", this.currentFile);
    }
    this.onFileSelected.emit(event);
  }

  /**
   * Callback to invoke when file upload is complete.
   * 
   * @param event event.originalEvent: Http Event; event.files: Uploaded files.
   */
  onUpload(event: any) 
  {
    this.loggerService.log("Event on upload: ", event);
    for(let file of event.files) 
    {
      this.uploadedFiles.push(file);
    }

    this.onFileUpload.emit(event);
    
    this.messageService.add({ sticky: true, severity: 'info', summary: 'File Uploaded!', detail: ''});
  }
}