import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-inbox-message-view-v2',
  templateUrl: './inbox-message-view-v2.component.html',
  styleUrls: ['./inbox-message-view-v2.component.sass']
})
export class InboxMessageViewV2Component extends BaseDynamicViewComponent implements OnInit {
  InboxMessageRecord: BaseEntityDto = new BaseEntityDto();

  constructor() {
    super();
  }

  ngOnInit() {
    this.fields = [
      {
        title: "Production",
        fields: [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'name', label: 'Name' },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time', columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time', columnWidth: 6 },
          { name: "Type", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'type', label: 'Type', columnWidth: 6 },
          { name: "Layout", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'layout', label: 'Layout', columnWidth: 6 },
          { name: "Product ID", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'productId', label: 'Product ID', columnWidth: 6 },
          { name: "Time to Live",
          inputType: DynamicViewType.BaseViewField ,
          viewTypes: [BaseViewFieldType.DropdownWithLabels],
          clearField: true,
          key: 'timeToLive',
          label: 'Time to Live',
          isRequired: true,
         options:
         {
           fieldName: 'Time to Live',
           values:
           [
             {name: '1 hour', value: 1},
             {name: '2 hours', value: 2},
             {name: '3 hours', value: 3},
             {name: '4 hours', value: 4},
             {name: '5 hours', value: 5},
             {name: '6 hours', value: 6},
             {name: '7 hours', value: 7},
             {name: '8 hours', value: 8},
             {name: '9 hours', value: 9},
             {name: '10 hours', value: 10},
             {name: '11 hours', value: 11}, 
             {name: '12 hours', value: 12},
             {name: '13 hours', value: 13},
             {name: '14 hours', value: 14},
             {name: '15 hours', value: 15},
             {name: '16 hours', value: 16},
             {name: '17 hours', value: 17},
             {name: '18 hours', value: 18},
             {name: '19 hours', value: 19},
             {name: '20 hours', value: 20},
             {name: '21 hours', value: 21},
             {name: '22 hours', value: 22},
             {name: '23 hours', value: 23},
             {name: '1 days / 24 hours', value: 24},
             {name: '2 days / 48 hours', value: 48},
             {name: '3 days / 72 hours', value: 72},
             {name: '4 days / 96 hours', value: 96},
             {name: '5 days / 120 hours', value: 120},
             {name: '6 days / 144 hours', value: 144},
             {name: '7 days / 168 hours', value: 168},
             {name: '8 days / 192 hours', value: 192},
             {name: '9 days / 216 hours', value: 216},
             {name: '10 days / 240 hours', value: 240},
             {name: '11 days / 264 hours', value: 264},
             {name: '12 days / 288 hours', value: 288},
             {name: '13 days / 312 hours', value: 312},
             {name: '14 days / 336 hours', value: 336},
             {name: '15 days / 360 hours', value: 360},
             {name: '16 days / 384 hours', value: 384},
             {name: '17 days / 408 hours', value: 408},
             {name: '18 days / 432 hours', value: 432},
             {name: '19 days / 456 hours', value: 456},
             {name: '20 days / 480 hours', value: 480},
             {name: '21 days / 504 hours', value: 504},
           ]
         },columnWidth: 6 },
          { name: "Minimum User Level", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'minimumUserLevel', label: 'Minimum User Level', columnWidth: 6 },
          { name: "Use Segment", inputType: DynamicViewType.Boolean, clearField: true, key: 'useSegment', label: 'Use Segment', columnWidth: 6 },
          { name: "Buy CTA", inputType: DynamicViewType.Boolean, clearField: true, key: 'showBuyCta', label: 'Buy CTA', columnWidth: 12 },
          { name: "Entity Type", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'entity_ref', label: 'Entity Type', columnWidth: 6 },
          { name: "Entity Reference ID", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.Text], clearField: true, key: 'ref_id', label: 'Entity Reference ID', columnWidth: 6 },
          {
            name: "Rewards",
            inputType: DynamicViewType.Price,
            clearField: true,
            key: 'rewards_ref',
            label: 'Rewards',
            controller: 'daily-rewards',
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Content",
        fields: [
          { 
            name: "Headline",
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text], 
            clearField: true, 
            key: 'headline', 
            label: 'Headline' 
          },
          { 
            name: "Title", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text], 
            clearField: true, 
            key: 'title', 
            label: 'Title' 
          },
          { 
            name: "Body", 
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text], 
            clearField: true, 
            key: 'body', 
            label: 'Body' 
          },
          {
            name: "Misc. Image Record",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            key: 'image_ref',
            label: 'Misc. Image Record',
            optionValue: '_id',
            filter: true,
            filterBy: 'name,id,path',
            autoDisplayFirst: false,
            setMongoId: false,
            options: { fieldName: 'image_ref' }
          },
          {
            name: "Link Destination", 
            inputType: DynamicViewType.NestedList, 
            clearField: true, 
            key: 'link_ref', 
            label: 'Link Destination',
            options: { fieldName: 'Link Destination', keys: ['linkText', 'linkDestination'] }
          },
        ],
        type: DynamicCardType.Card
      }
    ];

    this.sidebarFields = [
      {
        title: "Imagery Build Status",
        type: DynamicCardType.Card, 
        fields: [
          {
            name: "Image Preview",
            inputType: DynamicViewType.BaseViewField , 
            viewTypes: [BaseViewFieldType.Text],
            key: '',
            label: 'Image Preview',
            columnWidth: 12,
            tagType: 'h1' 
          },
          {
            name: "Image Details",
            inputType: DynamicViewType.sideThumbnail,
            key: 'image_ref',
            label: 'Image Details', 
          },
          {
            name: "Inbox Preview",
            inputType: DynamicViewType.InGamePreview,
            key: '',
            label: 'Inbox Preview',
            subFields: [
              { 
                name: "Headline",
                inputType: DynamicViewType.BaseViewField , 
                viewTypes: [BaseViewFieldType.Text],  
                key: 'headline', 
                label: 'Headline' 
              },
              { 
                name: "Title", 
                inputType: DynamicViewType.BaseViewField , 
                viewTypes: [BaseViewFieldType.Text], 
                key: 'title', 
                label: 'Title' 
              },
              { 
                name: "Body", 
                inputType: DynamicViewType.BaseViewField , 
                viewTypes: [BaseViewFieldType.Text], 
                key: 'body', 
                label: 'Body' 
              },
            ] 
          },
        ]
      }
    ];

    this.InboxMessageRecord = {
      enabled: false // Asegúrate de que esta estructura coincida con la definición de BaseEntityDto
    };

    this.title = "Inbox Message";
    this.viewRoute = 'inboxmessage-v2';
    this.isLoading = false;
  }
}

