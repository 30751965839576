import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { Component, ViewChild, AfterViewInit, ElementRef, Renderer2, ChangeDetectorRef } from '@angular/core';
import { ICellEditorAngularComp } from 'ag-grid-angular';
import { Calendar } from 'primeng/calendar';
import { InterceptorService } from 'src/app/services/interceptor.service';

@Component({
  selector: 'app-calendar-cell-editor',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.css']
})
export class CalendarEditorComponent implements ICellEditorAngularComp, AfterViewInit {
  constructor(
    private renderer: Renderer2,
    private interceptorService: InterceptorService,
    private utilitiesService: UtilitiesService
  ) { }

  private params: any;
  public selectedDate: Date;
  public originalDate: Date;
  private prevDate: Date;
  public localDate: Date;


  @ViewChild(Calendar) private calendar: Calendar;

  checkTimeZone: boolean = false

  agInit(params: any): void {
    this.params = params;
    if(this.params.data[this.params.colDef.field]){
      const localLAString = new Date(this.params.data[this.params.colDef.field])
                              .toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
      this.selectedDate = new Date(localLAString);
      this.originalDate = new Date(localLAString);

      this.checkTimeZone = this.utilitiesService.isUserLocalTimeZonePST();
      this.localDate = this.convertToUserLocalTime(this.selectedDate, this.checkTimeZone);

      console.log('value: ', this.params.data[this.params.colDef.field]);
      console.log('selectedDate: ', this.selectedDate);
      console.log('originalDate: ', this.originalDate);
    }
}


  ngAfterViewInit(): void {

    setTimeout(() => {
      const popupEditor = document.querySelector('.ag-popup-editor');
      if (popupEditor) {
        this.renderer.setStyle(popupEditor, 'z-index', '9999');
      }
      if (this.calendar && this.calendar.inputfieldViewChild && this.calendar.inputfieldViewChild.nativeElement) {
        this.calendar.inputfieldViewChild.nativeElement.focus();
      }
      this.calendar.showOverlay();
    });
  }

  // DO NOT USE
  adjustToPacificTime(date: any) {
    return this.interceptorService.adjustToPacificTime(date, true)
  }



  getValue(): any {
    console.log('getValue: ', this.selectedDate);
    return this.adjustToPacificTime(this.selectedDate);
  }

  isPopup(): boolean {
    return true;
  }


  onDateSelect(event: any): void {
    if (this.prevDate) { // Check if prevDate is initialized
      if (this.selectedDate.getDate() !== this.prevDate.getDate() ||
          this.selectedDate.getMonth() !== this.prevDate.getMonth() ||
          this.selectedDate.getFullYear() !== this.prevDate.getFullYear()) {
            // Reset time to 00:00:00
            this.selectedDate.setHours(0, 0, 0, 0);
      }
    }
    
    this.localDate = this.convertToUserLocalTime(this.selectedDate, this.checkTimeZone);

    this.prevDate = new Date(this.selectedDate); // Update the previous date
}

// Helper function to determine if a given date is in PDT or PST
isPDT(date: Date) {
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return date.getTimezoneOffset() === Math.min(jan, jul);
}

// Helper function to convert a given date from Pacific Time (PDT/PST) to the user's local time
convertToUserLocalTime(date: Date, checkTimeZone: boolean) {
  if (!checkTimeZone) {
      // Set Pacific Time offset based on whether it's PDT or PST
      const pacificOffset = this.isPDT(date) ? 7 * 60 : 8 * 60; // PDT = UTC-7, PST = UTC-8
      const userOffset = date.getTimezoneOffset(); // User's timezone offset in minutes for the selected date
      
      // Calculate the time difference
      const timeDifference = pacificOffset - userOffset;

      // Create a new Date object adjusted by the time difference
      const localDate = new Date(date);
      localDate.setMinutes(localDate.getMinutes() - timeDifference);

      return localDate;
  }
  return date; // Return the original date if checkTimeZone is true
}


  getLocalTimeZone(): boolean {
    return this.utilitiesService.isUserLocalTimeZonePST();
  }

  setOriginalValue(){
    this.selectedDate = this.originalDate;
  }
}
