import { Component, OnInit } from '@angular/core';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-store-transfer-v2-view',
  templateUrl: './store-transfer-v2-view.component.html',
  styleUrls: ['./store-transfer-v2-view.component.sass']
})
export class StoreTransferV2ViewComponent extends BaseDynamicViewComponent implements OnInit  {
  recordId: number;
  handleIdChange(newId: number) {
    this.recordId = newId; 
  }
  StoreTransferRecord: BaseEntityDto = new BaseEntityDto();

  constructor(
    private route: ActivatedRoute,
  ) 
  {
  super();
  } 
  ngOnInit() {
    this.recordId = Number(this.route.snapshot.paramMap.get('id'));
    if (!this.recordId || this.recordId <= 0) {
      console.error('Invalid recordId');
      return;
    }
        this.fields = 
        [
          {
        title: "Store Transfer Details",
        fields: [
          {
            name: "Entity",
            inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],
            key: 'meta.entity',
            label: 'Entity',
            columnWidth: 6,
          },
          { name: "Triggered At", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'triggeredAt', label: 'Triggered At', columnWidth: 6 },
          { 
            name: "Triggered By", 
            inputType: DynamicViewType.BaseViewField, 
            viewTypes: [BaseViewFieldType.Text],
            clearField: true, 
            key: 'meta.user.name', 
            isRequired: true, 
            label: 'Triggered By',  
            columnWidth: 6,
          },      
          { 
            name: "Source", 
            inputType: DynamicViewType.BaseViewField, 
            viewTypes: [BaseViewFieldType.Text],
            clearField: true, 
            key: 'meta.source', 
            isRequired: true, 
            label: 'Source',
            columnWidth: 6,  
          },       
          { 
            name: "Destination", 
            inputType: DynamicViewType.BaseViewField, 
            viewTypes: [BaseViewFieldType.Text],
            clearField: true, 
            key: 'meta.destination', 
            isRequired: true, 
            label: 'Destination',  
            columnWidth: 6,
          },   
          { 
            name: "recordsDiff", 
            inputType: DynamicViewType.BaseViewField, 
            viewTypes: [BaseViewFieldType.NestedList],
            clearField: true, 
            key: 'meta.recordsDiff', 
            isRequired: true, 
            label: 'recordsDiff',  
          },           
       ],
       type: DynamicCardType.Card,
      },
    ]
    this.title = "Store-transfer";
    this.viewRoute = 'store-transfer-v2';
    this.isLoading = false;

  }
}

