<!-- Toaster Alert -->
<p-messages [style]="{ width: '100%', position: 'absolute', top: 0, left: 0 }"></p-messages>
<!-- Confirm Modal -->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000"></p-confirmDialog>
<!-- Spinner -->
<ngx-spinner bdColor="rgba(36,34,36,0.8)" size="medium" color="#81c784" type="pacman">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>
<div class="container" style="padding-top: 20vh;">
    <div class="center p-pt-1" *ngIf="!isInit && environmentName !=='test'">
        <button pButton pRipple type="button" class="p-button-rounded p-button-warning p-button-lg p-mr-3 p-ml-3" [disabled]="isLoading || !cmsSettings.manualDevQAGamedataPushEnabled" (click)="displayConfirmBuildDevAndQAModalV3()">
            <h2 style="margin: 1.5vw 3.5vw; font-size: '3rem'">BUILD DEV + QA GAMEDATA V3</h2>
        </button>
    </div>
</div>
<div class="container" *ngIf="!isInit && environmentName !=='test'">
    <!-- DEV GAMEDATA BUTTON -->
    <div class="p-grid">
        <div class="p-col-12">
            <button pButton pRipple type="button" class="p-button-rounded p-button-lg p-mr-3 p-ml-3" [disabled]="isLoading || !cmsSettings.manualDevGamedataPushEnabled" (click)="onGameDataBuildV3('dev')">
                <h2 style="margin: 1.5vw 3.5vw; font-size: '3rem'">BUILD DEV GAMEDATA V3</h2>
            </button>
        </div>
    </div>
    <!-- QA GAMEDATA BUTTON -->
    <div class="p-grid">
        <div class="p-col-12">
            <button pButton pRipple type="button" class="p-button-rounded p-button-info p-button-lg p-mr-3 p-ml-3" [disabled]="isLoading || !cmsSettings.manualQAGamedataPushEnabled" (click)="onGameDataBuildV3('stage')">
                <h2 style="margin: 1.5vw 3.5vw; font-size: '3rem'">BUILD QA GAMEDATA V3</h2>
            </button>
        </div>
    </div>
</div>
<div class="container" *ngIf="!isInit && environmentName =='test'">
  <!-- TEST GAMEDATA BUTTON V3 -->
  <div class="center p-pt-1">
    <button pButton pRipple type="button" class="p-button-rounded p-button-info p-button-lg p-mr-3 p-ml-3" [disabled]="isLoading || !cmsSettings.manualQAGamedataPushEnabled" (click)="onGameDataBuildV3('test')">
        <h2 style="margin: 1.5vw 3.5vw; font-size: '3.5rem'">BUILD TEST GAMEDATA V3</h2>
    </button>
  </div>
</div>
