<div [style]="{ margin: '1em' }" *ngIf="entity && !isLoading">
  <div>
    <h1 style="margin: 0 0 1em" class="p-mr-3 p-d-inline">
      {{ entity.name }}
    </h1>
    <a
      *ngIf="!authService.hasRole('ROLE_READ_ONLY')"
      pButton
      pRipple
      label="Edit"
      type="button"
      class="p-d-inline"
      icon="pi pi-pencil"
      href="/{{ type }}/edit/{{ entity.id }}"
    ></a>
  </div>

  <ng-container *ngFor="let field of headerFieldGroup">
    <div class="p-m-1 p-px-2">
      <span class="p-text-bold">{{ field.name }}: </span>
      <span *ngIf="entity[field.key]" class="p-text-normal">{{
        entity[field.key]
      }}</span>
    </div>
  </ng-container>

  <div class="p-grid nested-grid p-mt-3">
    <!-- Main Column (left) -->
    <div class="p-col-12 p-lg-8">
      <!-- Master Build Status (sm scrn) -->
      <ng-container *ngFor="let field of buildStatusFieldGroup">
        <span class="p-grid p-text-center p-d-lg-none">
          <div
            class="p-col p-mx-2 p-mt-3"
            [ngStyle]="{
              position: 'relative',
              color: 'var(--primary-color-text)',
              'background-color': entity[field.key].color,
              'border-radius': '0.5em'
            }"
          >
            <ng-container [ngSwitch]="entity[field.key].text">
              <ng-container *ngSwitchCase="'No build data'">
                <h3 class="p-m-0 p-text-center">Not Found</h3>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <h3 class="p-d-inline p-m-0 p-text-center">
                  {{ entity[field.key].text }}
                </h3>
                <p class="p-d-inline">
                  {{ entity[field.key].date }}
                </p>

                <p-badge
                  [value]="entity[field.key].buildCount"
                  [style]="{
                    'background-color': 'var(--text-color)',
                    position: 'absolute',
                    right: '20%',
                    top: '-1em'
                  }"
                ></p-badge>
              </ng-container>
            </ng-container>
          </div>
        </span>
      </ng-container>

      <!-- Main Body Cards -->
      <p-card *ngFor="let card of cards" class="p-mx-2 p-shadow-4">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-p-3">
            <span class="p-card-title">{{ card.name }}</span>
          </div>
          <ng-container *ngIf="card.name == 'Dev' && buildable">
            <div class="p-d-flex p-p-3">
              <div class="p-mr-auto p-fluid">
                <ng-container *ngIf="buildButtons.asset.length > 0">
                  <div class="">
                    <ng-container
                      *ngFor="let button of buildButtons.asset; let i = index"
                    >
                      <build-asset
                        class="p-d-block p-mb-2"
                        [entity]="entity"
                        [path]="
                          button.pathPrefix +
                          entity[button.pathField] +
                          button.fileExtension
                        "
                        [type]="button.type"
                        [assetType]="button.assetType"
                        (triggerCompleteEvent)="getUpdatedDocument($event)"
                        [label]="button.label"
                        [buildType]="button.buildType"
                        [isHighPriority]="button.isHighPriority"
                      ></build-asset>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
              <div class="p-ml-auto p-fluid">
                <ng-container *ngIf="buildButtons.image.length > 0">
                  <div class="">
                    <ng-container *ngFor="let button of buildButtons.image">
                      <build-asset
                        class="p-d-block p-mb-2"
                        [entity]="entity"
                        [path]="
                          button.pathPrefix +
                          entity[button.pathField] +
                          button.fileExtension
                        "
                        [type]="button.type"
                        [assetType]="button.assetType"
                        (triggerCompleteEvent)="getUpdatedDocument($event)"
                        [label]="button.label"
                        [buildType]="button.buildType"
                        [isHighPriority]="button.isHighPriority"
                      ></build-asset>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="card.name == 'History'">
            <div class="p-ml-auto">
              <p-button
                label="See latest History Changes"
                routerLink="/history/{{type}}/{{ entity.id }}"
              ></p-button>
            </div>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="content">
          <div class="p-grid p-formgrid p-fluid">
            <span
              *ngFor="let column of card.fieldGroups"
              class="p-col-12 p-sm-6 card-col"
            >
              <ul *ngFor="let group of column" style="list-style-type: none">
                <li *ngFor="let field of group">
                  <span *ngIf="group.length > 1" class="p-text-bold">
                    {{ field.name }}:
                  </span>

                  <ng-container
                    *ngIf="field.controlType == 'links'; else notLink"
                  >
                    <div *ngFor="let value of field.value">
                      <a
                        href="{{ value }}"
                        target="_blank"
                        [ngStyle]="{ color: 'var(--primary-color)' }"
                        class="p-text-normal p-d-block"
                        >{{ value }}</a
                      >
                    </div>
                  </ng-container>
                  <ng-container
                    #notLink
                    *ngIf="field.controlType == 'rules_ref'; else notRules"
                  >
                    <div *ngFor="let value of field.value">
                      <ul>
                        <li class="p-mb-2">
                          <strong>Filter Model:</strong> {{value.filterModel}} <br>
                          <strong>Filter Table Name:</strong> <a href="/{{value.filterTableName}}" [ngStyle]="{ color: 'var(--primary-color)' }"> {{value.filterTableName}} </a><br>
                          <span *ngIf="value.filter_ref && value.filter_ref.id">
                            <strong>Filter Details:</strong>({{value.filter_ref.id}}) {{value.filter_ref.name}}<br>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                  <ng-template #notRules>

                    <ng-container [ngSwitch]="field.key">
                      <ng-container
                        *ngSwitchCase="
                          ['asset_versions', 'image_versions'].includes(
                            field.key
                          )
                            ? field.key
                            : ''
                        "
                      >
                        <span *ngIf="field.value.length > 0">{{
                          field.value.slice(-1)[0].destinationPath
                        }}</span>
                      </ng-container>
                      <ng-container
                        *ngSwitchCase="
                          ['image_ref'].includes(
                            field.key
                          )
                            ? field.key
                            : ''
                        "
                      >
                      <a
                      href="/miscellaneous-build/{{ this.image_ref_id }}"
                      target="_blank"
                      class="p-text-normal p-d-block"
                      [ngStyle]="{ color: 'var(--primary-color)' }"
                      >{{ field.value }}</a
                    >
                      </ng-container>
                      <ng-container *ngSwitchDefault>
                        <span class="p-text-normal">{{ field.value }}</span>
                      </ng-container>
                    </ng-container>
                  </ng-template>
                </li>
              </ul>
            </span>
          </div>

          <ng-container *ngIf="card.name == 'Dev' && buildable">
            <p-accordion [multiple]="true">
              <p-accordionTab header="Asset Build Data" [selected]="true">
                <build-data
                  label="Asset Build Data"
                  [entity]="entity"
                  [buildData]="entity.buildData"
                  [isItemBuild]="true"
                  [isAssetBuild]="true"
                ></build-data>
              </p-accordionTab>
              <p-accordionTab header="Image Build Data">
                <build-data
                  label="Image Build Data"
                  [entity]="entity"
                  [buildData]="entity.imageBuildData"
                  [isImageBuild]="true"
                ></build-data>
              </p-accordionTab>
            </p-accordion>
          </ng-container>
        </ng-template>
      </p-card>

      <p-card class="p-mx-2 p-shadow-4" *ngIf="this.type =='categories'">
        <app-icons-view [id]="this.id"></app-icons-view>
      </p-card>

    </div>
    <div class="p-col-12 p-lg-4">

      <ng-container *ngIf="envs">
        <h4 class="p-m-2 p-d-none p-d-lg-flex">Environment(s):</h4>
        <span class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex">
           <!-- Environment(s) -->
           <ng-container>
            <div
              class="p-col p-m-2"
              [ngStyle]="{
                position: 'relative',
                color: 'var(--primary-color-text)',
                'background-color': 'green',
                'border-radius': '0.5em'
              }"
            >
              <h3 class="p-d-inline p-m-0 p-text-center">
                {{ entity.env && entity.env.length > 0 ? entity.env.join(', ') : 'No Environment Found' }}
              </h3>
            </div>
          </ng-container>
        </span>
      </ng-container>
    </div>
  </div>
</div>
