import { Component, OnInit } from '@angular/core';
import { ColDef, ISetFilterParams } from 'ag-grid-enterprise';
import { CellRendererService } from 'src/app/common/ag-grid-table/cell-renderers/dynamic.cell-renderer'
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { BuildType } from 'src/app/enums/build-type';
import { getDefaultTableColumns } from 'src/app/common/ag-grid-table/default-table-columns';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { LineItemCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/line-item/line-item.component';
import { LineItemEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/line-item/line-item.component';
import { ColorTextComponent } from 'src/app/common/ag-grid-table/cell-editors/color-text/color-text.component';

@Component({
  selector: 'app-co-op-table-ag-grid',
  templateUrl: './co-op-table-ag-grid.component.html',
  styleUrls: ['./co-op-table-ag-grid.component.sass']
})
export class CoOpTableAgGridComponent implements OnInit {

  public entity: string = 'co-op';
  public columnDefs: ColDef[] = [];
  public bulkUpdateColDefs: any[] = [];
  public columnSelection: string;

  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  private options: any = {};

  constructor(
    private cellRendererService: CellRendererService,
    private utilitiesService: UtilitiesService,
    private agGridToolsService: AgGridToolsService,
  ) {
    this.autopopulateSelect = 'logo_ref';
  }

  async ngOnInit(): Promise<void> {

    await this.setOptions();

    const defaultColumns = getDefaultTableColumns(this.entity, this.agGridToolsService);

    this.columnDefs = [
      ...defaultColumns,
      {
        headerName: 'Internal Name',
        field: 'internalName',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Invite Text',
        field: 'invite_text',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Welcome Text',
        field: 'welcome_text',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        maxWidth: 450,
        minWidth: 200,
        width: 250,
        autoHeight: true,
        cellEditor: 'agLargeTextCellEditor',
        cellEditorParams: { maxLength: 1000 },
        suppressKeyboardEvent: (params) => {
          return this.agGridToolsService.suppressEnter(params);
        },
        type: 'editableColumn',
      },
      {
        headerName: 'Co-Op Logo',
        field: 'logo_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'logo_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.logo_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Co-Op Logo Mask',
        field: 'white_image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'white_image_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.white_image_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Co-Op Banner Image',
        field: 'banner_image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'banner_image_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.banner_image_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Co-Op Popup Background',
        field: 'popup_background_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        cellRendererParams: {
          isTeamColor: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'popup_background_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.popup_background_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      // {
      //   headerName: 'Team Colors',
      //   field: 'team_colors',
      //   cellRenderer: TextCellRendererComponent,
      //   sortable: true,
      //   unSortIcon: true,
      //   resizable: true,
      //   filter: 'agTextColumnFilter',
      //   floatingFilter: true,
      //   autoHeight: true,
      // },
      {
        headerName: 'Team Colors',
        field: 'team_colors',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isTeamColor: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
      },
      {
        headerName: 'Goal Name',
        field: 'goal_name',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Goal Name Plural',
        field: 'goal_name_plural',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Currency Reference',
        field: 'currency_record_ref',
        sortable: true,
        unSortIcon: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          entity: this.entity,
          isReference: true
        },
        resizable: true,
        autoHeight: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
      },
      {
        headerName: 'Sprite Index',
        field: 'currency_sprite_index',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        floatingFilter: true,
        cellEditor: 'agNumberCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Rewards Track',
        field: 'track_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.track_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,

        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        width: 400,
      },
      {
        headerName: 'Rewards Track 2',
        field: 'track_2_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.track_ref,
          valueFormatter: (params: any) => {
            console.log('params', params);
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        width: 400,

      },
      {
        headerName: 'Rewards Track 3',
        field: 'track_3_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'rewards-track',
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.track_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isSingleRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name _id'
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        type: 'editableColumn',
        width: 400,
      },
      {
        headerName: 'Grand Prize(s)',
        field: 'rewards_ref',
        cellRenderer: LineItemCellRendererComponent,
        cellRendererParams: {
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agMultiColumnFilter',
        filterParams: {
          filters: [
            {
              filter: 'agSetColumnFilter',
              display: 'accordion',
              title: 'Currency',
              filterParams: {
                buttons: ['clear'],
                title: 'Currency',
                values: this.options.currencies_ref,
                valueFormatter: (params: any) => {
                  return `${params.value.name}`;
                },
                keyCreator: (params: any) => {
                  return params.value._id;
                }
              },
            },
            {
              filter: 'agNumberColumnFilter',
              display: 'accordion',
              title: 'Cost',
              filterParams: {
                buttons: ['clear'],
              }
            },
          ],
          populate: true,
          nestedPop: true,
          select: {
            path: 'id t', // Assuming 'id' and 't' are fields within 'costs_ref'
            select: '_id id name' // Adjust the fields you want to select from the referenced documents
          }
        },
        floatingFilter: true,
        width: 300,
        autoHeight: true,
        type: 'editableColumn',
        cellEditor: LineItemEditorComponent,
        cellEditorPopup: true,
        cellEditorParams: {
          resources: this.options.resources_ref,
          currencies: this.options.currencies_ref,
          titles: this.options.titles_ref,
        },
      },
      {
        headerName: 'Challenge feed button Color 1',
        field: 'feedBtnColor1',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorPicker: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: ColorTextComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Challenge feed button Color 2',
        field: 'feedBtnColor2',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorPicker: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: ColorTextComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Logo Outline Color',
        field: 'logoOutlineColor',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorPicker: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: ColorTextComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Horizontal Bar Color',
        field: 'horizontalBarColor',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isColorPicker: true
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: ColorTextComponent,
        // cellEditorPopup: true,
        type: 'editableColumn',
      },
      {
        headerName: 'Slide Down Image',
        field: 'slide_down_image_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'slide_down_image_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.white_image_ref
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Popup Goal Image',
        field: 'popup_goalimage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'popup_goalimage_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path'],
          isMiscBuild: true,
          values: this.options.popup_goalimage_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,

        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
    ];

    this.bulkUpdateColDefs = [
      {
        name: 'Name', id: 'name', type: 'text'
      },
      {
        name: 'Start', id: 'start', type: 'date'
      },
      {
        name: 'End', id: 'end', type: 'date'
      },
      {
        name: 'Enabled', id: 'enabled', type: 'boolean', onLabel: 'Enabled', offLabel: 'Disabled'
      },
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
      {
        name: 'Costs', id: 'costs_ref', type: 'line-items', optionLabel: 'name', optionValue: '_id', smartPopulate: true
      },
    ];

    this.buildParams = {
      imageKeys: ['logo_ref', 'white_image_ref', 'banner_image_ref', 'currency_ref', 'slide_down_image_ref', 'popup_goalimage_ref'],
      imageBuildType: BuildType.Images
    };

    this.tableParams = {
      customActionsLabel: 'Custom Bulk Actions',
      customActionButtons: ['bulkLocalize'],
      localizeCollectionName: 'coops',
    }


    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }

  async setOptions() {
    const entities = [
      { ref: 'currencies_ref', entity: 'currencies' },
      { ref: 'resources_ref', entity: 'resources' },
      { ref: 'titles_ref', entity: 'titles' },
      { ref: 'logo_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { assetType: AssetTypes.CoopLogo } },
      { ref: 'white_image_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { assetType: AssetTypes.CoopLogo } },
      { ref: 'slide_down_image_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { entityType: { $in: [3] } } },
      { ref: 'banner_image_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { assetType: AssetTypes.CoopBanner } },
      { ref: 'popup_background_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { assetType: AssetTypes.CoopPopupBackground } },
      { ref: 'currency_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { assetType: AssetTypes.CoopCurrency } },
      { ref: 'track_ref', entity: 'rewards-track' },
      { ref: 'popup_goalimage_ref', entity: 'miscellaneous-build', select: 'id _id name path', query: { assetType: AssetTypes.WelcomeGoal } },
    ]

    for (const { ref, entity, select, query } of entities) {
      const params = new OptionsParams({
        entity, select, query
      });
      await this.utilitiesService.getOptions(this.options, ref, params);;
    }

  }

}
