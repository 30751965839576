<p-dialog [(visible)]="displayNotesPopup" [modal]="true" header="Internal Notes" 
    [style]="{width: '50vw', minHeight: '400px'}" [resizable]="true" [dismissableMask]="false" (onHide)="closePopup()">
  <div class="p-fluid" style="display: flex; flex-direction: column; height: 100%;">
    <div class="p-field" style="flex-grow: 1; overflow-y: auto;">
      <p-editor [(ngModel)]="tempCopy" id="copy" [style]="{height: '100%', width: '100%'}">
        <ng-template pTemplate="header">
          <span class="ql-formats">
            <select class="ql-header" aria-label="Header"></select>
            <button class="ql-bold" aria-label="Bold"></button>
            <button class="ql-italic" aria-label="Italic"></button>
            <button class="ql-underline" aria-label="Underline"></button>
            <select class="ql-color" aria-label="Color"></select>
            <select class="ql-background" aria-label="Background Color"></select>
            <button class="ql-list" value="ordered" aria-label="Ordered List"></button>
            <button class="ql-list" value="bullet" aria-label="Unordered List"></button>
            <!-- <button class="ql-link" aria-label="Insert Link"></button> -->
          </span>
        </ng-template>
      </p-editor>
    </div>
    <div class="p-field p-d-flex p-jc-center" style="flex-shrink: 0; padding-top: 10px;">
      <button pButton type="button" label="Save" icon="pi pi-save" class="p-button-sm" (click)="saveNotes()" style="width: 350px;"></button>
    </div>
  </div>
</p-dialog>

<div class="button-container" style="display: flex; justify-content: flex-end;">
  <button pButton pRipple icon="pi pi-notes" label="Internal Notes" type="button" class="p-ml-2" (click)="openNotesPopup()"></button>
</div>

<p-confirmDialog header="Confirm" icon="pi pi-exclamation-triangle" [acceptLabel]="'Discard Changes'" [rejectLabel]="'Continue Editing'" [acceptIcon]="'pi pi-trash'" [rejectIcon]="'pi pi-pencil'"></p-confirmDialog>