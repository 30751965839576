export const itemConstants = {
  inputNames: {
    category: { name: "category", optionsName: "category_ref"},
    fileType: { name: "fileType", optionsName: "itemFileType_ref"},
    year: { name: "year", optionsName: "year"},
    fileName: { name: "fileName", optionsName: "fileName"}
  },
  categoryPath: {

  }
};

export const pathconfigValues = {
  year: "2024",
  type: "items",
  itemsThumbnailPathPrefix: "images/item_thumbs"
}

export const primaryCard = [
  {
    name: 'Production',
    rows: [
      {
        layout: 'columns',
        fieldGroups: [
          [
            {
              name: 'Path',
              key: 'prefab',
              controlType: 'auto',
              value: '',
            },
            {
              name: 'Thumbnail Path',
              key: 'thumbnail',
              controlType: 'auto',
              value: '',
            },
            {
              name: 'File Name',
              key: 'fileName',
              controlType: 'auto',
              value: '',
            },
          ],
          [
            {
              name: 'File Type',
              key: 'fileType',
              controlType: 'auto',
              value: '',
            },
            {
              name: 'Asset Type',
              key: 'assetType',
              controlType: 'auto',
              value: '',
            },
            {
              name: 'Year',
              key: 'year',
              controlType: 'dropdown',
              value: '',
            },
          ],
          [
            {
              name: 'Vendor Height',
              key: 'vendorHeight',
              controlType: 'inputText',
              value: '',
            },
            {
              name: 'Vendor Spread',
              key: 'vendorLength',
              controlType: 'inputText',
              value: '',
            },
            {
              name: 'Vendor Dimensions',
              key: 'vendorDimensions',
              controlType: 'inputTextarea',
              value: '',
            },
          ],
          [
            {
              name: 'Art Status',
              key: 'vendorStatus',
              controlType: 'dropdown',
              value: '',
            },
            {
              name: 'Art Hold',
              key: 'flagged',
              controlType: 'dropdown',
              value: '',
            },
          ],
          [
            {
              name: 'Vendor',
              key: 'vendor_ref',
              controlType: 'autoComplete_ref',
              apiController: 'vendors',
              value: '',
            },
            {
              name: 'Batch',
              key: 'batch_ref',
              controlType: 'autoComplete_ref',
              apiController: 'batches',
              value: '',
            },
            {
              name: 'Collection(s)',
              key: 'collection_ref',
              controlType: 'multiSelect_ref',
              apiController: 'collections',
              value: '',
            },
            {
              name: 'Set(s)',
              key: 'itemSet_ref',
              controlType: 'multiSelect_ref',
              apiController: 'item-sets',
              value: '',
            },
          ],
           [
            {
              name: 'Bundle Asset (IOS)',
              key: 'bundleAsset',
              controlType: 'toggle',
              value: '',
            },
            {
              name: 'Bundle Image (IOS)',
              key: 'bundleImage',
              controlType: 'toggle',
              value: '',
            },
            {
              name: 'Bundle Asset (AND)',
              key: 'bundleAssetAnd',
              controlType: 'toggle',
              value: '',
            },
            {
              name: 'Bundle Image (AND)',
              key: 'bundleImageAnd',
              controlType: 'toggle',
              value: '',
            },
           ],
           [
            {
              name: 'Spawn Audios',
              key: 'spawnAudios_ref',
              controlType: 'multiSelect_ref',
              apiController: 'miscellaneous-build',
              value: '',
            },
            {
              name: 'Spawn Audio Collections',
              key: 'spawnAudioCollections_ref',
              controlType: 'multiSelect_ref',
              apiController: 'audio-collections',
              value: '',
            },
          ],
          [
            {
              name: 'Loop Audios',
              key: 'loopAudios_ref',
              controlType: 'multiSelect_ref',
              apiController: 'miscellaneous-build',
              value: '',
            },
            {
              name: 'Loop Audio Collections',
              key: 'loopAudioCollections_ref',
              controlType: 'multiSelect_ref',
              apiController: 'audio-collections',
              value: '',
            },
           ],
           [
            {
              name: 'Keywords',
              key: 'keywords_ref',
              controlType: 'multiSelect_ref',
              value: '',
            },
           ]
        ],
      },
      {
        layout: 'rows',
        fieldGroups: [
          [
            {
              name: 'Vendor Reference Link',
              key: 'referenceLinks',
              controlType: 'ref-link',
              value: '',
            },
            {
              name: 'Internal Reference Link',
              key: 'internalReferenceLinks',
              controlType: 'ref-link',
              value: '',
            },
          ],
        ],
      },
    ],
  },
  {
    name: 'Vendor Notes',
    rows: [
      {
        layout: 'rows',
        fieldGroups: [
          [
            {
              name: 'Vendor Notes',
              key: 'vendorNotes',
              controlType: 'richTextArea',
              value: '',
            },
          ],
        ],
      },
    ],
  },
  {
    name: 'Internal (Content) Notes',
    rows: [
      {
        layout: 'rows',
        fieldGroups: [
          [
            {
              name: 'Content Notes',
              key: 'internalNotes',
              controlType: 'richTextArea',
              value: '',
            },
          ],
        ],
      },
    ],
  },
  {
    name: 'Content',
    rows: [
      {
        layout: 'columns',
        fieldGroups: [
          [
            {
              name: 'Climate(s)',
              key: 'climates_ref',
              controlType: 'multiSelect_ref',
              apiController: 'climates',
              value: '',
            },
            {
              name: 'Set(s)',
              key: 'itemSet_ref',
              controlType: 'multiSelect_ref',
              apiController: 'item-sets',
              value: '',
            },
            {
              name: 'Category Type',
              key: 'category_ref',
              apiController: 'categories',
              controlType: 'dropdown_ref',
              value: '',
            },
            {
              name: 'Plant Type',
              key: 'type_ref',
              apiController: 'item-types',
              controlType: 'dropdown_ref',
              value: '',
            },
          ],
          [
            {
              name: 'Height',
              key: 'height',
              controlType: 'inputText',
              value: '',
            },
            {
              name: 'Spread',
              key: 'spread',
              controlType: 'inputText',
              value: '',
            },
          ],
        [
          {
            name: 'Store Start',
            key: 'start',
            controlType: 'calendar-start',
            value: '',
          },
          {
            name: 'Store End',
            key: 'end',
            controlType: 'calendar-end',
            value: '',
          },
        ],
        [

          {
            name: 'Re Release Start',
            key: 'reReleaseStart',
            controlType: 'calendar-start',
            value: '',
          },
          {
            name: 'Re Release End',
            key: 'reReleaseEnd',
            controlType: 'calendar-end',
            value: '',
          },
        ],
          [
            {
              name: 'Color(s)',
              key: 'colors_ref',
              apiController: 'colors',
              controlType: 'multiSelect_ref',
              value: '',
            },
            {
              name: 'Trait(s)',
              key: 'traits_ref',
              apiController: 'traits',
              controlType: 'multiSelect_ref',
              value: '',
            },
            {
              name: 'Style(s)',
              key: 'styles_ref',
              apiController: 'styles',
              controlType: 'multiSelect_ref',
              value: '',
            },
            {
              name: 'Material(s)',
              key: 'materials_ref',
              apiController: 'materials',
              controlType: 'multiSelect_ref',
              value: '',
            },
            {
              name: 'Pattern(s)',
              key: 'patterns_ref',
              apiController: 'patterns',
              controlType: 'multiSelect_ref',
              value: '',
            },
            {
              name: 'Shape(s)',
              key: 'shape_ref',
              apiController: 'shapes',
              controlType: 'dropdown_ref',
              value: ''
            }
          ],
          [
            {
              name: 'Content Status',
              key: 'itemStatus',
              controlType: 'dropdown',
              value: '',
            },
            {
              name: 'Content Hold',
              key: 'contentHold_ref',
              controlType: 'multiSelect_ref',
              apiController: 'settings',
              value: '',
            },
            {
              name: 'Cost(s)',
              key: 'costs_ref',
              controlType: 'lineItem',
              value: '',
            },
            {
              name: 'Tag(s)',
              key: 'tags_ref',
              controlType: 'multiSelect_ref',
              apiController: 'settings',
              value: '',
            },
            {
              name: 'Level Lock',
              key: 'progressionLevel_ref',
              controlType: 'dropdown_ref',
              apiController: 'progression-levels',
              value: '',
            },
          ],
          // [

          //   {
          //     name: 'Re Release Start',
          //     key: 'reReleaseStart',
          //     controlType: 'calendar-start',
          //     value: '',
          //   },
          //   {
          //     name: 'Re Release End',
          //     key: 'reReleaseEnd',
          //     controlType: 'calendar-end',
          //     value: '',
          //   },
          // ],
          [
            {
              name: 'Linked Challenge(s)',
              key: 'challenges_ref',
              controlType: 'ref-link',
              value: '',
            },
            {
              name: 'Associated Asset(s)',
              key: 'releatedItems',
              controlType: 'ref-link',
              value: '',
            },
            {
              name: 'Nurture',
              key: 'nurture_ref',
              apiController: 'nurture',
              controlType: 'dropdown_ref',
              value: '',
            },
          ],
          [
            {
              name: 'Blurb Status',
              key: 'blurbStatus',
              controlType: 'dropdown',
              value: '',
            },
          ],
          [
            {
              name: 'Blurb',
              key: 'blurb',
              controlType: 'inputTextarea',
              value: '',
            },
          ],
        ],
      },
      // {
      //   layout: 'rows',
      //   fieldGroups: [
      //     [

      //       {
      //         name: 'Blurb',
      //         key: 'blurb',
      //         controlType: 'inputTextarea',
      //         value: '',
      //       },
      //     ],
      //   ],
      // }
    ],
  },
  {
    name: 'Build Data',
    rows: [
      {
        layout: 'columns',
        fieldGroups: [
          [
            {
              name: 'Created By',
              key: 'createdBy',
              controlType: 'userName',
              value: '',
            },
            {
              name: 'Created At',
              key: 'createdAt',
              controlType: 'date',
              value: '',
            },
            {
              name: 'Updated At',
              key: 'updatedAt',
              controlType: 'date',
              value: '',
            },
          ],
          [
            {
              name: 'Dim X (Width)',
              key: 'dimensionX',
              controlType: 'inputText',
              value: '',
            },
            {
              name: 'Dim Y (Height)',
              key: 'dimensionY',
              controlType: 'inputText',
              value: '',
            },
            {
              name: 'Dim Z (Depth)',
              key: 'dimensionZ',
              controlType: 'inputText',
              value: '',
            },
            {
              name: 'Base Radius',
              key: 'radius',
              controlType: 'inputText',
              value: '',
            },
          ],
          [
            {
              name: 'Build Data Reference ID',
              key: 'prefab_ref',
              controlType: 'buildReferenceId',
              value: '',
            },
          ],
          [

            {
              name: 'Promotional Status',
              key: '',
              controlType: 'label',
            },
            {
              name: 'Item Promoted Status',
              key: 'IsAssetUpToDate',
              controlType: 'auto',
              version: '',
            },
            {
              name: 'Image Promoted Status',
              key: 'IsImageUpToDate',
              controlType: 'auto',
              version: '',
            },
          ],
          [
            {
              name: 'Item Hash',
              key: '',
              controlType: 'label',
            },
            {
              name: 'Current Item Hash',
              key: 'asset_versions',
              controlType: 'inputText',
            },
            {
              name: 'Latest Item Build Hash',
              key: 'LatestAssetBuildVersion',
              controlType: 'auto',
              version: '',
            },
          ],
          [
            {
              name: 'Image Hash',
              key: '',
              controlType: 'label',
            },
            {
              name: 'Current Image Hash',
              key: 'image_versions',
              controlType: 'inputText',
            },
            {
              name: 'Latest Image Build Hash',
              key: 'LatestImageBuildVersion',
              controlType: 'auto',
              version: '',
            },
          ],
        ],
      },
    ],
  },
  {
    name: 'History',
    rows: [
      {
        layout: 'rows',
        fieldGroups: [],
      },
    ],
  },
];

export const secondaryCard = {
  name: 'Images',
  fields: [
    {
      name: 'Thumbnail',
      key: 'thumbnail_url_1024',
      controlType: 'image',
    },
    {
      name: 'Linux Render',
      key: 'renderPreview',
      controlType: 'renderGallery'
    },
  ],
};

export const envRules = {
  'dev': {
    rules:['Item is enabled', 'itemStatus is QA Ready or Approved', 'vendorStatus is Approved'],
    status: true,
  },
  'qa': {
    rules:['Item is enabled', 'Item has costs', 'itemStatus is Approved', 'vendorStatus is Approved', 'Start and End dates have values', 'item is NOT flagged on hold'],
    status: true,
  },
  'prod': {
    rules:['Item is enabled', 'Item has costs', 'itemStatus is Approved', 'vendorStatus is Approved', 'Start and End dates have values', 'item is NOT flagged on hold'],
    status: true,
  },
}

export const plantPreview = {
  category: '',
  type: '',
  colors: { raw: [], names: [] },
  climates: { raw: [], names: [] },
  traits: { raw: [], names: [] },
  hardinessZones: '',
  nativeArea: '',
  sunExposure: '',
  bloomTime: '',
  description: '',
  blurb: '',
  typicalSize: '',
  cost: '',
};

export const formFields = {
  name: null,
  blurb: null,
  blurbStatus: null,
  cultivar: null,
  latinName: null,
  fileName: null,
  plantFamily: null,
  start: null,
  end: null,
  reReleaseStart: null,
  bundleAsset: false,
  bundleImage: false,
  bundleAssetAnd: false,
  bundleImageAnd: false,
  reReleaseEnd: null,
  category_ref: null,
  nurture_ref: null,
  type_ref: null,
  climates_ref: null,
  itemSet_ref: null,
  colors_ref: null,
  styles_ref: null,
  traits_ref: null,
  materials_ref: null,
  patterns_ref: null,
  shape_ref: null,
  batch_ref: null,
  enabled: false,
  year: null,
  spruceDataStatus: false,
  thumbnail: null,
  prefab: null,
  vendor_ref: null,
  vendorStatus: null,
  vendorNotes: null,
  vendorDimensions: null,
  itemStatus: null,
  contentHold_ref: null,
  // recolorSource: null,
  internalNotes: null,
  height: null,
  spread: null,
  dimensionX: 0,
  dimensionY: 0,
  dimensionZ: 0,
  radius: 0,
  progressionLevel_ref: null,
  itemFileType_ref: null,
  externalPlantData_ref: null,
  assetType: null,
  costs_ref: [],
  referenceLinks: [],
  referenceImages: [],
  internalReferenceLinks: [],
  internalReferenceImageLinks: [],
  vendorHeight: null,
  vendorLength: null,
  isLocked: false,
  releatedItems: [],
  env: [],
  tags_ref : [],
  // referenceImageLinks: [],
};
