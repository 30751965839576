import { ViewportScroller } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isInteger } from 'lodash';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/auth/auth.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { ValidationsService } from 'src/app/common/services/validations.service';
import { DataService } from 'src/app/services/data.service';
import { FormService } from 'src/app/services/form.service';
import { AchievementService } from '../services/achievement.service';
import { Title } from '@angular/platform-browser';
import { environments } from 'src/app/common/constants/constants';

@Component({
  selector: 'app-achievement-form',
  templateUrl: './achievement-form.component.html',
  styleUrls: ['./achievement-form.component.css'],
})
export class AchievementFormComponent implements OnInit {
  key: string = 'achievements';
  id: number;
  isEditMode: boolean = false;
  submitUrl: string;
  defaultDate: any;
  options: any = {
    achievementTypeOptionsMapping: [
      {
        key: 'SubmitChallenge',
        type: 0,
        fields2Show: [
          'showAchievementTypeOptions',
          'showChallengeTypeOptions',
          'showChallengeClimateOptions',
        ],
      },
      {
        key: 'DailyGiftCollections',
        type: 1,
        fields2Show: [],
      },
      {
        key: 'ChallengeResultsScore',
        type: 2,
        fields2Show: [
          'showAchievementTypeOptions',
          'showChallengeResultScoreInput',
        ],
      },
      {
        key: 'ItemsPlacedSubmittedChallenge',
        type: 3,
        fields2Show: [
          'showAchievementTypeOptions',
          'showItemCategoryOptions',
          'showItemTypeOptions',
        ],
      },
      {
        key: 'NotificationOptIn',
        type: 4,
        fields2Show: [],
      },
      {
        key: 'ExternalSignIn',
        type: 5,
        fields2Show: [],
      },
      {
        key: 'VotingRoundsCompleted',
        type: 6,
        fields2Show: [],
      },
      {
        key: 'StarScoreReached',
        type: 7,
        fields2Show: [],
      },
      {
        key: 'IAPs',
        type: 8,
        fields2Show: [
          'showAchievementTypeOptions',
          'showMinimumIAPCost'
        ],
      },
      {
        key: 'ContactsIntegration',
        type: 9,
        fields2Show: [],
      },
      {
        key: 'plantsGrown',
        type: 10,
        fields2Show: [],
      },
      {
        key: 'friendInvite',
        type: 11,
        fields2Show: [],
      },
      {
        key: 'friendsAdded',
        type: 12,
        fields2Show: [],
      },
      {
        key: 'watchVideo',
        type: 13,
        fields2Show: [],
      },
      {
        key: 'minigamesRoundsCompleted',
        type: 14,
        fields2Show: [],
      },
      {
        key: 'submitInfiniteChallenge',
        type: 15,
        fields2Show: [],
      },
    ],
  };
  suggestions: any = [];
  existingDoc: any = {};
  uploadedFiles: any[] = [];
  currentFiles: any[] = [];
  loading: boolean = true;
  achievementForm: UntypedFormGroup;
  fields: any = {};
  display: boolean = false;
  displayShown: boolean = false;
  showOptions: any = {
    showAchievementTypeOptions: false,
    showChallengeTypeOptions: false,
    showChallengeClimateOptions: false,
    showItemCategoryOptions: false,
    showItemTypeOptions: false,
    showChallengeResultScoreInput: false,
    showMinimumIAPCost: false,
  };
  // showAchievementTypeOptions: boolean = true;
  fields2Show: any = [
    'showAchievementTypeOptions',
    'showChallengeTypeOptions',
    'showChallengeClimateOptions',
    'showItemCategoryOptions',
    'showItemTypeOptions',
    'showChallengeResultScoreInput',
    'showMinimumIAPCost',
  ];
  // Do I need these variables ?
  payload = '';
  action = 'add';
  fileUploaderUrl ='https://worker.flora-cms.bgamestudios.com/upload';

  characterCount: number = 0;

  // Repetitive Events
  weekDays = [
    { label: 'Monday', value: 'monday' },
    { label: 'Tuesday', value: 'tuesday' },
    { label: 'Wednesday', value: 'wednesday' },
    { label: 'Thursday', value: 'thursday' },
    { label: 'Friday', value: 'friday' },
    { label: 'Saturday', value: 'saturday' },
    { label: 'Sunday', value: 'sunday' }
  ];

  repeats: string[] = [];

  constructor(
    private logger: LoggerService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private formService: FormService,
    private authService: AuthService,
    private dataService: DataService,
    private messageService: MessageService,
    private achievementService: AchievementService,
    private validationService: ValidationsService,
    private utilitiesService: UtilitiesService,
    private scroller: ViewportScroller,
    private titleService: Title,
    private router: Router

  ) { }

  async ngOnInit() {
    this.logger.log('init');

    this.defaultDate = this.utilitiesService.getCurrentDateAtMidnight()
    // Url to submit to the form to.
    this.submitUrl = `${this.key}/${this.action}`;

    /*
      Form Fields / Defaults
     */
    this.fields = {
      title: null,
      playerDescription: null,
      interval: null,
      achievementType: null,
      link_ref: null,
      challengeType: null,
      challengeClimate: null,
      itemCategory: null,
      itemType: null,
      challengeResultScore: 0,
      minimumIAPCost: 0,
      rewards_ref: [],
      promoRewards_ref: [],
      image: null,
      target: null,
      start: null,
      end: null,
      promoStartDate: null,
      promoEndDate: null,
      enabled: false,
      isConsecutive: false,
      isRepetitive: false,
      env: null,
      repeatsDuration: 0,
      repeats: [],
      sortingOrder: 0,
      subText: null,
    };

    // check for ID for edit mode.
    const routeParams = this.route.snapshot.paramMap;
    this.id = Number(routeParams.get('id'));
    if (this.id) {
      this.loading = true;
      this.isEditMode = true;
      // change submit url for updating existing entity.
      this.submitUrl = `${this.key}/update/${this.id}`;

      // get existing document.
      console.log('get existing doc');
      await this.getExistingDoc().then(async (result) => {
        this.existingDoc = result;
        if (this.existingDoc.title) {
          this.titleService.setTitle(`Editing: ${this.existingDoc.title}`);
        }

        // set existing values from doc.
        console.log('set existing values');
        await this.setExistingValues().then(async () => {
          // init form.
          console.log('init form');
          await this.initForm();
        });
      });
      this.loading = false;
    } else {
      // initialize form
      this.titleService.setTitle(`Add Achievement`);
      await this.initForm();
    }

    try {
      const playerDescription = this.achievementForm.get('playerDescription');
      if (playerDescription) {
        const value = playerDescription.value || '';
        this.characterCount = value.length;
      }
    } catch (error) {
      console.error('Error occurred during initialization:', error);
      this.characterCount = 0;
    }

    console.log('end of init');
  }

  async setOptions() {
    await this.getOptionsFromRef('t', 'resources', true, true, false);
    await this.getOptionsFromRef(
      'challenge-types',
      'challenge-types',
      true,
      true
    );
    await this.getOptionsFromRef('climate-types', 'climates', true, true, true);
    await this.getOptionsFromRef(
      'item-categories',
      'categories',
      true,
      true,
      true
    );
    await this.getOptionsFromRef('item-types', 'item-types', true, true, true);
    await this.getOptionsFromRef('user_titles', 'titles', true, true, true);
    await this.getOptionsFromRef('link_ref', 'link-destination', true, true, true);

    this.options['interval'] = [
      {
        name: 'Continuous',
        key: 'Continuous',
        value: 0,
      },
      {
        name: 'Single / One and Done',
        key: 'OneAndDone',
        value: 1,
      },
    ];

    this.options['achievementType'] = [
      {
        name: 'Challenges submitted',
        key: 'SubmitChallenge',
        value: 0,
      },
      {
        name: 'Daily Gifts collected',
        key: 'DailyGiftCollections',
        value: 1,
      },
      {
        name: 'Challenge Results w/ Score of X or above',
        key: 'ChallengeResultsScore',
        value: 2,
      },
      {
        name: 'Items submitted',
        key: 'ItemsPlacedSubmittedChallenge',
        value: 3,
      },
      {
        name: 'Notifications',
        key: 'NotificationOptIn',
        value: 4,
      },
      {
        name: 'Facebook Sign In',
        key: 'ExternalSignIn',
        value: 5,
      },
      {
        name: 'Voting Rounds completed',
        key: 'VotingRoundsCompleted',
        value: 6,
      },
      {
        name: 'Star Score Reached',
        key: 'StarScoreReached',
        value: 7,
      },
      {
        name: 'IAPs completed',
        key: 'IAPs',
        value: 8
      },
      {
        name: 'Contacts Integration',
        key: 'ContactsIntegration',
        value: 9,
      },
      {
        name: 'Plants Grown',
        key: 'plantsGrown',
        value: 10,
      },
      {
        name: 'Friend Invite',
        key: 'friendInvite',
        value: 11,
      },
      {
        name: 'Friends Added',
        key: 'friendsAdded',
        value: 12,
      },
      {
        name: 'Watch Video',
        key: 'watchVideo',
        value: 13,
      },
      {
        name: 'Minigames Rounds Completed',
        key: 'minigamesRoundsCompleted',
        value: 14,
      },
      {
        name: 'Infinite Challenges Submitted',
        key: 'submitInfiniteChallenge',
        value: 15,
      },
    ];

    this.options['eventType'] = [
      {
        name: 'All',
        value: 1,
      },
      {
        name: 'Daily',
        value: 1000,
      },
      {
        name: 'Classic',
        value: 1001,
      },
      {
        name: 'Grand',
        value: 1002,
      },
    ];

    this.options['eventClimate'] = [
      {
        name: 'Default',
        value: 0,
      },
      {
        name: 'Arid',
        value: 1000,
      },
      {
        name: 'Tropical',
        value: 1001,
      },
      {
        name: 'Temperate',
        value: 1002,
      },
    ];

    this.options['achievementTypeOptions'] = [
      {
        name: 'Challenge Type',
        value: 0,
      },
      {
        name: 'Score',
        value: 1,
      },
      {
        name: 'Item Category',
        value: 2,
      },
      {
        name: 'Item Type',
        value: 3,
      },
      {
        name: 'Challenge Climate',
        value: 4,
      },
      {
        name: 'Minimum IAP cost (≥ greater than or equal to)',
        value: 5,
      },
    ];

    this.options['envs'] = environments;
    this.loading = false;
  }

  async checkAchievementTypeValue(e: any) {
    let mappedOptions = this.options['achievementTypeOptionsMapping'].filter(
      (option: { type: any }) => option.type == e.value
    );
    console.log(mappedOptions);
    mappedOptions.forEach((option: any) => {
      let difference = this.fields2Show.filter(
        (field: any) => !option.fields2Show.includes(field)
      );
      console.log('dif: ', difference);
      difference.forEach((field: string) => {
        this.showOptions[field] = false;
      });
      option.fields2Show.forEach((field: string) => {
        this.showOptions[field] = true;
      });
    });
  }

  async getExistingDoc() {
    const doc = await this.dataService.getDocumentAsync(this.key, {
      query: { id: this.id },
      autopopulate: true,
      virtuals: true,
    });
    // console.log('existing doc', doc);
    return doc;
  }

  async setExistingValues() {
    // Simple fields that are directly copied if they exist
    const simpleFields = [
      'title', 'playerDescription', 'challengeResultScore', 'repeatsDuration', 'repeats',
      'minimumIAPCost', 'target', 'image', 'enabled', 'isConsecutive', 'isRepetitive',
      'sortingOrder', 'subText', 'env'
    ];

    simpleFields.forEach(field => {
      if (this.existingDoc[field] !== undefined) {
        this.fields[field] = this.existingDoc[field];
      }
    });

    // Fields that need special handling or checks
    if (this.existingDoc.interval >= 0) {
      this.fields.interval = this.existingDoc.interval;
    }

    if (this.existingDoc.achievementType >= 0) {
      this.checkAchievementTypeValue({
        value: this.existingDoc.achievementType,
      });
      this.fields.achievementType = this.existingDoc.achievementType;
    }

    ['challengeType', 'challengeClimate', 'itemCategory', 'itemType','link_ref'].forEach(field => {

      if (this.existingDoc[field]) {
        this.fields[field] = this.existingDoc[field]._id;
      }
    });

    if (this.existingDoc.start) {
      this.fields.start = new Date(this.existingDoc.start);
    }

    if (this.existingDoc.end) {
      this.fields.end = new Date(this.existingDoc.end);
    }

    if (this.existingDoc.promoStartDate) {
      this.fields.promoStartDate = new Date(this.existingDoc.promoStartDate);
    }

    if (this.existingDoc.promoEndDate) {
      this.fields.promoEndDate = new Date(this.existingDoc.promoEndDate);
    }

    // if (this.existingDoc.env) {
    //   let envs = this.existingDoc.sendReady.map((env: string) => env.toLowerCase());
    //   this.fields.env = envs;
    // }

    // Handle rewards
    if (this.existingDoc.rewards_ref) {
      this.fields.rewards_ref = this.existingDoc.rewards_ref.map((reward: { t: any; id: any; c: any; lineItemType: any; }) =>
        this.fb.group({
          t: reward.t,
          id: reward.id,
          c: reward.c,
          lineItemType: reward.lineItemType,
        })
      );
    }
    if (this.existingDoc.promoRewards_ref) {
      this.fields.promoRewards_ref = this.existingDoc.promoRewards_ref.map((reward: { t: any; id: any; c: any; lineItemType: any; }) =>
        this.fb.group({
          t: reward.t,
          id: reward.id,
          c: reward.c,
          lineItemType: reward.lineItemType,
        })
      );
    }
  }


  addReward() {
    this.rewards_ref.push(
      this.fb.group({
        t: null,
        id: null,
        c: 5,
        lineItemType: '',
      })
    );
  }

  addPromoReward() {
    this.promoRewards_ref.push(
      this.fb.group({
        t: null,
        id: null,
        c: 5,
        lineItemType: '',
      })
    );
  }

  get rewards_ref() {
    return this.achievementForm.get('rewards_ref') as UntypedFormArray;
  }

  get promoRewards_ref() {
    return this.achievementForm.get('promoRewards_ref') as UntypedFormArray;
  }

  deleteReward(index: number) {
    this.rewards_ref.removeAt(index);
  }

  deletePromoReward(index: number) {
    this.promoRewards_ref.removeAt(index);
  }

  get resourceMap() {
    return {
      Currency: 'currencies',
      Item: 'items',
      Titles: 'titles',
      SeedPackBox: 'seed-pack-box',
    };
  }

  onSubmit() {
    var { value } = this.achievementForm;

    // Fetch selected days using getSelectedDays method
    let selectedDays = this.getSelectedDays();

    // Check if the selectedDays array is not empty
    if (selectedDays.length > 0) {
      // If there are selected days, assign it to value.repeats
      value.repeats = selectedDays;
    } else {
      // If no days are selected, you might want to handle this case
      // For example, set value.repeats to null or an empty array
      value.repeats = []; // or null, depending on your requirements
    }

        // New validation: Check if isRepetitive is true and repeatsDuration or selected days are empty
        if (value.isRepetitive) {
          if (!value.repeatsDuration || value.repeats.length === 0) {
              this.messageService.add({
                  sticky: true,
                  severity: 'error',
                  summary: 'Repetitive Event Validation',
                  detail: 'If the achievement is Repetitive/Scheduled, both Repetitive Events Options of duration and available days must be filled.',
              });
              this.scroller.scrollToAnchor('repetitive-fields'); // Assuming you have a section to scroll to
              return;
          }
      }

    if (!value.target || value.target.length == 0) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Target(s) field',
        detail: 'Target(s) is required',
      });
      this.scroller.scrollToAnchor('target-field');
      return;
    }

    if (value.target && (!this.validationService.isArrayOfFloatNumbers(value.target))) {
      this.messageService.add({
        sticky: true,
        severity: 'error',
        summary: 'Values not a float number',
        detail: 'Target values must be float numbers.',
      });
      this.scroller.scrollToAnchor('target-field');
      return;
    }
    else {
      this.messageService.clear();
    }
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };


    this.formService
      .submitForm(value, this.submitUrl, this.isEditMode)
      .subscribe(
        (val) => {
          // adjust submit message for edit/new.
          if (this.isEditMode) {
            this.messageService.add({
              sticky: true,
              severity: 'success',
              summary: 'Update Successful',
              detail: `"${value.title}" was successfully updated`,
            });
            this.router.navigate([`/${this.key}/${val.id}`]);
          } else {
            this.messageService.add({
              sticky: true,
              severity: 'success',
              summary: 'Submit Successful',
              detail: `"${value.title}" was successfully created`,
            });
            this.router.navigate([`/${this.key}/${val.id}`]);
          }
        },
        (response) => {
          if (response.error) {
            response = response.error;
          }
          this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Submit Error',
            detail: response.message ? response.message : 'There was an error submitting.',
          });
          window.scrollTo(0, 0);
        },
        () => {
          console.log('The POST observable is now completed.');
        }
      );
  }

  async initForm() {
    const weekDayControls: Record<string, any[]> = {};
    this.weekDays.forEach(day => {
      // Check if the day is included in the existing values and set the control accordingly
      const isDaySelected = this.fields.repeats && this.fields.repeats.includes(day.value);
      weekDayControls[day.value] = [isDaySelected]; // Initialize each day based on existing values
    });

    this.achievementForm = this.fb.group({
      title: [this.fields.title, Validators.required],
      playerDescription: [this.fields.playerDescription, Validators.required],
      interval: [this.fields.interval],
      achievementType: [this.fields.achievementType, Validators.required],
      link_ref: [this.fields.link_ref],
      challengeType: [this.fields.challengeType],
      challengeClimate: [this.fields.challengeClimate],
      itemCategory: [this.fields.itemCategory],
      itemType: [this.fields.itemType],
      challengeResultScore: [this.fields.challengeResultScore],
      minimumIAPCost: [this.fields.minimumIAPCost],
      rewards_ref: this.fb.array(this.fields.rewards_ref),
      promoRewards_ref: this.fb.array(this.fields.promoRewards_ref),
      image: [this.fields.image],
      target: [this.fields.target],
      start: [this.fields.start],
      end: [this.fields.end],
      promoStartDate: [this.fields.promoStartDate],
      promoEndDate: [this.fields.promoEndDate],
      enabled: [this.fields.enabled, Validators.required],
      isConsecutive: [this.fields.isConsecutive],
      isRepetitive: [this.fields.isRepetitive],
      sortingOrder: [this.fields.sortingOrder],
      subText: [this.fields.subText],
      env: [this.fields.env],
      repeatsDuration: [this.fields.repeatsDuration],
      ...weekDayControls,
    });





    /**
     * set all field options for the form.
     */
    await this.setOptions();
  }

  clearField(fieldKey: string) {
    this.achievementForm.get(fieldKey)!.reset();
  }

  getSelectedDays() {
    return this.weekDays
      .filter(day => this.achievementForm.get(day.value)?.value)
      .map(day => day.value);
  }


  /**
   * Autocomplete searching
   *
   * @param e
   * @param fieldName
   * @param model
   * @param minimal
   */
  async getSuggestionsForRef(
    e: any,
    fieldName: string,
    model: string,
    minimal: boolean = false
  ) {
    this.dataService
      .getAllOfType(model, {
        query: isNaN(e) ? { name: { $regex: e, $options: 'i' } } : { id: e },
        select: '_id name id',
        virtuals: false,
        autopopulate: false,
        sort: { name: 1 },
      })
      .subscribe((result) => {
        if (minimal) {
          let o: any[] = [];
          for (const option of result) {
            o.push({ id: option.id, name: option.name, _id: option._id });
          }
          this.suggestions[fieldName] = o;
        } else {
          this.suggestions[fieldName] = result;
        }
      });
  }

  /**
   * Get/set options from a reference entity type.
   *
   * @param fieldName
   * @param model
   * @param minimal
   */
  async getOptionsFromRef(
    fieldName: string,
    model: string,
    minimal: boolean = false,
    autopopulate: boolean = true,
    addDefault: boolean = false
  ) {
    const options = await this.dataService.getAllOfTypeAsync(model, {
      query: {},
      autopopulate: autopopulate,
      virtuals: true,
      sort: { name: 1 },
    });
    if (minimal) {
      let o: any[] = [];
      // if(addDefault){
      //   o.push({id: 0, name: 'All', _id: 0});
      // }
      for (const option of options) {
        o.push({ id: option.id, name: option.name, _id: option._id, linkText: option.linkText});
      }
      this.options[fieldName] = o;
    } else {
      this.options[fieldName] = options;
    }
  }

  /**
   * Update a dependent field with the value of another field.
   * TODO: handle non arrays.
   *
   * @param e contains the value of the field the event was triggered on.
   * @param refField field object that triggered the event.
   * @param dependentField the field you want to update the value of
   * @param index index in the form array.
   */
  updateDependentField(
    e: any,
    refField: any,
    dependentField: string,
    index: number,
    countField: any
  ) {
    this.logger.log('refField', refField);
    this.logger.log('dep field', dependentField);
    this.logger.log('count field', countField);
    this.logger.log('e', e);
    if (e.value) {
      refField.parent.controls[dependentField]?.setValue(e.value.name);
      if (e.value.name == 'Titles') {
        countField.parent.controls['c']?.setValue(1);
      }
    } else {
      refField.parent.controls[dependentField]?.setValue('');
    }
  }

  /**
   * Handle events comming from upload completed
   * action comming from file uploader.
   *
   * @param response API response with cdn URL value.
   */
  async onFileUpload(response: any) {
    if (
      response &&
      response.originalEvent &&
      response.originalEvent.body &&
      response.originalEvent.body.result
    ) {
      let result = response.originalEvent.body.result;
      console.log('upload result: ', result);

      if (this.id || this.isEditMode) {
        await this.achievementService.updateAchievement(this.id, {
          image: result.cdnUrl,
        });
        // This is to prevent the value from being overwritten when a user submits the form after updating the image preview.
        this.achievementForm.get('image')?.setValue(result.cdnUrl);
      } else {
        this.achievementForm.get('image')?.setValue(result.cdnUrl);
      }
    }
  }

  /**
   * Validate if the target is a float number
   *
   * @param newValue Target value selected by the user
   */
  async validateTargetInput() {
    // Regular expression to match strictly integer or float values
    const numberRegex = /^-?\d+(\.\d+)?$/;

    // Retrieve the current array from the form control
    let currentValues = this.achievementForm.controls['target'].value || [];

    // Filter the array to remove elements that do not match the regex and convert them to numbers
    let validValues = currentValues.filter((value: any) => numberRegex.test(value.toString())).map(Number);

    // Check if the array is in ascending order
    let isAscending = validValues.every((val: number, i: number, arr: number[]) => i === 0 || (arr[i - 1] <= val));

    // If not ascending, adjust the validValues and show error message
    if (!isAscending) {
        // Sort the array in ascending order to automatically correct the input
        validValues.sort((a: number, b: number) => a - b);

        this.messageService.add({
            sticky: true,
            severity: 'error',
            summary: 'Error: Targets must be in ascending order',
            detail: 'The targets have been automatically sorted in ascending order.',
        });
    }

    // Update the form control with the possibly sorted array
    this.achievementForm.controls['target'].setValue(validValues);

    // Optionally, show a message if some values were removed due to being invalid
    if (currentValues.length > validValues.length) {
        this.messageService.add({
            sticky: true,
            severity: 'info',
            summary: 'Some values were removed',
            detail: 'Only integer or float numbers have been retained.',
        });
    }
}




  /**
   * Sets default date and time value if start/end date value is empty
   *
   * @param isStartDate Flag that sets whether or not is start date field
   */
  setDefaultTime(isStartDate: boolean) {
    if (isStartDate) {
      if (
        !this.achievementForm.value.start ||
        this.achievementForm.value.start.length <= 0
      ) {
        this.achievementForm.patchValue({
          start: this.utilitiesService.getCurrentDateAtMidnight(),
        });
      }
    } else {
      if (
        !this.achievementForm.value.end ||
        this.achievementForm.value.end.length <= 0
      ) {
        this.achievementForm.patchValue({
          end: this.utilitiesService.getCurrentDateAtMidnight(),
        });
      }
    }
  }
  /**
   * Handle the date change event when it is copy and pasted
   */
  handleDateInputChange(field: any, event: any) {
    const inputValue = event.target.value;
    const parsedDate = new Date(inputValue);

    if (inputValue && !inputValue.includes('0:00')) {
      this.achievementForm.controls[field].setValue(parsedDate);
      this.achievementForm.controls[field].markAsUntouched();
    }
  }

  updateCharacterCount(event: Event) {
    try {
      const textarea = event.target as HTMLTextAreaElement;
      this.characterCount = textarea.value.length;
    } catch (error) {
      console.error('Error occurred during character count update:', error);
      this.characterCount = 0;
    }
  }

  exceedsMaxCharacters(): boolean {
    return this.characterCount > 35;
  }

  getCharacterCount() {
    return this.characterCount;
  }

}
