import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MiscImageService 
{
  private readonly apiURLprefix : string = '/api/miscellaneous-build';
  constructor(private http: HttpClient) {}

  /**
   * Validates if Misc. Record path is unique
   * 
   * @param path Misc. Record path
   */
  async validateMiscRecordPath(path: string): Promise<{Success: boolean, IsOk: boolean}>
  {
    let response = await firstValueFrom(this.http.get<{Success: boolean, IsOk: boolean}>(`${this.apiURLprefix}/validate/path?path=${path}`));

    return response;
  }
}