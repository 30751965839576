import { AuthGuard } from "../auth/auth.guard";
import { AgGridScheduleTableComponent } from "../common/ag-grid-table/ag-grid-schedule-table/ag-grid-schedule-table.component";

export const contentScheduleRoutes = {
  prefix: 'content-schedule',
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: AgGridScheduleTableComponent,
        canActivate: [AuthGuard],
      },
    //   {
    //     path: `${this.prefix}/add`,
    //     component: AudioCollectionFormV3Component,
    //     canActivate: [AuthGuard],
    //   },
    //   {
    //     path: `${this.prefix}/:id`,
    //     component: AudioCollectionViewComponent,
    //     canActivate: [AuthGuard],
    //   },
    //   {
    //     path: `${this.prefix}/edit/:id`,
    //     component: AudioCollectionFormV3Component,
    //     canActivate: [AuthGuard],
    //   },
    ]
  }
};