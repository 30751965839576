import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LoggerService } from 'src/app/common/services/logger.service';
import { UserContextService } from 'src/app/common/services/user-context.service';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { TableDataComponent } from 'src/app/cms-v2/entities/table-data/components/table-data/table-data.component';
import { ItemService } from 'src/app/entities/item/services/item.service';
import { pathconfigValues } from 'src/app/entities/item/data/constants';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { DynamicTableComponent } from 'src/app/common/components/dynamic-table/dynamic-table.component';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';

@Component({
  selector: 'collection-table-view',
  templateUrl: './collection-table-view.component.html',
  styleUrls: ['./collection-table-view.component.sass'],
})
export class CollectionTableViewComponent
  extends TableDataComponent
  implements OnInit
{
  constructor(
    private entityViewService: EntityViewService,
    private dataService: DataService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private userContextService: UserContextService,
    private logger: LoggerService,
    private titleService: Title,
    private messageService: MessageService,
    private itemService: ItemService,
    private utilitiesService: UtilitiesService
  ) {
    super();
  }

  @ViewChild(DynamicTableComponent)
  dynamicTableComponent: DynamicTableComponent;
  // variables for path generation
  @Input() tableType: string;
  @Input() tableName: string;
  fileTypePath: string = '';
  fileTypeCode: string = '';
  selectedYearPath: string = '';
  fileNamePath: string = '';
  defaultYearPath: string = pathconfigValues.year;
  options: Array<any> = [];
  tmpFields: any = { prefab: null, thumbnail: null, fileName: null };
  customQuery: any = null;

  key: string = 'collections';

  id: number;
  entity: any;
  doc: any = {
    name: null,
    start: null,
    end: null,
    enabled: false,
  };

  currentTimeout: any;
  currentTimeout2: any;
  displayModal: boolean = false;
  invalidItems: Array<any> = [];

  /**
   * Sourcing Group Component Initialization
   */
  async ngOnInit() {
    this.isLoading = true;
    await this.setItemFileTypeOptions();

    this.globalFilters = [
      'id',
      'name',
      'category_ref.name',
      'type_ref.name',
      'thumbnail',
      'prefab',
      'batch_ref.name',
      'fileName',
      'commonName',
      'plantFamily',
      'botanicalName',
      'latinName',
      'cultivar',
      'height',
      'spread',
      'internalNotes',
      'referenceLinks',
      'buildStatus.text',
      'progressionLevel_ref.level',
      'vendorStatus',
    ];
    this.filtersToRegister = [
      ...this.defaultFilters,
      'stringIn',
      'stringIn_ref',
      'multiIn',
      'multiIn_ref',
      'complex',
      'inCollection',
    ];

    this.tableConfig.isEditMode = false;

    const routeParams = this.route.snapshot.paramMap;

    this.id = Number(routeParams.get('id'));

    await this.getExistingDoc().then(async (result) => {
      this.entity = result;
      this.customQuery = { 'id': { $in: this.entity.items } };
      this.logger.log('checking existing doc', this.entity);
      if (this.entity.name) {
        this.doc.name = this.entity.name;
      }

      if (this.entity.start) {
        this.doc.start = this.entity.start;
      }

      if (this.entity.end) {
        this.doc.end = new Date(this.entity.end);
      }

      if (this.entity.enabled) {
        this.doc.enabled = this.entity.enabled;
      }
    });


    this.fields = [
      ...this.defaultFields,
      {
        key: 'vendorStatus',
        name: 'Art Status',
        controlType: 'dropdown',
        options: [
          { label: 'Awaiting Batch', value: 'Awaiting Batch' },
          { label: 'Assigned', value: 'Assigned' },
          { label: 'Sent to Vendor', value: 'Sent to Vendor' },
          { label: 'Awaiting Revision', value: 'Awaiting Revision' },
          { label: 'Approved', value: 'Approved' },
        ],
        filterKey: 'vendorStatus',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'assetType',
        name: 'Asset Type',
        controlType: 'dropdown',
        options: [
          { label: 'Organics', value: 'Organics' },
          { label: 'Organic Recolors', value: 'Organic Recolors' },
          { label: 'Hard Surface', value: 'Hard Surface' },
          { label: 'HS Recolor', value: 'HS Recolor' },
        ],
        filterKey: 'assetType',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'batch_ref',
        name: 'Batch',
        controlType: 'dropdown_ref',
        apiController: 'batches',
        isOptionsMin: true,
        filterKey: 'batch_ref._id',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'blurb',
        name: 'Blurb',
        controlType: 'inputTextarea',
        filterKey: 'blurb',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'blurbStatus',
        name: 'Blurb Status',
        controlType: 'dropdown',
        options: [
          { label: 'Needs Blurb', value: 'Needs Blurb' },
          { label: 'Ready for Review', value: 'Ready for Review' },
          { label: 'Revision Needed', value: 'Revision Needed' },
          { label: 'Approved', value: 'Approved' },
        ],
        filterKey: 'blurbStatus',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'category_ref',
        name: 'Category',
        controlType: 'dropdown_ref',
        apiController: 'categories',
        filterKey: 'category_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        isOptionsMin: true,
      },
      {
        key: 'climates_ref',
        name: 'Climate(s)',
        controlType: 'multiSelect_ref',
        apiController: 'climates',
        filterKey: 'climates_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        isOptionsMin: true,
      },
      {
        key: 'collections',
        name: 'Collection(s)',
        controlType: 'autoComplete-multi_ref',
        apiController: 'collections',
        filterKey: 'id',
        matchMode: 'inCollection',
        filterProp: 'items',
        isColumn: false,
        isHidden: true,
        isInputField: false,
        isFilterField: true,
        isOptionsMin: false,
      },
      {
        key: 'colors_ref',
        name: 'Color(s)',
        controlType: 'multiSelect_ref',
        apiController: 'colors',
        filterKey: 'colors_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        isOptionsMin: true,
      },
      {
        key: 'contentHold',
        name: 'Content Hold',
        controlType: 'multiSelect',
        options: [
          'Prize Hold',
          'Series Hold',
          'New Arrivals Hold',
          'Bundle Hold',
          'Do Not Use',
        ],
        filterKey: 'contentHold',
        matchMode: 'multiIn',
        isColumn: true,
        isInputField: false,
        isFilterField: true,
      },
      {
        key: 'costs_ref',
        name: 'Cost(s)',
        controlType: 'lineItem',
        viewControl: {
          sortBy: 'c',
        },
        filterKey: 'costs_ref',
        matchMode: 'complex',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        subFields: [
          {
            key: 'lineItemType',
            name: 'Line Item Type',
            controlType: 'inputText',
            isHidden: true,
            presetValue: 'Currency',
          },
          {
            key: 't',
            name: 'Resource',
            controlType: 'dropdown_ref',
            apiController: 'resources',
            filterKey: 't',
            matchMode: 'stringIn_ref',
            isOptionsMin: true,
            isInputField: false,
            isFilterField: false,
            isHidden: true,
            presetValue: {
              key: 'name',
              value: 'Currency',
            },
          },
          {
            key: 'id',
            name: 'Currency',
            controlType: 'dropdown_ref',
            apiController: 'currencies',
            filterKey: 'id',
            matchMode: 'stringIn_ref',
            isOptionsMin: true,
            isFilterField: true,
            isInputField: true,
          },
          {
            key: 'c',
            name: 'Count',
            controlType: 'inputNumber',
            filterKey: 'c',
            matchMode: 'between',
            isFilterField: true,
            isInputField: true,
          },
        ],
      },
      {
        key: 'cultivar',
        name: 'Cultivar',
        controlType: 'inputText',
        filterKey: 'cultivar',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'dimensionX',
        name: 'Dimension (X)',
        controlType: 'inputNumber',
        filterKey: 'dimensionX',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'dimensionY',
        name: 'Dimension (Y)',
        controlType: 'inputNumber',
        filterKey: 'dimensionY',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'dimensionZ',
        name: 'Dimension (Z)',
        controlType: 'inputNumber',
        filterKey: 'dimensionZ',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'fileName',
        name: 'File Name',
        controlType: 'inputText',
        filterKey: 'fileName',
        matchMode: 'contains',
        isColumn: true,
        isFilterField: true,
        isInputField: true,
      },
      {
        key: 'flagged',
        name: 'Flagged',
        controlType: 'dropdown',
        options: [
          { label: 'In QA', value: 'In QA' },
          { label: 'Hold', value: 'Hold' },
          { label: 'Marked for Deletion', value: 'Marked for Deletion' },
        ],
        filterKey: 'flagged',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'height',
        name: 'Height',
        controlType: 'inputText',
        filterKey: 'height',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'internalNotes',
        name: 'Internal Notes',
        controlType: 'inputTextarea',
        filterKey: 'internalNotes',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'internalReferenceLinks',
        name: 'Internal Reference Links',
        controlType: 'ref-link',
        filterKey: 'internalReferenceLinks',
        matchMode: 'notEmpty',
        isColumn: true,
        isFilterField: true,
        isInputField: true,
        subFields: [
          {
            key: 'link',
            name: 'Link',
            controlType: 'inputText',
            filterKey: 'link',
            matchMode: 'contains',
            isInputField: true,
            isFilterField: false,
          },
        ],
      },
      {
        key: 'itemFileType_ref',
        name: 'File Type',
        controlType: 'dropdown_ref',
        apiController: 'item-file-types',
        isOptionsMin: true,
        filterKey: 'itemFileType_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'itemStatus',
        name: 'Content Status',
        controlType: 'dropdown',
        options: [
          { label: 'QA Ready', value: 'QA Ready' },
          { label: 'Awaiting Revision', value: 'Awaiting Revision' },
          { label: 'Approved', value: 'Approved' },
        ],
        filterKey: 'itemStatus',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'latinName',
        name: 'Latin Name',
        controlType: 'inputText',
        filterKey: 'latinName',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'materials_ref',
        name: 'Material(s)',
        controlType: 'multiSelect_ref',
        apiController: 'materials',
        isOptionsMin: true,
        filterKey: 'materials_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'patterns_ref',
        name: 'Pattern(s)',
        controlType: 'multiSelect_ref',
        apiController: 'patterns',
        isOptionsMin: true,
        filterKey: 'patterns_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'plantFamily',
        name: 'Plant Family',
        controlType: 'inputText',
        filterKey: 'plantFamily',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'prefab',
        name: 'Prefab Path',
        controlType: 'inputText',
        filterKey: 'prefab',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'progressionLevel_ref',
        name: 'Progression Level',
        controlType: 'dropdown_ref',
        apiController: 'progression-levels',
        isOptionsMin: true,
        filterKey: 'progressionLevel_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'radius',
        name: 'Radius',
        controlType: 'inputNumber',
        filterKey: 'radius',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      // {
      //   key: 'recolorSource',
      //   name: 'Recolor Source',
      //   controlType: 'dropdown',
      //   options: [
      //     { label: 'Internal', value: 'Internal' },
      //     { label: 'External', value: 'External' },
      //   ],
      //   filterKey: 'recolorSource',
      //   matchMode: 'stringIn',
      //   isColumn: true,
      //   isInputField: true,
      //   isFilterField: true,
      // },
      {
        key: 'referenceLinks',
        name: 'Vendor Reference Links',
        controlType: 'ref-link',
        filterKey: 'referenceLinks',
        matchMode: 'notEmpty',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        subFields: [
          {
            key: 'link',
            name: 'Link',
            controlType: 'inputText',
            filterKey: 'link',
            matchMode: 'contains',
            isFilterField: false,
            isInputField: true,
          },
        ],
      },
      {
        key: 'shape_ref',
        name: 'Shape',
        controlType: 'dropdown_ref',
        apiController: 'shapes',
        isOptionsMin: true,
        filterKey: 'shape_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'spread',
        name: 'Spread',
        controlType: 'inputText',
        filterKey: 'spread',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'spruceDataStatus',
        name: 'Spruce Data Status',
        controlType: 'toggle',
        filterKey: 'spruceDataStatus',
        matchMode: 'equals',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'styles_ref',
        name: 'Style(s)',
        controlType: 'multiSelect_ref',
        apiController: 'styles',
        isOptionsMin: true,
        filterKey: 'styles_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'subType',
        name: 'Sub-Type',
        controlType: 'inputText',
        filterKey: 'subType',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'tags_ref',
        name: 'Tags(s)',
        controlType: 'multiSelect_ref',
        apiController: 'tags',
        filterKey: 'tags_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
        isOptionsMin: true,
      },
      {
        key: 'thumbnail_url_128',
        name: 'Thumbnail',
        controlType: 'image',
        isColumn: true,
        isInputField: false,
        isFilterField: false,
      },
      {
        key: 'thumbnail',
        name: 'Thumbnail Path',
        controlType: 'inputText',
        filterKey: 'thumbnail',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'traits_ref',
        name: 'Trait(s)',
        controlType: 'multiSelect_ref',
        apiController: 'traits',
        isOptionsMin: true,
        filterKey: 'traits_ref',
        matchMode: 'multiIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'type_ref',
        name: 'Type',
        controlType: 'dropdown_ref',
        apiController: 'item-types',
        isOptionsMin: true,
        filterKey: 'type_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendor_ref',
        name: 'Vendor',
        controlType: 'dropdown_ref',
        apiController: 'vendors',
        isOptionsMin: true,
        filterKey: 'vendor_ref',
        matchMode: 'stringIn_ref',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorDimensions',
        name: 'Vendor Dimensions',
        controlType: 'inputText',
        filterKey: 'vendorDimensions',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorNotes',
        name: 'Vendor Notes',
        controlType: 'inputTextarea',
        filterKey: 'vendorNotes',
        matchMode: 'contains',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'year',
        name: 'Year',
        controlType: 'dropdown',
        options: [
          { label: '2021', value: '2021' },
          { label: '2022', value: '2022' },
          { label: '2023', value: '2023' },
          { label: '2024', value: '2024' },
          { label: '2025', value: '2025' },
        ],
        filterKey: 'year',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorHeight',
        name: 'Vendor Height',
        controlType: 'inputText',
        filterKey: 'vendorHeight',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorWidth',
        name: 'Vendor Width',
        controlType: 'inputText',
        filterKey: 'vendorWidth',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'vendorLength',
        name: 'Vendor Spread',
        controlType: 'inputText',
        filterKey: 'vendorLength',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'promoted',
        name: 'Uploaded',
        controlType: 'toggle',
        filterKey: 'promoted',
        matchMode: 'equals',
        isColumn: true,
        isInputField: false,
        isFilterField: true,
      },
      {
        key: 'archived',
        name: 'Archived',
        controlType: 'toggle',
        filterKey: 'archived',
        matchMode: 'equals',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'isAssetUpToDate',
        name: 'Promoted Status',
        controlType: 'dropdown',
        options: [
          { label: 'No data', value: 'No data' },
          { label: 'Outdated', value: 'Outdated' },
          { label: 'Up to date', value: 'Up to date' },
        ],
        filterKey: 'isAssetUpToDate',
        matchMode: 'stringIn',
        isColumn: true,
        isInputField: false,
        isFilterField: true,
      },
      {
        key: 'reReleaseStart',
        name: 'Re Release Start',
        controlType: 'date',
        filterKey: 'reReleaseStart',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
      {
        key: 'reReleaseEnd',
        name: 'Re Release End',
        controlType: 'date',
        filterKey: 'reReleaseEnd',
        matchMode: 'between',
        isColumn: true,
        isInputField: true,
        isFilterField: true,
      },
    ];
    this.defaultColumnOrder = [
      // not editable
      'id',
      'thumbnail_url_128',
      'fileName',

      // main card
      'name',
      'cultivar',
      'plantFamily',
      'latinName',

      // 'Required - Production'
      'prefab',
      'thumbnail',
      'vendorDimensions',

      'itemFileType_ref',
      'assetType',
      'year',
      'vendor_ref',
      'batch_ref',
      'collections',

      'vendorStatus',
      'flagged',
      // 'recolorSource',

      // 'Vendor Notes'
      'vendorNotes',

      // 'Content'
      'climates_ref',
      'category_ref',
      'type_ref',
      'traits_ref',
      'colors_ref',
      'start',
      'end',
      'reReleaseStart',
      'reReleaseEnd',

      'itemStatus',
      'contentHold',
      'materials_ref',
      'patterns_ref',
      'styles_ref',
      'tags_ref',
      'shape_ref',
      'progressionLevel_ref',
      'costs_ref',

      // 'Internal Notes'
      'internalNotes',

      // 'Dev'
      'dimensionX',
      'dimensionY',
      'dimensionZ',
      'radius',

      // 'Info Blurb'
      'blurbStatus',
      'blurb',

      // 'Reference'
      'height',
      'spread',

      'internalReferenceLinks',
      'referenceLinks',

      // not editable
      'enabled',
      'promoted',
      'subType',
      'spruceDataStatus',
      'isAssetUpToDate',
      'vendorHeight',
      'vendorWidth',
      'vendorLength',
      'archived',
    ];
    this.customGlobalColumnSets = [
      {
        name: 'Default',
        value: ['id', 'name', 'start', 'end', 'enabled', 'archived'],
      },
    ];
    this.tableConfig.assetType = AssetTypes.Item;
    this.tableConfig.defaultColumns = ['fileName', 'itemStatus', 'vendorStatus', 'start', 'thumbnail', 'prefab', 'thumbnail_ref', 'prefab_ref'];
    this.tableConfig.customFilterToRegister = this.filtersToRegister;
    await this.getExistingDoc();
    this.isLoading = false;
  }

  /**
   * Retrieves existing sourcing group reecord
   */
  async getExistingDoc() {
    const doc = await this.dataService.getDocumentAsync(this.key, {
      query: { id: this.id },
      autopopulate: true,
      virtuals: true,
    });
    return doc;
  }

  /**
   * Copies the ID, field and error message of a row in the validation report
   * @param row Row to be copied
   */
  copyMessage(row: any) {
    let stringBuild =
      'Item ID: ' +
      row.Entity.id +
      '   ' +
      'Field: ' +
      row.Field +
      '   ' +
      'Error Message: ' +
      row.ErrorMessage;
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = stringBuild;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.messageService.add({
      sticky: true,
      severity: 'success',
      summary: 'Row Copied',
      detail: `Row copied to clipboard successfully!`,
    });
  }
  /**
   * Set Item File Type Options
   */
  async setItemFileTypeOptions() {
    await this.utilitiesService.getOptionsFromRef(
      this.options,
      'itemFileType_ref',
      'item-file-types',
      false
    );
  }
}
