import { Component, OnInit } from '@angular/core';
import { constants } from './constants/constants';
import { BuildType } from 'src/app/enums/build-type';
import { ImagerySettingsService } from 'src/app/entities/settings/services/imagery-settings.service';
import { Title } from '@angular/platform-browser';
import { UtilitiesService } from 'src/app/common/services/utilities.service';
import { LoggerService } from 'src/app/common/services/logger.service';
import { FormService } from 'src/app/services/form.service';
import { DataService } from 'src/app/services/data.service';
import { EntityViewService } from 'src/app/services/entity-view.service';
import { EntityTypeSettingsService } from 'src/app/entities/settings/services/entity-type-settings.service';

@Component({
  selector: 'create-new-asset',
  templateUrl: './create-new-asset.component.html',
  styleUrls: ['./create-new-asset.component.sass']
})
export class CreateNewAssetComponent implements OnInit {

  constants: any = constants;
  pageTitle: string = 'Assets Table';
  section1Title: string = 'Build New Asset';
  section2Title: string = 'Build Records History';
  path: string;
  name: string;
  entity: any = {
    id: null,
    name: '',
    entityType: null,
    imageType: null,
    path: '',
  };
  type: string = 'miscellaneous-build';
  isValidPath: boolean = false;
  approvedAssetsPrefix: string;
  showBuildButton: boolean;
  submitUrl: string;
  isLoading: boolean;
  
  imageTypeSelection: any[] = [
    {name:'Feed Image', value:'Feed Image'},
    {name:'Icon', value:'Icon'}, 
    {name:'Inbox',value:'Inbox'}, 
    {name:'Loading Screen',value:'Loading Screen'}, 
    {name:'Spruce',value:'Spruce'},
    {name:'Thumbnail',value:'Thumbnail'}
  ]
    ;
  
  tabSelection:any;
  selectedTabSelection:any;
  
  selectedImageTypeSelection: any[];
  selectedEntityTypeSelection: any[];
  customQuery: any = null;

  // Dialog for Add Assets Variables

  displayCreateNewAsset: boolean = false;


  get buildType(): typeof BuildType {
    return BuildType;
  }
  imagerySettings: Array<any> = [];
  selectedType: string | null = null;

  entityTypeSettings: Array<any> = [];
  selectedEntityType: string | null = null;

  constructor(
    private entityViewService: EntityViewService,
    private dataService: DataService,
    private formService: FormService,
    private loggerService: LoggerService,
    private titleService: Title,
    private utilitiesService: UtilitiesService,
    private imagerySettingsService: ImagerySettingsService,
    private entityTypeSettingsService: EntityTypeSettingsService,
  ) {}

  async ngOnInit() {

    this.entity.id = -999999;
    await this.setMiscPath();
    this.submitUrl = 'miscellaneous-build/add';
    this.showBuildButton = false;
    this.approvedAssetsPrefix = 'approved_assets/';

    this.isLoading = true;

    await this.getImagerySettings();

    this.tabSelection = [
      {label: 'Other', value: 'Other'},
      {label: 'Icon', value: 'Icon'},
      {label: 'Inbox', value: 'Inbox'},
      {label: 'Loading Screen', value: 'Loading Screen'},
      {label: 'Spruce', value: 'Spruce'},
      {label: 'Thumbnail', value: 'Thumbnail'},
      {label: 'Item Asset', value: 'Item Asset'},
      {label: 'Feed Image', value: 'Feed Image'},
  ];
  
  this.isLoading = false;
}

  async getImagerySettings()
  {
    let response = await this.imagerySettingsService.getAllImagerySettingsWithQuery( { query: { showOnImageTypeList : true } });

    if(response && response.length > 0)
    {
      this.imagerySettings = response;
    }
  }

  async getEntityTypeSettings()
  {
    let response = await this.entityTypeSettingsService.getAllEntityTypeSettings();

    if(response && response.length > 0)
    {
      this.entityTypeSettings = response;
    }
  }
  

  async buildPath(event: any) 
  {
    let pathInput = '';
    if(event.isSelectChange)
    {
      pathInput = event.value.path;
    }
    else
    {
      pathInput = event.value;
    }
    if (this.validatePath(pathInput)) {
      this.entity.path = this.approvedAssetsPrefix + pathInput;
      this.isValidPath = true;
    } else {
      this.isValidPath = false;
    }
  }
  
  validatePath(path: string) {
    if(path && path.length > 0)
    {
      let foundProtected = this.constants.protectedPaths.find(
        (v: string) => path == v
      );
      let isValidFolder = path.substr(-1) !== '/';

      console.log('foundProtected: ', foundProtected);
      console.log('isValidFolder: ', isValidFolder);

      if (!foundProtected && isValidFolder) {
        return true;
      } else {
        return false;
      }
    }
    else
    {
      return false;
    }
  }

  async refreshData(e: any) {
    if (e) {
      setTimeout(() =>
      {
        this.utilitiesService.refreshComponent('miscellaneous-build');
      }, 500);
    } else {
      this.loggerService.log('Error in build event callback', e);
    }
  }

  preBuildAction() {
    let newMiscRecord = {
      path: this.path,
      name: this.name,
      enabled: true,
    };
    this.formService
      .submitForm(newMiscRecord, this.submitUrl, false)
      .subscribe(async (val) => {
        console.log('response! ', val);
      });
    this.showBuildButton = true;
  }

  async getBuildData() {
    if (this.entity.buildData) {
      this.entity.buildData = await this.dataService.getBuildData(
        this.entity.buildData
      );
      this.entity.buildStatus = await this.entityViewService.getBuildStatus(
        this.entity.buildData
      );
    } else {
      this.entity.buildData = [];
      this.entity.buildStatus = {
        text: 'Build Status Not Found',
        date: '',
        color: 'var(--gray-400)',
        buildCount: 0,
      };
    }
  }

  getUpdatedDocument(e: any) {
    setTimeout(async () => {
      await this.entityViewService
        .getEntity(this.type, { query: { id: this.entity.id } })
        .then(async (data: any) => {
          console.log('doc', data);
          this.entity = data;
          if (this.entity.name) {
            this.titleService.setTitle(this.entity.name);
          }

          // get build data.
          await this.getBuildData();
          console.log('got updated entity', this.entity);
        });
    }, 1000);
  }

    /**
   * Sets Misc. default path
   */
    async setMiscPath() {
      let response = await this.entityViewService.getEntity('ImagerySettings', {
        query: { entityType: 'miscImages' },
      });
  
      if (
        response &&
        response.enabled &&
        response.path &&
        response.path.length > 0
      ) {
        this.path = this.selectedType = response.path;
      }
    }

      /**
   * Handle changes type selection
   *
   * @param event Type selected.
   */
  changePathPrefix(event: any)
  {
    if(event && event.value)
    {
      this.path = event.value;
    }
  }

  showDialog() {
    this.displayCreateNewAsset = true;
}

/**
 * createNewAsset
 * 
 * This function controls the creation of new asset in the asset table. Along with refreshing the page
 */

async createNewAsset(){

  await this.dataService.addNewRecord(this.type, {
    name: this.entity.name,
    enabled: false,
    path: this.entity.path,
    entityType: this.entity.entityType,
    imageType: this.entity.imageType,
  })

  this.utilitiesService.refreshComponent('miscellaneous-build');

  this.displayCreateNewAsset = false;
}

}
