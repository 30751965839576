import { Component, OnInit } from '@angular/core';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import SeedPacksDTO from '../dtos/SeedPacksDTO';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BuildType } from 'src/app/enums/build-type';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';

@Component({
  selector: 'app-seed-pack-view',
  templateUrl: './seed-pack-view.component.html',
  styleUrls: ['./seed-pack-view.component.sass']
})
export class SeedPackViewComponent extends BaseDynamicViewComponent implements OnInit {


  seedPackRecord: BaseEntityDto = new BaseEntityDto();
  constructor() {
    super();
  }

  ngOnInit() {
    {
      this.fields =
      [
        {
          title: "Seed Packs Data",
          fields:
          [
            {
              name: "Seed Pack Name",
              inputType: DynamicViewType.BaseViewField , 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'name',
              isRequired: true,
              label: 'Seed Pack Name'
            },
            {
              name: "Nurture Display Name",
              inputType: DynamicViewType.BaseViewField , 
              viewTypes: [BaseViewFieldType.Text],
              clearField: true,
              key: 'displayName',
              isRequired: true,
              label: 'Nurture Display Name'
            },
            {
              name: "Start Date/Time",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'start',
              label: 'Start Date/Time',
              columnWidth: 6, 
            },
            {
              name: "End Date/Time",
              inputType: DynamicViewType.BaseViewField,
              viewTypes: [BaseViewFieldType.Calendar],
              clearField: true,
              key: 'end',
              label: 'End Date/Time',
              columnWidth: 6
            },
            {
              name: "Opened Image",
              inputType: DynamicViewType.MiscImageRecordV2,
              viewMode: 'record',
              clearField: true,
              key: 'opened_image_ref',
              label: 'Opened Image',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: { fieldName: 'opened_image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
            },
            {
              name: "Closed Image",
              inputType: DynamicViewType.MiscImageRecordV2,
              viewMode: 'record',
              clearField: true,
              key: 'closed_image_ref',
              label: 'Closed Image',
              optionValue:'_id',
              filter: true,
              filterBy:'name,id,path',
              autoDisplayFirst: false,
              options: { fieldName: 'closed_image_ref', apiController: 'miscellaneous-build', customQuery: { entityType: BuildType.Images }, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id' }
            },
            {
              name: "Nurture",
              inputType: DynamicViewType.AutoComplete,
              clearField: true,
              key: 'nurture_ref',
              label: 'Nurture'
            }
          ],
          type: DynamicCardType.Card
        }
      ]

      this.seedPackRecord =
      {
        enabled: false,
      };
      this.title = "Seed Packs";
      this.viewRoute = 'seed-pack';
      this.isLoading = false;
    }
  }
}
