import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import NurtureDTO from '../../dtos/NurtureDTO';
import NurtureStageDTO from '../../dtos/NurtureStageDTO';
import * as _ from 'lodash';

@Component({
  selector: 'nurture-stage-custom-field',
  templateUrl: './nurture-stage-custom-field.component.html',
  styleUrls: ['./nurture-stage-custom-field.component.sass']
})
export class NurtureCustomFieldComponent implements OnInit
{
  @Input() nurtureRecord: NurtureDTO = new NurtureDTO();
  @Input() isEdit: boolean = false;
  @Input() miscImageOptions: Array<any>;
  @Input() stages: any;
  @Input() isLoading: boolean;
  @Output() onChange = new EventEmitter<any>();
  nurtureStage: NurtureStageDTO = new NurtureStageDTO();

  constructor() { }

  /**
   * Nurture Stage Custom Field Component Initialization
   */
  ngOnInit()
  {

    for(let i = 0; i < this.stages.length; i++)
    {
      this.stages[i].image_ref = this.stages[i].image_ref && this.stages[i].image_ref._id ? this.stages[i].image_ref._id : null;
    }
  }

  /**
   * Handle Record Changes from child component.
   *
   * @param field Field that changed.
   * @param event Event value.
   */
  onRecordChange(event: any)
  {
    this.onChange.emit(event);
  }
  /**
   * Handle Record Changes from child component.
   *
   * @param field Field that changed.
   * @param event Event value.
   */
  pushNewStage(event: any)
  {
    let nurtureStage = new NurtureStageDTO();
    if (this.stages && (this.stages.length >= 2 && this.stages.length < 4)) {
      nurtureStage.costs_ref[0].c = 2;
    } else if (this.stages && this.stages.length >= 4) {
      nurtureStage.costs_ref[0].c = 3;
    }
    this.stages.push(nurtureStage);
    this.onChange.emit(event);

  }
}
