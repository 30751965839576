<div class="p-grid p-mx-5" style="margin: -20px auto" *ngIf="!isLoading">
    <!-- 'New Free Track' - Right column -->
    <div class="p-col-6 p-pr-4">
        <div style="text-align: center;">
            <button
                *ngIf="!createNewFreeTrack"
                pButton
                pRipple
                type="submit"
                label="Create New Free Track"
                class="p-mb-4"
                [style]="{ 'min-width': '18.5em' }"
                icon="pi pi-plus" iconPos="right"
                (click)="onCreateRewardsTrack(true)"
            ></button>
            <button
                *ngIf="createNewFreeTrack"
                pButton
                pRipple
                type="submit"
                label="Cancel"
                class="p-mb-4 p-button-warning"
                [style]="{ 'min-width': '18.5em' }"
                (click)="onCancelRewardsTrack(true);"
            ></button>
        </div>
    </div>
    <!-- 'New Paid Track' - Right column -->
    <div class="p-col-6 p-pl-4">
        <div style="text-align: center;">
            <button
                *ngIf="!createNewPaidTrack"
                pButton
                pRipple
                type="submit"
                label="Create New Paid Track"
                class="p-mb-4"
                [style]="{ 'min-width': '18.5em' }"
                icon="pi pi-plus" iconPos="right"
                (click)="onCreateRewardsTrack(false)"
            ></button>
            <button
                *ngIf="createNewPaidTrack"
                pButton
                pRipple
                type="submit"
                label="Cancel"
                class="p-mb-4 p-button-warning"
                [style]="{ 'min-width': '18.5em' }"
                (click)="onCancelRewardsTrack(false);"
            ></button>
        </div>
    </div>
    <!-- 'Free Track' - Right column -->
    <div class="p-col-6 p-pr-4">
        <season-pass-tracks-field *ngIf="isEdit && seasonPassRecord.free && freeTrack && freeTrack.free && !createNewFreeTrack" [(seasonPassRecord)]="freeTrack" [isFreeTrack]="true" (onChange)="onRecordChange('freeObject', $event)"></season-pass-tracks-field>
        <rewards-track-form-v3 *ngIf="createNewFreeTrack" [(rewardsTrackRecord)]="freeTrack" [isChildComponent]="true" [disableTypeField]="true" (onRewardsTrackRecordChange)="onRecordChange('freeObject', $event)"></rewards-track-form-v3>
    </div>
    <!-- 'Paid Track' - Right column -->
    <div class="p-col-6 p-pl-4">
        <season-pass-tracks-field *ngIf="isEdit && seasonPassRecord.paid && paidTrack && paidTrack.paid && !createNewPaidTrack" [(seasonPassRecord)]="paidTrack" [isFreeTrack]="false" (onChange)="onRecordChange('paidObject', $event)"></season-pass-tracks-field>
        <rewards-track-form-v3 *ngIf="createNewPaidTrack" [(rewardsTrackRecord)]="paidTrack" [isChildComponent]="true" [disableTypeField]="true" (onRewardsTrackRecordChange)="onRecordChange('paidObject', $event)"></rewards-track-form-v3>
    </div>
</div>