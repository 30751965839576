<div class="p-d-flex p-ai-center p-jc-center" style="box-sizing: border-box;">
  <ng-container>
    <div class="p-grid" style="margin-left: 10px;">
      <!-- First row for status and count -->
      <div class="p-col-12 p-p-0" style="height: 30px; min-width: 125px; margin-top:10px; box-sizing: border-box;" >
        <span class="p-d-flex" *ngIf="buildStatus">
          <strong [ngStyle]="{ color: buildStatus.color, 'font-size': '0.9rem' }">{{ buildStatus.text }}</strong>
          <div
            *ngIf="buildStatus.buildCount > 0"
            class="p-ml-2"
            style="
              height: 21px;
              width: 21px;
              background-color: var(--text-color);
              border-radius: 50%;
              font-weight: 700;
              line-height: 21px;
              font-size: 0.9rem;
              color: var(--primary-color-text);
              text-align: center;
              box-sizing: border-box;
              margin-top: 5px;
            "
          >
            <strong class="p-m-auto">
              {{ buildStatus.buildCount }}
            </strong>
          </div>
        </span>
      </div>
      <!-- Second row for the date -->
      <div class="p-col-12 p-p-0" style="margin-top: -12px; box-sizing: border-box;" *ngIf="buildStatus && buildStatus.buildCount > 0">
        {{ buildStatus.date }}
      </div>
    </div>
  </ng-container>
</div>
