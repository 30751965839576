<!-- Header-->
<div class="p-grid p-mx-1">
  <div class="p-col-6 p-pr-4">
    <div style="text-align: center;">
      <h3>{{type}} Track</h3>
    </div>
  </div>
  <div class="p-col-1 p-pr-4">
    <button pButton pRipple type="button" label="Add Stage +" class="p-button-raised p-button-success p-button-text"
      (click)="addStage(); onRecordChange('stageMilestone'); onRecordChange('paid'); onRecordChange('free'); onRecordChange('friends'); onRecordChange('coop'); onRecordChange('miniGamesRewards'); onRecordChange('seasonalPrizes');">
    </button>
  </div>
</div>
<div *ngIf="isFreeTrack && !isFriendsTrack">
  <p-orderList [value]="seasonPassRecord.free" (onReorder)="assignStages(seasonPassRecord.free)"
    [listStyle]="{ 'max-height': seasonPassRecord.free.length > 2 ? '200rem' : '50rem' }" header="Reward Track"
    [dragdrop]="true">
    <ng-template let-day let-i="index" pTemplate="item">
      <div class="flex justify-items-center p-2 w-full flex-wrap p-grid">
        <div style="float: left;" class="p-col-11">
          <prizes-card-form-group [(prize)]="seasonPassRecord.free[i]" [showPrize]="true"
            fieldsetName="Free Stage {{ i + 1 }}" [excludeResourceTypes]="null"
            (onPrizeChange)="onRecordChange('free'); seasonPassRecord.free[i] = $event;">
          </prizes-card-form-group>
        </div>
        <div class="p-col-1">
          <button pButton type="button" icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-sm p-button-text"
            (click)="removeStage(i); onRecordChange('stageMilestone'); onRecordChange('paid'); onRecordChange('free'); onRecordChange('friends'); onRecordChange('coop');"></button>
        </div>
        <div class="p-col-12">
          <div>
            <h4> {{ isFriendsTrack ? "Friends Invited" : "Challenge Submissions" }}</h4>
            <span>
              <p-inputNumber [(ngModel)]="seasonPassRecord.stageMilestone[i]" mode="decimal" inputId="minmax-buttons"
                [min]="0" (onInput)="onRecordChange('stageMilestone')">
              </p-inputNumber>
            </span>
            <h4> Move To:</h4>
            <span>
              <p-dropdown (onChange)="moveItemInArray(seasonPassRecord.free, i, $event.value - 1, 'free')"
                [options]="createNumberRange(0, stages.length)" [autoDisplayFirst]="false" appendTo="body"
                [style]="{ 'max-width': '12em' }"></p-dropdown>
            </span>
          </div>
        </div>

      </div>

    </ng-template>
  </p-orderList>
</div>
<div *ngIf="!isFreeTrack && !isFriendsTrack && !isCoopTrack && !isMiniGameTrack && !isSeasonalPrizesTrack">
  <p-orderList [value]="seasonPassRecord.paid" (onReorder)="assignStages(seasonPassRecord.paid)"
    [listStyle]="{ 'max-height': seasonPassRecord.paid.length > 2 ? '200rem' : '50rem' }" header="Reward Track"
    [dragdrop]="true">
    <ng-template let-day let-i="index" pTemplate="item">
      <div class="flex justify-items-center p-2 w-full flex-wrap p-grid">
        <div style="float: left;" class="p-col-11">
          <prizes-card-form-group [(prize)]="seasonPassRecord.paid[i]" [showPrize]="true"
            fieldsetName="Paid Stage {{ i + 1 }}" [excludeResourceTypes]="null"
            (onPrizeChange)="onRecordChange('paid'); seasonPassRecord.paid[i] = $event;">
          </prizes-card-form-group>
        </div>
        <div class="p-col-1">
          <button pButton type="button" icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-sm p-button-text"
            (click)="removeStage(i); onRecordChange('stageMilestone'); onRecordChange('paid'); onRecordChange('free'); onRecordChange('friends'); onRecordChange('coop');"></button>
        </div>
        <div class="p-col-10">
          <div>
            <h4> {{ isFriendsTrack ? "Friends Invited" : "Challenge Submissions" }}</h4>
            <span style="width: 100%;">
              <p-inputNumber [(ngModel)]="seasonPassRecord.stageMilestone[i]" mode="decimal" inputId="minmax-buttons"
                [min]="0" (onInput)="onRecordChange('stageMilestone')">
              </p-inputNumber>
            </span>
            <h4> Move To:</h4>
            <span style="width: 100%;">
              <p-dropdown (onChange)="moveItemInArray(seasonPassRecord.paid, i, $event.value - 1, 'paid')"
                [options]="createNumberRange(0, stages.length)" [autoDisplayFirst]="false"
                [style]="{ 'max-width': '12em' }"></p-dropdown>
            </span>
          </div>
        </div>

      </div>

    </ng-template>
  </p-orderList>
</div>
<div *ngIf="isFriendsTrack && seasonPassRecord.friends">
  <p-orderList [value]="seasonPassRecord.friends" (onReorder)="assignStages(seasonPassRecord.friends)"
    [listStyle]="{ 'max-height': seasonPassRecord?.friends?.length > 2 ? '200rem' : '50rem' }" header="Reward Track"
    [dragdrop]="true">
    <ng-template let-day let-i="index" pTemplate="item">
      <div class="flex justify-items-center p-2 w-full flex-wrap p-grid">
        <div style="float: left;" class="p-col-6">
          <prizes-card-form-group [(prize)]="seasonPassRecord.friends[i]" [showPrize]="true"
            fieldsetName="Friends Stage {{ i + 1 }}" [excludeResourceTypes]="null"
            (onPrizeChange)="onRecordChange('friends'); seasonPassRecord.friends[i] = $event;">
          </prizes-card-form-group>
        </div>
        <div class="p-col-5">
          <h4> {{ isFriendsTrack ? "Friends Invited" : "Challenge Submissions" }}</h4>
          <span style="width: 100%;">
            <p-inputNumber [(ngModel)]="seasonPassRecord.stageMilestone[i]" mode="decimal" inputId="minmax-buttons"
              [min]="0" (onInput)="onRecordChange('stageMilestone')">
            </p-inputNumber>
          </span>
          <h4> Move To:</h4>
          <span style="width: 100%;">
            <p-dropdown (onChange)="moveItemInArray(seasonPassRecord.friends, i, $event.value - 1, 'friends')"
              [options]="createNumberRange(0, stages.length)" [autoDisplayFirst]="false"
              [style]="{ 'max-width': '12em' }"></p-dropdown>
          </span>
        </div>
        <div class="p-col-1">
          <button pButton type="button" icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-sm p-button-text"
            (click)="removeStage(i); onRecordChange('stageMilestone'); onRecordChange('friends');"></button>
        </div>
      </div>

    </ng-template>
  </p-orderList>
</div>
<div *ngIf="isCoopTrack && !isFriendsTrack && !isFreeTrack && seasonPassRecord.coop">
  <p-orderList [value]="seasonPassRecord.coop" (onReorder)="assignStages(seasonPassRecord.coop)"
    [listStyle]="{ 'max-height': seasonPassRecord.friends.length > 2 ? '200rem' : '50rem' }" header="Reward Track"
    [dragdrop]="true">
    <ng-template let-day let-i="index" pTemplate="item">
      <div class="flex justify-items-center p-2 w-full flex-wrap p-grid">
        <div style="float: left;" class="p-col-6">
          <prizes-card-form-group [(prize)]="seasonPassRecord.coop[i]" [showPrize]="true"
            fieldsetName="Co-Op Stage {{ i + 1 }}" [excludeResourceTypes]="null"
            (onPrizeChange)="onRecordChange('coop'); seasonPassRecord.coop[i] = $event;">
          </prizes-card-form-group>
        </div>
        <div class="p-col-5">
          <h4> {{ "Reward Target" }}</h4>
          <span style="width: 100%;">
            <p-inputNumber [(ngModel)]="seasonPassRecord.stageMilestone[i]" mode="decimal" inputId="minmax-buttons"
              [min]="0" (onInput)="onRecordChange('stageMilestone')">
            </p-inputNumber>
          </span>
          <h4> Move To:</h4>
          <span style="width: 100%;">
            <p-dropdown (onChange)="moveItemInArray(seasonPassRecord.coop, i, $event.value - 1, 'coop')"
              [options]="createNumberRange(0, stages.length)" [autoDisplayFirst]="false"
              [style]="{ 'max-width': '12em' }"></p-dropdown>
          </span>
        </div>
        <div class="p-col-1">
          <button pButton type="button" icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-sm p-button-text"
            (click)="removeStage(i); onRecordChange('coop');"></button>
        </div>
      </div>

    </ng-template>
  </p-orderList>
</div>
<div *ngIf="isMiniGameTrack">
  <p-orderList [value]="seasonPassRecord.miniGamesRewards" (onReorder)="assignStages(seasonPassRecord.miniGamesRewards)"
    [listStyle]="{ 'max-height': seasonPassRecord.miniGamesRewards.length > 2 ? '200rem' : '50rem' }" header="Reward Track"
    [dragdrop]="true">
    <ng-template let-day let-i="index" pTemplate="item">
      <div class="flex justify-items-center p-2 w-full flex-wrap p-grid">
        <div style="float: left;" class="p-col-11">
          <prizes-card-form-group [(prize)]="seasonPassRecord.miniGamesRewards[i]" [showPrize]="true"
            fieldsetName="Mini Games Stage {{ i + 1 }}" [excludeResourceTypes]="null" version="3"
            (onPrizeChange)="onRecordChange('miniGamesRewards'); seasonPassRecord.miniGamesRewards[i] = $event;">
          </prizes-card-form-group>
        </div>
        <div class="p-col-1">
          <button pButton type="button" icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-sm p-button-text"
            (click)="removeStage(i); onRecordChange('miniGamesRewards');"></button>
        </div>
        <!-- <div class="p-col-12">
          <div>
            <h4> Move To:</h4>
            <span>
              <p-dropdown (onChange)="moveItemInArray(seasonPassRecord.miniGamesRewards, i, $event.value - 1, 'miniGamesRewards')"
                [options]="createNumberRange(0, stages.length)" [autoDisplayFirst]="false" appendTo="body"
                [style]="{ 'max-width': '12em' }"></p-dropdown>
            </span>
          </div>
        </div> -->
      </div>

    </ng-template>
  </p-orderList>
</div>

<div *ngIf="isSeasonalPrizesTrack && seasonPassRecord.seasonalPrizes">
  <p-orderList [value]="seasonPassRecord.seasonalPrizes" (onReorder)="assignStages(seasonPassRecord.seasonalPrizes)"
    [listStyle]="{ 'max-height': seasonPassRecord?.seasonalPrizes?.length > 2 ? '200rem' : '50rem' }" header="Reward Track"
    [dragdrop]="true">
    <ng-template let-day let-i="index" pTemplate="item">
      <div class="flex justify-items-center p-2 w-full flex-wrap p-grid">
        <div style="float: left;" class="p-col-6">
          <prizes-card-form-group [(prize)]="seasonPassRecord.seasonalPrizes[i]" [showPrize]="true"
            fieldsetName="Seasonal Prizes Stage {{ i + 1 }}" [excludeResourceTypes]="null"
            (onPrizeChange)="onRecordChange('seasonalPrizes'); seasonPassRecord.seasonalPrizes[i] = $event;">
          </prizes-card-form-group>
        </div>
        <div class="p-col-5">
          <h4> Targets / Milestones</h4>
          <span style="width: 100%;">
            <p-inputNumber [(ngModel)]="seasonPassRecord.stageMilestone[i]" mode="decimal" inputId="minmax-buttons"
              [min]="0" (onInput)="onRecordChange('stageMilestone')">
            </p-inputNumber>
          </span>
        </div>
        <div class="p-col-1">
          <button pButton type="button" icon="pi pi-trash"
            class="p-button-rounded p-button-danger p-button-sm p-button-text"
            (click)="removeStage(i); onRecordChange('stageMilestone'); onRecordChange('seasonalPrizes');"></button>
        </div>
      </div>

    </ng-template>
  </p-orderList>
</div>