import { Injectable } from '@angular/core';
import { UtilitiesService } from './utilities.service';

@Injectable({
  providedIn: 'root'
})
export class SetDefaultTimeService {

  constructor(private utilitiesService: UtilitiesService,) { }

  setDefaultTime(patchValue: boolean, form: any, prop: any) {

    if(patchValue) {
      form.patchValue({ [prop] : this.utilitiesService.getCurrentDateAtMidnight() });
    }
    
    if(!patchValue){
      form[prop] = this.utilitiesService.getCurrentDateAtMidnight()
    }
  }
}
