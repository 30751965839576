import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import BuildGameDataSlackNotification from 'src/app/build-gamedata/dtos/BuildGameDataSlackNotification';

@Injectable({
  providedIn: 'root'
})
/**
 * Notifications Service
 */
export class NotificationsService 
{
  constructor(private http: HttpClient) { }

  /**
   * Sends a slack notification to the cms-gamedata channel
   * 
   * @param payload Data with information regading the user and the status of the build
   */
  async sendGameDataBuildNotification(payload: BuildGameDataSlackNotification): Promise<any> 
  {
    return this.http.post<any>(`/api/gamedata/notification`, payload).toPromise();
  }

  /**
   * Sends a slack notification to the cms-builds channel
   * 
   * @param payload Data with information regading the user and the status of auto scaling setting
   */
  async scaleSettingsNotification(payload: any): Promise<any> 
  {
    return this.http.post<any>(`/api/build/nodes/settings/notification`, payload).toPromise();
  }
}
