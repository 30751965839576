 <!-- Auto Complete -->
 <div class="p-inputgroup" style="width: 100%;" *ngIf="!isLoading">
    <span ngClass="p-float-label">
        <p-autoComplete
            [(ngModel)]="path"
            [multiple]="false"
            field="path"
            [suggestions]="files"
            (completeMethod)="listPlasticFiles()"
            [minLength]="3"
            [style]="{ width: '100%' }"
            [showClear]="true"
            (onSelect)="onSelect(true)"
            (onKeyUp)="onSelect()"
            (onClear)="null"
            appendTo="body"
        >
        <ng-template let-file pTemplate="item">
          <div class="autocomplete-item">
            <p-tag icon="pi pi-user" class="p-mr-1" severity="info" [value]="file.user"></p-tag>
            <!-- Show this tag if file.type contains 'dir' -->
            <p-tag *ngIf="file.type.includes('dir')" class="p-mr-2" icon="pi pi-folder-open" [value]="file.type"></p-tag>
            <!-- Show this tag if file.type does not contain 'dir' -->
            <p-tag *ngIf="!file.type.includes('dir')" class="p-mr-2" icon="pi pi-file" [value]="file.type"></p-tag>
            <span class="path-text">{{ file.path }}</span>
          </div>
        </ng-template>        
        </p-autoComplete>
        <label for="autocomplete"> {{label}} </label>
    </span>
</div>
