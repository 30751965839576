import { AuthGuard } from "../auth/auth.guard";
import { SeasonPassFormV3Component } from "../cms-v2/entities/season-pass/components/season-pass-form-v3/season-pass-form-v3.component";
import { SeasonPassTableAgGridComponent } from "../cms-v2/entities/season-pass/components/season-pass-table-ag-grid/season-pass-table-ag-grid.component";
import { ReleaseGardenPassComponent } from "../entities/release/release-garden-pass/release-garden-pass/release-garden-pass.component";
import { SeasonPassViewComponent } from "../entities/season-pass/season-pass-view.component";

export const seasonPassRoutes = {
  prefix: 'season-pass',     
  routes: function () {
    return [
      {
        path: `${this.prefix}`,
        component: SeasonPassTableAgGridComponent,    
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/add`,
        component: SeasonPassFormV3Component,          
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/:id`,
        component: ReleaseGardenPassComponent,                  
        canActivate: [AuthGuard],
      },
      {
        path: `releases/${this.prefix}/:id`,
        component: ReleaseGardenPassComponent,                  
        canActivate: [AuthGuard],
      },
      {
        path: `${this.prefix}/edit/:id`,
        component: SeasonPassFormV3Component,    
        canActivate: [AuthGuard],
      },
    ]
  }
};