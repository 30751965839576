import { DynamicInputType } from "../enums/DynamicInputType";
import { DynamicViewType } from "../../dynamic-view-v2/enums/DynamicViewType";
import DynamicMultiplePricesFieldDTO from "./DynamicMultiplePricesFieldDTO";
import DynamicOptionsObject from "./DynamicOptionsObjectDTO";
import { ColDef } from 'ag-grid-enterprise';


/**
 * Dynamic Form Fields DTO
 */
export default class DynamicFormFieldDTO
{
    /**
     * Id of the field.
     */
    id?: string = "";
    /**
     * Name of the field
     */
    name: string = "";
    /**
     * Advisory information to display on input.
     */
    placeholder?: string = "";
    /**
     * When present, it specifies that the element should be disabled.
     */
    isRequired?: boolean = false;
    /**
     * Input type: Input Text, Input Number, Input Switch, Input Text Area, etc.
     */
    inputType: DynamicInputType | DynamicViewType;
    /**
     * Flag that sets whether or not to show the clear field button
     */
    clearField?: boolean = true;
    /**
     * Field Options to display as the variable options.
     */
    options?: DynamicOptionsObject = new DynamicOptionsObject();
    /**
     * Field Options to display as the variable options.
     */
    dependantLabels?: any | null = null;
    /**
     * Name of the label field of an option.
     */
    optionLabel?: string ="name";
    /**
     * Name of the value field of an option.
     */
    optionValue?: string | null = null;
    /**
     * Name of the disabled field of an option.
     */
    optionDisabled?: string ="disabled";
    /**
     * When present, it specifies that the component should be disabled.
     */
    disabled?: boolean = false;
    /**
     * 	When present, it specifies that the component cannot be edited.
     */
    readonly?: boolean = false;
    /**
     * 	Target element to attach the overlay, valid values are "body" or a local
     *  ng-template variable of another element (note: use binding with brackets for template variables,
     *  e.g. [appendTo]="mydiv" for a div element having #mydiv as variable name).
     */
    appendTo?: string | null = null;
    /**
     * Icon to display
     */
    icon?: string | null = null;
    /**
     * Field key (property)
     */
    key: string;
    /**
     * Flag that sets whether or not the field was modified
     */
    touched?: boolean = false;
    /**
     * Flag that sets whether the dependent field was touched or not
     */
    touchedDependent?: boolean = false;
    /**
     * Field Label
     */
    label?: string;
    /**
     * 	Defines the behavior of the component, valid values are "decimal" and "currency".
     */
    inputNumberMode?: string = "decimal";
    /**
     * Whether to use grouping separators, such as thousands separators or thousand/lakh/crore separators.
     */
    inputNumberUseGrouping?: boolean = true;
    /**
     * When specified, displays an input field to filter the items on keyup.
     */
    filter?: boolean = false;
    /**
     * When filtering is enabled, filterBy decides which field or fields (comma separated) to search against.
     */
    filterBy?: string;
    /**
     * 	Whether to display the first item as the label if no placeholder is defined and value is null.
     */
    autoDisplayFirst?: boolean = true;
    /**
     * Inline style of the element.
     */
    style?: any;
    /**
     * Callback function
     */
    onModelChange?: any;
    /**
     * Suggestions query data
     */
    suggestions?: DynamicOptionsObject = new DynamicOptionsObject();
    /**
     * Specifies if multiple values can be selected.
     */
    autocompleteMultipleValues?: boolean = false;
    /**
     * 	Whether to show the empty message or not.
     */
    autocompleteShowEmptyMessage?: boolean = false;
    /**
     * Field of a suggested object to resolve and display.
     */
    autocompleteField?: any;
    /**
     * Minimum number of characters to initiate a search.
     */
    autocompleteMinLength?: number = 1;
    /**
     * Flag that sets whether or not to get the _id from an object.
     */
    setMongoId?: boolean = false;
    /**
     * Whether to display timepicker.
     */
    calendarShowTime?: boolean = true;
    /**
     * The minimum selectable date.
     */
    calendarMinDate?: any;
    /**
     * Whether the year should be rendered as a dropdown instead of text.
     */
    calendarYearNavigatior?: boolean = true;
    /**
     * The range of years displayed in the year drop-down in (nnnn:nnnn) format such as (2000:2020).
     */
    calendarYearRange?: string;
    /**
     * Whether the month should be rendered as a dropdown instead of text.
     */
    calendarMonthNavigator?: boolean = true;
    /**
     * 	Whether days in other months shown before or after the current month are selectable. This only applies if the showOtherMonths option is set to true.
     */
    calendarSelectOtherMonths?: boolean = false;
    /**
     * Set the date to highlight on first opening if the field is blank.
     */
    calendarDefaultDate?: string | Date;
    /**
     * Flag that sets whether or not to show clear option.
     */
    showClear?: boolean;
    /**
     * Scroll height value
     */
    scrollHeight?: string = "200px";
    /**
     * Flag that sets whether or not to set groups in dropdowns.
     */
    dropdownGroup?: boolean = false;
    /**
     * Flag that sets whether or not to show button on input number.
     */
    inputNumberShowButtons?: boolean = false;
    /**
     * Id of the input for Input Number
     */
    inputNumberInputId?: string;
    /**
     * Min value for Input Number
     */
    inputNumberMin?: number;
    /**
     * Max value for Input Number
     */
    inputNumberMax?: number;
    /**
     * Id of the input
     */
    inputId?: string;
    /**
     * Flag that sets whether or not to set binary value on checkbox
     */
    checkboxBinary?: boolean = false;
    /**
     * List of resource types to exclude
     */
    priceExcludeResourceTypes?: Array<string> = [];
    /**
     * Input Text area columns
     */
    inputTextAreaCols?: number;
    /**
     * Input Text area rows
     */
    inputTextAreaRows?: number;
    /**
     * Multiselect display type
     */
    multiSelectDisplayType?: string | null;
    type?: 'ref' | 'self';
    /**
     * List of prop. rewards/prices - For Multi-Price component
     */
    prices?: Array<DynamicMultiplePricesFieldDTO> = [];
    /**
     * Custom field object
     */
    customFields?: Array<any> = [];
    /**
     * Show/Hide Fields
     */
    hideField?: boolean = false;

    /**
     * Flag that sets whether or not to hide the component on create view.
     */
    hideOnCreate?: boolean = false;
    /**
     * Flag that sets whether or not to hide the component on edit view.
     */
    hideOnEdit?: boolean = false;
    /**
     * Name of the dependent field
     */
    nameDependent?: string;
    /**
     * Key of the dependent field
     */
    keyDependent?: string;
    /**
     * Label of the dependent field
     */
    labelDependent?: string;
    /**
     * Options for the dependent field
     */
    optionsDependent?: any;
    /**
     * Controller that indicates the entity that the field belongs to.
     */
    controller?: string;
    /**
     * Indicates if the field has subfields.
     */
    hasSubfields?: boolean = false;
    /**
     * Subfields of the field.
     */
    subfields?: any;
    /**
     * Adds a title to each item in the list.
     */
    listItemsTitle?: string;
    /**
     * Number of columns to display in the list.
     */
    listColumns?: string;
    /**
     * width of the column
     */
    columnWidth?: number;
    /**
     * parent entity for misc assets component
     */
    parentEntity?: string;
    /**
     * parent entity for misc assets component
     */
    columns?: any;
    /**
     * assetInputComponent input
     */
    defaultEntityType?: any
    defaultAssetType?: any
    nameInputEnabled?: any
    assetTypeInputEnabled?: any
    defaultEntityTypeName?: any

    tagType?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p' | 'small';

    showPlural?: boolean = false;

    inputTypeFields?: any;

    validate?: any;

    embeddedFormFields? : any[];

    viewTypes? : any;

    displayNameIDPath?: boolean = false;

    labelKeys?: any[];

    viewType?: DynamicViewType;

    viewMode?: 'record' | 'array' | undefined;
    subFields?: DynamicFormFieldDTO[];
    componentType?: string;
    fieldName?: string = "";


    /**
     * fields for table input component to display data
     *
     **/

    rowData?: any;

    isTableInput?: boolean = false;
    tableInputParams?: any = null;

    isItemSet?: boolean = false;

    entity?: string;

    displayType?: string;

    displayControlType?: string;

    displayControls?: {
      [key: string]: (name: string) => boolean;
    };

    showField?: boolean = true;

    onLabel?: string;

    offLabel?: string;

    booleanoptions?: {
      true: string;
      false: string;
    };

    deficolumn?: ColDef<any, any>[];

    showthumb?: boolean = false;

    minFractionDigits?: number = undefined;

    maxFractionDigits?: number = undefined;
    customHeight?: string;  

    isdisplayNameIDPath?: boolean = false;

    autocomplete?: boolean = false;
    layoutMode?: 'horizontal';

}
