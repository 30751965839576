import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { TableCommunicationService } from '../../../services/table-communication.service';

@Component({
  selector: 'eo-track-cell-renderer',
  templateUrl: './eo-track.component.html',
  styleUrls: ['./eo-track.component.css']
})
export class EOTrackCellRenderer implements ICellRendererAngularComp {

  constructor(
    private tableCommunicationService: TableCommunicationService
  ) {

  }

  public params: any;
  timestamp = new Date().getTime();

  agInit(params: any): void {
    this.params = params;
  }

  refresh(params: any): boolean {
    return false;
  }

}
