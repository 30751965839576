import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';

@Component({
  selector: 'app-diff-tool-ag-agrid',
  templateUrl: './diff-tool-ag-agrid.component.html',
  styleUrls: ['./diff-tool-ag-agrid.component.sass']
})
export class DiffToolAgAgridComponent implements OnInit {

  public entity: string = 'diff-jobs';
  public columnDefs: ColDef[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};

  constructor(
    private agGridToolsService: AgGridToolsService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc', 'desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: false,
      },
      {
        headerName: 'Job Id',
        field: 'uuid',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Diff Type',
        field: 'diffType',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'diffRef',
        field: 'diffRef',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      }, 
      {
        headerName: 'diff Qty',
        field: 'diffQty',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      }, 
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
      {
        headerName: 'Date time',
        field: 'createdAt',
        sortable: true,
        unSortIcon: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ['clear'],
        },
        floatingFilter: true,
        suppressSizeToFit: true,
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          isDate: true,
        },
        valueGetter: this.agGridToolsService.dateValueGetter('createdAt'),
        resizable: true,
        type: 'editableColumn',
        cellEditor: CalendarEditorComponent,
        width: 300, 
        autoHeight: true, 
        cellStyle: {
          padding: '10px', 
          whiteSpace: 'normal', 
          lineHeight: '1.5', 
        },
        cellClass: 'custom-cell-style', 
      },
      {
        headerName: 'Created By',
        field: 'createdBy',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,

        autoHeight: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn'
      },
    ];

    this.buildParams = {
      tableSearchColumns: ['entityName', 'id'],
    };

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }
}
