import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { CommonEntityService } from '../../services/common-entity.service';
import { SpinnerService } from '../../services/spinner.service';
import { UtilitiesService } from '../../services/utilities.service';
import DynamicFormDTO from './dtos/DynamicFormDTO';
import DynamicFormFieldDTO from './dtos/DynamicFormFieldDTO';
import { DynamicInputType } from './enums/DynamicInputType';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicCardType } from './enums/DynamicCardType';
import { LoggerService } from '../../services/logger.service';
import * as _ from 'lodash';
import OnFieldChangeDTO from './dtos/OnFieldChangeDTO';
import { DatePipe } from '@angular/common';
import { environments } from '../../constants/constants';
import { constants } from 'src/app/entities/misc-image/constants';
import { AssetRefInputComponent } from 'src/app/common/components/asset-ref-input/asset-ref-input.component'; // Import the ChildComponent class.
import { OfficeTimeFormPipe } from '../../pipes/officeTimeForm.pipe';
import { OfficeTimePipe } from '../../pipes/officeTime.pipe';
import { EmbeddedFormComponent } from './components/embedded-form/embedded-form.component';
import { StoreManagementService } from '../../services/store-management.service';
import { ItemSetGeneratorPopupComponent } from './components/item-set-generator-popup/item-set-generator-popup.component';

@Component({
  selector: 'dynamic-form-v2',
  templateUrl: './dynamic-form-v2.component.html',
  styleUrls: ['./dynamic-form-v2.component.sass'],
  providers: [ConfirmationService],

})
export class DynamicFormV2Component implements OnInit {
  @ViewChildren(AssetRefInputComponent) assetRefInputChildren: QueryList<AssetRefInputComponent>;
  @ViewChild(EmbeddedFormComponent) embeddedFormComponent?: EmbeddedFormComponent;
  @ViewChild(ItemSetGeneratorPopupComponent) itemSetGeneratorPopupComponent?: ItemSetGeneratorPopupComponent;
  getEmbeddedFormComponent(): EmbeddedFormComponent | undefined {
    return this.embeddedFormComponent;
  }
  //
  @Input() isStoreEntity: boolean = false;
  @Input() storeEnv: 'dev' | 'qa' | 'prod';
  //
  @Input() entityType: string;
  @Input() recordId: number;
  @Input() formName: string;
  @Input() isEmbedded: boolean = false;
  @Input() embeddedType: 'new' | 'edit';
  @Input() fields: Array<DynamicFormDTO> = [];
  @Input() layout: string = 'full-screen';
  @Input() hideEnabledField: boolean = false;
  @Input() hideEnvFlagsField: boolean = false;
  @Input() record: any;
  @Input() listOfFields: any;
  @Input() originalRecord: any;
  @Input() showBottonSubmit: boolean = true;
  @Input() viewRoute: string;
  @Input() validationCallbackFunction: any;
  @Input() autopopulateEntity: boolean = false;
  @Input() isSubChildComponent: boolean = false;
  @Input() isRequired: boolean = false;
  @Input() customQuery: any = null;
  @Input() hasSubRecord: boolean = false;
  @Input() hasAssetRefComponent: boolean = false;
  @Input() submitEmbededForm: EventEmitter<any>;
  @Input() useTwoColumnLayout: boolean = false;
  @Input() sidebarFields: Array<DynamicFormDTO> = [];
  @Output() onValidation = new EventEmitter<any>();
  @Output() onSubmitAction = new EventEmitter<any>();
  @Output() onFieldChange = new EventEmitter<OnFieldChangeDTO>();
  @Output() onPreLoad = new EventEmitter<any>();
  @Output() onRecordChange = new EventEmitter<any>();
  @Output() onSubmitSubRecord = new EventEmitter<any>();
  @Output() formSubmitted = new EventEmitter<any>();

  isBaseInputValid: boolean = true
  confirmValidationCallback: boolean;
  confirmValidationCallbackMessage: string;


  approvedAssetsPrefix: string = 'approved_assets/';
  isValidPath: boolean = false;
  constants: any = constants;
  isEdit: boolean = false;
  isLoading: boolean = true;
  options: any = {};
  suggestions: Array<any> = [];
  spinnerIcon: string = "line-scale";
  spinnerSize: string = "medium";
  spinnerColor: string = "#81c784";
  defaultDate: any;
  showConflictsModal: boolean = false;
  showDatesVerificationModal: boolean = false;
  startDateErrorMessage: string = "";
  endDateErrorMessage: string = "";
  conflicts: any[] = [];
  conflictFetchedAt: string = '';
  conflictUpdatedAt: string = '';
  specificObject: any
  hasEmbeddedForm: boolean = false;
  embeddedFieldsChanged: Array<string> = [];
  categoryId: any;

  fieldTemplates: {
    [key: string]: {
      header?: string[];
      fields: string[];
    };
  } = {
      'Link - Destination': {
        header: ['Link', 'Destination'],
        fields: ['linkText', 'linkDestination']
      },
      'Target Collection': {
        fields: ['name', 'id']
      }
    };


  get inputType(): typeof DynamicInputType {
    return DynamicInputType;
  }
  get cardType(): typeof DynamicCardType {
    return DynamicCardType;
  }
  ItemSetRecord: any = {}; // Definición de la propiedad ItemSetRecord

  constructor
    (
      private commonEntityService: CommonEntityService,
      private utilitiesService: UtilitiesService,
      private titleService: Title,
      private spinnerService: SpinnerService,
      private authService: AuthService,
      private route: ActivatedRoute,
      private router: Router,
      private messageService: MessageService,
      private loggerService: LoggerService,
      private datePipe: DatePipe,
      private officeTimeFormPipe: OfficeTimeFormPipe,
      private officeTimePipe: OfficeTimePipe,
      private storeManagementService: StoreManagementService,
      private validation: BaseInputValidationServiceService,
      private confirmationService: ConfirmationService,
      private cdr: ChangeDetectorRef,

    ) { }


  async ngOnInit() {
    await this.initialize();
    await this.processRecord();
    this.setTitleAndOriginalRecord();
    await this.handleEntityTypeSpecificLogic();
    this.subscriptions();
    this.isLoading = false;
    this.spinnerService.endSpinner();
  }

  private async initialize() {
    if (this.isEmbedded && this.embeddedType == 'new') {
      this.record = {
        enabled: false,
        name: null
      };
    }
    this.spinnerService.loadSpinner();
    this.defaultDate = this.utilitiesService.getCurrentDateAtMidnight();
    let userResult = this.authService.getSocialUser();
    this.record.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };
    await this.setOptions();
  }

  async regenerateOptions(field: any) {
    this.options[field.options.fieldName] = await this.utilitiesService.getOptionsFromRef
      (
        this.options,
        field.options.fieldName,
        field.options.apiController,
        field.options.minimal,
        field.options.autopopulate,
        field.options.select,
        field.options.virtuals,
        field.options.sort,
        field.options.customQuery,
        this.isStoreEntity,
        this.storeEnv
      );
  }

  private async processRecord() {
    const routeParams = this.route.snapshot.paramMap;
    this.recordId = this.isEmbedded && this.record ? this.record.id : Number(routeParams.get('id'));

    if (this.recordId && !this.isSubChildComponent) {
      this.isEdit = true;
      await this.fetchAndProcessRecord();
    }

    if (this.isEmbedded && this.embeddedType == 'new') {
      this.isEdit = false;
    }
  }

  private async fetchAndProcessRecord() {
    let response = await this.commonEntityService.findOneWithQuery(
      this.entityType,
      this.customQuery ? { query: { id: this.recordId }, ...this.customQuery } : { query: { id: this.recordId }, autopopulate: this.autopopulateEntity }
    );

    if (response && response.id) {
      this.record = response;
      this.fields.forEach(card => this.processCardFields(card));
    } else {
      this.messageService.add(
        {
          sticky: true,
          severity: 'info',
          summary: 'Not Found',
          detail: `404 Not Found.`
        });
      this.router.navigate([`home`]);
      return;
    }
  }

  private processCardFields(card: any) {
    if (!card.fields || card.fields.length === 0) {
      return;
    }

    card.fields.forEach((field: any) => {
      if (!field || !field.key) {
        return;
      }

      if (this.isStoreEntity && this.storeEnv && !['id', 'enabled', 'name'].includes(field.key)) {
        this.record[field.key] = this.record[field.key]?.[this.storeEnv] ?? null;
      }

      if (field.setMongoId) {
        if (Array.isArray(this.record[field.key])) {
          this.record[field.key] = this.record[field.key].map((element: { _id: any; }) => element?._id ?? null);
        } else {
          this.record[field.key] = this.record[field.key]?._id ?? null;
        }
      }
    });
  }

  private setTitleAndOriginalRecord() {
    this.onRecordChange.emit(this.record);
    this.titleService.setTitle(this.isEdit ? `Edit ${this.record.name}(${this.record.id})` : `Add new ${this.entityType}`);
    this.originalRecord = _.cloneDeep(this.record);
  }

  private async handleEntityTypeSpecificLogic() {
    if (this.entityType == 'co-op') {
      if (this.record.currency_record_ref == undefined) {
        this.record.currency_record_ref = '654ab1abe5646276a04884f7'
        this.specificObject = this.options.currency_record_ref.find((option: { _id: any; }) => option._id === this.record.currency_record_ref);
      } else {
        this.specificObject = this.options.currency_record_ref.find((option: { _id: any; }) => option._id === this.record.currency_record_ref);
      }
    }
    if (this.formName == 'Asset' || this.formName == 'Audio Collection' || this.formName == 'Inbox Message') {
      this.onPreloadExecution();
    }
  }

  private subscriptions() {
    this.route.queryParams.subscribe(params => {
      if (params['viewRoute']) {
        this.viewRoute = params['viewRoute'];
        // this.loggerService.log("View Route: ", this.viewRoute);
      }
    });
    this.submitEmbededForm?.subscribe((eventData) => {
      this.beforeSubmitAction();
    });
  }

  /**
   * Sets options object values
   */
  async setOptions() {
    this.options['envs'] = environments;
    for (let card of this.fields) {
      if (card.fields && card.fields) {
        for (let field of card.fields) {
          if (field.options && field.options.fieldName && field.options.apiController) {
            this.options[field.options.fieldName] = await this.utilitiesService.getOptionsFromRef
              (
                this.options,
                field.options.fieldName,
                field.options.apiController,
                field.options.minimal,
                field.options.autopopulate,
                field.options.select,
                field.options.virtuals,
                field.options.sort,
                field.options.customQuery,
                this.isStoreEntity,
                this.storeEnv
              );
            if (field.optionsDependent && field.optionsDependent.fieldName && field.optionsDependent.apiController) {
              this.options[field.optionsDependent.fieldName] = await this.utilitiesService.getOptionsFromRef
                (
                  this.options,
                  field.optionsDependent.fieldName,
                  field.optionsDependent.apiController,
                  field.optionsDependent.minimal,
                  field.optionsDependent.autopopulate,
                  field.optionsDependent.select,
                  field.optionsDependent.virtuals,
                  field.optionsDependent.sort,
                  field.optionsDependent.customQuery,
                  this.isStoreEntity,
                  this.storeEnv
                );
            }
          }
          else if (field.options && field.options.values) {
            this.options[field.options.fieldName] = field.options.values;
            console.log(this.options)
            if (field.optionsDependent) {
              this.options[field.optionsDependent.fieldName] = field.optionsDependent.values
            }
          }
          //  ------------------------
          // create or select handling
          if (field.options && field.options.createOrSelect && field.options.createOrSelect.parent) {
            const newObj = { id: -1, _id: "createNew", name: `Create new ${field.name}`, createOption: true };
            this.options[field.options.fieldName].unshift(newObj);
          }
          // ------------------------
        }
      }
    }
  }

  /**
   * Autocomplete searching
   *
   * @param value Query value
   * @param fieldName Field name
   * @param model API Controller
   * @param minimal Flag that sets whether or not to return minimal result
   * @param autopopulate Flag that sets whether or not to autopopulate result.
   * @param sort Sort expression
   * @param virtuals Flag that sets whether or not to include virtual props.
   * @param select Select query
   */
  async getSuggestionsForRef(
    value: any,
    fieldName: string | any,
    model: string,
    minimal: boolean = false,
    autopopulate: boolean = false,
    sort: any = null,
    virtuals: boolean = false,
    select: string | null = null,
    customQuery: any = null,
    customQueryField: string | null = null
  ) {
    let idValue = parseInt(value);
    let queryValue = customQuery ? customQuery : (customQueryField && customQueryField.length > 0 ? (isNaN(idValue) ? { [customQueryField]: { $regex: value, $options: 'i' } } : { id: idValue }) : (isNaN(idValue) ? { name: { $regex: value, $options: 'i' } } : { id: idValue }));
    let result = await this.commonEntityService.findAllWithQuery(model,
      {
        query: queryValue,
        select: select ? select : '_id name id',
        virtuals: virtuals,
        autopopulate: autopopulate,
        sort: sort ? sort : { name: 1 },
      });

    if (minimal) {
      let o: any[] = [];
      for (const option of result) {
        o.push({ id: option.id, name: option.name, _id: option._id });
      }
      this.suggestions[fieldName] = o;
    } else {
      this.suggestions[fieldName] = result;
    }
  }

  /**
   * Parse Date Fields comming from the API
   *
   * @param existingRecord Existing record
   * @param field Date field property
   */
  parseDateField(existingRecord: any, field: any) {
    //this.record[field] = existingRecord[field] ? new Date(existingRecord[field]) : null;
    this.record[field] = existingRecord[field] ? new Date(this.officeTimeFormPipe.transform(existingRecord[field])) : null
  }

  /**
   * Handles field changes
   *
   * @param field Field changed
   * @param value Field value
   */
  onFieldValueChange(field: string, value: any, forceValueUpdate: boolean = false, displayControls: any = null, displayControlType: any = null) {
    if (displayControls) {
      // console.log('Function: ', displayControls);
      // console.log('Function: displayControlType ', displayControlType);

      this.applyDisplayControls(displayControls, value, displayControlType);

    }
    if (forceValueUpdate) {
      this.record[field] = value;
    }

    this.onFieldChange.emit({ field: field, value: value, record: this.record, options: this.options, fieldOptions: this.options[field] });

    if (value && this.options.currency_ref) {
      this.specificObject = this.options?.currency_ref?.find((option: { _id: any; }) => option._id === value);
    }
    this.onRecordChange.emit(this.record);
  }

  handleFieldChange(changeInfo: OnFieldChangeDTO) {
    // Emit the onFieldChange event to the parent form component
    this.onFieldChange.emit(changeInfo);
  }

  /**
   *  This function applies display controls to the fields based on the values returned by the functions in the displayControls object. It can disable or enable fields or show/hide fields based on the specified control type.
   *
   * @param displayControls
   * @param value
   * @param displayControlType
   */

  applyDisplayControls(displayControls: any, value: any, displayControlType: any) {
    for (const key in displayControls) {
      if (displayControls.hasOwnProperty(key)) {
        const control = displayControls[key](value);
        console.log('Control result: ', control);
        this.fields.forEach(card => {
          card.fields.forEach(f => {
            if (f.key == key) {
              switch (displayControlType) {
                case 'disable':
                  f.disabled = !control;
                  break;
                case 'showHide':
                  f.showField = control;
                  break;
                default:
                  break;
              }
            }
          });
        });
      }
    }
  }



  /**
 * Handle the date change event when it is copy and pasted
 */
  handleDateInputChange(field: any, event: any) {
    const inputValue = event.target.value;
    const parsedDate = new Date(inputValue);

    // this.loggerService.log('field', field)
    // this.loggerService.log('event', event)
    // this.loggerService.log('inputValue', inputValue)
    // this.loggerService.log('parsedDate', parsedDate)


    if (inputValue && !inputValue.includes('0:00')) {
      // this.itemForm.controls[field].setValue(parsedDate);
      // this.itemForm.controls[field].markAsUntouched();
      if (field == 'start') {
        this.record.start = parsedDate;
      } else if (field == 'end') {
        this.record.end = parsedDate;
      }

    }
  }


  onPreloadExecution() {
    this.onPreLoad.emit(this.record);
  }

  /**
 * Handle Submit action with cleaner and more efficient code.
 */
  async beforeSubmit(checkConflicts = true, skip: string = '') {
    // Simplify record transformation if conditions are met
    if (this.isStoreEntity && this.storeEnv) {
      this.record = Object.entries(this.record).reduce((acc, [key, value]) => ({
        ...acc,
        [key]: ['id', 'enabled', 'name'].includes(key) ? value : { [this.storeEnv]: value }
      }), {});
    }

    // Check form validity once and store the result
    const isFormValid = await this.isValidForm(skip);

    // Simplify validation logic
    if (this.validationCallbackFunction) {
      this.onValidation.emit({ record: this.record });
      const { confirmValidationCallback, message } = await this.validationCallbackFunction(this.record);
      this.loggerService.log("Response from Callback Function: ", { confirmValidationCallback, message });

      this.confirmValidationCallback = confirmValidationCallback;
      this.confirmValidationCallbackMessage = message;

      // Use the stored validity result
      if (confirmValidationCallback && isFormValid) {
        await this.onSubmit(checkConflicts);
      } else if (!confirmValidationCallback) {
        this.handleConfirmationDialog(message);
      } else {
        console.log('Invalid form - isValidForm did not pass checks.');
      }
    } else {
      // Use the stored validity result
      if (isFormValid) {
        await this.onSubmit(checkConflicts);
      } else {
        console.log('Invalid form - isValidForm did not pass checks.');
      }
    }
  }


  /**
   * Handle confirmation dialog separately for cleaner code.
   * This function assumes the existence of a message parameter.
   */
  private handleConfirmationDialog(message: string, checkConflicts: boolean = true) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to continue?', // Fallback message
      header: message || 'Confirmation Required', // Ensures there's a header even if message is undefined
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.onSubmit(checkConflicts);
      },
      reject: () => {
        this.messageService.add({
          severity: 'info',
          summary: 'Submission Cancelled',
          detail: 'You have cancelled the submission.'
        });
      }
    });
  }


  /**
   * Creates or Updates a record.
   */
  async onSubmit(checkConflicts = true) {
    this.onSubmitAction.emit(this.record);

    try {
      const embeddedForm = this.getEmbeddedFormComponent();
      if (embeddedForm && embeddedForm.embeddedType) {
        const response = await embeddedForm.submitInnerForm();
        // console.log('embedded form response: ', response);
        await this.handleEmbeddedFormChanges(response);
      }
      if (this.isEdit) {
        if (this.hasAssetRefComponent) {
          let assetResponses = await this.submitAllAssetsParallel(this.recordId);
          // console.log('assetResponse:', assetResponses);
        }
        await this.onEdit(checkConflicts);
      } else {
        let createResponse = await this.onCreate();
      }

      // Success handling or further processing
    } catch (error: any) {
      // Error handling
      console.error('An error occurred:', error);
      if (error.error) {
        error = error.error;
      }

      this.showMessage('error', 'Conflict Error', error.message ? error.message : JSON.stringify(error));

    }
  }

  async handleEmbeddedFormChanges(response: any) {
    if (response.data && response.field) {
      this.record[response.field.key] = response.data[response.field.optionValue];
      this.embeddedFieldsChanged.push(response.field.key)
    }
  }

  async submitAllAssetsParallel(parentId: number = 0) {
    let assetPromises = this.assetRefInputChildren.map(child => child.submitAssetRef(parentId));
    let assetResponses = await Promise.all(assetPromises);
    return assetResponses;
  }

  /**
   * Submits form with overwrite flag to bypass conflict checks
  */
  async submitAndOverwrite() {
    this.beforeSubmit(false);
  }

  /**
   * Validate all touched fields, and returns an object
   * with only the fields modified by the user.
   */
  validateTouchedValues() {
    let touchedRecord: any = this.hideEnabledField ? {} : ((this.originalRecord.enabled !== this.record.enabled || (this.record.enabled === undefined || this.record.enabled === null)) ? { enabled: this.record.enabled } : {});
    if (this.hideEnvFlagsField || _.isEqual(this.originalRecord.env, this.record.env)) {
      delete touchedRecord.env;
    }
    else {
      touchedRecord['env'] = this.record.env;
    }
    for (let card of this.fields) {
      if (card.fields && card.fields.length > 0) {
        card.fields.forEach(field => {
          if (this.embeddedFieldsChanged.includes(field.key)) {
            field.touched = true;
          }
          if (field.touched || field.touchedDependent) {
            touchedRecord[field.key] = this.record[field.key];
            if (field.touchedDependent && field.keyDependent) {
              touchedRecord[field?.keyDependent] = this.record[field.keyDependent]
            }
          }
          if (field.inputType == DynamicInputType.MultiplePrices) {
            if (field.prices && field.prices.length > 0) {
              field.prices.forEach(price => {
                if (price.touched) {
                  touchedRecord[price.key] = this.record[price.key];
                }
              });
            }
          }
          if (field.customFields && field.customFields.length > 0) {
            field.customFields.forEach((field) => {
              if (field.touched) {
                touchedRecord[field.key] = this.record[field.key];
              }
            });
          }
        });
      }
    }

    return touchedRecord;
  }

  /**
   * Updates an existing record.
   */
  async onEdit(checkConflicts = true) {
    this.conflicts = [];
    const recordData = this.validateTouchedValues();

    if (Object.keys(recordData).length === 0) {
      this.navigateToViewIfNeeded();
      return;
    }

    try {
      const updateResponse = await this.updateRecord(recordData, checkConflicts);
      if (updateResponse) {
        this.handleSuccessfulUpdate(updateResponse);
      } else {
        this.showMessage('error', 'Submit Error', 'There was an error updating the record.');
      }
    } catch (error) {
      this.handleErrorOnUpdate(error);
    }
  }

  private async updateRecord(recordData: any, checkConflicts: any) {
    return await this.commonEntityService.update(this.entityType, this.recordId, {
      dto: recordData,
      fetchedAt: this.record.fetchedAt,
      checkConflicts: checkConflicts
    }, true);
  }

  private handleSuccessfulUpdate(updateResponse: any) {
    this.showMessage('success', 'Update Successful', `${this.formName} Updated Successfully.`);
    if (!this.isEmbedded) {
      this.router.navigate([`${this.viewRoute}/${updateResponse.id}`]);
    } else {
      this.formSubmitted.emit(updateResponse);
    }
  }

  private handleErrorOnUpdate(error: any) {
    if (error?.error) {
      error = error.error;
    }

    if (error?.conflicts && error.fetchedAt && error.updatedAt) {
      this.prepareForConflictResolution(error);
      this.showMessage('error', 'Conflict Error', error.message || 'Error updating record');
    } else {
      this.showMessage('error', 'Form Submission Error', error.message || error);
    }
  }

  private prepareForConflictResolution(error: any) {
    this.showConflictsModal = true;
    this.conflictFetchedAt = error.fetchedAt;
    this.conflictUpdatedAt = error.updatedAt;
    this.conflicts = error.conflicts;
  }

  private navigateToViewIfNeeded() {
    if (this.isEdit) {
      this.router.navigate([`${this.viewRoute}/${this.record.id}`]);
    }
  }

  /**
   * Creates a new record.
  */
  async onCreate() {
    const createResponse = await this.commonEntityService.create(this.entityType, this.record);
    if (!createResponse) {
      this.showMessage('error', 'Submit Error', 'There was an error creating the record.');
      return null;
    }

    this.showMessage('success', 'Create Successful', `${this.formName} Created Successfully.`);

    if (this.hasAssetRefComponent) {
      await this.processAssetReferences(createResponse);
    }

    this.handleNavigation(createResponse);
    return createResponse;
  }

  private async processAssetReferences(createResponse: any) {
    const assetResponses = await this.submitAllAssetsParallel(createResponse.id);
    // console.log('assetResponse:', assetResponses);

    const updateAssetsObj = this.prepareUpdateAssetsObject(assetResponses);
    await this.commonEntityService.update(this.entityType, createResponse.id, { dto: updateAssetsObj }, true);
  }

  private prepareUpdateAssetsObject(assetResponses: any) {
    const updateAssetsObj: any = {};
    for (const assetResponse of assetResponses) {
      if (assetResponse.success) {
        updateAssetsObj[assetResponse.key] = assetResponse.record._id;
      } else {
        this.showMessage('error', 'Asset Reference Error', assetResponse.error);
      }
    }
    return updateAssetsObj;
  }

  private showMessage(severity: string, summary: string, detail: string) {
    this.messageService.add({
      sticky: true,
      severity,
      summary,
      detail
    });
  }

  private handleNavigation(createResponse: any) {
    if (this.isEmbedded && this.embeddedType === 'new') {
      this.formSubmitted.emit(createResponse);
      return;
    }
    this.router.navigate([`${this.viewRoute}/${createResponse.id}`]);
  }

  async buildPath(event: any) {
    let pathInput = '';
    if (event.isSelectChange) {
      pathInput = event.value.path;
    }
    else {
      pathInput = event.value;
    }
    if (this.validatePath(pathInput)) {
      this.isValidPath = true;
    } else {
      this.isValidPath = false;
    }
  }

  async handlePathComplete(event: any) {
    if (event.value && event.value.path) {
      this.record.path = event.value.path;
    } else if (event.value && event.value.length > 0) {
      this.record.path = event.value;
    }
  }

  validatePath(path: string) {
    if (path && path.length > 0) {
      let foundProtected = this.constants.protectedPaths.find(
        (v: string) => path == v
      );
      let isValidFolder = path.substr(-1) !== '/';

      // console.log('foundProtected: ', foundProtected);
      // console.log('isValidFolder: ', isValidFolder);

      if (!foundProtected && isValidFolder) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  /**
   * Handles changes on custom fields
   *
   * @param event Event comming from child component.
   * @param field Field changed.
   */
  onCustomFieldChange(event: any, field: DynamicFormFieldDTO) {
    if (field.customFields && event) {
      for (let customField of field.customFields) {
        if (customField.key == event.field) {
          customField.touched = true;
          break;
        } else if (field.options && field.options.createOrSelect && field.options.createOrSelect.child) {
          customField.touched = true;
          if (event.record) {
            this.record[customField.key] = event.record
          }
          break;
        }
      }
    }
  }

  // formatDate(date: any){
  //   return this.datePipe.transform(new Date(date), 'medium');
  // }
  formatDate(date: any) {
    if (!date || isNaN(new Date(date).getTime())) {
      this.messageService.add(
        {
          sticky: false,
          severity: 'error',
          summary: 'Invalid Date',
          detail: `The date entered is invalid. Please select or input a valid date.`,
        });
      return 'Invalid Date';
    }
    return this.datePipe.transform(new Date(date), 'medium');
  }


  async validateDateChanges() {
    let isValidDate = true;

    if (this.hasStartDateChanged()) {
      isValidDate = this.evaluateStartDate() && isValidDate;
    }

    if (this.hasEndDateChanged()) {
      isValidDate = this.evaluateEndDate() && isValidDate;
    }

    return isValidDate;
  }

  private hasStartDateChanged() {
    let originalStart = new Date(this.originalRecord.start).getTime();
    let newStart = new Date(this.record.start).getTime();
    return this.originalRecord.start && originalStart !== newStart;
  }

  private hasEndDateChanged() {
    let originalEnd = new Date(this.originalRecord.end).getTime();
    let newEnd = new Date(this.record.end).getTime();
    return this.originalRecord.end && originalEnd !== newEnd;
  }

  private evaluateStartDate() {
    let timeDifference = this.getTimeDifference(new Date(this.originalRecord.start));
    let differenceInDays = this.getDifferenceInDays(timeDifference);
    let differenceInHours = this.getDifferenceInHours(timeDifference);

    if (differenceInDays > 0) {
      this.startDateErrorMessage = `Original Start date passed ${Math.floor(differenceInDays)} days ago`;
      return false;
    } else if (differenceInDays <= 0 && differenceInHours <= 48) {
      this.startDateErrorMessage = `Original Start date is soon to go live in ${differenceInHours} hours`;
      return false;
    }

    return true;
  }

  private evaluateEndDate() {
    let timeDifference = this.getTimeDifference(new Date(this.originalRecord.end));
    let differenceInDays = this.getDifferenceInDays(timeDifference);
    let differenceInHours = this.getDifferenceInHours(timeDifference);

    if (differenceInDays > 0) {
      this.endDateErrorMessage = `Original End date passed ${Math.floor(differenceInDays)} days ago`;
      return false;
    } else if (differenceInDays <= 0 && differenceInHours <= 48) {
      this.endDateErrorMessage = `Original End date is soon to go live in ${differenceInHours} hours`;
      return false;
    }

    return true;
  }

  private getTimeDifference(date: Date) {
    let currentDate = new Date();
    return currentDate.getTime() - date.getTime();
  }

  private getDifferenceInDays(timeDifference: number) {
    return timeDifference / (1000 * 3600 * 24);
  }

  private getDifferenceInHours(timeDifference: number) {
    return +Math.abs(timeDifference / 3600000).toFixed(1);
  }

  async validateEnvPreReqs() {
    return false;
  }

  /**
   * Submits form with overwrite flag to bypass conflict checks
  */
  async submitAndSkipChecks(skip: string) {
    this.beforeSubmit(true, skip);
  }


  async isValidForm(skip: string) {
    let shouldSubmit = this.isBaseInputValid; // Initial value set based on BaseInputField validity

    // Check for date validity if not skipped
    if (shouldSubmit && skip !== 'date') {
      let result = await this.validateDateChanges();
      this.showDatesVerificationModal = !result;
      shouldSubmit = result;
    }

    let validateDateChangesResult = this.validation.validateDateRange(this.record.start, this.record.end);

    // Check for required fields
    if (shouldSubmit) {
      let requiredFieldKeys = await this.getRequiredFieldKeys(this.fields);

      // New validation for no spaces or empty values in required fields
      requiredFieldKeys.forEach(key => {
        if (!this.isValueValid(this.record[key])) {
          this.messageService.add({
            sticky: false,
            severity: 'error',
            summary: 'Invalid Input',
            detail: `The field '${key}' cannot be empty or contain only spaces.`,
          });
          shouldSubmit = false;
        }
      });

      // Existing check for missing required fields
      let requiredFieldsResult = await this.getRequiredFields(this.fields, this.record);
      if (requiredFieldsResult.length > 0) {
        this.messageService.add({
          sticky: false,
          severity: 'error',
          summary: 'Missing Required Fields',
          detail: `The following fields are required: ${requiredFieldsResult.join(', ')}`,
        });
        shouldSubmit = false;
      }
    }

    // Enhanced validation for rules_ref
    if (this.record.rules_ref && this.record.rules_ref.length > 0) {
      for (const rule of this.record.rules_ref) {
        // Destructure rule for easier access to properties
        const { filterModel, filterTableName, filter_ref } = rule;

        // Check if filterModel or filterTableName are empty or if filter_ref is missing or has no _id
        const isFilterModelEmpty = !filterModel || (typeof filterModel === 'object' && !filterModel.model);
        const isFilterTableNameEmpty = !filterTableName;
        const isFilterRefEmpty = !filter_ref || !filter_ref._id;

        // If any are empty, flag validation error
        if (isFilterModelEmpty || isFilterTableNameEmpty || isFilterRefEmpty) {
          this.messageService.add({
            sticky: false,
            severity: 'error',
            summary: 'Incomplete Rule Configuration',
            detail: 'Each rule must have a non-empty filter model, filter table name, and filter reference. Please complete all rule configurations.',
          });
          shouldSubmit = false;
          break; // Stop checking further if an error is found
        }
      }
    }


    // Date range validation
    if (this.record.end && !validateDateChangesResult) {
      this.messageService.add({
        sticky: false,
        severity: 'error',
        summary: 'Invalid Date Range',
        detail: `The end date cannot be earlier than the start date. Please select a later date.`,
      });
      shouldSubmit = false;
    }

    return shouldSubmit;
  }

  async getRequiredFieldKeys(fields: any): Promise<string[]> {
    const requiredFieldKeys: string[] = [];

    for (const card of fields) {
      for (const field of card.fields) {
        if (field.isRequired) {
          requiredFieldKeys.push(field.key);
        }
      }
    }

    return requiredFieldKeys;
  }

  async getRequiredFields(fields: any, record: any) {

    const missingFields: string[] = [];

    for (const card of fields) {
      for (const field of card.fields) {
        if (field.isRequired && (record[field.key] === undefined || record[field.key] === null)) {
          missingFields.push(field.key);
        }
      }
    }

    return missingFields;
  }

  beforeSubmitAction() {
    if (this.hasSubRecord) {
      this.onSubmitSubRecord.emit({ record: this.record, fields: this.fields });
    } else {
      this.beforeSubmit();
    }
  }

  findObjectById(id: string) {
    return this.record.find((obj: { _id: string; }) => obj._id === id);
  }

  // STORE ENTITY SPECIFIC FUNCTIONS
  getIcon(env: string): string {
    return this.storeManagementService.getIcon(env);
  }

  getSeverity(env: string): string {
    return this.storeManagementService.getSeverity(env);
  }
  // END
  handleBaseInputValidEvent(value: boolean) {
    // Handle the boolean value here
    this.isBaseInputValid = value;
    // console.log('Received boolean from child:', value);
  }

  // New function to check if a value is non-empty and not just spaces
  isValueValid(value: any) {

    if (typeof value === 'string') {
      return value.trim().length > 0;
    } else {
      // Handle non-string values. For now, considering them as valid.
      // You can add specific checks for other types if necessary.
      return true;
    }
  }
}
