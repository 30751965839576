import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import UserDto from '../dtos/UserDto';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService
{
  private readonly apiURLprefix : string = '/api/users';

  constructor(private http: HttpClient) {}

  private user = new BehaviorSubject<UserDto | null>(null);

  user$ = this.user.asObservable();

  /**
   * Retrieves a User by a given query
   *
   * @param query Query data
   */
  async findUser(query: any): Promise<any> {
    return await this.http.post(`${this.apiURLprefix}/find`, query).toPromise();
  }

  /**
   * Retrieves all CMS users
   */
  async getAllUsers(): Promise<Array<UserDto> | undefined> {
    return await this.http.get<Array<UserDto>>(`${this.apiURLprefix}`).toPromise();
  }

  /**
   * Updates user data
   */

  async updateUser(userId: any = null, dto: any): Promise<any> {
    return await this.http.patch(`${this.apiURLprefix}/update/v2/${userId}`, dto).toPromise();
  }

  async getInitials(): Promise<any> {
    return await this.http.post(`${this.apiURLprefix}/getInitials`, {}).toPromise();
  }
  
  setUser(user: UserDto) {
    this.user.next(user);
  }
}
