<div class="p-grid">
    <div class="p-col-9">
        <button
            pButton
            pRipple
            type="submit"
            label="Add Stage"
            class="p-mb-4"
            [style]="{ 'min-width': '18.5em' }"
            icon="pi pi-plus" iconPos="right"
            (click)="pushNewStage($event)"
        ></button>
    </div>
    <div class="p-col-3">
        <button
            pButton
            pRipple
            type="submit"
            label="Generate Stages"
            class="p-mb-4"
            [style]="{ 'min-width': '18.5em' }"
            (click)="generateStagesModal()"
        ></button>
    </div>
</div>
<p-orderList (onReorder)="onReorder($event)" [value]="stages" [listStyle]="{ 'max-height': '50rem' }">
    <ng-template let-index="index" let-product pTemplate="item">
        <div class="flex align-items-center p-2 w-full flex-wrap">
            <div class="header-container" style="display: flex; justify-content: space-between; width: 100%; align-items: center;">
                <h4>Stage {{index + 1}}</h4>
                <!-- Remove stage button aligned to the right -->
                <button
                    pButton
                    pRipple
                    type="button"
                    label="Remove Stage"
                    class="p-button-danger p-button-sm"
                    (click)="removeStage(index)">
                </button>
            </div>
            <p-dropdown 
                [(ngModel)]="stages[index]"
                [options]="options" optionLabel="name" optionValue="_id"
                [autoDisplayFirst]="false"
                [filter]="true"
                filterBy="name"
                [showClear]="true"
                appendTo="body"
                (ngModelChange)="onRecordChange('stages_ref')">      
                <ng-template let-stage pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2">
                        <div>{{ stage.name }} ({{ stage.id }})</div>
                    </div>
                </ng-template>
                <ng-template let-stage pTemplate="item">
                    <div class="flex align-items-center gap-2">
                        <div>{{ stage.name }} ({{ stage.id }})</div>
                    </div>
                </ng-template>
            </p-dropdown>
            <prizes-card-form-group
                [(prize)]="costs[index]"
                [showPrize]="true"
                [fieldsetName]="'Costs'"
                (onPrizeChange)="onRecordChange('costs_ref'); costs[index] = $event"
            >
            </prizes-card-form-group>
            <p-divider></p-divider>
        </div>
    </ng-template>
</p-orderList>

<p-dialog [(visible)]="generateStagesModalView" [dismissableMask]="true" [modal]="true" [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '90vw' }">
  <ng-template pTemplate="header">
    <div class="header-content">
      <h3>Generate Stages</h3>
    </div>
  </ng-template>
  <div>
    <p>Do you want to automatically generate all the stages related to the item? This will create stages in the 
        nurture stages table.
    </p>
  </div>
  <ng-template pTemplate="footer">
    <button pButton type="button" label="Confirm" icon="pi pi-check" (click)="generateStages()"
      class="p-button-primary p-mt-3"></button>
    <button pButton type="button" label="Cancel" icon="pi pi-times" (click)="generateStagesModalView = false"
      class="p-button-secondary p-mt-3"></button>
  </ng-template>
</p-dialog>