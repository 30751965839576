import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AesEncryptionDecryptionService {
  constructor() {}

  /**
   * 
   * @param content   Content will be either the object or the string being encryption;
   * @param password The key set within the template side to allow for encryption. If the key is wrong it will not complete the process.
   * @returns 
   */
  
  encrypt = (content: any, password: any) =>
    CryptoJS.AES.encrypt(JSON.stringify({ content }), password).toString();

  /**
   * 
   * @param crypted Crypted is the encrypt object or string.
   * @param password 
   * @returns 
   */

  decrypt = (crypted: any, password: any) =>
    JSON.parse(
      CryptoJS.AES.decrypt(crypted, password).toString(CryptoJS.enc.Utf8)
    ).content;

}
