import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-readonly-field',
  templateUrl: './readonly-field.component.html',
  styleUrls: ['./readonly-field.component.sass']
})
export class ReadonlyFieldComponent implements OnChanges {

  @Input() record: any;
  @Input() options: any;
  @Input() entityType: any;
  @Input() label: any;
  @Input() isPlural: boolean = false;

  specificObject: any;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.record && this.entityType === 'co-op') {
      this.updateSpecificObject();
    }
  }

  private updateSpecificObject(): void {
    if (this.options) {
      this.specificObject = this.options.find((option: { _id: any; }) => option._id === (this.record || "654ab1abe5646276a04884f7"));
    }
  }

  getPluralizedName(name: string): string {
    if (!name) return 'N/A';

    // Basic pluralization rules
    if (name.endsWith('y')) {
      return name.slice(0, -1) + 'ies';
    } else if (name.endsWith('h')) {
      return name + 'es';
    } else {
      return name
    }
  }

}

