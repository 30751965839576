<button
    *ngIf="isEnabled"
    [disabled]="disableButton"
    pButton
    pRipple
    type="button"
    icon="pi pi-copy"
    class="p-button-raised"
    (click)="duplicateRow()"
></button>

<!-- Toaster Alert -->
<p-messages [style]="{ width: '50%', position: 'absolute', top: 0, right: 0 }"></p-messages>