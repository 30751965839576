import { Component, OnInit } from '@angular/core';
import { BaseDynamicFormComponent } from 'src/app/common/components/dynamic-form-v2/components/base-dynamic-form/base-dynamic-form.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import SeedPackBoxesDTO from '../dtos/SeedPackBoxesDTO';
import { DynamicInputType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicInputType';
import { BuildType } from 'src/app/enums/build-type';
import { AssetTypes } from 'src/app/entities/enums/AssetTypes';
import { InputFieldType } from 'src/app/common/components/dynamic-form-v2/enums/InputFieldType';
import { BaseInputValidationServiceService } from 'src/app/common/services/base-input-validation-service.service';

@Component({
  selector: 'app-seed-pack-box-form',
  templateUrl: './seed-pack-box-form.component.html',
  styleUrls: ['./seed-pack-box-form.component.sass']
})
export class SeedPackBoxFormComponent extends BaseDynamicFormComponent implements OnInit {

  SeedPackBoxesRecord: SeedPackBoxesDTO = new SeedPackBoxesDTO();
  constructor(
    private validation: BaseInputValidationServiceService
  ) {
    super();
  }


  /**
   * Seed Packs Forms V2 Component Initialization
  */

  ngOnInit(){

    this.fields =
    [
      {
        title: "Seed Pack Box Data",
        fields:
        [
          { name: "Seed Pack Box Internal Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'name',
            isRequired: true,
            label: 'Seed Pack Box Internal Name',
            columnWidth: 6
          },
          { name: "Seed Pack Box Display Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'displayName',
            isRequired: true,
            label: 'Seed Pack Box Display Name',
            columnWidth: 6
          },
          { name: "Shortened Name",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'shortenedName',
            isRequired: true,
            label: 'Shortened Name',
            columnWidth: 6
          },
          { name: "Sprite Icon",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.TextInput],
            clearField: true,
            key: 'spriteIcon',
            isRequired: true,
            label: 'Sprite Icon',
            columnWidth: 6
          },
          {
            name: "Start Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'start',
            label: 'Start Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) => {
              if (incomingValue && record.end) {
                return [(this.validation.validateDateRange(incomingValue, record.end)), "Start Date must be before end date."]
              } else {
                return true;
              }
            },
          },
          {
            name: "End Date/Time",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Calendar],
            clearField: true,
            key: 'end',
            label: 'End Date/Time',
            calendarShowTime: true,
            calendarYearNavigatior: true,
            calendarYearRange: '2020:2030',
            calendarMonthNavigator: true,
            calendarSelectOtherMonths: true,
            calendarMinDate: this.SeedPackBoxesRecord.start,
            columnWidth: 6,
            validate: (incomingValue: boolean, record: any, fieldKey: any) =>{
              if(record.start && incomingValue){
                return [(this.validation.validateDateRange(record.start, incomingValue)), "End date must be greater than start date."]
              } else{
                return true;
              }
            }
          },
          {
            name: 'Guaranteed Rarity',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'guaranteed_rarity_ref',
            isRequired: false,
            label: 'Guaranteed Rarity',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            options: { fieldName: 'guaranteed_rarity_ref', apiController: 'nurture-rarity', minimal: true, autopopulate: false, virtuals: false, sort: { name: 1 }, select: '-createdAt -updatedAt -createdBy' },
            columnWidth: 6
          },
          {
            name: "Guranteed Count",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'guaranteed_count',
            label: 'Guaranteed Count',
            inputNumberShowButtons: true,
            columnWidth: 3
          },
          {
            name: "",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.SpaceCol],
            clearField: true,
            key: '',
            label: '',
            columnWidth: 3
          },
          {
            name: "Guarantee New SeedPackBox?",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'guarantee_new',
            isRequired: false,
            label: 'Guarantee New SeedPackBox?',
            inputId: 'guarantee_new',
            checkboxBinary: true,
            columnWidth: 3
          },
          {
            name: 'Backup SeedPackBox',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'backupBox_ref',
            isRequired: false,
            label: 'Backup SeedPackBox',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            options: { fieldName: 'backupBox_ref', apiController: 'seed-pack-box', minimal: true, autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'id _id name' },
            columnWidth: 6
          },
          {
            name: 'Type',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'type_ref',
            isRequired: false,
            label: 'Type',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            options: { fieldName: 'type_ref', apiController: 'seed-pack-box-types', minimal: true, autopopulate: false, virtuals: false, sort: { name: 1 }, select: '-createdAt -updatedAt -createdBy' },
            columnWidth: 6
          },
          {
            name: 'Nurture Collection',
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Dropdown],
            clearField: true,
            key: 'collection_ref',
            isRequired: false,
            label: 'Nurture Collection',
            optionLabel: 'name',
            optionValue: "_id",
            filter: true,
            filterBy: "id,name",
            showClear: true,
            setMongoId: true,
            autoDisplayFirst: false,
            options: { fieldName: 'collection_ref', apiController: 'nurture-collection', minimal: true, autopopulate: false, virtuals: false, sort: { name: 1 }, select: '-createdAt -updatedAt -createdBy' },
            columnWidth: 6
          },
          {
            name: "Box Image",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'box_image_ref',
            label: 'Box Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            setMongoId: true,
            autoDisplayFirst: false,
            scrollHeight: '200px',
            options: {
              fieldName: 'box_image_ref',
              apiController: 'miscellaneous-build',
              customQuery: { assetType: AssetTypes.SeedPackBox },
              autopopulate: false, virtuals: false, sort: { name: 1 },
              select: 'name id path _id',
              isdisplayNameIDPath: true
            },
            columnWidth: 6
          },
          {
            name: "Box Image Empty",

            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.queryDropdown],
            clearField: true,
            key: 'box_image_empty_ref',
            label: 'Box Image Empty',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            setMongoId: true,
            autoDisplayFirst: false,
            scrollHeight: '200px',
            options: {
              fieldName: 'box_image_empty_ref',
              apiController: 'miscellaneous-build',
              customQuery: { assetType: AssetTypes.SeedPackBox },
              autopopulate: false, virtuals: false, sort: { name: 1 },
              select: 'name id path _id',
              isdisplayNameIDPath: true
            },
            columnWidth: 6
          },
          {
            name: "Seed Pack Quantity",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'seedPackQty',
            label: 'Seed Pack Quantity',
            inputNumberShowButtons: true,
            columnWidth: 3
          },
          {
            name: "Allow Duplicates?",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.CheckBox],
            clearField: false,
            key: 'allowDups',
            isRequired: false,
            label: 'Allow Duplicates?',
            inputId: 'allowDups',
            checkboxBinary: true,
            columnWidth: 5
          },
          {
            name: "Nurtures",
            inputType: DynamicInputType.BaseInputField,
            clearField: false,
            key: 'nurtures_ref',
            inputTypeFields: [InputFieldType.TableInput],
            isRequired: true,
            label: 'Nurtures',
            disabled: true,
            rowData: this.SeedPackBoxesRecord.nurtures_ref,
            entity: 'nurture',
            isTableInput: true,
            tableInputParams: {
              select: 'id name thumbnail_ref'
            }
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Rarity Weight Overrides",
        fields:
        [
          {
            name: "Common Weight",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'weight_1000',
            label: 'Common Weight',
            inputNumberShowButtons: true,
            columnWidth: 7
          },
          {
            name: "Uncommon Weight",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'weight_1001',
            label: 'Uncommon Weight',
            inputNumberShowButtons: true,
            columnWidth: 7
          },
          {
            name: "Rare Weight",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'weight_1002',
            label: 'Rare Weight',
            inputNumberShowButtons: true,
            columnWidth: 7
          },
          {
            name: "Epic Weight",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'weight_1003',
            label: 'Epic Weight',
            inputNumberShowButtons: true,
            columnWidth: 7
          },
          {
            name: "Legendary Weight",
            inputType: DynamicInputType.BaseInputField,
            inputTypeFields: [InputFieldType.Number],
            clearField: true,
            key: 'weight_1004',
            label: 'Legendary Weight',
            inputNumberShowButtons: true,
            columnWidth: 7
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    this.title = "Seed Pack Boxes";
    this.viewRoute = 'seed-pack-box';
    this.isLoading = false;
  }


}
