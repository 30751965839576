<p-card class="p-d-block p-mx-auto p-px-1 p-mt-1">
  <!-- CARD BODY -->
  <ng-template pTemplate="content">
    <div class="custom-card-body">
      <!-- Simplified grid structure -->
      <div class="p-grid">
        <div class="p-col-7 p-p-0">
          <div class="p-pt-2 p-pl-2">
            <h5  class="p-m-0">  {{ customLabel ? customLabel : field.label }} {{ imageRecord?.id }} </h5>
          </div>
        </div>
        <div class="p-col-5 p-p-0">
          <!-- Condensed styling and conditionals -->
          <div *ngIf="imageRecord && imageRecord.buildStatus" class="p-col" [ngStyle]="getStyle(imageRecord)">
            <!-- Switch-case structure -->
            <ng-container [ngSwitch]="imageRecord.buildStatus.text">
              <ng-container *ngSwitchCase="'n/a'">
                <p class="p-m-0 p-text-center"><strong>Not Found</strong></p>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <strong>
                  <p class="p-d-inline p-m-0 p-text-center">
                    {{ imageRecord.buildStatus.text }}
                  </p>
                </strong>
                <p class="p-d-inline">
                  {{ imageRecord.buildStatus.date }}
                </p>
                <!-- Badge component with dynamic styling -->
                <p-badge [value]="imageRecord.buildStatus.buildCount" [style]="getBadgeStyle(imageRecord)"></p-badge>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="p-col-12 p-p-0">
          <p class="p-ml-2 p-mb-1" style="font-size: 12px;">{{ imageRecord?.name}}</p>
        </div>
      </div>

      <!-- Thumbnail display -->
      <div *ngIf="imageRecord && imageRecord.thumbnails && imageRecord.thumbnails[1]">
        <img [src]="imageRecord.thumbnails[1].path+'?ts=' + timestamp" class="p-mx-auto" width="100%" (click)="displayImage(imageRecord.thumbnails[0].path)" />
        <div class="p-grid p-align-center custom-row p-mt-1">
          <button pButton type="button" icon="pi pi-copy" class="p-button-text p-p-2" (click)="copyToClipboard(imageRecord.thumbnails[0].path)"></button>
          <div class="custom-text-container">
            <small>{{imageRecord.thumbnails[1].path }}</small>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-card>

<!-- 'View Image' dialog -->
<p-dialog [(visible)]="showImgDialog" [dismissableMask]="true" [modal]="true" [transitionOptions]="'200ms'" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '50vw' }">
  <ng-template pTemplate="header">
    <div>
      <h3 class="p-m-0">{{ imgData?.title }}</h3>
      <h4 class="p-mt-1 p-m-0">{{ imgData?.imageName }}</h4>
    </div>
  </ng-template>
  <div class="p-grid">
    <div class="p-col-12 text-center">
      <img *ngIf="imgData && imgData.path" [src]="imgData.path+'?ts=' + timestamp" style="width: 100%; height: auto; max-height: 90vh;" />
    </div>
    <div class="p-grid">
      <div class="p-col-1">
        <button pButton type="button" icon="pi pi-copy" class="p-button-text" (click)="copyToClipboard(imgData.path)"></button>
      </div>
      <div class="p-col-11">
        <small>{{ imgData.name }}</small>
      </div>
    </div>
  </div>
</p-dialog>
