import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-environment-build-status',
  templateUrl: './environment-build-status.component.html',
  styleUrls: ['./environment-build-status.component.sass']
})
export class EnvironmentBuildStatusComponent {
  @Input() entity: any;
  @Input() areEnvValuesOk: string;
  @Input() buildStatusFieldGroup: any[];
  @Input() imageBuildStatusFieldGroup: any[];
}

