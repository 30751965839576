export const refMapping: { [key: string]: any } = {
  //This ones are just for items (ONLY)
  "items": {
    "category_ref": {
      "collection": "categories" 
    },
    "type_ref": {
      "collection": "item-types"
    },
    "climates_ref": {
      "collection": "climates"
    },
    "colors_ref": {
      "collection": "colors"
    },
    "thumbnail_ref": {
      "collection": "miscellaneous-build"
    },
    "traits_ref": {
      "collection": "traits"
    },
    "materials_ref": {
      "collection": "materials"
    },
    "styles_ref": {
      "collection": "styles"
    },
    "patterns_ref": {
      "collection": "patterns"
    },
    "shape_ref": {
      "collection": "shapes"
    },
    "vendor_ref": {
      "collection": "vendors"
    },
    "batch_ref": {
      "collection": "batches"
    },
    "progressionLevel_ref": {
      "collection": "progression-levels"
    },
    "itemFileType_ref": {
      "collection": "item-file-types"
    },
    "externalPlantData_ref": {
      "collection": "external-plant-data"
    },
    "sourcingGroup_ref": {
      "collection": "sourcing-groups"
    },
    "tags_ref": {
      "collection": "tags"
    },
    "nurture_ref": {
      "collection": "nurture"
    },
    "keywords_ref": {
      "collection": "item-keywords"
    },
    // "spawnAudios_ref": {
    //   "collection": "audio-collections" //review 
    // },
    // "loopAudios_ref": {
    //   "collection": "audio-collections" //review
    // },
    // "spawnAudioCollections_ref": {
    //   "collection": "audio-collections" //review
    // },
    // "loopAudioCollections_ref": {
    //   "collection": "audio-collections" //review
    // }, 
    "itemSet_ref": {
      "collection": "item-sets" 
    },
  },
  // This one is for challenges
  "challenges": {
    "sceneType": {
       "collection": "scene-types" 
     },
    "progressionLevel_ref": {
      "collection": "progression-levels"
    },
    "sponsor_ref": {
      "collection": "sponsors"
    },
    "objective_ref": {
      "collection": "objectives"
    },
    "climate_ref": {
      "collection": "climates"
    },
    "type_ref": {
      "collection": "challenge-types"
    },
    // "tester_ref": {
    //   "collection": "users"
    // },
    // "artist_ref": {
    //   "collection": "users"
    // },
    "restrictions_ref": {
    "collection": "restrictions"
    },
    "contentHold_ref": {
      "collection": "content-hold"
      },
    "keywords_ref": {
      "collection": "item-keywords"
      },
    // "spawnAudios_ref": {
    //   "collection": "audio-collections" //review 
    // },
    // "loopAudios_ref": {
    //   "collection": "audio-collections" //review
    // },
    // "spawnAudioCollections_ref": {
    //   "collection": "audio-collections" //review
    // },
    // "loopAudioCollections_ref": {
    //   "collection": "audio-collections" //review
    // }, 
  }
}
