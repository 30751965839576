import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MessageService, ConfirmationService } from 'primeng/api';
import { TableService } from 'src/app/services/table.service';
import { settingsConstants } from 'src/app/entities/settings/constants';
import { LoggerService } from 'src/app/common/services/logger.service';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-managed-lists',
  templateUrl: './managed-lists.component.html',
  styleUrls: ['./managed-lists.component.sass'],
  providers: [ConfirmationService, MessageService],
})
export class ManagedListsComponent implements OnInit {
  settingsConstants = settingsConstants;

  selectedRows: any[] = []; 
  selectedRows_Entity: any[] = [];
  selectedRows_Imagery: any[] = [];
  // Scene Types
  sceneTypesRows: any;
  newRecordForm: UntypedFormGroup;
  newRecordDialog: boolean = false;
  editRecordDialog: boolean = false;

  // Entity Types
  entityTypeSettingsRows: any;
  newRecordForm_Entity: UntypedFormGroup;
  newRecordDialog_Entity: boolean = false;
  editRecordDialog_Entity: boolean = false;

  // Imagery Settings
  imagerySettingsRows: any;
  newRecordForm_Imagery: UntypedFormGroup;
  newRecordDialog_Imagery: boolean = false;
  editRecordDialog_Imagery: boolean = false;
  user: any;
  submitted: boolean;
  userDialog: any;
  isEditMode: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private tableService: TableService,
    private messageService: MessageService,
    private loggerService: LoggerService,
    private dataService: DataService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.initForm();
    await this.loadTableData();
  }
  openNew(entity: string) {
    switch (entity) {
      case this.settingsConstants.entities.readOnlyUser:
        this.user = {};
        this.submitted = false;
        this.userDialog = true;
        this.newRecordDialog = true;
        break;
      case this.settingsConstants.entities.sceneTypes:
        this.user = {};
        this.submitted = false;
        this.userDialog = true;
        this.newRecordDialog = true;
        break;
      case this.settingsConstants.entities.imagery:
        this.user = {};
        this.submitted = false;
        this.userDialog = true;
        this.newRecordDialog_Imagery = true;
        this.newRecordForm_Imagery.reset({
          name: null,
          path: 'images/',
          entityType: null,
          enabled: true,
          showOnImageTypeList: true,
          showOnAssetBundleOnly: false
        });
        break;
      case this.settingsConstants.entities.entityType:
        this.user = {};
        this.submitted = false;
        this.userDialog = true;
        this.newRecordDialog_Entity = true;
        this.newRecordForm_Entity.reset({
          name: null,
          enabled: true,
          showOnAssetBundleOnly: false
        });
        break;
      default:
        break;
    }
  }
  

  initForm() {
    this.newRecordForm = this.fb.group({
      name: [null],
      pathName: [null],
      enabled: [false],
    });

    this.newRecordForm_Entity = this.fb.group({
      name: [null],
      enabled: [true],
      showOnAssetBundleOnly: [false],
    });

    this.newRecordForm_Imagery = this.fb.group({
      name: [null],
      path: ['images/'],
      entityType: [null],
      enabled: [true],
      showOnImageTypeList: [true],
      showOnAssetBundleOnly: [false],
    });
  }

  async loadTableData(type: 'scene' | 'entity' | 'imagery' | 'all' = 'all') {
    if (type === 'scene' || type === 'all') {
      this.sceneTypesRows = await this.tableService.getRows(settingsConstants.sceneTypes);
    }
    if (type === 'entity' || type === 'all') {
      this.entityTypeSettingsRows = await this.tableService.getRows(settingsConstants.entityTypeSettings);
    }
    if (type === 'imagery' || type === 'all') {
      this.imagerySettingsRows = await this.tableService.getRows(settingsConstants.imagerySettings);
    }
  }

  // Scene Types Methods
  submitNewRecord() {
    const value = this.newRecordForm.value;
    this.dataService.addNewRecord('scene-types', value).then(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `${value.name} added.` });
      this.loadTableData();
      this.newRecordDialog = false;
    });
  }

  editNewRecordModal(record: any) {
    this.isEditMode = true;
    this.editRecordDialog = true;

    this.newRecordForm = this.fb.group({
      id: [record.id],
      name: [record.name],
      pathName: [record.pathName],
      enabled: [record.enabled],
    });
  }

  updateNewRecord() {
    const value = { ...this.newRecordForm.value, id: this.selectedRows[0]?.id }; 
    if (!value.id) return;
    this.dataService.updateRecord(value.id, 'scene-types', value).then(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `${value.name} updated.` });
      this.loadTableData();
      this.editRecordDialog = false;
    });
  }

  confirmDeleteNewRecordModal(record: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${record.name}?`,
      accept: async () => {
        await this.dataService.deleteRecord(record.id, 'scene-types');
        this.loadTableData();
      },
    });
  }

  // Entity Types Methods
  submitNewRecord_Entity() {
    const value = this.newRecordForm_Entity.value;
    this.dataService.addNewRecord('entity-type-settings', value).then(() => { 
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `${value.name} added.` });
      this.loadTableData();
      this.newRecordDialog_Entity = false;
    });
  }

  editNewRecordModal_Entity(record: any) {
    if (!record) return;
    this.selectedRows_Entity = [record];
    this.newRecordForm_Entity.setValue({
      name: record.name || null,
      enabled: record.enabled ?? true,
      showOnAssetBundleOnly: record.showOnAssetBundleOnly ?? false,
    });
    this.editRecordDialog_Entity = true;
  }

  updateNewRecord_Entity() {
    const value = { ...this.newRecordForm_Entity.value, id: this.selectedRows_Entity[0]?.id };
    if (!value.id) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No record selected.' });
      return;
    }
    
    this.dataService.updateRecord(value.id, 'entity-type-settings', value).then(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `${value.name} updated.` });
      this.loadTableData('entity');
      this.editRecordDialog_Entity = false;
    }).catch(error => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Update failed.' });
      console.error('Update error:', error);
    });
  }

  confirmDeleteNewRecordModal_Entity(record: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${record.name}?`,
      accept: async () => {
        await this.dataService.deleteRecord(record.id, 'entity-type-settings');
        this.loadTableData();
      },
    });
  }

  // Imagery Settings Methods
  submitNewRecord_Imagery() {
    const value = this.newRecordForm_Imagery.value;
    this.dataService.addNewRecord('imagery-settings', value).then(() => { 
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `${value.name} added.` });
      this.loadTableData();
      this.newRecordDialog_Imagery = false;
    });
  }

  editNewRecordModal_Imagery(record: any) {
    if (!record) return;
    this.newRecordForm_Imagery.setValue({
      name: record.name || null,
      path: record.path || 'images/',
      entityType: record.entityType || null,
      enabled: record.enabled ?? true,
      showOnImageTypeList: record.showOnImageTypeList ?? true,
      showOnAssetBundleOnly: record.showOnAssetBundleOnly ?? false,
    });
    this.editRecordDialog_Imagery = true;
  }

  updateNewRecord_Imagery() {
    const value = { ...this.newRecordForm_Imagery.value, id: this.selectedRows_Imagery[0]?.id };
    if (!value.id) return;
    
    this.dataService.updateRecord(value.id, 'imagery-settings', value).then(() => {
      this.messageService.add({ severity: 'success', summary: 'Success', detail: `${value.name} updated.` });
      this.loadTableData('imagery'); 
      this.editRecordDialog_Imagery = false;
    });
  }

  confirmDeleteNewRecordModal_Imagery(record: any) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${record.name}?`,
      accept: async () => {
        await this.dataService.deleteRecord(record.id, 'ImagerySettings');
        this.loadTableData();
      },
    });
  }
  hideDialog(dialogType: 'scene' | 'entity' | 'imagery') {
    if (dialogType === 'scene') {
      this.newRecordDialog = false;
      this.editRecordDialog = false;
    }
    if (dialogType === 'entity') {
      this.newRecordDialog_Entity = false;
      this.editRecordDialog_Entity = false;
    }
    if (dialogType === 'imagery') {
      this.newRecordDialog_Imagery = false;
      this.editRecordDialog_Imagery = false;
    }
  }
  
  navigateToLinkDestination(): void {
    this.router.navigate(['/link-destination']);
  }
}
