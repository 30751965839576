<p-dialog [(visible)]="display" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}"
  appendTo="body" [dismissableMask]="true" [modal]="true">
  <ng-template pTemplate="header">
    <div class="p-gird">
      <div class="p-col-12 p-p-0 p-m-0">
        <h4>
          Duplicate {{entity}} Record ID:<strong> {{payload.id}}</strong>
        </h4>
      </div>
      <div class="p-col-12 p-p-0 p-m-0">
        <p>
          Select fields (columns) to copy from into the new record.
        </p>
      </div>
    </div>
  </ng-template>
  <div>
    <div class="select-all-container" style="text-align: right; margin-bottom: 10px;">
      <div class="dialog-footer">
        <p-button [label]="allSelected ? 'Deselect All' : 'Select All'"
          [icon]="allSelected ? 'fa-solid fa-square-minus' : 'fa-solid fa-square-check'" iconPos="right"
          (onClick)="toggleAllSelection()" class="p-button-secondary"></p-button>
      </div>
    </div>

    <p-table [value]="tableData" [tableStyle]="{'width': '100%'}">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 20%;">Field</th>
          <th style="width: 70%;">Value</th>
          <th style="width: 10%;">Duplicate?</th>
        </tr>

      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr>
          <td>{{ rowData.label }}</td>
          <td [ngSwitch]="rowData.type">
            <!-- Date type -->
            <span *ngSwitchCase="'date'">{{ rowData.value | officeTime: 'short' }}</span>

            <!-- Multiselect_ref type -->
            <ul *ngSwitchCase="'multiselect_ref'">
              <li *ngFor="let item of rowData.value">
                {{item?.name}}
                <a href="/{{rowData?.controller}}/{{item?.id}}" target="_blank"
                   [ngStyle]="{ color: 'var(--primary-color)' }">
                  ({{item?.id}})
                </a>
              </li>
            </ul>

            <!-- single_ref type-->
            <div *ngSwitchCase="'single_ref'">
              <span>{{ rowData?.value?.name }}</span>
              <a href="/{{rowData?.controller}}/{{rowData?.value?.id}}" target="_blank"
                 [ngStyle]="{ color: 'var(--primary-color)' }">
                ({{rowData?.value?.id}})
              </a>
            </div>

            <!-- Costs type -->
            <div *ngSwitchCase="'costs'">
              <div *ngFor="let reward of rowData.value; let i = index">
                <p-divider *ngIf="i > 0"></p-divider>
                <div *ngIf="i > 0" class="p-mt-2"></div>
                <div *ngIf="reward.lineItemType === 'Item'">
                  <span class="p-text-bold">Reward Type: </span><span>{{reward.lineItemType}}</span>
                  <div class="reward-layout">
                    <img *ngIf="reward.id?.thumbnail_ref?.thumbnails?.length > 4"
                         [src]="reward.id.thumbnail_ref.thumbnails[4].path" alt="Item Thumbnail" class="item-thumbnail">
                    <span class="item-quantity pill">{{reward.c}}</span>
                    <span class="item-name">{{ reward.id?.name }}</span>
                    <span>{{ reward.id?.id }}</span>

                  </div>
                </div>
                <div *ngIf="reward.lineItemType !== 'Item'">
                  <span class="p-text-bold">Reward Type: </span><span>{{reward.lineItemType}}</span>
                  <div class="p-text-bold">Quantity: </div><span>{{reward.c}}</span>
                  <div class="p-text-bold">Reward: </div>
                  <span>{{ reward.id?.name }}</span>
                  <span>{{ reward.id?.id }}</span>

                </div>
              </div>
            </div>

            <!-- text_arr -->
            <ul *ngSwitchCase="'text_arr'">
              <li *ngFor="let item of rowData.value">
                {{item | json}}
              </li>
            </ul>

            <div *ngSwitchCase="'nameInput'">
              <input type="text" pInputText [(ngModel)]="rowData.value" [ngStyle]="{'width': '90%'}" />
            </div>

            <!-- Default case for text and other unspecified types -->
            <span *ngSwitchDefault>{{ rowData.value }}</span>
          </td>

          <td>
            <span *ngIf="rowData.required" style="color: red" class="p-mx-1">*</span>
            <p-checkbox [(ngModel)]="rowData.duplicate" [binary]="true"
              (onChange)="onItemSelected(rowData)" [disabled]="rowData.required"></p-checkbox>
          </td>
        </tr>
      </ng-template>
    </p-table>

  </div>
  <ng-template pTemplate="footer">
    <div class="dialog-footer">
      <p-button label="Duplicate" icon="fa-solid fa-copy" iconPos="right" (onClick)="onDuplicate()"
        class="p-button-success"></p-button>
    </div>
  </ng-template>
</p-dialog>
