import { Title } from '@angular/platform-browser';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { FieldBase } from '../dynamic-field/field-base';
import { FieldControlService } from '../services/field-control.service';
import { DataService } from './../services/data.service';
import { FieldService } from './../services/field.service';
import { FormService } from './../services/form.service';
import { AuthService } from '../auth/auth.service';
import { EntityViewService } from '../services/entity-view.service';

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.sass'],
  providers: [FieldControlService, FieldService, FormService],
})
export class DynamicFormComponent implements OnInit {
  @Input() key: any;
  @Input() id: number;
  @Input() editMode: boolean;
  @Input() showFileUploader: boolean = false;
  @Input() fileUploaderUrl: string;
  @Output() onUpload = new EventEmitter<any>();

  documentForEdit: Object;

  fields: FieldBase<string>[] | null = [];
  form!: UntypedFormGroup;
  payLoad = '';
  action = 'add';
  loading: boolean = true;
  entity: any;

  formURL: string;

  constructor(
    private fcs: FieldControlService,
    private fieldService: FieldService,
    private formService: FormService,
    private messageService: MessageService,
    private dataService: DataService,
    private authService: AuthService,
    private entityViewService: EntityViewService,
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.form = new UntypedFormGroup({});
  }

  async ngOnInit() {
    this.formURL = `${this.key}/${this.action}`;

    // If in edit mode, pull in existing field values.
    if (this.editMode) {
      // set form url to 'update' endpoint.
      this.formURL = `${this.key}/update/${this.id}`;
      console.log('form url', this.formURL);

      // get existing document values.
      this.dataService
        .getDocument(this.key, {
          query: { id: this.id },
          autopopulate: true,
          virtuals: true,
          sort: { name: 1 },
        })
        .pipe(first())
        .subscribe(async (document) => {
          if (document) {
            this.entity = document;
            this.documentForEdit = document;
            console.log('document', document);
            console.log('getting fields');
            await this.fieldService
              .getFormFieldsFromJSON(this.key)
              .then(async (data) => {
                this.fields = data;
                this.form = this.fcs.toFormGroup(
                  this.fields as FieldBase<string>[]
                );
                console.log('fields in init', this.fields);
              });
          }
          this.titleService.setTitle(`Edit ${this.entity.name}(${this.id})`)
        });
    } else {
      console.log('getting fields');
      await this.fieldService
        .getFormFieldsFromJSON(this.key)
        .then(async (data) => {
          this.fields = data;
          this.form = await this.fcs.toFormGroup(
            this.fields as FieldBase<string>[]
          );
          console.log('fields in init', this.fields);
        });
      await this.setDefaultPath();
      this.titleService.setTitle(`Add New ${this.key}`)
    }
    this.loading = false;
  }

  onFieldAdded(e: any) {
    console.log(`onFieldAdded: ${e.field.key}`);
    const { key, field, i } = e;
    let index = this.fields!.findIndex((f) => f.key === key);

    this.fields?.splice(index + i, 0, field);
  }

  onFieldReplaced(e: any) {
    console.log(`onFieldReplaced: ${e.field.key}`);
    const { key, field } = e;
    let index = this.fields!.findIndex((f) => f.key === key);

    this.fields?.splice(index, 1, field);
  }

  onFieldRemoved(e: any) {
    console.log(`onFieldRemoved: ${e}`);
    const key = e;
    let index = this.fields!.findIndex((f) => f.key === key);

    this.fields?.splice(index, 1);
  }

  onSubmit() {
    var { value } = this.form;
    // userData from authService
    let userResult = this.authService.getSocialUser();
    value.userData = {
      name: userResult.currentUser.name,
      email: userResult.currentUser.email,
      id: userResult.currentUser.id,
    };
    //
    // console.log('submitting form: ', value);
    // console.log(this.formURL);
    // console.log(JSON.stringify(value));

    this.formService.submitForm(value, this.formURL, this.editMode).subscribe(
      (val) => {
        console.log('POST call successful value returned in body', val);
        // adjust submit message for edit/new.
        if (this.editMode) {
          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Update Successful',
            detail: `"${value.name}" was successfully updated`,
          });
          this.router.navigate([`/${this.key}/${val.id}`]);
        } else {
          this.messageService.add({
            sticky: true,
            severity: 'success',
            summary: 'Submit Successful',
            detail: `"${value.name}" was successfully created`,
          });
          this.router.navigate([`/${this.key}/${val.id}`]);
          this.form.reset();
        }
      },
      (response) => {
        console.log('POST call in error', response);
        this.messageService.add({
          sticky: true,
          severity: 'error',
          summary: 'Submit Error',
          detail: 'There was an error submitting.',
        });
        window.scrollTo(0, 0);
      },
      () => {
        console.log('The POST observable is now completed.');
      }
    );

    // this.payLoad = JSON.stringify(this.form.getRawValue());
  }

  /**
   * Handle File Upload events
   *
   * @param event Event comming from file uploader.
   */
  onFileUpload(event: any) {
    this.onUpload.emit(event);
  }

  /**
   * Sets a value for a given property within the form.
   *
   * @param property Entity property.
   * @param value Value to be set.
   */
  setValueToForm(property: string, value: any) {
    this.form.get(property)?.setValue(value);
  }

  /**
   * Sets default path prefix
   */
  async setDefaultPath() {
    if (this.key == 'currencies') {
      let response = await this.entityViewService.getEntity('ImagerySettings', {
        query: { entityType: this.key },
      });

      if (
        response &&
        response.enabled &&
        response.path &&
        response.path.length > 0
      ) {
        this.setValueToForm('image', response.path);
        this.setValueToForm('imageSmall', response.path);
        this.setValueToForm('imageMedium', response.path);
        this.setValueToForm('imageLarge', response.path);
        this.setValueToForm('imageChallengePrizeButton', response.path);
        this.setValueToForm('imageChallengePrizeButton', response.path);
        this.setValueToForm('thumbnail', response.path);
      }
    }
  }
}
