import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataManagementService 
{
  private readonly apiURLprefix : string = '/api/data-management';
  constructor(private http: HttpClient) {}

 /**
   * Database Migration call
   * 
   * @param host Target DB.
   */
  async syncDatabase(host: string): Promise<any>
  {
    return firstValueFrom(this.http.post<any>(`${this.apiURLprefix}/sync`, { host: host }));
  }
}