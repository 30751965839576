<div [style]="{ margin: '1em' }" *ngIf="entity && !isLoading">
  <div>
    <h1 style="margin: 0 0 1em" class="p-mr-3 p-d-inline">
      {{ entity.name }}
    </h1>
    <a
      pButton
      pRipple
      label="Edit"
      type="button"
      class="p-d-inline"
      icon="pi pi-pencil"
      href="/{{ type }}/edit/{{ entity.id }}"
    ></a>
  </div>

  <ng-container *ngFor="let field of headerFieldGroup">
    <div class="p-m-1 p-px-2">
      <span class="p-text-bold p-mr-2">{{ field.name }}:</span>
      <span *ngIf="entity[field.key]" class="p-text-normal">{{
        entity[field.key]
      }}</span>
    </div>
  </ng-container>

  <div class="p-grid nested-grid p-mt-3">
    <!-- Main Column (left) -->
    <div class="p-col-12 p-lg-8">
      <!-- Main Status Indicators -->
      <span class="p-grid">
        <ng-container *ngFor="let field of mainStatusGroup">
          <div class="p-col-4">
            <h4 class="p-col p-m-2 p-mx-lg-2 p-my-lg-0">{{ field.name }}:</h4>
            <div
              *ngIf="field.key != 'itemStatus'"
              class="p-col p-m-2 p-mx-lg-2 p-my-lg-0 p-text-center"
              [ngClass]="statusBgColors(field)"
            >
              <h3 class="p-m-0 p-text-center">
                <ng-container
                  [ngSwitch]="parseValueForView(entity[field.key], field)"
                >
                  <ng-container *ngSwitchCase="''"> Not Found </ng-container>

                  <ng-container *ngSwitchDefault>
                    {{ parseValueForView(entity[field.key], field) }}
                  </ng-container>
                </ng-container>
              </h3>
            </div>

            <div
              *ngIf="field.key == 'itemStatus'"
              class="p-col p-m-2 p-mx-lg-2 p-my-lg-0 p-text-center"
              [ngClass]="statusBgColors(field)"
            >
              <h3 class="p-m-0 p-text-center">
                <ng-container
                  [ngSwitch]="parseValueForView(entity[field.key], field)"
                >
                  <ng-container *ngSwitchCase="''"> Not Found </ng-container>

                  <ng-container *ngSwitchDefault>
                    {{ parseValueForView(entity[field.key], field) }}
                  </ng-container>
                </ng-container>
              </h3>
            </div>
          </div>
        </ng-container>
      </span>

      <!-- Master Build Status -->
      <ng-container *ngFor="let field of secondaryStatusGroup">
        <span
          *ngIf="field.controlType === 'buildStatus'"
          class="p-grid p-text-center p-d-lg-none"
        >
          <div
            *ngIf="entity[field.key] && entity[field.key].buildStatus"
            class="p-col p-mx-2 p-mt-3"
            [ngStyle]="{
              position: 'relative',
              color: 'var(--primary-color-text)',
              'background-color':  entity[field.key]
              ? entity[field.key].buildStatus.color
              : '',
              'border-radius': '0.5em'
            }"
          >
            <ng-container [ngSwitch]="entity[field.key].buildStatus.text">
              <ng-container *ngSwitchCase="'n/a'">
                <h3 class="p-m-0 p-text-center">Not Found</h3>
              </ng-container>

              <ng-container *ngSwitchDefault>
                <h3 class="p-d-inline p-m-0 p-text-center">
                  {{ entity[field.key].buildStatus.text }}
                </h3>
                <p class="p-d-inline">
                  {{ entity[field.key].buildStatus.date }}
                </p>

                <p-badge
                  [value]="entity[field.key].buildStatus.buildCount"
                  [style]="{
                    'background-color':  entity[field.key]
                    ? entity[field.key].buildStatus.color
                    : '',
                    position: 'absolute',
                    right: '20%',
                    top: '-1em'
                  }"
                ></p-badge>
              </ng-container>
            </ng-container>
          </div>
        </span>
      </ng-container>

      <!-- Main Body Cards -->
      <p-card *ngFor="let card of cards" class="p-mx-2 p-shadow-4">
        <ng-template pTemplate="header">
          <div class="p-d-flex p-pt-3 p-pl-3">
            <span class="p-card-title">{{ card.name }} </span>
          </div>
          <ng-container *ngIf="card.name == 'Build Data'">
            <!-- Build and Render -->
            <div style="margin: 15px;">
              <div>
                  <h5 class="p-d-inline p-mr-2">Render Asset? <i pTooltip="Renders the asset once it has been successfully built, and a slack notification is sent to preview the asset." class="pi pi-info-circle" style="font-size: 12px"></i></h5>
                  <p-inputSwitch class="p-d-inline" [(ngModel)]="buildAndRender"></p-inputSwitch>
              </div>
            </div>
            <!-- Build Actions -->
            <div class="p-d-flex p-pl-3 p-pr-3 p-pb-3">
              <build-asset
                class="p-mr-auto"
                [entity]="entity"
                [path_ref]="entity.prefab_ref"
                [type]="type"
                assetType="asset"
                (triggerCompleteEvent)="updateBuildData($event)"
                label="Build Item"
                [renderAsset]="buildAndRender"
                [buildType]="buildType.Items"
              ></build-asset>
              <build-asset
                class="p-ml-2"
                [entity]="entity"
                [path_ref]="entity.thumbnail_ref"
                type="item-thumbnail"
                assetType="image"
                (triggerCompleteEvent)="updateBuildData($event)"
                label="Build Thumbnail"
                [buildType]="buildType.Images"
              ></build-asset>
            </div>
            <div class="p-d-flex p-pl-3 p-pr-3 p-pb-3">
              <build-asset
                class="p-mr-auto"
                [entity]="entity"
                [path_ref]="entity.prefab_ref"
                [type]="type"
                assetType="asset"
                (triggerCompleteEvent)="updateBuildData($event)"
                label="Build Item as High Priority"
                [buildType]="buildType.Items"
                [renderAsset]="buildAndRender"
                [isHighPriority]="true"
              ></build-asset>
              <build-asset
                class="p-ml-2"
                [entity]="entity"
                [path_ref]="entity.thumbnail_ref"
                type="item-thumbnail"
                assetType="image"
                (triggerCompleteEvent)="updateBuildData($event)"
                label="Build Thumbnail as High Priority"
                [buildType]="buildType.Images"
                [isHighPriority]="true"
              ></build-asset>
            </div>
          </ng-container>
          <ng-container *ngIf="card.name == 'History'">
            <div class="p-mx-5">
              <p-button
                class="p-mx-2"
                label="See latest History Changes"
                routerLink="/history/items/{{ entity.id }}"
              ></p-button>
              <p-button
                class="p-mx-2"
                label="See latest Builds history"
                routerLink="/build-history/items/{{ entity.id }}"
              ></p-button>
            </div>
          </ng-container>
        </ng-template>

        <ng-template pTemplate="content" *ngIf="!isLoading">
          <ng-container *ngFor="let row of card.rows" [ngSwitch]="row.layout">
            <div class="p-grid p-formgrid p-fluid">
              <ng-container *ngSwitchCase="'columns'">
                <span
                  *ngFor="let column of row.fieldGroups"
                  class="p-col-12 p-sm-6 card-col"
                >
                  <ul
                    *ngFor="let group of column"
                    style="list-style-type: none"
                  >
                    <li *ngFor="let field of group" class="p-d-flex p-mb-1">
                      <span *ngIf="group.length > 0" class="p-text-bold p-mr-2">
                        {{ field.name }}:
                      </span>
                      <app-data-field
                        [field]="field"
                        [value]="entity[field.key]"
                        [page]="'view'"
                        [entityType]="type"
                        [entity]="entity"
                      ></app-data-field>
                    </li>
                  </ul>
                </span>
              </ng-container>

              <ng-container *ngSwitchCase="'rows'">
                <ul
                  *ngFor="let group of row.fieldGroups"
                  style="list-style-type: none"
                >
                  <div class="p-col-12">
                    <li *ngFor="let field of group" class="p-d-flex">
                      <span *ngIf="group.length > 0" class="p-text-bold p-mr-2">
                        {{ field.name }}:
                      </span>
                      <app-data-field
                        [field]="field"
                        [value]="entity[field.key]"
                        [page]="'view'"
                        [entityType]="type"
                        [entity]="entity"
                      ></app-data-field>
                    </li>
                  </div>
                </ul>
              </ng-container>
            </div>
          </ng-container>

          <ng-container *ngIf="card.name == 'Build Data'">
            <p-accordion [multiple]="true">
              <p-accordionTab header="Asset Build Data" [selected]="true">
                <build-data
                  [parentEntity]="entity"
                  [(entity)]="entity.prefab_ref"
                  [buildData]="entity.prefab_ref && entity.prefab_ref.buildData ? entity.prefab_ref.buildData : null"
                  [type]="type"
                  [buildType]="buildType.Items"
                  (triggerRefreshEvent)="updateBuildData($event)"
                ></build-data>
              </p-accordionTab>
              <p-accordionTab header="Image Build Data" [selected]="true">
                <build-data
                  [parentEntity]="entity"
                  [(entity)]="entity.thumbnail_ref"
                  [buildData]="entity.thumbnail_ref && entity.thumbnail_ref.buildData ? entity.thumbnail_ref.buildData : entity.thumbnail_ref"
                  [type]="type"
                  [buildType]="buildType.Images"
                  (triggerRefreshEvent)="updateBuildData($event)"
                ></build-data>
              </p-accordionTab>
            </p-accordion>
          </ng-container>
        </ng-template>
      </p-card>
    </div>

    <!-- Secondary Column (right) -->
    <div class="p-col-12 p-lg-4">
      <!-- Secondary Indicators (lg screen) -->

      <ng-container *ngFor="let field of secondaryStatusGroup">
        <h4 class="p-m-2 p-d-none p-d-lg-flex">{{ field.name }}:</h4>
        <span
          class="p-grid p-mb-2 p-text-center p-d-none p-d-lg-flex"
          [ngSwitch]="field.controlType"
        >
          <!-- Environment(s) -->
          <ng-container *ngSwitchCase="'envs'" >
            <div
              class="p-col p-m-2"
              [ngClass]="statusBgColors(field)"
            >
              <h3 class="p-d-inline p-m-0 p-text-center">
                {{ entity[field.key] && entity[field.key].length > 0 ? entity[field.key].join(', ') : 'No Environment Found' }}
              </h3>
            </div>
          </ng-container>

          <!-- buildStatus -->
          <ng-container *ngSwitchCase="'buildStatus'">
            <div
              class="p-col p-m-2"
              [ngStyle]="{
                position: 'relative',
                color: 'var(--primary-color-text)',
                'background-color': entity[field.key].buildStatus.color,
                'border-radius': '0.5em'
              }"
              *ngIf="entity[field.key] && entity[field.key].buildStatus"
            >
              <h3 class="p-d-inline p-m-0 p-text-center">
                {{ entity[field.key].buildStatus.text }}
              </h3>
              <p class="p-d-inline">
                {{ entity[field.key].buildStatus.date }}
              </p>

              <p-badge
                [value]="entity[field.key].buildStatus.buildCount"
                [style]="{
                  'background-color': 'var(--text-color)',
                  position: 'absolute',
                  right: '5%'
                }"
              ></p-badge>
            </div>
          </ng-container>

          <!-- toggle -->
          <ng-container *ngSwitchCase="'toggle'">
            <div
              class="p-col p-m-2"
              [ngClass]="statusBgColors(field)"
            >
              <h3 class="p-m-0 p-text-center">
                {{ parseValueForView(entity[field.key], field) }}
              </h3>
            </div>
          </ng-container>
        </span>
      </ng-container>

      <!-- Secondary Body Card (Images) -->
      <p-card class="p-m-2 p-shadow-4">
        <ng-template pTemplate="header">
          <span class="p-d-flex p-pt-3 p-px-3">
            <span class="p-card-title">
              {{ secondaryCard.name }}
            </span>
          </span>
        </ng-template>

        <ng-template pTemplate="content">
          <div class="p-grid">
            <span
              *ngFor="let field of secondaryCard.fields"
              class="p-col-12 p-sm-6 p-lg-12 p-px-1 p-pb-4"
              style="overflow-wrap: anywhere"
            >
              <div *ngIf="field.controlType == 'renderGallery' else elseBlock ">
                <app-render-gallery
                [entityId]="entity.id"
                [type]="type"
                ></app-render-gallery>
              </div>
              <ng-template #elseBlock>
                <p class="p-mb-1">{{ field.name }}</p>
                <div
                  style="
                    min-width: 225px;
                    max-width: 450px;
                    min-height: 300px;
                    margin-top: 7px;
                  "
                  class="p-d-block p-mx-auto p-px-1"
                >
                  <img
                    *ngIf="entity[field.key]"
                    [lazyLoad]="entity[field.key]"
                    class="p-mx-auto"
                    width="100%"
                    style="border-radius: 20px;"
                    (click)="displayImage(field)"
                  />

                  <p-skeleton
                    *ngIf="!entity[field.key]"
                    width="100%"
                    height="100%"
                  ></p-skeleton>
                </div>
              </ng-template>
            </span>

            <!-- Start of Spruce Card -->
            <div id="maincard">
              <div id="headerBody">
                <div class="p-mb-2" id="headerT">
                  {{ this.entity.name ? this.entity.name : "Commune nomen" }}
                </div>
                <div class="p-mb-2" id="subheaderT">
                  {{
                    this.entity.cultivar
                      ? this.entity.cultivar
                      : "Iterum conare"
                  }}
                </div>
              </div>
              <div id="contentBody">
                <div class="p-mb 2">
                  <div class="p-d-flex p-jc-center">
                    <img
                      id="image1"
                      [src]="entity['newThumbnail_img']"
                      (error)="setDefaultPic()"
                    />
                  </div>
                </div>
                <div class="p-d-flex">
                  <div class="p-mr-2">
                    <div class="p-d-flex p-flex-column" id="text1">
                      <div class="p-mb-2" id="contentTitle">
                        <div id="rowText1">Latin Name</div>
                      </div>
                      <div class="p-mb-2" id="contentTitle">
                        <div id="rowText1">Category</div>
                      </div>
                      <div class="p-mb-2" id="contentTitle">
                        <div id="rowText1">Type</div>
                      </div>
                      <div class="p-mb-2" id="contentTitle">
                        <div id="rowText1">Climates</div>
                      </div>
                      <div class="p-mb-2" id="contentTitle">
                        <div id="rowText1">Color</div>
                      </div>
                      <div class="p-mb-2" id="contentTitle">
                        <div id="rowText1">Traits</div>
                      </div>
                    </div>
                  </div>
                  <div class="p-mr-2">
                    <div class="p-d-flex p-flex-column" id="text1">
                      <div class="p-mb-2" id="contentText">
                        <div id="rowText1">
                          {{
                            this.entity.latinName
                              ? this.entity.latinName
                              : "🌼 Not Filled"
                          }}
                        </div>
                      </div>
                      <div class="p-mb-2" id="contentText">
                        <div id="rowText1">
                          {{
                            this.plantPreview.category
                              ? this.plantPreview.category
                              : "🌼 Not Filled"
                          }}
                        </div>
                      </div>
                      <div class="p-mb-2" id="contentText">
                        <div id="rowText1">
                          {{
                            this.plantPreview.type
                              ? this.plantPreview.type
                              : "🌼 Not Filled"
                          }}
                        </div>
                      </div>
                      <div class="p-mb-2" id="contentText">
                        <div id="rowText1">
                          {{
                            this.plantPreview.climates
                              ? this.plantPreview.climates.names
                              : "🌼 Not Filled"
                          }}
                        </div>
                      </div>
                      <div class="p-mb-2" id="contentText">
                        <div id="rowText1">
                          {{
                            this.plantPreview.colors.names
                              ? this.plantPreview.colors.names
                              : "🌼 Not Filled"
                          }}
                        </div>
                      </div>
                      <div class="p-mb-2" id="contentText">
                        <div id="rowText1">
                          {{
                            this.plantPreview.traits.names
                              ? this.plantPreview.traits.names
                              : "🌼 Not Filled"
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="p-d-flex p-flex-column">
                  <div class="p-mb-2">
                    <div id="AboutTitle">About this Plant</div>
                  </div>
                  <div class="p-mb-2">
                    <div class="p-d-flex p-jc-center">
                      <img
                        id="image2"
                        [src]="entity['spruce_img']"
                        alt="🥀 Sorry No Image Found"
                      />
                    </div>
                  </div>
                  <div class="p-mb-2">
                    <div class="p-d-flex">
                      <div class="p-mr-2">
                        <div class="p-d-flex p-flex-column" id="text1">
                          <div class="p-mb-2" id="contentTitle">
                            <div id="rowText2">Typical Size</div>
                          </div>
                          <div class="p-mb-2" id="contentTitle">
                            <div id="rowText2">Hardiness Zone</div>
                          </div>
                          <div class="p-mb-2" id="contentTitle">
                            <div id="rowText2">Native Area</div>
                          </div>
                          <div class="p-mb-2" id="contentTitle">
                            <div id="rowText2">Sun Exposure</div>
                          </div>
                          <div class="p-mb-2" id="contentTitle">
                            <div id="rowText2">Bloom Time</div>
                          </div>
                        </div>
                      </div>
                      <div class="p-mr-2">
                        <div class="p-d-flex p-flex-column" id="text1">
                          <div class="p-mb-2" id="contentText">
                            <div id="rowText2">
                              {{
                                this.plantPreview.colors.names
                                  ? this.plantPreview.colors.names
                                  : "🌼 Not Filled"
                              }}
                            </div>
                          </div>
                          <div class="p-mb-2" id="contentText">
                            <div id="rowText2">
                              {{
                                this.plantPreview.hardinessZones
                                  ? this.plantPreview.hardinessZones
                                  : "🌼 Not Filled"
                              }}
                            </div>
                          </div>
                          <div class="p-mb-2" id="contentText">
                            <div id="rowText2">
                              {{
                                this.plantPreview.nativeArea
                                  ? this.plantPreview.nativeArea
                                  : "🌼 Not Filled"
                              }}
                            </div>
                          </div>
                          <div class="p-mb-2" id="contentText">
                            <div id="rowText2">
                              {{
                                this.plantPreview.sunExposure
                                  ? this.plantPreview.sunExposure
                                  : "🌼 Not Filled"
                              }}
                            </div>
                          </div>
                          <div class="p-mb-2" id="contentText">
                            <div id="rowText2">
                              {{
                                this.plantPreview.bloomTime
                                  ? this.plantPreview.bloomTime
                                  : "🌼 Not Filled"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-d-flex p-flex-column">
                    <div class="p-mb-2">
                      <div class="p-text-left">
                        {{
                          this.entity.blurb
                            ? this.entity.blurb
                            : "Description goes here ...."
                        }}
                      </div>
                    </div>
                    <div class="p-mb-2">
                      <button id="contentButton">
                        Read more on the Spruce
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="footerBody">
                <div class="p-d-flex p-flex-column p-flex-md-row">
                  <div class="p-mb-2 p-mr-2" id="costSection">
                    <img
                      id="gold"
                      src="https://d3tfb94dc03jqa.cloudfront.net/images/currency/currency_coin_fly_up.png"
                    />
                    {{
                      this.plantPreview.cost ? this.plantPreview.cost : " Empty"
                    }}
                  </div>
                  <div class="p-mb-2 p-mr-2">
                    <button id="footerButton">Buy in game</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-card>
    </div>
  </div>
</div>

<!-- 'View Image' dialog -->
<p-dialog
  [(visible)]="showImgDialog"
  [dismissableMask]="true"
  [modal]="true"
  [transitionOptions]="'200ms'"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
  [style]="{ width: '50vw' }"
>
  <ng-template pTemplate="header">
    <h3 class="p-m-0">{{ imgData.title }}</h3>
  </ng-template>

  <div class="p-text-center">
    <img [src]="imgData.path" width="90%" />
    <h4 class="p-mt-0" width="90%" style="overflow-wrap: anywhere">
      {{ imgData.name }}
      <button
        pButton
        type="button"
        icon="pi pi-copy"
        class="p-button-text p-ml-3 p-mt-1"
        (click)="copyToClipboard(imgData.path)"
      ></button>
    </h4>
  </div>
</p-dialog>
