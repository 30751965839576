import { Injectable } from '@angular/core';
import { ColDef, ColGroupDef } from 'ag-grid-enterprise';
import { pipe } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgGridFiltersService {

  transformFilterModelToMongo(filterModel: any, filterParams: any, keys: any) {
    const query: any = {};
  // console.log(filterModel);
  // console.log('filterParams:');
  // console.log(filterParams);
    for (var field in filterModel) {
      // Skip buildStatus field as it's meant for aggregation
      if (field === 'buildStatus' || field == 'imageBuildStatus' || field == 'dimensionX' || filterParams[field].isAggregate) {
        continue;
      }

      if (filterModel[field]) {
        const filter = filterModel[field];

        // column/field specific filters

        // special case for challenges_ref
        if (field === 'challenges_ref' && filter.filterType === 'text') {
          const isNumber = !isNaN(parseFloat(filter.filter)) && isFinite(filter.filter);
          const key = `${field}.${isNumber ? 'id' : 'name'}`;
          const value = isNumber ? parseInt(filter.filter) : filter.filter;

          // Use the function to get the MongoDB operator
          let operator = this.getMongoDbOperatorChallenges(filter.type);

          let queryValue: any;
          if (operator === '$regex') {
            query[key] = { $regex: value, $options: 'i' }; // Directly set $regex and $options here
          } else {
            // For other operators, handle them appropriately
            if (operator === '$not') {
              query[key] = { $not: { $regex: value, $options: 'i' } }; // Use $not with $regex correctly
            } else if (operator === '$in') {
              query[key] = { [operator]: [null, ''] };
            } else if (operator === '$exists') {
              queryValue = { $exists: true, $ne: null };
              query[key] = queryValue;
            } else if (operator !== '') {
              query[key] = { [operator]: value }; // Use the operator directly with the value
            } else {
              query[key] = value; // For 'equals', directly set the value
            }
          }
          continue;
        }


        // Special case for costs_ref
        if (field === 'costs_ref' || field === 'rewards_ref' || field === 'prizes_ref') {
          this.processLineItemFieldFilter(query, filterModel, field);
          continue;  // Skip to next iteration
        }

        // fitlerType specific filter
        if (filter.filterType === 'set') {
          this.processSetFilter(query, field, filter, filterParams[field]);
          continue;
        }

        // filter.type specific fitlers
        switch (filter.type) {
          case 'equals':
            if (filter.filterType === 'date') {
              const startDate = new Date(filter.dateFrom);
              startDate.setHours(0, 0, 0, 0);

              const endDate = new Date(filter.dateFrom);
              endDate.setHours(23, 59, 59, 999);

              query[field] = { $gte: startDate, $lt: endDate };
            } else {
              query[field] = filter.filter;
            }
            break;
          case 'notEqual':
            if (filter.filterType === 'date') {
              const startDate = new Date(filter.dateFrom);
              startDate.setHours(0, 0, 0, 0);

              const endDate = new Date(filter.dateFrom);
              endDate.setHours(23, 59, 59, 999);

              query[field] = { $not: { $gte: startDate, $lt: endDate } };
            } else {
              query[field] = { $ne: filter.filter };
            }
            break;
          case 'startsWith':
            query[field] = { $regex: `^${filter.filter}`, $options: 'i' };
            break;
          case 'endsWith':
            if (filter.filterType === 'text') {
              query[field] = { $regex: `${filter.filter}$`, $options: 'i' };
            }
            break;
          case 'contains':
            query[field] = { $regex: `${filter.filter}`, $options: 'i' };
            break;
          case 'notContains':
            if (filter.filterType === 'text') {
              query[field] = { $not: { $regex: `${filter.filter}`, $options: 'i' } };
            }
            break;
          case 'inRange':
            if (filter.filterType === 'date') {
              const startDate = new Date(filter.dateFrom);
              startDate.setHours(0, 0, 0, 0);

              const endDate = new Date(filter.dateTo);
              endDate.setHours(23, 59, 59, 999);

              query[field] = { $gte: startDate, $lte: endDate };
            } else {
              query[field] = {
                $gte: parseFloat(filter.filter) || filter.filter,
                $lte: parseFloat(filter.filterTo) || filter.filterTo
              };
            }
            break;
          case 'blank':
            query[field] = { $in: [null, ''] };
            break;
          case 'notBlank':
            if (filter.filterType === 'text') {
              query[field] = { $exists: true, $nin: [null, ''] };
            }
            break;
          case 'greaterThan':
            if (filter.filterType === 'date') {
              query[field] = {
                $gt: new Date(filter.dateFrom)
              };
            } else {
              query[field] = {
                $gt: parseFloat(filter.filter) || filter.filter
              };
            }
            break;
          case 'greaterThanOrEqual':
            if (filter.filterType === 'number') {
              query[field] = { $gte: parseFloat(filter.filter) || filter.filter };
            }
            break;
          case 'lessThan':
            if (filter.filterType === 'date') {
              query[field] = {
                $lt: new Date(filter.dateFrom)
              };
            } else {
              query[field] = {
                $lt: parseFloat(filter.filter) || filter.filter
              };
            }
            break;
          case 'lessThanOrEqual':
            if (filter.filterType === 'number') {
              query[field] = { $lte: parseFloat(filter.filter) || filter.filter };
            }
            break;
        }
      }
    }
    return query;
  }

  // Define a function to map filter types to MongoDB operators
  getMongoDbOperatorChallenges(filterType: string): string {
    const operatorMap: { [key: string]: string } = {
      'contains': '$regex',
      'notContains': '$not',
      'equals': '',
      'notEqual': '$ne',
      'blank': '$in',
      'notBlank': '$exists'
    };
    return operatorMap[filterType] || '';
  }

  handleSetFilter(query: any, field: string, filter: any) {
    query[`${field}.id`] = { $in: filter.values };
  }

  handleNumberFilter(query: any, field: string, filter: any) {
    const operatorMap: { [key: string]: string } = {
      equals: '',
      notEqual: '$ne',
      greaterThan: '$gt',
      greaterThanOrEqual: '$gte',
      lessThan: '$lt',
      lessThanOrEqual: '$lte',
    };

    const isBlankOrNotBlank = (type: string) => type === 'blank' || type === 'notBlank';
    const isComparisonOperator = (type: string) => Object.keys(operatorMap).includes(type);

    if (isBlankOrNotBlank(filter.type)) {
      query[field] = filter.type === 'blank' ? { $size: 0 } : { $not: { $size: 0 } };
    } else if (filter.type === 'inRange') {
      query[`${field}.c`] = {
        $gte: parseFloat(filter.filter) || filter.filter,
        $lte: parseFloat(filter.filterTo) || filter.filterTo,
      };
    } else if (isComparisonOperator(filter.type)) {
      const value = parseFloat(filter.filter) || filter.filter;
      const operator = operatorMap[filter.type];
      if (operator) {
        query[`${field}.c`] = { [operator]: value };
      } else {
        query[`${field}.c`] = value;
      }
    }
  }

  processLineItemFieldFilter(query: any, filterModel: any, field: string) {
    if (filterModel[field].filterType === 'multi') {
      const filterModels = filterModel[field].filterModels;

      for (const filter of filterModels) {

        if (!filter) return;

        if (filter.filterType === 'set') {
          this.handleSetFilter(query, field, filter);
        } else if (filter.filterType === 'number') {
          this.handleNumberFilter(query, field, filter);
        }
      }
    }
  }

  processSetFilter(query: any, field: string, filter: any, filterParams: any) {
    // Separate the special values '(EMPTY)' and '(NOT EMPTY)' from other values
    const normalValues = filter.values.filter((value: string | number) => value !== '(EMPTY)'  && value !== '(NOT EMPTY)' && value !== -1 && value !== -2);
    const emptyValueIncluded = filter.values.includes('(EMPTY)') || filter.values.includes(-1);
    const notEmptyValueIncluded = filter.values.includes('(NOT EMPTY)') || filter.values.includes(-2);

    // Early exit if no values are defined
    if (normalValues.length === 0 && !emptyValueIncluded && !notEmptyValueIncluded) {
      return false; // Indicate no changes made to query
    }

    if (field === "collection_ref") {
      field = "collection_ref.id";
    }

    // Initialize the field in query if not yet defined
    if (!query[field]) {
      query[field] = {};
    }

    if (field === "env") {
      // Specific logic for "env" field
      if (emptyValueIncluded) {
        query[field] = { $size: 0 };
      } else if (notEmptyValueIncluded) {
        query[field] = { $not: { $size: 0 } };
      } else if (normalValues.length > 0) {
        query[field] = { $all: normalValues, $size: normalValues.length };
      } else {
        // If there are no values, consider removing the field from query
        delete query[field];
      }
    } else {
      // Logic for fields other than "env"
      if (normalValues.length === 0 && (!emptyValueIncluded && !notEmptyValueIncluded)) {
        query.$or = query.$or || [];
        query.$or.push({ [field]: [] }, { [field]: null });
      } else {
        // Handle the normal values
        if (normalValues.length > 0) {
          query[field].$in = normalValues;
        }
        // Handle '(EMPTY)' value
        if (emptyValueIncluded) {
          if (filterParams.isMultiRefFilter) {
            query.$or = query.$or || [];
            query.$or.push(
              { [field]: { $size: 0 } }, // Matches documents where the field is an empty array
              { [field]: null } // Matches documents where the field is null
            );
          } else if (filterParams.isSingleRefFilter) {
            // For single reference fields, an empty value means null
            query[field] = null;
          } else {
            // For non-reference fields or default case
            query[field].$in = [...(query[field].$in || []), null, ''];
          }
        }

        // Handle '(NOT EMPTY)' value
        if (notEmptyValueIncluded) {
          if (filterParams.isMultiRefFilter) {
            // For multi reference fields, non-empty means the array has at least one element
            query[field] = { $ne: null, $exists: true, $not: { $size: 0 } };
          } else if (filterParams.isSingleRefFilter) {
            // For single reference fields, non-empty means the field is not null
            query[field] = { $ne: null };
          } else {
            // For non-reference fields or default case
            query[field].$nin = [null, ''];
          }
        }

      }
    }

    if (query[field] && Object.keys(query[field]).length === 0) {
      delete query[field];
    }
    return true; // Indicate changes were made to query
  }



  transformAggregateFilterModelToMongo(filterModel: any, filtersParamsDict: any, keys: any, sortModel: any): any[] {
    const pipeline: any = [];
    const lookedUpFields: any = [];

    Object.keys(filtersParamsDict).forEach((element: any) => {

      // Skip the iteration if filterKey is "SKIP"
      if (filtersParamsDict[element].filterKey === 'SKIP') {
        return;
      }

      if (filtersParamsDict[element].isAggregate) {
        let joinedName = filtersParamsDict[element].filterKey + '_joined';

        if (!lookedUpFields.includes(joinedName)) {
          pipeline.push({
            $lookup: {
              from: 'miscellaneousbuilds',
              localField: filtersParamsDict[element].filterKey,
              foreignField: '_id',
              as: joinedName
            }
          });

          pipeline.push({
            $unwind: `$${joinedName}`
          });

          lookedUpFields.push(joinedName)
        }
      }

    });

    Object.keys(filtersParamsDict).forEach((element: any) => {
      if (filtersParamsDict[element].isAggregate) {

        let joinedName = '';

        if (filtersParamsDict[element].filterKey !== 'SKIP') {
          joinedName = filtersParamsDict[element].filterKey + '_joined.';
        }

        if (filtersParamsDict[element].isMiscBuild) {

          // Construct an array of conditions for each field key
          const orConditions = filtersParamsDict[element].fieldKeys.map((fieldKey: string) => {
            if (fieldKey === 'id') {
              return {
                [joinedName + fieldKey]: parseInt(filterModel[element].filter, 10) // Parse as integer for id
              };
            } else {
              return {
                [joinedName + fieldKey]: {
                  $regex: filterModel[element].filter,
                  $options: 'i' // 'i' for case-insensitive matching
                }
              };
            }
          });

          // Add the `$or` condition to the pipeline
          pipeline.push({
            $match: {
              $or: orConditions
            }
          });

          pipeline.push({
            $project: { _id: 1 }
          });
        } else if (filtersParamsDict[element].isBuildStatus) {

          const actualValues = filterModel[element].values.map(this.mapFilterLabelsToValues);

          // Create $addFields to summarize statuses
          pipeline.push({
            $addFields: {
              'allFailed': {
                $eq: [
                  ['failed', 'failed', 'failed', 'failed', 'failed'],
                  [
                    '$' + joinedName + 'buildOutput.ios.status',
                    '$' + joinedName + 'buildOutput.and.status',
                    '$' + joinedName + 'buildOutput.win.status',
                    '$' + joinedName + 'buildOutput.mac.status',
                    '$' + joinedName + 'buildOutput.lin.status'
                  ]
                ]
              },
              'anyFailed': {
                $in: ['failed', [
                  '$' + joinedName + 'buildOutput.ios.status',
                  '$' + joinedName + 'buildOutput.and.status',
                  '$' + joinedName + 'buildOutput.win.status',
                  '$' + joinedName + 'buildOutput.mac.status',
                  '$' + joinedName + 'buildOutput.lin.status'
                ]]
              },
              'anyBuilding': {
                $in: ['building', [
                  '$' + joinedName + 'buildOutput.ios.status',
                  '$' + joinedName + 'buildOutput.and.status',
                  '$' + joinedName + 'buildOutput.win.status',
                  '$' + joinedName + 'buildOutput.mac.status',
                  '$' + joinedName + 'buildOutput.lin.status'
                ]]
              },
              'allFinished': {
                $eq: [
                  ['finished', 'finished', 'finished', 'finished', 'finished'],
                  [
                    '$' + joinedName + 'buildOutput.ios.status',
                    '$' + joinedName + 'buildOutput.and.status',
                    '$' + joinedName + 'buildOutput.win.status',
                    '$' + joinedName + 'buildOutput.mac.status',
                    '$' + joinedName + 'buildOutput.lin.status'
                  ]
                ]
              },
              'allQueued': {
                $eq: [
                  ['queued', 'queued', 'queued', 'queued', 'queued'],
                  [
                    '$' + joinedName + 'buildOutput.ios.status',
                    '$' + joinedName + 'buildOutput.and.status',
                    '$' + joinedName + 'buildOutput.win.status',
                    '$' + joinedName + 'buildOutput.mac.status',
                    '$' + joinedName + 'buildOutput.lin.status'
                  ]
                ]
              },
              'isEmpty': {
                $or: [
                  { $eq: ['$' + joinedName + 'buildOutput', null] },
                  { $eq: ['$' + joinedName + 'buildOutput', {}] }
                ]
              }
            }
          });

          // Create the $match stage based on the buildStatus filter
          const matchConditions = actualValues.map((value: any) => {
            switch (value) {
              case 'failed': return { allFailed: true };
              case 'partial-fail': return { $and: [{ anyFailed: true }, { allFailed: false }] };
              case 'building': return { anyBuilding: true };
              case 'empty': return { isEmpty: true };
              case 'finished': return { allFinished: true };
              case 'queued': return { allQueued: true };
              default: return {};
            }
          });

          if (matchConditions.length > 0) {
            pipeline.push({
              $match: { $or: matchConditions }
            });
          }

        } else if (filtersParamsDict[element].isDimension) {
          let matchCondition: any = {}
          matchCondition = this.addDimensionFilter(pipeline, filterModel, element)
          pipeline.push({
            $match: matchCondition
          });
        } else if (filtersParamsDict[element].isMasterBuildDate) {
          this.generateMasterBuildDateAggregateQuery(filtersParamsDict, pipeline, element)
        } else if (filtersParamsDict[element].isAssetUpToDate || filtersParamsDict[element].isImageUpToDate) {
          let matchCondition: any = {}
          let isAssetVersions: boolean = true;

          if (filtersParamsDict[element].isImageUpToDate) {
            isAssetVersions = false;
          }

          matchCondition = this.isAssetUpToDateFilter(pipeline, filterModel, joinedName, isAssetVersions, filtersParamsDict[element]);
        }
      }
    });

    if (sortModel) {
      this.addSortModelToPipeline(pipeline, sortModel)
    }

    return pipeline;

  }

  isAssetUpToDateFilter(pipeline: any, filterModel: any, joinedName: string, isAssetVersions: boolean, filterParams: any) {
    console.log('isAssetUpToDate FilterModel: ', filterModel);
    console.log('isAssetUpToDate FilterParams: ', filterParams);

    // Add new fields: lastAssetVersion and lastHash from the joined document
    let versions_key: string = isAssetVersions ? 'asset_versions' : 'image_versions';
    let assetVersionKey: string;
    let lastHashKey: string;


    if (filterParams.filterKey === 'SKIP') {
      assetVersionKey = "$" + versions_key;
      lastHashKey = "$lastHash";
    } else {
      assetVersionKey = "$" + joinedName + "." + versions_key;
      lastHashKey = "$" + joinedName + ".lastHash";
    }


    pipeline.push({
      "$addFields": {
        "lastAssetVersion": {
          "$ifNull": [
            { "$arrayElemAt": [assetVersionKey, -1] },
            { "destinationPath": "QXQXQX" }
          ]
        },
        "lastHash": {
          "$ifNull": [lastHashKey, "ZXZXZX"]
        }
      }
    });


    const filterValues = isAssetVersions ? filterModel.isAssetUpToDate.values : filterModel.isImageUpToDate.values;

    filterValues.forEach((value: any) => {
      switch (value) {
        case "Up to date":
          pipeline.push({
            "$match": {
              "$expr": {
                "$gte": [
                  {
                    "$indexOfBytes": ["$lastAssetVersion.destinationPath", "$lastHash"]
                  },
                  0
                ]
              }
            }
          });
          break;

        case "No data":
          pipeline.push({
            "$match": {
              "$expr": {
                "$and": [
                  {
                    "$eq": ["$lastHash", "ZXZXZX"]
                  },
                  {
                    "$eq": ["$lastAssetVersion.destinationPath", "QXQXQX"]
                  }
                ]
              }
            }
          });
          break;

        case "Outdated":
          pipeline.push({
            "$match": {
              "$expr": {
                "$and": [
                  {
                    "$lt": [
                      { "$indexOfBytes": ["$lastAssetVersion.destinationPath", "$lastHash"] }, 0
                    ]
                  },
                  { "$ne": ["$lastAssetVersion.destinationPath", "QXQXQX"] }
                ]
              }
            }
          });
          break;

        default:
          // Do nothing for unrecognized values
          break;
      }
    });
  }

  addDimensionFilter(pipeline: any, filterModel: any, dimensionName: any) {
    let matchCondition = {};
    if (filterModel[dimensionName]) {
      const type = filterModel[dimensionName].type;
      const value = filterModel[dimensionName].filter;
      const valueTo = filterModel[dimensionName]?.filterTo;

      // Create $addFields to convert dimension to number (if needed)
      pipeline.push({
        $addFields: {
          [`${dimensionName}AsNumber`]: { $toDouble: `$prefab_ref_joined.${dimensionName}` }
        }
      });



      switch (type) {
        case 'lessThan':
          matchCondition = { [`${dimensionName}AsNumber`]: { $lt: value } };
          break;
        case 'lessThanOrEqual':
          matchCondition = { [`${dimensionName}AsNumber`]: { $lte: value } };
          break;
        case 'greaterThan':
          matchCondition = { [`${dimensionName}AsNumber`]: { $gt: value } };
          break;
        case 'greaterThanOrEqual':
          matchCondition = { [`${dimensionName}AsNumber`]: { $gte: value } };
          break;
        case 'inRange':
          matchCondition = { [`${dimensionName}AsNumber`]: { $gte: parseFloat(value), $lte: parseFloat(valueTo) } };
          break;
        case 'blank':
          matchCondition = { [`${dimensionName}AsNumber`]: { $eq: null } };
          break;
        case 'notBlank':
          matchCondition = { [`${dimensionName}AsNumber`]: { $ne: null } };
          break;
        default:
        // Handle other cases or do nothing
      }
    }
    return matchCondition
  }

  mapFilterLabelsToValues(label: string): string {
    switch (label) {
      case 'Queued': return 'queued';
      case 'Building': return 'building';
      case 'Finished': return 'finished';
      case 'Full Fail': return 'failed';
      case 'Partial Fail': return 'partial-fail';
      case '(EMPTY)': return 'empty';
      default: return label.toLowerCase();
    }
  }

  generateMasterBuildDateAggregateQuery(filterParamsDict: any, pipeline: any[], element: any) {
    if (filterParamsDict[element]) {
      const startDate = new Date(filterParamsDict[element].dateFrom);

      let dateStringPath = "$prefab_ref_joined.buildOutput.ios.finishedAt";
      if (filterParamsDict[element].filterKey == "SKIP") {
        dateStringPath = "$buildOutput.ios.finishedAt";
      } else {
        dateStringPath = "$" + filterParamsDict[element].filterKey + "_joined.buildOutput.ios.finishedAt";
      }

      // Add an $addFields stage to convert the string to a date
      pipeline.push({
        $addFields: {
          "finishedAtAsDate": {
            $dateFromString: {
              dateString: dateStringPath
            }
          }
        }
      });

      switch (filterParamsDict[element].type) {
        case 'equals':
          startDate.setHours(0, 0, 0, 0);
          const endDate = new Date(filterParamsDict[element].dateFrom);
          endDate.setHours(23, 59, 59, 999);

          // Add a $match stage to filter based on the converted date
          pipeline.push({
            $match: {
              "finishedAtAsDate": {
                $gte: startDate,
                $lte: endDate
              }
            }
          });
          break;

        case 'greaterThan':
          startDate.setHours(0, 0, 0, 0);

          // Add a $match stage to filter based on the converted date
          pipeline.push({
            $match: {
              "finishedAtAsDate": {
                $gt: startDate
              }
            }
          });
          break;

        case 'lessThan':
          startDate.setHours(23, 59, 59, 999);

          // Add a $match stage to filter based on the converted date
          pipeline.push({
            $match: {
              "finishedAtAsDate": {
                $lt: startDate
              }
            }
          });
          break;

        case 'notEqual':
          startDate.setHours(0, 0, 0, 0);
          const endDateNe = new Date(filterParamsDict[element].dateFrom);
          endDateNe.setHours(23, 59, 59, 999);

          // Add a $match stage to filter based on the converted date
          pipeline.push({
            $match: {
              "finishedAtAsDate": {
                $not: {
                  $gte: startDate,
                  $lte: endDateNe
                }
              }
            }
          });
          break;

        case 'inRange':
          startDate.setHours(0, 0, 0, 0);
          const endDateInRange = new Date(filterParamsDict[element].dateTo);
          endDateInRange.setHours(23, 59, 59, 999);

          // Add a $match stage to filter based on the converted date
          pipeline.push({
            $match: {
              "finishedAtAsDate": {
                $gte: startDate,
                $lte: endDateInRange
              }
            }
          });
          break;

        default:
          break;
      }
    }
  }

  // Helper function to transform ag-Grid sort model to MongoDB sort format
  transformSortModelToMongo(sortModel: any[]): any {
    if (sortModel.length === 0) {
      return { id: 1 }; // Default sort order
    }

    return sortModel.reduce((acc, s) => {
      acc[s.colId] = s.sort === 'asc' ? 1 : -1;
      return acc;
    }, {});
  }


  addSortModelToPipeline(pipeline: any[], sortModel: any) {
    const buildDateSortKeys = ["masterBuildDate"];
    let addFieldsExists = false;

    // Check if $addFields with 'finishedAtAsDate' already exists in the pipeline
    for (const stage of pipeline) {
      if (stage.$addFields && stage.$addFields.finishedAtAsDate) {
        addFieldsExists = true;
        break;
      }
    }

    if (buildDateSortKeys.some(key => sortModel.hasOwnProperty(key))) {
      let dateStringPath = "$buildOutput.ios.finishedAt";

      // If it doesn't exist, push the new $addFields stage
      if (!addFieldsExists) {
        pipeline.push({
          $addFields: {
            "finishedAtAsDate": {
              $dateFromString: {
                dateString: dateStringPath
              }
            }
          }
        });
      }

      const sortKey = Object.keys(sortModel).find(key => buildDateSortKeys.includes(key));
      if (sortKey) {
        pipeline.push({
          $sort: {
            "finishedAtAsDate": sortModel[sortKey]
          }
        });
      }
    }
  }
}

