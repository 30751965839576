import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import GlobalSearchResults from '../dtos/GlobalSearchResults';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  private readonly apiURLprefix : string = '/api/global-search';
  constructor(private http: HttpClient) {}

  /**
   * Perform a search query on multiple entities.
   *
   * @param queryValue The search query value
   */
  async globalSearch(queryValue: any, isFullSearch: boolean = false): Promise<any> {
    return await this.http.get<any>(`${this.apiURLprefix}?value=${queryValue}&isFullSearch=${isFullSearch}`).toPromise();
  }

  async seriesOnlySearch(queryValue: any, isFullSearch: boolean = false): Promise<any> {
    return await this.http.get<any>(`${this.apiURLprefix}/series-only?value=${queryValue}&isFullSearch=${isFullSearch}`).toPromise();
  }

  /**
   * Perform a search query on multiple entities.
   *
   * @param queryValue The search query value
   */
  async challengeSearch(queryValue: any): Promise<any> {
    return await this.http.get<any>(`${this.apiURLprefix}/challenges?value=${queryValue}`).toPromise();
  }


}
