import { Component, OnInit } from '@angular/core';
import { BaseDynamicViewComponent } from 'src/app/common/components/dynamic-view-v2/components/base-dynamic-form/base-dynamic-view.component';
import { DynamicCardType } from 'src/app/common/components/dynamic-form-v2/enums/DynamicCardType';
import { DynamicViewType } from 'src/app/common/components/dynamic-view-v2/enums/DynamicViewType';
import BaseEntityDto from 'src/app/common/dtos/BaseEntityDto';
import { BaseViewFieldType } from 'src/app/common/components/dynamic-view-v2/enums/BaseViewFieldType';
import { BuildType } from 'src/app/enums/build-type';

@Component({
  selector: 'app-mini-game-view-v2',
  templateUrl: './mini-game-view-v2.component.html',
  styleUrls: ['./mini-game-view-v2.component.sass']
})
export class MiniGameViewV2Component  extends BaseDynamicViewComponent implements OnInit
{
  miniGameRecord: BaseEntityDto = new BaseEntityDto();
  constructor()
  {
    super();
  }

  /**
   * MiniGame View V2 Initial Implementation
   */
  ngOnInit()
  {
    this.fields =
    [
      {
        title: "MiniGame Data",
        fields:
        [
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'name', isRequired: true, label: 'Name', columnWidth: 4 },
          // { name: "Move Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'moveName', isRequired: true, label: 'Move Name', columnWidth: 4 },
          {
            name: "Type", inputType: DynamicViewType.BaseViewField, viewTypes: [BaseViewFieldType.DropdownWithLabels],
            clearField: true,
            key: 'type',
            label: 'Type',
            filter: true,
            autoDisplayFirst: false,
            optionLabel: 'name',
            optionValue: 'value',
            isRequired: true,
            options: { fieldName: 'type', values: [ { name: 'Match the Plant', value: 1 }, { name: 'Mole Hunt', value: 2 }, { name: 'Sample Game', value: 3 },] },
            columnWidth: 3
          },
          { name: "Start Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'start', label: 'Start Date/Time',columnWidth: 6 },
          { name: "End Date/Time", inputType: DynamicViewType.BaseViewField,viewTypes: [BaseViewFieldType.Calendar], clearField: true, key: 'end', label: 'End Date/Time',columnWidth: 6 },
          { name: "Download URL", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'downloadUrl', isRequired: true, label: 'Download URL', columnWidth: 12 },
          { name: "Min. Level Requirement", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'minLevel', isRequired: true, label: 'Min. Level Requirement', columnWidth: 3 },
          { name: "Min. Moves", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'minMoves', isRequired: true, label: 'Min. Moves', columnWidth: 3 },
          { name: "Mover per Round", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'movesPerRound', isRequired: true, label: 'Min. Moves', columnWidth: 3 },
          { name: "Reward Loop Multiplier", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'rewardLoopMultiplier', isRequired: true, label: 'Reward Loop Multiplier', columnWidth:3 },
          { name: "Name", inputType: DynamicViewType.BaseViewField , viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'rewardLoopCap', isRequired: true, label: 'Reward Loop Cap', columnWidth: 3 },
          {
            name: "Title Localization Key",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'titleLocalizationKey',
            isRequired: true,
            label: 'Title Localization Key',
            columnWidth: 6
          },
          {
            name: "Welcome Popup Description",
            inputType: DynamicViewType.BaseViewField ,
            viewTypes: [BaseViewFieldType.Text],
            clearField: true,
            key: 'welcomePopupDescription',
            isRequired: true,
            label: 'Welcome Popup Description',
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Imagery",
        fields:
        [
          {
            name: "Banner Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'banner_ref',
            label: 'Banner Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'banner_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
          {
            name: "Logo Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'logoImage_ref',
            label: 'Logo Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'logoImage_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
          {
            name: "Banner Background Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'welcomePopupImage_ref',
            label: 'Banner Background Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'welcomePopupImage_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
          {
            name: "Welcome Popup Image",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'welcomePopupImage_ref',
            label: 'Welcome Popup Image',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'welcomePopupImage_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
          {
            name: "Welcome Popup Header",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'welcomePopupHeader_ref',
            label: 'Welcome Popup Header',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'welcomePopupHeader_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
          {
            name: "Welcome Popup Background",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'welcomePopupBackground_ref',
            label: 'Welcome Popup Background',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'welcomePopupBackground_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
          // {
          //   name: "Move Image",
          //   inputType: DynamicViewType.MiscImageRecordV2,
          //   viewMode: 'record',
          //   clearField: true,
          //   key: 'moveImage_ref',
          //   label: 'Move Image',
          //   optionValue:'_id',
          //   filter: true,
          //   filterBy:'name,id,path',
          //   autoDisplayFirst: false,
          //   options: {
          //     fieldName: 'moveImage_ref', apiController: 'miscellaneous-build',
          //     customQuery: { entityType: BuildType.Images },
          //     autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
          //   },
          //   columnWidth: 6
          // },
          {
            name: "Bar Background",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'barBackground_ref',
            label: 'Bar Background',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'barBackground_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
          {
            name: "Bar Overlay",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'barOverlay_ref',
            label: 'Bar Overlay',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'barOverlay_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
          {
            name: "Game Icon",
            inputType: DynamicViewType.MiscImageRecordV2,
            viewMode: 'record',
            clearField: true,
            key: 'gameIcon_ref',
            label: 'Game Icon',
            optionValue:'_id',
            filter: true,
            filterBy:'name,id,path',
            autoDisplayFirst: false,
            options: {
              fieldName: 'gameIcon_ref', apiController: 'miscellaneous-build',
              customQuery: { entityType: BuildType.Images },
              autopopulate: false, virtuals: false, sort: { name: 1 }, select: 'name id path _id'
            },
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Costs & Rewards",
        fields:
        [
          {
            name: 'Cost(s)',
            label: 'Cost(s)',
            inputType: DynamicViewType.Price,
            key: 'costs_ref',
          },
          {
            name: 'Reward(s)',
            label: 'Reward(s)',
            inputType: DynamicViewType.Price,
            key: 'rewards_ref',
          },
          {
            name: "Rewards Track",
            inputType: DynamicViewType.MiscRef,
            clearField: true,
            key: 'rewardsTrack_ref',
            label: 'Rewards Track',
            controller: 'rewards-track',
            columnWidth: 6
          },
        ],
        type: DynamicCardType.Card
      },
      {
        title: "Mini Game Metadata",
        fields:
        [
          {
            name: "JSON", inputType: DynamicViewType.BaseViewField,
            viewTypes: [BaseViewFieldType.Text],  clearField: true, key: 'metadata', isRequired: true, label: 'JSON'
          },
        ],
        type: DynamicCardType.Card
      }
    ]
    // This will help us to define fields
    // with pre-established values.
    // This is just an example, there is no
    // need to add it if it's not necessary.
    this.miniGameRecord =
    {
      enabled: false
    };
    this.title = "MiniGame";
    this.viewRoute = 'minigames';
    this.isLoading = false;
  }

  /**
   * Validate MiniGame required field.
   *
   * @param miniGame MiniGame record
   */
  validateMiniGame(miniGame: any)
  {
    return miniGame && miniGame.name && miniGame.name.length > 0;
  }
}
