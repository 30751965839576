
<h1>Advanced Search</h1>

<div class="p-grid">
    <div class="p-col-12">
         <!-- CARD: Top Details -->
         <p-card>
            <!-- Search Input -->
            <div class="p-inputgroup">
                <span class="p-float-label p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" id="inputtext-left" pInputText [(ngModel)]="globalSearchQueryValue" (ngModelChange)="globalSearch()"> 
                    <label for="inputtext-left">Full Search</label>
                </span>
            </div>
        </p-card>
        <!-- Results -->
        <p-card class="p-mx-2" *ngIf="!isLoading && fullSearchResult">
            <div class="p-grid">
                <div class="p-col-12 p-pr-2">
                    <p-tabView>
                        <p-tabPanel header="{{view}}" *ngFor="let view of views; let i = index">
                            <div class="card" *ngIf="view == 'All'">
                                <p-table [value]="fullSearchResult.FullResults" rowGroupMode="rowspan" groupRowsBy="apiControllerRoute" responsiveLayout="scroll">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Entity</th>
                                            <th>Name</th>
                                            <th>Id</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template
                                        pTemplate="body"
                                        let-record
                                        let-rowIndex="rowIndex"
                                        let-rowgroup="rowgroup"
                                        let-rowspan="rowspan"
                                    >
                                        <tr *ngIf="(record.apiControllerRoute === 'items' && authService.hasPermission(['read'], 'ItemController')) || (record.apiControllerRoute === 'challenges' && authService.hasPermission(['read'], 'ChallengeController'))">
                                            <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                                                <span class="font-bold ml-2">{{record.apiControllerRoute.toUpperCase()}}</span>
                                            </td>
                                            <td>
                                                <a
                                                    [href]="record.apiControllerRoute + '/' + record.id"
                                                    target="_blank"
                                                    [ngStyle]="{ color: 'var(--primary-color)' }"
                                                >
                                                    {{record.name}}
                                                </a>
                                            </td>
                                            <td>
                                                {{record.id}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            <div
                                class="card"
                                *ngIf="view != 'All' && i != 0 && fullSearchResult.GlobalSearchResults && fullSearchResult.GlobalSearchResults.GroupedEntities && fullSearchResult.GlobalSearchResults.GroupedEntities.length > 0"
                            >
                                <p-table [value]="fullSearchResult.GlobalSearchResults.GroupedEntities[i - 1].items" responsiveLayout="scroll">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th>Name</th>
                                            <th>ID</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-record>
                                        <tr *ngIf="(record.apiControllerRoute === 'items' && authService.hasPermission(['read'], 'ItemController')) || (record.apiControllerRoute === 'challenges' && authService.hasPermission(['read'], 'ChallengeController'))">
                                            <td>
                                                <a
                                                    [href]="record.apiControllerRoute + '/' + record.id"
                                                    target="_blank"
                                                    [ngStyle]="{ color: 'var(--primary-color)' }"
                                                >
                                                    {{record.name}}
                                                </a>
                                            </td>
                                            <td>
                                                {{record.id}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </p-card>
    </div>
</div>