import { Component, OnInit } from '@angular/core';
import { ColDef } from 'ag-grid-enterprise';
import { BooleanEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/boolean/boolean.component';
import { CalendarEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/calendar/calendar.component';
import { DropdownEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/dropdown/dropdown.component';
import { MultiselectEditorComponent } from 'src/app/common/ag-grid-table/cell-editors/multiselect/multiselect.component';
import { LinkCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/link/link.component';
import { MiscAssetCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/misc-asset/misc-asset.component';
import { RefsCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/refs/refs.component';
import { TextCellRendererComponent } from 'src/app/common/ag-grid-table/cell-renderers/render-components/text/text.component';
import { AgGridToolsService } from 'src/app/common/ag-grid-table/services/ag-grid-tools.service';
import { OptionsParams, UtilitiesService } from 'src/app/common/services/utilities.service';

@Component({
  selector: 'app-nurture-collection-groups-ag-grid',
  templateUrl: './nurture-collection-groups-ag-grid.component.html',
  styleUrls: ['./nurture-collection-groups-ag-grid.component.sass']
})
export class NurtureCollectionGroupsAgGridComponent implements OnInit {

  public entity: string = 'nurture-collection-groups';
  public bulkUpdateColDefs: any[] = [];
  public columnDefs: ColDef[] = [];
  public columnSelection: string;
  public autopopulateSelect: string = '';
  public buildParams: any = {};
  public tableParams: any = {};
  private options: any = {};


  constructor(
    private agGridToolsService: AgGridToolsService,
    private utilitiesService: UtilitiesService,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.setOptions();
    this.columnDefs = [
      {
        headerName: 'ID',
        field: 'id',
        cellRendererParams: {
          entity: this.entity
        },
        cellRenderer: LinkCellRendererComponent,
        sortable: true,
        initialSort: 'desc',
        sortIndex: 0,
        initialSortIndex: 0,
        sort: 'desc',
        sortingOrder: ['asc', 'desc'],
        unSortIcon: false,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'equals',
            'notEqual',
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange'
          ]
        },
        resizable: true,
        floatingFilter: true,
        suppressSizeToFit: false,
      },
      {
        headerName: 'Name',
        field: 'name',
        cellRendererParams: {
          entity: 'nurture-collection-groups'
        },
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Start',
        field: 'start',
        sortable: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: this.agGridToolsService.dateValueGetter('start'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'End',
        field: 'end',
        sortable: true,
        filter: 'agDateColumnFilter',
        floatingFilter: true,
        cellRenderer: TextCellRendererComponent,
        valueGetter: this.agGridToolsService.dateValueGetter('end'),
        resizable: true,
        cellEditor: CalendarEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Enabled',
        field: 'enabled',
        cellRenderer: TextCellRendererComponent,
        cellRendererParams: {
          onLabel: 'Enabled',
          offLabel: 'Not Enabled',
          isBoolean: true
        },
        sortable: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Enabled' : 'Not Enabled'
        },
        floatingFilter: true,
        cellEditor: BooleanEditorComponent,
        type: 'editableColumn',
      },
      {
        headerName: 'Environment(s)',
        field: 'env',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        cellEditor: 'agTextCellEditor',
        type: 'editableColumn',
      },
      {
        headerName: 'Blurred Image',
        field: 'blurredImage_ref',
        cellRenderer: MiscAssetCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        filterParams: {
          filterKey: 'blurredImage_ref',
          isAggregate: true,
          fieldKeys: ['name', 'id', 'path' ],
          isMiscBuild: true,
          values: this.options.welcomePopupImage_ref,
          populate: true,
          select: 'id name _id path'
        },
        width: 500,
        autoHeight: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorPopup: false,
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
      },
      {
        headerName: 'Regions (Nurture Collections)',
        field: 'nurtureCollections_ref',
        cellRenderer: RefsCellRendererComponent,
        cellRendererParams: {
          entity: 'nurture-collections',
          isArray: true,
        },
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: this.options.nurtureCollections_ref,
          valueFormatter: (params: any) => {
            return `${params.value.name} (${params.value.id})`;
          },
          keyCreator: (params: any) => {
            return params.value._id;
          },
          comparator: this.agGridToolsService.nameASC,
          isMultiRefFilter: true,
          addEmptyFilters: true,
          populate: true,
          select: 'id name'
        },
        floatingFilter: true,

        cellEditor: MultiselectEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: "_id",
          optionLabel: "name",
          objectValue: true
        },
        width: 450,
        autoHeight: true,
      },
      {
        headerName: 'Unlock Condition',
        field: 'unlockCondition',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        resizable: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: ['Grow [x] unique plants', 'Grow [x] plants', 'Grow 0 plants'],
        },
        floatingFilter: true,
        cellEditor: DropdownEditorComponent,
        type: 'editableColumn',
        cellEditorParams: {
          optionValue: 'value',
          optionLabel: 'name',
          values: [
            { name: 'Grow [x] unique plants', value: 'Grow [x] unique plants' },
            { name: 'Grow [x] plants', value: 'Grow [x] plants' },
            { name: 'Grow 0 plants', value: 'Grow 0 plants' },
          ],
        },
      },
      {
        headerName: 'Unlock Threshold',
        field: 'unlockThreshold',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          filterKey: 'priority',
        },
        floatingFilter: true,

        autoHeight: true,
        type: 'editableColumn',
        cellEditor: "agNumberCellEditor",
      },
      {
        headerName: 'Unlock Condition Phrase',
        field: 'unlockConditionPhrase',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        resizable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        width: 300,
      },
      {
        headerName: 'priority',
        field: 'priority',
        cellRenderer: TextCellRendererComponent,
        sortable: true,
        unSortIcon: true,
        resizable: true,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: [
            'lessThan',
            'lessThanOrEqual',
            'greaterThan',
            'greaterThanOrEqual',
            'inRange',
            'blank',
            'notBlank'
          ],
          filterKey: 'priority',
        },
        floatingFilter: true,

        autoHeight: true,
        type: 'editableColumn',
        cellEditor: "agNumberCellEditor",
      },
    ];

    this.bulkUpdateColDefs = [
      {
        name: 'Environment(s)', id: 'env', type: 'multiselect', isFlatList: true
      },
    ];

    this.buildParams = {
      // assetKeys: ['prefab_ref'],
      // assetBuildType: BuildType.Items,
      // renderBuildType: BuildType.Items,
      // imageKeys: ['thumbnail_ref'],
      // imageBuildType: BuildType.Images
    }

    this.columnSelection = this.agGridToolsService.getColumnFieldNamesFromCols(this.columnDefs);
  }
  async setOptions(){
    const entities = [
      { ref: 'nurtureCollections_ref', entity: 'nurture-collection-groups' },
    ];

    for (const { ref, entity } of entities) {
      const params = new OptionsParams({
        entity,
      });
      await this.utilitiesService.getOptions(this.options, ref, params);
    }
  }
}
