import { AssetTypes } from "src/app/entities/enums/AssetTypes";

/**
 * Table configuration DTO
 */
export default class TableConfiguration
{
    /**
     * Flag that sets whether or not to load thumbnails
     */
    loadThumbnails: boolean = false;
    /**
     * List of all filters that needs to be register on the table
     */
    customFilterToRegister: Array<string> = [];
    /**
     * Custom Build data keys
     */
    customBuildDataKeys: Array<string> = [];
    /**
     * Flag that sets whether table is in full Edit Mode
     */
    isEditMode: boolean = false;
    /**
     * Flag that sets whether "Edit Row" column is hidden.
     */
    hideEditRow: boolean = false;
    /**
     * Flag that sets whther or not to show build actions
     */
    isBuildable: boolean = false;
    /**
     * Asset type
     */
    assetType: AssetTypes;
    /**
     * Flag that sets whether or not add new row is enabled
     */
    addNewRowEnabled: boolean = false;
    /**
     * Flag that sets whether or not duplicate row is enabled
     */
    duplicateRowEnabled: boolean = false;
    /**
     * Flag that sets whether or not to fetch virtual columns.
     */
    fetchVirtuals: boolean = true;
    /**
     * Flag that sets whether or not to autopopulate entities.
     */
    autopopulateEntities: boolean = true;
    /**
     * Flag that sets whether or not to use the dynamic table columns feature.
     */
    useDynamicTableColumns: boolean = true;
    /**
     * Table default columns.
     */
    defaultColumns: Array<string> = [];
}
