<div>
  <!-- EO Reward Track Rewards -->
  <div *ngIf="!isLoading; else isLoadingMsg">
    <div class="p-grid">
      <div class="p-col-8">
        <div class="flex-container p-ml-2 p-mb-2">
          <p class="label">Is Loop</p>
          <p-inputSwitch class="field-item" [(ngModel)]="endlessOfferTrackRecord.isLoop"
            [ngModelOptions]="{ standalone: true }" (ngModelChange)="onChange('isLoop', $event);"></p-inputSwitch>
        </div>
        <p-orderList [value]="endlessOfferTrackRecord['EObundles']" [listStyle]="{ 'max-height': '50rem' }"
          [dragdrop]="true" (onReorder)="reOrderLists($event); onChange('EObundles', {}); onChange('EOrewards', {});">
          <!-- Custom header template with the "add bundle" button -->
          <ng-template pTemplate="header">
            <div class="p-clearfix">
              <span style="font-size: 25px;">Steps</span>
              <!-- Add bundle button -->
              <p-button *ngIf="endlessOfferTrackRecord['EObundles'].length == 0" label="Add Step" class="p-ml-5"
                icon="pi pi-plus"
                (click)="addBundle(); onChange('EObundles', {}); onChange('EOrewards', {});"></p-button>
            </div>
          </ng-template>
          <ng-template let-bundle let-i="index" pTemplate="item">
            <div #bundleItem class="flex justify-items-center p-2 w-full flex-wrap">
              <div class="w-full text-center">
                <div class="p-grid">
                  <div class="p-col-12 p-d-flex p-ai-center p-jc-start">
                    <h4 style="margin: 0;">Step Type</h4>
                    <p-dropdown [(ngModel)]="bundle.type" [options]="types" placeholder="Select Type"
                      (onChange)="onTypeChange($event, i); onChange('EObundles', $event); onChange('EOrewards', {});"
                      appendTo="body" class="p-ml-2"></p-dropdown>
                    <div [ngStyle]="{'margin-left': 'auto'}">
                      <p-button label="Remove Step" icon="pi pi-times" styleClass="p-button-danger p-button-sm"
                        (onClick)="removeBundle(i)"></p-button>
                      <p-button *ngIf="i === endlessOfferTrackRecord['EObundles'].length - 1" label="Add Step"
                        icon="pi pi-plus" styleClass="p-button-success p-button-sm p-ml-4"
                        (onClick)="addBundle(); onChange('EObundles', {}); onChange('EOrewards', {});"></p-button>
                    </div>
                  </div>
                </div>

                <!-- if bundle type "free"-->
                <div *ngIf="bundle.type === 'free'">
                  <prizes-card-form-group [(prize)]="endlessOfferTrackRecord.EOrewards[i]" [showPrize]="true"
                    [fieldsetName]="'Step #'+(i+1)+' Rewards'" [excludeResourceTypes]="null"
                    (onPrizeChange)="onChange('EOrewards', $event); endlessOfferTrackRecord.EOrewards[i] = $event;"
                    [version]="3">
                  </prizes-card-form-group>
                </div>
                <!-- else if bundle type "paid" -->
                <div *ngIf="bundle.type === 'paid'">
                  <div class="p-grid">
                    <div class="p-col-4">
                      <h5 class="p-mb-2">Store Listing</h5>
                      <p-dropdown [(ngModel)]="endlessOfferTrackRecord.EOrewards[i][0].id._id"
                        (onChange)="onSelectPaidOption($event, i); onChange('EObundles', $event); onChange('EOrewards', $event);"
                        [options]="dropdownOptions['storeListing_ref']" [optionLabel]="'name'" [optionValue]="'_id'"
                        [filter]="true" [filterBy]="'name'" appendTo="body">
                      </p-dropdown>
                    </div>
                    <div class="p-col-8">
                      <div class="p-grid">
                        <div class="p-col-12">
                          <div
                            *ngIf="endlessOfferTrackRecord['EOrewards'][i] && endlessOfferTrackRecord.EOrewards[i][0].id"
                            style="border-radius: 8px; box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); background-color: #8c8c8c99;">
                            <div
                              style="display: flex; justify-content: space-between; align-items: center; padding: 4px 8px; border-top-left-radius: 8px; border-top-right-radius: 8px;">
                              <span style="font-size: 16px; margin: 0;">
                                <strong>Store Listing ID:</strong> {{ endlessOfferTrackRecord.EOrewards[i][0].id.name }}
                              </span>
                            </div>
                            <div class="p-grid" class="p-px-1 p-py-1">
                              <div class="p-col-12 p-grid">
                                <div class="p-col-5">
                                  <div>
                                    <p style="display: block;" class="p-my-1">
                                      <!-- <strong>Start:</strong> {{ endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.DisplayDetails?.start }} -->
                                      <strong>Start:</strong> {{
                                      endlessOfferTrackRecord['EOrewards'][i][0]?.id?.storeStart?.test }}
                                    </p>
                                  </div>
                                  <div>
                                    <p style="display: block;" class="p-my-1">
                                      <!-- <strong>End:</strong> {{ endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.DisplayDetails?.end }} -->
                                      <strong>End:</strong> {{
                                      endlessOfferTrackRecord['EOrewards'][i][0]?.id?.storeEnd?.test }}
                                    </p>
                                  </div>
                                  <div
                                    *ngIf="endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.PricePointName">
                                    <!-- <p style="display: block;" class="p-my-1">
                                      <strong>Price Point:</strong>
                                      <a [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank"
                                      href="https://admin.dev.petunia.withbuddies.com/Economy/PricePoint/?name={{ endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.PricePointName }}"
                                    > {{ endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.PricePointName }}</a>
                                    </p> -->
                                    <p style="display: block;" class="p-my-1">
                                      <strong>Price Point:</strong>
                                      <a [ngStyle]="{ color: 'var(--primary-color)' }" target="_blank"
                                        href="/price-points/{{ endlessOfferTrackRecord['EOrewards'][i][0]?.pricePoint.id }}">
                                        {{ endlessOfferTrackRecord['EOrewards'][i][0]?.pricePoint.name }}</a>
                                    </p>
                                  </div>
                                  <!-- <div *ngIf="endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.OriginalStoreListingName">
                                    <p style="display: block;" class="p-my-1">
                                      <strong>Original Store Listing ID:</strong>
                                      <a [ngStyle]="{ color: 'var(--primary-color)' }"  target="_blank"
                                        href="https://admin.dev.petunia.withbuddies.com/Economy/StoreListing/?name={{ endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.OriginalStoreListingName }}"
                                      > {{ endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.OriginalStoreListingName }}</a>
                                    </p>
                                  </div>
                                  <div *ngIf="endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.DisplayDetails?.backgroundHex">
                                    <p style="display: block;" class="p-my-1">
                                      <strong>Background Hex:</strong> {{endlessOfferTrackRecord['EOrewards'][i][0].storeListingData.DisplayDetails.backgroundHex}}
                                      <span
                                        [ngStyle]="{'background-color': endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.DisplayDetails.backgroundHex, 'border-radius': '50%', display: 'inline-block', width: '15px', height: '15px'}"
                                      >
                                      </span>
                                    </p>
                                  </div>
                                  <div  *ngIf="endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.DisplayDetails?.referencePricePointText">
                                    <p style="display: block;" class="p-my-1">
                                      <strong>Reference Price Point:</strong> {{ endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.DisplayDetails.referencePricePointText }}
                                    </p>
                                  </div> -->
                                </div>
                                <div class="p-col-7">
                                  <div style="display: block;"
                                    *ngIf="endlessOfferTrackRecord['EOrewards'][i][0]?.id?.credits_ref">
                                    <strong class="p-my-1">Credits:</strong>
                                    <ul style="list-style-type: none; padding: 0; font-size: 14px;">
                                      <!-- <li *ngFor="let credit of endlessOfferTrackRecord['EOrewards'][i][0]?.storeListingData?.Credits.LineItems" style="margin-bottom: 5px;"> -->
                                      <!-- <li *ngFor="let credit of endlessOfferTrackRecord.EOrewards[i][0].id.credits_ref.qa" style="margin-bottom: 5px;"> -->
                                      <li *ngFor="let credit of rewards_credits[i]" style="margin-bottom: 5px;">
                                        <img *ngIf="credit.thumbnail" src="{{credit.thumbnail}}"
                                          style="height: 40px; width: 40px; vertical-align: middle;" alt="d">
                                        <span
                                          style="
                                          background-color: var(--text-color); border-radius: 14%; padding: 1px 5px; font-weight: 700; line-height: 21.25px; font-size: 12px;
                                          color: var(--primary-color-text); text-align: center; margin-left: 5px; margin-right: 3px;  ">
                                          <strong class="p-m-auto">{{credit.c}}</strong>
                                        </span>
                                        <!-- <span *ngIf="credit.displayName">
                                          {{credit.displayName}}
                                          <a
                                            href="{{credit.link}}"
                                            target="_blank" [ngStyle]="{ color: 'var(--primary-color)' }"
                                          > -->
                                        <!-- ({{credit.link.substring(0, 2)}}) -->
                                        <!-- ({{ credit.link.split('/')[1] }}) -->
                                        <!-- </a>
                                        </span> -->
                                        <span *ngIf="credit.lineItemType === 'SeedPackBox'">
                                          {{credit.id.name}}
                                          <a href="seed-box-pack/{{credit.id.id}}" target="_blank"
                                            [ngStyle]="{ color: 'var(--primary-color)' }">
                                            ({{ credit.id.id}})
                                          </a>
                                        </span>
                                        <span *ngIf="credit.displayName && credit.lineItemType !== 'SeedPackBox'">
                                          {{credit.displayName}}
                                          <a href="{{credit.link}}" target="_blank"
                                            [ngStyle]="{ color: 'var(--primary-color)' }">
                                            ({{ credit.link.split('/')[1] }})
                                          </a>
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </ng-template>
        </p-orderList>
      </div>
      <div class="p-col-4">

        <div class="p-grid">
          <!-- Looping through endlessOfferTrackRecord['EOrewards'] to create grid cells for each bundle -->
          <div *ngFor="let bundles of endlessOfferTrackRecord['EObundles']; let i = index" class="p-col-8"
            [ngClass]="{'p-col-8': columnCount === 1, 'p-col-6': columnCount !== 1}">
            <ng-container
              *ngIf="endlessOfferTrackRecord['EOrewards'][i] && endlessOfferTrackRecord['EOrewards'][i][0]; else freeStage">
              <div class="box p-shadow-2 p-grid p-flex align-items-center">
                <div class="p-col-12">
                  <strong>{{endlessOfferTrackRecord['EOrewards'][i][0]?.id?.name}}</strong>
                </div>
                <!-- <div class="img-container p-col-6" *ngFor="let credit of endlessOfferTrackRecord['EOrewards'][i][0]?.id.credits_ref.qa"> -->
                <div class="img-container p-col-6" *ngFor="let credit of rewards_credits[i]">
                  <div class="p-col-12">
                    <img [src]="credit?.thumbnail" alt="B" class="bundle-img" />
                  </div>
                  <div class="p-col-12">
                    <p-tag [style]="{ 'background': 'white', 'border-radius': '10px', 'color': 'black'}"
                      class="quantity-tag">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <img [src]="credit?.thumbnail" alt="P" style="width: 18px" />
                        <span class="text-base">{{ credit.c }}</span>
                      </div>
                    </p-tag>
                  </div>
                </div>

                <!-- Green button below -->
                <div class="p-col-12">
                  <button pButton label="{{endlessOfferTrackRecord['EOrewards'][i][0]?.pricePoint?.name}}"
                    class="p-button-success"></button>
                  <!-- <button pButton label="{{endlessOfferTrackRecord['EOrewards'][i][0]?.id.priceLabel.qa}}" class="p-button-success"></button> -->
                </div>
              </div>
            </ng-container>
            <ng-template #freeStage>
              <div class="box p-shadow-2 p-grid p-flex align-items-center">
                <div class="p-col-12">
                  <strong>Rewards #{{i+1}}</strong>
                </div>
                <!--  -->
                <div class="img-container p-col-6" *ngFor="let credit of endlessOfferTrackRecord['EOrewards'][i]">
                  <div class="p-col-12">
                    <ng-container *ngIf="credit?.id?.thumbnails && credit?.id?.thumbnails[1]; else currency">
                      <img [src]="credit?.id?.thumbnails[1]?.path" alt="B" class="bundle-img" />
                    </ng-container>
                    <ng-template #currency>
                      <ng-container
                        *ngIf="credit?.id?.image_ref && credit?.id?.image_ref?.thumbnails && credit?.id?.image_ref?.thumbnails[1]; else nameLabel">
                        <img [src]="credit?.id?.image_ref?.thumbnails[1]?.path" alt="B" class="bundle-img" />
                      </ng-container>
                      <ng-template #nameLabel>
                        <small>{{credit?.id?.name}}</small>
                      </ng-template>
                    </ng-template>

                  </div>
                  <div class="p-col-12">
                    <p-tag [style]="{ 'background': 'white', 'border-radius': '10px'}" class="quantity-tag">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <img *ngIf="credit?.id?.thumbnails && credit?.id?.thumbnails[1];"
                          [src]="credit?.id?.thumbnails[1]?.path" alt="P" style="width: 18px" />
                        <img
                          *ngIf="credit?.id?.image_ref && credit?.id?.image_ref?.thumbnails && credit?.id?.image_ref?.thumbnails[1];"
                          [src]="credit?.id?.image_ref?.thumbnails[1]?.path" alt="P" style="width: 18px" />
                        <span class="text-base">{{ credit?.c }}</span>
                      </div>
                    </p-tag>
                  </div>
                </div>

                <!-- Green button below -->
                <div class="p-col-12">
                  <button pButton label="Free" class="p-button-success"></button>
                </div>
              </div>
            </ng-template>
          </div>

        </div>


      </div>
    </div>

  </div>
  <ng-template #isLoadingMsg>
    Loading Steps Data...
  </ng-template>
</div>
