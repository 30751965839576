import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { MessageService } from 'primeng/api';
import { SettingsService } from 'src/app/entities/settings/services/settings.service';
import { ArtToolsService } from './services/art-tools.service';

@Component({
  selector: 'app-art',
  templateUrl: './art.component.html',
  styleUrls: ['./art.component.sass'],
  
})
export class ArtComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private messageService: MessageService,
    private settingsService: SettingsService,
    private artToolsService: ArtToolsService,
  ) {}

  syncResourcesButtonEnabled: boolean = true;
  isLoading: boolean;

  /**
   * Art Component Initialiation
   */
  async ngOnInit()
  {
    await this.isSyncResourcesButtonEnabled();
    this.isLoading = false;
  }

  /**
   * Sets if sync resources button is enabled or disabled
   */
  async isSyncResourcesButtonEnabled()
  {
    let result = await this.settingsService.getSettingByQuery({ query: { name: 'cms-settings' }});

    if(result)
    {
      this.syncResourcesButtonEnabled = result.syncResourcesButtonEnabled;
    }
  }

  /**
   * Sync Resources for Build
   */
  async syncResources()
  {
    this.syncResourcesButtonEnabled = false;

    let result = await this.artToolsService.syncResources();

    if(result)
    {
      this.messageService.add(
      {
        sticky: true,
        severity: result.Succes ? 'success': 'info',
        summary: 'Sync Resources Action',
        detail: result.Message
      });

      if(result.Message == 'One or more nodes are processing builds. Please try again later.')
      {
        this.syncResourcesButtonEnabled = true;
      }
    }
    else
    {
      this.messageService.add
      (
        {
          sticky: true,
          severity: 'error',
          summary: 'Error',
          detail: 'There was an error triggering update of resources directory.',
        }
      );
      this.syncResourcesButtonEnabled = true;
    }
  }
}
