<div class="side-menu">
  <ul class="menu-list">
      <ng-container *ngFor="let menu of menus; let i = index">
          <li class="menu-item" [class.expanded]="menu.expanded" 
              *ngIf="!menu.permission || authService.hasPermission(['create', 'update'], menu.permission)">
              <div class="menu-label" (click)="toggleMenu(i)">
                  <i class="fas" [ngClass]="menu.expanded ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
                  <span>{{ menu.label }}</span>
              </div>
              <ul *ngIf="menu.expanded" class="sub-menu">
                  <li *ngFor="let sub of menu.subMenu">
                      <a [routerLink]="sub.route">{{ sub.label }}</a>
                  </li>
              </ul>
          </li>
      </ng-container>
  </ul>
</div>
