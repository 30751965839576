import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdvancedSearchComponent } from './advanced-search/components/advanced-search/advanced-search.component';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/login/login.component';
import { BulkBuildDataComponent } from './build-data/components/bulk-build-data/bulk-build-data.component';
import { BuildGamedataComponent } from './build-gamedata/build-gamedata.component';
import { GamedataBuildsHistoryComponent } from './build-gamedata/build-history/gamedata-buildshistory.component';
import { GamedataDiffComponent } from './build-gamedata/prod-gamedata-push/gamedata-diff/gamedata-diff.component';
import { GamedataDiffViewComponent } from './build-gamedata/prod-gamedata-push/gamedata-diff-view/gamedata-diff-view.component';
import { ProdGamedataPushComponent } from './build-gamedata/prod-gamedata-push/prod-gamedata-push.component';
import { AchievementCollectionViewV2Component } from './cms-v2/entities/achievements-collection/achievement-collection-view-v2/achievement-collection-view-v2.component';
import { CategoryCollectionViewV2Component } from './cms-v2/entities/category-collection/category-collection-view-v2/category-collection-view-v2.component';
import { CategoryFormV2Component } from './cms-v2/entities/category/components/category-form-v2/category-form-v2.component';
import { LoadingScreenViewV2Component } from './cms-v2/entities/loading-screen/loading-screen-view-v2/loading-screen-view-v2.component';
import { SeriesTableComponent } from './cms-v2/entities/series/components/series-table/series-table.component';
import { SourcingGroupFormComponent } from './cms-v2/entities/sourcing-group/sourcing-group-form/sourcing-group-form.component';
import { SourcingGroupViewComponent } from './cms-v2/entities/sourcing-group/sourcing-group-view/sourcing-group-view.component';
import { TitlesViewV2Component } from './cms-v2/entities/titles/components/titles-view-v2/titles-view-v2.component';
import { EntityHistoryComponent } from './common/components/entity-history/entity-history.component';
import { TableViewComponent } from './common/components/table-view/table-view/table-view.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { AchievementFormComponent } from './entities/achievement/achievement-form/achievement-form.component';
import { BatchViewV2Component } from './cms-v2/entities/batch/components/batch-view-v2/batch-view-v2.component';
import { CategoryViewComponent } from './entities/category/category-view/category-view.component';
import { ChallengeTypeFormV3Component } from './cms-v2/entities/challenge-type/components/challenge-type-form-v3/challenge-type-form-v3.component';
import { ChallengeTypeViewV2Component } from './cms-v2/entities/challenge-type/components/challenge-type-view-v2/challenge-type-view-v2.component';
import { ChallengeFormComponent } from './entities/challenge/challenge-form/challenge-form.component';
import { ChallengesKeywordsComponent } from './entities/challenge/challenge-keywords/challenge-keyword.component';
import { ClimateViewComponent } from './entities/climate/climate-view/climate-view.component';
import { CurrencyViewV2Component } from './cms-v2/entities/currency/components/currency-view-v2/currency-view-v2.component';
import { ExternalPlantDataFormV3Component } from './cms-v2/entities/external-plant-data/components/external-plant-data-form-v3/external-plant-data-form-v3.component';
import { InboxMessageViewV2Component } from './cms-v2/entities/inbox-message/components/inbox-message-view-v2/inbox-message-view-v2.component';
import { ItemTypeViewV2Component } from './cms-v2/entities/item-type/components/item-type-view-v2/item-type-view-v2.component';
import { ItemHistoryComponent } from './entities/item/item-history/item-history.component';
import { ItemFileTypeFormV2Component } from './cms-v2/entities/item-file-type/components/item-file-type-form/item-file-type-form.component';
import { itemfiletypeViewV2Component } from './cms-v2/entities/item-file-type/components/item-file-type-view-v2/item-file-type-view-v2.component';
import { PatternFormV2Component } from './cms-v2/entities/pattern/components/pattern-form/pattern-form.component';
import { ReleaseFormComponent } from './entities/release/release-form/release-form.component';
import { ResourceFormV2Component } from './cms-v2/entities/resource/components/resource-form/resource-form.component';
import { ResourceViewV2Component } from './cms-v2/entities/resource/components/resource-view-v2/resource-view-v2.component';
import { RestrictionViewV2Component } from './cms-v2/entities/restriction/components/restriction-view-v2/restriction-view-v2.component';
import { SettingsComponent } from './entities/settings/settings.component';
import { VendorViewV2Component } from './cms-v2/entities/vendor/components/vendor-view-v2/vendor-view-v2.component';
import { FormCreatorComponent } from './form-creator/form-creator.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ArtComponent } from './tools/art/art.component';
import { EconTableComponent } from './entities/econ-table/econ-table.component';
import { SourcingChallengeGroupFormComponent } from './cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-form/sourcing-challenge-group-form.component';
import { SourcingChallengeGroupViewComponent } from './cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-view/sourcing-challenge-group-view.component';
import { ColorFormV2Component } from './cms-v2/entities/color/components/color-form-v2/color-form-v2.component';
import { BatchFormV3Component } from './cms-v2/entities/batch/components/batch-form-v3/batch-form-v3.component';
import { ShapeFormV2Component } from './cms-v2/entities/shape/components/shape-form-v2/shape-form-v2.component';
import { ItemTypeFormV3Component } from './cms-v2/entities/item-type/components/item-type-form-v3/item-type-form-v3.component';
import { TraitFormV3Component } from './cms-v2/entities/traits/components/trait-form-v3/trait-form-v3.component';
import { StyleFormV3Component } from './cms-v2/entities/style/components/style-form-v3/style-form-v3.component';
import { TitlesFormV2Component } from './cms-v2/entities/titles/components/titles-form-v2/titles-form-v2.component';
import { MaterialFormV2Component } from './cms-v2/entities/material/components/material-form-v2/material-form-v2.component';
import { InboxMessageFormV3Component } from './cms-v2/entities/inbox-message/components/inbox-message-form-v3/inbox-message-form-v3.component';
import { SeasonPassViewComponent } from './entities/season-pass/season-pass-view.component';
import { DailyRewardsFormV3Component } from './cms-v2/entities/daily-rewards/components/daily-rewards-form-v3/daily-rewards-form-v3.component';
import { DailyRewardsViewComponent } from './cms-v2/entities/daily-rewards/components/daily-rewards-view/daily-rewards-view.component';
import { SeasonPassFormV3Component } from './cms-v2/entities/season-pass/components/season-pass-form-v3/season-pass-form-v3.component';
import { RewardsTrackFormV3Component } from './cms-v2/entities/rewards-track/components/rewards-track-form-v3/rewards-track-form-v3.component';
import { RestrictionFormV3Component } from './cms-v2/entities/restriction/components/restriction-form-v3/restriction-form-v3.component';
import { VendorFormV3Component } from './cms-v2/entities/vendor/components/vendor-form-v3/vendor-form-v3.component';
import { ClimateFormV2Component } from './cms-v2/entities/climate/components/climate-form-v2/climate-form-v2.component';
import { CategoryCollectionFormV3Component } from './cms-v2/entities/category-collection/category-collection-form-v3/category-collection-form-v3.component';
import { AchievementsCollectionFormV3Component } from './cms-v2/entities/achievements-collection/achievements-collection-form-v3/achievements-collection-form-v3.component';
import { CollectionFormV3Component } from './cms-v2/entities/collection/components/collection-form-v3/collection-form-v3.component';
import { CurrencyFormV3Component } from './cms-v2/entities/currency/components/currency-form-v3/currency-form-v3.component';
import { LoadingScreenFormV3Component } from './cms-v2/entities/loading-screen/loading-screen-form-v3/loading-screen-form-v3.component';
import { ProgressionLevelFormV3Component } from './cms-v2/entities/progression-level/components/progression-level-form-v3/progression-level-form-v3.component';
import { AssetVersionTableComponent } from './entities/asset-version-table/asset-version-table.component';
import { ColorViewV2Component } from './cms-v2/entities/color/components/color-view-v2/color-view-v2.component';
import { MaterialViewV2Component } from './entities/material/material-view-v2/material-view-v2.component';
import { PatternViewV2Component } from './entities/pattern/pattern-view-v2/pattern-view-v2.component';
import { ShapeViewV2Component } from './entities/shape/shape-view-v2/shape-view-v2.component';
import { StyleViewV2Component } from './entities/style/style-view-v2/style-view-v2.component';
import { TraitViewV2Component } from './entities/trait/trait-view-v2/trait-view-v2.component';
import { AssetFormV3Component } from './common/components/assets/asset-form/asset-form-v3.component';
import { AssetViewV2Component } from './common/components/assets/asset-view/asset-view-v2.component';
import { NurtureFormV3Component } from './cms-v2/entities/nurture/components/nurture-form-v3/nurture-form-v3.component';
import { NurtureCardFormComponent } from './cms-v2/entities/nurture/nurture-card/nurture-card-form/nurture-card-form.component';
import { NurtureCardViewComponent } from './cms-v2/entities/nurture/nurture-card/nurture-card-view/nurture-card-view.component';
import { NurtureCardTableComponent } from './cms-v2/entities/nurture/nurture-card/nurture-card-table/nurture-card-table.component';
import { NurtureRarityFormComponent } from './cms-v2/entities/nurture/nurture-rarity/nurture-rarity-form/nurture-rarity-form.component';
import { NurtureRarityViewComponent } from './cms-v2/entities/nurture/nurture-rarity/nurture-rarity-view/nurture-rarity-view.component';
import { NurtureCollectionFormComponent } from './cms-v2/entities/nurture/nurture-collection/nurture-collection-form/nurture-collection-form.component';
import { NurtureCollectionViewComponent } from './cms-v2/entities/nurture/nurture-collection/nurture-collection-view/nurture-collection-view.component';
import { SeedPackViewComponent } from './cms-v2/entities/nurture/seed-pack/seed-pack-view/seed-pack-view.component';
import { SeedPackFormComponent } from './cms-v2/entities/nurture/seed-pack/seed-pack-form/seed-pack-form.component';
import { RenderPreviewToolComponent } from './tools/art/render-preview-tool/render-preview-tool.component';
import { ReleaseTypeFormV2Component } from './cms-v2/entities/release-type/components/release-type-form-v2/release-type-form-v2.component';
import { ReleaseTypeViewV2Component } from './cms-v2/entities/release-type/components/release-type-view-v2/release-type-view-v2.component';
import { ExternalPlantDataViewV2Component } from './cms-v2/entities/external-plant-data/components/external-plant-data-view-v2/external-plant-data-view-v2.component';
import { ReleaseViewV2Component } from './entities/release/release-view-v2/release-viewV2.component';
import { BuildHistoryComponent } from './common/components/build-history/build-history.component';
import { NurtureViewV3Component } from './cms-v2/entities/nurture/components/nurture-view-v3/nurture-view-v3.component';
import { NurtureStageViewComponent } from './cms-v2/entities/nurture/nurture-stage/nurture-stage-view/nurture-stage-view.component';
import { NurtureStageFormComponent } from './cms-v2/entities/nurture/nurture-stage/nurture-stage-form/nurture-stage-form.component';
import { ItemViewV2Component } from './entities/item/item-view-v2/item-view-v2.component';
import { ItemFormV2Component } from './entities/item/item-form-v2/item-form-v2.component';
import { ChallengeViewV2Component } from './entities/challenge/challenge-view-v2/challenge-view.component';
import { ItemKeywordsComponent } from './entities/item/item-keywords/item-keywords.component';
import { AudioCollectionViewComponent } from './cms-v2/entities/audio-collection/audio-collection-view/audio-collection-view.component';
import { AudioCollectionFormV3Component } from './cms-v2/entities/audio-collection/audio-collection-form-v3/audio-collection-form-v3.component';
import { ItemTableAgGridComponent } from './cms-v2/entities/item/components/item-table-ag-grid/item-table-ag-grid.component';
import { AssetBundleTableAgGridComponent } from './cms-v2/entities/asset-bundle-table-ag-grid/asset-bundle-table-ag-grid.component';
import { ChallengeTableAgGridComponent } from './cms-v2/entities/challenge/components/challenge-table-ag-grid/challenge-table-ag-grid.component';
import { FlashBackChallengeTableAgGridComponent } from './cms-v2/entities/challenge/components/flashback-challenge-table-ag-grid/challenge-table-ag-grid.component';
import { SeasonPassTableAgGridComponent } from './cms-v2/entities/season-pass/components/season-pass-table-ag-grid/season-pass-table-ag-grid.component';
import { DailyRewardsTableAgGridComponent } from './cms-v2/entities/daily-rewards/daily-rewards-table-ag-grid/daily-rewards-table-ag-grid.component';
import { RewardsTrackTableAgGridComponent } from './cms-v2/entities/rewards-track/components/rewards-track-table-ag-grid/rewards-track-table-ag-grid.component';
import { CollectionTableAgGridComponent } from './cms-v2/entities/collection/components/collection-table-ag-grid/collection-table-ag-grid.component';
import { EndlessOfferTableAgGridComponent } from './cms-v2/entities/endless-offer/components/table/endless-offer-table-ag-grid.component';
import { EndlessOfferFormComponent } from './cms-v2/entities/endless-offer/components/form/endless-offer-form-v3.component';
import { EndlessOfferViewComponent } from './cms-v2/entities/endless-offer/components/view/endless-offer-view.component';
import { LoadingScreenTableAgGridComponent } from './cms-v2/entities/loading-screen/loading-screen-table-ag-grid/loading-screen-table-ag-grid.component';
import { VendorTableAgGridComponent } from './cms-v2/entities/vendor/components/vendor-table-ag-grid/vendor-table-ag-grid.component';
import { ColorTableAgGridComponent } from './cms-v2/entities/color/components/color-table-ag-grid/color-table-ag-grid.component';
import { StyleTableAgGridComponent } from './cms-v2/entities/style/components/style-table-ag-grid/style-table-ag-grid.component';
import { MaterialTableAgGridComponent } from './cms-v2/entities/material/components/material-table-ag-grid/material-table-ag-grid.component';
import { PatternTableAgGridComponent } from './cms-v2/entities/pattern/components/pattern-table-ag-grid/pattern-table-ag-grid.component';
import { ShapeTableAgGridComponent } from './cms-v2/entities/shape/components/shape-table-ag-grid/shape-table-ag-grid.component';
import { TraitsTableAgGridComponent } from './cms-v2/entities/traits/components/traits-table-ag-grid/traits-table-ag-grid.component';
import { ExternalPlantDataTableAgGridComponent } from './cms-v2/entities/external-plant-data/components/external-plant-data-table-ag-grid/external-plant-data-table-ag-grid.component';
import { AchievementTableAgGridComponent } from './cms-v2/entities/achievement/components/achievement-table-ag-grid/achievement-table-ag-grid.component';
import { ItemFileTypeTableAgGridComponent } from './cms-v2/entities/item-file-type/components/item-file-type-table-ag-grid/item-file-type-table-ag-grid.component';
import { CategoryTableAgGridComponent } from './cms-v2/entities/category/components/category-table-ag-grid/category-table-ag-grid.component';
import { CurrencyTableAgGridComponent } from './cms-v2/entities/currency/components/entity-table-ag-grid/currency-table-ag-grid.component';
import { ReleasesAgGridComponent } from './entities/release/releases-ag-grid/releases-ag-grid.component';
import { NurtureCollectionTableAgGridComponent } from './cms-v2/entities/nurture/nurture-collection/nurture-collection-table-ag-grid/nurture-collection-table-ag-grid.component';
import { TitlesAgGridComponent } from './cms-v2/entities/titles/components/titles-ag-grid/titles-table-ag-grid.component';
import { CategoryCollectionTableAgGridComponent } from './cms-v2/entities/category-collection/category-collection-table-ag-grid/category-collection-table-ag-grid.component';
import { ReleaseTypeAgGridComponent } from './cms-v2/entities/release-type/components/release-type-ag-grid/release-type-ag-grid.component';
import { SourcingGroupTableAgGridComponent } from './cms-v2/entities/sourcing-group/sourcing-group-table-ag-grid/sourcing-group-table-ag-grid.component';
import { NufCollectionTableAgGridComponent } from './entities/nuf-collection/nuf-collection-table-ag-grid/nuf-collection-table-ag-grid.component';
import { MiscImageTableAgGridComponent } from './entities/misc-image/misc-image-table-ag-grid/misc-image-table-ag-grid.component';
import { SeedPackTableAgGridComponent } from './cms-v2/entities/nurture/seed-pack/seed-pack-table-ag-grid/seed-pack-table-ag-grid.component';
import { InboxMessageTableAgGridComponent } from './cms-v2/entities/inbox-message/components/inbox-message-table-ag-grid/inbox-message-table-ag-grid.component';
import { NurtureRarityTableAgGridComponent } from './cms-v2/entities/nurture/nurture-rarity/nurture-rarity-table-ag-grid/nurture-rarity-table-ag-grid.component';
import { BatchesAgGridComponent } from './cms-v2/entities/batch/components/batches-table-ag-grid/batches-table-ag-grid.component';
import { ItemTypeAgGridComponent } from './cms-v2/entities/item-type/components/item-type-table-ag-grid/item-type-table-ag-grid.component';
import { ProgressionLevelTableAgGridComponent } from './entities/progression-level/progression-level-table-ag-grid/progression-level-table-ag-grid.component';
import { NurtureStageTableAgGridComponent } from './cms-v2/entities/nurture/nurture-stage/nurture-stage-table-ag-grid/nurture-stage-table-ag-grid.component';
import { ChallengeTypeTableAgGridComponent } from './cms-v2/entities/challenge-type/components/challenge-type-table-ag-grid/challenge-type-table-ag-grid.component';
import { AudioCollectionTableAgGridComponent } from './cms-v2/entities/audio-collection/audio-collection-table-ag-grid/audio-collection-table-ag-grid.component';
import { AchievementCollectionTableAgGridComponent } from './cms-v2/entities/achievements-collection/achievement-collection-table-ag-grid/achievement-collection-table-ag-grid.component';
import { ClimateTableAgGridComponent } from './cms-v2/entities/climate/components/climate-table-v2/climate-table-ag-grid/climate-table-ag-grid.component';
import { RestrictionTableAgGridComponent } from './cms-v2/entities/restriction/components/entity-table-ag-grid/restriction-table-ag-grid.component';
import { NurtureTableAgGridComponent } from './cms-v2/entities/nurture/components/nurture-table-ag-grid/nurture-table-ag-grid.component';
import { ResourceTableAgGridComponent } from './cms-v2/entities/resource/components/resource-table-ag-grid/resource-table-ag-grid.component';
import { CoOpFormV3Component } from './cms-v2/entities/co-op/components/co-op-form-v3/co-op-form-v3.component';
import { SourcingChallengeGroupTableAgGridComponent } from './cms-v2/entities/sourcing-challenge-group/sourcing-challenge-group-table-ag-grid/sourcing-challenge-group-table-ag-grid.component';
import { CollectionTableViewAgGridComponent } from './cms-v2/entities/collection/components/collection-view-ag-grid/collection-table-view-ag-grid.component';
import { CoOpTableAgGridComponent } from './cms-v2/entities/co-op/components/co-op-table-ag-grid/co-op-table-ag-grid.component';
import { CoOpViewComponent } from './cms-v2/entities/co-op/components/co-op-view/co-op-view.component';
import { StoreListingFormV2Component } from './cms-v2/entities/store-listing/components/store-listing-form-v2/store-listing-form-v2.component';
import { StoreListingViewV2Component } from './cms-v2/entities/store-listing/components/store-listing-view-v2/store-listing-view-v2.component';
import { BundleAssetViewComponent } from './entities/bundle-asset-view/bundle-asset-view.component';
import { ProgressionLevelTableViewAgGridComponent } from './cms-v2/entities/progression-level/components/progression-level-table-v2/progression-level-view-v2/progression-level-table-view-ag-grid.component';
import { SeedPackBoxTableAgGridComponent } from './cms-v2/entities/nurture/seed-pack-box/seed-pack-box-table-ag-grid/seed-pack-box-table-ag-grid.component';
import { SeedPackBoxFormComponent } from './cms-v2/entities/nurture/seed-pack-box/seed-pack-box-form/seed-pack-box-form.component';
import { SeedPackBoxViewComponent } from './cms-v2/entities/nurture/seed-pack-box/seed-pack-box-view/seed-pack-box-view.component';
import { SeedPackBoxTypeTableAgGridComponent } from './cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-type-table-ag-grid/seed-pack-box-type-table-ag-grid.component';
import { SeedPackBoxTypeFormComponent } from './cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-type-form/seed-pack-box-type-form.component';
import { SeedPackBoxTypeViewComponent } from './cms-v2/entities/nurture/seed-pack-box-type/seed-pack-box-view/seed-pack-box-type-view.component';
// STORE
import { StoregamedataviewComponent } from './cms-v2/store/store-game-data/storegamedataview/storegamedataview.component';
// Price Points
import { PricePointTableAgGridComponent } from './cms-v2/store/price-point/price-point-table-ag-grid/price-point-table-ag-grid.component';
import { PricePointFormComponent } from './cms-v2/store/price-point/price-point-form/price-point-form.component';
import { PricePointViewComponent } from './cms-v2/store/price-point/price-point-view/price-point-view.component';
// Store Listings
import { StoreListingV2TableAgGridComponent } from './cms-v2/store/store-listing-v2/store-listing-v2-table-ag-grid/store-listing-v2-table-ag-grid.component';
import { StoreListingV2FormComponent } from './cms-v2/store/store-listing-v2/store-listing-v2-form/store-listing-v2-form.component';
import { StoreListingV2ViewComponent } from './cms-v2/store/store-listing-v2/store-listing-v2-view/store-listing-v2-view.component';
// Store Sync
import { StoreSyncTableAgGridComponent } from './cms-v2/store/store-sync/store-sync-table-ag-grid/store-sync-table-ag-grid.component';
import { Storesyncv2viewComponent } from './cms-v2/store/storesyncv2view/storesyncv2view/storesyncv2view.component';
import { StoreTransferTableAgGridComponent } from './cms-v2/store/store-transfer/store-transfer-table-ag-grid/store-transfer-table-ag-grid.component';
import { StoreTransferV2ViewComponent } from './cms-v2/store/store-transfer-v2-view/store-transfer-v2-view.component';
import { AchievementViewV2Component } from './entities/achievement/achievement-view-v2/achievement-view-v2.component';
import { LocalizationConfigurationViewComponent } from './cms-v2/entities/localization/localization-config-view/localization-configuration-view/localization-configuration-view.component';
import { LocalizationConfigurationTableComponent } from './cms-v2/entities/localization/localization-config-view/localization-configuration-view/localization-configuration-table/localization-configuration-table/localization-configuration-table.component';
// Localization Config
import { LocalizationConfigFormComponent } from './cms-v2/entities/localization/form/localization-config-form-component/localization-config-form-component';
// import { AchievementFormV3Component } from './entities/achievement/achievement-form-v3/achievement-form-v3.component';
// ITEM SETS
import { ItemSetTableAgGridComponent } from './cms-v2/entities/item-set/item-set-table-ag-grid/item-set-table-ag-grid.component';
import { ItemSetFormV2Component } from './cms-v2/entities/item-set/item-set-form-v2/item-set-form-v2.component';
import { ItemSetViewV2Component } from './cms-v2/entities/item-set/item-set-view-v2/item-set-view-v2.component';
// COLORS
import { SponsorTableAgGridComponent } from './cms-v2/sponsor/sponsor-table-ag-grid/sponsor-table-ag-grid.component';
import { SponsorFormV2Component } from './cms-v2/sponsor/sponsor-form-v2/sponsor-form-v2.component';
import { SponsorViewV2Component } from './cms-v2/sponsor/sponsor-view-v2/sponsor-view-v2.component';
import { BuildGamedataV3ViewComponent } from './build-gamedata/build-gamedata-v3-view/build-gamedata-v3-view.component';
import { ClientStringsTableAgGridComponent } from './cms-v2/entities/client-strings/components/client-strings/client-strings-ag-grid.component';
import { BuildGamedataV3Component } from './build-gamedata/build-gamedata-v3/build-gamedata-v3/build-gamedata-v3.component';
import { LocalizedStringsTableAgGridComponent } from './cms-v2/entities/localized-strings/components/localized-strings/localized-strings-ag-grid.component';
import { ChallengeCollectionTableAgGridComponent } from './cms-v2/entities/challenge-collection/components/challenge-collection-table-ag-grid/challenge-collection-table-ag-grid.component';
import { ChallengeCollectionFormV3Component } from './cms-v2/entities/challenge-collection/components/challenge-collection-form-v3/challenge-collection-form-v3.component';
import { ChallengeCollectionTableViewAgGridComponent } from './cms-v2/entities/challenge-collection/components/challenge-collection-view-ag-grid/challenge-collection-table-view-ag-grid.component';
import { KeywordsViewV2Component } from './cms-v2/entities/keywords/keywords-view-v2/keywords-view-v2.component';
import { KeywordsFormV2Component } from './cms-v2/entities/keywords/keywords-form-v2/keywords-form-v2.component';
import { KeywordsTableAgGridComponent } from './cms-v2/entities/keywords/keywords-table-ag-grid/keywords-table-ag-grid.component';
import { DiffToolViewComponent } from './entities/diff-tool-view/diff-tool-view.component';
import { DiffToolAgAgridComponent } from './entities/diff-tool-ag-agrid/diff-tool-ag-agrid.component';
import { RewardsTrackViewV2Component } from './cms-v2/entities/rewards-track/components/rewards-track-view-v2/rewards-track-view-v2.component';
import { NurtureCollectionGroupsAgGridComponent } from './cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-ag-grid/nurture-collection-groups-ag-grid/nurture-collection-groups-ag-grid.component';
import { NurtureCollectionGroupsFormComponent } from './cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-form/nurture-collection-groups-form/nurture-collection-groups-form.component';
import { NurtureCollectionGroupsViewV2Component } from './cms-v2/entities/nurture/nurture-collection-groups/nurture-collection-groups-view-v2/nurture-collection-groups-view-v2.component';
// infinite series
import { InfiniteSeriesTableAgGridComponent } from './cms-v2/entities/series/infinite-series/components/infinite-series-table-ag-grid/infinite-series-table-ag-grid.component';
import { InfiniteSeriesFormComponent } from './cms-v2/entities/series/infinite-series/components/infinite-series-form/infinite-series-form.component';
import { InfiniteSeriesViewComponent } from './cms-v2/entities/series/infinite-series/components/infinite-series-view/infinite-series-view.component';import { ReleasesV2AgGridComponent } from './entities/release/releases-v2-ag-grid/releases-v2-ag-grid.component';
import { SeriesViewV2Component } from './cms-v2/entities/series/components/series-view-v2/series-view-v2.component';
import { SeriesFormV2Component } from './cms-v2/entities/series/components/series-form-v2/series-form-v2/series-form-v2.component';
import { SeriesAgGridComponent } from './cms-v2/entities/series/components/series-ag-grid/series-ag-grid/series-ag-grid.component';
import { ReleaseSeriesViewV2Component } from './entities/release/release-series-view-v2/release-series-view-v2/release-series-view-v2.component';
import { ReleaseEndlessOfferViewV2Component } from './entities/release/release-endless-offer/release-endless-offer-view-v2/release-endless-offer-view-v2.component'; 
import { RoleComponent } from './role/role.component';

// New routes scheme
import { routes as routesv2 } from './routes';
import { TestUserTableAgGridComponent } from './user/test-user-table-ag-grid-old/test-user-table-ag-grid.component';
import { ReleaseProgressionLevelsV2Component } from './entities/release/release-progression-levels-v2/release-progression-levels-v2.component';
import { ReleaseNurtureCollectionComponent } from './entities/release/release-nurture-collection/release-nurture-collection/release-nurture-collection.component';
import { ReleaseGardenPassComponent } from './entities/release/release-garden-pass/release-garden-pass/release-garden-pass.component';
import { SeasonalPrizesFormV2Component } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-form-v2/seasonal-prizes-form-v2/seasonal-prizes-form-v2.component';
import { SeasonalPrizesViewV2Component } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-view-v2/seasonal-prizes-view-v2/seasonal-prizes-view-v2.component';
import { SeasonalPrizesAgGridComponent } from './cms-v2/entities/seasonal-prizes/seasonal-prizes-ag-grid/seasonal-prizes-ag-grid/seasonal-prizes-ag-grid.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: 'home',
    component: HomePageComponent,
  },
  {
    path: 'form-creator',
    component: FormCreatorComponent,
  },
  {
    path: 'dynamic-form',
    component: DynamicFormComponent,
  },
  {
    path: 'items',
    component: ItemTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'items/add',
    component: ItemFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'items/:id',
    component: ItemViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'items/edit/:id',
    component: ItemFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'items/history/:id',
    component: ItemHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenges',
    component: ChallengeTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'flashback-challenges',
    component: FlashBackChallengeTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-collections',
    component: ChallengeCollectionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-collections/add',
    component: ChallengeCollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-collections/:id',
    component: ChallengeCollectionTableViewAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-collections/edit/:id',
    component: ChallengeCollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'titles',
    component: TitlesAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'titles/add',
    component: TitlesFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'titles/:id',
    component: TitlesViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'titles/edit/:id',
    component: TitlesFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenges/add',
    component: ChallengeFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenges/:id',
    component: ChallengeViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenges/edit/:id',
    component: ChallengeFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'build-history/:entityType/:id',
    component: BuildHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenges-keywords',
    component: KeywordsTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-keywords/add',
    component: KeywordsFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-keywords/:id',
    component: KeywordsViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-keywords/edit/:id',
    component: KeywordsFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-keywords',
    component: ItemKeywordsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'categories',
    component: CategoryTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'categories/add',
    component: CategoryFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'categories/:id',
    component: CategoryViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'categories/edit/:id',
    //component: CategoryFormComponent,
    component: CategoryFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'category-collections',
    component: CategoryCollectionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'category-collections/add',
    component: CategoryCollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'category-collections/:id',
    component: CategoryCollectionViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'category-collections/edit/:id',
    component: CategoryCollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'audio-collections',
    component: AudioCollectionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'audio-collections/add',
    component: AudioCollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'audio-collections/:id',
    component: AudioCollectionViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'audio-collections/edit/:id',
    component: AudioCollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'batches',
    component: BatchesAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'batches/add',
    component: BatchFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'batches/:id',
    component: BatchViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'batches/edit/:id',
    component: BatchFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'vendors',
    component: VendorTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'vendors/add',
    component: VendorFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'vendors/:id',
    component: VendorViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'vendors/edit/:id',
    component: VendorFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'colors',
    component: ColorTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'colors/add',
    component: ColorFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'colors/:id',
    component: ColorViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'colors/edit/:id',
    component: ColorFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'restrictions',
    //component: RestrictionTableComponent,
    component: RestrictionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'restrictions/add',
    component: RestrictionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'restrictions/:id',
    component: RestrictionViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'restrictions/edit/:id',
    component: RestrictionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'climates',
    //component: ClimateTableComponent,
    component: ClimateTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'climates/add',
    component: ClimateFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'climates/edit/:id',
    component: ClimateFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'climates/:id',
    component: ClimateViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'materials',
    component: MaterialTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'materials/add',
    component: MaterialFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'materials/:id',
    component: MaterialViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'materials/edit/:id',
    component: MaterialFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'styles',
    component: StyleTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'styles/add',
    component: StyleFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'styles/:id',
    component: StyleViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'styles/edit/:id',
    component: StyleFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'traits',
    component: TraitsTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'traits/add',
    component: TraitFormV3Component,
    // component: TraitFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'traits/:id',
    component: TraitViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'traits/edit/:id',
    component: TraitFormV3Component,
    // component: TraitFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resources',
    component: ResourceTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resources/add',
    component: ResourceFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'resources/:id',
    component: ResourceViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'resources/edit/:id',
    component: ResourceFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'currencies',
    component: CurrencyTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'currencies/add',
    component: CurrencyFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'currencies/:id',
    component: CurrencyViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'currencies/edit/:id',
    component: CurrencyFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'achievements',
    component: AchievementTableAgGridComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'achievements/add',
    // component: AchievementFormV3Component,
    component: AchievementFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'achievements/:id',
    component: AchievementViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'achievements/edit/:id',
    // component: AchievementFormV3Component,
    component: AchievementFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'achievements-collection',
    component: AchievementCollectionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'achievements-collection/add',
    component: AchievementsCollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'achievements-collection/:id',
    component: AchievementCollectionViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'achievements-collection/edit/:id',
    component: AchievementsCollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-types',
    component: ItemTypeAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-types/add',
    component: ItemTypeFormV3Component,
    // component: ItemTypeFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-types/edit/:id',
    component: ItemTypeFormV3Component,
    // component: ItemTypeFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-types/:id',
    component: ItemTypeViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-types',
    component: ChallengeTypeTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-types/add',
    component: ChallengeTypeFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-types/:id',
    component: ChallengeTypeViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'challenge-types/edit/:id',
    component: ChallengeTypeFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-plant-data',
    component: ExternalPlantDataTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-plant-data/add',
    component: ExternalPlantDataFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-plant-data/:id',
    component: ExternalPlantDataViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'external-plant-data/edit/:id',
    component: ExternalPlantDataFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'shapes',
    component: ShapeTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'shapes/add',
    component: ShapeFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'shapes/:id',
    component: ShapeViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'shapes/edit/:id',
    component: ShapeFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'collections',
    component: CollectionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'collections/add',
    component: CollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'collections/:id',
    component: CollectionTableViewAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'collections/edit/:id',
    component: CollectionFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'collections/table-view/:id',
    component: TableViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-sets',
    component: ItemSetTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-sets/add',
    component: ItemSetFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-sets/edit/:id',
    component: ItemSetFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-sets/:id',
    component: ItemSetViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'progression-levels',
    component: ProgressionLevelTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'progression-levels/add',
    component: ProgressionLevelFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'progression-levels/:id',
    component: ProgressionLevelTableViewAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'releases/progression-levels/:id',
    component: ReleaseProgressionLevelsV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'progression-levels/edit/:id',
    component: ProgressionLevelFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-file-types',
    component: ItemFileTypeTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-file-types/add',
    component: ItemFileTypeFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-file-types/:id',
    component: itemfiletypeViewV2Component,
    canActivate: [AuthGuard],
  },

  {
    path: 'item-file-types/edit/:id',
    component: ItemFileTypeFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'patterns',
    component: PatternTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'patterns/add',
    component: PatternFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'patterns/:id',
    component: PatternViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'patterns/edit/:id',
    component: PatternFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'inbox-messages',
    component: InboxMessageTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'econ-table',
    component: EconTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'inbox-messages/add',
    component: InboxMessageFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'inbox-messages/:id',
    component: InboxMessageViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'inbox-messages/edit/:id',
    component: InboxMessageFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'build-gamedata',
    component: BuildGamedataV3Component,
    canActivate: [AuthGuard],
    data: {
      permission: {
        abilities: ['read'],
        controller: 'GamedataController'
      }
    }
  },
  {
    path: 'build-gamedata/history',
    component: GamedataBuildsHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gamedatalogs/:id',
    component: BuildGamedataV3ViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gamedata/diff',
    component: GamedataDiffComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'gamedata/diff/:id',
    component: GamedataDiffViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'test-users',
    component: TestUserTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'settings/:type',
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'loading-screens',
    component: LoadingScreenTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'loading-screens/add',
    component: LoadingScreenFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'loading-screens/:id',
    component: LoadingScreenViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'loading-screens/edit/:id',
    component: LoadingScreenFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing-groups',
    component: SourcingGroupTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing-groups/add',
    component: SourcingGroupFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing-groups/:id',
    component: SourcingGroupViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing-groups/edit/:id',
    component: SourcingGroupFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing-challenge-groups',
    component: SourcingChallengeGroupTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing-challenge-groups/add',
    component: SourcingChallengeGroupFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing-challenge-groups/:id',
    component: SourcingChallengeGroupViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sourcing-challenge-groups/edit/:id',
    component: SourcingChallengeGroupFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'art-tools',
    component: ArtComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'render-preview-tool',
    component: RenderPreviewToolComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'miscellaneous-build',
    component: MiscImageTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'miscellaneous-build/:id',
    component: AssetViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'miscellaneous-build/add',
    component: AssetFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'miscellaneous-build/edit/:id',
    component: AssetFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'asset-bundles',
    component: AssetBundleTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bundle-view',
    component: BundleAssetViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'history/:entityType/:id',
    component: EntityHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'prod-gamedata',
    component: ProdGamedataPushComponent,
    canActivate: [AuthGuard],
    data: {
      permission: {
        abilities: ['prod_push'],
        controller: 'GamedataController'
      }
    }
  },
  {
    path: 'series',
    component: SeriesAgGridComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'infinite-series',
    component: InfiniteSeriesTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'infinite-series/add',
    component: InfiniteSeriesFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'infinite-series/edit/:id',
    component: InfiniteSeriesFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'infinite-series/:id',
    component: InfiniteSeriesViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'series/add',
    component: SeriesFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'series/:id',
    component: SeriesViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'releases/series/:id',
    component: ReleaseSeriesViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'series/edit/:id',
    component: SeriesFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'releases',
    component: ReleasesAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'v2/releases',
    component: ReleasesV2AgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'releases/add',
    component: ReleaseFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'releases/:id',
    component: ReleaseViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'releases/edit/:id',
    component: ReleaseFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'release-type',
    component: ReleaseTypeAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'release-type/add',
    component: ReleaseTypeFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'release-type/edit/:id',
    component: ReleaseTypeFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'release-type/:id',
    component: ReleaseTypeViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'advanced-search',
    component: AdvancedSearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bulk-build-search',
    component: BulkBuildDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seasonal-prizes',
    component: SeasonalPrizesAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seasonal-prizes/add',
    component: SeasonalPrizesFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'seasonal-prizes/:id',
    component: SeasonalPrizesViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'seasonal-prizes/edit/:id',
    component: SeasonalPrizesFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'daily-rewards',
    component: DailyRewardsTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'daily-rewards/add',
    component: DailyRewardsFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'daily-rewards/:id',
    component: DailyRewardsViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'season-pass',
    component: SeasonPassTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'season-pass/add',
    component: SeasonPassFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'season-pass/:id',
    component: SeasonPassViewComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'releases/season-pass/:id',
    component: ReleaseGardenPassComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'daily-rewards/edit/:id',
    component: DailyRewardsFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'season-pass/edit/:id',
    component: SeasonPassFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'rewards-track/add',
    component: RewardsTrackFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'rewards-track/:id',
    component: RewardsTrackViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'rewards-track/edit/:id',
    component: RewardsTrackFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'rewards-track',
    component: RewardsTrackTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'endless-offer',
    component: EndlessOfferTableAgGridComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'endless-offer/add',
    component: EndlessOfferFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'endless-offer/edit/:id',
    component: EndlessOfferFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'releases/endless-offer/:id',
    component: ReleaseEndlessOfferViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'endless-offer/:id',
    component: EndlessOfferViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'item-keywords/:id',
    component: KeywordsViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-game-data',
    component: StoregamedataviewComponent,
    canActivate: [AuthGuard]
  },
  // {
  //   path: 'store-listing',
  //   component: StoreListingTableAgGridComponent,
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'store-listing/add',
  //   component: StoreListingFormV2Component,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'store-listing/edit/:id',
    component: StoreListingFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-listing/:id',
    component: StoreListingViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-sync/:id',
    component: Storesyncv2viewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-sync',
    component: StoreSyncTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-transfer',
    component: StoreTransferTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-transfer/:id',
    component: StoreTransferV2ViewComponent,
    canActivate: [AuthGuard],
  },

  //
  {
    path: 'asset-versions/:table/:type/:id',
    component: AssetVersionTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture/add',
    component: NurtureFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture/edit/:id',
    component: NurtureFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture/:id',
    component: NurtureViewV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture',
    component: NurtureTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-card/add',
    component: NurtureCardFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-card/edit/:id',
    component: NurtureCardFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-card/:id',
    component: NurtureCardViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-card',
    component: NurtureCardTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-rarity/add',
    component: NurtureRarityFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-rarity/edit/:id',
    component: NurtureRarityFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-rarity/:id',
    component: NurtureRarityViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-rarity',
    component: NurtureRarityTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-stage/add',
    component: NurtureStageFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-stage/edit/:id',
    component: NurtureStageFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-stage',
    component: NurtureStageTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-stage/:id',
    component: NurtureStageViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-collection-groups/add',
    component: NurtureCollectionGroupsFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-collection-groups/edit/:id',
    component: NurtureCollectionGroupsFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-collection-groups/:id',
    component: NurtureCollectionGroupsViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-collection-groups',
    component: NurtureCollectionGroupsAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-collection/add',
    component: NurtureCollectionFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-collection/edit/:id',
    component: NurtureCollectionFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'releases/nurture-collection/:id',
    component: ReleaseNurtureCollectionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-collection/:id',
    component: NurtureCollectionViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nurture-collection',
    component: NurtureCollectionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack',
    component: SeedPackTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack/add',
    component: SeedPackFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack/:id',
    component: SeedPackViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack/edit/:id',
    component: SeedPackFormComponent,
    canActivate: [AuthGuard],
  },
  // Seed Back Boxes
  {
    path: 'seed-pack-box',
    component: SeedPackBoxTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack-box/add',
    component: SeedPackBoxFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack-box/:id',
    component: SeedPackBoxViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack-box/edit/:id',
    component: SeedPackBoxFormComponent,
    canActivate: [AuthGuard],
  },
  //
  // Seed Pack Box Types
  {
    path: 'seed-pack-box-types',
    component: SeedPackBoxTypeTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack-box-types/add',
    component: SeedPackBoxTypeFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack-box-types/:id',
    component: SeedPackBoxTypeViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'seed-pack-box-types/edit/:id',
    component: SeedPackBoxTypeFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'nuf-collection',
    component: NufCollectionTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'co-op',
    component: CoOpTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'co-op/add',
    component: CoOpFormV3Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'co-op/:id',
    component: CoOpViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'co-op/edit/:id',
    component: CoOpFormV3Component,
    canActivate: [AuthGuard],
  },
  // STORE
  // Price Points
  {
    path: 'price-points',
    component: PricePointTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'price-points/add',
    component: PricePointFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'price-points/:id',
    component: PricePointViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'price-points/edit/:id',
    component: PricePointFormComponent,
    canActivate: [AuthGuard],
  },
  // store listings
  {
    path: 'store-listings-v2',
    component: StoreListingV2TableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-listings-v2/add',
    component: StoreListingV2FormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-listings-v2/:id',
    component: StoreListingV2ViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'store-listings-v2/edit/:id',
    component: StoreListingV2FormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-strings',
    component: LocalizedStringsTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'client-strings',
    component: ClientStringsTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-configuration',
    component: LocalizationConfigurationTableComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-configuration/add',
    component: LocalizationConfigFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-configuration/:id',
    component: LocalizationConfigurationViewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'localization-configuration/edit/:id',
    component: LocalizationConfigFormComponent,
    canActivate: [AuthGuard],
  },
  // SPONSORTS
  {
    path: 'sponsors',
    component: SponsorTableAgGridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sponsors/add',
    component: SponsorFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'sponsors/:id',
    component: SponsorViewV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'sponsors/edit/:id',
    component: SponsorFormV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'diff-jobs',
    component: DiffToolAgAgridComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'diff-jobs/:id',
    component: DiffToolViewComponent,
    canActivate: [AuthGuard],
  },
  //
  {
    path: '**',
    component: HomePageComponent,
    // canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot([
    ...routesv2,
    ...routes
  ])],
  exports: [RouterModule],
})
export class AppRoutingModule { }
