import { Component, Input, OnInit } from '@angular/core';
import { CommonEntityService } from 'src/app/common/services/common-entity.service';
import { MessageService } from 'primeng/api';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-job-records-table',
  templateUrl: './job-records-table.component.html',
  styleUrls: ['./job-records-table.component.sass']
})
export class JobRecordsTableComponent implements OnInit {
  @Input() jobRecords: any[] = [];
  @Input() diffUuid: string = '';
  selectedImage: string | null = null;
  selectedImageIndex: number = 0;
  currentJobImages: string[] = [];
  imagePreviewTitle: string = 'Image Preview';
  placeholderUrl: string = 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/narrow_thumbnail_placeholder.jpeg';

  constructor(
    private commonEntityService: CommonEntityService,
    private messageService : MessageService,
    private dataService: DataService
  ) {}
  ngOnInit(): void {
    console.log('Job Records:', this.diffUuid);
    if (this.jobRecords.length > 0) {
      console.log('Job Records:', this.jobRecords);
    } else {
      console.warn('No Job Records available');
    }
  }

  getImageUrl(imageUrl: string | null): string {
    return imageUrl ? imageUrl : this.placeholderUrl;
  }

  openImagePopup(job: any, imageIndex: number, title: string) {
    this.currentJobImages = [
      this.getImageUrl(job.baseRenderURL),
      this.getImageUrl(job.compareRenderURL),
      this.getImageUrl(job.diffPixelImagePath)
    ];
    this.selectedImageIndex = imageIndex;
    this.selectedImage = this.currentJobImages[imageIndex];
    this.imagePreviewTitle = title;
  }

  onImageChange(newIndex: number): void {
    this.selectedImageIndex = newIndex;
    const titles = ['Base Render', 'Compare Render', 'Diff Image'];
    this.imagePreviewTitle = titles[newIndex];
    this.selectedImage = this.currentJobImages[newIndex];
  }


  closeImagePopup() {
    this.selectedImage = null;
    this.currentJobImages = [];
    this.selectedImageIndex = 0;
  }
  markAsChanged(job: any): void {
    job.isModified = true;
  }

  async submitChanges(): Promise<void> {
    try {
      const modifiedJobs = this.jobRecords.filter(job => job.isModified);

      if (modifiedJobs.length === 0) {
        this.messageService.add({severity:'info', summary:'No Changes', detail:'No changes detected to save.'});
        return;
      }
      for (const job of modifiedJobs) {
        await this.commonEntityService.update('challenges', job.entityId, {
          isInfiniteChallengeExcluded: job.isInfiniteChallengeExcluded
        }).then(updatedRecord => {
          if (updatedRecord) {
            const index = this.jobRecords.findIndex(jr => jr.entityId === updatedRecord.entityId);
            if (index !== -1) {
              this.jobRecords[index] = {
                ...this.jobRecords[index],
                isInfiniteChallengeExcluded: updatedRecord.isInfiniteChallengeExcluded,
                isModified: false
              };
            }
          }
          console.log('Record updated in challenges:', updatedRecord);
        }).catch(error => {
          console.error('Error updating record:', error);
        });
      }
      this.messageService.add({severity:'success', summary:'Success', detail:'Changes saved successfully!'});
      console.log('All updates successfully processed');
    } catch (error) {
      this.messageService.add({severity:'error', summary:'Error', detail:'An error occurred while saving changes.'});
      console.error('Error updating job records:', error);
    }
  }

  retryFailedDiffJobs(): void {
   this.dataService.retryFailedJobs({uuid: this.diffUuid}).then((response: any) => {
    if (response.success) {
      this.messageService.add({severity:'success', summary:'Success', detail: response.message});
    } else {
      this.messageService.add({severity:'error', summary:'Error', detail: response.message || 'Failed to retry jobs.'});
    }
   });
  }

}
