import { Component, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';

interface Image {
  previewImageSrc: string;
  thumbnailImageSrc: string;
  alt: string;
  title: string;
  devDiff?: any;
  qaDiff?: any;
  prodDiff?: any;
  lastAssetVersion?: any;
  style?: { [key: string]: string };
}

@Component({
  selector: 'app-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.sass']
})
export class ThumbnailComponent implements OnInit {
  images: Image[] = [];
  display: boolean = true;
  selectedImage: Image | null = null;
  isLoading: boolean = true;
  isImageLoaded: Array<boolean> = [];
  imageDisplay: string;
  @Input() assetRecord: any;
  @Input() entityId: number;
  @Input() entity: 'challenges' | 'items' | 'miscellaneous-build';
  diffImage: string | null = null; // Add this property
  showDiffDialog = false;
  prePromotionImage: any = {};
  activeIndex: any = 0;


  private readonly BASE_URL = 'https://flora-render.{env}.petunia.withbuddies.com/renderserver/test';

  constructor(private dataService: DataService, private cdRef: ChangeDetectorRef) {}

  async ngOnInit() {
    this.prePromotionImage = {};
    let challengeString = this.entity === 'challenges' ? this.entityId.toString() : '2791';
    let itemsString = this.entity === 'challenges' ? '0' : this.entityId.toString();
    let prePromotionQuery = this.entity === 'challenges' ?  'challengePath' : 'assetpath';

    if (this.entity != 'miscellaneous-build') {
      this.images = this.initializeImages(challengeString, itemsString);

      this.addPrePromotion(challengeString, itemsString, prePromotionQuery);

    } else {
      this.images = this.assetRecord.thumbnails ? this.assetRecord.thumbnails : [{path: 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg'}]
      this.activeIndex = this.images && this.images[2] ? 2 : 0;
    }

    

    if (this.entity === 'challenges') {
      await this.addTestCollectionImage(challengeString);
    }


    this.isImageLoaded = this.images ? Array(this.images.length).fill(false) : [];

    this.display = true;
  }

  private initializeImages(challengeString: string, itemsString: string): Image[] {
    const environments = ['dev', 'qa', 'prod'];
    return environments.map(env => ({
      previewImageSrc: `${this.BASE_URL.replace('{env}', env)}/${challengeString}/512/${itemsString}.jpg`,
      thumbnailImageSrc: `${this.BASE_URL.replace('{env}', env)}/${challengeString}/512/${itemsString}.jpg`,
      alt: `${env.toUpperCase()} Render`,
      title: `${env.toUpperCase()}`
    }));
  }

  private async addTestCollectionImage(challengeString: string) {
    let testCollectionItems = '0000';
    let testCollection = await this.dataService.getAllOfTypeAsync('collections', { query: { id: 1053 }, select: 'id name item' });
    if (testCollection[0]?.items?.length > 0) {
      testCollectionItems = testCollection[0].items.join('-');
    }
    this.images.push({
      previewImageSrc: `${this.BASE_URL.replace('{env}', 'qa')}/${challengeString}/512/${testCollectionItems}.jpg`,
      thumbnailImageSrc: `${this.BASE_URL.replace('{env}', 'qa')}/${challengeString}/512/${testCollectionItems}.jpg`,
      alt: 'Scene Render',
      title: 'Items Preview (QA)'
    });
  }

  private addPrePromotion(challengeString: string, itemsString: string, prePromotionQuery: string) {
    // Assuming this.assetRecord.asset_versions contains your array of asset version objects
    let assetVersions = this.assetRecord.asset_versions || [];
    let lastAssetVersion = assetVersions[assetVersions.length - 1] || null;
    let destinationPath = lastAssetVersion ? lastAssetVersion.destinationPath : '';

    const prePromotionImage: Image = {
      previewImageSrc: `${this.BASE_URL.replace('{env}', 'qa')}/${challengeString}/512/${itemsString}.jpg?${prePromotionQuery}=${destinationPath}`,
      thumbnailImageSrc: `${this.BASE_URL.replace('{env}', 'qa')}/${challengeString}/512/${itemsString}.jpg?${prePromotionQuery}=${destinationPath}`,
      alt: 'Pre Promotion Render',
      title: 'Pre Promotion',
      lastAssetVersion: lastAssetVersion
    };

    // Insert the prePromotionImage at index 1
    this.images.splice(1, 0, prePromotionImage);
  }

  showDialog(image: Image) {
    this.selectedImage = image;
    this.display = true;
  }

  onImgError(event: any) {
    event.target.src = 'https://d3tfb94dc03jqa.cloudfront.net/thumbnails/thumbnail_placeholder.jpeg';
  }

  async fetchAndDisplayDiffImage(imageUrl1: string, imageUrl2: string) {
    this.dataService.getDiffImage(imageUrl1, imageUrl2)
      .then(response => {
        // Assuming response.diffImage contains the Base64 string
        this.diffImage = response.diffImage;
      })
      .catch(error => {
        console.error(error);
        // Handle any errors, potentially resetting diffImage or showing a default image
        this.diffImage = null; // or set to a default image
      });
  }

  


  onImageLoad(index: number) {
    this.isImageLoaded[index] = true;
    this.imageDisplay = 'block'
    this.cdRef.detectChanges()
  }

  // Method to go to the previous image
  prevImage() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
      this.imageDisplay = 'none';
      this.cdRef.detectChanges()
    }
  }

  // Method to go to the next image
  nextImage() {
    if (this.activeIndex < this.images.length - 1) {
      this.activeIndex++;
      this.imageDisplay = 'none';
      this.cdRef.detectChanges()
    }
  }


}
